import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {
  Button,
  Dropdown,
  Form,
  Modal,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ReconcileService as reconcileService } from "../../../Common/Services/ReconcileService";
import { bankSyncServices } from "../../../Common/Services/bankSyncServices";
import { resolve, reject } from "q";
import "../Reconcile/reconcile.scss";
import { Register as registersevice } from "../../../Common/Services/Register";
import { InvoiceEntrySlideout } from "../../Register/InvoiceEntrySlideout";
import { TransactionSlideout } from "../../Register/TransactionSlideout";
import { TransferSlideout } from "../../Register/TransferSlideout";
import { ToastContainer, toast } from "react-toastify";
import { Utils } from "../../../Common/Utilis";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import _ from "lodash";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

export class AdjustmentModal extends React.Component<any, any> {
  private saveBtnRef: any;
  constructor(props: any) {
    super(props);
    this.saveBtnRef = React.createRef();
    this.state = {
      confirmTxData: this.props.confirmTxData,
      accTper: this.props.accTper,
      hidValue: this.props.hidValue,
      acctType: this.props.acctType,
      AccountType: this.props.AccountType,
      AdjEntryType: this.props.AdjEntryType,
      OprID: this.props.OprID,
      adjustmentAmount: this.props.adjustmentAmount,
      adjustmentReq: {
        UniqueNo: "",
        SplitHid: this.props.hidValue,
        BackHID: 0,
        hotelName: this.props.hotelName,
        OprID: 0,
        Date: new Date(this.props.accper),
        // Date: new Date(
        //   new Date(this.props.accper).getFullYear(),
        //   new Date(this.props.accper).getMonth() + 1,
        //   0
        // ),
        AccPer: "",
        COA: "",
        COAName: "",
        Sign: "",
        Adjustment: this.props.adjustmentAmount,
        Discount: 0,
        NetAmount: this.props.adjustmentAmount,
        Type: "",
        Description: "",
        CLMonth: "",
        CLYear: "",
        AmtType: "",
        CheckNo: "",
        EntryType: "",
        EntryStatus: "",
        accTypeName: "",
        accTypeId: 0,
        defaultCOA: [],
      },
      ehidList: [],
      coaList: [],
      accountTypes: [],
      isGrouped: false,
      isLoading:false,
    };
  }
  onAddAdjustment = (isSave = 'close') => {
    this.setState(
      {
        accountTypes: [],
        adjustmentReq: {
          UniqueNo: "",
          SplitHid: 0,
          BackHID: 0,
          hotelName: "",
          OprID: 0,
          Date: new Date(),
          // new Date(this.props.accper).getFullYear(),
          // new Date(this.props.accper).getMonth() + 1,
          // 0
          AccPer: "",
          COA: "",
          COAName: "",
          Sign: "",
          Adjustment: "",
          Discount: 0,
          NetAmount: 0,
          Type: "",
          Description: "",
          CLMonth: "",
          CLYear: "",
          AmtType: "",
          CheckNo: "",
          EntryType: "",
          EntryStatus: "",
          accTypeName: "",
          accTypeId: 0
        },
      },
      () => {
        this.props.hideAdjustmentModal(isSave);
        // this.setState({ isAddAdjustment: !this.state.isAddAdjustment });
      }
    );
  };
  componentDidMount() {
    let newDate = new Date(this.state.adjustmentReq.Date.toLocaleDateString("en-US")); //Changes as per NW-22812
    //new Date(this.state.accTper);
    let strMonth = Number(newDate.getMonth()) + 1;
    let strYear = newDate.getFullYear();
    let enddate = strMonth + "/01/" + strYear;

    if (this.props.rowEntryType === "DepositEntry") {
      let groupUniqueNo = [];
      this.state.confirmTxData[2].map((items) => {
        if (items.groupUniqueNo != null) {
          groupUniqueNo = items.groupUniqueNo.split(",");
          if (groupUniqueNo.length > 1) {
            this.setState({ isGrouped: true }, () => this.getBankSyncGroupMapping())
          }
        }
      });
    }

    this.setState(
      { EndDate: enddate, ClMonth: strMonth, ClYear: strYear },
      () => {
        // this.getAccountStatus();
        // this.getReconcilationData();
        this.getEhidList();
        this.getCOAList();
      }
    );
  }

  getBankSyncGroupMapping = () => {
    let request: any = {};
    request.Groupuniqueno = this.state.confirmTxData[2][0].groupUniqueNo;
    bankSyncServices
      .BankSyncGroupMapping(request)
      .then(async (result: any | null) => {
        if (result.length > 0) {
          this.setState({ accountTypes: result });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  getEhidList = () => {
    let request: any = {};
    request.Hid = this.state.hidValue;
    request.Type = "ReconcileAdj";
    request.OprId = this.state.OprID;
    request.Accper = this.state.accTper;

    // let selectedRows: any[] = this.props.confirmTxData[2];
    // selectedRows = _.uniqBy(selectedRows, 'hotelId');

    reconcileService
      .GetHotelsForAddAdjustment(request)
      .then(async (result: any | null) => {
        if (result.length > 0) {
          let hidName = result.filter(
            (e) => e.hotelId === Number(this.state.hidValue)
          );
          hidName = hidName.length > 0 ? hidName[0].letterCode : "";
          this.setState({ ehidList: result, hidName });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  handleAddAdjustmentVal = async (item, type, EntryStatus) => {
    this.setState({isLoading:true});
    let adjustmentReq = { ...this.state.adjustmentReq };
    if (type === "save") {
      // Update If trans date to bank date if Grater
      let res: any;

      if (this.isValidAmount()) {
        //debugger
        if (this.props.isAlerted)
          res = await Promise.all([this.props.UpdateFromAdjustment()]);
        
        this.saveBtnRef.current.disabled = true;
        adjustmentReq.OprID = this.state.OprID;
        adjustmentReq.AccPer = this.state.EndDate;
        adjustmentReq.Type = this.state.AccountType;
        adjustmentReq.CLMonth = this.state.ClMonth;
        adjustmentReq.CLYear = this.state.ClYear;
        adjustmentReq.EntryType = this.state.AdjEntryType;
        adjustmentReq.AmtType = "Adjustment";
        adjustmentReq.Sign = "1";
        adjustmentReq.EntryStatus = EntryStatus;
        adjustmentReq.acctType = this.state.acctType;
        adjustmentReq.syncId = this.props.syncID;
        adjustmentReq.Date = adjustmentReq.Date.toLocaleDateString("en-US");
        adjustmentReq.objTranctionRequest = this.state.confirmTxData[2];
        reconcileService
          .AddAdjustmentsBankSync(adjustmentReq)
          .then(async (result: any | null) => {
            if (result.saveStatus === "Success") {
              if (result.messageCode === "INSERT") {
                toast.success("Adjustment added successfully.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "Reconcile",
                });
                this.setState({isLoading:false});
              } else if (result.messageCode === "UPDATE") {
                toast.success("Adjustment update successfully.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "Reconcile",
                });
                this.setState({isLoading:false});
              } else if (result.messageCode === "DELETE") {
                toast.success("Adjustment delete successfully.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "Reconcile",
                });
                this.setState({isLoading:false});
              }

              this.onAddAdjustment("save");
              // this.getReconcilationData();
            } else {
              Utils.toastError(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "Reconcile",
              });
              this.setState({isLoading:false});
              this.onAddAdjustment("error");
            }
            this.saveBtnRef.current.disabled = false;
            resolve();
          })
          .catch((error) => {
            reject();
            this.setState({isLoading:false});
          });
      }
    } else {
      let selectedRows: any[] = this.props.confirmTxData[2];
      const { ehidList } = this.state;
      selectedRows = _.uniqBy(selectedRows, 'backHid');
      let currentEhid = ehidList.filter((o: any) => o.hotelId === selectedRows[0]?.backHid);
      currentEhid = _.uniqBy(currentEhid, 'hotelId');

      if (type === "hid") {
        adjustmentReq.SplitHid = currentEhid.length > 0 ? currentEhid[0]?.letterCode : item.hotelId;
        adjustmentReq.BackHID = item.hotelId;
        adjustmentReq.hotelName = item.letterCode;
      }

      if (type === "coa") {
        if (item.length > 0) {
          let defaultCOA: any = [];
          let objCOA: any = {};
          adjustmentReq.COA = item[0].id;
          adjustmentReq.COAName = item[0].name;
          objCOA.id = item[0].id;
          objCOA.label = item[0].name;
          defaultCOA.push(objCOA);
          adjustmentReq.defaultCOA = defaultCOA;
        } else {
          adjustmentReq.COA = "";
          adjustmentReq.COAName = "";
          adjustmentReq.defaultCOA = [];
        }
      }

      if (type === "accType") {
        adjustmentReq.accTypeId = item.groupID;
        adjustmentReq.accTypeName = item.auditName;
        this.state.confirmTxData[2].map((items) => ((items["accType"] = item.auditName)));
      }

      if (type === "date") {
        adjustmentReq.Date = new Date(item);
      }

      if (type === "amount") {
        const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
        let amount = adjustmentReq.Adjustment;
        let e = item;
        if (rx_live.test(e.target.value)) {
          if (e.target.value.includes(".")) {
            const timeArray = e.target.value?.split(".");
            if (timeArray[0].length <= 8 && timeArray[1].length <= 2) {
              amount = e.target.value;
            }
          } else {
            if (e.target.value.length <= 8) {
              amount = e.target.value;
            }
          }
        } else if (
          e.target.value.includes(".") &&
          e.target.value.length === 1
        ) {
          amount = e.target.value;
        }
        adjustmentReq.Adjustment = amount;
        adjustmentReq.NetAmount = amount;
      }

      if (type === "description") {
        adjustmentReq.Description = item.target.value;
      }
      this.setState({isLoading:false,adjustmentReq });
    }
  };

  getReconcilationData = () => {
    this.setState({ isLoader: true });
    let request: any = {};
    request.Hid = this.state.hidValue;
    request.Flag = this.state.AccountType;
    request.OprID = this.state.OprID;
    request.ClMonth = this.state.ClMonth;
    request.ClYear = this.state.ClYear;
    request.EndDate = this.state.EndDate;
    request.acctType = this.state.acctType;
    reconcileService
      .GetReconcileData(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element) => {
            element.isReconcile = element.isReconcile === 1 ? true : false;
            element.payment = Number(element.payment).toFixed(2);
            element.netAmount = Number(element.netAmount).toFixed(2);
            element.date1 = new Date(element.date);
          });
          //   this.calculateHeaderData(result, "onload");
        } else {
          this.setState({ tableData: [], isLoader: false });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ tableData: [], isLoader: false }, () => {
          Utils.toastError(error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "Reconcile",
          });
        });
        reject();
      });
  };

  isValidAmount = () => {
    let adjustmentReq = { ...this.state.adjustmentReq };
    if (adjustmentReq.SplitHid === 0) {
      Utils.toastError("Please select hotel.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "Reconcile",
      });
      return false;
    }

    if (adjustmentReq.COA === 0 || adjustmentReq.COA === "") {
      Utils.toastError("Please select COA.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "Reconcile",
      });
      return false;
    }

    if (this.props.rowEntryType === "DepositEntry" && this.state.isGrouped) {
      if (adjustmentReq.accTypeId === 0 || adjustmentReq.accTypeName === "") {
        Utils.toastError("Please select account type.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "Reconcile",
        });
        return false;
      }
    }

    if (adjustmentReq.Date === "") {
      Utils.toastError("Please select date.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "Reconcile",
      });
      return false;
    }

    if (adjustmentReq.Adjustment === "") {
      Utils.toastError("Please enter amount.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "Reconcile",
      });
      return false;
    }

    // if (Number(adjustmentReq.Adjustment) <= 0) {
    //   Utils.toastError("Please enter valid amount.", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     containerId: "Reconcile",
    //   });
    //   return false;
    // }

    if (adjustmentReq.Description.trim().length === 0) {
      Utils.toastError("Please enter description.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "Reconcile",
      });
      return false;
    }
    return true;
  };

  // getCOAList = () => {
  //   let request: any = {};
  //   request.Vid = 0;
  //   reconcileService
  //     .GetCOAListForAddAdjustment(request)
  //     .then(async (result: any | null) => {
  //       if (result.length > 0) {
  //         this.setState({ coaList: result });
  //       }
  //       resolve();
  //     })
  //     .catch((error) => {
  //       reject();
  //     });
  // };

  getCOAList = () => {
    let request: any = {};
    request.UsedFor = "Adjustment";
    reconcileService.GetSelectedCOAData(request)
      .then(async (result: any | null) => {
        if (result.length > 0) {
          let adjustmentReq = { ...this.state.adjustmentReq }

          result.map(item => item["label"] = item.name);

          let defaultCOA: any = [];
          let objCOA: any = {};
          let currentCOA = result.filter(e => e.isSelected === 1);
          if (currentCOA.length > 0) {
            adjustmentReq.COA = currentCOA[0].id;
            adjustmentReq.COAName = currentCOA[0].name;
            objCOA.id = currentCOA[0].id;
            objCOA.label = currentCOA[0].name;
            defaultCOA.push(objCOA);
            adjustmentReq.defaultCOA = defaultCOA;
          }
          this.setState({
            coaList: result,
            adjustmentReq,
          })
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };

  render() {
    let selectedRows: any[] = this.props.confirmTxData[2];
    const { ehidList } = this.state;
    selectedRows = _.uniqBy(selectedRows, 'backHid');
    let currentEhid = ehidList.filter((o: any) => o.hotelId === selectedRows[0]?.backHid);
    currentEhid = _.uniqBy(currentEhid, 'hotelId');

    const renderItemChildren = (option, props, index) => {
      return (
        <>
          <div key={option.id}>
            <div className="vendor-name">
              <EllipsisWithTooltip placement="bottom">
                {option.label}
              </EllipsisWithTooltip>
            </div>
          </div>
        </>
      );
    };

    return (
      <>
        <Modal
          className="move-invoice-modal mark-invoice-as-paid"
          show={this.props.isAddAdjustment}
          onHide={() => this.onAddAdjustment()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Adjustment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group controlId="EHID">
                  <Form.Label>EHID</Form.Label>
                  <Dropdown className="disabled-area custom-selectbox" alignRight>
                    <Dropdown.Toggle id="dropdown-periodhid">
                      <EllipsisWithTooltip placement="bottom">
                        {/* {this.state.adjustmentReq.hotelName.length === 0 ? "Select" : this.state.adjustmentReq.hotelName} */}
                        {currentEhid.length > 0 ? currentEhid[0]?.letterCode : "Select"}
                      </EllipsisWithTooltip>
                      <svg
                        width="10"
                        height="7"
                        viewBox="0 0 10 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M8.29272 0.292969L5.00006 3.5863L1.70739 0.292969L0.292725 1.70764L5.00006 6.4143L9.70739 1.70764L8.29272 0.292969Z" />
                      </svg>
                    </Dropdown.Toggle>
                    {/* <Dropdown.Menu>
                      {this.state.ehidList.map((item: any, idx: any) => (
                        <Dropdown.Item
                          eventKey={item.hotelId}
                          key={idx}
                          onClick={() => {
                            this.handleAddAdjustmentVal(item, "hid", "");
                          }}
                        >
                          {item.letterCode}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu> */}
                  </Dropdown>
                </Form.Group>
              </Col>
              <Col className="w-50">
                <Form.Group controlId="COA">
                  <Form.Label>COA</Form.Label>
                  {/* <Dropdown className="custom-selectbox" alignRight>
                    <Dropdown.Toggle id="dropdown-periodcoa" style={{ paddingRight: "30px" }}>
                      <EllipsisWithTooltip placement="bottom">
                        {this.state.adjustmentReq.COAName.length === 0
                          ? "Select"
                          : this.state.adjustmentReq.COAName}
                      </EllipsisWithTooltip>
                      <svg
                        width="10"
                        height="7"
                        viewBox="0 0 10 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M8.29272 0.292969L5.00006 3.5863L1.70739 0.292969L0.292725 1.70764L5.00006 6.4143L9.70739 1.70764L8.29272 0.292969Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {this.state.coaList.map((item: any, idx: any) => (
                        <Dropdown.Item
                          eventKey={item.id}
                          key={idx}
                          onClick={() => {
                            this.handleAddAdjustmentVal(item, "coa", "");
                          }}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown> */}

                  <div className="more-action single-select-image dropdown coa-select" onWheel={() => this.hoverOutEllipsis()}>
                    <Typeahead
                      inputProps={{ className: "ConditionalInput", id: "cOASelected" }}
                      options={this.state.coaList}
                      renderMenu={(results: any, menuProps) => (
                        <Menu {...menuProps}>
                          {results.map((result: any, index: any) => (
                            <>
                              <MenuItem option={result} position={index}>
                                <EllipsisWithTooltip placement="bottom">{result.label}</EllipsisWithTooltip>
                              </MenuItem>
                              {result.label === "" && (
                                <MenuItem className="load-more" option={result} position={index}>Load More</MenuItem>
                              )}
                              {this.state.coaList.length > 0 &&
                                results[index - 1]?.orderno === 1 &&
                                result?.orderno === 2 && <Dropdown.Divider />}
                            </>
                          ))}
                        </Menu>
                      )}
                      renderMenuItemChildren={renderItemChildren}
                      onChange={(e) => this.handleAddAdjustmentVal(e, "coa", "")}
                      selected={this.state.adjustmentReq.defaultCOA}
                      key={Math.floor(Math.random() * 90 + 10)}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="date">
                  <Form.Label>Date</Form.Label>
                  <DatePicker
                    id="paymentdate"
                    className="form-control"
                    placeholderText={"MM/DD/YY"}
                    dateFormat="MM/dd/yy"
                    //maxDate={new Date(new Date(this.props.accTper).getFullYear(), new Date(this.props.accTper).getMonth() + 1, 0)} NW-26044
                    maxDate={new Date(new Date(this.state.adjustmentReq.Date).getFullYear(), new Date(this.state.adjustmentReq.Date).getMonth() + 1, 0)}
                    // maxDate={new Date(this.props.accTper)}
                    selected={
                      this.state.adjustmentReq.Date === ""
                        ? new Date()
                        : this.state.adjustmentReq.Date
                    }
                    onChange={(event: any) =>
                      this.handleAddAdjustmentVal(event, "date", "")
                    }
                    disabled={
                      this.state.adjustmentReq.UniqueNo !== "" ? true : false
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="amount">
                  <Form.Label>Variance Amount</Form.Label>
                  <Form.Control
                    placeholder="Amount"
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    readOnly={true}
                    value={this.state.adjustmentReq.Adjustment}
                    onChange={(e) => {
                      this.handleAddAdjustmentVal(e, "amount", "");
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {this.props.rowEntryType === "DepositEntry" && this.state.isGrouped &&
              <Row>
                <Col>
                  <Form.Group controlId="EHID">
                    <Form.Label>Account Types</Form.Label>
                    <Dropdown className="custom-selectbox" alignRight>
                      <Dropdown.Toggle id="dropdown-periodhid">
                        <EllipsisWithTooltip placement="bottom">
                          {this.state.adjustmentReq.accTypeName.length === 0
                            ? "Select"
                            : this.state.adjustmentReq.accTypeName}
                        </EllipsisWithTooltip>
                        <svg
                          width="10"
                          height="7"
                          viewBox="0 0 10 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.29272 0.292969L5.00006 3.5863L1.70739 0.292969L0.292725 1.70764L5.00006 6.4143L9.70739 1.70764L8.29272 0.292969Z" />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {this.state.accountTypes && this.state.accountTypes.map((item: any, idx: any) => (
                          <Dropdown.Item
                            eventKey={item.groupID}
                            key={idx}
                            onClick={() => {
                              this.handleAddAdjustmentVal(item, "accType", "");
                            }}
                          >
                            {item.auditName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Col>
              </Row>}
            <Form.Group controlId="Description">
              <Form.Label>Description <strong className="text-danger">*</strong></Form.Label>
              <Form.Control
                id="txtDescription"
                as="textarea"
                placeholder="Add Description"
                wrap="hard"
                rows={3}
                value={this.state.adjustmentReq.Description}
                onChange={(e) => {
                  this.handleAddAdjustmentVal(e, "description", "");
                }}
                maxLength={99}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {this.state.adjustmentReq.UniqueNo !== "" && (
              <Button
                className="btn-outline-primary"
                onClick={() => {
                  this.handleAddAdjustmentVal("", "save", "Delete");
                }}
              >
                Delete
              </Button>
            )}
            <Button
              className="btn-primary"
              ref={this.saveBtnRef}
              disabled={this.state.isLoading}
              onClick={() => {
                this.handleAddAdjustmentVal(
                  "",
                  "save",
                  this.state.adjustmentReq.UniqueNo !== "" ? "Update" : "Insert"
                );
              }}
            >
              {this.state.isLoading && <Spinner size="sm" animation="border" />} Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
