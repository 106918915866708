import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { ModuleReports } from "./../../Common/Services/ModuleReports";
import { ToastContainer, toast } from "react-toastify";
import { Utils } from "../../Common/Utilis";

export class ProjectBudgets extends React.Component<any, any> {

  private inputElement: any;

  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      selectedItem: {},
      filterData: [],
      projectBudgetsList: []
    };
  }


  componentDidUpdate(prevProps, prevState) {
    //debugger;
    const { projectBudgetId } = this.props;
    const { filterData } = this.state;
    if ( projectBudgetId === -1 && (prevProps.hotelIds !== this.props.hotelIds || prevProps.selectedVendorId !== this.props.selectedVendorId)) {
        this.getProjectBudget();
    }
  }

  componentDidMount = () => {
    //debugger;
    this.getProjectBudget();

  };

  getProjectBudget = () => {
    let request: any = {};
    request.hotelID = this.props.hotelIds;
    ModuleReports
      .GetProjectBudget(request)
      .then(async (result: any | null) => {
        //debugger;
        if (result !== null) {
          this.setState({ filterData: result, projectBudgetsList: result }, () => {
            const { projectBudgetId } = this.props;
            const { filterData } = this.state;
            if (projectBudgetId === undefined || projectBudgetId === -1) {
              let selectedItem = { label: "Select", id: -1};
              this.setState({ selectedItem }, () => {
                // this.handleDropdownChange(selectedItem);
              })

            } else {
              let selectedItem = filterData?.filter(r => r.id === projectBudgetId);
              this.setState({ selectedItem: selectedItem[0], }, () => {
                // this.handleDropdownChange(selectedItem[0]);
              });
            }
          });
        }
      })
      .catch((err: any) => {
        this.setState({ filterData: [], projectBudgetsList: [], selectedItem: { label: "Select", id: -1}, });
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }


  handleDropdownChange = (item: any) => {
    this.setState({ selectedItem: item });
    this.props.onProjectBudgetsChange(item);
    this.resetSerchBox();
  }

  resetSerchBox = () => {
    this.setState({ filterData: this.state.projectBudgetsList });
  };

  render() {

    return (
      <Dropdown
        className="single-select" style={{ marginBottom: "10px" }}>
        <Dropdown.Toggle id="Modal-reportTypes">
          <span >
            {this.state.selectedItem?.label}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (

              <Dropdown.Item
                onClick={() => this.handleDropdownChange(item)}
              >
                {item.label}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
