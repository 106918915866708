import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  //Container,
  Dropdown,
  Button,
  Form,
  Modal,
  Table,
  ButtonGroup,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TableSearch from "../../../../Common/Components/TableSearch";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { OtrScheduleSlideOut } from "./Modals & SlideOut/OtrScheduleSlideOut";
import { OtrTimeSheetSlideOut } from "./Modals & SlideOut/OtrTimeSheetSlideOut";

//import { LaborTimesheetDetail } from "../../../LaborManagement/TimeSheet/LaborTimesheetDetail";

import { ShiftReassignPopUp } from "./components/ShiftReassignPopUp";
import $ from "jquery";
import { Hotel } from "../../../../Common/Services/Hotel";
import { reject, resolve } from "q";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { LaborDeptPosition as laborDeptPosition } from "../../../../Common/Services/LaborDeptPosition";
import { LaborPerformance as laborPerformance } from "../../../../Common/Services/LaborPerfomance";
import { ILaborScheduleScheduleDragRequestDto } from "../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../Common/Services/LaborSchedule";
import { ConfirmationModal } from "../../../../Common/Components/ConfirmationModal";
import { scheduleComponents } from "./commonFunctions/ScheduleComponents";
import { Utils } from "../../../../Common/Utilis";

let bulkSelectRow: any = [];
let bulkSelectCount = 0;
let bulkHidden = false;
let leftPanelColumns: any;
let countColumns: any;
let otarCurrentRow: any = "";
let getcountRowdata: any;
let newSearchResult: any = [];
let blankRow: any = {};

export class OvertimeRisk extends React.Component<any, any> {
  private childHID: any;
  private child: any;
  constructor(props: any) {
    super(props);
    this.child = React.createRef();
    this.childHID = React.createRef();
    this.state = {
      otarTableData: [],
      loaderCountTrue: false,
      isBulkSelect: false,
      leftTableClass: "left-body cus4rowTableOverTime",
      isLeftFooter: false,
      bulkRiskAccept: false,
      sortingOrderList: ["Ascending", "Descending"],
      sortingOrder: "Ascending",
      tableData: [],
      isLastRecord: false,
      leftPanelData: [],
      otarValue: "",
      otarBulkValue: "",
      otarError: "",
      otarBulkError: "",
      rightPanelRowTop: [],
      rightPanelRowBottom: [],
      edtOTRstartTime: "00:00",
      edtOTRendTime: "00:00",
      outSaveHandler: false,
      currentPageIndex: 0,
      otgSplitShift: false,
      isScheduleSlideOut: false,
      isTimesheetSlideOut: false,
      loaderLeftTrue: false,
      loaderRightTrue: false,
      otarcountRowDetails: {},
      rowEventData: {},
      showotrPanel: true,
      splitDepartment: "Select",
      splitDepartmentID: "",
      splitPosition: "Select",
      splitPositionID: "",
      actualDetails: [],
      departmentlist: [],
      alldepartmentlist: [],
      allpositionlist: [],
      shiftItems: {},
      splitTimeVal: "",
      splitNotes: "",
      splitShiftError: "",
      checkEnable: false,
      OTARPanelLoader: false,
      activeClassRow: 1,
      isSlideOutShow: false,
      slideOutData: [],
      // Readding modal States
      reassignModalSchedule: {},
      reassignModalRow: {},
      navigationCalenderDates: [],
      currentDate:
        JSON.parse(localStorage.storage).currentUtcTime !== ""
          ? new Date(JSON.parse(localStorage.storage).currentUtcTime)
          : new Date(),
    };
  }

  componentDidMount() {
    console.warn = () => {};
    if (this.props.parentState.hidValue !== "All") {
      this.getLeftPanelData();
    }
    this.refotarPanel();
    this.refotarPanelCount();
  }

  refotarPanelCount() {
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let request: any = {};
    request.tenantID = localStorageUser.tenantID;
    request.hotelID =
      this.props.parentState.hidValue === "All"
        ? 0
        : this.props.parentState.hidValue;
    request.userName = "";
    request.callType = "HotelWiseCount";
    request.loginUserName = localStorageUser.userName;
    this.setState({ loaderCountTrue: true });
    Hotel.GetOverTimeRiskData(request)
      .then(async (result) => {
        if (result != null) {
          let data = result.result;
          let count;
          if (data.length > 0) {
            let numbers = data.map((items) => items.otarCount);
            count = numbers.reduce((a, b) => a + b);
          } else {
            count = 0;
          }
          this.props.changeOTARCount(count);
          resolve();
        }
      })
      .catch((error) => {
        reject();
      });
  }

  refotarPanel() {
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let request: any = {};
    request.tenantID = localStorageUser.tenantID;
    request.hotelID =
      this.props.parentState.hidValue === "All"
        ? 0
        : this.props.parentState.hidValue;
    request.userName = "";
    request.callType = "HotelWiseCount";
    request.loginUserName = localStorageUser.userName;
    this.setState({ loaderCountTrue: true });
    Hotel.GetOverTimeRiskData(request)
      .then(async (result) => {
        if (result != null) {
          let data = result.result;
          this.setState({
            otarTableData: data,
            otarcountRowDetails: data[0],
            loaderCountTrue: false,
          });
          resolve();
        }
      })
      .catch((error) => {
        reject();
        this.setState({ loaderCountTrue: false });
      });
  }

  countRowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.getcountRowdata(row);
    },
  };

  getcountRowdata = (row) => {
    getcountRowdata = row;
    this.setState(
      { otarcountRowDetails: getcountRowdata, showotrPanel: false },
      () => {
        this.getLeftPanelData();
      }
    );
  };

  getHidPayPeriod() {
    let { otarcountRowDetails } = this.state;
    let hotelID =
      otarcountRowDetails === undefined ? 0 : otarcountRowDetails.hid;
    laborPerformance
      .getPayPeriodDates(hotelID, this.state.currentDate, "Weekly")
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({
            navigationCalenderDates: [result[0].startdate, result[0].enddate],
          });
        }
      });
  }

  getLeftPanelData = () => {
    this.setBulkCount();
    let { otarcountRowDetails } = this.state;
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    otarCurrentRow = sessionStorage.getItem("otarCurrentRow");
    let hid = otarcountRowDetails === undefined ? 0 : otarcountRowDetails.hid;
    let phid =
      this.props.parentState.hidValue === "All"
        ? hid
        : this.props.parentState.hidValue;
    let hotelID = phid;
    let request: any = {};
    request.tenantID = localStorageUser.tenantID;
    request.hotelID = hotelID;
    request.userName = "";
    request.callType = "ShowEmpLeft";
    request.loginUserName = localStorageUser.userName;
    this.setState({ OTARPanelLoader: true });
    Hotel.GetOverTimeRiskData(request)
      .then(async (result) => {
        if (result != null) {
          let data = result.result;
          let dataAscending = data.sort(
            (a, b) => parseFloat(a.otHrs) - parseFloat(b.otHrs)
          );
          data.map(
            (items) => (
              (items["isAccepted"] = false), (items["isChecked"] = false)
            )
          );
          this.setState(
            { tableData: dataAscending, sortingOrder: "Ascending" },
            () => {
              if (otarCurrentRow !== null) {
                this.eventRowData(JSON.parse(otarCurrentRow));
              } else {
                this.eventRowData(dataAscending[0]);
              }
              this.getHidPayPeriod();
              this.setBulkCount();
              this.setState({ OTARPanelLoader: false });
            }
          );
          resolve();
        }
      })
      .catch((error) => {
        reject();
        this.setState({ OTARPanelLoader: false });
      });
  };

  clearSession = () => {
    sessionStorage.removeItem("otarCurrentRow");
  };

  allHotelBreadCrumb = (e: any) => {
    this.clearSession();
    this.setState(
      {
        tableData: [],
        otarTableData: [],
        rightPanelRowTop: [],
        rightPanelRowBottom: [],
        rowEventData: {},
        showotrPanel: true,
        sortingOrder: "Ascending",
      },
      () => {
        this.refotarPanel();
        this.refotarPanelCount();
      }
    );
  };

  LeftPanelRowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      let value = (rowIndex + 1) % 10;
      value === 0
        ? this.setState({ isLastRecord: true })
        : this.setState({ isLastRecord: false });
      sessionStorage.setItem("otarCurrentRow", JSON.stringify(row));
      otarCurrentRow = sessionStorage.getItem("otarCurrentRow");
      if (otarCurrentRow !== null) {
        this.eventRowData(JSON.parse(otarCurrentRow));
      } else {
        this.eventRowData(row);
      }
    },
  };

  setBulkCount = () => {
    let dataLen = this.state.tableData.filter((items) => !items.isAccepted);
    dataLen.length > 1 ? (bulkHidden = false) : (bulkHidden = true);
  };

  eventRowData(row: any) {
    let { tableData } = this.state;
    let noAccept = tableData.filter((items) => !items.isAccepted);
    if (row !== undefined) {
      if (noAccept.length > 0 && row.isAccepted) {
        this.setState(
          { rowEventData: noAccept[0], activeClassRow: noAccept[0].id },
          () => {
            this.getRightPanelData();
          }
        );
      } else {
        this.setState({ rowEventData: row, activeClassRow: row.id }, () => {
          this.getRightPanelData();
        });
      }
    } else {
      this.props.cusToastMessage(1);
      this.clearSession();
    }
  }

  getlaborPositionDeptByemployee() {
    let { otarcountRowDetails } = this.state;
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let hotelID =
      otarcountRowDetails === undefined ? 0 : otarcountRowDetails.hid;
    this.setState({ loaderTrue: true });
    let LoginUserName = localStorageUser.userName;
    laborDeptPosition
      .getlaborPositionDeptByemployee(hotelID, LoginUserName)
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ departmentlist: result });
          this.setState({ alldepartmentlist: result });
        } else {
          this.setState({ departmentlist: [] });
          this.setState({ alldepartmentlist: [] });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  departmentSplitChange(event: any, item: any, rowIndex: any) {
    this.setState({
      splitDepartmentID: item.departmentID,
      splitDepartment: item.departmentName,
      allpositionlist: item.laborPositionlist,
      splitPosition: "Select",
      SplitShift: { department: item.laborPositionlist },
    });
  }

  positionSplitChange(event: any, item: any, rowIndex: any) {
    this.setState({
      splitPosition: item.positionName,
      splitPositionID: item.positionID,
    });
  }

  getRightPanelData = () => {
    let { rowEventData, otarcountRowDetails } = this.state;
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let hotelID =
      otarcountRowDetails === undefined ? 0 : otarcountRowDetails.hid;
    let request: any = {};
    request.tenantID = localStorageUser.tenantID;
    request.hotelID = hotelID;
    request.userName = rowEventData.employee;
    request.callType = "ShowEmpScheduleRight";
    request.loginUserName = localStorageUser.userName;
    this.setState({ loaderRightTrue: true });
    Hotel.GetOverTimeRiskData(request)
      .then(async (result) => {
        if (result != null) {
          if (result.success) {
            let data = result.result;
            data.map((items) => (items["isAssigned"] = false));
            let rightPanelTop = [
              {
                empName: data[0].employee,
                overtimerisk: data[0].otHrs,
                workedhours: data[0].actualHrs,
                remainingschedule: data[0].scheduleHrs,
                remainingshifts: data[0].scheduleHrs,
              },
            ];
            this.setState({
              rightPanelRowTop: rightPanelTop,
              rightPanelRowBottom: data,
            });
            this.setState({ loaderRightTrue: false });
            resolve();
          } else {
            this.setState({ loaderRightTrue: false });
            this.setState({
              rightPanelRowTop: [],
              rightPanelRowBottom: [],
            });
          }
        }
      })
      .catch((error) => {
        reject();
        this.setState({ loaderRightTrue: false });
      });
  };

  leftBulkSelect = () => {
    this.setState({
      leftTableClass: "left-body cus4rowTableOverTimeWithCheckBox",
      isBulkSelect: true,
      isLeftFooter: true,
      leftPanelColumns: [],
    });
  };

  handleBulkCheckChange = (event: any, row: any, rowIndex) => {
    event.stopPropagation();
    let isChecked = event.target.checked;
    let data;
    bulkSelectRow.push(row);
    data = this.state.tableData.map((items) => {
      if (items.id === row.id) {
        return { ...items, isChecked: true };
      } else {
        return items;
      }
    });
    if (!isChecked) {
      data = this.state.tableData.map((items) => {
        if (items.id === row.id) {
          return { ...items, isChecked: false };
        } else {
          return items;
        }
      });
      bulkSelectRow = bulkSelectRow.filter((item) => item.id !== row.id);
    }
    bulkSelectCount = bulkSelectRow.length;
    let dataLen = this.state.tableData.filter((items) => !items.isAccepted);
    let checkTrue;
    if (bulkSelectCount === dataLen.length) {
      checkTrue = true;
    } else {
      checkTrue = false;
    }
    this.setState({ checkEnable: checkTrue, tableData: data });
    //this.setState((curr: any) => ({ ...curr, tableData: tableData }));
  };

  LeftPanelBulkDiscard = () => {
    bulkSelectRow = [];
    this.state.tableData.map((items) => {
      if (items.isChecked) {
        return { ...items, isChecked: false };
      } else {
        return items;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        isLeftFooter: false,
        leftTableClass: "left-body cus4rowTableOverTime",
        isBulkSelect: false,
      }),
      () => {
        bulkSelectCount = 0;
      }
    );
  };

  handleLeftPanelDiscard = () => {
    bulkSelectRow = [];
    let { tableData } = this.state;
    tableData = tableData.map((items) => {
      if (items.isChecked) {
        return { ...items, isChecked: false };
      } else {
        return items;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        checkEnable: false,
        isLeftFooter: false,
        leftTableClass: "left-body cus4rowTableOverTime",
        isBulkSelect: false,
        tableData: tableData,
      }),
      () => {
        bulkSelectCount = 0;
      }
    );
  };

  openBulkAcceptModalForReason = () => {
    if (typeof bulkSelectRow !== "undefined" && bulkSelectRow.length > 0) {
      this.setState({ bulkRiskAccept: true });
    } else {
      Utils.toastError("Please Select atleast one user.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "overTimeRiskToast",
      });
    }
  };

  bulkAcceptRiskModalClose = () => {
    this.handleLeftPanelDiscard();
    bulkSelectRow = [];
    this.setState({
      bulkRiskAccept: false,
      isLeftFooter: false,
      otarBulkValue: "",
      otarBulkError: "",
    });
    bulkSelectCount = 0;
  };

  dotsSVG = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      </svg>
    );
  };

  croseSVG = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
          fill="#84888C"
        />
      </svg>
    );
  };

  resetPopupData = (time) => {
    this.setState({ resetedDate: time });
    this.setState({ edtOTRstartTime: time.inTime24Hrs });
    this.setState({ edtOTRendTime: time.outTime24Hrs });
  };

  editOTRStarttime = (e) => {
    this.setState({ edtOTRstartTime: e.target.value });
  };

  editOTREndtime = (e) => {
    this.setState({ edtOTRendTime: e.target.value });
  };

  formatDate(time) {
    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    let replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  }

  editOTRRequestSubmit = (e: any) => {
    let { resetedDate, edtOTRstartTime, edtOTRendTime, otarcountRowDetails } =
      this.state;
    e.preventDefault();
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let iDate = resetedDate.date;
    let yr = iDate.split("T")[0].split("-")[0];
    let mh = iDate.split("T")[0].split("-")[1];
    let dt = iDate.split("T")[0].split("-")[2];
    let fullDate = mh + "/" + dt + "/" + yr;
    let request: any = {};

    request.employee = resetedDate.employee;
    request.tenantID = localStorageUser.tenantID;
    request.hotelID = resetedDate.hid;
    request.scheduleNo = resetedDate.scheduleNo;
    request.date = fullDate;
    request.inTime = this.formatDate(edtOTRstartTime);
    request.outTime = this.formatDate(edtOTRendTime);
    request.InDate = fullDate + " " + resetedDate.inTime24Hrs;

    Hotel.EditOTARScheduleData(request)
      .then(async (result: any) => {
        let data = result.result;
        if (result.success) {
          if (data.result === "Success") {
            toast.success("Requested time has been saved successfully.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "Success1",
            });
            $(".tableHasDropdown.show").trigger("click");
            this.getRightPanelData();
            this.getLeftPanelData();
            this.refotarPanelCount();
            this.props.cusToastMessage(otarcountRowDetails.otarCount);
          } else if (data === "overlape") {
            Utils.toastError("Schedule overlap, Unable to Update.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "overlape1",
            });
          } else if (data === "punchin") {
            Utils.toastError("User punched in, Unable to update.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "punchin1",
            });
          }
        } else {
          Utils.toastError("Error" + result.result, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "punchedin",
          });
        }
      })
      .catch((error) => {
        reject();
        this.setState({ loaderRightTrue: false });
      });
  };

  RemoveOTAREntry = (shiftItems) => {
    let { otarcountRowDetails } = this.state;
    let message:string = "Are you sure you want to delete this Entry?";
    
    if(shiftItems.isMPOR === "Yes"){
      message = 'Removing this shift will result in unassigning the rooms asssigned to this employee.\n \n Are you sure you want to delete this Entry?'
    }

    confirmAlert({
      title: "Confirm to Delete",
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let scheduleNo = parseInt(shiftItems.scheduleNo);
            Hotel.DeleteScheduleShiftRow(scheduleNo)
              .then(async (result) => {
                let data = result.result;
                if (result.success) {
                  if (data.toLowerCase() === "overlape") {
                    Utils.toastError("Schedule overlap, Unable to Update.", {
                      // // position: toast.POSITION.BOTTOM_RIGHT,
                      // // containerId: "overlape2",
                    });
                  } else if (data.toLowerCase() === "punchin") {
                    Utils.toastError("Schedule punched in, Unable to update.", {
                      // // position: toast.POSITION.BOTTOM_RIGHT,
                      // // containerId: "punchin2",
                    });
                  } else if (data.toLowerCase() === "success") {
                    toast.success("Schedule has been deleted successfully.", {
                      // // position: toast.POSITION.BOTTOM_RIGHT,
                      // // containerId: "success2",
                    });
                    this.getLeftPanelData();
                    this.getRightPanelData();
                    this.refotarPanelCount();
                    this.props.cusToastMessage(otarcountRowDetails.otarCount);
                  }
                }
              })
              .catch((error) => {
                reject();
                this.setState({ loaderRightTrue: false });
              });
          },
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
    });
  };
  sortByRiskAscDesc() {
    let tableData = [...this.state.tableData];
    if (this.state.sortingOrder === "Ascending") {
      tableData = tableData.sort(
        (a, b) => parseFloat(a.otHrs) - parseFloat(b.otHrs)
      );
    } else {
      tableData = tableData.sort(
        (a, b) => parseFloat(b.otHrs) - parseFloat(a.otHrs)
      );
    }
    this.setState({ tableData });
  }
  sortingOrderChange = (item: any) => {
    let { sortingOrder } = this.state;
    sortingOrder = item;
    this.setState({ sortingOrder, tableData: this.state.tableData }, () => {
      this.sortByRiskAscDesc();
    });
  };

  openScheduleSlideOut = () => {
    this.setState({ isScheduleSlideOut: true });
  };

  closeScheduleSlideOut = () => {
    this.setState({ isScheduleSlideOut: false });
  };

  openTimesheetSlideOut = () => {
    this.setState({ isTimesheetSlideOut: true });
  };

  closeTimesheetSlideOut = () => {
    this.setState({ isTimesheetSlideOut: false });
  };

  otarValidate = (value) => {
    if (value === "") {
      this.setState({ otarError: "Please provide an explanation." });
    } else if (value.length > 200) {
      this.setState({ otarError: "Maximum 200 characters allowed." });
    } else {
      return value;
    }
  };

  otarBulkValidate = (value) => {
    if (value === "") {
      this.setState({ otarBulkError: "Please provide an explanation." });
    } else if (value.length > 200) {
      this.setState({ otarBulkError: "Maximum 200 characters allowed." });
    } else {
      return value;
    }
  };

  otarBulkValChange = (event: any) => {
    let { value } = event.target;
    this.setState({ otarBulkValue: value.trimStart() });
  };

  otarValChange = (event: any) => {
    let { value } = event.target;
    this.setState({ otarValue: value.trimStart() });
  };

  nextSelectedRow = (row: any) => {
    let data: any;
    if (newSearchResult.length > 0) {
      data = newSearchResult;
    } else {
      data = this.state.tableData;
    }

    data = this.state.tableData;
    for (let i = 0; i < data.length; i++) {
      let rowIndex: number;
      if (data[i].id === row.id) {
        if (data.length - 1 > i) {
          rowIndex = i + 1;
          if (data[rowIndex].isAccepted === false) {
            this.setState(
              {
                activeClassRow: data[rowIndex].id,
              },
              () => {
                this.leftSelectedRow(data[rowIndex]);
              }
            );
          } else {
            for (let y = 0; y < data.length; y++) {
              if (data[y].isAccepted === false) {
                this.setState(
                  {
                    activeClassRow: data[y].id,
                  },
                  () => {
                    this.leftSelectedRow(data[y]);
                  }
                );
                break;
              } else {
                let blankRow: any = {};
                blankRow.employee = "";
                this.setState({ activeClassRow: "" }, () => {
                  this.leftSelectedRow(blankRow);
                });
              }
            }
          }
        } else {
          for (let x = 0; x < data.length; x++) {
            if (data[x].isAccepted === false) {
              this.setState(
                {
                  activeClassRow: data[x].id,
                },
                () => {
                  this.leftSelectedRow(data[x]);
                }
              );
              break;
            } else {
              let blankRow: any = {};
              blankRow.employee = "";
              this.setState({ activeClassRow: "" }, () => {
                this.leftSelectedRow(blankRow);
              });
            }
          }
        }
      }
    }
  };

  leftSelectedRow = (row: any) => {
    if (Object.keys(row).length !== 0) {
      this.eventRowData(row);
    } else {
      this.setState({ rightPanelRowTop: [], rightPanelRowBottom: [] });
    }
  };

  otarSubmit = (event: any) => {
    let {
      rowEventData,
      otarValue,
      rightPanelRowBottom,
      tableData,
      otarcountRowDetails,
    } = this.state;
    event.preventDefault();
    let acceptHrs;
    let userName;
    let hotelID;
    if (
      typeof rightPanelRowBottom !== "undefined" &&
      rightPanelRowBottom.length > 0
    ) {
      acceptHrs = rightPanelRowBottom[0].otHrs;
      userName = rightPanelRowBottom[0].employee;
      hotelID = rightPanelRowBottom[0].hid;
    }
    if (this.otarValidate(otarValue)) {
      this.setState({ otarError: "" });
      let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
      tableData = tableData.map((items) => {
        if (items.id === rowEventData.id) {
          return { ...items, isAccepted: true };
        } else {
          return items;
        }
      });
      let request: any = {};
      request.tenantID = localStorageUser.tenantID;
      request.hotelID = hotelID;
      request.userName = userName;
      request.acceptHrs = acceptHrs;
      request.comments = otarValue;
      request.createdBy = localStorageUser.userName;
      this.LeftPanelBulkDiscard();
      Hotel.AcceptOTARData(request)
        .then(async (result) => {
          let data = result.result;
          if (result.success) {
            if (data[0].result.toLowerCase() === "success") {
              toast.success("Risk accepted successfully.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "sriskSccess",
              });
              this.setState(
                (curr: any) => ({
                  ...curr,
                  tableData: tableData,
                  rightPanelRowTop: [],
                  rightPanelRowBottom: [],
                  otarValue: "",
                }),
                () => {
                  this.nextSelectedRow(rowEventData);
                  this.refotarPanelCount();
                  this.props.cusToastMessage(otarcountRowDetails.otarCount);
                  this.CleanSearch();
                }
              );
            } else {
              Utils.toastError(data[0].result, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "riskError",
              });
            }
          }
        })
        .catch((error) => {
          reject();
          this.setState({ loaderRightTrue: false });
        });
    }
  };

  otarBulkSubmit = (event: any) => {
    event.preventDefault();
    let { otarBulkValue, tableData, otarcountRowDetails, checkEnable } =
      this.state;
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let createdBy = localStorageUser.userName;
    let acceptHrs: any = [];
    let userNames: any = [];
    let request: any = {};
    let hotelID =
      otarcountRowDetails === undefined ? 0 : otarcountRowDetails.hid;
    if (this.otarBulkValidate(otarBulkValue)) {
      this.setState({ otarBulkError: "" });
      if (typeof bulkSelectRow !== "undefined" && bulkSelectRow.length > 0) {
        bulkSelectRow.forEach((bitems) => {
          tableData = tableData.map((items) => {
            if (items.id === bitems.id) {
              return { ...items, isAccepted: true };
            } else {
              return items;
            }
          });
          this.setState(
            (curr: any) => ({
              ...curr,
              tableData: tableData,
              rightPanelRowTop: [],
              rightPanelRowBottom: [],
            }),
            () => {
              this.nextSelectedRow(bitems);
              this.refotarPanelCount();
              this.props.cusToastMessage(otarcountRowDetails.otarCount);
            }
          );
          acceptHrs.push(bitems.otHrs);
          userNames.push(bitems.employee);
          request.tenantID = localStorageUser.tenantID;
          request.hotelID = hotelID;
          request.userName = userNames.toString();
          request.acceptHrs = acceptHrs.toString();
          request.comments = otarBulkValue;
          request.createdBy = createdBy;
        });
        Hotel.AcceptOTARData(request)
          .then(async (result) => {
            let data = result.result;
            if (result.success) {
              if (data[0].result.toLowerCase() === "success") {
                toast.success("Risk accepted successfully.", {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                  // // containerId: "riskSuccess2",
                });
                // this.bulkAcceptRiskModalClose();
                // this.setState({ isLeftFooter: false });
                // bulkSelectRow = [];
                // this.LeftPanelBulkDiscard();
                this.setState({
                  bulkRiskAccept: false,
                  isLeftFooter: false,
                  otarBulkValue: "",
                  otarBulkError: "",
                });
                this.CleanSearch();
                bulkSelectRow = [];
                bulkSelectCount = 0;
                this.props.cusToastMessage(otarcountRowDetails.otarCount);
                this.refotarPanelCount();
                if (checkEnable) {
                  this.clearSession();
                }
              } else {
                Utils.toastError(data[0].result, {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                  // // containerId: "riskError2",
                });
              }
            }
          })
          .catch((error) => {
            reject();
            this.setState({ loaderRightTrue: false });
          });
      }
    }
  };

  otarSplitShiftOpen = (items) => {
    this.getlaborPositionDeptByemployee();
    this.setState({ otgSplitShift: true, shiftItems: items });
  };

  otgSplitShiftClose = () => {
    this.setState({
      otgSplitShift: false,
      shiftItems: {},
      splitNotes: "",
      splitShiftError: "",
      splitTimeVal: "",
      splitDepartmentID: "",
      splitPositionID: "",
      splitDepartment: "Select",
      splitPosition: "Select",
    });
  };

  otarAcceptClose = () => {
    this.setState({ otarError: "", otarValue: "" });
  };

  splitTimeChange = (e) => {
    this.setState({ splitTimeVal: e.target.value });
  };

  noteSplitChange = (e: any) => {
    let { value } = e.target;
    this.setState({ splitNotes: value.trimStart() });
  };

  timeConvert = (time) => {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  };

  convertTime12to24 = (time12h) => {
    let time24 = "";
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    let ii =
      hours === "12"
        ? (hours = "00")
        : modifier === "PM" || modifier === "pm"
        ? (hours = parseInt(hours, 10) + 12)
        : "";
    time24 = `${hours}:${minutes}`;
    return time24;
  };

  submitSplitShift = (e) => {
    let { shiftItems, splitTimeVal } = this.state;
    e.preventDefault();

    let iDate = shiftItems.date;
    let yr = iDate.split("T")[0].split("-")[0];
    let mh = iDate.split("T")[0].split("-")[1];
    let dt = iDate.split("T")[0].split("-")[2];
    let fullDate = mh + "/" + dt + "/" + yr;

    let splitDepartment = this.state.splitDepartmentID;
    let splitPosition = this.state.splitPositionID;
    let splitNotes = this.state.splitNotes;
    let scheduleNo = shiftItems.scheduleNo;

    let st = shiftItems.inTime24Hrs;
    let et = shiftItems.outTime24Hrs;
    let gt = splitTimeVal;

    if (gt === "") {
      this.setState({ splitShiftError: "Please enter split time" });
    } else if (this.convertTime12to24(gt) < this.convertTime12to24(st)) {
      this.setState({
        splitShiftError: "Please provide a valid time duration.",
      });
    } else if (this.convertTime12to24(gt) > this.convertTime12to24(et)) {
      this.setState({
        splitShiftError: "Please provide a valid time duration.",
      });
    } else if (
      this.timeConvert(gt) === this.timeConvert(st) ||
      this.timeConvert(gt) === this.timeConvert(et)
    ) {
      this.setState({ splitShiftError: "Actual time not accepted." });
    } else {
      this.setState({ splitShiftError: "" });

      this.setState({ splitShiftError: "" });
      let request: any = {};
      request.scheduleNo = scheduleNo;
      request.deptID = splitDepartment;
      request.position = splitPosition;
      request.splitTime = this.formatDate(gt);
      request.SCHInDateTime = fullDate + " " + shiftItems.inTime24Hrs;
      request.notes = splitNotes;
      //return;
      Hotel.SplitScheduleShiftData(request)
        .then(async (result) => {
          let data = result.result;
          if (result.success) {
            if (data === "Success") {
              toast.success("Shift has been split successfully.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "success",
              });
              this.getRightPanelData();
              this.getLeftPanelData();
              this.otgSplitShiftClose();
            } else if (data === "punchedin") {
              Utils.toastError("User already punched in, Unable to split Shift.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "punchedin",
              });
            }
          } else {
            Utils.toastError("Error" + result.result, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "punchedin",
            });
          }
        })
        .catch((error) => {
          reject();
          this.setState({ loaderRightTrue: false });
        });
    }
  };

  showReassignModal = (e, value) => {
    
    let { rightPanelRowBottom } = this.state;
    let reassignModalRow: any = {};
    reassignModalRow.employeeName = value.employee;
    let reassignModalSchedule: any = {};
    reassignModalSchedule.shiftDate = value.date;
    reassignModalSchedule.shiftInTime = value.inTime;
    reassignModalSchedule.shiftOutTime = value.outTime;
    reassignModalSchedule.positionId = value.empCode;
    reassignModalSchedule.shiftPosition = value.empTitle;
    if(value.isMPOR === "Yes"){
      rightPanelRowBottom.forEach((items: any) => {
        if (items.id === value.id) {
          items.reassignWarning = true;
        }
      });
    }else{
      rightPanelRowBottom.forEach((items: any) => {
        if (items.id === value.id) {
          //items.reassignWarning = false;
          items.isAssigned = true;
        }
      });
    }
    this.setState({
      reassignModalRow,
      reassignModalSchedule,
      rightPanelRowBottom,
    });
  };

  reassignWarningSubmit = (value, mode) => {
    if (mode) {
      let { rightPanelRowBottom } = this.state;
      let reassignModalRow: any = {};
      reassignModalRow.employeeName = value.employee;
      let reassignModalSchedule: any = {};
      reassignModalSchedule.shiftDate = value.date;
      reassignModalSchedule.shiftInTime = value.inTime;
      reassignModalSchedule.shiftOutTime = value.outTime;
      reassignModalSchedule.positionId = value.empCode;
      reassignModalSchedule.shiftPosition = value.empTitle;
      rightPanelRowBottom.forEach((items: any) => {
        if (items.id === value.id) {
          items.reassignWarning = false;
          items.isAssigned = true;
        }
      });
      this.setState({
        reassignModalRow,
        reassignModalSchedule,
        rightPanelRowBottom,
      });
    } else {
      this.reassignOutsideClick(value);
    }
  };

  reassignOutsideClick = (value) => {
    let { rightPanelRowBottom } = this.state;
    rightPanelRowBottom.forEach((items: any) => {
      if (items.id === value.id) {
        items.isAssigned = false;
        items.reassignWarning = false;
      }
    });
    this.setState({
      rightPanelRowBottom,
      reassignModalRow: {},
      reassignModalSchedule: {},
    });
  };

  reassignShcedule = (row: any, schedule: any, item: any, rowitems: any) => {
    let { otarcountRowDetails, navigationCalenderDates } = this.state;
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let hotelID =
      otarcountRowDetails === undefined ? 0 : otarcountRowDetails.hid;
    let iDate = item.date;
    let yr = iDate.split("T")[0].split("-")[0].substr(2);
    let mh = iDate.split("T")[0].split("-")[1];
    let dt = iDate.split("T")[0].split("-")[2];
    let fullDate = mh + "/" + dt + "/" + yr;
    let reassignRequest = {} as ILaborScheduleScheduleDragRequestDto;
    reassignRequest.tenantID = localStorageUser.tenantID;
    reassignRequest.deptId = item.deptID.toString();
    reassignRequest.positionId = item.empCode.toString();
    reassignRequest.Uniqueno = item.scheduleNo.toString();
    reassignRequest.date = fullDate;
    reassignRequest.inDate = item.date.split("T")[0] + " " + item.inTime24Hrs;
    reassignRequest.outDate = item.date.split("T")[0] + " " + item.outTime24Hrs;
    reassignRequest.inTime = schedule.shiftInTime;
    reassignRequest.outTime = schedule.shiftOutTime;
    reassignRequest.isAssignedStatus = "Yes";
    reassignRequest.employeeName = localStorageUser.userName;
    reassignRequest.hotelID = hotelID;
    reassignRequest.payPeriodStartDate = navigationCalenderDates[0];
    reassignRequest.entryType = "Schedule";
    reassignRequest.isAssignedShift = "Yes";
    reassignRequest.isPublishShift =
      schedule.shiftType === "published" ||
      schedule.shiftType === "hideShowPublished"
        ? "Yes"
        : "No";
    reassignRequest.offRepeat = "No";
    reassignRequest.ptoType = -1;
    reassignRequest.dropShiptEmployee = rowitems.employee;
    reassignRequest.dropShiptDate = fullDate;
    reassignRequest.viewType = "Departments";
    let requestArray: any = [];
    requestArray.push(reassignRequest);
    LaborSchedule.SaveLaborScheduleDragAndDropDetails(requestArray)
      .then(async (result: any | null) => {
        if (result != null && result.saveStatus === "Success") {
          //this.getScheduleData("reasignSingleShift");
          let assignedValue: any = "reassigned";
          // if (row.shiftType === "UnAssigned") {
          //   assignedValue = "assigned";
          // }
          toast.success(`Shift ${assignedValue} to ${rowitems.employee}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
          this.clearSession();
          this.getLeftPanelData();
          this.getRightPanelData();
          this.refotarPanelCount();
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderRightTrue: false });
      });
  };

  /*******Search*****/
  searchedData = (newResult: any) => {
    if (newResult.length > 0) {
      let newRowIndex = 0;
      this.setState({ isFirstRowSelected: true });
      for (let i = 0; i < newResult.length; i++) {
        if (newResult[i].action === false) {
          newRowIndex = i;
          break;
        }
      }
      this.setState({ activeClassRow: newResult[newRowIndex].uniqueno });
      if (newResult.length > 0) {
        this.leftSelectedRow(newResult[newRowIndex]);
      }
    } else {
      this.setState({ activeClassRow: "" });
      this.leftSelectedRow(blankRow);
    }
    newSearchResult = newResult;
  };
  CleanSearch = () => {
    if (this.child.current != null) {
      this.child.current.CleanSearch();
    }
  };
  /*******Search*****/

  isSlideOutShow() {
    let { rightPanelRowBottom, otarcountRowDetails } = this.state;
    let newItem: any = {};
    newItem.disputeStatus = "Disputed";
    newItem.hotelID = rightPanelRowBottom[0].hid;
    newItem.hotelName = otarcountRowDetails.lettercode;
    newItem.userUniqueno = rightPanelRowBottom[0].scheduleNo;
    newItem.userName = rightPanelRowBottom[0].employee;
    newItem.payPeriod = this.state.navigationCalenderDates[0];
    newItem.date = this.state.navigationCalenderDates[0];
    newItem.ruleType = "Standard Rule";
    newItem.imagePath = "";
    newItem.position = rightPanelRowBottom[0].empTitle;
    newItem.fullDate = this.state.navigationCalenderDates;
    this.setState({ slideOutData: newItem, isSlideOutShow: true });
  }

  hideSlideOut(data: any) {
    this.setState({ isSlideOutShow: false });
  }

  handleOutsideClick() {
    this.setState({ isSlideOutShow: false });
  }
  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="text-center">
        This is calculated basis actuals
        <br /> for current week till yesterday.
      </div>
    </Tooltip>
  );

  render() {
    let {
      tableData,
      otgSplitShift,
      otarcountRowDetails,
      rightPanelRowTop,
      rightPanelRowBottom,
      rowEventData,
      navigationCalenderDates,
    } = this.state;
    countColumns = [
      {
        dataField: "letterCode",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="hotelDetailsData">
            <span className="hotelLetterAbbr">{row.lettercode}</span>
            <EllipsisWithTooltip placement="bottom">
              <span className="hotelFullName ">
                {row.pname}
              </span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "roomTotal",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdCountData">
            <span className="smileIdCount">{row.otarCount}</span>
            <span className="smileIdLabel">OTAR</span>
          </div>
        ),
      },
      {
        dataField: "OTHrs",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdCountData">
            <span className="smileIdCount">
              {row.otHrs.toString().includes(".")
                ? row.otHrs.toString().replace(/\./g, ":")
                : row.otHrs.toString() + ":00"}
            </span>
            <span className="smileIdLabel">HH:MM</span>
          </div>
        ),
      },
      {
        dataField: "action",
        text: "",

        // formatter: (cell: any, row: any, rowIndex: any) => (
        //   <div>
        //     <Dropdown className="more-action" alignRight onClick={(e) => {e.stopPropagation();}} >
        //       <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">{this.trippleDots()}</Dropdown.Toggle>
        //       <Dropdown.Menu>
        //         <Dropdown.Item eventKey={"Dummy Text"}>Lorem ipsum.</Dropdown.Item>
        //       </Dropdown.Menu>
        //     </Dropdown>
        //   </div>
        // ),
      },
    ];
    leftPanelColumns = [
      {
        dataField: "checkbox",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div
              className="checkBoxBulkSelectOTR"
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <span className="bulkSelectBox">
                <label className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={row.id}
                    onChange={(e: any) =>
                      this.handleBulkCheckChange(e, row, rowIndex)
                    }
                    checked={row.isChecked}
                  />
                  <span></span>
                </label>
              </span>
            </div>
          );
        },
      },
      {
        dataField: "employee",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdEmpDetails">
            <span className="title">
              <span className="text-capitalize">
                <EllipsisWithTooltip placement="bottom">
                  {row.employee}
                </EllipsisWithTooltip>
              </span>
            </span>
            <span className="desc textCamelCase">
              <EllipsisWithTooltip placement="bottom">
                {row.empTitle}
              </EllipsisWithTooltip>
            </span>
          </div>
        ),
      },
      {
        dataField: "Accepted",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.isAccepted && (
                <div className="red-badges green-bg">Accepted</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "otarHour",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="overTimeCountLeft">
            <span className="count text-danger">
              {row.otHrs.toString().includes(".")
                ? row.otHrs.toString().replace(/\./g, ":")
                : row.otHrs.toString() + ":00"}
            </span>
            <br />
            <span className="desc">Overtime at Risk</span>
          </div>
        ),
      },
      {
        dataField: "BulkSelect",
        text: "",
        hidden: bulkHidden,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            className="overTimeBulkSelect"
            // onClick={(e: any) => {
            //   e.stopPropagation();
            // }}
          >
            <div className="hasDropDown">
              <Dropdown className="more-action" alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="BulkSelect"
                  style={{ borderColor: "transparent" }}
                >
                  {this.dotsSVG()}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.leftBulkSelect}>
                    Bulk Select
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        ),
      },
    ];

    let hotelID =
      otarcountRowDetails === undefined ? 0 : otarcountRowDetails.hid;
    const rowClasses = (row: any, rowIndex: number) => {
      if (row.id === Number(this.state.activeClassRow)) {
        return "active-row";
      } else if (row.isAccepted) {
        return "acceptRisk";
      } else {
        return;
      }
    };
    let hidValue = this.props.parentState.hidValue === "All";
    return (
      <>
        <div className="overTimeMain">
          {/* <ToastContainer containerId="overTimeRiskToast" autoClose={3000} /> */}
          <>
            <div className="budget-settings smileIdOverview otarOverView">
              {this.state.tableData.length > 0 && (
                <>
                  {hidValue && (
                    <>
                      <div className="smileIDOverviewActionHeader">
                        <span className="smileIDBreadCrumbs">
                          <span onClick={this.allHotelBreadCrumb}>
                            {" "}
                            &laquo; All Properties{" "}
                          </span>
                        </span>
                      </div>
                      <h1 className={`${tableData.length > 0 && "border-0"}`}>
                        {this.props.parentState.hotelName === "All"
                          ? this.state.otarcountRowDetails.lettercode
                          : this.props.parentState.hotelName}{" "}
                        Overtime at Risk
                      </h1>
                    </>
                  )}
                </>
              )}
              {this.state.showotrPanel && hidValue && (
                <div className="smileIdOverviewTable">
                  {this.state.loaderCountTrue ? (
                    <ReactPageLoader useas={"hotelsListOTAR"} />
                  ) : (
                    <>
                      <h1>Overtime at Risk</h1>
                      <BootstrapTable
                        keyField={this.state.otarTableData.map(
                          (items, index) => `id_` + items.id
                        )}
                        data={this.state.otarTableData}
                        columns={countColumns}
                        rowEvents={this.countRowEvents}
                        hover
                      />
                    </>
                  )}
                </div>
              )}

              {tableData.length > 0 ? (
                <>
                  {hidValue === false && (
                    <h1 className={`${tableData.length > 0 && "border-0"}`}>
                      {this.props.parentState.hotelName === "All"
                        ? this.state.otarcountRowDetails.lettercode
                        : this.props.parentState.hotelName}{" "}
                      Overtime at Risk
                    </h1>
                  )}
                  <div className="budget-settings d-flex justify-content-start otarMainPage">
                    <div className="left-panel">
                      <div className="smileid-left-panel">
                        <div className="">
                          <ToolkitProvider
                            keyField={tableData.map(
                              (items) => items.scheduleNo
                            )}
                            data={tableData}
                            columns={leftPanelColumns}
                            rowEvents={this.LeftPanelRowEvents}
                            search={{
                              afterSearch: (newResult: any) => {
                                this.searchedData(newResult);
                              },
                            }}
                          >
                            {(props: {
                              searchProps: JSX.IntrinsicAttributes;
                              baseProps: JSX.IntrinsicAttributes;
                            }) => (
                              <React.Fragment>
                                <div className="search-header d-flex">
                                  <TableSearch
                                    id={tableData.map(
                                      (items) => items.scheduleNo
                                    )}
                                    key={tableData.map(
                                      (items) => items.scheduleNo
                                    )}
                                    ref={this.child}
                                    {...props.searchProps}
                                    CleanSearch={this.CleanSearch.bind(this)}
                                    placeholder={"Search..."}
                                  />
                                  <div className="navigationSort ml-auto">
                                    <Dropdown
                                      className="more-action bg-blue"
                                      alignRight
                                    >
                                      <Dropdown.Toggle
                                        className=""
                                        id="sortingOrder"
                                      >
                                        <svg
                                          width="16"
                                          height="12"
                                          viewBox="0 0 16 12"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M3.5 12L6.5 9H4.25V0H2.75V9H0.5L3.5 12ZM7.25 3H14V4.5H7.25V3ZM7.25 6H12.5V7.5H7.25V6ZM7.25 0H15.5V1.5H7.25V0ZM7.25 9H11V10.5H7.25V9Z" />
                                        </svg>

                                        {this.state.sortingOrder}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Header>SORT</Dropdown.Header>
                                        {this.state.sortingOrderList.map(
                                          (item: any, idx: any) => (
                                            <Dropdown.Item
                                              key={idx}
                                              className={
                                                this.state.sortingOrder === item
                                                  ? "active"
                                                  : ""
                                              }
                                              eventKey={item}
                                              onClick={() =>
                                                this.sortingOrderChange(item)
                                              }
                                            >
                                              <div className="d-flex">
                                                <div className="mr-auto">
                                                  {" "}
                                                  {item}
                                                </div>
                                                <svg
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 18 18"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                                    fill="#2CAF92"
                                                  />
                                                </svg>
                                              </div>
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>

                                <div className={this.state.leftTableClass}>
                                  <div className="list">
                                    <BootstrapTable
                                      {...props.baseProps}
                                      id="tableid_"
                                      keyField={`bootstrapTable_${tableData.map(
                                        (items) => items.id
                                      )}`}
                                      rowEvents={this.LeftPanelRowEvents}
                                      hover
                                      condensed
                                      rowClasses={rowClasses}
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                          {this.state.isLeftFooter && (
                            <div className="fixed-action-overTimeRisk">
                              <div className="d-flex align-content-center flex-wrap">
                                <div className="mr-auto message">
                                  <span className="bulkSelectBox">
                                    <label className="form-check">
                                      <input
                                        className="formCheckInput"
                                        type="checkbox"
                                        disabled
                                        ref={(input) => {
                                          input &&
                                            (input.indeterminate =
                                              !this.state.checkEnable);
                                        }}
                                        checked={this.state.checkEnable}
                                      />
                                      <span></span>
                                    </label>
                                  </span>
                                  <span className="bulkSelectCount">
                                    &nbsp; {bulkSelectCount} Selected
                                  </span>
                                  <span className="acceptRiskButtonDiv">
                                    <button
                                      type="button"
                                      disabled={this.state.isSaveButtonDisable}
                                      className="btn btn-primary acceptRiskButton"
                                      onClick={
                                        this.openBulkAcceptModalForReason
                                      }
                                    >
                                      Accept Risk
                                    </button>
                                  </span>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-discard overTimeBulkDiscard"
                                  onClick={this.handleLeftPanelDiscard}
                                >
                                  {this.croseSVG()}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="right-panel">
                      {rightPanelRowTop.length > 0 ? (
                        <div className="">
                          <div className="pos-header d-flex align-content-center flex-wrap">
                            <div className="pos-ttl mr-auto cusRightFull">
                              <React.Fragment>
                                <EllipsisWithTooltip placement="bottom">
                                  {typeof rightPanelRowTop !== "undefined" &&
                                  rightPanelRowTop.length > 0 ? (
                                    <span className="text-capitalize">
                                      {rightPanelRowTop[0].empName}
                                    </span>
                                  ) : null}
                                </EllipsisWithTooltip>
                                {typeof rightPanelRowTop !== "undefined" &&
                                rightPanelRowTop.length > 0 ? (
                                  <div className="userPTOprofile">
                                    <div className="PTObtnGroup">
                                      <ButtonGroup aria-label="userapprove">
                                        <OutsideClickHandler
                                          onOutsideClick={this.otarAcceptClose}
                                        >
                                          <Dropdown
                                            className="otrBtnPanel btnApprove hasForm mr-2"
                                            alignRight
                                          >
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                            >
                                              <div>Accept Risk</div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Form
                                                noValidate
                                                onSubmit={this.otarSubmit}
                                              >
                                                <div className="dropDownHeader minTop10">
                                                  <label>
                                                    Accept Overtime Risk
                                                  </label>
                                                  <Dropdown.Item
                                                    className="xClose"
                                                    title="Close"
                                                    onClick={
                                                      this.otarAcceptClose
                                                    }
                                                  >
                                                    {this.croseSVG()}
                                                  </Dropdown.Item>
                                                </div>
                                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                                  <div className="clearfix">
                                                    <Form.Label>
                                                      Explanation
                                                    </Form.Label>
                                                  </div>
                                                  <Form.Control
                                                    as="textarea"
                                                    placeholder="Describe the acceptance"
                                                    name="decline"
                                                    value={this.state.otarValue}
                                                    onChange={
                                                      this.otarValChange
                                                    }
                                                    rows={4}
                                                    required
                                                  />
                                                  <span className="small text-danger">
                                                    {this.state.otarError}
                                                  </span>
                                                </Form.Group>
                                                <Form.Group className="text-right seprator">
                                                  <Dropdown.Item
                                                    onClick={
                                                      this.otarAcceptClose
                                                    }
                                                  >
                                                    Cancel
                                                  </Dropdown.Item>
                                                  <Button
                                                    variant="success"
                                                    type="submit"
                                                  >
                                                    Submit
                                                  </Button>
                                                </Form.Group>
                                              </Form>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </OutsideClickHandler>
                                        {/* <Dropdown className="more-action hasPrimaryBorder" alignRight >
                                        <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropDownMore">
                                          {this.dotsSVG()}
                                        </Dropdown.Toggle>
                                      </Dropdown> */}
                                      </ButtonGroup>
                                    </div>
                                  </div>
                                ) : null}
                              </React.Fragment>
                            </div>
                          </div>
                          <div className="pos-body pos-rel">
                            {/*!this.state.loaderRightTrue && ()*/}
                            <>
                              {typeof rightPanelRowTop !== "undefined" &&
                                rightPanelRowTop.length > 0 && (
                                  <React.Fragment>
                                    <div className="form-group mb-0 row">
                                      <label className="form-label col-form-label col-sm-4 timeoffLabel">
                                        Overtime at Risk{" "}
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={this.renderTooltip}
                                        >
                                          <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            version="1.1"
                                            viewBox="0 0 16 16"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                                            <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                                            <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                                          </svg>
                                        </OverlayTrigger>
                                      </label>

                                      <div className="col-sm-8">
                                        <div className="form-control-plaintext fortwoline">
                                          <strong className="secondLine">
                                            <div className="cusptoflexPanel">
                                              <div className="cusflexgrid">
                                                <div className="col2 forTextBtn">
                                                  <span className="cusDangerBtn hasInfo">
                                                    {rightPanelRowTop[0].overtimerisk
                                                      .toString()
                                                      .includes(".")
                                                      ? rightPanelRowTop[0].overtimerisk
                                                          .toString()
                                                          .replace(/\./g, ":")
                                                      : rightPanelRowTop[0].overtimerisk.toString() +
                                                        ":00"}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </strong>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group mb-0 row">
                                      <label className="form-label col-form-label col-sm-4 timeoffLabel">
                                        Hours Worked
                                      </label>
                                      <div className="col-sm-8">
                                        <div className="form-control-plaintext fortwoline">
                                          <span className="secondLine">
                                            <span className="fixW">
                                              {rightPanelRowTop[0].workedhours
                                                .toString()
                                                .includes(".")
                                                ? rightPanelRowTop[0].workedhours
                                                    .toString()
                                                    .replace(/\./g, ":")
                                                : rightPanelRowTop[0].workedhours.toString() +
                                                  ":00"}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group mb-0 row">
                                      <label className="form-label col-form-label col-sm-4 timeoffLabel">
                                        Remaining Schedule
                                      </label>
                                      <div className="col-sm-8">
                                        <div className="form-control-plaintext fortwoline">
                                          <span className="secondLine">
                                            <span className="fixW">
                                              {rightPanelRowTop[0].remainingschedule
                                                .toString()
                                                .includes(".")
                                                ? rightPanelRowTop[0].remainingschedule
                                                    .toString()
                                                    .replace(/\./g, ":")
                                                : rightPanelRowTop[0].remainingschedule.toString() +
                                                  ":00"}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="form-group mb-0 row"
                                      style={{ paddingTop: 10 }}
                                    >
                                      <label className="form-label col-form-label col-sm-3 timeoffLabel">
                                        <strong
                                          onClick={() => this.isSlideOutShow()}
                                          className="timeoffLink"
                                        >
                                          View Timesheet
                                        </strong>
                                      </label>
                                      <label className="form-label col-form-label col-sm-4 timeoffLabel">
                                        <strong
                                          onClick={() =>
                                            this.openScheduleSlideOut()
                                          }
                                          className="timeoffLink"
                                        >
                                          View Position Schedule
                                        </strong>
                                      </label>
                                    </div>

                                    <hr />
                                    <div className="formGroup row mb-2">
                                      <label className="form-label col-form-label timeoffLabel labelheader">
                                        Remaining Shifts
                                      </label>
                                    </div>

                                    <div className="cusPTOGridTable cusPTOtable">
                                      <Table responsive className="tbl1">
                                        <thead>
                                          <tr className="bg-light">
                                            <th style={{ width: "10%" }}>
                                              Date
                                            </th>
                                            <th
                                              className="pl-4"
                                              style={{ width: "10%" }}
                                            >
                                              Start
                                            </th>
                                            <th
                                              className="pl-4"
                                              style={{ width: "10%" }}
                                            >
                                              End
                                            </th>
                                            <th
                                              className="text-center"
                                              style={{ width: "10%" }}
                                            >
                                              Duration
                                            </th>
                                            <th style={{ width: "20%" }}>
                                              Position
                                            </th>
                                            <th style={{ width: "20%" }}></th>
                                            <th style={{ width: "10%" }}></th>
                                            <th style={{ width: "10%" }}></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {rightPanelRowBottom.map(
                                            (tOffRDT, index) => {
                                              let itemsDate = tOffRDT.date;
                                              let year = itemsDate
                                                .split("T")[0]
                                                .split("-")[0]
                                                .substring(2);
                                              let month = itemsDate
                                                .split("T")[0]
                                                .split("-")[1];
                                              let date = itemsDate
                                                .split("T")[0]
                                                .split("-")[2];
                                              let fullDate =
                                                month + "/" + date + "/" + year;
                                              return (
                                                <tr
                                                  id={"id_" + tOffRDT.id}
                                                  key={tOffRDT.id}
                                                >
                                                  <td className="otarTdNoPadd">
                                                    <div className="tableLockSymbol">
                                                      <span>{fullDate}</span>
                                                    </div>
                                                  </td>
                                                  <td className="otarTdNoPadd">
                                                    {tOffRDT.mainUnique ===
                                                    0 ? (
                                                      <Dropdown
                                                        alignRight
                                                        className="tableHasDropdown"
                                                      >
                                                        <Dropdown.Toggle
                                                          className="btnHasNoPadd"
                                                          id={
                                                            "endTime_" +
                                                            tOffRDT.inTime.replace(
                                                              " ",
                                                              "_"
                                                            )
                                                          }
                                                        >
                                                          <div
                                                            className="btnHandler mxW"
                                                            onClick={() =>
                                                              this.resetPopupData(
                                                                tOffRDT
                                                              )
                                                            }
                                                          >
                                                            {tOffRDT.inTime}
                                                          </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                          <form
                                                            onSubmit={
                                                              this
                                                                .editOTRRequestSubmit
                                                            }
                                                          >
                                                            <div className="dropDownHasForm">
                                                              <div className="dropDownHeader">
                                                                <label>
                                                                  Edit Scheduled
                                                                  In
                                                                </label>
                                                                <Dropdown.Item className="xClose">
                                                                  {this.croseSVG()}
                                                                </Dropdown.Item>
                                                              </div>
                                                              <div className="dropDownBody">
                                                                <input
                                                                  type="time"
                                                                  name="endTime"
                                                                  value={
                                                                    this.state
                                                                      .edtOTRstartTime
                                                                  }
                                                                  onChange={
                                                                    this
                                                                      .editOTRStarttime
                                                                  }
                                                                  className="form-control"
                                                                  required
                                                                />
                                                              </div>
                                                              <div className="dropDownFooter">
                                                                <Button type="submit">
                                                                  Save
                                                                </Button>
                                                              </div>
                                                            </div>
                                                          </form>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    ) : (
                                                      <div className="noMainUnique">
                                                        {tOffRDT.inTime}
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="otarTdNoPadd">
                                                    {tOffRDT.mainUnique ===
                                                    0 ? (
                                                      <Dropdown
                                                        alignRight
                                                        className="tableHasDropdown"
                                                      >
                                                        <Dropdown.Toggle
                                                          className="btnHasNoPadd"
                                                          id={
                                                            "endTime_" +
                                                            tOffRDT.outTime.replace(
                                                              " ",
                                                              "_"
                                                            )
                                                          }
                                                        >
                                                          <div
                                                            className="btnHandler mxW"
                                                            onClick={() =>
                                                              this.resetPopupData(
                                                                tOffRDT
                                                              )
                                                            }
                                                          >
                                                            {tOffRDT.outTime}
                                                          </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                          <form
                                                            onSubmit={
                                                              this
                                                                .editOTRRequestSubmit
                                                            }
                                                          >
                                                            <div className="dropDownHasForm">
                                                              <div className="dropDownHeader">
                                                                <label>
                                                                  Edit Scheduled
                                                                  Out
                                                                </label>
                                                                <Dropdown.Item className="xClose">
                                                                  {this.croseSVG()}
                                                                </Dropdown.Item>
                                                              </div>
                                                              <div className="dropDownBody">
                                                                <input
                                                                  type="time"
                                                                  name="endTime"
                                                                  value={
                                                                    this.state
                                                                      .edtOTRendTime
                                                                  }
                                                                  onChange={
                                                                    this
                                                                      .editOTREndtime
                                                                  }
                                                                  className="form-control"
                                                                  required
                                                                />
                                                              </div>
                                                              <div className="dropDownFooter">
                                                                <Button type="submit">
                                                                  Save
                                                                </Button>
                                                              </div>
                                                            </div>
                                                          </form>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    ) : (
                                                      <div className="noMainUnique">
                                                        {tOffRDT.outTime}
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="otarTdNoPadd text-center">
                                                    {tOffRDT.hrs
                                                      .toString()
                                                      .includes(".")
                                                      ? tOffRDT.hrs
                                                          .toString()
                                                          .replace(/\./g, ":")
                                                      : tOffRDT.hrs.toString() +
                                                        ":00"}
                                                  </td>
                                                  <td className="otarTdNoPadd">
                                                    <div className="otarPosition">
                                                      <EllipsisWithTooltip placement="bottom">
                                                        {tOffRDT.empTitle}
                                                      </EllipsisWithTooltip>
                                                    </div>
                                                  </td>
                                                  <td className="otarTdNoPadd otarStatus">
                                                    {tOffRDT.isOTR && (
                                                      <strong className="cusDangerBtn">
                                                        OTAR
                                                      </strong>
                                                    )}
                                                  </td>
                                                  <td className="otarTdNoPadd otrReassignBox">
                                                    {tOffRDT.mainUnique ===
                                                      0 && (
                                                      <>
                                                        <div className="assign-shift sos">
                                                          {/* <strong>Reassign</strong> */}
                                                          
                                                          <Button
                                                            className="unassign btn-outline-primary btn-ghost"
                                                            onClick={(e) =>
                                                              this.showReassignModal(
                                                                e,
                                                                tOffRDT
                                                              )
                                                            }
                                                            // className={
                                                            //   this.state.mainUnique
                                                            //     ? "unassign btn-outline-primary btn-ghost active"
                                                            //     : "unassign btn-outline-primary btn-ghost"
                                                            // }
                                                          >
                                                            Reassign
                                                          </Button>
                                                          
                                                            <Dropdown
                                                              className="single-select-image"
                                                              show
                                                              alignRight
                                                              >
                                                              {tOffRDT.reassignWarning && (
                                                              <Dropdown.Menu>
                                                                <OutsideClickHandler
                                                                  onOutsideClick={(
                                                                    e
                                                                  ) =>
                                                                    this.reassignOutsideClick(
                                                                      tOffRDT
                                                                    )
                                                                  }
                                                                >
                                                                  <ConfirmationModal 
                                                                    confirmModalContent={scheduleComponents.confirmationInfo(
                                                                      "singleShift",
                                                                      tOffRDT,
                                                                      {},
                                                                      "moprAssignAlert"
                                                                    )}
                                                                    ishandleDeletePosition={this.reassignWarningSubmit.bind(
                                                                      this,
                                                                      tOffRDT
                                                                    )}
                                                                  />
                                                                </OutsideClickHandler>
                                                              </Dropdown.Menu>
                                                          )}
                                                          {tOffRDT.isAssigned && (
                                                            // <Dropdown
                                                            //   show
                                                            //   className="single-select-image tableHasAsignDropdown"
                                                            // >
                                                              <Dropdown.Menu
                                                                show
                                                              >
                                                                <div className="single-select-image dropdownItemPaddingRemove">
                                                                  <div className="showReassignShiftList">
                                                                    <OutsideClickHandler
                                                                      onOutsideClick={(
                                                                        e
                                                                      ) =>
                                                                        this.reassignOutsideClick(
                                                                          tOffRDT
                                                                        )
                                                                      }
                                                                    >
                                                                      {tOffRDT.isAssigned && (
                                                                        <ShiftReassignPopUp
                                                                          schedule={
                                                                            this
                                                                              .state
                                                                              .reassignModalSchedule
                                                                          }
                                                                          row={
                                                                            this
                                                                              .state
                                                                              .reassignModalRow
                                                                          }
                                                                          parentState={
                                                                            this
                                                                              .state
                                                                          }
                                                                          hidValue={
                                                                            hotelID
                                                                          }
                                                                          handleSelectedItem={this.reassignShcedule.bind(
                                                                            this,
                                                                            this
                                                                              .state
                                                                              .reassignModalRow,
                                                                            this
                                                                              .state
                                                                              .reassignModalSchedule,
                                                                            tOffRDT
                                                                          )}
                                                                        />
                                                                      )}
                                                                    </OutsideClickHandler>
                                                                  </div>
                                                                </div>
                                                              </Dropdown.Menu>
                                                            // </Dropdown>
                                                          )}
                                                            </Dropdown>

                                                          

                                                        </div>
                                                      </>
                                                    )}
                                                  </td>
                                                  <td className="otarTdNoPadd text-right">
                                                    {tOffRDT.mainUnique ===
                                                      0 && (
                                                      <Dropdown
                                                        alignRight
                                                        className="OTRtabledd tableHasDropdown tableRightDropdown"
                                                      >
                                                        <Dropdown.Toggle
                                                          className="btn btn-primary"
                                                          id="RemoveSplit"
                                                        >
                                                          {this.dotsSVG()}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="rightDrop">
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              this.RemoveOTAREntry(
                                                                tOffRDT
                                                              )
                                                            }
                                                          >
                                                            Remove
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                            onClick={() =>
                                                              this.otarSplitShiftOpen(
                                                                tOffRDT
                                                              )
                                                            }
                                                          >
                                                            Split
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>

                                        <tfoot>
                                          <tr>
                                            <th colSpan={3}>Totals</th>
                                            <th className="text-center">
                                              {
                                                rightPanelRowTop[0]
                                                  .remainingschedule
                                              }
                                            </th>
                                            <th colSpan={4}></th>
                                          </tr>
                                        </tfoot>
                                      </Table>
                                    </div>
                                  </React.Fragment>
                                )}
                            </>

                            <OutsideClickHandler
                              onOutsideClick={this.otgSplitShiftClose}
                            >
                              {otgSplitShift && (
                                <div className="hasOtgAssignModal">
                                  <div className="otrAssignModal">
                                    <div className="otrModalTitle d-flex align-items-center">
                                      <div className="mr-auto">Split Shift</div>
                                      <Dropdown.Item
                                        href=""
                                        onClick={this.otgSplitShiftClose}
                                      >
                                        {this.croseSVG()}
                                      </Dropdown.Item>
                                    </div>
                                    <Form
                                      noValidate
                                      onSubmit={this.submitSplitShift}
                                    >
                                      <div className="otrModalContainer">
                                        <div className="otrModalBody">
                                          <Form.Group
                                            as={Row}
                                            controlId="time-picker1"
                                          >
                                            <Col sm="6">
                                              <Form.Label>
                                                Split Time
                                              </Form.Label>
                                              <input
                                                type="time"
                                                value={this.state.splitTimeVal}
                                                name="appt"
                                                className="html-time-picker"
                                                onChange={(e: any) =>
                                                  this.splitTimeChange(e)
                                                }
                                                required
                                              />
                                            </Col>
                                          </Form.Group>

                                          <Form.Group
                                            as={Row}
                                            controlId="statistic"
                                          >
                                            <Col sm="6">
                                              <Form.Label>
                                                Department
                                              </Form.Label>
                                              <Dropdown className="otgCusInput">
                                                <Dropdown.Toggle id="dropdown-department">
                                                  <EllipsisWithTooltip placement="bottom">
                                                    {this.state.splitDepartment}
                                                  </EllipsisWithTooltip>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  {this.state.alldepartmentlist.map(
                                                    (item: any, index: any) => (
                                                      <Dropdown.Item
                                                        className={`${
                                                          item.departmentName ===
                                                            this.state
                                                              .splitDepartment &&
                                                          "bg-light"
                                                        }`}
                                                        key={
                                                          item.departmentName
                                                        }
                                                        eventKey={
                                                          item.departmentName
                                                        }
                                                        onClick={(event: any) =>
                                                          this.departmentSplitChange(
                                                            event,
                                                            item,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <EllipsisWithTooltip placement="bottom">
                                                          {item.departmentName}
                                                        </EllipsisWithTooltip>
                                                      </Dropdown.Item>
                                                    )
                                                  )}
                                                </Dropdown.Menu>
                                              </Dropdown>
                                              <input
                                                type="text"
                                                className="textHidden"
                                                name="department"
                                                value={
                                                  this.state.splitDepartment ===
                                                  "Select"
                                                    ? ""
                                                    : this.state
                                                        .splitDepartmentID
                                                }
                                                placeholder={
                                                  this.state.splitDepartment ===
                                                  "Select"
                                                    ? "Select"
                                                    : ""
                                                }
                                                required
                                                autoComplete="Off"
                                              />
                                            </Col>
                                            <Col sm="6">
                                              <Form.Label>Position</Form.Label>
                                              <Dropdown className="otgCusInput">
                                                <Dropdown.Toggle id="dropdown-position">
                                                  <EllipsisWithTooltip placement="bottom">
                                                    {this.state.splitPosition}
                                                  </EllipsisWithTooltip>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  {this.state.allpositionlist.map(
                                                    (item: any, index: any) => (
                                                      <Dropdown.Item
                                                        className={`${
                                                          item.positionName ===
                                                            this.state
                                                              .splitPosition &&
                                                          "bg-light"
                                                        }`}
                                                        key={item.positionName}
                                                        eventKey={
                                                          item.positionName
                                                        }
                                                        onClick={(event: any) =>
                                                          this.positionSplitChange(
                                                            event,
                                                            item,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <EllipsisWithTooltip placement="bottom">
                                                          {item.positionName}
                                                        </EllipsisWithTooltip>
                                                      </Dropdown.Item>
                                                    )
                                                  )}
                                                </Dropdown.Menu>
                                              </Dropdown>
                                              <input
                                                type="text"
                                                className="textHidden"
                                                name="position"
                                                value={
                                                  this.state.splitPosition ===
                                                  "Select"
                                                    ? ""
                                                    : this.state.splitPositionID
                                                }
                                                placeholder={
                                                  this.state.splitPosition ===
                                                  "Select"
                                                    ? "Select"
                                                    : ""
                                                }
                                                required
                                                autoComplete="Off"
                                              />
                                            </Col>
                                          </Form.Group>

                                          <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Notes</Form.Label>
                                            <Form.Control
                                              as="textarea"
                                              rows={2}
                                              placeholder="Why are you splitting the shift..."
                                              value={this.state.splitNotes}
                                              onChange={this.noteSplitChange}
                                              required
                                            />
                                          </Form.Group>

                                          <span className="text-danger">
                                            {this.state.splitShiftError}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="otrModalFooter text-right">
                                        <Button
                                          type="submit"
                                          className="btn btn-primary"
                                        >
                                          Split Shift
                                        </Button>
                                      </div>
                                    </Form>
                                  </div>
                                </div>
                              )}
                            </OutsideClickHandler>
                          </div>
                        </div>
                      ) : (
                        this.state.loaderRightTrue && (
                          <ReactPageLoader useas={"rightPanelOTAR"} />
                        )
                      )}
                    </div>
                  </div>
                </>
              ) : (
                this.state.OTARPanelLoader && (
                  <ReactPageLoader useas={"OTARPanel"} />
                )
              )}
            </div>

            {/*=========================== Accept Bulk Request Modal =================================*/}
            <Modal
              className="save-template-modal"
              show={this.state.bulkRiskAccept}
              onHide={this.bulkAcceptRiskModalClose}
              aria-labelledby="contained-modal-title-vcenter"
              animation={true}
              centered
              keyboard={false}
              backdrop="static"
            >
              <OutsideClickHandler
                onOutsideClick={this.bulkAcceptRiskModalClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Accept Overtime Risk</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={this.otarBulkSubmit}>
                  <Modal.Body>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <div className="clearfix">
                        <Form.Label>Reason</Form.Label>
                      </div>
                      <Form.Control
                        as="textarea"
                        placeholder="Describe the acceptance"
                        name="decline"
                        value={this.state.otarBulkValue}
                        onChange={this.otarBulkValChange}
                        rows={4}
                        required
                      />
                      <span className="text-danger">
                        {this.state.otarBulkError}
                      </span>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer className="bg-light">
                    <Button type="submit">Accept Risk</Button>
                  </Modal.Footer>
                </Form>
              </OutsideClickHandler>
            </Modal>
            {/*=========================== Accept Bulk Request Modal =================================*/}
          </>
          {this.state.isScheduleSlideOut && (
            <OtrScheduleSlideOut
              date={navigationCalenderDates}
              hotel={otarcountRowDetails}
              rowEventData={rowEventData}
              rightPanel={rightPanelRowBottom}
              closeSlideOut={this.closeScheduleSlideOut}
            />
          )}
          {this.state.isSlideOutShow && (
            <OtrTimeSheetSlideOut
              data={this.state.slideOutData}
              isOTAR={this.state.isSlideOutShow}
              hideSlideOut={this.hideSlideOut.bind(this)}
            />
          )}
        </div>
      </>
    );
  }
}
