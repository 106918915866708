import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { Hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

export class SingleBusinessAccountSelector extends React.Component<any, any> {
  private inputElement: any;
  private SingleselectChild: any;
  private singleSerch: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      hotelNameModal: "Select EHID", //Dispaly on account
      filterData: [],
      hotelList: [],
      inputValue: "",
    };
    this.handleChangeEhid = this.handleChangeEhid.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);
  }

  componentDidMount() {
    let getUserHotelAccess: any;
    const { reportMasterId, moduleReportId } = this.props;
    if (this.props?.isAll) {
      if (this.props?.notIncludeEnterpriseAccounts) {
        getUserHotelAccess = Hotel.getUserHotelAccessAllByModuleName(
          true,
          this.props?.showOnlyHotelAccounts,
          this.props?.payrollSubscriptionRequired
        );
      } else {
        getUserHotelAccess = Hotel.getUserHotelAccessAllByModuleName(
          false,
          this.props?.showOnlyHotelAccounts,
          this.props?.payrollSubscriptionRequired
        );
      }
    } else {
      if (this.props?.notIncludeEnterpriseAccounts) {
        if (reportMasterId === 185) {
          getUserHotelAccess = Hotel.getUserHotelAccessReport(
            "",
            true,
            this.props?.showOnlyHotelAccounts,
            reportMasterId,
            this.props?.payrollSubscriptionRequired
          );
        } else {
          getUserHotelAccess = Hotel.getUserHotelAccessByModuleName(
            "",
            true,
            this.props?.showOnlyHotelAccounts
          );
        }
      } else {
        if (
          moduleReportId === 2088 ||
          moduleReportId === 2158 ||
          moduleReportId === 2128
        ) {
          getUserHotelAccess = Hotel.getUserHotelAccessReport(
            "",
            false,
            this.props?.showOnlyHotelAccounts,
            185,
            this.props?.payrollSubscriptionRequired
          );
        } else {
          getUserHotelAccess = Hotel.getUserHotelAccessByModuleName(
            "",
            false,
            this.props?.showOnlyHotelAccounts
          );
        }
      }
    }
    getUserHotelAccess
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null && result.length > 0) {
          let hotelList: any[] = [];
          result.forEach((element) => {
            let newItem = {} as any;
            newItem.hotelID = element.hotelID;
            newItem.value = element.lettercode;
            newItem.description = element.hotelName;
            newItem.hotelType = element.hotelType;
            newItem.timeZone = element.timeZone;
            newItem.isChecked = false;
            newItem.timeZone = element.timeZone;
            hotelList.push(newItem);
          });
          this.setState({ filterData: hotelList, hotelList: hotelList });
          const { defaultValue } = this.props;

          if (+defaultValue) {
            const hotel = hotelList.find((r) => +r.hotelID === +defaultValue);
            if (
              this.props?.rdlcName === "ProjectExpensesCoa.rdlc" ||
              this.props?.rdlcName === "VendorHistoryReportVer02.rdlc" ||
              this.props?.rdlcName === "ProjectExpensesVendor2.rdlc"
            ) {
            } else {
              this.props.isHotelid(hotel);
            }
            this.hidSelectModal(hotel?.description);
          }
        }
      })
      .catch((error) => {
        // Need to handle this
        console.log(error);
      });
  }

  handleChangeEhid(e: { target: { value: string } }) {
    const updatedList = this.state.hotelList.filter((item) => {
      return (
        item.description.toLowerCase().indexOf(e.target.value.toLowerCase()) >
        -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  handleDropdownChange(e: any) {
    this.props.isHotelid(e);
    this.resetSerchBox();
  }

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.hotelList });
  };
  componentDidUpdate = (prevProps: any) => {
    const { defaultValue } = this.props;
    if (!defaultValue && prevProps.defaultValue !== defaultValue) {
      this.hidSelectModal("Select EHID");
    }
  };

  hidSelectModal = (eventKey: any) => {
    this.setState({ inputValue: "" });
    this.setState({ hotelNameModal: eventKey });
  };

  render() {
    const onFocusInput = (eventKey: any) => {
      setTimeout(() => {
        this.inputElement?.current?.focus();
      }, 100);
    };

    return (
      <Dropdown
        className="single-select"
        onSelect={this.hidSelectModal}
        onClick={onFocusInput}
      >
        <Dropdown.Toggle
          id={this.props?.ddlId ? this.props?.ddlId : "Modal-hid"}
        >
          {this.state.hotelNameModal === "Select Group" && (
            <span className="placeholder">{this.state.hotelNameModal}</span>
          )}
          {this.state.hotelNameModal !== "Select Group" && (
            <EllipsisWithTooltip placement="bottom">
              <span
                style={{
                  width: "95%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                }}
              >
                {this.state.hotelNameModal}
              </span>
            </EllipsisWithTooltip>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="search-sec">
            <input
              type="text"
              className="form-control"
              onChange={this.handleChangeEhid}
              placeholder="Filter Hotel"
              value={this.state.inputValue}
              ref={this.inputElement}
            />
          </div>
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (
              <EllipsisWithTooltip placement="bottom">
                <Dropdown.Item
                  className={
                    this.state.filterData[idx - 1]?.hotelType &&
                    this.state.filterData[idx - 1]?.hotelType !==
                      item?.hotelType
                      ? "hotel-separator"
                      : ""
                  }
                  eventKey={item.description}
                  onClick={() => this.handleDropdownChange(item)}
                >
                  {item.description}
                </Dropdown.Item>
              </EllipsisWithTooltip>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
