import React from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import {
  ILogHistoryProps,
  ILogHistoryState,
  ICurrentFilter,
} from "../../../../../Common/Contracts/IScheduleLogHistory";
import { Schedule } from "../../../../../Common/Services/Schedule";
import {
  IScheduleChangeLogResponse,
  IScheduleChangeLogRequest,
} from "../../../../../Common/Contracts/ISchedule";
import { ChangeLogs } from "../../../../../Common/Components/ChangeLogs/ChangeLogs";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Utils } from "../../../../../Common/Utilis";

export class ScheduleLogHistory extends React.Component<
ILogHistoryProps,
  ILogHistoryState
> {
  private pageName: string = "ScheduleLogHistory";
  state = {
    tableData: [],
    filteredData: [],
    tableColumns: [],
    title: "Schedule History",
    keyFieldName: "uniqueno",
    isDataLoading: false,
    scheduleId: this.props.shiftId ? this.props.shiftId : 0,
    isDrillDownView: false,
    filterColumns: [],
    filterPopUps: [],
    filterPopUpsCopy: [],
    showFilterPopUp: false,
    popClassName: "",
    currentFilter: {
      filterName: "",
      filterValue: "",
      filterKeyName: "",
      filterInputType: "",
    },
    tableClassName: "schedule-log-table",
    startDate: this.props.payPeriodStartDate,
    endDate: this.props.payPeriodEndDate,
    pageSize: 20,
    currentPageNo: 1,
    totalDataLength: 0,
    drillDownShiftDate: this.props?.shiftDate ||  "",
    loader:false
  };

  hoverOutEllipsis = () => {
    $(".tooltip")?.removeClass("show");
    $(".tooltip")?.removeClass("fade");
};

  cellTooltipFormatter = (cell: any, row: any, rowIndex: any) => {
    const text = cell?.toString();
    let substr = "";
    if (text?.length > 15) {
      substr = `${text.substr(0, 15)}...`;
    }
    return (
      <>
        {" "}
        {text?.length > 15 ? (
          <div onWheel={() => this.hoverOutEllipsis()}>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id={"PositionName"}>{cell}</Tooltip>}
            >
              <span>{substr}</span>
            </OverlayTrigger>
          </div>
        ) : (
          <div>{cell}</div>
        )}
      </>
    );
  };

  cellTooltipPositionFormatter = (cell: any, row: any, rowIndex: any) => {
    const text = cell?.toString();
    let substr = "";
    if (text?.length > 11) {
      substr = `${text.substr(0, 11)}...`;
    }
    return (
      <>
        {" "}
        {text?.length > 11 ? (
          <div onWheel={() => this.hoverOutEllipsis()}>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id={"PositionName"}>{cell}</Tooltip>}
            >
              <span>{substr}</span>
            </OverlayTrigger>
          </div>
        ) : (
          <div>{cell}</div>
        )}
      </>
    );
  };

  tableColumns = [
    {
      dataField: "uniqueno",
      text: "Shift",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "editor",
      text: "Editor",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "editDateTime",
      text: "Edit Date & Time",
      filterInputType: "date",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div>{cell}</div>
          </>
        );
      },
    },
    {
      dataField: "employee",
      text: "Username",
      filterInputType: "text",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "position",
      text: "Position",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipPositionFormatter,
    },
    {
      dataField: "shiftDate",
      text: "Shift Date",
      filterInputType: "date",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div>{cell}</div>
          </>
        );
      },
    },
    {
      dataField: "fieldname",
      text: "Field",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div>{cell}</div>
          </>
        );
      },
    },
    {
      dataField: "oldValue",
      text: "Old Value",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "newValue",
      text: "New Value",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "triggerName",
      text: "Trigger",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div>{cell}</div>
          </>
        );
      },
    },
    {
      dataField: "",
      text: "",
      filterInputType: "",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div
              onClick={() => this.onShiftClick(row.uniqueno, row?.shiftDate)}
            >
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.625 3.2H11.375V4.8H2.625V3.2ZM0 0H14V1.6H0V0ZM5.25 6.4H8.75V8H5.25V6.4Z" />
              </svg>
            </div>
          </>
        );
      },
    },
  ];

  componentDidMount = () => {
    this.buildDefaultData();
  };

  buildDefaultData = () => {
    const filterColumns = this.tableColumns
      .filter((item) => item.text)
      .map((item) => ({
        text: item.text,
        key: item.dataField,
        isFilterApplied: item?.dataField === "shiftDate" ? true : false,
        filterInputType: item?.filterInputType,
      }));

    const { startDate, endDate } = this.state;
    if (!this.props.shiftId) {
      const currentFilter: ICurrentFilter = {
        filterName: "Shift Date",
        filterValue: `${startDate} - ${endDate}`,
        filterKeyName: "shiftDate",
        filterInputType: "date",
        popClassName: "filter-edit-popup-0",
      };
      this.setState({
        filterPopUps: [currentFilter],
        currentFilter,
        filterColumns,
        tableColumns: this.tableColumns,
      });
    } else {
      this.setState({
        filterColumns,
        tableColumns: this.tableColumns,
        scheduleId: this.props.shiftId,
      });
    }

    const { shiftId } = this.props;
    if (+shiftId > 0) {
      this.onShiftClick(+shiftId, this.props.shiftDate);
    } else {
      this.getChangeLogData(true);
    }
  };

  validateDates = () => {
    const currentFilter: any = { ...this.state.currentFilter };
    if (
      currentFilter.filterKeyName === "editDateTime" ||
      currentFilter.filterKeyName === "shiftDate"
    ) {
      const { startDate, endDate } = this.state;

      if (!endDate) {
        Utils.toastError("Please enter End Date.", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
      } else if (!startDate) {
        Utils.toastError("Please enter Start Date.", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  updateFilters = () => {
    const currentFilter: any = { ...this.state.currentFilter };
    let filterPopUps: any[] = [...this.state.filterPopUps];
    const filterIndex = filterPopUps.findIndex(
      (filter) => filter?.filterName === currentFilter?.filterName
    );
    if (
      (currentFilter.filterKeyName === "editDateTime" ||
        currentFilter.filterKeyName === "shiftDate") &&
      !currentFilter.filterValue
    ) {
      currentFilter.filterValue = `${this.props.payPeriodStartDate} - ${this.props.payPeriodEndDate}`;
    }
    if (filterIndex < 0) {
      filterPopUps.push(currentFilter);
    } else {
      filterPopUps[filterIndex] = currentFilter;
    }
    filterPopUps = filterPopUps?.filter((item) => item?.filterValue);
    filterPopUps = filterPopUps.map((item, index) => {
      item.popClassName = "filter-edit-popup-" + index;
      return item;
    });

    this.setState({ filterPopUps, currentPageNo: 1 }, () => {
      this.getChangeLogData();
      this.updateFilterColumns();
    });
  };

  updateFilterColumns = () => {
    const { filterColumns: columns, filterPopUps } = this.state;
   
    const filteredKeys = filterPopUps?.map(
      (filter: ICurrentFilter) => filter?.filterKeyName
    );

    const filterColumns = columns.map((item: any) => {
      if (filteredKeys.find((key) => key === item?.key)) {
        item.isFilterApplied = true;
      } else {
        item.isFilterApplied = false;
      }
      return item;
    });

    this.setState({ filterColumns });
  };

  onFilterTextChange = (event: any) => {
    const currentFilter: any = { ...this.state.currentFilter };
    currentFilter.filterValue = event.currentTarget?.value;
    this.setState({ currentFilter });
  };
  onFilterChange = (filterItem: any, popClassName): void => {
    const filterPopUps: any[] = [...this.state.filterPopUps];
    let currentFilter: ICurrentFilter = this.state.currentFilter;

    if (filterPopUps.length >= 5) {
      Utils.toastError("You can't apply more than 5 filters!", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    if (
      filterPopUps.length >= 5 ||
      filterPopUps.filter((filter) => filter?.filterName === filterItem.text)
        ?.length > 0
    ) {
      return;
    }
    currentFilter = {
      filterName: filterItem?.text,
      filterValue: "",
      filterKeyName: filterItem.key,
      filterInputType: filterItem?.filterInputType,
      popClassName: popClassName,
    };
    this.setState({
      currentFilter,
      currentPageNo: 1,
      showFilterPopUp: true,
      popClassName,
      startDate: this.props.payPeriodStartDate,
      endDate: this.props.payPeriodEndDate,
    });
  };

  removeFilter = (filterName: string): void => {
    let filterPopUps: any[] = [...this.state.filterPopUps];
    if (filterPopUps.length === 0) {
      return;
    }
    filterPopUps = filterPopUps.filter(
      (filter) => filter?.filterName !== filterName
    );
    if (filterPopUps.length > 0) {
      filterPopUps = filterPopUps.map((item, index) => {
        item.popClassName = "filter-edit-popup-" + index;
        return item;
      });
    }
    if (+this.state.scheduleId > 0) {
      this.setState({ tableClassName: "schedule-log-table" });
      this.returnBackFromDrillDown();
    } else {
      this.setState({ filterPopUps, currentPageNo: 1 }, () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      });
    }
  };

  hideFilterPopUp = () => {
    if (this.state.showFilterPopUp) {
      this.setState({
        showFilterPopUp: false,
        popClassName: "",
      });
    }
  };

  editFilter = (currentFilter: ICurrentFilter, popClassName) => {
    if (currentFilter.filterInputType === "date") {
      const datesArray = currentFilter.filterValue?.split("-");
      this.setState({
        currentFilter,
        popClassName,
        showFilterPopUp: true,
        startDate: datesArray[0]?.trim(),
        endDate: datesArray[1]?.trim(),
      });
    } else {
      this.setState({ currentFilter, popClassName, showFilterPopUp: true });
    }
  };

  returnBackFromDrillDown = () => {
    this.setState(
      {
        scheduleId: 0,
        currentPageNo: 1,
        isDrillDownView: false,
        filterPopUps: this.state.filterPopUpsCopy,
      },
      () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      }
    );
  };

  onShiftClick = (scheduleId, shiftDate) => {
    this.setState(
      {
        tableClassName: "schedule-log-table-drilldown",
        currentPageNo: 1,
        drillDownShiftDate: shiftDate,
        scheduleId,
        isDrillDownView: true,
        filterPopUpsCopy:
          +this.props.shiftId > 0 ? [] : [...this.state.filterPopUps],
        tableColumns: this.tableColumns.filter(
          (column: any) => column?.dataField
        ),
        filterPopUps: [
          {
            filterName: "Shift",
            filterValue: scheduleId,
            filterKeyName: "uniqueno",
            popClassName: "",
            filterInputType: "",
          },
        ],
      },
      () => this.getScheduleChangeLogByScheduleId()
    );
  };

  onPaginationchange = () => {
    const currentPageNo = this.state.currentPageNo + 1;
    this.setState({ currentPageNo }, () => {
      if (this.state.isDrillDownView) {
        this.getScheduleChangeLogByScheduleId(true);
      } else {
        this.getChangeLogData(false, true);
      }
    });
  };

  getChangeLogData = (isDefaultLoad = false, isPagination = false) => {
    if (!isPagination) {
      this.setState({
        tableData: [],
        filteredData: [],
        tableColumns: this.tableColumns,
      });
    } else {
      this.setState({
        tableColumns: this.tableColumns,
      });
    }
    this.toggleDataLoading();
    const {  filterPopUps, pageSize, currentPageNo } = this.state;
    const request: IScheduleChangeLogRequest = {
      shiftfromdate: "",
      shifttodate: "",
      auditfromdate: "",
      audittodate: "",
      tenantId: 0,
      hotelId: this.props.hotelId,
      uniqueno: "",
      editor: "",
      username: "",
      position: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
      uniquenoCheckStrict: false,
      pageNo: isPagination ? currentPageNo : 1,
      pageSize: pageSize,
    } as IScheduleChangeLogRequest;

    //if (isDefaultLoad) {
      request.shiftfromdate = this.props.payPeriodStartDate;
      request.shifttodate = this.props.payPeriodEndDate;
   // }

    filterPopUps.forEach((item: ICurrentFilter) => {
      if (item.filterKeyName === "shiftDate") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.shiftfromdate = datesArray[0];
        request.shifttodate = datesArray[1];
      } else if (item.filterKeyName === "editDateTime") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.auditfromdate = datesArray[0];
        request.audittodate = datesArray[1];
      } else if (item.filterKeyName === "uniqueno") {
        request.uniqueno = item.filterValue?.trim();
      } else if (item.filterKeyName === "editor") {
        request.editor = item.filterValue;
      } else if (item.filterKeyName === "employee") {
        request.username = item.filterValue;
      } else if (item.filterKeyName === "position") {
        request.position = item.filterValue;
      } else if (item.filterKeyName === "fieldname") {
        request.fieldName = item.filterValue;
      } else if (item.filterKeyName === "oldValue") {
        request.oldValue = item.filterValue;
      } else if (item.filterKeyName === "newValue") {
        request.newValue = item.filterValue;
      } else if (item.filterKeyName === "triggerName") {
        request.triggerName = item.filterValue;
      }
    });

   
    Schedule.GetScheduleChangeLog(request)
      .then((tableData: IScheduleChangeLogResponse[] | null) => {
        tableData = tableData?.map((item) => {
          item.editDateTime = moment(item.editDateTime).format(
            "MM/DD/YYYY hh:mm A"
          );
          item.shiftDate = moment(item.shiftDate).format("MM/DD/YYYY");

          return item;
        }) as IScheduleChangeLogResponse[] | null;
        if (isPagination) {
          tableData = [
            ...this.state.tableData,
            ...(tableData as IScheduleChangeLogResponse[]),
          ];
        }

        const totalDataLength = tableData?.length
          ? tableData[0]?.totalCount
          : 0;
        this.setState({
          tableData: tableData,
          filteredData: tableData,
          totalDataLength,
        });
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => this.toggleDataLoading());
  };

  getFilteredData = () => {
    this.onFiltersChange();
  };

  getScheduleChangeLogByScheduleId = (isPagination = false) => {
    this.toggleDataLoading();
    const {
      scheduleId,
      drillDownShiftDate,
      pageSize,
      currentPageNo,
    } = this.state;

    if (!isPagination) {
      this.setState({
        tableData: [],
        filteredData: [],
      });
    }

    const request: IScheduleChangeLogRequest = {
      shiftfromdate: drillDownShiftDate,
      shifttodate: drillDownShiftDate,
      auditfromdate: drillDownShiftDate,
      audittodate: "",
      tenantId: 0,
      hotelId: this.props.hotelId,
      uniqueno: scheduleId?.toString(),
      editor: "",
      username: "",
      position: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
      uniquenoCheckStrict: true,
      pageNo: currentPageNo,
      pageSize: pageSize,
    } as IScheduleChangeLogRequest;

    Schedule.GetScheduleChangeLog(request)
      .then((tableData: IScheduleChangeLogResponse[] | null) => {
        tableData = tableData?.map((item) => {
          item.editDateTime = moment(item.editDateTime).format(
            "MM/DD/YYYY hh:mm A"
          );
          item.shiftDate = moment(item.shiftDate).format("MM/DD/YYYY");
          return item;
        }) as IScheduleChangeLogResponse[] | null;

        if (isPagination) {
          tableData = [
            ...this.state.tableData,
            ...(tableData as IScheduleChangeLogResponse[]),
          ];
        }

        const totalDataLength = tableData?.length
          ? tableData[0]?.totalCount
          : 0;
        this.setState({
          tableData: tableData,
          filteredData: tableData,
          totalDataLength,
        });
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => this.toggleDataLoading());
  };

  downloadChangeLogData = () => {
    const { filterPopUps, isDrillDownView, scheduleId, drillDownShiftDate} = this.state;
    const request: IScheduleChangeLogRequest = {
      shiftfromdate: "",
      shifttodate: "",
      auditfromdate: "",
      audittodate: "",
      tenantId: 0,
      hotelId: this.props.hotelId,
      uniqueno: "",
      editor: "",
      username: "",
      position: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
      uniquenoCheckStrict: false,
    } as IScheduleChangeLogRequest;

    request.shiftfromdate = this.props.payPeriodStartDate;
    request.shifttodate = this.props.payPeriodEndDate;
    
    if (isDrillDownView) {
      request.shiftfromdate = drillDownShiftDate;
      request.shifttodate = drillDownShiftDate;
      request.uniqueno = scheduleId?.toString();
      request.uniquenoCheckStrict = true;
    } else {
      filterPopUps.forEach((item: ICurrentFilter) => {
        if (item.filterKeyName === "shiftDate") {
          const datesArray = item.filterValue
            ?.split("-")
            .map((item) => item?.trim());
          request.shiftfromdate = datesArray[0];
          request.shifttodate = datesArray[1];
        } else if (item.filterKeyName === "editDateTime") {
          const datesArray = item.filterValue
            ?.split("-")
            .map((item) => item?.trim());
          request.auditfromdate = datesArray[0];
          request.audittodate = datesArray[1];
        } else if (item.filterKeyName === "uniqueno") {
          request.uniqueno = item.filterValue?.trim();
        } else if (item.filterKeyName === "editor") {
          request.editor = item.filterValue;
        } else if (item.filterKeyName === "employee") {
          request.username = item.filterValue;
        } else if (item.filterKeyName === "position") {
          request.position = item.filterValue;
        } else if (item.filterKeyName === "fieldname") {
          request.fieldName = item.filterValue;
        } else if (item.filterKeyName === "oldValue") {
          request.oldValue = item.filterValue;
        } else if (item.filterKeyName === "newValue") {
          request.newValue = item.filterValue;
        } else if (item.filterKeyName === "triggerName") {
          request.triggerName = item.filterValue;
        }
      });
    }
    this.setState({loader:true})
    Schedule.DownloadChangeLog(request, "ScheduleChangeLogs")
      .then(() => {
    this.setState({loader:false})
      })
      .catch((error) => {
    this.setState({loader:false})
      });
  };
  toggleDataLoading = () => {
    this.setState({ isDataLoading: !this.state.isDataLoading });
  };

  handleDatesChange = (date, type) => {
    const { startDate, endDate } = this.state;
    const currentFilter: any = { ...this.state.currentFilter };
    if (type === "StartDate") {
      if (new Date(date) > new Date(endDate)) {
        this.setState({ startDate: "" });
        Utils.toastError(
          "Please enter the Start Date less than or equal to End Date.",
          {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        return false;
      }

      currentFilter.filterValue = `${date} - ${endDate}`;
      this.setState({ startDate: date });
    }

    if (type === "EndDate") {
      if (new Date(startDate) > new Date(date)) {
        this.setState({ endDate: "" });
        Utils.toastError(
          "Please enter the End Date greater than or equal to Start Date.",
          {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        return false;
      }

      currentFilter.filterValue = `${startDate} - ${date}`;
      this.setState({ endDate: date });
    }

    this.setState({ currentFilter });
  };

  onFiltersChange = () => {
    const currentFilter = this.state.currentFilter;
    this.setState({ currentPageNo: 1 }, () => {
      this.getChangeLogData();
    });

    if (currentFilter?.filterKeyName) {
      this.setState({
        currentFilter: {
          filterName: "",
          filterValue: "",
          filterKeyName: "",
          popClassName: "",
          filterInputType: "",
        },
      });
    }
  };

  clearFilters = () => {
    if (+this.state.scheduleId > 0) {
      this.setState({ tableClassName: "schedule-log-table" });
      this.returnBackFromDrillDown();
    } else {
      this.setState({ filterPopUps: [], currentPageNo: 1 }, () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      });
    }
  };

  render() {
    const { tableColumns } = this.state;
    const functions = {
      downloadChangeLogData: this.downloadChangeLogData,
      updateFilters: this.updateFilters,
      editFilter: this.editFilter,
      onFilterTextChange: this.onFilterTextChange,
      onFilterChange: this.onFilterChange,
      removeFilter: this.removeFilter,
      hideFilterPopUp: this.hideFilterPopUp,
      clearFilters: this.clearFilters,
      onFiltersChange: this.onFiltersChange,
      handleDatesChange: this.handleDatesChange,
      validateDates: this.validateDates,
      onPaginationchange: this.onPaginationchange,
    };
    const forwardedProps = { ...this.state, ...this.props, ...functions };
    return (
      <>
        {/* <ToastContainer autoClose={3000} /> */}
        {tableColumns?.length > 0 && <ChangeLogs {...forwardedProps} />}
      </>
    );
  }
}
