import React from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Utils } from "../../Common/Utilis";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import _ from "lodash";
import { Facility } from "../../Common/Services/Facility";
import LocationSingleSelector from "./LocationSingleSelector";




export class AddAsset extends React.Component<any, any> {
  private FormSchema = Joi.object({
    assetName: Joi.string()
    .required()
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Asset Name is required" };
      });
    }),

    assetType: Joi.number()
    .required()
    .min(1)
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Please select Asset Type" };
      });
    }),

    location: Joi.number()
    .required()
    .min(1)
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Please select Location" };
      });
    }),

    
    description: Joi.string().max(200)
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Maximun 200 characters are allowed" };
      });
    })
    .optional().allow('')
  });  

  state: any = {
    FormData: {
        assetName: '',
      description:  '',
      assetType:-1,
      location:-1
    },
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    dateList: [{label: "11/11/21", value: "11/11/21"}, {label: "11/11/21", value: "11/11/21"}],
  
    isEditButton: false,
  };

  componentDidMount = () => {
    this.getHotels();

  
  };





  
  getHotels = () => {
    this.setState({ hotelList: this.props?.userHotels });
  };

  hideModal = (isReload = false) => {
    const    FormData = {
        assetName:  '',
   
    description:  '',
    assetType:-1,
    location:-1
    };
    this.props?.hideAddAsset(isReload);

    this.setState({ FormData, isFormNotValid: true,isDataSaving:false });
  };

  onFieldChange = (event, inputPosition) => {
   // debugger
    const { FormData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if(fieldName === "assetName" || fieldName === "description"){
      value = value?.toString()?.trimLeft();
    }
   
    FormData[fieldName] = value;
    this.setState({FormData},()=> {
      this.validationOnClick(inputPosition);
    });
  };


  SaveData = () => {

  
    let {
        FormData: {
            assetName,
            assetType,
            location,
            description
        }
      } = this.state;
   


    const data={
        RoomId: location,
        AssetName: assetName,
        AssetTypeId: assetType,
        AssetDescription: description,
        }
    this.setState({isDataSaving:true})
    Facility.SaveAssets(data)
    .then((result: any) => {
      
         this.setState({isDataSaving:false})
         this.hideModal(true);

         toast.success(`Asset Added successfully.`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
      
     
    }).catch((error)=>{
        this.setState({isDataSaving:false})

        if(error.response.data == 'Asset Name already in use. Please provide a different name.')
        {
            let newErrorObject=_.cloneDeep(this.state.error)
            newErrorObject= {...newErrorObject, assetName: 'Asset Name already in use. Please provide a different name.'}
            this.setState({isDataSaving:false, isFormNotValid:false,errors:newErrorObject}, ()=>{
                this.validateConfigurationFormSchema(true)
            })
        }
        else{
         Utils.toastError(error.response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });

          this.hideModal(true);
        }
   
    })

  };
  validateConfigurationFormSchema = (isAssetNameChanged=false) => {
    const valid = Joi.validate(
      this.state.FormData,
      this.FormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
   
    if (this.state.errors?.assetName === 'Asset Name already in use. Please provide a different name.' && isAssetNameChanged) {
        newErrorObject["assetName"] = 'Asset Name already in use. Please provide a different name.';
    }

   
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () =>
      this.validateConfigurationFormSchema()
    );
  };

  render() {
    const {
      FormData: {
          assetName,
          assetType,
          location,
          description
      },
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,

    } = this.state;

    const { showAddAssetModal,assetTypesList } = this.props;
    return (
      <>
        <div className="">
          <ToastContainer containerId={"addDeduction"} autoClose={3000} />

          <Modal
            className="add-room-type-modal add-new-asset"
            show={showAddAssetModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
           // keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Add New Asset
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                <Form.Group 
                      onBlur={() => this.validationOnClick(1)}
                      controlId="name" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.assetName && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      // onClick={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Asset Name</Form.Label>
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"assetName"} className={(assetName.length <= 47) ? "tootltip-hide": ""}>
                                {assetName}
                            </Tooltip> 
                        }
                        >
                      <Form.Control
                        type="text"
                        className="form-control"
                        // tabIndex={1}
                        maxLength={100}
                        id="txtassetName"
                        name="assetName"
                        autoComplete="assetName"
                        value={assetName}
                        onChange={(e) => this.onFieldChange(e, 1)}
                        onBlur={()=>{this.setState({FormData:{...this.state.FormData,assetName:assetName.trimRight()}})}}
                      />
                        </OverlayTrigger>
                      {this.getFieldError("assetName", 1)}
                    </Col>
                  </Form.Group>

                  <Form.Group 
                      onBlur={() => this.validationOnClick(2)}
                      controlId="name" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.assetType && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                    //   onBlur={() => this.validationOnClick(2)}
                    >
                      <Form.Label>Asset Type</Form.Label>

                    {assetTypesList.length > 0 &&  <DropDownList
                          // tabIndex={2}
                          placeHolder={"Select Asset Type"}
                          data={assetTypesList}
                          defaultValue={assetType}
                          isSearchRequired={true}
                          label={"assetTypeName"}
                          value={"assetTypeId"}
                          placeHolderItem={{ id: -1, name: "Select Asset Type" }}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("assetType", item.assetTypeId),
                              2
                            );
                          }}
                          selectedItem={[
                            ...[{ assetTypeId: -1, assetTypeName: "Select Asset Type" }],
                            ...assetTypesList,
                          ].find((r: any) => r?.assetTypeId === assetType)}
                        />}
                     
                      {this.getFieldError("assetType", 2)}
                    </Col>
                      </Form.Group>

                      <Form.Group 
                      onBlur={() => this.validationOnClick(3)}
                      controlId="name" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.location && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                    //   onBlur={() => this.validationOnClick(3)}
                    >
                      <Form.Label>Location</Form.Label>

                     <LocationSingleSelector
                         hid={this.props.hid}
                         defaultValue={location}
                          isSearchRequired={true}
                          onDropDownChange={(item) => {
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("location", item.roomId),
                              3
                            );
                          }}
                        />
                     
                      {this.getFieldError("location", 3)}
                    </Col>
                      </Form.Group>
              
                  <Form.Group  onBlur={() => this.validationOnClick(4)} controlId="description" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.description && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }`}
                      onClick={() => this.validationOnClick(4)}
                    >
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea"
                      
                        className="form-control"
                        id="description"
                        rows={1}
                        // tabIndex={4}
                        name="description"
                        autoComplete="description"
                        value={description}
                        maxLength={200}
                        onChange={(e) => this.onFieldChange(e, 4)}
                        onBlur={()=>{this.setState({FormData:{...this.state.FormData,description:description.trimRight()}})}}

                      />
                      {this.getFieldError("description", 4)}
                    </Col>
                  </Form.Group>
                 
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
              // tabIndex={9}
                className="btn-ghost"
                onClick={() => this.hideModal(false)}
              >
                Cancel
              </Button>
              <Button
              //  tabIndex={8}
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.SaveData}
              >
              Add Asset
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
