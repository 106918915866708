import React from "react";
// import GreyTickMark from "../../../../../Common/Assets/Images/icons/grey_tick_mark.svg";
import {
  Button, Dropdown, Form, OverlayTrigger, Tooltip
} from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import TableSearch from "../../../Common/Components/TableSearch";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

import "./bankSync.scss";
import { Utils } from "../../../Common/Utilis";
import { SingleDropdownListWithoutSearch } from "../../../Common/Components/SingleDropdownListWithoutSearch";
import { bankSyncServices } from "../../../Common/Services/bankSyncServices";
import { toast } from "react-toastify";
import { IBankSyncTransactionData } from "./BankSync";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
export default class BankSyncFindTable extends React.Component<any, any> {
  private laborDayDetails: any;
  private laborAPOChild: any;
  private laborUnderBudgetChild: any;
  private laborOverBudgetChild: any;
  private tableSearchBankSync: any;


  constructor(props: any) {
    super(props);
    this.laborDayDetails = React.createRef();
    this.tableSearchBankSync = React.createRef();
    this.laborAPOChild = React.createRef();
    this.laborUnderBudgetChild = React.createRef();
    this.laborOverBudgetChild = React.createRef();
    this.state = {
      renderTable: "",
      row: props.row,
      isBackVisible: true,
      isContinueVisible: true,
      activeTab: "review",
      selectedTransaction: -1,
      sortingOrder: "asc",
      dayFilterText: "+/- 10 Days",
      selectedRows: [],
      dayFilter: [
        { id: -1, name: "All Transactions" },
        { id: 1, name: "+/- 1 Day" },
        { id: 2, name: "+/- 3 Days" },
        { id: 3, name: "+/- 5 Days" },
        { id: 4, name: "+/- 10 Days" },
        { id: 5, name: "+/- 30 Days" },

      ],
      dayFilterValue: "10",
      dollarVarianceList: [
        { id: 1, name: "+/- 2%" },
        { id: 2, name: "+/- 5%" },
        { id: 3, name: "+/- 10%" },
      ],
      dollarVarianceText: "Dollar Variance",
      dollarVarianceValue: "0",
      isloader: true,
      tableData: [],
      deftableData: [],
      pageIndex: 1,
      bulkSelectArray: {},
      loadMoreLoader: false,
      hasMoreData: true,
      lastRowId: 1,

      ehidSearch: "",
      ehidFilterList: [],
      defehidFilterList: [],
      ehidName: "EHID",
      searchText: "",
      InnerSpinner: false,
    };
  }
  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      // this.handlerowEvents(e, row, rowIndex, "main");

      // let row = item.data;
      let newRow: any = { ...row };
      if (row.badge.search(/Register/i) !== 0 && row.transCode.search(/invoice/i) !== -1) {
        this.props.OpenInvoiceSlideOut(e, row, rowIndex, "invoice");
      } else if (row.transCode.search(/transfer/i) !== -1) {
        this.props.hideTrSlideOut(false, row, 0);
      } else if (row.transCode.search(/invoice/i) !== -1) {
        this.props.hideSlideOut(false, row, 0);
      } else {
        this.props.hideSlideOut(false, row, 0);
      }
    },
  };

  componentDidMount() {
    this.getFindTableData();
  }

  filterFindData = (e) => {
    this.setState({ pageIndex: 1 });
    let inPutValue = e.currentTarget.value;
    if (inPutValue?.trim() !== "") {
      inPutValue = inPutValue?.trimLeft();
    }
    this.setState({ searchText: inPutValue, loadMoreLoader: true, tableData: [] }, () => {
      this.getFindTableData();
    });
  }

  getFindTableData = () => {
    this.props?.UpdateLoadMoreState(true);
    let request: any = {};
    const { dollarVarianceValue, dayFilterValue, pageIndex, lastRowId } =
      this.state;
    request.hotelID = this.props?.hidValue;
    request.oprId = this.props?.accountNameValue;
    request.pageIndex = pageIndex;
    request.date = this.props?.row?.date;
    request.filterDay = dayFilterValue;
    request.filterVeriance = dollarVarianceValue;
    request.amount = this.props?.row?.plaidAmount;
    request.serachText = this.state.searchText;
    request.EntryType =
      (Number(this.props?.row?.checkType) > 0 && Number(this.props?.row?.plaidAmount) > 0) ? "Deposit" :
        (Number(this.props?.row?.checkType) > 0 && Number(this.props?.row?.plaidAmount) < 0) ? "Withdrawal" :
          (this.props?.row?.checkType?.toString().toLocaleLowerCase() === "deposit" ? "Deposit" : "Withdrawal");//NW-30456

    this.setState({ ehidFilterList: [], defehidFilterList: [], ehidName: "EHID" });
    bankSyncServices
      .GetBankSyncFindData(request)
      .then((res) => {
        let tableData: any = [];
        let newlastRowId = lastRowId;
        let hasMoreData = true;
        if (res.length > 0) {
          res.map((o: any) => o["date1"] = new Date(o.date).toISOString())
          res.map((item, index) => {
            let tableResponse: IBankSyncTransactionData = {
              checkType: "",
            };
            tableResponse = { ...item };
            tableResponse.id = newlastRowId;
            tableResponse.rowId = this.props?.row?.syncID;
            tableResponse.syncID = this.props?.row?.syncID;
            tableResponse.plaidAmount = this.props?.row?.plaidAmount;
            tableResponse.amount = Number(item.amount).toFixed(2);
            tableResponse.trUniqueNo = item.uniqeno;
            tableResponse.Hotelid = this.props?.hidValue;
            tableResponse.transCode = "";
            if (item.updateTable.search(/transfer/i) !== -1) {
              tableResponse.transCode = "Transfer";
            } else if (item.updateTable.search(/creditcard/i) !== -1) {
              tableResponse.transCode = item.updateTable;
            } else if (item.updateTable.search(/invoice/i) !== -1) {
              tableResponse.transCode = "Invoice";
            } else if (item.updateTable.search(/deposit/i) !== -1) {
              tableResponse.transCode = "Deposit";
            } else if (item.updateTable.search(/withdrawal/i) !== -1) {
              tableResponse.transCode = "Withdrawal";
            } else if (item.updateTable.search(/miscamount/i) !== -1) {
              tableResponse.transCode = "Withdrawal";
            }
            tableData.push(tableResponse);
            newlastRowId++;
          });

          if (tableData.length < 50) {
            hasMoreData = false
          }
          let _tableData: any = [...this.state.tableData, ...res];
          this.getEhidList(_tableData);

          this.setState(
            (prevState) => ({
              tableData: [...prevState.tableData, ...tableData],
              deftableData: [...prevState.tableData, ...tableData],
              isloader: false,
              loadMoreLoader: false,
              InnerSpinner: false,
              hasMoreData: hasMoreData,
              lastRowId: newlastRowId,
            }),
            () => {
              this.props.findTableData(true)
              setTimeout(() => {
                this.CleanSearch();
              }, 0);
            }
          );
        } else {
          this.setState(
            {
              hasMoreData: false,
              isloader: false,
              loadMoreLoader: false,
              InnerSpinner: false,
            },
            () => {
              if (this.state.tableData.length > 0) {
                this.props.findTableData(true)
              } else {
                this.props.findTableData(false)
              }
              this.CleanSearch();
            }
          );
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        //this.setState({ isloader: false, loadMoreLoader: false });
      }).finally(() => {
        this.props?.UpdateLoadMoreState(false);
      });
  };
  onPaginationchange = () => {
    const currentPageNo = this.state.pageIndex + 1;
    this.setState({ pageIndex: currentPageNo, /*loadMoreLoader: true*/ }, () => {
      this.getFindTableData();
    });
  };

  CleanSearch = () => {
    if (this.tableSearchBankSync.current !== null) {
      this.tableSearchBankSync.current.CleanSearch();
    }
  };

  getEhidList = (dataList: any) => {
    let { ehidFilterList } = this.state;
    ehidFilterList = [];
    dataList.forEach((item: any) => {
      item["_hid"] = item.backHid;
      item["sort"] = item.backHIDLetterCode;
      item["isShow"] = true;
      item["label"] = item.backHIDLetterCode;
      item["name"] = item.backHIDLetterCode;
      ehidFilterList.push(item);
    });
    ehidFilterList = _.uniqBy(ehidFilterList, '_hid');
    let clearFilter = { _hid: -1, sort: "-1", name: "All EHID(s)", label: "EHID", isShow: false };
    /*ehidFilterList.length > 1 && */ehidFilterList.push(clearFilter);
    ehidFilterList = _.sortBy(ehidFilterList, "sort");
    this.setState({ ehidFilterList, defehidFilterList: ehidFilterList });
  }

  handleSearch = (e: any) => {
    const updatedList = this.state.defehidFilterList.filter((item: any) => {
      return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
    });
    this.setState({ ehidFilterList: updatedList, ehidSearch: e.target.value });
  };

  handleToggle = (e: any) => {
    const dataList = Object.assign([], JSON.parse(JSON.stringify(this.state.defehidFilterList)));
    this.setState({ ehidSearch: "", ehidFilterList: dataList });
    (e === true) && setTimeout(() => { $("#idEhidSearch").focus(); }, 50);
  }

  handleChange = (item: any) => {
    let { ehidName, defehidFilterList } = this.state;
    let updatedList: any[] = [];
    if (item.label === "EHID") {
      updatedList = this.state.deftableData;
      ehidName = item.label;
      defehidFilterList.forEach((o: any) => o.name === "All EHID(s)" && (o.isShow = false));
    } else {
      updatedList = this.state.deftableData.filter((o: any) => o.backHIDLetterCode.toLowerCase() === item.backHIDLetterCode.toLowerCase());
      ehidName = item.backHIDLetterCode;
      defehidFilterList.forEach((o: any) => o.name === "All EHID(s)" && (o.isShow = true));
    } this.setState({ tableData: [] }, () => {
      let hasMoreData = true;
      if (updatedList.length < 50) {
        hasMoreData = false
      }
      this.setState({ tableData: updatedList, ehidName, defehidFilterList, hasMoreData: hasMoreData });
    });
  }

  handleSelectedItem = (controlID: any, id: any) => {
    // debugger;
    // this.CleanSearch();
    let dayFilterText;
    let dayFilterValue;
    let dollarVarianceValue = "0";
    let dollarVarianceText;
    if (controlID === 1) {
      if (id === 1) {
        dayFilterValue = "1";
        dayFilterText = "+/- 1 Day";
      } else if (id === 2) {
        dayFilterText = "+/- 3 Days";
        dayFilterValue = "3";
      } else if (id === 3) {
        dayFilterText = "+/- 5 Days";
        dayFilterValue = "5";
      } else if (id === 4) {
        dayFilterText = "+/- 10 Days";
        dayFilterValue = "10";
      } else if (id === 5) {
        dayFilterText = "+/- 30 Days";
        dayFilterValue = "30";
      } else if (id === -1) {
        dayFilterText = "Date Range";
        dayFilterValue = "Date Range";
      }
      this.setState({
        dayFilterText,
        dayFilterValue,
      });
    } else {
      if (id === 0) {
        dollarVarianceValue = "0";
        dollarVarianceText = "Dollar Variance";
      } else if (id === 1) {
        dollarVarianceValue = "2";
        dollarVarianceText = "+/- 2%";
      } else if (id === 2) {
        dollarVarianceValue = "5";
        dollarVarianceText = "+/- 5%";
      } else if (id === 3) {
        dollarVarianceValue = "10";
        dollarVarianceText = "+/- 10%";
      }
      if (dollarVarianceValue !== "0") {
        let dollarVarianceList = [...this.state.dollarVarianceList];
        if (dollarVarianceList[3] === undefined) {
          dollarVarianceList.push({ id: 0, name: "All Transactions" })
        }
        this.setState({
          dollarVarianceList: dollarVarianceList
        })
      } else {
        let dollarVarianceList = [...this.state.dollarVarianceList];
        let newList = dollarVarianceList.filter((item) => item.id !== 0)

        this.setState({
          dollarVarianceList: newList
        })
      }
      this.setState({
        dollarVarianceText,
        dollarVarianceValue,
      });
    }
    this.setState(
      {
        // tableData,
        bulkSelectRow: false,
        bulkSelect: false,
        bulkSelectArray: {},
        selectedRows: [],
        expandedList: [],
        isloader: true,
        istableloader: true,
        tableData: [],
        pageIndex: 1,
        lastRowId: 1,
      },
      () => {
        this.getFindTableData();
      }
    );
  };
  handleOnSelect = (row, isSelect) => {
    // debugger;
    let {
      tableData,
      selectedRows,
      bulkSelectRow,
      bulkSelect,
      bulkSelectArray,
      hasMultipleTxn,
    } = this.state;
    // let tableLen = tableData.filter((item) => item.viewType === 1);
    if (isSelect) {
      selectedRows = [...this.state.selectedRows, row.id];
      bulkSelectArray[row.id] = row;
    } else {
      selectedRows = this.state.selectedRows.filter((x) => x !== row.id);
      delete bulkSelectArray[row.id];
      //   bulkSelectArray = this.state.bulkSelectArray.filter(
      //     (x) => x.id !== row.id
      //   );
    }
    if (selectedRows.length === 0) {
      bulkSelectRow = false;
      bulkSelect = false;
      $("#footerBulkSelect")
        .prop("checked", false)
        .prop("indeterminate", false);
    } else if (
      selectedRows.length > 0 &&
      selectedRows.length < tableData.length
    ) {
      bulkSelectRow = false;
      bulkSelect = true;
      $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
    } else if (selectedRows.length === tableData.length) {
      bulkSelectRow = true;
      bulkSelect = true;
      $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
    }
    this.props.onRowSelection(bulkSelectArray);
    this.setState(() => ({
      selectedRows,
      bulkSelectRow,
      bulkSelect,
      bulkSelectArray,
      hasMultipleTxn,
    }));
  };
  handleOnSelectAll = (isSelect, rows) => {
    // debugger;
    let { selectedRows, bulkSelectArray, hasMultipleTxn } = this.state;
    if (rows.length > 0) {
      if (isSelect) {
        rows.forEach((item) => {
          selectedRows.push(item.id);
          bulkSelectArray[item.id] = item;
          //   bulkSelectArray.push(item);
        });
        $("#footerBulkSelect")
          .prop("checked", true)
          .prop("indeterminate", false);
      } else {
        selectedRows = [];
        bulkSelectArray = {};
        this.footerBulkClose();
      }
      this.props.onRowSelection(bulkSelectArray);
      this.setState(() => ({
        selectedRows,
        bulkSelectArray,
        bulkSelectRow: isSelect,
        bulkSelect: isSelect,
        hasMultipleTxn,
      }));
    }
  };
  footerBulkClose = () => {
    this.setState(
      {
        selectedRows: [],
        bulkSelect: false,
        bulkSelectRow: false,
        bulkSelectArray: {},
        hasMultipleTxn: [],
      },
      () => {
        $("#footerBulkSelect")
          .prop("checked", false)
          .prop("indeterminate", false);
      }
    );
  };
  footerBulkSelectAll = (event) => {
    let {
      tableData,
      selectedRows,
      searchResult,
      bulkSelectArray,
      hasMultipleTxn,
    } = this.state;
    selectedRows = [];
    // let tableLen = tableData.filter((item) => item.viewType === 1);
    let checked = event.target.checked;
    if (checked) {
      tableData.forEach((item) => {
        selectedRows.push(item.id);
        bulkSelectArray[item.id] = item;
      });
    } else {
      bulkSelectArray = {};
      selectedRows = [];
      this.footerBulkClose();
    }
    if (selectedRows.length > 0 && selectedRows.length < tableData.length) {
      $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
    } else if (selectedRows.length === tableData.length) {
      $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
    }
    this.setState({
      bulkSelectRow: checked,
      selectedRows,
      bulkSelectArray,
      bulkSelect: checked,
      hasMultipleTxn,
    });
  };

  setActiveTab = (selectedTab) => {
    this.setState({ activeTab: selectedTab });
  };

  render() {
    const groupInvInfo = "There are multiple transactions associated with this group. Please view these transactions from the Register page.";

    const returnToolTipColumn = (dollerSign, amt) => {
      return (
        <div className="amount-value">
          {dollerSign}
          {Utils.amountFormatted(Number(amt).toFixed(2))}
        </div>
      )
    }

    const columns = [
      {
        dataField: "date1",
        text: "",
        sort: true,
        order: "desc",
        headerClasses: "bsyncFindTableColDate",
        searchable: false,
        sortCaret: (order, column) => {
          if (!order)
            return (
              <div className="d-flex descending">
                <div>Date</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "desc")
            return (
              <div className="d-flex ascending">
                <div>Date</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "asc")
            return (
              <div className="d-flex descending">
                <div>Date</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          return null;
        },
        onSort: (field, order) => {
          if (order === "asc") {
            this.setState({ sortingOrder: "asc" });
          } else if (order === "desc") {
            this.setState({ sortingOrder: "desc" });
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.date}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "venderName",
        text: "",
        headerClasses: "bsyncFindTableColVendor",
        classes: "bsyncFindTableColVendor",
        searchable: true,
        sort: true,
        order: "asc",
        sortFunc: (a, b, order) => {
          if (order === "desc") {
            return b.localeCompare(a)
          }
          return a.localeCompare(b)
        },
        sortCaret: (order) => {
          if (!order)
            return (
              <div className="d-flex descending">
                <div>Vendor</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "desc")
            return (
              <div className="d-flex ascending">
                <div>Vendor</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "asc")
            return (
              <div className="d-flex descending">
                <div>Vendor</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          return null;
        },
        onSort: (field, order) => {
          if (order === "asc") {
            this.setState({ sortingOrder: "asc" });
          } else if (order === "desc") {
            this.setState({ sortingOrder: "desc" });
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                {row.venderName}
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "notes",
        text: "",
        searchable: false,
        headerClasses: "bsyncFindTableColNotes",
        classes: "bsyncFindTableColNotes",
        sort: true,
        order: "asc",
        sortFunc: (a, b, order) => {
          if (order === "desc") {
            return b.localeCompare(a)
          }
          return a.localeCompare(b)
        },
        sortCaret: (order) => {
          if (!order)
            return (
              <div className="d-flex descending">
                <div>Notes</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "desc")
            return (
              <div className="d-flex ascending">
                <div>Notes</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "asc")
            return (
              <div className="d-flex descending">
                <div>Notes</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          return null;
        },
        onSort: (field, order) => {
          if (order === "asc") {
            this.setState({ sortingOrder: "asc" });
          } else if (order === "desc") {
            this.setState({ sortingOrder: "desc" });
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="top">
              {row.notes}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "backHIDLetterCode",
        text: "",
        searchable: false,
        headerClasses: "bsyncFindTableColNotes",
        sort: true,
        hidden: this.props.sharedHIDList.length === 1,
        order: "asc",
        sortFunc: (a, b, order) => {
          if (order === "desc") {
            return b.localeCompare(a)
          }
          return a.localeCompare(b)
        },
        sortCaret: (order) => {
          if (!order)
            return (
              <div className="d-flex descending">
                <div>EHID</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "desc")
            return (
              <div className="d-flex ascending">
                <div>EHID</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "asc")
            return (
              <div className="d-flex descending">
                <div>EHID</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          return null;
        },
        onSort: (field, order) => {
          if (order === "asc") {
            this.setState({ sortingOrder: "asc" });
          } else if (order === "desc") {
            this.setState({ sortingOrder: "desc" });
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="top">
              {row.backHIDLetterCode}
            </EllipsisWithTooltip>
          );
        },
      },
      // date:"05/18/22",
      // vendor:'Vendor1',
      // Notes:"Test",
      // total:"1173.80",
      // trUniquesNo:'12345',
      // transType:"withdrawal"
      {
        dataField: "badge",
        text: "",
        headerClasses: "location text-left",
        classes: "location bages",
        sort: true,
        searchable: true,
        sortFunc: (a, b, order) => {
          if (order === "desc") {
            return b.localeCompare(a)
          }
          return a.localeCompare(b)
        },
        sortCaret: (order) => {
          if (!order)
            return (
              <div className="d-flex descending">
                <div>Location</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "desc")
            return (
              <div className="d-flex ascending">
                <div>Location</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "asc")
            return (
              <div className="d-flex descending">
                <div>Location</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          return null;
        },
        onSort: (field, order) => {
          if (order === "asc") {
            this.setState({ sortingOrder: "asc" });
          } else if (order === "desc") {
            this.setState({ sortingOrder: "desc" });
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex">
              <div className="badges color-gray">{row.badge}</div>
            </div>
          )
        }
      },
      {
        dataField: "amount",
        text: "",
        headerClasses: "bsyncFindTableColAmt",
        searchable: true,
        sort: true,
        order: "asc",
        sortFunc: (a, b, order) => {
          if (order === "desc") {
            return b - a
          }
          return a - b
        },
        sortCaret: (order) => {
          if (!order)
            return (
              <div className="d-flex descending justify-content-end">
                <div>Total</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "desc")
            return (
              <div className="d-flex ascending justify-content-end">
                <div>Total</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          else if (order === "asc")
            return (
              <div className="d-flex descending justify-content-end">
                <div>Total</div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
                    fill="#1D1E20"
                  />
                </svg>
              </div>
            );
          return null;
        },
        onSort: (field, order) => {
          if (order === "asc") {
            this.setState({ sortingOrder: "asc" });
          } else if (order === "desc") {
            this.setState({ sortingOrder: "desc" });
          }
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          //   return <div>{row.amt}</div>;
          let amt = String(row.amount);
          let dollerSign = "$";
          if (amt.toString().includes("-")) {
            amt = amt.slice(1);
            dollerSign = "-$";
          }
          const grpUniqueNoLen = row.groupUniqueNo?.split(',');
          return (
            <>
              {grpUniqueNoLen.length > 1 && (
                <OverlayTrigger placement="top"
                  overlay={<Tooltip id={`infoIconFind_${rowIndex}`}>{groupInvInfo}</Tooltip>}>
                  {returnToolTipColumn(dollerSign, amt)}
                </OverlayTrigger>
              )}
              {grpUniqueNoLen.length <= 1 && (
                <div className="amount-value">
                  {returnToolTipColumn(dollerSign, amt)}
                </div>
              )}
            </>
          );
        },
      },
    ];

    const selectRow = {
      mode: "checkbox",
      // clickToSelect: true,
      selected: this.state.selectedRows,
      headerClasses: "selectionHeader",
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };
    return (
      <>
        <div className="find-tab-content">
          <div className="">
            <ToolkitProvider
              keyField="Modal_rNo"
              data={this.state.tableData}
              columns={columns}
              // search={{
              //   afterSearch: (newResult) => {
              //     if (newResult.length === 0) {
              //       this.setState({ hasMoreData: true });
              //     } else {
              //       this.setState({ hasMoreData: false });
              //     }
              //   },
              // }}
              rowEvents={this.rowEvents}
            >
              {(props: {
                searchProps: JSX.IntrinsicAttributes;
                baseProps: JSX.IntrinsicAttributes;
              }) => (
                <div>
                  <div className="navigation-menus d-flex">
                    {this.state.accountNameValue !== -1 && (
                      <>
                        {/* <>
                          {!this.state.isloader ? ( */}
                        <div className="registerSearch">
                          <Form.Control
                            className="form-control"
                            placeholder={"Search Inn-Flow Transactions"}
                            id="tableSearch"
                            key="tableSearch"
                            autoComplete="Off"
                            onChange={this.filterFindData}
                            value={this.state.searchText}
                          //ref={this.tableSearchBankSync}
                          >

                          </Form.Control>
                          {/* <TableSearch
                                id="tableSearch"
                                key="tableSearch"
                                ref={this.tableSearchBankSync}
                                {...props.searchProps}
                                CleanSearch={this.CleanSearch.bind(this)}
                                placeholder={"Search Inn-Flow Transactions"}
                              /> */}
                        </div>
                        {/* //   ) : (
                        //     <div className="single-loader loadingAnimation"></div>
                        //   )}
                        // </> */}
                        <div className="filter-section cusDateMargin d-flex align-items-center">
                          {/* {!this.state.isloader ? ( */}
                          <>
                            <div className="transactionFilter">
                              <SingleDropdownListWithoutSearch
                                id={"dayFilter"}
                                itemList={this.state.dayFilter}
                                handleSelectedItem={this.handleSelectedItem.bind(
                                  this,
                                  1
                                )}
                                defaultName={this.state.dayFilterText}
                                calIcon={this.state.dayFilterText == "Date Range" ? false : true}
                              />
                            </div>
                            <div className={`dollarVariance ${this.state.dollarVarianceValue === "0" ? "no-filter" : ""}`}>
                              <SingleDropdownListWithoutSearch
                                id={"dollarVariance"}
                                itemList={this.state.dollarVarianceList}
                                handleSelectedItem={this.handleSelectedItem.bind(
                                  this,
                                  2
                                )}
                                defaultName={this.state.dollarVarianceText}
                              // calIcon={true}
                              />
                            </div>
                            {this.props.sharedHIDList.length > 1 && (
                              <div className="dollarVariance ml-3 mr-3">
                                <Dropdown className="searchControlWithClear more-action bg-blue single-select-image dropdown" onToggle={this.handleToggle}>
                                  <Dropdown.Toggle className="filter-btn dropdown-toggle btn btn-primary" id="dropdown-Tenant">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <div className="">
                                        {this.state.ehidName}
                                      </div>
                                      <div className="ml-auto drop-arrow d-flex">
                                        <FiChevronDown />
                                      </div>
                                    </div>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div className="search-control">
                                      <Form.Group controlId="ehidSearch">
                                        <Form.Control
                                          id="idEhidSearch"
                                          type="text"
                                          name="search"
                                          value={this.state.ehidSearch}
                                          autoComplete="off"
                                          onChange={this.handleSearch}
                                          placeholder="Search..."
                                          autoFocus={true}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="dropdownItems">
                                      {this.state.ehidFilterList.length > 0 ? (<>
                                        {this.state.ehidFilterList.map((item: any, idx: any) => (
                                          <Dropdown.Item key={idx}
                                            className={`${item.isShow ? "" : "d-none"}`}
                                            eventKey={item._hid}
                                            onClick={() => this.handleChange(item)}>
                                            <div className="d-flex">
                                              <div className="">{item.name}</div>
                                            </div>
                                          </Dropdown.Item>
                                        ))}
                                      </>) : (
                                        <Dropdown.Item>No Item Found</Dropdown.Item>
                                      )}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )}
                          </>
                          {/* // ) : (
                          //   <div className="single-loader loadingAnimation"></div>
                          // )} */}
                        </div>
                      </>
                    )}
                  </div>
                  {(!this.state.isloader && !this.state.loadMoreLoader) ? (
                    <div
                      className={`${this.state.defaultDensityView} fixHeader100 forBankSyncFindTableTable tableToggleRow regular`}
                    >
                      <BootstrapTable
                        {...props.baseProps}
                        keyField="id"
                        key="uniqeno"
                        hover
                        rowEvents={this.rowEvents}
                        // expandRow={expandRow}
                        selectRow={selectRow}
                        // defaultSorted={defaultSorted}
                        noDataIndication={'No record found.'}
                      />
                      <div
                        className="loadMoreBtn"
                        style={{
                          position: "relative",
                          bottom: "-10px",
                          justifyContent: "left",
                        }}
                      >
                        {this.state.hasMoreData && (
                          <Link className="LoadMoreBankSync" to="#"
                            onClick={() => this.onPaginationchange()}
                          >
                            {this.state.InnerSpinner ? "Loading.. " : "Load More "}
                            {this.state.InnerSpinner && (
                              <Spinner style={{ color: "#2caf92", alignItems: 'center' }} size="sm" animation="border" />
                            )}
                          </Link>
                        )
                          // : (
                          //   <div className="single-loader loadingAnimation"></div>
                          // )
                        }
                      </div>
                    </div>
                  ) : (
                    <div className="single-loader loadingAnimation"></div>
                  )}
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </>
    );
  }
}
