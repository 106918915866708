import React from "react";
import { Container, Tabs, Tab, Dropdown } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import { PayrollExportReciepent } from "./PayrollExportReciepent";
import { PayrollExportCodeMappng } from "./PayrollExportCodeMappng";
import { PayrollProcessor } from "../../../Common/Services/PayrollProcessor";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../../Common/Services/RoleSubPermissionSetting";
import "./payroll-processor-index.scss";
import { PayrollProcessorLogHistory } from "./PayrollProcessorLogHistory";
import { Utils } from "../../../Common/Utilis";

export class PayrollProcessorIndex extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: "exportReciept",
      exportTypeFormat: [],
      activeExportType: "",
      activeExportName: "",
      permissionManageSettings: false,
      showChangeLogModal: false,
    };
  }

  componentDidMount() {
    this.getPayrollPermission();
    this.getExportTypeFormat("componentMount");
  }

  showHideChangeLogModal = () => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal,
    });
  };

  getPayrollPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10034;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionID) {
              case 10046:
                this.setState({ permissionManageSettings: true });
                break;
            }
          });
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
      });
  };

  getExportTypeFormat = (type: string) => {
    PayrollProcessor.getExportTypeFormat()
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let selectFormat = result[0].selectFormat;

          let selectedIndex: number = 0;
          for (let i = 0; i < result.length; i++) {
            if (Number(result[i].exportTypeID) === Number(selectFormat)) {
              selectedIndex = i;
              break;
            }
          }

          let activeExportType = result[selectedIndex].exportType;
          let activeExportName = result[selectedIndex].exportName;
          this.setState({
            exportTypeFormat: result,
            activeExportType,
            activeExportName,
          });
        } else {
          Utils.toastError("No Data found", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "payrollProcessorIndex",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "payrollProcessorIndex",
        });
        reject();
      });
  };

  exportFormatChange = (item: any) => {
    let activeExportName = item.exportName;
    let activeExportType = item.exportType;
    this.setState({ activeExportType, activeExportName });
  };

  selectTab = (eventKey: any) => {
    this.setState({ activeTab: eventKey });
  };

  render() {
    return (
      <>
        {/* <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"payrollProcessorIndex"}
        /> */}

        <div className="payroll-processor-main">
          <Container fluid className="body-sec">
            <div className="page-heading d-flex">
              <div className="mr-auto">Payroll Processor</div>
              {this.state.activeTab === "exportReciept" && (
                <div className="header-popout">
                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        eventKey="changeLog"
                        key="changeLog"
                        onClick={() => this.showHideChangeLogModal()}
                      >
                        Change Log
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>

            {this.state.exportTypeFormat.length > 0 && (
              <div className="processor-tabs">
                <Tabs
                  id="payroll-processor-tabs"
                  activeKey={this.state.activeTab}
                  onSelect={this.selectTab}
                  unmountOnExit={true}
                >
                  <Tab
                    eventKey="exportReciept"
                    title="Payroll Export Recipient"
                  >
                    <PayrollExportReciepent parentState={this.state} />
                  </Tab>
                  <Tab
                    eventKey="codeMapping"
                    title="Payroll Export Code Mapping"
                  >
                    <PayrollExportCodeMappng
                      parentState={this.state}
                      exportFormatChange={this.exportFormatChange}
                    />
                  </Tab>
                </Tabs>
              </div>
            )}

            {this.state.showChangeLogModal && (
              <PayrollProcessorLogHistory
                payPeriodStartDate={""}
                payPeriodEndDate={""}
                accType={"All-J"}
                showChangeLogModal={this.state.showChangeLogModal}
                showHideChangeLogModal={this.showHideChangeLogModal}
                hotelName={""}
                hotelId={0}
                hideCrossAndClearFilters={false}
                shiftId={0}
                shiftDate={""}
                noOfDays={0}
                showPerviousLogBanner={false}
              />
            )}
          </Container>
        </div>
      </>
    );
  }
}
