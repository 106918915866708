import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import moment from "moment";
import { Utils } from "../../../Common/Utilis";

export const AddTerminationDateForPayrollHotel = ({
  hid,
  userUniqueno,
  showTerminationDateModal,
  hideTerminationDateModal,
}) => {
  const [terminationData, setTerminationData] = useState({
    hid: hid,
    terDate: "",
    userUniqueno: userUniqueno,
  });

  const [calenderTerminationDateShow, setCalenderTerminationDateShow] =
    useState(false);

  const hideModal = (isSave = false, data = {}) => {
    setTerminationData({ ...terminationData, terDate: "" });
    hideTerminationDateModal(isSave, data);
  };

  const calenderTerminationDateHide = () => {
    setCalenderTerminationDateShow(false);
  };

  const calenderTerminationDateDisplay = () => {
    setCalenderTerminationDateShow(true);
  };

  const onContactFieldChange = (event, isBlur = false) => {
    let value = event.target.value;
    const fieldName = event.target.name;
    setTerminationData({ ...terminationData, [fieldName]: value });
  };

  return (
    <>
      <Modal
        className="inactive-ehid-modal"
        show={showTerminationDateModal}
        onHide={hideModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Inactive EHID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-section">
            <div className="body-section">
              <Form.Group
                controlId="date-selection"
                className={"d-flex flex-column date-picker"}
              >
                <Form.Label>Termination Date</Form.Label>

                <div className="validate-input d-flex flex-column">
                  <div className="calender-container">
                    <div
                      className={
                        calenderTerminationDateShow
                          ? "calender-display open"
                          : "calender-display"
                      }
                      onClick={calenderTerminationDateDisplay}
                    >
                      <input
                        type="text"
                        placeholder="MM/DD/YY"
                        name="terminationDate"
                        autoComplete="terminationDate"
                        value={terminationData.terDate}
                        className="form-control single-date-pick"
                      />
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                        <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                      </svg>
                    </div>
                    {calenderTerminationDateShow && (
                      <div className="calender">
                        <OutsideClickHandler
                          onOutsideClick={calenderTerminationDateHide}
                        >
                          <Calendar
                            calendarType={"US"}
                            maxDate={new Date()}
                            onChange={(date) => {
                              const value = moment(date).format("MM/DD/YY");
                              onContactFieldChange(
                                Utils.BuildCustomEventObject("terDate", value)
                              );
                              calenderTerminationDateHide();
                            }}
                            value={
                              terminationData.terDate
                                ? new Date(terminationData.terDate)
                                : new Date()
                            }
                          />
                        </OutsideClickHandler>
                      </div>
                    )}
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-ghost" onClick={() => hideModal(false)}>
            Cancel
          </Button>
          <Button
            disabled={!terminationData?.terDate}
            className="btn btn-primary"
            onClick={() => hideModal(true, terminationData)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
