import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import scrollIntoView from "scroll-into-view";
import { resolve, reject } from "q";
import chevronRight from "../../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
// import PageLoader from "../../../../../Common/Components/PageLoader";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import {
  IOverUnderDeptsDaysDto,
  IApproveSaveRequestDto,
} from "../../../../../Common/Contracts/ILaborDayApprove";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { Utils } from "../../../../../Common/Utilis";

let localStorageUser: any;
export default class UnderBudgetDept extends React.Component<any, any> {
  private underBudgetDivRef: any;
  private expendedUnderBudgetRef: any;
  constructor(props: any) {
    super(props);
    this.underBudgetDivRef = React.createRef();
    this.expendedUnderBudgetRef = React.createRef();
    this.state = {
      parentRow: props.row,
      underBudgetDeptsData: [],
      multipleRowSelected: false,
      selectedRows: [],
      tableExpended: [],
      loaderUnderBudget: false,
      preSelectedRows: [],
      expendedRowIndex: 0,
      isExpand: false,
      UnApproveDeptList:""
    };
  }

  componentDidMount() {
    this.underBudgetsDeptApprovalData();
  }

  underBudgetsDeptApprovalData() {
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ loaderUnderBudget: true });
    let parentRow = this.state.parentRow;
    let request = {} as IOverUnderDeptsDaysDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date;
    request.entryType = "UnderBudget";

    LaborDayApprove.GetOverUnderDeptApprovalDays(request)
      .then(async (result: any | null) => {
        let DayApproveData: any = [];
        if (result != null && result.length > 0) {
          DayApproveData = result;
         
          for (let i = 0; i < DayApproveData.length; i++) {
            DayApproveData[i].rowNo = i + 1;
            DayApproveData[i].action = "";
            DayApproveData[i].hoverOutAction = "selected";
            DayApproveData[i].flyOutAction = "rowSeclected";

            if (
              DayApproveData[i].isAutoPunch === "No" &&
              DayApproveData[i].isNotPunchOut === "No"
            ) {
              this.state.preSelectedRows.push(DayApproveData[i].rowNo);
              this.state.selectedRows.push(DayApproveData[i]);
            }
          }
          this.setState({
            underBudgetDeptsData: DayApproveData,
            loaderUnderBudget: false,
          });
          if (this.state.preSelectedRows.length > 0) {
            this.setState({
              multipleRowSelected: true,
              loaderUnderBudget: false,
            });
          }
        } else {
          this.setState({ loaderUnderBudget: false });
        }

        scrollIntoView(this.underBudgetDivRef.current, {
          align: {
            top: 0,
            left: 0,
            topOffset: 259,
            leftOffset: 0,
          },
        });
        // this.setState({ loaderUnderBudget: false });
        resolve();
        //this.setState({ loaderUnderBudget: false });
      })
      .catch((error) => {
        this.setState({ loaderUnderBudget: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  }

  rowApprove = (row: any, actionItem: any) => {
    
    this.setState({ loaderUnderBudget: true, multipleRowSelected: false });
    let parentRow = this.state.parentRow;
    let data = this.state.underBudgetDeptsData;
    let requestArray: any = [];
    let UnApproveDeptList="";
    for (let i = 0; i < data.length; i++) {
      UnApproveDeptList = data[i].unApproveDeptList;      
    }
   
    if (actionItem === "singleApprove") {
      let request = {} as IApproveSaveRequestDto;
      request.hotelID = Number(parentRow.hotelID);
      request.user_uniqueno = localStorageUser.user_UniqueID;
      request.userName = localStorageUser.userName;
      request.date = parentRow.date;
      request.explanation = "";
      request.tenantID = localStorageUser.tenantID;
      request.entryType = "detail";
      request.deptId = row.deptID;
      requestArray.push(request);
      request.UnApproveDeptList=UnApproveDeptList;
    } else {
      for (let i = 0; i < row.length; i++) {
        let request = {} as IApproveSaveRequestDto;
        request.hotelID = Number(parentRow.hotelID);
        request.user_uniqueno = localStorageUser.user_UniqueID;
        request.userName = localStorageUser.userName;
        request.date = parentRow.date;
        request.explanation = "";
        request.tenantID = localStorageUser.tenantID;
        request.entryType = "detail";
        request.deptId = row[i].deptID;
        //request.UnApproveDeptList="-1";
        request.UnApproveDeptList=UnApproveDeptList;
        requestArray.push(request);
      }
    }

    LaborDayApprove.ApproveLaborBudgetSetting(requestArray)
      .then(async (result: any | null) => {
        if (result != null) {
          let resultData = result.result as any;
          if (resultData.saveStatus === "Success") {
            if (actionItem === "singleApprove") {
              let indexOf = data.indexOf(row);
              data.splice(indexOf, 1);
              this.props.underBudgetApprove("Approved", "underBudget");
            } else if (actionItem === "bulkApprove") {
              for (let i = 0; i < row.length; i++) {
                let indexOf = data.indexOf(row[i]);
                data.splice(indexOf, 1);
                this.props.underBudgetApprove("Approved", "underBudget");
              }
            }
            this.setState({
              underBudgetDeptsData: data,
              selectedRows: [],
              preSelectedRows: [],
            });

            for (let i = 0; i < data.length; i++) {
              let index = data.indexOf(data[i]);
              data[index].hoverOutAction = "";
              data[index].flyOutAction = "";
            }
            this.setState({ underBudgetDeptsData: data });

            toast.success(`Department(s) approved successfully`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
          } else {
            Utils.toastError(resultData.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
          }
        }
        this.setState({
          loaderUnderBudget: false,
        });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderUnderBudget: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  };

  refreshUnderBudgetChild() {
    this.underBudgetsDeptApprovalData();
  }

  underBudgetDetails = (row: any, rowIndex: any) => {
    row.parentName = "underBudget";
    this.props.positionDetail(row);
  };

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    
    // if (isExpand) {
    //   row.action = "Expended";
    //   this.setState(() => ({
    //     tableExpended: [...this.state.tableExpended, row.rowNo],
    //   }));
    //   this.setState({expendedRowIndex: rowIndex,isExpand:true});
    // } else {
    //   row.action = "";
    //   this.setState(() => ({
    //     tableExpended: this.state.tableExpended.filter(
    //       (x: any) => x !== row.rowNo
    //     ),
    //   }));
    //   this.setState({expendedRowIndex: "",isExpand:false});
    // }

    if (this.state.tableExpended.length > 0) {
      // row.action = "";
      // this.setState(() => ({
      //   tableExpended: this.state.tableExpended.filter(
      //     (x: any) => x !== row.rowNo
      //   ),
      // }));

      this.setState(
        { expendedRowIndex: rowIndex, isExpand: true, loaderUnderBudget: true },
        () => {
          this.setState(() => ({
            tableExpended: [],
          }));

          let data = this.state.underBudgetDeptsData;
          for (let i = 0; i < data.length; i++) {
            let index = data.indexOf(data[i]);
            data[index].action = "";
          }
          this.setState({
            underBudgetDeptsData: data,
            loaderUnderBudget: false,
          });
        }
      );
    } else {
      row.action = "Expended";
      this.setState(() => ({
        tableExpended: [...this.state.tableExpended, row.rowNo],
      }));
      this.setState(
        {
          expendedRowIndex: rowIndex,
          isExpand: true,
          loaderUnderBudget: false,
        },
        () => {
          this.scrollExpendedInView();
        }
      );
    }
  };

  headerFormat = (column: { text: React.ReactNode }, colIndex: any) => {
    if (this.state.multipleRowSelected === true) {
      return (
        <div className="headerApproveDropDown">
          <Button
            className="btn-outline-primary approvalButton"
            onClick={() => {
              this.rowApprove(this.state.selectedRows, "bulkApprove");
            }}
          >
            Approve
          </Button>
        </div>
      );
    } else {
      return <span className="underBudgetDepartment">{column.text}</span>;
    }
  };

  handlePositionDetails = (row: any, rowIndex: any) => {
    this.underBudgetDetails(row, rowIndex);
  };

  onRowSelect = (
    selectedRow: any,
    isSelect: boolean,
    rowIndex: any,
    e: any
  ) => {
    let data = this.state.underBudgetDeptsData;
    let selectedRows = this.state.selectedRows;
    let preSelectedRows = this.state.preSelectedRows;
    if (isSelect === true) {
      if (
        selectedRow.isAutoPunch === "No" &&
        selectedRow.isNotPunchOut === "No"
      ) {
        selectedRows.push(selectedRow);
        preSelectedRows.push(selectedRow.rowNo);
      }
      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });

        for (let i = 0; i < data.length; i++) {
          let index = data.indexOf(data[i]);
          data[index].hoverOutAction = "selected";
          data[index].flyOutAction = "rowSeclected";
        }
        this.setState({ underBudgetDeptsData: data });
      } else {
        this.setState({
          multipleRowSelected: false,
        });
      }
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,
      });
    } else {
      let indexOf = selectedRows.indexOf(selectedRow);
      selectedRows.splice(indexOf, 1);
      preSelectedRows.splice(indexOf, 1);
      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });
      } else {
        this.setState({
          multipleRowSelected: false,
        });

        for (let i = 0; i < data.length; i++) {
          let index = data.indexOf(data[i]);
          data[index].hoverOutAction = "";
          data[index].flyOutAction = "";
        }
        this.setState({ underBudgetDeptsData: data });
      }
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,
      });
    }
  };

  onRowSelectAll = (isSelect: any, rows: any, e: any) => {
    let data = this.state.underBudgetDeptsData;
    if (isSelect === true) {
      let tempSelectedRows: any = [];
      let selectedRows = this.state.selectedRows;
      let preSelectedRows = this.state.preSelectedRows;
      selectedRows = tempSelectedRows;
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].isAutoPunch === "No" && rows[i].isNotPunchOut === "No") {
          selectedRows.push(rows[i]);
          preSelectedRows.push(rows[i].rowNo);
        }
        let index = data.indexOf(rows[i]);
        data[index].hoverOutAction = "selected";
        data[index].flyOutAction = "rowSeclected";
      }

      this.setState({ underBudgetDeptsData: data });

      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });
      } else {
        this.setState({ multipleRowSelected: false });
      }
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,
      });
    } else {
      this.setState({
        multipleRowSelected: false,
        selectedRows: [],
        preSelectedRows: [],
      });

      for (let i = 0; i < data.length; i++) {
        let index = data.indexOf(data[i]);
        data[index].hoverOutAction = "";
        data[index].flyOutAction = "";
      }
      this.setState({ underBudgetDeptsData: data });
    }
  };

  scrollExpendedInView = () => {
    if (this.state.tableExpended.length > 0) {
      setTimeout(() => {
        //
        scrollIntoView(this.expendedUnderBudgetRef.current, {
          align: {
            top: 0,
            left: 0,
            topOffset: 259,
            leftOffset: 0,
          },
        });
      }, 0);
    } else {
      return false;
    }
  };

  render() {
    const underBudgetDeptsSelectRow = {
      mode: "checkbox",
      classes: "selection-row",
      selected: this.state.preSelectedRows,
      clickToExpand: true,
      onSelect: (
        selectedRow: any,
        isSelect: boolean,
        rowIndex: any,
        e: any
      ) => {
        this.onRowSelect(selectedRow, isSelect, rowIndex, e);
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        this.onRowSelectAll(isSelect, rows, e);
      },
    };

    const rowClasses = (row: any, rowIndex: any) => {
      if (row.isAutoPunch === "Yes" || row.isNotPunchOut === "Yes") {
        return "row-disabled";
      }
      // if (
      //   (this.state.isExpand === true && row.action === "Expended") ||
      //   Number(this.state.expendedRowIndex) === rowIndex
      // ) {
      //   return "row-Expended";
      // } else if (this.state.isExpand === true) {
      //   return "row-Collapsed";
      // } else {
      //   return "row-Action";
      // }
    };

    const expandRow = {
      renderer: (row: any) => {
        // this.scrollExpendedInView();
        const rowColumns = [
          {
            dataField: "",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "position",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <EllipsisWithTooltip placement="bottom">
                <span className="rowPostion">{row.positionName}</span>
              </EllipsisWithTooltip>
            ),
          },
          {
            dataField: "budgeted",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBudgeted">{row.budgetTotal}</span>
            ),
          },
          {
            dataField: "actual",
            formatter: (cell: any, row: any, rowIndex: any) => {
              let actualTotal = Number(row.actualTotal.replace(":", "."));
              let budgetTotal = Number(row.budgetTotal.replace(":", "."));
              if (rowIndex >= 0) {
                return (
                  <span
                    className={
                      actualTotal > budgetTotal ? "rowActual red" : "rowActual"
                    }
                  >
                    {row.actualTotal}
                  </span>
                );
              }
            },
          },
          {
            dataField: "",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <Button
                className="btn-details underBudgetDetails"
                onClick={() => this.underBudgetDetails(row, rowIndex)}
              >
                Details
              </Button>
            ),
          },
          {
            dataField: "",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <Dropdown className="more-action" alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"Details"}
                    onClick={() => this.handlePositionDetails(row, rowIndex)}
                  >
                    Details
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ),
          },
        ];
        return (
          <div className="rowExpand" ref={this.expendedUnderBudgetRef}>
            <BootstrapTable
              keyField="rowNo"
              data={row.positionExpended}
              columns={rowColumns}
              hover
            />
          </div>
        );
      },
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      onlyOneExpanding: true,
    };

    const underBudgetDeptscolumns = [
      {
        dataField: "action",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.action === "Expended") {
            return (
              <span className="chevronExpended">
                <img src={chevronRight} alt="chevron Right" />
              </span>
            );
          } else {
            return (
              <span className="chevronCollpsed">
                <img src={chevronRight} alt="chevron Right" />
              </span>
            );
          }
        },
      },
      {
        dataField: "departMentName",
        text: "Department",
        headerFormatter: this.headerFormat,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="underBudgetDeptsDepartment">
            <EllipsisWithTooltip placement="bottom">
              <span className="underBudgetDeptsDepartment-name font-weight-bold">
                {row.departMentName}
              </span>
            </EllipsisWithTooltip>
            {row.isAutoPunch === "Yes" && (
              <span className="autoPunch-status">APO</span>
            )}
            {row.isNotPunchOut === "Yes" && (
              <span className="notPunch-status">Open</span>
            )}
          </div>
        ),
      },
      {
        dataField: "bgtDeptTotal",
        text: "Budgeted",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <span className="underBudgetDeptsBudgeted">{row.bgtDeptTotal}</span>
        ),
      },
      {
        dataField: "actualDeptTotal",
        text: "Actual",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <span className="underBudgetDeptsActual">{row.actualDeptTotal}</span>
        ),
      },
      {
        dataField: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Button
            className="btn-details rowDetails"
            onClick={() => this.underBudgetDetails(row, rowIndex)}
          >
            Details
          </Button>
        ),
      },
      {
        dataField: "hoverOutAction",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.hoverOutAction !== "selected") {
            const stopPropagation = (e: { stopPropagation: () => any }) =>
              e.stopPropagation();

            return (
              <div className="underBudgetDeptAction" onClick={stopPropagation}>
                <Button
                  className="btn-outline-primary approvalButton"
                  onClick={() => {
                    this.rowApprove(row, "singleApprove");
                  }}
                >
                  Approve
                </Button>
              </div>
            );
          }
        },
      },
      {
        dataField: "flyOutAction",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.flyOutAction === "") {
            const stopPropagation = (e: { stopPropagation: () => any }) =>
              e.stopPropagation();
            return (
              <Dropdown
                className="more-action"
                alignRight
                onClick={stopPropagation}
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"Approve"}
                    onClick={() => this.rowApprove(row, "singleApprove")}
                  >
                    Approve
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          } else if (row.flyOutAction === "rowSeclected") {
            const stopPropagation = (e: { stopPropagation: () => any }) =>
              e.stopPropagation();
            return <div onClick={stopPropagation}></div>;
          }
        },
      },
    ];

    return (
      <>
        {this.state.loaderUnderBudget ? (
          <ReactPageLoader useas={"daysToApproveExpand"} />
        ) : (
          <div className="underBudgetDeptsTable" ref={this.underBudgetDivRef}>
            <BootstrapTable
              keyField="rowNo"
              data={this.state.underBudgetDeptsData}
              columns={underBudgetDeptscolumns}
              hover
              selectRow={underBudgetDeptsSelectRow}
              expandRow={expandRow}
              rowClasses={rowClasses}
            />
          </div>
        )}
      </>
    );
  }
}
