import * as React from "react";
import { Button, Container, Dropdown, Form, Spinner } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { toast } from "react-toastify";
import { UserManagement } from "../../Common/Services/UserManagement";
import { bankSyncServices } from "../../Common/Services/bankSyncServices";
import "../../Common/Assets/Styles/Modules/epay-onboarding.scss";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { resolve, reject } from "q";
import _ from "lodash";
import { CommonService } from "../../Common/Services/CommonService";
import { Utils } from "../../Common/Utilis";
export class EpayOnboarding extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            ePayTenant: { id: -1, name: "", status: "" },
            tenantSearch: "",
            isTenantLoading: false,
            tenantsList: [],
            filterTenantList: [],
            tableData: [],
            deftableData: [],
            isTableLoading: false,
            isbulkSelect: false,
            dataSearch: "",
            isEPayBulkSelect: false,
            isSubmitLoading: false,
            isDownloadReady: false,
            BlobbaseUrl: "",
        };
    }

    componentDidMount() {
        this.getTenantsList();
        this.getBlobbaseUrl();
    }
    getBlobbaseUrl = () => {

        let keyName = "BlobbaseUrl";
        let BlobbaseUrl = "";
        CommonService.GetInnflowConfigKeyValue(keyName)
          .then(async (result: any | null) => {
            console.log(result);
            if (result !== null) {
              BlobbaseUrl = result;
              this.setState({ BlobbaseUrl: BlobbaseUrl });
            }
    
          });
      }
    getTenantsList = () => {
        this.setState({ isTenantLoading: true })
        UserManagement.GetTenantList().then(async (result: any) => {
            const data = result?.result?.result;
            let filterItem: any[] = [];
            filterItem = data.filter((item: any) => item.status.toString().toLocaleLowerCase() === "active")
            this.setState({ tenantsList: filterItem, filterTenantList: filterItem, isTenantLoading: false });
        })
    }

    handleAccordion = (item: any) => {
        let { tableData } = this.state;
        let currentType = item.acctType.toString().toLocaleLowerCase();
        tableData.forEach((item: any) => {
            if (item.acctType.toString().toLocaleLowerCase() === currentType) {
                item.isToggle = !item.isToggle;
            }
        });
        this.setState({ tableData });
    }

    searchItems = (e: any) => {
        const dataList = Object.assign([], JSON.parse(JSON.stringify(this.state.tenantsList)));
        let { filterTenantList } = this.state;
        let result = e.target.value;
        this.setState({ tenantSearch: result });
        if (result !== "") {
            filterTenantList = dataList.filter((o: any) => o.tenantname.toLowerCase().includes(result.toLowerCase().trim()));
        } else {
            filterTenantList = dataList;
        }
        this.setState({ filterTenantList });
    };

    tenantDDToggle = (e: any) => {
        const dataList = Object.assign([], JSON.parse(JSON.stringify(this.state.tenantsList)));
        this.setState({ tenantSearch: "", filterTenantList: dataList });
        (e === true) && setTimeout(() => { $("#idTenantSearch").focus(); }, 50);
    }

    tanentChange = (item: any) => {
        let { ePayTenant } = this.state;
        ePayTenant.id = item.tenantid;
        ePayTenant.name = item.tenantname;
        ePayTenant.status = item.status;
        this.setState({
            ePayTenant,
            tableData: [],
            deftableData: [],
            isTableLoading: true,
            isbulkSelect: false,
            dataSearch: "",
            isEPayBulkSelect: false,
            isDownloadReady: false
        }, () => {
            this.GetePayOnboardingTenantWiseList(item.tenantid);
            this.isEapyDocumentExists(item.tenantid);
        });
    }

    GetePayOnboardingTenantWiseList = (tenantid: any) => {
        let request: any = {};
        request.Tenantid = tenantid;
        bankSyncServices.GetePayOnboardingTenantWiseList(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    let data = result?.result;
                    data.forEach((o: any) => { o["isChecked"] = false; o["isDisabled"] = false; o["isSelectAll"] = false });
                    let isData = this.setTreeView(data);
                    isData.forEach((item: any, index: any) => {
                        item.isToggle = index === 0 ? true : false;
                    })
                    // let { isDownloadReady } = this.state;
                    // isDownloadReady = data.some((o: any) => o.isdownloaded.toString().toLocaleLowerCase() === "yes");
                    this.setState({
                        tableData: isData,
                        deftableData: data,
                        isTableLoading: false,
                        // isDownloadReady
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isTableLoading: false, isDownloadReady: false })
                reject();
            });
    }

    setTreeView = (list: any) => {
        let filterData: any[] = [];
        let accFilter = _.uniqBy(list, o => o.acctType?.toLowerCase()?.trim());
        accFilter.forEach((item: any, index: any) => {
            let hidFilter: any = list.filter((o) => o.acctType === item.acctType);
            hidFilter = _.uniqBy(hidFilter, o => o.hid);
            let row: any = {}
            row.srid = item.srid;
            row.acctType = item.acctType;
            row.isSelectAll = item.isSelectAll;
            row.ehidList = [];
            hidFilter.forEach(item2 => {
                let newArray: any = { ehid: "", status: "", data: {} };
                newArray.data = list.filter((it: any) => (it.hid === item2.hid && it.acctType === item2.acctType));
                newArray.ehid = item2.lettercode;
                newArray.acctType = item2.acctType;
                newArray.status = newArray.data.some((it: any) => it.isdownloaded?.toString().toLocaleLowerCase() === "yes") ? "Download Ready" : "";
                row.ehidList.push(newArray);
            });
            filterData.push(row);
        });
        return filterData;
    }

    handleDataSearch = (e: any) => {
        const dataList = Object.assign([], JSON.parse(JSON.stringify(this.state.deftableData)));
        let result = e.target.value
        this.setState({ dataSearch: result });
        let { tableData } = this.state;
        if (result !== "") {
            tableData = this.setTreeView(dataList.filter(item =>
                item.lettercode.toLowerCase().includes(result.toLowerCase().trim()) ||
                item.bName.toLowerCase().includes(result.toLowerCase().trim())
            ))
            tableData.map((o: any) => o.isToggle = true);
        } else {
            tableData = this.setTreeView(dataList);
            tableData.map((o: any, i: any) => o.isToggle = i === 0 ? true : false)
        }
        this.setState({ tableData });
    }

    checkaccTypeLength = (type: any) => {
        let { deftableData, dataSearch, tableData } = this.state;
        let totalLength: any[] = [];
        let allData = this.setTreeView(deftableData);
        let filterData: any = dataSearch !== "" ? tableData : allData;
        filterData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.data.forEach((dataList: any) => {
                    if (type.toString().toLocaleLowerCase() === dataList.acctType.toString().toLocaleLowerCase()) {
                        totalLength.push(dataList);
                    }
                });
            });
        });
        return totalLength;
    }

    checkTotalLength = () => {
        let { deftableData, dataSearch, tableData } = this.state;
        let totalLength: any[] = [];
        let allData = this.setTreeView(deftableData);
        let filterData: any = dataSearch !== "" ? tableData : allData;
        filterData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.data.forEach((dataList: any) => {
                    totalLength.push(dataList);
                });
            });
        });
        return totalLength;
    }

    handleSelectCheck = (event: any, list: any) => {
        let { tableData, deftableData } = this.state;
        let isChecked = event.target.checked;
        let allSelect: any[] = [];
        tableData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.data.forEach((dataList: any) => {
                    if (dataList?.srid === list?.srid && list?.acctType.toString().toLocaleLowerCase() === dataList?.acctType.toString().toLocaleLowerCase()) {
                        dataList.isChecked = isChecked;
                    }
                });
            });
        });
        deftableData.forEach((o: any) => {
            if (o?.srid === list?.srid && list?.acctType.toString().toLocaleLowerCase() === o?.acctType.toString().toLocaleLowerCase()) {
                o.isChecked = isChecked;
                o.isSelectAll = isChecked;
            }
        });
        allSelect = this.checkTotalLength().filter((o: any) => o?.isChecked);
        let isEqualLen = (allSelect.length === this.checkTotalLength().length);
        this.setState({ tableData, isbulkSelect: isEqualLen, isEPayBulkSelect: (allSelect.length > 0) });
    }

    handleSelectAll = (type: any, isChecked) => {
        let { tableData, deftableData, dataSearch } = this.state;
        let totalLength: any[] = [];
        let allSelect: any[] = [];
        tableData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.data.forEach((dataList: any) => {
                    if (type.acctType.toString().toLocaleLowerCase() === dataList.acctType.toString().toLocaleLowerCase()) {
                        dataList.isChecked = !isChecked;
                        totalLength.push(dataList);
                    }
                });
            });
        });
        deftableData.forEach((o: any) => {
            if (type.acctType.toString().toLocaleLowerCase() === o.acctType.toString().toLocaleLowerCase() &&
                (o.lettercode.toLowerCase().includes(dataSearch.toLowerCase().trim()) || o.bName.toLowerCase().includes(dataSearch.toLowerCase().trim()))
            ) {
                o.isChecked = !isChecked;
            }
        });
        allSelect = this.checkTotalLength().filter((o: any) => o?.isChecked);
        let isEqualLen = (allSelect.length === this.checkTotalLength().length);
        this.setState({ tableData, deftableData, isbulkSelect: isEqualLen, isEPayBulkSelect: (allSelect.length > 0) });
    }

    isAllSelected = (types) => {
        let allSelect = this.checkaccTypeLength(types).filter((o: any) => o.isChecked);
        return (allSelect.length === this.checkaccTypeLength(types).length);
    }

    handleSaveEPay = () => {
        this.setState({ isSubmitLoading: true });
        let { tableData, ePayTenant } = this.state;
        let isSelectedHid: any[] = [];
        let isSelectedOprId: any[] = [];
        let request: any = {};
        request.Tenantid = ePayTenant.id;
        let isCheckedItems: any = [];
        tableData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.data.forEach((dataList: any) => {
                    if (dataList.isChecked) {
                        isCheckedItems.push(dataList);
                    }
                });
            });
        });
        isCheckedItems.forEach((chkitem: any) => {
            tableData.forEach((item: any) => {
                item.ehidList.forEach((hidList: any) => {
                    if (hidList.ehid === chkitem.lettercode && hidList.acctType === chkitem.acctType) {
                        hidList.status = "Pending";
                    }
                });
            })
        })
        tableData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.data.forEach((dataList: any) => {
                    dataList.isDisabled = true;
                });
            });
        });
        this.setState({ tableData });
        isCheckedItems.forEach((o: any) => isSelectedHid.push(o.hid));
        isCheckedItems.forEach((o: any) => isSelectedOprId.push(o.oprid));
        request.OprID = isSelectedOprId.toString();
        request.EHID = isSelectedHid.toString();
        bankSyncServices.GetePayOnboardingCustomerID(request)
            .then(async (result: any | null) => {
                if (result.success) {
                    toast.success("Successfully Submitted.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({
                        isSubmitLoading: false,
                        isbulkSelect: false,
                        dataSearch: "",
                        isEPayBulkSelect: false,
                        isTableLoading: true,
                        isDownloadReady: true
                    }, () => {
                        this.GetePayOnboardingTenantWiseList(ePayTenant.id);
                    });
                } else {
                    this.setState({ isSubmitLoading: false });
                    Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isSubmitLoading: false });
                reject();
            });
    }
    DeleteePayOnboardingAutomationDocument = async (type: any) => {
        debugger;
        let tenantId = this.state.ePayTenant.id;  
        let result =   await bankSyncServices.DeleteePayOnboardingAutomationFile(tenantId);
     
                        
                    this.setState({ isDownloadReady: false });                
        
    }
    downloadFile = (type: any) => {
        debugger;
        let tenantId = this.state.ePayTenant.id;
        let IsDelete = type === "" ? true : false;
        bankSyncServices.DownloadePayOnboardingAutomationDocument("Samplechecks.pdf", tenantId, IsDelete)
            .then(async (result: any | null) => {
                if (type === "") {
                    this.downloadExcel(tenantId, IsDelete);
                    this.setState({ isDownloadReady: false });
                } else {
                    // result !== undefined && 
                    this.setState({ isDownloadReady: true });
                }
                resolve();
            })
            .catch((error) => {

                reject();
            });
    }
    isEapyDocumentExists =  (type: any) => {
        
        let tenantId = this.state.ePayTenant.id;        
        bankSyncServices.IsEapyDocumentExists({tenantId})
            .then(async (result: any | null) => {

                if (result !== null) {
                     
                    if (result)     
                    {
                    this.setState({ isDownloadReady: true });
                    }
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }
    downloadExcel = (tenantId, IsDelete) => {
        bankSyncServices.DownloadePayOnboardingAutomationDocument("epayonboardingAccounts.csv", tenantId, IsDelete)
            .then(async (result: any | null) => {
                resolve();
                this.setState({ isDownloadReady: false });
            })
            .catch((error) => {
                reject();
            });
    }
    downloadepayBoth = async (tenantId) => {
       
        this.downloadepayDocumentexcel(tenantId);
        this.downloadepayDocumentpdf(tenantId);
      
    };
    downloadepayDocumentpdf = (tenantId) => {
        debugger;
        let BlobbaseUrl = this.state.BlobbaseUrl;
        let fileName = "Samplechecks_"+tenantId+".pdf";
        //'https://innflowfilestoretest.blob.core.windows.net/innflowtemplate/ImportInvoiceForMultiHID.xlsx'
        fetch(BlobbaseUrl+'epayonboarding/'+tenantId+'/Files/Samplechecks.pdf', {
            method: 'GET', headers: { 'Content-Type': 'application/pdf' }
        })
            .then(async (response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                this.DeleteePayOnboardingAutomationDocument(tenantId);
            });
      };
      downloadepayDocumentexcel = (tenantId) => {
        let BlobbaseUrl = this.state.BlobbaseUrl;
        let fileName = "epayonboardingAccounts_"+tenantId+".csv";
        //'https://innflowfilestoretest.blob.core.windows.net/innflowtemplate/ImportInvoiceForMultiHID.xlsx'
        fetch(BlobbaseUrl+'epayonboarding/'+tenantId+'/Files/epayonboardingAccounts.csv', {
            method: 'GET', headers: { 'Content-Type': 'text/csv' }
        })
            .then(async (response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                
            });
      };
    render() {
        const { isTenantLoading, filterTenantList, ePayTenant, tableData, isTableLoading, dataSearch, isSubmitLoading, isEPayBulkSelect, isDownloadReady } = this.state;
        const checkItem = () => {
            return (
                <span className="itemcheck">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                    </svg>
                </span>
            )
        }
        return (
            <div className="ePayOnboardingControlMain">
                <Container fluid className="body-sec pos-rel">
                    <div className="page-heading underline d-flex">
                        <div className="mr-auto">ePay Onboarding</div>
                        <div className="action-group d-flex">{/**/}</div>
                    </div>
                    <div className="d-flex headerDownControl">
                        <div className="ePayTenantSelector">
                            <label>Tenant</label>
                            <div className="d-flex">
                                <Dropdown className={`${isTenantLoading ? "disabled-area" : ""} ePayTenantSelectorDropdown`} onToggle={this.tenantDDToggle}>
                                    <Dropdown.Toggle className="ePayButton" id="dropdown-Tenant">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="">
                                                {(ePayTenant.id !== -1 ? ePayTenant.name : "Select Tenant")}
                                            </div>
                                            <div className="ml-auto drop-arrow d-flex">
                                                <FiChevronDown />
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="ePayTenantSelectorMenu">
                                        <div className="ePayTenantSearch">
                                            <Form.Group controlId="ePayTenantSearch">
                                                <Form.Control
                                                    id="idTenantSearch"
                                                    type="text"
                                                    name="search"
                                                    value={this.state.tenantSearch}
                                                    autoComplete="off"
                                                    onChange={this.searchItems}
                                                    placeholder="Search Tenant"
                                                    autoFocus={true}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="dropdownItems">
                                            {filterTenantList.length > 0 ? (<>
                                                {filterTenantList.map((item: any, idx: any) => (
                                                    <Dropdown.Item key={idx} eventKey={item.tenantid} onClick={() => this.tanentChange(item)}>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className=""> {item.tenantname}</div>
                                                            <div className="ml-auto">
                                                                {(item.tenantid === ePayTenant.id) && (
                                                                    checkItem()
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </>) : (
                                                <Dropdown.Item>No Item Found</Dropdown.Item>
                                            )}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="ml-auto">
                                    
                                    {(ePayTenant.id !== -1 && isDownloadReady) && (<Button onClick={() => this.downloadepayBoth(ePayTenant.id)}> Download Ready</Button>)}
                                </div>
                            </div>
                        </div>
                    </div>

                    {ePayTenant.id !== -1 && (
                        <>
                            {isTableLoading ? (
                                <ReactPageLoader useas={"ePayOnboarding"} />
                            ) : (
                                <>
                                    <div className="ePayOnBoardingListItems">
                                        <div className="ePayOnBoardingListHeader d-flex align-self-center">
                                            <div className="ePaySearchInput">
                                                <Form.Group controlId="ePayDataSearch">
                                                    <Form.Control
                                                        type="text"
                                                        name="search"
                                                        value={dataSearch}
                                                        autoComplete="off"
                                                        onChange={this.handleDataSearch}
                                                        placeholder="Search"
                                                    />
                                                </Form.Group>
                                            </div>
                                            {/* <div className="SelectUnselectAll">
                                                <Button type="button" onClick={() => this.handleSelectAll()}>{this.state.isbulkSelect ? "Unselect All" : "Select All"}</Button>
                                            </div> */}
                                        </div>
                                        <div className="ePayOnBoardingListBody">
                                            {tableData.map((item: any, itemIdx: any) => (
                                                <div className="ePayListItems">
                                                    <Button className="ePayheaderbtn">
                                                        <div className="d-flex">
                                                            <div onClick={() => this.handleAccordion(item)} className={item.isToggle ? "activeToggle arrowIcon" : "toggleItem arrowIcon"}>
                                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg>
                                                            </div>
                                                            <div onClick={() => this.handleAccordion(item)} className="ePayHeaderTitle">{item.acctType}</div>
                                                            <Button className="select-all-btn" type="button" onClick={() => this.handleSelectAll(item, this.isAllSelected(item.acctType))}>
                                                                {this.isAllSelected(item.acctType) ? "Unselect All" : "Select All"}
                                                            </Button>
                                                        </div>
                                                    </Button>
                                                    <div className={`${item.isToggle ? "" : "d-none"} ePayCollapse`} id={`id_${itemIdx}`}>
                                                        <div className="ePayItemBody">
                                                            <ul className="">
                                                                {item.ehidList.map((hidList: any, hidIdx: any) => (
                                                                    <li key={hidIdx}>
                                                                        <div className="epayEhid">
                                                                            <div className="ehidHeader">{hidList.ehid}</div>
                                                                            {hidList.status !== "" && (
                                                                                <div className={`${hidList.status === "Download Ready" ? "bdgGreen" : "badge"} epayBadge`}>{hidList.status === "Download Ready" ? "" : hidList.status}</div>
                                                                            )}
                                                                        </div>
                                                                        <ul>
                                                                            {hidList.data.map((dataList: any, dataidx: any) => (
                                                                                <li key={dataidx}>
                                                                                    <Form.Check
                                                                                        type="checkbox"
                                                                                        disabled={dataList.isDisabled}
                                                                                        label={`${dataList.bName}`}
                                                                                        id={`id_${dataList.srid}`}
                                                                                        checked={dataList.isChecked}
                                                                                        onChange={(e: any) => this.handleSelectCheck(e, dataList)}
                                                                                    />
                                                                                    {/* oprid: {dataList.oprid} - HID: {dataList.hid} */}
                                                                                    {(dataList.isdownloaded?.toString().toLocaleLowerCase() === "yes") && (checkItem())}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="ePaySubmitPanel">
                                        <Button disabled={!isEPayBulkSelect || isSubmitLoading} onClick={this.handleSaveEPay}>
                                            {isSubmitLoading && (<Spinner size="sm" className="disabled-area" animation="border" />)} Submit</Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Container>
            </div>
        );
    }
}