import * as React from "react";
import { Container, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-input-range/lib/css/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { confirmAlert } from "react-confirm-alert";
import { resolve, reject } from "q";
import { InvoiceApprovalLeftPanel } from "./InvoiceApprovalLeftPanel";
import { InvoiceApprovalRightPanel } from "./InvoiceApprovalRightPanel";
import RouteLeavingGuard from "../../../Common/Components/RouteLeavingGuard";
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";
import MasterRoleLock from "../../../Common/Assets/Images/icons/forecast-lock.svg";
import { Register as registersevice } from "../../../Common/Services/Register";
import { Utils } from "../../../Common/Utilis";

let routeLeavingGuard: any = null;
let childRowValue: any;
export class InvoiceApproval extends React.Component<any, any> {
  private childRight: any;
  private childleft: any;
  constructor(props: any) {
    super(props);
    this.childRight = React.createRef();
    this.childleft = React.createRef();
    this.state = {
      isSaved: false,
      ApprovalList: [],
      isLeftPanelrefresh: true,
      fieldData: {
        ruleID: 0,
        ruleStatus: "",
        approvalName: "",
        approvalType: "Select rule type",
        threshold: "",
        mainGroup: "Select Main Group",
        mainGroupID: "0",
        subGroupOne: "Select Sub Group 1",
        subGroupOneID: "-1",
        subGroupTwo: "Select Sub Group 2",
        subGroupTwoID: "-1",
        vendor: "",
        coa: "",
        internalNotes: "",
        primaryApprover: "",
        secondaryApprover: "",
      },
      isPagePermission: true,
      changeValue: false,
      AAPermission:"No",
      isPanelLoad:"No",
    };

    this.addApproval = this.addApproval.bind(this);
    this.refreshleftpanel = this.refreshleftpanel.bind(this);
    this.isDuplicateRow = this.isDuplicateRow.bind(this);
    this.removeBlankRow = this.removeBlankRow.bind(this);
    this.isValidateModelPoupTab = this.isValidateModelPoupTab.bind(this);
  }

  componentDidMount() {
    this.GetAdvanceFeatureFlagPermission(-1);
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
  }

  GetAdvanceFeatureFlagPermission(hotelValue: any) {
    registersevice.GetAdvanceFeatureFlagPermission("InvoiceApprovals",hotelValue)
        .then(async (result: any | null) => {
            if (result !== null) {               
                this.setState({AAPermission: result.featureFlagPermission,isPanelLoad:"Yes"},()=>{
                  this.bindData();
                })
                         }
            else{
              this.setState({isPanelLoad:"Yes"},()=>{
                this.bindData();
              })
            }

            resolve();

        })
        .catch((error) => {

            reject();
        });
}

  beforeunload(e: any) {
    if (
      this.state.changeValue &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  // getMenuPermission = () => {
  //   let isPermission = false;
  //   let requestObject = {} as any;
  //   requestObject.permissionID = "ALL";
  //   requestObject.Module = "LaborManagement";
  //   requestObject.Type = "menu";
  //   RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
  //     .then(async (result: any | null) => {
  //       if (result != null && result.length > 0) {
  //         result.forEach((element: any) => {
  //           if (element.permissionID === 10029) {
  //             isPermission = true;
  //             this.setState({ isPagePermission: true });
  //           }
  //         });
  //         if (isPermission) {
  //           this.getBudgetPermission();
  //         } else {
  //           window.location.href = "/unauthorize-access";
  //         }
  //       }
  //       resolve();
  //     })
  //     .catch((err: any) => {
  //       Utils.toastError(`Server Error, ${err}`);
  //       reject();
  //     });
  // };

  bindData = () => {
    this.setState({ isLeftPanelrefresh: true });
    this.childRight.current.HideRightPanel();
    this.getApprovalList();
  };

  getApprovalList = () => {
    InvoiceApprovalS.GetInvoiceApprovalList()
      .then(async (result: any | null) => {
        let ApprovalData: any = [];
        if (result != null) {
          let appData = result as [];
          for (let i = 0; i < appData.length; i++) {
            let pushObject: any = {};
            pushObject = appData[i];
            pushObject.rowNo = i;
            ApprovalData.push(pushObject);
          }
          this.setState({ ApprovalList: ApprovalData }, () => {
            this.props?.updateParent(this.state.ApprovalList);
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error ${err}`);
        reject();
      });
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
    
  }

 

  updateState = (data: any) => {
    this.childRight.current.handleRowClick(data);
  };

  updateStateNewApproval = (data: any) => {
    this.setState({
      fieldData: data,
    });
    this.childRight.current.handleApprovalLeft(data);
  };

  addApproval = (e: any) => {
    if (this.state.isChangesTab) {
      this.isValidateModelPoupTab();
    }
    this.setState({ isHandlebadget: "newbudget" });
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.childRight.current.handleAddApproval(fieldData);
    this.childleft.current.removeSelectedRow(fieldData);
  };

  refreshleftpanel = () => {
    this.setState({
      isLeftPanelrefresh: false,
      isSaved: false,
      changeValue: false,
    });
    this.getApprovalList();
  };

  isDuplicateRow = () => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.childleft.current.removeSelectedRow(fieldData);
  };

  removeBlankRow = () => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.childleft?.current?.removeNewBlankRow(fieldData);
    this.childleft?.current?.removeSelectedRow(fieldData);
  };

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeUnsaved(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isChangeUnsaved = () => {
    this.setState({ changeValue: false }, () => {
      if (this.state.handleChangeValue === "rowClick") {
        if (this.childleft.current !== null) {
          this.childleft?.current?.rowEvents.onClick(
            "e",
            childRowValue,
            "rowIndex"
          );
        }
      }
    });
  };

  searchClear = () => {
    this.setState({ search: "" });
    this.setState({ hidList: this.state.hidFilterList });
  };

  changeValueFuntion = (type) => {
    this.setState({ changeValue: type });
  };

  childHandleChange = (value: any) => {
    this.setState({ handleChangeValue: value });
  };

  rowChildValue = (rowValue: any) => {
    childRowValue = rowValue;
  };

  deleteRuleHeader = (eventType: any) => {
    let { ApprovalList } = this.state;
    this.childRight?.current?.deleteRule(eventType, "", ApprovalList);
  }

  render() {
    const hoverOutEllipsis = () => {
      $(".tooltip").removeClass("show");
    };

    const inactiveBadge = (cell: any, row: any, rowIndex: any) => {
      if (row.status?.toLowerCase() === "inactive") {
        return (
          <div className="desc grey inactiveBadge">
            <span className="red-badges gray-bg">Inactive</span>
          </div>
        );
      } else {
        return (
          <></>
        )
      }
    }
    const columns = [
      {
        dataField: "ruleID",
        text: "",
        hidden: true,
      },
      {
        dataField: "ruleName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          let maxWidth = "";
          if (row.status?.toLowerCase() === "inactive")
            maxWidth = "82%"
          else
            maxWidth = "100%"
          return (
            <div>
              <div
                className="title"
                onWheel={() => hoverOutEllipsis()}
              >
                <div className="d-flex">
                  <div style={{ width: maxWidth, marginRight: "auto" }}>
                    <EllipsisWithTooltip placement="bottom">
                      {row.ruleName}
                    </EllipsisWithTooltip>
                  </div>

                  {inactiveBadge(cell, row, rowIndex)}

                </div>

              </div>
              <div
                className="desc"
                onWheel={() => hoverOutEllipsis()}
              >
                <EllipsisWithTooltip placement="bottom">
                  {row.appType}
                </EllipsisWithTooltip>
              </div>
            </div>
          )
        },
      },
      {
        dataField: "standardRule",
        text: "",
        formatter: (
          cell: any,
          row: any,
          rowIndex: any
        ) => (
          <div className="lockIconIA">
            {row.appType === "Standard" && (
              <div>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip">
                    System rules cannot be deleted
                  </Tooltip>}
                >
                  <img src={MasterRoleLock} alt="lock" />
                </OverlayTrigger>
              </div>
            )}
          </div>
        ),
      },
    ];


    return (
      <>
        <RouteLeavingGuard
          when={this.state.changeValue}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
            if (this.state.changeValue) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />
            <div className="section" style={{ display: "flex" }}>
                {this.state.isPanelLoad === "Yes" && (
                    <div className="IApproval-Setting d-flex justify-content-start">
                        <InvoiceApprovalLeftPanel
                            ref={this.childleft}
                            ApprovalList={this.state.ApprovalList}
                            columns={columns}
                            updateState={this.updateState.bind(this)}
                            updateStateNewApproval={this.updateStateNewApproval.bind(
                                this
                            )}
                            changeValue={this.state.changeValue}
                            isValidateModelPoupTab={this.isValidateModelPoupTab.bind(
                                this
                            )}
                            childHandleChange={this.childHandleChange}
                            rowChildValue={this.rowChildValue}
                            AAPermission={this.state.AAPermission}
                        />
                        <InvoiceApprovalRightPanel
                            ref={this.childRight}
                            updateState={[
                                this.updateState.bind(this),
                                this.state,
                            ]}
                            refreshleftpanel={this.refreshleftpanel}
                            isDuplicateRow={this.isDuplicateRow}
                            removeBlankRow={this.removeBlankRow}
                            changeValueFuntion={this.changeValueFuntion}
                            AAPermission={this.state.AAPermission}
                        />
                    </div>
                )}
            </div>
      </>
    );
  }
}
