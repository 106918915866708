import * as React from "react";
import { Container, Tabs, Tab, Dropdown, Button, Form, Row, Col } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import AutosizeInput from "react-input-autosize";
import DatePicker from "react-datepicker";
import { HeaderMenu } from "../../Common/Services/HeaderMenu";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import { Link, Redirect } from "react-router-dom";
import { Player } from "video-react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import createDOMPurify from "dompurify";
import { Utils } from "../../Common/Utilis";
const { SearchBar } = Search;
const DOMPurify = createDOMPurify(window);
export class HomeConfiguration extends React.Component<any, any> {
  private childHID: any;
  private input: any;
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.input = React.createRef();
    this.state = {
      isPermission: "No",
      key: "allCards",
      search: "",
      cardTypeValue: "Select Card Type",
      cardIndexValue: "Select",
      cardDismissibleValue: "Select",
      cardStatusValue: "Select",
      cardTypeList: [],
      selectedFile: '',
      selectedBgFile: '',
      selectedFilename: 'Select File',
      selectedBgFilename: 'Select File',
      confirmModalContent: [{ title: "Delete Card", desc: "Are you sure you want to delete", cancleAction: "Cancel", ActionTrue: "Delete" }],

      formData: {
        disputeID: 0,
        cardTitle: '',
        cardTypeID: 0,
        cardType: '',
        alwaysTop: 'No',
        dismiss: 'Yes',
        exDate: '5/25/2022',
        content: '',
        cta1Text: '',
        cta1Link: '',
        isFreshdeskLink:false,
        cta2Text: '',
        cta2Link: '',
        title2: '',
        videoUrl: '',
        publishDate: '5/25/2021',
        status: ''
      },
      cardData: [],
      allcardData: [],
      isDeleteModelShow: false,
      ischangeStatus: false,
      htmlItem: [],
      newID: 0,


    };
    this.handleChangeQuill = this.handleChangeQuill.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleSaveCard = this.handleSaveCard.bind(this);
    this.showdeletePopUp = this.showdeletePopUp.bind(this);
    // this.handleDelete = this.handleDelete.bind(this);
    this.handleNew = this.handleNew.bind(this);
    this.hadleDuplicate = this.hadleDuplicate.bind(this);
    this.hadleDiscard = this.hadleDiscard.bind(this);



  }

  componentDidMount() {
    this.validateHomeConfigarion();

  }


  validateHomeConfigarion() {
    HeaderMenu.ValidateHomeConfigarion()
      .then(async (result: any[] | null) => {
        if (result != null) {
          console.log(result);
          if (result.toString().toLowerCase() === "invalid") {
            window.location.href = "/unauthorize-access";
          }
          else {
            this.setState({ isPermission: "Yes" }, () => {
              this.getHomeCard("1", 0);
              this.getCardType();
            })

          }

        }
        resolve();
      })
      .catch((error) => {
        reject();

      });
  }


  handleNew = () => {

    let today = new Date();
    let date = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
    let formData = {
      disputeID: 0,
      cardTitle: '',
      cardTypeID: 0,
      cardType: '',
      alwaysTop: 'No',
      dismiss: 'Yes',
      exDate: null,
      content: '',
      cta1Text: '',
      cta1Link: '',
      isFreshdeskLink:false,
      cta2Text: '',
      cta2Link: '',
      title2: '',
      videoUrl: '',
      publishDate: date,
      status: ''
    }
    let selectedFile = '', selectedBgFile = '';
    let selectedFilename = 'Select File', selectedBgFilename = 'Select File';
    let cardTypeValue = "Select Card Type", cardIndexValue = "No", cardDismissibleValue = "Yes",
      cardStatusValue = "Select";
    this.setState({
      formData, selectedFile, selectedBgFile, selectedFilename, selectedBgFilename,
      cardTypeValue, cardIndexValue, cardDismissibleValue, cardStatusValue
    });
  }

  hadleDiscard() {

    this.handleNew();
    let selectedFile = '', selectedBgFile = '', selectedFilename = 'Select File', selectedBgFilename = 'Select File';

    this.setState({ ischangeStatus: false, selectedFile, selectedBgFile, selectedFilename, selectedBgFilename });

  }

  hadleDuplicate() {

    let new_state = Object.assign({}, this.state);
    let formData = new_state.formData;

    formData.disputeID = 0;
    formData.cardTitle = '';

    let selectedFile = '', selectedBgFile = '', selectedFilename = 'Select File', selectedBgFilename = 'Select File';

    this.setState({ formData, selectedFile, selectedBgFile, selectedFilename, selectedBgFilename });

  }

  showdeletePopUp() {
    this.setState({ isDeleteModelShow: true })
  }

  handleDelete = (mode: any) => {
    if (mode) {
      HeaderMenu.DeleteCardDetails(this.state.formData.disputeID)
        .then(async (result: any | null) => {

          if (result != null) {
            if (result.saveStatus === "Success") {

              toast.success(result.message, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "SaveHomeCard",
              });

              this.getHomeCard("1", 0);
              this.setState({ isDeleteModelShow: false, ischangeStatus: false })

            } else {
              Utils.toastError(result.message, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "SaveHomeCard",
              });
            }
          } else {
            Utils.toastError("Internal server error.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "SaveHomeCard",
            });
          }

          resolve();
        })
        .catch((error) => {
          reject();

        });
    }
    else {
      this.setState({ isDeleteModelShow: false })
    }

  }

  getCardType() {
    HeaderMenu.CardMasterDetails()
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ cardTypeList: result.filter(e => e.orderno === 3) })


        }
        resolve();
      })
      .catch((error) => {
        reject();

      });
  }


  getHomeCard(mode, newID) {
    HeaderMenu.AllCardDetails()
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ cardData: result, allcardData: result })
          if (mode === "2") {
            let row = result.filter(e => e.id === Number(newID));
            this.getSingleCardDetails(row[0])
          }
          else {
            this.handleNew();
          }

        }
        resolve();
      })
      .catch((error) => {
        reject();

      });
  }

  isFormValid() {
    let exDate = this.state.formData.exDate;
    let pubDate = this.state.formData.publishDate;
    if (this.state.formData.content.trim().length === 0 || this.state.formData.content === '<p><br></p>') {
      Utils.toastError("Please enter Content.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if (this.state.formData.cardTitle.trim().length === 0) {
      Utils.toastError("Please enter Title.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if (this.state.formData.cardTypeID === 0) {
      Utils.toastError("Please select Card Type.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if (this.state.formData.alwaysTop.trim().length === 0) {
      Utils.toastError("Please select Always on Top.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if (this.state.formData.dismiss.trim().length === 0) {
      Utils.toastError("Please select Dismissible.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if ((exDate != null) &&
      (new Date(exDate) <= new Date(pubDate))) {
      Utils.toastError("Expiration date should be greater then publish date", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if (this.state.formData.content.trim().length === 0) {
      Utils.toastError("Please enter Content.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if ((this.state.formData.disputeID === 0) && (this.state.formData.cardType != "Tall Video card" &&
      this.state.formData.cardType != "Text Only card" &&
      this.state.formData.cardType != "Tall Image Background card")
      && (this.state.selectedFilename === 'Select File')) {
      Utils.toastError("Please select Icon Url.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }

    else if ((this.state.formData.disputeID === 0) && (this.state.formData.cardType === "Event card" ||
      this.state.formData.cardType === "Tall Image Background card")
      && (this.state.selectedBgFilename === 'Select File')) {
      Utils.toastError("Please select Bg image Url.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }


    else if ((this.state.formData.cardType != "Tall Video card") &&
      (this.state.formData.cta1Text.trim().length === 0)) {
      Utils.toastError("Please enter CTA1 Text.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if ((this.state.formData.cardType != "Tall Video card") &&
      (this.state.formData.cta1Link.trim().length === 0)) {
      Utils.toastError("Please enter CTA1 Link.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if ((this.state.formData.cardType === "Event card"
      || this.state.formData.cardType === "Tall Center-aligned Icon card" || this.state.formData.cardType === "Text Only card") &&
      (this.state.formData.cta2Text.trim().length === 0)) {
      Utils.toastError("Please enter CTA2 Text.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if ((this.state.formData.cardType === "Event card"
      || this.state.formData.cardType === "Tall Center-aligned Icon card" || this.state.formData.cardType === "Text Only card") &&
      (this.state.formData.cta2Link.trim().length === 0)) {
      Utils.toastError("Please enter CTA2 Link.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if ((this.state.formData.cardType === "Tall Image Background card") &&
      (this.state.formData.title2.trim().length === 0)) {
      Utils.toastError("Please enter Title2.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if ((this.state.formData.cardType === "Tall Video card") &&
      (this.state.formData.videoUrl.trim().length === 0)) {
      Utils.toastError("Please enter VideoUrl.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    else if (this.state.formData.status.trim().length === 0) {
      Utils.toastError("Please select Status.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "SaveHomeCard"
      });
      return false;
    }
    return true;
  }

  handleSaveCard() {

    if (this.isFormValid()) {

      HeaderMenu.SaveCard(this.state.formData, this.state.selectedFile, this.state.selectedBgFile)
        .then(async (result: any | null) => {

          if (result != null) {
            if (result.saveStatus === "Success") {

              toast.success(result.message, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "SaveHomeCard",
              });
              this.setState({ newID: result.actualID })
              this.getHomeCard("2", result.actualID);

            } else {
              Utils.toastError(result.message, {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "SaveHomeCard",
              });
            }
          } else {
            Utils.toastError("Internal server error.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "SaveHomeCard",
            });
          }

          resolve();
        })
        .catch((error) => {
          reject();

        });
    }
  }



  onFileChange = (event: any, mode) => {

    if(event.target.files[0] != undefined && event.target.files[0].name.includes(".exe",event.target.files[0].name.length-4)) {
      // Utils.toastError(".exe is not a valid file type.", {});
    }
    else {
      if (mode === "1") {
        this.setState({ ischangeStatus: true, selectedFile: event.target.files[0], selectedFilename: event.target.files[0] === undefined ? 'Select File' : event.target.files[0].name });
      }
      else {
        this.setState({ ischangeStatus: true, selectedBgFile: event.target.files[0], selectedBgFilename: event.target.files[0] === undefined ? 'Select File' : event.target.files[0].name });
      }
    }

    console.log(event.target.files[0]);

  };


  changeCardType(cardType: string) {

    let new_state = Object.assign({}, this.state);
    let formData = new_state.formData;
    if (cardType === "Left-aligned Icon card") {
      formData.cta2Text = ''
      formData.cta2Link = ''
      formData.title2 = ''
      formData.videoUrl = ''

      let selectedBgFilename = 'Select File'
      this.setState({ selectedBgFilename })
    }
    else if (cardType === "Event card") {
      formData.title2 = ''
    }
    else if (cardType === "Text Only card") {
      formData.cta2Text = ''
      formData.cta2Link = ''
      formData.title2 = ''
      formData.videoUrl = ''
      let selectedFilename = 'Select File'
      let selectedBgFilename = 'Select File'
      this.setState({ selectedFilename, selectedBgFilename })
    }
    else if (cardType === "Tall Video card") {
      formData.cta1Text = ''
      formData.cta1Link = ''
      formData.cta2Text = ''
      formData.cta2Link = ''
      formData.title2 = ''

      let selectedFilename = 'Select File'
      let selectedBgFilename = 'Select File'
      this.setState({ selectedFilename, selectedBgFilename })
    }
    else if (cardType === "Tall Image Background card") {
      formData.cta2Text = ''
      formData.cta2Link = ''
      formData.videoUrl = ''
      formData.title2 = ''
      let selectedFilename = 'Select File'
      //let  selectedBgFilename = 'Select File'
      this.setState({ selectedFilename })
    }
    else if (cardType === "Tall Center-aligned Icon card") {
      formData.videoUrl = ''
      formData.title2 = ''
      // let  selectedFilename = 'Select File'
      let selectedBgFilename = 'Select File'
      this.setState({ selectedBgFilename })
    }
    this.setState({ formData: formData })
  }

  handleChangeQuill(value: any) {
    let new_state = Object.assign({}, this.state);
    let formData = new_state.formData;
    formData.content = DOMPurify.sanitize(value);
    this.setState({ formData: formData })
    if ((value.replace(/<[^>]+>/g, '')).length > 0) {
      this.setState({ ischangeStatus: true })
    }
  }

  handleChange = (event: any, FieldType: string) => {
    let new_state = Object.assign({}, this.state);
    let formData = new_state.formData;
    if (FieldType === "expireonselect") {
      if (event === null) {
        formData.exDate = null;
      }
    }
    else if (FieldType === "title") {
      formData.cardTitle = event.target.value;
    }
    else if (FieldType === "cardType") {
      formData.cardType = event.cardType;
      formData.cardTypeID = event.cardID;
      this.changeCardType(event.cardType);
    }
    else if (FieldType === "always") {
      formData.alwaysTop = event;
    }
    else if (FieldType === "dismiss") {
      formData.dismiss = event;
    }
    else if (FieldType === "content") {
      formData.content = event;
    }
    else if (FieldType === "cta1Text") {
      formData.cta1Text = event.target.value;
    }
    else if (FieldType === "cta1Link") {
      formData.cta1Link = event.target.value;
    }
    else if (FieldType === "isFreshdeskLink") {
      formData.isFreshdeskLink = event.target.checked;
    }
    else if (FieldType === "cta2Text") {
      formData.cta2Text = event.target.value;
    }
    else if (FieldType === "cta2Link") {
      formData.cta2Link = event.target.value;
    }
    else if (FieldType === "status") {
      formData.status = event;
    }
    else if (FieldType === "title2") {
      formData.title2 = event.target.value;
    }
    else if (FieldType === "videoUrl") {
      formData.videoUrl = event.target.value;
    }
    else if (FieldType === "exDate") {

      let today = new Date(event);
      let dateNew = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
      formData.exDate = dateNew;
    }
    else if (FieldType === "publishDate") {
      let today = new Date(event);
      let dateNew = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
      formData.publishDate = dateNew;
    }
    console.log(formData);

    this.setState({ formData: formData, ischangeStatus: true })
  }


  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({ ischangeStatus: false }, () => {
        this.getSingleCardDetails(row);
      })

    },
  };
  getSingleCardDetails(row) {
    ;
    this.setState({ ischangeStatus: false });
    let new_state = Object.assign({}, this.state);
    let formData = new_state.formData;

    let exDate1;
    let date1;
    let today = new Date(row.publishDate);
    let date = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();

    if (row.exDate.length > 0) {
      exDate1 = new Date(row.exDate);
      date1 = exDate1.getMonth() + 1 + '/' + exDate1.getDate() + '/' + exDate1.getFullYear();
    }

    formData.disputeID = row.id;
    formData.cardTitle = row.title;
    formData.cardType = row.cardType;
    formData.cardTypeID = row.cardID;
    formData.alwaysTop = row.alwaysonTop;
    formData.dismiss = row.dismiss;
    formData.content = DOMPurify.sanitize(row.content);
    formData.cta1Text = row.cta1Text;
    formData.cta1Link = row.cta1Link;
    formData.isFreshdeskLink = row.isFreshdeskLink;
    formData.cta2Text = row.cta2Text;
    formData.cta2Link = row.cta2Link;
    formData.status = row.status;
    formData.publishDate = date;//new Date(row.publishDate) ;
    formData.exDate = row.exDate.length > 0 ? date1 : null;// row.exDate;
    this.handleCardPreview(row);

    this.setState({
      ischangeStatus: false, newID: 0,
      cardTypeValue: row.cardType, cardIndexValue: row.alwaysonTop,
      cardDismissibleValue: row.dismiss, cardStatusValue: row.status
    }, () => {
      this.setState({ formData: formData, ischangeStatus: false })
    })
  }

  handleSelect = (eventKey: any) => {
    this.setState({ key: eventKey });
    let cardlist: any[] = [];
    let selectcardlist: any[] = [];
    cardlist = Object.assign([], JSON.parse(JSON.stringify(this.state.allcardData)));
    if (eventKey === "publishedCards") {
      selectcardlist = cardlist.filter(e => e.status === "Published" && e.showPublish === "Show");
    }
    else {
      selectcardlist = cardlist;
    }
    this.setState({ cardData: [] }, () => {
      this.setState({ cardData: selectcardlist })
    })
  };

  handleCardPreview(dataArr: any) {
    let item: any[] = [];
    for (let x = 0; x < 1; x++) {
      let ID = dataArr.id;
      let CardID = dataArr.cardID;
      let CardType = dataArr.cardType;
      let Title = dataArr.title;
      let Title2 = dataArr.title2;
      let Content = dataArr.content;
      let IconURL = dataArr.iconURL;
      let BgImageURL = dataArr.bgImageURL;
      let VideoURL = dataArr.videoURL;
      let Cta1Text = dataArr.cta1Text;
      let Cta1Link = dataArr.cta1Link;
      let isFreshdeskLink=dataArr.isFreshdeskLink;
      let Cta2Text = dataArr.cta2Text;
      let Cta2Link = dataArr.cta2Link;
      let Dismiss = dataArr.dismiss;
      switch (CardType) {
        case "Left-aligned Icon card":
          item.push(
            <li className="card left-aligned">
              <div className="d-flex align-items-center">
                <div className="icon">
                  <img src={IconURL} alt={Title} />
                </div>
                <div className="body-part">
                  <div className="title">{Title}</div>
                  <div
                    className="description"
                    //dangerouslySetInnerHTML={{ __html: Content }}                    
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                  ></div>
                  <div className="action">
                    {Cta1Link.length > 0 && (
                      <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                    )}
                    {/* <Link
                    className="d-flex align-items-center wht-bg-outline btn btn-primary"
                    to={Cta1Link}
                    target="_blank"
                  >
                    {Cta1Text}
                  </Link> */}
                  </div>
                </div>
              </div>
              {Dismiss === "Yes" && (
                <div className="dismiss">
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary  more"
                      id="dropdown-basic"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        id="dismiss1">
                        Dismiss
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </li>
          );
          break;
        case "Event card":
          item.push(
            <li
              className="card right-aligned"
              style={{
                backgroundImage: "url(" + BgImageURL + ")",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="d-flex align-items-center">
                <div className="body-part mr-auto">
                  <div className="title">{Title}</div>
                  <div
                    className="description"
                    //dangerouslySetInnerHTML={{ __html: Content }}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                  ></div>
                  <div className="action">
                    <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                    <a className="d-flex align-items-center wht-bg-outline btn btn-primary remove-bdr" href={Cta2Link} target="_blank">{Cta2Text}</a>

                  </div>
                </div>
                <div className="icon">
                  <img src={IconURL} alt={Title} />
                </div>
              </div>
              {Dismiss === "Yes" && (
                <div className="dismiss">
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary  more"
                      id="dropdown-basic"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        id="dismiss2"

                      >
                        Dismiss
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </li>
          );
          break;
        case "Text Only card":
          item.push(
            <li className="card left-aligned">
              <div className="d-flex align-items-center">
                <div className="body-part">
                  <div className="title">{Title}</div>
                  <div
                    className="description"
                    //dangerouslySetInnerHTML={{ __html: Content }}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                  ></div>
                  <div className="action">
                    <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                    <a className="d-flex align-items-center wht-bg-outline btn btn-primary remove-bdr" href={Cta2Link} target="_blank">{Cta2Text}</a>

                  </div>
                </div>
              </div>
              {Dismiss === "Yes" && (
                <div className="dismiss">
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary  more"
                      id="dropdown-basic"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        id="dismiss3"

                      >
                        Dismiss
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </li>
          );
          break;
        case "Tall Video card":
          item.push(
            <li className="card video">
              <Player>
                <source src={VideoURL} />
              </Player>
              <div className="footer">
                <div className="title">{Title}</div>
                <div
                  className="desc"
                  //dangerouslySetInnerHTML={{ __html: Content }}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                ></div>
              </div>
              {Dismiss === "Yes" && (
                <div className="dismiss">
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary  more"
                      id="dropdown-basic"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        id="dismiss4"

                      >
                        Dismiss
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </li>
          );
          break;
        case "Tall Image Background card":
          item.push(
            <li
              className="card center-aligned-bg"
              style={{
                backgroundImage: "url(" + BgImageURL + ")",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              {" "}
              <div className="d-flex align-items-center justify-content-center height-100">
                <div className="body-part">
                  <div className="title">{Title}</div>
                  <div className="title heading">{Title2}</div>
                  <div
                    className="description"
                    //dangerouslySetInnerHTML={{ __html: Content }}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                  ></div>
                  <div className="action">

                    <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                  </div>
                </div>
              </div>
              {Dismiss === "Yes" && (
                <div className="dismiss">
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary  more"
                      id="dropdown-basic"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        id="dismiss5"

                      >
                        Dismiss
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </li>
          );
          break;
        case "Tall Center-aligned Icon card":
          item.push(
            <li className="card center-aligned">
              <div className="icon">
                <img src={IconURL} alt={Title} />
              </div>
              <div className="body-part">
                <div className="title">{Title}</div>
                <div
                  className="description"
                  //dangerouslySetInnerHTML={{ __html: Content }}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                ></div>
                <div className="action">
                  <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                  <a className="d-flex align-items-center wht-bg-outline btn btn-primary remove-bdr" href={Cta2Link} target="_blank">{Cta2Text}</a>
                </div>
              </div>
              {Dismiss === "Yes" && (
                <div className="dismiss">
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary  more"
                      id="dropdown-basic"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        id="dismiss6"
                      >
                        Dismiss
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </li>
          );
          break;
      }
    }

    this.setState({
      htmlItem: item
    });

  }





  render() {
    const EditorModules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        ['clean']
      ],
    }
    const EditorFormats = [
      'bold', 'italic', 'underline',
      'list', 'bullet',
      'link'
    ]
    const cardTypeSelect = (eventKey: any) => {
      this.setState({ cardTypeValue: eventKey });
    };
    const cardIndexSelect = (eventKey: any) => {
      this.setState({ cardIndexValue: eventKey });
    };
    const cardDismissbleSelect = (eventKey: any) => {
      this.setState({ cardDismissibleValue: eventKey });
    };
    const cardStatusSelect = (eventKey: any) => {
      this.setState({ cardStatusValue: eventKey });
    };
    const hoverOutEllipsis = () => {
      $(".tooltip").removeClass("show");
    };
    const products = [
      { cardID: 1, cardName: 'FAQs', cardType: "Left-aligned Icon card", status: 'Status: Expiring: 5/15/21 6:00 AM' },
      { cardID: 2, cardName: 'Upcoming Webinar on January 12th', cardType: "Event card", status: 'Status: Expiring: 5/16/21 6:00 AM' },
      { cardID: 3, cardName: 'An Informative Thing is Happening', cardType: "Text Only card", status: 'Status: Expiring: 5/17/21 6:00 AM' },
      { cardID: 4, cardName: 'Learn about a new thing', cardType: "Tall Center-aligned Icon card", status: 'Status: Expiring: 5/18/21 6:00 AM' },
      { cardID: 5, cardName: 'Upcoming Scheduled Maintenance', cardType: "Tall Image Background card", status: 'Status: Expiring: 5/19/21 6:00 AM' }
    ]
    const columns = [
      {
        dataField: "cardType",
        text: "",
        hidden: true,
      },
      {
        dataField: "title",
        text: "",
        formatter: (
          cell: any,
          row: {
            title: React.ReactNode, cardType: React.ReactNode;
          },
          rowIndex: any
        ) => (
          <div>
            <div className="title" onWheel={() => hoverOutEllipsis()}>
              <EllipsisWithTooltip placement="bottom">
                {row.title}
              </EllipsisWithTooltip>
            </div>
            <div className="desc" onWheel={() => hoverOutEllipsis()}>
              <EllipsisWithTooltip placement="bottom">
                {row.cardType}
              </EllipsisWithTooltip>
            </div>
          </div>
        ),
      },
      {
        dataField: "status",
        text: "",
        formatter: (
          cell: any,
          row: {
            publishDate: React.ReactNode, status: React.ReactNode, status1: React.ReactNode, statusnew: React.ReactNode;
          },
          rowIndex: any
        ) => (
          <div>
            <div className="title1">{row.statusnew}</div>
            {/* {row.status} */}
            <div className="desc" onWheel={() => hoverOutEllipsis()}>
              <EllipsisWithTooltip placement="bottom">
                {row.status1}
              </EllipsisWithTooltip>
            </div>
          </div>
        ),
      },
    ];

    const rowStyle2 = (row, rowIndex) => {
      const style: any = {};
      if (row.id === this.state.newID) {
        style.backgroundColor = '#c8e6c9';
        style.fontWeight = 'bold';
        style.color = 'white';
      }
      return style;
    };

    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      classes: "active-row",
    };
    return (
      <>
        {/* <ToastContainer
          autoClose={3000}
          containerId={"SaveHomeCard"}
          enableMultiContainer
        /> */}
        <Container fluid className="body-sec home-configuration">
         
          {this.state.isPermission === "Yes" && (
            <>
          <div className="page-heading d-flex">
            <div className="mr-auto">Homepage Cards</div>
            <div className="action-group d-flex flex-row">
              <button type="button" className="btn btn-primary" onClick={this.handleNew}> New Card </button>
            </div>

          </div>
          <Tabs id="home-card-configuration" activeKey={this.state.key} onSelect={this.handleSelect}>
            <Tab eventKey="publishedCards" title="Published Cards"></Tab>
            <Tab eventKey="allCards" title="All Cards"></Tab>
          </Tabs>
          <div className="section d-flex">
            <div className="budget-settings d-flex justify-content-start ptoSettings">
              <div className="left-panel">
                <ToolkitProvider
                  keyField="cardID"
                  data={this.state.cardData}
                  columns={columns}
                  search
                >
                  {(props: {
                    searchProps: JSX.IntrinsicAttributes;
                    baseProps: JSX.IntrinsicAttributes;
                  }) => (
                    <div>
                      <div className="search-header">
                        <SearchBar
                          {...props.searchProps}
                          placeholder={"Filter..."}
                        />
                      </div>
                      <div className="left-body">
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          rowEvents={this.rowEvents}
                          hover
                          condensed
                          selectRow={selectRow}
                          rowStyle={rowStyle2}
                        />
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              </div>
              <div className="right-panel">
                <div className="pos-header d-flex align-content-center flex-wrap">
                  <div className="pos-ttl mr-auto">
                    <AutosizeInput
                      ref={this.input}
                      className="form-control removeBorder input-component"
                      style={{ fontSize: 24 }}
                      injectStyles={true}
                      type="input"
                      value={this.state.formData.cardTitle}
                      placeholder={"Card Title"}
                      maxLength={25}
                      onChange={(e: any) =>
                        this.handleChange(e, "title")
                      }
                    />
                  </div>
                  <div className="pos-rel">
                    {this.state.formData.disputeID > 0 && (
                      <Dropdown className="more-action delete-Confirm" alignRight>
                        <Dropdown.Toggle
                          className="btn-outline-primary btn btn-primary more"
                          id="dropdown-more"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>

                          <Dropdown.Item onClick={this.hadleDuplicate}>Duplicate</Dropdown.Item>
                          <Dropdown.Item onClick={this.showdeletePopUp}>Delete</Dropdown.Item>

                        </Dropdown.Menu>
                        {this.state.isDeleteModelShow && (
                          <div><ConfirmationModal confirmModalContent={this.state.confirmModalContent} ishandleDeletePosition={this.handleDelete.bind(this)} /></div>
                        )}
                      </Dropdown>
                    )}
                  </div>

                </div>
                <div className={this.state.ischangeStatus ? "pos-body dataChange" : "pos-body"}>
                  {this.state.formData.disputeID > 0 && (
                    // <div className="card-show d-flex justify-content-center">
                    //   <div className="card left-aligned">
                    //     <div className="d-flex align-items-center">
                    //       <div className="icon">
                    //         <img src="https://innflowfilestore1.blob.core.windows.net/cardfile/left-align-faq-icon.svg" alt="FAQs" />
                    //       </div>
                    //       <div className="body-part">
                    //         <div className="title">FAQs</div>
                    //         <div className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam viverra enim eu ligula auctor, ac pharetra ex iaculis.</div>
                    //         <div className="action">
                    //           <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href="https://www.google.com/" target="_blank">Read the FAQs</a>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                    <div className="card-show d-flex justify-content-center">
                      {this.state.htmlItem}
                    </div>
                  )}
                  <div className="card-update">
                    <Form>
                      <Form.Group as={Row} controlId="cardType">
                        <Form.Label column sm="3">
                          Card Type
                        </Form.Label>
                        <Col sm="9">
                          <Dropdown onSelect={cardTypeSelect}>
                            <Dropdown.Toggle id="dropdown-cardType">
                              <span>{this.state.cardTypeValue}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {this.state.cardTypeList.map(
                                (item: any, idx: any) => (
                                  <Dropdown.Item
                                    eventKey={item.cardType}
                                    onClick={() =>
                                      this.handleChange(item, 'cardType')
                                    }
                                  >
                                    {item.cardType}
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="AlwaysOnTop">
                        <Form.Label column sm="3">
                          Always on Top
                        </Form.Label>
                        <Col sm="9">
                          <Dropdown onSelect={cardIndexSelect}>
                            <Dropdown.Toggle id="dropdown-card-index">
                              {this.state.cardIndexValue === "Select" && (
                                <span className="placeholder">{this.state.cardIndexValue}</span>
                              )}
                              {this.state.cardIndexValue !== "Select" && (
                                <span>{this.state.cardIndexValue}</span>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="Yes" onClick={() =>
                                this.handleChange("Yes", 'always')
                              }>Yes</Dropdown.Item>
                              <Dropdown.Item eventKey="No" onClick={() =>
                                this.handleChange("No", 'always')
                              }>No</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="Dismissible">
                        <Form.Label column sm="3">
                          Dismissible?
                        </Form.Label>
                        <Col sm="9">
                          <Dropdown onSelect={cardDismissbleSelect}>
                            <Dropdown.Toggle id="dropdown-card-index">
                              {this.state.cardDismissibleValue === "Select" && (
                                <span className="placeholder">{this.state.cardDismissibleValue}</span>
                              )}
                              {this.state.cardDismissibleValue !== "Select" && (
                                <span>{this.state.cardDismissibleValue}</span>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="Yes" onClick={() =>
                                this.handleChange("Yes", 'dismiss')
                              }>Yes</Dropdown.Item>
                              <Dropdown.Item eventKey="No" onClick={() =>
                                this.handleChange("No", 'dismiss')
                              }>No</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="Content">
                        <Form.Label column sm="3">
                          Expiration Date
                        </Form.Label>
                        <Col sm="9">
                          <div className="date-picker calender-picker">
                            <DatePicker key="datePicker"
                              selected={this.state.formData.exDate === null ? "" : new Date(this.state.formData.exDate)}
                              placeholderText={"Select Expiration Date"} dateFormat="M/dd/yy"
                              className="form-control"
                              onSelect={(event: any) =>
                                this.handleChange(event, 'exDate')
                              }
                              onChange={(event: any) => { this.handleChange(event, 'expireonselect') }}
                            />
                          </div>
                        </Col>
                      </Form.Group>


                      <Form.Group as={Row} controlId="Content">
                        <Form.Label column sm="3">
                          Publish Date
                        </Form.Label>
                        <Col sm="9">
                          <div className="date-picker calender-picker">
                            <DatePicker key="datePicker"
                              selected={new Date(this.state.formData.publishDate)}
                              placeholderText={"Select publish Date"} dateFormat="M/dd/yy"
                              className="form-control"
                              onSelect={(event: any) =>
                                this.handleChange(event, 'publishDate')
                              }
                            />
                          </div>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="Content">
                        <Form.Label column sm="3">
                          Content
                        </Form.Label>
                        <Col sm="9">
                          <ReactQuill
                            theme="snow"
                            modules={EditorModules}
                            formats={EditorFormats}
                            value={DOMPurify.sanitize(this.state.formData.content)} onChange={this.handleChangeQuill} />
                          {/* <Form.Control placeholder="Enter Content" as="textarea" rows={2}
                            value={this.state.formData.content}
                            onChange={(e: any) =>
                              this.handleChange(e, 'content')
                            }
                          /> */}
                        </Col>
                      </Form.Group>
                      {this.state.formData.cardType != "Tall Video card" &&
                        this.state.formData.cardType != "Text Only card" &&
                        this.state.formData.cardType != "Tall Image Background card" && (
                          <div>
                            <Form.Group as={Row} controlId="Icon">
                              <Form.Label column sm="3">
                                Icon Url
                              </Form.Label>
                              <Col sm="9">
                                <Form.File
                                  id="custom-file-translate-html"
                                  label={this.state.selectedFilename}
                                  data-browse="Select File"
                                  onChange={(event: any) => this.onFileChange(event, "1")}
                                  custom
                                />

                              </Col>
                            </Form.Group>
                          </div>
                        )}


                      {(this.state.formData.cardType === "Event card" || this.state.formData.cardType === "Tall Image Background card") && (
                        <div>
                          <Form.Group as={Row} controlId="Icon">
                            <Form.Label column sm="3">
                              Bg image Url
                            </Form.Label>
                            <Col sm="9">
                              <Form.File
                                id="custom-file-translate-html"
                                label={this.state.selectedBgFilename}
                                data-browse="Select File"
                                onChange={(event: any) => this.onFileChange(event, "2")}
                                custom
                              />

                            </Col>
                          </Form.Group>
                        </div>
                      )}

                      {this.state.formData.cardType != "Tall Video card" && (
                        <div>
                          <Form.Group as={Row} controlId="CTA1Text">
                            <Form.Label column sm="3">
                              CTA1 Text
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control type="text" placeholder="Enter CAT1 Text"
                                value={this.state.formData.cta1Text}
                                onChange={(e: any) =>
                                  this.handleChange(e, 'cta1Text')
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} controlId="CTA1Link">
                            <Form.Label column sm="3">
                              CTA1 Link
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control type="text" placeholder="Enter CAT1 URL"
                                value={this.state.formData.cta1Link}
                                onChange={(e: any) =>
                                  this.handleChange(e, 'cta1Link')
                                }

                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} controlId="isFreshdeskLink">
                            <Form.Label column sm="3">
                              Is Freshdesk Link
                            </Form.Label>
                            <Col sm="9">
                              <Form.Check
                               className="mt-2"
                                type="checkbox" 
                                aria-label="isFreshdeskLink"
                                disabled={!this.state.formData.cta1Link}
                                checked={this.state.formData.isFreshdeskLink}
                                onChange={(e: any) =>
                                  this.handleChange(e, 'isFreshdeskLink')
                                }

                              />
                            </Col>
                          </Form.Group>
                        </div>
                      )}
                      {(this.state.formData.cardType === "Event card" || this.state.formData.cardType === "Tall Center-aligned Icon card" || this.state.formData.cardType === "Text Only card") && (
                        <div>
                          <Form.Group as={Row} controlId="CTA2Text">
                            <Form.Label column sm="3">
                              CTA2 Text
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control type="text" placeholder="Enter CAT2 Text"
                                value={this.state.formData.cta2Text}
                                onChange={(e: any) =>
                                  this.handleChange(e, 'cta2Text')
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} controlId="CTA2Link">
                            <Form.Label column sm="3">
                              CTA2 Link
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control type="text" placeholder="Enter CAT2 URL"
                                value={this.state.formData.cta2Link}
                                onChange={(e: any) =>
                                  this.handleChange(e, 'cta2Link')
                                }

                              />
                            </Col>
                          </Form.Group>
                        </div>
                      )}
                      {this.state.formData.cardType === "Tall Image Background card" && (
                        <div>
                          <Form.Group as={Row} controlId="title2">
                            <Form.Label column sm="3">
                              Title 2
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control type="text" placeholder="Enter Title"
                                value={this.state.formData.title2}
                                onChange={(e: any) =>
                                  this.handleChange(e, 'title2')
                                }
                              />
                            </Col>
                          </Form.Group>
                        </div>
                      )}

                      {this.state.formData.cardType === "Tall Video card" && (
                        <div>
                          <Form.Group as={Row} controlId="Video1">
                            <Form.Label column sm="3">
                              Video Url
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control type="text" placeholder="Enter Video Url"
                                value={this.state.formData.videoUrl}
                                onChange={(e: any) =>
                                  this.handleChange(e, 'videoUrl')
                                }
                              />
                            </Col>
                          </Form.Group>
                        </div>
                      )}

                      <Form.Group as={Row} controlId="Status">
                        <Form.Label column sm="3">
                          Status
                        </Form.Label>
                        <Col sm="9">
                          <Dropdown onSelect={cardStatusSelect}>
                            <Dropdown.Toggle id="dropdown-card-status">
                              {this.state.cardStatusValue === "Select" && (
                                <span className="placeholder">{this.state.cardStatusValue}</span>
                              )}
                              {this.state.cardStatusValue !== "Select" && (
                                <span>{this.state.cardStatusValue}</span>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="Draft" onClick={() =>
                                this.handleChange("Draft", 'status')
                              }>Draft</Dropdown.Item>
                              <Dropdown.Item eventKey="Published" onClick={() =>
                                this.handleChange("Published", 'status')
                              }>Published</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Form.Group>
                    </Form>
                    {this.state.ischangeStatus && (
                      <div className="fixed-action">
                        <div className="d-flex align-content-center flex-wrap">
                          <div className="mr-auto message">
                            <span>You have unsaved changes</span>
                          </div>
                          <button type="button" className="btn btn-primary btn-discard" onClick={this.hadleDiscard}>Discard</button>
                          <button type="button" className="btn btn-primary"
                            onClick={() => this.handleSaveCard()}>Save</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
          )}
         
        </Container>
      </>
    );
  }
}
