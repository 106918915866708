import * as React from "react";
import { Dropdown } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ReactTooltip from "react-tooltip";
import { resolve, reject } from "q";
import profile from "../../../../../Common/Assets/Images/profile.png";
import { ReactComponentLoader } from "../../../../../Common/Components/ReactComponentLoader";
import { ILaborScheduleReassignEmployeeListDto } from "../../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";

export class ShiftReassignPopUp extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      loaderTrue: false,
      filterData: [],
      allEmployeeList: [],
      positionList: [],
      remainingUsers: [],
      inputValue: "",
    };
  }

  componentDidMount() {
    this.setState({ loaderTrue: true });

    let schedule = this.props.schedule;
    let request = {} as ILaborScheduleReassignEmployeeListDto;
    request.hotelID = this.props.hidValue;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    request.date = schedule.shiftDate;
    request.inTime = schedule.shiftInTime;
    request.outTime = schedule.shiftOutTime;
    request.position = schedule.positionId;
    request.type = "ReassignSchedule";
    request.startDate = this.props.parentState.navigationCalenderDates[0];
    request.endDate = this.props.parentState.navigationCalenderDates[1];

    LaborSchedule.GetLaborReassignScheduleEmployee(request)
      .then(async (result: any) => {
        this.setState(
          {
            allEmployeeList: result.result,
          },
          () => {
            this.updateLists();
          }
        );
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  updateLists = () => {
    let { allEmployeeList, filterData } = this.state;
    if (filterData.length === 0 && this.state.inputValue === "") {
      filterData = allEmployeeList;
    }
    let unassignedEmplyeeName: any;
    if (this.props.row === "row") {
      unassignedEmplyeeName = "Unassigned Shifts";
    } else {
      unassignedEmplyeeName = this.props.row.employeeName;
    }

    let positionList = filterData.filter(
      (data: any) =>
        data.employee.toLowerCase() !== unassignedEmplyeeName.toLowerCase() &&
        data.positionid === Number(this.props.schedule.positionId)
    );
    let remainingList = filterData.filter(
      (data: any) =>
        data.employee.toLowerCase() !== unassignedEmplyeeName.toLowerCase() &&
        data.positionid !== Number(this.props.schedule.positionId)
    );

    this.setState({
      positionList: positionList,
      remainingUsers: remainingList,
    });
  };

  handleChangeEmployee = (e: any) => {
    const updatedList = this.state.allEmployeeList.filter((item: any) => {
      return (
        item.employee.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    this.setState(
      {
        filterData: updatedList,
        inputValue: e.target.value,
      },
      () => {
        this.updateLists();
      }
    );
  };

  handleDropdownChange = (item: any) => {
    this.setState({ inputValue: "" }, () => {
      this.props.handleSelectedItem(item);
    });
  };

  render() {
    const tooltipMessage =
      "This user is already scheduled for another shift within the selected hours.";
    return (
      <>
        <div className="reassignAllModal">
          {this.state.loaderTrue ? (
            <ReactComponentLoader useas={"shiftReassignDDN"} />
          ) : (
            <>
              <div className="search-sec">
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChangeEmployee}
                  placeholder="Search..."
                  value={this.state.inputValue}
                  ref={this.inputElement}
                />
              </div>
              <div className="list-area">
                <div className="list-section">
                  {this.state.positionList.length > 0 && (
                    <div className="filtered-position-users">
                      <div className="sec-ttl">
                        {this.props.schedule.shiftPosition}
                      </div>
                      {this.state.positionList.map((item: any, idx: any) => (
                        <div
                          data-tip={
                            item.isconflict === "Yes" ? tooltipMessage : ""
                          }
                        >
                          <ReactTooltip
                            effect="solid"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            place="bottom"
                            multiline={true}
                          />

                          <Dropdown.Item
                            className={
                              item.isconflict === "Yes" ||
                              item.isconflict.toLowerCase() === "incomplete"
                                ? "disabled"
                                : ""
                            }
                            key={idx}
                            eventKey={item.employee}
                            onClick={() => {
                              if (item.isconflict === "No") {
                                this.handleDropdownChange(item);
                              } else {
                                return false;
                              }
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              key={idx}
                            >
                              <img
                                src={
                                  item.imagePath === null ||
                                  item.imagePath.trim() === ""
                                    ? profile
                                    : item.imagePath
                                }
                                className="profileimg"
                                width="36"
                                height="36"
                                alt=""
                              />
                              <div className="details">
                                <div className="name">{item.employee}</div>
                                <div className="pos-name">
                                  {item.positionName}
                                </div>
                              </div>
                              {item.isconflict === "No" ? (
                                <div className="showAvailableHours mr-auto">
                                  <div className="hours">
                                    {item.availableHrs}
                                  </div>
                                  <div className="availableTitle">
                                    Available
                                  </div>
                                </div>
                              ) : (
                                <div className="red-badges">Conflict</div>
                              )}
                            </div>
                          </Dropdown.Item>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="sec-ttl">All Users</div>
                  {this.state.remainingUsers.map((item: any, idx: any) => (
                    <div
                      data-tip={item.isconflict === "Yes" ? tooltipMessage : ""}
                    >
                      <ReactTooltip
                        effect="solid"
                        event="mouseover mouseenter"
                        eventOff="mouseleave mouseout scroll mousewheel blur"
                        place="bottom"
                        multiline={true}
                      />

                      <Dropdown.Item
                        className={
                          item.isconflict === "Yes" ||
                          item.isconflict.toLowerCase() === "incomplete"
                            ? "disabled"
                            : ""
                        }
                        key={idx}
                        eventKey={item.employee}
                        onClick={() => {
                          if (item.isconflict === "No") {
                            this.handleDropdownChange(item);
                          } else {
                            return false;
                          }
                        }}
                      >
                        <div className="d-flex align-items-center" key={idx}>
                          <img
                            src={
                              item.imagePath === null ||
                              item.imagePath.trim() === ""
                                ? profile
                                : item.imagePath
                            }
                            className="profileimg"
                            width="36"
                            height="36"
                            alt=""
                          />
                          <div className="details">
                            <EllipsisWithTooltip placement="bottom">
                              <div className="name">{item.employee}</div>
                            </EllipsisWithTooltip>
                            <EllipsisWithTooltip placement="bottom">
                              <div className="pos-name">
                                {item.positionName}
                              </div>
                            </EllipsisWithTooltip>
                          </div>
                          {item.isconflict === "No" ? (
                            <div className="showAvailableHours mr-auto">
                              <div className="hours">{item.availableHrs}</div>
                              <div className="availableTitle">Available</div>
                            </div>
                          ) : (
                            // <div className="red-badges">Conflict</div>
                            <>
                            {item.isconflict === "Yes" ? (
                        <div className="red-badges">Conflict</div>
                            ) : (
                              <div className="red-badges">Incomplete</div>
                            )}
                            </>
                          )}
                          
                        </div>
                      </Dropdown.Item>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
