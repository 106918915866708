import React, { Fragment } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { resolve, reject } from "q";
import moment from "moment-timezone";
import { WebTimeClockService } from "../Common/Services/WebTimeClock";
import {
  IEmployeePunchInRequestDto,
  IEmployeePunchOutRequestDto,
} from "../Common/Contracts/IWebTimeClock";
import { toast } from "react-toastify";
import { ReactComponentLoader } from "../Common/Components/ReactComponentLoader";
import { IUser } from "../Common/Contracts/IUser";
import { User } from "../Common/Services/User";
import { GetUser } from "../ForgotPassword/GetUser";
import { ResetMethod } from "../ForgotPassword/ResetMethod";
import { EnterCode } from "../ForgotPassword/EnterCode";
import { ChangePassword } from "../ForgotPassword/ChangePassword";
import { ResetPasswordComplete } from "../ForgotPassword/ResetPasswordComplete";
import profile from "../Common/Assets/Images/profile.png";
import { Utils } from "../Common/Utilis";

export class UserPunchModal extends React.Component<any, any> {
  private timerID: any | undefined;
  constructor(props: any) {
    super(props);
    this.state = {
      scheduleData: [],
      loaderTrue: false,
      punchButtonSpinner: false,
      password: "",
      errorMessage: "",
      closeTrigger: 0,
      resetpassTrigger: 0,
      selectedSechdule: [],
      enterpriseEarlyPunch: 0,
      mode: -1,
      userName: "",
      passCode: "",
      resetMode: "",
    };
  }

  componentDidMount() {
    this.getUserSchedules("componentMount");
    this.timerID = setInterval(this.trigger, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  trigger = () => {
    this.setState((prevState: any) => ({
      closeTrigger: prevState.closeTrigger + 1,
      resetpassTrigger: prevState.resetpassTrigger + 1,
    }));
    // close on 5 minutes of inactivity 5*60=300
    if (
      this.state.mode === 0 ||
      this.state.mode === 1 ||
      this.state.mode === 4
    ) {
      if (this.state.resetpassTrigger >= 30) {
        this.setState(
          {
            mode: -1,
            resetpassTrigger: 0,
          },
          () => {
            this.usePunchModalClose();
          }
        );
      }
    }
    if (this.state.mode === 2 || this.state.mode === 3) {
      if (this.state.resetpassTrigger >= 300) {
        this.setState(
          {
            mode: -1,
            resetpassTrigger: 0,
          },
          () => {
            this.usePunchModalClose();
          }
        );
      }
    }
    if (this.state.mode === -1 && this.state.closeTrigger >= 300) {
      this.usePunchModalClose();
    }
  };

  getUserSchedules = (type: any) => {
    this.setState({ loaderTrue: true });

    let request: any = {};

    request.userName = this.props.parentState.userPucnModalData.userName;
    request.scheduleDate = this.props.parentState.displayDate;
    request.hotelID = this.props.parentState.hidValue;
    WebTimeClockService.GetEmployeeTimeClockSchedules(request)
      .then(async (result: any) => {
        let apiResult = result.result;
        let earlyPunch: any = 0;
        if (apiResult.length > 0 && apiResult !== null) {
          let scheduleData: any = [];
          let selectedSechdule: any = [];

          if (this.props.parentState.userPucnModalData.status === "S") {
            apiResult.forEach((elem: any, idx: any) => {
              earlyPunch = elem.earlyPunch;
              let pushObject: any = {};
              pushObject = elem;
              pushObject.isChecked = idx === 0 ? true : false;
              pushObject.departmentName = elem.departmentName.replace(
                /\w\S*/g,
                function (txt: any) {
                  return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  );
                }
              );
              pushObject.positionName = elem.positionName.replace(
                /\w\S*/g,
                function (txt: any) {
                  return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  );
                }
              );
              pushObject.topStatus = `${pushObject.positionName}, ${pushObject.departmentName}`;

              let dayStatus: any = "Today";

              if (
                moment(elem.date).isBefore(this.props.parentState.displayDate)
              ) {
                dayStatus = "Yesterday";
              }

              if (
                moment(elem.date).isAfter(this.props.parentState.displayDate)
              ) {
                dayStatus = "Tomorrow";
              }

              pushObject.bottomStatus = `${dayStatus} from ${elem.scheduleInTime} - ${elem.scheduleOutTime}`;

              scheduleData.push(pushObject);
              if (idx === 0) {
                selectedSechdule.push(pushObject);
              }
            });
          } else {
            apiResult.forEach((elem: any, idx: any) => {
              if (elem.mainUniqueNo !== -1) {
                earlyPunch = elem.earlyPunch;
                let pushObject: any = {};
                pushObject = elem;
                pushObject.isChecked = true;
                pushObject.departmentName = elem.departmentName.replace(
                  /\w\S*/g,
                  function (txt: any) {
                    return (
                      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                    );
                  }
                );

                pushObject.positionName = elem.positionName.replace(
                  /\w\S*/g,
                  function (txt: any) {
                    return (
                      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                    );
                  }
                );
                pushObject.topStatus = `${pushObject.positionName}, ${pushObject.departmentName}`;
                let inDate = new Date(pushObject.date);
                let displayDate = new Date(this.props.parentState.displayDate);

                let dayStatus: any = "Today";

                if (moment(inDate).isBefore(displayDate)) {
                  dayStatus = "Yesterday";
                }

                if (moment(inDate).isAfter(displayDate)) {
                  dayStatus = "Tomorrow";
                }

                // let dayStatus: any = "";
                // if (inDate < displayDate) {
                //   dayStatus = "Yesterday";
                // } else if (inDate === displayDate) {
                //   dayStatus = "Today";
                // } else {
                //   dayStatus = "Tomorrow";
                // }
                pushObject.bottomStatus = `${dayStatus} from ${elem.scheduleInTime} - ${elem.scheduleOutTime}`;

                scheduleData.push(pushObject);
                selectedSechdule.push(pushObject);
              }
            });
          }

          this.setState({
            scheduleData: scheduleData,
            selectedSechdule: selectedSechdule,
            enterpriseEarlyPunch: earlyPunch,
          });
        }
        resolve();
        this.setState({ loaderTrue: false });
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeClockIndex",
        });
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  usePunchModalClose = () => {
    this.props.userPunchModalClose();
  };

  resetPassword = () => {
    this.setState({
      mode: 0,
    });
  };

  cancelResetPassword = () => {
    this.setState({
      mode: -1,
    });
  };

  handleChange = (e: any) => {
    this.setState({ password: e.target.value });
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
    }
  };

  scheduleCheck = (e: any, idx: any) => {
    let scheduleData: any = [...this.state.scheduleData];
    let selectedSechdule: any = [...this.state.selectedSechdule];

    let result = scheduleData[idx].defaultChecked === "true";

    if (result && e.target.checked) {
      for (let i = 0; i < scheduleData.length; i++) {
        if (i === idx) {
          scheduleData[i].isChecked = true;
        } else {
          scheduleData[i].isChecked = false;
        }
      }

      let checkedSchedule = scheduleData[idx];
      selectedSechdule.pop();
      selectedSechdule.push(checkedSchedule);

      this.setState({
        scheduleData: scheduleData,
        selectedSechdule: selectedSechdule,
      });
    } else {
      return false;
    }
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.setState({
      closeTrigger: 0,
    });
    if (this.state.password === "") {
      this.setState({
        errorMessage: "Password is required",
      });
      return false;
    } else {
      this.setState({ punchButtonSpinner: true });

                  if (this.props.parentState.userPucnModalData.status === "S") {
                    this.punchInCall();
                  } else {
                    this.punchOutCall();
                  }
      // Check Login User First
      // User.login(
    //     this.props.parentState.userPucnModalData.userName,
    //     this.state.password,
    //     ""
    //   )
    //     .then(async (result: IUser | null) => {
    //       if (result !== null) {
    //         if (result.message === "success") {
    //           User.ValidateUserStatus(
    //             result.userName,
    //             result.tenantID,
    //             "userPunchModal"
    //           ).then(async (userRes: any | null) => {
    //             let apiResult = userRes.result;
    //             if (apiResult.rVal === "0") {
    //               this.setState({ punchButtonSpinner: false });
    //               let userObject: any = {};
    //               userObject.userName = this.props.parentState.userPucnModalData.userName;
    //               userObject.email = apiResult.email;
    //               userObject.phone = apiResult.phone;
    //               userObject.primaryContact = apiResult.primaryContact;
    //               userObject.rVal = "0";
    //               userObject.isPhoneBlock = apiResult.isPhoneBlock;
    //               userObject.isEmailBlock = apiResult.isEmailBlock;
    //               userObject.displayEmail = apiResult.displayEmail;
    //               userObject.displayPhone = apiResult.displayPhone;
    //               userObject.isSplPermission = apiResult.isSplPermission;
    //               this.props.userValidated(userObject);
    //             } else {
    //               // Punch In / Out the User for Selected Schedule
    //               if (this.props.parentState.userPucnModalData.status === "S") {
    //                 this.punchInCall();
    //               } else {
    //                 this.punchOutCall();
    //               }
    //             }
    //           });
    //         } else {
    //           this.setState({
    //             punchButtonSpinner: false,
    //             errorMessage: "Incorrect Password.",
    //             password: "",
    //           });
    //         }
    //       } else {
    //         this.setState({ punchButtonSpinner: false });
    //       }

    //       resolve();
    //     })
    //     .catch((err: any) => {
    //       this.setState({ punchButtonSpinner: false });
    //       Utils.toastError(`Server Error, ${err}`, {
    //         // // position: toast.POSITION.BOTTOM_RIGHT,
    //         // // containerId: "timeClockIndex",
    //       });
    //       reject();
    //     });
    }
  };

  punchInCall = (isValidate?) => {
    let selectedSechdule = this.state.selectedSechdule[0];
    let request = {} as IEmployeePunchInRequestDto;
    request.Hotelid = Number(this.props.parentState.hidValue);
    request.ScheduleNo = Number(selectedSechdule.uniqueno);
    request.UserName = this.props.parentState.userPucnModalData.userName;
    request.DateTime24Hrs = this.props.parentState.displayDate +" "+ this.props.parentState.displayTime.slice(0,5);
    request.PageSource= "WebTimeClock";
    request.CallingPage= "WebTimeClock";
    request.ForceRemarks= "";
    request.IsPunchCall="Yes";
    request.Password = this.state.password;
    request.validateModel = isValidate ? "Yes" : "No"
    debugger;    
    WebTimeClockService.EmployeePunchIn(request)
      .then(async (result: any) => {
        let apiResult = result.result.result;
        if (apiResult.status === "Success") {
          this.usePunchModalClose();
          this.props.userPunchSuccess();
          toast.success("User Punched In Successfully", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "timeClockIndex",
          });
        } else if (apiResult.status === "Fail") {
          if (apiResult.rVal === "0") {
            this.setState({ punchButtonSpinner: false });
            let userObject: any = {};
            userObject.userName = this.props.parentState.userPucnModalData.userName;
            userObject.password = this.state.password;
            userObject.email = apiResult.email;
            userObject.phone = apiResult.phone;
            userObject.primaryContact = apiResult.primaryContact;
            userObject.rVal = "0";
            userObject.isPhoneBlock = apiResult.isPhoneBlock;
            userObject.isEmailBlock = apiResult.isEmailBlock;
            userObject.displayEmail = apiResult.displayEmail;
            userObject.displayPhone = apiResult.displayPhone;
            userObject.isSplPermission = apiResult.isSplPermission;
            userObject.phoneStatus = apiResult.phoneStatus;
            userObject.modalOpen = "userPunchModal";
            this.props.userValidated(userObject);
          }
        }
        else {
          Utils.toastError(apiResult.status, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "timeClockIndex",
          });
        }
        this.setState({ punchButtonSpinner: false });
        resolve();
      })
      .catch((err: any) => {
        this.setState({ punchButtonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeClockIndex",
        });
        reject();
      });
  };

  punchOutCall = (isValidate?) => {
    let selectedSechdule = this.state.selectedSechdule[0];
    let request = {} as IEmployeePunchOutRequestDto;
    request.Hotelid = Number(this.props.parentState.hidValue);
    request.ScheduleNo = Number(selectedSechdule.mainUniqueNo);
    request.UserName = this.props.parentState.userPucnModalData.userName;
    request.date = this.props.parentState.displayDate;
    request.DateTime24Hrs = this.props.parentState.displayDate +" "+ this.props.parentState.displayTime.slice(0,5);
    request.PageSource= "WebTimeClock";
    request.CallingPage= "WebTimeClock";
    request.ForceRemarks= "";
    request.IsPunchCall = "No";
    request.Password = this.state.password;
    request.validateModel = isValidate ? "Yes" : "No"
    debugger;
    WebTimeClockService.EmployeePunchOut(request)
      .then(async (result: any) => {
        let apiResult = result.result.result;
        if (apiResult.status === "Success") {
          // let apiResult = userRes.result;
          this.usePunchModalClose();
          this.props.userPunchSuccess();
          toast.success("User Punched Out Successfully", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "timeClockIndex",
          });
        } else if (apiResult.status === "Fail") {
          if (apiResult.rVal === "0") {
            this.setState({ punchButtonSpinner: false });
            let userObject: any = {};
            userObject.userName = this.props.parentState.userPucnModalData.userName;
            userObject.password = this.state.password;
            userObject.email = apiResult.email;
            userObject.phone = apiResult.phone;
            userObject.primaryContact = apiResult.primaryContact;
            userObject.rVal = "0";
            userObject.isPhoneBlock = apiResult.isPhoneBlock;
            userObject.isEmailBlock = apiResult.isEmailBlock;
            userObject.displayEmail = apiResult.displayEmail;
            userObject.displayPhone = apiResult.displayPhone;
            userObject.isSplPermission = apiResult.isSplPermission;
            userObject.phoneStatus = apiResult.phoneStatus;
            userObject.modalOpen = "userPunchModal";
            this.props.userValidated(userObject);
          }
        }
        else {
          Utils.toastError(apiResult.status, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "timeClockIndex",
          });
        }
        this.setState({ punchButtonSpinner: false });
        resolve();
      })
      .catch((err: any) => {
        this.setState({ punchButtonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeClockIndex",
        });
        reject();
      });
  };

  changeModule(module: any) {
    this.setState({
      resetpassTrigger: 0,
      mode: module.mode,
      userName: module.userName,
      passCode: module.passCode,
      resetMode: module.resetMode,
    });
  }

  render() {
    const userData = this.props.parentState.userPucnModalData;

    var moduleShow: any;
    if (this.state.mode === 0) {
      moduleShow = (
        <GetUser
          changeModule={this.changeModule.bind(this)}
          page="timeclock"
          cancelResetPassword={() => this.cancelResetPassword()}
          userName={userData.userName}
        />
      );
    } else if (this.state.mode === 1) {
      moduleShow = (
        <ResetMethod
          page="timeclock"
          platform="timeclock"
          cancelResetPassword={() => this.cancelResetPassword()}
          changeModule={[this.changeModule.bind(this), this.state.userName]}
        />
      );
    } else if (this.state.mode === 2) {
      moduleShow = (
        <EnterCode
          page="timeclock"
          platform="timeclock"
          cancelResetPassword={() => this.cancelResetPassword()}
          changeModule={[this.changeModule.bind(this), this.state]}
        />
      );
    } else if (this.state.mode === 3) {
      moduleShow = (
        <ChangePassword
          errorMessage=""
          page="timeclock"
          cancelResetPassword={() => this.cancelResetPassword()}
          changeModule={[this.changeModule.bind(this), this.state]}
        />
      );
    } else if (this.state.mode === 4) {
      moduleShow = (
        <ResetPasswordComplete
          page="timeclock"
          platform="timeclock"
          cancelResetPassword={() => this.cancelResetPassword()}
          changeModule={[this.changeModule.bind(this), this.state]}
        />
      );
    }

    return (
      <div>
        <Modal
          className="user-punch-modal webtime-clock-modal"
          show={this.props.parentState.userPunchModal}
          onHide={() => this.usePunchModalClose()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
          backdrop={this.props.hideUserPunchModal ? !this.props.hideUserPunchModal : true}
          style={{display:`${this.props.hideUserPunchModal ? "none":""}`}}
        >
          {this.state.mode === -1 && (
            <Modal.Header closeButton>
              <Modal.Title>
                {userData.status === "S" ? "Punch In" : "Punch Out"}
              </Modal.Title>
            </Modal.Header>
          )}
          <Modal.Body>
            {moduleShow}
            {this.state.mode === -1 && (
              <>
                <div className="body-sec">
                  <div className="employee-card d-flex">
                    <div className="employee-img">
                      <img
                        src={
                          userData.imagePath === null ||
                          userData.imagePath.trim() === ""
                            ? profile
                            : userData.imagePath
                        }
                        alt={userData.fullName}
                      />
                      <span className={userData.statusBar}></span>
                    </div>
                    <div className="employee-details d-flex">
                      <span className="employee-name">{userData.fullName}</span>
                      <span className="employee-status">
                        {userData.status === "S"
                          ? "Punched out"
                          : userData.scheduleBar}
                      </span>
                    </div>
                  </div>

                  <div className="employee-password d-flex">
                    <input
                      autoComplete="new-password"
                      type="password"
                      className={
                        this.state.errorMessage
                          ? "alert-danger employee-pass form-control"
                          : "employee-pass form-control"
                      }
                      placeholder="Enter Your Password"
                      value={this.state.password}
                      onChange={(e: any) => this.handleChange(e)}
                    />

                    <button
                      className={
                        this.state.punchButtonSpinner
                          ? "punch-in btn btn-primary p-none"
                          : "punch-in btn btn-primary"
                      }
                      onClick={(e: any) => this.handleSubmit(e)}
                      disabled={this.state.scheduleData.length <= 0}
                    >
                      {this.state.punchButtonSpinner && (
                        <Spinner
                          className="mr-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {userData.status === "S" ? "Punch In" : "Punch Out"}
                    </button>
                  </div>
                  {this.state.errorMessage !== "" && (
                    <span className="error-message">
                      {this.state.errorMessage}
                    </span>
                  )}
                </div>
                <div className="body-sec">
                  {this.state.loaderTrue ? (
                    <ReactComponentLoader useas={"userPunchModalShifts"} />
                  ) : (
                    this.state.scheduleData.length > 0 && (
                      <div className="employee-schedule-container">
                        {this.state.scheduleData.map((data: any, idx: any) => (
                          <Fragment key={idx}>
                            <div className="employee-schedule d-flex">
                              <Form.Check
                                disabled={
                                  this.props.parentState.userPucnModalData
                                    .status === "P"
                                }
                                type="checkbox"
                                label=""
                                checked={data.isChecked}
                                onChange={(e: any) => {
                                  this.scheduleCheck(e, idx);
                                }}
                              />

                              <div className="schedule-details d-flex">
                                <span className="schedule-name">
                                  {data.topStatus}
                                </span>
                                <span className="schedule-time">
                                  {data.bottomStatus}
                                </span>
                              </div>
                            </div>
                            <div className="schedule-seperator"></div>
                          </Fragment>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </>
            )}
          </Modal.Body>
          {this.state.mode === -1 && (
            <Modal.Footer>
              <Button
                className="reset-password-btn btn-ghost mr-auto"
                onClick={() => this.resetPassword()}
              >
                Reset Password
              </Button>
              <Button
                className="cancel-btn btn-ghost"
                onClick={() => this.usePunchModalClose()}
              >
                Cancel
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}
