(function (apiKey = "1a89ccf6-6c83-43b2-7483-05ef4fcb3f9b") {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ["initialize", "identify", "updateOptions", "pageLoad"];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? "unshift" : "push"](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;

    y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, "script", "pendo");
})("1a89ccf6-6c83-43b2-7483-05ef4fcb3f9b");

const pendo = window.pendo;

export class Pendo {
  static IntegratePendo = (type) => {
    // Visitor Meta
    let id = "";
    let RoleID = "";
    let RoleName = "";
    let UserName = "";
    let TenantID = "";
    let Email = "";

    if (localStorage.getItem("storage")) {
      const storage = JSON.parse(localStorage.getItem("storage"));
      id = storage.userName;
      RoleID = storage.rolId;
      RoleName = storage.roleName;
      UserName = storage.userName;
      TenantID = storage.tenantID;
      Email = storage.email;
    }

    // Account MetaData
    let OrgName = "";
    let OrgCode = "";

    if (sessionStorage.getItem("hotelSelected")) {
      const hotelData = JSON.parse(sessionStorage.getItem("hotelSelected"));
      OrgName = hotelData.hotelName;
      OrgCode = hotelData.lettercode;
    } else if (localStorage.getItem("hotelSelected")) {
      const hotelData = JSON.parse(localStorage.getItem("hotelSelected"));
      OrgName = hotelData.hotelName;
      OrgCode = hotelData.lettercode;
    }
    // Initializing Pendo
    pendo.initialize({
      visitor: {
        id: id,
        RoleID: RoleID,
        RoleName: RoleName,
        UserName: UserName,
        TenantID: TenantID,
        Email:Email
      },
      account: {
        id: TenantID,
        OrgName: OrgName,
        OrgCode: OrgCode,
      },
    });
  };
}
