import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import PageLoader from "../Common/Components/PageLoader";
// import { User } from "../Common/Services/User";
// import { resolve, reject } from "q";

export class GetUser extends React.Component<any, any> {
  formDefaults = {
    userName: { value: this.props.userName, isValid: true, message: "" },
  };
  constructor(props: any) {
    super(props);
    this.state = {
      mode: 1,
      loaderTrue: false,
      ...this.formDefaults,
      navigate: false,
      nameLength: 0,
    };
    this.handleContinue = this.handleContinue.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  /*********Form field change */
  onChange = (event: any) => {
    const userName = { ...this.state.userName };
    if(event.target.value?.includes('@')){
      this.setState({
        [event.target.name]: {
          message: "Invalid Format. Usernames are not your email address.",
          isValid: false,
        },
      });
      return;
    }
    this.setState({
      [event.target.name]: {
        value: event.target.value,
        isValid: !!event.target.value,
      },
    });
    this.setState({ nameLength: event.target.value });
  };

  /*************Form submit******************** */
  handleContinue(event: any) {
    event.preventDefault();
    const form = event.target;

    event.target.className += " was-validated";

    if (this.formIsValid()) {
      const changeName = form.elements.userName.value;
      this.setState({ loaderTrue: true });
      this.setState({ userName: changeName },()=>{
        this.props.changeModule(this.state);
      });
      this.setState({ loaderTrue: false });
    }
  }
  formIsValid = () => {
    const userName = { ...this.state.userName };
    let isGood = true;
    if (userName.value === "") {
      userName.isValid = false;
      userName.message = "Please enter username";
      isGood = false;
    }
    if (!isGood) {
      this.setState({
        userName,
      });
    }
    return isGood;
  };

  cancel = () => {
    this.setState({ navigate: true });
    if (this.props.page === "timeclock") {
      return this.props.cancelResetPassword();
    }
  };

  render() {
    const { navigate, userName, nameLength } = this.state;
    if (navigate && this.props.page !== "timeclock") {
      return <Redirect to={this.state.redirect} />;
    }
    /************ Page Loader *************** */
    var loaderTrue = this.state.loaderTrue;
    var loadShow;
    if (loaderTrue) {
      loadShow = <PageLoader />;
    }

    const isEnabled = (userName?.value == "undefined" || userName?.value == ""
    || userName?.value == null
    ) ? false : userName?.isValid;

    return (
      <div className="forgot-password">
        {loadShow}
        <div className="header-sec">
          <div className="sec-ttl">Forgot Password</div>
          <div className="sec-desc">To reset your password, enter your Inn-Flow username below and follow the steps.</div>
        </div>
        <Form
          className="frm-section"
          name="forgotForm"
          onSubmit={this.handleContinue}
          noValidate
        >
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Inn-Flow Username</Form.Label>
            <Form.Control
              type="text"
              className={this.state.userName.isValid ? "" : "invalidusername"}
              value={userName.value}
              onChange={this.onChange}
              name="userName"
              //placeholder="Usernames are not your email address."
              autoComplete="off"
            />
            <div className="info-txt">If you forgot your username, please contact your manager.</div>
            <span className="invalid-username">{userName.message}</span>
          </Form.Group>
          <div className="frm-action">
            <Button variant="primary" type="submit" onSubmit={this.handleContinue} disabled={!isEnabled}>Continue</Button>
            <Button variant="primary" type="button" className="white-bg" onClick={() => this.cancel()}>Cancel</Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(GetUser);
