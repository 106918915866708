 import * as React from "react";
import { Dropdown} from "react-bootstrap";
import { ModuleReports } from "./../../Common/Services/ModuleReports";

export class YearSelector extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      placeHolder: "Select Year",
      filterData: [],
      years: [],
      inputValue: "",
    };
  }
  getAccountingYears = async () => {
    try {
      ;
      const result: string[] = await ModuleReports.GetAllAccountingYears();
      if (result?.length > 0) {
        this.setState({ years: result, filterData: result });
      }
    } catch (error) {
      // Need to handle this
      console.log(error);
    }
  };

  componentDidMount = () => {
    this.getAccountingYears();
    const { defaultValue } = this.props;
    if (defaultValue) {
      this.yearSelectModal(defaultValue);
      this.props.onYearChange(defaultValue);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { defaultValue } = this.props;
    if (prevProps?.defaultValue !== defaultValue && !defaultValue) {
      this.yearSelectModal("Select Year");
    }
  };

  handleYearSearch = (e: { target: { value: string } }) => {
    const updatedList = this.state.years.filter((item) => {
      return (
        item?.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) >
        -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  };

  handleDropdownChange = (e: any) => {
    this.props.onYearChange(e);
    this.resetSerchBox();
  };

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.years });
  };
  yearSelectModal = (eventKey: any) => {
    this.setState({ inputValue: "" });
    this.setState({ placeHolder: eventKey });
  };
  render() {
    const onFocusInput = (eventKey: any) => {
      setTimeout(() => {
        this.inputElement.current.focus();
      }, 100);
    };

    return (
      <Dropdown
        className="single-select"
        onSelect={this.yearSelectModal}
        onClick={onFocusInput}
      >
        <Dropdown.Toggle id="Modal-year">
          {this.state.placeHolder === "Select Year" && (
            <span className="placeholder">{this.state.placeHolder}</span>
          )}
          {this.state.placeHolder !== "Select Year" && (
            <span
              style={{
                width: " 95%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              {this.state.placeHolder}
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="search-sec">
            <input
              type="text"
              className="form-control"
              onChange={this.handleYearSearch}
              placeholder="Filter Year"
              value={this.state.inputValue}
              ref={this.inputElement}
            />
          </div>
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (
              <Dropdown.Item
                eventKey={item}
                onClick={() => this.handleDropdownChange(item)}
              >
                {item}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
