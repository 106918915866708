import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import _ from "lodash";
import { Facility } from '../../Common/Services/Facility';
import { IMaintenanceByLocation } from '../../Common/Contracts/IFacilities';
import moment from 'moment';
import {PreventativeMaintenanceRightPanel} from "./PreventativeMaintenanceRightPanel";

export class AssetMaintenance extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {       
          tableData:[],
          tableDataLength : 0,
          sortingOrder: "asc",
          sortingField: "location",
          maintenanceScheduleId:"",
          cycleId:"",
          hidValue:this.props.parentData.hidValue,
          locationId:this.props.roomId,
          isReload: false,
          createEditPermission:false,
    };

    // Define your column configuration (e.g., this.columns)
  }

  componentDidMount() {

    this.CheckAddEditPermission();
    this.getMaintenance();
  }

  CheckAddEditPermission = () => {
    Facility.FacilityMaintenanceCheckEditStatusPermission().then((res) => {
      this.setState({ createEditPermission: res?.result });
    });
  };
  getMaintenance = () => {
    let request = {} as IMaintenanceByLocation;
    // request.locationid=this.props.parentData.locationId;
    request.hotelID=this.props.parentData.hidValue
    request.locationId=this.props.roomId
    Facility.GetMaintenanceByLocation(request).then((result: any) => {
      this.setState({tableData : result,tableDataLength : result.length});
    }
    ).catch(err => {
        console.log(err)
    });
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        this.sortTableData(this.state.tableData, this.state.sortingField);
      }
    );
  };

  sortTableData = (data: any, fieldName) => {
    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(data, [fieldName], ["asc", "asc"]);
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(data, [fieldName], ["desc", "asc"]);
    }
    this.setState({ tableData: data });
  };

  sortImage = (order) => {

    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    return null;
  };

  closeSlideout = () => {
    this.props?.handleHideSlideout(this.state.isReload);
  }

  handleShowSlideout=(row:any)=>{
    //debugger;
    this.setState({maintenanceScheduleId:row.maintenanceId,cycleId:row.cycleId,},()=>{
      this.setState({ isSlideOut:true});
    })   
  }

  handleHideSlideout=(row:any)=>{
    this.setState({ isSlideOut: false } , () => {
      this.getMaintenance();
    }); 
  }

  render() {
    let dateFormat="MM/DD/YY";
    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.handleShowSlideout(row)
      },
  
    }

    const columns = [
        {
          dataField: "name",
          text: "Name",
          headerFormatter: (column, colIndex) => {
            return (
              <div
                className={`d-inline-block ${this.state.sortingField === "name" ? "active" : ""
                  }`}
                onClick={() => this.handleSortByColumn("name")}
              >
                {column.text}
                {this.sortImage(this.state.sortingOrder)}
              </div>
            );
          },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <>
                <EllipsisWithTooltip placement="bottom">
                  <div className="work-name"> {row.name}</div>
                </EllipsisWithTooltip>
              </>
            );
          },
  
        },
        {
          dataField: "frequency",
          searchable: false,
          text: "Frequency",
          headerFormatter: (column, colIndex) => {
            return (
              <div
                className={`d-inline-block ${this.state.sortingField === "frequency" ? "active" : ""
                  }`}
                onClick={() => this.handleSortByColumn("frequency")}
              >
                {column.text}
                {this.sortImage(this.state.sortingOrder)}
              </div>
            );
          },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <>
                <EllipsisWithTooltip>
                  <div className={`frequency ${row.frequency.toLowerCase()}`}>{row.frequency}</div>
                </EllipsisWithTooltip>
              </>
            )
          },
  
  
        },
        {
          dataField: "status",
          searchable: false,
          text: "Status",
          headerFormatter: (column, colIndex) => {
            return (
              <div
                className={`d-inline-block ${this.state.sortingField === "status" ? "active" : ""
                  }`}
                onClick={() => this.handleSortByColumn("status")}
              >
                {column.text}
                {this.sortImage(this.state.sortingOrder)}
              </div>
            );
          },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <>
                <EllipsisWithTooltip><div className={`badge ${row.status.toLowerCase() === "on hold" ? "hold-badge" : row.status.toLowerCase() === "in progress" ? "progress-badge" : row.status.toLowerCase() === "completed" ? "completed" : row.status.toLowerCase() === "active" ? "color-green" : row.status.toLowerCase() === "overdue" ? "overdue" : ""}`}>{row.status}</div></EllipsisWithTooltip>
              </>
            )
          },
  
  
        },
        {
          dataField: "dueDate",
          text: "Due Date",
          headerFormatter: (column, colIndex) => {
            return (
              <div
                className={`d-inline-block ${this.state.sortingField === "dueDate" ? "active" : ""
                  }`}
                onClick={() => this.handleSortByColumn("dueDate")}
              >
                {column.text}
                {this.sortImage(this.state.sortingOrder)}
              </div>
            );
          },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <>
                <EllipsisWithTooltip placement="bottom">
                  <div className="work-name"> {row.dueDate !== null ? moment(row.dueDate).format(dateFormat) : ""}</div>
                </EllipsisWithTooltip>
              </>
            );
          },
  
        },
  
      ];

    return (
       <div>
      {this.state.isSlideOut && (
        <div className="location-preventative-maintenance-slideOut slideOut-level-2">
              <div className="breadcrumb-bar">
                <button type="button" className="btn btn-primary" onClick={this.handleHideSlideout}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none"><path d="M25.116 3.1328L22.4208 0.4375L11.6396 11.2187L22.4208 22L25.116 19.3045L17.0302 11.2187L25.116 3.1328Z" fill="#2CAF92"></path><path d="M11.6396 0.4375L14.3349 3.1328L6.24901 11.2187L14.3349 19.3045L11.6396 21.9998L0.858398 11.2187L11.6396 0.4375Z" fill="#2CAF92"></path></svg><span>Back to {this.props.parentData?.name}</span>
                  </button>
              </div>
          <div>
            <PreventativeMaintenanceRightPanel           
                parentState={this.state}  
                closeMaintenanceSlideout={this.closeSlideout}  
                createEditPermission={this.state.createEditPermission}     
            />
          </div>
        </div>

      )}


      <div>
        <BootstrapTable
          keyField="workOrderID"
          data={this.state.tableData}
          columns={columns}
          search
          hover
          rowEvents={tableRowEvents}
        />
      </div>
      </div>  
    );
  }
}
