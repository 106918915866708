import * as React from "react";
import { MultiSelectSearchAllHid } from "../../Common/Components/MultiSelectSearchAllHid";
import { Hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";

export class MultiHotelsByPositivePay extends React.Component<any, any> {
  private multiselectChild: any;
  private SingleselectChild: any;

  constructor(props: any) {
    super(props);
    this.multiselectChild = React.createRef();
    this.state = {
      hotelList: [],
      selectedLetterCode: [],
      PayFileFormatId: this.props?.PayFileFormatId,
    };
  }

  bindhids = (PayFileFormatId) => {
    let getUserHotelAccess: any;
    getUserHotelAccess = Hotel.getUserHotelByPositivePay(PayFileFormatId);
    getUserHotelAccess
      .then(async (result: any[] | null) => {
        if (result != null && result.length > 0) {
          let hotelList: any[] = [];
          result.forEach((element) => {
            let newItem = {} as any;
            newItem.hotelID = element.hotelID;
            newItem.value = element.lettercode;
            newItem.description = element.hotelName;
            newItem.isChecked = false;
            newItem.hotelType = element.hotelType;
            // newItem.cCode = element.pCompanyCode;
            // newItem.oprid = element.oprId;

            hotelList.push(newItem);
          });

          this.setState({ hotelList: hotelList });
        } else {
          this.setState({ hotelList: [] });
        }
      })
      .catch((error) => {
        // Need to handle this
        console.log(error);
      });
  };
  componentDidMount() {
    this.bindhids(this.state.PayFileFormatId);
  }

  updateHotelList = (PayFileFormatId) => {
    this.bindhids(PayFileFormatId);
  };

  removalChips = (removalChips) => {
    let hotelList = [...this.state.hotelList];
    // let tempHotelList: any = [...this.state.hotelList];
    if (removalChips) {
      let parentId = removalChips.uniqueno;
      for (let i = this.state.hotelList.length - 1; i >= 0; i--) {
        if (Number(this.state.hotelList[i].rGroupID) === Number(parentId)) {
          hotelList.splice(i, 1);
        }
      }
      // hotelList.pop();
      this.setState({ hotelList });
    } else {
      this.setState({ hotelList: [] });
    }
  };

  componentDidUpdate = (prevprops, prevState) => {
    let hotelList = [...this.state.hotelList];
    const { defaultValue } = this.props;
    if (
      (prevState?.hotelList?.length !== hotelList?.length ||
        prevprops.defaultValue !== defaultValue) &&
      hotelList?.length > 0 &&
      defaultValue?.length > 0
    ) {
      let hotelIDs = defaultValue.split(",")?.map((r) => +r);
      const selectedHotels = hotelList?.filter((r) =>
        hotelIDs?.includes(r.hotelID)
      );
      hotelIDs = hotelIDs.filter((r) =>
        selectedHotels?.map((r) => r?.hotelID)?.includes(r)
      );
      const selectLength = selectedHotels?.filter((r) => r?.isChecked)?.length;
      //if(hotelIDs?.length !== selectLength) {
      hotelList = hotelList?.map((item) => {
        if (hotelIDs?.includes(item?.hotelID)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      });
      this.setState({ hotelList });
      //}
    }

    if (prevprops?.defaultValue !== defaultValue && !defaultValue) {
      let hotelList = [...this.state.hotelList];
      hotelList = hotelList?.map((item) => {
        item.isChecked = false;
        return item;
      });
      this.setState({ hotelList });
    }
  };

  updateMultiSelectItem = (selectedItem: any) => {
    this.setState({ selectedLetterCode: selectedItem }, () => {
      this.props.handleBusinessAccountChange(selectedItem, true);
    });
  };

  updateSingleSearchItem = (selectedItem: any) => {
    this.setState({ selectedLetterCode: selectedItem }, () => {
      this.props.handleBusinessAccountChange(selectedItem, true);
    });
  };

  dataChange = () => {
    this.setState({ isUnsavedChanges: true });
  };

  // disablebuton=(e: React.MouseEvent<HTMLElement>)=>
  // {
  //
  //   const target = e.target as HTMLElement;
  //   let attr = target.getAttribute("btnselection");
  //   console.log(attr);
  // style={{visibility: this.state.show ? "visible" : "hidden",}}
  // }

  render() {
    return (
      <div
        id="businessAccountSelectorbypositivepay"
        className={
          this.props.isDisabled
            ? "account-selector noPointer"
            : "account-selector"
        }
      >
        <MultiSelectSearchAllHid
          ref={this.SingleselectChild}
          itemList={this.state.hotelList}
          updateMultiSelectItem={this.updateMultiSelectItem.bind(this)}
          dataChange={this.dataChange.bind(this)}
          isSelectAll={true}
          filtertitle={"Filter EHIDs"}
        />
      </div>
    );
  }
}
