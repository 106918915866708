import React from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {
  ILogHistoryProps,
  ILogHistoryState,
  ICurrentFilter,
} from "./../../../Common/Contracts/IScheduleLogHistory";
import { ChangeLogs } from "./../../../Common/Components/ChangeLogs/ChangeLogs";
import { UserManagement } from "../../../Common/Services/UserManagement";
import {
  IUserChangeLogRequest,
  IUserChangeLogResponse,
} from "../../../Common/Contracts/IUserChangeLog";
import { Utils } from "../../../Common/Utilis";

export class UsersLogHistory extends React.Component<
  ILogHistoryProps,
  ILogHistoryState
> {
  private pageName: string = "UserLogHistory";
  state = {
    tableData: [],
    filteredData: [],
    tableColumns: [],
    title: this.props.isViewAll ? "Users History" : "User History",
    keyFieldName: "rowNumber",
    isDataLoading: false,
    scheduleId: 0,
    isDrillDownView: false,
    filterColumns: [],
    filterPopUps: [],
    filterPopUpsCopy: [],
    showFilterPopUp: false,
    popClassName: "",
    currentFilter: {
      filterName: "",
      filterValue: "",
      filterKeyName: "",
      filterInputType: "",
    },
    tableClassName: "schedule-log-table",
    startDate: this.props.payPeriodStartDate,
    endDate: this.props.payPeriodEndDate,
    pageSize: 20,
    currentPageNo: 1,
    totalDataLength: 0,
    drillDownShiftDate: "",
  };

  hoverOutEllipsis = () => {
    $(".tooltip")?.removeClass("show");
    $(".tooltip")?.removeClass("fade");
  };

  cellTooltipFormatter = (cell: any, row: any, rowIndex: any) => {
    return (
      <>
        <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
      </>
    );
  };

  cellTooltipPositionFormatter = (cell: any, row: any, rowIndex: any) => {
    return (
      <>
        <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
      </>
    );
  };

  tableColumns = [
    {
      dataField: "rowNumber",
      text: "",
      hidden: true,
    },
    {
      dataField: "userName",
      text: "User Name",
      filterInputType: "text",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "editor",
      text: "Editor",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "18%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "editDateTime",
      text: "Date Time",
      filterInputType: "date",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div>{cell}</div>
          </>
        );
      },
    },
    {
      dataField: "fieldName",
      text: "Field Name",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "oldValue",
      text: "Old Value",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "28%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "newValue",
      text: "New Value",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "28%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "triggerName",
      text: "Trigger",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <div>{cell}</div>
          </>
        );
      },
    },
  ];

  componentDidMount = () => {
    this.buildDefaultData();
    // this.setDefaultDateTimeForAllUserseView();
  };

  buildDefaultData = () => {
    const filterColumns = (
      this.props?.isViewAll
        ? this.tableColumns.filter((item) => item.text)
        : this.tableColumns.filter(
            (item) => item.dataField !== "userName" && item.text
          )
    ).map((item) => ({
      text: item.text,
      key: item.dataField,
      isFilterApplied: false,
      filterInputType: item?.filterInputType,
    }));

    

    this.setState({
      filterPopUps: [],
      filterColumns,
      tableColumns: this.tableColumns,
    });
    this.getChangeLogData(true);
  };

  validateDates = () => {
    const currentFilter: any = { ...this.state.currentFilter };
    if (currentFilter.filterKeyName === "editDateTime") {
      const { startDate, endDate } = this.state;

      if (!endDate) {
        Utils.toastError("Please enter End Date", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
      } else if (!startDate) {
        Utils.toastError("Please enter Start Date", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  setDefaultDateTimeForAllUsersView = () => {
    if (
      this.props.isViewAll &&
      this.state?.filterPopUps?.filter(
        (item: any) => item.filterKeyName === "editDateTime"
      )?.length == 0 &&
      this.state?.filterPopUps?.filter(
        (item: any) => item.filterKeyName === "userName"
      )?.length == 0 &&
      this.state.tableData.length > 0 &&
      this.state?.startDate == "" &&
      this.state?.endDate == ""
    ) {
      const datesArray = this.state.tableData.map((x: any) =>
        Date.parse(x?.editDateTime)
      );

      const maxDate = moment(new Date(Math.max(...datesArray))).format(
        "MM/DD/YY"
      );
      const minDate = moment(new Date(maxDate))
        .subtract(6, "d")
        .format("MM/DD/YY");

      const currentFilter: ICurrentFilter = {
        filterName: "Date Time",
        filterValue: `${minDate} - ${maxDate}`,
        filterKeyName: "editDateTime",
        filterInputType: "date",
        hideClearAndCancelBtn: true,
      };

      this.setState({ currentFilter }, () => {
        this.updateFilters(true);
      });
    }
  };

  updateFilters = (isViewAll = false) => {
    const currentFilter: ICurrentFilter = { ...this.state.currentFilter };
    let filterPopUps: ICurrentFilter[] = [...this.state.filterPopUps];
    let filterIndex = filterPopUps.findIndex(
      (filter) => filter?.filterName === currentFilter?.filterName
    );

    if (
      currentFilter.filterKeyName === "editDateTime" &&
      !currentFilter.filterValue
    ) {
      currentFilter.filterValue = `${this.props.payPeriodStartDate} - ${this.props.payPeriodEndDate}`;
    }
    if (filterIndex < 0) {
      filterPopUps.push(currentFilter);
    } else {
      filterPopUps[filterIndex] = currentFilter;
    }
    filterPopUps = filterPopUps?.filter((item) => item?.filterValue);
    filterPopUps = filterPopUps.map((item, index) => {
      item.popClassName = "filter-edit-popup-" + index;
      return item;
    });

    filterIndex = filterPopUps.findIndex(
      (filter) => filter?.filterName === currentFilter?.filterName
    );

    if (
      currentFilter.filterKeyName === "editDateTime" &&
      this.props.isViewAll &&
      filterPopUps?.filter((item: any) => item.filterKeyName === "userName")
        ?.length == 0
    ) {
      currentFilter.hideClearAndCancelBtn = true;
      filterPopUps[filterIndex] = currentFilter;
      this.setState({ currentFilter });
    } else {
      currentFilter.hideClearAndCancelBtn = false;
      filterPopUps[filterIndex] = currentFilter;
      const dateFilterIndex = filterPopUps.findIndex(
        (filter) => filter?.filterKeyName === "editDateTime"
      );
      const dateFilter = filterPopUps[dateFilterIndex];
      if (dateFilter) {
        if (
          this.props.isViewAll &&
          filterPopUps?.filter((item: any) => item.filterKeyName === "userName")
            ?.length == 0
        ) {
          dateFilter.hideClearAndCancelBtn = true;
        } else {
          dateFilter.hideClearAndCancelBtn = false;
        }

        filterPopUps[dateFilterIndex] = dateFilter;
      }
      this.setState({ currentFilter });
    }

    this.setState({ filterPopUps, currentPageNo: 1 }, () => {
      if (!isViewAll) {
        this.getChangeLogData();
      }
      this.updateFilterColumns();
    });
  };

  updateFilterColumns = () => {
    const { filterColumns: columns, filterPopUps } = this.state;
    const filteredKeys = filterPopUps?.map(
      (filter: ICurrentFilter) => filter?.filterKeyName
    );
    const filterColumns = columns.map((item: any) => {
      if (filteredKeys.find((key) => key === item?.key)) {
        item.isFilterApplied = true;
      } else {
        item.isFilterApplied = false;
      }
      return item;
    });
    this.setState({ filterColumns });
  };

  onFilterTextChange = (event: any) => {
    const currentFilter: any = { ...this.state.currentFilter };
    currentFilter.filterValue = event.currentTarget?.value;
    this.setState({ currentFilter });
  };
  onFilterChange = (filterItem: any, popClassName): void => {
    const filterPopUps: any[] = [...this.state.filterPopUps];
    let currentFilter: ICurrentFilter = this.state.currentFilter;

    if (filterPopUps.length >= 5) {
      Utils.toastError("You can't apply more than 5 filters", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    if (
      filterPopUps.length >= 5 ||
      filterPopUps.filter((filter) => filter?.filterName === filterItem.text)
        ?.length > 0
    ) {
      return;
    }
    currentFilter = {
      filterName: filterItem?.text,
      filterValue: "",
      filterKeyName: filterItem.key,
      filterInputType: filterItem?.filterInputType,
      popClassName: popClassName,
    };
    this.setState({
      currentFilter,
      currentPageNo: 1,
      showFilterPopUp: true,
      popClassName,
      startDate: this.props.payPeriodStartDate,
      endDate: this.props.payPeriodEndDate,
    });
  };

  removeFilter = (filterName: string): void => {
    let filterPopUps: any[] = [...this.state.filterPopUps];
    if (filterPopUps.length === 0) {
      return;
    }
    filterPopUps = filterPopUps.filter(
      (filter) => filter?.filterName !== filterName
    );
    if (filterPopUps.length > 0) {
      filterPopUps = filterPopUps.map((item, index) => {
        item.popClassName = "filter-edit-popup-" + index;
        return item;
      });
    }
    if (+this.state.scheduleId > 0) {
      this.setState({ tableClassName: "schedule-log-table" });
      this.returnBackFromDrillDown();
    } else {
      const dateFilterIndex = filterPopUps.findIndex(
        (filter) => filter?.filterKeyName === "editDateTime"
      );
      const dateFilter = filterPopUps[dateFilterIndex];
      if (dateFilter) {
        if (
          this.props.isViewAll &&
          filterPopUps?.filter((item: any) => item.filterKeyName === "userName")
            ?.length == 0
        ) {
          dateFilter.hideClearAndCancelBtn = true;
        } else {
          dateFilter.hideClearAndCancelBtn = false;
        }

        filterPopUps[dateFilterIndex] = dateFilter;
      }

      this.setState({ filterPopUps, currentPageNo: 1 }, () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      });
    }
  };

  hideFilterPopUp = () => {
    if (this.state.showFilterPopUp) {
      this.setState({
        showFilterPopUp: false,
        popClassName: "",
      });
    }
  };

  editFilter = (currentFilter: ICurrentFilter, popClassName) => {
    if (currentFilter.filterInputType === "date") {
      const datesArray = currentFilter.filterValue?.split("-");
      this.setState({
        currentFilter,
        popClassName,
        showFilterPopUp: true,
        startDate: datesArray[0]?.trim(),
        endDate: datesArray[1]?.trim(),
      });
    } else {
      this.setState({ currentFilter, popClassName, showFilterPopUp: true });
    }
  };

  returnBackFromDrillDown = () => {
    this.setState(
      {
        scheduleId: 0,
        currentPageNo: 1,
        isDrillDownView: false,
        filterPopUps: this.state.filterPopUpsCopy,
      },
      () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      }
    );
  };

  onPaginationchange = () => {
    const currentPageNo = this.state.currentPageNo + 1;
    this.setState({ currentPageNo }, () => {
      this.getChangeLogData(false, true);
    });
  };

  getChangeLogData = (isDefaultLoad = false, isPagination = false) => {
    if (!isPagination) {
      this.setState({
        tableData: [],
        filteredData: [],
      });
    }

    const { filterPopUps, pageSize, currentPageNo } = this.state;

    const request: IUserChangeLogRequest = {
      fromDate: "",
      toDate: "",
      tenantId: 0,
      userUniqueNo: this.props.shiftId,
      editor: "",
      userName: "",
      isViewAll: this.props?.isViewAll,
      parentUserUniqueId: 0,
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
      pageNumber: currentPageNo,
      pageSize: pageSize,
    } as IUserChangeLogRequest;

    filterPopUps.forEach((item: ICurrentFilter) => {
      if (item.filterKeyName === "editDateTime") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.fromDate = datesArray[0];
        request.toDate = datesArray[1];
      } else if (item.filterKeyName === "userName") {
        request.userName = item.filterValue;
      } else if (item.filterKeyName === "editor") {
        request.editor = item.filterValue;
      } else if (item.filterKeyName === "fieldName") {
        request.fieldName = item.filterValue;
      } else if (item.filterKeyName === "oldValue") {
        request.oldValue = item.filterValue;
      } else if (item.filterKeyName === "newValue") {
        request.newValue = item.filterValue;
      } else if (item.filterKeyName === "triggerName") {
        request.triggerName = item.filterValue;
      }
    });
    this.toggleDataLoading();
    UserManagement.GetUsersChangeLogData(request)
      .then((tableData: IUserChangeLogResponse[] | null) => {
        tableData = tableData?.map((item) => {
          item.editDateTime = moment(item.editDateTime).format(
            "MM/DD/YYYY hh:mm A"
          );

          return item;
        }) as IUserChangeLogResponse[] | null;
        if (isPagination) {
          tableData = [
            ...this.state.tableData,
            ...(tableData as IUserChangeLogResponse[]),
          ];
        }

        const totalDataLength = tableData?.length
          ? tableData[0]?.totalCount
          : 0;
        this.setState(
          {
            tableData: tableData,
            filteredData: tableData,
            totalDataLength,
          },
          () => {
            this.setDefaultDateTimeForAllUsersView();
          }
        );
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        setTimeout(() => this.toggleDataLoading());
      });
  };

  getFilteredData = () => {
    this.onFiltersChange();
  };

  downloadChangeLogData = () => {
    const { filterPopUps } = this.state;

    const request: IUserChangeLogRequest = {
      fromDate: "",
      toDate: "",
      tenantId: 0,
      userUniqueNo: this.props.shiftId,
      userName: "",
      isViewAll: this.props?.isViewAll,
      parentUserUniqueId: 0,
      editor: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
    } as IUserChangeLogRequest;

    filterPopUps.forEach((item: ICurrentFilter) => {
      if (item.filterKeyName === "editDateTime") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.fromDate = datesArray[0];
        request.toDate = datesArray[1];
      } else if (item.filterKeyName === "userName") {
        request.userName = item.filterValue;
      } else if (item.filterKeyName === "editor") {
        request.editor = item.filterValue;
      } else if (item.filterKeyName === "fieldName") {
        request.fieldName = item.filterValue;
      } else if (item.filterKeyName === "oldValue") {
        request.oldValue = item.filterValue;
      } else if (item.filterKeyName === "newValue") {
        request.newValue = item.filterValue;
      } else if (item.filterKeyName === "triggerName") {
        request.triggerName = item.filterValue;
      }
    });

    UserManagement.DownloadChangeLog(
      request,
      `UserChangeLogs_${moment().format("DD/MM/YY")}`
    )
      .then(() => {})
      .catch((error) => {});
  };

  toggleDataLoading = () => {
    this.setState({ isDataLoading: !this.state.isDataLoading });
  };

  handleDatesChange = (date, type, ref) => {
    const { startDate, endDate, filterPopUps } = this.state;
    const currentFilter: any = { ...this.state.currentFilter };

    const isUserNameFilterApplied =
      filterPopUps?.filter((item: any) => item.filterKeyName === "userName")
        ?.length > 0;

    if (type === "StartDate") {
      if (new Date(date) > new Date(endDate) && !this.props?.isViewAll) {
        this.setState({ startDate: "" });
        Utils.toastError(
          "Please enter the Start Date less than or equal to End Date",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        return false;
      }

      if (this.props?.isViewAll) {
        const daysDiff = moment(new Date(endDate)).diff(
          moment(new Date(date)),
          "d"
        );
        if ((daysDiff > 6 || daysDiff < 0) && !isUserNameFilterApplied) {
          let _endDate: any = moment(date).add(6, "days");
          ref.current.setStartDate(_endDate);
          ref.current.setEndDate(_endDate);

          _endDate = _endDate.format("MM/DD/YY");
          currentFilter.filterValue = `${date} - ${_endDate}`;

          this.setState({ startDate: date, endDate: _endDate, currentFilter });
          // Utils.toastError("Date range should not be more than 7 days.", {
          //   position: toast.POSITION.BOTTOM_RIGHT,
          // });

          return false;
        }
      }
      currentFilter.filterValue = `${date} - ${endDate}`;
      this.setState({ startDate: date });
    }

    if (type === "EndDate") {
      if (new Date(startDate) > new Date(date) && !this.props?.isViewAll) {
        this.setState({ endDate: "" });
        Utils.toastError(
          "Please enter the End Date greater than or equal to Start Date",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        return false;
      }

      if (this.props?.isViewAll) {
        const daysDiff = moment(new Date(date)).diff(
          moment(new Date(startDate)),
          "d"
        );
        if ((daysDiff > 6 || daysDiff < 0) && !isUserNameFilterApplied) {
          let _startDate: any = moment(date).subtract(6, "days");
          ref.current.setStartDate(_startDate);
          ref.current.setEndDate(_startDate);

          _startDate = _startDate.format("MM/DD/YY");
          currentFilter.filterValue = `${_startDate} - ${date}`;

          this.setState({
            endDate: date,
            startDate: _startDate,
            currentFilter,
          });
          // Utils.toastError("Date range should not be more than 7 days.", {
          //   position: toast.POSITION.BOTTOM_RIGHT,
          // });

          return false;
        }
      }
      currentFilter.filterValue = `${startDate} - ${date}`;
      this.setState({ endDate: date });
    }

    this.setState({ currentFilter });
  };

  onFiltersChange = () => {
    const currentFilter = this.state.currentFilter;
    this.setState({ currentPageNo: 1 }, () => {
      this.getChangeLogData();
    });

    if (currentFilter?.filterKeyName) {
      this.setState({
        currentFilter: {
          filterName: "",
          filterValue: "",
          filterKeyName: "",
          popClassName: "",
          filterInputType: "",
        },
      });
    }
  };

  clearFilters = () => {
    if (+this.state.scheduleId > 0) {
      this.setState({ tableClassName: "schedule-log-table" });
      this.returnBackFromDrillDown();
    } else {
      this.setState(
        {
          filterPopUps:
            this.state.filterPopUps?.filter(
              (x: any) => x?.hideClearAndCancelBtn
            ) || [],
          currentPageNo: 1,
        },
        () => {
          this.getChangeLogData();
          this.updateFilterColumns();
        }
      );
    }
  };

  render() {
    const { tableColumns } = this.state;

    const functions = {
      downloadChangeLogData: this.downloadChangeLogData,
      updateFilters: this.updateFilters,
      editFilter: this.editFilter,
      onFilterTextChange: this.onFilterTextChange,
      onFilterChange: this.onFilterChange,
      removeFilter: this.removeFilter,
      hideFilterPopUp: this.hideFilterPopUp,
      clearFilters: this.clearFilters,
      onFiltersChange: this.onFiltersChange,
      handleDatesChange: this.handleDatesChange,
      validateDates: this.validateDates,
      onPaginationchange: this.onPaginationchange,
    };
    const forwardedProps = {
      ...this.state,
      ...this.props,
      ...functions,
    };
    return (
      <>
        <ToastContainer autoClose={3000} />
        {tableColumns?.length > 0 && <ChangeLogs {...forwardedProps} />}
      </>
    );
  }
}
