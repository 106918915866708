import React, { forwardRef, useState, useRef, useImperativeHandle, useEffect } from "react";
// import default react-pdf entry
import { Document, Page, pdfjs } from "react-pdf";
import { Dropdown, Form, Button, ButtonGroup } from "react-bootstrap";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ddlSearchWithTab as SingleSearchDropdownList } from "../../Common/Components/ddlSearchWithTab";
import { render } from "@testing-library/react";
import ScrollContainer from 'react-indiana-drag-scroll';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


interface ISelectorProps {
  ref: any;
  uniqueno: any;
  filePreview: any;
  defaultView: any;
  fileNo: any;
  fileCount: any;
  fileName: any;
  docFile: any;
  updateState: any;
  updateFiles: any;
  showBulkSelect: any
  checkBulkSelect: any
  handleFileCheckBox: any
  handleUpdatedFile: any
  handleincompleteFile: any
  saveSplitFile: any;
  scrollAreaHeight: any;
  isModalOpen: any;
  pageType: any;
  handleRotate: any;
  handlePageRemove: any;
  selectedChk: any;
  selectedZoom: any;
  NoDelPermission: any
  isEditPermission: any;
  fileEtype?: any;
}

const PDFViewerNonInvoice: React.FC<ISelectorProps> = forwardRef(
  (props: any, ref) => {
    const [file, setFile] = useState(props);
    const [numPages, setNumPages] = useState(null);
    const [error, setError] = useState(false);
    const [chkids, setchkIds] = useState<Array<any>>(props.selectedChk);
    const [files, setfiles] = useState<Array<any>>([]);
    const [bulkSelect, setBulkSelect] = useState(false);
    const [attachInvoice, setAttachInvoice] = useState(false);
    const chkRef = useRef<any>(null);
    const attachInvoiceModal = useRef<any>(null);
    const innWidthSec = useRef<any>(null);
    const [currentFileNo, setFileNo] = useState(null);
    const [dropdownIDs, setdropdownID] = useState<Array<any>>([]);
    const [incompleteFiles, setincompleteFile] = useState<Array<any>>([]);
    const [toggleIds, settoggleId] = useState('');
    const [inputValue, setinputValue] = useState('');
    const [selectedparm, setselectedparm] = useState<Array<any>>([]);

    useEffect(() => {
      if (props.selectedChk.length > 0) {
        setBulkSelect(true);
      }
      //chkindeterminate();
      updateFileList();
      setTimeout(() => {
        checkBulkSelect();
      }, 500);
    }, [chkids]);

    function handleId(event) {
      console.log(event.currentTarget.id);
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
      setNumPages(nextNumPages);
      // setBulkSelect(true);
    }
    function renderError() {
      setError(true);
    }

    //function updateFileList(fileNo, numPages) {
    function updateFileList() {
      const propsFile = props.docFile;
      let newfiles = [...files];
      propsFile.forEach(element => {
        const count = files.filter((x) => x.fileNo === element.fileUniqueno).length;
        if (count === 0) {
          let newItem: any = [];
          newItem.fileNo = element.fileUniqueno;
          newItem.totalpage = Number(element.pageno);
          newItem.etype = element.etype;
          newfiles.push(newItem);
        }
      });
      setfiles(newfiles);
      // const count1 = files.filter((x) => x.fileNo === fileNo).length;
      // if (count1 === 0) {
      //   let item: any = {};
      //   item.totalpage = numPages;
      //   item.fileNo = fileNo;
      //   newfiles.push(item);
      //   setfiles(newfiles);
      //   props.updateFiles(item);
      //}
      //props.updateFiles(files);
    }

    function handleChange(e: any) {
      const newincompleteFile = props.handleincompleteFile();
      const updatedList = newincompleteFile.filter((item: any) => {
        return (item.createDate.toLowerCase() + item.userName.toLowerCase() + item.vendor.toLowerCase()).indexOf(e.target.value.toLowerCase()) > -1;
      });
      setincompleteFile(updatedList);
      setinputValue(e.target.value);
      //this.setState({ filterData: updatedList, inputValue: e.target.value });
    }

    function handledropDown(eventKey: any, toggelId, selectedId: any) {
      hideAttachInvoiceList(eventKey, selectedId);
      $("#" + toggelId).click();
    }

    function handleAttachInvoice(eventKey: any, pageno, pageType, fileID, selectedId: any) {
      props.isModalOpen("yes");
      const newincompleteFile = props.handleincompleteFile();
      setincompleteFile(newincompleteFile);
      let parmlist: any = [];
      if (selectedparm.length > 0) {
        parmlist = selectedparm.filter((x) => x.fileID === 0);
      }
      let parm: any = {};
      parm.pageno = pageno;
      parm.pageType = pageType;
      parm.fileID = fileID;
      parmlist.push(parm)
      setselectedparm(parmlist);
      const newIds = [...dropdownIDs];
      let item: any = {};
      item.id = selectedId;
      newIds.push(item);
      setdropdownID(newIds);
      const handle = setTimeout(() => {
        const handleAttachInvoicePosX = attachInvoiceModal.current.getBoundingClientRect().x;
        const handleAttachInvoicePosY = attachInvoiceModal.current.getBoundingClientRect().y;
        const innerWidthSec = innWidthSec.current.getBoundingClientRect().width;
        const innerHeightSec = props.scrollAreaHeight;
        debugger;
        //console.log("handleAttachInvoicePosY-" + handleAttachInvoicePosY + "innerHeightSec-" + innerHeightSec);
        if (handleAttachInvoicePosX <= innerWidthSec - 210) {
          attachInvoiceModal.current.style.left = '0';
        }
        else {
          attachInvoiceModal.current.style.right = '0';
        }
        if (handleAttachInvoicePosY <= innerHeightSec - 300) {
          attachInvoiceModal.current.style.top = '34px';
        }
        else {
          attachInvoiceModal.current.style.bottom = '34px';
        }
      }, 100);
    }

    function saveAttachInvoice(eventKey, item) {
      props.isModalOpen("no");
      let parm = [...selectedparm];

      let splitFile: any = [];
      let splitrequest: any = {};
      const newincompleteFile = props.docFile;//props.handleincompleteFile();
      let pageno = parm[0].pageno;
      let pageType = parm[0].pageType;
      let fileID = parm[0].fileID;
      splitFile = newincompleteFile.filter((x) => x.fileUniqueno === fileID);
      let NoofSplit = "";
      let singleSplitPageNo = 0;
      let SplitPageNofrom = 0;
      let SplitPageNoTo = 0;
      if (pageno === 1 && pageType === "Single" && pageno !== Number(splitFile[0].pageno)) {
        NoofSplit = "SINGLEMERGE"
        singleSplitPageNo = pageno;
      } else if (pageno === 1 && pageType === "Single" && pageno === Number(splitFile[0].pageno)) {
        NoofSplit = "FULLMERGE"
        singleSplitPageNo = pageno;
      } else if (pageno === 1 && pageType === "All") {
        NoofSplit = "FULLMERGE"
        SplitPageNofrom = 1;
        SplitPageNoTo = Number(splitFile[0].pageno);
      } else if (pageno > 1 && pageType === "Single") {
        NoofSplit = "SINGLEMERGE"
        singleSplitPageNo = pageno;
      } else if (pageno > 1 && pageType === "All" && pageno !== Number(splitFile[0].pageno)) {
        NoofSplit = "MULTIMERGE"
        SplitPageNofrom = pageno;
        SplitPageNoTo = Number(splitFile[0].pageno);
      } else if (pageno > 1 && pageType === "All" && pageno === Number(splitFile[0].pageno)) {
        NoofSplit = "FULLMERGE"
        SplitPageNofrom = 1;
        SplitPageNoTo = Number(splitFile[0].pageno);
      }
      splitrequest.etype = "IncompleteInvoice";
      splitrequest.uploadFile = splitFile[0].uploadFile;
      splitrequest.uniqueno = splitFile[0].uniqueNo;
      splitrequest.NoofSplit = NoofSplit;
      splitrequest.singleSplitPageNo = singleSplitPageNo;
      splitrequest.SplitPageNofrom = SplitPageNofrom;
      splitrequest.SplitPageNoTo = SplitPageNoTo;
      splitrequest.fileUniqueno = fileID;
      splitrequest.oldUniqueno = item.uniqueno;
      splitrequest.OldFileUniqueno = item.fileUniqueno;
      props.saveSplitFile(splitrequest, pageno, 1);
    }


    function handleSplitInvoice(eventKey, pageno, pageType, fileID, selectedId) {
      let splitFile: any = [];
      let splitrequest: any = {};
      const newincompleteFile = props.docFile;
      splitFile = newincompleteFile.filter((x) => x.fileUniqueno === fileID);
      let NoofSplit = "";
      let singleSplitPageNo = 0;
      let SplitPageNofrom = 0;
      let SplitPageNoTo = 0;
      if (pageno === 1 && pageType === "Single" && pageno !== Number(splitFile[0].pageno)) {
        NoofSplit = "single"
        singleSplitPageNo = pageno;
      } else if (pageno === 1 && pageType === "Single" && pageno === Number(splitFile[0].pageno)) {
        NoofSplit = "full"
        singleSplitPageNo = pageno;
      } else if (pageno === 1 && pageType === "All") {
        NoofSplit = "full"
        SplitPageNofrom = 1;
        SplitPageNoTo = Number(splitFile[0].pageno);
      } else if (pageno > 1 && pageType === "Single") {
        NoofSplit = "single"
        singleSplitPageNo = pageno;
      } else if (pageno > 1 && pageType === "All" && pageno !== Number(splitFile[0].pageno)) {
        NoofSplit = "multi"
        SplitPageNofrom = pageno;
        SplitPageNoTo = Number(splitFile[0].pageno);
      } else if (pageno > 1 && pageType === "All" && pageno === Number(splitFile[0].pageno)) {
        NoofSplit = "full"
        SplitPageNofrom = 1;
        SplitPageNoTo = Number(splitFile[0].pageno);
      }
      splitrequest.etype = "IncompleteInvoice";
      splitrequest.uploadFile = splitFile[0].uploadFile;
      splitrequest.uniqueno = splitFile[0].uniqueNo;
      splitrequest.NoofSplit = NoofSplit;
      splitrequest.singleSplitPageNo = singleSplitPageNo;
      splitrequest.SplitPageNofrom = SplitPageNofrom;
      splitrequest.SplitPageNoTo = SplitPageNoTo;
      splitrequest.fileUniqueno = fileID;
      splitrequest.oldUniqueno = 0;
      splitrequest.OldFileUniqueno = 0;
      props.saveSplitFile(splitrequest, pageno, 2);
    }

    function hideAttachInvoiceList(eventKey: any, selectedId: any) {
      const newIds = dropdownIDs.filter((x) => x.id !== selectedId);
      setdropdownID(newIds);
      props.isModalOpen("no");
    }

    function handleRotate(eventKey: any, currentfileNo: any, currentPage: any) {
      const chkIds = [...chkids];
      props.handleRotate(chkIds, currentfileNo, currentPage);
    }

    function handlePageRemove(eventKey: any, currentfileNo: any, currentPage: any) {
      const chkIds = [...chkids];
      props.handlePageRemove(chkIds, currentfileNo, currentPage);
    }

    function handleSelectedItem(id: any, control: any) {
      console.log(control);
      console.log(id);
    }
    function onTAB(control: any, id: any) {
    }

    function handleFileCheckbox(event, fileNo) {
      if (!event.target.checked) {
        const newIds = chkids.filter((x) => x.fileNo !== fileNo);
        setchkIds(newIds);
        props.handleFileCheckBox(event, files, fileNo, "", "All")
      } else {
        const newIds = [...chkids];
        files.filter((x) => x.fileNo === fileNo).forEach(element => {
          for (let inx = 0; inx < element.totalpage; inx++) {
            let id = element.fileNo.toString() + '_' + inx.toString()
            const selectedId = `custom_${id}`
            const newId = chkids.filter((x) => x.id === selectedId);
            if (newId.length === 0) {
              let item: any = {};
              item.id = selectedId;
              item.fileNo = element.fileNo;
              newIds.push(item);
            }
          }
          setchkIds(newIds);
          props.handleFileCheckBox(event, files, fileNo, "", "All")
          //const newIds = chkids.filter((x) => x.id !== selectedId);
        });
      }
    }

    function handleCheckbox(event, id, fileNo, totalpage) {
      debugger
      //const selectedId = event.target.value;
      const selectedId = `custom_${id}`
      if (!event.target.checked) {
        const newIds = chkids.filter((x) => x.id !== selectedId);
        setchkIds(newIds);
        props.handleFileCheckBox(event, files, fileNo, selectedId, "Single")
      } else {
        const newIds = [...chkids];
        let item: any = {};
        item.id = selectedId;
        item.fileNo = fileNo;
        newIds.push(item);
        setchkIds(newIds);
        // setFileNo(fileNo);
        props.handleFileCheckBox(event, files, fileNo, selectedId, "Single")
      }
      // setTimeout(() => {
      //   const newIds1 = [...chkids];
      //   const chklen = newIds1.filter((x) => x.fileNo == fileNo)?.length;
      //   const filelen = files.filter((x) => x.fileNo == fileNo)?.length;
      //   if (chklen > 0 && chklen !== filelen) {
      //     chkRef.current.indeterminate = true;
      //   }
      //   else {
      //     chkRef.current.indeterminate = false;
      //   }
      // }, 1000);
    }
    function handleBulkSelect() {
      props.showBulkSelect("show");
      //alert("bulk select")
    }

    function checkBulkSelect() {
      if (bulkSelect) {
        const newfiles: any = [];
        const newchkids: any = [];
        let newUpdatedFile: any = [];
        newUpdatedFile = props.handleUpdatedFile();
        files.forEach(element => {
          let inx = newfiles.filter(x => x.fileNo === element.fileNo);
          if (inx.length === 0) {
            newfiles.push(element);
          }
        });
        newUpdatedFile.forEach(element => {
          let inx1 = newchkids.filter(x => x.fileNo === element.fileNo);
          if (inx1.length === 0) {
            newchkids.push(element);
          }
        });
        let totalpage = 0;
        newfiles.forEach(element => {
          totalpage = totalpage + element.totalpage;
        });
        let chk = "";
        if (newUpdatedFile.length > 0) {
          chk = ((newfiles.length === newchkids.length) && (totalpage === newUpdatedFile.length)) ? "full" : "helf";
        } else {
          chk = "removeAll"
        }
        props.checkBulkSelect(chk, newUpdatedFile.length)
      }
    }



    function chkindeterminate() {

      if (bulkSelect) {
        const chklen = chkids.filter((x) => x.fileNo == props.fileNo)?.length;
        const file = files.filter((x) => x.fileNo == props.fileNo);

        if (chklen > 0 && Number(chklen) !== Number(file[0].totalpage)) {
          chkRef.current.indeterminate = true;
        }
        else {
          chkRef.current.indeterminate = false;
        }

      }
    }

    useImperativeHandle(ref, () => ({


      chkindeterminateCheckBox() {

        if (bulkSelect) {
          const propsFile = props.docFile;
          const chklen = chkids.filter((x) => x.fileNo == props.fileNo)?.length;
          const file = propsFile.filter((x) => x.fileUniqueno == props.fileNo);

          if (chklen > 0 && Number(chklen) !== Number(file[0].pageno)) {
            chkRef.current.indeterminate = true;
          }
          else {
            chkRef.current.indeterminate = false;
          }
        }
      },

      showBulkSelect() {
        setBulkSelect(true);

      },

      hideBulkSelect() {
        if (bulkSelect) {
          //chkRef.current.indeterminate = false;
        }
        setBulkSelect(false);
        setchkIds([]);
      },

      afterUploadDone() {
        // setTimeout(() => {
        //   $("#page_4_3").addClass("bg-success");
        // }, 500)
      },

      hanndleDeleteFile(fileNo) {
        //const newIds = chkids.filter((x) => x.fileNo !== fileNo);
        //setchkIds(newIds);
        const newfiles = files.filter((x) => x.fileNo !== fileNo);
        setfiles(newfiles);
        setchkIds([]);
      },

      handleAllCheckbox(event) {

        const newIds = [...chkids];
        if (event.target.checked) {
          files.filter((file) => file?.etype?.toLowerCase() !== "invoices").forEach(element => {
            for (let inx = 0; inx < element.totalpage; inx++) {
              let id = element.fileNo.toString() + '_' + inx.toString()
              const selectedId = `custom_${id}`
              const newId = chkids.filter((x) => x.id === selectedId);
              if (newId.length === 0) {

                let item: any = {};
                item.id = selectedId;
                item.fileNo = element.fileNo;
                newIds.push(item);

              }
            }
            setchkIds(newIds);
            props.handleFileCheckBox(event, files, 0, "", "bulkSelect")
            //const newIds = chkids.filter((x) => x.id !== selectedId);
          });
        }
        else {
          setchkIds([]);
          props.handleFileCheckBox(event, files, 0, "", "bulkSelect")
        }
      }
    }));




    return (
      <div ref={innWidthSec}>
        {/* <Page pageNumber={pageNumber} /> */}


        <Document file={file.filePreview} onLoadSuccess={onDocumentLoadSuccess} loading={""} error={renderError}>
          <div className="custom-page-action d-flex justify-content-between align-items-center">
            <div className="page-ttl">
              {bulkSelect && (props.pageType?.toString().toLowerCase() !== "payment" || (props.pageType?.toString().toLowerCase() === "payment" && props?.fileEtype?.toLowerCase() !== "invoices")) && (
                <Form.Check
                  custom
                  type="checkbox"
                  key={`custom_${props.fileNo}`}
                  id={`custom_${props.fileNo}`}
                  label={
                    <EllipsisWithTooltip placement="bottom">
                      {file.fileName}
                    </EllipsisWithTooltip>

                  }
                  ref={chkRef => {
                    if (chkRef && bulkSelect) {
                      const chklen = chkids.filter((x) => x.fileNo == props.fileNo)?.length;
                      if (files.length > 0) {
                        const file = files.filter((x) => x.fileNo == props.fileNo);
                        if (chklen > 0 && Number(chklen) !== Number(file[0].totalpage)) {
                          chkRef.indeterminate = true;
                        }
                        else {
                          chkRef.indeterminate = false;
                        }
                      }
                    }
                  }}
                  //ref={chkRef}
                  onChange={(e: any) => handleFileCheckbox(e, props.fileNo)}
                  checked={(chkids.filter((x) => x.fileNo.toString() === props.fileNo.toString()).length === files.filter((x) => x.fileNo.toString() === props.fileNo.toString())[0]?.totalpage) ? true : false}
                  style={{ display: props.defaultView != "doc-view" ? "" : "none" }}

                />
              )}
              {(props.defaultView != "doc-view" && (!bulkSelect || (bulkSelect && props.pageType?.toString().toLowerCase() === "payment" && props?.fileEtype?.toLowerCase() === "invoices"))) && (
                <EllipsisWithTooltip placement="bottom">{file.fileName}</EllipsisWithTooltip>
              )}
            </div>

          </div>

          {Array.from(new Array(numPages), (_el, index) => (
            //updateFileList(props.fileNo,numPages),s
            <>
              {props.defaultView === "doc-view" && (
                <div className="custom-page-action d-flex justify-content-between align-items-center doc-header">
                  <div className="page-ttl">
                    {bulkSelect && (props.pageType?.toString().toLowerCase() !== "payment" || (props.pageType?.toString().toLowerCase() === "payment" && props?.fileEtype?.toLowerCase() !== "invoices")) && (
                      <Form.Check
                        custom
                        type="checkbox"
                        key={`custom_${props.fileNo.toString() + '_' + index.toString()}`}
                        id={`custom_${props.fileNo.toString() + '_' + index.toString()}`}
                        label={
                          <EllipsisWithTooltip placement="bottom">
                            {file.fileName}
                          </EllipsisWithTooltip>
                        }

                        onChange={(e: any) => handleCheckbox(e, props.fileNo.toString() + '_' + index.toString(), props.fileNo, numPages)}
                        checked={chkids.filter((x) => x.id.toString() === `custom_${props.fileNo.toString() + '_' + index.toString()}`).length > 0 ? true : false}

                      />
                    )}

                    {(!bulkSelect || (bulkSelect && props.pageType?.toString().toLowerCase() === "payment" && props?.fileEtype?.toLowerCase() === "invoices")) && (
                      <EllipsisWithTooltip placement="bottom">{file.fileName}</EllipsisWithTooltip>
                    )}
                  </div>
                  <div className="page-count">Page {index + 1} of {numPages}</div>
                  {!bulkSelect && (props.pageType?.toString().toLowerCase() !== "payment" || (props.pageType?.toString().toLowerCase() === "payment" && props?.fileEtype?.toLowerCase() !== "invoices")) && (
                    <div className="action d-flex">
                      {props.pageType?.toString().toLowerCase() !== "invoice" && (
                        <Dropdown className="more-action" alignRight>
                          {/* <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-Split">Move to New Invoice</Dropdown.Toggle> */}
                          <Dropdown.Menu>
                            {(index + 1) === 1 && (index + 1) !== numPages && (
                              <>
                                {/* {(numPages > 1 && ( */}
                                <Dropdown.Item eventKey={""}
                                  onClick={(event: any) => handleSplitInvoice(event, (index + 1), "Single", props.fileNo, `dropdown_${props.fileNo + index + 1}`)}
                                >First Page Only</Dropdown.Item>
                                {/* )} */}

                                <Dropdown.Item eventKey={""}
                                  onClick={(event: any) => handleSplitInvoice(event, (index + 1), "All", props.fileNo, `dropdown_${props.fileNo + index + 1}`)}
                                >Entire document</Dropdown.Item>
                              </>
                            )}

                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                      {/* {props.isEditPermission && ( NW-10552 - Rotate Pdf visibulity issue */}
                      <Dropdown className={dropdownIDs.filter((e) => e.id.toString() === `dropdown_${props.fileNo + index + 1}`).length > 0 ? "more-action show" : "more-action"}
                        alignRight>
                        <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id={`toggle_${props.fileNo + index + 1}`}>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>

                          {!bulkSelect && (
                            <Dropdown.Item eventKey={""} onClick={handleBulkSelect}>Bulk Select</Dropdown.Item>
                          )}
                          {!props.NoDelPermission && (
                            <>
                              {props.isEditPermission && (
                                <Dropdown.Item onClick={(event: any) => handlePageRemove(event, props.fileNo, index + 1)}>Remove Page</Dropdown.Item>
                              )}
                            </>
                          )}
                          <Dropdown.Item eventKey={""} onClick={(event: any) => handleRotate(event, props.fileNo, index + 1)}>Rotate</Dropdown.Item>
                        </Dropdown.Menu>
                        {dropdownIDs.filter((e) => e.id.toString() === `dropdown_${props.fileNo + index + 1}`).length > 0 && (
                          <>
                            <div className="backDrop-dropdown"></div>
                            <div className="attach-invoice-modal" ref={attachInvoiceModal}>
                              <div className="header d-flex align-items-center" >
                                <button type="button" className="btn wht-bg cross" onClick={(event: any) => handledropDown(event, `toggle_${props.fileNo + index + 1}`, `dropdown_${props.fileNo + index + 1}`)} >
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0776 5.24414L6.32178 9.99997L11.0776 14.7558L12.2559 13.5775L8.67844 9.99997L12.2559 6.42247L11.0776 5.24414Z" fill="#2CAF92" />
                                  </svg>
                                </button>
                                <div className="title mr-auto">Select Invoice to Attach to</div>
                                <button type="button" className="btn wht-bg cross" onClick={(event: any) => hideAttachInvoiceList(event, `dropdown_${props.fileNo + index + 1}`)}>
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z" fill="#84888C" />
                                  </svg>
                                </button>
                              </div>
                              <div className="search"><Form.Control type="text" onChange={handleChange} value={inputValue} placeholder="Filter Invoices" /></div>
                              <div className="list">
                                <ul>
                                  {incompleteFiles.map((items, index) => (
                                    <li onClick={(event: any) => saveAttachInvoice(event, items)}>
                                      <div className="date">{items.createDate}</div>
                                      <div className="name">Uploaded by {items.userName}</div>
                                      <div className="name">{items.vendor}</div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </>)}
                      </Dropdown>
                      {/* )} NW-10552 - Rotate Pdf visibulity issue */}
                    </div>
                  )}
                </div>

              )}
              <div
                //id={`page_${numPages}_${index + 1}`}//
                id={`page_${props.fileNo + index + 1}`}
                className={props.defaultView !== "doc-view" ? "grid-view" : "doc-panel"}

              //onClick={handleId}
              >
                {props.defaultView === "doc-view" && (
                  <ScrollContainer className="scroll-container">
                    <Page
                      key={`page_${props.fileNo + index + 1}`}
                      pageNumber={index + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      scale={props.defaultView === "grid-view" ? 1.0 : props.selectedZoom}
                      onClick={() => props.defaultView === "grid-view" ? props.updateState(`page_${index + 1}`) : ""}
                    />
                  </ScrollContainer>
                )}
                {props.defaultView !== "doc-view" && (
                  <Page
                    key={`page_${props.fileNo + index + 1}`}
                    pageNumber={index + 1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    onClick={() => props.defaultView === "grid-view" ? props.updateState(`page_${index + 1}`) : ""}
                  />
                )}

                {props.defaultView !== "doc-view" && (
                  <div className="custom-page-action d-flex justify-content-between align-items-center">
                    <div className="page-count">
                      {bulkSelect && (props.pageType?.toString().toLowerCase() !== "payment" || (props.pageType?.toString().toLowerCase() === "payment" && props?.fileEtype?.toLowerCase() !== "invoices")) && (
                        <Form.Check
                          custom
                          type="checkbox"
                          key={`custom_${props.fileNo.toString() + '_' + index.toString()}`}
                          id={`custom_${props.fileNo.toString() + '_' + index.toString()}`}
                          label={`Page ${index + 1}`}
                          onChange={(e: any) => handleCheckbox(e, props.fileNo.toString() + '_' + index.toString(), props.fileNo, numPages)}
                          checked={chkids.filter((x) => x.id.toString() === `custom_${props.fileNo.toString() + '_' + index.toString()}`).length > 0 ? true : false}
                        />
                      )}
                      {(!bulkSelect || (bulkSelect && props.pageType?.toString().toLowerCase() === "payment" && props?.fileEtype?.toLowerCase() === "invoices")) && (
                        `Page ${index + 1}`
                      )}
                    </div>

                    {!bulkSelect && (props.pageType?.toString().toLowerCase() !== "payment" || (props.pageType?.toString().toLowerCase() === "payment" && props?.fileEtype?.toLowerCase() !== "invoices")) && (
                      <div className="action">
                        {/* {props.isEditPermission && ( */}
                        <Dropdown
                          className={dropdownIDs.filter((e) => e.id.toString() === `dropdown_${props.fileNo + index + 1}`).length > 0 ? "more-action show" : "more-action"}
                          alignRight>
                          <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id={`toggle_${props.fileNo + index + 1}`} >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {!bulkSelect && (
                              <Dropdown.Item eventKey={""} onClick={handleBulkSelect}>Bulk Select</Dropdown.Item>
                            )}
                            {!props.NoDelPermission && (
                              <>
                                {props.isEditPermission && (
                                  <Dropdown.Item eventKey={""} onClick={(event: any) => handlePageRemove(event, props.fileNo, index + 1)}>Remove Page</Dropdown.Item>
                                )}
                              </>
                            )}
                            <Dropdown.Item eventKey={""} onClick={(event: any) => handleRotate(event, props.fileNo, index + 1)}>Rotate</Dropdown.Item>
                          </Dropdown.Menu>

                        </Dropdown>
                        {/* )} */}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          ))}
        </Document>
        {error && (
          <div className="custom-error-msg">Cannot be previewed - {file.fileName}</div>
        )}
      </div>
    );
  });

export default PDFViewerNonInvoice;


