import * as React from "react";
// import { Form } from "react-bootstrap";
// import { ReportGroup } from "../../Common/Services/ReportGroup";
// import {
//   IReportGroupResponseDto,
//   IReportGroupSubGroupWithHotelCountDto,
// } from "../../Common/Contracts/IReportParameter";
import { SingleSelectReportGroupSearch } from "../../Common/Components/SingleSelectReportGroupSearch";

export class ReportGroupSelector extends React.Component<any, any> {
  // private SingleselectChild: any;
  private singleSerch: any;
  constructor(props: any) {
    super(props);
    this.singleSerch = React.createRef();
  }

  onDDLChange = (selectedItem: any) => {
    this.props.handleReportGroupChange(selectedItem);
  };

  resetValue = () => {
    this.singleSerch.current.resetSerchBox();
  };
  render() {
    return (
      <SingleSelectReportGroupSearch
        defaultValue={this.props.defaultValue}
        notIncludeEnterpriseAccounts={this.props?.notIncludeEnterpriseAccounts}
        showOnlyHotelAccounts={this.props?.showOnlyHotelAccounts}
        ref={this.singleSerch}
        onDDLChange={this.onDDLChange}
        isAll={this.props?.isAll}
        showBlueBg={this.props?.showBlueBg}
        moduleName={this.props?.moduleName}
        reportid={this.props.reportid}
        payrollSubscriptionRequired={this.props?.payrollSubscriptionRequired}
      ></SingleSelectReportGroupSearch>
    );
  }
}
