import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import {
  ILaborDayApproveRequestDto,
  IApproveSaveRequestDto,
} from "../../../../../Common/Contracts/ILaborDayApprove";
import BreakExceptionChildDetails from "./BreakExceptionChildDetails";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { resolve, reject } from "q";
import chevronRight from "../../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { Utils } from "../../../../../Common/Utilis";


let localStorageUser: any;
export default class BreakExceptionDetails extends React.Component<any, any> {
  private laborDayAppChild: any;
  constructor(props: any) {
    super(props);

    this.laborDayAppChild = React.createRef();

    this.state = {
      loaderTrue: false,
      showEhidLabel: true,
      hidValue: props.parentState.hidValue,
      hotelLetterCode: props.parentState.hotelName,
      tableExpended: [],
      laborDayApprovalHotelData: [],
      isLetterCode: props.parentState.isLetterCode,
      isExpand: false,
      expendedRow: {},
    };
  }

  dayToApproveHotel(request: ILaborDayApproveRequestDto) {
    this.setState({ loaderTrue: true });
    LaborDayApprove.GetLaborBreakAttestationHidDetails(request)
      .then(async (result: any | null) => {
        let laborDayApproveData: any = [];
        // 
        if (result !== null && result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            let pushObject: any = result[i];

            pushObject.rowNo = i + 1;
            let status: string = "Needs Review";
            pushObject.status = status;
            let tempDate = pushObject.dayToApprove.split(",");
            pushObject.dayToApprove = `${tempDate[0]} ${Number(tempDate[1])}, ${
              tempDate[2]
            }`;
            pushObject.action = "";
            pushObject.approveButtonSpinner = false;
            pushObject.approveModal = false;
            laborDayApproveData.push(pushObject);
          }
          this.setState({ laborDayApprovalHotelData: laborDayApproveData });
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "laborDayOverviewContainer",
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  }

  allHotelBreadCrumb = (e: any) => {
    e.stopPropagation();
    this.props.laborDayApprovalBreadCrumbs("allHotels");
  };

  approveButtonStatusToggle = (value: boolean, row: any) => {
    let newTableData = [...this.state.laborDayApprovalHotelData];
    newTableData = newTableData.map((d) => {
      if (d.rowNo === row.rowNo) {
        return { ...d, approveButtonSpinner: value };
      }

      return d;
    });
    this.setState((curr: any) => ({
      ...curr,
      laborDayApprovalHotelData: newTableData,
    }));
  };

  // laborDayApprove = (e: any, row: any, rowIndex: any) => {
  //   ;
  //   e.stopPropagation();
  //    ;
  //   this.approveButtonStatusToggle(true, row);

  //   localStorageUser = JSON.parse(localStorage.getItem("storage")!);
  //   let requestArray: any = [];
  //   let request = {} as IApproveSaveRequestDto;
  //   request.hotelID = Number(row.hotelID);
  //   request.user_uniqueno = localStorageUser.user_UniqueID;
  //   request.userName = localStorageUser.userName;
  //   request.date = row.date;
  //   request.explanation = "";
  //   request.tenantID = localStorageUser.tenantID;
  //   request.entryType = "row";
  //   request.deptId = "-1";
  //   request.UnApproveDeptList="-1";
  //   requestArray.push(request);

  //   LaborDayApprove.ApproveLaborBudgetSetting(requestArray)
  //     .then(async (result: any | null) => {
  //       if (result != null) {
  //         let data = result.result as any;
  //         if (data.saveStatus === "Success") {
  //           toast.success(
  //             `${row.weekDayName} - ${row.date} is approved successfully`,
  //             {
  //               // // position: toast.POSITION.BOTTOM_RIGHT,
  //               // // containerId: "laborDayOverviewContainer",
  //             }
  //           );
  //           let newTableData = [...this.state.laborDayApprovalHotelData];

  //           newTableData.splice(rowIndex, 1);
  //           newTableData.forEach((ele,index)=>{
  //             if(index >= rowIndex){
  //                 ele.rowNo = ele.rowNo -1
  //             }
  //           })
  //           let showEhidLabel = false;
  //           if (newTableData.length > 0) {
  //             showEhidLabel = true;
  //           }

  //           this.setState({
  //             laborDayApprovalHotelData: newTableData,
  //             showEhidLabel,
  //           });
  //           this.props.laborDayApproval("Approved");
  //         } else {
  //           Utils.toastError(data.message, {
  //             // // position: toast.POSITION.BOTTOM_RIGHT,
  //             // // containerId: "laborDayOverviewContainer",
  //           });
  //         }
  //       }
  //       this.setState({
  //         isExpand: false,
  //       });
  //       this.approveButtonStatusToggle(false, row);
  //       resolve();
  //     })
  //     .catch((error) => {
  //       this.approveButtonStatusToggle(false, row);
  //       this.setState({
  //         isExpand: false,
  //       });
  //       Utils.toastError(`Server Error: ${error}`, {
  //         // // position: toast.POSITION.BOTTOM_RIGHT,
  //         // // containerId: "laborDayOverviewContainer",
  //       });
  //       reject();
  //     });
  // };
  
  // navigatetoPerformancePage= (e: any, row: any, rowIndex: any) => {
  //   e.stopPropagation();
  //   let date = window.btoa(row.date);
  //   let hotelID = window.btoa(row.hotelID);
  //   let url =window.location.origin+`/labor-management/Performance?date=${date}&hotelId=${hotelID}`
   
  //   url = encodeURI(url);


  //     window.open(url,
  //     "_blank")

    
  // };
  // laborDayApproveForReport= (e: any, row: any, rowIndex: any) => {
  //   ;
  //   e.stopPropagation();

  //   let url =window.location.origin+`/labor-management/viewer?date=${ row.date}&hotelId=${row.hotelID}`
   
  //   url = encodeURI(url);


  //     window.open(url,
  //     "_blank")

    
  // };

  // laborDayReview = (e: any, row: any, rowIndex: any) => {
  //   e.stopPropagation();

  //   if (!this.state.tableExpended.includes(row.rowNo)) {
  //     row.action = "Expended";
  //     this.setState({ isExpand: true });
  //     this.setState(() => ({
  //       tableExpended: [...this.state.tableExpended, row.rowNo],
  //     }));
  //   } else {
  //     row.action = "";
  //     this.setState({ isExpand: false });
  //     this.setState(() => ({
  //       tableExpended: this.state.tableExpended.filter(
  //         (x: any) => x !== row.rowNo
  //       ),
  //     }));
  //   }
  // };

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    // ;
    // if(row.underBudgetDept===0 && row.overBudgetDept===0 && row.autoPunchOut===0){
    //   this.setState({ isExpand: false });
    //   return;
    // }
    if (isExpand) {
      ;
      row.action = "Expended";
      this.setState({ isExpand: true });
      this.setState(() => ({
        tableExpended: [...this.state.tableExpended, row.rowNo],
      }));
      this.setState({ expendedRow: row });
    } else {
      ;
      row.action = "";
      this.setState({ isExpand: false });
      this.setState(() => ({
        tableExpended: this.state.tableExpended.filter(
          (x: any) => x !== row.rowNo
        ),
      }));
    }
  };

  childApprove = (updatedRow: any, renderTable: string) => {
    // 
    let data =[...this.state.laborDayApprovalHotelData]
    // data[updatedRow.rowNo - 1 ] ={...data[updatedRow.rowNo - 1]} 
    // if (renderTable === "autoPunchOut") {
    //   data[updatedRow.rowNo - 1].autoPunchOut = updatedRow.autoPunchOut;
    // } else if (renderTable === "overBudget") {
    //   data[updatedRow.rowNo - 1].overBudgetDept = updatedRow.overBudgetDept;
    // } else if (renderTable === "underBudget") {
    //   data[updatedRow.rowNo - 1].underBudgetDept = updatedRow.underBudgetDept;
    // }
    data = data.map((value,index)=>{
      if(index === (updatedRow.rowNo - 1)){
        return {
          ...value,
          dayCount:value.dayCount
        }
      }
      return value;
    })
    console.log(data);
   
  
    if (this.state.isExpand) {
      this.handleOnExpand(this.state.expendedRow, false, "", "");
    }
  
    this.setState((curr: any) => ({
      ...curr,
      laborDayApprovalHotelData: data,
    }));
    this.props.laborDayApproval("Approved","breakExceptionApproved");
    // this.setState({
    //   laborDayApprovalHotelData: data,
    // },()=>{
    // });
  };

  removeRow = (rowNo: any) => {
    let data = [...this.state.laborDayApprovalHotelData];

    let rowIndex: number = -1;

    for (let i = 0; i < data.length; i++) {
      if (data[i].rowNo === rowNo) {
        rowIndex = i;
        break;
      }
    }
    if (rowIndex >= 0) {
      data.splice(rowIndex, 1);
    }

    data.forEach((ele,index)=>{
      if(index >= rowIndex){
          ele.rowNo = ele.rowNo -1
      }
    })
    if (this.state.isExpand) {
      this.handleOnExpand(this.state.expendedRow, false, "", "");
    }
    this.setState({
      laborDayApprovalHotelData: data,
      // isExpand: false,
    },()=>{

      this.props.laborDayApproval("Approved","breakExceptionApproved");
    });
  };

  refreshChild() {
    if (this.laborDayAppChild.current !== null) {
      this.laborDayAppChild.current.refreshDayAppChild();
    }
  }

  handlePositionDetail(row: any) {
    this.props.positionDetail(row);
  }

  onOutsideClick = (e: any) => {
    if (document?.getElementById("timeSheetSlideOut")) {
      return;
  }
    let isChildClick = false;
    e.path?.map((x: any) => {
      if (
        x.className ===
        "body-sec performance-position justify-content-end container-fluid"
      ) {
        isChildClick = true;      
      }    
      return false;
    });
    if (isChildClick) {
      return;
    }
    if (this.state.isExpand) {
      this.handleOnExpand(this.state.expendedRow, false, "", "");
    }
  };

  //  runReportClicked = (event: any) => {
  //   window.open(window.location.origin+"/labor-management/viewer",
  //     "_blank"
  //   );
  // };

  render() {
    const rowClasses = (row: any, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const expandRow = {
      renderer: (row: any) => (
        <div className="rowExpended" id="laborDayDetails">
          <BreakExceptionChildDetails
            ref={this.laborDayAppChild}
            row={row}
            childApprove={this.childApprove}
            removeRow={this.removeRow}
            positionDetail={this.handlePositionDetail.bind(this)}
            laborOverviewChecked={this.props.laborOverviewChecked}                    
          />
        </div>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const columns = [
      {
        dataField: "status",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.status === "Ready to Approve") {
            return (
              <div className="laborDayDetails">
                {/* <div className="laborDayStatus" data-tip={row.status}>
                  <span className="DayStatusApprove">
                    <ReactTooltip
                      effect="solid"
                      event="mouseover mouseenter"
                      eventOff="mouseleave mouseout scroll mousewheel blur"
                      place="bottom"
                    />
                  </span>
                </div> */}
                <div className="laborDetailsdayDate">
                  <span className="day">{row.weekDayName}</span>
                  <span className="date">{row.dayToApprove}</span>
                </div>
              </div>
            );
          } else {
            return (
              <div className="laborDayDetails">
                {/* <div className="laborDayStatus" data-tip={row.status}>
                  <span className="DayStatusPending">
                    <ReactTooltip
                      effect="solid"
                      event="mouseover mouseenter"
                      eventOff="mouseleave mouseout scroll mousewheel blur"
                      place="bottom"
                    />
                  </span>
                </div> */}
                <div className="laborDetailsdayDate">
                  <span className="day">{row.weekDayName}</span>
                  <span className="date">{row.dayToApprove}</span>
                </div>
              </div>
            );
          }
        },
      },
      {
        dataField: "BreakException",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          
        return (  <div className="autoPunchOutsData">
            <span className="autoPunchOutsCount">{row.dayCount}</span>
            <span className="autoPunchOutsLabel">Break Exceptions</span>
          </div>)
      }
      }
      // {
      //   dataField: "action",
      //   text: "",
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     if (row.status === "Ready to Approve" || row.underBudgetDept===0 && row.overBudgetDept===0 && row.autoPunchOut===0) {
      //       return (
      //         <Button
      //           className={
      //             row.approveButtonSpinner
      //               ? "btn-outline-primary laborDayReadyToApprove p-none"
      //               : "btn-outline-primary laborDayReadyToApprove"
      //           }
      //           onClick={(e: any) => this.laborDayApprove(e, row, rowIndex)}
      //         >
      //           {row.approveButtonSpinner && (
      //             <Spinner
      //               className="mr-2"
      //               as="span"
      //               animation="border"
      //               size="sm"
      //               role="status"
      //               aria-hidden="true"
      //             />
      //           )}
      //           Approve
      //         </Button>
      //       );
      //     } else {
      //       return (
      //         <Button
      //           className="btn-outline-primary laborDayReadyToReview"
      //           onClick={(e: any) => this.laborDayReview(e, row, rowIndex)}
      //         >
      //           {row.action === "Expended" ? "Close" : "Review"}
      //         </Button>
      //       );
      //     }
      //   },
      // }, 
      // {
      //   dataField: "",
      //   text: "",
      //   formatter: (cell: any, row: any, rowIndex: any) => (
      //     <Dropdown
      //       onClick={(e) => {
      //         e.stopPropagation();
      //       }}
      //       className="more-action"
      //       alignRight
      //     >
      //       <Dropdown.Toggle
      //         className="btn-outline-primary btn btn-primary btn-ghost more"
      //         id="dropdown-more"
      //       >
      //         <svg
      //           width="20"
      //           height="20"
      //           viewBox="0 0 20 20"
      //           fill="none"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
      //           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
      //           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      //         </svg>
      //       </Dropdown.Toggle>
      //       <Dropdown.Menu>
      //         <Dropdown.Item
      //           key={"ViewAllPunch"}
      //           eventKey={"ViewAllPunch"}
      //          // onClick={(e: any) => this.renameReport(e, row)}
      //          onClick={(e: any) => this.laborDayApproveForReport(e, row, rowIndex)}
      //          //onClick={this.runReportClicked}
      //         >
      //           View All Punches
      //         </Dropdown.Item>
      //         <Dropdown.Item
      //           key={"ViewPerformance"}
      //           eventKey={"ViewPerformance"}
      //          // onClick={(e: any) => this.renameReport(e, row)}
      //          onClick={(e: any) => this.navigatetoPerformancePage(e, row, rowIndex)}
      //          //onClick={this.runReportClicked}
      //         >
      //           View Performance
      //         </Dropdown.Item>
      //       </Dropdown.Menu>
            
      //     </Dropdown>
      //   )

      //     }
  
    ];
    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"daysToApproveDetailList"} />
        ) : (
          <div className="laborDayApproval">
            <div className="laborDayApprovalActionHeader">
              {this.state.hidValue === "All" && (
                <span className="laborBreadCrumbs">
                  <span onClick={this.allHotelBreadCrumb}>
                    &laquo; All Properties
                  </span>
                </span>
              )}
              {this.state.showEhidLabel && (
                <span className="laborHeader">
                  {this.state.hotelLetterCode === "All"
                    ? this.state.isLetterCode
                    : this.state.hotelLetterCode}{" "}
                  Break Attestation Exceptions to Resolve
                </span>
              )}
            </div>
            <OutsideClickHandler
              onOutsideClick={(e) => {
                this.onOutsideClick(e);
              }}
            >
              <div className="laborDayApprovalTable cuslaborDayApprovalTable breakAttestationExceptions">
                <BootstrapTable
                  keyField="rowNo"
                  data={this.state.laborDayApprovalHotelData}
                  columns={columns}
                  expandRow={expandRow}
                  rowClasses={rowClasses}
                  hover
                />
              </div>
            </OutsideClickHandler>
          </div>
        )}
      </>
    );
  }
}
