/* eslint-disable array-callback-return */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  // Link,
  Redirect,
} from "react-router-dom";
import { Container, Dropdown, Form, Row, Col, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import chevronRight from "../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import profile from "../../../../Common/Assets/Images/profile1.png";
import notesIcon from "../../../../Common/Assets/Images/icons/notes-icon.png";

import DatePicker from "react-datepicker";
import OutsideClickHandler from "react-outside-click-handler";
import { resolve, reject } from "q";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { confirmAlert } from "react-confirm-alert";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ForecastLock from "../../../../Common/Assets/Images/icons/forecast-lock.svg";
import moment from "moment";
import { LaborSchedule } from "../../Common/Services/LaborSchedule";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { Utils } from "../../Common/Utilis";

const { SearchBar } = Search;
export class TimeOffHistory extends React.Component<any, any> {
  

  constructor(props: any) {
    debugger;
    super(props);

    
    this.state = {
        timeOffHistoryData:[],
        tableLoader: false,
    };
  
  }

  componentDidMount() {   

    // let requestArray: any = [];
    // let request = {} as any;    
    // request.startDate = "09/11/2023";
    // request.endDate="09/11/2023";
    // request.hrs="8.0";  
    // request.ptoType="pto"; 
    // request.status="used"; 
    //  request.reason="test";  
    //  request.remainingPTO="23:23";  
    // requestArray.push(request);
    // this.setState({timeOffHistoryData:requestArray});
    this.LaborTimeoffPTOHistoryDetails();
  }

  LaborTimeoffPTOHistoryDetails(){   
    let request = {} as any;
    request.hid = 0;    
    
    request.Useruniqueno = JSON.parse(localStorage.storage).user_UniqueID;  
    request.type = "Declined";
    this.setState({ tableLoader: true });
    LaborSchedule.LaborTimeoffPTOHistoryDetails(request)
      .then(async (result: any) => {
        if (result.message === "Success") {
          let requestArray: any = [];
         
          for(let i=0; i < result.result.length;i++ ){            
                       
           let request = {} as any;    
           request.hid =  result.result[i].hid;
           request.date =  result.result[i].date;
           request.ptoType= result.result[i].ptoName; 
           request.hrs =  result.result[i].hrs;
           request.status= result.result[i].status;   
           request.balance= result.result[i].balance; 
           requestArray.push(request);
        }
        this.setState({ timeOffHistoryData:requestArray, tableLoader: false });
         


        }else{
          this.setState({ timeOffHistoryData:[], tableLoader: false });
        }
      }).catch((err) => {
                Utils.toastError(`Server Error, ${err}`, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });

               this.setState({ tableLoader: false });
                //reject();
              });



};

  render() {

    const TimeOffHistorycolumns = [
      {
        dataField: "",
        text: "EHID",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
         
            <span className="">
             <span>{row.entryType}  </span> 
             <span className="autoPunch-status badge">{row.hid}</span>
            </span>
        ),
      },
        {
            dataField: "",
            text: "Date",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
             
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.date}</span>
                </span>
            ),
          },
         
          {
            dataField: "",
            text: "Hours",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
             
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.hrs}</span>
                </span>
            ),
          },
          {
            dataField: "",
            text: "Type",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.ptoType}</span>
                </span>
            ),
          },
         
          {
            dataField: "",
            text: "Status",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.status}</span>
                </span>
            ),
          },
          {
            dataField: "",
            text: "Remaining PTO (HH:MM)",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
             
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.balance}</span>
                </span>
            ),
          },
      
  
  
      ];
  return(<>

        {
          this.state.tableLoader ? <ReactPageLoader useas={"myPTOLoader"} /> :
        
          <div className={this.state.timeOffHistoryData.length > 5 ? "add-scrollabr" : ""}>
              <BootstrapTable
              keyField="rowNo"
              data={this.state.timeOffHistoryData}
              columns={TimeOffHistorycolumns}
              hover
            />
              {this.state.timeOffHistoryData.length === 0 && <div className="data-alert-caption">No record found</div>}
          </div>
        }
   </>
    );
  }
}

