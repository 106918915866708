/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { SingleDropdownListWithoutSearch } from "../../../Common/Components/SingleDropdownListWithoutSearch";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
import { HidSelector } from "../../../Common/Components/HidSelector";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { Register as registersevice } from "../../../Common/Services/Register";
import "bootstrap-daterangepicker/daterangepicker.css";
import { ToastContainer, toast } from "react-toastify";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import BankSyncNoDataFrame from "../../../Common/Assets/Images/BankSyncNoDataFrame2302.png";
import { InfoTooltip } from "../../../Common/Components/InfoTooltip";
import TableSearch from "../../../Common/Components/TableSearch";
import $ from "jquery";
import Spinner from "react-bootstrap/Spinner";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import _ from "lodash";
import BankSyncChildTable from "./BankSyncChildTable";
import { bankSyncServices } from "../../../Common/Services/bankSyncServices";
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../../../Common/Utilis";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { TransactionSlideout } from "../../Register/TransactionSlideout";
import { TransferSlideout } from "../../Register/TransferSlideout";
import { InvoiceEntrySlideout } from "../../Register/InvoiceEntrySlideout";
import { AdjustmentModal } from "./AdjustmentModal";
import { CreditCardSlideout } from "../../Register/CreditCardSlideout";
import { BankAccountSlideout } from "../BankAccountSlideout";
import { CreditCardAccountSlideout } from "../CreditCardAccountSlideout";
import { BankSyncWizard } from "../../Accounting/BankSync/BankSyncWizard";
import { RoleSubPermissionSetting } from "../../../Common/Services/RoleSubPermissionSetting";
import { accountService } from "../../../Common/Services/account";
import { ManualStatementUploadModal } from "./ManualStatementUploadModal";

import { CSVLink } from "react-csv";
let exportDataSet: any = [];
let callingPage = "BankSync";
interface IBankSyncTableData {
  id?: number;
  syncID?: number;
  date?: string;
  date1?: string;
  description?: string;
  checkType?: string;
  plaidAmount?: number;
  singleTx?: boolean;
  isExpand?: boolean;
  tranType?: string;
  transCode?: string;
  trUniqueNo?: string;
  MatchedEntry?: string;
  transactions: Array<IBankSyncChild>;
  backHid?: number;
  customField?: string;
  checkNo?: string;
  series?: string;
  updateTable?: string;
  transType?: string;
  Hotelid?: number;
  groupUniqueNo?: string;
  adjustment?: number;
  notes?: string;
  checkNumbers?: string;
  badge?: string;
}

interface IBankSyncChild {
  data: IBankSyncTransactionData;
  name?: string;
}
export interface IBankSyncTransactionData {
  id?: number;
  rowId?: number;
  syncID?: number;
  trUniqueNo?: number;
  transCode?: string;
  amount?: string;
  date?: any;
  vendorName?: string;
  updateTable?: string;
  transType?: string;
  checkType: string;
  plaidAmount?: number | string;
  backHid?: number;
  checkNo?: string;
  series?: string;
  Hotelid?: number;
  groupUniqueNo?: string;
  adjustment?: number | string;
  badge?: string;
}

export class BankSync extends React.Component<any, any> {
  private tableSearchBankSync: any;
  private childHID: any;
  private accountName: any;
  private BankSyncChildTableRef: any;
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.accountName = React.createRef();
    this.tableSearchBankSync = React.createRef();
    this.BankSyncChildTableRef = React.createRef();
    this.state = {
      selectedaccount: "",
      selectedOprID: "",
      backPage: "register",
      isShowSlideout: false,
      isCCShowSlideout: false,
      isNew: true,
      isBgBlue: false,
      selectedRow: {},
      hidValue: "Select",
      hotelName: "Select",
      customDate: false,
      repeatingModal: false,
      hotelList: [],
      showChangeLogModal: false,
      moreAction: [
        { name: "Repeating", eventKey: "repeating" },
        { name: "Change Log", eventKey: "changeLog" },
        { name: "Export", eventKey: "Export" },
      ],
      addTransactionAction: [
        { name: "Deposit", eventKey: "deposit", toShow: 0 },
        { name: "Withdrawal", eventKey: "withdrawal", toShow: 0 },
        { name: "Add Debit", eventKey: "AddDebit", toShow: 1 }, //withdrawal
        { name: "Add Credit", eventKey: "AddCredit", toShow: 1 }, //deposit
        { name: "Add Payment", eventKey: "AddPayment", toShow: 1 },
        { name: "Transfer", eventKey: "transfer", toShow: 0 },
      ],
      isCCAcc: false,
      istableloader: false,
      repeatlist: [],
      regularlist: [],
      splitlist: [],
      selectedRows: [],
      tableData: [],
      tableData2: [],
      accountBal: [],
      accountNamelist: [],
      viewType: "regular",
      accountNames: [],
      accountName: "Select Account Name",
      accountNameValue: -1,
      dayFilter: [
        { id: 1, name: "Last 30 Days" },
        { id: 2, name: "Last 60 Days" },
        { id: 3, name: "Last 90 Days" },
      ],
      transactionFilter: [
        { id: 1, name: "For Review" },
        { id: 2, name: "Completed" },
        { id: 3, name: "Excluded" },
      ],
      transactionFilterValue: 1,
      dayText: "Last 30 Days",
      transactionFilterText: "For Review",
      dayValue: 1,
      totalAcctBal: 0,
      totalsharedHID: [],
      totalsharedHIDTemp: 0,
      totalsharedHIDCount: 0,
      sharedcode: "",
      dateRange: "",
      start: "",
      end: "",
      minDate: "",
      maxDate: "",
      isSelect: true,
      ehidSected: 0,
      isloader: true,
      isSlideOut: false,
      trUniqueNo: 0,
      slideOutRequest: {},
      sildeoutHid: -1,
      sildeoutCode: "",
      pageType: "",
      oprId: -1,
      vid: 0,
      isTransferSlideOut: false,
      isExpand: false,
      densityView: [
        { id: 1, densityType: "densityView_1", name: "WIDE" },
        { id: 2, densityType: "densityView_3", name: "NARROW" },
      ],
      defaultDensityView: "densityView_3",
      expandedList: [],
      expList: [],
      valuesArray: false,
      transferRequest: { acctType: "", glArray: [] },
      creditCardStatementModal: false,
      CCPaymentShow: false,
      CardUniqueNo: -1,
      permissionlist: [],
      exportModal: false,
      isExportRun: false,
      sortingOrder: "asc",
      isCCSlideout: false,
      ccSlideType: "",
      isBulkImportSlideout: false,
      bulkImportRequest: {
        pageName: "",
        hotelName: "",
        hidValue: "",
        oprId: "",
        acctType: "",
      },
      accountStatus: "",
      isReconcilePerm: false,
      isGLBalncePerm: false,
      isfromSearch: false,
      loadingFromOther: false,
      sharedHIDList: [],
      lastUpdatedStatus: "",
      accountBalance: "",
      sharedHID: "",
      bulkSelectArray: {},
      hasMultipleTxn: [],
      selectType: "0",
      tenantID: 0,
      logInUserID: 0,
      defaultFilters: ["densityView_3", "For Review", "Last 30 Days", "0", 1],
      selectedAccountType: "",
      invoiceslideoutData: {},
      invoiceSlideout: false,
      confirmSpinner: false,
      showAdjustmentModal: false,
      adjustmentData: {
        accper: "",
        hidValue: "",
        acctType: "",
        AccountType: "",
        AdjEntryType: "",
        OprID: "",
        adjustmentAmount: 0,
        syncID: 0,
      },
      confirmTxData: [],
      AddTransationSyncID: 0,
      entryType: "",
      showBalance: false,
      hasMultipleAdjustments: false,
      showReconnectButton: false,
      errorCodeMsg: "",
      showBanner: false,
      hasConnectPermission: false,
      reconcileAfterSave: false,
      addTranReq: [],
      sortingField: "date",
      selectedPlaidAmount: "",
      isBankWizardShow: false,
      isConnectBankPerm: false,
      hasBankPerm: false,
      hasNoSubscription: false,
      selectedEhid: {},
      selectedAccount: {},
      isAfterAddConnection: false,
      rowEntryType: "",
      isDateUpdated: false,
      isAlerted: false,
      isAdjustment: false,
      batchTotal: 0,
      notes: "",
      trslideoutData: { hotelID: -1, lettercode: '', oprID: -1, acctType: "", date: "", amount: "", checkType: "" },
      bulkSelectArrayList: [],
      showManualUploadModal: false,
      hasManualStatementUploadPermission: false,
      manualUploadColumns: { CheckNo: false, CustomField: false, Description: false },
      isSyncedAcc: false,
    }
    this.handleDateCallback = this.handleDateCallback.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
  }

  componentDidMount() {
    this.getConnectbankPermission();
    this.advancedAccounting();
    this.getDefaultView();
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = storage === null ? 0 : (storage.tenantID);
    let logInUserID: any = storage === null ? 0 : (storage.user_UniqueID);
    let newDate = new Date();
    let strMonth = Number(newDate.getMonth()) + 1;
    let strYear = newDate.getFullYear();
    let accountPeriod = (Number(strMonth) > 9 ? strMonth : "0" + strMonth) + "/01/" + strYear.toString().substring(2, 4);
    this.setState({ tenantID: tenantID, logInUserID, accountPeriod });
    this.loadFromAccount();
  }

  getConnectbankPermission = () => {
    let requestObject = {} as any;
    requestObject.permissionID = 1010;
    requestObject.Module = "Accounting";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          this.setState({ isConnectBankPerm: result.some((o) => o.permissionName.toLowerCase() === "connect bank") });
        }
        resolve();
      })
      .catch((err: any) => {
        reject();
      });
  };
  advancedAccounting = () => {
    accountService.AdvancedAccounting()
      .then(async (result: any | null) => {
        if (result !== null && result.length > 0) {
          let hasBankSync = result.some((o) => o.name.toLowerCase() === "banksync");
          this.setState({
            hasBankPerm: hasBankSync,
            hasNoSubscription: !hasBankSync
          });
        } else {
          this.setState({ hasNoSubscription: true });
        }
      })
      .catch((err: any) => {
        reject();
      });
  }
  getTableData = () => {
    const {
      transactionFilterText,
      hidValue,
      accountNameValue,
      selectType, start, end
    } = this.state;
    let request: any = {};
    request.hotelID = hidValue;
    request.oprId = accountNameValue;
    request.tranType = transactionFilterText;
    request.filterDate = selectType;
    request.StartDate = start;
    request.EndDate = end;
    if (transactionFilterText === "For Review") {
      request.tranType = "Pending";
    }
    this.setState({ istableloader: true });
    bankSyncServices
      .getBankSyncTableData(request)
      .then((res) => {
        let tableData: any = [];
        let nonExpandRows: any = [];
        //debugger;
        if (res.length > 0) {
          res[0]?.map((item, index) => {
            let newDate = moment(item.date).format("MM/DD/YYYY");
            let tableResponse: IBankSyncTableData = {
              transactions: [],
            };
            tableResponse.id = index + 1;
            tableResponse.syncID = item.syncID;
            tableResponse.date = newDate;
            tableResponse.date1 = item.date;
            tableResponse.description = item.discription;
            tableResponse.checkType = item.cheackType;
            tableResponse.customField = item.customField;
            tableResponse.plaidAmount = parseFloat(Number(item.amount).toFixed(2));
            tableResponse.singleTx = true;
            tableResponse.isExpand = false;
            tableResponse.tranType = item.tranType;
            tableResponse.Hotelid = this.state.hidValue;
            tableResponse.adjustment = parseFloat(Number(item.adjustment).toFixed(2));
            tableResponse.notes = item.notes;
            if (item.transCode.search(/transfer/i) !== -1) {
              tableResponse.transCode = "Transfer";
            } else if (item.transCode.search(/CreditCard/i) !== -1) {
              tableResponse.transCode = item.transCode;
            } else if (item.transCode.search(/invoice/i) !== -1) {
              tableResponse.transCode = "Invoice";
            } else if (item.transCode.search(/deposit/i) !== -1) {
              tableResponse.transCode = "Deposit";
            } else if (item.transCode.search(/withdrawal/i) !== -1) {
              tableResponse.transCode = "Withdrawal";
            } else {
              tableResponse.transCode = item.transCode;
            }
            tableResponse.trUniqueNo = item.transUniqueno;
            let matchEntry = "";
            let matchEntryDetails = res[1]?.filter(
              (matchData) => matchData.syncid === item.syncID
            );
            tableResponse.transactions = [];
            if (matchEntryDetails.length > 0) {
              const uniqueCheckNos = new Set();
              matchEntryDetails.map((matchData) => {
                uniqueCheckNos.add(matchData.check_Number);
                const matchedEntryCheckNo = Array.from(uniqueCheckNos).join(', ');
                let transCode = "";
                if (matchData.updateTbale.search(/transfer/i) !== -1) {
                  transCode = "Transfer";
                } else if (matchData.updateTbale.search(/CreditCard/i) !== -1) {
                  transCode = matchData[4];
                } else if (matchData.updateTbale.search(/invoice/i) !== -1) {
                  transCode = "Invoice";
                } else if (matchData.updateTbale.search(/deposit/i) !== -1) {
                  transCode = "Deposit";
                } else if (matchData.updateTbale.search(/withdrawal/i) !== -1) {
                  transCode = "Withdrawal";
                } else if (matchData.updateTbale.search(/miscamount/i) !== -1) {
                  transCode = "Withdrawal";
                }
                let data: IBankSyncTransactionData = {
                  syncID: tableResponse.syncID,
                  trUniqueNo: matchData.uniqueno,
                  transCode: transCode,
                  amount: matchData.displayAmount,
                  date: matchData.date,
                  vendorName: matchData.company,
                  updateTable: matchData.updateTbale,
                  transType: matchData.transType,
                  checkType: "",
                  plaidAmount: tableResponse.plaidAmount,
                  backHid: matchData.backHId,
                  checkNo: matchData.check_Number,
                  Hotelid: this.state.hidValue,
                  groupUniqueNo: matchData.groupUniqueno,
                  adjustment: tableResponse.adjustment,
                  badge: matchData.badge
                };
                if (!isNaN(parseInt(item.cheackType))) {
                  data.checkType = item.cheackType;
                }
                tableResponse.backHid = matchData.backHId;
                tableResponse.checkNo = matchData.check_Number;
                tableResponse.series = "";
                tableResponse.updateTable = matchData.updateTbale;
                tableResponse.transType = matchData.transType;
                tableResponse.groupUniqueNo = matchData.groupUniqueno;
                tableResponse.checkNumbers = matchedEntryCheckNo;
                tableResponse.badge = matchData.badge;
                matchEntry = matchData.matchEntry;
                tableResponse.transactions.push({
                  name: matchEntry,
                  data: data,
                });
              });
              if (matchEntryDetails.length > 1) {
                matchEntry = matchEntryDetails.length + " Transactions";
                tableResponse.singleTx = false;
                let plaidAmount = Math.abs(
                  Number(tableResponse.plaidAmount)
                );
                tableResponse.transactions =
                  tableResponse.transactions.sort((a, b) => {
                    return (
                      Math.abs(Number(a.data.amount) - plaidAmount) -
                      Math.abs(Number(b.data.amount) - plaidAmount)
                    );
                  });
              }
            } else {
              tableResponse.singleTx = false;
            }
            tableResponse.MatchedEntry = matchEntry;
            if (item.tranType === "Excluded" || (item.tranType === "Completed" && matchEntryDetails.length <= 1)) {
              nonExpandRows.push(index + 1);
            }
            tableData.push(tableResponse);
          });
        }
        this.setState(
          {
            tableData: tableData,
            nonExpandRow: nonExpandRows,
            isloader: false,
            batchTotal: 0,
          },
          () => {
            setTimeout(() => {
              this.CleanSearch();
              if (this.state.reconcileAfterSave) {
                //debugger;
                const transData = tableData.filter((o) => o.syncID == this.state.addTranReq[0].SyncId)[0].transactions;
                let reconCileData;
                for (let n of transData) {
                  const dataObj = n?.data;
                  if (+(dataObj.trUniqueNo) === this.state.addTranReq[0].Uniqueno) {
                    reconCileData = dataObj;
                    break;
                  }
                }
                this.setState({ reconcileAfterSave: false, addTranReq: [] });
                this.confirmTransation("single", "Completed", [
                  reconCileData,
                ]);
              } else {
                this.setState({ istableloader: false });
              }
              this.handleSortByColumnOnLoad(this.state.sortingField)
            }, 0);
          }
        );
      })
      .catch((error) => {
        this.CleanSearch();
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ isloader: false, istableloader: false });
      });
  };

  hideAdjustmentModal = (isSave) => {
    this.setState(
      {
        showAdjustmentModal: false,
        adjustmentData: {
          accper: "",
          hidValue: "",
          acctType: "",
          AccountType: "",
          AdjEntryType: "",
          OprID: "",
          adjustmentAmount: 0,
          syncID: 0,
        },
      },
      () => {
        const { confirmTxData } = this.state;
        if (isSave === "save") {
          this.saveTransactionStatus(
            confirmTxData[0],
            confirmTxData[1],
            confirmTxData[2]
          );
        } else if (isSave === "error") {
          this.setState(
            {
              // tableData,
              bulkSelectRow: false,
              bulkSelect: false,
              bulkSelectArray: {},
              selectedRows: [],
              expandedList: [],
              isloader: true,
              istableloader: true,
            },
            () => {
              this.getTableData();
            }
          );
        } else if (isSave === "close") {
          if (this.state.isDateUpdated)
            this.selectHotelDropDown(this.state.selectedEhid);
        }
      }
    );
    if (this.BankSyncChildTableRef.current !== null) {
      this.BankSyncChildTableRef?.current.enableConfirmBtn();
    }
  };

  getEtype(mtype, rowType) {
    let etype = "";
    etype = registersevice.getEtype(mtype, rowType);
    return etype;
  }

  showConfirmDateAlertForCheck = (dataToSave: any) => {
    confirmAlert({
      title: "Warning!",
      message: `Select an Inn-Flow transaction dated earlier than the corresponding bank transaction.`,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            reject();
            if (this.BankSyncChildTableRef.current !== null) {
              this.BankSyncChildTableRef?.current.enableConfirmBtn();
              this.BankSyncChildTableRef?.current.resetTableData();
              this.BankSyncChildTableRef?.current.ClearSelection();
            }
            if (dataToSave || [].some((o: any) => o?.transCode === "Transfer")) {
              this.getTableData();
            }
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  showConfirmDateAlert = (BankTranDate, entryType, actionType, dataToSave) => {
    confirmAlert({
      title: "Alert!",
      message: `By confirming, this will change the Inn-Flow transaction date to ${BankTranDate}. Are you sure you would like to proceed?`,
      buttons: [
        {
          label: "Confirm",
          onClick: () => {
            this.checkAdjustment(BankTranDate, entryType, actionType, dataToSave);
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            reject();
            if (this.BankSyncChildTableRef.current !== null) {
              this.BankSyncChildTableRef?.current.enableConfirmBtn();
              this.BankSyncChildTableRef?.current.resetTableData();
              this.BankSyncChildTableRef?.current.ClearSelection();
            }
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  checkAdjustment = async (BankTranDate, entryType, actionType, dataToSave) => {
    const {
      accountNameValue,
      bulkSelectArray,
      tenantID,
    } = this.state;
    let requestArray: any = [];
    if (dataToSave.length === 0) {
      for (const key in bulkSelectArray) {
        dataToSave.push(bulkSelectArray[key]);
      }
    }

    dataToSave.map((item) => {
      let request: any = { ...item };
      request.oprid = accountNameValue;
      request.Tenantid = tenantID;
      request.Type = actionType;
      request.SaveType = entryType;
      request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
      request.transType = item.transType ? item.transType : "";
      request.updateTable = item.updateTable ? item.updateTable : "";
      request.SyncId = item.syncID ? item.syncID : 0;
      request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
      request.transDate = BankTranDate;
      request.backHid = item.backHid;
      request.IFTransDate = item.date;
      requestArray.push(request);
    });
    console.log(requestArray[0]?.Type);
    let diffrentEHID: boolean = false;
    let checkDataSave = dataToSave;
    checkDataSave = _.uniqBy(checkDataSave, 'backHid');
    diffrentEHID = checkDataSave.length > 1;

    if (await this.checkForAdjustment(dataToSave)) {
      if (diffrentEHID) {
        confirmAlert({
          title: "Alert!",
          message: 'You have selected transactions from more than one EHID. Please only select transactions from the same EHID and continue.',
          buttons: [{
            label: "OK", onClick: () => {
              reject();
              if (this.BankSyncChildTableRef.current !== null) {
                this.BankSyncChildTableRef?.current.enableConfirmBtn();
                this.BankSyncChildTableRef?.current.resetTableData();
              }
            }
          }],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      } else {
        this.setState({
          showAdjustmentModal: true,
          isAdjustment: true,
          confirmTxData: [entryType, actionType, dataToSave],
          rowEntryType: dataToSave[0].updateTable
        });
      }
    } else {
      this.saveTransactionStatus(entryType, actionType, dataToSave);
    }
  }

  UpdateFromAdjustment = async () => {
    let { BankTranDate, entryType, actionType, dataToSave } = this.state;
    const {
      accountNameValue,
      bulkSelectArray,
      tenantID,
    } = this.state;

    let requestArray: any = [];
    if (dataToSave.length === 0) {
      for (const key in bulkSelectArray) {
        dataToSave.push(bulkSelectArray[key]);
      }
    }

    dataToSave.map((item) => {
      let request: any = { ...item };
      request.oprid = accountNameValue;
      request.Tenantid = tenantID;
      request.Type = actionType;
      request.SaveType = entryType;
      request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
      request.transType = item.transType ? item.transType : "";
      request.updateTable = item.updateTable ? item.updateTable : "";
      request.SyncId = item.syncID ? item.syncID : 0;
      request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
      request.transDate = BankTranDate;
      request.backHid = item.backHid;
      request.IFTransDate = item.date;
      requestArray.push(request);
    });
    const response: any = await Promise.all([bankSyncServices
      .UpdateIFTranDateToPlaidTranDate(requestArray)]);
    return response;
  }

  confirmTransation = async (entryType, actionType, dataToSave: any = []) => {
    let IsPendingPayment = this.state.bulkSelectArrayList.some((o) => (o?.badge === "Draft" || o?.badge === 'Awaiting Approval' || o?.badge === 'Awaiting Payment'));
    if (!IsPendingPayment) {
      let MainTrans = this.state.tableData.filter((o) => o.syncID === dataToSave[0]?.syncID);
      let BankTranDate = MainTrans[0]?.date;
      let isValid: any = true; let isDailyValid: any = true;
      let isDailyActivity = dataToSave.some((e) => e?.updateTable?.toLowerCase() === "depositentry");
      let isPayrollEntry = dataToSave.some((e) => e?.updateTable?.toLowerCase() === "payrollimportexcel" || e?.updateTable?.toLowerCase() === "payrollcheckapi");
      if (isDailyActivity || isPayrollEntry) {
        dataToSave.map((item) => {
          if (item?.updateTable?.toLowerCase() === "depositentry" || item?.updateTable?.toLowerCase() === "payrollimportexcel" || item?.updateTable?.toLowerCase() === "payrollcheckapi") {
            let d1 = `01/${(moment(item.date).format("MM/YY"))}`;
            let d2 = `01/${(moment(BankTranDate).format("MM/YY"))}`;
            if (new Date(d1) > new Date(d2)) {
              Utils.toastError("Can not reconcile future date transations.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              if (this.BankSyncChildTableRef.current !== null) {
                this.BankSyncChildTableRef?.current.enableConfirmBtn();
                this.BankSyncChildTableRef?.current.resetTableData();
                this.BankSyncChildTableRef?.current.ClearSelection();
              }
              isDailyValid = false;
              return false;
            }
          }
        });

      }
      if (isDailyValid) {
        dataToSave.map((item) => {
          if (item?.transType?.toLowerCase() === "check" || item?.transType?.toLowerCase() === "chkgroup" || (!isNaN(item?.checkNo) && item.transType.includes('Transfer')))
            if (new Date(item.date) > new Date(BankTranDate)) {
              this.showConfirmDateAlertForCheck(dataToSave);
              isValid = false;
              return;
            }
        });
        //}
        if (isValid) {
          if (actionType === "Completed") {
            this.checkForValidations(entryType, actionType, dataToSave, BankTranDate);
          } else {
            this.saveTransactionStatus(entryType, actionType, dataToSave);
          }
        }
      }
    } else {
      Utils.toastError("There is some transaction with pending payment.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  checkForValidations = async (entryType, actionType, dataToSave, BankTranDate) => {
    this.setState({
      isAlerted: false,
      entryType: entryType,
      actionType: actionType,
      dataToSave: dataToSave,
      BankTranDate: BankTranDate
    });
    const {
      accountNameValue,
      bulkSelectArray,
      tenantID,
    } = this.state;
    let requestArray: any = [];
    if (dataToSave.length === 0) {
      for (const key in bulkSelectArray) {
        dataToSave.push(bulkSelectArray[key]);
      }
    }
    if (entryType === "bulk" && BankTranDate === undefined)
      BankTranDate = this.state.tableData.filter((o) => o.syncID === dataToSave[0]?.syncID)[0]?.date;

    dataToSave.map((item) => {
      let request: any = { ...item };
      request.oprid = accountNameValue;
      request.Tenantid = tenantID;
      request.Type = actionType;
      request.SaveType = entryType;
      request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
      request.transType = item.transType ? item.transType : "";
      request.updateTable = item.updateTable ? item.updateTable : "";
      request.SyncId = item.syncID ? item.syncID : 0;
      request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
      request.transDate = BankTranDate;
      request.backHid = item.backHid;
      requestArray.push(request);
    });
    let diffrentEHID: boolean = false;
    let checkDataSave = dataToSave;
    checkDataSave = _.uniqBy(checkDataSave, 'backHid');
    diffrentEHID = checkDataSave.length > 1;
    bankSyncServices
      .BankSyncValidation(requestArray)
      .then(async (res: any) => {
        if (res !== null) {
          if (res.result?.toLowerCase() === 'success') {
            if (await this.checkForAdjustment(dataToSave)) {
              if (diffrentEHID) {
                confirmAlert({
                  title: "Alert!",
                  message: 'You have selected transactions from more than one EHID. Please only select transactions from the same EHID and continue.',
                  buttons: [{
                    label: "OK", onClick: () => {
                      reject();
                      if (this.BankSyncChildTableRef.current !== null) {
                        this.BankSyncChildTableRef?.current.enableConfirmBtn();
                        this.BankSyncChildTableRef?.current.resetTableData();
                      }
                    }
                  }],
                  closeOnEscape: false,
                  closeOnClickOutside: false,
                });
              } else {
                this.setState({
                  showAdjustmentModal: true,
                  isAdjustment: true,
                  confirmTxData: [entryType, actionType, dataToSave],
                  rowEntryType: dataToSave[0].updateTable
                });
              }
            } else {
              this.saveTransactionStatus(entryType, actionType, dataToSave);
            }
          } else {
            if (res?.message?.toLowerCase() == "inactive accounting period can not be confirmed.") {
              this.showConfirmAlert();
            } else if (res?.message?.toLowerCase() == "can not reconcile future date transations.") {
              this.setState({ isAlerted: true });
              this.showConfirmDateAlert(BankTranDate, entryType, actionType, dataToSave);
            } else {
              Utils.toastError(res.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              if (this.BankSyncChildTableRef.current !== null) {
                this.BankSyncChildTableRef?.current.enableConfirmBtn();
              }
            }
          }
        }
      })
      .catch((err) => {
        Utils.toastError("Failed to fetch adjustment amount.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        if (this.BankSyncChildTableRef.current !== null) {
          this.BankSyncChildTableRef?.current.enableConfirmBtn();
        }
        return true;
      });

  };

  showConfirmAlert = () => {
    confirmAlert({
      title: "Alert!",
      message: `This bank transaction cannot be reconciled, because the payment date is within a closed accounting period.  Please open the accounting period and then reconcile this transaction.`,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            reject();
            if (this.BankSyncChildTableRef.current !== null) {
              this.BankSyncChildTableRef?.current.enableConfirmBtn();
              this.BankSyncChildTableRef?.current.resetTableData();
              this.BankSyncChildTableRef?.current.ClearSelection();
            }
          }
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  checkForAdjustment = async (dataToSaveA?) => {
    const {
      hidValue,
      accountNameValue,
      hotelName,
      selectedAccountType,
      tenantID,
    } = this.state;
    let dataToSave = dataToSaveA[0];
    let plaidTxAmount = dataToSave.plaidAmount;
    let amountDiff = 0;
    let requestArr: any = [];
    let date;
    let BankTranDate = this.state.tableData.filter((o) => o.syncID === dataToSave?.syncID)[0]?.date;
    if (dataToSave.transactions === undefined) {
      dataToSaveA.map((item) => {
        let requestObj: any = {};
        requestObj.TenantID = tenantID;
        requestObj.Uniqueno = item.trUniqueNo;
        requestObj.Backhid = hidValue;
        requestObj.SyncID = item.syncID;
        requestObj.Oprid = accountNameValue;
        requestObj.TableName = item.updateTable;
        requestObj.PlaidAmount = plaidTxAmount;
        requestObj.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
        requestArr.push(requestObj);
      });
      date = new Date(BankTranDate);
    } else {
      let ifTransData = dataToSave?.transactions[0]?.data;
      let requestObj: any = {};
      requestObj.TenantID = tenantID;
      requestObj.Uniqueno = ifTransData.trUniqueNo;
      requestObj.Backhid = hidValue;
      requestObj.SyncID = ifTransData.syncID;
      requestObj.Oprid = accountNameValue;
      requestObj.TableName = ifTransData.updateTable;
      requestObj.PlaidAmount = plaidTxAmount;
      requestObj.groupUniqueNo = ifTransData.groupUniqueNo ? ifTransData.groupUniqueNo : "";
      requestArr.push(requestObj);
      date = new Date(BankTranDate);
    }

    let response: any = await bankSyncServices
      .GetBankSyncAdjustmentValue(requestArr)
      .then((res: any) => {
        if (res !== null) {
          if (res.length > 0) {
            amountDiff = Number(res[0]?.amount);
            if (amountDiff === 0) {
              return { showAdjustment: false, adjustmentType: "", adjEntryType: "", hotelName: "", backHid: "" };
            } else {
              return {
                showAdjustment: true,
                adjustmentType: res[0]?.type,
                adjEntryType: res[0]?.adjEntryType,
                hotelName: res[0]?.hotelName,
                backHid: res[0]?.backHid
              };
            }
          }
        }
      })
      .catch((err) => {
        Utils.toastError("Failed to fetch adjustment amount.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return true;
      });

    let syncID = dataToSave.syncID;
    let adjustmentData: any = {
      accTper: this.state.accountPeriod,
      accper: date ? date : "",
      hidValue: response.backHid ? response.backHid : hidValue,
      hotelName: response.hotelName ? response.hotelName : hotelName,
      acctType: selectedAccountType,
      AccountType: response.adjustmentType,
      AdjEntryType: response.adjEntryType,
      OprID: accountNameValue,
      adjustmentAmount: amountDiff.toFixed(2),
      syncID: syncID,
    };

    this.setState({ adjustmentData });
    return response.showAdjustment;
  };
  saveTransactionStatus = async (
    entryType,
    actionType,
    dataToSave: any = []
  ) => {
    if (!this.state.isAdjustment && this.state.isAlerted) {
      let res: any;
      res = await Promise.all([this.UpdateFromAdjustment()]);
      console.log(res);
    }

    this.setState({ isAdjustment: false });
    let BankTranDate = this.state.tableData.filter((o) => o.syncID === dataToSave[0]?.syncID)[0]?.date;
    let bulkSelectRow = false,
      bulkSelect = false;
    let { hidValue, accountNameValue, bulkSelectArray, tenantID } = this.state;
    let newTableData = [...this.state.tableData];
    let requestArray: any = [];
    if (dataToSave.length === 0) {
      for (const key in bulkSelectArray) {
        dataToSave.push(bulkSelectArray[key]);
      }
    }

    dataToSave.map((item) => {
      let request: any = {};
      if (entryType === "bulk") {
        request.oprid = accountNameValue;
        request.Hotelid = hidValue;
        request.Tenantid = tenantID;
        request.Type = actionType;
        request.SaveType = entryType;
        request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
        request.transType = item.transType ? item.transType : "";
        request.updateTable = item.updateTable ? item.updateTable : "";
        request.SyncId = item.syncID ? item.syncID : 0;
        request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
        request.backHid = item.backHId;
        request.transDate = BankTranDate;
        if (item.rowId) {
          newTableData = newTableData.filter(
            (tableObj) => tableObj.syncID !== item.rowId
          );
        } else {
          newTableData = newTableData.filter((item1) => item.id !== item1.id);
        }
      } else {
        request.SyncId = item.syncID ? item.syncID : 0;
        request.oprid = accountNameValue;
        request.Hotelid = hidValue;
        request.Tenantid = tenantID;
        request.Type = actionType;
        request.SaveType = entryType;
        request.trUniqueNo = item.trUniqueNo ? item.trUniqueNo : 0;
        request.transType = item.transType ? item.transType : "";
        request.updateTable = item.updateTable ? item.updateTable : "";
        request.groupUniqueNo = item.groupUniqueNo ? item.groupUniqueNo : "";
        request.backHid = item.backHid;
        request.transDate = BankTranDate;
        newTableData = newTableData.filter(
          (tableObj) => tableObj.syncID !== item.syncID
        );
      }
      requestArray.push(request);
    });

    bankSyncServices
      .SaveBankSyncTranctionStatus(requestArray)
      .then((res: any) => {
        if (res !== null && res.message.toLowerCase() === "success") {
          this.setState({
            tableData: newTableData,
            confirmSpinner: false,
            expandedList: [],
          });
          toast.success("Transaction has been saved successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          this.getTableData();
        } else {
          if (res?.message?.toLowerCase() == "inactive accounting period can not be confirmed.") {
            this.setState({
              confirmSpinner: false,
            });
            confirmAlert({
              title: "Alert!",
              message: `This bank transaction cannot be reconciled, because the payment date is within a closed accounting period.  Please open the accounting period and then reconcile this transaction.`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    reject();
                    if (this.BankSyncChildTableRef.current !== null) {
                      this.BankSyncChildTableRef?.current.enableConfirmBtn();
                      this.BankSyncChildTableRef?.current.resetTableData();
                      this.BankSyncChildTableRef?.current.ClearSelection();
                    }
                  }
                },
              ],
              closeOnEscape: false,
              closeOnClickOutside: false,
            });
          } else {
            Utils.toastError(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState(
              {
                bulkSelectRow: false,
                bulkSelect: false,
                bulkSelectArray: {},
                selectedRows: [],
                expandedList: [],
                isloader: true,
                istableloader: true,
              },
              () => {
                this.getTableData();
              }
            );
            this.setState({
              confirmSpinner: false,
            });
          }
        }
      })
      .catch((err) => {
        Utils.toastError("Transaction update failed.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({
          confirmSpinner: false,
        });
      });
    $("#footerBulkSelect").prop("checked", false).prop("indeterminate", false);
    this.setState({
      bulkSelectRow,
      bulkSelect,
      bulkSelectArray: {},
      selectedRows: [],
      hasMultipleTxn: [],
      hasMultipleAdjustments: false,
    });
  };

  getBankBalance = (request) => {
    bankSyncServices
      .getBankBalance(request)
      .then((resp) => {
        if (resp !== null) {
          let res = resp[0];
          let sharedHIDs = res.sharing.split(",");
          let { manualUploadColumns } = this.state
          let statusNotUploadedYet: any = res.balUpdatetime.toString().toLocaleLowerCase().includes("not uploaded yet");
          let colData = res.manualUploadColumns === undefined ? [] : res.manualUploadColumns.split(",");
          manualUploadColumns.CustomField = ((this.state.isSyncedAcc || res?.balUpdatetime?.includes("Updated on")) ? true : colData.some((o: any) => o.toString().toLocaleLowerCase() === "customfield"));
          manualUploadColumns.Description = ((this.state.isSyncedAcc || res?.balUpdatetime?.includes("Updated on")) ? true : statusNotUploadedYet ? true : colData.some((o: any) => o.toString().toLocaleLowerCase() === "description"));
          this.setState(
            {
              sharedHIDList: sharedHIDs,
              hasManualStatementUploadPermission: res?.hasManualStatementUploadPermission === "true" ? true : false,
              sharedHID: res.sharing,
              lastUpdatedStatus: res.balUpdatetime,
              accountBalance: res.accBalance,
              showBalance: res.glBalancee === "true" ? true : false,
              showReconnectButton:
                res.plaidError.toLowerCase() === "yes" ? true : false,
              errorCodeMsg: res.errorCodeMsg,
              showBanner: res.errorCodeMsg !== "",
              hasConnectPermission:
                res.hasConnectPermission.toLowerCase() === "true"
                  ? true
                  : false,
              manualUploadColumns
            },
            () => {
              this.getTableData();
            }
          );
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  getParamValueByParamName(paramName: string): any {
    let valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    window.history.replaceState(null, "");
    return valuesArray;
  }

  loadFromAccount = () => {
    const location = this.props.location;
    if (location.state !== undefined) {
      const stateLength = Object.keys(location.state).length;
      if (stateLength > 0) {
        let { oprid } = location.state;
        this.setState({ selectedOprID: oprid, loadingFromOther: true });
      }
    }
  };

  selectHotelDropDown = (hotelDetails: any) => {
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 1) {
      return;
    }
    this.setState({
      hotelName: hotelDetails.lettercode,
      hidValue: hotelDetails.hotelID,
    });
    let repeatlist = [],
      regularlist = [],
      splitlist = [],
      accountBal = [],
      viewType = "regular";
    let dayFilter: any[] = [
      { id: 1, name: "Last 30 Days" },
      { id: 2, name: "Last 60 Days" },
      { id: 3, name: "Last 90 Days" },
    ];
    let dayValue = 1,
      totalAcctBal = 0,
      totalsharedHIDCount = 0,
      totalsharedHIDTemp = 0;
    let totalsharedHID: any[] = [];
    let sharedcode = "";
    let accountNamelist: any[] = [];
    let accountNames: any[] = [];
    console.log("4", accountNames);

    let accountName = "Select Account Name";
    let accountNameValue = -1;
    const { defaultFilters } = this.state;
    this.setState(
      {
        defaultDensityView: defaultFilters[0],
        transactionFilterText: defaultFilters[1],
        dayText: defaultFilters[2],
        selectType: defaultFilters[3],
        transactionFilterValue: defaultFilters[4],
        repeatlist,
        regularlist,
        splitlist,
        accountBal,
        viewType,
        dayFilter,
        dayValue,
        totalAcctBal,
        totalsharedHID,
        totalsharedHIDTemp,
        totalsharedHIDCount,
        sharedcode,
        accountNamelist,
        accountNames,
        accountName,
        accountNameValue,
        dateRange: "",
        start: "",
        end: "",
        isSelect: true,
        ehidSected: 0,
        selectedEhid: hotelDetails,
        bulkSelectArrayList: [],
        hasManualStatementUploadPermission: false
      },
      () => {
        let registerRequest1: any = {};
        registerRequest1.hotelID = hotelDetails.hotelID;
        registerRequest1.lettercode = hotelDetails.lettercode;
        registerRequest1.logInUserID = this.state.logInUserID;
        registerRequest1.PageType = "BankSync";
        this.getRegisterNames(registerRequest1);
      }
    );
    if (this.childHID.current !== null) {
      let hidList = this.childHID.current.handleHIDList();
      this.setState({ hotelList: hidList });
    }
  };

  getRegisterNames = (registerRequest: any) => {
    let selectedaccount = "";
    let selectedStatus = "";
    bankSyncServices
      .BankSyncAccounts(registerRequest)
      .then(async (result: any[] | null) => {
        if (result !== null && result.length > 0) {
          let accountNames: any[] = [];
          result.sort((a, b) =>
            a.status !== b.status ? (a.status < b.status ? -1 : 1) : 0
          );
          result.forEach((element) => {
            if (element.status.toLowerCase() === "active") {
              let acctName: any = {};
              let oprID = element.oprID;
              let name = element.name;
              acctName.id = oprID;
              acctName.sort = element.orderBy;
              acctName.name = name;
              acctName.status = element.status;
              acctName.bName = element.bName;
              acctName.acctType = element.acctType;
              acctName.isSynced = Number(element.isSynced);
              accountNames.push(acctName);

              if (Number(this.state.selectedOprID) === Number(oprID)) {
                selectedaccount = name;
                selectedStatus = element.status;
              }
            }
          });

          let isDeviderSync: any = { bName: "SYNCED ACCOUNTS", id: 0, name: "Devider", sort: 0, status: "isLabelSyncd", };
          let isDeviderOther: any = { bName: "OTHER ACCOUNTS", id: 0, name: "Devider", sort: 0, status: "isLabelOther", };
          let isSyncAcc = accountNames.filter((item) => item.isSynced === 1);
          isSyncAcc = _.sortBy(isSyncAcc, "sort");
          let isOthrAcc = accountNames.filter((item) => item.isSynced === 0);
          isOthrAcc = _.sortBy(isOthrAcc, "sort");
          let combineGroup: any = [];
          isSyncAcc.length > 0 && combineGroup.push(isDeviderSync);
          combineGroup.push(...isSyncAcc);
          isOthrAcc.length > 0 && combineGroup.push(isDeviderOther);
          combineGroup = [...combineGroup, ...isOthrAcc];
          this.setState({ accountNames: [] })
          accountNames = _.sortBy(accountNames, "sort");
          this.setState({ accountNamelist: result });
          const location = this.props.location;
          if (location.state !== undefined) {
            const stateLength = Object.keys(location.state).length;
            if (stateLength > 0) {
              let arr: any = {};
              arr.id = this.state.selectedOprID;
              arr.status = selectedStatus;

              this.setState(
                { accountNames: combineGroup, accountName: selectedaccount },
                () => {
                  this.handleaccountNameSelectedItem(1, arr);
                }
              );
            }
          } else if (this.state.isAfterAddConnection) {
            const oldaccount = this.state.selectedAccount;
            let arr: any = {};
            arr.id = oldaccount?.id;
            arr.status = oldaccount?.status;
            setTimeout(() => {
              this.setState(
                { accountNames: combineGroup, accountName: oldaccount?.name !== undefined ? oldaccount?.name : "Select Account Name" },
                () => {
                  if (oldaccount?.name !== undefined) {
                    this.handleaccountNameSelectedItem(1, arr);
                  }
                });
            }, 100);
            this.setState({ isAfterAddConnection: false });
          } else {
            if (accountNames.length === 1) {
              this.handleaccountNameSelectedItem(1, accountNames[0]);
              this.setState({ accountName: accountNames[0].name }, () => {
                this.setState({
                  accountNames: combineGroup,
                  showReconnectButton: false,
                  errorCodeMsg: "",
                  showBanner: false,
                  hasConnectPermission: false,
                });
              });
            } else {
              this.setState({
                accountNames: combineGroup,
                showReconnectButton: false,
                errorCodeMsg: "",
                showBanner: false,
                hasConnectPermission: false,
              });
            }
          }
          resolve();
        }
      })
      .catch((error) => {
        reject();
      });
  };

  getDefaultView() {
    this.setState({ pageloader: true });
    laborPerformance
      .getDefaultViewProperties("bankSync")
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          let defaultDensityView = "";
          let transactionFilterText = "";
          let transactionFilterValue = 1;
          let selectType = "";
          let DayText = "Last 30 Days";
          response.map((item) => {
            if (item.fieldName === "DayCount") {
              if (item.fieldValue === "60") {
                DayText = "Last 60 Days";
              } else if (item.fieldValue === "90") {
                DayText = "Last 90 Days";
              }
              selectType = item.fieldValue;
            } else if (item.fieldName === "transType") {
              transactionFilterText = item.fieldValue;
              if (item.fieldValue !== "For Review") {
                transactionFilterValue = 2;
              }
            } else if (item.fieldName === "sort") {
              defaultDensityView = item.fieldValue;
            }
          });
          this.setState({
            defaultFilters: [
              defaultDensityView,
              transactionFilterText,
              DayText,
              selectType,
              transactionFilterValue,
            ],
            defaultDensityView,
            transactionFilterText,
            selectType,
            dayText: DayText,
            transactionFilterValue,
          });
        } else {
          this.setState({
            defaultDensityView: this.state.densityView[1].densityType,
          });
        }
      })
      .catch((error) => {
        this.setState({
          defaultDensityView: this.state.densityView[1].densityType,
        });
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }

  saveDefaultView = (): void => {
    const laborSaveAsDefaultdtos: any[] = [];
    const { defaultDensityView, transactionFilterText, selectType } =
      this.state;
    laborSaveAsDefaultdtos.push({
      fieldName: "sort",
      fieldValue: defaultDensityView,
      pageName: "bankSync",
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "transType",
      fieldValue: transactionFilterText,
      pageName: "bankSync",
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "DayCount",
      fieldValue: selectType,
      pageName: "bankSync",
    });
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({ showLoader: false });
      });
  };

  hideBankSlideOut(mode) {
    if (mode === 1) {
      this.CleanSearch();
      this.setState({ isShowSlideout: false, showLoader: true }, () => {
        let arr: any = {};
        arr.id = this.state.CardUniqueNo;
        arr.status = "active";
        this.handleaccountNameSelectedItem(1, arr);
      });
    } else {
      this.setState({ isShowSlideout: false });
    }
  }

  hideCreditCardSlideOut(mode) {
    if (mode === 1) {
      let request = {} as any;
      request.hotelID = this.state.hidValue;
      request.lettercode = this.state.hotelName;
    } else if (mode === 3) {
      let arr: any = {};
      arr.id = this.state.CardUniqueNo;
      arr.status = "active";
      this.handleaccountNameSelectedItem(1, arr);
    }
    this.setState({ isCCShowSlideout: false });
  }

  handleaccountNameSelectedItem = (controlID: any, arr: any) => {
    const { defaultFilters } = this.state;
    this.setState({
      istableloader: true,
      isloader: true,
      accountStatus: arr.status,
      selectedAccount: arr,
      bulkSelectArrayList: []
    });
    this.setState({ customDate: false });
    let sharedHIDs: any[] = [];
    let totalsharedHIDCount = 0;
    let acctType = "";
    let sharedcode = "";
    let name = "";
    let isCCA = false;
    this.state.accountNamelist
      .filter((x) => x.oprID === arr.id)
      .forEach((element) => {
        let hidCode: any = [];
        hidCode = element.sharedHID.split("[");
        totalsharedHIDCount = element.hidCount;
        acctType = element.acctType;
        sharedcode = element.sharedcode;
        name = element.name;
        isCCA = element.acctType.toString().toLocaleLowerCase() === "c";
        hidCode.forEach((item) => {
          let sharedHID: any = {};
          let lettercodes: any = [];
          lettercodes = item.split("_");
          let splitHid = lettercodes[0];
          let splitcode = lettercodes[1];
          sharedHID.hid = splitHid;
          sharedHID.lettercode = splitcode;
          sharedHID.isChecked = false;
          sharedHIDs.push(sharedHID);
        });
      });

    let transferRequest = { ...this.state.transferRequest };
    transferRequest.acctType = acctType;
    let balanceRequest: any = {};
    balanceRequest.oprId = arr.id;

    this.setState(
      {
        defaultDensityView: defaultFilters[0],
        transactionFilterText: defaultFilters[1],
        dayText: defaultFilters[2],
        selectType: defaultFilters[3],
        transactionFilterValue: defaultFilters[4],
        CardUniqueNo: arr.id,
        accountName: name,
        transferRequest,
        loadingFromOther: false,
        isCCAcc: isCCA,
        accountNameValue: arr.id,
        totalsharedHIDCount: totalsharedHIDCount,
        totalsharedHID: sharedHIDs,
        totalsharedHIDTemp: sharedHIDs,
        sharedcode: sharedcode,
        registerList: [],
        dateRange: "",
        start: "",
        end: "",
        isSelect: true,
        ehidSected: 0,
        viewType: "regular",
        selectedAccountType: acctType,
        bulkSelectRow: false,
        bulkSelect: false,
        bulkSelectArray: {},
        selectedRows: [],
        expandedList: [],
        bankName: arr.bName,
        acctType: arr.acctType,
        sortingField: "date",
        sortingOrder: "asc",
        isSyncedAcc: (arr?.isSynced === 1)
      },
      () => {
        this.CleanSearch();
        let balanceRequest: any = {};
        balanceRequest.oprId = arr.id;
        balanceRequest.oprType = acctType;
        if (acctType !== "" && acctType !== undefined) {
          setTimeout(() => {
            this.getBankBalance(balanceRequest);
          }, 100);
          this.getRegisterPermission();
        }
        else {
          this.setState({ istableloader: false, isloader: false, tableData: [] });
          return;
        }
      }
    );
    let { moreAction } = this.state;
    if (acctType === "C") {
      moreAction = this.state.moreAction.filter(
        (item) => item.name !== "Repeating"
      );
    } else {
      const ifExist = this.state.moreAction.find(
        (item) => item.name === "Repeating"
      );
      if (ifExist === undefined) {
        let MoreData: any = [];
        let more: any = {};
        more.name = "Repeating";
        more.eventKey = "repeating";
        MoreData.push(more);
        moreAction = this.state.moreAction.concat(MoreData);
      }
    }
    this.setState({ moreAction });
  };

  handleSelectedItem = (controlID: any, id: any) => {
    let selectType = "0";
    let dayText: any;
    let dayValue: any;
    let transactionFilterText: any;
    let updatedDayFilter = [...this.state.dayFilter];

    if (controlID === 1) {
      if (id === 1) {
        transactionFilterText = "For Review";
        updatedDayFilter = updatedDayFilter.filter(item => item.id !== 4);
      } else if (id === 2) {
        selectType = "30";
        dayText = "Last 30 Days";
        dayValue = 1;
        transactionFilterText = "Completed";
        if (!updatedDayFilter.find(item => item.id === 4)) {
          updatedDayFilter.push({ id: 4, name: "Custom" });
        }
      } else if (id === 3) {
        selectType = "30";
        dayText = "Last 30 Days";
        dayValue = 1;
        transactionFilterText = "Excluded";
        updatedDayFilter = updatedDayFilter.filter(item => item.id !== 4);
      }
      this.setState({
        transactionFilterText,
        transactionFilterValue: id,
        selectType,
        dayText,
        dayFilter: updatedDayFilter
      });
    } else {
      if (id === 1) {
        selectType = "30";
        dayText = "Last 30 Days";
        dayValue = id;
      } else if (id === 2) {
        selectType = "60";
        dayText = "Last 60 Days";
        dayValue = id;
      } else if (id === 3) {
        selectType = "90";
        dayText = "Last 90 Days";
        dayValue = id;
      } else if (id === 4) {
        let { dateRange } = this.state;
        selectType = "DateRange";
        dayText = "Custom";
        dayValue = id;
        this.setState({ customDate: true });

        const startDatee = new Date();
        const endDatee = new Date();
        let startDate;
        let endDate;
        let startDateFormatted: any;
        let endDateFormatted: any;

        endDatee.setFullYear(startDatee.getFullYear() - 1);
        startDateFormatted = this.formatDate(endDatee);
        endDateFormatted = this.formatDate(startDatee);

        if (dateRange === "") {
          let now = new Date();
          let pre30days;
          if (this.state.dayValue === 1) {
            pre30days = new Date(now.setDate(now.getDate() - 30))
          } else if (this.state.dayValue === 2) {
            pre30days = new Date(now.setDate(now.getDate() - 60))
          } else if (this.state.dayValue === 3) {
            pre30days = new Date(now.setDate(now.getDate() - 90))
          }

          let calenderYear = pre30days.getFullYear();
          let calenderDate = pre30days.getDate() > 9 ? pre30days.getDate() : 0 + "" + pre30days.getDate();
          let calenderMonth = pre30days.getMonth() + 1 > 9 ? pre30days.getMonth() + 1 : 0 + "" + (pre30days.getMonth() + 1);
          startDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

          let datee = new Date();
          let calenderYeare = datee.getFullYear();
          let calenderDatee = datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
          let calenderMonthe = datee.getMonth() + 1 > 9 ? datee.getMonth() + 1 : 0 + "" + (datee.getMonth() + 1);
          endDate = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;
        } else {
          startDate = this.state.dateRange.split("-")[0].trim()
          endDate = this.state.dateRange.split("-")[1].trim()
        }
        this.setState({
          dateRange: startDate + " - " + endDate, start: startDate, end: endDate,
          minDate: startDateFormatted, maxDate: endDateFormatted, dayText, selectType
        })
        setTimeout(() => {
          $('#dtrange').trigger('click');
        }, 100);
      }
      this.setState({
        dayValue: dayValue,
        dayText,
        selectType,
        dayFilter: updatedDayFilter,
      });
    }

    if (id !== 4) {
      this.setState(
        {
          bulkSelectRow: false,
          bulkSelect: false,
          bulkSelectArray: {},
          selectedRows: [],
          expandedList: [],
          isloader: true,
          istableloader: true,
          sortingField: "date",
          sortingOrder: "asc",
          dateRange: "", customDate: false, dayValue: dayValue, dayText
        },
        () => {
          this.getTableData();
        }
      );
    }
  };

  formatDate = (date) => {
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  handleDateCallback(start, end, label) {
    let selectType = "0";
    let date = new Date(start._d);
    let calenderYear = date.getFullYear();
    let calenderDate = date.getDate() > 9 ? date.getDate() : 0 + "" + date.getDate();
    let calenderMonth = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : 0 + "" + (date.getMonth() + 1);
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

    let datee = new Date(end._d);
    let calenderYeare = datee.getFullYear();
    let calenderDatee = datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
    let calenderMonthe = datee.getMonth() + 1 > 9 ? datee.getMonth() + 1 : 0 + "" + (datee.getMonth() + 1);
    let tempDatee = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;

    selectType = "DateRange";
    this.setState({ dateRange: tempDate + " - " + tempDatee, start: tempDate, end: tempDatee, selectType });
    this.getTableData();
  }

  handleDensityView = (viewName) => {
    this.setState({ defaultDensityView: viewName });
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => { }
  };

  CleanSearch = () => {
    if (this.tableSearchBankSync.current !== null) {
      this.tableSearchBankSync.current.CleanSearch();
    }
  };

  searchedData = (newResult: any) => {
    this.footerBulkClose();
  };

  handleOnSelect = (row, isSelect) => {
    let {
      tableData,
      selectedRows,
      bulkSelectRow,
      bulkSelect,
      bulkSelectArray,
      hasMultipleTxn,
      hasMultipleAdjustments,
      batchTotal,
      bulkSelectArrayList
    } = this.state;
    if (isSelect) {
      if (!row.singleTx) {
        hasMultipleTxn.push(row.id);
      } else {
        if (!hasMultipleAdjustments) {
          hasMultipleAdjustments =
            Number(Math.abs(row.plaidAmount)) !==
            Number(Math.abs(row.transactions[0].data.amount));
        }
      }
      selectedRows = [...this.state.selectedRows, row.id];
      bulkSelectArray[row.id] = row;
      console.log("select", row);
      batchTotal += row.plaidAmount;
      if (row?.transactions[0]?.data)
        bulkSelectArrayList.push(row?.transactions[0]?.data);
    } else {
      if (!row.singleTx) {
        hasMultipleTxn = hasMultipleTxn.filter((x) => x !== row.id);
      } else {
        if (hasMultipleAdjustments) {
          hasMultipleAdjustments = !(
            Number(Math.abs(row.plaidAmount)) !==
            Number(Math.abs(row.transactions[0].data.amount))
          );
        }
      }
      selectedRows = this.state.selectedRows.filter((x) => x !== row.id);
      delete bulkSelectArray[row.id];
      batchTotal -= row.plaidAmount;
      bulkSelectArrayList = bulkSelectArrayList.filter((o) => o?.syncID != row?.syncID);
    }
    if (selectedRows.length === 0) {
      bulkSelectRow = false;
      bulkSelect = false;
      $("#footerBulkSelect")
        .prop("checked", false)
        .prop("indeterminate", false);
    } else if (
      selectedRows.length > 0 &&
      selectedRows.length < tableData.length
    ) {
      bulkSelectRow = false;
      bulkSelect = true;
      $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
    } else if (selectedRows.length === tableData.length) {
      bulkSelectRow = true;
      bulkSelect = true;
      $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
    }
    let IsPendingPay = false;
    IsPendingPay = bulkSelectArrayList.some((o) => (o?.badge === "Draft" || o?.badge === 'Awaiting Approval' || o?.badge === 'Awaiting Payment'));
    this.setState(() => ({
      batchTotal,
      selectedRows,
      bulkSelectRow,
      bulkSelect,
      bulkSelectArray,
      hasMultipleTxn,
      hasMultipleAdjustments,
      isPendingPayment: IsPendingPay,
      bulkSelectArrayList
    }));
  };

  handleOnSelectAll = (isSelect, rows) => {
    let {
      selectedRows,
      bulkSelectArray,
      hasMultipleTxn,
      hasMultipleAdjustments,
      batchTotal,
      bulkSelectArrayList
    } = this.state;
    batchTotal = 0;
    if (rows.length > 0) {
      if (isSelect) {
        rows.forEach((item) => {
          if (!item.singleTx) {
            hasMultipleTxn.push(item.id);
          } else {
            if (!hasMultipleAdjustments) {
              hasMultipleAdjustments =
                Number(Math.abs(item.plaidAmount)) !==
                Number(Math.abs(item.transactions[0]?.data.amount));
            }
          }
          selectedRows.push(item.id);
          bulkSelectArray[item.id] = item;
          batchTotal += item.plaidAmount;
          if (item?.transactions?.length > 0)
            bulkSelectArrayList.push(item?.transactions[0]?.data);
        });
        $("#footerBulkSelect")
          .prop("checked", true)
          .prop("indeterminate", false);
      } else {
        hasMultipleTxn = [];
        selectedRows = [];
        bulkSelectArray = {};
        hasMultipleAdjustments = false;
        this.footerBulkClose();
        bulkSelectArrayList = [];
      }
      let IsPendingPay = false;
      rows.forEach(element => {
        IsPendingPay = selectedRows.length > 0 ? (element?.badge === "Draft" || element?.badge === 'Awaiting Approval' || element?.badge === 'Awaiting Payment') : false
      });

      this.setState(() => ({
        selectedRows,
        bulkSelectArray,
        bulkSelectRow: isSelect,
        bulkSelect: isSelect,
        hasMultipleTxn,
        hasMultipleAdjustments,
        batchTotal,
        isPendingPayment: IsPendingPay,
        bulkSelectArrayList
      }));
    } else {
      this.setState(() => ({
        batchTotal
      }));
    }
  };
  footerBulkClose = () => {
    this.setState(
      {
        selectedRows: [],
        bulkSelect: false,
        bulkSelectRow: false,
        bulkSelectArray: {},
        hasMultipleTxn: [],
        hasMultipleAdjustments: false,
        batchTotal: 0,
        bulkSelectArrayList: []
      },
      () => {
        $("#footerBulkSelect")
          .prop("checked", false)
          .prop("indeterminate", false);
      }
    );
  };
  footerBulkSelectAll = (event) => {
    let {
      tableData,
      selectedRows,
      bulkSelectArray,
      hasMultipleTxn,
      hasMultipleAdjustments,
      bulkSelectArrayList
    } = this.state;
    selectedRows = [];
    let checked = event.target.checked;
    if (checked) {
      tableData.forEach((item) => {
        if (!item.singleTx) {
          hasMultipleTxn.push(item.id);
        } else {
          if (!hasMultipleAdjustments) {
            hasMultipleAdjustments =
              Number(Math.abs(item.plaidAmount)) !==
              Number(Math.abs(item.transactions[0]?.data.amount));
          }
        }
        selectedRows.push(item.id);
        bulkSelectArray[item.id] = item;
        if (item.transactions?.length > 0)
          bulkSelectArrayList.push(item?.transactions[0]?.data);
      });
    } else {
      bulkSelectArray = {};
      selectedRows = [];
      hasMultipleAdjustments = false;
      this.footerBulkClose();
      bulkSelectArrayList = [];
    }
    if (selectedRows.length > 0 && selectedRows.length < tableData.length) {
      $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
    } else if (selectedRows.length === tableData.length) {
      $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
    }

    let IsPendingPay = false;
    IsPendingPay = bulkSelectArrayList.some((o) => (o?.badge === "Draft" || o?.badge === 'Awaiting Approval' || o?.badge === 'Awaiting Payment'));

    this.setState({
      bulkSelectRow: checked,
      selectedRows,
      bulkSelectArray,
      bulkSelect: checked,
      hasMultipleTxn,
      hasMultipleAdjustments,
      isPendingPayment: IsPendingPay,
      bulkSelectArrayList
    });
  };
  handleExpandCol = (row, rowIndex) => {
    let tableData = _.cloneDeep(this.state.tableData);
    tableData.map((item) => {
      if (item.id === row.id) {
        item.isExpand = !item.isExpand;
      }
    });
    this.setState(() => ({
      expandedList: [],
      tableData: tableData,
    }));
  };

  transationSlideoutSave = (saveReq, saveRes) => {
    const { entryType } = this.state;
    if (entryType === "edit") {
      this.setState({ entryType: "" });
      return;
    }
    let requestArray: any = [];
    let request: any = {};
    const { hidValue, accountNameValue, tenantID, AddTransationSyncID } = this.state;
    let isPageType = (saveReq.pageType === "BankSyncIncomplete") ? "IncompleteInvoice" : saveReq.pageType === "BankSyncInvoice" ? "Invoice" : saveReq.pageType;
    request.SyncId = AddTransationSyncID;
    request.HotelId = hidValue;
    request.Tenantid = tenantID;
    request.Uniqueno = saveRes.uniqueno;
    request.Oprid = accountNameValue;
    request.PageType = isPageType;
    request.UpdateTabale = "";
    console.log(request, "transationSlideoutSave");
    requestArray.push(request);
    this.setState({ addTranReq: requestArray });
    bankSyncServices
      .SavePlaidTranSyncMatchingDetailsStatus(requestArray)
      .then((response: any) => {
        if (response.success) {
          if (saveReq.pageType?.includes("BankSync")) {
            toast.success("Transaction has been saved successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
            this.setState({ invoiceSlideout: false }, () => {
              this.getTableData();
            })
          } else {
            this.setState({ reconcileAfterSave: true }, () => { });
          }
        } else {
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  getTransactionSlideoutData = async (row) => {
    if (
      row &&
      (row.updateTable?.toLowerCase().includes("invoice") ||
        row.updateTable?.toLowerCase().includes("creditcard"))
    ) {
      const { hidValue, accountNameValue, slideoutData } = this.state;
      let requestObj: any = {
        Hotelid: hidValue,
        oprid: accountNameValue,
        TransUniqueno: row.trUniqueNo,
        TableType: row.updateTable,
        PageType: "BankSync",
      };
      bankSyncServices
        .OpenSlideoutParameter(requestObj)
        .then((response) => {
          if (response !== null) {
            let SlideoutData = { ...slideoutData };
            let data = response.result[0];
            if (row.updateTable?.toLowerCase().includes("invoice")) {
              SlideoutData.selectedRow = {
                hid: data.backhid,
                groupID: data.uniqueno,
                oprId: data.oprid,
                checkno: data.ckNo,
                invPostFix: data.invPostfix,
                series: data.series,
              };
            } else {
              SlideoutData.oprId = data.oprid;
              SlideoutData.vid = data.vid;
            }

            this.setState(
              {
                slideoutData: SlideoutData,
                trslideoutData: SlideoutData
              },
              () => {
                this.setState((state) => {
                  if (row.updateTable?.toLowerCase().includes("invoice")) {
                    return { ...state, isSlideOut: true };
                  } else {
                    return { ...state, isCCSlideout: true };
                  }
                });
              }
            );
          }
        })
        .catch((error) => {
          Utils.toastError(error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    } else {
      if (row === undefined) {
        this.setState((state) => {
          return { ...state, isSlideOut: false, isCCSlideout: false };
        });
      } else {
        if (row.transCode.toLowerCase().includes("credit")) {
          this.setState((state) => {
            return { ...state, isCCSlideout: true };
          });
        } else if (row.transCode.toLowerCase() === "transfer") {
          let { trslideoutData, transferRequest } = this.state;
          trslideoutData.hotelID = this.state.hidValue;
          trslideoutData.lettercode = this.state.hotelName;
          trslideoutData.oprID = this.state.accountNameValue;
          trslideoutData.acctType = this.state.acctType;
          trslideoutData.amount = row?.amount;
          trslideoutData.date = row?.transDate;
          trslideoutData.slideType = row?.slideType;
          let hasGLPermission: any = [];
          hasGLPermission = this.state.permissionlist.filter((x) => x.eType.toLocaleLowerCase().includes("View Bank and GL Balances".toLocaleLowerCase()));
          if (hasGLPermission.length > 0) {
            hasGLPermission.forEach((items) => {
              transferRequest.glArray.push(items.eType.charAt(0));
            });
          }
          this.setState((state) => {
            return { ...state, trslideoutData, transferRequest, isTransferSlideOut: true };
          });
        } else if (row.transCode.toLowerCase() === "invoice") {
          let rowIndex = 0;

          row.callFrom = "BankSyncInvoice";
          this.OpenInvoiceSlideOut("", row, rowIndex, "invoice", "draft");
        } else {
          this.setState((state) => {
            return { ...state, isSlideOut: !state.isSlideOut };
          });
        }
      }
    }
  };

  hideSlideOut = (isSave, row, rowIndex) => {
    let slideoutData: any = {};
    let pageType = "";
    let AddTransationSyncID;
    let entryType;
    if (row) {
      if (row.transType?.toLowerCase() === "epay") {
        Utils.toastError("Grouped entries cannot be opened from here.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "banksyncpage"
        });
        return;
      } else if (row.transType?.toLowerCase() === "directdeposit") {
        Utils.toastError("Payroll entries cannot be opened from here.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "banksyncpage"
        });
        return;
      } else if (row.updateTable?.toLowerCase() === "depositentry") {
        Utils.toastError("Daily entries cannot be opened from here.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "banksyncpage"
        });
        return;
      }
      if (row.transCode.search(/CreditCardDep/i) !== -1) {
        pageType = "CCCREDIT";
      } else if (row.transCode.search(/CreditCardWith/i) !== -1) {
        pageType = "CCDEBIT";
      } else if (row.transCode.search(/deposit/i) !== -1) {
        pageType = "Deposit";
      } else if (row.transCode.search(/withdrawal/i) !== -1) {
        pageType = "Withdrawal";
      } else {
        pageType = this.getEtype(row.transCode, "main");
      }
      slideoutData.pageType = pageType;
      slideoutData.accDetails = {
        acctType: this.state.selectedAccountType,
        oprID: this.state.accountNameValue,
        accountName: this.state.accountName,
        description: row.description,
        transDate: row.transDate,
        totalAmount: row.amount,
        isNewTransBankSync: row.isNewTransBankSync
      };
      slideoutData.hidValue = this.state.hidValue;
      slideoutData.hotelName = this.state.hotelName;
      slideoutData.uniqueNo = row.trUniqueNo;
      slideoutData.selectedRow = {
        hid: row.backHid,
        groupID: row.trUniqueNo,
        oprId: this.state.accountNameValue,
        checkno: isNaN(parseInt(row.checkNo)) ? row.checkNo : row.checkNo,
        invPostFix: "",
        series: row.series ? row.series : "",
      };
      entryType = "edit";
      if (row.trUniqueNo === 0) {
        if (pageType === "CCCREDIT" || pageType === "CCDEBIT") {
          slideoutData.oprId = -1;
          slideoutData.vid = 0;
          slideoutData.selectedRow = {};
          slideoutData.isBankSyncPage = true;
        } else {
          slideoutData.selectedRow = {};
          slideoutData.isBankSyncPage = true;
          slideoutData.accper = this.state.accountPeriod;
        }
        entryType = "add";
      }
      AddTransationSyncID = row.syncID;
    }
    if (isSave) {
      this.setState(
        (state) => {
          return {
            ...state,
            slideoutData,
            isSlideOut: false,
            isCCSlideout: false,
            expandedList: [],
            isloader: true,
            istableloader: true,
            AddTransationSyncID: 0,
            entryType,
            invoiceSlideout: false
          };
        },
        () => {
          this.getTableData();
        }
      );
    } else {
      this.setState(
        (state) => {
          return {
            ...state,
            slideoutData,
            AddTransationSyncID,
            entryType,
            invoiceSlideout: false
          };
        },
        () => {
          this.getTransactionSlideoutData(row);
        }
      );
    }
  };

  getRegisterPermission = () => {
    let permissionRequest: any = {};
    permissionRequest.hotelID = this.state.hidValue;
    permissionRequest.oprID = this.state.accountNameValue;
    permissionRequest.pageName = "Register";
    registersevice
      .getRegisterPermission(permissionRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          let glBalanceType = this.state.transferRequest.acctType === "M" ? "Misc View Bank and GL Balances"
            : this.state.transferRequest.acctType === "O" ? "Op View Bank and GL Balances"
              : this.state.transferRequest.acctType === "P" ? "Py View Bank and GL Balances" : "";

          let gLBalancePerm = result.filter((x) => x.eType === glBalanceType).length;
          this.setState({
            permissionlist: result,
            isGLBalncePerm: this.state.transferRequest.acctType === "C" ? true : gLBalancePerm === 1 ? true : false,
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  hideTrSlideOut(hasEvent, row, rowIndex) {
    //debugger;
    let trslideoutData: any = {};
    let AddTransationSyncID: any = 0;
    let { transferRequest } = this.state;
    if (row) {
      let updateTable = "Transfer";
      if (row.updateTable) {
        updateTable = row.updateTable;
      } else if (row.transactions[0].data?.updateTable) {
        updateTable = row.transactions[0].data?.updateTable;
      }
      let acctType = "O";
      if (updateTable?.search(/misc/i) !== -1) {
        acctType = "M";
      } else if (updateTable?.search(/payroll/i) !== -1) {
        acctType = "P";
      }
      trslideoutData.pageType = "Transfer";
      trslideoutData.accDetails = {
        acctType: acctType,//this.state.selectedAccountType,
        oprID: this.state.accountNameValue,
        accountName: this.state.accountName,
      };
      trslideoutData.hidValue = row.backHid;
      trslideoutData.hotelName = this.state.hotelName;
      trslideoutData.uniqueNo = row.trUniqueNo;
      transferRequest.acctType = acctType;
    }
    AddTransationSyncID = row?.syncID;

    this.setState({ trslideoutData: trslideoutData, transferRequest }, () => {
      this.setState((state) => {
        return {
          ...state,
          isTransferSlideOut: !state.isTransferSlideOut,
          AddTransationSyncID
        };
      }, () => {
        hasEvent && this.getTableData();
      });
    });
  }

  OpenInvoiceSlideOut = (e, newrow, rowIndex, rowType, type) => {
    let invoiceslideoutData: any = {};
    if (newrow) {
      let row: any = {};
      let pageType = "";
      let etype = newrow.badge === "Draft" ? "INCOMPLETEINVOICE" : "Invoice";
      row.hid = (rowType === "invoice" ? newrow.backHid ? newrow.backHid : this.state.hidValue : newrow.hotelid);
      row.etype = "Invoice";
      row.vendor = (rowType === "invoice" ? newrow.venderName : newrow.vendor);
      row.backHIDCode = (rowType === "invoice" ? newrow.backHIDLetterCode ? newrow.backHIDLetterCode : this.state.hotelName : newrow.lettercode);
      row.vid = (rowType === "invoice" ? 0 : newrow.vid);
      row.oprId = (rowType === "invoice" ? this.state.accountNameValue : newrow.oprID);
      row.uniqueno = (rowType === "invoice" ? newrow.trUniqueNo : newrow.uniqueno);
      row.ckno = (rowType === "invoice" ? newrow.checkNo : newrow.ckno);
      row.series = newrow.series ? newrow.series : "";
      row.invPostFix = newrow.postFix ? newrow.postFix : "";
      row.groupID = (rowType === "invoice" ? newrow.trUniqueNo : newrow.groupID);
      pageType = (type === "draft") ? "IncompleteInvoice" : (rowType === "invoice" ? etype : this.getEtype(row.etype, rowType));
      invoiceslideoutData.pageType = pageType;
      invoiceslideoutData.oprID = row.oprId;
      invoiceslideoutData.oprID = row.vid;
      invoiceslideoutData.hidValue = this.state.hidValue;
      invoiceslideoutData.hotelName = this.state.hotelName;
      invoiceslideoutData.uniqueNo = row.uniqueno;
      invoiceslideoutData.isFromRepeating = false;
      invoiceslideoutData.selectedRow = { ...row, ...newrow };
    }
    this.setState({ invoiceslideoutData }, () => {
      this.setState((state) => {
        return {
          ...state,
          invoiceSlideout: !state.invoiceSlideout,
          isSlideOut: !state,
          isTransferSlideOut: false,
        };
      });
    });
    if (e === true) {
      this.setState(
        (state) => {
          return {
            ...state,
            invoiceslideoutData,
            isSlideOut: false,
            isCCSlideout: false,
            expandedList: [],
            isloader: true,
            istableloader: true,
          };
        },
        () => {
          this.getTableData();
        }
      );
    }
  };

  sortImage = (order) => {
    if (!order || order === "asc") {
      return (
        <span className="ascending">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
          </svg>
        </span>
      );
    } else if (order === "desc") {
      return (
        <span className="descending">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z" fill="#1D1E20" />
          </svg>
        </span>
      )
    }
    return null;
  };


  handleSortByColumnOnLoad = (field) => {
    if (field === "MatchedEntry") {
      let sortedData = this.state.tableData.sort((aData, bData) => {
        let a = aData.MatchedEntry;
        let b = bData.MatchedEntry;
        if (a?.toString()?.trim()?.toLowerCase() === b?.toString()?.trim()?.toLowerCase()) {
          return 0;
        }
        if (this.state.sortingOrder === "desc") {
          if (!a) return 1;
          if (!b) return -1;

          let AmatchCount = a.split(",");
          let BmatchCount = b.split(",");
          if (AmatchCount.length > 1 && BmatchCount.length > 1) {
            let aDate = a.split(",")[0];
            let bDate = b.split(",")[0];
            return new Date(aDate).valueOf() - new Date(bDate).valueOf();
          } else if (AmatchCount.length > 1 || BmatchCount.length > 1) {
            if (AmatchCount.length > 1) {
              return -1;
            }
            if (BmatchCount.length > 1) {
              return 1;
            }
          } else {
            let aDate = a.split(" ")[0];
            let bDate = b.split(" ")[0];
            return aDate - bDate;
          }
        } else {
          if (!a) return -1;
          if (!b) return 1;

          let AmatchCount = a.split(",");
          let BmatchCount = b.split(",");
          if (AmatchCount.length > 1 && BmatchCount.length > 1) {
            let aDate = a.split(",")[0];
            let bDate = b.split(",")[0];
            return new Date(aDate).valueOf() - new Date(bDate).valueOf();
          } else if (AmatchCount.length > 1 || BmatchCount.length > 1) {
            if (AmatchCount.length > 1) {
              return 1;
            }
            if (BmatchCount.length > 1) {
              return -1;
            }
          } else {
            let aDate = a.split(" ")[0];
            let bDate = b.split(" ")[0];
            return bDate - aDate;
          }
        }
      });
      this.setState({ tableData: [] }, () => {
        this.setState({ tableData: sortedData, istableloader: false, });
      });
    }

    else {
      this.sortTableData(this.state.tableData, this.state.sortingField);
    }
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        if (field === "MatchedEntry") {
          let sortedData = this.state.tableData.sort((aData, bData) => {
            let a = aData.MatchedEntry;
            let b = bData.MatchedEntry;
            if (a?.toString()?.trim()?.toLowerCase() === b?.toString()?.trim()?.toLowerCase()) {
              return 0;
            }
            if (this.state.sortingOrder === "desc") {
              if (!a) return 1;
              if (!b) return -1;

              let AmatchCount = a.split(",");
              let BmatchCount = b.split(",");
              if (AmatchCount.length > 1 && BmatchCount.length > 1) {
                let aDate = a.split(",")[0];
                let bDate = b.split(",")[0];
                return new Date(aDate).valueOf() - new Date(bDate).valueOf();
              } else if (AmatchCount.length > 1 || BmatchCount.length > 1) {
                if (AmatchCount.length > 1) {
                  return -1;
                }
                if (BmatchCount.length > 1) {
                  return 1;
                }
              } else {
                let aDate = a.split(" ")[0];
                let bDate = b.split(" ")[0];
                return aDate - bDate;
              }
            } else {
              if (!a) return -1;
              if (!b) return 1;

              let AmatchCount = a.split(",");
              let BmatchCount = b.split(",");
              if (AmatchCount.length > 1 && BmatchCount.length > 1) {
                let aDate = a.split(",")[0];
                let bDate = b.split(",")[0];
                return new Date(aDate).valueOf() - new Date(bDate).valueOf();
              } else if (AmatchCount.length > 1 || BmatchCount.length > 1) {
                if (AmatchCount.length > 1) {
                  return 1;
                }
                if (BmatchCount.length > 1) {
                  return -1;
                }
              } else {
                let aDate = a.split(" ")[0];
                let bDate = b.split(" ")[0];
                return bDate - aDate;
              }
            }
          });
          this.setState({ tableData: [] }, () => {
            this.setState({ tableData: sortedData });
          });
        }
        else {
          this.sortTableData(this.state.tableData, this.state.sortingField);
        }
      }
    );
  };

  sortTableData = (data: any, fieldName) => {
    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(data, [fieldName === "date" ? "date1" : fieldName, "date1"], ["asc", "asc"]);
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(data, [fieldName === "date" ? "date1" : fieldName, "date1"], ["desc", "asc"]);
    }
    this.setState({ tableData: data });
  };

  handleNotesChange(e) {
    this.setState({ notes: e.target.value.trim() });
  }

  getExport = () => {
    exportDataSet = []
    let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));
    let dateObj = new Date();
    let objmonth = (dateObj.getMonth() + 1) < 10 ? "0" + (dateObj.getMonth() + 1).toString() : (dateObj.getMonth() + 1).toString();
    let objdate = (dateObj.getUTCDate()) < 10 ? "0" + (dateObj.getUTCDate()).toString() : (dateObj.getUTCDate()).toString();
    let objHr = (dateObj.getHours()) < 10 ? "0" + (dateObj.getHours()).toString() : (dateObj.getHours()).toString();
    let objMM = (dateObj.getMinutes()) < 10 ? "0" + (dateObj.getMinutes()).toString() : (dateObj.getMinutes()).toString();
    let objSS = (dateObj.getSeconds()) < 10 ? "0" + (dateObj.getSeconds()).toString() : (dateObj.getSeconds()).toString();

    let excelfileName = "BankSync-" + (dateObj.getUTCFullYear().toString().substring(2, 4))
      + "-" + objmonth + "" + objdate
      + " " + objHr.toString() + "." + objMM.toString() + "." + objSS.toString();
    let lastUpdatedStatus = this.state.lastUpdatedStatus?.toLocaleLowerCase()
    let isCustomField = this.state.manualUploadColumns.CustomField && (lastUpdatedStatus.includes("last manual upload") || lastUpdatedStatus.includes("not uploaded yet")) ? true : false;

    let dateFormat = "MM/DD/YY";

    tableData.forEach(item => {
      let row: any = {};
      const isCheckEntry = +(item.checkType) > 0;
      row["Date"] = moment(item.date).format(dateFormat);
      this.state.manualUploadColumns.Description && (row["Description"] = item.description);
      row["Check / Type"] = item.checkType;
      row["Amount"] = item.plaidAmount;
      isCustomField && (row["Custom Field"] = item.customField);
      row["Matched Entry"] = item.MatchedEntry + ((isCheckEntry && item.MatchedEntry !== "" && item.checkNo !== null) ? ", Check Number: " + (this.state.transactionFilterText !== "Completed" ? item.checkNo : item.checkNumbers) : "");
      this.state.transactionFilterText === "Completed" && (row["Adj."] = item.adjustment);
      exportDataSet.push(row);
    })
    this.setState({ exportData: exportDataSet, excelfileName: excelfileName }, () => {
      let tempLink = document.getElementById("btnexport1") as any;
      tempLink.click();
      toast.success("File has been downloaded successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "banksyncpage",
      })
    })
  }

  toggleManualStatUploadModal = (updated: any, isReload: any = false) => {
    if (updated) {
      this.setState({ showManualUploadModal: true });
    } else {
      this.setState({ showManualUploadModal: false });
    }
    if (isReload) {
      let req: any = {};
      let balReq: any = {};
      req.hotelID = this.state.hidValue;
      req.lettercode = this.state.hotelName;
      req.logInUserID = this.state.logInUserID;
      req.PageType = "BankSync";
      balReq.oprId = this.state.accountNameValue;
      balReq.oprType = this.state.acctType;
      this.setState({ selectedRows: [], bulkSelect: false, batchTotal: 0 });
      this.getRegisterNames(req);
      this.getTableData();
      this.getBankBalance(balReq);
    }
  };

  render() {
    const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => sortOrder === "asc" ? "active" : "active";
    const renderDateheaderFormatter = (column: any) => (
      <div className={`d-inline-block ${this.state.sortingField === "date" ? "active" : ""}`} onClick={() => this.handleSortByColumn("date")}>{column.text}
        {this.state.tableData.length > 0 && this.sortImage(this.state.sortingOrder)}
      </div>
    )
    const renderColumnsheaderFormatter = (column: any, type: any) => (
      <div className={`d-inline-block ${this.state.sortingField === type ? "active" : ""}`} onClick={() => this.handleSortByColumn(type)}>
        {column.text}
        {this.state.tableData.length > 0 && this.sortImage(this.state.sortingOrder)}
      </div>
    );

    const renderEllipsisWithTooltip = (value: any) => (<EllipsisWithTooltip placement="bottom">{value}</EllipsisWithTooltip>);
    const renderPlaidAmount = (value: any) => {
      let amt = String(value);
      let dollerSign = "$";
      if (amt.toString().includes("-")) {
        amt = amt.slice(1);
        dollerSign = "-$";
      }
      return (
        <div className="amount-value">
          {dollerSign}
          {Utils.amountFormatted(Number(amt).toFixed(2))}
        </div>
      );
    }

    const renderMatchedEntry = (row: any, rowIndex: any) => {
      let isExpand = row.isExpand;
      let allowSlideout = false;
      if (row.singleTx) {
        allowSlideout = true;
      }
      const isCheckEntry = +(row.checkType) > 0;
      const isCheckNo = +(row.checkNo) > 0
      return (
        <div className="matched-entry-value" onClick={() => {
          if (!row.singleTx) {
            let tableData = _.cloneDeep(this.state.tableData);
            let expandedList: any = [];
            tableData.map((item) => {
              if (item.id === row.id) {
                item.isExpand = !isExpand;
              } else {
                item.isExpand = false;
              }
            });
            isExpand = !isExpand;
            if (isExpand) {
              expandedList.push(row.id);
            }
            this.setState({ expandedList, tableData });
            return;
          }
          if (!allowSlideout) {
            return;
          }
          if (row.badge.search(/Register/i) !== 0 && row.transCode.search(/invoice/i) !== -1) {
            this.OpenInvoiceSlideOut("", row, rowIndex, "invoice", "invoice");
          } else if (row.transCode.search(/transfer/i) !== -1) {
            this.hideTrSlideOut(false, row, rowIndex);
          } else if (row.transCode.search(/invoice/i) !== -1) {
            this.hideSlideOut(false, row, rowIndex);
          } else {
            this.hideSlideOut(false, row, rowIndex);
          }
        }}
        >
          {this.state.transactionFilterText !== "Completed" ?
            <EllipsisWithTooltip placement="bottom">
              {row.MatchedEntry}
              {isCheckNo && isCheckEntry && row.MatchedEntry != "" && row.checkNo !== null ? ", Check Number: " + row.checkNo : ""}
            </EllipsisWithTooltip>
            :
            <EllipsisWithTooltip placement="bottom">
              {row.MatchedEntry}
              {isCheckNo && isCheckEntry && row.MatchedEntry != "" && row.checkNo !== null ? ", Check Number: " + row.checkNumbers : ""}
            </EllipsisWithTooltip>
          }
        </div>
      );
    }

    const renderColAdjustment = (value: any) => {
      let amt = String(value);
      return (
        <div className="amount-value">
          {amt === "0" ? "-" : Utils.currencyFormat(Number(amt).toFixed(2))}
        </div>
      );
    }

    const renderRightColComponent = (row: any, rowIndex: any) => {
      let isExpand = row.isExpand;
      let shouldDisable = false;
      shouldDisable = row.badge === 'Draft' ||
        row.badge === 'Awaiting Approval' ||
        row.badge === 'Awaiting Payment' ? true : false;
      return (
        <div className="d-flex text-right action-col">
          <div className="">
            {row.tranType === "Pending" && row.singleTx ? (
              <>
                {shouldDisable ?
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="custom-Tooltip">This transaction has not yet been posted.</Tooltip>
                    }>
                    <Button
                      className="confirm-btn"
                      disabled={row.confirmSpinner || shouldDisable}
                      onClick={() => {
                        this.confirmTransation("single", "Completed", [
                          { ...row },
                        ]);
                      }}
                    >
                      {row.confirmSpinner && (
                        <Spinner
                          className="mr-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Confirm
                    </Button>
                  </OverlayTrigger> :
                  <Button
                    className="confirm-btn"
                    disabled={row.confirmSpinner || shouldDisable}
                    onClick={() => {
                      this.confirmTransation("single", "Completed", [
                        { ...row },
                      ]);
                    }}
                  >
                    {row.confirmSpinner && (
                      <Spinner
                        className="mr-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    Confirm
                  </Button>}
              </>
            ) : (
              <>
                {row.tranType === "Pending" && (
                  <Button
                    className="btn-outline-primary review-btn"
                    onClick={() => {
                      let tableData = _.cloneDeep(this.state.tableData);
                      let expandedList: any = [];
                      tableData.map((item) => {
                        if (item.id === row.id) {
                          item.isExpand = !isExpand;
                        } else {
                          item.isExpand = false;
                        }
                      });
                      isExpand = !isExpand;
                      if (isExpand) {
                        expandedList.push(row.id);
                      }
                      this.setState({ expandedList, tableData, selectedPlaidAmount: row?.plaidAmount });
                    }}
                  >
                    {isExpand ? "Close" : "Review"}
                  </Button>
                )}
              </>
            )}
          </div>
          {row.tranType === "Pending" && (
            <div className="">
              <Dropdown
                id="more-action"
                className="more-action"
                alignRight
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary btn-ghost more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"Delete"}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.saveTransactionStatus("single", "Excluded", [
                        { ...row },
                      ]);
                    }}
                    key={rowIndex}
                  >
                    Exclude Transaction
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      );
    }

    const hiddenCustomField = () => {
      let isShow: boolean = false;
      isShow = (this.state.manualUploadColumns.CustomField && (this.state.lastUpdatedStatus?.toLocaleLowerCase().includes("last manual upload") || this.state.lastUpdatedStatus?.toLocaleLowerCase().includes("not uploaded yet")))
      return isShow;
    }

    const hiddenDescription = () => {
      return true
    }

    const columns = [
      {
        dataField: "date",
        text: "Date",
        headerSortingClasses,
        headerClasses: "bsyncColDate",
        searchable: true,
        headerFormatter: (column, colIndex) => renderDateheaderFormatter(column),
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.date),
      },
      {
        dataField: "description",
        text: "Description",
        headerClasses: "bsyncColDes",
        classes: "bsyncColDes",
        searchable: this.state.manualUploadColumns.Description ? true : false,
        hidden: this.state.manualUploadColumns.Description ? false : true,
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "description"),
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.description),
      },
      {
        dataField: "checkType",
        text: "Check / Type",
        headerClasses: "bsyncColCheckType",
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "checkType"),
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.checkType),
      },
      {
        dataField: "plaidAmount",
        text: "Amount",
        headerClasses: "bsyncColAmt right-align",
        classes: "right-align pad-right-16",
        searchable: true,
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "plaidAmount"),
        formatter: (cell: any, row: any, rowIndex: any) => renderPlaidAmount(row.plaidAmount),
      },
      {
        dataField: "customField",
        text: "Custom Field",
        headerClasses: "bsyncColDes",
        classes: "bsyncColDes",
        searchable: hiddenCustomField() ? true : false,
        hidden: hiddenCustomField() ? false : true,
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "customField"),
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.customField),
      }
      , {
        dataField: "MatchedEntry",
        text: "Matched Entry",
        headerClasses: "bsyncColMatchedEntry right-align",
        classes: "right-align pad-right-16 bsyncColMatchedEntry",
        searchable: false,
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "MatchedEntry"),
        formatter: (cell: any, row: any, rowIndex: any) => renderMatchedEntry(row, rowIndex),
      },
      {
        dataField: "adjustment",
        text: "Adj.",
        headerClasses: "right-align",
        classes: "right-align pad-right-16",
        hidden: this.state.transactionFilterText !== "Completed" ? true : false,
        headerSortingClasses,
        headerFormatter: (column, colIndex) => renderColumnsheaderFormatter(column, "adjustment"),
        formatter: (cell: any, row: any, rowIndex: any) => renderColAdjustment(row.adjustment),
      },
      {
        dataField: "",
        text: "",
        headerAlign: "right",
        headerClasses: "bsyncColcl",
        hidden: this.state.transactionFilterText === "For Review" ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => renderRightColComponent(row, rowIndex),
      },
    ];

    const handleOnExpand = (row, isExpand, rowIndex, e) => {
      let tableData = _.cloneDeep(this.state.tableData);
      tableData.map((item) => {
        if (item.id === row.id) {
          item.isExpand = !item.isExpand;
        } else {
          item.isExpand = false;
        }
      });
      if (isExpand) {
        this.setState(() => ({
          expandedList: [row.id],
          tableData: tableData,
          selectedPlaidAmount: row?.plaidAmount
        }));
      } else {
        this.setState(() => ({
          expandedList: [],
          tableData: tableData,
        }));
      }
    };

    const selectRow = {
      mode: "checkbox",
      selected: this.state.selectedRows,
      headerClasses: "selectionHeader",
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };
    const defaultSorted = [
      {
        dataField: "date",
        order: "asc",
      },
    ];

    const expandHeaderColumnRenderer = (isAnyExpands: any) => {
      if (isAnyExpands) {
        return <div></div>;
      }
      return <div></div>;
    }

    const expandColumnRenderer = (expanded: any, rowKey: any) => {
      let isnonexpend = false;
      this.state.nonExpandRow.forEach(element => {
        if (rowKey === element) {
          isnonexpend = true;
        }
      });
      if (isnonexpend) {
        return (
          <div></div>
        )
      }
      else {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      }
    }

    const expandRow = {
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => (
        <div className="rowExpended" id="laborDayDetails">
          <BankSyncChildTable
            transactionFilterType={this.state.transactionFilterText}
            ref={this.BankSyncChildTableRef}
            expandColapseRow={this.handleExpandCol.bind(this, row, rowIndex)}
            row={row}
            transactions={row.transactions}
            saveTransactionStatus={this.saveTransactionStatus.bind(this)}
            confirmTransation={this.confirmTransation.bind(this)}
            hideSlideOut={this.hideSlideOut.bind(this)}
            OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
            hideTrSlideOut={this.hideTrSlideOut.bind(this)}
            showConfirmAlert={this.showConfirmAlert}
            {...this.state}
          />
        </div>
      ),
      nonExpandable: this.state.nonExpandRow,
      expanded: this.state.expandedList,
      showExpandColumn: true,
      expandByColumnOnly: true,
      onExpand: handleOnExpand,
      onlyOneExpanding: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => expandHeaderColumnRenderer(isAnyExpands),
      expandColumnRenderer: ({ expanded, rowKey }) => expandColumnRenderer(expanded, rowKey),
    };

    let errMsg = "We are having trouble connecting to this bank account. ";

    if (this.state.errorCodeMsg?.toUpperCase() == "ITEM_LOGIN_REQUIRED" ||
      this.state.errorCodeMsg?.toUpperCase() == "ITEM_NOT_FOUND" ||
      this.state.errorCodeMsg?.toUpperCase() == "USER_PERMISSION_REVOKED") {
      errMsg = 'For security purposes, many banks require regular reauthentication. After reauthentication, Inn-Flow will retrieve your transaction history. ';
    }

    return (
      <React.Fragment>
        <div>
          <ToastContainer
            autoClose={3000}
            containerId={"banksyncpage"}
            enableMultiContainer
          />

          <div className="bank-sync">
            <Container fluid className="body-sec">
              {this.state.hasBankPerm && (
                <div className="page-heading underline d-flex">
                  <HidSelector
                    onClickEvent={this.selectHotelDropDown}
                    ref={this.childHID}
                    modulename={"banksync"}
                  />
                  {this.state.loadingFromOther && (
                    <Spinner
                      style={{ fontSize: "12px" }}
                      animation="border"
                      size="sm"
                      variant="primary"
                    />
                  )}
                  {this.state.hasBankPerm && (
                    <div className="mr-auto d-flex align-items-center">
                      Bank Sync
                    </div>
                  )}
                  <div className="action-group d-flex">
                    {this.state.hasManualStatementUploadPermission && (
                      <button type="button" className="nonActiveBtn" onClick={() => { this.toggleManualStatUploadModal(true, "") }}>
                        Manual Statement Upload
                      </button>
                    )}

                    {this.state.hasBankPerm && this.state.isConnectBankPerm && (
                      <Button type="button" onClick={() => { this.showBankSyncWizard() }}>
                        Bank Sync Connections
                      </Button>
                    )}
                    {this.state.tableData.length > 0 && (
                      <div className="section">

                        <Button className="without-border margin-right-15" variant="primary" style={{ display: "none" }}>
                          <CSVLink id="btnexport1" data={exportDataSet}
                            filename={this.state.excelfileName + ".csv"}>Export</CSVLink></Button>
                        <Dropdown className="more-action three-dot" alignRight onSelect={this.getExport}>
                          <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey={"excel"}>Export</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                    )}
                  </div>
                </div>
              )}
              {this.state.hasNoSubscription && (
                <div className="page-heading underline d-flex">
                  No Advanced Accounting Subscription
                </div>
              )}
              {this.state.hidValue !== "Select" && (
                <div className="main-Page">
                  <div className="bdy-sec">
                    <ToolkitProvider
                      keyField="Modal_rNo"
                      data={this.state.tableData}
                      columns={columns}
                      search={{
                        afterSearch: (newResult: any) => {
                          this.searchedData(newResult);
                        },
                      }}
                      rowEvents={this.rowEvents}
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          {this.state.hotelName !== "Select" && this.state.accountNames.length > 0 && (
                            <div className="search-header registerHeaderWithoutSearch d-flex">
                              <div className="filter-section">
                                <div className="singleSearchDropdownListWithIcon">
                                  <SingleSearchDropdownList
                                    id={"tableAccountName"}
                                    ref={this.accountName}
                                    itemList={this.state.accountNames}
                                    handleSelectedItem={this.handleaccountNameSelectedItem.bind(
                                      this,
                                      1
                                    )}
                                    defaultItem={this.state.HIDToNM}
                                    defaultText={"Search..."}
                                    defaultName={this.state.accountName}
                                    calHomeIcon={true}
                                    currentChecked={this.state.accountName}
                                    controlID="2"
                                    pageType={"Register"}
                                    isStatusbadge={true}
                                  />
                                </div>
                              </div>
                              {this.state.accountNameValue !== -1 &&
                                this.state.sharedHIDList.length > 1 &&
                                !this.state.istableloader && (
                                  <>
                                    <div className="shared-badge badge grey-bg">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={"ehid-list"}>
                                            {this.state.sharedHID}
                                          </Tooltip>
                                        }
                                      >
                                        <span>{`Shared (${this.state.sharedHIDList.length})`}</span>
                                      </OverlayTrigger>
                                    </div>
                                  </>
                                )}
                              {this.state.accountNameValue !== -1 &&
                                this.state.lastUpdatedStatus !== null && (
                                  <>
                                    <div className="ml-auto">
                                      {this.state.showBalance && !this.state.lastUpdatedStatus?.toLocaleLowerCase().includes("not uploaded yet") && (
                                        <div className="Accbalance">

                                          {!this.state.lastUpdatedStatus?.toLocaleLowerCase().includes("last manual upload") && (
                                            <>
                                              <span className="balTitle">Bank Account Balance</span>
                                              <strong className="balMain">{"$"}{Utils.amountFormatted(Number(this.state.accountBalance).toFixed(2))}</strong>
                                            </>
                                          )}
                                          {(this.state.lastUpdatedStatus?.toLocaleLowerCase() !== "syncedwithplaid" && !this.state.lastUpdatedStatus?.toLocaleLowerCase().includes("updated on")) && (
                                            <div className="timeStamp-badge"><span>{this.state.lastUpdatedStatus}</span></div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                            </div>
                          )}
                          {this.state.accountNameValue !== -1 &&
                            (
                              <>
                                <div className="search-header registerSearchHeader d-flex">
                                  <>
                                    {!this.state.isloader ? (
                                      <div className="registerSearch">
                                        <TableSearch
                                          id="tableSearch"
                                          key="tableSearch"
                                          ref={this.tableSearchBankSync}
                                          {...props.searchProps}
                                          CleanSearch={this.CleanSearch.bind(
                                            this
                                          )}
                                          placeholder={
                                            "Filter Bank Transactions"
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div className="single-loader loadingAnimation"></div>
                                    )}
                                  </>
                                  <div className="filter-section cusDateMargin d-flex align-items-center">
                                    {!this.state.isloader ? (
                                      <>
                                        {this.state.accountNamelist.length >
                                          0 && (
                                            <>
                                              <div className="transactionFilter">
                                                <SingleDropdownListWithoutSearch
                                                  id={"transactionFilter"}
                                                  itemList={
                                                    this.state.transactionFilter
                                                  }
                                                  handleSelectedItem={this.handleSelectedItem.bind(
                                                    this,
                                                    1
                                                  )}
                                                  defaultName={
                                                    this.state
                                                      .transactionFilterText
                                                  }
                                                />
                                              </div>
                                            </>
                                          )}

                                        {this.state.transactionFilterValue !==
                                          1 && (
                                            <>
                                              <SingleDropdownListWithoutSearch
                                                id={"tabledayCount"}
                                                itemList={this.state.dayFilter}
                                                handleSelectedItem={this.handleSelectedItem.bind(
                                                  this,
                                                  2
                                                )}
                                                defaultName={this.state.dayText}
                                                calIcon={true}
                                              />
                                            </>
                                          )}
                                        {!this.state.isloader ?
                                          <>
                                            {this.state.customDate && (
                                              <div className="dateRangePicker hideDisableDate">
                                                <DateRangePicker
                                                  onCallback={this.handleDateCallback}
                                                  initialSettings={{
                                                    minDate: this.state.minDate,
                                                    maxDate: this.state.maxDate,
                                                    startDate: new Date(this.state.start), endDate: new Date(this.state.end)
                                                  }}
                                                >
                                                  <div className="d-flex align-items-center info">
                                                    <div className="shared-list" id="dtrange">{this.state.dateRange}</div>
                                                  </div>
                                                </DateRangePicker>
                                              </div>
                                            )}
                                          </>
                                          : <div className="single-loader loadingAnimation"></div>
                                        }
                                      </>
                                    ) : (
                                      <div className="single-loader loadingAnimation"></div>
                                    )}
                                  </div>

                                  <div className="filter-section ml-auto hasGearIcon">
                                    {!this.state.isloader ? (
                                      <Dropdown className="more-action bg-blue dropdown single-select-image" >
                                        <Dropdown.Toggle id="rolesList">
                                          <div className="d-flex align-items-center">
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                                            </svg>
                                            <div className="drop-arrow ml-1 d-flex">
                                              <FiChevronDown />
                                            </div>
                                          </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <>
                                            <div className="gearIconHeader headerTop">
                                              <strong>
                                                Density View Control
                                              </strong>
                                            </div>
                                            {this.state.densityView.map(
                                              (items, index) => (
                                                <div
                                                  className={
                                                    this.state
                                                      .defaultDensityView ===
                                                      items.densityType
                                                      ? "dropdownItem active"
                                                      : "dropdownItem"
                                                  }
                                                  key={items.id}
                                                  onClick={(e: any) =>
                                                    this.handleDensityView(
                                                      items.densityType
                                                    )
                                                  }
                                                >
                                                  {items.name}
                                                  {this.state
                                                    .defaultDensityView ===
                                                    items.densityType && (
                                                      <svg
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 18 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                                          fill="#2CAF92"
                                                        ></path>
                                                      </svg>
                                                    )}
                                                </div>
                                              )
                                            )}
                                            <div className="m-0 dropdown-divider"></div>
                                            <Dropdown.Item
                                              className="saveDefaultBtn"
                                              onClick={() =>
                                                this.saveDefaultView()
                                              }
                                            >
                                              Save as Default View
                                            </Dropdown.Item>
                                          </>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      <div className="single-loader loadingAnimation"></div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          {this.state.showBanner && (
                            <div className="notification-banners red-bg">
                              <div>
                                Reauthentication required {" "}
                                <span className="Bank-Reauth">
                                  <span className="err-icon">
                                    <svg
                                      stroke="currentColor"
                                      fill="#6a6e73"
                                      stroke-width="0"
                                      version="1.1"
                                      viewBox="0 0 16 16"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ marginTop: "-3px" }}
                                    >
                                      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                                      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                                      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                                    </svg>{" "}
                                  </span>
                                  <span className="customToolTipBank">
                                    {errMsg}
                                  </span>
                                </span>
                              </div>
                              {this.state.showReconnectButton &&
                                this.state.hasConnectPermission && (
                                  <button
                                    className="btn btn-primary btn-ghost reauthenticateBtn"
                                    onClick={() => this.bankSyncOnReconnect()}
                                  >
                                    Reauthenticate
                                  </button>
                                )}
                            </div>
                          )}
                          {this.state.accountNameValue !== -1 &&
                            (
                              <div className={this.state.bulkSelect ? "bottom-spacer" : ""}>
                                {!this.state.istableloader && (
                                  <div
                                    className={`${this.state.defaultDensityView} fixHeader100 forBankSyncMainTable tableToggleRow regular banksyncScrollArea bankSyncTableDesign ${this.state.showBanner ? "scroll-height" : ""}`}
                                  >
                                    {this.state.transactionFilterValue === 2 ? (
                                      <BootstrapTable
                                        {...props.baseProps}
                                        keyField="id"
                                        hover
                                        rowEvents={this.rowEvents}
                                        expandRow={expandRow}
                                        defaultSorted={defaultSorted}
                                      />
                                    ) : (
                                      <BootstrapTable
                                        {...props.baseProps}
                                        keyField="id"
                                        hover
                                        rowEvents={this.rowEvents}
                                        expandRow={expandRow}
                                        selectRow={this.state.tableData.length > 0 && selectRow}
                                        defaultSorted={defaultSorted}
                                      />
                                    )}
                                    {(this.state.tableData.length === 0) && (
                                      <div className="BankSyncNoDataFrame">
                                        <div className="FrameText">
                                          <strong>No Data Yet</strong>
                                          <span>Manually upload or configure bank sync connections</span>
                                        </div>
                                        <img src={BankSyncNoDataFrame} alt="No Data yet" />
                                      </div>
                                    )}
                                  </div>

                                )}

                              </div>
                            )}
                        </div>
                      )}
                    </ToolkitProvider>

                    {this.state.istableloader && !this.state.showBanner && (
                      <div className="loader-responsive register">
                        <div className="performanceList">
                          <div className="tableList">
                            <ul>
                              <li className="d-flex">
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div
                className={
                  this.state.bulkSelect
                    ? "bankSync-TabFooter"
                    : "d-none bankSync-TabFooter"
                }
              >
                <div className="bulkSelectFooter">
                  <Form.Check
                    type="checkbox"
                    label=""
                    id="footerBulkSelect"
                    checked={this.state.bulkSelectRow}
                    onClick={(e: any) => this.footerBulkSelectAll(e)}
                  />
                  <span className="shift-selected-count">
                    {this.state.selectedRows.length} Selected
                  </span>
                  <div className="FooterHorLine"></div>
                  <span className="shift-selected-count"> Total: {Utils.currencyFormat(this.state.batchTotal)}</span>
                  <div className="action-group d-flex flex-row mr-auto">
                    {this.state.transactionFilterValue === 1 && (
                      <>
                        {this.state.hasMultipleTxn.length === 0 &&
                          !this.state.hasMultipleAdjustments &&
                          !this.state.isPendingPayment && (
                            <Button
                              disabled={this.state.confirmSpinner}
                              onClick={() => {
                                this.confirmTransation("bulk", "Completed");
                              }}
                            >
                              {this.state.confirmSpinner && (
                                <Spinner
                                  className="mr-2"
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              Confirm
                            </Button>
                          )}
                        <Button
                          className="btn-outline-primary"
                          disabled={this.state.confirmSpinner}
                          onClick={() => {
                            this.saveTransactionStatus("bulk", "Excluded");
                          }}
                        >
                          {this.state.confirmSpinner && (
                            <Spinner
                              className="mr-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Exclude
                        </Button>
                      </>
                    )}
                    {this.state.transactionFilterValue === 3 && (
                      <>
                        <Button
                          className="btn-outline-primary"
                          disabled={this.state.confirmSpinner}
                          onClick={() => {
                            this.saveTransactionStatus("bulk", "Pending");
                          }}
                        >
                          {this.state.confirmSpinner && (
                            <Spinner
                              className="mr-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Unexclude
                        </Button>
                      </>
                    )}
                  </div>
                  <Button
                    className="closeButton btn-ghost-light"
                    onClick={() => this.footerBulkClose()}
                  >
                    &times;
                  </Button>
                </div>
              </div>
              {this.state.isSlideOut && (
                <>
                  <TransactionSlideout
                    {...this.state.slideoutData}
                    hideSlideOut={this.hideSlideOut.bind(this)}
                    OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                    transationSlideoutSave={this.transationSlideoutSave.bind(
                      this
                    )}
                    callFrom="BankSync"
                  />
                </>
              )}
              {this.state.isCCSlideout && (
                <CreditCardSlideout
                  {...this.state.slideoutData}
                  hideCCSlideOut={this.hideSlideOut.bind(this)}
                  transationSlideoutSave={this.transationSlideoutSave.bind(
                    this
                  )}
                  callFrom="BankSync"
                />
              )}
              {this.state.isTransferSlideOut && (
                <TransferSlideout
                  accDetails={this.state.trslideoutData}
                  hidValue={this.state.hidValue}
                  hotelName={this.state.hotelName}
                  uniqueNo={this.state.trUniqueNo}
                  pageType={"BankSyncTransfer"}
                  transferRequest={this.state.transferRequest}
                  {...this.state.trslideoutData}
                  hideTrSlideOut={this.hideTrSlideOut.bind(this)}
                  transationSlideoutSave={this.transationSlideoutSave.bind(
                    this
                  )}
                />
              )}
              {this.state.invoiceSlideout && (
                <InvoiceEntrySlideout
                  {...this.state.invoiceslideoutData}
                  hideSlideOut={this.hideSlideOut.bind(this)}
                  OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                  isFromRepeating={false}
                  callFrom="BankSync"
                  transationSlideoutSave={this.transationSlideoutSave.bind(
                    this
                  )}
                />
              )}
              {this.state.showAdjustmentModal && (
                <AdjustmentModal
                  isAddAdjustment={this.state.showAdjustmentModal}
                  hideAdjustmentModal={this.hideAdjustmentModal.bind(this)}
                  confirmTxData={this.state.confirmTxData}
                  rowEntryType={this.state.rowEntryType}
                  {...this.state.adjustmentData}
                  isAlerted={this.state.isAlerted}
                  UpdateFromAdjustment={this.UpdateFromAdjustment}
                />
              )}
              {this.state.isShowSlideout && (
                <BankAccountSlideout
                  hidValue={this.state.hidValue}
                  hotelName={this.state.hotelName}
                  actionType={"edit"}
                  callFrom={"BankSync"}
                  oprID={this.state.CardUniqueNo}
                  accountName={this.state.bankName}
                  acctType={this.state.acctType}
                  hideSlideOut={this.hideBankSlideOut.bind(this)}
                  accDisplayName={this.state.accountName}
                />
              )}
              {this.state.isCCShowSlideout && (
                <CreditCardAccountSlideout
                  hidValue={this.state.hidValue}
                  hotelName={this.state.hotelName}
                  actionType={"edit"}
                  oprID={this.state.CardUniqueNo}
                  callFrom={"BankSync"}
                  acctType={"CC"}
                  hideCreditCardSlideOut={this.hideCreditCardSlideOut.bind(
                    this
                  )}
                  accDisplayName={this.state.accountName}
                  accountName={this.state.bankName}
                ></CreditCardAccountSlideout>
              )}
              {this.state.isBankWizardShow && (
                <BankSyncWizard
                  showBankSyncWizard={this.showBankSyncWizard}
                  hotelID={'All'}
                  reloadPage={this.reloadPage.bind(this)}
                  callFrom={callingPage}
                  oprID={this.state.CardUniqueNo}
                ></BankSyncWizard>
              )}
              {this.state.showManualUploadModal &&
                <ManualStatementUploadModal
                  {...this.state}
                  show={this.state.showManualUploadModal}
                  toggleManualStatUploadModal={this.toggleManualStatUploadModal.bind(this)} />
              }
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }

  showBankSyncWizard = () => {
    callingPage = "";
    this.setState({ isBankWizardShow: !this.state.isBankWizardShow });
  }

  reloadPage = (mode) => {
    if (mode == 2) {
      let arr: any = {};
      arr.id = this.state.CardUniqueNo;
      arr.status = "active";
      this.handleaccountNameSelectedItem(1, arr);
    }
    else {
      this.setState({ isAfterAddConnection: true });
      this.selectHotelDropDown(this.state.selectedEhid);
    }
  }

  bankSyncOnReconnect = () => {
    callingPage = "BankSync";
    setTimeout(() => {
      this.setState({ isBankWizardShow: !this.state.isBankWizardShow });
    }, 100);
  }
}
