import * as React from "react";
import "@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min";
import "@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.min.css";
import "@boldreports/react-reporting-components/Scripts/bold.reports.react.min";
import { IRolePermissionReport } from "../../../Common/Contracts/IRolePermission";
import { API_ROOT } from "../../../Common/Services/Api-config";
import { RoleSubPermissionSetting } from "../../../Common/Services/RoleSubPermissionSetting";
import { Redirect } from "react-router-dom";
declare let ej: any;
declare let BoldReportViewerComponent: any;

export const ReportViewerPayRegister: React.FC = (props: any) => {
  const [reportParameters, setReportParameters] = React.useState<
    IRolePermissionReport[]
  >([]);



  let parameterSettings = {
    hideParameterBlock: true,
  };

  function onReportPrint(event) {
    event.isStyleLoad = false;
  }
  var exportSettings = {
    exportOptions:
      ej.ReportViewer.ExportOptions.All &
      ~ej.ReportViewer.ExportOptions.CSV &
      ~ej.ReportViewer.ExportOptions.Word &
      ~ej.ReportViewer.ExportOptions.XML & 
      ~ej.ReportViewer.ExportOptions.Html &
      ~ej.ReportViewer.ExportOptions.PPT 
  };

  var toolbarSettings = {
    showToolbar: true,
    items:
      ~ej.ReportViewer.ToolbarItems.FittoPage &
      ~ej.ReportViewer.ToolbarItems.Parameters &
      ~ej.ReportViewer.ToolbarItems.Refresh &
      ~ej.ReportViewer.ToolbarItems.PageSetup
  };
  const getParamValueByParamName=(paramName: string): any=> {
    const valuesArray = decodeURIComponent(props.history.location.search)?.replace('?', '')?.split('&');
    const item = valuesArray?.find(r => r?.includes(paramName));
    return     item ? window.atob(item?.split('=')[1] as any) : "";
  }

  React.useEffect(() => {
    if (!reportParameters || reportParameters.length === 0) {
        let currentDay: any = new Date();
        let currentdate = currentDay.getDate();
        let currentMonth = currentDay.getMonth() + 1;
        let currentYear = currentDay.getFullYear();
        let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;

      let reportData = {
        name: "tenantId",
        value: JSON.parse(localStorage.storage).tenantID,
      };
      let reportData2 = {
        name: "reportId",
        value: "2037",
      };
      let reportData3 = {
        name: "StartDate",
        value: tempDate,
      };
      let reportData4 = {
        name: "EndDate",
        value: tempDate,
      };
      let reportData5 = {
        name: "ReportName",
        value: "Payment Register #1",
      };
      let reportData6 = {
        name: "Hotelid",
        value:  getParamValueByParamName("hotelId") ,
      };
      let reportData7 = {
        name: "BankAccount",
        value: "-1",
      };
      setReportParameters([reportData, reportData2,reportData3,reportData4,reportData5,reportData6,reportData7]);
    }
  }, [reportParameters]);

 

    if (
      reportParameters &&
      reportParameters.length > 0 
    ) {
      return (
        <div id="rolepermissionreportviewer">
<BoldReportViewerComponent
          id="reportviewer-container"
           reportServiceUrl={
          `${API_ROOT.reportingHost}/reportviewer`
          }
          reportPath={JSON.stringify(reportParameters)}
          isResponsive={true}
          parameterSettings={parameterSettings}
          reportPrint={onReportPrint}
          exportSettings={exportSettings}
          toolbarSettings={toolbarSettings}
         
        ></BoldReportViewerComponent>
        </div>
      );
    } else {
    return <div></div>;
  }
};
