import React from "react";
import { Button, Col, Form, Modal,OverlayTrigger, Tooltip } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import _ from "lodash";


export class AddAllowances extends React.Component<any, any> {

    private RecurrencePeriodList = [
        { label: "Every Pay Period", value: "Every Pay Period" },
        { label: "First pay period of the month", value: "First pay period of the month" },
        { label: "Last pay period of the month", value: "Last pay period of the month" },
    ]

    private hotelWiseSttingFormSchema = Joi.object({
        uniqueNo: Joi.allow(""),
        hid: Joi.number()
            .min(1)
            .required()
            .error((errors) => {
                return errors.map((error) => {
                    return { message: "Please Select EHID" };
                });
            }),


        Amount: Joi.number()
            .min(0.00)
            .required()
            .error((errors) => {
                const _errors = errors.map((error) => {
                    if (error?.message) {
                        return;
                    }
                    switch (error.type) {
                        case "number.base":
                            return {
                                message:
                                    "Please Enter Allowance Amount",
                            };
                        case "number.min":
                            return {
                                message:
                                    "Allowance Amount should not be less than 0",
                            };
                    }
                });
                return [_errors[0]];
            }),

        AllowanceName: Joi.string().required().regex(/^[A-Za-z ][A-Za-z \-\_']*$/,"AllowanceName").
        error((errors) => {
            return errors.map((error) => {
                if (error?.message) {
                    return;
                }
                if(error.type === "string.regex.name")
                {
                    return { message: "Allowance Name is not valid" };
                }
                return { message: "Please Enter Allowance Name" };
            });
        }),
        AllowanceTypeID: Joi.required().error((errors) => {
            return errors.map((error) => {
                return { message: "Please Select Allowance Type" };
            });
        }),
        AllowanceType: Joi.string().required().error((errors) => {
            return errors.map((error) => {
                return { message: "Please Select Allowance Type" };
            });
        }),
        Recurrence: Joi.string().required().error((errors) => {
            return errors.map((error) => {
                return { message: "Please Select Recurrence Period" };
            });
        }),
    });

    state: any = {
        hotelWiseSettingFormData: {
            uniqueNo: this.props?.AllowancePayrollRowEdit?.uniqueNo || "",
            hid: +this.props?.AllowancePayrollRowEdit?.hid || 0,
            AllowanceName: this.props?.AllowancePayrollRowEdit?.allowanceName || "",
            AllowanceType: this.props?.AllowancePayrollRowEdit?.allowanceType || "",
            AllowanceTypeID: this.props?.AllowancePayrollRowEdit?.allowanceTypeID || 0,
            Recurrence: this.props?.AllowancePayrollRowEdit?.recurrence || 0,
            Amount: this.props?.AllowancePayrollRowEdit?.amount || "",
        },
        positionList: [],
        errors: {},
        isDataSaving: false,
        isFormNotValid: true,
        inputPosition: 0,
        hotelsLoaded: false,
        hotelList: [],
        allowanceTypeList: []

    };



    componentDidMount = () => {
        this.getHotels();
        if(this.props?.AllowancePayrollRowEdit?.uniqueNo){
            this.validateHotelWiseFormSchema()
        }
    };



    saveAllowanceDetails = () => {
        let request: any = {};
        
        const {hotelWiseSettingFormData}=this.state
        request = {
            ...hotelWiseSettingFormData,
            Hid:hotelWiseSettingFormData?.hid,
            UserUniqueno:this.props?.userUniqueNo,
            Amount:parseFloat(hotelWiseSettingFormData?.Amount),
            UniqueNo:hotelWiseSettingFormData.uniqueNo && hotelWiseSettingFormData?.uniqueNo!=""? parseInt(hotelWiseSettingFormData.uniqueNo):0
        } 

        delete request.hid
        delete request.uniqueNo
        
        
        
        
        this.setState({ isDataSaving: true });
    
     UserManagement.SaveUserAllowance(request)
            .then((result) => {
                this.setState({ isDataSaving: false });
              
                if (result?.result?.message === "Success") {
                    toast.success(
                        this.props?.AllowancePayrollRowEdit?.uniqueNo > 0
                            ? "Payroll Allowance Updated Successfully"
                            : "Payroll Allowance Saved Successfully",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "addDeduction",
                        }
                    );
                    this.hideModal(true);
                } else {
                    Utils.toastError(result?.result?.result, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "addDeduction",
                    });
                }
            })
            .catch((error) => {
                this.setState({ isDataSaving: false });
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "addDeduction",
                });
            });
    };

    decimalValueChange =(event)=>{
        const {name,value}=event.target
        const newValue=Utils.decimalValue(value)
        const {hotelWiseSettingFormData}=this.state
        hotelWiseSettingFormData[name] = newValue;
        this.setState({ hotelWiseSettingFormData },()=>{this.validateHotelWiseFormSchema()});
    }

    onFieldChange = (event, inputPosition) => {
        const { hotelWiseSettingFormData, hotelList } = this.state;
        let value = event.target.value;
        const fieldName = event.target.name;

        if (fieldName === "hid" && this.props?.isPayrollTab) {
            const taxWithholding = hotelList.find(x => x.hid = value).taxWithholding
            hotelWiseSettingFormData.unemployeeState = hotelWiseSettingFormData.unemployeeState || taxWithholding;

        }
        if(fieldName === "AllowanceName")
        {
            // value = value?.toString()?.replace(/[^A-Z \-\_]+/gi, "")?.trimLeft();
            value = value?.toString()?.trimLeft();
            if (value) {
              if (value.indexOf("_") === 0 || value.indexOf("-") === 0) {
                // value = value?.slice(0, 0);
                value = value?.substring(1);
              }
            }
        }



        // if (fieldName === "Amount"  &&
        //     value?.toString()?.split('.')[1]?.length > 2) {
        //     return;
        // }
        if (fieldName == "Amount") {
            value = Utils.removeInvaildCharFromAmount(value, 10);
        }
        hotelWiseSettingFormData[fieldName] = value;
        if (inputPosition > this.state.inputPosition) {
            this.setState({ inputPosition });
        }
        this.setState({ hotelWiseSettingFormData },()=>{this.validateHotelWiseFormSchema()});
    };



    validateHotelWiseFormSchema = () => {
        const valid = Joi.validate(
            this.state.hotelWiseSettingFormData,
            this.hotelWiseSttingFormSchema,
            {
                abortEarly: false,
            }
        );

        const newErrorObject = {};
        if (valid.error) {
            valid.error.details.forEach((err) => {
                newErrorObject[err.path.join(".")] = err.message;
            });
        }

        // if(this.state.hotelWiseSettingFormData)

        console.log({newErrorObject})
        if (Object.keys(newErrorObject).length === 0) {
            this.setState({ isFormNotValid: false });
        } else {
            this.setState({ isFormNotValid: true });
        }
        this.setState({ errors: newErrorObject });
        return newErrorObject;
    };

    validationOnClick = (inputPosition) => {
        if (inputPosition > this.state.inputPosition) {
            this.setState({ inputPosition }, () => this.validateHotelWiseFormSchema());
        }
    };

    getFieldError = (fieldName: string, fieldPosition: number) => {
        const { errors, inputPosition } = this.state;
        return (
            <>
                {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
                    <span className="validation-message">{errors[fieldName]}</span>
                )}
            </>
        );
    };

    hideModal = (isReload = false) => {
        const hotelWiseSettingFormData = {
            uniqueNo: this.props?.AllowancePayrollRowEdit?.uniqueNo || "",
            hid: +this.props?.AllowancePayrollRowEdit?.hid || 0,
            status: this.props?.AllowancePayrollRowEdit?.status || "Active",
            coa: +this.props?.AllowancePayrollRowEdit?.coa || 0,
            regularRate: +this.props?.AllowancePayrollRowEdit?.regularRate || "",
            payRate: this.props?.AllowancePayrollRowEdit?.payRate || "",
            annualSal: this.props?.AllowancePayrollRowEdit?.annualSal,
            terDate: this.props?.AllowancePayrollRowEdit?.terDate,
            unemployeeState:
                this.props?.AllowancePayrollRowEdit?.unemploymentState || "",
            withHoldingState:
                this.props?.AllowancePayrollRowEdit?.withholdingState || "",
            stateFilling:
                this.props?.AllowancePayrollRowEdit?.stateFillingStatus || "",
            empCode: this.props?.AllowancePayrollRowEdit?.empLoyee_Code || "",
        };
        this.props?.hideAllowanceAddEditModalForm(isReload);
        this.setState({ hotelWiseSettingFormData, isFormNotValid: true });
    };

    getHotels = () => {
        const { hotelWiseSettingFormData } = this.state;
        let newHotelList=_.uniq(this.props?.userHotels)
        this.setState({ hotelList: [...newHotelList] });

        if (_.uniq(this.props?.userHotels)?.length === 1) {
            hotelWiseSettingFormData.hid = this.props?.userHotels[0].hotelID;
            this.getAllowanceType(hotelWiseSettingFormData.hid)
           

            this.setState({ hotelWiseSettingFormData, inputPosition: 0 }, () => {
                const positionIds = (this.state?.speacialRates || []).map((item: any) => +item?.positionId);
                if (+hotelWiseSettingFormData?.hid) {

                    this.setState({ positionList: [] });
                    let filterPosition = this.props.positionList.filter(x => +x.hid ===
                        + hotelWiseSettingFormData?.hid && !positionIds?.includes(+x.empCode)).map(x => {

                            x.empCode = x?.empCode?.toString();
                            return x;
                        });


                    this.setState({ positionList: filterPosition?.length > 0 ? filterPosition : [] });
                }




            });
        }


    };


    getAllowanceType = (hid) => {
        UserManagement.GetPayrollAllowancesType(hid).then(res => {
            const result=[...res]
            const currentHIDTableAllowanceTypeID=this.props.allowanceTableData.filter(item => item.hid == hid).map(item => item.allowanceTypeID)
            let newList=result.filter(item => !currentHIDTableAllowanceTypeID.includes(item.uniqueno))
            // if(this.props?.AllowancePayrollRowEdit?.uniqueNo > 0){
            //     const allowanceObj=result.find(res => res.uniqueno === this.props?.AllowancePayrollRowEdit.allowanceTypeID )
            //     newList= allowanceObj ? [...newList,allowanceObj] : newList
            // }
           if(this.props?.AllowancePayrollRowEdit?.uniqueNo > 0)
           {
            newList=result.filter(item => (!currentHIDTableAllowanceTypeID.includes(item.uniqueno)) || this.props?.AllowancePayrollRowEdit?.allowanceTypeID == item.uniqueno)
           }

           let hotelWiseSettingFormData=this.state.hotelWiseSettingFormData
           if(newList.length === 1){
             hotelWiseSettingFormData.AllowanceTypeID=newList[0].uniqueno
             hotelWiseSettingFormData.AllowanceType=newList[0].displayName
           }

         
           let AllowanceTypeID=hotelWiseSettingFormData.AllowanceTypeID
           const AllowanceTypeExist=newList.filter(item => item.uniqueno === AllowanceTypeID)
         
           if(AllowanceTypeExist.length === 0)
           {
            hotelWiseSettingFormData.AllowanceTypeID=0
           }
           this.setState({hotelWiseSettingFormData,allowanceTypeList: [...newList] })
        }).catch(err => {
            this.setState({allowanceTypeList: [] })
        })
    }



    render() {
        const {
            hotelWiseSettingFormData: {
             
                hid,
                AllowanceName,
              
                AllowanceTypeID,
                Recurrence,
                Amount
            },
            hotelList,
           
            errors,
            isDataSaving,
            isFormNotValid,
            inputPosition,
            allowanceTypeList


        } = this.state;
        const { showAllowanceAddEditModal } = this.props;

        // let mode = "Add";
        // if (this.props?.AllowancePayrollRowEdit.uniqueNo > 0) {
        //     mode = "Edit"
        // }

        return (
            <>
                <div className="">

                    <Modal
                        className="add-labor-payroll-allowances-modal"
                        show={showAllowanceAddEditModal}
                        onHide={this.hideModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        keyboard={this.props?.showAllowanceAddEditModal}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props?.AllowancePayrollRowEdit?.uniqueNo > 0
                                    ? "Edit Allowance"
                                    : "Add Allowance"}{" "}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-section">
                                <div className="body-section">
                                    <Form.Group controlId="taxType" className="d-flex">
                                        <Col
                                            sm={"6"}
                                            className={`mb-0 ${errors?.hid && inputPosition >= 1
                                                ? "validation-error"
                                                : ""
                                                }`}
                                            onBlur={() => this.validationOnClick(1)}
                                        >
                                            <Form.Label>EHID</Form.Label>
                                            {hotelList?.length > 0 && (
                                                <DropDownList
                                                    placeHolder={"Select HID"}
                                                    data={hotelList}
                                                    defaultValue={hid}
                                                    isSearchRequired={true}
                                                    label={"lettercode"}
                                                    value={"hotelID"}
                                                    disabled={this.props?.AllowancePayrollRowEdit?.uniqueNo > 0}
                                                    onDropDownChange={(item) => {
                                                        if (!item) {
                                                            return;
                                                        }
                                                        this.onFieldChange(
                                                            Utils.BuildCustomEventObject("hid", item.hotelID),
                                                            1
                                                        );
                                                        this.getAllowanceType(item.hotelID)
                                                    }}
                                                    selectedItem={[
                                                        ...[{ hotelID: 0, lettercode: "Select HID" }],
                                                        ...hotelList,
                                                    ].find((r: any) => r?.hotelID === hid)}
                                                />
                                            )}
                                            {this.getFieldError("hid", 1)}
                                        </Col>

                                        <Col
                                            sm={"6"}
                                            className={`mb-0 ${errors?.AllowanceName && inputPosition >= 2
                                                ? "validation-error"
                                                : ""
                                                }`}
                                            onBlur={() => this.validationOnClick(2)}
                                        >
                                            <Form.Label>Allowance Name</Form.Label>
                                            <OverlayTrigger
                                                placement="bottom"
                                                defaultShow={false}
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id={"AllowanceName"} className={((AllowanceName).length <= 23) ? "tootltip-hide": ""}>
                                                        {(AllowanceName)}
                                                    </Tooltip> 
                                                }
                                                >
                                            <input
                                                maxLength={100}
                                                type="text"
                                                className="form-control"
                                                id="AllowanceName"
                                                name="AllowanceName"
                                                autoComplete="AllowanceName"
                                                value={AllowanceName}
                                                onKeyDown={Utils.allowOnlyAplhabetAndHypenUnderscore}
                                                onChange={(e) => this.onFieldChange(e, 2)}
                                            />
                                                </OverlayTrigger>
                                           
                                            {this.getFieldError("AllowanceName", 3)}
                                        </Col>

                                    </Form.Group>



                                    {hid != 0 && allowanceTypeList.length  >0  &&
                                        <>
                                            <Form.Group controlId="AllowanceType" className="d-flex">
                                                <Col
                                                    sm="6"
                                                    className={`mb-0 ${errors?.AllowanceType && inputPosition >= 3
                                                        ? "validation-error"
                                                        : ""
                                                        }`}
                                                    onBlur={() => this.validationOnClick(3)}
                                                >
                                                    <Form.Label>Allowance Type</Form.Label>
                                                    {allowanceTypeList?.length > 0 && (
                                                        <DropDownList
                                                            dependent={true}
                                                            placeHolder={"Search"}
                                                            data={[...allowanceTypeList]}
                                                            defaultValue={AllowanceTypeID}
                                                            isSearchRequired={true}
                                                            label={"displayName"}
                                                            value={"uniqueno"}
                                                            onDropDownChange={(item) => {
                                                                if (!item) {
                                                                    return;
                                                                }
                                                                this.onFieldChange(
                                                                    Utils.BuildCustomEventObject("AllowanceTypeID", item.uniqueno),
                                                                    3
                                                                );
                                                                this.onFieldChange(
                                                                    Utils.BuildCustomEventObject("AllowanceType", item.displayName),
                                                                    3
                                                                );
                                                            }}
                                                            selectedItem={[
                                                                ...[{ uniqueno: 0, displayName: "Select Allowance Type" }],
                                                                ...allowanceTypeList,
                                                            ].find((r: any) => r?.uniqueno === AllowanceTypeID)}
                                                        />
                                                    )}

                                                    {this.getFieldError("AllowanceType", 3)}
                                                </Col>
                                                <Col
                                                    sm={"6"}
                                                    className={`mb-0 ${errors?.Recurrence && inputPosition >= 4
                                                        ? "validation-error"
                                                        : ""
                                                        }`}
                                                    onBlur={() => this.validationOnClick(4)}
                                                >
                                                    <Form.Label>Recurrence Period</Form.Label>
                                                    {this.RecurrencePeriodList?.length > 0 && (
                                                        <DropDownList
                                                            placeHolder={"Search"}
                                                            data={this.RecurrencePeriodList}
                                                            defaultValue={Recurrence}
                                                            isSearchRequired={false}
                                                            label={"label"}
                                                            value={"value"}
                                                            onDropDownChange={(item) => {
                                                                if (!item) {
                                                                    return;
                                                                }
                                                                this.onFieldChange(
                                                                    Utils.BuildCustomEventObject("Recurrence", item.value),
                                                                    4
                                                                );
                                                            }}
                                                            selectedItem={[
                                                                ...[{ value: 0, label: "Select Recurrence Period" }],
                                                                ...this.RecurrencePeriodList,
                                                            ].find((r: any) => r?.value === Recurrence)}
                                                        />
                                                    )}

                                                    {this.getFieldError("Recurrence", 4)}
                                                </Col>

                                            </Form.Group>


                                            <Form.Group controlId="taxType" className="d-flex">


                                                <Col
                                                    sm={"12"}
                                                    className={`mb-0 ${errors?.Amount && inputPosition >= 5
                                                        ? "validation-error"
                                                        : ""
                                                        }`}
                                                    onBlur={() => this.validationOnClick(5)}
                                                >
                                                    <Form.Label>Allowance Amount</Form.Label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Amount"
                                                        name="Amount"
                                                        placeholder="$0.00"
                                                        autoComplete="Amount"
                                                        onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                                                        value={Amount}
                                                        onChange={(e) => this.onFieldChange(e, 5)}
                                                        onBlur={(e)=> this.decimalValueChange(e)}
                                                    />
                                                    {this.getFieldError("Amount", 5)}
                                                </Col>
                                            </Form.Group>
                                        </>}

                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className="btn-ghost"
                                onClick={() => this.hideModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={isFormNotValid || isDataSaving}
                                className="btn btn-primary"
                                onClick={this.saveAllowanceDetails}
                            >
                                {this.props?.AllowancePayrollRowEdit?.uniqueNo > 0
                                    ? "Edit Allowance"
                                    : "Add Allowance"}{" "}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        );
    }
}
