import * as React from "react";
import { Form } from "react-bootstrap";
import { ModuleReports } from "../../Common/Services/ModuleReports";
import { DropDownMultiList } from "./common-components/dropdown-multi-list";

export class AgingSelector extends React.Component<any, any> {
  private checkBoxRef: any;
  constructor(props: any) {
    super(props);
    this.checkBoxRef = React.createRef();

    this.state = {
      dataSourceList: [{ label: "0 - 30", value: "0 - 30", isChecked: true }, { label: "31 - 60", value: "31 - 60", isChecked: false },{ label: "61 - 90+", value: "61 - 90+", isChecked: false }]
      , defaultValue: "",
    };
  }
  
  componentDidMount = () => {
    const { defaultValue } = this.props;
    let dataSourceList = [...this.state.dataSourceList];
    if (defaultValue === undefined) {
      this.props?.onAgingSelectChange(this.state.dataSourceList);
    } else {
      let groupNames = defaultValue.split(",")?.map(r => r);
      const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
      groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
      dataSourceList = dataSourceList?.map(item => {
        if (groupNames?.includes(item?.label)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      })
      this.setState({ dataSourceList, defaultValue },()=>{
        this.props?.onAgingSelectChange(dataSourceList);
      });
    }
  };

  handleDropdownChange = (e: any) => {
  };

  updateMultiSelectItem = (selectedItem: any) => {
    this.props?.onAgingSelectChange(selectedItem);
  };

  dataChange = () => {
  };

  componentDidUpdate(prevProps, prevState) {
    const { defaultValue } = this.props;
    let dataSourceList = [...this.state.dataSourceList];
    if (prevState.defaultValue !== defaultValue ) {
      let groupNames = defaultValue.toString().split(",")?.map(r => r);
      const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
      groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
      dataSourceList = dataSourceList?.map(item => {
        if (groupNames?.includes(item?.label)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      })
      this.setState({ dataSourceList, defaultValue });
    }
  }

  render() {
    return (
      <div>
        <DropDownMultiList
            defaultValue={this.state?.defaultValue}
            label={"label"}
            value={"value"}
            data={[...this.state.dataSourceList]}
            updateMultiSelectItem={this.updateMultiSelectItem}
            dataChange={this.dataChange.bind(this)}
            isSelectAll={true}
            placeHolder={"Select Aging"}
            filtertitle={"Filter Aging"}
          />
      </div>
    );
  }
}
