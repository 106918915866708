import React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { IGetProceureDetailsRequestDto } from "../../Common/Contracts/IMaintenanceSchedule";
import { FacilityMaintenance } from "../../Common/Services/FacilityMaintenance";
import { toast } from "react-toastify";
import { resolve, reject } from "q";
import _ from "lodash";

import { Utils } from "../../Common/Utilis";

export class AddProcedure extends React.Component<any, any> {
  private inputSearch: any;
  private skipRef: any;
  private skipRefAddproc: any;
  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();
    this.skipRef = React.createRef();
    this.skipRefAddproc = React.createRef();
    this.state = {
      selectedItems: [],
      itemLists: [],
      allActualProceuresList: this.props.parentState.proceduresList,
      allProceuresList: this.props.parentState.proceduresList,
      allPrevProceuresList: this.props.parentState.proceduresList,
      filterData: [],
      opened: false,
      isSelect: true,
      inputValue: "",
      positionID: 0,
      positionName: "",
      isRemove: true,
      isEdit: "",
      isProceureClick: false,
      taskList: [],
      tooltipPosition: [],
      lblGloble:false,
      upatedProcedureList:[],
      isProcedureChanges:false,
      tooltipVisible: false,
      isUpdateList:"",
    };
  }

// componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
//   debugger;
//   let allProceuresList=_.cloneDeep(this.state.allProceuresList);
 
// function customizer(objValue, othValue, key) {
//   debugger;
//   if (key === 'assetName') {
//     return true; // Ignore comparison for the 'name' key
//   }
//   // For other keys, perform default comparison
// }
  
//   let yesCount=allProceuresList.filter(x=> x.isChecked==="yes");

//   var result = _.isEqual(
//     _.omit(prevProps.parentState.isProcedureChanges, ['assetName']),
//     _.omit(yesCount, ['assetName'])
//   );

//  // if(!_.isEqual(prevProps.parentState.tableProceuresData,yesCount,customizer)){
//     // if( prevProps.parentState.isProcedureChanges !=this.state.isProcedureChanges){
//     if(!result){
//     debugger;
//     let upatedProcedureList:any=[];
//     upatedProcedureList= prevProps.parentState.tableProceuresData;
//     let data=upatedProcedureList;
//     let allProceuresListdata = _.cloneDeep([...this.state.allProceuresList]);
//       data.forEach(element => {
//         if (element.isChecked == "yes") {
//         const index = allProceuresListdata.findIndex((xvalue) => xvalue.procedureId === element.procedureId);
//         if(index!==-1){
//           allProceuresListdata[index].isChecked=element.isChecked;
      
//         }
//         }     
//       });
  
//       this.setState({ allProceuresList: allProceuresListdata });
//   }
//   // if (this.props.hotelId !== _.is prevProps.proceduresList && this.props.hotelId > 0) {
//   //   this.getDefaultView();
//   // }


 


// }
  








  componentDidMount() {
    
    this.setState({ itemLists: this.props.parentState.proceduresList });    
  
    let tGlobal=[... this.props.parentState.proceduresList];
   
   let isgloble=tGlobal.filter(x=> x.isGlobal===true);
   if(isgloble.length>0){
    this.setState({lblGloble:true});
   }
    

  }

  discardChanges = () => {   
    this.setState({ allProceuresList: this.props.parentState.proceduresList, });
  }


  updateLists = () => {

    let { filterData,allPrevProceuresList } = this.state;
    //
    if (filterData.length === 0 && this.state.inputValue === "") {
      filterData = allPrevProceuresList;
    }
   

    this.setState({ allProceuresList: filterData });

  };



  handleOutsideClickForProcdure = (e:any) => {
    debugger;
    
    this.setState({ inputValue: "" },()=>{
      this.checkProceureToAdd();
    });
   
  };

  handleChange = (e: any) => {
    //debugger;
    const updatedList = this.state.allPrevProceuresList.filter((item: any) => {
      return (
        item.procedureName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });

    this.setState(
      { filterData: updatedList, inputValue: e.target.value },
      () => {
        this.updateLists();
      }
    );
  };

  

  handleCheckCheckbox = (idx: any, e: any) => {
    //debugger;
    let allProceuresList = [...this.state.allProceuresList];
    let allPrevProceuresList = [...this.state.allPrevProceuresList];
    

    const index = allProceuresList.findIndex((xvalue) => xvalue.procedureId === idx);
    const index2 = allPrevProceuresList.findIndex((xvalue) => xvalue.procedureId === idx);

    // allProceuresList[index].isChecked = e.target.checked;

    // idx = index;
    // this.setState({ itemLists });

    if (e.target.checked === true) {
      allProceuresList[index].isChecked = "yes";
      allPrevProceuresList[index2].isChecked = "yes";
      this.setState({ allProceuresList: allProceuresList,allPrevProceuresList:allPrevProceuresList,isProcedureChanges:true }, () => {
         //this.props.procedureSelection(this.state.allProceuresList,"add");
        // this.props.procedureSelection(allProceuresList[index],"add");
      });

      
    } else {
      
      allProceuresList[index].isChecked = "no";
      allPrevProceuresList[index2].isChecked = "no";
      this.setState({ allProceuresList: allProceuresList,allPrevProceuresList:allPrevProceuresList,isProcedureChanges:true }, () => {
        this.props.procedureSelection(allProceuresList[index].procedureId, "remove");
      });


    }
  };

  

  handleDropdownChange() {
   // debugger;

   // this.props.procedureSelection(this.state.allProceuresList, "add");
   this.props.procedureSelection(this.state.allPrevProceuresList, "add");
    if (this.skipRef?.current && this.skipRef?.current?.ariaExpanded === "true") {
      this.skipRef.current.click()
    }

  }


  GetProcedureTaskList(idx: any) {
    this.setState({ isworkLoader: true });
    let request = {} as IGetProceureDetailsRequestDto;
    request.hotelid = Number(this.props.parentState.hidValue);
    request.type = "AllProceure";
    request.gblId = idx;
    let storage = JSON.parse(localStorage.getItem("storage")!);   
     let userUniqueID = storage === null ? 0 : (storage.user_UniqueID);   
    request.loginUser = Number(userUniqueID);


    FacilityMaintenance.GetProcedureTaskList(request)
      .then(async (result: any | null) => {

        if (result != null && result.length > 0) {

          this.setState({ taskList: result });
        } else {
          this.setState({ taskList: [] });
        }


        resolve();
      })
      .catch((err) => {

        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "AddProcedure",
        });

        reject();
      });
  }


  taskClick = (idx: any, e) => {
    this.setState({ taskList: [] });
    this.GetProcedureTaskList(idx);
    const buttonRect = e.currentTarget.getBoundingClientRect();
    // Calculate the tooltip position based on the button's position
    const tooltipTop = buttonRect.top + buttonRect.height;
    const tooltipLeft = buttonRect.left;
    // let test=[...this.state.tooltipPosition];
    let test: any = {};
    test.x = tooltipTop;
    test.y = tooltipLeft;
    let Data: any = [];
    Data.push(test);
    this.setState({ tooltipPosition: Data, tooltipVisible: true, });
  }

  upatedProcedureList = (tableProceuresData:any) => {   
    //debugger
    this.setState({ upatedProcedureList: tableProceuresData,isUpdateList:"Update"  });
  }
  
  checkProceureToAdd=()=>{
    debugger;
   
    let procdata = _.cloneDeep([...this.state.upatedProcedureList]);
    let allProceuresListdata = _.cloneDeep([...this.state.allActualProceuresList]);
    let allProceuresListdata_final = _.cloneDeep([...this.state.allActualProceuresList]);
    

    allProceuresListdata.forEach(element => {
     

        let procdataindex = procdata.findIndex((xvalue) => xvalue.procedureId === element.procedureId);
        if(procdataindex!==-1){
        let index = allProceuresListdata_final.findIndex((xvalue) => xvalue.procedureId === element.procedureId);
        if(index!==-1){
         
          allProceuresListdata_final[index].isChecked="yes";
        }
      }else{
        let index = allProceuresListdata_final.findIndex((xvalue) => xvalue.procedureId === element.procedureId);
        if(index!==-1){
        
          allProceuresListdata_final[index].isChecked="no";
        }
      }



     
  });




    // procdata.forEach(element => {
    //         if (element.isChecked == "yes") {
    //           let index = allProceuresListdata_final.findIndex((xvalue) => xvalue.procedureId === element.procedureId);
    //           if(index!==-1){
    //             allProceuresListdata_final[index].isChecked="Checkyes";
    //           }
    //         }   
    //     });





  //let data=[...this.state.upatedProcedureList];
  
//   if(procdata.length>0){
//     procdata.forEach(element => {
//       if (element.isChecked == "yes") {
//         let index = allProceuresListdata_final.findIndex((xvalue) => xvalue.procedureId === element.procedureId);
//         if(index!==-1){
//           allProceuresListdata_final[index].isChecked="Checkyes";
//         }
//       }   
//   });
//   allProceuresListdata_final.forEach(element => {
//     if (element.isChecked === "Checkyes") {

//       let index = allProceuresListdata.findIndex((xvalue) => xvalue.procedureId === element.procedureId);
//       if(index!==-1){
//         allProceuresListdata[index].isChecked="yes";
//       }   
//     }else{
//       let index = allProceuresListdata.findIndex((xvalue) => xvalue.procedureId === element.procedureId);
//       if(index!==-1){
//         allProceuresListdata[index].isChecked="no";
//       }   
//     }
//   });



// }else{

//   if(this.state.isUpdateList==="Update"){
//     allProceuresListdata_final.forEach(element => {
//       let index = allProceuresListdata.findIndex((xvalue) => xvalue.procedureId === element.procedureId);
//       if(index!==-1){
//         allProceuresListdata[index].isChecked="no";
//       }   
//   });
//   }

// }

this.setState({allProceuresList:[]},()=>{
  this.setState({ allProceuresList: allProceuresListdata_final,allPrevProceuresList:allProceuresListdata_final });
})
    

  }

   removeTooltip = (event) => {
    
    this.setState({ tooltipVisible: false,});
    // if(type==="OutsideClick"){
    //   this.setState({ inputValue: "" },()=>{
    //        this.checkProceureToAdd();
    //   });
    // }
  }
  onOutsideClick = (event,type:any) => {
    
    this.setState({ tooltipVisible: false,});
    
  }


  render() {
   
   
    
    return (
      <>
        <OutsideClickHandler ref={this.skipRefAddproc} onOutsideClick={(e) => this.onOutsideClick(e,"OutsideClick")}>
          <div className="add-room-dropdown add-procedure"
          // onClick={(e) => { this.handleOutsideClickForProcdure(e) }} 
          >
            <Dropdown onToggle={(e) =>this.handleOutsideClickForProcdure(e)} className="pl-3">
              <DropdownToggle className="add-link Add-procedure_button" ref={this.skipRef}  
              disabled={this.props.disabled}
              >Add Procedure</DropdownToggle>
              <DropdownMenu>
                <div className="coaSearch">
                  <div className="dropDownPanel inner-label">
                    <div className="SearchInput">
                      <input
                        ref={this.inputSearch}
                        key={"text-Filter"}
                        type="text"
                        className="form-control"
                        onChange={this.handleChange}
                        placeholder="Search"
                        value={this.state.inputValue}
                      />
                    </div>
                    <ul onScroll={(e) => this.removeTooltip(e)}>
                      {this.state.allProceuresList.length > 0 && (
                        <div>
                        
                        <div className="dropdown-header divider">{this.state.lblGloble ? "GLOBAL":""}</div>

                          {this.state.allProceuresList.map((item: any, idx: any) => {
                            if (item.isGlobal !== false)
                              return (

                                <div>
                                  
                                  <li
                                    id={item.procedureId}
                                    key={item.procedureId}
                                  // className={
                                  //   item.isconflict === "Yes" ? "disabled" : ""
                                  // }
                                  // onClick={() =>
                                  //   this.handleDropdownChange(item)
                                  // }
                                  
                                  >
                                    <Form.Check
                                      // custom
                                      type="checkbox"
                                      key={"custom-" + item.procedureId}
                                      id={"custom-" + item.procedureId}
                                      checked={item.isChecked !== "no" ? true : false}
                                      onChange={(e: any) =>
                                        this.handleCheckCheckbox(item.procedureId, e)
                                      }
                                    />
                                    <div className="user-details">


                                      <div className="name-position mr-auto">
                                          <div className="name">
                                            <EllipsisWithTooltip placement="bottom">
                                                {item.procedureName}
                                            </EllipsisWithTooltip>
                                          </div>
                                        <EllipsisWithTooltip placement="bottom">
                                          <div className="pos-name">
                                            {item.assetName}, <span
                                              onClick={(e) => { this.taskClick(item.procedureId, e) }} >Task: {item.countTask}</span>
                                          </div>
                                        </EllipsisWithTooltip>
                                      </div>

                                    </div>
                                  </li>
                                </div>
                              );
                          })}
                          <div className="dropdown-header divider">
                            {this.props.parentState.hotelName}
                          </div>
                          {this.state.allProceuresList.map((item: any, idx: any) => {
                            if (item.isGlobal === false)
                              return (
                                <div
                                // data-tip={
                                //   tooltipIncompleteMessage
                                // }
                                >
                                  <li id={item.procedureId} key={item.procedureId} className={"disabled"}
                                  // onClick={() =>
                                  //   this.handleDropdownChange(item)
                                  // }
                                    
                                  >
                                    <Form.Check
                                      // custom
                                      type="checkbox"
                                      key={"custom-" + item.procedureId}
                                      id={"custom-" + item.procedureId}
                                      checked={item.isChecked !== "no" ? true : false}
                                      onChange={(e: any) =>
                                        this.handleCheckCheckbox(item.procedureId, e)
                                      }
                                    />
                                    <div className="user-details">

                                      <div className="name-position mr-auto">
                                        <EllipsisWithTooltip placement="bottom">
                                          <div className="name">
                                            {item.procedureName}
                                          </div>
                                        </EllipsisWithTooltip>
                                        <EllipsisWithTooltip placement="bottom">
                                          <div className="pos-name">
                                            {item.assetName}, <span
                                              onClick={(e) => { this.taskClick(item.procedureId, e) }} >Task: {item.countTask}</span>
                                          </div>
                                        </EllipsisWithTooltip>
                                      </div>

                                    </div>
                                  </li>
                                </div>
                              );
                          })}
                        </div>
                      )}

                      




                    </ul>
                    <div className="footer text-right">
                      <Button
                        onClick={() =>
                          this.handleDropdownChange()
                        }
                        className="select-link">Select</Button>
                    </div>
                  </div>
                </div>
              </DropdownMenu>
            </Dropdown>
            {this.state.taskList.length > 0 && this.state.tooltipVisible && (
                        <OutsideClickHandler onOutsideClick={(e) => this.removeTooltip(e)}>
                          <div className="task-list-wrapper"
                          style={{
                            position: "fixed",
                            top: this.state.tooltipPosition[0]?.x + 11 + "px",
                            left: this.state.tooltipPosition[0]?.y - 100 + "px",
                            zIndex: 1000,
                          }}>
                            <div className="task-list-container" onClick={(e) => e.stopPropagation()}>
                              <div className="wrapper">
                                <h6 className="title">Procedure Tasks:</h6>
                                  {this.state.taskList.map((item: any, idx: any) => {
                                  return (
                                    <div>
                                      
                                      <li id={idx} key={idx} className="item">
                                        <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip> 
                                      </li>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                        </div>
                        </OutsideClickHandler>
                      )}
          </div>
        </OutsideClickHandler>
      </>
    );
  }
}
