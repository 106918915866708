import React from "react";
import { Dropdown, Form, Button, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { resolve, reject } from "q";
import GreyTickMark from "../../../../../Common/Assets/Images/icons/grey_tick_mark.svg";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { IGetHousekeepingBannerDto } from "../../../../../Common/Contracts/ILaborDayApprove";
import { LaborBudgetSetting } from "../../../../../Common/Services/LaborBudgetSetting";
import { Utils } from "../../../../../Common/Utilis";
export class HousekeepingApprovalsDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      positionID: 0,
      hidValue: props.hidValue,
      columnDetails: this.props.columnDetails,
      assigned: this.props.assigned,
      date: this.props.date,
      assignmentsDetails: [],
      UserDetails: [],
      assignmentColumn: [],
      assignmentData: [],
      step: 1,
      employeeOverBudget: [],
      roomDiscrepency: [],
      isChecked: false,
      overBudgetNotes: "",
      overBudgetDetail: [],
      discrepencyNotes: "",
      isEdit: true,
      copied: false,
      copiedRow: false,
      housekeepingBanner: "",
      row:this.props.row ? this.props.row : null,
      multiAddExplanationLoading:true,
      spinnerLoading:true,
      checkDiscrepencyRooms:false,
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleInputValue = this.handleInputValue.bind(this);
    this.onBlurInputValue = this.onBlurInputValue.bind(this);
    this.onFocusInputValue = this.onFocusInputValue.bind(this);
    this.serviceFortmatter = this.serviceFortmatter.bind(this);
  }

  handleBack = () => {
    if(this.state.checkDiscrepencyRooms)
    {
      this.setState({ step: 1 });
    }else{
      this.setState({ step: Number(this.state.step) - 1 });
    }
    
  };

  handleSave = () => {
    
    let saveData: any[] = [];
    let assignmentData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.assignmentData))
    );
    for (let i = 0; i < this.state.UserDetails.length; i++) {
      let userName = this.state.UserDetails[i].userName;
      let userUniqueID = this.state.UserDetails[i].userUniqueID;
      let positionID = this.state.UserDetails[i].positionID;
      let filterService = assignmentData.filter((e) => e.userName === userName);
      for (let j = 0; j < this.state.columnDetails.length; j++) {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let newData: any = {};
        let typeID = this.state.columnDetails[j].typeID;
        newData.tenantID = Number(tenantID);
        newData.hid = Number(this.state.hidValue);
        newData.userName = userName;
        newData.userUniqueID = Number(userUniqueID);
        newData.positionID = positionID;
        newData.typeID = Number(typeID);
        newData.serviceValue = Number(filterService[0][typeID]);
        newData.date = this.state.date;
        saveData.push(newData);
      }
    }
    this.saveRoomAssignmentDetails(saveData);
  };

  approvedUnAssigned() {
    
    let approveDetail: any[] = [];
    let newItem: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID);
    newItem.hid = this.state.hidValue;
    newItem.date = this.state.date;
    newItem.tenantid = tenantID;
    approveDetail.push(newItem);
    LaborDayApprove.LaborRoomApprovedSave(approveDetail)
      .then(async (result: any | null) => {
        
        if (result != null) {
          if (result.message === "Success") {
            toast.success("Housekeeping assignments successfully approved.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            
            this.saveBudgetCalculation(this.state.date,this.state.hidValue);

            setTimeout(() => {
              this.props.refreshData();
            }, 2000);
          } else {
            Utils.toastError(result.result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  saveRoomAssignmentDetails(request: any[]) {
    this.setState({ loaderTrue: true });
    LaborDayApprove.LaborRoomAssignmentSave(request)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.result.message === "saved") {
            this.loadDiscrepencydetails();
          } else {
            Utils.toastError(result.result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  loadAssignmentdetails() {
    
    this.setState({ loaderTrue: true });
    let request = {} as any;
    request.hotelID = this.state.hidValue;
    request.date = this.state.date;
    LaborDayApprove.LaborRoomApproval(request)
      .then(async (result: any[] | null) => {
        if (result != null) {
          if (result.length > 0) {
            let UserDetails: any[] = [];
            result.forEach((element) => {
              let users: any = {};
              users.userName = element.employee;
              users.userUniqueID = element.userUniqueID;
              users.positionID = element.positionID;
              let findUser = UserDetails.filter(
                (e) => e.userName === element.employee
              );
              if (findUser.length === 0) {
                UserDetails.push(users);
              }
            });

            let assignmentData: any[] = [];
            for (let j = 0; j < UserDetails.length; j++) {
              let data: any = {};
              data.userName = UserDetails[j].userName;
              for (let i = 0; i < this.state.columnDetails.length; i++) {


                let userfilter: any[] = [];
                let cellValue: any[] = [];
                userfilter = result.filter(
                  (x) => x.employee === UserDetails[j].userName
                );
                cellValue = userfilter.filter(
                  (x) => x.typeID === this.state.columnDetails[i].typeID
                );
                if(cellValue.length>0)
                {
                data[this.state.columnDetails[i].typeID] =
                  cellValue[0].serviceValue;
                data.totalAssigned = cellValue[0].totalAssign;
                data.totalTime = cellValue[0].totalTime;
                data.empStatus = cellValue[0].empStatus;
                }
                else
                {
                  data.totalAssigned =0;
                }
              }
              assignmentData.push(data);
            }
            this.setState({
              isEdit: result[0].isEdit.toLowerCase() === "yes" ? true : false,
            });
            this.setState({ assignmentsDetails: result });
            this.setState({ UserDetails: UserDetails });
            this.setState({ assignmentData: assignmentData });
            this.setState({ loaderTrue: false });
          }
        }

        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  componentDidMount() {
    this.loadAssignmentdetails();
    this.getHousekeepingBanner();
  }

  getHousekeepingBanner = () => {
    let request = {} as IGetHousekeepingBannerDto;

    request.hotelId = this.state.hidValue;
    request.date = this.state.date;

    LaborDayApprove.GetHousekeepingBanner(request)
      .then(async (result: any) => {
        if (result.saveStatus === "Success") {
          let apiMessage = result?.message.split(",");

          let housekeepingBanner = `Daily activity was edited by ${apiMessage[1]} on ${apiMessage[0]} and
    		        requires housekeeping re-approval for this day.`;

          this.setState({ housekeepingBanner });
        } else {
          this.setState({ housekeepingBanner: "" });
        }
      })
      .catch((err) => {});
  };

  loadDiscrepencydetails() {
    let request = {} as any;
    request.hotelID = this.state.hidValue;
    request.date = this.state.date;
    LaborDayApprove.LaborRoomDiscrepency(request).then(
      async (result: any[] | null) => {
        if (result != null) {
          if (result.length > 0) {
            let data = Object.assign([], JSON.parse(JSON.stringify(result)));
            data.map((item: any) => {
              item.discrepencyNotes = item.notes;
            });
            this.setState({ roomDiscrepency: data });
            if (data[0].diff === 0) {
              this.setState({checkDiscrepencyRooms:true})
              this.loadOverBudgetdetails(true);
            } else {
              this.setState({ step: 2 });
            }
          } else {
            this.loadOverBudgetdetails(true);
          }
        }
      }
    );
  }

  autoSubmitTotalSaveData =()=>{
    let overBudgetDetail: any[] = [];
    let array = this.state.employeeOverBudget
    array.map((item: any) => {
    //  debugger;
      let newItem: any = {};
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID);
      newItem.hid = this.state.hidValue;
      newItem.date = this.state.date;
      newItem.tenantid = tenantID;
      
      let comment = this.state.overBudgetDetail.filter((item1: any) => {
        return (item1.userUniqueID===item.userUniqueID)
      });  
      if(comment.length > 0){
        newItem.notes = comment[0].notes;
       }else{         
        newItem.notes = this.state.overBudgetNotes;
       }

      newItem.positionID = this.state.positionID;
      newItem.userUniqueID = item.userUniqueID;
      newItem.discrepencyNotes = this.state.discrepencyNotes;
      overBudgetDetail.push(newItem);
    });
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.employeeOverBudget))
    );
   
    this.setState({
      employeeOverBudget: data,
      overBudgetDetail: overBudgetDetail,
    });

    this.updateoverBudgetDetail(overBudgetDetail);
  }

  loadOverBudgetdetails(isApproved: any) {
    let request = {} as any;
    request.hotelID = this.state.hidValue;
    request.date = this.state.date;
    LaborDayApprove.LaborEmployeeOverBudget(request).then(
      async (result: any[] | null) => {
        if (result != null) {
          debugger;
          if (result.length > 0) {
            let data:any[] = [];
            data = Object.assign([], JSON.parse(JSON.stringify(result)));
            data.map((item: any) => {
              item.overBudgetNotes = item.notes;
              item.isChecked = false;
              item.isNotes = false;
              item.copiedRow = false;
            });

            const isAutoSave=!(data.filter(item => item?.explainationRequired == 1).length > 0)
            this.setState({
              employeeOverBudget: data,
              step:3,
              isChecked: false,
            }, ()=>{
              if(isAutoSave)
              {
                this.autoSubmitTotalSaveData()
              }
            });
          } else {
            if (isApproved) {
              this.approvedUnAssigned();
            } else {
              this.saveDiscrepancyDetails();
            }
          }
        }
      }
    );
  }

  saveDiscrepancyDetails() {
    
    let overBudgetDetail: any[] = [];
    let newItem: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID);
    newItem.hid = this.state.hidValue;
    newItem.date = this.state.date;
    newItem.tenantid = tenantID;
    newItem.positionID = this.state.positionID;
    newItem.discrepencyNotes = this.state.discrepencyNotes;
    overBudgetDetail.push(newItem);
    this.setState({ loaderTrue: true });
    LaborDayApprove.LaborDiscrepancyWithSave(overBudgetDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            this.setState({ overBudgetDetail: [] });
            toast.success("Housekeeping assignments successfully approved.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });

            this.saveBudgetCalculation(this.state.date,this.state.hidValue);
            


            setTimeout(() => {
              this.props.refreshData();
            }, 2000);
          } else {
            Utils.toastError(result.result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  handleInputValue = (
    e: any,
    row: any,
    rowIndex: any,
    typeID: any,
    txtID: any
  ) => {
    let val = e.target.value;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.assignmentData))
    );

    if (/^\d+$/.test(val) === false) {
      val = val.replace(/[^\d]/g, "");
    }
    if (val.length > 3) {
      let a = val.replace(/[^\d]/g, "");
      val = a.substring(0, val.length - 1);
    }

    data[rowIndex][typeID] = val;

    this.setState({ assignmentData: data }, () => {
      $("#tblHousekeeping")
        .find("input[id^='" + txtID + "']")
        .focus();
    });
  };

  onBlurInputValue(e: any, row: any, rowIndex: any, typeID: any, txtID: any) {
    let newValue = e.target.value;

    if (newValue === "") {
      newValue = 0;
    } else if (/^\d+$/.test(newValue) === false) {
      newValue = 0;
    }

    let totalAssign = 0;
    let totalMin = 0;
    for (let i = 0; i < this.state.columnDetails.length; i++) {
      if (
        this.state.columnDetails[i].servicename.toString().toLowerCase() !==
        "denied"
      ) {
        totalAssign =
          totalAssign + Number(row[this.state.columnDetails[i].typeID]);
        totalMin =
          totalMin +
          Number(row[this.state.columnDetails[i].typeID]) *
            Number(this.state.columnDetails[i].mporValue);
      }
    }
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.assignmentData))
    );
    let index = data.findIndex((e) => e.userName === row.userName);
    let colName = typeID;
    data[index][colName] = newValue === "" ? 0 : newValue;
    data[index].totalAssigned = totalAssign;
    data[index].totalTime = this.FormatHoursMinutes(totalMin);
    this.setState({ assignmentData: data });
    $("#tblHousekeeping")
      .find("input[id^='" + txtID + "']")
      .closest("tr")
      .removeClass("focus-row");
  }

  onFocusInputValue(e: any, row: any, rowIndex: any, typeID: any, txtID: any) {
    $("#tblHousekeeping")
      .find("input[id^='" + txtID + "']")
      .addClass("focus");
    $("#tblHousekeeping")
      .find("input[id^='" + txtID + "']")
      .closest("tr")
      .addClass("focus-row");
  }

  serviceFortmatter(cell: any, row: any, rowIndex: any, columnName: any) {
    
    let txtID = "";
    let typeID = columnName.split("_")[1];
    txtID = "text" + rowIndex.toString() + typeID.toString();

    if (cell === undefined) {
      cell=0;
    }
    return (
      <div className={"forecast-name tab-key"}>
        <Form.Group>
          <Form.Control
            disabled={row.empStatus === "Deleted"}
            maxLength={3}
            type="text"
            key={txtID}
            id={txtID}
            onChange={(e: any) =>
              this.handleInputValue(e, row, rowIndex, typeID, txtID)
            }
            onBlur={(e: any) =>
              this.onBlurInputValue(e, row, rowIndex, typeID, txtID)
            }
            onSelect={(e: any) =>
              this.onFocusInputValue(e, row, rowIndex, typeID, txtID)
            }
            value={cell}
            autoComplete="off"
          />
        </Form.Group>
      </div>
    );
  }

  EmployeeNameFormatter(cell: any, row: any, rowIndex: any, cellIndex) {
    
    return (
      <div className="d-flex">
        <EllipsisWithTooltip placement="bottom">
          {row.userName}
        </EllipsisWithTooltip>

        {row?.empStatus === "Deleted" && (
          <span className="red-badges ml-2">Deleted</span>
        )}
      </div>
    );
  }
  actionFortmatter(cell: any, row: any, rowIndex: any, cellIndex) {
    return (
      <Dropdown className="more-action" alignRight>
        <Dropdown.Toggle
          className="btn-outline-primary btn btn-primary more"
          id="dropdown-more"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
          </svg>
        </Dropdown.Toggle>
      </Dropdown>
    );
  }

  cellEdit = cellEditFactory({
    mode: "click",
    blurToSave: true,
    afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
      let totalAssign = 0;
      let totalMin = 0;
      for (let i = 0; i < this.state.columnDetails.length; i++) {
        if (
          this.state.columnDetails[i].servicename.toString().toLowerCase() !==
          "denied"
        ) {
          totalAssign =
            totalAssign + Number(row[this.state.columnDetails[i].typeID]);
          totalMin =
            totalMin +
            Number(row[this.state.columnDetails[i].typeID]) *
              Number(this.state.columnDetails[i].mporValue);
        }
      }
      let data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.assignmentData))
      );
      let index = data.findIndex((e) => e.userName === row.userName);
      let colName = column.dataField;
      data[index][colName] = newValue === "" ? 0 : newValue;
      data[index].totalAssigned = totalAssign;
      data[index].totalTime = this.FormatHoursMinutes(totalMin);
      this.setState({ assignmentData: data });
    },
  });

  totalTimeFooter = (columnData: any) => {
    let total = 0;
    columnData.map((data: any) => {
      if (data !== null) {
        if (data !== undefined) {
          if (data.trim().length > 0) {
            if (data.includes(":")) {
              let subTotal = 0;
              const dataArray = data.split(":");
              let HH = Number(dataArray[0]);
              let MM = Number(dataArray[1]);
              if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                HH = HH < 0 ? HH * -1 : HH;
                MM = MM < 0 ? MM * -1 : MM;
                subTotal = (HH * 60 + MM) * -1;
              } else {
                subTotal = HH * 60 + MM;
              }
              total = total + subTotal;
            }
          }
        }
      }
    });
    return this.FormatHoursMinutes(total);
  };

  FormatHoursMinutes(HoursMints: number) {
    if (HoursMints < 0) {
      HoursMints = HoursMints * -1;
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${"-"}${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    } else {
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    }
  }

  rowEvents = {
    onBlur: (e: { target: { value: string | number } }) => {
      if (e.target.value === "") {
        e.target.value = 0;
      }
    },
    onChange: (e: { target: { value: any } }, cell: any) => {
      let evalue = e.target.value;
      if (evalue === "") {
      } else if (/^\d+$/.test(evalue) === false) {
        e.target.value = 0;
      } else {
        e.target.value = evalue;
      }
    },
  };

  discrepencyNotesChange(e: any, row: any, rowIndex: any) {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.roomDiscrepency))
    );
    data[rowIndex].discrepencyNotes = e.target.value;
    this.setState({ roomDiscrepency: data });
  }

  onOutsideClick = (e, row, rowIndex) => {
    let data = this.state.roomDiscrepency;
    data[rowIndex].discrepencyNotes = "";
    this.setState({ roomDiscrepency: [] }, () => {
      this.setState({ roomDiscrepency: data });
    });
  };

  onOutsideemployeeOverBudgetClick = (e, row, rowIndex) => {
    let data = this.state.employeeOverBudget;
    data[rowIndex].overBudgetNotes = "";
    data[rowIndex].copiedRow = false;
    this.setState({ employeeOverBudget: [] }, () => {
      this.setState({
        employeeOverBudget: data,
        copied: false,
        copiedRow: false,
      });
    });
  };

  overBudgetNotesChange(e: any, row: any, rowIndex: any) {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.employeeOverBudget))
    );
    let newIndex = 0;
    data.forEach((element, index) => {
      if (element.userUniqueID === row.userUniqueID) {
        newIndex = index;
      }
    });
    data[newIndex].overBudgetNotes = e.target.value;
    this.setState({ employeeOverBudget: data, copiedRow: false });
  }

  onDiscrepencySubmit(e: any, row: any, rowIndex: any) {
    this.setState({spinnerLoading : true})
    if (row.discrepencyNotes.trim().length > 0) {
      this.setState({ discrepencyNotes: row.discrepencyNotes.trim() });
      setTimeout(() => {
        this.loadOverBudgetdetails(false);
      }, 100);
      
    } else {
      Utils.toastError("Please add explanation", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    this.setState({spinnerLoading : false})
  }

  onOverBudgetSubmit(e: any, row: any, rowIndex: any) {
    this.setState({spinnerLoading : true})
    setTimeout(() => {
      if (row.overBudgetNotes.trim().length > 0) {
        let overBudgetDetail: any[] = [];
        overBudgetDetail = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.overBudgetDetail))
        );
        let newItem: any = {};
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        newItem.hid = this.state.hidValue;
        newItem.date = this.state.date;
        newItem.tenantid = tenantID;
        newItem.notes = row.overBudgetNotes;
        newItem.positionID = this.state.positionID;
        newItem.userUniqueID = row.userUniqueID;
        newItem.discrepencyNotes = this.state.discrepencyNotes;
        overBudgetDetail.push(newItem);
        let data = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.employeeOverBudget))
        );

        data.map((x: any) => {
          if(x.explainationRequired !=1)
          {
           x.overBudgetNotes = "";
           x.isNotes = true;
          }
         });
 
 
        let index = this.state.employeeOverBudget.indexOf(row);
        data[index].overBudgetNotes = "";
        data[index].isNotes = true;
        this.setState({
          employeeOverBudget: data,
          overBudgetDetail: overBudgetDetail,
        });
        if (
          data.filter((row: { isNotes: boolean }) => row.isNotes === false)
            .length === 0
        ) {
         
          this.updateoverBudgetDetail(overBudgetDetail);
        } else {
          toast.success("Housekeeping assignments successfully approved.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } else {
        Utils.toastError("Please add explanation", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      this.setState({spinnerLoading : false})
    }, 100);
    //
   
  }

  onSubmitClick(e: any) {
   this.setState({spinnerLoading : true})
    if (this.state.overBudgetNotes.trim().length > 0) {
      let overBudgetDetail: any[] = [];
      let array = this.state.employeeOverBudget.filter(
        (row: { isChecked: boolean }) => row.isChecked === true
      );
      array.map((item: any) => {
        let newItem: any = {};
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID);
        newItem.hid = this.state.hidValue;
        newItem.date = this.state.date;
        newItem.tenantid = tenantID;
        
        let comment = this.state.overBudgetDetail.filter((item1: any) => {
          return (item1.userUniqueID===item.userUniqueID)
        });  
        if(comment.length > 0){
          newItem.notes = comment[0].notes;
         }else{         
          newItem.notes = this.state.overBudgetNotes;
         }

        newItem.positionID = this.state.positionID;
        newItem.userUniqueID = item.userUniqueID;
        newItem.discrepencyNotes = this.state.discrepencyNotes;
        overBudgetDetail.push(newItem);
      });
      let data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.employeeOverBudget))
      );
      data.map((item: any) => {
        if (item.isChecked) {
          item.isNotes = true;
        }
      });
      this.setState({
        employeeOverBudget: data,
        overBudgetDetail: overBudgetDetail,
      });
      if (
        data.filter((row: { isNotes: boolean }) => row.isNotes === false)
          .length === 0
      ) {
        this.updateoverBudgetDetail(overBudgetDetail);
      }
      else{
        this.setState({multiAddExplanationLoading:false},()=>{
          this.setState({multiAddExplanationLoading:true})
        })
        this.onCancelClick(e);
      }
    } else {
      Utils.toastError("Please add explanation", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    this.setState({spinnerLoading : false})
  }

  updateoverBudgetDetail(overBudgetDetail: any[]) {
    setTimeout(() => {
      this.setState({ loaderTrue: true });
    LaborDayApprove.LaborRooomTotalSave(overBudgetDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            this.setState({ overBudgetDetail: [] });
            toast.success("Housekeeping assignments successfully approved.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            
                 
            this.saveBudgetCalculation(this.state.date,this.state.hidValue);     



            setTimeout(() => {
              this.props.refreshData();
            }, 2000);
          } else {
            Utils.toastError(result.result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
    }, 100);
    
  }

  headerFormat = (column: any, colIndex: any) => {
    if (this.state.isChecked) {
      return (
        <div className="headerApproveDropDown" id="header-add-explanation">
          <Dropdown className="more-action" alignRight>
            <Dropdown.Toggle
              className="btn-outline-primary overBudgetApprove more flayout-btn"
              id="dropdown-more"
            >
              Add Explanation
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="flayoutApproveMenu">
                <div className="approveDepartmentDetails">
                  <div className="entry-body">
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <div className="copytoClipBoardPanel">
                        <Form.Label>Explanation</Form.Label>
                        <div className="copytoClipBoard">
                          {this.state.overBudgetNotes.trim().length > 0 && (
                            <React.Fragment>
                              {this.state.copied ? <span>Copied.</span> : null}
                              <CopyToClipboard
                                text={this.state.overBudgetNotes}
                                onCopy={() =>
                                  this.updateCopyState("header", "")
                                }
                              >
                                <Link to="#">
                                  <svg
                                    stroke="currentColor"
                                    fill="#2caf92"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M20,2H10C8.897,2,8,2.897,8,4v4H4c-1.103,0-2,0.897-2,2v10c0,1.103,0.897,2,2,2h10c1.103,0,2-0.897,2-2v-4h4 c1.103,0,2-0.897,2-2V4C22,2.897,21.103,2,20,2z M4,20V10h10l0.002,10H4z M20,14h-4v-4c0-1.103-0.897-2-2-2h-4V4h10V14z"></path>
                                    <path d="M6 12H12V14H6zM6 16H12V18H6z"></path>
                                  </svg>
                                </Link>
                              </CopyToClipboard>
                            </React.Fragment>
                          )}
                        </div>
                      </div>

                      <Form.Control
                        as="textarea"
                        value={this.state.overBudgetNotes}
                        onChange={(e: any) => this.overExplanationChange(e)}
                        placeholder="Describe the reason for the budget overage"
                        rows={3}
                        maxLength={200}
                      />
                    </Form.Group>
                  </div>
                  <div className="action-link">
                    <Dropdown.Item
                      className="btn wht-bg dropdown-item cancel"
                      onClick={(e: any) => {
                        this.onCancelClick(e);
                      }}
                    >
                      Cancel
                    </Dropdown.Item>
                    <Button
                      className={`btn btn-primary dropdown-item submit-btn`}
                      onClick={(e: any) => {
                        this.onSubmitClick(e);
                      }}
                    >
                      Submit {this.state.spinnerLoading ? <Spinner animation="border" size="sm" /> : <></>}
                    </Button>
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return <span className="overBudgetDepartment">{column.text}</span>;
    }
  };

  selectRow = (selectedRow: any, isSelect: boolean, rowIndex: any, e: any) => {
    let isChecked = false;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.employeeOverBudget))
    );

    let newIndex = 0;
    data.forEach((element, index) => {
      if (element.userUniqueID === selectedRow.userUniqueID) {
        newIndex = index;
      }
    });

    data[newIndex].isChecked = isSelect;
    this.setState({ employeeOverBudget: data });
    data.map((item: any) => {
      if (item.isChecked) {
        isChecked = true;
        return;
      }
    });
   debugger
    let isMultiCheck=(data.filter(item => item?.isChecked == true).length > 1)
    if(isMultiCheck){
      this.setState({ isChecked: isChecked });
    }else{
      this.setState({ isChecked: false });
    }
   // this.setState({ isChecked: isChecked });
  };

  selectRowAll = (isSelect: boolean, rows: any, e: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.employeeOverBudget))
    );
    data.map((item: any) => {
      item.isChecked = isSelect;
    });
    this.setState({ employeeOverBudget: data, isChecked: isSelect });
  };

  overExplanationChange(e: any) {
    this.setState({ overBudgetNotes: e.target.value, copied: false });
  }

  onCancelClick(e: any) {
    this.setState({ overBudgetNotes: "", copied: false });
  }

  updateCopyState = (clickFrom: any, rowIndex: any) => {
    if (clickFrom === "header") {
      if (this.state.overBudgetNotes.trim().length > 0) {
        this.setState({ copied: true });
        setTimeout(() => {
          this.setState({ copied: false });
        }, 2000);
      }
    } else {
      if (clickFrom.overBudgetNotes.trim().length > 0) {
        let data = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.employeeOverBudget))
        );
        data[rowIndex].copiedRow = true;
        this.setState({ employeeOverBudget: data, copiedRow: true });

        setTimeout(() => {
          this.hideCopiedText(rowIndex);
        }, 2000);
      }
    }
  };

  hideCopiedText = (rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.employeeOverBudget))
    );
    data[rowIndex].copiedRow = false;
    this.setState({ employeeOverBudget: data, copiedRow: false });
  };

  saveBudgetCalculation=(date: any,hidValue :any)=>{
    let request: any = {};
    let currentDate: any = null;
    currentDate = date;
    request.hotelID = hidValue;
    request.layout = "2";
    request.date = currentDate;
    request.type = "Housekeeping";
    LaborBudgetSetting.SaveBudgetCalculation(request)
      .then(async (result: any | null) => {})
      .catch((err: any) => {});
  }
  

  render() {
    const selectRow = {
      mode: "checkbox",
      classes: "selection-row",
      clickToExpand: true,
      selected: this.state.preSelectedRows,
      onSelect: (
        selectedRow: any,
        isSelect: boolean,
        rowIndex: any,
        e: any
      ) => {
        this.selectRow(selectedRow, isSelect, rowIndex, e);
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        this.selectRowAll(isSelect, rows, e);
      },
    };
    let column: any[] = [];
    let col: any = {};
    col.dataField = "userName";
    col.text = "Username";
    col.footer = "Totals";
    col.editable = false;
    col.formatter = this.EmployeeNameFormatter;
    column.push(col);
    for (let i = 0; i < this.state.columnDetails.length; i++) {
      
      col = {};
      col.dataField = this.state.columnDetails[i].typeID;
      col.text = this.state.columnDetails[i].servicename;
      col.editable = true;
      col.formatter = this.serviceFortmatter;
      col.footer = (columnData) =>
        columnData.reduce((acc, item) => Number(acc) + Number(item), 0);
      col.formatExtraData = i + 1 + "_" + this.state.columnDetails[i].typeID;
      column.push(col);
    }

    col = {};
    col.dataField = "totalAssigned";
    col.text = "Total Assigned";
    col.editable = false;
    col.footer = (columnData) =>
      columnData.reduce((acc, item) => Number(acc) + Number(item), 0);
    column.push(col);

    col = {};
    col.dataField = "totalTime";
    col.editable = false;
    col.text = "Total Time";
    col.footer = this.totalTimeFooter;
    column.push(col);

    col = {};
    col.dataField = "";
    col.editable = false;
    col.text = "";
    col.footer = "";
    col.formatter = this.actionFortmatter;


    const rowClassesStep1 = (row, rowIndex) => {
      let classes: string | null=null;
    
      if (row.empStatus === "Deleted") {
        classes = 'hsk-deleted-row';
      }
    
      return classes;
    };

    const columnDiscrepency = [
      {
        dataField: "totalOccupied",
        text: "Total Occupied",
      },
      {
        dataField: "totalDenied",
        text: "Total Denied",
      },
      {
        dataField: "totalClean",
        text: "Total Cleaned",
      },
      {
        dataField: "diff",
        text: "Difference",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={row.diff > 0 ? "red-txt" : ""}>{row.diff}</div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="d-flex justify-content-end">
            <Dropdown className="more-action" alignRight>
              <Dropdown.Toggle
                className="btn-outline-primary btn btn-primary more flayout-btn"
                id="dropdown-more"
              >
                Add Explanation
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="flayoutApproveMenu">
                  <div className="approveDepartmentDetails">
                    <div className="entry-body">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Explanation</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={row.discrepencyNotes}
                          onChange={(e: any) =>
                            this.discrepencyNotesChange(e, row, rowIndex)
                          }
                          placeholder="Describe the discrepancy"
                          rows={3}
                          maxLength={200}
                        />
                      </Form.Group>
                    </div>
                    <div className="action-link">
                      <Dropdown.Item
                        className="btn wht-bg dropdown-item cancel"
                        onClick={(e) => {
                          this.onOutsideClick(e, row, rowIndex);
                        }}
                      >
                        Cancel
                      </Dropdown.Item>
                      <Button
                        className={`btn btn-primary dropdown-item submit-btn`}
                        onClick={(e) => {
                          this.onDiscrepencySubmit(e, row, rowIndex);
                        }}
                      >
                        Submit  {this.state.spinnerLoading ? <Spinner animation="border" size="sm" /> : <></>}
                      </Button>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      },
    ];

    const columnOverBudget = [
      {
        dataField: "employee",
        text: "Username",
       // headerFormatter: this.headerFormat,
        formatter: (cell: any, row: any, rowIndex: any) => {
          
          return (
            <div className="d-flex">
              <EllipsisWithTooltip placement="bottom">
                {row.employee}
              </EllipsisWithTooltip>
      
              {row?.shiftStatus === "NoShow" && (
                <span className="red-badges ml-2">No Show</span>
              )}
            </div>
          );
        }
      },
      {
        dataField: "totalAssign",
        text: "Total Assigned",
      },
      {
        dataField: "budget",
        text: "Budget Total",
      },
      {
        dataField: "actualHrs",
        text: "Actual Total",
      },
      {
        dataField: "",
        text: "",
        headerFormatter: this.headerFormat,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="d-flex justify-content-end">
            <Dropdown className="more-action" alignRight>
              <Dropdown.Toggle
                className="btn-outline-primary btn btn-primary more flayout-btn"
                id="dropdown-more"
              >
                Add Explanation
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="flayoutApproveMenu">
                  <div className="approveDepartmentDetails">
                    <div className="entry-body">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <div className="copytoClipBoardPanel">
                          <Form.Label>Explanation</Form.Label>
                          <div className="copytoClipBoard">
                            {row.overBudgetNotes.trim().length > 0 && (
                              <React.Fragment>
                                {row.copiedRow && this.state.copiedRow && (
                                  <span>Copied.</span>
                                )}

                                <CopyToClipboard
                                  text={row.overBudgetNotes}
                                  onCopy={() =>
                                    this.updateCopyState(row, rowIndex)
                                  }
                                >
                                  <Link to="#">
                                    <svg
                                      stroke="currentColor"
                                      fill="#2caf92"
                                      stroke-width="0"
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M20,2H10C8.897,2,8,2.897,8,4v4H4c-1.103,0-2,0.897-2,2v10c0,1.103,0.897,2,2,2h10c1.103,0,2-0.897,2-2v-4h4 c1.103,0,2-0.897,2-2V4C22,2.897,21.103,2,20,2z M4,20V10h10l0.002,10H4z M20,14h-4v-4c0-1.103-0.897-2-2-2h-4V4h10V14z"></path>
                                      <path d="M6 12H12V14H6zM6 16H12V18H6z"></path>
                                    </svg>
                                  </Link>
                                </CopyToClipboard>
                              </React.Fragment>
                            )}
                          </div>
                        </div>

                        <Form.Control
                          as="textarea"
                          value={row.overBudgetNotes}
                          onChange={(e: any) =>
                            this.overBudgetNotesChange(e, row, rowIndex)
                          }
                          placeholder="Describe the reason for the budget overage"
                          rows={3}
                          maxLength={200}
                        />
                      </Form.Group>
                    </div>
                    <div className="action-link">
                      <Dropdown.Item
                        className="btn wht-bg dropdown-item cancel"
                        onClick={(e) => {
                          this.onOutsideemployeeOverBudgetClick(
                            e,
                            row,
                            rowIndex
                          );
                        }}
                      >
                        Cancel
                      </Dropdown.Item>
                      <Button
                        className={`btn btn-primary dropdown-item submit-btn`}
                        onClick={(e) => {
                          this.onOverBudgetSubmit(e, row, rowIndex);
                        }}
                      >
                        Submit  {this.state.spinnerLoading ? <Spinner animation="border" size="sm" /> :<></>}
                      </Button>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      },
    ];

    let buttonDisabled = false;
    if(this.state.row !== null){
      const {row } = this.state;
      if(row.occupiedRoom === "" || row.occupiedRoom === null){
        buttonDisabled = true
      }
    }

    const renderTooltip = (props) => {
      return (
        <Tooltip id="tooltip-top" {...props}>
          Occupied Rooms can't be blank.
        </Tooltip>
      );
    };

    const renderhideTooltip = (props) => {
      return <div id="tooltip-bottom" {...props}></div>;
    };
    return (
      <>
        {/* <ToastContainer autoClose={3000} /> */}
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"roomsAssignedExpand"} />
        ) : (
          <div className="laborDayApprovalDetails roomAssignmentsDetails">
            <div className="laborDayNavigation">
              <div className="labordayNavigationlabels">
                {this.state.step === 1 && (
                  <>
                    <span className="labordayNavigationSpan active">
                      <span className="labordayNavigationNumber">1</span>
                      <span className="labordayNavigationLabel">
                        Assignments
                      </span>
                    </span>
                    <span className="laborDayApprovalDesign"></span>
                    <span className="labordayNavigationSpan">
                      <span className="labordayNavigationNumber">2</span>
                      <span className="labordayNavigationLabel">
                        Total Discrepancies
                      </span>
                    </span>
                    <span className="laborDayApprovalDesign"></span>
                    <span className="labordayNavigationSpan">
                      <span className="labordayNavigationNumber">3</span>
                      <span className="labordayNavigationLabel">
                      Employee Performance
                      </span>
                    </span>
                  </>
                )}
                {this.state.step === 2 && (
                  <>
                    <span className="labordayNavigationSpan disable">
                      <span className="labordayNavigationNumber">
                        <span className="tickMark">
                          <img src={GreyTickMark} alt="GreyTickMark" />
                        </span>
                      </span>
                      <span className="labordayNavigationLabel">
                        Assignments
                      </span>
                    </span>
                    <span className="laborDayApprovalDesign"></span>
                    <span className="labordayNavigationSpan active">
                      <span className="labordayNavigationNumber">2</span>
                      <span className="labordayNavigationLabel">
                        Total Discrepancies
                      </span>
                    </span>
                    <span className="laborDayApprovalDesign"></span>
                    <span className="labordayNavigationSpan">
                      <span className="labordayNavigationNumber">3</span>
                      <span className="labordayNavigationLabel">
                      Employee Performance
                      </span>
                    </span>
                  </>
                )}
                {this.state.step === 3 && (
                  <>
                    <span className="labordayNavigationSpan disable">
                      <span className="labordayNavigationNumber">
                        <span className="tickMark">
                          <img src={GreyTickMark} alt="GreyTickMark" />
                        </span>
                      </span>
                      <span className="labordayNavigationLabel">
                        Assignments
                      </span>
                    </span>
                    <span className="laborDayApprovalDesign"></span>
                    <span className="labordayNavigationSpan disable">
                      <span className="labordayNavigationNumber">
                        <span className="tickMark">
                          <img src={GreyTickMark} alt="GreyTickMark" />
                        </span>
                      </span>
                      <span className="labordayNavigationLabel">
                        Total Discrepancies
                      </span>
                    </span>
                    <span className="laborDayApprovalDesign"></span>
                    <span className="labordayNavigationSpan active">
                      <span className="labordayNavigationNumber">3</span>
                      <span className="labordayNavigationLabel">
                      Employee Performance
                      </span>
                    </span>
                  </>
                )}
              </div>
              <div className="labordayNavigationbuttons">
                {this.state.step === 1 && this.state.isEdit && (
                <OverlayTrigger placement="bottom" 
                overlay={buttonDisabled ? renderTooltip : renderhideTooltip}
                >
                   <span className="d-inline-block">
                  <button
                    type="button"
                    style={{pointerEvents:buttonDisabled?'none':'auto'}}
                    className="btn-outline-primary btn btn-primary"
                    onClick={this.handleSave}
                    disabled={buttonDisabled}
                    >
                    Continue
                  </button>
                  </span>
                </OverlayTrigger>
                )}
                {this.state.step !== 1 && (
                  <button
                    type="button"
                    className="btn-outline-primary btn btn-primary"
                    onClick={this.handleBack}
                  >
                    Back
                  </button>
                )}
              </div>
            </div>
            {this.state.housekeepingBanner !== "" && (
              <div className="notification-banners red-bg">
                {this.state.housekeepingBanner}
              </div>
            )}
            <div className="overBudgetDeptsTable housekeepingApprovals">
              {this.state.step === 1 && (
                <div className="table-responsive assignmentData">
                  <BootstrapTable
                    id="tblHousekeeping"
                    keyField="userName"
                    data={this.state.assignmentData}
                    columns={column}
                    rowClasses={ rowClassesStep1 }
                    hover
                  />
                </div>
              )}
              {this.state.step === 2 && (
                <div className="totalsDiscrepency roomDiscrepency">
                  <BootstrapTable
                    keyField="date"
                    data={this.state.roomDiscrepency}
                    columns={columnDiscrepency}
                    hover
                  />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="totalsDiscrepency emp-budget employeeOverBudget" id="housekeeping-approval-tab">
                 {this.state.multiAddExplanationLoading && <BootstrapTable
                    keyField="id"
                    data={this.state.employeeOverBudget.filter(
                      (row) => row.explainationRequired == 1 && row.isNotes === false
                    )}
                    columns={columnOverBudget}
                    hover
                    selectRow={selectRow}
                    tabIndexCell
                  />}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}
