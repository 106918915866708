import * as React from "react";
import { Dropdown } from "react-bootstrap";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../Common/Assets/Images/logo.png";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import { EmployeeTimeClock } from "./EmployeeTimeClock";
import { ManagerTimeClock } from "./ManagerTimeClock";
import { UserPunchModal } from "./UserPunchModal";
import { ManagerLoginModal } from "./ManagerLoginModal";
import { TimeClockCommonFunc } from "./TimeClockCommonFunc";
import { LiveClock } from "../Common/Components/LiveClock";
import { ValidateUserModal } from "../Common/Components/ValidateUserModal";
import { User } from "../Common/Services/User";
import { Cookies } from "react-cookie";
import { WebTimeClockService } from "../Common/Services/WebTimeClock";
import { Utils } from "../Common/Utilis";
const cookies = new Cookies();
let curntTickTime: any;
export class TimeClockIndex extends React.Component<any, any> {
  private validateUserTimeID: any | undefined;
  private EmployeePunchInRef: any;
  private ManagerPunchInRef: any;
  private userPunchModalRef: any;
  constructor(props: any) {
    super(props);
    this.EmployeePunchInRef = React.createRef();
    this.ManagerPunchInRef = React.createRef();
    this.userPunchModalRef = React.createRef();
    this.state = {
      hidValue: "",
      hotelName: "",
      hotelFullName: "",
      hidTimeZone: "",
      displayDate: "",
      viewType: "employee",
      userPunchModal: false,
      managerLoginModal: false,
      validateUserModal: false,
      validateUserCloseTrigger: 0,
      languagesList: ["English"],
      selectedLangugage: "English",
      userPucnModalData: {},
      validatedUserData: {},
      managerName: "",
      managerId: 0,
      isAuthenticatedUser: false,
      selectedHID: "",
      authenticatedTenantID: 0,
      currentHotelTime: '',
    };
  }

  componentDidMount() {
    this.validateUserWithGUID();
  }

  getHotelCurrentTime = (Hotel) => {
    let request: any = {};
    request.HID = Hotel.hotelID;
    User.GetHotelCurrentDateTime(request).then(async (response: any | null) => {
      if (response !== null) {
        this.setState({ currentHotelTime: response.result })
      } else {
        this.setState({ currentHotelTime: new Date().toISOString() })
      }
    })
  }


  getHotelTime = () => {
    WebTimeClockService.GetHotelCurrentDateTime()
      .then(async (result: any | null) => {
        if (result.message === "Success") {
          let date = new Date(result.result);
          let year = date.getFullYear();
          let month = (1 + date.getMonth()).toString().padStart(2, "0");
          let day = date.getDate().toString().padStart(2, "0");
          curntTickTime = result.result;//"2021-05-06T02:30:58.4660267";//
          this.againCall();
          this.setState({ displayDate: month + "/" + day + "/" + year });
        }
      })
      .catch((error) => { 
        Utils.toastError(`Server Error, ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeClockIndex",
        });
      });
  };
  againCall = () => {
    //this.update();
    setInterval(this.update, 1000);
  };


  update = () => {
    var date = moment(new Date(curntTickTime));
    // var date2 = moment(new Date());
    // var datetime2=date2.format('hh:mm:ss a');
    // var datetime=date.format('hh:mm:ss a');
    let hour: string = date.format("h");
    let min: string = date.format("m");
    let sec: string = date.format("s");
    let am_pm: string = date.format("A");

    let TMinute: any;
    let THour: any;
    let TSec: any;
    THour = hour;
    TMinute = min;
    TSec = sec;
    if (am_pm === "AM" && THour === "12") {
      THour = "0";
    }
    let changeHour = false;
    if (TMinute === "59" && TSec === "59") {
      THour = parseInt(THour) + 1;
      THour = String(THour);
      TMinute = "0";
      TSec = "0";
      changeHour = true;
    }

    if (changeHour === false) {
      if (TSec === "59") {
        TMinute = parseInt(TMinute) + 1;
        TMinute = String(TMinute);
        TSec = "0";
      } else {
        TMinute = TMinute;
        TSec = parseInt(TSec) + 1;
        TSec = String(TSec);
      }
    }

    if (Number(THour) < 10) {
      THour = `0${THour}`;
    }
    if (Number(TMinute) < 10) {
      TMinute = `0${TMinute}`;
    }
    if (Number(TSec) < 10) {
      TSec = `0${TSec}`;
    }
    
    if(THour == "12" && TMinute == "00" && TSec == "00"){
    if(am_pm === "AM"){
      am_pm = "PM"
    }else{
      THour = "00";
      am_pm = "AM"
    }
  }
    let TimeNewHour = am_pm==="PM" && THour < "12"? parseInt(THour, 10) + 12: THour;

    curntTickTime=curntTickTime.toString().split("T")[0]+"T"+ TimeNewHour  + ":" + TMinute + ":" + TSec;
    this.setState({ displayTime: THour + ":" + TMinute + ":" + TSec + " " + am_pm });
  };


  validateUserWithGUID = () => {
    let found = document.cookie
      .split(";")
      .filter((c) => c.trim().split("=")[0] === "WebTimeClock");
    let cookieValue = found.length > 0 ? found[0].split("=")[1] : "";
    if (cookieValue === undefined) cookieValue = "";
    let currentURL = window.location.origin;
    if (cookieValue !== "") {
      WebTimeClockService.validateTimeClockLoginWithGUID(cookieValue)
        .then(async (result: any | null) => {
          if (result.success === true) {
            if (result.user.cookieStatus.toLowerCase() === "active") {
              localStorage.setItem(
                "WebClockDetail",
                JSON.stringify(result.user)
              );
              //localStorage.setItem("token", result.token);
              let WebClockDetail: any;
              WebClockDetail = JSON.parse(
                localStorage.getItem("WebClockDetail")!
              );
              if (WebClockDetail !== null) {
                this.setState({
                  selectedHID: WebClockDetail.defaultHid,
                  authenticatedTenantID: WebClockDetail.tenantID,
                  isAuthenticatedUser: true,
                });
                this.getHotelTime();
                // this.setDisplayDate();
                let timeClockTemp: any = {};
                timeClockTemp.tenantID = WebClockDetail.tenantID;
                timeClockTemp.userName = WebClockDetail.userName;
                timeClockTemp.user_UniqueID = WebClockDetail.user_UniqueID;
                timeClockTemp.roleID = WebClockDetail.rolId;
                localStorage.setItem(
                  "timeClockTemp",
                  JSON.stringify(timeClockTemp)
                );

                if (
                  sessionStorage.getItem("timeClock") ||
                  localStorage.getItem("timeClock")
                ) {
                  let timeClock: any;
                  if (sessionStorage.getItem("timeClock")) {
                    timeClock = JSON.parse(
                      sessionStorage.getItem("timeClock")!
                    );
                  } else {
                    timeClock = JSON.parse(localStorage.getItem("timeClock")!);
                  }
                  let managerName =
                    timeClock === null ? "" : timeClock.managerName;
                  let managerId = timeClock === null ? 0 : timeClock.managerId;

                  this.setState({
                    viewType: "manager",
                    managerName: managerName,
                    managerId: managerId,
                  });
                }
                this.getSelectedHotelDetails();
              }
            } else {
              window.location.href = currentURL + "/login";
            }
          } else {
            cookies.remove("WebTimeClock", {
              path: "/",
              domain: this.state.currentDomain,
            });
            window.location.href = currentURL + "/login";
          }
          resolve();
        })
        .catch((err) => {
          Utils.toastError(`Server Error, ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "timeClockIndex",
          });
          reject();
        });
    } else {
      cookies.remove("WebTimeClock", {
        path: "/",
        domain: this.state.currentDomain,
      });
      window.location.href = currentURL + "/login";
    }
  };

  componentWillUnmount() {
    localStorage.clear();
    sessionStorage.clear();
  }

  getSelectedHotelDetails = () => {
    let hotelDetails: any[] = [];
    let details = {} as any;

    WebTimeClockService.getUserHotelAccess("Labor Management")
      .then(async (result: any | null) => {
        if (result.length > 0) {
          let allHIDs = result.filter(
            (items: any) => items.hotelID === parseInt(this.state.selectedHID)
          );

          details.ehidName = allHIDs[0].ehidName;
          details.hotelID = allHIDs[0].hotelID;
          details.hotelName = allHIDs[0].hotelName;
          details.hotelType = allHIDs[0].hotelType;
          details.lettercode = allHIDs[0].lettercode;
          details.tenantID = this.state.authenticatedTenantID;
          details.timeZone = allHIDs[0].timeZone;
          hotelDetails.push(details);
          this.selectHotelDropDown(hotelDetails[0]);
          this.getHotelCurrentTime(hotelDetails[0]);

        }
      })
      .catch((error) => {
        Utils.toastError(`Server Error, ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "timeClockIndex",
        });
        reject();
      });
  };

  selectHotelDropDown = (hotelDetails: any) => {
    let hidValue = hotelDetails.hotelID;
    let hotelName = hotelDetails.lettercode;
    let hotelFullName = hotelDetails.hotelName;
    let hidTimeZone = hotelDetails.timeZone;

    this.setState(
      {
        hidValue,
        hotelName,
        hotelFullName,
        hidTimeZone,
      },
      () => {
        //this.setDisplayDate();
        if (this.state.viewType === "employee") {
          if (this.EmployeePunchInRef.current !== null) {
            this.EmployeePunchInRef.current.getEmployeeData("ehidSelector");
          }
        } else {
          if (this.ManagerPunchInRef.current !== null) {
            this.ManagerPunchInRef.current.getPostionDepartment("ehidSelector");
            this.ManagerPunchInRef.current.getLaborManagerTimeClockData(
              "ehidSelector"
            );
          }
        }
      }
    );
  };

  setDisplayDate = () => {
    let timeZoneValue = TimeClockCommonFunc.getCurrentTimeZone(
      this.state.hidTimeZone
    );

    let zoneDate: any = moment.tz(timeZoneValue).format("DD");
    let zoneMonth: any = moment.tz(timeZoneValue).format("MM");
    let zoneYear: any = moment.tz(timeZoneValue).format("YYYY");
    let curretnzoneDate = `${zoneMonth}/${zoneDate}/${zoneYear}`;
    this.setState({ displayDate: curretnzoneDate });
  };

  managerLoginClose = () => {
    this.setState({ managerLoginModal: false });
  };

  managerLogin = () => {
    this.setState({ managerLoginModal: true });
  };


  managerLoginSucsess = (managerName: any, user_UniqueID: any) => {
    this.setState({
      managerLoginModal: false,
      viewType: "manager",
      managerName: managerName,
      managerId: user_UniqueID,
    });
  };

  managerLogout = () => {
    this.setState({ viewType: "employee", managerName: "", managerId: 0 });
    let timeClock = JSON.parse(localStorage.getItem("WebClockDetail")!);
    if (timeClock !== null) {
      //localStorage.setItem("token", timeClock.mergeToken);
    }
  };

  sleep(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  userPunchModalShow = (userData: any) => {
    this.setState({ userPucnModalData: userData }, () => {
      this.setState({ userPunchModal: true });
    });
  };

  userPunchModalClose = () => {
    localStorage.removeItem("storage");
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("logoutPopupTime");
    this.setState({ userPucnModalData: {}, userPunchModal: false });
  };

  userPunchSuccess = () => {
    if (this.EmployeePunchInRef.current !== null) {
      this.EmployeePunchInRef.current.getEmployeeData("userPunchSuccess");
    }
  };

  userValidated = (userData: any) => {
    this.setState({ validatedUserData: userData }, () => {
      this.validateUserModalShow();
    });
  };

  validateUserModalShow = () => {
    this.setState({ validateUserModal: true,hideUserPunchModal:true,managerLoginModal:false }, () => {
      // Timer Trigger for validate User Modal AutoClose After  5 min
      this.validateUserTimeID = setInterval(this.validateModalTrigger, 1000);
    });
  };

  validateUserModalHide = (callFrom?,data?) => {
    clearInterval(this.validateUserTimeID);
    if(callFrom === "autoClose"){
      this.userPunchModalClose()
    } else if (callFrom === 'managerLoginModal'){
      this.managerLoginSucsess(data.userName, data.user_UniqueID);
    }
    else if (callFrom === 'userPunchModal'){
      if (this.state.userPucnModalData.status === "S") {
        this.userPunchModalRef.current.punchInCall("validate");
      } else {
        this.userPunchModalRef.current.punchOutCall("validate");
      }
    }
    this.setState({
      validatedUserData: {},
      validateUserModal: false,
      validateUserCloseTrigger: 0,
      hideUserPunchModal:false
    });
  };

  validateModalTrigger = () => {
    this.setState((prevState: any) => ({
      validateUserCloseTrigger: prevState.validateUserCloseTrigger + 1,
    }));
    // close on 5 minutes of inactivity 5*60=300
    if (this.state.validateUserCloseTrigger >= 300) {
      this.validateUserModalHide("autoClose");
    }
  };

  render() {
    return (
      <>
        {/* <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"timeClockIndex"}
        /> */}
        <div className="time-clock-main">
          <div className="time-clock-header d-flex">
            <div className="header-left d-flex mr-auto">
              <img className="inn-flow-logo" src={logo} alt="inn-flow" />
              <div className="hotel-details">
                <span className="hotel-id">{this.state.hotelName}</span>
                <span className="hotel-full-name">
                  {this.state.hotelFullName}
                </span>
              </div>
            </div>

            <div className="header-right d-flex">
              <div className="time-details d-flex">
                <span className="current-date">{this.state.displayDate}</span>
                <span className="current-time">
                  {this.state.displayTime}
                  {/* <LiveClock
                    component="webTimeClock"
                    timeZone={this.state.hidTimeZone}
                  /> */}
                </span>
              </div>
              <div className="horizontal-seperator"></div>
              <div className="language-dropdown">
                <Dropdown className="more-action bg-transparent" alignRight>
                  <Dropdown.Toggle className="" id="dropdown-more">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9.99999 1.66663C5.40499 1.66663 1.66666 5.40496 1.66666 9.99996C1.66666 14.595 5.40499 18.3333 9.99999 18.3333C14.595 18.3333 18.3333 14.595 18.3333 9.99996C18.3333 5.40496 14.595 1.66663 9.99999 1.66663ZM16.6092 9.16663H14.3058C14.2092 7.36246 13.6975 5.58329 12.8125 3.96413C14.8442 4.91496 16.3183 6.85663 16.6092 9.16663ZM10.4417 3.35579C11.3042 4.49246 12.4642 6.50579 12.6308 9.16663H7.52499C7.64082 7.00329 8.35332 4.97663 9.56749 3.35496C9.71082 3.34663 9.85416 3.33329 9.99999 3.33329C10.1492 3.33329 10.295 3.34663 10.4417 3.35579ZM7.23999 3.93913C6.41999 5.51496 5.94666 7.30163 5.85832 9.16663H3.39082C3.68416 6.83663 5.18082 4.88079 7.23999 3.93913ZM3.39082 10.8333H5.86916C5.98249 12.8158 6.42332 14.565 7.16582 16.025C5.14499 15.07 3.67999 13.135 3.39082 10.8333ZM9.54166 16.6441C8.37416 15.2291 7.68499 13.2466 7.53416 10.8333H12.6283C12.455 13.1441 11.6975 15.1633 10.4592 16.6433C10.3075 16.6533 10.1558 16.6666 9.99999 16.6666C9.84499 16.6666 9.69416 16.6533 9.54166 16.6441ZM12.8842 16.0008C13.68 14.5058 14.1658 12.75 14.2933 10.8333H16.6083C16.3225 13.1158 14.8783 15.0391 12.8842 16.0008Z" />
                    </svg>
                    {this.state.selectedLangugage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {this.state.languagesList.map((elem: any, id: any) => (
                      <Dropdown.Item key={id}>{elem}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {this.state.viewType === "employee" && (
                <>
                  <div className="horizontal-seperator"></div>
                  <div className="manager-login">
                    <button
                      className="login-btn btn btn-primary btn-outline-primary"
                      onClick={() => this.managerLogin()}
                    >
                      Manager
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <>
            {this.state.viewType === "employee" && (
              <EmployeeTimeClock
                ref={this.EmployeePunchInRef}
                parentState={this.state}
                userPunchModalShow={this.userPunchModalShow}
              />
            )}
            {this.state.viewType === "manager" && (
              <ManagerTimeClock
                ref={this.ManagerPunchInRef}
                parentState={this.state}
                managerLogout={this.managerLogout}
              />
            )}
            {this.state.userPunchModal && (
              <UserPunchModal
                ref={this.userPunchModalRef}
                parentState={this.state}
                hideUserPunchModal={this.state.hideUserPunchModal}
                userPunchModalClose={this.userPunchModalClose}
                userPunchSuccess={this.userPunchSuccess}
                userValidated={this.userValidated}
              />
            )}
            {this.state.managerLoginModal && (
              <ManagerLoginModal
                parentState={this.state}
                managerLoginClose={this.managerLoginClose}
                managerLoginSucsess={this.managerLoginSucsess}
                userValidated={this.userValidated}
              />
            )}
            {this.state.validateUserModal && (
              <ValidateUserModal
                userData={this.state.validatedUserData}
                modalShow={this.state.validateUserModal}
                validateUserModalHide={this.validateUserModalHide}
                parnetComponent="webTimeClock"
              />
            )}
          </>
        </div>
      </>
    );
  }
}
