import Instense from "./Axios-config";
// import axios from "axios";

import {
  ILabourTimeOffRequestDto,
  ILabourTimeOffResponseDto,
  ILabourTimeOffApprovePTORequest,
  ILabourTimeOffUpdatePTORequestEntries,
} from "../Contracts/ILabourTimeOff";
import { ITimeOffPermission } from "../Contracts/ILaborForecast";

export class LaborTimeOff {
  public static GetLaborTimeOffList = async (
    request: ILabourTimeOffRequestDto
  ): Promise<ILabourTimeOffResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.TenantID = tenantID;
    request.LoginUserRoleID = rolId;
    const url = `/TimeClock/GetTimeOffRequestForApprove`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as ILabourTimeOffResponseDto;
      return result;
    });
  };
}

export class LaborTimeOffDetails {
  public static GetTimeOffRequestDetails = async (
    PTORequestID: number,
    selectDate: string,
    IsPTOID: string
  ): Promise<any | null> => {
    // let storage = JSON.parse(localStorage.getItem("storage")!);

    let request = {} as any;
    request.PTORequestID = PTORequestID;
    request.selectDate = selectDate;
    request.IsPTOID = IsPTOID;
    const url = `/TimeClock/GetTimeOffRequestDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data;
      return result;
    });
  };

  public static GetTimeOffPermissionData = async (
    request: ITimeOffPermission
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.TenantID = tenantID;
    request.RoleID = rolId;

    const url = `/RolePermission/GetPageSubPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      
      return result;
    });
  };

}

export class LabourTimeOffApprovePTORequest {
  public static ApprovePTORequest = async (
    request: ILabourTimeOffApprovePTORequest
  ): Promise<any | null> => {
    // let storage = JSON.parse(localStorage.getItem("storage")!);
    const url = `/LaborSchedule/ApprovePTORequest`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data;
      return result;
    });
  };
}

export class LabourTimeOffUpdatePTORequestEntries {
  public static ApprovePTORequestEntries = async (
    request: ILabourTimeOffUpdatePTORequestEntries
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/LaborSchedule/UpdatePTORequest`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };
}
