import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { GridOptions } from "ag-grid-community";
import { AddUserModal } from "./components/AddUserModal";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { UserManagement } from "./../../Common/Services/UserManagement";
import { IUserRequest } from "../../Common/Contracts/IUserRequest";
import { resolve, reject } from "q";
import { IUserList } from "../../Common/Contracts/IUserList";
import { Button, Container, Dropdown, Form } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { IUserRole } from "../../Common/Contracts/IUserRole";
import { Hotel } from "./../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { UserDetailSlideOut } from "./components/UserDetailSlideOut";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { threadId } from "worker_threads";
import OutsideClickHandler from "react-outside-click-handler";
import _ from "lodash";
import { IUserExcelExportRequest } from "../../Common/Contracts/IUserExcelExportRequest";
import { ILaborSaveAsDefaultDto } from "../../Common/Contracts/ILaborSaveAsDefaultDto";
import { LaborPerformance } from "../../Common/Services/LaborPerfomance";
import { toast } from "react-toastify";
import moment from "moment";
import {
  IPageSubPermissionRole,
  RoleSubPermissionSetting,
} from "../../Common/Services/RoleSubPermissionSetting";
import ReactTooltip from "react-tooltip";
import { ChangeRoleModal } from "./ChangeRoleModal";
import { ChangeStatusModal } from "./ChangeStatusModal";
import { UsersLogHistory } from "./components/UsersLogHistory";
import { userUniqueID } from "./../../Common/Services/LocalStorage";
import { GMResetPassword } from "./GMResetPassword";
import { MultiUserSelector } from "./common-components/MultiUserSelector";
import { User } from "../../Common/Services/User";
import { ChangeEHIDAccessModal } from "./ChangeEHIDAccessModal";
import { MissingInfoModal } from "./MissingInfoModal";
import "../../Common/Assets/Styles/Modules/user-management.scss";
import { ResetPassword } from "./ResetPassword";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { UserManagementHub } from "../../Common/SignalR/Hubs/UserManagementHub";
import EventEmitter from "reactjs-eventemitter";
import { Utils } from "../../Common/Utilis";
import { Actions } from "../../Common/SignalR/Actions/Actions";

export class Users extends React.Component<any, any> {
  private mutationObserver: any;
  private pageName = "userManagement";
  private checkAllRef: any = {};
  private scrollArea: any = {};
  columnDefs: any[] = [
    {
      headerName: "Username",
      field: "username",
      cellRenderer: "profileImageCellRenderer",
      //suppressMovable: true,
      lockPosition: true,
      // checkboxSelection: true,
      maxWidth: 250,
      minWidth: 250,
      pinned: true,
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: "statusCellRenderer",
      maxWidth: 140,
      minWidth: 140,
      tooltip: (params) => params?.data?.userStatus,
    },
    {
      headerName: "Pay Type",
      field: "displayEmployeeType",
      cellRenderer: "payTypeCellRenderer",
      maxWidth: 200,
      minWidth: 200,
    },
    {
      headerName: "EHID",
      field: "hiDs",
      cellRenderer: "hotelIdsCellRenderer",
      maxWidth: 140,
      cellStyle: { overflow: "visible" },
      minWidth: 140,
      cellClass: "ehid-cells",
      //suppressMovable: true,
    },

    {
      headerName: "Role",
      field: "role",
      cellRenderer: "roleCellRenderer",
      maxWidth: 160,
      minWidth: 160,
    },
    {
      headerName: "First name",
      field: "firstName",
      cellRenderer: "firstNameCellRenderer",
      maxWidth: 140,
      minWidth: 140,
    },
    {
      headerName: "Last name",
      field: "lastName",
      cellRenderer: "lastNameCellRenderer",
      maxWidth: 140,
      minWidth: 140,
    },
    {
      headerName: "Position",
      field: "position",
      cellRenderer: "positionCellRenderer",
      maxWidth: 160,
      minWidth: 160,
      cellClass: "position-nam",
    },
    {
      headerName: "Email",
      field: "email",
      cellRenderer: "emailCellRenderer",
      maxWidth: 160,
      hide: true,
      minWidth: 160,
    },
    {
      headerName: "Mobile Phone",
      field: "phone1",
      cellRenderer: "phoneCellRenderer",
      maxWidth: 140,
      hide: true,
      minWidth: 140,
    },

    {
      headerName: "Payroll ID",
      field: "payRoll",
      cellRenderer: "payRollCellRenderer",
      maxWidth: 160,
      minWidth: 160,
      hide: true,
      cellClass: "payroll-id",
    },

    {
      headerName: "Pay Rate",
      field: "payRate",
      cellRenderer: "payRateCellRenderer",
      cellClass: "pay-rate",
      hide: true,
      maxWidth: 140,
      minWidth: 140,
    },
    {
      headerName: "Salary",
      field: "salary",
      cellRenderer: "salaryCellRenderer",
      cellClass: "salary",
      hide: true,
      maxWidth: 140,
      minWidth: 140,
    },
    {
      headerName: "Employee No.",
      field: "uniqueno",
      hide: true,
      maxWidth: 140,
      minWidth: 140,
    },

    {
      headerName: "Address",
      field: "address",
      cellRenderer: "addressCellRenderer",
      hide: true,
      maxWidth: 140,
      minWidth: 140,
    },
    {
      headerName: "Street",
      field: "street",
      cellRenderer: "streetCellRenderer",
      hide: true,
      maxWidth: 140,
      minWidth: 140,
    },
    {
      headerName: "City",
      field: "city",
      cellRenderer: "cityCellRenderer",
      hide: true,
      maxWidth: 140,
      minWidth: 140,
    },
    {
      headerName: "State",
      field: "state",
      cellRenderer: "stateCellRenderer",
      hide: true,
      maxWidth: 140,
      minWidth: 140,
    },
    {
      headerName: "Zip",
      field: "zip",
      cellRenderer: "zipCellRenderer",
      hide: true,
      maxWidth: 100,
      minWidth: 100,
    },
    {
      headerName: "Hire Date",
      field: "date_of_hire",
      hide: true,
      maxWidth: 100,
      minWidth: 100,
    },
    {
      headerName: "Termination Date",
      field: "date_of_termination",
      hide: true,
      maxWidth: 160,
      minWidth: 160,
    },
    {
      headerName: "SSN",
      field: "ssn",
      cellRenderer: "ssnCellRenderer",
      hide: true,
      maxWidth: 140,
      minWidth: 140,
    },
    {
      headerName: "Date of Birth",
      field: "dob",
      cellRenderer: "dobCellRenderer",
      hide: true,
      maxWidth: 150,
      minWidth: 150,
    },

    {
      headerName: "Primary Contact",
      field: "primaryContact",
      cellRenderer: "primaryContactCellRenderer",
      hide: true,
      maxWidth: 140,
      minWidth: 140,
    },
    {
      headerName: "",
      field: "",
      lockPinned: true,
      pinned: "right",
      cellRenderer: "tripleDotCellRenderer",
      maxWidth: 100,
      minWidth: 100,
      cellStyle: { overflow: "visible", position: "reset" },
      cellClass: "triple-dot",
    },
  ];
  gridApi: any;
  gridColumnApi: any;
  agGridRef;
  constructor(props: any) {
    super(props);
    this.checkAllRef = React.createRef();
    this.scrollArea = React.createRef();
    this.agGridRef = React.createRef();
    this.state = {
      hotelId: -1,
      searchText: "",
      status: -1,
      role: -1,
      isDataLoading: false,
      columnDropdownToggle: false,
      toggleColumnData: [],
      currentColumnSelected: 7,
      gridColumns: this.columnDefs.map((item, index) => {
        item.order = index;
        return item;
      }),
      maxColumnSelected: 6,
      totalColumns: 0,
      totalDataLength: 0,
      payType: "",
      statusList: [],
      missingStatusList: [],
      _missingStatusList: [],
      childStatusList: [],
      payTypes: [],
      userRoles: [],
      isInitialDataDataLoading: true,
      sortingFields: ["Name", "Status", "Pay type", "Role"],
      sortFieldName: "Name",
      users: [],
      hotels: [],
      showAddUserModal: false,
      showSlideout: false,
      pageSize: 20,
      currentPageNo: 1,
      isChangeEHIDAccess: false,
      isChangeUserRole: false,
      isEditGeneralInfo: false,
      isEditUserInfo: false,
      isEditLaborandPayrollInfo: false,
      isEditDocumentInfo: false,
      isEditSalesInfo: false,
      isPageCreateNewUser: false,
      isChangeStatus: false,
      isResetPassword: false,
      isSendWelcomeEmail: true,
      activeStatus: "Active",
      tableActions: [
        { action: "Change Role", eventKey: "changeRole" },
        { action: "Change Status", eventKey: "changeStatus" },
        { action: "Reset Password", eventKey: "changePassword" },
        { action: "Re-Send Welcome Email", eventKey: "welcomeEmail" },
        { action: "Bulk Select", eventKey: "bulkSelect" },
        { action: "View Change Log", eventKey: "viewChangeLog" },
      ],
      showRoleModal: false,
      showStatusModal: false,
      showMissingInfoModal: false,
      missingPhoneEmailUsers: [],
      isBulkAction: false,
      resetPassword: false,
      welcomeEmail: false,
      selectedRows: [],
      isInactive: false,
      hideChangeStatus: false,
      showChangeLogModal: false,
      currentClickedRow: {},
      isSelectAllCols: false,
      isNewUserAdded: false,
      gmresetPassword: false,
      usernames: [],
      showEHIDModal: false,
      showResetPasswordModal: false,
      IsLoginUserPasswordAssistant: false,
      isViewAll: false,
      payrollIntegrationHids: [],
      bulkSelectitem: {
        eventKey: "bulkSelect",
      },
    };
  }

  handleShowResetPasswordModal = () => {
    this.setState({ showResetPasswordModal: true });
  };
  handleHideResetPasswordModal = () => {
    this.setState({ showResetPasswordModal: false });
  };

  setToggleColumnData = () => {
    const toggleColumnData = this.columnDefs
      .filter((item) => item?.field)
      .map((item: any, index) => {
        let toggleColumnObject: any = {};
        toggleColumnObject.colDispalyName = item?.headerName;
        toggleColumnObject.colName = item?.field;
        toggleColumnObject.checked = item?.hide ? false : true;
        toggleColumnObject.order = index;
        toggleColumnObject.disabled =
          item?.headerName === "Username" ? true : false;
        return toggleColumnObject;
      });

    this.setState({
      toggleColumnData,
      currentColumnSelected: toggleColumnData.filter((item) => item.checked)
        .length,
      maxColumnSelected: toggleColumnData?.length,
    });
  };

  columnDragStop = (params: any) => {
    let columnInstance =
      params.columnApi.columnController.displayedCenterColumns;
    const fields = columnInstance
      .map((item, index) => ({ name: item.colDef.field, order: index }))
      .filter((item) => item.name);

    const toggleColumnData = this.state.toggleColumnData.map((item) => {
      item.order = fields.find((x) => x?.name === item?.colName)?.order || 0;

      return item;
    });

    let columnData = this.columnDefs.map((item) => {
      if (
        toggleColumnData.find(
          (col) => col.colName === item.field && col.checked
        ) ||
        !item.field
      ) {
        item.hide = false;
      } else {
        item.hide = true;
      }

      if (item.field) {
        item.order = toggleColumnData.find(
          (col) => col.colName === item.field
        )?.order;
      } else {
        if (item.cellClass === "triple-dot") {
          item.order = 100;
        }
      }

      return item;
    });
    const gridColumns = columnData.sort((a, b) => +a.order - +b.order);
    this.setState({
      toggleColumnData,
      gridColumns,
      currentColumnSelected: toggleColumnData.filter((item) => item.checked)
        .length,
      maxColumnSelected: toggleColumnData?.length,
    });
  };

  componentDidMount() {
    this.GetPayrollIntegrationHidsByTenant();
    this.getAddUserPermission();
    this.getManageUserPermission();
    this.getDefaultView();
    this.getAllUsernameByTenantId();
    this.CheckIfLoginUserIsPasswordAssitant();
    this.recieveNotifySaveUserDetails();
  }

  recieveNotifySaveUserDetails = () => {
    EventEmitter.subscribe(
      Actions.UserManagementHubActions.SAVE_USER,
      (response) => {
        this.getUserList();
      }
    );
  };

  GetPayrollIntegrationHidsByTenant = () => {
    UserManagement.GetPayrollIntegrationHidsByTenant()
      .then((data: any[]) => {
        const hids = this.props.userGeneralInfo?.hids?.map((x) => x.hotelID);
        this.setState({ payrollIntegrationHids: data });
      })
      .catch(() => {
        this.setState({ payrollIntegrationHids: [] });
      });
  };
  handleEmitMessage = async (user: any, message: any) => {
    const chatMessage = {
      message: "User is updated!",
    };

    // if(this.state.hubConnection.connectionStarted)
    // {
    try {
      await fetch(
        "https://inn-flow-v2-api-testv2.azurewebsites.net/api/chat/messages",
        {
          method: "POST",
          body: JSON.stringify(chatMessage),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      this.state.hubConnection?.send("SendMessage", chatMessage);
    } catch (e) {
      console.log(e);
    }
    // }
    // else{
    //   alert("connection is not done yet");
    // }
  };

  CheckIfLoginUserIsPasswordAssitant = () => {
    UserManagement.CheckIfLoginUserIsPasswordAssitant().then((result: any) => {
      this.setState({ IsLoginUserPasswordAssistant: result?.result?.result });
    });
  };

  getAllUsernameByTenantId = () => {
    UserManagement.GetAllUsernameByTenantId().then((result: any) => {
      this.setState({ usernames: result?.result });
    });
  };

  showHideEHIDModal = () => {
    this.setState({ showEHIDModal: !this.state?.showEHIDModal });
  };

  showHideMissingInfoModal = () => {
    this.setState({ showMissingInfoModal: !this.state?.showMissingInfoModal });
  };

  showHideGMResetModal = () => {
    this.setState({ gmresetPassword: !this.state?.gmresetPassword });
  };

  showHideStatusModal = () => {
    this.setState({ showStatusModal: !this.state?.showStatusModal });
  };

  showHideResetPasswordModal = () => {
    this.setState({
      showResetPasswordModal: !this.state?.showResetPasswordModal,
    });
  };

  showHideRoleModal = () => {
    this.setState({ showRoleModal: !this.state?.showRoleModal });
  };

  getAddUserPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 57;
    requestObject.Module = "All";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        // 2213	Change E/HID Access
        // 2214	Change User Role
        // 2215	Change User Status
        // 2218	Create New User
        // 2229	Edit User Information
        // 2231	Payroll Deduction Settings
        // 2234	Reset Password
        // 2235	Role Permission
        // 2237	Training Setup
        //NEW PERMISSION
        // Edit General Information
        // Edit Labor and Payroll Data
        // Edit Documents
        // Edit Sales Information
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            if (element.permissionName === "Create New User") {
              this.setState({ isPageCreateNewUser: true });
            }
            if (element.permissionName === "Edit General Information") {
              this.setState({
                isEditGeneralInfo: true,
                isChangeUserRole: true,
                isResetPassword: true,
                isChangeStatus: true,
                isSendWelcomeEmail: true,
                isChangeEHIDAccess: true,
              });
            }
            if (element.permissionName === "Edit Labor and Payroll Data") {
              this.setState({ isEditLaborandPayrollInfo: true });
            }
            if (element.permissionName === "Edit Documents") {
              this.setState({ isEditDocumentInfo: true });
            }
            if (element.permissionName === "Edit Sales Information") {
              debugger;
              this.setState({ isEditSalesInfo: true });
            }
          });
        } else {
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "scheduleMain",
        });
      });
  };

  getManageUserPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10084;
    requestObject.Module = "All";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        // 2213	Change E/HID Access
        // 2214	Change User Role
        // 2215	Change User Status
        // 2218	Create New User
        // 2229	Edit User Information
        // 2231	Payroll Deduction Settings
        // 2234	Reset Password
        // 2235	Role Permission
        // 2237	Training Setup
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            if (element.permissionName === "Edit Labor and Payroll Data") {
              this.setState({ isEditLaborandPayrollInfo: true });
            }
          });
        } else {
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "scheduleMain",
        });
      });
  };

  getDefaultView() {
    this.toggleDataLoading();
    LaborPerformance.getDefaultViewProperties(this.pageName)
      .then(async (response: any | null) => {
        debugger;
        if (response?.length > 0) {
          const result = response;

          const hotelId =
            result.find((item) => item.fieldName === "hotelId")?.fieldValue ||
            -1;
          const status =
            +result.find((item) => item.fieldName === "status")?.fieldValue ||
            -1;
          const role =
            +result.find((item) => item.fieldName === "role")?.fieldValue || -1;
          const payType =
            result.find((item) => item.fieldName === "payType")?.fieldValue ||
            "";
          const activeStatus =
            result.find((item) => item.fieldName === "activeStatus")
              ?.fieldValue || "Active";
          const sortFieldName =
            result.find((item) => item.fieldName === "sortFieldName")
              ?.fieldValue || "Name";
          const toggleColumnData = JSON.parse(
            result.find((item) => item.fieldName === "toggleColumnData")
              ?.fieldValue || JSON.stringify([])
          );
          if (toggleColumnData?.length > 0) {
            let columnData = this.columnDefs.map((item) => {
              if (
                toggleColumnData.find(
                  (col) => col.colName === item.field && col.checked
                ) ||
                !item.field
              ) {
                item.hide = false;
              } else {
                item.hide = true;
              }

              if (item.field) {
                item.order = toggleColumnData.find(
                  (col) => col.colName === item.field
                )?.order;
              } else {
                if (item.cellClass === "triple-dot") {
                  item.order = this.columnDefs.length - 1;
                } else {
                  item.order = this.columnDefs.length - 2;
                }
              }

              return item;
            });
            const gridColumns = columnData.sort((a, b) => +a.order - +b.order);
            let isSelectAllCols = false;
            if (
              toggleColumnData?.filter((item) => item?.checked)?.length ===
              toggleColumnData?.length
            ) {
              isSelectAllCols = true;
            }

            this.setState({
              isSelectAllCols,
              toggleColumnData,
              gridColumns,
              currentColumnSelected: toggleColumnData.filter(
                (item) => item.checked
              ).length,
              maxColumnSelected: toggleColumnData?.length,
            });
          } else {
            this.setToggleColumnData();
          }

          this.setState(
            {
              hotelId,
              status,
              role,
              payType,
              activeStatus,
              sortFieldName,
            },
            () => {
              this.loadDefaultData();
            }
          );
        } else {
          this.setState({ status: -1 }, () => {
            this.loadDefaultData();
          });
          this.setToggleColumnData();
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ status: -1 }, () => {
          this.loadDefaultData();
        });
      })
      .finally(() => this.toggleDataLoading());
  }
  loadDefaultData = () => {
    this.getUserList();
    this.loadDropDownsData();
    // this.setToggleColumnData();
  };

  handleShowSlideout = (event: any) => {
    if (this.state.isBulkAction !== true) {
      const data = event.api.gridOptionsWrapper.getRowData();
      if (this.state.isNewUserAdded) {
        this.setState({ isNewUserAdded: false });
      }

      this.setState({
        showSlideout: true,
        userRowData: data[event.rowIndex],
      });
    }
  };

  showSlideoutonAdd = (userData) => {
    this.setState({
      showSlideout: true,
      isNewUserAdded: true,
      userRowData: userData,
    });
  };

  loadDropDownsData = () => {
    this.getUserStatusList();
    this.getUserMissingStatusList();
    this.getPayTypeList();
    this.getUserRoleList();
    this.getHotels();
  };

  saveDefaultView = (): void => {
    const saveAsDefaultdtos: ILaborSaveAsDefaultDto[] = [];
    const {
      hotelId,
      status,
      activeStatus,
      role,
      payType,
      sortFieldName,
      toggleColumnData,
    } = this.state;

    saveAsDefaultdtos.push({
      fieldName: "hotelId",
      fieldValue: hotelId,
      pageName: this.pageName,
    });

    saveAsDefaultdtos.push({
      fieldName: "status",
      fieldValue: status,
      pageName: this.pageName,
    });
    saveAsDefaultdtos.push({
      fieldName: "activeStatus",
      fieldValue: activeStatus,
      pageName: this.pageName,
    });
    saveAsDefaultdtos.push({
      fieldName: "role",
      fieldValue: role,
      pageName: this.pageName,
    });
    saveAsDefaultdtos.push({
      fieldName: "payType",
      fieldValue: payType,
      pageName: this.pageName,
    });
    saveAsDefaultdtos.push({
      fieldName: "sortFieldName",
      fieldValue: sortFieldName,
      pageName: this.pageName,
    });
    saveAsDefaultdtos.push({
      fieldName: "toggleColumnData",
      fieldValue: JSON.stringify(toggleColumnData),
      pageName: this.pageName,
    });

    LaborPerformance.saveDefaultViewProperties(saveAsDefaultdtos)
      .then(async (result: any | null) => {
        this.setState({ loaderTrue: false });
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            //this.getDefaultView();
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  addNewUser = () => {
    if (this.state.showAddUserModal) {
      this.setState({ showAddUserModal: false });
    }
    setTimeout(() => {
      this.setState({ showAddUserModal: true });
    });
  };

  hideAddUserModal = (isReloadRequired = false) => {
    if (isReloadRequired) {
      this.setState(
        {
          currentPageNo: 1,
        },
        () => this.getUserList()
      );
    }
    this.setState({ showAddUserModal: false });
  };

  toggleDataLoading = () => {
    this.setState({ isDataLoading: !this.state.isDataLoading });
  };

  onPaginationchange = () => {
    this.gridApi.showLoadingOverlay();
    const currentPageNo = this.state.currentPageNo + 1;
    this.setState({ currentPageNo }, () => {
      this.getUserList(false, true);
    });
  };

  onSortingOrderChange = (sortFieldName: string) => {
    this.setState({ sortFieldName, currentPageNo: 1 }, () => {
      this.getUserList();
    });
  };

  getUserStatusList = () => {
    UserManagement.GetUserStatusList().then((response: string[]) => {
      const statusList = [
        { id: -1, name: "All" },
        ...response.map((item) => ({ id: item, name: item })),
      ];

      const childStatusList = response.map((item) => {
        if (item === "Incomplete") {
          return { id: item, name: item, disabled: true };
        } else {
          return { id: item, name: item };
        }
      });

      this.setState({ statusList, childStatusList });
    });
  };

  getUserMissingStatusList = () => {
    UserManagement.GetUserMissingStatusList().then((response: string[]) => {
      let missingStatusList = [
        { id: -1, name: "All" },
        ...response.map((item: any) => ({
          id: item?.statusId,
          name: item?.displayStatus,
        })),
      ];

      missingStatusList = missingStatusList
        .filter((x) => x.id < 3)
        .map((x) => {
          if (x.id === 2) {
            x.name = "Incomplete";
          }
          return x;
        });

      this.setState({
        missingStatusList,
        _missingStatusList: missingStatusList,
      });
    });
  };

  getPayTypeList = () => {
    UserManagement.GetPayTypeList().then((response: any[]) => {
      const payTypes = [
        { id: "", name: "All" },
        ..._.sortBy(
          response.map((item) => ({ id: item.value, name: item.name })),
          "name"
        ),
      ];
      this.setState({ payTypes });
    });
  };

  getUserRoleList = () => {
    UserManagement.GetUserRoleList().then((response: IUserRole[]) => {
      const userRoles = [
        { id: -1, name: "All" },
        ...response.map((item) => ({
          id: item.roleID,
          name: item.roleName,
          isLowSecurity: item.isLowSecurity,
        })),
      ];

      this.setState({ userRoles });
    });
  };

  getHotels = () => {
    Hotel.getUserHotelAccessUM().then(
      async (response: IHotelResponseDto[] | null) => {
        let hotels = [
          { id: -1, name: "All" },
          ..._.sortBy(
            response?.map((item) => ({
              id: item.hotelID,
              name: item.hotelName,
              status: item.status,
            })),
            "status",
            "name"
          ),
        ];

        this.setState({ hotels });
      }
    );
  };
  toggleColumnDropdownToggle = (value: boolean) => {
    this.setState({ columnDropdownToggle: value });
  };

  onGridReady = (params: any) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.columnWidthSizeToFit();

    const headerSelector = ".ag-header";
    const scrollSelector = ".ag-body-horizontal-scroll";
    // const scrollViewportSelector = ".ag-body-horizontal-scroll-viewport";
    const scrollContainerSelector = ".ag-body-horizontal-scroll-container";

    // get scrollbar elements
    const scrollElement: any = document.querySelector(scrollSelector);
    // const scrollViewportElement: any = document.querySelector(
    //   scrollViewportSelector
    // );
    const scrollContainerElement: any = document.querySelector(
      scrollContainerSelector
    );

    // create scrollbar clones
    // const cloneElement: any = scrollElement.cloneNode(true) as Element;
    // const cloneViewportElement: any = cloneElement.querySelector(
    //   scrollViewportSelector
    // );
    // const cloneContainerElement: any = cloneElement.querySelector(
    //   scrollContainerSelector
    // );

    // insert scrollbar clone
    // const headerElement: any = document.querySelector(headerSelector);
    // headerElement.insertAdjacentElement("afterend", cloneElement);

    // add event listeners to keep scroll position synchronized
    // scrollViewportElement.addEventListener("scroll", () =>
    //   cloneViewportElement.scrollTo({ left: scrollViewportElement.scrollLeft })
    // );
    // cloneViewportElement.addEventListener("scroll", () =>
    //   scrollViewportElement.scrollTo({ left: cloneViewportElement.scrollLeft })
    // );

    // create a mutation observer to keep scroll size synchronized
    // this.mutationObserver = new MutationObserver((mutationList) => {
    //   for (const mutation of mutationList) {
    //     switch (mutation.target) {
    //       case scrollElement:
    //         cloneElement.setAttribute(
    //           "style",
    //           scrollElement.getAttribute("style")
    //         );
    //         break;
    //       case scrollViewportElement:
    //         cloneViewportElement.setAttribute(
    //           "style",
    //           scrollViewportElement.getAttribute("style")
    //         );
    //         break;
    //       case scrollContainerElement:
    //         cloneContainerElement.setAttribute(
    //           "style",
    //           scrollContainerElement.getAttribute("style")
    //         );
    //         break;
    //     }
    //   }
    // });

    // start observing the scroll elements for `style` attribute changes
    // this.mutationObserver.observe(scrollElement, {
    //   attributeFilter: ["style"],
    //   subtree: true,
    // });
  };

  columnWidthSizeToFit = () => {
    this.gridApi?.sizeColumnsToFit();
  };
  onActiveStatusChange = (event) => {
    this.setState(
      {
        missingStatusList: [],
        isPagination: false,
        status:
          this.state?.status === 7 && !event.checked ? 0 : this.state?.status,
        activeStatus: event.checked ? "Inactive" : "Active",
      },
      () => {
        this.setState({ missingStatusList: this.state._missingStatusList });
        this.getUserList();
      }
    );
  };

  tableOutsideClick = () => {
    setTimeout(() => {
      this.columnWidthSizeToFit();
    }, 800);
  };

  onDDlFiltersChange = (controlID: any, id: any) => {
    let { hotelId, status, role, payType } = this.state;
    switch (controlID) {
      case "ddlStatus":
        status = id;
        break;
      case "ddlPayType":
        payType = id;
        break;
      case "ddlRole":
        role = id;
        break;
      default:
        hotelId = id;
        break;
    }

    this.setState({ hotelId, status, role, payType, currentPageNo: 1 }, () => {
      this.getUserList();
    });
  };

  onSearchChange = (searchText: string) => {
    this.setState({ searchText, currentPageNo: 1 }, () => {
      if (searchText?.length > 1 || !searchText) {
        this.getUserList();
      }
    });
  };

  getUserList = (isDefaultDataLoad = true, isPagination = false) => {
    if (this.state.isBulkAction) {
      this.closeBulkAction();
      this.setState({
        users: [],
        currentPageNo: 1,
        totalDataLength: 0,
      });
    }
    if (!isPagination) {
      this.setState({
        users: [],
        totalDataLength: 0,
      });
    } else {
      const hollowRows = [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ];
      this.setState({
        users: [...this.state.users, ...(hollowRows as IUserList[])],
      });
    }

    let {
      hotelId,
      searchText,
      status,
      role,
      payType,
      pageSize,
      currentPageNo,
      sortFieldName,
      activeStatus,
    } = this.state;
    hotelId = hotelId === -1 ? -1 : hotelId;
    status = status === -2 ? -1 : status;
    role = role === -1 ? null : role;
    payType = payType === "All" ? "" : payType;
    debugger;
    const request: IUserRequest = {
      searchText,
      status,
      role,
      payType,
      hid: hotelId,
      pageIndex: currentPageNo,
      totalRecords: pageSize,
      tenantId: 0,
      sortBy: sortFieldName,
      sortDirection: "ASC",
      activeStatus: activeStatus,
    };
    this.toggleDataLoading();
    // if (isDefaultDataLoad) {
    this.setState({ isInitialDataDataLoading: true });
    // }
    UserManagement.GetUserList(request)
      .then((users: IUserList[]) => {
        if (users?.length && users?.length > 0) {
          const modifiedData = users.map((item) => {
            let payroll = item?.payRoll;
            if (payroll) {
              const mainData = payroll.split(",");
              let payRoll = "";
              let payRate = "";
              let position = "";
              mainData.forEach((data, i) => {
                const splittedData = data.split("#");

                payRoll += splittedData[0]
                  ? splittedData[0] + `${i === mainData?.length - 1 ? "" : ","}`
                  : "";
                if (splittedData[1]?.toString()?.includes("*")) {
                  payRate +=
                    splittedData[1] +
                    `${i === mainData?.length - 1 ? "" : ","}`;
                } else {
                  payRate += !isNaN(+splittedData[1])
                    ? (+splittedData[1]).toFixed(2) +
                      `${i === mainData?.length - 1 ? "" : ","}`
                    : "";
                }
                position += splittedData[2]
                  ? splittedData[2] + `${i === mainData?.length - 1 ? "" : ","}`
                  : "";
              });

              item.payRoll = payRoll;
              item.payRate = payRate;
              item.position = position;
            }
            return item;
          });
          users = modifiedData || [];

          let currentColumnSelected = 0;
          let { toggleColumnData, gridColumns } = this.state;
          let allcolumns = this.columnDefs.filter(
            (item) =>
              Object.keys(users[0]).includes(item?.field) || !item?.field
          );

          if (
            allcolumns.filter((x) => x?.field)?.length !==
            toggleColumnData?.length
          ) {
            allcolumns = allcolumns.map((item) => {
              const column = gridColumns?.find((x) => x?.field === item?.field);
              if (column) {
                item = column;
              } else {
                item.hide = true;
              }

              return item;
            });

            gridColumns = allcolumns;

            let allToggleColumnData = allcolumns
              .filter((r) => r?.field)
              .map((item, index) => {
                const column = toggleColumnData?.find(
                  (x) => x?.colName === item?.field
                );
                if (column) {
                  item = column;
                } else {
                  let toggleColumnObject: any = {};
                  toggleColumnObject.colDispalyName = item?.headerName;
                  toggleColumnObject.colName = item?.field;
                  toggleColumnObject.checked = item?.hide ? false : true;
                  toggleColumnObject.order = allcolumns?.length + index;
                  toggleColumnObject.disabled =
                    item?.headerName === "Username" ? true : false;

                  item = toggleColumnObject;
                }

                return item;
              });

            toggleColumnData = allToggleColumnData;
            if (toggleColumnData.filter((item) => item.checked)?.length < 7) {
              let i = 0;
              do {
                if (toggleColumnData[i]) {
                  toggleColumnData[i].checked = true;
                }

                i++;
              } while (i < 7);
              let j = 0;
              gridColumns = gridColumns.filter(
                (item) =>
                  Object.keys(users[0]).includes(item?.field) || !item?.field
              );
              do {
                if (gridColumns[j]) {
                  gridColumns[j].hide = false;
                }

                j++;
              } while (j < 7);
            }
          }
          currentColumnSelected = toggleColumnData.filter(
            (item) => item.checked
          )?.length;
          toggleColumnData = toggleColumnData.filter((item) =>
            Object.keys(users[0]).includes(item?.colName)
          );

          this.setState({
            currentColumnSelected,
            toggleColumnData,
            maxColumnSelected: toggleColumnData?.length,
            gridColumns: gridColumns.filter(
              (item) =>
                Object.keys(users[0]).includes(item?.field) || !item?.field
            ),
          });
        }

        const totalDataLength = users?.length ? users[0]?.totalResult : 0;

        let _users: any[] = [
          ...this.state.users?.filter((item) => item?.username),
          ...(users as IUserList[]),
        ];

        if (sortFieldName === "Status") {
          _users = _.sortBy(_users, ["status", "userStatus"]);
        }

        if (isPagination) {
          this.setState({
            users: _users,
            totalDataLength,
          });
        } else {
          this.setState({
            users:
              sortFieldName === "Status"
                ? _.sortBy(users, ["status", "userStatus"])
                : users,
            totalDataLength,
          });
        }
        // if (isDefaultDataLoad) {
        this.setState({ isInitialDataDataLoading: false });
        // }
        this.toggleDataLoading();
      })
      .catch((error) => {
        if ((error as any)?.message === "Cancel") {
          this.setState({ isInitialDataDataLoading: true });
          this.toggleDataLoading();
        } else {
          // if (isDefaultDataLoad) {
          this.setState({ isInitialDataDataLoading: false });
          // }
          this.toggleDataLoading();
        }
      });
  };

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  downloadUsersSheet = () => {
    const {
      hotelId: hid,
      searchText,
      status,
      role,
      payType,
      pageSize,
      currentPageNo,
      sortFieldName,
      toggleColumnData,
      activeStatus,
    } = this.state;

    const request: IUserExcelExportRequest = {
      searchText,
      status,
      role,
      payType,
      hid,
      pageIndex: currentPageNo,
      totalRecords: pageSize,
      tenantId: 0,
      sortBy: sortFieldName,
      sortDirection: "ASC",
      columnsToRemoveInExport: toggleColumnData
        .filter((item) => !item?.checked)
        ?.map((item) => this.capitalize(item?.colName)),
      activeStatus,
    };

    const fileName = `UserDetails-${moment().format("YY-MMDD hh.mm.ss")}`;
    UserManagement.downloadUsersSheet(request, fileName).then(
      (users: IUserList[] | null) => {}
    );
  };

  getAgGridOptions = () => {
    const gridOptions: GridOptions = {
      rowData: [],
      rowClass: "user-table-row",
      // getRowStyle: (params) => {
      //   let rowNo = params.node.rowIndex;
      //   let zIndex =
      //     (this.state.users.length === 0 ? 1 : this.state.users.length) * 1 -
      //     rowNo;

      //   return { zIndex: `${zIndex}` };
      // },
      getRowHeight: (params) => {
        const dataLength = params.data.payRoll?.toString()?.split(",").length;
        return dataLength > 1
          ? 22 * dataLength + (22 * dataLength < 56 ? 56 - 22 * dataLength : 0)
          : 56;
      },
      headerHeight: 48,
      frameworkComponents: {
        profileImageCellRenderer: this.profileImageCellRenderer,
        statusCellRenderer: this.statusCellRenderer,
        hotelIdsCellRenderer: this.hotelIdsCellRenderer,
        roleCellRenderer: this.roleCellRenderer,
        payRollCellRenderer: this.payRollCellRenderer,
        payRateCellRenderer: this.payRateCellRenderer,
        salaryCellRenderer: this.salaryCellRenderer,
        tripleDotCellRenderer: this.tripleDotCellRenderer,
        payTypeCellRenderer: this.payTypeCellRenderer,
        firstNameCellRenderer: this.firstNameCellRenderer,
        lastNameCellRenderer: this.lastNameCellRenderer,
        emailCellRenderer: this.emailCellRenderer,
        phoneCellRenderer: this.phoneCellRenderer,
        addressCellRenderer: this.addressCellRenderer,
        streetCellRenderer: this.streetCellRenderer,
        cityCellRenderer: this.cityCellRenderer,
        stateCellRenderer: this.stateCellRenderer,
        zipCellRenderer: this.zipCellRenderer,
        ssnCellRenderer: this.ssnCellRenderer,
        dobCellRenderer: this.dobCellRenderer,
        positionCellRenderer: this.positionCellRenderer,
        primaryContactCellRenderer: this.primaryContactCellRenderer,
      },
      onColumnMoved: (params: any) => {
        const columnCount = params.columnApi
          .getAllColumns()
          .filter((c) => c.isVisible()).length;
        const maxIndex = columnCount - 2;
        if (params.toIndex > maxIndex) {
          params.columnApi.moveColumnByIndex(params.toIndex, maxIndex);
        }
      },
      rowSelection: "single",
    };
    return gridOptions;
  };

  customCellRenderer(params): any {
    return <ReactPageLoader useas={"usersTableRow"} />;
  }

  footerBulkSelectAll = (e) => {
    let allUsers = [] as any;
    let ischecked = e.target.checked;
    let userArr = this.state.users.map((row) => {
      if (ischecked) {
        allUsers.push({ ...row, checked: ischecked });
      }
      return {
        ...row,
        checked: ischecked,
      };
    });

    const currentData: any[] = [];
    const currentNodeData: any[] = [];
    this.agGridRef?.current?.api?.forEachNode((node) => {
      const data = { ...node?.data, checked: ischecked };
      currentData.push(data);
      currentNodeData.push({ ...node, data });
    });
    this.agGridRef?.current.api.applyTransaction({
      update: currentData,
    });
    // this.agGridRef?.current.api.redrawRows()

    this.agGridRef?.current.api.refreshCells({
      force: true,
      suppressFlash: true,
      columns: ["username"],
      rowNodes: currentNodeData,
    });

    this.setState({ selectedRows: allUsers });
  };
  handleRowSelection = (e, rowData) => {
    let checked = e.target.checked;
    let { selectedRows, isInactive, hideChangeStatus } = this.state;
    if (!checked) {
      selectedRows = selectedRows.filter(
        (row) => row.username != rowData?.data.username
      );
    } else {
      selectedRows.push(rowData.data);
    }
    let activeUsers = selectedRows.filter((x) => x.status == "Active");
    let inActiveUsers = selectedRows.filter((x) => x.status == "Inactive");
    if (inActiveUsers?.length > 0) {
      isInactive = true;
    }

    if (activeUsers?.length > 0 || inActiveUsers?.length > 0) {
      hideChangeStatus = false;
    } else {
      if (selectedRows?.length == 0) {
        hideChangeStatus = false;
      } else {
        hideChangeStatus = true;
      }
    }

    let isSelected = selectedRows.filter(
      (x) => x.username == rowData.data.username
    );
    const finalRowData = {
      ...rowData.data,
      checked: isSelected?.length > 0 ? true : false,
    };

    selectedRows = selectedRows.map((x) => ({ ...x, checked: true }));
    rowData.node.updateData(finalRowData);

    const currentNodeData: any[] = [];

    this.agGridRef?.current?.api?.forEachNode((node) => {
      if (node.data.username === rowData.data.username) {
        const data = {
          ...node?.data,
          checked: isSelected?.length > 0 ? true : false,
        };
        currentNodeData.push({ ...node, data });
      }
    });

    this.agGridRef?.current.api.refreshCells({
      force: true,
      suppressFlash: true,
      columns: ["username"],
      rowNodes: currentNodeData,
    });

    this.setState({ selectedRows, isInactive, hideChangeStatus });
  };

  profileImageCellRenderer = (params) => {
    if (params.value !== undefined) {
      return (
        <div className="employee-name-wrapper d-flex align-items-center">
          <div className="form-check">
            {this.state.isBulkAction === true && (
              <input
                className="form-check-input"
                type="checkbox"
                checked={params.data.checked}
                onChange={(e) => this.handleRowSelection(e, params)}
              />
            )}
          </div>
          {params?.data?.imagePath ? (
            <img
              src={params?.data?.imagePath}
              className="profileimg"
              width="36"
              height="36"
              alt=""
            />
          ) : (
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="20" r="20" fill="#E5F5FF" />
              <path
                d="M20.0013 20.2819C22.5548 20.2819 24.6422 18.1945 24.6422 15.6409C24.6422 13.0874 22.5548 11 20.0013 11C17.4477 11 15.3604 13.0874 15.3604 15.6409C15.3604 18.1945 17.4477 20.2819 20.0013 20.2819Z"
                fill="#065A8C"
              />
              <path
                d="M27.9658 23.9905C27.8442 23.6865 27.6821 23.4027 27.4997 23.1393C26.5674 21.7612 25.1286 20.8492 23.5073 20.6263C23.3047 20.606 23.0817 20.6465 22.9196 20.7681C22.0684 21.3964 21.0551 21.7206 20.0013 21.7206C18.9474 21.7206 17.9341 21.3964 17.0829 20.7681C16.9208 20.6465 16.6979 20.5857 16.4952 20.6263C14.8739 20.8492 13.4148 21.7612 12.5028 23.1393C12.3204 23.4027 12.1583 23.7067 12.0367 23.9905C11.9759 24.1121 11.9962 24.2539 12.057 24.3755C12.2191 24.6592 12.4217 24.943 12.6041 25.1862C12.8878 25.5713 13.1918 25.9157 13.5364 26.24C13.8201 26.5237 14.1444 26.7872 14.4686 27.0506C16.0696 28.2464 17.9949 28.8746 19.981 28.8746C21.9671 28.8746 23.8924 28.2463 25.4934 27.0506C25.8176 26.8075 26.1419 26.5237 26.4256 26.24C26.7499 25.9157 27.0741 25.5712 27.3579 25.1862C27.5605 24.9227 27.743 24.6592 27.9051 24.3755C28.0063 24.2539 28.0266 24.112 27.9658 23.9905Z"
                fill="#065A8C"
              />
            </svg>
          )}
          <div className="employee-details d-flex flex-column">
            <EllipsisWithTooltip placement="bottom">
              <div className="employee-name">{params?.data?.username}</div>
            </EllipsisWithTooltip>
            <div className="employee-uid">{params?.data?.uniqueno}</div>
          </div>
        </div>
      );
    } else {
      //
      return (
        <div
          style={{
            width: "1400px",
            height: "84px",
            background: "#fff",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "250px",
              height: "60px",
              background: "#fff",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40px",
                background: "#fff",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "80%",
                  height: "10px",
                  background: "#ecf0f2",
                  borderRadius: "50px",
                  marginBottom: "10px",
                }}
              ></div>
              <div
                style={{
                  width: "80%",
                  height: "10px",
                  background: "#ecf0f2",
                  borderRadius: "50px",
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              width: "150px",
              height: "60px",
              background: "#fff",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40px",
                background: "#fff",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "80%",
                  height: "10px",
                  background: "#ecf0f2",
                  borderRadius: "50px",
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              width: "150px",
              height: "60px",
              background: "#fff",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40px",
                background: "#fff",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "80%",
                  height: "10px",
                  background: "#ecf0f2",
                  borderRadius: "50px",
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              width: "150px",
              height: "60px",
              background: "#fff",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40px",
                background: "#fff",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "80%",
                  height: "10px",
                  background: "#ecf0f2",
                  borderRadius: "50px",
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              width: "150px",
              height: "60px",
              background: "#fff",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40px",
                background: "#fff",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "80%",
                  height: "10px",
                  background: "#ecf0f2",
                  borderRadius: "50px",
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              width: "150px",
              height: "60px",
              background: "#fff",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40px",
                background: "#fff",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "80%",
                  height: "10px",
                  background: "#ecf0f2",
                  borderRadius: "50px",
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              width: "150px",
              height: "60px",
              background: "#fff",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40px",
                background: "#fff",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "80%",
                  height: "10px",
                  background: "#fff",
                  borderRadius: "50px",
                }}
              ></div>
            </div>
          </div>
        </div>
      );
    }
  };

  statusCellRenderer(params: any) {
    let className = "";
    if (
      (params?.data?.status === "Active" ||
        params?.data?.status === "Incomplete") &&
      params?.data?.userStatus !== "Complete"
    ) {
      className = "color-orange";
    } else if (params?.data?.status === "Inactive") {
      className = "color-grey";
    } else {
      className = "color-green";
    }
    return (
      <>
        {params?.data?.status ? (
          <div>
            {params?.data?.userStatus !== "Complete" &&
            params?.data?.status !== "Inactive" ? (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={"Labor-Cost"}>
                    {params?.data?.userStatus?.split(",")?.length > 1
                      ? params?.data?.userStatus?.split(",").map((x) => {
                          return (
                            <span>
                              {x}
                              <br></br>
                            </span>
                          );
                        })
                      : params?.data?.userStatus}
                  </Tooltip>
                }
              >
                <span className={`badge ${className}`}>Missing Info</span>
              </OverlayTrigger>
            ) : (
              <span className={`badge ${className}`}>
                {params?.data?.status === "Active"
                  ? params?.data?.userStatus === "Complete"
                    ? "Complete"
                    : ""
                  : params?.data?.status}
              </span>
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  payTypeCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="displayPayType">
            {params?.data?.displayEmployeeType}
          </span>
        </EllipsisWithTooltip>
      </div>
    );
  }

  firstNameCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="firstName">{params?.data?.firstName}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }

  lastNameCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="lastName">{params?.data?.lastName}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }
  emailCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="email">{params?.data?.email}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }

  phoneCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="phone1">{params?.data?.phone1}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }

  addressCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="address">{params?.data?.address}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }

  streetCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="street">{params?.data?.street}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }

  cityCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="city">{params?.data?.city}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }
  stateCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="state">{params?.data?.state}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }
  zipCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="zip">{params?.data?.zip}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }

  ssnCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="ssn">{params?.data?.ssn}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }
  dobCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="dob">{params?.data?.dob}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }

  positionCellRenderer(params: any) {
    const positionData = params?.data?.position?.toString().split(",");
    let roleHtml = [] as any;
    if (positionData?.length > 0) {
      positionData?.forEach((position) => {
        roleHtml.push(
          <EllipsisWithTooltip>
            <span className="position-item">{position}</span>
          </EllipsisWithTooltip>
        );
      });
    } else {
      roleHtml.push(
        <EllipsisWithTooltip>
          <span className="position-item">{params?.data?.position}</span>
        </EllipsisWithTooltip>
      );
    }
    return roleHtml;
  }

  primaryContactCellRenderer(params: any) {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="primaryContact">{params?.data?.primaryContact}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }

  toggleColumnCheck = (e: any, data: any, idx: any) => {
    let checked = e.target.checked;
    let gridColumns = _.cloneDeep(this.state.gridColumns);
    let { currentColumnSelected, maxColumnSelected } = this.state;
    for (let i = 0; i < gridColumns.length; i++) {
      if (gridColumns[i].field === data.colName) {
        gridColumns[i].hide = !checked;
        break;
      }
    }

    if (checked) {
      currentColumnSelected = currentColumnSelected + 1;
    } else {
      currentColumnSelected = currentColumnSelected - 1;
    }

    let toggleColumnData = _.cloneDeep(this.state.toggleColumnData);

    toggleColumnData[idx].checked = checked;
    let isSelectAllCols = false;
    if (
      toggleColumnData?.filter((item) => item?.checked)?.length ===
      maxColumnSelected
    ) {
      isSelectAllCols = true;
    }
    this.setState(
      { gridColumns, currentColumnSelected, toggleColumnData, isSelectAllCols },
      () => {
        this.columnWidthSizeToFit();
      }
    );
  };

  hotelIdsCellRenderer(params: any): any {
    //

    const hids = params?.data?.hiDs?.toString()?.split(",");

    if (hids?.length > 1) {
      hids?.shift();

      const hidsToolTipData = hids?.join(",  ");
      return (
        <div className="ehid-tag rm-bg d-flex align-items-center">
          <div className="hids">
            {params?.data?.hiDs?.toString()?.split(",")[0]}
          </div>
          <button type="button" className="showalltags d-flex">
            <div className="more-hids" style={{ paddingRight: "2px" }}>
              <EllipsisWithTooltip placement="top">
                <span className="data-ehids" data-tip={hidsToolTipData}>
                  <span>{hidsToolTipData}</span>+
                  {params?.data?.hiDs?.toString()?.split(",").length - 1} More
                  {""}
                  {/* <ReactTooltip
                  effect="solid"
                  event="mouseover mouseenter"
                  className="tooltipSOS"
                  place="bottom"
                  eventOff="mouseleave mouseout scroll mousewheel blur"
                  multiline={true}
                  data-html={true}
                  wrapper="div"
                /> */}
                </span>
              </EllipsisWithTooltip>
            </div>
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <span className="hids">{params?.data?.hiDs}</span>
        </div>
      );
    }
  }

  handleTripleDotMenu(item: any, rowData: any) {
    debugger;
    if (item.eventKey === "viewChangeLog") {
      this.showHideChangeLogModal(rowData);
      return;
    }
    let {
      showRoleModal,
      showStatusModal,
      isBulkAction,
      gmresetPassword,
      welcomeEmail,
      users,
      selectedRows,
      isInactive,
      IsLoginUserPasswordAssistant,
    } = this.state;

    selectedRows = [];
    if (rowData.status === "Inactive") {
      isInactive = true;
    }

    // if (item.eventKey === "changePassword" && !rowData.email && !rowData.phone1) {
    //   this.setState({ showMissingInfoModal: true });
    //   return;
    // }

    showStatusModal = false;
    showRoleModal = false;
    isBulkAction = false;
    gmresetPassword = false;
    welcomeEmail = false;
    switch (item.eventKey) {
      case "changeStatus":
        showStatusModal = true;
        selectedRows.push(rowData);
        break;
      case "changeRole":
        showRoleModal = true;
        selectedRows.push(rowData);
        break;
      case "bulkSelect":
        isBulkAction = true;
        break;
      case "welcomeEmail":
        selectedRows.push(rowData);
        welcomeEmail = true;
        break;
      default:
        selectedRows.push(rowData);
        break;
    }

    if (isBulkAction === true) {
      let userArr = users.map((row) => {
        return {
          ...row,
          allowBulkAction: row.status == "Incomplete" ? false : true,
          isBulkAction: true,
        };
      });
      this.setState({ users: userArr });
    }

    this.setState(
      {
        selectedRows,
        showStatusModal,
        showRoleModal,
        isBulkAction,
        gmresetPassword,
        isInactive,
      },
      () => {
        if (welcomeEmail) {
          this.sendWelcomeEmail();
        }
        if (item.eventKey === "changePassword") {
          if (IsLoginUserPasswordAssistant && rowData.isLowSecurity == "1") {
            this.handleShowResetPasswordModal();
          } else {
            this.setState({ gmresetPassword: true });

            //this.saveGMResetPassword(rowData?.primaryContact?.toString()?.toLowerCase());
          }
        }
      }
    );
  }

  closeBulkAction = () => {
    let { isBulkAction, users } = this.state;

    if (isBulkAction === true) {
      let userArr = users.map((row) => {
        return {
          ...row,
          checked: false,
          allBulkAction: row.status == "Incomplete" ? false : true,
          isBulkAction: false,
        };
      });
      this.setState({
        users: userArr,
        isBulkAction: false,
        showRoleModal: false,
        showStatusModal: false,
        resetPassword: false,
      });
    }
  };
  showHideChangeLogModal = (currentClickedRow: any = {}, isViewAll = false) => {
    this.setState({
      isViewAll,
      showChangeLogModal: !this.state.showChangeLogModal,
      currentClickedRow,
    });
  };

  // resetPassword = () => {
  //   this.setState({ resetPassword: false });
  //   if (this.state.selectedRows?.length <= 0) {
  //     Utils.toastError("No user selected to reset password", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       // containerId: "scheduleMain",
  //     });
  //     return;
  //   }

  //   let activeUsers = this.state.selectedRows?.filter(
  //     (x) => x.status == "Active"
  //   );

  //   if (activeUsers?.length == 0) {
  //     Utils.toastError(
  //       "Password can be reset for only active users.",
  //       {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //         // containerId: "scheduleMain",
  //       }
  //     );
  //     return;
  //   }

  //   let missingPhoneandEmail = [] as any;
  //   this.state.selectedRows.map((user) => {
  //     if(!user.email && !user.phone1){
  //       missingPhoneandEmail.push(user);
  //     }
  //   });

  //   if(missingPhoneandEmail?.length === 0) {
  //     this.setState({gmresetPassword: true});
  //     let UpdateUserRoles = [] as any;
  //     this.state.selectedRows.map((user) => {
  //       let usertoupdate: any = {};
  //       usertoupdate.Username = user.username;
  //       usertoupdate.RoleId = 0;
  //       usertoupdate.TenantId = user.tenantId;
  //       UpdateUserRoles.push(usertoupdate);
  //     });
  //   }else{
  //     this.setState({showMissingInfoModal: true,missingPhoneEmailUsers:missingPhoneandEmail});
  //   }
  // };

  resetPasswordBulkUser = () => {
    if (this.state.selectedRows?.length <= 0) {
      Utils.toastError("No user selected to reset password", {
        position: toast.POSITION.BOTTOM_RIGHT,
        // containerId: "scheduleMain",
      });
      return;
    }

    let activeUsers = this.state.selectedRows?.filter(
      (x) => x.status == "Active"
    );

    if (activeUsers?.length == 0) {
      Utils.toastError("Password can be reset for only active users.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        // containerId: "scheduleMain",
      });
      return;
    }

    let UpdateUserRoles = [] as any;
    this.state.selectedRows.map((user) => {
      let usertoupdate: any = {};
      usertoupdate.Username = user.username;
      usertoupdate.RoleId = 0;
      usertoupdate.TenantId = user.tenantId;
      UpdateUserRoles.push(usertoupdate);
    });

    UserManagement.ResetPasswordNotEmailed(UpdateUserRoles).then(
      (response: any) => {
        if (response?.result.success == true) {
          toast.success("Password reset URL sent successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        } else {
          toast.success(response.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        }
      }
    );
  };

  sendWelcomeEmail = () => {
    this.setState({ welcomeEmail: false });
    if (this.state.selectedRows?.length <= 0) {
      Utils.toastError("No user selected to reset password", {
        position: toast.POSITION.BOTTOM_RIGHT,
        // containerId: "scheduleMain",
      });
      return;
    }

    let activeUsers = this.state.selectedRows?.filter(
      (x) => x.status == "Active"
    );

    if (activeUsers?.length == 0) {
      Utils.toastError("Welcome Email can only be sent to active users", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    let UpdateUserRoles = [] as any;
    this.state.selectedRows.map((user) => {
      let usertoupdate: any = {};
      usertoupdate.Username = user.username;
      usertoupdate.RoleId = 0;
      usertoupdate.TenantId = user.tenantId;
      UpdateUserRoles.push(usertoupdate);
    });
    UserManagement.sendWelcomeEmail(UpdateUserRoles).then((response: any) => {
      this.closeBulkAction();
      if (response?.success == true) {
        toast.success("Welcome Email has been sent successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "scheduleMain",
        });
      } else {
        toast.success(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "scheduleMain",
        });
      }
    });
  };

  saveGMResetPassword = (mode) => {
    let UpdateUserRoles = [] as any;
    this.state.selectedRows.map((user) => {
      let usertoupdate: any = {};
      usertoupdate.Username = user.username;
      usertoupdate.RoleId = 0;
      usertoupdate.TenantId = user.tenantId;
      usertoupdate.passwordMode = mode;
      UpdateUserRoles.push(usertoupdate);
    });
    UserManagement.SendPasswordToUserandAssitance(UpdateUserRoles).then(
      (response: any) => {
        if (response?.result.success == true) {
          toast.success("Reset password link sent successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        } else {
          toast.success(response.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        }
      }
    );
  };

  tripleDotCellRenderer = (params: any): any => {
    let allowedBulkAction =
      !params.data?.email ||
      !params.data?.role ||
      !params?.data?.hiDs?.toString()?.trim()
        ? this.state.tableActions.filter((x) => x.eventKey !== "welcomeEmail")
        : this.state.tableActions;
    allowedBulkAction =
      params.data?.status === "Inactive"
        ? allowedBulkAction.filter((x) => x.eventKey !== "changePassword")
        : allowedBulkAction;
    allowedBulkAction =
      params.data?.status === "Inactive"
        ? allowedBulkAction.filter((x) => x.eventKey !== "welcomeEmail")
        : allowedBulkAction;
    //allowedBulkAction = (!params.data?.email || !params.data?.role  || !params?.data?.hiDs?.toString()?.trim()) ? allowedBulkAction.filter(x => x.eventKey !== "changePassword") : allowedBulkAction;

    if (
      !this.state.IsLoginUserPasswordAssistant &&
      params.data.isLowSecurity == "1"
    ) {
      allowedBulkAction = allowedBulkAction.filter(
        (x) => x.eventKey !== "changePassword"
      );
    }
    if (this.state.isChangeStatus === false) {
      allowedBulkAction = allowedBulkAction.filter(
        (x) => x.eventKey !== "changeStatus"
      );
    }
    if (this.state.isResetPassword === false) {
      allowedBulkAction = allowedBulkAction.filter(
        (x) => x.eventKey !== "changePassword"
      );
    }
    if (this.state.isChangeUserRole === false) {
      allowedBulkAction = allowedBulkAction.filter(
        (x) => x.eventKey !== "changeRole"
      );
    }
    if (this.state.isSendWelcomeEmail === false) {
      allowedBulkAction = allowedBulkAction.filter(
        (x) => x.eventKey !== "welcomeEmail"
      );
    }
    if (this.state.isBulkAction === false && allowedBulkAction?.length > 1) {
      return (
        <Dropdown className="more-action" alignRight>
          <Dropdown.Toggle
            className="btn-outline-primary btn btn-primary more"
            id="dropdown-more"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {allowedBulkAction?.map((item) => {
              if (
                !(
                  params.data.status === "Incomplete" &&
                  item.eventKey === "changeStatus"
                )
              ) {
                return (
                  <Dropdown.Item
                    disabled={this.state.isBulkAction}
                    onClick={() => this.handleTripleDotMenu(item, params.data)}
                    eventKey={item?.eventKey}
                  >
                    {item?.action}
                  </Dropdown.Item>
                );
              }
            })}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    return <div></div>;
  };

  roleCellRenderer(params): any {
    return (
      <div>
        <EllipsisWithTooltip>
          <span className="payRoll">{params?.data?.role}</span>
        </EllipsisWithTooltip>
      </div>
    );
  }

  payRateCellRenderer(params): any {
    const payRateData = params?.data?.payRate?.toString().split(",");
    let roleHtml = [] as any;
    if (payRateData?.length > 0) {
      payRateData?.forEach((payRate) => {
        roleHtml.push(
          <EllipsisWithTooltip>
            <span className="payrate-item">{payRate}</span>
          </EllipsisWithTooltip>
        );
      });
    } else {
      roleHtml.push(
        <EllipsisWithTooltip>
          <span className="payrate-item">{params?.data?.payRate}</span>
        </EllipsisWithTooltip>
      );
    }

    return roleHtml;
    //<div className="payrollID" dangerouslySetInnerHTML={{ __html: roleHtml }} /></>
  }

  salaryCellRenderer(params): any {
    const salaryData = params?.data?.salary?.toString().split("\n");
    const employeeType = params?.data?.employeeType;
    if (
      employeeType === "ExcludefromPayroll" ||
      employeeType === "Contractor" ||
      employeeType === "HourlyWage"
    ) {
      return <span className="payrate-item">N/A</span>;
    }

    let roleHtml = [] as any;

    if (salaryData?.length > 0) {
      salaryData?.forEach((salary) => {
        roleHtml.push(
          <EllipsisWithTooltip>
            <span className="payrate-item">
              {salary != "" && !isNaN(Number(salary))
                ? Utils.currencyFormat(salary)
                : salary}
            </span>
          </EllipsisWithTooltip>
        );
      });
    } else {
      roleHtml.push(
        <EllipsisWithTooltip>
          <span className="payrate-item">
            {params?.data?.salary != "" && !isNaN(Number(params?.data?.salary))
              ? Utils.currencyFormat(params?.data?.salary)
              : params?.data?.salary}
          </span>
        </EllipsisWithTooltip>
      );
    }

    return roleHtml;
  }

  payRollCellRenderer(params): any {
    const payRollData = params?.data?.payRoll?.toString().split(",");
    let roleHtml = [] as any;
    if (payRollData?.length > 0) {
      payRollData?.forEach((payRoll) => {
        roleHtml.push(
          <EllipsisWithTooltip>
            <span className="payroll-item">{payRoll}</span>
          </EllipsisWithTooltip>
        );
      });
    } else {
      roleHtml.push(
        <EllipsisWithTooltip>
          <span className="payroll-item">{params?.data?.payRoll}</span>
        </EllipsisWithTooltip>
      );
    }

    return roleHtml;
    //<div className="payrollID" dangerouslySetInnerHTML={{ __html: roleHtml }} /></>
  }

  closeSlideOut = (shouldReload = false) => {
    if (shouldReload) {
      this.getUserList();
    }
    this.setState({ showSlideout: false });
  };

  onSelectAllCols = (event) => {
    let colData = _.cloneDeep(this.state.toggleColumnData);
    if (event.target.checked) {
      colData = colData.map((item) => {
        item.checked = true;
        return item;
      });
    } else {
      colData = colData.map((item) => {
        if (!item?.disabled) {
          item.checked = false;
        }
        return item;
      });
    }

    let gridColumns = _.cloneDeep(this.state.gridColumns);
    let { currentColumnSelected, maxColumnSelected } = this.state;
    for (let i = 0; i < gridColumns.length; i++) {
      if (
        colData.find(
          (item) => item?.colName === gridColumns[i].field && !item?.disabled
        )
      ) {
        gridColumns[i].hide = !event.target.checked;
      }
    }

    currentColumnSelected = colData.filter((item) => item?.checked).length;

    this.setState(
      {
        gridColumns,
        currentColumnSelected,
        toggleColumnData: colData,
        isSelectAllCols: event.target.checked,
      },
      () => {
        this.columnWidthSizeToFit();
      }
    );
  };

  hideRoleModal = () => {
    let userArr = this.state.users.map((row) => {
      return {
        ...row,
        allowBulkAction: false,
        isBulkAction: false,
        checked: false,
      };
    });
    this.setState({ users: userArr });
    this.setState({
      showRoleModal: false,
      isBulkAction: false,
      selectedRows: [],
    });
    this.closeBulkAction();
  };

  hideStatusModal = () => {
    let userArr = this.state.users.map((row) => {
      return {
        ...row,
        allowBulkAction: false,
        isBulkAction: false,
        checked: false,
      };
    });
    this.setState({ users: userArr });
    this.setState({
      showStatusModal: false,
      isBulkAction: false,
      selectedRows: [],
    });
    this.closeBulkAction();
  };

  hideMissingInfoModal = () => {
    this.setState({ showMissingInfoModal: false });
    this.closeBulkAction();
  };

  hideGMResetModal = () => {
    this.setState({ gmresetPassword: false });
    this.closeBulkAction();
  };
  redirectOldSite = (event) => {
    User.GenerateToken()
      .then(async (result: any | null) => {
        if (result) {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let userName = storage === null ? 0 : (storage.userName as any);
          let mergeURL = result.result.mergeURL;
          let token = result.result.token;
          let tokenID = result.result.tokenID;
          let url =
            mergeURL +
            "?logInUser=" +
            btoa(userName) +
            "&tokenID=" +
            btoa(tokenID) +
            "&module=" +
            btoa(event) +
            "&logInType=2" +
            "&Key=" +
            btoa("DeductionCriteria") +
            "&Value=" +
            btoa("DeductionCriteria") +
            "&PageUrl=/Administration/DeductionCriteria.aspx?PageName=DeductionCriteria";
          window.open(url);
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  handleBulkSelect = () => {
    this.setState({ isBulkAction: true });
  };

  render() {
    const {
      users,
      statusList,
      missingStatusList,
      childStatusList,
      payTypes,
      userRoles,
      searchText,
      hotels,
      showAddUserModal,
      pageSize,
      currentPageNo,
      totalDataLength,
      isDataLoading,
      sortingFields,
      sortFieldName,
      gridColumns,
      isInitialDataDataLoading,
      status,
      hotelId,
      role,
      payType,
      showSlideout,
      userRowData,
      currentClickedRow,
      showChangeLogModal,
      isViewAll,
    } = this.state;

    return (
      <div className="userManagementModule users-page">
        {((showChangeLogModal && +currentClickedRow?.uniqueno > 0) ||
          isViewAll) && (
          <UsersLogHistory
            payPeriodStartDate={""}
            payPeriodEndDate={""}
            showChangeLogModal={showChangeLogModal}
            showHideChangeLogModal={this.showHideChangeLogModal}
            hotelName={""}
            hotelId={0}
            hideCrossAndClearFilters={false}
            shiftId={currentClickedRow?.uniqueno}
            shiftDate={""}
            accType={""}
            isViewAll={isViewAll}
          />
        )}

        <Container fluid className="body-sec">
          <div className="page-heading underline d-flex">
            <div className="mr-auto">Users</div>
            {this.state.isBulkAction === false && (
              <div className="action-group d-flex flex-row ml-auto">
                {/* <Button onClick={this.redirectOldSite}>Payroll Deduction Setting</Button> */}
                {this.state.isPageCreateNewUser && (
                  <Button onClick={this.addNewUser}>Add User</Button>
                )}
                <div className="header-popout">
                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.downloadUsersSheet}>
                        Export
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.showHideChangeLogModal({}, true)}
                      >
                        View Change Log
                      </Dropdown.Item>
                      <Dropdown.Item onClick={this.saveDefaultView}>
                        {" "}
                        Save as Default View{" "}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          this.handleTripleDotMenu(
                            this.state.bulkSelectitem,
                            ""
                          )
                        }
                      >
                        {" "}
                        Bulk Select{" "}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
          <div className="main-page">
            <div className="th-cover"></div>
            <div className="navigation-menus d-flex">
              <input
                type="text"
                value={searchText}
                disabled={this.state.isBulkAction}
                onChange={(e) => this.onSearchChange(e.target.value)}
                className="search-box form-control"
                placeholder="Search..."
              />

              <Form.Check
                type="switch"
                id="custom-switch"
                label="Show Inactive Users"
                checked={this.state.activeStatus === "Inactive"}
                disabled={isDataLoading}
                onChange={(event: any) =>
                  this.onActiveStatusChange(event.target)
                }
              />

              <div className="status-filter">
                {missingStatusList?.length > 0 && (
                  <SingleSearchDropdownList
                    allowSearch={false}
                    itemList={
                      this.state.activeStatus === "Active"
                        ? missingStatusList?.filter((x) => x?.id !== 7)
                        : missingStatusList
                    }
                    handleSelectedItem={this.onDDlFiltersChange.bind(
                      this,
                      "ddlStatus"
                    )}
                    isdisable={this.state.isBulkAction}
                    defaultText={"Search Status"}
                    defaultName={
                      +status < 0
                        ? "Status"
                        : (this.state.activeStatus === "Active"
                            ? missingStatusList?.filter((x) => x?.id !== 7)
                            : missingStatusList
                          ).find((item) => item?.id === status)?.name
                    }
                    isStatusbadge={false}
                    controlID="ddlStatus"
                  />
                )}
              </div>
              <div className="role-filter">
                {userRoles?.length > 0 && (
                  <SingleSearchDropdownList
                    itemList={userRoles}
                    allowSearch={true}
                    handleSelectedItem={this.onDDlFiltersChange.bind(
                      this,
                      "ddlRole"
                    )}
                    isdisable={this.state.isBulkAction}
                    defaultText={"Search Role"}
                    defaultName={
                      +role < 1
                        ? "Role"
                        : userRoles.find((item) => +item?.id === +role)?.name
                    }
                    isStatusbadge={false}
                    controlID="ddlRole"
                  />
                )}
              </div>

              <div className="payType-filter">
                {payTypes?.length > 0 && (
                  <SingleSearchDropdownList
                    itemList={payTypes}
                    allowSearch={false}
                    handleSelectedItem={this.onDDlFiltersChange.bind(
                      this,
                      "ddlPayType"
                    )}
                    isdisable={this.state.isBulkAction}
                    defaultText={"Search Pay Type"}
                    defaultName={
                      !payType
                        ? "Pay Type"
                        : payTypes.find((item) => item?.id === payType)?.name
                    }
                    isStatusbadge={false}
                    controlID="ddlPayType"
                  />
                )}
              </div>

              <div className="ehid-filter">
                {hotels?.length > 0 && (
                  <SingleSearchDropdownList
                    itemList={hotels}
                    allowSearch={true}
                    handleSelectedItem={this.onDDlFiltersChange.bind(
                      this,
                      "ddlHotel"
                    )}
                    isdisable={this.state.isBulkAction}
                    defaultText={"Search EHID"}
                    defaultName={
                      +hotelId < 1
                        ? "EHID"
                        : hotels.find((item) => +item?.id === +hotelId)?.name
                    }
                    isUmControl={true}
                    isStatusbadge={true}
                    controlID="ddlHotel"
                  />
                )}
              </div>

              <div className="navigationSort ml-auto">
                <Dropdown className="more-action bg-blue" alignRight>
                  <Dropdown.Toggle
                    className=""
                    id="dropdown-more"
                    disabled={this.state.isBulkAction}
                  >
                    <div className="d-flex align-items-center">
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M3.5 12L6.5 9H4.25V0H2.75V9H0.5L3.5 12ZM7.25 3H14V4.5H7.25V3ZM7.25 6H12.5V7.5H7.25V6ZM7.25 0H15.5V1.5H7.25V0ZM7.25 9H11V10.5H7.25V9Z" />
                      </svg>

                      {sortFieldName}
                      <div className="drop-arrow d-flex">
                        <FiChevronDown />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Header>SORT</Dropdown.Header>

                    {sortingFields.map((item: any, idx: any) => (
                      <Dropdown.Item
                        key={idx}
                        disabled={this.state.isBulkAction}
                        className={sortFieldName === item ? "active" : ""}
                        eventKey={item}
                        onClick={() => this.onSortingOrderChange(item)}
                      >
                        <div className="d-flex">
                          <div className="mr-auto"> {item}</div>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                              fill="#2CAF92"
                            />
                          </svg>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="user-management-table">
              {isInitialDataDataLoading ? (
                <ReactPageLoader useas={"usersTable"} />
              ) : (
                <OutsideClickHandler
                  onOutsideClick={() => this.tableOutsideClick()}
                >
                  <div
                    id="myGrid"
                    className={
                      this.state.isBulkAction === true
                        ? "ag-theme-balham static-header bulkactionHeight"
                        : "ag-theme-balham static-header"
                    }
                    style={{
                      //height: "calc(100vh - 296px)",
                      // height: "calc(100vh - 230px)",
                      // minHeight: "calc(100vh - 296px)",
                      overflowX: "auto",
                      overflowY: "auto",
                    }}
                  >
                    <div className={"customToggleDropdown static-toggle"}>
                      <div
                        className={
                          this.state.columnDropdownToggle
                            ? "ddnicon d-flex show"
                            : "ddnicon d-flex"
                        }
                        onClick={() => this.toggleColumnDropdownToggle(true)}
                      >
                        <span className="dropdown-icon">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M16.3914 2H3.60861C2.72146 2 2 2.79733 2 3.77778V16.2222C2 17.2027 2.72146 18 3.60861 18H16.3914C17.2785 18 18 17.2027 18 16.2222V6.44356V5.55467V4.66578V3.77778V3.77689C17.9992 2.79644 17.2769 2 16.3914 2ZM6.82582 16.2222H3.60861V6.44356H6.82582V16.2222ZM11.6516 16.2222H8.43442V6.44356H11.6516V16.2222ZM13.2602 16.2222V6.44356H16.3914L16.3922 16.2222H13.2602Z" />
                          </svg>
                        </span>
                        <span>
                          <FiChevronDown />
                        </span>
                      </div>
                      {this.state.columnDropdownToggle && (
                        <OutsideClickHandler
                          onOutsideClick={() =>
                            this.toggleColumnDropdownToggle(false)
                          }
                        >
                          <div className="toggle-drowpdown-wrapper">
                            <div className="dropdown-header d-flex">
                              <span className="header-name">Columns</span>
                              <span
                                className="close-icon"
                                onClick={() =>
                                  this.toggleColumnDropdownToggle(false)
                                }
                              >
                                &times;
                              </span>
                            </div>
                            <div className="dropdown-body">
                              <div className="selected-option-info">
                                {this.state.currentColumnSelected} out of{" "}
                                {this.state.maxColumnSelected} Selected
                              </div>

                              <div className="show-hide-column">
                                {this.state.toggleColumnData.map(
                                  (data: any, idx: any) => (
                                    <div
                                      className="dropdown-col-items d-flex form-check"
                                      key={idx}
                                    >
                                      <input
                                        className="form-check-input"
                                        key={idx}
                                        type="checkbox"
                                        checked={data.checked}
                                        disabled={data.disabled}
                                        onChange={(e: any) =>
                                          this.toggleColumnCheck(e, data, idx)
                                        }
                                      />

                                      <label
                                        className={
                                          data.disabled
                                            ? "form-check-label disabled"
                                            : data.checked
                                            ? "form-check-label active"
                                            : "form-check-label"
                                        }
                                      >
                                        {data.colDispalyName}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="dropdown-footer d-flex">
                              <div
                                className="select-all-items d-flex form-check"
                                key={"SelectAll"}
                              >
                                <input
                                  className="form-check-input"
                                  key={"SelectAll"}
                                  type="checkbox"
                                  checked={this.state?.isSelectAllCols}
                                  onChange={(e: any) => this.onSelectAllCols(e)}
                                />

                                <label
                                  className={
                                    this.state?.isSelectAllCols
                                      ? "form-check-label active"
                                      : "form-check-label"
                                  }
                                >
                                  Select All
                                </label>
                              </div>
                            </div>
                          </div>
                        </OutsideClickHandler>
                      )}
                    </div>
                    <div className="user-tbl-wrapper" ref={this.scrollArea}>
                      <AgGridReact
                        ref={this.agGridRef}
                        getRowNodeId={(data) => {
                          return data?.user_UniqueID;
                        }}
                        onGridReady={this.onGridReady}
                        domLayout={"autoHeight"}
                        onDragStopped={this.columnDragStop}
                        // style ={{minHeight: 200}}
                        rowData={users}
                        columnDefs={gridColumns}
                        enableCellChangeFlash={false}
                        suppressDragLeaveHidesColumns={true}
                        onCellClicked={(e: any) => {
                          if (
                            e.column &&
                            (e.column.colDef.cellClass === "triple-dot" ||
                              e.event.target.type === "checkbox")
                          ) {
                            e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection =
                              true;
                          } else {
                            e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection =
                              false;
                            this.handleShowSlideout(e);
                          }
                        }}
                        suppressRowDrag={true}
                        gridOptions={this.getAgGridOptions()}
                      />
                      <div className="scrollbar-cover"></div>
                    </div>
                    <div className="scrollbar-cover"></div>
                  </div>
                </OutsideClickHandler>
              )}
            </div>
            {totalDataLength > pageSize * currentPageNo && !isDataLoading && (
              <div className="loadMoreBtn">
                <Button
                  className="btn btn-ghost"
                  onClick={() => this.onPaginationchange()}
                >
                  Load More...
                </Button>
              </div>
            )}
            <>
              {showAddUserModal && this.state.userRoles?.length > 0 && (
                <AddUserModal
                  showAddUserModal={showAddUserModal}
                  userRoles={this.state.userRoles?.filter((x) => +x.id > -1)}
                  hideAddUserModal={this.hideAddUserModal}
                  showSlideoutOnAdd={this.showSlideoutonAdd}
                  payrollIntegrationHids={this.state.payrollIntegrationHids}
                />
              )}
            </>
            <>
              {showSlideout &&
                userRowData &&
                statusList?.length > 0 &&
                userRoles?.length > 0 && (
                  <UserDetailSlideOut
                    isChangeEHIDAccess={this.state.isChangeEHIDAccess}
                    isChangeUserRole={this.state.isChangeUserRole}
                    isEditGeneralInfo={this.state.isEditGeneralInfo}
                    isEditUserInfo={this.state.isEditUserInfo}
                    isEditLaborandPayrollInfo={
                      this.state.isEditLaborandPayrollInfo
                    }
                    isEditDocumentInfo={this.state.isEditDocumentInfo}
                    isEditSalesInfo={this.state.isEditSalesInfo}
                    isPageCreateNewUser={this.state.isPageCreateNewUser}
                    userData={userRowData}
                    statusList={childStatusList}
                    isNewUserAdded={false}
                    closeSlideOut={this.closeSlideOut}
                    handleEmitMessage={this.handleEmitMessage}
                    payrollIntegrationHids={this.state.payrollIntegrationHids}
                  ></UserDetailSlideOut>
                )}
            </>
          </div>
          {this.state.showRoleModal == true && (
            <ChangeRoleModal
              showModal={this.state.showRoleModal}
              hideRoleModal={this.showHideRoleModal}
              SelectedUsers={this.state.selectedRows}
              getUserList={this.getUserList}
            />
          )}
          {this.state.showResetPasswordModal == true && (
            <ResetPassword
              showModal={this.state.showResetPasswordModal}
              hideModal={this.showHideResetPasswordModal}
              selectedUsers={this.state.selectedRows}
              loginUserName={
                JSON.parse(localStorage.getItem("storage")!)?.userName
              }
              getUserList={this.getUserList}
            />
          )}

          {this.state.showStatusModal == true && (
            <ChangeStatusModal
              showModal={this.state.showStatusModal}
              hideStatusModal={this.showHideStatusModal}
              SelectedUsers={this.state.selectedRows}
              getUserList={this.getUserList}
              isInactive={this.state.isInactive}
            />
          )}
          {this.state.gmresetPassword == true && (
            <GMResetPassword
              showModal={this.state.gmresetPassword}
              hideStatusModal={this.showHideGMResetModal}
              SelectedUsers={this.state.selectedRows}
              getUserList={this.getUserList}
              isInactive={this.state.isInactive}
            />
          )}
          {this.state.showMissingInfoModal == true && (
            <MissingInfoModal
              showModal={this.state.showMissingInfoModal}
              missingPhoneEmailUsers={this.state.missingPhoneEmailUsers}
              hideMissingInfoModal={this.showHideMissingInfoModal}
            />
          )}
          {this.state.showEHIDModal && (
            <ChangeEHIDAccessModal
              showModal={this.state.showEHIDModal}
              hideEHIDModal={this.showHideEHIDModal}
              getUserList={this.getUserList}
              userNames={this.state.selectedRows?.map((r) => r.username)}
              selectedRows={this.state.selectedRows}
              payrollIntegrationHids={this.state.payrollIntegrationHids}
            />
          )}

          {this.state.isBulkAction === true && (
            <div className="tableFooter">
              <div className="bulkSelectFooter">
                {/* this.footerchk.current.indeterminate = false; */}
                <Form.Check
                  type="checkbox"
                  label=""
                  id="footerBulkCheckBox"
                  ref={(chkRef) => {
                    if (chkRef) {
                      this.checkAllRef = chkRef;
                      if (this.state.selectedRows?.length === users.length) {
                        chkRef.checked = true;
                      } else {
                        chkRef.checked = false;
                      }

                      if (
                        this.state.selectedRows?.length > 0 &&
                        !chkRef?.checked
                      ) {
                        chkRef.indeterminate = true;
                      } else {
                        chkRef.indeterminate = false;
                      }
                    }
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    this.footerBulkSelectAll(e);
                  }}
                />
                <span className="shift-selected-count">
                  {this.state.selectedRows?.length}{" "}
                  {this.state.selectedRows?.length <= 1 && "row"}
                  {this.state.selectedRows?.length > 1 && "rows"} selected
                </span>
                <div className="action-group d-flex flex-row mr-auto">
                  {this.state.isChangeUserRole === true && (
                    <Button
                      disabled={this.state.selectedRows?.length <= 0}
                      className="unassign btn-outline-primary"
                      onClick={() => this.setState({ showRoleModal: true })}
                    >
                      Change Role
                    </Button>
                  )}

                  {this.state.hideChangeStatus != true &&
                    this.state.isChangeStatus == true && (
                      <div className="assign-shift">
                        <Button
                          disabled={this.state.selectedRows?.length <= 0}
                          className="assign btn-outline-primary"
                          onClick={() =>
                            this.setState({ showStatusModal: true })
                          }
                        >
                          Change Status
                        </Button>
                      </div>
                    )}
                  {this.state.isResetPassword === true && (
                    <Button
                      disabled={this.state.selectedRows?.length <= 0}
                      className="remove btn-outline-primary"
                      onClick={() => this.resetPasswordBulkUser()}
                    >
                      Reset Password
                    </Button>
                  )}
                  {this.state.isSendWelcomeEmail === true && (
                    <Button
                      disabled={this.state.selectedRows?.length <= 0}
                      className="remove btn-outline-primary"
                      onClick={() => this.sendWelcomeEmail()}
                    >
                      Re-send Welcome Email
                    </Button>
                  )}
                  {this.state.isChangeEHIDAccess === true && (
                    <Button
                      disabled={this.state.selectedRows?.length <= 0}
                      className="remove btn-outline-primary"
                      onClick={() => this.showHideEHIDModal()}
                    >
                      Change EHID Access
                    </Button>
                  )}
                </div>
                <Button
                  className="closeButton btn-ghost-light"
                  onClick={() => this.closeBulkAction()}
                >
                  &times;
                </Button>
              </div>
            </div>
          )}
        </Container>
      </div>
    );
  }
}
