import React from "react";
import { Form, Dropdown, Button } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import $ from "jquery";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {Link} from 'react-router-dom'


export class ApproveDepartmentDetailsNew extends React.Component<any, any> {
  private textArea: any;
  constructor(props: any) {
    super(props);
    this.textArea = React.createRef();
    this.state = {
      approveDepartmentDetailsContent: props.approveDepartmentDetailsContent,
      outTime: props.outTime,
      approvalReason: this.props.table === "autoPunchOut" ? "Employee forgot to punch out" : "",
      isBack: false,
      copied: false,
    };
  }
  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if( this.textArea.current!=null && this.props!=prevProps)  {
      this.textArea.current?.focus();
    }
  }

  setFocus = () => {
    $("#reason-text").focus();

    if (this.props.table === "autoPunchOut") {
      if (this.state.isBack) {
        //this.setState({ approvalReason: "", isBack: false });
        this.setState({ isBack: false });
      } else {
        this.setState({ approvalReason: "User forgot to punch out" });
      }
    } else if (this.props.table === "overBudget") {
      this.setState({ approvalReason: "" });
    }
  };

  clearButton = (e: any) => {
    this.setState({ isBack: true, approvalReason: "" });
  };

  onTextBlur = (e: any) => {
    let value = e.target.value.trim();
    this.setState({ approvalReason: value });
  };

  onTextChange = (e: any) => {
    let value = e.target.value;
    this.setState({ approvalReason: value,copied: false });
  };

  onOutTimeChange = (e: any) => {
    const value = e.target.value;
    this.setState({ outTime: value });
  };

  handleCancel = () => {
    //debugger;
    this.setState({ outTime: this.props.outTime,  copied: false });
    if (
      this.props.table === "autoPunchOut" ||
      this.props.table === "departmentTab"
    ) {
      this.props.approveModalAction(false, this.state);
      this.setState({  approvalReason: this.props.table === "autoPunchOut" ?"Employee forgot to punch out" :"" });
    }
  };

  handlAapprove = () => {
    //debugger;
    if (
      this.props.table === "autoPunchOut" ||
      this.props.table === "departmentTab"
    ) {
      this.props.approveModalAction(true, this.state);
    }
  };

  onOutsideClick = (e) => {
   // this.setState({ outTime: this.props.outTime ,approvalReason:""});
   this.setState({ outTime: this.props.outTime });
    
  };

  updateCopyState = () => {
    if (this.state.approvalReason.trim().length > 0) {
      this.setState({ copied: true });
      setTimeout(() => {
        this.setState({ copied: false });
      }, 2000);
    } else {
    }
  };

  render() {
    return (
      <OutsideClickHandler
        onOutsideClick={(e) => {
          this.onOutsideClick(e);
        }}
      >
        <div
          className="approveDepartmentDetails"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {this.state.approveDepartmentDetailsContent.map(
            (item: any, idx: any) => (
              <div id={idx} key={idx}>
                <div className="title">
                <div className=""> {this.state.approveDepartmentDetailsContent[idx].title}</div>
                  {this.state.approvalReason.trim().length > 0 && this.state.approveDepartmentDetailsContent[idx].title.toLowerCase() ==="approve over budget" && (
                    <CopyToClipboard
                      text={this.state.approvalReason}
                      onCopy={() => this.updateCopyState()}
                    >
                      <Link to="#">
                      <svg
                        stroke="currentColor"
                        fill="#2caf92"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ float: "right" }}
                      >
                        <path d="M20,2H10C8.897,2,8,2.897,8,4v4H4c-1.103,0-2,0.897-2,2v10c0,1.103,0.897,2,2,2h10c1.103,0,2-0.897,2-2v-4h4 c1.103,0,2-0.897,2-2V4C22,2.897,21.103,2,20,2z M4,20V10h10l0.002,10H4z M20,14h-4v-4c0-1.103-0.897-2-2-2h-4V4h10V14z"></path>
                        <path d="M6 12H12V14H6zM6 16H12V18H6z"></path>
                      </svg></Link>
                    </CopyToClipboard>
                  )}

                  {this.state.copied ? (
                    <span style={{ color: "#b12a18", marginLeft: 90 }}>
                      Copied.
                    </span>
                  ) : null}
                </div>
                <div className="entry-body">
                  {/* {this.props.showOutTime && (
                    <Form.Group controlId="time-picker">
                      <Form.Label>Actual Out</Form.Label>
                      <input
                        type="time"
                        id="outTime"
                        name="outTime"
                        className="html-time-picker"
                        value={this.state.outTime}
                        onChange={(e: any) => this.onOutTimeChange(e)}
                      />
                    </Form.Group>
                  )} */}

                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>{this.state.approveDepartmentDetailsContent[idx].actionButtonName.toLowerCase() ==="add note" ? "": "Approval Reason"}</Form.Label>
                    <Form.Control
                      id="reason-text"
                      autoFocus
                      className="form-control"
                      as="textarea"
                      onChange={(e: any) => this.onTextChange(e)}
                      onBlur={(e: any) => this.onTextBlur(e)}
                      ref={this.textArea}
                      placeholder={
                        this.props.showPlaceHolder
                          // ? "User forgot to punch out"
                          // : "Explanation required..."
                      }
                      value={this.state.approvalReason}
                      onFocus={(e: any) => e.target.select()}
                      required
                    />
                    {/* <Button
                      type="reset"
                      className="clearBtn"
                      onClick={(e: any) => this.clearButton(e)}
                    ></Button> */}
                  </Form.Group>
                </div>

                <div className="action-link">
                  {this.state.approveDepartmentDetailsContent[
                    idx
                  ].actionButtonName.trim().length > 0 && (
                    <>
                      <Dropdown.Item
                        className="btn wht-bg dropdown-item cancel"
                        onClick={this.handleCancel.bind(this)}
                      >
                        {
                          this.state.approveDepartmentDetailsContent[idx]
                            .cancelButtonName
                        }
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="btn btn-primary dropdown-item approve"
                        onClick={this.handlAapprove.bind(this)}
                      >
                        {
                          this.state.approveDepartmentDetailsContent[idx]
                            .actionButtonName
                        }
                      </Dropdown.Item>
                    </>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </OutsideClickHandler>
    );
  }
}
