import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Utils } from '../../Common/Utilis';

const DefaultValue=5*60
export const IdleTimeOutModal = ({ showModal, handleClose, handleLogout }) => {
  const [seconds, setSeconds] = useState(DefaultValue);

  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === "secondsTime") {
      setSeconds(parseInt(newValue));
    }
  };

  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => {
        if (seconds > 0) {
          updateSeconds();
        } else {
         
          handleLogout(true);
        }
      }, 1000);
      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    const secondsTimeValue=parseInt(JSON.parse(localStorage.getItem("secondsTime") || "{}")) || 0
    if(secondsTimeValue <=0)
    {
      setSeconds(DefaultValue);

    }
    else{
      setSeconds(secondsTimeValue)
    }
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  const updateSeconds = (): void => {
    localStorage.setItem("secondsTime", JSON.stringify((seconds - 1)));
    setSeconds(seconds - 1);
    if(showModal && document.hidden) {
        if (localStorage.getItem("closeIdlePopUp")) {
          handleClose();
        } 
        if (localStorage.getItem("logOutFromIdlePopUp")) {
          handleLogout(true);
        }
    }
  };

  const onClose = () => {
    localStorage.setItem("secondsTime", JSON.stringify(DefaultValue));
    setSeconds(300);
    handleClose();
  };

  return (
    <Modal backdropClassName="idle-bckdrp" className="idle-timeout-modal" backdrop = {'static'}
    keyboard = {false} show={showModal} onHide={onClose}>
      <Modal.Header>
        <div className="modal-title h4">Still There?</div>
      </Modal.Header>
      <Modal.Body>
        <div className="description">You will be logged out automatically in {Utils.getMinutesInHHMMFormat(Math.round(seconds))} minutes</div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-danger" variant="danger" onClick={()=>handleLogout(false)}>
          Logout
        </Button>
        <Button variant="primary" onClick={onClose}>
          Stay Logged In
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
