import Instense from "./Axios-config";

export class LaborHolidays {
  public static laborHolidayList = async (
    selectedYear: string
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    let request = {} as any;
    request.tenantID = tenantID;
    request.year = selectedYear;
    const url = `/LaborHoliday/LaborHolidayList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static laborHolidayYears = async (): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/LaborHoliday/LaborHolidayYears`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static laborHolidayDetail = async (
    holidayID: number
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.holidayID = holidayID;
    const url = `/LaborHoliday/LaborHolidayDetail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static saveLaborHoliday = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    request.tenantID = tenantID;
    const url = `/LaborHoliday/SaveLaborHoliday`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static DeleteLaborHoliday = async (
    holidayID: number
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    let request = {} as any;

    request.holidayID = holidayID;
    request.tenantID = tenantID;
    const url = `/LaborHoliday/DeleteLaborHoliday`;
    return Instense.delete(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static UpdateHolidayStatus = async (
    holidayID: number,
    status: string
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    let request = {} as any;
    request.tenantID = tenantID;
    request.holiDayID = holidayID;
    request.status = status;
    const url = `/LaborHoliday/UpdateHolidayStatus`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static approveLaborHoliday = async (
    selectedYear: string
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.year = selectedYear;
    const url = `/LaborHoliday/ApproveLaborHoliday`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
}
