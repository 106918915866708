import * as React from "react";
import {
  Form,
  //  Dropdown
} from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
export class MultiSelectDropdown extends React.Component<any, any> {
  private btnSection: any;
  private searchSection: any;
  private dropDownFooter: any;
  toggleRef: any = React.createRef();
  private inputElement: any = React.createRef();

  constructor(props: any) {
    super(props);
    this.btnSection = React.createRef();
    this.searchSection = React.createRef();
    this.dropDownFooter = React.createRef();
    this.state = {
      selectedItems: [],
      filterData: [],
      filterDataCopy: [],
      opened: false,
      itemList: [],
      isSelectAll: props.isSelectAll,
      filtertitle: props.filtertitle,
      isSelect: true,
      inputValue: "",
      childClick: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
  }
  componentDidMount() {
    this.getData("onMount");
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.itemList !== this.props.itemList) {
      this.getData("listUpdate");
    }
  }
  onChipMouseOver = (): void => {
    this.setState({ childClick: true });
  };
  onChipMouseOut = (): void => {
    this.setState({ childClick: false });
  };

  resetMultiSelectDropDown =() =>{
    const itemList = this.props.itemList;
    
    this.handleClearAll(null)
    this.setState({ selectedItems: [],  
      filterData: itemList,
      itemList });
  }

  getData = (type: string) => {
    const itemList = this.props.itemList;

    this.setState({
      filterData: itemList,
      itemList,
    });

    // if (type === "listUpdate") {
      const activeChip = itemList.filter((itemList: any) => itemList.isChecked);
      this.setState({ selectedItems: activeChip });
    // }
  };
  /**For remove COA(s) list **/
  handleRemoveSpecificChip = (idx: number) => () => {
    if(this.props.disabled )
    {
      return
    }
    this.setState({ childClick: true });
    this.props.dataChange();
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];
    const removeSelect = this.getIndex(selectedItems[idx].value, "removeChip");
    itemList[removeSelect].isChecked = false;
    selectedItems.splice(idx, 1);
    this.setState({ selectedItems });
    this.setState({ itemList });
    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem);
    this.setState({ childClick: false });
  };

  /************checkbox check unchek********/
  handleCheckCheckbox = (idx: any) => (event: any) => {
    this.props.dataChange();
    const itemList = [...this.state.itemList];
    const selectedItems = [...this.state.selectedItems];

    this.setState({
      filterDataCopy: this.state.itemList,
    });
    const index = itemList.findIndex((xvalue) => xvalue.value === idx);
    itemList[index].isChecked = event.target.checked;

    idx = index;
    this.setState({ itemList });

    //Item push and remove
    const item = {
      ...itemList[idx],
      value: itemList[idx].value,
      isChecked: event.target.checked,
    };
    if (event.target.checked === true) {
      this.setState({
        selectedItems: [...this.state.selectedItems, item],
      });
    } else {
      const removeIndex = this.getIndex(itemList[idx].value, "selectbox");
      selectedItems.splice(removeIndex, 1);
      this.setState({ selectedItems });
    }
    const coaSelectedItem = this.state.itemList.filter(
      (itemList: { isChecked: boolean }) => itemList.isChecked === true
    );
    this.props.updateMultiSelectItem(coaSelectedItem);
  };
  //Get array Index
  getIndex(val: any, type: string) {
    if (type === "removeChip") {
      let itemList = [...this.state.itemList];
      return itemList.findIndex((obj) => obj.value === val);
    } else {
      let selectedItems = [...this.state.selectedItems];
      return selectedItems.findIndex((obj) => obj.value === val);
    }
  }
  handleChange(e: { target: { value: string } }) {
    const updatedList = this.state.itemList.filter(
      (item: { description: string }) => {
        return (
          item.description.toLowerCase().indexOf(e.target.value.toLowerCase()) >
          -1
        );
      }
    );

    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  updateSavedItem(data: any) {
    let allCoaList = [...this.state.itemList];
    allCoaList.forEach((element) => {
      element.isChecked = false;
    });
    this.setState({ itemList: allCoaList });
    data.forEach((element: { value: any }) => {
      this.state.itemList.filter(
        (itemList: { value: any }) => itemList.value === element.value
      )[0].isChecked = true;
    });
    this.setState({ selectedItems: data });
  }
  handleClick() {
    if(this.props?.disabled)
    return
    
    if (this.state?.childClick === true) {
      return false;
    }
    this.setState((prevState) => ({
      opened: !prevState.opened,
    }));
    // this.onFocusInput()
    this.setState(
      {
        filterData: this.state.filterData,
      },
      () =>
        this.dropDownFooter.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        })
    );
  }

  handleOutsideClick(e) {
    this.setState((prevState) => ({
      opened: false,
    }));
    const itemList = this.props.itemList;

    this.setState({
      filterData: itemList,
      itemList,
      inputValue: ""
    });
  }

  handleSelectAll(e: any) {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    if(this.state.inputValue) {

    allItemList = allItemList?.map((element) => {
      if(element.description.toLowerCase().indexOf(this.state?.inputValue?.toLowerCase()) >
      -1
      ) {
    
        element.isChecked = true;
      }
      
      return element;
    });
    }
    else {
      
      allItemList = allItemList?.map((element) => {
      
        element.isChecked = true;
    
      return element;
    });

  }
    this.setState(
      {
        selectedItems: allItemList?.filter(x => x?.isChecked),
        itemList: allItemList,
        filterData:allItemList,
        isSelect: false,
        opened: false,
        inputValue:""
      },
      () => this.dropDownFooter.current?.scrollIntoView()
    );
    this.props.updateMultiSelectItem(allItemList?.filter(x => x?.isChecked));
  }

  handleClearAll(e: any) {
    this.props.dataChange();
    let allItemList = [...this.state.itemList];
    allItemList = allItemList?.map((element) => {
    
        element.isChecked = false;
   

      return element;


    });
    this.setState({ itemList: allItemList, filterData:allItemList,isSelect: true, selectedItems: (allItemList?.filter(x => x?.isChecked) || []),inputValue:"" });
  
    this.props.updateMultiSelectItem((allItemList?.filter(x => x?.isChecked) || []));
  }

  onTabKeyDown = (e) => {
    e.preventDefault();
    if (!this.state.opened && e?.keyCode === 9) {
      this.handleClick();

    }
  };
   onFocusInput = () => {
    setTimeout(() => {
      this.inputElement?.current?.focus();
    }, 100);
  };

 

  render() {
    const hoverOutEllipsis = () => {
      $(".tooltip").removeClass("show");
    };

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div
          className={
            this.state.opened ? "multiSelectSearch opened" : "multiSelectSearch"
          }
        >
          <div
          // tabIndex={0}
           onKeyUp={(e) => this.onTabKeyDown(e)}
            ref={this.toggleRef}
            className={`fieldArea ${this.props?.disabled ? "disabled" : ""} `}
            onMouseOut={this.onChipMouseOut}
            onClick={this.handleClick}
          >
            {this.state.selectedItems?.length === 0 ? (
              <span className="placeholder">{this.props.placeholder}</span>
            ) : (
              <div className="chip" onMouseOut={this.onChipMouseOut}>

                <div className="chipSelect"   >
                  {this.state.selectedItems?.length === 1 ? <div>
                  {this.state.selectedItems.map((item) => <div className={`chipVal width-auto ${item.description?.length > 30 ? "max-chip-width":""}`}>
                            <EllipsisWithTooltip placement="bottom">
                              {item.description}
                            </EllipsisWithTooltip>
                  </div>)  }
                   {!this.props.disabled && this.props.removeItem &&( <button id="btnremove" type="button" className="cross"
                            onMouseOver={this.onChipMouseOver}
                             onClick={this.handleRemoveSpecificChip(0)}
                                                 >
                             <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" >
                               <path d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z" fill="#6A6E73" />
                             </svg>
                           </button>)}
                  </div> :
                   <div className="chipVal chipValueSelected">
                
                      {this.state.selectedItems?.length + " Selected"}
                    </div>

                        
                  }
                 
                </div>
              </div>
            )}
            <div
              onMouseOver={this.onChipMouseOver}
              className="coaSearch"
              ref={(searchSection) => {
                this.searchSection = searchSection;
              }}
            >
              <div className="dropDownPanel">
                <div className="SearchInput">
                  <input
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}        
                    placeholder={this.state.filtertitle}
                    value={this.state.inputValue}
                    ref={this.inputElement}
                  />
                </div>
                <ul onWheel={() => hoverOutEllipsis()}>
                  {this.state.filterData?.map((item: any, idx: any) => (
            
            
                      <li id={idx} key={idx}>
                        <Form.Check
                          // custom
                          type="checkbox"
                          key={"custom-s"+item.description + idx}
                          id={"custom-s" +item.description + idx}
                          label={
                            <>
                            <EllipsisWithTooltip placement="bottom">
                              {item.description} 
                            </EllipsisWithTooltip>
                            </>
                          }
                        
                          checked={item.isChecked}
                          onChange={this.handleCheckCheckbox(item.value)}
                        // style={{
                        //   overflow: "hidden",
                        //   textOverflow: "ellipsis",
                        //   width: 400,
                        // }}
                        />
                      </li>
                      
                 
                  ))}
                </ul>
                {this.state.isSelectAll && (
                  <div ref={this.dropDownFooter} className="footer">
                    {this.state.filterData?.length>0 && this.state.itemList?.length !==
                      this.state.selectedItems?.length && (
                        <button
                          id="selectAll"
                          type="button"
                          className="btn wht-bg txt-green"
                          onClick={this.handleSelectAll}
                        >
                          Select All
                        </button>
                      )}

                   {this.state.filterData?.length===0 && this.state.inputValue && (
                      <span>No data found</span>  
                    )}

                    {(this.state.filterData?.length>0 && (this.state.itemList?.length ===
                      this.state.selectedItems?.length))
                      &&
                      (
                        <button
                          id="ClearAll"
                          type="button"
                          className="btn wht-bg txt-green"
                          onClick={this.handleClearAll}
                        >
                          Clear All
                        </button>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}
