import Instense from "./Axios-config";
import {
  IReportGroup,
  IAllReportGroupResponseDto,
  IAllReportGroupResponseBrandDto,
  IDeportmentDto,
  IReportGroupSubGroupWithHotelCountDto,
} from "../Contracts/IReportParameter";
import { ModuleReports } from "./ModuleReports";

export class ReportGroup {
  public static getGetReportGroup = async (
    moduleName: string
  ): Promise<IReportGroup[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as IReportGroup;
    request.tenantID = tenantID;
    const url = `/Reports/GetReportGroup`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IReportGroup[];
      return result;
    });
  };

  public static GetReportGroupSubGroupWithHotelCount = async (
    moduleName: string,
    isAll: boolean = false,
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false,
    payrollSubscriptionRequired: boolean
  ): Promise<IReportGroupSubGroupWithHotelCountDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as any;
    request.tenantId = tenantID;
    request.userId = userUniqueID;

    let Module_Id = ModuleReports.getModuleId(localStorage.mainMenu as any);
    if (Module_Id == 4) {
      request.moduleName = "Labor Management";
    } else if (Module_Id == 8) {
      request.moduleName = "Accounting";
    }
    //  request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts ? 1 : 0;

    request.showOnlyHotelAccounts = showOnlyHotelAccounts ? 1 : 0;

    request.isAll = isAll ? 1 : 0;
    request.payrollSubscriptionRequired = payrollSubscriptionRequired ? 1 : 0;
    //debugger;

    const url = `/Reports/GetReportGroupSubGroupWithHotelCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data
        .result as IReportGroupSubGroupWithHotelCountDto[];
      return result;
    });
  };

  public static GetReportGroupSubGroupWithHotelCountForChart = async (
    moduleName: string,
    isAll: boolean = false,
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false
  ): Promise<IReportGroupSubGroupWithHotelCountDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as any;
    request.tenantId = tenantID;
    request.userId = userUniqueID;
    let Module_Id = ModuleReports.getModuleId(localStorage.mainMenu as any);

    request.moduleName = moduleName;

    //  request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts ? 1 : 0;

    request.showOnlyHotelAccounts = showOnlyHotelAccounts ? 1 : 0;

    request.isAll = isAll ? 1 : 0;

    const url = `/Reports/GetReportGroupSubGroupWithHotelCount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data
        .result as IReportGroupSubGroupWithHotelCountDto[];
      return result;
    });
  };

  public static GetReportGroupSubGroupWithHotelCountDashboard = async (
    moduleName: string,
    isAll: boolean = false,
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false
  ): Promise<IReportGroupSubGroupWithHotelCountDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as any;
    request.tenantId = tenantID;
    request.userId = userUniqueID;
    let Module_Id = ModuleReports.getModuleId(localStorage.mainMenu as any);

    request.moduleName = moduleName;

    //  request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts ? 1 : 0;

    request.showOnlyHotelAccounts = 1;

    request.isAll = isAll ? 1 : 0;

    const url = `/Reports/GetReportGroupSubGroupWithHotelCountDashboard`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data
        .result as IReportGroupSubGroupWithHotelCountDto[];
      return result;
    });
  };
}

export class AllReportGroup {
  public static getAllGetReportGroup = async (
    moduleName: number
  ): Promise<IAllReportGroupResponseDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as IAllReportGroupResponseDto;
    request.TenantID = tenantID;
    request.RGroupID = moduleName;
    const url = `/Reports/AllGroupReportDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAllReportGroupResponseDto[];
      return result;
    });
  };
}

export class AllHotelBrandSelector {
  public static GetHidSelectbyGroup = async (
    groupId: number,
    isAll: boolean = false,
    payrollSubscriptionRequired: boolean = false
  ): Promise<IAllReportGroupResponseBrandDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantId = tenantID;
    request.userId = userUniqueID;
    let Module_Id = ModuleReports.getModuleId(localStorage.mainMenu as any);
    if (Module_Id == 4) {
      request.moduleName = "Labor Management";
    } else if (Module_Id == 8) {
      request.moduleName = "Accounting";
    }
    request.rGroupID = groupId;
    request.isAll = isAll ? 1 : 0;
    request.payrollSubscriptionRequired = payrollSubscriptionRequired ? 1 : 0;
    const url = `/Reports/GetHotelIdsByGroupId`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAllReportGroupResponseBrandDto[];
      return result;
    });
  };
  public static GetHidSelectbyGroupForChart = async (
    moduleName: string,
    groupId: number,
    isAll: boolean = false,
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false
  ): Promise<IAllReportGroupResponseBrandDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantId = tenantID;
    request.userId = userUniqueID;
    let Module_Id = ModuleReports.getModuleId(localStorage.mainMenu as any);

    request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts ? 1 : 0;

    request.showOnlyHotelAccounts = showOnlyHotelAccounts ? 1 : 0;
    // request.moduleName = moduleName;
    request.rGroupID = groupId;
    request.isAll = isAll ? 1 : 0;
    const url = `/Reports/GetHotelIdsByGroupId`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAllReportGroupResponseBrandDto[];
      return result;
    });
  };

  public static GetHotelIdsByGroupIdDashboard = async (
    moduleName: string,
    groupId: number,
    isAll: boolean = false,
    notIncludeEnterpriseAccounts: boolean = false,
    showOnlyHotelAccounts: boolean = false
  ): Promise<IAllReportGroupResponseBrandDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.tenantId = tenantID;
    request.userId = userUniqueID;
    let Module_Id = ModuleReports.getModuleId(localStorage.mainMenu as any);

    request.moduleName = moduleName;
    request.notIncludeEnterpriseAccounts = notIncludeEnterpriseAccounts ? 1 : 0;

    request.showOnlyHotelAccounts = showOnlyHotelAccounts ? 1 : 0;
    // request.moduleName = moduleName;
    request.rGroupID = groupId;
    request.isAll = isAll ? 1 : 0;
    const url = `/Reports/GetHotelIdsByGroupIdDashboard`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IAllReportGroupResponseBrandDto[];
      return result;
    });
  };
}

export class Departments {
  public static GetDepartmentRepository = async (
    moduleName: number
  ): Promise<IDeportmentDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userName = storage === null ? 1 : (storage.userName as any);
    let request = {} as IDeportmentDto;
    request.UserName = userName;
    request.tenantID = tenantID;
    const url = `/Reports/GetSingleDepartment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IDeportmentDto[];
      return result;
    });
  };
}

export class SingleDepartments {
  public static GetDepartmentRepository = async (
    moduleName: number
  ): Promise<IDeportmentDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userName = storage === null ? 1 : (storage.userName as any);
    let request = {} as IDeportmentDto;
    request.UserName = userName;
    request.tenantID = tenantID;
    const url = `/Reports/GetDepartmentRepository`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IDeportmentDto[];
      return result;
    });
  };
}
