import React, { Component } from 'react'
import { Button, Form } from 'react-bootstrap';
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";
import { ToastContainer, toast } from "react-toastify";
import { resolve, reject } from "q";
import OutsideClickHandler from 'react-outside-click-handler';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from '../../../Common/Utilis';
export class InvoiceApprovalSubmit extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            markApprovalModal: false,
            finalApprove: false,
            approveUniqueNo: [],
            markApprovalArray: [],
        }
    }

    componentDidMount() {
        let data = this.props.data;
        this.checkInvoiceApproval(data);
    }

    hideApprovalModal = () => {
        this.setState({
            isSpinnerLoader: false,
            finalApprove: false,
            markApprovalModal: false
        }, () => {
            this.setState({ markApprovalArray: [], approveUniqueNo: [] }, () => {
                this.props.hideCheckApprovalModal(false);
            });
        })
    }

    checkInvoiceApproval = (row: any) => {
      
        let { markApprovalArray, approveUniqueNo } = this.state;
        approveUniqueNo.push(row);
        this.setState({ isSpinnerLoader: true, approveUniqueNo: approveUniqueNo.toString() });
        let request: any = {};
        request.InvoiceUniqueNo = approveUniqueNo.toString();
        InvoiceApprovalS.GetApproveInvoiceRule(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.length === 0) { // no rule
                        Utils.toastError("This invoice is already approved by some other user.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isSpinnerLoader: false, finalApprove: false }, () => {
                            this.props.hideCheckApprovalModal(true);
                            this.props.reloadTableData();
                        });
                    } else if (result.length === 1) {
                        result.forEach(item => {
                            let obj: any = {};
                            item['isCheck'] = true;
                            markApprovalArray.push(obj);
                        });
                        this.setState({ markApprovalArray: result }, () => {
                            this.approveInvoice();
                        })
                    } else if (result.length > 1) {
                        result.forEach(item => {
                            let obj: any = {};
                            if (item.appLavel === 1) {
                                item['key'] = "primary";
                                item['isCheck'] = true;
                            } else {
                                item['key'] = "secondary";
                                item['isCheck'] = false;
                            }
                            markApprovalArray.push(obj);
                        });
                        this.setState({ markApprovalArray: result, markApprovalModal: true });
                    }
                } else {
                    Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ isSpinnerLoader: false, finalApprove: false }, () => {
                        this.props.hideCheckApprovalModal(false);
                    });
                }
                resolve();
            })
            .catch((err: any) => {
                this.setState({ isSpinnerLoader: false, finalApprove: false }, () => {
                    this.props.hideCheckApprovalModal(false);
                });
                Utils.toastError(`Server Error ${err}`);
                reject();
            });
    }


    approvalcheckChange = (event, row) => {
        let { markApprovalArray } = this.state;
        let checked = event.target.checked;
        let index = markApprovalArray.indexOf(row);
        row.isCheck = checked;
        markApprovalArray[index] = row;
        this.setState({ markApprovalArray });
    }

    approveInvoice = () => {
        this.setState({ finalApprove: true })
        let { markApprovalArray, approveUniqueNo } = this.state;
        let request: any = {};
        let ruleIDList: any = [];
        markApprovalArray.forEach(item => item.isCheck && ruleIDList.push(item.ruleID));
        ruleIDList = ruleIDList.toString();
        request.RuleIds = ruleIDList
        request.InvoiceUniqueNo = approveUniqueNo;
        let successMessage = "";
        if (approveUniqueNo.split(",").length > 1) {
            successMessage = "Invoices approved successfully."
        } else {
            successMessage = "Invoice approved successfully."
        }
        InvoiceApprovalS.ApproveInvoiceWaitingOnMe(request)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.success) {
                        toast.success(successMessage, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isSpinnerLoader: false, finalApprove: false }, () => {
                            this.hideApprovalModal();
                            this.props.reloadTableData();
                            this.props.hideCheckApprovalModal(true);
                            this.props?.handleEmitMessage();
                        });
                    } else {
                        this.setState({ isSpinnerLoader: false, finalApprove: false }, () => {
                            this.props.hideCheckApprovalModal(true);
                            this.hideApprovalModal();
                        });
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } else {
                    Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ isSpinnerLoader: false, finalApprove: false }, () => {
                        this.props.hideCheckApprovalModal(false);
                        this.hideApprovalModal();
                    });
                }
                resolve();
            })
            .catch((err: any) => {
                this.setState({ isSpinnerLoader: false, finalApprove: false }, () => {
                    this.props.hideCheckApprovalModal(false);
                    this.hideApprovalModal();
                });
                Utils.toastError(`Server Error ${err}`);
                reject();
            });
    }

    render() {
        let isDisableApproveBtn = this.state.markApprovalArray.filter(item => item.isCheck).length > 0 ? false : true;
        const label = (item) => {
            return (
                <EllipsisWithTooltip placement='bottom'>{item.ruleName}</EllipsisWithTooltip>
            )
        }
        return (
            <>
                {this.state.markApprovalModal && (
                    <OutsideClickHandler onOutsideClick={this.hideApprovalModal}>
                        <div className="confirmModal wom_confirmModal">
                            <div className="title">Mark Approvals to Complete</div>
                            <div className="description wom_description">
                                <div className="wom_ApprovalsList">

                                    <ul>
                                        {this.state.markApprovalArray.map((item: any, index: any) => {
                                            let id = "idal_" + index;
                                            return (
                                                <li key={index}>
                                                    <Form.Check
                                                        className={item.key === "primary" ? "wom_formCheck isPrimary" : "wom_formCheck"}
                                                        type="checkbox"
                                                        label={label(item)}
                                                        id={id}
                                                        checked={item.isCheck}
                                                        onClick={(e: any) => this.approvalcheckChange(e, item)}
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>

                                </div>
                            </div>
                            <div className="action-link">
                                <Button className="btn btnCancel" onClick={this.hideApprovalModal}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="btn btnApprove"
                                    disabled={isDisableApproveBtn || this.state.finalApprove}
                                    onClick={(e: any) => this.approveInvoice()}
                                >
                                    Approve
                                </Button>
                            </div>
                        </div>
                    </OutsideClickHandler>
                )}
            </>
        )
    }
}
