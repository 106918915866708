export class scheduleComponents {
  public static confirmationInfo = (
    shiftType: string,
    row: any,
    schedule: any,
    callType: string
  ) => {
    // 
    if (callType === "ShiftCell") {
      let descMsg: string = `Are you sure you want to delete this schedule for ${row.employeeName} ?`;
      if (schedule.isMPOR) {
        descMsg = `Deleting this schedule will unassign the rooms assigned to the employee.\n \nAre you sure you want to delete this schedule for ${row.employeeName} ?`;
      }

      // this condition always needs to be at the bottom
      if (shiftType === "unAssigned") {
        descMsg =
          "Are you sure you want to delete this schedule from Unassigned Shifts ?";
      }

      return [
        {
          title: "Delete Schedule",
          desc: descMsg,
          cancleAction: "Cancel",
          ActionTrue: "Delete Schedule",
        },
      ];
    }
    if(callType === "shiftRow"){
      
      let descMsg:string = "Are you sure you want to remove this user from the schedule ?";
      
      if(scheduleComponents.checkMporForRow(row)){
        descMsg = `Removing this user from schedule will unassign the rooms assigned to the employee.\n \nAre you sure you want to remove this user from the schedule ?`;
      }
      return [
        {
          title:`Remove ${row.employeeName}`,
          desc: descMsg,
          cancleAction: "Cancel",
          ActionTrue: "Remove User",
        },
      ];
    }
    if (callType === "moprAssignAlert") {
      return [
        {
          title: "Reassign Schedule",
          desc : `Reassigning this schedule will unassign the rooms assigned to the employee.\n \nAre you sure you want to reassign this schedule for ${row.employeeName || row.employee} ?`,
          cancleAction: "Cancel",
          ActionTrue: "Reassign Schedule",
        },
      ];
    }
    if (callType === "onUnassignedMPORAlert") {
      return [
        {
          title: "Unassign Schedule",
          desc : `Unassigning this schedule will remove the rooms assigned to the employee.\n \nAre you sure you want to unassign this schedule ?`,
          cancleAction: "Cancel",
          ActionTrue: "Unassign Schedule",
        },
      ];
    }
    if (callType === "moprEditAlert") {
      return [
        {
          title: "Edit Schedule",
          desc : `Editing this schedule will unassign the rooms assigned to the employee.\n \nAre you sure you want to edit this schedule for ${row.employeeName} ?`,
          cancleAction: "Cancel",
          ActionTrue: "Edit Schedule",
        },
      ];
    }
    if (callType === "ondropMPORAlert") {
      return [
        {
          title: "Reassign Schedule",
          desc : `Reassigning this schedule will unassign the rooms assigned to the employee.\n \nAre you sure you want to reassign this schedule for ${row.employeeName} ?`,
          cancleAction: "Cancel",
          ActionTrue: "Reassign Schedule",
        },
      ];
    }
    if (callType === "bulkDeleteAlert") {
      return [
        {
          title: "Delete Schedule",
          desc: "Deleting schedule will unassign the rooms assigned to the employee.\n \nAre you sure you want to delete this schedule ?",
          cancleAction: "Cancel",
          ActionTrue: "Delete Schedule",
        },
      ];
    }
    if (callType === "bulkReassignAlert") {
      return [
        {
          title: "Reassign Schedule",
          desc : `Reassigning this schedule will unassign the rooms assigned to the employee.\n \nAre you sure you want to reassign this schedule ?`,
          cancleAction: "Cancel",
          ActionTrue: "Reassign Schedule",
        },
      ];
    }
    if (callType === "bulkUnassignAlert") {
      return [
        {
          title: "Unassign Schedule",
          desc : `Unassigning this schedule will remove the rooms assigned to the employee.\n \nAre you sure you want to unassign this schedule ?`,
          cancleAction: "Cancel",
          ActionTrue: "Unassign Schedule",
        },
      ];
    }
  };

  public static checkMporAlert = (row: any, schedule: any) => {
    
    // 
    let datee = new Date();
    let calenderYeare = datee.getFullYear().toString().substring(2,4);
    let calenderDatee = datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
    let calenderMonthe = datee.getMonth() + 1 > 9 ? datee.getMonth() + 1 : 0 + "" + (datee.getMonth() + 1);
    let tempDatee = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;
    if (schedule.isMPOR) {
      return true;
    }else if(schedule.shiftDate !== tempDatee){
        return false;
      } else{}
      return false;
  };

  public static checkMporForRow = (row:any)=>{
    
    let hasMPORShift :Boolean = false;
    for(let i = 1;i <= 7;i ++){
      let day = `day${i}`;
      let mPORShiftArray =  row[day].filter((item :any) =>{
     return item.isMPOR === true
     })
     if(mPORShiftArray.length !== 0){
      hasMPORShift = true;
      break;
     }
    }
    return hasMPORShift
  }
}
