import * as React from "react";
import { Dropdown } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
export class MapDataSearchDropdownList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            defaultSelectedName: props.defaultName,
            filterData: props.itemList,
            itemList: props.itemList,
            inputValue: "",
            searchText: props.defaultText,
            controlID: props.controlID,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e: any) {
        const updatedList = this.state.itemList.filter((item: any) => {
            return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
        });
        this.setState({ filterData: updatedList, inputValue: e.target.value });
    }
    handleDropdownChange(e: any) {
        this.setState({ inputValue: "", filterData: this.state.itemList });
        this.props.handleSelectedItem(e, this.state.controlID);
    }

    handleMouseOver(name) {
        this.props.handleMouseOver(name, this.state.controlID);
    }
    handleMouseLeave(name) {
        this.props.handleMouseLeave(name, this.state.controlID);
    }

    render() {
        const handleSelect = (eventKey: any) => {
            this.setState({ inputValue: "", defaultSelectedName: eventKey });
        };


        return (
            <Dropdown className="single-select-image" onSelect={handleSelect}>
                <Dropdown.Toggle id="map-dropdown">
                    <div className="d-flex align-items-center">
                        <div className="mr-auto select-name">
                            <EllipsisWithTooltip placement="bottom">{this.state.defaultSelectedName}</EllipsisWithTooltip>
                        </div>
                        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.29248 0.292725L4.99981 3.58606L1.70715 0.292725L0.29248 1.70739L4.99981 6.41406L9.70715 1.70739L8.29248 0.292725Z" fill="#84888C" />
                        </svg>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="search-sec">
                        <input type="text" className="form-control"
                            onChange={this.handleChange}
                            placeholder={this.state.searchText}
                            value={this.state.inputValue}
                        />
                    </div>
                    <div className="list-area">
                        <Dropdown.Item eventKey={"Select"} key={"Select"} onClick={() => this.handleDropdownChange({ name: "", value: "" })}>
                            <div className="d-flex align-items-center">
                                <div className={"display-item mr-auto"}>
                                    <div className="name">{"Select"}</div>
                                </div>
                            </div>
                        </Dropdown.Item>
                        {this.state.filterData.map((item: any, idx: any) => (
                            <Dropdown.Item eventKey={item.name} key={idx} onClick={() => this.handleDropdownChange(item)}
                                //onMouseOver={() => this.handleMouseOver(item.name)}
                                onMouseEnter={() => this.handleMouseOver(item.name)}
                                onMouseLeave={() => this.handleMouseLeave(item.name)}
                            //onMouseEnter={handleMouseEnter}
                            // onMouseLeave={handleMouseLeave}
                            >
                                <div className="d-flex align-items-center">
                                    <div className={item.status !== "" ? "is-status display-item mr-auto" : "display-item mr-auto"}>
                                        <div className="name"><EllipsisWithTooltip placement="bottom"  >{item.name}</EllipsisWithTooltip></div>
                                        <div className="val">
                                            <EllipsisWithTooltip placement="bottom">{item.value !== "" ? item.value : "No Value"}</EllipsisWithTooltip>
                                        </div>
                                    </div>
                                    {item.status !== "" && (
                                        <div className="status d-flex justify-content-center align-items-center">Mapped</div>
                                    )}
                                </div>
                            </Dropdown.Item>
                        ))}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
