import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-toastify/dist/ReactToastify.css";
import { Container, Dropdown } from "react-bootstrap";
import { reject } from "q";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Hotel } from "../../../Common/Services/Hotel";
import _ from "lodash";
import moment from "moment-timezone";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import caught from "../../../Common/Assets/Images/icons/all-caught.png";
import { Facility } from "../../../Common/Services/Facility";
import { IFacilityOverview } from "../../../Common/Contracts/IFacilities";
const noOfApis = 1;

const labelMappings = {
  openWorkOrder: "Open Work Orders",
  overdueWorkOrder: "Overdue Work Orders",
  overdueDailyTask: "Overdue Daily Tasks",
  openPMProject: "Open PM Projects",
  overduePMProject: "Overdue PM Projects",
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={
        className + " d-flex justify-content-center align-items-center"
      }
      onClick={onClick}
    >
      <svg
        width="7"
        height="10"
        viewBox="0 0 7 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z"
          fill="#2CAF92"
        />
      </svg>
    </div>
  );
}
function SampleNextArrowDisabled(props) {
  const { className, style, onClick } = props;
  return <></>;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={
        className + " d-flex justify-content-center align-items-center"
      }
      onClick={onClick}
    >
      <svg
        width="7"
        height="10"
        viewBox="0 0 7 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z"
          fill="#2CAF92"
        />
      </svg>
    </div>
  );
}

let slickSliderCount: any = 5;
export class FacilityOverview extends React.Component<any, any> {
  private hasPageWidth: any;
  private linkRef: any;
  private reportDivRef: any;
  private cardDivRef: any;
  constructor(props: any) {
    super(props);
    this.hasPageWidth = React.createRef();
    this.linkRef = React.createRef();
    this.reportDivRef = React.createRef();

    this.state = {
      scrollWidth: "auto",
      scrollItemList: 1,
      currentHotelTime: "",
      activeComparision: "",
      OverviewCounts: [],
      hotelIds: "",
      parameterSet: false,
      isSliderLoading: false,
      hotelAccessDetails: [],
      allOverviewCounts: [],
      allData: [],
      apisCalledCount: 0,
    };
  }

  componentDidMount() {
    this.setState({ isSliderLoading: true });

    this.getFacilityOverviewStats();
    this.loadHotelsDetails();

    window.addEventListener("resize", this.setslickSliderCount);
    var navToggleBtn: any = document.getElementById("nav-toggle-btn");
    navToggleBtn.addEventListener("click", this.navbuttonClicked);
    const location = this.props.location;
    if (location.state !== undefined) {
      const stateLength = Object.keys(location.state).length;
      if (stateLength > 0) {
        let { tabName } = location.state;
        // if (tabName === "unapprovedVendors" || tabName === "untrustedVendors") {

        this.optionBoxClick(tabName);
      }
    }
  }

  handleAlertPreference = () => {
    localStorage.setItem("mainMenu", "Profile");
    localStorage.setItem("subMenu", "Alert Preferences");
  };

  loadHotelsDetails() {
    Hotel.getUserHotelAccess("Register")
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ hotelAccessDetails: result });
        }
      })
      .catch((error) => {
        reject();
      });
  }

  navbuttonClicked = () => {
    this.setState({ navbtnClicked: true });
    this.setslickSliderCount();
  };

  UpdateOverViewCount = (overviewArray, obj, hiddenBoxCount) => {
    const updatedArray = [...overviewArray];
    if (obj.isVisible) {
      updatedArray.push(obj);
      if (
        updatedArray.filter((x) => x.name === "totalHiddenBoxes").length > 0
      ) {
        const index = updatedArray.findIndex(
          (x) => x.name === "totalHiddenBoxes"
        );
        let hiddenObjArr = updatedArray.splice(index, 1);
        updatedArray.push(hiddenObjArr[0]);
      }
    } else {
      hiddenBoxCount = hiddenBoxCount + 1;
    }

    return { updatedArray, hiddenBoxCount };
  };

  getFacilityOverviewStats = () => {
    Facility.GetFacilityOverviewStat()
      .then((data: IFacilityOverview[]) => {
        let OverviewCounts = [...this.state.OverviewCounts];
        let allOverviewCounts = [...this.state.allOverviewCounts];
        let hiddenBoxCount = 0;

        if (data.length > 0) {
          const flags: IFacilityOverview = data[0];

          const openWorkOrderSum: number = data
            ?.map((x) => +x?.openWorkOrder)
            ?.reduce((a, b) => a + b, 0);
          const openWorkOrderObj: any = {
            label: "Open Work Orders",
            counts: openWorkOrderSum,
            name: "openWorkOrder",
            isVisible: flags?.showOpenWorkOrder1 == 1 ? true : false,
          };
          if (openWorkOrderObj.counts > 0 && flags?.showOpenWorkOrder) {
            allOverviewCounts.push(openWorkOrderObj);
            const resultData = this.UpdateOverViewCount(
              OverviewCounts,
              openWorkOrderObj,
              hiddenBoxCount
            );
            OverviewCounts = resultData.updatedArray;
            hiddenBoxCount = resultData.hiddenBoxCount;
          }

          const overdueWorkOrderSum: number = data
            ?.map((x) => +x?.overdueWorkOrder)
            ?.reduce((a, b) => a + b, 0);
          const overdueWorkOrderObj: any = {
            label: "Overdue Work Orders",
            counts: overdueWorkOrderSum,
            name: "overdueWorkOrder",
            isVisible: flags?.showOverdueWorkOrder1 == 1 ? true : false,
          };
          if (overdueWorkOrderObj.counts > 0 && flags?.showOverdueWorkOrder) {
            allOverviewCounts.push(overdueWorkOrderObj);

            const resultData = this.UpdateOverViewCount(
              OverviewCounts,
              overdueWorkOrderObj,
              hiddenBoxCount
            );
            OverviewCounts = resultData.updatedArray;
            hiddenBoxCount = resultData.hiddenBoxCount;
          }

          const openPMProjectSum: number = data
            ?.map((x) => +x?.openPMProject)
            ?.reduce((a, b) => a + b, 0);
          const openPMProjectObj: any = {
            label: "Open PM Projects",
            counts: openPMProjectSum,
            name: "openPMProject",
            isVisible: flags?.showOpenPMProject1 == 1 ? true : false,
          };
          if (openPMProjectObj.counts > 0 && flags?.showOpenPMProject) {
            allOverviewCounts.push(openPMProjectObj);
            const resultData = this.UpdateOverViewCount(
              OverviewCounts,
              openPMProjectObj,
              hiddenBoxCount
            );
            OverviewCounts = resultData.updatedArray;
            hiddenBoxCount = resultData.hiddenBoxCount;
          }

          const overduePMProjectSum: number = data
            ?.map((x) => +x?.overduePMProject)
            ?.reduce((a, b) => a + b, 0);
          const overduePMProjectObj: any = {
            label: "Overdue PM Projects",
            counts: overduePMProjectSum,
            name: "overduePMProject",
            isVisible: flags?.showOverduePMProject1 == 1 ? true : false,
          };
          if (overduePMProjectObj.counts > 0 && flags?.showOverduePMProject) {
            allOverviewCounts.push(overduePMProjectObj);
            const resultData = this.UpdateOverViewCount(
              OverviewCounts,
              overduePMProjectObj,
              hiddenBoxCount
            );
            OverviewCounts = resultData.updatedArray;
            hiddenBoxCount = resultData.hiddenBoxCount;
          }

          const overdueDailyTaskSum: number = data
            ?.map((x) => +x?.overdueDailyTask)
            ?.reduce((a, b) => a + b, 0);
          const overdueDailyTaskObj: any = {
            label: "Overdue Daily Tasks",
            counts: overdueDailyTaskSum,
            name: "overdueDailyTask",
            isVisible: flags?.showOverdueDailyTask1 == 1 ? true : false,
          };
          if (overdueDailyTaskObj.counts > 0) {
            allOverviewCounts.push(overdueDailyTaskObj);
            const resultData = this.UpdateOverViewCount(
              OverviewCounts,
              overdueDailyTaskObj,
              hiddenBoxCount
            );
            OverviewCounts = resultData.updatedArray;
            hiddenBoxCount = resultData.hiddenBoxCount;
          }
        }

        this.setState(
          {
            allOverviewCounts,
            allData: data,
            OverviewCounts,
            isSliderLoading: false,
          },
          () => {
            this.getHiddenCount(hiddenBoxCount);
          }
        );
      })
      .finally(() => {
        this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
        // this.setState({ isSliderLoading: false });
        this.setslickSliderCount();
      });
  };

  handleShowHidden = () => {
    let OverviewCounts = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.allOverviewCounts))
    );
    OverviewCounts.sort((a, b) => {
      if (a.isVisible > b.isVisible) {
        return -1;
      }
      if (a.isVisible < b.isVisible) {
        return 1;
      }
      return 0;
    });

    this.setState({ OverviewCounts });
  };

  getHiddenCount = (hiddenCount) => {
    let OverviewCounts = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.OverviewCounts))
    );
    if (
      OverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0
    ) {
      const index = OverviewCounts.findIndex(
        (x) => x.name === "totalHiddenBoxes"
      );
      OverviewCounts[index].counts = OverviewCounts[index].counts + hiddenCount;
      OverviewCounts[index].label =
        "Show " + OverviewCounts[index].counts + " Hidden";
    } else {
      let totalHiddenBoxes = hiddenCount;
      const totalHiddenBoxesObj: any = {
        label: "Show " + totalHiddenBoxes + " Hidden",
        counts: totalHiddenBoxes,
        name: "totalHiddenBoxes",
        isVisible: true,
      };
      if (totalHiddenBoxes > 0) {
        OverviewCounts.push(totalHiddenBoxesObj);
      }
    }
    this.setState({ OverviewCounts }, () => {
      this.setslickSliderCount();
    });
  };

  optionBoxClick = (name: string) => {
    this.setState({ activeComparision: name });
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.handleFacilityRedirectionRow(row);
    },
  };

  handleFacilityRedirectionRow = (row: any) => {
    const { activeComparision } = this.state;
    let hotelid = row?.hotelid || "";

    if (
      activeComparision === "openWorkOrder" ||
      activeComparision === "overdueWorkOrder"
    ) {
      const location = {
        pathname: "/facilities-management/workorder",
        search: "",
      };
      const status = activeComparision == "openWorkOrder" ? "Open" : "Overdue";
      let url =
        this.props.history.createHref(location) +
        `?hotelId=${btoa(hotelid)}&status=${btoa(status)}&source=${btoa("overview")}`;
      url = encodeURI(url);
      window.location.replace(url);
      return;
    }

    if (activeComparision === "overdueDailyTask") {
      const location = {
        pathname: "/facilities-management/dailytask",
        search: "",
      };
      let url =
        this.props.history.createHref(location) +
        `?hotelId=${btoa(hotelid)}&source=${btoa("overview")}`;
      url = encodeURI(url);
      window.location.replace(url);
      return;
    }

    if (
      activeComparision === "openPMProject" ||
      activeComparision === "overduePMProject"
    ) {
      const location = {
        pathname: "/facilities-management/preventative",
        search: "",
      };

      const status = activeComparision == "openPMProject" ? "Open" : "Overdue";
      let url =
        this.props.history.createHref(location) +
        `?hotelId=${btoa(hotelid)}&status=${status}&source=overview`;
      url = encodeURI(url);
      window.location.replace(url);
      return;
    }
  };

  setslickSliderCount = () => {
    if (this.hasPageWidth.current !== null) {
      let navbtnClicked = this.state.navbtnClicked;
      let setTimeVal = 0;
      if (navbtnClicked) {
        setTimeVal = 500;
      } else {
        setTimeVal = 0;
      }
      setTimeout(() => {
        let hasWidth = this.hasPageWidth.current.clientWidth;
        //this is for slidesToShow
        // if (hasWidth < 540) {
        //   this.setState({ scrollItemList: 1 });
        // } else if (hasWidth > 540 && hasWidth < 810) {
        //   this.setState({ scrollItemList: 2 });
        // } else if (hasWidth > 810 && hasWidth < 1080) {
        //   this.setState({ scrollItemList: 3 });
        // } else if (hasWidth > 1080 && hasWidth < 1350) {
        //   this.setState({ scrollItemList: 4 });
        // } else if (hasWidth > 1350 && hasWidth < 1620) {
        //   this.setState({ scrollItemList: 5 });
        // } else {
        //   this.setState({ scrollItemList: 6 });
        // }
        if (hasWidth < 440) {
          this.setState({ scrollItemList: 1 });
        } else if (hasWidth > 440 && hasWidth < 660) {
          this.setState({ scrollItemList: 2 });
        } else if (hasWidth > 660 && hasWidth < 880) {
          this.setState({ scrollItemList: 3 });
        } else if (hasWidth > 880 && hasWidth < 1100) {
          this.setState({ scrollItemList: 4 });
        } else if (hasWidth > 1100 && hasWidth < 1320) {
          this.setState({ scrollItemList: 5 });
        } else if (hasWidth > 1320 && hasWidth < 1540) {
          this.setState({ scrollItemList: 6 });
        } else {
          this.setState({ scrollItemList: 7 });
        }
        this.setState({ scrollWidth: hasWidth });
      }, setTimeVal);
    }
  };

  render() {
    const {
      OverviewCounts,
      activeComparision,
      parameterSet,
      isSliderLoading,
      apisCalledCount,
    } = this.state;

    const getLabelName = () => {
      let DefaultLabel: any = "Open Work Orders";
      return labelMappings[activeComparision] || DefaultLabel;
    };

    const getModifiedTableData = () => {
      let tableData: any[] = [];
      const { allData } = this.state;
      switch (this.state.activeComparision) {
        case "openWorkOrder":
          tableData = allData
            ?.filter((x) => +x.openWorkOrder > 0)
            .map((x) => {
              x.counts = x.openWorkOrder;
              return x;
            });
          break;
        case "overdueDailyTask":
          tableData = allData
            ?.filter((x) => +x.overdueDailyTask > 0)
            .map((x) => {
              x.counts = x.overdueDailyTask;
              return x;
            });
          break;
        case "openPMProject":
          tableData = allData
            ?.filter((x) => +x?.openPMProject > 0)
            .map((x) => {
              x.counts = x?.openPMProject;
              return x;
            });
          break;
        case "overduePMProject":
          tableData = allData
            ?.filter((x) => +x?.overduePMProject > 0)
            .map((x) => {
              x.counts = x?.overduePMProject;
              return x;
            });
          break;
        case "overdueWorkOrder":
          tableData = allData
            ?.filter((x) => +x?.overdueWorkOrder > 0)
            .map((x) => {
              x.counts = x?.overdueWorkOrder;
              return x;
            });
          break;

        default:
          tableData = [];
          break;
      }

      return tableData;
    };

    let daycountlen = OverviewCounts.length;

    const settings = {
      className: "slider variable-width",
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: this.state.scrollItemList,
      slidesToScroll: this.state.scrollItemList,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    const columns = [
      {
        dataField: "letterCode",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="hotelDetailsData">
            <span className="hotelLetterAbbr">{row.letterCode}</span>
            <EllipsisWithTooltip placement="bottom">
              <span className="hotelFullName">{row.hotelName}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "counts",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="daysToApproveData">
            <span className="daysToApproveCount">{row.counts}</span>
            <span className="daysToApproveLabel">{getLabelName()}</span>
          </div>
        ),
      },
    ];

    return (
      <>
        <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"laborDayOverviewContainer"}
        />

        <div className="facility-overview">
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <div className="mr-auto">Facilities Management Overview</div>
              <div className="action-group d-flex">
                <Dropdown className="more-action three-dot" alignRight>
                  <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey="alertPreferences"
                      key="alertPreferences"
                      as={Link}
                      to="/alertPreferences"
                      onClick={() => this.handleAlertPreference()}
                    >
                      Alert Preferences
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {isSliderLoading &&
              daycountlen === 0 &&
              apisCalledCount !== noOfApis && (
                <ReactPageLoader useas={"laborDashboardTopBox"} />
              )}
            {daycountlen > 0 && (
              <div className="slider-option">
                <div className={"optionBoxCont d-flex"}>
                  <div
                    className="scroll-area"
                    ref={this.hasPageWidth}
                    style={{ width: `${this.state.scrollWidth}px` }}
                  >
                    <div className="slick-arrow slick-prev slick-disabled d-flex justify-content-center align-items-center shadow-remove">
                      <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z"
                          fill="#2CAF92"
                        ></path>
                      </svg>
                    </div>
                    <div className="slick-arrow slick-next slick-disabled d-flex justify-content-center align-items-center shadow-remove">
                      <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z"
                          fill="#2CAF92"
                        ></path>
                      </svg>
                    </div>
                    <Slider {...settings}>
                      {OverviewCounts.map((data: any, key: any) => (
                        <div
                          style={{ width: 250 }}
                          // className={
                          //   this.state?.isCheckComponent === ""
                          //     ? "optionBox d-flex align-items-center"
                          //     : this.state.activeComparision === data.name
                          //       ? "optionBox d-flex align-items-center active"
                          //       : "optionBox d-flex align-items-center"
                          // }

                          className={`optionBox d-flex align-items-center ${
                            this.state.activeComparision === data.name
                              ? "active"
                              : ""
                          } ${
                            data.name === "totalHiddenBoxes"
                              ? "hiddenCountBoxes"
                              : ""
                          }`}
                          onClick={(e) =>
                            data.name !== "totalHiddenBoxes"
                              ? this.optionBoxClick(data.name)
                              : e.stopPropagation()
                          }
                          key={key}
                        >
                          {data.name !== "totalHiddenBoxes" && (
                            <div
                              className={
                                data.name === "overdueDailyTask" ||
                                data.name === "overdueWorkOrder" ||
                                data.name === "overduePMProject"
                                  ? "circle d-flex align-items-center justify-content-center red"
                                  : "circle d-flex align-items-center justify-content-center"
                              }
                            >
                              {data.counts}
                            </div>
                          )}

                          <div
                            className="label-sec"
                            onClick={(e) =>
                              data.name === "totalHiddenBoxes"
                                ? this.handleShowHidden()
                                : ""
                            }
                          >
                            {data.label}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            )}
            <div ref={this.cardDivRef} className="facility-overview-details">
              {getModifiedTableData()?.length > 0 &&
                apisCalledCount === noOfApis &&
                !parameterSet && (
                  <>
                    {" "}
                    <h1>{getLabelName()}</h1>
                    <div className="facility-overview-details-table">
                      <BootstrapTable
                        keyField="hotelid"
                        data={getModifiedTableData() || []}
                        columns={columns}
                        rowEvents={this.rowEvents}
                        hover
                      />
                    </div>
                  </>
                )}
            </div>
            {daycountlen <= 0 && apisCalledCount === noOfApis && (
              <div className="caught d-flex align-items-center justify-content-center">
                <img src={caught} alt="You’re all caught up!" />
                <div className="label-sec"> You’re all caught up!</div>
              </div>
            )}
          </Container>
        </div>
      </>
    );
  }
}
