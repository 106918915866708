import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../../../Common/Utilis";

export class EmployeePositions extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeComparison: this.props.activeComparison,
            departmentPositions: [],
            pageloader: false,
        }
    }

    componentDidMount() {
        
        this.setState({ departmentPositions: this.props.data, pageloader: true, })
    }

    hoursMintustoMinuts(data: any) {
        if (data.includes(":")) {
            let total = 0;
            let subTotal = 0;
            const dataArray = data.split(":");
            let HH = Number(dataArray[0]);
            let MM = Number(dataArray[1]);
            if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                HH = HH < 0 ? (HH * -1) : HH
                MM = MM < 0 ? (MM * -1) : MM
                subTotal = ((HH * 60 + MM) * -1);
            } else {
                subTotal = (HH * 60 + MM)
            }
            return total = total + subTotal;
        }
    }

    getActualHighlight(actual: any, value: any) {
        actual = this.hoursMintustoMinuts(actual);
        value = this.hoursMintustoMinuts(value);
        let perrow = Number(Number(actual) - Number(value)) / Number(value) * 100
        if (Number(perrow > 0)) {
            if (Number(value) < Number(actual) && Number(perrow) <= Number(this.state.actualHighlight) && this.state.isOptionHighlight) {
                return ("main-ttl color-red");
            } else if (Number(value) < Number(actual) && Number(perrow) <= Number(this.state.actualHighlight) && !this.state.isOptionHighlight) {
                return ("main-ttl");
            } else if (Number(value) < Number(actual) && Number(perrow) > Number(this.state.actualHighlight)) {
                return ("main-ttl color-red");
            }
            else {
                return ("main-ttl");
            }
        }
        else {
            return ("main-ttl");
        }
    }

    getActualHighlightVariance(actual: any, value: any) {
        actual = this.hoursMintustoMinuts(actual);
        value = this.hoursMintustoMinuts(value);
        if (actual > value) {
            return ("main-ttl color-red");
        }
        else {
            return ("main-ttl");
        }
    }

    getVarianceHighlight(actual: any, budget: any) {
        actual = this.hoursMintustoMinuts(actual);
        budget = this.hoursMintustoMinuts(budget);
        if (actual > budget) {
            return ("sub-ttl mdy-fnt color-red");
        }
        else {
            return ("sub-ttl mdy-fnt");
        }
    }
    
    openSlideOut = (row , day)=>{
        
        let todayDate: any = new Date(this.props.calenderSelectDate);
        let date = row[day].date + "/" + todayDate.getFullYear(); //'04/19/2021';
        let hotelId = this.state.hidValue;
        if(this.props.openSlideOut.length === 2) 
        {
            this.props.openSlideOut(row ,day)
        }
        else {
        this.props.openSlideOut("",row.deptId,0,date,hotelId  , row?.deptName ,row?.positionName, row?.userName   
           , Utils.getMinutesFromHHMM(row[day].budget), false , false );
   
     }
    
    }

    render() {
       
        const positionColumns = [
            {
                dataField: "",
                text: "",
            },
            {
                dataField: "positionName",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="pos-section">
                        <div className="main-ttl pos-name">
                            <EllipsisWithTooltip placement="bottom">
                                {row.positionName}
                            </EllipsisWithTooltip>

                        </div>
                    </div>
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'cursor-pointer remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="display-area" onClick={() => this.openSlideOut(row , "day1")}>
                        {(row.day1.noShowcount > 0 || row.day1.apoCount > 0 || row.day1.manualCount > 0 || row.day1.otfCount > 0) && (
                            <div className="pipes-section">
                                <ul className="pip-icon-list pips d-flex justify-content-center">
                                    {row.day1.noShowcount > 0 && (<li className="pip red"></li>)}
                                    {row.day1.apoCount > 0 && (<li className="pip orange"></li>)}
                                    {(row.day1.manualCount > 0 || row.day1.otfCount > 0) && (<li className="pip blue"></li>)}
                                </ul>
                                <div className="pips pip-list">
                                    <ul>
                                        {row.day1.noShowcount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip red"></div>
                                                <div>{row.day1.noShowcount} No Show</div>
                                            </div>
                                        </li>)}
                                        {row.day1.apoCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip orange"></div>
                                                <div>{row.day1.apoCount} Auto Punch Out</div>
                                            </div>
                                        </li>)}
                                        {row.day1.manualCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day1.manualCount} Manual</div>
                                            </div>
                                        </li>)}
                                        {row.day1.otfCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day1.otfCount} OTF</div>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {this.props.activeComparison === 0 && (
                            <>
                                <div className={this.getActualHighlightVariance(row.day1.actual, row.day1.schedule)}>{row.day1.actual}</div>
                                <div className={this.getVarianceHighlight(row.day1.actual, row.day1.schedule)}>{row.day1.actualVariance}</div>
                            </>
                        )}
                        {this.props.activeComparison === 1 && (
                            <>
                                <div className={this.getActualHighlight(row.day1.actual, row.day1.schedule)}>{row.day1.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day1.schedule}</div>
                            </>
                        )}
                        {this.props.activeComparison === 2 && (
                            <> <div className={this.getActualHighlight(row.day1.actual, row.day1.schedule)}>{row.day1.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day1.schedule}</div></>
                        )}

                    </div>
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'cursor-pointer remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="display-area" onClick={() => this.openSlideOut(row,"day2")}>
                        {(row.day2.noShowcount > 0 || row.day2.apoCount > 0 || row.day2.manualCount > 0 || row.day2.otfCount > 0) && (
                            <div className="pipes-section">
                                <ul className="pip-icon-list pips d-flex justify-content-center">
                                    {row.day2.noShowcount > 0 && (<li className="pip red"></li>)}
                                    {row.day2.apoCount > 0 && (<li className="pip orange"></li>)}
                                    {(row.day2.manualCount > 0 || row.day2.otfCount > 0) && (<li className="pip blue"></li>)}
                                </ul>
                                <div className="pips pip-list">
                                    <ul>
                                        {row.day2.noShowcount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip red"></div>
                                                <div>{row.day2.noShowcount} No Show</div>
                                            </div>
                                        </li>)}
                                        {row.day2.apoCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip orange"></div>
                                                <div>{row.day2.apoCount} Auto Punch Out</div>
                                            </div>
                                        </li>)}
                                        {row.day2.manualCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day2.manualCount} Manual</div>
                                            </div>
                                        </li>)}
                                        {row.day2.otfCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day2.otfCount} OTF</div>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {this.props.activeComparison === 0 && (
                            <>
                                <div className={this.getActualHighlightVariance(row.day2.actual, row.day2.schedule)}>{row.day2.actual}</div>
                                <div className={this.getVarianceHighlight(row.day2.actual, row.day2.schedule)}>{row.day2.actualVariance}</div>
                            </>
                        )}
                        {this.props.activeComparison === 1 && (
                            <>  <div className={this.getActualHighlight(row.day2.actual, row.day2.schedule)}>{row.day2.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day2.schedule}</div></>
                        )}
                        {this.props.activeComparison === 2 && (
                            <> <div className={this.getActualHighlight(row.day2.actual, row.day2.schedule)}>{row.day2.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day2.schedule}</div></>
                        )}

                    </div>
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'cursor-pointer remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="display-area" onClick={() => this.openSlideOut(row, "day3")}>
                        {(row.day3.noShowcount > 0 || row.day3.apoCount > 0 || row.day3.manualCount > 0 || row.day3.otfCount > 0) && (
                            <div className="pipes-section">
                                <ul className="pip-icon-list pips d-flex justify-content-center">
                                    {row.day3.noShowcount > 0 && (<li className="pip red"></li>)}
                                    {row.day3.apoCount > 0 && (<li className="pip orange"></li>)}
                                    {(row.day3.manualCount > 0 || row.day3.otfCount > 0) && (<li className="pip blue"></li>)}
                                </ul>
                                <div className="pips pip-list">
                                    <ul>
                                        {row.day3.noShowcount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip red"></div>
                                                <div>{row.day3.noShowcount} No Show</div>
                                            </div>
                                        </li>)}
                                        {row.day3.apoCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip orange"></div>
                                                <div>{row.day3.apoCount} Auto Punch Out</div>
                                            </div>
                                        </li>)}
                                        {row.day3.manualCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day3.manualCount} Manual</div>
                                            </div>
                                        </li>)}
                                        {row.day3.otfCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day3.otfCount} OTF</div>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {this.props.activeComparison === 0 && (
                            <>
                                <div className={this.getActualHighlightVariance(row.day3.actual, row.day3.schedule)}>{row.day3.actual}</div>
                                <div className={this.getVarianceHighlight(row.day3.actual, row.day3.schedule)}>{row.day3.actualVariance}</div>
                            </>
                        )}
                        {this.props.activeComparison === 1 && (
                            <>  <div className={this.getActualHighlight(row.day3.actual, row.day3.schedule)}>{row.day3.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day3.schedule}</div></>
                        )}
                        {this.props.activeComparison === 2 && (
                            <> <div className={this.getActualHighlight(row.day3.actual, row.day3.schedule)}>{row.day3.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day3.schedule}</div></>
                        )}

                    </div>
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'cursor-pointer remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="display-area" onClick={() => this.openSlideOut(row,"day4")}>
                        {(row.day4.noShowcount > 0 || row.day4.apoCount > 0 || row.day4.manualCount > 0 || row.day4.otfCount > 0) && (
                            <div className="pipes-section">
                                <ul className="pip-icon-list pips d-flex justify-content-center">
                                    {row.day4.noShowcount > 0 && (<li className="pip red"></li>)}
                                    {row.day4.apoCount > 0 && (<li className="pip orange"></li>)}
                                    {(row.day4.manualCount > 0 || row.day4.otfCount > 0) && (<li className="pip blue"></li>)}
                                </ul>
                                <div className="pips pip-list">
                                    <ul>
                                        {row.day4.noShowcount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip red"></div>
                                                <div>{row.day4.noShowcount} No Show</div>
                                            </div>
                                        </li>)}
                                        {row.day4.apoCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip orange"></div>
                                                <div>{row.day4.apoCount} Auto Punch Out</div>
                                            </div>
                                        </li>)}
                                        {row.day4.manualCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day4.manualCount} Manual</div>
                                            </div>
                                        </li>)}
                                        {row.day4.otfCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day4.otfCount} OTF</div>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {this.props.activeComparison === 0 && (
                            <>
                                <div className={this.getActualHighlightVariance(row.day4.actual, row.day4.schedule)}>{row.day4.actual}</div>
                                <div className={this.getVarianceHighlight(row.day4.actual, row.day4.schedule)}>{row.day4.actualVariance}</div>
                            </>
                        )}
                        {this.props.activeComparison === 1 && (
                            <>  <div className={this.getActualHighlight(row.day4.actual, row.day4.schedule)}>{row.day4.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day4.schedule}</div></>
                        )}
                        {this.props.activeComparison === 2 && (
                            <> <div className={this.getActualHighlight(row.day4.actual, row.day4.schedule)}>{row.day4.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day4.schedule}</div></>
                        )}

                    </div>
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'cursor-pointer remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="display-area" onClick={() => this.openSlideOut(row, "day5")}>
                        {(row.day5.noShowcount > 0 || row.day5.apoCount > 0 || row.day5.manualCount > 0 || row.day5.otfCount > 0) && (
                            <div className="pipes-section">
                                <ul className="pip-icon-list pips d-flex justify-content-center">
                                    {row.day5.noShowcount > 0 && (<li className="pip red"></li>)}
                                    {row.day5.apoCount > 0 && (<li className="pip orange"></li>)}
                                    {(row.day5.manualCount > 0 || row.day5.otfCount > 0) && (<li className="pip blue"></li>)}
                                </ul>
                                <div className="pips pip-list">
                                    <ul>
                                        {row.day5.noShowcount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip red"></div>
                                                <div>{row.day5.noShowcount} No Show</div>
                                            </div>
                                        </li>)}
                                        {row.day5.apoCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip orange"></div>
                                                <div>{row.day5.apoCount} Auto Punch Out</div>
                                            </div>
                                        </li>)}
                                        {row.day5.manualCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day5.manualCount} Manual</div>
                                            </div>
                                        </li>)}
                                        {row.day5.otfCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day5.otfCount} OTF</div>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {this.props.activeComparison === 0 && (
                            <>
                                <div className={this.getActualHighlightVariance(row.day5.actual, row.day5.schedule)}>{row.day5.actual}</div>
                                <div className={this.getVarianceHighlight(row.day5.actual, row.day5.schedule)}>{row.day5.actualVariance}</div>
                            </>
                        )}
                        {this.props.activeComparison === 1 && (
                            <>  <div className={this.getActualHighlight(row.day5.actual, row.day5.schedule)}>{row.day5.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day5.schedule}</div></>
                        )}
                        {this.props.activeComparison === 2 && (
                            <> <div className={this.getActualHighlight(row.day5.actual, row.day5.schedule)}>{row.day5.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day5.schedule}</div></>
                        )}

                    </div>
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'cursor-pointer remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="display-area" onClick={() => this.openSlideOut(row, "day6")}>
                        {(row.day6.noShowcount > 0 || row.day6.apoCount > 0 || row.day6.manualCount > 0 || row.day6.otfCount > 0) && (
                            <div className="pipes-section">
                                <ul className="pip-icon-list pips d-flex justify-content-center">
                                    {row.day6.noShowcount > 0 && (<li className="pip red"></li>)}
                                    {row.day6.apoCount > 0 && (<li className="pip orange"></li>)}
                                    {(row.day6.manualCount > 0 || row.day6.otfCount > 0) && (<li className="pip blue"></li>)}
                                </ul>
                                <div className="pips pip-list">
                                    <ul>
                                        {row.day6.noShowcount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip red"></div>
                                                <div>{row.day6.noShowcount} No Show</div>
                                            </div>
                                        </li>)}
                                        {row.day6.apoCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip orange"></div>
                                                <div>{row.day6.apoCount} Auto Punch Out</div>
                                            </div>
                                        </li>)}
                                        {row.day6.manualCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day6.manualCount} Manual</div>
                                            </div>
                                        </li>)}
                                        {row.day6.otfCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day6.otfCount} OTF</div>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {this.props.activeComparison === 0 && (
                            <>
                                <div className={this.getActualHighlightVariance(row.day6.actual, row.day6.schedule)}>{row.day6.actual}</div>
                                <div className={this.getVarianceHighlight(row.day6.actual, row.day6.schedule)}>{row.day6.actualVariance}</div>
                            </>
                        )}
                        {this.props.activeComparison === 1 && (
                            <>  <div className={this.getActualHighlight(row.day6.actual, row.day6.schedule)}>{row.day6.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day6.schedule}</div></>
                        )}
                        {this.props.activeComparison === 2 && (
                            <> <div className={this.getActualHighlight(row.day6.actual, row.day6.schedule)}>{row.day6.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day6.schedule}</div></>
                        )}
                    </div>
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'cursor-pointer remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="display-area" onClick={() => this.openSlideOut(row, "day7")}>
                        {(row.day7.noShowcount > 0 || row.day7.apoCount > 0 || row.day7.manualCount > 0 || row.day7.otfCount > 0) && (
                            <div className="pipes-section">
                                <ul className="pip-icon-list pips d-flex justify-content-center">
                                    {row.day7.noShowcount > 0 && (<li className="pip red"></li>)}
                                    {row.day7.apoCount > 0 && (<li className="pip orange"></li>)}
                                    {(row.day7.manualCount > 0 || row.day7.otfCount > 0) && (<li className="pip blue"></li>)}
                                </ul>
                                <div className="pips pip-list">
                                    <ul>
                                        {row.day7.noShowcount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip red"></div>
                                                <div>{row.day7.noShowcount} No Show</div>
                                            </div>
                                        </li>)}
                                        {row.day7.apoCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip orange"></div>
                                                <div>{row.day7.apoCount} Auto Punch Out</div>
                                            </div>
                                        </li>)}
                                        {row.day7.manualCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day7.manualCount} Manual</div>
                                            </div>
                                        </li>)}
                                        {row.day7.otfCount > 0 && (<li>
                                            <div className="d-flex">
                                                <div className="pip blue"></div>
                                                <div>{row.day7.otfCount} OTF</div>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {this.props.activeComparison === 0 && (
                            <>
                                <div className={this.getActualHighlightVariance(row.day7.actual, row.day7.schedule)}>{row.day7.actual}</div>
                                <div className={this.getVarianceHighlight(row.day7.actual, row.day7.schedule)}>{row.day7.actualVariance}</div>
                            </>
                        )}
                        {this.props.activeComparison === 1 && (
                            <>  <div className={this.getActualHighlight(row.day7.actual, row.day7.schedule)}>{row.day7.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day7.schedule}</div></>
                        )}
                        {this.props.activeComparison === 2 && (
                            <> <div className={this.getActualHighlight(row.day7.actual, row.day7.schedule)}>{row.day7.actual}</div>
                                <div className="sub-ttl mdy-fnt">{row.day7.schedule}</div></>
                        )}

                    </div>
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'move-header',
                headerClasses: 'move-header',                
            },
            {
                dataField: "",
                text: "",
                classes: 'cursor-pointer',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="">
                        {this.props.activeComparison === 0 && (
                            <>
                                <div className={this.getActualHighlightVariance(row.totalActual, row.totalSchedule)}>{row.totalActual}</div>
                                <div className={this.getVarianceHighlight(row.totalActual, row.totalSchedule)}>{row.actualTotalvarianceInHour}</div>
                            </>
                        )}
                        {this.props.activeComparison === 1 && (
                            <>  <div className={this.getActualHighlight(row.totalActual, row.totalSchedule)}>{row.totalActual}</div>
                                <div className="sub-ttl mdy-fnt">{row.totalSchedule}</div></>
                        )}
                        {this.props.activeComparison === 2 && (
                            <> <div className={this.getActualHighlight(row.totalActual, row.totalSchedule)}>{row.totalActual}</div>
                                <div className="sub-ttl mdy-fnt">{row.totalSchedule}</div></>
                        )}

                    </div>
                },
            },
            {
                dataField: "totalot",
                text: "",
                classes: 'cursor-pointer',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="">
                        {(row.totalot !== "0:00") && (
                            <div className="main-ttl color-red">{row.totalot}</div>
                        )}
                    </div>
                },
            },
            {
                dataField: "totaldot",
                text: "",
                classes: 'cursor-pointer',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <div className="">
                        {(row.totaldot !== "0:00") && (
                            <div className="main-ttl color-red">{row.totaldot}</div>
                        )}
                    </div>
                },
            },
            {
                dataField: "",
                text: "",
            },
        ];


        return (
            <div>
                {(!this.state.pageloader &&
                    <div className="loader-responsive">
                        <div className="performanceList position-list">
                            <div className="tableList">
                                <ul>
                                    <li className="d-flex">
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="d-flex">
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                        <div className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                {(this.state.pageloader &&
                <div className="position-list remove-arrow">
                     <BootstrapTable
                        keyField="positionID"
                        data={this.state.departmentPositions}
                        columns={positionColumns}
                        hover
                    />
                </div>                   
                )}
            </div>
        );
    }

}