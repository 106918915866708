import * as React from "react";
import { User } from "../Common/Services/User";

class LogoutComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    ;
    User.isAuthenticated = false;
    User.apiLoginResponse = null;
    localStorage.clear();
    sessionStorage.clear();
    // window.location.href = "https://inn-flow-v2-dev.azurewebsites.net/login";
    window.location.href = window.location.origin;
  }

  render() {
    return <div className="login-bg"></div>;
  }
}

export const Logout = LogoutComponent;
