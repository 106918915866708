import * as React from "react";
import { Dropdown, 
  // OverlayTrigger, Tooltip
 } from "react-bootstrap";
//import { SingleDepartments } from "../../Common/Services/ReportGroup";
import { SingleDepartments } from '../Services/ReportGroup';
import {IDeportmentDto} from "../../Common/Contracts/IReportParameter";
//import { SingleDepartments } from "../../Common/Services/ReportGroup";


export class SingleAllDepartmentSelect extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      hotelNameModal: "Select Department",
      filterData: [],
      hotelList: [],
      inputValue: "",
      
    };
    this.handleChangeEhid = this.handleChangeEhid.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);

  }


  componentDidMount() {

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let username= storage === null ? 1 : (storage.userName as any);
    let UconcatinateT : any =tenantID +','+username;
    SingleDepartments.GetDepartmentRepository(UconcatinateT)
      .then(async (result: IDeportmentDto[] | null) => {
        if (result != null) {
         // result.push("All Depoartment");
         const {defaultValue} = this.props;
         if(defaultValue) {
           const item = result.find(r=> +r?.deptID === +defaultValue);
           this.handleDropdownChange(item);
           this.hidSelectModal(item?.deptName);
         } else {
          this.props.isCopyBudgetId('0',"All");
         }
         
          this.setState({ filterData: result, hotelList: result });
        }
      })
      .catch((error) => {
      });
  }
 
  componentDidUpdate = (prevProps) => {
    const { defaultValue } = this.props;
    if (!defaultValue && defaultValue !== prevProps.defaultValue) {
      if (+defaultValue === 0) {
        this.setState({ hotelNameModal: "All" });
      } else {
        this.setState({ hotelNameModal: "Select Department" });
      }
    }
  };

  handleChangeEhid(e: { target: { value: string; }; }) {
   
    const updatedList = this.state.hotelList.filter(item => {
      return (
        item.deptName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
   
  }

  handleDropdownChange(e: any) {
    this.props.isCopyBudgetId(e.deptID,e.deptName);
    this.resetSerchBox() ;    
   

  }

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.hotelList }); 


  };


   hidSelectModal = (eventKey: any) => {  
      
    this.setState({ inputValue: "" });
    this.setState({ hotelNameModal: eventKey });
  };

  render() {



   

    const onFocusInput = (eventKey: any) => {
     
      setTimeout(() => {
        this.inputElement.current.focus();
      }, 100)
    };



    return (
      <Dropdown className="single-select" onSelect={this.hidSelectModal} onClick={onFocusInput}  >
        <Dropdown.Toggle id="Modal-hid">
          {this.state.hotelNameModal ===
            "Select Department" && (
              <span className="placeholder">
                {this.state.hotelNameModal}
              </span>
            )}
          {this.state.hotelNameModal !==
            "Select Department" && (
              this.state.hotelNameModal
            )}

        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="search-sec" >
            <input
              type="text"
              className="form-control"
              onChange={this.handleChangeEhid}
              placeholder="Filter Departments"
              value={this.state.inputValue}
              ref={this.inputElement}
            />
          </div>
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (

              

                <Dropdown.Item
                  eventKey={item.deptName}
                  onClick={() =>
                    this.handleDropdownChange(item)
                  }
                 
                  >


                  {item.deptName}

                </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
