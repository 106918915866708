import React, { useState, useRef, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";

interface InputElement{
  checked:boolean
}
function RoomsListTable({
  id = "rooms",
  handleBulkAction,
  handleSelectedIds,
  isRoomsArrayModified,
  showChangeRoomTypeModal,
  showTakeRoomOfflineModal,
  hasEditPermission,
  ...restProps
}) {
  let checkAllRef = useRef<any | null>(null);
  let checkAllRef1 = useRef<any | null>(null);

  const [isBulkAction, setIsBulkAction] = useState(false);
  const [selectedId, setSelectedId] = useState<any[]>([]);

  useEffect(() => {
    const _isBulkAction = selectedId.length > 0;
    handleBulk(_isBulkAction);
    handleSelectedIds(selectedId)
  }, [selectedId]);

  const handleBulk = (_isBulkAction) => {
    setIsBulkAction(_isBulkAction);
    handleBulkAction(_isBulkAction);
    if(_isBulkAction === false){
      checkAllRef.current=null
    }
    
  };

  const handleOnSelect = (row, isSelect) => {
    if (isRoomsArrayModified) return;

    if (isSelect) {
      if (!selectedId.includes(row.roomID))
        setSelectedId((prev) => [...prev, row.roomID]);
    } else {
      setSelectedId((prev) => prev.filter((x) => x != row.roomID));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isRoomsArrayModified) return;
    const ids = restProps.data.map((r) => r.roomID);

    const checked=checkAllRef?.current ? !checkAllRef?.current?.checked :isSelect
    if (checked) {
      setSelectedId(ids);
      handleBulk(true);
     
    } else {
      setSelectedId([]);
      handleBulk(false);
    }
  };

  const footerBulkSelectAll = (e) => {
    const checked = e.target.checked;
    const ids = restProps.data.map((r) => r.roomID);

    if (checked) {
      setSelectedId(ids);
      handleBulk(true);

    } else {
      setSelectedId([]);
      handleBulk(false);

    }
  };

  const closeBulkAction = () => {
    setSelectedId([]);
  };

  const handleChangeRoomType = () => {
    showChangeRoomTypeModal(selectedId);
  };

  const handleTakeRoomOffline = () => {
    showTakeRoomOfflineModal(selectedId);
  };

  const selectRow = {
    mode: "checkbox",
    // clickToSelect: true,
    nonSelectable:
      isRoomsArrayModified || !hasEditPermission
        ? restProps.data.map((r) => r.roomID)
        : [],
    selected: [...selectedId],
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <Form.Check
        type={mode}
        {...rest}
        label=""
        custom
        disabled={isRoomsArrayModified}
        ref={(chkRef) => {
          if (chkRef) {
            checkAllRef1.current = chkRef;
            if (selectedId?.length === restProps.data?.length) {
              chkRef.checked = true;
            } else {
              chkRef.checked = false;
            }

            if (selectedId?.length > 0 && !chkRef?.checked) {
              chkRef.indeterminate = true;
            } else {
              chkRef.indeterminate = false;
            }
          }
        }}

       
      />
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <>
        {/* <input type={ mode } { ...rest } /> */}
        <Form.Check
          type={mode}
          {...rest}
          label=""
          custom
          disabled={isRoomsArrayModified}
        />
      </>
    ),
  };

  return (
    <div className={selectedId.length > 0 ? "bulk-select-open" : ""}>
      <div
        className={`tablewrapper ${
          restProps?.data?.length >= 7 ? "overflow-auto add-scrollbar" : ""
        }`}
      >
        <BootstrapTable
          {...restProps}
          keyField="roomID"
          id={"roomID"}
          hover
          selectRow={selectRow}
        />
      </div>
      {selectedId.length > 0 && (
        <div className="tableFooter TabFooter-Pay">
          <div className="bulkSelectFooter">
            {/* this.footerchk.current.indeterminate = false; */}
            <Form.Check
              type="checkbox"
              label=""
              id="footerBulkCheckBox"
              ref={(chkRef) => {
                if (chkRef) {
                  checkAllRef.current = chkRef;
                  if (selectedId?.length === restProps.data?.length) {
                    chkRef.checked = true;
                  } else {
                    chkRef.checked = false;
                  }

                  if (selectedId?.length > 0 && !chkRef?.checked) {
                    chkRef.indeterminate = true;
                  } else {
                    chkRef.indeterminate = false;
                  }
                }
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                footerBulkSelectAll(e);
              }}
            />
            <span className="shift-selected-count">
              {selectedId?.length} {selectedId?.length <= 1 && "Room"}
              {selectedId?.length > 1 && "Rooms"} selected
            </span>
            <div className="action-group d-flex flex-row mr-auto">
              <Button
                disabled={selectedId?.length <= 0}
                className="unassign btn-outline-primary deactivate-btn"
                onClick={() => handleTakeRoomOffline()}
              >
                Take Offline
              </Button>

              <Button
                disabled={selectedId?.length <= 0}
                className="unassign btn-outline-primary deactivate-btn"
                onClick={() => handleChangeRoomType()}
              >
                Change Room Type
              </Button>
            </div>
            <Button
              className="closeButton btn-ghost-light text-white"
              onClick={() => closeBulkAction()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                  fill="#84888C"
                />
              </svg>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RoomsListTable;
