import * as React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Utils } from "../../Common/Utilis";

export class Generate1096Ctrl extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            ctrlData: {
                toggle: false,
                name: "",
                nameError: false,
                email: "",
                emailError: false,
            }
        };
    }

    componentDidUpdate = (prevprops, prevState) => {
        const { defaultValue } = this.props;
        if (((prevprops.defaultValue !== defaultValue)) && defaultValue?.length > 0) {
            this.handleInput();
        }
        if (!defaultValue && prevprops?.defaultValue !== defaultValue) {
            this.handleInput();
        }
    }

    componentDidMount = () => {
        this.handleInput();
    };

    handleInput = () => {
        let { ctrlData } = this.state;
        let data: any = [];
        data.push(ctrlData.toggle ? "Yes" : "No");
        data.push(ctrlData.name);
        data.push(ctrlData.email);
        data = data.toString();
        console.log(data);
        this.setState({ ctrlData: this.state.ctrlData }, () => {
            this.props?.handleGenerate1096(data);
        })
    }

    handletoggleChange = (event: any) => {
        let { ctrlData } = this.state;
        let isChecked = event.target.checked;
        ctrlData.toggle = isChecked;
        ctrlData.name = "";
        ctrlData.nameError = false;
        ctrlData.email = "";
        ctrlData.emailError = false;
        this.setState({ ctrlData }, () => {
            this.handleInput();
        });
    }

    handleFormChange = (event: any, type: any) => {
        let { value } = event.target;
        let isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let { ctrlData } = this.state;
        value = value.trimStart();
        if (type === "name") {
            ctrlData.nameError = (value.trim() === "")
            ctrlData.name = value.trim()
        } else {
            ctrlData.emailError = (value.trim().match(isValidEmail) === null)
            ctrlData.email = value.trim()
        }
        this.setState({ ctrlData }, () => {
            this.handleInput();
        });
    }


    render() {
        const { ctrlData } = this.state;
        return (
            <Form.Group controlId="ctrltxtform" className="ctrltxtform hasCheck">
                <div className="d-flex align-items-center">
                    <Form.Label className="sec-ttl d-flex align-items-center m-0">Also generate 1096?
                        &nbsp;
                        <Form.Check
                            type="checkbox"
                            key={"isGenerate"}
                            id={"isGenerate"}
                            label="Yes"
                            checked={ctrlData.toggle}
                            onChange={(e: any) => this.handletoggleChange(e)}
                        />
                    </Form.Label>
                    <div className="ml-auto">
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={
                            <Tooltip id="button-tooltip">
                                Use these two fields if you want every 1096 form completed with the same contact name and contact email address.
                            </Tooltip>
                        }>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" viewBox="0 0 16 16" height="15" width="15" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                                <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                                <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                            </svg>
                        </OverlayTrigger>

                    </div>
                </div>
                {ctrlData.toggle && (<>
                    <Form.Group controlId="CtrlName" className="txtCtrlInput">
                        <Form.Control
                            type="text"
                            maxLength={100}
                            className={`${ctrlData.nameError ? "alert-danger" : ""}`}
                            id="nameInput"
                            placeholder="Contact Name"
                            onChange={(e: any) => this.handleFormChange(e, "name")}
                            value={ctrlData.name}
                        />                       
                    </Form.Group>
                    <Form.Group controlId="CtrlEmail" className="txtCtrlInput">
                        <Form.Control
                            type="text"
                            maxLength={100}
                            className={`${ctrlData.emailError ? "alert-danger" : ""}`}
                            id="emailInput"
                            placeholder="Contact Email"
                            onChange={(e: any) => this.handleFormChange(e, "email")}
                            value={ctrlData.email}
                        />                       
                    </Form.Group>
                </>)}
            </Form.Group>
        );
    }
}