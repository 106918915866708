

import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import scrollIntoView from "scroll-into-view";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import { resolve, reject } from "q";
import { ApproveDepartmentDetailsNew } from "../../../../../Common/Components/ApproveDepartmentDetailsNew";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import chevronRight from "../../../../../Common/Assets/Images/icons/bx-chevron-right.svg"; 
import "./DepartmentTab.scss";
import {
  IAutoPunchOutDaysDto,IApproveSaveRequestDto,
  IDepartmentPostionDaysDto,
} from "../../../../../Common/Contracts/ILaborDayApprove";
import { Utils } from "../../../../../Common/Utilis";
import Profile from "../../../../../Common/Assets/Images/profile.png";

const approveDepartmentDetailsContent = [
  {
    // title: "Add department note",
    title: "Add Department Approval Reason",
    cancelButtonName: "Cancel",
    actionButtonName: "Add note",
    table: "departmentTab",
  },
];
let localStorageUser: any;

export default class DepartmentTab extends React.Component<any, any> {
  private departmentTabDivRef: any;
  private approveDepartment: any;
  private expendedDeptatbRef: any;
  private addNoteHidRef: any;
  constructor(props: any) {
    super(props);
    this.departmentTabDivRef = React.createRef();
    this.approveDepartment = React.createRef();
    this.expendedDeptatbRef = React.createRef();
    this.addNoteHidRef = React.createRef();
    this.state = {
      parentRow: props.row,
      ApprovalDeptData: [],
      multipleRowSelected: false,
      selectedRows: [],
      preSelectedRows: [],
      loaderDepartmentTab: false,
      flyOutMenuApprove: false,
      Approvedstatus:"",
      ApprovalDeptCommentData: [],
      selectedHeaderRows: [],
      positionExpended:[],
    tableExpended: [],
    loaderPositionTab:false,
    };
  }
  componentDidMount() {    
    this.getDepartmentComments();
  }

  getDepartmentApprovalData() {    
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ loaderDepartmentTab: true });
    let parentRow = this.state.parentRow;
    let selectedRows = this.state.selectedRows;
    let selectedHeaderRows = this.state.selectedHeaderRows;
    let preSelectedRows = this.state.preSelectedRows;
    let request = {} as IAutoPunchOutDaysDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date;
    request.entryType = "Department";
    request.type = parentRow.isGM;

    LaborDayApprove.GetLaborOverViewDayApproveDeptPunchDetails(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
       
          let Data : any = [];
          Data = result.map((res, index) => {
            res.rowNo = index + 1;
            if(
              res.departmentName.toLowerCase() === "total" ||
              res.isAutoPunch.toLowerCase() === "yes" ||
              res.isNotPunchOut.toLowerCase() === "yes" ||
              res.isGMApproved.toLowerCase() === "approve"||
              res.isDeptAccess.toLowerCase() === "no"
              ){
            }else{
              selectedHeaderRows.push(res);
              selectedRows.push(res);
              preSelectedRows.push(res.rowNo);
            } 
           
            return { ...res, isGM: this.props.row.isGM};
          });
        //  comment add this logic
      
          let requestArray: any = []; 
          let commentResult= [...this.state.ApprovalDeptCommentData]; 
         
          let matchDeptId={} as any;
         
          Data.map((matchData,index) => {  
             matchDeptId = commentResult.filter((item: any) => {
              return (Number(item.deptid)===Number(matchData.deptid))
            });            
           
            for(let i=0;i< matchDeptId.length; i++){
              let objmatchDeptId={} as any;
              objmatchDeptId.notes= matchDeptId[i].comment;
              objmatchDeptId.imagePath= matchDeptId[i].imagePath;
              objmatchDeptId.approvedBy= matchDeptId[i].username;
              objmatchDeptId.approvedDate= matchDeptId[i].createDate;
              requestArray.push(objmatchDeptId);             
            }
           
        
           Data[index]["deptComment"]=requestArray; 
          requestArray=[];
          // index= index;
          
          });
          

        // end here


        Data= Data.map(x=>{
        x.ddlRef = React.createRef();
        x.ddlNotes= React.createRef();
        x.ddlDeptdetailsRef= React.createRef();
         return x;
          })
          
          this.props.AproveAll("Approve All Selected",selectedRows,"departmenttab")

          this.setState({
            ApprovalDeptData: Data,
            loaderDepartmentTab: false,
            selectedRows: selectedRows,
            preSelectedRows: preSelectedRows,
          },()=>{
            this.props.TotalDeptdata(this.state.ApprovalDeptData);
          });
        } else {
          this.setState({ loaderDepartmentTab: false,ApprovalDeptData:[],selectedRows:[],selectedHeaderRows:[], },()=>{
            this.props.AllApprovedStatus("Approved");
            this.props.AproveAll("Approve All Selected",selectedRows,"departmenttab")
          });
        }
       
if(!this.props.tableExpended){
  // setTimeout(() => {
  scrollIntoView(this.departmentTabDivRef.current, {
  align: {
    top: 0,
    left: 0,
    topOffset: 259,
    leftOffset: 0,
  },
});
// }, 500);
this.props.changeExpandedState(true)

}
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loaderDepartmentTab: false });
        reject();
      });
  }

  getDepartmentComments() {    
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ loaderDepartmentTab: true });
    let parentRow = this.state.parentRow;
    let selectedRows = this.state.selectedRows;
    let preSelectedRows = this.state.preSelectedRows;
    let request = {} as IAutoPunchOutDaysDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date;
    request.entryType = "Department";
    request.type = parentRow.isGM;

    LaborDayApprove.GetLaborDepartmentComments(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
       
       
          let Data = [];
          let requestArray: any = [];

          this.setState({ApprovalDeptCommentData:result},()=>{
            this.getDepartmentApprovalData();
          })


          // Data = result.map((res, index) => {
          //   res.rowNo = index + 1;
          //   if(
          //     res.departmentName.toLowerCase() === "total" ||
          //     res.isAutoPunch.toLowerCase() === "yes" ||
          //     res.isNotPunchOut.toLowerCase() === "yes" ||
          //     res.isGMApproved.toLowerCase() === "approve"||
          //     res.isDeptAccess.toLowerCase() === "no"
          //     ){
          //   }else{
          //     selectedRows.push(res);
          //     preSelectedRows.push(res.rowNo);
          //   }
          //   return { ...res, isGM: this.props.row.isGM};
          // });

        //   let deptData=[... this.state.ApprovalDeptData]      
        //   let matchDeptId={} as any;
         
        //   deptData.map((matchData,index) => {  
        //      matchDeptId = result.filter((item: any) => {
        //       return (Number(item.deptid)===Number(matchData.deptid))
        //     });            
           
        //     for(let i=0;i< matchDeptId.length; i++){
        //       let objmatchDeptId={} as any;
        //       objmatchDeptId.notes= matchDeptId[i].comment;
        //       objmatchDeptId.imagePath= matchDeptId[i].imagePath;
        //       objmatchDeptId.approvedBy= matchDeptId[i].username;
        //       objmatchDeptId.approvedDate= matchDeptId[i].createDate;
        //       requestArray.push(objmatchDeptId);             
        //     }
           
        //    debugger;
        //   deptData[index]["deptComment"]=requestArray;
        //  // objmatchDeptId={};
        //   requestArray=[];
        //   index= index;
          
        //   });
          
          
          // this.setState({ApprovalDeptData:[]},()=>{
          //   this.setState({ApprovalDeptData: deptData});
          // });
         
          
        
         

      }else{

        this.setState({ApprovalDeptCommentData:result},()=>{
          this.getDepartmentApprovalData();
        })

        // let requestArray: any = [];
        // let deptData=[... this.state.ApprovalDeptData]      
        //   let matchDeptId={} as any;
        //   let objmatchDeptId={} as any;
        //   deptData.map((matchData,index) => {  
        //      matchDeptId = result.filter((item: any) => {
        //       return (Number(item.deptid)===Number(matchData.deptid))
        //     });            
           
        //     for(let i=0;i< matchDeptId.length; i++){
        //       objmatchDeptId.notes= matchDeptId[i].comment;
        //       objmatchDeptId.imagePath= matchDeptId[i].imagePath;
        //       objmatchDeptId.approvedBy= matchDeptId[i].username;
        //       objmatchDeptId.approvedDate= matchDeptId[i].createDate;             
        //     }
        //     requestArray.push(objmatchDeptId);
           
        //   deptData[index]["deptComment"]=requestArray;
        //   objmatchDeptId={};
        //   requestArray=[];
        //   index= index;
          
        //   });
          
          
          // this.setState({ApprovalDeptData:[]},()=>{
          //  // this.setState({ApprovalDeptData: deptData});
          // });
      }
     // this.setState({ loaderDepartmentTab: false });
        resolve();
      })
      .catch((err) => {
        this.getDepartmentApprovalData();
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loaderDepartmentTab: false });
        reject();
      });
  }

  GetLaborDayOverViewBudgetDeptPositionDetails(row:any) {   
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ loaderPositionTab: true });
    let parentRow = this.state.parentRow;
    let selectedRows = this.state.selectedRows;   
    let request = {} as IDepartmentPostionDaysDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date;
    request.entryType = "Department";
    request.type = parentRow.isGM;
    request.deptID = row.deptid; 

    LaborDayApprove.GetLaborDayOverViewBudgetDeptPositionDetails(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          this.setState({positionExpended:result,loaderPositionTab: false});
        }else{
          this.setState({positionExpended:[],loaderPositionTab: false});
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loaderPositionTab: false });
        reject();
      });
  }


  onRowSelect = (
    selectedRow: any,
    isSelect: boolean,
    rowIndex: any,
    e: any
  ) => {
    
    let data = this.state.ApprovalDeptData;
    let selectedRows = this.state.selectedRows;
    let preSelectedRows = this.state.preSelectedRows;
    if (isSelect === true) {
      selectedRows.push(selectedRow);
      preSelectedRows.push(selectedRow.rowNo);

      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });

        let index = data.indexOf(data[rowIndex]);
        data[index].hoverOutAction = "selected";
        data[index].flyOutAction = "rowSeclected";

        for (let i = 0; i < data.length; i++) {
          let index = data.indexOf(data[i]);
          data[index].hoverOutAction = "selected"; 
        }

        this.setState({ ApprovalDeptData: data });
      } else {
        this.setState({
          multipleRowSelected: false,
        });
      }
      this.setState(
        {
          selectedRows: selectedRows,
          preSelectedRows: preSelectedRows,
        },
        () => {
          if (data.length === selectedRows.length && selectedRows.length > 0) {
            this.props.AproveAll(
              "Approve all",
              this.state.selectedRows,
              "departmenttab"
            );
          } else {
            this.props.AproveAll(
              "Approve All Selected",
              this.state.selectedRows,
              "departmenttab"
            );
          }
        }
      );
    } else {
      // let indexOf = selectedRows.indexOf(selectedRow);
      selectedRows = selectedRows.filter(item=>item.rowNo !== selectedRow.rowNo) 
      preSelectedRows = preSelectedRows.filter(item=>item !== selectedRow.rowNo)  
      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });
      } else {
        this.setState({
          multipleRowSelected: false,
        });

        for (let i = 0; i < data.length; i++) {
          let index = data.indexOf(data[i]);
          data[index].hoverOutAction = "";
          data[index].flyOutAction = "";
        }
        this.setState({ ApprovalDeptData: data });
      }
      this.setState(
        {
          selectedRows: selectedRows,
          preSelectedRows: preSelectedRows,
        },
        () => {
          if (data.length === selectedRows.length) {
            this.props.AproveAll(
              "Approve All Selected",
              this.state.selectedRows,
              "departmenttab"
            );
          } else {
            if (selectedRows.length === 0) {
              this.props.AproveAll(
                "",
                this.state.selectedRows,
                "departmenttab"
              );
            }
            if (selectedRows.length > 0) {
              this.props.AproveAll(
                "Approve All Selected",
                this.state.selectedRows,
                "departmenttab"
              );
            }
          }
        }
      ); 
    }
  };

  onRowSelectAll = (isSelect: any, rows: any, e: any) => {

    let data = this.state.ApprovalDeptData;
    if (isSelect === true) {
      let tempSelectedRows: any = [];
      let selectedRows = this.state.selectedRows;
      let preSelectedRows = this.state.preSelectedRows;
      selectedRows = tempSelectedRows;
      for (let i = 0; i < rows.length; i++) {
        
        if(rows[i].departmentName.toLowerCase()!== "total" && 
        rows[i].isDeptAccess.toLowerCase() === "yes" && 
        rows[i].isNotPunchOut.toLowerCase() === "no" && 
         rows[i].isAutoPunch.toLowerCase() === "no" 
         //&&  
        // rows[i].departmentApproval.toLowerCase() === "pending"
         )

        {
        selectedRows.push(rows[i]);
        preSelectedRows.push(rows[i].rowNo);

        let index = data.indexOf(rows[i]);
        data[index].hoverOutAction = "selected";
        data[index].flyOutAction = "rowSeclected";
        }
      
      }

      this.setState({ ApprovalDeptData: data });

      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });
      } else {
        this.setState({ multipleRowSelected: false });
      }
      this.setState(
        {
          selectedRows: selectedRows,
          preSelectedRows: preSelectedRows,
        },
        () => {
          if (data.length === selectedRows.length && selectedRows.length > 0) {
            this.props.AproveAll(
              "Approve All Selected",
              this.state.selectedRows,
              "departmenttab"
            );
          } else {
            if(selectedRows.length > 0){
            this.props.AproveAll(
              "Approve All Selected",
              this.state.selectedRows,
              "departmenttab"
            );
            }
          }
        }
      );
    } else {
      this.setState(
        {
          multipleRowSelected: false,
          selectedRows: [],
          preSelectedRows: [],
        },
        () => {
          this.props.AproveAll("", this.state.selectedRows, "departmenttab");
        }
      );

      for (let i = 0; i < data.length; i++) {
        let index = data.indexOf(data[i]);
        data[index].hoverOutAction = "";
        data[index].flyOutAction = "";
      }
      this.setState({ autoPunchOutData: data });
    }
  };

  rowApprove = (row: any, actionItem: any, childState: any) => {
   //debugger
    this.setState({ loaderOverBudget: true });
    let data = this.state.ApprovalDeptData;
    let parentRow = this.state.parentRow;
    let requestArray: any = [];

    if (actionItem === "singleApprove") {
      let request = {} as IApproveSaveRequestDto;
      request.hotelID = Number(parentRow.hotelID);
      request.user_uniqueno = localStorageUser.user_UniqueID;
      request.userName = localStorageUser.userName;
      request.date = parentRow.date;
      request.explanation = childState.approvalReason;
      request.tenantID = localStorageUser.tenantID;
      request.entryType = "detail";
      request.deptId = row.deptid;
      request.UnApproveDeptList = "-1";
      request.isGmApproval=this.state.parentRow.isGM;
      requestArray.push(request);

      LaborDayApprove.LaborSaveDayDepartmentNotes(requestArray)
      .then(async (result: any | null) => {
        if (result != null) {
          let resultData = result.result as any;
          if (resultData.saveStatus === "Success") {            

            toast.success(`Department(s) added notes successfully`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            }); 
            if(this.state.parentRow.isGM!=="DailyApproveDay"){
            this.getDepartmentComments();
            }
          } else {
            Utils.toastError(resultData.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
          }
        }
        this.setState({
          loaderOverBudget: false,
        });
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loaderOverBudget: false });
        reject();
      });





    } else {
      for (let i = 0; i < row.length; i++) {
        let request = {} as IApproveSaveRequestDto;
        request.hotelID = Number(parentRow.hotelID);
        request.user_uniqueno = localStorageUser.user_UniqueID;
        request.userName = localStorageUser.userName;
        request.date = parentRow.date;
        request.explanation = childState.approvalReason;
        request.tenantID = localStorageUser.tenantID;
        request.entryType = "detail";
        request.deptId = row[i].deptid;
        request.UnApproveDeptList = "-1";
        request.isGmApproval=this.state.parentRow.isGM;
        requestArray.push(request);
      }
    
    LaborDayApprove.LaborSaveDayDepartmentApproval(requestArray)
      .then(async (result: any | null) => {
        if (result != null) {
          let resultData = result.result as any;
          if (resultData.saveStatus === "Success") {        
          
           let status="";
            let panding = data.filter((item: any) => {
              return (item.departmentName.toLowerCase()!=="total")
            });
            if(panding.length===row.length)
            {
              status="Approved";
            }else{
              status="Panding";
            }
        
            this.props.AllApprovedStatus(status);
            this.props.overBudgetApprove("Approved", "overBudget");
            
            
            if(this.state.parentRow.isGM==="DailyApproveDay"){
              //this.getDepartmentApprovalData();
              this.getDepartmentComments();
            }
           
            this.setState({ overBudgetDeptsData: data, });

            // toast.success(`Department(s) approved successfully`, {
            //   // // position: toast.POSITION.BOTTOM_RIGHT,
            //   // // containerId: "laborDayOverviewContainer",
            // });
          } else {
            Utils.toastError(resultData.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
          }
        }
        this.setState({
          loaderOverBudget: false,
        });
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loaderOverBudget: false });
        reject();
      });
    }
  };

  approveModalAction = (
    row: any,
    actionItem: any,
    flag: any,
    childState: { approvalReason: string }
  ) => {
    
    if (flag) {
      if (childState.approvalReason === "") {
        Utils.toastError(`Please enter the explanation`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
      } else {
        if (childState.approvalReason.length > 200) {
          Utils.toastError(`Explanation should not exceed 200 characters`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "laborDayOverviewContainer",
          });
        } else {
          this.rowApprove(row, actionItem, childState);
        }
      }
    } else {
      if (actionItem === "singleApprove") {
        let data = this.state.ApprovalDeptData;
        let index = data.indexOf(row);
        data[index].flyOutAction = "";
        this.setState({ ApprovalDeptData: data });
      }
    }
  };

clossAll=(ref)=> {
  
  if(ref.current?.ariaExpanded=="true") {
  ref.current.click();
  }
  
   
}

ddlNotes=(ref)=> {
 
  if(ref.current?.ariaExpanded=="true") {
  ref.current.click();
  }  
 
                       // this.approveDepartment?.current?.handleCancel();
}

ddlDeptdetails=(ref)=> {
 
  if(ref.current?.ariaExpanded=="true") {
  ref.current.click();
  }    
  
}

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
   
    if (this.state.tableExpended.length > 0) {
      // this.setState(() => ({
      //   tableExpended: this.state.tableExpended.filter(
      //     (x: any) => x !== row.rowNo
      //   ),
      // }));

      this.setState(
        { expendedRowIndex: rowIndex, isExpand: false, loaderOverBudget: true },
        () => {
          this.setState(() => ({
            tableExpended: [],
          }));
//debugger
          let data = this.state.ApprovalDeptData;
          for (let i = 0; i < data.length; i++) {
            let index = data.indexOf(data[i]);
            data[index].action = "";
          }
          this.setState({ ApprovalDeptData: data, loaderOverBudget: false });
        }
      ); 
    } else {
     this.GetLaborDayOverViewBudgetDeptPositionDetails(row);
      row.action = "Expended";
      this.setState(() => ({
        tableExpended: [...this.state.tableExpended, row.rowNo],
      }));
      this.setState(
        { expendedRowIndex: rowIndex, isExpand: true, loaderOverBudget: false },
        () => {
          this.scrollExpendedInView();
        }
      );
    }
  };

  scrollExpendedInView = () => {
    if (this.state.tableExpended.length > 0) {
      setTimeout(() => {
        //
        scrollIntoView(this.expendedDeptatbRef.current, {
          align: {
            top: 0,
            left: 0,
            topOffset: 259,
            leftOffset: 0,
          },
        });
      }, 0);
    } else {
      return false;
    }
  };

  overBudgetDetails=(row)=>{
   
    row.parentName = "underBudget";
    row.budgetType = "UnderBudgetTabNew";
   
    this.props.positionDetail(row);
  }
  overDeptBudgetDetails=(row)=>{

    row.parentName = "overBudgetDepartment";
    row.budgetType ="UnderBudgetTabNew";
    row.positionExpended=false;
    this.props.positionDetail(row);
  }

  refreshDptoChild=()=> {   
   // this.GetLaborDayOverViewBudgetDeptPositionDetails();
  }
  render() {
    const DeptTabSelectRow = {
      mode: "checkbox",
      classes: "selection-row",
      selected: this.state.preSelectedRows,
      clickToExpand: true,
      onSelect: (
        selectedRow: any,
        isSelect: boolean,
        rowIndex: any,
        e: any
      ) => {
        this.onRowSelect(selectedRow, isSelect, rowIndex, e);
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        this.onRowSelectAll(isSelect, rows, e);
      },
    };
    const rowClasses = (row: any, rowIndex: any) => {      
      if (
       // row.departmentName.toLowerCase() === "total" ||
        row.isAutoPunch.toLowerCase() === "yes" ||
        row.isNotPunchOut.toLowerCase() === "yes" 
        || row.isDeptAccess.toLowerCase() === "no"
        // || row.departmentApproval.toLowerCase() !== "pending"
      ) {
        return "row-disabled";
      }
      if(row.departmentName.toLowerCase() === "total"){
        return "row-checkbox-disabled";
      }
      if(row.isGMApproved.toLowerCase() === "approve"){
        return "row-isgmapproved-checkbox-disabled";
      }
      
    };

    const DeptTabcolumns = [
      {
        dataField: "action",
        text: "",
        headerClasses:  "expand-collpse",
        classes: "expand-collpse",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.action === "Expended") {
            return (
              <span className="chevronExpended">
                <img src={chevronRight} alt="chevron Right" />
              </span>
            );
          } else {
            return (
              <span className="chevronCollpsed">
                <img src={chevronRight} alt="chevron Right" />
              </span>
            );
          }
        },
      },
      {
        dataField: "departmentName",
        text: "Department",
        headerClasses:  "deptCol text-left",
        classes: "deptCol",
        editable: false,
       // headerFormatter: this.headerFormat,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <span className="autoPunchOutUsername">{row.departmentName==="Total"? "Totals":row.departmentName }        
            
            </span>
            {row.isAutoPunch.toLowerCase() === "yes" && (
              <span className="autoPunch-status badge">APO</span>
            )} 
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "Total",
        text: "Total Hours",
        editable: false,
        headerClasses: "totalHoursCol",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <span className="autoPunchOutposition d-inline">
                {Utils.amountFormatted(Number(row.actualTotal).toFixed(2).replace('.',':'))}
            </span>
              
              <span  className={`${(Number(row.acualBudget)===0 && Number(row.actualTotal)===0) ? "text-green" :
               (Number(row.acualBudget)===0 && Number(row.actualTotal) > 0) ?"text-red" : 
                row.acualBudget > 0 ? "text-red" : "text-green"}`}
              >
                {(Number(row.acualBudget)===0 && Number(row.actualTotal)===0) ? 
                Utils.amountFormatted(Number(row.acualBudget).toFixed(2).replace('.',':')) :
                row.acualBudget >= 0
                  ? "+" +
                    Utils.amountFormatted(Number(row.acualBudget).toFixed(2).replace('.',':'))
                  : Utils.amountFormatted(Number(row.acualBudget).toFixed(2).replace('.',':'))}
              </span>
          </div> 
        ),
      },
      {
        dataField: "totalWages",
        text: "Total Wages",
        editable: false,
        headerClasses: "totalWagesCol",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <span className="autoPunchOutposition d-inline">
            {"$"}
              {Utils.amountFormatted(Number(row.totalWages).toFixed(2))}
            {" "}
            </span>
            <span
              className={`${(Number(row.totalWages)===0 &&  Number(row.wagesBudget)===0 ) ? "text-green"              
               :  (Number(row.wagesBudget)===0 && Number(row.wagesBudget) > 0) ?"text-red" :
               row.wagesBudget >= 0 ? "text-red" : "text-green"}`}
            >
              {(Number(row.totalWages)===0 &&  Number(row.wagesBudget)===0 ) ? 
              Utils.amountFormatted(Number(row.wagesBudget).toFixed(2)) :
              row.wagesBudget >= 0
                ? "+" +
                  Utils.amountFormatted(Number(row.wagesBudget).toFixed(2))
                : Utils.amountFormatted(Number(row.wagesBudget).toFixed(2))}
            </span>
          </div> 
        ),
      },
      {
        dataField: "cpor",
        text: "CPOR",
        headerClasses: "CPORCol",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
        <div>
            <span className="autoPunchOutposition d-inline">
              {"$"}
              {Utils.amountFormatted(Number(row.cpor).toFixed(2))}

              {/* {'$'+row.totalWages} */}
            </span>{" "}
            <span
              className={`${row.cporBudget >0 ? "text-red" : "text-green"}`}
            >
              {row.cporBudget > 0
                ? "+" + Utils.amountFormatted(Number(row.cporBudget).toFixed(2))
                : Utils.amountFormatted(Number(row.cporBudget).toFixed(2))}
            </span>
          </div>
        ),
      },
      {
        dataField: "roomOcc",
        text: "Occupied",
        editable: false,
        headerClasses: "occupiedCol",
        formatter: (cell: any, row: any, rowIndex: any) => (
          // <span className="autoPunchOutposition">{row.roomOcc} + {row.roomOccBudget}</span>
          <div>
              {row.departmentName === "Total" ?
              <div>
              <span className="autoPunchOutposition d-inline cursorDefault">
                {" "}
                {row.roomOcc}
              </span>
              <span
                className={`${row.roomOccBudget > 0 ? "text-red cursorDefault" : "text-green cursorDefault"}`}
              >
                {row.roomOccBudget > 0
                  ? "+" + row.roomOccBudget
                  : row.roomOccBudget}
              </span> </div> : <></>
            }
          </div>
            
        ),
      },
      {
        dataField: "occPercentage",
        text: "Occupancy %",
        headerClasses: "occupancyCol text-center",
        classes: "text-center",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            {row.departmentName === "Total" ?
            <div>
            <span className="autoPunchOutposition d-inline cursorDefault">
              {Utils.amountFormatted(Number(row.occPercentage).toFixed(2))}{" "}
              {"%"}
            </span>
            <span
              className={`${
                row.occPercentageBudget > 0 ? "text-red cursorDefault" : "text-green cursorDefault"
              }`}
            >
              {row.occPercentageBudget > 0
                ? "+" +
                  Utils.amountFormatted(
                    Number(row.occPercentageBudget).toFixed(2)
                  )
                : Utils.amountFormatted(
                    Number(row.occPercentageBudget).toFixed(2)
                  ) + " %"}
            </span> </div> : <></> }
          </div>
        ),
      },
      {
        dataField: "departmentApproval",
        text: "Department Approval",
        headerClasses: "departmentApprovalCol text-center",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          const stopPropagation = (e: { stopPropagation: () => any }) =>
          e.stopPropagation();
          const hasKey = row.hasOwnProperty('deptComment');
          if(row.departmentName === "Total"){            
            return
          }
          if(hasKey==false){
            return
          }
          if (row.departmentApproval === "Pending") {
            let scheduleLength=false;          
            if(row.deptComment.length>0){
             scheduleLength = row.deptComment[0].hasOwnProperty('approvedBy');            
            }
                       
            return (
              <OutsideClickHandler onOutsideClick={(e)=>this.ddlNotes(row.ddlNotes) }>
              <div className="text-center">
                {/* <span className="deptApprovalStatusPending">
                  {row.departmentApproval}
                </span> */}
                {scheduleLength?(
              //  <Dropdown className="more-action approvePendingStatus text-center">
               <Dropdown className={ row.deptComment.length > 1 ? "more-action approvePendingStatus text-center multiComment" : "more-action approvePendingStatus text-center"}
               onClick={stopPropagation} >
                <Dropdown.Toggle className="btn-outline-primary bg-transparent"
                 ref={row.ddlNotes} >
                  <div className="Btn">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#863902"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                      <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                    </svg>
                    <span className="deptApprovalStatusPending" >{row.departmentApproval}</span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {row.deptComment.map((item: any, idx: any) => {
                    
                  return(
                    <>
                  <div className="dropdownMenu">
                    <div className="dropdownHeader">
                      <img src={item.imagePath === null ? Profile : item.imagePath} alt="" />
                      <div>
                        <h4>{item.approvedBy}</h4>
                        <h5>{item.approvedDate}</h5>
                      </div>
                    </div>
                    <div className="content">
                      <h6>
                        {item.notes}
                      </h6>
                    </div>
                  </div>
                  </>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
                ):(
                  <span className="deptApprovalStatusPending" onClick={stopPropagation} >
                  {row.departmentApproval}
                </span>
                )}




              </div>
              </OutsideClickHandler>
            );
          } else {
           
            let scheduleLength1=false;          
            if(row.deptComment.length>0){
              scheduleLength1 = row.deptComment[0].hasOwnProperty('approvedBy');            
            }
            return (                          
              <OutsideClickHandler onOutsideClick={(e)=>this.ddlNotes(row.ddlNotes) }>
              <div className="text-center">     
              {scheduleLength1 ?  (
                
              // <Dropdown className="more-action approveCompletedStatus text-center">
              <Dropdown className={ row.deptComment.length > 1 ? "more-action approveCompletedStatus text-center multiComment" : "more-action approveCompletedStatus text-center"}
               onClick={stopPropagation}
              >
                <Dropdown.Toggle className="btn-outline-primary bg-transparent"
                ref={row.ddlNotes}>
                  <div className="Btn">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#0E9074"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                      <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                    </svg>
                    <span>{row.departmentApproval}</span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {row.deptComment.map((item: any, idx: any) => {                  
                  return(                   
                    <>
                  <div className="dropdownMenu">
                    <div className="dropdownHeader">
                    <img src={item.imagePath === null ? Profile : item.imagePath} alt="" />
                      <div>
                        <h4>{item.approvedBy}</h4>
                        <h5>{item.approvedDate}</h5>
                      </div>
                    </div>
                    <div className="content">
                      <h6>
                        {item.notes}
                      </h6>
                    </div>
                  </div>
                  </>
                );
              })}
                </Dropdown.Menu>
              </Dropdown>
             
              ):(
                <div className="approveCompletedStatus " onClick={stopPropagation}>
                  <div className="btn-outline-primary bg-transparent">
                  <div className="Btn d-inline-block">
                    <span className="ml-0">{row.departmentApproval}</span>
                  </div>
                  </div>
                </div>
              )}
              </div>
              </OutsideClickHandler>
            );
            
          }
        },
      },

      {
        dataField: "hoverOutAction",
        text: "Notes",
        editable: false,
        headerClasses: "NotesCol text-center",
      
        formatter: (cell: any, row: any, rowIndex: any) => {
          if(row.departmentName === "Total")
          {
            return;
          }
           const stopPropagation = (e: { stopPropagation: () => any }) =>
           e.stopPropagation();
          // if (row.hoverOutAction !== "selected") {
            return (
              <OutsideClickHandler onOutsideClick={(e)=>this.clossAll(row.ddlRef) }>
              <div  className="autoPunchOutAction approveBtnOuter">
                
                <Dropdown
                  className="more-action text-center addNoteBtn"
                  alignRight
                  // onClick={this.ApproveSetFocus}
                   onClick={stopPropagation}

                >
                   
                  <Dropdown.Toggle
                    className="btn-outline-primary autoPunchOutApprove more"
                    id="dropdown-more"
                    ref={row.ddlRef}
                  >
                    Add Note
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ApproveDepartmentDetailsNew
                    
                      approveDepartmentDetailsContent={
                        approveDepartmentDetailsContent
                      }
                      outTime={"00:00"}
                      // showPlaceHolder={"Explain the numbers..."}
                      showPlaceHolder={"Please provide note here."}
                      table="departmentTab"
                      ref={this.approveDepartment}
                      approveModalAction={this.approveModalAction.bind(
                        this,
                        row,
                        "singleApprove"
                      )}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              </OutsideClickHandler>
            );
          // } else {
          //   return <div className="autoPunchOutAction"></div>;
          // }
        },
      },

      {
        dataField: "",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          const stopPropagation = (e: { stopPropagation: () => any }) =>
          e.stopPropagation();
         // if (row.flyOutAction === "") {
            return (
              <OutsideClickHandler onOutsideClick={(e)=>this.ddlDeptdetails(row.ddlDeptdetailsRef) }>
              <Dropdown
                className="more-action autoPunchOutDetailsDropdown"
                alignRight
                onClick={stopPropagation}
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                  ref={row.ddlDeptdetailsRef}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"Details"}
                    onClick={() => this.overDeptBudgetDetails(row)}
                  >
                    Details
                  </Dropdown.Item>
 
                </Dropdown.Menu>
              </Dropdown>
              </OutsideClickHandler>
            );
        
       // }
      },
    }



    ];




    const checkIsGM = this.state.parentRow.isGM === "DailyApproveDay";
    const expandRow = {
     
      onlyOneExpanding: true,
      renderer: (row: any) => {        
   
        const rowColumns = [
          {
            dataField: "",
            headerClasses: "selection-cell-header",
            classes: "selection-cell",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "",
            headerClasses: "expand-collpse",
            classes: "expand-collpse",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "positionName",
            text: "Department",
            headerClasses: "deptCol-child",
            classes: "deptCol-child",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <EllipsisWithTooltip placement="bottom">
               <span className="dept-position-name">{row.positionName}</span>
              </EllipsisWithTooltip>
            ),
          },
          {
            dataField: "actualTotal",
            text: "Total Hours",
            headerClasses: "totalHoursCol",
            formatter: (cell: any, row: any, rowIndex: any) => ( 
              <div>
                <span className="autoPunchOutposition d-inline">
                  {Utils.amountFormatted(Number(row.actualTotal).toFixed(2).replace('.',':'))}
                </span>
                
                <span  className={`${(Number(row.acualBudget)===0 && Number(row.actualTotal)===0) ? "text-green" :
                (Number(row.acualBudget)===0 && Number(row.actualTotal) > 0) ?"text-red" : 
                  row.acualBudget > 0 ? "text-red" : "text-green"}`}
                >
                  {(Number(row.acualBudget)===0 && Number(row.actualTotal)===0) ? 
                  Utils.amountFormatted(Number(row.acualBudget).toFixed(2).replace('.',':')) :
                  row.acualBudget >= 0
                    ? "+" +
                      Utils.amountFormatted(Number(row.acualBudget).toFixed(2).replace('.',':'))
                    : Utils.amountFormatted(Number(row.acualBudget).toFixed(2).replace('.',':'))}
                </span>
              </div>
            ),
          },

          {
            dataField: "totalWages",
           text: "Total Wages",
            editable: false,
            headerClasses: "totalWagesCol",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <div>
                <span className="autoPunchOutposition d-inline">
                  {"$"}
                  {Utils.amountFormatted(Number(row.totalWages).toFixed(2))}
                </span>{" "}
                <span
                  className={`${(Number(row.totalWages)===0 &&  Number(row.wagesBudget)===0 ) ? "text-green"              
                   :  (Number(row.wagesBudget)===0 && Number(row.wagesBudget) > 0) ?"text-red" :
                   row.wagesBudget >= 0 ? "text-red" : "text-green"}`}
                >
                  {(Number(row.totalWages)===0 &&  Number(row.wagesBudget)===0 ) ? 
                  Utils.amountFormatted(Number(row.wagesBudget).toFixed(2)) :
                  row.wagesBudget >= 0
                    ? "+" +
                      Utils.amountFormatted(Number(row.wagesBudget).toFixed(2))
                    : Utils.amountFormatted(Number(row.wagesBudget).toFixed(2))}
                </span>
              </div>
            ),
          },



          {
            dataField: "cpor",
            text: "CPOR",
            headerClasses: "CPORCol",
            formatter: (cell: any, row: any, rowIndex: any) => (   
              <div>
                <span className="autoPunchOutposition d-inline">
                  {"$"}
                  {Utils.amountFormatted(Number(row.cpor).toFixed(2))}
    
                   {/* {'$'+row.totalWages}  */}
                 </span>{" "}
                <span
                  className={`${row.cporBudget > 0 ? "text-red" : "text-green"}`}
                >
                  {row.cporBudget > 0
                    ? "+" + Utils.amountFormatted(Number(row.cporBudget).toFixed(2))
                    : Utils.amountFormatted(Number(row.cporBudget).toFixed(2))}
                </span>
              </div>         
              // <span className="rowBudgeted">{row.cporBudget}</span>
              // let actualTotal = Number(row.cporBudget.replace(":", "."));
              // let budgetTotal = Number(row.cporBudget.replace(":", "."));
              // if (rowIndex >= 0) {
              //   return (
              //     <span
              //       className={
              //         actualTotal > budgetTotal ? "rowActual red" : "rowActual"
              //       }
              //     >
              //       {row.cporBudget}
              //     </span>
              //   );
              // }
            ),
          }, 
          {
            dataField: "",
            text: "Occupied",
            headerClasses: "occupiedCol",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },{
            dataField: "",
            text: "Occupancy %",
            headerClasses: `${checkIsGM ? "d-none" : "occupancyCol"}`,
            classes: `${checkIsGM ? "d-none" : ""}`,
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "",
            text: `${checkIsGM ? "Occupancy %" : "Department Approval"}`,
            headerClasses: `${checkIsGM ? "occupancyCol text-center" : "departmentApprovalCol" }`,
            classes: "text-center",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <Button
                className="btn-details rowDetails"
                onClick={() => this.overBudgetDetails(row)}
              >
                Details
              </Button>
            ),
          },
          {
            dataField: "",
            text: "Notes",
            headerClasses: "NotesCol",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <span className="rowBlank"></span>
            ),
          },
          {
            dataField: "",
            // text: "Notes",
            // headerClasses: "NotesCol",
            formatter: (cell: any, row: any, rowIndex: any) => (
              <Dropdown className="more-action" alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"Details"}
                    onClick={() => this.overBudgetDetails(row)}
                  >
                    Details
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ),
          },
    ];
        return (
          <>
          {this.state.loaderPositionTab ? (
            <ReactPageLoader useas={"daysToApproveExpand"} />
          ) :(
          <div className="rowExpand department-child-table" ref={this.expendedDeptatbRef}>
            <BootstrapTable
              keyField="rowNo"
              data={this.state.positionExpended}
              columns={rowColumns}
              hover
            />
          </div>)}
          </>
        );
      },
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      nonExpandable: [1],
      
    };

 

    if(this.state.parentRow.isGM === "DailyApproveDay"){
      DeptTabcolumns.splice(DeptTabcolumns.length-3,1)
          }

    return (
      <>
        {this.state.loaderDepartmentTab ? (
          <ReactPageLoader useas={"daysToApproveExpand"} />
        ) : (
          <div className={this.state.selectedHeaderRows.length > 0 ? "autoPunchOutsTable department-tbl" :"autoPunchOutsTable department-tbl Header-Chk-disabled"}
           // className="autoPunchOutsTable"
            id="departmentTabUi"
            ref={this.departmentTabDivRef}
          >
            <div className="pb-2">
              <h6 className="tableHeading">Department Actuals vs Budget</h6>
            </div>
            <BootstrapTable
              keyField="rowNo"
              data={this.state.ApprovalDeptData}
              columns={DeptTabcolumns}
              selectRow={DeptTabSelectRow}
              hover
              expandRow={expandRow}
              rowClasses={rowClasses}
             
            />
          </div>
        )}
      </>
    );
  }
}
