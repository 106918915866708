import Instense from "./Axios-config";

export class PayrollIntegration {
    public static GetLaborTenantList = (type): Promise<any> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let request: any = {};
        request.type = type;
        const url = `/PayrollIntegration/GetLaborTenantList`;
        return Instense.get(url, { params: request }).then((response) => {
          let result = response?.data?.result;
          return result;
        });
      };

      public static GetPayrollIntegrationHeader = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantId = tenantID;
        const url = `/PayrollIntegration/GetPayrollIntegrationHeader`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static GetPayrollIntegrationHotelDetails = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.TenantId = tenantID;
        const url = `/PayrollIntegration/GetPayrollIntegrationHotelDetails`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static SavePayrollIntegrationHid = async (request: any[]): Promise<any | null> => {
        // debugger;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.map((items) => ((items["TenantId"] = tenantID)));
        const url = `/PayrollIntegration/SavePayrollIntegrationHid`;
        return Instense.post(url, request).then(
          (response: any) => {
            let result = response.data.result as any;
            return result;
          }
        );
      }

      public static DeleteWebHookPayrollIntegrationHid = async (request: any[]): Promise<any | null> => {
        // debugger;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.map((items) => ((items["TenantId"] = tenantID)));
        const url = `/PayrollIntegration/DeleteWebHookPayrollIntegrationHid`;
        return Instense.post(url, request).then(
          (response: any) => {
            let result = response.data.result as any;
            return result;
          }
        );
      }


}

