

import Instense from "./Axios-config";
import { IGlobalSettingsRequest } from '../Contracts/IGlobalSettings';
import { IRoomTypeNew } from '../Contracts/IRoomType';
import { IConferenceRoomRequest } from "../Contracts/IConferenceRoomRequest";

export class DwollaService {

    public static DwollaTokenURL = async (request:any): Promise< any[] | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      const url = `/DwollaWebhook/DwollaTokenURL`;
      return Instense.post(url,request).then((response) => {
        console.log(response);
        let result = response.data as  any;
        return result;
      });
    };
    public static SaveDwollaCustomer = async (request:any): Promise< any[] | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      request.tenantId =tenantID;
      const url = `/DwollaWebhook/SaveDwollaCustomer`;
      return Instense.post(url,null, {params:request}).then((response) => {
        console.log(response);
        let result = response.data as  any;
        return result;
      });
    };

    public static DwollaResetup = async (request:any): Promise< any[] | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      request.TenantId =tenantID;
      const url = `/DwollaWebhook/DwollaResetup`;
      return Instense.post(url,null, {params:request}).then((response) => {
        console.log(response);
        let result = response.data as  any;
        return result;
      });
    };

    public static AgreeDwollaTerms = async (request:any): Promise< any[] | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      request.TenantId =tenantID;
      request.Hid =request.Hid;
      const url = `/DwollaWebhook/AgreeDwollaTerms`;
      return Instense.post(url,null, {params:request}).then((response) => {
        let result = response.data as  any;
        return result;
      });
    };
     
    public static DisableACH = async (request:any): Promise< any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      request.TenantId =tenantID;
      const url = `/DwollaWebhook/DisableACH`;
      return Instense.post(url,null, {params:request}).then((response) => {
        let result = response.data as  any;
        return result;
      });
    };
}