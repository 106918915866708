import * as React from "react";
import {Modal, Form } from "react-bootstrap";
import OtpInput from "react-optinput";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import { accountService } from "../../Common/Services/account";
import "./otpModal.scss";


export class OtpModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      ispopupshow: true,
      errorMessage: "",
      otp: 0,
      reSendOtp: true,
    };
    this.handleContinue = this.handleContinue.bind(this);
    this.resendPasscode = this.resendPasscode.bind(this);
    
  }

  
  handleContinue(event: any) {
    event.preventDefault();
   let errorMessage = "";

    if (this.state.otp === 0 || this.state.otp === "") {
      errorMessage = "Please enter passcode";
      this.setState({ errorMessage: errorMessage });
    } else {
      this.setState({ loaderTrue: true });

      let storage = JSON.parse(localStorage.getItem("storage")!);
      let userName = storage === null ? 0 : (storage.userName as any);

      let request: any = {};
      request.userName = userName;
      request.purpose = this.props.purpose ? this.props.purpose : "Bank Validation";
      request.otpCode = this.state.otp;
      request.primaryContact = this.props?.primaryContact || "Phone";
      request.userEmail = this.props?.userEmail;
      request.userPhone = this.props?.userPhone;
      accountService.ValidateOTP(request)
        .then(async (result: any | null) => {
         
          if (result.status !== "") {
            if (result.status === "InvalidPassCode") {
              errorMessage = "Incorrect passcode entered. Please try again.";
            } else if (result.status === "PasscodeExpired") {
              errorMessage =
                "Passcode expired. Please click 'Resend Code' to send a new passcode.";
            } else if (result.status === "ValidPassCode") {
              this.props.ValidateOTP(this.state.otp);
            } 
          }
          this.setState({ errorMessage: errorMessage });
          this.setState({ loaderTrue: false });
          resolve();
        })
        .catch((error) => {
          this.setState({ loaderTrue: false });
          reject();
        });
    }
    
  }
  onInputChange = (value: number) => {
    if (isNaN(value)) {
      let errorMessage = "Invalid input, accept only numeric value.";
      this.setState({ errorMessage: errorMessage });
    } else {
      this.setState({ otp: value });
      this.setState({ errorMessage: "" });
    }
  };

  resendPasscode(event: any) {
    this.props.ReSendOTP(this.props?.primaryContact);
  }
  
  render() {
    return (
        <Modal className="otp-modal" show={this.state.ispopupshow} onHide={this.props.closePopup} centered>      
        <Modal.Header closeButton>
            <Modal.Title>Enter Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="description">
            <p>
              We've sent a code to your {this.props?.primaryContact || "Phone"}. Please enter it below
              to continue. The code will expire in <b>20 minutes</b>.
            </p>
            <p>
              If you didn't receive the code within a few minutes, you can have
              us{" "}
              <span
                className={this.state.reSendOtp ? "enableOtp" : "disabledOtp"}
                onClick={this.resendPasscode}
              >
                resend the code
              </span>
              , or you may contact your Inn-flow administrator for further help.
            </p>
            </div>
            <Form.Group className="mb-3" controlId="formOtp">
                <Form.Label>Code</Form.Label>
                <OtpInput
                codeLength={6}
                onInputChange={(value: any) => this.onInputChange(value)}
              />
              <div className="invalid-feedback">
                {this.state.errorMessage}
              </div>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>                                
            <button type="button" className="btn wht-bg" onClick={this.props.closePopup}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={this.handleContinue} >Continue</button>
        </Modal.Footer>        
    </Modal>
    );
  }
}
