import React from "react";
import { Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip"; 
import "react-toastify/dist/ReactToastify.css"; 
import { resolve, reject } from "q";
import profile from "../../../../../Common/Assets/Images/profile.png"; 
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import {IHouseKeepingDaysDto,} from "../../../../../Common/Contracts/ILaborDayApprove";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import "./HousekeepingTab.scss";
import { Utils } from "../../../../../Common/Utilis"

let localStorageUser: any;
export default class HousekeepingActualVsBudget extends React.Component<
  any,
  any
> {
  private underBudgetDivRef: any;
  private expendedUnderBudgetRef: any;
  constructor(props: any) {
    super(props);
    this.underBudgetDivRef = React.createRef();
    this.expendedUnderBudgetRef = React.createRef();
    this.state = {
      parentRow: props.row,
      underBudgetDeptsData: [],
      multipleRowSelected: false,
      selectedRows: [],
      tableExpended: [],
      loaderUnderBudget: false,
      preSelectedRows: [],
      expendedRowIndex: 0,
      isExpand: false,
      UnApproveDeptList: "",
      ApproveStatus:"",
      tblComment:[],
    };
  }

  componentDidMount() { 
    this.bindHouseKeepingComments();
  }

  underBudgetsDeptApprovalData() { 
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ loaderUnderBudget: true });
    let parentRow = this.state.parentRow;
    let request = {} as IHouseKeepingDaysDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date;
    request.entryType = "UnderBudget";
    request.type=parentRow.isGM;

    LaborDayApprove.GetHousekeepungApprovalDetails(request)
      .then(async (result: any | null) => { 
        let DayApproveData: any = [];
        if (result != null && result.length > 0) {
       
          DayApproveData = result;
          
          for (let i = 0; i < DayApproveData.length; i++) {
            DayApproveData[i].rowNo = i + 1;
            DayApproveData[i].action = "";
            DayApproveData[i].hoverOutAction = "selected";
            DayApproveData[i].flyOutAction = "rowSeclected";
 
          }
          let status=result[1].status!=="Unapproved" ? result[1].status :"Approval Pending";

 
                    
              let requestArray: any = []; 
              let commentResult= [...this.state.tblComment]; 

              let matchDeptId={} as any;

              DayApproveData.map((matchData,index) => {  
                  matchDeptId = commentResult.filter((item: any) => {
                  return (Number(item.userUniqueno)===Number(matchData.user_UniqueID))
                });            
                
                for(let i=0;i< matchDeptId.length; i++){
                  let objmatchDeptId={} as any;
                  objmatchDeptId.notes= matchDeptId[i].notes;
                  objmatchDeptId.imageUrl= matchDeptId[i].imageUrl;
                  objmatchDeptId.username= matchDeptId[i].username;
                  objmatchDeptId.createdDate= matchDeptId[i].createdDate;
                  requestArray.push(objmatchDeptId);             
                }
                 
                DayApproveData[index]["HouseKeepingComment"]=requestArray; 
              requestArray=[];
              index= index;
              
              });
              

              // end here





          this.setState({
            underBudgetDeptsData: DayApproveData,
            ApproveStatus:status,
            loaderUnderBudget: false,
          }); 
        } else {



          //debugger;
          //  comment add this logic
                
          let requestArray: any = []; 
          let commentResult= [...this.state.tblComment]; 

          let matchDeptId={} as any;

          DayApproveData.map((matchData,index) => {  
              matchDeptId = commentResult.filter((item: any) => {
              return (Number(item.userUniqueno)===Number(matchData.user_UniqueID))
            });            
            
            for(let i=0;i< matchDeptId.length; i++){
              let objmatchDeptId={} as any;
              objmatchDeptId.notes= matchDeptId[i].notes;
              objmatchDeptId.imageUrl= matchDeptId[i].imageUrl;
              objmatchDeptId.username= matchDeptId[i].username;
              objmatchDeptId.createdDate= matchDeptId[i].createdDate;
              requestArray.push(objmatchDeptId);             
            }
            
            DayApproveData[index]["HouseKeepingComment"]=requestArray;
          requestArray=[];
          index= index;
          
          });
          

          // end here



          this.setState({  underBudgetDeptsData: DayApproveData,loaderUnderBudget: false });
        }

        // scrollIntoView(this.underBudgetDivRef.current, {
        //   align: {
        //     top: 0,
        //     left: 0,
        //     topOffset: 259,
        //     leftOffset: 0,
        //   },
        // });
        // this.setState({ loaderUnderBudget: false });
        resolve();
        //this.setState({ loaderUnderBudget: false });
      })
      .catch((error) => {
        this.setState({ loaderUnderBudget: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  }


  bindHouseKeepingComments() {
    this.setState({ loaderUnderBudget: true });
    let parentRow = this.state.parentRow;
    let request = {} as any;
    request.hotelID = Number(parentRow.hotelID);
    request.selectedDate =  parentRow.date;
    request.type = "Housekeeping";

    LaborDayApprove.bindHouseKeepingComments(request)
        .then(async (result: any[] | null) => {
            if (result != null) {
                if (result.length > 0) {

                  this.setState({ tblComment: result },()=>{
                    this.underBudgetsDeptApprovalData();
                  });
                }
                else {
                  this.underBudgetsDeptApprovalData();
                }
            }
            else {
              this.underBudgetsDeptApprovalData();
            }
            resolve();
        })
        .catch((error) => {
          this.underBudgetsDeptApprovalData();
            reject();
            this.setState({ loaderTrue: false });
        });
}

  refreshUnderBudgetChild() {
    this.underBudgetsDeptApprovalData();
  }

  underBudgetDetails = (row: any, rowIndex: any) => {
    row.parentName = "underBudget";
    this.props.positionDetail(row);
  };

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
     

    if (this.state.tableExpended.length > 0) {
       
      this.setState(
        { expendedRowIndex: rowIndex, isExpand: true, loaderUnderBudget: true },
        () => {
          this.setState(() => ({
            tableExpended: [],
          }));

          let data = this.state.underBudgetDeptsData;
          for (let i = 0; i < data.length; i++) {
            let index = data.indexOf(data[i]);
            data[index].action = "";
          }
          this.setState({
            underBudgetDeptsData: data,
            loaderUnderBudget: false,
          });
        }
      );
    } else {
      row.action = "Expended";
      this.setState(() => ({
        tableExpended: [...this.state.tableExpended, row.rowNo],
      }));
      this.setState(
        {
          expendedRowIndex: rowIndex,
          isExpand: true,
          loaderUnderBudget: false,
        },
        () => {
          this.scrollExpendedInView();
        }
      );
    }
  };


  handlePositionDetails = (row: any, rowIndex: any) => {
    this.underBudgetDetails(row, rowIndex);
  };

   

  scrollExpendedInView = () => {
    if (this.state.tableExpended.length > 0) {
      setTimeout(() => {
        //
        // scrollIntoView(this.expendedUnderBudgetRef.current, {
        //   align: {
        //     top: 0,
        //     left: 0,
        //     topOffset: 259,
        //     leftOffset: 0,
        //   },
        // });
      }, 0);
    } else {
      return false;
    }
  };

  render() {
    
    
    const underBudgetDeptscolumns = [
      
      {
        dataField: "userName",
        text: "Housekeeper",
        headerClasses: 'housekeeperCol',
       // headerFormatter: this.headerFormat,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="underBudgetDeptsDepartment userCol">
              {row.housekeeperName !== "Total" && (
                <div className="profile-img">
                  <img
                    // src={ row.profileImageURL === "" ? profile : row.profileImageURL }
                    src={ row.imagePath === "" || row.imagePath === null ? profile : row.imagePath }
                    // src={ profile }
                    className="default"
                    width="40"
                    height="40"
                  />
                </div> 
              )}
            <EllipsisWithTooltip placement="bottom">
              <span className="underBudgetDeptsDepartment-name font-weight-bold">
                {row.userName}
              </span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "occupiedRoom",
        text: "Occupied",
        headerClasses: "text-center",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <span className="underBudgetDeptsBudgeted d-block text-center">{row.occupiedRoom}</span>
        ),
      },
      {
        dataField: "roomClean",
        text: "Rooms Cleaned",
        headerClasses: "roomCleanedCol",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <span className="underBudgetDeptsBudgeted">{row.roomClean}</span>
        ),
      },
      {
        dataField: "totalCleanTime",
        text: "Total Clean Time",
        headerClasses: "totalCleanTimeCol",
        formatter: (cell: any, row: any, rowIndex: any) => (
          //<span className="underBudgetDeptsBudgeted">{row.totalCleanTime} +{row.totalCleanTimeBudget}</span>
          <div>
          <span className="underBudgetDeptsBudgeted">{row.totalCleanTime}</span>

          {/* <span className={`${Number(row.totalCleanTimeBudget.replace(':','.')) > Number(row.totalCleanTime.replace(':','.')) ?"text-red":'text-green'}`}> */}

          <span className={`${(Number(row.totalCleanTimeBudget.replace(':','.'))===0 && Number(row.totalCleanTime.replace(':','.'))===0) ? "text-green" :
               (Number(row.totalCleanTimeBudget.replace(':','.'))===0 && Number(row.totalCleanTime.replace(':','.')) > 0) ?"text-red" : 
               Number(row.totalCleanTimeBudget.replace(':','.')) > 0 ? "text-red" : "text-green"}`}
              >

       

          {(Number(row.totalCleanTimeBudget.replace(':','.'))===0 && Number(row.totalCleanTime.replace(':','.'))===0) ? 
                Utils.amountFormatted(Number(row.totalCleanTimeBudget.replace(':','.')).toFixed(2).replace('.',':')) :
                Number(row.totalCleanTimeBudget.replace(':','.')) >= 0
                  ? " +" +
                    Utils.amountFormatted(Number(row.totalCleanTimeBudget.replace(':','.')).toFixed(2).replace('.',':'))
                  : Utils.amountFormatted(Number(row.totalCleanTimeBudget.replace(':','.')).toFixed(2).replace('.',':'))}


         </span>
         </div>


        ),
      },
      {
        dataField: "mpcr",
        text: "MPCR",
        headerClasses: "mpcrCol",
        formatter: (cell: any, row: any, rowIndex: any) => (
          // <span className="underBudgetDeptsActual">{row.mpcr} +{row.mpcrBudget}</span>
          <div>
          <span className="underBudgetDeptsBudgeted">{row.mpcr}</span>
          <span className={`${row.mprSign==="Red" ?"text-red":'text-green'}`}>
          {row.mprSign==="Red" ? " +"+ row.mpcrBudget : row.mprSign ==="Green" ? " -"  +row.mpcrBudget:row.mpcrBudget}
         </span>
         </div>
        ),
      },

      {
        dataField: "noteUser",
        text: "Notes",
        headerClasses: "notes-sec notesCol",
        classes: "notes-sec",
        formatter: (cell: any, row: any, rowIndex: any) => {
          const stopPropagation = (e) => e.preventDefault();
          // console.log(row)
          return (
            
            <div className="d-flex justify-content-center">
              {row.HouseKeepingComment.length > 0 && (
                <Dropdown
                  // className="more-action notes-btn housekeepingNotesDropdown"
                  className={ row.HouseKeepingComment.length > 1 ? "more-action approvePendingStatus text-center multiComment" : "more-action approvePendingStatus text-center"}
                  alignRight
                  onClick={stopPropagation}
                >
                  <Dropdown.Toggle
                    className="btn-outline-primary  more"
                    id="dropdown-more"
                  >
                    <div className="d-flex">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                        <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                      </svg>
                      {/* <span>5</span> */}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {row.HouseKeepingComment.map((item: any, idx: any) => {

return (
    <>
   
            <div className="notes-sec">
                <div className="notes-list">
                    <div className="comment-list">
                        <div className="d-flex profile-details">
                            <div className="profile-img">
                                <img
                                    src={item.imageUrl === "" ? profile : item.imageUrl}
                                    className="default testClass"
                                    width="40"
                                    height="40"
                                />
                            </div>
                            <div>
                                <div className="main-ttl">{item.username}</div>
                                <div className="sub-ttl">{Utils.getLocalDateTimeFromUtc(item.createdDate)}</div>
                                {/* <div className="sub-ttl">{item.Date}</div> */}
                            </div>
                        </div>
                        <ul>
                            <li>
                                <div className="d-flex">
                                    <div className="detail-sec">
                                        <div className="comments">
                                            {item.notes}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
       
    </>
)
})}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          );
        },
      },
    ];

    return (
      <>
        {this.state.loaderUnderBudget ? (
          <ReactPageLoader useas={"daysToApproveExpand"} />
        ) : (
          <div
            className="dayApproveHousekeepingTab"
            id="dayApproveHouseKeepingUi"
            ref={this.underBudgetDivRef}
          >
            <div className="d-flex tableHeading">
              <p>Housekeeping Actuals vs Budget</p>
              <div>
                <span className= {this.state.ApproveStatus==="Approval Pending" ? "badge color-red  ml-2" :"badge color-green ml-2"}>{this.state.ApproveStatus}</span>
              </div>
            </div>
            <BootstrapTable
              keyField="rowNo"
              data={this.state.underBudgetDeptsData}
              columns={underBudgetDeptscolumns}
              hover 
            />
          </div>
        )}
      </>
    );
  }
}
