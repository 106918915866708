/* eslint-disable array-callback-return */
import React from "react";
import {
  Form,
  Dropdown,
  Row,
  Col,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { resolve, reject } from "q";
import { MultiSelectSearch } from "../../../../Common/Components/MultiSelectSearch";
import { InvoiceApprovalS } from "../../../../Common/Services/InvoiceApprovals";
import { toast } from "react-toastify";
import { FiChevronDown } from "react-icons/fi";
import { VendorService } from "../../../../Common/Services/vendor";
import "./vendorTabs.scss";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { TransactionLogHistory as VendorHistoryLog } from "../../../Register/TransactionLogHistory";
import $ from "jquery";
import { Utils } from "../../../../Common/Utilis";

let isChange: any = false;
let oldBilling: any = "";
let isChangeCOA: any = false;
let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export class GeneralTab extends React.Component<any, any> {
  private multiselectChild: any;
  constructor(props) {
    super(props);
    this.multiselectChild = React.createRef();
    this.state = {
      vendorDetails: props?.VendorDetails,
      billingDetails: props?.billingDetails,
      isbillingModal: false,
      addAddress: {
        street: "",
        city: "",
        state: "",
        pcode: "",
        status: "Active",
        department: "",
        streetError: false,
        cityError: false,
        stateError: false,
        pcodeError: false,
      },
      vendorError: {
        company: false,
        billingEmail: false,
        phone: false,
        taxOrSsnId: false,
        taxType: false,
        invoiceDue: false,
        services: false,
      },
      editVandor: {
        company: "",
        billingEmail: "",
        phone: "",
        taxOrSsnId: "",
        taxType: "",
        invoiceDue: "",
        services: "",
      },
      COAList: [],
      allCOAList: [],
      isConfiPerm: "",
      isFormSubmit: false,
      isEpayEnable: false,
    };
  }

  componentDidMount() {
    this.GetHotelCOAlist();
    Object.keys(this.state.vendorDetails[0]).forEach((item) => {
      this.validateVendorForm(item);
    });
    oldBilling = this.state.vendorDetails[0].billingEmail;
    this.CheckEpayEnabled();
  }

  CheckEpayEnabled = () => {
    InvoiceApprovalS.CheckEpayEnabled()
      .then(async (result: any) => {
        if (result?.message === "Success") {
          const isCustIDExist = result?.result?.isCustIDExist;
          const isePayEnabled = result?.result.isePayEnabled;

          this.setState({
            isEpayEnable: isCustIDExist && isePayEnabled ? true : false,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT });
        reject();
      });
  };

  public static filterCOAList = (allCOAList: any, isConfiCOAPerm: any) => {
    let filteredCOA = allCOAList.filter((item) => item.status === "active");
    if (!isConfiCOAPerm) {
      filteredCOA = filteredCOA.filter((item) => item.isconfidentialcoa === "");
    }
    return filteredCOA;
  };

  GetHotelCOAlist = () => {
    let { vendorDetails } = this.state;
    let request: any = {};
    request.vid = this.props?.vid;
    InvoiceApprovalS.GetHotelCOAlist(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          let isConfiPerm = result.some((item: any) => item.isCOAPer === "Yes");
          result = result.map((item: any, id: any) => {
            return {
              value: item.id + " " + item.coaName,
              chartNo: item.id,
              chartName: item.coaName,
              description: item.id + " " + item.coaName,
              isChecked: false,
              id: item.id,
              isCOAPer: item.isCOAPer,
              coaName: item.coaName,
              status:
                !isConfiPerm && item.isconfidentialcoa !== ""
                  ? "inactive"
                  : item.status?.toLowerCase(),
              isconfidentialcoa: item.isconfidentialcoa,
            };
          });
          result = _.sortBy(result, "chartNo");
          result = _.sortBy(result, "status");
          let newCOAList = vendorDetails[0].coaList.split(",");
          newCOAList.length > 0 &&
            newCOAList.forEach((item) => {
              result.forEach((coaItem) => {
                if (item === coaItem.chartNo) {
                  vendorDetails[0].selectedCOAList.push(coaItem);
                }
              });
            });
          result = GeneralTab.filterCOAList(result, result[0].isCOAPer);
          this.setState(
            {
              vendorDetails,
              COAList: result,
              allCOAList: result,
              isConfiCOAPerm: isConfiPerm,
            },
            () => {
              this.multiselectChild?.current?.updateSavedItem(
                vendorDetails[0].selectedCOAList
              );
            }
          );
        }
        resolve();
      })
      .catch((error) => {
        reject();
        Utils.toastError("Something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  updateMultiSelectItem = (coaSelectedItem: any) => {
    let { vendorDetails } = this.state;
    isChangeCOA = true;
    vendorDetails[0].coaList = "";
    let filteredActiveCOA = this.state.allCOAList.filter(
      (item) => item.status === "active"
    );
    let newItem = {} as any;
    coaSelectedItem.forEach((element) => {
      newItem.value = element.value;
      newItem.chartNo = element.chartNo;
      newItem.chartName = element.chartName;
      newItem.description = element.description;
      newItem.isChecked = true;
      newItem.id = element.id;
      newItem.isCOAPer = element.isCOAPer;
      newItem.coaName = element.coaName;
      newItem.status = element.status;
      newItem.isconfidentialcoa = element.isconfidentialcoa;
      filteredActiveCOA.push(newItem);
      vendorDetails[0].coaList += element.chartNo + ",";
    });
    vendorDetails[0].coaList = vendorDetails[0].coaList.replace(
      /,(?=\s*$)/,
      ""
    );
    vendorDetails[0].selectedCOAList.push(coaSelectedItem);
    filteredActiveCOA = _.uniqBy(filteredActiveCOA, "chartNo");
    this.setState({ vendorDetails, COAList: filteredActiveCOA });
  };

  dataChange = () => { };

  handleMultiSelectClose = (value) => {
    let { vendorDetails } = this.state;
    if (isChangeCOA) {
      this.UpdateVendorDetails(vendorDetails, "coa");
    }
  };

  allCharactersSame = (s): boolean => {
    let n = s.length;
    for (let i = 1; i < n; i++) if (s[i] !== s[0]) return false;
    return true;
  };

  validateVendorForm = (name: any) => {
    let { vendorDetails, vendorError } = this.state;
    if (name === "company") {
      if (
        vendorDetails[0].company.length < 3 ||
        vendorDetails[0].company.length > 50
      ) {
        vendorError.company = true;
        this.setState(() => ({ vendorError }));
      } else {
        vendorError.company = false;
        this.setState(() => ({ vendorError }));
      }
    } else if (name === "billingEmail") {
      let isValidEmail = emailRegex;
      if (vendorDetails[0]?.dachStatus?.toLowerCase() !== "verified") {
        if (vendorDetails[0]?.billingEmail.length > 0) {
          if (vendorDetails[0].billingEmail.match(isValidEmail)) {
            vendorError.billingEmail = false;
            this.setState(() => ({ vendorError }));
          } else {
            vendorError.billingEmail = true;
            this.setState(() => ({ vendorError }));
          }
        } else {
          vendorError.billingEmail = false;
          this.setState(() => ({ vendorError }));
        }
      } else {
        if (vendorDetails[0].billingEmail.match(isValidEmail)) {
          vendorError.billingEmail = false;
          this.setState(() => ({ vendorError }));
        } else {
          vendorError.billingEmail = true;
          this.setState(() => ({ vendorError }));
        }
      }
    } else if (name === "phone") {
      if (
        vendorDetails[0].phone.length !== 0 &&
        vendorDetails[0].phone.length < 10
      ) {
        vendorError.phone = true;
        this.setState(() => ({ vendorError }));
      } else {
        vendorError.phone = false;
        this.setState(() => ({ vendorError }));
      }
    } else if (name === "taxOrSsnId") {
      let ssn = vendorDetails[0].taxOrSsnId?.toString().replace(/-/g, "");
      if (ssn?.length > 5 && !ssn?.includes("-")) {
        if (vendorDetails[0].isTaxOrSSN === "TAX ID")
          ssn = ssn.substr(0, 2) + "-" + ssn.substr(2, ssn?.length - 2);
        else
          ssn =
            ssn.substr(0, 3) +
            "-" +
            ssn.substr(3, 2) +
            "-" +
            ssn.substr(5, ssn?.length - 4);
      }
      vendorDetails[0].taxOrSsnId = ssn;
      if (vendorDetails[0].taxOrSsnId.length === 0) {
        vendorError.taxOrSsnId = false;
      } else {
        let charLen = vendorDetails[0].isTaxOrSSN === "TAX ID" ? 10 : 11;
        if (
          vendorDetails[0].taxOrSsnId.length > 1 &&
          vendorDetails[0].taxOrSsnId.length < charLen
        ) {
          vendorError.taxOrSsnId = true;
          this.setState(() => ({ vendorError }));
        } else {
          vendorError.taxOrSsnId = false;
          this.setState(() => ({ vendorError }));
        }
      }
      this.setState(() => ({ vendorDetails }));
    }
    // else if (name === "taxType") {
    //     if (vendorDetails[0].taxType.length < 1 || vendorDetails[0].taxType.length > 50) {
    //         vendorError.taxType = true;
    //         this.setState(() => ({ vendorError }));
    //     } else {
    //         vendorError.taxType = false;
    //         this.setState(() => ({ vendorError }));
    //     }
    // }
    else if (name === "invoiceDue") {
      if (vendorDetails[0].invoiceDue.length < 1) {
        vendorError.invoiceDue = true;
        this.setState(() => ({ vendorError }));
      } else {
        vendorError.invoiceDue = false;
        this.setState(() => ({ vendorError }));
      }
    }
  };

  onVendorFieldChange = (event: any, name: any) => {
    let { vendorDetails, vendorError } = this.state;
    if (name === "company") {
      if (event.target.value.length < 3 || event.target.value.length > 50) {
        vendorError.company = true;
        vendorDetails[0].company = event.target.value.trimStart();
        this.setState(() => ({ vendorDetails, vendorError }));
      } else {
        vendorError.company = false;
        vendorDetails[0].company = event.target.value.trimStart();
        this.setState(
          () => ({ vendorDetails, vendorError }),
          () => {
            isChange = true;
          }
        );
      }
    } else if (name === "billingEmail") {
      let isValidEmail = emailRegex;
      if (vendorDetails[0]?.dachStatus?.toLowerCase() !== "verified") {
        if (event.target.value.trimStart().trim().length > 0) {
          if (event.target.value.trimStart().trim().match(isValidEmail)) {
            vendorError.billingEmail = false;
            vendorDetails[0].billingEmail = event.target.value.trimStart();
            this.setState(() => ({ vendorDetails, vendorError }), () => { isChange = true });
          } else {
            vendorError.billingEmail = true;
            vendorDetails[0].billingEmail = event.target.value.trimStart();
            this.setState(() => ({ vendorDetails, vendorError }));
          }
        } else {
          if (oldBilling !== "") {
            vendorError.billingEmail = false;
            vendorDetails[0].billingEmail = event.target.value.trimStart();
            this.setState(
              () => ({ vendorDetails, vendorError }),
              () => {
                isChange = true;
              }
            );
          } else {
            vendorError.billingEmail = false;
            vendorDetails[0].billingEmail = event.target.value.trimStart();
            this.setState(
              () => ({ vendorDetails, vendorError }),
              () => {
                isChange = false;
              }
            );
          }
        }
      } else {
        if (event.target.value.match(isValidEmail)) {
          vendorError.billingEmail = false;
          vendorDetails[0].billingEmail = event.target.value.trimStart();
          this.setState(
            () => ({ vendorDetails, vendorError }),
            () => {
              isChange = true;
            }
          );
        } else {
          vendorError.billingEmail = true;
          vendorDetails[0].billingEmail = event.target.value.trimStart();
          this.setState(() => ({ vendorDetails, vendorError }));
        }
      }
    } else if (name === "phone") {
      if (event.target.value.length !== 0 && event.target.value.length < 10) {
        vendorError.phone = true;
        vendorDetails[0].phone = event.target.value.replace(/[^\d]/g, "");
        this.setState(() => ({ vendorDetails, vendorError }));
      } else {
        vendorError.phone = false; // /^(\+\d{1,3}[- ]?)?\d{10}$/
        vendorDetails[0].phone = event.target.value.replace(/[^\d]/g, "");
        this.setState(
          () => ({ vendorDetails, vendorError }),
          () => {
            isChange = true;
          }
        );
      }
    } else if (name === "taxOrSsnId") {
      let value = event.target.value.trim().replace(/[^\d]/g, "");
      value = value?.substr(0, 9);
      if (value.length === 0) {
        vendorError.taxOrSsnId = false;
        vendorDetails[0].taxOrSsnId = value;
        this.setState(
          () => ({ vendorDetails, vendorError }),
          () => {
            isChange = true;
          }
        );
      } else {
        if (value.length < 9 || this.allCharactersSame(value)) {
          vendorError.taxOrSsnId = true;
          vendorDetails[0].taxOrSsnId = value;
          this.setState(() => ({ vendorDetails, vendorError }));
        } else {
          vendorError.taxOrSsnId = false;
          vendorDetails[0].taxOrSsnId = value;
          this.setState(
            () => ({ vendorDetails, vendorError }),
            () => {
              isChange = true;
            }
          );
        }
      }
    } else if (name === "taxType") {
      if (event.target.value.length < 1 || event.target.value.length > 50) {
        vendorError.taxType = true;
        vendorDetails[0].taxType = event.target.value.trimStart();
        this.setState(() => ({ vendorDetails, vendorError }));
      } else {
        vendorError.taxType = false;
        vendorDetails[0].taxType = event.target.value.trimStart();
        this.setState(
          () => ({ vendorDetails, vendorError }),
          () => {
            isChange = true;
          }
        );
      }
    } else if (name === "invoiceDue") {
      if (event.target.value.length < 1) {
        vendorError.invoiceDue = true;
        vendorDetails[0].invoiceDue = event.target.value
          .replace(/[^\d]/g, "")
          .trimStart();
        this.setState(() => ({ vendorDetails, vendorError }));
      } else {
        vendorError.invoiceDue = false;
        vendorDetails[0].invoiceDue = event.target.value
          .replace(/[^\d]/g, "")
          .trimStart();
        this.setState(
          () => ({ vendorDetails, vendorError }),
          () => {
            isChange = true;
          }
        );
      }
    } else if (name === "services") {
      vendorDetails[0].services = event.target.value.trimStart();
      this.setState(() => ({ vendorDetails, vendorError }), () => { isChange = true });
    } else if (name === "paymentType") {
      vendorDetails[0].paymentType = event;
      this.setState(
        () => ({ vendorDetails, vendorError }),
        () => {
          this.UpdateVendorDetails(vendorDetails, "paymentType");
        }
      );
    } else if (name === "isTaxOrSSN") {
      vendorDetails[0].isTaxOrSSN = event;
      let ssn = vendorDetails[0].taxOrSsnId?.replaceAll("-", "").toString();
      if (ssn?.length > 5 && !ssn?.includes("-")) {
        if (event === "TAX ID")
          vendorDetails[0].taxOrSsnId =
            ssn.substr(0, 2) + "-" + ssn.substr(2, ssn?.length - 2);
        else
          vendorDetails[0].taxOrSsnId =
            ssn.substr(0, 3) +
            "-" +
            ssn.substr(3, 2) +
            "-" +
            ssn.substr(5, ssn?.length - 4);
      }
      this.setState(
        () => ({ vendorDetails, vendorError }),
        () => {
          this.UpdateVendorDetails(vendorDetails, event);
        }
      );
    } else if (name === "vendor_1099") {
      vendorDetails[0].vendor_1099 = event.target.checked ? "Yes" : "No";
      this.setState(
        () => ({ vendorDetails, vendorError }),
        () => {
          this.UpdateVendorDetails(vendorDetails, "vendor_1099");
        }
      );
    } else if (name === "salesTax") {
      vendorDetails[0].salesTax = event.target.checked ? "Yes" : "No";
      this.setState(
        () => ({ vendorDetails, vendorError }),
        () => {
          this.UpdateVendorDetails(vendorDetails, "salesTax");
        }
      );
    } else if (name === "groupInvoice") {
      vendorDetails[0].groupInvoice = event.target.checked ? "No" : "Yes";
      this.setState(
        () => ({ vendorDetails, vendorError }),
        () => {
          this.UpdateVendorDetails(vendorDetails, "groupInvoice");
        }
      );
    } else if (name === "ContractAgency") {
      vendorDetails[0].isContractAgency = event.target.checked ? "Yes" : "No";
      this.setState(
        () => ({ vendorDetails, vendorError }),
        () => {
          this.UpdateVendorDetails(vendorDetails, "ContractAgency");
        }
      );
    } else if (name === "PostingRule") {
      vendorDetails[0].postingRule = event.target.value?.trimStart();
      this.setState(
        () => ({ vendorDetails, vendorError }),
        () => {
          isChange = true;
        }
      );
    }
  };

  onVendorFieldBlur = (event: any, name: any) => {
    let { vendorDetails, vendorError } = this.state;
    if (name === "company") {
      if (event.target.value.length < 3 || event.target.value.length > 50) {
        vendorError.company = true;
        this.setState(() => ({ vendorError }));
      } else {
        vendorError.company = false;
        this.setState(
          () => ({ vendorError }),
          () => {
            if (isChange) {
              this.UpdateVendorDetails(vendorDetails, "company");
            }
          }
        );
      }
    } else if (name === "billingEmail") {
      let isValidEmail = emailRegex;
      if (vendorDetails[0]?.dachStatus?.toLowerCase() !== "verified") {
        if (event.target.value.trimStart().trim().length > 0) {
          if (vendorDetails[0].billingEmail.match(isValidEmail)) {
            vendorError.billingEmail = false;
            this.setState(
              () => ({ vendorError }),
              () => {
                if (isChange) {
                  this.UpdateVendorDetails(vendorDetails, "billingEmail");
                }
              }
            );
          } else {
            vendorError.billingEmail = true;
            this.setState(() => ({ vendorDetails, vendorError }));
          }
        } else {
          vendorError.billingEmail = false;
          this.setState(
            () => ({ vendorError }),
            () => {
              if (isChange) {
                this.UpdateVendorDetails(vendorDetails, "billingEmail");
              }
            }
          );
        }
      } else {
        if (event.target.value.match(isValidEmail)) {
          vendorError.billingEmail = false;
          this.setState(
            () => ({ vendorError }),
            () => {
              if (isChange) {
                this.UpdateVendorDetails(vendorDetails, "billingEmail");
              }
            }
          );
        } else {
          vendorError.billingEmail = true;
          this.setState(() => ({ vendorDetails, vendorError }));
        }
      }
    } else if (name === "phone") {
      if (event.target.value.length !== 0 && event.target.value.length < 10) {
        vendorError.phone = true;
        this.setState(() => ({ vendorDetails, vendorError }));
      } else {
        vendorError.phone = false;
        this.setState(
          () => ({ vendorError }),
          () => {
            if (isChange) {
              this.UpdateVendorDetails(vendorDetails, "phone");
            }
          }
        );
      }
    } else if (name === "taxOrSsnId") {
      let ssn = vendorDetails[0].taxOrSsnId?.toString();
      if (ssn?.length > 5 && !ssn?.includes("-")) {
        if (vendorDetails[0].isTaxOrSSN === "TAX ID")
          ssn = ssn.substr(0, 2) + "-" + ssn.substr(2, ssn?.length - 2);
        else
          ssn =
            ssn.substr(0, 3) +
            "-" +
            ssn.substr(3, 2) +
            "-" +
            ssn.substr(5, ssn?.length - 4);
      }
      if (event.target.value.length === 0) {
        vendorDetails[0].taxOrSsnId = ssn;
        vendorError.taxOrSsnId = false;
        this.setState(() => ({ vendorDetails, vendorError }));
        if (isChange) {
          this.UpdateVendorDetails(vendorDetails, "taxOrSsnId");
        }
      } else {
        let charLen = vendorDetails[0].isTaxOrSSN === "TAX ID" ? 10 : 11;
        if (
          ssn.length < charLen ||
          this.allCharactersSame(event.target.value)
        ) {
          vendorDetails[0].taxOrSsnId = ssn;
          vendorError.taxOrSsnId = true;
          this.setState(() => ({ vendorDetails, vendorError }));
        } else {
          vendorDetails[0].taxOrSsnId = ssn;
          vendorError.taxOrSsnId = false;
          this.setState(
            () => ({ vendorDetails, vendorError }),
            () => {
              if (isChange) {
                this.UpdateVendorDetails(vendorDetails, "taxOrSsnId");
              }
            }
          );
        }
      }
    } else if (name === "taxType") {
      if (event.target.value.length < 1 || event.target.value.length > 50) {
        vendorError.taxType = true;
        this.setState(() => ({ vendorError }));
      } else {
        vendorError.taxType = false;
        this.setState(
          () => ({ vendorError }),
          () => {
            if (isChange) {
              this.UpdateVendorDetails(vendorDetails, "taxType");
            }
          }
        );
      }
    } else if (name === "invoiceDue") {
      if (vendorDetails[0].invoiceDue.length < 1) {
        vendorError.invoiceDue = true;
        this.setState(() => ({ vendorError }));
      } else {
        vendorError.invoiceDue = false;
        this.setState(
          () => ({ vendorError }),
          () => {
            if (isChange) {
              this.UpdateVendorDetails(vendorDetails, "invoiceDue");
            }
          }
        );
      }
    } else if (name === "services") {
      this.setState(() => ({ vendorDetails, vendorError }), () => {
        if (isChange) {
          this.UpdateVendorDetails(vendorDetails, "services");
        }
      });
    } else if (name === "PostingRule") {
      if (isChange) {
        vendorDetails[0].postingRule = vendorDetails[0].postingRule?.trim();
        this.setState(
          () => ({ vendorDetails, vendorError }),
          () => {
            this.UpdateVendorDetails(vendorDetails, "PostingRule");
          }
        );
      }
    }
  };

  onVendorFieldFocus = (event: any, name: any) => {
    let { editVandor, vendorError, vendorDetails } = this.state;
    if (name === "company") {
      editVandor.company = vendorDetails[0].company;
    } else if (name === "billingEmail") {
      editVandor.billingEmail = vendorDetails[0].billingEmail;
    } else if (name === "phone") {
      editVandor.phone = vendorDetails[0].phone;
    } else if (name === "taxOrSsnId") {
      if (vendorDetails[0].taxOrSsnId.length === 0) {
        editVandor.taxOrSsnId = vendorDetails[0].taxOrSsnId;
        event.target.value = vendorDetails[0].taxOrSsnId
          ?.toString()
          .replace(/-/g, "");
        vendorError.taxOrSsnId = false;
        vendorDetails[0].taxOrSsnId = event.target.value;
      } else {
        editVandor.taxOrSsnId = vendorDetails[0].taxOrSsnId;
        event.target.value = vendorDetails[0].taxOrSsnId
          ?.toString()
          .replace(/-/g, "");
        vendorError.taxOrSsnId = vendorDetails[0].taxOrSsnId.length < 9;
        vendorDetails[0].taxOrSsnId = event.target.value;
      }
    } else if (name === "taxType") {
      editVandor.taxType = vendorDetails[0].taxType;
    } else if (name === "invoiceDue") {
      editVandor.invoiceDue = vendorDetails[0].invoiceDue;
    } else if (name === "services") {
      editVandor.services = vendorDetails[0].services;
    } else if (name === "groupInvoice") {
      editVandor.groupInvoice = vendorDetails[0].groupInvoice;
    } else if (name === "IsContractAgency") {
      editVandor.isContractAgency = vendorDetails[0].isContractAgency;
    }
    this.setState(() => ({ editVandor, vendorError }));
  };

  handleRowClick = (type: string, row: any) => {
    let isType = type.toString().toLocaleLowerCase();
    if (isType === "active" || isType === "inactive") {
      let request: any = {};
      request.VID = this.props.vid;
      request.AID = row.aid;
      request.Street = row.street === null ? "" : row.street;
      request.City = row.city === null ? "" : row.city;
      request.State = row.state === null ? "" : row.state;
      request.Pcode = row.pcode === null ? "" : row.pcode;
      request.Status = isType === "active" ? "Active" : "Inactive";
      request.Department = row.department === null ? "" : row.department;
      request.ActionType = "Update Status";
      this.saveAddressForm(request, "changeStatus", row);
    } else if (isType === "notinactive") {
      Utils.toastError(
        "This address is associated with an active contract. Please inactivate the contract first.",
        { position: toast.POSITION.BOTTOM_RIGHT }
      );
    }
  };

  showBillingAddress = (type: any, row: any) => {
    let { billingDetails } = this.state;
    if (type === "addAddress") {
      this.setState({ isbillingModal: true });
    } else {
      billingDetails = billingDetails.map((items) => {
        if (items.rowNo === row.rowNo) {
          return {
            ...items,
            isEditModal: true,
            rowNo1: items.rowNo,
            aid1: items.aid,
            department1: items.department,
            city1: items.city,
            pcode1: items.pcode,
            status1: items.status,
            street1: items.street,
            state1: items.state,
            streetError: !this.validateAddress(items.street),
            cityError: !this.validateAddress(items.city),
            stateError: !this.validateAddress(items.state),
            pcodeError: !this.pCodeValidate(items.pcode),
          };
        } else {
          return items;
        }
      });
      this.setState({ billingDetails });
    }
  };

  resetDefaultAddAddress = () => {
    let defaultAddAddress = {
      street: "",
      city: "",
      state: "",
      pcode: "",
      status: "Active",
      department: "",
      streetError: false,
      cityError: false,
      stateError: false,
      pcodeError: false,
    };
    this.setState({ addAddress: defaultAddAddress });
  };

  hideBillingAddress = (type: any, row: any) => {
    let { billingDetails } = this.state;
    if (type === "addAddress") {
      this.setState({ isbillingModal: false, isFormSubmit: false }, () => {
        this.resetDefaultAddAddress();
      });
    } else {
      billingDetails = billingDetails.map((items) => {
        if (items.rowNo === row.rowNo) {
          return {
            ...items,
            isEditModal: false,
            streetError: false,
            cityError: false,
            stateError: false,
            pcodeError: false,
          };
        } else {
          return items;
        }
      });
      this.setState(
        (curr: any) => ({ ...curr, billingDetails }),
        () => {
          $("body").trigger("click");
        }
      );
    }
  };

  pCodeValidate = (val: any) => {
    return /^\d{5}(-\d{4})?$/.test(val);
  };

  validateAddress = (val: any) => {
    return /^(?=.*[a-zA-Z0-9])/.exec(val);
  };

  handleFormBlur = (event: any, type: any, row: any, name: any) => {
    let { billingDetails, addAddress } = this.state;
    if (type === "addAddress") {
      if (name === "pcode") {
        let pCode = addAddress[name].toString().trim().replace(/-/g, "");
        if (pCode?.length > 5 && pCode?.length === 9 && !pCode?.includes("-")) {
          pCode = pCode.substr(0, 5) + "-" + pCode.substr(5, pCode?.length - 5);
        }
        addAddress[name] = pCode;
        addAddress.pcodeError = !this.pCodeValidate(pCode);
      }
    } else {
      billingDetails = billingDetails.map((items) => {
        if (items.rowNo === row.rowNo) {
          if (name === "pcode") {
            let pCode = items.pcode1.toString().trim().replace(/-/g, "");
            if (
              pCode?.length > 5 &&
              pCode?.length === 9 &&
              !pCode?.includes("-")
            ) {
              pCode =
                pCode.substr(0, 5) + "-" + pCode.substr(5, pCode?.length - 5);
            }
            return {
              ...items,
              pcodeError: !this.pCodeValidate(pCode),
              pcode1: pCode,
            };
          }
        } else {
          return items;
        }
      });
      this.setState((curr: any) => ({ ...curr, billingDetails }));
    }
    this.setState({ addAddress, isFormSubmit: false });
  };

  setZipError = (value) => {
    let zipActLen = value.includes("-") ? 10 : 9;
    let zipLen = value.length;
    let pcodeError: boolean = false;
    if (zipLen < 5) {
      pcodeError = true;
    } else if (zipLen > 5 && zipLen < zipActLen) {
      pcodeError = true;
    } else {
      pcodeError = false;
    }
    return pcodeError;
  };

  handleFormChange = (event: any, type: any, row: any, name: any) => {
    let { billingDetails, addAddress } = this.state;
    let value = event.target.value;
    if (type === "addAddress") {
      if (name === "pcode") {
        value = value.indexOf("-") === 5 ? value : value.replace(/[^\d]/g, "");
        value = value
          ?.toString()
          .trim()
          .replace(/[^0-9-]/g, "");
        value = value?.substring(0, value.indexOf("-") === 5 ? 10 : 9);
        addAddress[name] = value;
        addAddress.pcodeError = this.setZipError(value);
      } else {
        addAddress[name] = value?.toString().trimStart();
        if (name === "street") {
          addAddress.streetError =
            value.length > 0 && this.validateAddress(value) ? false : true;
        } else if (name === "city") {
          addAddress.cityError =
            value.length > 0 && this.validateAddress(value) ? false : true;
        } else if (name === "state") {
          addAddress.stateError =
            value.length > 0 && this.validateAddress(value) ? false : true;
        }
      }
      this.setState({ addAddress, isFormSubmit: false });
    } else {
      billingDetails = billingDetails.map((items) => {
        if (items.rowNo === row.rowNo) {
          if (name === "department") {
            return {
              ...items,
              department1: value?.toString().trimStart(),
              isFormSubmit: false,
            };
          } else if (name === "street") {
            return {
              ...items,
              street1: value?.toString().trimStart(),
              streetError:
                value.length > 0 && this.validateAddress(value) ? false : true,
              isFormSubmit: false,
            };
          } else if (name === "city") {
            return {
              ...items,
              city1: value?.toString().trimStart(),
              cityError:
                value.length > 0 && this.validateAddress(value) ? false : true,
              isFormSubmit: false,
            };
          } else if (name === "state") {
            return {
              ...items,
              state1: value?.toString().trimStart(),
              stateError:
                value.length > 0 && this.validateAddress(value) ? false : true,
              isFormSubmit: false,
            };
          } else if (name === "pcode") {
            value =
              value.indexOf("-") === 5 ? value : value.replace(/[^\d]/g, "");
            value = value
              ?.toString()
              .trim()
              .replace(/[^0-9-]/g, "");
            value = value.substring(0, value.indexOf("-") === 5 ? 10 : 9);
            return {
              ...items,
              pcode1: value,
              pcodeError: this.setZipError(value),
              isFormSubmit: false,
            };
          }
        } else {
          return items;
        }
      });
      this.setState((curr: any) => ({ ...curr, billingDetails }));
    }
  };

  billingModalContainer = (type, row) => {
    let { addAddress, isFormSubmit } = this.state;
    let isNewAddess = type === "addAddress";
    let isSpclCharMsg = "Fields containing only special characters is not allowed. Please update the field.";
    return (
      <div className="generalEditForm">
        <div className="formTitle">
          <h4 className="mr-auto">
            {isNewAddess ? "Add Address" : "Edit Address"}
          </h4>
          <button
            type="button"
            className="btn wht-bg cross"
            onClick={() =>
              this.hideBillingAddress(
                isNewAddess ? "addAddress" : "editAddress",
                isNewAddess ? "" : row
              )
            }
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                fill="#84888C"
              />
            </svg>
          </button>
        </div>
        <div className="formContainer">
          <Form.Group controlId="DepartmentSelection">
            <Form.Label>Department</Form.Label>
            <input
              type="text"
              maxLength={50}
              className="form-control"
              value={isNewAddess ? addAddress.department : row.department1}
              onChange={(event: any) =>
                this.handleFormChange(
                  event,
                  type,
                  isNewAddess ? addAddress : row,
                  "department"
                )
              }
            />
          </Form.Group>
          <Form.Group controlId="StreetSelection">
            <Form.Label>Street</Form.Label>
            <input
              type="text"
              className={`${addAddress.streetError || row.streetError ? "alert-danger" : ""
                } form-control`}
              maxLength={100}
              value={isNewAddess ? addAddress.street : row.street1}
              onChange={(event: any) =>
                this.handleFormChange(
                  event,
                  type,
                  isNewAddess ? addAddress : row,
                  "street"
                )
              }
            />
            {isNewAddess ? (
              addAddress.street.length > 0 &&
                !this.validateAddress(addAddress.street) ? (
                <div className="textError">{isSpclCharMsg}</div>
              ) : (
                addAddress.streetError && (
                  <div className="textError">Please enter street.</div>
                )
              )
            ) : row.street1.length > 0 && !this.validateAddress(row.street1) ? (
              <div className="textError">{isSpclCharMsg}</div>
            ) : (
              row.streetError && (
                <div className="textError">Please enter street.</div>
              )
            )}
          </Form.Group>
          <Form.Group controlId="CitySelection">
            <Form.Label>City</Form.Label>
            <input
              type="text"
              maxLength={50}
              className={`${addAddress.cityError || row.cityError ? "alert-danger" : ""
                } form-control`}
              value={isNewAddess ? addAddress.city : row.city1}
              onChange={(event: any) =>
                this.handleFormChange(
                  event,
                  type,
                  isNewAddess ? addAddress : row,
                  "city"
                )
              }
            />

            {isNewAddess ? (
              addAddress.city.length > 0 &&
                !this.validateAddress(addAddress.city) ? (
                <div className="textError">{isSpclCharMsg}</div>
              ) : (
                addAddress.cityError && (
                  <div className="textError">Please enter city.</div>
                )
              )
            ) : row.city1.length > 0 && !this.validateAddress(row.city1) ? (
              <div className="textError">{isSpclCharMsg}</div>
            ) : (
              row.cityError && (
                <div className="textError">Please enter city.</div>
              )
            )}
          </Form.Group>
          <Row>
            <Col className="pr-1">
              <Form.Group
                controlId="StateSelection"
                className={`${addAddress.stateError || row.stateError
                  ? "generalStateControlError"
                  : ""
                  } generalStateControl`}
              >
                <Form.Label>State</Form.Label>
                <input
                  type="text"
                  maxLength={50}
                  className={`${addAddress.stateError || row.stateError
                    ? "alert-danger"
                    : ""
                    } form-control`}
                  value={isNewAddess ? addAddress.state : row.state1}
                  onChange={(event: any) =>
                    this.handleFormChange(
                      event,
                      type,
                      isNewAddess ? addAddress : row,
                      "state"
                    )
                  }
                />
                {isNewAddess ? (
                  addAddress.state.length > 0 &&
                    !this.validateAddress(addAddress.state) ? (
                    <div className="textError">{isSpclCharMsg}</div>
                  ) : (
                    addAddress.stateError && (
                      <div className="textError">Please enter state.</div>
                    )
                  )
                ) : row.state1.length > 0 &&
                  !this.validateAddress(row.state1) ? (
                  <div className="textError">{isSpclCharMsg}</div>
                ) : (
                  row.stateError && (
                    <div className="textError">Please enter state.</div>
                  )
                )}
              </Form.Group>
            </Col>
            <Col className="pl-1">
              <Form.Group controlId="ZIPSelection">
                <Form.Label>ZIP</Form.Label>
                <input
                  type="text"
                  maxLength={10}
                  className={`${addAddress.pcodeError || row.pcodeError
                    ? "alert-danger"
                    : ""
                    } form-control`}
                  value={isNewAddess ? addAddress.pcode : row.pcode1}
                  onChange={(event: any) =>
                    this.handleFormChange(
                      event,
                      type,
                      isNewAddess ? addAddress : row,
                      "pcode"
                    )
                  }
                  onBlur={(event: any) =>
                    this.handleFormBlur(
                      event,
                      type,
                      isNewAddess ? addAddress : row,
                      "pcode"
                    )
                  }
                />
                {(addAddress.pcodeError || row.pcodeError) && (
                  <div className="textError">
                    {(isNewAddess ? addAddress.pcode : row.pcode1).length > 5
                      ? "Please enter 9 digit zip."
                      : "Please enter 5 digit zip."}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="formFooter">
          <Button
            disabled={isNewAddess ? isFormSubmit : row.isFormSubmit}
            onClick={(e: any) =>
              this.handleSaveAddress(type, isNewAddess ? "" : row)
            }
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  handleSaveAddress = (type, row) => {
    let { addAddress, billingDetails } = this.state;
    let request: any = {};
    let isLenMsg: any =
      "Total length of City, State and ZIP cannot be greater than 50.";
    if (type === "addAddress") {
      addAddress.streetError =
        addAddress.street === "" || !this.validateAddress(addAddress.street);
      addAddress.cityError =
        addAddress.city === "" || !this.validateAddress(addAddress.city);
      addAddress.stateError =
        addAddress.state === "" ||
        addAddress.state === undefined ||
        !this.validateAddress(addAddress.state);
      addAddress.pcodeError = !this.pCodeValidate(addAddress.pcode);
      let isGreaterLen: boolean = false;
      let isValidForm =
        !addAddress.streetError &&
        !addAddress.cityError &&
        !addAddress.stateError &&
        !addAddress.pcodeError;
      if (isValidForm) {
        addAddress.pcodeError = false;
        request.VID = this.props.vid;
        request.AID = 0;
        request.Street = addAddress.street.trim();
        request.City = addAddress.city.trim();
        request.State = addAddress.state.trim();
        request.Pcode = addAddress.pcode.trim();
        request.Status =
          addAddress.status === undefined ? "Active" : addAddress.status;
        request.Department = addAddress.department.trim();
        request.ActionType = "New Address";
        isGreaterLen =
          request.City.length + request.State.length + request.Pcode.length >
          50;
        if (isGreaterLen) {
          Utils.toastError(isLenMsg, { position: toast.POSITION.BOTTOM_RIGHT });
        } else {
          this.saveAddressForm(request, "addAddress", "");
        }
      }

      this.setState(() => ({ addAddress }));
    } else {
      let isValidForm: any;
      billingDetails = billingDetails.map((items) => {
        if (items.rowNo === row.rowNo) {
          return {
            ...items,
            streetError:
              items.street1 === "" || !this.validateAddress(items.street1),
            cityError: items.city1 === "" || !this.validateAddress(items.city1),
            stateError:
              items.state1 === "" ||
              items.state1 === undefined ||
              !this.validateAddress(items.state1),
            pcodeError: !this.pCodeValidate(items.pcode1),
          };
        } else {
          return items;
        }
      });
      this.setState((curr: any) => ({ ...curr, billingDetails }));
      let isGreaterLen: boolean = false;
      billingDetails.forEach((items) => {
        if (items.rowNo === row.rowNo) {
          isValidForm =
            !items.streetError &&
            !items.cityError &&
            !items.stateError &&
            !items.pcodeError;
          isGreaterLen =
            items.city1.length + items.state1.length + items.pcode1.length > 50;
        }
      });
      if (isValidForm) {
        request.VID = this.props.vid;
        billingDetails.forEach((item) => {
          if (item.rowNo === row.rowNo) {
            request.AID = item.aid;
            request.Street = item.street1;
            request.City = item.city1;
            request.State = item.state1;
            request.Pcode = item.pcode1;
            request.Status = item.status1;
            request.Department = item.department1;
            request.ActionType = "Update Address";
          }
        });
        if (isGreaterLen) {
          Utils.toastError(isLenMsg, { position: toast.POSITION.BOTTOM_RIGHT });
        } else {
          this.saveAddressForm(request, "editAddress", row);
        }
      }
    }
  };

  saveAddressForm = (request: any, type: any, row: any) => {
    if (type !== "changeStatus" && (request.Street === "" || request.City === "" || request.State === "" || request.Pcode === "" || request.Status === "")) {
      console.log("");
    } else {
      if (type === "addAddress") {
        this.updatingBillingAddress(type, request, row);
      } else {
        confirmAlert({
          title: "Alert!",
          message:
            "Updating a Vendor`s address will affect all previously generated checks. This new information will appear the next time you view or print the check. Are you sure you want to proceed?",
          buttons: [
            {
              label: "Yes",
              onClick: () => this.updatingBillingAddress(type, request, row),
            },
            {
              label: "No",
              onClick: () => {
                this.hideBillingAddress(type, row);
                reject();
              },
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    }
  };

  updatingBillingAddress = (type, request, row) => {
    let isType = row !== "" && row.status === "Active" ? "active" : "inactive";
    let { billingDetails } = this.state;
    this.setState({ isFormSubmit: true });
    VendorService.AddModifyVendorAddress(request)
      .then((result: any) => {
        let data = result?.result;
        if (result.success) {
          if (data[0].result === "VendorNotExists") {
            this.setState({ isFormSubmit: false }, () => {
              Utils.toastError("This vendor is no longer exists.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            });
          } else if (data[0].result !== "VendorNotExists") {
            let response: any = {};
            if (type === "addAddress") {
              response.rowNo = billingDetails.length + 1;
              response.street = request.Street.trim();
              response.aid = data[0].id;
              response.city = request.City.trim();
              response.state = request.State;
              response.pcode = request.Pcode.trim();
              response.status = request.Status;
              response.department = request.Department.trim();
              response.rowNo1 = "";
              response.street1 = "";
              response.aid1 = "";
              response.city1 = "";
              response.state1 = "";
              response.pcode1 = "";
              response.status1 = "";
              response.department1 = "";
              billingDetails.push(response);
              toast.success("New address added successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              setTimeout(() => {
                this.setState({ billingDetails, isFormSubmit: false }, () => {
                  this.hideBillingAddress("addAddress", "");
                  this.props.isReload(true);
                });
              }, 1000);
            } else if (
              isType === "active" &&
              data[0].result === "InvoiceExist"
            ) {
              Utils.toastError(
                "Vendor status cannot be made inactive until all pending invoices/checks have been cleared.",
                { position: toast.POSITION.BOTTOM_RIGHT }
              );
              this.setState({ isFormSubmit: false });
            } else if (
              isType === "active" &&
              data[0].result === "ActiveContract"
            ) {
              Utils.toastError(
                "This address is associated with an active contract. Please inactivate the contract first.",
                { position: toast.POSITION.BOTTOM_RIGHT }
              );
              this.setState({ isFormSubmit: false });
            } else if (
              isType === "active" &&
              data[0].result === "AddressUseInCard"
            ) {
              Utils.toastError(
                "Address cannot be inactivated as this vendor is associated with a credit card.",
                { position: toast.POSITION.BOTTOM_RIGHT }
              );
              this.setState({ isFormSubmit: false });
            } else {
              billingDetails = billingDetails.map((items) => {
                if (items.rowNo === row.rowNo) {
                  return {
                    ...items,
                    isEditModal: false,
                    rowNo: row.rowNo,
                    aid: request.AID,
                    street: request.Street.trim(),
                    city: request.City.trim(),
                    state: request.State.trim(),
                    pcode: request.Pcode.trim(),
                    status: request.Status.trim(),
                    department: request.Department.trim(),
                  };
                } else {
                  return items;
                }
              });
              billingDetails = _.sortBy(billingDetails, "status");
              setTimeout(() => {
                let message =
                  type === "changeStatus"
                    ? "Status updated successfully."
                    : "Address updated successfully.";
                toast.success(message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.setState(
                  (curr: any) => ({
                    ...curr,
                    billingDetails,
                    isFormSubmit: false,
                  }),
                  () => {
                    this.props.isReload(true);
                  }
                );
              }, 1000);
            }
          } else {
            this.setState({ isFormSubmit: false }, () => {
              Utils.toastError("Something went wrong.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            });
          }
        } else {
          this.setState({ isFormSubmit: false }, () => {
            Utils.toastError("Something went wrong.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isFormSubmit: false }, () => {
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
        reject();
      });
  };

  UpdateVendorDetails = (vendorArray, type) => {
    let { editVandor, vendorDetails, vendorError } = this.state;
    let isValidEmail = emailRegex;
    let postFrom = type;
    let request: any = {};
    request.VID = this.props.vid;
    request.Company =
      vendorArray[0].company.length < 3 || vendorArray[0].company.length > 50
        ? vendorArray[0].oldcompany
        : vendorArray[0].company.trim();
    if (vendorDetails[0]?.dachStatus?.toLowerCase() === "verified") {
      if (vendorError.billingEmail) {
        request.BillingEmail = oldBilling;
      } else {
        request.BillingEmail = isValidEmail.test(
          vendorArray[0].billingEmail.trim()
        )
          ? vendorArray[0].billingEmail.trim()
          : "";
      }
    } else {
      request.BillingEmail = isValidEmail.test(
        vendorArray[0].billingEmail.trim()
      )
        ? vendorArray[0].billingEmail.trim()
        : "";
    }
    request.Phone =
      vendorArray[0].phone.length < 10 || vendorArray[0].phone.length > 20
        ? ""
        : vendorArray[0].phone.trim();
    request.Service =
      vendorArray[0].services.length < 1 || vendorArray[0].services.length > 50
        ? ""
        : vendorArray[0].services.trim();
    request.IsTaxorSSN = vendorArray[0].isTaxOrSSN;
    let charLen = vendorArray[0].isTaxOrSSN === "TAX ID" ? 10 : 11;
    request.TaxOrSSNID =
      vendorArray[0].taxOrSsnId.length < charLen ||
        this.allCharactersSame(
          vendorArray[0].taxOrSsnId.toString().replace(/-/g, "")
        )
        ? ""
        : vendorArray[0].taxOrSsnId.trim();
    request.TaxType =
      vendorArray[0].taxType.length < 1 || vendorArray[0].taxType.length > 50
        ? ""
        : vendorArray[0].taxType.trim();
    request.PaymentMethod = vendorArray[0].paymentType;
    request.Vendor_1099 = vendorArray[0].vendor_1099;
    request.InvoiceDueDays =
      vendorArray[0].invoiceDue.length < 1 ||
        vendorArray[0].invoiceDue.length > 2
        ? ""
        : vendorArray[0].invoiceDue.trim();
    request.IsContractAgency =
      vendorArray[0]?.isContractAgency?.toLowerCase() === "yes" ? 1 : 0;
    request.SalesTax = vendorArray[0].salesTax;
    request.COA = vendorArray[0].coaList;
    request.GroupInvoice = vendorArray[0]?.groupInvoice;
    request.postingRule = vendorArray[0]?.postingRule?.trim();
    request.TableType = type === "coa" ? "coa" : "vendor";
    let isChanged = false;
    if (vendorDetails[0]?.dachStatus?.toLowerCase() === "verified") {
      if (vendorError.billingEmail) {
        isChanged = false;
      } else {
        if (oldBilling !== vendorArray[0]?.billingEmail) isChanged = true;
        else isChanged = false;
      }
    } else {
      isChanged = false;
    }
    request.BillingChange = isChanged;
    request.DACHStatus = vendorArray[0]?.dachStatus;
    if (postFrom === "company") {
      confirmAlert({
        title: "Alert!",
        message:
          "Updating a Vendor name will affect all previously generated checks. This new information will appear the next time you view or print the check. Are you sure you want to proceed?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.submitVandorDetails(request, postFrom),
          },
          {
            label: "No",
            onClick: () => {
              vendorDetails[0].company = editVandor.company;
              this.setState(() => ({ vendorDetails }));
              isChange = false;
              reject();
            },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      this.submitVandorDetails(request, postFrom);
    }
  };

  submitVandorDetails = (request, postFrom) => {
    this.setState({ isFormSubmit: true });
    let { editVandor, vendorDetails } = this.state;
    VendorService.UpdateVendorDetails(request)
      .then((result: any) => {
        if (result.success) {
          let data = result?.result;
          if (data.length > 0) {
            if (data[0]?.result.toString().toLowerCase() === "vendorexists") {
              Utils.toastError("This vendor already exist", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              vendorDetails[0].company = vendorDetails[0].oldcompany;
              isChange = false;
              setTimeout(() => {
                this.setState(() => ({ vendorDetails, isFormSubmit: false }));
              }, 1000);
            } else if (data[0]?.result?.toLowerCase() === "checkexist") {
              Utils.toastError(
                "Please clear the checks that have been generated but not printed for this vendor on the payments page.",
                { position: toast.POSITION.BOTTOM_RIGHT }
              );
              vendorDetails[0].groupInvoice = editVandor.groupInvoice;
              isChange = false;
              setTimeout(() => {
                this.setState(() => ({ vendorDetails, isFormSubmit: false }));
              }, 1000);
            } else if (
              data[0]?.result?.toLowerCase() === "activecontractuser"
            ) {
              Utils.toastError(
                "There is an active contractor type user assigned to this vendor.",
                { position: toast.POSITION.BOTTOM_RIGHT }
              );
              vendorDetails[0].isContractAgency = "Yes";
              isChange = false;
              setTimeout(() => {
                this.setState(() => ({ vendorDetails, isFormSubmit: false }));
              }, 1000);
            } else if (data[0]?.result?.toLowerCase() === "vendornotexists") {
              Utils.toastError("This vendor is no longer exists.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              isChange = false;
              setTimeout(() => {
                this.setState(() => ({ vendorDetails, isFormSubmit: false }));
              }, 1000);
            } else {
              let heading: any = {};
              if (postFrom === "company") {
                heading = "Name";
              } else if (postFrom === "billingEmail") {
                heading = "Email";
              } else if (postFrom === "phone") {
                heading = "Phone";
              } else if (postFrom === "services") {
                heading = "Services";
              } else if (postFrom === "taxType") {
                heading = "Tax Type";
              } else if (postFrom === "paymentType") {
                heading = "Payment Method";
              } else if (postFrom === "vendor_1099") {
                heading = "1099 Vendor";
              } else if (postFrom === "invoiceDue") {
                heading = "Invoices Due After";
              } else if (postFrom === "salesTax") {
                heading = "Sales Tax";
              } else if (postFrom === "coa") {
                heading = "COA";
              } else if (postFrom === "TAX ID") {
                heading = "TAX ID";
              } else if (postFrom === "taxOrSsnId") {
                heading = vendorDetails[0].isTaxOrSSN + " detail";
              } else if (postFrom === "SSN") {
                heading = "SSN";
              } else if (postFrom === "groupInvoice") {
                heading = "Group Invoice";
              } else if (postFrom === "ContractAgency") {
                heading = "Contract agency/vendor";
              } else if (postFrom === "PostingRule") {
                heading = "Posting rule";
              }
              vendorDetails.map((item) => {
                item["company"] = item.company.trim();
                item["billingEmail"] = item.billingEmail.trim();
                item["services"] = item.services.trim();
                item["phone"] = item.phone.trim();
                item["taxType"] = item.taxType.trim();
                item["taxOrSsnId"] = item.taxOrSsnId.trim();
              });
              toast.success(`${heading} updated successfully.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              isChange = false;
              isChangeCOA = false;
              if (
                vendorDetails[0]?.dachStatus?.toLowerCase() !== "verified" ||
                request.BillingChange
              ) {
                oldBilling = this.state.vendorDetails[0]?.billingEmail;
              }
              setTimeout(() => {
                this.setState(
                  () => ({ vendorDetails, isFormSubmit: false }),
                  () => {
                    this.props.isReload(true);
                  }
                );
              }, 1000);
            }
          }
        } else {
          this.setState({ isFormSubmit: false });
          isChange = false;
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
        console.log(result);
      })
      .catch((error) => {
        this.setState({ isFormSubmit: false });
        isChange = false;
        Utils.toastError("Something went wrong.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  showHideChangeLogModal = (value: any) => {
    this.setState({ showChangeLogModal: !this.state.showChangeLogModal });
    if (value === "View Past Changes") {
      this.setState({
        showChangeLogModal: true,
        showChangeHistoryLogModal: true,
      });
    }
  };

  showHideChangeHistoryLogModal = () => {
    this.setState({
      showChangeHistoryLogModal: !this.state.showChangeHistoryLogModal,
    });
  };

  render() {
    let { vendorDetails, billingDetails, vendorError } = this.state;
    let noEditPermission = this.props?.isEditVendor ? false : true;
    const rowItem = (item) => (
      <EllipsisWithTooltip placement="bottom">{item}</EllipsisWithTooltip>
    );
    // const isTooltop = (value) => (<div className="toolTipIn">{value}</div>)
    const salesTaxInfo =
      "Enable if sales tax is included on the invoice. Disable if sales tax has not been paid by the vendor, and if use tax is required to be paid on the items purchased.";
    const infoIcon = () => (
      <svg
        stroke="currentColor"
        fill="#6a6e73"
        stroke-width="0"
        version="1.1"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
      </svg>
    );

    const renderColStatus = (row: any) => {
      let isActive = (row.status.toString().toLowerCase() === "active" ? "green" : "grey");
      return (<span className={`badge color-${isActive}`}>{row.status}</span>)
    }

    const renderAddressRight = (row: any) => {
      let currentStatus = row.status.toString().toLowerCase() === "active" ? "Inactive" : "Active";
      let statusActive = row.status.toString().toLowerCase() === "active";
      return (
        <div className="generalCusAddEditModal">
          <Dropdown className="more-action" alignRight>
            <Dropdown.Toggle className={`${noEditPermission ? "disabled-area" : ""} btn-outline-primary btn btn-primary more`} id="dropdown-more">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            {row.isEditModal && (
              <OutsideClickHandler onOutsideClick={() => this.hideBillingAddress("editAddress", row)}>
                <div className="generalEditAddressModal">{this.billingModalContainer("editAddress", row)}</div>
              </OutsideClickHandler>
            )}
            {noEditPermission ? (
              <></>
            ) : (
              <Dropdown.Menu>
                <Dropdown.Item onClick={(e: any) => this.showBillingAddress("editAddress", row)}>Edit</Dropdown.Item>
                {statusActive ? (
                  <>
                    {row.activeContract > 0 ? (
                      <Dropdown.Item onClick={(e: any) => this.handleRowClick("notInactive", row)}>{currentStatus}</Dropdown.Item>
                    ) : (
                      <Dropdown.Item onClick={(e: any) => this.handleRowClick(currentStatus.toString().toLocaleLowerCase(), row)}>
                        {currentStatus}
                      </Dropdown.Item>
                    )}
                  </>
                ) : (
                  <Dropdown.Item onClick={(e: any) => this.handleRowClick(currentStatus.toString().toLocaleLowerCase(),row)}>{currentStatus}</Dropdown.Item>
                )}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
      );
    }

    const billingColumns = [
      {
        dataField: "street",
        text: "Street",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return rowItem(row.street);
        },
        headerClasses: "generalCol_Street",
        classes: "generalCol_Street",
      },
      {
        dataField: "city",
        text: "City",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return rowItem(row.city);
        },
        headerClasses: "generalCol_City",
        classes: "generalCol_City",
      },
      {
        dataField: "state",
        text: "State",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return rowItem(row.state);
        },
        headerClasses: "generalCol_State",
        classes: "generalCol_State",
      },
      {
        dataField: "pcode",
        text: "ZIP",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return rowItem(row.pcode);
        },
        headerClasses: "generalCol_ZIP",
        classes: "generalCol_ZIP",
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell: any, row: any, rowIndex: any) => renderColStatus(row),
        headerClasses: "generalCol_Status",
        classes: "generalCol_Status",
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => renderAddressRight(row),
        headerClasses: "generalCol_Dropdown",
        classes: "generalCol_Dropdown",
      },
    ];

    return (
      <>
        {this.state.isFormSubmit && (
          <div className="pageOverlaySpinner">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
        {this.state.showChangeLogModal && (
          <VendorHistoryLog
            payPeriodStartDate={""}
            payPeriodEndDate={""}
            accType={"vendorlog"}
            showChangeLogModal={this.state.showChangeLogModal}
            showHideChangeLogModal={this.showHideChangeLogModal}
            hotelName={""}
            hotelId={-1}
            hideCrossAndClearFilters={false}
            shiftId={this.props?.VendorDetails[0]?.company}
            oprID={""}
            shiftDate={""}
            noOfDays={1}
          />
        )}

        {this.state.showChangeHistoryLogModal && (
          <VendorHistoryLog
            payPeriodStartDate={""}
            payPeriodEndDate={""}
            showChangeLogModal={this.state.showChangeHistoryLogModal}
            showHideChangeLogModal={this.showHideChangeHistoryLogModal}
            hotelName={""}
            hotelId={this.state.hotelId}
            hideCrossAndClearFilters={false}
            shiftId={this.props?.vid}
            shiftDate={""}
            accType={"VendorHistorical"}
          />
        )}
        <div className="general-tab posRelative">
          <div className="header-popout contractModalShow">
            <Dropdown className="more-action" alignRight>
              <Dropdown.Toggle
                className="btn-outline-primary btn btn-primary more"
                id="dropdown-more"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={this.showHideChangeLogModal}>
                  Change Log
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="body-section">
            <div className="heading">General Information</div>
            {vendorDetails.length > 0 && (
              <div
                className={`${noEditPermission ? "disabled-area" : ""
                  } vendor-general form-section d-flex flex-column`}
              >
                <Form.Group
                  className="d-flex align-item-center"
                  controlId="nameSelection"
                >
                  {vendorError.company && (
                    <div className="pips VendorPipShow">
                      <div className="pip light-red"></div>
                    </div>
                  )}
                  <Form.Label
                    className={vendorError.company ? "hasTextError" : ""}
                  >
                    Name
                  </Form.Label>
                  <div className="d-flex flex-column">
                    <input
                      type="text"
                      className={
                        vendorError.company
                          ? "hasError form-control"
                          : "form-control"
                      }
                      id="company"
                      disabled={noEditPermission}
                      maxLength={50}
                      minLength={3}
                      value={vendorDetails[0]?.company}
                      onFocus={(e: any) =>
                        this.onVendorFieldFocus(e, "company")
                      }
                      onChange={(e: any) =>
                        this.onVendorFieldChange(e, "company")
                      }
                      onBlur={(e: any) => {
                        this.onVendorFieldBlur(e, "company");
                      }}
                    />
                    {vendorError.company && (
                      <div className="errorContainer hasTextError">
                        Minumum 3 character required.
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group
                  className="d-flex align-item-center"
                  controlId="emailSelection"
                >
                  {vendorError.billingEmail && (
                    <div className="pips VendorPipShow">
                      <div className="pip light-red"></div>
                    </div>
                  )}
                  <Form.Label
                    className={vendorError.billingEmail ? "hasTextError" : ""}
                  >
                    Billing Email
                  </Form.Label>
                  <div className="d-flex flex-column">
                    <input
                      type="text"
                      className={
                        vendorError.billingEmail
                          ? "hasError form-control"
                          : "form-control"
                      }
                      id="billingEmail"
                      disabled={noEditPermission}
                      maxLength={50}
                      value={vendorDetails[0]?.billingEmail}
                      onFocus={(e: any) =>
                        this.onVendorFieldFocus(e, "billingEmail")
                      }
                      onChange={(e: any) =>
                        this.onVendorFieldChange(e, "billingEmail")
                      }
                      onBlur={(e: any) =>
                        this.onVendorFieldBlur(e, "billingEmail")
                      }
                    />
                    {vendorError.billingEmail && (
                      <div className="errorContainer hasTextError">
                        {vendorDetails[0]?.billingEmail.length === 0
                          ? "Email Address is required"
                          : "Email Address is not valid"}
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group
                  className="d-flex align-item-center"
                  controlId="phoneSelection"
                >
                  {vendorError.phone && (
                    <div className="pips VendorPipShow">
                      <div className="pip light-red"></div>
                    </div>
                  )}
                  <Form.Label
                    className={vendorError.phone ? "hasTextError" : ""}
                  >
                    Phone Number
                  </Form.Label>
                  <div className="d-flex flex-column">
                    <input
                      type="text"
                      className={
                        vendorError.phone
                          ? "hasError form-control"
                          : "form-control"
                      }
                      id="phone"
                      disabled={noEditPermission}
                      minLength={10}
                      maxLength={20}
                      value={vendorDetails[0]?.phone}
                      onFocus={(e: any) => this.onVendorFieldFocus(e, "phone")}
                      onChange={(e: any) =>
                        this.onVendorFieldChange(e, "phone")
                      }
                      onBlur={(e: any) => this.onVendorFieldBlur(e, "phone")}
                    />
                    {vendorError.phone && (
                      <div className="errorContainer hasTextError">
                        Phone Number should be 10 to 20 digits.
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group
                  className="d-flex align-item-center"
                  controlId="TaxSSN"
                >
                  {vendorError.taxOrSsnId && (
                    <div className="pips VendorPipShow">
                      <div className="pip light-red"></div>
                    </div>
                  )}
                  <Form.Label>
                    <div className="generalDropdown">
                      <Dropdown className="LabelDropdown">
                        <Dropdown.Toggle
                          id="taxssn"
                          className={
                            vendorError.taxOrSsnId ? "hasTextError" : ""
                          }
                          disabled={!vendorDetails[0]?.sensitivePerm}
                        >
                          <div className="pos-rel">
                            <div className="d-flex align-items-center">
                              <div className="txt-area gTextArea">
                                {vendorDetails[0]?.isTaxOrSSN}
                              </div>
                              <div className="drop-arrow ml-0 d-flex">
                                <FiChevronDown />
                              </div>
                            </div>
                          </div>
                        </Dropdown.Toggle>
                        {noEditPermission ? (
                          <></>
                        ) : (
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e: any) =>
                                this.onVendorFieldChange("TAX ID", "isTaxOrSSN")
                              }
                            >
                              TAX ID
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e: any) =>
                                this.onVendorFieldChange("SSN", "isTaxOrSSN")
                              }
                            >
                              SSN
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        )}
                      </Dropdown>
                    </div>
                  </Form.Label>
                  <div className="d-flex flex-column">
                    <input
                      type="text"
                      className={
                        vendorError.taxOrSsnId
                          ? "hasError form-control"
                          : "form-control"
                      }
                      id="taxOrSsnId"
                      minLength={11}
                      maxLength={11}
                      value={
                        vendorDetails[0]?.taxOrSsnId?.includes("*")
                          ? vendorDetails[0]?.taxOrSsnId?.replaceAll("-", "")
                          : vendorDetails[0]?.taxOrSsnId
                      }
                      onFocus={(e: any) =>
                        this.onVendorFieldFocus(e, "taxOrSsnId")
                      }
                      onChange={(e: any) =>
                        this.onVendorFieldChange(e, "taxOrSsnId")
                      }
                      onBlur={(e: any) =>
                        this.onVendorFieldBlur(e, "taxOrSsnId")
                      }
                      disabled={!vendorDetails[0]?.sensitivePerm ? noEditPermission ? true : true : false}
                    />
                    {vendorError.taxOrSsnId && (
                      <div className="errorContainer hasTextError">
                        Minumum 9 non repeating digits required.
                      </div>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  className="d-flex align-item-center"
                  controlId="GroupInvoiceSelection"
                >
                  <Form.Label>Group Invoices by Default</Form.Label>
                  <Form.Check
                    type="switch"
                    id="group_invoice"
                    label=""
                    disabled={noEditPermission}
                    checked={
                      vendorDetails[0]?.groupInvoice?.toLowerCase() === "no"
                    }
                    onChange={(e: any) => {
                      this.onVendorFieldChange(e, "groupInvoice");
                    }}
                    onFocus={(e: any) =>
                      this.onVendorFieldFocus(e, "groupInvoice")
                    }
                  />
                </Form.Group>

                <Form.Group
                  className="d-flex align-item-center"
                  controlId="paymentSelection"
                >
                  <Form.Label>Payment Method</Form.Label>
                  <div className="d-flex flex-column">
                    <div className="generalDropdown generalKeyDrop w350 ">
                      <Dropdown className="itemDropdown">
                        <Dropdown.Toggle id="eType">
                          <div className="pos-rel">
                            <div className="d-flex align-items-center">
                              <div className="txt-area gTextArea mr-auto">
                                {vendorDetails[0]?.paymentType
                                  .toString()
                                  .toLocaleLowerCase() === "ach"
                                  ? "Manual ACH"
                                  : vendorDetails[0]?.paymentType}
                              </div>
                              <div className="drop-arrow ml-0 d-flex">
                                <FiChevronDown />
                              </div>
                            </div>
                          </div>
                        </Dropdown.Toggle>
                        {noEditPermission ? (
                          <></>
                        ) : (
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e: any) =>
                                this.onVendorFieldChange(
                                  "Manual ACH",
                                  "paymentType"
                                )
                              }
                            >
                              Manual ACH
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e: any) =>
                                this.onVendorFieldChange("Check", "paymentType")
                              }
                            >
                              Check
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e: any) =>
                                this.onVendorFieldChange(
                                  "Credit Card",
                                  "paymentType"
                                )
                              }
                            >
                              Credit Card
                            </Dropdown.Item>
                            {this.state.isEpayEnable && (
                              <Dropdown.Item
                                onClick={(e: any) =>
                                  this.onVendorFieldChange(
                                    "ePay",
                                    "paymentType"
                                  )
                                }
                              >
                                ePay
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        )}
                      </Dropdown>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group
                  className="d-flex align-item-center"
                  controlId="InvoicesDueSelection"
                >
                  {vendorError.invoiceDue && (
                    <div className="pips VendorPipShow">
                      <div className="pip light-red"></div>
                    </div>
                  )}
                  <Form.Label
                    className={vendorError.invoiceDue ? "hasTextError" : ""}
                  >
                    Invoices Due After
                  </Form.Label>
                  <div className="d-flex flex-column">
                    <input
                      type="text"
                      className={
                        vendorError.invoiceDue
                          ? "hasError form-control"
                          : "form-control"
                      }
                      id="invoiceDue"
                      disabled={noEditPermission}
                      maxLength={2}
                      value={vendorDetails[0]?.invoiceDue}
                      onFocus={(e: any) =>
                        this.onVendorFieldFocus(e, "invoiceDue")
                      }
                      onChange={(e: any) =>
                        this.onVendorFieldChange(e, "invoiceDue")
                      }
                      onBlur={(e: any) =>
                        this.onVendorFieldBlur(e, "invoiceDue")
                      }
                    />
                    {vendorError.invoiceDue && (
                      <div className="errorContainer hasTextError">
                        Minumum 1 character required.
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group
                  className="d-flex align-item-center"
                  controlId="vendor_1099Selection"
                >
                  <Form.Label>1099 Vendor</Form.Label>
                  <Form.Check
                    type="switch"
                    id="vendor_1099"
                    label=""
                    disabled={noEditPermission}
                    name="hasEnterpriseAccess"
                    checked={
                      vendorDetails[0]?.vendor_1099?.toLowerCase() === "yes"
                    }
                    onChange={(e: any) => {
                      this.onVendorFieldChange(e, "vendor_1099");
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="d-flex align-item-center"
                  controlId="salesTaxSelection"
                >
                  <Form.Label>
                    Sales Tax{" "}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="infoIcon">{salesTaxInfo}</Tooltip>}
                    >
                      {infoIcon()}
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    id="salesTax"
                    disabled={noEditPermission}
                    label=""
                    name="hasEnterpriseAccess"
                    checked={
                      vendorDetails[0]?.salesTax?.toLowerCase() === "yes"
                    }
                    onChange={(e: any) => {
                      this.onVendorFieldChange(e, "salesTax");
                    }}
                  />
                </Form.Group>
                {vendorDetails[0]?.contractFeature?.toLowerCase() === "yes" && (
                  <Form.Group
                    className="d-flex align-item-center"
                    controlId="contractAgencySelection"
                  >
                    <Form.Label>Contract Agency/Vendor</Form.Label>
                    <Form.Check
                      type="switch"
                      id="Agency"
                      disabled={noEditPermission}
                      label=""
                      checked={
                        vendorDetails[0]?.isContractAgency?.toLowerCase() ===
                        "yes"
                      }
                      onChange={(e: any) => {
                        this.onVendorFieldChange(e, "ContractAgency");
                      }}
                    />
                  </Form.Group>
                )}
                <Form.Group
                  className="d-flex align-item-center"
                  controlId="coaSelection"
                >
                  <Form.Label>Chart of Accounts</Form.Label>
                  <div className="generalmultiSelectDropdown">
                    <OutsideClickHandler
                      onOutsideClick={() => this.handleMultiSelectClose(false)}
                    >
                      <MultiSelectSearch
                        ref={this.multiselectChild}
                        itemList={this.state.COAList}
                        updateMultiSelectItem={this.updateMultiSelectItem.bind(
                          this
                        )}
                        dataChange={this.dataChange.bind(this)}
                        isSelectAll={false}
                        filtertitle={"Filter COAs"}
                        pageName="VendorGeneral"
                        isConfiCOAPerm={this.state.isConfiCOAPerm}
                        isDisable={noEditPermission}
                      ></MultiSelectSearch>
                    </OutsideClickHandler>
                  </div>
                </Form.Group>
                <Form.Group
                  className="d-flex align-item-center"
                  controlId="servicesSelection"
                >
                  <Form.Label>Services</Form.Label>
                  <div className="d-flex flex-column">
                    <div className="generalTooltip">
                      <input
                        type="text"
                        className={
                          vendorError.services
                            ? "hasError form-control"
                            : "form-control"
                        }
                        id="services"
                        maxLength={50}
                        disabled={noEditPermission}
                        value={vendorDetails[0]?.services}
                        onFocus={(e: any) =>
                          this.onVendorFieldFocus(e, "services")
                        }
                        onChange={(e: any) =>
                          this.onVendorFieldChange(e, "services")
                        }
                        onBlur={(e: any) =>
                          this.onVendorFieldBlur(e, "services")
                        }
                      />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group
                  className="d-flex align-item-center"
                  controlId="servicesSelection"
                >
                  <Form.Label>Posting Rules</Form.Label>
                  <div className="d-flex flex-column">
                    <div className="generalTooltip">
                      <Form.Control
                        value={vendorDetails[0]?.postingRule}
                        as="textarea"
                        disabled={noEditPermission}
                        className="VendorPostRuleArear"
                        maxLength={1000}
                        onChange={(e: any) =>
                          this.onVendorFieldChange(e, "PostingRule")
                        }
                        onBlur={(e: any) =>
                          this.onVendorFieldBlur(e, "PostingRule")
                        }
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            )}
          </div>
          <div className="separator-line"></div>
          <div className="body-section">
            <div className="heading">Billing Address</div>
            {billingDetails.length > 0 && (
              <>
                <ToolkitProvider
                  keyField="rowNo"
                  data={billingDetails}
                  columns={billingColumns}
                >
                  {(props: {
                    searchProps: JSX.IntrinsicAttributes;
                    baseProps: JSX.IntrinsicAttributes;
                  }) => (
                    <div
                      className={`${noEditPermission ? "disabled-area" : ""
                        } generalTable`}
                    >
                      <BootstrapTable {...props.baseProps} hover />
                    </div>
                  )}
                </ToolkitProvider>
                <div className="generalCusAddEditModal mt-2">
                  {this.state.isbillingModal && (
                    <OutsideClickHandler
                      onOutsideClick={() =>
                        this.hideBillingAddress("addAddress", "")
                      }
                    >
                      <div className="generalAddAddressModal">
                        {this.billingModalContainer("addAddress", "")}
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
                {noEditPermission ? (
                  <></>
                ) : (
                  <div className="generalCusButton">
                    <span
                      className="isbtnspan"
                      onClick={() => this.showBillingAddress("addAddress", "")}
                    >
                      Add Address
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
