import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModuleReports } from "../../Common/Services/ModuleReports";

export class MultiTabls extends React.Component<any, any> {


  private summarySubTypes = [
    { label: "Multiple Tabs", value: "1" },
    { label: "Single Tab", value: "0" },

  ];


  constructor(props: any) {
    super(props);

    this.state = {
      selectedItem: { label: "Multiple Tabs", value: "1" },
      summarySubTypes: this.summarySubTypes,
    };
  }

  componentDidUpdate = (prevprops, prevState) => {
    const { defaultValue } = this.props;
    if (((prevprops.defaultValue !== defaultValue)) && defaultValue?.length > 0) {
      let arr = this.state.summarySubTypes.filter(e => e.value === defaultValue);
      this.setState({ selectedItem: arr.length > 0 ? arr[0] : { label: "Multiple Tabs", value: "1" } });
    }
  }

  componentDidMount = () => {

    const { defaultValue } = this.props;
    if (defaultValue === undefined || defaultValue === "1") {
      this.handleDropdownChange(this.state.selectedItem);
    } else {
      this.handleDropdownChange({ label: "Single Tab", value: defaultValue });
    }
  };


  handleDropdownChange = (item: any) => {

    this.setState({ selectedItem: item });
    //this.props?.handleCOAType(item.value);
    this.props?.handleMultiTabls(item.value);
  };



  render() {

    return (
      <Dropdown className="single-select">
        <Dropdown.Toggle id="Modal-empType">
          <span
            style={{
              width: "95%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {this.state.selectedItem.label}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.summarySubTypes.map((item: any, idx: any) => (

              <Dropdown.Item onClick={() => this.handleDropdownChange(item)}>
                {item.label}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

