import React from "react";
import {
  Form,
  Dropdown,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal
} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { RoleSubPermissionSetting } from "../../../Common/Services/RoleSubPermissionSetting";
import _ from "lodash";
import "../../../Modules/Accounting/vendor.scss";
import { FiChevronDown } from "react-icons/fi";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { Register as registersevice } from "../../../Common/Services/Register";
import { resolve, reject } from "q";
import { isTemplateSpan } from "typescript";
import { Utils } from "../../../Common/Utilis";

let isValidInput: any = "";
let isShowGeneric: boolean = false;
let selectedRow: any = {};
let currentTabIndex: any = 0;
let MaxTabIndex: any = 0;
let MaxContractTabIndex: any = 0;
export class VendorSearchUM extends React.Component<any, any> {
  private inputSearch: any;
  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();
    this.state = {
      hotelID: props.HID,
      loader: false,
      entryType: props.entryType,
      pageName: props.pageName,
      selectedVendor: "",
      oldselectedVendor: "",
      vendorForCreate: "",
      selectedContract: "",
      oldSelectedContract: "",
      vendorList: [],
      mainVendorList: [],
      vendorAddressList: [],
      isListOpen: false,
      isContractDetailsOpen: false,
      contractDetails: [],
      vendorforDetails: "",
      vendorType: "",
      vendorStatus: "app",
      isNewRequest: false,
      isNewVendor: false,
      isNewContract: false,
      isAddressSelect: false,
      isNewAddress: false,
      isGeneric: false,
      isFoundCurrent: false,
      isFoundOther: false,
      isAddVendorPerm: false,
      isAddVendorAddressPerm: false,
      isAddContractPerm: false,
      isCreateVendorWithApp: false,
      isGenericVendorPerm: false,
      btnName: "",
      // Inputs Control States
      newVendorName: "",
      vendorCheck: false,
      salesTaxCheck: false,
      paymentMethod: "Check",
      invoicesDuesAfter: "0",
      address: "Select Address",
      addressID: 0,
      city: "",
      states: "",
      zip: "",
      customerNo: "",
      taxIDPrefix: "TAX ID",
      taxIdSuffix: "",
      phone: "",
      email: "",
      lastVid: 0,
      // End Here
      lastVType: "",
      isAddVendorBtnVisible: false,
      isMatchingMsgVisible: false,
      addVendorFooterVisible: false,
      callFromPage: props.callFromPage,
      selectedContractRowID: 0,
      isGenericSelect: false,
      cursor: -1,
      cursorDetails: 1,
      tabEnableVendor: 1,
      postingRule: "",
      editedVid: 0,
      AddNewPostingRule: false,
      rules: "",
      newRuleError: false,
      LastUpdatedBy: "",
      LastUpdatedTime: "",
      selectedVID: 0,
      isEditInvoiceRules: false,
      disabled: props?.disabled,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  getVendorPermission = async () => {
    let requestObject = {} as any;
    requestObject.permissionID = 3; //For Invoice
    requestObject.Module = "Accounting";
    requestObject.Type = "page";

    let requestObject1 = {} as any;
    requestObject1.permissionID = 4; //For Other Pages
    requestObject1.Module = "Accounting";
    requestObject1.Type = "page";

    try {
      const response = await Promise.all([
        RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject),
        RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject1),
      ]);
      if (response !== null) {
        let finalResponse = response[0].concat(response[1]); //Merge Both response in one array
        if (finalResponse.length > 0) {
          for (let i = 0; i < finalResponse.length; i++) {
            if (
              (finalResponse[i].permissionName.toString().toLocaleLowerCase() === "Create Vendor".toLocaleLowerCase() ||
                finalResponse[i].permissionName.toString().toLocaleLowerCase() === "add invoice".toLocaleLowerCase()) &&
              this.state.pageName === "InvoiceEntry" && (finalResponse[i].permissionID === 2011 || finalResponse[i].permissionID === 2010)
            ) {
              this.setState({ isAddVendorPerm: true }); //This permission is for Create Vendor
            }
            if (
              finalResponse[i].permissionName.toString().toLocaleLowerCase() === "create vendor" && this.state.pageName !== "InvoiceEntry"
              && finalResponse[i].permissionID === 2027) {
              this.setState({ isAddVendorPerm: true }); //This permission is for Add Address
            }
            if (
              finalResponse[i].permissionName.toString().toLocaleLowerCase() === "add address") {
              this.setState({ isAddVendorAddressPerm: true }); //This permission is for Add Address
            }
            this.setState({ isAddContractPerm: true });
            // if (finalResponse[i].displayName === "Add Contract") {
            //   this.setState({ isAddContractPerm: true }); //This permission is for Add Contract
            // }
            // if (finalResponse[i].permissionName.toString().toLocaleLowerCase() === "create new vendor approval") {
            //   this.setState({ isCreateVendorWithApp: true }); //This permission is for Add Contract
            // }
            if (finalResponse[i].permissionName.toString().toLocaleLowerCase() === "access to generic vendor") {
              this.setState({ isGenericVendorPerm: true }); //This permission is for Add Contract
            }
            if (finalResponse[i].permissionName.toString().toLocaleLowerCase() === "edit invoice rules") {
              this.setState({ isEditInvoiceRules: true });
            }
          }
        }
      }
    } catch (error) {
      Utils.toastError((error as any)?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  componentDidMount() {
    this.GetVendorTranlist();
    //document.addEventListener("keydown", this.escFunction, false); //Close dropdown on pressing esc and tab
    document.addEventListener("onKeyPress", this.taxIDCheck, false);
  }

  componentWillUnmount() {
    //document.removeEventListener("keydown", this.escFunction, false);
    document.addEventListener("onKeyPress", this.taxIDCheck, false);

  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      //esc key press
      this.setState({
        isListOpen: false,
        addVendorFooterVisible: false,
        isNewVendor: false,
        isNewContract: false,
        isNewRequest: false,
      });
    }
  };

  importFromCCSI = (vendorname, vid, type) => {
    this.addNewContract(vendorname, vid, type, this.state.hotelID);
  };
  importFromJournal = (vendorname, vid, type) => {
    this.setState({ hotelID: this.props.HID });
    this.importFromCCSI(vendorname, vid, type);
  };

  GetVendorTranlist = () => {

    let vendorContractRequest: any = {};
    vendorContractRequest.hotelID = this.state.hotelID;
    vendorContractRequest.vendorType = "Current";
    registersevice
      .GetVendorlistUEM(vendorContractRequest) //Vendor list service method
      .then(async (result: any | null) => {
        if (result !== null) {
          result.sort((a, b) => a.vendorType.toString().toLowerCase() !== b.vendorType.toString().toLowerCase() ? a.vendorType.toString().toLowerCase() < b.vendorType.toString().toLowerCase() ? -1 : 1 : 0);//_.sortBy(result, "status");

          result=result?.filter(x=> +x.ctCount>0);
          this.updateParentState(result);

          if(this.props?.vendorId>0) {
             let item = result?.find(x=>x.vid ===this.props?.vendorId);
             item = !item ? {vid:0} : item;
             this.handleSelectVendor(item);
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };


  updateParentState = (result: any) => {
    this.setState(
      {
        vendorList: [],
        mainVendorList: [],
      },
      () => {
        this.setState(
          {
            vendorList: result,
            mainVendorList: result,
            loader: false,
          },
          () => {
           // this.props.updateVendorList(result);
          }
        );
      }
    );
  };



  handleClick = () => {
    selectedRow = {};
    MaxTabIndex = 0;
    if (this.state.selectedContract !== "" || this.state.isGenericSelect) {
      $("#inputSearch").select();
    }
    if (this.state.selectedVendor?.trim().length > 2) {
      this.setState(
        {
          cursor: -1,
          cursorDetails: 1,
          isListOpen: true,
          addVendorFooterVisible: true,
          isNewVendor: false,
          isNewRequest: false,
          isNewContract: false,
          isContractDetailsOpen: false,
        },
        () => {
          this.filterFromVendorList(this.state.selectedVendor?.trim(), "click"); //Calling this fun for filtering the Vendor List
        }
      );
    }
  };

  filterFromVendorList = (name: any, callFrom) => {
    selectedRow = {};
    MaxTabIndex = 0;
    this.setState({
      selectedVendor: name.toString(),
      vendorForCreate: name.toString()?.trim(),
      addVendorFooterVisible: true,
      isGenericSelect: name.toString().toLowerCase() === "generic vendor" ? true : false,
      cursor: -1,
      cursorDetails: 1,
    });
    let newResult: any = [];
    if (name?.trim().length > 2) {
      let vendorDataFilter: any[] = [];
      vendorDataFilter = _.cloneDeep(this.state.mainVendorList);

      //Check If Generic vendor is ceated or not for this tenant
      isShowGeneric = _.some(this.state.mainVendorList, function (el) {
        return el?.vendorName?.toString()?.toLowerCase() === "generic vendor";
      });

      //This will remove generic vendor from Vendor List
      vendorDataFilter = _.reject(vendorDataFilter, (o) =>
        _.includes(o?.vendorName?.toString().toLowerCase(), "generic vendor")
      );

      //_.reject(vendorDataFilter, {vendorName:"Generic Vendor"});

      newResult = _.filter(vendorDataFilter, (o) =>
        _.includes(
          o?.vendorName?.toString()?.toLowerCase(),
          name?.trim()?.toString()?.toLowerCase()
        )
      );

      newResult.sort((a, b) => a?.vendorType?.toString()?.toLowerCase() !== b?.vendorType?.toString()?.toLowerCase() ? a?.vendorType?.toString()?.toLowerCase() < b?.vendorType?.toString()?.toLowerCase() ? -1 : 1 : 0);//_.sortBy(result, "status");

      newResult?.map(
        (item, index) => (
          (item["id"] = index)
        )
      );

      //Check if any current EHID data present in result set
      const isCurrentFound = newResult.some(
        (item) => item.vendorType.toString().toLowerCase() === "current"
      );

      //Check if any Other EHID data present in result set
      const isOtherFound = newResult.some(
        (item) => item.vendorType.toString().toLowerCase() === "other"
      );

      if (callFrom === "dropdown") {
        const arrayResult: any = [];
        if(typeof  this.props["handleVendorChange"] ==="function") {
        this.props.handleVendorChange(arrayResult);
        }
      }
      MaxTabIndex = newResult.length === 0 ? 1 : 0;
      this.setState({
        vendorList: newResult,
        isFoundCurrent: isCurrentFound,
        isFoundOther: isOtherFound,
      });
      if (newResult.length > 0) {
        this.setState({
          isListOpen: true,
          isMatchingMsgVisible: false,
          isAddVendorBtnVisible: false,
        });
      } else {
        this.setState({
          isListOpen: false,
          isMatchingMsgVisible: true,
          isAddVendorBtnVisible: true,
        });
      }
    } else {
      this.setState({
        isListOpen: false,
        isMatchingMsgVisible: false,
        isAddVendorBtnVisible: false,
      });
    }
    if (name?.trim().length === 0) {
      if (callFrom === "dropdown") {
        const arrayResult: any = [];
        if(typeof  this.props["handleVendorChange"] ==="function") {
          this.props.handleVendorChange(arrayResult);
          }
      }
      this.setState({
        isListOpen: false,
        isMatchingMsgVisible: false,
      });
    }
    // if (name?.trim().length > 0 && newResult.length <= 0) {
    //   this.setState({ isAddVendorBtnVisible: true });
    // } else {
    //   this.setState({ isAddVendorBtnVisible: false });
    // }
  };

  //Handle change when entering the value in vendor search box
  handleChange = (event, eventFrom) => {
    debugger;
    this.setState({ selectedContract: "", oldSelectedContract: "" });
    if (!this.state.isNewRequest)
      this.filterFromVendorList(event.target.value, eventFrom); //Calling this fun to filter Vendor List
  };

  //Close vendor control on outside click
  handleOutsideClick = (e) => {
    if (!this.state.loader && !this.state.isNewRequest)
      this.clearFieldValuesFromOutsideClick();
    this.setState({ isListOpen: false, addVendorFooterVisible: false });
  };

  clearFieldValuesFromOutsideClick = () => {
    this.setState({
      vendorCheck: false,
      invoicesDuesAfter: "0",
      salesTaxCheck: false,
      address: "Select Address",
      addressID: 0,
      city: "",
      states: "",
      zip: "",
      customerNo: "",
      taxIDPrefix: "TAX ID",
      taxIdSuffix: "",
      phone: "",
      email: "",
      isContractDetailsOpen: false,
      isListOpen: true,
      addVendorFooterVisible: true,
      isNewVendor: false,
      isNewContract: false,
      isNewRequest: false,
      vendorForCreate: this.state.selectedVendor,
    });
  };

 
  //Fun to open a form for creating a New Contract for existing Vendor

  addNewContract = (vendorName, vid, vendorType, hotelID) => {
    let vendorAddressReq: any = {};
    vendorAddressReq.hotelID = hotelID;// this.state.hotelID;
    vendorAddressReq.vendorType = vendorType;
    vendorAddressReq.VendorId = vid;
    this.clearFieldValues();
    this.setState(
      {
        hotelID: hotelID,
        btnName: "Add Contract",
        loader: true,
        isNewContract: true,
        isNewVendor: false,
        isListOpen: false,
        addVendorFooterVisible: false,
        isNewRequest: true,
        isAddressSelect: false,
        address: "Select Address",
        isContractDetailsOpen: false,
        newVendorName: vendorName,
        vendorForCreate: vendorName?.trim(),
        isNewAddress: false,
        lastVid: vid,
        lastVType: vendorType,
        vendorType: vendorType,
        vendorAddressList: [],
        paymentMethod: "Check",
        vendorCheck: false,
        salesTaxCheck: false,
        invoicesDuesAfter: "0",
      },
      () => {
        registersevice //Service method for getting the address and some basic details of the selected vendor
          .GetVendorAddress(vendorAddressReq)
          .then(async (result: any | null) => {
            if (result !== null) {
              this.setState({
                loader: false,
                vendorAddressList: result,
                paymentMethod: result[0].paymentType.toString().toLowerCase() === "ACH" ? "Manual ACH" : result[0].paymentType,
                vendorCheck:
                  result[0].isVendor1099?.toLowerCase() === "yes"
                    ? true
                    : false,
                salesTaxCheck:
                  result[0].salesTax?.toLowerCase() === "yes" ? true : false,
                invoicesDuesAfter:
                  result[0].invoicesDuesAfter === ""
                    ? "0"
                    : result[0].invoicesDuesAfter,
                taxIDPrefix: result[0].isTaxOrSSN,
                taxIdSuffix: result[0].taxOrSsnId,
              });
            } else {
              this.setState({ loader: false });
            }
            resolve();
          })
          .catch((error) => {
            this.setState({ loader: false });
            reject();
          });
        $("#streetaddressDrop").focus();
      }
    );
  };

  // contractDetails = async (vendorName, vid, vendorType, approval) => {
  //   this.setState({ loader: true });
  //   let vendorContractRequest: any = {};
  //   vendorContractRequest.hotelID = this.state.hotelID;
  //   vendorContractRequest.vendorType = vendorType;
  //   vendorContractRequest.VendorId = vid;
  //   let contractDetail: any[] = [];

  //   registersevice //Service method for getting the contract details of the selected vendor
  //     .GetVendorContract(vendorContractRequest)
  //     .then(async (result: any | null) => {
  //       if (result !== null) {
  //         for (let i = 0; i < result.length; i++) {
  //           let dta: any = {};
  //           dta.vendorName = vendorName;
  //           dta.rowID = result[i].rowID;
  //           dta.vid = vid;
  //           dta.accountNo = result[i].accountNo;
  //           dta.street = result[i].street;
  //           dta.city = result[i].city;
  //           dta.state = result[i].state;
  //           dta.pCode = result[i].pCode;
  //           dta.aid = result[i].aid;
  //           dta.salestax = result[i].salesTax;
  //           dta.paymentType = result[i].paymentType;
  //           dta.vendorDays = result[i].vendorDays;
  //           //dta.ctCount= "2";
  //           contractDetail.push(dta);
  //         }
  //         const selectedCustID = result.filter(
  //           (nis) =>
  //             nis.accountNo?.toString().toLowerCase() === this.state.selectedContract.replace('(', '').replace(')', '').trim()
  //         );
  //         MaxContractTabIndex = result.length;
  //         contractDetail.map(
  //           (item, index) => (
  //             (item["id"] = index)
  //           )
  //         );
  //         this.setState({
  //           selectedContractRowID: this.state.selectedContract === "" ? 0 : selectedCustID[0]?.rowID,
  //           vendorType: vendorType,
  //           contractDetails: contractDetail,
  //           isContractDetailsOpen: true,
  //           isListOpen: false,
  //           addVendorFooterVisible: false,
  //           vendorforDetails: vendorName,
  //           lastVid: vid,
  //           lastVType: vendorType,
  //           vendorStatus: approval,
  //           loader: false,
  //         }, () => {
  //           setTimeout(() => {
  //             $('[tabindex=' + 1 + ']').focus();
  //           }, 0);
  //         });
  //       }
  //       resolve();
  //     })
  //     .catch((error) => {
  //       this.setState({ loader: false });
  //       reject();
  //     });
  // };

  clearFieldValues = () => {
    this.setState({
      vendorCheck: false,
      invoicesDuesAfter: "0",
      salesTaxCheck: false,
      address: "Select Address",
      addressID: 0,
      city: "",
      states: "",
      zip: "",
      customerNo: "",
      taxIDPrefix: "TAX ID",
      taxIdSuffix: "",
      phone: "",
      email: "",
      isContractDetailsOpen: false,
      isListOpen: true,
      addVendorFooterVisible: true,
      isNewVendor: false,
      isNewContract: false,
      isNewRequest: false,
      vendorForCreate: this.state.selectedVendor,
      cursor: -1,
      cursorDetails: 1
    }, () => {
      $('#SlideBody').scrollTop(0);
      this.moveCursorToEnd();
      selectedRow = {};
    });
  };

  moveCursorToEnd = () => {
    let el: any = document.getElementById('inputSearch');
    if (el !== null && el !== undefined) {
      el.focus();
      setTimeout(() => {
        if (typeof el.selectionStart == "number") {
          el.selectionStart = el.selectionEnd = el.value.length;
        } else if (typeof el.createTextRange != "undefined") {
          var range = el.createTextRange();
          range.collapse(false);
          range.select();
        }

      }, 0);
    }
  }

  handleSelectVendor = (item: any) => {
    this.setState(
      {
        selectedVendor: item.vendorName,
        vendorForCreate: item.vendorName?.trim(),
        selectedContract:
          item.vendorName?.trim(),
        selectedContractRowID: item.rowID,
        oldSelectedContract: item.accountNo,
        isListOpen: false,
        addVendorFooterVisible: false,
        isContractDetailsOpen: false,
        isAddVendorBtnVisible: false,
        isMatchingMsgVisible: false,
        selectedVID: item.vid
      },
      () => {
        if(typeof  this.props["handleVendorChange"] ==="function") {
          this.props.handleVendorChange(item);
          } //Calling the parent change method
      }
    );
  };

  handleNotApprove = () => {
    // Nothing to do in this fun
  };

  handleAddNewAddress = (event: any) => {
    this.setState({
      isAddressSelect: true,
      isNewAddress: true,
      address: "",
      phone: "",
      email: "",
      addressID: 0,
      customerNo: "",
      //taxIDPrefix: "TAX ID",
      //taxIdSuffix: "",
    }, () => {
      $("#streetaddressText").focus();
      $("#customerno").removeClass("alert-danger");
    });
  };

  //Old Method
  // onClickVendor = (items: any) => {
  //   this.setState({ postingRule: items.postingRule, selectedVID: items.vid,LastUpdatedBy: items.postingRuleBy,LastUpdatedTime:items.postingRuleDate, });
  //   if (this.state.pageName === "TransactionPage" || this.state.pageName === "CreditCardEntry") {
  //     if (items.ctCount > 1) {
  //       this.contractDetails(
  //         items.vendorName,
  //         items.vid,
  //         items.vendorType,
  //         items.approval
  //       );
  //     } else if (items.vendorType?.toString().toLowerCase() === "other") {
  //       this.addNewContract(
  //         items.vendorName,
  //         items.vid,
  //         items.vendorType,
  //         this.state.hotelID
  //       );
  //     } else {
  //       this.handleSelectVendor(items);
  //     }
  //   } else if (this.state.pageName === "InvoiceEntry") {
  //     if (items.ctCount > 1) {
  //       this.contractDetails(
  //         items.vendorName,
  //         items.vid,
  //         items.vendorType,
  //         items.approval
  //       );
  //     } else if (items.vendorType?.toString().toLowerCase() === "other") {
  //       if (items.approval?.toString().toLowerCase() === "app" && this.state.isAddContractPerm) {
  //         this.addNewContract(
  //           items.vendorName,
  //           items.vid,
  //           items.vendorType,
  //           this.state.hotelID
  //         );
  //       } else {
  //         this.handleNotApprove();
  //       }
  //     } else {
  //       if (items.approval?.toString().toLowerCase() === "app") {
  //         this.handleSelectVendor(items);
  //       }
  //     }
  //   }
  // }
  onClickVendor = (items: any) => {
    this.setState({ postingRule: items.postingRule, selectedVID: items.vid, LastUpdatedBy: items.postingRuleBy, LastUpdatedTime: items.postingRuleDate, });
 
  }

  onClickContractDetailsList = (item: any) => {
    this.state.pageName === "TransactionPage" &&
      this.state.vendorStatus?.toLowerCase() ===
      "no"
      ? this.handleNotApprove()
      : this.state.vendorType?.toLowerCase() ===
        "other"
        ?
        this.state.isAddContractPerm ?
          this.addNewContract(
            item.vendorName,
            item.vid,
            this.state.vendorType,
            this.state.hotelID
          )
          : this.handleNotApprove()
        : this.handleSelectVendor(item);
  }

  handleKeyDownArrow = (e, items, action) => {
    const { cursor, vendorList } = this.state;
    if (e.keyCode === 38 && cursor > 0 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor - 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          console.log("tabindex", tabindex)
          if (Number(currentTabIndex) === 1) {
            $('.vendorListFocusSet').focus();
          } else {
            currentTabIndex = (Number(currentTabIndex) - 1)
            $('[tabindex=' + currentTabIndex + ']').focus();
          }

        }, 0);
      })

    } if (e.keyCode === 38 && cursor === 0 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        selectedRow = {};
        MaxTabIndex = 0;
        setTimeout(() => {
          this.handleClick();
          $('#SlideBody').scrollTop(0);
          $('#inputSearch').focus();
        }, 0);
      })

    } else if (e.keyCode === 40 && cursor < vendorList.length - 1 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor + 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          currentTabIndex = tabindex;
          if (Number(MaxTabIndex) === this.state.cursor) {
            $('.vendorListFocusSet').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      });

    } else if (e.keyCode === 40 && cursor === vendorList.length - 1 && this.state.isListOpen && action === "vendorRow") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          currentTabIndex = tabindex;
          $('[tabindex=' + tabindex + ']').focus();
        }, 0);
      });

    } else if (e.keyCode === 40 && cursor === vendorList.length && this.state.isListOpen && action === "GenericSelect") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');

          currentTabIndex = MaxTabIndex;
          if (Number(MaxTabIndex) === this.state.cursor) {
            $('.vendorListFocusSet').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      });

    } else if (e.keyCode === 40 && this.state.isMatchingMsgVisible && action === "GenericSelect") {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');

          currentTabIndex = MaxTabIndex;
          if (Number(MaxTabIndex) === this.state.cursor) {
            $('.vendorListFocusSet').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      });

    } else if (e.keyCode === 38 && cursor === vendorList.length && this.state.isListOpen && action === "GenericSelect") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor - 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.vendorListFocusSet').attr('tabindex');
          if (Number(currentTabIndex) === 1) {
            $('.vendorListFocusSet').focus();
          } else {
            currentTabIndex = (Number(currentTabIndex) - 1)
            $('[tabindex=' + currentTabIndex + ']').focus();
          }

        }, 0);
      })

    } else if (e.keyCode === 38 && (this.state.isListOpen || this.state.isMatchingMsgVisible) && action === "AddVendorBtn") {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor - 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          currentTabIndex = (Number(MaxTabIndex))
          $('[tabindex=' + currentTabIndex + ']').focus();
        }, 0);
      })

    } else if (e.keyCode === 38 && !this.state.isListOpen && this.state.isMatchingMsgVisible && action === "GenericSelect") {

      selectedRow = {};
      MaxTabIndex = 0;
      setTimeout(() => {
        this.handleClick();
        //this.setState({cursor:-1});
        $('#inputSearch').focus();
      }, 0);


    } else if (e.keyCode === 39 && cursor > -1 && this.state.isListOpen && action === "vendorRow") { // for right arrow
      if (this.state.pageName === "InvoiceEntry") {
        if (selectedRow?.vendorType.toLowerCase() === "current") {
          if (selectedRow?.ctCount > 1)
            this.onClickVendor(selectedRow)
          else
            this.addNewContract(
              selectedRow?.vendorName,
              selectedRow?.vid,
              selectedRow?.vendorType,
              this.state.hotelID
            );
        }
      } else {
        if (selectedRow?.vendorType.toLowerCase() === "current" && selectedRow.approval?.toString().toLowerCase() !== "no") {
          if (selectedRow?.ctCount > 1)
            this.onClickVendor(selectedRow)
          else
            this.addNewContract(
              selectedRow?.vendorName,
              selectedRow?.vid,
              selectedRow?.vendorType,
              this.state.hotelID
            );
        }
        else if (selectedRow.approval?.toString().toLowerCase() !== "no") {
          this.addNewContract(
            selectedRow?.vendorName,
            selectedRow?.vid,
            selectedRow?.vendorType,
            this.state.hotelID
          );
        }
      }
      //Old Code// if (selectedRow?.vendorType.toLowerCase() === "current" && selectedRow.approval?.toString().toLowerCase() !== "no") {
      //   if (selectedRow?.ctCount > 1)
      //     this.onClickVendor(selectedRow)
      //   else
      //     this.addNewContract(
      //       selectedRow?.vendorName,
      //       selectedRow?.vid,
      //       selectedRow?.vendorType,
      //       this.state.hotelID
      //     );
      // }
      // else if (selectedRow.approval?.toString().toLowerCase() !== "no") {
      //   this.addNewContract(
      //     selectedRow?.vendorName,
      //     selectedRow?.vid,
      //     selectedRow?.vendorType,
      //     this.state.hotelID
      //   );
      // }
    } else if (e.keyCode === 37 && cursor < vendorList.length - 1 && (this.state.isContractDetailsOpen || this.state.isNewRequest) && action === "vendorRow") {// for left arrow
      this.clearFieldValues();
    } else if (e.keyCode === 13 && this.state.isListOpen && action === "vendorRow") {// for Enter Key
      //if (selectedRow.approval?.toString().toLowerCase() !== "no")
      this.onClickVendor(selectedRow);
    } else if (e.keyCode === 13 && this.state.isListOpen && action === "GenericSelect") {// for Enter Key
      this.genericSelectClick();
    } else if (e.keyCode === 9) {// For disable tab
      e.preventDefault()
    } else if (e.keyCode === 13 && !this.state.isListOpen && action === "GenericSelect") {// for Enter Key
      this.genericSelectClick();
    } else if (e.keyCode === 13 && (this.state.isListOpen || this.state.isMatchingMsgVisible) && action === "AddVendorBtn") {// for Enter Key
    
    } else if (e.keyCode === 13 && cursor < vendorList.length - 1 && this.state.isListOpen && action === "GenericSelect") {
      this.genericSelectClick();
    }
  }

  handleKeyDown = (e) => {
    const { cursor, vendorList } = this.state;
    if (e.keyCode === 40 && vendorList.length === 0 && this.state.isMatchingMsgVisible) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 2
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor + 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          var tabindex: any = $('.GenericVendor').attr('tabindex');
          if (tabindex === undefined) {
            $('[tabindex=' + MaxTabIndex + ']').focus();
          } else {
            currentTabIndex = tabindex;
            $('[tabindex=' + tabindex + ']').focus();
          }
        }, 0);
      })

    } if (e.keyCode === 40 && cursor < vendorList.length - 1 && this.state.isListOpen) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }), () => {
        let selRow = vendorList.filter((item) => item.id === cursor + 1);
        selectedRow = {};
        selectedRow = (selRow[0]);
        setTimeout(() => {
          $('.vendorListFocusSet').focus();
        }, 0);
      });
    }
    else if (e.keyCode === 9) {// for Disable Tab Key
      e.preventDefault();
    }
  }

  handleContractDetailBox = (e, action, item) => {
    const { cursorDetails, contractDetailList } = this.state;
    if (e.keyCode === 37 && this.state.isContractDetailsOpen) { // for left arrow click
      this.clearFieldValues();
    } if (e.keyCode === 13 && this.state.isContractDetailsOpen && action === "back") {// for Enter Key
      this.clearFieldValues();
    } if (e.keyCode === 38 && this.state.isContractDetailsOpen && action === "back") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          $('[tabindex=' + 1 + ']').focus();
        }, 0);
      });
    } if (e.keyCode === 40 && this.state.isContractDetailsOpen && action === "back") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails + 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          $('[tabindex=' + tabindex + ']').focus();
        }, 0);
      });
    } if (e.keyCode === 40 && this.state.isContractDetailsOpen && action === "contractList") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails + 1
      }), () => {
        selectedRow = {};
        selectedRow = item;
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          $('[tabindex=' + tabindex + ']').focus();
        }, 0);
      });
    } if (e.keyCode === 38 && this.state.isContractDetailsOpen && action === "contractList") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails - 1
      }), () => {
        selectedRow = {};
        selectedRow = item;
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          if (tabindex === undefined) {
            $('[tabindex=' + 1 + ']').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }

        }, 0);
      });
    } if (e.keyCode === 38 && this.state.isContractDetailsOpen && action === "AddContract") {
      this.setState(prevState => ({
        cursorDetails: prevState.cursorDetails - 1
      }), () => {
        selectedRow = {};
        setTimeout(() => {
          var tabindex: any = $('.contractListFocusSet').attr('tabindex');
          if (tabindex === undefined) {
            $('[tabindex=' + 1 + ']').focus();
          } else {
            $('[tabindex=' + tabindex + ']').focus();
          }

        }, 0);
      });
    } if (e.keyCode === 13 && this.state.isContractDetailsOpen && action === "contractList") {// for Enter Key
      this.onClickContractDetailsList(item);
    } if (e.keyCode === 13 && this.state.isContractDetailsOpen && action === "AddContract") {// for Enter Key
      this.addNewContract(
        this.state.vendorforDetails,
        this.state.lastVid,
        this.state.vendorType,
        this.state.hotelID
      );
    } if (e.keyCode === 9) {// for Disable Tab Key
      e.preventDefault();
    }
  }

  handleKeyTabForm = (e, action) => { 
    if (e.keyCode === 27 && this.state.isNewRequest) { // for Left Arrow
      this.clearFieldValues();
    }
    if (e.keyCode === 9 && this.state.isNewRequest) { // for Tab pressing
      setTimeout(() => {
        if (action === "vendorname")
          $('[tabindex=' + 2 + ']').focus();
        if (action === "vendorCheck")
          $('[tabindex=' + 3 + ']').focus();
        if (action === "paymentMethod")
          $('[tabindex=' + 4 + ']').focus();
        if (action === "invoices")
          $('[tabindex=' + 5 + ']').focus();
        if (action === "salesTaxCheck")
          $('[tabindex=' + 6 + ']').focus();
        if (action === "taxIDPrefix")
          $('[tabindex=' + 7 + ']').focus();
        if (action === "taxIdSuffix")
          $('[tabindex=' + 8 + ']').focus();
        if (action === "addressNew")
          $('[tabindex=' + 9 + ']').focus();
        if (action === "city")
          $('[tabindex=' + 10 + ']').focus();
        if (action === "states")
          $('[tabindex=' + 11 + ']').focus();
        if (action === "zip")
          $('[tabindex=' + 12 + ']').focus();
        if (action === "customerno")
          $('[tabindex=' + 13 + ']').focus();
        if (action === "phone")
          $('[tabindex=' + 14 + ']').focus();
        if (action === "email")
          $('[tabindex=' + 15 + ']').focus();
        if (action === "cancel")
          $('[tabindex=' + 16 + ']').focus();
        if (action === "saveBtn")
          if (this.state.isNewContract)
            if (this.state.isNewAddress)
              $('[tabindex=' + 8 + ']').focus();
            else
              $('[tabindex=' + 6 + ']').focus();
          // if (this.state.isNewAddress)
          //   setTimeout(() => {
          //     $('[tabindex=' + 6 + ']').focus();
          //   }, 0);
          // else
          //   setTimeout(() => {
          //     $('[tabindex=' + 7 + ']').focus();
          //   }, 0);
          else
            $('[tabindex=' + 1 + ']').focus();
      }, 0);
    }
  }

  //Fun for rendring the vendor list for current and Other Ehid's
  renderVendorList = (items: any, index: any, venDorType: any) => {
    //listRowIndex = listRowIndex + 1;
    let rNo = Number(items.id);
    MaxTabIndex = rNo + 1;
    if (
      items.vendorType?.toString().toLowerCase() ===
      venDorType?.toString().toLowerCase()
    ) {
      return (
        <div
          className={this.state.cursor === items.id ? 'activeVendor currentHIDRepeat vendorListItem vendorListFocusSet' : "vendorListItem currentHIDRepeat"}
          tabIndex={rNo}
    
        >
          <Row
            className={
              this.state.pageName === "TransactionPage" &&
                items.approval?.toString().toLowerCase() === "no"
                ? "disabledRow VendorListRow"
                : "VendorListRow"
            }
          >
            <Col sm="11">
              <div
                className={items.approval?.toString().toLowerCase() === "no" && items.vendorName.length > 27 ? "selectableDiv showBadge" : "selectableDiv"}
                onClick={(e) => { this.handleSelectVendor(items) }}
              >
                <div className="d-flex">
                  {items.vendorName === this.state.selectedVendor &&
                    items.vendorType?.toString().toLowerCase() ===
                    "current" && (
                      <span className="VendorItemCheck">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                            fill="#2CAF92"
                          />
                        </svg>
                      </span>
                    )}
                  <span className="vendorName">{items.vendorName}</span>
                  {items.approval?.toString().toLowerCase() === "no" ? (
                    <span className="red-badges"> Unapproved</span>
                  ) : null}
                </div>

                {/* {items.vendorType?.toString().toLowerCase() === "current" ? (
                  <span className="contracts">
                    {items.ctCount > 1
                      ? items.ctCount + " Contracts"
                      : items.accountNo}
                  </span>
                ) : (
                  <span className="contracts">{items.contractdetails}</span>
                )} */}
              </div>
            </Col>
            {/* <Col sm="1">{this.renderAddContractorDetailsBtn(items)}</Col> */}
          </Row>
        </div>
      );
    }
  };





  genericSelectClick = () => {
    const genericVendorResult = this.state.mainVendorList.filter(
      (nis) =>
        nis.vendorName?.toString().toLowerCase() === "generic vendor" &&
        nis.vendorType?.toString().toLowerCase() === "current"
    );

    //if (genericVendorResult.length > 0)
    if (
      //genericVendorResult[0]?.accountNo !== "0" &&
      genericVendorResult[0]?.accountNo !== "" &&
      genericVendorResult[0] !== undefined
    ) {
      this.setState({ isGenericSelect: true }, () => {
        this.handleSelectVendor(genericVendorResult[0]);
      });
    } else {
      this.setState(
        {
          lastVid:
            genericVendorResult[0]?.vid === undefined
              ? 0
              : genericVendorResult[0]?.vid,
          addressID: 0,
          vendorForCreate:
            genericVendorResult[0]?.vendorName === undefined
              ? ""
              : genericVendorResult[0]?.vendorName,
          address: "",
          city: "",
          states: "",
          zip: "",
          phone: "",
          paymentMethod: "",
          salesTaxCheck: "",
          vendorCheck: "",
          taxIdSuffix: "",
          taxIDPrefix: "",
          invoicesDuesAfter: "",
          customerNo: "",
          email: "",
          isGeneric: true,
        },
        () => {
        }
      );
    }
  };

  showModal = () => {
    if (this.state.isEditInvoiceRules)
      this.setState({ AddNewPostingRule: true, rules: this.state.postingRule, newRuleError: false });
  }
  hideModal = () => {
    this.setState({ AddNewPostingRule: false, });
  }
  handleRules = (event) => {
    let { value } = event.target;
    this.setState({ rules: value, newRuleError: false })
    this.setState({ rules: value, newRuleError: false })
  }
  saveInvoiceRule = () => {
    if (this.state.rules.trim() === "") {
      this.setState({ newRuleError: true });
      Utils.toastError("Please enter rules.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.setState({ loader: true });
      let formData: any = {};
      formData.Vid = this.state.selectedVID;
      formData.Description = this.state.rules.substring(0, 1000).trim();
      registersevice
        .SaveInvoiceRule(formData)
        .then(async (result: any | null) => {
          if (result !== null) {
            if (result.message?.toLowerCase() === "success") {
              this.setState({ postingRule: result.result.description, loader: false, LastUpdatedBy: result?.result.createdBy, LastUpdatedTime: result?.result.createdDateTime }, () => {
                this.hideModal();
                this.state.vendorList.find((o) => o.vid === this.state.selectedVID).postingRule = result.result.description;
                this.state.vendorList.find((o) => o.vid === this.state.selectedVID).postingRuleDate = result?.result.createdDateTime;
                this.state.vendorList.find((o) => o.vid === this.state.selectedVID).postingRuleBy = result.result.createdBy;
              });
              toast.success("Invoice rule saved Successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else {
              Utils.toastError(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loader: false });
          reject();
        });
    }
  }

  componentWillReceiveProps(nextProps: { disabled: any }) {
    this.setState({ disabled: nextProps.disabled });
  }

  renderVendorSearchBox = () => {
    if ((this.state.selectedVendor?.length) > 20 && !this.state.isListOpen && !this.state.addVendorFooterVisible)
      return (<OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 200 }}
        overlay={
          <Tooltip id={"ddd"}>
            {this.state.selectedVendor}
          </Tooltip>
        }>
        <input
          ref={this.inputSearch}
          id="inputSearch"
          key={"text-Filter"}
          type="text"
          className="form-control searchVendor VendorDisabled"
          onChange={(e) => {
            this.handleChange(e, "dropdown");
          }}
          onClick={this.handleClick}
          disabled={this.state.disabled ? true : this.state.isNewRequest}
          placeholder="Enter 3 characters to search."
          //title={this.state.selectedVendor.length > 20 ? this.state.selectedVendor + this.state.selectedContract : ""}
          autoComplete="Off"
          value={
            this.state.selectedVendor
          }
          onKeyDown={this.handleKeyDown}
        />
      </OverlayTrigger>)
    else
      return (<input
        ref={this.inputSearch}
        id="inputSearch"
        key={"text-Filter"}
        type="text"
        className="form-control searchVendor VendorDisabled"
        onChange={(e) => {
          this.handleChange(e, "dropdown");
        }}
        onClick={this.handleClick}
        disabled={this.state.disabled ? true : this.state.isNewRequest}
        placeholder="Enter 3 characters to search."
        autoComplete="Off"
        value={
          this.state.selectedVendor 
        }
        onKeyDown={this.handleKeyDown}
      />)
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loader && (
          <div className="matrixSpinnerCenter">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}

        <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
          <div className="vendorSearch">
            {this.state.callFromPage === "CreditCardStatementImport" ? (
              ""
            ) : (
              <>
                <div className="SearchInput d-flex">
                  {this.renderVendorSearchBox()}
                  {this.state.selectedContract !== "" && this.state.pageName === "InvoiceEntry" && this.state.postingRule !== "" && this.state.postingRule !== undefined && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="postingRule">{this.state.selectedContract}</Tooltip>}
                    >
                      <svg
                        stroke="currentColor"
                        fill="#6a6e73"
                        stroke-width="0"
                        version="1.1"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        className="postingToolTip"
                        onClick={this.showModal}
                      >
                        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                      </svg>
                    </OverlayTrigger>
                  )}{this.state.selectedContract !== "" && this.state.pageName === "InvoiceEntry" && (this.state.postingRule === "" || this.state.postingRule === undefined) && (
                    <svg
                      stroke="currentColor"
                      fill="#6a6e73"
                      stroke-width="0"
                      version="1.1"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      className="postingToolTip"
                      onClick={this.showModal}
                    >
                      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                    </svg>
                  )}
                </div>

                {this.state.isListOpen && this.state.vendorList.length > 0 && (
                  <React.Fragment>
                    <div className="VendorList" id="VendorList">
                      <div className="scrollList VendorListScroll" id="VendorListScrol">
                        {/* {this.state.isFoundCurrent && (
                          <div className="borderDiv" id="borderDiv">
                            <div className="CurrentEHID" id="CurrentEHID">
                              <p>EHID (Current)</p>

                              {this.state.vendorList.map((items, index) =>
                                this.renderVendorList(items, index, "Current")
                              )}
                            </div>
                          </div>
                        )} */}
                        {this.state.isFoundOther && (
                          <div className="borderDiv">
                            <div className="CurrentEHID">
                              {/* <p>Other EHIDs</p> */}
                              {this.state.vendorList.map((items, index) =>
                                this.renderVendorList(items, index, "Other")
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )}

                {/* isMatchingMsgVisible */}

                {this.state.addVendorFooterVisible && (
                  <div className="VendorList">
                    {this.state.isMatchingMsgVisible && (
                      <div className="scrollList">
                        <div className="borderDiv">
                          <div className="CurrentEHID">
                            <div className="VendorListLoading">
                              No matching vendors exist.
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
             {this.state.callFromPage === "CreditCardStatementImport" ? (
              ""
            ) : (
              <>
                {this.state.isContractDetailsOpen && (
                  <div className="VendorList">
                    <div className="ContractDetailHeader">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 320 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={this.clearFieldValues}
                        className={this.state.cursorDetails === 1 ? 'activeVendor VendorDetailBackButton contractListFocusSet' : "VendorDetailBackButton"}
                        tabIndex={1}
                      //  onKeyDown={(e) => { this.handleContractDetailBox(e, 'back', '') }}
                      >
                        <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
                      </svg>{" "}
                      <span className="vendorDetailTitle">
                        {this.state.vendorforDetails}
                      </span>
                      {this.state.vendorStatus?.toLowerCase() === "no" ? (
                        <span className="red-badges"> Unapproved</span>
                      ) : null}
                    </div>
                    <div className="scrollList ContractScrollList">
                      <div className="borderDiv">
                        <div className="CurrentEHID">
                          {this.state.contractDetails.map((item, index) => (
                            <div
                              className={this.state.cursorDetails === (item.rowID) + 1 ? 'activeVendor currentHIDRepeat ListContract contractListFocusSet' : "currentHIDRepeat ListContract"}
                              tabIndex={Number(item.rowID) + 1}
                            //  onKeyDown={(e) => { this.handleContractDetailBox(e, 'contractList', item) }}
                            >
                              <div
                                className="selectableDiv"
                                onClick={(e) => { this.handleSelectVendor(item) }}
                              >
                                <div className="d-flex">
                                  {item.accountNo ===
                                    this.state.oldSelectedContract &&
                                    this.state.vendorType
                                      ?.toString()
                                      .toLowerCase() === "current" && this.state.selectedContractRowID === item.rowID && (
                                      <span className="VendorItemCheck">
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                            fill="#2CAF92"
                                          />
                                        </svg>
                                      </span>
                                    )}
                                  <span className="vendorName">
                                    {item.accountNo}
                                  </span>
                                </div>
                                <span className="contractsDetails">
                                  {item.street}, {item.city}
                                </span>
                                <span className="contractsDetails">
                                  {item.state} {item.pCode}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                   
                  </div>
                )}
              </>
            )} 
          </div>
        </OutsideClickHandler>
  
      </React.Fragment>
    );
  }

  //Validate fields value format correct or not
  validateInputsFormat = (controlID: any) => {
    if (
      controlID === "vendorname" &&
      this.state.vendorForCreate?.trim() !== ""
    ) {
      if (this.state.vendorForCreate?.trim().length < 3) {
        Utils.toastError("Vendor name should be atleast 3 characters.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        $("#vendorname").addClass("alert-danger");
      } else {
        $("#vendorname").removeClass("alert-danger");
      }
    }

    if (controlID === "EmailControl" && this.state.email?.trim() !== "") {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!emailRegex.test(this.state.email?.toLowerCase()?.trim())) {
        $("#email").addClass("alert-danger");
        // Utils.toastError("E-Mail format is not correct.", {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
      } else {
        $("#email").removeClass("alert-danger");
      }
    }
    if (controlID === "PhoneControl" && this.state.phone?.trim() !== "") {
      if (
        this.state.phone?.trim().length < 7 ||
        this.state.phone?.trim().length > 20
      ) {
        // Utils.toastError("Mobile Number must be between 7 to 20 digits.", {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
        $("#phone").addClass("alert-danger");
      } else {
        $("#phone").removeClass("alert-danger");
      }
    }
    if (controlID === "taxIdSuffix" && this.state.taxIdSuffix?.trim() !== "") {
      if (
        this.state.taxIdSuffix?.trim().replace("-", "").length > 9 ||
        this.state.taxIdSuffix?.trim().replace("-", "").length < 9
        //this.state.taxIdSuffix?.trim().replace("-", "").length > 20
      ) {
        $("#taxIdSuffix").addClass("alert-danger");
        Utils.toastError(
          this.state.taxIDPrefix + " must be 9 digits.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      } else if (this.allCharactersSame(this.state.taxIdSuffix?.trim())) {
        Utils.toastError(
          this.state.taxIDPrefix + " cannot be the same digit repeating.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        $("#taxIdSuffix").addClass("alert-danger");
      } else {
        $("#taxIdSuffix").removeClass("alert-danger");
      }
    }
  };

  //Check if all characters are not same in taxid/ssn
  allCharactersSame = (s): boolean => {
    let n = s.length;
    for (let i = 1; i < n; i++) if (s[i] != s[0]) return false;

    return true;
  };

  //Allow entering -(dash) in input field
  taxIDCheck = (event) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (
      charCode != 46 &&
      charCode != 45 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    )
      isValidInput = "No";
    else isValidInput = "Yes";
  };

  //Set input values to the states when entering by the user
  onInputChange = (e: any, type: any) => {
    if (type === "vendorname") {
      $("#vendorname").removeClass("alert-danger");
      if (this.state.isNewAddress)
        this.setState({ vendorForCreate: e.currentTarget.value });
    } else if (type === "vendorcheck") {
      this.setState({ vendorCheck: e.currentTarget.checked });
    } else if (type === "invoices") {
      let val = e.currentTarget.value.replace(/[@\/\\^\/\\&\/\\#,+()$~.%'":*?<>{}-]/g, '').substring(0, 2).trim();
      if (!isNaN(val)) {
        this.setState({ invoicesDuesAfter: val });
      }
      $("#invoices").removeClass("alert-danger");
    } else if (type === "salescheck") {
      this.setState({ salesTaxCheck: e.currentTarget.checked });
    } else if (type === "address") {
      $("#streetaddressText").removeClass("alert-danger");
      this.setState({ address: e.currentTarget.value });
    } else if (type === "city") {
      $("#city").removeClass("alert-danger");
      this.setState({ city: e.currentTarget.value });
    } else if (type === "state") {
      $("#state").removeClass("alert-danger");
      this.setState({ states: e.currentTarget.value });
    } else if (type === "zip") {
      $("#zip").removeClass("alert-danger");
      this.setState({ zip: e.currentTarget.value });
    } else if (type === "customerno") {
      $("#customerno").removeClass("alert-danger");
      this.setState({ customerNo: e.currentTarget.value });
    } else if (type === "taxIdSuffix") {
      if (isValidInput === "Yes") {
        $("#taxIdSuffix").removeClass("alert-danger");
        this.setState({ taxIdSuffix: e.currentTarget.value });
      }
    } else if (type === "phone") {
      // let val = e.currentTarget.value.replace(/[@\/\\^\/\\&\/\\#,+()$~%.'":*?<>{}-]/g, '');
      let val = e.currentTarget.value.replace(/[^0-9]/g, '');
      //if (!isNaN(val)) {
      if (this.state.isNewAddress) {
        this.setState({ phone: val });
      }
      //}
    } else if (type === "email") {
      if (this.state.isNewAddress)
        this.setState({ email: e.currentTarget.value });
    }
  };

  //Filter the selected address from vendor all contract address list
  handleSelectAddress = (event: any, item: any) => {
    $("#streetaddressDrop").removeClass("alert-danger");
    let addressResult = [...this.state.vendorAddressList];
    let newResult = _.filter(addressResult, { adddressID: item.adddressID });

    this.setState({
      address: item.address,
      isAddressSelect: true,
      addressID: item.adddressID,
      phone: newResult[0].phoneNo,
      email: newResult[0].email,
      //taxIDPrefix: newResult[0].isTaxOrSSN,
      //taxIdSuffix: newResult[0].taxOrSsnId,
    }, () => {
      $("#streetaddressDrop").blur();
      setTimeout(() => {
        $("#customerno").focus();
      }, 0);
    });
  };

  // Set dropdown value on selecting
  onDropDownClick = (value: any, type: any) => {
    if (type === "payMethod") {
      this.setState({ paymentMethod: value });
    } else if (type === "taxIDPrefix") {
      this.setState({ taxIDPrefix: value });
    }
  };

  //Disabled the fields on creating a new contract
  isDisabled = (controlId: any): boolean => {
    if (this.state.isNewContract /*&& !this.state.isNewAddress*/) {
      if (
        controlId === "VendorNameControl" /*&&
        this.state.vendorForCreate?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "PaymentMethod" /*&&
        this.state.paymentMethod?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "PhoneControl" &&
        // !this.state.isNewAddress &&
        // this.state.phone?.trim() !== ""
        this.state.isNewContract &&
        !this.state.isNewAddress
        /*|| this.state.phone?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "EmailControl" &&
        !this.state.isNewAddress &&
        this.state.isNewContract
        /*|| this.state.email?.trim() !== ""*/
      ) {
        return true;
      } else if (
        controlId === "invoices" /*&& this.state.invoicesDuesAfter !== ""*/
      ) {
        return true;
      } else if (
        controlId ===
        "taxIdSuffix" /*&& !this.state.isNewAddress this.state.taxIdSuffix !== ""*/
      ) {
        return true;
      } else if (
        controlId ===
        "taxIDPrefix" /*&& !this.state.isNewAddress this.state.taxIDPrefix !== ""*/
      ) {
        return true;
      } else if (controlId === "salesTaxCheck") {
        return true;
      } else if (controlId === "vendorCheck") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //Validate all input values before save
  checkValidation = () => {
    let isValid: any = [];
    let focusSet = false;
    if (this.state.vendorForCreate?.trim() === "") {
      $("#vendorname").focus();
      $("#vendorname").addClass("alert-danger");
      focusSet = true;
      isValid.push(false);
    } else {
      $("#vendorname").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if (this.state.invoicesDuesAfter?.trim() === "") {
      if (!focusSet) {
        $("#invoices").focus();
        focusSet = true;
      }
      $("#invoices").addClass("alert-danger");
      isValid.push(false);
    } else {
      focusSet = focusSet ? true : false;
      $("#invoices").blur();
      isValid.push(true);
    }
    if (this.state.taxIdSuffix?.trim() === "") {
      $("#taxIdSuffix").addClass("alert-danger");
      isValid.push(false);
      if (!focusSet) {
        $("#taxIdSuffix").focus();
        focusSet = true;
      }
    } else {
      $("#taxIdSuffix").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }
    if (
      this.state.address?.trim() === "" ||
      this.state.address?.trim() === "Select Address"
    ) {
      if (this.state.isNewAddress) {
        $("#streetaddressText").addClass("alert-danger");
        isValid.push(false);
        if (!focusSet) {
          $("#streetaddressText").focus();
          focusSet = true;
        }
      } else {
        $("#streetaddressDrop").addClass("alert-danger");
        isValid.push(false);
        if (!focusSet) {
          $("#streetaddressDrop").focus();
          focusSet = true;
        }
      }
    } else {
      if (this.state.isNewAddress) {
        $("#streetaddressText").blur();
        focusSet = focusSet ? true : false;
        isValid.push(true);
      } else {
        $("#streetaddressDrop").blur();
        focusSet = focusSet ? true : false;
        isValid.push(true);
      }
    }

    if (this.state.city?.trim() === "" && this.state.isNewAddress) {
      if (!focusSet) {
        $("#city").focus();
        focusSet = true;
      }
      $("#city").addClass("alert-danger");
      isValid.push(false);
    } else {
      $("#city").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if (this.state.states?.trim() === "" && this.state.isNewAddress) {
      $("#state").addClass("alert-danger");
      isValid.push(false);
      if (!focusSet) {
        $("#state").focus();
        focusSet = true;
      }
    } else {
      $("#state").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }
    if (this.state.zip?.trim() === "" && this.state.isNewAddress) {
      $("#zip").addClass("alert-danger");
      isValid.push(false);
      if (!focusSet) {
        $("#zip").focus();
        focusSet = true;
      }
    } else {
      $("#zip").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }
    if (this.state.customerNo?.trim() === "") {
      $("#customerno").addClass("alert-danger");
      isValid.push(false);
      if (!focusSet) {
        $("#customerno").focus();
        focusSet = true;
      }
    } else {
      $("#customerno").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }

    const returnValue = isValid.some((item) => item === false);
    if (!returnValue) {
      if (
        $("#vendorname").hasClass("alert-danger") &&
        this.state.vendorForCreate !== ""
      ) {
        return "vendorName";
      } else {
        $("#vendorname").removeClass("alert-danger");
      }
      if (
        $("#taxIdSuffix").hasClass("alert-danger") &&
        this.state.taxIdSuffix !== ""
      ) {
        return "taxIdSuffix";
      } else {
        $("#taxIdSuffix").removeClass("alert-danger");
      }
      if ($("#phone").hasClass("alert-danger") && this.state.phone !== "") {
        return "phone";
      } else {
        $("#phone").removeClass("alert-danger");
      }
      if ($("#email").hasClass("alert-danger") && this.state.email !== "") {
        return "email";
      } else {
        $("#email").removeClass("alert-danger");
      }
    }
    return returnValue ? "Required" : "Succeed";
  };

  checkTotalLength = () => {
    let cityLength = this.state.city?.substring(0, 50).trim().length;
    let stateLength = this.state.states?.substring(0, 50).trim().length;
    let zipLength = this.state.zip?.substring(0, 50).trim().length;
    let totalLength = (Number(cityLength) + Number(stateLength) + Number(zipLength));
    if (totalLength > 50) {
      return 'Exceed';
    } else {
      return 'NotExceed';
    }
  }

 
}
