import * as React from "react";
import { Dropdown } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import profile from "../Assets/Images/profile.png";

export class TipsEmployee extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      row: {},
      employeeModel: "Select Employee",
      filterData: [],
      hotelList: [],
      inputValue: "",
      employeeList: [],
      tableData: [],
      filterEmployeeList: [],
    };
    this.handleChangeEmployee = this.handleChangeEmployee.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);
  }

  static getDerivedStateFromProps(props: any, state: any) {
    return {
      employeeList: props.employeeList,
      row: props.row,
      tableData: props.tableData,
    };
  }

  componentDidMount() {
    let row = this.state.row;
    let filterEmployeeList1: any = [];
    let filtertableData: any = [];
    let filtertableData1: any = [];
    filtertableData = this.state.tableData;
    filtertableData1 = filtertableData.filter((item) => item.date === row.date);
    filterEmployeeList1 = this.state.employeeList;

    filterEmployeeList1.forEach((res: any) => {
      let isExistEmployee = filtertableData1.filter(
        (item: any) => item.username.toLowerCase() === res.employee.toLowerCase()
      );

      if (isExistEmployee.length === 0) {
        this.state.filterEmployeeList.push(res);
      }
    });

    this.setState({ filterData: this.state.filterEmployeeList });
  }

  handleChangeEmployee(e: any) {
    const updatedList = this.state.filterEmployeeList.filter((item) => {
      return (
        item.employee.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  handleDropdownChange(item) {
    this.setState({ inputValue: "" });
    this.props.employeeSelected(item, this.state.row);
  }

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.filterEmployeeList });
  };

  render() {
    const hidSelectModal = (eventKey: any) => {
      this.setState({ inputValue: "" });
      this.setState({ employeeModel: eventKey });

      //this.props.beforeSaveCell("","",this.props.row,{ dataField:"username"});
    };

    const beforeSaveCell = (eventKey: any) => {
      // this.setState({ inputValue: "" });
      // this.setState({ employeeModel: eventKey });
      //this.props.beforeSaveCell("","",this.props.row,{ dataField:"username"});
    };

    return (
      // this.state.employeeList.length > 0 && (
      <OutsideClickHandler onOutsideClick={beforeSaveCell}>
        <Dropdown
          className="single-select-image"
          onSelect={hidSelectModal}
          show
        >
          <Dropdown.Toggle className="" id="Modal-hid">
            {this.state.employeeModel === "Select EHID" && (
              <span className="placeholder">{this.state.employeeModel}</span>
            )}
            {this.state.employeeModel !== "Select EHID" && (
              <EllipsisWithTooltip placement="bottom">
                <span
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                  }}
                >
                  {this.state.employeeModel}
                </span>
              </EllipsisWithTooltip>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu show>
            <div className="search-sec">
              <input
                type="text"
                className="form-control"
                onChange={this.handleChangeEmployee}
                placeholder="Search "
                value={this.state.inputValue}
                ref={this.inputElement}
              />
            </div>
            <div className="list-area">
              <div className="list-section">
                <div className="sec-ttl">All Users</div>
                {this.state.filterData.map((item: any, idx: any) => (
                  <Dropdown.Item
                    className={
                      item.isconflict.toLowerCase() === "incomplete"
                        ? "disabled"
                        : ""
                    }
                    eventKey={item.employee}
                    onClick={() => this.handleDropdownChange(item)}
                  >
                    <div className="d-flex">
                      <img
                       src={
                        item.imagePath === null ||
                        item.imagePath.trim() === ""
                          ? profile
                          : item.imagePath
                      }
                        // src={item.imagePath}
                        className="profileimg"
                        width="36"
                        height="36"
                        alt=""
                      />

                      <div>
                        <EllipsisWithTooltip placement="bottom">
                          <div className="name">{item.employee}</div>
                        </EllipsisWithTooltip>
                        <EllipsisWithTooltip placement="bottom">
                          <div className="pos-name">{item.positionName}</div>
                        </EllipsisWithTooltip>
                      </div>
                      {item.isconflict.toLowerCase() === "incomplete" && (
                        <div className="red-badges">Incomplete</div>
                      )}
                    </div>
                  </Dropdown.Item>
                ))}
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </OutsideClickHandler>
      // )
    );
  }
}
