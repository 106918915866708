import React from "react";
import { Button, Col, Form, Modal,OverlayTrigger, Tooltip  } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import Joi from "joi-browser";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../../Common/StaticArrays";
import _ from "lodash";



export class AddPayrollAddress extends React.Component<any, any> {
  private payrollAddressFormSchema = Joi.object({
    street: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          if (error?.message) {
            return;
          }
          return { message: "Please enter Street" }
        });
      }),
    city: Joi.string().regex(/^[A-Za-z\s]*$/,"street")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error?.type) {
            case "string.regex.name":
              return { message: "City is not Valid" };
           default:
            return { message: "Please enter City" };
          }
         
        });
      }),
    state: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select State" };
        });
      }),
    zip: Joi.string().regex(/^[0-9]*$/,"zip")
    .required().min(5).max(5)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "any.empty":
              return {
                message:
                  "Please enter Zip",
              };
            case "string.regex.name":
                return { message: "Zip Code is not Valid" };
            case "string.min":
              return {
                message:
                  "Zip Code should be 5 digit",
              };
              case "string.max":
              return {
                message:
                  "Zip Code should be 5 digit",
              };
          }
        });
        return [_errors[0]];
      })
  });

  state: any = {
    payrollAddress: {
      street: this.props?.enterPriseWisePayrollData?.street ||"",
      city: this.props?.enterPriseWisePayrollData?.city ||"",
      state: this.props?.enterPriseWisePayrollData?.state || "",
      zip: this.props?.enterPriseWisePayrollData?.zip||""
    },
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0
  };

  componentDidMount = () => {};

  onFieldChange = (event, inputPosition) => {
    const { payrollAddress } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if(fieldName === "city" || fieldName === "country" || fieldName === "school") {
      // value = Utils.AllowOnlyAlphabetsWithRightSpace(value);
    }
    value= typeof value === 'string' ? value?.toString()?.trimLeft() :value
    payrollAddress[fieldName] =  typeof value === 'string' ? value?.toString()?.trimLeft() :value;
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({ inputPosition });
    }
    this.setState({ payrollAddress }, () =>
      
    this.validatepayrollAddressFormSchema()
    );
  };



  validatepayrollAddressFormSchema = () => {
    const valid = Joi.validate(
      this.state.payrollAddress,
      this.payrollAddressFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  validationOnClick = (inputPosition) => {
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({ inputPosition }, () => this.validatepayrollAddressFormSchema());
    }
  };
  componentWillUnmount=()=> {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }
  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };

  hideModal = () => {
    const payrollAddress: any = {
      street: "",
      city: "",
      state: "",
      zip: "",
    };
    this.props?.hidePayrollAddressModal();
    this.setState({ payrollAddress, isFormNotValid: true });
  };

addAddress = () => {
  this.props?.reAttachEnteriseWideSettingAddressToMainObject(this.state?.payrollAddress);
  this.hideModal();
}


  render() {
    const {
      payrollAddress: { street, city, state, zip },
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition
    } = this.state;
    const { showPayrollAddressModal } = this.props;
    return (
      <>
        <div className="">
        

          <Modal
            className="add-address-modal"
            show={showPayrollAddressModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                  Address
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Form.Group controlId="street">
                    <Col
                      sm={"12"}
                      className={`mb-3 ${
                        errors?.street && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Street</Form.Label>
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={`txtStreet`} className={((street).length <= 82) ? "tootltip-hide": ""}>
                                {(street)}
                            </Tooltip> 
                        }
                        >
                      <input
                        maxLength={100}
                        type="text"
                        className="form-control"
                        id="txtStreet"
                        name="street"
                        autoComplete="txtStreet"
                        value={street}
                        // onKeyDown={Utils.allowOnlyAplhaNumeric}
                        onChange={(e) => this.onFieldChange(e, 1)}
                      />
                      </OverlayTrigger>
                      {this.getFieldError("street", 1)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="city">
                    <Col
                      sm={"12"}
                      className={`mb-3 ${
                        errors?.city && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(2)}
                    >
                      <Form.Label>City</Form.Label>

                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={`txtCity`} className={((city).length <= 82) ? "tootltip-hide": ""}>
                                {(city)}
                            </Tooltip> 
                        }
                        >
                      <input
                        maxLength={100}
                        type="text"
                        className="form-control"
                        id="txtCity"
                        name="city"
                        autoComplete="txtCity"
                        value={city}
                        onKeyDown={Utils.allowOnlyAplhabet}
                        onChange={(e) => this.onFieldChange(e, 2)}
                      />
                      </OverlayTrigger>
                      {this.getFieldError("city", 2)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="state">
                    <Col
                      sm={"12"}
                      className={`mb-3 ${
                        errors?.state && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(3)}
                    >
                      <Form.Label>State</Form.Label>
                       <DropDownList
                        placeHolder={"Select State"}
                        data={_.sortBy(StaticArrays.userStates,'label')}
                        defaultValue={state}
                        isSearchRequired={true}
                        label={"label"}
                        value={"value"}
                        // disabled={
                        //   !userGeneralInfo?.hasEditContactInfo &&
                        //   !isNewUserAdded
                        // }
                        onDropDownChange={(item) => {
                          if (!item) {
                            return;
                          }
                          this.onFieldChange(
                            Utils.BuildCustomEventObject("state", item.value),
                            3
                          );
                        }}
                        selectedItem={[
                          ...[{ value: "", label: "Select State" }],
                          ...StaticArrays.userStates,
                        ].find((r: any) => r?.value === state) || {}}
                      /> 
                      {this.getFieldError("state", 3)}
                    </Col>
                  </Form.Group>

                 
                 
                  <Form.Group controlId="zip">
                    <Col
                      sm={"12"}
                      onBlur={() => this.validationOnClick(6)}
                      className={
                        errors?.zip && inputPosition >= 6
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Zip</Form.Label>
                      <input
                      type="text"
                      onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                       min="0"
                        className="form-control"
                        id="txtZip"
                        name="zip"
                        autoComplete="txtZip"
                        value={zip}
                        onChange={(e) => this.onFieldChange(e, 6)}
                      />
                      {this.getFieldError("zip", 6)}
                    </Col>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-ghost" onClick={this.hideModal}>Cancel</Button>
              <Button
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.addAddress}
              >
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
