import React from "react";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Row, Col } from "react-bootstrap";
import { Roles } from "../../Common/Services/RolePermissionSetting";
import profile from "../Assets/Images/profile.png";
import { resolve, reject } from "q";
import OutsideClickHandler from "react-outside-click-handler";

export class UserListSpecialPermission extends React.Component<any, any> {
  private inputSearch: any;
  static defaultProps: { component: string };
  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();
    this.state = {
      filterData: [],
      allEmployeeList: [],
      selectedItems: [],
      inputValue: "",
      selectedPermissionID: 0,
    };
  }

  // componentDidMount() {
  //   this.SearchEmployeeForSpecialPermission();
  // }

  SearchEmployeeForSpecialPermission = (permissionID: number) => {
    Roles.SearchEmployeeForSpecialPermission(permissionID)
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({
            allEmployeeList: result,
            filterData: result,
            itemLists: result,
            selectedPermissionID: permissionID,
          });
          console.log(result);
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  handleOutsideClick = () => {
    this.setState({ opened: false, inputValue: "",allEmployeeList:this.state.itemLists });
  };

  openDropdown = () => {
    this.setState(
      {
        opened: true,
        filterData: [],
        inputValue: "",
      },
      () => {
        this.inputSearch.current.focus();
      }
    );
  };

  handleRemoveSpecificChip = (idx: number) => () => {
    const itemLists = [...this.state.itemLists];
    const selectedItems = [...this.state.selectedItems];
    if (selectedItems[0].employee !== undefined) {
      selectedItems.splice(idx, 1);
      this.setState({ selectedItems, itemLists }, () => {
        this.props.selectFromSearchBar([]);
        this.setState({ opened: false, inputValue: "" });
        this.SearchEmployeeForSpecialPermission(
          this.state.selectedPermissionID
        );
      });
    }
  };

  handleChange = (e: any) => {
    const updatedList = this.state.itemLists.filter((item: any) => {
      return (
        item.username.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    this.setState({
      filterData: updatedList,
      allEmployeeList: updatedList,
      inputValue: e.target.value,
    });
  };

  userListClick = (idx: any) => {
    const itemLists = [...this.state.itemLists];

    const index = itemLists.findIndex((xvalue) => xvalue.uniqueno === idx);
    idx = index;
    this.setState({ itemLists });
    const item = {
      value: itemLists[idx].uniqueno,
      employee: itemLists[idx].username,
    };
    this.setState(
      {
        selectedItems: [item],
        opened: false,
        inputValue: "",
      },
      () => {
        this.props.selectFromSearchBar(itemLists[index]);
        this.handleOutsideClick();
      }
    );
  };

  clearSearch = () => {
    let blankSelectedItems: any = [];
    this.setState({ selectedItems: blankSelectedItems });
  };

  userListScrollHeader = (idx: any) => {
    const itemLists = [...this.state.itemLists];
    const index = itemLists.findIndex((xvalue) => xvalue.rowno === idx);
    idx = index;
    this.setState({ itemLists });
    const item = {
      value: itemLists[idx].rowno,
      employee: itemLists[idx].employee,
    };
    this.setState(
      {
        selectedItems: [item],
      },
      () => {
        this.props.selectFromSearchBar(itemLists[index]);
        this.setState({ opened: false, inputValue: "" });
      }
    );
  };

  render() {
    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div
          className={
            this.state.opened ? "multiSelectSearch opened" : "multiSelectSearch"
          }
          id={this.props.id}
        >
          <div className="fieldArea" onClick={this.openDropdown}>
            {this.state.selectedItems.length === 0 ? (
              <span className="areaPlaceholder">Search </span>
            ) : (
              <div className="chip">
                {this.state.selectedItems.map((item: any, idx: any) => (
                  <div className="chipSelect" id={idx} key={idx}>
                    <div className="chipVal">
                      <EllipsisWithTooltip placement="bottom">
                        {item.employee}
                      </EllipsisWithTooltip>
                    </div>
                    <button
                      id="btnremove"
                      type="button"
                      className="cross"
                      onClick={this.handleRemoveSpecificChip(idx)}
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                          fill="#6A6E73"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div className="coaSearch">
              <div className="dropDownPanel DropDownPanel-Special" style={{minWidth:390}}>
                <div className="SearchInput">
                  <input
                    ref={this.inputSearch}
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder="Search"
                    value={this.state.inputValue}
                  />
                </div>
                
                <ul>
                  {this.state.allEmployeeList !== undefined && (
                    <div>
                      {this.state.allEmployeeList.map((item: any, idx: any) => (
                        <div>
                          <li
                            id={idx}
                            key={idx}
                            onClick={() =>
                              item.badge.toLowerCase() === "valid"
                                ? this.userListClick(item.uniqueno)
                                : ""
                            }
                          >
                            <div
                              className={[
                                item.badge.toLowerCase() !== "valid"
                                  ? "disabledRowSpecialPerm"
                                  : "",
                                "user-details",
                              ].join(" ")}
                            >
                              <img
                                className={
                                  item.imagePath === null ||
                                  item.imagePath.trim() === ""
                                    ? "profileimg default"
                                    : "profileimg"
                                }
                                src={
                                  item.imagePath === null ||
                                  item.imagePath.trim() === ""
                                    ? profile
                                    : item.imagePath
                                }
                                alt=""
                                width="36"
                                height="36"
                              />

                              <div className="name-position mr-auto">
                                <EllipsisWithTooltip placement="bottom">
                                  <div className="name mr-auto">
                                    {item.username}
                                  </div>
                                </EllipsisWithTooltip>
                              </div>
                              {item.badge.toLowerCase() !== "valid" && (
                                <span
                                  className="red-badges ml-auto"
                                  style={{ marginRight: -7 }}
                                >
                                  {item.badge}
                                </span>
                              )}
                            </div>
                          </li>
                        </div>
                      ))}
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

UserListSpecialPermission.defaultProps = {
  component: "rolePermission",
};
