import React from 'react';
import "./KnowledgeBaseLoader.scss";
import icon from "../Common/Assets/Images/icons/freshdesk-loader.gif";

function KnowledgeBaseLoader() {
  return (
    <div className='loader-wrapper'>
      <div className='pb-md-5 mb-lg-4'>
        <h1 className="heading pb-lg-1 pb-2">You are being redirected to our Knowledge Base. This may take 10-15 seconds.</h1>
        <h4 className="sub-heading mb-5">Some users experience a login screen after the redirect. Simply login with your Inn-Flow credentials to view the knowledge base.</h4>
        <img src={icon} width="100" className='img-fluid loader-icon' alt="loader" />
      </div>
    </div>
  )
}

export default KnowledgeBaseLoader
