import * as React from "react";
import {  Modal, Button, Form } from "react-bootstrap";
import Joi from "joi-browser";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import { Utils } from "../../../Common/Utilis";
export class AdDocumentType extends React.Component<any, any> {
  documentTypeSchema = Joi.object({
      typeName: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error)=> {
          switch (error.type) {
            case "any.empty":
              return { message: "Document Type is required" };
          }
        });
      }),
  

      typeId: Joi.number(),
      docEntryType: Joi.string(),
    tenantID: Joi.number()

  });

  constructor(props: any) {
    super(props);
    this.state = {
      isValidForm: false,
      error: {},
      inputPosition: 1,
      
      documentType: {
        typeName:  this.props.typeName || "",
        typeId: this.props.typeId || 0,
        docEntryType: this.props.docEntryType || "",
        tenantID: 0
      },
    };
  }

  validateForm = () => {
    const valid = Joi.validate(this.state.documentType, this.documentTypeSchema, {
      abortEarly: false,
    });
    
    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    if (!valid.error) {
      this.setState({ isValidForm: true });
    } else {
      this.setState({ isValidForm: false }); 
    }

    this.setState({ error: newErrorObject });
    return newErrorObject;
  };


  addEditDocumentType = (e) => {

      const {  documentType: {
        typeName,
        typeId,
        docEntryType
      } } = this.state;
     this.setState({isValidForm: false});
      UserManagement.AddEditDocumentType(typeName,typeId,docEntryType)
        .then((response) => {
          if(response?.result?.success) {
            toast.success(response?.result?.result, {
               position: toast.POSITION.BOTTOM_RIGHT,
               containerId: "addDocumentType"
            });
            this.hideDocTypeModal(true, typeName);
           
            
          } else {
            Utils.toastError(response?.result?.result, {
               position: toast.POSITION.BOTTOM_RIGHT,
               containerId: "addDocumentType"
            });
          }
        })
        .catch((error) => {
          Utils.toastError(`Server Error, ${error}`, {
             position: toast.POSITION.BOTTOM_RIGHT,
             containerId: "addDocumentType"
          });
        });
    
  };

  onFieldChange = (fieldName, value, inputPosition) => {
    const { documentType } = this.state;
    value =value?  Utils.CapitalizeFirstLetter(value) : value;
    documentType[fieldName]  = value ? value?.trimLeft() : value ; 
    this.setState({ documentType , inputPosition }, () =>{ this.validateForm();
    });
   
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () =>
    this.validateForm()
  );
  };
  componentWillUnmount=()=> {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }
  hideDocTypeModal = (isReloadRequired = false, typeName ='') => {
    this.props.hideDocTypeModal(isReloadRequired,typeName);
    this.setState({
      isValidForm: false,
      error: {},
      inputPosition: 1,
      
      documentType: {
        typeName:  this.props.typeName || "",
        typeId: this.props.typeId || 0,
        docEntryType: this.props.docEntryType || "",
        tenantID: 0
      },
    
    });
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { error , inputPosition } = this.state;
    
    return (
      <>
        {(Object.keys(error).length > 0 && fieldPosition <= inputPosition)  && (
          <span className="validation-message">{error[fieldName]}</span>
        )}
      </>
    );
  };

  handlerCopy(e) {
    e.preventDefault();
  }

  render() {
    const { showDocTypeModal } = this.props;
    const {
      documentType: { typeName },
      error,
      isValidForm,
      inputPosition
    } = this.state;

    
    return (
      <form autoComplete="false">
    
        <Modal
          className="add-edit-document-type"
          show={showDocTypeModal}
          onHide={()=> this.hideDocTypeModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          
        >
          <Modal.Header closeButton>
            <Modal.Title> {this.props.docEntryType ==="New" ? "Add" : "Edit"  } Document Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-section d-flex flex-column">
              <div className="body-section d-flex flex-row justify-content-between">
                <Form.Group
                  controlId="date-selection"
                  className={(error?.typeName && inputPosition >=1) ? "validation-error" : ""}
                  onBlur={()=> this.validationOnClick(1) }
                >
                  <Form.Label>Type Name</Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    id="txtTypeName"
                    name="txtTypeName"
                    autoComplete ="txtTypeName"
                    value={typeName}
                    onChange={(e: any) =>
                      this.onFieldChange("typeName", e.target.value,1)
                    }
                  />

                  {this.getFieldError("typeName",1)}
                </Form.Group>

              </div>
            
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={!isValidForm}
              type="submit"
              className="import-forecast-button"
               onClick={(e: any) => this.addEditDocumentType(e)}
            >
             {this.props.docEntryType ==="New" ? "Add" : "Update"  }
              
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    );
  }
}
