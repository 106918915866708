import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { HeaderMenu } from "../../Common/Services/HeaderMenu";
import { resolve, reject } from "q";
import { Player } from 'video-react';
import { ToastContainer, toast } from "react-toastify";
import {
    ILaborDayApproveRequestDto,
    //  ILaborSmileIdRequestDto,
} from "../../Common/Contracts/ILaborDayApprove";
import { LaborDayApprove } from "../../Common/Services/LaborDayApprove";
import { Hotel as hotel } from "../../Common/Services/Hotel";
import { accountService } from "../../Common/Services/account";
import _ from "lodash";
import createDOMPurify from "dompurify";
import { Utils } from "../../Common/Utilis";
const DOMPurify = createDOMPurify(window);
export class Archive extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            cardData: [],
            htmlItem: [],
            htmlErrorItem: [],
            htmlWarningItem: [],
            dayApprove: 0,
            roomAssignment: 0,
            housekeppingApp: 0,
            overrisk: 0,
            smileID: 0,
            payrollExport: 0,
            timeoffrequestCount: 0,
            isLaborOpen: false,
            isAccountOpen: false,
            tabName: 'OverView',
            activeToggle: false,
            activeToggleAC: true,
            hasLaborMenu: false,
            accountingOverviewCounts: [],
            unpaidTableData: [],
            hasAccountingMenu: false,
        };
        this.toggleClass = this.toggleClass.bind(this);
        this.toggleClassAC = this.toggleClassAC.bind(this);
        this.handleDismissCard = this.handleDismissCard.bind(this);
        this.openOverView = this.openOverView.bind(this);
    }

    componentDidMount() {
        this.getHomeCard();
        this.getMenuItems();
        // this.getAccountOverviewStat();
    }

    getAccountOverviewStat = () => {
        accountService.GetAccountOverviewStat().then((data) => {
            let accountingOverviewCounts: any[] = [];
            const unpaidTableData: any[] = [];
            let hotelIds = "";

            if (data?.length > 0) {
                hotelIds = _.uniq(data?.filter(x => x?.type === 2)
                    ?.map((x) => x.hotelId)).join(",");
                const flags = data[0];

                const draftInvoiceSum: number = data
                    ?.map((x) => +x.draftInvoice)
                    ?.reduce((a, b) => a + b, 0);
                const draftInvoiceObj: any = {
                    label: "Draft Invoices",
                    counts: draftInvoiceSum,
                    name: "draftInvoice",
                    isVisible: flags?.showDraftInvoice
                };
                accountingOverviewCounts.push(draftInvoiceObj);

                const unapprovedInvoiceSum: number = data
                    ?.map((x) => +x.unapprovedInvoice)
                    ?.reduce((a, b) => a + b, 0);
                const unapprovedInvoiceObj: any = {
                    label: "Unapproved Invoices",
                    counts: unapprovedInvoiceSum,
                    name: "unapprovedInvoice",
                    isVisible: flags?.showUnapprovedInvoice
                };
                accountingOverviewCounts.push(unapprovedInvoiceObj);

                const unapprovedVendorsSum = data
                    ?.map((x) => +x.unapprovedVendors)[0];
                const unapprovedVendorsObj: any = {
                    label: "Unapproved Vendors",
                    counts: unapprovedVendorsSum,
                    name: "unapprovedVendors",
                    isVisible: flags?.showUnapprovedVendors
                };


                accountingOverviewCounts.push(unapprovedVendorsObj);

                const unpaidInvoiceSum = data
                    ?.map((x) => +x.unpaidInvoiceACH + +x.unpaidInvoiceCheck)
                    ?.reduce((a, b) => a + b, 0);
                const unpaidInvoiceObj: any = {
                    label: "Unpaid Invoices",
                    counts: unpaidInvoiceSum,
                    name: "unpaidInvoice",
                    isVisible: flags?.showUnpaidInvoice

                };

                unpaidTableData.push({
                    id: 1,
                    name: "Unpaid Digital ACH",
                    counts: data
                        ?.map((x) => +x.unpaidInvoiceACH)
                        ?.reduce((a, b) => a + b, 0),
                });
                unpaidTableData.push({
                    id: 2,
                    name: "Unpaid Checks",
                    counts: data
                        ?.map((x) => +x.unpaidInvoiceCheck)
                        ?.reduce((a, b) => a + b, 0),
                });
                accountingOverviewCounts.push(unpaidInvoiceObj);
                const untrustedVendorsSum = data
                    ?.map((x) => +x.untrustedVendors)[0];
                const untrustedVendorsObj: any = {
                    label: "Untrused Vendors",
                    counts: untrustedVendorsSum,
                    name: "untrustedVendors",
                    isVisible: flags?.showUntrustedVendors
                };
                accountingOverviewCounts.push(untrustedVendorsObj);
            }
            accountingOverviewCounts = accountingOverviewCounts.filter(x => x?.counts > 0 && x?.isVisible)
            this.setState({ accountingOverviewCounts });
        })
    };

    openAccounting(tabName) {
        this.setState({ isAccountOpen: true, isLaborOpen: false, tabName: tabName });
        var btnlabor = $("[id*=defaultSet-Accounting]");
        var btnHome = $("[id*=defaultSet-Home]");
        btnlabor[0].className = "nav-link active";
        btnHome[0].className = "nav-link";
        localStorage.setItem("mainMenu", "Accounting");
    }

    getMenuItems() {
        HeaderMenu.GetHeaderData()
            .then(async (result: any[] | null) => {
                if (result != null) {
                    let menuItemTop: any[] = [];
                    let { hasLaborMenu, hasAccountingMenu } = this.state;
                    menuItemTop = result.filter((e: { itemPosition: string }) => e.itemPosition === "Top");
                    hasLaborMenu = menuItemTop.some((e: { name: string }) => e.name === "Labor");
                    hasAccountingMenu = menuItemTop.some((e: { name: string }) => e.name === "Accounting");
                    this.setState({ hasLaborMenu, hasAccountingMenu });
                    if (hasLaborMenu) {
                        this.bindDailyAlert();
                    }
                    if (hasAccountingMenu) {
                        this.getAccountOverviewStat();
                    }
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError(`Server Error, ${err}`);
                reject();
            });
    }

    toggleClass() {
        const currentStateAC = this.state.activeToggleAC;
        const currentState = this.state.activeToggle;
        if (!currentStateAC) {
            this.setState({ activeToggleAC: !currentStateAC })
        }
        this.setState({ activeToggle: !currentState });
    }

    toggleClassAC() {
        const currentStateAC = this.state.activeToggleAC;
        const currentState = this.state.activeToggle;
        if (!currentState) {
            this.setState({ activeToggle: !currentState })
        }
        this.setState({ activeToggleAC: !currentStateAC });
    }

    openOverView(tabName) {
        this.setState({ isLaborOpen: true, isAccountOpen: false, tabName: tabName });
        var btnlabor = $('[id*=defaultSet-Labor]');
        var btnHome = $('[id*=defaultSet-Home]');
        btnlabor[0].className = "nav-link active";
        btnHome[0].className = "nav-link";
        localStorage.setItem("mainMenu", "Labor");
    }

    payrollExportFirstApi = () => {
        let request: any = {};
        let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
        request.tenantID = localStorageUser.tenantID;
        request.hotelID = 0;
        request.LoginUserName = localStorageUser.userName;
        request.callType = "HotelWiseCount";
        hotel.GetPayrollExportData(request)
            .then(async (result) => {
                if (result != null) {
                    let apiResult = result.result;
                    //console.log(apiResult);
                    this.setState({ payrollExport: apiResult.length });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ loaderTrue: false });
                Utils.toastError(`Server Error: ${error}`, {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                    // // containerId: "laborDayOverviewContainer",
                });
                reject();
            });
    };

    TimeOffRequestFirstApi = () => {
        let request: any = {};
        let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
        request.tenantID = localStorageUser.tenantID;
        request.hotelID = 0;
        request.LoginUserName = localStorageUser.userName;
        request.callType = "HotelWiseCount";
        hotel.GetPendingTimeoffCount(request).then(async (result) => {
            if (result.success) {
                let dayCount = 0;
                let apiResult = result.result;
                for (let i = 0; i < apiResult.length; i++) {
                    dayCount = dayCount + apiResult[i].countData;
                }
                this.setState({ timeoffrequestCount: dayCount });
            }
            resolve();
        })
            .catch((error) => {
                this.setState({ loaderTrue: false });
                Utils.toastError(`Server Error: ${error}`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "timeoffrequestCountContainer",
                });
                reject();
            });
    };

    bindOverRisk(response: any) {
        hotel.GetOverTimeRiskData(response).then(async (result) => {

            if (result != null) {
                let data = result.result;
                this.setState({ overrisk: data[0].result })
            }
        }).catch((error) => {
            reject();
        });
    }


    bindDailyAlert() {
        let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
        let request = {} as ILaborDayApproveRequestDto;
        request.hotelID = "All";
        request.userUniqueNo = localStorageUser.user_UniqueID;
        this.binDayApproveData(request);
        let response1: any = {};
        response1.tenantID = localStorageUser.tenantID;
        response1.hotelID = 0;
        response1.loginUserName = localStorageUser.userName;
        response1.callType = 'AllHotelCount';
        response1.userName = "";
        this.bindOverRisk(response1)
        this.payrollExportFirstApi();
        this.TimeOffRequestFirstApi();
    }

    binDayApproveData(request: ILaborDayApproveRequestDto) {
        LaborDayApprove.GetLaborDayApprovalDetails(request).then(async (result: any | null) => {
            if (result != null && result.length > 0) {
                //console.log(result);
                // let dayApprove = 0, roomAssignment = 0, housekeppingApp = 0, overrisk = 0
                for (let x = 0; x < result.length; x++) {
                    let dayType = result[x].dayType;
                    let dayCount = result[x].dayCount;
                    switch (dayType) {
                        case "ApproveDay":
                            this.setState({ dayApprove: dayCount })
                            break;
                        case "SmileID":
                            this.setState({ smileID: dayCount })
                            break;
                        case "RoomAssignments":
                            this.setState({ roomAssignment: dayCount })
                            break;
                        case "HousekeepingApprovals":
                            this.setState({ housekeppingApp: dayCount })
                            break;
                        case "OverTimeRisk":
                            this.setState({ overrisk: dayCount })
                            break;
                    }
                }
            }
            resolve();
        })
            .catch((error) => {
                reject();
            });
    }



    handleDismissCard(ID: number, cardID: number) {
        HeaderMenu.DeleteDismissCard(ID)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.saveStatus === "Success") {
                        this.getHomeCard();
                        toast.success(result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                            // // containerId: "HomeCard",
                        });
                        this.setState({ loaderTrue: false, isNewPosition: false });
                        this.setState({ selectedItems: [] });
                    } else {
                        Utils.toastError(result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                            // // containerId: "HomeCard",
                        });
                    }
                } else {
                    Utils.toastError("Internal server error.", {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                        // // containerId: "HomeCard",
                    });
                }
                this.setState({ loaderTrue: false });
                resolve();
            })
            .catch((error) => {
                reject();
                this.setState({ loaderTrue: false });
            });
    }


    getHomeCard() {
        HeaderMenu.CardDisMissDetails()
            .then(async (result: any[] | null) => {
                if (result != null) {
                    this.setState({
                        cardData: result,
                        htmlItem: []
                    }, () => {
                        let item: any[] = [];
                        let dataArr: any[] = [];
                        dataArr = result
                        for (let x = 0; x < dataArr.length; x++) {
                            let ID = dataArr[x].id;
                            let CardID = dataArr[x].cardID;
                            let CardType = dataArr[x].cardType;
                            let Title = dataArr[x].title;
                            let Title2 = dataArr[x].title2;
                            let Content = dataArr[x].content;
                            let IconURL = dataArr[x].iconURL;
                            let BgImageURL = dataArr[x].bgImageURL;
                            let VideoURL = dataArr[x].videoURL;
                            let Cta1Text = dataArr[x].cta1Text;
                            let Cta1Link = dataArr[x].cta1Link;
                            let Cta2Text = dataArr[x].cta2Text;
                            let Cta2Link = dataArr[x].cta2Link;
                            // let AlwaysonTop = dataArr[x].alwaysonTop;
                            let Dismiss = dataArr[x].dismiss;
                            switch (CardType) {
                                case "Left-aligned Icon card":
                                    item.push(<li className="card left-aligned">
                                        <div className="d-flex card-content-wrapper">
                                            <div className="icon">
                                                <img src={IconURL} alt={Title} />
                                            </div>
                                            <div className="body-part">
                                                <div className="title">{Title}</div>
                                                <div className="description" 
                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                                                ></div>
                                                <div className="action">
                                                    {Cta1Link.length > 0 && (
                                                        <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                                                    )}
                                                    {/* <Link className="d-flex align-items-center wht-bg-outline btn btn-primary" to={Cta1Link} target="_blank">{Cta1Text}</Link> */}
                                                </div>
                                            </div>
                                        </div>
                                        {Dismiss === "Yes" && (
                                            <div className="dismiss">
                                                <Dropdown alignRight>
                                                    <Dropdown.Toggle className="btn-outline-primary  more" id="dropdown-basic">
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item id="dismiss1" onClick={() => this.handleDismissCard(ID, CardID)}>Restore</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        )}
                                    </li>
                                    )
                                    break;
                                case "Event card":
                                    item.push(<li className="card right-aligned"
                                        style={{
                                            backgroundImage: "url(" + BgImageURL + ")",
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat'
                                        }}>
                                        <div className="d-flex align-items-center">
                                            <div className="body-part mr-auto">
                                                <div className="title">{Title}</div>
                                                <div className="description" 
                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                                                ></div>
                                                <div className="action">
                                                    <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                                                    <a className="d-flex align-items-center wht-bg-outline btn btn-primary remove-bdr" href={Cta2Link} target="_blank">{Cta2Text}</a>
                                                </div>
                                            </div>
                                            <div className="icon">
                                                <img src={IconURL} alt={Title} />
                                            </div>
                                        </div>
                                        {Dismiss === "Yes" && (
                                            <div className="dismiss">
                                                <Dropdown alignRight>
                                                    <Dropdown.Toggle className="btn-outline-primary  more" id="dropdown-basic">
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item id="dismiss2" onClick={() => this.handleDismissCard(ID, CardID)}>Restore</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        )}
                                    </li>
                                    )
                                    break;
                                case "Text Only card":
                                    item.push(<li className="card left-aligned">
                                        <div className="d-flex align-items-center">
                                            <div className="body-part">
                                                <div className="title">{Title}</div>
                                                <div className="description" 
                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                                                ></div>
                                                <div className="action">
                                                    <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                                                    <a className="d-flex align-items-center wht-bg-outline btn btn-primary remove-bdr" href={Cta2Link} target="_blank">{Cta2Text}</a>
                                                </div>
                                            </div>
                                        </div>
                                        {Dismiss === "Yes" && (
                                            <div className="dismiss">
                                                <Dropdown alignRight>
                                                    <Dropdown.Toggle className="btn-outline-primary  more" id="dropdown-basic">
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item id="dismiss3" onClick={() => this.handleDismissCard(ID, CardID)}>Restore</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        )}
                                    </li>
                                    )
                                    break;
                                case "Tall Video card":
                                    item.push(<li className="card video">
                                        <Player>
                                            <source src={VideoURL} />
                                        </Player>
                                        <div className="footer">
                                            <div className="title">{Title}</div>
                                            <div className="desc" 
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                                            ></div>
                                        </div>
                                        {Dismiss === "Yes" && (
                                            <div className="dismiss">
                                                <Dropdown alignRight>
                                                    <Dropdown.Toggle className="btn-outline-primary  more" id="dropdown-basic">
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item id="dismiss4" onClick={() => this.handleDismissCard(ID, CardID)}>Restore</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        )}
                                    </li>
                                    )
                                    break;
                                case "Tall Image Background card":
                                    item.push(<li className="card center-aligned-bg"
                                        style={{
                                            backgroundImage: "url(" + BgImageURL + ")",
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat'
                                        }}>  <div className="d-flex align-items-center justify-content-center height-100">
                                            <div className="body-part">
                                                <div className="title">{Title}</div>
                                                <div className="title heading">{Title2}</div>
                                                <div className="description" 
                                               dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                                                ></div>
                                                <div className="action">
                                                    <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                                                </div>
                                            </div>
                                        </div>
                                        {Dismiss === "Yes" && (
                                            <div className="dismiss">
                                                <Dropdown alignRight>
                                                    <Dropdown.Toggle className="btn-outline-primary  more" id="dropdown-basic">
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item id="dismiss5" onClick={() => this.handleDismissCard(ID, CardID)}>Restore</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        )}
                                    </li>
                                    )
                                    break;
                                case "Tall Center-aligned Icon card":
                                    item.push(<li className="card center-aligned">
                                        <div className="icon">
                                            <img src={IconURL} alt={Title} />
                                        </div>
                                        <div className="body-part">
                                            <div className="title">{Title}</div>
                                            <div className="description" 
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Content) }}
                                            ></div>
                                            <div className="action">
                                                <a className="d-flex align-items-center wht-bg-outline btn btn-primary" href={Cta1Link} target="_blank">{Cta1Text}</a>
                                                <a className="d-flex align-items-center wht-bg-outline btn btn-primary remove-bdr" href={Cta2Link} target="_blank">{Cta2Text}</a>
                                            </div>
                                        </div>
                                        {Dismiss === "Yes" && (
                                            <div className="dismiss">
                                                <Dropdown alignRight>
                                                    <Dropdown.Toggle className="btn-outline-primary  more" id="dropdown-basic">
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item id="dismiss6" onClick={() => this.handleDismissCard(ID, CardID)}>Restore</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        )}
                                    </li>
                                    )
                                    break;
                            }
                        }
                        this.setState({ htmlItem: item });
                    });
                }
                resolve();
            })
            .catch((error: any) => {
                this.setState({ loaderTrue: false });
                reject();
            });
    }


    render() {
        if (this.state.isLaborOpen) {
            return <Redirect to={{
                pathname: "/labor-management/approval",
                state: {
                    tabName: this.state.tabName,
                },
            }} />;
        }
        if (this.state.isAccountOpen) {
            return (
                <Redirect to={{
                    pathname: "/Account-management/Overview",
                    state: { tabName: this.state.tabName, },
                }}
                />
            );
        }
        return (
            <div className="home d-flex">
                {/* <ToastContainer autoClose={3000} containerId={"HomeCard"} enableMultiContainer /> */}
                <div className="left-sec mr-auto">
                    <Container fluid className="body-sec">
                        <div className="page-heading underline d-flex">
                            <div className="mr-auto align-items-center">
                                Archive
                                <div className="tag-line">Here’s what’s happening for you today.</div>
                            </div>
                            <Button as={Link} to="/home" className="btn btn-primary d-flex align-items-center">Back to Feed</Button>
                        </div>
                        <div className="body-list">
                            <div className="add-list">
                                <ul>
                                    {this.state.htmlItem}
                                </ul>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="right-sec">
                    <div className="sec-name">Tasks</div>
                    {/* {this.state.hasLaborMenu && ( */}
                        <div className="Module-list">
                            <Accordion defaultActiveKey="1">
                                {this.state.hasAccountingMenu && (
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button}
                                                className={this.state.activeToggleAC ? "activeToggle" : ""}
                                                onClick={this.toggleClassAC}
                                                variant="link"
                                                eventKey="1">
                                                <div className="d-flex">
                                                    <div className="mr-auto">Accounting</div>
                                                    <div>
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M6.29297 10.707L12 16.414L17.707 10.707L16.293 9.293L12 13.586L7.70697 9.293L6.29297 10.707Z"
                                                                fill="#84888C" />
                                                        </svg>
                                                    </div>
                                                </div>

                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <ul style={{ cursor: "pointer" }}>
                                                    {this.state.accountingOverviewCounts.map((item: any, idx: number) => (
                                                        <li>
                                                            <a className="d-flex" onClick={() => this.openAccounting(item.name)}>
                                                                <div className="mr-auto">
                                                                    <EllipsisWithTooltip placement="bottom">
                                                                        {item.label}
                                                                    </EllipsisWithTooltip>
                                                                </div>
                                                                <div className="badge">{item.counts}</div>
                                                            </a>
                                                        </li>
                                                    ))
                                                    }
                                                    <li className="static">
                                                        <a onClick={() => this.openAccounting("Overview")}>
                                                            <EllipsisWithTooltip placement="bottom">
                                                                Accounting Overview
                                                            </EllipsisWithTooltip>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )}
                                {this.state.hasLaborMenu && (
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle
                                                as={Button} className={this.state.activeToggle ? 'activeToggle' : ""}
                                                onClick={this.toggleClass} variant="link" eventKey="0">
                                                <div className="d-flex">
                                                    <div className="mr-auto">Labor Management</div>
                                                    <div>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.29297 10.707L12 16.414L17.707 10.707L16.293 9.293L12 13.586L7.70697 9.293L6.29297 10.707Z" fill="#84888C" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <ul style={{ cursor: "pointer" }}>
                                                    {(this.state.dayApprove > 0 &&
                                                        <li>
                                                            <a className="d-flex" onClick={() => this.openOverView('ApproveDay')}>
                                                                <div className="mr-auto">
                                                                    <EllipsisWithTooltip placement="bottom">
                                                                        Days to Approve
                                                                    </EllipsisWithTooltip>
                                                                </div>
                                                                <div className="badge">{this.state.dayApprove}</div>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(this.state.smileID > 0 &&
                                                        <li>
                                                            <a className="d-flex" onClick={() => this.openOverView('SmileID')}>
                                                                <div className="mr-auto">
                                                                    <EllipsisWithTooltip placement="bottom">
                                                                        Smile iD
                                                                    </EllipsisWithTooltip>
                                                                </div>
                                                                <div className="badge">{this.state.smileID}</div>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(this.state.roomAssignment > 0 &&
                                                        <li>
                                                            <a className="d-flex" onClick={() => this.openOverView('RoomAssignments')}>
                                                                <div className="mr-auto">
                                                                    <EllipsisWithTooltip placement="bottom">
                                                                        Room Assignments
                                                                    </EllipsisWithTooltip>
                                                                </div>
                                                                <div className="badge">{this.state.roomAssignment}</div>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(this.state.housekeppingApp > 0 &&
                                                        <li>
                                                            <a className="d-flex" onClick={() => this.openOverView('HousekeepingApprovals')}>
                                                                <div className="mr-auto">
                                                                    <EllipsisWithTooltip placement="bottom">
                                                                        Housekeeping Approvals
                                                                    </EllipsisWithTooltip>
                                                                </div>
                                                                <div className="badge">{this.state.housekeppingApp}</div>
                                                            </a>
                                                        </li>
                                                    )}

                                                    {(this.state.payrollExport > 0 &&
                                                        <li>
                                                            <a className="d-flex" onClick={() => this.openOverView('PE')}>
                                                                <div className="mr-auto">
                                                                    <EllipsisWithTooltip placement="bottom">
                                                                        Payroll Export
                                                                    </EllipsisWithTooltip>
                                                                </div>
                                                                <div className="badge">{this.state.payrollExport}</div>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {this.state.timeoffrequestCount > 0 &&
                                                        //this.state.isToffPPermission && ( )
                                                        <li>
                                                            <a
                                                                className="d-flex"
                                                                onClick={() => this.openOverView("ToffR")}
                                                            >
                                                                <div className="mr-auto">
                                                                    <EllipsisWithTooltip placement="bottom">
                                                                        Time off Requests
                                                                    </EllipsisWithTooltip>
                                                                </div>
                                                                <div className="badge">
                                                                    {this.state.timeoffrequestCount}
                                                                </div>
                                                            </a>
                                                        </li>
                                                    }
                                                    {(this.state.overrisk > 0 &&
                                                        <li>
                                                            <a className="d-flex" onClick={() => this.openOverView('otar')}>
                                                                <div className="mr-auto">
                                                                    <EllipsisWithTooltip placement="bottom">
                                                                        Overtime at Risk
                                                                    </EllipsisWithTooltip>
                                                                </div>
                                                                <div className="badge">{this.state.overrisk}</div>
                                                            </a>
                                                        </li>
                                                    )}
                                                    <li className="static">
                                                        <a onClick={() => this.openOverView('OverView')}>
                                                            <EllipsisWithTooltip placement="bottom">
                                                                Labor Overview
                                                            </EllipsisWithTooltip>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )}
                            </Accordion>
                        </div>
                    {/* )} */}
                </div>
            </div>
        );
    }
}
