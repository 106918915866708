import Instense from "./Axios-config";
import {
  TimeClockDetailsDto,
  TimeClockDeviceDetails,
  WebClockTimePeriod,
  IClockCookieSaveDto,
  WebTimeClockDetailsDto,
  IClockCookieUpdateDeleteDto,
  TimeClockPeriodDto,
} from "../Contracts/ITimeClockDeviceDetails";

export class TimeClockDeviceDetail {
  public static TimeClockDeviceDetails = async (
    hotelID: number
  ): Promise<TimeClockDeviceDetails | null> => {
    let storageKey = JSON.parse(localStorage.getItem("storage")!);
    let TenantID = storageKey.tenantID as any;
    let request = {} as TimeClockDetailsDto;
    request.tenantID = TenantID;
    request.hotelID = hotelID;
    const url = `/TimeClock/TimeClockDeviceDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as TimeClockDeviceDetails;
      return result;
    });
  };
  public static GetTimeClockCookieDetailsForUpdateTime = async (
    ): Promise<WebClockTimePeriod | null> => {
      let request = {} as WebTimeClockDetailsDto;
      request.tenantID = 0;
      request.HID = 0;
      const url = `/TimeClock/GetTimeClockCookieData`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response?.data?.result;
        return result;
      });
    };
  
  
    public static UpdateCookieExpireTime = async (
      request: IClockCookieUpdateDeleteDto
    ): Promise<any | null> => {
      const url = `/TimeClock/UpdateTimeClockCookieStatus`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result;
        return result;
      });
    };
  public static GetCookieTimePeriod = async (
    uniqueID: string,
    hotelID: number
  ): Promise<WebClockTimePeriod | null> => {
    let storageKey = JSON.parse(localStorage.getItem("storage")!);
    let TenantID = storageKey.tenantID as any;
    let request = {} as TimeClockPeriodDto;
    request.tenantID = TenantID;
    request.UniqueID = uniqueID;
    request.HID = hotelID;
    const url = `/TimeClock/GetCookieExpiryDays`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
  // public static GetTimeClockCookieDetailsForUpdateTime = async (
  //   ): Promise<WebClockTimePeriod | null> => {
  //     let request = {} as WebTimeClockDetailsDto;
  //     request.tenantID = 0;
  //     request.HID = 0;
  //     const url = `/TimeClock/GetTimeClockCookieData`;
  //     return Instense.get(url, { params: request }).then((response) => {
  //       let result = response.data.result;
  //       return result;
  //     });
  //   };
  
 
    // public static UpdateCookieExpireTime = async (
    //   request: IClockCookieUpdateDeleteDto
    // ): Promise<any | null> => {
    //   const url = `/TimeClock/UpdateTimeClockCookieStatus`;
    //   return Instense.get(url, { params: request }).then((response) => {
    //     let result = response.data.result;
    //     return result;
    //   });
    // };
  public static GetTimeClockCookieDetails = async (
    hotelID: number
  ): Promise<WebClockTimePeriod | null> => {
    let storageKey = JSON.parse(localStorage.getItem("storage")!);
    let TenantID = storageKey.tenantID as any;
    let request = {} as WebTimeClockDetailsDto;
    request.tenantID = TenantID;
    request.HID = hotelID;
    const url = `/TimeClock/GetTimeClockCookieData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result;

      return result;
    });
  };
  public static saveCookieDetails = async (
    request: IClockCookieSaveDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userName = storage === null ? 0 : (storage.userName as any);
    request.TenantID = tenantID;
    request.CreatedBy = userName;

    const url = `/TimeClock/SaveTimeClockCookie`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;

      return result;
    });
  };

  public static deleteOrUpdateCookie = async (
    request: IClockCookieUpdateDeleteDto
  ): Promise<any | null> => {
    const url = `/TimeClock/UpdateTimeClockCookieStatus`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
}
