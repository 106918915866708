import React from "react";
import {
  Container,

} from "react-bootstrap";
import { Line } from "react-chartjs-2";
import _ from "lodash";
import moment from "moment";
import { LaborDashBoard } from "../../Common/Services/LaborDashBoard";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { Utils } from "../../Common/Utilis";
import "./addRgbColor.scss";
import "./LaborDashboardChart.scss";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import { PeriodSelector } from "./Components/PeriodSelector";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ParameterSelector } from "./Components/ParameterSelector";
import { toast } from "react-toastify";

const MAXLIMIT=10
const rand = () => Math.floor(Math.random() * 255);
const chartRef: any = React.createRef();
const popUpRef: any = React.createRef();
const scrollRef: any = React.createRef();
const dataComparisonList: any[] = [
  { label: "Budget Variance", value: 2, type: 1 },
  { label: "Last Year Variance", value: 1, type: 1 },
  { label: "None", value: -1, type: 1 },
];

const getPeriods = (date = new Date()) => {
  return [
    { period: "Last 7 Days", label: `Last 7 Days (${moment(date).subtract(6, "days").format('MM/DD')} - ${moment(date).format('MM/DD')})`, value: 2 },
    { period: "Last 14 Days", label: `Last 14 Days (${moment(date).subtract(13, "days").format('MM/DD')} - ${moment(date).format('MM/DD')})`, value: 3 },
    { label: `Pay Period to Date`, value: 4 },
    { period: "Month To Date", label: `Month To Date (${moment(date).startOf('month').format('MM/DD')} - ${moment(date).format('MM/DD')})`, value: 5 },
    { period: "Year To Date", label: `Year To Date  (${moment(date).startOf('year').format('MM/DD')} - ${moment(date).format('MM/DD')})`, value: 6 },
    { period: "Trailing 12 Months", label: "Trailing 12 Months", value: 7 }

  ];
}

function convertToMMSS(time) {
  var parts = time.split(':');
  var hours = parseInt(parts[0]);
  var minutes = parseInt(parts[1]);
  var seconds = parseInt(parts[2]);

  var totalSeconds = (hours * 3600) + (minutes * 60) + seconds;

  var formattedMinutes = String(Math.floor(totalSeconds / 60)).padStart(2, '0');
  var formattedSeconds = String(totalSeconds % 60).padStart(2, '0');

  return formattedMinutes + ':' + formattedSeconds;
}
const sumOfArray = (list) => {
  if (!list || list?.length == 0) {
      return {}
  }

  
  let result = list.reduce((acc, curr) => {
      if (acc === null) {
          return curr
      }
      else {
          // const calculateTotalHrs= Utils.getMinutesFromHHMMDOT(acc?.totalHours || 0) + Utils.getMinutesFromHHMMDOT(curr?.totalHours || 0)
          return {
              ...curr,
              // hskmpcr: Number(Utils.getMinutesInHHMMFormatDot(calculateTotalHrs) || 0),
              // hskTotalCleanTime: Number((Number(acc?.hskTotalCleanTime) + Number(curr?.hskTotalCleanTime)) || 0),
              // hskmpcr: Number((Number(convertToMMSS(acc?.hskmpcr)) + Number(convertToMMSS(curr?.hskmpcr))) || 0),
              // totalWages: Number((Number(acc?.totalWages) + Number(curr?.totalWages)) || 0)?.toFixed(2),
              // cpor: Number((Number(acc?.cpor) + Number(curr?.cpor)) || 0),
              // "laborCostas%ofRevenue": Number((Number(acc["laborCostas%ofRevenue"]) + Number(curr["laborCostas%ofRevenue"])) || 0),
              roomsOccupied:    Number(curr?.roomsOccupied ) ,
              hskRoomsCleaned:    Number(curr?.hskRoomsCleaned ) ,
              // roomRevenue:     Number(curr?.roomRevenue ),
              // "occupied%":    Number(curr["occupied%"]) ,
          }
      }
  }, null)


  


  result = {
    ...result,
    hskTotalCleanTime: Number(Utils.getMinutesInHHMMFormat(Utils.getMinutesFromHHMMDOT(Number(result.hskTotalCleanTime || 0).toFixed(2).toString()))?.toString()?.replace(":",".")?.replaceAll(",","") || 0).toFixed(2),
    hskmpcr: Number(Utils.getMinutesInHHMMFormat(Utils.getMinutesFromHHMMDOT(Number(result.hskmpcr || 0).toFixed(2).toString()))?.toString()?.replace(":",".")?.replaceAll(",","") || 0).toFixed(2),
   
  }

  return result
}

const sumOfArrayHouseKeeper = (list) => {
  if (!list || list?.length == 0) {
      return {}
  }

  
  let result = list.reduce((acc, curr) => {
      if (acc === null) {
          return curr
      }
      else {
        
          return {
              ...curr,
            
              occupiedRoom:    Number(curr?.occupiedRoom ) ,
             
          }
      }
  }, null)


  const subGroupData =   _.groupBy(list.filter(x=>x.user_UniqueID ===result?.user_UniqueID),"date")
  result.occupiedRoom  = Object.keys(subGroupData).map(x=> subGroupData[x][0].occupiedRoom).reduce((a,b)=>a+b)?.toFixed(0)
  result.roomClean  = Object.keys(subGroupData).map(x=> subGroupData[x][0].roomClean).reduce((a,b)=>a+b)?.toFixed(0)
  result.totalCleanTime=result?.totalCleanTime || 0
  result.mpcr= result?.mpcr || 0


  return result
}

export class LaborHouseKeepingDashboardChart extends React.Component<any, any> {
  private pageName: string = "LaborHouseKeepingDashboardChart";
  private hasPageWidth: any;



  private chartFixedLineColors: any[] =  [
    "rgb(44, 175, 146)",
    "rgb(110, 44, 175)",
    "rgb(13, 144, 223)",
    "rgb(214, 116, 44)",
    "rgb(211, 65, 46)",
    "rgb(98, 242, 210)",
    "rgb(134, 58, 2)",
    "rgb(195, 155, 235)",
    "rgb(6, 90, 140)",
    "rgb(255, 133, 117)"
  ];


  constructor(props: any) {
    super(props);
    this.hasPageWidth = React.createRef();
    this.state = {



      //Common Filter

      comparisonPeriodValue: 2,
      chartPeriodValueChart: 2,
      comparisonPeriodValueChart: -1,
      hotelList: [],
      currentHotelIndex: 0,
      hotelName: "",
      calendarDate: null,
      pageHeading: "Accounting",
      chartData: {
        labels: [],
        type: "line",
        datasets: [],
      },
      isChartLoading: false,
      finalLoader:false,
      parameterId: "roomsOccupied",
      totalDataValue: 0,
      totalVariance: 0,
      showCheckBoxes: false,
      parameterList: [],
      periods: [],
      lastChartData: {
        labels: [],
        type: "line",
        datasets: [],
      }

    };
  }

  componentDidMount(): void {
    this.getDashboardParameters();
    this.setState({ chartPeriodValueChart:this.props.periodValue == 1? 2 :this.props.periodValue,comparisonPeriodValueChart: this.props?.selectedhotelIds?.length > 0 ? this.props.comparisonPeriodValue : 2 }, () => {
      this.getData();
    })

  }

  componentDidUpdate = (prevProps) => {
  
    if(prevProps.drillDownArray  !== this.props.drillDownArray ||
       prevProps.hotelIds  !== this.props.hotelIds ||
       prevProps.calendarDate  !== this.props.calendarDate || 
       prevProps.comparisonPeriodValue  !== this.props.comparisonPeriodValue || 
       prevProps.selectedhotelIds  !== this.props.selectedhotelIds || 
       prevProps.selectedColumn  !== this.props.selectedColumn ||
       prevProps.periodValue  !== this.props.periodValue ||
       !_.isEqual(prevProps.toggleColumnData,this.props.toggleColumnData)
       
       ){

        // if (!_.isEqual(

        //   _.omit(prevProps, ["onComparisonPeriodChange", "updateTableSelectedData", "onChangeMetricParameter"]), _.omit(this.props, ["onComparisonPeriodChange", "updateTableSelectedData", "onChangeMetricParameter"]))
    
    
        // ) {
          const item = this.state.parameterList.find(item => item.label === this.props.selectedColumn)
    
          if (item) {
            this.setState({ parameterId: item?.value })
          }
    
          const isDrillDown=!_.isEqual(prevProps?.drillDownArray, this.props?.drillDownArray)
        if(isDrillDown) {
          this.setState({ lastChartData: {
            labels: [],
            type: "line",
            datasets: [],
          }, chartData: {
            labels: [],
            type: "line",
            datasets: [],
          }});
        }
    
          this.setState({chartPeriodValueChart:this.props.periodValue == 1? 2 :this.props.periodValue, comparisonPeriodValueChart: this.state.comparisonPeriodValueChart === -1 ? -1: this.props.comparisonPeriodValue }, () => {
            if ( !_.isEqual(prevProps?.selectedColumn, this.props?.selectedColumn)) {
                this.getData(false,null , true, true);
             
              // if (this.state.chartResponseData?.length > 0 || this.props?.drillDownArray?.length>1) {
              //   this.buildChartData(true);
              // }
              // else {
              //   this.getData(true);
              // }
            }
            else if(!_.isEqual(prevProps?.selectedhotelIds, this.props?.selectedhotelIds)){
              if (this.state.chartResponseData?.length > 0 && this.props?.drillDownArray?.length>1) {
                this.buildChartData(true);
              }
              else {
                this.getData(false, prevProps?.selectedhotelIds,true);
              }
            }
            else {
              if(_.isEqual(prevProps.toggleColumnData,this.props.toggleColumnData)) {
                this.getData(true);
                }
            }
    
          });

          const _item = this.state.parameterList.find(item => item.value  === this.state.parameterId)
         
          if( (this.props.toggleColumnData?.filter(x=>x?.colDispalyName ===_item?.label && x?.checked)?.length ===0  &&  prevProps.selectedColumn  === this.props.selectedColumn 
         )
          || (this.props.selectedColumn === null )
          
          )
              {
            this.setState({
            chartData: {
                labels: [],
                type: "line",
                datasets: [],
              },
              legendList: [],
          totalDataValue: 0,
          totalVariance: 0,
          parameterId:"" 
          
            });
          //  this.onChartNameChange({ label: "Select Metric", value: "" })
            
              }
    
        // }
       }
   
  };






  getData = (isFilterApplied = false,previousSelectedHotelIds=null, isSelectedHidChange=false,isColumnChange=false) => {
    const _periods = getPeriods(this.props.calendarDate)
    this.setState({ periods: _periods }, () => {
      this.getChartData(isFilterApplied,previousSelectedHotelIds,isSelectedHidChange,isColumnChange);

    })
  }



  getOptions = (): any => {
    const outerScope = this;
    let checkboxYes = new Image(14, 14);
    checkboxYes.src = '/static/img/check_yes.png';
    let checkboxNo = new Image(14, 14);
    checkboxNo.src = '/static/img/check_no.png';
    const state = this.state;
    const options = {
      maintainAspectRatio: false,
      // animation:{
      //   duration:0
      // },
      animation:true,
      elements: {
        line: {
          cubicInterpolationMode: "monotone",
        },
        point: {
          radius: 0
        }
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      responsive: true,

      plugins: {
        //   labels: {
        //     usePointStyle: true,
        //     generateLabels: function(chart) {
        //       const  labels = chart.defaults.plugins.legend.labels.generateLabels(chart);
        //         for (var key in labels) {
        //             labels[key].pointStyle = labels[key].hidden ? checkboxNo : checkboxYes;
        //         }
        //         return labels;
        //       }
        // },
        //   legend: {
        //   legendCallback: function(chart) { 
        //     var ul = document.createElement('ul');
        //     var borderColor = chart.data.datasets[0].borderColor;
        //     chart.data.labels.forEach(function(label, index) {
        //        ul.innerHTML += `
        //          <li>
        //              <span style="background-color: ${borderColor[index]}"></span>
        //              ${label } amit
        //           </li>
        //        `; // ^ ES6 Template String
        //     });
        //     return ul.outerHTML; 
        //   },
        // },

        legend: false,
        // legend: {

        //   // onClick: (event, legendItem, legend, legendList) => {
        //   //   let activeLegendLabels: any[] = legend.legendItems.filter(x => !x.hidden).map(x => x.text);


        //   //   const index = legendItem.datasetIndex;
        //   //   const ci = legend.chart;
        //   //   if (ci.isDatasetVisible(index)) {
        //   //     ci.hide(index);
        //   //     legendItem.hidden = true;
        //   //     activeLegendLabels = activeLegendLabels?.filter(x => x !== legendItem.text);
        //   //   } else {
        //   //     ci.show(index);
        //   //     legendItem.hidden = false;
        //   //     activeLegendLabels?.push(legendItem.text);
        //   //   }



        //   //   const letterCodes: string[] = outerScope.state.chartData.datasets.filter(x => activeLegendLabels?.includes(x?.label))
        //   //     ?.map(x => x?.label?.toString());

        //   // },
        //   position: "bottom",
        //   display: true,
        //   labels: {
        //     filter: function (legendItem, data) {

        //       return true;


        //       // if ((!legendItem.text?.toString()?.includes("#")) || outerScope.state.chartData.datasets?.filter(x=>x.label === legendItem.text?.toString()?.replace("#",""))?.length ===0) {
        //       //   return true; //only show when the label is cash
        //       // } else {
        //       //   return false;
        //       // }
        //     },
        //     usePointStyle: true,
        //     boxWidth: 6,
        //   },
        // },

        tooltip: {
          // Disable the on-canvas tooltip
          enabled: false,
          intersect: false,
          mode: "point",
          position: "nearest",
          external: function customTooltips(context) {
            const tooltipModel = context.tooltip;
            var tooltipEl: any = document.getElementById(`chartjs-tooltip`);


            const yAlign = tooltipModel.yAlign;
            const xAlign = tooltipModel.xAlign;

            function removeToolTip() {
              var tooltipEl: any = document.getElementById("chartjs-tooltip");
              if (tooltipEl) {
                tooltipEl?.remove();
              }
            }
            if (tooltipEl) {
              tooltipEl.focus();
              tooltipEl?.addEventListener("mouseleave", function () {
                removeToolTip();
              });
              scrollRef.current.addEventListener("scroll", function () {
                removeToolTip();
              });
            }
            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = "chartjs-tooltip";
              tooltipEl.innerHTML = "<table></table>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove(
              "top",
              "bottom",
              "center",
              "left",
              "right"
            );
            if (tooltipModel.yAlign || tooltipModel.xAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
              tooltipEl.classList.add(tooltipModel.xAlign);
            }


            function getVarianceClass(variance) {
              return +variance === 0
                ? ""
                : variance?.toString()?.includes("-")
                  ? ""
                  : "show-neg-variance";
            }
            function getVarianceSymbol(variance) {
              return +variance === 0
                ? ""
                : variance?.toString()?.includes("-")
                  ? ""
                  : "-";
            }
            function getBody(bodyItem) {
              return bodyItem.lines;
            }
            function getDateBasedOnMatchDateValue(
              periodValue: number,
              date: any,
              isSubtract = false
            ) {
              let periodFrom: any = moment(date);
              const formattedDate = moment(date)?.format("MM/DD/YY");
              if (isSubtract) {
                switch (periodValue) {
                  case 1:
                    periodFrom = moment(formattedDate).subtract(1, "months");
                    break;
                  case 2:
                    periodFrom = moment(formattedDate).subtract(2, "months");
                    break;
                  case 3:
                    periodFrom = moment(formattedDate).subtract(1, "months");
                    break;
                  case 4:
                    periodFrom = moment(formattedDate).subtract(3, "months");
                    break;
                  case 5:
                    periodFrom = moment(formattedDate).subtract(6, "months");
                    break;
                  case 6:
                    periodFrom = moment(formattedDate).subtract(12, "months");
                    break;
                  default:
                    break;
                }
              } else {
                switch (periodValue) {
                  case 1:
                    periodFrom = moment(formattedDate).add(1, "weeks");
                    break;
                  case 2:
                    periodFrom = moment(formattedDate).add(2, "weeks");
                    break;
                  case 3:
                    periodFrom = moment(formattedDate).add(1, "months");
                    break;
                  case 4:
                    periodFrom = moment(formattedDate).add(3, "months");
                    break;
                  case 5:
                    periodFrom = moment(formattedDate).add(6, "months");
                    break;
                  case 6:
                    periodFrom = moment(formattedDate).add(12, "months");
                    break;
                  default:
                    break;
                }
              }
              return periodFrom.format("MM/DD/YY");
            }

            // Set Text

            if (tooltipModel?.body) {
              let titleLines = tooltipModel.title || [];
              let bodyLines = tooltipModel.body?.map(getBody);
              let innerHtml = "";

              //  innerHtml = "<thead>";
              // titleLines.forEach(function (title) {
              //   innerHtml += "<tr><th>" + title + "</th></tr>";
              // });
              // innerHtml += "</thead>";

              innerHtml += "<tbody>";
              const { chartResponseData, chartPeriodValueChart } = state;


              let labelName = "Hotel";
              //  let params =

              const dataPoints = tooltipModel?.dataPoints?.filter(
                (item) =>
                  item?.element?.x === tooltipModel?.caretX &&
                  item?.element?.y === tooltipModel?.caretY
              );
              // const index  = tooltipModel?.dataPoints?.findIndex(item => item?.x ===
              //  tooltipModel?.caretX &&   item?.y === tooltipModel?.caretX)
              dataPoints.forEach(function (data, i) {
                let tickLabel =  data?.dataset?.label;
                let date = data?.label;

                let groupedData: any[] = [];
                let dataSetValue = tickLabel?.toString()?.includes("#") ? 2 : 1;
                let _chartResponseData: any[] = chartResponseData.filter(
                  (x) => +x.dataSet === dataSetValue
                );
                let  _tickLabel = tickLabel?.toString()?.split(":")[0]?.toString()?.replace("#", "");

                // groupedData = _chartResponseData.filter(
                //   (r) =>
                //     r?.hotelCode ===
                //      tickLabel?.toString()?.split(":")[0]?.toString()?.replace("#", "") &&
                //      (chartPeriodValueChart == 4 || chartPeriodValueChart) == 5 ? moment(r.dataDate).format("MMM YY") 
                //      :  moment(r.dataDate).format("ddd M/D") === date
                // );


                // let totalActual = 0;
                // if (groupedData?.length > 0) {
                //   totalActual = groupedData
                //     ?.map((r) => +r.dataValue)
                //     .reduce((a, b) => a + b) || +(tickLabel?.toString()?.split(":")[1] || 0);
                // }

                let totalActual = data?.raw;

                // const variance = outerScope.getCalculatedVariance(
                //   totalActual,
                //   totalBudget
                // );

                //var style = 'background:' + colors.backgroundColor;

                let style = "background:" + data?.dataset?.backgroundColor;;
                style += "; border-color:" + data?.dataset?.backgroundColor;;
                style += "; border-width: 2px";
                style += "; height: 13px";
                style += "; width: 13px";
                style += "; margin-right: 6px";

                let formattedDate = moment(titleLines[0]).format("ddd M/D");

                // if (dataSetValue === 2) {
                //   formattedDate = moment(groupedData[0]?.dataCompareDate).format("MM/DD/YY");
                // }
                if(!(dataSetValue === 2 && state.comparisonPeriodValueChart ===-1)) {
           
                  const isNumber = [
                    "hskRoomsCleaned",
                    "roomsOccupied",

                    "roomClean",
                    "occupiedRoom"
                  ]
  
               
  
                  if (i === 0) {
                    innerHtml += "<tr><td><b>" + tooltipModel?.dataPoints[i]?.label + "</b></td></tr>";
                  }
  
                 
                  innerHtml += "<tr><td style='display:flex; align-items:center;'>" +
                  `<div style="${style}"></div>` +
                  _tickLabel + (dataSetValue === 2 ? "#" : "") +
                  `: ${isNumber.includes(state.parameterId) ?  Number(totalActual)?.toFixed(0) : Utils.getMinutesInHHMMFormat(Utils.getMinutesFromHHMMDOT(totalActual?.toString() || 0))}` + 
                  "</td></tr>"
                   
                  }
                });
                


              

              innerHtml += "</tbody>";

              var tableRoot = tooltipEl.querySelector("table") as any;
              tableRoot.innerHTML = innerHtml;
            }


            // Tooltip height and width
            const { height, width } = tooltipEl.getBoundingClientRect();

            // Chart canvas positions
            const positionY = chartRef.current.canvas.offsetTop;
            const positionX = chartRef.current.canvas.offsetLeft;

            // Carets
            const caretY = tooltipModel.caretY;
            const caretX = tooltipModel.caretX;

            // Final coordinates
            let top = positionY - scrollRef.current.scrollTop + caretY - height;
            let left = positionX + window.pageXOffset + caretX - width / 2;
            let space = 8; // The caret plus one pixle for some space, you can increase it.

            // yAlign could be: `top`, `bottom`, `center`
            if (yAlign === "top") {
              top += height + space;
            } else if (yAlign === "center") {
              top += height / 2;
            } else if (yAlign === "bottom") {
              top -= space;
            }

            // xAlign could be: `left`, `center`, `right`

            if (xAlign === "left") {
              left += width / 2 - 6 - space / 2;
              if (yAlign === "center") {
                left += space * 2;
              }
            } else if (xAlign === "right") {
              left -= width / 2;
              if (yAlign === "center") {
                left -= space;
              } else {
                left += space;
              }
            }

            // var position = context.chart.canvas.getBoundingClientRect();
            var position = chartRef.current.canvas.getBoundingClientRect();
            // tooltipEl.style.opacity = 1;
            // tooltipEl.style.position = 'absolute';
            // tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            // //tooltipEl.style.font = bodyFont.string;
            // tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
            // tooltipEl.style.pointerEvents = 'none';

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;

            // Left and right
            //tooltipEl.style.top = `${top}px`;
            tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;

            // Font
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;

            // Paddings
            tooltipEl.style.padding = "6px 6px";
            //tooltipModel.yPadding + "10px " + tooltipModel.xPadding + "px";
          },
        },
      },
      scales: {
        y: {
          ticks: {
            // color: function (a, b, fontColor) {
            //   if (a.tick.value > 0) return "red";
            // },
            callback: function (value, index, values) {

              const isNumber = [
                "hskRoomsCleaned",
                "roomsOccupied",


                "roomClean",
                "occupiedRoom"
              ]

         

              if (
                isNumber.includes(state.parameterId)
              ) {
               if (+value > 10 || +value < -10 || +value == 0) {
                  return Utils.currencyFormatWithOutdollerYAxis(value);
                }
                return Utils.currencyFormatWithOutdoller(value);
              }
              else {
                return Utils.getMinutesInHHMMFormat(Utils.getMinutesFromHHMMDOT(value?.toString() || 0))
              }

            },
          },
        },

      },
    };

    return options;
  };
  getTicksLimitBasedOnPeriodValue = (periodValue: number): number => {
    let ticks = 7;
    switch (periodValue) {
      case 2:
        ticks = 7;
        break;
      case 3:
        ticks = 12;
        break;
      case 4:
        ticks = 12;
        break;
      case 5:
        ticks = 12;
        break;
      case 6:
        ticks = 12;
        break;
      default:
        break;
    }
    return ticks;
  };







  getElementAtEvent = async (element) => {
    setTimeout(() => {
      $("#chartjs-tooltip").remove()
    }, 50);

    
    var tooltipEl = document.getElementById("chartjs-tooltip") as any;
    if (tooltipEl) {
      tooltipEl.style.opacity = "0";
    }



    var tooltipEl = document.getElementById("chartjs-tooltip") as any;
    if (tooltipEl) {
      tooltipEl.style.opacity = "0";
    }
    const { chartStage } = this.state;
    const {drillDownArray,handleDrillDown,handlePreviousPath } =this.props;


    if (!element.length || chartStage >= 3) return;
    const nextChartStage = chartStage + 1;
    const { _datasetIndex: datasetIndex, _index: index } = element[0];
    const label = this.state.chartData.datasets[element[0].datasetIndex].label?.replace("#","");
   
 const data = {};

 let { chartOriginalData: result} = this.state;


      if(drillDownArray?.length==0) {
        handleDrillDown( result?.find(x=>x?.lettercode === label),0)
      } else if(drillDownArray?.length==2)  {
        const data= result?.find(x =>x?.userName === drillDownArray[1]?.name  );		
        const calendarDate = this.state.chartDates[element[0].index].value;		
          this.props?.handleHousekeeperSlideOut(data,calendarDate);
      }
     


    

    const groupId =
      this.state.chartData.datasets[element[0].datasetIndex].groupId;
   
  };

  getPeriodBackDateBasedOnValue = (periodValue: number, calendarDate: string): string => {
    let periodFrom: any = moment();
    switch (periodValue) {
      case 1:
        periodFrom = moment(calendarDate).subtract(6, "days");
        break;
      case 2:
        periodFrom = moment(calendarDate).subtract(13, "days");
        break;
      case 3:
        periodFrom = moment(calendarDate).startOf("month");
        break;
      case 4:
        periodFrom = moment(calendarDate).subtract(1, "year");
        break;
      case 5:
        periodFrom = moment(calendarDate).subtract(12, "months");
        break;
      default:
        break;
    }
    return periodFrom.format("MM/DD/YY");
  };

  buildChartData = (isFilterApplied:boolean = false) => {
    let { chartOriginalData: result} = this.state;
    const {drillDownArray} = this.props;
    this.setState({isChartLoading: (this.state?.chartOriginalData|| [])?.length >0  ?false: true})
    const chartData: any = {
      labels: [],
      type: "line",
      datasets: [],
    };
  
    const mainData: any[] = [];
  
    let drilldwonLevel: number = drillDownArray.length;
    let hidValueKey = "";
    let hotelCodeValueKeyName = "";
    let hotelNameValueKeyName = "";
    let dataDateKey=""
    if (drilldwonLevel === 2) {
      hidValueKey = "user_UniqueID";
      hotelCodeValueKeyName = "userName"
      hotelNameValueKeyName = "userName"
      dataDateKey="date"

      result = result.map( (item) => {

        item.occupiedRoom = Number(item.occupiedRoom || 0);
        item.roomClean = Number(item.roomClean || 0);
        item.totalCleanTime = Number(item.totalCleanTime || 0);
        item.mpcr=    Number( item.mpcr || 0)  ;
      

        return item;

      })
    
    }
    else
     {
        hidValueKey = "hotelid";
        hotelCodeValueKeyName = "lettercode";
        hotelNameValueKeyName = "hotelName";
        dataDateKey="datadate"

        result = result.map( (item) => {

          item.roomsOccupied = Number(item.roomsOccupied || 0);
          item.hskRoomsCleaned = Number(item.hskRoomsCleaned || 0);
          item.hskhskTotalCleanTime = Number(item.hskhskTotalCleanTime || 0);
          item.hskmpcr=    Number( item.hskmpcr || 0)  ;
        
  
          return item;
  
      })

  
      }
  
    const uniqueGroups = _.groupBy(result, v => `"${v[hidValueKey]}+${v[dataDateKey]}+${v.valueActual}"`)
  
    const sumOfUniuqArray =Object.keys(uniqueGroups).map(item => drilldwonLevel ===2 ? sumOfArrayHouseKeeper(uniqueGroups[item]): sumOfArray(uniqueGroups[item]))

  
    const sortedData = _.sortBy(sumOfUniuqArray, dataDateKey);
    const mainValues = (sortedData || [])?.map((x, i) => {
      const newItem: any = {};
      newItem.rowId = i;
      newItem.hid = x[hidValueKey];
      newItem.hotelCode = x[hotelCodeValueKeyName];
      newItem.hotelName = x[hotelNameValueKeyName];
      newItem.dataDate = x[dataDateKey];
      newItem.dataCompareDate = x[dataDateKey];
      newItem.dataValue = x[this.state.parameterId];
      newItem.dataSet = x?.valueActual === "Actual" ? 1 : 2;
      return newItem;
    });
    mainData.push(...mainValues);
  
  
  
  
    if (!this.props.hotelIds) {
      this.setState({
        chartData,
        originalData: _.cloneDeep(chartData),
        isChartLoading: false,
      });
      return;
    }
    let totalDataValue = 0;
    let totalVariance = 0;
    if (mainData?.length > 0) {
      let chartResponseData = _.flatten(mainData);
  
      totalDataValue = (result || []).map(x => x.datavalue).reduce((a, b) => a + b, 0);
      totalVariance = (result || []).map(x => x.variance).reduce((a, b) => a + b, 0);
      const mappedData = (chartResponseData || [])?.map((item) => {
        item.dataDate = moment(item.dataDate).format("MM/DD/YY");
        item.dataCompareDate = moment(item.dataDate).format("MM/DD/YY");
        return item;
      });
      this.setState({ chartResponseData: mappedData, totalDataValue, totalVariance }, () => {
        this.getChartDataBasedOnMode(isFilterApplied);
      });
    }
    else{
      this.setState({isChartLoading:false, chartData: {
        labels: [],
        type: "line",
        datasets: [],
      },chartResponseData:[]})
    }
  }

  getChartData = async (
    isFilterApplied: boolean = false,
    previousSelectedHotelIds: string | null=null,
    isSelectedHidChange:boolean =false,
    isColumnChange:boolean=false
  ) => {
    let chartData:any={}
    if((this.state?.chartOriginalData|| [])?.length >0)
    {
      chartData= this.state.chartData
    }
    else{
      chartData= {
        labels: [],
        type: "line",
        datasets: [],
      };
    }
   
 
    const { parameterId, chartPeriodValueChart, comparisonPeriodValueChart } = this.state;
    const { calendarDate, hotelIds, selectedhotelIds,drillDownArray } = this.props
    if(hotelIds)
    {
      this.props.handleMainChartLoading(true)
    }
    // let hotelIdsParam=hotelIds
    // if(selectedhotelIds.length ==0){
    //   hotelIdsParam=[]
    // }
    let HID=hotelIds
    const previousHID=previousSelectedHotelIds?.split(",") || []
    const selectedHID=this.props?.selectedhotelIds ? this.props?.selectedhotelIds?.split(",") : []
    const isFilterOut=previousHID?.length == 0 && selectedHID?.length == 0 ? false: previousHID?.length <= selectedHID?.length
    
    if(!isColumnChange && isSelectedHidChange && drillDownArray.length ==0){
     
      let diffItems=_.difference(selectedHID,previousHID)
     
      diffItems=diffItems.length > 0 ? diffItems.join(",") :null
      HID=diffItems ? diffItems :  hotelIds


    }

    const request: any = drillDownArray.length == 0 ? {
      HID: HID,
      DataDate: calendarDate,
      DailyFlashType: comparisonPeriodValueChart,
      TimePeriod: chartPeriodValueChart
    }:{
      Hotelid: drillDownArray[1].id,
      SelectedDate: calendarDate,
      DailyFlashType: comparisonPeriodValueChart,
      TimePeriod: chartPeriodValueChart 
  }

    this.setState({
      isChartLoading:(this.state?.chartOriginalData|| [])?.length >0 ?false: isFilterOut, legendList: [], chartData,
      finalLoader:isFilterOut,
      totalDataValue: 0,
      totalVariance: 0
    });
    try {
      const mainData: any[] = [];
      const splittedSelectedHotelIds = selectedhotelIds?.toString()?.split(",") || [];
      let drilldwonLevel: number = drillDownArray.length;
      let result:any[]=[]

      if(drillDownArray.length ===0)
      {
        result=_.cloneDeep(this.state.chartOriginalData)|| []
        if(previousHID?.length <= selectedHID?.length)
        {
          let resultData =  await LaborDashBoard.getHouseKeepingDashboardDataChart(request)
       
          if(!isColumnChange && isSelectedHidChange && drilldwonLevel ==0)
          {
            result=[...result,...resultData]
          }
          else{
            result=[...resultData]
          }
        }
        else if(!isColumnChange && isSelectedHidChange){
          let diffItems=_.difference(previousHID,selectedHID)
          result=result.filter(x =>!diffItems.includes(x.hotelid+"") )
          
        }
      }
      else{
        result=await LaborDashBoard.getHouseKeepingDashboardUserDataChart(request) 
      }
    


      // let result=drillDownArray.length ===2 ? await LaborDashBoard.getHouseKeepingDashboardUserDataChart(request) :  await LaborDashBoard.getHouseKeepingDashboardDataChart(request)
      // let result = (await LaborDashBoard.getLaborDashboardDataChart(request)) as any;
      this.setState({ chartOriginalData: result });
    
      let hidValueKey = "";
      let hotelCodeValueKeyName = "";
      let hotelNameValueKeyName = "";
      let dataDateKey=""
      if (drilldwonLevel === 2) {
        hidValueKey = "user_UniqueID";
        hotelCodeValueKeyName = "userName"
        hotelNameValueKeyName = "userName"
        dataDateKey="date"

            //Grouped data  with respect to groupedBy
        result = result.map( (item) => {

          item.occupiedRoom = Number(item.occupiedRoom || 0);
          item.roomClean = Number(item.roomClean || 0);
          item.totalCleanTime = Number(item.totalCleanTime || 0);
          item.mpcr=    Number( item.mpcr || 0)  ;
        

          return item;

        })
      
      }
      else
      {
          hidValueKey = "hotelid";
          hotelCodeValueKeyName = "lettercode";
          hotelNameValueKeyName = "hotelName";
          dataDateKey="datadate"

          result = result.map( (item) => {

            item.roomsOccupied = Number(item.roomsOccupied || 0);
            item.hskRoomsCleaned = Number(item.hskRoomsCleaned || 0);
            item.hskhskTotalCleanTime = Number(item.hskhskTotalCleanTime || 0);
            item.hskmpcr=    Number( item.hskmpcr || 0)  ;
          
    
            return item;
    
        })

      }



   

      const uniqueGroups = _.groupBy(result, v => `"${v[hidValueKey]}+${v[dataDateKey]}+${v.valueActual}"`)

      const sumOfUniuqArray =Object.keys(uniqueGroups).map(item => drilldwonLevel ===2 ? sumOfArrayHouseKeeper(uniqueGroups[item]): sumOfArray(uniqueGroups[item]))
      console.log({sumOfUniuqArray})
      const sortedData = _.sortBy(sumOfUniuqArray, dataDateKey);
      const mainValues = (sortedData || [])?.map((x, i) => {
        const newItem: any = {};
        newItem.rowId = i;
        newItem.hid = x[hidValueKey];
        newItem.hotelCode = x[hotelCodeValueKeyName];
        newItem.hotelName = x[hotelNameValueKeyName];
        newItem.dataDate = x[dataDateKey];
        newItem.dataCompareDate = x[dataDateKey];
        newItem.dataValue = x[this.state.parameterId];
        newItem.dataSet = x?.valueActual === "Actual" ? 1 : 2;
        return newItem;
      });
      mainData.push(...mainValues);




      if (!this.props.hotelIds) {
        this.props.handleMainChartLoading(false)
        this.setState({
          chartData,
          originalData: _.cloneDeep(chartData),
          isChartLoading: false,
          finalLoader:false,
        });
        return;
      }
      let totalDataValue = 0;
      let totalVariance = 0;
      if (mainData?.length > 0) {
        let chartResponseData = _.flatten(mainData);

        totalDataValue = (result || []).map(x => x.datavalue).reduce((a, b) => a + b, 0);
        totalVariance = (result || []).map(x => x.variance).reduce((a, b) => a + b, 0);
        const mappedData = (chartResponseData || [])?.map((item) => {
          item.dataDate = moment(item.dataDate).format("MM/DD/YY");
          item.dataCompareDate = moment(item.dataDate).format("MM/DD/YY");
          return item;
        });
        this.setState({ chartResponseData: mappedData, totalDataValue, totalVariance }, () => {
          this.getChartDataBasedOnMode(isFilterApplied,isColumnChange);
        });
      } else {
        this.props.handleMainChartLoading(false)
        this.setState({ isChartLoading: false });
      }
    } catch (error) {
      console.log({ error })
      if ((error as any)?.message === "Cancel") {
        this.setState({ isChartLoading: (this.state?.chartOriginalData|| [])?.length >0  ? false: true,finalLoader:true, });
      } else {
        this.props.handleMainChartLoading(false)
        this.setState({ isChartLoading: false,finalLoader:false, });
      }
    } finally {

    }
  };


  getDashboardParameters = async () => {
    this.setState({
      parameterList: [
        { label: "Occupied", value: "roomsOccupied" },
        { label: "Rooms Cleaned", value: "hskRoomsCleaned", },
        { label: "Total Clean Time", value: "hskTotalCleanTime", },
        { label: "MPCR", value: "hskmpcr" },
      
      ]
    }, () => {
      const item = this.state.parameterList.find(item => item.label === this.props.selectedColumn)

      if (item) {
        this.setState({ parameterId: item?.value })
      }
    });
  }


  getLabelsDateArray = (
    _fromDate: string,
    _toDate: string
  ): string[] => {

    // if(comparisonPeriodValue>0) {
    //   fromDate =  moment(this.getDataComparisonBackDateBasedOnValue(periodValue,comparisonPeriodValue)[0]);
    // }
    const fromDate = moment(_fromDate);
    const toDate: any = moment(_toDate);
    const days = toDate.diff(fromDate, "days");
    const dates: any = [fromDate.format("MM/DD/YY")];
    for (let i = 0; i < days; i++) {
      dates.push(fromDate.add(1, "days").format("MM/DD/YY"));
    }
    return dates;
  };


  getChartDataBasedOnMode = (isFilterApplied = false, isColumnChange=false) => {
    //;
    let chartData: any = {
      labels: [],
      type: "line",
      datasets: [],
    };
    const { calendarDate, selectedhotelIds } = this.props
    let { chartResponseData, chartPeriodValueChart, comparisonPeriodValueChart, lastChartData } = this.state;
    let _chartResponseData = [...chartResponseData];
    const fromDate = this.getPeriodBackDateBasedOnValue(chartPeriodValueChart, calendarDate);
    const toDate = calendarDate;
    // const labelDates: string[] =  this.getLabelsDateArray(
    //   fromDate,
    //   toDate
    // );

    const labelDates: string[] = _.uniq(_chartResponseData.map(x => x.dataDate))
    chartData.labels = labelDates?.map((item) =>
      moment(item).format("MM/DD/YY")
    );


    const splittedSelectedHotelIds = selectedhotelIds?.toString()?.split(",") || [];

    if (lastChartData?.length > 0) {
      lastChartData = (lastChartData || [])?.map(x => {
        if ((splittedSelectedHotelIds || [])?.find(y => y == x?.groupId)) {
          x.checked = true;
        }
        else {
          x.checked = false;
        }
        return x;
      });

    } 

    const chartDates = labelDates?.map((item) => {
      const newItem: any = {};
      newItem.label = moment(item).format("MM/DD/YY");
      newItem.value = moment(item).format("MM/DD/YY");
      return newItem;
    });

    let groupsWithIds: any[] = [];


    groupsWithIds = _chartResponseData?.map((item) => ({
      labelName: item.hotelCode,
      hotelName: item.hotelName,
      id: item.hid,
      dataSet: item.dataSet,
    }));

    const uniqueGroups = _.uniqBy(groupsWithIds, (v) =>
      [v.labelName, v.dataSet].join()
    );


    const colorMapper={}
    var colorIndex=0
    chartData.datasets = uniqueGroups?.map((group, i) => {
      if(!colorMapper[group.id])
      {
        colorMapper[group.id]= this.chartFixedLineColors[colorIndex] 
        colorIndex=colorIndex+1
      }

      let color = i < MAXLIMIT*2 ?  colorMapper[group.id]: `rgb(${rand()}, ${rand()}, ${rand()})`;

      // const color =  `rgb(${rand()}, ${rand()}, ${rand()})`;
      const newItem: any = {
        type: "line",
        label: +group.dataSet === 2 ? group.labelName + "#" : group.labelName,
        hotelName: group.hotelName,
        groupId: group.id,
        borderWidth: 2,
        backgroundColor: color,
        borderColor: color,
        fill: false,
        checked: false,
        borderDash: +group.dataSet === 2 ? [5, 5] : [0, 0],
        hidden:!splittedSelectedHotelIds.includes(group.id+""),
        data: chartData.labels?.map((item: any) => {
          //;
          const varianceData: any = _chartResponseData.filter(
            (r) =>
              r.dataDate === item &&
              r?.hotelCode === group.labelName &&
              r.dataSet === group.dataSet
          );

          return varianceData[0]?.dataValue || 0;
        }),
      };
      return newItem;
    })?.filter(x => x);

    chartData.datasets = (chartData.datasets || [])?.map((item: any, i) => {
      if (item?.label?.toString()?.includes("#")) {
        const dataItem = (chartData.datasets || []).find(
          (x) => x?.label === item?.label?.replace("#", "")
        );
        if (dataItem?.backgroundColor) {
          item.backgroundColor = this.shadeColor(dataItem?.backgroundColor, 60);
          item.borderColor = this.shadeColor(dataItem?.backgroundColor, 60);
        }
      }
      return item;
    });
    let legendList = (chartData.datasets || [])?.map((item) => {
      let legObj: any = {};
      legObj.value = item.groupId;
      legObj.description = item.label?.replace("#", "");
      legObj.color = item.backgroundColor;
      legObj.isChecked = true;
      legObj.hotelID = item.groupId;
      return legObj;
    })
    legendList = _.uniqBy(legendList, "description");
    if(chartPeriodValueChart == 4){	
      chartData.labels = labelDates?.map((item,index) =>  `Day ${index+1}`   );	
    }	
    else{	
      chartData.labels = labelDates?.map((item) =>	
      chartPeriodValueChart == 6 || chartPeriodValueChart == 7 ? moment(item).format("MMM YY") : moment(item).format("ddd M/D")	
      );	
    }

    const splittedData = selectedhotelIds?.toString()?.split(",");

    if (splittedData?.length > 0) {

      if (isFilterApplied) {
        if(lastChartData?.datasets?.length ===0) {
          lastChartData= chartData;
        }
        lastChartData.datasets = (lastChartData.datasets || [])?.map(x => {
          if (splittedData.filter(y => y == x.groupId)?.length > 0) {
            x.checked = true;
          } else {
            x.checked = false;
          }
          return x;
        });
      }
      else {
        // const previousSelectedCheckbox=  (lastChartData.datasets || [])?.map(x => x.groupId)
        chartData.datasets = (chartData.datasets || [])?.map(x => {
          const item = (lastChartData.datasets || [])?.find(y =>  y.groupId == x.groupId);

          if(item && !isColumnChange)
          {
            x.checked =item.checked
          }
          else if (splittedData.filter(y => y == x.groupId)?.length > 0) {
            x.checked = true;
          } else {
            x.checked = false;
          }
          return x;
        });
      }

    }

    if (isFilterApplied && (lastChartData.datasets || []).filter(x => x?.checked)?.length > 0) {
      chartData.datasets = (chartData.datasets || [])?.map((item: any, i) => {
        if ((lastChartData.datasets || []).filter(x => x?.checked).map(x => x.label)?.includes(item.label)) {
          const comparisonIndex = chartData.datasets?.findIndex(x => x.label === item.label + "#");
          if (comparisonIndex >= 0) {
            chartData.datasets[comparisonIndex].checked = true;
          }
          item.checked = true;

        }
        else {
          const comparisonIndex = chartData.datasets?.findIndex(x => x.label === item.label + "#");
          if (comparisonIndex >= 0) {
            chartData.datasets[comparisonIndex].checked = true;
          }
          item.checked = true;
        }
        return item;
      });

      legendList = (legendList || [])?.map((item: any, i) => {
        if ((lastChartData.datasets || []).filter(x => x?.checked).map(x => x.label)?.includes(item.label)) {
          item.checked = true;


        }
        else {
          item.checked = true;
        }
        return item;
      });
    };

    chartData.datasets = (chartData.datasets || [])?.map((item: any, i) => {
      if(item.label?.toString()?.includes("#") && comparisonPeriodValueChart === -1 ) {
       item.checked =  false;
      }
      return item;
     });

     
    this.setState({
      chartData,
      lastChartData: chartData,
      originalData: _.cloneDeep(chartData),
      chartDates,
      legendList: legendList,
      isChartLoading: false
    }, () => {
      setTimeout(() => {
        if (isFilterApplied && (lastChartData.datasets || []).filter(x => x?.checked)?.length > 0) {
          const ci = chartRef.current;
          if (ci) {
            (ci.config.data.datasets || [])?.forEach((item: any, index) => {

              const comparisonIndex = ci.config.data.datasets?.findIndex(x => x.label === item.label + "#");

              if ((lastChartData.datasets || []).filter(x => x?.checked)?.map(x => x.label)?.includes(item.label)) {

                if(
                  (item.label?.includes("#") &&
                  comparisonPeriodValueChart === -1) ||
                  (comparisonPeriodValueChart === 2 &&
                  item.label?.toString()?.endsWith('#') &&this.state.parameterId ==="hskRoomsCleaned")) {
                    ci.hide(index);
                  } else {
                    ci.show(index);
                  }
              

                if (comparisonIndex >= 0) {
                  if (comparisonPeriodValueChart !== -1 
                    ) {
                    ci.show(comparisonIndex);
                  } else {
                    ci.hide(comparisonIndex);
                  }
                }
              } else {
                ci.hide(index);
                if (comparisonIndex >= 0) {
                  ci.hide(comparisonIndex);
                }


              }
            });

          }
        }
        else{
          const ci = chartRef.current;
          if (ci) {
            (ci.config.data.datasets || [])?.forEach((item: any, index) => {

              const comparisonIndex = ci.config.data.datasets?.findIndex(x => x.label === item.label + "#");

              if ((chartData.datasets || []).filter(x => x?.checked)?.map(x => x.label)?.includes(item.label)) {
   if(comparisonPeriodValueChart === 2 &&
    item.label?.toString()?.endsWith('#') &&this.state.parameterId ==="hskRoomsCleaned") {
      ci.hide(index);
    } else {
      ci.show(index);
    }

                
                if (comparisonIndex >= 0) {
                  if (comparisonPeriodValueChart !== -1 ) {
                    ci.show(comparisonIndex);
                  } else {
                    ci.hide(comparisonIndex);
                  }
                }
              } else {
                ci.hide(index);
                if (comparisonIndex >= 0) {
                  ci.hide(comparisonIndex);
                }


              }
            });

          }
        }

        this.setState({finalLoader:false})   
        this.props.handleMainChartLoading(false)
      })
    });



  }





  shadeColor = (color: any, p: number = 90) => {
    if (!color) {
      return color;
    }
    const rgbIntArray: any[] = color
      .replace(/ /g, "")
      .slice(4, -1)
      .split(",")
      ?.map((e) => parseInt(e));

    const R = rgbIntArray[0];
    const G = rgbIntArray[1];
    const B = rgbIntArray[2];
    const curr_total_dark = (255 * 3) - (R + G + B);

    // calculate how much of the current darkness comes from the different channels
    const RR = ((255 - R) / curr_total_dark);
    const GR = ((255 - G) / curr_total_dark);
    const BR = ((255 - B) / curr_total_dark);

    // calculate how much darkness there should be in the new color
    const new_total_dark = ((255 - 255 * (p / 100)) * 3);

    // make the new channels contain the same % of available dark as the old ones did
    const NR = 255 - Math.round(RR * new_total_dark);
    const NG = 255 - Math.round(GR * new_total_dark);
    const NB = 255 - Math.round(BR * new_total_dark);

    const RO = ((NR.toString(16).length === 1) ? "0" + NR.toString(16) : NR.toString(16));
    const GO = ((NG.toString(16).length === 1) ? "0" + NG.toString(16) : NG.toString(16));
    const BO = ((NB.toString(16).length === 1) ? "0" + NB.toString(16) : NB.toString(16));

    const rgb = this.hexToRgb("#" + RO + GO + BO) as any;
    return "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")";
  }





  onChartPeriodChangeChart = (item) => {
    this.setState({ chartPeriodValueChart: item.value }, () => this.getChartData(true));
  }

  onComparisonPeriodChangeChart = (item) => {
    if (item.value !== -1) {
      this.props.onComparisonPeriodChange(item)
    }
    this.setState({ comparisonPeriodValueChart: item.value }, () => this.getChartData(true));
  }

  onChartNameChange = (item) => {
    this.props.onChangeMetricParameter(item)
    this.setState({ parameterId: item.value })
  }

  hexToRgb = (hex) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  };

  updateTableData = () => {
    // let { chartData } = this.state;
    // const selectedHidsInChart = _.uniq(chartData.datasets.filter(item => item.groupId && item.checked).map(item => item.groupId))

    // const selectedHeaderName = this.state.parameterList.find(item => item?.value === this.state?.parameterId)?.label

    // this.props.updateTableSelectedData([...selectedHidsInChart], selectedHeaderName)
  }

  
  isLimitValidation =(data)=>{
      

    const filterData=data.filter(x =>x?.checked)
    if(filterData.length  === (MAXLIMIT*2))
    {
        toast.success(
            "Please limit chart selections to 10 properties.",
             {
               position: toast.POSITION.BOTTOM_RIGHT,
               containerId: "laborDashBoard",
             }
           );

        return true
    }

    return false
}

  updateDataSetCheckboxes = (index, value, label, isFromDiv = false) => {

    let { chartData } = this.state;
    const datasets = _.cloneDeep(chartData?.datasets) || [];
    if (isFromDiv) {

      const ci = chartRef.current;;
      for (let i = 0; i < datasets.length; i++) {
        const comparisonIndex = datasets?.findIndex(l => l.label === label + "#");
        if (index !== i && comparisonIndex !== i) {
          if (ci) {
            if (ci.isDatasetVisible(i)) {
              ci.hide(i);
            }
          }
          chartData.datasets[i].checked = false;
        }
        else {
          if (ci) {
            if (!ci.isDatasetVisible(i)) {
              ci.show(i);
            }
          }
          chartData.datasets[i].checked = true;
        }

      }

      // if (chartData.datasets.filter(x => x.checked)?.length === 0 && chartData.datasets?.length > 1) {
      //   for (let i = 0; i < datasets.length; i++) {
      //     ci.show(i);
      //     chartData.datasets[i].checked = true;
      //   }

      // }
      this.setState({ chartData }, () => { this.updateTableData() });
    }
    else {
      if(value){
        if(this.isLimitValidation(chartData.datasets))
        {
          return
        }
      }

      chartData.datasets[index].checked = value;
      const comparisonIndex = chartData.datasets?.findIndex(x => x.label === label + "#");
      if (comparisonIndex >= 0) {
        chartData.datasets[comparisonIndex].checked = value;
      }

      const ci = chartRef.current;
      if (ci) {

        if (ci.isDatasetVisible(index) && !value) {
          ci.hide(index);
          if (comparisonIndex >= 0) {
            ci.hide(comparisonIndex);
          }
        } else {
          ci.show(index);
          if (comparisonIndex >= 0) {
            ci.show(comparisonIndex);
          }
        }

        // if (chartData.datasets.filter(x => x.checked)?.length === 0 && chartData.datasets?.length > 1) {
        //   for (let i = 0; i < datasets.length; i++) {
        //     ci.show(i);
        //     chartData.datasets[i].checked = true;
        //   }

        // }
      }
      this.setState({ chartData }, () => { this.updateTableData() });
    }
  }
  render() {

    const {
      comparisonPeriodValue,
      isChartLoading, chartData, parameterId, chartPeriodValueChart, comparisonPeriodValueChart,finalLoader,
      totalDataValue, totalVariance, showCheckBoxes, parameterList } = this.state


      // chartData.datasets=_.orderBy(chartData.datasets, ['label'],['asc']); 

    return (

      <div className="labor-dashboard userManagementModule labor-housekeeping-dashboard-chart mt-2">
        {/* <ToastContainer autoClose={3000} /> */}
        <div className="body-sec p-0" ref={scrollRef}>


          <div className="widget-area">

            <>


              <div className="daily-flash-trends-container chart-container">
               

                {/* <div className="per-variance-container d-flex">
                  <div className="variance"><span className="bold">{totalDataValue ? (parameterId === 1?  Utils.currencyFormatWithOutdoller(totalDataValue).replace(".00","") : parameterId === 4 ? totalDataValue?.toFixed(2) + " %" : Utils.currencyFormat(totalDataValue)) : "0.00"}</span> {this.chartNames.find((x) => x.value === parameterId)?.label || ""}</div>
                  <div className="variance"><span className="bold">{totalVariance ? ( parameterId === 1 ?  Utils.currencyFormatWithOutdoller(totalVariance).replace(".00","") :  parameterId === 4 ? totalVariance?.toFixed(2) + " %" : Utils.currencyFormat(totalVariance)) : "0.00"}</span> Variance to {dataComparisonList.find((x) => x.value === comparisonPeriodValueChart)?.label || ""}</div>
                </div> */}

                <div className="chartjs-wrapper mb-1">
                  {isChartLoading ? (
                  <div className="chart-overlay">
                  <ReactPageLoader useas={"laborDashboardGraph"} />
                 </div>
                  ) : (
                    <>
                      <Line
                        id={"chart"}
                        ref={chartRef}
                        height={100}
                        data={(chartData || [])}
                        options={this.getOptions()}
                        

                        getElementAtEvent={this.getElementAtEvent}
                      />
                    </>
                  )}
                  {/* {(finalLoader ||  this.props.isInitialDataDataLoading) && <div className="chart-overlay">
                   <ReactPageLoader useas={"laborDashboardGraph"} />
                  </div>} */}
                  {!isChartLoading && chartData?.datasets?.filter(x => x.checked)?.length === 0 && <div className="info-badge-absPos d-flex align-items-center justify-content-center">Select a Value to Begin</div>}
                </div>
                {!isChartLoading   && chartData?.datasets?.length > 0 &&
                  <div id="legend" className="legend-container eHidList row justify-content-center pl-4">
                    <div className="col-lg-11 mx-auto">
                    <ul className="text-center eHidList mb-0">
                      {
                        // (chartData.datasets.filter(x => !x?.label?.toString().endsWith("#")).length !== chartData.datasets.filter(x => x.checked && !x?.label?.toString().endsWith("#")).length) ?
                          (chartData.datasets || [])?.map((x, i) => {
                            const c = x.backgroundColor;
                            let rgb2hex = '#' + c.match(/\d+/g).map(x => (+x).toString(16).padStart(2, "0")).join``;
                            return !x.label?.toString()?.includes("#") ?
                              (
                                <li className={`form-check ${this.props.isInitialDataDataLoading ? "chart-checkbox-disable" :""}`}>

                                  <input className="form-check-input"
                                    onChange={(e) =>
                                      this.updateDataSetCheckboxes(i, e.target.checked, x.label)}
                                    checked={x.checked} style={{ backgroundColor: x.checked ? rgb2hex : "#fff", border: "1px solid #c7c8ca" }}
                                    id={x.label + i} type="checkbox"
                                  />
                                  <OverlayTrigger
                                    placement="top"
                                    defaultShow={false}
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id={"ehid-name"}>
                                        {x.hotelName}
                                      </Tooltip>
                                    }
                                  >
                                    <label className="form-check-label" >{x.label}  </label>
                                  </OverlayTrigger>
                                </li>) : <></>
                          }) 
                          // :

                      //     (chartData.datasets || [])?.map((x, i) => {
                      //       const c = x.backgroundColor;
                      //       let rgb2hex = '#' + c.match(/\d+/g).map(x => (+x).toString(16).padStart(2, "0")).join``;
                      //       return !x.label?.toString()?.includes("#") ?
                      //         (<div key={i} className="form-check d-flex" style={{ paddingLeft: '0' }}>
                      //           <div
                      //             onClick={(e) => this.updateDataSetCheckboxes(i, true, x.label, true)}
                      //             style={{ backgroundColor: rgb2hex, height: '12px', width: '12px', margin: '3px', borderRadius: '3px' }}>
                      //           </div>
                      //           <OverlayTrigger
                      //             placement="top"
                      //             defaultShow={false}
                      //             delay={{ show: 250, hide: 400 }}
                      //             overlay={
                      //               <Tooltip id={"ehid-name"}>
                      //                 {x.hotelName}
                      //               </Tooltip>
                      //             }
                      //           >
                      //             <label className="form-check-label" style={{ lineHeight: '18px' }}>
                      //               {x.label}
                      //             </label>
                      //           </OverlayTrigger>

                      //         </div>) : <></>
                      //     })
                      }
                    </ul>
                  </div>
                  </div>
                }
              </div>

            </>
          </div>

        </div>
      </div>
    )

  }
}
