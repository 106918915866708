import React from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import {
  ILogHistoryProps,
  ILogHistoryState,
  ICurrentFilter,
} from "../../../../../Common/Contracts/IScheduleLogHistory";
import { Schedule } from "../../../../../Common/Services/Schedule";
import {
  IScheduleChangeLogResponse,
  IScheduleChangeLogRequest,
} from "../../../../../Common/Contracts/ISchedule";
import { ChangeLogs } from "../../../../../Common/Components/ChangeLogs/ChangeLogs";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Spinner, ToastHeader, Tooltip } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
// import { UserSearchBox } from "../components/UserSearchBox";
import { schConversions } from "../commonFunctions/conversions";
import { ILaborScheduleEmployeeRequestDto, ILabourBulkSelectSchedule, ILabourScheduleRequestDto } from "../../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import profile from "../../../../../Common/Assets/Images/profile.png";
import $ from "jquery";
import { MoonIcon } from "../../../../../Common/Components/MoonIcon";
import TableAutocomplete from "../../../../../Common/Components/TableAutocomplete";
import '../../../../../Common/Assets/Styles/Modules/bulk-entry.scss';
import UserListAutoComplete from "../../../../../Common/Components/UserListAutoComplete";
import { Utils } from "../../../../../Common/Utilis";

const { SearchBar } = Search;

export class BulkEntryModal extends React.Component<any, any> {
  private pageName: string = "ScheduleLogHistory";
  private userSearchScroll: any;
  private searchBar: any;
  private userInputRef: any;
  private shiftSaveBtnRef: any;

  constructor(props) {
    super(props);
    this.userSearchScroll = React.createRef();
    this.searchBar = React.createRef();
    this.userInputRef = React.createRef();
    this.shiftSaveBtnRef = React.createRef();
  }
  state: any = {
    tableData: [],
    filteredData: [],
    tableColumns: [],
    title: "Bulk Shift Entry",
    keyFieldName: "uniqueno",
    isDataLoading: false,
    scheduleId: this.props.shiftId ? this.props.shiftId : 0,
    isDrillDownView: false,
    filterColumns: [],
    filterPopUps: [],
    filterPopUpsCopy: [],
    showFilterPopUp: false,
    popClassName: "",
    currentFilter: {
      filterName: "",
      filterValue: "",
      filterKeyName: "",
      filterInputType: "",
    },
    tableClassName: "schedule-log-table",
    startDate: this.props.payPeriodStartDate,
    endDate: this.props.payPeriodEndDate,
    pageSize: 20,
    currentPageNo: 1,
    totalDataLength: 0,
    drillDownShiftDate: this.props?.shiftDate,
    calenderShow: false,
    addNewRow: true,
    headerDays: [],
    headerFullDates: [],
    headerDates: [],
    positionList: [],
    navigationCalenderDates: this.props.parentState.navigationCalenderDates,
    payPeriodDates: [],
    calenderSelectDate: "",
    allEmployeeList: [],
    isActionFooter: false,
    currentIndex: "",
    fullWeekDays: {
      "Mon": "Monday",
      "Tue": "Tuesday",
      "Wed": "Wednesday",
      "Thu": "Thursday",
      "Fri": "Friday",
      "Sat": "Saturday",
      "Sun": "Sunday"
    },
    warningCount: 0,
    isWarning: false,
    positionIds: {},
    shiftButtonSpinner: false
  };

  hoverOutEllipsis = () => {
    $(".tooltip")?.removeClass("show");
    $(".tooltip")?.removeClass("fade");
  };

  cellTooltipFormatter = (cell: any, row: any, rowIndex: any) => {
    const text = cell?.toString();
    let substr = "";
    if (text?.length > 15) {
      substr = `${text.substr(0, 15)}...`;
    }
    return (
      <>
        {" "}
        {text?.length > 15 ? (
          <div onWheel={() => this.hoverOutEllipsis()}>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id={"PositionName"}>{cell}</Tooltip>}
            >
              <span>{substr}</span>
            </OverlayTrigger>
          </div>
        ) : (
          <div>{cell}</div>
        )}
      </>
    );
  };

  cellTooltipPositionFormatter = (cell: any, row: any, rowIndex: any) => {
    const text = cell?.toString();
    let substr = "";
    if (text?.length > 11) {
      substr = `${text.substr(0, 11)}...`;
    }
    return (
      <>
        {" "}
        {text?.length > 11 ? (
          <div onWheel={() => this.hoverOutEllipsis()}>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id={"PositionName"}>{cell}</Tooltip>}
            >
              <span>{substr}</span>
            </OverlayTrigger>
          </div>
        ) : (
          <div>{cell}</div>
        )}
      </>
    );
  };

  componentDidMount = () => {
    // let lastDate = this.state.navigationCalenderDates[1];
    // const [dates, weekDayName] =
    //   schConversions.getNextPayPeriodByEndDate(lastDate);
    // let headerDates: any = [];
    // dates.map(item => {
    //   headerDates.push(item.substring(0, 5))
    // })
    let fullDates:any = [];
    let date = this.props.payPeriodStartDate;

    let dates = this.props.parentState.headerDates;
    let year = date.slice(6);
     dates.map(item=>{
      if(item === '01/01'){
        year = Number(year) + 1;
      }
      fullDates.push(`${item}/${year}`)  
    })
    let positionList = this.props.positionList;
    let finalPositionList: any = [];
    let positionIds = {};
    positionList.map((item, index) => {
      if (index > 0) {
        item.label = item.name;
        positionIds[item.label] = item.id;
        finalPositionList.push(item);
      }
    })

    this.setState({
      headerDays: this.props.parentState.headerDays,
      payPeriodDates: [this.props.payPeriodStartDate, this.props.payPeriodEndDate],
      calenderSelectDate: new Date(this.props.payPeriodStartDate),
      headerFullDates: fullDates,
      minDate: new Date(this.props.payPeriodStartDate),
      headerDates:dates,
      positionList: finalPositionList,
      positionIds
    });
    this.getSearchUsersList();

    // this.buildDefaultData();
  };
  getSearchUsersList = () => {
    let request = {} as ILaborScheduleEmployeeRequestDto;

    request.hotelID = this.props.parentState.hidValue;
    request.date =
      // this.props.component === "timesheet"
      //   ? this.props.parentState.calenderDates[0]
      // :
      this.state.navigationCalenderDates[0];
    request.inTime = "0:00 AM";
    request.outTime = "0:00 AM";
    request.position = 0;
    request.type = "add";
    request.shiftDate =
      // this.props.component === "timesheet"
      //   ? this.props.parentState.calenderDates[1]
      // :
      this.state.navigationCalenderDates[1];
    request.uniqueno = 0;

    // if (this.props.component === "timesheet") {
    //   this.laborTimeSheetEmployee(
    //     this.props.hidValue,
    //     this.props.parentState.calenderDates[0]
    //   );
    // } else {
    this.setState({ isDataLoading: true })
    LaborSchedule.GetLaborScheduleEmployee(request)
      .then(async (result: any) => {

        let userList = result.result;
        userList.map(item => {
          item.label = item.employee;
        })
        let finalUserList = [{ label: "Unassigned", employee: "Unassigned", imagePath: "" }, ...userList]
        this.setState(
          {
            filterData: finalUserList,
            allEmployeeList: finalUserList,
            itemLists: finalUserList,
            isDataLoading: false
          }
          // ,
          // () => {
          // this.getSelectedUser();
          // }
        );
        // resolve();
      })
      .catch((error: any) => {
        Utils.toastError(error.message, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        // reject();
      });
  };

  // buildDefaultData = () => {
  //   // const filterColumns = this.tableColumns
  //   //   .filter((item) => item.text)
  //   //   .map((item) => ({
  //   //     text: item.text,
  //   //     key: item.dataField,
  //   //     isFilterApplied: item?.dataField === "shiftDate" ? true : false,
  //   //     filterInputType: item?.filterInputType,
  //   //   }));

  //   const { startDate, endDate } = this.state;
  //   if (!this.props.shiftId) {
  //     const currentFilter: ICurrentFilter = {
  //       filterName: "Shift Date",
  //       filterValue: `${startDate} - ${endDate}`,
  //       filterKeyName: "shiftDate",
  //       filterInputType: "date",
  //       popClassName: "filter-edit-popup-0",
  //     };
  //     this.setState({
  //       filterPopUps: [currentFilter],
  //       currentFilter,
  //       tableColumns: this.tableColumns,
  //     });
  //   } else {
  //     this.setState({
  //       tableColumns: this.tableColumns,
  //       scheduleId: this.props.shiftId,
  //     });
  //   }

  //   const { shiftId } = this.props;
  //   if (+shiftId > 0) {
  //     this.onShiftClick(+shiftId, this.props.shiftDate);
  //   } else {
  //     this.getChangeLogData(true);
  //   }
  // };

  validateDates = () => {
    const currentFilter: any = { ...this.state.currentFilter };
    if (
      currentFilter.filterKeyName === "editDateTime" ||
      currentFilter.filterKeyName === "shiftDate"
    ) {
      const { startDate, endDate } = this.state;

      if (!endDate) {
        Utils.toastError("Please enter End Date.", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
      } else if (!startDate) {
        Utils.toastError("Please enter Start Date.", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  updateFilters = () => {
    const currentFilter: any = { ...this.state.currentFilter };
    let filterPopUps: any[] = [...this.state.filterPopUps];
    const filterIndex = filterPopUps.findIndex(
      (filter) => filter?.filterName === currentFilter?.filterName
    );
    if (
      (currentFilter.filterKeyName === "editDateTime" ||
        currentFilter.filterKeyName === "shiftDate") &&
      !currentFilter.filterValue
    ) {
      currentFilter.filterValue = `${this.props.payPeriodStartDate} - ${this.props.payPeriodEndDate}`;
    }
    if (filterIndex < 0) {
      filterPopUps.push(currentFilter);
    } else {
      filterPopUps[filterIndex] = currentFilter;
    }
    filterPopUps = filterPopUps?.filter((item) => item?.filterValue);
    filterPopUps = filterPopUps.map((item, index) => {
      item.popClassName = "filter-edit-popup-" + index;
      return item;
    });

    this.setState({ filterPopUps, currentPageNo: 1 }, () => {
      this.getChangeLogData();
      this.updateFilterColumns();
    });
  };

  updateFilterColumns = () => {
    const { filterColumns: columns, filterPopUps } = this.state;

    const filteredKeys = filterPopUps?.map(
      (filter: ICurrentFilter) => filter?.filterKeyName
    );

    const filterColumns = columns.map((item: any) => {
      if (filteredKeys.find((key) => key === item?.key)) {
        item.isFilterApplied = true;
      } else {
        item.isFilterApplied = false;
      }
      return item;
    });

    this.setState({ filterColumns });
  };

  onFilterTextChange = (event: any) => {
    const currentFilter: any = { ...this.state.currentFilter };
    currentFilter.filterValue = event.currentTarget?.value;
    this.setState({ currentFilter });
  };
  onFilterChange = (filterItem: any, popClassName): void => {
    const filterPopUps: any[] = [...this.state.filterPopUps];
    let currentFilter: ICurrentFilter = this.state.currentFilter;

    if (filterPopUps.length >= 5) {
      Utils.toastError("You can't apply more than 5 filters!", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    if (
      filterPopUps.length >= 5 ||
      filterPopUps.filter((filter) => filter?.filterName === filterItem.text)
        ?.length > 0
    ) {
      return;
    }
    currentFilter = {
      filterName: filterItem?.text,
      filterValue: "",
      filterKeyName: filterItem.key,
      filterInputType: filterItem?.filterInputType,
      popClassName: popClassName,
    };
    this.setState({
      currentFilter,
      currentPageNo: 1,
      showFilterPopUp: true,
      popClassName,
      startDate: this.props.payPeriodStartDate,
      endDate: this.props.payPeriodEndDate,
    });
  };

  removeFilter = (filterName: string): void => {
    let filterPopUps: any[] = [...this.state.filterPopUps];
    if (filterPopUps.length === 0) {
      return;
    }
    filterPopUps = filterPopUps.filter(
      (filter) => filter?.filterName !== filterName
    );
    if (filterPopUps.length > 0) {
      filterPopUps = filterPopUps.map((item, index) => {
        item.popClassName = "filter-edit-popup-" + index;
        return item;
      });
    }
    if (+this.state.scheduleId > 0) {
      this.setState({ tableClassName: "schedule-log-table" });
      this.returnBackFromDrillDown();
    } else {
      this.setState({ filterPopUps, currentPageNo: 1 }, () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      });
    }
  };

  hideFilterPopUp = () => {
    if (this.state.showFilterPopUp) {
      this.setState({
        showFilterPopUp: false,
        popClassName: "",
      });
    }
  };

  editFilter = (currentFilter: ICurrentFilter, popClassName) => {
    if (currentFilter.filterInputType === "date") {
      const datesArray = currentFilter.filterValue?.split("-");
      this.setState({
        currentFilter,
        popClassName,
        showFilterPopUp: true,
        startDate: datesArray[0]?.trim(),
        endDate: datesArray[1]?.trim(),
      });
    } else {
      this.setState({ currentFilter, popClassName, showFilterPopUp: true });
    }
  };

  returnBackFromDrillDown = () => {
    this.setState(
      {
        scheduleId: 0,
        currentPageNo: 1,
        isDrillDownView: false,
        filterPopUps: this.state.filterPopUpsCopy,
      },
      () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      }
    );
  };

  // onShiftClick = (scheduleId, shiftDate) => {
  //   this.setState(
  //     {
  //       tableClassName: "schedule-log-table-drilldown",
  //       currentPageNo: 1,
  //       drillDownShiftDate: shiftDate,
  //       scheduleId,
  //       isDrillDownView: true,
  //       filterPopUpsCopy:
  //         +this.props.shiftId > 0 ? [] : [...this.state.filterPopUps],
  //       tableColumns: this.tableColumns.filter(
  //         (column: any) => column?.dataField
  //       ),
  //       filterPopUps: [
  //         {
  //           filterName: "Shift",
  //           filterValue: scheduleId,
  //           filterKeyName: "uniqueno",
  //           popClassName: "",
  //           filterInputType: "",
  //         },
  //       ],
  //     },
  //     () => this.getScheduleChangeLogByScheduleId()
  //   );
  // };

  // onPaginationchange = () => {
  //   const currentPageNo = this.state.currentPageNo + 1;
  //   this.setState({ currentPageNo }, () => {
  //     if (this.state.isDrillDownView) {
  //       this.getScheduleChangeLogByScheduleId(true);
  //     } else {
  //       this.getChangeLogData(false, true);
  //     }
  //   });
  // };

  getChangeLogData = (isDefaultLoad = false, isPagination = false) => {
    if (!isPagination) {
      this.setState({
        tableData: [],
        filteredData: [],
      });
    } else {
      this.setState({});
    }
    this.toggleDataLoading();
    const { filterPopUps, pageSize, currentPageNo } = this.state;
    const request: IScheduleChangeLogRequest = {
      shiftfromdate: "",
      shifttodate: "",
      auditfromdate: "",
      audittodate: "",
      tenantId: 0,
      hotelId: this.props.hotelId,
      uniqueno: "",
      editor: "",
      username: "",
      position: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
      uniquenoCheckStrict: false,
      pageNo: isPagination ? currentPageNo : 1,
      pageSize: pageSize,
    } as IScheduleChangeLogRequest;
    filterPopUps.forEach((item: ICurrentFilter) => {
      if (item.filterKeyName === "shiftDate") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.shiftfromdate = datesArray[0];
        request.shifttodate = datesArray[1];
      } else if (item.filterKeyName === "editDateTime") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.auditfromdate = datesArray[0];
        request.audittodate = datesArray[1];
      } else if (item.filterKeyName === "uniqueno") {
        request.uniqueno = item.filterValue?.trim();
      } else if (item.filterKeyName === "editor") {
        request.editor = item.filterValue;
      } else if (item.filterKeyName === "employee") {
        request.username = item.filterValue;
      } else if (item.filterKeyName === "position") {
        request.position = item.filterValue;
      } else if (item.filterKeyName === "fieldname") {
        request.fieldName = item.filterValue;
      } else if (item.filterKeyName === "oldValue") {
        request.oldValue = item.filterValue;
      } else if (item.filterKeyName === "newValue") {
        request.newValue = item.filterValue;
      } else if (item.filterKeyName === "triggerName") {
        request.triggerName = item.filterValue;
      }
    });

    if (isDefaultLoad) {
      request.shiftfromdate = this.props.payPeriodStartDate;
      request.shifttodate = this.props.payPeriodEndDate;
    }

    Schedule.GetScheduleChangeLog(request)
      .then((tableData: IScheduleChangeLogResponse[] | null) => {
        tableData = tableData?.map((item) => {
          item.editDateTime = moment(item.editDateTime).format(
            "MM/DD/YYYY hh:mm A"
          );
          item.shiftDate = moment(item.shiftDate).format("MM/DD/YYYY");

          return item;
        }) as IScheduleChangeLogResponse[] | null;
        if (isPagination) {
          tableData = [
            ...this.state.tableData,
            ...(tableData as IScheduleChangeLogResponse[]),
          ];
        }

        const totalDataLength = tableData?.length
          ? tableData[0]?.totalCount
          : 0;
        this.setState({
          tableData: tableData,
          filteredData: tableData,
          totalDataLength,
        });
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => this.toggleDataLoading());
  };

  // getFilteredData = () => {
  //   this.onFiltersChange();
  // };

  getScheduleChangeLogByScheduleId = (isPagination = false) => {
    this.toggleDataLoading();
    const { scheduleId, drillDownShiftDate, pageSize, currentPageNo } =
      this.state;

    if (!isPagination) {
      this.setState({
        tableData: [],
        filteredData: [],
      });
    }

    const request: IScheduleChangeLogRequest = {
      shiftfromdate: "",
      shifttodate: drillDownShiftDate,
      auditfromdate: drillDownShiftDate,
      audittodate: "",
      tenantId: 0,
      hotelId: this.props.hotelId,
      uniqueno: scheduleId?.toString(),
      editor: "",
      username: "",
      position: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
      uniquenoCheckStrict: true,
      pageNo: currentPageNo,
      pageSize: pageSize,
    } as IScheduleChangeLogRequest;

    Schedule.GetScheduleChangeLog(request)
      .then((tableData: IScheduleChangeLogResponse[] | null) => {
        tableData = tableData?.map((item) => {
          item.editDateTime = moment(item.editDateTime).format(
            "MM/DD/YYYY hh:mm A"
          );
          item.shiftDate = moment(item.shiftDate).format("MM/DD/YYYY");
          return item;
        }) as IScheduleChangeLogResponse[] | null;

        if (isPagination) {
          tableData = [
            ...this.state.tableData,
            ...(tableData as IScheduleChangeLogResponse[]),
          ];
        }

        const totalDataLength = tableData?.length
          ? tableData[0]?.totalCount
          : 0;
        this.setState({
          tableData: tableData,
          filteredData: tableData,
          totalDataLength,
        });
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => this.toggleDataLoading());
  };

  downloadChangeLogData = () => {
    const { filterPopUps, isDrillDownView, scheduleId } = this.state;
    const request: IScheduleChangeLogRequest = {
      shiftfromdate: "",
      shifttodate: "",
      auditfromdate: "",
      audittodate: "",
      tenantId: 0,
      hotelId: this.props.hotelId,
      uniqueno: "",
      editor: "",
      username: "",
      position: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
      uniquenoCheckStrict: false,
    } as IScheduleChangeLogRequest;
    if (isDrillDownView) {
      request.uniqueno = scheduleId?.toString();
      request.uniquenoCheckStrict = true;
    } else {
      filterPopUps.forEach((item: ICurrentFilter) => {
        if (item.filterKeyName === "shiftDate") {
          const datesArray = item.filterValue
            ?.split("-")
            .map((item) => item?.trim());
          request.shiftfromdate = datesArray[0];
          request.shifttodate = datesArray[1];
        } else if (item.filterKeyName === "editDateTime") {
          const datesArray = item.filterValue
            ?.split("-")
            .map((item) => item?.trim());
          request.auditfromdate = datesArray[0];
          request.audittodate = datesArray[1];
        } else if (item.filterKeyName === "uniqueno") {
          request.uniqueno = item.filterValue?.trim();
        } else if (item.filterKeyName === "editor") {
          request.editor = item.filterValue;
        } else if (item.filterKeyName === "employee") {
          request.username = item.filterValue;
        } else if (item.filterKeyName === "position") {
          request.position = item.filterValue;
        } else if (item.filterKeyName === "fieldname") {
          request.fieldName = item.filterValue;
        } else if (item.filterKeyName === "oldValue") {
          request.oldValue = item.filterValue;
        } else if (item.filterKeyName === "newValue") {
          request.newValue = item.filterValue;
        } else if (item.filterKeyName === "triggerName") {
          request.triggerName = item.filterValue;
        }
      });
    }

    Schedule.DownloadChangeLog(request, "ScheduleChangeLogs")
      .then(() => { })
      .catch((error) => { });
  };
  toggleDataLoading = () => {
    this.setState({ isDataLoading: !this.state.isDataLoading });
  };

  handleDatesChange = (date, type) => {
    const { startDate, endDate } = this.state;
    const currentFilter: any = { ...this.state.currentFilter };
    if (type === "StartDate") {
      if (new Date(date) > new Date(endDate)) {
        this.setState({ startDate: "" });
        Utils.toastError(
          "Please enter the Start Date less than or equal to End Date.",
          {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        return false;
      }

      currentFilter.filterValue = `${date} - ${endDate}`;
      this.setState({ startDate: date });
    }

    if (type === "EndDate") {
      if (new Date(startDate) > new Date(date)) {
        this.setState({ endDate: "" });
        Utils.toastError(
          "Please enter the End Date greater than or equal to Start Date.",
          {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        return false;
      }

      currentFilter.filterValue = `${startDate} - ${date}`;
      this.setState({ endDate: date });
    }

    this.setState({ currentFilter });
  };

  searchedData = (newResult: any) => {
    if(newResult.length <=6){
      $(`.bulk-entry-modal`).find('.react-bootstrap-table').removeClass('scroll-true');
    }else{
      $(`.bulk-entry-modal`).find('.react-bootstrap-table').addClass('scroll-true');
    }
    return newResult;
  };

  showCalender = () => {
    this.setState({ calenderShow: true });
  };

  hideCalender = () => {
    this.setState({ calenderShow: false });
  };

  headerFormatter = (column: any, colIndex: any, row: any) => {
    let headerDates = this.state.headerDates;
    let headerDays = this.state.headerDays;
    let fullWeekDays = this.state.fullWeekDays;
    // debugger;
    return (
      <div className="column-header">
        <div className="header-data">
          <div className="header-day">{fullWeekDays[headerDays[colIndex - 3]]}</div>
        </div>
        <div className="header-date">{headerDates[colIndex - 3]}</div>
      </div>
    );
  };

  inTimeChange = (e, row, rowIndex, cell,span, input) => {


    this.setState({ isActionFooter: true })
   
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    data[row.rowNo][cell.day].inTime = e.target.value;
    data[row.rowNo][cell.day].showWarning = false;
    this.setState({ tableData: data },()=>{
      $(`#${cell.day}${span}${rowIndex}`)?.removeClass("show");
    // $(`#${cell.day}${span}${rowIndex}`)?.removeClass("warning");
    $(`#${cell.day}${input}${rowIndex}`)?.addClass("show")
    });
  };
  outTimeChange = (e, row, rowIndex, cell,span, input) => {
    // debugger;\
    this.setState({ isActionFooter: true })

    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    data[row.rowNo][cell.day].outTime = e.target.value;
    // data[rowIndex][cell.day].outTime = e.target.value;
    data[row.rowNo][cell.day].showWarning = false;
    // data[rowIndex][cell.day].showWarning = false;
    this.setState({ tableData: data },()=>{
      $(`#${cell.day}${span}${rowIndex}`)?.removeClass("show");
      // $(`#${cell.day}${span}${rowIndex}`)?.removeClass("warning");
      $(`#${cell.day}${input}${rowIndex}`)?.addClass("show")
    });
  };

  onFocusInputField = (cell, row, rowIndex, span, input) => {
    //  debugger;
    $(`#${cell.day}${span}${rowIndex}`)?.removeClass("show");
    // $(`#${cell.day}${span}${rowIndex}`)?.removeClass("warning");
    $(`#${cell.day}${input}${rowIndex}`)?.addClass("show")
    // $("tooltip")?.removeClass("fade");

    $(`#${cell.day}${input}${rowIndex}`)?.trigger("focus")

    // this.setState({ isWarning: true, warningCount: 0 })
  }

  onBlurInputField = (isEmpty, cell, rowIndex, span, input) => {
    if (isEmpty) {
      $(`#${cell.day}${span}${rowIndex}`)?.addClass("show");
      $(`#${cell.day}${input}${rowIndex}`)?.removeClass("show")
      // return;
    }
    debugger;

    if (input === "endInput" || input === "startInput") {

      let data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.tableData))
      );

      if (cell.outTime !== "" && cell.inTime !== "") {


        let inMomentTime = moment(
          `${cell.inDate} ${cell.inTime}`
        );
        let outMomentTime = moment(
          `${cell.inDate} ${cell.outTime}`
        );

        let differenceInTime = outMomentTime.diff(inMomentTime);
        let differenceInTimeMin = outMomentTime.diff(inMomentTime,'m');
      
        debugger;
        let punchOutDate = moment(cell.inDate).format("MM/DD/YYYY");
        if (differenceInTime <= 0) {
          data[rowIndex][cell.day].showMoonIcon = true;
          punchOutDate = moment(cell.inDate)
            .add(1, "days")
            .format("MM/DD/YYYY");
          let newOutDate = moment(
            `${punchOutDate} ${cell.outTime}`
          );
          let differenceInMin = newOutDate.diff(inMomentTime, "m");
          if (differenceInMin > 600) {
            let timeInHours =  schConversions.convertMinuteToHour(differenceInMin);
            data[rowIndex][cell.day].shiftHours = timeInHours;
            data[rowIndex][cell.day].showTooltip = true
          } else {
            data[rowIndex][cell.day].showTooltip = false
          }
        }
        else {
          if (differenceInTimeMin > 600) {
            let timeInHours =  schConversions.convertMinuteToHour(differenceInTimeMin);
            data[rowIndex][cell.day].shiftHours = timeInHours;
            data[rowIndex][cell.day].showTooltip = true
          }  else {
            data[rowIndex][cell.day].showTooltip = false
          }
          data[rowIndex][cell.day].showMoonIcon = false;
        }
        data[rowIndex][cell.day].outDate = punchOutDate;
      }
      else{

        data[rowIndex][cell.day].showMoonIcon = false;
        data[rowIndex][cell.day].showTooltip = false
      }

      this.setState({ tableData: data },()=>{
      this.shiftValidation();

      })
    }

  }

  shiftTimeFormatter = (cell: any, row: any, rowIndex: any) => {
    let inTimeEmpty: any = false;
    let outTimeEmpty: any = false;

    if (cell.inTime === "") {
      inTimeEmpty = true;
    }
    if (cell.outTime === "") {
      outTimeEmpty = true;
    }
    return (
      <>
        <div className="start-end-time d-flex" >

          <Form.Group controlId="start-time" className="startTime">

            <input
              id={`${cell.day}StartSpan${rowIndex}`}
              placeholder="Start"
              type="text"
              onFocus={() => this.onFocusInputField(cell, row, rowIndex, "StartSpan", "startInput")}
              className={`span ${inTimeEmpty && "show"} ${cell.showWarning ? "warning-red" : ""}`} />

            <input
              className={`html-time-picker ${!inTimeEmpty && "show"} ${cell.showWarning ? "warning-red" : ""} ${cell.showTooltip ? "warning-orange" : ""}`}
              type="time"
              placeholder="Start"
              id={`${cell.day}startInput${rowIndex}`}
              name={`${cell.day}starttime`}
              value={row[cell.day].inTime}
              onBlur={() => this.onBlurInputField(inTimeEmpty, cell, rowIndex, "StartSpan", "startInput")}
              onChange={(e: any) => this.inTimeChange(e, row, rowIndex, cell,"StartSpan", "startInput")}
            />

          </Form.Group>

          {/* {moment(this.state.punchInDate).format("MM/DD/YY") !==
                  moment(this.state.punchOutDate).format("MM/DD/YY") &&
                  this.state.punchInDate !== "Select" &&
                  this.state.punchOutDate !== "Select" && ( */}
          {cell.showMoonIcon && (

            <div className="moon-icon">
              <MoonIcon parentComponent={"BulkEntry"} />
            </div>
          )}
          {/* )} */}
          <Form.Group controlId="end-time" className="endTime">

            <input
              id={`${cell.day}endSpan${rowIndex}`}
              className={`span  ${outTimeEmpty && "show"} ${cell.showWarning ? "warning-red" : ""}`}
              type="text"
              placeholder="End"
              onFocus={() => this.onFocusInputField(cell, row, rowIndex, "endSpan", "endInput")}
            />


            <input
              className={`html-time-picker  ${!outTimeEmpty && "show"} ${cell.showWarning ? "warning-red" : ""}  ${cell.showTooltip ? "warning-orange" : ""}`}
              placeholder="End"
              // type={`${outTimeEmpty ? "text" : "time"}`}
              type="time"
              id={`${cell.day}endInput${rowIndex}`}
              name={`${cell.day}endTime`}
              value={row[cell.day].outTime}
              onBlur={() => this.onBlurInputField(outTimeEmpty, cell, rowIndex, "endSpan", "endInput")}

              onChange={(e: any) => this.outTimeChange(e, row, rowIndex, cell,"endSpan", "endInput")}
            />

          </Form.Group>
          {cell.showTooltip &&
            (
              <Tooltip id={`tooltip-bottom`} placement="bottom" >
                This shift is <strong>{cell.shiftHours ? `${cell.shiftHours}` : "00:00"}</strong>.
              </Tooltip>

            )

          }

        </div>
      </>
    );
  };
  addNewRowClickHandler = () => {
    // debugger;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let rowNo = data.length;
    let dates = this.state.headerFullDates;
    let newRow: any = {};
    newRow.user = "Unassigned";
    newRow.position = "Position";
    // newRow.positionId = 0;
    newRow.rowNo = rowNo;
    newRow.showPositionColumn = true;
    newRow.addNewPositionBtn = true;
    newRow.showUserColumn = true;
    newRow.addNewRowButton = true;
    // newRow.isPositionEmpty = ;
    // newRow.showToolTip = false;
    // newRow.showWarning = false
    newRow.day1 = {
      inTime: "",
      outTime: "",
      day: "day1",
      fullDate: dates[0],
      inDate: dates[0],
      outDate: "",
      showTooltip: false,
      showWarning: false,
      showMoonIcon: false
    };
    newRow.day2 = {
      inTime: "",
      outTime: "",
      day: "day2",
      fullDate: dates[1],
      inDate: dates[1],
      outDate: "",
      showTooltip: false,
      showWarning: false,
      showMoonIcon: false
    };
    newRow.day3 = {
      inTime: "",
      outTime: "",
      day: "day3",
      fullDate: dates[2],
      inDate: dates[2],
      outDate: "",
      showTooltip: false,
      showWarning: false,
      showMoonIcon: false
    };
    newRow.day4 = {
      inTime: "",
      outTime: "",
      day: "day4",
      fullDate: dates[3],
      inDate: dates[3],
      outDate: "",
      showTooltip: false,
      showWarning: false,
      showMoonIcon: false
    };
    newRow.day5 = {
      inTime: "",
      outTime: "",
      day: "day5",
      fullDate: dates[4],
      inDate: dates[4],
      outDate: "",
      showTooltip: false,
      showWarning: false,
      showMoonIcon: false
    };
    newRow.day6 = {
      inTime: "",
      outTime: "",
      day: "day6",
      fullDate: dates[5],
      inDate: dates[5],
      outDate: "",
      showTooltip: false,
      showWarning: false,
      showMoonIcon: false
    };
    newRow.day7 = {
      inTime: "",
      outTime: "",
      day: "day7",
      fullDate: dates[6],
      inDate: dates[6],
      outDate: "",
      showTooltip: false,
      showWarning: false,
      showMoonIcon: false
    };
    data.push(newRow);
    this.setState({ tableData: data },()=>{

     // $(`#userCol${rowNo}`)?.trigger("focus")
    });
    if(rowNo >= 6){
    // $(`.bulk-entry-modal > .react-bootstrap-table`).addClass('scroll-true')
      $(`.bulk-entry-modal`).find('.react-bootstrap-table').addClass('scroll-true');
    }
  };
  addNewRowWithSamePosition = (parentRow, parentRowIndex) => {
    // debugger;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let dates = this.state.headerFullDates;
    let rowNo = data.length;

    let lastIndex = data.length - 1;

    data.map((item, index) => {
      if (index === parentRowIndex) {
        item.addNewPositionBtn = false;
        item.addNewRowButton = true;
      }
    });
    let newTableData: any = [];

    if (parentRowIndex !== lastIndex) { 
      let sliceIndex = 0;  
      let nextColFound = false;
      for(let x = parentRowIndex +1;x<data.length;x++){
        if(data[x].showPositionColumn){
          nextColFound = true;
          sliceIndex = x;
          break;
        }
      }  
      if(!nextColFound){
        sliceIndex = rowNo;
      } 
      let slicedData = data.slice(0, sliceIndex);
      let restData = data.slice(sliceIndex);
      if (slicedData.length > 0) {
        slicedData.map((item: any) => newTableData.push(item));
      }
      let lastRowNo = sliceIndex;
      let newRow: any = {};
      newRow.rowNo = lastRowNo ;
      newRow.user = data[parentRowIndex].user;
      newRow.position = "Position";
      // newRow.positionId = data[parentRowIndex].positionId;
      newRow.showPositionColumn = true;
      newRow.addNewPositionBtn = true;
      newRow.showUserColumn = false;
      newRow.addNewRowButton = true;
      newRow.day1 = {
        inTime: "",
        outTime: "",
        day: "day1",
        fullDate: dates[0],
        inDate: dates[0],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day2 = {
        inTime: "",
        outTime: "",
        day: "day2",
        fullDate: dates[1],
        inDate: dates[1],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day3 = {
        inTime: "",
        outTime: "",
        day: "day3",
        fullDate: dates[2],
        inDate: dates[2],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day4 = {
        inTime: "",
        outTime: "",
        day: "day4",
        fullDate: dates[3],
        inDate: dates[3],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day5 = {
        inTime: "",
        outTime: "",
        day: "day5",
        fullDate: dates[4],
        inDate: dates[4],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day6 = {
        inTime: "",
        outTime: "",
        day: "day6",
        fullDate: dates[5],
        inDate: dates[5],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day7 = {
        inTime: "",
        outTime: "",
        day: "day7",
        fullDate: dates[6],
        inDate: dates[6],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      // newRow.addNewRowButton = false;
      newTableData.push(newRow);
      if (restData.length > 0) {
        restData.map((item: any) =>{
          item.rowNo = item.rowNo +1;
         return newTableData.push(item)
        });
      }
      this.setState({ tableData: newTableData });
    } else {
      let newRow: any = {};
      newRow.rowNo = rowNo;
      newRow.user = data[parentRowIndex].user;
      newRow.position = "Position";
      // newRow.positionId = data[parentRowIndex].positionId;
      newRow.showPositionColumn = true;
      newRow.addNewPositionBtn = true;
      newRow.showUserColumn = false;
      newRow.addNewRowButton = true;
      // newRow.showToolTip = false;
      // newRow.showWarning = fals
      newRow.day1 = {
        inTime: "",
        outTime: "",
        day: "day1",
        fullDate: dates[0],
        inDate: dates[0],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day2 = {
        inTime: "",
        outTime: "",
        day: "day2",
        fullDate: dates[1],
        inDate: dates[1],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day3 = {
        inTime: "",
        outTime: "",
        day: "day3",
        fullDate: dates[2],
        inDate: dates[2],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day4 = {
        inTime: "",
        outTime: "",
        day: "day4",
        fullDate: dates[3],
        inDate: dates[3],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day5 = {
        inTime: "",
        outTime: "",
        day: "day5",
        fullDate: dates[4],
        inDate: dates[4],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day6 = {
        inTime: "",
        outTime: "",
        day: "day6",
        fullDate: dates[5],
        inDate: dates[5],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day7 = {
        inTime: "",
        outTime: "",
        day: "day7",
        fullDate: dates[6],
        inDate: dates[6],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      data.push(newRow);
      this.setState({ tableData: data });
    }
    if(rowNo >= 6){
      // $(`.bulk-entry-modal > .react-bootstrap-table`).addClass('scroll-true')
        $(`.bulk-entry-modal`).find('.react-bootstrap-table').addClass('scroll-true');
      }
  };

  addNewRowWithSameUser = (parentRow, parentRowIndex) => {
      debugger;
    //this.CleanSearch();
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let dates = this.state.headerFullDates;
    let rowNo = data.length;
    let lastIndex = data.length - 1;

    data.map((item, index) => {
      if (index === parentRowIndex) {
        if(item.addNewPosition){
          item.addNewRowButton = false;
        }
        item.addNewRowButton = false;
        // item.addNewPositionBtn = false;
      }
    });
    let newTableData: any = [];

    if (parentRowIndex !== lastIndex) {
      let slicedData = data.slice(0, parentRowIndex + 1);
      let restData = data.slice(parentRowIndex + 1);
      if (slicedData.length > 0) {
        slicedData.map((item: any) => newTableData.push(item));
      }
      let lastRowNo = parentRowIndex;
      let newRow: any = {};
      newRow.rowNo = lastRowNo + 1;
      newRow.user = data[parentRowIndex].user;
      newRow.position = data[parentRowIndex].position;
      // newRow.positionId = data[parentRowIndex].positionId;
      newRow.showPositionColumn = false;
      newRow.addNewPositionBtn = false;
      newRow.showUserColumn = false;
      newRow.addNewRowButton = true;
      // newRow.showToolTip = false;
      // newRow.showWarning = fals
      newRow.day1 = {
        inTime: "",
        outTime: "",
        day: "day1",
        fullDate: dates[0],
        inDate: dates[0],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day2 = {
        inTime: "",
        outTime: "",
        day: "day2",
        fullDate: dates[1],
        inDate: dates[1],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day3 = {
        inTime: "",
        outTime: "",
        day: "day3",
        fullDate: dates[2],
        inDate: dates[2],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day4 = {
        inTime: "",
        outTime: "",
        day: "day4",
        fullDate: dates[3],
        inDate: dates[3],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day5 = {
        inTime: "",
        outTime: "",
        day: "day5",
        fullDate: dates[4],
        inDate: dates[4],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day6 = {
        inTime: "",
        outTime: "",
        day: "day6",
        fullDate: dates[5],
        inDate: dates[5],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day7 = {
        inTime: "",
        outTime: "",
        day: "day7",
        fullDate: dates[6],
        inDate: dates[6],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newTableData.push(newRow);
      if (restData.length > 0) {
        restData.map((item: any) =>{
          item.rowNo = item.rowNo +1;
         return newTableData.push(item)
        });
      }
      this.setState({ tableData: newTableData });
    } else {
      let newRow: any = {};
      newRow.rowNo = rowNo;
      newRow.user = data[parentRowIndex].user;
      newRow.position = data[parentRowIndex].position;
      // newRow.positionId = data[parentRowIndex].positionId;
      newRow.showPositionColumn = false;
      newRow.addNewPositionBtn = false;
      newRow.showUserColumn = false;
      newRow.addNewRowButton = true;
      // newRow.showToolTip = false;
      // newRow.showWarning = fals
      newRow.day1 = {
        inTime: "",
        outTime: "",
        day: "day1",
        fullDate: dates[0],
        inDate: dates[0],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day2 = {
        inTime: "",
        outTime: "",
        day: "day2",
        fullDate: dates[1],
        inDate: dates[1],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day3 = {
        inTime: "",
        outTime: "",
        day: "day3",
        fullDate: dates[2],
        inDate: dates[2],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day4 = {
        inTime: "",
        outTime: "",
        day: "day4",
        fullDate: dates[3],
        inDate: dates[3],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day5 = {
        inTime: "",
        outTime: "",
        day: "day5",
        fullDate: dates[4],
        inDate: dates[4],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day6 = {
        inTime: "",
        outTime: "",
        day: "day6",
        fullDate: dates[5],
        inDate: dates[5],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      newRow.day7 = {
        inTime: "",
        outTime: "",
        day: "day7",
        fullDate: dates[6],
        inDate: dates[6],
        outDate: "",
        showTooltip: false,
        showWarning: false,
        showMoonIcon: false
      };
      data.push(newRow);
      this.setState({ tableData: data });
    }
    if(rowNo >= 6){
      // $(`.bulk-entry-modal > .react-bootstrap-table`).addClass('scroll-true')
        $(`.bulk-entry-modal`).find('.react-bootstrap-table').addClass('scroll-true');
      }
  };

  selectFromSearchBar = (type, row, rowIndex, item) => {
    // debugger;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    data[rowIndex].user = item.employee;
    let searchSelectionList: any = [];

    if (item.length !== 0 && Object.keys(item).length !== 0) {
      searchSelectionList.push(item);
    }
    this.setState({ searchSelectionList }, () => {
      // this.departmentSearch.current?.getSelectedUser();
      this.userSearchScroll.current?.getSelectedUser();
    });
    this.setState({ tableData: data });
  };

  CleanSearch = () => {
    // debugger;
    if (this.searchBar.current !== null) {
      this.searchBar.current.props.onClear("");
      this.searchBar.current.props.onSearch("");
      this.searchBar.current.state.value = "";
     // this.addNewRowClickHandler();
    }
  };
  positionChange = (item, row, rowIndex) => {
    // debugger;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    data[rowIndex].position = item.name;
    data.map((ele, index) => {
      if (index > rowIndex) {
        if (!ele.showPositionColumn) {
          ele.position = item.name
        } else {
          return
        }
      }

    })
    this.setState({ tableData: data });
  };

  searchPosition = (e, row, rowIndex) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let positionList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.positionList))
    );

    data[rowIndex].position = e.target.value;
    let filterList = positionList.filter(item => {
      item.name.match(e.target.value)
    })


    // debugger;
    this.setState({
      tableData: data,
      positionList: filterList
    })


  }

  getScheduleData = (type: string) => {
    if (type === "ehidChange" || type === "calenderDropDown") {
      this.setState({ loaderTrue: true });
    }

    let request = {} as ILabourScheduleRequestDto;
    request.HotelID = this.props.parentState.hidValue;
    request.ViewType = "Users";
    request.DayLayOut = 7;
    request.filter = "Alphabetical";

    request.Date = this.state.calenderLoadDate;

    if (type === "ehidChange" || type === "calenderDropDown") {
      this.CleanSearch();
    }
    this.setState({ isDataLoading: true, isWarning: false })
    LaborSchedule.GetLaborScheduleData(request).then(
      async (result: any) => {
        if (result != null && result.length > 0) {
          let filteredResult = result.filter((item) => {
            return item.employee === "header";
          });
          let fullWeekDays = this.state.fullWeekDays;
          let payPeriodDates: any = [];
          let headerFullDates: any = [];
          let headerDates: any = [];
          let headerDay: any = [];
          // debugger;
          for (let item in filteredResult[0]) {
            if (item == "payPeriodDate") {
              let payP = filteredResult[0][item];

              let displayDates = payP.split("_");
              payPeriodDates[0] = displayDates[0];
              payPeriodDates[1] = displayDates[1];
            }
          }

          for (let j = 1; j <= 7; j++) {
            // let dayValues: any = [];
            let day = `day${j}`;
            let dayData = filteredResult[0][day];
            let dayDetails = dayData.split("||");
            headerFullDates.push(dayDetails[0]);
            headerDates.push(dayDetails[0].substring(0, 5));
            headerDay.push(dayDetails[1]);
          }


          const uniqueHeaderDates: any = new Set(headerDates);
          const arrayHeaderDates = [...uniqueHeaderDates];
          const uniqueHeaderFullDates: any = new Set(headerFullDates);
          const arrayHeaderFullDates = [...uniqueHeaderFullDates];
          const uniqueHeaderDays: any = new Set(headerDay);
          const arrayHeaderDays = [...uniqueHeaderDays];

          this.setState({
            headerDates: arrayHeaderDates,
            headerFullDates: arrayHeaderFullDates,
            headerDays: arrayHeaderDays,
            payPeriodDates: payPeriodDates,
            isDataLoading: false
          });
        }
      }
      // this.setState({ loaderTrue: false });
      // resolve();
    ).catch((err: any) => {
      Utils.toastError(`Server Error, ${err}`, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
      this.setState({ isDataLoagin: false });
      // reject();
    });
  };
  calenderChange = (e: any) => {
    let calenderYear = e.getFullYear();
    let calenderDate = e.getDate();
    let calenderMonth = e.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
    // this.props.changeCalenderDate(tempDate);
    this.setState(
      {
        calenderSelectDate: e,
        calenderShow: false,
        calenderLoadDate: tempDate,
        tableData: []
      },
      () => {
        this.getScheduleData("calenderDropDown");
      }
    );
  };
  onUserSelction = (item, row, rowIndex) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    data[rowIndex].user = item.employee;

    for(let x=0;x<data.length;x++){
      if (x > rowIndex) {
        if (data[x].showUserColumn) {
          break;
        } else {
          data[x].user = item.employee
        }
      }
    }

    this.setState({ tableData: data });
  };


  bulkSeclectOption = (eName: any, type: any) => {
    // debugger;

    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    const positionIds = this.state.positionIds;
    let requestArray: any = [];

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    for (let i = 0; i < data.length; i++) {

      for (let x = 1; x <= 7; x++) {
        let requestObject: any = {};
        let day = `day${x}`;
        let dayWiseInTime = data[i][day].inTime;
        let dayWiseOutTime = data[i][day].outTime;
        if (!dayWiseInTime && !dayWiseOutTime) {
          continue;
        }
        requestObject.tenantID = tenantID;
        requestObject.hotelID = this.props.parentState.hidValue;
        requestObject.date = data[i][day].fullDate;
        requestObject.inDate = `${data[i][day].inDate} ${data[i][day].inTime}`;
        requestObject.outDate = `${data[i][day].outDate} ${data[i][day].outTime}`;

        // requestObject.outTime = data[i][day].outTime;
        requestObject.Uniqueno = 0;
        requestObject.actionType = type;
        requestObject.employeeToAssign = "";
        requestObject.employee = data[i].user;
        requestObject.sheduleType = "unpublished";
        requestObject.WeekStartDate = this.state.navigationCalenderDates[0];
        requestObject.WeekEndDate = this.state.navigationCalenderDates[1];
        requestObject.deptId = 0;
        requestObject.positionId = positionIds[data[i].position];
        requestObject.viewType = "";
        requestObject.inTime = schConversions.twentyFourToTwelveConvert(data[i][day].inTime);
        requestObject.outTime = schConversions.twentyFourToTwelveConvert(data[i][day].outTime);
        requestArray.push(requestObject);
      }



    }
    debugger;
    if(requestArray.length === 0 ){
      Utils.toastError("Blank shift can not be saved, Please enter atleast one shift.")
      this.setState({ shiftButtonSpinner: false });
      this.shiftSaveBtnRef.current.disabled = false;
      return;
    }
    
    LaborSchedule.LaborScheduleBulkSelect(requestArray)

      .then(async (result: any | null) => {
        if (result.message === "Success") {

          let totalShifts = requestArray.length;
          let toastmessage = `${totalShifts} shift saved successfully.`
          if (totalShifts > 1) {
            toastmessage = `${totalShifts} shifts saved successfully.`
          }
          toast.success(toastmessage)
          this.setState({ tableData: [] })
          this.props.showHideChangeLogModal();
          this.props.addShiftRefresh();
        }
        // this.setState({ shiftButtonSpinner: false });
        // this.shiftSaveBtnRef.current.disabled = false;
        // resolve();
      })
      .catch((err: any) => {

        this.setState({ shiftButtonSpinner: false });
        // this.shiftSaveBtnRef.current.disabled = false;
        Utils.toastError(`Server Error ${err}`);
        // reject();
      });
  };


  shiftValidation = (callFrom?) :any=>{
    debugger;

    
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    // debugger;
    let isWarning = this.state.isWarning;
    let has24Hoursshift = false;
    let warningCount = 0;
    let positionEmpty = false;
    let hasInOutTime = false;
   
    data.map((item, index) => {
       hasInOutTime = false;
       positionEmpty = false;

      if (item.position.toLowerCase() !== "position") {
        for (let x = 1; x <= 7; x++) {
          let day = `day${x}`
          let dayData = item[day];
          if ((dayData.inTime && !dayData.outTime) || (dayData.outTime && !dayData.inTime)) {
            // debugger;
            warningCount += 1;
            // isWarning = true;
            dayData.showWarning = true;
          }else{
            if(dayData.inTime && dayData.outTime){
              if(dayData.inTime === dayData.outTime){
              dayData.showWarning = true;
              warningCount += 1;
              has24Hoursshift=true;
              // isWarning = true;
if(callFrom === "saveShift"){

  Utils.toastError("Shift Start time and End time can not be same.")
}
                // return 
              }
              debugger;
              // warningCount += 1;
              // isWarning = true;
              // // dayData.showWarning = true;
              // hasInOutTime=true;
            }
          }
        }
      } else {
        for (let x = 1; x <= 7; x++) {
          let day = `day${x}`
          let dayData = item[day];

          if ((dayData.inTime && !dayData.outTime) || (dayData.outTime && !dayData.inTime)) {

            // debugger;
            warningCount += 1;
            // isWarning = true;
            dayData.showWarning = true;
            hasInOutTime=true;

          }else{
            if(dayData.inTime && dayData.outTime){
              if(dayData.inTime === dayData.outTime){
                dayData.showWarning = true;
                warningCount += 1;
                has24Hoursshift=true;
                // isWarning = true;
  if(callFrom === "saveShift"){
  
    Utils.toastError("Shift Start time and End time can not be same.")
  }
                  // return 
                }
              debugger;
              // warningCount += 1;
              // isWarning = true;
              // dayData.showWarning = true;
              hasInOutTime=true;
            }
          }
        }
        if(hasInOutTime){
          positionEmpty=true;
          if(data[index].showPositionColumn){
            data[index].positionEmpty = true;
            warningCount += 1;
          }else{
            for(let x=index-1; x>=0; x--){
                  if(data[x].showPositionColumn){
                    if(!data[x].positionEmpty){
                      data[x].positionEmpty = true;
                      warningCount += 1;
                    }
                    break;
                  }
                }
          }
          // isWarning = true;
        }else{
          // data[index].positionEmpty = false;
          // positionEmpty=false;
          if(data[index].showPositionColumn){
            data[index].positionEmpty = false;
            // warningCount += 1;
          }else{
            for(let x=index-1; x>=0; x--){
                  if(data[x].showPositionColumn){
                    // if(data[x].positionEmpty){
                      break;
                    // }
                    // data[x].positionEmpty = false;
                    // warningCount += 1;
                  }
                }
          }
        }
       }
      })
      debugger;
     
        this.setState({tableData: data, warningCount:warningCount, })
      if(warningCount === 0){
        this.setState({isWarning:false })

      }

      return [warningCount,positionEmpty]

  }
  handleSave = () => {
    this.shiftSaveBtnRef.current.disabled = true;
    this.setState({ shiftButtonSpinner: true })

   
    const [warningCount,positionEmpty]  =  this.shiftValidation("saveShift");

    if (warningCount > 0) {
      if(positionEmpty){

        Utils.toastError("Please Select Position.")
      }
      this.setState({ shiftButtonSpinner: false,isWarning:true })
      this.shiftSaveBtnRef.current.disabled = false;
    } else {
      // this.setState({shiftButtonSpinner:true})
      this.bulkSeclectOption("", "bulkAddEntry")
    }

  }
  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({ currentIndex: row.rowNo });
    },
    onBlur: (e: any, row: any, rowIndex: any) => { },
  };
  rowClasses = (row, rowIndex) => {
    // debugger;
    if (row.addNewPositionBtn) {
      return 'show-row-border'
    }
    return 'hide-row-border';
  };
  cellEdit = cellEditFactory({
    mode: "click",
    blurToSave: true,
    // beforeSaveCell: (oldValue, newValue, row, column) => { 
    //   // debugger;
    // },
    afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {

      let rowIndex = this.state.currentIndex;
      let data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.tableData))
      );

      
      let nextRowUpdate = false;
      if(column.dataField === "position"){
        row.position  = newValue;
          data[rowIndex].positionEmpty = false;

          row.positionEmpty = false;
          for(let x=0;x<data.length;x++){
            if (x > rowIndex) {
              if (data[x].showPositionColumn) {
                break;
              } else {
                nextRowUpdate=true;
                data[x].position = newValue;
              }
            }
          }
      this.shiftValidation()


        }else{
            row.user = newValue;
          for(let x=0;x<data.length;x++){
            if (x > rowIndex) {
              if (data[x].showUserColumn) {
                break;
              } else {
                nextRowUpdate=true;
                data[x].user = newValue;
              }
            }
          }
      this.shiftValidation()

        }
      // debugger;
        this.setState({ tableData: data });
      
      // this.setState({ isWarning:false,warningCount:0});
      // if (!row.addNewRowButton) {
      //   data.map((ele, index) => {
      //     let rowCount = 0;
      //     if (index > rowIndex) {
      //       if (!ele.addNewRowButton) {
      //         ele.position = newValue;
      //       } else {
      //         if (rowCount === 0) {
      //           ele.position = newValue;
      //           rowCount += 1;
      //         }
      //         return
      //       }
      //     }
      //   })
      // } {
      //   data[rowIndex].position = newValue;
      // }

    },
  });

  



  CleanSearchNew = () => {
    this.CleanSearch();
    setTimeout(() => {
     this.addNewRowClickHandler();
    }, 200);
    ;
  };

  

  render() {
    const Columns = [
      {
        dataField: "user",
        text: "User",
        // editable: false,
        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cell, row, rowIndex) => {          
            return (
              <>
                {row.showUserColumn && (
                  <>
                    <input
                      id={`userCol${rowIndex}`}
                      // className={`${row.positionEmpty? "alert-danger" : ""}`}
                      /// onFocus={()=>{  $(`#userCol${rowIndex}`)?.trigger("click") }}
                       type="text" placeholder="" value={row.user} />
                  </>
                )}
              </>
            );
        },
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => {
          if (row.showUserColumn) {
            return (
              <div className="auto-complete-user">
                <>
                  <UserListAutoComplete
                    key={"user-1" + rowIndex}
                    {...editorProps}
                    value={value}
                    items={this.state.allEmployeeList}
                    inputProps={{ placeholder: `${row.user}` }}
                  />
                </>
              </div>
            )
          }
        }
      },
      {
        dataField: "position",
        text: "Position",

        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cell, row, rowIndex) => {
          return (
            <>
              {row.showPositionColumn && (
                <>
                  <input
                    id={`positionCol${rowIndex}`}
                    className={`${row.positionEmpty? "alert-danger" : ""}`}
                      onFocus={()=>{  $(`#positionCol${rowIndex}`)?.trigger("click") }} type="text" placeholder="" value={`${row.position}`} />
                </>
              )}
            </>
          );
        },
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => {
          if (row.showPositionColumn) {
            return (
              <div className="auto-complete-pos">
                <>
                  <TableAutocomplete
                    key={"position-1" + rowIndex}
                    {...editorProps}
                    value={value}
                    items={this.state.positionList}
                    inputProps={{ placeholder: `${row.position}` }}
                  />
                </>

              </div>
            )
          }
        }
      },
      {
        dataField: "addNewPositionBtn",
        text: "",
        editable: false,

        searchable: false,
        headerStyle: () => {
          return { width: "3%" };
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              {row.addNewPositionBtn && (
                <div style={{cursor:"pointer"}}
                onClick={() => this.addNewRowWithSamePosition(row, row.rowNo)}
                >
                <svg
                  style={{ transform: "rotate3d(0, 0, 1, 45deg)" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#2caf92" />
                </svg>
                </div>

              )}
            </>
          );
        },
      },
      {
        dataField: "day1",
        // text: "Monday",
        searchable: false,
        editable: false,

        headerFormatter: this.headerFormatter,
        headerStyle: () => {
          return { width: "12%" };
        },
        formatter: this.shiftTimeFormatter,
      },
      {
        dataField: "day2",
        // text: "Tuesday",
        searchable: false,
        editable: false,

        headerFormatter: this.headerFormatter,

        headerStyle: () => {
          return { width: "12%" };
        },
        formatter: this.shiftTimeFormatter,
      },
      {
        dataField: "day3",
        // text: "Wednesday",
        searchable: false,
        editable: false,

        headerFormatter: this.headerFormatter,

        headerStyle: () => {
          return { width: "12%" };
        },
        formatter: this.shiftTimeFormatter,
      },
      {
        dataField: "day4",
        // text: "Thursday",
        searchable: false,
        headerFormatter: this.headerFormatter,
        editable: false,

        headerStyle: () => {
          return { width: "12%" };
        },
        formatter: this.shiftTimeFormatter,
      },
      {
        dataField: "day5",
        // text: "Friday",
        searchable: false,
        headerFormatter: this.headerFormatter,
        editable: false,

        headerStyle: () => {
          return { width: "12%" };
        },
        formatter: this.shiftTimeFormatter,
      },
      {
        dataField: "day6",
        // text: "Saturday",
        searchable: false,
        headerFormatter: this.headerFormatter,
        editable: false,

        headerStyle: () => {
          return { width: "12%" };
        },
        formatter: this.shiftTimeFormatter,
      },
      {
        dataField: "day7",
        // text: "Sunday",
        searchable: false,
        headerFormatter: this.headerFormatter,
        editable: false,

        headerStyle: () => {
          return { width: "12%" };
        },
        formatter: this.shiftTimeFormatter,
      },
      {
        dataField: "addNewRowButton",
        text: "",
        searchable: false,
        editable: false,
        headerStyle: () => {
          return { width: "4%" };
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              {row.addNewRowButton && (
                 <button onClick={() => this.addNewRowWithSameUser(row, row.rowNo)}>
               
                  {/* <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.625 3.2H11.375V4.8H2.625V3.2ZM0 0H14V1.6H0V0ZM5.25 6.4H8.75V8H5.25V6.4Z" />
                  </svg> */}
                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25 21V18H23V21H20.78H20V23H20.78H23V26H25V23H27.063H28V21H27.063H25ZM10 13H21V15H10V13ZM10 17H21V19H10V17ZM10 21H18V23H10V21Z" fill="#2CAF92" />
                  </svg>

                </button>
              )}
            </>
          );
        },
      },
    ];

    const { showChangeLogModal, showHideChangeLogModal } = this.props;
    return (
      <div>
        <Modal
          className="bulk-entry-modal"
          show={showChangeLogModal}
          onHide={showHideChangeLogModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* <div className="navigation-menus d-flex">
            <div className="added-filter-grp d-inline-flex mr-auto">
             
            </div>
            <div>
            </div>
          </div> */}

            {this.state.isDataLoading ? (
              <ReactPageLoader useas={"changeLog"} />
            ) : (
              <div className={this.state.tableClassName}>
                <div className="userViewTable">
                  <div className="schedule-user-view-table">
                    <ToolkitProvider
                      keyField="rowNo"
                      data={this.state.tableData}
                      columns={Columns}
                      rowEvents={this.rowEvents}
                      search={{
                        afterSearch: (newResult: any) => {
                          this.searchedData(newResult);
                        },
                      }}
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          <div className="userViewNavigation">
                            <div className="navigation-menus d-flex">
                              <div
                                className={
                                  false
                                    ? "schedule-table-search p-none"
                                    : "schedule-table-search"
                                }
                              >
                                <SearchBar
                                  {...props.searchProps}
                                  placeholder="Search..."
                                  ref={this.searchBar}
                                  CleanSearch={this.CleanSearch.bind(this)}
                                />
                              </div>

                              <div className="calender-container disabled">
                                <div
                                  className={
                                    this.state.calenderShow
                                      ? "calender-display open"
                                      : "calender-display"
                                  }
                                  onClick={this.showCalender}
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                                    <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                                  </svg>

                                  <span className="prev-Date">
                                    {this.state.payPeriodDates[0]}
                                  </span>
                                  <span className="seperator"> - </span>
                                  <span className="next-Date">
                                    {this.state.payPeriodDates[1]}
                                  </span>
                                  <span className="chevron-down">
                                    <FiChevronDown />
                                  </span>
                                </div>
                                {this.state.calenderShow && (
                                  <div className="calender">
                                    <OutsideClickHandler
                                      onOutsideClick={this.hideCalender}
                                    >
                                      <Calendar
                                        calendarType={"US"}
                                        onChange={this.calenderChange}
                                        value={this.state.calenderSelectDate}
                                        minDate={this.state.minDate}
                                      />
                                    </OutsideClickHandler>
                                  </div>
                                )}
                               
                              </div>
                            </div>
                            {(this.state.warningCount > 0 && this.state.isWarning )&& (
                                  <div className="notification-banners red-bg">
                                    <div className="mr-auto">
                                      <svg
                                        width="20"
                                        height="18"
                                        viewBox="0 0 20 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M19.0598 15.4202L16.0798 10.2602C15.4998 9.28016 14.5798 7.66016 13.9998 6.66016L11.0398 1.52016C10.4598 0.540156 9.53979 0.540156 8.95979 1.52016L5.99979 6.68016C5.41979 7.66016 4.49979 9.28016 3.91979 10.2802L0.93979 15.4202C0.35979 16.4002 0.83979 17.2202 1.97979 17.2202H18.0198C19.1598 17.2202 19.6198 16.4002 19.0598 15.4202ZM10.5798 14.2202C10.4198 14.3602 10.2198 14.4402 9.99979 14.4402C9.77979 14.4402 9.57979 14.3602 9.41979 14.2202C9.25979 14.0802 9.17979 13.8802 9.17979 13.6202C9.17979 13.4002 9.25979 13.2002 9.41979 13.0402C9.57979 12.8802 9.77979 12.8002 9.99979 12.8002C10.2198 12.8002 10.4198 12.8802 10.5798 13.0402C10.7398 13.2002 10.8198 13.3802 10.8198 13.6202C10.8198 13.8802 10.7398 14.0802 10.5798 14.2202ZM10.7798 8.20016L10.5398 10.8802C10.5198 11.2002 10.4598 11.4402 10.3798 11.6202C10.2998 11.8002 10.1598 11.8802 9.95979 11.8802C9.75979 11.8802 9.61979 11.8002 9.55979 11.6402C9.47979 11.4802 9.41979 11.2202 9.39979 10.8802L9.21979 8.28016C9.17979 7.78016 9.17979 7.40016 9.17979 7.18016C9.17979 6.88016 9.25979 6.64016 9.41979 6.48016C9.57979 6.32016 9.77979 6.22016 10.0398 6.22016C10.3598 6.22016 10.5598 6.32016 10.6598 6.54016C10.7598 6.76016 10.8198 7.06016 10.8198 7.48016C10.8198 7.72016 10.7998 7.96016 10.7798 8.20016Z"
                                          fill="#B12A18"
                                        />
                                      </svg>
                                     There {" "}
                                      {this.state.warningCount > 1
                                        ? `are ${this.state.warningCount} errors.`
                                        : `is ${this.state.warningCount} error.`}{" "}
                                      Please fix them and try again.
                                    </div>
                                  </div>
                                )}
                          </div>
                          <div className="table">
                            <BootstrapTable
                              {...props.baseProps}
                              keyField="rowNo"
                              condensed
                              cellEdit={this.cellEdit}
                              rowEvents={this.rowEvents}
                              rowClasses={this.rowClasses}
                            />
                          </div>
                          {this.state.addNewRow && (
                            <Button
                              type="button"
                              className="btn btn-outline-primary add-row-btn"
                              // disabled={this.state.showDeactivatedPos}
                              //onClick={this.addNewRowClickHandler}
                              onClick={() => this.CleanSearchNew()}
                            >
                              Add Row
                            </Button>
                          )}
                          {this.state.isActionFooter && (
                            <div className="fixed-action">
                              <div className="d-flex align-content-center flex-wrap">
                                <div className="mr-auto message">
                                  <span>You have unsaved changes</span>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-discard"
                                  onClick={() => {
                                    this.setState({
                                      isActionFooter: false,
                                      tableData: [],
                                      isWarning:false,
                                      warningCount:0
                                    });
        $(`.bulk-entry-modal`).find('.react-bootstrap-table').removeClass('scroll-true');

                                  }}
                                >
                                  Discard
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={this.handleSave}
                                  ref={this.shiftSaveBtnRef}
                                  disabled={this.state.isWarning}
                                >
                                  {this.state.shiftButtonSpinner && (
                                    <Spinner
                                      className="mr-2"
                                      size="sm"
                                      animation="border"
                                    />
                                  )}
                                  Create Shifts
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
