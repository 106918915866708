import * as React from "react";
import { Dropdown } from "react-bootstrap";
// import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

export class LaborSummarySubTypeSelector extends React.Component<any, any> {
  private inputElement: any;

  private summarySubTypes = [
    { label: "Sub Total by Department", value: "No" },
    { label: "Sub Total by Position", value: "Yes" },
  ];

  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      selectedItem: { label: "Sub Total by Department", value: "No" },
      summarySubTypes: this.summarySubTypes,
      inputValue: "",
    };
  }

  componentDidMount = () => {
    ;
    const { defaultValue } = this.props;
    if (defaultValue === "Yes") {
      this.handleDropdownChange({
        label: "Sub Total by Position",
        value: "Yes",
      });
    } else {
      this.handleDropdownChange(this.state.selectedItem);
    }
  };

 
  handlesummarySubTypesearch = (e: { target: { value: string } }) => {
    const updatedList = this.state.summarySubTypes.filter((item) => {
      return (
        item?.label
          ?.toString()
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  };

  handleDropdownChange = (item: any) => {
    ;
    this.setState({ selectedItem: item });
    this.props?.onSummarySubTypeChange(item.value);
    this.resetSerchBox();
  };

  resetSerchBox = () => {
    this.setState({ filterData: this.state.summarySubTypes, inputValue: "" });
  };

  render() {
    return (
      <Dropdown className="single-select">
        <Dropdown.Toggle id="Modal-empType">
          <span
              style={{
                width: "95%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              {this.state.selectedItem.label}
            </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.summarySubTypes.map((item: any, idx: any) => (
             
                <Dropdown.Item onClick={() => this.handleDropdownChange(item)}>
                  {item.label}
                </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
