import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModuleReports } from "./../../Common/Services/ModuleReports";

export class BankAccountSelector extends React.Component<any, any> {
    //private inputElement: any;
    constructor(props: any) {
        super(props);
        //this.inputElement = React.createRef();
        this.state = {
            placeHolder: "Select",
            filterData: [],
            BankAccountList: [],
            inputValue: "",
        };
    }
    getBankAccounts = async (hotelIds: string = "") => {
        try {
            const result: string[] = await ModuleReports.GetBankAccounts(hotelIds);
            if (result?.length > 0) {
                this.setState({ BankAccountList: result, filterData: result }, () => {
                    const { defaultValue } = this.props;
                    debugger;
                    let selectedAccount = this.state.BankAccountList.filter(e => e.oprID === defaultValue);
                    if (selectedAccount.length > 0) {
                        this.AccountSelect(selectedAccount[0].name);
                        this.props.onBankAccountChange(selectedAccount[0]);
                    } else {
                        this.AccountSelect("Select");
                        this.props.onBankAccountChange("");
                    }
                });
            }
        } catch (error) {
            // Need to handle this
            console.log(error);
        }
    };

    componentDidMount = () => {
        if (this.props?.hotelIds) {
            this.updateAccountDDL(this.props?.hotelIds);
        }
    };

    updateAccountDDL(hotelIds: string) {
        debugger;
        if (!hotelIds) {
            this.setState({ BankAccountList: [], filterData: [] });
            this.AccountSelect("Select");
            this.props.onBankAccountChange("");
        }
        else {
            this.getBankAccounts(hotelIds);
        }
    }

    componentDidUpdate = (prevProps) => {
        const { defaultValue } = this.props;
        if (prevProps?.defaultValue !== defaultValue && !defaultValue) {
            let selectedAccount = this.state.BankAccountList.filter(e => e.oprID === defaultValue);
            if (selectedAccount.length > 0) {
                this.AccountSelect(selectedAccount[0].name);
                this.props.onBankAccountChange(selectedAccount[0]);
            } else {
                this.AccountSelect("Select");
                this.props.onBankAccountChange("");
            }
        }
    }

    handleLaborEmployeeSearch = (e: { target: { value: string } }) => {
        const updatedList = this.state.BankAccountList.filter((item) => {
            return (
                item?.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) >
                -1
            );
        });
        this.setState({ filterData: updatedList, inputValue: e.target.value });
    }

    handleDropdownChange = (e: any) => {
        this.props.onBankAccountChange(e);
        this.resetSerchBox();
    }

    resetSerchBox = () => {
        this.setState({ inputValue: "" });
        this.setState({ filterData: this.state.BankAccountList });
    };

    AccountSelect = (eventKey: any) => {
        this.setState({ inputValue: "" });
        this.setState({ placeHolder: eventKey });
    };

    render() {
        return (
            <Dropdown
                className="single-select"
                onSelect={this.AccountSelect}
            //onClick={onFocusInput}
            >
                <Dropdown.Toggle id="Modal-LaborEmployees">
                    {this.state.placeHolder === "Select Account" && (
                        <span className="placeholder">{this.state.placeHolder}</span>
                    )}
                    {this.state.placeHolder !== "Select Account" && (
                        <OverlayTrigger
                            placement="bottom-start"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id={"Employee" + this.state.placeHolder}>
                                    {this.state.placeHolder}
                                </Tooltip>
                            }
                        >
                            <span
                                style={{
                                    width: " 95%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                }}
                            >
                                {this.state.placeHolder}
                            </span>
                        </OverlayTrigger>
                    )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {/* <div className="search-sec">
            <input
              type="text"
              className="form-control"
              onChange={this.handleLaborEmployeeSearch}
              placeholder="Filter Account"
              value={this.state.inputValue}
              ref={this.inputElement}
            />
          </div> */}
                    <div className="list-area">
                        {this.state.filterData.map((item: any, idx: any) => (
                            <OverlayTrigger
                                placement="bottom-start"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id={"Employeedata" + idx}>{item.name}</Tooltip>
                                }
                                key={idx}
                            >
                                <Dropdown.Item
                                    eventKey={item.name}
                                    onClick={() => this.handleDropdownChange(item)}
                                >
                                    {item.name}
                                </Dropdown.Item>
                            </OverlayTrigger>
                        ))}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
