import * as React from "react";
import { Form, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import { LaborBudgetSetting } from "../../../Common/Services/LaborBudgetSetting";
import {
  IBudgetServiceRequestDto,
  IGetServiceLevelDto,
} from "../../../Common/Contracts/ILaborBudgetSettings";
import { Utils } from "../../../Common/Utilis";

let routeLeavingGuard: any = null;
let serviceLevelValue: any = null;
export class BudgetSettingServiceLevel extends React.Component<any, any> {
  private newServiceLevelRow: any;
  constructor(props: any) {
    super(props);
    this.newServiceLevelRow = React.createRef();
    this.state = {
      isflag: false,
      changeValue: false,
      hotelID: "",
      ddlStatusData: "",
      tableData: [],
      editedCell: [],
      duplicateServiceLevel: [],
      row: "",
      rowIndex: "",
      column: "",
      columnIndex: "",
      startEditingMon: false,
      startEditingTue: false,
      startEditingWed: false,
      startEditingThu: false,
      startEditingFri: false,
      startEditingSat: false,
      startEditingSun: false,
      columnDataToalMon: false,
      columnDataToalTue: false,
      columnDataToalWed: false,
      columnDataToalThu: false,
      columnDataToalFri: false,
      columnDataToalSat: false,
      columnDataToalSun: false,
      oldValue: 0,
      isdisabled: false,
    };
    this.routeLeavingGuardConfirm = this.routeLeavingGuardConfirm.bind(this);
  }

  componentWillReceiveProps(nextProps: {
    HotelID: any;
    bulkCopyBudetSetting: boolean;
  }) {
    let hotelId = this.state.hotelID;
    if (hotelId !== nextProps.HotelID && this.state.changeValue === false) {
      this.setState({ hotelID: nextProps.HotelID }, () => {
        this.updateData();
      });
    } else if (nextProps.bulkCopyBudetSetting === true) {
      this.updateData();
    } 
  }

  updateData = () => {
    let request = {} as IBudgetServiceRequestDto;
    request.hotelID = this.state.hotelID;
    this.bindServiceGrid(request);
  };

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.changeValue &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  bindServiceGrid = (request: IBudgetServiceRequestDto) => {
    LaborBudgetSetting.GetBudgetServiceLevel(request)
      .then(async (result: any | null) => {
        let ServiceData: any = [];
        if (result != null && result.length > 0) {
          let Data = result as [];
          Data.map((res, index) => ServiceData.push(res));
          for (let i = 0; i <= ServiceData.length - 1; i++) {
            if (Number.isInteger(ServiceData[i].avg) === true) {
              ServiceData[i].avg = ServiceData[i].avg + ".00";
            }  
          }
          this.setState({ tableData: ServiceData });

          for (let y = 1; y < 8; y++) {
            for (let x = 0; x < ServiceData.length; x++) {
              let txtID = "";
              txtID = `txt${y.toString()}day${x.toString()}`;
              $("#tblBudget")
                .find("input[id^='" + txtID + "']")
                .closest("td")
                .removeClass("red_cell");
            }
          }
        } else {
          this.setState({ tableData: ServiceData });
        }
        if (this.state.isflag === false) {
          this.props.isServiceData(this.state.tableData);
        }
        this.setState({ isflag: true });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error ${err}`);
        reject();
      });
  };

  cellEdit = cellEditFactory({
    mode: "click",
    autoSelectText: true,
    blurToSave: true,
    onUpdate: (rowId: any, dataField: any, newValue: any) => {},
    onStartEdit: (
      row: any,
      column: { dataField: string },
      rowIndex: any,
      columnIndex: any
    ) => {
      if (row.roomTypeName === "" && serviceLevelValue !== null) {
        row.roomTypeName = serviceLevelValue;
        let uniqueCell = rowIndex.toString() + "roomTypeName";
        this.state.editedCell.push(uniqueCell);
      } else {
        serviceLevelValue = null;
      }
      if (column.dataField === "mon") {
        this.setState({ startEditingMon: true });
      } else {
        this.setState({ startEditingMon: false });
      }
      if (column.dataField === "tue") {
        this.setState({ startEditingTue: true });
      } else {
        this.setState({ startEditingTue: false });
      }
      if (column.dataField === "wed") {
        this.setState({ startEditingWed: true });
      } else {
        this.setState({ startEditingWed: false });
      }
      if (column.dataField === "thu") {
        this.setState({ startEditingThu: true });
      } else {
        this.setState({ startEditingThu: false });
      }
      if (column.dataField === "fri") {
        this.setState({ startEditingFri: true });
      } else {
        this.setState({ startEditingFri: false });
      }
      if (column.dataField === "sat") {
        this.setState({ startEditingSat: true });
      } else {
        this.setState({ startEditingSat: false });
      }
      if (column.dataField === "sun") {
        this.setState({ startEditingSun: true });
      } else {
        this.setState({ startEditingSun: false });
      }
    },
    beforeSaveCell: (
      oldValue: any,
      newValue: any,
      row: any,
      column: { text: string },
      done: (arg0: boolean | undefined) => void
    ) => {
      if (column.text !== "Service Level") {
        let validationFlag: any = false;
        let saveValidationMessage: string;
        let saveValidationMessageFlag: boolean = false;
        if (newValue > 100) {
          saveValidationMessage = "Please enter value between 0 and 100";
          saveValidationMessageFlag = true;
          validationFlag = true;
        } else if (isNaN(newValue) === true) {
          saveValidationMessage = "Only take integer values";
          saveValidationMessageFlag = true;
          validationFlag = true;
        } else if (Number(oldValue) === Number(newValue)) {
          saveValidationMessage = "";
          saveValidationMessageFlag = false;
          validationFlag = true;
        } else if (newValue < 0) {
          saveValidationMessage = "Negative values are not accepted";
          saveValidationMessageFlag = true;
          validationFlag = true;
        } else if (Number.isInteger(Number(newValue)) === false) {
          saveValidationMessage = "Only take integer values";
          saveValidationMessageFlag = true;
          validationFlag = true;
        } else if (newValue.includes("+")) {
          saveValidationMessage = "Special char are not allowed";
          saveValidationMessageFlag = true;
          validationFlag = true;
        } else {
          validationFlag = false;
        }
        setTimeout(() => {
          if (validationFlag === true) {
            if (saveValidationMessageFlag === true) {
              done(false);
              Utils.toastError(saveValidationMessage);
            } else {
              done(false);
            }
          } else {
            done(true);
          }
        }, 0);
        this.setState({
          startEditingMon: false,
          startEditingTue: false,
          startEditingWed: false,
          startEditingThu: false,
          startEditingFri: false,
          startEditingSat: false,
          startEditingSun: false,
        });
        return { async: true };
      }
    },
    afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
      row.avg = (
        (Number(row.mon) +
          Number(row.tue) +
          Number(row.wed) +
          Number(row.thu) +
          Number(row.fri) +
          Number(row.sat) +
          Number(row.sun)) /
        7
      ).toFixed(2);

      if (column.dataField === "roomTypeName") {
        if (oldValue !== newValue) {
          let rowIndex = this.state.rowIndex;
          let columnIndex = this.state.column.dataField;
          let uniqueCell = rowIndex.toString() + columnIndex;
          this.state.editedCell.push(uniqueCell);
          row.action = "Edited" + column.dataField;
          if (newValue !== "") {
            newValue = newValue.replace(/ +(?= )/g, "");
            let camelRoomTypeName: any = newValue.trim().split(" ");
            for (let i = 0, x = camelRoomTypeName.length; i < x; i++) {
              camelRoomTypeName[i] =
                camelRoomTypeName[i][0].toUpperCase() +
                camelRoomTypeName[i].substr(1).toLowerCase();
            }
            camelRoomTypeName = camelRoomTypeName.join(" ");
            row.roomTypeName = camelRoomTypeName;
          } else {
            row.roomTypeName = newValue;
          }
          this.setState({ changeValue: true }, () => {
            this.props.isValidateTab(this.state.changeValue);
          });
        } 
      } else {
        if (Number(oldValue) !== Number(newValue)) {
          let rowIndex = this.state.rowIndex;
          let columnIndex = this.state.columnIndex.dataField;
          let uniqueCell = rowIndex.toString() + columnIndex;
          this.state.editedCell.push(uniqueCell);
          if (column.dataField === "mon") {
            row.mon = Number(newValue);
          } else if (column.dataField === "tue") {
            row.tue = Number(newValue);
          } else if (column.dataField === "wed") {
            row.wed = Number(newValue);
          } else if (column.dataField === "thu") {
            row.thu = Number(newValue);
          } else if (column.dataField === "fri") {
            row.fri = Number(newValue);
          } else if (column.dataField === "sat") {
            row.sat = Number(newValue);
          } else if (column.dataField === "sun") {
            row.sun = Number(newValue);
          }  
          this.setState({ changeValue: true }, () => {
            this.props.isValidateTab(this.state.changeValue);
          });
        }
      }
      setTimeout(() => {
        this.setState({
          startEditingMon: false,
          startEditingTue: false,
          startEditingWed: false,
          startEditingThu: false,
          startEditingFri: false,
          startEditingSat: false,
          startEditingSun: false,
        });
      }, 0);
    },
  });

  statusDropdownChange = (e: any, row: any) => {
    this.setState({ ddlStatusData: e });
    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (e === "Deactivate" && d.roomTypeID === row.roomTypeID) {
        return {
          ...d,
          status: "Inactive",
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0,
          sat: 0,
          sun: 0,
          avg: "0.00",
        };
      } else if (e === "Reactivate" && d.roomTypeID === row.roomTypeID) {
        return {
          ...d,
          status: "Active",
        };
      }  
      return d;
    });
    this.setState((curr: any) => ({ ...curr, tableData: newTableData }));
    if (e === "Delete") {
      let indexNumber: any;
      for (let i = 0; i <= newTableData.length - 1; i++) {
        if (row.roomTypeID === newTableData[i].roomTypeID) {
          indexNumber = i;
        }  
      }
      newTableData.splice(indexNumber, 1);
    }
    this.setState({ changeValue: true }, () => {
      this.props.isValidateTab(this.state.changeValue);
    });
  };

  handleDiscard = () => {
    let request = {} as IBudgetServiceRequestDto;
    request.hotelID = this.state.hotelID;
    this.bindServiceGrid(request);
    this.setState(
      {
        changeValue: false,
        columnDataToalMon: false,
        columnDataToalTue: false,
        columnDataToalWed: false,
        columnDataToalThu: false,
        columnDataToalFri: false,
        columnDataToalSat: false,
        columnDataToalSun: false,
        editedCell: [],
        duplicateServiceLevel: [],
      },
      () => {
        this.props.isValidateTab(false);
      }
    );
  };

  handleCalculatedState() {
    this.setState({ isSaved: true });
  }

  handleSave = () => {
    let request = {} as IGetServiceLevelDto;
    let tblServiceData = this.state.tableData;

    for (let y = 1; y < 8; y++) {
      for (let x = 0; x < tblServiceData.length; x++) {
        let txtID = "";
        txtID = `txt${y.toString()}day${x.toString()}`;
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")
          .closest("td")
          .removeClass("red_cell");
      }
    }

    let ServiceData: any = [];
    let saveValidationFlag: any;
    let saveValidationMessage: any;
    for (let i = 0; i <= tblServiceData.length - 1; i++) {
      let data: any = {};
      data.roomTypeID = tblServiceData[i].roomTypeID;
      data.roomTypeName = tblServiceData[i].roomTypeName;
      data.tenantID = tblServiceData[i].tenantID;
      data.hotelID = tblServiceData[i].hotelID;
      data.Mon = tblServiceData[i].mon === "" ? 0 : tblServiceData[i].mon;
      data.Tue = tblServiceData[i].tue === "" ? 0 : tblServiceData[i].tue;
      data.Wed = tblServiceData[i].wed === "" ? 0 : tblServiceData[i].wed;
      data.Thu = tblServiceData[i].thu === "" ? 0 : tblServiceData[i].thu;
      data.Fri = tblServiceData[i].fri === "" ? 0 : tblServiceData[i].fri;
      data.Sat = tblServiceData[i].sat === "" ? 0 : tblServiceData[i].sat;
      data.Sun = tblServiceData[i].sun === "" ? 0 : tblServiceData[i].sun;
      data.Avg = tblServiceData[i].avg;
      data.Action = "";
      data.Status = tblServiceData[i].status;
      ServiceData.push(data);
    }

    let dataRowTotalMon: any = 0;
    let dataRowTotalTue: any = 0;
    let dataRowTotalWed: any = 0;
    let dataRowTotalThu: any = 0;
    let dataRowTotalFri: any = 0;
    let dataRowTotalSat: any = 0;
    let dataRowTotalSun: any = 0;
    let allServiceLevels: any = [];
    let toLowerCaseServiceLevel: any = [];
    for (let i = 0; i < ServiceData.length; i++) {
      dataRowTotalMon = dataRowTotalMon + Number(ServiceData[i].Mon);
      dataRowTotalTue = dataRowTotalTue + Number(ServiceData[i].Tue);
      dataRowTotalWed = dataRowTotalWed + Number(ServiceData[i].Wed);
      dataRowTotalThu = dataRowTotalThu + Number(ServiceData[i].Thu);
      dataRowTotalFri = dataRowTotalFri + Number(ServiceData[i].Fri);
      dataRowTotalSat = dataRowTotalSat + Number(ServiceData[i].Sat);
      dataRowTotalSun = dataRowTotalSun + Number(ServiceData[i].Sun);
      allServiceLevels.push(ServiceData[i].roomTypeName);
      toLowerCaseServiceLevel.push(ServiceData[i].roomTypeName.toLowerCase());
    }

    for (let i = 0; i <= allServiceLevels.length; i++) {
      if (allServiceLevels[i] === "") {
        saveValidationFlag = true;
        Utils.toastError("Please enter service level name");
        if (this.newServiceLevelRow.current !== null) {
          this.newServiceLevelRow.current.focus();
        }  
        return;
      }
    }
    if (
      toLowerCaseServiceLevel.some(
        (val: any, i: any) => toLowerCaseServiceLevel.indexOf(val) !== i
      )
    ) {
      this.setState({ duplicateServiceLevel: [] });
      let duplicateArray: any = [];
      let duplicateArrayName: any = [];
      let duplicates = {};
      for (let i = 0; i < toLowerCaseServiceLevel.length; i++) {
        if (duplicates.hasOwnProperty(toLowerCaseServiceLevel[i])) {
          duplicates[toLowerCaseServiceLevel[i]].push(i);
        } else if (
          toLowerCaseServiceLevel.lastIndexOf(toLowerCaseServiceLevel[i]) !== i
        ) {
          duplicates[toLowerCaseServiceLevel[i]] = [i];
        }
      }
      duplicateArrayName = Object.keys(duplicates);
      duplicateArray = Object.values(duplicates);
      let anyArray: any = [];
      for (let i = 0; i < duplicateArray.length; i++) {
        let test = duplicateArray[i];
        for (let j = 0; j < test.length; j++) {
          let elem = test[j].toString() + "duplicateServiceLevel";
          anyArray.push(elem);
        }
      }
      let duplicateServiceLevel = anyArray.sort();
      let tableData = this.state.tableData;
      saveValidationFlag = true;
      if (duplicateArrayName.includes("denied")) {
        Utils.toastError("You cannot create a service level named Denied");
        if (this.newServiceLevelRow.current !== null) {
          this.newServiceLevelRow.current.focus();
        }  
      } else {
        Utils.toastError("Service Level name(s) already exists");
      }
      this.setState({
        tableData: tableData,
        duplicateServiceLevel: duplicateServiceLevel,
      });
      return;
    } else {
      this.setState({
        duplicateServiceLevel: [],
      });
    }
    if (dataRowTotalMon !== 100) {
      saveValidationFlag = true;
      saveValidationMessage = "Day total should be 100 %";
      this.setState({ columnDataToalMon: true });
      for (let x = 0; x < tblServiceData.length; x++) {
        let txtID = "";
        let y = 1;
        txtID = `txt${y.toString()}day${x.toString()}`;
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")
          .closest("td")
          .addClass("red_cell");
      }
    } else {
      this.setState({ columnDataToalMon: false });
    }
    if (dataRowTotalTue !== 100) {
      saveValidationFlag = true;
      saveValidationMessage = "Day total should be 100 %";
      this.setState({ columnDataToalTue: true });

      for (let x = 0; x < tblServiceData.length; x++) {
        let txtID = "";
        let y = 2;
        txtID = `txt${y.toString()}day${x.toString()}`;
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")
          .closest("td")
          .addClass("red_cell");
      }
    } else {
      this.setState({ columnDataToalTue: false });
    }
    if (dataRowTotalWed !== 100) {
      saveValidationFlag = true;
      saveValidationMessage = "Day total should be 100 %";
      this.setState({
        columnDataToalWed: true,
      });

      for (let x = 0; x < tblServiceData.length; x++) {
        let txtID = "";
        let y = 3;
        txtID = `txt${y.toString()}day${x.toString()}`;
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")
          .closest("td")
          .addClass("red_cell");
      }
    } else {
      this.setState({ columnDataToalWed: false });
    }
    if (dataRowTotalThu !== 100) {
      saveValidationFlag = true;
      saveValidationMessage = "Day total should be 100 %";
      this.setState({
        columnDataToalThu: true,
      });

      for (let x = 0; x < tblServiceData.length; x++) {
        let txtID = "";
        let y = 4;
        txtID = `txt${y.toString()}day${x.toString()}`;
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")
          .closest("td")
          .addClass("red_cell");
      }
    } else {
      this.setState({ columnDataToalThu: false });
    }
    if (dataRowTotalFri !== 100) {
      saveValidationFlag = true;
      saveValidationMessage = "Day total should be 100 %";
      this.setState({
        columnDataToalFri: true,
      });

      for (let x = 0; x < tblServiceData.length; x++) {
        let txtID = "";
        let y = 5;
        txtID = `txt${y.toString()}day${x.toString()}`;
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")
          .closest("td")
          .addClass("red_cell");
      }
    } else {
      this.setState({ columnDataToalFri: false });
    }
    if (dataRowTotalSat !== 100) {
      saveValidationFlag = true;
      saveValidationMessage = "Day total should be 100 %";
      this.setState({
        columnDataToalSat: true,
      });

      for (let x = 0; x < tblServiceData.length; x++) {
        let txtID = "";
        let y = 6;
        txtID = `txt${y.toString()}day${x.toString()}`;
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")
          .closest("td")
          .addClass("red_cell");
      }
    } else {
      this.setState({ columnDataToalSat: false });
    }
    if (dataRowTotalSun !== 100) {
      saveValidationFlag = true;
      saveValidationMessage = "Day total should be 100 %";
      this.setState({
        columnDataToalSun: true,
      });

      for (let x = 0; x < tblServiceData.length; x++) {
        let txtID = "";
        let y = 7;
        txtID = `txt${y.toString()}day${x.toString()}`;
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")
          .closest("td")
          .addClass("red_cell");
      }
    } else {
      this.setState({ columnDataToalSun: false });
    }
    if (saveValidationFlag === true) {
      Utils.toastError(saveValidationMessage);
    } else {
      this.setState({ isdisabled: true });
      request = ServiceData;
      LaborBudgetSetting.SaveBudgetServiceLevel(request)
        .then(async (result: any | null) => {
          if (result != null) {
            let data = result.result;
            if (data.saveStatus === "Success") {
              toast.success("Service levels saved successfully");
            } else {
              Utils.toastError(data.message);
            }
          }
          this.setState(
            {
              isSaved: true,
              changeValue: false,
              editedCell: [],
              duplicateServiceLevel: [],
              isdisabled: false,
            },
            () => {
              this.props.isValidateTab(this.state.changeValue);
            }
          );
          resolve();
          this.updateData();
          this.props.handleCalculatedState();
        })
        .catch((err: any) => {
          this.setState({ isdisabled: false });
          Utils.toastError(`Server Error ${err}`);
          reject();
        });
    }
    this.props.isValidateTab(this.state.changeValue);
  };

  saveNewPosition = (e: any, row: any) => {
    let tableData = this.state.tableData;
    e.target.value = e.target.value.replace(/ +(?= )/g, "");
    if (e.target.value !== "") {
      let camelRoomTypeName: any = e.target.value.trim().split(" ");
      for (let i = 0, x = camelRoomTypeName.length; i < x; i++) {
        camelRoomTypeName[i] =
          camelRoomTypeName[i][0].toUpperCase() +
          camelRoomTypeName[i].substr(1).toLowerCase();
      }
      camelRoomTypeName = camelRoomTypeName.join(" ");
      row.roomTypeName = camelRoomTypeName;
    } else {
      row.roomTypeName = e.target.value;
    }
    let rowIndex = this.state.tableData.length - 1;
    let uniqueCell = rowIndex.toString() + "roomTypeName";
    this.state.editedCell.push(uniqueCell);
    this.setState({ tableData: tableData });
    $(`#tblBudget #roomNameDiv`).closest("td").removeClass("startEditing");
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      if (row.roomTypeName === "") {
        let value: any = null;
        let updatedValue: any = null;
        if (e.target.value !== undefined) {
          value = e.target.value;
          value = value.toLowerCase().split(" ");
          for (let i = 0; i < value.length; i++) {
            value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
          }
          updatedValue = value.join(" ");
          serviceLevelValue = updatedValue;
        }
      } else {
        serviceLevelValue = null;
      }

      this.setState({ row: row, rowIndex: rowIndex });
    },

    onBlur: (e: { target: { value: string | number } }) => {
      let column = this.state.column;
      if (e.target.value === "" && column.dataField !== "roomTypeName") {
        e.target.value = 0;
      }  
    },

    onChange: (e: { target: { value: any } }, cell: any) => {
      let row = this.state.row;
      let columnIndex = this.state.columnIndex;
      let dataFeildName = columnIndex.dataField;
      let value = e.target.value;
      if (dataFeildName !== "roomTypeName") {
        if (value === "") {
        } else if (value.includes(".")) {
          let a: any = [];
          a = value.split(".");
          e.target.value = a[0];
        } else if (/^\d+$/.test(value) === false) {
          if (dataFeildName === "mon") {
            e.target.value = row.mon;
          } else if (dataFeildName === "tue") {
            e.target.value = row.tue;
          } else if (dataFeildName === "wed") {
            e.target.value = row.wed;
          } else if (dataFeildName === "thu") {
            e.target.value = row.thu;
          } else if (dataFeildName === "fri") {
            e.target.value = row.fri;
          } else if (dataFeildName === "sat") {
            e.target.value = row.sat;
          } else if (dataFeildName === "sun") {
            e.target.value = row.sun;
          } else {
            e.target.value = value;
          }
        } else {
          e.target.value = value;
        }
      }
    },
  };

  headerFormat = (column: any, colIndex: any) => {
    return <div>{column.text}</div>;
  };

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  handleInputValue(e: any, row: any, rowIndex: any, dayNo: any, typeID: any) {
    let val = e.target.value;
    let cursorIndex = e.target.selectionStart;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let txtID = "";

    if (/^\d+$/.test(val) === false) {
      val = val.replace(/[^\d]/g, "");
    }
    if (val.length > 3) {
      let a = val.replace(/[^\d]/g, "");
      val = a.substring(0, val.length - 1);
    }

    let dayName = "";
    if (Number(dayNo) === 1) {
      dayName = "mon";
    } else if (Number(dayNo) === 2) {
      dayName = "tue";
    } else if (Number(dayNo) === 3) {
      dayName = "wed";
    } else if (Number(dayNo) === 4) {
      dayName = "thu";
    } else if (Number(dayNo) === 5) {
      dayName = "fri";
    } else if (Number(dayNo) === 6) {
      dayName = "sat";
    } else if (Number(dayNo) === 7) {
      dayName = "sun";
    }

    data[rowIndex][dayName] = val;

    txtID = `txt${dayNo.toString()}day${rowIndex.toString()}`;
    this.setState({ tableData: data, changeValue: true }, () => {
      $("#tblBudget")
        .find("input[id^='" + txtID + "']")[0]
        .focus()
        // .prop("selectionEnd", cursorIndex);
    });

    $("#tblBudget")
      .find("input[id^='" + txtID + "']")
      .closest("td")
      .addClass("startEditing");
  }

  onFocusInputValue(e: any, row: any, rowIndex: any, dayNo: any, typeID: any) {
    let tablelength = this.state.tableData.length;
    for (let y = 1; y < 8; y++) {
      for (let x = 0; x < tablelength; x++) {
        let txtID = "";
        txtID = `txt${y.toString()}day${x.toString()}`;
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")
          .closest("td")
          .removeClass("startEditing");
      }
    }

    for (let x = 0; x < tablelength; x++) {
      let txtID = "";
      txtID = `txt${dayNo.toString()}day${x.toString()}`;
      $("#tblBudget")
        .find("input[id^='" + txtID + "']")
        .closest("td")
        .addClass("startEditing");
    }
  }

  onKeyPress(e: any, row: any, rowIndex: any, dayNo: any, typeID: any) {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    let tablelength = this.state.tableData.length;
    let isColumnChange = false;

    let val = e.target.value;
    if (val === "") {
      val = 0;
    }

    let dayName = "";
    if (Number(dayNo) === 1) {
      dayName = "mon";
    } else if (Number(dayNo) === 2) {
      dayName = "tue";
    } else if (Number(dayNo) === 3) {
      dayName = "wed";
    } else if (Number(dayNo) === 4) {
      dayName = "thu";
    } else if (Number(dayNo) === 5) {
      dayName = "fri";
    } else if (Number(dayNo) === 6) {
      dayName = "sat";
    } else if (Number(dayNo) === 7) {
      dayName = "sun";
    }

    let newValue = 0;
    if (Number(dayNo) === 1) {
      newValue = row.mon;
    } else if (Number(dayNo) === 2) {
      newValue = row.tue;
    } else if (Number(dayNo) === 3) {
      newValue = row.wed;
    } else if (Number(dayNo) === 4) {
      newValue = row.thu;
    } else if (Number(dayNo) === 5) {
      newValue = row.fri;
    } else if (Number(dayNo) === 6) {
      newValue = row.sat;
    } else if (Number(dayNo) === 7) {
      newValue = row.sun;
    }

    let saveValidationMessage = "";
    let saveValidationMessageFlag = false;

    if (newValue > 100) {
      saveValidationMessage = "Please enter value between 0 and 100";
      saveValidationMessageFlag = true;
    } else if (isNaN(newValue) === true) {
      saveValidationMessage = "Only take integer values";
      saveValidationMessageFlag = true;
    } else if (newValue < 0) {
      saveValidationMessage = "Negative values are not accepted";
      saveValidationMessageFlag = true;
    } else if (Number.isInteger(Number(newValue)) === false) {
      saveValidationMessage = "Only take integer values";
      saveValidationMessageFlag = true;
    }

    if (saveValidationMessageFlag) {
      data[rowIndex][dayName] = 0;
      let txtID = `txt${dayNo.toString()}day${rowIndex.toString()}`;
      this.setState({ tableData: data }, () => {
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")[0]
          .focus();
      });

      Utils.toastError(saveValidationMessage);

      e.preventDefault();
    } else {
      let avg = (
        (Number(row.mon) +
          Number(row.tue) +
          Number(row.wed) +
          Number(row.thu) +
          Number(row.fri) +
          Number(row.sat) +
          Number(row.sun)) /
        7
      ).toFixed(2);

      data[rowIndex]["avg"] = avg;

      if (tablelength === rowIndex + 1 && Number(dayNo) === 7) {
        dayNo = 1;
        rowIndex = 0;
        isColumnChange = true;
      } else if (tablelength === rowIndex + 1) {
        dayNo = Number(dayNo) + 1;
        rowIndex = 0;
        isColumnChange = true;
      }

      if (e.keyCode === 9) {
        let txtID = "";
        let nextIndex = 0;
        if (isColumnChange) {
          nextIndex = Number(rowIndex);
        } else {
          nextIndex = Number(rowIndex) + 1;
        }

        txtID = `txt${dayNo.toString()}day${nextIndex.toString()}`;

        this.setState({ tableData: data, oldValue: newValue }, () => {
          $("#tblBudget")
            .find("input[id^='" + txtID + "']")[0]
            .focus();
        });

        e.preventDefault();
      }
    }
  }

  tableOutSideClick = () => {
    let tablelength = this.state.tableData.length;
    for (let y = 1; y < 8; y++) {
      for (let x = 0; x < tablelength; x++) {
        let txtID = "";
        txtID = `txt${y.toString()}day${x.toString()}`;
        $("#tblBudget")
          .find("input[id^='" + txtID + "']")
          .closest("td")
          .removeClass("startEditing");
      }
    }
  };

  inputformatter = (cell: any, row: any, rowIndex: any, dayNo: any) => {
    const txtID = `txt${dayNo.toString()}day${rowIndex.toString()}`;
    let value = 0;
    if (Number(dayNo) === 1) {
      value = row.mon;
    } else if (Number(dayNo) === 2) {
      value = row.tue;
    } else if (Number(dayNo) === 3) {
      value = row.wed;
    } else if (Number(dayNo) === 4) {
      value = row.thu;
    } else if (Number(dayNo) === 5) {
      value = row.fri;
    } else if (Number(dayNo) === 6) {
      value = row.sat;
    } else if (Number(dayNo) === 7) {
      value = row.sun;
    }

    if (this.props.manageBudgets) {
      return (
        <div>
          <Form.Group>
            <Form.Control
              maxLength={3}
              type="text"
              key={txtID}
              id={txtID}
              onChange={(e: any) => {
                this.handleInputValue(e, row, rowIndex, dayNo, row.typeid);
              }}
              onSelect={(e: any) => {
                this.onFocusInputValue(e, row, rowIndex, dayNo, row.typeid);
              }}
              onKeyDown={(e: any) => {
                this.onKeyPress(e, row, rowIndex, dayNo, row.typeid);
              }}
              onBlur={() => {
                this.onBlurInputValue();
              }}
              value={value}
              disabled={ row.status === "Inactive"}
              autoComplete="off"
            />
          </Form.Group>
        </div>
      );
    } else {
      return <div>{value}</div>;
    }
  };

  onBlurInputValue = () => {
    this.props.isValidateTab(this.state.changeValue);
  };
  focusRoomName = (row: any, rowIndex: any) => {
    $(`#tblBudget #roomNameDiv`).closest("td").addClass("startEditing");
  };

  blurRoomName = (e: any, row: any, rowIndex: any) => {
    this.props.isValidateTab(this.state.changeValue);

    $(`#tblBudget #roomNameDiv`).closest("td").removeClass("startEditing");
  };

  changeRoomName = (e: any, row: any, rowIndex: any) => {
    let newTableData = [...this.state.tableData];
    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;


    newTableData = newTableData.map((d) => {
      if (d.roomTypeID === row.roomTypeID) {
        if (value.trim().toLowerCase() === "denied") {
          value = row.roomTypeName;
          Utils.toastError("Service Level can not be added as Denied.");
        }
        return {
          ...d,
          roomTypeName: value,
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
        changeValue: true,
      }),
      () => {
        $(`#tblBudget #roomName${row.roomTypeID}`)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  };

  render() {
    const columns = [
      {
        dataField: "roomTypeName",
        text: "Service Level",
        editable: (content: any, row: any) =>
          row.status !== "Inactive" && row.roomTypeName !== "Denied",
        events: {
          onClick: (e: any, column: any, columnIndex: any) => {
            this.setState({ column: column, columnIndex: columnIndex });
          },
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.roomTypeName === "") {
            return (
              <div className="edited" id="roomNameDiv">
                <Form.Group>
                  <Form.Control
                    id={`roomName${row.roomTypeID}`}
                    ref={this.newServiceLevelRow}
                    type="text"
                    onFocus={() => this.focusRoomName(row, rowIndex)}
                    onBlur={(e: any) => this.saveNewPosition(e, row)}
                    onChange={(e: any) => this.changeRoomName(e, row, rowIndex)}
                  />
                </Form.Group>
              </div>
            );
          } else {
            return (
              <div
                id="roomNameDiv"
                className={
                  this.state.editedCell.includes(
                    rowIndex.toString() + "roomTypeName"
                  )
                    ? this.state.duplicateServiceLevel.includes(
                        rowIndex.toString() + "duplicateServiceLevel"
                      )
                    : ""
                }
              >
                <Form.Group>
                  <Form.Control
                    disabled={row.roomTypeName.toLowerCase() === "denied" || row.status === "Inactive"}
                    id={`roomName${row.roomTypeID}`}
                    type="text"
                    value={row.roomTypeName}
                    onFocus={() => this.focusRoomName(row, rowIndex)}
                    onChange={(e: any) => this.changeRoomName(e, row, rowIndex)}
                    onBlur={(e: any) => this.blurRoomName(e, row, rowIndex)}
                  />
                </Form.Group>
                {row.status !== "" && row.status !== "Active" && (
                  <span className="service-status">{row.status}</span>
                )}
              </div>
            );
          }
        },
        footerEvents: {
          onClick: (e: any, column: any, columnIndex: any) => {
            
            if (this.props.manageBudgets) {
              const tableData = [...this.state.tableData];
              let newItemNumber: any;
              if (tableData[tableData.length - 1].roomTypeID > 0) {
                newItemNumber = -1;
              } else {
                newItemNumber = tableData[tableData.length - 1].roomTypeID;
                newItemNumber = newItemNumber - 1;
              }
              const item = {
                roomTypeID: newItemNumber,
                roomTypeName: "",
                mon: 0,
                tue: 0,
                wed: 0,
                thu: 0,
                fri: 0,
                sat: 0,
                sun: 0,
                avg: "0.00",
                action: "",
                status: "Active",
              };
              this.setState(
                {
                  tableData: [...this.state.tableData, item],
                  changeValue: true,
                  column: column,
                  columnIndex: columnIndex,
                },
                () => {
                  this.props.isValidateTab(this.state.changeValue);
                  this.newServiceLevelRow.current.focus();
                  this.newServiceLevelRow.current.scrollIntoView();
                }
              );
            }
          },
        },
        footer: this.props.manageBudgets ? "New Service Level" : "",
      },
      {
        dataField: "mon",
        text: "Mon",
        headerFormatter: this.headerFormat,
        formatter: this.inputformatter,
        formatExtraData: "1",
        editable: true,
        footer: (columnData: any[]) => {
          const total = columnData.reduce(
            (acc: any, item: any) => Number(acc) + Number(item),
            0
          );
          return (
            <span className={total === 100 ? "" : "wrong-value"}>
              <Form.Control plaintext readOnly value={total} />
            </span>
          );
        },
        footerClasses: (column: any, colIndex: any) => {
          if (this.state.columnDataToalMon === true) {
            return "rowTotalValidationTrue";
          } else {
            return "rowTotalValidationFalse";
          }
        },
      },
      {
        dataField: "tue",
        text: "Tue",
        headerFormatter: this.headerFormat,
        formatter: this.inputformatter,
        formatExtraData: "2",
        footer: (columnData: any[]) => {
          const total = columnData.reduce(
            (acc: any, item: any) => Number(acc) + Number(item),
            0
          );
          return (
            <span className={total === 100 ? "" : "wrong-value"}>
              <Form.Control plaintext readOnly value={total} />
            </span>
          );
        },
        footerClasses: (column: any, colIndex: any) => {
          if (this.state.columnDataToalTue === true) {
            return "rowTotalValidationTrue";
          } else {
            return "rowTotalValidationFalse";
          }
        },
      },
      {
        dataField: "wed",
        text: "Wed",
        headerFormatter: this.headerFormat,
        formatter: this.inputformatter,
        formatExtraData: "3",
        footer: (columnData: any[]) => {
          const total = columnData.reduce(
            (acc: any, item: any) => Number(acc) + Number(item),
            0
          );
          return (
            <span className={total === 100 ? "" : "wrong-value"}>
              <Form.Control plaintext readOnly value={total} />
            </span>
          );
        },
        footerClasses: (column: any, colIndex: any) => {
          if (this.state.columnDataToalWed === true) {
            return "rowTotalValidationTrue";
          } else {
            return "rowTotalValidationFalse";
          }
        },
      },
      {
        dataField: "thu",
        text: "Thu",
        headerFormatter: this.headerFormat,
        formatter: this.inputformatter,
        formatExtraData: "4",
        footer: (columnData: any[]) => {
          const total = columnData.reduce(
            (acc: any, item: any) => Number(acc) + Number(item),
            0
          );
          return (
            <span className={total === 100 ? "" : "wrong-value"}>
              <Form.Control plaintext readOnly value={total} />
            </span>
          );
        },
        footerClasses: (column: any, colIndex: any) => {
          if (this.state.columnDataToalThu === true) {
            return "rowTotalValidationTrue";
          } else {
            return "rowTotalValidationFalse";
          }
        },
      },
      {
        dataField: "fri",
        text: "Fri",
        headerFormatter: this.headerFormat,
        formatter: this.inputformatter,
        formatExtraData: "5",
        footer: (columnData: any[]) => {
          const total = columnData.reduce(
            (acc: any, item: any) => Number(acc) + Number(item),
            0
          );
          return (
            <span className={total === 100 ? "" : "wrong-value"}>
              <Form.Control plaintext readOnly value={total} />
            </span>
          );
        },
        footerClasses: (column: any, colIndex: any) => {
          if (this.state.columnDataToalFri === true) {
            return "rowTotalValidationTrue";
          } else {
            return "rowTotalValidationFalse";
          }
        },
      },
      {
        dataField: "sat",
        text: "Sat",
        headerFormatter: this.headerFormat,
        formatter: this.inputformatter,
        formatExtraData: "6",
        footer: (columnData: any[]) => {
          const total = columnData.reduce(
            (acc: any, item: any) => Number(acc) + Number(item),
            0
          );
          return (
            <span className={total === 100 ? "" : "wrong-value"}>
              <Form.Control plaintext readOnly value={total} />
            </span>
          );
        },
        footerClasses: (column: any, colIndex: any) => {
          if (this.state.columnDataToalSat === true) {
            return "rowTotalValidationTrue";
          } else {
            return "rowTotalValidationFalse";
          }
        },
      },
      {
        dataField: "sun",
        text: "Sun",
        headerFormatter: this.headerFormat,
        formatter: this.inputformatter,
        formatExtraData: "7",
        footer: (columnData: any[]) => {
          const total = columnData.reduce(
            (acc: any, item: any) => Number(acc) + Number(item),
            0
          );
          return (
            <span className={total === 100 ? "" : "wrong-value"}>
              <Form.Control plaintext readOnly value={total} />
            </span>
          );
        },
        footerClasses: (column: any, colIndex: any) => {
          if (this.state.columnDataToalSun === true) {
            return "rowTotalValidationTrue";
          } else {
            return "rowTotalValidationFalse";
          }
        },
      },
      {
        dataField: "avg",
        text: "Avg",
        editable: false,
        footer: "",
      },
      {
        hidden: !this.props.manageBudgets,
        dataField: "action",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.roomTypeName !== "Denied" || row.roomTypeID < 0) {
            return (
              <Dropdown className="more-action" alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={
                      row.roomTypeID > 0
                        ? row.status === "Active"
                          ? "Deactivate"
                          : "Reactivate"
                        : "Delete"
                    }
                    onSelect={(e: any) => this.statusDropdownChange(e, row)}
                  >
                    {row.roomTypeID > 0
                      ? row.status === "Active"
                        ? "Deactivate"
                        : "Reactivate"
                      : "Delete"}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          } else {
            return null;
          }
        },
        editable: false,
        footer: "",
      },
    ];

    return (
      <>
        <div className="service-level">
          {this.props.isBaneer && (
            <div className="notification-banners blue-bg">
              Budgets are recalculating. During recalculation your reports and
              data may not reflect your recent changes to budget settings.
            </div>
          )}
          <div className="service-ttl">
            Distribution of Cleaning Service for Rooms Occupied In %
          </div>
          <OutsideClickHandler onOutsideClick={() => this.tableOutSideClick()}>
            <BootstrapTable
              id="tblBudget"
              keyField="roomTypeID"
              data={this.state.tableData}
              columns={columns}
              hover
            />
          </OutsideClickHandler>
          {this.state.changeValue && this.props.manageBudgets && (
            <div className="fixed-action relative">
              <div className="d-flex align-content-center flex-wrap">
                <div className="mr-auto message">
                  <span>You have unsaved changes</span>
                </div>
                <button
                  type="button"
                  className="btn btn-primary btn-discard"
                  onClick={() => this.handleDiscard()}
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.handleSave()}
                  disabled={this.state.isdisabled}
                >
                  Save Changes
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
