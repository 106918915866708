import React from "react";
import { Button,  Form,  Container, Table } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { CommonService } from "../../../Common/Services/CommonService";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';



const groupImportError=(data)=>{
  if(!data || data?.length == 0) 
   return null
   
  const groupedData = {};
  data.forEach((row) => {
    if (!groupedData[row.srNo]) {
      groupedData[row.srNo] = [];
    }
    groupedData[row.srNo].push(row);
  });



  return groupedData


}

const downloadExcel = (data) => {
  debugger
  const fileName = `Import_user_errors`;

  const worksheet = XLSX.utils.json_to_sheet(data);

  const header = ["Row Number", "Column Name", "Error Message"] // columns name

  //  var wscols:any = [];
  //  for (var i = 0; i < header.length; i++) {  // columns length added
  //    wscols.push({ wch: header[i].length + 10 })
  //  }
  //  worksheet["!cols"] = wscols;

  XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" })
  const workbook = XLSX.utils.book_new();



  // adding heading to the first row of the created sheet.
  // sheet already have contents from above statement


  XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, fileName + ".xlsx", {});
};
export default class ImportUser extends React.Component<any, any> {


  private TenantDataFormSchema = Joi.object({
    tenant: Joi.number()
      .required()
      .min(1)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Tenant" };
        });
      }),
    userUniqueno: Joi.number(),

    file: Joi.object(),
    fileName: Joi.string().required().error((errors) => {
      return errors.map((error) => {
        return { message: "Please upload file" };
      });
    }),
  });

  state: any = {
    tenantData: {
      userUniqueno: this.props?.userUniqueNo,
      tenant: 0
    },
    output: null,
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    BlobbaseUrl:"",
  };

  componentDidMount = () => {
    this.getTenantsList();
    this.getBlobbaseUrl();
  };

  
    
      getBlobbaseUrl = () => {
       
        let keyName ="BlobbaseUrl";
        let BlobbaseUrl="";
         CommonService.GetInnflowConfigKeyValue(keyName)
           .then(async (result: any | null) => {
             console.log(result);        
             if (result !== null) {
               BlobbaseUrl = result;
               this.setState({ BlobbaseUrl:BlobbaseUrl });
             }
             
           });
       }



  onFieldChange = (event, inputPosition, isBlur = false) => {
    const { tenantData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;



    tenantData[fieldName] = value;


    if (inputPosition > this.state.inputPosition) {
      this.setState({ inputPosition });
    }
    this.setState({ tenantData }, () => {
      this.validateTenantDataFormSchema();
    });

  };

  validateTenantData = () => {
    const {
      tenant,
      fileName } = this.state.tenantData;

    const request: any = {

      //   UserUniqueno: userUniqueno,
      // TenantID:tenant,
      FileCode: "0",
      FileName: fileName

    }



    delete request["file"];
    this.setState({ isDataSaving: true });
    UserManagement.ImportNewUsers(request, this.state.tenantData?.file, tenant)
      .then((result) => {
      
        const res=result?.result
        const output= {commonError:res?.commonError, importErros: groupImportError(res?.importErros), importErrosArray:res?.importErros || []};
       const _tenantData=this.state.tenantData
        this.setState({ isDataSaving: false, output:output ||[],inputPosition:2,isFormNotValid: true, tenantData:{
          ..._tenantData,
          file:null,
          fileName:""
        } });

        if (result?.message === "Success") {
          toast.success(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
          
          });
  
        } else {
          Utils.toastError("Error While Importing Users.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            
          });
        }
       
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,

        });
      });
  };


  validateTenantDataFormSchema = () => {
    const valid = Joi.validate(
      this.state.tenantData,
      this.TenantDataFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }



    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };




  validationOnClick = (inputPosition) => {
  
      this.setState({ inputPosition }, () => this.validateTenantDataFormSchema());
    
  };

  onFileDrop = (files) => {


    this.onFieldChange(
      Utils.BuildCustomEventObject("file", files[0]),

      2
    );
    this.onFieldChange(
      Utils.BuildCustomEventObject("fileName", files[0]?.name),

      2
    );
  };



  getTenantsList = () => {

    UserManagement.GetTenantList()
      .then((data) => {
        this.setState({ tenantsList: data.result.result })
      })

  }
  
 
  
  downloadTemplate=()=>{
    let BlobbaseUrl =this.state.BlobbaseUrl;
    const filename="ImportUser.xlsx"
    const link=document.createElement("a")
    //link.href="https://innflowfilestoretest.blob.core.windows.net/innflowtemplate/ImportUser.xlsx"
    link.href=BlobbaseUrl+"innflowtemplate/ImportUser.xlsx"
    link.download=filename
    link.click()
  }

  render() {
    const {
      tenantData: {
        tenant,
        fileName
      },
      errors,
      inputPosition,
      isFormNotValid,
      isDataSaving,
      tenantsList,

    } = this.state;
 
    const acceptedFiles = `.xls,.xlsx`;

    return (
      <div className="importUsersModule">
        <Container fluid className="body-sec">
          <div className="page-heading underline d-flex">
            <div className="mr-auto">Import Users</div>
            <Button onClick={this.downloadTemplate} className="btn btn-primary">Download Template</Button>
            </div>

          <div className="upload-section d-flex">
            <div className="form-section">
              <div className="body-section d-flex">
                <Form.Group
                  controlId="type"
                  
                  className={
                    errors?.tenant && inputPosition >= 1
                      ? "validation-error"
                      : ""
                  }
                  onBlur={() => this.validationOnClick(1)}
                >
                  <Form.Label>Tenant</Form.Label>
                  {(tenantsList && tenantsList.length > 0 && <DropDownList
                    placeHolder={"Search Tenant"}
                    data={tenantsList}
                    isSearchRequired={true}
                    label={"tenantname"}
                    value={"tenantid"}
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onFieldChange(
                        Utils.BuildCustomEventObject(
                          "tenant",
                          item.tenantid
                        ),
                        1
                      );


                    }}

                    selectedItem={[
                      ...[{ tenantid: 0, tenantname: "Select Tenant" }],
                      ...tenantsList,
                    ].find((r) => +r.tenantid === tenant)}
                  />
                  )}


                  {this.getFieldError("tenant", 1)}
                </Form.Group>


                <Form.Group
                  //as={Row}
                  controlId="fileUpload"
                  onBlur={() => this.validationOnClick(2)}
                  className={
                    errors?.fileName && inputPosition >= 2
                      ? "validation-error-file"
                      : ""
                  }
                >
                  <Form.Label>Attachment</Form.Label>

                  <Dropzone onDrop={this.onFileDrop} multiple={false} accept={acceptedFiles}>
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                    }) => (
                      <section className="upload-area d-flex align-items-center justify-content-center">
                        <aside className="files">
                          <div
                            {...getRootProps({
                              className: "dropzone",
                            })}
                          >
                            <input {...getInputProps()} />
                            <div className="attached-file">
                              {fileName ? (
                                <EllipsisWithTooltip placement="bottom">
                                    {fileName}{" "}
                                </EllipsisWithTooltip>
                              ) : (
                                <p>
                                  Drag &amp; drop here or{" "}
                                  <span >browse file</span> to upload
                                </p>
                              )}
                            </div>
                          </div>
                        </aside>
                      </section>
                    )}
                  </Dropzone>
                  {this.getFieldError("fileName", 2)}
                </Form.Group>
              </div>
            </div>
            <div className="btn-section">
              <Button
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.validateTenantData}
              >
                Import Users
              </Button>
            </div>
          </div>

          {this.state?.output && this.state?.output?.commonError && this.state.output?.importErrosArray && (this.state.output?.commonError?.length > 0 || this.state.output?.importErrosArray?.length > 0) &&
            <div className="error-header d-flex align-items-center">
              <div className="error-header-ttl mr-auto">Uploaded Data Errors</div>
              <Button onClick={() => downloadExcel([...this.state?.output?.commonError, ...this.state.output?.importErrosArray])}>Download Errors</Button>
            </div>
          }
          <div className="error-table validation-error">
            <div className="column-table">
              {this.state?.output && this.state?.output?.commonError && this.state.output?.commonError.length > 0 && <>
                <div className="table-ttl">Error Details</div>

                <Table  bordered>
                  <thead>
                    <tr>
                      <th>column Name</th>
                      <th>Error Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.output?.commonError && this.state.output?.commonError.length > 0 && this.state.output?.commonError?.map((item) => <tr key={item?.columnName}>
                      <td>{item?.columnName}</td>
                      <td>{item?.errorMessage}</td>
                    </tr>)}
                  </tbody>
                </Table>
              </>}
            </div>

            <div className="row-table">
              {this.state?.output && this.state.output?.importErros && Object.keys(this.state.output?.importErros)?.length > 0 && <>
                <div className="table-ttl">Error Details</div>

                <Table  bordered >
                  <thead>
                    <tr>
                      <th>Row Number</th>
                      <th>column Name</th>
                      <th>Error Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(this.state.output?.importErros).map((key) => {
                      const rows = this.state.output?.importErros[key];
                      const rowspan = rows.length;
                      return rows.map((row, index) => {

                        return <tr key={row?.srNo}>
                          {index === 0 && <td rowSpan={rowspan}>{row?.srNo}</td>}
                          {/* {index !== 0 &&  <td></td>} */}
                          <td>{row?.columnName}</td>
                          <td>{row?.errorMessage}</td>
                        </tr>

                      });
                    })}
                    {/* {this.state.output?.importErros &&  this.state.output?.importErros.length > 0 &&  this.state.output?.importErros?.map((item)=> <tr  >
                <td rowSpan={item?.rowspan}>{item?.srNo}</td>
                <td>{item?.columnName}</td>
                <td>{item?.errorMessage}</td>
              </tr>)} */}
                  </tbody>
                </Table>
              </>}
            </div>
          </div>


        </Container>


      </div>
    );
  }
}