import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import OutsideClickHandler from "react-outside-click-handler";
import { Utils } from "../../Common/Utilis";

export class CompleteRooms extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      errors: false,
      reason: "",
      parentState: this.props.parentState,
    };
  }

  hideDropdown = (isReload = false) => {
    this.props?.handleHideDropdown(isReload);
  };

  onSkipRoom = () => {
    if (this.state.reason === "") {
      // alert("helloooo")
      this.validationOnClick(1);
    } else {
      //debugger;
      this.props.handleHideDropdown();
      let data = this.state.parentState.rightPanelData;
      data.reason = this.state.reason;
      // this.state.parentState.rightPanelDate.Reason = this.state.reason
      this.props.updateWorkOrderStatus(data);
    }
  };

  validationOnClick = (inputPosition) => {
    if (this.state.reason === "") {
      this.setState({ errors: true });
      Utils.toastError(
        "A reason is required if selecting to skip room PM for this instance."
      );
    } else {
      this.setState({ errors: false });
    }
  };

  reasonChange = (e: any) => {
    let value = e.target.value?.toString()?.trimLeft();
    this.setState({ reason: value }, () => {
      // if(this.props.parentState.PageType==="workOrderDetails"){
      // this.props.changesOfSlideOut(true);
      // }
    });
  };

  render() {
    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => {
            this.hideDropdown(false);
          }}
        >
          <div className="dropdown-wrapper">
            <div className="header">
              <h4>Complete Project</h4>
              <div>
                <DropdownItem>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                      fill="#84888C"
                    />
                  </svg>
                </DropdownItem>
              </div>
            </div>
            <div className="body">
              <Form.Group controlId="reason" className="d-flex">
                <Col
                  // sm={"12"}

                  // className="p-0"
                  sm={"12"}
                  className={`mb-0 p-0 ${
                    this.state.errors ? "validation-error" : ""
                  }`}
                  // onClick={() => this.validationOnClick(5)}
                >
                  <div className="alert">
                    There are {this.props.remainingCount} remaining locations in
                    this PM project. In order to complete this project, these
                    rooms will be marked as skipped.
                  </div>
                  <Form.Label>
                    Reason <span>*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Explain the reason why you cannot complete these rooms"
                    rows={3}
                    value={this.state.reason}
                    onChange={(e) => this.reasonChange(e)}
                    maxLength={300}
                    onBlur={() => {
                      this.setState({
                        reason:
                          this.state.reason?.trimRight(),
                      });
                    }}
                  />
                </Col>
              </Form.Group>
            </div>
            <div className="footer d-flex justify-content-end">
              <Button
                onClick={() => {
                  this.hideDropdown(false);
                }}
                className="cancel-btn"
              >
                Cancel
              </Button>
              <Button className="skip-btn" onClick={this.onSkipRoom}>
                Skip and Complete
              </Button>
            </div>
          </div>
        </OutsideClickHandler>
      </>
    );
  }
}
