import { IApiConfig } from "../Contracts/IApiConfig";

const hostname = window && window.location && window.location.hostname;
const app_Root = {} as IApiConfig;

if (hostname.indexOf("localhost") > -1) {
  app_Root.reportingHost = "https://repapi.inn-flow.net/api";
  // app_Root.reportingHost = "https://tsrepapi.inn-flow.net/api";
  app_Root.backendHost = "https://apiv2.inn-flow.net/api";
  app_Root.notificationHubURL = "https://apiv2.inn-flow.net/hubs";
  app_Root.dwollaConfigure = "production";
} else {
  app_Root.environment = "production";
  app_Root.reportingHost = "https://repapi.inn-flow.net/api";
  // app_Root.reportingHost = "https://tsrepapi.inn-flow.net/api";
  app_Root.backendHost = "https://apiv2.inn-flow.net/api";
  app_Root.notificationHubURL = "https://apiv2.inn-flow.net/hubs";
  app_Root.dwollaConfigure = "production";
}

export const API_ROOT = app_Root;
