import axios from "axios";
import {
  IDailyTasKDto,
  IDailyTaskCommentRequest,
  IDailyTaskCreationRequest,
  IDailyTaskCreationResponse,
  IDailyTaskDeleteFilesRequest,
  IDailyTaskSaveRequest,
  IDownloadFacilityDailyTaskFileRequest,
  IFacilityDailyTaskListRequest,
  IFacilityDailyTaskListResponse,
  ISaveDailyTaskFileRequest,
  ISaveFacilityDailyTaskFileRequestDto,
  IUpdatFacilityDailyTaskStatusRequest,
} from "../Contracts/IDailyTask";

import Instense from "./Axios-config";

export class FacilityDailyTask {
  private static ajaxRequestDailyTasks: any = null;
  private static ajaxRequestDailyTaskTemplate: any = null;

  public static GetFacilityDailyTask = async (
    request: IFacilityDailyTaskListRequest
  ): Promise<IFacilityDailyTaskListResponse[] | null> => {
    if (FacilityDailyTask.ajaxRequestDailyTasks) {
      FacilityDailyTask.ajaxRequestDailyTasks.cancel("Cancel");
    }
    FacilityDailyTask.ajaxRequestDailyTasks = axios.CancelToken.source();
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/FacilityDailyTask/GetFacilityDailyTask`;
    return Instense.post(url, request, {
      cancelToken: FacilityDailyTask.ajaxRequestDailyTasks.token,
    }).then((response) => {
      let result = response?.data?.result as IFacilityDailyTaskListResponse[];
      return result;
    });
  };

  public static UpdateFacilityDailyTaskStatus = async (
    request: IUpdatFacilityDailyTaskStatusRequest[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.map((data) => {
      data.tenantId = tenantID;
      return data;
    });
    const url = `/FacilityDailyTask/UpdateFacilityDailyTaskStatus`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data as any;
      return result;
    });
  };

  public static GetFacilityDailyTaskTemplate = async (
    request: IDailyTaskCreationRequest
  ): Promise<IDailyTaskCreationResponse[] | null> => {
    if (FacilityDailyTask.ajaxRequestDailyTaskTemplate) {
      FacilityDailyTask.ajaxRequestDailyTaskTemplate.cancel("Cancel");
    }

    FacilityDailyTask.ajaxRequestDailyTaskTemplate = axios.CancelToken.source();
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantId = tenantID;

    const url = `/FacilityDailyTask/GetFacilityDailyTaskTemplate`;

    return Instense.get(url, {
      params: { ...request },
      cancelToken: FacilityDailyTask.ajaxRequestDailyTaskTemplate.token,
    }).then((response) => {
      let result = response?.data?.result as IDailyTaskCreationResponse[];
      return result;
    });
  };

  public static DeactivateDailyTasks = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/FacilityDailyTask/DeactivateDailyTasks`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data as any;
      return result;
    });
  };

  public static SaveDailyTask = async (
    request: IDailyTaskSaveRequest
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantId = tenantID;

    const url = `/FacilityDailyTask/SaveFacilityDailyTask`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data as any;
      return result;
    });
  };

  public static SaveDailyTaskFile = async (
    request: ISaveDailyTaskFileRequest,
    files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;

    const url = `/FacilityDailyTask/SaveDailyTaskFile`;

    const formData: FormData = new FormData();
    files.forEach((element) => {
      formData.append("file", element);
    });

    formData.append("formField", JSON.stringify(request));
    return Instense.post(url, formData).then((response) => {
      let result = response?.data as any;
      return result;
    });
  };

  public static SaveFacilityDailyTaskFile = async (
    request: ISaveFacilityDailyTaskFileRequestDto,
    files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;

    const url = `/FacilityDailyTask/SaveFacilityDailyTaskFile`;

    const formData: FormData = new FormData();
    files.forEach((element) => {
      formData.append("file", element);
    });

    formData.append("formField", JSON.stringify(request));
    return Instense.post(url, formData).then((response) => {
      let result = response?.data as any;
      return result;
    });
  };
  public static DeleteDailyTaskFiles = async (
    request: IDailyTaskDeleteFilesRequest
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    // debugger

    request.tenantID = tenantID;
    const url = `/FacilityDailyTask/DeleteDailyTaskFile`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static DeleteFacilityDailyTaskFile = async (
    request: IDailyTaskDeleteFilesRequest
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    // debugger

    request.tenantID = tenantID;
    const url = `/FacilityDailyTask/DeleteFacilityDailyTaskFile`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static DownloadDailyTaskUploadFile = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    request.tenantId = tenantID;

    const url = `/FacilityDailyTask/DownloadDailyTaskUploadFile`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", request.filename);
        tempLink.click();
      }
    );
  };

  public static DownloadFacilityDailyTaskUploadFile = async (
    request: IDownloadFacilityDailyTaskFileRequest
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    request.tenantid = tenantID;

    const url = `/FacilityDailyTask/DownloadFacilityDailyTaskUploadFile`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", request.filename);
        tempLink.click();
      }
    );
  };

  public static GetDailyTaskUploadfile = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    // debugger

    request.tenantID = tenantID;
    const url = `/FacilityDailyTask/GetDailyTaskUploadfile`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetFacilityDailyTaskUploadfile = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    // debugger

    request.tenantID = tenantID;
    const url = `/FacilityDailyTask/GetFacilityDailyTaskUploadfile`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static DailyTaskCheckAddEditPermission = async () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    const userName = storage === null ? 0 : (storage.userName as any);
    const TenantID = tenantID;

    const url = `/FacilityDailyTask/CheckAddEditPermission`;
    return Instense.get(url, {
      params: { userName: userName, TenantID: TenantID },
    }).then((response) => {
      let result = response?.data?.result;
      return result;
    });
  };
  public static GetFacilityDailyTaskAssignedComment = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;

    const url = `/FacilityDailyTask/GetFacilityDailyTaskAssignedComment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result as any;
      return result;
    });
  };

  public static SaveFacilityDailyTaskComment = async (
    request: IDailyTaskCommentRequest
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/FacilityDailyTask/SaveFacilityDailyTaskComment`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data?.result as any;
      return result;
    });
  };

  public static SaveDailyTaskManagementData = async (
    request: IDailyTasKDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/FacilityDailyTask/SaveDailyTask`;
    return Instense.post(url, request).then((response) => {
      let result = response?.data as any;
      return result;
    });
  };

  public static GetFacilityAssignedTo = async (
    roleId: any,
    hid: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantId = storage === null ? 0 : (storage.tenantID as any);
    const request = {
      roleId,
      tenantId,
      hid,
    };
    const url = `/FacilityDailyTask/GetFacilityAssignedTo`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result as any;
      return result;
    });
  };
}
