import React from "react";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { resolve, reject } from "q";
import { WebTimeClockService } from "../Common/Services/WebTimeClock";
// import PageLoader from "../Common/Components/PageLoader";
import { ReactPageLoader } from "../Common/Components/ReactPageLoader";
import profile from "../Common/Assets/Images/profile.png";
import { Utils } from "../Common/Utilis";

export class EmployeeTimeClock extends React.Component<any, any> {
  private setTimer: any | undefined;
  constructor(props: any) {
    super(props);
    this.state = {
      loaderTrue: false,
      employeeData: [],
      getTrigger: 0,
    };
  }

  componentDidMount() {
    this.getEmployeeData("componentMount");
    this.setTimer = setInterval(this.getTriggerData, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.setTimer);
  }

  getTriggerData = () => {
    this.setState((prevState: any) => ({
      getTrigger: prevState.getTrigger + 1,
    }));
    // Get New Data after every 5 minutes 5*60=300
    if (this.state.getTrigger >= 300) {
      this.setState({ getTrigger: 0 });
      this.getEmployeeData("triggerCall");
    }
  };

  getEmployeeData = (type: any) => {
    if (this.props.parentState.hidValue === "") {
      return false;
    } else {
      if (type === "componentMount" || type === "ehidSelector") {
        this.setState({ loaderTrue: true });
      }

      let request: any = {};
      request.hotelID = this.props.parentState.hidValue;
      request.tenantID = this.props.parentState.authenticatedTenantID;

      WebTimeClockService.GetEmployeeTimeClockData(request)
        .then(async (result: any) => {
          const apiResult = result.result;
          if (apiResult.length > 0 && apiResult !== null) {
            let tempEmployeeData: any = [];
            apiResult.forEach((elem: any, idx: any) => {
              let pushObject: any = {};
              pushObject = elem;
              pushObject.rowNo = idx;
              pushObject.departmentName = elem.departmentName.replace(
                /\w\S*/g,
                function (txt: any) {
                  return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  );
                }
              );

              pushObject.positionName = elem.positionName.replace(
                /\w\S*/g,
                function (txt: any) {
                  return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  );
                }
              );

              // User Defined Items
              pushObject.fullName = `${elem.firstName} ${elem.lastName}`;
              pushObject.statusBar =
                elem.status === "S"
                  ? "employee-punch-out"
                  : "employee-punch-in";
              pushObject.scheduleBar =
                elem.status === "S"
                  ? `${pushObject.positionName} at ${pushObject.inTime}`
                  : `Punched in at ${pushObject.inTime}`;

              tempEmployeeData.push(pushObject);
            });
            this.setState({ employeeData: tempEmployeeData });
          } else {
            this.setState({ employeeData: [] });
            toast.success(`No user(s) scheduled for the day`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "timeClockIndex",
            });
          }
          this.setState({ loaderTrue: false });
          resolve();
        })
        .catch((err: any) => {
          Utils.toastError(`Server Error, ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "timeClockIndex",
          });
          this.setState({ loaderTrue: false });
          reject();
        });
    }
  };

  handleEmployeePunch = (userData: any) => {
    this.props.userPunchModalShow(userData);
  };

  render() {
    return (
      <div className="time-clock-container container-fluid">
        <div className="employee-view">
          <div className="time-clock-heading">Employees Scheduled to Work</div>
          {this.state.loaderTrue ? (
            <ReactPageLoader useas={"employeeTimeClock"} />
          ) : (
            <Row className="employee-container">
              {this.state.employeeData.map((data: any, id: any) => (
                <Col
                  className="employee-card d-flex"
                  key={id}
                  onClick={() => this.handleEmployeePunch(data)}
                >
                  <div className="employee-img">
                    <img
                      src={
                        data.imagePath === null || data.imagePath.trim() === ""
                          ? profile
                          : data.imagePath
                      }
                      className={
                        data.imagePath === null || data.imagePath.trim() === ""
                          ? "default"
                          : ""
                      }
                      alt={data.fullName}
                    />

                    <span className={data.statusBar}></span>
                  </div>
                  <div className="employee-details d-flex">
                    <EllipsisWithTooltip placement="bottom">
                      <span className="employee-name">{data.fullName}</span>
                    </EllipsisWithTooltip>
                    <span className="employee-status">
                      {data.status === "S" ? (
                        <>
                          <EllipsisWithTooltip placement="bottom">
                            {data.positionName}
                          </EllipsisWithTooltip>
                          at {data.inTime}
                        </>
                      ) : (
                        <>Punched in at {data.inTime}</>
                      )}
                    </span>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </div>
    );
  }
}
