import React from "react";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BootstrapTable from "react-bootstrap-table-next";
import { LaborSmileID } from "../../../../Common/Services/LaborSmileId";
import { ILaborSmileIdRequestDto } from "../../../../Common/Contracts/ILaborDayApprove";
import { reject, resolve } from "q";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import { Utils } from "../../../../Common/Utilis";

export default class SmileIdOverview extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      smileIdOverviewData: [],
      loaderTrue: false,
    };
  }

  smileIdOverviewAccounts(request: ILaborSmileIdRequestDto) {
    this.setState({ loaderTrue: true });
    LaborSmileID.GetLaborSmileIdDetails(request)
      .then(async (result: any | null) => {
        if (result.message === "Success") {
          this.setState({ smileIdOverviewData: result.result });
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "smileIdOverview",
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "smileIdOverview",
        });
        reject();
      });
  }

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.props.smileIdChecked(row.letterCode, row.hotelID);
    },
  };

  render() {
    const columns = [
      {
        dataField: "letterCode",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="hotelDetailsData">
            <span className="hotelLetterAbbr">{row.letterCode}</span>
            <EllipsisWithTooltip placement="bottom">
              <span className="hotelFullName">{row.hotelName}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "smilidCount",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdCountData">
            <span className="smileIdCount">{row.smilidCount}</span>
            <span className="smileIdLabel">Smile iD Failure</span>
          </div>
        ),
      },
      {
        dataField: "action",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => <div></div>,
      },
    ];
    return (
      <>
        {/* <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"smileIdOverview"}
        /> */}
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"hotelsListOTAR"} />
        ) : (
          <div className="smileIdOverview">
            <h1>Properties with Smile iD Failures</h1>
            <div className="smileIdOverviewTable">
              <BootstrapTable
                keyField="rowno"
                data={this.state.smileIdOverviewData}
                columns={columns}
                rowEvents={this.rowEvents}
                hover
              />
            </div>
          </div>
        )}
      </>
    );
  }
}
