import * as React from "react";
import { Container, Tabs, Tab, Dropdown, Modal, Button, Form, Spinner } from "react-bootstrap"; 
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import "react-input-range/lib/css/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { confirmAlert } from "react-confirm-alert";
import { resolve, reject } from "q";
import { BudgetSettingsLeftPanel } from "./BudgetSettingLeftPanel";
import { BudgetSettingsRightPanel } from "./BudgetSettingRightPanel";
import { BudgetSettingServiceLevel } from "./BudgetSettingServiceLevel";
import { HidSelector } from "../../../Common/Components/HidSelector";
import { SingleSelectSearch } from "../../../Common/Components/SingleSelectSearch";
import RouteLeavingGuardBudget from "../../../Common/Components/RouteLeavingGuardBudget";
import RouteLeavingGuard from "../../../Common/Components/RouteLeavingGuard";
import { ConfirmationModalNonTable } from "../../../Common/Components/ConfirmationModalNonTable";
import { LaborBudgetSetting } from "../../../Common/Services/LaborBudgetSetting";
import { IBudgetCopyBudgetRequestDto } from "../../../Common/Contracts/ILaborBudgetSettings";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../../Common/Services/RoleSubPermissionSetting";
import { Utils } from "../../../Common/Utilis";

//Do not use hotelDetailsData and hidSelectHotelName varaible apart from get Model and hidSelect function
// let hotelDetailsData: any;
// let hidSelectHotelName: any;
let routeLeavingGuard: any = null;
let hotelDetailsDataOnChangeValue: any;
const tooltipMessage =
  "Some of the changes you made to model settings require recalculation of report data. When you are done making changes, recalculate Models for accurate reporting data.";

const poupmsg = (
  <div className="recalculate-budget-desc">
    <div className="bold">
      Are you sure you want to leave without recalculating Models?
    </div>
    <div>
      Your changes have been saved; however reports and data will be inaccurate
      until you recalculate.
    </div>
  </div>
);

export class BudgetSettings extends React.Component<any, any> {
  private child: any;
  private childleft: any;
  private serviceLableChield: any;
  private mypositionRef: any;
  private mydepartmentRef: any;
  private mybudgetTypeRef: any;
  private itemNameRef: any;
  private singleSerch: any;
  private childHID: any;
  constructor(props: any) {
    super(props);
    this.child = React.createRef();
    this.childleft = React.createRef();
    this.serviceLableChield = React.createRef();
    this.mypositionRef = React.createRef();
    this.mydepartmentRef = React.createRef();
    this.mybudgetTypeRef = React.createRef();
    this.itemNameRef = React.createRef();
    this.singleSerch = React.createRef();
    this.childHID = React.createRef();
    this.state = {
      isSaved: false,
      Budgets: [],
      key: "allBudgets",
      isLeftPanelrefresh: true,
      hidList: [],
      hidFilterList: [],
      modalSetShow: false,
      modalShow: false,
      warningmodalSetShow: false,
      moreAction: [
        { name: "Copy Model Settings", eventKey: "copyBudgetSettings" },
      ],
      hidValue: "Select",
      hotelName: "Select",
      copyFromHid: 0,
      bulkCopyBudetSetting: false,
      ServicelebleData: [],
      fieldData: {
        uniqueno: 0,
        departmentID: 0,
        departmentName: "",
        positionID: 0,
        positionName: "",
        notes: "",
        budgetType: "",
        budgetTypeID: "",
        revenueType: "",
        subType: "",
        tenantID: 0,
        hotelID: 0,
        statisticsNo: "",
        itemName: "",
        isRoomAssign: false,
        budgetSettingList: [
          {
            text: "",
            value: "",
            srNo: "",
          },
        ],
        isBtnDisable: true,
        search: "",
      },
      manageBudgets: false,
      isPagePermission: false,
      changeValue: false,
      isBaneer: false,
    };

    this.handleBudget = this.handleBudget.bind(this);
    this.refreshleftpanel = this.refreshleftpanel.bind(this);
    this.isDuplicateRow = this.isDuplicateRow.bind(this);
    this.removeBlankRow = this.removeBlankRow.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.isBydefaultSelectedRow = this.isBydefaultSelectedRow.bind(this);
    this.isCopyBudgetId = this.isCopyBudgetId.bind(this);
    this.isValidateTab = this.isValidateTab.bind(this);
    this.isValidateModelPoupTab = this.isValidateModelPoupTab.bind(this);
    this.isServiceData = this.isServiceData.bind(this);
    this.handleCalculateBudget = this.handleCalculateBudget.bind(this);
    this.handleCalculatedState = this.handleCalculatedState.bind(this);
  }

  componentDidMount() {
    this.getMenuPermission();
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e: any) {
    if (
      this.state.changeValue &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  getMenuPermission = () => {
    let isPermission = false;
    let requestObject = {} as any;
    requestObject.permissionID = "ALL";
    requestObject.Module = "LaborManagement";
    requestObject.Type = "menu";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            if (element.permissionID === 10029) {
              isPermission = true;
              this.setState({ isPagePermission: true });
            }
          });
          if (isPermission) {
            this.getBudgetPermission();
          } else {
            window.location.href = "/unauthorize-access";
          }
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  selectHotelDropDown = (hotelDetails: any) => {
    
    if (this.state.isChangesTab) {
      this.setState({ isHandlebadget: "hidselect" });
      this.isValidateModelPoupTab();
      hotelDetailsDataOnChangeValue = hotelDetails;
    } else if (this.state.changeValue) {
      hotelDetailsDataOnChangeValue = hotelDetails;
      this.isValidateClaculateBudgetTab();
    } else {
      // Update the Hid Secltor Component state
      this.childHID?.current?.updateChildState(hotelDetails);
      hotelDetailsDataOnChangeValue = null;
      this.setState({
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,
        isBaneer: false,
      });
      this.bindData(hotelDetails);
    }
  };

  isValidateClaculateBudgetTab = () => {
    let bodyCss: string = "recalculate-budget-desc";
    let boldFont: string = "bold";
    let Message: any = "";
    Message = (
      <div
        className={
          bodyCss === "recalculate-budget-desc" ? "recalculate-budget-desc" : ""
        }
      >
        <div className={boldFont === "bold" ? "bold" : ""}>
          Are you sure you want to leave without recalculating Models?
        </div>
        <div>
          Your changes have been saved, however report and other data will be
          inaccurate until you have recalculated.
        </div>
      </div>
    );

    confirmAlert({
      title: "Recalculate Models",
      message: Message,
      buttons: [
        {
          className: "leave-without-recalc",
          label: "Leave Without Recalculating",
          onClick: () => this.isChangeBudget(),
        },
        {
          className: "return-to-settings",
          label: "Return To Settings",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "recalculate-modal",
    });
  };

  isChangeBudget = () => {
    this.setState({ changeValue: false }, () => {
      this.selectHotelDropDown(hotelDetailsDataOnChangeValue);
    });
  };

  bindData = (hotelDetails: any) => {
    // if (hotelID !== "Select") {
    this.setState({ isLeftPanelrefresh: true, isHandlebadget: "newbudget" });
    this.getBudget(hotelDetails);
    // }
  };

  getBudgetPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10029;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionID) {
              case 10039:
                this.setState({ manageBudgets: true });
                break;
            }
          });
        } else {
          Utils.toastError(result.message);
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  };

  componentWillUnmount() {
    window.localStorage.removeItem("hotelDetails");
    window.localStorage.removeItem("hotelName");
    window.localStorage.removeItem("hotelid");
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  updateState = (data: any) => {
    let hotelid = this.state.fieldData.hotelID;
    data.hotelID = hotelid;
    this.setState({
      fieldData: data,
    });
    this.child.current.handleRowClick(data);
  };

  updateStateNewBudget = (data: any) => {
    let hotelid = this.state.fieldData.hotelID;
    data.hotelID = hotelid;
    this.setState({
      fieldData: data,
    });
    this.child.current.handleBudgetLeft(data);
  };

  handleCalculatedState = () => {
    this.setState({ isSaved: true, changeValue: true, isBaneer: false });
  };

  handleCalculateBudget = (e: any) => {
    this.setState({
      isBtnDisable: true,
    });
    let currentDate: any = new Date();
    if (
      JSON.parse(localStorage.storage).currentUtcTime !== "" &&
      JSON.parse(localStorage.storage).currentUtcTime !== null &&
      JSON.parse(localStorage.storage).currentUtcTime !== undefined
    ) {
      currentDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    currentDate.setDate(currentDate.getDate());
    let currentdate = currentDate.getDate();
    let currentMonth = currentDate.getMonth() + 1;
    let currentYear = currentDate.getFullYear();

    let request: any = {};
    request.hotelID = this.state.fieldData.hotelID;
    request.layout = "365";
    request.date = currentMonth + "/" + currentdate + "/" + currentYear;
    request.type = "Model Setting";
    LaborBudgetSetting.SaveBudgetCalculation(request).then(
      async (result: any | null) => {
        if (result != null) {
          let data = result.result;
          if (data.saveStatus === "Success") {
            this.setState({
              isSaved: false,
              isBtnDisable: false,
              isBaneer: true,
              changeValue: false,
            });
            toast.success("Models recalculated successfully");
          } else {
            Utils.toastError(data.message);
          }
        }
      }
    );
  };

  handleBudget = (e: any) => {
    if (this.state.isChangesTab) {
      this.isValidateModelPoupTab();
    } else {
      this.setState({ key: "allBudgets" });
    }
    this.setState({ isHandlebadget: "newbudget" });
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.child.current.handleBudgetRight(fieldData);
    this.childleft.current.removeSelectedRow(fieldData);
  };

  refreshleftpanel = () => {
    this.setState({
      isLeftPanelrefresh: false,
      isBtnDisable: false,
      isSaved: false,
      isBaneer: false,
      changeValue: false,
    });
    let hotelid = this.state.fieldData.hotelID;
    let hotelDetails: any = [];
    hotelDetails.hotelID = hotelid;
    this.getBudget(hotelDetails);
  };

  isShowAlertBox = () => {
    this.setState({
      changeValue: true,
    });
  };

  isDuplicateRow = () => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.childleft.current.removeSelectedRow(fieldData);
  };

  removeBlankRow = () => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.childleft.current.removeNewBlankRow(fieldData);
  };

  handleTabSelect = () => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    localStorage.setItem("hotelid", JSON.stringify(fieldData.hotelID));
    localStorage.setItem("hotelName", JSON.stringify(fieldData.lettercode));
  };

  getBudget = (hotelDetails: any) => {
    if (this.state.isChangesTab === true) {
      hotelDetailsDataOnChangeValue = hotelDetails;
      this.isValidateModelPoupTab();
    } else {
      hotelDetailsDataOnChangeValue = null;
      this.btnReacalculatedStatus(hotelDetails?.hotelID);
      LaborBudgetSetting.GetLaborBudgetSummary(hotelDetails?.hotelID)
        .then(async (result: any | null) => {
          let BudgetData: any = [];
          if (result != null) {
            let budgetData = result;
            for (let i = 0; i < budgetData.length; i++) {
              let pushObject: any = {};
              if(budgetData[i].budgetType === "MPOR"){
                pushObject = budgetData[i];
                pushObject.budgetType = "MPCR"
                pushObject.budgetTypeID = "MPCR"
              }else{
                pushObject = budgetData[i];
              }
              pushObject.rowNo = i;
              BudgetData.push(pushObject);
            }

            this.setState({ Budgets: BudgetData });
            localStorage.setItem(
              "hotelid",
              JSON.stringify(hotelDetails.hotelID)
            );

            let new_state = Object.assign({}, this.state);
            let fieldData = new_state.fieldData;
            fieldData.hotelID = hotelDetails.hotelID;
            if (budgetData.length > 0) {
              fieldData.hotelID = result[0].hotelID;
            }
            fieldData.ishide = false;
            this.setState({ fieldData });
            if (this.state.isLeftPanelrefresh === true) {
              this.child.current.HideRightPanel();
            }
            let dataUniqueID = JSON.parse(
              localStorage.getItem("dataUniqueID")!
            );
            if (dataUniqueID !== "") {
              this.childleft.current.defaultSelectedRow(dataUniqueID);
            }
          }
          resolve();
        })
        .catch((err: any) => {
          Utils.toastError(`Server Error ${err}`);
          reject();
        });
    }
  };

  btnReacalculatedStatus = (hotelID: number) => {
    LaborBudgetSetting.budgetCalculationDetails(hotelID)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result === true) {
            this.setState({ isSaved: true, isBtnDisable: false });
          } else {
            this.setState({ isSaved: false });
          }
        } else {
          this.setState({ isSaved: false });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error ${err}`);
        reject();
      });
  };

  isBydefaultSelectedRow = (childState: any) => {
    if (childState !== "") {
      localStorage.setItem("dataUniqueID", JSON.stringify(childState));
    }
  };

  isCopyBudgetId = (childhotelid: any) => {
    this.setState({ copyFromHid: childhotelid });
  };

  isValidateTab = (childState: any) => {
    this.setState({ isChangesTab: childState });
  };

  isChangeBudgetTab = () => {
    if (this.state.isHandlebadget === "newbudget") {
      this.setState({ key: "allBudgets" });
      let new_state = Object.assign({}, this.state);
      let fieldData = new_state.fieldData;
      this.child.current.handleBudgetRight(fieldData);
      this.childleft.current.removeSelectedRow(fieldData);
      this.serviceLableChield.current.handleDiscard();
    } else if (this.state.isHandlebadget === "tabbudget") {
      this.setState({ key: "allBudgets" });
      this.serviceLableChield.current.handleDiscard();
    } else if (this.state.isHandlebadget === "hidselect") {
      this.serviceLableChield.current.handleDiscard();
      this.setState({ isChangesTab: false }, () => {
        this.selectHotelDropDown(hotelDetailsDataOnChangeValue);
        // this.getBudget(hotelDetailsDataOnChangeValue);
      });
    } else if (this.state.isHandlebadget === "bulkCopy") {
      this.setState({
        modalSetShow: true,
        warningmodalSetShow: false,
        copyFromHid: 0,
      });
      this.serviceLableChield.current.handleDiscard();
    }
    this.setState({ isChangesTab: false });
  };

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeBudgetTab(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  handleClose = () => {
    this.setState({ modalSetShow: false });
    this.setState({
      copyFromHid: 0,
    });
  };

  resetValue = () => {
    this.singleSerch.current.resetSerchBox();
  };

  isServiceData = (childState: any) => {
    this.setState({ ServicelebleData: childState });
  };

  handleBulkCopy = () => {
    if (this.state.copyFromHid === 0 && this.state.key === "allBudgets") {
      Utils.toastError("Please select EHID.");
      return;
    }
    if (this.state.copyFromHid === 0 && this.state.key === "serviceLevels") {
      Utils.toastError("Please select EHID.");
      return;
    }
    if (
      this.state.copyFromHid === Number(this.state.fieldData.hotelID) &&
      this.state.key === "serviceLevels"
    ) {
      Utils.toastError("Copy to and  copy from EHID can not be same.");
      return;
    }

    if (
      this.state.copyFromHid === Number(this.state.fieldData.hotelID) &&
      this.state.key === "allBudgets"
    ) {
      Utils.toastError("Copy to and  copy from EHID can not be same.");
      return;
    }

    let isDenied: any;
    let findRoomType = this.state.ServicelebleData;
    if (findRoomType.length === 0) {
      let roomTypeName = findRoomType.filter(
        (x: any) => x.roomTypeName === "Denied"
      )[0];
      isDenied = roomTypeName.roomTypeName;
      if (isDenied !== "Denied") {
        isDenied = "notDenied";
      }
    }

    if (this.state.Budgets.length > 0) {
      this.setState({ warningmodalSetShow: true });
    } else if (this.state.ServicelebleData.length > 1) {
      this.setState({ warningmodalSetShow: true });
    } else if (isDenied === "notDenied") {
      this.setState({ warningmodalSetShow: true });
    } else {
      this.saveCopyBudgets();
    }
  };

  warningCloseModel = () => {
    this.setState({ warningmodalSetShow: false });
  };

  saveCopyBudgets = () => {
    let request = {} as IBudgetCopyBudgetRequestDto;
    request.loadHid = Number(this.state.copyFromHid);
    request.deletedHid = Number(this.state.fieldData.hotelID);
    LaborBudgetSetting.SaveBudgetCopy(request)
      .then(async (result: any | null) => {
        if (result != null) {
          let data = result.result;
          if (data.saveStatus === "Success") {
            this.refreshleftpanel();
            this.isShowAlertBox();
            this.setState({
              warningmodalSetShow: false,
              bulkCopyBudetSetting: true,
              copyFromHid: 0,
            });
            if (this.state.key === "allBudgets") {
              toast.success("Model settings successfully copied");
            }
            if (this.state.key === "serviceLevels") {
              toast.success("Model settings successfully copied");
            }
            this.setState({ modalSetShow: false });
          } else {
            if (this.state.key === "serviceLevels") {
              Utils.toastError(data.message);
            }
            if (this.state.key === "allBudgets") {
              Utils.toastError(data.message);
            }
          }
        }
        this.setState({
          bulkCopyBudetSetting: false,
        });
        resolve();
      })
      .catch((err: any) => {
        this.setState({
          bulkCopyBudetSetting: false,
        });
        Utils.toastError(`Server Error ${err}`);
        reject();
      });
  };

  // hidSelect = (eventKey: any) => {
  //   if (this.state.isChangesTab) {
  //     this.setState({ isHandlebadget: "hidselect" });
  //     this.isValidateModelPoupTab();
  //     hidSelectHotelName = eventKey;
  //   } else {
  //     hidSelectHotelName = null;
  //     this.setState({ hidValue: eventKey });
  //     this.setState({ isLeftPanelrefresh: true });
  //     localStorage.setItem("hotelName", JSON.stringify(eventKey));
  //   }
  // };

  hasCheck = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
          fill="#2CAF92"
        />
      </svg>
    );
  };

  searchClear = () => {
    this.setState({ search: "" });
    this.setState({ hidList: this.state.hidFilterList });
  };

  searchItems = (e: any) => {
    const { hidFilterList } = this.state;
    let result = e.target.value;
    this.setState({ search: result });
    if (result !== "") {
      this.setState({
        hidList: hidFilterList.filter((hName) =>
          hName.hotelName.toLowerCase().includes(result.toLowerCase().trim())
        ),
      });
    } else {
      this.setState({ hidList: this.state.hidFilterList });
    }
  };

  render() {
    const hoverOutEllipsis = () => {
      $(".tooltip").removeClass("show");
    };
    const columns = [
      {
        dataField: "positionName",
        text: "",
        hidden: true,
      },
      {
        dataField: "departmentName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <div
              className="title"
              ref={this.mypositionRef}
              onWheel={() => hoverOutEllipsis()}
            >
              <EllipsisWithTooltip placement="bottom">
                {row.positionName}
              </EllipsisWithTooltip>
            </div>
            <div
              className="desc"
              ref={this.mydepartmentRef}
              onWheel={() => hoverOutEllipsis()}
            >
              <EllipsisWithTooltip placement="bottom">
                {row.departmentName}
              </EllipsisWithTooltip>
            </div>
          </div>
        ),
      },
      {
        dataField: "budgetType",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <div
              className="title1"
              ref={this.mybudgetTypeRef}
              onWheel={() => hoverOutEllipsis()}
            >
              <EllipsisWithTooltip placement="bottom">
                {row.budgetType === "MPOR" ? "MPCR" : row.budgetType }
              </EllipsisWithTooltip>
            </div>
            <div
              className="desc grey"
              ref={this.itemNameRef}
              onWheel={() => hoverOutEllipsis()}
            >
              <EllipsisWithTooltip placement="bottom">
                {row.itemName}
              </EllipsisWithTooltip>
            </div>
          </div>
        ),
      },
    ];

    const handleSelect = (eventKey: any) => {
      let new_state = Object.assign({}, this.state);
      let fieldData = new_state.fieldData;
      if (this.state.isChangesTab) {
        this.setState({ isHandlebadget: "tabbudget" });
        this.isValidateModelPoupTab();
        this.child.current.HideRightPanel();
        this.childleft.current.removeNewBlankRow(fieldData);
      } else {
        this.setState({ key: eventKey });
        this.child.current.HideRightPanel();
        this.childleft.current.removeNewBlankRow(fieldData);
      }
      localStorage.setItem("hotelid", JSON.stringify(fieldData.hotelID));
    };

    const moreAction = (eventKey: any) => {
      if (eventKey === "copyBudgetSettings") {
        if (this.state.isChangesTab) {
          this.setState({ isHandlebadget: "bulkCopy" });
          this.isValidateModelPoupTab();
        } else {
          this.setState({
            modalSetShow: true,
            warningmodalSetShow: false,
            copyFromHid: 0,
          });
        }
      }
    };

    return (
      <>

<RouteLeavingGuard
          when={this.state.isChangesTab}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
            if (this.state.isChangesTab) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />

        <RouteLeavingGuardBudget
          when={this.state.changeValue}
          Title={"Recalculate Models"}
          Message={poupmsg}
          okButton={"Leave Without Recalculating"}
          cancleButton={"Return To Settings"}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
            if (this.state.changeValue ) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />
        {this.state.isPagePermission && (
          <Container fluid className="body-sec" onClick={this.searchClear}>
            <div className="budget-settings-main">
              <div className="page-heading d-flex">
                <HidSelector
                  onClickEvent={this.selectHotelDropDown}
                  ref={this.childHID}
                  alertComponent={
                    this.state.changeValue || this.state.isChangesTab
                  }
                />

                <div className="mr-auto">Models</div>
                {this.state.isSaved && this.state.manageBudgets && (
                  <span className="schedule-lock" data-tip={tooltipMessage}>
                    <ReactTooltip
                      effect="solid"
                      event="mouseover mouseenter"
                      eventOff="mouseleave mouseout scroll mousewheel blur"
                      place="bottom"
                      multiline={true}
                    />
                    <span className="recalculate-budgets-wrapper">
                      <span className="pip-icon red"></span>
                      <button
                        type="button"
                        className="btn-outline-primary btn btn-primary"
                        onClick={this.handleCalculateBudget}
                        disabled={this.state.isBtnDisable}
                      >
                        {this.state.isBtnDisable && (
                          <Spinner
                            className="mr-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        Recalculate Models
                      </button>
                    </span>
                  </span>
                )}
                {this.state.fieldData.hotelID > 0 && (
                  <div className="action-group d-flex flex-row">
                    {this.state.manageBudgets && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleBudget}
                      >
                        New Model
                      </button>
                    )}
                    {this.state.manageBudgets && (
                      <>
                        <Dropdown
                          className="more-action"
                          onSelect={moreAction}
                          alignRight
                        >
                          <Dropdown.Toggle
                            className="btn-outline-primary btn btn-primary more"
                            id="dropdown-more"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                              <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                              <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                          </Dropdown.Toggle>
                          {this.state.manageBudgets && (
                            <Dropdown.Menu>
                              {this.state.moreAction.map(
                                (item: any, idx: any) => (
                                  <Dropdown.Item
                                    eventKey={
                                      this.state.moreAction[idx].eventKey
                                    }
                                    key={idx}
                                  >
                                    {this.state.moreAction[idx].name}
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          )}
                        </Dropdown>

                        <span className="divider"></span>
                      </>
                    )}
                  </div>
                )}
              </div>
              {this.state.fieldData.hotelID > 0 && (
                <Tabs
                  id="controlled-tab-example"
                  activeKey={this.state.key}
                  onSelect={handleSelect}
                >
                  <Tab eventKey="allBudgets" title="All Models">
                    {this.state.isBaneer && (
                      <div className="notification-banners blue-bg">
                        Models are recalculating. During recalculation your
                        reports and data may not reflect your recent changes to
                        model settings.
                      </div>
                    )}

                    <div className="section" style={{ display: "flex" }}>
                      <div className="budget-settings d-flex justify-content-start">
                        <BudgetSettingsLeftPanel
                          manageBudgets={this.state.manageBudgets}
                          ref={this.childleft}
                          Budgets={this.state.Budgets}
                          columns={columns}
                          updateState={this.updateState.bind(this)}
                          updateStateNewBudget={this.updateStateNewBudget.bind(
                            this
                          )}
                        />
                        <BudgetSettingsRightPanel
                          manageBudgets={this.state.manageBudgets}
                          ref={this.child}
                          updateState={[
                            this.updateState.bind(this),
                            this.state,
                          ]}
                          refreshleftpanel={this.refreshleftpanel}
                          isDuplicateRow={this.isDuplicateRow}
                          removeBlankRow={this.removeBlankRow}
                          handleCalculatedState={this.handleCalculatedState}
                          isBydefaultSelectedRow={this.isBydefaultSelectedRow}
                          isShowAlertBox={this.isShowAlertBox}
                        />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="serviceLevels" title="Service Levels">
                    <BudgetSettingServiceLevel
                      manageBudgets={this.state.manageBudgets}
                      ref={this.serviceLableChield}
                      HotelID={this.state.fieldData.hotelID}
                      bulkCopyBudetSetting={this.state.bulkCopyBudetSetting}
                      isValidateTab={this.isValidateTab}
                      isServiceData={this.isServiceData}
                      handleCalculatedState={this.handleCalculatedState}
                      isBaneer={this.state.isBaneer}
                    />
                  </Tab>
                </Tabs>
              )}

              <Modal
                className="copy-budget"
                show={this.state.modalSetShow}
                onHide={this.handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                animation={true}
                centered
                onClick={this.resetValue}
                backdrop="static"
                keyboard={false}
              >
                <OutsideClickHandler onOutsideClick={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Copy Model Settings</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group controlId="copyFrom">
                      <Form.Label>Copy From</Form.Label>
                      <SingleSelectSearch
                        ref={this.singleSerch}
                        isCopyBudgetId={this.isCopyBudgetId}
                      ></SingleSelectSearch>
                    </Form.Group>
                    <Form.Group controlId="copyTo">
                      <Form.Label>Copy To</Form.Label>
                      <Form.Control
                        plaintext
                        readOnly
                        defaultValue={this.state.hotelName}
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className={
                        this.state.warningmodalSetShow
                          ? "bulkCopyAlertButton"
                          : "bulkCopyButton"
                      }
                      onClick={this.handleBulkCopy}
                    >
                      Copy Settings
                    </Button>
                  </Modal.Footer>
                  {this.state.warningmodalSetShow === true && (
                    <ConfirmationModalNonTable
                      title={"Copy Model Settings"}
                      description={`${this.state.hotelName} already has model settings configured. Copying will overwrite all the existing settings. Are you sure you want to continue?`}
                      cancelButtonName={"Cancel"}
                      actionButtonName={"Copy Settings"}
                      warningCloseModel={this.warningCloseModel.bind(this)}
                      saveCopyBudgets={this.saveCopyBudgets.bind(this)}
                      onceClickable={true}
                    />
                  )}
                </OutsideClickHandler>
              </Modal>
            </div>
          </Container>
        )}
      </>
    );
  }
}
