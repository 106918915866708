import React from "react";
import GreyTickMark from "../../../../../Common/Assets/Images/icons/grey_tick_mark.svg";
import BootstrapTable from "react-bootstrap-table-next";

import { Button, Dropdown, Form, Spinner } from "react-bootstrap";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../../../../Common/Utilis";

let localStorageUser: any;
export default class BreakExceptionChildDetails extends React.Component<
  any,
  any
> {
  private laborDayDetails: any;
  private laborAPOChild: any;
  private laborUnderBudgetChild: any;
  private laborOverBudgetChild: any;
  private saveCommentBtnRef: any;

  constructor(props: any) {
    super(props);
    this.laborDayDetails = React.createRef();
    this.laborAPOChild = React.createRef();
    this.laborUnderBudgetChild = React.createRef();
    this.laborOverBudgetChild = React.createRef();
    this.saveCommentBtnRef = React.createRef();
    this.state = {
      loaderExceptionChild: false,
      renderTable: "",
      row: props.row,
      isBackVisible: true,
      isContinueVisible: true,
      breakExceptionSubDetails: [],
    };
  }

  componentDidMount() {
    this.getBreakExceptionChildData();
  }

  getBreakExceptionChildData = () => {
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ loaderExceptionChild: true });
    let request: any = {};
    request.userUniqueID = localStorageUser.user_UniqueID;
    request.selectDate = this.state.row.date;
    request.hotelId = this.state.row.hotelID;

    LaborDayApprove.GetLaborBreakAttestationHidSubDetails(request)
      .then(async (result: any | null) => {
        let laborDayApproveData: any = [];
        if (result !== null && result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            let pushObject: any = result[i];
            // 
            pushObject.weekDayName = this.state.row.weekDayName;
            // let status: string = "Needs Review";
            // pushObject.status = status;
            // let tempDate = pushObject.dayToApprove.split(",");
            // pushObject.dayToApprove = `${tempDate[0]} ${Number(tempDate[1])}, ${
            //   tempDate[2]
            // }`;
            // pushObject.action = "";
            pushObject.approveButtonSpinner = false;
            pushObject.approveModal = false;
            pushObject.resolveComment = "";
            laborDayApproveData.push(pushObject);
          }

          this.setState({ breakExceptionSubDetails: laborDayApproveData });
        }else{
          this.setState({ breakExceptionSubDetails: [] });
        }
        this.setState({ loaderExceptionChild: false });
      })
      .catch((error) => {
        this.setState({ loaderExceptionChild: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
      });
  };
  labirDayNavigation = (event: string) => {
    if (event === "continue") {
      if (this.state.renderTable === "autoPunchOut") {
        if (Number(this.state.row.overBudgetDept) !== 0) {
          this.setState({ renderTable: "overBudgetDepts" });
          if (Number(this.state.row.autoPunchOut) !== 0) {
            this.setState({ isBackVisible: true });
          } else {
            this.setState({ isBackVisible: false });
          }
        } else {
          this.setState({ renderTable: "underBudgetDepts" });
          if (
            Number(this.state.row.autoPunchOut) !== 0 ||
            Number(this.state.row.overBudgetDept) !== 0
          ) {
            this.setState({ isBackVisible: true });
          } else {
            this.setState({ isBackVisible: false });
          }
        }
      } else if (this.state.renderTable === "overBudgetDepts") {
        this.setState({ renderTable: "underBudgetDepts" });
        if (
          Number(this.state.row.autoPunchOut) !== 0 ||
          Number(this.state.row.overBudgetDept) !== 0
        ) {
          this.setState({ isBackVisible: true });
        } else {
          this.setState({ isBackVisible: false });
        }
      } else {
      }
    } else {
      if (this.state.renderTable === "overBudgetDepts") {
        this.setState({ renderTable: "autoPunchOut" });
        if (
          Number(this.state.row.overBudgetDept) !== 0 ||
          Number(this.state.row.underBudgetDept) !== 0
        ) {
          this.setState({ isContinueVisible: true });
        } else {
          this.setState({ isContinueVisible: false });
        }
      } else if (this.state.renderTable === "underBudgetDepts") {
        if (Number(this.state.row.overBudgetDept) !== 0) {
          this.setState({ renderTable: "overBudgetDepts" });
          if (Number(this.state.row.underBudgetDept) !== 0) {
            this.setState({ isContinueVisible: true });
          } else {
            this.setState({ isContinueVisible: false });
          }
          if (Number(this.state.row.autoPunchOut) !== 0) {
            this.setState({ isBackVisible: true });
          } else {
            this.setState({ isBackVisible: false });
          }
        } else {
          this.setState({ renderTable: "autoPunchOut" });
          if (
            Number(this.state.row.overBudgetDept) !== 0 ||
            Number(this.state.row.underBudgetDept) !== 0
          ) {
            this.setState({ isContinueVisible: true });
          } else {
            this.setState({ isContinueVisible: false });
          }
        }
      } else {
      }
    }
  };

  dataApprove = () => {
    // 
    let row = this.state.row;
    row.dayCount = row.dayCount - 1;
    if(row.dayCount > 0){
      // this.getBreakExceptionChildData()
      this.props.childApprove(row, "");
    }
    else{

      this.props.removeRow(row.rowNo);
    }

    // this.props.removeRow(row.rowNo);

    // if (Number(row.autoPunchOut) === 0) {
    //   if (Number(row.overBudgetDept) !== 0) {
    //     this.setState({
    //       renderTable: "overBudgetDepts",
    //       isBackVisible: false,
    //     });
    //   } else if (Number(row.underBudgetDept) !== 0) {
    //     if (Number(row.autoPunchOut) !== 0) {
    //       this.setState({
    //         isBackVisible: true,
    //       });
    //     } else {
    //       this.setState({
    //         isBackVisible: false,
    //       });
    //     }
    //     this.setState({ renderTable: "underBudgetDepts" });
    //   } else {
    //   }
    // }
    // if (renderTable === "autoPunchOut" && status === "Approved") {
    //   row.autoPunchOut = row.autoPunchOut - 1;
    //   if (Number(row.autoPunchOut) === 0 && Number(row.overBudgetDept) === 0) {
    //     row.status = "Ready to Approve";
    //   }
    //   this.props.childApprove(row, renderTable);
    //   this.setState({ row: row });
    //   if (Number(row.autoPunchOut) === 0) {
    //     if (Number(row.overBudgetDept) !== 0) {
    //       this.setState({
    //         renderTable: "overBudgetDepts",
    //         isBackVisible: false,
    //       });
    //     } else if (Number(row.underBudgetDept) !== 0) {
    //       if (Number(row.autoPunchOut) !== 0) {
    //         this.setState({
    //           isBackVisible: true,
    //         });
    //       } else {
    //         this.setState({
    //           isBackVisible: false,
    //         });
    //       }
    //       this.setState({ renderTable: "underBudgetDepts" });
    //     } else {
    //       this.props.removeRow(row.rowNo);
    //     }
    //   }
    // } else if (renderTable === "overBudget" && status === "Approved") {
    //   row.overBudgetDept = row.overBudgetDept - 1;
    //   if (Number(row.autoPunchOut) === 0 && Number(row.overBudgetDept) === 0) {
    //     row.status = "Ready to Approve";
    //   }
    //   this.props.childApprove(row, renderTable);
    //   this.setState({ row: row });
    //   if (Number(row.overBudgetDept) === 0) {
    //     if (Number(row.underBudgetDept) !== 0) {
    //       if (Number(row.autoPunchOut) !== 0) {
    //         this.setState({
    //           isBackVisible: true,
    //         });
    //       } else {
    //         this.setState({
    //           isBackVisible: false,
    //         });
    //       }
    //       this.setState({
    //         renderTable: "underBudgetDepts",
    //       });
    //     } else if (Number(row.autoPunchOut) !== 0) {
    //       this.setState({
    //         renderTable: "autoPunchOut",
    //         isContinueVisible: false,
    //       });
    //     } else {
    //       this.props.removeRow(row.rowNo);
    //     }
    //   }
    // } else if (renderTable === "underBudget" && status === "Approved") {
    //   row.underBudgetDept = row.underBudgetDept - 1;
    //   if (Number(row.autoPunchOut) === 0 && Number(row.overBudgetDept) === 0) {
    //     row.status = "Ready to Approve";
    //   }
    //   this.props.childApprove(row, renderTable);
    //   this.setState({ row: row });
    //   if (Number(row.underBudgetDept) === 0) {
    //     if (Number(row.overBudgetDept) !== 0) {
    //       if (Number(row.autoPunchOut) !== 0) {
    //         this.setState({
    //           isBackVisible: true,
    //         });
    //       } else {
    //         this.setState({
    //           isBackVisible: false,
    //         });
    //       }
    //       this.setState({
    //         renderTable: "overBudgetDepts",
    //         isContinueVisible: false,
    //       });
    //     } else if (Number(row.autoPunchOut) !== 0) {
    //       this.setState({
    //         renderTable: "autoPunchOut",
    //         isContinueVisible: false,
    //       });
    //     } else {
    //     }
    //   }
    // }
  };

  refreshDayAppChild() {
    if (this.laborAPOChild.current !== null) {
      this.laborAPOChild.current.refreshAPOChild();
    }

    if (this.laborUnderBudgetChild.current !== null) {
      this.laborUnderBudgetChild.current.refreshUnderBudgetChild();
    }

    if (this.laborOverBudgetChild.current !== null) {
      this.laborOverBudgetChild.current.refreshOverBudgetChild();
    }
  }

  handlePositionDetail(row: any) {
    let newrow = this.state.row;
    row.date = newrow.date;
    row.hotelID = newrow.hotelID;
    row.letterCode = newrow.letterCode;
    this.props.positionDetail(row);
  }

  laborDayApprove = (e: any, row: any, rowIndex: any) => {
    e.stopPropagation();
    let dataArr = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.breakExceptionSubDetails))
    );
    dataArr[rowIndex].approveModal = true;
    this.setState({ breakExceptionSubDetails: dataArr });
  };

  handleOutsideClick = (row: any, rowIndex: any) => {
    let dataArr = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.breakExceptionSubDetails))
    );
    dataArr[rowIndex].approveModal = false;
    this.setState({ breakExceptionSubDetails: dataArr });
  };

  resolveCommentChange = (e: any, row: any, rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.breakExceptionSubDetails))
    );
    data[rowIndex].resolveComment = e.target.value;
    this.setState({ breakExceptionSubDetails: data });
  };

  saveBreakExceptionComment = (row: any, rowIndex: any) => {
    if(row.resolveComment == ""){
        Utils.toastError(`Please enter the explanation.`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        return;
    }
    // 
    this.saveCommentBtnRef.current.disabled = true;
    let requestArray: any = [];
    let request: any = {};
    request.hotelID = row.hotelID;
    request.userUniqueNo = localStorageUser.user_UniqueID;
    request.tenantID = localStorageUser.tenantID;
    request.username = row.employeeName;
    request.selectDate = row.date;
    request.resolveComment = row.resolveComment;
    requestArray.push(request)
    LaborDayApprove.SaveLaborOverViewBreakAttestationResolve(requestArray)
      .then(async (result: any | null) => {
        // let laborDayApproveData: any = [];
        toast.success(
          `${row.weekDayName} - ${row.date} is resolved successfully`,
          {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "laborDayOverviewContainer",
          }
        );
        this.dataApprove()
        // this.getBreakExceptionChildData()
      })
      .catch((error) => {
        this.saveCommentBtnRef.current.disabled = false;
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
      });

  };
  headerFormat = (column: { text: React.ReactNode }, colIndex: any) => {
    return <span className="overBudgetDepartment">{column.text}</span>;
  };

  render() {
    const rowClasses = (row: any, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const columns = [
      {
        dataField: "employeeName",
        text: "Username",
        headerFormatter: this.headerFormat,
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.status === "Ready to Approve") {
            return (
              <div className="laborDayDetails">
                {/* <div className="laborDayStatus" data-tip={row.status}>
                  <span className="DayStatusApprove">
                    <ReactTooltip
                      effect="solid"
                      event="mouseover mouseenter"
                      eventOff="mouseleave mouseout scroll mousewheel blur"
                      place="bottom"
                    />
                  </span>
                </div> */}
                <div className="laborDetailsdayDate">
                  <span className="day">{row.employeeName}</span>
                  {/* <span className="date">{row.dayToApprove}</span> */}
                </div>
              </div>
            );
          } else {
            return (
              <div className="laborDayDetails">
                {/* <div className="laborDayStatus" data-tip={row.status}>
                  <span className="DayStatusPending">
                    <ReactTooltip
                      effect="solid"
                      event="mouseover mouseenter"
                      eventOff="mouseleave mouseout scroll mousewheel blur"
                      place="bottom"
                    />
                  </span>
                </div> */}
                <div className="laborDetailsdayDate">
                  <span className="day">{row.employeeName}</span>
                  {/* <span className="date">{row.dayToApprove}</span> */}
                </div>
              </div>
            );
          }
        },
      },
      {
        dataField: "comment",
        text: "User Comments",
        headerFormatter: this.headerFormat,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="autoPunchOutsData">
            <span className="autoPunchOutsCount">
            <EllipsisWithTooltip placement="bottom">
              {row.comment}
            </EllipsisWithTooltip>
              </span>
            {/* <span className="autoPunchOutsLabel">Break Exceptions</span> */}
          </div>
        ),
      },
      {
        dataField: "action",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <Button
              className={
                row.approveButtonSpinner
                  ? "btn-outline-primary laborDayReadyToApprove p-none"
                  : "btn-outline-primary laborDayReadyToApprove"
              }
              onClick={() => this.handlePositionDetail(row)}
            >
              {row.approveButtonSpinner && (
                <Spinner
                  className="mr-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Details
            </Button>
          );
        },
      },
      {
        dataField: "action",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <Button
                className={
                  row.approveButtonSpinner
                    ? "btn-outline-primary laborDayReadyToApprove p-none"
                    : "btn-outline-primary laborDayReadyToApprove"
                }
                onClick={(e: any) => this.laborDayApprove(e, row, rowIndex)}
              >
                {row.approveButtonSpinner && (
                  <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Resolve
              </Button>
              {row.approveModal && (
                <OutsideClickHandler
                  onOutsideClick={() => this.handleOutsideClick(row, rowIndex)}
                >
                  <div className="d-flex justify-content-end drop-menu">
                    <div className="add-entry height-346">
                      <div className="re-assign-modal">
                        <div className="">
                          {/* <div className="title height-50 d-flex align-items-center">
                            <div className="mr-auto">Resolution Details</div>
                          </div> */}
                          <div className="entry-body">
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Resolution Details</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                placeholder="Explanation required..."
                                value={row.resolveComment}
                                id={"txtchangenotes" + rowIndex}
                                maxLength={150}
                                onChange={(event: any) =>
                                  this.resolveCommentChange(
                                    event,
                                    row,
                                    rowIndex
                                  )
                                }
                              />
                            </Form.Group>
                          </div>
                          <div className="action-link">
                            <Button
                              className="btn-ghost"
                              //  ref={this.btnChangePostion}
                              id={"btnchange" + rowIndex}
                              onClick={(e: any) => {
                                this.handleOutsideClick(row, rowIndex);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="btn btn-primary"
                              //  ref={this.btnChangePostion}
                              ref={this.saveCommentBtnRef}
                              id={"btnchange" + rowIndex}
                              onClick={(e: any) =>
                                this.saveBreakExceptionComment(row, rowIndex)
                              }
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
            </>
          );
        },
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="more-action"
            alignRight
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                key={"ViewAllPunch"}
                eventKey={"ViewAllPunch"}
                onClick={() => this.handlePositionDetail(row)}
                // onClick={(e: any) => this.renameReport(e, row)}
                //  onClick={(e: any) => this.laborDayApproveForReport(e, row, rowIndex)}
                //onClick={this.runReportClicked}
              >
                Details
              </Dropdown.Item>
              {/* <Dropdown.Item
                key={"ViewPerformance"}
                eventKey={"ViewPerformance"}
                // onClick={(e: any) => this.renameReport(e, row)}
                //  onClick={(e: any) => this.navigatetoPerformancePage(e, row, rowIndex)}
                //onClick={this.runReportClicked}
              >
                View Performance
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    return (
      <>
        {this.state.loaderExceptionChild ? (
          <ReactPageLoader useas={"daysToApproveExpand"} />
        ) : (
          <div className="breakExceptionDetailsTable" ref={this.laborDayDetails}>
            <BootstrapTable
              keyField="rowNo"
              data={this.state.breakExceptionSubDetails}
              columns={columns}
              rowClasses={rowClasses}
              hover
            />
          </div>
        )}
      </>
    );
  }
}
