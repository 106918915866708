import * as React from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  // Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { UserManagement } from "../../Common/Services/UserManagement";
import { IUserRole } from "../../Common/Contracts/IUserRole";
import _ from "lodash";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Joi from "joi-browser";
import { Utils } from "../../Common/Utilis";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import { confirmAlert } from "react-confirm-alert";
import { DropDownListWithToolTip } from "./common-components/DropDownListWithToolTip";
import { DropDownList } from "../Reports/common-components/dropdown-list";
export class ChangeStatusModal extends React.Component<any, any> {
  private changeStatusFormSchema = Joi.object({
    status: Joi.string().required(),
    terminationDate: Joi.string().allow(""),
    terminationReason: Joi.string().allow(""),
    rehireEligibility: Joi.string().allow(""),
  });
  constructor(props: any) {
    super(props);
    this.state = {
      showStatusModal: this.props.showModal,
      statusList: [],
      formData: {
        status: "",
        terminationDate: "",
        terminationReason: "",
        rehireEligibility: "",
      },
      errors: {},
      inputPosition: 0,
      disableSave: false,
      showTerminationCalender: false,
      showWarningMessage: false,
      rehireEligibilityList: [
        { id: "-1", name: "Select Rehire Eligibility" },
        { id: "Eligible for Rehire", name: "Eligible for Rehire" },
        { id: "Questionable for Rehire", name: "Questionable for Rehire" },
        { id: "Ineligible for Rehire", name: "Ineligible for Rehire" },
      ],
    };
  }
  componentDidMount() {
    this.validateForm();
    console.log(this.props.SelectedUsers);
    //this.setState({ showWarningMessage: true });
  }

  calenderTerminationDateHide = () => {
    this.setState({ showTerminationCalender: false });
  };

  calenderTerminationDateDisplay = () => {
    this.setState({ showTerminationCalender: true });
  };

  validateForm = () => {
    const { formData } = this.state;
    const valid = Joi.validate(formData, this.changeStatusFormSchema, {
      abortEarly: false,
    });

    let newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if (formData.status === "Inactive") {
        // newErrorObject["terminationDate"] =
        //   "Please select valid Termination Date";
        var splittedDate = formData.terminationDate?.toString()?.split("/");
        if (
          moment(formData.terminationDate)?.format("MM/DD/YY") === "Invalid date" ||
          formData.terminationDate?.toString()?.length !== 8 ||
          splittedDate[0]?.toString()?.length !== 2 ||
          splittedDate[1]?.toString()?.length !== 2 ||
          splittedDate[2]?.toString()?.length !== 2
        ) {
          newErrorObject["terminationDate"] = "Termination Date should be in MM/DD/YY format";
        }
    } else {
      if (newErrorObject["terminationDate"]) {
        delete newErrorObject["terminationDate"];
      }
    }
    if (formData.status === "Inactive" && !formData.terminationReason) {
      newErrorObject["terminationReason"] = "Please enter Termination Reason";
    } else {
      if (newErrorObject["terminationReason"]) {
        delete newErrorObject["terminationReason"];
      }
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  UpdateUserStatus = () => {
    let UpdateUserStatus = [] as any;
    const {
      formData: {
        status,
        terminationDate,
        terminationReason,
        rehireEligibility,
      },
    } = this.state;

    this.setState({ disableSave: false });
    this.props.SelectedUsers.map((user) => {
      let usertoupdate: any = {};
      usertoupdate.Username = user.username;
      usertoupdate.Status = status;
      usertoupdate.OldStatus = user.status;
      usertoupdate.Date = terminationDate;
      usertoupdate.TerminationReason = terminationReason;
      usertoupdate.RehireEligibility = rehireEligibility;
      let storage = JSON.parse(localStorage.getItem("storage")!);
      usertoupdate.TenantId =
        storage.tenantID === undefined ? 0 : storage.tenantID;
      usertoupdate.ChangedBy = storage.userName;
      usertoupdate.LoginUser = storage.user_UniqueID;
      UpdateUserStatus.push(usertoupdate);
    });
    UserManagement.UpdateUserStatus(UpdateUserStatus)
      .then((response: any) => {
        this.setState({ disableSave: true });
        if (response?.message === "Success") {
          if (this.props.SelectedUsers?.length > 1) {
            if (response.result.result?.length >= 1) {
              this.setState({ showWarningMessage: true });
            } else {
              toast.success("User status has been changed successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                // containerId: "scheduleMain",
              });
              this.hideStatusModal();
              this.props.getUserList();
            }
          } else {
            if (response.result?.result?.length == 1) {
              //   Utils.toastError("Prior to making this change, please ensure that all entities are covered by a Password Assistance Role or Termination Date should be greater than or equal to Hire Date.", {
              //     position: toast.POSITION.BOTTOM_RIGHT,
              //    // containerId: "scheduleMain",
              //  });
              this.setState({ showWarningMessage: true });
            } else {
              toast.success("User status updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                // containerId: "scheduleMain",
              });
              this.hideStatusModal();
              this.props.getUserList();
            }
          }
        } else {
          toast.success(response.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "scheduleMain",
          });
        }
      })
      .finally(() => {
        this.setState({ disableSave: false });
      });
  };

  showErrorMessage = (errormessage) => {
    confirmAlert({
      title: "Result",
      message:
        "One or more users could not be updated as they fall into one or more of the below mentioned categories " +
        "- Is the last user in the EHID as Password Assitance User",
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            this.onHideAllModal();
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  buildCustomEventObject = (name, value) => {
    const valueObj: any = {
      target: {
        name,
        value,
      },
    };
    return valueObj;
  };
  onFieldChange = (event, inputPosition, isBlur = false) => {
    let value = event.target.value;
    const fieldName = event.target.name;
    const { formData } = this.state;
    if (fieldName === "terminationDate") {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        formData?.terminationDate,
        true,
        2,
        isBlur
      );
      if (_formattedDate === "invalid") {
        return;
      } else {
        value = _formattedDate;
      }
    }

    formData[fieldName] = value?.toString()?.trimLeft();
    this.setState({ formData, inputPosition }, () => {
      this.validationOnBlur(inputPosition);
    });
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    return (
      <>
        {Object.keys(this.state.errors).length > 0 &&
          fieldPosition <= this.state.inputPosition && (
            <span className="validation-message">
              {this.state.errors[fieldName]}
            </span>
          )}
      </>
    );
  };

  hideStatusModal = () => {
    this.props.hideStatusModal();
  };
  validationOnBlur = (inputPosition) => {
    this.setState({ inputPosition });
    this.validateForm();
  };
  onHideAllModal = () => {
    this.hideStatusModal();
    this.props.getUserList();
    this.setState({ showWarningMessage: false });
  };
  render() {
    const {
      formData: {
        status,
        terminationDate,
        terminationReason,
        rehireEligibility,
      },
      inputPosition,
      errors,
      showTerminationCalender,
      rehireEligibilityList,
    } = this.state;
    let statusList = [
      { id: "Active", name: "Active", toolTip: "" },
      { id: "Inactive", name: "Inactive", toolTip: "" },
      {
        id: "ManagerLocked",
        name: "Manager Locked",
        toolTip: "This option will prevent a user from logging in.",
      },
      { id: "Active", name: "Unlocked", toolTip: "User is able to log in." },
    ];
    let hasActive = this.props.SelectedUsers.filter(
      (x) => x.status == "Active"
    );
    let hasInactive = this.props.SelectedUsers.filter(
      (x) => x.status == "Inactive"
    );
    let hasIncomplete = this.props.SelectedUsers.filter(
      (x) => x.status == "Incomplete"
    );

    let hasManagerLocked = this.props.SelectedUsers.filter(
      (x) => x?.isLocked === "True"
    );
    let hasAutoLocked = this.props.SelectedUsers.filter(
      (x) => x?.isAutoLocked === "True"
    );

    if (hasActive?.length == 0) {
      statusList = [
        { id: "Active", name: "Active", toolTip: "" },
        {
          id: "ManagerLocked",
          name: "Manager Locked",
          toolTip: "This option will prevent a user from logging in.",
        },
        { id: "Active", name: "Unlocked", toolTip: "User is able to log in." },
      ];
    }
    if (hasActive?.length > 0 && hasInactive?.length == 0) {
      statusList = [
        { id: "Inactive", name: "Inactive", toolTip: "" },
        {
          id: "ManagerLocked",
          name: "Manager Locked",
          toolTip: "This option will prevent a user from logging in.",
        },
        { id: "Active", name: "Unlocked", toolTip: "User is able to log in." },
      ];
    }
    if (
      this.props.SelectedUsers?.length === 1 &&
      hasManagerLocked?.length > 0
    ) {
      statusList = statusList.filter((x) => x.id !== "ManagerLocked");
    } else if (
      this.props.SelectedUsers?.length === 1 &&
      hasManagerLocked?.length == 0 &&
      hasAutoLocked?.length == 0
    ) {
      statusList = statusList.filter((x) => x.name !== "Unlocked");
    }

    return (
      <div>
        <>
          <Modal
            className="change-ehid-access-warning-modal"
            show={this.state.showWarningMessage}
            onHide={() => this.onHideAllModal()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="warningMessageModal"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <div className="description">
                    One or more users could not be updated as they fall into one
                    or more of the below mentioned categories
                    <ul className="pt-3 pl-3">
                      <li>
                        Please ensure that all entities are covered by a
                        Password Assistance Role
                      </li>
                      <li>
                        Termination Date should be greater than or equal to Hire
                        Date.{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="reset-password-btn"
                onClick={(e) => this.onHideAllModal()}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            className={`change-status-modal ${
              this.state.showWarningMessage ? "changeStatusModal" : ""
            }`}
            show={this.state.showStatusModal}
            onHide={() => this.hideStatusModal()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            id="changeStatusModal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="status-section">
                <Form.Group>
                  <Form.Label>New Status</Form.Label>
                  {statusList?.length > 0 && (
                    // <SingleSearchDropdownList
                    //   itemList={statusList}
                    //   handleSelectedItem={(value) => this.onFieldChange(
                    //     Utils.BuildCustomEventObject(
                    //       "status",
                    //       value
                    //     ),
                    //     1
                    //   )}
                    //   defaultText={"Search"}
                    //   defaultName={
                    //     status
                    //       ? statusList.find((item) => item?.id === status)?.id
                    //       : "Select Status"
                    //   }
                    //   controlID="ddlStatus"
                    // />

                    <DropDownListWithToolTip
                      placeHolder={"Select Status"}
                      data={statusList}
                      isSearchRequired={false}
                      label={"name"}
                      value={"id"}
                      onDropDownChange={(item) => {
                        this.onFieldChange(
                          Utils.BuildCustomEventObject("status", item.id),
                          1
                        );
                      }}
                      selectedItem={[
                        ...[{ id: "", name: "Select Status" }],
                        ...statusList.filter((item) => item?.id),
                      ].find((r) => r.id === status)}
                    />
                  )}
                </Form.Group>

                {status == "Inactive" && (
                  <>
                    <Form.Group
                      controlId="date-selection"
                      onClick={() => this.validationOnBlur(2)}
                      className={
                        errors?.terminationDate && inputPosition >= 2
                          ? "validation-error d-flex flex-column date-picker"
                          : "d-flex flex-column date-picker"
                      }
                    >
                      <Form.Label>
                        <div className="d-flex align-items-center">
                          <div>Termination Date</div>
                        </div>
                      </Form.Label>
                      <div className="validate-input d-flex flex-column">
                        <div className="calender-container">
                          <div
                            className={
                              showTerminationCalender
                                ? "calender-display open"
                                : "calender-display"
                            }
                          >
                            <input
                              onClick={this.calenderTerminationDateDisplay}
                              onFocus={this.calenderTerminationDateDisplay}
                              type="text"
                              placeholder="MM/DD/YY"
                              maxLength={8}
                              name="terminationDate"
                              onInput={(e) => {
                                let value = e.currentTarget.value;

                                value = value
                                  ?.toString()
                                  ?.replace(/[^0-9\/]+/gi, "");

                                this.onFieldChange(
                                  Utils.BuildCustomEventObject(
                                    "terminationDate",
                                    value
                                  ),
                                  2
                                );
                              }}
                              onBlur={(e) => {
                                let value = e.currentTarget.value;

                                value = value
                                  ?.toString()
                                  ?.replace(/[^0-9\/]+/gi, "");

                                this.onFieldChange(
                                  Utils.BuildCustomEventObject(
                                    "terminationDate",
                                    value
                                  ),
                                  2,
                                  true
                                );

                                this.validationOnBlur(2);
                              }}
                              autoComplete="terminationDate"
                              value={terminationDate}
                              className="form-control single-date-pick"
                            />
                            <svg
                              onClick={this.calenderTerminationDateDisplay}
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                              <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                            </svg>
                          </div>
                          {showTerminationCalender && (
                            <div className="calender">
                              <OutsideClickHandler
                                onOutsideClick={
                                  this.calenderTerminationDateHide
                                }
                              >
                                <Calendar
                                  calendarType={"US"}
                                  onChange={(date) => {
                                    const value =
                                      moment(date).format("MM/DD/YY");
                                    this.onFieldChange(
                                      Utils.BuildCustomEventObject(
                                        "terminationDate",
                                        value
                                      ),
                                      2
                                    );
                                    this.calenderTerminationDateHide();
                                  }}
                                  value={
                                    terminationDate
                                      ? moment(terminationDate)?.format(
                                          "MM/DD/YY"
                                        ) === "Invalid date"
                                        ? new Date()
                                        : new Date(terminationDate)
                                      : new Date()
                                  }
                                  maxDate={new Date()}
                                />
                              </OutsideClickHandler>
                            </div>
                          )}
                        </div>

                        {this.getFieldError("terminationDate", 2)}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="date-selection-sos"
                      onBlur={() => this.validationOnBlur(3)}
                      className={
                        errors?.rehireEligibility && inputPosition >= 3
                          ? "validation-error d-flex flex-column "
                          : "d-flex flex-column "
                      }
                    >
                      <Form.Label>
                        <div className="d-flex align-items-center">
                          <div>Rehire Eligibility</div>
                        </div>
                      </Form.Label>
                      {rehireEligibilityList?.length > 0 && (
                        <DropDownList
                          defaultTextColor={true}
                          onFocus={this.calenderTerminationDateHide}
                          placeHolder={"Select Rehire Eligibility"}
                          data={rehireEligibilityList.filter(
                            (item) => item?.id
                          )}
                          isSearchRequired={false}
                          label={"name"}
                          value={"id"}
                          onDropDownChange={(item) => {
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "rehireEligibility",
                                item.id
                              ),
                              3
                            );
                          }}
                          selectedItem={[
                            ...[{ id: "", name: "Select Rehire Eligibility" }],
                            ...rehireEligibilityList.filter((item) => item?.id),
                          ].find((r) => r.id === rehireEligibility)}
                        />
                      )}
                      {this.getFieldError("rehireEligibility", 3)}
                    </Form.Group>
                    <Form.Group
                      onBlur={() => this.validationOnBlur(4)}
                      className={
                        errors?.terminationReason && inputPosition >= 4
                          ? "validation-error d-flex flex-column"
                          : "d-flex flex-column"
                      }
                    >
                      <Form.Label>Termination Reason</Form.Label>
                      <textarea
                        value={terminationReason}
                        placeholder={"Comments..."}
                        name="terminationReason"
                        onChange={(e: any) => this.onFieldChange(e, 4)}
                        className="termination-reason form-control"
                      ></textarea>
                      {this.getFieldError("terminationReason", 4)}
                    </Form.Group>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={
                  this.state.disableSave || Object.keys(errors).length > 0
                }
                className="reset-password-btn"
                onClick={(e) => this.UpdateUserStatus()}
              >
                Change Status
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    );
  }
}
