import React from "react";
import { Col, Container, Dropdown, Form, Row, Spinner } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AutosizeInput from "react-input-autosize";
import { confirmAlert } from "react-confirm-alert";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ToastContainer, toast } from "react-toastify";
import { resolve, reject } from "q";
import "react-toastify/dist/ReactToastify.css";
import "react-input-range/lib/css/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { PTOSettingLog } from "./PTOSettingLog";
import { MultiSelectSearch } from "../../Common/Components/MultiSelectSearch";
import { SliderComponent as Slider } from "../../Common/Components/Slider.component";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { InfoTooltip } from "../../Common/Components/InfoTooltip";
import RouteLeavingGuard from "../../Common/Components/RouteLeavingGuard";
import {
  ILaborPTOListDTO,
  ILaborPTOTypeFrequencyListDTO,
} from "../../Common/Contracts/ILaborPTOCalculation";
import { Hotel } from "../../Common/Services/Hotel";
import { LaborPTOCalculation } from "../../Common/Services/LaborPTOCalculation";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../Common/Services/RoleSubPermissionSetting";
import TableSearch from "../../Common/Components/TableSearch";
import { Utils } from "../../Common/Utilis";

let routeLeavingGuard: any = null;
const { SearchBar } = Search;
const awardFrequencyTooltip =
  "Award Frequency is how often this PTO will run, calculate, and award hours.";
const thresholdInfoTooltio =
  " Minimum Threshold is the minimum number of hours worked in a week over the Look Back Period. If the minimum is met then the earned hours will be calculated and awarded appropriately.";
let validaterRowClickId: number = -1;
export class PTOSetting extends React.Component<any, any> {
  private child: any;
  private input: any;
  private multiselectChild: any;
  constructor(props: any) {
    super(props);
    this.child = React.createRef();
    this.input = React.createRef();
    this.multiselectChild = React.createRef();

    this.state = {
      leftPanelLoader: false,
      rightPanelLoader: false,
      saveButtonSpinner: false,
      isPtoSettingPermission: false,
      header: ["Year", "", "Thru Year", "Yearly", "Hourly", ""],
      sliderData: [
        {
          min: "0",
          value: { min: "0", max: "" },
          max: "",
          Year: "0",
          Hours: "0",
        },
      ],
      isSelected: false,
      selected: [],
      isNewPolicy: false,
      isPolicyStatus: false,
      isRPanel: false,
      isDataChange: false,
      earningMethod: "Select",
      awardFrequency: "Select",
      ptoType: "Select",
      ptoSubType: "Select",
      PTOlist: [],
      ptoSubTypeList: [],
      oldFieldData: {},
      fieldData: {
        ptoID: 0,
        ptoType: "",
        ptoName: "",
        method: "",
        hoursWorked: "0.00",
        accruedPTO: "0.00",
        period: 0,
        allHidCheck: false,
        accrualOvertimeCheck: false,
        selectedEhids: [],
        earnedPeriod: "",
        minimumHours: 0,
        accrualType: "",
        carryOverFreq: "Anniversary Date",
        maximumPTOCarry: 0,
        ptoLimit: "",
        ptypeUniqueno: 1,
        status: "Active",
        showChangeLogModal: false,
        accrualOnPtotimeCheck: false,
      },
      allHotelList: [],
      earningList: [
        { id: "Accrual", name: "Accrual" },
        { id: "Lump Sum", name: "Lump Sum" },
      ],
      awardFrequencyList: [],
      actualawardFrequencyList: [],
      ptoTypeList: [
        { id: "Vacation", name: "Vacation" },
        { id: "Sick", name: "Sick" },
      ],
      weeklyHours: 0,
      bdrValue: false,
      btnEventName: "",
      changeLogptoID: 0,
    };
  }

  componentDidMount() {
    this.getPtoSettingPermission();
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
    this.loadPTOList("componentMount");
    this.getPTOSubTypeDetails();
    this.loadWeeklyHour();
    this.getAllhotelList();
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.isDataChange &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  getPtoSettingPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10032;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((elem: any) => {
            switch (elem.permissionID) {
              case 10043:
                this.setState({ isPtoSettingPermission: true });
            }
          });
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  // Lefe Panel PTO Lists Load
  loadPTOList = (type: string) => {
    if (type === "componentMount") {
      this.setState({ leftPanelLoader: true });
    }
    LaborPTOCalculation.GetLaborPTOList()
      .then(async (result: ILaborPTOListDTO[] | null) => {
        if (result != null) {
          this.setState({
            PTOlist: result,
          });
        }
        this.setState({ leftPanelLoader: false });
        resolve();
      })
      .catch((err: any) => {
        this.setState({ leftPanelLoader: false });
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  // Weekly Hours Load for calculation
  loadWeeklyHour = () => {
    LaborPTOCalculation.loadWeeklyHour()
      .then(async (result: any) => {
        if (result != null) {
          this.setState({ weeklyHours: result.weeklyHours });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  // All Ehids List for Hid Selection
  getAllhotelList = () => {
    Hotel.getUserHotelAccessAll("Labor Management", true)
      .then(async (result: any[] | null) => {
        if (result != null) {
          let allHotelList: Array<any> = [];
          result.forEach((element) => {
            let newItem = {} as any;
            newItem.hotelID = element.hotelID;
            newItem.value = element.lettercode;
            newItem.description = element.hotelName;
            newItem.isChecked = false;
            allHotelList.push(newItem);
          });
          this.setState({ allHotelList });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  // Left Panel Functions
  newPolicy = () => {
    if (this.state.isDataChange) {
      this.setState({ btnEventName: "newpolicy" });
      this.isValidateModelPoupTab();
    } else {
      this.addNewPolicy();
    }
  };

  addNewPolicy = () => {
    this.loadState();
    this.setState({
      isNewPolicy: true,
      isRPanel: true,
    });
    setTimeout(() => {
      this.input.current?.focus();
      this.child.current?.updateChildState([
        {
          min: "0",
          value: { min: "0", max: "" },
          max: "",
          Year: "0",
          Hours: "0",
        },
      ]);
    }, 100);
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      if (this.state.isDataChange) {
        validaterRowClickId = row.ptoID;
        this.setState({ btnEventName: "rowevents" });
        this.isValidateModelPoupTab();
      } else {
        this.getSinglePTODetails(row.ptoID);
      }
    },
  };

  getSinglePTODetails = (ptoID: number) => {
    this.getPTODetails(ptoID, "leftRowClick");
    let selectedRow: Array<any> = [];
    selectedRow.push(ptoID);
    this.setState({
      isSelected: true,
      selected: selectedRow,
      isRPanel: true,
      isNewPolicy: false,
      isDataChange: false,
    });
  };

  loadState = () => {
    let awardFrequencyList: any[] = [];

    awardFrequencyList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualawardFrequencyList))
    );

    let awardFre = awardFrequencyList[0].name;

    let new_state = JSON.parse(JSON.stringify(this.state));
    let fieldData = new_state.fieldData;
    fieldData.ptoID = 0;
    fieldData.allHidCheck = false;
    fieldData.accrualOvertimeCheck = false;
    fieldData.selectedEhids = [];
    fieldData.ptoType = "Vacation";
    fieldData.ptoName = "";
    fieldData.method = "Accrual";
    fieldData.hoursWorked = "0.00";
    fieldData.accruedPTO = "0.00";
    fieldData.period = 48;
    fieldData.earnedPeriod = "";
    fieldData.minimumHours = 0;
    fieldData.accrualType = awardFre;
    fieldData.carryOverFreq = awardFre;
    fieldData.maximumPTOCarry = 0;
    fieldData.ptoLimit = "";
    fieldData.ptypeUniqueno = 1;
    fieldData.status = "Active";
    this.setState(
      {
        oldFieldData: fieldData,
        fieldData,
        earningMethod: "Accrual",
        awardFrequency: awardFre,
        ptoType: "Vacation",
        ptoSubType: "Select",
        isSelected: false,
        isNewPolicy: false,
        isRPanel: false,
        isDataChange: false,
        sliderData: [
          {
            min: "0",
            value: { min: "0", max: "" },
            max: "",
            Year: "0",
            Hours: "0",
          },
        ],
      },
      () => {
        this.updateSelectedHids();
      }
    );
  };

  ishandleAction = () => {
    if (this.state.isDataChange) {
      this.setState({ btnEventName: "policystatus" });
      this.isValidateModelPoupTab();
    } else {
      this.setPolicyStatus();
    }
  };

  setPolicyStatus = () => {
    this.setState({
      isPolicyStatus: true,
    });
    LaborPTOCalculation.UpdatePTOStatus(
      this.state.fieldData.ptoID,
      this.state.fieldData.status === "Active" ? "Inactive" : "Active"
    )
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.saveStatus === "Success") {
            toast.success(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.loadPTOList("changePolicyStatus");
            this.getPTODetails(
              this.state.fieldData.ptoID,
              "changePolicyStatus"
            );
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState({
              isPolicyStatus: false,
            });
          }
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  // load PTO list ptoSubTypeList
  getPTODetails = (ptoID: number, type: string) => {
    if (type === "leftRowClick") {
      this.setState({ rightPanelLoader: true });
    }
    LaborPTOCalculation.GetLaborPTODetails(ptoID)
      .then(async (result: any[] | null) => {
        if (result != null) {
          let earningMethod = result[0].method;
          let awardFrequency = result[0].accrualType;
          let ptoType = result[0].ptoType;
          this.setState({
            fieldData: result[0],
            earningMethod,
            awardFrequency,
            ptoType,
          });
          let new_state = Object.assign({}, this.state);
          let fieldData = new_state.fieldData;
          fieldData.ptoLimit =
            fieldData.ptoLimit === null || fieldData.ptoLimit.length === 0
              ? "Not Set"
              : fieldData.ptoLimit;
          fieldData.accrualOvertimeCheck =
            fieldData.isOvertime === 1 ? true : false;
          fieldData.accrualOnPtotimeCheck =
            fieldData.isAccrualOnPTO === 1 ? true : false;
          if (fieldData.selectedHids === "ApplyToAllEHID") {
            fieldData.allHidCheck = true;
            fieldData.selectedEhids = [];
          } else {
            let hidList = fieldData.selectedHids.split(",");
            let selectedHotels: Array<any> = [];
            for (let i = 0; i < this.state.allHotelList.length; i++) {
              if (hidList.includes(`${this.state.allHotelList[i]?.hotelID}`)) {
                selectedHotels.push(this.state.allHotelList[i]);
              }
            }
            fieldData.allHidCheck = false;
            fieldData.selectedEhids = selectedHotels;
          }
          this.setState({ fieldData, oldFieldData: fieldData });

          this.state.ptoSubTypeList.forEach((element: any) => {
            if (element.uniqueno === result[0].ptypeUniqueno) {
              this.setState({ ptoSubType: element.pType });
            }
          });

          let ptoDetailslist = [] as any;
          result[0].ptoDetails.forEach((element: any) => {
            let newItem = {} as any;
            newItem.if_record = element.if_record;
            newItem.min = element.startYear;
            newItem.value = { min: element.startYear, max: element.thruYear };
            newItem.max = element.thruYear;
            newItem.Year = element.maximumAnnualPTO;
            newItem.Hours = (
              element.maximumAnnualPTO /
              ((364 * this.state.weeklyHours) / 7)
            ).toFixed(4);

            ptoDetailslist.push(newItem);
          });
          this.setState(
            { sliderData: ptoDetailslist, isNewPolicy: false },
            () => {
              this.sortByThruYear(ptoDetailslist, "max");
            }
          );
          this.child.current?.updateChildState(ptoDetailslist);
        }
        this.setState({ rightPanelLoader: false }, () => {
          this.updateSelectedHids();
        });
        resolve();
      })
      .catch((err: any) => {
        this.setState({ rightPanelLoader: false });
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  sortByThruYear = (array: any, key: any) => {
    return array.sort((a: any, b: any) => {
      let x = parseInt(a[key]);
      let y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  getPTOSubTypeDetails = () => {
    LaborPTOCalculation.GetLaborPTOSubTypes()
      .then(async (result: ILaborPTOTypeFrequencyListDTO | null) => {
        if (result != null) {
          this.setState({
            ptoSubTypeList: result.laborPTOType,
            awardFrequencyList: result.laborFrequency,
            actualawardFrequencyList: result.laborFrequency,
            awardFrequency: result.laborFrequency[0].name,
          });

          let new_state = Object.assign({}, this.state);
          let fieldData = new_state.fieldData;
          fieldData.carryOverFreq = result.laborFrequency[0].name;
          this.setState({ fieldData });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  handleClick = () => {
    this.setState({ bdrValue: true });
  };

  handleChange = (event: any, FieldType: string) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    if (FieldType === "PTOName") {
      fieldData.ptoName = event.target.value;
    }
    if (FieldType === "Period") {
      fieldData.period = event.target.value;
    }
    if (FieldType === "MinimumThreshold") {
      fieldData.minimumHours = event.target.value;
    }
    if (FieldType === "MaxCarryOver") {
      fieldData.maximumPTOCarry = event.target.value;
    }

    if (FieldType === "PTOLimit") {
      fieldData.ptoLimit = event.target.value;
    }

    this.setState({ fieldData, isDataChange: true });
  };

  handleBlurAutoSize = () => {
    this.setState({ bdrValue: false });
  };

  allEhidCheckTogle = (e: any) => {
    let toggleValue: boolean = e.target.checked;

    let new_state = JSON.parse(JSON.stringify(this.state));
    let fieldData = new_state.fieldData;

    fieldData.allHidCheck = toggleValue;

    this.setState({ fieldData, isDataChange: true }, () => {
      this.updateSelectedHids();
    });
  };
  accrualOvertimeChange = (e: any) => {
    let toggleValue: boolean = e.target.checked;

    let new_state = JSON.parse(JSON.stringify(this.state));
    let fieldData = new_state.fieldData;

    fieldData.accrualOvertimeCheck = toggleValue;

    this.setState({ fieldData, isDataChange: true });
  };

  accrualOnPtoChange = (e: any) => {
    let toggleValue: boolean = e.target.checked;

    let new_state = JSON.parse(JSON.stringify(this.state));
    let fieldData = new_state.fieldData;

    fieldData.accrualOnPtotimeCheck = toggleValue;

    this.setState({ fieldData, isDataChange: true });
  };

  hidSelectionChange = () => {
    this.setState({ isDataChange: true });
  };

  updateMultiSelectItem = (selectedItem: any) => {
    let new_state = JSON.parse(JSON.stringify(this.state));
    let fieldData = new_state.fieldData;

    fieldData.selectedEhids = selectedItem;
    this.setState({ fieldData });
  };

  updateSelectedHids = () => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.multiselectChild.current?.updateSavedItem(fieldData.selectedEhids);
  };

  handleEarningChange = (item: any) => {
    this.setState({ awardFrequencyList: [] });
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    let awardFrequencyList: any[] = [];
    let firstItem: any[] = [];
    awardFrequencyList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualawardFrequencyList))
    );
    firstItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(awardFrequencyList[0]))
    );
    fieldData.accrualType = awardFrequencyList[0].name;
    if (item.name === "Lump Sum") {
      this.setState({
        awardFrequencyList: firstItem,
        awardFrequency: awardFrequencyList[0].name,
      });
    } else {
      this.setState({
        awardFrequencyList: awardFrequencyList,
        awardFrequency: awardFrequencyList[0].name,
      });
    }
    fieldData.period = 48;
    fieldData.method = item.name;
    this.setState({ fieldData, isDataChange: true });
  };

  handleAwardChange = (item: any) => {
    let awardFrequencyList: any[] = [];
    awardFrequencyList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualawardFrequencyList))
    );
    let awardFrequency = awardFrequencyList[0].name;

    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    if (item.name === "Anniversary Date" || item.name === "Calendar Date") {
      fieldData.period = 48;
    } else {
      fieldData.period = 0;
    }
    fieldData.carryOverFreq = awardFrequency;
    fieldData.accrualType = item.name;
    this.setState({ fieldData, isDataChange: true });
  };

  handlePTOChange = (item: any, ddlType: string) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    if (ddlType === "PTOType") {
      fieldData.ptypeUniqueno = 1;
      fieldData.ptoType = item.name;
    }

    if (ddlType === "SubPTOType") {
      fieldData.ptypeUniqueno = item.uniqueno;
    }
    this.setState({ fieldData, isDataChange: true });
  };

  updateSliderData = (sliderData: any) => {
    for (let i = 0; i < sliderData.length; i++) {
      if (i > 0) {
        sliderData[i].min = sliderData[i - 1].max;
        sliderData[i].value.min = sliderData[i - 1].max;
      }
      if (isNaN(sliderData[i].Year)) {
        sliderData[i].Hours = "";
      } else {
        sliderData[i].Hours = (
          sliderData[i].Year /
          ((364 * this.state.weeklyHours) / 7)
        ).toFixed(4);
      }
    }

    this.setState({
      sliderData,
      isDataChange: true,
    });
  };

  handleDiscard = () => {
    if (this.state.isNewPolicy === true) {
      this.loadState();
      this.setState({ isNewPolicy: true, isRPanel: true, isDataChange: false });
      this.child.current?.updateChildState([
        {
          min: "0",
          value: { min: "0", max: "" },
          max: "",
          Year: "0",
          Hours: "0",
        },
      ]);
    } else {
      this.setState({
        isRPanel: true,
        isNewPolicy: false,
        isDataChange: false,
      });
      this.getPTODetails(this.state.fieldData.ptoID, "discardAction");
    }
  };

  checkSaveValidations = () => {
    let { sliderValidationResult, sliderValidationMessage } =
      this.sliderDataValidations();
    if (this.state.fieldData.ptoName.trim().length === 0) {
      Utils.toastError("Please enter Policy Name.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (
      this.state.ptoSubType === "Select" &&
      this.state.fieldData.ptoType.trim() === "Sick"
    ) {
      Utils.toastError("Select PTO Sub Type.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (this.state.fieldData.ptoName.trim().length > 30) {
      Utils.toastError("Policy Name length should be <= 30 characters", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (
      !this.state.fieldData.allHidCheck &&
      this.state.fieldData.selectedEhids.length === 0
    ) {
      Utils.toastError("Please select at least one EHID.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (
      !isNaN(Number(this.state.fieldData.period)) &&
      Number(this.state.fieldData.period) < 0
    ) {
      Utils.toastError("Please enter positive Look Back Period.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (isNaN(Number(this.state.fieldData.period))) {
      Utils.toastError("Please enter valid Look Back Period.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (this.state.fieldData.minimumHours === "") {
      Utils.toastError("Please enter Minimum Threshold hours.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (isNaN(Number(this.state.fieldData.minimumHours))) {
      Utils.toastError("Please enter positive value for Minimum Threshold hours.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (Number(this.state.fieldData.minimumHours) < 0) {
      Utils.toastError("Please enter positive value for Minimum Threshold hours.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (isNaN(Number(this.state.fieldData.maximumPTOCarry))) {
      Utils.toastError("Please enter valid Maximum Carry Over hours.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (Number(this.state.fieldData.maximumPTOCarry) < 0) {
      Utils.toastError("Please enter positive value for Maximum Carry Over hours.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (
      isNaN(Number(this.state.fieldData.ptoLimit)) &&
      this.state.fieldData.ptoLimit !== "Not Set"
    ) {
      Utils.toastError("Please enter valid Maximum Allowed hours.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (
      Number(this.state.fieldData.ptoLimit) < 0 &&
      this.state.fieldData.ptoLimit !== "Not Set"
    ) {
      Utils.toastError("Please enter positive value for Maximum Allowed hours.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (
      Number(this.state.fieldData.ptoLimit) <
        Number(this.state.fieldData.maximumPTOCarry) &&
      this.state.fieldData.ptoLimit.trim().length > 0 &&
      this.state.fieldData.ptoLimit !== "Not Set"
    ) {
      Utils.toastError(
        "Maximum Carry Over hours should be less than or equal to Maximum Allowed hours.",
        {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return false;
    } else if (this.state.sliderData.length === 0) {
      Utils.toastError("Pleasen enter slider data.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    } else if (!sliderValidationResult) {
      Utils.toastError(`${sliderValidationMessage}`, {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return !sliderValidationResult;
    } else {
      this.checkForReducingHidAlert();
    }
  };

  sliderDataValidations = () => {
    let sliderValidationResult = true;
    let sliderValidationMessage: string = "";
    let lastmax = 0;
    if (this.state.sliderData.length > 0) {
      this.state.sliderData.forEach((element: any, index: number) => {
        if (index > 0) {
          if (lastmax !== element.min) {
            sliderValidationMessage = "Please enter valid range.";
            sliderValidationResult = false;
          }
        }
        if (isNaN(element.max)) {
          sliderValidationMessage = "Please enter valid Thru Year.";
          sliderValidationResult = false;
        } else if (element.max === "") {
          sliderValidationMessage = "Please enter Thru Year.";
          sliderValidationResult = false;
        } else if (Number(element.max) < 0) {
          sliderValidationMessage =
            "Please enter positive value for Thru Year.";
          sliderValidationResult = false;
        } else if (Number(element.max) === 0) {
          sliderValidationMessage = "Thru Year should be greater than 0.";
          sliderValidationResult = false;
        } else if (Number(element.max) < Number(element.min)) {
          sliderValidationMessage = "Thru Year should be greater than Year.";
          sliderValidationResult = false;
        } else if (isNaN(element.Year)) {
          sliderValidationMessage = "Please enter valid Yearly hours.";
          sliderValidationResult = false;
        } else if (element.Year === "") {
          sliderValidationMessage = "Please enter Yearly hours.";
          sliderValidationResult = false;
        } else if (Number(element.Year) < 0) {
          sliderValidationMessage =
            "Please enter positive value for Yearly hours.";
          sliderValidationResult = false;
        }
        lastmax = element.max;
      });
    }
    return { sliderValidationResult, sliderValidationMessage };
  };

  checkForReducingHidAlert = () => {
    let fieldData = Object.assign({}, this.state.fieldData);
    let oldFieldData = Object.assign({}, this.state.oldFieldData);

    // checking for subset
    let oldSelectedHids = oldFieldData.selectedEhids;
    let newSelectedHids = fieldData.selectedEhids;

    let isSubSet = oldSelectedHids.every((el: any) => {
      return newSelectedHids.indexOf(el) !== -1;
    });

    if (!fieldData.allHidCheck && (oldFieldData.allHidCheck || !isSubSet)) {
      this.showAlertForReducingHid();
    } else {
      this.handleSave();
    }
  };

  showAlertForReducingHid = () => {
    confirmAlert({
      title: "Property removed",
      message:
        "Removing property(s) will prevent this time-off policy from being assigned to employees at that property(s) in the future. Employees currently assigned to this policy will retain access and continue to accrue until they are individually removed.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleSave(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  handleSave = () => {
    this.setState({ saveButtonSpinner: true });
    let fieldData = Object.assign({}, this.state.fieldData);
    let saveRequest: any = {};
    let ptoDetails: any[] = [];
    this.state.sliderData.forEach((element: any) => {
      let newItem = {} as any;
      newItem.if_record = element.if_record;
      newItem.startYear = element.min;
      newItem.thruYear = element.max;
      newItem.maximumAnnualPTO = element.Year;
      ptoDetails.push(newItem);
    });
    saveRequest.ptoID = fieldData.ptoID;
    saveRequest.ptoName = fieldData.ptoName;
    saveRequest.method = fieldData.method;
    saveRequest.hoursWorked = fieldData.hoursWorked;
    saveRequest.accruedPTO = fieldData.accruedPTO;
    saveRequest.period = fieldData.period;
    saveRequest.selectedHids = "ApplyToAllEHID";
    saveRequest.isOvertime = fieldData.accrualOvertimeCheck ? 1 : 0;
    if (!fieldData.allHidCheck) {
      let ehidArray: Array<string> = [];
      fieldData.selectedEhids.forEach((data: any) =>
        ehidArray.push(data?.hotelID)
      );
      let selectedHids = ehidArray.join(",");
      saveRequest.selectedHids = selectedHids;
    }
    saveRequest.earnedPeriod = 0;
    saveRequest.minimumHours = fieldData.minimumHours;
    saveRequest.ptoType = fieldData.ptoType;
    saveRequest.accrualType = fieldData.accrualType;
    saveRequest.carryOverFreq = fieldData.carryOverFreq;
    saveRequest.maximumPTOCarry = fieldData.maximumPTOCarry;
    saveRequest.ptoLimit =
      fieldData.ptoLimit === "Not Set" ? "" : fieldData.ptoLimit;
    saveRequest.PtypeUniqueno = fieldData.ptypeUniqueno;
    saveRequest.status = fieldData.status;
    saveRequest.ptoDetails = ptoDetails;
    saveRequest.status = "Active";
    saveRequest.IsAccrualOnPTO = fieldData.accrualOnPtotimeCheck ? 1 : 0;
    LaborPTOCalculation.saveLaborPTODetails(saveRequest)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.saveStatus === "Success") {
            let fieldData = Object.assign({}, this.state.fieldData);
            fieldData.ptoID = result.ptoID;
            let selectedRow: Array<any> = [];
            selectedRow.push(result.ptoID);
            this.setState({
              fieldData,
              oldFieldData: fieldData,
              isDataChange: false,
              isSelected: true,
              selected: selectedRow,
            });
            this.loadPTOList("saveAction");
            this.getPTODetails(result.ptoID, "refreshOnSave");
            toast.success(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          Utils.toastError("Internal server error.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.setState({ saveButtonSpinner: false });
        resolve();
      })
      .catch((err: any) => {
        this.setState({ saveButtonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeBudgetTab(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isChangeBudgetTab = () => {
    this.setState({ isDataChange: false });
    switch (this.state.btnEventName) {
      case "newpolicy":
        return this.addNewPolicy();
      case "rowevents":
        return this.getSinglePTODetails(validaterRowClickId);
      case "policystatus":
        return this.setPolicyStatus();
    }
  };

  changeLogMenuClick = (ptoID: number) => {
    this.showHideChangeLogModal(ptoID);
  };

  showHideChangeLogModal = (ptoID: number) => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal,
      changeLogptoID: ptoID,
    });
  };

  render() {
    const hoverOutEllipsis = () => {
      $(".tooltip").removeClass("show");
    };

    const columns = [
      {
        dataField: "ptoName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <div className="title" onWheel={() => hoverOutEllipsis()}>
              <EllipsisWithTooltip placement="bottom">
                {row.ptoName}
              </EllipsisWithTooltip>
            </div>

            {row.status === "Inactive" && <div className="desc">Inactive</div>}
          </div>
        ),
      },
      {
        dataField: "ptoType",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <div className="title1" onWheel={() => hoverOutEllipsis()}>
              <EllipsisWithTooltip placement="bottom">
                {row.ptoType}
              </EllipsisWithTooltip>
            </div>
            <div className="desc">Type</div>
          </div>
        ),
      },
    ];

    const selectRow = {
      mode: "radio",
      hideSelectColumn: true,
      classes: "active-row",
      selected: this.state.isSelected ? this.state.selected : "",
    };

    const hideRow = {
      mode: "radio",
      hideSelectColumn: true,
      classes: "",
    };

    const ptoMethodSelect = (eventKey: any) => {
      this.setState({ earningMethod: eventKey });
    };

    const handleAwardFrequency = (eventKey: any) => {
      this.setState({ awardFrequency: eventKey });
    };

    const handlePTOSelect = (eventKey: any) => {
      this.setState({ ptoType: eventKey });
    };

    const handlePTOSubTypeSelect = (eventKey: any) => {
      this.setState({ ptoSubType: eventKey });
    };

    return (
      <React.Fragment>
        <RouteLeavingGuard
          when={this.state.isDataChange}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
            if (this.state.isDataChange) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />
        <Container fluid className="body-sec">
          {/* <ToastContainer autoClose={3000} /> */}
          <div className="page-heading d-flex">
            <div className="mr-auto">Time Off</div>
            <div className="action-group d-flex flex-row">
              {this.state.isPtoSettingPermission && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.newPolicy()}
                  >
                    New Policy
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="section" style={{ display: "flex" }}>
            <div className="budget-settings time-off-settings d-flex justify-content-start ptoSettings">
              <div className="left-panel">
                <ToolkitProvider
                  keyField="ptoID"
                  data={this.state.PTOlist}
                  columns={columns}
                  search
                >
                  {(props: {
                    searchProps: JSX.IntrinsicAttributes;
                    baseProps: JSX.IntrinsicAttributes;
                  }) => (
                    <div>
                      <div className="search-header">
                        {/* <SearchBar
                          autoComplete="off"
                          {...props.searchProps}
                          placeholder={"Filterrr..."}
                        /> */}
                        <TableSearch
                          id="tableSearch"
                          key="tableSearch"
                          {...props.searchProps}
                          placeholder={"Filter..."}
                        />
                      </div>
                      {this.state.leftPanelLoader ? (
                        <ReactPageLoader useas="timeOffSettingsLeftPanel" />
                      ) : (
                        <div className="left-body">
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="ptoID"
                            rowEvents={this.rowEvents}
                            hover
                            condensed
                            selectRow={
                              this.state.isSelected ? selectRow : hideRow
                            }
                          />
                          {this.state.isPtoSettingPermission && (
                            <div
                              className="add-Budget"
                              onClick={() => this.newPolicy()}
                            >
                              New Policy
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </ToolkitProvider>
              </div>
              {this.state.isRPanel && (
                <div className="right-panel">
                  {this.state.rightPanelLoader ? (
                    <ReactPageLoader useas="timeOffSettingsRightPanel" />
                  ) : (
                    <>
                      <div className="pos-header d-flex align-content-center flex-wrap">
                        <div className="pos-ttl mr-auto timeOffsetCusBg">
                          {this.state.fieldData.status === "Active" &&
                            this.state.isPtoSettingPermission && (
                              <AutosizeInput
                                ref={this.input}
                                className={
                                  this.state.bdrValue
                                    ? "form-control removeBorder input-component bdr-green"
                                    : "form-control removeBorder input-component"
                                }
                                style={{ fontSize: 24 }}
                                injectStyles={false}
                                type="input"
                                value={this.state.fieldData.ptoName}
                                onClick={() => this.handleClick()}
                                onChange={(e: any) =>
                                  this.handleChange(e, "PTOName")
                                }
                                onBlur={() => this.handleBlurAutoSize()}
                                placeholder={"Policy Name"}
                                maxLength={25}
                              />
                            )}
                          {this.state.fieldData.status === "Active" &&
                            !this.state.isPtoSettingPermission && (
                              <div>{this.state.fieldData.ptoName}</div>
                            )}

                          {this.state.fieldData.status === "Inactive" &&
                            this.state.isPtoSettingPermission && (
                              <div className="d-flex align-items-center">
                                <AutosizeInput
                                  ref={this.input}
                                  className={
                                    this.state.bdrValue
                                      ? "form-control removeBorder input-component bdr-green"
                                      : "form-control removeBorder input-component"
                                  }
                                  style={{ fontSize: 24 }}
                                  injectStyles={false}
                                  type="input"
                                  value={this.state.fieldData.ptoName}
                                  onClick={() => this.handleClick()}
                                  onChange={(e: any) =>
                                    this.handleChange(e, "PTOName")
                                  }
                                  onBlur={() => this.handleBlurAutoSize()}
                                  placeholder={"Policy Name"}
                                  maxLength={25}
                                />
                                <div className="service-status">Inactive</div>
                              </div>
                            )}
                          {this.state.fieldData.status === "Inactive" &&
                            !this.state.isPtoSettingPermission && (
                              <div>
                                {this.state.fieldData.ptoName}
                                <div className="service-status">Inactive</div>
                              </div>
                            )}
                        </div>

                        {!this.state.isNewPolicy &&
                          this.state.isPtoSettingPermission && (
                            <Dropdown className="more-action" alignRight>
                              <Dropdown.Toggle
                                className="btn-outline-primary btn btn-primary more"
                                id="dropdown-more"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => this.ishandleAction()}
                                >
                                  {this.state.fieldData.status === "Active"
                                    ? "Deactivate"
                                    : "Activate"}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  key={"ChangeLog"}
                                  onClick={() =>
                                    this.changeLogMenuClick(
                                      this.state.fieldData.ptoID
                                    )
                                  }
                                >
                                  Change Log
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                      </div>
                      <div
                        className={
                          this.state.isDataChange
                            ? "pos-body dataChange"
                            : "pos-body"
                        }
                      >
                        <Form>
                          <Form.Group as={Row} controlId="carryover">
                            <Form.Label column sm="6">
                              Type
                            </Form.Label>
                            <Col sm="3">
                              <Dropdown onSelect={handlePTOSelect}>
                                <Dropdown.Toggle
                                  id="dropdown-ptoType"
                                  disabled={!this.state.isPtoSettingPermission}
                                >
                                  {this.state.ptoType}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {this.state.ptoTypeList.map(
                                    (item: any, idx: any) => (
                                      <Dropdown.Item
                                        key={idx}
                                        eventKey={item.name}
                                        onClick={() =>
                                          this.handlePTOChange(item, "PTOType")
                                        }
                                      >
                                        {item.name}
                                      </Dropdown.Item>
                                    )
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                            {this.state.fieldData.ptoType === "Sick" && (
                              <Col sm="3">
                                <Dropdown onSelect={handlePTOSubTypeSelect}>
                                  <Dropdown.Toggle
                                    id="dropdown-ptosubType"
                                    disabled={
                                      !this.state.isPtoSettingPermission
                                    }
                                  >
                                    {this.state.ptoSubType}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {this.state.ptoSubTypeList.map(
                                      (item: any, idx: any) => (
                                        <Dropdown.Item
                                          key={idx}
                                          eventKey={item.pType}
                                          onClick={() =>
                                            this.handlePTOChange(
                                              item,
                                              "SubPTOType"
                                            )
                                          }
                                        >
                                          {item.pType}
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            )}
                          </Form.Group>

                          {/* Apply to All Ehids Row Starts*/}
                          <Form.Group as={Row} controlId="earningmethod">
                            <Form.Label column sm="6">
                              Apply To All EHID's
                            </Form.Label>
                            <Col sm="6">
                              <Form.Check
                                type="switch"
                                id="sw_autopunchout"
                                label=""
                                checked={this.state.fieldData.allHidCheck}
                                onChange={(e: any) => {
                                  this.allEhidCheckTogle(e);
                                }}
                              />
                            </Col>
                          </Form.Group>
                          {!this.state.fieldData.allHidCheck && (
                            <Form.Group as={Row} controlId="earningmethod">
                              <Form.Label column sm="6">
                                Applies To
                              </Form.Label>
                              <Col sm="6">
                                <MultiSelectSearch
                                  ref={this.multiselectChild}
                                  itemList={this.state.allHotelList}
                                  updateMultiSelectItem={this.updateMultiSelectItem.bind(
                                    this
                                  )}
                                  dataChange={this.hidSelectionChange.bind(
                                    this
                                  )}
                                  isSelectAll={true}
                                  filtertitle={"Filter EHIDs"}
                                />
                              </Col>
                            </Form.Group>
                          )}
                          {/* Apply to All Ehids Row Ends*/}

                          <Form.Group as={Row} controlId="earningmethod">
                            <Form.Label column sm="6">
                              Earning Method
                            </Form.Label>
                            <Col sm="6">
                              <Dropdown onSelect={ptoMethodSelect}>
                                <Dropdown.Toggle
                                  id="dropdown-earningmethod"
                                  disabled={!this.state.isPtoSettingPermission}
                                >
                                  {this.state.earningMethod}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {this.state.earningList.map(
                                    (item: any, idx: any) => (
                                      <Dropdown.Item
                                        key={idx}
                                        eventKey={item.name}
                                        onClick={() =>
                                          this.handleEarningChange(item)
                                        }
                                      >
                                        {item.name}
                                      </Dropdown.Item>
                                    )
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="awardFrequency">
                            <Form.Label column sm="6" className="d-flex">
                              Award Frequency
                              <span data-tip={awardFrequencyTooltip}>
                                <ReactTooltip
                                  effect="solid"
                                  event="mouseover mouseenter"
                                  eventOff="mouseleave mouseout scroll mousewheel blur"
                                  place="bottom"
                                  multiline={true}
                                />
                                <InfoTooltip />
                              </span>
                            </Form.Label>

                            <Col sm="6">
                              <Dropdown onSelect={handleAwardFrequency}>
                                <Dropdown.Toggle
                                  id="dropdown-awardFrequency"
                                  disabled={!this.state.isPtoSettingPermission}
                                >
                                  {this.state.awardFrequency}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {this.state.awardFrequencyList.map(
                                    (item: any, idx: any) => (
                                      <Dropdown.Item
                                        key={idx}
                                        eventKey={item.name}
                                        onClick={() =>
                                          this.handleAwardChange(item)
                                        }
                                      >
                                        {item.name}
                                      </Dropdown.Item>
                                    )
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="lookbackperiod">
                            <Form.Label column sm="6">
                              Look Back Period
                            </Form.Label>
                            <Col sm="6">
                              {this.state.fieldData.accrualType ===
                                "Anniversary Date" && (
                                <div className="form-control-plaintext">
                                  {this.state.fieldData.period}
                                </div>
                              )}
                              {this.state.fieldData.accrualType !==
                                "Anniversary Date" && (
                                <Form.Control
                                  className="removeBorder min-content-width"
                                  type="input"
                                  disabled={!this.state.isPtoSettingPermission}
                                  value={this.state.fieldData.period}
                                  onChange={(e) =>
                                    this.handleChange(e, "Period")
                                  }
                                  placeholder=""
                                  maxLength={2}
                                />
                              )}
                              <div className="pos-abs grey mdy-top">weeks</div>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="minthreshold">
                            <Form.Label column sm="6" className="d-flex">
                              Minimum Threshold
                              <span data-tip={thresholdInfoTooltio}>
                                <ReactTooltip
                                  effect="solid"
                                  event="mouseover mouseenter"
                                  eventOff="mouseleave mouseout scroll mousewheel blur"
                                  place="bottom"
                                  multiline={true}
                                />
                                <InfoTooltip />
                              </span>
                            </Form.Label>
                            <Col sm="6">
                              <Form.Control
                                className="removeBorder min-content-width"
                                type="input"
                                disabled={!this.state.isPtoSettingPermission}
                                value={this.state.fieldData.minimumHours}
                                onChange={(e) =>
                                  this.handleChange(e, "MinimumThreshold")
                                }
                                placeholder=""
                                maxLength={2}
                              />
                              <div className="pos-abs grey">hours</div>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="carryover">
                            <Form.Label column sm="6">
                              Carry Over Frequency
                            </Form.Label>
                            <Col sm="6">
                              <div className="form-control-plaintext grey">
                                {this.state.fieldData.carryOverFreq}
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="maxcarryover">
                            <Form.Label column sm="6">
                              Maximum Carry Over
                            </Form.Label>
                            <Col sm="6">
                              <Form.Control
                                className="removeBorder min-content-width"
                                type="input"
                                disabled={!this.state.isPtoSettingPermission}
                                value={this.state.fieldData.maximumPTOCarry}
                                onChange={(e) =>
                                  this.handleChange(e, "MaxCarryOver")
                                }
                                placeholder=""
                                maxLength={3}
                              />
                              <div className="pos-abs grey">hours</div>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} controlId="maxcarryover">
                            <Form.Label column sm="6">
                              Maximum Allowed
                            </Form.Label>
                            <Col sm="6">
                              <Form.Control
                                className={
                                  this.state.fieldData.ptoLimit !== "Not Set"
                                    ? "removeBorder min-content-width"
                                    : "removeBorder max-content-width2"
                                }
                                type="input"
                                disabled={!this.state.isPtoSettingPermission}
                                value={
                                  this.state.fieldData.ptoLimit === "Not Set"
                                    ? ""
                                    : this.state.fieldData.ptoLimit
                                }
                                onChange={(e: any) =>
                                  this.handleChange(e, "PTOLimit")
                                }
                                placeholder={this.state.fieldData.ptoLimit}
                                maxLength={4}
                              />
                              {this.state.fieldData.ptoLimit !== "Not Set" && (
                                <div className="pos-abs grey">hours</div>
                              )}
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} controlId="earningmethod">
                            <Form.Label column sm="6">
                              Accrual on Overtime
                            </Form.Label>
                            <Col sm="6">
                              <Form.Check
                                type="switch"
                                id="sw_accrualOvertime"
                                label=""
                                checked={
                                  this.state.fieldData.accrualOvertimeCheck
                                }
                                onChange={(e: any) => {
                                  this.accrualOvertimeChange(e);
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} controlId="earningmethod">
                            <Form.Label column sm="6">
                              Accrual on PTO
                            </Form.Label>
                            <Col sm="6">
                              <Form.Check
                                type="switch"
                                id="sw_accrualOnPto"
                                label=""
                                checked={
                                  this.state.fieldData.accrualOnPtotimeCheck
                                }
                                onChange={(e: any) => {
                                  this.accrualOnPtoChange(e);
                                }}
                              />
                            </Col>
                          </Form.Group>

                          <Slider
                            ref={this.child}
                            PtoSettingPermission={
                              this.state.isPtoSettingPermission
                            }
                            rows={this.state.sliderData}
                            header={this.state.header}
                            updateSliderData={this.updateSliderData.bind(this)}
                            isContinousSilder={true}
                            disableColumn={[0, 5]}
                            splitRange={2}
                            parentPage={"TimeOff"}
                            buttonName={"Add Range"}
                            description={
                              "Years beyond the maximum defined will use the highest limits"
                            }
                          />
                        </Form>
                        {this.state.isDataChange &&
                          this.state.isPtoSettingPermission && (
                            <div className="fixed-action">
                              <div className="d-flex align-content-center flex-wrap">
                                <div className="mr-auto message">
                                  <span>You have unsaved changes</span>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-discard"
                                  onClick={this.handleDiscard}
                                >
                                  Discard
                                </button>

                                <button
                                  type="button"
                                  className={
                                    this.state.saveButtonSpinner
                                      ? "btn btn-primary p-none"
                                      : "btn btn-primary"
                                  }
                                  onClick={() => this.checkSaveValidations()}
                                >
                                  {this.state.saveButtonSpinner && (
                                    <Spinner
                                      className="mr-2"
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                  Save Time Off
                                </button>
                              </div>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </Container>
        {this.state.showChangeLogModal && (
          <PTOSettingLog
            payPeriodStartDate={""}
            payPeriodEndDate={""}
            showChangeLogModal={this.state.showChangeLogModal}
            showHideChangeLogModal={this.showHideChangeLogModal}
            shiftId={this.state.changeLogptoID}
            hotelName={""}
            hotelId={0}
            hideCrossAndClearFilters={false}
            shiftDate={""}
            accType={""}
          />
        )}
      </React.Fragment>
    );
  }
}
