import React from "react";
import { Container, Dropdown, Tab, Tabs, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import scrollIntoView from "scroll-into-view";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment";
import _ from "lodash";
import { resolve, reject } from "q";
import { schConversions } from "../Labor/Schedule/commonFunctions/conversions";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import profile from "../../../Common/Assets/Images/profile.png";
import ForecastLock from "../../../Common/Assets/Images/icons/forecast-lock.svg";
import { HidSelector } from "../../../Common/Components/HidSelector";
import { MyImage } from "../../../Common/Components/MyImage";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { Utils } from "../../../Common/Utilis";
import { LaborTimesheetDisputeUserView } from "../TimeSheetUserView/LaborTimesheetDisputeUserView";
import { LaborTimesheetResolvedDisputeUserView } from "../TimeSheetUserView/LaborTimesheetResolvedDisputeUserView";
import { LaborTimeSheet } from "../../../Common/Services/LaborTimeSheet";
import { LaborPerformance } from "../../../Common/Services/LaborPerfomance";
import { LaborSmileID } from "../../../Common/Services/LaborSmileId";
import { ReportExport } from "../../../Common/Services/ReportExport";
import { IReportParameter } from "../../../Common/Contracts/IReportParameter";
export class LaborTimesheetDetailUserView extends React.Component<any, any> {
  private childHID: any;
  private linkRef: any;
  private openRow: any = {};
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.linkRef = React.createRef();
    this.state = {
      loaderTrue: false,
      mobileLoader: false,
      activetab: "details",
      timesheetStatus: "",
      hotelID: 0,
      imagePath: "",
      hotelName: "Select",
      userUniqueno: 0,
      userName: "",
      payPeriod: "",
      date: "",
      ruleType: "",
      position: "",
      activeClass: -1,
      allData: [],
      total: [],
      data: [],
      positionWiseList: [],
      positionList: [],
      dayDetails: [],
      alldepartmentlist: [],
      allpositionlist: [],
      breakPositionlist: [],
      actionText: "",
      adjustments: "0:00",
      dispute: 0,
      isDiputeOpen: "",
      condition: "All",
      exportedDate: "",
      flyingMenuList: ["Export to PDF"],
      exportLoader: false,
      addEntry: {
        newInTime: "00:00",
        newOutTime: "00:00",
        breakHH: "",
        breakMM: "",
        newDepartmentId: 0,
        newDepartment: "Select",
        newPositionId: 0,
        newPosition: "Select",
        newNotes: "",
      },
    };
    this.handleComparisonClick = this.handleComparisonClick.bind(this);
  }

  flyOutMenuClick = (item: any) => {
    if (item === "Export to PDF") {
      this.downloadLaborTimesheet();
    }
  };

  userData = JSON.parse(localStorage.getItem("storage")!);

  getTimeSheetOverViewDetails = (hotelID: Number, selectedDate: any) => {
    this.setState({ userUniqueno: this.userData.user_UniqueID });
    LaborTimeSheet.timeSheetOverViewDetails(hotelID, selectedDate)
      .then(async (result: any[] | null) => {
        if (result != null) {
          let userdata = result.find(
            (x) => x.userName === this.userData.userName
          );
          if (userdata) {
            this.setState(
              {
                position: userdata.position,
                imagePath: userdata.imagePath,
              },
              () => {
                this.loadLaborTimesheetDetails();
                this.loadTimesheetDateWise();
                this.getDisputeCount();
              }
            );
          } else {
            this.setState({
              userUniqueno: 0,
            });
            this.loadLaborTimesheetDetails();
            this.loadTimesheetDateWise();
            this.getDisputeCount();
          }
        } else {
          this.setState({
            userUniqueno: 0,
          });
          this.loadLaborTimesheetDetails();
          this.loadTimesheetDateWise();
          this.getDisputeCount();
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ loaderTrue: true, mobileLoader: true });
    let currentDate: any = new Date();
    if (
      JSON.parse(localStorage.storage).currentUtcTime !== "" &&
      JSON.parse(localStorage.storage).currentUtcTime !== null &&
      JSON.parse(localStorage.storage).currentUtcTime !== undefined
    ) {
      currentDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    this.setState({
      hotelName: hotelDetails.lettercode,
      hotelID: hotelDetails.hotelID,
      ruleType: "Standard Rule",
    });
    if (hotelDetails.hotelID !== 0) {
      this.getPayPeroid(currentDate, hotelDetails.hotelID);
    }
  };

  componentDidUpdate(prevProp: any, prevState: any) {
    let currentDate: any = new Date();
    if (
      JSON.parse(localStorage.storage).currentUtcTime !== "" &&
      JSON.parse(localStorage.storage).currentUtcTime !== null &&
      JSON.parse(localStorage.storage).currentUtcTime !== undefined
    ) {
      currentDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    if (prevState.hotelID !== this.state.hotelID) {
      this.setState({ loaderTrue: true, mobileLoader: true });
      this.getPayPeroid(currentDate, this.state.hotelID);
    }
  }

  nextPayperoid = () => {
    this.setState({ loaderTrue: true, mobileLoader: true });
    let date = new Date(this.state.calenderDates[1]);
    let dateCheck = new Date();
    if (
      JSON.parse(localStorage.storage).currentUtcTime !== "" &&
      JSON.parse(localStorage.storage).currentUtcTime !== null &&
      JSON.parse(localStorage.storage).currentUtcTime !== undefined
    ) {
      dateCheck = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    if (new Date(date) >= new Date(dateCheck.toDateString())) {
      return false;
    }
    this.setState(
      { calenderSelectDate: date.setDate(date.getDate() + 1) },
      () => {
        this.getPayPeroid(date, this.state.hotelID);
      }
    );
  };

  prevPayperoid = () => {
    this.setState({ loaderTrue: true, mobileLoader: true });
    let date = new Date(this.state.calenderDates[0]);
    this.setState(
      { calenderSelectDate: date.setDate(date.getDate() - 1) },
      () => {
        this.getPayPeroid(date, this.state.hotelID);
      }
    );
  };

  getPayPeroid = (selectedDate: any, hotelID: Number) => {
    selectedDate = new Date(selectedDate);
    selectedDate.setDate(selectedDate.getDate());
    let currentdate = selectedDate.getDate();
    let currentMonth = selectedDate.getMonth() + 1;
    let currentYear = selectedDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    this.setState({ calenderSelectDate: tempDate, date: tempDate });
    LaborPerformance.getPayPeriodDates(hotelID, tempDate, "Weekly")
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState(
            {
              calenderDates: [result[0].startdate, result[0].enddate],
              payPeriod: result[0].startEndDate,
              ruleType: result[0].ruleType,
              exportedDate: result[0].exporteddateMMMMDDYYYY,
            },
            () => {
              this.getTimeSheetOverViewDetails(hotelID, tempDate);
            }
          );
          LaborTimeSheet.getLaborTimesheetApprovalStatus(
            hotelID,
            result[0].startdate,
            result[0].enddate,
            this.userData.user_UniqueID
          )
            .then(async (result: any | null) => {
              if (result === "Agreed") {
                this.setState({ timesheetStatus: "Approved" });
              } else {
                this.setState({ timesheetStatus: result });
              }
              resolve();
            })
            .catch((err: any) => {
              Utils.toastError(`Server Error: ${err}`);
              this.setState({ loaderTrue: false, mobileLoader: false });
              reject();
            });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  getDisputeCount = () => {
    LaborTimeSheet.LaborDisputeCount(
      this.state.hotelID,
      this.state.date,
      this.userData.userName,
      "MyTimeSheet"
    )
      .then(async (result: any | null) => {
        if (result != null) {
          this.setState({
            dispute: result.totalDispute !== null ? result.totalDispute : 0,
            isDiputeOpen: result.openBedge,
            loaderTrue: false,
            mobileLoader: false,
          });
        } else {
          this.setState({ dispute: 0, loaderTrue: false, mobileLoader: false });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  formatDate = (time: string) => {
    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    let replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  };

  formatOutDate = (time: string) => {
    if (time === "00:00") {
      return "";
    }
    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    let replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  };

  loadLaborTimesheetDetails = () => {
    LaborTimeSheet.getLaborTimesheetDetails(
      this.state.hotelID,
      this.state.date,
      this.userData.userName,
      "MyTimeSheet"
    )
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.data != null) {
            this.setState({
              total: result.total,
              positionWiseList: result.positionWiseList,
              positionList: result.total.positionList,
            });

            let filterData: any[] = [];
            filterData = _.cloneDeep(result.data);
            if (this.state.ruleType === "Standard Rule") {
              filterData = filterData.filter(
                (item) => item.title.toUpperCase() !== "DOT"
              );
            }
            if (result.total.isHolidayshow === false) {
              filterData = filterData.filter(
                (item) => item.title.toUpperCase() !== "HOLIDAY"
              );
            }
            this.setState({ data: filterData, allData: filterData });
          } else {
            this.setState({
              data: [],
              allData: [],
              positionList: [],
              total: { totlhrs: "0:00" },
            });
          }
        } else {
          this.setState({
            data: [],
            allData: [],
            positionList: [],
            total: { totlhrs: "0:00" },
          });
        }
        resolve();
      })
      .catch((err: any) => {
        this.setState({
          data: [],
          allData: [],
          positionList: [],
          total: { totlhrs: "0:00" },
        });
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  downloadLaborTimesheet = () => {
    if (this.state.total?.totlhrs === "0:00") {
      Utils.toastError("No data to export");
      return;
    }

    this.setState({ exportLoader: true });
    const reportParameters: IReportParameter[] = [
      {
        name: "tenantId",
        value: JSON.parse(localStorage.getItem("storage")!).tenantID,
      },
      {
        name: "reportId",
        value: "14",
      },
      {
        name: "writerFormat",
        value: "PDF",
      },
      {
        name: "Hotelid",
        value: this.state.hotelID,
      },
      {
        name: "StartDate",
        value: this.state.calenderSelectDate,
      },
      {
        name: "Employee",
        value: this.userData.userName,
      },
    ];
    ReportExport.reportDownload(JSON.stringify(reportParameters))
      .then((result) => {
        const href = window.URL.createObjectURL(result);
        const a = this.linkRef.current;
        a.download = "TIMESHEET.pdf";
        a.href = href;
        a.click();
        a.href = "";
        this.setState({ exportLoader: false });
        resolve();
      })
      .catch((err: any) => {
        this.setState({ exportLoader: false });
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  loadTimesheetDateWise = () => {
    LaborTimeSheet.dayWiseTimeSheetDetails(
      this.state.hotelID,
      this.state.date,
      this.userData.userName,
      "MyTimeSheet"
    )
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.length > 0) {
            result = result.map((r: any) => {
              r.timesheet.map((elem: any) => {
                let tempElem = elem;
                tempElem.mobileOpen = false;
                return tempElem;
              });
              r.ref = React.createRef();
              return r;
            });
          }
          this.setState({ dayDetails: result });
        } else {
          this.setState({ dayDetails: [] });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  handleComparisonClick = (index: any, filteredBy: any) => {
    let condition = "";
    this.setState({ activeClass: index });
    let tableData: any[] = [];
    if (filteredBy === "All") {
      tableData = _.cloneDeep(this.state.allData);
      condition = "All";
    } else if (filteredBy === "Adjustments") {
      condition = "Adjustments";
    } else if (filteredBy === "Dispute") {
      condition = "Dispute";
    } else {
      tableData = _.cloneDeep(this.state.positionWiseList);
      tableData = tableData.filter((item) => item.position === filteredBy);
      condition = "All";
    }
    this.setState({ data: tableData, condition: condition });
  };

  onParentGridCellClick = (cell: any) => {
    let header = this.state.data;
    let compDate = "";
    if (header.length > 0) {
      compDate =
        header[0][cell].date +
        "/" +
        new Date(this.state.calenderDates[0])
          .getFullYear()
          .toString()
          .substr(2, 2);
      const ref = this.state.dayDetails.find((r: any) =>
        r.timesheet
          .map((x: any) => {
            let date = new Date(x.date);
            const _date =
              (date.getMonth() + 1).toString().padStart(2, "0") +
              "/" +
              date.getDate().toString().padStart(2, "0") +
              "/" +
              date.getFullYear().toString().substr(2, 2);
            return _date;
          })
          .includes(compDate)
      )?.ref?.current;
      if (ref) {
        ref.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  };

  headerFormatter = (column: any, colIndex: any) => {
    let header = this.state.data;
    if (header.length > 0) {
      let header1 = "";
      let header2 = "";
      switch (column.text) {
        case "total":
          header1 = "Total";
          header2 = "";
          break;
        case "days1":
          header1 = header[0].days1.dayname;
          header2 = header[0].days1.date;
          break;
        case "days2":
          header1 = header[0].days2.dayname;
          header2 = header[0].days2.date;
          break;
        case "days3":
          header1 = header[0].days3.dayname;
          header2 = header[0].days3.date;
          break;
        case "days4":
          header1 = header[0].days4.dayname;
          header2 = header[0].days4.date;
          break;
        case "days5":
          header1 = header[0].days5.dayname;
          header2 = header[0].days5.date;
          break;
        case "days6":
          header1 = header[0].days6.dayname;
          header2 = header[0].days6.date;
          break;
        case "days7":
          header1 = header[0].days7.dayname;
          header2 = header[0].days7.date;
          break;
      }
      return (
        <>
          {column.text !== "" && (
            <div
              onClick={() => this.onParentGridCellClick(column.text)}
              className="column-header"
            >
              <div className="header-data">
                <span className="header-day">{header1}</span>
              </div>
              <div className="header-date">{header2}</div>
            </div>
          )}
          {column.text === "" && <div className="vertical-line-th"></div>}
        </>
      );
    }
  };

  FormatHoursMinutesDuration = (HoursMints: number, totalBreak: number) => {
    HoursMints = HoursMints - Number(totalBreak);
    if (HoursMints < 0) {
      HoursMints = HoursMints * -1;
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${"-"}${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    } else {
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    }
  };

  getSimleIDProfileImage = (mode: any, row: any) => {
    let request = {
      hotelID: this.state.hotelID,
      uniqueID: mode === "In" ? row.inUniqueno : row.outUniqueno,
    };

    LaborSmileID.GetSimleIDProfile(request)
      .then(async (result: any | null) => {
        let staticPath: string = "";
        let basePath: any;
        if (result.success) {
          if (result.result !== "") {
            staticPath = "data:image/jpeg;base64,";
            basePath = result.result;
          } else {
            staticPath = "";
            basePath = profile;
          }
        } else {
          staticPath = "";
          basePath = profile;
        }
        let smileId = mode === "In" ? row.inUniqueno : row.outUniqueno;
        $("#" + smileId).attr("src", staticPath + basePath);
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  };

  getParentTableData = (index: any) => {
    let nonBreakData = this.state.dayDetails[index].timesheet.filter(
      (data: any) => data.entryType !== "Break"
    );
    let parentTableData: any = [];
    for (let i = 0; i < nonBreakData.length; i++) {
      let pushObject: any = {};
      let existingIndex: number = parentTableData.findIndex(
        (data: any) =>
          data?.parentData[0]?.scheduleNo === nonBreakData[i].scheduleNo
      );

      if (existingIndex >= 0) {
        parentTableData[existingIndex].parentData.push(nonBreakData[i]);
      } else {
        let tableTempData: any = [];
        tableTempData.push(nonBreakData[i]);
        pushObject.tableKey = i;
        pushObject.parentData = tableTempData;
        parentTableData.push(pushObject);
      }
    }
    return parentTableData;
  };

  getParentTable = (index: any) => {
    return (
      <BootstrapTable
        id={"timesheetParentDetails"}
        keyField="tableKey"
        data={this.getParentTableData(index)}
        columns={this.getParentTableColumns(index)}
        hover
        expandRow={this.parentRowExpender}
      />
    );
  };

  getParentTableColumns = (index: any) => [
    {
      dataField: "position",
      text: "Position",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="position-wrapper">
          <EllipsisWithTooltip>
            <div className="positon-name">{row?.parentData[0]?.position}</div>
          </EllipsisWithTooltip>
          <div className="schedule-status">Scheduled</div>
        </div>
      ),
      footer: "Totals",
    },
    {
      dataField: "",
      text: "Shift",
      formatter: (cell: any, row: any, rowIndex: any) => {
        const actualStartTimeAMPM = moment(
          `${row?.parentData[0]?.inDate} ${row?.parentData[0]?.inTime}`
        ).format("h:mm A");
        const actualEndTimeAMPM = moment(
          `${row?.parentData[row?.parentData.length - 1]?.outDate} ${
            row?.parentData[row?.parentData.length - 1]?.outTime
          }`
        ).format("h:mm A");
        const scheduleStartTimeAMPM = moment(
          `${row?.parentData[0]?.scheduleInDate} ${row?.parentData[0]?.scheduleInTime}`
        ).format("h:mm A");
        const scheduleEndTimeAMPM = moment(
          `${row?.parentData[row?.parentData.length - 1]?.scheduleOutDate} ${
            row?.parentData[row?.parentData.length - 1]?.scheduleOutTime
          }`
        ).format("h:mm A");
        return (
          <div className="shift-wrapper">
            {row?.parentData[0]?.noShow === 1 &&
            row?.parentData[0]?.inDate === "" &&
            row?.parentData[0]?.outDate === "" ? (
              <div className="red-badges red-bg">No Show</div>
            ) : (
              <div className="shift-actual">
                {row?.parentData[0].opens === "Open"
                  ? `${actualStartTimeAMPM} - `
                  : `${actualStartTimeAMPM} - ${actualEndTimeAMPM}`}
              </div>
            )}
            {row?.parentData[0].manuals.length > 0 ? (
              row?.parentData[0].manuals !== "Manual" ? (
                <div className="red-badges blue-bg">
                  {row?.parentData[0].manuals}
                </div>
              ) : scheduleStartTimeAMPM === scheduleEndTimeAMPM ? (
                <div className="red-badges blue-bg">
                  {row?.parentData[0].manuals}
                </div>
              ) : (
                <div className="shift-scheduled">
                  {`${scheduleStartTimeAMPM} - ${scheduleEndTimeAMPM}`}
                </div>
              )
            ) : (
              <div className="shift-scheduled">
                {`${scheduleStartTimeAMPM} - ${scheduleEndTimeAMPM}`}
              </div>
            )}
          </div>
        );
      },
      footer: "",
    },
    {
      dataField: "",
      text: "Totals",
      formatter: (cell: any, row: any, rowIndex: any) => {
        let rowScheduleNo = row.parentData[0].scheduleNo;
        let totalActual = 0;
        let totalSchedule = 0;
        let timesheetData = this.state.dayDetails[index].timesheet;

        for (let i = 0; i < timesheetData.length; i++) {
          if (timesheetData[i].scheduleNo === rowScheduleNo) {
            if (
              timesheetData[i].entryType === "Break" &&
              timesheetData[i].noShow !== 1 &&
              timesheetData[i].inDate !== "" &&
              timesheetData[i].outDate !== "" &&
              timesheetData[i].opens !== "Open"
            ) {
              let breakTimeInMinutes = schConversions.convertHourToMInute(
                timesheetData[i].breakTime
              );
              totalActual = totalActual - breakTimeInMinutes;
            } else {
              if (
                timesheetData[i].noShow !== 1 &&
                timesheetData[i].inDate !== "" &&
                timesheetData[i].outDate !== "" &&
                timesheetData[i].opens !== "Open"
              ) {
                let actualInTime = moment(
                  `${timesheetData[i].inDate} ${timesheetData[i].inTime}`
                );
                let actualOutTime = moment(
                  `${timesheetData[i].outDate} ${timesheetData[i].outTime}`
                );
                let diffInActual = actualOutTime.diff(actualInTime, "minutes");
                totalActual = totalActual + diffInActual;
              }

              let scheduleInTime = moment(
                `${timesheetData[i].scheduleInDate} ${timesheetData[i].scheduleInTime}`
              );
              let scheduleOutTime = moment(
                `${timesheetData[i].scheduleOutDate} ${timesheetData[i].scheduleOutTime}`
              );

              let diffInSchedue = scheduleOutTime.diff(
                scheduleInTime,
                "minutes"
              );

              totalSchedule = totalSchedule + diffInSchedue;
            }
          }
        }
        return (
          <div className="total-wrapper">
            <div className="total-actual red">
              {schConversions.convertMinuteToHour(totalActual)}
            </div>
            <div className="total-scheduled">
              {schConversions.convertMinuteToHour(totalSchedule)}
            </div>
          </div>
        );
      },
      footer: (columnData: any) => {
        const timesheetData = this.state.dayDetails[index].timesheet;
        let totalActual: number = 0;
        for (let i = 0; i < timesheetData.length; i++) {
          if (
            timesheetData[i].noShow !== 1 &&
            timesheetData[i].inDate !== "" &&
            timesheetData[i].outDate !== "" &&
            timesheetData[i].opens !== "Open"
          ) {
            if (timesheetData[i].entryType === "Break") {
              let breakTimeInMinutes = schConversions.convertHourToMInute(
                timesheetData[i].breakTime
              );
              totalActual = totalActual - breakTimeInMinutes;
            } else {
              let actualInTime = moment(
                `${timesheetData[i].inDate} ${timesheetData[i].inTime}`
              );
              let actualOutTime = moment(
                `${timesheetData[i].outDate} ${timesheetData[i].outTime}`
              );
              let diffInActual = actualOutTime.diff(actualInTime, "minutes");
              totalActual = totalActual + diffInActual;
            }
          }
        }
        return (
          <div className="footer-total-actual">
            {schConversions.convertMinuteToHour(totalActual)}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Variance",
      formatter: (cell: any, row: any, rowIndex: any) => {
        let rowScheduleNo = row.parentData[0].scheduleNo;
        let totalActual = 0;
        let totalSchedule = 0;
        let timesheetData = this.state.dayDetails[index].timesheet;

        for (let i = 0; i < timesheetData.length; i++) {
          if (timesheetData[i].scheduleNo === rowScheduleNo) {
            if (
              timesheetData[i].entryType === "Break" &&
              timesheetData[i].noShow !== 1 &&
              timesheetData[i].inDate !== "" &&
              timesheetData[i].outDate !== "" &&
              timesheetData[i].opens !== "Open"
            ) {
              let breakTimeInMinutes = schConversions.convertHourToMInute(
                timesheetData[i].breakTime
              );
              totalActual = totalActual - breakTimeInMinutes;
            } else {
              if (
                timesheetData[i].noShow !== 1 &&
                timesheetData[i].inDate !== "" &&
                timesheetData[i].outDate !== "" &&
                timesheetData[i].opens !== "Open"
              ) {
                let actualInTime = moment(
                  `${timesheetData[i].inDate} ${timesheetData[i].inTime}`
                );
                let actualOutTime = moment(
                  `${timesheetData[i].outDate} ${timesheetData[i].outTime}`
                );
                let diffInActual = actualOutTime.diff(actualInTime, "minutes");
                totalActual = totalActual + diffInActual;
              }

              let scheduleInTime = moment(
                `${timesheetData[i].scheduleInDate} ${timesheetData[i].scheduleInTime}`
              );
              let scheduleOutTime = moment(
                `${timesheetData[i].scheduleOutDate} ${timesheetData[i].scheduleOutTime}`
              );

              let diffInSchedue = scheduleOutTime.diff(
                scheduleInTime,
                "minutes"
              );

              totalSchedule = totalSchedule + diffInSchedue;
            }
          }
        }

        let valueInDiff = Number(totalSchedule - totalActual);
        let modValueInDiff = valueInDiff;
        if (modValueInDiff < 0) {
          modValueInDiff = modValueInDiff * -1;
        }
        return (
          <div className="varience-wrapper text-center">
            <div
              className={
                valueInDiff < 0 ? "varience-actual red" : "varience-actual"
              }
            >
              {schConversions.convertMinuteToHour(modValueInDiff)}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "ot",
      text: "Overtime",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="overtime-wrapper text-center">
          <div
            className={
              row?.parentData[0]?.ot !== "0:00"
                ? "overtime-actual red"
                : "overtime-actual"
            }
          >
            {row?.parentData[0]?.ot}
          </div>
        </div>
      ),
    },
    {
      dataField: "dot",
      text: "DOT",
      hidden: this.state.ruleType === "Standard Rule" ? true : false,
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="overtime-wrapper text-center">
          <div
            className={
              row?.parentData[0]?.ot !== "0:00"
                ? "overtime-actual red"
                : "overtime-actual"
            }
          >
            {row?.parentData[0]?.dot}
          </div>
        </div>
      ),
    },
  ];

  inTimeFormatter = (row: any, rowIndex: number, index: number) => {
    return (
      <>
        {row.isActualentry !== "No" && row.entryType.toLowerCase() !== "break" && (
          <div className="d-flex align-items-center">
            {row.maxCountin > 1 && <div className="dot margin-right"></div>}
            {((row.maxCountin > 1 &&
              row.approveStatus.toString().toLowerCase() !== "yes" &&
              row.opens !== "Open") ||
              row.lastInNotes.length > 0 ||
              row.inImage.length > 0) && (
              <Dropdown className="more-action grey">
                <Dropdown.Toggle
                  className="btn-outline-primary inner-sec-btn more pos-abs"
                  id="dropdown-more"
                >
                  <div className="d-flex align-items-center">
                    {this.formatDate(row.inTime)}
                    {row.inImage.length > 0 && (
                      <div>
                        {row.inimagestatus === "Pending" && (
                          <div className="orange-bg">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                              />
                              <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                            </svg>
                          </div>
                        )}
                        {row.inimagestatus === "Pass" && (
                          <div className="grey-bg">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                              />
                              <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                            </svg>
                          </div>
                        )}
                        {row.inimagestatus === "Fail" && (
                          <div className="red-badges">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                              />
                              <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                            </svg>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="pos-name">
                    <Dropdown.Item className="cross-btn" href="">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                          fill="#84888C"
                        />
                      </svg>
                    </Dropdown.Item>
                  </div>
                  <Tabs id={"punchIn-tab-" + rowIndex}>
                    {row.lastInNotes.length > 0 && (
                      <Tab
                        eventKey={"details" + rowIndex + index}
                        title="Details"
                      >
                        <div className="section">
                          <div className="pading">
                            <div className="d-flex start">
                              <div className="profile-img">
                                <img
                                  src={
                                    row.lastInImage === null ||
                                    row.lastInImage.trim() === ""
                                      ? profile
                                      : row.lastInImage
                                  }
                                  className={
                                    row.lastInImage === null ||
                                    row.lastInImage.trim() === ""
                                      ? "default"
                                      : ""
                                  }
                                  width="40"
                                  height="40"
                                  alt={row.lastInUsername}
                                />
                              </div>
                              <div className="details">
                                <div className="name">{row.lastInUsername}</div>
                                <div className="date">{row.lastInDate}</div>
                              </div>
                            </div>
                            <div className="notes">{row.lastInNotes}</div>
                            <div className="time d-flex start">
                              <div className="current-status">
                                <div className="ttl">Original</div>
                                <div className="time">
                                  {row.firstPunchinTime}
                                </div>
                              </div>
                              <div className="last-status">
                                <div className="ttl">Updated</div>
                                <div className="time">
                                  {this.formatDate(row.inTime)}
                                </div>
                              </div>
                            </div>
                          </div>
                          {row.intimeDispute !== "" && (
                            <div className="related-link">
                              <div className="title">Related</div>
                              <ul>
                                <li>
                                  <span>Punch In Dispute</span>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </Tab>
                    )}
                    {row.inImage.length > 0 && (
                      <Tab
                        eventKey={"smile-id" + rowIndex + index}
                        edit-punch
                        title="Smile iD"
                      >
                        <div className="section">
                          <div className="pading edit-pad">
                            <div className="currentimg">
                              <MyImage
                                src={row.inImage}
                                fallbackSrc={profile}
                                alt="User SmileId"
                                id={row.inUniqueno}
                              />
                              {row.inimagestatus === "Pending" && (
                                <div className="badges red-badges orange-bg">
                                  {row.inimagelevel} %
                                </div>
                              )}
                              {row.inimagestatus === "Pass" && (
                                <div className="badges red-badges green-bg">
                                  {row.inimagelevel} %
                                </div>
                              )}
                              {row.inimagestatus === "Fail" && (
                                <div className="badges red-badges red-badges">
                                  {row.inimagelevel} %
                                </div>
                              )}
                            </div>
                            <div className="profile-details">
                              {row.inimagestatus === "Pending" && (
                                <div className="q-details">
                                  Is this {row.employee}?
                                </div>
                              )}
                              {row.inimagestatus !== "Pending" && (
                                <div className="q-details">{row.employee}</div>
                              )}
                              <div className="punch-details">
                                Punched in at {row.inImageTime} on {row.inDate}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {row.approveStatus.toString().toLowerCase() === "yes" &&
              row.lastInNotes.length === 0 &&
              row.inImage.length === 0 && (
                <div className="rm-bold margin-top">
                  {this.formatDate(row.inTime)}
                </div>
              )}
            {row.maxCountin <= 1 &&
              row.approveStatus.toString().toLowerCase() !== "yes" && (
                <div className="rm-bold margin-top">
                  {this.formatDate(row.inTime)}
                </div>
              )}
          </div>
        )}
        {row.entryType.toLowerCase() === "break" && (
          <div className="d-flex align-items-center">Manual Break</div>
        )}
      </>
    );
  };

  outTimeFormatter = (row: any, rowIndex: number, index: number) => {
    return (
      <>
        {row.isActualentry !== "No" &&
          row.entryType.toLowerCase() !== "break" &&
          row.opens !== "Open" && (
            <div className="d-flex align-items-center">
              {row.maxCountout > 1 && <div className="dot margin-right"></div>}
              {((row.maxCountout > 1 &&
                row.approveStatus.toString().toLowerCase() !== "yes" &&
                row.opens !== "Open") ||
                row.lastOutNotes.length > 0 ||
                row.outImage.length > 0) && (
                <Dropdown className="more-action grey">
                  <Dropdown.Toggle
                    className="btn-outline-primary inner-sec-btn more pos-abs"
                    id="dropdown-more"
                  >
                    <div className="d-flex align-items-center">
                      {this.formatOutDate(row.outTime)}
                      {row.outImage.length > 0 && (
                        <div>
                          {row.outImageStatus === "Pending" && (
                            <div className="orange-bg">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                />
                                <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                              </svg>
                            </div>
                          )}
                          {row.outImageStatus === "Pass" && (
                            <div className="grey-bg">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                />
                                <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                              </svg>
                            </div>
                          )}
                          {row.outImageStatus === "Fail" && (
                            <div className="red-badges">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                />
                                <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="pos-name">
                      <Dropdown.Item className="cross-btn" href="">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                            fill="#84888C"
                          />
                        </svg>
                      </Dropdown.Item>
                    </div>
                    <Tabs id={"punchOut-tab-" + rowIndex}>
                      {row.lastOutNotes.length > 0 && (
                        <Tab
                          eventKey={"details" + rowIndex + index}
                          title="Details"
                        >
                          <div className="section">
                            <div className="pading">
                              <div className="d-flex start">
                                <div className="profile-img">
                                  <img
                                    src={
                                      row.lastOutImage === null ||
                                      row.lastOutImage.trim() === ""
                                        ? profile
                                        : row.lastOutImage
                                    }
                                    className={
                                      row.lastOutImage === null ||
                                      row.lastOutImage.trim() === ""
                                        ? "default"
                                        : ""
                                    }
                                    width="40"
                                    height="40"
                                    alt={row.lastOutUsername}
                                  />
                                </div>
                                <div className="details">
                                  <div className="name">
                                    {row.lastOutUsername}
                                  </div>
                                  <div className="date">{row.lastOutDate}</div>
                                </div>
                              </div>
                              <div className="notes">{row.lastOutNotes}</div>
                              <div className="time d-flex start">
                                <div className="current-status">
                                  <div className="ttl">Original</div>
                                  <div className="time">
                                    {row.firstPunchoutTime}
                                  </div>
                                </div>
                                <div className="last-status">
                                  <div className="ttl">Updated</div>
                                  <div className="time">
                                    {this.formatOutDate(row.outTime)}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {row.outtimeDispute !== "" && (
                              <div className="related-link">
                                <div className="title">Related</div>
                                <ul>
                                  <li>
                                    <span>Punch Out Dispute</span>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </Tab>
                      )}
                      {row.outImage.length > 0 && (
                        <Tab
                          eventKey={"smile-id" + rowIndex + index}
                          title="Smile iD"
                        >
                          <div className="section">
                            <div className="pading edit-pad">
                              <div className="currentimg">
                                <MyImage
                                  src={row.outImage}
                                  fallbackSrc={profile}
                                  alt="User SmileId"
                                  id={row.outUniqueno}
                                ></MyImage>
                                {row.outImageStatus === "Pending" && (
                                  <div className="badges red-badges orange-bg">
                                    {row.outImageLevel} %
                                  </div>
                                )}
                                {row.outImageStatus === "Pass" && (
                                  <div className="badges red-badges green-bg">
                                    {row.outImageLevel} %
                                  </div>
                                )}
                                {row.outImageStatus === "Fail" && (
                                  <div className="badges red-badges red-badges">
                                    {row.outImageLevel} %
                                  </div>
                                )}
                              </div>
                              <div className="profile-details">
                                {row.outImageStatus === "Pending" && (
                                  <div className="q-details">
                                    Is this {row.employee}?
                                  </div>
                                )}
                                {row.outImageStatus !== "Pending" && (
                                  <div className="q-details">
                                    {row.employee}
                                  </div>
                                )}
                                <div className="punch-details">
                                  Punched out at {row.outImageTime} on{" "}
                                  {row.outDate}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>
                      )}
                    </Tabs>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {row.approveStatus.toString().toLowerCase() === "yes" &&
                row.lastOutNotes.length === 0 &&
                row.outImage.length === 0 && (
                  <div className="rm-bold margin-top">
                    {this.formatOutDate(row.outTime)}
                  </div>
                )}
              {row.maxCountout <= 1 &&
                row.approveStatus.toString().toLowerCase() !== "yes" && (
                  <div className="rm-bold margin-top">
                    {this.formatOutDate(row.outTime)}
                  </div>
                )}
            </div>
          )}
      </>
    );
  };

  breakTimeFormatter = (row: any, rowIndex: number, index: number) => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        {row.maxBreakcount > 1 && <div className="dot margin-right"></div>}
        {((row.maxBreakcount > 1 &&
          row.approveStatus.toString().toLowerCase() !== "yes") ||
          row.lastBreakNotes.length > 0) && (
          <Dropdown className="more-action grey">
            <Dropdown.Toggle
              className="btn-outline-primary inner-sec-btn more pos-abs"
              id="dropdown-more"
            >
              <div className="d-flex align-items-center">{row.breakTime}</div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="pos-name">
                <Dropdown.Item className="cross-btn" href="">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                      fill="#84888C"
                    />
                  </svg>
                </Dropdown.Item>
              </div>
              <Tabs id={"punchBreak-tab-" + rowIndex}>
                {row.lastBreakNotes.length > 0 && (
                  <Tab eventKey={"details" + rowIndex + index} title="Details">
                    <div className="section">
                      <div className="pading">
                        <div className="d-flex start">
                          <div className="profile-img">
                            <img
                              src={row.breakUserImage}
                              width="40"
                              height="40"
                              alt={row.lastBreakUser}
                            />
                          </div>
                          <div className="details">
                            <div className="name">{row.lastBreakUser}</div>
                            <div className="date">{row.lastBreakDate}</div>
                          </div>
                        </div>
                        <div className="notes">{row.lastBreakNotes}</div>
                        <div className="time d-flex start">
                          <div className="current-status">
                            <div className="ttl">Original</div>
                            <div className="time">
                              {row.firstBreakHrs.toString().replace(".", ":")}
                            </div>
                          </div>
                          <div className="last-status">
                            <div className="ttl">Updated</div>
                            <div className="time">
                              {row.breakTime.toString().replace(".", ":")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                )}
              </Tabs>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {row.approveStatus.toString().toLowerCase() === "yes" &&
          row.lastBreakNotes.length === 0 && (
            <div className="rm-bold margin-top">{row.breakTime}</div>
          )}
        {row.maxBreakcount <= 1 &&
          row.approveStatus.toString().toLowerCase() !== "yes" && (
            <div className="rm-bold margin-top">{row.breakTime}</div>
          )}
      </div>
    );
  };

  getChildTableData = (row: any) => {
    let dataIndex: number = -1;
    for (let i = 0; i < this.state.dayDetails.length; i++) {
      if (
        row?.parentData[0]?.dayName ===
        this.state.dayDetails[i].timesheet[0].dayName
      ) {
        dataIndex = i;
        break;
      }
    }
    let childTableAllData = this.state.dayDetails[dataIndex].timesheet.filter(
      (data: any) =>
        data.scheduleNo === row?.parentData[0]?.scheduleNo && data.noShow !== 1
    );
    let childTableNonBreakData = childTableAllData.filter(
      (data: any) => data.entryType !== "Break"
    );
    let childTableBreakData = childTableAllData.filter(
      (data: any) => data.entryType === "Break"
    );
    let childTableData: any = [
      ...childTableNonBreakData,
      ...childTableBreakData,
    ];
    return { childTableData, dataIndex };
  };

  parentRowExpender = {
    renderer: (row: any, rowIndex: any) => {
      let { childTableData, dataIndex } = this.getChildTableData(row);
      return (
        <div>
          <BootstrapTable
            id={"timesheetDetails"}
            keyField="titlee"
            data={childTableData}
            columns={this.getChildTableColumns(dataIndex)}
            hover
          />
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    onlyOneExpanding: true,
    onExpand: (row: any, isExpand: any, rowIndex: any, e: any) => {
      if (isExpand) {
        this.openRow = row?.parentData[0];
        let dataIndex: number = -1;
        for (let i = 0; i < this.state.dayDetails.length; i++) {
          if (
            row?.parentData[0]?.dayName ===
            this.state.dayDetails[i].timesheet[0].dayName
          ) {
            dataIndex = i;
            break;
          }
        }
        let data = _.cloneDeep(this.state.dayDetails);
        let timesheetRef = data[dataIndex].ref?.current;

        scrollIntoView(timesheetRef, {
          align: {
            top: 0,
            left: 0,
            topOffset: 200,
            leftOffset: 0,
          },
        });
      }
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <span className="chevronExpended">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      }
      return (
        <span className="chevronCollpsed">
          <img src={chevronRight} alt="chevron Right" />
        </span>
      );
    },
  };

  getChildTableColumns = (index: any) => [
    {
      dataField: "inTime",
      text: "In",
      formatter: (cell: any, row: any, rowIndex: any) =>
        this.inTimeFormatter(row, rowIndex, index),
      footer: "Totals",
    },
    {
      dataField: "outTime",
      text: "Out",
      formatter: (cell: any, row: any, rowIndex: any) =>
        this.outTimeFormatter(row, rowIndex, index),
      footer: "",
    },
    {
      dataField: "breakTime",
      text: "Break",
      formatter: (cell: any, row: any, rowIndex: any) =>
        this.breakTimeFormatter(row, rowIndex, index),
      footer: (columnData: any) => {
        let totalBreak = 0;
        for (let i = 0; i < columnData.length; i++) {
          totalBreak =
            totalBreak +
            schConversions.convertHourToMInute(
              columnData[i] === "" ? "0:00" : columnData[i]
            );
        }
        return schConversions.convertMinuteToHour(totalBreak);
      },
    },
    {
      dataField: "total",
      text: "Total",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <>{row.entryType.toLowerCase() !== "break" && <>{row.total}</>}</>
      ),
      footer: (columnData: any) => {
        let total = 0;
        let breakTime = 0;
        let dataArr: any[] = _.cloneDeep(
          this.state.dayDetails[index].timesheet
        );
        columnData.map((data: any) => {
          if (data && data.trim().length > 0 && data.includes(":")) {
            let subTotal = 0;
            const dataArray = data.split(":");
            let HH = Number(dataArray[0]);
            let MM = Number(dataArray[1]);
            if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
              HH = HH < 0 ? HH * -1 : HH;
              MM = MM < 0 ? MM * -1 : MM;
              subTotal = (HH * 60 + MM) * -1;
            } else {
              subTotal = HH * 60 + MM;
            }
            total = total + subTotal;
          }
          return false;
        });
        if (dataArr.length > 0) {
          dataArr = dataArr.filter((item) => item.entryType === "Break");
          if (dataArr.length > 0) {
            dataArr.map((dataArr: any) => {
              if (dataArr.breakTime.length > 0) {
                let subbreakTotal = 0;
                if (dataArr.breakTime.includes(":")) {
                  const dataArray = dataArr.breakTime.split(":");
                  let HH = Number(dataArray[0]);
                  let MM = Number(dataArray[1]);
                  if (
                    dataArray[0].includes("-") ||
                    dataArray[1].includes("-")
                  ) {
                    HH = HH < 0 ? HH * -1 : HH;
                    MM = MM < 0 ? MM * -1 : MM;
                    subbreakTotal = (HH * 60 + MM) * -1;
                  } else {
                    subbreakTotal = HH * 60 + MM;
                  }
                  breakTime = breakTime + subbreakTotal;
                }
              }
              return false;
            });
          }
        } else {
          breakTime = 0;
        }
        return this.FormatHoursMinutesDuration(total, breakTime);
      },
    },
    {
      dataField: "position",
      text: "Position",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <EllipsisWithTooltip placement="bottom">
          {row.position}
        </EllipsisWithTooltip>
      ),
      footer: "",
    },
    {
      dataField: "",
      text: "",
      footer: "",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="d-flex flex-wrap">
          {row.apo.length > 0 && <div className="red-badges">{row.apo}</div>}
          {row.manuals.length > 0 && (
            <div className="red-badges blue-bg">{row.manuals}</div>
          )}
          {row.opens !== "" && row.opens !== null && (
            <div className="red-badges">{row.opens}</div>
          )}
          {row.otf !== "" && row.otf !== null && (
            <div className="red-badges blue-bg">{row.otf}</div>
          )}
        </div>
      ),
    },
    {
      dataField: "",
      text: "",
      formatter: (cell: any, row: any, rowIndex: any) => (
        <div className="d-flex">
          {row.laborActualCommentRes?.length > 0 && (
            <Dropdown className="more-action notes-btn" alignRight>
              <Dropdown.Toggle
                className="btn-outline-primary  more"
                id="dropdown-more"
              >
                <div className="d-flex">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                    <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                  </svg>
                  <span>{row.laborActualCommentRes?.length}</span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="notes-sec">
                  <div className="notes-list">
                    {row.laborActualCommentRes.map((item: any, idx: any) => (
                      <div className="comment-list">
                        <div className="d-flex profile-details">
                          <div className="profile-img">
                            <img
                              src={
                                item.imagepath === null ||
                                item.imagepath.trim() === ""
                                  ? profile
                                  : item.imagepath
                              }
                              className={
                                item.imagepath === null ||
                                item.imagepath.trim() === ""
                                  ? "default"
                                  : ""
                              }
                              width="40"
                              height="40"
                              alt={item.username}
                            />
                          </div>
                          <div>
                            <div className="main-ttl">{item.username}</div>
                            <div className="sub-ttl">
                              {Utils.getDateTimeMddyyFromUtc(item.commentdate)}
                            </div>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <div className="d-flex">
                              <div className="detail-sec">
                                <div className="comments">{item.comments}</div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      ),
      footer: "",
    },
    {
      dataField: "",
      text: "",
    },
  ];

  // Mobile Design Function Starts
  getMobileViewDayTimesheet = () => {
    return (
      <div className="detail-view">
        {this.state.dayDetails.map((item: any, index: any) => {
          let mobileParentData = this.getParentTableData(index);
          return (
            <div className="day-list" key={index}>
              <div className="day-stamp">
                {`${item.timesheet[0].dayName} `}
                {item.timesheet[0].approveStatus.toString().toLowerCase() ===
                  "yes" && <img src={ForecastLock} alt="ForecastLock" />}
              </div>
              <div ref={item.ref} className="day-wise-details-table">
                <div className="table-head d-flex">
                  <div className="arrow-col"></div>
                  <div className="shift-col mr-auto">Shift</div>
                  <div className="total-col">Totals</div>
                </div>
                {mobileParentData.map((elem: any, idx: number) => {
                  let parentData = elem?.parentData;
                  let actualInTimeAMPM = moment(
                    `${parentData[0].inDate} ${parentData[0].inTime}`
                  ).format("h:mm A");
                  let actualOutTimeAMPM = moment(
                    `${parentData[parentData.length - 1].outDate} ${
                      parentData[parentData.length - 1].outTime
                    }`
                  ).format("h:mm A");

                  if (
                    parentData[0].actualUniqueno === 0 &&
                    parentData[0].scheduleNo === 0
                  ) {
                    return null;
                  } 
                  // else if (
                  //   parentData[0].actualUniqueno === 0 &&
                  //   actualInTimeAMPM === actualOutTimeAMPM
                  // ) {
                  //   return null;
                  // } 
                  else {
                    let scheduleInTimeAMPM = moment(
                      `${parentData[0].scheduleInDate} ${parentData[0].scheduleInTime}`
                    ).format("h:mm A");
                    let scheduleOutTimeAMPM = moment(
                      `${parentData[parentData.length - 1].scheduleOutDate} ${
                        parentData[parentData.length - 1].scheduleOutTime
                      }`
                    ).format("h:mm A");

                    let isRowExpended = parentData[0].mobileOpen;

                    let { totalActual, totalSchedule } = this.getTotalActual(
                      index,
                      parentData
                    );

                    let noShowFlag: boolean = false;
                    if (
                      parentData[0]?.noShow === 1 &&
                      parentData[0]?.inDate === "" &&
                      parentData[0]?.outDate === ""
                    ) {
                      noShowFlag = true;
                    }

                    let manualShiftFlag: boolean = false;

                    if (parentData[0].manuals.length > 0) {
                      if (parentData[0].manuals !== "Manual") {
                        manualShiftFlag = true;
                      } else {
                        if (scheduleInTimeAMPM === scheduleOutTimeAMPM) {
                          manualShiftFlag = true;
                        }
                      }
                    }

                    return (
                      <div className="table-body">
                        <div
                          className="day-wise-row d-flex"
                          onClick={() =>
                            this.mobileRowExpension(index, idx, parentData)
                          }
                        >
                          <div
                            className={
                              isRowExpended ? "arrow-col rotate" : "arrow-col"
                            }
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.92247 14.7558L13.6783 9.99997L8.92247 5.24414L7.74414 6.42247L11.3216 9.99997L7.74414 13.5775L8.92247 14.7558Z"
                                fill="#84888C"
                              />
                            </svg>
                          </div>
                          <div className="shift-col mr-auto">
                            <div className="actual-details">
                              {noShowFlag ? (
                                <div className="red-badges red-bg">No Show</div>
                              ) : (
                                <div className="shift-actual">
                                  {parentData[0].opens === "Open"
                                    ? `${actualInTimeAMPM} - `
                                    : `${actualInTimeAMPM} - ${actualOutTimeAMPM}`}
                                </div>
                              )}
                            </div>
                            <div className="schedulde-details">
                              {manualShiftFlag ? (
                                <div className="red-badges blue-bg">
                                  {parentData[0].manuals}
                                </div>
                              ) : (
                                <div className="shift-scheduled">
                                  {`${scheduleInTimeAMPM} - ${scheduleOutTimeAMPM}`}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="total-col">
                            <div className="actual-details red">
                              {schConversions.convertMinuteToHour(totalActual)}
                            </div>
                            <div className="schedulde-details">
                              {schConversions.convertMinuteToHour(
                                totalSchedule
                              )}
                            </div>
                          </div>
                        </div>
                        {isRowExpended &&
                          parentData.map((chData: any, chIdx: number) => {
                            return (
                              <div className="mobile-row expended" key={chIdx}>
                                <div className="upper-section">
                                  <div className="in-time">
                                    <span className="name">In</span>
                                    <span className="value">
                                      {noShowFlag
                                        ? "-"
                                        : this.inTimeFormatter(
                                            chData,
                                            chIdx,
                                            index
                                          )}
                                    </span>
                                  </div>
                                  <div className="out-time">
                                    <span className="name">Out</span>
                                    <span className="value">
                                      {noShowFlag
                                        ? "-"
                                        : this.outTimeFormatter(
                                            chData,
                                            chIdx,
                                            index
                                          )}
                                    </span>
                                  </div>
                                  <div className="break-time">
                                    <span className="name">Break</span>
                                    <span className="value">
                                      {chData.breakTime}
                                    </span>
                                  </div>
                                  <div className="position-name">
                                    <span className="name">Position</span>
                                    <span className="value">
                                      {chData.positionName}
                                    </span>
                                  </div>
                                </div>

                                {chIdx === parentData.length - 1 && (
                                  <div className="total-section">
                                    <div className="totals-heading">Totals</div>
                                    <div className="total-break d-flex align-items-center">
                                      <span className="name">Break</span>
                                      <span className="value">
                                        {this.getBreakRow(parentData, index) ===
                                        null
                                          ? "0:00"
                                          : this.breakTimeFormatter(
                                              this.getBreakRow(
                                                parentData,
                                                index
                                              ),
                                              chIdx,
                                              index
                                            )}
                                      </span>
                                    </div>

                                    <div className="total-shift">
                                      <span className="name">Shift</span>
                                      <span className="value">
                                        {schConversions.convertMinuteToHour(
                                          totalActual
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    );
                  }
                })}
                <div className="table-footer d-flex">
                  <div className="shift-col mr-auto">Totals</div>
                  <div className="total-col">
                    {this.getDayWiseActual(index)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  getBreakRow = (parentData: any, index: number) => {
    let rowScheduleNo = parentData[0].scheduleNo;
    const timesheetData = this.state.dayDetails[index].timesheet;
    let breakRow: any = null;
    for (let i = 0; i < timesheetData.length; i++) {
      if (timesheetData[i].scheduleNo === rowScheduleNo) {
        if (
          timesheetData[i].noShow !== 1 &&
          timesheetData[i].inDate !== "" &&
          timesheetData[i].outDate !== "" &&
          timesheetData[i].opens !== "Open"
        ) {
          if (timesheetData[i].entryType === "Break") {
            breakRow = timesheetData[i];
          }
        }
      }
    }
    return breakRow;
  };

  getDayWiseActual = (index: number) => {
    const timesheetData = this.state.dayDetails[index].timesheet;
    let totalActual: number = 0;
    for (let i = 0; i < timesheetData.length; i++) {
      if (
        timesheetData[i].noShow !== 1 &&
        timesheetData[i].inDate !== "" &&
        timesheetData[i].outDate !== "" &&
        timesheetData[i].opens !== "Open"
      ) {
        if (timesheetData[i].entryType === "Break") {
          let breakTimeInMinutes = schConversions.convertHourToMInute(
            timesheetData[i].breakTime
          );
          totalActual = totalActual - breakTimeInMinutes;
        } else {
          let actualInTime = moment(
            `${timesheetData[i].inDate} ${timesheetData[i].inTime}`
          );
          let actualOutTime = moment(
            `${timesheetData[i].outDate} ${timesheetData[i].outTime}`
          );
          let diffInActual = actualOutTime.diff(actualInTime, "minutes");
          totalActual = totalActual + diffInActual;
        }
      }
    }
    return schConversions.convertMinuteToHour(totalActual);
  };

  getTotalActual = (index: number, parentData: any) => {
    let rowScheduleNo = parentData[0].scheduleNo;
    let totalActual = 0;
    let totalSchedule = 0;

    let timesheetData = this.state.dayDetails[index].timesheet;

    for (let i = 0; i < timesheetData.length; i++) {
      if (timesheetData[i].scheduleNo === rowScheduleNo) {
        if (
          timesheetData[i].entryType === "Break" &&
          timesheetData[i].noShow !== 1 &&
          timesheetData[i].inDate !== "" &&
          timesheetData[i].outDate !== "" &&
          timesheetData[i].opens !== "Open"
        ) {
          let breakTimeInMinutes = schConversions.convertHourToMInute(
            timesheetData[i].breakTime
          );
          totalActual = totalActual - breakTimeInMinutes;
        } else {
          if (
            timesheetData[i].noShow !== 1 &&
            timesheetData[i].inDate !== "" &&
            timesheetData[i].outDate !== "" &&
            timesheetData[i].opens !== "Open"
          ) {
            let actualInTime = moment(
              `${timesheetData[i].inDate} ${timesheetData[i].inTime}`
            );
            let actualOutTime = moment(
              `${timesheetData[i].outDate} ${timesheetData[i].outTime}`
            );
            let diffInActual = actualOutTime.diff(actualInTime, "minutes");
            totalActual = totalActual + diffInActual;
          }

          let scheduleInTime = moment(
            `${timesheetData[i].scheduleInDate} ${timesheetData[i].scheduleInTime}`
          );
          let scheduleOutTime = moment(
            `${timesheetData[i].scheduleOutDate} ${timesheetData[i].scheduleOutTime}`
          );

          let diffInSchedue = scheduleOutTime.diff(scheduleInTime, "minutes");

          totalSchedule = totalSchedule + diffInSchedue;
        }
      }
    }

    return { totalActual, totalSchedule };
  };

  mobileRowExpension = (parentId: number, childId: number, rowData: any) => {
    let newDayDetails: any = [...this.state.dayDetails];
    for (let i = 0; i < newDayDetails.length; i++) {
      let timesheetData = newDayDetails[i].timesheet;
      for (let j = 0; j < timesheetData.length; j++) {
        let elem = timesheetData[j];
        if (
          i === parentId &&
          timesheetData[j].scheduleNo === rowData[0].scheduleNo
        ) {
          elem.mobileOpen = !elem.mobileOpen;
        } else {
          elem.mobileOpen = false;
        }
      }
      newDayDetails[i].timesheet = timesheetData;
    }
    this.setState({ dayDetails: newDayDetails }, () => {
      let data = _.cloneDeep(this.state.dayDetails);
      let timesheetRef = data[parentId].ref?.current;

      scrollIntoView(timesheetRef, {
        align: {
          top: 0,
          left: 0,
          topOffset: 150,
          leftOffset: 0,
        },
        behavior: "smooth",
      });
    });
  };

  // Mobile Design Function Ends

  render() {
    const columns = [
      {
        dataField: "title",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              <div className="main-ttl">
                {row.title === "PtoPayOut" ? "PTO Payout" : row.title}
              </div>
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "total",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.total !== "00:00" && (
                <div className="main-ttl">{row.total}</div>
              )}
              {row.total === "00:00" && <div className="main-ttl">{"-"}</div>}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div className="vertical-line-td"></div>;
        },
      },
      {
        dataField: "",
        text: "days1",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days1.value !== "0:00" && (
                <div className="main-ttl">{row.days1.value}</div>
              )}
              {row.days1.value === "0:00" && (
                <div className="main-ttl">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days2",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days2.value !== "0:00" && (
                <div className="main-ttl">{row.days2.value}</div>
              )}
              {row.days2.value === "0:00" && (
                <div className="main-ttl">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days3",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days3.value !== "0:00" && (
                <div className="main-ttl">{row.days3.value}</div>
              )}
              {row.days3.value === "0:00" && (
                <div className="main-ttl">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days4",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days4.value !== "0:00" && (
                <div className="main-ttl">{row.days4.value}</div>
              )}
              {row.days4.value === "0:00" && (
                <div className="main-ttl">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days5",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days5.value !== "0:00" && (
                <div className="main-ttl">{row.days5.value}</div>
              )}
              {row.days5.value === "0:00" && (
                <div className="main-ttl">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days6",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days6.value !== "0:00" && (
                <div className="main-ttl">{row.days6.value}</div>
              )}
              {row.days6.value === "0:00" && (
                <div className="main-ttl">{"-"}</div>
              )}
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "days7",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.days7.value !== "0:00" && (
                <div className="main-ttl">{row.days7.value}</div>
              )}
              {row.days7.value === "0:00" && (
                <div className="main-ttl">{"-"}</div>
              )}
            </div>
          );
        },
      },
    ];

    return (
      <div className="user-timesheet">
        {/* Desktop View Starts */}
        <div className="body-sec performance-position timesheet-details desktop-view-user-timesheet justify-content-end rm-padding container-fluid rm-width sos">
          <Container fluid className="body-sec pt-0">
            <div className="page-heading underline d-flex pt-28-px bg-white sticky-top">
              <a ref={this.linkRef}></a>
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
              />
              <div className="mr-auto">Timesheet</div>
              {this.state.hotelID > 0 && this.state.dayDetails.length > 0 && (
                <div className="action-group d-flex flex-row">
                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      {this.state.exportLoader && (
                        <Spinner
                          className="mr-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {this.state.flyingMenuList.map((data: any, id: any) => (
                        <Dropdown.Item
                          key={id}
                          onClick={() => this.flyOutMenuClick(data)}
                        >
                          {data}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
            {this.state.loaderTrue ? (
              <ReactPageLoader useas={"timesheetUserView"} />
            ) : (
              <>
                {this.state.hotelID > 0 && (
                  <div className="add-padding read-only user-timesheet-main">
                    {this.state.exportedDate.length > 0 &&
                      this.state.exportedDate !== "0" && (
                        <div className="notification-banners green-bg">
                          This timesheet was exported to payroll on{" "}
                          {this.state.exportedDate}.
                        </div>
                      )}

                    <div className="overFlow-hide">
                      <div className="d-flex details-sec overFlow-scroll">
                        <div className="flex-fill align-content-center box">
                          <div className="d-flex">
                            <div className="title mr-auto">
                              <div className="d-flex align-content-center">
                                <div>{this.state.payPeriod}</div>
                                <div className="badge">
                                  {this.state.timesheetStatus}
                                </div>
                              </div>
                            </div>

                            <div className="action-link next-prev">
                              <button
                                type="button"
                                className="btn-outline-primary btn btn-primary"
                                onClick={() => this.prevPayperoid()}
                              >
                                <svg
                                  width="7"
                                  height="11"
                                  viewBox="0 0 7 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M5.295 0L0 5.29575L5.295 10.5915L6.8865 9L3.1815 5.29575L6.8865 1.5915L5.295 0Z" />
                                </svg>
                              </button>
                              {this?.state?.calenderDates?.length > 0 && (
                                <button
                                  type="button"
                                  disabled={
                                    new Date(this.state.calenderDates[1]) >=
                                    new Date(
                                      JSON.parse(localStorage.storage)
                                        .currentUtcTime !== ""
                                        ? new Date(
                                            JSON.parse(
                                              localStorage.storage
                                            ).currentUtcTime
                                          )
                                        : new Date().toDateString()
                                    )
                                  }
                                  className="btn-outline-primary btn btn-primary"
                                  onClick={() => this.nextPayperoid()}
                                >
                                  <svg
                                    width="7"
                                    height="11"
                                    viewBox="0 0 7 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M1.705 0L7 5.29575L1.705 10.5915L0.113499 9L3.8185 5.29575L0.113499 1.5915L1.705 0Z" />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                          {this.state.dayDetails.length > 0 && (
                            <div className="list">
                              <ul>
                                <li
                                  className={
                                    this.state.activeClass === -1
                                      ? "active"
                                      : ""
                                  }
                                  key={-1}
                                  onClick={this.handleComparisonClick.bind(
                                    this,
                                    -1,
                                    "All"
                                  )}
                                >
                                  <div className="ttl">
                                    {this.state.total.totlhrs}
                                  </div>
                                  <div className="desc">Total Hours</div>
                                </li>
                                {this.state.positionList.length > 0 && (
                                  <li>
                                    <div className="separator"></div>
                                  </li>
                                )}
                                {this.state.positionList.map(
                                  (item: any, index: any) => (
                                    <li
                                      key={index}
                                      className={
                                        this.state.activeClass === index
                                          ? "active"
                                          : ""
                                      }
                                      onClick={this.handleComparisonClick.bind(
                                        this,
                                        index,
                                        item.position
                                      )}
                                    >
                                      <div className="ttl">{item.value}</div>
                                      <div className="desc">
                                        {item.position}
                                      </div>
                                    </li>
                                  )
                                )}
                                {this.state.positionList.length > 0 && (
                                  <li>
                                    <div className="separator"></div>
                                  </li>
                                )}
                                {this.state.dispute !== 0 && (
                                  <li
                                    className={
                                      this.state.activeClass === 1002
                                        ? "active dispute"
                                        : "dispute"
                                    }
                                    key={-1}
                                    onClick={this.handleComparisonClick.bind(
                                      this,
                                      1002,
                                      "Dispute"
                                    )}
                                  >
                                    <div className="ttl">
                                      <div className="pos-rel">
                                        {this.state.dispute}
                                        {this.state.isDiputeOpen.toLowerCase() ===
                                          "yes" && (
                                          <div className="dot-abs"></div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="desc">Dispute</div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {this.state.condition === "All" &&
                      this.state.dayDetails.length > 0 && (
                        <div className="scroll-area">
                          <div className="detail-view without-bdr">
                            <BootstrapTable
                              id={"timesheetdtlList"}
                              keyField="title"
                              data={this.state.data}
                              columns={columns}
                              hover
                            />
                          </div>
                        </div>
                      )}
                    {this.state.dayDetails.length > 0 &&
                      this.state.condition === "All" && (
                        <div className="week-list">
                          <div className="">
                            <div className="main-heading">Details by Day</div>
                            <div className="detail-view">
                              {this.state.dayDetails.map(
                                (item: any, index: any) => (
                                  <div className="day-list" key={index}>
                                    <div className="day-stamp">
                                      {
                                        this.state.dayDetails[index]
                                          .timesheet[0].dayName
                                      }{" "}
                                      {this.state.dayDetails[
                                        index
                                      ].timesheet[0].approveStatus
                                        .toString()
                                        .toLowerCase() === "yes" && (
                                        <img
                                          src={ForecastLock}
                                          alt="ForecastLock"
                                        />
                                      )}
                                    </div>
                                    <div
                                      ref={this.state.dayDetails[index].ref}
                                      className={
                                        this.state.dayDetails[index].timesheet
                                          .length === 1
                                          ? this.state.dayDetails[index]
                                              .timesheet[0].isActualentry ===
                                            "No"
                                            ? "hideRow"
                                            : ""
                                          : ""
                                      }
                                    >
                                      {this.getParentTable(index)}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    {this.state.condition === "Dispute" && (
                      <>
                        <div className="week-list dispute">
                          <LaborTimesheetDisputeUserView
                            hotelID={this.state.hotelID}
                            userName={this.state.userName}
                            date={this.state.date}
                            handleComparisonClick={this.handleComparisonClick}
                            alldepartmentlist={this.state.alldepartmentlist}
                          />
                        </div>
                        <div className="week-list dispute">
                          <LaborTimesheetResolvedDisputeUserView
                            hotelID={this.state.hotelID}
                            userName={this.state.userName}
                            date={this.state.date}
                            handleComparisonClick={this.handleComparisonClick}
                            alldepartmentlist={this.state.alldepartmentlist}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </Container>
        </div>
        {/* Desktop View Ends */}

        {/* Mobile View Starts */}
        <div className="mobile-view-my-timesheet ">
          <div className="body-sec container-fluid">
            <div className="mobile-page-heading d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
              />
              <div className="mr-auto">Timesheet</div>
              {this.state.hotelID > 0 && this.state.dayDetails.length > 0 && (
                <div className="action-group d-flex flex-row">
                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      {this.state.exportLoader && (
                        <Spinner
                          className="mr-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {this.state.flyingMenuList.map((data: any, id: any) => (
                        <Dropdown.Item
                          key={id}
                          onClick={() => this.flyOutMenuClick(data)}
                        >
                          {data}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>

            {this.state.mobileLoader ? (
              <ReactPageLoader useas={"mobileMyTimesheet"} />
            ) : (
              this.state.hotelID > 0 && (
                <>
                  <div className="add-padding read-only mobile-user-timesheet-main">
                    {this.state.exportedDate.length > 0 &&
                      this.state.exportedDate !== "0" && (
                        <div className="notification-banners green-bg">
                          {` This timesheet was exported to payroll on ${this.state.exportedDate}.`}
                        </div>
                      )}
                  </div>

                  <div className="date-range-slider d-flex align-items-center">
                    <div className="date-stamp mr-auto">
                      <div className="d-flex align-content-center">
                        <div>{this.state.payPeriod}</div>
                        <div className="badge">
                          {this.state.timesheetStatus}
                        </div>
                      </div>
                    </div>

                    <div className="action-link next-prev">
                      <button
                        type="button"
                        className="btn-outline-primary btn btn-primary"
                        onClick={() => this.prevPayperoid()}
                      >
                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5.295 0L0 5.29575L5.295 10.5915L6.8865 9L3.1815 5.29575L6.8865 1.5915L5.295 0Z" />
                        </svg>
                      </button>
                      {this?.state?.calenderDates?.length > 0 && (
                        <button
                          type="button"
                          disabled={
                            new Date(this.state.calenderDates[1]) >=
                            new Date(
                              JSON.parse(localStorage.storage)
                                .currentUtcTime !== ""
                                ? new Date(
                                    JSON.parse(
                                      localStorage.storage
                                    ).currentUtcTime
                                  )
                                : new Date().toDateString()
                            )
                          }
                          className="btn-outline-primary btn btn-primary"
                          onClick={() => this.nextPayperoid()}
                        >
                          <svg
                            width="7"
                            height="11"
                            viewBox="0 0 7 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1.705 0L7 5.29575L1.705 10.5915L0.113499 9L3.8185 5.29575L0.113499 1.5915L1.705 0Z" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="tabular-slide">
                    <div className="d-flex details-sec overFlow-scroll">
                      <div className="flex-fill align-content-center box">
                        {this.state.dayDetails.length > 0 && (
                          <div className="list">
                            <ul>
                              <li
                                className={
                                  this.state.activeClass === -1 ? "active" : ""
                                }
                                key={-1}
                                onClick={this.handleComparisonClick.bind(
                                  this,
                                  -1,
                                  "All"
                                )}
                              >
                                <div className="ttl">
                                  {this.state.total.totlhrs}
                                </div>
                                <div className="desc">Total Hours</div>
                              </li>
                              {this.state.positionList.length > 0 && (
                                <li>
                                  <div className="separator"></div>
                                </li>
                              )}
                              {this.state.positionList.map(
                                (item: any, index: any) => (
                                  <li
                                    key={index}
                                    className={
                                      this.state.activeClass === index
                                        ? "active"
                                        : ""
                                    }
                                    onClick={this.handleComparisonClick.bind(
                                      this,
                                      index,
                                      item.position
                                    )}
                                  >
                                    <div className="ttl">{item.value}</div>
                                    <div className="desc">{item.position}</div>
                                  </li>
                                )
                              )}
                              {this.state.dispute !== 0 && (
                                <>
                                  <li>
                                    <div className="separator"></div>
                                  </li>
                                  <li
                                    className={
                                      this.state.activeClass === 1002
                                        ? "active dispute"
                                        : "dispute"
                                    }
                                    key={-1}
                                    onClick={this.handleComparisonClick.bind(
                                      this,
                                      1002,
                                      "Dispute"
                                    )}
                                  >
                                    <div className="ttl">
                                      <div className="pos-rel">
                                        {this.state.dispute}
                                        {this.state.isDiputeOpen.toLowerCase() ===
                                          "yes" && (
                                          <div className="dot-abs"></div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="desc">Dispute</div>
                                  </li>
                                </>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.state.condition === "All" &&
                    this.state.dayDetails.length > 0 && (
                      <div className="scroll-area-mobile">
                        <div className="detail-view without-bdr">
                          <div className="gradient-column-separator"></div>
                          <div className="gradient-right-blend"></div>
                          <BootstrapTable
                            id={"timesheetdtlList"}
                            keyField="title"
                            data={this.state.data}
                            columns={columns}
                            hover
                          />
                        </div>
                      </div>
                    )}
                  {this.state.dayDetails.length > 0 &&
                    this.state.condition === "All" && (
                      <div className="week-list">
                        <div className="">
                          <div className="main-heading">Details by Day</div>

                          {this.getMobileViewDayTimesheet()}
                        </div>
                      </div>
                    )}
                  {this.state.condition === "Dispute" && (
                    <>
                      <div className="week-list dispute">
                        <LaborTimesheetDisputeUserView
                          hotelID={this.state.hotelID}
                          userName={this.state.userName}
                          date={this.state.date}
                          handleComparisonClick={this.handleComparisonClick}
                          alldepartmentlist={this.state.alldepartmentlist}
                        />
                      </div>
                      <div className="week-list dispute">
                        <LaborTimesheetResolvedDisputeUserView
                          hotelID={this.state.hotelID}
                          userName={this.state.userName}
                          date={this.state.date}
                          handleComparisonClick={this.handleComparisonClick}
                          alldepartmentlist={this.state.alldepartmentlist}
                        />
                      </div>
                    </>
                  )}
                </>
              )
            )}
          </div>
        </div>
        {/* Mobile View Ends */}
      </div>
    );
  }
}
