import moment from "moment";
import { toast } from "react-toastify";
export class Utils {
  public static BuildCustomEventObject = (name, value) => {
    const valueObj: any = {
      target: {
        name,
        value,
      },
    };

    return valueObj;
  };

  public static CapitalizeFirstLetter(string) {
    if (string) {
      return string?.charAt(0).toUpperCase() + string?.slice(1);
    } else {
      return string;
    }
  }

  public static ConvertToTitleCase(str) {
    if (str) {
      let splitStr = str.toLowerCase().split(' ');
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toLowerCase();
      }
      return splitStr.join(' ');
    } else {
      return str;
    }
  }

  public static GetHHMMValidString = (
    value,
    isAllowNegative = false,
    maxCcolonIndex = 2
  ) => {
    let isNegativeValue = "";
    if (isAllowNegative) {
      isNegativeValue = value?.toString()?.includes("-") ? "-" : "";
      value = value?.toString()?.includes("-")
        ? value?.toString()?.replace("-", "")
        : value;
    }
    const regexNumberAndColon = /^[0-9]*\:?[0-9]*$/;
    if (!value?.toString()?.match(regexNumberAndColon)) {
      return isNegativeValue + "";
    }
    const colonIndex = value?.toString()?.indexOf(":");
    // if (value?.toString()?.includes(":") && colonIndex < 2) {
    //   value = value.split(":")[0];
    // }
    if (value?.toString()?.includes(":") && colonIndex > maxCcolonIndex) {
      value = value.replace(":", "");
    }
    if (value?.length > maxCcolonIndex && !value?.toString()?.includes(":")) {
      value = value.substr(0, maxCcolonIndex);
    }

    return isNegativeValue + value;
  };

  //  Date param Should be in  'MM/DD/YYYY' format
  public static CheckIfDateIsValid = (date: string, yearDigit: number = 2) => {
    if (date.length >= 5) {
      var splittedDate = date?.split("/");
      if (splittedDate[2]?.length === yearDigit) {
        date =
          splittedDate[0]?.padStart(2, "0") +
          "/" +
          splittedDate[1]?.padStart(2, "0") +
          "/" +
          splittedDate[2]?.padStart(yearDigit, "0");
      } else {
        if (date?.substring(5, 6) === "/") {
          return true;
        } else {
          date = date + (yearDigit == 2 ? "/00" : "/0000");
        }
      }
      return moment(
        date,
        yearDigit == 2 ? "MM/DD/YY" : "MM/DD/YYYY",
        true
      ).isValid();
    } else {
      return true;
    }
  };

  public static CheckIfDateIsValidMMDD = (date: string) => {
    if (date.toString()?.length > 3) {
      date = date;

      if (+date?.toString()?.split("/")[1] === 0) {
        return true;
      } else {
        return moment(moment(date).format("MM/DD"), "MM/DD", true).isValid();
      }
    } else {
      return true;
    }
  };

  public static OnlyNumbersWithColon(e, allowNegative = false) {
    var charCode;
    if (e.keyCode > 0) {
      charCode = e.which || e.keyCode;
    } else if (typeof e.charCode != "undefined") {
      charCode = e.which || e.keyCode;
    }
    if (charCode == 58) {
      return true;
    }

    if (charCode == 45 && allowNegative) {
      return true;
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  public static AllowOnlyAlphabetsWithRightSpace = (value: string) => {
    return value?.toString()?.replace(/[^A-Za-z ]+$/gi, "");
  };

  public static AllowOnlyAlphaNumricWithRightSpace = (value: string) => {
    return value?.toString()?.replace(/[^a-zA-Z0-9 ]+$/gi, "");
  };

  public static getLocalDateTimeFromUtc(utcdate: any) {
    const nth = function (d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const fortnightAway = new Date(utcdate + " UTC");
    const date = fortnightAway.getDate();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][fortnightAway.getMonth()];
    const time = this.formatAMPM(fortnightAway);
    return (
      month +
      " " +
      date +
      "" +
      nth(date) +
      ", " +
      fortnightAway.getFullYear() +
      " at " +
      time
    );
  }

  public static getLocalDateTimeWithoutUtc(utcdate: any) {
    const nth = function (d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const fortnightAway = new Date(utcdate);
    const date = fortnightAway.getDate();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][fortnightAway.getMonth()];
    const time = this.formatAMPM(fortnightAway);
    return (
      month +
      " " +
      date +
      "" +
      nth(date) +
      ", " +
      fortnightAway.getFullYear() +
      " at " +
      time
    );
  }

  public static getDateTimeMddyyFromUtc(utcdate: any) {
    const fortnightAway = new Date(utcdate + " UTC");
    const date = fortnightAway.getDate();
    const time = this.formatAMPM(fortnightAway);
    return (
      fortnightAway.getMonth() +
      1 +
      "/" +
      date +
      "/" +
      fortnightAway.getFullYear().toString().substr(2, 4) +
      " at " +
      time
    );
  }

  public static convertNumberToTwoPlacesDecimal = (value: string) => {
    return parseFloat(value).toFixed(2);
  };

  public static getLocalDateUtc(utcdate: any) {
    const nth = function (d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const fortnightAway = new Date(utcdate + " UTC");
    const date = fortnightAway.getDate();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][fortnightAway.getMonth()];
    return (
      month + " " + date + "" + nth(date) + ", " + fortnightAway.getFullYear()
    );
  }

  public static getLocalDateFromUtc(utcdate: any) {
    const nth = function (d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const fortnightAway = new Date(utcdate + " UTC");
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    var day = weekday[fortnightAway.getDay()];
    const date = fortnightAway.getDate();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][fortnightAway.getMonth()];
    return day + ", " + month + " " + date + "" + nth(date);
  }

  public static formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  getTimeWithAMPM(time: string) {
    const hour = +time?.split(":")[0];
    const amOrPm = +time?.split(":")[0] >= 12 ? "PM" : "AM";
    if (hour === 0) {
      time = time.replace("00:", "12:");
    }
    if (hour > 12) {
      const value = (hour - 12).toString().padStart(2, "0");
      time = `${value}:${time?.split(":")[1]}`;
    }
    return `${time} ${amOrPm} `;
  }

  public static convertToInternationalCurrencySystem = (labelValue): string => {
    // Nine Zeroes for Billions
    return (
      Math.abs(Number(labelValue)) >= 1.0e9
        ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "b"
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
          ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "m"
          : // Three Zeroes for Thousands
          Math.abs(Number(labelValue)) >= 1.0e3
            ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "k"
            : Math.abs(Number(labelValue))
    ) as string;
  };

  public static getMinutesFromHHMM(hours: string): number {
    const hoursArray = hours?.toString()?.split(":");
    return +hoursArray[0] * 60 + (+hoursArray[1] || 0);
  }

  public static getMinutesFromHHMMDOT(hours: string): number {
    const hoursArray = hours?.toString()?.split(".");
    return +hoursArray[0] * 60 + (+hoursArray[1] || 0);
  }
  public static getSecondsFromHHMMSS(hours: string): number {
    const hoursArray = hours?.toString()?.split(":");
    return +hoursArray[0] * 60 + +hoursArray[1] * 60 + +hoursArray[2];
  }

  public static getDecimalHHMMToMinutes = (decimalHHMM) => {
    if (!decimalHHMM) {
      return 0;
    }
    const adjBreakArray = decimalHHMM?.toString()?.split(".");
    if (adjBreakArray.length > 1) {
      let total =
        +adjBreakArray[0].replace("-", "") * 60 +
        +adjBreakArray[1].toString().padEnd(2, "0");
      return decimalHHMM?.toString().includes("-")
        ? Number(`${-total}`)
        : total;
    } else {
      return +decimalHHMM * 60;
    }
  };
  public static getMinutesFromHHMMWithPositiveNegative(hours: string): number {
    const hoursArray = hours?.toString()?.split(":");
    const minutes = +(hoursArray[0] || 0) * 60 + +(hoursArray[1] || 0);
    return minutes;
  }

  public static getMinutesInHHMMFormat = (minutes): string => {
    if (minutes) {
      const totalHours = Math.floor(
        +minutes?.toString()?.replace("-", "") / 60
      );
      const remainingMinutes = minutes % 60;

      return `${totalHours?.toString()?.length === 1
          ? totalHours?.toString()?.padStart(2, "0")
          : totalHours.toLocaleString("en-US", { maximumFractionDigits: 2 })
        }:${remainingMinutes?.toString()?.replace("-", "")?.length === 1
          ? remainingMinutes
            ?.toString()
            ?.replace("-", "")
            ?.padStart(2, "0")
            ?.toString()
          : remainingMinutes?.toString()?.replace("-", "")
        }`;
    } else {
      return "00:00";
    }
  };

  public static getMinutesInHHMMFormatDot = (minutes): number => {
    if (minutes) {
      const totalHours = Math.floor(
        +minutes?.toString()?.replace("-", "") / 60
      );
      const remainingMinutes = minutes % 60;
      return +`${totalHours?.toString()?.length === 1
          ? totalHours?.toString()?.padStart(2, "0")
          : totalHours.toLocaleString("en-US", { maximumFractionDigits: 2 })
        }:${remainingMinutes?.toString()?.replace("-", "")?.length === 1
          ? remainingMinutes
            ?.toString()
            ?.replace("-", "")
            ?.padStart(2, "0")
            ?.toString()
          : remainingMinutes?.toString()?.replace("-", "")
        }`?.replace(":", ".");
    } else {
      return 0;
    }
  };

  public static getMinutesBetweenInOutDatetime = (
    inDateTime: any,
    outDateTime: any
  ) => {
    var InDate: any = new Date(inDateTime);
    var OutDate: any = new Date(outDateTime);
    var diffMs = OutDate - InDate; // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    //alert(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes until Christmas 2009 =)");
    return diffDays * 24 * 60 + diffHrs * 60 + diffMins;
  };

  public static ShowNumberUptoLastTwoDecimal = (number) => {
    return isFinite(Math.round(number * 100) / 100)
      ? (Math.round(number * 100) / 100).toFixed(2)
      : 0;
  };

  public static AddSlashINInputDateFormat = (
    value,
    comparatorValue,
    shouldNotGreaterThanToday = false,
    noOfYearDigit = 2,
    isBlur = false
  ) => {
    if (value) {
      if (value !== "Invalid date") {
        if (
          value?.toString()?.length > comparatorValue?.toString()?.length &&
          !Utils.CheckIfDateIsValid(value, noOfYearDigit)
        ) {
          return "invalid";
        }
      }

      if (
        value?.toString()?.substr(0, 1) === "/" ||
        value?.toString()?.split("/")?.length > 3 ||
        value?.toString()?.split("/")[0]?.toString() === "00" ||
        value?.toString()?.split("/")[1]?.toString() === "00"
      ) {
        return "invalid";
      }

      if (
        (value?.length === 2 ||
          value?.length === 5 ||
          (value?.length === 3 && !value?.includes("/")) ||
          (value?.length === 6 && value?.substr(5, 6) !== "/")) &&
        value?.toString()?.length > comparatorValue?.toString()?.length
      ) {
        if (value?.length === 3) {
          value = value.substr(0, 2) + "/" + value.substr(2, 3);
        } else if (
          value?.length === 6 &&
          value.split("").filter((x) => x === "/").length < 2
        ) {
          value = value.substr(0, 5) + "/" + value.substr(5, 6);
        } else {
          if (
            value.slice(-1) !== "/" &&
            value.split("").filter((x) => x === "/").length < 2
          ) {
            value += `/`;
          }
        }
      } else {
        if (
          value?.length >= 3 &&
          value?.substr(2, 1) !== "/" &&
          value?.substr(1, 1) !== "/" &&
          value.split("").filter((x) => x === "/").length < 2
        ) {
          value = value.substr(0, 2) + "/" + value.substr(2, value?.length);
        }
        if (
          value?.length >= 6 &&
          value?.substr(5, 1) !== "/" &&
          value?.substr(4, 1) !== "/" &&
          value.split("").filter((x) => x === "/").length < 2
        ) {
          value = value.substr(0, 5) + "/" + value.substr(5, value?.length);
        }
      }

      let splittedString = value?.split("");

      // if(splittedString.filter(x=> x==="/").length>2) {

      var modifiedDate = splittedString
        ?.map((item, i) => {
          //var indexs: any[] = [1,2,3,4,5];
          if (
            i > 0 &&
            splittedString[i - 1] === splittedString[i] &&
            item === "/"
          ) {
            return "";
          } else {
            return item;
          }
        })
        ?.filter((x) => x);

      value = modifiedDate.join("");
      // }
      var splittedDate = value?.split("/");

      if (isBlur) {
        let day = "";
        let month = "";
        let year = "";
        if (splittedDate[0]) {
          month = splittedDate[0]?.padStart(2, "0");
          value = month;
        }
        if (splittedDate[1]) {
          day = splittedDate[1]?.padStart(2, "0");
          value += "/" + day;
        }

        if (splittedDate[2]) {
          if (noOfYearDigit === 2) {
            year = splittedDate[2]?.padStart(noOfYearDigit, "0");
          } else {
            year = splittedDate[2];
          }
          value += "/" + year;
        }
      }

      // }

      if (splittedDate[0]) {
        if (+splittedDate[0] > 12) {
          return "invalid";
        }
      }
      if (+splittedDate[1]) {
        if (+splittedDate[1] > 31) {
          return "invalid";
        }
      }

      if (shouldNotGreaterThanToday) {
        if (moment(value).isAfter(moment().format("MM/DD/YY"))) {
          return "invalid";
        }
      }
    }

    return value || "";
  };

  // public static AddHypenSSNFormat =(value, comparatorValue) => {
  //   if (value) {

  //     if ((value?.length ===3 || value?.length ===6 || ((value?.length ===3 &&
  //        !value?.includes("/")) ||(value?.length ===7 && value?.substr(6,7) !=="/")))
  //      && value?.toString()?.length> comparatorValue?.toString()?.length ) {
  //      if(value?.length ===3 ) {
  //       value = value.substr(0,3) + '/' + value.substr(2,3);
  //      }
  //      else  if(value?.length ===6  && value.split('').filter(x=> x==="/").length <2) {
  //       value = value.substr(0,5) + '/' + value.substr(5,6);
  //      }
  //       else {
  //         if(value.slice(-1)!=="/" && value.split('').filter(x=> x==="/").length <2) {
  //           value += `/`;
  //         }

  //     }
  //     } else {

  //       if(value?.length >=3 && (value?.substr(2,1) !=="/" && value?.substr(1,1) !=="/" ) && value.split('').filter(x=> x==="/").length <2) {
  //         value = value.substr(0,2) + '/' + value.substr(2,value?.length);
  //       }
  //       if(value?.length >=6 && (value?.substr(5,1) !=="/" && value?.substr(4,1) !=="/") && value.split('').filter(x=> x==="/").length <2 ) {
  //         value = value.substr(0,5) + '/' + value.substr(5,value?.length);
  //       }

  //     }

  //     let splittedString = value?.split('');

  //     // if(splittedString.filter(x=> x==="/").length>2) {

  //    var modifiedDate = splittedString?.map((item,i)=> {
  //      //var indexs: any[] = [1,2,3,4,5];
  //      if(i>0 && (splittedString[i-1] === splittedString[i] && item ==="/"))   {
  //         return "";
  //      }
  //      else {
  //       return item;
  //      }
  //    })?.filter(x=> x);

  //   value =modifiedDate.join('');
  //     // }
  //   var splittedDate = value?.split("/");

  //   if(isBlur) {
  //     let day="";
  //     let month="";
  //     let year="";
  //         if(splittedDate[0]) {
  //           month = splittedDate[0]?.padStart(2,"0");
  //           value =month;
  //         }
  //         if(splittedDate[1]) {
  //           day = splittedDate[1]?.padStart(2,"0");
  //           value +="/"+day
  //         }

  //         if(splittedDate[2]) {
  //           if(noOfYearDigit===2) {
  //           year = splittedDate[2]?.padStart(noOfYearDigit,"0");
  //         }else{
  //           year = splittedDate[2];
  //         }
  //         value +="/"+year
  //       }
  //   }

  //   // }

  //   if(splittedDate[0]) {
  //     if(+splittedDate[0]> 12) {
  //       return "invalid";
  //     }

  //   }
  //     if(+splittedDate[1]) {
  //       if(+splittedDate[1]> 31) {
  //         return "invalid";
  //       }
  //     }

  //     if(shouldNotGreaterThanToday) {
  //       if(moment(value).isAfter(moment().format("MM/DD/YY"))) {
  //         return "invalid";
  //       }
  //     }
  //   }

  //   return value || "";
  // }

  public static AddHypenSSNFormat = (value, comparatorValue) => {
    let inValidString = false;
    if (value) {
      if (value?.toString()?.length > comparatorValue?.toString()?.length) {
        const splittedString = value.split("");
        let finalValue = "";
        const dashIndexes = [3, 6];
        splittedString.forEach((char, i) => {
          if (!dashIndexes.includes(i) && char === "-") {
            inValidString = true;
          }

          if (dashIndexes.includes(i) && char !== "-") {
            finalValue += "-";
            finalValue += char;
          } else {
            finalValue += char;
          }
        });
        value = finalValue;

        const splittedString2 = value.split("");

        if (splittedString2.length === 3 || splittedString2.length === 6) {
          value += "-";
        }
      }
    }

    if (inValidString) {
      return "invalid";
    } else {
      return value || "";
    }
  };

  public static addHypenInInputDateFormatMMYY = (value, comparatorValue) => {
    let inValidString = false;
    if (value) {
      if (value?.toString()?.length > comparatorValue?.toString()?.length) {
        const splittedString = value.split("");
        let finalValue = "";
        const dashIndexes = [2];
        splittedString.forEach((char, i) => {
          if (!dashIndexes.includes(i) && char === "-") {
            inValidString = true;
          }

          if (dashIndexes.includes(i) && char !== "-") {
            finalValue += "-";
            finalValue += char;
          } else {
            finalValue += char;
          }
        });
        value = finalValue;

        const splittedString2 = value.split("");

        if (splittedString2.length === 2) {
          value += "-";
        }
      }
    }
    if (inValidString) {
      return "invalid";
    } else {
      return value || "";
    }
  };

  public static AddSlashINInputDateFormatMMDD = (
    value,
    comparatorValue,
    shouldNotGreaterThanToday = false,
    isBlur = false
  ) => {
    if (value) {
      if (value !== "Invalid date") {
        if (
          value?.toString()?.length > comparatorValue?.toString()?.length &&
          !Utils.CheckIfDateIsValidMMDD(value)
        ) {
          return "invalid";
        }
      }

      if (
        (value?.length === 2 ||
          (value?.length === 3 && !value?.includes("/"))) &&
        value?.toString()?.length > comparatorValue?.toString()?.length
      ) {
        if (value?.length === 3) {
          value = value.substr(0, 2) + "/" + value.substr(2, 3);
        } else {
          if (
            value.slice(-1) !== "/" &&
            value.split("").filter((x) => x === "/").length < 2
          ) {
            value += `/`;
          }
        }
      } else {
        if (
          value?.length >= 3 &&
          value?.substr(2, 1) !== "/" &&
          value?.substr(1, 1) !== "/" &&
          value.split("").filter((x) => x === "/").length < 2
        ) {
          value = value.substr(0, 2) + "/" + value.substr(2, value?.length);
        }
      }

      let splittedString = value?.split("");

      // if(splittedString.filter(x=> x==="/").length>2) {

      var modifiedDate = splittedString
        ?.map((item, i) => {
          //var indexs: any[] = [1,2,3,4,5];
          if (
            i > 0 &&
            splittedString[i - 1] === splittedString[i] &&
            item === "/"
          ) {
            return "";
          } else {
            return item;
          }
        })
        ?.filter((x) => x);

      value = modifiedDate.join("");
      // }
      var splittedDate = value?.split("/");

      if (isBlur) {
        let day = "";
        let month = "";
        let year = "";
        if (splittedDate[0]) {
          month = splittedDate[0]?.padStart(2, "0");
          value = month;
        }
        if (splittedDate[1]) {
          day = splittedDate[1]?.padStart(2, "0");
          value += "/" + day;
        }
      }

      // }

      if (splittedDate[0]) {
        if (+splittedDate[0] > 12) {
          return "invalid";
        }
      }
      if (+splittedDate[1]) {
        if (+splittedDate[1] > 31) {
          return "invalid";
        }
      }

      // if(shouldNotGreaterThanToday) {
      //   if(moment(value).isAfter(moment().format("MM/DD/YY"))) {
      //     return "invalid";
      //   }
      // }
    }

    return value || "";
  };

  public static AllowOnlyPositiveNumnberInput = (e: any) => {
    //debugger
    if (
      e.keyCode !== 190 &&
      e.keyCode !== 110 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      !e.ctrlKey
    ) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode === 8 ||
          e.keyCode === 46
        ) &&
        e.keyCode !== 9
      ) {
        e.preventDefault();
      }
    }
  };

  public static AllowOnlyPositiveNumnberInputWithComa = (e: any) => {
    if (
      e.keyCode !== 190 &&
      e.keyCode !== 110 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 188 &&
      !e.ctrlKey
    ) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode === 8 ||
          e.keyCode === 46
        ) &&
        e.keyCode !== 9
      ) {
        e.preventDefault();
      }
    }
  };

  public static AllowOnlyPositiveNumnberInputWithDash = (e: any) => {
    if (
      e.keyCode !== 190 &&
      e.keyCode !== 110 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 189 &&
      e.keyCode !== 109 &&
      !e.ctrlKey
    ) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode === 8 ||
          e.keyCode === 46
        ) &&
        e.keyCode !== 9
      ) {
        e.preventDefault();
      }
    }
  };

  public static AllowOnlyPositiveNumnberInputWithoutDot = (e: any) => {
    if (e.keyCode !== 37 && e.keyCode !== 39 && !e.ctrlKey) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode === 8 ||
          e.keyCode === 46
        ) &&
        e.keyCode !== 9
      ) {
        e.preventDefault();
      } else {
        //  e.preventDefault();
        const test = /[0-9]/i.test(e.key);
        if (!test && e.keyCode !== 8 && e.keyCode !== 9 && e.keyCode !== 46) {
          e.preventDefault();
        }
      }
    }
  };

  public static AllowOnlyPositiveNegativeNumnberInputWithoutDot = (e: any) => {
    //debugger;
    if (
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      !e.ctrlKey &&
      e.keyCode !== 189 &&
      e.keyCode != 109
    ) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode === 8 ||
          e.keyCode === 46
        ) &&
        e.keyCode !== 9
      ) {
        e.preventDefault();
      } else {
        //  e.preventDefault();
        const test = /[0-9]/i.test(e.key);
        if (!test && e.keyCode !== 8 && e.keyCode !== 9 && e.keyCode !== 46) {
          e.preventDefault();
        }
      }
    }
  };

  public static amountFormatted(value: any) {
    try {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (error) {
      return value;
    }
  }

  public static getHotalCurrentDate() {
    let currentDate: any = new Date();
    if (
      JSON.parse(localStorage.storage).currentUtcTime !== "" &&
      JSON.parse(localStorage.storage).currentUtcTime !== null &&
      JSON.parse(localStorage.storage).currentUtcTime !== undefined
    ) {
      currentDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    return currentDate;
  }

  public static removeInvaildCharFromAmount(input, lenbeforePoint = 0) {
    let text = input;
    let output = "";
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`;
    for (let x = 0; x < input.length; x++) {
      let result = input.substring(x, x + 1).trim() as any;

      if ((x === 0 && result === "-") || !isNaN(result)) {
        if (x === 0 && result === "-") {
          output = output + result.toString();
        } else {
          output = output + result.toString();
        }
      } else if (result === ".") {
        output = output + result.toString();
      }
    }

    if (output.split(".").length > 2) {
      output = output.substring(0, output.length - 1) || "";
    } else {
      lenbeforePoint = lenbeforePoint || 8;
      if (output.includes("-")) {
        lenbeforePoint = lenbeforePoint + 1;
      }
      if (output.includes(".")) {
        const outputArray = output?.split(".");
        let val1 = "",
          val2 = "";
        if (outputArray[0].length > lenbeforePoint) {
          val1 = outputArray[0].substring(0, lenbeforePoint);
        } else {
          val1 = outputArray[0];
        }
        if (outputArray[1].length >= 2) {
          val2 = outputArray[1].substring(0, 2);
        } else {
          val2 = outputArray[1];
        }
        output = val1 + "." + val2;
      } else {
        output = output.substring(0, lenbeforePoint);
      }
    }
    return output;
  }

  public static removeInvaildCharFromAmountNonMinus(input) {
    let text = input;
    let output = "";
    const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`;
    for (let x = 0; x < input.length; x++) {
      let result = input.substring(x, x + 1).trim() as any;

      if ((x === 0 && result === "-") || !isNaN(result)) {
        if (x === 0 && result === "-") {
          output = output + result.toString();
        } else {
          output = output + result.toString();
        }
      } else if (result === ".") {
        output = output + result.toString();
      }
    }

    if (output.split(".").length > 2) {
      output = "";
    } else {
      let lenbeforePoint = 8;
      if (output.includes("-")) {
        lenbeforePoint = 9;
      }
      if (output.includes(".")) {
        const outputArray = output?.split(".");
        let val1 = "",
          val2 = "";
        if (outputArray[0].length > lenbeforePoint) {
          val1 = outputArray[0].substring(0, lenbeforePoint);
        } else {
          val1 = outputArray[0];
        }
        if (outputArray[1].length >= 2) {
          val2 = outputArray[1].substring(0, 2);
        } else {
          val2 = outputArray[1];
        }
        output = val1 + "." + val2;
      } else {
        output = output.substring(0, lenbeforePoint);
      }
    }
    return output;
  }
  public static currencyFormatWithOutdollerYAxis(num) {
    return Math.round(Number(num))
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  public static currencyFormatYAxis(num) {
    num = num?.toString()?.replaceAll(",", "");
    if (num.toString().includes("-")) {
      num = num.toString().replace("-", "");
      return (
        "-$" +
        Math.round(Number(num))
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      );
    }
    return (
      "$" +
      Math.round(Number(num))
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }
  public static currencyFormat(num) {
    num = num?.toString()?.replaceAll(",", "");
    num = this.decimalValue(num);
    if (num.toString().includes("-")) {
      num = num.toString().replace("-", "");
      return (
        "-$" +
        Number(num)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      );
    }
    return (
      "$" +
      Number(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }

  public static currencyFormatNoMinus(num) {//NW-31839
    num = num?.toString()?.replaceAll(",", "");
    num = this.decimalValue(num);
    if (num.toString().includes("-")) {
      num = num.toString().replace("-", "");
      return (
        "$" +
        Number(num)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      );
    }
    return (
      "$" +
      Number(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }

  public static currencyFormatWithoutRegex(num) {
    let result = num;
    if (num.toString().includes("-")) {
      num = num.toString().replace("-", "");
      result =
        "-$" +
        Number(num)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    result =
      "$" +
      Number(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return result;
  }
  public static currencyFormatWithOutdoller(num) {
    return Number(num)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  public static removecurrencyFormat(num) {
    return num.toString().replaceAll(",", "").replace("$", "");
  }
  public static removeSpecialCharcterFromString(InputValue) {
    return InputValue?.toString()
      .trim()
      ?.replace(/[@\/\\^\/\\&\/\\#,+()$~.%'":*?<>{}-]/g, "");
  }

  public static setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };
  public static getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // Use this event on onBlur
  public static decimalValue = (value) => {
    if (value === ".") {
      return "";
    }

    if (value !== "" && value !== "." && value?.length > 0) {
      let newValue = Number(value);
      if (value.includes(".")) {
        let val = newValue?.toFixed(2);
        if (Number.isInteger(parseFloat(val))) {
          val = parseInt(val)?.toString();
        }
        return val;
      }
    }
    return value;
  };

  public static convertUtcToTimezoneFormat = (
    utcDateTime: any,
    timeZone: string
  ) => {
    let timeZoneValue = "America/New_York"; // Default timezone

    switch (timeZone) {
      case "EST":
        timeZoneValue = "America/New_York";
        break;
      case "PST":
        timeZoneValue = "America/Los_Angeles";
        break;
      case "MST":
        timeZoneValue = "America/Phoenix";
        break;
      case "CST":
        timeZoneValue = "America/Chicago";
        break;
      case "HST":
        timeZoneValue = "US/Hawaii";
        break;
      default:
        timeZoneValue = "America/New_York";
        break;
    }

    const formattedTime = moment
      .utc(utcDateTime)
      .tz(timeZoneValue)
      .format("MMMM DD, YYYY h:mm A");
    return formattedTime;
  };


  public static toastError = (message, obj = {}) => {
    if (message === undefined || message.toLowerCase().includes('unauthorized') || message === "Unauthorized" || message === "Server Error, Unauthorized"
      || message === "Server Error: Unauthorized" || message?.toLowerCase().includes("cancel") || message?.toLowerCase().includes("Network Error")) {
      return
    }
    toast.error(`${message}`, obj);
  }

  public static toastSuccess = (message, obj = {}) => {
    if (message === undefined || message.toLowerCase().includes('unauthorized') || message === "Unauthorized" || message === "Server Error, Unauthorized"
      || message === "Server Error: Unauthorized" || message?.toLowerCase().includes("cancel")) {
      return
    }
    toast.success(`${message}`, obj);
  }

  public static allowOnlyAplhabetAndHypenUnderscoreApostrophe = (e) => {
    const allowChar = /^[A-Za-z'_\-\s]*$/;

    if (e.key.length === 1 && !allowChar.test(e.key)) {
      e.preventDefault()
    }
  }

  public static allowOnlyAplhabetAndHypenUnderscore = (e) => {
    const allowChar = /^[A-Za-z_\-\s]*$/;

    if (e.key.length === 1 && !allowChar.test(e.key)) {
      e.preventDefault()
    }
  }

  public static allowOnlyAplhabet = (e) => {
    const allowChar = /^[A-Za-z\s]*$/;

    if (e.key.length === 1 && !allowChar.test(e.key)) {
      e.preventDefault()
    }
  }

  public static allowOnlyAplhaNumeric = (e) => {
    const allowChar = /^[A-Za-z0-9\s]*$/;

    if (e.key.length === 1 && !allowChar.test(e.key)) {
      e.preventDefault()
    }
  }
}


