import * as React from "react";
import { Container, Dropdown, Form, Row, Col, } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { LaborHolidays as laborHolidays } from "../../Common/Services/LaborHolidays";
import { MultiSelectSearch } from "../../Common/Components/MultiSelectSearch";
import { Hotel as hotel } from "../../Common/Services/Hotel";
import { resolve, reject } from "q";
import PageLoader from "../../Common/Components/PageLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import calender from "../../Common/Assets/Images/icons/calendar.svg";
import calenderGrey from "../../Common/Assets/Images/icons/calendar_grey.svg";
import calenderArrow from "../../Common/Assets/Images/icons/calnder-arrow.svg";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import AutosizeInput from 'react-input-autosize';
import RouteLeavingGuard from "../../Common/Components/RouteLeavingGuard";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {
    RoleSubPermissionSetting,
    IPageSubPermissionRole,
} from "../../Common/Services/RoleSubPermissionSetting";
import { Utils } from "../../Common/Utilis";
let routeLeavingGuard: any = null;
export class Holidays extends React.Component<any, any> {
    private multiselectChild: any;
    private input: any;
    private saveBtnRef : any;
    constructor(props: any) {
        super(props);
        this.multiselectChild = React.createRef();
        this.input = React.createRef();
        this.saveBtnRef = React.createRef();
        this.state = {
            moreAction: [
                { name: "Change Log", eventKey: "changeLog" },
            ],
            activeHolidays: [],
            inactiveHolidays: [],
            holidayYears: [],
            selectYear: "",
            fieldData: {
                holidayID: 0,
                selectedHoliday: "",
                selectedDate: null,
                applyAll: "No",
                status: "Active",
                holiDayType: "CUSTOM"
            },
            isUnsavedChanges: false,
            selectedLetterCode: [],
            hotelList: [],
            isHotelFill: false,
            loaderTrue: false,
            isRPanelHide: true,
            isDeactivate: false,
            selectedActiveRow: false,
            selectedInActiveRow: false,
            isNewHoliday: false,
            selected: [],
            isFederal: false,
            isModelShow: false,
            isDelete: false,
            confirmModalContent: [],
            minDate: "",
            maxDate: "",
            actionKey: "",
            isModelPopUpInactive: false,
            isModelPopUpSave: false,
            btnEventName: "",
            isApproved: "",
            manageHolidays: false,
            managePermission:false,
            approveHoliday: false,
            isPagePermission: false,
        };

        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleChangeHoliday = this.handleChangeHoliday.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeCheck = this.handleChangeCheck.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleDiscard = this.handleDiscard.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSelectDate = this.handleSelectDate.bind(this);
        this.deleteHoliday = this.deleteHoliday.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.deactivateHoliday = this.deactivateHoliday.bind(this);
        this.ishandleAction = this.ishandleAction.bind(this);
        this.approveSchedule = this.approveSchedule.bind(this);
    }

    ishandleAction(mode: any) {
        if (mode) {
            if (this.state.actionKey === "deactivate") {
                this.activeInactive();
            } else if (this.state.actionKey === "delete") {
                this.DeleteHoliday();
            }
        }
        else {
            this.setState({
                isModelShow: false,
                isModelPopUpInactive: false,
                isModelPopUpSave: false,
            });

        }
    }

    activeInactive() {
        this.setState({ loaderTrue: true });
        laborHolidays.UpdateHolidayStatus(this.state.fieldData.holidayID, this.state.fieldData.status === "Active" ? "Inactive" : "Active")
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.saveStatus === "Success") {
                        toast.success(result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.laborHolidayList(this.state.selectYear);
                    } else if (result.saveStatus === "Fail" && result.messageCode === "ApprovedHoliday") {
                        let Message = "This holiday exists within one or more approved payroll periods. Please unapprove the relevant payroll periods for each of the following hotels before attempting to deactivate this holiday:  \n\n" + result.code + "";
                        let confirmModalContent = Object.assign([], this.state.confirmModalContent);
                        confirmModalContent = [{ title: "Payroll must be unapproved", desc: Message, cancleAction: "Cancel", ActionTrue: "" }];
                        this.setState({ confirmModalContent: confirmModalContent, isModelPopUpInactive: true });
                    }
                    else {
                        Utils.toastError(result.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                }
                else {
                    Utils.toastError("Internal server error.", {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                this.setState({ loaderTrue: false, isModelShow: false });
                resolve();

            })
            .catch((error) => {
                reject();
                this.setState({ loaderTrue: false, isModelShow: false });
            });
    }

    DeleteHoliday() {

        this.setState({ loaderTrue: true });
        laborHolidays.DeleteLaborHoliday(this.state.fieldData.holidayID)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.saveStatus === "Success") {
                        toast.success("Holiday Deleted Successfully.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.laborHolidayList(this.state.selectYear);
                        this.loadState();
                        this.setState({ isRPanelHide: true });
                    } else if (result.saveStatus === "Fail" && result.messageCode === "ApprovedHoliday") {
                        let Message = "This holiday exists within one or more approved payroll periods. Please unapprove the relevant payroll periods for each of the following hotels before attempting to delete this holiday:  \n\n" + result.code + "";
                        let confirmModalContent = Object.assign([], this.state.confirmModalContent);
                        confirmModalContent = [{ title: "Payroll must be unapproved", desc: Message, cancleAction: "Cancel", ActionTrue: "" }];
                        this.setState({ confirmModalContent, isModelPopUpInactive: true });
                    }
                    else {
                        Utils.toastError(result, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                }
                else {
                    Utils.toastError("Internal server error.", {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                this.setState({ loaderTrue: false, isModelShow: false });
                resolve();

            })
            .catch((error) => {
                reject();
                this.setState({ loaderTrue: false, isModelShow: false });
            });
    }


    deactivateHoliday() {
        if (this.state.isUnsavedChanges) {
            this.setState({ btnEventName: "status" });
            this.isValidateModelPoupTab();
        }
        else {
            this.holidayStatusPopup();
        }
    }

    holidayStatusPopup() {
        let confirmModalContent = Object.assign([], this.state.confirmModalContent);
        if (this.state.fieldData.status === "Active") {
            confirmModalContent = [{ title: "Deactivate Holiday", desc: "Are you sure you want to deactivate '" + this.state.fieldData.selectedHoliday + "' ?", cancleAction: "Cancel", ActionTrue: "Deactivate Holiday" }];
        } else {
            confirmModalContent = [{ title: "Activate Holiday", desc: "Are you sure you want to activate '" + this.state.fieldData.selectedHoliday + "' ?", cancleAction: "Cancel", ActionTrue: "Activate Holiday" }];
        }
        this.setState({ confirmModalContent, isModelShow: true, actionKey: "deactivate" });

    }

    deleteHoliday() {
        if (this.state.isUnsavedChanges) {
            this.setState({ btnEventName: "delete" });
            this.isValidateModelPoupTab();
        }
        else {
            this.deletePopUp();
        }
    }

    deletePopUp() {
        let confirmModalContent = Object.assign([], this.state.confirmModalContent);
        confirmModalContent = [{ title: "Delete Holiday", desc: "Are you sure you want to delete '" + this.state.fieldData.selectedHoliday + "'?", cancleAction: "Cancel", ActionTrue: "Delete Holiday" }];
        this.setState({ confirmModalContent, isModelShow: true, actionKey: "delete" });
    }

    handleSelectDate = (date: any) => {
        let fieldData = Object.assign({}, this.state.fieldData);
        fieldData.selectedDate = date;
        this.setState({ fieldData, isUnsavedChanges: true })
    }

    handleNew() {
        if (this.state.isUnsavedChanges) {
            this.setState({ btnEventName: "newholiday" });
            this.isValidateModelPoupTab();
        }
        else {
            this.addNewHoliday();
        }
    }

    addNewHoliday() {
        this.loadState();
        setTimeout(() => {
            this.input.current.focus();
        }, 100);
    }

    handleChangeHoliday(event: { target: any; }) {
        const e = event.target;
        const value = e.value;
        let fieldData = Object.assign({}, this.state.fieldData);
        fieldData.selectedHoliday = value;
        this.setState({ fieldData, isUnsavedChanges: true })
    }

    handleChangeDate(event: { target: any; }) {
        const e = event.target;
        const value = e.value;
        let fieldData = Object.assign({}, this.state.fieldData);
        fieldData.selectedDate = value;
        this.setState({ fieldData, isUnsavedChanges: true })
    }

    handleChangeCheck(event: { target: any; }) {
        const e = event.target;
        const value = e.type === 'checkbox' ? e.checked ? "Yes" : "No" : e.value;
        let fieldData = Object.assign({}, this.state.fieldData);
        fieldData.applyAll = value;
        this.setState({ fieldData, selectedLetterCode: [], isUnsavedChanges: true }, () => {
            if (!e.checked) {
                this.multiselectChild.current.updateSavedItem([]);
            }
        })
    }

    componentDidMount() {
        this.getMenuPermission();
    }


    getMenuPermission = () => {
        let isPermission = false;
        let requestObject = {} as any;
        requestObject.permissionID = "ALL";
        requestObject.Module = "LaborManagement";
        requestObject.Type = "menu";
        RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
            .then(async (result: any | null) => {
                if (result != null && result.length > 0) {
                    result.forEach((element: any) => {
                        if (element.permissionID === 10033) {
                            isPermission = true;
                            this.setState({ isPagePermission: true });
                        }
                    });
                    if (isPermission) {
                        this.getHolidayPermission();
                        window.addEventListener("beforeunload", this.beforeunload.bind(this));
                        this.getHolidayYears();
                        this.gethotel();
                    } else {
                        window.location.href = "/unauthorize-access";
                    }
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError(`Server Error, ${err}`, {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                    // // containerId: "payrollExportIndex",
                });
                reject();
            });
    }

    getHolidayPermission = () => {
        let requestObject = {} as IPageSubPermissionRole;
        requestObject.permissionID = 10033;
        requestObject.Module = "LaborManagement";
        requestObject.Type = "page";
        RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
            .then(async (result: any | null) => {
                if (result != null && result.length > 0) {
                    result.forEach((element: any) => {
                        switch (element.permissionID) {
                            case 10044:
                                this.setState({ manageHolidays: true,managePermission:true });
                                break;
                            case 10045:
                                this.setState({ approveHoliday: true });
                                break;
                        }

                    });
                } else {
                    Utils.toastError(result.message, {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                        // // containerId: "scheduleMain",
                    });
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError(`Server Error, ${err}`, {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                    // // containerId: "scheduleMain",
                });
                reject();
            });
    };


    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeunload.bind(this));
    }


    beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
        if (
            this.state.isUnsavedChanges &&
            routeLeavingGuard !== "routeLeavingGuardTrue"
        ) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    routeLeavingGuardConfirm = (childState: any) => {
        if (childState === true) {
            routeLeavingGuard = "routeLeavingGuardTrue";
        } else {
            routeLeavingGuard = null;
        }
    };

    getHolidayYears = () => {
        this.setState({ loaderTrue: true })
        laborHolidays.laborHolidayYears().then(async (result: any[] | null) => {
            if (result != null) {
                let minDate
                    = "01-01-" + result[0].defaultyear;
                let maxDate = "12-31-" + result[0].defaultyear;
                this.setState({ holidayYears: result, selectYear: result[0].defaultyear, minDate, maxDate })
                this.laborHolidayList(result[0].defaultyear);
            }
            this.setState({ loaderTrue: false })
            resolve();
        })
            .catch((error) => {
                this.setState({ loaderTrue: false })
                reject();
            });

    }


    laborHolidayList = (selectedYear: string) => {
        this.setState({ loaderTrue: true })
        laborHolidays.laborHolidayList(selectedYear).then(async (result: any) => {
            if (result != null && result.length > 0) {
                let activeHolidays: any[], inactiveHolidays: any[];
                activeHolidays = result.filter(item => item.status.toLowerCase() === "active")
                inactiveHolidays = result.filter(item => item.status.toLowerCase() === "inactive")
                this.setState({ activeHolidays, inactiveHolidays, isApproved: result[0].isApprove });
                if (this.state.isNewHoliday || this.state.actionKey === "deactivate") {
                    this.loadHolidaysList(this.state.fieldData.holidayID);
                }
            }
            else{
                this.setState({activeHolidays : [], inactiveHolidays : [],  isApproved : ""});
            }
            this.setState({ loaderTrue: false })
            resolve();
        })
            .catch((error) => {
                this.setState({ loaderTrue: false })
                reject();
            });
    }

    laborHolidayDetail = (holidayID: number) => {
        debugger;
        this.setState({ loaderTrue: true })
        laborHolidays.laborHolidayDetail(holidayID).then(async (result: any[] | null) => {
            debugger;
            if (result != null) {
                let fieldData: any;
                let new_state = Object.assign({}, this.state);
                fieldData = new_state.fieldData;
                fieldData.holidayID = holidayID;
                fieldData.applyAll = result[0].applyAll;
                fieldData.selectedHoliday = result[0].holiDay;
                fieldData.status = result[0].status;
                fieldData.holiDayType = result[0].holiDayType;
                if (result[0].holiDayType.toUpperCase() === "FEDERAL") {
                    this.setState({ isFederal: true })
                }
                else {
                    this.setState({ isFederal: false })
                }
                fieldData.selectedDate = new Date(result[0].selectedDate);
                this.setState({ fieldData, isRPanelHide: false, isDeactivate: true, selectedLetterCode: result[0].laborHolidayHIDs }
                    , () => {
                        if (this.state.fieldData.applyAll === "No") {
                            let chartItems: any[] = [];
                            let savedchartItems = this.state.selectedLetterCode;
                            savedchartItems.forEach((element: { lettercode: any }) => {
                                let newItem = {} as any;
                                newItem.value = element.lettercode;
                                newItem.isChecked = true;
                                chartItems.push(newItem);
                            });
                            this.multiselectChild.current.updateSavedItem(chartItems);
                        }
                    }
                );
            }
            this.setState({ isUnsavedChanges: false, isNewHoliday: false, loaderTrue: false });
            resolve();
        })
            .catch((error) => {
                this.setState({ loaderTrue: false })
                reject();
            });
    }

    gethotel = () => {
        this.setState({ loaderTrue: true })
        hotel.getAllActiveHotelAccess("Labor Management")
            .then(async (result: any[] | null) => {
                if (result != null) {
                    let hotelList: any[] = [];
                    let isHotelFill = true;
                    result.forEach((element) => {
                        let newItem = {} as any;
                        newItem.hotelID = element.hotelID;
                        newItem.value = element.lettercode;
                        newItem.description = element.hotelName;
                        newItem.isChecked = false;
                        hotelList.push(newItem);
                    });
                    this.setState({ hotelList, isHotelFill })
                }
                this.setState({ loaderTrue: false })
                resolve();
            })
            .catch((error) => {
                this.setState({ loaderTrue: false })
                reject();
            });
    }


    handleYearChange = (event: any, selectYear) => {
        if (this.state.isUnsavedChanges) {
            this.setState({ selectYear, btnEventName: "ddlyearchange" });
            this.isValidateModelPoupTab();
        }
        else {
            this.loadYears(selectYear);
        }
    }

    loadYears(selectYear: string) {
        let minDate = "01-01-" + selectYear;
        let maxDate = "12-31-" + selectYear;
        this.loadState();
        this.setState({ selectYear, minDate, maxDate, isRPanelHide: true, actionKey: "", isNewHoliday: false });
        this.laborHolidayList(selectYear);
    }

    rowActiveEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
            if (this.state.isUnsavedChanges) {
                let new_state = Object.assign({}, this.state);
                let fieldData = new_state.fieldData;
                fieldData.holiDayID = row.holiDayID;
                this.setState({ fieldData, btnEventName: "roweventactive" });
                this.isValidateModelPoupTab();
            }
            else {
                this.rowActiveHolidays(row.holiDayID);
            }
        }
    };

    rowActiveHolidays(holiDayID: number) {
        this.setState({
            selectedActiveRow: true, selectedInActiveRow: false, isNewHoliday: false, selected: [], isselected: false, isModelShow: false,
            isModelPopUpInactive: false,
            isModelPopUpSave: false,
        });
        this.laborHolidayDetail(holiDayID);
    }

    rowInactiveEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
            if (this.state.isUnsavedChanges) {
                let new_state = Object.assign({}, this.state);
                let fieldData = new_state.fieldData;
                fieldData.holiDayID = row.holiDayID;
                this.setState({ fieldData, btnEventName: "roweventinactive" });
                this.isValidateModelPoupTab();
            }
            else {
                this.rowInactiveHolidays(row.holiDayID);
            }
        }
    };

    rowInactiveHolidays(holiDayID: number) {
        this.setState({
            selectedActiveRow: false, selectedInActiveRow: true, isNewHoliday: false, selected: [], isselected: false, isModelShow: false,
            isModelPopUpInactive: false,
            isModelPopUpSave: false,
        });
        this.laborHolidayDetail(holiDayID);
    }

    handleDiscard() {
        if (this.state.isNewHoliday) {
            this.loadState();
            setTimeout(() => {
                this.input.current.focus();
            }, 100);
        }
        else {
            this.laborHolidayDetail(this.state.fieldData.holidayID);
        }
    }

    loadState() {
        let fieldData = Object.assign({}, this.state.fieldData);
        fieldData.holidayID = 0;
        fieldData.selectedHoliday = "";
        //fieldData.selectedDate = null;
        fieldData.selectedDate = JSON.parse(localStorage.storage).currentUtcTime !== "" ?new Date(
            JSON.parse(localStorage.storage).currentUtcTime
          ) :null;
        fieldData.applyAll = "No";
        fieldData.status = "Active";
        fieldData.holiDayType = "CUSTOM";
        this.setState({
            fieldData, isRPanelHide: false, isDeactivate: false, isUnsavedChanges: false,
            isNewHoliday: true, selectedLetterCode: [], isFederal: false, selectedActiveRow: false, selectedInActiveRow: false,
            selected: [], isselected: false,
            isModelShow: false,
            isDelete: false,
            actionKey: "",
            isModelPopUpInactive: false,
            isModelPopUpSave: false,
        }, () => {
            if (this.state.fieldData.applyAll === "No" && this.state.isRPanelHide === false) {
                this.multiselectChild.current.updateSavedItem([]);
            }
        })
    }

    updateMultiSelectItem = (selectedItem: any) => {
        this.setState({ selectedLetterCode: selectedItem });
    };

    dataChange = () => {
        this.setState({ isUnsavedChanges: true });
    };

    handleSave() {
        if (this.isFormValid()) {
            let today = new Date(this.state.fieldData.selectedDate);
            let date = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
            let fieldData = Object.assign({}, this.state.fieldData);
            this.setState({ loaderTrue: true });
            this.saveBtnRef.current.disabled = true;
            let saveRequest: any = {};
            let selectedHIDs: any[] = [];
            this.state.selectedLetterCode.forEach((element: any) => {
                let newItem = {} as any;
                newItem.hotelID = element.hotelID;
                selectedHIDs.push(newItem);
            });
            saveRequest.holiDayID = fieldData.holidayID;
            saveRequest.holiDay = fieldData.selectedHoliday;
            saveRequest.selectedDate = date;
            saveRequest.applyAll = fieldData.applyAll;
            saveRequest.date = date;
            saveRequest.year = this.state.selectYear;
            saveRequest.status = fieldData.status;
            saveRequest.holiDayType = fieldData.holiDayType;
            saveRequest.laborHolidayHIDs = selectedHIDs;
            laborHolidays.saveLaborHoliday(saveRequest)
                .then(async (result: any | null) => {
                    if (result != null) {
                        if (result.saveStatus === "Success") {
                            let fieldData = Object.assign({}, this.state.fieldData);
                            fieldData.holidayID = result.holidayID;
                            this.setState({ fieldData });
                            this.laborHolidayList(this.state.selectYear);
                            toast.success(result.message, {
                                // // position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            this.saveBtnRef.current.disabled = false;
                            this.setState({ loaderTrue: false, isUnsavedChanges: false });
                        } else if (result.saveStatus === "Fail" && result.messageCode === "ApprovedHoliday") {
                            let Message = "This holiday exists within one or more approved payroll periods. Please unapprove the relevant payroll periods for each of the following hotels before attempting to edit this holiday:  \n\n" + result.code + "";
                            let confirmModalContent = Object.assign([], this.state.confirmModalContent);
                            confirmModalContent = [{ title: "Payroll must be unapproved", desc: Message, cancleAction: "Cancel", ActionTrue: "" }];
                            this.saveBtnRef.current.disabled = false;
                            this.setState({ confirmModalContent, isModelPopUpSave: true, loaderTrue: false, isUnsavedChanges: true });
                        }
                        else {
                            Utils.toastError(result.message, {
                                // // position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            this.saveBtnRef.current.disabled = false;
                            this.setState({ loaderTrue: false, isUnsavedChanges: true });
                        }
                    }
                    else {
                        Utils.toastError("Internal server error.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.saveBtnRef.current.disabled = false;
                        this.setState({ loaderTrue: false, isUnsavedChanges: true });
                    }

                    resolve();
                })
                .catch((error) => {
                    reject();
                    this.saveBtnRef.current.disabled = false;
                    this.setState({ loaderTrue: false, isUnsavedChanges: true });
                });
        }
    }

    loadHolidaysList(holidayID: any) {
        this.laborHolidayDetail(holidayID);
        this.defaultSelectedRow(holidayID);
    }


    defaultSelectedRow = (holidayID: any) => {
        for (let i = 0; i <= this.state.activeHolidays.length - 1; i++) {
            if (Number(this.state.activeHolidays[i].holiDayID) === Number(holidayID)) {
                this.setState({
                    selected: [this.state.activeHolidays[i].holiDayID], isselected: true, selectedActiveRow: true,
                    selectedInActiveRow: false
                });
            }
        }

        for (let i = 0; i <= this.state.inactiveHolidays.length - 1; i++) {
            if (Number(this.state.inactiveHolidays[i].holiDayID) === Number(holidayID)) {
                this.setState({
                    selected: [this.state.inactiveHolidays[i].holiDayID], isselected: true, selectedActiveRow: false,
                    selectedInActiveRow: true
                });
            }
        }
    }

    getMonthName(month: any) {
        switch (month) {
            case 0:
                return 'Janaury';
            case 1:
                return 'February';
            case 2:
                return 'March';
            case 3:
                return 'April';
            case 4:
                return 'May';
            case 5:
                return 'June';
            case 6:
                return 'July';
            case 7:
                return 'August';
            case 8:
                return 'September';
            case 9:
                return 'October';
            case 10:
                return 'November';
            case 11:
                return 'December';
        }
    }

    isFormValid() {
        if (this.state.fieldData.selectedHoliday.trim().length === 0) {
            Utils.toastError("Please enter holiday.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        } else if (this.state.fieldData.selectedHoliday.trim().length > 100) {
            Utils.toastError("Holiday length should be 100 characters.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        else if (this.state.fieldData.selectedDate === null) {
            Utils.toastError("Please select date.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        else if (this.state.selectedLetterCode.length === 0 && this.state.fieldData.applyAll === "No") {
            Utils.toastError("Please choose EHID.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false;
        }
        else {
            return true;
        }

    }

    isValidateModelPoupTab() {
        confirmAlert({
            title: "Unsaved confirmation",
            message:
                "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.isChangeBudgetTab(),
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }

    isChangeBudgetTab = () => {
        this.setState({ isUnsavedChanges: false });
        switch (this.state.btnEventName) {
            case 'status':
                return this.holidayStatusPopup();
            case 'delete':
                return this.deletePopUp();
            case 'newholiday':
                return this.addNewHoliday();
            case 'roweventactive':
                return this.rowActiveHolidays(this.state.fieldData.holidayID);
            case 'roweventinactive':
                return this.rowInactiveHolidays(this.state.fieldData.holidayID);
            case 'ddlyearchange':
                return this.loadYears(this.state.selectYear);
        }
    };

    approveSchedule() {
        let year = this.state.selectYear;
        laborHolidays.approveLaborHoliday(year).then(async (result: any | null) => {
            if (result != null) {
                toast.success("Holiday approved successfully.", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.setState({ isApproved: "Yes" });
            }
            else {
                Utils.toastError("Internal server error.", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
            this.setState({ loaderTrue: false });
            resolve();

        })
            .catch((error) => {
                reject();
                this.setState({ loaderTrue: false });
            });
    }

    render() {
        var loadShow: any;
        var loaderTrue = this.state.loaderTrue;
        if (loaderTrue) {
            loadShow = <PageLoader />;
        }

        const moreAction = (eventKey: any) => {
            if (eventKey === "changeLog") {
                //alert(eventKey);
            }
        };


        const handleSelectYear = (eventKey: any) => {
            this.setState({ selectYear: eventKey });
        };
        const hoverOutEllipsis = () => {
            $(".tooltip").removeClass("show");
        };

        const columns = [{
            dataField: 'holiDay',
            text: '',
            formatter: (
                cell: any,
                row: {
                    holiDay: React.ReactNode;
                },
                rowIndex: any
            ) => (
                <div>
                    <div className="title" onWheel={() => hoverOutEllipsis()}>
                        <EllipsisWithTooltip placement="bottom">
                            {row.holiDay}
                        </EllipsisWithTooltip>
                    </div>
                </div>

            ),
        }, {
            dataField: 'dayName',
            text: '',
            formatter: (
                cell: any,
                row: {
                    dayName: React.ReactNode;
                },
                rowIndex: any
            ) => (
                <div>
                    <div className="title1">{row.dayName}</div>
                    <span className="desc">Date</span>
                </div>

            ),
        }];

        const selectRow = {
            mode: "radio",
            clickToSelect: true,
            hideSelectColumn: true,
            classes: "active-row",
            selected: this.state.isselected ? this.state.selected : "",
        };
        const hideRow = {
            mode: "radio",
            clickToSelect: true,
            hideSelectColumn: true,
            classes: "",

        };
        const isActiveHoliday = this.state.activeHolidays.length > 0 ? true : false;
        const isInactiveHoliday = this.state.inactiveHolidays.length > 0 ? true : false;


        let isCalenderDisabled = false;
        if (this.state.manageHolidays) {
            isCalenderDisabled = this.state.isFederal;
        }
        else {
            isCalenderDisabled = false;
        }

        return (
            <>
                {this.state.isPagePermission && (
                    <React.Fragment>
                        <RouteLeavingGuard
                            when={this.state.isUnsavedChanges}
                            routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
                            shouldBlockNavigation={(location: { pathname: any }) => {
                                if (this.state.isUnsavedChanges) {
                                    if (location.pathname) {
                                        return true;
                                    }
                                }
                                return false;
                            }}
                        />
                        <Container fluid className="body-sec">
                            {/* <ToastContainer autoClose={3000} /> */}
                            {loadShow}
                            <div className="page-heading underline d-flex">
                                <div className="mr-auto">Holidays</div>
                                <div className="action-group d-flex flex-row">
                                    {this.state.manageHolidays && (
                                        <button type="button" className="btn btn-primary" onClick={this.handleNew}> New Holiday </button>
                                    )}
                                    {this.state.isMoreDrop && (
                                        <Dropdown className="more-action" onSelect={moreAction} alignRight >
                                            <Dropdown.Toggle className="btn-outline-primary btn btn-primary more"
                                                id="dropdown-more" >
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg" >
                                                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                </svg>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {this.state.moreAction.map((item: any, idx: any) => (
                                                    <Dropdown.Item key={"action-" + idx} eventKey={this.state.moreAction[idx].eventKey} >
                                                        {this.state.moreAction[idx].name}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>)}
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="section">
                                    <div className="budget-settings holidays d-flex justify-content-start">
                                        <div className="left-panel">
                                            <div className="search-header">
                                                <Dropdown className="more-action date-selector" onSelect={handleSelectYear}>
                                                    <Dropdown.Toggle id="dropdown-ptoType">
                                                        <img src={calender} alt="Calender" />
                                                        <span className="date-select">{this.state.selectYear}</span>
                                                        <img className="arrow" src={calenderArrow} alt="Calender Arrow" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {this.state.holidayYears.map(
                                                            (item: any, idx: any) => (
                                                                <Dropdown.Item
                                                                    key={item.year}
                                                                    eventKey={item.year}
                                                                    onClick={(e: any) => this.handleYearChange(e, item.year)}
                                                                >
                                                                    {item.year}
                                                                </Dropdown.Item>
                                                            )
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown> </div>
                                            <div className="left-body">
                                                <div>
                                                    {this.state.isApproved === "No" && this.state.approveHoliday && (
                                                        <div className="info-badges ">
                                                            <div>Please review the holidays below and make any changes necessary. Once done, accept the schedule.</div>
                                                            <div>
                                                                <button type="button" className="btn btn-primary" onClick={this.approveSchedule}> Accept Schedule </button></div>
                                                        </div>
                                                    )}

                                                    {isActiveHoliday && (
                                                        <div className="sec-ttl">Active</div>
                                                    )}
                                                    <BootstrapTable keyField='holiDayID' data={this.state.activeHolidays} columns={columns} rowEvents={this.rowActiveEvents}
                                                        selectRow={this.state.selectedActiveRow ? selectRow : hideRow} />

                                                    {this.state.manageHolidays && (
                                                        <div className="add-Budget" onClick={this.handleNew}> New Holiday </div>
                                                    )}

                                                </div>
                                                <div>
                                                    {isInactiveHoliday && (
                                                        <div className="sec-ttl mdy-mrg">InActive</div>
                                                    )}
                                                    <div className="inactive-holiday">
                                                        <BootstrapTable keyField='holiDayID' data={this.state.inactiveHolidays} columns={columns} rowEvents={this.rowInactiveEvents}
                                                            selectRow={this.state.selectedInActiveRow ? selectRow : hideRow} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!this.state.isRPanelHide && (
                                            <div className="right-panel">
                                                <div className="pos-header d-flex align-content-center flex-wrap">
                                                    <div className="pos-ttl mr-auto ">
                                                        {(this.state.fieldData.status === "Active") && (
                                                            <Form.Control
                                                                ref={this.input}
                                                                id="hilday"
                                                                type="text"
                                                                name="selectedHoliday"
                                                                className="removeBorder bg-white"
                                                                value={this.state.fieldData.selectedHoliday}
                                                                onChange={this.handleChangeHoliday}
                                                                placeholder={"Holiday Name"}
                                                                maxLength={30}
                                                                disabled={!this.state.manageHolidays}
                                                                readOnly={this.state.isFederal}
                                                            />
                                                        )}
                                                        {(this.state.fieldData.status === "Inactive") && (
                                                            <div className="d-flex align-items-center">
                                                                <AutosizeInput
                                                                    ref={this.input}
                                                                    className={this.state.bdrValue ? "form-control removeBorder input-component bdr-green" : "form-control removeBorder input-component"}
                                                                    style={{ fontSize: 24 }}
                                                                    injectStyles={false}
                                                                    type="input"
                                                                    value={this.state.fieldData.selectedHoliday}
                                                                    placeholder={"Holiday Name"}
                                                                    readOnly
                                                                />
                                                                <div className="service-status">Inactive</div></div>
                                                        )}
                                                    </div>
                                                    {this.state.isDeactivate && this.state.manageHolidays && (
                                                        <div className="pos-rel">
                                                            <Dropdown className="more-action delete-Confirm" alignRight>
                                                                <Dropdown.Toggle
                                                                    className="btn-outline-primary btn btn-primary more"
                                                                    id="dropdown-more"
                                                                >
                                                                    <svg
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                                                    </svg>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={this.deactivateHoliday}> {this.state.fieldData.status === "Active" ? "Deactivate Holiday" : "Activate Holiday"} </Dropdown.Item>
                                                                    {!this.state.isFederal && (
                                                                        <Dropdown.Item onClick={this.deleteHoliday}> Delete Holiday </Dropdown.Item>
                                                                    )}
                                                                </Dropdown.Menu>
                                                                {this.state.isModelShow && (
                                                                    <div><ConfirmationModal confirmModalContent={this.state.confirmModalContent} ishandleDeletePosition={this.ishandleAction.bind(this)} /></div>
                                                                )}
                                                            </Dropdown>
                                                            {this.state.isModelPopUpInactive && (
                                                                <div className="pos-abs right-align"><ConfirmationModal confirmModalContent={this.state.confirmModalContent} ishandleDeletePosition={this.ishandleAction.bind(this)} /></div>
                                                            )}
                                                        </div>
                                                    )}

                                                </div>

                                                <div className="pos-body" >
                                                    <Form>
                                                        <Form.Group as={Row} controlId="selectedDate">
                                                            <Form.Label column sm="4"> Date </Form.Label>
                                                            <Col sm="8" className="date-picker">
                                                                <DatePicker key="datePicker"
                                                                    disabled={this.state.manageHolidays === true ? this.state.isFederal : true}
                                                                    //disabled={!isCalenderDisabled}
                                                                    selected={this.state.fieldData.selectedDate}
                                                                    placeholderText={"Select date"} dateFormat="MMMM d, yyyy" className="removeBorder form-control"
                                                                    onSelect={this.handleSelectDate} minDate={new Date(this.state.minDate)} maxDate={new Date(this.state.maxDate)} />
                                                                <img className="calender-selector-icon" src={calenderGrey} alt="Calender Arrow" />
                                                            </Col>

                                                        </Form.Group>
                                                        <div className="border-line"></div>
                                                        <Form.Group as={Row} controlId="notes">
                                                            <Form.Label column sm="4"> Apply To All EHID's</Form.Label>
                                                            <Col sm="8">
                                                                <Form.Check
                                                                    key="HIDApply"
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    name="applyAll"
                                                                    disabled={!this.state.manageHolidays}
                                                                    label=""
                                                                    checked={this.state.fieldData.applyAll === "Yes" ? true : false}
                                                                    onChange={this.handleChangeCheck}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        {this.state.fieldData.applyAll === "No" && (
                                                            <Form.Group as={Row} controlId="notes">
                                                                <Form.Label column sm="4"> Applies To  </Form.Label>
                                                                <Col sm="8">
                                                                    {this.state.isHotelFill && (
                                                                        <MultiSelectSearch
                                                                            ref={this.multiselectChild}
                                                                            itemList={this.state.hotelList}
                                                                            updateMultiSelectItem={this.updateMultiSelectItem.bind(this)}
                                                                            dataChange={this.dataChange.bind(this)}
                                                                            isSelectAll={true}
                                                                            filtertitle={"Filter EHIDs"}
                                                                            managePermission={this.state.managePermission}
                                                                        />
                                                                    )}
                                                                </Col>
                                                            </Form.Group>
                                                        )}
                                                    </Form>

                                                    {this.state.isUnsavedChanges && this.state.manageHolidays && (
                                                        <div className="fixed-action">
                                                            <div className="d-flex align-content-center flex-wrap pos-rel ">
                                                                {this.state.isModelPopUpSave && (
                                                                    <div className="pos-abs right-align btm-margin-71"><ConfirmationModal confirmModalContent={this.state.confirmModalContent} ishandleDeletePosition={this.ishandleAction.bind(this)} /></div>
                                                                )}
                                                                <div className="mr-auto message">
                                                                    <span>You have unsaved changes</span>
                                                                </div>
                                                                <button type="button" className="btn btn-primary btn-discard"
                                                                    onClick={this.handleDiscard}
                                                                > Discard </button>

                                                                <button
                                                                    type="button"
                                                                    ref={this.saveBtnRef}
                                                                    className="btn btn-primary"
                                                                    onClick={this.handleSave}
                                                                > Save Changes </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Container>

                    </React.Fragment>
                )}
            </>
        );
    }
}
