import * as React from "react";
import {
  Form,
} from "react-bootstrap";
import { DropDownMultiList } from "./common-components/dropdown-multi-list";

export class DataSourceOperating extends React.Component<any, any> {
  private checkBoxRef: any;
  constructor(props: any) {
    super(props);
    this.checkBoxRef = React.createRef();
    this.state = {
      dataSourceList: [
        { label: "Actual", value: "Actual", isChecked: true },
        { label: "Adjusted Budget", value: "Adjusted Budget" },
        { label: "Original Budget", value: "Original Budget", isChecked: true },
        { label: "Forecast", value: "Forecast" },
        { label: "Prior", value: "Prior", isChecked: true }
      ],
      listQuarterly: [
        { label: "Actual", value: "Actual", isChecked: false },
        { label: "Adjusted Budget", value: "Adjusted Budget", isChecked: false },
        { label: "Original Budget", value: "Original Budget", isChecked: false },
      ],
      dataSourceList1: [
        { label: "Adjusted Budget", value: "Adjusted Budget" },
        { label: "Original Budget", value: "Original Budget", isChecked: true },
        { label: "Forecast", value: "Forecast" },
        { label: "Prior", value: "Prior" }
      ]
      , defaultValue: "",
    }
  }


  componentDidUpdate(prevProps, prevState) {
    const { defaultValue } = this.props;
    let dataSourceList = this.props?.reportid === 2168 ? [...this.state.listQuarterly] : [...this.state.dataSourceList];
    if (prevProps.defaultValue !== defaultValue) {
      let groupNames = defaultValue.split(",")?.map(r => r);
      const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
      groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
      dataSourceList = dataSourceList?.map(item => {
        if (groupNames?.includes(item?.label)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      })
      this.setState({ dataSourceList, defaultValue });
    }
  }

  componentDidMount = () => {
    const { defaultValue } = this.props;
    let dataSourceList = this.props?.reportid === 2168 ? [...this.state.listQuarterly] : [...this.state.dataSourceList];
    if (defaultValue === undefined || defaultValue === "") {
      this.props?.onDataSourceOperating(dataSourceList);
    } else {
      let groupNames = defaultValue.split(",")?.map(r => r);
      const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
      groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
      dataSourceList = dataSourceList?.map(item => {
        if (groupNames?.includes(item?.label)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      })
      this.setState({ dataSourceList, defaultValue }, () => {
        this.props?.onDataSourceOperating(dataSourceList);
      });
    }
  };

  updateMultiSelectItem = (selectedItem: any) => {
    this.props?.onDataSourceOperating(selectedItem);
  };

  render() {
    const { listQuarterly, dataSourceList, dataSourceList1 } = this.state;
    const isList = this.props?.reportid === 2168 ? listQuarterly : this.props?.reportid === 2178 ? dataSourceList1 : dataSourceList;

    return (

      <DropDownMultiList
        defaultValue={this.state?.defaultValue}
        label={"label"}
        value={"value"}
        data={[...isList]}
        updateMultiSelectItem={this.updateMultiSelectItem}
        isSelectAll={true}
        placeHolder={"Select Data Source 1"}
        filtertitle={"Filter Data Source 1"}
      />

    );
  }
}
