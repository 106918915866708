import React, { Suspense } from "react";
// import GreyTickMark from "../../../../../Common/Assets/Images/icons/grey_tick_mark.svg";
import { Dropdown, FormGroup, Nav, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import "./bankSync.scss";
import BankSyncFindTable from "./BankSyncFindTable";
import { resolve, reject } from "q";
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";
import { Utils } from "../../../Common/Utilis";
import { Register as registersevice } from "../../../Common/Services/Register";
import { EndOfLineState } from "typescript";
// const BankSyncFindTable = React.lazy(() => import("./BankSyncFindTable"));

export default class BankSyncChildTable extends React.Component<any, any> {
  private laborDayDetails: any;
  private confirmBtnRef: any;
  private BankSyncFindTableRef: any;
  constructor(props: any) {
    super(props);
    this.laborDayDetails = React.createRef();
    this.confirmBtnRef = React.createRef();
    this.BankSyncFindTableRef = React.createRef();
    this.state = {
      renderTable: "",
      row: props.row,
      isBackVisible: true,
      isContinueVisible: true,
      activeTab: this.props.transactions.length > 0 ? "review" : "find",
      selectedTransaction: 0,
      findTabSelectedRows: [],
      addTransactionAction: [
        { name: "Deposit", eventKey: "deposit", toShow: 0 },
        { name: "Withdrawal", eventKey: "withdrawal", toShow: 0 },
        { name: "Add Debit", eventKey: "CreditCardWithdrawalAmount", toShow: 0 },
        { name: "Add Credit", eventKey: "CreditCardDepositAmount", toShow: 0 },
        { name: "Transfer", eventKey: "transfer", toShow: 0 },
        { name: "Draft Transactions", eventKey: "isInvoice", toShow: 0 },
      ],
      hidValue: this.props.hidValue,
      hotelName: this.props.hotelName,
      hasDataToConfirm: false,
      isLoadMore: false,
      isConfirmDisabled: false,
      isDraftInvoicePer: false
    };
  }

  componentDidMount() {
    this.IsTransactionRolePermission();
    if (this.props.transactions.length > 0) {
      let shouldDisable = false;
      let reviewabSelectedTransaction: any = [];
      reviewabSelectedTransaction = Object.values(this.props.transactions[0]);
      shouldDisable = reviewabSelectedTransaction.some(row => {
        return row?.badge === 'Draft' ||
          row?.badge === 'Awaiting Approval' ||
          row?.badge === 'Awaiting Payment';
      });
      this.setState({
        hasDataToConfirm: true, isConfirmDisabled: shouldDisable
      });
    }
    // if (this.props.selectedAccountType === "C") {
    //   this.setState({
    //     addTransactionAction: [
    //       { name: "Add Debit", eventKey: "CreditCardWithdrawalAmount", toShow: 1 },
    //       { name: "Add Credit", eventKey: "CreditCardDepositAmount", toShow: 1 },
    //     ],
    //   })
    // }
  }

  IsTransactionRolePermission = () => {
    let request: any = {};
    request.etype = "Invoice";
    registersevice.IsTransactionRolePermission(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.length > 0) {
            this.setState({ isDraftInvoicePer: result[0].isPermission.toString().toLocaleLowerCase() === "yes" });
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  findTableData = (hasData) => {
    this.setState({
      hasDataToConfirm: hasData,
    });
  };

  onSelection = (e, index, item) => {
    let shouldDisable = false;

    let reviewabSelectedTransaction: any = [];
    reviewabSelectedTransaction = Object.values(item);

    shouldDisable = reviewabSelectedTransaction.some(row => {
      return row.badge === 'Draft' ||
        row.badge === 'Awaiting Approval' ||
        row.badge === 'Awaiting Payment';
    });

    this.setState({ selectedTransaction: index, isConfirmDisabled: shouldDisable });
  };

  handleConfirmTx = (e) => {
    this.confirmBtnRef.current.disabled = true;
    if (this.state.activeTab === "review") {
      if (this.props.transactions.length > 0) {
        this.props.confirmTransation("single", "Completed", [
          this.props.transactions[this.state.selectedTransaction]?.data,
        ]);
      }
      // this.props.saveTransactionStatus(
      //   "single",
      //   "Completed",
      //   this.props.row.syncID,
      //   this.props.row,
      //   this.props.transactions[this.state.selectedTransaction]?.data
      //     .trUniqueNo,
      //   this.props.transactions[this.state.selectedTransaction]?.data.transType,
      //   this.props.transactions[this.state.selectedTransaction]?.data
      //     .updateTable
      // );
      // this.props.saveTransactionStatus("single", "Completed", [
      //   this.props.transactions[this.state.selectedTransaction]?.data,
      // ]);
    } else if (this.state.activeTab === "find") {
      if (this.state.findTabSelectedRows.length === 0) {
        Utils.toastError("Please Select a transaction to confirm.");
        this.confirmBtnRef.current.disabled = false;

        return;
      }
      // else if(this.state.findTabSelectedRows.length > 1){
      //   Utils.toastError("Please select one tranasaction.");
      //   this.confirmBtnRef.current.disabled = false;

      //   return;
      // }
      // this.props.saveTransactionStatus(
      //   "single",
      //   "Completed",
      //   this.state.findTabSelectedRows
      // );
      this.props.confirmTransation(
        "single",
        "Completed",
        this.state.findTabSelectedRows
      );
    }
  };

  enableConfirmBtn = () => {
    if (this.confirmBtnRef.current !== null) {
      this.confirmBtnRef.current.disabled = false;
    }
  }

  resetTableData = () => {
    if (this.BankSyncFindTableRef.current !== null) {
      this.BankSyncFindTableRef?.current.footerBulkClose();
    }
  }

  onRowSelection = (selectedRows) => {
    let shouldDisable = false;

    let findTabSelectedRows: any = [];
    findTabSelectedRows = Object.values(selectedRows);

    shouldDisable = findTabSelectedRows.some(row => {
      return row.badge === 'Draft' ||
        row.badge === 'Awaiting Approval' ||
        row.badge === 'Awaiting Payment';
    });
    this.setState({ findTabSelectedRows: findTabSelectedRows, isConfirmDisabled: shouldDisable });
  };

  openSlideout = (item, index) => {
    let row = item.data;
    if (row.badge.search(/Register/i) !== 0 && row.transCode.search(/invoice/i) !== -1) {
      this.props.OpenInvoiceSlideOut("", row, index, "invoice");
    } else if (row.transCode.search(/transfer/i) !== -1) {
      this.props.hideTrSlideOut(false, row, 0);
    } else if (row.transCode.search(/invoice/i) !== -1) {
      this.props.hideSlideOut(false, row, 0);
    } else {
      this.props.hideSlideOut(false, row, 0);
    }
  };

  setActiveTab = (selectedTab) => {
    if (selectedTab === 'review') {
      if (this.props.transactions.length > 0) {
        this.setState({
          hasDataToConfirm: true,
        });
      }
      else {
        this.setState({
          hasDataToConfirm: false,
        });
      }
    }
    this.setState({ activeTab: selectedTab });
  };

  render() {
    // let showDraftTransaction: boolean = false;
    let isEntryCCA = (this.props.selectedAccountType?.toLowerCase() === "c");
    let isDeposit = ((isEntryCCA && Number(this.props.selectedPlaidAmount) > 0) || this.props?.row?.checkType?.toLowerCase() === "deposit");
    let isDebitAcc = (this.state.addTransactionAction.some((o: any) => o.name?.toLowerCase() === "add debit") && isDeposit);
    let isCreditAcc = (isEntryCCA && Number(this.props.selectedPlaidAmount) < 0 && this.state.addTransactionAction.some((o: any) => o.name?.toLowerCase() === "add credit") && !isDeposit);
    let isWithdrawal = (!isEntryCCA && !isDeposit && (Number(this.props.selectedPlaidAmount) < 0));
    // showDraftTransaction = (isDebitAcc || isCreditAcc || isWithdrawal);

    const handleAddTransaction = (eventKey: any) => {
      let slideoutData: any = {};
      let _isAmount = ((eventKey === "isInvoice" && isEntryCCA) ? (Number(this.props.selectedPlaidAmount) < 0) ? Math.abs(this.props?.row?.plaidAmount) : (Math.abs(this.props?.row?.plaidAmount) * -1) : Math.abs(this.props?.row?.plaidAmount));
      slideoutData.transCode = (eventKey === "isInvoice" ? "invoice" : eventKey);
      slideoutData.trUniqueNo = 0;
      slideoutData.transCode = (eventKey === "isInvoice" ? "invoice" : eventKey);
      slideoutData.syncID = this.props?.row?.syncID;
      slideoutData.description = this.props.manualUploadColumns.Description ? this.props?.row?.description : "";
      slideoutData.transDate = this.props?.row?.date;
      slideoutData.amount = _isAmount;
      slideoutData.isNewTransBankSync = true;
      let isSlideType: any = "";
      if (eventKey === "transfer") {
        if (this.props?.row?.checkType?.toString()?.toLowerCase() === "deposit" || Number(this.props?.row?.plaidAmount) > 0) {
          isSlideType = "Deposit"
        } else if (this.props?.row?.checkType?.toString()?.toLowerCase() === "withdrawal" || Number(this.props?.row?.plaidAmount) < 0) {
          isSlideType = "Withdrawal"
        } else {
          isSlideType = "checkWithdrawal"
        }
      }
      if (eventKey === "isInvoice") {
        if (isDebitAcc) {
          isSlideType = "invoiceDebit";
        } else if (isCreditAcc) {
          isSlideType = "invoiceCredit";
        } else if (isWithdrawal) {
          isSlideType = "invoicewithdrawal";
        }
      }
      slideoutData.slideType = isSlideType;
      this.props.hideSlideOut(false, slideoutData, 0);
    };

    const CheckACPeriodStatusByDate = (eventKey: any) => {
      if (eventKey === "isInvoice") {
        handleAddTransaction(eventKey);
      } else {
        let dateList = this.props?.row?.date;
        let request: any = {};
        request.Date = dateList?.replace(/,(?=\s*$)/, '');
        request.HID = this.props?.row?.Hotelid;
        request.OPRID = this.props.selectedAccount.id;

        InvoiceApprovalS.CheckACPeriodStatusByDateBank(request)
          .then(async (result: any | null) => {
            if (result?.result?.message.toLowerCase() === "success") {
              let closedDats = [] as any;
              closedDats = result?.result?.result.filter((o) => o?.status?.toLowerCase() === "closed");
              if (closedDats.length > 0) {
                if (closedDats[0]?.date == "IsReconcile") {
                  Utils.toastError("The accounting period has already been reconciled; thus, this entry cannot be reconciled.");
                  return;
                }
                else {
                  this.props.showConfirmAlert();
                }
              } else {
                handleAddTransaction(eventKey);
              }
            }
            resolve();
          })
          .catch((error) => {
            reject();
          });
      }
    }
    return (
      <>
        <div className="" ref={this.laborDayDetails}>
          <div className="down-slideout-body">
            {/* <div className="page-heading d-flex"></div> */}

            {this.props.transactionFilterType === "Completed" ?
              <div className="body completed">
                <FormGroup>
                  {this.props.transactions ? (
                    this.props.transactions.map((item, index) => (
                      <>
                        {this.renderMatchedTransactions(item, index)}
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </FormGroup>
              </div> :
              <Tab.Container
                activeKey={this.state.activeTab}
                onSelect={(selectedtab) => this.setActiveTab(selectedtab)}
                id="left-tabs-example"
              // defaultActiveKey="review"
              >
                <div className="nav-container">
                  <Nav className="nav-tabs flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="review">
                        Review ({this.props.transactions.length}){" "}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="find">Find </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="add">Add</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey="review">
                      <div className="body">
                        <FormGroup>
                          {this.props.transactions ? (
                            this.props.transactions.map((item, index) => (
                              <>
                                {this.renderReviewTabBody(item, index)}
                              </>
                            ))
                          ) : (
                            <></>
                          )}
                        </FormGroup>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="find" unmountOnExit>
                      {/* <Suspense
                      fallback={
                        <div className="single-loader single-accbalance-loader loadingAnimation"></div>
                      }
                    > */}
                      <BankSyncFindTable
                        ref={this.BankSyncFindTableRef}
                        onRowSelection={this.onRowSelection.bind(this)}
                        findTableData={this.findTableData.bind(this)}
                        UpdateLoadMoreState={this.UpdateLoadMoreState.bind(this)}
                        {...this.props}
                      />
                      {/* </Suspense> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey="add">
                      <div className="add-tab-content">
                        <Dropdown
                          className="more-action btn-dropdown add-transaction"
                          alignRight
                          onSelect={CheckACPeriodStatusByDate}
                        >
                          <Dropdown.Toggle
                            className="btn btn-primary"
                            id="dropdown-more"
                          >
                            Add Transaction
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {this.state.addTransactionAction.map(
                              (item: any, idx: any) => (
                                <>
                                  {this.returnDropDownItem(item, idx)}
                                </>
                              )
                            )}
                            {/* {this.props.selectedAccountType === "O" && isWithdrawal && (<Dropdown.Item eventKey="transfer">Transfer</Dropdown.Item>)}
                            {(this.props.selectedAccountType !== "M" && this.state.isDraftInvoicePer && showDraftTransaction) && (<Dropdown.Item eventKey="isInvoice">Draft Transactions</Dropdown.Item>)} */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Tab.Pane>
                    {this.state.hasDataToConfirm &&
                      this.state.activeTab !== "add" && (
                        <div className="footer">
                          <div
                            className="fixed-action"
                            style={{ position: "relative" }}
                          >
                            <div className="fa-cont d-flex align-content-center flex-wrap">
                              {/* <div className="mr-auto message">
                          <span>You have unsaved changes</span>
                        </div> */}
                              <button
                                type="button"
                                className="btn btn-primary btn-discard"
                                onClick={() => this.props.expandColapseRow()}
                                disabled={this.state.isLoadMore}
                              >
                                Cancel
                              </button>
                              {this.state.isConfirmDisabled ?
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip id="custom-Tooltip">This transaction has not yet been posted.</Tooltip>
                                  }
                                >
                                  <button
                                    type="button"
                                    ref={this.confirmBtnRef}
                                    className="btn btn-primary"
                                    onClick={this.handleConfirmTx}
                                    disabled={this.state.isConfirmDisabled || this.state.isLoadMore}
                                  >
                                    Confirm
                                  </button>
                                </OverlayTrigger> :
                                <button
                                  type="button"
                                  ref={this.confirmBtnRef}
                                  className="btn btn-primary"
                                  onClick={this.handleConfirmTx}
                                  disabled={this.state.isConfirmDisabled || this.state.isLoadMore}
                                >
                                  Confirm
                                </button>}
                            </div>
                          </div>
                        </div>
                      )}
                  </Tab.Content>
                </div>
              </Tab.Container>
            }
          </div>
        </div>
      </>
    );
  }

  returnDropDownItemold = (item, idx) => {
    let isDisabled = false;
    let isEntryCCA = (this.props.selectedAccountType?.toString()?.toLowerCase() === "c");
    let isAmount = Number(this.props.selectedPlaidAmount);
    let isHideCRDE = (isAmount < 0 && (isEntryCCA ? item?.name?.toLowerCase() === "add credit" : item?.name?.toLowerCase() === "deposit"));
    let isHideDEWI = (isAmount > 0 && (isEntryCCA ? item?.name?.toLowerCase() === "add debit" : item?.name?.toLowerCase() === "withdrawal"));
    isDisabled = (isHideCRDE || isHideDEWI);
    return (
      <Dropdown.Item
        eventKey={item.eventKey}
        key={idx}
        className={isDisabled ? "GrayedOutOption" : ""}
        disabled={isDisabled}
      >
        {item.name}
      </Dropdown.Item>
    )
  }

  returnDropDownItem = (item, idx) => {
    let isDisabled = false;
    item.toShow = 0;
    const AcntType = this.props?.selectedAccountType.toLowerCase();
    const plaidAmount = Number(this.props?.selectedPlaidAmount);
    const itemName = item?.name?.toLowerCase();
    if (AcntType === "c") {
      if (itemName === "add credit" || itemName === "add debit" || itemName === "draft transactions") item.toShow = 1;
      if ((plaidAmount > 0 && itemName === "add debit") || (plaidAmount < 0 && itemName === "add credit")) isDisabled = true;
    }
    else if (AcntType === "o" || AcntType === "p") {
      if(AcntType === "o"){
        if (((itemName === "deposit" || itemName === "withdrawal") && plaidAmount > 0) || ((itemName === "deposit" || itemName === "withdrawal" || itemName === "draft transactions" || itemName === "transfer") && plaidAmount < 0)) item.toShow = 1;
       } else if (((itemName === "deposit" || itemName === "withdrawal") && plaidAmount > 0) || ((itemName === "deposit" || itemName === "withdrawal" || itemName === "draft transactions") && plaidAmount < 0)) item.toShow = 1;
      if ((plaidAmount > 0 && itemName === "withdrawal") || (plaidAmount < 0 && itemName === "deposit")) isDisabled = true;
    } else {
      if (((itemName === "deposit" || itemName === "withdrawal") && plaidAmount > 0) || ((itemName === "deposit" || itemName === "withdrawal") && plaidAmount < 0)) item.toShow = 1;
      if ((plaidAmount > 0 && itemName === "withdrawal") || (plaidAmount < 0 && itemName === "deposit")) isDisabled = true;
    }

    if (item.toShow == 1)
      return (
        <Dropdown.Item
          eventKey={item.eventKey}
          key={idx}
          className={isDisabled ? "GrayedOutOption" : ""}
          disabled={isDisabled}
        >
          {item.name}
        </Dropdown.Item>
      )
  }

  renderReviewTabBody = (item, index) => {
    const groupInvInfo = "There are multiple transactions associated with this group. Please view these transactions from the Register page.";
    // debugger
    const isCheck = +(item?.data?.checkType) > 0;
    const isCheckNo = +(item?.data?.checkNo) > 0
    const isShowCheckNo = Math.abs(item?.data?.amount) == Math.abs(item?.data?.plaidAmount);
    const isGroupes = item.data.groupUniqueNo.split(",");
    return (
      <>
        <div className="form-check">
          <input
            className="form-check-input position-static"
            type="radio"
            id={`default-${index}`}
            onChange={(e) => this.onSelection(e, index, item)}
            checked={
              this.state.selectedTransaction === index
            }
          />
          {item.data.groupUniqueNo !== "" && (
            <>
              {isGroupes.length > 1 ?
                <OverlayTrigger placement="top" overlay={<Tooltip id="infoIcon">{groupInvInfo}</Tooltip>} >
                  <span
                    className="form-check-label"
                    onClick={() => this.openSlideout(item, index)}
                  >
                    {item.name}
                    {isCheckNo && isCheck && isShowCheckNo ? ", Check Number: " + item?.data?.checkNo : ""}
                    <span className="badges color-gray lft-5">{item.data.badge}</span>
                  </span>
                </OverlayTrigger> :
                <span
                  className="form-check-label"
                  onClick={() => this.openSlideout(item, index)}
                >
                  {item.name}
                  {isCheckNo && isCheck && isShowCheckNo ? ", Check Number: " + item?.data?.checkNo : ""}
                  <span className="badges color-gray lft-5">{item.data.badge}</span>
                </span>}
            </>
          )}
          {item.data.groupUniqueNo == "" && (
            <span
              className="form-check-label"
              onClick={() => this.openSlideout(item, index)}
            >
              {item.name}
              {isCheckNo && isCheck && isShowCheckNo ? ", Check Number: " + item?.data?.checkNo : ""}
              <span className="badges color-gray lft-5">{item.data.badge}</span>
            </span>
          )}
        </div>
      </>
    );
  }

  renderMatchedTransactions = (item, index) => {
    return (
      <div className="form-check">
        <span
          className="form-check-label"
          onClick={() => this.openSlideout(item, index)}
        >
          {item.name}
        </span>
      </div>
    )
  }

  UpdateLoadMoreState = (state) => {
    this.setState({
      isLoadMore: state, selectedTransaction: 0,
      findTabSelectedRows: []
    }, () => {
      if (this.BankSyncFindTableRef.current !== null) {
        this.BankSyncFindTableRef?.current.footerBulkClose();
      }
    });
  }

  ClearSelection = () => {
    this.setState({ findTabSelectedRows: [], selectedTransaction: 0 });
  }
}
