import React from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Row,
} from "react-bootstrap";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import { Utils } from "../../../Common/Utilis";
import { ILaborPTORequest } from "./../../../Common/Contracts/ILaborPTOCalculation";
import Joi from "joi-browser";
import moment from "moment";
import Calendar from "react-calendar";
import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";

export class AddPTOAdjustment extends React.Component<any, any> {
  private ptoAdjustmentFormSchema = Joi.object({
    employee: Joi.string(),
    ptoType: Joi.number(),
    date: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select date" };
        });
      }),
    pto: Joi.string().optional().allow(""),
   HH: Joi.string().optional().allow(""),
   MM:Joi.string().optional().allow(""),
    notes: Joi.string().required()
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Please enter notes" };
      });
    }),
  });
  state: any = {
    errors: {},
    isFormNotValid: true,
    inputPosition: 0,
    calenderShow: false,
    isDataSaving: false,
    ptoAdjustment: {
      date:  moment().format("MM/DD/YY"),
      pto: "",
      ptoType: this.props.parentData.ptoUniqueNo,
      notes: "",
      employee: this.props.parentData.userName,
      HH:"",
      MM:""
    },
  };






  changebreakHH = (e: any, inputPosition: number) => {
 
      let data = _.cloneDeep(this.state.ptoAdjustment);
if(!(/^-?\d+$/i.test(e.target.value)) && e.target.value!=="-" && e.target.value) {
  return false;
}
      data.HH= e.target.value;
      this.setState({ ptoAdjustment: data ,inputPosition},()=> {
        this.setPtoHours();
      });
    
  };

  blurbreakHH = (e: any,inputPosition: number) => {
 
      let data = _.cloneDeep(this.state.ptoAdjustment);

      data.HH=  e.target.value==='' ? "00" : e.target.value?.
      toString()?.padStart(2, "0")
       ;
      this.setState({ ptoAdjustment: data,inputPosition },()=> {
        this.setPtoHours();
      });
    
  };
  setPtoHours = ()=> {
    let data = _.cloneDeep(this.state.ptoAdjustment);

    data.pto= data?.HH?.
      toString()?.padStart(2, "0") + ":"+
      data?.MM?.
      toString()?.padStart(2, "0");
      this.setState({ ptoAdjustment: data }, ()=> {
        this.validatePTOAdjustmentFormSchema();
      });
    
  }
  changebreakMM = (e: any, inputPosition: number) => {
    if (Number(e.target.value) < 60) {
 
      let data = _.cloneDeep(this.state.ptoAdjustment);


      if (!data.HH) {
        data.HH = "00";
      }
      data.MM = e.target.value;;
      this.setState({ ptoAdjustment: data ,inputPosition},()=> {
        this.setPtoHours();
        
      });
    }
    
  };

  blurbreakMM = (e: any, inputPosition: number) => {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.ptoAdjustment);
      if (!data.HH) {
        data.HH = "00";
      }
      data.MM = Number(e.target.value)>0 ? e.target.value?.
      toString()?.padStart(2, "0") :"00"
       ;

      this.setState({ ptoAdjustment: data,inputPosition },()=> {
        this.setPtoHours();
      });
    }
  };

  onPTOBlur = (value) => {
    const { ptoAdjustment } = this.state;
    const negativeValue = value?.toString()?.includes("-") ? "-" : "";
    value = value?.toString()?.replace("-", "");
    let newValue =value;
    if (value?.toString()?.includes(":")) {
      const valueArray = value?.split(":");
      if (valueArray[1]?.length === 1) {
        newValue =
          valueArray[0]?.toString() +
          ":" +
          valueArray[1]?.toString()?.padStart(2, "0");
      }
    }

    ptoAdjustment.pto =negativeValue +newValue;
    this.setState({ ptoAdjustment });
  };
  onFieldChange = (event, inputPosition) => {
    const { ptoAdjustment } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if(fieldName === "notes"){
      value =typeof value === 'string' ? value?.toString()?.trimLeft() :value;
    }
    if (fieldName === "pto") {
      const _ptoAdjustment = _.cloneDeep(this.state.ptoAdjustment);
      const altValue =
        value  ? value : "";
      if (!Utils.GetHHMMValidString(value,true,7) && value) {
        return;
      }
      value = Utils.GetHHMMValidString(value, true,7) || altValue;

      if (value?.toString()?.includes(":")) {
        const valueArray = value?.split(":");

        if (+valueArray[1] > 59) {
          value =
            valueArray[0]?.toString() +
            ":" +
            valueArray[1]?.toString()?.substr(0, 1);
        }
      }
    }

    ptoAdjustment[fieldName] = value;
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({ inputPosition });
    }
    this.setState({ ptoAdjustment }, () =>
      this.validatePTOAdjustmentFormSchema()
    );
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };

  savePTOAdjustment = () => {
    const data: any = this.state.ptoAdjustment;

  // const ptoStartingBalance=  Utils.getMinutesFromHHMMWithPositiveNegative(this.props.parentData?.ptoStartingBalance) ;
  // if(data.pto?.toString()?.includes("-")) {
    // let pto = Utils.getMinutesFromHHMMWithPositiveNegative(data.pto.replace("-",""));
    // pto = pto * -1
  //   if((ptoStartingBalance+pto) <0) {
  //      Utils.toastError("Current PTO Balance + Adjusted PTO balance total can not be negative", {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //         containerId: "addPTOAdjustment"
  //      });
  //      return;
  //  }
  // }
    
    data.pto = data.pto?.replace(":", ".");

  


    const request: ILaborPTORequest = { ...data } as ILaborPTORequest;
    this.setState({isDataSaving: true});
    UserManagement.SavePTOAdjustment(request)
      .then((result) => {
        this.setState({isDataSaving: false});
        if (result?.message === "Success") {
          toast.success("PTO adjustment successfully added", {
             position: toast.POSITION.BOTTOM_RIGHT,
             containerId: "addPTOAdjustment"
          });
          this.props?.getPTOAdjustedDetails(this.props.parentData.ptoUniqueNo);
          $('#ddlAddAdjustment')?.click();
        } else {
          Utils.toastError(result?.result?.message, {
             position: toast.POSITION.BOTTOM_RIGHT,
             containerId: "addPTOAdjustment"
          });
        }
      })
      .catch((error) => {
        this.setState({isDataSaving: false});
        Utils.toastError(error?.message, {
           position: toast.POSITION.BOTTOM_RIGHT,
           containerId: "addPTOAdjustment"
        });
      });
  };

  validatePTOAdjustmentFormSchema = () => {
    const valid = Joi.validate(
      this.state.ptoAdjustment,
      this.ptoAdjustmentFormSchema,
      {
        abortEarly: false,
      }
    );
    const newErrorObject:any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
  if(!Number(this.state.ptoAdjustment.HH) &&  !Number(this.state.ptoAdjustment.MM)) {
    newErrorObject.pto= "Please enter minutes.";
  } 
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };
  componentWillUnmount=()=> {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };
  openModal = (e) => {
   
    let ptoAdjustment: any = {};
    ptoAdjustment = {
      date:  moment().format("MM/DD/YY"),
      pto: "",
      ptoType: this.props.parentData.ptoUniqueNo,
      notes: "",
      employee: this.props.parentData.userName,
      HH:"",
      MM:""
    };
    this.setState({ ptoAdjustment,  errors: {},
      isFormNotValid: true,
      inputPosition: 0,
      calenderShow: false,
      isDataSaving: false, });
 
  };
 
  render() {
    const { ptoAdjustment,isDataSaving, isFormNotValid, errors, inputPosition } = this.state;
    return (
      <div className="add-pto-adjustment-wrapper">
        <Dropdown className="more-action add-pto-adjustment-drp" alignRight>
          <Dropdown.Toggle disabled={ +this.props.parentData?.status===0 ? true:false}
            className="btn-outline-primary inner-sec-btn more"
            id="ddlAddAdjustment"
          >
            <div  onClick={(e: any) =>  this.openModal(e)}>
              Add PTO Adjustment
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="pos-name">
              <Dropdown.Item className="cross-btn" href="">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                    fill="#84888C"
                  />
                </svg>
              </Dropdown.Item>
            </div>
            <div className="re-assign-modal">
              <div className="">
                <div className="title">Add PTO Adjustment</div>
                <div className="entry-body">
                  <Form.Group as={Row} controlId="time-picker">
                    <Col
                      sm="6"
                      className={
                        errors?.date && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Date</Form.Label>
                      <div className="validate-input d-flex flex-column">
                        <div className="calender-container">
                          <div
                            className={
                              this.state.calenderShow
                                ? "calender-display open"
                                : "calender-display"
                            }
                            onClick={this.calenderShow}
                          >
                            <input
                              type="text"
                              // disabled={!userGeneralInfo?.hasEditContactInfo}
                              placeholder="MM/DD/YY"
                              name="date"
                              autoComplete ="date"
                              value={ptoAdjustment.date}
                              className="form-control single-date-pick"
                            />
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                              <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                            </svg>
                          </div>
                          {this.state.calenderShow && (
                            <div className="calender">
                              <OutsideClickHandler
                                onOutsideClick={this.calenderHide}
                              >
                                <Calendar
                                  calendarType={"US"}
                                  onChange={(date) => {
                                    const value = moment(date).format(
                                      "MM/DD/YY"
                                    );
                                    this.calenderHide();
                                    this.onFieldChange(
                                      Utils.BuildCustomEventObject(
                                        "date",
                                        value
                                      ),

                                      1
                                    );
                                    this.calenderHide();
                                  }}
                                  value={
                                    ptoAdjustment.date
                                      ? new Date(ptoAdjustment.date)
                                      : new Date()
                                  }
                                  minDate={
                                    this.props?.hireDate
                                      ? new Date(
                                          moment(this.props?.hireDate).format(
                                            "MM/DD/YY"
                                          )
                                        )
                                      : undefined
                                  }
                                />
                              </OutsideClickHandler>
                            </div>
                          )}
                        </div>

                        {this.getFieldError("date", 1)}
                      </div>
                    </Col>
                    <Col
                      sm="6"
                      className={
                        errors?.pto && inputPosition >= 2
                          ? "validation-error-hours-input"
                          : ""
                      }
                    >
                        <Form.Label>PTO</Form.Label>
                    <div className="d-flex modf-input justify-content-center align-items-center input-border">
                                    <Form.Control
                                      type="text"
                                      onKeyDown={Utils.AllowOnlyPositiveNegativeNumnberInputWithoutDot}
                                      maxLength={4}
                                      placeholder="HH"
                                      id="HH"
                                      value={ptoAdjustment.HH}
                                      onBlur={(e: any) =>
                                        this.blurbreakHH(e,2 )
                                      }
                                      onChange={(e: any) =>
                                        this.changebreakHH(
                                          e,
                                          2
                                        )
                                      }
                                    />
                                    <span className="mx-2">:</span>
                                    <Form.Control
                                      type="text"
                                      onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                                      maxLength={2}
                                      placeholder="MM"
                                      id="MM"
                                      value={ptoAdjustment.MM}
                                      onBlur={(e: any) =>
                                        this.blurbreakMM(e,2 )
                                      }
                                      onChange={(e: any) =>
                                        this.changebreakMM(
                                          e,2
                                        )
                                      }
                                    />
                                  </div>
                                  {this.getFieldError("pto", 2)}
                                  </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="statistic">
                    <Col sm="12"  className={
                        errors?.notes && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }>
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        onBlur={()=> {
                          this.setState({inputPosition:3},()=> {
                            this.validatePTOAdjustmentFormSchema();
                          });
                        }}
                        name="notes"
                        value={ptoAdjustment.notes}
                        onChange={(event: any) => this.onFieldChange(event, 3)}
                        placeholder="Comments..."
                      />
                           {this.getFieldError("notes", 3)}
                    </Col>
                
                  </Form.Group>
                </div>
                <div className="action-link">
                  <Button
                    className="btn btn-primary"
                    disabled={isFormNotValid || isDataSaving}
                    onClick={this.savePTOAdjustment}
                  >
                    Add Adjustment
                  </Button>
                </div>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
