import React from "react";
import { Modal, Button, Col, Row, Dropdown, Spinner, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "../BankSync/BankWizard.scss";
import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import _ from "lodash";
import { accountService } from "../../../Common/Services/account";
import { resolve, reject } from "q";
import { PlaidSetup } from "../../../Common/ThirdPartyServices/PlaidSetup";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment";
import { Utils } from "../../../Common/Utilis";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";

let isProcessRunning = false;
let isValidDate = true;
export class BankSyncWizard extends React.Component<any, any> {
    private connectionName;
    constructor(props: any) {
        super(props);
        this.connectionName = React.createRef();
        this.state = {
            step: 0,
            bankName: "",
            connectionName: "",
            institutionList: [],
            allInstitutionList: [],
            nickNameList: [],
            selectedBank: [{ RowID: -1, ID: -1, OprID: -1, accDisplayName: "No Mapping", displayName: "No Mapping", CanSelect: true }],
            isLoading: false,
            spinner: false,
            plaidInitiate: false,
            bankDetails: [],
            accountMapping: [],
            actionType: "",
            connectionList: [],
            selectedConnection: {},
            isShowAddButton: true,
            updatedToken: "",
            callType: "",
            reconnectSpinner: false,
            isLastAccountRemoved: false,
            innerLoader: false,
            pullTranDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            pullTransDays: "90",
            advAccSubscriptionDate: "",
            isMappingSaved: false,
            calenderShow: false,
        }
    }

    clearState = () => {
        this.setState({
            bankName: "",
            connectionName: "",
            institutionList: [],
            allInstitutionList: [],
            nickNameList: [],
            selectedBank: [{ RowID: -1, ID: -1, OprID: -1, accDisplayName: "No Mapping", displayName: "No Mapping", CanSelect: true }],
            spinner: false,
            plaidInitiate: false,
            bankDetails: [],
            accountMapping: [],
            actionType: "",
            connectionList: [],
            selectedConnection: {},
            isShowAddButton: true,
            updatedToken: "",
            callType: "",
            reconnectSpinner: false,
            isLastAccountRemoved: false,
            innerLoader: false,
        });
    }

    componentDidMount(): void {
        if (this.props?.callFrom?.toLowerCase() === "banksync") {
            this.setState({ reconnectSpinner: true });
            this.InitializePlaidUpdateMode(this.props.oprID);
        } else {
            this.setState({ isLoading: true });
            let request = {} as any;
            request.HID = this.props?.hotelID;
            this.getInstituteData(request);
        }
    }

    // Method to get the Institute data by hid, tenentid and username
    getInstituteData = (request: any) => {
        accountService.GetInstitutionName(request)
            .then(async (result: any) => {
                if (result.message === "Success") {
                    if (result.result?.length > 0) {
                        let data = result.result;
                        let newdata: any = _.uniqBy(data, o => o.bName?.toLowerCase()?.trim());
                        newdata = _.orderBy(newdata, [o => o.bName?.toLowerCase()?.trim()], ['asc']);

                        const pullTransDays = data[0].pullTransDays;
                        const pullTraDate = new Date(new Date().setDate(new Date().getDate() - 1));
                        const newDate = new Date(pullTraDate.toLocaleDateString("en-US"));
                        const strMonth = Number(newDate.getMonth()) + 1;
                        const strYear = newDate.getFullYear();
                        let pullDate = strMonth + "/01/" + strYear;

                        this.setState({
                            institutionList: newdata, //.filter((items) => items?.status?.toLowerCase() === "active"),
                            allInstitutionList: data,
                            pullTransDays: pullTransDays,
                            isMappingSaved: false,
                            pullTranDate: moment(pullDate).format("MM/DD/YY"),
                        });
                        resolve();
                    }
                } else {
                    Utils.toastError(result?.result, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.wizardClose();
                }
            })
            .catch((error: any) => {
                reject();
            })
            .finally(() => {
                this.setState({ isLoading: false, innerLoader: false }, () => {
                    isProcessRunning = false;
                });
            });
    };

    // This will call when user wants to Connect/Modify/continue setup
    connectModify = (items: any) => {
        const bName = items.bName?.trim();
        if (items.bankConnectionStatus?.toLowerCase() === "yes" || items.pendingSetup?.toLowerCase() === "yes") {
            this.setState({ isLoading: true });
            this.GetConnectionList(bName);
        } else {
            const nickName = this.filterByBank(bName);
            setTimeout(() => {
                this.connectionName.current.focus();
            }, 0);
            this.setState({ step: 1, bankName: bName, nickNameList: nickName, actionType: "Connect" });
        }
    }

    //This fun will return the connection list which was earlier created for the selected bank
    GetConnectionList = (BankName) => {
        let req = {} as any;
        req.BankName = BankName?.toLowerCase();
        accountService.GetConnectionList(req)
            .then(async (result: any) => {
                if (result.message === "Success") {
                    let data = result?.result;
                    for (let n = 0; n < data?.length; n++) {
                        data[n]["hasSelected"] = n == 0;
                        data[n]["deleteSpinner"] = false;
                    }
                    let { allInstitutionList } = this.state;
                    let nickName = allInstitutionList.filter((o) => o.bName?.toLowerCase()?.trim() === BankName?.toLowerCase()?.trim());
                    const totalMapAccounts = _.sumBy(data, 'oprIDCount');
                    let connectionDetail = {} as any;
                    connectionDetail.connectionID = data[0]?.connectionID;
                    connectionDetail.connectionname = data[0]?.connectionname;
                    connectionDetail.oprIDs = data[0]?.oprIDs;
                    connectionDetail.accCount = data[0]?.oprIDCount;
                    this.setState({
                        connectionList: data,
                        actionType: "AddManage",
                        step: 1,
                        bankName: BankName,
                        connectionName: data[0]?.connectionname,
                        selectedConnection: connectionDetail,
                        isShowAddButton: !(nickName?.length == totalMapAccounts),
                    });
                    resolve();
                } else {
                    Utils.toastError(result?.result, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((error: any) => {
                reject();
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    //Change method for Connection name and account selection checkboxes
    handleChange = (controlID, e, items: any, index: any) => {
        let { name, value } = e.target;
        if (controlID === 1) {
            if (value.trim() !== "") {
                value = value.trimLeft()
            }
            this.setState({ connectionName: value })
        } else if (controlID === 2) {
            if (!items.isDisabled) {
                let { selectedBank, nickNameList } = this.state;
                let arr = {} as any;
                arr.ID = index;
                arr.RowID = index;
                arr.OprID = items.oprid;
                arr.accDisplayName = items.accDisplayName;
                arr.displayName = items.displayName;
                arr.CanSelect = true;
                if (e.currentTarget.checked) {
                    selectedBank.push(arr);
                } else {
                    selectedBank.splice(selectedBank.findIndex((a) => a.OprID === items.oprid), 1);
                }
                nickNameList.filter((o) => o.oprid === items.oprid)[0].isSelect = e.currentTarget.checked;
                this.setState({ nickNameList, selectedBank });
            }
        }
    }

    //This will call when user click on next button. This will also initiate the plaid workflow
    handleNext = async () => {
        let { step, connectionName, bankName, selectedBank, actionType, selectedConnection } = this.state;
        if (step < 4) {
            if (connectionName?.trim() === "" && actionType == "Connect") {
                this.connectionName?.current?.focus();
                return;
            }
            if (step === 1) {
                let { selectedBank, connectionList } = this.state;
                const nickName = this.filterByBank(bankName);
                const disabledOptionList = connectionList.filter((o) => o.connectionname != connectionName); //[0]?.oprIDs?.split(',');
                const disabledOprIds = _.map(disabledOptionList, 'oprIDs');

                if (actionType == "Connect") {
                    try {
                        this.setState({ spinner: true });
                        let request = {} as any;
                        request.ConnectionName = connectionName?.trim();
                        request.BankName = bankName?.trim();

                        const result: any = await accountService.checkConnectionName(request);
                        if (result?.message === "success") {
                            if (result.result === "Exist") {
                                Utils.toastError("Connection name already exist.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                                this.setState({ spinner: false });
                                this.connectionName?.current?.focus();
                                return;
                            }

                            disabledOprIds.forEach(el => {
                                const splitID = el?.split(',');
                                for (let n = 0; n < splitID?.length; n++) {
                                    var idx = nickName.findIndex((a) => a.oprid === +(splitID[n]));
                                    if (idx > -1)
                                        nickName[idx]["isDisabled"] = true;
                                }
                            });
                            this.setState({ spinner: false, nickNameList: nickName, selectedBank });
                        } else {
                            Utils.toastError("Something went wrong.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            this.connectionName?.current?.focus();
                            this.setState({ spinner: false });
                            return;
                        }
                    } catch (error) {
                        this.setState({ spinner: false });
                    }
                } else if (actionType == "AddManage") {
                    const oprIds = connectionList.filter((o) => o.connectionname == connectionName)[0]?.oprIDs?.split(',');

                    disabledOprIds.forEach(el => {
                        const splitID = el?.split(',');
                        for (let n = 0; n < splitID?.length; n++) {
                            var idx = nickName.findIndex((a) => a.oprid === +(splitID[n]));
                            if (idx > -1)
                                nickName[idx]["isDisabled"] = true;
                        }
                    });

                    for (let d = 0; d < oprIds?.length; d++) {
                        if (oprIds[d] != "") {
                            var idx = nickName.findIndex((a) => a.oprid === +(oprIds[d]));
                            if (idx > -1) {
                                nickName[idx]["isSelect"] = true;
                                nickName[idx]["isDisabled"] = true;
                            }

                            const element = nickName.filter((o) => o.oprid == +(oprIds[d]));
                            if (element?.length > 0) {
                                let arr = {} as any;
                                arr.ID = d;
                                arr.RowID = d;
                                arr.OprID = element[0]?.oprid;
                                arr.accDisplayName = element[0]?.accDisplayName;
                                arr.displayName = element[0]?.displayName;
                                arr.CanSelect = true;
                                selectedBank.push(arr);
                            }
                        }
                    }
                    this.setState({ nickNameList: nickName, selectedBank });
                }
            } else if (step === 2) {
                this.setState({ plaidInitiate: true, spinner: true });
                if (actionType === "Connect") {
                    const selectedOprIDs = (selectedBank.filter((o) => o?.OprID != -1)).map((o) => o.OprID).join(",");
                    PlaidSetup.LinkBankAccountPlaid(connectionName?.trim(), bankName, selectedOprIDs, this.stopLoader);
                    return;
                } else {
                    PlaidSetup.InitializePlaidUpdateMode(selectedConnection?.connectionID, 'Modify', this.setUpResponse);
                    return;
                }
            }
            this.setState({ step: (step + 1) });
        }
    }

    //Called this method from bank sync page to reconnect the bank which has the connection error
    InitializePlaidUpdateMode = (oprID) => {
        this.setState({ reconnectOprID: oprID });
        PlaidSetup.InitializePlaidReconnect(oprID, 'Reconnect', this.finishReconnect);
        return;
    }

    finishReconnect = (stage, token) => {
        if (stage == "Exit" || stage == "Fail" || stage == "tokenNotFound" || stage == "SomeError") {
            this.setState({ reconnectSpinner: false });
            this.props.showBankSyncWizard();
            if (stage == "tokenNotFound")
                Utils.toastError("Invalid token details.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "SetupBankSync",
                });
        }
        else if (stage == "success") {
            PlaidSetup.UpdateBankConnectionStatus(token, this.state.reconnectOprID, this.reloadPage);
        }
    }

    reloadPage = (responseStatus) => {
        this.setState({ reconnectSpinner: false });
        if (responseStatus == "success") {
            this.wizardClose();
            this.props.reloadPage(2);
        }
    }

    //This will call to chnage the plaid response as per UI
    setUpResponse = (stage, token: any, response: any) => {
        if (stage == "Exit" || stage == "Fail" || stage == "tokenNotFound" || stage == "LoginExpired" || stage == "SomeError" || stage == "ITEM_NOT_FOUND") {
            this.setState({ plaidInitiate: false, spinner: false });
        } else if (stage == "startLoader") {
            this.setState({ isLoading: true });
        }
        else {
            this.setState({ updatedToken: token });
            this.changeResponse(response, "", "ModifyConn");
            console.log("ModifyConn", response)
        }
    }

    //After successfully connection this will call and chnage the response for mapping the bank accounts
    stopLoader = (stage, response: any) => {
        if (stage == "Exit" || stage == "Fail") {
            this.setState({ plaidInitiate: false, spinner: false });
            this.wizardClose();
        } else if (stage == "startLoader") {
            this.setState({ isLoading: true });
        } else if (stage == "DuplicateMapping") {
            this.setState({ plaidInitiate: false, spinner: false, isLoading: false }, () => {
                this.wizardClose();
            });
        } else if (stage == "InvalidClient") {
            Utils.toastError("Invalid client or secret id provided.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState({ plaidInitiate: false, spinner: false });
        }
        else {
            this.setState({ isLoading: true });
            this.changeResponse(response, "", "connect");
        }
    }

    //API response change in this method
    changeResponse = (response: any, bankName, setupType) => {
        let { allInstitutionList, selectedBank, accountMapping, bankDetails } = this.state;
        try {
            let oldSelectedAccounts = response[1];
            if (setupType == "ModifyConn") {
                response = response[0];
            }
            if (setupType === "pending") {
                const oprIDs = response?.accounts[0].oprIds.split(',');
                oprIDs.forEach((element, idx) => {
                    let arr = {} as any;
                    const bankAcntDetails = allInstitutionList.filter((o) => o.oprid === +(element));
                    arr.ID = idx;
                    arr.RowID = idx;
                    arr.OprID = +(element);
                    arr.accDisplayName = bankAcntDetails[0].accDisplayName;
                    arr.displayName = bankAcntDetails[0].displayName;
                    arr.CanSelect = true;
                    selectedBank.push(arr);
                });
            }

            let res = response?.accounts;
            localStorage.setItem("selectedBank", btoa(JSON.stringify(selectedBank)));
            let sBank = [] as any;
            for (let n = 0; n < res?.length; n++) {
                sBank = [];
                sBank = JSON.parse(atob(localStorage.getItem("selectedBank")!));
                for (let d = 0; d < sBank?.length; d++) {
                    sBank[d].ID = "";
                    sBank[d].ID = sBank[d].RowID + '_' + n;
                }
                res[n]["AccountName"] = "No Mapping";
                res[n]["OPRID"] = "";
                res[n]["AccountList"] = sBank;
            }

            if (setupType === "ModifyConn") {
                oldSelectedAccounts.forEach((element, idxx) => {
                    const idx = res.findIndex((o) => o.account_id === element.accountID);
                    if (idx > -1) {
                        const bankAcntDetails = selectedBank.filter((o) => o.OprID === +(element.oprid));
                        res[idx]["AccountName"] = bankAcntDetails[0]?.accDisplayName == undefined ? "No Mapping" : bankAcntDetails[0]?.accDisplayName;
                        res[idx]["OPRID"] = bankAcntDetails[0]?.accDisplayName == undefined ? "" : +(element.oprid);

                        let oprIdx;
                        res.map((val, i) => {
                            oprIdx = res[i].AccountList.findIndex((o) => o.OprID === +(val.OPRID));
                            res[i].AccountList.map((itm, idx) => {
                                if (itm.OprID != -1 && itm.OprID === +(element.oprid)) {
                                    itm.CanSelect = false;
                                }
                            });
                        });
                        const oprIdxEne = res[idx].AccountList.findIndex((o) => o.OprID === +(bankAcntDetails[0].OprID));
                        if (oprIdxEne != -1 && res[idx] !== undefined)
                            res[idx].AccountList.filter((o) => o.ID === res[idx]?.AccountList[oprIdxEne]?.ID)[0].CanSelect = true;
                    }
                    //#region <Old Code>
                    // const bankAcntDetails = selectedBank.filter((o) => o.OprID === +(element.oprid));
                    // var idx = res.findIndex((a) => a.account_id === element.accountID);
                    // if (idx > -1) {
                    //     res[idx]["AccountName"] = bankAcntDetails[0]?.accDisplayName == undefined ? "No Mapping" : bankAcntDetails[0]?.accDisplayName;
                    //     res[idx]["OPRID"] = bankAcntDetails[0]?.accDisplayName == undefined ? "" : +(element.oprid);
                    // }

                    // let oprIdx;
                    // res.map((val, i) => {
                    //     oprIdx = res[i].AccountList.findIndex((o) => o.OprID === +(val.OPRID));
                    //     res[i].AccountList.map((itm, idx) => {
                    //         debugger
                    //         if (itm.OprID != -1 && itm.OprID === +(element.oprid)) {
                    //             itm.CanSelect = false;
                    //         }
                    //     });
                    // });
                    // if (oprIdx != -1 && res[idx] !== undefined)
                    //     res[idx].AccountList.filter((o) => o.ID === res[idx]?.AccountList[oprIdx]?.ID)[0].CanSelect = true;

                    //#endregion
                    if (idx > -1) {
                        let arrMap = {} as any;
                        arrMap.OPRID = +(element.oprid);
                        arrMap.accountID = element.accountID;
                        accountMapping.push(arrMap);
                        this.setState({ accountMapping });
                    }
                });
            }

            if (setupType === "pending")
                this.setState({
                    selectedBank, bankName: bankName,
                    connectionName: response?.accounts[0].connectionName,
                    bankDetails: response?.accounts,
                    isLoading: false,
                    callType: setupType
                });
            else
                this.setState({
                    step: (this.state.step + 1),
                    plaidInitiate: false,
                    spinner: false,
                    bankDetails: response?.accounts,
                    callType: setupType,
                    isLoading: false
                });
        } catch (error) {
            accountService.LogConnectionProcess(response[0]?.accounts[0]?.oprIds, `ChangeResponse`, JSON.stringify(response) + '</break>' + JSON.stringify(selectedBank), (error as any)?.message);
            Utils.toastError((error as any)?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState({
                plaidInitiate: false,
                spinner: false,
                isLoading: false
            });
        }
    }

    //Calls when user hit the back button
    handleBack = () => {
        if (!this.state.plaidInitiate) {
            this.setState({ step: (this.state.step - 1), spinner: false });
            if (this.state.actionType == "Connect")
                setTimeout(() => {
                    if (this.state.step === 1)
                        this.connectionName.current.focus();
                }, 0);
            else if (this.state.actionType == "AddManage")
                this.setState({ selectedBank: [] });
        }
    }

    //On the mapping step this will call for map the accounts to the plaid accounts
    handleAccountMapping = (items, mainItems, rowIndex) => {
        const oldSelectedOPrID = mainItems.OPRID;
        let { bankDetails, accountMapping } = this.state;
        mainItems.AccountName = items.accDisplayName;
        mainItems.OPRID = items.OprID;

        let arrMap = {} as any;
        arrMap.OPRID = items.OprID;
        arrMap.accountID = mainItems.account_id;
        if (items.OprID !== -1) {
            if (oldSelectedOPrID !== "" && oldSelectedOPrID != -1)
                accountMapping.splice(accountMapping.findIndex((a) => a.OPRID === oldSelectedOPrID), 1);

            accountMapping.push(arrMap);
        }
        else {
            if (oldSelectedOPrID !== "" && oldSelectedOPrID != -1)
                accountMapping.splice(accountMapping.findIndex((a) => a.OPRID === oldSelectedOPrID), 1);
        }

        this.setState({ accountMapping });
        bankDetails.map((val, i) => {
            if (i != rowIndex) {
                bankDetails[i].AccountList.map((itm, idx) => {
                    if (itm.OprID != -1 && itm.OprID === items.OprID) {
                        itm.CanSelect = false;
                    }
                    else if (oldSelectedOPrID !== "" && itm.OprID != -1 && oldSelectedOPrID === itm.OprID) {
                        itm.CanSelect = true;
                    }
                });
            }
        });
        bankDetails[rowIndex].AccountList.filter((o) => o.ID === items.ID)[0].CanSelect = true;
    }

    //This will call to save the accounts mapping in db.
    finishSetup = () => {
        this.setState({ spinner: true });
        let { bankName, connectionName, accountMapping, callType } = this.state;
        let saveReq = {} as any;
        saveReq.BankName = bankName;
        saveReq.ConnectionName = connectionName;
        saveReq.Action = callType === "ModifyConn" ? "Update" : "New";
        saveReq.AccessToken = this.state.updatedToken;
        let oprAccMap: any = [];
        accountMapping.forEach((item, index) => {
            let request: any = {};
            request.OprID = item?.OPRID;
            request.accountID = item?.accountID;
            oprAccMap.push(request);
        });
        saveReq.AccountMap = oprAccMap;

        console.log(saveReq)

        accountService.SaveBankPlaidMapping(saveReq)
            .then(async (result: any) => {
                let msg = callType === "ModifyConn" ? "Plaid mapping updated successfully." : "Plaid mapping successfully completed.";
                if (result.message === "Success") {
                    if (result?.result) {
                        toast.success(msg, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });

                        const advAccDate = new Date(result?.messageCode);
                        const MinSelectedDate = new Date(new Date().setDate(new Date().getDate() - +(this.state.pullTransDays)));
                        //let advAccSubscriptionDate: any; let minDate: any;
                        // if (advAccDate < MinSelectedDate) {
                        //     minDate = MinSelectedDate;
                        // } else {
                        //     minDate = (advAccDate.getMonth()) + 1 + `/01/` + advAccDate.getFullYear();
                        // }
                        this.setState({
                            //advAccSubscriptionDate: advAccSubscriptionDate,
                            minTransPulldate: new Date(moment(MinSelectedDate).format("MM/DD/YY"))
                        });
                        setTimeout(() => {
                            this.showDateSelectorStep();
                        }, 0);
                    } else {
                        this.props?.reloadPage(1);
                        this.wizardClose();
                    }
                } else {
                    Utils.toastError(result?.result, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((error: any) => {
                reject();
            })
            .finally(() => {
                this.setState({ spinner: false });
            });
    }

    //This will call to save the trans pulling date in db. So that we can pull the transactions as per new workflow
    saveTranPullDate = () => {
        this.setState({ spinner: true });
        let { bankName, connectionName } = this.state;
        let saveReq = {} as any;
        saveReq.BankName = bankName;
        saveReq.ConnectionName = connectionName;
        saveReq.PullTranDate = moment(this.state.pullTranDate).format("DD-MMM-YYYY");

        accountService.SaveTranPullDate(saveReq)
            .then(async (result: any) => {
                if (result.message === "Success") {
                    toast.success(result?.result, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.setState({ isMappingSaved: false });
                    this.props?.reloadPage(1);
                    this.wizardClose();
                } else {
                    Utils.toastError(result?.result, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((error: any) => {
                reject();
            })
            .finally(() => {
                this.setState({ spinner: false });
            });
    }

    showDateSelectorStep = () => {
        let { step } = this.state;
        this.setState({ step: (step + 1), isMappingSaved: true });
    }

    //Radio button change event for connection names
    onRadioButtonChange = (item, eType) => {
        let { selectedConnection, connectionList } = this.state;
        const oldCID = selectedConnection?.connectionID;
        selectedConnection = {};
        let connectionDetail = {} as any;
        connectionDetail.connectionID = item?.connectionID;
        connectionDetail.connectionname = item?.connectionname;
        connectionDetail.oprIDs = item?.oprIDs;
        connectionDetail.accCount = item?.oprIDCount;
        connectionList.forEach((o: any) => o.hasSelected = false);
        connectionList.filter((a) => a.connectionID === item.connectionID)[0].hasSelected = true;
        if (eType == "ChangeType")
            connectionList.filter((a) => a.connectionID === oldCID)[0].hasSelected = false;

        if (eType === "DeleteType")
            this.setState({ isShowAddButton: true });

        isProcessRunning = false;
        this.setState({
            selectedConnection: connectionDetail,
            connectionList,
            connectionName: item?.connectionname,
            isLoading: false,
            innerLoader: false,
        });
    }

    onEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            this.handleNext();
        }
    }

    render() {
        let { step, connectionName, institutionList, bankName, nickNameList,
            isLoading, spinner, bankDetails, selectedBank, accountMapping, actionType,
            connectionList, isShowAddButton, reconnectSpinner, innerLoader, calenderShow,
            pullTranDate, minTransPulldate
        } = this.state;

        const selectedBankLength = selectedBank.filter((o) => o.OprID !== -1)?.length;

        const skelatonBody = () => {
            return (
                <div className="loader-responsive">
                    <div className="tipsEntryMain">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex justify-content-between bw-loader">
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation mr-auto"></div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation mr-auto"></div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                            <div></div>
                                        </div>
                                    </div>
                                </li>
                                <li className="d-flex justify-content-between bw-loader">
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation mr-auto"></div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation mr-auto"></div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                            <div></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            );
        }

        const skelatonHeader = () => {
            return (
                <div className="loader-responsive">
                    <div className="tipsEntryMain">
                        <div className="header d-flex">
                            <div className="loadingAnimation"></div>
                        </div>
                    </div>
                </div>
            );
        }

        const maskAccNo = (acct) => {
            const totalStar = 9 - acct?.length
            let mask = "";
            for (let i = 0; i < totalStar; i++) {
                mask += "*";
            }
            return (
                <>
                    {`${mask}${acct ?? ""}`}
                </>
            )
        }

        const RenderHeader = () => {
            if (step === 4) { return (<>Select Import Date</>); }
            else if (step === 0) { return (<>Bank Sync Connections</>); }
            else if (actionType == "AddManage" && step == 1) { return (`${bankName}`) }
            else if (step === 2 || step === 3) {
                return (<React.Fragment>
                    <div>{bankName}</div>
                    <div className="bw-conn-name-header">({connectionName})</div>
                </React.Fragment>)
            } else { return (<strong>Name This Connection</strong>) }
        }

        const calenderDisplay = () => {
            this.setState({ calenderShow: true });
        };

        const calenderHide = () => {
            this.setState({ calenderShow: false });
        };

        const onContactFieldChange = (event, _inputPosition, isBlur = false) => {
            let value = event.target.value;
            const fieldName = event.target.name;
            if (fieldName === "pullDate") {
                const _formattedDate = Utils.AddSlashINInputDateFormat(
                    value,
                    pullTranDate,
                    false,
                    2,
                    isBlur
                );
                if (_formattedDate === "invalid") {
                    return;
                } else {
                    value = _formattedDate;
                }
            }

            if (value.length === 8) {
                if (!moment(minTransPulldate)?.isSameOrBefore(value)) {
                    isValidDate = false;
                    return;
                }
            }
            if (value.length === 8) {
                if (!moment(new Date().setDate(new Date().getDate() - 1))?.isAfter(value)) {
                    isValidDate = false;
                    return;
                }
            }
            if (value.length < 8) isValidDate = false; else isValidDate = true;

            this.setState({ pullTranDate: value });
        };

        const isBtnDisabled = step == 4 ? !isValidDate : (spinner || isProcessRunning || ((step == 1 && actionType == "Connect") ? connectionName.trim() === "" :
            (step == 1 && actionType == "AddManage") ? false :
                step === 3 ? accountMapping?.length === 0 : selectedBankLength === 0));

        return (
            <>
                {reconnectSpinner ? (
                    <div className="matrixSpinnerCenter">
                        <Spinner animation="border" variant="secondary" />
                    </div>
                ) : (
                    <>
                        <ToastContainer autoClose={3000} enableMultiContainer />
                        <Modal className="BankWizardModal"
                            centered
                            show={true}
                            onHide={this.wizardClose}
                            backdrop="static"
                            dialogClassName="BModalDialog"
                            aria-labelledby="contained-modal-title-vcenter"
                        >{isLoading ? (
                            <React.Fragment>
                                <Modal.Header className="bw-skelaton-header">
                                    <Modal.Title>
                                        {skelatonHeader()}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="bw-setup-body">
                                        {skelatonBody()}
                                    </div>
                                </Modal.Body>
                            </React.Fragment>
                        ) : (
                            <>
                                <Modal.Header closeButton>
                                    <Modal.Title className="bw-modal-title">
                                        {RenderHeader()}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {step === 0 && (
                                        <>
                                            {institutionList.length > 0 ?
                                                <React.Fragment>
                                                    <Form.Group as={Row}>
                                                        <Col sm="6" className="bw-label-header">Bank Name</Col>
                                                        <Col sm="6" className="bw-label"></Col>
                                                    </Form.Group>
                                                    <div className="bw-setup-body bw-scroll-area">
                                                        {institutionList.map((items, index) => (
                                                            <Form.Group as={Row}>
                                                                <Col sm="6" className="bw-label">{items.bName}</Col>
                                                                <Col sm="6" className="bw-label">
                                                                    <Link className="bw-connect-modify-btn" to="#"
                                                                        onClick={(e) => { this.connectModify(items) }}
                                                                    >
                                                                        {(items.bankConnectionStatus?.toLowerCase() === "yes" || items.pendingSetup?.toLowerCase() === "yes") ? "Add/Manage Connections" : "Connect"}
                                                                    </Link>
                                                                </Col>
                                                            </Form.Group>
                                                        ))}
                                                    </div>
                                                </React.Fragment>
                                                : <div className="NoAdvLbl">
                                                    No Advanced Accounting Subscription
                                                </div>
                                            }
                                        </>
                                    )}
                                    {step === 1 && (
                                        <div className="bw-setup-body">
                                            <div className="bw-body-heading bw-body-margin">
                                                {actionType == "Connect" ?
                                                    <div><strong>One Set of Login Credentials, One Connection:</strong> If you use the same login credentials for all accounts, only one connection is needed. If you have multiple sets of credentials for this bank, each set of credentials should have its own connection.</div> :
                                                    <div>Please choose which connection you would like to manage.</div>
                                                }
                                            </div>
                                            <div className="form-section frm-section">
                                                <div className={`bw-body-section ${actionType == "Connect" ? "d-flex" : "bw-Body-Scroll"} justify-content-between`}>
                                                    {actionType == "Connect" && (
                                                        <Form.Group controlId="ConnectionName">
                                                            <div>
                                                                <input
                                                                    ref={this.connectionName}
                                                                    type="text"
                                                                    className="form-control bw-connectiona-name"
                                                                    autoComplete="Off"
                                                                    value={connectionName}
                                                                    placeholder="Connection Name"
                                                                    maxLength={50}
                                                                    onChange={(e) => { this.handleChange(1, e, "", 0) }}
                                                                    onKeyDown={this.onEnter.bind(this)}
                                                                />
                                                            </div>
                                                        </Form.Group>
                                                    )}
                                                    {actionType == "AddManage" && (
                                                        <>
                                                            {innerLoader ? <> {skelatonBody()} </> : (
                                                                <>
                                                                    {connectionList.map((item, idx) => (
                                                                        <div className="d-flex">
                                                                            <Form.Group className="mb-3 mr-auto" controlId={`Connection_${idx}`}>
                                                                                <Form.Check custom
                                                                                    type="radio"
                                                                                    className="bw-form-chk"
                                                                                    label={item.connectionname}
                                                                                    value={item.connectionID}
                                                                                    checked={item.hasSelected}
                                                                                    onChange={(e) => { this.onRadioButtonChange(item, "ChangeType") }}
                                                                                />
                                                                            </Form.Group>
                                                                            <Link className="bw-remove-conn-btn" to="#"
                                                                                onClick={(e) => { this.showWarning("DeleteConn", item.connectionID) }}
                                                                            >
                                                                                {item.deleteSpinner ? (
                                                                                    <Spinner style={{ marginLeft: "5px" }} size="sm" animation="border" />
                                                                                ) : "Remove Connection"}
                                                                            </Link>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                {isShowAddButton && actionType == "AddManage" && (
                                                    <div className="bw-add-connection-btn-container">
                                                        <Link className={`bw-add-connection-btn ${innerLoader ? "DisabledLink" : ""}`} to="#" aria-disabled={innerLoader}
                                                            onClick={(e) => { this.showWarning("NewConn", "") }}
                                                        >
                                                            Add Connections
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="bw-body-heading bw-body-margin-top">
                                                {actionType == "Connect" ?
                                                    <div>Consider giving each connection a nickname such as “Chase Bank Main Account” in case you need to update or add more accounts to this connection in the future.</div> : <></>
                                                }
                                            </div>
                                        </div>
                                    )}
                                    {step === 2 && (
                                        <div className="bw-setup-body">
                                            <div className="bw-body-heading">
                                                Please select the Inn-Flow accounts you would like to
                                                connect with <strong>{bankName}</strong>
                                            </div>
                                            <div className="form-section bw-scroll-area">
                                                <div className="bw-body-section justify-content-between"></div>
                                                {nickNameList.map((items, index) => (
                                                    <div className="d-flex bw-acctname-list" onMouseEnter={(e) => { this.showIcon(items) }} onMouseLeave={(e) => { this.hideIcon(items) }}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`list_${items.oprid}`}
                                                            label={items.accDisplayName}
                                                            value={items.oprid}
                                                            onChange={(e: any) => this.handleChange(2, e, items, index)}
                                                            checked={items.isSelect}
                                                            disabled={items.isDisabled}
                                                        />
                                                        {items.showEdit && items.isSelect && items.isDisabled && (
                                                            <>
                                                                {items.showSpinner ? (
                                                                    <Spinner className="bw-acctname-edit" size="sm" animation="border" />
                                                                ) :
                                                                    <FontAwesomeIcon className="bw-acctname-edit bw-btn-rotate" icon={faEdit} onClick={(e) => {
                                                                        this.showWarningOnEdit(items)
                                                                    }} />
                                                                }
                                                            </>
                                                        )}
                                                        {items.isDisabled && !items.isSelect && (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`button-tooltip-helpText_${index}`} style={{ opacity: 1 }}>
                                                                    <span>This account is a part of another connection.</span>
                                                                </Tooltip>}
                                                            >
                                                                <span className="help-icon bw-acnt-info">
                                                                    <svg
                                                                        stroke="currentColor"
                                                                        fill="#6a6e73"
                                                                        stroke-width="0"
                                                                        version="1.1"
                                                                        viewBox="0 0 16 16"
                                                                        height="1em"
                                                                        width="1em"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                                                                        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                                                                        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                                                                    </svg>{" "}
                                                                </span>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {step === 3 && (
                                        <div className="bw-setup-body">
                                            <div className="bw-body-heading bw-body-margin">
                                                Please map the bank accounts from Plaid to an Inn-Flow account.
                                            </div>
                                            <Form.Group as={Row}>
                                                <Col sm="4" className="bw-setup-laststep-header">Plaid Account</Col>
                                                <Col sm="1" className="bw-setup-laststep-header"></Col>
                                                <Col sm="5" className="bw-setup-laststep-header">Inn-Flow Account</Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                {bankDetails.map((rowItems, rowIndex) => (
                                                    <React.Fragment>
                                                        <Col sm="4" className="bw-bank-details-m">
                                                            <div className="bw-banktype-lbl">{rowItems.subtype}</div>
                                                            <div className="bw-banktype-lbl">
                                                                {maskAccNo(rowItems.mask)}
                                                            </div>
                                                        </Col>
                                                        <Col sm="1">
                                                            <div className="bw-Right-Arrow">
                                                                <svg stroke="currentColor" fill="#1d1e20" stroke-width="0" viewBox="0 0 448 512" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg"><path d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z">
                                                                </path>
                                                                </svg>
                                                            </div>
                                                        </Col>
                                                        <Col sm="5">
                                                            <div className="singleSelect">
                                                                <Dropdown className="more-action bg-blue single-select-image">
                                                                    <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="txt-area">
                                                                                <EllipsisWithTooltip placement="bottom">
                                                                                    {rowItems.AccountName}
                                                                                </EllipsisWithTooltip>
                                                                            </div>
                                                                            <div className="drop-arrow ml-0 d-flex">
                                                                                <FiChevronDown />
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="ddScrollableArea bw-dropmenu-width">
                                                                        {rowItems.AccountList.map((items, index) => (
                                                                            <Dropdown.Item
                                                                                onClick={(e: any) => this.handleAccountMapping(items, rowItems, rowIndex)}
                                                                                key={index}
                                                                                disabled={!items.CanSelect}
                                                                                className={!items.CanSelect ? "bw-disabled-acct" : ""}
                                                                            >
                                                                                <EllipsisWithTooltip placement="bottom">
                                                                                    {items.accDisplayName}
                                                                                </EllipsisWithTooltip>
                                                                            </Dropdown.Item>
                                                                        ))}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </Col>
                                                    </React.Fragment>
                                                ))}
                                            </Form.Group>
                                        </div>
                                    )}
                                    {step === 4 && (
                                        <div className="bw-setup-body">
                                            <div className="bw-body-heading bw-body-margin">
                                                <div>How far back would you like for us to retrieve bank transactions?</div>
                                                <div>(90 day maximum)</div>
                                            </div>
                                            <div className="form-section frm-section">
                                                <div className="calender-container">
                                                    <div
                                                        className={
                                                            calenderShow
                                                                ? "calender-display open"
                                                                : "calender-display"
                                                        }
                                                    >
                                                        <input
                                                            onFocus={calenderDisplay}
                                                            onClick={calenderDisplay}
                                                            type="text"
                                                            placeholder="MM/DD/YY"
                                                            maxLength={8}
                                                            name="pullDate"
                                                            onInput={(e) => {
                                                                let value = e.currentTarget.value;

                                                                value = value
                                                                    ?.toString()
                                                                    ?.replace(/[^0-9\/]+/gi, "");

                                                                onContactFieldChange(
                                                                    Utils.BuildCustomEventObject("pullDate", value),
                                                                    6
                                                                );
                                                            }}
                                                            onBlur={(e) => {
                                                                let value = e.currentTarget.value;

                                                                value = value
                                                                    ?.toString()
                                                                    ?.replace(/[^0-9\/]+/gi, "");

                                                                onContactFieldChange(
                                                                    Utils.BuildCustomEventObject("pullDate", value),
                                                                    6,
                                                                    true
                                                                );
                                                            }}
                                                            autoComplete="pullDate"
                                                            value={this.state.pullTranDate}
                                                            className="form-control single-date-pick"
                                                        />
                                                        <svg
                                                            onClick={calenderDisplay}
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 18 18"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                                                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                                                        </svg>
                                                    </div>
                                                    {calenderShow && (
                                                        <div className="calender" >
                                                            <OutsideClickHandler onOutsideClick={calenderHide}>
                                                                <Calendar
                                                                    calendarType={"US"}
                                                                    onChange={(date) => {
                                                                        const value = moment(date).format("MM/DD/YY");
                                                                        onContactFieldChange(
                                                                            Utils.BuildCustomEventObject(
                                                                                "pullDate",
                                                                                value
                                                                            ),
                                                                            6
                                                                        );
                                                                        calenderHide();
                                                                    }}
                                                                    value={
                                                                        this.state.pullTranDate
                                                                            ? moment(this.state.pullTranDate)?.format(
                                                                                "MM/DD/YY"
                                                                            ) === "Invalid date"
                                                                                ? new Date()
                                                                                : new Date(this.state.pullTranDate)
                                                                            : new Date()
                                                                    }

                                                                    maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                                    minDate={this.state.minTransPulldate}
                                                                />
                                                            </OutsideClickHandler>
                                                        </div>
                                                    )}


                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Modal.Body>
                                {step !== 0 && (
                                    <Modal.Footer className="bw-modal-footer">
                                        <Button
                                            onClick={step == 3 ? this.finishSetup : step == 4 ? this.saveTranPullDate : this.handleNext}
                                            variant="primary"
                                            disabled={isBtnDisabled}
                                        >
                                            {spinner && (
                                                <Spinner style={{ marginLeft: "5px" }} size="sm" animation="border" />
                                            )}
                                            {step == 4 ? " Finish Setup" : " Next"}
                                        </Button>
                                    </Modal.Footer>
                                )}
                            </>
                        )}
                        </Modal>
                    </>
                )}
            </>
        );
    }

    //This will use to close the modal
    wizardClose = () => {
        if (this.state.step == 4) {
            if (this.state.isMappingSaved)
                this.showWarning("CloseModal", 0);
            else
                this.props.showBankSyncWizard();
        }
        else {
            if (!this.state.plaidInitiate) {
                let { isLastAccountRemoved, step, actionType, selectedConnection } = this.state;
                if (actionType == "AddManage" && isLastAccountRemoved && step == 2) {
                    this.deleteOrRemoveAccount(selectedConnection.connectionID, 0, "All");
                }
                this.props.showBankSyncWizard();
                localStorage.removeItem("selectedBank")
            }
        }
    }

    //Alert msg while req for a new connection by Add/Manage connections
    showWarning = (warningType, connectionID) => {
        confirmAlert({
            title: "Warning!",
            message: warningType === "CloseModal" ? `Closing this will not import any previous bank transactions.` : warningType === "NewConn" ? `Only select Add Connection if you are planning to use different credentials than the ones used in any other connections. Plaid groups connections together based on the credentials used.` :
                `Removing this connection will unlink all related account connections.  Any bank transactions that have been imported thus far will be removed. You will need to go through the setup process for these accounts again.`,

            buttons: [
                {
                    label: warningType === "NewConn" ? "Proceed" : "Confirm",
                    className: warningType === "NewConn" ? "bw-alert-proceed" : "bw-alert-delete",
                    onClick: () => { warningType === "CloseModal" ? this.cancelPullTrans() : warningType === "NewConn" ? this.newConnectionProceed() : this.deleteConnection(connectionID); },
                },
                {
                    label: "Cancel",
                    onClick: () => reject(),
                },
            ],
            overlayClassName: "bw-warning-alert"
        });
    }

    cancelPullTrans = () => {
        this.props?.reloadPage(1);
        this.props.showBankSyncWizard();
    }

    //This will called when user wants to add another connection to the same bank
    newConnectionProceed = () => {
        let { bankName, connectionList, selectedBank } = this.state;
        const nickName = this.filterByBank(bankName);

        for (let n = 0; n < connectionList?.length; n++) {
            const oprIds = connectionList[n]?.oprIDs?.split(',');
            for (let d = 0; d < oprIds?.length; d++) {
                var idx = nickName.findIndex((a) => a.oprid === +(oprIds[d]));
                if (idx > -1) {
                    nickName[idx]["isSelect"] = true;
                    nickName[idx]["isDisabled"] = true;
                } else {
                    const element = nickName.filter((o) => o.oprid == +(oprIds[d]));
                    if (element?.length > 0) {
                        let arr = {} as any;
                        arr.ID = d;
                        arr.RowID = d;
                        arr.OprID = element[0]?.oprid;
                        arr.accDisplayName = element[0]?.accDisplayName;
                        arr.displayName = element[0]?.displayName;
                        arr.CanSelect = false;
                        selectedBank.push(arr);
                    }
                }
            }
        }

        setTimeout(() => {
            this.connectionName.current.focus();
        }, 0);
        this.setState({ step: 1, nickNameList: nickName, actionType: "Connect", connectionName: "", selectedBank });
    }

    filterByBank = (bankName) => {
        let { allInstitutionList } = this.state;
        let nickName = allInstitutionList.filter((o) => o.bName?.toLowerCase()?.trim() === bankName?.toLowerCase()?.trim() && o?.advAccSubscription === "Yes");
        nickName.map(
            (item, index) => (
                (item["isSelect"] = false),
                (item["isDisabled"] = false),
                (item["showEdit"] = false),
                (item["showSpinner"] = false)
            )
        );
        return nickName;
    }

    deleteConnection = (connectionID) => {
        this.deleteOrRemoveAccount(connectionID, 0, "All");
    }

    //Show edit Icon on hover to remove the account from a connection
    showIcon = (item) => {
        if (!isProcessRunning) {
            let { nickNameList } = this.state;
            nickNameList.filter((o) => o.oprid === item.oprid)[0].showEdit = true;
            this.setState({ nickNameList });
        }
    }

    //Hide edit Icon on hover
    hideIcon = (item) => {
        if (!isProcessRunning) {
            let { nickNameList } = this.state;
            nickNameList.filter((o) => o.oprid === item.oprid)[0].showEdit = false;
            this.setState({ nickNameList });
        }
    }

    //Alert msg while user click on edit icon
    showWarningOnEdit = (item) => {
        let { selectedConnection } = this.state;
        const msg = selectedConnection.accCount == 1 ? `If you do not add a new bank account to this connection, this connection will also gets deleted. Are you sure you want to remove this bank account?` : `Would you like to unlink this bank account? Doing so will remove all transactions currently on the Bank Sync page.`
        confirmAlert({
            title: "Warning!",
            message: msg,
            buttons: [
                {
                    label: "Confirm",
                    className: "bw-alert-delete",
                    onClick: () => { this.handleEdit(item) },
                },
                {
                    label: "Cancel",
                    onClick: () => reject(),
                },
            ],
            overlayClassName: "bw-warning-alert"
        });
    }

    //Handle edit click ewvent and remove the account from selected connection
    handleEdit = (items) => {
        let { selectedConnection, nickNameList } = this.state;
        nickNameList.filter((o) => o.oprid === items.oprid)[0].showSpinner = true;
        nickNameList.filter((o) => o.oprid === items.oprid)[0].showEdit = true;
        this.setState({ nickNameList })
        this.deleteOrRemoveAccount(selectedConnection.connectionID, items.oprid, "SingleAccount")
    }

    //Common fun to remove the single account or remove the whole connection
    deleteOrRemoveAccount = (connectionID, oprID, ActionType) => {
        isProcessRunning = true;
        let { connectionList, nickNameList, selectedBank, selectedConnection } = this.state;
        if (ActionType == "All") {
            connectionList.filter((a) => a.connectionID === connectionID)[0].deleteSpinner = true;
            this.setState({ connectionList, innerLoader: true });
        }
        accountService.RemovePlaidConnection(connectionID, oprID, ActionType)
            .then(async (result: any) => {
                let msg = ActionType == "SingleAccount" ? "Account removed successfully." : "Plaid connection deleted successfully.";
                if (result.message === "Success") {
                    toast.success(msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    if (ActionType == "All") {
                        this.props?.reloadPage(1);
                        connectionList.splice(connectionList.findIndex((a) => a.connectionID === connectionID), 1);
                        if (connectionList?.length == 0) {
                            this.clearState();
                            this.setState({ step: 0, connectionList: [], selectedConnection: {}, }, () => {
                                this.setState({ isLoading: true });
                                let request = {} as any;
                                request.HID = this.props?.hotelID;
                                this.getInstituteData(request);
                            });
                        } else {
                            const item = connectionList[0];
                            this.onRadioButtonChange(item, "DeleteType");
                        }
                    } else {
                        var accCount = +(selectedConnection.accCount) - 1;
                        let newList = nickNameList.filter((o) => o.oprid === oprID);
                        newList[0].showSpinner = false;
                        newList[0].showEdit = false;
                        newList[0].isSelect = false;
                        newList[0].isDisabled = false;
                        selectedBank.splice(selectedBank.findIndex(a => a.OprID === oprID), 1);
                        selectedConnection.accCount = accCount;
                        this.setState({ nickNameList, selectedBank, isLastAccountRemoved: accCount == 0, isShowAddButton: true, selectedConnection }, () => {
                            isProcessRunning = false;
                        });
                    }
                } else {
                    Utils.toastError(result?.result, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((error: any) => {
                reject();
            });
    }
}