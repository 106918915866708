import React from "react";
import { Button, Col, Form, FormGroup } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import { Utils } from "../../Common/Utilis";

export class SkipRoom extends React.Component<any, any>{
    constructor(props: any){
        super(props);
        this.state = {
            errors: false,
            reason : "",
            parentState: this.props.parentState
        }
    }

    hideDropdown = (isReload = false) => {
        this.props?.handleHideDropdown(isReload)
    }

    onSkipRoom = () => {
       // debugger;
        if(this.state.reason.trim() === ""){
            this.validationOnClick(1)
        }
        else
        {
          //  debugger;
            this.props.handleHideDropdown();
            let data = this.state.parentState.rightPanelData
            data.reason = this.state.reason
            // this.state.parentState.rightPanelDate.Reason = this.state.reason
            this.props.updateWorkOrderStatus("Skipped",data)
        }
    }

    validationOnClick = (inputPosition) => {

        if (this.state.reason.trim() === "") {
            this.setState({errors: true});
            Utils.toastError("A reason is required if selecting to skip room PM for this instance.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 4000,
                pauseOnHover: false,
                closeOnClick: false
            }); 


        } else {
            this.setState({ errors: false });
        }
    };

    reasonChange = (e: any) => {
        
        let value = e.target.value;
        this.setState({ reason: value }, () => {
        });
    }

    render() {
        return(
            <>
            <OutsideClickHandler
                onOutsideClick={() => {
                    this.hideDropdown(false)
                }}
            >
                <div className="dropdown-wrapper">
                    <div className="header">
                        <h4>Skip Room</h4>
                        <div>
                            <DropdownItem>
                                <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                    fill="#84888C"
                                />
                                </svg>
                            </DropdownItem>
                        </div>
                    </div>
                    <div className="body">
                    <Form.Group  controlId="reason" className="d-flex">
                            <Col
                                // sm={"12"} 
                                // className="p-0"
                                sm={"12"}
                                className={`mb-0 p-0 ${this.state.errors ? "validation-error" : ""
                                    }`}
                                // onClick={() => this.validationOnClick(5)}
                            >
                                <div className="alert">You are choosing to skip this room for this Preventative Maintenance project instance.</div>
                                <Form.Label>Reason <span>*</span></Form.Label>
                                <Form.Control 
                                as="textarea" 
                                placeholder="Explain the reason why you can not complete this room" 
                                rows={3}
                                value={this.state.reason}
                                onChange={(e) => this.reasonChange(e)}
                                 />
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="footer d-flex justify-content-end">
                        <Button className="cancel-btn" onClick={() => {this.hideDropdown(false)}}>Cancel</Button>
                        <Button className="skip-btn" onClick={this.onSkipRoom}>Skip Room</Button>
                    </div>
                </div>
            </OutsideClickHandler>
            </>
        )
    }
}