import React from "react";
import { Spinner, Modal, Button, Form } from "react-bootstrap";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import { SaveTemplateNameSaveRequestDTO } from "../../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import { ConfirmationModalNonTable } from "../../../../../Common/Components/ConfirmationModalNonTable";
import { Utils } from "../../../../../Common/Utilis";

export class SaveTemplateModal extends React.Component<any, any> {
  private saveInputRef: any;
  constructor(props: any) {
    super(props);
    this.saveInputRef = React.createRef();
    this.state = {
      saveTemplateSpinner: false,
      saveTemplateName: "",
      templateSaveConfirmation: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.saveInputRef?.current?.focus();
    }, 0);
  }

  saveTemplateModalHide = () => {
    this.props.saveTemplateModalHide();
  };
  warningCloseModel = () => {
    this.setState({ templateSaveConfirmation: false });
  };

  saveCopyBudgets = () => {
    this.setState(
      {
        templateSaveConfirmation: false,
      },
      () => {
        this.handleSave("exitingTemplate");
      }
    );
  };

  handleSave = (type: string) => {
    if (this.state.saveTemplateName === "") {
      Utils.toastError("Please enter a template name to save.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
      return;
    } else {
      this.setState({ saveTemplateSpinner: true });

      let requestObject = {} as SaveTemplateNameSaveRequestDTO;
      const storage = JSON.parse(localStorage.getItem("storage")!);

      requestObject.Hid = this.props.parentState.hidValue;
      requestObject.TemplateName = this.state.saveTemplateName;
      requestObject.date = this.props.parentState.navigationCalenderDates[0];
      requestObject.Type = type;
      requestObject.TenantID = storage?.tenantID;
      requestObject.LogInUser = storage?.userName;

      let requestArray: any = [];
      requestArray.push(requestObject);

      LaborSchedule.SaveScheduleTemplateName(requestArray)
        .then(async (result: any) => {
          let apiResult = result.result;
          
          if (apiResult.saveStatus === "Success") {
            this.saveTemplateModalHide();
            toast.success("Template Saved Successfully", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "scheduleMain",
            });
          } else if (apiResult.message === "Templetename is already exits") {
            
            this.setState({ templateSaveConfirmation: true });
          } else {
            Utils.toastError(`${apiResult.message}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "scheduleMain",
            });
          }
          this.setState({ saveTemplateSpinner: false });
          resolve();
        })
        .catch((err) => {
          this.setState({ saveTemplateSpinner: false });
          Utils.toastError(`Server Error, ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
          reject();
        });
    }
  };

  templateNameChange = (e: any) => {
    let value = e.target.value;

    if (/^[A-Za-z0-9\-'&_() ]+$/.test(value) === false) {
      e.target.value = value.replace(/[^A-Za-z0-9\-'&_() ]/g, "");
    }
    if (value.length > 50) {
      let a = value.replace(/[^A-Za-z0-9\-'&_() ]/g, "");
      e.target.value = a.substring(0, value.length - 1);
    }

    this.setState({ saveTemplateName: value });
  };

  render() {
    return (
      <div>
        <Modal
          className="schedule-save-template-modal"
          show={this.props.parentState.saveTemplateModal}
          onHide={this.saveTemplateModalHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Save as Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="template-name-text">Template Name</div>

            <div className="template-name-imput">
              <Form.Group className="daily-activity-input">
                <Form.Control
                  ref={this.saveInputRef}
                  placeholder="Enter Template Name"
                  value={this.state.saveTemplateName}
                  maxLength={49}
                  onChange={(e: any) => {
                    this.templateNameChange(e);
                  }}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer-wrapper">
              <Button
                className={
                  this.state.saveTemplateSpinner
                    ? "save-template template-modal-btn p-none"
                    : "save-template template-modal-btn"
                }
                onClick={() => this.handleSave("saveTemplate")}
              >
                {this.state.saveTemplateSpinner && (
                  <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Save Template
              </Button>
            </div>
          </Modal.Footer>
          {this.state.templateSaveConfirmation && (
            <div>
              <ConfirmationModalNonTable
                title={"Save as Template"}
                description={`${this.state.saveTemplateName} already exists, continuing to save will overwrite the existing values. Are you sure you want to continue?`}
                cancelButtonName={"No"}
                actionButtonName={"Yes"}
                warningCloseModel={this.warningCloseModel.bind(this)}
                saveCopyBudgets={this.saveCopyBudgets.bind(this)}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
