import * as React from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import { LaborDepartment } from "../LaborManagement/LaborDepartment";
import { LaborPosition } from "../LaborManagement/LaborPosition";
import { LaborDepartmentAccess } from "../LaborManagement/LaborDepartmentAccess";
import RouteLeavingGuard from "../../Common/Components/RouteLeavingGuard";
import {
  // ToastContainer, 
  toast
} from "react-toastify";
import { resolve, reject } from "q";
import { confirmAlert } from "react-confirm-alert";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../Common/Services/RoleSubPermissionSetting";
import { Utils } from "../../Common/Utilis";
import { PayrollPositionMapping } from "../LaborManagement/PayrollPositionMapping";
import { LaborDeptPosition as laborDeptPosition } from "../../Common/Services/LaborDeptPosition";

let routeLeavingGuard: any = null;
export class LaborDepartmentPosition extends React.Component<any, any> {
  private roleChild: any;
  private departmentChild: any;
  private positionChild: any;
  private payrollpositionmapping: any;
  
  constructor(props: any) {
    super(props);
    this.roleChild = React.createRef();
    this.departmentChild = React.createRef();
    this.positionChild = React.createRef();
    this.payrollpositionmapping = React.createRef();
    
    this.state = {
      key: "departments",
      tab: "departments",
      btname: "",
      isbtnshow: true,
      isManageDept: false,
      isManagePosition: false,
      isPagePermission: false,
      toggleDisableBtn: false,
      togglePayrollPositionMapping: false,
      tblPayrollIntegrationType:[],
      isPayroollPermission:""
    };
    this.hanndleClick = this.hanndleClick.bind(this);
    this.handleNewDepartment = this.handleNewDepartment.bind(this);
    this.handleNewPosition = this.handleNewPosition.bind(this);
  }

  componentDidMount() {
    this.getPayrollIntegrationType();
    this.getMenuPermission();
  }

  getMenuPermission = () => {
    let isPermission = false;
    let requestObject = {} as any;
    requestObject.permissionID = "ALL";
    requestObject.Module = "LaborManagement";
    requestObject.Type = "menu";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            if (element.permissionID === 10031) {
              isPermission = true;
              this.setState({ isPagePermission: true });
            }
          });
          if (isPermission) {
            this.getDepartmentPositionPermission();
            const location = this.props.location;
            if (location.state !== undefined) {
              const stateLength = Object.keys(location.state).length;
              if (stateLength > 0) {
                // pageLoadDate
                let { roleID, pageName } = location.state;
                if (pageName === "RolePermission") {
                  this.handleSelect("laborDepartmentAccess"); 

                  this.setState({ isbtnshow: false });
                  this.setState({ key: "laborDepartmentAccess" });
                  if (this.roleChild.current != null) {
                    setTimeout(() => {
                      this.roleChild.current.rebindTable(roleID, true);
                    }, 2000);
                  }
                }
              }
            }
          } else {
            window.location.href = "/unauthorize-access";
          }
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "payrollExportIndex",
        });
        reject();
      });
  }

  getDepartmentPositionPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10031;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionID) {
              case 10041:
                this.setState({ isManageDept: true, btname: "Add Department" });
                break;
              case 10042:
                this.setState({ isManagePosition: true, btname: "Add Position" });
                break;
            }
            if (result.length >= 2) {
              this.setState({ btname: "Add Department" });
            }

          });
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
      });
  };

  getPayrollIntegrationType = () => {
    
    this.setState({ loaderTrue: true });
    laborDeptPosition
      .GetPayrollIntegrationType()
      .then(async (result: any[] | null) => {
      debugger;
        if (result != null && result.length > 0) {   
          this.setState({tblPayrollIntegrationType:result,isPayroollPermission:result[0].isPayroollPermission});
        }else{
          this.setState({tblPayrollIntegrationType:[],isPayroollPermission:""});
        }

        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };


  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.changeValue &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeUnsaved(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isChangeUnsaved() {
    this.setState({ isbtnshow: true }, () => {
      this.handleSelect(this.state.tab);

      if (this.state.key === "departments") {
        if (this.departmentChild.current != null) {
          this.departmentChild.current.handleRemoveNewRow();
        }
      } else if (this.state.key === "positions") {
        if (this.positionChild.current != null) {
          this.positionChild.current.handleRemoveNewRow();
        }
      }
    });
  }

  handleSelect = (eventKey: any) => {
  
    if (eventKey === "departments") {
      this.departmentChild.current.getlaborDepartment();
    } else if (eventKey === "positions") {
      this.positionChild.current.getlaborPositionDeptMapping();
    }
    let Ischange = false;
    let tab = this.state.key;
    if (tab === "departments" || tab === "positions") {
      if (!this.state.isbtnshow) {
        Ischange = true;
        this.setState({ tab: eventKey });
        this.isValidateModelPoupTab();
      }
    }
     
    if(tab==="lblPayrollPositionMapping")
    {
      
      if(this.state.togglePayrollPositionMapping){
        Ischange = true;
        this.setState({ tab: eventKey });
       this.isValidateModelPayrollPositionTab(eventKey);
      }else{
        this.setState({ tab: eventKey });
      }
    }



    if (!Ischange) {
      this.setState({ key: eventKey });
      if (eventKey === "departments") {
        this.setState({ btname: "Add Department", isbtnshow: true });
        if (this.departmentChild.current != null) {
          this.departmentChild.current.rebindTable();
        }
      } else if (eventKey === "positions") {
        this.setState({ btname: "Add Position", isbtnshow: true });
        if (typeof this.positionChild?.current?.rebindTable === "function") {
          this.positionChild.current.rebindTable();
        }
      }else if (eventKey === "lblPayrollPositionMapping") {
        if (this.payrollpositionmapping.current != null) {
          this.payrollpositionmapping.current.getPayrollIntegrationType();         
        }
      } else {
        this.setState({ isbtnshow: false });
        if (this.roleChild.current != null) {
          this.roleChild.current.rebindTable(0, false);
        }
      }
    }
  };

  hanndleClick() {
    if (this.state.key === "departments") {
      if (this.departmentChild.current != null) {
        this.setState({ isbtnshow: false });
        this.departmentChild.current.CleanSearch();
      }
    } else if (this.state.key === "positions") {
      if (this.positionChild.current != null) {
        this.setState({ isbtnshow: false });
        this.positionChild.current.PositionCleanSearch();
      }
    }
  }

  handleNewDepartment(isShow: boolean) {
   // debugger
    this.setState({ isbtnshow: isShow });
  }

  handleNewPosition(isShow: boolean) {
    this.setState({ isbtnshow: isShow });
  }

  toggleDepartment = (isShow) => {
    //debugger
    this.setState({ toggleDisableBtn: isShow});
  }
  togglePosition = (isShow) => {
    this.setState({ toggleDisableBtn: isShow });
  }

  togglePayrollPositionMapping= (isShow) => {
    this.setState({ togglePayrollPositionMapping: isShow });
  }

  isValidateModelPayrollPositionTab(eventkeytype:any) {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangePayrollPosition(eventkeytype),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isChangePayrollPosition(eventkeytype:any) {
   
       if (this.payrollpositionmapping.current != null) {
          this.payrollpositionmapping.current.handleDiscardChanges("disccardbutton","");
          this.setState({togglePayrollPositionMapping:false},()=>{
            this.handleSelect(this.state.tab);
          });
         
        }
        
     // this.setState({ tab: eventkeytype });
      // if (this.state.key === "departments") {
      //   if (this.departmentChild.current != null) {
      //     this.departmentChild.current.handleRemoveNewRow();
      //   }
      // } else if (this.state.key === "positions") {
      //   if (this.positionChild.current != null) {
      //     this.positionChild.current.handleRemoveNewRow();
      //   }
      // }
      
  }

  render() {
    return (
      <>
        {this.state.isPagePermission && (
          <React.Fragment>
            <RouteLeavingGuard
              when={this.state.changeValue}
              routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
              shouldBlockNavigation={(location: { pathname: any }) => {
                if (
                  this.state.key !== "laborDepartmentAccess" &&
                  !this.state.isbtnshow
                ) {
                  if (location.pathname) {
                    return true;
                  }
                }
                return false;
              }}
            />

            <Container fluid className="body-sec position-relative">
              <div className="page-heading d-flex">
                <div className="mr-auto height-40">Departments & Positions</div>
                {this.state.isManageDept && this.state.btname === "Add Department" && this.state.key === "departments" && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.hanndleClick}
                    disabled={this.state.toggleDisableBtn}
                  >
                    {this.state.btname}
                  </button>
                )}
                {this.state.isManagePosition && this.state.btname === "Add Position" && this.state.key === "positions" && (

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.hanndleClick}
                    disabled={this.state.toggleDisableBtn}
                  >
                    {this.state.btname}
                  </button>
                )}
              </div>
              <Tabs
                id="controlled-tab-example"
                activeKey={this.state.key}
                onSelect={this.handleSelect}
              >
                <Tab eventKey="departments" title="Departments">
                  <LaborDepartment
                    ref={this.departmentChild}
                    manageDept={this.state.isManageDept}
                    handleNewDepartment={this.handleNewDepartment}
                    toggleDepartment={this.toggleDepartment}
                  />
                </Tab>
                <Tab eventKey="positions" title="Positions">
                  <LaborPosition
                    ManagePosition={this.state.isManagePosition}
                    ref={this.positionChild}
                    handleNewPosition={this.handleNewPosition}
                    togglePosition={this.togglePosition}
                    parentState={this.state}
                  />
                </Tab>
                <Tab
                  eventKey="laborDepartmentAccess"
                  title="Labor Department Access"
                >
                  <LaborDepartmentAccess ref={this.roleChild}
                    manageDept={this.state.isManageDept} />
                </Tab>
                {this.state.isPayroollPermission==="Yes" && this.state.tblPayrollIntegrationType.length > 0 &&    
                <Tab
                  eventKey="lblPayrollPositionMapping"
                  title="Payroll Position Mapping"
                  >
                  <PayrollPositionMapping 
                    ref={this.payrollpositionmapping}
                    parentState={this.state}
                    togglePayrollPositionMapping={this.togglePayrollPositionMapping}
                    />
                </Tab>
              }

                
              </Tabs>
            </Container>
          </React.Fragment>
        )}
      </>
    );
  }
}
