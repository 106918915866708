import React, { useState } from 'react';
// import default react-pdf entry
import { Document, Page, pdfjs } from "react-pdf";
import { Spinner } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PDFViewer(props) {
  const [file, setFile] = useState(props);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }
  function onDocumentLoadProgress({ loaded, total }) {
    console.log('Loading a document: ' + (loaded / total) * 100 + '%');
  }
  function renderLoader() {

    return (
      <div className="spiner d-flex align-items-center justify-content-center"><Spinner animation="border" variant="success" /></div>
    )
  }
  function renderError() {
    return (
      <>
        {props.errormsg === undefined ?
          <div className="custom-error-msg d-flex align-items-center">Can not be previewed - {file.fileName}</div>
          :
          <div className="custom-error-msg d-flex align-items-center">{props.errormsg} {" - "} {file.fileName}</div>
        }
      </>

    )
  }
  function renderNoData() {
    // return (
    //   <div>
    //     {/* <Spinner animation="border" variant="success" /> */}
    //   </div>
    // )    
  }

  return (
    <Document file={file.filePreview} onLoadSuccess={onDocumentLoadSuccess} loading={renderLoader} noData={renderNoData} error={renderError}>
      {Array.from(new Array(numPages), (_el, index) => (
        <>

          <div className="custom-page-action d-flex justify-content-between align-items-center doc-header">
            <div className="page-ttl">
              <EllipsisWithTooltip placement="bottom">{file.fileName}</EllipsisWithTooltip>
            </div>
            <div className="page-count">Page {index + 1} of {numPages}</div>
          </div>
          <Page
            key={`page_${props.fileNo + index + 1}`}
            pageNumber={index + 1}
            scale={props.selectedZoom}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </>
      ))}
    </Document>

  );
}

export default PDFViewer;