import * as React from 'react';
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import "./reorderList.scss";
import { toast, ToastContainer } from "react-toastify";
import { ModuleReports } from "../../../Common/Services/ModuleReports";
export class StatisticsRevenueSummary extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            statisticsData: [],
            revenueData: "0.00",
            isload: false,
            isAllChecked: false,
        };
    }

    componentDidMount() {
        this.getStatisticsData();
    }

    getStatisticsData() {
        ModuleReports.getStatisticsData().then(async (result: any[] | null) => {
            if (result != null && result.length > 0) {
                let isAllChecked = false;
                if (result.filter(e => e.addToSR.toLowerCase() === "false").length === 0) {
                    isAllChecked = true;
                }
                this.setState({
                    statisticsData: result, isAllChecked
                }, () => {
                    this.props.updateState(result);
                    this.setState({ isload: true })
                })
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }

    onchange = (index, e) => {
        let statisticsData = this.state.statisticsData
        statisticsData[index].addToSR = e.target.checked ? 'true' : 'false';
        let isAllChecked = false;
        if (statisticsData.filter(e => e.addToSR.toLowerCase() === "false").length === 0) {
            isAllChecked = true;
        }
        this.setState({ statisticsData, isAllChecked }, () => {
            this.props.updateState(statisticsData);
        })
    }

    onallchange = (e) => {
        let statisticsData = this.state.statisticsData
        statisticsData.map((items) => ((items.addToSR = items.itemOrder === 1 ? "true" : (e.target.checked ? "true" : "false"))));
        this.setState({ statisticsData, isAllChecked: !this.state.isAllChecked }, () => {
            this.props.updateState(statisticsData);
        })
    }
    render() {

        return (
            <>
                {this.state.isload && (
                    <div className="sec-group cash-flow">
                        <div className="group-title"></div>
                        <div className="section">
                            <div className='list-section'>
                                <div className='section-ttl d-flex'>
                                    <div className='statics mr-auto'>Statistics</div>
                                    <Form.Check id={"all"} custom type="checkbox" label="" checked={this.state.isAllChecked} onChange={(e) => this.onallchange(e)} />
                                </div>

                                <div className="drag-list capitalize">
                                    {this.state.statisticsData.map((widget, idx) => (
                                        <div className="list pad-2 d-flex align-items-center">
                                            <div key={idx} className="display-name mr-auto">
                                                {widget?.description.trim().length > 0 ? (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={
                                                            <Tooltip id={`cashFlow${idx}`}>
                                                                <div className='title'></div>
                                                                <div className='desc'>{widget?.description}</div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className={widget?.addToSR.toLowerCase() === "true" ? "selectedRow" : ""}>{widget?.itemName.toString().toLowerCase()}</span>
                                                    </OverlayTrigger>
                                                ) : <span className={widget?.addToSR.toLowerCase() === "true" ? "selectedRow" : ""}>{widget?.itemName.toString().toLowerCase()}</span>}

                                            </div>
                                            <Form.Check id={idx} custom type="checkbox" label=""
                                                disabled={widget?.itemOrder === 1 ? true : false}
                                                checked={widget?.addToSR.toLowerCase() === "true" ? true : false}
                                                onChange={(e) => this.onchange(idx, e)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}