import React from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Utils } from "../../../Common/Utilis";
import moment from "moment";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { Hotel } from "../../../Common/Services/Hotel";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { IRoomTypeNew } from "../../../Common/Contracts/IRoomType";

export class DuplicateGroup extends React.Component<any, any> {


  state: any = {
     groupName: 'Copy - '+ this.props?.groupName|| '',
     groupId: 0
  };

  componentDidMount = () => {

  };

  onFieldChange = (groupName) => {
    groupName =groupName?.toString()?.trimStart();
    groupName = Utils.AllowOnlyAlphabetsWithRightSpace(groupName);
    groupName = typeof groupName === 'string' ? groupName?.toString()?.trimLeft() :groupName;
    if(groupName?.toString().length>=100) {
       return;
    }
 
     if (groupName) {
      groupName = Utils.CapitalizeFirstLetter(groupName);
     }
    this.setState({groupName});
  };

  checkIfGroupNameAlreadyExsit = (value)=> {
    const groupNames = [].concat.apply([], (this.props?.entityGroupsData || [])?.map(x=> (x?.groupWithHotels || [])))?.map((l:any)=> l?.groupName) || [];
    return  groupNames?.filter(x=> x?.toString()?.toLowerCase() === value?.toString()?.toLowerCase())?.length >0;
   }

 addGroup = ()=> {
  if(this.checkIfGroupNameAlreadyExsit(this.state.groupName)) {
    Utils.toastError("Please enter different group name.", {
       position: toast.POSITION.BOTTOM_RIGHT,
       containerId: "addDocument",
     });
     return;
}
    this.props.addGroup(this.state.groupName);
    this.props.handleHideGroupModal();
 }

 hideGroup = ()=> {
    this.props.handleHideGroupModal();
 }



  render() {
    const {
      groupName
    } = this.state;

    const { showDuplicateGroupModal } = this.props;
    return (
      <>
        <div className="">
          <ToastContainer containerId={"addDeduction"} autoClose={3000} />

          <Modal
            className="add-room-type-modal"
            show={showDuplicateGroupModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.hideGroup}
          >
            <Modal.Header closeButton>
              <Modal.Title>
             Duplicate Group
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                <Form.Group 
                      controlId="groupName" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0}`}
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        tabIndex={1}
                        id="txtgroupName"
                        name="groupName"
                        autoComplete="groupName"
                        value={groupName}
                        onChange={(e) => this.onFieldChange(e?.target?.value)}
                      />
                    </Col>
                  </Form.Group>
                 
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-ghost"
                onClick={() => this.hideGroup()}
              >
                Cancel
              </Button>
              <Button
                disabled={!groupName}
                className="btn btn-primary"
                onClick={this.addGroup}
              >
             { "Save"}   
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
