import * as React from "react";
import {
  // Container,
  Form,
  Dropdown,
  Tooltip,
  OverlayTrigger,
  // OverlayTrigger, Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { resolve, reject } from "q";
import PageLoader from "../../Common/Components/PageLoader";
import { LaborDeptPosition as laborDeptPosition } from "../../Common/Services/LaborDeptPosition";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableSearch from "../../Common/Components/TableSearch";
import { Hotel, Hotel as hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { SearchMultiSelectItems } from "../../Common/Components/SearchMultiSelectItems";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import OutsideClickHandler from "react-outside-click-handler";
import { Utils } from "../../Common/Utilis";
export class LaborDepartment extends React.Component<any, any> {
  private inputdep: any;
  private child: any;
  constructor(props: any) {
    super(props);
    this.inputdep = React.createRef();
    this.child = React.createRef();
    this.state = {
      EHIDs: [],
      tableData: [],
      actualDepartmentData: [],
      SearchData: [],
      actualtableData: [],
      columns: [],
      selectedItems: [],
      selectedHItems: [],
      loaderTrue: false,
      isNewDept: false,
      isChange: false,
      showDeactivatedDept: false,
    };
    this.handleRemoveNewRow = this.handleRemoveNewRow.bind(this);
    this.FocusTextbox = this.FocusTextbox.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.CleanSearch = this.CleanSearch.bind(this);
    this.rebindTable = this.rebindTable.bind(this);
  }

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  componentDidMount() {
    this.getlaborDepartment();
    this.getEHIDS();
  }
  rebindTable() {
    this.setState({ showDeactivatedDept: false }, () => {
      this.props.toggleDepartment(false);
    });

    this.child.current.CleanSearch();
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualtableData))
    );
    this.setState({ tableData: [], isMountDone: false }, () => {
      this.setState({ tableData: data, isMountDone: true });
    });
  }

  FocusTextbox() {
    // Utils.toastError("Mandatory details of Department are missing.", {
    //   // // position: toast.POSITION.BOTTOM_RIGHT,
    //   // // containerId: "LaborDepartment",
    // });
  }
  //For remove new unsave row from table

  CleanSearch() {
    this.child.current.CleanSearch();
    setTimeout(() => {
      this.handleNewDepartment();
    }, 200);
  }

  handleNewDepartment() {
    if (!this.state.isNewDept) {
      let newtableData: any = [];
      let datalen = [...this.state.actualtableData].length;
      newtableData = Object.assign([], this.state.actualtableData);
      let newRow = {} as any;
      newRow.deptID = 0;
      newRow.deptName = "";
      newRow.selectedIDs = "";
      newRow.lettercode = "";
      newRow.tenantID = 0;
      newtableData.push(newRow);
      // setTimeout(() => {
      //  // this.inputdep.current.focus();
      // }, 200);
      this.setState(
        {
          tableData: [],
          isNewDept: true,
          newIndex: datalen,
          isMountDone: false,
        },
        () => {
          this.setState(
            {
              tableData: newtableData,
              isNewDept: true,
              newIndex: datalen,
              isMountDone: true,
            },
            () => {
              this.inputdep.current.click();
              this.props.handleNewDepartment(false);
            }
          );
        }
      );
    }
  }

  handleRemoveNewRow = () => {
    let newtableData: any = [];
    newtableData = Object.assign([], this.state.tableData);
    newtableData.splice(-1, 1);
    this.setState({ tableData: newtableData, isNewDept: false });
    //debugger
    this.props.toggleDepartment(false);
    this.props.handleNewDepartment(true);
  };

  itemsSelected = (Chieldstate: any) => {
    let data = Object.assign([], JSON.parse(JSON.stringify(Chieldstate)));
    this.setState({ selectedItems: data, isChange: true });
  };

  getEHIDS() {
    this.setState({ loaderTrue: true });
    hotel
      .getUserHotelAccess("Labor Management")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          let arrayList: any[] = [];
          result.map((item: any) => {
            let data = {} as any;
            data.value = item.hotelID;
            data.text = item.lettercode;
            data.isconflict = false;
            data.isChecked = false;
            arrayList.push(data);
          });
          this.setState({ EHIDs: arrayList });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  getlaborDepartment = () => {
    this.setState({ loaderTrue: true });
    laborDeptPosition
      .LaborDeptHotelMapping()
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          //
          this.setState({ actualDepartmentData: result });
          result = result.filter(
            (items) => items.status.toString().toLowerCase() === "active"
          );
          this.setState({
            actualtableData: result,
            tableData: result,
            SearchData: result,
          });
          this.setState({ isMountDone: true });
        }
        setTimeout(() => {
          this.setState({ loaderTrue: false });
        }, 100);
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  cellEdit = cellEditFactory({
    mode: "click",
    blurToSave: true,
    afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
      //debugger;
      let isChange = false;
      let col = column.dataField;
      if (oldValue !== newValue && col === "deptName") {
        isChange = true;
        row.deptName = newValue;
      }

      if (row.deptName.trim().length === 0) {
        Utils.toastError("Please enter the Department.", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "LaborDepartment",
        });
      } else if (row.deptName.length > 100) {
        Utils.toastError(
          "Department length should be a maximum of 100 characters, and has been truncated.",
          {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "LaborDepartment",
          }
        );
      } else if (this.state.selectedItems.length === 0 && col === "deptName") {
        // Utils.toastError("Please choose EHID.", {
        //   // // position: toast.POSITION.BOTTOM_RIGHT,
        //   // // containerId: "LaborDepartment",
        // });
      } else {
        this.saveUpdateDepartment(row, isChange);
      }
    },
  });

  saveUpdateDepartment(row: any, isChange: Boolean) {
    if (String(this.state.selectedItems).length === 0) {
      Utils.toastError("At least 1 hotel should be selected.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "LaborDepartment",
      });
      return;
    } else {
      let data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.selectedItems))
      );
      let arrayList: any[] = [];
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
       let previousLetterCode = row.selectedIDs.split(",");
       let removedId = previousLetterCode.map((items) => {
         let valueExist = false;
         for (let item = 0; item < data.length; item++) {
           if (items == data[item].value) {
             valueExist = true;
             break;
           }
         }
         if (!valueExist) {
           return items;
         }
       });
              
       let filterId =removedId.filter(item=>item!== undefined);
       let removeIds = filterId.join(",");      
      data.map((item: any) => {
        let data = {} as any;
        data.deptID = row.deptID;
        data.deptName = row.deptName;
        data.hotelID = item.value;
        data.hotelname = item.text;
        data.tenantID = tenantID;
        data.removeHotelID = removeIds
        arrayList.push(data);
      });
      if (this.state.isChange) {
        this.updateActualDetails(arrayList);
      } else if (isChange) {
        this.updateActualDetails(arrayList);
      }
    }
  }

  updateActualDetails(saveActualDetail: any[]) {
    this.setState({ loaderTrue: true });
    laborDeptPosition
      .LaborDepartmentSave(saveActualDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.status === "Success") {
            toast.success(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborDepartment",
            });
            this.props.handleNewDepartment(true);
            this.setState({ isNewDept: false, isChange: false });
            this.getlaborDepartment();
          } else {
            Utils.toastError(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborDepartment",
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  changeNewDept = (e: any) => {
    this.setState({ deptName: e.target.value, isChange: true });
  };

  onOutsideNewPosition = (e: any, newPosition, row: any) => {
    let newtableData: any = [];
    newtableData = Object.assign([], this.state.tableData);
    let tableIndex = newtableData.length as number;
    newtableData[tableIndex - 1].deptName = this.inputdep.current.value;
    this.setState({ tableData: newtableData });
  };

  onBlurNewDept = (e: any, row: any) => {
    if (this.state.deptName !== undefined) {
      let newtableData: any = [];
      newtableData = Object.assign([], this.state.tableData);
      let tableIndex = newtableData.length as number;
      newtableData[tableIndex - 1].deptName = this.state.deptName;
      this.setState({ tableData: newtableData });
    }
  };

  rowEvents = {
    onChange: (e: any, cell: any) => {
      let value = e.target.value;
      if (value.length > 100) {
        e.target.value = value.substring(0, value.length - 1);
      }
    },

    onClick: (e: any, row: any, rowIndex: any) => {
      let selectedList: any[] = [];
      let arrayList: any[] = [];
      arrayList = row.selectedIDs.split(","); //Number(arrayList[0])
      this.state.EHIDs.map((val: any) => {
        for (let i = 0; i < arrayList.length; i++) {
          if (Number(arrayList[i]) === val.value) {
            let data = {} as any;
            data.value = val.value;
            data.text = val.text;
            data.isChecked = true;
            selectedList.push(data);
          }
        }
      });
      this.setState({ selectedItems: selectedList });
      this.setState({ currentIndex: rowIndex });
    },
  };

  hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };

  newDepartmentformater = (cell, row, rowIndex) => {
    if (row.deptName === "") {
      return (
        <div>
          <OutsideClickHandler
            onOutsideClick={(e: any) => {
              this.onOutsideNewPosition(e, this.state.deptName, row);
            }}
          >
            <Form.Group>
              <Form.Control
                ref={this.inputdep}
                maxLength={100}
                placeholder="Add Department"
                type="text"
                key="121666"
                id="Depttxt"
                //value={row.deptName}
                disabled={!this.props.manageDept}
                value={this.state.deptName}
                onChange={this.changeNewDept}
                onBlur={(e: any) => {
                  this.onBlurNewDept(e, row);
                }}
                onFocus={(e: any) => {
                  this.onBlurNewDept(e, row);
                }}
              />
            </Form.Group>
          </OutsideClickHandler>{" "}
        </div>
      );
    } else {
      return (
        <div
          className={`${
            row.status === "Inactive" && this.state.showDeactivatedDept
              ? "hasNoPointer"
              : ""
          } pos-name`}
        >
          <EllipsisWithTooltip placement="bottom">
            {row.deptName}
          </EllipsisWithTooltip>
        </div>
      );
    }
  };

  LatterCodeFormater = (cell, row, rowIndex) => {
    if (row.deptName === "" && row.lettercode === "") {
      return <div style={{ color: "gray" }}>Select EHID</div>;
    } else if (row.lettercode === "" && row.deptName !== "") {
      return (
        <div className="required-cell-text">
          EHID <div>Required</div>
        </div>
      );
    } else {
      return (
        <div
          className={`${
            row.status === "Inactive" && this.state.showDeactivatedDept
              ? "hasNoPointer"
              : ""
          } pos-name`}
          onWheel={this.hoverOutEllipsis.bind(this)}
        >
          {this.props.manageDept ? (
            <EllipsisWithTooltip placement="bottom">
              {row.lettercode}
            </EllipsisWithTooltip>
          ) : (
            <>
              {!row.lettercode.includes("+") ? (
                <span>{row.lettercode}</span>
              ) : (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 0, hide: 0 }}
                  overlay={this.renderTooltip(row)}
                >
                  <span>{row.lettercode}</span>
                </OverlayTrigger>
              )}
            </>
          )}
        </div>
      );
    }
  };

  formatColumn(cell: any, row: any, rowIndex: any) {
    let confirmModalContent = [
      {
        title: "Delete Department",
        desc: "Are you sure you want to delete '" + row.deptName + "' ?",
        cancleAction: "Cancel",
        ActionTrue: "Delete Department",
      },
    ];
    const tableDataLength = [...this.state.tableData].length - 1;
    if (rowIndex === tableDataLength && this.state.isNewDept === true) {
      return (
        <button
          type="button"
          className="btn btn-primary fa-trash"
          onClick={this.handleRemoveNewRow}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
              fill="#2caf92"
            />
          </svg>
        </button>
      );
    } else {
      return (
        <Dropdown className="more-action hasDropShadow" alignRight>
          <Dropdown.Toggle
            className="btn-outline-primary btn btn-primary more fa-trash"
            id="dropdown-more"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z" />
              <path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ConfirmationModal
              confirmModalContent={confirmModalContent}
              ishandleDeletePosition={this.ishandleDeletePosition.bind(
                this,
                row
              )}
            />
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }

  ishandleDeletePosition(row: any, mode: any) {
    if (mode) {
      this.handleDeletePosition(row);
    }
  }

  handleDeletePosition = (row: any) => {
    this.setState({ loaderTrue: true });
    let deleteRequest: any = {};
    deleteRequest.departmentID = row.deptID;
    laborDeptPosition
      .LaborDepartmentDelete(deleteRequest)
      .then(async (result: any | null) => {
        if (result != null) {
          
          if (result.status === "Success") {
            toast.success(result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborDepartment",
            });
            if (
              result.message.toString().toLowerCase() ===
              "department deleted successfully"
            ) {
              let tableDataNew = this.state.actualDepartmentData;
              const index = tableDataNew.findIndex((item)=> item.deptID===row.deptID);
              if (index > -1) {
                tableDataNew.splice(index, 1);
               }
             
              if (this.state.showDeactivatedDept) {
                this.setState({
                  tableData: tableDataNew.filter(
                    (items) =>
                      items.status.toString().toLowerCase() === "inactive"
                  ),
                });
              } else {
                this.setState({
                  tableData: tableDataNew.filter(
                    (items) => items.status.toString().toLowerCase() === "active"
                  ),
                });
              }

              // this.setState( {
              //     showDeactivatedDept: false,
              //     loaderTrue: false,
              //     isNewDept: false, },
              //   () => {
              //     this.props.toggleDepartment(false);
              //     this.getlaborDepartment();
              //   }
              // );
            }
          } else {
            Utils.toastError(
              "This department cannot be deleted as associated with one or more position.",
              {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "LaborDepartment",
              }
            );
          }
        } else {
          Utils.toastError("Internal server error.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "LaborDepartment",
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  searchedData = (newResult: any) => {
    this.setState({ SearchData: newResult });
  };

  renderTooltip = (ehids) => {
    return (
      <Tooltip className="LDtoolTipLettercodes" id="button-tooltip" {...ehids}>
        {ehids.toolTipLettercodes.replaceAll(",", ", ")}
      </Tooltip>
    );
  };

  deactivateDepartment = (cell: any, row: any, rowIndex: any) => {
    return (
      <Dropdown
        // onClick={(e) => {
        //   e.stopPropagation();
        // }}
        className="more-action hasDropShadow"
        alignRight
      >
        <Dropdown.Toggle
          className="btn-outline-primary more fa-trash dropdown-toggle btn btn-primary"
          id="dropdown-more"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu className="hasDropdownMenu">
          <Dropdown.Item onClick={(e) => this.actionDepartment(e, row, rowIndex)}>
            {row.status === "Active" ? "Deactivate" : "Activate"}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  deativatedDeptChange = (e) => {
    //let { tableData, actualtableData } = this.state;
    let { checked } = e.target;
    this.setState({ showDeactivatedDept: checked, isNewDept: false }, () => {
      this.props.toggleDepartment(checked);
      this.props.handleNewDepartment(true);
    });
    let tableDataNew = this.state.actualDepartmentData;
    if (checked === true) {
      this.setState({
        tableData: tableDataNew.filter(
          (items) => items.status.toString().toLowerCase() === "inactive"
        ),
      });
    } else {
      this.setState({
        tableData: tableDataNew.filter(
          (items) => items.status.toString().toLowerCase() === "active"
        ),
      });
    }
  };

  toggleDepartment = () => {
    this.getlaborDepartment();
  };

  actionDepartment = (e, row, rowIndex) => {
    
    let request: any = {};
    request.DeptPosID = row.deptID;
    request.TenantID = row.tenantID;
    request.IsActive = row.status === "Active" ? 0 : 1;
    Hotel.UpdateStatusLaborDepartmentMaster(request)
      .then(async (result: any) => {
        let currentStatus =
          row.status === "Active" ? "Deactivated" : "Activated";
        let data = result.result;
        if (data != null) {
          if (data.result === "Success") {
            //this.getlaborDepartment();
            
            let tableDataNew = this.state.actualDepartmentData;
            let affectedRow =  tableDataNew.filter((item) =>item.deptID===row.deptID && item.tenantID === row.tenantID);
            affectedRow[0].status=affectedRow[0].status === "Active"?"Inactive":"Active";
            //  if (rowIndex > -1) {
            //   tableDataNew.splice(rowIndex, 1);
            //  }
            //  tableDataNew.splice(rowIndex,0,affectedRow[0]);

           // let newdata :any = _.uniqBy(tableDataNew,'acctType');
            if (this.state.showDeactivatedDept) {
              this.setState({
                tableData: tableDataNew.filter(
                  (items) =>
                    items.status.toString().toLowerCase() === "inactive"
                ),
              });
            } else {
              this.setState({
                tableData: tableDataNew.filter(
                  (items) => items.status.toString().toLowerCase() === "active"
                ),
              });
            }
            //this.getlaborDepartment();
            // this.setState(
            //   { showDeactivatedDept: false, loaderTrue: false },
            //   () => {
            //     this.props.toggleDepartment(false);
            //     this.getlaborDepartment();
            //   }
            // );
            toast.success(`Department ${currentStatus} Successfully`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborDepartment",
            });
          } else if (data.result === "Not-Exists") {
            Utils.toastError("Department does not exist.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborDepartment",
            });
            this.setState({ loaderTrue: false });
          } else if (data.result === "Position-Exists") {
            Utils.toastError("Positions are active for this department.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborDepartment",
            });
            this.setState({ loaderTrue: false });
          } else {
            Utils.toastError(data.result, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "LaborDepartment",
            });
            this.setState({ loaderTrue: false });
          }
        } else {
          Utils.toastError("Internal server error.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "LaborDepartment",
          });
          this.setState({ loaderTrue: false });
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  render() {
    let columns = [
      {
        dataField: "deptName",
        text: "Departments",
        editable: !this.state.showDeactivatedDept,
        formatter: this.newDepartmentformater,
        sort: this.state.isNewDept === true ? false : true,
      },
      {
        dataField: "lettercode",
        text: "EHID",
        editable: !this.state.showDeactivatedDept,
        formatter: this.LatterCodeFormater,
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <div className="department-filter">
            {row.deptName.length > 0 && (
              <SearchMultiSelectItems
                itemsList={this.state.EHIDs}
                itemsSelected={this.itemsSelected}
                selectedItems={row.selectedIDs}
                {...editorProps}
                value={value}
                placeholder={"Search EHID"}
              />
            )}
          </div>
        ),
      },
      {
        dataField: "action",
        text: "",
        editable: false,
        searchable: false,
        hidden: this.state.isNewDept,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.deactivateDepartment(cell, row, rowIndex),
      },
      {
        dataField: "action",
        text: "",
        hidden: this.props.manageDept,
      },
      {
        dataField: "action",
        text: "",
        hidden: !this.props.manageDept,
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.formatColumn(cell, row, rowIndex),
      },
    ];
    var loaderTrue = this.state.loaderTrue;
    var loadShow;
    if (loaderTrue) {
      loadShow = <PageLoader />;
    }
    return (
      <div className="body-sec position dept-pos" onKeyPress={this.onKeyPress}>
        {/* <ToastContainer
          autoClose={3000}
          containerId={"LaborDepartment"}
          enableMultiContainer
        /> */}
        {loadShow}
        <div className="">
          <Form>
            {this.state.isMountDone && (
              <ToolkitProvider
                keyField="deptID"
                data={this.state.tableData}
                columns={columns}
                rowEvents={this.rowEvents}
                search={{
                  afterSearch: (newResult: any) => {
                    this.searchedData(newResult);
                  },
                }}
              >
                {(props: {
                  searchProps: JSX.IntrinsicAttributes;
                  baseProps: JSX.IntrinsicAttributes;
                }) => (
                  <div className="pos-rel">
                    <div className="search-filter d-flex justifyBetween">
                      <label id="labeltableSearch" className="search-label">
                        <TableSearch
                          id="tableSearch"
                          key="tableSearch"
                          ref={this.child}
                          {...props.searchProps}
                          //handleNewPosition={this.handleNewPosition.bind(this)}
                          CleanSearch={this.CleanSearch.bind(this)}
                          placeholder={"Search..."}
                        />
                      </label>
                      <Form.Check
                        id="showDeactivatedDept"
                        key="showDeactivatedDept"
                        type="switch"
                        label="Show Deactivated"
                        checked={this.state.showDeactivatedDept}
                        onChange={this.deativatedDeptChange}
                      />
                    </div>
                    <div className="cusPos">
                      <div className="position-list depart LDPsetlastW4">
                        <div
                          className={
                            this.state.SearchData.length > 5
                              ? "maxlength pos-rel"
                              : "minlength pos-rel"
                          }
                        >
                          {this.state.isNewDept && (
                            <div
                              className="bck-drop zIndex-1"
                              onClick={this.FocusTextbox}
                            ></div>
                          )}
                          <BootstrapTable
                            rowEvents={this.rowEvents}
                            {...props.baseProps}
                            keyField="deptID"
                            cellEdit={
                              this.props.manageDept ? this.cellEdit : ""
                            }
                            //cellEdit={this.cellEdit}
                            hover
                            condensed
                          />
                        </div>
                        {!this.state.isNewDept && this.props.manageDept && (
                          <button
                            type="button"
                            className="btn wht-bg txt-green rm-pad"
                            disabled={this.state.showDeactivatedDept}
                            onClick={this.CleanSearch}
                          >
                            Add Department
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
