import * as React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { Container, Dropdown, Form, Button, OverlayTrigger, Tooltip, Modal, Spinner } from "react-bootstrap";
import { resolve, reject } from "q";
import { Utils } from "../../Common/Utilis";
//import "./comment.scss";
import profile from "../../Common/Assets/Images/profile.png";
import { Register as registersevice } from "../../Common/Services/Register";
import { toast } from "react-toastify";
import {
    IWorkOrderRequestDto,
    IWorkOrderSummaryRequestDto,
    IWorkOrderDeleteRequestDto,
  } from "../../Common/Contracts/IWorkOrder";
  import { WorkOrder } from "../../Common/Services/WorkOrder";
  import {
    IWorkOrderCommentRequestDto,
  } from "../../Common/Contracts/IWorkOrder";

// let profile=null;
export default class AddCommentBox extends React.Component<any, any> {
    private scrollToBottomlist: any;
    constructor(props: any) {
        super(props);
        this.scrollToBottomlist = React.createRef();
        this.state = {
            hotelid: this.props.hotelid,
            entryType: this.props.entryType,
            workOrderId: this.props.workOrderId,            
           // precommentlist: this.props.precommentlist,
           precommentlist:[],
            value: '',
            mentionData: null,
            commentlist: [],
            users: [],
            htmlcomment: [],
            userComments: [],
            logInUser: "",
            logInUserImage: "",
            isCommentAdd: false,
            addCommentDisabled : this.props.addCommentDisabled,
        };
    }

    onTAB = (event) => {   
        if( this.state.value.trim().length > 0)   
        {
        if (event.key.toLowerCase() === "tab") {
            let txtID = "btncomment";
           
            // setTimeout(() => {
            //     $("button[id^='" + txtID + "']").focus();
            // }, 50);
        }
        if (event.key.toLowerCase() === "enter") {
          // this.addComment();
        }
    }
    }

    handleChange = (event, newValue, newPlainTextValue, mentions) => {
        let text = newPlainTextValue;
        this.setState({
            value: newValue,
            mentionData: { newValue, newPlainTextValue: text, mentions: mentions }
        })
        setTimeout(() => {
            this.props.parentScrollMethod();
        }, 50)
    }
    handleFocus = () => {
        setTimeout(() => {
            this.props.parentScrollMethod();
        }, 50)
    }


    componentDidMount() {
        this.getCommentWorkOrder();
        this.usernameList();

    }

    getCommentWorkOrder() {
        this.setState({ isworkLoader: true });
        let request = {} as IWorkOrderCommentRequestDto;
        request.hotelid =  Number(this.state.hotelid);
        request.workOrderID =Number(this.state.workOrderId);
        WorkOrder.getCommentWorkOrder(request)
          .then(async (result: any | null) => {          
            if (result != null && result.length > 0) {
              this.setState({ precommentlist: result},()=>{
                this.loadPrevComment();
              });
            } else {
              this.setState({ precommentlist: []});
            }
           
          
            resolve();
          })
          .catch((err) => {
            this.setState({ isworkLoader: false });
            Utils.toastError(`Server Error, ${err}`, {
            });
    
            reject();
          });
      }

   
    loadPrevComment = () => {
        debugger;
        if (this.state.precommentlist.length > 0) {
            let itemComment: any[] = [];
            this.state.precommentlist.forEach(element => {
                let savedComment = element.htmlComment;
                if (element.displayUserTo.length > 0) {
                    let displayusers = element.displayUserTo.split(',')
                    displayusers.forEach(userTo => {

                        let logInUser = this.state.users.filter(x => x.username === userTo.replace("@", ""));

                        if (logInUser.length === 0) {
                            savedComment = savedComment.replaceAll(userTo, '<span class ="isNotPermission">' + userTo + '</span>')
                        }

                    });
                }

                // let  comment1 = element.htmlComment.replaceAll(element.display, '<span class ="isNotPermission">' + element.display + '</span>')

                itemComment.push(<li className="d-flex">
                    <div className="profile"><img className={element.profileimage === "" ? 'default' : ''}
                        src={element.profileimage === "" ? profile : element.profileimage}
                        width="36" height="36" alt={element.createdBy} /></div>
                    <div className="profile-details">
                        <div className="name">{element.createdBy}<span> {element.commentDate.length > 0 ?
                            Utils.getLocalDateTimeFromUtc(element.commentDate) : ""}</span></div>
                        <div className="comment" dangerouslySetInnerHTML={{ __html: savedComment.replace('\n', '<br/>') }}></div>
                    </div>
                </li>)

            });

            this.setState({ htmlcomment: itemComment });

        }
    }

    CommentToSave = () => {
        let array: any = [];
        if (this.state.workOrderId > 0) {
            return array;
        }
        return this.state.userComments;
    }

    isCommentAdd = () => {
        return this.state.value.trim().length > 0 ? true : false;
    }

    retsetComment = () => {
        this.setState({ userComments: [] })
    }


    addComment = () => {
        debugger
        let comment: any = "";
        let comment1: any = "";
        let itemComment: any[] = [];
        let displayUser: any[] = [];

        let updatedCommentItem: any = {};
        updatedCommentItem = this.props.updatedCommentItem();

        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : (storage.userName as any);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let currentDateTime = new Date().toLocaleString(); //March 22nd at 3:32pm
        let userComments = Object.assign([], JSON.parse(JSON.stringify(this.state.userComments)));

        if (this.state.workOrderId > 0) {
            userComments = [];
        }

        comment = this.state.mentionData.newPlainTextValue.trim();
        if (comment.length > 0) {
            
            if (this.state.mentionData.mentions.length > 0) {
                this.state.mentionData.mentions.forEach(element => {
                 
                    let userCount = displayUser.filter(x => x.display === element.display.trim()).length;

                    if (userCount === 0) {
                        comment1 = comment.replaceAll(element.display.trim(), '<span>' + element.display.trim() + '</span>')
                        comment = comment1;
                        displayUser.push(element.display.trim());
                    }
                  

                    let usercomment: any = {};
                     usercomment.WorkorderNo = this.state.workOrderId;
                  //  usercomment.entryType = this.state.entryType;
                   // usercomment.logInuser = userName;
                   // usercomment.createDate = currentDateTime.toString();
                    usercomment.AssignedTo = element.display.trim().replace("@", "");
                    usercomment.Comment = this.state.mentionData.newPlainTextValue;
                    usercomment.htmlcomment = comment1.replaceAll('\n', '<br/>');
                    usercomment.Tenantid = tenantID;
                    usercomment.Hid =  Number(this.state.hotelid);;
                     usercomment.LocationName = this.props.parentState.parentRowData.locationName;
                     usercomment.AssetName = this.props.parentState.assetList.filter(((item) => item.id === this.props.parentState.parentRowData.assetID))[0]?.name;
                   
                    
                  //  usercomment.date = updatedCommentItem.transcationDate;
                  //  usercomment.vendor = updatedCommentItem.vendorName;
                  //  usercomment.amount = updatedCommentItem.amountTotal;

                    userComments.push(usercomment);

                });

                if (userComments.length > 0) {
                    userComments.forEach(ele => {
                       // ele.htmlcomment = ele.htmlcomment.replaceAll('\n', '<br/>');
                        ele.HtmlComment = comment1.replaceAll('\n', '<br/>');
                    });
                }
            }
            else {
                comment1 = this.state.mentionData.newPlainTextValue;
                let usercomment: any = {};
                usercomment.WorkorderNo = this.state.workOrderId;
              //  usercomment.entryType = this.state.entryType;
               // usercomment.logInuser = userName;
                //usercomment.createDate = currentDateTime;
               // usercomment.toUser = "";
               // usercomment.comment = this.state.mentionData.newPlainTextValue;
                //usercomment.htmlcomment = comment1.replaceAll('\n', '<br/>');
              //  usercomment.tenantID = tenantID;
               // usercomment.date = updatedCommentItem.transcationDate;
              //  usercomment.vendor = updatedCommentItem.vendorName;
              //  usercomment.amount = updatedCommentItem.amountTotal;

              usercomment.AssignedTo="";
              usercomment.Comment = this.state.mentionData.newPlainTextValue;
              usercomment.htmlcomment = comment1.replaceAll('\n', '<br/>');
              usercomment.Tenantid = tenantID;
              usercomment.Hid = Number(this.state.hotelid);;

              usercomment.LocationName = this.props.parentState.parentRowData.locationName;
              usercomment.AssetName = this.props.parentState.assetList.filter(((item) => item.id === this.props.parentState.parentRowData.assetID))[0]?.name;

                userComments.push(usercomment);
            }



            let newcomment: any = {};


            this.state.htmlcomment.forEach(element => {
                itemComment.push(element);
            });

            itemComment.push(<li className="d-flex">
                <div className="profile"><img className={this.state.logInUserImage === "" ? 'default' : ''}
                    src={this.state.logInUserImage === "" ? profile : this.state.logInUserImage} width="36" height="36" alt="Inn-Flow" /></div>
                <div className="profile-details">
                    <div className="name">{userName}<span> {Utils.getLocalDateTimeWithoutUtc(new Date())}</span></div>
                    <div className="comment" dangerouslySetInnerHTML={{ __html: comment1.replaceAll('\n', '<br/>') }}></div>
                </div>
            </li>)


            this.setState({
                htmlcomment: [],
                userComments: [],
                value: '',
                mentionData: {}
            }, () => {
                this.setState({ htmlcomment: itemComment, userComments }, () => {
                    if (this.state.workOrderId > 0) {
                        debugger;
                        this.saveComments();
                    }
                })
            })
        }
        setTimeout(() => {
            this.scrollToBottomlist.current.scrollTo({
                top: this.scrollToBottomlist.current.scrollHeight,
                behavior: 'smooth',
            });
        }, 100)
    }



    usernameList = () => {
        debugger;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : (storage.userName as any);
        // let userRequest: any = {};
        // userRequest.hotelid = this.state.hotelid;
        // registersevice.usernameList(userRequest)
        //     .then(async (result: any | null) => {
        //         if (result !== null) {
        //             if (result.length > 0) {
        //                 let logInUser = result.filter(x => x.username === userName);
        //                 let logInUserimage = logInUser[0].image
        //                 this.setState({ users: result, logInUserImage: logInUserimage }, () => {
        //                     this.loadPrevComment();
        //                 })
        //             }

        //         }
        //         resolve();
        //     })
        //     .catch((error) => {
        //         reject();
        //     });

        let request = {} as IWorkOrderRequestDto;
        request.hotelID = Number(this.state.hotelid);
       // request.type = "AssignUsers";
        WorkOrder.UsernameDetails(request)
          .then(async (result: any | null) => {
            debugger;
            if (result != null && result.length > 0) {
              let Data = result.map(x => {
                return { id: x?.user_UniqueID, username: x?.employee,position:"abc" };
              })
    
              if (result.length > 0) {
                                let logInUser = result.filter(x => x.username === userName);
                                let logInUserimage = logInUser[0]?.image===undefined ? "" :logInUser[0]?.image;
                                this.setState({ users: result, logInUserImage: logInUserimage }, () => {
                                    this.loadPrevComment();
                                })
                            }

            } else {
             // this.setState({ assignToList: [{ id: -1, name: "All" }],assignToListToChild:[{ id: -1, name: "Select a technician" }] });
            }
            resolve();
          })
          .catch((err) => {
            Utils.toastError(`Server Error, ${err}`, {
            });
    
            reject();
          });









    };


    saveComments = () => {
        debugger;
        WorkOrder.SaveWorkOrderComment(this.state.userComments)
            .then(async (result: any | null) => {
                if (result !== null) {
    
                   
                    if (result.saveStatus === "Success") {

                       
                    }
                    else{
                      
                        if(result.messageCode === "Validation"){
                            Utils.toastError(result.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "InvoiceSlideout",
                            });
                           // this.setState(() => {
                                this.props.commentModalClose();
                        //})
                        }   
                    }
                    
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });

    }


    render() {
        const userMentionData = this.state.users.map(myUser => ({
            id: myUser.username,
            username: myUser.username,
            name: myUser.name,
            image: myUser.image,
            email: myUser.email,
            position: myUser.position,
            display: `@${myUser.username + ' '}`,
        }))
        return (

            <div className={`${this.state.value.trim().length > 0 ? "comment-box add-comment-button" : "comment-box"} ${this.state.htmlcomment.length === 0 ? "empty-comments" : "" }`}>

                <div className="comment-list mb-1">
                    {/* <div className="sec-ttl">Comments</div>
                    {this.state.htmlcomment.length === 0 && (
                        <div className='no-comment-placeholder'>None</div>
                    )} */}
                    <ul ref={this.scrollToBottomlist}>
                        {this.state.htmlcomment}
                    </ul>
                </div>
                <div className={`${this.state.addCommentDisabled ? "comment-area d-flex disabled" : "comment-area d-flex"}`}>
                    <div className="profile"><img className={this.state.logInUserImage === "" ? 'default' : ''}
                        src={this.state.logInUserImage === "" ? profile : this.state.logInUserImage} width="36" height="36" alt="Inn-Flow" /></div>
                    <div className="inpute-field">
                        <MentionsInput
                            id="txtcomment"
                            className="mentions__mention"
                            markup="@{{__type__||__id__||__display__}}"
                            value={this.state.value}
                            onChange={this.handleChange}
                            onClick={this.handleFocus}
                            placeholder="Type @ to notify another user."
                            onKeyDown={(e: any) => this.onTAB(e)}
                        //singleLine

                        >
                            <Mention
                                type="user"
                                trigger="@"
                                data={userMentionData}
                                className="mentions__mention"
                                //displayTransform={(id, display) => `@${display}`}
                                renderSuggestion={(entry, search, highlightedDisplay, index, focused) => (
                                    <div className="d-flex align-items-center justify-content-start">
                                        <div className="profile">
                                            <img className={entry.image === "" ? 'default' : ''} src={entry.image === "" ? profile : entry.image} width="36" height="36" alt={entry.username} />
                                        </div>
                                        <div>
                                            <div className="user-name">{entry.username}</div>
                                            <div className="user-pos">{entry.position}</div>
                                        </div>
                                    </div>
                                )}
                            />
                        </MentionsInput>
                        {this.state.value.trim().length > 0 && (
                            <div className="d-flex justify-content-end add-comment">
                                <button id="btncomment" type="button" className="btn btn-primary" onClick={this.addComment} >
                                    Add Comment
                                </button>
                            </div>
                        )}

                        {/* <hr />
                        {JSON.stringify(this.state.mentionData)}
                        <hr />
                        <p>The raw text is:</p>
                        <p>
                            {this.state.value}
                        </p> */}
                    </div>
                </div>
            </div>
        );
    }
}