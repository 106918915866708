import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModuleReports } from "../../Common/Services/ModuleReports";

export class HireDateSortSelector extends React.Component<any, any> {
  private sortParameters = [
    { label: "Select Sorting", value: "" },
    { label: "Ascending", value: "Ascending" },
    { label: "Descending", value: "Descending" },
  ];

  constructor(props: any) {
    super(props);

    this.state = {
      selectedItem: { label: "Select Sorting", value: "" },
      sortParameters: this.sortParameters,
    };
  }

  componentDidMount = () => {
    debugger;
    const { defaultValue } = this.props;
    if (defaultValue === "Ascending") {
      this.handleDropdownChange({ label: "Ascending", value: "Ascending" });
    } else if (defaultValue === "Descending") {
      this.handleDropdownChange({ label: "Descending", value: "Descending" });
    } else {
      this.handleDropdownChange(
        this.sortParameters.filter((x) => x?.label === defaultValue)[0]
      );
    }
  };

  handleDropdownChange = (item: any) => {
    this.setState({ selectedItem: item });
    //this.props?.handleCOAType(item.value);
    this.props?.handleReportSortSelectorChange(item?.value);
  };

  componentDidUpdate = (prevprops, prevState) => {
    const { defaultValue } = this.props;
    if (prevprops.defaultValue !== defaultValue && defaultValue?.length > 0) {
      let arr = this.state.sortParameters.filter(
        (e) => e.value === defaultValue
      );
      this.setState({
        selectedItem:
          arr.length > 0 ? arr[0] : { label: "Select Sorting", value: "" },
      });
    }
    if (!defaultValue && prevprops?.defaultValue !== defaultValue) {
      this.setState({
        selectedItem: { label: "Select Sorting", value: "" },
      });
    }
  };

  render() {
    return (
      <Dropdown className="single-select">
        <Dropdown.Toggle id="Modal-empType">
          <span
            style={{
              width: "95%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {this.state.selectedItem?.label}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.sortParameters.map((item: any, idx: any) => (
              <Dropdown.Item onClick={() => this.handleDropdownChange(item)}>
                {item?.label}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
