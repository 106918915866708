import * as React from "react";
import {
  // Dropdown, OverlayTrigger, Tooltip,
  Form,
} from "react-bootstrap";
import { ModuleReports } from "../../Common/Services/ModuleReports";
import { DropDownMultiList } from "./common-components/dropdown-multi-list";

export class ACACodeSelector extends React.Component<any, any> {
  private checkBoxRef: any;
  constructor(props: any) {
    super(props);
    this.checkBoxRef = React.createRef();
    this.state = {
      accTypeCodes: [],
      accTypeHarborCodes: [],
    };
  }
  getACACodes = async () => {
    try {
      const result: any[] = await ModuleReports.GetACACodes(
        this.props.accTypeID
      );

      if (result?.length > 0) {
        let codes: any[] = [];

        if (this.props?.defaultValue?.length > 0) {
          codes = this.props?.defaultValue.split(",");
        }
        if (+this.props.accTypeID === 1) {
          this.setState({
            accTypeCodes: result.map((item) => {
              if (codes.includes(item.code)) {
                item.isChecked = true;
              } else {
                item.isChecked = false;
              }

              return item;
            }),
            accTypeHarborCodes: [],
          });
        } else {
          this.setState({
            accTypeHarborCodes: result.map((item) => {
              if (codes.includes(item.code)) {
                item.isChecked = true;
              } else {
                item.isChecked = false;
              }
              return item;
            }),
            accTypeCodes: [],
          });
        }
      }
    } catch (error) {
      // Need to handle this
      console.log(error);
    }
  };

  componentDidMount = () => {
    this.getACACodes();
  };

  handleDropdownChange = (e: any) => {
    // this.props?.onYearChange(e);
  };

  //  onCodesChange =() : void => {
  //      const {accTypeCodes } = this.state;
  //      const codes = accTypeCodes.filter(r=>r.checked).map(r=>r.code)?.join();
  //      if(+this.props?.accTypeID=== 1) {
  //         this.props?.onCoverageCodesChange(codes);
  //      } else {
  //         this.props?.onSaferCodesChange(codes);
  //      }
  //  }
  updateMultiSelectItem = (selectedItem: any) => {
    if (+this.props?.accTypeID === 1) {
      this.props?.onCoverageCodesChange(selectedItem);
    } else {
      this.props?.onSaferCodesChange(selectedItem);
    }
    // this.setState({ selectedLetterCode: selectedItem }, () => {
    //   this.props.handleBusinessAccountChange(selectedItem);
    // });
  };

  dataChange = () => {
    //  this.setState({ isUnsavedChanges: true });
  };

  render() {
    return (
      <div>
        {/* <Form.Group
          controlId={
            +this.props.accTypeID === 1 ? "ACACODES" : "ACAHARBOURCODES"
          }
        > */}
          {this.state.accTypeCodes.length > 0 && (
            <>
              {/* <Form.Label className="sub-ttl">ACA COVERAGE CODES</Form.Label> */}
              <DropDownMultiList
                defaultValue={this.props?.defaultValue}
                label={"code"}
                value={"code"}
                data={[...this.state.accTypeCodes]}
                updateMultiSelectItem={this.updateMultiSelectItem}
                dataChange={this.dataChange.bind(this)}
                isSelectAll={true}
                placeHolder={"Select ACA COVERAGE CODES"}
                filtertitle={"Filter ACA Codes"}
              />{" "}
            </>
          )}
          {this.state.accTypeHarborCodes.length > 0 && (
            <>
              {/* <Form.Label className="sub-ttl">ACA HARBOUR CODES</Form.Label> */}
              <DropDownMultiList
                defaultValue={this.props?.defaultValue}
                label={"code"}
                value={"code"}
                data={[...this.state.accTypeHarborCodes]}
                updateMultiSelectItem={this.updateMultiSelectItem}
                dataChange={this.dataChange.bind(this)}
                isSelectAll={true}
                placeHolder={"Select ACA HARBOUR CODES"}
                filtertitle={"Filter ACA Harbor Codes"}
              />
            </>
          )}
        {/* </Form.Group> */}
      </div>
    );
  }
}
