
import axios from "axios";

import { IFacilityPreventativeMaintenanceRequest, IPMAssignToChangeRequestDto, IPMCalculationRequestDto, IPMCommentRequestDto, IPMTaskListRequestDto } from "../Contracts/IPreventativeMaintenance";
import { IWorkOrderRequestDto, IWorkOrderUsers } from "../Contracts/IWorkOrder";

import Instense from "./Axios-config";

export class FacilityPreventativeMaintenance {
  private static ajaxRequestPreventativeMaintenance: any = null;

  public static GetAllPreventativeMaintenance = async (
    request: IFacilityPreventativeMaintenanceRequest
  ): Promise<any[] | null> => {
    if (FacilityPreventativeMaintenance.ajaxRequestPreventativeMaintenance) {
      FacilityPreventativeMaintenance.ajaxRequestPreventativeMaintenance.cancel(
        "Cancel"
      );
    }

    FacilityPreventativeMaintenance.ajaxRequestPreventativeMaintenance =
      axios.CancelToken.source();
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantId = tenantID;
    const url = `/FacilityMaintenance/GetAllPreventativeMaintenance`;
    return Instense.get(url, {
      params: { ...request },
      cancelToken:
        FacilityPreventativeMaintenance.ajaxRequestPreventativeMaintenance
          .token,
    }).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  public static StartEarly = async (request: any): Promise<any> => {
    const url = `/FacilityMaintenance/StartEarly`;
    return Instense.get(url, { params: { ...request } }).then((response) => {
      let result = response?.data?.result;
      return result;
    });
  };

  public static GetFrequency = async (): Promise<any[] | null> => {
    const url = `/FacilityMaintenance/GetFrequency`;
    return Instense.get(url).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  public static GetPriority = async (): Promise<any[] | null> => {
    const url = `/FacilityMaintenance/GetPriority`;
    return Instense.get(url).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  public static GetStatusFacilityMaintenanceSchedule = async (): Promise<
    any[] | null
  > => {
    const url = `/FacilityMaintenance/GetStatusFacilityMaintenanceSchedule`;
    return Instense.get(url).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  public static GetRoomByMaintenance = async (CycleId,hid,maintenanceid)=> {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    const url = `/FacilityMaintenance/Get_RoomByMaintenance?CycleId=${CycleId}&TenantId=${tenantID}&Hid=${hid}&MaintenanceId=${maintenanceid}`;
    return Instense.get(url).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  public static GetRoomByMaintenanceRightPanle = async (CycleId,hid,maintenanceid,type,locationId)=> {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);

    const url = `/FacilityMaintenance/Get_RoomByMaintenance?CycleId=${CycleId}&TenantId=${tenantID}&Hid=${hid}&MaintenanceId=${maintenanceid}&type=${type}&locationId=${locationId}`;
    return Instense.get(url).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  public static SaveMaintenanceFile = async (
    request: any, files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    const url = `/FacilityMaintenance/SaveMaintenanceFile`;
    const formData: FormData = new FormData();
    files.forEach(element => {
      formData.append('file', element);
    });

    formData.append('formField', JSON.stringify(request));
    return Instense.post(url, formData).then((response) => {
      let result = response.data.result as any;       
      return result;
    });
  };

  public static GetPreventiveMaintenanceUploadFile = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    // debugger

    // request.tenantID = tenantID;
    const url = `/FacilityMaintenance/GetPreventiveMaintenanceUploadFile`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static UpdateMaintenanceStatus = async (
    request: any
  ): Promise<any | null> => {           
    const url = `/FacilityMaintenance/UpdateMaintenanceStatus`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  }; 
  
  public static UsernameDetails = async (
    request: IWorkOrderRequestDto
  ): Promise<IWorkOrderUsers | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/FacilityMaintenance/UsernameDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IWorkOrderUsers;
      return result;
    });
  };
  
  public static DeletePMFiles = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    // debugger

    request.tenantID = tenantID;
    const url = `/FacilityMaintenance/DeletePMFiles`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });

  };

  public static DownloadPMUploadFile = async (
    request: any
  ): Promise<any | null> => {
    //debugger;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/FacilityMaintenance/DownloadPMUploadFile`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {

        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", request.filename);
        tempLink.click();
      }
    );
  };

  public static SavePMComment = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
      const url = `/FacilityMaintenance/SavePreventiveMaintenanceComment`;
      return Instense.post(url, request).then((response) => {
        let result = response.data.result as any;
        return result;
      });
    
  };

  public static getCommentPM = async (
    request: IPMCommentRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/FacilityMaintenance/GetPMAssignedComment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IWorkOrderUsers;
      return result;
    });
  };

  public static UpdateMaintenanceAssignedTo = async (
    request: IPMAssignToChangeRequestDto
  ): Promise<any | null> => {           
    const url = `/FacilityMaintenance/UpdateMaintenanceAssignedTo`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  }; 

  public static GetPMCalculations = async (
    request: IPMCalculationRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/FacilityMaintenance/Get_PMCalculations`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IWorkOrderUsers;
      return result;
    });
  };
  // public static GetPMCalculations = async (request: IPMAssignToChangeRequestDto)=> {
  //   let storage = JSON.parse(localStorage.getItem("storage")!);
  //   let tenantID = storage === null ? 0 : (storage.tenantID as any);

  //   const url = `/FacilityMaintenance/Get_PMCalculations?CycleId=${CycleId}`;
  //   return Instense.get(url).then((response) => {
  //     let result = response?.data?.result as any[];
  //     return result;
  //   });
  // };

  public static AddCycleRoomTaskStatus = async (
    request: any
  ): Promise<any | null> => {           
    const url = `/FacilityMaintenance/AddCycleRoomTaskStatus`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  }; 

  public static CompletePMCycle = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantId = tenantID;
    const url = `/FacilityMaintenance/CompletePMCycle`;
    return Instense.post(url,  request ).then((response) => {
      let result = response.data
      //debugger;
      return result;
    });
  };

}
