import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dropdown, Form, Row, Col, ToggleButtonGroup, ToggleButton, ButtonGroup, Button } from "react-bootstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { SingleSearchDropdownList } from "../../../../Common/Components/SingleSearchDropdownList";
import { LaborPerformance as laborPerformance } from "../../../../Common/Services/LaborPerfomance";
import filterFactory from "react-bootstrap-table2-filter";
import TableSearch from "../../../../Common/Components/TableSearch";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ForecastLock from "../../../../Common/Assets/Images/icons/forecast-lock.svg";
import chevronRight from "../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { EmployeePositions } from "./EmployeePositions";
import { ILaborSaveAsDefaultDto } from "../../../../Common/Contracts/ILaborSaveAsDefaultDto";
import profile from "../../../../Common/Assets/Images/profile.png";
import { Redirect } from "react-router-dom";
import { Utils } from "../../../../Common/Utilis";
import { LaborPerformance } from "../../../../Common/Services/LaborPerfomance";
import { resolve, reject } from "q";
export class EmployeeView extends React.Component<any, any> {
    private calender: any;
    private ddlPosition: any;
    private ddlDepartment: any;
    private positionSelect: any;
    private positionFromTop: any;
    private child: any;
    private pageName = "Performance";
    private tabName = "employee";
    constructor(props: any) {
        super(props);
        this.calender = React.createRef();
        this.ddlPosition = React.createRef();
        this.ddlDepartment = React.createRef();
        this.positionSelect = React.createRef();
        this.positionFromTop = React.createRef();
        this.child = React.createRef();
        this.state = {

            performanceSummery: [],
            tableData: [],
            firstWeek: [],
            secoundWeek: [],
            dayTotal: [],
            firstdayTotal: [],
            secounddayTotal: [],

            hidValue: "Select",
            hotelName: "Select",
            pageloader: true,

            calenderDates: [],
            calenderSelectDate: {},
            tempSelectedDate: "",
            calenderShow: false,

            departmentpositionList: [],
            departmentList: [],
            positionList: [],
            selectedDepartment: 0,
            selectedPosition: 0,
            defaultDepartment: "Department",
            defaultPosition: "Position",

            selectedFilter: "Name",
            filterList: [
                { id: "deptName", value: "Department" },
                { id: "totalActualinmin", value: "Actual" },
                { id: "totalScheduleinmin", value: "Schedule" }
            ],

            dataComparison: ["Variance", "Schedule"],
            activeComparison: 1,
            actualHighlight: 0,
            isOptionHighlight: false,

            exportedDate: "",
            payPeriodDates: [],
            paytype: "",
            defaultPayType: "",
            field: "userName",
            order: "asc",

            tableExpended: [],
            isExpand: false,
            deptRow: [],
            isDeptExpend: false,
            isRedirect: false,
            mode: 0,
            scrollbarClick: false,
            isPageLoad: true,
            hidwiseDate: "",
        }
    }

    componentDidMount() {
        debugger;
        window.addEventListener("mousedown", this.onMouseDown, true);
        this.GetCurrentDateTimeByHid(this.props.hidValue, "", "componentDidMount");
    }


    componentDidMountNew(hotelWiseDate: any) {

        window.addEventListener("mousedown", this.onMouseDown, true);
        let currentdate = "";
        let currentMonth = "";
        let currentYear = "";
        let todayDate: any = new Date();
 
        todayDate = new Date(hotelWiseDate);

        if (this.props?.paramDate) {
            todayDate = new Date(this.props?.paramDate);
        }
        currentdate = todayDate.getDate();
        currentMonth = todayDate.getMonth() + 1;
        currentYear = todayDate.getFullYear();
        let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;

        this.setState({
            departmentpositionList: this.props.departmentpositionList,
            departmentList: this.props.departmentList,
            positionList: this.props.positionList,
            hidValue: this.props.hidValue,
            hotelName: this.props.hotelName,
            tempSelectedDate: tempDate,
            calenderSelectDate: tempDate,
            pageloader: true,
            hidwiseDate: tempDate
        })
        this.getDefaultView();
        this.setDefaultURLParamValues();
    }


    GetCurrentDateTimeByHid = (hotelID: any, hotelDetails: any, type: any) => {

        LaborPerformance.GetCurrentDateTimeByHid(hotelID)
            .then(async (result: any | null) => {
                if (result != null) {

                    if (type === "componentDidMount") {
                        this.componentDidMountNew(result.currentDate);
                    }
                }
                resolve();
            })
            .catch((err) => {
                Utils.toastError(`Server Error: ${err}`);
                reject();
            });
    };


    onMouseDown = (event: any) => {
        if (event.offsetX > event.target.clientWidth) {
            this.setState({ scrollbarClick: true });
        }
        else {
            this.setState({ scrollbarClick: false });
        }
    }

    getDefaultView() {
        this.setState({ pageloader: true });
        let haspaytype = this.props.paytype;
        laborPerformance
            .getDefaultViewProperties(this.pageName)
            .then(async (response: any | null) => {
                if (response?.length > 0) {
                    const result = response;
                    const selectedFilter = result.find(
                        (item) => item.fieldName === "selectedFilter"
                    )?.fieldValue;
                    const isOptionHighlight = result.find(
                        (item) => item.fieldName === "isOptionHighlight"
                    )?.fieldValue;
                    const activeComparison = +result.find(
                        (item) => item.fieldName === "activeComparison"
                    )?.fieldValue;
                    const field = result.find(
                        (item) => item.fieldName === "selectedFilterId"
                    )?.fieldValue;
                    const paytype = result.find(
                        (item) => item.fieldName === "paytype"
                    )?.fieldValue;

                    this.setState(
                        {
                            selectedFilter,
                            isOptionHighlight: isOptionHighlight === "true",
                            activeComparison,
                            field,
                            paytype: haspaytype !== "" ? haspaytype : paytype,
                            order: selectedFilter === "Name" ? "asc" : "desc",
                        },
                        () => {
                            this.props.commonFunction(
                                this.state.tempSelectedDate,
                                this.props.hidValue,
                                haspaytype !== ""
                                    ? haspaytype
                                    : paytype !== undefined
                                        ? paytype
                                        : "A",
                                "employee"
                            );
                            this.handleComparisonClick(activeComparison);
                            this.setDefaultURLParamValues();
                        }
                    );
                } else {
                    this.setDefaultFilterValues();
                }
            })
            .catch((error) => {
                Utils.toastError(error.message, {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.setDefaultFilterValues();
            });
    }

    setDefaultFilterValues = (): void => {
        this.setState(
            {
                selectedFilter: "Name",
                isOptionHighlight: false,
                activeComparison: 1,
                field: "userName",
                order: "asc",
            },
            () => {
                this.getPayPeroid(this.state.tempSelectedDate, this.props.hidValue, "A");
                this.handleComparisonClick(1);
                this.setDefaultURLParamValues();
            }
        );
    };

    getPayPeroid(selectedDate: any, hotelID: number, paytype: any) {
        laborPerformance.getPayPeriodDates(hotelID, selectedDate, paytype)
            .then(async (result: any[] | null) => {
                if (result != null) {
                    if ((paytype !== "A" ? paytype : result[0].paytype) === "weekly") {
                        this.setState({
                            payPeriodDates: [result[0].startdate, result[0].enddate],
                            calenderDates: [result[0].startdate, result[0].enddate],
                            exportedDate: result[0].exporteddateMMMMDDYYYY,
                            defaultPayType: result[0].paytype,
                            paytype: paytype !== "A" ? paytype : result[0].paytype,
                            actualHighlight: result[0].actualVariancehighlight
                        }, () => this.props?.getGetCalenderDates(this.state.calenderDates));
                    } else {
                        this.setState({
                            payPeriodDates: [result[0].paystartdate, result[0].payenddate],
                            calenderDates: [result[0].paystartdate, result[0].payenddate],
                            exportedDate: result[0].exporteddateMMMMDDYYYY,
                            defaultPayType: result[0].paytype,
                            paytype: paytype !== "A" ? paytype : result[0].paytype,
                            actualHighlight: result[0].actualVariancehighlight
                        }, () => this.props?.getGetCalenderDates(this.state.calenderDates));
                    }
                    if (result[0].exporteddateMMMMDDYYYY.length > 0 && result[0].exporteddateMMMMDDYYYY !== "0") {
                        this.props.exportedDate();
                    }
                    this.bindTableData(selectedDate, hotelID, paytype !== "A" ? paytype : result[0].paytype);
                }
            });
    }

    bindTableData(selectedDate: any, hotelID: number, paytype: any) {
        this.setState({ pageloader: true })
        laborPerformance.LaborPerformanceEmployeeViewMain(hotelID, selectedDate, paytype)
            .then(async (result: any[] | null) => {
                if (result !== null && result.length > 0) {
                    this.setState({
                        firstWeek: result[0].laborEmployeeWise,
                        performanceSummery: result[0].laborEmployeeWise,
                        tableData: result[0].laborEmployeeWise,
                        dayTotal: result[0].daysTotals,
                        firstdayTotal: result[0].daysTotals,
                        pageloader: false,
                    }, () => this.setDefaultURLParamValues())

                    if (paytype === "bi-weekly") {
                        this.setState({
                            secounddayTotal: result[1].daysTotals,
                            secoundWeek: result[1].laborEmployeeWise,
                        })
                    }
                } else {
                    this.setState({ pageloader: false })
                }
            })
            .catch((error) => {
                Utils.toastError(error.message, {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.setState({ pageloader: false })
            });
    }

    calenderShow = () => {
        this.setState({ calenderShow: true });
    };

    calenderHide = () => {
        this.setState({ calenderShow: false });
    };

    calenderAction = (e: any) => {
        this.props.commonFunctionForDateAcyionChange(e);
    };

    calenderActionBoth = (e: any) => {
        let date = new Date(e);
        this.setState({ pageloader: true });
        let calenderYear = date.getFullYear();
        let calenderDate = date.getDate();
        let calenderMonth = date.getMonth() + 1;
        let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
        this.setState({ tempSelectedDate: tempDate, calenderSelectDate: tempDate });
        this.props.commonFunction(
            tempDate,
            this.state.hidValue,
            this.state.paytype,
            "employee"
        );
    };

    calenderChange = (e: any) => {
        this.props.commonFunctionForDateChange(e);
    };

    calendarChangeBoth = (e: any) => {
        this.setState({ pageloader: true });
        let calenderYear = e.getFullYear();
        let calenderDate = e.getDate();
        let calenderMonth = e.getMonth() + 1;
        let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
        this.setState({
            tempSelectedDate: tempDate,
            calenderSelectDate: tempDate,
            selectedDepartment: 0,
            selectedPosition: 0,
            defaultDepartment: "Department",
            defaultPosition: "Position",
            //selectedFilter: "Name",
            //activeComparison: 1,
            actualHighlight: 0,
            calenderShow: false,
            isOptionHighlight: false,
            exportedDate: "",
            payPeriodDates: [],
            paytype: "",
            defaultPayType: "",
            field: "userName",
            order: "asc",

            tableExpended: [],
            isExpand: false,
            deptRow: [],
            isDeptExpend: false,
            isRedirect: false,
        });
        this.props.commonFunction(
            tempDate,
            this.state.hidValue,
            this.state.paytype,
            "employee"
        );
    };


    handleSelectedItem = (controlID: any, id: any) => {
        if (controlID === 1) {
            this.setState({ selectedPosition: id });
            this.FilterByDropDownList(id, this.state.selectedDepartment);
        } else {
            this.setState({ selectedDepartment: id });
            this.FilterByDropDownList(this.state.selectedPosition, id);
        }
        this.setState({ isDeptExpend: false, isExpand: false, tableExpended: [] });
    };

    FilterByDropDownList(positionId: number, departmentId: Number) {
        let departmentpositionList: any[] = [];
        let performanceSummery: any[] = [];
        performanceSummery = Object.assign([], JSON.parse(JSON.stringify(this.state.tableData)));
        departmentpositionList = Object.assign([], JSON.parse(JSON.stringify(this.state.departmentpositionList)));

        if (positionId > 0) {
            //performanceSummery = performanceSummery.filter((item) => item.userName === );

            let defaultPosition = departmentpositionList.filter(
                (item) => item.positionID === positionId
            )[0]?.positionName;

            this.setState({ performanceSummery: performanceSummery, defaultPosition: defaultPosition });
        }

        if (departmentId > 0) {
            performanceSummery = performanceSummery.filter(
                (item) => item.deptID === departmentId
            );

            let defaultDepartment = departmentpositionList.filter(
                (item) => item.departmentID === departmentId
            )[0]?.departmentName;

            this.setState({ performanceSummery: performanceSummery, defaultDepartment: defaultDepartment });
        }
        if (positionId <= 0 && departmentId <= 0) {
            this.setState({ performanceSummery: this.state.tableData });
        }
        if (performanceSummery.length === 0) {
            this.setState({ isDeptExpend: false });
        }
    }


    getURLParamsValues = (): any => {
        const paramObject: any = {
            date: this.getParamValueByParamName("date"),
            hotelId: this.getParamValueByParamName("hotelId"),
            departmentId: this.getParamValueByParamName("departmentId"),
            positionId: this.getParamValueByParamName("positionId"),
            dataComparisonMode: this.getParamValueByParamName("dataComparisonMode")
        };
        return paramObject;
    }

    getParamValueByParamName(paramName: string): any {
        const valuesArray = decodeURIComponent(this.props?.history?.location?.search)?.replace('?', '')?.split('&');
        const item = valuesArray?.find(r => r?.includes(paramName));
        return item ? window.atob(item?.split('=')[1] as any) : "";
    }

    setDefaultURLParamValues = (): any => {

        const paramObject: any = this.getURLParamsValues();
        let hidValue = "Select";
        let initialDate = "";
        let selectedDepartment = 0;
        let selectedPosition = 0;
        let currentdate = "";
        let currentMonth = "";
        let currentYear = "";
        let todayDate: any = new Date();
        
        if (this.state.hidwiseDate === "") {
            if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
                todayDate = new Date(
                    JSON.parse(localStorage.storage).currentUtcTime
                );
            }
        } else {
            todayDate = new Date(this.state.hidwiseDate);
        }
        currentdate = todayDate.getDate();
        currentMonth = todayDate.getMonth() + 1;
        currentYear = todayDate.getFullYear();
        let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;

        if (paramObject?.hotelId) {
            if (paramObject?.hotelId) {
                hidValue = paramObject?.hotelId;
            }
            if (+paramObject?.departmentId) {
                selectedDepartment = +paramObject?.departmentId;
            }
            if (+paramObject?.positionId) {
                selectedPosition = + paramObject?.positionId;
            }

        }

        if (paramObject?.date) {
            initialDate = paramObject?.date;
        } else {
            initialDate = tempDate;
        }

        if (this.getParamValueByParamName("hotelId")) {
            this.handleComparisonClick((+paramObject?.dataComparisonMode || 0), () => {
                this.handleSelectedItem(1, selectedPosition);
                this.handleSelectedItem(2, selectedDepartment);
            });

            this.setState({ hidValue, selectedDepartment, selectedPosition, initialDate, tempSelectedDate: initialDate });
        }
    }



    handleFilterChange = (event: any, value: any) => {

        this.setState({
            tableExpended: [],
            deptRow: {},
            isExpand: false,
            isDeptExpend: false,
        });
        this.setState(
            {
                selectedFilter: value.value,
                field: value.id,
                order: value.value === "Name" ? "asc" : "desc",
            },
            () => {
                this.props.updateNewStateForFilter(value.id, value.value, "empFilter");
            }
        );
    };

    handleFilterChangeNew = (value: any) => {
        this.setState({
            tableExpended: [],
            deptRow: {},
            isExpand: false,
            isDeptExpend: false,
            pageloader: true,
        });
        this.setState({ pageloader: true }, () => {
            setTimeout(() => {
                this.setState({
                    selectedFilter: value.value,
                    field: value.id,
                    order: value.value === "Name" ? "asc" : "desc",
                    pageloader: false,
                });
            }, 0);
            this.props.updateNewStateForFilter(value.id, value.value, "empFilter");
        });
    };

    handleComparisonClick(index, hasCallBackFunction = () => { }) {
        let performance: any[] = [];
        performance = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.performanceSummery))
        );
        const { selectedFilter, field } = this.state;
        let currentFilter = "";
        const filters = ["Name", "Actual"];
        const filterList = [
            { id: "userName", value: "Name" },
            { id: "totalActualinmin", value: "Actual" },
        ];
        if (index === 0) {
            this.setState(
                { activeComparison: index, performanceSummery: [], filterList: [] },
                () => {
                    this.setState({
                        performanceSummery: performance,
                        filterList: [
                            ...filterList,
                            ...[{ id: "totalVarianceinmin", value: "Variance" }],
                        ],
                        selectedFilter: filters.includes(selectedFilter)
                            ? selectedFilter
                            : "Variance",
                        field: filters.includes(selectedFilter)
                            ? field
                            : "totalVarianceinmin",
                    }, () => hasCallBackFunction());
                }
            );
            currentFilter = filters.includes(selectedFilter)
                ? selectedFilter
                : "Variance";
        } else if (index === 10) {
            this.setState(
                { activeComparison: index, performanceSummery: [], filterList: [] },
                () => {
                    this.setState({
                        performanceSummery: performance,
                        filterList: [
                            ...filterList,
                            ...[{ id: "totalBudgetinmin", value: "Budget" }],
                        ],
                        selectedFilter: filters.includes(selectedFilter)
                            ? selectedFilter
                            : "Budget",
                        field: filters.includes(selectedFilter)
                            ? field
                            : "totalBudgetinmin",
                    }, () => hasCallBackFunction());
                }
            );
            currentFilter = filters.includes(selectedFilter)
                ? selectedFilter
                : "Budget";
        } else if (index === 1) {
            this.setState(
                { activeComparison: index, performanceSummery: [], filterList: [] },
                () => {
                    this.setState({
                        performanceSummery: performance,
                        filterList: [
                            ...filterList,
                            ...[{ id: "totalScheduleinmin", value: "Schedule" }],
                        ],
                        selectedFilter: filters.includes(selectedFilter)
                            ? selectedFilter
                            : "Schedule",
                        field: filters.includes(selectedFilter)
                            ? field
                            : "totalScheduleinmin",
                    }, () => hasCallBackFunction());
                }
            );
            currentFilter = filters.includes(selectedFilter)
                ? selectedFilter
                : "Schedule";
        }

        let keyName = "";
        if (currentFilter === "Schedule") keyName = "totalScheduleinmin";
        else if (currentFilter === "Budget") keyName = "totalBudgetinmin";
        else if (currentFilter === "Variance") keyName = "totalVarianceinmin";
        else if (currentFilter === "Name") keyName = "userName";

        this.props.updateNewStateForFilter(keyName, currentFilter, "empFilter");
        this.setDataComparison(index);
    }

    setDataComparison = (index) => {
        let { dataComparison } = this.state;
        this.props.updateNewStateForFilter(index, dataComparison[index], "empComp");
        // this.props.dataComparison(index, dataComparison[index]);
    };

    handleActualFilter = (event: any, value: string) => {
        this.setState({ loaderTrue: true });
        let performance: any[] = [];
        performance = Object.assign([], JSON.parse(JSON.stringify(this.state.performanceSummery)));
        this.setState({
            isOptionHighlight: event.target.checked,
            performanceSummery: [],
            loaderTrue: false,
        }, () => {
            this.setState({ performanceSummery: performance });
        });
        if (this.positionSelect.current !== null) {
            this.positionSelect.current.loadData(event);
        }
    };

    saveDefaultView = (): void => {
        const laborSaveAsDefaultdtos: ILaborSaveAsDefaultDto[] = [];
        const {
            selectedFilter,
            isOptionHighlight,
            activeComparison,
            field,
            paytype,
        } = this.state;
        laborSaveAsDefaultdtos.push({
            fieldName: "selectedFilter",
            fieldValue: selectedFilter,
            pageName: this.pageName,
        });
        laborSaveAsDefaultdtos.push({
            fieldName: "activeComparison",
            fieldValue: activeComparison,
            pageName: this.pageName,
        });
        laborSaveAsDefaultdtos.push({
            fieldName: "isOptionHighlight",
            fieldValue: isOptionHighlight,
            pageName: this.pageName,
        });
        laborSaveAsDefaultdtos.push({
            fieldName: "selectedFilterId",
            fieldValue: field,
            pageName: this.pageName,
        });
        laborSaveAsDefaultdtos.push({
            fieldName: "paytype",
            fieldValue: paytype,
            pageName: this.pageName,
        });
        laborSaveAsDefaultdtos.push({
            fieldName: "tabName",
            fieldValue: this.tabName,
            pageName: this.pageName,
        });
        this.setState({ loaderTrue: true });
        laborPerformance
            .saveDefaultViewProperties(laborSaveAsDefaultdtos)
            .then(async (result: any | null) => {
                this.setState({ loaderTrue: false });
                if (result != null) {
                    if (result.message === "Success") {
                        toast.success(result?.result?.message, {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                         
                    }
                }
            })
            .catch((error) => {
                Utils.toastError(error.message, {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.setState({ loaderTrue: false });
            });
    };


    openOverView() {
        this.setState({ isRedirect: true });
        localStorage.setItem("mainMenu", "Labor");
        localStorage.setItem("subMenu", "Payroll");
    }

    refershData() {
        this.props.refreshPerformanceData();
    }
    onOutsideClick = (e: any) => {
        if (document?.getElementById("performanceSlideOut")) {
            return;
        }
        let isChildClick = false;
        e?.path?.map((x: any) => {
            if (
                x.className ===
                "body-sec performance-position justify-content-end container-fluid"
            ) {
                isChildClick = true;
                return false;
            } else {
                return false;
            }
        });
        if (isChildClick) {
            return;
        }
        if (e.path !== undefined &&
            (e.path[0].className ===
                "calender-display date-picker d-flex align-items-center" ||
                e.path[1].className ===
                "calender-display date-picker d-flex align-items-center" ||
                e.path[2].className ===
                "calender-display date-picker d-flex align-items-center" ||
                e.path[2].className === "react-calendar__month-view " ||
                e.path[1].className === "react-calendar__month-view__weekdays__weekday" ||
                e.path[0].className === "scroll-area" ||
                e.path[0].className === "back-drop")
        ) {
            return;
        }
        if (
            e.currentTarget.activeElement.className ===
            "dropdown-toggle btn btn-primary" ||
            e.currentTarget.activeElement.className ===
            "filter-btn dropdown-toggle btn btn-primary" ||
            e.currentTarget.activeElement.className ===
            "add-shift btn-outline-primary btn btn-primary" ||
            e.currentTarget.activeElement.className === "close" ||
            e.currentTarget.activeElement.className ===
            "btn-outline-primary btn btn-primary more dropdown-toggle btn btn-primary" ||
            e.currentTarget.activeElement.className === "dropdown-item" ||
            e.currentTarget.activeElement.className === "bdr-bottom dropdown-item"
        ) {
            return;
        }
        if (!this.state.scrollbarClick) {
            if (this.state.isExpand) {
                let row = this.state.performanceSummery.filter(
                    (x: any) => x.deptID === this.state.tableExpended[0]
                );
                this.handleOnExpand(row[0], false, "", "");
                this.props.hideSticky();
            }
        }
    };

    CleanSearch() {
        this.child.current.CleanSearch();
    };

    handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
        if (this.state.tableExpended.length > 0) {
            this.setState({ deptRow: {}, isExpand: false, isDeptExpend: false });
            this.setState(() => ({ tableExpended: [] }));
            this.props.hideSticky();
        } else {
            //row.action = "Expended";
            this.setState({ deptRow: row, isExpand: true, isDeptExpend: true });
            this.setState(() => ({ tableExpended: [row.userName] }));
            
        }
    };

    getActualHighlightVariance(actual: any, value: any) {
        actual = this.hoursMintustoMinuts(actual);
        value = this.hoursMintustoMinuts(value);
        if (actual > value) {
            return "main-ttl color-red";
        } else {
            return "main-ttl";
        }
    }

    hoursMintustoMinuts(data: any) {
        if (data.includes(":")) {
            let total = 0;
            let subTotal = 0;
            const dataArray = data.split(":");
            let HH = Number(dataArray[0]);
            let MM = Number(dataArray[1]);
            if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                HH = HH < 0 ? HH * -1 : HH;
                MM = MM < 0 ? MM * -1 : MM;
                subTotal = (HH * 60 + MM) * -1;
            } else {
                subTotal = HH * 60 + MM;
            }
            return (total = total + subTotal);
        }
    }

    getVarianceHighlight(actual: any, budget: any) {
        actual = this.hoursMintustoMinuts(actual);
        budget = this.hoursMintustoMinuts(budget);
        if (actual > budget) {
            return "sub-ttl mdy-fnt color-red";
        } else {
            return "sub-ttl mdy-fnt";
        }
    }

    getActualHighlight(actual: any, value: any) {
        actual = this.hoursMintustoMinuts(actual);
        value = this.hoursMintustoMinuts(value);
        let perrow = (Number(Number(actual) - Number(value)) / Number(value)) * 100;
        if (Number(perrow > 0)) {
            if (
                Number(value) < Number(actual) &&
                Number(perrow) <= Number(this.state.actualHighlight) &&
                this.state.isOptionHighlight
            ) {
                return "main-ttl color-red";
            } else if (
                Number(value) < Number(actual) &&
                Number(perrow) <= Number(this.state.actualHighlight) &&
                !this.state.isOptionHighlight
            ) {
                return "main-ttl";
            } else if (
                Number(value) < Number(actual) &&
                Number(perrow) > Number(this.state.actualHighlight)
            ) {
                return "main-ttl color-red";
            } else {
                return "main-ttl";
            }
        } else {
            return "main-ttl";
        }
    }

    navAction = () => {

        let deptRow: any = {};
        deptRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.deptRow)));
        let isExpand = this.state.isExpand;
        this.setState({
            performanceSummery: [],
            tableData: [],
            dayTotal: [],
        }, () => {
            if (this.state.mode === 0) {
                this.setState({
                    performanceSummery: this.state.secoundWeek,
                    tableData: this.state.secoundWeek,
                    dayTotal: this.state.secounddayTotal,
                    mode: 1
                })
            } else {
                this.setState({
                    performanceSummery: this.state.firstWeek,
                    tableData: this.state.firstWeek,
                    dayTotal: this.state.firstdayTotal,
                    mode: 0
                })
            }
        })







        this.setState({
            selectedDepartment: 0,
            selectedPosition: 0,
            defaultDepartment: "Department",
            defaultPosition: "Position",
            //selectedFilter: "Name",
            activeComparison: 1,
            actualHighlight: 0,
            isOptionHighlight: false,
            //  field: "userName",
            // order: "asc",
            tableExpended: [],
            isExpand: false,
            deptRow: [],
            isDeptExpend: false,
        }, () => {
            if (isExpand) {
                this.setState({ deptRow: deptRow, isExpand: true, isDeptExpend: true, tableExpended: [deptRow.userName] });

                // this.handleOnExpand(deptRow, isExpand, 0, "");
            }
        });
    }

    actionheaderFormatter = (column: any, colIndex: any) => {
        return (
            <>
                {this.state.defaultPayType === "bi-weekly" && (
                    <div className={this.state.mode === 0 ? "move-btn" : "move-btn back"} onClick={(e) => this.navAction()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" rx="3" fill="#E0E3E6" />
                            <path d="M10.9225 16.7558L15.6783 12L10.9225 7.24414L9.74414 8.42247L13.3216 12L9.74414 15.5775L10.9225 16.7558Z" fill="#1D1E20" />
                        </svg>
                    </div>
                )}
            </>
        );
    }

    headerFormatter = (column: any, colIndex: any) => {
        let header = this.state.dayTotal;
        if (header.length > 0) {
            let header1 = "";
            let header2 = "";
            let headerLock = "";
            switch (column.text) {
                case "day1":
                    header1 = header[0].day1.dayName;
                    header2 = header[0].day1.date;
                    headerLock = header[0].day1.approveStatus;
                    break;
                case "day2":
                    header1 = header[0].day2.dayName;
                    header2 = header[0].day2.date;
                    headerLock = header[0].day2.approveStatus;
                    break;
                case "day3":
                    header1 = header[0].day3.dayName;
                    header2 = header[0].day3.date;
                    headerLock = header[0].day3.approveStatus;
                    break;
                case "day4":
                    header1 = header[0].day4.dayName;
                    header2 = header[0].day4.date;
                    headerLock = header[0].day4.approveStatus;
                    break;
                case "day5":
                    header1 = header[0].day5.dayName;
                    header2 = header[0].day5.date;
                    headerLock = header[0].day5.approveStatus;
                    break;
                case "day6":
                    header1 = header[0].day6.dayName;
                    header2 = header[0].day6.date;
                    headerLock = header[0].day6.approveStatus;
                    break;
                case "day7":
                    header1 = header[0].day7.dayName;
                    header2 = header[0].day7.date;
                    headerLock = header[0].day7.approveStatus;
                    break;
            }
            return (
                <div className="column-header">
                    <div className="header-data">
                        <div className="header-day d-flex justify-content-center align-items-center">
                            {headerLock.toLowerCase() === "yes" && (
                                <div className="lock-icon"><img src={ForecastLock} alt="ForecastLock" /></div>
                            )}
                            <div>{header1}</div>
                        </div>
                    </div>
                    <div className="header-date">{header2}</div>
                </div>
            );
        }
    };

    dotFormatter = (column, colIndex) => {
        if (this.state.dayTotal.length > 0) {
        } if (this.state.dayTotal[0].totaldot !== "0:00" && this.state.dayTotal[0].totaldot !== "") {
            return (
                <>{column.text}</>
            );
        }
    }

    reloadData = () => {
        this.getPayPeroid(this.state.tempSelectedDate, this.props.hidValue, this.state.paytype !== undefined ? this.state.paytype : "A");
    }

    openSlideOut = (row, day) => {
        let todayDate: any = new Date(this.state.calenderSelectDate);
        let date = row[day]?.date + "/" + todayDate.getFullYear(); //'04/19/2021';
        let hotelId = this.state.hidValue;
        //this.props.openSlideOut(row , day);
        if (this.props.openSlideOut.length === 2) {
            this.props.openSlideOut(row, day, true);
        } else {
            this.props.openSlideOut(row.userName, 0, row?.positionID, date, hotelId,
                row?.deptName, row?.positionName, row?.userName
                , Utils.getMinutesFromHHMM(row[day].budget),
                false, true);
        }
    }
    render() {

        const rowClasses = (
            row: { deptID: number; action: string }, rowIndex: any) => {
            if (this.state.isExpand === true && row.deptID === this.state.deptRow.deptID) {
                return "row-Expended";
            } else if (this.state.isExpand === true) {
                return "row-Collapsed";
            } else {
                return "row-Action";
            }
        };

        const columnsHeader = [
            {
                dataField: "",
                text: "",
            },
            {
                dataField: "",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            <div className="main-ttl">
                                <EllipsisWithTooltip placement="bottom">
                                    {"Day Totals"}
                                </EllipsisWithTooltip>
                            </div>
                            <div className="sub-ttl mdy-fnt-14">{row.allDepartment}</div>
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "day1",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div className={this.getActualHighlightVariance(row.day1.actual, row.day1.schedule)}>
                                        {row.day1.actual}
                                    </div>
                                    <div className={this.getVarianceHighlight(row.day1.actual, row.day1.schedule)}>
                                        {row.day1.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    <div className={this.getActualHighlight(row.day1.actual, row.day1.schedule)}>
                                        {row.day1.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day1.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div className={this.getActualHighlight(row.day1.actual, row.day1.schedule)}>
                                        {row.day1.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day1.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "day2",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div className={this.getActualHighlightVariance(row.day2.actual, row.day2.schedule)}>
                                        {row.day2.actual}
                                    </div>
                                    <div className={this.getVarianceHighlight(row.day2.actual, row.day2.schedule)}>
                                        {row.day2.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div className={this.getActualHighlight(row.day2.actual, row.day2.schedule)}>
                                        {row.day2.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day2.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div className={this.getActualHighlight(row.day2.actual, row.day2.schedule)}>
                                        {row.day2.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day2.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "day3",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div className={this.getActualHighlightVariance(row.day3.actual, row.day3.schedule)}>
                                        {row.day3.actual}
                                    </div>
                                    <div className={this.getVarianceHighlight(row.day3.actual, row.day3.schedule)}>
                                        {row.day3.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div className={this.getActualHighlight(row.day3.actual, row.day3.schedule)}>
                                        {row.day3.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day3.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div className={this.getActualHighlight(row.day3.actual, row.day3.schedule)}>
                                        {row.day3.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day3.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "day4",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(row.day4.actual, row.day4.schedule)}>
                                        {row.day4.actual}
                                    </div>
                                    <div className={this.getVarianceHighlight(row.day4.actual, row.day4.schedule)} >
                                        {row.day4.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(row.day4.actual, row.day4.schedule)} >
                                        {row.day4.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day4.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(row.day4.actual, row.day4.schedule)} >
                                        {row.day4.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day4.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "day5",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(row.day5.actual, row.day5.schedule)}>
                                        {row.day5.actual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(row.day5.actual, row.day5.schedule)}>
                                        {row.day5.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day5.actual,
                                            row.day5.schedule
                                        )}
                                    >
                                        {row.day5.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day5.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day5.actual,
                                            row.day5.schedule
                                        )}
                                    >
                                        {row.day5.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day5.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "day6",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.day6.actual,
                                            row.day6.schedule
                                        )}
                                    >
                                        {row.day6.actual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.day6.actual,
                                            row.day6.schedule
                                        )}
                                    >
                                        {row.day6.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day6.actual,
                                            row.day6.schedule
                                        )}
                                    >
                                        {row.day6.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day6.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day6.actual,
                                            row.day6.schedule
                                        )}
                                    >
                                        {row.day6.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day6.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "day7",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.day7.actual,
                                            row.day7.schedule
                                        )}
                                    >
                                        {row.day7.actual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.day7.actual,
                                            row.day7.schedule
                                        )}
                                    >
                                        {row.day7.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day7.actual,
                                            row.day7.schedule
                                        )}
                                    >
                                        {row.day7.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day7.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day7.actual,
                                            row.day7.schedule
                                        )}
                                    >
                                        {row.day7.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day7.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                headerClasses: 'move-header',
                classes: 'move-header',
                headerFormatter: this.actionheaderFormatter,

            },
            {
                dataField: "",
                text: "Totals",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.totalActual,
                                            row.totalSchedule
                                        )}
                                    >
                                        {row.totalActual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.totalActual,
                                            row.totalSchedule
                                        )}
                                    >
                                        {row.actualTotalvarianceInHour}
                                    </div>
                                </>
                            )}

                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.totalActual,
                                            row.totalSchedule
                                        )}
                                    >
                                        {row.totalActual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.totalSchedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.totalActual,
                                            row.totalSchedule
                                        )}
                                    >
                                        {row.totalActual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.totalSchedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "totalot",
                text: "OT",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {row.totalot !== "0:00" && (
                                <div className="main-ttl color-red">{row.totalot}</div>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "totaldot",
                text: "DOT",
                headerFormatter: this.dotFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {row.totaldot !== "0:00" && (
                                <div className="main-ttl color-red">{row.totaldot}</div>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                isDummyField: true,
                text: "",
                editable: false,
            },
        ];

        const columns = [
            {
                dataField: "userName",
                text: "",
                sort: true,
                hidden: true,
                searchable: false
            },
            {
                dataField: "totalActualinmin",
                text: "",
                sort: true,
                hidden: true,
                searchable: false
            },
            {
                dataField: "totalVarianceinmin",
                text: "",
                sort: true,
                hidden: true,
                searchable: false
            },
            {
                dataField: "totalBudgetinmin",
                text: "",
                sort: true,
                hidden: true,
                searchable: false
            },
            {
                dataField: "totalScheduleinmin",
                text: "",
                sort: true,
                hidden: true,
                searchable: false
            },

            {
                dataField: "userName",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="d-flex align-items-center">
                            <div className="profile-img">
                                <img
                                    src={row.imagePath}
                                    className="default"
                                    width="40"
                                    height="40"
                                    alt={row.userName}
                                    onError={(e) =>
                                        (e.currentTarget.src = profile)
                                    }
                                />
                            </div>
                            <div className="employee-list-sec">
                                <div className="main-ttl">
                                    <EllipsisWithTooltip placement="bottom">
                                        {row.userName}
                                    </EllipsisWithTooltip>
                                </div>
                                <div className="sub-ttl">{row.position}</div>
                            </div>
                        </div>
                    );
                },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (row.action === "Expended")
                            this.handleOnExpand(row, false, rowIndex, e);
                        else this.handleOnExpand(row, true, rowIndex, e);
                    },
                },
                searchable: true
            },
            {
                dataField: "",
                text: "",
                classes: 'remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="display-area" onClick={() => this.openSlideOut(row, "day1")}>
                            {(row.day1.noShowcount > 0 || row.day1.apoCount > 0 || row.day1.manualCount > 0 || row.day1.otfCount > 0) && (
                                <div className="pipes-section">
                                    <ul className="pip-icon-list pips d-flex justify-content-center">
                                        {row.day1.noShowcount > 0 && (<li className="pip red"></li>)}
                                        {row.day1.apoCount > 0 && (<li className="pip orange"></li>)}
                                        {(row.day1.manualCount > 0 || row.day1.otfCount > 0) && (<li className="pip blue"></li>)}
                                    </ul>
                                    <div className="pips pip-list">
                                        <ul>
                                            {row.day1.noShowcount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip red"></div>
                                                    <div>{row.day1.noShowcount} No Show</div>
                                                </div>
                                            </li>)}
                                            {row.day1.apoCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip orange"></div>
                                                    <div>{row.day1.apoCount} Auto Punch Out</div>
                                                </div>
                                            </li>)}
                                            {row.day1.manualCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day1.manualCount} Manual</div>
                                                </div>
                                            </li>)}
                                            {row.day1.otfCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day1.otfCount} OTF</div>
                                                </div>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.day1.actual,
                                            row.day1.schedule
                                        )}
                                    >
                                        {row.day1.actual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.day1.actual,
                                            row.day1.schedule
                                        )}
                                    >
                                        {row.day1.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    <div
                                        className={this.getActualHighlight(
                                            row.day1.actual,
                                            row.day1.schedule
                                        )}
                                    >
                                        {row.day1.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day1.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day1.actual,
                                            row.day1.schedule
                                        )}
                                    >
                                        {row.day1.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day1.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="display-area" onClick={() => this.openSlideOut(row, "day2")}>
                            {(row.day2.noShowcount > 0 || row.day2.apoCount > 0 || row.day2.manualCount > 0 || row.day2.otfCount > 0) && (
                                <div className="pipes-section">
                                    <ul className="pip-icon-list pips d-flex justify-content-center">
                                        {row.day2.noShowcount > 0 && (<li className="pip red"></li>)}
                                        {row.day2.apoCount > 0 && (<li className="pip orange"></li>)}
                                        {(row.day2.manualCount > 0 || row.day2.otfCount > 0) && (<li className="pip blue"></li>)}
                                    </ul>
                                    <div className="pips pip-list">
                                        <ul>
                                            {row.day2.noShowcount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip red"></div>
                                                    <div>{row.day2.noShowcount} No Show</div>
                                                </div>
                                            </li>)}
                                            {row.day2.apoCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip orange"></div>
                                                    <div>{row.day2.apoCount} Auto Punch Out</div>
                                                </div>
                                            </li>)}
                                            {row.day2.manualCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day2.manualCount} Manual</div>
                                                </div>
                                            </li>)}
                                            {row.day2.otfCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day2.otfCount} OTF</div>
                                                </div>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.day2.actual,
                                            row.day2.schedule
                                        )}
                                    >
                                        {row.day2.actual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.day2.actual,
                                            row.day2.schedule
                                        )}
                                    >
                                        {row.day2.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day2.actual,
                                            row.day2.schedule
                                        )}
                                    >
                                        {row.day2.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day2.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day2.actual,
                                            row.day2.schedule
                                        )}
                                    >
                                        {row.day2.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day2.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="display-area" onClick={() => this.openSlideOut(row, "day3")}>
                            {(row.day3.noShowcount > 0 || row.day3.apoCount > 0 || row.day3.manualCount > 0 || row.day3.otfCount > 0) && (
                                <div className="pipes-section">
                                    <ul className="pip-icon-list pips d-flex justify-content-center">
                                        {row.day3.noShowcount > 0 && (<li className="pip red"></li>)}
                                        {row.day3.apoCount > 0 && (<li className="pip orange"></li>)}
                                        {(row.day3.manualCount > 0 || row.day3.otfCount > 0) && (<li className="pip blue"></li>)}
                                    </ul>
                                    <div className="pips pip-list">
                                        <ul>
                                            {row.day3.noShowcount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip red"></div>
                                                    <div>{row.day3.noShowcount} No Show</div>
                                                </div>
                                            </li>)}
                                            {row.day3.apoCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip orange"></div>
                                                    <div>{row.day3.apoCount} Auto Punch Out</div>
                                                </div>
                                            </li>)}
                                            {row.day3.manualCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day3.manualCount} Manual</div>
                                                </div>
                                            </li>)}
                                            {row.day3.otfCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day3.otfCount} OTF</div>
                                                </div>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.day3.actual,
                                            row.day3.schedule
                                        )}
                                    >
                                        {row.day3.actual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.day3.actual,
                                            row.day3.schedule
                                        )}
                                    >
                                        {row.day3.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day3.actual,
                                            row.day3.schedule
                                        )}
                                    >
                                        {row.day3.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day3.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day3.actual,
                                            row.day3.schedule
                                        )}
                                    >
                                        {row.day3.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day3.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="display-area" onClick={() => this.openSlideOut(row, "day4")}>
                            {(row.day4.noShowcount > 0 || row.day4.apoCount > 0 || row.day4.manualCount > 0 || row.day4.otfCount > 0) && (
                                <div className="pipes-section">
                                    <ul className="pip-icon-list pips d-flex justify-content-center">
                                        {row.day4.noShowcount > 0 && (<li className="pip red"></li>)}
                                        {row.day4.apoCount > 0 && (<li className="pip orange"></li>)}
                                        {(row.day4.manualCount > 0 || row.day4.otfCount > 0) && (<li className="pip blue"></li>)}
                                    </ul>
                                    <div className="pips pip-list">
                                        <ul>
                                            {row.day4.noShowcount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip red"></div>
                                                    <div>{row.day4.noShowcount} No Show</div>
                                                </div>
                                            </li>)}
                                            {row.day4.apoCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip orange"></div>
                                                    <div>{row.day4.apoCount} Auto Punch Out</div>
                                                </div>
                                            </li>)}
                                            {row.day4.manualCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day4.manualCount} Manual</div>
                                                </div>
                                            </li>)}
                                            {row.day4.otfCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day4.otfCount} OTF</div>
                                                </div>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.day4.actual,
                                            row.day4.schedule
                                        )}
                                    >
                                        {row.day4.actual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.day4.actual,
                                            row.day4.schedule
                                        )}
                                    >
                                        {row.day4.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day4.actual,
                                            row.day4.schedule
                                        )}
                                    >
                                        {row.day4.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day4.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day4.actual,
                                            row.day4.schedule
                                        )}
                                    >
                                        {row.day4.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day4.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="display-area" onClick={() => this.openSlideOut(row, "day5")}>
                            {(row.day5.noShowcount > 0 || row.day5.apoCount > 0 || row.day5.manualCount > 0 || row.day5.otfCount > 0) && (
                                <div className="pipes-section">
                                    <ul className="pip-icon-list pips d-flex justify-content-center">
                                        {row.day5.noShowcount > 0 && (<li className="pip red"></li>)}
                                        {row.day5.apoCount > 0 && (<li className="pip orange"></li>)}
                                        {(row.day5.manualCount > 0 || row.day5.otfCount > 0) && (<li className="pip blue"></li>)}
                                    </ul>
                                    <div className="pips pip-list">
                                        <ul>
                                            {row.day5.noShowcount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip red"></div>
                                                    <div>{row.day5.noShowcount} No Show</div>
                                                </div>
                                            </li>)}
                                            {row.day5.apoCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip orange"></div>
                                                    <div>{row.day5.apoCount} Auto Punch Out</div>
                                                </div>
                                            </li>)}
                                            {row.day5.manualCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day5.manualCount} Manual</div>
                                                </div>
                                            </li>)}
                                            {row.day5.otfCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day5.otfCount} OTF</div>
                                                </div>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.day5.actual,
                                            row.day5.schedule
                                        )}
                                    >
                                        {row.day5.actual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.day5.actual,
                                            row.day5.schedule
                                        )}
                                    >
                                        {row.day5.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day5.actual,
                                            row.day5.schedule
                                        )}
                                    >
                                        {row.day5.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day5.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day5.actual,
                                            row.day5.schedule
                                        )}
                                    >
                                        {row.day5.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day5.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="display-area" onClick={() => this.openSlideOut(row, "day6")}>
                            {(row.day6.noShowcount > 0 || row.day6.apoCount > 0 || row.day6.manualCount > 0 || row.day6.otfCount > 0) && (
                                <div className="pipes-section">
                                    <ul className="pip-icon-list pips d-flex justify-content-center">
                                        {row.day6.noShowcount > 0 && (<li className="pip red"></li>)}
                                        {row.day6.apoCount > 0 && (<li className="pip orange"></li>)}
                                        {(row.day6.manualCount > 0 || row.day6.otfCount > 0) && (<li className="pip blue"></li>)}
                                    </ul>
                                    <div className="pips pip-list">
                                        <ul>
                                            {row.day6.noShowcount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip red"></div>
                                                    <div>{row.day6.noShowcount} No Show</div>
                                                </div>
                                            </li>)}
                                            {row.day6.apoCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip orange"></div>
                                                    <div>{row.day6.apoCount} Auto Punch Out</div>
                                                </div>
                                            </li>)}
                                            {row.day6.manualCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day6.manualCount} Manual</div>
                                                </div>
                                            </li>)}
                                            {row.day6.otfCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day6.otfCount} OTF</div>
                                                </div>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.day6.actual,
                                            row.day6.schedule
                                        )}
                                    >
                                        {row.day6.actual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.day6.actual,
                                            row.day6.schedule
                                        )}
                                    >
                                        {row.day6.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day6.actual,
                                            row.day6.schedule
                                        )}
                                    >
                                        {row.day6.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day6.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day6.actual,
                                            row.day6.schedule
                                        )}
                                    >
                                        {row.day6.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day6.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'remove-overflow',
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="display-area" onClick={() => this.openSlideOut(row, "day7")}>
                            {(row.day7.noShowcount > 0 || row.day7.apoCount > 0 || row.day7.manualCount > 0 || row.day7.otfCount > 0) && (
                                <div className="pipes-section">
                                    <ul className="pip-icon-list pips d-flex justify-content-center">
                                        {row.day7.noShowcount > 0 && (<li className="pip red"></li>)}
                                        {row.day7.apoCount > 0 && (<li className="pip orange"></li>)}
                                        {(row.day7.manualCount > 0 || row.day7.otfCount > 0) && (<li className="pip blue"></li>)}
                                    </ul>
                                    <div className="pips pip-list">
                                        <ul>
                                            {row.day7.noShowcount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip red"></div>
                                                    <div>{row.day7.noShowcount} No Show</div>
                                                </div>
                                            </li>)}
                                            {row.day7.apoCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip orange"></div>
                                                    <div>{row.day7.apoCount} Auto Punch Out</div>
                                                </div>
                                            </li>)}
                                            {row.day7.manualCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day7.manualCount} Manual</div>
                                                </div>
                                            </li>)}
                                            {row.day7.otfCount > 0 && (<li>
                                                <div className="d-flex">
                                                    <div className="pip blue"></div>
                                                    <div>{row.day7.otfCount} OTF</div>
                                                </div>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.day7.actual,
                                            row.day7.schedule
                                        )}
                                    >
                                        {row.day7.actual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.day7.actual,
                                            row.day7.schedule
                                        )}
                                    >
                                        {row.day7.actualVariance}
                                    </div>
                                </>
                            )}
                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day7.actual,
                                            row.day7.schedule
                                        )}
                                    >
                                        {row.day7.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day7.schedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.day7.actual,
                                            row.day7.schedule
                                        )}
                                    >
                                        {row.day7.actual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.day7.schedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                dataField: "",
                text: "",
                classes: 'move-header',
                headerClasses: 'move-header',
            },
            {
                dataField: "",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {this.state.activeComparison === 0 && (
                                <>
                                    <div
                                        className={this.getActualHighlightVariance(
                                            row.totalActual,
                                            row.totalSchedule
                                        )}
                                    >
                                        {row.totalActual}
                                    </div>
                                    <div
                                        className={this.getVarianceHighlight(
                                            row.totalActual,
                                            row.totalSchedule
                                        )}
                                    >
                                        {row.actualTotalvarianceInHour}
                                    </div>
                                </>
                            )}

                            {this.state.activeComparison === 1 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.totalActual,
                                            row.totalSchedule
                                        )}
                                    >
                                        {row.totalActual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.totalSchedule}</div>
                                </>
                            )}
                            {this.state.activeComparison === 2 && (
                                <>
                                    {" "}
                                    <div
                                        className={this.getActualHighlight(
                                            row.totalActual,
                                            row.totalSchedule
                                        )}
                                    >
                                        {row.totalActual}
                                    </div>
                                    <div className="sub-ttl mdy-fnt">{row.totalSchedule}</div>
                                </>
                            )}
                        </div>
                    );
                },
                searchable: false
            },
            {
                dataField: "ot",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {row.totalot !== "0:00" && (
                                <div className="main-ttl color-red">{row.totalot}</div>
                            )}
                        </div>
                    );
                },
                searchable: false
            },
            {
                dataField: "totaldot",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="">
                            {row.totaldot !== "0:00" && (
                                <div className="main-ttl color-red">{row.totaldot}</div>
                            )}
                        </div>
                    );
                },
                searchable: false
            },
            {
                dataField: "",
                isDummyField: true,
                text: "",
                editable: false,
                // formatter: (cell: any, row: any, rowIndex: any) => (
                //   <Dropdown
                //     onClick={(e) => {
                //       e.stopPropagation();
                //     }}
                //     className="more-action"
                //     alignRight
                //   >
                //     <Dropdown.Toggle
                //       className="btn-outline-primary btn btn-primary more"
                //       id="dropdown-more"
                //     >
                //       <svg
                //         width="20"
                //         height="20"
                //         viewBox="0 0 20 20"
                //         fill="none"
                //         xmlns="http://www.w3.org/2000/svg"
                //       >
                //         <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                //         <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                //         <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                //       </svg>
                //     </Dropdown.Toggle>
                //     <Dropdown.Menu>
                //       <Dropdown.Item eventKey={"Dummy Text"}>Dummy Text</Dropdown.Item>
                //     </Dropdown.Menu>
                //   </Dropdown>
                // ),
                // events: {
                //   onClick: (e, column, columnIndex, row, rowIndex) => {
                //     if (row.action === "Expended")
                //       this.handleOnExpand(row, false, rowIndex, e);
                //     else this.handleOnExpand(row, true, rowIndex, e);
                //   },
                // },
            },
        ]

        const expandRow = {
            onlyOneExpanding: true,
            parentClassName: "parent-expand-foo top-parent-list",
            renderer: (row: any, rowIndex: any) => (
                <EmployeePositions
                    data={row.laborPositionWise}
                    activeComparison={this.state.activeComparison}
                    openSlideOut={this.openSlideOut}
                    calenderSelectDate={this.state.calenderSelectDate}
                />
            ),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expanded: this.state.tableExpended,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <div></div>;
                }
                return <div></div>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="chevronExpended">
                            <img src={chevronRight} alt="chevron Right" />
                        </span>
                    );
                }
                return (
                    <span className="chevronCollpsed">
                        <img src={chevronRight} alt="chevron Right" />
                    </span>
                );
            },
        };

        // const handleChange = (eventKey: any) => {
        //     this.setState({ paytype: eventKey, mode: 0 }, () => {
        //         this.getPayPeroid(this.state.tempSelectedDate, this.state.hidValue, eventKey);
        //     });
        // }
        const handleChange = (eventKey: any) => {
            this.setState({ paytype: eventKey, mode: 0 }, () => {
                this.setState({ paytype: eventKey, mode: 0 }, () => {
                    this.props.commonFunction(
                        this.state.tempSelectedDate,
                        this.state.hidValue,
                        eventKey,
                        "commonChange"
                    );
                });
            });
        };

        if (this.state.isRedirect) {
            return <Redirect to={{
                pathname: "/labor-management/payroll-export",
                state: {
                    payPeriod: this.state.calenderDates,
                    pageTitle: "performance",
                },
            }} />;
        }

        return (
            <>
                {/* <ToastContainer autoClose={3000} /> */}
                {this.state.hidValue !== "Select" && (
                    <div className="main-Page department-employee">
                        <div className="bdy-sec">
                            {(this.state.pageloader &&
                                <div className="loader-responsive">
                                    <div className="performanceList">
                                        <div className="header d-flex">
                                            <div className="loadingAnimation"></div>
                                            <div className="loadingAnimation"></div>
                                            <div className="loadingAnimation mr-auto"></div>
                                            <div className="loadingAnimation"></div>
                                            <div className="loadingAnimation"></div>
                                        </div>
                                        <div className="tableHeader loadingAnimation"></div>
                                        <div className="tableList">
                                            <ul>
                                                <li className="d-flex">
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="loadingAnimation"></div>
                                                    </div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="group">
                                                            <div className="loadingAnimation"></div>
                                                            <div className="loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                    <div className="list d-flex align-self-center">
                                                        <div className="loadingAnimation"></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(!this.state.pageloader &&
                                <ToolkitProvider
                                    keyField="userName"
                                    data={this.state.performanceSummery}
                                    columns={columns}
                                    sort={{
                                        dataField: this.state.field,
                                        order: this.state.order,
                                    }}
                                    search>
                                    {(props: {
                                        searchProps: JSX.IntrinsicAttributes;
                                        baseProps: JSX.IntrinsicAttributes;
                                    }) => (
                                        <div>
                                            <div className="search-header d-flex">
                                                <div className="search-filter">
                                                    <label id="labeltableSearch" className="search-label">
                                                        <TableSearch id="tableSearch" key="tableSearch" ref={this.child}
                                                            {...props.searchProps} CleanSearch={this.CleanSearch.bind(this)}
                                                            placeholder={"Search..."}
                                                        />
                                                    </label>
                                                </div>
                                                {this.state.defaultPayType === "bi-weekly" && (
                                                    <div className="pay-period">
                                                        <ToggleButtonGroup type="radio" name="options" defaultValue={this.state.paytype === "" ? this.state.defaultPayType : this.state.paytype} onChange={handleChange}>
                                                            <ToggleButton value={"weekly"}>Weekly</ToggleButton>
                                                            <ToggleButton value={"bi-weekly"}>Bi-Weekly</ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </div>
                                                )}

                                                <div className="calender-container">
                                                    <ButtonGroup aria-label="Basic example">
                                                        <Button onClick={(e) => { this.calenderAction((new Date(this.state.calenderDates[0])).setDate(new Date(this.state.calenderDates[0]).getDate() - 1)) }}>
                                                            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.98314 8.414L0.879001 4.207L4.98314 0L6 1.04235L2.91273 4.207L6 7.37165L4.98314 8.414Z" fill="#1D1E20" />
                                                            </svg>
                                                        </Button>
                                                        <Button onClick={this.calenderShow}>
                                                            <div className="calender-display">
                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0)">
                                                                        <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" fill="#3F4347" />
                                                                        <g filter="url(#filter0_d)">
                                                                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" fill="#3F4347" />
                                                                        </g>
                                                                    </g>
                                                                    <defs>
                                                                        <filter id="filter0_d" x="-1.75" y="1.5" width="21.5" height="23" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                                                                            <feOffset dy="4" />
                                                                            <feGaussianBlur stdDeviation="2" />
                                                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                                                        </filter>
                                                                        <clipPath id="clip0">
                                                                            <rect width="18" height="18" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                                <span className="prev-Date">
                                                                    {this.state.calenderDates[0]}
                                                                </span>
                                                                <span className="seperator"> - </span>
                                                                <span className="next-Date">
                                                                    {this.state.calenderDates[1]}
                                                                </span>
                                                            </div>
                                                        </Button>
                                                        <Button onClick={(e) => { this.calenderAction((new Date(this.state.calenderDates[1])).setDate(new Date(this.state.calenderDates[1]).getDate() + 1)) }}>
                                                            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.01693 8.414L5.12106 4.207L1.01693 0L6.10352e-05 1.04235L3.08733 4.207L6.10352e-05 7.37165L1.01693 8.414Z" fill="#1D1E20" />
                                                            </svg>
                                                        </Button>
                                                    </ButtonGroup>
                                                    {this.state.calenderShow && (
                                                        <div className="calender">
                                                            <OutsideClickHandler
                                                                onOutsideClick={this.calenderHide}
                                                            >
                                                                <Calendar
                                                                    calendarType={"US"}
                                                                    ref={this.calender}
                                                                    onChange={this.calenderChange}

                                                                    value={
                                                                        new Date(this.state.calenderSelectDate)
                                                                    }
                                                                />
                                                            </OutsideClickHandler>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="position-filter">
                                                    {/* {this.state.positionList.length > 0 && (
                                                        <SingleSearchDropdownList
                                                            ref={this.ddlPosition}
                                                            itemList={this.state.positionList}
                                                            handleSelectedItem={this.handleSelectedItem.bind(
                                                                this,
                                                                1
                                                            )}
                                                            defaultItem={this.state.positionName}
                                                            defaultText={"Search Position"}
                                                            defaultName={this.state.defaultPosition}//{"Position"}
                                                            controlID="1"
                                                        />
                                                    )} */}
                                                </div>
                                                <div className="mr-auto department-filter">
                                                    {/* {this.state.departmentList.length > 0 && (
                                                        <SingleSearchDropdownList
                                                            ref={this.ddlDepartment}
                                                            itemList={this.state.departmentList}
                                                            handleSelectedItem={this.handleSelectedItem.bind(
                                                                this,
                                                                2
                                                            )}
                                                            defaultItem={this.state.positionName}
                                                            defaultText={"Search Department"}
                                                            defaultName={this.state.defaultDepartment}//{"Department"}
                                                            controlID="2"
                                                        />
                                                    )} */}
                                                </div>
                                                <div className="navigationSort">
                                                    <Dropdown className="more-action bg-blue">
                                                        <Dropdown.Toggle id="dropdown-ptoType">
                                                            <div className="d-flex align-items-center">
                                                                <svg
                                                                    width="18"
                                                                    height="18"
                                                                    viewBox="0 0 18 18"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
                                                                </svg>
                                                                {this.state.selectedFilter}
                                                                <div className="drop-arrow d-flex">
                                                                    <FiChevronDown />
                                                                </div>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {this.state.filterList.map(
                                                                (item: any, idx: any) => (
                                                                    <Dropdown.Item
                                                                        key={item.id}
                                                                        eventKey={item.value}
                                                                        onClick={(e: any) =>
                                                                            this.handleFilterChange(e, item)
                                                                        }
                                                                    >
                                                                        {item.value}
                                                                    </Dropdown.Item>
                                                                )
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <div className="navigationSettings">
                                                    <Dropdown className="more-action bg-blue">
                                                        <Dropdown.Toggle id="dropdown-ptoType">
                                                            <div className="d-flex align-items-center">
                                                                <svg
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 16 16"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M1.56286 10.1808L2.89619 12.4875C3.08019 12.8062 3.48819 12.9155 3.80686 12.7308L4.73752 12.1935C5.12419 12.4982 5.55152 12.7482 6.00086 12.9348V14.0002C6.00086 14.3682 6.29886 14.6668 6.66752 14.6668H9.33419C9.70286 14.6668 10.0009 14.3682 10.0009 14.0002V12.9348C10.4502 12.7482 10.8769 12.4982 11.2642 12.1935L12.1949 12.7308C12.5129 12.9142 12.9222 12.8042 13.1055 12.4875L14.4389 10.1808C14.6222 9.8635 14.5122 9.4535 14.1955 9.27016L13.2809 8.7415C13.3155 8.4955 13.3335 8.2475 13.3335 8.00016C13.3335 7.75283 13.3155 7.50483 13.2795 7.25883L14.1942 6.73016C14.5115 6.54683 14.6215 6.13683 14.4375 5.8195L13.1042 3.51283C12.9202 3.19416 12.5122 3.08483 12.1935 3.2695L11.2629 3.80683C10.8762 3.50216 10.4495 3.2515 10.0002 3.0655V2.00016C10.0002 1.63216 9.70219 1.3335 9.33352 1.3335H6.66686C6.29819 1.3335 6.00019 1.63216 6.00019 2.00016V3.0655C5.55086 3.25216 5.12419 3.50216 4.73686 3.80683L3.80686 3.2695C3.48752 3.08483 3.08019 3.19416 2.89619 3.51283L1.56286 5.8195C1.37953 6.13683 1.48953 6.54683 1.80619 6.73016L2.72086 7.25883C2.68486 7.50483 2.66686 7.75283 2.66686 8.00016C2.66686 8.2475 2.68486 8.4955 2.72086 8.7415L1.80619 9.27016C1.48886 9.4535 1.37886 9.8635 1.56286 10.1808ZM8.00019 5.3335C9.47086 5.3335 10.6669 6.5295 10.6669 8.00016C10.6669 9.47083 9.47086 10.6668 8.00019 10.6668C6.52952 10.6668 5.33352 9.47083 5.33352 8.00016C5.33352 6.5295 6.52952 5.3335 8.00019 5.3335Z" />
                                                                </svg>
                                                                View Options
                                                                <div className="drop-arrow d-flex">
                                                                    <FiChevronDown />
                                                                </div>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <div className="variance-modal">
                                                                <div className="bdy-container bdr-bottom">
                                                                    <div className="ttl">DATA COMPARISON</div>
                                                                    <ul className="comparison-list">
                                                                        {this.state.dataComparison.map(
                                                                            (item, index) => (
                                                                                <li
                                                                                    key={index}
                                                                                    className={
                                                                                        this.state.activeComparison ===
                                                                                            index
                                                                                            ? "active"
                                                                                            : ""
                                                                                    }
                                                                                    onClick={this.handleComparisonClick.bind(
                                                                                        this,
                                                                                        index,
                                                                                        () => { }
                                                                                    )}
                                                                                >
                                                                                    <div className="d-flex">
                                                                                        <div className="mr-auto">
                                                                                            {item}
                                                                                        </div>
                                                                                        <svg
                                                                                            width="18"
                                                                                            height="18"
                                                                                            viewBox="0 0 18 18"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                                                                                fill="#2CAF92"
                                                                                            />
                                                                                        </svg>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                                <div className="bdy-container bdr-bottom">
                                                                    <div className="ttl">
                                                                        Actuals Highlighting
                                                                    </div>
                                                                    <Form.Group as={Row} controlId="">
                                                                        <Form.Label column sm="8">
                                                                            0 - {Number(this.state.actualHighlight)}
                                                                            %
                                                                        </Form.Label>
                                                                        <Col sm="4">
                                                                            <Form.Check
                                                                                type="switch"
                                                                                className="sm-controll"
                                                                                id="custom-switch1"
                                                                                label=""
                                                                                checked={this.state.isOptionHighlight}
                                                                                onChange={(event: any) =>
                                                                                    this.handleActualFilter(
                                                                                        event,
                                                                                        this.state.actualHighlight
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </Form.Group>
                                                                    <Form.Group as={Row} controlId="">
                                                                        <Form.Label column sm="8">
                                                                            {Number(this.state.actualHighlight)}% +
                                                                        </Form.Label>
                                                                        <Col sm="4">
                                                                            <Form.Check
                                                                                type="switch"
                                                                                className="sm-controll"
                                                                                id="custom-switch2"
                                                                                label=""
                                                                                checked
                                                                            />
                                                                        </Col>
                                                                    </Form.Group>
                                                                </div>
                                                                <button
                                                                    className="bdy-container wht-bg"
                                                                    style={{ borderStyle: "none" }}
                                                                >
                                                                    <button
                                                                        className="mr-auto btn  wht-bg"
                                                                        disabled={this.state.loaderTrue}
                                                                        onClick={this.saveDefaultView}
                                                                    >
                                                                        {" "}
                                                                        Save as Default View
                                                                    </button>
                                                                </button>
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            {
                                                this.props.showRefershBanner == true &&
                                                <>
                                                    <div className="info-badges">
                                                        <div className="d-flex">
                                                            <div> Some data may have changed. {""}</div>
                                                            <a className="d-flex" onClick={() => this.refershData()}>Refresh the page</a>
                                                            <span>to reflect any data changes.</span>
                                                        </div>
                                                    </div>
                                                    {/* <div className="notification-banners orange-bg">
                                                            <div className="">
                                                                <div>
                                                                    Some data may have changed. <span><a className="" onClick={() => this.refershData()}>Refresh the page</a></span> to reflect any data changes. {""}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                </>
                                            }
                                            {this.state.exportedDate.length > 0 &&
                                                this.state.exportedDate !== "0" && (
                                                    <div className="info-badges">
                                                        <div className="d-flex">
                                                            <div>This week was exported as part of the {""}</div>
                                                            <a className="d-flex" onClick={() => this.openOverView()}> {this.state.payPeriodDates[0]} {"-"} {this.state.payPeriodDates[1]} {"payroll"} </a>
                                                            <span>on</span> <div className="bold">{this.state.exportedDate}</div>
                                                        </div>
                                                    </div>
                                                )}

                                            <div ref={this.positionFromTop}>
                                                <div className="combined-table">
                                                    {this.state.staticHeaderShow && (
                                                        <>
                                                            <div className="staticHeader"></div>
                                                            <div className="staticHeader grey-bg"></div>
                                                        </>
                                                    )}
                                                    {this.state.dayTotal.length > 0 && (
                                                        <div className="stickey">
                                                            <BootstrapTable
                                                                keyField="allDepartment"
                                                                data={this.state.dayTotal}
                                                                columns={columnsHeader}
                                                                sort={{
                                                                    dataField: this.state.field,
                                                                    order: this.state.order,
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    <OutsideClickHandler
                                                        onOutsideClick={(e) => {
                                                            this.onOutsideClick(e);
                                                        }}
                                                    >
                                                        {this.state.performanceSummery.length > 0 && (
                                                            <div className="stickey-body">
                                                                <BootstrapTable
                                                                    {...props.baseProps}
                                                                    keyField="userName"
                                                                    expandRow={expandRow}
                                                                    rowClasses={rowClasses}
                                                                    hover
                                                                    sort={{
                                                                        dataField: this.state.field,
                                                                        order: this.state.order,
                                                                    }}
                                                                    filter={filterFactory()}
                                                                />
                                                            </div>
                                                        )}
                                                    </OutsideClickHandler>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </ToolkitProvider>
                            )}
                        </div>
                    </div>
                )}
            </>
        )
    }
}