import React from "react";
import { Container, Dropdown, ProgressBar, Form, Row, Col, Modal, Spinner } from "react-bootstrap";
import Dropzone from 'react-dropzone';
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { HidSelector } from "../../../Common/Components/HidSelector";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
import Delete from "../../../Common/Assets/Images/icons/delete.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./payrollImport.scss";
import { resolve, reject } from "q";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import { cashMgmtService } from "../../../Common/Services/cashManagement";
import { CommonService } from "../../../Common/Services/CommonService";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { Utils } from "../../../Common/Utilis";

const { SearchBar } = Search;
export class payrollImport extends React.Component<any, any> {
  private childHID: any;
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.state = {
      BlobbaseUrl:"",
      hidValue: "",
      calenderShow: false,
      calenderDates: "Select Date",
      calenderSelectDate: new Date(),
      startDate: "",
      endDate: "",
      selectedFilter: "All",
      currentPageIndex: 0,
      newImportModal: false,
      filterList: [
        { id: "Check", value: "Check", status: false },
        { id: "COAs", value: "COAs", status: false },
        { id: "All", value: "All", status: true },
      ],
      payrollImportData: [],
      filterImportData: [],
      detailTableDate: [],
      selected: [],
      isselected: false,
      selectedRow: true,
      selectedRowData: {},
      isProgress: 0,
      files: [],
      isDeleteHilde: true,
      leftPanelLoader: false,
      rightPanelLoader: false,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
this.getBlobbaseUrl();
  }

  getBlobbaseUrl = () => {
   
    let keyName ="BlobbaseUrl";
    let BlobbaseUrl="";
     CommonService.GetInnflowConfigKeyValue(keyName)
       .then(async (result: any | null) => {
         console.log(result);        
         if (result !== null) {
           BlobbaseUrl = result;
           this.setState({ BlobbaseUrl:BlobbaseUrl });
         }
         
       });
   }

  onDrop = (files: any) => {
    this.setState({ files })
    if (files.length > 0) {
      let Previews = files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
      let request: any = {};
      request.hotelID = this.state.hidValue;
      cashMgmtService.ImportPayrollSave(request, Previews, (event) => {
        this.setState({ isProgress: Math.round((100 * event.loaded) / event.total) })
      }).then(async (result: any | null) => {
        if (result !== null) {
          if (result.saveStatus === "Success") {
            //this.setState({ isProgress: 100 })
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "payrollImport",
            });
            this.setState({ newImportModal: false, files: [], selectedFilter: "All", detailTableDate: [], selectedRowData: {}, selected: [] }, () => {
              this.loadPayrollImportLogs(this.state.hidValue, 2);
            });

          }
          else {
            this.setState({ files: [] })
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "payrollImport",
            });
          }
        }
        resolve();
      })
        .catch((error) => {
          reject();
        });
    }
  }

  handleFilterChange = (event: any, value: any) => {
    this.setState({
      filterImportData: [],
      detailTableDate: [], selectedRowData: {}, selected: [], selectedFilter: "All",
    })
    let data = this.state.filterList;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === value.id) {
        data[i].status = true;
      } else {
        data[i].status = false;
      }
    }

    let filterdata: any;
    if (this.state.calenderDates !== "Select Date") {
      if (value.value !== "All") {
        filterdata = this.state.payrollImportData.filter(e => e.date === this.state.calenderDates && e.type === (value.value === "COAs" ? "COA" : value.value))
      } else {
        filterdata = this.state.payrollImportData.filter(e => e.date === this.state.calenderDates)
      }
    } else {
      if (value.value !== "All") {
        filterdata = this.state.payrollImportData.filter(e => e.type === (value.value === "COAs" ? "COA" : value.value))
      } else {
        filterdata = this.state.payrollImportData;
      }
    }

    this.setState({
      filterList: data, selectedFilter: value.value, filterImportData: filterdata
    });

  };

  handleDelete = (e: any) => {
    confirmAlert({
      title: "Delete confirmation",
      message:
        "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deletePayroll(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  deletePayroll() {
    let request: any = {};
    request.hotelID = this.state.hidValue;
    request.id = this.state.selectedRowData.logId;
    cashMgmtService.deletePayrollData(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.saveStatus === "Success") {
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "payrollImport",
            });
            this.setState({ detailTableDate: [], selectedRowData: {}, selected: [], selectedFilter: "All" })
            this.loadPayrollImportLogs(this.state.hidValue, 1);
          }
          else {
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "payrollImport",
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({
      hotelName: hotelDetails.lettercode,
      hidValue: hotelDetails.hotelID,
      payrollImportData: [], filterImportData: [],
      detailTableDate: [], selectedRowData: {}, selected: [], selectedFilter: "All"
    });
    this.loadPayrollImportLogs(hotelDetails.hotelID, 1);
  };

  loadPayrollImportLogs(hotelId: any, mode: any) {
    this.setState({ leftPanelLoader: true })
    let request: any = {};
    request.hotelID = hotelId;
    cashMgmtService.getPayrollImportLog(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          this.setState({ payrollImportData: [], filterImportData: [], leftPanelLoader: false, }, () => {
            this.setState({ payrollImportData: result.result, filterImportData: result.result })
          });
        } else {
          this.setState({ leftPanelLoader: false })
        }
      });
  }

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  AllDates = () => {
    this.setState({
      filterImportData: [],
      detailTableDate: [], selectedRowData: {}, selected: [], selectedFilter: "All"
    })
    let filterdata: any;
    if (this.state.selectedFilter !== "All") {
      filterdata = this.state.payrollImportData.filter(e => e.type === (this.state.selectedFilter === "COAs" ? "COA" : this.state.selectedFilter))
    } else {
      filterdata = this.state.payrollImportData;
    }
    this.setState({ calenderSelectDate: new Date(), calenderDates: "Select Date", calenderShow: false, filterImportData: filterdata });

  };

  calenderChange = (date: any) => {
    this.setState({
      filterImportData: [],
      detailTableDate: [], selectedRowData: {}, selected: [], selectedFilter: "All"
    })
    let calenderYeare = date.getFullYear().toString().substr(2, 4);
    let calenderDatee = date.getDate() > 9 ? date.getDate() : 0 + "" + date.getDate();
    let calenderMonthe = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : 0 + "" + (date.getMonth() + 1);
    let tempDate = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;
    let filterdata: any;
    if (this.state.selectedFilter !== "All") {
      filterdata = this.state.payrollImportData.filter(e => e.date === tempDate && e.type === (this.state.selectedFilter === "COAs" ? "COA" : this.state.selectedFilter))
    } else {
      filterdata = this.state.payrollImportData.filter(e => e.date === tempDate)
    }
    this.setState({ calenderSelectDate: date, calenderDates: tempDate, calenderShow: false, filterImportData: filterdata });

  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({
        selectedRow: true,
        selected: [row.id],
        isselected: true,
      });
      this.getPayrollImportRecord(row);
    },
  };

  getPayrollImportRecord(row: any) {
    this.setState({ rightPanelLoader: true, });
    let request: any = {};
    request.hotelID = this.state.hidValue;
    request.logID = row.logId;
    cashMgmtService.getPayrollImportRecord(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          let filterArr = result.result.filter(e => e.cl !== "JRN" && e.cl !== "" && e.cl !== null);
          let isDeleteHilde = true;
          if (filterArr.length > 0) {
            isDeleteHilde = true;
          } else {
            isDeleteHilde = false;
          }
          this.setState({ detailTableDate: result.result, selectedRowData: row, isDeleteHilde, rightPanelLoader: false });
        } else {
          this.setState({ rightPanelLoader: false });
        }
      });
  }

  showNewImportModal = () => {
    this.setState({ newImportModal: true });
  }

  hideNewImportModal = () => {
    this.setState({ newImportModal: false });
  }

  downloadTemplate = () => {
    let BlobbaseUrl =this.state.BlobbaseUrl;
    //https://innflowfilestoretest.blob.core.windows.net/innflowtemplate/ImportPayrollChecks.xls
    fetch(BlobbaseUrl+'innflowtemplate/ImportPayrollChecks.xls', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/xls',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `ImportPayrollChecks.xls`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
      });
  }

  render() {
    const files = this.state.files.map(file => (
      <li key={file.name}>
        <div className="d-flex align-items-center">
          <div className="file-name">{file.name}</div>
          <div className="file-loader mr-auto"><ProgressBar variant="success" now={this.state.isProgress} /></div>
          <div className="file-percentage">{this.state.isProgress} %</div>
        </div>
      </li>
    ));

    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      classes: "active-row",
      selected: this.state.isselected ? this.state.selected : "",
    };

    const hideRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      classes: "",
    };
    const columns = [
      {
        dataField: "endDate",
        text: "",
        hidden: true,
      },
      {
        dataField: "date",
        text: "",
        formatter: (
          cell: any,
          row: {
            endDate: React.ReactNode;
            date: React.ReactNode;
            etype: React.ReactNode;
          },
          rowIndex: any
        ) => (
          <div>
            <div className="title"> {row.etype === "Auto" ? row.endDate : row.date}</div>
            {row.etype !== "Auto" && (
              <div className="desc">Manual</div>
            )}
          </div>
        ),
      },
      {
        dataField: "type",
        text: "",
        formatter: (
          cell: any,
          row: { type: React.ReactNode; },
          rowIndex: any
        ) => (
          <div className="badge orange-bg">{row.type === "COA" ? "COAs" : row.type}</div>
        ),
      },
    ];

    const detailColumns = [
      {
        dataField: "date",
        text: "Pay Period End Date",
        hidden: true,
      },
      {
        dataField: "period",
        text: "Act. Per.",
      },
      {
        dataField: "ckno",
        text: "CheckNo",
      },
      {
        dataField: "vendor",
        text: "Vendor",
        classes: 'max-width-300',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.vendor}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "displayName",
        text: "Nickname",
      },
      {
        dataField: "lettercode",
        text: "E/HID",
      },
      {
        dataField: "coa",
        text: "COA",
      },
      {
        dataField: "description",
        text: "Description",
        classes: 'max-width-300',
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.description}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "paymentNew",
        text: "Payment",
        headerClasses: 'amount-align',
        classes: 'amount-align',
      },
      {
        dataField: "depositNew",
        text: "Deposit",
        headerClasses: 'amount-align',
        classes: 'amount-align',
      },
      {
        dataField: "transtotalNew",
        text: "TransTotal",
        headerClasses: 'amount-align',
        classes: 'amount-align',
      },
      {
        dataField: "cl",
        text: "CL",
      }
    ]
    return (
      <Container fluid className="body-sec">
        <ToastContainer
          autoClose={3000}
          containerId={"payrollImport"}
          enableMultiContainer
        />
        <div className="budget-settings-main payroll-import">
          <div className="page-heading underline d-flex mrgn-btm">
            <HidSelector
              onClickEvent={this.selectHotelDropDown}
              ref={this.childHID}
              modulename={'Accounting'}
            />
            <div className="mr-auto">Payroll Import</div>
            <div className="action-group d-flex flex-row">
              {this.state.hidValue !== "" && (
                <button type="button" className="btn btn-primary" onClick={this.showNewImportModal}>New Import</button>
              )}
              <Dropdown className="more-action three-dot" alignRight>
                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="" key="" onClick={this.downloadTemplate}>Download Template</Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </div>

          </div>

          <div className="performance-position performance payroll-import">
            <div className="body-sec">
              <div className="main-Page">
                <div className="search-header d-flex">
                  {this.state.hidValue !== "" && (
                    <>
                      <div className="calender-container ml-0">
                        <div
                          className={
                            this.state.calenderShow
                              ? "calender-display open"
                              : "calender-display"
                          }
                          onClick={this.calenderShow}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                          </svg>

                          <span className="prev-Date">
                            {this.state.calenderDates}
                          </span>
                          <span className="chevron-down">
                            <FiChevronDown />
                          </span>
                        </div>
                        {this.state.calenderShow && (
                          <div className="calender">
                            <OutsideClickHandler
                              onOutsideClick={this.calenderHide}
                            >
                              <Calendar
                                calendarType={"US"}
                                onChange={this.calenderChange}
                                value={
                                  new Date(this.state.calenderSelectDate)
                                }
                              />
                              {this.state.calenderDates !== "Select Date" && (
                                <div
                                  className="all-dates"
                                  onClick={() => this.AllDates()}
                                >
                                  {" "}
                                  All Dates
                                </div>
                              )}
                            </OutsideClickHandler>
                          </div>
                        )}
                      </div>
                      <div className="position-filter select">
                        <Dropdown className="more-action bg-blue">
                          <Dropdown.Toggle id="dropdown-ptoType">
                            <svg
                              width="14"
                              height="10"
                              viewBox="0 0 14 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 2C0.447715 2 0 1.55228 0 0.999999C0 0.447715 0.447715 0 0.999999 0H12.3328C12.8851 0 13.3328 0.447715 13.3328 0.999999C13.3328 1.55228 12.8851 2 12.3328 2H1Z" />
                              <path d="M3 6C2.44771 6 2 5.55228 2 5C2 4.44771 2.44771 4 3 4H10.3328C10.8851 4 11.3328 4.44771 11.3328 5C11.3328 5.55228 10.8851 6 10.3328 6H3Z" />
                              <path d="M5.66601 10C5.11373 10 4.66602 9.55229 4.66602 9C4.66602 8.44772 5.11373 8 5.66602 8H7.66601C8.2183 8 8.66601 8.44772 8.66601 9C8.66601 9.55229 8.2183 10 7.66601 10H5.66601Z" />
                            </svg>
                            {this.state.selectedFilter}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div className="ttl">TYPE</div>
                            {this.state.filterList.map(
                              (item: any, idx: any) => (
                                <Dropdown.Item
                                  className={item.status ? "active" : ""}
                                  key={item.id}
                                  eventKey={item.value}
                                  onClick={(e: any) =>
                                    this.handleFilterChange(e, item)
                                  }
                                >
                                  <div className="d-flex">
                                    <div className="mr-auto">
                                      {" "}
                                      {item.value}
                                    </div>
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                        fill="#2CAF92"
                                      />
                                    </svg>
                                  </div>
                                </Dropdown.Item>
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="budget-settings smileIdOverview payroll-import">
            <div className="panel-container d-flex justify-content-start">

              <div className="left-panel">
                {this.state.hidValue !== "" && (
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.filterImportData}
                    columns={columns}
                    rowEvents={this.rowEvents}
                    search
                  >
                    {(props: {
                      searchProps: JSX.IntrinsicAttributes;
                      baseProps: JSX.IntrinsicAttributes;
                    }) => (
                      <div>
                        <div className="search-header">
                          <SearchBar {...props.searchProps} placeholder={"Filter"} />
                        </div>
                        {this.state.leftPanelLoader ? (
                          <ReactPageLoader useas="payrollImportLeftPanel" />
                        ) : (
                          <div className="left-body">
                            <BootstrapTable
                              {...props.baseProps}
                              keyField="id"
                              rowEvents={this.rowEvents}
                              selectRow={this.state.selectedRow ? selectRow : hideRow}
                              hover
                              condensed
                            />
                            <div className="add-Budget" onClick={this.showNewImportModal}>
                              New Import
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </div>

              <div className="right-panel">
                {this.state.rightPanelLoader ? (
                  <ReactPageLoader useas="payrollImportRightPanel" />
                ) : (
                  <>
                    <div className="pos-header d-flex align-content-center flex-wrap">
                      {(this.state.detailTableDate.length > 0) && (
                        <>
                          <div className="pos-ttl mr-auto">Payroll log for {this.state.selectedRowData.date}</div>
                          {(!this.state.isDeleteHilde && this.state.selectedRowData.etype !== "Auto") && (
                            <Dropdown className="more-action" alignRight>
                              <Dropdown.Toggle
                                className="btn-outline-primary btn btn-primary more"
                                id="dropdown-more"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                  <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                  <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={this.handleDelete}>
                                  <img src={Delete} alt="Delete" /> Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </>
                      )}
                    </div>
                    <div className="pos-body">
                      {this.state.detailTableDate.length > 0 && (
                        <>
                          <div className="data-info">
                            <Row className="mb-3">
                              <Col sm="6">
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                  <Form.Label column sm="6">Imported by</Form.Label>
                                  <Col sm="6">
                                    <Form.Control plaintext readOnly value={this.state.selectedRowData.userName} />
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col sm="6">
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                  <Form.Label column sm="6">Date of Import</Form.Label>
                                  <Col sm="6">
                                    <Form.Control plaintext readOnly value={this.state.selectedRowData.edate} />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col sm="6">
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                  <Form.Label column sm="6">Type (Check or COA)</Form.Label>
                                  <Col sm="6">
                                    <Form.Control plaintext readOnly value={this.state.selectedRowData.type} />
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col sm="6">
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                  <Form.Label column sm="6">Accounting Period</Form.Label>
                                  <Col sm="6">
                                    <Form.Control plaintext readOnly value={this.state.selectedRowData.accper} />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col sm="6">
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                  <Form.Label column sm="6">Payment Date</Form.Label>
                                  <Col sm="6">
                                    <Form.Control plaintext readOnly value={this.state.selectedRowData.date} />
                                  </Col>
                                </Form.Group>
                              </Col>
                              <Col sm="6">
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                  <Form.Label column sm="6">Pay Period End Date</Form.Label>
                                  <Col sm="6">
                                    <Form.Control plaintext readOnly value={this.state.selectedRowData.endDate} />
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                          <div className="detail-tbl">
                            <BootstrapTable
                              id="detailTbl"
                              keyField="rowNo"
                              data={this.state.detailTableDate}
                              columns={detailColumns}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal className="import-payroll-modal" dialogClassName="modal-60w" backdrop="static" show={this.state.newImportModal} onHide={this.hideNewImportModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Payroll Import</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Dropzone onDrop={this.onDrop} accept=".xlsx,.xls,.csv" multiple={false}>
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <>
                  {this.state.files.length === 0 && (
                    <section className={isDragActive ? "container upload-area default drag-active " : "container upload-area default"}>
                      <div {...getRootProps({ className: 'dropzone d-flex align-items-center' })}>
                        <input {...getInputProps()} />
                        <p>{/*Drag and drop invoices or <span>browse files</span> to upload*/}
                          Drag and drop payroll file here to upload
                          <br /><strong className="acceptedFiles">.xls, .xlxs</strong></p>

                      </div>
                    </section>
                  )}
                  {this.state.files.length > 0 && (
                    <aside className="d-flex flex-column file-list">
                      <ul>{files}</ul>
                    </aside>
                  )}
                </>
              )}
            </Dropzone>
          </Modal.Body>
        </Modal>

      </Container>
    );
  }
}
