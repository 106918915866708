import * as React from "react";
import { Dropdown } from "react-bootstrap";
export class EpayStatus extends React.Component<any, any> {
  private summarySubTypes = [
    { label: "Processing", value: "Awaiting Funds" },
    { label: "Paid-Funded", value: "Funded" },
    { label: "Paid-Sent", value: "Open" },
    { label: "Paid", value: "Paid" },
    { label: "All", value: "All" },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      selectedItem: { label: "All", value: "All" },
      summarySubTypes: this.summarySubTypes,
    };
  }

  componentDidMount = () => {
    const { defaultValue } = this.props;
    if (defaultValue === undefined) {
      this.handleDropdownChange(this.state.selectedItem);
    } else {
      let currentItem = this.state.summarySubTypes.filter((o: any) => o.value === defaultValue);
      this.handleDropdownChange({ label: currentItem[0].label, value: defaultValue, });
    }
  };

  handleDropdownChange = (item: any) => {
    this.setState({ selectedItem: item }, () => {
      this.props?.handleEpayStatus(item.value);
    });
  };

  componentDidUpdate = (prevprops, prevState) => {
    const { defaultValue } = this.props;
    if (((prevprops.defaultValue !== defaultValue)) && defaultValue?.toString().length > 0) {
      let arr = this.state.summarySubTypes.filter(e => e.value === defaultValue);
      this.setState({ selectedItem: arr.length > 0 ? arr[0] : { label: "All", value: "All" } });
    }
    // if (!defaultValue && prevprops?.defaultValue !== defaultValue) {
    //   this.setState({ selectedItem: { label: "30 Days", value: 30 } });
    // }
  }

  render() {
    return (
      <Dropdown className="single-select">
        <Dropdown.Toggle id="Modal-empType">
          <span
            style={{
              width: "95%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {this.state.selectedItem.label}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.summarySubTypes.map((item: any, idx: any) => (
              <Dropdown.Item onClick={() => this.handleDropdownChange(item)}>
                {item.label}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}