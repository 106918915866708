import React from "react";
import { Form, Container, Card, Modal, Button, Spinner, OverlayTrigger, Tooltip} from "react-bootstrap";
import { UserManagement } from "../../../../Common/Services/UserManagement";
import { resolve, reject } from "q";
import Joi from "joi-browser";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import { toast } from "react-toastify";
import { Utils } from "../../../../Common/Utilis";
import { OTPCodeModal } from "../OTPCodeModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { DropDownList } from "../../../Reports/common-components/dropdown-list";
import {
  IIncompleteUserRequest,
  IValidateUserEmailAndPhone,
} from "../../../../Common/Contracts/IIncompleteUserRequest";
import { OtpModal } from "../../../../Common/Components/OtpModal";
import { accountService } from "../../../../Common/Services/account";
import { InfoTooltip } from "../../../../Common/Components/InfoTooltip";
import { User } from "../../../../Common/Services/User";
import { DropDownListWithDescription } from "../../../../Common/Components/dropDownListWithDescription";
import { PassCodeService as OTPService } from "../../../../Common/Services/PassCodeService";

let justSaved = false;
const styles = (theme) => ({
  overrideMe: {
    maxWidth: "auto",
  },
});

export interface IGeneralProps {
  userData: any;
  userGeneralInfo: any;
  onEnterpriseAccessChange: Function;
  getGeneralTabModificationStatus: Function;
  getUserGeneralInfo: Function;
  user: any;
  enterpriseAccess: any;
  enableEnterpriseAccess: any;
  statusList: any[];
  uniqueno: string;
  isNewUserAdded: boolean;
  isDuplicatePhone: boolean;
  isDuplicateEmail: boolean;
  isMissingPhone: boolean;
  isMissingEmail: boolean;
  updatePhoneStatus: Function;
}

const primaryContacts: any[] = [
  {
    label: "Email",
    value: "Email"
    
  },
  { label: "Phone", value: "Phone" },
];

const primaryMethods: any[] = [
  {
    label: "Text Message",
    value: "TextMsg",
    disabled: true,
    description : "Enable method above to select"
  },
  { label: "Google Authenticator", 
  value: "GoogleFA" ,
  disabled: true,
  description : "Enable method above to select"},
];


let accountStatusList: any[] = [
  {
    label: "Unlock",
    value: 1,
  },
  { label: "Auto lock", value: 2, disabled: true },
  { label: "Manager Lock", value: 3 },
];

const contactFormSchema = Joi.object({
  user_UniqueId: Joi.number(),
  tenantID: Joi.number(),
  createdBy: Joi.number(),
  primaryContact: Joi.string().allow(""),
  primaryMethod: Joi.string().allow(""),
  terminationDate: Joi.string().allow(""),
  terminationReason: Joi.string().allow(""),
  userUniqueID: Joi.number(),
  accountStatus: Joi.number(),
  firstName: Joi.string()
    .required()
    .error((errors) => {
      return errors.map((error) => {
        switch (error.type) {
          case "any.empty":
            return { message: "First Name is required" };
        }
      });
    }),
  lastName: Joi.string()
    .required()
    .error((errors) => {
      return errors.map((error) => {
        switch (error.type) {
          case "any.empty":
            return { message: "Last Name is required" };
        }
      });
    }),
  email: Joi.string()
    .regex(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "email"
    )
    .required()
    .max(200)
    .error((errors) => {
      const _errors = errors.map((error) => {
        if (error?.message) {
          return;
        }
        switch (error.type) {
          case "any.empty":
            return { message: "Email Address is required" };
          case "string.regex.name":
            return { message: "Email Address is not valid" };
          case "string.max":
            return { message: "Email Address is not valid" };
        }
      });
      return [_errors[0]];
    }),

  phone1: Joi.string()
    .optional()
    .allow("")
    .regex(/^(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, "phone1")
    .error((errors) => {
      const _errors = errors.map((error) => {
        return { message: "Mobile Phone Number is not valid" };
      });
      return [_errors[0]];
    }),
  status: Joi.string(),
  noPhone: Joi.boolean().default(false),
  updateLater: Joi.boolean().default(false),
  isTextMessage : Joi.boolean().default(false),
  isGoogleFA : Joi.boolean().default(false),
  allow2FA : Joi.boolean().default(false)
});

export class LockInGeneral extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      contactInfo: {
        firstName: props.userGeneralInfo.firstName,
        lastName: props.userGeneralInfo.lastName,
        email: props.userGeneralInfo?.isDuplicateEmail
          ? ""
          : props.userGeneralInfo.email,
        userUniqueID: props.userGeneralInfo.user_UniqueId,
        phone1:
          props.userGeneralInfo?.isDuplicatePhone ||
            props.userGeneralInfo.phoneStatus === "blocked"
            ? ""
            : props.userGeneralInfo.phone1,
        status: props.userGeneralInfo?.status || "Incomplete",
        terminationDate:
          props.userGeneralInfo.terminationDate != "0001-01-01T00:00:00"
            ? moment(props.userGeneralInfo.terminationDate).format("MM/DD/YY")
            : "",
        terminationReason: props.userGeneralInfo.terminationReason || "",
        primaryContact: props.userGeneralInfo?.primaryContact || "Email",
        accountStatus: props.userGeneralInfo?.accountStatus || 0,
        noPhone:  props.userGeneralInfo?.phoneUpdateStatus == "NoPhone" ? true : false,
        updateLater:   justSaved  ? (props.userGeneralInfo?.phoneUpdateStatus == "UpdateLater" ? true : false) : false,
        primaryMethod: props.userGeneralInfo?.primaryMethod || "0",
        isTextMessage :props?.userGeneralInfo?.isTextMessage > 0 ? true : false,
        isGoogleFA:props?.userGeneralInfo?.isGoogleFA > 0 ? true : false,
        allow2FA: props?.userGeneralInfo?.allow2FA > 0 ? true : false
      },
      prevContactInfo: {
        firstName: props.userGeneralInfo.firstName,
        lastName: props.userGeneralInfo.lastName,
        email: props.userGeneralInfo?.isDuplicateEmail
          ? ""
          : props.userGeneralInfo.email,
        userUniqueID: props.userGeneralInfo.user_UniqueId,
        phone1:
          props.userGeneralInfo?.isDuplicatePhone ||
            props.userGeneralInfo.phoneStatus === "blocked"
            ? ""
            : props.userGeneralInfo.phone1,
        status: props.userGeneralInfo?.status || "Incomplete",
        terminationDate:
          props.userGeneralInfo.terminationDate !== "0001-01-01T00:00:00"
            ? moment(props.userGeneralInfo.terminationDate).format("MM/DD/YY")
            : "",
        terminationReason: props.userGeneralInfo.terminationReason || "",
        primaryContact: props.userGeneralInfo?.primaryContact || "Email",
        accountStatus: props.userGeneralInfo?.accountStatus || 0,
        noPhone:  props.userGeneralInfo?.phoneUpdateStatus == "NoPhone" ? true : false,
        updateLater:  justSaved == true ? (props.userGeneralInfo?.phoneUpdateStatus == "UpdateLater" ? true : false) : false,
        primaryMethod: props.userGeneralInfo?.primaryMethod || "0",
        isTextMessage :props?.userGeneralInfo?.isTextMessage > 0 ? true : false,
        isGoogleFA:props?.userGeneralInfo?.isGoogleFA > 0 ? true : false,
        allow2FA: props?.userGeneralInfo?.allow2FA > 0 ? true : false
      },
      accessControlInfo: {
        role: props.userGeneralInfo.role || 0,
        hasEnterpriseAccess: props.userGeneralInfo.hasEnterpriseAccess,
        hids: props.userGeneralInfo.hids,
      },
      contactFormError: {},
      inputPosition: 20,
      isContactFormValid: false,
      isContactFormUpdated: false,
      userName: props.userGeneralInfo?.userName,
      duplicatePhoneMessage: "",
      duplicateEmailMessage: "",
      isDataSubmitting: "",
      hotelWiseSetPayHours: [],
      hasLogiUserEnterpriseAccess: false,
      userRoleList: [],
      openOTPModal: false,
      mode: "",
      isOtpNeed: false,
      otpSuccess: false,
      isEmailOtpVerified: props.userGeneralInfo?.email ? true : false,
      isPhoneOtpVerified: (props.userGeneralInfo?.phone1 || props.userGeneralInfo?.phoneUpdateStatus == "NoPhone") ? true : false,
      isEmailDuplicate: props.userGeneralInfo?.isDuplicateEmail,
      isPhoneDuplicate: props.userGeneralInfo?.isDuplicatePhone,
      primaryContact: "Phone",
      primaryMethod: "TextMsg",
      timeOutFunc: {},

      isTextMessage: this.props?.userGeneralInfo?.isTextMessage > 0 ? true : false,
      isGoogleFA: this.props?.userGeneralInfo?.isGoogleFA > 0 ? true : false,
      isSendOTP: false,
      otp: "",
      otpCount: 0,
      reSendOtp: false,

      errorMessage: "",
      errorMessageForGoogle:"",
      isValidOTP:false,
      isSendAuthKey:false,
      authKeyData: {},
      code:"",
      isValidCode:false,
      isClosePanel:false,
      isOpenOTPModal:false,
      isOpenCodeModal:false,
      isTextMessageDisabled: false,
      isGoogleDisabled: false,
      allow2FA: this.props?.userGeneralInfo?.allow2FA > 0 ? true : false,
      isSendOTPSubmitted : false,
      isOTPSubmitted:false,
      isGoogleCodeSubmitted:false,
      otpID :0,
      isgoogleAuth: false,
      isSpinner:false,
      isSubmitSpinner:false
    };
    this.resendTextMsgcode = this.resendTextMsgcode.bind(this);
    if(this.state.contactInfo["isGoogleFA"]){
      primaryMethods[1].disabled=false;
    }
    if(this.state.contactInfo["isTextMessage"]){
      primaryMethods[0].disabled=false;
    }
  }

  renderInfoIcon = () => {
    return (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        version="1.1"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
      </svg>
    );
  };
  renderTextMessageTooltip = (e) => (
    <Tooltip id="button-tooltipPwd" {...e}>
      To allow Inn-Flow to send OTP to your phone number for 2 Factor Authentication.
    </Tooltip>
  );
  renderGoogleTooltip = (e) => (
    <Tooltip id="button-tooltipPwd" {...e}>
      To allow Inn-Flow to use your Google authentication app for 2 Factor Authentication.
    </Tooltip>
  );
  renderValidationTooltip = (e) => (
    
    <Tooltip id="button-tooltipPwd" {...e}>
      
      Due to your role, you can not disable 2-Factor Authentication.
      <br/>
      Setup secondary...
    </Tooltip>
 
  );
  componentDidMount = () => {
    // setTimeout(() => {
    //   toast.dismiss();
    // }, 1000);
    this.GoogleAuthFeatureFlag();

  
    this.validationOnContactClick(20);
    accountStatusList = this.props.isNewUserAdded
      ? accountStatusList.map((item) => {
        if (item.value === 3) {
          item.disabled = true;
        }
        return item;
      })
      : accountStatusList.map((item) => {
        if (item.value === 3) {
          item.disabled = false;
        }
        return item;
      });
  };

  
  validateAndUpdateContactFormStatus = () => {
    const { userGeneralInfo } = this.props;
    const { contactInfo, prevContactInfo } = this.state;
    this.validateContactForm();
    if (userGeneralInfo?.email !== contactInfo?.email) {
      this.setState({ isEmailOtpVerified: false });
    } else {
      this.setState({ isEmailOtpVerified: true });
    }

    if (userGeneralInfo?.email) {
      if (userGeneralInfo?.email !== contactInfo?.email) {
        this.setState({ isEmailOtpVerified: false });
      } else {
        this.setState({ isEmailOtpVerified: true });
      }
    }

 
      if (userGeneralInfo?.phone1 !== contactInfo?.phone1  && !(contactInfo.noPhone || contactInfo.updateLater)) {
        this.setState({ isPhoneOtpVerified: false });
      } else {
        this.setState({ isPhoneOtpVerified: true });
      }
    

    if (prevContactInfo) {
      const _isContactFormUpdated = !_.isEqual(contactInfo, prevContactInfo);
      this.setState({ isContactFormUpdated: _isContactFormUpdated});
      justSaved=false ;
     
      if (_isContactFormUpdated) {
        this.props.getGeneralTabModificationStatus(_isContactFormUpdated);
      } else {
        this.props.getGeneralTabModificationStatus(false);
      }
    }
  };

  CapitalizeFirstLetter =(string) =>{
    if(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
    } else {
     return string; 
    }
  }

  validatePhoneNo = (phone, inputPosition): any => {
    const { contactInfo, contactFormError } = this.state;
    this.setState({ isContactFormValid: false });
    contactInfo.phone1 = phone;
    const errorObj: any = contactFormError;
    if (!phone) {
      if (
        errorObj?.phone1 &&
        contactInfo.primaryContact === "Email"
      ) {
        delete errorObj.phone1;
        this.setState({ contactFormError: errorObj });
      }
      return;
    }

    if (
      phone?.toString()?.includes("+") &&
      phone?.toString()?.substr(1, 1) !== "1"
     

    ) {
      if(phone?.toString()?.length <9) {
        errorObj["phone1"] ="Mobile Phone Number is not valid";
        this.setState({contactFormError:errorObj}, ()=> this.validateContactForm());
      }
      else if (errorObj.phone1   ) {
        delete errorObj.phone1;
        this.setState({ contactFormError:errorObj, inputPosition }, () => this.validateContactForm());
      } else {
        this.validateContactForm();
      }
      
      

      return;
    }
    this.setState({ isDataSubmitting: true });
    User.ValidatePhoneNumber(phone)
      .then((response: any) => {
        if (!this.state.contactInfo.phone1) {
          this.validateAndUpdateContactFormStatus();
          return;
        }

        if (
          response?.fakeNumber === "YES" 
          //&&
        //  response?.lineType === "CELL PHONE"
        ) {
          errorObj["phone1"] = "Mobile Phone Number is not valid";
          this.setState({ inputPosition, contactFormError: errorObj });
        } else if (response?.lineType !== "CELL PHONE") {
          errorObj["phone1"] = response?.lineType && response?.lineType !== "FAKE" ? `${this.CapitalizeFirstLetter(response?.lineType)} Phone Numbers are not supported` : `Mobile Phone Number is not valid`;
          this.setState({ inputPosition, contactFormError: {...errorObj} });
        } else {
          this.validateAndUpdateContactFormStatus();
          if ((contactInfo.phone1 !== this.props?.userGeneralInfo.phone1) && !(contactInfo.noPhone || contactInfo.updateLater)) {
            this.setState({ isPhoneOtpVerified: false });
          }
          if (errorObj?.phone1) {
            delete errorObj.phone1;
            this.setState({ contactFormError: errorObj });
          }
        }
        if (Object.keys(errorObj).length === 0) {
          this.setState({ isContactFormValid: true });
          this.validateContactForm();
        } else {
          this.setState({ isContactFormValid: false });
        }
      })
      .finally(() => this.setState({ isDataSubmitting: false }));
  };

  validateContactForm = () => {
    const { contactInfo } = this.state;
    const valid = Joi.validate(this.state.contactInfo, contactFormSchema, {
      abortEarly: false,
    });

    let newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    
    if(
      newErrorObject["phone1"] == "Mobile Phone Number is not valid"&&
      contactInfo?.phone1?.toString()?.length >= 9 &&
      (contactInfo?.phone1?.toString()?.startsWith("+1242") || 
      contactInfo?.phone1?.toString()?.startsWith("1242") || 
      contactInfo?.phone1?.toString()?.startsWith("242"))
    ){
       delete newErrorObject["phone1"];
    }

    if(
      newErrorObject["phone1"] == "Mobile Phone Number is not valid"&&
      contactInfo?.phone1?.toString()?.length >= 9 &&
      (contactInfo?.phone1?.toString()?.startsWith("+1242") || 
      contactInfo?.phone1?.toString()?.startsWith("1242") || 
      contactInfo?.phone1?.toString()?.startsWith("242"))
    ){
       delete newErrorObject["phone1"];
    }

    if (this.state.contactFormError?.phone1 && this.state.contactInfo?.phone1) {
      newErrorObject["phone1"] = this.state.contactFormError?.phone1;
    }

    if( newErrorObject?.phone1  && this.state.contactInfo?.phone1?.toString()?.length>8
    && 
    this.state.contactInfo?.phone1?.toString()?.includes("+") &&
    this.state.contactInfo?.phone1?.toString()?.substr(1, 1) !== "1") {
  delete newErrorObject["phone1"];
    }

    
    if (contactInfo.status === "Inactive" && !contactInfo.terminationDate) {
      newErrorObject["terminationDate"] =
        "Please select valid Termination Date";
    } else {
      if (newErrorObject["terminationDate"]) {
        delete newErrorObject["terminationDate"];
      }
    }
    if (contactInfo.status === "Inactive" && !contactInfo.terminationReason) {
      newErrorObject["terminationReason"] = "Please enter Termination Reason";
    } else {
      if (newErrorObject["terminationReason"]) {
        delete newErrorObject["terminationReason"];
      }
    }

    if (
      (contactInfo?.primaryContact === "Phone" ||
        this.props?.userGeneralInfo?.hasSpecialPermissionUsers) &&
      !contactInfo.phone1
    ) {
      newErrorObject["phone1"] = "Missing Mobile Phone Number";
    }
    else if(contactInfo?.primaryContact === "Email" && !this.props?.userGeneralInfo?.hasSpecialPermissionUsers && !contactInfo.phone1){
      if (newErrorObject?.phone1) {
        delete newErrorObject.phone1;
      
      }
    } 
    else {
      if (newErrorObject?.phone1 === "Missing Mobile Phone Number") {
        delete newErrorObject.phone1;
      }
    }

    const { userGeneralInfo } = this.props;
    
      if(userGeneralInfo?.isLowSecurityUser ===1)
      {
      
       
        if(newErrorObject.phone1 != "Mobile Phone Number is not valid")
        {
          delete newErrorObject.phone1;
        }
        if(newErrorObject.email !="Email Address is not valid")
        {
          delete newErrorObject.email;
        }
       
      }
  

    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isContactFormValid: true });
    } else {
      this.setState({ isContactFormValid: false });
    }

    this.setState({ contactFormError: newErrorObject });

    return newErrorObject;
  };

  onContactFieldChange = (event, inputPosition, isBlur = false) => {
   
    const { timeOutFunc, contactInfo } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if(fieldName == "email"){
      value = value?.toString()?.trim();
    }
    if(fieldName === "noPhone" && value) {
      contactInfo.updateLater = false;
  
      this.setState({isPhoneOtpVerified: true});
    }
    if(fieldName === "updateLater"&& value ) {
      contactInfo.noPhone = false;
    
      this.setState({isPhoneOtpVerified: true});

    }

    if (fieldName === "firstName" || fieldName === "lastName") {
      value = value?.toString()?.replace(/[^A-Z\-\_\']+/gi, "");
      if (value) {
        if (value.indexOf("_") === 0 || value.indexOf("-") === 0 || value.indexOf("'") === 0) {
          value = value?.slice(0, 0);
        }
      }
    }

    if (fieldName === "phone1") {
      value = value?.toString()?.replace(/[^0-9\-\+]+/gi, "");
      if (value) {
        if (value.indexOf("-") >= 0) {
          value = value?.replaceAll("-", "");
        }
        if (value?.toString()?.length > 1) {
          value =
            value?.toString().substr(0, 1) +
            value?.toString()?.substr(1)?.replaceAll("+", "");
        }
      }
    }
    if (fieldName === "phone1" && value) {
      if (timeOutFunc) {
        clearTimeout(timeOutFunc);
      }
      this.setState({ isContactFormValid: false });
      const timeoutId = setTimeout(() => this.validatePhoneNo(value, 6), 500);
      this.setState({ timeOutFunc: timeoutId });
    }
    if (fieldName === "hireDate") {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        contactInfo?.hireDate,
        true,
        2,
        isBlur
      );
      if (_formattedDate === "invalid") {
        return;
      } else {
        value = _formattedDate;
      }
    }

    if (fieldName === "status" && value === "Active") {
      contactInfo["terminationDate"] = "";
      contactInfo["terminationReason"] = "";
    }



    contactInfo[fieldName] = value;

    this.setState({ inputPosition, contactInfo }, () => {
      if (
        (fieldName === "phone1" && !this.state?.contactInfo?.phone1) ||
        fieldName !== "phone1"
      ) {
        this.validateAndUpdateContactFormStatus();
      }
    });
  };

  getContactFieldError = (fieldName: string, fieldPosition: number) => {
    const { contactFormError, inputPosition } = this.state;
    return (
      <>
        {Object.keys(contactFormError).length > 0 &&
          fieldPosition <= inputPosition && (
            <span className="validation-message">
              {contactFormError[fieldName]}
            </span>
          )}
      </>
    );
  };

  handleDiscard = () => {
    this.props.getGeneralTabModificationStatus(false);
    this.props.getUserGeneralInfo();
    this.setState({ errorMessage: "" ,errorMessageForGoogle:""});

    this.setState({isSendOTPSubmitted :false,isOTPSubmitted:false});
  };

  validationOnContactClick = (inputPosition, fieldName = "") => {
    this.setState({ inputPosition });
    if (
      ((fieldName === "phone1" && !this.state?.contactInfo?.phone1) ||
        fieldName !== "phone1") || (fieldName === "phone1" && this.state?.contactInfo?.phone1?.toString()?.includes("+") &&
          this.state?.contactInfo?.phone1?.toString()?.substr(1, 1) !== "1")
    ) {
      this.validateAndUpdateContactFormStatus();
    }
  };

  updateGeneralInfo = () => {
    const { userGeneralInfo } = this.props;
    
    let {
      contactInfo,
      isEmailOtpVerified,
      isPhoneOtpVerified,
      primaryContact,
    } = this.state;
    const request: IValidateUserEmailAndPhone = {
      userUniqueno: userGeneralInfo?.uniqueno,
      username: userGeneralInfo?.userName,
      email: contactInfo.email,
      phone: contactInfo.phone1,
    } as IValidateUserEmailAndPhone;
    this.setState({ isDataSubmitting: true,isSpinner:true });
    
    if(userGeneralInfo?.isLowSecurityUser ===1 && !contactInfo?.email){
      this.setState({isEmailOtpVerified:true});
      isEmailOtpVerified = true;
    }
    if(userGeneralInfo?.isLowSecurityUser ===1 && !contactInfo?.phone1){
      this.setState({isPhoneOtpVerified:true});
      isPhoneOtpVerified = true;
    }
    if(!(userGeneralInfo?.hasSpecialPermissionUsers ||
      contactInfo.primaryContact == "Phone") && !request?.phone){
        if(!userGeneralInfo.isLowSecurityUser &&  contactInfo.noPhone == false && contactInfo.updateLater == false) {
        Utils.toastError("Please enter mobile phone number", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
        this.setState({ isDataSubmitting: false,isSubmitSpinner:false });
        return;
      }
    }

    if(this.props?.userGeneralInfo?.hasSpecialPermissionUsers && this.state.isgoogleAuth &&
        contactInfo.allow2FA &&  contactInfo.isTextMessage == false && contactInfo.isGoogleFA == false) {
        Utils.toastError("Please setup 2-Factor Authentication method", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
        this.setState({ isDataSubmitting: false,isSubmitSpinner:false });
        return;
      }
   if(this.props?.userGeneralInfo?.hasSpecialPermissionUsers && ( !this.state.isgoogleAuth
        || !contactInfo.allow2FA) &&  contactInfo.isTextMessage == false ) {
        Utils.toastError("Please setup 2-Factor Authentication method", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "generaltab1",
        });
        this.setState({ isDataSubmitting: false ,isSubmitSpinner:false});
        return;
      
    } 

    if(contactInfo.primaryMethod == "TextMsg" && !contactInfo.isTextMessage){
       
      Utils.toastError("Please enable Text Message", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
      this.setState({ isDataSubmitting: false ,isSubmitSpinner:false});
      return;
    
  }

  
  if(contactInfo.primaryMethod == "GoogleFA" && !contactInfo.isGoogleFA){
     
    Utils.toastError("Please enable Google", {
      position: toast.POSITION.BOTTOM_RIGHT,
      containerId: "userSlideOut",
    });
    this.setState({ isDataSubmitting: false,isSubmitSpinner:false });
    return;
  
}

//if( userGeneralInfo.isLowSecurity ===0) {
    UserManagement.ValidateIncompleteUser(request).then((result: any) => {
      if (result.result?.result === "FailEmail") {
        contactInfo["email"] = "";
        Utils.toastError(result?.result?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });

        this.setState({
          isOtpNeed: false,
          otpSuccess: false,
          inputPosition: 15,
          contactInfo,
          isEmailDuplicate: false,
          isDataSubmitting: false,
          isSubmitSpinner:false
        });

        return;
      }

      if (result.result?.result === "FailPhone") {
        contactInfo["phone1"] = "";
        Utils.toastError(result?.result?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
        this.setState({
          isOtpNeed: false,
          otpSuccess: false,
          inputPosition: 15,
          contactInfo,
          isPhoneDuplicate: false,
          isDataSubmitting: false,
          isSubmitSpinner:false
        });
        return;
      }

      if (
        contactInfo.phone1 &&
     
        isEmailOtpVerified &&
        !isPhoneOtpVerified
      ) {
        this.setState({ isOtpNeed: false, isDataSubmitting: false,isSubmitSpinner:false });
        if (primaryContact === "Email") {
          this.setState({ primaryContact: "Phone" }, () =>
            this.resendOTP("Email")
          );
        }
        if (primaryContact === "Phone") {
          this.resendOTP();
        }

        return;
      }

      if (
        (userGeneralInfo.email !== contactInfo.email && !isEmailOtpVerified) ||
        (
          isEmailOtpVerified &&
          !isPhoneOtpVerified)
      ) {
        this.setState({ isOtpNeed: false, isDataSubmitting: false,isSubmitSpinner:false });
        let primaryContact = isPhoneOtpVerified ? "Email" :  "Phone";

        if (
          (userGeneralInfo?.hasSpecialPermissionUsers ||
            contactInfo.primaryContact === "Phone") &&
          isEmailOtpVerified
        ) {
          primaryContact =  "Phone";
        }
        
        if (
          primaryContact === "Email" && 
          !contactInfo.email &&
          userGeneralInfo.email !== contactInfo.email
        ) {
          return;
        }

        if (
          primaryContact === "Phone" &&
          !contactInfo.phone1 &&
          userGeneralInfo.phone1 !== contactInfo.phone1
        ) {
          return;
        }
        if (primaryContact === "Email") {
          if (userGeneralInfo.phone1 === contactInfo.phone1) {
            this.setState({ isPhoneOtpVerified: true });
          }
          this.resendOTP("Email");
        }
        if (primaryContact === "Phone") {
          if (userGeneralInfo.email === contactInfo.email) {
            this.setState({ isEmailOtpVerified: true });
          }
          this.resendOTP();
        }

        this.setState({ primaryContact });
      } else {
        this.saveIncompleteUser();
      }
    });
  //} 
  // else {
  //   this.saveIncompleteUser();
  // }

}

  removeOptOutWithConfirmation = (phone) => {
    confirmAlert({
      title: "Remove Opt-Out",
      message: "Are you sure you would like to opt-in this user?",
      buttons: [
        {
          label: "Yes, Proceed",
          onClick: () => this.removeOptOut(phone),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  removeOptOut = (phone) => {
    UserManagement.RemoveOptOut(phone).then((result: any) => {
      if (result?.message === "success") {
        this.props.getUserGeneralInfo();
        toast.success("User Opted-In Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "labortabcontainer",
        });
      }
    });
  };

  closeOTPModal = (shouldUpdate = false, email ='', phone ='') => {
    const {contactInfo} = this.state;
    if(shouldUpdate) {
      if(email) {
        contactInfo.email = email;
      }
      if(phone) {
        contactInfo.phone1 = phone;
      }
      this.setState({contactInfo},()=> {
this.validateContactForm();
      });
    }
    this.setState({ openOTPModal: false });

    this.handleDiscard();
  };

  showHideSavebutton = (): boolean => {
   
    const { userGeneralInfo } = this.props;
    const { contactInfo,isContactFormValid, isContactFormUpdated,prevContactInfo  } = this.state;
    if(userGeneralInfo?.isLowSecurityUser ===0 && ((userGeneralInfo?.hasSpecialPermissionUsers ||
      contactInfo.primaryContact == "Phone")) && !contactInfo.phone1)
    {
        return false;
    }
    else if(isContactFormUpdated && (contactInfo.updateLater || contactInfo.noPhone)){
        return true;
    }

    const _isPrimaryMethodUpdated = !_.isEqual(contactInfo.primaryMethod, prevContactInfo.primaryMethod);
    const _isTextMsgUpdated = !_.isEqual(contactInfo.isTextMessage, prevContactInfo.isTextMessage);
    const _isGoogleAuthUpdated = !_.isEqual(contactInfo.isGoogleFA, prevContactInfo.isGoogleFA);

    if (_isPrimaryMethodUpdated || _isTextMsgUpdated || _isGoogleAuthUpdated) {
      return true;
    }
    
    
    if (isContactFormValid) {
      if (isContactFormUpdated && !isContactFormValid) {
        return false;
      } else if (isContactFormUpdated) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  closeOtpModal = () => {
    const { isOtpNeed } = this.state;
    this.setState({ isDataSubmitting: false });
    if (isOtpNeed) {
      this.setState({ isOtpNeed: false });
    }
  };

  validateOTP = (OTPCode) => {
    const { primaryContact, contactFormError } =
      this.state;

    this.setState({
      isOtpNeed: false,
      otpSuccess: true,
    });

    let isEmailOtpVerified = this.state?.isEmailOtpVerified || false;
    let isPhoneOtpVerified = this.state?.isPhoneOtpVerified || false;

    if (primaryContact === "Email") {
      isEmailOtpVerified = true;
      this.setState({ isEmailOtpVerified: true });
    } else {
      isPhoneOtpVerified = true;
      this.setState({ isPhoneOtpVerified: true });
    }

    let allOtpVerified = false;
    
      if (isPhoneOtpVerified && isEmailOtpVerified) {
        allOtpVerified = true;
      }
    

    if (allOtpVerified && Object.keys(contactFormError)?.length === 0) {
      this.saveIncompleteUser();
    } else if (
      !isEmailOtpVerified  &&
      Object.keys(contactFormError)?.length === 0
    ) {
      this.setState({ primaryContact: "Email" }, () => {
        this.resendOTP("Email");
      });
    }
  };

  resendOTP = (primaryContact = "Phone") => {
    const { userGeneralInfo, contactInfo, isEmailOtpVerified } = this.state;
    if (
      (userGeneralInfo?.hasSpecialPermissionUsers ||
        contactInfo.primaryContact === "Phone") &&
      isEmailOtpVerified
    ) {
      primaryContact = "Phone";
    }

    if (primaryContact === "Email" && !contactInfo.email) {
      return;
    }

    if (primaryContact === "Phone" && !contactInfo.phone1) {
      return;
    }
    //setprimaryContact(primaryContact);
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let userName = storage === null ? 0 : (storage.userName);
    let request: any = {};
    request.userName = userName;
    request.purpose = "Email Verification";
    request.otpCode = "";
    request.primaryContact = primaryContact;
    request.userEmail = contactInfo?.email;
    request.userPhone = contactInfo?.phone1;
    accountService
      .OTPForAuthentication(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.status === "Success") {
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });
            this.setState({ isOtpNeed: true });
          } else {
            toast.success("Something went wrong", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });
          }
        }
      });
  };

  saveIncompleteUser = () => {
    const { contactInfo } = this.state;
    const { userGeneralInfo } = this.props;
    const request: IIncompleteUserRequest = {
      userUniqueno: userGeneralInfo?.uniqueno,
      username: userGeneralInfo?.userName,
      email: contactInfo.email,
      phone: contactInfo.phone1,
      primaryContact: contactInfo.primaryContact,
      directDeposit: "",
      bankName: "",
      accountType: "",
      routingNumber: "",
      accountNumber: "",
      dDAmountType: "",
      dDAmount: "",
      bankName2: "",
      accountType2: "",
      routingNumber2: "",
      accountNumber2: "",
      dDAmountType2: "",
      dDAmount2: "",
      directDeposit2: "",
      primaryMethod: contactInfo.primaryMethod === "0"? "" :contactInfo.primaryMethod,
      isTextMessage: this.state.isTextMessage ? 1 : 0, 
      isGoogleFA : this.state.isGoogleFA ? 1 : 0
    } as IIncompleteUserRequest;

    if(contactInfo.noPhone) {
      request.phoneUpdateStatus = 'NoPhone';
    }
    if(contactInfo.updateLater) {
      request.phoneUpdateStatus = 'UpdateLater';
    }

    this.setState({ isDataSubmitting: true });
    UserManagement.SaveIncompleteUser(request)
      .then((result: any) => {
        this.setState({ isDataSubmitting: false });
        if (result?.result?.success) {
         

          this.props.getGeneralTabModificationStatus(false);
          toast.success(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
          this.setState({
            isContactFormUpdated: false,
            isEmailDuplicate: false,
            isPhoneDuplicate: false,
            isSubmitSpinner:false
          });
          justSaved=true;
          this.props.updatePhoneStatus( request.phoneUpdateStatus);
          this.props.getUserGeneralInfo();
        } else {
          if (result?.result?.message === "Email is duplicate") {
            contactInfo["email"] = "";
            this.setState({
              isOtpNeed: false,
              otpSuccess: false,
              isEmailOtpVerified: false,
              isEmailDuplicate: true,
              contactInfo,
              isSubmitSpinner:false
            });
          }
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSubmitting: false,isSubmitSpinner:false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      });
  };
   getOTP = (mode) => {
    // UserManagement.SendOTP(props?.uniqueno, mode)
    //   .then((result) => {
    //     if (result?.result?.success) {
    //     } else {
    //       Utils.toastError(result?.result?.message, {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //         containerId: "userSlideOut",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     Utils.toastError(error?.message, {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       containerId: "userSlideOut",
    //     });
    //   });
    if(this.props.userGeneralInfo?.hasSpecialPermissionUsers &&!this.state.contactInfo?.email && mode==="phone"){
    
      Utils.toastError("Please update Email Address in order to update Mobile Phone Number", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
      return;
    
  }


  if(this.props.userGeneralInfo?.hasSpecialPermissionUsers &&!this.state.contactInfo?.phone1 && mode==="email"){
    
    Utils.toastError("Please update Mobile Phone Number in order to update Email Address", {
      position: toast.POSITION.BOTTOM_RIGHT,
      containerId: "userSlideOut",
    });
    return;
  
}

    this.setState({mode},()=> {
      this.setState({openOTPModal: true});

    })
  
  };

   closeFAOTPModal = () => {
    this.setState({openOTPModal: false});
    this.handleDiscard();
  };

  onTextMessageChange = (event) => {
    if(event.target.checked){
       const { contactInfo  } = this.state;
      // contactInfo.isTextMessage =true;
      //     this.setState({contactInfo});
    if(contactInfo.phone1){
      this.setState({isTextMessage:true, isSendOTP:false,isOpenOTPModal:true,isSendOTPSubmitted :false,isValidOTP:false,isTextMessageDisabled:false});
    }
   
    }
    else{
      const { contactInfo } = this.state;
      if(this.props?.userGeneralInfo?.hasSpecialPermissionUsers
        && contactInfo.allow2FA && this.state.isgoogleAuth) {


      if(contactInfo.isGoogleFA === false){
       
      // contactInfo.isTextMessage =true;
      //     this.setState({contactInfo});
        this.setState({isTextMessageDisabled : true });
        this.setState({ isTextMessage: true});
    
      
      }
      else{
        if(contactInfo.isGoogleFA){
          contactInfo.primaryMethod = "GoogleFA";      
          this.setState({ contactInfo });
          this.setState({isTextMessageDisabled : false });
        }
       
        primaryMethods[0].disabled = true;
     
       contactInfo.isTextMessage =false;
           this.setState({contactInfo});
          this.setState({ isTextMessage: false, isSendOTP: false, isOpenOTPModal: false,isValidOTP:false,otp:""  });
        
      }
     
    }
    else{
      this.setState({isTextMessageDisabled : true });
      this.setState({ isTextMessage: true});
      
    }
    }
  };
  onGoogleChange = (event) => {
    if(event.target.checked){
      // const { contactInfo } = this.state;
      // contactInfo.isGoogleFA =true;
      //     this.setState({contactInfo});
    this.setState({isGoogleFA:true,isOpenCodeModal: true, isClosePanel: true,isValidCode:false,code:"",isGoogleDisabled : false});
    this.GenerateGoogleAuthKey();
    }
    else{
      const { contactInfo } = this.state;
     

      if(contactInfo.isTextMessage === false){
       
        // contactInfo.isGoogleFA =true;
        // this.setState({contactInfo});
        this.setState({isGoogleDisabled : true });
        this.setState({ isGoogleFA: true});
     
      }
      else {
        if(contactInfo.isTextMessage){
          contactInfo.primaryMethod = "TextMsg";      
          this.setState({ contactInfo,isGoogleDisabled:false });
        }

        primaryMethods[1].disabled = true;
       
        contactInfo.isGoogleFA =false;
            this.setState({contactInfo});
        this.setState({ isGoogleFA: false, isOpenCodeModal: false, isSendAuthKey: false,isValidCode:false,code:""  });
    }
      
    }
  };

  SendTextMsgOTP(mode ="") {
    this.setState({isSendOTPSubmitted :true});
    const {  contactInfo } = this.state;

    debugger;
    let newErrorObject: any = {};
    if (!contactInfo.phone1
    ) {
      newErrorObject["phone1"] = "Missing Mobile Phone Number";
      this.setState({ contactFormError: newErrorObject });
    }
    else {
      let request: any = {};
    request.otpID = 0;
    request.userName = this.props.userGeneralInfo?.userName;;
    request.userEmail = contactInfo?.email;
    request.userPhone = contactInfo?.phone1;
    request.purpose = "Text Message Verification - LockIn Profile";;
    request.otpCode = "";
    request.primaryContact = "phone";
    request.source = "3";
    request.HID = -1;
    request.otpMode = "sms";

    
    OTPService
       .SendPassCode(request)
       .then(async (result: any | null) => {
         if (result !== null) {
          debugger;
           if (result.status === "Success") {
             this.setState({isSendOTP:true});
             this.setState({ otpID: result.otpID });
             toast.success(result.message, {
               position: toast.POSITION.BOTTOM_RIGHT,
               containerId: "userSlideOut",
             });
             
           } else {
             this.setState({isSendOTP:false,isSendOTPSubmitted :false})
             
             toast.success("Something went wrong", {
               position: toast.POSITION.BOTTOM_RIGHT,
               containerId: "userSlideOut",
             });
           }
         }
       })
       .catch((error) => {
             this.setState({isSendOTP:false,isSendOTPSubmitted :false})
            
             Utils.toastError(error?.message, {
               position: toast.POSITION.BOTTOM_RIGHT,
               containerId: "userSlideOut",
             });
       });

      }
  
     };

  resendTextMsgcode(event: any) {
    this.setState({ reSendOtp: true });
      const {contactInfo } = this.state;
      let request: any = {};
      request.otpID = 0;
      request.userName = this.props.userGeneralInfo?.userName;;
      request.userEmail = contactInfo?.email;
      request.userPhone = contactInfo?.phone1;
      request.purpose = "Text Message Verification - LockIn Profile";;
      request.otpCode = "";
      request.primaryContact = "phone";
      request.source = "3";
      request.HID = -1;
      request.otpMode = "";
      OTPService
        .SendPassCode(request)
        .then(async (result: any | null) => {
          if (result !== null) {
            if (result.status === "Success") {
              this.setState({isSendOTP:true});
              this.setState({ otpID: result.otpID });
              toast.success(result.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "userSlideOut",
              });
              
            } else {
              this.setState({isSendOTP:false})
              toast.success("Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "userSlideOut",
              });
            }
          }
        })
        .catch((error) => {
              this.setState({isSendOTP:false})
              Utils.toastError(error?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "userSlideOut",
              });
        });

        setTimeout(() => {
          this.setState({ reSendOtp: false });
        }, 60000);
    };

  validateTextMsgOTP = () =>{
    this.setState({isOTPSubmitted :true,isSubmitSpinner:true});
      const {  contactInfo } = this.state;
 let errorMessage = "";
 
 if (this.state.otp === 0 || this.state.otp === "") {
  errorMessage = "Please enter passcode";
  this.setState({ errorMessage: errorMessage, isOTPSubmitted :false,isSubmitSpinner:false });
} 
else if (this.state.otp.length > 6) {
  errorMessage = "Please enter valid passcode";
  this.setState({ errorMessage: errorMessage, isOTPSubmitted :false ,isSubmitSpinner:false});
}
  else {
    this.setState({ loaderTrue: true });


    let request: any = {};
      request.otpID = this.state.otpID;
      request.userName = this.props.userGeneralInfo?.userName;
      request.userEmail = contactInfo?.email;
      request.userPhone = contactInfo?.phone1;
      request.purpose = "Text Message Verification - LockIn Profile";
      request.otpCode = this.state.otp;
      request.primaryContact = "phone";
      request.source = "";
      request.HID = -1;
      request.otpMode = "sms";

    
      OTPService.ValidatePassCode(request)
      .then(async (result: any | null) => {
       
        if (result.status !== "") {
          debugger;
          if (result.status === "InvalidPassCode") {
            
            errorMessage = "Incorrect passcode entered. Please try again";
            this.setState({isOTPSubmitted :false,isSubmitSpinner:false});
            // Utils.toastError(errorMessage, {
            //           position: toast.POSITION.BOTTOM_RIGHT,
            //           containerId: "userSlideOut",
            //         });
          } else if (result.status === "ExpiredPassCode") {
            
            errorMessage =
              "Passcode expired. Please click 'Resend Code' to send a new passcode";
              this.setState({isOTPSubmitted :false,isSubmitSpinner:false});
              // Utils.toastError(errorMessage, {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              //   containerId: "userSlideOut",
              // });
          } else if (result.status === "ValidPassCode") {
            this.setState({isValidOTP: true});
           this.setState({isSendOTP: true});
          // this.setState({isTextMessageVerified: true});
          const { contactInfo } = this.state;
              this.setState({isGoogleDisabled : false });
              if(this.state.isGoogleFA){
                contactInfo.primaryMethod = "GoogleFA";                
              }
              else {
                contactInfo.primaryMethod = "TextMsg"; 
              }
              contactInfo.isTextMessage=true;
              this.setState({ contactInfo });

          this.updateGeneralInfo();
           //this.updateAuthenticationInfo();
          //  toast.success("2-Factor Authentication Successfully Enabled", {
          //              position: toast.POSITION.BOTTOM_RIGHT,
          //              containerId: "userSlideOut",
          //              });

            primaryMethods[0].disabled = false;
          } 
          else {
            
            errorMessage = "Incorrect passcode entered. Please try again";
            this.setState({isOTPSubmitted :false,isSubmitSpinner:false});
            // Utils.toastError(errorMessage, {
            //           position: toast.POSITION.BOTTOM_RIGHT,
            //           containerId: "userSlideOut",
            //         });
          }
        }
        this.setState({ errorMessage: errorMessage });
      
      
      })
      .catch((error) => {
        this.setState({isValidOTP: false});
        this.setState({isSendOTP: true, isOTPSubmitted :false,isSubmitSpinner:false});
      
        Utils.toastError(error?.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });

      });
  }
  

    
    };

  GenerateGoogleAuthKey(){
  
         this.setState({ isSendAuthKey: false,isSpinner:true });
         let storage = JSON.parse(localStorage.getItem("storage")!);
         let tenantID = storage === null ? 0 : (storage.tenantID);
             let request = {} as  any;
             request.tenantId = tenantID;
             request.userName = this.state.userName;
        OTPService.GenerateGoogleAuthKey(request)
         .then(async (result: any | null) => {
           if (result != null) {
             let resobj={} as any;
             resobj.userName= result.userName;
             resobj.ImageUrl= result.imageUrl;
             resobj.Key= result.key;
             this.setState({ authKeyData: resobj,isSpinner: false });
           //  this.setState({ isSendAuthKey: true });
           }
          // resolve();
         })
         .catch((err) => {
          this.setState({isSendAuthKey:false,isSpinner: false})
           Utils.toastError(`Server Error, ${err}`);
        //   reject();
         });
  
    };
  
  validateGoogleCode = () =>{
       
        this.setState({ isEnabled: false,isGoogleCodeSubmitted:true,isSubmitSpinner:true });
        let errorMessageForGoogle = "";
        if (this.state.code === 0 || this.state.code === "" ) {  
          errorMessageForGoogle = "Please enter the confirmation code.";
          this.setState({ errorMessageForGoogle: errorMessageForGoogle,isGoogleCodeSubmitted:false,isSubmitSpinner:false });
        } 
        else if(this.state.code.length > 6)
        {
          errorMessageForGoogle = "Incorrect code entered. Please try again.";
          this.setState({ errorMessageForGoogle: errorMessageForGoogle,isGoogleCodeSubmitted:false,isSubmitSpinner:false });
        }
        else
        {
          let request: any = {};
          request.otpID = this.state.otpID;
          request.userName = this.state.userName;
          request.userEmail = "";
          request.userPhone = "";
          request.purpose = "Google Verification - LockIn Profile";
          request.otpCode = this.state.code;
          request.primaryContact = "";
          request.source = "";
          request.HID = -1;
          request.otpMode = "";

          OTPService.ValidateQRCode(request)
          .then((result) => {
            
            if (result != null) {
  
              if (result?.message === "ValidPassCode") {
                this.setState({isValidCode: true});
           
                this.setState({isSendAuthKey: true});
                const { contactInfo } = this.state;
                contactInfo.primaryMethod = "GoogleFA";
                contactInfo.isGoogleFA=true;
                this.setState({isTextMessageDisabled : false });
                this.setState({ contactInfo });

                this.updateGeneralInfo();
                //this.updateAuthenticationInfo();
               // primaryMethods[1].disabled = false;
                
              // toast.success("2-Factor Authentication Successfully Enabled", {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              //   });

            }else if (result?.message === "InvalidPassCode") {
              this.setState({isValidCode: false,isGoogleCodeSubmitted:false,isSubmitSpinner:false});
           
                this.setState({isSendAuthKey: true});
                errorMessageForGoogle = "Incorrect code entered. Please try again";
                // Utils.toastError(errorMessageForGoogle, {
                //      position: toast.POSITION.BOTTOM_RIGHT,
                //      });
                
            }
  
            this.setState({ errorMessageForGoogle: errorMessageForGoogle });
            
            } else {
              this.setState({isValidCode: false,isSubmitSpinner:false});
           
              this.setState({isSendAuthKey: true,isGoogleCodeSubmitted:false});
              // Utils.toastError("Please enter the confirmation code.", {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              // });
            }
          })
          .catch((error) => {
            this.setState({isValidCode: false,isSubmitSpinner:false});
           
            this.setState({isSendAuthKey: true,isGoogleCodeSubmitted:false});
            Utils.toastError(error?.message, {
             position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
        }
    };

    cancelTextMsgCard = () => {
      // const { contactInfo } = this.state;
      //   contactInfo.isTextMessage =false;
      //       this.setState({contactInfo});
      this.setState({ isTextMessage: false, isSendOTP: false });
      this.setState({ errorMessage: "" ,otp:"",isSendOTPSubmitted :false,isOTPSubmitted:false});
     
    };
  
    cancelGoogle = () => {
      // const { contactInfo } = this.state;
      // contactInfo.isGoogleFA =false;
      //     this.setState({contactInfo});
      this.setState({ isGoogleFA: false, isSendAuthKey: false });
      this.setState({ errorMessageForGoogle: "", code:"" ,isGoogleDisabled : false});
      this.setState({isGoogleCodeSubmitted:false});
    };
  

  onInputChangeForTextMsg = (value: number) => {
    let numbers = /^[0-9]+$/;
    let x= value.toString();
    if (isNaN(value) ||(value && !x.match(numbers))) {
      let errorMessage = "Invalid input, accept only numeric value";
      this.setState({ errorMessage: errorMessage });
    } else {
      if(value.toString().length <= 6){
      this.setState({ otp: value });
      this.setState({ errorMessage: "" });
      }
    }
  };

  onInputChangeForGoogle = (value: number) => {
    let numbers = /^[0-9]+$/;
    let x= value.toString();
    if (isNaN(value) || (value && !x.match(numbers))) {
      let errorMessageForGoogle = "Invalid input, accept only numeric value";
      this.setState({ errorMessageForGoogle: errorMessageForGoogle });
    } else {
      if(value.toString().length <= 6){
      this.setState({ code: value });
      this.setState({ errorMessageForGoogle: "" });
      }
    }
  };

  handleContinue() {
    this.setState({ isSendAuthKey: true });
  };

  updateAuthenticationInfo = () => {
    const { userGeneralInfo } = this.props;
    const {
      contactInfo,
    } = this.state;

    //this.setState({ isDataSubmitting: true });
    UserManagement.UpdateUserAuthenticationDetails(
      userGeneralInfo?.userName,
      userGeneralInfo?.uniqueno,
      this.state.isTextMessage ? 1 : 0,
      this.state.isGoogleFA ? 1 : 0,
      contactInfo.primaryMethod
    ).then((result: any) => {
      this.setState({ isDataSubmitting: false });
      if (result?.result?.success) {

        //this.props.getGeneralTabModificationStatus(false);
        this.props?.getUserGeneralInfo();
       // this.setState({ isClosePanel: true });
        // toast.success(result.result?.message, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        //   containerId: "userSlideOut",
        // });

        toast.success("2-Factor Authentication Successfully Enabled", {
          position: toast.POSITION.BOTTOM_RIGHT,
          });
      }
      else {
        Utils.toastError(result?.result?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      }
    })
      .catch((error) => {
        this.setState({ isDataSubmitting: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      });


  };

  GoogleAuthFeatureFlag = () => {


    OTPService.GoogleAuthFeatureFlag()
      .then(async (result: any | null) => {

        if (result?.result.length > 0) {
          let googleAuth = result?.result[0].googleAuth;
          if(googleAuth === "No"){
            primaryMethods.splice(1);
          }

          this.setState({
            isgoogleAuth: (googleAuth === "Yes")
          })
        }

        resolve();
      })
      .catch((error) => {

        reject();
      });
  }




  render() {
    const {  userGeneralInfo } = this.props;
    const {
      contactFormError,
      inputPosition,
      contactInfo,
      userName,
      isEmailDuplicate,
      isPhoneDuplicate,
      isDataSubmitting,
      isOtpNeed,
      primaryContact,
      openOTPModal,
      mode,
      isGoogleDisabled,
      isTextMessageDisabled,
      isSendOTPSubmitted,
      isOTPSubmitted,
      isGoogleCodeSubmitted
     
    } = this.state;

    // debugger;
    // let primarymethodlength = primaryMethods.filter(item => item.value == contactInfo.primaryMethod) ;

    // if(primarymethodlength.length == 0){
    //   contactInfo.primaryMethod =   primaryMethods[0].value;
    // }
                         
    return (
      <>
        <>
          {
            <Modal
              className="enter-code-modal email-phone-modal"
              show={openOTPModal}
              //onHide={(e) => this.hideRenameModal(e)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={this.closeFAOTPModal}
              keyboard={false}
            >
              <Modal.Header closeButton>
              <Modal.Title>{mode == "email" ? "Updated Email" : "Updated Mobile Phone Number"} </Modal.Title>
            </Modal.Header>
              <Modal.Body>
                <Container fluid className="login">
                  <div className="d-flex justify-content-center login-container flex-column forgot-password">
                    <Card className="align-self-center">
                      <OTPCodeModal
                        platform="web"
                        userNameToEdit={userName}
                        uniqueNo={this.props?.uniqueno}
                        otpMethod={mode}
                        updateGeneralInfo={this.updateGeneralInfo}
                        closeOTPModal={this.closeOTPModal}
                      />
                    </Card>
                  </div>
                </Container>
              </Modal.Body>
            </Modal>
          }
        </>
        <>
          <div className="general-tab">
            {
              <>

                <div className="body-section">
                  {this.state.isSubmitSpinner && (
                    <div className="loader-spinner d-flex justify-content-center align-items-center">
                      <Spinner animation="border" variant="success" />
                    </div>
                  )}
                  <div className="heading">User Information</div>
                  <div className="form-section d-flex flex-column">
                    <Form.Group
                      controlId="date-selection"
                      className={
                        contactFormError?.firstName && inputPosition >= 1
                          ? "validation-error d-flex flex-row"
                          : "d-flex flex-row"
                      }
                    >
                      <Form.Label
                        onBlur={() => this.validationOnContactClick(1)}
                      >
                        First Name
                      </Form.Label>
                      <div className="validate-input d-flex flex-column">
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"First-Name"} className={(Utils.CapitalizeFirstLetter(contactInfo?.firstName).length <= 26) ? "tootltip-hide": ""}>
                                {Utils.CapitalizeFirstLetter(contactInfo?.firstName)}
                            </Tooltip> 
                        }
                        >
                       <input
                          type="text"
                          className="form-control SOS1"
                          style={{ cursor: "not-allowed" }}
                          id="txtFirstName"
                          name="firstName"
                          autoComplete="firstName"
                          disabled={true}
                          value={Utils.CapitalizeFirstLetter(
                            contactInfo?.firstName
                          )}
                          onChange={(e: any) => this.onContactFieldChange(e, 1)}
                        // onBlur={(e) => getUsername()}
                        />
                    </OverlayTrigger>
                        
                        {this.getContactFieldError("firstName", 1)}
                      </div>
                    </Form.Group>
                    <Form.Group
                      onBlur={() => this.validationOnContactClick(2)}
                      controlId="date-selection"
                      className={
                        contactFormError?.lastName && inputPosition >= 2
                          ? "validation-error d-flex flex-row"
                          : "d-flex flex-row"
                      }
                    >
                      <Form.Label>Last Name</Form.Label>
                      <div className="validate-input d-flex flex-column">
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"Last-Name"} className={(Utils.CapitalizeFirstLetter(contactInfo?.lastName).length <= 26) ? "tootltip-hide": ""}>
                                {Utils.CapitalizeFirstLetter(contactInfo?.lastName)}
                            </Tooltip> 
                        }
                        >
                       <input
                          type="text"
                          className="form-control"
                          id="txtLastName"
                          name="lastName"
                          autoComplete="lastName"
                          disabled={true}
                          value={Utils.CapitalizeFirstLetter(
                            contactInfo?.lastName
                          )}
                          onChange={(e: any) => this.onContactFieldChange(e, 2)}
                        // onBlur={(e) => getUsername()}
                        />
                    </OverlayTrigger>
                       
                        {this.getContactFieldError("lastName", 2)}
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="d-flex flex-row"
                      controlId="date-selection"
                    >
                      <Form.Label>Username</Form.Label>
                      <div className="validate-input">
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"Last-Name"} className={(Utils.CapitalizeFirstLetter(userName).length <= 26) ? "tootltip-hide": ""}>
                                {Utils.CapitalizeFirstLetter(userName)}
                            </Tooltip> 
                        }
                        >
                       <input
                          type="text"
                          className="form-control"
                          id="txtUsername"
                          disabled={true}
                          name="txtUsername"
                          autoComplete="txtUsername"
                          value={userName}
                        />
                    </OverlayTrigger>
                      
                      </div>
                    </Form.Group>
                    <Form.Group
                      onBlur={() => this.validationOnContactClick(5)}
                      controlId="date-selection"
                      className={
                        contactFormError?.email && inputPosition >= 5
                          ? "validation-error d-flex flex-row"
                          : "d-flex flex-row"
                      }
                    >
                      <Form.Label>
                        Email
                        {isEmailDuplicate && (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={"tooltip-nickname"}>
                                This Email is already used by another user.
                                Please enter other Email.
                              </Tooltip>
                            }
                          >
                            <span
                              style={{
                                marginLeft: "4px",
                                display: "inline-block",
                              }}
                            >
                              <InfoTooltip />{" "}
                            </span>
                          </OverlayTrigger>
                        )}
                      </Form.Label>
                      <div className="validate-input d-flex flex-column">
                        {contactInfo?.email && (
                          <ReactTooltip
                            className={"overrideMe"}
                            data-html={true}
                            id="txtEmail"
                            effect="solid"
                            place="bottom"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            multiline={true}
                          >
                            {contactInfo?.email}
                          </ReactTooltip>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          id="txtEmail"
                          data-tip
                          data-for="txtEmail"
                          name="email"
                          disabled={this.props?.userGeneralInfo?.hasSpecialPermissionUsers}
                          autoComplete="email"
                          placeholder="Add Email Address"
                          value={contactInfo?.email}
                          onChange={(e: any) => this.onContactFieldChange(e, 5)}
                        />
  {userGeneralInfo?.hasSpecialPermissionUsers && (
                        <FontAwesomeIcon
                          onClick={() => this.getOTP("email")}
                          icon={faEdit}
                        />
                      )}
                        {this.getContactFieldError("email", 5)}
                      </div>
                    </Form.Group>
                    <Form.Group
                      controlId="date-selection"
                      className="d-flex flex-row"
                    >
                      <Form.Label>User ID</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtUserId"
                        disabled={true}
                        name="userUniqueID"
                        autoComplete="userUniqueID"
                        value={userGeneralInfo?.uniqueno}
                      />
                    </Form.Group>
                    <Form.Group
                      onBlur={() => this.validationOnContactClick(6, "phone1")}
                      controlId="date-selection"
                      className={
                        contactFormError?.phone1 && inputPosition >= 6
                          ? "validation-error d-flex flex-row"
                          : "d-flex flex-row"
                      }
                    >
                      <Form.Label>
                        Mobile Phone
                        {isPhoneDuplicate && (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={"tooltip-nickname"}>
                                This Mobile Phone is already used by another
                                user. Please enter other Mobile Phone.
                              </Tooltip>
                            }
                          >
                            <span
                              style={{
                                marginLeft: "4px",
                                display: "inline-block",
                              }}
                            >
                              {/* <InfoTooltipRed />{" "} */}
                              <InfoTooltip />{" "}
                            </span>
                          </OverlayTrigger>
                        )}
                      </Form.Label>
                      <div className="validate-input d-flex flex-column">
                        <input
                          type="text"
                          className="form-control"
                          id="txtPhoneNo"
                          name="phone1"
                          maxLength={16}
                          disabled={this.props?.userGeneralInfo?.hasSpecialPermissionUsers}
                          autoComplete="phone1"
                          placeholder="Add Mobile Phone Number"
                          value={contactInfo?.phone1}
                          onChange={(e: any) => {
                            this.onContactFieldChange(e, 6);
                          }}
                        />

                         {!userGeneralInfo.isLowSecurityUser && (!(userGeneralInfo?.hasSpecialPermissionUsers ||
                            contactInfo.primaryContact == "Phone")) && !contactInfo?.phone1
                          &&  <>
                        <Form.Check type="switch" id="custom-switch" label="No Phone"
                          checked={contactInfo?.noPhone}
                          style={{marginBottom: 12, marginTop: 8}}
                          onChange={(event: any) => {
                            const value = event.target.checked;
                            this.onContactFieldChange(
                              Utils.BuildCustomEventObject("noPhone", value),
                              6
                            );
                          }}
                        />
                       
                        <Form.Check type="switch" id="custom-switch-2" label="Update Later"
                          checked={contactInfo?.updateLater}

                          onChange={(event: any) => {
                            const value = event.target.checked;
                            this.onContactFieldChange(
                              Utils.BuildCustomEventObject("updateLater", value),
                              6
                            );
                          }}
                        />
                        </>
                         } 
                         {userGeneralInfo?.hasSpecialPermissionUsers && (
                        <FontAwesomeIcon
                          onClick={() => this.getOTP("phone")}
                          icon={faEdit}
                        />
                      )}
                        {this.getContactFieldError("phone1", 6)}
                      </div>
                      <div className="badge-sec d-flex align-items-center" style={{height: 35}}>
                        {userGeneralInfo.phoneStatus === "optout" && (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={"tooltip-nickname"}>
                                This user has opted-out and will no longer
                                receive text messages
                              </Tooltip>
                            }
                          >
                            <span
                              onClick={() =>
                                this.removeOptOutWithConfirmation(
                                  contactInfo?.phone1
                                )
                              }
                              style={{ cursor: "pointer" }}
                              className="optout-badge badge color-orange"
                            >
                              Opted-Out
                            </span>
                          </OverlayTrigger>
                        )}
                        {userGeneralInfo.phoneStatus === "blocked" && (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={"tooltip-nickname"}>
                                {userGeneralInfo?.blockedPhone} has been blocked
                                due to too many send failures
                              </Tooltip>
                            }
                          >
                            <span
                              style={{ cursor: "default" }}
                              className="blocked-badge badge color-red"
                            >
                              Blocked
                            </span>
                          </OverlayTrigger>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group
                      controlId="date-selection"
                      className={"d-flex flex-row"}
                    >
                      <Form.Label>Primary Contact</Form.Label>
                      {primaryContacts?.length > 0 && (
                        <DropDownList
                          placeHolder={"Select Primary Contact"}
                          data={primaryContacts}
                          defaultValue={contactInfo.primaryContact}
                          isSearchRequired={false}
                          label={"label"}
                          value={"value"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onContactFieldChange(
                              Utils.BuildCustomEventObject(
                                "primaryContact",
                                item.value
                              ),
                              18
                            );
                          }}
                          selectedItem={[
                            ...[{ value: "", label: "Select Primary Contact" }],
                            ...primaryContacts,
                          ].find(
                            (r: any) => r?.value === contactInfo.primaryContact
                          )}
                        />
                      )}
                      
                    </Form.Group>
                  </div>  
                  </div>
                  <div className="separator-line"></div>
             
                  {this.state.allow2FA && 
                  <div className="body-section">
                
                <div className="heading">
                <div className={ this.props?.userGeneralInfo?.hasSpecialPermissionUsers && !contactInfo.isTextMessage && !contactInfo.isGoogleFA ? "pips" : ""}>
                  <div className="pip light-red"></div></div>
                  2-Factor Authentication </div>
                <div className="form-section d-flex flex-column mb-3">
                    <>
                    <Form.Group
                      controlId="date-selection"
                      className="d-flex flex-row"
                    >
                      <Form.Label
                      
                      >
                        Text Message
                        <OverlayTrigger
                            placement="bottom"
                            overlay={this.renderTextMessageTooltip}
                          >
                            {this.renderInfoIcon()}
                          </OverlayTrigger>
                      </Form.Label>
                      <div className="validate-input d-flex flex-column">
                      {isTextMessageDisabled && ( 
                          <ReactTooltip
                            className={"overrideMe"}
                            data-html={true}
                            id="ChkIsTextMessage"
                            effect="solid"
                            place="bottom"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            multiline={true}
                          >
                           <span>  Due to your role, you can not disable 2-Factor Authentication.
                            <br/>
                            Setup secondary...</span>
                          </ReactTooltip>
                        )} 
                    <Form.Check
                        type="switch"
                        id="ChkIsTextMessage"
                        label=""
                        name="ChkTextMessage"
                        checked={this.state.isTextMessage}
                       // disabled={isTextMessageDisabled}
                       data-tip
                          data-for="ChkIsTextMessage"
                        onChange={(event: any) => {
                          this.onTextMessageChange(event);
                        }}
                      />
                      </div>
                </Form.Group> 
                {this.state.isTextMessage && !this.state.isSendOTP && this.state.isOpenOTPModal && (
                      <>
                        <div className="setup-text-message">
                          <div className="section-header">
                            <div className="title">Setup Text Message</div>
                            <span className="description">
                              In order to enable text messages for 2-factor authentication, we need to send a code to your phone number for verification.
                            </span>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-footer">
                            <Button
                              variant="primary"
                              type="button"
                              className="btn-ghost"
                              onClick={() => this.cancelTextMsgCard()}
                            >
                              Cancel
                            </Button>
                            <Button disabled={isSendOTPSubmitted} onClick={() => this.SendTextMsgOTP()} variant="primary" type="button">
                              Send Code
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                 {this.state.isTextMessage && this.state.isSendOTP && !this.state.isValidOTP && (
                      <>
                        <div className="setup-text-message">
                          <div className="section-header">
                            <div className="title">Setup Text Message</div>
                            <span className="description">Enter the code you received below:</span>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-body">

                            <Form.Group
                              controlId="date-selection"
                              className={
                                (this.state.errorMessage !== "" && (!this.state.isValidOTP || this.state.otp.length < 6))
                                  ? "validation-error d-flex flex-column"
                                  : "d-flex flex-column"
                              }
                            //className="validation-error" // invalid entry class apply condition to add/remove this class   
                            >
                              <Form.Label>Validation Code</Form.Label>
                              <div className="validate-input">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtOtp"
                                  name="otp"
                                  autoComplete="otp"
                                  placeholder="Enter the Code you received"
                                  value={this.state.otp}

                                  //  onBlur={(e: any) => this.validate(e, "phone")}
                                  onChange={(e: any) => this.onInputChangeForTextMsg(e.target.value)}
                                />
                                <span className="validation-message">
                                  {this.state.errorMessage}
                                </span>
                              </div>

                              <div className="receive-textcode">
                                Didn't receive the code ?{" "}
                                <span
                                  className={!this.state.reSendOtp ? "enableOtp" : "disabledOtp"}
                                  onClick={this.resendTextMsgcode}
                                >Resend Code</span>
                              </div>
                            </Form.Group>

                          </div>
                          <div className="separator-line"></div>
                          <div className="section-footer">
                            <Button
                              variant="primary"
                              type="button"
                              className="btn-ghost"
                              onClick={() => this.cancelTextMsgCard()}
                            >
                              Cancel
                            </Button>
                            {
                              this.state.isSendOTP  &&
                              <Button  disabled={isOTPSubmitted} onClick={() => this.validateTextMsgOTP()} variant="primary" type="button" >
                                Submit
                              </Button>
                            }
                          </div>
                        </div>
                      </>
                    )}
                    </>
                    <>
                {this.state.isgoogleAuth && (<Form.Group
              controlId="date-selection"
              className="d-flex flex-row"
            >
              <Form.Label
              >
                Google
                <OverlayTrigger
                            placement="bottom"
                            overlay={this.renderGoogleTooltip}
                          >
                            {this.renderInfoIcon()}
                          </OverlayTrigger>
              </Form.Label>
              <div className="validate-input d-flex flex-column">
              {isGoogleDisabled && ( 
                          <ReactTooltip
                            className={"overrideMe"}
                            data-html={true}
                            id="ChkIsGoogle"
                            effect="solid"
                            place="bottom"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            multiline={true}
                          >
                           <span>  Due to your role, you can not disable 2-Factor Authentication.
                            <br/>
                            Setup secondary...</span>
                          </ReactTooltip>
                        )} 
                        <Form.Check
                          type="switch"
                          id="ChkIsGoogle"
                          label=""
                          name="ChkGoogle"
                          checked={this.state.isGoogleFA}
                          data-tip
                          data-for="ChkIsGoogle"
                          //   disabled={!(this.props?.isEditSalesInfo || this.props?.isNewUserAdded)}
                          onChange={(event: any) => {
                            this.onGoogleChange(event);
                          }}
                      />
                    
              </div>
            </Form.Group>
            )}
            {this.state.isGoogleFA && !this.state.isSendAuthKey && this.state.isOpenCodeModal && (
                      <>
                        <div className="setup-google-auth-section">
                          <div className="section-header">
                            <div className="title">Setup Google Authenticator</div>

                            <div className="description">
                              In order to enable Google authentication, use your Google authentication app to scan this QR code :
                            </div>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-body d-flex flex-row align-items-center">
                            <div className="bar-code">
                              <img
                                src={this.state.authKeyData.ImageUrl}
                                className={"default"}
                                width="230"
                                height="230"
                              //onError={(e) => (e.currentTarget.src = profile)}
                              />
                            </div>
                            <div className="optional-code">
                              <div className="text"> Or enter this code into your authentication app:</div>
                              <div className="authentication-code">{this.state.authKeyData.Key}</div>
                              {/* <div className="authentication-code">XNCG FPRO REP3 KV5H</div> */}
                            </div>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-footer">
                            <Button
                              variant="primary"
                              type="button"
                              className="btn-ghost"
                              onClick={() => this.cancelGoogle()}
                            >
                              Cancel
                            </Button>

                            <Button onClick={() => this.handleContinue()} variant="primary" type="button">
                              Continue
                            </Button>


                          </div>
                            {this.state.isSpinner && (
                              <div className="loader-spinner d-flex justify-content-center align-items-center">
                                <Spinner animation="border" variant="success" />
                              </div>
                            )}
                        </div>
                      </>
                    )}

                    {this.state.isGoogleFA && this.state.isSendAuthKey && !this.state.isValidCode && (
                      <>
                        <div className="setup-google-auth-section">
                          <div className="section-header">
                            <div className="title">Setup Google Authenticator</div>
                            <div className="description">Enter the confirmation code you see on your Google Authenticator app:</div>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-body">
                            <Form.Group
                              controlId="date-selection"
                              className={
                                (this.state.errorMessageForGoogle !== "" && (!this.state.isValidCode || this.state.code.length < 6))
                                  ? "validation-error d-flex flex-column"
                                  : "d-flex flex-column"
                              }
                            //className="validation-error" // invalid entry class apply condition to add/remove this class
                            >
                              <Form.Label>Confirmation Code</Form.Label>
                              <div className="validate-input">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtcode"
                                  name="code"
                                  autoComplete="code"
                                  placeholder="Enter the Code you received"
                                  value={this.state.code}

                                  //  onBlur={(e: any) => this.validate(e, "phone")}
                                  onChange={(e: any) => this.onInputChangeForGoogle(e.target.value)}
                                />
                                <span className="validation-message">
                                  {this.state.errorMessageForGoogle}
                                </span>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-footer">
                            <Button
                              variant="primary"
                              type="button"
                              className="btn-ghost"
                              onClick={() => this.cancelGoogle()}
                            >
                              Cancel
                            </Button>
                            <Button disabled={isGoogleCodeSubmitted}  onClick={() => this.validateGoogleCode()} variant="primary" type="button" >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    </>
                    <Form.Group
                      controlId="date-selection"
                      className={"d-flex flex-row"}
                    >
                      <Form.Label>Primary Method</Form.Label>
                      {primaryMethods?.length > 0 && (
                        <DropDownListWithDescription
                          placeHolder={"Select Primary Method"}
                          data={primaryMethods}
                          defaultValue={contactInfo.primaryMethod}
                          isSearchRequired={false}
                          label={"label"}
                          value={"value"}
                          showBlueBg={true}
                          shouldToggle={false}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onContactFieldChange(
                              Utils.BuildCustomEventObject(
                                "primaryMethod",
                                item.value
                              ),
                              5
                            );
                          }}
                          selectedItem={[
                            ...[{ value: "0", label: "Select Primary Method" }],
                            ...primaryMethods,
                          ].find(
                            (r: any) => r?.value === contactInfo.primaryMethod
                          )}
                        />
                       )} 
                      </Form.Group>
                   </div>
                   <div className="separator-line"></div>
                </div> 
      
                
              }
                </> 
            }
          </div>
          <>
            {(this.showHideSavebutton() ||  ((userGeneralInfo.isDuplicatePhone ||
  userGeneralInfo.isDuplicateEmail) && userGeneralInfo?.isLowSecurityUser===1)) &&
              <div className="fixed-action ml-auto">
                <div className="d-flex align-content-center flex-wrap justify-content-end">

                  <div className="mr-auto message">
                    <span>You have unsaved changes</span>
                  </div>
                  <button
                    disabled={isDataSubmitting}
                    type="button"
                    className="btn btn-primary btn-discard"
                    onClick={this.handleDiscard}
                  >
                    Discard
                  </button>

                  <button
                    disabled={isDataSubmitting}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.updateGeneralInfo()}
                  >
                    Save
                  </button>
                </div>
              </div>
            }
          </>
        </>
        {isOtpNeed && (
          <OtpModal
            closePopup={this.closeOtpModal}
            ValidateOTP={this.validateOTP}
            ReSendOTP={this.resendOTP}
            primaryContact={primaryContact}
            purpose={"Email Verification"}
            userEmail={userGeneralInfo.email}
            userPhone={userGeneralInfo.phone1}
          ></OtpModal>
        )}
      </>
    );
  }
}
