import Instense from "./Axios-config";
import {
  IForecastRequestDto,
  IForecastResponseDto,
  IForecastSaveDto,
  IForecastTemplateRequestDto,
  IForecastTemplateResponseDto,
  IForecastImportTemplateRequestDto,
  IForecastImportTemplateResponseDto,
  IForecastTempalteRequestDto,
  IForecastTempalteResponseDto,
  IScheduleForecastTempalteRequestDto,
  ILaborImportScheduleRequestDto,
  ILaborImportScheduleTemplateRequestDto,
  IExitsLaborEmpScheduleRequestDto,
  IUnapproveResponseDto,
} from "../Contracts/ILaborForecast";

export class LaborForecast {
  public static GetLaborForecastSummary = async (
    request: IForecastRequestDto
  ): Promise<IForecastResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborForecast/GetLaborForecastSummary`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IForecastResponseDto;
      return result;
    });
  };

  public static ForecastSave = async (
    request: IForecastSaveDto
  ): Promise<any | null> => {
    const url = `/LaborForecast/SaveLaborForecastDetails`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static DeleteNewTemplete = async (
    request: IForecastSaveDto
  ): Promise<any | null> => {
    const url = `/LaborForecast/DeleteForcastTemplete`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };



  public static GetLaborForecastTemplateData = async (
    request: IForecastTemplateRequestDto
  ): Promise<IForecastTemplateResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborForecast/GetLaborForecastTamplate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IForecastTemplateResponseDto;
      return result;
    });
  };


  public static GetForecastNewTemplete = async (
    request: IForecastRequestDto
  ): Promise<IForecastResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborForecast/GetForecastNewTemplete`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IForecastResponseDto;
      return result;
    });
  };



  public static GetLaborForecastImportTemplateData = async (
    request: IForecastImportTemplateRequestDto
  ): Promise<IForecastImportTemplateResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/LaborForecast/GetLaborForecastImportTamplate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IForecastImportTemplateResponseDto;
      return result;
    });
  };

  public static ImportForecastTemplate = async (
    request: IForecastTempalteRequestDto
  ): Promise<IForecastTempalteResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let loginUser = storage === null ? 0 : (storage.userName as any);
    request.tenantID = tenantID;
    request.LoginUser = loginUser;
    const url = `/LaborForecast/ImportLaborForecastDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as IForecastTempalteResponseDto;
      return result;
    });
  };



  public static ImportNewForecastTemplate = async (
    request: IForecastTempalteRequestDto
  ): Promise<IForecastTempalteResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let loginUser = storage === null ? 0 : (storage.userName as any);
    request.tenantID = tenantID;
    request.LoginUser = loginUser;
    const url = `/LaborForecast/ImportLaborForecastTemplateDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as IForecastTempalteResponseDto;
      return result;
    });
  };


  public static ImportSchudleForecastTemplate = async (
    request: IScheduleForecastTempalteRequestDto
  ): Promise<IForecastTempalteResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let loginUser = storage === null ? 0 : (storage.userName as any);
    request.tenantID = tenantID;
    request.LoginUser = loginUser;
    const url = `/LaborSchedule/Get_ImportLaborForecastFromSchedule`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as IForecastTempalteResponseDto;
      return result;
    });
  };

  public static LaborImportSchedule = async (
    request: ILaborImportScheduleRequestDto
  ): Promise<IForecastTempalteResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let loginUser = storage === null ? 0 : (storage.userName as any);
    request.tenantID = tenantID;
    request.LoginUser = loginUser;
    const url = `/LaborSchedule/Get_LaborImportSchedule`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as IForecastTempalteResponseDto;
      return result;
    });
  };

  public static LaborImportScheduleTemplate = async (
    request: ILaborImportScheduleTemplateRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let loginUser = storage === null ? 0 : (storage.userName as any);
    request.tenantID = tenantID;
    request.LoginUser = loginUser;
    const url = `/LaborSchedule/Get_LaborImportScheduleTemplate`;
    return Instense.get(url, { params: request }).then((response) => {
      
      let result = response.data as any;
      return result;
    });
  };

  public static ExitsLaborEmpScheduleDetails = async (
    request: IExitsLaborEmpScheduleRequestDto
  ): Promise<IForecastTempalteResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;

    const url = `/LaborSchedule/IsExitsEmpSchdule`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as IForecastTempalteResponseDto;
      return result;
    });
  };
  public static GetLaborUnapproveDetails = async (
    request: any
  ): Promise<IUnapproveResponseDto | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.tenantID = tenantID;
    request.LoginUserRoleID = rolId;

    const url = `/Department/GetApproveDepartmentListByDate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as IUnapproveResponseDto;
      return result;
    });
  };

  public static updateDepartmentUnapprove = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;

    const url = `/Department/UpdateDepartmentApproveStatus`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
}
