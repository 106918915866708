import React from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Utils } from "../../../Common/Utilis";
import moment from "moment";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { Hotel } from "../../../Common/Services/Hotel";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { IRoomTypeNew } from "../../../Common/Contracts/IRoomType";

export class ChangeCategory extends React.Component<any, any> {


  state: any = {
     categoryId: this.props?.categoryId|| 0,
     groupId: this.props?.groupId|| 0
  };

  componentDidMount = () => {

  };

  onFieldChange = (categoryId) => {
    this.setState({categoryId});
  };

 addCategory = ()=> {
    this.props.handleCategoryEdit(  this.state.categoryId , this.props?.categoryId, this.props?.groupId);
    this.props.handleHideCategoryModal();
 }

 hideCategory = ()=> {
    this.props.handleHideCategoryModal();
 }



  render() {
    const {
      categoryId
    } = this.state;

    const { showChangeCategoryModal, categories } = this.props;
    return (
      <>
        <div className="">
          <ToastContainer containerId={"addDeduction"} autoClose={3000} />

          <Modal
            className="add-room-type-modal"
            show={showChangeCategoryModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.hideCategory}
          >
            <Modal.Header closeButton>
              <Modal.Title>
             Change Category
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                <Form.Group 
                      controlId="groupName" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0}`}
                    >
                      <Form.Label>Categories</Form.Label>
                      <DropDownList
                          placeHolder={"Select Category"}
                          data={categories}
                          defaultValue={categoryId}
                          isSearchRequired={true}
                          label={"label"}
                          value={"value"}
                          // disabled={
                          //   !userGeneralInfo?.hasEditContactInfo &&
                          //   !isNewUserAdded
                          // }
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange( item.value);
                          }}
                          selectedItem={
                            [
                              ...[{ value: 0, label: "Select Category" }],
                              ...categories,
                            ].find((r: any) => r?.value === +categoryId) || {
                              value: 0,
                              label: "Select Category",
                            }
                          }
                        />
                    </Col>
                  </Form.Group>
                 
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-ghost"
                onClick={() => this.hideCategory()}
              >
                Cancel
              </Button>
              <Button
                disabled={categoryId<0}
                className="btn btn-primary"
                onClick={this.addCategory}
              >
             { "Change"}   
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
