import * as React from "react";
import {
  Form,
  Row,
  // Col,
  Dropdown,
  // OverlayTrigger,
  // Tooltip,
  Button,
  // Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "react-input-range/lib/css/index.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MultiSelectSearch } from "../../../Common/Components/MultiSelectSearch";
import { confirmAlert } from "react-confirm-alert";
import { SingleSelectSearchWithImage } from "../../../Common/Components/SingleSelectSearchWithImage";
import { User } from "../../../Common/Services/User";
import { DeleteIcon } from "../../../Common/Components/delete";
import { Roles } from "../../../Common/Services/RolePermissionSetting";
import { IRolePermissionResponseDto } from "../../../Common/Contracts/IRolePermission";
import { ModuleReports } from "../../../Common/Services/ModuleReports";
import OutsideClickHandler from "react-outside-click-handler";
import { InfoTooltip } from "../../../Common/Components/InfoTooltip";
import RouteLeavingGuard from "../../../Common/Components/RouteLeavingGuard";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { BusinessAccountSelector } from "../BusinessAccountSelector";
import { ReportParameterSelectionForSchedule } from "../ReportParameterSelectionForSchedule";
import { IReportParameter } from "../../../Common/Contracts/IReportParameter";
import { ReportGroupSelector } from "../ReportGroupSelector";
import { AllHotelBrandSelector } from "../../../Common/Services/ReportGroup";
import _ from "lodash";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ReportGroupBrandSelector } from "../ReportGroupBrandSelector";
import { DataSourceSelector } from "../DataSourceSelector";
import { DataSource2Selectore } from "../DataSource2Selectore";
import moment from "moment";
import { isThisTypeNode } from "typescript";
import { Utils } from "../../../Common/Utilis";
let routeLeavingGuard: any = null;
// import $ from 'jquery';

// const autocompleteData: any[] = [];
var NewTimePeriods = [
  { label: "Last 7 Days", value: "Last 7 Days" },
  { label: "Last 14 Days", value: "Last 14 Days" },
  { label: "Last Pay Period", value: "Last Pay Period" },
  { label: "Last 30 Days", value: "Last 30 Days" },
  { label: "Last 90 Days", value: "Last 90 Days" },
  { label: "Last 180 Days", value: "Last 180 Days" },
  { label: "Last 360 Days", value: "Last 360 Days" },
];

let ValidationMessage = "";
const reportParamRef = React.createRef() as any;
export class ScheduleReportRightPanel extends React.Component<any, any> {
  private multiselectChild: any;
  private firstSingleSelect: any;
  private buisnessAccountRef: any;

  constructor(props: any) {
    super(props);
    this.multiselectChild = React.createRef();
    this.firstSingleSelect = React.createRef();
    this.buisnessAccountRef = React.createRef();
    this.state = {
      loaderTrue: false,
      isRPanelHide: false,
      isDataChanged: false,
      isActionFooter: false,
      allemployee: [],
      userRoles: [],
      selectedEmployees: [],
      selectedRoles: [],
      roleIds: [],
      AllowScheduleEdit: props.allowScheduleEdit,
      AllowScheduleView: props.allowScheduleEdit,
      policy: ["Restricted", "Unrestricted"],
      UniqueNo: 0,
      Version: "",
      moduleReportId: 0,
      uniqueReportId: 0,
      useNewPeriod: null,
      newReportName: "",
      ReportName: "",
      ReportType: "",
      FirstRunDate: "MM/DD/YY",
      ScheduleType: "Select",
      Period: "Select",
      DistributionPolicy: "Select",
      Parameters: "",
      ValidationMessage: "",
      showdeleteConfirmation: false,
      showDuplicateConfirmation: false,
      isduplicate: false,
      initalRow: {},
      discardMode: "",
      disableDelete: false,
      showMultiHidSelector: false,
      subGroupSelectValue: "Select Group",
      groupName: "",
      HotelBrand: "",
      HotelType: "",
      Hoteldescription: "",
      showGroupSelector: false,
      selectedHIDs: "",
      reportData: {},
      key: 0,
      AnniversaryDate: "Select",
      callParamValidation: false,
      SevenDayTrailing: null,
      showMultiGroupSelector: false,
      isHideStatistics: false,
      isZeroActualValues: false,
      isCheckCOA: false,
      IsdataSoureSelect: "",
      IsdataSoureSelect2: "",
      subGroupSelectedList: [],
    };
  }

  updateData = (selectedRow) => {
    debugger;
    if (selectedRow.parameters) {
      localStorage.removeItem("schedulereportParameters");
      let currentReportParameters = JSON.parse(
        JSON.stringify(selectedRow.parameters)
      );
      currentReportParameters = JSON.parse(currentReportParameters);
      const jsonReportParameters = JSON.stringify(currentReportParameters);
      localStorage.setItem("schedulereportParameters", jsonReportParameters);
      let ChkIsCompSet = currentReportParameters.find(
        (x) => x.name == "ChkIsCompSet"
      );
      let hotelGroupobj = currentReportParameters.find(
        (x) => x.name == "GroupName"
      );
      let hotelBrandobj = currentReportParameters.find(
        (x) => x.name == "HotelBrand"
      );
      let Hotelids = currentReportParameters.find((x) => x.name == "Hotelid");
      let documentType = currentReportParameters.find(
        (x) => x.name == "DocumentType"
      );
      let ChkHideZeroActualValues = currentReportParameters.find(
        (x) => x.name == "ChkHideZeroActualValues"
      );
      let ChkCheckCOA = currentReportParameters.find(
        (x) => x.name == "ChkCheckCOA"
      );
      let ChkHideStatistics = currentReportParameters.find(
        (x) => x.name == "ChkHideStatistics"
      );
      let DataSource1 = currentReportParameters.find(
        (x) => x.name == "DataSource1"
      );
      let DataSource2 = currentReportParameters.find(
        (x) => x.name == "DataSource2"
      );
      let _useNewPeriod = currentReportParameters.find(
        (x) => x.name == "useNewPeriod"
      );
      this.setState(
        {
          selectedHIDs: Hotelids?.value?.toString(),
        },
        () => this.getEmployeeData()
      );
      if (hotelGroupobj && hotelBrandobj) {
        this.setState({
          subGroupSelectValue:
            hotelGroupobj?.value == "All" ? "All" : hotelBrandobj?.value,
        });
      }

      if (ChkIsCompSet) {
        this.setState({
          isCompSetValue: ChkIsCompSet?.value === "Yes" ? true : false,
        });
      }

      if (hotelBrandobj) {
        this.setState({
          groupName: hotelBrandobj?.value ? hotelBrandobj?.value : "All",
          HotelBrand: hotelBrandobj?.value,
        });
      }
      if (documentType) {
        this.setState({
          AnniversaryDate:
            documentType?.value == "1" ? "This Month" : "Next Month",
          SevenDayTrailing: documentType?.value == "Yes" ? true : false,
        });
      }

      if (ChkHideZeroActualValues) {
        this.setState({
          isZeroActualValues:
            ChkHideZeroActualValues?.value === "Yes" ? true : false,
        });
      }

      if (ChkCheckCOA) {
        this.setState({
          isCheckCOA: ChkCheckCOA?.value === "Yes" ? true : false,
        });
      }

      if (ChkHideStatistics) {
        this.setState({
          isHideStatistics: ChkHideStatistics?.value === "Yes" ? true : false,
        });
      }

      if (DataSource1) {
        this.setState({
          IsdataSoureSelect: DataSource1?.value,
        });
      }

      if (DataSource2) {
        this.setState({
          IsdataSoureSelect2: DataSource2?.value,
        });
      }

      if (_useNewPeriod) {
        this.setState({
          useNewPeriod: _useNewPeriod?.value,
        });
      }
    }

    this.setState({
      loaderTrue: false,
      initalRow: selectedRow,
      isDataChanged: selectedRow.uniqueNo > 0 ? false : true,
      isActionFooter: selectedRow.uniqueNo > 0 ? false : true,
      UniqueNo: selectedRow.uniqueNo ? selectedRow.uniqueNo : 0,
      Version: selectedRow.version,
      key: this.state.key + 1,
      uniqueReportId: selectedRow.uniqueReportId, //---- Report static/unchangeable Id -----------
      moduleReportId: selectedRow.reportId,
      newReportName: selectedRow.description,
      ReportName: selectedRow.reportName ? selectedRow.reportName : "",
      ReportType: selectedRow.reportType,
      FirstRunDate: selectedRow.firstDate ? selectedRow.firstDate : "",
      ScheduleType:
        selectedRow.sechdule != null ? selectedRow.sechdule : "Select",
      Period: selectedRow.period != null ? selectedRow.period : "Select",
      DistributionPolicy:
        selectedRow.mailType != null
          ? selectedRow.mailType === "All"
            ? "Unrestricted"
            : "Restricted"
          : "Select",
      Parameters: selectedRow.parameters ? selectedRow.parameters : "",
      roleIds: selectedRow?.roleIds?.length > 0 ? selectedRow.roleIds : [],
    });

    let selectedRoles = [] as any;
    this.state.userRoles.map((res, index) => {
      let selectedRole = selectedRow?.roleIds?.filter((x) => x === res.roleID);
      if (selectedRole && selectedRole.length > 0) {
        let newItem = {} as any;
        newItem.roleID = res.roleID;
        newItem.value = res.description;
        newItem.isChecked = true;
        selectedRoles.push(newItem);
      }
    });

    this.setState({ selectedRoles });
    if (this.multiselectChild && this.multiselectChild.current) {
      this.multiselectChild.current.updateSavedItem(selectedRoles);
    }
    if (selectedRow) {
      let emp = [] as any;
      selectedRow?.usernames?.map((item) => {
        emp.push({ employee: item });
      });
      this.setState({ selectedEmployees: emp });
    }

    ModuleReports.GetReportInputParamters(selectedRow.reportId).then(
      (parameters) => {
        if (parameters && parameters?.length > 0) {
          let ddlReportGroup = parameters.find(
            (x) => x.paramName === "ddlReportGroup"
          );
          let singleSelect = parameters.find(
            (x) => x.paramName === "ddlHotelSingle"
          );
          let ddlHotelMulti = parameters.find(
            (x) => x.paramName === "ddlHotelMulti"
          );
          let showAllHotels = parameters.find(
            (x) => x.paramName === "showAllHotels"
          );
          let notIncludeEnterpriseAccounts = parameters.find(
            (x) => x.paramName === "notIncludeEnterpriseAccounts"
          );
          let showOnlyHotelAccounts = parameters.find(
            (x) => x.paramName === "showOnlyHotelAccounts"
          );

          let ddlMultiReportGroup = parameters.find(
            (x) => x.paramName === "ddlMultiReportGroup"
          );

          let ddlRowTittle = parameters.find(
            (x) => x.paramName === "ddlRowTittle"
          );

          if (singleSelect || ddlHotelMulti) {
            this.setState({ showMultiHidSelector: true });
          } else {
            this.setState({ showMultiHidSelector: false });
          }
          if (ddlReportGroup) {
            this.setState({ showGroupSelector: true });
          } else {
            this.setState({ showGroupSelector: false });
          }

          if (showAllHotels) {
            this.setState({ showAllHotels: true });
          } else {
            this.setState({ showAllHotels: false });
          }
          if (notIncludeEnterpriseAccounts) {
            this.setState({ notIncludeEnterpriseAccounts: true });
          } else {
            this.setState({ notIncludeEnterpriseAccounts: false });
          }

          if (showOnlyHotelAccounts) {
            this.setState({ showOnlyHotelAccounts: true });
          } else {
            this.setState({ showOnlyHotelAccounts: false });
          }

          if (ddlMultiReportGroup) {
            this.setState({ showMultiGroupSelector: true });
          } else {
            this.setState({ showMultiGroupSelector: false });
          }

          if (ddlRowTittle) {
            this.setState({ ddlRowTittle: true });
          } else {
            this.setState({ ddlRowTittle: false });
          }
        }
      }
    );

    let item = {} as any;
    item.reportName = selectedRow.reportName;
    item.moduleReportId = selectedRow.reportId;
    item.uniqueReportId = selectedRow.uniqueReportId; //---- Report static/unchangeable Id -----------
    item.scheduleReportId = selectedRow.uniqueNo ? selectedRow.uniqueNo : 0;
    item.reportParameter = selectedRow.parameters ? selectedRow.parameters : "";
    item.reportVersion = selectedRow.version;
    item.payrollSubscriptionRequired = selectedRow.payrollSubscriptionRequired;
    item.payrollIntegratedHotelsCount =
      selectedRow.payrollIntegratedHotelsCount;

    this.setState({ reportData: item });
    this.setState({ loaderTrue: false });
  };

  componentDidMount() {
    this.getRoleData();
  }

  validateInputs() {
    let isValid = true;
    if (
      this.state.newReportName === "" ||
      this.state.newReportName === undefined
    ) {
      isValid = false;
      ValidationMessage = "Please enter Report Name";
      return isValid;
    }
    if (
      this.state.FirstRunDate === "" ||
      this.state.FirstRunDate === "MM/DD/YY" ||
      this.state.FirstRunDate === undefined
    ) {
      isValid = false;
      ValidationMessage = "Please select First Run Date";
      return isValid;
    }
    if (
      this.state.ScheduleType === "" ||
      this.state.ScheduleType === "Select" ||
      this.state.ScheduleType === undefined
    ) {
      isValid = false;
      ValidationMessage = "Please select Schedule Type";
      return isValid;
    }
    if (this.showPeriod()) {
      if (
        this.state.Period === "" ||
        this.state.Period === "Select" ||
        this.state.Period === undefined
      ) {
        isValid = false;
        ValidationMessage = "Please select Period";
        return isValid;
      }
    }
    if (
      this.state.DistributionPolicy === "" ||
      this.state.DistributionPolicy === "Select" ||
      this.state.DistributionPolicy === undefined
    ) {
      isValid = false;
      ValidationMessage = "Please select Distribution Policy";
      return isValid;
    }
    if (
      this.state.selectedEmployees.length === 0 &&
      this.state.selectedRoles.length === 0
    ) {
      isValid = false;
      ValidationMessage = "Please select Role or Employee";
      return isValid;
    }

    if (this.state.showMultiHidSelector) {
      if (
        this.state.selectedHIDs === "" ||
        this.state.selectedHIDs == undefined ||
        this.state.selectedHIDs == null
      ) {
        isValid = false;
        ValidationMessage = "Please select Hotel";
        return isValid;
      }
    }
    if (this.state.uniqueReportId === 3) {
      if (this.state.AnniversaryDate == "Select") {
        isValid = false;
        ValidationMessage = "Please select Anniversary Date";
        return isValid;
      }
    }

    // if(this.state.SevenDayTrailing == ""){
    //     isValid = false;
    //     ValidationMessage = "Please select Anniversary Date";
    //     return isValid;
    // }
    ValidationMessage = "";
    return true;
  }

  getRoleData = () => {
    let RoleData = [] as any;
    this.setState({ loaderTrue: true });
    Roles.GetLoginUserRoleList()
      .then(async (result: IRolePermissionResponseDto[] | null) => {
        if (result != null) {
          let roleData = result;
          let selectedRoles = [] as any;
          roleData.map((res, index) => {
            let newItem = {} as any;
            newItem.roleID = res.roleID;
            newItem.value = res.roleName;
            newItem.description = res.roleName;
            newItem.isChecked = false;
            let selectedRole = this.state.roleIds.filter(
              (x) => x === res.roleID
            );
            if (selectedRole && selectedRole.length > 0) {
              newItem.isChecked = true;
              selectedRoles.push(newItem);
            } else {
              newItem.isChecked = false;
            }
            RoleData.push(newItem);
          });

          this.setState({
            userRoles: RoleData,
            selectedRoles,
            loaderTrue: false,
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  getEmployeeData = () => {
    // this.setState({ loaderTrue: true });
    User.GetAllActiveUsersByHotelIds(this.state.selectedHIDs)
      .then(async (result: any | null) => {
        if (result && result.length > 0) {
          // let newArr = [] as any;
          if (this.props.mode === "edit") {
            let emp = [] as any;
            this.props.rightPanelData?.usernames?.map((item) => {
              emp.push({ employee: item });
            });
            this.setState({ selectedEmployees: emp });
          }
          this.setState({
            allemployee: _.sortBy(result, "employee"),
            loaderTrue: false,
          });
        } else {
          Utils.toastError("Something went wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "ScheduleReportRightPanel",
          });
          this.setState({ loaderTrue: false });
        }
      })
      .catch();
  };

  rowEvents = {
    onChange: (e: any, row: any, rowIndex: any) => {
      this.setState({ isActionFooter: true });
    },
  };

  handleDiscard = () => {
    if (this.state.UniqueNo > 0) {
      this.updateData(this.state.initalRow);
      this.setState({ isActionFooter: false, isDataChanged: false });
    } else {
      this.setState({
        isActionFooter: false,
        selectedEmployees: [],
        selectedRoles: [],
        newReportName: "",
        FirstRunDate: "MM/DD/YY",
        ScheduleType: "Select",
        Period: "Select",
        DistributionPolicy: "Select",
      });
      this.multiselectChild.current.handleClearAll();
      this.props.hideRightPanel();
      this.props.reloadLeftPanel();
    }
  };

  onReportNameChange = (e) => {
    this.setState({
      newReportName: e.target.value,
      isDataChanged: true,
      isActionFooter: true,
    });
  };

  handleDatesChange = (date) => {
    let netDate = date;
    this.setState({
      FirstRunDate: netDate,
      isDataChanged: true,
      isActionFooter: true,
    });
  };

  handleSelectedItem(item: any) {
    let arr = this.state.selectedEmployees;
    if (arr.length > 0) {
      let exist = arr.filter((x) => x.employee === item.employee);
      if (exist && exist.length > 0) {
        return;
      }
    }
    arr.push(item);
    this.setState({
      selectedEmployees: arr,
      isDataChanged: true,
      isActionFooter: true,
    });
  }

  handleRemoveUser = (employee) => {
    let arr = this.state.selectedEmployees.filter(
      (x) => x.employee !== employee
    );
    this.setState({
      selectedEmployees: arr,
      isDataChanged: true,
      isActionFooter: true,
    });
  };

  handleisCheckCOA = (e: any) => {
    this.setState({ isCheckCOA: e.currentTarget.checked });
    let newParameter: IReportParameter = {
      name: "ChkCheckCOA",
      value: e.currentTarget.checked ? "Yes" : "No",
    };
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);
    let nameIndex = currentReportParameters
      .map((p) => p.name)
      .indexOf("ChkCheckCOA");
    if (nameIndex > -1) {
      currentReportParameters.splice(nameIndex, 1);
    }
    currentReportParameters.push(newParameter);
    const jsonReportParameters = JSON.stringify(currentReportParameters);
    localStorage.setItem("schedulereportParameters", jsonReportParameters);
    this.setState({ isDataChanged: true, isActionFooter: true });
  };
  handleisZeroActualValues = (e: any) => {
    this.setState({ isZeroActualValues: e.currentTarget.checked });
    let newParameter: IReportParameter = {
      name: "ChkHideZeroActualValues",
      value: e.currentTarget.checked ? "Yes" : "No",
    };
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);
    let nameIndex = currentReportParameters
      .map((p) => p.name)
      .indexOf("ChkHideZeroActualValues");
    if (nameIndex > -1) {
      currentReportParameters.splice(nameIndex, 1);
    }
    currentReportParameters.push(newParameter);
    const jsonReportParameters = JSON.stringify(currentReportParameters);
    localStorage.setItem("schedulereportParameters", jsonReportParameters);
    this.setState({ isDataChanged: true, isActionFooter: true });
  };
  handleisHideStatistics = (e: any) => {
    this.setState({ isHideStatistics: e.currentTarget.checked });
    let newParameter: IReportParameter = {
      name: "ChkHideStatistics",
      value: e.currentTarget.checked ? "Yes" : "No",
    };
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);
    let nameIndex = currentReportParameters
      .map((p) => p.name)
      .indexOf("ChkHideStatistics");
    if (nameIndex > -1) {
      currentReportParameters.splice(nameIndex, 1);
    }
    currentReportParameters.push(newParameter);
    const jsonReportParameters = JSON.stringify(currentReportParameters);
    localStorage.setItem("schedulereportParameters", jsonReportParameters);
    this.setState({ isDataChanged: true, isActionFooter: true });
  };

  handleChkIsCompSet = (e: any) => {
    this.setState({ isCompSetValue: e.currentTarget.checked });
    let newParameter: IReportParameter = {
      name: "ChkIsCompSet",
      value: e.currentTarget.checked ? "Yes" : "No",
    };
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);
    let nameIndex = currentReportParameters
      .map((p) => p.name)
      .indexOf("ChkIsCompSet");
    if (nameIndex > -1) {
      currentReportParameters.splice(nameIndex, 1);
    }
    currentReportParameters.push(newParameter);
    const jsonReportParameters = JSON.stringify(currentReportParameters);
    localStorage.setItem("schedulereportParameters", jsonReportParameters);
    this.setState({ isDataChanged: true, isActionFooter: true });
  };

  handleDataSourceChang = (value: any) => {
    if (value.length > 0) {
      let newItem = "";
      value.forEach((element: any) => {
        if (element.isChecked === true) {
          newItem += element.value + ",";
        }
      });
      this.setState({ IsdataSoureSelect: newItem });
      let newParameter: IReportParameter = {
        name: "DataSource1",
        value: newItem,
      };
      let currentReportParameters = JSON.parse(
        JSON.stringify(localStorage.getItem("schedulereportParameters"))
      );
      currentReportParameters = JSON.parse(currentReportParameters);
      let nameIndex = currentReportParameters
        .map((p) => p.name)
        .indexOf("DataSource1");
      if (nameIndex > -1) {
        currentReportParameters.splice(nameIndex, 1);
      }
      currentReportParameters.push(newParameter);
      const jsonReportParameters = JSON.stringify(currentReportParameters);
      localStorage.setItem("schedulereportParameters", jsonReportParameters);
      this.setState({ isDataChanged: true });
    }
  };

  handleDataSource2Chang = (value: any) => {
    if (value.length > 0) {
      let newItem = "";
      value.forEach((element: any) => {
        if (element.isChecked === true) {
          newItem += element.value + ",";
        }
      });
      this.setState({ IsdataSoureSelect2: newItem });
      let newParameter: IReportParameter = {
        name: "DataSource2",
        value: newItem,
      };
      let currentReportParameters = JSON.parse(
        JSON.stringify(localStorage.getItem("schedulereportParameters"))
      );
      currentReportParameters = JSON.parse(currentReportParameters);
      let nameIndex = currentReportParameters
        .map((p) => p.name)
        .indexOf("DataSource2");
      if (nameIndex > -1) {
        currentReportParameters.splice(nameIndex, 1);
      }
      currentReportParameters.push(newParameter);
      const jsonReportParameters = JSON.stringify(currentReportParameters);
      localStorage.setItem("schedulereportParameters", jsonReportParameters);
      this.setState({ isDataChanged: true });
    }
  };

  updateMultiSelectItem = (selectedItem: any) => {
    this.setState({
      selectedRoles: selectedItem,
      isDataChanged: true,
      isActionFooter: true,
    });
  };

  multiSelectOpen = () => {
    $("#schedulerightpanelbody").animate(
      { scrollTop: $(document).height() },
      1500
    );
  };
  onScheduleTypeChange = (value) => {
    this.setState({
      ScheduleType: value,
      isDataChanged: true,
      isActionFooter: true,
    });
  };

  onPeriodChange = (value) => {
    this.setState({ Period: value, isDataChanged: true, isActionFooter: true });
  };

  onDistributionPolicyChange = (value) => {
    this.setState({
      DistributionPolicy: value,
      isDataChanged: true,
      isActionFooter: true,
    });
  };

  onParameterChange = () => {
    let oldParam = this.state.Parameters;
    let newParam = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    oldParam = _.sortBy(JSON.parse(oldParam), "name");
    newParam = _.sortBy(JSON.parse(newParam), "name");

    if (!_.isEqual(oldParam, newParam)) {
      this.setState({
        isDataChanged: true,
        isActionFooter: true,
      });
    } else {
      if (this.state.isduplicate == false)
        this.setState({
          isDataChanged: false,
          isActionFooter: false,
        });
    }
  };

  dataChange = () => {};

  saveReport = async () => {
    if (this.validateInputs() === false) {
      toast.success(ValidationMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "ScheduleReportRightPanel",
      });
      return;
    }
    this.setState({ callParamValidation: true, key: this.state.key + 1 });
  };

  saveReportWithParameters = () => {
    this.setState({ disableSave: true });
    if (this.state.UniqueNo > 0) {
      this.SaveScheduleReport();
    } else {
      ModuleReports.getAutomationReportByName(this.state.newReportName)
        .then((result: any) => {
          this.setState({ disableSave: false, loaderTrue: false });
          if (result != null) {
            Utils.toastError(
              "This report name already exists in Scheduled Reports. Please select a unique name.",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "ScheduleReportRightPanel",
              }
            );
          } else {
            this.SaveScheduleReport();
          }
        })
        .catch((error) => {
          // Need to handle this
          this.setState({ disableSave: false });
          console.log(error);
        });
    }
  };

  confirmDelete = () => {
    this.setState({ showdeleteConfirmation: true });
  };

  deleteScheduleReport = () => {
    this.setState({ disableDelete: true });
    ModuleReports.deleteScheduleReport(this.state.UniqueNo)
      .then(async (result: any) => {
        if (result != null) {
          this.setState({ disableDelete: false });
          if (result.success) {
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "ScheduleReportRightPanel",
            });

            this.setState({ showdeleteConfirmation: false });
            this.setState({
              isActionFooter: false,
              selectedEmployees: [],
              selectedRoles: [],
              newReportName: "",
              FirstRunDate: "MM/DD/YY",
              ScheduleType: "Select",
              Period: "Select",
              DistributionPolicy: "Select",
            });
            this.multiselectChild.current.handleClearAll();
            this.props.hideRightPanel();
            this.props.reloadLeftPanel();
          } else {
            this.setState({ disableDelete: false });
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "ScheduleReportRightPanel",
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ disableDelete: false });
        Utils.toastError("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "ScheduleReportRightPanel",
        });
        reject();
      });
  };

  duplicateScheduleReport = () => {
    this.setState(
      {
        isduplicate: true,
        isActionFooter: true,
        isDataChanged: false,
        CopyOfUniqueNo: this.state.UniqueNo,
      },
      () => {
        this.props.duplicateReport(this.state.UniqueNo);
      }
    );
  };

  updateHidsInreportParameters = () => {
    let newParameter: IReportParameter = {
      name: "Hotelid",
      value: this.state.selectedHIDs,
    };
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);
    debugger;
    let nameIndex = currentReportParameters
      .map((p) => p.name)
      .indexOf("Hotelid");
    if (nameIndex > -1) {
      currentReportParameters.splice(nameIndex, 1);
    }
    currentReportParameters.push(newParameter);

    if (this.state.showGroupSelector) {
      nameIndex = -1;
      nameIndex = currentReportParameters
        .map((p) => p.name)
        .indexOf("GroupName");
      if (nameIndex > -1) {
        currentReportParameters.splice(nameIndex, 1);
      }
      if (this.state.subGroupSelectValue === "") {
        newParameter = { name: "GroupName", value: "" };
      } else {
        newParameter = { name: "GroupName", value: this.state.groupName };
      }

      currentReportParameters.push(newParameter);
      nameIndex = -1;
      nameIndex = currentReportParameters
        .map((p) => p.name)
        .indexOf("HotelBrand");
      if (nameIndex > -1) {
        currentReportParameters.splice(nameIndex, 1);
      }
      newParameter = { name: "HotelBrand", value: this.state.HotelBrand };
      currentReportParameters.push(newParameter);
    }

    if (this.state.showMultiGroupSelector) {
      nameIndex = -1;
      nameIndex = currentReportParameters
        .map((p) => p.name)
        .indexOf("GroupName");
      if (nameIndex > -1) {
        currentReportParameters.splice(nameIndex, 1);
      }
      if (this.state.subGroupSelectValue === "") {
        newParameter = { name: "GroupName", value: "" };
      } else {
        newParameter = { name: "GroupName", value: this.state.groupName };
      }

      currentReportParameters.push(newParameter);
      nameIndex = -1;
      nameIndex = currentReportParameters
        .map((p) => p.name)
        .indexOf("HotelBrand");
      if (nameIndex > -1) {
        currentReportParameters.splice(nameIndex, 1);
      }
      newParameter = { name: "HotelBrand", value: this.state.HotelBrand };
      currentReportParameters.push(newParameter);
    }

    const jsonReportParameters = JSON.stringify(currentReportParameters);
    localStorage.setItem("schedulereportParameters", jsonReportParameters);
    return jsonReportParameters;
  };

  SaveScheduleReport = async () => {
    let jsonReportParameters = this.updateHidsInreportParameters();
    const request: any = {};
    request.UniqueNo = this.state.UniqueNo;
    request.ReportId = this.state.moduleReportId;
    request.ReportGroup = this.state.moduleReportId;
    request.ReportType = this.state.ReportType;
    request.Description = this.state.newReportName;
    request.Hids = this.state.selectedHIDs;
    request.Parameters = jsonReportParameters;

    if (this.state.ScheduleType !== "Select") {
      request.Sechdule = this.state.ScheduleType;
    }
    request.Version = this.state.Version;
    if (this.state.Period !== "Select") {
      request.Period = this.state.Period;
    }
    request.FirstDate = this.state.FirstRunDate;
    request.MailType =
      this.state.DistributionPolicy === "Restricted" ? "Single" : "All";
    request.HIDSelection = "Single";
    let users = [] as any;
    let userRoles = [] as any;
    this.state.selectedRoles.map((item) => {
      userRoles.push(item.roleID);
    });
    this.state.selectedEmployees.map((item) => {
      users.push(item.employee);
    });
    request.Usernames = users;
    request.RoleIds = userRoles;
    if (this.state.isduplicate === true) {
      request.IsDuplicate = this.state.isduplicate;
      request.CopyOfUniqueNo = this.state.CopyOfUniqueNo;
    }
    this.setState({ disableSave: true, loaderTrue: true });
    try {
      const result: any = await ModuleReports.SaveAutomationReport(request);
      this.setState({
        disableSave: false,
        callParamValidation: false,
        loaderTrue: false,
      });
      if (result.success) {
        toast.success(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "ScheduleReportRightPanel",
        });
        this.setState(
          {
            UniqueNo: result.result.uniqueNo,
            isduplicate: false,
            isDataChanged: false,
            isActionFooter: false,
            showDuplicateConfirmation: false,
          },
          () => {
            this.setState({ selectedHIDs: "" });
            this.props.reloadLeftPanel(result.result.uniqueNo);
          }
        );
      } else {
        Utils.toastError(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "ScheduleReportRightPanel",
        });
      }
    } catch (error) {
      this.setState({ disableSave: false });
      Utils.toastError("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "ScheduleReportRightPanel",
      });
    }
  };

  showPeriod = () => {
    //
    if (
      this.state.uniqueReportId === 1 ||
      this.state.uniqueReportId === 5 ||
      this.state.uniqueReportId === 8 ||
      this.state.uniqueReportId === 9
    ) {
      return false;
    } else {
      return true;
    }
  };

  getNewPayPeriodItems = () => {
    let items = [] as any;

    NewTimePeriods.forEach((timeFrame) => {
      if (
        this.state.selectedHIDs?.split(",")?.length > 1 &&
        timeFrame.value == "Last Pay Period"
      ) {
        return;
      }

      items.push(
        <Dropdown.Item
          key={timeFrame.value}
          eventKey={timeFrame.value}
          onClick={(e: any) => this.onPeriodChange(timeFrame.value)}
        >
          {timeFrame.label}
        </Dropdown.Item>
      );
    });

    return items;
  };

  getPeriodItems = () => {
    //
    let items = [] as any;
    if (
      this.state.uniqueReportId === 45 ||
      this.state.uniqueReportId === 35 ||
      this.state.uniqueReportId === 36 ||
      this.state.uniqueReportId === 37 ||
      this.state.uniqueReportId === 38 ||
      this.state.uniqueReportId === 39 ||
      this.state.uniqueReportId === 40 ||
      this.state.uniqueReportId === 41 ||
      this.state.uniqueReportId === 42 ||
      this.state.uniqueReportId === 19
    ) {
      items.push(
        <Dropdown.Item
          key={"Prior Day of Run Date"}
          eventKey={"Prior Day of Run Date"}
          onClick={(e: any) => this.onPeriodChange("Prior Day of Run Date")}
        >
          Prior Day of Run Date
        </Dropdown.Item>
      );
    }
    if (
      !(
        this.state.uniqueReportId === 35 ||
        this.state.uniqueReportId === 36 ||
        this.state.uniqueReportId === 37 ||
        this.state.uniqueReportId === 38 ||
        this.state.uniqueReportId === 39 ||
        this.state.uniqueReportId === 40 ||
        this.state.uniqueReportId === 41 ||
        this.state.uniqueReportId === 42
      )
    ) {
      items.push(
        <Dropdown.Item
          key={"Same as Run Date"}
          eventKey={"Same as Run Date"}
          onClick={(e: any) => this.onPeriodChange("Same as Run Date")}
        >
          Same as Run Date
        </Dropdown.Item>
      );

      items.push(
        <Dropdown.Item
          key={"Prior Day of Run Date"}
          eventKey={"Prior Day of Run Date"}
          onClick={(e: any) => this.onPeriodChange("Prior Day of Run Date")}
        >
          Prior Day of Run Date
        </Dropdown.Item>
      );
      items.push(
        <Dropdown.Item
          key={"Prior Month of Run Date"}
          eventKey={"Prior Month of Run Date"}
          onClick={(e: any) => this.onPeriodChange("Prior Month of Run Date")}
        >
          Prior Month of Run Date
        </Dropdown.Item>
      );
    }
    if (
      !(
        this.state.uniqueReportId === 35 ||
        this.state.uniqueReportId === 36 ||
        this.state.uniqueReportId === 37 ||
        this.state.uniqueReportId === 38 ||
        this.state.uniqueReportId === 39 ||
        this.state.uniqueReportId === 40 ||
        this.state.uniqueReportId === 41 ||
        this.state.uniqueReportId === 42
      )
    ) {
      items.push(
        <Dropdown.Item
          key={"Prior to Prior Month of Run Date"}
          eventKey={"Prior to Prior Month of Run Date"}
          onClick={(e: any) =>
            this.onPeriodChange("Prior to Prior Month of Run Date")
          }
        >
          Prior to Prior Month of Run Date
        </Dropdown.Item>
      );
    }

    return items;
  };

  confirmedDiscardChages = () => {
    if (this.state.discardMode === "opengallery") {
      this.handleDiscard();
      this.props.confirmOpenReportGallery();
    }
    if (this.state.discardMode === "tabchange") {
      this.handleDiscard();
      this.props.confirmsetActiveTab();
    }
    this.handleDiscard();
  };
  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmedDiscardChages(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  checkUnSavedChanges = (mode) => {
    this.setState({ discardMode: mode });
    if (this.state.isDataChanged) {
      this.isValidateModelPoupTab();
      return true;
    } else {
      return false;
    }
  };

  handleBusinessAccountChange = (value: any, reset = false) => {
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);

    let newItem = "";
    value.forEach((element: any) => {
      newItem += element.hotelID + ",";
    });
    newItem = newItem?.substring(0, newItem.length - 1);

    let newParameter: IReportParameter = {
      name: "Hotelid",
      value: this.state.selectedHIDs,
    };

    let nameIndex = -1;
    nameIndex = currentReportParameters.map((p) => p.name).indexOf("Hotelid");
    if (nameIndex > -1) {
      currentReportParameters.splice(nameIndex, 1);
    }
    currentReportParameters.push(newParameter);

    if (reset) {
      this.setState({
        groupName: "",
        HotelBrand: "",
        subGroupSelectValue: "Select Group",
      });
      nameIndex = -1;
      nameIndex = currentReportParameters
        .map((p) => p.name)
        .indexOf("GroupName");
      if (nameIndex > -1) {
        currentReportParameters.splice(nameIndex, 1);
      }
      newParameter = { name: "GroupName", value: "" };
      currentReportParameters.push(newParameter);

      nameIndex = -1;
      nameIndex = currentReportParameters
        .map((p) => p.name)
        .indexOf("HotelBrand");
      if (nameIndex > -1) {
        currentReportParameters.splice(nameIndex, 1);
      }
      newParameter = { name: "HotelBrand", value: "" };
      currentReportParameters.push(newParameter);
    }
    let hotename;
    let hotelcode;
    if (value?.length > 0 && value[0].description !== "") {
      let sethotelName = value[0].description;
      if (sethotelName === "CMC - Cmc Corporation") {
        hotename = sethotelName.slice(6);
        hotelcode = value[0]?.value;
      } else {
        hotename = sethotelName.slice(7);
        hotelcode = value[0]?.value;
      }
      nameIndex = -1;
      nameIndex = currentReportParameters
        .map((p) => p.name)
        .indexOf("description");
      if (nameIndex > -1) {
        currentReportParameters.splice(nameIndex, 1);
      }
      newParameter = {
        name: "description",
        value: hotename.toUpperCase() + " (" + hotelcode + ")",
      };
      currentReportParameters.push(newParameter);
      this.setState({
        Hoteldescription: hotename?.toUpperCase() + " (" + hotelcode + ")",
      });
    } else {
      nameIndex = -1;
      nameIndex = currentReportParameters
        .map((p) => p.name)
        .indexOf("description");
      if (nameIndex > -1) {
        currentReportParameters.splice(nameIndex, 1);
      }
      newParameter = { name: "description", value: "" };
      currentReportParameters.push(newParameter);
      this.setState({ Hoteldescription: "" });
    }

    const jsonReportParameters = JSON.stringify(currentReportParameters);
    localStorage.setItem("schedulereportParameters", jsonReportParameters);

    let _Period = this.state.Period;
    if (
      newItem?.split(",")?.length > 1 &&
      this.state.Period == "Last Pay Period"
    ) {
      _Period = "Select";
    }

    if (this.state.selectedHIDs != newItem || this.state.Period != _Period) {
      this.setState(
        {
          selectedHIDs: newItem,
          Period: _Period,
          isDataChanged: true,
          isActionFooter: true,
        },
        () => this.getEmployeeData()
      );
    }
  };

  handleReportGroupChange = (item: any) => {
    if (item?.groupName) {
      this.setState({
        groupName: item?.groupName === "All" ? "" : item?.groupName,
      });
      this.handleGroupItemChange(item);
    }
  };

  handleMultiReportGroupChange = (value: any, reset = false) => {
    const childcall: any = this.buisnessAccountRef.current;
    if (value.length > 0) {
      let newItem = "";
      value.forEach((element: any) => {
        newItem += element.subGroupName + ",";
      });
      let mulGroup = newItem.replace(/,(\s+)?$/, "");
      this.setState(
        {
          HotelBrand: mulGroup,
          subGroupSelectValue: mulGroup,
          groupName: mulGroup,
          subGroupSelectedList: value,
        },
        () => {
          const hotelList: any = [];
          value.forEach((item: any) => {
            AllHotelBrandSelector.GetHidSelectbyGroup(
              item.subGroupID,
              this.state.showAllHotels,
              this.state.reportData?.payrollSubscriptionRequired
            ).then(async (result: any | null) => {
              if (result != null) {
                result.forEach((element) => {
                  let newItem = {} as any;
                  newItem.hotelID = element.hotelID;
                  newItem.isChecked = true;
                  hotelList.push(newItem);
                });
                if (childcall !== null) {
                  if (reset && this.state.selectedHIDs?.length > 0) {
                    let hotelIDs = this.state.selectedHIDs
                      .split(",")
                      ?.map((r) => +r);
                    let selectedHotels = hotelList?.map((r) => {
                      if (hotelIDs?.includes(r.hotelID)) {
                        r.isChecked = true;
                      } else {
                        r.isChecked = false;
                      }
                      return r;
                    });
                    childcall.updateHotelList(selectedHotels);
                  }

                  if (!reset) {
                    childcall.updateHotelList(hotelList);
                  }
                }
              }
            });
          });
        }
      );
    }
  };

  handleGroupItemChange = (item: any, initial = false) => {
    if (!item) {
      return false;
    }
    const childcall: any = this.buisnessAccountRef.current;
    if (+item?.groupID === 0 || +item?.groupID === -1) {
      this.setState({ HotelBrand: "" });
      if (+item?.groupID === 0) {
        childcall.updateHotelList([], true);
      } else {
        childcall.updateHotelList([]);
      }
      this.setState({
        subGroupSelectValue: item.groupName,
        groupName: item.groupName,
      });
      return;
    }

    this.setState({
      HotelBrand: item.subGroupName,
      subGroupSelectValue: item.subGroupName,
    });
    AllHotelBrandSelector.GetHidSelectbyGroup(
      item.subGroupID,
      this.state.showAllHotels,
      this.state.reportData?.payrollSubscriptionRequired
    ).then(async (result: any | null) => {
      if (result != null) {
        const hotelList: any = [];
        result.forEach((element) => {
          let newItem = {} as any;
          newItem.hotelID = element.hotelID;
          newItem.isChecked = true;
          hotelList.push(newItem);
        });

        if (this.multiselectChild.current !== null) {
          if (initial && this.state.selectedHIDs?.length > 0) {
            let hotelIDs = this.state.selectedHIDs.split(",")?.map((r) => +r);
            const selectedHotels = hotelList?.map((r) => {
              if (hotelIDs?.includes(r.hotelID)) {
                r.isChecked = true;
              } else {
                r.isChecked = false;
              }
              return r;
            });

            childcall.updateHotelList(selectedHotels);
          }
          if (!initial) {
            childcall.updateHotelList(hotelList);
          }
        }
      }
    });
  };

  onAnniversaryDateChange = (value, name) => {
    this.setState({ AnniversaryDate: name });
    let newParameter: IReportParameter = { name: "DocumentType", value: value };
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);
    let nameIndex = currentReportParameters
      .map((p) => p.name)
      .indexOf("DocumentType");
    if (nameIndex > -1) {
      currentReportParameters.splice(nameIndex, 1);
    }
    currentReportParameters.push(newParameter);
    const jsonReportParameters = JSON.stringify(currentReportParameters);
    localStorage.setItem("schedulereportParameters", jsonReportParameters);
    this.setState({ isDataChanged: true, isActionFooter: true });
  };

  handle7DayTrailingChange = (e: any) => {
    this.setState({ SevenDayTrailing: e.currentTarget.checked });
    let newParameter: IReportParameter = {
      name: "DocumentType",
      value: e.currentTarget.checked ? "Yes" : "",
    };
    let currentReportParameters = JSON.parse(
      JSON.stringify(localStorage.getItem("schedulereportParameters"))
    );
    currentReportParameters = JSON.parse(currentReportParameters);
    let nameIndex = currentReportParameters
      .map((p) => p.name)
      .indexOf("DocumentType");
    if (nameIndex > -1) {
      currentReportParameters.splice(nameIndex, 1);
    }
    currentReportParameters.push(newParameter);
    const jsonReportParameters = JSON.stringify(currentReportParameters);
    localStorage.setItem("schedulereportParameters", jsonReportParameters);
    this.setState({ isDataChanged: true, isActionFooter: true });
  };

  render() {
    const columns = [
      {
        dataField: "User",
        text: "User",
        formatter: (cell, row) => (
          <div className="d-flex">
            <span className="">{row.employee}</span>
          </div>
        ),
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
      },
      {
        dataField: "Delete",
        text: "",
        formatter: (cell, row) => {
          if (!this.state.AllowScheduleEdit) {
            return <></>;
          }

          return (
            <div className="">
              <span
                className="fa-trash"
                onClick={() => this.handleRemoveUser(row.employee)}
              >
                <DeleteIcon />
              </span>
            </div>
          );
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {},
        },
      },
    ];

    return (
      <>
        <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"ScheduleReportRightPanel"}
        />
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"reportsScheduleRightPanel"} />
        ) : (
          <>
            <RouteLeavingGuard
              when={this.state.isDataChanged}
              routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
              shouldBlockNavigation={(location: { pathname: any }) => {
                if (this.state.isDataChanged) {
                  if (location.pathname) {
                    return true;
                  }
                }
                return false;
              }}
            />

            {!this.state.isRPanelHide && (
              <div className="right-panel">
                <div className="pos-header d-flex align-content-center flex-wrap">
                  <div className="search-header mr-auto auto-complete">
                    <input
                      type="text"
                      maxLength={100}
                      disabled={!this.state.AllowScheduleEdit}
                      value={this.state.newReportName}
                      onChange={(e) => this.onReportNameChange(e)}
                    ></input>
                  </div>
                  {this.state.AllowScheduleEdit && (
                    <div className="action-group d-flex flex-row">
                      <Dropdown
                        className="more-action duplicate-report"
                        alignRight
                      >
                        <Dropdown.Toggle
                          disabled={
                            this.state.AllowScheduleEdit === false ||
                            this.state.UniqueNo === 0 ||
                            this.state.isDataChanged === true
                          }
                          className="btn-outline-primary btn btn-primary more"
                          id="dropdown-more"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => this.duplicateScheduleReport()}
                          >
                            {/* <img src={Duplicate} alt="Duplicate" />  */}
                            Duplicate
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => this.confirmDelete()}>
                            {/* <img src={Delete} alt="Delete" />  */}
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {this.state.showdeleteConfirmation && (
                        <div className="deleteReport">
                          <OutsideClickHandler
                            onOutsideClick={() =>
                              this.setState({ showdeleteConfirmation: false })
                            }
                          >
                            <div className="deleteReportDropdown">
                              <div className="deleteReportBody">
                                <div className="deleteReportHeading">
                                  Delete Scheduled Report
                                </div>
                                <div className="deleteReportTxt">
                                  <p>
                                    Are you sure you want to delete this
                                    scheduled report{" "}
                                    <b>{this.state.newReportName}</b> ?
                                  </p>
                                </div>
                              </div>
                              <div className="deleteReportFooter action-link">
                                <button
                                  className="deleteReportCancel btn wht-bg"
                                  onClick={() =>
                                    this.setState({
                                      showdeleteConfirmation: false,
                                    })
                                  }
                                >
                                  Cancel
                                </button>
                                <button
                                  disabled={this.state.disableDelete}
                                  className="deleteReportDelete btn btn-primary btn-danger"
                                  onClick={() => this.deleteScheduleReport()}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </OutsideClickHandler>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  id="schedulerightpanelbody"
                  className={
                    this.state.isActionFooter
                      ? "pos-body dataChange"
                      : "pos-body"
                  }
                >
                  <Form>
                    <Form.Group as={Row} controlId="department">
                      <Form.Label
                        column
                        sm="3"
                        className="d-flex align-items-center section-ttl"
                      >
                        Report Type{" "}
                      </Form.Label>
                      <div className="col-sm-9">
                        <div className="form-control-plaintext">
                          {this.state.ReportType}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group as={Row} controlId="department">
                      <Form.Label
                        column
                        sm="3"
                        className="d-flex align-items-center"
                      >
                        Report Name{" "}
                      </Form.Label>
                      <div className="col-sm-9">
                        <div className="form-control-plaintext">
                          {this.state.ReportName}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group as={Row} controlId="department">
                      <Form.Label
                        column
                        sm="3"
                        className="d-flex align-items-center"
                      >
                        First run date
                      </Form.Label>
                      <div className="col-sm-9">
                        <div className="form-control-plaintext">
                          <DateRangePicker
                            initialSettings={{
                              showDropdowns: true,
                              singleDatePicker: true,
                              autoUpdateInput: false,
                              autoApply: true,
                              locale: {
                                daysOfWeek: [
                                  "Sun",
                                  "Mon",
                                  "Tue",
                                  "Wed",
                                  "Thu",
                                  "Fri",
                                  "Sat",
                                ],
                              },
                            }}
                            onApply={(e: any, picker: any) => {
                              const value = picker.startDate.format("MM/DD/YY");
                              this.handleDatesChange(value);
                            }}
                          >
                            {!this.state.FirstRunDate ||
                            this.state.FirstRunDate === "MM/DD/YY" ? (
                              <input
                                disabled={!this.state.AllowScheduleEdit}
                                type="text"
                                placeholder="First run date"
                                value={""}
                                className="removeBorder form-control"
                              />
                            ) : (
                              <input
                                disabled={!this.state.AllowScheduleEdit}
                                type="text"
                                value={moment(this.state.FirstRunDate).format(
                                  "MM/DD/YY"
                                )}
                                className="removeBorder form-control"
                              />
                            )}
                          </DateRangePicker>
                        </div>
                      </div>
                    </Form.Group>

                    <div className="border-line-main"></div>
                    <div>
                      <div className="section-ttl">Scheduling</div>

                      <Form.Group as={Row} controlId="department">
                        <Form.Label
                          column
                          sm="3"
                          className="d-flex align-items-center"
                        >
                          {" "}
                          Schedule{" "}
                        </Form.Label>
                        <div className="col-sm-9">
                          <div className="form-control-plaintext">
                            <Dropdown>
                              <Dropdown.Toggle
                                id="ddlScheduleType"
                                disabled={!this.state.AllowScheduleEdit}
                              >
                                <div className="d-inline-block">
                                  {this.state.ScheduleType}
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  key={"Daily"}
                                  eventKey={"Daily"}
                                  onClick={(e: any) =>
                                    this.onScheduleTypeChange("Daily")
                                  }
                                >
                                  Daily
                                </Dropdown.Item>
                                <Dropdown.Item
                                  key={"Weekly"}
                                  eventKey={"Weekly"}
                                  onClick={(e: any) =>
                                    this.onScheduleTypeChange("Weekly")
                                  }
                                >
                                  Weekly
                                </Dropdown.Item>
                                <Dropdown.Item
                                  key={"Monthly"}
                                  eventKey={"Monthly"}
                                  onClick={(e: any) =>
                                    this.onScheduleTypeChange("Monthly")
                                  }
                                >
                                  Monthly
                                </Dropdown.Item>
                                <Dropdown.Item
                                  key={"Yearly"}
                                  eventKey={"Yearly"}
                                  onClick={(e: any) =>
                                    this.onScheduleTypeChange("Yearly")
                                  }
                                >
                                  Yearly
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Form.Group>
                      {this.showPeriod() && (
                        <Form.Group as={Row} controlId="department">
                          <Form.Label
                            column
                            sm="3"
                            className="d-flex align-items-center"
                          >
                            Period
                          </Form.Label>
                          <div className="col-sm-9">
                            <div className="form-control-plaintext">
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="ddlPeriod"
                                  disabled={!this.state.AllowScheduleEdit}
                                >
                                  <div className="d-inline-block">
                                    {this.state.Period}
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {this.state.useNewPeriod == 1
                                    ? this.getNewPayPeriodItems()
                                    : this.getPeriodItems()}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </Form.Group>
                      )}
                      <Form.Group as={Row} controlId="department">
                        <Form.Label
                          column
                          sm="3"
                          className="d-flex align-items-center"
                        >
                          Distribution Policy
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={"tooltip-Assign"}>
                                Unrestricted: Selecting the UNRESTRICTED option
                                will send reports to the selected users for all
                                selected EHID's regardless of the employee EHID
                                access.
                                <br></br>
                                <br></br>
                                Restricted: Selecting the RESTRICTED option will
                                send reports to the selected users only for the
                                EHID's for which the user has been given access
                                to.
                              </Tooltip>
                            }
                          >
                            <span>
                              <InfoTooltip />{" "}
                            </span>
                          </OverlayTrigger>
                        </Form.Label>
                        <div className="col-sm-9">
                          <div className="form-control-plaintext">
                            <Dropdown>
                              <Dropdown.Toggle
                                id="ddlDistributionPolicy"
                                disabled={!this.state.AllowScheduleEdit}
                              >
                                <div className="d-inline-block">
                                  {this.state.DistributionPolicy}
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {this.state.policy.map(
                                  (item: any, idx: any) => (
                                    <Dropdown.Item
                                      key={item}
                                      eventKey={item}
                                      onClick={(e: any) =>
                                        this.onDistributionPolicyChange(item)
                                      }
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Form.Group>
                      {this.state.showGroupSelector && (
                        <Form.Group
                          controlId="ddlReportGroup"
                          className="reportGrpSelector row"
                        >
                          <Form.Label
                            className="d-flex align-items-center sec-ttl col-sm-3"
                            style={{ fontFamily: "OpenSans-Semibold" }}
                          >
                            Group
                          </Form.Label>
                          <div className="col-sm-9" style={{ paddingLeft: 24 }}>
                            <ReportGroupSelector
                              defaultValue={this.state.subGroupSelectValue}
                              isAll={this.state.showAllHotels}
                              handleReportGroupChange={
                                this.handleReportGroupChange
                              }
                              payrollSubscriptionRequired={
                                this.state.reportData
                                  ?.payrollSubscriptionRequired
                              }
                            />
                          </div>
                        </Form.Group>
                      )}
                      {this.state.showMultiGroupSelector && (
                        <Form.Group
                          controlId="ddlReportGroup"
                          className="reportGrpSelector row"
                        >
                          <Form.Label
                            className="d-flex align-items-center sec-ttl col-sm-3"
                            style={{ fontFamily: "OpenSans-Semibold" }}
                          >
                            Group
                          </Form.Label>
                          <div className="col-sm-9" style={{ paddingLeft: 35 }}>
                            <ReportGroupBrandSelector
                              defaultValue={this.state.subGroupSelectValue}
                              selectedItems={this.state.subGroupSelectedList}
                              notIncludeEnterpriseAccounts={
                                this.state.notIncludeEnterpriseAccounts
                              }
                              isAll={this.state.showAllHotels}
                              handleMultiReportGroupChange={
                                this.handleMultiReportGroupChange
                              }
                              payrollSubscriptionRequired={
                                this.state.reportData
                                  ?.payrollSubscriptionRequired
                              }
                            />
                          </div>
                        </Form.Group>
                      )}
                      {this.state.showMultiHidSelector && (
                        <Form.Group as={Row} controlId="multiSelectHid">
                          <Form.Label
                            column
                            sm="3"
                            className="d-flex align-items-center"
                          >
                            {" "}
                            Select Hotels{" "}
                          </Form.Label>
                          <div className="col-sm-9">
                            <div className="form-control-plaintext">
                              <div
                                className={`ehid-picker ${
                                  !this.state.AllowScheduleEdit
                                    ? "cursor-disable"
                                    : ""
                                }`}
                              >
                                <BusinessAccountSelector
                                  defaultValue={this.state.selectedHIDs}
                                  notIncludeEnterpriseAccounts={
                                    this.state.notIncludeEnterpriseAccounts
                                  }
                                  showOnlyHotelAccounts={
                                    this.state.showOnlyHotelAccounts
                                  }
                                  isAll={this.state.showAllHotels}
                                  custom
                                  ref={this.buisnessAccountRef}
                                  isDisabled={!this.state.AllowScheduleEdit}
                                  handleBusinessAccountChange={
                                    this.handleBusinessAccountChange
                                  }
                                  payrollSubscriptionRequired={
                                    this.state.reportData
                                      ?.payrollSubscriptionRequired
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Form.Group>
                      )}
                      {this.state.uniqueReportId === 3 && (
                        <Form.Group as={Row} controlId="AnniversaryDates">
                          <Form.Label
                            column
                            sm="3"
                            className="d-flex align-items-center"
                          >
                            {" "}
                            Anniversary Dates for{" "}
                          </Form.Label>
                          <div className="col-sm-9">
                            <div className="form-control-plaintext">
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="ddlAnniversaryDates"
                                  disabled={!this.state.AllowScheduleEdit}
                                >
                                  <div className="d-inline-block">
                                    {this.state.AnniversaryDate}
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    key={1}
                                    eventKey={"1"}
                                    onClick={(e: any) =>
                                      this.onAnniversaryDateChange(
                                        "1",
                                        "This month"
                                      )
                                    }
                                  >
                                    This month
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    key={"2"}
                                    eventKey={"2"}
                                    onClick={(e: any) =>
                                      this.onAnniversaryDateChange(
                                        "2",
                                        "Next month"
                                      )
                                    }
                                  >
                                    Next Month
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </Form.Group>
                      )}
                      {this.state.uniqueReportId === 17 && (
                        <Form.Group as={Row} controlId="ddlCheckbox">
                          <Form.Label
                            className="sec-ttl d-flex align-items-center form-label col-form-label col-sm-3"
                            style={{ fontFamily: "OpenSans-Semibold" }}
                          >
                            Last 7 Days Trailing{" "}
                          </Form.Label>
                          <div className="col-sm-9" style={{ paddingLeft: 24 }}>
                            <div className="form-control-plaintext">
                              <Form.Check
                                type="switch"
                                className="sm-controll"
                                onChange={(e) =>
                                  this.handle7DayTrailingChange(e)
                                }
                                checked={this.state.SevenDayTrailing}
                                label=""
                              />
                            </div>
                          </div>
                        </Form.Group>
                      )}
                    </div>
                    <div className="border-line-main"></div>
                    <div>
                      <div className="section-ttl">Report Parameters</div>
                      {this.state.reportData.moduleReportId > 0 && (
                        <ReportParameterSelectionForSchedule
                          key={this.state.key}
                          param={JSON.stringify(
                            localStorage.getItem("schedulereportParameters")
                          )}
                          saveReportWithParameters={
                            this.saveReportWithParameters
                          }
                          callParamValidation={this.state.callParamValidation}
                          userReportName={this.state.newReportName}
                          reportData={this.state.reportData}
                          onParameterChange={this.onParameterChange}
                          runFrom={"schedulereport"}
                          disabled={!this.state.AllowScheduleEdit}
                        ></ReportParameterSelectionForSchedule>
                      )}
                    </div>

                    <div className="border-line-main"></div>
                    <div className="12345">
                      <div className="section-ttl">Recipients</div>
                      <Form.Group as={Row} controlId="department">
                        <Form.Label
                          column
                          sm="3"
                          className="d-flex align-items-center"
                        >
                          Select Roles
                        </Form.Label>
                        <div
                          className="col-sm-9 selectRolesMultiSelect"
                          style={{ maxWidth: "fit-content" }}
                        >
                          {this.state.userRoles.length > 0 && (
                            <MultiSelectSearch
                              ref={this.multiselectChild}
                              multiSelectOpen={this.multiSelectOpen}
                              itemList={this.state.userRoles}
                              updateMultiSelectItem={this.updateMultiSelectItem.bind(
                                this
                              )}
                              managePermission={this.state.AllowScheduleEdit}
                              dataChange={this.dataChange.bind(this)}
                              isSelectAll={true}
                              filtertitle={"Filter By Roles"}
                              isDisable={!this.state.AllowScheduleEdit}
                            />
                          )}
                        </div>
                      </Form.Group>
                      <BootstrapTable
                        keyField="employee"
                        data={this.state.selectedEmployees}
                        columns={columns}
                      />
                      {this.state.allemployee.length > 0 &&
                        this.state.AllowScheduleView && (
                          <div>
                            <SingleSelectSearchWithImage
                              ref={this.firstSingleSelect}
                              employeeList={this.state.allemployee}
                              addUser={true}
                              handleSelectedItem={this.handleSelectedItem.bind(
                                this
                              )}
                              mode="primary"
                              defaultItem={this.state.positionName}
                            />
                          </div>
                        )}
                    </div>
                    {this.state.isActionFooter &&
                      this.state.AllowScheduleEdit && (
                        <div className="fixed-action">
                          <div className="d-flex align-content-center flex-wrap">
                            <div className="mr-auto message">
                              <span>You have unsaved changes</span>
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary btn-discard"
                              onClick={this.handleDiscard}
                            >
                              Discard
                            </button>

                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={this.state.disableSave}
                              onClick={this.saveReport}
                            >
                              Save Schedule
                            </button>
                          </div>
                        </div>
                      )}
                  </Form>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
