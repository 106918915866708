/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React from "react";
import { Dropdown, Button, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TableSearch from "../../../../Common/Components/TableSearch";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import { FiChevronDown } from "react-icons/fi";
import './vendorTabs.scss';
import _ from "lodash";
import DatePicker from "react-datepicker";
import { VendorService } from "../../../../Common/Services/vendor";
import Dropzone from 'react-dropzone';
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { Utils } from "../../../../Common/Utilis";

export class DocumentsTab extends React.Component<any, any> {
    private searchControl: any;
    constructor(props) {
        super(props)
        this.searchControl = React.createRef();
        this.state = {
            vid: props?.vid,
            tableData: [],
            fileSize: 5242880,
            selectedfiles: [],
            sortArray: [
                { name: "Name", id: "sortfilename", isActive: true },
                { name: "Type", id: "docType", isActive: false },
                { name: "Effective Date", id: "sortStratDate", isActive: false },
                { name: "Expiration Date", id: "sortExpDate", isActive: false }
            ],
            SortBy: { name: "Name", id: "sortfilename", isActive: true },
            submitLoader: false,
            pageLoader: false,
            tableLen: [],
        }
    }

    componentDidMount(): void {
        this.getVendorTaxDocuments();
    }


    checkAValidDate = (itemdt: any) => {
        let validDate: any = "";
        if (itemdt !== null) {
            if (itemdt.includes(".")) {
                validDate = new Date(itemdt.split(".")[0]);
            } else {
                if (itemdt !== "01/01/00" && new Date(itemdt).toString() !== "Invalid Date") {
                    validDate = itemdt
                }
            }
        }
        return validDate;
    }

    getVendorTaxDocuments = () => {
        let request: any = {};
        request.VID = Number(this.state.vid);
        this.setState({ tableData: [], pageLoader: true, tableLen: [] })
        VendorService.GetVendorTaxDocuments(request)
            .then((result: any) => {
                if (result.length > 0) {
                    let docTypeList = ["Workmans Comp", "General Liability", "W9", "Other Tax Form", "Other Insurance Certificate"];
                    result.map((item: any, index: any) => {
                        let otItm = item.docType.toString().toLowerCase() === "other tax form";
                        let w9Itm = item.docType.toString().toLowerCase() === "w9";
                        let isGreaterExpDate = item.expiryDate !== null ? new Date(item.expiryDate) < new Date(new Date(item.stratDate).setDate(new Date(item.stratDate).getDate() + 1)) : false;
                        let expDate = this.checkAValidDate(item.expiryDate);
                        let startDate = this.checkAValidDate(item.stratDate);
                        let isCheckStartDate = (new Date(startDate).toString() === "Invalid Date" ? true : false);
                        let isChCond = ((expDate === "") || isGreaterExpDate ? true : false)

                        item["docTypeList"] = docTypeList;
                        item["sortfilename"] = (item.filename).toString().toLowerCase();
                        item["staticRow"] = false;
                        item["newUniqueno"] = item.uniqueno;
                        item["fileIndex"] = index;
                        item["attachedFile"] = [];
                        item["expiryDate"] = expDate;
                        item["entryType"] = "old";
                        item["stratDate"] = startDate;
                        item["sortStratDate"] = (startDate === "" ? "" : startDate);
                        item["sortExpDate"] = (expDate === "" ? "" : expDate);
                        item["isRowUpdated"] = false;
                        item["typeError"] = item.docType === "" ? true : false;
                        item["isDisableSubmit"] = false;
                        item["isDisableSort"] = false;
                        item["stratDateError"] = startDate === "" ? false : isCheckStartDate;
                        item["expdateError"] = ((otItm || w9Itm) ? false : isChCond);
                        item["isExpiryDate"] = ((otItm || w9Itm) ? true : false);
                    })

                    result = _.sortBy(result, this.state.SortBy.id);
                    let { tableLen } = this.state;
                    result.map((item, index) => {
                        tableLen.push(item.fileIndex)
                    })

                    this.setState({ tableData: result, tableLen, isUnsavedChanges: false, pageLoader: false });
                } else {
                    this.setState({ isUnsavedChanges: false, pageLoader: false });
                }
                resolve();
            })
            .catch((error) => {
                reject();
                this.setState({ isUnsavedChanges: false, pageLoader: false });
                Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
            });
    }

    searchedData = (newResult: any) => {
        let { tableData } = this.state;
        if (tableData.length > 0) {
            tableData[0].isDisableSubmit = false;
            tableData[0].isDisableSort = newResult.length < 2;
        }
        this.setState(() => ({ tableData }))
    }

    CleanSearch() {
        this.searchControl.current.CleanSearch();
    };

    dateFormat = (date) => {
        let newDate = new Date(date).toLocaleDateString();
        let month = Number(newDate.split("/")[0]) < 10 && newDate.split("/")[0].length < 2 ? `0${newDate.split("/")[0]}` : newDate.split("/")[0]
        let day = Number(newDate.split("/")[1]) < 10 && newDate.split("/")[1].length < 2 ? `0${newDate.split("/")[1]}` : newDate.split("/")[1];
        let year = new Date(newDate.split("/")[2]).getFullYear() % 100;
        return `${month}/${day}/${year}`;
    }

    isValidDate = (date: any) => {
        let reGoodDate = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][\d]|3[01])[- /.](19|20)?[\d]{2})*$/;
        return reGoodDate.test(date);
    }

    handleDateControlKey = (event: any, row: any, type: any) => {
        let { tableData } = this.state;
        let validDate = event.target.value !== "" ? this.isValidDate(event.target.value) : false;
        let isExpdtError: any;
        let newNextPlusDate: any;
        let newDate: any;
        let isStDtLess: any;
        let hasLowerExpDate: any;
        let nextDayDate: any;
        let setExpiryDate: any;
        if (event.keyCode === 13) {
            if (type === "stratDate") {
                tableData = tableData.map((items) => {
                    if (items.fileIndex === row.fileIndex) {
                        isExpdtError = items.docType.toString().toLowerCase() === "other tax form" || items.docType.toString().toLowerCase() === "w9" ? true : false;
                        newNextPlusDate = validDate ? new Date(new Date(event.target.value).setDate(new Date(event.target.value).getDate() + 1)) : "";
                        newDate = new Date(new Date().setHours(0, 0, 0, 0));
                        isStDtLess = newNextPlusDate > new Date(event.target.value);
                        let hasLowerDate = (newDate <= new Date(event.target.value) ? new Date(event.target.value) : new Date());
                        hasLowerExpDate = ((isStDtLess ? hasLowerDate : new Date(event.target.value)) >= new Date(items?.expiryDate));
                        let isCheckDTLess = newNextPlusDate < newDate ? newDate : newNextPlusDate;
                        nextDayDate = validDate ? isCheckDTLess : "";
                        let isTrCheck = hasLowerExpDate ? moment(nextDayDate).format("DD-MMM-YYYY") : items.expiryDate !== "" ? moment(items.expiryDate).format("DD-MMM-YYYY") : "";
                        let isTrCheckSort = hasLowerExpDate ? this.dateFormat(event.target.value) : items.expiryDate !== "" ? this.dateFormat(items.expiryDate) : "";
                        return {
                            ...items,
                            stratDate: validDate ? moment(event.target.value).format("DD-MMM-YYYY") : "",
                            expiryDate: validDate ? isTrCheck : "",
                            sortExpDate: validDate ? isTrCheckSort : "",
                            sortStratDate: validDate ? this.dateFormat(event.target.value) : "",
                            stratDateError: false,
                            isRowUpdated: true,
                            expdateError: isExpdtError ? false : items.expiryDate === "" ? true : false,
                        };
                    } else {
                        return items;
                    }
                });
            } else {
                tableData = tableData.map((items) => {
                    if (items.fileIndex === row.fileIndex) {
                        newDate = new Date(new Date().setHours(0, 0, 0, 0));
                        let stDate = items.stratDate === "" ? newDate : new Date(new Date(items.stratDate).setDate(new Date(items.stratDate).getDate() + 1));
                        newNextPlusDate = validDate ? stDate : "";
                        isStDtLess = newNextPlusDate > (items.stratDate === "" ? new Date(event.target.value) : new Date(items.stratDate));
                        hasLowerExpDate = ((isStDtLess ? (newDate <= new Date(items?.stratDate) ? new Date(items?.stratDate) : new Date()) : new Date(items?.stratDate)) >= new Date(event.target.value));
                        nextDayDate = validDate ? newNextPlusDate < newDate ? newDate : newNextPlusDate : "";
                        setExpiryDate = validDate ? hasLowerExpDate ? moment(nextDayDate).format("DD-MMM-YYYY") : moment(event.target.value).format("DD-MMM-YYYY") : "";
                        return {
                            ...items,
                            expiryDate: setExpiryDate,
                            sortExpDate: validDate ? hasLowerExpDate ? this.dateFormat(nextDayDate) : this.dateFormat(event.target.value) : "",
                            expdateError: validDate ? false : true,
                            isRowUpdated: true
                        };
                    } else {
                        return items;
                    }
                });
                if (type === "expiryDate") {
                    tableData = tableData.map((items) => {
                        if (items.fileIndex === row.fileIndex) {
                            return {
                                ...items,
                                expiryDate: setExpiryDate,
                                sortExpDate: validDate ? hasLowerExpDate ? this.dateFormat(nextDayDate) : this.dateFormat(event.target.value) : "",
                                expdateError: validDate ? false : true,
                                isRowUpdated: true
                            };
                        } else {
                            return items;
                        }
                    });
                }
            }
        }

        this.setState(() => ({ tableData }));
    }

    handleRowEvent = (event: any, row: any, type: any) => {
        let { tableData } = this.state;
        let disableSort = tableData.some(o => o.typeError || o.stratDateError || o.expdateError);
        if (type === "type") {
            tableData = tableData.map((items) => {
                if (items.fileIndex === row.fileIndex) {
                    let isExpdtError = event.toString().toLowerCase() === "other tax form" || event.toString().toLowerCase() === "w9" ? true : false;
                    return {
                        ...items,
                        docType: event,
                        isExpiryDate: isExpdtError,
                        typeError: false,
                        expdateError: isExpdtError ? false : items.expiryDate === "" ? true : false,
                        expiryDate: isExpdtError ? "" : items.expiryDate,
                        sortExpDate: isExpdtError ? "" : items.expiryDate,
                        isRowUpdated: true,
                    };
                } else {
                    return items;
                }
            });
        } else if (type === "stratDate") {
            tableData = tableData.map((items) => {
                if (items.fileIndex === row.fileIndex) {
                    let isExpdtError = items.docType.toString().toLowerCase() === "other tax form" || items.docType.toString().toLowerCase() === "w9" ? true : false;
                    let newNextPlusDate = event !== null ? new Date(new Date(event).setDate(new Date(event).getDate() + 1)) : "";
                    let newDate = new Date(new Date().setHours(0, 0, 0, 0));

                    let isStDtLess = newNextPlusDate > new Date(event);
                    let hasLowerExpDate = ((isStDtLess ? (new Date(newDate) <= new Date(event) ? new Date(event) : new Date()) : new Date(event)) >= new Date(items?.expiryDate));

                    let nextDayDate = event !== null ? newNextPlusDate <= newDate ? newDate : newNextPlusDate : "";
                    return {
                        ...items,
                        stratDate: event !== null ? moment(event).format("DD-MMM-YYYY") : "",
                        sortStratDate: event !== null ? this.dateFormat(event) : "",
                        expiryDate: hasLowerExpDate ? moment(nextDayDate).format("DD-MMM-YYYY") : items.expiryDate !== "" ? moment(items.expiryDate).format("DD-MMM-YYYY") : "",
                        sortExpDate: hasLowerExpDate ? this.dateFormat(nextDayDate) : items.expiryDate !== "" ? this.dateFormat(items.expiryDate) : "",
                        isExpiryDate: isExpdtError,
                        stratDateError: false,
                        isRowUpdated: true,
                        expdateError: isExpdtError ? false : items.expiryDate === "" ? true : false,
                    };
                } else {
                    return items;
                }
            });
        } else if (type === "expiryDate") {
            tableData = tableData.map((items) => {
                if (items.fileIndex === row.fileIndex) {
                    return {
                        ...items,
                        expiryDate: event !== null ? moment(event).format("DD-MMM-YYYY") : "",
                        sortExpDate: event !== null ? this.dateFormat(event) : "",
                        expdateError: false,
                        isRowUpdated: true,
                    };
                } else {
                    return items;
                }
            });
        } else if (event === "downloadDy") {
            this.downloadVendorTaxDocuments(row);
        } else if (event === "removeDy") {
            this.deleteVendorTaxDocuments(row);
        }
        if (!disableSort) {
            tableData = _.sortBy(tableData, this.state.SortBy.id);
        }
        this.setState((curr: any) => ({ ...curr, tableData, isUnsavedChanges: true }), () => {
            this.props.changesStatus(true);
        });
    }

    removeStaticRow = (row: any) => {
        let { tableData, tableLen } = this.state;
        let disableSort = tableData.some(o => o.typeError || o.stratDateError || o.expdateError);
        confirmAlert({
            title: "Delete entry",
            message: "Are you sure you want to delete this Entry?",
            buttons: [{
                label: "Yes", onClick: () => {
                    tableData = tableData.filter((item: any) => item.fileIndex !== row.fileIndex);
                    tableLen = tableLen.filter(item => item !== row.fileIndex);

                    let hasZero = tableData.filter(item => item.newUniqueno === 0);
                    let count: number = 0;
                    tableData.map(item => {
                        if (item.newUniqueno === 0) {
                            count = count + 1;
                            item.fileIndex = (tableData.length - hasZero.length) + count - 1;
                        }
                    })

                    this.setState(() => ({ tableData, tableLen }));
                },
            }, {
                label: "No", onClick: () => reject(),
            }],
        });
        if (!disableSort) {
            tableData = _.sortBy(tableData, this.state.SortBy.id);
        }
        this.setState((curr: any) => ({ ...curr, tableData, isUnsavedChanges: true }), () => {
            this.props.changesStatus(true);
        });
    }

    deleteVendorTaxDocuments = (row) => {
        let request: any = {};
        request.Uniqueno = row.uniqueno;
        request.docType = row.docType;
        request.expiryDate = row.expiryDate;
        request.stratDate = row.stratDate;
        request.Filecode = Number(row.filecode);
        request.Filename = row.filename;
        confirmAlert({
            title: "Delete entry",
            message: "Are you sure you want to delete this Entry?",
            buttons: [{
                label: "Yes", onClick: () => {
                    this.setState({ submitLoader: true })
                    VendorService.DeleteVendorTaxDocuments(request)
                        .then((result: any) => {
                            let { tableData, tableLen } = this.state;
                            if (result.saveStatus === "Success") {
                                toast.success(result.message, { position: toast.POSITION.BOTTOM_RIGHT, });
                                tableData = tableData.filter(item => item.fileIndex !== row.fileIndex);
                                tableLen = tableLen.filter(item => item !== row.fileIndex);

                                let hasZero = tableData.filter(item => item.newUniqueno === 0);
                                let count: number = 0;
                                tableData.map((item: any, index: any) => {
                                    if (item.newUniqueno !== 0) {
                                        item.fileIndex = index;
                                    } else {
                                        if (tableData.some(item => item.fileIndex !== row.fileIndex)) {
                                            count = count + 1;
                                            item.fileIndex = (tableData.length - 1 - hasZero.length) + count;
                                        } else {
                                            item.fileIndex = (tableData.length - hasZero.length) + count + 1;
                                        }
                                    }
                                })
                                this.setState(() => ({ tableData, submitLoader: false, tableLen }));
                            }
                        })
                        .catch((error) => {
                            reject();
                        });
                },
            }, {
                label: "No", onClick: () => reject(),
            }],
        });
    }

    downloadVendorTaxDocuments = (row) => {
        let request: any = {};
        request.UniqueNo = row.uniqueno;
        request.Name = row.filename;
        request.size = 0
        request.FileUniqueno = 0;
        request.UploadFile = (row.filecode).toString();
        request.Etype = ""
        request.FileCode = (row.filecode).toString()
        request.Pageno = ""
        request.CreateDate = row.stratDate;
        VendorService.DownloadVendorTaxDocuments(request)
            .then((result: any) => {
                resolve();
            })
            .catch((error) => {
                reject();
                Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
            });
    }

    onAttachmentDrop = (uploadAttachments: any, type: any) => {
        let size = this.state.fileSize
        let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024))
        let { tableData, tableLen } = this.state;
        if (uploadAttachments.length > 0) {
            if (uploadAttachments[0].size > size) {
                Utils.toastError("File is greater than " + filesizeval + "MB.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                let docTypeList = ["Workmans Comp", "General Liability", "W9", "Other Tax Form", "Other Insurance Certificate"];
                let newList: any = [];
                uploadAttachments.forEach((items, index) => {
                    let newRow: any = {};
                    newRow.attachedFile = [];
                    newRow.filename = items.name.split(/\.(?=[^\\.]+$)/)[0];
                    newRow.sortfilename = (items.name.split(/\.(?=[^\.]+$)/)[0]).toString().toLowerCase();
                    newRow.uniqueno = tableLen.length + newList.length + 1;
                    newRow.fileUploadDateTime = new Date();
                    newRow.newUniqueno = 0;
                    newRow.fileIndex = tableData.length + newList.length;
                    newRow.docTypeList = docTypeList;
                    newRow.docType = "Select Type";
                    newRow.staticRow = true;
                    newRow.stratDate = "";
                    newRow.expiryDate = "";
                    newRow.entryType = "new";
                    newRow.attachedFile.push(items);
                    newRow.isRowUpdated = true;
                    newRow.typeError = true;
                    newRow.stratDateError = false;
                    newRow.expdateError = true;
                    newRow.isExpiryDate = false;
                    newList.push(newRow);
                });
                tableData = tableData.concat(newList);
                newList.forEach(item => tableLen.push(item.fileIndex));
                tableData = _.sortBy(tableData, this.state.SortBy.id);
                $("#addAttachmentDocument").val("");
                this.CleanSearch();
                this.setState(() => ({ tableData, tableLen }), () => {
                    this.props.changesStatus(true);
                });
            }
        } else {
            Utils.toastError("Please select a valid file.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    handleSortTable = (eventKey: any) => {
        let { sortArray, tableData, SortBy } = this.state;
        sortArray.map(item => item.isActive = item.id === eventKey);
        let isRow = sortArray.filter(item => item.id === eventKey)
        SortBy.name = isRow[0].name;
        SortBy.id = isRow[0].id;
        tableData = _.sortBy(tableData, eventKey);
        this.setState({ SortBy, tableData, sortArray });
    }

    saveVendorTaxDocuments = () => {
        this.setState({ submitLoader: true })
        let { tableData } = this.state;
        let requestArray: any = [];
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage?.tenantID);
        let userName = storage === null ? "" : (storage?.userName);

        tableData.forEach((item: any, index: any) => {
            let reqRow: any = {};
            reqRow.attachedFile = [];
            reqRow.vid = Number(this.state.vid);
            reqRow.uniqueNo = item.newUniqueno;
            reqRow.fileIndex = item.fileIndex;
            reqRow.docType = item.docType;
            reqRow.stratDate = item.stratDate === "" ? "" : item.stratDate;
            reqRow.expiryDate = item.expiryDate === "" ? "" : item.expiryDate;
            reqRow.attachedFile = item.attachedFile;
            reqRow.userName = userName;
            reqRow.tenantID = tenantID;
            requestArray.push(reqRow);
        });
        requestArray = _.sortBy(requestArray, "fileIndex");
        VendorService.SaveVendorTaxDocuments(requestArray)
            .then((result: any) => {
                if (result.saveStatus.toString().toLowerCase() === "success") {
                    toast.success(result.message, { position: toast.POSITION.BOTTOM_RIGHT, });
                    this.props.isReload(true);
                    this.props.changesStatus(false);
                    this.getVendorTaxDocuments();
                } else {
                    if (result?.messageCode.toLowerCase() === "vendornotexists") {
                        Utils.toastError("This Vendor is already merged into another vendor.", { position: toast.POSITION.BOTTOM_RIGHT, });
                    } else {
                        Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
                    }
                }
                this.setState({ submitLoader: false });
            })
            .catch((error: any) => {
                reject();
                this.setState({ submitLoader: false });
                Utils.toastError(error, { position: toast.POSITION.BOTTOM_RIGHT, });
            });
    }
    substrYear = (time) => {
        let month = ((time.split("/")[0].length < 2 && time.split("/")[0] < 10) ? ("0" + time.split("/")[0]) : (time.split("/")[0]));
        let date = ((time.split("/")[1].length < 2 && time.split("/")[1] < 10) ? ("0" + time.split("/")[1]) : (time.split("/")[1]));
        let year = ((time.split("/")[2].length > 2) ? (time.split("/")[2].substring(2)) : (time.split("/")[2]));
        return (`${month}/${date}/${year}`);
    }
    render() {
        let { tableData, SortBy } = this.state;
        let noEditPermission = this.props?.isEditVendor ? false : true;
        const notesIcon = () => (
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"></path>
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"></path>
            </svg>
        )

        const checkSvg = () => {
            return (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                </svg>
            )
        }
        const acceptedFiles = `image/*,application/pdf,
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
        application/vnd.ms-excel,
        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
        text/plain,
        .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml`;
        let showFooterPanel = false;
        let disableSaveButton = false;
        let disableSorting = false;
        if (tableData.length > 0) {
            showFooterPanel = tableData.some(o => o.isRowUpdated);
            disableSaveButton = tableData.some(o => o.typeError || o.stratDateError || o.expdateError || o.isDisableSubmit);
            disableSorting = tableData[0].isDisableSort;
        }
        const dateControl = (row: any, rowIndex: any, type: any) => {
            let isExpiryDate = (type === "expiryDate");
            let hasDateClass = (type === "stratDate" ?
                (row.stratDateError ? "border-danger form-control" : "form-control") :
                (row.isExpiryDate ? "form-control" : row.expdateError ? "border-danger form-control" : "form-control"));
            let isSelectedDate = (type === "stratDate" ? (row.stratDate === "" ? "" : row.stratDate) : (row.expiryDate === "" ? "" : row.expiryDate));
            let isReadOnly = (type === "stratDate" ? false : row.isExpiryDate);
            let setDateClass = type === "stratDate" ?
                ("date-picker calender-picker row_CustomDateDropdown nonEditableDate") :
                ("date-picker calender-picker row_CustomDateDropdown nonEditableDate dateSetToRight");

            let todaysDate = new Date(new Date().setHours(0, 0, 0, 0));
            let newStartPlusDate = new Date(new Date(row.stratDate).setDate(new Date(row.stratDate).getDate() + 1));
            let maxExpDate = (todaysDate <= new Date(row.stratDate) ? newStartPlusDate : todaysDate)
            return (
                <div className={setDateClass}>
                    <div className="hasOverlay">
                        <label className="calanderOverlay" htmlFor={`${type}_` + rowIndex}></label>
                        <label htmlFor={`${type}_` + rowIndex} className="drop-arrow">
                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                            </svg>
                        </label>
                    </div>
                    <DatePicker
                        id={`${type}_` + rowIndex}
                        className={hasDateClass}
                        placeholderText={"MM/DD/YY"}
                        dateFormat="MM/dd/yy"
                        autoComplete="off"
                        disabled={noEditPermission}
                        readOnly={isReadOnly}
                        selected={isSelectedDate === "" ? null : new Date(isSelectedDate)}
                        onSelect={(event: any) => this.handleRowEvent(event, row, type)}
                        onKeyDown={(event: any) => this.handleDateControlKey(event, row, type)}
                        minDate={isExpiryDate && maxExpDate}
                    />
                </div>
            )
        }

        const renderDocNameCol = (row: any) => {
            let isInValidFileDate = row.fileUploadDateTime === "" || row.fileUploadDateTime === "1900-01-01T00:00:00";
            return (
                <>
                    <div className="documentColName">
                        <strong><EllipsisWithTooltip placement="bottom">{row.filename.split(/\.(?=[^\.]+$)/)[0]}</EllipsisWithTooltip></strong>
                        <span><EllipsisWithTooltip placement="bottom">
                            {(isInValidFileDate) ? <>&nbsp;</> : this.substrYear(new Date(row.fileUploadDateTime).toLocaleDateString())}
                        </EllipsisWithTooltip></span>
                    </div>
                </>
            )
        }

        const renderDocTypeCol = (row) => (
            <div className="row_CustomDropdown">
                <Dropdown className="itemDropdown" onSelect={(event: any) => this.handleRowEvent(event, row, "type")}>
                    <Dropdown.Toggle id="eType" className={`${noEditPermission ? row.typeError ? "border-danger disabled-area" : "disabled-area" : row.typeError ? "border-danger" : ""}`}>
                        <div className="pos-rel">
                            <div className="d-flex align-items-center">
                                <div className="txt-area gTextArea mr-auto">
                                    <EllipsisWithTooltip placement="bottom">{row.docType}</EllipsisWithTooltip>
                                </div>
                                <div className="drop-arrow ml-0 d-flex">
                                    <FiChevronDown />
                                </div>
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {row.docTypeList.map((item: any, index: any) => (
                            <Dropdown.Item key={item} eventKey={item}>{item}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div >
        )

        const renderDocDateCol = (row: any, rowIndex: any) => (row.isExpiryDate ? (<div></div>) : dateControl(row, rowIndex, "expiryDate"));

        const renderDocIconCol = (row) => {
            if (row.staticRow) {
                return (
                    <div className="float-right">
                        <Button className="btn-outline-primary removeXBtn more" onClick={() => this.removeStaticRow(row)}>
                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z">
                                </path>
                            </svg>
                        </Button>
                    </div>
                )
            } else {
                return (
                    <div className="float-right">
                        <Dropdown className="more-action" alignRight onSelect={(event: any) => this.handleRowEvent(event, row, "")}>
                            <Dropdown.Toggle className={`${noEditPermission ? "disabled-area" : ""} btn-outline-primary btn btn-primary more`} id="dropdown-more">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="removeDy">Delete</Dropdown.Item>
                                <Dropdown.Item eventKey="downloadDy">Download</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )
            }
        }

        const tableColumns = [{
            dataField: '',
            headerClasses: "docDocumentCol",
            classes: "docDocumentCol",
            text: '',
            formatter: (cell: any, row: any, rowIndex: any) => notesIcon()
        }, {
            dataField: 'filename',
            text: 'Name',
            headerClasses: "docNameCol",
            classes: "docNameCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderDocNameCol(row)
        }, {
            dataField: 'docType',
            text: 'Type',
            headerClasses: "docTypeCol",
            classes: "docTypeCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderDocTypeCol(row)
        }, {
            dataField: 'stratDate',
            headerClasses: "docDateCol",
            classes: "docDateCol",
            text: 'Effective Date',
            formatter: (cell: any, row: any, rowIndex: any) => dateControl(row, rowIndex, "stratDate")
        }, {
            dataField: 'sortStratDate',
            hidden: true,
            text: '',
        }, {
            dataField: 'sortExpDate',
            hidden: true,
            text: '',
        }, {
            dataField: 'expiryDate',
            headerClasses: "docDateCol",
            classes: "docDateCol",
            text: 'Expiration Date',
            formatter: (cell: any, row: any, rowIndex: any) => renderDocDateCol(row, rowIndex)
        }, {
            dataField: 'action',
            text: '',
            headerClasses: "docIconCol",
            classes: "docIconCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderDocIconCol(row)
        }]
        const billingDetailsLoading = (number) => {
            let newCount: any = [];
            for (let i = 0; i < Number(number); i++) { newCount.push(i) }
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    {newCount.map((item) => (
                                        <div key={item} className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }
        const tabLoader = () => {
            return (
                <>
                    <div style={{ "height": "50px" }}></div>
                    <div className="bulkImportList mt-0">
                        <div className="bulkImportList1 d-flex">
                            <div className="single-loader loadingAnimation"></div>
                        </div>
                        <>
                            {billingDetailsLoading(6)}
                            {billingDetailsLoading(6)}
                            {billingDetailsLoading(6)}
                        </>
                    </div>
                </>
            )
        }
        return (
            <>
                {this.state.submitLoader && (
                    <div className="pageOverlaySpinner">
                        <Spinner animation="border" variant="secondary" />
                    </div>
                )}
                <div className="general-tab">
                    <div className="body-section">
                        <div className="heading">Documents</div>
                        {this.state.pageLoader ? (<>{tabLoader()}</>) : (
                            <div className="bulkInvoiceImportChildTable">
                                <div className="documentsTabTable">
                                    <ToolkitProvider
                                        keyField="fileIndex"
                                        data={tableData}
                                        columns={tableColumns}
                                        search={{ afterSearch: (newResult: any) => { this.searchedData(newResult); } }}>
                                        {(props: { searchProps: JSX.IntrinsicAttributes; baseProps: JSX.IntrinsicAttributes; }) => (
                                            <>
                                                <div className="navigation-menus mb-3 mt-3 d-flex">
                                                    <div className={tableData.length > 0 ? "" : "disabled-area"}>
                                                        <TableSearch id="tableSearch" key="tableSearch" ref={this.searchControl}
                                                            {...props.searchProps} CleanSearch={this.CleanSearch.bind(this)}
                                                            placeholder={"Search..."}
                                                        />
                                                    </div>
                                                    <div className={(tableData.length === 0 || disableSorting) ? "navigationSort ml-auto disabled-area" : "navigationSort ml-auto"}>
                                                        <Dropdown className="more-action bg-blue" alignRight>
                                                            <Dropdown.Toggle className="" id="dropdown-more">
                                                                <div className="d-flex align-items-center">
                                                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.5 12L6.5 9H4.25V0H2.75V9H0.5L3.5 12ZM7.25 3H14V4.5H7.25V3ZM7.25 6H12.5V7.5H7.25V6ZM7.25 0H15.5V1.5H7.25V0ZM7.25 9H11V10.5H7.25V9Z" />
                                                                    </svg>
                                                                    {SortBy.name}
                                                                    <div className="drop-arrow d-flex">
                                                                        <FiChevronDown />
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Header>SORT</Dropdown.Header>
                                                                {this.state.sortArray.map((item: any, index: any) => (
                                                                    <Dropdown.Item key={item?.id} className={item.isActive ? "active wom_dropdownItem" : "wom_dropdownItem"}
                                                                        onClick={(e) => this.handleSortTable(item.id)}
                                                                    >
                                                                        <div className="d-flex">
                                                                            <div className="mr-auto">{item.name}</div>
                                                                            <div className="ml-auto">{item.isActive && checkSvg()}</div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <BootstrapTable {...props.baseProps} keyField="fileIndex" hover noDataIndication={tableData.length === 0 ? "" : 'No record found.'} />
                                                {tableData.length > 0 && (
                                                    <div className={`${noEditPermission ? "disabled-area" : ""} footerAddButton`}>
                                                        <Dropzone disabled={noEditPermission} onDrop={(e) => this.onAttachmentDrop(e, "addBox")} accept={acceptedFiles}>
                                                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                                <>
                                                                    <input id="addAttachmentDocument" {...getInputProps()} />
                                                                    <label htmlFor="addAttachmentDocument">Add Document</label>
                                                                </>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </ToolkitProvider>
                                </div>
                                {tableData.length === 0 && (<>
                                    <div className={`${noEditPermission ? "disabled-area" : ""} bulkInvoiceUploadSection`}>
                                        <Dropzone disabled={noEditPermission} onDrop={(e) => this.onAttachmentDrop(e, "selectBox")} accept={acceptedFiles}>
                                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (<>
                                                <section className={isDragActive ? "upload-area default drag-active " : "upload-area default"}>
                                                    <div {...getRootProps({ className: 'dropzone d-flex align-items-center' })}>
                                                        <input {...getInputProps()} />
                                                        <p>Drag and drop or <span>browse files</span> to upload</p>
                                                    </div>
                                                </section></>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <div className={`${noEditPermission ? "disabled-area" : ""} footerAddButton`}>
                                        <Dropzone disabled={noEditPermission} onDrop={(e) => this.onAttachmentDrop(e, "addBox")} accept={acceptedFiles}>
                                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (<>
                                                <input id="addAttachmentDocument" {...getInputProps()} />
                                                <label htmlFor="addAttachmentDocument">Add Document</label></>
                                            )}
                                        </Dropzone>
                                    </div></>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={showFooterPanel ? "tabFooterSection" : "d-none tabFooterSection"}>
                        <div className="d-flex align-items-center justify-content-end height-72">
                            <Button variant="primary" type="button"
                                disabled={disableSaveButton || this.state.submitLoader}
                                onClick={this.saveVendorTaxDocuments} >Save Changes</Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
