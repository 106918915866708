import React from "react";
import {
    Button,
    Modal,
    Dropdown,
    ProgressBar,
    Spinner,
    Tooltip,
    OverlayTrigger,
    Form
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import { Register as registersevice } from "../../../Common/Services/Register";
import { Journal as Journalsevice } from "../../../Common/Services/Journal";
import { resolve, reject } from "q";
import "./JournalImport.scss";
import { FiChevronDown, FiTrash, FiPaperclip } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { DataImportRules } from "../../../Common/Services/DataImportRule";
import { confirmAlert } from "react-confirm-alert";
import cellEditFactory from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import _ from "lodash";
import { Hotel } from "../../../Common/Services/Hotel";
import { VendorSearch } from "../../../Common/Components/VendorSearch";
import { CommonService } from "../../../Common/Services/CommonService";
import { RoleSubPermissionSetting } from "../../../Common/Services/RoleSubPermissionSetting";
import { Utils } from "../../../Common/Utilis";

const { SearchBar } = Search;
let expandedRows: any = [];
let clickedRow: any = [];
export class JournalImport extends React.Component<any, any> {
    private trdate: any;
    private vendorSearch: any;
    constructor(props: any) {
        super(props);
        this.trdate = React.createRef();
        this.vendorSearch = React.createRef();
        this.state = {
            BlobbaseUrl:"",
            hidValue: props.hidValue,
            hotelName: props.hotelName,
            oprId: props.oprId,
            modalWidth: "modal-60w",
            isProgress: Math.floor(Math.random() * 30),
            uploadFiles: [],
            importJournalModal: true,
            isUploadedJournal: false,
            coaList: [],
            accPeriodList: [],
            journalData: [],
            isImportData: false,
            isUpdated: false,
            vendorListMain: [],
            hidList: [],
            uploadAttachments: [],
            isMountDone: true,
            isActSpinner: false,
            isAddContractPerm: true,
            currentRowHid: "",
            editedRow: [],
            loader: false,
            fileSize: 5242880,
            isChanging: false
        };
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount() {
        this.getBlobbaseUrl();
          }
        
          getBlobbaseUrl = () => {
           
            let keyName ="BlobbaseUrl";
            let BlobbaseUrl="";
             CommonService.GetInnflowConfigKeyValue(keyName)
               .then(async (result: any | null) => {
                 console.log(result);        
                 if (result !== null) {
                   BlobbaseUrl = result;
                   this.setState({ BlobbaseUrl:BlobbaseUrl });
                 }
                 
               });
           }

    getFileSize() {
        registersevice.GetFileSize()
            .then(async (result: any | null) => {
                if (result > 0) {
                    this.setState({ fileSize: result })
                }

            });
    }

    journalModalClose = () => {
        expandedRows = [];
        this.setState(
            {
                importJournalModal: false,
                isProgress: 0,
                isUploadedJournal: false,
            },
            () => {
                this.props.hideJournalModal(this.state.isUpdated);
            }
        );
    };

    downloadTemplate = () => {
        let BlobbaseUrl =this.state.BlobbaseUrl;
        //'https://innflowfilestoretest.blob.core.windows.net/innflowtemplate/ImportJournal.xlsx'
        fetch(BlobbaseUrl+'innflowtemplate/ImportJournal.xlsx', {
            method: 'GET', headers: { 'Content-Type': 'application/vnd.ms-excel' }
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ImportJournal.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
    }


    onDrop = (uploadFiles: any) => {
        let size = 5242880;
        if (uploadFiles.length === 1) {
            // if (uploadFiles[0].size > size) {
            //     Utils.toastError("File is greater than 5 MB.", {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //     });
            // } else {
                this.setState({ uploadFiles }, () => {
                    this.setState({ loader: true });
                    this.journalExcelImport();
                    this.getFileSize();
                });
            //}
        } else if (uploadFiles.length > 1) {
            Utils.toastError("Multiple files are not allowed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } else {
            Utils.toastError("Only .xlsx,.xls, and .csv file is acceptable.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };

    journalExcelImport = () => {
        Journalsevice.JournalExcelImport(this.state.uploadFiles)
            .then(async (result: any) => {
                this.setState({ isProgress: 100 });
                if (result.success) {
                    let excelResult = _.sortBy(result?.result, "id");
                    let data = excelResult;
                    data.map(
                        (item, index) => (
                            (item["defaultSplitHID"] = []),
                            (item["defaultVendor"] = []),
                            (item["attachmentMain"] = []),
                            (item["defaultSplitCOA"] = []),
                            (item["defaultHID"] = []),
                            (item["accPerList"] = []),
                            (item["isActSpinner"] = false),
                            (item["customerList"] = []),
                            (item["isVariance"] = false),
                            (item["varianceAmount"] = "0.00"),
                            (item["customerList"] = []),
                            (item["isValidCust"] = false),
                            (item["drAmount"] = Utils.removeInvaildCharFromAmountNonMinus(item.drAmount)),
                            (item["crAmount"] = Utils.removeInvaildCharFromAmountNonMinus(item.crAmount))
                        )
                    );

                    setTimeout(() => {
                        this.setState({ journalData: excelResult, isUploadedJournal: false }, () => {
                            this.GetHotelCOAlist(excelResult);
                            this.loadHotelsDetails(excelResult);
                            //this.getVendorPermission();
                            this.hasVariance(excelResult, null);
                            this.varianceAmount(excelResult, null);
                        });
                    }, 1000);
                } else if (result?.message.toLocaleLowerCase() === "invalidcallname") {
                    setTimeout(() => {
                        Utils.toastError(result?.result, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                } else if (result?.message.toLocaleLowerCase() === "DuplicateCol".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("Duplicate columns are not allowed.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                } else if (result?.message.toLocaleLowerCase() === "InvalidEHID".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("Please enter the valid EHID.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                } else if (result?.message.toLocaleLowerCase() === "AccPerFormatInvalid".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("Please enter the accounting period in correct format 'MM/DD/YY'.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                } else if (result?.message.toLocaleLowerCase() === "DateFormatInvalid".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("Please enter the date in correct format 'MM/DD/YY'", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                } else if (result?.message.toLocaleLowerCase() === "InternalLength".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("Internal notes length not be more than 100 char.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                } else if (result?.message.toLocaleLowerCase() === "DescriptionLength".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("Description length not be more than 100 char.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                } else if (result?.message.toLocaleLowerCase() === "InvalidAmount".toLocaleLowerCase() || result?.message.toLocaleLowerCase() === "AmountLength".toLocaleLowerCase()) {
                    setTimeout(() => {
                        Utils.toastError("Please enter a valid amount.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ isUploadedJournal: false, uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                }
                else if (result?.message === "NoRecords") {
                    setTimeout(() => {
                        Utils.toastError("No records to import.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                } else {
                    setTimeout(() => {
                        Utils.toastError(result?.result, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }, 1000)
                    setTimeout(() => {
                        this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                    }, 1500)
                }
                resolve();
            })
            .catch((error) => {
                setTimeout(() => {
                    Utils.toastError("No Records are available to import.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }, 1000);
                setTimeout(() => {
                    this.setState({ uploadFiles: [], isProgress: Math.floor(Math.random() * 30) });
                }, 1500)
                reject();
            });
    };

    GetAccountingPeriod = (row: any, dropID) => {
        if (row.accPerList.length === 0) {
            let accPeriod: any = [];
            let { journalData } = this.state;
            journalData = journalData.map((items) => {
                if (items.groupID === row.groupID) {
                    return { ...items, isActSpinner: true, accPerList: [] };
                } else {
                    return items;
                }
            });
            this.setState({ accPeriodList: [], isActSpinner: true, journalData: journalData, isMountDone: false, });
            accPeriod = Object.assign(
                [],
                JSON.parse(JSON.stringify(this.state.accPeriodList))
            );
            let request: any = {};
            request.hotelID = row.ehid;
            request.etype = "Operating";
            registersevice
                .GetAccountingPeriod(request)
                .then(async (result: any | null) => {
                    if (result !== null) {
                        result.forEach((element) => {
                            let item: any = {};
                            item.id = element.accperiod;
                            item.name = element.accperiod;
                            accPeriod.push(item);
                        });
                        const accPerFound = accPeriod.some((item) => item.name === row.accPer);
                        journalData = journalData.map((items) => {
                            if (items.groupID === row.groupID) {
                                return { ...items, accPerList: accPeriod, isActSpinner: false, eAccPer: accPerFound ? null : "InActive Period" };
                            } else {
                                return items;
                            }
                        });
                        this.setState((curr: any) => ({
                            ...curr,
                            journalData: journalData,
                        }), () => {
                            if (!accPerFound && row.eAccPer === null)
                                setTimeout(() => {
                                    $('#' + dropID).click();
                                }, 100);

                            if (row.vid !== "")
                                this.GetVendorContract(row.vid, row, 'current');

                            setTimeout(() => { this.isReadyToImport(row); }, 500);
                        });

                    }

                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        }
    };

    getVendorPermission = async () => {
        let requestObject = {} as any;
        requestObject.permissionID = 3;
        requestObject.Module = "Accounting";
        requestObject.Type = "page";
        try {
            const response = await Promise.all([RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)]);
            if (response !== null) {
                let finalResponse = response[0];
                if (finalResponse.length > 0) {
                    for (let i = 0; i < finalResponse.length; i++) {
                        if (finalResponse[i].displayName === "Add Contract") {
                            this.setState({ isAddContractPerm: true });
                        }
                    }
                }
            }
        } catch (error) {
            Utils.toastError("Something went wrong in roll permission.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };

    GetHotelCOAlist = (journalData) => {
        let request: any = {};
        request.hotelID = this.state.hidValue;
        registersevice
            .GetHotelCOAlist(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    let coaData = result.transactionChartList;
                    journalData.forEach((ccd: any, idx: any) => {
                        coaData.forEach((coa: any) => {
                            if (ccd.coa === coa.id) {
                                let defaultCOA: any = [];
                                let objCOA: any = {};
                                objCOA.id = coa?.id;
                                objCOA.label = coa?.label;
                                objCOA.coAname = coa?.coAname;
                                defaultCOA.push(objCOA);
                                journalData[idx].defaultSplitCOA = defaultCOA;
                            }
                        });
                    });
                    this.setState(
                        {
                            coaList: coaData,
                            journalData: journalData,
                        },
                        () => {
                            this.GetVendorList(journalData, "");
                        }
                    );
                } else {
                    Utils.toastError("Something went wrong", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.setState({
                        loader: false,
                    });
                }
                resolve();
            })
            .catch((error) => {
                reject();
                this.setState({
                    loader: false,
                });
            });
    };

    GetVendorList = (journalData, Rowhid) => {
        let request: any = {};
        request.HID = Rowhid === "" ? this.state.hidValue : Rowhid;
        DataImportRules.GetVendorList(request)
            .then(async (result: any) => {
                if (result.success) {
                    let vendorData = result.result;
                    vendorData.map(
                        (items) => (
                            (items["label"] = items.vendor), (items["id"] = items.vid)
                        )
                    );
                    journalData.forEach((ccd: any, idx: any) => {
                        vendorData.forEach((vendor: any) => {
                            if (
                                ccd.vendorName.toLowerCase() ===
                                vendor.vendor.toLowerCase()
                            ) {
                                let defaultVendor: any = [];
                                let objVEN: any = {};
                                objVEN.id = vendor.vid;
                                objVEN.label = vendor?.vendor;
                                objVEN.vendor = vendor?.vendor;
                                defaultVendor.push(objVEN);
                                journalData[idx].defaultVendor = defaultVendor;
                            }
                        });


                        let contractList = ccd.customerNoList !== null && ccd.customerNoList.split(",");
                        ccd.customerNoList === null && (ccd.customerNo = "");
                        let cusList: any = [];
                        if (ccd.vendorName !== "") {
                            if (contractList.length > 0) {
                                contractList.forEach((item, index) => {
                                    let cRow: any = {};
                                    cRow.accountNo = item;
                                    cRow.rowID = index;
                                    ccd.customerList.push(cRow);
                                    if (ccd.customerNo.toString().toLocaleLowerCase() === item.toLocaleLowerCase()) {
                                        cusList.push(item);
                                        ccd.isValidCust = true;
                                    }
                                })
                            }
                        } else {
                            ccd.customerNo = "";
                        }


                    });
                    this.setState(
                        {
                            vendorListMain: vendorData,
                            isUploadedJournal: true,
                            journalData: journalData,
                            loader: false
                        }
                    );
                } else {
                    Utils.toastError(result?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.setState({
                        loader: false
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({
                    loader: false
                });
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    };

    loadHotelsDetails(journalData) {
        Hotel.getUserHotelAccess("Accounting")
            .then(async (result: any[] | null) => {
                if (result != null) {
                    let HIDData = result;
                    HIDData = HIDData.map((item: any, id: any) => {
                        return {
                            id: item.hotelID,
                            label: item.lettercode,
                        };
                    });
                    journalData.forEach((ccd: any, idx: any) => {
                        result.forEach((hid: any) => {
                            if (ccd.ehid === hid.hotelID?.toString()) {
                                let defaulthid: any = [];
                                let objhid: any = {};
                                objhid.id = hid?.hotelID;
                                objhid.label = hid?.lettercode;
                                defaulthid.push(objhid);
                                journalData[idx].defaultHID = defaulthid;
                            }
                        });

                    });
                    let data = _.uniqBy(this.state.journalData, "groupID");
                    data.forEach((ccd: any, idx: any) => {
                        if (ccd.isExpand)
                            expandedRows.push(ccd.id);//
                    });
                    this.setState({ hidList: HIDData, journalData });
                }
                resolve();
            })
            .catch((err) => {
                Utils.toastError(`Server Error, ${err}`);
                reject();
            });
    }

    deleteCurrentRow = (row) => {
        let { journalData } = this.state;
        confirmAlert({
            title: "Delete entry",
            message: "Are you sure you want to delete this Entry?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        journalData = journalData.filter(
                            (item) => item.groupID !== row.groupID
                        );
                        setTimeout(() => {
                            this.setState({
                                journalData,
                            }, () => {
                                this.handleChange(null, row, "prDate"); // for re render the table
                            });
                            this.isReadyToImport(row);
                        }, 1000);
                    },
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
            ],
        });
    };

    handleBackScreen = () => {
        confirmAlert({
            title: "Alert Message",
            message:
                "All data will remove which you set to current screen, Are you sure you want to redirect previous screen?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        this.setState({
                            isUploadedJournal: false,
                            uploadFiles: []
                        });
                    },
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
            ],
        });
    };

    headerFormatter = (column: any, colIndex: any) => {
        return <>{column.text}</>;
    };

    headerFileFormatter = (column: any, colIndex: any) => {
        return <FontAwesomeIcon icon={faPaperclip}  />;
    };

    handleImportData = () => {
        this.setState({ isImportData: true });
        let { journalData } = this.state;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage.userName;
        let pageName = this.props.pageName;
        if (pageName === "Register") {
            let requestArray: any = [];
            journalData.forEach((items) => {
                if (items.crAmount !== "0.00" || items.drAmount !== "0.00") {
                    let request: any = {};
                    request.id = items.id;
                    request.groupID = items.groupID;
                    request.Hid = items.ehid; //items.ehid//latterCode - 1430
                    request.OprId = 0;
                    request.Vendor = items.vendorName;
                    request.ContractNo = items.customerNo;
                    request.Date = items.date;
                    request.Accper = items.accPer;
                    request.EHID = this.state.hidValue; //items.ehid;
                    request.COA = items.coa?.split(' ')[0];
                    request.Amount = items.crAmount === 0 || items.crAmount === "0" || items.crAmount === "0.00" ? items.drAmount.replace(',', '') : items.crAmount.replace(',', '');
                    request.Description = items.description;
                    request.Internalnotes = items.internalNotes;
                    request.SplitHid = items.ehid;
                    request.SplitLetterCode = items.letterCode;
                    request.Type = items.crAmount === 0 || items.crAmount === "0" || items.crAmount === "0.00" ? "JournalFrom" : "JournalTo";
                    request.TotalAmt = Number(items.varianceAmount); //items.crAmount === 0 || items.crAmount === "0" || items.crAmount === "0.00" ? items.drAmount : items.crAmount;
                    request.TenantID = storage.tenantID;
                    request.UserName = userName;
                    request.attachmentMain = items.attachmentMain;
                    requestArray.push(request);
                }
            });
            // console.log(requestArray)
            this.importDataJournal(requestArray);
        }
    };

    importDataJournal = (requestArray) => {
        Journalsevice
            .SaveJournalImport(requestArray)
            .then(async (result: any) => {
                if (result !== null) {
                    if (
                        result?.saveStatus.toLowerCase() ===
                        "fail".toLocaleLowerCase()
                    ) {
                        let failMessage = "";
                        let failResponse = result?.message.toLowerCase();
                        if (failResponse === "VendorNotExist".toLocaleLowerCase()) {
                            failMessage = "Vendor does not exist.";
                        } else if (failResponse === "FixedCOA".toLocaleLowerCase()) {
                            failMessage = "Entry can not be made in Fixed COA.";
                        } else if (
                            failResponse === "ConfidentialCOA".toLocaleLowerCase()
                        ) {
                            failMessage =
                                "Transactions with Confidential COAs are not permitted.";
                        } else if (failResponse === "PeriodNotActive".toLocaleLowerCase()) {
                            failMessage = "Please set active accounting period.";
                        } else if (
                            failResponse === "EHIDNotExist".toLocaleLowerCase()
                        ) {
                            failMessage = "Please enter the valid EHID.";
                        } else if (failResponse === "COANotExist".toLocaleLowerCase()) {
                            failMessage = "COA does not exists.";
                        } else if (
                            failResponse === "DebitCreditNotFound".toLocaleLowerCase()
                        ) {
                            failMessage = "Debit or Credit side not found.";
                        } else if (
                            failResponse === "AmountNotMatch".toLocaleLowerCase()
                        ) {
                            failMessage = "Variance Amount should be zero.";
                        }
                        this.setState({ isImportData: false });
                        Utils.toastError(failMessage, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (
                        result?.message.toString().toLowerCase() ===
                        "success".toLocaleLowerCase()
                    ) {
                        toast.success("Data imported successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isImportData: false, isUpdated: true });
                        setTimeout(() => {
                            this.journalModalClose();
                        }, 1500);
                    } else {
                        this.setState({ isImportData: false });
                        Utils.toastError(result?.result.result, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                } else {
                    this.setState({ isImportData: false });
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isImportData: false });
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    };

    splitTablecellEdit = cellEditFactory({
        mode: "click",
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            this.setState({isChanging : true});
            if (oldValue !== newValue) {
                let { journalData } = this.state;
                let dataField = column.dataField;
                newValue = newValue.toString().replace('-', '').replace(/,/g, '').trim();
                if (dataField === "drAmount") {
                    newValue = Utils.removeInvaildCharFromAmount(newValue);
                    if (newValue === "" || newValue === "0") {
                        journalData.map((items) => items.id === row.id && (items[dataField] = Number(0).toFixed(2)));
                    } else {
                        journalData.map((items: any, index: any) => items.id === row.id &&
                            (
                                journalData[index].crAmount = newValue ? Number(0).toFixed(2) : row.crAmount,
                                journalData[index].drAmount = (newValue !== "" && newValue) ? newValue : oldValue
                            ))
                    }


                } else if (dataField === "crAmount") {
                    newValue = Utils.removeInvaildCharFromAmount(newValue);
                    if (newValue === "" || newValue === "0") {
                        journalData.map((items) => items.id === row.id && (items[dataField] = Number(0).toFixed(2)));
                    } else {
                        journalData.map((items: any, index: any) => items.id === row.id &&
                            (
                                journalData[index].drAmount = newValue ? Number(0).toFixed(2) : row.drAmount,
                                journalData[index].crAmount = (newValue !== "" && newValue) ? newValue : oldValue
                            ))
                    }
                } else {
                    if (oldValue !== newValue) {
                        journalData.map((items) => items.id === row.id && (items[dataField] = newValue.trim()));
                    }
                }
                this.setState((curr: any) => ({
                    ...curr,
                    journalData: journalData,
                }), () => {
                    setTimeout(() => {
                        this.isReadyToImport(row);
                        this.hasVariance(journalData, row);
                        this.varianceAmount(journalData, row);
                        this.handleChange(null, row, "prDate"); // for re render the table
                    }, 500);
                });
            }
        },
    });

    tableCellEdit = cellEditFactory({
        mode: "click",
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            if (oldValue !== newValue) {
                let { journalData } = this.state;
                let dataField = column.dataField;
                // if (row.internalNotes.trim().length === 0) {
                //     Utils.toastError("Please enter the internal notes.", {
                //         position: toast.POSITION.BOTTOM_RIGHT,
                //     });
                //     setTimeout(() => { this.setBorderDanger(row, true); }, 500);
                //     return;
                // } else {
                // this.setBorderDanger(row, false);
                journalData.map((items) => items.groupID === row.groupID && (items[dataField] = newValue.trim()));
                //}
                this.setState((curr: any) => ({
                    ...curr,
                    journalData: journalData,
                }), () => {
                    setTimeout(() => { this.isReadyToImport(row); }, 500);
                });
            }
        },
    });

    setBorderDanger = (row, isError) => {
        if (isError)
            $(`#InternalNotes_${row.id}`).addClass('alert-danger');
        else
            $(`#InternalNotes_${row.id}`).removeClass('alert-danger');
    }

    handleRowEvent = (event: any, row: any, type: any) => {
        let { journalData } = this.state;
        if (type === "actPer") {
            journalData = journalData.map((items) => {
                if (items.groupID === row.groupID) {
                    return { ...items, accPer: event, eAccPer: null };
                } else {
                    return items;
                }
            });
        } else if (type === "cusNo") {
            journalData = journalData.map((items) => {
                if (items.groupID === row.groupID) {
                    return { ...items, customerNo: JSON.parse(event).accountNo, isValidCust: true };
                } else {
                    return items;
                }
            });
        }

        this.setState((curr: any) => ({
            ...curr,
            journalData,
        }), () => {
            setTimeout(() => { this.isReadyToImport(row); }, 500);
        });
    };

    handleSelectedItem = (event: any, row: any, type: any, tableType) => {
        if (tableType === "Main") {
            let { journalData } = this.state;
            if (type === "vendor") {
                if (event.length > 0) {
                    let defaultVendor: any = [];
                    let objVend: any = {};
                    objVend.id = event[0].id;
                    objVend.label = event[0].label;
                    defaultVendor.push(objVend);

                    journalData = journalData.map((items) => {
                        if (items.groupID === row.groupID) {
                            row.vid = event[0].id;
                            row.customerNo = "";
                            return {
                                ...items,
                                defaultVendor: defaultVendor,
                                vendorName: event[0].label,
                                eVendorName: null,
                                vid: event[0].id,
                                customerNo: "",
                            };
                        } else {
                            return items;
                        }
                    });
                    this.setState((curr: any) => ({
                        ...curr,
                        journalData,
                    }), () => { this.isReadyToImport(row); });
                } else {
                    journalData = journalData.map((items) => {
                        if (items.groupID === row.groupID) {
                            row.vid = "";
                            row.isErr = false;
                            return {
                                ...items,
                                defaultVendor: [],
                                vendorName: "",
                                eVendorName: "Please select vendor",
                                customerList: [],
                                customerNo: "",
                                vid: ""
                            };
                        } else {
                            return items;
                        }
                    });
                }
            }
            else if (type === "HID") {
                if (event.length > 0) {
                    let defaultHID: any = [];
                    let objHID: any = {};
                    objHID.id = event[0].id;
                    objHID.label = event[0].label;
                    defaultHID.push(objHID);
                    journalData = journalData.map((items) => {
                        if (items.groupID === row.groupID) {
                            row.ehid = event[0].id;
                            row.accPerList = [];
                            return {
                                ...items,
                                defaultHID: defaultHID,
                                ehid: event[0].id,
                                letterCode: event[0].label,
                                eehid: null,
                                accPerList: [],
                                customerList: [],
                                customerNo: "",
                                defaultVendor: [],
                                vendorName: "",
                                eVendorName: "Please select vendor",
                                vid: "",
                            };
                        } else {
                            return items;
                        }
                    });
                } else {
                    journalData = journalData.map((items) => {
                        if (items.groupID === row.groupID) {
                            row.ehid = "";
                            return {
                                ...items,
                                defaultHID: [],
                                ehid: "",
                                letterCode: "",
                                eehid: "Invalid EHID",
                                accPerList: [],
                                customerList: [],
                                customerNo: "",
                                defaultVendor: [],
                                vendorName: "",
                                eVendorName: "Please select vendor",
                                vid: "",
                            };
                        } else {
                            return items;
                        }
                    });
                }
            }
            this.setState((curr: any) => ({
                ...curr,
                journalData,
            }), () => {
                if (row.ehid !== "") {
                    if (type === "HID") {
                        this.GetAccountingPeriod(row, 'x');
                    } else {
                        if (row.vid !== "")
                            this.GetVendorContract(row.vid, row, 'current');
                    }
                } else {
                    this.isReadyToImport(row);
                }
            });
        } else {
            let { journalData } = this.state;
            if (type === "coa") {
                if (event.length > 0) {
                    let defaultCOA: any = [];
                    let objCoa: any = {};
                    objCoa.id = event[0].id;
                    objCoa.label = event[0].label;
                    objCoa.coAname = event[0].coAname;
                    defaultCOA.push(objCoa);
                    journalData = journalData.map((items) => {
                        if (items.id === row.id) {
                            return {
                                ...items,
                                defaultSplitCOA: defaultCOA,
                                coa: event[0].label,
                                ecoa: null
                            };
                        } else {
                            return items;
                        }
                    });
                } else {
                    journalData = journalData.map((items) => {
                        if (items.id === row.id) {
                            return {
                                ...items,
                                defaultSplitCOA: [],
                                coa: "",
                                ecoa: "Please Select COA"
                            };
                        } else {
                            return items;
                        }
                    });
                }
            }
            this.setState((curr: any) => ({
                ...curr,
                journalData,
            }), () => { setTimeout(() => { this.isReadyToImport(row); }, 500); });
        }
    };

    handleChange = (event: any, row: any, FieldType: string) => {
        let { journalData } = this.state;
        if (FieldType === "trDate") {
            let isDate = event === null ? Utils.getHotalCurrentDate() : event;
            let today = new Date(isDate);
            let month =
                today.getMonth() + 1 <= 9
                    ? "0" + (today.getMonth() + 1)
                    : today.getMonth() + 1;
            let day = today.getDate() <= 9 ? "0" + today.getDate() : today.getDate();
            let year = today.getFullYear().toString().substr(-2);
            let dateNew = `${month}/${day}/${year}`;
            journalData = journalData.map((items) => {
                if (items.groupID === row.groupID) {
                    return { ...items, date: dateNew };
                } else {
                    return items;
                }
            });
        }
        //Don't remove this code block (START)///////
        if (FieldType === "prDate") {
            let dd = new Date(new Date(2012, 0, 1).getTime() + Math.random() * (Utils.getHotalCurrentDate().getTime() - new Date(2012, 0, 1).getTime()));
            let today = new Date(dd);
            let month =
                today.getMonth() + 1 <= 9
                    ? "0" + (today.getMonth() + 1)
                    : today.getMonth() + 1;
            let day = today.getDate() <= 9 ? "0" + today.getDate() : today.getDate();
            let year = today.getFullYear().toString().substr(-2);
            let dateNew = `${month}/${day}/${year}`;
            journalData = journalData.map((items) => {
                if (items.groupID === row.groupID) {
                    return { ...items, date1: dateNew };
                } else {
                    return items;
                }
            });
        }
        //////////////////// End ////////////////////

        this.setState((curr: any) => ({
            ...curr,
            journalData,
        }));
    };


    onAttachmentDrop = (uploadAttachments: any, uniqueid: any) => {
        let size = this.state.fileSize//5242880//5MB
        let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024))
        let { journalData } = this.state;
        if (uploadAttachments.length > 0) {
            if (uploadAttachments[0].size > size) {
                Utils.toastError("File is greater than " + filesizeval + "MB.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                let hasAttachmentMain: any = [];
                uploadAttachments.forEach((items, index) => {
                    hasAttachmentMain.push(items);
                });
                journalData = journalData.map((items) => {
                    if (items.id === clickedRow[0].id) {
                        return {
                            ...items,
                            attachmentMain: items.attachmentMain.concat(hasAttachmentMain)
                        };
                    } else {
                        return items;
                    }
                });
                $("#addAttachment").val("");
                this.setState((curr: any) => ({ ...curr, journalData: journalData, uploadAttachments }));
            }
        } else {
            Utils.toastError("Only .xlsx,.xls, and .csv file is acceptable.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };

    clickedRow = (row) => {
        clickedRow = [];
        clickedRow.push(row);
    }

    notesIcon() {
        return (
            <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                height="3em"
                width="3em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"></path>
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"></path>
            </svg>
        );
    }

    deleteCurrentAttachment = (rowIndex, row) => {
        let { journalData } = this.state;
        confirmAlert({
            title: "Delete file",
            message: "Are you sure you want to delete this file?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        journalData = journalData.map((items) => {
                            if (items.id === row.id) {
                                return {
                                    ...items,
                                    attachmentMain: items.attachmentMain.filter((item, index) => rowIndex !== index)
                                };
                            } else {
                                return items;
                            }
                        });
                        setTimeout(() => {
                            this.setState((curr: any) => ({
                                ...curr,
                                journalData: journalData,
                            }));
                            journalData.forEach((items) => {
                                if (items.id === row.id) {
                                    if (items.attachmentMain.length === 0) {
                                        this.setState({ uploadAttachments: [] });
                                    }
                                }
                            });
                        }, 1000);
                    },
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
            ],
        });
    };

    renderEHID = (cell: any, row: any, rowIndex: any) => {
        return (
            <div className="more-action single-select-image dropdown coa-select jecoaSelect JorTypeheaddropdown">
                <div
                    className={
                        row.defaultHID.length > 0
                            ? ""
                            : "typeaheadBorderDanger"
                    }
                >
                    <Typeahead
                        flip={true}
                        inputProps={{ className: "coa" + rowIndex.toString() }}
                        id={"coa-" + rowIndex.toString()}
                        options={this.state.hidList}
                        placeholder={"Select EHID"}
                        renderMenu={(results: any, menuProps) => (
                            <Menu {...menuProps}>
                                {results.map((result: any, index: any) => (
                                    <>
                                        <MenuItem option={result} position={index}>
                                            <EllipsisWithTooltip placement="bottom">
                                                {result.label}
                                            </EllipsisWithTooltip>
                                        </MenuItem>
                                        {result.label == "" && (
                                            <MenuItem
                                                className="load-more"
                                                option={result}
                                                position={index}
                                            >
                                                Load More
                                            </MenuItem>
                                        )}
                                        {this.state.hidList.length > 0 &&
                                            results[index - 1]?.orderno === 1 &&
                                            result?.orderno === 2 && <Dropdown.Divider />}
                                    </>
                                ))}
                            </Menu>
                        )}
                        renderMenuItemChildren={this.renderTypeaheadChildren}
                        onChange={(e) =>
                            this.handleSelectedItem(e, row, "HID", "Main")
                        }
                        selected={row.defaultHID}
                    />
                </div>
            </div>
        );
    }

    renderVendor = (cell: any, row: any, rowIndex: any) => {
        if (row.ehid !== "") {
            return (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id={"VendorName"}>
                            {row.eVendorName === null || row.eVendorName === undefined || row.eVendorName === "" ? row.defaultVendor[0]?.label : row.eVendorName}
                        </Tooltip>
                    }>
                    <div className="more-action single-select-image dropdown coa-select jecoaSelect JorTypeheaddropdown">
                        <div
                            className={
                                row.defaultVendor.length > 0
                                    ? ""
                                    : "typeaheadBorderDanger"
                            }
                        >
                            <Typeahead
                                flip={true}
                                inputProps={{
                                    className: "vendor" + rowIndex.toString(),
                                }}
                                id={"vendor-" + rowIndex.toString()}
                                options={this.state.vendorListMain}
                                placeholder={"Select Vendor"}
                                renderMenu={(results: any, menuProps) => (
                                    <Menu {...menuProps}>
                                        {results.map((result: any, index: any) => (
                                            <>
                                                <MenuItem option={result} position={index}>
                                                    <EllipsisWithTooltip placement="bottom">
                                                        {result.vendor}
                                                    </EllipsisWithTooltip>
                                                </MenuItem>
                                                {result.label == "" && (
                                                    <MenuItem
                                                        className="load-more"
                                                        option={result}
                                                        position={index}
                                                    >
                                                        Load More
                                                    </MenuItem>
                                                )}
                                                {this.state.vendorListMain.length > 0 &&
                                                    results[index - 1]?.orderno === 1 &&
                                                    result?.orderno === 2 && (
                                                        <Dropdown.Divider />
                                                    )}
                                            </>
                                        ))}
                                    </Menu>
                                )}
                                renderMenuItemChildren={this.renderTypeaheadChildren}
                                onChange={(e) =>
                                    this.handleSelectedItem(e, row, "vendor", "Main")
                                }
                                selected={row.defaultVendor}
                            />
                        </div>
                    </div>
                </OverlayTrigger>
            );
        } else {
            return (
                <Form.Control
                    disabled={true}
                    type="text" placeholder="Vendor" />
            );
        }
    }

    renderAccPer = (cell: any, row: any, rowIndex: any) => {
        return (
            <div className="singleSelect importDropdown importJourDropdSmall"
                onClick={(e) => { this.GetAccountingPeriod(row, `actDrop_${row.id}`); }}
            >
                <Dropdown
                    className={
                        row.eAccPer === null
                            ? "w-110 more-action single-select-image"
                            : "ddBorderDanger w-110 more-action single-select-image"
                    }
                    onSelect={(e) => { this.handleRowEvent(e, row, "actPer"); }}
                >
                    <Dropdown.Toggle id={`actDrop_${row.id}`} className="filter-btn" disabled={row.ehid === "" || row.ehid === null ? true : false}>
                        <div className="d-flex align-items-center">
                            <div className="txt-area">
                                <EllipsisWithTooltip placement="bottom">
                                    {row.accPer}
                                </EllipsisWithTooltip>
                            </div>
                            <div className="drop-arrow ml-0 d-flex">
                                <FiChevronDown />
                            </div>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {row.accPerList.map((item: any, idx: any) => (
                            <Dropdown.Item eventKey={item.name} key={idx}>
                                <EllipsisWithTooltip placement="bottom">
                                    {item.name}
                                </EllipsisWithTooltip>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>


            </div>);
    }

    renderCOA = (cell: any, row: any, rowIndex: any) => {
        return (<div className="more-action single-select-image dropdown coa-select jecoaSelect JorTypeheaddropdown">
            <div
                className={
                    row.defaultSplitCOA.length > 0
                        ? ""
                        : "typeaheadBorderDanger"
                }
            >
                <Typeahead
                    flip={true}
                    inputProps={{ className: "coa" + rowIndex.toString() }}
                    id={"coa-" + rowIndex.toString()}
                    options={this.state.coaList}
                    placeholder={"Enter COA"}
                    renderMenu={(results: any, menuProps) => (
                        <Menu {...menuProps}>
                            {results.map((result: any, index: any) => (
                                <>
                                    <MenuItem option={result} position={index}>
                                        <EllipsisWithTooltip placement="bottom">
                                            {result.label}
                                        </EllipsisWithTooltip>
                                    </MenuItem>
                                    {result.label == "" && (
                                        <MenuItem
                                            className="load-more"
                                            option={result}
                                            position={index}
                                        >
                                            Load More
                                        </MenuItem>
                                    )}
                                    {this.state.coaList.length > 0 &&
                                        results[index - 1]?.orderno === 1 &&
                                        result?.orderno === 2 && <Dropdown.Divider />}
                                </>
                            ))}
                        </Menu>
                    )}
                    renderMenuItemChildren={this.renderTypeaheadChildren}
                    onChange={(e) =>
                        this.handleSelectedItem(e, row, "coa", "Split")
                    }
                    selected={row.defaultSplitCOA}
                />
            </div>

        </div>);
    }

    renderTypeaheadChildren = (option, props, index) => {
        return (
            <>
                <div key={option.id}>
                    <div className="vendor-name">
                        <EllipsisWithTooltip placement="bottom">
                            {option.label}
                        </EllipsisWithTooltip>
                    </div>
                </div>
            </>
        );
    };

    renderAmmount = (cell: any, row: any, rowIndex: any) => {
        let decimalAmount = row.amount.toFixed(2);
        return (
            <div className="hasPaymentError text-right">
                <EllipsisWithTooltip placement="bottom">
                    {decimalAmount}
                </EllipsisWithTooltip>
            </div>
        );
    }

    isReadyToImport = (row: any) => {
        let { journalData } = this.state;
        journalData.map((items) => {
            if (items.groupID === row.groupID) {
                if (items.eVendorName === null && items.eAccPer === null && items.eehid === null && items.ecoa === null && items.eAmount === null && items.ehid !== "" && items.vendorName !== "" /*&& items.internalNotes !== ""*/ && items.customerNo !== "" && items.customerNo !== "More" && items.isVariance !== true) {
                    items.isErr = false;
                } else {
                    items.isErr = true;
                }
            }
        });
        this.setState((curr: any) => ({ ...curr, journalData: journalData, isChanging: false }));
    };

    GetVendorContract = (vid, row, type) => {
        if (row.customerList.length === 0) {
            let { journalData } = this.state;
            journalData = journalData.map((items) => {
                if (items.groupID === row.groupID) {
                    return { ...items, isVandorSpinner: true, };
                } else {
                    return items;
                }
            });
            this.setState((curr: any) => ({ ...curr, journalData, isVandorSpinnerPanel: true }));
            let request: any = {};
            request.hotelID = row.ehid; //this.state.hidValue;
            request.vendorType = "Current";
            request.VendorId = vid;
            registersevice.GetVendorContract(request)
                .then(async (result: any) => {
                    if (result.length > 0) {
                        if (result.length === 1) {
                            journalData = journalData.map((items) => {
                                if (items.groupID === row.groupID) {
                                    return { ...items, customerList: result, customerNo: result[0].accountNo, isVandorSpinner: false };
                                } else {
                                    return items;
                                }
                            });
                        } else if (result.length > 1) {
                            journalData = journalData.map((items) => {
                                if (items.groupID === row.groupID) {
                                    items.customerNo = 'Select';
                                    return { ...items, customerList: result, customerNo: 'Select', isVandorSpinner: false };
                                } else {
                                    return items;
                                }
                            });
                            this.setState((curr: any) => ({ ...curr, journalData, isVandorSpinnerPanel: false }), () => {
                                setTimeout(() => { this.isReadyToImport(row); }, 500);
                            });
                        } else {
                            journalData = journalData.map((items) => {
                                if (items.groupID === row.groupID) {
                                    return { ...items, customerList: result, customerNo: '', isVandorSpinner: false };
                                } else {
                                    return items;
                                }
                            });
                        }
                        this.setState((curr: any) => ({ ...curr, journalData, isVandorSpinnerPanel: false }), () => {
                            setTimeout(() => { this.isReadyToImport(row); }, 500);
                        });
                    } else {
                        journalData = journalData.map((items) => {
                            if (items.groupID === row.groupID) {
                                return { ...items, customerNo: "", customerList: [], isVandorSpinner: false };
                            } else {
                                return items;
                            }
                        });
                        this.setState((curr: any) => ({ ...curr, journalData, isVandorSpinnerPanel: false }), () => {
                            if (type === "custNo") {
                                this.addNewCustomerNo(row);
                            } else {
                                setTimeout(() => { this.isReadyToImport(row); }, 500);
                            }
                        });
                    }
                    resolve();
                })
                .catch((error) => {
                    Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    reject();
                });
        }
    }

    addNewCustomerNo = (row: any) => {
        this.setState({ editedRow: row }, () => {
            if (this.vendorSearch?.current !== null) {
                this.setState(
                    prevState => ({ currentRowHid: row.ehid }),
                    () => {
                        setTimeout(() => { this.vendorSearch?.current.importFromJournal(row.vendorName, row.vid, "current"); }, 500);
                    }
                );
            }
        })
    }

    updateCustomerList = (newCustList) => {
        this.updateNewCustomerList(newCustList);
    }

    updateNewCustomerList = (newCustList) => {
        let { journalData, editedRow } = this.state;
        let request: any = {};
        request.hotelID = editedRow.ehid;
        request.vendorType = "Current";
        request.VendorId = editedRow.vid;
        registersevice.GetVendorContract(request)
            .then(async (result: any) => {
                if (result.length > 0) {
                    journalData = journalData.map((items) => {
                        if (items.groupID === editedRow.groupID) {
                            return { ...items, customerList: result, customerNo: newCustList.accountNo };
                        } else {
                            return items;
                        }
                    });
                    this.setState((curr: any) => ({ ...curr, journalData }), () => {
                        //setTimeout(() => { this.finalExSet(editedRow) }, 500);
                    });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    hasVariance = (journalData, row) => {
        if (row === null) {
            journalData.forEach((ccd: any, idx: any) => {
                let splitData = this.state.journalData.filter((items: any) => items.groupID === ccd.groupID);
                let totalCrValue = splitData.reduce(function (cnt, o) { return cnt + parseFloat(o.crAmount.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "")); }, 0);
                let totalDrValue = splitData.reduce(function (cnt, o) { return cnt + parseFloat(o.drAmount.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "")); }, 0);
                if ((parseFloat(totalCrValue).toFixed(2) === parseFloat(totalDrValue).toFixed(2))) {
                    journalData[idx].isVariance = false;
                } else {
                    journalData[idx].isVariance = true;
                }
            });
        } else {
            let splitData = journalData.filter((items: any) => items.groupID === row.groupID);
            let totalCrValue = splitData.reduce(function (cnt, o) { return cnt + parseFloat(o.crAmount.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "")); }, 0);
            let totalDrValue = splitData.reduce(function (cnt, o) { return cnt + parseFloat(o.drAmount.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "")); }, 0);
            let isValid = true;
            if ((parseFloat(totalCrValue).toFixed(2) === parseFloat(totalDrValue).toFixed(2)) || (Number(totalCrValue) - Number(totalDrValue)).toString() === "0.00") {
                isValid = false;
            } else {
                isValid = true;
            }
            journalData.map((items: any, index: any) => items.groupID === row.groupID &&
                (
                    journalData[index].isVariance = isValid
                ))
            this.setState((curr: any) => ({
                ...curr,
                journalData: journalData,
            }), () => {
                setTimeout(() => {
                    this.isReadyToImport(row);
                }, 500);
            });
        }
    };

    varianceAmount = (journalData, row) => {
        if (row === null) {
            journalData.forEach((ccd: any, idx: any) => {
                let splitData = this.state.journalData.filter((items: any) => items.groupID === ccd.groupID);
                let totalCrValue = splitData.reduce(function (cnt, o) { return cnt + parseFloat(o.crAmount.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "")); }, 0);
                let totalDrValue = splitData.reduce(function (cnt, o) { return cnt + parseFloat(o.drAmount.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "")); }, 0);
                let varianceAmnt = (parseFloat(totalCrValue) - parseFloat(totalDrValue));
                journalData[idx].varianceAmount = varianceAmnt.toFixed(2);
            });
        } else {
            let splitData = journalData.filter((items: any) => items.groupID === row.groupID);
            let totalCrValue = splitData.reduce(function (cnt, o) { return cnt + parseFloat(o.crAmount.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "")); }, 0);
            let totalDrValue = splitData.reduce(function (cnt, o) { return cnt + parseFloat(o.drAmount.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "")); }, 0);
            let varianceAmnt = (parseFloat(totalCrValue) - parseFloat(totalDrValue));
            journalData.map((items: any, index: any) => items.groupID === row.groupID &&
                (
                    journalData[index].varianceAmount = varianceAmnt.toFixed(2)
                ))
        }
    };

    internalNotesValue = (cell, row, rowIndex) => {
        // if (row.internalNotes === "") {
        //     return (
        //         <Form.Group className="InternalNotesImp">
        //             <Form.Control
        //                 maxLength={200}
        //                 type="text"
        //                 id={`InternalNotes_${row.id}`}
        //             />
        //         </Form.Group>
        //     );
        // } else {
        return (
            <div>
                <EllipsisWithTooltip placement="bottom">
                    {row.internalNotes}
                </EllipsisWithTooltip>
            </div>
        );
        // }
    };

    renderCustomerNo = (cell: any, row: any, rowIndex: any) => {
        let hasValidVendor = this.state.vendorListMain.some((item: any) => item.vendor.toLowerCase() === row.vendorName.toLowerCase());
        if (hasValidVendor && row.ehid !== "") {
            return (
                <div className="isCusNoField hasVendorSearch posStatic">
                    {(row.customerList.length > 0) &&
                        (
                            <div className={row.customerNo === "" || !row.isValidCust ?
                                "ddBorderDanger singleSelect importDropdown importDropdownSmall hasCustomerList hasFixButton" :
                                "singleSelect importDropdown importDropdownSmall hasCustomerList hasFixButton"}
                            >
                                <Dropdown
                                    className="w-110 more-action single-select-image posUnset">
                                    <Dropdown.Toggle id="Modal-hid" className="filter-btn posRelative"
                                        disabled={row.vendorName.toLowerCase() === "generic vendor" ? true : false}>
                                        <div className="d-flex align-items-center">
                                            <div className="txt-area">
                                                <EllipsisWithTooltip placement="bottom">
                                                    {row.vendorName.toLowerCase() === "generic vendor" ? "" :
                                                        !row.isValidCust ? "Select" : row.customerNo}</EllipsisWithTooltip>
                                            </div>
                                            <div className="drop-arrow ml-0 d-flex">
                                                <FiChevronDown />
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="scrollableArea">
                                            {row.customerList !== undefined && row.customerList.map((item: any, idx: any) => (
                                                <Dropdown.Item eventKey={JSON.stringify(item)} key={idx} onSelect={(e) => this.handleRowEvent(e, row, "cusNo")}>
                                                    <EllipsisWithTooltip placement="bottom">{item.accountNo}</EllipsisWithTooltip>
                                                </Dropdown.Item>
                                            ))}
                                        </div>
                                        {this.state.isAddContractPerm && (
                                            <Dropdown.Item onClick={(e) => this.addNewCustomerNo(row)} style={{ borderTop: "1px solid #e0e3e6" }}>
                                                <EllipsisWithTooltip placement="bottom"
                                                ><strong style={{ color: "#2caf92" }}>Add New Contract</strong> </EllipsisWithTooltip>
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )}
                    {row.customerNo === "" && row.customerList.length == 0 && (
                        <div onClick={(e) => this.GetVendorContract(row.vid, row, "custNo")}
                            className={this.state.isAddContractPerm ?
                                "ddBorderDanger singleSelect importDropdown importDropdownSmall hasCustomerList" :
                                "disabled-area ddBorderDanger singleSelect importDropdown importDropdownSmall hasCustomerList"}>
                            <Dropdown className="w-110 more-action single-select-image posUnset">
                                <Dropdown.Toggle id="Modal-hid" className="filter-btn posRelative">
                                    <div className="d-flex align-items-center">
                                        <div className="txt-area">
                                            <EllipsisWithTooltip placement="bottom">
                                                {row.customerNo === ""
                                                    ? "Add" : row.customerNo}
                                            </EllipsisWithTooltip>
                                        </div>
                                        <div className="add-icon ml-0 d-flex">
                                            {row.isVandorSpinner ? (
                                                <Spinner size="sm" variant="success" animation="border" />) : (
                                                <svg stroke="currentColor" fill="#2caf92" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
                                                </svg>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </div>
                    )}
                </div>
            )
        }
        else {
            return (
                <Form.Control
                    disabled={!hasValidVendor || row.ehid === ""}
                    className={!hasValidVendor || row.ehid === "" ? "border-danger" : ""}
                    value={row.customerNo === "More" ? "" : row.customerNo}
                    type="text" placeholder="Cust. No" />
            )
        }
    }

    render() {
        let { journalData } = this.state;
        let isNotValidImport = false;
        let isAllZero = false;
        if (journalData.length > 0) {
            isNotValidImport = journalData.some((item: any) => item.isErr === true || item.customerNo === "" || item.customerNo.toLowerCase() === "Select" || !item.isValidCust || item.isVariance === true /*|| (item.crAmount === "0.00" && item.drAmount === "0.00")*/);
            let zeroCRDR = journalData.filter((item: any) => item.crAmount === "0.00" && item.drAmount === "0.00");
            if (zeroCRDR.length === journalData.length)
                isAllZero = true;
        }

        const files = this.state.uploadFiles.map((file) => (
            <li key={file.name}>
                <div className="d-flex align-items-center">
                    <div className="file-name">{file.name}</div>
                    <div className="file-loader mr-auto">
                        <ProgressBar variant="success" now={this.state.isProgress} />
                    </div>
                    <div className="file-percentage">{this.state.isProgress} %</div>
                </div>
            </li>
        ));

        const getRenderChild = (registerList, row) => {
            return registerList.filter(
                (splist) => row.groupID === splist.groupID
            );
        };

        const renderChild = (rowIndex, row) => {
            let { journalData } = this.state;
            let splitlist = [];
            let uniqueID = Math.floor(Math.random() * 90 + 10);
            let uniqueID123 = Math.floor(Math.random() * 90 + 10);
            splitlist = getRenderChild(journalData, row);
            return (
                <div className="nestedJourChildTable">
                    <div className="childHeader">
                        <strong>Splits</strong>
                    </div>
                    <BootstrapTable
                        key={uniqueID}
                        id={uniqueID}
                        keyField="id"
                        data={splitlist}
                        columns={columnChild}
                        cellEdit={this.splitTablecellEdit}
                    />
                    <div className="mt-3 mb-3 childHeader">
                        <strong>Attachments</strong>
                    </div>
                    {row.attachmentMain.length > 0 ? (
                        <>
                            <div className="attachmentTable">
                                <BootstrapTable
                                    key={uniqueID123}
                                    id={uniqueID123}
                                    keyField="id"
                                    data={row.attachmentMain}
                                    columns={columnAttachments(row)}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="JourUploadSection">
                                <Dropzone
                                    onDrop={(e) => this.onAttachmentDrop(e, row.id)}
                                    accept="image/*,application/pdf,
                                                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                                application/vnd.ms-excel,
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                text/plain,
                                                .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                >
                                    {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                        isDragReject,
                                    }) => (
                                        <>
                                            <section
                                                onClick={(e) => this.clickedRow(row)}
                                                className={
                                                    isDragActive
                                                        ? "upload-area default drag-active "
                                                        : "upload-area default"
                                                }
                                            >
                                                <div
                                                    {...getRootProps({
                                                        className: "dropzone d-flex align-items-center",
                                                    })}
                                                >
                                                    <input {...getInputProps()} />
                                                    <p>
                                                        Drag and drop or <span>browse files</span>{" "}
                                                        to upload
                                                    </p>
                                                </div>
                                            </section>
                                        </>
                                    )}
                                </Dropzone>
                            </div>
                        </>
                    )}
                </div>
            );
        };
        const columnAttachments = (mainRow) => [
            {
                dataField: "attachment",
                headerClasses: "attachmentCol",
                text: "",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return <>{this.notesIcon()}</>;
                },
            },
            {
                dataField: "name",
                text: "Name",
                headerClasses: "fileNameCol",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <>
                            <EllipsisWithTooltip placement="bottom">{row.name.split(/\.(?=[^\.]+$)/)[0]}</EllipsisWithTooltip>
                            <div className="dateSection"><EllipsisWithTooltip placement="bottom">{new Date(row.lastModified).toLocaleDateString()}</EllipsisWithTooltip></div>
                        </>
                    )
                },
            },
            {
                dataField: "type",
                headerClasses: "fileTypeCol",
                text: "File Type",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom"><span className="badge badgeOrange">{row.path.split(/\.(?=[^\.]+$)/)[1]}</span></EllipsisWithTooltip>
                    )
                },
            },
            {
                dataField: "action",
                text: "",
                headerClasses: "iconCol",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="deleteAttach">Delete Attachment</Tooltip>}
                        >
                            <button type="button"
                                tabIndex={-1}
                                onClick={(e) => { this.deleteCurrentAttachment(rowIndex, mainRow) }}
                                className="btn btn-primary fa-trash">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="trashSVGIcon">
                                    <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path><path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                                </svg>
                            </button>
                        </OverlayTrigger>

                    );
                },
            },
        ];

        const columnChild = [
            {
                dataField: "coa",
                text: "COA #",
                editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    if (row.ecoa !== null) {
                        return (
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(row.ecoa)}
                            >
                                <div onWheel={hoverOutEllipsis.bind(this)}>
                                    {this.renderCOA(cell, row, rowIndex)}

                                </div>
                            </OverlayTrigger>
                        );
                    } else {
                        return (
                            <div>
                                {this.renderCOA(cell, row, rowIndex)}
                            </div>
                        );
                    }
                },
            },
            {
                dataField: "description",
                text: "Description",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">
                            {row.description}
                        </EllipsisWithTooltip>
                    );
                },
            },
            {
                dataField: "drAmount",
                text: "Debit",
                headerClasses: "text-right",
                // classes: (cell: any, row: any, rowIndex: any) => {
                //     if (parseFloat(row.crAmount) === 0.00 && parseFloat(row.drAmount) === 0.00) {
                //         return "JournalAmountZero";
                //     } else {
                //         return "";
                //     }
                // },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">
                            {Utils.currencyFormatWithOutdoller(row.drAmount)}
                        </EllipsisWithTooltip>
                    );
                },
            },
            {
                dataField: "crAmount",
                text: "Credit",
                headerClasses: "text-right",
                // classes: (cell: any, row: any, rowIndex: any) => {
                //     if (parseFloat(row.crAmount) === 0.00 && parseFloat(row.drAmount) === 0.00) {
                //         return "JournalAmountZero";
                //     } else {
                //         return "";
                //     }
                // },
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <EllipsisWithTooltip placement="bottom">
                            {Utils.currencyFormatWithOutdoller(row.crAmount)}
                        </EllipsisWithTooltip>
                    );
                },
            },
        ];

        const handleOnExpand = (row, isExpand, rowIndex, e) => {
            if (isExpand) {
                expandedRows.push(row.id);
            } else {
                expandedRows.pop(row.id);
            }
        }

        const expandSplitRow = {
            renderer: (row: any, rowIndex: any) => renderChild(rowIndex, row),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expanded: expandedRows,
            onExpand: handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <div></div>;
                }
                return <div></div>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="chevronExpended">
                            <img src={chevronRight} alt="chevron Right" />
                        </span>
                    );
                }
                return (
                    <span className="chevronCollpsed">
                        <img src={chevronRight} alt="chevron Right" />
                    </span>
                );
            },
        };

        const hoverOutEllipsis = () => {
            $(".tooltip").removeClass("show");
        };

        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                {props}
            </Tooltip>
        );

        const JournalColumns = [
            {
                dataField: "letterCode",
                text: "EHID",
                editable: false,
                headerClasses: "defaultColWidth2",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    if (row.eehid !== null) {
                        return (
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(row.eehid)}
                            >
                                <div onWheel={hoverOutEllipsis.bind(this)}>
                                    {this.renderEHID(cell, row, rowIndex)}
                                </div>
                            </OverlayTrigger>
                        );
                    } else {
                        return (
                            <div onWheel={hoverOutEllipsis.bind(this)}>
                                {this.renderEHID(cell, row, rowIndex)}
                            </div>
                        );
                    }




                },
            },
            {
                dataField: "vendorName",
                text: "Vendor",
                editable: false,
                headerClasses: "defaultColWidth5",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    if (row.eVendorName !== null) {
                        return (
                            // <OverlayTrigger
                            //     placement="top"
                            //     delay={{ show: 250, hide: 400 }}
                            //     overlay={renderTooltip(row.eVendorName)}
                            // >
                            <div onWheel={hoverOutEllipsis.bind(this)}>
                                <div className="ddBorderDanger">
                                    {this.renderVendor(cell, row, rowIndex)}
                                </div>
                            </div>
                            // </OverlayTrigger>
                        );
                    } else {
                        return (
                            <div onWheel={hoverOutEllipsis.bind(this)}>
                                {this.renderVendor(cell, row, rowIndex)}
                            </div>
                        );
                    }
                },
            },
            {
                dataField: "customerNo",
                text: "Cust. No",
                editable: false,
                headerClasses: "defaultColWidth",
                classes: "defaultColWidth",
                headerFormatter: this.headerFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    if (row.customerNo === null || row.customerNo === "" || !row.isValidCust) {
                        return (
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip("Please select Cust. No")}
                            >
                                <div onWheel={hoverOutEllipsis.bind(this)}>
                                    <div className="ddBorderDanger">
                                        {this.renderCustomerNo(cell, row, rowIndex)}
                                    </div>
                                </div>
                            </OverlayTrigger>
                        );
                    } else {
                        return (
                            <div onWheel={hoverOutEllipsis.bind(this)}>
                                {this.renderCustomerNo(cell, row, rowIndex)}
                            </div>
                        );
                    }
                }

            },
            {
                dataField: "date",
                text: "Date",
                editable: false,
                headerClasses: "defaultColWidth2",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="date-picker calender-picker importJourDropdSmall">
                            <label htmlFor="trdate" className="drop-arrow">
                                <svg
                                    width="8"
                                    height="6"
                                    viewBox="0 0 8 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z"
                                        fill="#3F4347"
                                    />
                                </svg>
                            </label>
                            <DatePicker
                                id="trdate"
                                ref={this.trdate}
                                className={
                                    row.date === ""
                                        ? "border-danger form-control"
                                        : "form-control"
                                }
                                placeholderText={"MM/DD/YY"}
                                dateFormat="MM/dd/yy"
                                autoComplete="off"
                                selected={row.date === "" ? "" : new Date(row.date)}
                                onSelect={(event: any) =>
                                    this.handleChange(event, row, "trDate")
                                }
                            // onChange={(event: any) =>
                            //     this.handleChange(event, row, "trDate")
                            // }
                            />
                        </div>
                    );
                },
            },
            {
                dataField: "accPer",
                text: "Act. Per",
                editable: false,
                headerFormatter: this.headerFormatter,
                headerClasses: "defaultColWidth2",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    if (row.eAccPer === null) {
                        return (
                            <>
                                {this.renderAccPer(cell, row, rowIndex)}
                            </>
                        );
                    } else {
                        return (
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(row.eAccPer)}
                            >
                                {this.renderAccPer(cell, row, rowIndex)}
                            </OverlayTrigger>
                        );
                    }
                },
            },
            {
                dataField: "internalNotes",
                text: "Internal Notes",
                headerClasses: "defaultColWidth5",
                formatter: this.internalNotesValue,
            },
            {
                dataField: "amount",
                text: "Variance",
                headerClasses: "text-right",
                editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="hasPaymentError text-right">
                            <EllipsisWithTooltip placement="bottom">
                                {row.isVariance ? <span style={{ color: "#dc3545" }}>
                                    {Utils.currencyFormatWithOutdoller(row.varianceAmount)}
                                </span> : <span>{Utils.currencyFormatWithOutdoller(row.varianceAmount)}</span>}

                            </EllipsisWithTooltip>
                        </div>
                    );
                },
            },
            {
                dataField: "action",
                text: "",
                editable: false,
                headerFormatter: this.headerFileFormatter,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <>
                            {row.attachmentMain.length > 0 && (
                                <div className="btnDropzone">
                                    <label>
                                        <FiPaperclip /> {row.attachmentMain.length}
                                    </label>
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                dataField: "action",
                text: "",
                editable: false,
                headerClasses: "lastChildColWidth",
                classes: "lastChildColWidth",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <button
                            type="button"
                            className="btn btn-primary fa-trash btnDropzone"
                        >
                            <div className="btnDropzone">
                                <Dropzone
                                    onDrop={(e) => this.onAttachmentDrop(e, row.id)}
                                    accept="image/*,application/pdf,
                                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                    application/vnd.ms-excel,
                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                    text/plain,
                                    .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                    key={row.id}
                                >
                                    {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                        isDragReject,
                                    }) => <input id="addAttachment"  {...getInputProps()} />}
                                </Dropzone>
                                <label htmlFor="addAttachment" onClick={(e) => this.clickedRow(row)}>
                                    <FiPaperclip />
                                </label>
                            </div>
                        </button>
                    );
                },
            },
            {
                dataField: "action",
                text: "",
                editable: false,
                headerClasses: "lastChildColWidth",
                classes: "lastChildColWidth",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="DeleteRow">Delete Row</Tooltip>}
                        >
                            <button type="button"
                                disabled={_.uniqBy(this.state.journalData, "groupID").length < 2}
                                onClick={(e) => { this.deleteCurrentRow(row) }}
                                className="btn btn-primary fa-trash">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="trashSVGIcon">
                                    <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path><path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                                </svg>
                            </button>
                        </OverlayTrigger>
                    );
                },
            },
        ];

        const journalImportLoader = () => {
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <>
                <ToastContainer autoClose={3000} enableMultiContainer />
                <Modal
                    className={
                        this.state.isUploadedJournal
                            ? "import-credit-card-result-modal importJournalModal"
                            : "import-credit-card-modal"
                    }
                    centered
                    show={this.state.importJournalModal}
                    onHide={this.journalModalClose}
                    backdrop="static"
                    dialogClassName={
                        this.state.isUploadedJournal ? "modal-100w" : "modal-60w"
                    }
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.isUploadedJournal ? (
                                <>
                                    Import
                                    <div className="sub-heading">
                                        {this.state.uploadFiles[0]?.path}
                                    </div>
                                </>
                            ) : (
                                <>Import</>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            {!this.state.isUploadedJournal ? (
                                <Dropzone
                                    onDrop={this.onDrop}
                                    accept=".xlsx,.xls,.csv"
                                    multiple={false}
                                >
                                    {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                        isDragReject,
                                    }) => (
                                        <>
                                            {this.state.uploadFiles.length == 0 && (
                                                <>
                                                    <section
                                                        className={
                                                            isDragActive
                                                                ? "container upload-area default drag-active "
                                                                : "container upload-area default"
                                                        }
                                                    >
                                                        <div
                                                            {...getRootProps({
                                                                className: "dropzone d-flex align-items-center",
                                                            })}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <p>
                                                                Drag and drop file here to upload
                                                                <br />
                                                                <strong className="acceptedFiles">
                                                                    .xls, .xlsx or .csv
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </section>
                                                    <div className="DownLoadTemplateLink">
                                                        <a className="templateLink" onClick={this.downloadTemplate}>Download Template</a>
                                                    </div>
                                                </>
                                            )}
                                            {this.state.uploadFiles.length > 0 && (
                                                <aside className="d-flex flex-column file-list">
                                                    <ul>{files}</ul>
                                                </aside>
                                            )}
                                        </>
                                    )}
                                </Dropzone>
                            ) : (
                                <>
                                    <ToolkitProvider
                                        keyField="id"
                                        data={_.uniqBy(this.state.journalData, "groupID")}
                                        columns={JournalColumns}
                                        search
                                    >
                                        {(props: {
                                            searchProps: JSX.IntrinsicAttributes;
                                            baseProps: JSX.IntrinsicAttributes;
                                        }) => (
                                            <div
                                                className={
                                                    this.state.isImportData ? "disabled-area" : ""
                                                }
                                            >
                                                <div className="steps">
                                                    <div className="filter-sec">
                                                        <div className="d-flex">
                                                            <div className="search-header">
                                                                <SearchBar
                                                                    {...props.searchProps}
                                                                    placeholder={"Search..."}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="JournalNavTabs">
                                                    <div className="grid-sec cusGridSec2">
                                                        <div className="scroll-area importedJournalTable table-responsive"
                                                        >
                                                            {/* {JSON.stringify(this.state.journalData)} */}
                                                            {this.state.loader ? <React.Fragment><>{journalImportLoader()}</><>{journalImportLoader()}</></React.Fragment> :
                                                                <BootstrapTable
                                                                    {...props.baseProps}
                                                                    keyField="id"
                                                                    hover
                                                                    noDataIndication={"No record found."}
                                                                    expandRow={expandSplitRow}
                                                                    cellEdit={this.tableCellEdit}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )}
                                    </ToolkitProvider>
                                </>
                            )}
                        </>
                    </Modal.Body>
                    {this.state.isUploadedJournal && (
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={this.handleBackScreen}
                                className="btn-outline-primary btn btn-primary mr-auto"
                            >
                                Back
                            </button>
                            <Button
                                variant="primary"
                                disabled={
                                    this.state.isImportData ||
                                    isNotValidImport || isAllZero || this.state.isChanging
                                }
                                onClick={this.handleImportData}
                            >
                                {this.state.isImportData && (
                                    <>
                                        <Spinner size="sm" animation="border" />{" "}
                                    </>
                                )}{" "}
                                Import Data
                            </Button>
                        </Modal.Footer>
                    )}
                    <div className="isCusNoField hasVendorSearch">
                        <VendorSearch
                            ref={this.vendorSearch}
                            updateVendorList={this.updateVendorList}
                            handleVendorChange={this.handleVendorChange}
                            getCustomerNo={this.getCustomerNo}
                            HID={this.state.currentRowHid}
                            callFromPage="CreditCardStatementImport"
                            updateCustomerList={this.updateCustomerList}
                        />
                    </div>
                </Modal>
            </>

        );
    }
    updateVendorList = (result: any) => { }
    handleVendorChange = (vendor: any) => { }
    getCustomerNo = (result: any) => { }
}
