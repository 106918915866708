import React from "react";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Utils } from "../../../Common/Utilis";
import moment from "moment";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { Hotel } from "../../../Common/Services/Hotel";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { IConferenceRoomRequest } from "../../../Common/Contracts/IConferenceRoomRequest";

export class AddConferenceRoom extends React.Component<any, any> {
  private conferenceRoomFormSchema = Joi.object({
    roomName: Joi.string()
    .required()
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Please enter Name" };
      });
    }),
    size: Joi.number().allow(0).max(9999).allow('').error((errors) => {
      return errors.map((error) => {
        return { message: "Size should not be more than 4 digits" };
      });
    }),
      seatingCapacity: Joi.number().allow(0).max(9999).allow('').error((errors) => {
        return errors.map((error) => {
          return { message: "Seating Capacity should not be more than 4 digits" };
        });
      }),
    notes: Joi.string().max(500)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Maximun 500 characters are allowed" };
        });
      })
      .optional().allow('')
  });  

  state: any = {
    conferenceRoomFormData: {
      roomName: this.props?.conferenceRoomRowEdit?.roomName || '',
      size: this.props?.conferenceRoomRowEdit?.size || 0,
      seatingCapacity: this.props?.conferenceRoomRowEdit?.seatingCapacity || 0,
      notes: this.props?.conferenceRoomRowEdit?.notes || ''
    },
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    dateList: [{label: "11/11/21", value: "11/11/21"}, {label: "11/11/21", value: "11/11/21"}],
        isEditButton: false,
  };

  componentDidMount = () => {
    this.getHotels();
  };
  getHotels = () => {
    this.setState({ hotelList: this.props?.userHotels });
  };

  hideModal = (isReload = false) => {
    const    conferenceRoomFormData = {
      roomName: this.props?.conferenceRoomRowEdit?.roomName || '',
      size: this.props?.conferenceRoomRowEdit?.size || 0,
      seatingCapacity: this.props?.conferenceRoomRowEdit?.seatingCapacity || 0,
      notes: this.props?.conferenceRoomRowEdit?.notes || ''
    };
    this.props?.handleHideConferenceRoomModal(isReload);
    this.setState({ conferenceRoomFormData, isFormNotValid: true });
  };

  onFieldChange = (event, inputPosition) => {
    const { conferenceRoomFormData } = this.state;


    const fieldName = event.target.name;
    let value = event.target.value;
    // if(fieldName=== "size" || fieldName=== "seatingCapacity") {
    //    value = +value;
      
    // }
    if(fieldName === "roomName" || fieldName === "notes"){
      value = value?.toString()?.trimLeft();
    }
   else if(fieldName=== "size" || fieldName=== "seatingCapacity" ){
      value = value?.toString()?.trim()?.replace(/[^0-9\+]+/gi, "");
      value=value.slice(0,4)
    }
    conferenceRoomFormData[fieldName] = value;
    this.setState({conferenceRoomFormData}, ()=> {
      this.validationOnClick(inputPosition);
    });
  };


  saveConferenceRoom = () => {
    const request: IConferenceRoomRequest = {...this.state?.conferenceRoomFormData } as IConferenceRoomRequest;
    request.hid =this.props?.hotelId;
    request.roomID = this.props?.conferenceRoomRowEdit?.roomID || 0;
    const roomCategory=this.props.isConferenceRoom ? "Conference Room" :"Other Room"
    request.roomCategory =roomCategory
    this.setState({ isDataSaving: true });
    EntityManagementService.SaveConferenceRoom(request)
      .then((result: any) => {
        this.setState({ isDataSaving: false });
        if (result?.message === "Success") {
          if(result?.success)
          {
            this.props?.isReloadRequired(true);
            toast.success(request.roomID>0 ? `${roomCategory} updated successfully.`  : `${roomCategory} saved successfully.`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addGarnishment",
            });
            this.hideModal(true);
          }
               
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        if(error.response.data === "Duplicate Room Name found")
        {
          Utils.toastError(`${roomCategory} already Exist.`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
  
        }
        else{
          Utils.toastError(error?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
       
      });
  };
  validateConferenceRoomFormSchema = () => {
    const valid = Joi.validate(
      this.state.conferenceRoomFormData,
      this.conferenceRoomFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if(this.state.conferenceRoomFormData?.size?.length > 4) {
      newErrorObject["size"]="Size should not be more than 4 digits";
    } else {
      if(newErrorObject["size"]) {
      delete newErrorObject["size"];
      }
    }

    if(this.state.conferenceRoomFormData?.seatingCapacity?.length > 4) {
      newErrorObject["seatingCapacity"]="Seating Capacity should not be more than 4 digits";
    } else {
      if(newErrorObject["seatingCapacity"]) {
      delete newErrorObject["seatingCapacity"];
      }
    }

   
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () =>
      this.validateConferenceRoomFormSchema()
    );
  };

  render() {
    const {
      conferenceRoomFormData: {
        roomName,
        size,
        seatingCapacity,
        notes
      },
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition
    } = this.state;

    const { showAddCoferenceRoomModal } = this.props;
    return (
      <>
        <div className="">
          <ToastContainer containerId={"addDeduction"} autoClose={3000} />

          <Modal
            className="add-room-type-modal"
            show={showAddCoferenceRoomModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
           // keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props?.conferenceRoomRowEdit?.roomID > 0
                  ? `Edit Room`
                  : `Add Room`}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                <Form.Group 
                      onBlur={() => this.validationOnClick(1)}
                      controlId="name" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.roomName && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                    >
                      <Form.Label>Name</Form.Label>
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"roomName"} className={(roomName.length <= 47) ? "tootltip-hide": ""}>
                                {roomName}
                            </Tooltip> 
                        }
                        >
                      <input
                        tabIndex={1}
                        maxLength={100}
                        type="text"
                        className="form-control"
                        id="txtRoomName"
                        name="roomName"
                        autoComplete="roomName"
                        value={roomName}
                        onChange={(e) => this.onFieldChange(e, 1)}
                        onBlur={()=>{this.setState({conferenceRoomFormData:{...this.state.conferenceRoomFormData,roomName:this.state.conferenceRoomFormData.roomName.trimRight()}})}}

                      />
                       </OverlayTrigger>
                      {this.getFieldError("roomName", 1)}
                    </Col>
                  </Form.Group>
                 {!this.props?.conferenceRoomRowEdit?.roomID &&    <Form.Group 
                      onBlur={() => this.validationOnClick(2)}
                  
                  controlId="size" className="d-flex">
                    <Col
                      sm={ "12"}
                      className={`mb-0 ${
                        errors?.size && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                    >
                      <Form.Label>Size</Form.Label>
                      <input
                        // maxLength={4}
                        tabIndex={2}
                        type="text"
                        min="0"
                        onKeyDown={Utils.AllowOnlyPositiveNumnberInput
                          }
                        className="form-control"
                        id="txtSize"
                        name="size"
                        autoComplete="size"
                        value={size}
                        onChange={(e) => this.onFieldChange(e, 2)}
                      />
                      {this.getFieldError("size", 2)}
                    </Col>
                  </Form.Group>}
                 {!this.props?.conferenceRoomRowEdit?.roomID && this.props.isConferenceRoom && <Form.Group
                      onBlur={() => this.validationOnClick(3)}
                      controlId="seatingCapacity" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.seatingCapacity && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                    >
                      <Form.Label>Seating Capacity</Form.Label>
                      <input
                        // maxLength={4}
                        tabIndex={3}
                        type="text"
                        // min="0"
                        onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                        className="form-control"
                        id="txtSeatingCapacity"
                        name="seatingCapacity"
                        autoComplete="seatingCapacity"
                        value={seatingCapacity}
                        onChange={(e) => this.onFieldChange(e, 3)}
                      />
                      {this.getFieldError("seatingCapacity", 3)}
                    </Col>
                  </Form.Group>}
                  <Form.Group  onBlur={() => this.validationOnClick(4)} controlId="notes" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.notes && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }`}
                      // onClick={() => this.validationOnClick(4)}
                    >
                      <Form.Label>Notes</Form.Label>
                      <Form.Control as="textarea"
                      
                        className="form-control"
                        id="txtnotes"
                        rows={1}
                        tabIndex={4}
                        name="notes"
                        autoComplete="notes"
                        value={notes}
                        maxLength={500}
                        onChange={(e) => this.onFieldChange(e, 4)}
                        onBlur={()=>{this.setState({conferenceRoomFormData:{...this.state.conferenceRoomFormData,notes:this.state.conferenceRoomFormData.notes.trimRight()}})}}

                      />
                      {this.getFieldError("notes", 4)}
                    </Col>
                  </Form.Group>
                 
                 
                 
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                tabIndex={8}
                className="btn-ghost"
                onClick={() => this.hideModal(false)}
              >
                Cancel
              </Button>
              <Button
               tabIndex={9}
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.saveConferenceRoom}
              >
               Save   
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
