import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from 'react-calendar';


export class AddMaintenanceSchedule extends React.Component<any, any> {
  private FormSchema = Joi.object({
    name: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please populate all required fields before continuing." };
        });
      }),

    startDate: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please populate all required fields before continuing." };
        });
      }),

    // dueDate: Joi.string()
    //   .required()
    //   .error((errors) => {
    //     return errors.map((error) => {
    //       return { message: "Please select Location" };
    //     });
    //   }),
    frequency: Joi.string()
      .required()
      .min(1)  
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please populate all required fields before continuing." };
        });
      }),
    frequenId: Joi.number()
      .required()
      .min(1)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please populate all required fields before continuing." };
        });
      }),


    // description: Joi.string().max(200)
    // .error((errors) => {
    //   return errors.map((error) => {
    //     return { message: "Maximun 200 characters are allowed" };
    //   });
    // })
    // .optional().allow('')
  });
  constructor(props: any) {
    super(props);
    this.state = {
      FormData: {
        name: '',
        startDate: '',
       // dueDate: '',
        frequency: '',
        frequenId: -1,
        // location:-1
      },
      isFormNotValid: true,

      pageLoader: false,
      showAddNewPmProject: this.props.showAddNewPmProject,
      errors: {},
      calendarOpened: false,
      dueDatecalendarOpened: false,
      freequencyList: [{ id: 1, name: "Days" }, { id: 2, name: "Months" }, { id: 3, name: "Years" }],
      inputPosition: 0,
      currentDate: "",
    };
  }


  componentDidMount = () => {  
    let todayDate: any = new Date(); 
    todayDate.setDate(todayDate.getDate() + 1) 
    this.setState({ currentDate: todayDate })
    console.log( "tomarrow" + this.state.currentDate);
    
  }

  hideNewPmProject = () => {
    this.props?.iscloseModulePopup();
  }

  openCalendar = () => {
    this.setState({ calendarOpened: true });
  };
  dueDateCalender = () => {

    this.setState({ dueDatecalendarOpened: true });
  }


  calenderHide = () => {
    this.setState({ calendarOpened: false, dueDatecalendarOpened: false });
  };
  AddMaintenceClick = () => {
   // debugger;
   // this.validationOnClick(4);
  //  const {
  //   FormData: {
  //     name,
  //     startDate,
  //     dueDate,
  //     frequency,
  //     frequenId
  //     // location,
  //     // description
  //   },
  //   errors,
  //   isDataSaving,
  //   isFormNotValid,
  //   inputPosition,
  //   dateList,
  //   freequencyList,

  // } = this.state;


   let newErrorObject=this.validateConfigurationFormSchema();
   if (Object.keys(newErrorObject).length > 0) {
    this.setState({inputPosition:4});
    return;
   }
 // this.validationOnClick(4);
  //  if(this.state.isFormNotValid){
   
  //   return
  //  }
   
    if(this.state.FormData.frequency==="0"){
      toast.success("Frequency value should be greater than zero", {
        position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "AddMaintenanceSchedule",
      });
      return;
    }


    let NextDueDate = "";
    let maintenceData: any = {};
    maintenceData.id = 0;
    maintenceData.name = this.state.FormData.name;
    maintenceData.startDate =  moment(this.state.FormData.startDate).format("MM/DD/YYYY");;

    if (this.state.FormData.frequenId === 1) {
      NextDueDate = moment(this.state.FormData.startDate).add(Number(this.state.FormData.frequency), "days").format("MM/DD/YYYY");;
    }
    if (this.state.FormData.frequenId === 2) {
      NextDueDate = moment(this.state.FormData.startDate).add(Number(this.state.FormData.frequency), "months").format("MM/DD/YYYY");;
    }
    if (this.state.FormData.frequenId === 3) {
      NextDueDate = moment(this.state.FormData.startDate).add(Number(this.state.FormData.frequency), "years").format("MM/DD/YYYY");;
    }

    maintenceData.dueDate = moment(NextDueDate).add(-1, "days").format("MM/DD/YYYY");
    maintenceData.frequency = this.state.FormData.frequency;
    maintenceData.frequenId = this.state.FormData.frequenId;
    maintenceData.nextOccurrenceDate =  moment(this.state.FormData.startDate).format("MM/DD/YYYY");
    maintenceData.priority = "";
    maintenceData.isChanges = true;
    maintenceData.isNew = true;

    this.props?.iscloseModulePopup();
    this.props?.isRequestSave(maintenceData);
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () =>
      this.validateConfigurationFormSchema()
    );
  };

  validateConfigurationFormSchema = (isAssetNameChanged = false) => {
    const valid = Joi.validate(
      this.state.FormData,
      this.FormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    // if (this.state.errors?.assetName === 'Asset Name already in use. Please provide a different name.' && isAssetNameChanged) {
    //     newErrorObject["assetName"] = 'Asset Name already in use. Please provide a different name.';
    // }


    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };



  onFieldChange = (event, inputPosition) => {
    const { FormData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if (fieldName === "name") {
      value = value?.toString()?.trimLeft();
    }

    FormData[fieldName] = value;
    this.setState({ FormData }, () => {
      //this.validationOnClick(inputPosition);
      this.resetError();
    });
  };

  changestartDate = (event, inputPosition) => {
    //debugger

    const { FormData } = this.state;

    let calenderYeare = event.getFullYear().toString().substr(2, 4);
    let calenderDatee =
      event.getDate() > 9 ? event.getDate() : 0 + "" + event.getDate();
    let calenderMonthe =
      event.getMonth() + 1 > 9
        ? event.getMonth() + 1
        : 0 + "" + (event.getMonth() + 1);
    let tempDatee = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;

    FormData['startDate'] = tempDatee


    this.setState({ FormData, calendarOpened: false }, () => {
      this.resetError();
      //this.validationOnClick(inputPosition);
    });


  }

  changeDuetDate = (event, inputPosition) => {
    //debugger

    const { FormData } = this.state;

    // let calenderYeare = event.getFullYear().toString().substr(2, 4);
    // let calenderDatee =
    //   event.getDate() > 9 ? event.getDate() : 0 + "" + event.getDate();
    // let calenderMonthe =
    //   event.getMonth() + 1 > 9
    //     ? event.getMonth() + 1
    //     : 0 + "" + (event.getMonth() + 1);
    // let tempDatee = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;

    //FormData['dueDate'] = tempDatee

    this.setState({ FormData, dueDatecalendarOpened: false }, () => {
      this.validationOnClick(inputPosition);
    });


  }

  onFieldChange_1 = (event, inputPosition) => {
    // debugger
    const { FormData } = this.state;
    // let value = event.target.value;
    // const fieldName = event.target.name;


    FormData['frequenId'] = event.id;

    // let calenderYeare = event.getFullYear().toString().substr(2, 4);
    // let calenderDatee =
    //   event.getDate() > 9 ? event.getDate() : 0 + "" + event.getDate();
    // let calenderMonthe =
    //   event.getMonth() + 1 > 9
    //     ? event.getMonth() + 1
    //     : 0 + "" + (event.getMonth() + 1);
    // let tempDatee = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;

    


    this.setState({ FormData,inputPosition }, () => {
      this.resetError();
      //this.validationOnClick(inputPosition);
    });
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;
       //debugger;
    return (
      <>
        {/* {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )} */}
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message validation-error">Please populate all required fields before continuing.</span>
        )}
      </>
    );
  };

resetError=()=>{
  this.setState({isFormNotValid:false,errors:{}})
}


  render() {

    const { showAddNewPmProject } = this.props;

    const {
      FormData: {
        name,
        startDate,
        frequency,
        frequenId
        // location,
        // description
      },
      errors,
      inputPosition,
      freequencyList,

    } = this.state;


    return (
      <>

        <div className="">
          <ToastContainer containerId={"addDeduction"} autoClose={3000} />

          <Modal
            className="add-room-type-modal add-new-asset add-maintenanace-schedule-modal"
            show={showAddNewPmProject}
            onHide={this.hideNewPmProject}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          // keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Add Maintenance Schedule
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">

                  <Form.Group
                   // onBlur={() => this.validationOnClick(1)}
                    controlId="name" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${errors?.name && inputPosition >= 1
                          ? "validation-error"
                          : ""
                        }`}
                    // onClick={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Name</Form.Label>
                      
                      <Form.Control
                        type="text"
                        className="form-control"
                        // tabIndex={1}
                        maxLength={50}
                        id="txtassetName"
                        name="name"
                        autoComplete='off'
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => this.onFieldChange(e, 1)}
                        onBlur={() => { this.setState({ FormData: { ...this.state.FormData, name: name.trimRight() } }) }}
                      />                      
                   
                    {/* {this.getFieldError("name", 1)} */}
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Col className="p-0">
                      <hr />
                    </Col>
                  </Form.Group>
                  <div className="d-flex input-row">
                    <Form.Group
                      //onBlur={() => this.validationOnClick(2)}
                      controlId="startDate" style={{ width: "40%" }} className="mb-0">

                      <Col className={`pl-0 col-left pr-3 ${errors?.startDate && inputPosition >= 2
                          ? "validation-error"
                          : ""
                        }`}
                        sm={"12"}>
                        <Form.Label>Start Date</Form.Label>
                       
                        <div className="calender-picker input-form-calender">
                          <Form.Control placeholder="Select Start Date"
                            autoComplete='off'
                            type="text" id="openingDate" name='startDate'
                            value={startDate}
                            // ref={this.openingDateRef}
                            onClick={() => this.openCalendar()}
                          />
                          <div className="icon" onClick={() => this.openCalendar()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                              <path d="M8.29297 0.292969L5.0003 3.5863L1.70764 0.292969L0.292969 1.70764L5.0003 6.4143L9.70763 1.70764L8.29297 0.292969Z" fill="#84888C" />
                            </svg>
                          </div>
                          {this.state.calendarOpened
                            ?
                            <OutsideClickHandler
                              onOutsideClick={this.calenderHide}
                            >
                              <div className="calender">
                                <Calendar
                                  calendarType={"US"}
                                  // onChange={this.changestartDate}
                                  onChange={(e) => this.changestartDate(e, 2)}
                                  value={
                                    this.state.FormData.startDate
                                    ? moment(this.state.FormData.startDate)?.format(
                                        "MM/DD/YY"
                                      ) === "Invalid date"
                                      ? ""
                                      : new Date(this.state.FormData.startDate)
                                    : ""
                                  } 
                                  minDate={this.state.currentDate}
                                />
                              </div>
                            </OutsideClickHandler>
                            : null}
                        </div>
                       

                        {/* {this.getFieldError("startDate", 1)} */}
                        {/* {errors.openingDate.length > 0 &&
                                    <span className='error'>{errors.openingDate}</span>
                                } */}
                      </Col>
                    </Form.Group>
                    <Form.Group
                     // onBlur={() => this.validationOnClick(3)}
                      controlId="Frequency" style={{ width: "60%" }}>

                       <Col
                              sm={"12"}
                              className={`mb-0 col-left ${errors?.frequency && inputPosition >= 3
                                  ? "validation-error1"
                                  : ""
                                }`}
                                >
                      {/* <Col sm={"12"}
                        className={`col-right ${errors?.dueDate && inputPosition >= 3
                            ? "validation-error"
                            : ""
                          }`}

                      > */}
                        {/* <Form.Label>Due Date</Form.Label>
                        <div className="calender-picker input-form-calender">
                          <Form.Control placeholder="Select Due Date"
                            autoComplete='off'
                            type="text" id="openingDate" name='dueDate'
                            //value={this.state.formData.dueDate}
                            // ref={this.openingDateRef}
                            value={dueDate}
                            onClick={() => this.dueDateCalender()}
                          />
                          <div className="icon" onClick={() => this.dueDateCalender()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                              <path d="M8.29297 0.292969L5.0003 3.5863L1.70764 0.292969L0.292969 1.70764L5.0003 6.4143L9.70763 1.70764L8.29297 0.292969Z" fill="#84888C" />
                            </svg>
                          </div>
                          {this.state.dueDatecalendarOpened
                            ?
                            <OutsideClickHandler
                              onOutsideClick={this.calenderHide}
                            >
                              <div className="calender">
                                <Calendar
                                  calendarType={"US"}
                                  onChange={(e) => this.changeDuetDate(e, 3)}
                                //onChange={this.changeDate}
                                //value={this.state.calenderSelectDate}
                                />
                              </div>
                            </OutsideClickHandler>
                            : null}
                        </div> */}
                        {/* {errors.openingDate.length > 0 &&
                                      <span className='error'>{errors.openingDate}</span>
                                  } */}

                      <Form.Label>Frequency</Form.Label>
                      
                        <div className="d-flex input-row">
                        
                          <Form.Group
                           // onBlur={() => this.validationOnClick(3)}
                            controlId="Frequency" style={{ width: "32%" }} className="mb-1">
                            <Col
                              sm={"12"}
                              className={`mb-0 col-left ${errors?.frequency && inputPosition >= 3
                                  ? "validation-error"
                                  : ""
                                }`}
                            // onClick={() => this.validationOnClick(1)}
                            >
                               
                                <Form.Control
                                  type="number"
                                  className="form-control mb-3"
                                  // tabIndex={1}
                                  // min={1}
                                  // max={2}
                                  id="txtassetName"
                                  name="frequency"
                                  autoComplete="frequency"
                                  value={frequency}
                                  onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                                  onChange={(e) => this.onFieldChange(e, 3)}
                                  onBlur={() => { this.setState({ FormData: { ...this.state.FormData, frequency: frequency.trimRight() } }) }}
                                /> 
                              {/* {this.state.priorityList.length > 0 &&   */}

                              {/* <DropDownList
                                placeHolder={"Select Frequency"}
                                data={this.state.freequencyList}
                                defaultValue={"Select Frequency"}
                                isSearchRequired={false}
                                label={"name"}
                                value={"id"}
                                placeHolderItem={{ id: -1, name: "Select Frequency" }}  
                              /> */}

                            </Col>
                          </Form.Group>
                          
                         {/* {this.getFieldError("frequency", 1)} */}
                          <Form.Group
                            //onBlur={() => this.validationOnClick(4)}
                            controlId="selectFrequency" style={{ width: "68%" }}>
                            <Col
                              sm={"12"}
                              className={`mb-0 col-right ${errors?.frequenId && inputPosition >= 4
                                  ? "validation-error"
                                  : ""
                                }`}
                            //   onBlur={() => this.validationOnClick(2)}
                            >
                              {/* <Form.Label>Asset Type</Form.Label> */}

                              {this.state.freequencyList.length > 0 && <DropDownList
                                // tabIndex={2}
                                placeHolder={"Select Frequency"}
                                data={this.state.freequencyList}
                                defaultValue={frequenId}
                                isSearchRequired={true}
                                label={"name"}
                                value={"id"}
                                placeHolderItem={{ id: -1, name: "Select Frequency" }}
                                onDropDownChange={(item) => {
                                  if (!item) {
                                    return;
                                  }
                                  this.onFieldChange_1(
                                    item, 4
                                  );
                                }}
                                selectedItem={[
                                  ...[{ id: -1, name: "Select Frequency" }],
                                  ...freequencyList,
                                ].find((r: any) => r?.id === frequenId)}
                              />}

                              {/* {this.getFieldError("assetType", 2)} */}
                            </Col>
                          </Form.Group>

                        </div>
                       
                      </Col>
                    </Form.Group>

                  </div>
                  <Form.Group>
                    <Col className="p-0">

                    {this.getFieldError("frequenId", 1)} 
                    </Col>
                  </Form.Group> 

                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-end">
              <Button
                //disabled={isFormNotValid}
                className="btn btn-primary"

                onClick={this.AddMaintenceClick}
              >
                Add Schedule
              </Button>
            </Modal.Footer>
          </Modal>
        </div>



      </>
    );
  }

}