import * as React from "react";
import { Container, Dropdown, Tab, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import { HidSelector } from "../../Common/Components/HidSelector";
import { Facility } from "../../Common/Services/Facility";
import "./Location.scss";
import "./facilities.scss";
import _ from "lodash";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { DailyTaskManagementDataTable } from "./DailyTaskManagementDataTable";
import "./DailyTaskMgt.scss";
import { IUserRole } from "../../Common/Contracts/IUserRole";
import { UserManagement } from "../../Common/Services/UserManagement";
import {
  IFacilityDailyTaskListRequest,
  IUpdatFacilityDailyTaskStatusRequest,
} from "../../Common/Contracts/IDailyTask";
import { FacilityDailyTask } from "../../Common/Services/FacilityDailyTask";
import DailyTaskManagementSlideOut from "./DailyTaskManagementSlideOut";
import { Hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import {  reject } from "q";
import { Utils } from "../../Common/Utilis";

//import DailyTaskManagementSlideOut from "./DailyTaskManagementSlideOut";

export class DailyTaskManagement extends React.Component<any, any> {
  private child: any;
  private childHID: any;
  private departmentSearch: any;
  private empTypeSearch: any;
  private tableTypeSearch: any;
  private scheduleUserViewRef: any;
  constructor(props: any) {
    super(props);
    this.scheduleUserViewRef = React.createRef();
    this.child = React.createRef();
    this.childHID = React.createRef();
    this.departmentSearch = React.createRef();
    this.empTypeSearch = React.createRef();
    this.tableTypeSearch = React.createRef();
    this.state = {
      pageLoader: false,
      hidValue: "Select",
      hotelName: "Select",
      originalTableData: [],
      tableData: [],
      filteredTableData: [],
      createEditPermission: true,
      searchText: "",
      sortingOrder: "asc",
      sortingField: "name",
      activeTab: "assignedToMe",
      selectedRowIDsMe: [],
      selectedRowIDsAll: [],
      selectedDate: "",
      locationList: [],
      locationId: 0,
      userRoles: [],
      roleId: 0,
      showCompleted: false,
      isDataLoading: false,
      showSlideout: false,
      commentLink: "",
    };
  }

  componentDidMount() {
    const params = this.getURLParamsValues();
    if (params?.taskId) {
      this.setState(
        {
          hidValue: params?.hotelId,
          locationId: params?.locationId,
          selectedDate: params?.selectedDate,
          activeTab: params?.activeTab,
          showCompleted: params?.showCompleted == "true" ? true : false,
        },
        () => {
          this.loadHotelsDetails(params?.hotelId);
        }
      );
    } else {
      let hotelId = this.getURLParamsValues()?.hotelId;
      let source = this.getURLParamsValues()?.source;
      if (source == "overview") {
        if (hotelId) {
          this.setState({ activeTab: "assignedToAll" }, () => {
            this.loadHotelsDetails(hotelId);
          });
        }
      }
    }

    this.CheckAddEditPermission();

    this.getUserRoleList();
  }

  getURLParamsValues = (): any => {
    const paramObject: any = {
      taskId: this.getParamValueByParamName("taskId"),
      activeTab: this.getParamValueByParamName("activeTab"),
      locationId: this.getParamValueByParamName("locationId"),
      selectedDate: this.getParamValueByParamName("selectedDate"),
      showCompleted: this.getParamValueByParamName("showCompleted"),
      hotelId: this.getParamValueByParamName("hotelId"),
      source: this.getParamValueByParamName("source"),
    };
    return paramObject;
  };

  getParamValueByParamName(paramName: string): any {
    const valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    const item = valuesArray?.find((r) => r?.includes(paramName));
    return item ? window.atob(item?.split("=")[1] as any) : "";
  }

  loadHotelsDetails(hid) {
    Hotel.getUserHotelAccess("Facility Maintenance")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          const hotelDetail: any = result.find((r) => +r?.hotelID === +hid);

          localStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
          sessionStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
          this.childHID?.current?.updateChildState(hotelDetail);
          this.setState(
            { hidValue: hotelDetail.hotelID, timezone: hotelDetail.timezone },
            () => {
              this.selectHotelDropDown(hotelDetail);
            }
          );
          //  this.selectHotelDropDown(hotelDetail);
        }
      })
      .catch((error) => {
        reject();
      });
  }

  getFacilityDailyTask = (isFromError = false) => {
    this.setState({ isDataLoading: true });
    const { selectedDate, hidValue } = this.state;
    const request: IFacilityDailyTaskListRequest = {
      fromDate: selectedDate,
      toDate: selectedDate,
      hid: hidValue,
      tenantId: 0,
      assignedTo: 0,
      status: "",
    };
    if (selectedDate) {
      FacilityDailyTask.GetFacilityDailyTask(request)
        .then((result) => {
          let data = result?.map((x) => ({ ...x, ref: React.createRef() }));
          this.setState(
            {
              tableData: _.sortBy(data, "name"),
              originalTableData: _.sortBy(_.cloneDeep(data), "name"),
            },
            () => {
              if (isFromError) {
                this.refreshTable(this.state.tableData);
              } else {
                this.searchAndFilterTableData();
              }
              const params = this.getURLParamsValues();
              if (params?.taskId) {
                const row = this.state.tableData?.find(
                  (x) => x?.taskId == params?.taskId
                );
                if (row?.taskId) {
                  this.handleShowSlideout(row);
                }
              }
              this.setState({ isDataLoading: false });
            }
          );
        })
        .catch((error) => {
          if ((error)?.message !== "Cancel") {
            this.setState({ isDataLoading: false });
            Utils.toastError(error?.response?.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        });
    } else {
      this.setState({ isDataLoading: false });
    }
  };

  updateFacilityDailyTaskStatus = (taskId, isSelect) => {
    const { selectedDate, hidValue } = this.state;
    const request: IUpdatFacilityDailyTaskStatusRequest = {
      hid: hidValue,
      tenantId: 0,
      taskId: taskId,
      status: isSelect ? "Completed" : "Not Started",
    };
    if (selectedDate) {
      FacilityDailyTask.UpdateFacilityDailyTaskStatus([request])
        .then((data) => {})
        .catch((error) => {
          if ((error)?.message !== "Cancel") {
            Utils.toastError(error?.response?.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          this.getFacilityDailyTask(true);
        });
    }
  };

  searchAndFilterTableData = () => {
    const { searchText, locationId, roleId, showCompleted } = this.state;
    const _tableData = _.cloneDeep(this.state.tableData);
    let filteredTableData: any[] = Array.apply(null, _tableData);
    if (searchText) {
      filteredTableData = filteredTableData.filter(
        (x) =>
          x?.name
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchText?.toString()?.toLowerCase()) ||
          x?.roomName
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchText?.toString()?.toLowerCase())
      );
    }

    if (locationId > 0) {
      filteredTableData = filteredTableData.filter(
        (x) => x?.roomID === locationId
      );
    }

    if (roleId > 0) {
      filteredTableData = filteredTableData.filter((x) => x?.roleId === roleId);
    }

    if (!showCompleted) {
      filteredTableData = filteredTableData.filter(
        (x) => x?.status !== "Completed"
      );
    }

    this.setState({ filteredTableData });
  };

  CheckAddEditPermission = () => {
    FacilityDailyTask.DailyTaskCheckAddEditPermission().then((res) => {
      this.setState({ createEditPermission: res?.result });
    });
  };

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ pageLoader: true });

    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,
      },
      () => {
        this.getFacilityDailyTask();
        this.getLocationList();
      }
    );
  };

  getLocationList = () => {
    Facility.GetDistinctRoomsList(this.state.hidValue)
      .then((res) => {
        this.setState({
          locationList: [
            { id: -1, name: "All" },
            ..._.orderBy(
              (res || [])?.map((x) => ({
                id: x.roomId,
                name: x.roomName,
              })),
              [(x) => x.name.toLowerCase(), ["asc"]],
              "name"
            ),
          ],
        });
      })
      .catch((res) => {
        this.setState({ locationList: [] });
      });
  };

  getUserRoleList = () => {
    UserManagement.GetUserRoleList().then((response: IUserRole[]) => {
      const userRoles = [
        { id: -1, name: "All" },
        ...response.map((item) => ({
          id: item.roleID,
          name: item.roleName,
        })),
      ];
      this.setState({ userRoles });
    });
  };

  setActiveTab = (selectedTab) => {
    this.setState(
      {
        activeTab: selectedTab,
        searchText: "",
        locationId: 0,
        roleId: 0,
      },
      () => {
        this.searchAndFilterTableData();
      }
    );
  };
  onSearchChange = (searchText: string) => {
    this.setState({ searchText: searchText?.toString()?.trimStart() }, () => {
      this.searchAndFilterTableData();
    });
  };

  onDDlFiltersChange = (controlID: any, id: any) => {
    let { locationId, roleId } = this.state;
    switch (controlID) {
      case "ddlLocation":
        locationId = id || 0;
        break;
      case "ddlRole":
        roleId = id || 0;
        break;
      default:
        break;
    }

    this.setState({ locationId, roleId }, () => {
      this.searchAndFilterTableData();
    });
  };

  handleOnSelectMe = (row, isSelect) => {
    if (isSelect) {
      const tableData = _.cloneDeep(this.state.tableData)?.map((data) => {
        if (data.taskId === row.taskId) {
          data.status = "Completed";
        }
        data.disabled = false;
        return data;
      });
      const _tableData = _.cloneDeep(this.state.tableData)?.map((data) => {
        if (data.taskId === row.taskId) {
          data.checked = true;
          data.disabled = false;
        } else {
          data.disabled = true;
        }
        return data;
      });
      const originalTableData = _.cloneDeep(this.state.originalTableData)?.map(
        (data) => {
          if (data.taskId === row.taskId) {
            data.status = "Completed";
          }
          return data;
        }
      );
      this.refreshTable(_tableData);

      setTimeout(() => {
        this.refreshTable(tableData);
        this.updateFacilityDailyTaskStatus(row.taskId, isSelect);
        this.setState({ selectedRowIDsMe: [], originalTableData });
      }, 1500);

      this.setState({ selectedRowIDsMe: [row.taskId] });
    } else {
      const tableData = _.cloneDeep(this.state.tableData)?.map((data) => {
        if (data.taskId === row.taskId) {
          data.status = "Not Started";
        }
        data.disabled = false;
        return data;
      });
      const _tableData = _.cloneDeep(this.state.tableData)?.map((data) => {
        if (data.taskId === row.taskId) {
          data.checked = false;
          data.disabled = false;
        } else {
          data.disabled = true;
        }
        return data;
      });
      const originalTableData = _.cloneDeep(this.state.originalTableData)?.map(
        (data) => {
          if (data.taskId === row.taskId) {
            data.status = "Not Started";
          }
          return data;
        }
      );
      this.refreshTable(_tableData);

      setTimeout(() => {
        this.refreshTable(tableData);
        this.updateFacilityDailyTaskStatus(row.taskId, isSelect);
        this.setState({ selectedRowIDsMe: [], originalTableData });
      }, 1500);

      this.setState({ selectedRowIDsMe: [row.taskId] });
    }
  };

  handleOnSelectAll = (row, isSelect) => {
    if (isSelect) {
      const tableData = _.cloneDeep(this.state.tableData)?.map((data) => {
        if (data.taskId === row.taskId) {
          data.status = "Completed";
        }
        data.disabled = false;
        return data;
      });

      const originalTableData = _.cloneDeep(this.state.originalTableData)?.map(
        (data) => {
          if (data.taskId === row.taskId) {
            data.status = "Completed";
          }
          return data;
        }
      );
      const _tableData = _.cloneDeep(this.state.tableData)?.map((data) => {
        if (data.taskId === row.taskId) {
          data.checked = true;
          data.disabled = false;
        } else {
          data.disabled = true;
        }
        return data;
      });

      this.refreshTable(_tableData);

      setTimeout(() => {
        this.refreshTable(tableData);
        this.updateFacilityDailyTaskStatus(row.taskId, isSelect);
        this.setState({ selectedRowIDsAll: [], originalTableData });
      }, 1500);

      this.setState({ selectedRowIDsAll: [row.taskId] });
    } else {
      const tableData = _.cloneDeep(this.state.tableData)?.map((data) => {
        if (data.taskId === row.taskId) {
          data.status = "Not Started";
        }
        data.disabled = false;
        return data;
      });

      const originalTableData = _.cloneDeep(this.state.originalTableData)?.map(
        (data) => {
          if (data.taskId === row.taskId) {
            data.status = "Not Started";
          }
          return data;
        }
      );
      const _tableData = _.cloneDeep(this.state.tableData)?.map((data) => {
        if (data.taskId === row.taskId) {
          data.checked = false;
          data.disabled = false;
        } else {
          data.disabled = true;
        }
        return data;
      });

      this.refreshTable(_tableData);

      setTimeout(() => {
        this.refreshTable(tableData);
        this.updateFacilityDailyTaskStatus(row.taskId, isSelect);
        this.setState({ selectedRowIDsAll: [], originalTableData });
      }, 1500);

      this.setState({ selectedRowIDsAll: [row.taskId] });
    }
  };

  onDateSelect = (data) => {
    this.setState({ selectedDate: data }, () => {
      this.getFacilityDailyTask();
    });
  };

  onCompletedChange = (checked) => {
    this.setState({ showCompleted: checked }, () => {
      this.getFacilityDailyTask();
    });
  };
  refreshTable = (data) => {
    this.setState(
      {
        tableData: [],
      },
      () =>
        this.setState({ tableData: _.cloneDeep(data) }, () =>
          this.searchAndFilterTableData()
        )
    );
  };
  tableColumnEvent = {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      this.handleShowSlideout(row);
    },
  };

  getCommentLink = (taskId: string) => {
    const { activeTab, locationId, selectedDate, showCompleted, hidValue } =
      this.state;
    let link = window.location.origin + window.location.pathname;
    link += "?taskId=" + btoa(taskId?.toString());
    link += "&activeTab=" + btoa(activeTab);
    link += "&locationId=" + btoa(locationId?.toString());
    link += "&selectedDate=" + btoa(selectedDate);
    link += "&showCompleted=" + btoa(showCompleted?.toString());
    link += "&hotelId=" + btoa(hidValue);
    return link;
  };
  handleShowSlideout = (row: any) => {
    const commentLink = this.getCommentLink(row?.taskId);
    this.setState({
      showSlideout: true,
      commentLink,
      rowData: { ...row },
    });
  };
  handleHideSlideout = (isReload) => {
    this.setState({
      showSlideout: false,
      commentLink: "",
    });

    if (isReload) {
      this.getFacilityDailyTask();
      // this.getFacilityDailyTaskTemplate();
    }
  };
  render() {
    const {
      selectedRowIDsMe,
      selectedRowIDsAll,
      filteredTableData,
      isDataLoading,
    
      originalTableData,
 
      createEditPermission,
      commentLink,
    } = this.state;

    const getAssignedToMeDataCount = () => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let rolId = storage === null ? 0 : (storage.rolId);
      let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID);
      return (
        (
          originalTableData?.filter((x) => x.status !== "Completed") || []
        ).filter(
          (x) => +x.roleId === +rolId || +x?.user_UniqueID === +user_UniqueID
        ) || []
      )?.length;
    };

    const getAssignedToMeData = () => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let rolId = storage === null ? 0 : (storage.rolId);
      let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID);
      return (
        (filteredTableData || []).filter(
          (x) => +x.roleId === +rolId || +x?.user_UniqueID === +user_UniqueID
        ) || []
      );
    };

    const propsMe = {
      ...this.state,
      onSearchChange: this.onSearchChange,
      onDDlFiltersChange: this.onDDlFiltersChange,
      onCompletedChange: this.onCompletedChange,
      onDateSelect: this.onDateSelect,
      handleOnSelect: this.handleOnSelectMe,
      tableData: getAssignedToMeData(),
      selectedRowIDs: selectedRowIDsMe,
      tableColumnEvent: this.tableColumnEvent,
      handleShowSlideout: this.handleShowSlideout,
      isAssignedToMe: true,
    };

    const propsAll = {
      ...this.state,
      onSearchChange: this.onSearchChange,
      onDDlFiltersChange: this.onDDlFiltersChange,
      onCompletedChange: this.onCompletedChange,
      onDateSelect: this.onDateSelect,
      handleOnSelect: this.handleOnSelectAll,
      tableData: filteredTableData,
      selectedRowIDs: selectedRowIDsAll,
      tableColumnEvent: this.tableColumnEvent,
      handleShowSlideout: this.handleShowSlideout,
      isAssignedToMe: false,
    };

    return (
      <>
        <div className="daily-task-mgt-wrapper">
          <Container fluid className="body-sec">
            <div className="page-heading d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
                modulename={"Facility Maintenance"}
              />
              <div className="mr-auto d-flex align-items-center">
                Daily Tasks
              </div>

              <Dropdown className="more-action ml-3">
                <DropdownToggle
                  disabled={!createEditPermission}
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem></DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {/* {this.state.pageLoader ? (
              <ReactPageLoader useas={"timesheetMain"} />
            ) : ( */}
            {this.state.hidValue !== "Select" && (
              <div className="main-Page">
                <div className="bdy-sec mt-0">
                  <div>
                    <div className="hasCursorPointer position-relative">
                      <div className="block-bar"></div>

                      <Tab.Container
                        activeKey={this.state.activeTab}
                        onSelect={(selectedtab) =>
                          this.setActiveTab(selectedtab)
                        }
                        id="left-tabs-example"
                        defaultActiveKey="assignedToMe"
                      >
                        <Nav className="nav-tabs flex-row">
                          <Nav.Item>
                            <Nav.Link eventKey="assignedToMe">
                              Assigned To Me{" "}
                              <div className="badge">
                                <span>
                                  {isDataLoading
                                    ? 0
                                    : getAssignedToMeDataCount()}
                                </span>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="assignedToAll">
                              All{" "}
                              <div className="badge">
                                <span>
                                  {" "}
                                  {isDataLoading
                                    ? 0
                                    : (
                                        originalTableData?.filter(
                                          (x) => x.status !== "Completed"
                                        ) || []
                                      )?.length}
                                </span>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>

                        <Tab.Content>
                          <Tab.Pane eventKey="assignedToMe">
                            {this.state.activeTab === "assignedToMe" && (
                              <DailyTaskManagementDataTable {...propsMe} />
                            )}
                          </Tab.Pane>

                          <Tab.Pane eventKey="assignedToAll">
                            {this.state.activeTab === "assignedToAll" && (
                              <DailyTaskManagementDataTable {...propsAll} />
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.showSlideout && (
              <DailyTaskManagementSlideOut
                hid={this.state.hidValue}
                timezone={this.state.timezone}
                handleHideSlideout={this.handleHideSlideout}
                parentRowData={this.state.rowData}
                hotelName={this.state.hotelName}
                userRoles={this.state.userRoles.filter((x) => x.id > 0)}
                locationList={this.state.locationList}
                createEditPermission={this.state.createEditPermission}
                commentLink={commentLink}
                scrollToComment={
                  this.getParamValueByParamName("taskId") ? true : false
                }
              />
            )}
          </Container>
        </div>
      </>
    );
  }
}
