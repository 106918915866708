import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { FiChevronDown } from "react-icons/fi";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { Hotel as hotel } from "../../Common/Services/Hotel";


export class SingleSelectSearchMove extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      hotelNameModal: this.props.defaultText,
      filterData: this.props?.dropDownList,
      hotelList: this.props?.dropDownList,
      inputValue: "",
      pageType: this.props.pageType,

    };
    this.handleChangeEhid = this.handleChangeEhid.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);

  }
  componentDidMount() {
    // hotel.getUserHotelAccess(this.props.moduleName)
    //   .then(async (result: IHotelResponseDto[] | null) => {
    //     if (result != null) {
    //       if (this.state.pageType === "Transaction") {
    //         this.setState({ hotelList: result, filterData: result });
    //       } else {
    //         this.setState({ hotelList: result.filter(item => item.hotelID !== this.props.selectedHID) });
    //         this.setState({ filterData: result.filter(item => item.hotelID !== this.props.selectedHID) });
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //   });
  }


  handleChangeEhid(e: { target: { value: string; }; }) {
    const updatedList = this.state.hotelList.filter(item => {
      return (
        item.displayName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  handleDropdownChange(e: any) {
    this.props.returnData(e);
    this.resetSerchBox();
  }

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.hotelList });
  };

  hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };

  render() {
    const hidSelectModal = (eventKey: any) => {
      this.setState({ inputValue: "" });
      this.setState({ hotelNameModal: eventKey });
    };
    const onFocusInput = (eventKey: any) => {
      setTimeout(() => {
        this.inputElement.current.focus();
      }, 100)
    };

    return (
      <Dropdown className="single-select" onSelect={hidSelectModal} onClick={onFocusInput}  >
        <Dropdown.Toggle id="Modal-hid">
          {this.state.hotelNameModal ===
            "Select EHID" && (
              <span className="placeholder">
                {this.state.hotelNameModal}
              </span>
            )}
          {this.state.hotelNameModal !==
            "Select EHID" && (
              <EllipsisWithTooltip placement="bottom">
                {this.state.hotelNameModal}
              </EllipsisWithTooltip>

            )}
          <div className="drop-arrow ml-0 d-flex">
            <FiChevronDown />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {this.props.search == "Yes" &&(
            <div className="search-sec" >
            <input
              type="text"
              className="form-control"
              onChange={this.handleChangeEhid}
              placeholder="Filter EHIDs"
              value={this.state.inputValue}
              ref={this.inputElement}
            />
          </div>
          )}
          
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (
              <>
                {item.isDisable === "Yes" ? (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id={"Move-list"}>
                          {item.toolTip}
                        </Tooltip>
                    }
                    >
                    <div className="dropdown-item read-only">{item.displayName}</div>
                </OverlayTrigger>
                ) : (
                  <Dropdown.Item
                    eventKey={item.displayName}
                    onWheel={() => this.hoverOutEllipsis()}
                    onClick={() =>
                      this.handleDropdownChange(item)
                    }>
                    <EllipsisWithTooltip placement="bottom">
                      {item.displayName}
                    </EllipsisWithTooltip>
                  </Dropdown.Item>
                )}
              </>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
