import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { accountService } from "../../Common/Services/account";

export class PositivePaySelector extends React.Component<any, any> {

  private inputElement: any;

  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      selectedItem: {},
      filterData: [],
      reportTypes: []
    };
  }


  componentDidUpdate(prevProps, prevState) {
    const { defaultValue } = this.props;
    const { filterData } = this.state;
    if (prevProps.defaultValue !== defaultValue && defaultValue !== "Select") {
      let selectedItem = filterData?.filter(r => r.payFileFormat === defaultValue);
      this.setState({ selectedItem: selectedItem[0], defaultValue }, () => {
        this.handleDropdownChange(selectedItem[0]);
      });
    }
  }

  componentDidMount = () => {
    let request: any = {};
    request.hotelID = "";
    accountService
      .PositivePayFileDetails(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          this.setState({ filterData: result, reportTypes: result }, () => {
            const { defaultValue } = this.props;
            const { filterData } = this.state;
            if (defaultValue === undefined) {
              let selectedItem = { payFileFormat: "Select", id: -1};
              this.setState({ selectedItem }, () => {
                this.handleDropdownChange(selectedItem);
              })

            } else {
              let selectedItem = filterData?.filter(r => r.payFileFormat === defaultValue);
              this.setState({ selectedItem: selectedItem[0], defaultValue }, () => {
                this.handleDropdownChange(selectedItem[0]);
              });
            }
          });
        }
      });

  };


  handleDropdownChange = (item: any) => {
    this.setState({ selectedItem: item });
    this.props.onPositivePayChange(item);
    this.resetSerchBox();
  }

  resetSerchBox = () => {
    this.setState({ filterData: this.state.reportTypes });
  };

  render() {

    return (
      <Dropdown
        className="single-select" style={{ marginBottom: "10px" }}>
        <Dropdown.Toggle id="Modal-reportTypes">
          <span >
            {this.state.selectedItem?.payFileFormat}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (

              <Dropdown.Item
                onClick={() => this.handleDropdownChange(item)}
              >
                {item.payFileFormat}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
