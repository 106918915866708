import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Button, Container, Dropdown, Form, Modal } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { Hotel } from "./../../../Common/Services/Hotel";
import { ToastContainer, toast } from "react-toastify";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import OutsideClickHandler from "react-outside-click-handler";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { EntityManagementSlideOut } from "./EntityManagementSlideOut";
import { userUniqueID } from "./../../../Common/Services/LocalStorage";
import { LaborPerformance as laborPerformance } from "./../../../Common/Services/LaborPerfomance";
import { ILaborSaveAsDefaultDto } from "./../../../Common/Contracts/ILaborSaveAsDefaultDto";
import { Utils } from "../../../Common/Utilis";
export class Entity extends React.Component<any, any> {
  private pageName = "Entities";

  constructor(props: any) {
    super(props);
    this.state = {
      Status: "Status",
      SearchText: "",
      SortBy: "Type",
      showSlideout: false,
      entityRowData: {},
      entityResult: [],
      filteredData: [],
      isDataLoading:  true
    };
  }

  componentDidMount() {
    this.getDefaultView();
  }

  getAllEntities = () => {
    this.setState({   isDataLoading:  true});
    Hotel.getAllEntities()
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ entityResult: result });
          let filterdata = _.sortBy(result, this.state.SortBy);
          if (this.state.Status !== "Status" && this.state.Status !== "All") {
            filterdata = filterdata.filter(
              (x) => x.status?.toLowerCase() === this.state.Status.toLowerCase()
            );
          }

          this.setState({ filteredData: filterdata }, );
        }
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }).finally(()=> {
    this.setState({   isDataLoading:  false});

      });
  };


  saveDefaultView = (): void => {
    const laborSaveAsDefaultdtos: ILaborSaveAsDefaultDto[] = [];
    const { Status, SortBy } = this.state;
   
    laborSaveAsDefaultdtos.push({
      fieldName: "Status",
      fieldValue: Status,
      pageName: this.pageName,
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "SortBy",
      fieldValue: SortBy,
      pageName: this.pageName,
    });
  
    this.setState({ loaderTrue: true });
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        this.setState({ loaderTrue: false });
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.getDefaultView();
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loaderTrue: false });
      });
  };

  getDefaultView() {
    laborPerformance
      .getDefaultViewProperties(this.pageName)
      .then(async (result: any | null) => {
        if (result != null) {
          const Status = result.find(
            (item) => item.fieldName === "Status"
          )?.fieldValue;
          const SortBy = result.find(
            (item) => item.fieldName === "SortBy"
          )?.fieldValue;
          //
          if (Status && SortBy) {
            this.setState({ Status, SortBy }, () => {
              this.getAllEntities();
            });
          } else {
            this.getAllEntities();
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }

  searchEntity = (e) => {
    this.setState({ SearchText: e.target.value }, () => this.filterEntity());
  };

  handleShowSlideout = (entityRowData: any) => {
    this.setState({ entityRowData }, () => {
      this.setState({
        showSlideout: true
      });
    });

  };

  handleHideSlideout = (isReload) => {
    this.setState({
      showSlideout: false,
      entityRowData: {}
    });
    if(isReload) {

    this.getAllEntities();
     }
   

  };

  setStatus = (status) => {
    this.setState({ Status: status }, () => this.filterEntity());
  };

  filterEntity = () => {
    let filterdata = this.state.entityResult;
    if (this.state.Status != "Status" && this.state.Status != "All") {
      filterdata = this.state.entityResult.filter(
        (x) => x.status?.toLowerCase() == this.state.Status.toLowerCase()
      );
    }

    if (this.state.SearchText != "") {
      filterdata = filterdata.filter(
        (x) =>
          x.hotelName
            ?.toLowerCase()
            .includes(this.state.SearchText.toLowerCase()) ||
          x.lettercode
            ?.toLowerCase()
            .includes(this.state.SearchText.toLowerCase()) ||
          x.hotelType
            ?.toLowerCase()
            .includes(this.state.SearchText.toLowerCase()) ||
          x.address1
            ?.toLowerCase()
            .includes(this.state.SearchText.toLowerCase()) ||
          x.taxID?.toLowerCase().includes(this.state.SearchText.toLowerCase())
      );
    }
    this.setState({ filteredData: filterdata });
  };

  sortData = (sortBy) => {
    let filterdata = _.sortBy(this.state.filteredData, [sortBy, "hotelName"]);
    if (sortBy === "hotelName") {
      this.setState({ SortBy: "Name" });
    } else if (sortBy === "hotelType") {
      this.setState({ SortBy: "Type" });
    } else if (sortBy === "status") {
      this.setState({ SortBy: "Status" });
    }
    
    this.setState({ filteredData: filterdata });
  };


  render() {
    const columns = [
      {
        dataField: "hotelId",
        hidden: true,
      },
      {
        dataField: "tenantID",
        hidden: true,
      },
      {
        dataField: "hotelName",
        text: "Name",

        formatter: (cell, row) => (
          <div
            onClick={() => this.handleShowSlideout(row)}

            className="entity-table-data d-flex flex-column">
            <EllipsisWithTooltip placement="bottom">
              <span className="hotel-name">{row.hotelName}</span>
            </EllipsisWithTooltip>
            <span className="lettercode">{row.lettercode}</span>
          </div>
        ),
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell, row) => (
          <div onClick={() => this.handleShowSlideout(row)} className="entity-table-data d-flex">
            {row.status == "Active" && (
              <span className="badge color-green">{row.status}</span>
            )}
            {row.status == "Inactive" && (
              <span className="badge color-grey">{row.status}</span>
            )}
          </div>
        ),
      },
      {
        dataField: "hotelType",
        text: "Type",
        formatter: (cell, row) => (
          <div onClick={() => this.handleShowSlideout(row)} className="entity-table-data d-flex">
            <span className="hotel-type">{row.hotelType}</span>
          </div>
        ),
      },
      {
        dataField: "address1",
        text: "Address",
        formatter: (cell, row) => (
          <div onClick={() => this.handleShowSlideout(row)} className="entity-table-data d-flex">
            <EllipsisWithTooltip placement="bottom">
              <span className="hotel-address">{row.address1}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "rooms",
        text: "Rooms",
        formatter: (cell, row) => (
          <div onClick={() => this.handleShowSlideout(row)} className="entity-table-data d-flex">
            <span className="hotel-rooms">{row.rooms}</span>
          </div>
        ),
      },
      {
        dataField: "taxID",
        text: "Tax ID",
        formatter: (cell, row) => (
          <div onClick={() => this.handleShowSlideout(row)} className="entity-table-data d-flex">
            <span className="tax-id">{row.taxID}</span>
          </div>
        ),
      },
      // {
      //   dataField: "",
      //   text: "",
      //   formatter: (cell: any, row: any, rowIndex: any) => (
      //     <Dropdown
      //       onClick={(e) => {
      //         e.stopPropagation();
      //       }}
      //       className="more-action"
      //       alignRight
      //     >
      //       <Dropdown.Toggle
      //         className="btn-outline-primary btn btn-primary btn-ghost more"
      //         id="dropdown-more"
      //       >
      //         <svg
      //           width="20"
      //           height="20"
      //           viewBox="0 0 20 20"
      //           fill="none"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
      //           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
      //           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      //         </svg>
      //       </Dropdown.Toggle>
      //       <Dropdown.Menu>
      //         <Dropdown.Item  onClick={() => this.handleShowSlideout(row)}  key={"edit"} eventKey={"edit"}>
      //           Edit
      //         </Dropdown.Item>
      //       </Dropdown.Menu>
      //     </Dropdown>
      //   ),
      // },
    ];
 
    const { showSlideout, entityRowData, isDataLoading } = this.state;
    return (
      <div className="entity-main">
        

          <div className="section" style={{ display: "flex" }}>
            <div className="main-Page">
              <div className="navigation-menus d-flex">
                <div className="schedule-table-search">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Search..."
                    value={this.state.search}
                    onChange={(e) => this.searchEntity(e)}
                  />
                </div>

                <div className="report-type-dropdown ml-2 mr-2">
                  <Dropdown className="more-action bg-blue">
                    <Dropdown.Toggle id="someId">
                      <div className="d-flex align-items-center">
                        {this.state.Status}
                        <div className="drop-arrow d-flex">
                          <FiChevronDown />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        key={"All"}
                        eventKey={"All"}
                        onClick={(e: any) => this.setStatus("All")}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        key={"Active"}
                        eventKey={"Active"}
                        onClick={(e: any) => this.setStatus("Active")}
                      >
                        Active
                      </Dropdown.Item>
                      <Dropdown.Item
                        key={"Inactive"}
                        eventKey={"Inactive"}
                        onClick={(e: any) => this.setStatus("Inactive")}
                      >
                        Inactive
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="navigationSort ml-auto">
                  <Dropdown className="more-action bg-blue">
                    <Dropdown.Toggle id="dropdown-ptoType">
                      <div className="d-flex align-items-center">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
                        </svg>
                        {this.state.SortBy}
                        <div className="drop-arrow d-flex">
                          <FiChevronDown />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        key={"Name"}
                        eventKey={"Name"}
                        className={this.state.SortBy === "Name" ? "active" : ""}
                        onClick={(e: any) => this.sortData("hotelName")}
                      >
                        <div className="d-flex">
                          <div className="mr-auto">Name</div>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                              fill="#2CAF92"
                            />
                          </svg>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        key={"Status"}
                        eventKey={"Status"}
                        className={
                          this.state.SortBy === "Status" ? "active" : ""
                        }
                        onClick={(e: any) => this.sortData("status")}
                      >
                        <div className="d-flex">
                          <div className="mr-auto">Status</div>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                              fill="#2CAF92"
                            />
                          </svg>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        key={"Type"}
                        eventKey={"Type"}
                        className={this.state.SortBy === "Type" ? "active" : ""}
                        onClick={(e: any) => this.sortData("hotelType")}
                      >
                        <div className="d-flex">
                          <div className="mr-auto">Type</div>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                              fill="#2CAF92"
                            />
                          </svg>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="navigationSettings">
                  <Dropdown className="more-action bg-blue">
                    <Dropdown.Toggle id="dropdown-ptoType">
                      <div className="d-flex align-items-center">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1.56286 10.1808L2.89619 12.4875C3.08019 12.8062 3.48819 12.9155 3.80686 12.7308L4.73752 12.1935C5.12419 12.4982 5.55152 12.7482 6.00086 12.9348V14.0002C6.00086 14.3682 6.29886 14.6668 6.66752 14.6668H9.33419C9.70286 14.6668 10.0009 14.3682 10.0009 14.0002V12.9348C10.4502 12.7482 10.8769 12.4982 11.2642 12.1935L12.1949 12.7308C12.5129 12.9142 12.9222 12.8042 13.1055 12.4875L14.4389 10.1808C14.6222 9.8635 14.5122 9.4535 14.1955 9.27016L13.2809 8.7415C13.3155 8.4955 13.3335 8.2475 13.3335 8.00016C13.3335 7.75283 13.3155 7.50483 13.2795 7.25883L14.1942 6.73016C14.5115 6.54683 14.6215 6.13683 14.4375 5.8195L13.1042 3.51283C12.9202 3.19416 12.5122 3.08483 12.1935 3.2695L11.2629 3.80683C10.8762 3.50216 10.4495 3.2515 10.0002 3.0655V2.00016C10.0002 1.63216 9.70219 1.3335 9.33352 1.3335H6.66686C6.29819 1.3335 6.00019 1.63216 6.00019 2.00016V3.0655C5.55086 3.25216 5.12419 3.50216 4.73686 3.80683L3.80686 3.2695C3.48752 3.08483 3.08019 3.19416 2.89619 3.51283L1.56286 5.8195C1.37953 6.13683 1.48953 6.54683 1.80619 6.73016L2.72086 7.25883C2.68486 7.50483 2.66686 7.75283 2.66686 8.00016C2.66686 8.2475 2.68486 8.4955 2.72086 8.7415L1.80619 9.27016C1.48886 9.4535 1.37886 9.8635 1.56286 10.1808ZM8.00019 5.3335C9.47086 5.3335 10.6669 6.5295 10.6669 8.00016C10.6669 9.47083 9.47086 10.6668 8.00019 10.6668C6.52952 10.6668 5.33352 9.47083 5.33352 8.00016C5.33352 6.5295 6.52952 5.3335 8.00019 5.3335Z" />
                        </svg>
                        <div className="drop-arrow d-flex">
                          <FiChevronDown />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
           <Dropdown.Item onClick={this.saveDefaultView}>
              Save as Default View
           </Dropdown.Item>
           </Dropdown.Menu>

                  </Dropdown>
                </div>
              </div>
              {   isDataLoading ? (
                      <ReactPageLoader useas={"entity-management-entities"} />)
                      :

              (<div className="entity-table">
                {this.state.filteredData.length > 0 && (
                  <div className="max-height">
                    <ToolkitProvider
                      keyField="id"
                      remote
                      data={this.state.filteredData}
                      columns={columns}
                      search
                    >
                      {(toolkitprops: any) => [
                        <BootstrapTable {...toolkitprops.baseProps} />,
                      ]}
                    </ToolkitProvider>
                  </div>
                )}
                {this.state.filteredData.length == 0 && (
                  <div className="no-record">No matching record found</div>
                )}
              </div>)
  }
            </div>
          </div>  

          {showSlideout && <EntityManagementSlideOut entityRowData={entityRowData} handleHideSlideout={this.handleHideSlideout} {...this.props} />
          }
        
      </div>
    );
  }
}
