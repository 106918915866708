import { ILaborSaveAsDefaultViewDto } from "../../../../../Common/Contracts/ILaborSchedule";

export class schAPIcall {
  public static saveAsDefaultView = (
    pageType: any,
    sortValue: any,
    defaultViewValue: any,
    SchduleVarianceValue: any,
    DepartmentValue ?: any,
    PostionValue ?: any
  ) => {
    let setArray: any = [];
    if (pageType === "User") {
      setArray[0] = "Sort";
      setArray[1] = "DefaultView";
    } else if (pageType === "Department") {
      setArray[0] = "Sort";
      setArray[1] = "DefaultView";
      setArray[2] = "SchduleVariance";
    } else if (pageType === "Position") {
      setArray[0] = "Sort";
      setArray[1] = "DefaultView";
      setArray[2] = "SchduleVariance";
      setArray[3] = "Department";
      setArray[4] = "Position";
    }

    let requestArray: any = [];

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);

    setArray.forEach((element: any) => {
      let requestObject = {} as ILaborSaveAsDefaultViewDto;
      requestObject.tenantID = tenantID;
      requestObject.UserUniqueID = user_UniqueID;
      requestObject.pageName = "Schedule";

      switch (element) {
        case "Sort":
          requestObject.fieldName = element;
          requestObject.fieldValue = sortValue;
          break;
        case "DefaultView":
          requestObject.fieldName = element;
          requestObject.fieldValue = defaultViewValue;
          break;
        case "SchduleVariance":
          requestObject.fieldName = element;
          requestObject.fieldValue = SchduleVarianceValue;
          break;
        case "Department":
          requestObject.fieldName = element;
          requestObject.fieldValue = DepartmentValue;
          break;
        case "Position":
          requestObject.fieldName = element;
          requestObject.fieldValue = PostionValue;
          break;

      }
      requestArray.push(requestObject);
    });

    return requestArray;
  };
}
