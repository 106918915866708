import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { Hotel } from "../../Common/Services/Hotel";
import { IAccountPeriodResponcedto, IcoaResponcedto, IHotelResponseDto } from "../../Common/Contracts/IHotel";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

export class AccountingPeriodStart extends React.Component<any, any> {
  private inputElement: any;
  private startingSelector: any;
  private SingleselectChild: any;
  private singleSerch: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.startingSelector = React.createRef();
    this.state = {
      hotelNameModal: this.props?.defaultValue, //Dispaly on account
      filterData: [],
      hotelList: [],
      inputValue: "",
    };
    this.handleChangeEhid = this.handleChangeEhid.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);
  }

  

  componentDidMount() {
    let getUserHotelAccess: any;
    
    getUserHotelAccess=Hotel.getAccotingPeriodForStartandEnd("Account-management",true,this.props?.rptid,this.props?.ddlValue)
    
    getUserHotelAccess
      .then(async (result: IAccountPeriodResponcedto[] | null) => {
        if (result != null && result.length > 0) {
          let hotelList: any[] = [];
          result.forEach((element) => {
            let newItem = {} as any;
            newItem.hotelID = element.date;
            newItem.value = element.date;
            newItem.description = element.date;
           
            hotelList.push(newItem);
          });
          this.setState({ filterData: hotelList, hotelList: hotelList });
          const { defaultValue } = this.props;

          if (+defaultValue) {
              
            const hotel = hotelList.find((r) => +r.hotelID === +defaultValue);
            this.props.isHotelid(hotel);
            this.hidSelectModal(hotel?.description);
          }
        }
      })
      .catch((error) => {
        // Need to handle this
        console.log(error);
      });
  }

  handleChangeEhid(e: { target: { value: string } }) {
    const updatedList = this.state.hotelList.filter((item) => {
      return (
        item.description.toLowerCase().indexOf(e.target.value.toLowerCase()) >
        -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  handleDropdownChange(e: any) {
      
    this.props.isHotelid(e);
    this.resetSerchBox();
  }

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.hotelList });
  };
  componentDidUpdate = (prevProps: any) => {
    const { defaultValue } = this.props;
    if (!defaultValue && prevProps.defaultValue !== defaultValue) {
      this.hidSelectModal("Select");
    }
  };

  hidSelectModal = (eventKey: any) => {
    this.setState({ inputValue: "" });
    this.setState({ hotelNameModal: eventKey });
    
  };

  handleStartingSelectToggle = (eventVal: any) => {
    setTimeout(() => {
      this.startingSelector.current.getElementsByClassName('active')[0]?.focus();
    }, 100);
  }

  render() {
    const onFocusInput = (eventKey: any) => {
      this.resetSerchBox();
      setTimeout(() => {
        this.inputElement?.current?.focus();
      }, 100);
    };

    return (
      <Dropdown
        className="single-select"
        onSelect={this.hidSelectModal}
        onClick={onFocusInput}
        onToggle={(event: any) => this.handleStartingSelectToggle(event)}
      >
        <Dropdown.Toggle id={ this.props?.ddlId? this.props?.ddlId: "Modal-hid"}>
          {this.state.hotelNameModal === "Select Group" && (
            <span className="placeholder">{this.state.hotelNameModal}</span>
          )}
          {this.state.hotelNameModal !== "Select Group" && (
            <EllipsisWithTooltip placement="bottom">
              <span
                style={{
                  width: "95%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                }}
              >
                {this.state.hotelNameModal}
              </span>
            </EllipsisWithTooltip>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu ref={this.startingSelector}>
          { <div className="search-sec">
            <input
              type="text"
              className="form-control"
              onChange={this.handleChangeEhid}
              placeholder={this.props?.rptid === 2078 ? "Filter Accounting Period" : "Filter Starting"}
              value={this.state.inputValue}
              ref={this.inputElement}
            />
          </div> }
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (
              <EllipsisWithTooltip placement="bottom">
                <Dropdown.Item
                  className={
                    `${
                      this.state.filterData[idx - 1]?.hotelType &&
                      this.state.filterData[idx - 1]?.hotelType !== item?.hotelType
                        ? "hotel-separator"
                        : ""
                    }${
                      this.state.hotelNameModal === item.description ? "active" : ""
                    }`
                  }
                  eventKey={item.description}
                  onClick={() => this.handleDropdownChange(item)}
                >
                  {item.description}
                </Dropdown.Item>
              </EllipsisWithTooltip>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
