import React from "react";
import { AddDocument } from "./AddDocument";
import BootstrapTable from "react-bootstrap-table-next";
import { Dropdown, Button } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { IUserDocumentUpload } from "../../../Common/Contracts/IUserDocumentUpload";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { Utils } from "../../../Common/Utilis";

export interface DocumentsProps { }

export class Documents extends React.Component<any, any> {


  state: any = {
    documents: [],
    showDocumentForm: false
  };

  componentDidMount() {
    this.getUserDocumentDetails();
  }

  DownloadUserDocument = (row) => {
    UserManagement.DownloadUserDocument(row.uniqueno, row?.filename, row?.typID).then(() => {

    });
  }

  viewUserDocument = (row) => {
    let uniqueNo = row.uniqueno;
    let tenantID = row.tenantID;
    let etype = "User";
    window.open("/documentViewer?Uniqueno=" + btoa(uniqueNo) + "&tenantID=" + btoa(tenantID) + "&Etype=" + btoa(etype) + "&title=" + btoa("Documents"), "_blank");
  }

  isValidFileExt = (filename) => {
    let isValid = false;
    if (filename !== null || filename !== "") {
      let ext = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
      if (ext.toString().toLowerCase() === "pdf") {
        isValid = true;
      } else if (ext.toString().toLowerCase() === "jpeg" || ext.toString().toLowerCase() === "jpg"
        || ext.toString().toLowerCase() === "png") {
        isValid = true;
      } 
      // else {
      //   isValid = false;
      // }
    }
    return isValid;
  }


  componentWillUnmount = () => {
    // setTimeout(() => {
    //   toast.dismiss();
    // }, 1000)
  }

  rowClasses = (row: any, rowIndex: any) => {
    if (this.state.isExpand === true && row.action === "Expended") {
      return "row-Expended";
    } else if (row.approveButtonSpinner) {
      return "p-none";
    } else if (this.state.isExpand === true) {
      return "row-Collapsed";
    } else {
      return "row-Action";
    }
  };

  getUserDocumentDetails = () => {
    UserManagement.GetUserDocumentDetails(this.props?.uniqueno).then(
      (data: IUserDocumentUpload[] | null) => {
        let _data: IUserDocumentUpload[] = [];
        if ((data as any)?.length > 0) {
          _data = (data as any)?.map((x) => {
            x.date = moment(x.date).format("MM/DD/YY");
            return x;
          }) as IUserDocumentUpload[];
        }
        this.setState({ documents: _data });
      }
    );
  };

  confirmationOnDelete = (row) => {
    confirmAlert({
      title: "Are you sure?",
      message:
        "Are you sure you want to delete this file?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteUserDocument(row),
        },
        {
          label: "No",
          onClick: () => { }
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };


  deleteUserDocument = (row) => {
    UserManagement.DeleteUserDocument(row.iF_Record, "Main")
      .then((response) => {
        if (response?.result?.success) {
          toast.success("File deleted successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
          this.getUserDocumentDetails();
        } else {
          Utils.toastError(response?.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(`Server Error, ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      });
  };

  handleShowHideDocumentForm = () => {
    this.setState({ showDocumentForm: !this.state?.showDocumentForm });
  }

  render() {
    const { documents } = this.state;

    let documentColumns = [
      {
        dataField: "date",
        text: "Date",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <span className="document-date">{cell}</span>
          </div>
        ),
      },
      {
        dataField: "eType",
        text: "Type",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <EllipsisWithTooltip placement="bottom">
              <span className="document-type">{cell}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },

      {
        dataField: "description",
        text: "Description",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            <EllipsisWithTooltip placement="bottom">
              <span className="document-description">{cell}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className="more-action"
            alignRight
          >
            <Dropdown.Toggle
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.props.hasDeletePermission &&
                <Dropdown.Item
                  key={row?.status}
                  onClick={() => this.confirmationOnDelete(row)}
                  eventKey={"status"}>
                  Delete
                </Dropdown.Item>
              }
              <Dropdown.Item
                key={row?.status}
                onClick={() => this.DownloadUserDocument(row)}
                eventKey={"status"}>
                Download
              </Dropdown.Item>
              {this.isValidFileExt(row?.filename) && (
                <Dropdown.Item
                  key={row?.status}
                  onClick={() => this.viewUserDocument(row)}
                  eventKey={"status"}>
                  View
                </Dropdown.Item>
              )}

            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];
    return (
      <>
        <div className="documents-tab">

          <div className="body-section">
            <div className="heading">Documents</div>
            <div className="add-documents-wrapper">
              {(this.props.isEditDocumentInfo || this.props?.isNewUserAdded) && (
                <>
                  <Button
                    className="btn-ghost"
                    onClick={(e: any) => this.handleShowHideDocumentForm()}>
                    Add Document
                  </Button>
                  {this.state.showDocumentForm &&
                    <AddDocument
                      getUserDocumentDetails={this.getUserDocumentDetails}
                      uniqueno={this.props?.uniqueno}
                      showDocumentForm={this.state.showDocumentForm}
                      roleId={this.props.userGeneralInfo.role}
                      hireDate={this.props.userGeneralInfo?.hireDate}
                      handleShowHideDocumentForm={this.handleShowHideDocumentForm}
                    />
                  }
                </>
              )}
            </div>
            <div className="add-documents-table">
              <BootstrapTable
                keyField="uniqueno"
                data={documents}
                columns={documentColumns}
                //expandRow={expandRow}
                rowClasses={this.rowClasses}
                hover
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
