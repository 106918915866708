import React from "react";
import {
  Container,
  Dropdown,
  Button,
  Form,
  Table,
  Modal,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../../Common/Components/PageLoader";
import { Hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import {
  LaborTimeOff,
  LaborTimeOffDetails,
  // LabourTimeOffApprovePTORequest,
  // LabourTimeOffUpdatePTORequestEntries,
} from "../../Common/Services/LabourTimeOff";
import {
  ILabourTimeOffRequestDto,
  // ILabourTimeOffApprovePTORequest,
  // ILabourTimeOffUpdatePTORequestEntries,
} from "../../Common/Contracts/ILabourTimeOff";
import { RoleSubPermissionSetting } from "../../Common/Services/RoleSubPermissionSetting";
import { resolve, reject } from "q";
import RouteLeavingGuard from "../../Common/Components/RouteLeavingGuard";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
//import calenderGrey from "../../Common/Assets/Images/icons/calendar_grey.svg";
import { LaborForecast } from "../../Common/Services/LaborForecast";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { IForecastRequestDto, ITimeOffPermission, } from "../../Common/Contracts/ILaborForecast";
import { HidSelector } from "../../Common/Components/HidSelector";
import TableSearch from "../../Common/Components/TableSearch";
import ForecastLock from "../../Common/Assets/Images/icons/forecast-lock.svg";
import $ from "jquery";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import profile from "../../Common/Assets/Images/profile.png";

import { LaborSchedule } from "../../Common/Services/LaborSchedule";
// import {ILaborScheduleEmployeeRequestDto,ISaveEditPTORequest} from "../../Common/Contracts/ILaborSchedule";
import { LaborPerformance as laborPerformance } from "../../Common/Services/LaborPerfomance";
import moment from "moment-timezone";
import deleteIcon from '../../Common/Assets/Images/icons/delete.svg';
import _ from "lodash";
import { Utils } from "../../Common/Utilis";

// let newSearchResult: any = [];
let hotelDetailsData: any;
let routeLeavingGuard: any = null;
export class TimeOffRequests extends React.Component<any, any> {
  private smileIdChildLeft: any;
  private child: any;
  private childHID: any;
  
  constructor(props: any) {
    super(props);
    this.smileIdChildLeft = React.createRef();
    this.child = React.createRef();
    this.childHID = React.createRef();
   
    this.state = {
      ptoTypeFilterClass: "",
      hidValue: "Select",
      hotelName: "Select",
      hidList: [],
      tableData: [],
      allPendingData: [],
      allData: [],
      rightPanelRow: [],
      sortBy: "username",
      calenderShow: false,
      calenderDates: [],
      calenderSelectDate: {},
      filterStatus: "Pending",
      typefilterStatus: "",
      startDate: "",
      endDate: "",
      panelDecline: false,
      panelApprove: false,
      lfRowuser: "",
      dsValue: "",
      dsError: "",
      asValue: "",
      asError: "",
      PTOSummery: "Add Comments",
      PTORequestID: "",
      PTOuserName: "",
      PTOuserStatus: "",
      rightPanelRowData: { rightPanelRowTop: [], rightPanelRowBottom: [] },
      PTORequestEntriesedit: [],
      reCalenderShow: false,
      inOutPtoDuration: "",
      timeDuration: '',
      edtPTOstartTime: "00:00",
      edtPTOendTime: "00:00",
      showNewEntry: false,
      outSaveHandler: false,
      rowClickScheduleType: "",
      reEditPto: { reCalcStartDate: "", reCalcEndDate: "", inTime: "", outTime: "" },
      reEditPtoErr: { reCalcStartDate: false, reCalcEndDate: false, inTime: false, outTime: false },
      currentPageIndex: 0,
      filterList: [
        { id: "Approved", value: "Approved", status: false },
        { id: "Declined", value: "Declined", status: false },
        { id: "Pending", value: "Pending", status: true },
        { id: "All", value: "All", status: false },
      ],
      navigationCalenderDates: [],
      ptoTypefilterList: [],
      isPtoUtoAccess: false,
      isDnsAccess: false,
      permissionManageSchedule: false,
      permissionManageTemplates: false,
      permissionManageTimeOff: false,
      permissionPublishSchedule: false,
      isPagePermission: false,
      clickedRow: [],
      changeValue: false,
      modalShiftOverlaps: false,
      modalShiftOverlapsData: "",
      localLocation: this.props.location.state,
      currentDate:
        this.props.location.state === null ||
          this.props.location.state === undefined
          ? ""
          : "All",
      formHasError: false,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    let oPValue = this.state.localLocation;
    if (oPValue === null || oPValue === undefined) {

      let todayDate: any = new Date();
      if (JSON.parse(localStorage.storage).currentUtcTime !== "") {
        todayDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
      }
      let currentdate = todayDate.getDate();
      let currentMonth = todayDate.getMonth() + 1;
      let currentYear = todayDate.getFullYear();
      let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
      this.setState({ currentDate: tempDate, calenderSelectDate: todayDate });
      this.getTimeOffPermission("standalone");
    } else {
      this.getTimeOffPermission("overview");
      let todayDate: any = new Date();
      let request = {} as any;
      request.Hid = oPValue.hid;
      request.SelectDateFrom = this.state.currentDate;
      request.SelectDateTo = this.state.currentDate;
      request.FilterStatus = this.state.filterStatus;
      request.TimeOffFilterStatus = this.state.typefilterStatus === "Do Not Schedule" ? "DNS" : this.state.typefilterStatus; //this.state.typefilterStatus;
      let hotelDetails: any[] = [];
      let details: any = {};
      details.ehidName = `${oPValue.hid} -${oPValue.pname}`;
      details.hotelID = oPValue.hid;
      details.hotelName = `${oPValue.lettercode} - ${oPValue.pname}`;
      details.lettercode = oPValue.lettercode;
      details.tenantID = 0;
      details.timeZone = "EST";
      hotelDetails.push(details);
      sessionStorage.setItem("hotelSelected", JSON.stringify(hotelDetails[0]));
      localStorage.setItem("hotelSelected", JSON.stringify(hotelDetails[0]));
      this.setState({ calenderSelectDate: todayDate }, () => {
        this.selectHotelDropDown(hotelDetails[0]);
        this.getTimeOffRequest(request, this.state.sortBy);
      });
    }
    this.loadHotelsDetails();
    console.warn = () => { };
    let tapedUser: any = {};
    tapedUser = sessionStorage.getItem("tapedUser");
    if (this.state.tableData.length > 0) {
      if (tapedUser !== null) {
        let currentRow = JSON.parse(tapedUser);
        let PTORequestID = currentRow.ptoRequestID;
        let userName = currentRow.username;
        let PTOStatus = currentRow.status;
        let scheduleType: string = currentRow.scheduleType;
        if (PTORequestID !== this.state.PTORequestID) {
          this.clearSession();
        } else {
          this.getrightPanelRequest(
            PTORequestID,
            userName,
            PTOStatus,
            scheduleType
          );
        }
      }
    } else {
      this.clearSession();
    }
  }


  getTimeOffPermission = (callType: any) => {
    let requestObject = {} as ITimeOffPermission;
    requestObject.permissionID = 10006;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";

    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject).then(async (result: any | null) => {
      if (result != null && result.length > 0) {
        result.forEach((element: any) => {
          switch (element.permissionName) {
            case "Manage Schedule":
              this.setState({ permissionManageSchedule: true }); // This permission is for DNS
              break;
            case "Manage Templates":
              this.setState({ permissionManageTemplates: true });
              break;
            case "Manage Time Off (PTO & UTO)":
              this.setState({ permissionManageTimeOff: true }); // This permission is for PTO & UTO
              break;
            case "Publish Schedule":
              this.setState({ permissionPublishSchedule: true });
              break;
          }
        });

        if (this.state.permissionManageTimeOff) {
          let dataPTO = {
            id: "PTO",
            value: "PTO",
            status: false,
          };
          let dataUTO = {
            id: "UTO",
            value: "UTO",
            status: false,
          };

          this.state.ptoTypefilterList.push(dataPTO, dataUTO);
        }
        if (this.state.permissionManageSchedule) {
          let status = true;
          if (this.state.permissionManageTimeOff) {
            status = false;
          }
          let dataDNS = {
            id: "Do Not Schedule",
            value: "Do Not Schedule",
            status: status,
          };
          this.setState({
            typefilterStatus: "Do Not Schedule",
          });
          this.state.ptoTypefilterList.push(dataDNS);
        }
        if (this.state.permissionManageTimeOff) {
          let dataALL = {
            id: "All",
            value: "All",
            status: true,
          };
          this.setState({ typefilterStatus: "All" });
          this.state.ptoTypefilterList.push(dataALL);
        }
        if (this.state.ptoTypefilterList.length === 3) {
          this.setState({
            ptoTypeFilterClass: "ptoType-filter2",
          });
        } else {
          this.setState({
            ptoTypeFilterClass: "ptoType-filter1",
          });
        }
        if (callType === "overview") {
          let oPValue = this.state.localLocation;
          let todayDate: any = new Date();
          if (JSON.parse(localStorage.storage).currentUtcTime !== "") {
            todayDate = new Date(
              JSON.parse(localStorage.storage).currentUtcTime
            );
          }

          let request = {} as any;
          request.Hid = oPValue.hid;
          request.SelectDateFrom = this.state.currentDate;
          request.SelectDateTo = this.state.currentDate;
          request.FilterStatus = this.state.filterStatus;
          request.TimeOffFilterStatus = this.state.typefilterStatus === "Do Not Schedule" ? "DNS" : this.state.typefilterStatus;;
          let hotelDetails: any[] = [];
          let details: any = {};
          details.ehidName = `${oPValue.hid} -${oPValue.pname}`;
          details.hotelID = oPValue.hid;
          details.hotelName = `${oPValue.lettercode} - ${oPValue.pname}`;
          details.lettercode = oPValue.lettercode;
          details.tenantID = 0;
          details.timeZone = "EST";
          hotelDetails.push(details);
          sessionStorage.setItem(
            "hotelSelected",
            JSON.stringify(hotelDetails[0])
          );
          localStorage.setItem(
            "hotelSelected",
            JSON.stringify(hotelDetails[0])
          );
          this.setState({ calenderSelectDate: todayDate }, () => {
            this.selectHotelDropDown(hotelDetails[0]);
            this.getTimeOffRequest(request, this.state.sortBy);
          });
        }
      }
      resolve();
    })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "timeoffRequest",
        });
        reject();
      });
  };

  clearSession = () => {
    sessionStorage.removeItem("tapedUser");
    this.setState({
      PTORequestID: "",
      rightPanelRowData: { rightPanelRowTop: [], rightPanelRowBottom: [] },
    });
  };

  handleFilterChange = (event: any, value: any) => {
    this.setState({ filterStatus: value.value, changeValue: false });
    this.CleanSearch();
    this.clearSession();
    let data = this.state.filterList;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === value.id) {
        data[i].status = true;
      } else {
        data[i].status = false;
      }
    }
    this.setState({
      filterList: data,
      filterStatus: value.value,
      tableData: [],
    });
    let request = {} as any;
    request.Hid = this.state.hidValue;
    request.SelectDateFrom =
      this.state.currentDate === "All"
        ? this.state.currentDate
        : this.state.startDate;
    request.SelectDateTo =
      this.state.currentDate === "All"
        ? this.state.currentDate
        : this.state.endDate;
    request.FilterStatus = value.value;
    //request.TimeOffFilterStatus = this.state.typefilterStatus;
    request.TimeOffFilterStatus = this.state.typefilterStatus === "Do Not Schedule" ? "DNS" : this.state.typefilterStatus;
    this.getTimeOffRequest(request, this.state.sortBy);
  };

  handleTimeOffTypeFilterChange = (event: any, value: any) => {

    this.setState({ typefilterStatus: value.value, changeValue: false });
    this.CleanSearch();
    this.clearSession();
    let data = this.state.ptoTypefilterList;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === value.id) {
        data[i].status = true;
      } else {
        data[i].status = false;
      }
    }

    this.setState({
      ptoTypefilterList: data,
      typefilterStatus: value.value,
      tableData: [],
    });
    let request = {} as any;
    request.Hid = this.state.hidValue;
    request.SelectDateFrom =
      this.state.currentDate === "All"
        ? this.state.currentDate
        : this.state.startDate;
    request.SelectDateTo =
      this.state.currentDate === "All"
        ? this.state.currentDate
        : this.state.endDate;
    request.FilterStatus = this.state.filterStatus;
    request.TimeOffFilterStatus = value.value === "Do Not Schedule" ? "DNS" : value.value;
    this.getTimeOffRequest(request, this.state.sortBy);
  };

  getTimeOffRequest(request: ILabourTimeOffRequestDto, sortType: string) {
    request.sortType = sortType;
    this.setState({ loaderTrue: true });
    LaborTimeOff.GetLaborTimeOffList(request)
      .then(async (result: any | null) => {
        if (result.message === "Success") {
          let data = result.result;
          if (
            this.state.filterStatus.toLowerCase() === "all" ||
            this.state.filterStatus.toLowerCase() === "Approved" ||
            this.state.filterStatus.toLowerCase() === "Declined"
          ) {
            if (this.state.filterStatus.toLowerCase() !== "all") {
              data = data.filter(
                (item: { status: any }) =>
                  item.status === this.state.filterStatus
              );
            }
          }
          if (data != null) {
            this.setState({ tableData: [] }, () =>
              this.setState({ tableData: data })
            );
            this.setState({ activeClassRow: "" });
            let tapedUser: any = {};
            tapedUser = sessionStorage.getItem("tapedUser");
            if (this.state.tableData.length > 0) {
              if (tapedUser !== null) {
                let currentRow = JSON.parse(tapedUser);
                let PTORequestID = currentRow.ptoRequestID;
                let userName = currentRow.username;
                let PTOStatus = currentRow.status;
                let scheduleType: string = currentRow.scheduleType;
                this.getrightPanelRequest(
                  PTORequestID,
                  userName,
                  PTOStatus,
                  scheduleType
                );
              }
            }
            this.setState({
              panelApprove: false,
              panelDecline: false,
              showNewEntry: false,
              inOutPtoDuration: "",
              timeDuration: '',
              reEditPto: {
                reCalcStartDate: "",
                reCalcEndDate: "",
                inTime: "",
                outTime: ""
              },
            });
          } else {
            this.setState({ tableData: [] });
            this.setState({
              rightPanelRowData: {
                rightPanelRowTop: [],
                rightPanelRowBottom: [],
              },
            });
            this.setState({
              panelApprove: false,
              panelDecline: false,
              showNewEntry: false,
              inOutPtoDuration: "",
              timeDuration: '',
              reEditPto: {
                reCalcStartDate: "",
                reCalcEndDate: "",
                inTime: "",
                outTime: ""
              },
            });
          }
          //this.eventRowData(data[0]); //show right panel with first row selected in left
          this.CleanSearch();
        } else {
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.setState({ loaderTrue: false });
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  loadHotelsDetails() {
    Hotel.getUserHotelAccess("Labor Management")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          this.setState({ hidList: result }, () => { });
        }
        resolve();
        this.setState({ loaderTrue: false });
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  selectHotelDropDown = (hotelDetails: any) => {
    let currDate = this.state.currentDate;
    if (this.state.changeValue) {
      this.setState({ isHandleForecast: "selectHotelDropDown" });
      hotelDetailsData = hotelDetails;
      this.isValidateModelPoupTab();
    } else {
      this.clearSession();
      this.childHID?.current?.updateChildState(hotelDetails);
      if (currDate === "All") {
        let todayDate: any = new Date();
        if (JSON.parse(localStorage.storage).currentUtcTime !== "") { todayDate = new Date(JSON.parse(localStorage.storage).currentUtcTime) }
        this.setState(
          {
            hotelName: hotelDetails.lettercode,
            hidValue: hotelDetails.hotelID,
            changeValue: false,
            currentDate: "All",
            calenderSelectDate: todayDate,
          },
          () => {
            let request = {} as IForecastRequestDto;
            request.hotelID = this.state.hidValue;
            request.date = this.state.currentDate;
            this.getForcastData(request);
          }
        );
        this.setState({ panelApprove: false, panelDecline: false });
      } else {
        let todayDate: any = new Date();
        if (JSON.parse(localStorage.storage).currentUtcTime !== "") {
          todayDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
        }
        let currentdate = todayDate.getDate();
        let currentMonth = todayDate.getMonth() + 1;
        let currentYear = todayDate.getFullYear();
        let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
        this.setState(
          {
            changeValue: false,
            hotelName: hotelDetails.lettercode,
            hidValue: hotelDetails.hotelID,
            currentDate: tempDate,
            calenderSelectDate: todayDate,
          },
          () => {
            let request = {} as IForecastRequestDto;
            request.hotelID = this.state.hidValue;
            request.date = this.state.currentDate;
            this.getForcastData(request);
          }
        );
      }
    }
  };

  getForcastData(request: IForecastRequestDto) {
    if (request.date === "All") {
      let request = {} as any;
      request.Hid = this.state.hidValue;
      request.SelectDateFrom = this.state.currentDate;
      request.SelectDateTo = this.state.currentDate;
      request.FilterStatus = this.state.filterStatus;
      request.TimeOffFilterStatus = this.state.typefilterStatus === "Do Not Schedule" ? "DNS" : this.state.typefilterStatus; //this.state.typefilterStatus;
      this.getTimeOffRequest(request, this.state.sortBy);
    } else {
      this.setState({ loaderTrue: true });
      LaborForecast.GetLaborForecastSummary(request).then(async (result: any) => {
        let calnderDatesDispaly: any = [];
        if (result != null && result.length > 0) {
          let displayDates: any = result[0].payPeriodDate.split("_");
          calnderDatesDispaly = displayDates;
          let request = {} as any;
          request.Hid = this.state.hidValue;
          request.SelectDateFrom = displayDates[0];
          request.SelectDateTo = displayDates[1];
          request.FilterStatus = this.state.filterStatus;
          request.TimeOffFilterStatus = this.state.typefilterStatus === "Do Not Schedule" ? "DNS" : this.state.typefilterStatus;//this.state.typefilterStatus;
          this.getTimeOffRequest(request, this.state.sortBy);
          this.setState({
            calenderDates: calnderDatesDispaly,
            startDate: displayDates[0],
            endDate: displayDates[1],
          });
        }

        this.setState({ loaderTrue: false });
        resolve();
      })
        .catch((error: any) => {
          reject();
          this.setState({ loaderTrue: false });
        });
    }
  }
  CleanSearch = () => {
    if (this.child.current != null) {
      this.child.current.CleanSearch();
    }
  };

  /*Right Panel---------------------------------------------------------------------------------------*/
  getrightPanelRequest = (PTORequestID: any, userName: any, PTOStatus: any, scheduleType: any) => {
    this.setState({ loaderTrue: true });
    let IsPTOID = "1";
    let tapedUser: any = {};
    tapedUser = sessionStorage.getItem("tapedUser");
    let currentRow = JSON.parse(tapedUser);
    LaborTimeOffDetails.GetTimeOffRequestDetails(
      PTORequestID,
      this.state.currentDate,
      IsPTOID
    )
      .then(async (result: any | null) => {
        let data = result.result;
        if (result.success) {
          let rightPanelTop = [
            {
              username: userName,
              employeeType: data.employeeType,
              ptoRequestID: data.ptoRequestID,
              postApprovalBalance: data.postBalance,
              ptoStatus: currentRow.status,
              filterStatus: currentRow.status,
              scheduleType: scheduleType,
            },
          ];
          let rightPanelBottom = [
            {
              ptoType: data.ptoType,
              ptoResion: data.ptoResion,
              postApprovalBalance: data.postBalance,
              ptoRequestDate: data.ptoRequestDate,
              ptoBalance: data.ptoBalance,
              managerComment: data.managerComment,
              duration: data.duration,
              timeOffRequestDateTime: data.timeOffRequestDateTime,
              imageURL: data.imageURL,
              ptoSubmitted: data.ptoRequestDate,
              ptoReason: data.ptoResion,
              ptoPostApprovalBalance: data.postBalance,
              overlapData: data.overlapData,
              overlapType: data.overlapType,
            },
          ];
          this.setState({ PTOuserStatus: PTOStatus });
          this.setState({
            rightPanelRowData: {
              rightPanelRowTop: rightPanelTop,
              rightPanelRowBottom: rightPanelBottom,
            },
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "timeoffRequest",
        });
      });
  };
  rowEvents = {
    onClick: (e: any, row: any) => {
      this.getPayPeroid();
      if (this.state.changeValue) {
        this.setState({ clickedRow: row });
        this.isValidateModelPoupTab();
      } else {
        sessionStorage.setItem("tapedUser", JSON.stringify(row));
        this.setState({ showNewEntry: false, inOutPtoDuration: "", timeDuration: '' });
        this.eventRowData(row);
        let PTORequestID: number = row.ptoRequestID;
        let userName: string = row.username;
        let PTOStatus: string = row.status;
        this.setState({ PTORequestID: row.ptoRequestID });
        let scheduleType: string = row.scheduleType;
        this.getrightPanelRequest(
          PTORequestID,
          userName,
          PTOStatus,
          scheduleType
        );
        this.setState({
          panelApprove: false,
          rowClickScheduleType: scheduleType,
        });
        this.setState({ panelDecline: false });
        this.setState({ lfRowuser: userName });
      }
    },
  };


  getPayPeroid() {
    let hotelID = this.state.hidValue;
    let currentDay: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "") {
      currentDay = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    laborPerformance.getPayPeriodDates(hotelID, currentDay, "Weekly").then(async (result: any[] | null) => {
      if (result != null) {
        this.setState({ navigationCalenderDates: [result[0].startdate, result[0].enddate] });
      }
    });
  }

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeUnsaved(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  eventRowData(row: any) {
    if (row !== undefined) {
      if (!row.action) {
        let array: any[] = [];
        let newItem: any = {};
        newItem.action = row.action;
        newItem.date = row.startDate;
        newItem.ptoRequestID = row.ptoRequestID;
        newItem.username = row.username;
        newItem.empTitle = row.empTitle;
        newItem.status = row.status;
        newItem.duration = row.duration;
        newItem.hotelId = this.state.hidValue;
        //newItem.entryType = row.entryType;
        array.push(newItem);
        this.setState({
          activeClassRow: row.ptoRequestID,
          rightPanelRow: array,
        });
      }
    }
  }
  leftSelectedRow = (row: any) => {
    this.eventRowData(row);
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };
  calenderShow = () => {
    this.setState({ calenderShow: true });
  };
  // recalenderHide = () => { this.setState({ reCalenderShow: false }) };

  setCalanderDate(date) {
    let calenderYear = date.getFullYear();
    let calenderDate = date.getDate();
    let calenderMonth = date.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
    this.setState(
      {
        calenderSelectDate: tempDate,
        currentDate: tempDate,
        calenderShow: false,
      },
      () => {
        let request = {} as IForecastRequestDto;
        request.hotelID = this.state.hidValue;
        request.date = tempDate;
        this.getForcastData(request);
      }
    );
  }

  calenderChange = (date: any) => {
    this.clearSession();
    if (this.state.currentDate === "All") {
      window.history.replaceState(null, "/");
      this.setState({ localLocation: "" }, () => {
        this.setCalanderDate(date);
      });
    } else {
      this.setCalanderDate(date);
    }
  };
  searchedData = (newResult: any) => {
    if (newResult.length > 0) {
      let newRowIndex = 0;
      this.setState({ isFirstRowSelected: true });
      for (let i = 0; i < newResult.length; i++) {
        if (newResult[i].action === false) {
          newRowIndex = i;
          break;
        }
      }
      this.setState({ activeClassRow: newResult[newRowIndex].uniqueno });
      if (newResult.length > 0) {
        this.leftSelectedRow(newResult[newRowIndex]);
      }
    } else {
      let blankRow: any = {};
      blankRow.employee = "";
      blankRow.action = "";
      this.setState({ activeClassRow: "" });
      this.leftSelectedRow(blankRow);
    }
    //newSearchResult = newResult;
  };
  /*Decline and Approve Panels*/
  cSummery = () => {
    this.setState({ dsError: "" });
    this.setState({ asError: "" });
    this.setState({ dsValue: "" });
    this.setState({ asValue: "" });
  };
  openDecline = (e) => {
    let { panelDecline } = this.state;
    this.cSummery();
    this.setState({
      panelDecline: !panelDecline,
      panelApprove: false,
      showNewEntry: false,
      inOutPtoDuration: "",
      timeDuration: '',
      reEditPto: {
        reCalcStartDate: "",
        reCalcEndDate: "",
        inTime: "",
        outTime: ""
      }
    });
  };
  openApprove = () => {
    let { panelApprove } = this.state;
    this.cSummery();
    this.setState({
      panelApprove: !panelApprove,
      panelDecline: false,
      showNewEntry: false, inOutPtoDuration: "", timeDuration: '',
      reEditPto: { reCalcStartDate: "", reCalcEndDate: "", inTime: "", outTime: "" }
    });
  };
  closeCusModal = () => {
    this.cSummery();
    this.setState({ panelApprove: false, panelDecline: false });
  };
  dsValidate = (value) => {
    if (value === "" || value === undefined) {
      this.setState({ dsError: "Please provide an explanation." });
    } else if (value.length > 200) {
      this.setState({ dsError: "Maximum 200 characters allowed." });
    } else {
      return value;
    }
  };

  asValidate = (value) => {
    if (value === "" || value === undefined) {
      this.setState({ asError: "Please provide an explanation." });
    } else if (value.length > 200) {
      this.setState({ asError: "Maximum 200 characters allowed." });
    } else {
      return value;
    }
  };
  declineChange = (event) => {
    let { value } = event.target;
    this.setState({ dsValue: value.trimStart() });
  };
  declineSubmit = (event: any) => {
    let tapedUser: any = {};
    tapedUser = sessionStorage.getItem("tapedUser");
    let currentRow = JSON.parse(tapedUser);
    let currentRowDate = currentRow.startDate;
    let {
      dsValue,
      calenderDates,
      PTORequestID,
      rowClickScheduleType,
    } = this.state;
    let msg = "";
    if (rowClickScheduleType === "3" || rowClickScheduleType === "4") {
      msg = "PTO";
    } else if (rowClickScheduleType === "5" || rowClickScheduleType === "6") {
      msg = "UTO";
    } else if (rowClickScheduleType === "8" || rowClickScheduleType === "10") {
      msg = "DNS";
    }
    let ptoStatusDeclined = "Declined";
    //let _date = new Date();
    event.preventDefault();
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let ptoRqstid = PTORequestID;
    let ptoUsrStatus = ptoStatusDeclined;
    let selectDate = currentRowDate;
    let currentuserName = storage.userName;
    let managerComment = this.dsValidate(dsValue);
    if (this.dsValidate(dsValue)) {
      this.setState({ dsError: "" });
      this.setState({ panelDecline: false });
      this.setState({ PTOSummery: this.dsValidate(dsValue) });
      //let request = {} as ILabourTimeOffApprovePTORequest;
      let request: any[] = [];
      let newItem: any = {
        HotelID: this.state.hidValue,
        ptoRequestID: ptoRqstid,
        Status: ptoUsrStatus,
        selectDate: selectDate,
        loginUser: currentuserName,
        managerComment: managerComment,
        IsPTOID: "1",
        pageSource: '',
      }
      request.push(newItem);
      //LabourTimeOffApprovePTORequest.ApprovePTORequest(request)
      LaborSchedule.RaiseTimeOff(request)
        .then(async (result: any | null) => {
          if (result.success) {
            let data = result.result;
            if (data.toLowerCase() === "dayapprove") {
              Utils.toastError("TimeOff can not be declined as day is approved.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              });
            } else if (data.toLowerCase() === "deptapprove") {
              Utils.toastError(
                "TimeOff can not be declined as department/day is approved.",
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "timeoffRequest",
                }
              );
            } else if (data.toLowerCase() === "scheduleoverlap") {
              Utils.toastError(
                msg + " overlap with user schedule, Request can't decline.",
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "timeoffRequest",
                }
              );
            } else if (data.toLowerCase() === "actualoverlap") {
              Utils.toastError(
                msg + " overlap with Actual Punch-in, Request can't decline.",
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "timeoffRequest",
                }
              );
            } else if (data.toLowerCase() === "fail") {
              Utils.toastError("Request Failed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              });
            } else if (data.toLowerCase() === "success") {
              this.setState({
                rightPanelRowData: {
                  rightPanelRowTop: [],
                  rightPanelRowBottom: [],
                },
              });
              this.setState({
                panelApprove: false,
                panelDecline: false, tableData: [], showNewEntry: false,
                inOutPtoDuration: "", timeDuration: '', reEditPto: { reCalcStartDate: "", reCalcEndDate: "", inTime: "", outTime: "" },
              });
              toast.success(msg + " Request Declined.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              });
              let request = {} as IForecastRequestDto;
              request.hotelID = this.state.hidValue;
              request.date =
                this.state.currentDate === "All"
                  ? this.state.currentDate
                  : calenderDates[0];
              this.getForcastData(request);
              this.clearSession();
            } else {
              Utils.toastError(data, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              });
            }
          }
        })
        .catch((error) => {
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeoffRequest",
          });
        });
    }
  };
  approveChange = (event) => {
    let { value } = event.target;
    this.setState({ asValue: value.trimStart() });
  };
  handleModalShiftClose = () => {
    this.setState({ modalShiftOverlaps: false });
  };

  approveSubmit = (event, type) => {
    
    
    let tapedUser: any = {};
    tapedUser = sessionStorage.getItem("tapedUser");
    let currentRow = JSON.parse(tapedUser);
    let currentRowDate = currentRow.startDate;
    let {
      asValue,
      calenderDates,
      PTORequestID,
      rowClickScheduleType,
    } = this.state;
    let msg = "";
    if (rowClickScheduleType === "3" || rowClickScheduleType === "4") {
      msg = "PTO";
    } else if (rowClickScheduleType === "5" || rowClickScheduleType === "6") {
      msg = "UTO";
    } else if (rowClickScheduleType === "8" || rowClickScheduleType === "10") {
      msg = "DNS";
    }
    let ptoStatusApproved = "Approved";
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let ptoRqstid = PTORequestID;
    let ptoUsrStatus = ptoStatusApproved;
    let selectDate = currentRowDate;
    let currentuserName = storage.userName;
    let managerComment;
    if (type === "Yes") {
      managerComment = this.asValidate(type);
    } else {
      managerComment = this.asValidate(asValue);
    }
    event.preventDefault();

    if (type === "No"  || this.asValidate(managerComment)) {
      this.setState({
        asError: "",
        panelApprove: false,
        PTOSummery: this.asValidate(asValue),
      });
      let request: any[] = [];
      let newItem: any = {
        HotelID: this.state.hidValue,
        ptoRequestID: ptoRqstid,
        Status: ptoUsrStatus,
        selectDate: selectDate,
        loginUser: currentuserName,
        managerComment: type === "No" ? "":managerComment,
        OverlapeUpdate: type,
        PageSource: "TimeOFF"
      }
      request.push(newItem);

      //LabourTimeOffApprovePTORequest.ApprovePTORequest(request)
      LaborSchedule.RaiseTimeOff(request).then(async (result: any | null) => {
        this.setState({
          modalShiftOverlaps: false,
          modalShiftOverlapsData: "",
        });
        if (result.success) {
          let data = result.result;
          let hasTable = data.includes("<table>");
          let hasString = data.includes(
            "already approved Time Off shift overlaps with this one"
          );

          if (data === "dayapprove") {
            Utils.toastError("TimeOff can not be approved as day is approved.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "timeoffRequest",
            });
          } else if (hasTable) {
            this.setState({
              modalShiftOverlaps: true,
              modalShiftOverlapsData: data,
            });
          } else if (hasString) {
            this.setState({
              modalShiftOverlaps: true,
              modalShiftOverlapsData: data,
            });
          } else if (data.toLowerCase() === "deptapprove") {
            Utils.toastError(
              "TimeOff can not be approved as department is approved.",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              }
            );
          } else if (data.toLowerCase() === "exceed") {
            Utils.toastError(
              "Maximum " + msg + " Hours for a week can not exceed 40 hours.",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              }
            );
          } else if (data.toLowerCase() === "scheduleoverlap") {
            Utils.toastError(
              msg + " overlap with user schedule, Request can't approve.",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              }
            );
          } else if (data.toLowerCase() === "actualoverlap") {
            Utils.toastError(
              msg + " overlap with Actual Punch-in, Request can't approve.",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              }
            );
          } else if (data.toLowerCase() === "fail") {
            Utils.toastError("Request Failed.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "timeoffRequest",
            });
          } else if (data.toLowerCase() === "success") {
            this.setState({
              rightPanelRowData: {
                rightPanelRowTop: [],
                rightPanelRowBottom: [],
              },
            });
            this.setState({
              panelApprove: false,
              panelDecline: false,
              tableData: [],
              showNewEntry: false,
              inOutPtoDuration: "",
              timeDuration: '',
              reEditPto: { reCalcStartDate: "", reCalcEndDate: "", inTime: "", outTime: "" },
            });
            toast.success(msg + " Request Approved.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "timeoffRequest",
            });
            let request = {} as IForecastRequestDto;
            request.hotelID = this.state.hidValue;
            request.date =
              this.state.currentDate === "All"
                ? this.state.currentDate
                : calenderDates[0];
            this.getForcastData(request);
            this.clearSession();
          } else {
            if(data!==""){
              console.log( "t" +data)
              if(data.length > 3){
            Utils.toastError(data, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "timeoffRequest",
            });
          }
          }
          }
         
        }
      })
        .catch((error) => {
         
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeoffRequest",
          });
        });
       
    }
  };

  labelPanelDecline = () => {
    let {
      panelDecline,
      dsValue,
      dsError,
      // permissionManageTimeOff,
    } = this.state;
    const { rightPanelRowTop } = this.state.rightPanelRowData;
    let ptoType = rightPanelRowTop[0].scheduleType;
    let isPermission = false;
    if (ptoType === "3" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "4" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "5" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "6" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    } else if (
      ptoType === "10" &&
      this.state.permissionManageSchedule === true
    ) {
      isPermission = true;
    }

    return (
      <div className="customDropdown">
        {isPermission && (
          <button
            type="button"
            className="btn btn-danger cusBtnDanger"
            onClick={this.openDecline.bind(this)}
          >
            Decline
          </button>
        )}

        {panelDecline ? (
          <div className="hasForm">
            <Form noValidate onSubmit={this.declineSubmit}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <div className="clearfix">
                  <Form.Label>Reason to Decline</Form.Label>
                </div>
                <Form.Control
                  as="textarea"
                  placeholder="Explanation required..."
                  name="decline"
                  value={dsValue}
                  onChange={this.declineChange}
                  rows={3}
                  required
                />
                <span className="small text-danger">{dsError}</span>
              </Form.Group>
              <Form.Group className="text-right seprator">
                <Button
                  variant="light"
                  type="button"
                  onClick={this.closeCusModal.bind(this)}
                >
                  Cancel
                </Button>
                <Button variant="danger" type="submit">
                  Decline
                </Button>
              </Form.Group>
            </Form>
          </div>
        ) : null}
      </div>
    );
  };

  labelPanelApprove = () => {
    let {
      panelApprove,
      asValue,
      asError,
      // permissionManageRequest
      /*, PTOSummery*/
    } = this.state;
    const { rightPanelRowTop } = this.state.rightPanelRowData;
    let ptoType = rightPanelRowTop[0].scheduleType;
    let isPermission = false;
    if (ptoType === "3" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "4" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "5" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    }
    if (ptoType === "6" && this.state.permissionManageTimeOff === true) {
      isPermission = true;
    } else if (
      ptoType === "10" &&
      this.state.permissionManageSchedule === true
    ) {
      isPermission = true;
    }
    return (
      <div className="customDropdown">
        {isPermission && (
          <Button  className="btn-success" onClick={(e) => this.approveSubmit(e, "No")}>
            Approve
          </Button>
        )}

        {/* {panelApprove ? (
          <div className="hasForm">
            <Form noValidate onSubmit={(e) => this.approveSubmit(e, "No")}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <div className="clearfix">
                  <Form.Label>Reason to Approve</Form.Label>
                </div>
                <Form.Control
                  as="textarea"
                  placeholder="Explanation required..."
                  name="decline"
                  value={asValue}
                  onChange={this.approveChange}
                  rows={3}
                  required
                />
                <span className="small text-danger">{asError}</span>
              </Form.Group>
              <Form.Group className="text-right seprator">
                <Button
                  variant="light"
                  type="button"
                  onClick={this.closeCusModal.bind(this)}
                >
                  Cancel
                </Button>
                <Button className="btn-success" type="submit">
                  Approve
                </Button>
              </Form.Group>
            </Form>
          </div>
        ) : null} */}
      </div>
    );
  };
  /*Decline and Velidate*/
  dotsSVG = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      </svg>
    );
  };
  croseSVG = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
          fill="#84888C"
        />
      </svg>
    );
  };
  resetPopupData = (time) => {
    let { PTORequestID } = this.state;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let PTORequestEntriesedit = {
      ptoReqID: PTORequestID,
      reqID: time.requestID,
      loginUser: storage.userName,
      ptoDate: time.startDate,

    };
    this.setState({
      PTORequestEntriesedit: PTORequestEntriesedit,
      edtPTOstartTime: time.inTime24Hrs,
      edtPTOendTime: time.outTime24Hrs,
      showNewEntry: false,
      inOutPtoDuration: "",
      timeDuration: ''
    });
  };
  editPTOStarttime = (e) => {
    this.setState({ edtPTOstartTime: e.target.value });
  };
  editPTOEndtime = (e) => {
    this.setState({ edtPTOendTime: e.target.value });
  };
  formatDate(time) {
    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    let replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  }

  editPTORequestSubmit = (e: any, row: any) => {
    e.preventDefault();
    let {
      PTORequestEntriesedit,
      edtPTOstartTime,
      edtPTOendTime,
      filterStatus,
      lfRowuser,
      rowClickScheduleType,
      navigationCalenderDates
    } = this.state;
    let msg = "";
    let Type = "";
    if (rowClickScheduleType === "3" || rowClickScheduleType === "4") {
      msg = "PTO";
      Type = "UpdateRequestPTOTimeOff";
    } else if (rowClickScheduleType === "5" || rowClickScheduleType === "6") {
      msg = "UTO";
      Type = "UpdateRequestUTOTimeOff";
    } else if (rowClickScheduleType === "8" || rowClickScheduleType === "10") {
      msg = "DNS";
      Type = "UpdateRequestDNSTimeOff";
    }
    
    /// Change end date when time is selected next date

    let startdate1 = row.startDate;

    let startTime1 = this.state.edtPTOstartTime;
    let endTime1 = this.state.edtPTOendTime;

    let startdate2 = moment(startdate1).format("MM/DD/YYYY");
    let inMomentTime = moment(
      `${startdate2} ${startTime1}`
    );
    let outMomentTime = moment(
      `${startdate2} ${endTime1}`
    );

    let differenceInTime = outMomentTime.diff(inMomentTime);

    let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
    if (differenceInTime <= 0) {
      punchOutDate = moment(startdate2)
        .add(1, "days")
        .format("MM/DD/YYYY");
    }

    ///==============
    

    let startdateTime = new Date(new Date(row.startDate).toLocaleDateString("en-US") + " " + edtPTOstartTime);
    //let endDateTime = new Date(new Date(row.endDate).toLocaleDateString("en-US") + " " + edtPTOendTime);
    let endDateTime = new Date(new Date(punchOutDate).toLocaleDateString("en-US") + " " + edtPTOendTime);

    let isOverlap=  this.cheackOverlapEdit(startdateTime, endDateTime,row);


    const totalMinutes = this.timeDiffCalc(startdateTime, endDateTime);
    if (totalMinutes < 0) {
      Utils.toastError("Start Time can't be greater than End Time.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "timeoffRequest",
      });
    } else if (totalMinutes === 0) {
      Utils.toastError("Start Time can't be same as End Time.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "timeoffRequest",
      });
    } else if (totalMinutes > 480 && msg !== "DNS") {
      Utils.toastError(msg + " for a day can't be greater than 8:00 hours.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "timeoffRequest",
      });
    } else if (isOverlap ===false) {
      Utils.toastError("Time of request is overlapping.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "timeoffRequest",
      });
    }
      else {
      this.formatDate(this.state.edtPTOstartTime);
      //let request = {} as ILabourTimeOffUpdatePTORequestEntries;
      let request: any[] = [];
      let newItem: any = {
        HotelID: this.state.hidValue,
        PTORequestID: parseInt(PTORequestEntriesedit.ptoReqID),
        RequestID: parseInt(PTORequestEntriesedit.reqID),
        InTime: this.formatDate(this.state.edtPTOstartTime),
        OutTime: this.formatDate(this.state.edtPTOendTime),
        StartDate: row.startDate,
        //EndDate: row.endDate,
        EndDate: punchOutDate,
        LoginUser: PTORequestEntriesedit.loginUser,
        Type: "Edit",
        PTODate: PTORequestEntriesedit.ptoDate,
        IsPTOID: "1",
        WeekStartDate: navigationCalenderDates[0],
        Status: '',
        pageSource: Type
      }
      request.push(newItem);
      LaborSchedule.RaiseTimeOff(request)
        .then(async (result: any | null) => {
          let resMessage: any = result.result;
          if (result.success && resMessage.toString().toLowerCase() === "Success".toLocaleLowerCase()) {
            toast.success(msg + " time has been saved successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "timeoffRequest",
            });
            let PTORequestID: number = parseInt(
              PTORequestEntriesedit.ptoReqID
            );
            //let cuserName: string = lfRowuser;
            let PTOStatus: string = filterStatus;
            //let scheduleType: string = row.scheduleType;
            this.getrightPanelRequest(
              PTORequestID,
              lfRowuser,
              PTOStatus,
              rowClickScheduleType
            );
            this.setState({ changeValue: false });
            $(".tableHasDropdown.show").trigger("click");
            let { calenderDates, hidValue } = this.state;
            let request = {} as IForecastRequestDto;
            request.hotelID = hidValue;
            request.date = this.state.currentDate === "All" ? this.state.currentDate : calenderDates[0];
            this.getForcastData(request);
          } else {
            Utils.toastError(resMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "timeoffRequest",
            });
          }
        }).catch((error) => {
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeoffRequest",
          });
        });
    }
  };

  RemovePTOEntry = (time) => {
    let {
      PTORequestID,
      filterStatus,
      lfRowuser,
      navigationCalenderDates,
      rowClickScheduleType,
    } = this.state;
    let msg = "";
    if (rowClickScheduleType === "3" || rowClickScheduleType === "4") {
      msg = "PTO";
    } else if (rowClickScheduleType === "5" || rowClickScheduleType === "6") {
      msg = "UTO";
    } else if (rowClickScheduleType === "8" || rowClickScheduleType === "10") {
      msg = "DNS";
    }
    this.setState({ showNewEntry: false });
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let PTORequestEntriesedit = {
      ptoReqID: PTORequestID,
      reqID: time.requestID,
      loginUser: storage.userName,
      ptoDate: time.startDate,

    };
    //let request = {} as ILabourTimeOffUpdatePTORequestEntries;
    let request: any[] = [];
    let newItem: any = {
      HotelID: this.state.hidValue,
      PTORequestID: PTORequestEntriesedit.ptoReqID,
      RequestID: PTORequestEntriesedit.reqID,
      InTime: this.formatDate(this.state.edtPTOstartTime),
      OutTime: this.formatDate(this.state.edtPTOendTime),
      LoginUser: PTORequestEntriesedit.loginUser,
      Type: "Delete",
      StartDate: time.startDate,
      EndDate: time.endDate,
      PTODate: PTORequestEntriesedit.ptoDate,
      IsPTOID: "1",
      WeekStartDate: navigationCalenderDates[0],
      Status: '',
      pageSource: ''
    }
    request.push(newItem);
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this Entry?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            LaborSchedule.RaiseTimeOff(request)
              .then(async (result: any | null) => {
                let data = result;
                if (data.success && data !== null) {
                  toast.success(msg + " entry delete successfully.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "timeoffRequest",
                  });
                  let PTORequestID: number = parseInt(
                    PTORequestEntriesedit.ptoReqID
                  );
                  let cuserName: string = lfRowuser;
                  let PTOStatus: string = filterStatus;
                  this.getrightPanelRequest(
                    PTORequestID,
                    cuserName,
                    PTOStatus,
                    rowClickScheduleType
                  );
                  let { calenderDates, hidValue } = this.state;
                  let request = {} as IForecastRequestDto;
                  request.hotelID = hidValue;
                  request.date = this.state.currentDate === "All" ? this.state.currentDate : calenderDates[0];
                  this.getForcastData(request);
                } else {
                  Utils.toastError(data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "timeoffRequest",
                  });
                }
              })
              .catch((error) => {
                Utils.toastError(error.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "timeoffRequest",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
    });
  };

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  isChangeUnsaved = () => {
    if (this.state.isHandleForecast === "selectHotelDropDown") {
      this.setState({
        changeValue: false,
      }, () => {
        this.selectHotelDropDown(hotelDetailsData);
      });
    } else {
      this.setState({ changeValue: false }, () => {
        this.rowEvents.onClick("e", this.state.clickedRow);
      });
    }
  };

  addNewEntry = (clickType) => {
    if (clickType === "Cancel") {
      this.setState({ changeValue: false, showNewEntry: false, inOutPtoDuration: '', timeDuration: '' });
    } else {
      this.setState({
        changeValue: true,
        showNewEntry: true,
        outSaveHandler: false,
        reEditPto: { reCalcStartDate: "", reCalcEndDate: "", inTime: "", outTime: "" },
        reEditPtoErr: { reCalcStartDate: false, reCalcEndDate: false, inTime: false, outTime: false },
        inOutPtoDuration: "",
        timeDuration: ''
      });
    }
  };

  changeDate = () => {
    this.setState({ calenderShow: !this.state.calenderShow });
  };

  reChangeDate = () => {
    this.setState({ reCalenderShow: !this.state.reCalenderShow });
  };

  reEditTime = (e: any) => {
    let { name, value } = e.target;
    let { reEditPto } = this.state;
    reEditPto[name] = value;

   // if (name === "outTime") {
      let startdate1 = reEditPto.reCalcStartDate;

      let startTime1 = reEditPto.inTime;
      let endTime1 = reEditPto.outTime;

      let startdate2 = moment(startdate1).format("MM/DD/YYYY");
      let inMomentTime = moment(
        `${startdate2} ${startTime1}`
      );
      let outMomentTime = moment(
        `${startdate2} ${endTime1}`
      );

      let differenceInTime = outMomentTime.diff(inMomentTime);

      let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
      if (differenceInTime <= 0) {
        punchOutDate = moment(startdate2)
          .add(1, "days")
          .format("MM/DD/YYYY");
      }

      reEditPto["reCalcEndDate"] = punchOutDate;

   // }

    this.setState({ reEditPto });
    this.setState({ outSaveHandler: false });
  };

  handleSelectDate = (event: any, dateType: any) => {
    let { reEditPto } = this.state;
    reEditPto[dateType] = event;
    if (dateType === "reCalcStartDate") {
      reEditPto.reCalcEndDate = ''
      this.handleFormValidation();
    }
    if (dateType === "reCalcEndDate") {
      reEditPto.outTime = ''
    }
    this.setState({ inOutPtoDuration: '', timeDuration: '', reEditPto, outSaveHandler: false });
    $(".schduleInput").attr("maxlength", "10");
  };
  timeDiffCalc = (startDateTime, endDateTime) => {
    let dateTime = endDateTime - startDateTime;
    let min = Math.floor(dateTime / 1000 / 60);
    return min;
  }

  mintoHour = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    let hour = h;// < 10 ? '0' + h : h
    let minute = m < 10 ? '0' + m : m;
    return `${hour}:${minute}`;
  }

  cheackOverlap = (startDateTime, endDateTime) => {
    //debugger;
    //let { tableData } = this.state;
    let isValidate = true;
    let tableData = [...this.state.rightPanelRowData.rightPanelRowBottom[0].timeOffRequestDateTime];
    //tableData.splice(index, 1);

    for (let i = 0; i < tableData.length; i++) {


      let srartdateA: any = new Date(new Date(tableData[i].startDate).toLocaleDateString("en-US") + " " + tableData[i].inTime);
      let endDateA: any = new Date(new Date(tableData[i].endDate).toLocaleDateString("en-US") + " " + tableData[i].outTime);


      let startDateTimeB: any = new Date(startDateTime);
      let endDateTimeB: any = new Date(endDateTime);
      if (srartdateA < endDateTimeB && endDateA > startDateTimeB) {
        isValidate = false;
        break;
      } 
    }


    return isValidate;


  }


  cheackOverlapEdit = (startDateTime, endDateTime,row) => {
    //debugger;
    //let { tableData } = this.state;
    let isValidate = true;
    let tableData = [...this.state.rightPanelRowData.rightPanelRowBottom[0].timeOffRequestDateTime];
   let rowIndex = tableData.findIndex(
      (xvalue) => xvalue.requestID === row.requestID
    );
   tableData.splice(rowIndex,1);
    for (let i = 0; i < tableData.length; i++) {


      let srartdateA: any = new Date(new Date(tableData[i].startDate).toLocaleDateString("en-US") + " " + tableData[i].inTime);
      let endDateA: any = new Date(new Date(tableData[i].endDate).toLocaleDateString("en-US") + " " + tableData[i].outTime);


      let startDateTimeB: any = new Date(startDateTime);
      let endDateTimeB: any = new Date(endDateTime);
      if (srartdateA < endDateTimeB && endDateA > startDateTimeB) {
        isValidate = false;
        break;
      } 
    }


    return isValidate;


  }








  handleFormValidation = () => {
    let { reEditPto, reEditPtoErr, rightPanelRowData, rowClickScheduleType } = this.state;
    let { reCalcStartDate, reCalcEndDate, inTime, outTime } = reEditPto;
    let msg = "";
    if (rowClickScheduleType === "3" || rowClickScheduleType === "4") {
      msg = "PTO";
    } else if (rowClickScheduleType === "5" || rowClickScheduleType === "6") {
      msg = "UTO";
    } else if (rowClickScheduleType === "8" || rowClickScheduleType === "10") {
      msg = "DNS";
    }
    var date_regex1 = /^([1-9]|1[0-2])\/([1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    var date_regex2 = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}/;
    if (reCalcStartDate === "") {
      reEditPtoErr.reCalcStartDate = true;
      return;
    }
    if (
      reCalcStartDate !== "Invalid Date" &&
      reCalcStartDate !== "" &&
      reCalcStartDate !== "1/1/1970" &&
      inTime !== "" &&
      reCalcEndDate !== "Invalid Date" &&
      reCalcEndDate !== "" &&
      reCalcEndDate !== "1/1/1970" &&
      outTime !== ""
    ) {
      reEditPtoErr.reCalcStartDate = false;
      reEditPtoErr.reCalcEndDate = false;
      reEditPtoErr.inTime = false;
      reEditPtoErr.outTime = false;
      if (
        !date_regex1.test(reCalcStartDate.toLocaleDateString("en-US")) &&
        !date_regex2.test(reCalcStartDate.toLocaleDateString("en-US")) &&
        !date_regex1.test(reCalcEndDate.toLocaleDateString("en-US")) &&
        !date_regex2.test(reCalcEndDate.toLocaleDateString("en-US"))) {
        Utils.toastError("Please provide date in mm/dd/yyyy format", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "timeoffRequest",
        });
      } else {
        let startdateTime = new Date(new Date(reCalcStartDate).toLocaleDateString("en-US") + " " + inTime);
        let endDateTime = new Date(new Date(reCalcEndDate).toLocaleDateString("en-US") + " " + outTime);
        const totalMinutes = this.timeDiffCalc(startdateTime, endDateTime);
        if (totalMinutes < 0) {
          Utils.toastError("Start Time can't be greater than End Time.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeoffRequest",
          });
          reEditPtoErr.inTime = true;
          reEditPtoErr.outTime = true;
          // return;
        }
        if (totalMinutes === 0) {
          Utils.toastError("Start Time can't be same as End Time.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeoffRequest",
          });
          reEditPtoErr.inTime = true;
          reEditPtoErr.outTime = true;
          //return;
        }
        if (totalMinutes > 480 && msg !== "DNS") {
          Utils.toastError("Request for a day can't be greater than 8:00 hours.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
          reEditPtoErr.inTime = true;
          reEditPtoErr.outTime = true;
          //return;
        }

        let isOverlap=  this.cheackOverlap(startdateTime, endDateTime);
        if (isOverlap ===false) {
          Utils.toastError("Time of request is overlapping.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeoffRequest",
          });
          reEditPtoErr.inTime = true;
          reEditPtoErr.outTime = true;
           return;
        }
        this.setState({ inOutPtoDuration: totalMinutes, timeDuration: this.mintoHour(totalMinutes) })
      }

    } else {
      if (reCalcStartDate !== "" && reCalcStartDate !== "Invalid Date" && reCalcStartDate !== "1/1/1970") {
        let ptoStartDate = reCalcStartDate.toLocaleDateString("en-US");
        let day = ptoStartDate.split("/")[0] < 10 ? "0" + ptoStartDate.split("/")[0] : ptoStartDate.split("/")[0];
        let month = ptoStartDate.split("/")[1] < 10 ? "0" + ptoStartDate.split("/")[1] : ptoStartDate.split("/")[1];
        let year = ptoStartDate.split("/")[2];
        let newCalcDate = day + "/" + month + "/" + year;
       // let x = rightPanelRowData.rightPanelRowBottom[0].timeOffRequestDateTime.filter((items: { startDate: string }) => items.startDate === newCalcDate);
        // if (x.length > 0) {
        //   Utils.toastError("User already added " + msg + " for " + newCalcDate, {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     containerId: "timeoffRequest",
        //   });
        //   reEditPtoErr.reCalcStartDate = true;
        //   return;
        // }
        reEditPtoErr.reCalcStartDate = false;
      } else {
        reEditPtoErr.reCalcStartDate = true;
        return
      }
      if (inTime === "") {
        reEditPtoErr.inTime = true;
        return;
      } else {
        reEditPtoErr.inTime = false;
      }
      if (reCalcEndDate !== "" && reCalcEndDate !== "Invalid Date" && reCalcEndDate !== "1/1/1970") {
        reEditPtoErr.reCalcEndDate = false;
      } else {
        reEditPtoErr.reCalcEndDate = true;
        return;
      }
      if (outTime === "") {
        reEditPtoErr.outTime = true;
        return;
      } else {
        reEditPtoErr.outTime = false;
      }
    }
    this.setState({ reEditPtoErr })
  }
  submitNewPTOEntry = (e: any) => {
    
    e.preventDefault();
    let { reEditPto, PTORequestID, filterStatus, lfRowuser, navigationCalenderDates, rowClickScheduleType, inOutPtoDuration, reEditPtoErr } = this.state;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    reEditPto.reCalcStartDate === "" && (reEditPtoErr.reCalcStartDate = true);
    this.setState({ reEditPtoErr })
    let calcStartDate = new Date(reEditPto.reCalcStartDate).toLocaleDateString("en-US");
    let calcEndDate = new Date(reEditPto.reCalcEndDate).toLocaleDateString("en-US");
    let inTime = reEditPto.inTime;
    let outTime = reEditPto.outTime;
    let ptoReqId = parseInt(PTORequestID);
    let msg = "";
    let Type = "";
    if (rowClickScheduleType === "3" || rowClickScheduleType === "4") {
      msg = "PTO";
      Type = "RequestedPTOTimeOff";
    } else if (rowClickScheduleType === "5" || rowClickScheduleType === "6") {
      msg = "UTO";
      Type = "RequestedUTOTimeOff";
    } else if (rowClickScheduleType === "8" || rowClickScheduleType === "10") {
      msg = "DNS";
      Type = "RequestedDNSTimeOff";
    }
    if (msg === "DNS" || (inOutPtoDuration !== "" && (inOutPtoDuration > 0 && inOutPtoDuration < 481))) {
      let request: any[] = [];
      let newItem: any = {
        HotelID: this.state.hidValue,
        InTime: this.formatDate(inTime),
        OutTime: this.formatDate(outTime),
        IsPTOID: "1",
        LoginUser: storage.userName,
        PTODate: calcStartDate,
        StartDate: calcStartDate,
        EndDate: calcEndDate,
        PTORequestID: ptoReqId,
        RequestID: 0,
        TenantID: storage.tenantID,
        Type: "Add",
        WeekStartDate: navigationCalenderDates[0],
        WeekEndDate: navigationCalenderDates[1],
        Status: '',
        PageSource: Type,
      }
      request.push(newItem);
      this.setState({ outSaveHandler: true });
      LaborSchedule.RaiseTimeOff(request)
        .then(async (result: any | null) => {
          let PTORequestID: number = ptoReqId;
          let cuserName: string = lfRowuser;
          let PTOStatus: string = filterStatus;
          let data = result.result;
          if (result.success) {
            this.setState({ changeValue: false });
            if (data.toLowerCase() === "success") {
              toast.success(msg + " entry added successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              });
              this.getrightPanelRequest(
                PTORequestID,
                cuserName,
                PTOStatus,
                rowClickScheduleType
              );
              this.setState({
                reCalcStartDate: "",
                reCalcEndDate: "",
                inTime: "",
                outTime: "",
                showNewEntry: false,
                inOutPtoDuration: "",
                timeDuration: ''
              });
              let { calenderDates, hidValue } = this.state;
              let request = {} as IForecastRequestDto;
              request.hotelID = hidValue;
              request.date = calenderDates[0];
              this.getForcastData(request);
            } else if (data.toLowerCase() === "approved") {
              Utils.toastError(msg + " has already been approved.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              });
            } else if (data.toLowerCase() === "declined") {
              Utils.toastError(msg + " has already been declined.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              });
            } else {
              this.setState({ outSaveHandler: false });
              Utils.toastError(data, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "timeoffRequest",
              });
            }
          }
        })
        .catch((error) => {
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeoffRequest",
          });
        });
    }
  };

  panelDeclineHide = () => {
    this.setState({ panelDecline: false });
  };
  panelApproveHide = () => {
    this.setState({ panelApprove: false });
  };




  render() {
    let tapedUser: any = {};
    tapedUser = sessionStorage.getItem("tapedUser");
    let currentRow = JSON.parse(tapedUser);
    const rowClasses = (row: any, rowIndex: number) => {
      if (tapedUser !== null) {
        if (row.ptoRequestID === currentRow.ptoRequestID) {
          return     "active-row";
        } else if (row.action) {
          return "faded-row";
        } else {
          return;
        }
      }
    };
    let loadShow: any;
    if (this.state.loaderTrue) {
      loadShow = <PageLoader />;
    }
    
    
    const columns = [
      {
        dataField: "username",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdEmpDetails"> 
              <span className="title">
                <EllipsisWithTooltip placement="bottom">
                  {row.username}
                </EllipsisWithTooltip> 
              </span> 
            <span className="desc textCamelCase">
              <EllipsisWithTooltip placement="bottom">
                {row.empTitle.toLowerCase()}
              </EllipsisWithTooltip>
            </span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdMatchPerDetails">
            <span className="title1">{row.startDate.slice(" ")}</span>
            <br />
            <span className="desc">Start Date</span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdMatchPerDetails">
            <EllipsisWithTooltip placement="bottom">
              <span className="title1">
                {parseInt(row.duration) < 10
                  ? "0" + row.duration
                  : row.duration}
              </span>
            </EllipsisWithTooltip>
            <span className="desc">Duration</span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        hidden:
          this.state.typefilterStatus.toLowerCase() === "all" ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          let labelValue = "";
          if (row.scheduleType === "3" || row.scheduleType === "4") {
            labelValue = "PTO";
          } else if (row.scheduleType === "5" || row.scheduleType === "6") {
            labelValue = "UTO";
          } else if (row.scheduleType === "8" || row.scheduleType === "10") {
            labelValue = "DNS";
          }
          return (
            <>
            <div
              
              style={{
                marginTop:
                  this.state.filterStatus.toLowerCase() === "all" &&
                    this.state.typefilterStatus.toLowerCase() === "all"
                    ? 4
                    : 0,
              }}
            >
              <div>{labelValue}</div>
         
              {/* {row.employeeType  ==="Contractor" &&
                this.state.filterStatus.toLowerCase() !== "all" &&
                  <span className="badge contractor-badge ml-0 mt-1">
                    <EllipsisWithTooltip placement="bottom">
                      {row.employeeType}
                    </EllipsisWithTooltip>
                  </span> 
              } */}
            </div>
            
            </>
          );
        },
      },
      {
        dataField: "",
        text: "",
        hidden: this.state.filterStatus.toLowerCase() === "all" ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <div className="h-auto">
                {row.status === "Pending" && (
                  <div className="red-badges ml-0 orange-bg">{row.status}</div>
                )}
                {row.status === "Declined" && (
                  <div className="red-badges ml-0">{row.status}</div>
                )}
                {row.status === "Approved" && (
                  <div className="red-badges ml-0 green-bg">{row.status}</div>
                )}
              </div>
              {row.employeeType  ==="Contractor" &&
this.state.filterStatus.toLowerCase() === "all" &&
                <span className="badge contractor-badge ml-0 mt-1">
                  <EllipsisWithTooltip placement="bottom">
                    {row.employeeType}
                  </EllipsisWithTooltip>
                </span> 
              }
            
            </> 
          );
        },
      },

      {
        dataField: "",
        text: "",
       hidden: this.state.filterStatus.toLowerCase() !== "all"  
       ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              {row.employeeType  ==="Contractor" &&
                <span className="badge contractor-badge ml-0 mt-1">
                  <EllipsisWithTooltip placement="bottom">
                    {row.employeeType}
                  </EllipsisWithTooltip>
                </span> 
              }
            
            </> 
          );
        },
      },

    ];
    const { tableData, filterStatus, reEditPto, reEditPtoErr, inOutPtoDuration, timeDuration,rowClickScheduleType } = this.state;
    const {
      rightPanelRowTop,
      rightPanelRowBottom,
    } = this.state.rightPanelRowData;
    let rightPaneluserName;
    let rightPanelBalance;
    let rightPanelPTPStatus;
    let rightPanelScheduleType;
    let rightPanelEmployeeType;
    if (
      typeof rightPanelRowBottom !== "undefined" &&
      rightPanelRowBottom.length > 0
    ) {
      rightPanelBalance = rightPanelRowBottom[0].postApprovalBalance
        .split(" ")[0]
        .split(":")[0];
    }
    if (
      typeof rightPanelRowTop !== "undefined" &&
      rightPanelRowTop.length > 0
    ) {
      rightPaneluserName = rightPanelRowTop[0].username;
      rightPanelEmployeeType =rightPanelRowTop[0]?.employeeType;
      rightPanelPTPStatus = rightPanelRowTop[0].ptoStatus;
      rightPanelScheduleType = rightPanelRowTop[0].scheduleType;
    }
    let isPermissionforEdit = false;
    if (
      rightPanelScheduleType === "3" ||
      rightPanelScheduleType === "4" ||
      rightPanelScheduleType === "5" ||
      rightPanelScheduleType === "6"
    ) {
      if (this.state.permissionManageTimeOff === true) {
        isPermissionforEdit = true;
      } else {
        isPermissionforEdit = false;
      }
    } else if (rightPanelScheduleType === "10") {
      if (this.state.permissionManageSchedule === true) {
        isPermissionforEdit = true;
      } else {
        isPermissionforEdit = false;
      }
    }
    return (
      <>
        <RouteLeavingGuard
          when={this.state.changeValue}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
            if (this.state.changeValue) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />
        <div className="time-off-request-page-wrapper">
          <ToastContainer autoClose={3000} containerId="timeoffRequest" />
          {loadShow}
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
                alertComponent={this.state.changeValue}
              />
              <div className="mr-auto d-flex align-items-center">
                Time Off Requests
              </div>
            </div>
            {this.state.hidValue !== "Select" && (
              <div>
                <div className="performance-position performance">
                  <div className="body-sec">
                    <div className="main-Page">
                      <div className="search-header d-flex">
                        <div className="calender-container ml-0">
                          <div
                            className={
                              this.state.calenderShow
                                ? "calender-display open"
                                : "calender-display"
                            }
                            onClick={this.calenderShow}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                              <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                            </svg>

                            {this.state.currentDate === "All" ? (
                              <span className="prev-Date">All Dates</span>
                            ) : (
                              <>
                                <span className="prev-Date">
                                  {this.state.calenderDates[0]}
                                </span>
                                <span className="seperator"> - </span>
                                <span className="next-Date">
                                  {this.state.calenderDates[1]}
                                </span>
                              </>
                            )}
                            <span className="chevron-down">
                              <FiChevronDown />
                            </span>
                          </div>
                          {this.state.calenderShow && (
                            <div className="calender">
                              <OutsideClickHandler
                                onOutsideClick={this.calenderHide}
                              >
                                <Calendar
                                  onChange={this.calenderChange}
                                  value={
                                    new Date(this.state.calenderSelectDate)
                                  }
                                />
                              </OutsideClickHandler>
                            </div>
                          )}
                        </div>
                        <div className="position-filter status-filter select">
                          <Dropdown
                            className={`more-action bg-blue ptoPositionFilter ${this.state.filterStatus.toLowerCase()}`}
                            alignRight
                          >
                            <Dropdown.Toggle
                              id="dropdown-ptoType"
                              className="d-flex align-items-center"
                            >
                              <div className="d-flex align-items-center">
                                <svg
                                  width="14"
                                  height="10"
                                  viewBox="0 0 14 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M1 2C0.447715 2 0 1.55228 0 0.999999C0 0.447715 0.447715 0 0.999999 0H12.3328C12.8851 0 13.3328 0.447715 13.3328 0.999999C13.3328 1.55228 12.8851 2 12.3328 2H1Z" />
                                  <path d="M3 6C2.44771 6 2 5.55228 2 5C2 4.44771 2.44771 4 3 4H10.3328C10.8851 4 11.3328 4.44771 11.3328 5C11.3328 5.55228 10.8851 6 10.3328 6H3Z" />
                                  <path d="M5.66601 10C5.11373 10 4.66602 9.55229 4.66602 9C4.66602 8.44772 5.11373 8 5.66602 8H7.66601C8.2183 8 8.66601 8.44772 8.66601 9C8.66601 9.55229 8.2183 10 7.66601 10H5.66601Z" />
                                </svg>
                                <div
                                  className={`${this.state.filterStatus} badges`}
                                >
                                  {this.state.filterStatus}
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div className="ttl">STATUS</div>
                              {this.state.filterList.map(
                                (item: any, idx: any) => (
                                  <Dropdown.Item
                                    className={item.status ? "active" : ""}
                                    key={idx}
                                    eventKey={item.value}
                                    onClick={(e: any) =>
                                      this.handleFilterChange(e, item)
                                    }
                                  >
                                    <div className="d-flex">
                                      <div className="mr-auto">
                                        {" "}
                                        {item.value}
                                      </div>
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                          fill="#2CAF92"
                                        />
                                      </svg>
                                    </div>
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div
                          className={[
                            this.state.ptoTypeFilterClass,
                            "status-filter select",
                          ].join(" ")}
                        >
                          <Dropdown
                            className={`more-action bg-blue ptoPositionFilter ptoTypeFilter ${this.state.typefilterStatus.toLowerCase()}`}
                            alignRight
                          >
                            <Dropdown.Toggle
                              id="dropdown-ptoType"
                              className="d-flex align-items-center"
                            >
                              <div className="d-flex align-items-center">
                                <svg
                                  width="14"
                                  height="10"
                                  viewBox="0 0 14 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M1 2C0.447715 2 0 1.55228 0 0.999999C0 0.447715 0.447715 0 0.999999 0H12.3328C12.8851 0 13.3328 0.447715 13.3328 0.999999C13.3328 1.55228 12.8851 2 12.3328 2H1Z" />
                                  <path d="M3 6C2.44771 6 2 5.55228 2 5C2 4.44771 2.44771 4 3 4H10.3328C10.8851 4 11.3328 4.44771 11.3328 5C11.3328 5.55228 10.8851 6 10.3328 6H3Z" />
                                  <path d="M5.66601 10C5.11373 10 4.66602 9.55229 4.66602 9C4.66602 8.44772 5.11373 8 5.66602 8H7.66601C8.2183 8 8.66601 8.44772 8.66601 9C8.66601 9.55229 8.2183 10 7.66601 10H5.66601Z" />
                                </svg>
                                <div
                                  className={`${this.state.typefilterStatus} badges`}
                                >
                                  {this.state.typefilterStatus}
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div className="ttl">TIME OFF TYPE</div>
                              {this.state.ptoTypefilterList.map(
                                (item: any, idx: any) => (
                                  <Dropdown.Item
                                    className={item.status ? "active" : ""}
                                    key={idx}
                                    eventKey={item.value}
                                    onClick={(e: any) =>
                                      this.handleTimeOffTypeFilterChange(
                                        e,
                                        item
                                      )
                                    }
                                  >
                                    <div className="d-flex">
                                      <div
                                        className="mr-auto"
                                        style={{
                                          background: "none",
                                          color: "#1d1e20",
                                        }}
                                      >
                                        {" "}
                                        {item.value}
                                      </div>
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                          fill="#2CAF92"
                                        />
                                      </svg>
                                    </div>
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="budget-settings smileIdOverview">
                  <div className="budget-settings d-flex justify-content-start">
                    <div className="left-panel">
                      <div className="smileid-left-panel">
                        <div>
                          <ToolkitProvider
                            keyField={`toolkitProvider_${this.state.tableData.map(
                              (items) => items.ptoRequestID
                            )}`}
                            data={ _.orderBy(tableData,["username"])}
                            columns={columns}
                            rowEvents={this.rowEvents}
                            search={{
                              afterSearch: (newResult: any) => {
                                this.searchedData(newResult);
                              },
                            }}
                          >
                            {(props: {
                              searchProps: JSX.IntrinsicAttributes;
                              baseProps: JSX.IntrinsicAttributes;
                            }) => (
                              <React.Fragment>
                                <div className="search-header d-flex">
                                  <TableSearch
                                    id={`tableSearchid_${this.state.tableData.map(
                                      (items) => items.ptoRequestID
                                    )}`}
                                    key={`tableSearch_${this.state.tableData.map(
                                      (items) => items.ptoRequestID
                                    )}`}
                                    ref={this.child}
                                    {...props.searchProps}
                                    CleanSearch={this.CleanSearch.bind(this)}
                                    placeholder={"Search..."}
                                  />
                                </div>
                                <div
                                  className={
                                    this.state.typefilterStatus.toLowerCase() ===
                                      "all" &&
                                      this.state.filterStatus.toLowerCase() ===
                                      "all"
                                      ? "left-body cus4rowTableWithType"
                                      : "left-body cus4rowTable"
                                  }
                                >
                                  <div className=  { `${this.state.filterStatus.toLowerCase() !== "all" 
                                  && tableData?.filter(x=>x?.employeeType  ==="Contractor")?.length >0 ? "five-columns-view" : ""} list time-off-request-list`}>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      id={`tableid_${this.state.tableData.map(
                                        (items) => items.ptoRequestID
                                      )}`}
                                      keyField={`bootstrapTable_${this.state.tableData.map(
                                        (items) => items.ptoRequestID
                                      )}`}
                                      rowEvents={this.rowEvents}
                                      hover
                                      condensed

                                      rowClasses={rowClasses}
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </div>
                      </div>
                    </div>
                    <div className="right-panel">
                      <div className="">
                        <div className="pos-header d-flex align-content-center flex-wrap">
                          <div className="pos-ttl mr-auto cusRightFull">
                            {typeof rightPanelRowTop !== "undefined" &&
                              rightPanelRowTop.length > 0 ? (
                              <React.Fragment>
                                <EllipsisWithTooltip placement="bottom">
                                  <span>{rightPaneluserName}</span> 
                                </EllipsisWithTooltip>
                                {rightPanelEmployeeType ==="Contractor" &&
                                    <span className="badge">{rightPanelEmployeeType}</span>
                                  }
                                  {filterStatus === "All" ||
                                    filterStatus === "Declined" ||
                                    filterStatus === "Approved" ? (
                                    <React.Fragment>
                                      {rightPanelPTPStatus === "Approved" ? (
                                        <span className="cusBadge red-badges cusBadgeApproved">
                                          {rightPanelPTPStatus}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {rightPanelPTPStatus === "Pending" ? (
                                        <span className="cusBadge red-badges cusBadgePending">
                                          {rightPanelPTPStatus}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {rightPanelPTPStatus === "Declined" ? (
                                        <span className="cusBadge red-badges cusBadgeDeclined">
                                          {rightPanelPTPStatus}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </React.Fragment>
                                  ) : null}
                                <div className="customDropdownPanel">
                                  {rightPanelPTPStatus === "Approved" ||
                                    rightPanelPTPStatus === "Declined" ? (
                                    ""
                                  ) : (
                                    <>
                                      {rightPanelBalance.includes("-") ? (
                                        <OutsideClickHandler
                                          onOutsideClick={this.panelDeclineHide}
                                        >
                                          {this.labelPanelDecline()}
                                        </OutsideClickHandler>
                                      ) : (
                                        <>
                                          <OutsideClickHandler
                                            onOutsideClick={
                                              this.panelDeclineHide
                                            }
                                          >
                                            {this.labelPanelDecline()}
                                          </OutsideClickHandler>

                                          {typeof rightPanelRowBottom !==
                                            "undefined" &&
                                            rightPanelRowBottom.length > 0 && (
                                              <>
                                                {(rightPanelRowBottom[0]
                                                  .overlapData === "" ||
                                                  rightPanelRowBottom[0].overlapData.toLowerCase() ===
                                                  "schedule") &&
                                                  (rightPanelRowBottom[0]
                                                    .overlapType === "" ||
                                                    rightPanelRowBottom[0].overlapType.toLowerCase() ===
                                                    "schedule") ? (
                                                  <OutsideClickHandler
                                                    onOutsideClick={
                                                      this.panelApproveHide
                                                    }
                                                  >
                                                    {this.labelPanelApprove()}
                                                  </OutsideClickHandler>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>
                        <div className="pos-body">
                          {/* typeof rightPanelRowBottom !== "undefined" && rightPanelRowBottom.length > 0 && (<>
                            overlapData : {rightPanelRowBottom[0].overlapData} && overlapType : {rightPanelRowBottom[0].overlapType}
                          </>) */}

                          {rightPanelRowBottom.map((items, index) => (
                            <React.Fragment key={index}>
                              <div className="form-group mb-0 row">
                                <label className="form-label col-form-label col-sm-4 timeoffLabel">
                                  Submitted
                                </label>
                                <div className="col-sm-8">
                                  <div className="form-control-plaintext fortwoline">
                                    <span className="secondLine">
                                      {items.ptoSubmitted}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group mb-0 row">
                                <label className="form-label col-form-label col-sm-4 timeoffLabel">
                                  Type
                                </label>
                                <div className="col-sm-8">
                                  <div className="form-control-plaintext fortwoline">
                                    <span className="secondLine">
                                      {items.ptoType}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mb-0 row">
                                <label className="form-label col-form-label col-sm-4 timeoffLabel">
                                  Reason
                                </label>
                                <div className="col-sm-8">
                                  <div className="form-control-plaintext fortwoline">
                                    <span className="secondLine">
                                      {items.ptoReason}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {rightPanelScheduleType === "3" ||
                                rightPanelScheduleType === "4" ? (
                                <div>
                                  {rightPanelPTPStatus === "Approved" ||
                                    rightPanelPTPStatus === "Declined" ? null : (
                                    <React.Fragment>
                                      <div className="form-group mb-0 row">
                                        <label className="form-label col-form-label col-sm-4 timeoffLabel">
                                          Balance
                                        </label>
                                        <div className="col-sm-8">
                                          <div className="form-control-plaintext fortwoline">
                                            <strong className="secondLine">
                                              <div className="cusptoflexPanel">
                                                <div className="cusflexgrid">
                                                  <div className="col2 forTextBtn">
                                                    <span className="forbtn">
                                                      {parseInt(items.ptoBalance) < 10 && !items.ptoBalance.includes("-")  ? "0" + items.ptoBalance.split(" ")[0] : items.ptoBalance.split(" ")[0]}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </strong>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="form-group mb-0 row">
                                        <label className="form-label col-form-label col-sm-4 timeoffLabel">
                                          Post Approval Balance
                                        </label>
                                        <div className="col-sm-8">
                                          <div className="form-control-plaintext fortwoline">
                                            <strong className="secondLine">
                                              <div className="cusptoflexPanel">
                                                <div className="cusflexgrid">
                                                  <div className="col2 forTextBtn">
                                                    {items.postApprovalBalance
                                                      .split(" ")[0]
                                                      .split(":")[0]
                                                      .includes("-") ? (
                                                      <span className="cusDangerBtn">
                                                        {
                                                          items.postApprovalBalance.split(
                                                            " "
                                                          )[0]
                                                        }
                                                      </span>
                                                    ) : (
                                                      <span className="cusSuccessBtn">
                                                        {
                                                          items.postApprovalBalance.split(
                                                            " "
                                                          )[0]
                                                        }
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </strong>
                                          </div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              ) : null}

                              <hr />
                              <div className="formGroup row mb-2">
                                <label className="form-label col-form-label timeoffLabel labelheader">
                                  Request Entries
                                </label>
                              </div>
                              <div className="cusPTOGridTable">
                                <Table responsive className="tbl1">
                                  <thead>
                                    <tr className="bg-light">
                                      <th style={{ width: "15%" }}>Start Date</th>
                                      <th style={{ width: "15%" }} className={
                                        rightPanelPTPStatus === "Approved" ||
                                          rightPanelPTPStatus === "Declined"
                                          ? ""
                                          : "pl-4"
                                      }
                                      >
                                        Start Time
                                      </th>
                                      {/* <th style={{ width: "15%" }}>End Date</th> */}
                                      <th
                                        style={{ width: "15%" }}
                                        className={
                                          rightPanelPTPStatus === "Approved" ||
                                            rightPanelPTPStatus === "Declined"
                                            ? ""
                                            : "pl-4"
                                        }
                                      >
                                        End Time
                                      </th>
                                      <th style={{ width: "35%" }}>Duration</th>
                                      <th style={{ width: "20%" }}></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {items.timeOffRequestDateTime.map(
                                      (tOffRDT, index) => {
                                        return (
                                          <tr key={index}>
                                            <td style={{ width: "20%" }}>
                                              <div className="tableLockSymbol">
                                                <span>{tOffRDT.startDate}</span>
                                                {tOffRDT.isDayApprove.toLowerCase() ===
                                                  "yes" ? (
                                                  <img
                                                    src={ForecastLock}
                                                    alt="isDayApprove"
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </td>
                                            <td style={{ width: "15%" }}
                                              className={
                                                rightPanelPTPStatus ===
                                                  "Approved" ||
                                                  rightPanelPTPStatus ===
                                                  "Declined"
                                                  ? ""
                                                  : "ptoTdNoPadd"
                                              }
                                            >
                                              {rightPanelPTPStatus ===
                                                "Approved" ||
                                                rightPanelPTPStatus ===
                                                "Declined" ? (
                                                tOffRDT.inTime.split(":")[0] <
                                                  10 ? (
                                                  "0" + tOffRDT.inTime
                                                ) : (
                                                  tOffRDT.inTime
                                                )
                                              ) : (
                                                <div>
                                                  {isPermissionforEdit ===
                                                    true ? (
                                                    <Dropdown
                                                      alignRight
                                                      className="tableHasDropdown"
                                                    >
                                                      <Dropdown.Toggle
                                                        className="btnHasNoPadd"
                                                        id={
                                                          "inTime_" +
                                                          tOffRDT.requestID
                                                        }
                                                      >
                                                        <div
                                                          className="btnHandler"
                                                          onClick={() =>
                                                            this.resetPopupData(
                                                              tOffRDT
                                                            )
                                                          }
                                                        >
                                                          {tOffRDT.inTime.split(
                                                            ":"
                                                          )[0] < 10
                                                            ? "0" +
                                                            tOffRDT.inTime
                                                            : tOffRDT.inTime}
                                                        </div>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu>
                                                        <form
                                                          onSubmit={(e) =>
                                                            this.editPTORequestSubmit(
                                                              e,
                                                              tOffRDT
                                                            )
                                                          }
                                                        >
                                                          <div className="dropDownHasForm">
                                                            <div className="dropDownHeader">
                                                              <label>
                                                                Edit Time
                                                              </label>
                                                              <Dropdown.Item className="xClose">
                                                                {this.croseSVG()}
                                                              </Dropdown.Item>
                                                            </div>
                                                            <div className="dropDownBody">
                                                              <input
                                                                type="time"
                                                                name="startTime"
                                                                value={
                                                                  this.state
                                                                    .edtPTOstartTime
                                                                }
                                                                onChange={
                                                                  this
                                                                    .editPTOStarttime
                                                                }
                                                                className="form-control"
                                                                required
                                                              />
                                                            </div>
                                                            <div className="dropDownFooter">
                                                              <Button type="submit">
                                                                Save
                                                              </Button>
                                                            </div>
                                                          </div>
                                                        </form>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  ) : (
                                                    <div
                                                      style={{ paddingTop: 12 }}
                                                    >
                                                      {tOffRDT.inTime}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </td>

                                            {/* <td>
                                              <div className="tableLockSymbol">
                                                <span>{tOffRDT.endDate}</span>
                                              </div>
                                            </td> */}

                                            <td style={{ width: "15%" }}
                                              className={
                                                rightPanelPTPStatus ===
                                                  "Approved" ||
                                                  rightPanelPTPStatus ===
                                                  "Declined"
                                                  ? ""
                                                  : "ptoTdNoPadd"
                                              }
                                            >
                                              {rightPanelPTPStatus ===
                                                "Approved" ||
                                                rightPanelPTPStatus ===
                                                "Declined" ? (
                                                tOffRDT.outTime.split(":")[0] <
                                                  10 ? (
                                                  "0" + tOffRDT.outTime
                                                ) : (
                                                  tOffRDT.outTime
                                                )
                                              ) : (
                                                <div>
                                                  {isPermissionforEdit ===
                                                    true ? (
                                                    <Dropdown
                                                      alignRight
                                                      className="tableHasDropdown"
                                                    >
                                                      <Dropdown.Toggle
                                                        className="btnHasNoPadd"
                                                        id={
                                                          "endTime_" +
                                                          tOffRDT.requestID
                                                        }
                                                      >
                                                        <div
                                                          className="btnHandler"
                                                          onClick={() =>
                                                            this.resetPopupData(
                                                              tOffRDT
                                                            )
                                                          }
                                                        >
                                                          {tOffRDT.outTime.split(
                                                            ":"
                                                          )[0] < 10
                                                            ? "0" +
                                                            tOffRDT.outTime
                                                            : tOffRDT.outTime}
                                                        </div>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu>
                                                        <form
                                                          onSubmit={(e) =>
                                                            this.editPTORequestSubmit(
                                                              e,
                                                              tOffRDT
                                                            )
                                                          }
                                                        >
                                                          <div className="dropDownHasForm">
                                                            <div className="dropDownHeader">
                                                              <label>
                                                                Edit Time
                                                              </label>
                                                              <Dropdown.Item className="xClose">
                                                                {this.croseSVG()}
                                                              </Dropdown.Item>
                                                            </div>
                                                            <div className="dropDownBody">
                                                              <input
                                                                type="time"
                                                                name="endTime"
                                                                value={
                                                                  this.state
                                                                    .edtPTOendTime
                                                                }
                                                                onChange={
                                                                  this
                                                                    .editPTOEndtime
                                                                }
                                                                className="form-control"
                                                                required
                                                              />
                                                            </div>
                                                            <div className="dropDownFooter">
                                                              <Button type="submit">
                                                                Save
                                                              </Button>
                                                            </div>
                                                          </div>
                                                        </form>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  ) : (
                                                    <div
                                                      style={{ paddingTop: 12 }}
                                                    >
                                                      {tOffRDT.outTime}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </td>
                                            <td style={{ width: "30%" }}>{tOffRDT.dayDuration}</td>
                                            <td style={{ width: "20%" }} className="ptoTdNoPadd text-right">
                                              {rightPanelPTPStatus ===
                                                "Approved" ||
                                                rightPanelPTPStatus ===
                                                "Declined" ? null : (
                                                <React.Fragment>
                                                  {items.timeOffRequestDateTime
                                                    .length < 2 ? null : (
                                                    <Dropdown
                                                      alignRight
                                                      className="tableHasDropdown tableRightDropdown"
                                                    >
                                                      <Dropdown.Toggle
                                                        className="btn btn-primary"
                                                        id="dropdown-more"
                                                      >
                                                        {this.dotsSVG()}
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu>
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            this.RemovePTOEntry(
                                                              tOffRDT
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            // src="/static/media/delete.svg"
                                                            src={deleteIcon}
                                                            alt="Delete"
                                                          />
                                                          &nbsp;Delete
                                                        </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  )}
                                                </React.Fragment>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </Table>
                                {this.state.showNewEntry ? (
                                  <OutsideClickHandler
                                    disabled={this.state.outSaveHandler}
                                    onOutsideClick={this.submitNewPTOEntry}
                                  >
                                    <Form
                                      autoComplete="Off"
                                      onSubmit={this.submitNewPTOEntry}
                                    >
                                      <Table>
                                        <tbody>
                                          <tr>
                                            <td style={{ width: "20%" }} className="ptoTdHasPadd">
                                              <div className="date-picker ptoDatePicker">
                                                <DatePicker
                                                  name="reCalcStartDate"
                                                  value={reEditPto.reCalcStartDate}
                                                  className={`form-control schduleInput ${reEditPtoErr.reCalcStartDate ? "ptoFormHasError" : ""}`}
                                                  id="reCalcStartDate"
                                                  selected={reEditPto.reCalcStartDate}
                                                  onSelect={(e: any) => this.handleSelectDate(e, "reCalcStartDate")}
                                                  //onChange={(e: any) => this.handleSelectDate(e, "reCalcStartDate")}
                                                  placeholderText="mm/dd/yyyy"
                                                  required
                                                  autoComplete="off"
                                                />
                                              </div>
                                            </td>
                                            <td style={{ width: "15%" }} className="ptoTdHasPadd">
                                              <Form.Control
                                                type="time"
                                                name="inTime"
                                                value={reEditPto.inTime}
                                                className={`scheduleInput ${reEditPtoErr.inTime ? "ptoFormHasError" : ""}`}
                                                onChange={this.reEditTime}
                                                onBlur={this.handleFormValidation}
                                                required
                                                disabled={
                                                  this.state.reEditPto.reCalcStartDate === "" ||
                                                  reEditPtoErr.reCalcStartDate
                                                }
                                              />
                                            </td>
                                            {/* <td style={{ width: "15%" }} className="ptoTdHasPadd">
                                              <div className="date-picker ptoDatePicker">
                                                <DatePicker
                                                  value={reEditPto.reCalcEndDate}
                                                  name="reCalcEndDate"
                                                  className={`form-control schduleInput ${reEditPtoErr.reCalcEndDate ? "ptoFormHasError" : ""}`}
                                                  id="reCalcEndDate"
                                                  minDate={reEditPto.reCalcStartDate}
                                                  maxDate={new Date(new Date(this.state.reEditPto.reCalcStartDate).getTime() + 24 * 60 * 60 * 1000)}
                                                  selected={this.state.reEditPto.reCalcEndDate}
                                                  onSelect={(e: any) => this.handleSelectDate(e, "reCalcEndDate")}
                                                  //onChange={(e: any) => this.handleSelectDate(e, "reCalcEndDate")}
                                                  placeholderText="mm/dd/yyyy"
                                                  required
                                                  autoComplete="off"
                                                  disabled={
                                                    this.state.reEditPto.reCalcStartDate === "" ||
                                                    this.state.reEditPto.inTime === "" ||
                                                    reEditPtoErr.reCalcStartDate
                                                  }
                                                />
                                              </div>
                                            </td> */}
                                            <td style={{ width: "15%" }} className="ptoTdHasPadd">
                                              <Form.Control
                                                type="time"
                                                name="outTime"
                                                value={reEditPto.outTime}
                                                onChange={this.reEditTime}
                                                onBlur={this.handleFormValidation}
                                                className={`scheduleInput ${reEditPtoErr.outTime ? "ptoFormHasError" : ""}`}
                                                required
                                                disabled={
                                                  this.state.reEditPto.reCalcStartDate === "" ||
                                                  this.state.reEditPto.inTime === "" ||
                                                  // this.state.reEditPto.reCalcEndDate === "" ||
                                                  reEditPtoErr.reCalcStartDate
                                                }
                                              />
                                            </td>
                                            <td style={{ width: "30%" }}>
                                              <span className={(rowClickScheduleType!=="8" && rowClickScheduleType!=="10" && (inOutPtoDuration < 1 || (inOutPtoDuration > 480))) ? "text-danger" : ""}>
                                                {timeDuration}
                                              </span>
                                            </td>
                                            <td style={{ width: "20%" }} className="text-right ptoTdHasPadd">
                                              <div id="calcHide" className="tableHasDropdown tableRightDropdown dropdown">
                                                &nbsp;
                                                <Button
                                                  onClick={() => {
                                                    this.addNewEntry("Cancel");
                                                  }}
                                                  title="Cancel"
                                                >
                                                  <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                                      fill="#2caf92"
                                                    ></path>
                                                  </svg>
                                                </Button>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </Form>
                                  </OutsideClickHandler>
                                ) : null}
                                <Table>
                                  <tfoot>
                                    <tr>
                                      <th style={{ width: "20%" }}>
                                        Totals
                                      </th>
                                      <th style={{ width: "15%" }}></th>
                                      <th style={{ width: "15%" }}></th>
                                      <th style={{ width: "30%" }}>
                                        {items.duration.split(" ")[0]}
                                      </th>
                                      <td
                                        style={{ width: "20%" }}
                                        className="text-right ptoTdNoPadd"
                                      >
                                        {rightPanelPTPStatus === "Approved" ||
                                          rightPanelPTPStatus === "Declined" ? (
                                          ""
                                        ) : this.state
                                          .showNewEntry ? null : isPermissionforEdit ===
                                            true ? (
                                          <Button
                                            onClick={() => {
                                              this.addNewEntry("Add Entry");
                                            }}
                                            className="btnAddEntry"
                                          >
                                            Add Entry
                                          </Button>
                                        ) : null}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </Table>
                              </div>

                              {rightPanelPTPStatus === "Pending" && (
                                <>
                                  <br />
                                  {typeof rightPanelRowBottom !== "undefined" &&
                                    rightPanelRowBottom.length > 0 && (
                                      <>
                                        {rightPanelRowBottom[0].overlapData.toLowerCase() ===
                                          "actual" &&
                                          rightPanelRowBottom[0].overlapType.toLowerCase() ===
                                          "timeoff" ? (
                                          <p className="hasoverlapMessage">
                                            <strong>*</strong> This request
                                            cannot be approved as entries for
                                            one or more days overlap with an
                                            approved time off.
                                          </p>
                                        ) : (
                                          ""
                                        )}

                                        {rightPanelRowBottom[0].overlapData.toLowerCase() ===
                                          "actual" &&
                                          rightPanelRowBottom[0].overlapType.toLowerCase() ===
                                          "schedule" ? (
                                          <p className="hasoverlapMessage">
                                            <strong>*</strong> This request
                                            cannot be approved as entries for
                                            one or more days overlap with actual
                                            hours worked.
                                          </p>
                                        ) : (
                                          ""
                                        )}

                                        {rightPanelRowBottom[0].overlapData.toLowerCase() ===
                                          "schedule" &&
                                          rightPanelRowBottom[0].overlapType.toLowerCase() ===
                                          "timeoff" ? (
                                          <p className="hasoverlapMessage">
                                            <strong>*</strong> This request
                                            cannot be approved as entries for
                                            one or more days overlap with an
                                            approved time off.
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                </>
                              )}

                              {rightPanelPTPStatus === "Pending" ||
                                rightPanelPTPStatus === "All" ? (
                                ""
                              ) : (
                                <React.Fragment>
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="border-line"></div>
                                    </div>
                                  </div>
                                  <div className="formGroup row mb-2">
                                    <label className="form-label col-form-label timeoffLabel labelheader">
                                      Comments
                                    </label>
                                  </div>
                                  <div className="d-flex ptoManagerComment">
                                    <div className="profile-img">
                                      {items.imageURL === "" ? (
                                        <img style={{ border: "1px solid #ccc" }}
                                          src={profile}
                                          alt="profile img"
                                          className="default"
                                        />
                                      ) : (
                                        <img
                                          src={items.imageURL}
                                          alt="profile img"
                                        />
                                      )}
                                    </div>
                                    <div className="details hasComments">
                                      <span className="comments">
                                        {items.managerComment === ""
                                          ? "Add Comments"
                                          : items.managerComment}
                                      </span>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Container>
        </div>

        <Modal
          show={this.state.modalShiftOverlaps}
          onHide={this.handleModalShiftClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title>Shift overlap</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="shiftTimeModal">
              {this.state.modalShiftOverlapsData.includes("<table>") ? (
                <>
                  <p>
                    <strong>
                      This time off request overlaps with the following shifts:
                    </strong>
                  </p>
                  <div className="table-responsive">
                    <div className="shiftTimeModalTable">
                      <table>
                        <tr>
                          <th>Position</th>
                          <th>Date</th>
                          <th>In Time</th>
                          <th>Out Time</th>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="shiftTimeModalTable shiftTimebodyTable"
                      dangerouslySetInnerHTML={{
                        __html: this.state.modalShiftOverlapsData,
                      }}
                    ></div>
                  </div>
                  <p>
                    Continuing will move these shifts to unassigned. Do you want
                    to continue?
                  </p>
                </>
              ) : (
                <p>{this.state.modalShiftOverlapsData}</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="text-center">
            {this.state.modalShiftOverlapsData.includes("<table>") ? (
              <>
                <form onSubmit={(e) => this.approveSubmit(e, "Yes")}>
                  <Button className="btn-success" type="submit">
                    Yes
                  </Button>
                </form>
                <Button variant="danger" onClick={this.handleModalShiftClose}>
                  No
                </Button>
              </>
            ) : (
              <Button onClick={this.handleModalShiftClose}>Ok</Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
