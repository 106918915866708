import moment from "moment";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";

const convertToYearDateFormat = (data, label, value) => {

  data=_.sortBy(data, (item)=>new Date(item[label]) )
  const groupedByYear = _.groupBy(data, (date) =>
    moment(date[label], "MM/DD/YYYY").year()
  );

  const result = Object.entries(groupedByYear).map(([year, dates]) => ({
    year: parseInt(year),
    dates,
  }));



  return result;

  // const datesList=data.map(x=> x[label])

  // const updatedData={}
  // datesList.forEach(date => {

  //     const year=moment(date,"DD/MM/YYYY").year()
  //     if(updatedData[year])
  //     {
  //       updatedData[year]=[...updatedData[year],date]
  //     }
  //     else{
  //      updatedData[year]=[date]
  //     }

  // });
};
function DateWithYearDropdown(props) {
  // const [placeHolder, setPlaceholder] = React.useState(props?.placeHolder);
  const [selectedItem, setSelectedItem] = React.useState(
    props?.selectedItem || {}
  );
  const [data, setData] = React.useState<any[]>([]);

  useEffect(() => {
    const dateData = convertToYearDateFormat(
      props?.data,
      props.label,
      props.value
    );
    setData(dateData);
  }, [props?.data]);

  useEffect(() => {
    if(props.dependent)
    {
      setSelectedItem(props?.selectedItem ||{})
    }

  },[props.dependent])

  const handleDropdownChange = (item: any) => {
    if (props?.placeHolderItem) {
      if (item) {
        if (props?.placeHolderItem[props?.value] === item[props?.value]) {
          setSelectedItem(props?.placeHolderItem);
        } else {
          setSelectedItem(item);
        }
      } else {
        setSelectedItem(props?.placeHolderItem);
      }
    } else {
      setSelectedItem(item);
    }

    if (
      item &&
      typeof props?.onDropDownChange === "function" &&
      item[props?.value] !== props?.defaultValue
    ) {
      props?.onDropDownChange(item);
    }
  };

  const closeDropdown = () => {};

  const { label, value, showBlueBg, tabIndex, style } = props;

  return (
    <div>
      <Dropdown className="more-action bg-blue">
        <Dropdown.Toggle id="dropdown-ptoType" disabled={props.disabled}>
          <div className="d-flex align-items-center">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
              <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
            </svg>
            {selectedItem[label]}
            <div className="drop-arrow d-flex">
              <FiChevronDown />
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <p>Rooms Configurations</p>
          {/* <button
                                type="button"
                                className="btn wht-bg cross"
                                onClick={closeDropdown}
                            >
                                <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                    fill="#84888C"
                                />
                                </svg>
                              </button> */}

          <hr />
          {data.map((section) => (
            <>
              <div className="dropdown-header divider">{section.year}</div>
              <div>
                {section.dates.map((item) => (
                  <Dropdown.Item
                    key={item[label]}
                    eventKey={item[label]}
                    className={
                      selectedItem[label] === item[label] ? "active" : ""
                    }
                    onClick={(e: any) => handleDropdownChange(item)}
                  >
                    <div className="d-flex">
                      <div className="mr-auto">{item[label]}</div>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                          fill="#2CAF92"
                        />
                      </svg>
                    </div>
                  </Dropdown.Item>
                ))}
              </div>
            </>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default DateWithYearDropdown;
