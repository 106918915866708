import React from "react";
import { Container, Dropdown, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../../../../Common/Components/PageLoader";
import { Hotel } from "../../../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../../../Common/Contracts/IHotel";
import { LaborSmileID } from "../../../../Common/Services/LaborSmileId";
import { resolve, reject } from "q";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
// import calenderGrey from "../../../../Common/Assets/Images/icons/calendar_grey.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  ILaborSmileIdRequestDto,
  ILaborSmileIdActionRequestDto,
} from "../../../../Common/Contracts/ILaborDayApprove";
import { LaborPerformance } from "../../../../Common/Services/LaborPerfomance";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { HidSelector } from "../../../../Common/Components/HidSelector";
import { MyImage } from "../../../../Common/Components/MyImage";
import profile from "../../../../Common/Assets/Images/profile.png";
import { Utils } from "../../../../Common/Utilis";
const { SearchBar } = Search;
let newSearchResult: any = [];
export class SmileIDPunches extends React.Component<any, any> {
  private smileIdChildLeft: any;
  private childHID: any;
  constructor(props: any) {
    super(props);
    this.smileIdChildLeft = React.createRef();
    this.childHID = React.createRef();

    this.state = {
      hidValue: "Select",
      hotelName: "Select",
      hidList: [],
      tableData: [],
      allPendingData: [],
      allData: [],
      rightPanelRow: [],
      sortBy: "Employees",
      calenderShow: false,
      calenderDates: "All Dates",
      calenderSelectDate: "",
      startDate: "",
      endDate: "",
      selectedFilter: "Pending",
      currentPageIndex: 0,
      filterList: [
        { id: "Pass", value: "Pass", status: false },
        { id: "Fail", value: "Fail", status: false },
        { id: "Pending", value: "Pending", status: true },
        { id: "All", value: "All", status: false },
      ],

      curentPage: 1,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.actionButton = this.actionButton.bind(this);
  }

  handleFilterChange = (event: any, value: any) => {
    let data = this.state.filterList;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === value.id) {
        data[i].status = true;
      } else {
        data[i].status = false;
      }
    }
    this.setState({
      filterList: data,
      selectedFilter: value.value,
      tableData: [],
    });
    if (value.value === "Pending") {
      let allPendingData: any[] = [];
      allPendingData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.allPendingData))
      );
      allPendingData = allPendingData.filter(
        (item) =>
          new Date(item.date) >= new Date(this.state.startDate) &&
          new Date(item.date) <= new Date(this.state.endDate) &&
          item.status === "Pending"
      );
      this.setState({ tableData: [], allData: [] }, () =>
        this.setState({ tableData: allPendingData })
      );
      this.eventRowData(allPendingData[0]);
    } else {
      if (this.state.allData.length > 0) {
        let tableData: any[] = [];
        tableData = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.allData))
        );
        if (value.value !== "All") {
          tableData = tableData.filter((item) => item.status === value.value);
        }
        this.setState({ tableData: [] }, () =>
          this.setState({ tableData: tableData })
        );
        this.eventRowData(tableData[0]);
      } else {
        if (this.state.startDate !== "" && this.state.endDate !== "") {
          let request = {} as any;
          request.hotelID = this.state.hidValue;
          request.startDate = this.state.startDate;
          request.endDate = this.state.endDate;
          request.pageType = "SmileID";
          this.smileIdOverviewAccountsDetails(request, this.state.sortBy);
        } else {
          this.setState({ loaderTrue: true });
          let currentDate: any = new Date();
          if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
            currentDate = new Date(
              JSON.parse(localStorage.storage).currentUtcTime
            );
          }
          let currentdate = currentDate.getDate();
          let currentMonth = currentDate.getMonth() + 1;
          let currentYear = currentDate.getFullYear();
          let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
          this.setState({
            calenderSelectDate: tempDate,
            calenderShow: false,
          });
          this.getHidPayPeriod(tempDate, this.state.hidValue);
        }
      }
    }
  };

  componentDidMount() {
    this.loadHotelsDetails();
  }

  smileIdOverviewAccountsDetails(
    request: ILaborSmileIdRequestDto,
    sortType: string
  ) {
    request.sortType = sortType;
    this.setState({ loaderTrue: true });
    LaborSmileID.GetLaborSmileHidFailureDetails(request)
      .then(async (result: any | null) => {
        if (result.message === "Success") {
          let data = result.result;
          if (this.state.calenderDates.toLowerCase() === "all dates") {
            this.setState({ allPendingData: data });
          }
          if (
            this.state.selectedFilter.toLowerCase() === "all" ||
            this.state.selectedFilter.toLowerCase() === "pass" ||
            this.state.selectedFilter.toLowerCase() === "fail"
          ) {
            this.setState({ allData: data });
            if (this.state.selectedFilter.toLowerCase() !== "all") {
              data = data.filter(
                (item: { status: any }) =>
                  item.status === this.state.selectedFilter
              );
            }
          }
          this.setState({ tableData: [] }, () =>
            this.setState({ tableData: data })
          );
          this.eventRowData(data[0]);
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.setState({ loaderTrue: false });
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  loadHotelsDetails() {
    Hotel.getUserHotelAccess("Labor Management")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          this.setState({ hidList: result }, () => {});
        }
        resolve();
        this.setState({ loaderTrue: false });
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  selectHotelDropDown = (hotelDetails: any) => {
    this.firstaLoadData(hotelDetails.lettercode, hotelDetails.hotelID);
  };

  AllDates = () => {
    this.firstaLoadData(this.state.hotelName, this.state.hidValue);
  };

  firstaLoadData(lettercode: any, hotelID: any) {
    let currentDate: any = new Date();
    if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
      currentDate = new Date(
        JSON.parse(localStorage.storage).currentUtcTime
      );
    }
    let currentdate = currentDate.getDate();
    let currentMonth = currentDate.getMonth() + 1;
    let currentYear = currentDate.getFullYear();
    let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    this.setState({
      hotelName: lettercode,
      hidValue: hotelID,
      calenderShow: false,
      calenderDates: "All Dates",
      calenderSelectDate: tempDate,
      selectedFilter: "Pending",
      tableData: [],
      allPendingData: [],
      rightPanelRow: [],
      startDate: "",
      endDate: "",
    });
    let request = {} as any;
    request.hotelID = hotelID;
    request.pageType = "ALL";
    this.smileIdOverviewAccountsDetails(request, this.state.sortBy);
  }

  getHidPayPeriod(selectedDate: any, hotelID: any) {
    LaborPerformance.getPayPeriodDates(hotelID, selectedDate, "Weekly").then(
      async (result: any[] | null) => {
        if (result != null) {
          this.setState({
            calenderDates: result[0].startdate + " - " + result[0].enddate,
            startDate: result[0].startdate,
            endDate: result[0].enddate,
          });
          if (this.state.selectedFilter === "Pending") {
            let allPendingData: any[] = [];
            allPendingData = Object.assign(
              [],
              JSON.parse(JSON.stringify(this.state.allPendingData))
            );
            allPendingData = allPendingData.filter(
              (item) =>
                new Date(item.date) >= new Date(this.state.startDate) &&
                new Date(item.date) <= new Date(this.state.endDate) &&
                item.status === "Pending"
            );
            this.setState({ tableData: [] }, () =>
              this.setState({ tableData: allPendingData })
            );
            if (allPendingData.length > 0) {
              this.eventRowData(allPendingData[0]);
            }
          } else {
            let request = {} as any;
            request.hotelID = hotelID;
            request.startDate = result[0].startdate;
            request.endDate = result[0].enddate;
            request.pageType = "SmileID";
            this.smileIdOverviewAccountsDetails(request, this.state.sortBy);
          }
        }
      }
    );
  }

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.eventRowData(row);
    },
  };

  eventRowData(row: any) {
    if (row !== undefined) {
      if (!row.action) {
        let array: any[] = [];
        let newItem: any = {};
        newItem.action = row.action;
        newItem.actualPunchID = row.actualPunchID;
        newItem.confidencelevel = row.confidencelevel;
        newItem.date = row.date;
        newItem.employee = row.employee;
        newItem.imageUrl = row.imageUrl;
        newItem.inTime = row.inTime;
        newItem.isFalsePunch = row.isFalsePunch;
        newItem.outTime = row.outTime;
        newItem.position = row.position;
        newItem.punchType = row.punchType;
        newItem.rowSelected = row.rowSelected;
        newItem.status = row.status;
        newItem.uniqueno = row.uniqueno;
        newItem.hotelId = row.hotelId;
        newItem.entryType = row.entryType;
        newItem.isUndo = row.isUndo;
        array.push(newItem);
        this.setState({ activeClassRow: row.uniqueno, rightPanelRow: array });
      }
    }
  }

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  calenderChange = (e: any) => {
    let calenderYear = e.getFullYear();
    let calenderDate = e.getDate();
    let calenderMonth = e.getMonth() + 1;
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;
    this.setState({ calenderSelectDate: tempDate, calenderShow: false });
    this.getHidPayPeriod(tempDate, this.state.hidValue);
  };

  actionButton = (e: any, type: string, row: any) => {
    let data = this.state.tableData;
    this.setState({ loaderTrue: true });
    let request = {} as ILaborSmileIdActionRequestDto;
    request.hotelID = row.hotelId;
    request.Uniqueno = row.uniqueno;
    request.isFalsePunches = type;
    LaborSmileID.SimeIdAction(request)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          for (let i = 0; i < data.length; i++) {
            if (data[i].uniqueno === row.uniqueno) {
              data[i].action = false;
              data[i].isUndo = true;
              if (this.state.selectedFilter.toLowerCase() === "pending") {
                data[i].action = true;
                data[i].isUndo = false;
              }
              if (this.state.selectedFilter.toLowerCase() === "all") {
                data[i].status = type === "Yes" ? "Pass" : "Fail";
              }
              break;
            }
          }
          this.setState({ tableData: data });
          if (this.state.selectedFilter.toLowerCase() === "pending") {
            let allPendingData = this.state.allPendingData;
            for (let i = 0; i < allPendingData.length; i++) {
              if (allPendingData[i].uniqueno === row.uniqueno) {
                allPendingData[i].isFalsePunch = type;
                allPendingData[i].action = true;
                allPendingData[i].isUndo = false;
                allPendingData[i].status = type === "Yes" ? "Pass" : "Fail";
                break;
              }
            }
            this.setState({ allPendingData: allPendingData });
            this.nextSelectedRow(row);
          } else {
            let dataRight = this.state.rightPanelRow;
            for (let i = 0; i < dataRight.length; i++) {
              if (dataRight[i].uniqueno === row.uniqueno) {
                dataRight[i].action = false;
                dataRight[i].isUndo = true;
                dataRight[i].isFalsePunch = type;
                dataRight[i].status = type === "Yes" ? "Pass" : "Fail";
                break;
              }
            }
            let allData = this.state.allData;
            for (let i = 0; i < allData.length; i++) {
              if (allData[i].uniqueno === row.uniqueno) {
                allData[i].isFalsePunch = type;
                allData[i].action = false;
                allData[i].isUndo = true;
                allData[i].status = type === "Yes" ? "Pass" : "Fail";
                break;
              }
            }

            let allPendingData = this.state.allPendingData;
            for (let i = 0; i < allPendingData.length; i++) {
              if (allPendingData[i].uniqueno === row.uniqueno) {
                allPendingData[i].isFalsePunch = type;
                allPendingData[i].action = false;
                allPendingData[i].isUndo = false;
                allPendingData[i].status = type === "Yes" ? "Pass" : "Fail";
                break;
              }
            }
            this.setState(
              {
                tableData: [],
                rightPanelRow: dataRight,
                allData: allData,
                allPendingData: allPendingData,
              },
              () => this.setState({ tableData: data })
            );
          }
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
        this.setState({ loaderTrue: false });
        var index = this.getIndex(
          row.uniqueno,
          this.state.tableData,
          "uniqueno"
        );
        if (index / 10 === 0) {
          //---next
        }
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  getIndex(value: any, arr: string | any[], prop: string | number) {
    for (var i = 1; i <= arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  undoButtonClick = (e: any, row: any, mode: any) => {
    this.setState({ activeClassRow: "" });
    this.undoButtonClickEvent(row, mode);
  };

  searchedData = (newResult: any) => {
    if (newResult.length > 0) {
      let newRowIndex = 0;
      this.setState({ isFirstRowSelected: true });
      for (let i = 0; i < newResult.length; i++) {
        if (newResult[i].action === false) {
          newRowIndex = i;
          break;
        }
      }
      this.setState({ activeClassRow: newResult[newRowIndex].uniqueno });
      if (newResult.length > 0) {
        this.leftSelectedRow(newResult[newRowIndex]);
      }
    } else {
      let blankRow: any = {};
      blankRow.employee = "";
      blankRow.action = "";
      this.setState({ activeClassRow: "" });
      this.leftSelectedRow(blankRow);
    }
    newSearchResult = newResult;
  };

  undoButtonClickEvent = (row: any, mode: any) => {
    let data = this.state.tableData;
    this.setState({ loaderTrue: true });
    let request = {} as ILaborSmileIdActionRequestDto;
    request.hotelID = row.hotelId;
    request.Uniqueno = row.uniqueno;
    request.isFalsePunches = null;
    LaborSmileID.SimeIdAction(request)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          for (let i = 0; i < data.length; i++) {
            if (data[i].uniqueno === row.uniqueno) {
              data[i].action = false;
              data[i].isUndo = false;
              data[i].isFalsePunch = null;
              data[i].rowSelected = false;
              data[i].status = "Pending";
              break;
            }
          }
          this.setState({ tableData: data });
          if (this.state.selectedFilter.toLowerCase() === "pending") {
            let allPendingData = this.state.allPendingData;
            for (let i = 0; i < allPendingData.length; i++) {
              if (allPendingData[i].uniqueno === row.uniqueno) {
                allPendingData[i].isFalsePunch = null;
                allPendingData[i].status = "Pending";
                break;
              }
            }
            this.setState({ allPendingData: allPendingData });
            row.action = false;
            row.isUndo = false;
            row.isFalsePunch = null;
            row.status = "Pending";
            this.undoButtonClickRerender(row);
          } else {
            let dataRight = this.state.rightPanelRow;
            for (let i = 0; i < dataRight.length; i++) {
              if (dataRight[i].uniqueno === row.uniqueno) {
                dataRight[i].action = true;
                dataRight[i].isUndo = false;
                dataRight[i].isFalsePunch = null;
                dataRight[i].status = "Pending";
                break;
              }
            }
            let allData = this.state.allData;
            for (let i = 0; i < allData.length; i++) {
              if (allData[i].uniqueno === row.uniqueno) {
                allData[i].isFalsePunch = null;
                allData[i].isUndo = false;
                allData[i].status = "Pending";
                break;
              }
            }

            let allPendingData = this.state.allPendingData;
            for (let i = 0; i < allPendingData.length; i++) {
              if (allPendingData[i].uniqueno === row.uniqueno) {
                allPendingData[i].isFalsePunch = null;
                allPendingData[i].status = "Pending";
                break;
              }
            }
            if (mode === "right") {
              this.setState(
                {
                  tableData: [],
                  rightPanelRow: dataRight,
                  allData: allData,
                  allPendingData: allPendingData,
                },
                () => this.setState({ tableData: data })
              );
            } else {
              this.setState({
                tableData: data,
                rightPanelRow: dataRight,
                allData: allData,
                allPendingData: allPendingData,
              });
            }
            this.undoButtonClickRerender(dataRight[0]);
          }
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "smileIdOverview",
          });
        }
        resolve();
        this.setState({ loaderTrue: false });
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  undoButtonClickRerender = (row: any) => {
    let data = this.state.tableData;
    for (let i = 0; i < data.length; i++) {
      if (data[i].uniqueno === row.uniqueno) {
        this.setState(
          {
            activeClassRow: row.uniqueno,
          },
          () => {
            this.leftSelectedRow(this.state.tableData[i]);
          }
        );
        break;
      }
    }
  };

  nextSelectedRow = (row: any) => {
    let data: any;
    if (newSearchResult.length > 0) {
      data = newSearchResult;
    } else {
      data = this.state.tableData;
    }

    for (let i = 0; i < data.length; i++) {
      let rowIndex: number;
      if (data[i].uniqueno === row.uniqueno) {
        if (data.length - 1 > i) {
          rowIndex = i + 1;
          if (data[rowIndex].action === false) {
            this.setState(
              {
                activeClassRow: data[rowIndex].uniqueno,
              },
              () => {
                this.leftSelectedRow(data[rowIndex]);
              }
            );
          } else {
            for (let y = 0; y < data.length; y++) {
              if (data[y].action === false) {
                this.setState(
                  {
                    activeClassRow: data[y].uniqueno,
                  },
                  () => {
                    this.leftSelectedRow(data[y]);
                  }
                );
                break;
              } else {
                let blankRow: any = {};
                blankRow.employee = "";
                this.setState({ activeClassRow: "" }, () => {
                  this.leftSelectedRow(blankRow);
                });
              }
            }
          }
        } else {
          for (let x = 0; x < data.length; x++) {
            if (data[x].action === false) {
              this.setState(
                {
                  activeClassRow: data[x].uniqueno,
                },
                () => {
                  this.leftSelectedRow(data[x]);
                }
              );
              break;
            } else {
              let blankRow: any = {};
              blankRow.employee = "";
              this.setState({ activeClassRow: "" }, () => {
                this.leftSelectedRow(blankRow);
              });
            }
          }
        }
      }
    }
  };

  leftSelectedRow = (row: any) => {
    this.eventRowData(row);
  };

  handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
    this.setState({ curentPage: page });
    this.eventRowData(this.state.tableData[page * 10]);
    let tblSmile = document.getElementById("tblSmile") as any;
    tblSmile.scrollIntoView();
  };

  handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
    this.setState({ curentPage: page });
    this.eventRowData(this.state.tableData[(page - 2) * 10]);
    let tblSmile = document.getElementById("tblSmile") as any;
    tblSmile.scrollIntoView();
  };

  render() {
    const rowClasses = (row: any, rowIndex: number) => {
      if (row.uniqueno === Number(this.state.activeClassRow)) {
        return "active-row";
      } else if (row.action) {
        return "faded-row";
      } else {
        return;
      }
    };

    let loadShow: any;

    if (this.state.loaderTrue) {
      loadShow = <PageLoader />;
    }

    const columns = [
      {
        dataField: "employee",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdEmpDetails">
            <EllipsisWithTooltip placement="bottom">
              <span className="smileIdEmpName">{row.employee}</span>
            </EllipsisWithTooltip>
            <EllipsisWithTooltip placement="bottom">
              <span className="smileIdEmpDesignation">{row.position}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        hidden:
          this.state.selectedFilter.toLowerCase() === "pending"
            ? this.state.calenderDates.toLowerCase() === "all dates"
              ? false
              : true
            : true,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdMatchPerDetails">
            <span className="smileIdMatchPerNum">{row.date}</span>
            <span className="smileIdMatchPerLabel">Punch Date</span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        hidden:
          this.state.calenderDates.toLowerCase() !== "all dates" ? false : true,
      },

      {
        dataField: "",
        text: "",
        hidden:
          this.state.selectedFilter.toLowerCase() === "all" ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex flex-wrap">
              {row.status === "Pending" && (
                <>
                  <div className="red-badges orange-bg">{row.status}</div>
                </>
              )}
              {row.status === "Pass" && (
                <>
                  <div className="red-badges green-bg">{row.status}</div>
                </>
              )}
              {row.status === "Fail" && (
                <>
                  <div className="red-badges">{row.status}</div>
                </>
              )}
            </div>
          );
        },
      },
      {
        dataField: "action",
        text: "",
        hidden:
          this.state.selectedFilter.toLowerCase() === "pending" ? false : true,
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.action) {
            return (
              <div className="smile-page-undo-button">
                <Button
                  className="row-UndoButton btn-outline-primary float-right"
                  onClick={(e: any) => this.undoButtonClick(e, row, "left")}
                >
                  {" "}
                  Undo{" "}
                </Button>
              </div>
            );
          } else {
            return <div className="smile-page-undo-button"></div>;
          }
        },
      },
    ];

    const options = {
      custom: true,
      totalSize: this.state.tableData.length,
    };

    return (
      <>
        <div className="">
          {/* <ToastContainer autoClose={3000} /> */}
          {loadShow}
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex mrgn-btm">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
              />
              <div className="mr-auto d-flex align-items-center">Smile iD</div>
            </div>
            {this.state.hidValue !== "Select" && (
              <div>
                <div className="performance-position performance">
                  <div className="body-sec">
                    <div className="main-Page">
                      <div className=" search-header d-flex">
                        <div className="calender-container ml-0">
                          <div
                            className={
                              this.state.calenderShow
                                ? "calender-display open"
                                : "calender-display"
                            }
                            onClick={this.calenderShow}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                              <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                            </svg>

                            <span className="prev-Date">
                              {this.state.calenderDates}
                            </span>
                            <span className="chevron-down">
                              <FiChevronDown />
                            </span>
                          </div>
                          {this.state.calenderShow && (
                            <div className="calender">
                              <OutsideClickHandler
                                onOutsideClick={this.calenderHide}
                              >
                                <Calendar
                                 calendarType={"US"}
                                  onChange={this.calenderChange}
                                  value={
                                    new Date(this.state.calenderSelectDate)
                                  }
                                />
                                {this.state.calenderDates !== "All Dates" &&
                                  this.state.selectedFilter.toLowerCase() ===
                                    "pending" && (
                                    <div
                                      className="all-dates"
                                      onClick={() => this.AllDates()}
                                    >
                                      {" "}
                                      All Dates
                                    </div>
                                  )}
                              </OutsideClickHandler>
                            </div>
                          )}
                        </div>
                        <div className="position-filter select">
                          <Dropdown className="more-action bg-blue" alignRight>
                            <Dropdown.Toggle id="dropdown-ptoType">
                              <svg
                                width="14"
                                height="10"
                                viewBox="0 0 14 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1 2C0.447715 2 0 1.55228 0 0.999999C0 0.447715 0.447715 0 0.999999 0H12.3328C12.8851 0 13.3328 0.447715 13.3328 0.999999C13.3328 1.55228 12.8851 2 12.3328 2H1Z" />
                                <path d="M3 6C2.44771 6 2 5.55228 2 5C2 4.44771 2.44771 4 3 4H10.3328C10.8851 4 11.3328 4.44771 11.3328 5C11.3328 5.55228 10.8851 6 10.3328 6H3Z" />
                                <path d="M5.66601 10C5.11373 10 4.66602 9.55229 4.66602 9C4.66602 8.44772 5.11373 8 5.66602 8H7.66601C8.2183 8 8.66601 8.44772 8.66601 9C8.66601 9.55229 8.2183 10 7.66601 10H5.66601Z" />
                              </svg>
                              {this.state.selectedFilter}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div className="ttl">STATUS</div>
                              {this.state.filterList.map(
                                (item: any, idx: any) => (
                                  <Dropdown.Item
                                    className={item.status ? "active" : ""}
                                    key={item.id}
                                    eventKey={item.value}
                                    onClick={(e: any) =>
                                      this.handleFilterChange(e, item)
                                    }
                                  >
                                    <div className="d-flex">
                                      <div className="mr-auto">
                                        {" "}
                                        {item.value}
                                      </div>
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                          fill="#2CAF92"
                                        />
                                      </svg>
                                    </div>
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="budget-settings smileIdOverview">
                  <div className="panel-container d-flex justify-content-start">
                    <div className="left-panel">
                      <div className="smileid-left-panel">
                        <div className="">
                          <PaginationProvider
                            pagination={paginationFactory(options)}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <div>
                                <div className="search-header d-flex">
                                  <SearchBar
                                    {...this.state.tableData}
                                    placeholder={"Search..."}
                                  />
                                </div>
                                <div className="left-body">
                                  <div className="list">
                                    <BootstrapTable
                                      id="tblSmile"
                                      keyField="uniqueno"
                                      data={this.state.tableData}
                                      columns={columns}
                                      {...paginationTableProps}
                                      rowEvents={this.rowEvents}
                                      hover
                                      condensed
                                      rowClasses={rowClasses}
                                    />
                                    {this.state.tableData.length > 0 && (
                                      <div className="row react-bootstrap-table-pagination">
                                        <span className="react-bootstrap-table-pagination-total">
                                          Showing{" "}
                                          <span>
                                            {(paginationProps.page - 1) * 10 +
                                              1}
                                          </span>{" "}
                                          -{" "}
                                          <span>
                                            {this.state.tableData.length <
                                            (paginationProps.page - 1) * 10 + 10
                                              ? this.state.tableData.length
                                              : (paginationProps.page - 1) *
                                                  10 +
                                                10}
                                          </span>{" "}
                                          of{" "}
                                          <span>
                                            {this.state.tableData.length}
                                          </span>{" "}
                                          Items
                                        </span>
                                        <span className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                                          <div className="pagination react-bootstrap-table-page-btns-ul">
                                            {paginationProps.page > 1 && (
                                              <button
                                                onClick={this.handlePrevPage(
                                                  paginationProps
                                                )}
                                              >
                                                {paginationProps.prePageText}
                                              </button>
                                            )}
                                            {paginationProps.page <
                                              Number(
                                                this.state.tableData.length / 10
                                              ) && (
                                              <button
                                                onClick={this.handleNextPage(
                                                  paginationProps
                                                )}
                                              >
                                                {paginationProps.nextPageText}
                                              </button>
                                            )}
                                          </div>
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </PaginationProvider>
                        </div>
                      </div>
                    </div>
                    <div className="right-panel">
                      {this.state.rightPanelRow.length > 0 &&
                        this.state.tableData.length > 0 &&
                        this.state.rightPanelRow[0].employee.length > 0 && (
                          <div>
                            <div className="commit-header d-flex align-content-center flex-wrap justify-content-between">
                              {(this.state.rightPanelRow[0].status ===
                                "Pending" ||
                                this.state.rightPanelRow[0].status === null) &&
                                !this.state.rightPanelRow[0].isUndo && (
                                  <>
                                    <span className="smileIdQuestion">
                                      Is this
                                      <EllipsisWithTooltip placement="bottom">
                                        <span>
                                          {this.state.rightPanelRow[0].employee}
                                        </span>
                                      </EllipsisWithTooltip>
                                      ?
                                    </span>
                                    <span className="action-group d-flex flex-row">
                                      <button
                                        type="button"
                                        className="btn-danger btn btn-primary"
                                        onClick={(e: any) =>
                                          this.actionButton(
                                            e,
                                            "No",
                                            this.state.rightPanelRow[0]
                                          )
                                        }
                                      >
                                        No
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={(e: any) =>
                                          this.actionButton(
                                            e,
                                            "Yes",
                                            this.state.rightPanelRow[0]
                                          )
                                        }
                                      >
                                        Yes
                                      </button>
                                    </span>
                                  </>
                                )}
                              {this.state.rightPanelRow[0].status !==
                                "Pending" && (
                                <>
                                  <span className="smileIdQuestion">
                                    <span>
                                      <EllipsisWithTooltip placement="bottom">
                                        <span>
                                          {this.state.rightPanelRow[0].employee}
                                        </span>
                                      </EllipsisWithTooltip>
                                    </span>
                                    {this.state.rightPanelRow[0].status ===
                                      "Pass" && (
                                      <>
                                        <div className="red-badges green-bg">
                                          {this.state.rightPanelRow[0].status}
                                        </div>
                                      </>
                                    )}
                                    {this.state.rightPanelRow[0].status ===
                                      "Fail" && (
                                      <>
                                        <div className="red-badges">
                                          {this.state.rightPanelRow[0].status}
                                        </div>
                                      </>
                                    )}
                                  </span>
                                  {this.state.rightPanelRow[0].entryType !==
                                    "Auto" && (
                                    <span className="action-group d-flex flex-row">
                                      <Button
                                        className="row-UndoButton btn-outline-primary float-right"
                                        onClick={(e: any) =>
                                          this.undoButtonClick(
                                            e,
                                            this.state.rightPanelRow[0],
                                            "right"
                                          )
                                        }
                                      >
                                        {" "}
                                        Undo{" "}
                                      </Button>
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                            <div className="right-body">
                              <div className="smileIdEmpImg">
                                <MyImage
                                  src={this.state.rightPanelRow[0].imageUrl}
                                  fallbackSrc={profile}
                                  alt="User SmileId"
                                  className="img-fluid"
                                ></MyImage>
                                {/* <img className="img-fluid" src={this.state.rightPanelRow[0].imageUrl} alt="User SmileId" /> */}
                              </div>
                              <div className="smileIdEmpImgCaption">
                                Punched{" "}
                                <span>
                                  {this.state.rightPanelRow[0].punchType}
                                </span>{" "}
                                at{" "}
                                <span>
                                  {this.state.rightPanelRow[0].punchType ===
                                  "Out"
                                    ? this.state.rightPanelRow[0].outTime
                                    : this.state.rightPanelRow[0].inTime}
                                </span>{" "}
                                on
                                <span>
                                  {" "}
                                  {this.state.rightPanelRow[0].date}
                                </span>{" "}
                                with a{" "}
                                <span>
                                  {this.state.rightPanelRow[0].confidencelevel}%
                                  Match
                                </span>{" "}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {/* <div className="Toastify"></div> */}
              </div>
            )}
          </Container>
        </div>
      </>
    );
  }
}
