import * as React from "react";
import { Dropdown} from "react-bootstrap";
// import { read } from "fs";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Link } from "react-router-dom";

export const DropDownListCustom: React.FC<any> = (props: any) => {
  const inputElement: any = React.createRef();
  const toggleRef: any = React.createRef();

  const [placeHolder, setPlaceholder] = React.useState(props?.placeHolder);
  const [selectedItem, setSelectedItem] = React.useState(props?.selectedItem || {});
  const [data, setData] = React.useState(props?.data || []);
  const [filterData, setFilterData] = React.useState(props?.data || []);
  const [disabled, setdisabled] = React.useState(props?.disabled);
  const [inputValue, setInputValue] = React.useState("");

  //When dropdown data values changed depending on another dropdown
  React.useEffect(() => {
    if(props.dependent)
    {
      setData(props?.data || [])
      setFilterData(props?.data || [])
      setSelectedItem(props?.selectedItem ||{})
    }

  },[props.data, props.dependent])

  React.useEffect(() => {

    const { defaultValue, value, label, shouldToggle } = props;
    if(!selectedItem && props?.selectedItem) {
      handleDropdownChange(props?.selectedItem);
    }
    else if(selectedItem) {
      if (
        defaultValue &&
        selectedItem[value] !== defaultValue &&
        data.length > 0
      ) {
        const selectedItem = data?.find((r) => r[value] === defaultValue);
        handleDropdownChange(selectedItem);
      }
    } else {
      if(data?.length> 0 && defaultValue) {
        const selectedItem = data?.find((r) => r[value] === defaultValue);
        handleDropdownChange(selectedItem);
      }
    }


  });


  const handleDropdownSearch = (e: { target: { value: string } }) => {
    const { label } = props;
    const updatedList = data.filter(
      (item) =>
        item[label]
          ?.toString()
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) > -1
    );
    setFilterData(updatedList);
    setInputValue(e.target.value);
  };

  const   hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };
  const handleDropdownChange = (item: any) => {
    
    if(props?.placeHolderItem) {
      if(item) {
        if(props?.placeHolderItem[props?.value] ===item[props?.value]) {
          setSelectedItem(props?.placeHolderItem);
        } else {
          setSelectedItem(item);
        }
      } else {
        setSelectedItem(props?.placeHolderItem);
      }

    }else {
      setSelectedItem(item);
    }

    if (item&& typeof props?.onDropDownChange === "function" && item[props?.value] !== props?.defaultValue) {
      props?.onDropDownChange(item);
    }
    resetSerchBox();

    if(toggleRef?.current && toggleRef?.current?.ariaExpanded === "true")
    {
      toggleRef.current.click()
    }

  };

  const resetSerchBox = () => {
    setFilterData(data);
    setInputValue("");
  };

  const ddlSelectModal = (eventKey: any) => {
    setPlaceholder(eventKey);
  };

  const onTabKeyDown = (e) => {
    e.preventDefault();
    if (toggleRef.current.ariaExpanded === "false" && e?.keyCode === 9) {
      toggleRef.current.click();
      setTimeout(() => {
        $(`#ddlSpecailSearchText`)?.focus();
      }, 100);
    }
  };

  const onFocusInput = (eventKey: any) => {
    const {onFocus=()=>{}}= props
    onFocus()
    resetSerchBox();
    setTimeout(() => {
      inputElement?.current?.focus();
    }, 100);
  };

  const { label, value ,showBlueBg , tabIndex,style } = props;

  return (
    <Dropdown
      style={style}
      tabIndex = {tabIndex}
      className="single-select"
      onSelect={ddlSelectModal}
      onKeyUp={(e)=>onTabKeyDown(e)}
      onClick={onFocusInput} >

      <Dropdown.Toggle ref={toggleRef} id={`Modal-ddl${tabIndex}`} className={showBlueBg? "selected-blue-bg" :""} disabled={props.disabled}>
        <EllipsisWithTooltip placement="top">
          {selectedItem &&
            <span
              style={{
                width: "95%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                color: props.defaultTextColor && selectedItem[value] === 0 ? "gray" : "black"
              }}
            >
              {selectedItem[label]}
            </span>
          }
        </EllipsisWithTooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props?.isSearchRequired &&
          <div className="search-sec">
            <input
              id="ddlSpecailSearchText"
              type="text"
              className="form-control"
              onChange={handleDropdownSearch}
              placeholder={placeHolder}
              autoComplete ={"ddlSpecailSearchText" + placeHolder}
              value={inputValue}
              ref={inputElement}
            />
          </div>
        }
        {filterData?.length > 0 && (
          <div className="list-area">
            {filterData.map((item: any, idx: any) => (
              <>
                <div className="list-item">

                  <div role="button" onClick={() => handleDropdownChange(item)}
                    className={`dropdown-item ${item.disabled ? "d-flex align-items-center disabled" : "d-flex align-items-center"}`}
                    // disabled={item.disabled} onWheel={() => hoverOutEllipsis()}
                    >
                    <EllipsisWithTooltip placement="top">{item[label]}</EllipsisWithTooltip>
                    {item.isLowSecurity == 1 && <div className={"ml-auto badge color-blue"}>Low Security</div>}
                  </div>


                </div>
              </>
            ))}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
DropDownListCustom.defaultProps = {
  isSearchRequired: true,
  showBlueBg: false,
  shouldToggle: false
};