import * as React from 'react';
import { Container, Dropdown, Form, Button, OverlayTrigger, Tooltip, Modal, Spinner, Row, Col, Accordion, Card } from "react-bootstrap";
import { HidSelector } from "../../Common/Components/HidSelector";
import BootstrapTable from "react-bootstrap-table-next";
import TableSearch from "../../Common/Components/TableSearch";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { DropDownSelect } from "./component/DropDownSelect";
import { FiChevronDown } from "react-icons/fi";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { SalesService } from "../../Common/Services/SalesService";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { LaborPerformance as laborPerformance } from "../../Common/Services/LaborPerfomance";
import { User } from "../../Common/Services/User";
import { confirmAlert } from "react-confirm-alert";

import "./sales.scss";
import { _ } from 'ag-grid-community';
import { Utils } from '../../Common/Utilis';


export class SalesAccounts extends React.Component<any, any> {
    private childHID: any;
    private tableSearch: any;
    private scrollArea: any;

    columnSelectorFormatter = () => {
        return (
            <div className='d-flex column-selector align-items-center'>
                <Dropdown className="ml-auto split-icon-header" alignRight onToggle={this.handleToggle}>
                    <Dropdown.Toggle className="d-flex align-items-center" id="dropdown-split-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.3914 2H3.60861C2.72146 2 2 2.79733 2 3.77778V16.2222C2 17.2027 2.72146 18 3.60861 18H16.3914C17.2785 18 18 17.2027 18 16.2222V6.44356V5.55467V4.66578V3.77778V3.77689C17.9992 2.79644 17.2769 2 16.3914 2ZM6.82582 16.2222H3.60861V6.44356H6.82582V16.2222ZM11.6516 16.2222H8.43442V6.44356H11.6516V16.2222ZM13.2602 16.2222V6.44356H16.3914L16.3922 16.2222H13.2602Z" />
                        </svg>
                        <div className="drop-arrow d-flex">
                            <FiChevronDown />
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Header>Columns</Dropdown.Header>
                        <div className="scroll-area">

                            {this.state.tableColumnList.map((items, index) => (
                                <div className="dropdown-item" key={"custom-s" + index}>
                                    <EllipsisWithTooltip placement="bottom">
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            id={`list_${items.id}`}
                                            name={items.name.toLowerCase()}
                                            label={items.name}
                                            value={items.id}
                                            onChange={(event) =>
                                                this.onHandleColumn(event, items, index)
                                            }
                                            checked={items.isChecked}
                                            disabled={items.disabled}
                                        />
                                    </EllipsisWithTooltip>
                                </div>
                            ))}
                        </div>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="save-default" onClick={this.saveDefaultColumn}>Save as Default Columns</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
    handleToggle = (event) => {
        if (event === false && this.state.isColumnChange) {
            let defaultWidth = this.scrollArea.current.getBoundingClientRect().width;
            let currentWidth = this.scrollArea.current.childNodes[0].childNodes[0].childNodes[0].getBoundingClientRect().width;
            if (defaultWidth < currentWidth) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        //this.setState({ isColumnChange: false });
                        return (
                            <div className='viom_CustomConfirmAlert'>
                                <h1 className="viom_alertTitle">Note:</h1>
                                <p className="viom_alertMessage">Your column selection may cause horizontal scrolling due to the number selected.  To eliminate scrolling, limit the number of columns you select.</p>
                                <div className="viom_alertButtonGroup">
                                    <button className="viom_alertButton viom_success" onClick={onClose}>Ok</button>
                                </div>
                            </div>
                        );
                    }
                })
            }
        }
    }

    columns = [
        {
            dataField: 'accountName',
            text: 'Account',
            headerClasses: 'account',
            classes: 'account',
            searchable: true,
            hidden: false,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{row.accountName}</EllipsisWithTooltip>
                );
            },
        }, {
            dataField: 'owner',
            text: 'Owner',
            headerClasses: 'owner',
            classes: 'owner',
            searchable: true,
            hidden: false,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        {row.owner !== "zzzzz" && (
                            <EllipsisWithTooltip placement="bottom">{row.owner}</EllipsisWithTooltip>
                        )}
                    </>
                );
            },
        }, {
            dataField: 'scoreID',
            text: 'Score',
            headerClasses: 'score',
            classes: 'score',
            searchable: true,
            hidden: false,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div className='d-flex'>
                        {row.scoreID == 1 && (
                            <div className="circle blue">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" />
                                </svg>
                            </div>
                        )}
                        {row.scoreID == 2 && (
                            <div className="circle orange">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" />
                                </svg>
                            </div>
                        )}
                        {row.scoreID == 3 && (
                            <div className="circle green">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" />
                                </svg>
                            </div>
                        )}

                        {row.scoreID !== 99 && <div>{row.scoreID}</div>}
                    </div>
                );
            },
        }, {
            dataField: 'status',
            text: 'Status',
            headerClasses: 'status',
            classes: 'status',
            searchable: true,
            hidden: false,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        {row.status == "Lead" && (
                            <div className="status grey">
                                {row.status}
                            </div>
                        )}
                        {row.status == "Active" && (
                            <div className="status green">
                                {row.status}
                            </div>
                        )}
                        {row.status == "Inactive" && (
                            <div className="status orange">
                                {row.status}
                            </div>
                        )}
                        {row.status == "At Risk" && (
                            <div className="status red">
                                {row.status}
                            </div>
                        )}
                    </>


                );
            },
        }, {
            dataField: 'createdDate',
            text: 'Date Created',
            headerClasses: 'date',
            classes: 'date',
            searchable: true,
            hidden: false,
        }, {
            dataField: 'lettercode',
            text: 'EHID',
            headerClasses: 'ehid',
            classes: 'ehid',
            hidden: false,
        }, {
            dataField: 'accountTarget',
            text: 'Target',
            searchable: false,
            headerClasses: 'targetAccount',
            classes: 'targetAccount',
            hidden: true,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        {row.accountTarget == "Yes" && (
                            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.8292 9.5002L0.554199 5.2252L1.62295 4.15645L4.8292 7.3627L11.7104 0.481445L12.7792 1.5502L4.8292 9.5002Z" fill="#6A6E73" />
                            </svg>
                        )}
                    </>
                );
            },
        }, {
            dataField: 'sourceName',
            text: 'Source',
            headerClasses: 'source',
            classes: 'source',
            hidden: true,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        {row.sourceName !== "zzzzz" && (
                            <EllipsisWithTooltip placement="bottom">{row.sourceName}</EllipsisWithTooltip>
                        )}
                    </>
                );
            },
        }, {
            dataField: 'webSite',
            text: 'Website',
            headerClasses: 'website',
            classes: 'website',
            searchable: false,
            hidden: true,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        {row.webSite !== "zzzzz" && (
                            <EllipsisWithTooltip placement="bottom">{row.webSite}</EllipsisWithTooltip>
                        )}
                    </>
                );
            },
        }, {
            dataField: 'marketSegment',
            text: 'Segment',
            headerClasses: 'segment',
            classes: 'segment',
            searchable: false,
            hidden: true,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        {row.marketSegment !== "zzzzz" && (
                            <EllipsisWithTooltip placement="bottom">{row.marketSegment}</EllipsisWithTooltip>
                        )}
                    </>
                );
            },
        }, {
            dataField: 'viewPermission',
            text: 'View Only',
            headerClasses: 'width-50 permission',
            classes: 'width-50 permission',
            searchable: false,
            hidden: true,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                    {row.viewPermission.toLowerCase() === "view" &&
                    <div className="d-flex">
                        <div className="view">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g clip-path="url(#clip0_2012_6768)">
                                    <path d="M9 12.9091C10.1364 12.9091 11.1023 12.5114 11.8977 11.7159C12.6932 10.9205 13.0909 9.95455 13.0909 8.81818C13.0909 7.68182 12.6932 6.71591 11.8977 5.92045C11.1023 5.125 10.1364 4.72727 9 4.72727C7.86364 4.72727 6.89773 5.125 6.10227 5.92045C5.30682 6.71591 4.90909 7.68182 4.90909 8.81818C4.90909 9.95455 5.30682 10.9205 6.10227 11.7159C6.89773 12.5114 7.86364 12.9091 9 12.9091ZM9 11.2727C8.31818 11.2727 7.73864 11.0341 7.26136 10.5568C6.78409 10.0795 6.54545 9.5 6.54545 8.81818C6.54545 8.13636 6.78409 7.55682 7.26136 7.07955C7.73864 6.60227 8.31818 6.36364 9 6.36364C9.68182 6.36364 10.2614 6.60227 10.7386 7.07955C11.2159 7.55682 11.4545 8.13636 11.4545 8.81818C11.4545 9.5 11.2159 10.0795 10.7386 10.5568C10.2614 11.0341 9.68182 11.2727 9 11.2727ZM9 15.6364C6.78788 15.6364 4.77273 15.0189 2.95455 13.7841C1.13636 12.5492 -0.181818 10.8939 -1 8.81818C-0.181818 6.74242 1.13636 5.08712 2.95455 3.85227C4.77273 2.61742 6.78788 2 9 2C11.2121 2 13.2273 2.61742 15.0455 3.85227C16.8636 5.08712 18.1818 6.74242 19 8.81818C18.1818 10.8939 16.8636 12.5492 15.0455 13.7841C13.2273 15.0189 11.2121 15.6364 9 15.6364ZM9 13.8182C10.7121 13.8182 12.2841 13.3674 13.7159 12.4659C15.1477 11.5644 16.2424 10.3485 17 8.81818C16.2424 7.28788 15.1477 6.07197 13.7159 5.17045C12.2841 4.26894 10.7121 3.81818 9 3.81818C7.28788 3.81818 5.71591 4.26894 4.28409 5.17045C2.85227 6.07197 1.75758 7.28788 1 8.81818C1.75758 10.3485 2.85227 11.5644 4.28409 12.4659C5.71591 13.3674 7.28788 13.8182 9 13.8182Z" fill="#065A8C" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2012_6768">
                                        <rect width="18" height="18" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                 }
                 {row.viewPermission.toLowerCase() !== "view" &&
                 <div className="d-flex">

                 </div>
                }
                 </>
               
                 
                 );
            },
        },{
            dataField: '',
            text: '',
            headerClasses: 'sticky-last-column width-6',
            classes: 'sticky-last-column width-6',
          }
    ]
    constructor(props: any) {
        super(props);
        this.childHID = React.createRef();
        this.tableSearch = React.createRef();
        this.scrollArea = React.createRef();
        this.state = {
            addressToggle: false,
            contactToggle: false,
            accountsPermissionArray: [],
            isCreateAccount: "No",
            isViewOtherEHIDToggle: "No",
            loader: false,
            mainHidName: "",
            mainHidValue: -1,
            ispopupshow: false,
            moreAction: [
                { name: "Change Log", eventKey: "changeLog" },
                { name: "Export", eventKey: "export" },
                { name: "Import", eventKey: "import" },
            ],
            columns: this.columns,
            tableColumnList: [
                { id: "accName", name: "Account", isChecked: true, disabled: true },
                { id: "owner", name: "Owner", isChecked: true, disabled: false },
                { id: "score", name: "Score", isChecked: true, disabled: false },
                { id: "status", name: "Status", isChecked: true, disabled: false },
                { id: "createdDate", name: "Date Created", isChecked: true, disabled: false },
                { id: "ehid", name: "EHID", isChecked: true, disabled: false },
                { id: "targetAccount", name: "Target", isChecked: false, disabled: false },
                { id: "source", name: "Source", isChecked: false, disabled: false },
                { id: "website", name: "Website", isChecked: false, disabled: false },
                { id: "segment", name: "Segment", isChecked: false, disabled: false },
                { id: "viewOnly", name: "View Only", isChecked: false, disabled: true }
            ],
            isColumnChange: true,
            accountsList: [],
            accountsListCopy: [],
            accountDetails: {
                accountId: -1, accountName: "", lettercode: "", ehidValue: -1, status: 1, statusName: "Lead", accountScore: -1, accountScoreName: "",
                owner: -1, ownerName: "", source: -1, sourceName: "", marketSegment: -1, marketSegmentName: "", website: "", taxId: "", iata: "",
                targetAccount: "no", isEdit: "no",
            },
            errors: {
                accountName: "", lettercode: '', statusName: "", address: "", zipCode: "", contact: "", email: "", phone: "",
            },
            accountAddressDetails: {
                addressId: -1, accountId: -1, country: "", countryCode: "", street: "", city: "",
                state: "", stateCode: "", zipcode: "", isEdit: "no",
            },
            accountContactDetails: {
                contactId: -1, accountId: -1, name: "", email: "", phone: "", title: "", isEdit: "no",
            },
            salesEhidList: [],
            statusList: [],
            accountScoreList: [
                { name: "Select Score", eventKey: "score_-1", id: -1 },
                { name: "1 (Low)", eventKey: "score_1", id: 1 },
                { name: "2 (Medium)", eventKey: "score_2", id: 2 },
                { name: "3 (High)", eventKey: "score_3", id: 3 },
            ],
            sortList: [
                { name: "Account", eventKey: "accountName", id: 0 },
                { name: "Owner", eventKey: "owner", id: 1 },
                { name: "Score", eventKey: "score", id: 2 },
                { name: "Status", eventKey: "status", id: 3 },
                { name: "Date Created", eventKey: "createdDate", id: 4 },
                { name: "EHID", eventKey: "lettercode", id: 5 },
                // { name: "Target", eventKey: "accountTarget", id: 6 },
                // { name: "Source", eventKey: "sourceName", id: 7 },
                // { name: "Website", eventKey: "webSite", id: 8 },
                // { name: "Segment", eventKey: "marketSegment", id: 9 },
            ],
            sortListCopy: [
                { name: "Account", eventKey: "accountName", id: 0 },
                { name: "Owner", eventKey: "owner", id: 1 },
                { name: "Score", eventKey: "score", id: 2 },
                { name: "Status", eventKey: "status", id: 3 },
                { name: "Date Created", eventKey: "createdDate", id: 4 },
                { name: "EHID", eventKey: "lettercode", id: 5 },
                { name: "Target", eventKey: "accountTarget", id: 6 },
                { name: "Source", eventKey: "sourceName", id: 7 },
                { name: "Website", eventKey: "webSite", id: 8 },
                { name: "Segment", eventKey: "marketSegment", id: 9 },
            ],
            densityList: [
                { name: "WIDE", eventKey: "wide", id: 1 },
                { name: "NARROW", eventKey: "narrow", id: 2 },
            ],
            selectedDensity: "narrow",
            selectedSort: "createdDate",
            ownerList: [],
            sourceList: [],
            margetSegmentList: [],
            countriesAndStatesList: [],
            countriesList: [],
            defaultStatesList: [],
            statesList: [],
            filterOwnerList: [],
            filterOwnerListCopy: [],
            filterEhidList: [],
            filterEhidListCopy: [],
            filterSelectedOwner: "Owner",
            filterSelectedStatusList: [],
            filterSelectedEhidList: [],
            filterSearchInput: "",
            savedDefaultStatusFilter: "",
            loadCounter: 1,  // By default show 25 records only
            searchvalue:'',
            isLoadMore: false,
            showOtherEhidData: "no",
            isSaveDisabled: true,
            isAddressMandatory: false,
			isContactMandatory: false,
        };
    }

    componentDidMount() {
        this.GetSalesPermission();
        this.getDefaultColumn();
        this.loadDropDownDataForCreateAccnt();
    }

    getDefaultColumn = () => {
        let tableColumnList = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        let { columns } = this.state;
        let sortList = Object.assign([], JSON.parse(JSON.stringify(this.state.sortList)));
        const data = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        laborPerformance.getDefaultViewProperties("SalesAccounts")
            .then(async (response: any | null) => {
                if (response?.length > 0) {
                    let defaultSetting = response[0].fieldValue.split("_");
                    let selectedOwner = defaultSetting[0].toString();
                    let selectedStatus = defaultSetting[1].toString();
                    let selectedSort = defaultSetting[2].toString();
                    let selectedDensity = defaultSetting[3].toString();
                    let defaultColumn = defaultSetting[4].toString();
                    sortList = [];
                    let resData = defaultColumn.split(",");
                    tableColumnList.map(item => { item.isChecked = false });
                    resData.forEach(ritm => {
                        tableColumnList.map(item => {
                            if (item.id === ritm && item.id !== "viewOnly") {
                                item.isChecked = true;
                            }
                        })
                        if(ritm === "accName") {
                            sortList.push({ name: "Account", eventKey: "accountName", id: 0 });
                        }
                        else if(ritm === "owner") {
                            sortList.push({ name: "Owner", eventKey: "owner", id: 1 });
                        }
                        else if(ritm === "score") {
                            sortList.push({ name: "Score", eventKey: "score", id: 2 });
                        }
                        else if(ritm === "status") {
                            sortList.push({ name: "Status", eventKey: "status", id: 3 });
                        }
                        else if(ritm === "createdDate") {
                            sortList.push({ name: "Date Created", eventKey: "createdDate", id: 4 });
                        }
                        else if(ritm === "ehid") {
                            sortList.push({ name: "EHID", eventKey: "lettercode", id: 5 });
                        }
                        else if(ritm === "targetAccount") {
                            sortList.push({ name: "Target", eventKey: "accountTarget", id: 6 });
                        }
                        else if(ritm === "source") {
                            sortList.push({ name: "Source", eventKey: "sourceName", id: 7 });
                        }
                        else if(ritm === "website") {
                            sortList.push({ name: "Website", eventKey: "webSite", id: 8 });
                        }
                        else if(ritm === "segment") {
                            sortList.push({ name: "Segment", eventKey: "marketSegment", id: 9 });
                        }
                    });
                    this.setState({
                        filterSelectedOwner: selectedOwner, selectedSort, selectedDensity, tableColumnList,
                        savedDefaultStatusFilter: selectedStatus, sortList,
                    }, () => {
                        this.loadDropDownDataForFilters();
                        tableColumnList.map((item, idx) => {
                            columns[idx].hidden = tableColumnList[idx].isChecked ? false : true;
                            columns[idx].searchable = tableColumnList[idx].isChecked ? true : false;
                        });

                        this.setState({ columns, isColumnChange: false }, () => {
                            this.setState({ isColumnChange: true })
                        })

                    });
                } else {
                    this.loadDropDownDataForFilters();
                    this.setState({ tableColumnList: data });
                }
            })
            .catch((error) => {
                this.loadDropDownDataForFilters();
                this.setState({ tableColumnList: data });
                Utils.toastError(error.message, 
                    { 
                        position: toast.POSITION.BOTTOM_RIGHT, 
                        containerId: "sales-acc-1-1", 
                    });
            });
    }

    saveDefaultColumn = (): void => {
        let tableColumnList = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        let selectedColumns: any = [];
        tableColumnList.map(item => {
            if (item.isChecked) {
                selectedColumns.push(item.id)
            }
        });
        let selectedStatusFilter: any = [];
        this.state.filterSelectedStatusList.map(item => {
            selectedStatusFilter.push(item.id);
        });

        const laborSaveAsDefaultdtos: any[] = [];
        laborSaveAsDefaultdtos.push({
            fieldName: "column",
            fieldValue: this.state.filterSelectedOwner + "_" + (selectedStatusFilter).toString() + "_" + this.state.selectedSort + "_" + this.state.selectedDensity + "_" + (selectedColumns).toString(),
            // fieldValue: (selectedColumns).toString(),
            pageName: "SalesAccounts",
        });

        laborPerformance.saveDefaultViewProperties(laborSaveAsDefaultdtos)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.message === "Success") {
                        toast.success(result?.result?.message, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "sales-savedefault-success", });
                    }
                }
            })
            .catch((error) => {
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "sales-savedefault-error", });
            });
    };

    onHandleColumn = (e: any, selitem: any, index: any) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        let sortList = Object.assign([], JSON.parse(JSON.stringify(this.state.sortList)));
        let selectedSort = this.state.selectedSort;
        let isSortingChanged = false;
        data[index].isChecked = e.target.checked;
        let { columns } = this.state;
        columns[index].hidden = data[index].isChecked ? false : true;
        columns[index].searchable = data[index].isChecked ? true : false;
        if(e.target.checked) {
            sortList.push(this.state.sortListCopy.filter((x) => x.id === index)[0]);
        }
        else {
            if(selectedSort === this.state.sortListCopy.filter((x) => x.id === index)[0]?.eventKey) {
                selectedSort = "createdDate";
                isSortingChanged = true;
            }
            const obj = sortList.findIndex((x) => x.id === index);
            sortList.splice(obj, 1);
        }
        this.setState({ tableColumnList: data, columns, isColumnChange: false, sortList, selectedSort }, () => {
            if(isSortingChanged) {
                this.filterAccountsListData();
            }
            this.setState({ isColumnChange: true });
        })
    }

    loadDropDownDataForFilters = () => {
        // SetTimeout added because multiple API's are being called and always some API fails

        setTimeout(() => {
            this.getSalesAgent(-1, "filter");  // API for Owner Dropdown
        }, 50);
        setTimeout(() => {
            this.getSalesStatus();     // API for both  EHID Filter Data && EHID Dropdown Data for Create Account
        }, 100);
        setTimeout(() => {
            this.getSalesEHID("All");    // API fot EHID Filter Data
        }, 150);

        // this.getSalesAgent(-1, "filter");  // API for Owner Dropdown
        // this.getSalesStatus();     // API for both  EHID Filter Data && EHID Dropdown Data for Create Account
        // this.getSalesEHID("All");    // API fot EHID Filter Data
    }

    loadDropDownDataForCreateAccnt = () => {
        // SetTimeout added because multiple API's are being called and always some API fails
        setTimeout(() => {
            this.getSalesEHID("UserWise");    // API for EHID Dropdown Data for Create Account
        }, 200);
        setTimeout(() => {
            this.getSalesSource();   //  API for Source Dropdown Data for Create Account
        }, 250);
        setTimeout(() => {
            this.getSalesMarketSegment();  //  API for Market segment Dropdown Data for Create Account
        }, 300);

        // this.getSalesEHID("UserWise");    // API for EHID Dropdown Data for Create Account
        // this.getSalesSource();   //  API for Source Dropdown Data for Create Account
        // this.getSalesMarketSegment();  //  API for Market segment Dropdown Data for Create Account

        fetch('https://countriesnow.space/api/v0.1/countries/states')   //  API for State and Country for Create Account
            .then(response => response.json())
            .then(listData => {
                if (!listData.error) {
                    this.setState({ countriesAndStatesList: listData.data }, () => {
                        let countriesList: any = [];
                        countriesList = this.state.countriesAndStatesList.map((data, index) => ({
                            id: index,
                            name: data.name,
                            eventKey: "country_" + index,
                            code: data.iso3,
                        }));
                        countriesList = countriesList.filter((data, index) => data?.code === "USA" || data?.code === "CAN");
                        this.setState({ countriesList }, () => {
                            let statesList = Object.assign({}, JSON.parse(JSON.stringify(this.state.statesList)));
                            let accountAddressDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountAddressDetails)));
                            accountAddressDetails.country = "United States";
                            accountAddressDetails.countryCode = "USA";
                            statesList = this.state.countriesAndStatesList.filter((list) => list.iso3 === "USA")[0].states;
                            statesList = statesList.map((data, index) => ({
                                id: index,
                                name: data.name,
                                eventKey: "state_" + index,
                                code: data.state_code,
                            }));
                            this.setState({ statesList, accountAddressDetails, defaultStatesList: statesList });
                        });
                    })
                }
                else {
                    Utils.toastError("Something went wrong. Failed to fetch Country and State names.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "sales-acc-1-2a", 
                    });
                }
            })
            .catch(error => {
                Utils.toastError("Something went wrong. Failed to fetch Country and State names.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "sales-acc-1-3a", 
                });
            });
    }

    GetSalesPermission = () => {       
        SalesService.GetSalesPermission()
            .then(async (result: any | null) => {               
                if (result != null && result.result.length > 0) {
                    // debugger;
                    let perm: any = result.result;
                     this.setState({ accountsPermissionArray: perm, isCreateAccount: perm[0].createSalesAccount, isViewOtherEHIDToggle: perm[0].otherHIDPermission });
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "sales-acc-1-4a", 
                });
                reject();
            });
    }

    getSalesEHID = (type) => {
        let request: any = {};
        request.Type = type;
        SalesService.getSalesEHID(request)
            .then(async (result: any | null) => {
                // debugger;
                if (result != null) {
                    if (type === "All") {
                        this.setState({ filterEhidList: result.result, filterEhidListCopy: result.result, });
                    }
                    else if (type === "UserWise") {
                        this.setState({ salesEhidList: result.result });
                    }
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong. Failed to fetch Sales EHID.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "sales-acc-1-5a", 
                });
                reject();
            });
    }

    getSalesStatus = () => {
        SalesService.getSalesStatus()
            .then(async (result: any | null) => {
                // debugger;
                if (result != null) {
                    let data = result.result;
                    let filterSelectedStatusList: any = [];
                    let savedData = this.state.savedDefaultStatusFilter.split(",");
                    for (let i = 0; i < savedData.length; i++) {
                        for (let j = 0; j < data.length; j++) {
                            if (savedData[i].toString() === data[j].id.toString()) {
                                filterSelectedStatusList.push(data[j]);
                            }
                        }
                    }
                    this.setState({ statusList: result.result, filterSelectedStatusList }, () => {
                        this.filterAccountsListData();
                    });
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong. Failed to fetch Status.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "sales-acc-1-6a", 
                });
                reject();
            });
    }

    getSalesAgent = (hid, type) => {
        //API to get Data for Owner dropdown
        let request: any = {};
        request.HID = hid;
        SalesService.getSalesAgent(request)
            .then(async (result: any | null) => {
                // debugger;
                if (result != null) {
                    if (type === "form") {
                        this.setState({ ownerList: result.result }, () => {
                            let accountDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountDetails)));
                            let storage = JSON.parse(localStorage.getItem("storage")!);
                            let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
                            if (this.state.ownerList.filter((x) => x.id === userUniqueID).length > 0) {
                                const index = this.state.ownerList.findIndex((x) => x.id === userUniqueID);
                                accountDetails.ownerName = this.state.ownerList[index].name;
                                accountDetails.owner = this.state.ownerList[index].id;
                            }
                            else {
                                accountDetails.ownerName = "";
                                accountDetails.owner = 0;
                            }
                            this.setState({ accountDetails });
                        });
                    }
                    else if (type === "filter") {
                        this.setState({ filterOwnerList: result.result, filterOwnerListCopy: result.result }, () => {
                            this.filterAccountsListData();
                        });
                    }
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong. Failed to fetch Agent.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "sales-acc-1-7a", 
                });
                reject();
            });
    }

    getSalesSource = () => {
        SalesService.getSalesSource()
            .then(async (result: any | null) => {
                // debugger;
                if (result != null) {
                    this.setState({ sourceList: result.result });
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong. Failed to fetch Source.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "sales-acc-1-8a", 
                });
                reject();
            });
    }

    getSalesMarketSegment = () => {
        SalesService.getSalesMarketSegment()
            .then(async (result: any | null) => {
                // debugger;
                if (result != null) {
                    this.setState({ margetSegmentList: result.result });
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong. Failed to fetch Market Segment.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "sales-acc-1-9a", 
                });
                reject();
            });
    }

    getSalesAccountsList = () => {
        this.setState({ loader: true });
        let request: any = {};
        request.HID = this.state.mainHidValue;
        request.ViewOtherHID = this.state.showOtherEhidData;
        request.Count = 0;
        request.PageNo = 0;
        SalesService.getSalesAccountsList(request)
            .then(async (result: any | null) => {
                //debugger;
                if (result != null) {
                    this.setState({ accountsListCopy: result.result }, () => {
                        // accountsList: result.result,
                        this.filterAccountsListData();
                    });
                }
                resolve();
            })
            .catch((err: any) => {
                this.setState({ loader: false });
                Utils.toastError("Something went wrong. Failed to fetch Accounts List.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "sales-acc-1-10a", 
                });
                reject();
            });
    }

    getSalesAccountListById = (accId) => {
        // used to get accountList Data after create account with save and continue
        this.setState({ loader: true });
        let request: any = {};
        request.AccountId = accId;
        SalesService.getSalesAccountListById(request)
            .then(async (result: any | null) => {
                // debugger;
                this.setState({ loader: false });
                if (result != null) {
                    this.props.history.push('/sales/accountDetails', { selectedAccount: result.result, fromPage: "salesAccounts", activeTab: "overview" });
                }
                resolve();
            })
            .catch((err: any) => {
                this.setState({ loader: false });
                Utils.toastError("Something went wrong. Failed to fetch Accounts List.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "sales-acc-1-10a", 
                });
                reject();
            });
    }

    selectHotelDropDown = (hotelDetails: any) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        let { columns } = this.state;
        const index = data.findIndex((x) => x.id === "viewOnly");
        data[index].isChecked = false;
        columns[index].hidden = true;
        columns[index].searchable = false;
        // debugger;
        this.setState({
            mainHidName: hotelDetails.lettercode,
            mainHidValue: hotelDetails.hotelID,
            loadCounter: 1,
            isLoadMore: false,
            showOtherEhidData: "no",
            tableColumnList: data,
            columns: columns,
            filterSelectedEhidList: [],
            accountDetails: {
                accountId: -1, accountName: "", lettercode: "", ehidValue: -1, status: 1, statusName: "Lead", accountScore: -1, accountScoreName: "",
                owner: -1, ownerName: "", source: -1, sourceName: "", marketSegment: -1, marketSegmentName: "", website: "", taxId: "", iata: "",
                targetAccount: "no", isEdit: "no",
            },
            accountAddressDetails: {
                addressId: -1, accountId: -1, country: "United States", countryCode: "USA", street: "", city: "",
                state: "", stateCode: "", zipcode: "", isEdit: "no",
            },
            accountContactDetails: {
                contactId: -1, accountId: -1, name: "", email: "", phone: "", title: "", isEdit: "no",
            },
            errors: {
                accountName: "", lettercode: '', statusName: "", address: "", zipCode: "", contact: "", email: "", phone: "",
            },
            isSaveDisabled: true,
            isAddressMandatory: false,
			isContactMandatory: false,
            searchvalue: "",
        }, () => {
            this.getSalesAccountsList();
            // this.getSalesAgent(-1, "filter");  // API for Owner Dropdown
            this.checkAutoFillEhidForCreateAcc();
        });
    };

    checkAutoFillEhidForCreateAcc = () => {
        if (this.state.mainHidName.toLowerCase() === "all") {
            let accountDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountDetails)));
            accountDetails.lettercode = "";
            accountDetails.ehidValue = -1;
            this.setState({ accountDetails, ownerList: [] });
        }
        else {
            let accountDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountDetails)));
            if (this.state.salesEhidList.filter((x) => x.name === this.state.mainHidName && x.id === this.state.mainHidValue).length > 0) {
                accountDetails.lettercode = this.state.mainHidName;
                accountDetails.ehidValue = this.state.mainHidValue;
                this.setState({ accountDetails }, () => {
                    this.getSalesAgent(this.state.mainHidValue, "form");
                });
            }
            else {
                let accountDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountDetails)));
                accountDetails.lettercode = "";
                accountDetails.ehidValue = -1;
                this.setState({ accountDetails, ownerList: [] });
            }
        }
    }

    CleanSearch = () => {
        if (this.tableSearch.current !== null) {
            this.tableSearch.current.CleanSearch();

        }
    };
    openCreateAccountPopup = () => {
        this.setState({ ispopupshow: true, });
    }

    closeCreateAccount = () => {
        this.setState({
            ispopupshow: false,
            statesList: this.state.defaultStatesList,
            accountDetails: {
                accountId: -1, accountName: "", lettercode: "", ehidValue: -1, status: 1, statusName: "Lead", accountScore: -1, accountScoreName: "",
                owner: -1, ownerName: "", source: -1, sourceName: "", marketSegment: -1, marketSegmentName: "", website: "", taxId: "", iata: "",
                targetAccount: "no", isEdit: "no",
            },
            accountAddressDetails: {
                addressId: -1, accountId: -1, country: "United States", countryCode: "USA", street: "", city: "",
                state: "", stateCode: "", zipcode: "", isEdit: "no",
            },
            accountContactDetails: {
                contactId: -1, accountId: -1, name: "", email: "", phone: "", title: "", isEdit: "no",
            },
            errors: {
                accountName: "", lettercode: '', statusName: "", address: "", zipCode: "", contact: "", email: "", phone: "",
            },
            isSaveDisabled: true,
            isAddressMandatory: false,
			isContactMandatory: false,
        }, () => {
            this.checkAutoFillEhidForCreateAcc();
        });
    }
    isFormValid = () => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountDetails)));
        let accountAddressDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountAddressDetails)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));

        if (accountDetails.accountName === "") {
            errors.accountName = 'Account name is required.';
        }
        if (accountDetails.lettercode === "") {
            errors.lettercode = 'Ehid is required.';
        }
        if (accountDetails.statusName === "") {
            errors.statusName = 'Status is required.';
        }
        // debugger;
        if(accountAddressDetails.isEdit !== "no") {
            if (((accountAddressDetails.street?.trim() === "" && accountAddressDetails.city?.trim() === "" && accountAddressDetails.state === "" && accountAddressDetails.zipcode?.trim() === "") ||
                (accountAddressDetails.street?.trim() !== "" && accountAddressDetails.city?.trim() !== "" && accountAddressDetails.state !== "" && accountAddressDetails.zipcode?.trim() !== ""))) {
                errors.address = "";
            }
            else {
                errors.address = 'Please enter a valid address. A valid address contains street, city, state, zipcode and country.';
            }
        }
        if(accountContactDetails.isEdit !== "no") {
            if (((accountContactDetails.name?.trim() === "" && accountContactDetails.email?.trim() === "" && accountContactDetails.phone?.trim() === "" && accountContactDetails.title?.trim() === "") ||
                (accountContactDetails.name?.trim() !== "" && accountContactDetails.email?.trim() !== ""))) {
                errors.contact = "";
            }
            else {
                errors.contact = 'Please enter a valid contact. A valid contact must contain a name and an email address.';
            }
        }
        if(accountAddressDetails.zipcode?.trim() !== "") {
            if(accountAddressDetails.zipcode?.trim().length < 3) {
                errors.zipCode = 'Please enter a valid zip code. A valid zip code is of minimum 3 characters length.';
            }
            else {
                errors.zipCode = ""
            }
        }
        if(accountContactDetails.email?.trim() !== "") {
            const emailRegex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if(!emailRegex.test(accountContactDetails.email?.toLowerCase()?.trim())) {
                errors.email = 'Please enter a valid email address.';
            }
            else {
                errors.email = ""
            }
        }
        this.setState({ errors });
        // debugger;
        let fields = Object.keys(errors);
        if(fields.length > 0) {
            for(let i = 0; i < fields.length; i++) {
                if(errors[fields[i]] !== "") {
                    Utils.toastError("Please check and correct the highlighted fields.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "sales-acc-1a-1a", 
                    });
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    validateZip = (event) => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let zip = event.target.value;
        if (zip?.toString()?.length === 0) {
            errors.zipCode = "";
            this.setState({ errors });
            return;
        }
        if(zip?.trim().length < 3) {
            errors.zipCode = 'Please enter a valid zip code. A valid zip code is of minimum 3 characters length.';
        }
        this.setState({ errors });
    }

    validateEmail = (event) => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let email = event.target.value;
        if (email?.toString()?.length === 0) {
            errors.email = "";
            this.setState({ errors });
            return;
        }
        const emailRegex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!emailRegex.test(email?.toLowerCase()?.trim())) {
            errors.email = 'Please enter a valid email address.';
        }
        else {
            errors.email = ""
        }
        this.setState({ errors });
    }

    validatePhone = (event) => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let phone = event.target.value;
        if (phone?.toString()?.length === 0) {
            errors.phone = "";
            this.setState({ errors });
            return;
        }
        if (phone?.toString()?.includes("+") && phone?.toString()?.substr(1, 1) !== "1") {
            if (phone?.toString()?.length < 9) {
                errors.phone = "Mobile Phone Number is not valid";
            } else {
                errors.phone = "";
            }
            this.setState({ errors });
            return;
        }
        User.ValidatePhoneNumber(phone).then((response: any) => {
            debugger;
            if (response?.fakeNumber === "YES") {
                errors.phone = "Mobile Phone Number is not valid";
            } else if (response?.lineType !== "CELL PHONE") {
                errors.phone = response?.lineType && response?.lineType !== "FAKE" ? `${this.CapitalizeFirstLetter(response?.lineType)} Phone Numbers are not supported` : `Mobile Phone Number is not valid`;
            } else {
                errors.phone = "";
            }
            this.setState({ errors });
        });
    }


    CapitalizeFirstLetter = (string) => {
        if (string) {
            return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
        } else {
            return string;
        }
    }

    onSaveCreateAccnt = (type) => {
        let formValid = this.isFormValid();
        if (formValid) {
            // console.log("Account Details " + JSON.stringify(this.state.accountDetails));
            // console.log("Account Address Details " + JSON.stringify(this.state.accountAddressDetails));
            // console.log("Account Contact Details " + JSON.stringify(this.state.accountContactDetails));
            let accountDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountDetails)));
            let accountAddressDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountAddressDetails)));
            let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));

            let storage = JSON.parse(localStorage.getItem("storage")!);
            let tenantID = storage === null ? 0 : (storage.tenantID as any);
            accountDetails.tenantID = tenantID;
            accountAddressDetails.tenantID = tenantID;
            accountContactDetails.tenantID = tenantID;

            let request: any = {};
            request.SalesAccountRequest = accountDetails;
            request.SalesAccountAddressRequest = accountAddressDetails;
            request.SalesAccountContactRequest = accountContactDetails;

            SalesService.SaveSalesAccount(request)
                .then(async (result: any | null) => {
                    // debugger;
                    if (result.saveStatus.toLowerCase() === "success") {
                        this.setState({ searchvalue: "" }); 
                        toast.success(result.message,
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "SalesAcc1",
                            });
                        this.closeCreateAccount();
                        if(type === "exit") {
                            this.getSalesAccountsList();
                        }
                        else if(type === "continue") {
                            this.getSalesAccountListById(result.actualID);
                        }
                    } else {
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "SalesAcc1",
                        });
                    }
                    resolve();
                })
                .catch((error) => {
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "SalesAcc1",
                    });
                    reject();
                });

        }


    }

    rowEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
            if (row.accountID > 0) {
                this.props.history.push('/sales/accountDetails', { selectedAccount: row, fromPage: "salesAccounts", activeTab: "overview" });
            }
        }
    }

    onDropdownChange = (item, type) => {
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountDetails)));
        let accountAddressDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountAddressDetails)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        if (type === "ehid" && accountDetails.ehidValue !== item.id) {
            this.getSalesAgent(item.id, "form");
            accountDetails.lettercode = item.name;
            accountDetails.ehidValue = item.id;
            accountDetails.isEdit = "yes";
            errors.lettercode = item.name == '' ? 'Please Select EHID.' : '';
            let isSaveDisabled = true;
            if(accountDetails.accountName.length > 0 && accountDetails.lettercode.length > 0 && accountDetails.statusName.length > 0) {
                isSaveDisabled = false;
            }
            this.setState({ isSaveDisabled });
        }
        if (type === "status") {            
            accountDetails.status = item.id;
            accountDetails.statusName = item.name;
            accountDetails.isEdit = "yes";
            errors.lettercode = item.name == '' ? 'Please Select Status.' : '';
            let isSaveDisabled = true;
            if(accountDetails.accountName.length > 0 && accountDetails.lettercode.length > 0 && accountDetails.statusName.length > 0) {
                isSaveDisabled = false;
            }
            this.setState({ isSaveDisabled });
        }
        if (type === "score") {
            accountDetails.accountScore = item.id;
            accountDetails.accountScoreName = item.name;
            accountDetails.isEdit = "yes";
        }
        if (type === "owner") {
            accountDetails.owner = item.id;
            accountDetails.ownerName = item.name;
            accountDetails.isEdit = "yes";
        }
        if (type === "source") {
            accountDetails.source = item.id;
            accountDetails.sourceName = item.name;
            accountDetails.isEdit = "yes";
        }
        if (type === "mktsegment") {
            accountDetails.marketSegment = item.id;
            accountDetails.marketSegmentName = item.name;
            accountDetails.isEdit = "yes";
        }
        if (type === "country" && accountAddressDetails.country !== item.name) {
            let statesList = Object.assign({}, JSON.parse(JSON.stringify(this.state.statesList)));
            accountAddressDetails.country = item.name;
            accountAddressDetails.countryCode = item.code;
            accountAddressDetails.state = "";
            accountAddressDetails.stateCode = "";
            accountAddressDetails.isEdit = "yes";
            statesList = this.state.countriesAndStatesList.filter((list) => list.iso3 === item.code)[0].states;
            statesList = statesList.map((data, index) => ({
                id: index,
                name: data.name,
                eventKey: "state_" + index,
                code: data.state_code,
            }));
            this.setState({ statesList });
        }
        if (type === "states") {
            accountAddressDetails.state = item.name;
            accountAddressDetails.stateCode = item.code;
            accountAddressDetails.isEdit = "yes";
            let isAddressMandatory = false;
            /* Condition to check if user has started typing address and so mark address fields as mandatory*/
            if(accountAddressDetails.street.length > 0 || accountAddressDetails.city.length > 0 || accountAddressDetails.state.length > 0 || accountAddressDetails.zipcode.length > 0) {
                isAddressMandatory = true;
            }
            /* Condition to reset errors state*/
            if (((accountAddressDetails.street?.trim() === "" && accountAddressDetails.city?.trim() === "" && accountAddressDetails.state === "" && accountAddressDetails.zipcode?.trim() === "") ||
                (accountAddressDetails.street?.trim() !== "" && accountAddressDetails.city?.trim() !== "" && accountAddressDetails.state !== "" && accountAddressDetails.zipcode?.trim() !== ""))) {
                errors.address = "";
            }
            this.setState({ isAddressMandatory });
        }
        this.setState({ accountDetails, accountAddressDetails, accountContactDetails, errors });
    }

    onInputFieldChange = (event: any, type: any) => {
        //debugger;
        let errors = Object.assign({}, JSON.parse(JSON.stringify(this.state.errors)));
        let accountDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountDetails)));
        let accountAddressDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountAddressDetails)));
        let accountContactDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountContactDetails)));
        let value = event.target.value;
        if (type === "accName") {
            accountDetails.accountName = value?.toString()?.replace(/[^a-zA-Z0-9 ]+/ig, '');
            accountDetails.isEdit = "yes";
            errors.accountName = value == '' ? 'Please enter account name.' : '';
            let isSaveDisabled = true;
            if(accountDetails.accountName.length > 0 && accountDetails.lettercode.length > 0 && accountDetails.statusName.length > 0) {
                isSaveDisabled = false;
            }
            this.setState({ isSaveDisabled });
        }
        if (type === "website") {
            accountDetails.website = value;
            accountDetails.isEdit = "yes";
        }
        if (type === "taxId") {
            accountDetails.taxId = value;
            accountDetails.isEdit = "yes";
        }
        if (type === "iata") {
            accountDetails.iata = value;
            accountDetails.isEdit = "yes";
        }
        if (type === "street") {
            accountAddressDetails.street = value?.toString()?.replace(/[^a-zA-Z0-9\- ]+/ig, '');
            accountAddressDetails.isEdit = "yes";
            let isAddressMandatory = false;
            /* Condition to check if user has started typing address and so mark address fields as mandatory*/
            if(accountAddressDetails.street.length > 0 || accountAddressDetails.city.length > 0 || accountAddressDetails.state.length > 0 || accountAddressDetails.zipcode.length > 0) {
                isAddressMandatory = true;
            }
            /* Condition to reset errors state*/
            if (((accountAddressDetails.street?.trim() === "" && accountAddressDetails.city?.trim() === "" && accountAddressDetails.state === "" && accountAddressDetails.zipcode?.trim() === "") ||
                (accountAddressDetails.street?.trim() !== "" && accountAddressDetails.city?.trim() !== "" && accountAddressDetails.state !== "" && accountAddressDetails.zipcode?.trim() !== ""))) {
                errors.address = "";
            }
            this.setState({ isAddressMandatory });
        }
        if (type === "city") {
            accountAddressDetails.city = value?.toString()?.replace(/[^a-zA-Z0-9\- ]+/ig, '');;
            accountAddressDetails.isEdit = "yes";
            let isAddressMandatory = false;
            /* Condition to check if user has started typing address and so mark address fields as mandatory*/
            if(accountAddressDetails.street.length > 0 || accountAddressDetails.city.length > 0 || accountAddressDetails.state.length > 0 || accountAddressDetails.zipcode.length > 0) {
                isAddressMandatory = true;
            }
            /* Condition to reset errors state*/
            if (((accountAddressDetails.street?.trim() === "" && accountAddressDetails.city?.trim() === "" && accountAddressDetails.state === "" && accountAddressDetails.zipcode?.trim() === "") ||
                (accountAddressDetails.street?.trim() !== "" && accountAddressDetails.city?.trim() !== "" && accountAddressDetails.state !== "" && accountAddressDetails.zipcode?.trim() !== ""))) {
                errors.address = "";
            }
            this.setState({ isAddressMandatory });
        }
        if (type === "zip") {
            accountAddressDetails.zipcode = value?.toString()?.replace(/[^a-zA-Z0-9\- ]+/ig, '');
            accountAddressDetails.isEdit = "yes";
            let isAddressMandatory = false;
            /* Condition to check if user has started typing address and so mark address fields as mandatory*/
            if(accountAddressDetails.street.length > 0 || accountAddressDetails.city.length > 0 || accountAddressDetails.state.length > 0 || accountAddressDetails.zipcode.length > 0) {
                isAddressMandatory = true;
            }
            /* Condition to reset errors state*/
            if (((accountAddressDetails.street?.trim() === "" && accountAddressDetails.city?.trim() === "" && accountAddressDetails.state === "" && accountAddressDetails.zipcode?.trim() === "") ||
                (accountAddressDetails.street?.trim() !== "" && accountAddressDetails.city?.trim() !== "" && accountAddressDetails.state !== "" && accountAddressDetails.zipcode?.trim() !== ""))) {
                errors.address = "";
            }
            /* Condition to reset zipcode errors state*/
            if(accountAddressDetails.zipcode?.trim().length >= 3 || accountAddressDetails.zipcode?.trim().length === 0) {
                errors.zipCode = "";
            }
            this.setState({ isAddressMandatory });
        }
        if (type === "contactName") {
            accountContactDetails.name = value?.toString()?.replace(/[^a-zA-Z0-9 ]+/ig, '');;
            accountContactDetails.isEdit = "yes"; 
            let isContactMandatory = false;
            /* Condition to check if user has started typing contact and so mark contact fields as mandatory*/
            if(accountContactDetails.name.length > 0 || accountContactDetails.email.length > 0 || accountContactDetails.phone.length > 0 || accountContactDetails.title.length > 0) {
                if(accountContactDetails.name.length > 0 && accountContactDetails.email.length > 0) {
                    isContactMandatory = false;
                }
                else {
                    isContactMandatory = true;
                }
            }
            /* Condition to reset errors state*/
            if (((accountContactDetails.name?.trim() === "" && accountContactDetails.email?.trim() === "" && accountContactDetails.phone?.trim() === "" && accountContactDetails.title?.trim() === "") ||
                (accountContactDetails.name?.trim() !== "" && accountContactDetails.email?.trim() !== ""))) {
                errors.contact = "";
            }
            this.setState({ isContactMandatory });
        }
        if (type === "contactEmail") {
            accountContactDetails.email = value;
            accountContactDetails.isEdit = "yes";
            let isContactMandatory = false;
            /* Condition to check if user has started typing contact and so mark contact fields as mandatory*/
            if(accountContactDetails.name.length > 0 || accountContactDetails.email.length > 0 || accountContactDetails.phone.length > 0|| accountContactDetails.title.length > 0) {
                if(accountContactDetails.name.length > 0 && accountContactDetails.email.length > 0) {
                    isContactMandatory = false;
                }
                else {
                    isContactMandatory = true;
                }
            }
            /* Condition to reset errors state*/
            if (((accountContactDetails.name?.trim() === "" && accountContactDetails.email?.trim() === "" && accountContactDetails.phone?.trim() === "" && accountContactDetails.title?.trim() === "") ||
                (accountContactDetails.name?.trim() !== "" && accountContactDetails.email?.trim() !== ""))) {
                errors.contact = "";
            }
            this.setState({ isContactMandatory });
        }
        if (type === "contactPhone") {
            let phone = value?.toString()?.replace(/[^0-9\+]+/gi, "");
            if (phone) {
                if (phone.indexOf("-") >= 0) {
                  phone = phone?.replaceAll("-", "");
                }
        
                if (phone?.toString()?.length > 1) {
                  phone = phone?.toString().substr(0, 1) + phone?.toString()?.substr(1)?.replaceAll("+", "");
                }
            }
            accountContactDetails.phone = phone;
            accountContactDetails.isEdit = "yes";
            let isContactMandatory = false;
            /* Condition to check if user has started typing contact and so mark contact fields as mandatory*/
            if(accountContactDetails.name.length > 0 || accountContactDetails.email.length > 0 || accountContactDetails.phone.length > 0|| accountContactDetails.title.length > 0) {
                if(accountContactDetails.name.length > 0 && accountContactDetails.email.length > 0) {
                    isContactMandatory = false;
                }
                else {
                    isContactMandatory = true;
                }
            }
            /* Condition to reset errors state*/
            if (((accountContactDetails.name?.trim() === "" && accountContactDetails.email?.trim() === "" && accountContactDetails.phone?.trim() === "" && accountContactDetails.title?.trim() === "") ||
                (accountContactDetails.name?.trim() !== "" && accountContactDetails.email?.trim() !== ""))) {
                errors.contact = "";
            }
            this.setState({ isContactMandatory });
        }
        if (type === "contactTitle") {
            accountContactDetails.title = value;
            accountContactDetails.isEdit = "yes";
            let isContactMandatory = false;
            /* Condition to check if user has started typing contact and so mark contact fields as mandatory*/
            if(accountContactDetails.name.length > 0 || accountContactDetails.email.length > 0 || accountContactDetails.phone.length > 0|| accountContactDetails.title.length > 0) {
                if(accountContactDetails.name.length > 0 && accountContactDetails.email.length > 0) {
                    isContactMandatory = false;
                }
                else {
                    isContactMandatory = true;
                }
            }
            /* Condition to reset errors state*/
            if (((accountContactDetails.name?.trim() === "" && accountContactDetails.email?.trim() === "" && accountContactDetails.phone?.trim() === "" && accountContactDetails.title?.trim() === "") ||
                (accountContactDetails.name?.trim() !== "" && accountContactDetails.email?.trim() !== ""))) {
                errors.contact = "";
            }
            this.setState({ isContactMandatory });
        }
        this.setState({ accountDetails, accountAddressDetails, accountContactDetails, errors });
    }

    onTargetAccountChange = (event: any) => {
        let accountDetails = Object.assign({}, JSON.parse(JSON.stringify(this.state.accountDetails)));
        if (event.target.checked) {
            accountDetails.targetAccount = "yes";
        }
        else {
            accountDetails.targetAccount = "no";
        }
        this.setState({ accountDetails });
    }

    selectSortingField = (sortKey) => {
        this.setState({ selectedSort: sortKey, }, () => {
            this.filterAccountsListData();
        });
    }

    // handleSorting = () => {
    //     // debugger;
    //     let sortKey = this.state.selectedSort;
    //     let sortaccountList: any = [];
    //     let accountsList = Object.assign([], JSON.parse(JSON.stringify(this.state.accountsList)));

    //     if (sortKey.toString().toLowerCase() === "accountName".toLowerCase()) {
    //         sortaccountList = accountsList.sort((a, b) => (a.accountName.localeCompare(b.accountName)));
    //     }
    //     else if (sortKey.toString().toLowerCase() === "owner".toLowerCase()) {
    //         sortaccountList = accountsList.sort((a, b) => (a.owner.localeCompare(b.owner)));
    //     }
    //     else if (sortKey.toString().toLowerCase() === "score".toLowerCase()) {
    //         sortaccountList = accountsList.sort((a, b) => (a.scoreID - b.scoreID));
    //     }
    //     else if (sortKey.toString().toLowerCase() === "status".toLowerCase()) {
    //         sortaccountList = accountsList.sort((a, b) => (a.status.localeCompare(b.status)));
    //     }
    //     else if (sortKey.toString().toLowerCase() === "lettercode".toLowerCase()) {
    //         sortaccountList = accountsList.sort((a, b) => (a.lettercode.localeCompare(b.lettercode)));
    //     }
    //     else if (sortKey.toString().toLowerCase() === "accountTarget".toLowerCase()) {
    //         sortaccountList = accountsList.sort((a, b) => (a.accountTarget.localeCompare(b.accountTarget) || a.eDate - b.eDate));
    //     }
    //     else if (sortKey.toString().toLowerCase() === "sourceName".toLowerCase()) {
    //         sortaccountList = accountsList.sort((a, b) => (a.sourceName.localeCompare(b.sourceName)));
    //     }
    //     else if (sortKey.toString().toLowerCase() === "webSite".toLowerCase()) {
    //         sortaccountList = accountsList.sort((a, b) => (a.webSite.localeCompare(b.webSite)));
    //     }
    //     else if (sortKey.toString().toLowerCase() === "marketSegment".toLowerCase()) {
    //         sortaccountList = accountsList.sort((a, b) => (a.marketSegment.localeCompare(b.marketSegment)));
    //     }
    //     else if (sortKey.toString().toLowerCase() === "createdDate".toLowerCase()) {
    //         sortaccountList = accountsList.sort((a, b) => (a.eDate.localeCompare(b.eDate)));
    //     }


    //     this.setState({ accountsList: sortaccountList });
    // }
    handleDensityView = (viewName) => {
        this.setState({ selectedDensity: viewName });
    }

    handleFilterInputChange = (event, field) => {
        let value = event.target.value;
        if (field === "owner") {
            let filteredList = this.state.filterOwnerListCopy.filter((item: any) => {
                return item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
            });
            this.setState({ filterOwnerList: filteredList, filterSearchInput: value });
        }
        else if (field === "hotelID") {
            let filteredList = this.state.filterEhidListCopy.filter((item: any) => {
                return item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
            });
            this.setState({ filterEhidList: filteredList, filterSearchInput: value });
        }
    }

    onFilterDropdownOpen = (event, field) => {
        // added to clean filter search box and update  filter list to default/all
        let filterOwnerListCopy = Object.assign([], JSON.parse(JSON.stringify(this.state.filterOwnerListCopy)));
        let filterEhidListCopy = Object.assign([], JSON.parse(JSON.stringify(this.state.filterEhidListCopy)));
        if (event && field === "owner") {
            this.setState({ filterOwnerList: filterOwnerListCopy, filterSearchInput: "" });
        }
        else if (event && field === "hotelID") {
            this.setState({ filterEhidList: filterEhidListCopy, filterSearchInput: "" });
        }
    }

    handleSelectOrClearAll = (type, field) => {
        //debugger;
        let filterSelectedStatusList = Object.assign([], JSON.parse(JSON.stringify(this.state.statusList)));
        let filterSelectedEhidList = Object.assign([], JSON.parse(JSON.stringify(this.state.filterEhidListCopy)));

        if (field === "status") {
            if (type === "selectAll") {

            } else {
                filterSelectedStatusList = [];
            }
            this.setState({ filterSelectedStatusList }, () => {
                this.filterAccountsListData();
            });
        }
        else if (field === "hotelID") {
            if (type === "selectAll") {

            } else {
                filterSelectedEhidList = [];
            }
            this.setState({ filterSelectedEhidList }, () => {
                this.filterAccountsListData();
            });
        }
        else if (field === "owner") {
            if (type === "clearAll") {
                this.setState({ filterSelectedOwner: "Owner" }, () => {
                    this.filterAccountsListData();
                });
            }
        }
    }

    isFilterCheckboxChecked = (item, field) => {
        if (field === "status") {
            if (this.state.filterSelectedStatusList.filter((x) => x.id === item.id).length > 0) {
                return true;
            } else {
                return false;
            }
        }
        else if (field === "hotelID") {
            if (this.state.filterSelectedEhidList.filter((x) => x.id === item.id).length > 0) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    filterAccountsList = (item, field, event) => {
        if (field === "owner") {
            this.setState({ filterSelectedOwner: item.name }, () => {
                this.filterAccountsListData();
            });
        }
        else if (field === "status") {
            let filterSelectedStatusList = Object.assign([], JSON.parse(JSON.stringify(this.state.filterSelectedStatusList)));
            if (event.target.checked) {
                filterSelectedStatusList.push(item);
            }
            else {
                if (filterSelectedStatusList.filter((x) => x.name === item.name).length > 0) {
                    const index = filterSelectedStatusList.findIndex((x) => x.name === item.name);
                    filterSelectedStatusList.splice(index, 1);
                }
            }
            this.setState({ filterSelectedStatusList }, () => {
                this.filterAccountsListData();
            });
        }
        else if (field === "hotelID") {
            let filterSelectedEhidList = Object.assign([], JSON.parse(JSON.stringify(this.state.filterSelectedEhidList)));
            if (event.target.checked) {
                filterSelectedEhidList.push(item);
            }
            else {
                if (filterSelectedEhidList.filter((x) => x.id === item.id).length > 0) {
                    const index = filterSelectedEhidList.findIndex((x) => x.id === item.id);
                    filterSelectedEhidList.splice(index, 1);
                }
            }
            this.setState({ filterSelectedEhidList }, () => {
                this.filterAccountsListData();
            });
        }
    }

    filterAccountsListData = () => {
        //  debugger;
        this.setState({ loader: true });
        let accountsList = Object.assign([], JSON.parse(JSON.stringify(this.state.accountsListCopy)));
        let filterSelectedStatusList = Object.assign([], JSON.parse(JSON.stringify(this.state.filterSelectedStatusList)));
        let filterSelectedEhidList = Object.assign([], JSON.parse(JSON.stringify(this.state.filterSelectedEhidList)));
        if (this.state.filterSelectedOwner.toLowerCase() !== "owner") {
            accountsList = accountsList.filter((x) => x.owner === this.state.filterSelectedOwner);
        }
        if (filterSelectedStatusList.length > 0) {
            accountsList = accountsList.
                filter((x) => {
                    for (let i = 0; i < filterSelectedStatusList.length; i++) {
                        if (filterSelectedStatusList[i].name === x.status) {
                            return true;
                        }
                    }
                    return false;
                });
        }
        if (filterSelectedEhidList.length > 0) {
            accountsList = accountsList.
                filter((x) => {
                    for (let i = 0; i < filterSelectedEhidList.length; i++) {
                        if (filterSelectedEhidList[i].id === x.hotelID) {
                            return true;
                        }
                    }
                    return false;
                });
        }
        
        let SearchFieldItem = '';
        this.state.columns.forEach((ele, inx) => {
            if (ele.hidden === false) {
                SearchFieldItem = (SearchFieldItem + 'element.' + ele.dataField + "}").toString();
            }
        });

        SearchFieldItem = SearchFieldItem.substring(0, SearchFieldItem.length - 1);
        SearchFieldItem = SearchFieldItem.replaceAll("}", "+'}'+")
        accountsList.forEach((element, inx) => {
            accountsList[inx]["searchField"] = eval(SearchFieldItem);
        });

        if (this.state.searchvalue.length > 2) {
            accountsList = accountsList.filter((item, index) => item.searchField.toUpperCase().includes(this.state.searchvalue.toUpperCase()))
        }
        
        // Sorting filtered data with selected sort field
        let sortKey = this.state.selectedSort;
        if (sortKey.toString().toLowerCase() === "accountName".toLowerCase()) {
            accountsList = accountsList.sort((a, b) => (a.accountName.localeCompare(b.accountName)));
        }
        else if (sortKey.toString().toLowerCase() === "owner".toLowerCase()) {
            accountsList = accountsList.sort((a, b) => (a.owner.localeCompare(b.owner)));
        }
        else if (sortKey.toString().toLowerCase() === "score".toLowerCase()) {
            accountsList = accountsList.sort((a, b) => (a.scoreID - b.scoreID));
        }
        else if (sortKey.toString().toLowerCase() === "status".toLowerCase()) {
            accountsList = accountsList.sort((a, b) => (a.status.localeCompare(b.status)));
        }
        else if (sortKey.toString().toLowerCase() === "lettercode".toLowerCase()) {
            accountsList = accountsList.sort((a, b) => (a.lettercode.localeCompare(b.lettercode)));
        }
        else if (sortKey.toString().toLowerCase() === "accountTarget".toLowerCase()) {
            accountsList = accountsList.sort((a, b) => (b.accountTarget.localeCompare(a.accountTarget) || a.eDate - b.eDate));
        }
        else if (sortKey.toString().toLowerCase() === "sourceName".toLowerCase()) {
            accountsList = accountsList.sort((a, b) => (a.sourceName.localeCompare(b.sourceName)));
        }
        else if (sortKey.toString().toLowerCase() === "webSite".toLowerCase()) {
            accountsList = accountsList.sort((a, b) => (a.webSite.localeCompare(b.webSite)));
        }
        else if (sortKey.toString().toLowerCase() === "marketSegment".toLowerCase()) {
            accountsList = accountsList.sort((a, b) => (a.marketSegment.localeCompare(b.marketSegment)));
        }
        else if (sortKey.toString().toLowerCase() === "createdDate".toLowerCase()) {
            accountsList = accountsList.sort((a, b) => (a.eDate.localeCompare(b.eDate)));
        }
        
        let isLoadMore = accountsList.length > this.state.loadCounter * 25 ? true : false;
        var filteraccList = accountsList.filter((i, index) => (index < (this.state.loadCounter * 25)));
        this.setState({ accountsList: filteraccList, isLoadMore, loader: false });
    }

    loadMoreRecord = () => {
        this.setState({ loadCounter: this.state.loadCounter + 1 }, () => {
            this.filterAccountsListData();
        });
    }
    handleSearch = (searchText) => {
        let searchValue = searchText.target.value;
        this.setState({ searchvalue: searchValue }, () => {

            if (searchValue.length == 0 || searchValue.length > 2) {
                this.filterAccountsListData();
            }
        })

    }

    viewOtherEhidData = (event) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        let { columns } = this.state;
        const index = data.findIndex((x) => x.id === "viewOnly");
        data[index].isChecked = event.target.checked;
        columns[index].hidden = data[index].isChecked ? false : true;
        columns[index].searchable = data[index].isChecked ? true : false;
        this.setState({ showOtherEhidData: event.target.checked ? "yes" : "no", tableColumnList: data, columns, }, () => {
            this.getSalesAccountsList();
        });
    }

    toggleClass=(evt) => {
        if(evt == "address"){
            this.setState({addressToggle: !this.state.addressToggle});
        }
        if(evt == "contact"){
            this.setState({contactToggle: !this.state.contactToggle});
        }
      }

    render() {
        const { errors, accountDetails } = this.state;
        const rowClasses = (row, rowIndex) => {
            let classes = '';
            if (row.viewPermission.toLowerCase() === "view") {
                classes = classes + 'highlighted-row';
            }
            return classes;
        };
        let sortName = this.state.sortList.filter((item) => item.eventKey === this.state.selectedSort).length > 0 ? this.state.sortList.filter((item) => item.eventKey === this.state.selectedSort)[0]?.name : "Sort";

        return (
            <div className="sales-module position-relative" >
                <Container fluid className="body-sec">
                    <ToastContainer
                        autoClose={3000}
                        containerId={"SalesAcc1"}
                        enableMultiContainer
                    />
                    <div className="page-heading underline d-flex">
                        <HidSelector
                            onClickEvent={this.selectHotelDropDown}
                            ref={this.childHID}
                            modulename={'Sales 2.0'}
                        />
                        <div className="mr-auto d-flex align-items-center">Accounts</div>
                        <div className="action-group d-flex">
                            { this.state.isCreateAccount.toLowerCase() === "yes" &&
                                <button type="button" className="btn btn-primary create-account" onClick={this.openCreateAccountPopup}>
                                    <div className='d-flex align-items-center'>
                                        <div>Create Account </div>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.0002 0.700195C6.54654 0.700195 6.17846 1.06828 6.17846 1.52193V6.17846H1.52193C1.06828 6.17846 0.700195 6.54654 0.700195 7.0002C0.700195 7.45385 1.06828 7.82193 1.52193 7.82193H6.17846V12.4785C6.17846 12.9321 6.54654 13.3002 7.0002 13.3002C7.45385 13.3002 7.82193 12.9321 7.82193 12.4785V7.82193H12.4785C12.9321 7.82193 13.3002 7.45385 13.3002 7.0002C13.3002 6.54654 12.9321 6.17846 12.4785 6.17846H7.82193V1.52193C7.82193 1.06828 7.45385 0.700195 7.0002 0.700195Z" fill="white" />
                                        </svg>
                                    </div>
                                </button>
                            }
                            <Dropdown className="more-action three-dot" alignRight>
                                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.state.moreAction.map(
                                        (item: any, idx: any) => (
                                            <Dropdown.Item
                                                eventKey={this.state.moreAction[idx].eventKey}
                                                key={idx}
                                            >
                                                {this.state.moreAction[idx].name}
                                            </Dropdown.Item>
                                        )
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="main-Page">
                        <div className="bdy-sec">
                            <ToolkitProvider
                                keyField="rNo"
                                data={this.state.accountsList}
                                columns={this.state.columns}
                                // search
                                rowEvents={this.rowEvents}
                            >
                                {(props: {
                                    searchProps: JSX.IntrinsicAttributes;
                                    baseProps: JSX.IntrinsicAttributes;
                                }) => (
                                    <div>
                                        <div className="search-header d-flex">
                                            <div className="salesSearch mrgn-right-8">
                                                {/* <TableSearch
                                                    id="tableSearch"
                                                    key="tableSearch"
                                                    ref={this.tableSearch}
                                                    {...props.searchProps}
                                                    CleanSearch={this.CleanSearch.bind(this)}
                                                    placeholder={"Search..."}
                                                /> */}
                                                <input
                                                    id="serachInput"
                                                    key="serachInput"
                                                    className="form-control"
                                                    style={{ backgroundColor: 'white' }}
                                                    type="text"
                                                    onChange={this.handleSearch}
                                                    placeholder={"Search..."}
                                                    value={this.state.searchvalue}
                                                    autoComplete="off"
                                                />

                                            </div>
                                            <div className='owner-selector mrgn-right-8'>
                                                <Dropdown className="dropDownSelector owner" onToggle={(event: any) => this.onFilterDropdownOpen(event, "owner")}>
                                                    <Dropdown.Toggle className='d-flex align-items-center'>
                                                        <div className='selected-val'><EllipsisWithTooltip placement="bottom">{this.state.filterSelectedOwner}</EllipsisWithTooltip></div>
                                                        <div className="drop-arrow"><FiChevronDown /></div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="search-sec">
                                                            <input type="text" className="form-control"
                                                                onChange={(e: any) => this.handleFilterInputChange(e, "owner")}
                                                                placeholder={"Search..."}
                                                                value={this.state.filterSearchInput}
                                                            />
                                                        </div>
                                                        <div className="list-area">
                                                            {this.state.filterOwnerList.map((items, index) => (
                                                                <Dropdown.Item
                                                                    eventKey={items.eventKey}
                                                                    key={index}
                                                                    onClick={() => this.filterAccountsList(items, "owner", "")}
                                                                >
                                                                    {items.name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </div>
                                                        <div className="footer d-flex">
                                                            <button id="clearAll" type="button" className="btn wht-bg txt-green"
                                                                onClick={() => this.handleSelectOrClearAll("clearAll", "owner")}
                                                                disabled={this.state.filterSelectedOwner.toLowerCase() === "owner"}
                                                            >
                                                                Clear Filter
                                                            </button>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className={this.state.showOtherEhidData.toLowerCase() !== "yes"? "Status-selector mrgn-right-8 mr-auto":"Status-selector mrgn-right-8"}>
                                                <Dropdown className="dropDownSelector status">
                                                    <Dropdown.Toggle className='d-flex align-items-center'>
                                                        {this.state.filterSelectedStatusList.length === 0 &&
                                                            <div className='selected-val'>Status</div>
                                                        }
                                                        {this.state.filterSelectedStatusList.length === 1 &&
                                                            <div className='selected-val'>{this.state.filterSelectedStatusList[0].name}</div>
                                                        }
                                                        {this.state.filterSelectedStatusList.length > 1 &&
                                                            <>
                                                                <div>Status</div>
                                                                <div className="cusBadge badgePrimary">{this.state.filterSelectedStatusList.length}</div>
                                                            </>
                                                        }
                                                        <div className="drop-arrow ml-auto"><FiChevronDown /></div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="list-area">
                                                            {this.state.statusList.map((items, index) => (
                                                                <div className="dropdown-item" key={index}>
                                                                    <EllipsisWithTooltip placement="bottom">
                                                                        <Form.Check
                                                                            custom
                                                                            type="checkbox"
                                                                            id={`owner_${items.name}`}
                                                                            //name={items.lettercode.toLowerCase()}
                                                                            label={items.name}
                                                                            value={items.name}
                                                                            onChange={(event) =>
                                                                                this.filterAccountsList(items, "status", event)
                                                                            }
                                                                            checked={this.isFilterCheckboxChecked(items, "status")}
                                                                        />
                                                                    </EllipsisWithTooltip>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="footer d-flex">
                                                            <button id="selectAll" type="button" className="btn wht-bg txt-green"
                                                                onClick={() => this.handleSelectOrClearAll("selectAll", "status")}
                                                                disabled={ this.state.statusList.length === this.state.filterSelectedStatusList.length }
                                                            >
                                                                Select All
                                                            </button>
                                                            <button id="ClearAll" type="button"
                                                                className="btn wht-bg txt-green ml-auto"
                                                                onClick={() => this.handleSelectOrClearAll("clearAll", "status")}
                                                                disabled={ this.state.filterSelectedStatusList.length === 0 }
                                                            >Clear All
                                                            </button>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            {this.state.isViewOtherEHIDToggle.toLowerCase() === "yes" && this.state.showOtherEhidData.toLowerCase() === "yes" &&
                                                <div className='ehid-selector mr-auto'>
                                                    <Dropdown className="dropDownSelector ehid" onToggle={(event: any) => this.onFilterDropdownOpen(event, "hotelID")}>
                                                        <Dropdown.Toggle className='d-flex align-items-center'>
                                                            {this.state.filterSelectedEhidList.length === 0 &&
                                                                <div className='selected-val'>EHID</div>
                                                            }
                                                            {this.state.filterSelectedEhidList.length === 1 &&
                                                                <div className='selected-val'>{this.state.filterSelectedEhidList[0].name}</div>
                                                            }
                                                            {this.state.filterSelectedEhidList.length > 1 &&
                                                                <>
                                                                    <div>EHID</div>
                                                                    <div className="cusBadge badgePrimary">{this.state.filterSelectedEhidList.length}</div>
                                                                </>
                                                            }
                                                            <div className="drop-arrow"><FiChevronDown /></div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <div className="search-sec">
                                                                <input type="text" className="form-control"
                                                                    onChange={(e: any) => this.handleFilterInputChange(e, "hotelID")}
                                                                    placeholder={"Search..."}
                                                                    value={this.state.filterSearchInput}
                                                                />
                                                            </div>
                                                            <div className="list-area">
                                                                {this.state.filterEhidList.map((items, index) => (
                                                                    <div className="dropdown-item" key={index}>
                                                                        <EllipsisWithTooltip placement="bottom">
                                                                            <Form.Check
                                                                                custom
                                                                                type="checkbox"
                                                                                id={`ehid_${index}`}
                                                                                //name={items.lettercode.toLowerCase()}
                                                                                label={items.name}
                                                                                value={items.name}
                                                                                onChange={(event) =>
                                                                                    this.filterAccountsList(items, "hotelID", event)
                                                                                }
                                                                                checked={this.isFilterCheckboxChecked(items, "hotelID")}
                                                                            />
                                                                        </EllipsisWithTooltip>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="footer d-flex">
                                                                <button id="selectAll" type="button" className="btn wht-bg txt-green"
                                                                    onClick={() => this.handleSelectOrClearAll("selectAll", "hotelID")}
                                                                    disabled={this.state.filterEhidListCopy.length === this.state.filterSelectedEhidList.length}
                                                                >
                                                                    Select All
                                                                </button>
                                                                <button id="ClearAll" type="button"
                                                                    className="btn wht-bg txt-green ml-auto"
                                                                    onClick={() => this.handleSelectOrClearAll("clearAll", "hotelID")}
                                                                    disabled={this.state.filterSelectedEhidList.length === 0}
                                                                >Clear All
                                                                </button>
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            }
                                            <div className='filter-selector mrgn-right-8'>
                                                <Dropdown className="dropDownSelector">
                                                    <Dropdown.Toggle className='d-flex align-items-center'>
                                                        <div className="selector-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                                                <path d="M4.44095 13.4059L4.13823 13.7093V1.03447C4.13823 0.664855 3.94103 0.323419 3.621 0.138576C3.30097 -0.0461922 2.90657 -0.0461922 2.58654 0.138576C2.26651 0.323419 2.06931 0.664855 2.06931 1.03447V13.7093L1.76658 13.4059C1.50516 13.1445 1.12413 13.0424 0.767024 13.1381C0.409919 13.2338 0.130972 13.5127 0.0352871 13.8698C-0.0604002 14.2269 0.0416807 14.608 0.303089 14.8694L2.37201 16.9383C2.56605 17.1324 2.82925 17.2415 3.10375 17.2415C3.37824 17.2415 3.64145 17.1324 3.83549 16.9383L5.90441 14.8694C6.16583 14.608 6.26792 14.2269 6.17221 13.8698C6.07652 13.5127 5.79758 13.2338 5.44047 13.1381C5.08337 13.0424 4.70234 13.1445 4.44092 13.4059H4.44095Z" />
                                                                <path d="M18.9656 0.689453H7.93132C7.56171 0.689453 7.22027 0.886654 7.03543 1.20668C6.85066 1.52671 6.85066 1.92112 7.03543 2.24115C7.22027 2.56118 7.56171 2.75838 7.93132 2.75838H18.9656C19.3352 2.75838 19.6766 2.56118 19.8615 2.24115C20.0462 1.92112 20.0462 1.52671 19.8615 1.20668C19.6766 0.886654 19.3352 0.689453 18.9656 0.689453Z" />
                                                                <path d="M16.207 5.51758H7.93132C7.56171 5.51758 7.22027 5.71478 7.03543 6.03481C6.85066 6.35484 6.85066 6.74924 7.03543 7.06927C7.22027 7.3893 7.56171 7.5865 7.93132 7.5865H16.207C16.5766 7.5865 16.9181 7.3893 17.1029 7.06927C17.2877 6.74924 17.2877 6.35484 17.1029 6.03481C16.9181 5.71478 16.5766 5.51758 16.207 5.51758Z" />
                                                                <path d="M13.4484 10.3447H7.93132C7.56171 10.3447 7.22027 10.5419 7.03543 10.862C6.85066 11.182 6.85066 11.5764 7.03543 11.8964C7.22027 12.2164 7.56171 12.4137 7.93132 12.4137H13.4484C13.8181 12.4137 14.1595 12.2164 14.3443 11.8964C14.5291 11.5764 14.5291 11.182 14.3443 10.862C14.1595 10.5419 13.8181 10.3447 13.4484 10.3447Z" />
                                                                <path d="M10.6899 15.1719H7.93132C7.56171 15.1719 7.22027 15.3691 7.03543 15.6891C6.85066 16.0091 6.85066 16.4035 7.03543 16.7236C7.22027 17.0436 7.56171 17.2408 7.93132 17.2408H10.6899C11.0595 17.2408 11.4009 17.0436 11.5858 16.7236C11.7705 16.4035 11.7705 16.0091 11.5858 15.6891C11.4009 15.3691 11.0595 15.1719 10.6899 15.1719Z" />
                                                            </svg>
                                                        </div>
                                                        <div>{sortName}</div>
                                                        <div className="drop-arrow"><FiChevronDown /></div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="list-area">
                                                            {this.state.sortList.map(
                                                                (item: any, idx: any) => (
                                                                    <Dropdown.Item
                                                                        eventKey={item.eventKey}
                                                                        key={idx}
                                                                        onClick={(e: any) => this.selectSortingField(item.eventKey)}
                                                                        className={this.state.selectedSort == item.eventKey ? "active" : ""}
                                                                    >
                                                                        <div className='d-flex'>
                                                                            <div><EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip></div>
                                                                            {this.state.selectedSort == item.eventKey && (
                                                                                <div className='active-icon ml-auto'>
                                                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92"></path>
                                                                                    </svg>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                )
                                                            )}
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            {this.state.isViewOtherEHIDToggle.toLowerCase() === "yes" && 
                                                <div className='switch-option mrgn-right-8'>
                                                    <Form.Check
                                                        type="switch"
                                                        id="viewOtherEhid_1"
                                                        label="View Other EHIDs"
                                                        checked={this.state.showOtherEhidData.toLowerCase() === "yes"}
                                                        onChange={(event: any) => { this.viewOtherEhidData(event); }}
                                                    />
                                                </div>
                                            }
                                            <div className='setting-selector'>
                                                <Dropdown className="dropDownSelector">
                                                    <Dropdown.Toggle className='d-flex align-items-center'>
                                                        <div className="selector-icon">
                                                            <svg width="14" height="14" viewBox="0 0 14 14"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                                                            </svg>
                                                        </div>
                                                        <div className="drop-arrow rm-mrgn"><FiChevronDown /></div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="list-area">
                                                            <div className="gearIconHeader"><strong>Density View Control</strong></div>
                                                            {this.state.densityList.map(
                                                                (item: any, idx: any) => (
                                                                    <Dropdown.Item
                                                                        eventKey={item.eventKey}
                                                                        key={idx}
                                                                        onClick={(e: any) => this.handleDensityView(item.eventKey)}
                                                                        className={this.state.selectedDensity == item.eventKey ? "active" : ""}
                                                                    >
                                                                        <div className='d-flex'>
                                                                            <div><EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip></div>
                                                                            {this.state.selectedDensity == item.eventKey && (
                                                                                <div className='active-icon ml-auto'>
                                                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92"></path>
                                                                                    </svg>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                )
                                                            )}
                                                            <div className="m-0 dropdown-divider"></div>
                                                            <Dropdown.Item className="defaultBtn" onClick={() => this.saveDefaultColumn()}>Save as Default View</Dropdown.Item>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className={this.state.selectedDensity == 'narrow' ? "tbl-sec narrow":"tbl-sec"}>
                                            {this.columnSelectorFormatter()}
                                            <div className="scroll-area" ref={this.scrollArea}>
                                                {this.state.isColumnChange &&
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        keyField="id"
                                                        hover
                                                        rowEvents={this.rowEvents}
                                                        rowClasses={rowClasses}
                                                    //noDataIndication={'No activity for the selected time period'}
                                                    />
                                                }
                                                { this.state.isLoadMore &&
                                                    <div className='loadMoreBtn'>
                                                        <Button type="button" className="btn btn-primary" onClick={this.loadMoreRecord}>Load More...</Button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                </Container>
                <Modal className="sales-create-account" show={this.state.ispopupshow} onHide={this.closeCreateAccount} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>New Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-section">
                            <div className="section">
                                <Row>
                                    <Col>
                                        <Form.Group className={errors.accountName.length > 0 ? "error" : ""}>
                                            <Form.Label>Account Name <span className='star'>*</span></Form.Label>
                                            {/* <Form.Control type="text" placeholder="" /> */}
                                            <Form.Control
                                                id="acc-name"
                                                type="text"
                                                name="accountName"
                                                value={accountDetails.accountName}
                                                autoComplete="off"
                                                onChange={(event: any) => this.onInputFieldChange(event, "accName")}
                                                placeholder="Account Name"
                                                maxLength={100}
                                            />
                                            {errors.accountName.length > 0 &&
                                                <span className='error'>{errors.accountName}</span>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group className={errors.lettercode.length > 0 ? "error" : ""}>
                                            <Form.Label>EHID <span className='star'>*</span></Form.Label>
                                            <DropDownSelect
                                                itemList={this.state.salesEhidList}
                                                onDropdownChange={this.onDropdownChange}
                                                type="ehid"
                                                selectedItem={accountDetails.lettercode}
                                                isSearchable={true}
                                                name="lettercode"
                                                placeholder="Select EHID"
                                                >
                                            </DropDownSelect>
                                            {errors.lettercode.length > 0 &&
                                                <span className='error'>{errors.lettercode}</span>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col  xs={6}>
                                        <Form.Group className={errors.statusName.length > 0 ? "error" : ""}>
                                            <Form.Label>Status <span className='star'>*</span></Form.Label>
                                            <DropDownSelect
                                                itemList={this.state.statusList}
                                                onDropdownChange={this.onDropdownChange}
                                                selectedItem={this.state.accountDetails.statusName}
                                                type="status"
                                                name="statusName"
                                                placeholder="Select Status"
                                                >
                                                {errors.statusName.length > 0 &&
                                                    <span className='error'>{errors.statusName}</span>
                                                }
                                            </DropDownSelect>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  xs={6}>
                                        <Form.Group>
                                            <Form.Label>Account Score
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="postingRule">{"The score is a numerical representation of the account's potential value or importance to the hotel."}</Tooltip>}
                                                >
                                                    <span className='info'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M7.3335 11.3333H8.66683V7.33325H7.3335V11.3333ZM8.00016 5.99992C8.18905 5.99992 8.34738 5.93603 8.47516 5.80825C8.60294 5.68047 8.66683 5.52214 8.66683 5.33325C8.66683 5.14436 8.60294 4.98603 8.47516 4.85825C8.34738 4.73047 8.18905 4.66658 8.00016 4.66658C7.81127 4.66658 7.65294 4.73047 7.52516 4.85825C7.39738 4.98603 7.3335 5.14436 7.3335 5.33325C7.3335 5.52214 7.39738 5.68047 7.52516 5.80825C7.65294 5.93603 7.81127 5.99992 8.00016 5.99992ZM8.00016 14.6666C7.07794 14.6666 6.21127 14.4916 5.40016 14.1416C4.58905 13.7916 3.8835 13.3166 3.2835 12.7166C2.6835 12.1166 2.2085 11.411 1.8585 10.5999C1.5085 9.78881 1.3335 8.92214 1.3335 7.99992C1.3335 7.0777 1.5085 6.21103 1.8585 5.39992C2.2085 4.58881 2.6835 3.88325 3.2835 3.28325C3.8835 2.68325 4.58905 2.20825 5.40016 1.85825C6.21127 1.50825 7.07794 1.33325 8.00016 1.33325C8.92238 1.33325 9.78905 1.50825 10.6002 1.85825C11.4113 2.20825 12.1168 2.68325 12.7168 3.28325C13.3168 3.88325 13.7918 4.58881 14.1418 5.39992C14.4918 6.21103 14.6668 7.0777 14.6668 7.99992C14.6668 8.92214 14.4918 9.78881 14.1418 10.5999C13.7918 11.411 13.3168 12.1166 12.7168 12.7166C12.1168 13.3166 11.4113 13.7916 10.6002 14.1416C9.78905 14.4916 8.92238 14.6666 8.00016 14.6666ZM8.00016 13.3333C9.48905 13.3333 10.7502 12.8166 11.7835 11.7833C12.8168 10.7499 13.3335 9.48881 13.3335 7.99992C13.3335 6.51103 12.8168 5.24992 11.7835 4.21659C10.7502 3.18325 9.48905 2.66659 8.00016 2.66659C6.51127 2.66659 5.25016 3.18325 4.21683 4.21659C3.1835 5.24992 2.66683 6.51103 2.66683 7.99992C2.66683 9.48881 3.1835 10.7499 4.21683 11.7833C5.25016 12.8166 6.51127 13.3333 8.00016 13.3333Z" fill="#84888C" />
                                                        </svg>
                                                    </span>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <DropDownSelect
                                                itemList={this.state.accountScoreList}
                                                onDropdownChange={this.onDropdownChange}
                                                type="score"
                                                placeholder="Select Score">
                                            </DropDownSelect>
                                        </Form.Group>
                                    </Col>
                                    <Col  xs={6}>
                                        <Form.Group>
                                            <Form.Label>Owner</Form.Label>
                                            <DropDownSelect
                                                itemList={this.state.ownerList}
                                                onDropdownChange={this.onDropdownChange}
                                                type="owner"
                                                selectedItem={this.state.accountDetails.ownerName}
                                                isSearchable={true}
                                                placeholder={'Select Owner'}>
                                            </DropDownSelect>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  xs={6}>
                                        <Form.Group>
                                            <Form.Label>Source</Form.Label>
                                            <DropDownSelect
                                                itemList={this.state.sourceList}
                                                onDropdownChange={this.onDropdownChange}
                                                type="source"
                                                isSearchable={true}
                                                placeholder={'Select Source'}>
                                            </DropDownSelect>
                                        </Form.Group>
                                    </Col>
                                    <Col  xs={6}>
                                        <Form.Group>
                                            <Form.Label>Market Segment</Form.Label>
                                            <DropDownSelect
                                                itemList={this.state.margetSegmentList}
                                                onDropdownChange={this.onDropdownChange}
                                                type="mktsegment"
                                                isSearchable={true}
                                                placeholder={'Select Market Segment'}>
                                            </DropDownSelect>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  xs={6}>
                                        <Form.Group>
                                            <Form.Label>Website</Form.Label>
                                            {/* <Form.Control type="text" placeholder="" /> */}
                                            <Form.Control
                                                id="website"
                                                type="text"
                                                // name="search"
                                                value={this.state.accountDetails.website}
                                                autoComplete="off"
                                                onChange={(event: any) => this.onInputFieldChange(event, "website")}
                                                placeholder="Website"
                                                maxLength={75}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col  xs={6}>
                                        <Form.Group>
                                            <Form.Label>Tax ID</Form.Label>
                                            {/* <Form.Control type="text" placeholder="" /> */}
                                            <Form.Control
                                                id="taxid"
                                                type="text"
                                                // name="search"
                                                value={this.state.accountDetails.taxId}
                                                autoComplete="off"
                                                onChange={(event: any) => this.onInputFieldChange(event, "taxId")}
                                                placeholder="Tax ID"
                                                maxLength={50}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  xs={6}>
                                        <Form.Group>
                                            <Form.Label>IATA</Form.Label>
                                            {/* <Form.Control type="text" placeholder="" /> */}
                                            <Form.Control
                                                id="iata"
                                                type="text"
                                                // name="search"
                                                value={this.state.accountDetails.iata}
                                                autoComplete="off"
                                                onChange={(event: any) => this.onInputFieldChange(event, "iata")}
                                                placeholder="IATA"
                                                maxLength={50}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col  xs={6}>
                                        <Form.Group>
                                            <Form.Label>Target</Form.Label>
                                            <Form.Check
                                                type="switch"
                                                id="targetAccount_1"
                                                label=""
                                                checked={this.state.accountDetails.targetAccount === "yes"}
                                                onChange={(event: any) => { this.onTargetAccountChange(event); }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="section toggle">
                                <Accordion className={errors.address != "" ? "error" : ""}>
                                    {/* Start Address section */}
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle
                                                as={Button}
                                                className={this.state.addressToggle ? "activeToggle" : ""}
                                                onClick={(e) => {this.toggleClass("address")}}
                                                variant="link"
                                                eventKey="0"
                                            >
                                                <div>Address</div>
                                                <div className="ml-auto">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6.29297 10.707L12 16.414L17.707 10.707L16.293 9.293L12 13.586L7.70697 9.293L6.29297 10.707Z"
                                                            fill="#84888C"
                                                        />
                                                    </svg>
                                                </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <Form.Label>Country</Form.Label>
                                                            <DropDownSelect
                                                                itemList={this.state.countriesList}
                                                                onDropdownChange={this.onDropdownChange}
                                                                type="country"
                                                                selectedItem={this.state.accountAddressDetails.country}
                                                                // isSearchable={true}
                                                                placeholder={'Select Country'}>
                                                            </DropDownSelect>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className={(errors.address.length > 0 && this.state.accountAddressDetails.street?.trim().length === 0) ? "error" : ""}>
                                                            <Form.Label>Street {this.state.isAddressMandatory && <span className='star'>*</span>}</Form.Label>
                                                            {/* <Form.Control type="text" placeholder="" /> */}
                                                            <Form.Control
                                                                id="street-name"
                                                                type="text"
                                                                // name="search"
                                                                value={this.state.accountAddressDetails.street}
                                                                autoComplete="off"
                                                                onChange={(event: any) => this.onInputFieldChange(event, "street")}
                                                                placeholder="Street"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={6}>
                                                        <Form.Group className={(errors.address.length > 0 && this.state.accountAddressDetails.city?.trim().length === 0) ? "error" : ""}>
                                                            <Form.Label>City {this.state.isAddressMandatory && <span className='star'>*</span>}</Form.Label>
                                                            {/* <Form.Control type="text" placeholder="" /> */}
                                                            <Form.Control
                                                                id="city-name"
                                                                type="text"
                                                                // name="search"
                                                                value={this.state.accountAddressDetails.city}
                                                                autoComplete="off"
                                                                onChange={(event: any) => this.onInputFieldChange(event, "city")}
                                                                placeholder="City"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Group className={(errors.address.length > 0 && this.state.accountAddressDetails.state.length === 0) ? "error" : ""}>
                                                            <Form.Label>State {this.state.isAddressMandatory && <span className='star'>*</span>}</Form.Label>
                                                            <DropDownSelect
                                                                itemList={this.state.statesList}
                                                                onDropdownChange={this.onDropdownChange}
                                                                type="states"
                                                                selectedItem={this.state.accountAddressDetails.state}
                                                                isSearchable={true} placeholder={'Select State'}>
                                                            </DropDownSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Group className={(errors.address.length > 0 && this.state.accountAddressDetails.zipcode?.trim().length === 0) || errors.zipCode.length > 0  ? "error" : ""}>
                                                            <Form.Label>Zip code/Postal {this.state.isAddressMandatory && <span className='star'>*</span>}</Form.Label>
                                                            {/* <Form.Control type="text" placeholder="" /> */}
                                                            <Form.Control
                                                                id="zip-code"
                                                                type="text"
                                                                // name="search"
                                                                value={this.state.accountAddressDetails.zipcode}
                                                                autoComplete="off"
                                                                onChange={(event: any) => this.onInputFieldChange(event, "zip")}
                                                                onBlur={(e) => this.validateZip(e)}
                                                                placeholder="Zip code/Postal"
                                                                maxLength={10}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {(errors.zipCode !== "" || errors.address !== "") &&
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className={"error"}>
                                                                <div className='error'>{errors.zipCode}</div>
                                                                <div className='error'>{errors.address}</div>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* End Address section */}

                                </Accordion>
                                <Accordion className={errors.contact != "" ? "error" : ""}>
                                    {/* Start Contact section */}
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle
                                                as={Button}
                                                className={this.state.contactToggle ? "activeToggle" : ""}
                                                onClick={(e) => {this.toggleClass("contact")}}
                                                variant="link"
                                                eventKey="0"
                                            >
                                                <div>Contact</div>
                                                <div className="ml-auto">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6.29297 10.707L12 16.414L17.707 10.707L16.293 9.293L12 13.586L7.70697 9.293L6.29297 10.707Z"
                                                            fill="#84888C"
                                                        />
                                                    </svg>
                                                </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className={(errors.contact.length > 0 && this.state.accountContactDetails.name?.trim().length === 0) ? "error" : ""}>
                                                            <Form.Label>Name {this.state.isContactMandatory && <span className='star'>*</span>}</Form.Label>
                                                            {/* <Form.Control type="text" placeholder="" /> */}
                                                            <Form.Control
                                                                id="contact-name"
                                                                type="text"
                                                                // name="search"
                                                                value={this.state.accountContactDetails.name}
                                                                autoComplete="off"
                                                                onChange={(event: any) => this.onInputFieldChange(event, "contactName")}
                                                                placeholder="Name"
                                                                maxLength={50}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col  xs={6}>
                                                        <Form.Group className={(errors.contact.length > 0 && this.state.accountContactDetails.email?.trim().length === 0) || errors.email.length > 0 ? "error" : ""}>
                                                            <Form.Label>Email {this.state.isContactMandatory && <span className='star'>*</span>}</Form.Label>
                                                            {/* <Form.Control type="text" placeholder="" /> */}
                                                            <Form.Control
                                                                id="contact-email"
                                                                type="text"
                                                                // name="search"
                                                                value={this.state.accountContactDetails.email}
                                                                autoComplete="off"
                                                                onChange={(event: any) => this.onInputFieldChange(event, "contactEmail")}
                                                                onBlur={(e) => this.validateEmail(e)}
                                                                placeholder="Email"
                                                            />
                                                            {errors.email.length > 0 &&
                                                                <span className='error'>{errors.email}</span>
                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                    <Col  xs={6}>
                                                        <Form.Group className={errors.phone.length > 0 ? "error" : ""}>
                                                            <Form.Label>Phone</Form.Label>
                                                            {/* <Form.Control type="text" placeholder="" /> */}
                                                            <Form.Control
                                                                id="contact-phone"
                                                                type="text"
                                                                // name="search"
                                                                value={this.state.accountContactDetails.phone}
                                                                autoComplete="off"
                                                                onChange={(event: any) => this.onInputFieldChange(event, "contactPhone")}
                                                                onBlur={(e) => this.validatePhone(e)}
                                                                placeholder="Phone"
                                                            />
                                                            {errors.phone.length > 0 &&
                                                                <span className='error'>{errors.phone}</span>
                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Title</Form.Label>
                                                            {/* <Form.Control type="text" placeholder="" /> */}
                                                            <Form.Control
                                                                id="contact-title"
                                                                type="text"
                                                                // name="search"
                                                                value={this.state.accountContactDetails.title}
                                                                autoComplete="off"
                                                                onChange={(event: any) => this.onInputFieldChange(event, "contactTitle")}
                                                                placeholder="Title"
                                                                maxLength={50}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {errors.contact !== "" &&
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className={"error"}>
                                                                <span className='error'>{errors.contact}</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* End Contact section */}
                                </Accordion>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" className="btn btn-outline-primary"
                            disabled={this.state.isSaveDisabled}
                            onClick={() => this.onSaveCreateAccnt("exit")}>
                            Save and Exit
                        </Button>
                        <Button type="button" className="btn"
                            disabled={this.state.isSaveDisabled}
                            onClick={() => this.onSaveCreateAccnt("continue")}>
                            Save and Continue
                        </Button>
                    </Modal.Footer>
                </Modal>
                {this.state.loader && (
                    <div className="loader-spinner d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="success" />
                    </div>
                )
                }
            </div >
        );
    }
}