import * as React from "react";
import { Modal } from "react-bootstrap";
import { DwollaService } from "../../../Common/Services/DwollaService";
import { Dwollajs } from "../../../Common/ThirdPartyServices/Dwolla";
import { API_ROOT } from "../../../Common/Services/Api-config";
export class DigitalACHCustomDwolla extends React.Component<any, any> {
  state: any = {
    showDwollaModal: true,
  };
  componentDidMount() {
    Dwollajs.IntegrateDwolla(
      this.props?.hotelId,
      this.props?.documentType,
      this.props?.statusCallBack
    );

    // const newWindowObject = window as any;
    // newWindowObject.dwolla.configure({
    //   environment: API_ROOT.dwollaConfigure,

    //   //styles: "https://inn-flow-test-v1-temp.azurewebsites.net/styles/Dwollaallcss.css",
    //   styles: "Dwollaallcss1.css",
    //   //tokenUrl: "../Administration/DwollaHandler.ashx",
    //   tokenUrl: API_ROOT.backendHost + "/DwollaWebhook/DwollaTokenURL",
    //   success: (res) => {
    //     console.log(res);

    //     res.hid = this.props?.hotelId;
    //     if (res.response.status == "certified") {
    //       res.custUrl = res.response._links.self.href;
    //       res.doctype = this.props?.documentType;
    //       DwollaService.SaveDwollaCustomer(res)
    //         .then((data) => this.props.statusCallBack(data, "certified"))
    //         .catch((err) => this.props.statusCallBack(err, "error"));
    //     } else {
    //       if (res.response.location) {
    //         res.custUrl = res.response.location;
    //         res.doctype = this.props?.documentType;
    //         DwollaService.SaveDwollaCustomer(res)
    //           .then((data) => this.props.statusCallBack(data, res.resource))
    //           .catch((err) => this.props.statusCallBack(err, "error"));
    //       }
    //     }
    //   },
    //   error: (err) => this.props.statusCallBack(err, "error"),
    // });
  }

  render() {
    const somediv = this.props.ModalName;

    return (
      <div id="digitalACH">
        {React.createElement(somediv, {
          customerid: this.props?.custId || "",
          terms: "https://www.inn-flow.com/terms-of-service/",
          privacy: "https://www.inn-flow.com/privacy-policy/",
        })}
      </div>
    );
  }
}
