import React from "react";
import { Spinner, Dropdown, Modal, Button, Form } from "react-bootstrap";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import {
  GetLaborScheduleTemplateRequest,
  IUpdateDeleteScheduleTemplateRequest,
} from "../../../../../Common/Contracts/ILaborSchedule";
import { LaborSchedule } from "../../../../../Common/Services/LaborSchedule";
import { Utils } from "../../../../../Common/Utilis";

export class ManageTemplateModal extends React.Component<any, any> {
  private renameInputRef: any;
  constructor(props: any) {
    super(props);
    this.renameInputRef = React.createRef();
    this.state = {
      deleteButtonSpinner: false,
      renameButtonSpinner: false,
      allTemplatesList: [],
      selectedTemplate: {},
      renameSection: false,
      newTemplateName: "",
    };
  }

  componentDidMount() {
    this.getTemplateList();
  }

  getTemplateList = () => {
    let requestObject = {} as GetLaborScheduleTemplateRequest;

    requestObject.hotelID = this.props.parentState.hidValue;

    LaborSchedule.GetLaborScheduleTemplates(requestObject)
      .then(async (result: any) => {
        if (result.message === "Success") {
          this.setState({ allTemplatesList: result.result });
        } else {
          Utils.toastError(`${result.message}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
      });
  };

  manageTemplateModalHide = () => {
    this.props.manageTemplateModalHide();
  };

  templateItemClick = (item: string) => {
    this.setState({ renameSection: false, selectedTemplate: item });
  };

  renameSectionShow = () => {
    if (Object.keys(this.state.selectedTemplate).length === 0) {
      Utils.toastError("Please select a template to rename", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
    } else {
      this.setState({ renameSection: true }, () => {
        this.renameInputRef?.current?.focus();
      });
    }
  };

  renameTemplateChange = (e: any) => {
    let value = e.target.value;

    if (/^[A-Za-z0-9\-'&_() ]+$/.test(value) === false) {
      e.target.value = value.replace(/[^A-Za-z0-9\-'&_() ]/g, "");
    }
    if (value.length > 50) {
      let a = value.replace(/[^A-Za-z0-9\-'&_() ]/g, "");
      e.target.value = a.substring(0, value.length - 1);
      
    }

    this.setState({ newTemplateName: value });
  };

  handleRename = () => {
    if (Object.keys(this.state.selectedTemplate).length === 0) {
      Utils.toastError("Please select a template to rename", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
    } else if (this.state.newTemplateName === "") {
      Utils.toastError("New Template name can not be blank", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
    } else {
      this.setState({ renameButtonSpinner: true });

      let requestObject = {} as IUpdateDeleteScheduleTemplateRequest;
      requestObject.Hid = this.props.parentState.hidValue;
      requestObject.TemplateName = this.state.selectedTemplate?.templateName;
      requestObject.EditTemplateName = this.state.newTemplateName;
      requestObject.date = this.props.parentState.navigationCalenderDates[0];
      requestObject.Type = "RenameTemplate";
      LaborSchedule.UpdateDeleteScheduleTemplate(requestObject)
        .then(async (result: any) => {
          let apiResult = result.result[0];

          if (apiResult.success === "Success") {
            this.manageTemplateModalHide();
            toast.success("Template Renamed Successfully", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "scheduleMain",
            });
          } else {
            Utils.toastError(`${apiResult.message}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "scheduleMain",
            });
          }

          this.setState({ renameButtonSpinner: false });
          resolve();
        })
        .catch((err) => {
          this.setState({ renameButtonSpinner: false });
          Utils.toastError(`Server Error, ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
          reject();
        });
    }
  };

  handleDelete = () => {
    if (Object.keys(this.state.selectedTemplate).length === 0) {
      Utils.toastError("Please select a template to delete", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "scheduleMain",
      });
    } else {
      this.setState({ deleteButtonSpinner: true });

      let requestObject = {} as IUpdateDeleteScheduleTemplateRequest;
      requestObject.Hid = this.props.parentState.hidValue;
      requestObject.TemplateName = this.state.selectedTemplate?.templateName;
      requestObject.EditTemplateName = "";
      requestObject.date = this.props.parentState.navigationCalenderDates[0];
      requestObject.Type = "DeleteTemplate";
      LaborSchedule.UpdateDeleteScheduleTemplate(requestObject)
        .then(async (result: any) => {
          let apiResult = result.result[0];

          if (apiResult.success === "Success") {
            this.manageTemplateModalHide();
            toast.success("Template Deleted Successfully", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "scheduleMain",
            });
          } else {
            Utils.toastError(`${apiResult.message}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "scheduleMain",
            });
          }
          this.setState({ deleteButtonSpinner: false });
          resolve();
        })
        .catch((err) => {
          this.setState({ deleteButtonSpinner: false });
          Utils.toastError(`Server Error, ${err}`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
          reject();
        });
    }
  };

  render() {
    return (
      <div>
        <Modal
          className="schedule-manage-template-modal"
          show={this.props.parentState.manageTemplateModal}
          onHide={this.manageTemplateModalHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Manage Templates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="template-name-text">Template Name</div>

            <div className="template-list">
              <Dropdown className="template-list-dropdown">
                <Dropdown.Toggle id="dropdown-importFrom">
                  <span
                    className={
                      this.state.selectedTemplate === ""
                        ? "placeholder"
                        : "selected"
                    }
                  >
                    {Object.keys(this.state.selectedTemplate).length === 0
                      ? "Select Template"
                      : this.state.selectedTemplate?.templateName}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.allTemplatesList.map((item: any, idx: any) => (
                    <Dropdown.Item
                      key={idx}
                      eventKey={item.id}
                      onClick={() => this.templateItemClick(item)}
                    >
                      {item?.templateName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {Object.keys(this.state.selectedTemplate).length !== 0 &&
              this.state.renameSection && (
                <div className="rename-template-section">
                  <div className="rename-title">
                    Please select new Template Name for:
                    <span className="rename-bold">
                      {this.state.selectedTemplate?.templateName}
                    </span>
                  </div>

                  <div className="rename-input">
                    <Form.Group className="daily-activity-input">
                      <Form.Control
                        ref={this.renameInputRef}
                        placeholder="Enter New Template Name"
                        value={this.state.newTemplateName}
                        maxLength={49}
                        onChange={(e: any) => {
                          this.renameTemplateChange(e);
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
              )}
          </Modal.Body>
          <Modal.Footer>
            <div className="footer-wrapper">
              <Button
                className={
                  this.state.renameButtonSpinner
                    ? "rename-template template-modal-btn p-none"
                    : "rename-template template-modal-btn"
                }
                onClick={
                  this.state.renameSection
                    ? () => this.handleRename()
                    : () => this.renameSectionShow()
                }
              >
                {this.state.renameButtonSpinner && (
                  <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {this.state.renameSection ? "Rename Template" : "Show Rename"}
              </Button>

              <Button
                className={
                  this.state.deleteButtonSpinner
                    ? "delete-template template-modal-btn p-none"
                    : "delete-template template-modal-btn"
                }
                onClick={() => this.handleDelete()}
              >
                {this.state.deleteButtonSpinner && (
                  <Spinner
                    className="mr-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
