import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import { RoomAssignmentsDetails } from "../RoomAssignmnetOverview/RoomAssignmentsDetails";
// import PageLoader from "../../../../Common/Components/PageLoader";
import chevronRight from "../../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { resolve, reject } from "q";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { toast } from "react-toastify";
import { LaborBudgetSetting } from "../../../../../Common/Services/LaborBudgetSetting";
import { Utils } from "../../../../../Common/Utilis";
export class RoomAssignments extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      hidValue: props.parentState.hidValue,
      hotelLetterCode: props.parentState.hotelName,
      isLetterCode: props.parentState.isLetterCode,
      isLetterId: props.parentState.isLetterId,
      date:
        JSON.parse(localStorage.storage).currentUtcTime !== ""
          ? new Date(JSON.parse(localStorage.storage).currentUtcTime)
          : new Date(),
      tableData: [],
      loaderTrue: false,
      expendedRow: {},
      tableExpended: [],
      columnDetails: [],
    };
  }

  refreshBindApproveData() {
    this.props.refreshBindApproveData();
  }

  getHouseKeepingRoomOccupied(request: any) {
    this.setState({ loaderTrue: true });
    LaborDayApprove.LaborServiceList(request)
      .then(async (result: any[] | null) => {
        if (result != null) {
          if (result.length > 0) {
            this.setState({ columnDetails: result });
            this.handleOnExpand(this.state.expendedRow, true, "", "");
          }
        }
        // this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
    this.setState({ loaderTrue: true });
    LaborDayApprove.GetHouseKeepingRoomOccupied(request)
      .then(async (result: any[] | null) => {
        let data = [];
        if (result != null) {
          if (result.length > 0) {
            data = Object.assign([], JSON.parse(JSON.stringify(result)));
            // eslint-disable-next-line array-callback-return
            data.map((item: any) => {
              item.occupiedRoom = item.roomTotal;
              item.roomOccupiedChange = false;
              item.isActualEntry = "No";
              if (item.type.toLowerCase() === "actual") {
                item.isActualEntry = "Yes";
              } else {
                item.occupiedRoom = item.roomTotal === 0 ? "" : item.roomTotal;
              }
            });
            this.setState({
              tableData: data,
              allData: data,
              loaderTrue: false,
            });
            this.setState({ date: result[0].date });
          }
        }

        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
    if (isExpand) {
      row.action = "Expended";
      this.setState({ isExpand: true });
      this.setState(() => ({
        tableExpended: [...this.state.tableExpended, row.rowNo],
      }));
      this.setState({ expendedRow: row });
    } else {
      row.action = "";
      this.setState({ isExpand: false });
      this.setState(() => ({
        tableExpended: this.state.tableExpended.filter(
          (x: any) => x !== row.rowNo
        ),
      }));
    }
  };

  allHotelBreadCrumb = (e: any) => {
    this.props.roomAssignmentBreadCrumbs("allHotels");
  };

  changeOccupiedRoows(e: any, row: any, rowIndex: any) {
    let val = e.target.value;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );

    if (/^\d+$/.test(val) === false) {
      val = val.replace(/[^\d]/g, "");
    }
    if (val.length > 3) {
      let a = val.replace(/[^\d]/g, "");
      val = a.substring(0, val.length - 1);
    }
    data[rowIndex].occupiedRoom = val;
    data[rowIndex].roomOccupiedChange = true;
    this.setState(
      {
        tableData: data,
      },
      () => {
        // $("#housekeeping")
        //   .find(`input[id^='occupiedRooms-${rowIndex}']`)
        //   .focus();
      }
    );
  }

  saveBudgetCalculation = (date: any, hidValue: any) => {
    let request: any = {};
    let currentDate: any = null;
    currentDate = date;
    request.hotelID = hidValue;
    request.layout = "2";
    request.date = currentDate;
    request.type = "Housekeeping";
    LaborBudgetSetting.SaveBudgetCalculation(request)
      .then(async (result: any | null) => {})
      .catch((err: any) => {});
  };

  saveHousekeepingManualOccupiedRooms = (e: any, row: any, rowIndex: any) => {
    let oldData = this.state.allData;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableData))
    );
    if (!row.roomOccupiedChange) {
      return;
    }
    if (row.occupiedRoom === "") {
      Utils.toastError(
        "Please enter rooms occupied. Rooms Occupied can't be blank."
      );
      data[rowIndex].occupiedRoom = oldData[rowIndex].occupiedRoom;
      data[rowIndex].roomOccupiedChange = false;
      this.setState({
        tableData: data,
      });
      return;
    }
    let nullOccupiedRoom = data.filter((item) => item.occupiedRoom === null);
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let requestArray: any = [];
    let request: any = {};
    request.Hid = this.state.isLetterId;
    request.Tenantid = tenantID;
    request.Date = row.actualdate;
    request.RoomTotal = row.occupiedRoom;
    request.type = "";
    requestArray.push(request);

    LaborDayApprove.saveHousekeepingManualOccupiedRooms(requestArray)
      .then(async (result: any | null) => {
        if (result != null) {
          // debugger;
          if (result.message === "Success") {
            toast.success("Total occupied rooms saved successfully.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.saveBudgetCalculation(row.actualdate, this.state.isLetterId);
          }
          // let data = Object.assign(
          //   [],
          //   JSON.parse(JSON.stringify(this.state.tableData))
          //   );
          // data[rowIndex].occupiedRoom = e.target.value;
          // let showBanner = false;
          // showBanner = nullOccupiedRoom.length > 0
          data[rowIndex].roomOccupiedChange = false;
          this.setState({
            // noDailyActivity:showBanner,
            tableData: data,
            allData: data,
            tableExpended: [],
            isExpand: false,
          });
          // this.selectHotelDropDown({hotelID:this.state.hidValue,lettercode:this.state.hotelName})
        }
      })
      .catch((error) => {
        Utils.toastError(`Server Error, ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
        // this.setState({ loaderTrue: false });
      });
  };

  render() {
    const rowClasses = (row: { action: string }, rowIndex: any) => {
      if (this.state.isExpand === true && row.action === "Expended") {
        return "row-Expended";
      } else if (this.state.isExpand === true) {
        return "row-Expended";
      } else {
        return "row-Action";
      }
    };

    const expandRow = {
      renderer: (row: any) => (
        <div className="rowExpended" id="laborDayDetails">
          <RoomAssignmentsDetails
            hidValue={row.hid}
            columnDetails={this.state.columnDetails}
            date={row.date}
            refreshBindApproveData={this.refreshBindApproveData.bind(this)}
            row={row}
          />
        </div>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      expandByColumnOnly: false,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const columns = [
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="overBudgetDeptsData">
            <span className="overBudgetDeptsCount">{row.day}</span>
            <span className="overBudgetDeptsLabel">{row.date}</span>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatExtraData: { otherRowExpanede: this.state.tableExpended },
        formatter: (cell: any, row: any, rowIndex: any, extraData) => {
          let readOnly = false;
          if (
            extraData.otherRowExpanede.length > 0 &&
            extraData.otherRowExpanede[0] !== row.id
          ) {
            readOnly = true;
          }
          const stopPropagation = (e: { stopPropagation: () => any }) =>
            e.stopPropagation();
          if (row.isActualEntry !== "Yes") {
            return (
              <div className="main-ttl" onClick={stopPropagation}>
                <input
                  id={`occupiedRooms-${rowIndex}`}
                  type="text"
                  readOnly={readOnly}
                  className="form-control"
                  style={{ fontFamily: "OpenSans" }}
                  value={row.occupiedRoom}
                  onChange={(e) => this.changeOccupiedRoows(e, row, rowIndex)}
                  onBlur={(e) => {
                    this.saveHousekeepingManualOccupiedRooms(e, row, rowIndex);
                  }}
                  autoComplete="off"
                />
                <span
                  className="overBudgetDeptsLabel"
                  style={{
                    fontFamily: "opensans",
                    color: "#6a6e73",
                    fontSize: "12px",
                  }}
                >
                  Rooms Occupied
                </span>
              </div>
            );
          } else {
            return (
              <div className="overBudgetDeptsData">
                <span className="overBudgetDeptsCount">
                  {row.roomTotal}
                  {/* <div className={row.type === "Forecasted" ? "red-badges orange-bg" : "red-badges blue-bg"}>{row.type}</div> */}
                </span>
                <span className="overBudgetDeptsLabel">Rooms Occupied</span>
              </div>
            );
          }
        },
      },
      {
        dataField: "",
        text: "",
        editable: false,
      },
    ];

    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"roomsAssignList"} />
        ) : (
          <React.Fragment>
            <div className="smileIdOverview">
              <div className="smileIDOverviewActionHeader">
                {this.state.hidValue === "All" && (
                  <span className="smileIDBreadCrumbs">
                    <span onClick={this.allHotelBreadCrumb}>
                      {" "}
                      &laquo; All Properties{" "}
                    </span>
                  </span>
                )}
              </div>
            </div>
            <div className="laborDayApproval roomassignments">
              <div className="laborDayApprovalActionHeader">
                <span className="laborHeader">
                  {this.state.hidValue === "All" ? this.state.isLetterCode : ""}{" "}
                  Room Assignments
                </span>
              </div>
              <div className="laborDayApprovalTable">
                <BootstrapTable
                  keyField="rowNo"
                  data={this.state.tableData}
                  columns={columns}
                  expandRow={expandRow}
                  rowClasses={rowClasses}
                  hover
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </>
    );
  }
}
