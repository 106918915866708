import { Hub } from "./Hub";
import { HubNames } from "../Constants/HubNames";
import { Slide, toast } from "react-toastify";
import EventEmitter from "reactjs-eventemitter";
import { HubEndPointNames } from "../Constants/HubEndPointNames";
export class UserManagementHub {
  public static hubConnection;

  static connect() {
    const hubConnection = Hub.connect(HubNames.USER_MANGEMENT);
    this.hubConnection = hubConnection;
    return hubConnection;
  }

  static connectAndOperateHub = () => {
    const hubConnection = UserManagementHub.connect();
    hubConnection
      .start()
      .then(() => {
        console.log("Connection started!");
        hubConnection.on(
          HubEndPointNames.UserManagementChannelNotification,
          UserManagementHub.recieveUserManagementChannelNotificationData
        );
      })
      .catch((err) => console.log("Error while establishing connection :("));
  };

  private static recieveUserManagementChannelNotificationData = (response) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantId = storage === null ? 0 : (storage.tenantID as any);
    if (
      Number(response?.tenantId) === Number(tenantId) ||
      Number(response?.tenantId) <= 0
    ) {
      // toast.info(response.data, {
      //   transition: Slide,
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      EventEmitter.emit(response.action, response);
    }
  };
}
