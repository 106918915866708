import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import moment from "moment";
import { IUserPositionRegularRate } from "../../../Common/Contracts/IUserPositionRegularRate";

export class AddSpecialRate extends React.Component<any, any> {
  private specialRateFormSchema = Joi.object({
    uniqueno: Joi.number().min(-1),
    position: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Position" };
        });
      }),
      regularRate: Joi.number()
      .min(0)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          debugger;
          if (error?.message) {
            return;
          }
          switch (error?.type) {
            case "any.required":
              return {
                message:
                  "Please enter Special Rate",
              };
            case "number.base":
              return {
                message:
                  "Please enter Special Rate",
              };
            case "number.min":
              return {
                message:
                  "Special Rate value sshould not be less than 0",
              };
          }
        });
        return [_errors[0]];
      }),
    wefDate: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Effective Date" };
        });
      }),
  });

  state: any = {
    specialRateFormData: {
      uniqueno: this.props?.specialRateRowEdit?.uniqueno || -1,
      position: this.props?.specialRateRowEdit?.positionId || 0,
      regularRate: this.props?.specialRateRowEdit?.regularRate ,
      wefDate: this.props?.specialRateRowEdit?.wefDate || "",
    },
    calenderShow: false,
    positionList: this.props?.positionList,
    positionListHid: this.props?.positionList,
    deductionFilters: [],
    deductionName: "",
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    hotelsLoaded: false,
  };

  componentDidMount = () => {
    this.getHotels();
    this.getAllDeductionCriteria();
    //this.getAllPositionlist();
   this.SelectedHidPostion(this.props?.hid); 
     
  };

  SelectedHidPostion=(hid : any)=>{
    
    let pos=[...this.state.positionListHid];
   let  filterPostion= pos.filter(x => Number(x.hid)===hid);
   this.setState({positionList:[]},()=>{
     this.setState({positionList:filterPostion},()=>{
      this.getAllPositionlist();
     });
   });
  }

  getAllPositionlist = () => {

    let positionIds =  this.props?.speacialRates?.map((x) => +x.positionId) || [];
    positionIds.push(+this.props?.rowPositionId);
    if(this.props?.specialRateRowEdit?.positionId>0) {
      positionIds = positionIds.filter(x=> +x!==this.props?.specialRateRowEdit?.positionId)
    }

  let positionList = [...this.state?.positionList];
      positionList =positionList.map(x=> {
        x.empCode = +x.empCode;
        return x;
      })?.filter(x=> !positionIds?.includes(x?.empCode)  && +x.hid=== +this.props?.hid );
      this.setState({ positionList: [] }, ()=> this.setState({positionList}));
  };

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };
  componentWillUnmount=()=> {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }
  calenderShow = (): any => {
    this.setState({ calenderShow: true });
  };

  getSpecialUniqueNo = () => {
    const uniqueNos = this.props?.speacialRates?.map((x) => x.specialUniqueno) || [];
    let no = 0;
    for (let i = 1; i <= 5; i++) {
      if (!uniqueNos.includes(i)) {
        no = i;
        break;
      }
    }
    return no;
  };

  saveSpecialRates = () => {
    const request: IUserPositionRegularRate = {
      ...this.state.specialRateFormData,
    } as IUserPositionRegularRate;
    request.userUniqueno = this.props?.userUniqueNo;
    request.hid = this.props?.hid;
    request.specialUniqueno = this.props?.specialRateRowEdit?.uniqueno > 0? this.props?.specialRateRowEdit?.specialUniqueno: this.getSpecialUniqueNo();
    this.setState({ isDataSaving: true });
    UserManagement.SaveSpecialRates(request)
      .then((result) => {
        this.setState({ isDataSaving: false });
        if (result?.message === "Success") {
          toast.success(
            this.props?.specialRateRowEdit?.uniqueno > 0
              ? "Special Rate Updated Successfully"
              : "Special Rate Saved Successfully",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addDeduction",
            }
          );
          this.hideModal(true);
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDeduction",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDeduction",
        });
      });
  };

  onSearchChange = (value) => {
    const deductionFilters = this.state?.deductions?.filter((x) =>
      x?.deductionName?.toString()?.toLowerCase()?.includes(value)
    );
    this.setState({ deductionFilters });
  };

  filterBy = () => true;

  onFieldChange = (event, inputPosition) => {
    const { specialRateFormData } = this.state;
    let value = event.target.value;
    
    const fieldName = event.target.name;
    if (
      fieldName === "regularRate" &&
      value?.toString()?.split(".")[0]?.length > 4
    ) {
      return;
    }
   
    if (
      fieldName === "regularRate" &&
      value?.toString()?.split(".")[1]?.length > 2
    ) {
      specialRateFormData[fieldName] = +value>=0?  value?.toFixed(2) : value;
      if(inputPosition> this.state.inputPosition) {
        this.setState({ inputPosition });
      }
     
      this.setState({ specialRateFormData }, () =>
        this.validateDeductionFormSchema()
      );
      return;
    }
   
    specialRateFormData[fieldName] = value;
    if(inputPosition> this.state.inputPosition) {
      this.setState({inputPosition});
    }
    this.setState({ specialRateFormData }, () =>
      this.validateDeductionFormSchema()
    );
  };

  validateDeductionFormSchema = () => {
    const valid = Joi.validate(
      this.state.specialRateFormData,
      this.specialRateFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  validationOnClick = (inputPosition) => {
    if(inputPosition> this.state.inputPosition) {
      this.setState({ inputPosition }, () => this.validateDeductionFormSchema());
    }
   
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };

  hideModal = (isReload = false) => {
    const specialRateFormData = {
      uniqueno: this.props?.specialRateRowEdit?.uniqueno || -1,
      position: this.props?.specialRateRowEdit?.positionId || 0,
      regularRate: this.props?.specialRateRowEdit?.regularRate || 0,
      wefDate: this.props?.specialRateRowEdit?.wefDate || "",
    };
    this.props?.hideSpecialRateAddEditModalForm(isReload);
    this.setState({ specialRateFormData, isFormNotValid: true });
  };

  getAllDeductionCriteria = (isDefaultLoad = false) => {
    this.setState({ isEnterpriseWideDataLoaded: false });
    UserManagement.GetAllDeductionCriteria()
      .then((deductions: any) => {
        if (deductions?.length > 0) {
          deductions = deductions
            .map((x) => {
              x.deductionName = `${x.deductionName}(${x.taxType}-${x.exempt}-${x.deductionType})`;
              return x;
            })
            .sort((a, b) => a.deductionName - b.deductionName);
        }
        this.setState({ deductions });
      })
      .finally(() => this.setState({ isEnterpriseWideDataLoaded: true }));
  };

  getHotels = () => {
    this.setState({ hotelList: this.props?.userHotels });
  };

  //    filterBy = () => {
  //     if(!props?.defaultValue && selectedValue?.length > 0) {
  //     typeahead?.current?.clear()
  //  }

  render() {
    const {
      specialRateFormData: {  position, regularRate, wefDate },
      positionList,
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,
    } = this.state;
    const { showSpecialRateAddEditModal } = this.props;
    return (
      <>
        <div className="">
        
          <Modal
            className="add-special-rate-modal"
            show={showSpecialRateAddEditModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={this.props?.showSpecialRateAddEditModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props?.specialRateRowEdit?.uniqueno > 0
                  ? "Edit Special Rate"
                  : "Add Special Rate"}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Form.Group controlId="taxType" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.position && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      onClick={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Position</Form.Label>
                      {positionList?.length > 0 && (
                        <DropDownList
                          placeHolder={"Search Position"}
                          data={positionList}
                          defaultValue={position}
                          isSearchRequired={true}
                          label={"empTitle"}
                          value={"empCode"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "position",
                                item.empCode
                              ),
                              1
                            );
                          }}
                          selectedItem={[
                            ...[{ empCode: 0, empTitle: "Select Position" }],
                            ...positionList,
                          ].find((r: any) => r?.empCode === position)}
                        />
                      )}
                      {this.getFieldError("position", 1)}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="taxType" className="d-flex">
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.regularRate && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                      onClick={() => this.validationOnClick(2)}
                    >
                      <Form.Label>Rate</Form.Label>
                      <input
                        type="text"
                        onKeyDown= {Utils.AllowOnlyPositiveNumnberInput}
                  
                        className="form-control"
                        id="txtRegularRate"
                        name="regularRate"
                        autoComplete="txtRegularRate"
                        value={regularRate}
                        placeholder="0.00"
                        onChange={(e) => this.onFieldChange(e, 2)}
                      />
                      {this.getFieldError("regularRate", 2)}
                    </Col>

                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.wefDate && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                      onClick={() => this.validationOnClick(3)}
                    >
                      <Form.Label>Effective Date</Form.Label>
                      <DropDownList
                          placeHolder={"Search Date"}
                          data={this.props?.dateList}
                          defaultValue={wefDate ? moment(wefDate)?.format("MM/DD/YY") : wefDate}
                          isSearchRequired={false}
                          label={"label"}
                          value={"value"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "wefDate",
                                item.value
                              ),
                              3
                            );
                          }}
                          selectedItem={[
                            ...[{ value: '', label: "Select Date" }],
                            ...this.props?.dateList
                          ].find((r) => r?.value === wefDate)}
                        />


                      {this.getFieldError("wefDate", 3)}
                    </Col>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-ghost"
                onClick={() => this.hideModal(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.saveSpecialRates}
              >
                {this.props?.specialRateRowEdit?.uniqueno > 0
                  ? "Edit Special Rate"
                  : "Add Special Rate"}{" "}
                
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
