import React from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { FiChevronDown } from "react-icons/fi";

export class HIDWithData extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            search: "",
            hidList:props?.hidList
        }
    }

    searchItems = (e: any) => {
        const { hidFilterList } = this.props;
        let result = e.target.value;
        this.setState({ search: result });
        if (result !== "") {
            this.setState({
                hidList: hidFilterList.filter((hName: any) =>
                    hName.hotelName.toLowerCase().includes(result.toLowerCase().trim())
                ),
            });
        } else {
            this.setState({ hidList: hidFilterList });
        }
    };

    render() {
        let isDataExist = this.state.hidList.some((o: any) => o.dataExists);
        return (
            <Dropdown className="hid-select">
                <Dropdown.Toggle id="dropdown-hid">
                    <div className="d-flex align-items-center justify-content-center">
                        {this.props?.hotelName}
                        <div className="drop-arrow d-flex"><FiChevronDown /></div>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="cusEhidSelector">
                    <div className="EhidSearch">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="text" name="search" value={this.state?.search} autoComplete="off" data-lpignore="true" onChange={this.searchItems} placeholder="Filter EHIDs" />
                        </Form.Group>
                    </div>
                    <div className="dropdownItems">
                        {this.state.hidList.length > 0 ? (
                            this.state.hidList.map(
                                (item: any, idx: number) => (
                                    <React.Fragment key={idx}>
                                        {(idx > 0 && item?.hotelType !== this.state.hidList[idx - 1]?.hotelType) && (<Dropdown.Divider />)}
                                        <Dropdown.Item eventKey={item.lettercode} onClick={() => this.props.selectHID(item)}>
                                            <div className={item.lettercode === this.props?.hotelName ? "ehidhasPIP dropDownListTitle hasCheck" : "ehidhasPIP dropDownListTitle"}>
                                                {isDataExist && (<span className={item.dataExists ? "pip blue" : "pip"}></span>)}
                                                <span className="name">{item.hotelName.split(" - ")[0]}</span>
                                                <span className="title">{item.hotelName.replace(" - ", ".").split(".")[1]}</span>
                                                {item.lettercode === this.props?.hotelName && (
                                                    <span className="itemcheck">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                                                        </svg>
                                                    </span>
                                                )}
                                            </div>
                                        </Dropdown.Item>
                                        {item.lettercode === "All" && <Dropdown.Divider />}
                                    </React.Fragment>
                                ))
                        ) : (
                            <Dropdown.Item><b>No Item Found</b></Dropdown.Item>
                        )}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}
