import Instense from "./Axios-config";
import {
  ILaborDeptPositionRequestDto, ILaborPositionDeptResponseDto, ILaborDepartmentDto,
  ILaborPositionMappingDto
} from "../Contracts/ILaborDeptPosition";

export class LaborDeptPosition {
  public static getlaborPositionDept = async (
    hotelID: number
  ): Promise<ILaborPositionDeptResponseDto[] | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;

    let request = {} as ILaborDeptPositionRequestDto;

    request.hotelID = hotelID;
    request.tenantID = tenantID;

    const url = `/LaborDeptPosition/LaborPositionDeptDetail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborPositionDeptResponseDto[];
      return result;
    });
  };

  public static getlaborPositionDeptMapping = async (

  ): Promise<ILaborPositionMappingDto[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;

    let request = {} as ILaborDeptPositionRequestDto;

    request.tenantID = tenantID;
    const url = `/LaborDeptPosition/LaborPositionDeptMapping`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborPositionMappingDto[];
      return result;
    });
  };

  public static getlaborDepartmentList = async (
  ): Promise<ILaborDepartmentDto[] | null> => {

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;

    let request = {} as ILaborDeptPositionRequestDto;
    request.status = "Active";
    request.tenantID = tenantID;
    const url = `/LaborDeptPosition/LaborDepartmentList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as ILaborDepartmentDto[];
      return result;
    });
  };



  public static saveRoleDeptMapping = async (request: any[]
  ): Promise<any | null> => {
    
    const url = `/LaborDeptPosition/LaborDepartmentRoleSave`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };


  public static savePositionDeptMapping = async (request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    const url = `/LaborDeptPosition/SaveLaborPositionDeptMapping`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static SaveLaborPositionDeptMappingCallBack = async (request: any
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID as any;
      request.tenantID = tenantID;
      const url = `/LaborDeptPosition/SaveLaborPositionDeptMappingCallBack`;
      return Instense.post(url, request).then((response) => {
        let result = response.data.result as any;
        return result;
      });
    };

  public static DeletePositionDeptMapping = async (request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    const url = `/LaborDeptPosition/DeleteLaborPositionDeptMapping`;
    return Instense.delete(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static getlaborPositionDeptByemployee = async (
    hotelID: number, employee: string
  ): Promise<any[] | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;

    let request = {} as any;

    request.userName = employee;
    request.hotelID = hotelID;
    request.tenantID = tenantID;

    const url = `/LaborDeptPosition/LaborDeptPositionByEmployee`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };


  public static LaborDepartmentRoleMapping = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/LaborDeptPosition/LaborDepartmentRoleMapping`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };

  public static LaborDeptHotelMapping = async (): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    let request = {} as any;
    request.tenantID = tenantID;
    const url = `/LaborDeptPosition/LaborDeptHotelMapping`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };


  public static LaborDepartmentSave = async (request: any): Promise<any | null> => {
    const url = `/LaborDeptPosition/LaborDepartmentSave`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborDepartmentDelete = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.tenantID = tenantID;
    const url = `/LaborDeptPosition/LaborDepartmentDelete`;
    return Instense.delete(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static DeactiveLaborPosition = async (request: any
    ): Promise<any | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID as any;
      request.tenantID = tenantID;
      const url = `/LaborDeptPosition/DeactiveLaborPosition`;
      return Instense.delete(url, { params: request }).then((response) => {
        let result = response.data.result as any;
        return result;
      });
    };

    public static GetPayrollIntegrationType = async (): Promise<any[] | null> => {
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID as any;
      let request = {} as any;
      request.tenantID = tenantID;
      const url = `/LaborDeptPosition/GetPayrollIntegrationType`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result as any[];
        return result;
      });
    };


    public static GetPayrollPositionMappingDetails = async (request: any): Promise<any[] | null> => {
    
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : storage.tenantID as any;
     // let request = {} as any;
      request.tenantID = tenantID;
      const url = `/LaborDeptPosition/GetPayrollPositionsmappingDetails`;
      return Instense.get(url, { params: request }).then((response) => {
        let result = response.data.result as any[];
        return result;
      });
    };

    public static SavePayrollPositionMappingDetails = async (request: any
      ): Promise<any[] | null> => {        
        const url = `/LaborDeptPosition/SavePositionsmappingDetails`;
        return Instense.post(url, request).then((response) => {
          let result = response.data.result as any;
          return result;
        });
      };

      public static GetLaborPostionWiseIntegrationType = async (): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let request = {} as any;
        request.tenantID = tenantID;
        const url = `/LaborDeptPosition/GetPostionWiseIntegrationType`;
        return Instense.get(url, { params: request }).then((response) => {
          let result = response.data.result as any[];
          return result;
        });
      };
  


}
