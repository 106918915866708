import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { AnyRecord } from "dns";


let hasSeprator: any = 1;
export class ddlSearchWithTab extends React.Component<any, any> {
  private inputElement: any;
  private listDiv: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.listDiv = React.createRef();
    this.state = {
      defaultSelectedName: props.defaultName,
      filterData: props.itemList,
      itemList: props.itemList,
      inputValue: "",
      searchText: props.defaultText,
      controlID: props.controlID,
      isdisable: false,
      id: props?.id,
      hasSeprator: "1",
      disabled: props?.disabled,
    };
    this.handleChange = this.handleChange.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);
  }

  handleChange(e: any) {
    const updatedList = this.state.itemList.filter((item: any) => {
      return (item.name.toLowerCase().indexOf((e.target.value.toLowerCase())) > -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  handleDropdownChange(e: any) {
    this.setState({ inputValue: "", filterData: this.state.itemList });
    this.props.handleSelectedItem(e.id, this.state.controlID);
  }

  onTAB = (event, rowIndex) => {
    
    this.props.onTAB(event, this.state.id);

  }

  resetSerchBox = () => {
    this.setState({ inputValue: "", filterData: this.state.itemList });
  };

  resetddlItem = (key: any, isdisable: any) => {
    this.setState({ inputValue: "", defaultSelectedName: key, isdisable: isdisable });
  };


  componentDidUpdate(prevProps) {
    if (prevProps?.isRefresh !== this.props?.isRefresh) {
      this.setState({ inputValue: "", defaultSelectedName: this.props.defaultName });
    }

  }



  render() {
    const hidSelectModal = (eventKey: any) => {
      this.setState({ inputValue: "", defaultSelectedName: eventKey });
    };

    const onFocusInput = (eventKey: any) => {
      //   if( this.inputElement.current!==null){
      //   setTimeout(() => {
      //     this.inputElement.current.focus();
      //   }, 200);
      // }
    };



    return (
      <>
        <OutsideClickHandler
          key="outsideclick"
          onOutsideClick={this.resetSerchBox}
        >
          <Dropdown
            className="more-action bg-blue single-select-image"
            onSelect={hidSelectModal}
            onClick={onFocusInput}

          >
            <Dropdown.Toggle id={this.state.id === null ? "ddl1" : this.state.id} className="filter-btn"
              disabled={this.state.disabled ? true : this.state.isdisable} onKeyDown={(e: any) => this.onTAB(e, 0)}>
              {this.state.defaultSelectedName === this.props.defaultName && (
                <div className="d-flex align-items-center">
                  <div className="txt-area">
                    <EllipsisWithTooltip placement="bottom">
                      {this.state.defaultSelectedName}
                    </EllipsisWithTooltip>
                  </div>
                  <div className="drop-arrow ml-0 d-flex">
                    <FiChevronDown />
                  </div>
                </div>
              )}
              {this.state.defaultSelectedName !== this.props.defaultName && (
                <div className="d-flex align-items-center">
                  <div className="txt-area">
                    <EllipsisWithTooltip placement="bottom">
                      {this.state.defaultSelectedName}
                    </EllipsisWithTooltip>
                  </div>
                  <div className="drop-arrow ml-0 d-flex">
                    <FiChevronDown />
                  </div>
                </div>
              )}
              {this.props.calIcon && (
                <div className="cal-icon housekeeping-standalone">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.66675 7.3335H6.00008V8.66683H4.66675V7.3335ZM4.66675 10.0002H6.00008V11.3335H4.66675V10.0002ZM7.33341 7.3335H8.66675V8.66683H7.33341V7.3335ZM7.33341 10.0002H8.66675V11.3335H7.33341V10.0002ZM10.0001 7.3335H11.3334V8.66683H10.0001V7.3335ZM10.0001 10.0002H11.3334V11.3335H10.0001V10.0002Z" />
                    <path d="M3.33333 14.6668H12.6667C13.402 14.6668 14 14.0688 14 13.3335V5.3335V4.00016C14 3.26483 13.402 2.66683 12.6667 2.66683H11.3333V1.3335H10V2.66683H6V1.3335H4.66667V2.66683H3.33333C2.598 2.66683 2 3.26483 2 4.00016V5.3335V13.3335C2 14.0688 2.598 14.6668 3.33333 14.6668ZM12.6667 5.3335L12.6673 13.3335H3.33333V5.3335H12.6667Z" />
                  </svg>
                </div>
              )}

            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="search-sec">
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  placeholder={this.state.searchText}
                  value={this.state.inputValue}
                  ref={this.inputElement}
                />
              </div>
              <div className="list-area" ref={this.listDiv}>
                {this.state.filterData.map((item: any, idx: any) => (
                  <>
                    {(idx > 0
                      && item?.type !== this.state.filterData[idx - 1]?.type) && (
                        <Dropdown.Divider />

                      )}
                    <Dropdown.Item
                      eventKey={item.name}
                      onClick={() => this.handleDropdownChange(item)}


                      key={idx}

                    >
                      <EllipsisWithTooltip placement="bottom">
                        {item.name}


                      </EllipsisWithTooltip>
                    </Dropdown.Item>


                    {(this.state.filterData.length > 0
                      && this.state.filterData[idx - 1]?.orderno === 1 && item?.orderno === 2) && (
                        <hr />

                      )}
                  </>

                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </OutsideClickHandler>
      </>
    );
  }
}
