import React from "react";
import {
  Container,
  Button,
  Dropdown,
  Form,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FiChevronDown } from "react-icons/fi";
import $ from "jquery";
import { HidSelector } from "../../Common/Components/HidSelector";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { Facility } from "../../Common/Services/Facility";
import "./Location.scss";
import "./facilities.scss";
import _ from "lodash";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { IGetAssetsListRequest } from "../../Common/Contracts/IFacilities";
import { AddAsset } from "./AddAsset";
import { AssetSlideOut } from "./AssetSlideOut";
import { Utils } from "../../Common/Utilis";
import Dropzone from "react-dropzone";
import {
  faFileImage,
  faFilePdf,
  faFileExcel,
  faFileCsv,
  faFileWord,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AssetBulkUploadTable } from "./AssetBulkUploadTable";
import "./Asset.scss";

export class Assets extends React.Component<any, any> {
  private child: any;
  private childHID: any;
  private departmentSearch: any;
  private empTypeSearch: any;
  private tableTypeSearch: any;
  private scheduleUserViewRef: any;
  constructor(props: any) {
    super(props);
    this.scheduleUserViewRef = React.createRef();
    this.child = React.createRef();
    this.childHID = React.createRef();
    this.departmentSearch = React.createRef();
    this.empTypeSearch = React.createRef();
    this.tableTypeSearch = React.createRef();
    this.state = {
      pageLoader: false,
      showAddAssetModal: false,
      hidValue: "Select",
      hotelName: "Select",
      tableData: [],
      assetTypes: [],
      assetTypesList: [],
      createEditPermission: true,
      locationList: [],
      searchText: "",
      assetTypeId: 0,
      sortingOrder: "asc",
      sortingField: "name",
      showSlideOut: false,
      entityRowData: {},
      toggleBulkUploadModal: false,
      fileSize: 5242880,
      fileUploadProgress: 0,
      bulkTableData: [],
      showTemplateModal: false,
      newSelectedfiles: [],
      savedfiles: [],
      files: [],
      selectedAssets: [],
      isChanging: false,
      isImportData: false,
      isAssetAndLocationDataLoading: false,
      showBulkOptions: false,
      validatingData: false,
    };
  }

  componentDidMount() {
    this.getAssetTypes();
    this.CheckAddEditPermission();
  }

  CheckAddEditPermission = () => {
    Facility.CheckAddEditPermission().then((res) => {
      this.setState({ createEditPermission: res?.result });
    });
  };

  handleShowSlideout = (entityRowData: any) => {
    this.setState({ entityRowData }, () => {
      this.setState({
        showSlideout: true,
      });
    });
  };

  handleHideSlideout = (isReload) => {
    this.setState({
      showSlideout: false,
    });

    this.getLocationList();
    this.getAssets();
  };

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ pageLoader: true });

    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,
      },
      () => {
        this.getLocationList();
        this.getAssets();
      }
    );
  };

  getAssets = () => {
    const { searchText, assetTypeId, hidValue } = this.state;
    const request: IGetAssetsListRequest = {
      searchText,
      hid: hidValue,
      assetTypeId,
    };

    Facility.GetAssets(request).then((tableData) => {
      this.setState({ tableData: _.sortBy(tableData, "assetName") });
    });
  };

  getAssetTypes = () => {
    this.setState({ isAssetAndLocationDataLoading: true });
    Facility.GetDistinctAssetTypeList()
      .then((assetTypes: any) => {
        const _assetTypesList = _.cloneDeep(assetTypes);
        if (assetTypes?.length > 0) {
          assetTypes = assetTypes.map((x) => {
            return { id: x?.assetTypeId, name: x?.assetTypeName };
          });
        }
        this.setState({
          assetTypes: [{ id: "", name: "All" }, ...assetTypes],
          assetTypesList: _assetTypesList,
          isAssetAndLocationDataLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          assetTypes: [],
          assetTypesList: [],
          isAssetAndLocationDataLoading: false,
        });
      });
  };

  onSearchChange = (searchText: string) => {
    this.setState({ searchText: searchText?.toString()?.trimStart() }, () => {
      // if (searchText?.length > 1 || !searchText) {
      this.getAssets();
      // }
    });
  };

  onDDlFiltersChange = (controlID: any, id: any) => {
    let { assetTypeId } = this.state;
    switch (controlID) {
      case "ddlAssetType":
        assetTypeId = id || 0;
        break;
      default:
        break;
    }

    this.setState({ assetTypeId }, () => {
      this.getAssets();
    });
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        this.sortTableData(this.state.tableData, this.state.sortingField);
      }
    );
  };

  sortTableData = (data: any, fieldName) => {
    if (this.state.sortingOrder === "asc") {
      data = _.orderBy(data, [fieldName], ["asc", "asc"]);
    } else if (this.state.sortingOrder === "desc") {
      data = _.orderBy(data, [fieldName], ["desc", "asc"]);
    }
    this.setState({ tableData: data });
  };
  addNewAsset = () => {
    if (this.state.locationList.length == 0) {
      Utils.toastError("Please Add Locations for this Entity.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addGarnishment",
      });
    } else {
      this.setState({ showAddAssetModal: true });
    }
  };

  hideAddAsset = () => {
    this.setState({ showAddAssetModal: false }, () => {
      //Call Get Data
      this.getAssets();
    });
  };

  sortImage = (order) => {
    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );

    return null;
  };

  getLocationList = () => {
    this.setState({ isAssetAndLocationDataLoading: true });
    Facility.GetDistinctRoomsAllList(this.state.hidValue)
      .then((res) => {
        this.setState({
          locationList: res,
          isAssetAndLocationDataLoading: false,
        });
      })
      .catch((res) => {
        this.setState({
          locationList: [],
          isAssetAndLocationDataLoading: false,
        });
      });
  };

  toggleBulkUploadModal = () => {
    this.setState({
      showBulkUploadModal: !this.state.showBulkUploadModal,
      showBulkOptions: false,
    });
  };

  toggleBulkTemplateModal = () => {
    this.setState({ showTemplateModal: !this.state.showTemplateModal }, () => {
      if (!this.state?.showTemplateModal) {
        this.getAssets();
        this.setState({
          bulkTableData: [],
          selectedAssets: [],
          show: false,
          showBulkOptions: false,
        });
      }
    });
  };

  handleKeyDown = (event, type) => {
    if (event.key.toLowerCase() === "enter") {
      if (type === "droparea") {
        $("div[id^='" + "droparea" + "']").focus();
      } else {
        this.setState({
          createOther: !event.target.checked,
          isUpdateRecord: false,
          newRequest: false,
        });
      }
    }
    if (event.key.toLowerCase() === "tab") {
      if (type === "droparea") {
        $("checkbox[id^='" + "chkAnother" + "']").focus();
      }
    }
  };

  onDrop = (filelist: any, mode: any) => {
    let size = this.state.fileSize; //5242880//5MB
    let newfiles: any = [];
    let files: any = [];
    let isReturn = true;
    let filestext = "";
    let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024));
    filelist.forEach((element) => {
      if (element.size > size) {
        filestext = filestext + element.name + ",";
        isReturn = false;
      }
    });

    if (!isReturn) {
      let msg = "One or more files are greater than " + filesizeval + "MB.";
      Utils.toastError(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "AssetNotifications",
      });
    }
    let isInvalidFile = false;
    const filExtList = ["xls", "xlsx", "csv"];
    filelist.forEach((element) => {
      if (
        filExtList.filter(
          (extentionName) =>
            extentionName ===
            element?.name
              ?.substring(
                element?.name.lastIndexOf(".") + 1,
                element?.name.length
              )
              .toLowerCase()
        )?.length > 0 &&
        element.size <= size
      ) {
        newfiles.push(element);
        files.push(element);
      } else {
        isInvalidFile = true;
      }
    });
    if (isInvalidFile && isReturn) {
      let msg = "";
      if (filelist.length === 1) {
        msg = "This file type is not supported.";
      } else {
        msg = "Some files are not supported.";
      }
      Utils.toastError(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "AssetNotifications",
      });
    }

    let newfileData = [...this.state.newSelectedfiles];
    
    for (let file of newfiles) {
      file.etype = "newfile";
      newfileData.push(file);
    }

    this.setState({ files, newSelectedfiles: newfileData }, () => {
      // debugger;

      if (mode === 2) {
        //this.isHandleAction();
      } else {
        if (files.length > 0) {
          this.uploadExcelTemplate();
        }
      }
    });
  };

  onFileChange = (event: any, mode) => {
    let newfiles: any = [];
    if (event.target.files.length > 0) {
      for (let file of event.target.files) {
        newfiles.push(file);
      }
      this.onDrop(newfiles, mode);
      let fileList = document.getElementById("formControlsFile") as any;
      fileList.value = "";
    }
  };

  onUploadProgress = (data: any) => {
    const progress: any = (
      (Number(data.loaded) / Number(data.total)) *
      100
    ).toFixed(2);
    this.setState({ fileUploadProgress: progress });
  };

  resetFiles = () => {
    this.setState({ newSelectedfiles: [], savedfiles: [], files: [] });
  };

  uploadExcelTemplate = () => {
    this.setState({ isSpinner: true });
    let newfileData = [...this.state.newSelectedfiles];

    Facility.ResponseExcelFile(
      newfileData,
      this.state?.hidValue,
      this.onUploadProgress
    )
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.success) {
            const data =
              (result?.result?.data || [])?.map((row, i) => {
                return { id: i + 1, ...row };
              }) || [];
            this.setState(
              {
                bulkTableData: data,
              },
              () => {
                if (data?.length > 0) {
                  this.toggleBulkTemplateModal();
                  this.toggleBulkUploadModal();
                }
              }
            );
            this.setState({ isSpinner: false });
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "AssetNotifications",
            });
          } else {
            this.setState({ isSpinner: false });
            Utils.toastError(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "AssetNotifications",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ changeValue: false, isSpinner: false });
      })
      .finally(() => {
        this.setState({ fileUploadProgress: 0 });
        this.resetFiles();
      });
  };

  bulkSaveAssets = () => {
    this.setState({ isImportData: true });
    let bulkTableData = [...this.state.bulkTableData];
    const request: any = {
      assets: bulkTableData,
      hid: this.state.hidValue,
    };
    Facility.BulkSaveAssets(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.success) {
            if (result?.result?.inValidRowsData?.length > 0) {
              const data =
                (result?.result?.inValidRowsData || [])?.map((row, i) => {
                  return { id: i + 1, ...row };
                }) || [];
              this.setState(
                {
                  bulkTableData: data,
                },
                () => {
                  this.refreshBulkUploadTableTable(data);
                }
              );
              Utils.toastError(result?.result?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "AssetNotifications",
              });
            } else {
              toast.success(result?.result?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "AssetNotifications",
              });
              this.toggleBulkTemplateModal();
            }
          } else {
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "AssetNotifications",
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "AssetNotifications",
        });
      })
      .finally(() => {
        this.setState({ isImportData: false });
      });
  };

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selectedAssets: [...this.state.selectedAssets, row.id],
        showBulkOptions: true,
      }));
    } else {
      this.setState(() => ({
        selectedAssets: this.state.selectedAssets.filter((x) => x !== row.id),
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = this.state.bulkTableData.map((r) => r.id);
    if (
      isSelect ||
      (this.state?.selectedAssets?.length > 0 &&
        this.state.bulkTableData?.length != this.state?.selectedAssets?.length)
    ) {
      this.setState({ selectedAssets: [] }, () =>
        this.setState({
          selectedAssets: ids,
          showBulkOptions: true,
        })
      );
    } else {
      this.setState({
        selectedAssets: [],
      });
    }
  };

  removeSelectedRows = () => {
    let filteredData = this.state.bulkTableData.filter(
      (row) => !this.state.selectedAssets?.includes(row?.id)
    );

    if (filteredData?.length > 0) {
      this.validatebulkAssetsData(filteredData);
    } else {
      this.toggleBulkUploadModal();
      this.toggleBulkTemplateModal();
      this.setState(
        {
          bulkTableData: filteredData || [],
          selectedAssets: [],
          showBulkOptions: false,
        },
        () => this.refreshBulkUploadTableTable(this.state.bulkTableData)
      );
      toast.success("Selections have successfully been removed", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "AssetNotifications",
      });
    }
  };

  validatebulkAssetsData = (bulkTableData, isFromRemove = true) => {
    this.setState({ validatingData: true });
    let _bulkTableData = [...bulkTableData];
    const request: any = {
      assets: _bulkTableData,
      hid: this.state.hidValue,
    };
    Facility.ValidatebulkAssetsData(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.success) {
            if (result?.result?.length > 0) {
              const data =
                (result?.result || [])?.map((row, i) => {
                  return { id: i + 1, ...row };
                }) || [];
              if (isFromRemove) {
                this.setState(
                  {
                    bulkTableData: data,
                    selectedAssets: [],
                    showBulkOptions: false,
                  },
                  () =>
                    this.refreshBulkUploadTableTable(this.state.bulkTableData)
                );
                toast.success("Selections have successfully been removed", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "AssetNotifications",
                });
              } else {
                this.setState(
                  {
                    bulkTableData: data,
                  },
                  () =>
                    this.refreshBulkUploadTableTable(this.state.bulkTableData)
                );
              }
            } else {
              toast.success(result?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "AssetNotifications",
              });
            }
          } else {
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "AssetNotifications",
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "AssetNotifications",
        });
      })
      .finally(() => {
        this.setState({ validatingData: false });
      });
  };

  refreshBulkUploadTableTable = (data) => {
    this.setState(
      {
        bulkTableData: [],
      },
      () => this.setState({ bulkTableData: _.cloneDeep(data) })
    );
  };

  closeBulkAction = () => {
    this.setState({
      showBulkOptions: false,
      selectedAssets: [],
    });
  };

  footerBulkSelectAll = (e) => {
    let ischecked = e.target.checked;
    const ids = this.state.bulkTableData.map((r) => r.id);
    if (ischecked) {
      this.setState({ selectedAssets: [] }, () =>
        this.setState(
          {
            selectedAssets: ids,
          },
          () => this.refreshBulkUploadTableTable(this.state.bulkTableData)
        )
      );
    } else {
      this.setState(
        {
          selectedAssets: [],
        },
        () => this.refreshBulkUploadTableTable(this.state.bulkTableData)
      );
    }
  };

  tableCellEdit = {
    mode: "click",
    blurToSave: true,
    afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
      this.setState({ isChanging: false });
      if (oldValue !== newValue) {
        let { bulkTableData } = this.state;
        let dataField = column.dataField;

        bulkTableData.map(
          (items) =>
            items.id === row.id &&
            (items[dataField] = newValue?.toString().trimLeft())
        );

        this.setState(
          (curr: any) => ({
            ...curr,
            bulkTableData: bulkTableData,
          }),
          () => {
            this.validatebulkAssetsData(bulkTableData, false);
            setTimeout(() => {
              this.isReadyToImport(row);
            }, 500);
          }
        );
      }
    },
    beforeSaveCell: (oldValue, newValue, row, column, done) => {
      let dataField = column.dataField;
      if (oldValue !== newValue) {
      

        setTimeout(() => {
          if (dataField === "notes") {
            if (newValue?.toString()?.length > 200) {
              return done(false);
            } else {
              return done(true);
            }
          } else if (dataField === "assetName") {
            if (newValue?.toString()?.length > 100) {
              return done(false);
            } else {
              return done(true);
            }
          } else {
            return done(true);
          }
        }, 0);
        return { async: true };
      }
    },
  };
  isReadyToImport = (row: any) => {
    let { bulkTableData } = this.state;
    bulkTableData.map((items) => {
      if (items.id === row.id) {
        if (!items.assetName || !items.assetType || !items.location) {
          items.isErr = true;
        } else {
          items.isErr = true;
        }
      }
    });
    this.refreshBulkUploadTableTable(bulkTableData);
    this.setState((curr: any) => ({
      ...curr,
      bulkTableData: bulkTableData,
      isChanging: false,
    }));
  };

  render() {
    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.handleShowSlideout(row);
      },
    };

    const {
      showBulkUploadModal,
      fileUploadProgress,
      showBulkOptions,
      showTemplateModal,
      bulkTableData,
      selectedAssets,
      isChanging,
      isImportData,
      assetTypesList,
      locationList,
      validatingData,
    } = this.state;

    const columns = [
      {
        dataField: "assetName",
        text: "Asset Name",

        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                {row.assetName}
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "roomName",
        text: "Location",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                {row.roomName}
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "assetTypeName",
        text: "Asset Type",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip>{row.assetTypeName}</EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "openTasks",
        text: "Open Tasks",
      },
    ];

    const files = this.state.newSelectedfiles?.map((file, index) => {
      return (
        <>
          <li key={file.name}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="icon">
                {file?.type?.split("/")[0] === "image" && (
                  <FontAwesomeIcon icon={faFileImage} />
                )}
                {file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) === "pdf" && <FontAwesomeIcon icon={faFilePdf} />}
                {file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) === "xlsx" && <FontAwesomeIcon icon={faFileExcel} />}
                {file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) === "csv" && <FontAwesomeIcon icon={faFileCsv} />}
                {file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) === "word" && <FontAwesomeIcon icon={faFileWord} />}
                {file?.type?.split("/")[0] !== "image" &&
                  file?.name.substring(
                    file?.name.lastIndexOf(".") + 1,
                    file?.name.length
                  ) !== "pdf" &&
                  file?.name.substring(
                    file?.name.lastIndexOf(".") + 1,
                    file?.name.length
                  ) !== "xlsx" &&
                  file?.name.substring(
                    file?.name.lastIndexOf(".") + 1,
                    file?.name.length
                  ) !== "csv" &&
                  file?.name.substring(
                    file?.name.lastIndexOf(".") + 1,
                    file?.name.length
                  ) !== "word" && <FontAwesomeIcon icon={faFile} />}
              </div>
              <div className="file-info">
                <div className="name-loader">
                  <div className="name d-flex">
                    <div className="full-name">{file.name}</div>
                  </div>
                </div>
              </div>
              <div className="upload-progress-bar">
                <ProgressBar
                  animated
                  variant="success"
                  now={fileUploadProgress}
                  // label={`${fileUploadProgress}%`}
                />{" "}
              </div>
              <div className="count">
                <span>{fileUploadProgress}%</span>
              </div>
            </div>
          </li>
        </>
      );
    });

    const files1 = this.state.savedfiles?.map((file, index) => (
      <>
        <li key={file.name}>
          <div className="d-flex align-items-center justify-content-start">
            <div className="icon">
              {file?.type?.split("/")[0] === "image" && (
                <FontAwesomeIcon icon={faFileImage} />
              )}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "pdf" && <FontAwesomeIcon icon={faFilePdf} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "xlsx" && <FontAwesomeIcon icon={faFileExcel} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "csv" && <FontAwesomeIcon icon={faFileCsv} />}
              {file?.name.substring(
                file?.name.lastIndexOf(".") + 1,
                file?.name.length
              ) === "word" && <FontAwesomeIcon icon={faFileWord} />}
              {file?.type?.split("/")[0] !== "image" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "pdf" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "xlsx" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "csv" &&
                file?.name.substring(
                  file?.name.lastIndexOf(".") + 1,
                  file?.name.length
                ) !== "word" && <FontAwesomeIcon icon={faFile} />}
            </div>
            <div className="file-info d-flex">
              <div className="name-loader">
                <div className="name d-flex">
                  <div className="full-name">{file.name}</div>
                  {/* <div className="full-size">- {file.size} bytes</div> */}
                </div>
                {/* <div className="loader"><ProgressBar animated variant="success" now={45} /></div> */}
              </div>
              <div className="formate-percentage">
                <div className="formate d-flex justify-content-center align-items-center">
                  {file?.name.substring(
                    file?.name.lastIndexOf(".") + 1,
                    file?.name.length
                  )}
                </div>
                {/* <div className="percentage d-flex justify-content-center align-items-center">45%</div> */}
              </div>
            </div>
            <div className="action">
              <Dropdown
                className="more-action"
                alignRight
                // onSelect={(event: any) =>
                //   this.handleSelectFileUpload(event, file, index)
                // }
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.moreActionFileUpload1.map(
                    (item: any, idx: any) => (
                      <>
                        {file.fileUniqueno > 0 && (
                          <Dropdown.Item
                            className={
                              (!this.state.hasEditPermission ||
                                this.state.isPermission === "No") &&
                              this.state.moreActionFileUpload1[idx].eventKey ===
                                "deleteFile"
                                ? "d-none"
                                : ""
                            }
                            eventKey={
                              this.state.moreActionFileUpload1[idx].eventKey
                            }
                            key={idx}
                          >
                            {this.state.moreActionFileUpload1[idx].name}
                          </Dropdown.Item>
                        )}
                      </>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </li>
      </>
    ));

    return (
      <>
        {this.state.showAddAssetModal && (
          <AddAsset
            hid={this.state.hidValue}
            assetTypesList={this.state.assetTypesList}
            showAddAssetModal={this.state.showAddAssetModal}
            hideAddAsset={this.hideAddAsset}
          />
        )}

        <div className="performance-position performance timesheet location-page-wrapper assets-wrapper">
          <ToastContainer containerId={"AssetNotifications"}></ToastContainer>
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
                modulename={"Facility Maintenance"}
              />
              <div className="mr-auto d-flex align-items-center">Assets</div>

              {this.state.hidValue !== "Select" &&
                this.state.createEditPermission && (
                  <Button onClick={this.addNewAsset}>New Asset</Button>
                )}

              <Dropdown className="more-action ml-3">
                <DropdownToggle
                  disabled={!this.state?.createEditPermission}
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    disabled={
                      this.state.isAssetAndLocationDataLoading ||
                      this.state.hidValue == "Select"
                    }
                    onClick={() => this.toggleBulkUploadModal()}
                  >
                    Bulk Upload
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {/* {this.state.pageLoader ? (
              <ReactPageLoader useas={"timesheetMain"} />
            ) : ( */}
            {this.state.hidValue !== "Select" && (
              <div className="main-Page">
                <div className="bdy-sec">
                  <ToolkitProvider
                    keyField="userUniqueno"
                    data={this.state.tableData}
                    columns={columns}
                    hover
                  >
                    {(props: {
                      searchProps: JSX.IntrinsicAttributes;
                      baseProps: JSX.IntrinsicAttributes;
                    }) => (
                      <div>
                        <div className="search-header d-flex">
                          <div className="schedule-main">
                            <div className="schedule-department-view-table">
                              <div className="departmentViewNavigation">
                                <div className="navigation-menus d-flex">
                                  <div className="schedule-table-search">
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        this.onSearchChange(e.target.value)
                                      }
                                      value={this.state.searchText}
                                      className="search-box form-control"
                                      placeholder="Search..."
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="room-type-dropdown">
                            {this.state.assetTypes.length > 0 && (
                              <SingleSearchDropdownList
                                id={"tabletypeddl"}
                                isRefresh={this.state.isRefresh}
                                itemList={this.state.assetTypes}
                                handleSelectedItem={this.onDDlFiltersChange.bind(
                                  this,
                                  "ddlAssetType"
                                )}
                                defaultItem={this.state.assetTypeId}
                                defaultText={"Search Asset Type"}
                                defaultName={"Asset Type"}
                                controlID="1"
                              />
                            )}
                          </div>

                          <Dropdown
                            className="setting-dropdown more-action"
                            alignRight
                          >
                            <Dropdown.Toggle className="" id="dropdown-more">
                              <div className="d-flex align-items-center">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                                </svg>
                                <div className="drop-arrow ml-0 d-flex">
                                  <FiChevronDown />
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item></Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <div className="hasCursorPointer position-relative">
                          <div className="block-bar"></div>
                          <BootstrapTable
                            id={"timesheet"}
                            {...props.baseProps}
                            keyField="userUniqueno"
                            hover
                            rowEvents={tableRowEvents}
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            )}
            {/* )} */}
            {this.state.showSlideout && (
              <AssetSlideOut
                hid={this.state.hidValue}
                handleHideSlideout={this.handleHideSlideout}
                {...this.props}
                parentstate={this.state.entityRowData}
                hotelName={this.state.hotelName}
              />
            )}
            <Modal
              className="add-room-type-modal add-new-asset upload-file-modal-ui"
              show={showBulkUploadModal}
              onHide={this.toggleBulkUploadModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              // keyboard={this.props?.showDeductionModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Asset Bulk Upload</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-section">
                  <div className="body-section">
                    <Dropzone
                      onDrop={this.onDrop}
                      multiple={false}
                      // accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragReject,
                      }) => (
                        <>
                          <section
                            className={
                              this.state.newSelectedfiles.length > 0
                                ? "container upload-area border-0"
                                : isDragActive
                                ? "container upload-area default drag-active "
                                : "container upload-area default"
                            }
                          >
                            {!this.state.bulkSelect && (
                              <div
                                {...getRootProps({
                                  className:
                                    "dropzone d-flex align-items-center",
                                  onClick:
                                    this.state.newSelectedfiles.length > 0 ||
                                    this.state.savedfiles.length > 0
                                      ? (event) => event.stopPropagation()
                                      : (event) => event,
                                })}
                              >
                                <input {...getInputProps()} />
                                {this.state.newSelectedfiles.length < 1 &&
                                  this.state.savedfiles.length < 1 && (
                                    <p>
                                      Drag and drop here to start
                                      <br></br>
                                      <span>.csv</span>
                                    </p>
                                  )}
                                {(this.state.newSelectedfiles.length > 0 ||
                                  this.state.savedfiles.length > 0) &&
                                  isDragActive && (
                                    <p>
                                      Drag and drop here to start
                                      <br></br>
                                      <span>.csv</span>
                                    </p>
                                  )}
                                {/* {!isDragActive && (<p>Drag and drop or <span>browse files</span> to upload</p>)} */}
                              </div>
                            )}

                            {/* {this.state.bulkSelect && ( 
                                                                            <div className="dropzone d-flex align-items-center">
                                                                                <p>Drag and drop or <span>browse files</span> to upload</p>
                                                                            </div>
                                                                      )}   */}
                            {(this.state.newSelectedfiles.length > 0 ||
                              this.state.savedfiles.length > 0) && (
                              <aside className="d-flex flex-column">
                                <ul className="p-0">{files1}</ul>
                                <ul className="p-0">{files}</ul>
                              </aside>
                            )}
                          </section>
                          {!this.state.bulkSelect &&
                            (this.state.newSelectedfiles.length > 0 ||
                              this.state.savedfiles.length > 0) &&
                            this.state.parentRowData?.dailyTaskPermission ===
                              "Yes" && (
                              <div className="upload-link mrgn-top">
                                <button
                                  type="button"
                                  className="btn p-0 wht-bg link-btn mr-auto"
                                >
                                  Add Attachment
                                </button>
                                <Form.Control
                                  id="formControlsFile"
                                  type="file"
                                  multiple
                                  onChange={(event: any) =>
                                    this.onFileChange(event, "1")
                                  }
                                  accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                />
                              </div>
                            )}
                        </>
                      )}
                    </Dropzone>
                    <div className="download-btn">
                      <a href="../../files/ExcelBulkUploadTemplates/AssetsBulkUploadTemplate.xlsx">
                        Download Template
                      </a>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <AssetBulkUploadTable
              showTemplateModal={showTemplateModal}
              toggleBulkTemplateModal={this.toggleBulkTemplateModal}
              toggleBulkUploadModal={this.toggleBulkUploadModal}
              bulkSaveAssets={this.bulkSaveAssets}
              tableData={bulkTableData}
              selectedAssets={selectedAssets}
              handleOnSelect={this.handleOnSelect}
              handleOnSelectAll={this.handleOnSelectAll}
              removeSelectedRows={this.removeSelectedRows}
              closeBulkAction={this.closeBulkAction}
              footerBulkSelectAll={this.footerBulkSelectAll}
              tableCellEdit={this.tableCellEdit}
              isChanging={isChanging}
              isImportData={isImportData}
              assetTypesList={assetTypesList}
              locationList={locationList}
              showBulkOptions={showBulkOptions}
              validatingData={validatingData}
            />
          </Container>
        </div>
      </>
    );
  }
}
