import { GridOptions } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useState, useEffect, useRef } from "react";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import _ from "lodash";
import {
  Button,
  Container,
  Dropdown,
  Form,
  FormLabel,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { PayrollDeductionForm } from "./PayrollDeductionSettingsForm";
import "./payrollDeductionSetting.scss";
import { PayrollDeductionLogHistory } from "./PayrollDeductionLogHistory";
import { PayrollDeductionLogHistory as HistoricalLogHistroy } from "./PayrollDeductionLogHistory";
import { DeductionCriteria } from "../../../Common/Services/DeductionCriteria";
import { toast, ToastContainer } from "react-toastify";
import { StaticArrays } from "../../../Common/StaticArrays"; 
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../../../Common/Utilis";
import { MultiSelectDropdown } from "./MultiSelectDropdown";

const columnDef = [
  {
    headerName: "",
    field: "isChecked",
    cellRenderer: "checkBoxRender",
    cellClass: "cursorPointer ag-checkbox-input-wrapper",
    // checkboxSelection: true,
    // showDisabledCheckboxes: true,
    maxWidth: 50,
    pinned: true,
    // hide:true,
    flex: 1,
    lockPosition: true,
  },
  {
    headerName: "Deduction Name",
    field: "deductionName",
    cellRenderer: "deductionNameRender",
    cellClass: "cursorPointer cell-item deductionNameCell",
    headerClass: "deductionName",
    width: 250,
    flex: 1,
    lockPosition: true,
  },
  {
    headerName: "Deduction Type",
    field: "deductionType",
    cellRenderer: "valueRender",
    cellClass: "cursorPointer cell-item",

    flex: 1,
    lockPosition: true,
    sortable: true,
    enableSorting: true,
  },
  {
    headerName: "Tax Type",
    field: "taxType",
    cellRenderer: "valueRender",
    cellClass: "cursorPointer cell-item pl-3",
    headerClass: "pl-3",
    flex: 1,
    lockPosition: true,
    enableSorting: true,
    sortable: true,
  },
  {
    headerName: "Deduction Criteria",
    field: "deductionCri",
    cellRenderer: "valueRender",
    cellClass: "cursorPointer cell-item",

    flex: 1,
    lockPosition: true,
  },
  {
    headerName: "Amount",
    field: "amount",
    cellRenderer: "amountRender",
    cellClass: "cursorPointer cell-item amountCol",
    headerClass: "amountCol",
    flex: 1,
    lockPosition: true,
  },
  {
    headerName: "Exemptions",
    field: "exempt",
    cellRenderer: "exemptionsCellRenderer",
    cellClass: "cursorPointer cell-item",
    flex: 1,
    lockPosition: true,
  },
];

const types = [
  {
    id: 1,
    name: "Test 1",
  },
  {
    id: 2,
    name: "Test 2",
  },
  {
    id: 3,
    name: "Test 3",
  },
];
let gridApi, gridColumnApi;
function PayrollDeductionSettingsList() {
  const gridRef = useRef<any>();
  let checkAllRef = useRef<any>();
  const [searchText, setSearchText] = useState("");
  const [activeStatus, setActiveStatus] = useState("Active");

  // const [gridApi, setGridApi]=useState<any>()
  // const [gridColumnApi, setGridColumnApi]=useState<any>()

  const [isBulkAction, setIsBulkAction] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showDeductionModal, setShowDeductionModal] = useState(false);
  const [showChangeLogModal, setShowChangeLogModal] = useState(false);
  const [showChangeHistoryLogModal, setShowChangeHistoryLogModal] =
    useState(false);

  const [gridColumns, setGridColumns] = useState<any[]>([...columnDef]);

  //Filters
  const [DeductionTypes, setDeductionTypes] = useState<any[]>([]);
  const [selectedDeductionType, setSelectedDeductionType] = useState<any[]>([]);

  const [TaxTypes, setTaxTypes] = useState<any[]>([]);
  const [selectedTaxType, setSelectedTaxType] = useState<any[]>([]);

  //Table Data
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [allData, setAllData] = useState<any[]>([]);
  const [deductionRowEdit, setDeductionRowEdit] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const showDataAsPerToggle = (data) => {
    // 0 means Active
    if (activeStatus === "Active") {
      const _filter = data.filter((item) => item.isDeleted !== 1);
      setFilteredData(_filter);
      setTableData(_filter);
    } else {
      setFilteredData(data);
      setTableData(data);
    }
  };

  const getListData = () => {
    setIsDataLoading(true);
    DeductionCriteria.GetAllDeductionCriteria()
      .then((res) => {
        const data = [...res.result.result];
        setAllData(data);
        showDataAsPerToggle(data);
        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
      });
  };

  useEffect(() => {
    let _deductionList: any[] = StaticArrays.deductionTypeList.map((x) => ({
      description: x.label,
      value: x.label,
      isChecked: false,
    }));
    _deductionList = _.sortBy(_deductionList, [
      function (o) {
        return o.description;
      },
    ]);
    setDeductionTypes(_deductionList);

    let _taxList: any[] = StaticArrays.taxTypesList.map((x) => ({
      description: x.label,
      value: x.label,
      isChecked: false,
    }));
    _taxList = _.sortBy(_taxList, [
      function (o) {
        return o.description;
      },
    ]);
    setTaxTypes(_taxList);
    //API call
    getListData();
  }, []);

  function filteringData() {
    const selectedDeductionTypeLabel =
      selectedDeductionType.length != 0
        ? selectedDeductionType?.map((x) => x.value.trim())
        : [];
    const selectedTaxTypeLabel =
      selectedTaxType.length != 0
        ? selectedTaxType?.map((x) => x.value.trim())
        : [];
    let _data: any[] = [];

    if (
      selectedTaxTypeLabel.length === 0 &&
      selectedDeductionTypeLabel.length == 0
    ) {
      _data = tableData;
    } else {
      let _filteredData = _.cloneDeep(tableData);
      _filteredData = _filteredData.filter((item) => {
        if (
          selectedDeductionTypeLabel.length == 0 &&
          selectedTaxTypeLabel.length != 0
        ) {
          return selectedTaxTypeLabel.includes(item.taxType.trim());
        } else if (
          selectedDeductionTypeLabel.length != 0 &&
          selectedTaxTypeLabel.length == 0
        ) {
          return selectedDeductionTypeLabel.includes(item.deductionType.trim());
        } else if (
          selectedDeductionTypeLabel.includes(item.deductionType.trim()) &&
          selectedTaxTypeLabel.includes(item.taxType.trim())
        ) {
          return true;
        }
        return false;
      });

      _data = _filteredData;
    }

    if (searchText !== "") {
      _data = _data.filter((item: any) => {
        return item.deductionName
          .toLowerCase()
          .startsWith(searchText.toLowerCase());
      });
    }

    setFilteredData(_data);
  }

  useEffect(() => {
    filteringData();
  }, [
    selectedDeductionType,
    selectedTaxType,
    searchText,
    activeStatus,
    tableData,
  ]);

  const handleSelectedRows = () => {
    const selectedRowdata: any[] = [];
    gridRef?.current?.api?.forEachNode((node) => {
      if (node.data?.isChecked) {
        selectedRowdata.push(node?.data);
      }
    });

    setSelectedRows([...selectedRowdata]);
    // setIsBulkAction(selectedRowdata.length == 0 ?  false : true)

    // const selectedData = gridApi ? gridApi?.getSelectedRows() : [];
    // setSelectedRows(selectedData)
    // setIsBulkAction(selectedData.length == 0 ?  false : true)
    // console.log(selectedData)
  };
  const handleAddDeduction = () => {
    setShowDeductionModal(true);
  };

  const handleSearch = (e) => {
    // const updatedList = tableData.filter((item: any) => {
    //   return (item.deductionName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
    // });

    // setFilteredData([...updatedList])

    setSearchText(e.target.value);
  };

  const onActiveStatusChange = (val) => {
    const newStatus = activeStatus === "Active" ? "Inactive" : "Active";

    if (newStatus === "Active") {
      // 0 means Active
      const _filter = allData.filter((item) => item.isDeleted !== 1);
      setFilteredData(_filter);
      setTableData(_filter);
    } else {
      setFilteredData(allData);
      setTableData(allData);
    }
    setActiveStatus((prev) => (prev === "Active" ? "Inactive" : "Active"));
    closeBulkAction();
  };

  const footerBulkSelectAll = (e) => {
    const currentData: any[] = [];
    gridRef?.current?.api?.forEachNode((node) => {
      if (node?.data.isDeleted != 1) {
        currentData.push({ ...node?.data, isChecked: e.target.checked });
      }
    });

    gridRef.current.api.applyTransaction({
      update: currentData,
    });

    handleSelectedRows();
  };

  const closeBulkAction = () => {
    setIsBulkAction(false);
    setSelectedRows([]);

    const currentData: any[] = [];
    gridRef?.current?.api?.forEachNode((node) => {
      currentData.push({ ...node?.data, isChecked: false });
    });
    gridRef.current.api.applyTransaction({
      update: currentData,
    });

    gridRef.current.columnApi.setColumnVisible("isChecked", false);
  };

  const handleStatusChange = () => {
    confirmAlert({
      title: "Deactivate Deduction",
      message:
        "Are you sure you would like to Deactivate this Deduction? Deactivating will affect all users with this deduction. ",
      buttons: [
        {
          label: "Yes",
          onClick: () => deactivateDeduction(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const deactivateDeduction = () => {
    const val = selectedRows.map((x) => x.uniqueno);
    DeductionCriteria.MultiDeleteDeductionCriteria(val)
      .then((result) => {
        if (result?.message === "Success") {
          closeBulkAction();
          getListData();
          toast.success(
            selectedRows.length == 1
              ? "Deduction Deactivated Successfully"
              : "Deductions Deactivated Successfully",

            {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addDeduction",
            }
          );
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDeduction",
          });
        }
      })
      .catch((error) => {
        closeBulkAction();
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDeduction",
        });
      });
  };

  const hideDeductionModal = (isReload = false) => {
    setShowDeductionModal(false);
    setDeductionRowEdit(null);

    if (isReload) {
      getListData();
    }
  };

  const handleRowClicked = (e) => {
    if (e.colDef?.field === "isChecked" || isBulkAction) {
      return;
    } else {
      setDeductionRowEdit(e.data);
      setShowDeductionModal(true);
    }
  };

  const handleBlukDeactivate = () => {
    setIsBulkAction(true);
    gridRef.current.columnApi.setColumnVisible("isChecked", true);
  };

  const onGridReady = (params: any) => {
    gridApi = params.api;
    gridColumnApi = params.columnApi;

    gridColumnApi.setColumnVisible("isChecked", false);
    // setGridApi(_gridApi)
    // setGridColumnApi(_gridColumnApi)
    // columnWidthSizeToFit();

    const scrollSelector = ".ag-body-horizontal-scroll";

    const scrollContainerSelector = ".ag-body-horizontal-scroll-container";

    // get scrollbar elements
    const scrollElement: any = document.querySelector(scrollSelector);

    const scrollContainerElement: any = document.querySelector(
      scrollContainerSelector
    );
  };

  const getAgGridOptions = () => {
    const gridOptions: GridOptions = {
      rowData: [],
      overlayNoRowsTemplate: "No data found",
      rowClass: "user-table-row",

      getRowHeight: (params) => {
        return 40;
      },
      headerHeight: 48,
      frameworkComponents: {
        valueRender: valueRender,
        amountRender: amountRender,
        exemptionsCellRenderer: exemptionsCellRenderer,
        checkBoxRender: checkBoxRender,
        deductionNameRender: deductionNameRender,
      },

      rowSelection: "multiple",
    };
    return gridOptions;
  };

  const deductionNameRender = (params: any) => {
    let field = params?.colDef?.field;
    return (
      <div className="deductionName d-flex align-center">
        <EllipsisWithTooltip placement="top">
          {params.data[field]}
        </EllipsisWithTooltip>
        {params.data.isDeleted === 1 && (
          <span className="badge color-grey-beta">Inactive</span>
        )}
      </div>
    );
  };
  const handleCheckbox = (params, checked) => {
    const currentData: any[] = [];

    gridRef?.current?.api?.forEachNode((node) => {
      if (node.data.uniqueno === params.data.uniqueno) {
        console.log(node.data);
        currentData.push({ ...node?.data, isChecked: checked });
      }
    });
    gridRef.current.api.applyTransaction({
      update: currentData,
    });

    handleSelectedRows();
  };

  const valueRender = (params: any) => {
    let field = params?.colDef?.field;

    return (
      <EllipsisWithTooltip placement="bottom">
        {params.data[field]}
      </EllipsisWithTooltip>
    );
  };

  const getAmountType = (ddCriteria = null) => {
    const val = ddCriteria;
    if (!val) {
      return false;
    }

    const isDollarValues = [
      "Fixed Amount",
      "Amount ($) Per Hour",
      "Amount ($) per Month",
      "Direct Entry",
    ];
    if (isDollarValues.includes(val)) {
      return "dollar";
    } else {
      return "percentage";
    }
  };

  const amountRender = (params: any) => {
    const amountType = getAmountType(params.data.deductionCri);

    let field = params?.colDef?.field;
    let amount = params.data[field];
    if (amountType === "dollar") {
      amount = Utils.currencyFormat(amount);
    } else {
      amount = Utils.currencyFormatWithOutdoller(amount) + "%";
    }

    return (
      <EllipsisWithTooltip placement="bottom">{amount}</EllipsisWithTooltip>
    );
  };
  const showHideChangeLogModal = (value: any) => {
    if (value === "View Past Changes") {
      setShowChangeLogModal(true);
      setShowChangeHistoryLogModal(true);
    } else {
      if (showChangeHistoryLogModal) {
        setShowChangeLogModal(true);
      } else {
        setShowChangeLogModal(!showChangeLogModal);
      }
      setShowChangeHistoryLogModal(false);
    }
  };

  //   showHideChangeLogModal = (value: any) => {
  //     this.setState({ showChangeLogModal: !this.state.showChangeLogModal });
  //     if (value === "View Past Changes") {
  //         this.setState({
  //             showChangeLogModal: true,
  //             showChangeHistoryLogModal: true
  //         });
  //     }
  // }

  const exemptionsCellRenderer = (params: any): any => {
    //

    const exempt = params?.data?.exempt
      ?.replaceAll(" ", "")
      ?.toString()
      ?.split(",");

    if (exempt?.length > 1) {
      exempt?.shift();

      const exemptionsToolTipData = exempt?.join(",  ");
      return (
        <div className="ehid-tag rm-bg d-flex align-items-center">
          <div className="exempt mr-1">
            {params?.data?.exempt?.toString()?.split(",")[0]}
          </div>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="exemptionsTool">{exemptionsToolTipData}</Tooltip>
            }
          >
            <button type="button" className="showalltags d-flex">
              <div className="more-exempt" style={{ paddingRight: "2px" }}>
                <span
                  className="data-eexemptions"
                  data-tip={exemptionsToolTipData}
                >
                  {/* <span>{exemptionsToolTipData}</span> */}+
                  {params?.data?.exempt?.toString()?.split(",").length - 1} More
                  {/* <ReactTooltip
                        effect="solid"
                        event="mouseover mouseenter"
                        className="tooltipSOS"
                        place="bottom"
                        eventOff="mouseleave mouseout scroll mousewheel blur"
                        multiline={true}
                        data-html={true}
                        wrapper="div"
                      /> */}
                </span>
              </div>
            </button>
          </OverlayTrigger>
        </div>
      );
    } else {
      return (
        <div>
          <span className="exempt">{params?.data?.exempt}</span>
        </div>
      );
    }
  };

  function checkBoxRender(params: any) {
    return (
      <Form.Check
        disabled={params.data.isDeleted == 1}
        onChange={(e) => handleCheckbox(params, e.target.checked)}
        type={"checkbox"}
        id={`default`}
        checked={params.data?.isChecked ? true : false}
        label={``}
      />
    );
  }

  const getUpdatedItemListDeduction = (list = selectedDeductionType) => {
    let data = [...StaticArrays.deductionTypeList].map((x) => ({
      description: x.label,
      value: x.label,
      isChecked: false,
    }));
    data = _.sortBy(data, [
      function (o) {
        return o.description;
      },
    ]);

    if (list.length > 0) {
      const valList = list.map((x) => x.value);
      data = data.map((x) => ({ ...x, isChecked: valList.includes(x.value) }));
    }

    setDeductionTypes(data);
  };

  const getUpdatedItemListTax = (list = selectedDeductionType) => {
    let data = [...StaticArrays.taxTypesList].map((x) => ({
      description: x.label,
      value: x.label,
      isChecked: false,
    }));
    data = _.sortBy(data, [
      function (o) {
        return o.description;
      },
    ]);

    if (list.length > 0) {
      const valList = list.map((x) => x.value);
      data = data.map((x) => ({ ...x, isChecked: valList.includes(x.value) }));
    }

    setTaxTypes(data);
  };

  return (
    <div id="payroll-deduction-setting-ui">
      <ToastContainer containerId={"addDeduction"} autoClose={3000} />
      {showDeductionModal && !isDataLoading && (
        <PayrollDeductionForm
          // userHotels={
          //   Object.keys(this.state.deductionRowEdit).length > 2
          //     ? this.props?.userGeneralInfo?.hids.filter(
          //         (x) =>
          //           x?.lettercode ===
          //           this.state.deductionRowEdit?.letterCode
          //       )
          //     : this.props?.userGeneralInfo?.hids

          userHotels={[]}
          showDeductionModal={showDeductionModal}
          hideDeductionModal={hideDeductionModal}
          userName={""}
          deductionsData={filteredData}
          deductionRowEdit={deductionRowEdit}
        />
      )}
      <Container
        fluid
        className={
          isBulkAction === true ? "body-sec bulkActionActive" : "body-sec"
        }
      >
        <div className="page-heading underline d-flex">
          <div className="mr-auto">Payroll Deduction Settings</div>
          <div className="action-group d-flex flex-row ml-auto">
            {!isBulkAction && (
              <Button onClick={handleAddDeduction}>
                Add Payroll Deduction
              </Button>
            )}
            {!isBulkAction && (
              <div className="header-popout">
                <Dropdown className="more-action" alignRight>
                  <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey="changeLog"
                      key="changeLog"
                      onClick={() => showHideChangeLogModal("")}
                    >
                      Change Log
                    </Dropdown.Item>
                    {filteredData?.filter((x) => x.isDeleted != 1)?.length >
                      0 && (
                      <Dropdown.Item onClick={() => handleBlukDeactivate()}>
                        Bulk Deactivate
                      </Dropdown.Item>
                    )}
                    {/* <Dropdown.Item onClick={()=>{}}>
                        {" "}
                        Save as Default View{" "}
                      </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>

        <div className="main-page">
          <div className="th-cover"></div>
          <div className="navigation-menus row">
            <div className="col-xl-9 pr-0">
              <div className="row">
                <div className="col-lg-3 col-md-4 pl-0">
                  <input
                    type="text"
                    value={searchText}
                    disabled={isBulkAction}
                    onChange={(e) => handleSearch(e)}
                    className="search-box form-control w-100"
                    placeholder="Search..."
                  />
                </div>
                <div className="col-md-4">
                  <div className="DeductionTypes-filter">
                    {DeductionTypes.length > 0 && (
                      <MultiSelectDropdown
                        key="deductionType"
                        dataChange={() => {}}
                        showLength={1}
                        disabled={isBulkAction}
                        itemList={
                          DeductionTypes?.length > 0 ? DeductionTypes : []
                        }
                        updateMultiSelectItem={(item) => {
                          if (!item) {
                            return;
                          }

                          setSelectedDeductionType(item);
                          // getUpdatedItemListDeduction(item)
                        }}
                        isSelectAll={true}
                        removeItem
                        placeholder={"Deduction Type"}
                        filtertitle={"Select Deduction Type"}
                      />
                    )}
 
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="TaxTypes-filter">
                    {TaxTypes.length > 0 && (
                      <MultiSelectDropdown
                        key="taxType"
                        dataChange={() => {}}
                        showLength={1}
                        disabled={isBulkAction}
                        itemList={TaxTypes?.length > 0 ? TaxTypes : []}
                        updateMultiSelectItem={(item) => {
                          if (!item) {
                            return;
                          }

                          setSelectedTaxType(item);
                          // getUpdatedItemListTax(item)
                        }}
                        isSelectAll={true}
                        removeItem
                        placeholder={"Tax Type"}
                        filtertitle={"Select Tax Type"}
                      />
                    )}
                     
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 pr-0 mt-2 mt-xl-0">
              <div className="d-flex align-items-center justify-content-end toggle">
                <FormLabel className="mb-0">Show Inactive</FormLabel>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  checked={activeStatus === "Inactive"}
                  disabled={isDataLoading}
                  onChange={(event: any) => onActiveStatusChange(event.target)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={filteredData.length <= 4 ? "hide-scrollbar" : ""}>
          <div
            id="myGrid"
            className={
              isBulkAction === true
                ? " ag-theme-balham static-header bulkactionHeight"
                : "ag-theme-balham static-header"
            }
          >
            {isDataLoading ? (
              <ReactPageLoader useas={"dashboardTable"} />
            ) : (
              <AgGridReact
                ref={gridRef}
                getRowNodeId={(data) => data.uniqueno}
                onGridReady={onGridReady}
                domLayout={"autoHeight"}
                rowData={[...filteredData]}
                columnDefs={gridColumns}
                gridOptions={getAgGridOptions()}
                suppressRowClickSelection
                onCellClicked={handleRowClicked}
              />
            )}
          </div>
        </div>

        {isBulkAction === true && (
          <div className="tableFooter TabFooter-Pay">
            <div className="bulkSelectFooter">
              {/* this.footerchk.current.indeterminate = false; */}
              <Form.Check
                type="checkbox"
                label=""
                id="footerBulkCheckBox"
                ref={(chkRef) => {
                  if (chkRef) {
                    checkAllRef = chkRef;
                    if (
                      selectedRows?.filter((x) => x.isDeleted != 1)?.length ===
                      filteredData?.filter((x) => x.isDeleted != 1)?.length
                    ) {
                      chkRef.checked = true;
                    } else {
                      chkRef.checked = false;
                    }

                    if (
                      selectedRows?.filter((x) => x.isDeleted != 1)?.length >
                        0 &&
                      !chkRef?.checked
                    ) {
                      chkRef.indeterminate = true;
                    } else {
                      chkRef.indeterminate = false;
                    }
                  }
                }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  footerBulkSelectAll(e);
                }}
              />
              <span className="shift-selected-count">
                {selectedRows?.length}{" "}
                {selectedRows?.length <= 1 && "Deduction"}
                {selectedRows?.length > 1 && "Deductions"} selected
              </span>
              <div className="action-group d-flex flex-row mr-auto">
                <Button
                  disabled={selectedRows?.length <= 0}
                  className="unassign btn-outline-primary deactivate-btn"
                  onClick={() => handleStatusChange()}
                >
                  Deactivate
                </Button>
              </div>
              <Button
                className="closeButton btn-ghost-light text-white"
                onClick={() => closeBulkAction()}
              >
                &times;
              </Button>
            </div>
          </div>
        )}

        {showChangeLogModal && (
          <PayrollDeductionLogHistory
            payPeriodStartDate={""}
            payPeriodEndDate={""}
            accType={"All-J"}
            showChangeLogModal={showChangeLogModal}
            showHideChangeLogModal={showHideChangeLogModal}
            hotelName={""}
            hotelId={0}
            hideCrossAndClearFilters={false}
            shiftId={0}
            shiftDate={""}
            noOfDays={0}
            showPerviousLogBanner={true}
          />
        )}

        {showChangeHistoryLogModal && (
          <HistoricalLogHistroy
            payPeriodStartDate={""}
            payPeriodEndDate={""}
            //accType={"All-J"}
            showChangeLogModal={showChangeHistoryLogModal}
            showHideChangeLogModal={showHideChangeLogModal}
            hotelName={""}
            hotelId={0}
            hideCrossAndClearFilters={false}
            shiftId={0}
            shiftDate={""}
            noOfDays={0}
            showPerviousLogBanner={false}
            accType={"PayrollDeductionHistorical"}
          />
        )}
      </Container>
    </div>
  );
}

export default PayrollDeductionSettingsList;
