import * as React from "react";
import { Form } from "react-bootstrap";
import { Utils } from "../../Common/Utilis";

export class AmountControl extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            amount: '',
        };
    }

    componentDidUpdate = (prevprops, prevState) => {
        const { defaultValue } = this.props;
        if ((prevprops.defaultValue !== defaultValue) && defaultValue === "") {
            this.setState({ amount: defaultValue }, () => {
                this.handleAmountInput();
            })
        }
        if (!defaultValue && prevprops?.defaultValue !== defaultValue) {
            this.setState({ amount: defaultValue }, () => {
                this.handleAmountInput();
            })
        }
    }

    componentDidMount = () => {
        const { defaultValue } = this.props;
        if (defaultValue === undefined) {
            this.handleAmountInput();
        } else {
            let newVal = Utils.removeInvaildCharFromAmount(defaultValue);
            this.setState({ amount: newVal }, () => {
                $("#amountInput").focus();
                setTimeout(() => { $("#amountInput").blur(); }, 150);
                this.handleAmountInput();
            })
        }
    };

    handleAmountInput = () => {
        this.setState({ amount: this.state.amount }, () => {
            this.props?.handleAmountVal(this.state.amount);
        })
    }

    handleValidAmount = (orderAmount) => {
        let newVal = Utils.removeInvaildCharFromAmount(orderAmount);
        orderAmount = newVal;
        const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
        let lenbeforePoint = 8;
        if (orderAmount.includes("-")) {
            lenbeforePoint = 9;
        }
        let validAmount: any;
        if (rx_live.test(orderAmount)) {
            if (orderAmount.includes(".")) {
                const timeArray = (orderAmount)?.split(".");
                if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
                    validAmount = orderAmount;
                }
            } else {
                if (orderAmount.length <= lenbeforePoint) {
                    validAmount = orderAmount;
                }
            }
        } else if (orderAmount.includes(".") && orderAmount.length === 1) {
            validAmount = orderAmount;
        }
        return validAmount
    }

    isValidOnBlurAmount = (e: any) => {
        let afterBlurAmount: any;
        if (!isNaN(e.target.value)) {
            if (e.target.value.includes(".")) {
                const timeArray = (e.target.value)?.split(".");
                if (timeArray[1].length <= 0) {
                    afterBlurAmount = Utils.currencyFormatWithOutdoller(timeArray[0]);;
                } else {
                    afterBlurAmount = Utils.currencyFormatWithOutdoller(e.target.value);
                }
            } else {
                afterBlurAmount = (e.target.value === '' ? '' : !isNaN(e.target.value) ? Utils.currencyFormatWithOutdoller(e.target.value) : e.target.value);
            }
        } else {
            afterBlurAmount = "";
        }
        return afterBlurAmount;
    }

    handleTabAmount = (event: any) => {
        let validAmount = (event.target.value === '' ? '' : !isNaN(event.target.value) ? Number(event.target.value).toFixed(2) : 0)
        if (event.key.toLowerCase() === "tab") {
            this.setState({ amount: validAmount }, () => {
                this.handleAmountInput();
            });
        }
    }

    handleFormChange = (event: any) => {
        let validAmount = this.handleValidAmount(event.target.value);
        this.setState({ amount: validAmount }, () => {
            this.handleAmountInput();
        });
    }

    handleBlurAmount = (event: any) => {
        let validAmount = this.isValidOnBlurAmount(event);
        this.setState({ amount: validAmount }, () => {
            this.handleAmountInput();
        });
    }

    onSplitAmountFocus = (event: any) => {
        let newval = Utils.removecurrencyFormat(event.target.value);
        let validAmount = newval;
        this.setState({ amount: validAmount }, () => {
            this.handleAmountInput();
        });
    }

    render() {
        const { amount } = this.state;
        return (
            <Form.Control
                type="text"
                maxLength={50}
                id="amountInput"
                placeholder="Enter amount"
                autoComplete="off"
                style={{"cursor":"text"}}
                onKeyDown={(e: any) => this.handleTabAmount(e)}
                onChange={(e: any) => this.handleFormChange(e)}
                onBlur={(e: any) => this.handleBlurAmount(e)}
                onFocus={(e: any) => this.onSplitAmountFocus(e)}
                value={amount}
            />
        );
    }
}