import React from "react";
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
// import PageLoader from "../../../../Common/Components/PageLoader";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { resolve, reject } from "q";
import GreyTickMark from "../../../../../Common/Assets/Images/icons/grey_tick_mark.svg";
// import { event } from "jquery";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import { Utils } from "../../../../../Common/Utilis";
export class RoomAssignmentsDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      positionID: 0,
      hidValue: props.hidValue,
      columnDetails: this.props.columnDetails.filter(
        (x) => x.serviceName !== "Denied"
      ),
      date: this.props.date,
      actualdate: "",
      assignmentsDetails: [],
      UserDetails: [],
      assignmentColumn: [],
      assignmentData: [],
      assignmentScheduleDetails: [],
      step: 1,
      loaderTrue: false,
      isEdit: true,
      shiftBreakTime: 0,
      exceedBreakTime: 0,
      IsBreakTimeSubracted: false,
      isMPOROptimize: false,
      row:this.props.row ? this.props.row : null
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleSaveComplete = this.handleSaveComplete.bind(this);
    this.handleScheduleUpdate = this.handleScheduleUpdate.bind(this);
    this.handleInputValue = this.handleInputValue.bind(this);
    this.onBlurInputValue = this.onBlurInputValue.bind(this);
    this.onFocusInputValue = this.onFocusInputValue.bind(this);
    this.serviceFortmatter = this.serviceFortmatter.bind(this);
  }

  handleScheduleUpdate = () => {
    this.setState({ step: 1 });
    this.loadAssignmentdetails();
  };

  handleSaveComplete = () => {
   // debugger;
    let assignmentScheduleDetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.assignmentScheduleDetails))
    );

    let isMassage=false;
    for (let i=0; i < assignmentScheduleDetails.length;i++){
     if(assignmentScheduleDetails[i].scheduleOutDate !==assignmentScheduleDetails[i].newPunchOut)
     {
      if (this.state.isMPOROptimize) {
      isMassage=true;
      }
      break;
     }
    }
  //  debugger;
    if(isMassage===true){
    this.isValidateModelPoupTab(); 
    }
    if(isMassage===false){
      if (this.state.isMPOROptimize) {
        this.optimizeMPORShift();
      } else {
        toast.success("Room Assignments successfully added.", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
        setTimeout(() => {
          this.props.refreshBindApproveData();
        }, 2000);
      }
    }
  };

  isValidateModelPoupTab() {
    confirmAlert({
     // title: "Unsaved confirmation",
      message:
        "One or more affected employees have already clocked into to their shift."+
         " These changes will update their schedule(s). Are you sure you want to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleSaveCompleteChanges(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "housekeeping-modal-alert",
    });
  }



  handleSaveCompleteChanges = () => {
    if (this.state.isMPOROptimize) {
      this.optimizeMPORShift();
    } else {
      toast.success("Room Assignments successfully added.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
      setTimeout(() => {
        this.props.refreshBindApproveData();
      }, 2000);
    }
  };


  optimizeMPORShift = () => {
    // This function called when we want to optimize the shift's
    let request: any[];
    request = this.state.assignmentScheduleDetails.map(
      (item: {
        employee: any;
        scheduleInDateMP: any;
        scheduleOutDateMP: any;
        scheduleInTimeMP: any;
        newPunchOut: any;
        actualHours: any;
        minusBreak: any;
        scheduleno: any;
        shiftUniqueNO: any;
        showPopup: any;
      }) => {
        const newItem: any = {
          Hid: this.state.hidValue,
          UserName: item.employee,
          SchInDate: item.scheduleInDateMP.replace("T", " "),
          SchOutDate: item.scheduleOutDateMP.replace("T", " "),
          SchInTime: item.scheduleInTimeMP,
          SchOutTime: item.newPunchOut,
          HRS: item.actualHours,
          MinusBreak: item.minusBreak,
          IsUserPunchIn: item.scheduleno === "0" ? "No" : "Yes",
          ShiftUniqueNo: item.shiftUniqueNO,
          showPopup: item.showPopup,
        };
        return newItem;
      }
    );
    request = request.filter((e) => e.showPopup === false);
    request = request.map(function (obj) {
      return {
        Hid: obj.Hid,
        UserName: obj.UserName,
        SchInDate: obj.SchInDate,
        SchOutDate: obj.SchOutDate,
        SchInTime: obj.SchInTime,
        SchOutTime: obj.SchOutTime,
        HRS: obj.HRS,
        MinusBreak: obj.MinusBreak,
        IsUserPunchIn: obj.IsUserPunchIn,
        ShiftUniqueNo: obj.ShiftUniqueNo,
      };
    });
    //delete request["showPopup"];
    ///request= _.omit(request,'showPopup');

    // console.log(request);

    this.setState({ loaderTrue: true });
    LaborDayApprove.optimizeMPORShift(request)
      .then(async (result: any | null) => {
        if (result.result.success) {
          toast.success("Room Assignments successfully added.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });
          setTimeout(() => {
            this.props.refreshBindApproveData();
          }, 2000);
        } else {
          Utils.toastError("Something went wrong.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  handleSave = () => {    
    if (this.state.step === 1) {
      let saveData: any[] = [];
      let assignmentData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.assignmentData))
      );
      for (let i = 0; i < this.state.UserDetails.length; i++) {
        let userName = this.state.UserDetails[i].userName;
        let userUniqueID = this.state.UserDetails[i].userUniqueID;
        let positionID = this.state.UserDetails[i].positionID;
        let filterService = assignmentData.filter(
          (e) => e.userName === userName
        );
        for (let j = 0; j < this.state.columnDetails.length; j++) {
          let storage = JSON.parse(localStorage.getItem("storage")!);
          let tenantID = storage === null ? 0 : (storage.tenantID as any);
          let newData: any = {};
          let typeID = this.state.columnDetails[j].typeID;
          newData.tenantID = tenantID;
          newData.hid = this.state.hidValue;
          newData.userName = userName;
          newData.userUniqueID = Number(userUniqueID);
          newData.positionID = positionID;
          newData.typeID = Number(typeID);
          newData.serviceValue = Number(filterService[0][typeID]);
          newData.date = this.state.actualdate;

          saveData.push(newData);
        }
      }
      this.saveRoomAssignmentDetails(saveData);
    } else {
      this.setState({ step: 3 });
    }
  };

  saveRoomAssignmentDetails(request: any[]) {
    this.setState({ loaderTrue: true });
    LaborDayApprove.LaborRoomAssignmentSave(request)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.result.message === "saved") {
            this.ScheduleUpdateDetails();
            this.setState({ isDataChange: false });
            // toast.success("Room Assignments successfully added.", {
            //     // // position: toast.POSITION.BOTTOM_RIGHT,
            // });
            // setTimeout(() => {
            //     this.props.refreshBindApproveData();

            // }, 2000);
          } else {
            Utils.toastError(result.result.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  // ScheduleUpdateDetails() {
  //     let request = {} as any;
  //     request.hotelID = this.state.hidValue;
  //     request.date = this.state.actualdate;
  //     LaborDayApprove.LaborRoomAssignmentSchedule(request)
  //         .then(async (result: any[] | null) => {
  //             if (result != null) {
  //                 if (result.length > 0) {
  //                     this.setState({ step: 2 });
  //                     this.setState({ assignmentScheduleDetails: result });
  //                 }
  //             }
  //             this.setState({ loaderTrue: false });
  //             resolve();
  //         })
  //         .catch((error) => {
  //             reject();
  //             this.setState({ loaderTrue: false });
  //         });
  // }

  ScheduleUpdateDetails() {
    let request = {} as any;
    request.hotelID = this.state.hidValue;
    request.date = this.state.actualdate;
    LaborDayApprove.LaborRoomAssignmentSchedule(request)
      .then(async (result: any[] | null) => {
        if (result != null) {
          if (result.length > 0) {
            result = result.filter((e) => e.employee_RowNo === 1);
            this.setState({ assignmentScheduleDetailsCoreData: result });
            //if (this.state.isMPOROptimize) {
            for (let index = 0; index < result.length; index++) {
              result[index]["minusBreak"] = "No"; // add a key to store MinusBreak value

              if (result[index].canOptimise === 0)
                result[index]["showPopup"] = true;
              if (result[index].canOptimise === 1)
                result[index]["showPopup"] =
                  result[index].scheduleno !== "0" &&
                  result[index].totalTime === "0:00"
                    ? true
                    : false; // This is for showing a tool tip msg for those user who already punchin and have zero assigned room

              let a = result[index].totalTime.split(":");
              result[index]["actualHours"] = result[index].totalTime.replace(
                ":",
                "."
              );
              let workingMinutes = +a[0] * 60 + +a[1];
              let exceedMinutes = this.state.exceedBreakTime * 60;
              if (workingMinutes >= exceedMinutes && exceedMinutes !== 0) {
                let newOutTime = new Date(result[index].scheduleInDateMP);
                let breakPerHour =
                  parseInt(this.state.shiftBreakTime) /
                  parseInt(this.state.exceedBreakTime);


                // newOutTime.setMinutes(
                //   newOutTime.getMinutes() +
                //     breakPerHour * Math.abs(parseInt(result[index].totalTime)) +
                //     workingMinutes
                // );

                newOutTime.setMinutes(
                  newOutTime.getMinutes() +
                  parseInt(this.state.shiftBreakTime)  +
                    workingMinutes
                );

                let outDate = newOutTime;
                let currentdate: any = outDate.getDate();
                let currentMonth: any = outDate.getMonth() + 1;
                let currentYear = outDate.getFullYear();

                currentdate =
                  currentdate < 10 ? `0${currentdate}` : currentdate;
                currentMonth =
                  currentMonth < 10 ? `0${currentMonth}` : currentMonth;

                let hours = newOutTime.getHours();
                let minute = newOutTime.getMinutes();

                result[index].scheduleOutDateMP =
                  currentYear +
                  "-" +
                  currentMonth +
                  "-" +
                  currentdate +
                  "T" +
                  `${hours}:${minute}:00`;

                const ampm = hours >= 12 ? "PM" : "AM";
                let mm: any = minute;
                hours %= 12;
                hours = hours || 12;
                let TTminute: any = mm < 10 ? `0${mm}` : mm;
                let TTHour: any = hours < 10 ? `0${hours}` : hours;

                result[index].newPunchOut = `${TTHour}:${TTminute} ${ampm}`;
                /////////////  This code for add break in total time ///////////////////////
                //   let totalWorkedMinute =
                //     workingMinutes +
                //     this.state.shiftBreakTime *
                //       Math.abs(parseInt(result[index].totalTime));
                //   let rhours = totalWorkedMinute / 60;
                //   let totalNewHours: any = Math.floor(rhours);
                //   let newMinute: any = (rhours - totalNewHours) * 60;
                //   totalNewHours =
                //     totalNewHours < 10 ? `0${totalNewHours}` : totalNewHours;
                //     newMinute=Math.round(newMinute);
                //   newMinute = newMinute < 10 ? `0${newMinute}` : newMinute;

                //   result[index].totalTime =
                //     totalNewHours + ":" + newMinute;

                result[index].minusBreak = "Yes";
              }
            }
            //}
            this.setState({ step: 2 });
          //  debugger;
            this.setState({ assignmentScheduleDetails: result });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  loadAssignmentdetails() {
    
    this.setState({ loaderTrue: true });
    let request = {} as any;
    request.hotelID = this.state.hidValue;
    LaborDayApprove.LaborRoomAssignment(request)
      .then(async (result: any[] | null) => {
        if (result != null) {
          if (result.length > 0) {
            let UserDetails: any[] = [];
            result.forEach((element) => {
              let users: any = {};
              users.userName = element.employee;
              users.userUniqueID = element.userUniqueID;
              users.positionID = element.position;
              users.empStatus = element.empStatus;
              let findUser = UserDetails.filter(
                (e) => e.userName === element.employee
              );
              if (findUser.length === 0) {
                UserDetails.push(users);
              }
            });

            let assignmentData: any[] = [];
            for (let j = 0; j < UserDetails.length; j++) {
              let data: any = {};
              data.userName = UserDetails[j].userName;
              //data.totalAssigned = result[j].totalAssign;
              //data.totalTime = result[j].totalTime;
              for (let i = 0; i < this.state.columnDetails.length; i++) {
                let userfilter: any[] = [];
                let cellValue: any[] = [];
                userfilter = result.filter(
                  (x) => x.employee === UserDetails[j].userName
                );
                cellValue = userfilter.filter(
                  (x) => x.typeID === this.state.columnDetails[i].typeID
                );
                if(cellValue.length>0)
                {
                data[this.state.columnDetails[i].typeID] =
                  cellValue[0].serviceValue;
                data.totalAssigned = cellValue[0].totalAssign;
                data.totalTime = cellValue[0].totalTime;
                data.empStatus = cellValue[0].empStatus;
                }
              }
              assignmentData.push(data);
            }

            this.setState({
              assignmentsDetails: result,
              actualdate: result[0].date,
              shiftBreakTime: result[0].breakTime,
              exceedBreakTime: result[0].exceedBreakTime,
              IsBreakTimeSubracted: result[0].isBreakTimeSubstract,
              isMPOROptimize: result[0].isMPOROptimize,
              UserDetails: UserDetails,
              assignmentData: assignmentData,
              isEdit: result[0].isEdit.toLowerCase() === "yes" ? true : false,
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  componentDidMount() {
    this.loadAssignmentdetails();
  }

  scheduleUpdateDetails() {
    this.setState({ step: 2 });
  }

  handleInputValue = (
    e: any,
    row: any,
    rowIndex: any,
    typeID: any,
    txtID: any
  ) => {
    let val = e.target.value;
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.assignmentData))
    );

    if (/^\d+$/.test(val) === false) {
      val = val.replace(/[^\d]/g, "");
    }
    if (val.length > 3) {
      let a = val.replace(/[^\d]/g, "");
      val = a.substring(0, val.length - 1);
    }

    data[rowIndex][typeID] = val;

    this.setState({ assignmentData: data }, () => {
      $("#tblHousekeeping")
        .find("input[id^='" + txtID + "']")
        .focus();
    });
  };

  onBlurInputValue(e: any, row: any, rowIndex: any, typeID: any, txtID: any) {
    let newValue = e.target.value;

    let totalAssign = 0;
    let totalMin = 0;
    for (let i = 0; i < this.state.columnDetails.length; i++) {
      totalAssign =
        totalAssign + Number(row[this.state.columnDetails[i].typeID]);
      totalMin =
        totalMin +
        Number(row[this.state.columnDetails[i].typeID]) *
          Number(this.state.columnDetails[i].mporValue);
    }
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.assignmentData))
    );
    let index = data.findIndex((e) => e.userName === row.userName);
    let colName = typeID;
    data[index][colName] = newValue === "" ? 0 : newValue;
    data[index].totalAssigned = totalAssign;
    data[index].totalTime = this.FormatHoursMinutes(totalMin);
    this.setState({ assignmentData: data });

    $("#tblHousekeeping")
      .find("input[id^='" + txtID + "']")
      .closest("tr")
      .removeClass("focus-row");
  }

  onFocusInputValue(e: any, row: any, rowIndex: any, typeID: any, txtID: any) {
    // let val = e.target.value

    $("#tblHousekeeping")
      .find("input[id^='" + txtID + "']")
      .addClass("focus");
    $("#tblHousekeeping")
      .find("input[id^='" + txtID + "']")
      .closest("tr")
      .addClass("focus-row");
  }

  serviceFortmatter(cell: any, row: any, rowIndex: any, columnName: any) {
    let txtID = "";
    let typeID = columnName.split("_")[1];
    txtID = "text" + rowIndex.toString() + typeID.toString();
    return (
      // <div className="form-control">
      //     <EllipsisWithTooltip placement="bottom">
      //         {cell}
      //     </EllipsisWithTooltip>
      // </div>

      <div className={"forecast-name tab-key"}>
        <Form.Group>
          <Form.Control
            //ref={this.inputRow}
            disabled={row.empStatus === "Deleted"}
            maxLength={3}
            type="text"
            key={txtID}
            id={txtID}
            onChange={(event: any) =>
              this.handleInputValue(event, row, rowIndex, typeID, txtID)
            }
            onBlur={(event: any) =>
              this.onBlurInputValue(event, row, rowIndex, typeID, txtID)
            }
            onSelect={(event: any) =>
              this.onFocusInputValue(event, row, rowIndex, typeID, txtID)
            }
            value={cell}
          />
        </Form.Group>
      </div>
    );
  }
  serviceHeaderFortmatter(column, colIndex) {
    return (
      <EllipsisWithTooltip placement="bottom">
        <div>{column.text}</div>
      </EllipsisWithTooltip>
    );
  }

  actionFortmatter(cell: any, row: any, rowIndex: any, cellIndex) {
    return (
      <Dropdown className="more-action" alignRight>
        <Dropdown.Toggle
          className="btn-outline-primary btn btn-primary more"
          id="dropdown-more"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
          </svg>
        </Dropdown.Toggle>
        {/* <Dropdown.Menu>
                        <Dropdown.Item eventKey={"Dummy Text"}>Dummy Text</Dropdown.Item>
                    </Dropdown.Menu> */}
      </Dropdown>
    );
  }

  EmployeeNameFormatter(cell: any, row: any, rowIndex: any, cellIndex) {
    
    return (
      <div className="d-flex">
        <EllipsisWithTooltip placement="bottom">
          {row.userName}
        </EllipsisWithTooltip>

        {row?.empStatus === "Deleted" && (
          <span className="red-badges ml-2">Deleted</span>
        )}
      </div>
    );
  }

  cellEdit = cellEditFactory({
    mode: "click",
    blurToSave: true,
    afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
      let totalAssign = 0;
      let totalMin = 0;
      for (let i = 0; i < this.state.columnDetails.length; i++) {
        totalAssign =
          totalAssign + Number(row[this.state.columnDetails[i].typeID]);
        totalMin =
          totalMin +
          Number(row[this.state.columnDetails[i].typeID]) *
            Number(this.state.columnDetails[i].mporValue);
      }
      let data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.assignmentData))
      );
      let index = data.findIndex((e) => e.userName === row.userName);
      let colName = column.dataField;
      data[index][colName] = newValue === "" ? 0 : newValue;
      data[index].totalAssigned = totalAssign;
      data[index].totalTime = this.FormatHoursMinutes(totalMin);
      this.setState({ assignmentData: data });
    },
  });

  totalTimeFooter = (columnData: any) => {
    let total = 0;
    columnData.map((data: any) => {
      if (data !== null) {
        if (data !== undefined) {
          if (data.trim().length > 0) {
            if (data.includes(":")) {
              let subTotal = 0;
              const dataArray = data.split(":");
              let HH = Number(dataArray[0]);
              let MM = Number(dataArray[1]);
              if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
                HH = HH < 0 ? HH * -1 : HH;
                MM = MM < 0 ? MM * -1 : MM;
                subTotal = (HH * 60 + MM) * -1;
              } else {
                subTotal = HH * 60 + MM;
              }
              total = total + subTotal;
            }
          }
        }
      }
    });
    return this.FormatHoursMinutes(total);
  };

  FormatHoursMinutes(HoursMints: number) {
    if (HoursMints < 0) {
      HoursMints = HoursMints * -1;
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${"-"}${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    } else {
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    }
  }
  rowEvents = {
    onBlur: (e: { target: { value: string | number } }) => {
      if (e.target.value === "") {
        e.target.value = 0;
      }
    },
    onChange: (e: { target: { value: any } }, cell: any) => {
      let evalue = e.target.value;
      if (evalue === "") {
      } else if (/^\d+$/.test(evalue) === false) {
        e.target.value = 0;
      } else {
        if (evalue.length > 3) {
          e.target.value = evalue.toString().substring(0, 3);
        } else {
          e.target.value = evalue;
        }
      }
    },
  };

  render() {
    const rowClassesStep1 = (row, rowIndex) => {
      let classes: string | null=null;
    
      if (row.empStatus === "Deleted") {
        classes = 'hsk-deleted-row';
      }
    
      return classes;
    };



    let column: any[] = [];
    let col: any = {};
    col.dataField = "userName";
    col.text = "Username";
    col.footer = "Totals";
    col.editable = false;
    col.formatter = this.EmployeeNameFormatter;
    column.push(col);
    for (let i = 0; i < this.state.columnDetails.length; i++) {
      col = {};
      col.dataField = this.state.columnDetails[i].typeID;
      col.text = this.state.columnDetails[i].serviceName;
      col.editable = true;
      col.headerFormatter = this.serviceHeaderFortmatter;
      col.formatter = this.serviceFortmatter;
      col.footer = (columnData) =>
        columnData.reduce((acc, item) => Number(acc) + Number(item), 0);
      col.formatExtraData = i + 1 + "_" + this.state.columnDetails[i].typeID;
      column.push(col);
    }

    col = {};
    col.dataField = "totalAssigned";
    col.text = "Total Assigned";
    col.editable = false;
    col.footer = (columnData) =>
      columnData.reduce((acc, item) => Number(acc) + Number(item), 0);
    column.push(col);

    col = {};
    col.dataField = "totalTime";
    col.editable = false;
    col.text = "Total Time";
    col.footer = this.totalTimeFooter;
    column.push(col);

    col = {};
    col.dataField = "";
    col.editable = false;
    col.text = "";
    col.footer = "";
    col.formatter = this.actionFortmatter;

    const columnSchedule = [
      {
        dataField: "employee",
        text: "Username",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            {row.employee}
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "totalTime",
        text: "Total Time",
      },
      {
        dataField: "scheduleOutDate",
        text: "Schedule Punch Out",
      },
      {
        dataField: "newPunchOut",
        text: "New Punch Out",
      },
    ];

    let buttonDisabled = false;
    if(this.state.row !== null){
      const {row } = this.state;
      if(row.occupiedRoom === "" || row.occupiedRoom === null || this.state.assignmentData.length === 0){
        buttonDisabled = true
      }
    }

    const renderTooltip = (props) => {
      return (
        <Tooltip id="tooltip-top" {...props}>
          Occupied Rooms can't be blank.
        </Tooltip>
      );
    };

    const renderhideTooltip = (props) => {
      return <div id="tooltip-bottom" {...props}></div>;
    };

    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"roomsAssignedExpand"} />
        ) : (
          <div className="laborDayApprovalDetails roomAssignmentsDetails">
            <div className="laborDayNavigation">
              <div className="labordayNavigationlabels">
                {this.state.step === 1 && (
                  <>
                    <span className="labordayNavigationSpan active">
                      <span className="labordayNavigationNumber">1</span>
                      <span className="labordayNavigationLabel">
                        Assignments
                      </span>
                    </span>
                    <span className="laborDayApprovalDesign"></span>
                    <span className="labordayNavigationSpan">
                      <span className="labordayNavigationNumber">2</span>
                      <span className="labordayNavigationLabel">
                        Schedule Update
                      </span>
                    </span>
                  </>
                )}
                {this.state.step === 2 && (
                  <>
                    <span className="labordayNavigationSpan disable">
                      <span className="labordayNavigationNumber">
                        <span className="tickMark">
                          <img src={GreyTickMark} alt="GreyTickMark" />
                        </span>
                      </span>
                      <span className="labordayNavigationLabel">
                        Assignments
                      </span>
                    </span>
                    <span className="laborDayApprovalDesign"></span>
                    <span className="labordayNavigationSpan active">
                      <span className="labordayNavigationNumber">2</span>
                      <span className="labordayNavigationLabel">
                        Schedule Update
                      </span>
                    </span>
                  </>
                )}
              </div>
              <div className="labordayNavigationbuttons back">
                {this.state.step === 2 && (
                  <button
                    type="button"
                    className="btn-outline-primary btn btn-primary"
                    onClick={this.handleScheduleUpdate}
                  >
                    Back
                  </button>
                )}
                {this.state.step === 2 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSaveComplete}
                  >
                    Continue
                  </button>
                )}

                {this.state.step === 1 && this.state.isEdit && (
                   <OverlayTrigger placement="bottom" 
                   overlay={buttonDisabled && (this.state.row.occupiedRoom === "" || this.state.row.occupiedRoom === null) ? renderTooltip : renderhideTooltip}
                   >
                      <span className="d-inline-block">
                  <button
                    type="button"
                    style={{pointerEvents:buttonDisabled?'none':'auto'}}
                    className="btn btn-primary"
                    onClick={this.handleSave}
                    disabled={buttonDisabled}
                  >
                    Continue
                  </button>
                  </span>
                </OverlayTrigger>
                )}
              </div>
              {/* <button type="button" className="btn-outline-primary btn btn-primary" onClick={this.handleSave}>Save</button> */}
            </div>
            <div className="overBudgetDeptsTable table-responsive assignmentData">
              {this.state.step === 1 && (
                <BootstrapTable
                  id="tblHousekeeping"
                  keyField="userName"
                  data={this.state.assignmentData}
                  columns={column}
                  rowClasses={ rowClassesStep1 }
                  // cellEdit={this.cellEdit}
                  //rowEvents={this.rowEvents}
                  hover
                />
              )}
              {this.state.step === 2 && (
                <div className="scheduleUpdate">
                  {/* <div className="info-bg">
                                    <div className="title">Auto Scheduling is enabled.</div>
                                    <div className="desc">Continuing will update each user's schedule to reflect the new scheduled Punch Out time, and a notification will be sent to each user alerting them to this update.</div>
                                </div> */}
                  <BootstrapTable
                    keyField="id"
                    data={this.state.assignmentScheduleDetails}
                    columns={columnSchedule}
                  />
                </div>
              )}
            </div>
          </div>
        )}{" "}
      </>
    );
  }
}
