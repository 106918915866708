import * as React from "react";
import { Container, Form, Dropdown, Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { IHotelResponseDto } from "../../../Common/Contracts/IHotel";
import { Hotel as hotel } from "../../../Common/Services/Hotel";

import { resolve, reject } from "q";
import { TimeClockDeviceDetail } from "../../../Common/Services/TimeClockDeviceDetails";
import {
  IClockCookieSaveDto,
  IClockCookieUpdateDeleteDto,
} from "../../../Common/Contracts/ITimeClockDeviceDetails";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../../Common/Services/RoleSubPermissionSetting";
import PageLoader from "../../../Common/Components/PageLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutsideClickHandler from "react-outside-click-handler";
import DatePicker from "react-datepicker";
import { Cookies } from "react-cookie";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { User } from "../../../Common/Services/User";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { HidSelector } from "../../../Common/Components/HidSelector";
import { Pendo } from "../../../../src/Common/ThirdPartyServices/Pendo";
import { InfoTooltip } from "../../../Common/Components/InfoTooltip";
//import { AddToHomescreen } from "react-add-to-homescreen";
import { LaborSchedule } from "../../../Common/Services/LaborSchedule";
import { IBufferTimeAndMissedPunchOutAlertEditRequest } from "../../../Common/Contracts/ILaborSchedule";
import { Utils } from "../../../Common/Utilis";

const cookies = new Cookies();
let expirationTimePeriod: any;
export class TimeClockSetting extends React.Component<any, any> {
  private childHID: any;
  private autoFocusTime: any;
  constructor(props: any) {
    super(props);
    this.autoFocusTime = React.createRef();
    this.state = {
      tableDate: [],
      timeClockDataTable: [],
      tablekey: "text",
      hidList: [],
      hidFilterList: [],
      hidValue: "Select",
      hid: 0,
      loaderTrue: false,
      search: "",
      operationType: "",
      addWebTimeClockModal: false,
      currentDomain: window.location.hostname,
      cookieDescription: "",
      descriptionClass: "form-control",
      dateClass:
        "form-control schduleInput react-datepicker-ignore-onclickoutside",
      cookieDate: "",
      cookieTime: "",
      timeClass: "html-time-picker",
      buttonText: "Save",
      isButtonDisabled: false,
      isUpdateorInsert: "",
      modelHeaderTitle: "Add Web Time Clock",
      isManageTimeClockPermission: false,
      isPagePermission: false,
      isReadyToSave: true,
      missPunchOutAlert: false,
      sendToAllGM: false,
      bufferTime: 0,
    };
  }

  componentDidMount() {
    this.setState({ loaderTrue: true });
    this.getWebTimeClockPermission();
    hotel
      .getUserHotelAccess("Labor Management")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          this.setState({ hidList: result });

          this.setState({ hidFilterList: result });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });

    if (this.state.hidValue === "Select") {
      this.setState({ isUnderline: "page-heading d-flex" });
    } else {
      this.setState({ isUnderline: "page-heading underline d-flex" });
    }
  }



  getWebTimeClockPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10035;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionID) {
              case 10047:
                this.setState({ isManageTimeClockPermission: true });
            }
          });
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "scheduleMain",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "scheduleMain",
        });
        reject();
      });
  };

  getBufferTimeAndMissedPunchOutAlert = (hotelDetails: any) => {
    this.setState({ loaderTrue: true });
    LaborSchedule.getBufferTimeAndMissedPunchOutAlert(hotelDetails.hotelID)
      .then((result: any | null) => {
        this.setState({ loaderTrue: false });
        if (result != null) {
          this.setState({
            missPunchOutAlert: result?.result?.missPunchOutAlert || false,
            sendToAllGM: result?.result?.sendToAllGM || false,
            bufferTime: result?.result?.bufferTime || 0,
          });
        }
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
      });
  };


  selectHotelDropDown = (hotelDetails: any) => {
    this.getTimeClockDeatils(hotelDetails);
    this.getBufferTimeAndMissedPunchOutAlert(hotelDetails);
    this.setState({
      hotelName: hotelDetails.lettercode,
      hidValue: hotelDetails.hotelID,
    });
  };

 

  getTimeClockDeatils = (hotelDetails: any) => {
    this.setState({ loaderTrue: true });

    TimeClockDeviceDetail.TimeClockDeviceDetails(hotelDetails.hotelID)
      .then(async (result: any | null) => {
        let TimeClockData = [];
        this.setState({ loaderTrue: false, hid: hotelDetails.hotelID });
        if (result != null && result.length > 0) {
          let Data = result as [];
          Data.map((res, index) => TimeClockData.push(res));
          this.setState({ tableDate: TimeClockData });
          // } else {
          //   this.setState({ tableDate: TimeClockData });
          //   toast.success("No machine id found.", {
          //     // // position: toast.POSITION.BOTTOM_RIGHT,
          //   });
        }
        this.getTimeClockCookieData();
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  getTimeClockCookieData = () => {
    TimeClockDeviceDetail.GetTimeClockCookieDetails(this.state.hid)
      .then(async (result: any | null) => {
        this.setState({ loaderTrue: false });
        if (result.message === "Success") {
          this.setState({ timeClockDataTable: result.result });
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  hasCheck = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
          fill="#2CAF92"
        />
      </svg>
    );
  };

  searchClear = () => {
    this.setState({ search: "" });
    this.setState({ hidList: this.state.hidFilterList });
  };
  searchItems = (e) => {
    const {
      // hidList,
      hidFilterList,
    } = this.state;
    let result = e.target.value;
    this.setState({ search: result });
    if (result !== "") {
      this.setState({
        hidList: hidFilterList.filter((hName) =>
          hName.hotelName.toLowerCase().includes(result.toLowerCase().trim())
        ),
      });
    } else {
      this.setState({ hidList: this.state.hidFilterList });
    }
  };

  openNewWebClockModal = () => {
    let uniqueID = "";
    uniqueID = cookies.get("WebTimeClock");
    if (uniqueID === undefined) uniqueID = "";

    TimeClockDeviceDetail.GetCookieTimePeriod(uniqueID, this.state.hid)
      .then(async (result: any | null) => {
        if (result.message === "Success") {
          var date = new Date();
          if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
            date = new Date(
              JSON.parse(localStorage.storage).currentUtcTime
            );
          }
          expirationTimePeriod = date.setDate(
            date.getDate() + (parseInt(result.result) - 1)
          );
          if (result.result === "OtherHIDCookie") {
            Utils.toastError(
              "Two web time clocks of different EHIDs cannot exist on the same physical computer.",
              {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "TimeClockSetting",
              }
            );
          } else if (result.result.split("-")[0] === "Exists") {
            confirmAlert({
              title: "Web Time Clock already exists on this system.",
              message: "Do you want to update?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => this.updateExistCookies("Yes", uniqueID),
                },
                {
                  label: "No",
                  onClick: () => this.updateExistCookies("No", ""),
                },
              ],
            });
          } else {
            this.setState({
              addWebTimeClockModal: true,
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  };

  updateExistCookies = (actionType, GUID) => {
    if (actionType === "Yes") {
      let uniqueID = "";
      let oldCreatedDate = "";
      uniqueID = cookies.get("WebTimeClock");

      if (uniqueID === undefined) uniqueID = "";

      if (uniqueID !== "" && uniqueID !== undefined) {
        let rowData = {} as any;
        rowData = this.state.timeClockDataTable.filter(
          (items) => items.uniqueID === uniqueID
        );
        if (rowData.length > 0) {
          oldCreatedDate = rowData[0].createdDate.split(" ")[0];
        }
      }
      this.setState({ modelHeaderTitle: "Update Web Time Clock" });

      TimeClockDeviceDetail.GetCookieTimePeriod(uniqueID, this.state.hid)
        .then(async (result: any | null) => {
          if (result.message === "Success") {
            var date = new Date();
            if (JSON.parse(localStorage.storage).currentUtcTime !== "" && JSON.parse(localStorage.storage).currentUtcTime !== null && JSON.parse(localStorage.storage).currentUtcTime !== undefined) {
              date = new Date(
                JSON.parse(localStorage.storage).currentUtcTime
              );
            }
            if (oldCreatedDate === "") {
              expirationTimePeriod = date.setDate(
                date.getDate() + (parseInt(result.result) - 1)
              );
            } else {
              let dd = date.getDate();
              let mm = date.getMonth() + 1;
              var yyyy = date.getFullYear();
              let today = mm + "/" + dd + "/" + yyyy;
              let date1 = new Date(oldCreatedDate);
              let date2 = new Date(today);
              let Difference_In_Time = date2.getTime() - date1.getTime();
              let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
              expirationTimePeriod = date.setDate(
                date.getDate() +
                (parseInt(result.result.split("-")[1]) -
                  (1 + Difference_In_Days))
              );
            }
          }
        })
        .catch((error) => {
          reject();
          this.setState({ loaderTrue: false });
        });

      if (uniqueID !== GUID) {
        Utils.toastError(
          "This web time clock url has been generated from another system, Please return to the same system to edit it.",
          {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "TimeClockSetting",
          }
        );
      } else {
        let rowData = {} as any;
        rowData = this.state.timeClockDataTable.filter(
          (items) => items.uniqueID === GUID
        );
        this.setState({
          buttonText: "Update",
          addWebTimeClockModal: true,
          cookieDescription: rowData[0].commnets,
          cookieDate: new Date(Date.parse(rowData[0].expiryDate.split(" ")[0])),
          cookieTime: rowData[0].expiryDate.split(" ")[1],
        });
      }
    }
  };

  newWebClockModalClose = () => {
    this.setState({
      isButtonDisabled: false,
      addWebTimeClockModal: false,
      cookieDescription: "",
      descriptionClass: "form-control",
      dateClass:
        "form-control schduleInput react-datepicker-ignore-onclickoutside",
      cookieDate: "",
      cookieTime: "",
      timeClass: "html-time-picker",
      modelHeaderTitle: "Add Web Time Clock",
    });
  };

  onTextChange = (e: any) => {
    if (e.target.value.length < 201) {
      this.setState({
        cookieDescription: e.target.value,
        descriptionClass: "form-control",
      });
    } else {
      Utils.toastError("Max 200 characters allowed.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "TimeClockSetting",
      });
    }
  };

  handleSelectDate = (e: any) => {
    let { cookieDate } = this.state;
    cookieDate = e;
    this.setState({ cookieDate });
    this.setState({
      cookieTime: "",
      //cookieDate: e,
      dateClass:
        "form-control schduleInput react-datepicker-ignore-onclickoutside",
    });
  };

  checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  expireTime = (e: any) => {
    if (this.state.cookieDate === "") {
      Utils.toastError("Please select date first.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "TimeClockSetting",
      });
      return;
    } else {
      this.setState({
        cookieTime: e.target.value,
        timeClass: "html-time-picker",
      });
    }
  };

  generateLink = () => {
    if (this.state.isReadyToSave) {
      if (
        this.state.cookieDescription.trim() !== "" &&
        this.state.cookieDescription !== undefined
      ) {
        this.setState({
          descriptionClass: "form-control",
          loaderTrue: true,
          isButtonDisabled: true,
        });
        let request = {} as IClockCookieSaveDto;
        let currentUniqueID = cookies.get("WebTimeClock");
        if (currentUniqueID === undefined) {
          currentUniqueID = "";
          request.Type = "INS";
          this.setState({ operationType: "INS" });
        } else {
          request.Type = "UPD";
          this.setState({ operationType: "UPD" });
        }
 
        let todayDate: any = new Date();
        let currentdate = todayDate.getDate();
        let currentMonth = todayDate.getMonth() + 1;
        let currentYear = todayDate.getFullYear() + 5;
        let dateTime =
          currentMonth +
          "/" +
          currentdate +
          "/" +
          currentYear +
          " 00:00:00.000";

        
        request.HID = this.state.hid;
        request.ExpiryDate = dateTime;
        request.Commnets = this.state.cookieDescription;
        request.UniqueID = currentUniqueID;

        TimeClockDeviceDetail.saveCookieDetails(request)
          .then(async (result: any | null) => {
            this.setState({ loaderTrue: false });

            if (result !== null) {
              if (this.state.operationType === "UPD") {
                cookies.remove("WebTimeClock", {
                  domain: this.state.currentDomain,
                  path: "/",
                });
                if (result.result.includes("&")) {
                  cookies.set("WebTimeClock", result.result.split("&")[0], {
                    expires: new Date(dateTime),
                    domain: this.state.currentDomain,
                    path: "/",
                  });
                  toast.success("Web time clock created successfully", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                    // // containerId: "TimeClockSetting",
                  });
                } else {
                  cookies.set("WebTimeClock", currentUniqueID, {
                    expires: new Date(dateTime),
                    domain: this.state.currentDomain,
                    path: "/",
                  });
                  toast.success("Web time clock updated successfully", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                    // // containerId: "TimeClockSetting",
                  });
                }
              } else {
                cookies.set("WebTimeClock", result.result, {
                  expires: new Date(dateTime),
                  path: "/",
                  domain: this.state.currentDomain,
                });
                toast.success("Web time clock created successfully", {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                  // // containerId: "TimeClockSetting",
                });
              }

              this.setState({
                isButtonDisabled: false,
                buttonText: "Save",
              });
              this.getTimeClockCookieData();
              this.newWebClockModalClose();
            }

            resolve();
          })
          .catch((error) => {
            reject();
            this.setState({ loaderTrue: false, isButtonDisabled: false });
          });
      } else {
        this.setState({
          descriptionClass: "alert-danger form-control",
        });
        Utils.toastError("Please enter description.", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "TimeClockSetting",
        });
      }
    }
  };
  checkPastTime = () => {
    if (this.state.cookieTime !== "") {
      let nowDate = new Date();
      let curentDate =
        nowDate.getFullYear() +
        "/" +
        (nowDate.getMonth() + 1) +
        "/" +
        nowDate.getDate();
      let selectedDate = this.state.cookieDate;
      let selectDate =
        selectedDate.getFullYear() +
        "/" +
        (selectedDate.getMonth() + 1) +
        "/" +
        selectedDate.getDate();
      if (selectDate === curentDate) {
        let h = nowDate.getHours();
        let m = nowDate.getMinutes();
        m = this.checkTime(m);
        h = this.checkTime(h);
        let currentTime = h + ":" + (m + 1);
        if (this.state.cookieTime < currentTime) {
          this.setState({ cookieTime: "", isReadyToSave: false });
          Utils.toastError("Web Time Clock can be created only for future time.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "TimeClockSetting",
          });
          return;
        } else {
          this.setState({ isReadyToSave: true });
        }
      }else{
        this.setState({ isReadyToSave: true });
      }
    }
  };

  redirectToWebClockLogin = (url) => {
    User.GenerateToken()
      .then(async (result: any | null) => {
        if (result) {
          User.isAuthenticated = false;
          User.apiLoginResponse = null;
          localStorage.clear();
          sessionStorage.clear();
          // Adding Pendo Snippet after successful logout
          Pendo.IntegratePendo("userLogout");
          let mergeURL = result.result.mergeURL;
          window.open(
            mergeURL + "?logInUser=" + btoa("logout") + "&logInType=3"
          );
          window.location.href = url;
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };


  deleteConfirmPopup = (ifRecordID) => {
    confirmAlert({
      title: "Do you want to delete this?",
      message: "",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleDeleteCookie("Yes", ifRecordID),
        },
        {
          label: "No",
          onClick: () => this.handleDeleteCookie("No", ""),
        },
      ],
    });
  };

  handleDeleteCookie = (actionType, ifRecordID) => {
    if (actionType === "Yes") {
      let request = {} as IClockCookieUpdateDeleteDto;
      request.RecordID = ifRecordID;
      request.Type = "Delete";
      request.Value = "";
      TimeClockDeviceDetail.deleteOrUpdateCookie(request)
        .then(async (result: any | null) => {
          this.setState({ loaderTrue: false });
          if (result === "Success") {
            cookies.remove("WebTimeClock", {
              path: "/",
              domain: this.state.currentDomain,
            });
            this.getTimeClockCookieData();
            toast.success("Web time clock deleted successfully.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "TimeClockSetting",
            });
            this.setState({ buttonText: "Save" });
          }
          resolve();
        })
        .catch((error) => {
          reject();
          this.setState({ loaderTrue: false });
        });
    }
  };

  handleUpdateCookie = (ifRecordID, statusForUpdate) => {
    if (this.state.isManageTimeClockPermission) {
      let request = {} as IClockCookieUpdateDeleteDto;
      request.RecordID = ifRecordID;
      request.Type = "Status";
      if (statusForUpdate === "Active") request.Value = "Inactive";
      else request.Value = "Active";
      TimeClockDeviceDetail.deleteOrUpdateCookie(request)
        .then(async (result: any | null) => {
          this.setState({ loaderTrue: false });
          if (result === "Success") {
            this.getTimeClockCookieData();
            toast.success("Web time clock status updated successfully.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "TimeClockSetting",
            });
          }
          resolve();
        })
        .catch((error) => {
          reject();
          this.setState({ loaderTrue: false });
        });
    }
  };

  handleAddToHomescreenClick = () => {
    alert(`
      1. Open Share menu
      2. Tap on "Add to Home Screen" button`);
  };
  updateBufferTimeAndMissedPunchOutAlert = () => {
    const request: IBufferTimeAndMissedPunchOutAlertEditRequest = {} as IBufferTimeAndMissedPunchOutAlertEditRequest;
    const { missPunchOutAlert, bufferTime, hidValue, sendToAllGM } = this.state;
    request.hotelId = hidValue;
    request.missPunchOutAlert = missPunchOutAlert || false;
    request.bufferTime = bufferTime || 0;
    request.sendToAllGM = sendToAllGM || false;
    
    LaborSchedule.updateBufferTimeAndMissedPunchOutAlert(request)
      .then((result: any | null) => {
        this.setState({ loaderTrue: false });

        if (result != null) {
          toast.success(
            result.result, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "TimeClockSetting",
          }
          );
        }
      })
      .catch((error) => {
        if(error?.message!== 'Cancel') {
        Utils.toastError(error.message, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "TimeClockSetting",
        });
      }
      });
    
  };

  formatDelete(cell: any, row: any, rowIndex: any) {
    return (
      <Dropdown className="more-action" alignRight>
        <Dropdown.Toggle
          className="btn-outline-primary btn btn-primary more fa-trash"
          id="dropdown-more"
          onClick={(e) => {
            e.stopPropagation();
            this.deleteConfirmPopup(row.iF_Record);
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z" />
            <path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z" />
          </svg>
        </Dropdown.Toggle>
      </Dropdown>
    );
  }
  onMissedPunchOutAlertGMChange(event: any) {
   
    const value = event.target.checked;
    this.setState({ sendToAllGM: value }, () =>
      this.updateBufferTimeAndMissedPunchOutAlert()
    );
  }
  formatEdit(cell: any, row: any, rowIndex: any) {
    return (
      <Dropdown className="more-action" alignRight>
        <Dropdown.Toggle
          className="btn-outline-primary btn btn-primary more fa-trash"
          id="dropdown-more-edit"
          style={{ padding: 11 }}
          onClick={(e) => {
            e.stopPropagation();
            this.updateExistCookies("Yes", row.uniqueID);
          }}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 576 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: -5, marginLeft: 2 }}
          >
            <path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"></path>
          </svg>
        </Dropdown.Toggle>
      </Dropdown>
    );
  }
  onButterTimeChange(event: any) {
    const value = event.target.value;
    this.setState({ bufferTime: value }, () =>
      this.updateBufferTimeAndMissedPunchOutAlert()
    );
  }
  onMissedPunchOutAlertChange(event: any) {

    const value = event.target.checked;
    this.setState({ missPunchOutAlert: value }, () =>
    {
      if(value == false){
        this.setState({sendToAllGM:false})
      }
      this.updateBufferTimeAndMissedPunchOutAlert()
    });
  }
  render() {
    let ColumnTimeClock = [
      {
        dataField: "machinId",
        text: "Machine ID",
        formatter: (
          cell: any,
          row: {
            machinId: React.ReactNode;
            accessStatus: React.ReactNode;
          },
          rowIndex: any
        ) => (
          <div>
            <span>{row.machinId}</span>
            {row.accessStatus === "false" && (
              <span className="machine-status">Disabled</span>
            )}
          </div>
        ),
      },
      {
        dataField: "defaultLanguage",
        text: "Default Language",
      },
      {
        dataField: "notes",
        text: "Notes",
      },
    ];

    let ColumnTimeClockLink = [
      {
        dataField: "url",
        text: "Link",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="webTimeClockLink">
            <Link
              to="#"
              style={{ textDecoration: "none" }}
              onClick={() => {
                this.redirectToWebClockLogin(window.location.origin + "/tc");
              }}
            >
              {window.location.origin + "/tc"}
            </Link>
          </div>
        ),
      },
      {
        dataField: "",
        text: "Expiration Date",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="webTimeClockLink">
            <EllipsisWithTooltip placement="top">
              {row.expiryDate}
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "",
        text: "Created Date",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="webTimeClockLink">
            <EllipsisWithTooltip placement="top">
              {row.createdDate}
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "",
        text: "Description",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="webTimeClockLink">
            <EllipsisWithTooltip placement="top">
              {row.commnets}
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "",
        text: "Status",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="webTimeClockDelete">
            <div style={{ marginTop: -10 }}>
              <Form.Check
                type="switch"
                id={rowIndex + 1}
                key={rowIndex + 1}
                label=""
                disabled={!this.state.isManageTimeClockPermission}
                checked={row.status === "Active" ? true : false}
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleUpdateCookie(
                    row.iF_Record,

                    row.status
                  );
                }}
              />
            </div>
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        editable: false,
        hidden: !this.state.isManageTimeClockPermission,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.formatEdit(cell, row, rowIndex),
      },
      {
        dataField: "",
        text: "",
        hidden: !this.state.isManageTimeClockPermission,
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.formatDelete(cell, row, rowIndex),
      },
    ];

    const loaderTrue = this.state.loaderTrue;
    let loadShow;
    if (loaderTrue) {
      loadShow = <PageLoader />;
    }

    const { missPunchOutAlert, bufferTime, sendToAllGM } = this.state;
    return (

      <React.Fragment>
        <Container
          fluid
          className="body-sec timeClock"
          onClick={this.searchClear}
        >
          {/* <ToastContainer
            autoClose={3000}
            containerId={"TimeClockSetting"}
          /> */}
          {loadShow}
          <div className={this.state.isUnderline}>
            <HidSelector
              onClickEvent={this.selectHotelDropDown}
              ref={this.childHID}
            />
            <div className="mr-auto">Time Clock</div>
            {this.state.hidValue !== "Select" && (
              <div className="action-group d-flex flex-row">
                {this.state.isManageTimeClockPermission && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.openNewWebClockModal}
                  >
                    + Web Time Clock
                  </button>
                )}
              </div>
            )}
          </div>
          {this.state.hidValue !== "Select" && (
            <div>
              <div className="missed-punchout-selector">
                <div className="punchout-alert d-flex align-items-center">
                  <Form.Check
                    id={"chkMissedPunchOut"}
                    key={"chkMissedPunchOut"}
                    type="switch"
                    label=""
                    checked={missPunchOutAlert}
                    onChange={(e: any) => this.onMissedPunchOutAlertChange(e)}
                  />
                  <div className="punchout-label">
                    Missed Punch-Out Alert
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id={"tooltip-displayName"}>
                          To configure which positions will send out missed punch-out alert, please go to the Department &amp; Positions page
                        </Tooltip>
                      }
                    >
                      <span>
                        <InfoTooltip />{" "}
                      </span>
                    </OverlayTrigger>
                  </div>

                </div>

                <div className="punchout-alert d-flex align-items-center">
                  <Form.Check
                    id={"chkMissedPunchOutGM"}
                    key={"chkMissedPunchOutGM"}
                    type="switch"
                    label=""
                    checked={sendToAllGM}
                    disabled={!missPunchOutAlert}
                    onChange={(e: any) => this.onMissedPunchOutAlertGMChange(e)}
                  />
                  <div className="punchout-label">
                    GM Always Receives Alert
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id={"tooltip-displayName"}>
                          Allow GMs to receive all missed punch-out alerts regardless of their schedule.
                        </Tooltip>
                      }
                    >
                      <span>
                        <InfoTooltip />{" "}
                      </span>
                    </OverlayTrigger>
                  </div>

                </div>
                <div className="alert-input">
                  Alert when punch-out is{" "}
                  <input
                    type="number"
                    min="0"
                    disabled = {!missPunchOutAlert}
                    value={bufferTime}
                    onChange={(e: any) => this.onButterTimeChange(e)}
                    className="input-mins form-control"
                    placeholder="0"
                  ></input>{" "}
                  minutes late
                </div>
              </div>
              <div className="bdy-sec margin-top">
                <div className="page-sub-ttl">Approved Devices</div>
                <Form>
                  <BootstrapTable
                    keyField={"Text"}
                    data={this.state.tableDate}
                    columns={ColumnTimeClock}
                    hover
                  />
                </Form>
              </div>
              <div className="bdy-sec margin-top">
                <div className="page-sub-ttl">Web Time Clock Link</div>
                <Form>
                  <div className="cusrowTableWebClock">
                    <BootstrapTable
                      keyField={"Text"}
                      data={this.state.timeClockDataTable}
                      columns={ColumnTimeClockLink}
                    />
                  </div>
                </Form>
              </div>
            </div>
          )}
          <Modal
            className="save-template-modal"
            show={this.state.addWebTimeClockModal}
            onHide={this.newWebClockModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            animation={true}
            centered
            backdrop="static"
            keyboard={false}
          >
            <OutsideClickHandler
              onOutsideClick={this.newWebClockModalClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modelHeaderTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>


                <Form.Group controlId="addtimeoff-reason">
                  <Form.Label>
                    Description <strong className="text-danger">*</strong>
                  </Form.Label>
                  <Form.Control
                    className={this.state.descriptionClass}
                    as="textarea"
                    value={this.state.cookieDescription}
                    onChange={(e: any) => this.onTextChange(e)}
                    placeholder="Enter Description"
                    required
                  />
                </Form.Group>


                {/* <Row>
                  <Col sm={7}>
                    <div className="date">
                      <Form.Label>
                        Date <strong className="text-danger">*</strong>
                      </Form.Label>
                      <Form.Group className="wtcDateCalc" controlId="date-selection">

                        <DatePicker
                          value={this.state.cookieDate}
                          className={this.state.dateClass}
                          id="reCalcDate"
                          selected={this.state.cookieDate}
                          onChange={(e: any) => this.handleSelectDate(e)}
                          onSelect={(e: any) => this.handleSelectDate(e)}
                          placeholderText="mm/dd/yyyy"
                          required
                          minDate={new Date()}
                          maxDate={expirationTimePeriod}
                          autoComplete="off"
                          
                          
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col sm={5}>
                    <Form.Label>
                      Time <strong className="text-danger">*</strong>
                    </Form.Label>
                    <Form.Group controlId="start-time">

                      <OutsideClickHandler
                        onOutsideClick={this.checkPastTime}
                      >
                        <input
                          className={this.state.timeClass}
                          type="time"
                          ref={this.autoFocusTime}
                          id="startTime"
                          name="startTime"
                          value={this.state.cookieTime}
                          onChange={(e: any) => this.expireTime(e)}
                          autoComplete="off"
                        />

                      </OutsideClickHandler>
                    </Form.Group>
                  </Col>
                </Row>
             */}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={this.state.isButtonDisabled}
                  className="import-forecast-button"
                  onClick={() => this.generateLink()}
                >
                  {this.state.buttonText}
                </Button>
              </Modal.Footer>
            </OutsideClickHandler>
          </Modal>
        </Container>
      </React.Fragment>

    );
  }
}
