import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { ModuleReports } from "../../Common/Services/ModuleReports";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";


export class LaborMultiEmployeeSelector extends React.Component<any, any> {
  private inputSearch: any;
  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();
    this.state = {
      placeHolder: "Select",
      filterData: [],
      inputValue: "",
      allEmployeeList: [],
      selectedItems: [],
      itemLists: [],
      itemLists1: [],
    };
  }
  getLaborEmployees = async (hotelIds: number) => {

    try {
      const result: any[] = await ModuleReports.GetLaborMultiUsers(hotelIds);
      if (result?.length > 0) {


        let userList: any[] = [];

        result.forEach((element) => {
          let newItem = {} as any;
          newItem.uniqueno = element.uniqueno;
          newItem.username = element.username;

          newItem.isChecked = false;


          userList.push(newItem);
        });




        this.setState({ filterData: userList, allEmployeeList: userList, itemLists: userList, itemLists1: userList, selectedItems: [] });


      }
    } catch (error) {
      // Need to handle this
      console.log(error);
    }
  };

  componentDidMount = () => {

    if (this.props?.hotelIds) {
      this.updateEmployeeDDL(this.props?.hotelIds);
    }
  };


  updateEmployeeDDL(hotelIds: number) {

    if (!hotelIds) {
      this.setState({ allEmployeeList: [], filterData: [] });

    }
    else {
      this.getLaborEmployees(hotelIds);

    }
  }





  handleOutsideClickForAddShift = () => {
    this.setState({ opened: false, inputValue: "" });
  };


  openDropdown = () => {


    if (this.state.inputValue === "") {
      this.setState(
        {
          opened: true,
          filterData: [],
          inputValue: "",
        },
        () => {

          if (this.state.itemLists1.length > 0) {
            this.updateLists();
            return false;

          } else {
            this.inputSearch.current.focus();

            this.updateEmployeeDDL(this.props?.hotelIds);
          }


        }
      );
    }


  };


  updateLists = () => {
    let { filterData, itemLists } = this.state;
    //
    if (filterData.length === 0 && this.state.inputValue === "") {
      filterData = itemLists;
    }

    this.setState({
      allEmployeeList: filterData,
    });

  };



  handleRemoveSpecificChip = (idx: number) => () => {

    const itemLists = [...this.state.itemLists];
    const selectedItems = [...this.state.selectedItems];
    if (selectedItems[0].username !== undefined) {
      const rowIndex = itemLists.findIndex(
        (obj) =>
          obj.username.toLowerCase().trim() ===
          selectedItems[idx].username.toLowerCase().trim()
      );
      itemLists[rowIndex].isChecked = false;
      selectedItems.splice(idx, 1);

      this.setState({ selectedItems, itemLists }, () => {
        this.props.handleMultiEmployeeChange(this.state);
      });
    }
  };



  handleCheckCheckbox = (idx: any, e: any) => {
    const itemLists = [...this.state.itemLists];
    const selectedItems = [...this.state.selectedItems];

    const index = itemLists.findIndex((xvalue) => xvalue.uniqueno === idx);

    itemLists[index].isChecked = e.target.checked;

    idx = index;
    this.setState({ itemLists });

    if (e.target.checked === true) {
      const item = {
        value: itemLists[idx].uniqueno,
        username: itemLists[idx].username,
        isChecked: e.target.checked,
      };

      this.setState(
        {
          selectedItems: [...this.state.selectedItems, item],
        },
        () => {
          this.props.handleMultiEmployeeChange(this.state);
        }
      );
    } else {
      const removeIndex = selectedItems.findIndex(
        (xvalue) =>
          xvalue.username.toLowerCase().trim() ===
          itemLists[index].username.toLowerCase().trim()
      );
      selectedItems.splice(removeIndex, 1);
      this.setState({ selectedItems, isRemove: false }, () => {
        this.props.handleMultiEmployeeChange(this.state);
      });
    }
  };

  handleChange = (e: any) => {

    const updatedList = this.state.itemLists.filter((item: any) => {
      return (
        item.username.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });

    this.setState(
      { filterData: updatedList, inputValue: e.target.value },
      () => {
        this.updateLists();
      }
    );
  };

  render() {


    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClickForAddShift}>
        <div
          className={
            this.state.opened ? "multiSelectSearch employee-picker opened" : "multiSelectSearch employee-picker"
          }
        >

          <div className="fieldArea" onClick={this.openDropdown}>
            {this.state.selectedItems.length === 0 ? (
              <span className="areaPlaceholder placeholder">
                ALL USERS
              </span>
            ) : (
              <div className="chip">
                {this.state.selectedItems.map((item: any, idx: any) => (
                  <div className="chipSelect" id={idx} key={idx}>
                    <div className="chipVal">{item.username}</div>
                    <button
                      id="btnremove"
                      type="button"
                      className="cross"
                      onClick={this.handleRemoveSpecificChip(idx)}
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.79465 0.229492L3.96598 3.05749L1.13798 0.229492L0.195312 1.17216L3.02331 4.00016L0.195312 6.82816L1.13798 7.77083L3.96598 4.94282L6.79465 7.77083L7.73731 6.82816L4.90931 4.00016L7.73731 1.17216L6.79465 0.229492Z"
                          fill="#6A6E73"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}


            <div className="coaSearch">
              <div className="dropDownPanel inner-label">
                <div className="SearchInput">
                  <input
                    ref={this.inputSearch}
                    key={"text-Filter"}
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    placeholder="Search"
                    value={this.state.inputValue}
                  />
                </div>
                <ul>

                  {this.state.allEmployeeList.length > 0 && (
                    <div>
                      <div className="dropdown-header divider">ALL USERS</div>
                      {this.state.allEmployeeList.map((item: any, idx: any) => (

                        <div
                        >

                          <li
                            id={idx}
                            key={idx}
                          >
                            <Form.Check
                              custom
                              type="checkbox"
                              key={"custom-" + idx}
                              id={"custom-" + idx}
                              checked={item.isChecked}
                              onChange={(e: any) => {
                                this.handleCheckCheckbox(item.uniqueno, e)
                              }
                              }
                            />
                            <div className="user-details">
                              <div className="name-position mr-auto">
                                <EllipsisWithTooltip placement="bottom">
                                  <div className="name">
                                    {item.username}
                                  </div>
                                </EllipsisWithTooltip>
                              </div>
                            </div>
                          </li>
                        </div>

                      ))}


                    </div>
                  )}
                </ul>
              </div>
            </div>

          </div>

        </div>
      </OutsideClickHandler>
    );



  }
}
