import * as React from "react";
// import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DropDownList } from "./common-components/dropdown-list";
export class LookBackPeriodSelector extends React.Component<any, any> {
  private inputElement: any;

  private lookBackPeriods = [
    { label: "30 Days", value: "1" },
    { label: "60 Days", value: "2" },
    { label: "90 Days", value: "3" },
    { label: "6 months", value: "4" },
    { label: "9 months", value: "5" },
    { label: "12 months", value: "6" },
  ];

  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      selectedItem: { label: "30 Days", value: "1" },
      filterData: this.lookBackPeriods,
      lookBackPeriods: this.lookBackPeriods,
      inputValue: "",
    };
  }

  componentDidMount = () => {
    //if(this.props?.defaultValue) {
    this.handleDropdownChange(this.state.selectedItem);
    // }
  };
  componentDidUpdate = (prevProps) => {
    const { defaultValue } = this.props;
    if (!defaultValue && this.state.selectedItem.value !== "1") {
      this.handleDropdownChange({ label: "30 Days", value: "1" });
    }
  };

  handleEmpTypeSearch = (e: { target: { value: string } }) => {
    const updatedList = this.state.lookBackPeriods.filter((item) => {
      return (
        item?.label
          ?.toString()
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  };

  handleDropdownChange = (item: any) => {
    this.setState({ selectedItem: item });
    this.props?.onLookBackPeriodChange(item.value);
    this.resetSerchBox();
  };

  resetSerchBox = () => {
    this.setState({ filterData: this.state.lookBackPeriods, inputValue: "" });
  };

  render() {

    // const onFocusInput = (eventKey: any) => {
    //   this.resetSerchBox();
    //   setTimeout(() => {
    //     this.inputElement.current.focus();
    //   }, 100);
    // };

    return (
      <>
        {this.lookBackPeriods?.length > 0 && (
          <DropDownList
            placeholder={"Select Employement Type"}
            data={this.lookBackPeriods}
            label={"label"}
            value={"value"}
            defaultValue={this.props.defaultValue}
            onDropDownChange={this.handleDropdownChange}
            selectedItem={this.state.selectedItem}
          />
        )}
      </>
    );
  }
}
