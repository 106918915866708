import * as React from "react";
// import { 
  // Form,
  //  OverlayTrigger, Tooltip
//  } from "react-bootstrap";
 import {
  Departments,
} from "../../Common/Services/ReportGroup";
import {IDeportmentDto} from "../../Common/Contracts/IReportParameter";
import { DropDownMultiList } from "./common-components/dropdown-multi-list";
import _ from "lodash";

 
  export class DepartmentSelector extends React.Component<any, any> {
    private multiselectChild: any;
  private SingleselectChild: any;

  constructor(props: any) {
    super(props);
    this.multiselectChild = React.createRef();
    this.state = {
      departments: [],
      selectedDepartments: [],
    };
  }
  componentDidMount() {
    
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let username= storage === null ? 1 : (storage.userName as any);
    let UconcatinateT : any =tenantID +','+username;
      Departments.GetDepartmentRepository(UconcatinateT)
      .then(async (result: IDeportmentDto[] | null) => {
        if (result != null && result.length > 0) {
          ;
          let departments: any[] = [];
          let depIds: any[] = [];

          if(this.props?.defaultValue?.length > 0) {
            depIds =  this.props?.defaultValue.split(",")?.map(r=> +r);
          }

          result.forEach((element) => {
            let newItem = {} as any;
            newItem.deptID = element.deptID;
            newItem.deptName = element.deptName;
            newItem.status = element.status;
            newItem.tenantID=element.tenantID;
            // newItem.TenantID=element.TenantID
            // newItem.userName=element.UserName
            if(depIds?.includes( element.deptID)) {
              newItem.isChecked = true;
            } else {
              newItem.isChecked = false;
            }
           
            departments.push(newItem);
          });
          this.setState({ departments: departments });
        }
      })
      .catch((error) => {
        // Need to handle this
        console.log(error);
      });
  }

  updatedepartments = (newdepartments) => {
    this.setState({ departments: newdepartments }, () => {
      this.props.handleDepartmentChange(newdepartments);
    });
  };

  removalChips = (removalChips) => {
    let departments=[...this.state.departments]
    // let tempdepartments: any = [...this.state.departments];
    if(removalChips){
      let parentId = removalChips.uniqueno;
      for (let i = this.state.departments.length - 1; i >= 0; i--) {
        if (Number(this.state.departments[i].rGroupID) === Number(parentId)) {
          departments.splice(i, 1);
        }
      }
      // departments.pop();
      this.setState({ departments })  ;
    }else{
      this.setState({departments:[]})
    }
    
  };

 


  updateMultiSelectItem = (selectedItem: any) => {
    this.setState({ selectedDepartments: selectedItem }, () => {
      this.props.handleDepartmentChange(selectedItem);
    });
  };

  updateSingleSearchItem = (selectedItem: any) => {
    ;
    this.setState({ selectedDepartments: selectedItem }, () => {
      this.props.handleDepartmentChange(selectedItem);
    });
  };

  dataChange = () => {
    this.setState({ isUnsavedChanges: true });
  };

  // disablebuton=(e: React.MouseEvent<HTMLElement>)=>
  // {
  //   
  //   const target = e.target as HTMLElement;
  //   let attr = target.getAttribute("btnselection");
  //   console.log(attr);
  // style={{visibility: this.state.show ? "visible" : "hidden",}}
  // }

 
  render() {
    return (
      <div>
         {this.state.departments.length > 0 &&   <DropDownMultiList
         
 defaultValue = {this.props?.defaultValue}
                label={"deptName"}
                value={"deptID"}
                data={[...this.state.departments]}
                updateMultiSelectItem={this.updateMultiSelectItem}
                dataChange={this.dataChange.bind(this)}
                isSelectAll={true}
                placeHolder={"Select Department"}
                filtertitle={"Filter Department"}


                
        />
         }
      </div>
    );
  }
}
