import * as React from "react";
import {
    Form,
} from "react-bootstrap";
import { DropDownMultiList } from "./common-components/dropdown-multi-list";

export class DataSource1Periods extends React.Component<any, any> {
    private checkBoxRef: any;
    constructor(props: any) {
        super(props);
        this.checkBoxRef = React.createRef();
        this.state = {
            dataSourceList: this.props.reportid === 2179 ?
                [
                    { label: "Beginning Balance", value: "Beginning Balance", isChecked: true },
                    { label: "Net Change", value: "Net Change", isChecked: true },
                    { label: "Ending Balance", value: "Ending Balance", isChecked: true }
                ] : [
                    { label: "Actual", value: "Actual", isChecked: true },
                    { label: "Original Budget", value: "Original Budget", isChecked: false },
                    { label: "Forecast", value: "Forecast", isChecked: false }
                ]
            , defaultValue: "",
        }
    }


    componentDidUpdate(prevProps, prevState) {
        const { defaultValue } = this.props;
        let dataSourceList = [...this.state.dataSourceList];
        if (prevProps.defaultValue !== defaultValue) {
            let groupNames = defaultValue.split(",")?.map(r => r);
            const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
            groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
            dataSourceList = dataSourceList?.map(item => {
                if (groupNames?.includes(item?.label)) {
                    item.isChecked = true;
                } else {
                    item.isChecked = false;
                }
                return item;
            })
            this.setState({ dataSourceList, defaultValue });
        }
    }

    updateMultiSelectItem = (selectedItem: any) => {
        this.props?.onDataSourceOperating(selectedItem);
    };

    componentDidMount = () => {
        const { defaultValue } = this.props;
        let dataSourceList = [...this.state.dataSourceList];
        if (defaultValue === undefined) {
            this.props?.onDataSourceOperating(this.state.dataSourceList);
        } else {
            let groupNames = defaultValue.split(",")?.map(r => r);
            const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
            groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
            dataSourceList = dataSourceList?.map(item => {
                if (groupNames?.includes(item?.label)) {
                    item.isChecked = true;
                } else {
                    item.isChecked = false;
                }
                return item;
            })
            this.setState({ dataSourceList, defaultValue }, () => {
                this.props?.onDataSourceOperating(dataSourceList);
            });
        }
    };



    render() {
        return (
            <DropDownMultiList
                defaultValue={this.state?.defaultValue}
                label={"label"}
                value={"value"}
                data={[...this.state.dataSourceList]}
                updateMultiSelectItem={this.updateMultiSelectItem}
                isSelectAll={true}
                placeHolder={"Select Data Source 1"}
                filtertitle={"Filter Data Source 1"}
            />
        );
    }
}
