import Instense from './Axios-config'
export class LaborSetting {
  

  public static enterPriseSettingDetails = async (): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    
    const url = `/EnterPriseSetting/EnterPriseSettingDetails`;
    return Instense.get(url, {params:{tenantID}} ).then(response => {
      return response.data as any;
    });
  };

  public static saveEnterPriseSetting = async (laborEnterpriseRequest: any): Promise<any | null> => {
    const url = `/EnterPriseSetting/SaveEnterPriseSetting`;
    return Instense.post(url, laborEnterpriseRequest).then(response => {
      return response.data as any;
    });
  };

  public static GetLaborPayrollUserName = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    
    const url = `/EnterPriseSetting/GetPayrollUser`;
    return Instense.get(url, {params: { tenantID } }).then(response => {
      return response.data as any;
    });
  };

  public static SavePayrollUser = async ( selectUser: any ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
   
    let laborPayrollUser = {} as any;
    laborPayrollUser.Users = JSON.stringify(selectUser);
    laborPayrollUser.TenantID = tenantID;
    const url = `/EnterPriseSetting/SavePayrollUser`;
    return Instense.post(url,laborPayrollUser).then(response => {
      return response.data as any;
    });
  };

  public static GetPayrollSavedUser = async (): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
   
    const url = `/EnterPriseSetting/GetPayrollSavedUser`;
    return Instense.get(url, {params: { tenantID } }).then(response => {
      return response.data as any;
    });
}
public static LaborRulesPerEnterpriseLogData = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  request.tenantId = tenantID;
  const url = `/EnterPriseSetting/LaborRulesPerEnterpriseLogData` ;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result?.result as any;
    return result;
  });
};

public static DownloadLaborRulesPerEnterpriseLogData = async (
  request: any, fileName: string
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  request.tenantId = tenantID;
  const url =  `/EnterPriseSetting/DownloadLaborRulesPerEnterpriseLogData`;
  return Instense.post(url, request, { responseType: "blob" }).then(
    (response: any) => {
      let csvURL = window.URL.createObjectURL(response.data);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", fileName + ".xlsx");
      tempLink.click();
    }
  );
};

public static getEnterpriseFeatureFalg = async (): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  
  const url = `/EnterPriseSetting/GetFeatureFlagEnterpriseWise`;
  return Instense.get(url, {params: { tenantID } }).then(response => {
    return response.data as any;
  });
};

}
interface IselectUser {
  lebel: string;
}
