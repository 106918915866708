import React from "react";
// import PageLoader from "../../../../Common/Components/PageLoader";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {
  ToastContainer,
  //  toast
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BootstrapTable from "react-bootstrap-table-next";
// import { Dropdown } from "react-bootstrap";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import { reject, resolve } from "q";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
export default class HousekeepingApprovalHID extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      tableData: [],
      loaderTrue: false,
    };
  }

  getHousekeepingApprovals(request: any) {
    this.setState({ loaderTrue: true });
    LaborDayApprove.HousekeepingApprovalCount(request)
      .then(async (result: any[] | null) => {
       
        let data = [];
        if (result != null) {
          if (result.length > 0) {
            data = Object.assign([], JSON.parse(JSON.stringify(result)));
          }
        }
        this.setState({ tableData: [] }, () => {
          this.setState({ tableData: data });
        });
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.props.housekeepingApprovalHID(row.letterCode, row.hid);
    },
  };

  render() {
    // let loadShow: {} | null | undefined;
    // if (this.state.loaderTrue) {
    //   loadShow = <PageLoader />;
    // }

    const columns = [
      {
        dataField: "letterCode",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="hotelDetailsData">
            <span className="hotelLetterAbbr">{row.letterCode}</span>
            <EllipsisWithTooltip placement="bottom">
              <span className="hotelFullName">{row.hotelName}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "roomTotal",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdCountData">
            <span className="smileIdCount">{row.dayCount}</span>
            <span className="smileIdLabel">Housekeeping Approvals</span>
          </div>
        ),
      },
      {
        dataField: "action",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div>
            {/* <Dropdown
                                className="more-action"
                                alignRight
                                onClick={(e) => {
                                    e.stopPropagation();
                                }} >
                                <Dropdown.Toggle
                                    className="btn-outline-primary btn btn-primary more"
                                    id="dropdown-more"
                                >
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey={"Dummy Text"}>
                                        Lorem ipsum.
                  </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
          </div>
        ),
      },
    ];
    return (
      <>
       {this.state.loaderTrue ? (<ReactPageLoader useas={"hotelsListOTAR"} />) : (
         <React.Fragment>
          {/* <ToastContainer
            enableMultiContainer
            autoClose={3000}
            containerId={"smileIdOverview"}
          /> */}
          
          <div className="smileIdOverview">
            <h1>Properties with Housekeeping Approvals</h1>
            <div className="smileIdOverviewTable">
              <BootstrapTable
                keyField="rowno"
                data={this.state.tableData}
                columns={columns}
                rowEvents={this.rowEvents}
                hover
              />
            </div>
          </div>
          </React.Fragment>
       )}
      </>
    );
  }
}
