import React from "react";
import { Button, ButtonGroup, Dropdown, Form, Row } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
export class SortSelector extends React.Component<any, any> {
 

  constructor(props: any) {
    super(props);
    this.state = {
      selectedData: { label: "Select", value: 0 },
      currentIndex: -1
    };
  }

  componentDidMount() {
  
      const selectedData = this.props.data.find(
        (r) => r.value === this.props?.defaultValue
      );
      this.setState({ selectedData });
  
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.defaultValue != this.props.defaultValue || !_.isEqual(prevProps.data,this.props.data)){
      const selectedData = this.props.data.find(
        (r) => r.value === this.props?.defaultValue
      );
      this.setState({ selectedData });
    }
  };

  

  onPeriodChange = (selectedItem, currentIndex = 0) => {
    this.setState({ selectedData: selectedItem, currentIndex });
      this.props.onChange(selectedItem);
  };
  render() {
    const { currentIndex } = this.state;
    return (

      <div className="day-year-picker-container single-select calender-container">
      
     
            <Dropdown className="single-select calender-display">
              <Dropdown.Toggle id="ddlPeriod" className={this.props?.showBlueBg ? "selected-blue-bg" : ""}>
                <span style={{marginRight: 2}}>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <path d="M2.25 12H3.75V3H6L3 0L0 3H2.25V12ZM12 9H9.75V0H8.25V9H6L9 12L12 9Z" fill="#6A6E73"/>
                  </svg> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                    <path d="M4.44095 13.9059L4.13823 14.2093V1.53447C4.13823 1.16486 3.94103 0.823419 3.621 0.638576C3.30097 0.453808 2.90657 0.453808 2.58654 0.638576C2.26651 0.823419 2.06931 1.16486 2.06931 1.53447V14.2093L1.76658 13.9059C1.50516 13.6445 1.12413 13.5424 0.767024 13.6381C0.409919 13.7338 0.130972 14.0127 0.0352871 14.3698C-0.0604002 14.7269 0.0416807 15.108 0.303089 15.3694L2.37201 17.4383C2.56605 17.6324 2.82925 17.7415 3.10375 17.7415C3.37824 17.7415 3.64145 17.6324 3.83549 17.4383L5.90441 15.3694C6.16583 15.108 6.26792 14.7269 6.17221 14.3698C6.07652 14.0127 5.79758 13.7338 5.44047 13.6381C5.08337 13.5424 4.70234 13.6445 4.44092 13.9059H4.44095Z" fill="#1D1E20"/>
                    <path d="M18.9656 1.18945H7.93132C7.56171 1.18945 7.22027 1.38665 7.03543 1.70668C6.85066 2.02671 6.85066 2.42112 7.03543 2.74115C7.22027 3.06118 7.56171 3.25838 7.93132 3.25838H18.9656C19.3352 3.25838 19.6766 3.06118 19.8615 2.74115C20.0462 2.42112 20.0462 2.02671 19.8615 1.70668C19.6766 1.38665 19.3352 1.18945 18.9656 1.18945Z" fill="#1D1E20"/>
                    <path d="M16.207 6.01709H7.93132C7.56171 6.01709 7.22027 6.21429 7.03543 6.53432C6.85066 6.85435 6.85066 7.24875 7.03543 7.56878C7.22027 7.88881 7.56171 8.08601 7.93132 8.08601H16.207C16.5766 8.08601 16.9181 7.88881 17.1029 7.56878C17.2877 7.24875 17.2877 6.85435 17.1029 6.53432C16.9181 6.21429 16.5766 6.01709 16.207 6.01709Z" fill="#1D1E20"/>
                    <path d="M13.4484 10.8447H7.93132C7.56171 10.8447 7.22027 11.0419 7.03543 11.362C6.85066 11.682 6.85066 12.0764 7.03543 12.3964C7.22027 12.7164 7.56171 12.9137 7.93132 12.9137H13.4484C13.8181 12.9137 14.1595 12.7164 14.3443 12.3964C14.5291 12.0764 14.5291 11.682 14.3443 11.362C14.1595 11.0419 13.8181 10.8447 13.4484 10.8447Z" fill="#1D1E20"/>
                    <path d="M10.6899 15.6719H7.93132C7.56171 15.6719 7.22027 15.8691 7.03543 16.1891C6.85066 16.5091 6.85066 16.9035 7.03543 17.2236C7.22027 17.5436 7.56171 17.7408 7.93132 17.7408H10.6899C11.0595 17.7408 11.4009 17.5436 11.5858 17.2236C11.7705 16.9035 11.7705 16.5091 11.5858 16.1891C11.4009 15.8691 11.0595 15.6719 10.6899 15.6719Z" fill="#1D1E20"/>
                  </svg>
                </span>

                <div className="d-inline-block">
                  {this.state.selectedData?.value}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.props.data.map((item, index) => {
                  return (
                    <Dropdown.Item
                      key={item.value}
                      className={item.value  ===this.state.selectedData?.value ? "selected-item":""}
                      onClick={(e: any) => this.onPeriodChange(item, index)}
                    >
                      {item?.label}
                      <FontAwesomeIcon icon={faCheck} color="#2CAF92" />
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
       

       
      </div>

    );
  }
}
