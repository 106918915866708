import React from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ChangeLogs } from "../../../Common/Components/ChangeLogs/ChangeLogs";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {
  ICurrentFilter,
  ILogHistoryProps,
  ILogHistoryState,
} from "../../../Common/Contracts/IScheduleLogHistory";

import {
  ITransactionChangeLogRequest,
  ITransactionLogResponse,
} from "../../../Common/Contracts/ITransactionChangeLog";
import { Register as registersevice } from "../../../Common/Services/Register";
import { Utils } from "../../../Common/Utilis";
export class JournalLogHistory extends React.Component<
  ILogHistoryProps,
  ILogHistoryState
> {
  private pageName: string = "TransactionLogHistory";

  private tranTypes: any[] = [{
    label: 'Deposit', value: 'Deposit'
  },
  { label: 'Transfer', value: 'Transfer' },
  {
    label: 'Withdrawal', value: 'Withdrawal'
  }]

  state = {
    tableData: [],
    filteredData: [],
    tableColumns: [],
    title: "Journal History",
    keyFieldName: "rowNumber",
    isDataLoading: false,
    scheduleId: 0,
    isDrillDownView: false,
    drillDownLevel: +this.props.shiftId > 0 ? 1 : 0,
    filterColumns: [],
    filterPopUps: [],
    filterPopUpsCopy: [],
    showFilterPopUp: false,
    popClassName: "",
    currentFilter: {
      filterName: "",
      filterValue: "",
      filterKeyName: "",
      filterInputType: "",
      dropDownProps: {},
    },
    tableClassName: "transaction-log-table",
    startDate: this.props.payPeriodStartDate,
    endDate: this.props.payPeriodEndDate,
    pageSize: 20,
    currentPageNo: 1,
    totalDataLength: 0,
    drillDownShiftDate: "",
    reactTableClassName: "react-transaction-log-table",
    tableRow: {},
    childTableRow: {}
  };

  hoverOutEllipsis = () => {
    $(".tooltip")?.removeClass("show");
    $(".tooltip")?.removeClass("fade");
  };

  cellTooltipFormatter = (cell: any, row: any, rowIndex: any) => {

    const text = cell?.toString();
    let substr = "";
    if (text?.length >= 15) {
      substr = `${text.substr(0, 10)}...`;
    }
    if (row.main === 999 && cell == row.transactionId) {
      return (
        <><svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1V5.94118H13M13 5.94118L8.05882 1M13 5.94118L8.05882 10.8824" stroke="#84888C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        </>
      )
    }
    return (
      <>
         <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
      </>
    );
  };

  cellTransactionTypeFormatter = (cell: any, row: any, rowIndex: any) => {

    const text = cell?.toString();
    let substr = "";
    if (text?.length >= 15) {
      substr = `${text.substr(0, 10)}...`;
    }
    if (row.main === 999 && cell == row.transactionId) {
      return (
        <><svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1V5.94118H13M13 5.94118L8.05882 1M13 5.94118L8.05882 10.8824" stroke="#84888C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        </>
      )
    }
    return (
      <>
        {" "}
        <div>
        {
          row.transactionType === "From" &&
          <EllipsisWithTooltip placement="bottom">{"Debit"}</EllipsisWithTooltip>
        }
        {
          row.transactionType === "To" &&
          <EllipsisWithTooltip placement="bottom">{"Credit"}</EllipsisWithTooltip>
        }
        {
          !(row.transactionType === "From" || row.transactionType === "To") &&
          <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
        }
        </div>
      </>
    );
  };

  cellTooltipPositionFormatter = (cell: any, row: any, rowIndex: any) => {
    const text = cell?.toString();
    let substr = "";
    if (text?.length > 11) {
      substr = `${text.substr(0, 11)}...`;
    }
    return (
      <>
        {" "}
        {text?.length > 11 ? (
          <div onWheel={() => this.hoverOutEllipsis()}>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id={"PositionName"}>{cell}</Tooltip>}
            >
              <span>{substr}</span>
            </OverlayTrigger>
          </div>
        ) : (
          <div>{cell}</div>
        )}
      </>
    );
  };

  tableColumns = [
    {
      dataField: "rowNumber",
      text: "",
      hidden: true,
    },
    {
      dataField: "transactionId",
      text: "ID",
      filterInputType: this.props.accType === "All" ? "text" : "",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "editor",
      text: "Editor",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "vendor",
      text: "Vendor",
      filterInputType: this.props.accType == "All" ? "text" : "",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "displayTransactionAmount",
      text: "Amount",
      filterInputType: this.props.accType == "All" ? "text" : "",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },

    {
      dataField: "transactionDate",
      text: "Date",
      filterInputType: this.props.accType === "All" ? "date" : "",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },

    {
      dataField: "transactionType",
      text: "Type",
      filterInputType: this.props.accType === "All" ? "ddl" : "",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTransactionTypeFormatter,
      dropDownProps: {
        ddlData: this.tranTypes,
        dropDownItemLabelFieldName: "label",
        dropDownItemValueFieldName: "value",
        dropDownSelectedItem: {},
      },
    },

    {
      dataField: "editDateTime",
      text: " Edit Date Time",
      filterInputType: "date",

      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id={"PositionName"}>{cell}</Tooltip>}
            >
              <div className="edit-date-time">{cell}</div>
            </OverlayTrigger>

          </>
        );
      },
    },
    {
      dataField: "fieldname",
      text: "Field Name",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "oldValue",
      text: "Old Value",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "newValue",
      text: "New Value",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "triggerName",
      text: "Trigger",
      filterInputType: "text",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: this.cellTooltipFormatter,
    },
    {
      dataField: "",
      text: "",
      filterInputType: "",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (<>
          {(this.state?.drillDownLevel === 1 && row.main === 999) || this.state?.drillDownLevel === 0 ?
            <>
              {
                <div
                  onClick={() => this.state?.drillDownLevel === 1 ? this.onSplitTypeClick(row) : this.onTranIdClick(row)}
                >
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.625 3.2H11.375V4.8H2.625V3.2ZM0 0H14V1.6H0V0ZM5.25 6.4H8.75V8H5.25V6.4Z" />
                  </svg>
                </div>
              }
            </>
            :
            <div>{""}</div>}</>);
      },
    },
  ];

  componentDidMount = () => {
    this.buildDefaultData();
  };

  buildDefaultData = () => {
    // let tableColumns = (+this.props?.shiftId > 0 ? this.tableColumns.filter(
    //   (column: any) => column?.dataField
    // ) : this.tableColumns) as any[];

    let tableColumns = this.tableColumns.map((x: any) => {
      x.drillDownLevel = 0;
      return x;
    });
    //let  tableClassName = +this.props?.shiftId > 0 ? "transaction-log-table-drilldown" : "transaction-log-table";
    let tableClassName = "transaction-log-table";
    const filterColumns = tableColumns
      .filter((item) => item.filterInputType)
      .map((item) => ({
        text: item.text,
        key: item.dataField,
        isFilterApplied: false,
        filterInputType: item?.filterInputType,
        dropDownProps: item?.dropDownProps,
      }));

    this.setState({
      tableClassName,
      filterPopUps: [],
      filterColumns,
      tableColumns: tableColumns,
    });

    this.getChangeLogData(true);
  };

  validateDates = () => {
    const currentFilter: any = { ...this.state.currentFilter };
    if (currentFilter.filterKeyName === "editDateTime") {
      const { startDate, endDate } = this.state;

      if (!endDate) {
        Utils.toastError("Please enter End Date.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
      } else if (!startDate) {
        Utils.toastError("Please enter Start Date.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  onTranIdClick = (row, isReturn = false) => {
    this.setState(
      {
        tableClassName: "transaction-log-table-drilldown-1",
        currentPageNo: 1,
        drillDownLevel: 1,
        drillDownShiftDate: "",
        scheduleId: row.transactionId,
        isDrillDownView: true,
        filterPopUpsCopy:
          isReturn ? [...this.state.filterPopUpsCopy] : +this.props.shiftId > 0 ? [] : [...this.state.filterPopUps],
        tableColumns: this.tableColumns,
        tableRow: row,
        filterPopUps: [
          {
            filterName: "Transaction ID",
            filterValue: row.transactionId,
            filterKeyName: "transactionId",
            popClassName: "",
            filterInputType: "",
          }
        ],
      },
      () => this.getChangeLogData(false, false, row.transactionType)
    );
  };

  onSplitTypeClick = (row) => {
    this.setState(
      {
        tableClassName: "transaction-log-table-drilldown-2",
        currentPageNo: 1,
        drillDownLevel: 2,
        drillDownShiftDate: "",
        scheduleId: row.transactionId,
        isDrillDownView: true,
        childTableRow: row,
        filterPopUps:  +this.props.shiftId > 0? [
          {
            filterName: "Sr No",
            filterValue: row.uniqueno,
            filterKeyName: "srNo",
            popClassName: "",
            filterInputType: "",
          },
        ]: [
          {
            filterName: "Transaction ID",
            filterValue: row.transactionId,
            filterKeyName: "transactionId",
            popClassName: "",
            filterInputType: "",
          },
          {
            filterName: "Sr No",
            filterValue: row.uniqueno,
            filterKeyName: "srNo",
            popClassName: "",
            filterInputType: "",
          },
        ],
      },
      () => this.getChangeLogDataSplitType(row.transactionId, row.transactionType,  row.uniqueno, row.dataType)
    );
  };

  updateFilters = () => {
    const currentFilter: any = { ...this.state.currentFilter };
    let filterPopUps: any[] = [...this.state.filterPopUps];
    const filterIndex = filterPopUps.findIndex(
      (filter) => filter?.filterName === currentFilter?.filterName
    );
    if (
      currentFilter.filterKeyName === "editDateTime" &&
      !currentFilter.filterValue
    ) {
      currentFilter.filterValue = `${this.props.payPeriodStartDate} - ${this.props.payPeriodEndDate}`;
    }
    if (filterIndex < 0) {
      filterPopUps.push(currentFilter);
    } else {
      filterPopUps[filterIndex] = currentFilter;
    }
    filterPopUps = filterPopUps?.filter((item) => item?.filterValue);
    filterPopUps = filterPopUps.map((item, index) => {
      item.popClassName = "filter-edit-popup-" + index;
      return item;
    });
    this.setState({ filterPopUps, currentPageNo: 1 }, () => {
      this.getChangeLogData();
      this.updateFilterColumns();
    });
  };

  updateFilterColumns = () => {
    const { filterColumns: columns, filterPopUps } = this.state;
    const filteredKeys = filterPopUps?.map(
      (filter: ICurrentFilter) => filter?.filterKeyName
    );
    const filterColumns = columns.map((item: any) => {
      if (filteredKeys.find((key) => key === item?.key)) {
        item.isFilterApplied = true;
      } else {
        item.isFilterApplied = false;
      }
      return item;
    });
    this.setState({ filterColumns });
  };

  onFilterTextChange = (event: any) => {
    const currentFilter: any = { ...this.state.currentFilter };
    currentFilter.filterValue = event.currentTarget?.value;
    this.setState({ currentFilter });
  };

  onFilterDropDownChange = (item: any, valueFieldName) => {
    const currentFilter: ICurrentFilter = { ...this.state.currentFilter };
    currentFilter.filterValue = item[valueFieldName];
    if (!currentFilter.dropDownProps) {
      currentFilter.dropDownProps = {};
    }
    currentFilter.dropDownProps.dropDownSelectedItem = item;
    this.setState({ currentFilter });
  };

  onFilterChange = (filterItem: any, popClassName): void => {
    const filterPopUps: any[] = [...this.state.filterPopUps];
    let currentFilter: ICurrentFilter = this.state.currentFilter;

    if (filterPopUps.length >= 5) {
      Utils.toastError("You can't apply more than 5 filters!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    if (
      filterPopUps.length >= 5 ||
      filterPopUps.filter((filter) => filter?.filterName === filterItem.text)
        ?.length > 0
    ) {
      return;
    }
    currentFilter = {
      filterName: filterItem?.text,
      filterValue: "",
      filterKeyName: filterItem.key,
      filterInputType: filterItem?.filterInputType,
      popClassName: popClassName,
      dropDownProps: filterItem?.dropDownProps,
    };
    this.setState({
      currentFilter,
      currentPageNo: 1,
      showFilterPopUp: true,
      popClassName,
      startDate: this.props.payPeriodStartDate,
      endDate: this.props.payPeriodEndDate,
    });
  };

  removeFilter = (filterName: string): void => {

    if (this.state.drillDownLevel === 2 && +this.props.shiftId === 0) {
      this.onTranIdClick(this.state.tableRow, true);
      return;
    }


    let filterPopUps: any[] = [...this.state.filterPopUps];
    if (filterPopUps.length === 0) {
      return;
    }
    filterPopUps = filterPopUps.filter(
      (filter) => filter?.filterName !== filterName
    );
    if (filterPopUps.length > 0) {
      filterPopUps = filterPopUps.map((item, index) => {
        item.popClassName = "filter-edit-popup-" + index;
        return item;
      });
    }

    if (+this.state.scheduleId > 0) {
      this.setState({ tableClassName: "transaction-log-table" });
      this.returnBackFromDrillDown();
    } else {
      this.setState({ filterPopUps, currentPageNo: 1 }, () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      });
    }
  };

  hideFilterPopUp = () => {
    if (this.state.showFilterPopUp) {
      this.setState({
        showFilterPopUp: false,
        popClassName: "",
      });
    }
  };

  editFilter = (currentFilter: ICurrentFilter, popClassName) => {
    if (currentFilter.filterInputType === "date") {
      const datesArray = currentFilter.filterValue?.split("-");
      this.setState({
        currentFilter,
        popClassName,
        showFilterPopUp: true,
        startDate: datesArray[0]?.trim(),
        endDate: datesArray[1]?.trim(),
      });
    } else {
      this.setState({ currentFilter, popClassName, showFilterPopUp: true });
    }
  };



  returnBackFromDrillDown = () => {
    this.setState(
      {
        scheduleId: 0,
        currentPageNo: 1,
        drillDownLevel: +this.props.shiftId > 0 ? 1 : 0,
        isDrillDownView: false,
        filterPopUps: this.state.filterPopUpsCopy,
      },
      () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      }
    );
  };

  onPaginationchange = () => {
    const currentPageNo = this.state.currentPageNo + 1;
    this.setState({ currentPageNo }, () => {
      this.getChangeLogData(false, true);
    });
  };

  getChangeLogData = (isDefaultLoad = false, isPagination = false, transactionType = "") => {

    // let tableColumns = (+this.props?.shiftId > 0 ? this.tableColumns.filter(
    //   (column: any) => column?.dataField
    // ) : this.tableColumns) as any[];

    let tableColumns = this.tableColumns

    if (!isPagination) {
      this.setState({
        tableData: [],
        filteredData: [],
        tableColumns,
      });
    } else {
      this.setState({
        tableColumns,
      });
    }



    const { currentFilter, filterPopUps, pageSize, currentPageNo } = this.state;

    const request: ITransactionChangeLogRequest = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      tenantId: 0,
      hotelId:this.props.hotelId,
      uniqueNo: this.props.shiftId,
      editor: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
      pageNo: currentPageNo,
      pageSize: pageSize,
      accType: this.props.accType,
      vendor: "",
      transType: transactionType,
      transfromDate: "",
      transtoDate: "",
      uniqueNoCheckStrict: false,
      noOfDays:this.props.noOfDays
    } as ITransactionChangeLogRequest;

    filterPopUps.forEach((item: ICurrentFilter) => {
      if (item.filterKeyName === "editDateTime") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.fromDate = datesArray[0];
        request.toDate = datesArray[1];
      } else if (item.filterKeyName === "transactionDate") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.transfromDate = datesArray[0];
        request.transtoDate = datesArray[1];
      } else if (item.filterKeyName === "editor") {
        request.editor = item.filterValue;
      } else if (item.filterKeyName === "fieldname") {
        request.fieldName = item.filterValue;
      } else if (item.filterKeyName === "oldValue") {
        request.oldValue = item.filterValue;
      } else if (item.filterKeyName === "newValue") {
        request.newValue = item.filterValue;
      } else if (item.filterKeyName === "triggerName") {
        request.triggerName = item.filterValue;
      } else if (item.filterKeyName === "transactionId") {
        request.uniqueNo = +item.filterValue;
      } else if (item.filterKeyName === "transactionType") {
        request.transType = item.filterValue;
      } else if (item.filterKeyName === "vendor") {
        request.vendor = item.filterValue;
      } else if (item.filterKeyName === "displayTransactionAmount") {
        request.amount = item.filterValue;
      }
    });

    this.toggleDataLoading();

    registersevice
      .GetTransactionChangeLog(request)
      .then((tableData: ITransactionLogResponse[] | null) => {
        tableData = tableData?.map((item) => {
          item.editDateTime = moment(item.editDateTime).format(
            "MM/DD/YYYY hh:mm"
          );
          item.transactionDate = item?.transactionDate ? moment(item.transactionDate).format(
            "MM/DD/YYYY"
          ) : "";

          item.transactionAmount = item?.transactionAmount?.toString()?.includes(".") ? item.transactionAmount : `${item.transactionAmount}.00`;

          return item;
        }) as ITransactionLogResponse[] | null;

        if (isPagination) {
          tableData = [
            ...this.state.tableData,
            ...(tableData as ITransactionLogResponse[]),
          ];
        }

        const totalDataLength = tableData?.length
          ? tableData[0]?.totalCount
          : 0;

        this.setState({
          tableData: tableData,
          filteredData: tableData,
          totalDataLength,
        });
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        setTimeout(() => this.toggleDataLoading());
      });
  };


  getChangeLogDataSplitType = (transactionId = 0, splitType = '',srNo=0 , dataType) => {

    // let tableColumns = (+this.props?.shiftId > 0 ? this.tableColumns.filter(
    //   (column: any) => column?.dataField
    // ) : this.tableColumns) as any[];

    let tableColumns = this.tableColumns


    this.setState({
      tableData: [],
      filteredData: [],
      tableColumns,
    });




    const { currentFilter, filterPopUps, pageSize, currentPageNo } = this.state;

    const request: any = {
      transactionId,
      srNo,
      splitType,
      type: "Journal"
    } as any;



    this.toggleDataLoading();

    registersevice
      .GetSplitTransactionLog(request)
      .then((tableData: ITransactionLogResponse[] | null) => {
        tableData = tableData?.map((item) => {
          item.editDateTime = moment(item.editDateTime).format(
            "MM/DD/YYYY hh:mm.ss.SSS"
          );
          item.transactionDate = item?.transactionDate ? moment(item.transactionDate).format(
            "MM/DD/YYYY"
          ) : "";

          item.transactionAmount = item?.transactionAmount?.toString()?.includes(".") ? item.transactionAmount : `${item.transactionAmount}.00`;

          return item;
        }) as ITransactionLogResponse[] | null;


        const totalDataLength = tableData?.length
          ? tableData[0]?.totalCount
          : 0;

        this.setState({
          tableData: tableData,
          filteredData: tableData,
          totalDataLength,
        });
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        setTimeout(() => this.toggleDataLoading());
      });
  };
  getFilteredData = () => {
    this.onFiltersChange();
  };

  downloadChangeLogData = () => {
    const { filterPopUps, childTableRow, drillDownLevel } = this.state;
    const request: ITransactionChangeLogRequest = {
      fromDate: "",
      toDate: "",
      tenantId: 0,
      uniqueNo: this.props.shiftId,
      editor: "",
      fieldName: "",
      oldValue: "",
      newValue: "",
      triggerName: "",
      accType: +drillDownLevel === 2 ? (childTableRow as any)?.dataType : this.props.accType,
      vendor: "",
      transType: +drillDownLevel === 2 ? (childTableRow as any)?.transactionType : '',
      transfromDate: "",
      transtoDate: "",
      uniqueNoCheckStrict: false,
      hotelId:this.props.hotelId,
      noOfDays:this.props.noOfDays,
      SrNo: +drillDownLevel === 2 ? (childTableRow as any)?.uniqueno : 0
    } as ITransactionChangeLogRequest;

    filterPopUps.forEach((item: ICurrentFilter) => {
      if (item.filterKeyName === "editDateTime") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.fromDate = datesArray[0];
        request.toDate = datesArray[1];
      } else if (item.filterKeyName === "transactionDate") {
        const datesArray = item.filterValue
          ?.split("-")
          .map((item) => item?.trim());
        request.transfromDate = datesArray[0];
        request.transtoDate = datesArray[1];
      } else if (item.filterKeyName === "editor") {
        request.editor = item.filterValue;
      } else if (item.filterKeyName === "fieldname") {
        request.fieldName = item.filterValue;
      } else if (item.filterKeyName === "oldValue") {
        request.oldValue = item.filterValue;
      } else if (item.filterKeyName === "newValue") {
        request.newValue = item.filterValue;
      } else if (item.filterKeyName === "triggerName") {
        request.triggerName = item.filterValue;
      } else if (item.filterKeyName === "transactionId") {
        request.uniqueNo = +item.filterValue;
      } else if (item.filterKeyName === "transactionType") {
        request.transType = item.filterValue;
      } else if (item.filterKeyName === "vendor") {
        request.vendor = item.filterValue;
      } else if (item.filterKeyName === "displayTransactionAmount") {
        request.amount = item.filterValue;
      }
    });

    registersevice
      .DownloadChangeLog(
        request,
        `JournalChangeLog_${moment().format("DD/MM/YY")}`
      )
      .then(() => { })
      .catch((error) => { });
  };
  toggleDataLoading = () => {
    this.setState({ isDataLoading: !this.state.isDataLoading });
  };

  handleDatesChange = (date, type) => {
    const { startDate, endDate } = this.state;
    const currentFilter: any = { ...this.state.currentFilter };
    if (type === "StartDate") {
      if (new Date(date) > new Date(endDate)) {
        this.setState({ startDate: "" });
        Utils.toastError(
          "Please enter the Start Date less than or equal to End Date.",
          {
             position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        return false;
      }

      currentFilter.filterValue = `${date} - ${endDate}`;
      this.setState({ startDate: date });
    }

    if (type === "EndDate") {
      if (new Date(startDate) > new Date(date)) {
        this.setState({ endDate: "" });
        Utils.toastError(
          "Please enter the End Date greater than or equal to Start Date.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        return false;
      }

      currentFilter.filterValue = `${startDate} - ${date}`;
      this.setState({ endDate: date });
    }

    this.setState({ currentFilter });
  };

  onFiltersChange = () => {
    const currentFilter = this.state.currentFilter;
    this.setState({ currentPageNo: 1 }, () => {
      this.getChangeLogData();
    });

    if (currentFilter?.filterKeyName) {
      this.setState({
        currentFilter: {
          filterName: "",
          filterValue: "",
          filterKeyName: "",
          popClassName: "",
          filterInputType: "",
          dropDownProps: {},
        },
      });
    }
  };

  clearFilters = () => {
    if (this.state.drillDownLevel === 2 && +this.props.shiftId === 0) {
      this.onTranIdClick(this.state.tableRow, true);
      return;
    }

    if (+this.state.scheduleId > 0) {
      this.setState({ tableClassName: "transaction-log-table" });
      this.returnBackFromDrillDown();
    } else {
      this.setState({ filterPopUps: [], currentPageNo: 1 }, () => {
        this.getChangeLogData();
        this.updateFilterColumns();
      });
    }
  };

  render() {
    const { tableColumns } = this.state;


    const functions = {
      downloadChangeLogData: this.downloadChangeLogData,
      updateFilters: this.updateFilters,
      editFilter: this.editFilter,
      onFilterTextChange: this.onFilterTextChange,
      onFilterChange: this.onFilterChange,
      removeFilter: this.removeFilter,
      hideFilterPopUp: this.hideFilterPopUp,
      clearFilters: this.clearFilters,
      onFiltersChange: this.onFiltersChange,
      handleDatesChange: this.handleDatesChange,
      validateDates: this.validateDates,
      onPaginationchange: this.onPaginationchange,
      onFilterDropDownChange: this.onFilterDropDownChange,
    };
    const forwardedProps = { ...this.state, ...this.props, ...functions };
    return (
      <>
        <ToastContainer autoClose={3000} />
        {tableColumns?.length > 0 && <ChangeLogs {...forwardedProps} />}
      </>
    );
  }
}
