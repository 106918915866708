import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Container,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { HidSelector } from "../../Common/Components/HidSelector";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { Register as registersevice } from "../../Common/Services/Register";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { TransactionSlideout } from "./TransactionSlideout";
import { InvoiceEntrySlideout } from "./InvoiceEntrySlideout";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./register.scss";
import { ToastContainer, toast } from "react-toastify";
import { TransferSlideout } from "./TransferSlideout";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import TableSearch from "../../Common/Components/TableSearch";
import { TransactionLogHistory } from "./TransactionLogHistory";
import { CreditCardStatementImport } from "./creditCardStatement/CreditCardStatementImport";
import { BankAccountSlideout } from "../Accounting/BankAccountSlideout";
import { CreditCardAccountSlideout } from "../Accounting/CreditCardAccountSlideout";
import $ from "jquery";
import { User } from "../../Common/Services/User";
import { CreditCardPayment } from "../Register/CreditCardPayment";
import { RegisterExport } from "../Register/RegisterExportModal";
import Spinner from "react-bootstrap/Spinner";
import { CreditCardSlideout } from "./CreditCardSlideout";
import { BulkImportSlideout } from "./bulkImportSlideout/bulkImportSlideout";
import { LaborPerformance as laborPerformance } from "../../Common/Services/LaborPerfomance";
import { ReconcileSummary } from "../Accounting/Reconcile/ReconcileSummary";
import { ReconcileService as reconcileService } from "../../Common/Services/ReconcileService";
import _ from "lodash";
import { EntityManagementService } from "../../Common/Services/EntityManagementService";
import { log } from "console";

const { SearchBar } = Search;
let openRow: any = {};
let isEnterPriseEntry = false;
export class DraftTransaction extends React.Component<any, any> {
  private tableSearch: any;
  private childHID: any;
  private accountName: any;
  private dayCount: any;
  private exportModal: any;
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.accountName = React.createRef();
    this.dayCount = React.createRef();
    this.tableSearch = React.createRef();
    this.exportModal = React.createRef();
    this.state = {
      isNewReconcile: "",
      selectedaccount: "",
      selectedOprID: "",
      backPage: "register",
      isShowSlideout: false,
      isCCShowSlideout: false,
      inNew: true,
      isBgBlue: false,
      selectedRow: {},
      hidValue: "Select",
      hotelName: "Select",
      customDate: false,
      repeatingModal: false,
      hotelList: [],
      showChangeLogModal: false,
      moreAction: [
        { name: "Repeating", eventKey: "repeating" },
        { name: "Change Log", eventKey: "changeLog" },
        { name: "Export", eventKey: "Export" },
      ],
      addTransactionAction: [
        { name: "Deposit", eventKey: "deposit", toShow: 0 },
        { name: "Withdrawal", eventKey: "withdrawal", toShow: 0 },
        { name: "Add Debit", eventKey: "AddDebit", toShow: 1 }, //withdrawal
        { name: "Add Credit", eventKey: "AddCredit", toShow: 1 }, //deposit
        { name: "Add Payment", eventKey: "AddPayment", toShow: 1 },
        { name: "Transfer", eventKey: "transfer", toShow: 0 },
      ],
      isCCAcc: false,
      istableloader: false,
      repeatlist: [],
      regularlist: [],
      splitlist: [],
      tableData: [],
      tableData2: [],
      accountBal: [],
      accountNamelist: [],
      viewType: "regular",
      registerRequest: {
        hotelID: -1,
        lettercode: "",
        oprID: -1,
        selectType: "30",
        isDepositHide: "Yes",
        startDate: "",
        endDate: "",
        acctType: "",
      },

      accountNames: [],
      accountName: "Select Account Name",
      accountNameValue: -1,
      dayFilter: [
        { id: 1, name: "Last 30 Days" },
        { id: 2, name: "Last 60 Days" },
        { id: 3, name: "Last 90 Days" },
        { id: 4, name: "Custom" },
      ],
      dayText: "Last 30 Days",
      dayValue: 1,
      totalAcctBal: 0,
      totalsharedHID: [],
      totalsharedHIDTemp: 0,
      totalsharedHIDCount: 0,
      sharedcode: "",
      dateRange: "",
      start: new Date(),
      end: new Date(),
      isSelect: true,
      ehidSected: 0,
      isFilter: false,
      isloader: true,
      isSlideOut: false,
      trUniqueNo: 0,
      slideOutRequest: {},
      sildeoutHid: -1,
      sildeoutCode: "",
      pageType: "",
      oprId: -1,
      vid: 0,
      isTransferSlideOut: false,
      isExpand: false,
      densityView: [
        { id: 1, densityType: "densityView_1", name: "WIDE" },
        { id: 2, densityType: "densityView_3", name: "NARROW" },
      ],
      defaultDensityView: "",
      expandedList: [],
      expandedEpayList: [],
      expList: [],
      epayexList: [],
      valuesArray: false,
      transferRequest: { acctType: "", glArray: [] },
      creditCardStatementModal: false,
      CCPaymentShow: false,
      CardUniqueNo: -1,
      permissionlist: [],
      exportModal: false,
      isExportRun: false,
      sortingOrder: "desc",
      isCCSlideout: false,
      ccSlideType: "",
      isBulkImportSlideout: false,
      bulkImportRequest: {
        pageName: "",
        hotelName: "",
        hidValue: "",
        oprId: "",
        acctType: "",
      },
      accountStatus: "",
      isReconcilePerm: false,
      isGLBalncePerm: false,
      isfromSearch: false,
      loadingFromOther: false,
      isReimburementSummaryPopup: false,
      bName: "",
      isBookKeepingServiceActive : false
    };
    this.handleDateCallback = this.handleDateCallback.bind(this);
    this.handleConcile = this.handleConcile.bind(this);
  }

  componentDidMount() {
    this.GetReconcilefeatureflag();
    
    this.loadFromAccount();
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 1) {
      //use the state via location.state
      //and replace the state via
      const { history } = this.props;
      const uniqueNo = valuesArray
        ? window.atob(valuesArray[0]?.split("=")[1])
        : "";
      const tokenID = valuesArray
        ? window.atob(valuesArray[1]?.split("=")[1])
        : "";
      const etype = valuesArray
        ? window.atob(valuesArray[2]?.split("=")[1])
        : "";
      const backHid = valuesArray
        ? window.atob(valuesArray[3]?.split("=")[1])
        : "";
      const oprID = valuesArray
        ? window.atob(valuesArray[4]?.split("=")[1])
        : "";
      const backPage = valuesArray
        ? window.atob(valuesArray[5]?.split("=")[1])
        : "";
      let pageType = this.getEtype(etype, "child");
      let acctType = this.getAccttype(etype);
      let registerRequest1 = { ...this.state.registerRequest };
      let transferRequest = { ...this.state.transferRequest };
      registerRequest1.oprID = Number(oprID);
      if (this.getEtype(etype, "child") === "Transfer") {
        transferRequest.acctType = acctType;
        this.setState(
          {
            trUniqueNo: uniqueNo,
            registerRequest: registerRequest1,
            transferRequest,
            isTransferSlideOut: true,
            sildeoutHid: backHid,
            sildeoutCode: Number(backHid),
            isBgBlue: true,
            isfromSearch: true,
          },
          () => {
            history.replace();
          }
        );
      } else if (
        etype.toString().toLowerCase() ===
        "CreditCardWithdrawal".toLocaleLowerCase()
      ) {
        registerRequest1.hotelID = backHid;
        this.setState({
          ccSlideType: "CCDEBIT",
          trUniqueNo: uniqueNo,
          hidValue: backHid,
          registerRequest: registerRequest1,
          isBgBlue: true,
          oprId: Number(oprID),
          isCCSlideout: true,
          sildeoutHid: backHid,
          sildeoutCode: backHid,
        });
      } else if (
        etype.toString().toLowerCase() ===
        "CreditCardDeposit".toLocaleLowerCase()
      ) {
        registerRequest1.hotelID = backHid;
        this.setState({
          ccSlideType: "CCCREDIT",
          trUniqueNo: uniqueNo,
          hidValue: backHid,
          registerRequest: registerRequest1,
          isBgBlue: true,
          oprId: Number(oprID),
          isCCSlideout: true,
          sildeoutHid: backHid,
          sildeoutCode: backHid,
        });
      } else {
        let registerRequest1 = { ...this.state.registerRequest };
        registerRequest1.oprID = Number(oprID);
        registerRequest1.acctType = acctType;
        this.setState(
          {
            trUniqueNo: uniqueNo,
            pageType: pageType,
            sildeoutHid: Number(backHid),
            registerRequest: {},
            backPage: backPage,
          },
          () => {
            this.setState(
              { registerRequest: registerRequest1, isBgBlue: true },
              () => {
                console.log(this.state.registerRequest);
                this.setState({ isSlideOut: true }, () => {
                  history.replace();
                });
              }
            );
          }
        );
      }
    }
    this.getDefaultView();
  }


  getBookKeepingSerivceStatus = (hotelid) => {

    EntityManagementService.GetHotelModules(hotelid).then((hotelDetails: any) => {
      //Accounting Service Means Bookkeeping Service
      let isBookKeepingServiceActive=false
      isBookKeepingServiceActive = [...hotelDetails?.hotelModules]?.find((x:any)=> x?.moduleName === 'Accounting Service' && x?.status?.toLowerCase() == "active") || false;
      this.setState({ isBookKeepingServiceActive });
    });
  };
  getParamValueByParamName(paramName: string): any {
    let valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    window.history.replaceState(null, "");
    return valuesArray;
  }

  GetReconcilefeatureflag = () => {
    let request: any = {};
    request.hotelId = 0;
    request.OprID = 0;
    reconcileService
      .GetReconcilefeatureflag(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          this.setState({ isNewReconcile: result[0].status });
        }
        resolve();
      })
      .catch((error) => {
        toast.error(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };

  loadFromAccount() {
    const location = this.props.location;
    if (location.state !== undefined) {
      const stateLength = Object.keys(location.state).length;
      if (stateLength > 0) {
        // pageLoadDate
        let { oprid, } = location.state;
        this.setState({ selectedOprID: oprid, loadingFromOther: true });
      }
    }
  }

  handleChange = (e: { target: { value: string } }) => {
    console.log(this.state.totalsharedHIDTemp);
    const updatedList = this.state.totalsharedHIDTemp.filter((item: any) => {
      return (
        item.lettercode.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    this.setState({ totalsharedHID: updatedList, inputValue: e.target.value });
  };

  selectHotelDropDown = (hotelDetails: any) => {
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 1) {
      return;
    }

    let currentDate: any = new Date();
    if (
      JSON.parse(localStorage.storage).currentUtcTime !== "" &&
      JSON.parse(localStorage.storage).currentUtcTime !== null &&
      JSON.parse(localStorage.storage).currentUtcTime !== undefined
    ) {
      currentDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    currentDate.setDate(currentDate.getDate());
    this.setState({
      hotelName: hotelDetails.lettercode,
      hidValue: hotelDetails.hotelID,
    });
    let repeatlist = [],
      regularlist = [],
      splitlist = [],
      tableData = [],
      accountBal = [],
      viewType = "regular";
    let registerRequest: any = {
      hotelID: -1,
      oprID: -1,
      selectType: "30",
      isDepositHide: "Yes",
      startDate: "",
      endDate: "",
      acctType: "M",
    };
    let dayFilter: any[] = [
      { id: 1, name: "Last 30 Days" },
      { id: 2, name: "Last 60 Days" },
      { id: 3, name: "Last 90 Days" },
      { id: 4, name: "Custom" },
    ];
    let dayText = "Last 30 Days",
      dayValue = 1,
      totalAcctBal = 0,
      totalsharedHIDCount = 0,
      totalsharedHIDTemp = 0;
    let totalsharedHID: any[] = [];
    let sharedcode = "";
    let accountNamelist: any[] = [];
    let accountNames: any[] = [],
      accountName = "Select Account Name";
    let accountNameValue = -1;

    this.setState(
      {
        repeatlist,
        regularlist,
        splitlist,
        tableData,
        accountBal,
        viewType,
        registerRequest,
        dayFilter,
        dayText,
        dayValue,
        totalAcctBal,
        totalsharedHID,
        totalsharedHIDTemp,
        totalsharedHIDCount,
        sharedcode,
        accountNamelist,
        accountNames,
        accountName,
        accountNameValue,
        dateRange: "",
        start: new Date(),
        end: new Date(),
        isSelect: true,
        ehidSected: 0,
        isFilter: false,
      },
      () => {
        let registerRequest1 = { ...this.state.registerRequest };
        registerRequest1.hotelID = hotelDetails.hotelID;
        registerRequest1.lettercode = hotelDetails.lettercode;
        this.getRegisterNames(registerRequest1);
        this.getBookKeepingSerivceStatus(hotelDetails.hotelID)

        const valuesArray = this.getParamValueByParamName("");
        if (valuesArray.length > 1) {
          // Do this
        } else {
          this.setState({ registerRequest: registerRequest1 });
        }
        this.CleanSearch();
      }
    );
    if (this.childHID.current !== null) {
      let hidList = this.childHID.current.handleHIDList();
      this.setState({ hotelList: hidList });
    }
  };

  getRegisterlist = (registerRequest: any) => {
    this.setState({
      istableloader: true,
      tableData: [],
      registerList: [],
      isFilter: false,
    });
    let regularlist: any = [];
    let exList: any = [];
    let epayexList: any = [];
    registersevice
      .DraftTransactionlist(registerRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          if (result.length > 0) { 
            console.log(result) ;          
            this.setState(
              {                
                registerList: result,
                tableData2: result,
                tableData: result,
                istableloader: false,
              },
              () => {
                this.CleanSearch();
              }
            );
          } else {
            this.setState({ istableloader: false }, () => {
              this.CleanSearch();
            });
          }
        } else {
          this.setState({ istableloader: false }, () => {
            this.CleanSearch();
          });
        }
        resolve();
      })
      .catch((error) => {
        toast.error(error.message, {
        });
        this.setState({ istableloader: false }, () => {
          this.CleanSearch();
        });
        reject();
      });
  };

  getRegisterBal = (registerRequest: any) => {
    this.setState({ isloader: true });
    let regularlist: any = [];
    let splitlist: any = [];
    registersevice
      .RegisterAccountBal(registerRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          this.setState({
            accountBal: result,
            totalAcctBal: result[0].accountBal,
          });
          this.setState({ isloader: false });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isloader: false });
        reject();
      });
  };

  getRegisterPermission = () => {
    let permissionRequest: any = {};
    permissionRequest.hotelID = this.state.registerRequest.hotelID;
    permissionRequest.oprID = this.state.registerRequest.oprID;
    permissionRequest.pageName = "Register";

    registersevice
      .getRegisterPermission(permissionRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          this.setState({ permissionlist: result });
          let acntType =
            this.state.registerRequest.acctType === "M"
              ? "Misc Export"
              : this.state.registerRequest.acctType === "O"
              ? "Op Export"
              : this.state.registerRequest.acctType === "P"
              ? "Py Export"
              : "";

          let isPermissionToExport = result.filter(
            (x) => x.eType === acntType
          ).length;

          if (this.state.registerRequest.acctType === "C") {
            isPermissionToExport = 1;
          }

          let concileType =
            this.state.registerRequest.acctType === "M"
              ? "Misc Reconcile"
              : this.state.registerRequest.acctType === "O"
              ? "Op Reconcile"
              : this.state.registerRequest.acctType === "P"
              ? "Py Reconcile"
              : this.state.registerRequest.acctType === "C"
              ? "CC Reconcile"
              : "";

          let ceconcilePerm = result.filter(
            (x) => x.eType === concileType
          ).length;

          let glBalanceType =
            this.state.registerRequest.acctType === "M"
              ? "Misc View Bank and GL Balances"
              : this.state.registerRequest.acctType === "O"
              ? "Op View Bank and GL Balances"
              : this.state.registerRequest.acctType === "P"
              ? "Py View Bank and GL Balances"
              : "";
          let gLBalancePerm = result.filter(
            (x) => x.eType === glBalanceType
          ).length;

          let { moreAction } = this.state;
          if (isPermissionToExport === 0) {
            moreAction = this.state.moreAction.filter(
              (item) => item.name !== "Export"
            );
          } else {
            const ifExist = this.state.moreAction.find(
              (item) => item.name === "Export"
            );
            if (ifExist === undefined) {
              let MoreData: any = [];
              let more: any = {};
              more.name = "Export";
              more.eventKey = "Export";
              MoreData.push(more);
              moreAction = this.state.moreAction.concat(MoreData);
            }
          }
          this.setState({
            permissionlist: result,
            moreAction,
            isReconcilePerm: ceconcilePerm === 1 ? true : false,
            isGLBalncePerm:
              this.state.registerRequest.acctType === "C"
                ? true
                : gLBalancePerm === 1
                ? true
                : false,
          });
          console.log(this.state.moreAction);
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  getRegisterNames = (registerRequest: any) => {
    let selectedaccount = "";
    let selectedStatus = "";
    registersevice
      .RegisterAccount(registerRequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          let accountNames: any[] = [];
          result.sort((a, b) =>
            a.status !== b.status ? (a.status < b.status ? -1 : 1) : 0
          );
          result.forEach((element) => {
            let acctName: any = {};
            let oprID = element.oprID;
            let name = element.name;
            acctName.id = oprID;
            acctName.sort = element.orderBy;
            acctName.name = name;
            acctName.status = element.status;
            acctName.bName = element.bName;
            accountNames.push(acctName);
            if (Number(this.state.selectedOprID) === Number(oprID)) {
              selectedaccount = name;
              selectedStatus = element.status;
            }
          });
          let activeGroup = accountNames.filter(
            (item) => item.status.toString().toLocaleLowerCase() === "active"
          );
          activeGroup = _.sortBy(activeGroup, "sort");
          let inactiveGroup = accountNames.filter(
            (item) => item.status.toString().toLocaleLowerCase() === "inactive"
          );
          inactiveGroup = _.sortBy(inactiveGroup, "sort");
          let combileGroup: any = [];
          let isDevider: any = {
            bName: "",
            id: 0,
            name: "Devider",
            sort: 0,
            status: "isDevider",
          };
          combileGroup.push(...activeGroup);
          inactiveGroup.length > 0 && combileGroup.push(isDevider);
          combileGroup = [...combileGroup, ...inactiveGroup];
          accountNames = _.sortBy(accountNames, "sort");
          this.setState({ accountNamelist: result });
          const location = this.props.location;
          if (location.state !== undefined) {
            const stateLength = Object.keys(location.state).length;
            if (stateLength > 0) {
              let arr: any = {};
              arr.id = this.state.selectedOprID;
              arr.status = selectedStatus;
              this.setState(
                { accountNames: combileGroup, accountName: selectedaccount },
                () => {
                  if (this.accountName.current !== null) {
                    this.accountName?.current?.refhandleDropdownChange(
                      this.state.selectedOprID
                    );
                  }
                }
              );
            }
          } else {
            if (accountNames.length === 1) {
              this.handleaccountNameSelectedItem(1, accountNames[0]);
              this.setState({ accountName: accountNames[0].name }, () => {
                this.setState({ accountNames: accountNames });
              });
            } else {
              this.setState({ accountNames: combileGroup });
            }
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  getDefaultView() {
    this.setState({ pageloader: true });
    laborPerformance
      .getDefaultViewProperties("register")
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          this.setState({
            defaultDensityView: response[0].fieldValue,
          });
        } else {
          this.setState({
            defaultDensityView: this.state.densityView[1].densityType,
          });
        }
      })
      .catch((error) => {
        this.setState({
          defaultDensityView: this.state.densityView[1].densityType,
        });
        toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
      });
  }

  saveDefaultView = (): void => {
    const laborSaveAsDefaultdtos: any[] = [];
    laborSaveAsDefaultdtos.push({
      fieldName: "sort",
      fieldValue: this.state.defaultDensityView,
      pageName: "register",
    });
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      })
      .catch((error) => {
        toast.error(error.message, {
        });
        this.setState({ showLoader: false });
      });
  };

  handleaccountNameSelectedItem = (controlID: any, arr: any) => {
    this.setState({
      istableloader: true,
      isloader: true,
      accountStatus: arr.status,
    });
    this.setState({ customDate: false });
    let sharedHIDs: any[] = [];
    let totalsharedHIDCount = 0;
    let acctType = "";
    let sharedcode = "";
    let name = "";
    let isCCA = false;
    this.state.accountNamelist
      .filter((x) => x.oprID === arr.id)
      .forEach((element) => {
        let hidCode: any = [];
        hidCode = element.sharedHID.split("[");
        totalsharedHIDCount = element.hidCount;
        acctType = element.acctType;
        sharedcode = element.sharedcode;
        name = element.name;
        isCCA = element.acctType.toString().toLocaleLowerCase() === "c";
        hidCode.forEach((item) => {
          let sharedHID: any = {};
          let lettercodes: any = [];
          lettercodes = item.split("_");
          let splitHid = lettercodes[0];
          let splitcode = lettercodes[1];
          sharedHID.hid = splitHid;
          sharedHID.lettercode = splitcode;
          sharedHID.isChecked = false;
          sharedHIDs.push(sharedHID);
        });
      });

    let transferRequest = { ...this.state.transferRequest };
    transferRequest.acctType = acctType;
    let registerRequest = { ...this.state.registerRequest };
    registerRequest.oprID = arr.id;
    registerRequest.acctType = acctType;
    registerRequest.hotelID = this.state.hidValue;
    registerRequest.hotelName = this.state.hotelName;
    registerRequest.accountName = name;
    registerRequest.selectType = "30";
    this.setState(
      {
        CardUniqueNo: arr.id,
        accountName: name,
        transferRequest,
        loadingFromOther: false,
        isCCAcc: isCCA,
        accountNameValue: arr.id,
        totalsharedHIDCount: totalsharedHIDCount,
        dayText: "Last 30 Days",
        totalsharedHID: sharedHIDs,
        totalsharedHIDTemp: sharedHIDs,
        registerRequest: registerRequest,
        sharedcode: sharedcode,
        tableData: [],
        registerList: [],
        dateRange: "",
        start: new Date(),
        end: new Date(),
        isSelect: true,
        ehidSected: 0,
        isFilter: false,
        viewType: "regular",
        bName: arr.bName,
      },
      () => {
        this.CleanSearch();
        this.getRegisterlist(registerRequest);
        this.getRegisterBal(registerRequest);
        this.getRegisterPermission();
      }
    );
    let { moreAction } = this.state;
    if (acctType === "C") {
      moreAction = this.state.moreAction.filter(
        (item) => item.name !== "Repeating"
      );
    } else {
      const ifExist = this.state.moreAction.find(
        (item) => item.name === "Repeating"
      );
      if (ifExist === undefined) {
        let MoreData: any = [];
        let more: any = {};
        more.name = "Repeating";
        more.eventKey = "repeating";
        MoreData.push(more);
        moreAction = this.state.moreAction.concat(MoreData);
      }
    }
    this.setState({ moreAction });
  };

  filterAccountList(item: any, idx: any) {
    if (this.state.isCCAcc) {
      if (item.toShow === 1 || item.toShow === "") {
        return (
          <Dropdown.Item
            eventKey={this.state.addTransactionAction[idx].eventKey}
            key={idx}
            onClick={(e) => {
              this.handleCCTransaction(
                this.state.addTransactionAction[idx].name
              );
            }}
          >
            {this.state.addTransactionAction[idx].name}
          </Dropdown.Item>
        );
      }
    } else {
      if (item.toShow === 0 || item.toShow === "") {
        return (
          <Dropdown.Item
            eventKey={this.state.addTransactionAction[idx].eventKey}
            key={idx}
          >
            {this.state.addTransactionAction[idx].name}
          </Dropdown.Item>
        );
      }
    }
  }
  handleCCTransaction = (transactionType: any) => {
    if (transactionType === "Add Payment") {
      this.setState({ CCPaymentShow: true });
    }
  };
  hideCCPaymentModal = () => {
    this.setState({ CCPaymentShow: false });
  };

  handleSelectedItem = (controlID: any, id: any) => {
    let selectType;
    let dayText: any;
    this.setState({ predayValue: this.state.dayValue });
    if (id === 1) {
      selectType = "30";
      dayText = "Last 30 Days";
    } else if (id === 2) {
      selectType = "60";
      dayText = "Last 60 Days";
    } else if (id === 3) {
      selectType = "90";
      dayText = "Last 90 Days";
    } else if (id === 4) {
      let { dateRange } = this.state;
      selectType = "DateRange";
      dayText = "Custom";
      this.setState({ customDate: true });
      let startDate;
      let endDate;

      if (dateRange === "") {
        let now = new Date();
        let pre30days;
        if (this.state.dayValue === 1) {
          pre30days = new Date(now.setDate(now.getDate() - 30));
        } else if (this.state.dayValue === 2) {
          pre30days = new Date(now.setDate(now.getDate() - 60));
        } else if (this.state.dayValue === 3) {
          pre30days = new Date(now.setDate(now.getDate() - 90));
        }

        let calenderYear = pre30days.getFullYear();
        let calenderDate =
          pre30days.getDate() > 9
            ? pre30days.getDate()
            : 0 + "" + pre30days.getDate();
        let calenderMonth =
          pre30days.getMonth() + 1 > 9
            ? pre30days.getMonth() + 1
            : 0 + "" + (pre30days.getMonth() + 1);
        startDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

        let datee = new Date();
        let calenderYeare = datee.getFullYear();
        let calenderDatee =
          datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
        let calenderMonthe =
          datee.getMonth() + 1 > 9
            ? datee.getMonth() + 1
            : 0 + "" + (datee.getMonth() + 1);
        endDate = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;
      } else {
        startDate = this.state.dateRange.split("-")[0].trim();
        endDate = this.state.dateRange.split("-")[1].trim();
      }
      this.setState({
        dateRange: startDate + " - " + endDate,
        start: startDate,
        end: endDate,
        dayText,
        selectType,
      });
      setTimeout(() => {
        $("#dtrange").trigger("click");
      }, 100);
    }
    if (id !== 4) {
      this.setState({ istableloader: true, tableData: [] }, () => {
        let registerRequest = { ...this.state.registerRequest };
        registerRequest.selectType = selectType;
        this.setState(
          {
            registerRequest,
            dateRange: "",
            customDate: false,
            dayValue: id,
            dayText,
          },
          () => {
            this.getRegisterlist(registerRequest);
          }
        );
      });
    }
    this.handleClearAll("");
    this.setState({ isFilter: false });
  };

  hideBankSlideOut(mode) {
    if (mode === 1) {
      let registerRequest1: any = {};
      registerRequest1.hotelID = this.state.hidValue;
      registerRequest1.lettercode = this.state.hotelName;
      this.selectHotelDropDown(registerRequest1);
    }
    this.setState({ isShowSlideout: false, isCCShowSlideout: false });
  }

  handleRepeatingModalClose = () => {
    this.setState({ repeatingModal: false });
  };

  showCreditCardModal = () => {
    this.setState({ creditCardStatementModal: true });
  };
  hideCreditCardModal = (isTrue) => {
    if (isTrue) {
      let registerRequest = this.state.registerRequest;
      this.setState({ creditCardStatementModal: false }, () => {
        this.setState(
          {
            registerList: [],
            tableData: [],
            transferRequest: { acctType: "" },
          },
          () => {
            this.getRegisterlist(registerRequest);
            this.getRegisterBal(registerRequest);
          }
        );
      });
    } else {
      this.setState({ creditCardStatementModal: false });
    }
  };

  onChangeHIDFilter = (event, items) => {
    this.setState({ isFilter: false });
    const itemList = [...this.state.totalsharedHID];
    const index = itemList.findIndex((x) => x.hid === items.hid);
    itemList[index].isChecked = event.target.checked;
    this.setState({ itemList });
    let CheckedHID: any = [];
    CheckedHID = itemList.filter((x) => x.isChecked === true);
    if (CheckedHID.length === itemList.length) {
      this.setState({ isSelect: false });
    } else {
      this.setState({ isSelect: true });
    }
    if (CheckedHID.length > 0 && this.state.registerList.length > 0) {
      this.setState({ isFilter: true });
      let regularlist: any[] = [];
      let filterregularlist: any[] = [];
      regularlist = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.registerList))
      );
      filterregularlist = regularlist.filter((x) =>
        CheckedHID.map((r) => r.hid).includes(x.hid.toString())
      );
      this.setState({ tableData: [] }, () => {
        this.setState({ tableData: filterregularlist });
      });
    } else {
      let regularlist: any[] = [];
      regularlist = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.registerList))
      );
      this.setState({ tableData: [] }, () => {
        this.setState({ tableData: regularlist });
      });
    }
  };

  handleDateCallback(start, end, label) {
    let date = new Date(start._d);
    let calenderYear = date.getFullYear();
    let calenderDate =
      date.getDate() > 9 ? date.getDate() : 0 + "" + date.getDate();
    let calenderMonth =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : 0 + "" + (date.getMonth() + 1);
    let tempDate = calenderMonth + "/" + calenderDate + "/" + calenderYear;

    let datee = new Date(end._d);
    let calenderYeare = datee.getFullYear();
    let calenderDatee =
      datee.getDate() > 9 ? datee.getDate() : 0 + "" + datee.getDate();
    let calenderMonthe =
      datee.getMonth() + 1 > 9
        ? datee.getMonth() + 1
        : 0 + "" + (datee.getMonth() + 1);
    let tempDatee = calenderMonthe + "/" + calenderDatee + "/" + calenderYeare;

    let registerRequest = { ...this.state.registerRequest };
    registerRequest.selectType = "DateRange";
    registerRequest.startDate = tempDate;
    registerRequest.endDate = tempDatee;
    this.getRegisterlist(registerRequest);
    this.setState({ registerRequest, dateRange: tempDate + " - " + tempDatee });
  }

  handleSelectAll = (e: any) => {
    let allItemList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.totalsharedHID))
    );
    allItemList.forEach((element) => {
      element.isChecked = true;
    });

    this.setState({ isFilter: true });
    let regularlist: any[] = [];
    let filterregularlist: any[] = [];
    regularlist = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.registerList))
    );
    filterregularlist = regularlist.filter((x) =>
      allItemList.map((r) => r.hid).includes(x.hid.toString())
    );
    this.setState({ tableData: [] }, () => {
      this.setState({
        tableData: filterregularlist,
        totalsharedHID: allItemList,
        isSelect: false,
      });
    });
  };

  handleClearAll = (e: any) => {
    let allItemList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.totalsharedHID))
    );
    allItemList.forEach((element) => {
      element.isChecked = false;
    });

    let regularlist: any[] = [];
    regularlist = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.registerList))
    );
    this.setState({ tableData: [] }, () => {
      this.setState({
        tableData: regularlist,
        totalsharedHID: allItemList,
        isSelect: true,
        isFilter: false,
      });
    });
  };

  resetFilter = (e) => {
    this.handleClearAll(e);
    this.setState({ isFilter: false, inputValue: "" });
    this.setState({ tableData: [] }, () => {
      this.setState({ tableData: this.state.tableData2 });
    });
  };

  handleBulkImport = (eventKey: any) => {
    let bulkImportRequest = { ...this.state.bulkImportRequest };
    bulkImportRequest.hotelName = this.state.hotelName;
    bulkImportRequest.hidValue = this.state.hidValue;
    bulkImportRequest.oprId = this.state.registerRequest.oprID;
    bulkImportRequest.acctType = this.state.registerRequest.acctType;

    if (eventKey === "BulkImportDeposits") {
      bulkImportRequest.pageName = "Deposit";
      this.setState({ bulkImportRequest, isBulkImportSlideout: true });
    } else if (eventKey === "BulkImportWithdrawal") {
      bulkImportRequest.pageName = "Withdrawal";
      this.setState({ bulkImportRequest, isBulkImportSlideout: true });
    }
  };

  hideBulkImportSlideout = (updated) => {
    if (updated) {
      this.setState({ isSave: true, isBulkImportSlideout: false }, () => {
        this.hideSlideOut(this.state.isSave);
      });
    } else {
      this.setState({ isBulkImportSlideout: false });
    }
  };

  hideTrSlideOut(hasEvent) {
    if (this.state.isBgBlue) {
      let win = window.open("about:blank", "_self");
      win?.close();
    } else {
      if (hasEvent) {
        let registerRequest = this.state.registerRequest;
        this.setState(
          {
            isTransferSlideOut: false,
            registerList: [],
            tableData: [],
            transferRequest: { acctType: "", glArray: [] },
          },
          () => {
            this.getRegisterlist(registerRequest);
            this.getRegisterBal(registerRequest);
          }
        );
      } else {
        this.setState({
          isTransferSlideOut: false,
          transferRequest: { acctType: "", glArray: [] },
        });
      }
    }
  }

  hideCCSlideOut = (isSave) => {
    if (this.state.isBgBlue) {
      let win = window.open("about:blank", "_self");
      win?.close();
    } else {
      if (isSave) {
        let registerRequest = this.state.registerRequest;
        this.setState(
          {
            isCCSlideout: false,
            registerList: [],
            tableData: [],
          },
          () => {
            this.getRegisterlist(registerRequest);
            this.getRegisterBal(registerRequest);
          }
        );
      } else {
        this.setState({ isCCSlideout: false });
      }
    }
  };

  hideSlideOut(isSave) {
    if (this.state.isBgBlue) {
      let win = window.open("about:blank", "_self");
      win?.close();
    } else {
      this.setState({
        isSlideOut: false,
        isTransferSlideOut: false,
      });

      if (isSave) {
        let registerRequest = this.state.registerRequest;
        this.setState(
          {
            registerList: [],
            tableData: [],
          },
          () => {
            this.getRegisterlist(registerRequest);
            this.getRegisterBal(registerRequest);
          }
        );
      }
    }
  }

  hideSlideOut1(isSave) {
    this.setState({
      isSlideOut: false,
      isTransferSlideOut: false,
    });

    if (isSave) {
      let registerRequest = this.state.registerRequest;
      this.setState(
        {
          registerList: [],
          tableData: [],
        },
        () => {
          this.getRegisterlist(registerRequest);
          this.getRegisterBal(registerRequest);
        }
      );
    }
  }

  notesIcon() {
    return (
      <svg
        stroke="currentColor"
        fill="#1d1e20"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1.2em"
        width="1.2em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19,4h-3V2h-2v2h-4V2H8v2H5C3.897,4,3,4.897,3,6v14c0,1.103,0.897,2,2,2h14c1.103,0,2-0.897,2-2V6C21,4.897,20.103,4,19,4z M5,20V7h3h2h4h2h3V6l0.002,14H5z"></path>
        <path d="M7 9H17V11H7zM7 13H12V15H7z"></path>
      </svg>
    );
  }

  handleDensityView = (viewName) => {
    this.setState({ defaultDensityView: viewName });
  };

  getEtype(mtype, rowType) {
    let etype = "";
    etype = registersevice.getEtype(mtype, rowType);
    return etype;
  }

  getAccttype(mtype) {
    let accttype = "";
    accttype = registersevice.getAccttype(mtype);
    return accttype;
  }

  OpenInvoiceSlideOut = (e, newrow, rowIndex, rowType) => {
    let row: any = {};
    row.hid = newrow.hotelid;
    row.etype = "Invoice";
    row.vendor = newrow.vendor;
    row.backHIDCode = newrow.lettercode;
    row.vid = newrow.vid;
    row.oprId = newrow.oprID;
    row.uniqueno = newrow.uniqueno;
    row.ckno = newrow.ckno;
    row.series = newrow.series;
    row.invPostFix = newrow.postFix;
    row.groupID = newrow.groupID;
    this.hideSlideOut1(false);
    this.handlerowEvents(e, row, rowIndex, rowType);
  };

  actTypeName = (e) => {
    let acctType = "";
    e === "O"
      ? (acctType = "Operating")
      : e === "P"
      ? (acctType = "Payroll")
      : e === "M" && (acctType = "Miscellaneous");
    return acctType;
  };

  handlerowEvents = (e: any, row: any, rowIndex: any, rowType) => {
    row.etype = row.transType;
    
        let registerRequest1 = { ...this.state.registerRequest };        
        let etype = "";
        etype = this.getEtype(row.etype, rowType);

        let selectedRow : any ={checkno:"",hid:row.backHID,groupID:0,oprId:row.oprId,series:"",invPostFix:"",isDraftUpdate:"Yes"};

        this.setState({ pageType: etype });
        if (
          row.etype.toString().toLowerCase() ===
          "CreditCardWithdrawal".toLocaleLowerCase()
        ) {
          this.setState({
            selectedRow: selectedRow,
            ccSlideType: "CCDEBIT",
            trUniqueNo: row.uniqueNo,
            registerRequest: registerRequest1,
            vid: row.vid,
            oprId: row.oprId,
            isCCSlideout: true,
            sildeoutHid: row.backHID,
            sildeoutCode: row.ehid
            
          });
        } else if (
          row.etype.toString().toLowerCase() ===
          "CreditCardDeposit".toLocaleLowerCase()
        ) {
          this.setState({
            selectedRow: selectedRow,
            ccSlideType: "CCCREDIT",
            trUniqueNo: row.uniqueNo,
            registerRequest: registerRequest1,
            vid: row.vid,
            oprId: row.oprId,
            isCCSlideout: true,
            sildeoutHid: row.backHID,
            sildeoutCode: row.ehid,            
          });
        } else {
          this.setState({
            selectedRow: selectedRow,
            trUniqueNo: row.uniqueNo,
            registerRequest: registerRequest1,
            vid: row.vid,
            oprId: row.oprId,
            isSlideOut: true,
            sildeoutHid: row.backHID,
            sildeoutCode: row.ehid,
            
          });
        }
       
    
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {      
        this.handlerowEvents(e, row, rowIndex, "main");     
    },
  };

  epayrowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.handlerowEvents(e, row, rowIndex, "main");
    },
  };

  rowChildEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.handlerowEvents(e, row, rowIndex, "child");
    },
  };

  handleBtnClick = (type) => {
    let { expList, epayexpList } = this.state;
    if (type === "Collapse_All") {
      this.setState(() => ({
        expandedList: [],
        expandedEpayList: [],
      }));
    } else {
      this.setState(() => ({
        expandedList: expList,
        expandedEpayList: epayexpList,
      }));
    }
  };

  CleanSearch = () => {
    if (this.tableSearch.current !== null) {
      this.tableSearch.current.CleanSearch();
    }
  };
  onEditAccount = (eventKey) => {
    if (eventKey === "editaccount") {
      if (this.state.registerRequest.acctType === "C") {
        this.setState({ isCCShowSlideout: true });
      } else {
        this.setState({ isShowSlideout: true });
      }
    } else if (eventKey === "reimburse") {
      this.handleConcile("Reimburse");
    }
  };
  onMoreActionChange = (item) => {
    if (item.eventKey === "changeLog") {
      this.showHideChangeLogModal();
    }
    if (item.eventKey === "Export") {
      if (this.state.registerRequest.acctType === "O") {
        this.showHideExportModal();
      } else {
        this.exportToExcel("", "", "");
      }
    }
  };

  showHideExportModal = () => {
    this.setState({
      exportModal: !this.state.exportModal,
    });
  };

  handleConcile = (queryStringparm) => {
    if (queryStringparm != "Reimburse") {
      this.props.history.push("/reconcile", {
        hotelId: this.state.hidValue.toString(),
        letterCode: this.state.hotelName,
        oprID: this.state.registerRequest.oprID,
        Accper: "",
        fromPage: "register",
      });
    } else {
      User.GenerateToken()
        .then(async (result: any | null) => {
          if (result) {
            let mergeURL = result.result.mergeURL;
            let tokenID = result.result.tokenID;
            let pageURL,
              queryStringKey,
              queryStringValue,
              functionName = "";
            queryStringKey = "PageName";
            queryStringValue = queryStringparm;
            functionName = this.state.registerRequest.acctType;
            let hotelID = this.state.registerRequest.hotelID;
            let hCode = this.state.registerRequest.hotelName;
            let storage = JSON.parse(localStorage.getItem("storage")!);
            let userName = storage.userName;
            window.open(
              mergeURL +
                //"http://localhost:50491/login.aspx" +

                "?logInUser=" +
                btoa(userName) +
                "&tokenID=" +
                btoa(tokenID) +
                "&module=" +
                btoa("Accounting") +
                "&logInType=2&PageUrl=" +
                pageURL +
                "&Key=" +
                btoa(queryStringKey) +
                "&Value=" +
                btoa(queryStringValue) +
                "&function=" +
                btoa(functionName) +
                "&hid=" +
                hotelID +
                "&HCode=" +
                hCode +
                "&AcctID=" +
                this.state.registerRequest.oprID
            );
          }

          resolve();
        })
        .catch((error) => {
          reject();
        });
    }
  };

  showHideChangeLogModal = () => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal,
    });
  };

  GetDefaultHotelByAccount = () => {
    localStorage.setItem("subMenu", "Repeating");

    this.props.history.push("/repeating", {
      oprid: this.state.registerRequest.oprID,
      acctType: this.state.registerRequest.acctType,
      pageName: "register",
      acntName: this.state.accountName,
    });
  };

  render() {
    let isTrButton = true;
    let isImportButton =
      this.state.permissionlist.filter(
        (x) =>
          x.eType === "BulkImportDeposits" || x.eType === "BulkImportWithdrawal"
      ).length > 0
        ? true
        : false;
    if (this.state.isCCAcc) {
      isTrButton =
        this.state.permissionlist.filter(
          (x) =>
            x.eType === "AddDebit" ||
            x.eType === "AddCredit" ||
            x.eType === "AddPayment"
        ).length > 0
          ? true
          : false;
    } else {
      isTrButton =
        this.state.permissionlist.filter(
          (x) =>
            x.eType === "withdrawal" ||
            x.eType === "deposit" ||
            x.eType === "transfer"
        ).length > 0
          ? true
          : false;
    }

    const handleSelect = (eventKey: any) => {
      if (eventKey === "repeating") {
        this.GetDefaultHotelByAccount();
      }
    };
    const handleAddTransaction = (eventKey: any) => {
      if (eventKey === "deposit") {
        this.setState({
          isSlideOut: true,
          trUniqueNo: 0,
          sildeoutHid: this.state.hidValue,
          sildeoutCode: this.state.hotelName,
          pageType: "Deposit",
        });
      } else if (eventKey === "withdrawal") {
        this.setState({
          isSlideOut: true,
          trUniqueNo: 0,
          sildeoutHid: this.state.hidValue,
          sildeoutCode: this.state.hotelName,
          pageType: "Withdrawal",
        });
      } else if (eventKey === "transfer") {
        let { transferRequest } = this.state;
        let hasGLPermission: any = [];
        hasGLPermission = this.state.permissionlist.filter((x) =>
          x.eType
            .toLocaleLowerCase()
            .includes("View Bank and GL Balances".toLocaleLowerCase())
        );
        if (hasGLPermission.length > 0) {
          hasGLPermission.forEach((items) => {
            transferRequest.glArray.push(items.eType.charAt(0));
          });
        }
        this.setState({
          isTransferSlideOut: true,
          trUniqueNo: 0,
          transferRequest,
          sildeoutHid: this.state.hidValue,
          sildeoutCode: this.state.hotelName,
          pageType: "Transfer",
        });
      } else if (eventKey === "AddCredit") {
        this.setState({
          isCCSlideout: true,
          trUniqueNo: 0,
          ccSlideType: "CCCredit",
        });
      } else if (eventKey === "AddDebit") {
        this.setState({
          isCCSlideout: true,
          trUniqueNo: 0,
          ccSlideType: "CCDebit",
        });
      }
    };
    let viewType = this.state.viewType;
    const itemList = this.state.totalsharedHID;
    let CheckedHID: any = [];
    CheckedHID = itemList.filter((x) => x.isChecked === true);

    const columns = [
      {
        dataField: "vendor",
        text: "Vendor",        
        headerClasses: "regColDate"       
      },
      {
        dataField: "ehid",
        text: "EHID",        
        headerClasses: "regColDate"       
      },
      {
        dataField: "acctName",
        text: "Account",        
        headerClasses: "regColDate"       
      },
      {
        dataField: "date",
        text: "Date",        
        headerClasses: "regColDate"       
      },
      {
        dataField: "amount",
        text: "Amount",        
        headerClasses: "regColDate"       
      },
      {
        dataField: "transType",
        text: "TransType",        
        headerClasses: "regColDate"       
      },
      {
        dataField: "backHID",
        text: "backHID",        
        hidden: true,  
      },
      {
        dataField: "oprID",
        text: "oprID",        
        hidden: true,
      },
      {
        dataField: "bankSyncId",
        text: "bankSyncId",        
        hidden: true,     
      },
      {
        dataField: "transType",
        text: "transType",
        hidden: true,
      },
      {
        dataField: "uniqueNo",
        text: "uniqueNo",
        hidden: true,
      },
    ];

    const handleOnExpand = (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        this.setState(() => ({
          expandedList: [...this.state.expandedList, row.id],
        }));
      } else {
        let expandedEpayList = [...this.state.expandedEpayList];
        let filterRno = this.state.registerList.filter(
          (item) => item.viewType === 2 && item.refID === row.refID
        );
        filterRno.forEach((element) => {
          let rno = element.rNo;
          if (expandedEpayList.filter((x) => x === rno).length > 0) {
            const index = expandedEpayList.findIndex((x) => x === rno);
            expandedEpayList.splice(index, 1);
          }
        });

        this.setState(() => ({
          expandedList: this.state.expandedList.filter((x) => x !== row.id),
          expandedEpayList,
        }));
      }
    };

    const expandRow = {
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderChild(row),
      expanded: this.state.expandedList,
      showExpandColumn: true,
      expandByColumnOnly: true,
      onExpand: handleOnExpand,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const getRenderEpayChild = (registerList, row) => {
      return registerList.filter(
        (item) => item.viewType === 2 && item.refID === row.refID
      );
    };

    const getRenderChild = (registerList, row) => {
      if (row.directDeposit === "DirectDeposit") {
        return registerList.filter(
          (item) =>
            item.viewType === 3 &&
            item.directDeposit === row.directDeposit &&
            item.date === row.date &&
            item.hid === row.hid
        );
      } else {
        let newCheckNo = row?.ckno?.toLowerCase().includes("ach")
          ? "ach"
          : row?.ckno.toLowerCase();
        return registerList.filter(
          (item) =>
            (item.viewType === 3 &&
              item.uniqueno === row.uniqueno &&
              item.vid === row.vid &&
              item.date === row.date &&
              item.ckno?.toLowerCase() === newCheckNo &&
              item.etype === row.etype) ||
            (item.viewType === 3 &&
              (row.uniqueno === "-1" || row.uniqueno === "0") &&
              item.vid === row.vid &&
              item.date === row.date &&
              item.ckno?.toLowerCase() === row?.ckno.toLowerCase() &&
              item.etype === row.etype &&
              item.groupID === row.groupID &&
              item.invPostFix === row.invPostFix)
        );
      }
    };
    const getRenderSplitHID = (registerList, row) => {
      return registerList.filter(
        (splist) =>
          splist.viewType === 3 &&
          (splist.uniqueno ===
            row.uniqueno /* && (splist.payment === row.payment)*/ ||
            (row.uniqueno === "-1" &&
              splist.vid === row.vid &&
              splist.date1 === row.date1 &&
              splist.ckno === row.ckno))
      );
    };

    const renderChild = (row) => {
      if (row.ckno.toString().toLowerCase() === "epay") {
        let { registerList } = this.state;
        let epaysplitlist = [];
        epaysplitlist = getRenderEpayChild(registerList, row);
        openRow = row;

        console.log(registerList);

        if (row.isEnterPrise) isEnterPriseEntry = true;
        else isEnterPriseEntry = false;

        return (
          <>
            <ToolkitProvider
              keyField="epyChild_rNo"
              data={epaysplitlist}
              columns={epaycolumnChild}
              search
            >
              {(props) => (
                <>
                  <div className="ePayChidTable">
                    <BootstrapTable
                      {...props.baseProps}
                      id="tblepay"
                      keyField="rNo"
                      rowEvents={this.epayrowEvents}
                      expandRow={expandEpayRow}
                      hover
                      noDataIndication={
                        "No activity for the selected time period"
                      }
                    />
                  </div>
                </>
              )}
            </ToolkitProvider>
          </>
        );
      } else {
        let { registerList } = this.state;
        let splitlist = [];
        splitlist = getRenderChild(registerList, row);
        openRow = row;

        if (row.isEnterPrise) isEnterPriseEntry = true;
        else isEnterPriseEntry = false;

        return (
          <>
            <ToolkitProvider
              keyField="Child_rNo"
              data={splitlist}
              columns={columnChild}
              search
            >
              {(props) => (
                <>
                  <div className="forRegChildTable">
                    <BootstrapTable
                      {...props.baseProps}
                      keyField="id"
                      id="tblsplit"
                      rowEvents={this.rowChildEvents}
                      hover
                      noDataIndication={
                        "No activity for the selected time period"
                      }
                    />
                  </div>
                </>
              )}
            </ToolkitProvider>
          </>
        );
      }
    };

    const renderEpayChild = (row) => {
      let { registerList } = this.state;
      let splitlist = [];
      splitlist = getRenderChild(registerList, row);
      openRow = row;

      if (row.isEnterPrise) isEnterPriseEntry = true;
      else isEnterPriseEntry = false;

      return (
        <>
          <ToolkitProvider
            keyField="Child_rNo"
            data={splitlist}
            columns={columnChild}
            search
          >
            {(props) => (
              <>
                <div className="ePayChidSubTable">
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="id"
                    rowEvents={this.rowChildEvents}
                    hover
                    noDataIndication={
                      "No activity for the selected time period"
                    }
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </>
      );
    };

    const columnChild = [
      {
        dataField: "",
        text: "",
        headerClasses: "blank-col",
      },
      {
        headerFormatter: (column: any, colIndex: any) => {
          let { registerList } = this.state;
          let splitlist: any = []; //openRow
          splitlist = splitlist = getRenderChild(registerList, openRow);
          let invoceArray: any = [];
          for (let i = 0; i < splitlist.length; i++) {
            invoceArray.push(splitlist[i].invoiceNo);
          }
          const uniqueInvoceArray: any = new Set(invoceArray);
          const setUnique = [...uniqueInvoceArray];
          if (setUnique.length <= 0) {
            return <span></span>;
          } else if (setUnique[0] !== "") {
            return <span>{column.text}</span>;
          } else {
            return <span></span>;
          }
        },
        dataField: "invoiceNo",
        text: "INVOICE",
        headerClasses: "regChildColInvoice",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div
              className={
                row.invoiceNo === "" ? "hasInvoice" : "hasInvoice hasNotesIcon"
              }
            >
              <strong>
                <EllipsisWithTooltip placement="bottom">
                  {row.invoiceNo}
                </EllipsisWithTooltip>
              </strong>
              {row.invoiceNo !== "" && this.notesIcon()}
            </div>
          );
        },
      },
      {
        headerFormatter: (column: any, colIndex: any) => {
          let { registerList } = this.state;
          let splitlist: any = [];
          splitlist = splitlist = getRenderSplitHID(registerList, openRow);
          let splitHIDArray: any = [];
          for (let i = 0; i < splitlist.length; i++) {
            if (openRow.isEnterPrise)
              splitHIDArray.push(splitlist[i].splitCode);
          }

          const uniqueInvoceArray: any = new Set(splitHIDArray);
          const setUnique = [...uniqueInvoceArray];
          if (setUnique.length <= 0) {
            return <span></span>;
          } else if (setUnique[0] !== "") {
            return <span>{column.text}</span>;
          } else {
            return <span></span>;
          }
        },
        dataField: "",
        text: "EHID",
        headerClasses: "regChildColhid",
        //hidden: !isEnterPriseEntry,
        formatter: (cell: any, row: any, rowIndex: any) => {
          let dis = isEnterPriseEntry ? "block" : "none";
          return <div style={{ display: dis }}>{row.splitCode}</div>;
        },
      },
      {
        dataField: "coa",
        text: "COA #",
        headerClasses: "regChildColCOANo",
      },
      {
        dataField: "coaname",
        text: "COA NAME",
        headerClasses: "regChildColCOAName",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.coaname}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "description",
        text: "DESCRIPTION",
        headerClasses: "regChildColDescription",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.etype.toString().toLowerCase() === "transfer"
                ? row.internalnotes
                : row.description}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "",
        text: "",
        headerClasses: "blank-col",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return <div>.</div>;
        },
      },
      {
        dataField: "period",
        text: "A/C PERIOD",
        headerClasses: "regChildColAccPeriod",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="text-right">
              <EllipsisWithTooltip placement="bottom">
                {row.period}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "payment",
        text: "AMOUNT",
        headerClasses: "regChildColAmount",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="text-right">
              <EllipsisWithTooltip placement="bottom">
                {row.payment === "0.00" ? row.deposit : row.payment}
              </EllipsisWithTooltip>
            </div>
          );
        },
      },
      {
        dataField: "",
        text: "",
        headerClasses: "regChildColLastBlank",
      },
    ];

    const ePayOnExpand = (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        this.setState(() => ({
          expandedEpayList: [...this.state.expandedEpayList, row.rNo],
        }));
      } else {
        this.setState(() => ({
          expandedEpayList: this.state.expandedEpayList.filter(
            (x) => x !== row.rNo
          ),
        }));
      }
    };

    const expandEpayRow = {
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderEpayChild(row),
      expanded: this.state.expandedEpayList,
      showExpandColumn: true,
      expandByColumnOnly: true,
      onExpand: ePayOnExpand,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const epaycolumnChild = [
      {
        dataField: "",
        text: "",
        headerClasses: "date-col",
        classes: "date-col",
      },
      {
        dataField: "",
        text: "",
        headerClasses: "ehid-col",
        classes: "ehid-col",
      },
      {
        dataField: "vendor",
        text: "Vendor",
        headerClasses: "vendor-col",
        classes: "vendor-col",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.vendor.toString()}
            </EllipsisWithTooltip>
          );
        },
      },

      {
        dataField: "ckno",
        text: "Type",
        headerClasses: "type-col",
        classes: "type-col",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.ckno.toString()}
            </EllipsisWithTooltip>
          );
        },
      },
      
     
      {
        dataField: "",
        text: "",
        headerClasses: "payment-col",
        classes: "payment-col",
      },
      {
        dataField: "",
        text: "",
        headerClasses: "payment-col",
        classes: "payment-col",
      },
      {
        dataField: "payment",
        text: "Payment",
        headerClasses: "payment-col",
        classes: "payment-col",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.payment.toString()}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "",
        text: "",
        headerClasses: "cl-col",
        classes: "cl-col",
      },
    ];

    const columnRepeat = [
      {
        dataField: "vendor",
        text: "Vendor",
      },

      {
        dataField: "payment",
        text: "Payment",
      },
      {
        dataField: "deposit",
        text: "Deposit",
      },

      {
        dataField: "repeatDate",
        text: "Next Post Date",
      },
    ];

    return (
      <React.Fragment>
        {this.state.isExportRun && (
          <div className="matrixSpinnerCenter">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
        <div className={this.state.isBgBlue ? "bg-blue-static" : ""}>
          <ToastContainer
            autoClose={3000}
            //containerId={"Saveregister"}
            enableMultiContainer
          />
          {this.state.showChangeLogModal && (
            <TransactionLogHistory
              payPeriodStartDate={
                this.state.dateRange != ""
                  ? this.state.dateRange.split("-")[0].trim()
                  : ""
              }
              payPeriodEndDate={
                this.state.dateRange != ""
                  ? this.state.dateRange.split("-")[1].trim()
                  : ""
              }
              accType={"All" + "-" + this.state.registerRequest.acctType}
              showChangeLogModal={this.state.showChangeLogModal}
              showHideChangeLogModal={this.showHideChangeLogModal}
              hotelName={""}
              hotelId={this.state.registerRequest.hotelID}
              hideCrossAndClearFilters={false}
              shiftId={0}
              oprID={this.state.registerRequest.oprID}
              shiftDate={""}
              noOfDays={
                this.state.registerRequest.selectType === "DateRange"
                  ? 0
                  : this.state.registerRequest.selectType
              }
            />
          )}
          {this.state.isSlideOut && (
            <>
              {this.state.pageType === "Invoice" ? (
                <InvoiceEntrySlideout
                  hidValue={this.state.sildeoutHid}
                  hotelName={this.state.sildeoutCode}
                  uniqueNo={this.state.trUniqueNo}
                  pageType={this.state.pageType}
                  oprId={this.state.oprId}
                  vid={this.state.vid}
                  hideSlideOut={this.hideSlideOut.bind(this)}
                  OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                  selectedRow={this.state.selectedRow}
                  isFromRepeating={false}
                />
              ) : (
                <TransactionSlideout
                  accDetails={this.state.registerRequest}
                  hidValue={this.state.sildeoutHid}
                  hotelName={this.state.sildeoutCode}
                  uniqueNo={this.state.trUniqueNo}
                  pageType={this.state.pageType}
                  hideSlideOut={this.hideSlideOut.bind(this)}
                  OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                  selectedRow={this.state.selectedRow}
                  isFromRepeating={false}
                  callFrom = "Draft"
                />
              )}
            </>
          )}

          {this.state.isTransferSlideOut && (
            <TransferSlideout
              accDetails={this.state.registerRequest}
              hidValue={this.state.sildeoutHid}
              hotelName={this.state.sildeoutCode}
              uniqueNo={this.state.trUniqueNo}
              pageType={this.state.pageType}
              valuesArray={this.state.valuesArray}
              transferRequest={this.state.transferRequest}
              hideTrSlideOut={this.hideTrSlideOut.bind(this)}
              accountStatus={this.state.accountStatus}
              isfromSearch={this.state.isfromSearch}
            />
          )}

          {this.state.isReimburementSummaryPopup && (
            <ReconcileSummary
              hotelId={this.state.hidValue}
              accDetails={this.state.registerRequest}
            />
          )}

          <div className="register">
            <Container fluid className="body-sec">
              <div className="page-heading underline d-flex">
                <HidSelector
                  onClickEvent={this.selectHotelDropDown}
                  ref={this.childHID}
                  modulename={"Register"}
                />
                {this.state.loadingFromOther && (
                  <Spinner
                    style={{ fontSize: "12px" }}
                    animation="border"
                    size="sm"
                    variant="primary"
                  />
                )}
                {this.state.accountNameValue !== -1 && (
                  <>
                    <div className="mr-auto d-flex align-items-center">
                    Draft Transaction
                    </div>

                    <div className="action-group d-flex">
                      {this.state.accountStatus.toString().toLowerCase() ===
                        "active" && (
                        <>
                                               
                          {isTrButton && (
                            <Dropdown
                              className="more-action btn-dropdown"
                              alignRight
                              onSelect={handleAddTransaction}
                            >
                              <Dropdown.Toggle
                                className="btn btn-primary"
                                id="dropdown-more"
                              >
                                Add Transaction
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {this.state.addTransactionAction.map(
                                  (item: any, idx: any) => (
                                    <>
                                      {this.state.permissionlist.filter(
                                        (x) => x.eType === item.eventKey
                                      ).length > 0 && (
                                        <>{this.filterAccountList(item, idx)}</>
                                      )}
                                    </>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </>
                      )}
                     
                    </div>
                  </>
                )}
              </div>

              {this.state.hidValue !== "Select" && (
                <div className="main-Page">
                  <div className="bdy-sec">
                    <ToolkitProvider
                      keyField="Modal_rNo"
                      data={this.state.tableData}
                      columns={columns}
                      search
                      rowEvents={this.rowEvents}
                    >
                      {(props: {
                        searchProps: JSX.IntrinsicAttributes;
                        baseProps: JSX.IntrinsicAttributes;
                      }) => (
                        <div>
                          {this.state.accountNames.length > 0 && (
                            <div className="search-header registerHeaderWithoutSearch d-flex">
                              <div className="filter-section">
                                <div className="singleSearchDropdownListWithIcon">
                                  <SingleSearchDropdownList
                                    id={"tableAccountName"}
                                    ref={this.accountName}
                                    itemList={this.state.accountNames}
                                    handleSelectedItem={this.handleaccountNameSelectedItem.bind(
                                      this,
                                      1
                                    )}
                                    defaultItem={this.state.HIDToNM}
                                    defaultText={"Search..."}
                                    defaultName={this.state.accountName}
                                    calHomeIcon={true}
                                    currentChecked={this.state.accountName}
                                    controlID="2"
                                    pageType={"Register"}
                                    isStatusbadge={true}
                                  />
                                </div>
                              </div>                              
                            </div>
                          )}
                          
                          {this.state.isFilter && (
                            <div className="info-badges exported-date">
                              <div className="d-flex">
                                <div>
                                  This is a partial view and transactions are
                                  hidden, to view all{" "}
                                </div>
                                <div>
                                  <a
                                    className="d-flex"
                                    onClick={(e: any) => this.resetFilter(e)}
                                  >
                                    {" "}
                                    click here
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                          {this.state.accountNameValue !== -1 && (
                            <>
                              {!this.state.istableloader && (
                                <div
                                  className={`${this.state.defaultDensityView} fixHeader100 forRegParentTable tableToggleRow regular regScrollArea`}
                                >
                                  <BootstrapTable
                                    {...props.baseProps}
                                    keyField="id"
                                    id="tblmain121"
                                    hover
                                    rowEvents={this.rowEvents}
                                    //expandRow={expandRow}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </ToolkitProvider>

                    {this.state.istableloader && (
                      <div className="loader-responsive register">
                        <div className="performanceList">
                          <div className="tableList">
                            <ul>
                              <li className="d-flex">
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                                <div className="list d-flex align-self-center">
                                  <div className="group">
                                    <div className="loadingAnimation"></div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <Modal
                show={this.state.repeatingModal}
                onHide={this.handleRepeatingModalClose}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Repeating</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="register">
                    <div className="body-sec">
                      <div className="main-Page">
                        <div className="bdy-sec">
                          <ToolkitProvider
                            keyField="rNo"
                            data={this.state.repeatlist}
                            columns={columnRepeat}
                            search
                          >
                            {(props: {
                              searchProps: JSX.IntrinsicAttributes;
                              baseProps: JSX.IntrinsicAttributes;
                            }) => (
                              <div>
                                <div className="search-header d-flex">
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder={"Filter..."}
                                  />
                                </div>
                                <div className="scroll-area">
                                  <BootstrapTable
                                    {...props.baseProps}
                                    keyField="id"
                                    hover
                                    noDataIndication={
                                      "No activity for the selected time period"
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </ToolkitProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </Container>
            {this.state.creditCardStatementModal && (
              <>
                <CreditCardStatementImport
                  pageName="Register"
                  hidValue={this.state.hidValue}
                  hotelName={this.state.hotelName}
                  oprId={this.state.CardUniqueNo}
                  hideCreditCardModal={this.hideCreditCardModal.bind(this)}
                />
              </>
            )}
            {this.state.isCCSlideout && (
              <CreditCardSlideout
                accDetails={this.state.registerRequest}
                hidValue={this.state.hidValue}
                hotelName={this.state.hotelName}
                uniqueNo={this.state.trUniqueNo}
                oprId={this.state.oprId}
                vid={this.state.vid}
                pageType={this.state.ccSlideType}
                hideCCSlideOut={this.hideCCSlideOut.bind(this)}
                slideOutFrom="register-page-creditcard-slideout"
                selectedRow={this.state.selectedRow}
                callFrom = "Draft"
              />
            )}
            {this.state.isBulkImportSlideout && (
              <BulkImportSlideout
                {...this.state.bulkImportRequest}
                hideBulkImportSlideout={this.hideBulkImportSlideout.bind(this)}
              />
            )}
            {this.state.CCPaymentShow && (
              <CreditCardPayment
                parentState={this.state}
                hideCCPaymentModal={(e) => {
                  this.hideCCPaymentModal();
                }}
              />
            )}

            {this.state.isShowSlideout && (
              <BankAccountSlideout
                hidValue={this.state.hidValue}
                hotelName={this.state.hotelName}
                oprID={this.state.registerRequest.oprID}
                acctType={this.state.registerRequest.acctType}
                actionType={"edit"}
                hideSlideOut={this.hideBankSlideOut.bind(this)}
                accountName={this.state.bName}
              ></BankAccountSlideout>
            )}

            {this.state.isCCShowSlideout && (
              <CreditCardAccountSlideout
                hidValue={this.state.hidValue}
                hotelName={this.state.hotelName}
                oprID={this.state.registerRequest.oprID}
                acctType={this.state.registerRequest.acctType}
                actionType={"edit"}
                hideCreditCardSlideOut={this.hideBankSlideOut.bind(this)}
                accountName={this.state.bName}
              ></CreditCardAccountSlideout>
            )}

            {this.state.exportModal && (
              <RegisterExport
                ref={this.exportModal}
                parentState={this.state}
                registerExoprtModalClose={this.showHideExportModal}
                exportToExcel={this.exportToExcel}
              ></RegisterExport>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  exportToExcel = (type: any, startDate: any, endDate: any) => {
    let req = { ...this.state.registerRequest };
    if (req.acctType !== "O") {
      this.setState({ isExportRun: true });
    }
    let registerExportReq: any = {};
    registerExportReq.hotelID = this.state.hidValue;
    registerExportReq.Type = type;
    registerExportReq.startDate =
      req.acctType === "O" ? startDate : req.startDate;
    registerExportReq.endDate = req.acctType === "O" ? endDate : req.endDate;
    registerExportReq.OprID = req.oprID;
    registerExportReq.SelectType = req.acctType === "O" ? "" : req.selectType;
    registerExportReq.IsDepositHide =
      req.acctType === "O" ? "" : req.isDepositHide;
    registerExportReq.AccountType = req.acctType;
    registerExportReq.IsInvoiceShow = "No";
    registerExportReq.SortOrder = this.state.sortingOrder;
    

    registersevice
      .ExportRegister(registerExportReq)
      .then(async (result: any) => {
        this.setState({ isExportRun: false });
        if (req.acctType === "O") this.showHideExportModal();
        this.exportModal?.current?.stopLoader();
        resolve();
      })
      .catch((error) => {
        this.setState({ isExportRun: false });
        this.exportModal?.current?.stopLoader();
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  };
}
