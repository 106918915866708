import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEllipsisH} from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col, Button, DropdownButton, Dropdown, ButtonToolbar, Card} from 'react-bootstrap';


  const UserManagement: React.FC  = () => {
    return(
        <div className="bdy-container">
            <div className="wht-bg shadow-bg">
                <Container fluid>
                    <Row className="screen-action">
                        <Col>
                            <div className="screen-heading flt-left">User Management</div>
                         
                        </Col>
                        <Col>
                            <ButtonToolbar className="justify-content-end">
                                <Button variant="primary">Save</Button>
                                <DropdownButton className="sub-nav rm-arrow" variant="default" id="nested-dropdown" alignRight title={<FontAwesomeIcon icon={faEllipsisH}  />}>
                                    <Dropdown.Item className="d-flex align-items-center">Vendor Log</Dropdown.Item>
                                    <Dropdown.Item className="d-flex align-items-center">Close</Dropdown.Item>
                                </DropdownButton>
                            </ButtonToolbar>                            
                        </Col>
                    </Row>
                </Container> 
            </div>
            <section>
                    <Container fluid>
                    <Card>
                        <Card.Header className="card-heading">
                            <Row className="d-flex align-items-center">
                                <Col>Search Or Add Vendor</Col>
                                <Col>
                                    <ButtonToolbar className="justify-content-end">
                                        <Button variant="primary">Save</Button>
                                        <Button variant="primary">Save</Button>
                                    </ButtonToolbar>
                                </Col>
                            </Row>                               
                        </Card.Header>
                        <Card.Body>
                                                                    
                        </Card.Body>
                    </Card>
                    </Container>
            </section>   
        </div>                            
    )
}
 export default UserManagement;

