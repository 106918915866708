import * as React from "react";
import { Form, Container, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Pdf from "react-to-pdf";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { resolve, reject } from "q";
import profile from "../../Common/Assets/Images/profile.png";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { HidSelector } from "../../Common/Components/HidSelector";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import ForecastLock from "../../Common/Assets/Images/icons/forecast-lock.svg";
import { LaborSchedule } from "../../Common/Services/LaborSchedule";
import { schConversions } from "../LaborManagement/Labor/Schedule/commonFunctions/conversions";
import { Hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import {
  ILaborScheduleDeptPositionRequestDto,
  ILabourScheduleRequestDto,
} from "../../Common/Contracts/ILaborSchedule";
import { Utils } from "../../Common/Utilis";

let mobileUserSelectedDay: string = "";
let mobileDeptSelectedDay: string = "";

export class MySchedule extends React.Component<any, any> {
  private childHID: any;
  private ref: any;
  constructor(props: any) {
    super(props);
    this.childHID = React.createRef();
    this.ref = React.createRef();
    this.state = {
      isLoader: false,
      mobileLoader: false,
      hidValue: "Select",
      hotelName: "Select",
      todayDate: "",
      tempScheduleData: [],
      MyScheduleData: [],
      ScheduleData: [],
      departmentName: "Department",
      departmentId: 0,
      departmentList: [],
      mystartDate: "",
      myendDate: "",
      startDate: "",
      endDate: "",
      lockDays: [],
      mylockDays: [],
      headerDates: [],
      myheaderFullDates: [],
      myheaderDates: [],
      mySchedulePDF: [],
      isDeepLinking: false,
      currentUrl: "",
      mobileUserSelectedDate: {},
      mobileDeptSelectedDate: {},
    };
  }

  componentDidMount() {
    let deepParam = localStorage.getItem("deepParam");
    let param = "";
    if (deepParam !== "") {
      try {
        param = atob(String(window.location.href.split("?")[1]));
      } catch {
        this.setState({ isLoader: false, mobileLoader: false });
        window.location.href = "/unauthorize-access";
      }
      let currentURL = window.location.href.split("?")[0] + "?" + param;

      this.setState({ currentUrl: currentURL });
      if (currentURL.includes("?")) {
        this.setState({ isDeepLinking: true, isLoader: true });
        sessionStorage.removeItem("hotelSelected");
        localStorage.removeItem("hotelSelected");
        let hotelDetails: any[] = [];
        let details = {} as any;

        Hotel.getUserHotelAccess("Labor Management")
          .then(async (result: IHotelResponseDto[] | null) => {
            if (result != null) {
              let x = result.filter(
                (items) =>
                  items.hotelID ===
                  parseInt(this.getUrlParameter(currentURL, "hid"))
              );

              if (x.length > 0) {
                details.ehidName = x[0].ehidName;
                details.hotelID = x[0].hotelID;
                details.hotelName = x[0].hotelName;
                details.hotelType = x[0].hotelType;
                details.lettercode = x[0].lettercode;
                details.tenantID = x[0].tenantID;
                details.timeZone = x[0].timeZone;
                hotelDetails.push(details);

                sessionStorage.setItem(
                  "hotelSelected",
                  JSON.stringify(hotelDetails[0])
                );
                localStorage.setItem(
                  "hotelSelected",
                  JSON.stringify(hotelDetails[0])
                );

                this.selectHotelDropDown(hotelDetails[0]);
              } else if (result.length > 1) {
                details.hotelName = "Select";
                details.hotelType = "No";
                details.lettercode = "Select";
                details.tenantID = 0;
                hotelDetails.push(details);
                this.selectHotelDropDown(hotelDetails[0]);
              }
            }
          })
          .catch((err) => {
            Utils.toastError(`Server Error: ${err}`);
            this.setState({ isLoader: false, mobileLoader: false });
          });
        return;
      }
    }

    // this.setState({
    //   mobileUserSelectedDate: moment(),
    //   mobileDeptSelectedDate: moment(),
    // });
  }

  getUrlParameter = (fullURL: string, name: string) => {
    name = name.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    let results = regex.exec(fullURL);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  selectHotelDropDown = (hotelDetails: any) => {
    this.setState({ isLoader: true, mobileLoader: true });
    let tempDate: any;
    let currentDate: any;
    let scheduleDate: any = new Date();
    if (
      JSON.parse(localStorage.storage).currentUtcTime !== "" &&
      JSON.parse(localStorage.storage).currentUtcTime !== null &&
      JSON.parse(localStorage.storage).currentUtcTime !== undefined
    ) {
      scheduleDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    }
    let newTempDate = "";
    if (this.state.isDeepLinking) {
      newTempDate = this.getUrlParameter(this.state.currentUrl, "date");
    } else {
      let currentdate = scheduleDate.getDate();
      let currentMonth = scheduleDate.getMonth() + 1;
      let currentYear = scheduleDate.getFullYear();
      newTempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    }
    currentDate = scheduleDate;
    tempDate = newTempDate;
    this.setState({
      hotelName: hotelDetails.lettercode,
      hidValue: hotelDetails.hotelID,
      todayDate: tempDate,
      calenderSelectDate: currentDate,
      departmentName:"Department"
    });
    if (tempDate.length <= 10) {
      if (hotelDetails.hotelType !== "No") {
        this.getDefaultDepartment(hotelDetails.hotelID, tempDate, 3);
        this.getMySchedulePDFDetails(hotelDetails.hotelID, tempDate);
      }
    } else {
      window.location.href = "/unauthorize-access";
    }
  };

  getMySchedulePDFDetails(hotelId: any, date: any) {
    let request = {} as any;
    request.date = date;
    request.hotelID = hotelId;
    this.setState({ isLoader: true });
    LaborSchedule.LaborPrintMySchedule(request)
      .then(async (result: any) => {
        if (result.message === "Success") {
          this.setState({
            mySchedulePDF: result.result,
          });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`);
        this.setState({ isLoader: false });
        reject();
      });
  }

  handleNevigation = (type: any, mode: any) => {
    let newTempDate: any;
    let scheduleDate: any;
    let currentdate: any;
    if (type === "next") {
      scheduleDate =
        mode === 1
          ? new Date(this.state.myendDate)
          : new Date(this.state.endDate);
      scheduleDate.setDate(scheduleDate.getDate() + 1);
    } else {
      scheduleDate =
        mode === 1
          ? new Date(this.state.mystartDate)
          : new Date(this.state.startDate);
      scheduleDate.setDate(scheduleDate.getDate() - 1);
    }
    currentdate = scheduleDate.getDate();
    let currentMonth = scheduleDate.getMonth() + 1;
    let currentYear = scheduleDate.getFullYear();
    newTempDate = currentMonth + "/" + currentdate + "/" + currentYear;
    this.setState({
      todayDate: newTempDate,
      calenderSelectDate: scheduleDate,
    });
    // if (mode === 1) {
      this.getMySchedulePDFDetails(this.state.hidValue, newTempDate);
    // }
    this.getScheduleData(
      this.state.hidValue,
      newTempDate,
      this.state.departmentId,
      3
    );
  };

  getDefaultDepartment = (hotelID: any, tempDate: any, mode: any) => {
    let request = {} as any;
    request.hid = hotelID;
    this.setState({ isLoader: true });
    LaborSchedule.LaborDefaultDepartMentName(request)
      .then(async (result: any) => {
        if (result.result.departmentID > 0) {
          this.setState({
            departmentId: result.result.departmentID,
            departmentName: result.result.departmentName,
          });
        }
        this.getScheduleData(
          hotelID,
          tempDate,
          result.result.departmentID,
          mode
        );
        if (hotelID !== "Select") {
          let requestPosDept = {} as ILaborScheduleDeptPositionRequestDto;
          requestPosDept.hotelID = hotelID;
          requestPosDept.type = "MySchedule";
          this.getPostionDepartment(requestPosDept);
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
        this.setState({ isLoader: false });
      });
  };

  getScheduleData = (
    hotelID: any,
    tempDate: any,
    departmentID: any,
    mode: any
  ) => {
    this.setState({ isLoader: true, mobileLoader: true });
    let request = {} as ILabourScheduleRequestDto;
    request.HotelID = hotelID;
    request.ViewType = "Users";
    request.DayLayOut = 7;
    request.filter = "Alphabetical";
    request.Date = tempDate;
    request.pageName = "myschedule";
    LaborSchedule.GetLaborScheduleData(request)
      .then(async (result: any) => {
        if (result != null && result.length > 0) {
          let tempScheduleData: any = [];
          let calnderDatesDispaly: any = [];
          let isPageLock: any = false;
          let lockDays: any = [];
          let headerDates: any = [];
          let headerFullDates: any = [];
          let headerDays: any = [];
          let currentDate: any = {};
          let shiftType: any = "";

          for (let i = 0; i < result.length; i++) {
            if (result[i].employee !== "Total") {
              currentDate = new Date(result[i].currentDate);
              shiftType = result[i].shiftType;
              let displayDates: any = result[i].payPeriodDate.split("_");
              calnderDatesDispaly = displayDates;
              let tempRowObject: any = {};
              tempRowObject.rowNo = i;
              tempRowObject.showUserList = "showOptions";
              tempRowObject.multipleShiftVisibility = "hideDropdown";
              tempRowObject.bulkSelect = false;
              tempRowObject.shiftType = result[i].shiftType;

              if (result[i].shiftType === "UnAssigned") {
                tempRowObject.employeeImage = "";
                tempRowObject.employeeName = "Unassigned Shifts";
                tempRowObject.timeDetails = "";
                tempRowObject.timeDetailsSummary = "";
                tempRowObject.actualHour = 0;
              } else {
                tempRowObject.employeeImage = result[i].imagePath;
                tempRowObject.employeeName = result[i].employee;
                tempRowObject.timeDetails = result[i].ot;
                let time = result[i].ot.split("/");
                tempRowObject.actualHour = Number(
                  time[0].trim().replace(":", ".")
                );
                if (
                  Number(time[0].trim().replace(":", ".")) >
                  Number(time[1].trim().replace(":", "."))
                ) {
                  tempRowObject.timeDetailsSummary = "overtime";
                } else {
                  tempRowObject.timeDetailsSummary = "undertime";
                }
              }
              let day1: any = [];
              let day2: any = [];
              let day3: any = [];
              let day4: any = [];
              let day5: any = [];
              let day6: any = [];
              let day7: any = [];
              for (let j = 1; j <= 7; j++) {
                let pushFlag = "";
                let multiShiftPushArray: any = [];
                let dayValues: any = [];
                let day = `day${j}`;
                let shiftObject: any = {};
                if (result[i][day] === null) {
                  shiftObject.shiftDate = "";
                  shiftObject.shiftDay = "";
                  shiftObject.shiftInTime = "";
                  shiftObject.shiftOutTime = "";
                  shiftObject.displayTime = "";
                  shiftObject.deptId = "";
                  shiftObject.positionId = "";
                  if (shiftType === "UnAssigned") {
                    shiftObject.shiftPosition = "";
                  } else {
                    shiftObject.shiftPosition = "+";
                  }
                  shiftObject.optionsItems = "showOptions";
                  shiftObject.shiftType = "blankShift";
                  shiftObject.shiftIsMultiple = "";
                  shiftObject.shiftAction = "";
                  shiftObject.shiftbulkSelect = false;
                  shiftObject.uniqueno = "";
                  shiftObject.indate = "";
                  shiftObject.outdate = "";
                  shiftObject.typeMaster = "1";
                  shiftObject.actualPunches = "No";
                } else {
                  if (result[i][day].includes("!!")) {
                    pushFlag = "multipleShift";

                    let multiShift = result[i][day].split("!!");
                    for (let k = 0; k < multiShift.length; k++) {
                      dayValues = multiShift[k].split("||");
                      let pushObject: any = {};
                      pushObject.shiftDate = dayValues[0];
                      pushObject.shiftDay = dayValues[1];
                      if (dayValues[2] === "") {
                        pushObject.shiftInTime = "";
                        pushObject.shiftOutTime = "";
                        pushObject.displayTime = "";
                      } else {
                        pushObject.shiftInTime = dayValues[2];
                        pushObject.shiftOutTime = dayValues[3];
                        pushObject.displayTime = `${dayValues[2]} - ${dayValues[3]}`;
                      }
                      pushObject.deptId = dayValues[4];
                      pushObject.positionId = dayValues[5];
                      pushObject.optionsItems = "showOptions";
                      if (dayValues[2] === "") {
                        pushObject.shiftPosition = "+";
                      } else {
                        pushObject.shiftPosition =
                          schConversions.replaceInvalidChar(dayValues[6]);
                      }
                      if (shiftType === "UnAssigned") {
                        pushObject.shiftType = "unassigned";
                      } else if (dayValues[7] === "Yes") {
                        pushObject.shiftType = "published";
                      } else {
                        pushObject.shiftType = "unpublished";
                      }
                      if (dayValues[2] === "") {
                        pushObject.shiftType = "blankShift";
                      }
                      pushObject.shiftIsMultiple = dayValues[8];
                      pushObject.shiftAction = "";
                      pushObject.shiftbulkSelect = false;
                      pushObject.uniqueno = dayValues[9];
                      pushObject.indate = dayValues[10];
                      pushObject.outdate = dayValues[11];
                      pushObject.typeMaster = dayValues[12];
                      pushObject.actualPunches = dayValues[13];

                      if (dayValues[12] === "3") {
                        pushObject.shiftPosition = "PTO";
                        pushObject.shiftType = "ptoRequest";
                      }
                      if (dayValues[12] === "4") {
                        pushObject.shiftPosition = "PTO";
                        pushObject.shiftType = "ptoApproved";
                      }
                      if (dayValues[12] === "5") {
                        pushObject.shiftPosition = "UTO ";
                        pushObject.shiftType = "ptoRequest";
                      }
                      if (dayValues[12] === "6") {
                        pushObject.shiftPosition = "UTO";
                        pushObject.shiftType = "ptoApproved";
                      }
                      if (dayValues[12] === "8") {
                        pushObject.shiftPosition = "Do not schedule";
                        pushObject.shiftType = "ptoApproved";
                      }
                      if (dayValues[12] === "10") {
                        pushObject.shiftPosition = "Do not schedule";
                        pushObject.shiftType = "ptoRequest";
                      }

                      multiShiftPushArray.push(pushObject);
                    }
                  } else {
                    pushFlag = "singleShift";

                    dayValues = result[i][day].split("||");

                    shiftObject.shiftDate = dayValues[0];
                    shiftObject.shiftDay = dayValues[1];
                    if (dayValues[2] === "") {
                      shiftObject.shiftInTime = "";
                      shiftObject.shiftOutTime = "";
                      shiftObject.displayTime = "";
                    } else {
                      shiftObject.shiftInTime = dayValues[2];
                      shiftObject.shiftOutTime = dayValues[3];
                      shiftObject.displayTime = `${dayValues[2]} - ${dayValues[3]}`;
                    }
                    shiftObject.deptId = dayValues[4];
                    shiftObject.positionId = dayValues[5];
                    shiftObject.optionsItems = "showOptions";
                    if (dayValues[2] === "") {
                      if (calnderDatesDispaly[2] === "No") {
                        shiftObject.shiftPosition = " ";
                      } else {
                        shiftObject.shiftPosition = "+";
                      }
                    } else {
                      shiftObject.shiftPosition =
                        schConversions.replaceInvalidChar(dayValues[6]);
                    }
                    if (shiftType === "UnAssigned") {
                      shiftObject.shiftType = "unassigned";
                    } else if (dayValues[7] === "Yes") {
                      shiftObject.shiftType = "published";
                    } else {
                      shiftObject.shiftType = "unpublished";
                    }
                    if (dayValues[2] === "") {
                      shiftObject.shiftType = "blankShift";
                    }
                    shiftObject.shiftIsMultiple = dayValues[8];
                    shiftObject.shiftAction = "";
                    shiftObject.shiftbulkSelect = false;
                    shiftObject.uniqueno = dayValues[9];
                    shiftObject.indate = dayValues[10];
                    shiftObject.outdate = dayValues[11];
                    shiftObject.typeMaster = dayValues[12];
                    shiftObject.actualPunches = dayValues[13];
                    if (dayValues[12] === "3") {
                      shiftObject.shiftPosition = "PTO";
                      shiftObject.shiftType = "ptoRequest";
                    }
                    if (dayValues[12] === "4") {
                      shiftObject.shiftPosition = "PTO";
                      shiftObject.shiftType = "ptoApproved";
                    }
                    if (dayValues[12] === "5") {
                      shiftObject.shiftPosition = "UTO ";
                      shiftObject.shiftType = "ptoRequest";
                    }
                    if (dayValues[12] === "6") {
                      shiftObject.shiftPosition = "UTO";
                      shiftObject.shiftType = "ptoApproved";
                    }
                    if (dayValues[12] === "8") {
                      shiftObject.shiftPosition = "Do not schedule";
                      shiftObject.shiftType = "ptoApproved";
                    }
                    if (dayValues[12] === "10") {
                      shiftObject.shiftPosition = "Do not schedule";
                      shiftObject.shiftType = "ptoRequest";
                    }
                  }
                }

                if (pushFlag === "multipleShift") {
                  let multiShiftAddObject: any = {};
                  multiShiftAddObject.shiftDate = "";
                  multiShiftAddObject.shiftDay = "";
                  multiShiftAddObject.shiftInTime = "";
                  multiShiftAddObject.shiftOutTime = "";
                  multiShiftAddObject.displayTime = "";
                  multiShiftAddObject.deptId = "";
                  multiShiftAddObject.positionId = "";
                  multiShiftAddObject.shiftPosition = "+";
                  multiShiftAddObject.shiftType = "blankShift";
                  multiShiftAddObject.shiftIsMultiple = "";
                  multiShiftAddObject.shiftAction = "";
                  multiShiftAddObject.shiftbulkSelect = false;
                  multiShiftAddObject.uniqueno = "";
                  multiShiftAddObject.indate = "";
                  multiShiftAddObject.outdate = "";
                  multiShiftAddObject.typeMaster = "1";
                  multiShiftAddObject.actualPunches = "No";
                  if (multiShiftPushArray.length > 0) {
                    const firtstElemMultiShift = multiShiftPushArray[0];
                    const shiftDateFitstElement =
                      firtstElemMultiShift.shiftDate;
                    const dateShiftDateFirstElement = new Date(
                      shiftDateFitstElement
                    );
                    const currentDate = result[i].currentDate;
                    const dateCurrentDate = new Date(currentDate);
                    if (dateShiftDateFirstElement >= dateCurrentDate) {
                      multiShiftPushArray.push(multiShiftAddObject);
                    }
                  }

                  if (day === "day1") {
                    day1 = multiShiftPushArray;
                  } else if (day === "day2") {
                    day2 = multiShiftPushArray;
                  } else if (day === "day3") {
                    day3 = multiShiftPushArray;
                  } else if (day === "day4") {
                    day4 = multiShiftPushArray;
                  } else if (day === "day5") {
                    day5 = multiShiftPushArray;
                  } else if (day === "day6") {
                    day6 = multiShiftPushArray;
                  } else if (day === "day7") {
                    day7 = multiShiftPushArray;
                  }
                } else {
                  if (day === "day1") {
                    day1.push(shiftObject);
                  } else if (day === "day2") {
                    day2.push(shiftObject);
                  } else if (day === "day3") {
                    day3.push(shiftObject);
                  } else if (day === "day4") {
                    day4.push(shiftObject);
                  } else if (day === "day5") {
                    day5.push(shiftObject);
                  } else if (day === "day6") {
                    day6.push(shiftObject);
                  } else if (day === "day7") {
                    day7.push(shiftObject);
                  }
                }

                if (dayValues.length > 0 && result[i].employee === "header") {
                  let testDate = new Date(dayValues[0]);
                  if (testDate < currentDate) {
                    lockDays.push(day);
                  }

                  let tempHeaderDate = dayValues[0].substring(0, 5);
                  headerDates.push(tempHeaderDate);
                  headerFullDates.push(dayValues[0]);
                  headerDays.push(dayValues[1]);
                }

                tempRowObject.day1 = day1;
                tempRowObject.day2 = day2;
                tempRowObject.day3 = day3;
                tempRowObject.day4 = day4;
                tempRowObject.day5 = day5;
                tempRowObject.day6 = day6;
                tempRowObject.day7 = day7;
              }

              tempScheduleData.push(tempRowObject);
            }
          }
          const uniqueLockDays: any = new Set(lockDays);
          const arrayLockDays = [...uniqueLockDays];
          const uniqueHeaderDates: any = new Set(headerDates);
          const arrayHeaderDates = [...uniqueHeaderDates];
          const uniqueHeaderFullDates: any = new Set(headerFullDates);
          const arrayHeaderFullDates = [...uniqueHeaderFullDates];
          const uniqueHeaderDays: any = new Set(headerDays);
          const arrayHeaderDays = [...uniqueHeaderDays];
          if (arrayLockDays.length >= 7) {
            isPageLock = true;
          }
          let storage = JSON.parse(localStorage.getItem("storage")!);

          // Updating Mobile selection Dates Starts
          let selectedDate: any;
          if (arrayHeaderFullDates.includes(moment().format("MM/DD/YY"))) {
            selectedDate = moment();
          } else {
            selectedDate = moment(arrayHeaderFullDates[0]);
          }
          if (mode === 1) {
            this.setState({
              mobileUserSelectedDate: selectedDate,
            });
          } else if (mode === 2) {
            this.setState({
              mobileDeptSelectedDate: selectedDate,
            });
          } else {
            this.setState({
              mobileUserSelectedDate: selectedDate,
              mobileDeptSelectedDate: selectedDate,
            });
          }
          // Updating Mobile selection Dates Ends

          if (mode === 1) {
            this.setState({
              isPageLock: isPageLock,
              mylockDays: arrayLockDays,
              myheaderDates: arrayHeaderDates,
              myheaderFullDates: arrayHeaderFullDates,
              myheaderDays: arrayHeaderDays,
              MyScheduleData: tempScheduleData.filter(
                (row: any) =>
                  row.shiftType !== "UnAssigned" &&
                  row.employeeName.toLowerCase() ===
                    storage.userName.toLowerCase()
              ),
              mystartDate: arrayHeaderFullDates[0],
              myendDate: arrayHeaderFullDates[6],
            });
          } else if (mode === 2) {
            this.setState({
              isPageLock: isPageLock,
              lockDays: arrayLockDays,
              headerDates: arrayHeaderDates,
              headerFullDates: arrayHeaderFullDates,
              headerDays: arrayHeaderDays,
              tempScheduleData: tempScheduleData.filter(
                (row: any) =>
                  row.shiftType !== "UnAssigned" &&
                  row.employeeName.toLowerCase() !==
                    storage.userName.toLowerCase() &&
                  row.actualHour !== 0
              ),
              startDate: arrayHeaderFullDates[0],
              endDate: arrayHeaderFullDates[6],
            });

            if (departmentID > 0) {
              this.filterDataByDepartment(
                departmentID,
                tempScheduleData.filter(
                  (row: any) =>
                    row.shiftType !== "UnAssigned" &&
                    row.employeeName.toLowerCase() !==
                      storage.userName.toLowerCase() &&
                    row.actualHour !== 0
                )
              );
            } else {
              this.setState({
                ScheduleData: tempScheduleData.filter(
                  (row: any) =>
                    row.shiftType !== "UnAssigned" &&
                    row.employeeName.toLowerCase() !==
                      storage.userName.toLowerCase() &&
                    row.actualHour !== 0
                ),
              });
            }
          } else {
            this.setState({
              isPageLock: isPageLock,
              lockDays: arrayLockDays,
              mylockDays: arrayLockDays,
              myheaderDates: arrayHeaderDates,
              myheaderFullDates: arrayHeaderFullDates,
              myheaderDays: arrayHeaderDays,
              headerDates: arrayHeaderDates,
              headerFullDates: arrayHeaderFullDates,
              headerDays: arrayHeaderDays,
              tempScheduleData: tempScheduleData.filter(
                (row: any) =>
                  row.shiftType !== "UnAssigned" &&
                  row.employeeName.toLowerCase() !==
                    storage.userName.toLowerCase() &&
                  row.actualHour !== 0
              ),
              MyScheduleData: tempScheduleData.filter(
                (row: any) =>
                  row.shiftType !== "UnAssigned" &&
                  row.employeeName.toLowerCase() ===
                    storage.userName.toLowerCase()
              ),
              mystartDate: arrayHeaderFullDates[0],
              myendDate: arrayHeaderFullDates[6],
              startDate: arrayHeaderFullDates[0],
              endDate: arrayHeaderFullDates[6],
            });

            if (departmentID > 0) {
              this.filterDataByDepartment(
                departmentID,
                tempScheduleData.filter(
                  (row: any) =>
                    row.shiftType !== "UnAssigned" &&
                    row.employeeName.toLowerCase() !==
                      storage.userName.toLowerCase() &&
                    row.actualHour !== 0
                )
              );
            } else {
              this.setState({
                ScheduleData: tempScheduleData.filter(
                  (row: any) =>
                    row.shiftType !== "UnAssigned" &&
                    row.employeeName.toLowerCase() !==
                      storage.userName.toLowerCase() &&
                    row.actualHour !== 0
                ),
              });
            }
          }
        }

        resolve();
        this.setState({ isLoader: false, mobileLoader: false });
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`);
        this.setState({ isLoader: false, mobileLoader: false });
        reject();
      });
  };

  headerFormatter = (column: any, colIndex: any, row: any) => {
    let scheduleLockDisplay = false;
    let lockDays = this.state.lockDays;
    let headerDates = this.state.headerDates;
    let headerDays = this.state.headerDays;

    if (lockDays.includes(column.dataField)) {
      scheduleLockDisplay = true;
    }

    return (
      <div className="column-header">
        <div className="header-data">
          {scheduleLockDisplay && (
            <span className="schedule-lock">
              <img src={ForecastLock} alt="ScheduleLock" />
              <span className="customToolTip">
                The schedule cannot be changed because a shift from this day has
                already begun.
              </span>
            </span>
          )}

          <span className="header-day">{headerDays[colIndex - 1]}</span>
        </div>
        <p className="header-date">{headerDates[colIndex - 1]}</p>
      </div>
    );
  };

  myheaderFormatter = (column: any, colIndex: any, row: any) => {
    let scheduleLockDisplay = false;
    let lockDays = this.state.mylockDays;
    let headerDates = this.state.myheaderDates;
    let headerDays = this.state.myheaderDays;
    if (lockDays.includes(column.dataField)) {
      scheduleLockDisplay = true;
    }
    return (
      <div className="column-header">
        <div className="header-data">
          {scheduleLockDisplay && (
            <span className="schedule-lock">
              <img src={ForecastLock} alt="ScheduleLock" />
              <span className="customToolTip">
                The schedule cannot be changed because a shift from this day has
                already begun.
              </span>
            </span>
          )}
          <span className="header-day">{headerDays[colIndex - 1]}</span>
        </div>
        <p className="header-date">{headerDates[colIndex - 1]}</p>
      </div>
    );
  };

  dayFormatter = (cell: any, row: any, rowIndex: any, dayType: any) => {
    let currentRow = row[dayType];
    let schedule: any = {};
    if (
      currentRow.filter((a: { deptId: string }) => a.deptId !== "").length <= 1
    ) {
      if (
        currentRow.filter((a: { deptId: string }) => a.deptId !== "").length ===
        1
      ) {
        schedule = row[dayType].filter(
          (a: { deptId: string }) => a.deptId !== ""
        )[0];
      } else {
        schedule = row[dayType][0];
      }

      let showPosition = true;

      let scheduleClassName: any = `schedule-type ${schedule.shiftType}`;
      if (row.shiftType === "UnAssigned") {
        if (
          this.state.lockDays.includes(dayType) ||
          this.state.importForecastBanner
        ) {
          if (schedule.shiftPosition.trim() === "") {
            scheduleClassName = `schedule-type ${schedule.shiftType} dayLockedUnassignedBlankShifts`;
          } else {
            scheduleClassName = `schedule-type ${schedule.shiftType} dayLockedUnassignedNonBlankShifts`;
          }
        } else {
          if (schedule.shiftPosition.trim() === "") {
            scheduleClassName = `schedule-type ${schedule.shiftType} unassignedBlankShifts`;
          }
        }
      } else {
        if (schedule.shiftPosition.trim() === "PTO") {
          if (this.state.lockDays.includes(dayType)) {
            scheduleClassName = `schedule-type ${schedule.shiftType} dayLocked`;
          }
        } else {
          if (
            this.state.lockDays.includes(dayType) ||
            this.state.importForecastBanner
          ) {
            if (schedule.shiftPosition.trim() === "") {
              scheduleClassName = `schedule-type ${schedule.shiftType} dayLockedBlankShift`;
            } else {
              scheduleClassName = `schedule-type ${schedule.shiftType} dayLocked`;
            }
          }
        }
      }

      return (
        <div className="singleShift">
          {schedule.optionsItems === "showOptions" && (
            <div
              className={
                schedule.shiftbulkSelect &&
                schedule.shiftAction === "bulkSelect"
                  ? `${scheduleClassName} ${schedule.shiftAction} bulkTrue`
                  : `${scheduleClassName} ${schedule.shiftAction}`
              }
            >
              <Dropdown className="more-action">
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <div className="d-flex">
                    <div className="details">
                      <span className="schedule-time">
                        {schedule.displayTime}
                      </span>
                      {showPosition && (
                        <EllipsisWithTooltip placement="bottom">
                          <span className="schedule-details">
                            {schedule.shiftPosition === "+"
                              ? ""
                              : schedule.shiftPosition}
                          </span>
                        </EllipsisWithTooltip>
                      )}
                    </div>
                  </div>
                </Dropdown.Toggle>
              </Dropdown>
            </div>
          )}
        </div>
      );
    } else {
      let shiftBulkSelectArray: any = [];
      let shiftPositionArray: any = [];
      let shiftClassArray: any = [];
      let shiftActionArray: any = [];
      let dropDownClassName: any = "";
      let shiftCount: any = 0;
      let bulkSelectParent: any = "fullCheck";

      currentRow.forEach((data: any) => {
        if (
          data.shiftPosition.trim() === "" ||
          data.shiftPosition.trim() === "+"
        ) {
        } else {
          shiftPositionArray.push(data.shiftPosition);
          shiftClassArray.push(data.shiftType);
          shiftActionArray.push(data.shiftAction);
          shiftBulkSelectArray.push(data.shiftbulkSelect);
          shiftCount = shiftCount + 1;
        }
      });

      if (shiftPositionArray.includes("+")) {
        let indexOfPlus = shiftPositionArray.indexOf("+");
        if (indexOfPlus > -1) {
          shiftPositionArray.splice(indexOfPlus, 1);
        }
      }
      if (shiftClassArray.includes("blankShift")) {
        let indexOfBlank = shiftClassArray.indexOf("blankShift");
        if (indexOfBlank > -1) {
          shiftClassArray.splice(indexOfBlank, 1);
        }
      }

      const uniqueShiftBulkSelect: any = new Set(shiftBulkSelectArray);
      const shiftBulkSelect = [...uniqueShiftBulkSelect];
      const uniqueShiftPoition: any = new Set(shiftPositionArray);
      const shiftPositions = [...uniqueShiftPoition];
      const uniqueShiftType: any = new Set(shiftClassArray);
      const shiftTypes = [...uniqueShiftType];
      const uniqueShiftAction: any = new Set(shiftActionArray);
      const shiftActions = [...uniqueShiftAction];

      if (shiftBulkSelect.length === 1) {
        if (shiftBulkSelect[0] === false) {
          bulkSelectParent = "noCheck";
        }
      } else {
        if (shiftBulkSelect.includes(true)) {
          bulkSelectParent = "halfCheck";
        }
      }

      if (
        shiftTypes.includes("unpublished") ||
        shiftTypes.includes("ptoRequest")
      ) {
        dropDownClassName =
          "btn-outline-primary btn btn-primary more unpublished";
      } else {
        dropDownClassName =
          "btn-outline-primary btn btn-primary more published";
      }

      if (shiftActions.includes("wfres")) {
        dropDownClassName = "btn-outline-primary btn btn-primary more wfres";
      }

      let isDayLock = false;
      let bulkSelectShow = true;
      if (this.state.lockDays.includes(dayType)) {
        isDayLock = true;
        bulkSelectShow = false;
      }

      if (shiftPositions.length === 1 && shiftPositions[0] === "PTO") {
        bulkSelectShow = false;
      }

      return (
        <div className="multipleShift">
          {row.multipleShiftVisibility === "hideDropdown" && (
            <div
              className={
                isDayLock ? "mulitpleShift dayLockedMultiple" : "mulitpleShift"
              }
            >
              <Dropdown className="multiple-shift-dropdown" draggable={false}>
                <Dropdown.Toggle
                  className={
                    row.bulkSelect && bulkSelectShow
                      ? `${dropDownClassName} bulkSelect ${bulkSelectParent}`
                      : dropDownClassName
                  }
                  id="dropdown-more"
                  draggable={false}
                >
                  {row.bulkSelect && bulkSelectShow && (
                    <div>
                      <Form.Check
                        className={bulkSelectParent}
                        id="multiShiftBulkSelect"
                        type="checkbox"
                        label=""
                      />
                    </div>
                  )}
                  <div className="details">
                    <EllipsisWithTooltip placement="bottom">
                      <span className="schedule-time">
                        {shiftCount <= 1
                          ? `${shiftCount} Shift`
                          : `${shiftCount} Shifts`}
                      </span>
                    </EllipsisWithTooltip>
                    <EllipsisWithTooltip placement="bottom">
                      <span className="schedule-details">
                        {shiftPositions.length > 1
                          ? `${shiftPositions.length} Positions`
                          : shiftPositions[0]}
                      </span>
                    </EllipsisWithTooltip>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu draggable={false}>
                  <Dropdown.Item draggable={false} className="ifCusOver">
                    {currentRow.map((schdeule: any, index: any) => {
                      let className = `schedule-type ${schdeule.shiftType} ${schdeule.shiftAction}`;

                      if (isDayLock) {
                        className = `schedule-type ${schdeule.shiftType} dayLocked`;
                      }

                      return (
                        <div
                          key={index}
                          className={
                            schdeule.shiftbulkSelect
                              ? `${className} bulkTrue`
                              : className
                          }
                          draggable={false}
                        >
                          <Dropdown
                            className="more-action dropdownItemDefaultPadding"
                            onClick={(e: any) => {
                              e.stopPropagation();
                            }}
                          >
                            <Dropdown.Toggle
                              className="btn-outline-primary btn btn-primary more"
                              id="dropdown-more"
                            >
                              <div className="details">
                                <span className="schedule-time">
                                  {schdeule.displayTime}
                                </span>
                                <EllipsisWithTooltip placement="bottom">
                                  <span className="schedule-details sos">
                                    {schdeule.shiftPosition}
                                  </span>
                                </EllipsisWithTooltip>
                              </div>
                            </Dropdown.Toggle>
                          </Dropdown>
                        </div>
                      );
                    })}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      );
    }
  };

  mydayFormatter = (cell: any, row: any, rowIndex: any, dayType: any) => {
    let currentRow = row[dayType];
    let showPosition = true;
    let item: any[] = [];
    for (let int = 0; int < currentRow.length; int++) {
      const schedule = row[dayType][int];
      let scheduleClassName: any = `schedule-type ${currentRow[int].shiftType}`;
      if (row.shiftType === "UnAssigned") {
        if (
          this.state.mylockDays.includes(dayType) ||
          this.state.importForecastBanner
        ) {
          if (schedule.shiftPosition.trim() === "") {
            scheduleClassName = `schedule-type ${currentRow[int].shiftType} dayLockedBlankShift`;
          } else {
            scheduleClassName = `schedule-type ${currentRow[int].shiftType} dayLockedBlankShift`;
          }
        } else {
          if (schedule.shiftPosition.trim() === "") {
            scheduleClassName = `schedule-type ${currentRow[int].shiftType} dayLockedBlankShift`;
          }
        }
      } else {
        if (schedule.shiftPosition.trim() === "PTO") {
          if (this.state.mylockDays.includes(dayType)) {
            scheduleClassName = `schedule-type ${currentRow[int].shiftType} dayLocked`;
          }
        } else {
          if (
            this.state.mylockDays.includes(dayType) ||
            this.state.importForecastBanner
          ) {
            if (schedule.shiftPosition.trim() === "+") {
              scheduleClassName = `schedule-type ${currentRow[int].shiftType} dayLockedBlankShift`;
            } else {
              scheduleClassName = `schedule-type ${currentRow[int].shiftType} dayLocked`;
            }
          }
        }
      }

      item.push(
        <div className="singleShift">
          {schedule.optionsItems === "showOptions" && (
            <div
              className={
                schedule.shiftbulkSelect &&
                schedule.shiftAction === "bulkSelect"
                  ? `${scheduleClassName} ${schedule.shiftAction} bulkTrue`
                  : `${scheduleClassName} ${schedule.shiftAction}`
              }
            >
              <Dropdown className="more-action">
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <div className="d-flex">
                    <div className="details">
                      <span className="schedule-time">
                        {schedule.displayTime}
                      </span>
                      {showPosition && (
                        <EllipsisWithTooltip placement="bottom">
                          <span className="schedule-details">
                            {schedule.shiftPosition === "+"
                              ? ""
                              : schedule.shiftPosition}
                          </span>
                        </EllipsisWithTooltip>
                      )}
                    </div>
                  </div>
                </Dropdown.Toggle>
              </Dropdown>
            </div>
          )}
        </div>
      );
    }

    return <div>{item}</div>;
  };

  employeeNameForematter = (cell: any, row: any, rowIndex: any) => {
    return (
      <div className="employee-set d-flex" draggable={false}>
        <div className="employee-image">
          <img
            src={
              row.employeeImage === null || row.employeeImage.trim() === ""
                ? profile
                : row.employeeImage
            }
            alt=""
          />
        </div>
        <div className="employee-details mr-auto">
          <EllipsisWithTooltip placement="bottom">
            <span
              className={
                row.timeDetailsSummary === "undertime"
                  ? " employeeName"
                  : "employeeName red"
              }
            >
              {row.employeeName}
            </span>
          </EllipsisWithTooltip>
          <span
            className={
              row.timeDetailsSummary === "undertime"
                ? " employeeTimeDetails"
                : "employeeTimeDetails red"
            }
          >
            {row.timeDetails}
          </span>
        </div>
      </div>
    );
  };

  handleSelectedItem = (id: any) => {
    let departmentName = this.state.departmentList.filter(
      (row: any) => row.id === id
    )[0].name;
    this.setState({ departmentId: id, departmentName: departmentName });
    this.filterDataByDepartment(id, this.state.tempScheduleData);
  };

  filterDataByDepartment(departmentId: any, array: []) {
    let dataArr: any[] = [];
    let mainArray: any[] = [];

    dataArr = Object.assign([], JSON.parse(JSON.stringify(array)));
    for (let i = 0; i < dataArr.length; i++) {
      let isValue = false;
      let day1: any[] = [];
      let obj: any = {};
      let day2: any[] = [];
      let day3: any[] = [];
      let day4: any[] = [];
      let day5: any[] = [];
      let day6: any[] = [];
      let day7: any[] = [];
      obj.actualHour = dataArr[i].actualHour;
      obj.bulkSelect = dataArr[i].bulkSelect;
      obj.employeeImage = dataArr[i].employeeImage;
      obj.employeeName = dataArr[i].employeeName;
      obj.multipleShiftVisibility = dataArr[i].multipleShiftVisibility;
      obj.rowNo = dataArr[i].rowNo;
      obj.shiftType = dataArr[i].shiftType;
      obj.showUserList = dataArr[i].showUserList;
      obj.timeDetails = dataArr[i].timeDetails;
      obj.timeDetailsSummary = dataArr[i].timeDetailsSummary;
      for (let j = 0; j < dataArr[i].day1.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day1[j].deptId) === departmentId) {
          newItem = dataArr[i].day1[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day1.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day2.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day2[j].deptId) === departmentId) {
          newItem = dataArr[i].day2[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day2.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day3.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day3[j].deptId) === departmentId) {
          newItem = dataArr[i].day3[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day3.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day4.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day4[j].deptId) === departmentId) {
          newItem = dataArr[i].day4[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day4.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day5.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day5[j].deptId) === departmentId) {
          newItem = dataArr[i].day5[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day5.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day6.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day6[j].deptId) === departmentId) {
          newItem = dataArr[i].day6[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day6.push(newItem);
      }
      for (let j = 0; j < dataArr[i].day7.length; j++) {
        let newItem: any = {};
        if (Number(dataArr[i].day7[j].deptId) === departmentId) {
          newItem = dataArr[i].day7[j];
          isValue = true;
        } else {
          newItem.actualPunches = "No";
          newItem.deptId = "";
          newItem.displayTime = "";
          newItem.indate = "";
          newItem.optionsItems = "showOptions";
          newItem.outdate = "";
          newItem.positionId = "";
          newItem.shiftAction = "";
          newItem.shiftDate = "";
          newItem.shiftDay = "";
          newItem.shiftInTime = "";
          newItem.shiftIsMultiple = "";
          newItem.shiftOutTime = "";
          newItem.shiftPosition = "";
          newItem.shiftType = "blankShift";
          newItem.shiftbulkSelect = false;
          newItem.typeMaster = "1";
          newItem.uniqueno = "";
        }
        day7.push(newItem);
      }
      obj.day1 = day1;
      obj.day2 = day2;
      obj.day3 = day3;
      obj.day4 = day4;
      obj.day5 = day5;
      obj.day6 = day6;
      obj.day7 = day7;
      if (isValue) {
        mainArray.push(obj);
      }
    }

    this.setState({ ScheduleData: mainArray });
  }

  getPostionDepartment = (request: ILaborScheduleDeptPositionRequestDto) => {
    LaborSchedule.GetLaborPositionDept(request)
      .then(async (result: any) => {
        if (result.message === "Success") {
          let departMentDetails: any = [];
          result.result[0].laborScheduleDepartment.forEach((res: any) => {
            let departMentObject: any = {};
            departMentObject.id = res.deptID;
            departMentObject.name = res.departmentName;
            departMentDetails.push(departMentObject);
          });
          this.setState({
            departmentList: departMentDetails,
          });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`);
        this.setState({ isLoader: false });
        reject();
      });
  };

  // ******** Mobile View Function Starts ******* //
  mobileCurrentDateClick = (e: any, date: any, type: string) => {
    let selectedDate = moment(date);
    if (type === "user") {
      this.setState({ mobileUserSelectedDate: selectedDate }, () =>
        this.filterMobileData()
      );
    } else {
      this.setState({ mobileDeptSelectedDate: selectedDate }, () =>
        this.filterMobileData()
      );
    }
  };

  filterMobileData = () => {};

  mobileDepartmentFilter = (item: any) => {
    this.setState({ departmentName: item.name });
    this.handleSelectedItem(item.id);
  };

  dayHasShifts = (day: string, viewType: string) => {
    let iterateData = this.state.MyScheduleData;
    if (viewType === "deptSchedule") {
      iterateData = this.state.ScheduleData;
    }
    let hasShift = false;
    if(day!==""){
    for (let i = 0; i < iterateData.length; i++) {
      if (
        iterateData[i][day].length === 1 &&
        iterateData[i][day][0].shiftType === "blankShift"
      ) {
        hasShift = false;
      } else {
        hasShift = true;
        break;
      }
    }
  }

    return hasShift;
  };

  // ******** Mobile View Function Ends ******* //

  render() {
    const rowClasses = (row: any, rowIndex: any) => {
      if (row.shiftType === "UnAssigned") {
        return "unassigned-row";
      } else {
        return "assigned-row";
      }
    };

    const columns = (mode: any) => [
      {
        dataField: "employeeName",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.employeeNameForematter(cell, row, rowIndex),
      },
      {
        dataField: "day1",
        text: "",
        headerFormatter:
          mode === 1 ? this.myheaderFormatter : this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          mode === 1
            ? this.mydayFormatter(cell, row, rowIndex, "day1")
            : this.dayFormatter(cell, row, rowIndex, "day1"),
        searchable: false,
      },
      {
        dataField: "day2",
        text: "",
        headerFormatter:
          mode === 1 ? this.myheaderFormatter : this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          mode === 1
            ? this.mydayFormatter(cell, row, rowIndex, "day2")
            : this.dayFormatter(cell, row, rowIndex, "day2"),
        searchable: false,
      },
      {
        dataField: "day3",
        text: "",
        headerFormatter:
          mode === 1 ? this.myheaderFormatter : this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          mode === 1
            ? this.mydayFormatter(cell, row, rowIndex, "day3")
            : this.dayFormatter(cell, row, rowIndex, "day3"),
        searchable: false,
      },
      {
        dataField: "day4",
        text: "",
        headerFormatter:
          mode === 1 ? this.myheaderFormatter : this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          mode === 1
            ? this.mydayFormatter(cell, row, rowIndex, "day4")
            : this.dayFormatter(cell, row, rowIndex, "day4"),
        searchable: false,
      },
      {
        dataField: "day5",
        text: "",
        headerFormatter:
          mode === 1 ? this.myheaderFormatter : this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          mode === 1
            ? this.mydayFormatter(cell, row, rowIndex, "day5")
            : this.dayFormatter(cell, row, rowIndex, "day5"),
        searchable: false,
      },
      {
        dataField: "day6",
        text: "",
        headerFormatter:
          mode === 1 ? this.myheaderFormatter : this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          mode === 1
            ? this.mydayFormatter(cell, row, rowIndex, "day6")
            : this.dayFormatter(cell, row, rowIndex, "day6"),
        searchable: false,
      },
      {
        dataField: "day7",
        text: "",
        headerFormatter:
          mode === 1 ? this.myheaderFormatter : this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          mode === 1
            ? this.mydayFormatter(cell, row, rowIndex, "day7")
            : this.dayFormatter(cell, row, rowIndex, "day7"),
        searchable: false,
      },
    ];

    return (
      <div className="pdf-show-relativePos ">
        {/* ******** Desktop View Starts ******** */}
        <div className="fixed-heading">
          <div className="schedule-main">
            <Container fluid className="body-sec">
              <div className="heading-fixed-container">
                <div className="page-heading underline d-flex">
                  {this.state.isDeepLinking === true && (
                    <React.Fragment>
                      {this.state.hidValue !== "Select" && (
                        <HidSelector
                          onClickEvent={this.selectHotelDropDown}
                          ref={this.childHID}
                        />
                      )}
                    </React.Fragment>
                  )}
                  {this.state.isDeepLinking === false && (
                    <HidSelector
                      onClickEvent={this.selectHotelDropDown}
                      ref={this.childHID}
                    />
                  )}

                  <div className="mr-auto">Schedule</div>
                  {this.state.hidValue !== "Select" && (
                    <div className="action-group d-flex flex-row">
                      <Dropdown className="more-action download-pdf" alignRight>
                        <Dropdown.Toggle
                          className="btn-outline-primary btn btn-primary more"
                          id="dropdown-more"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <Pdf targetRef={this.ref} filename="MySchedule.pdf">
                              {({ toPdf }) => (
                                <button onClick={toPdf}>Export to PDF</button>
                              )}
                            </Pdf>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </div>
              </div>

              <div className="userViewTable">
                {this.state.isLoader ? (
                  <ReactPageLoader useas={"myscheduleUserView"} />
                ) : (
                  <>
                    {this.state.hidValue !== "Select" && (
                      <div className="schedule-user-view-table read-only">
                        <div className="section d-flex align-items-center">
                          <div className="tittle mr-auto">My Schedule</div>
                          <div className="action-link">
                            <button
                              type="button"
                              className="btn-outline-primary btn btn-primary"
                              onClick={() => this.handleNevigation("back", 1)}
                            >
                              <svg
                                width="7"
                                height="11"
                                viewBox="0 0 7 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M5.295 0L0 5.29575L5.295 10.5915L6.8865 9L3.1815 5.29575L6.8865 1.5915L5.295 0Z" />
                              </svg>
                            </button>
                            <button
                              type="button"
                              className="btn-outline-primary btn btn-primary"
                              onClick={() => this.handleNevigation("next", 1)}
                            >
                              <svg
                                width="7"
                                height="11"
                                viewBox="0 0 7 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1.705 0L7 5.29575L1.705 10.5915L0.113499 9L3.8185 5.29575L0.113499 1.5915L1.705 0Z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        {this.state.myheaderDates.length > 0 && (
                          <div className="table underline rm-magn-add">
                            <BootstrapTable
                              keyField="rowNo"
                              data={this.state.MyScheduleData}
                              columns={columns(1)}
                              hover
                              rowClasses={rowClasses}
                            />
                          </div>
                        )}
                        <div className="margin-bdr">
                          <div className="section d-flex align-items-center">
                            <div className="tittle mr-auto">
                              Department Schedule
                            </div>
                            <div className="action-link">
                              <button
                                type="button"
                                className="btn-outline-primary btn btn-primary"
                                onClick={() => this.handleNevigation("back", 2)}
                              >
                                <svg
                                  width="7"
                                  height="11"
                                  viewBox="0 0 7 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M5.295 0L0 5.29575L5.295 10.5915L6.8865 9L3.1815 5.29575L6.8865 1.5915L5.295 0Z" />
                                </svg>
                              </button>
                              <button
                                type="button"
                                className="btn-outline-primary btn btn-primary"
                                onClick={() => this.handleNevigation("next", 2)}
                              >
                                <svg
                                  width="7"
                                  height="11"
                                  viewBox="0 0 7 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M1.705 0L7 5.29575L1.705 10.5915L0.113499 9L3.8185 5.29575L0.113499 1.5915L1.705 0Z" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                        {this.state.departmentList.length > 0 && (
                          <div className="section department-filter">
                            <SingleSearchDropdownList
                              itemList={this.state.departmentList}
                              handleSelectedItem={this.handleSelectedItem.bind(
                                this
                              )}
                              defaultItem={this.state.departmentName}
                              defaultText={"Search Department"}
                              defaultName={this.state.departmentName}
                              controlID="2"
                            />
                          </div>
                        )}
                        {this.state.headerDates.length > 0 && (
                          <div className="table">
                            <BootstrapTable
                              keyField="rowNo"
                              data={this.state.ScheduleData}
                              columns={columns(2)}
                              hover
                              rowClasses={rowClasses}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </Container>
          </div>
        </div>
        {/* ******** Desktop View Ends ******** */}

        {/* ******** Mobile View Starts ******** */}
        <div className="moble-view-user-schedule">
          <div className="body-sec container-fluid">
            <div className="mobile-page-heading d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
              />

              <div className="mr-auto">Schedule</div>
              {this.state.hidValue !== "Select" && !this.state.mobileLoader && (
                <div className="action-group d-flex flex-row">
                  <Dropdown className="more-action download-pdf" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Pdf targetRef={this.ref} filename="MySchedule.pdf">
                        {({ toPdf }) => (
                          <Dropdown.Item onClick={toPdf}>
                            Export to PDF
                          </Dropdown.Item>
                        )}
                      </Pdf>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>

            {this.state.mobileLoader ? (
              <ReactPageLoader useas={"myscheduleMobileView"} />
            ) : (
              <>
                {this.state.hidValue !== "Select" && (
                  <div className="my-schedule user-schedule-wrapper">
                    <div className="user-schedule-heading">My Schedule</div>

                    <div className="user-calender">
                      <div className="calender-carasoul d-flex align-items-center justify-content-between">
                        <span
                          className="left-calender-arrow active"
                          onClick={() => this.handleNevigation("back", 1)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.295 5L6 10.2957L11.295 15.5915L12.8865 14L9.1815 10.2957L12.8865 6.5915L11.295 5Z"
                              fill=""
                            />
                          </svg>
                        </span>

                        {this.state.myheaderDates.map(
                          (data: any, idx: number) => {
                            let lockDays = this.state.mylockDays;
                            let fullDate = this.state.myheaderFullDates;
                            let splittedDate = data.split("/");
                            let dateClassName =
                              "single-day future-schedule-date";
                            if (lockDays.includes(`day${idx + 1}`)) {
                              dateClassName = "single-day back-schedule-date";
                            }
                            if (
                              moment(this.state.mobileUserSelectedDate).isSame(
                                moment(fullDate[idx]),
                                "day"
                              )
                            ) {
                              mobileUserSelectedDay = `day${idx + 1}`;
                              dateClassName = `${dateClassName} active`;
                            }
                            return (
                              <div
                                className={dateClassName}
                                key={idx}
                                onClick={(e: any) => {
                                  this.mobileCurrentDateClick(
                                    e,
                                    fullDate[idx],
                                    "user"
                                  );
                                }}
                              >
                                <div className="day-name">
                                  {this.state.myheaderDays[idx].substring(0, 1)}
                                </div>
                                <div
                                  className={
                                    this.dayHasShifts(
                                      `day${idx + 1}`,
                                      "userSchedule"
                                    )
                                      ? lockDays.includes(`day${idx + 1}`)
                                        ? "dot grey"
                                        : "dot green"
                                      : "dot white"
                                  }
                                ></div>
                                <div className="day-date">
                                  {splittedDate[1]}
                                </div>
                              </div>
                            );
                          }
                        )}

                        <span
                          className="right-calender-arrow active"
                          onClick={() => this.handleNevigation("next", 1)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.295 5L6 10.2957L11.295 15.5915L12.8865 14L9.1815 10.2957L12.8865 6.5915L11.295 5Z"
                              fill=""
                            />
                          </svg>
                        </span>
                      </div>

                      <div className="complete-day-datails">
                        {moment(this.state.mobileUserSelectedDate).format(
                          "dddd, MMMM Do, YYYY"
                        )}
                      </div>
                    </div>

                    <div className="user-schedules">
                      {!this.dayHasShifts(
                        mobileUserSelectedDay,
                        "userSchedule"
                      ) ? (
                        <div className="no-schedule-message">
                          You are not scheduled for this day.
                        </div>
                      ) : (
                        this.state.MyScheduleData.length > 0 &&
                        this.state.MyScheduleData.map(
                          (data: any, idx: number) => {
                            if (
                              data[mobileUserSelectedDay].length === 1 &&
                              data[mobileUserSelectedDay][0].shiftType ===
                                "blankShift"
                            ) {
                              return null;
                            } else {
                              return (
                                <div className="user-shifts d-flex" key={idx}>
                                  <div className="user-image">
                                    <img
                                      src={
                                        data.employeeImage === null ||
                                        data.employeeImage.trim() === ""
                                          ? profile
                                          : data.employeeImage
                                      }
                                      alt="user-name"
                                    />
                                  </div>

                                  <div className="user-details">
                                    <div className="user-schedule-demographic-data d-flex align-items-center justify-content-between">
                                      <span className="user-name">
                                        {data.employeeName}
                                      </span>
                                      <span className="user-scheduled-hours">
                                        {schConversions.convertMinuteToHour(
                                          data[mobileUserSelectedDay].reduce(
                                            (acc: any, curr: any) => {
                                              if (
                                                curr.shiftType !== "blankShift"
                                              ) {
                                                let scheduleInTime = moment(
                                                  curr.indate
                                                );
                                                let scheduleOutTime = moment(
                                                  curr.outdate
                                                );

                                                let diffInSchedue =
                                                  scheduleOutTime.diff(
                                                    scheduleInTime,
                                                    "minutes"
                                                  );

                                                acc = acc + diffInSchedue;
                                              }

                                              return acc;
                                            },
                                            0
                                          )
                                        )}
                                      </span>
                                    </div>

                                    <div className="user-schedule-details">
                                      {data[mobileUserSelectedDay].map(
                                        (schedule: any, idt: number) => {
                                          if (
                                            schedule.shiftType !== "blankShift"
                                          ) {
                                            let scheduleClassName: any = `schedule-type ${schedule.shiftType}`;
                                            if (
                                              this.state.mylockDays.includes(
                                                mobileUserSelectedDay
                                              )
                                            ) {
                                              scheduleClassName = `schedule-type ${schedule.shiftType} dayLocked`;
                                            }
                                            return (
                                              <div
                                                className="singleShift"
                                                key={idt}
                                              >
                                                <div
                                                  className={scheduleClassName}
                                                >
                                                  <Dropdown className="more-action">
                                                    <Dropdown.Toggle
                                                      className="btn-outline-primary btn btn-primary more"
                                                      id="dropdown-more"
                                                    >
                                                      <div className="d-flex">
                                                        <div className="details">
                                                          <span className="schedule-time">
                                                            {
                                                              schedule.displayTime
                                                            }
                                                          </span>

                                                          <EllipsisWithTooltip placement="bottom">
                                                            <span className="schedule-details">
                                                              {
                                                                schedule.shiftPosition
                                                              }
                                                            </span>
                                                          </EllipsisWithTooltip>
                                                        </div>
                                                      </div>
                                                    </Dropdown.Toggle>
                                                  </Dropdown>
                                                </div>
                                              </div>
                                            );
                                          } else {
                                            return null;
                                          }
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          }
                        )
                      )}
                    </div>
                  </div>
                )}

                {this.state.hidValue !== "Select" && (
                  <div className="department-schedule user-schedule-wrapper">
                    <div className="department-schedule-heading">
                      Department Schedule
                      {this.state.departmentList.length > 0 && (
                        <div className="department-picker">
                          <Dropdown className="more-action">
                            <Dropdown.Toggle
                              className="my-schedule-department-select"
                              id="dropdown-more"
                            >
                              <span
                                className={
                                  this.state.departmentName === "Department"
                                    ? "department-name placeholder"
                                    : "department-name"
                                }
                              >
                                {this.state.departmentName === "Department"
                                  ? "Select Department"
                                  : this.state.departmentName}
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {this.state.departmentList.map(
                                (item: any, idx: number) => {
                                  return (
                                    <Dropdown.Item
                                      key={idx}
                                      onClick={() =>
                                        this.mobileDepartmentFilter(item)
                                      }
                                    >
                                      {item.name}
                                    </Dropdown.Item>
                                  );
                                }
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </div>

                    <div className="user-calender">
                      <div className="calender-carasoul d-flex align-items-center justify-content-between">
                        <span
                          className="left-calender-arrow active"
                          onClick={() => this.handleNevigation("back", 2)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.295 5L6 10.2957L11.295 15.5915L12.8865 14L9.1815 10.2957L12.8865 6.5915L11.295 5Z"
                              fill=""
                            />
                          </svg>
                        </span>

                        {this.state.headerDates.map(
                          (data: any, idx: number) => {
                            let lockDays = this.state.lockDays;
                            let fullDate = this.state.headerFullDates;
                            let splittedDate = data.split("/");
                            let dateClassName =
                              "single-day future-schedule-date";
                            if (lockDays.includes(`day${idx + 1}`)) {
                              dateClassName = "single-day back-schedule-date";
                            }
                            if (
                              moment(this.state.mobileDeptSelectedDate).isSame(
                                moment(fullDate[idx]),
                                "day"
                              )
                            ) {
                              mobileDeptSelectedDay = `day${idx + 1}`;
                              dateClassName = `${dateClassName} active`;
                            }
                            return (
                              <div
                                className={dateClassName}
                                key={idx}
                                onClick={(e: any) => {
                                  this.mobileCurrentDateClick(
                                    e,
                                    fullDate[idx],
                                    "dept"
                                  );
                                }}
                              >
                                <div className="day-name">
                                  {this.state.headerDays[idx].substring(0, 1)}
                                </div>
                                <div
                                  className={
                                    this.dayHasShifts(
                                      `day${idx + 1}`,
                                      "deptSchedule"
                                    )
                                      ? lockDays.includes(`day${idx + 1}`)
                                        ? "dot grey"
                                        : "dot green"
                                      : "dot white"
                                  }
                                ></div>
                                <div className="day-date">
                                  {splittedDate[1]}
                                </div>
                              </div>
                            );
                          }
                        )}

                        <span
                          className="right-calender-arrow active"
                          onClick={() => this.handleNevigation("next", 2)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.295 5L6 10.2957L11.295 15.5915L12.8865 14L9.1815 10.2957L12.8865 6.5915L11.295 5Z"
                              fill=""
                            />
                          </svg>
                        </span>
                      </div>

                      <div className="complete-day-datails">
                        {moment(this.state.mobileDeptSelectedDate).format(
                          "dddd, MMMM Do, YYYY"
                        )}
                      </div>
                    </div>

                    {!this.dayHasShifts(
                      mobileDeptSelectedDay,
                      "deptSchedule"
                    ) ? (
                      <div className="no-schedule-message">
                        No scheduled users for this day.
                      </div>
                    ) : (
                      this.state.ScheduleData.length > 0 &&
                      this.state.ScheduleData.map((data: any, idx: number) => {
                        if (
                          data[mobileDeptSelectedDay].length === 1 &&
                          data[mobileDeptSelectedDay][0].shiftType ===
                            "blankShift"
                        ) {
                          return null;
                        } else {
                          return (
                            <div className="user-schedules" key={idx}>
                              <div className="user-shifts d-flex">
                                <div className="user-image">
                                  <img
                                    src={
                                      data.employeeImage === null ||
                                      data.employeeImage.trim() === ""
                                        ? profile
                                        : data.employeeImage
                                    }
                                    alt="user-name"
                                  />
                                </div>

                                <div className="user-details">
                                  <div className="user-schedule-demographic-data d-flex align-items-center justify-content-between">
                                    <span className="user-name">
                                      {data.employeeName}
                                    </span>
                                    <span className="user-scheduled-hours">
                                      {schConversions.convertMinuteToHour(
                                        data[mobileDeptSelectedDay].reduce(
                                          (acc: any, curr: any) => {
                                            if (
                                              curr.shiftType !== "blankShift"
                                            ) {
                                              let scheduleInTime = moment(
                                                curr.indate
                                              );
                                              let scheduleOutTime = moment(
                                                curr.outdate
                                              );

                                              let diffInSchedue =
                                                scheduleOutTime.diff(
                                                  scheduleInTime,
                                                  "minutes"
                                                );

                                              acc = acc + diffInSchedue;
                                            }

                                            return acc;
                                          },
                                          0
                                        )
                                      )}
                                    </span>
                                  </div>

                                  {mobileDeptSelectedDay !== "" &&
                                    data[mobileDeptSelectedDay].map(
                                      (schedule: any, idt: number) => {
                                        if (
                                          schedule.shiftType !== "blankShift"
                                        ) {
                                          let scheduleClassName: any = `schedule-type ${schedule.shiftType}`;
                                          if (
                                            this.state.lockDays.includes(
                                              mobileDeptSelectedDay
                                            )
                                          ) {
                                            scheduleClassName = `schedule-type ${schedule.shiftType} dayLocked`;
                                          }

                                          return (
                                            <div
                                              className="singleShift"
                                              key={idt}
                                            >
                                              <div
                                                className={scheduleClassName}
                                              >
                                                <Dropdown className="more-action">
                                                  <Dropdown.Toggle
                                                    className="btn-outline-primary btn btn-primary more"
                                                    id="dropdown-more"
                                                  >
                                                    <div className="d-flex">
                                                      <div className="details">
                                                        <span className="schedule-time">
                                                          {schedule.displayTime}
                                                        </span>

                                                        <EllipsisWithTooltip placement="bottom">
                                                          <span className="schedule-details">
                                                            {
                                                              schedule.shiftPosition
                                                            }
                                                          </span>
                                                        </EllipsisWithTooltip>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Toggle>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          );
                                        } else {
                                          return null;
                                        }
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* ******** Mobile View Ends ******** */}

        {/* Export to PDF Table Structure Starts */}
        {this.state.mySchedulePDF.length > 0 && (
          <div
            ref={this.ref}
            className="table-view"
            style={{
              paddingTop: "10px",
              // , display: "none"
            }}
          >
            <div
              style={{
                textAlign: "center",
                color: "#000",
                width: "750px",
                marginLeft: "22px",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                My Schedule
              </div>
              <div style={{ fontSize: "14px" }}>
                {this.state.mySchedulePDF[0].userName}
              </div>
              <div style={{ fontSize: "14px" }}>
                {this.state.mySchedulePDF[0].hotelName}
              </div>
              <div style={{ fontSize: "14px" }}>
                {this.state.mySchedulePDF[0].date}
              </div>
            </div>
            {this.state.mySchedulePDF[0].week1.length > 0 && (
              <div>
                <table className="table table-bordered pdf-view">
                  <thead>
                    <tr>
                      <th style={{ width: "10%", fontSize: "10px" }}>Day</th>
                      <th style={{ width: "10%", fontSize: "10px" }}>Date</th>
                      <th
                        style={{
                          width: "25%",
                          textAlign: "left",
                          fontSize: "10px",
                        }}
                      >
                        Department{" "}
                      </th>
                      <th
                        style={{
                          width: "25%",
                          textAlign: "left",
                          fontSize: "10px",
                        }}
                      >
                        Position
                      </th>
                      <th style={{ width: "10%", fontSize: "10px" }}>In</th>
                      <th style={{ width: "10%", fontSize: "10px" }}>Out</th>
                      <th style={{ width: "10%", fontSize: "10px" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.mySchedulePDF[0].week1.map(
                      (item: any, idx: number) => {
                        return (
                          <tr key={idx}>
                            <td style={{ fontSize: "10px" }}>{item.day}</td>
                            <td style={{ fontSize: "10px" }}>{item.date}</td>
                            <td
                              style={{
                                textAlign: "left",
                                fontSize: "10px",
                              }}
                            >
                              {item.departmentName}
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                fontSize: "10px",
                              }}
                            >
                              {item.positionName}
                            </td>
                            <td style={{ fontSize: "10px" }}>{item.intime}</td>
                            <td style={{ fontSize: "10px" }}>{item.outtime}</td>
                            <td style={{ fontSize: "10px" }}>{item.total}</td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td colSpan={6} style={{ textAlign: "left" }}>
                        Total
                      </td>
                      <td>
                        <strong>
                          {this.state.mySchedulePDF[0].week1[0].totalTime}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        {/* Export to PDF Table Structure Ends */}
      </div>
    );
  }
}
