import { AnyMxRecord } from "dns";
import { truncate, truncateSync } from "fs";
import React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
//import ReactTooltip from "react-tooltip";
import profile from "../../Common/Assets/Images/profile.png";
import _ from "lodash";
// import { ReactComponentLoader } from "../../../../../Common/Components/ReactComponentLoader";

export class LocationAdded extends React.Component<any, any> {
  private inputSearch: any;
  private roomTypeRef: any;
  private checkAllRefLocation: any;
  private skipRefLocation: any;

  constructor(props: any) {
    super(props);
    this.inputSearch = React.createRef();
    this.roomTypeRef = React.createRef();
    this.checkAllRefLocation = React.createRef();
    this.skipRefLocation = React.createRef();

    this.state = {
      selectedItems: [],
      itemLists: [],
      positionEmployeeList: [],
      allActualLocationList: this.props.parentState.locationControl,
      allLocationList: this.props.parentState.locationControl,
      allPrevLocationList: this.props.parentState.locationControl,
      //allRoomList: this.props.parentState.tableRoomsData1,
      filterData: [],
      opened: false,
      isSelect: true,
      inputValue: "",
      positionID: 0,
      positionName: "",
      isRemove: true,
      isEdit: "",
      isProceureClick: false,
      isSelectLocationType: "Select All",
      isSelectRoomType: "Select All",
      upatedLocationList: [],
      upatedRoomTypeLocationList: [],
      isUpdateList: "",
      isSelectButton:false,
    };
  }



  componentDidMount() {
    //debugger;
    let lable: any = "Select All";
    let lableRoom: any = "Select All";

    let data = this.props.parentState.locationControl;
    let checkSelectAll = data.filter((x) => x.isChecked === "no"
      && x.type.toLowerCase() === "roomtype");
    if (checkSelectAll.length === 0) {
      lable = "Clear All";
    } else {
      lable = "Select All";

    }
    let checkSelectAllcount = this.props.parentState.locationControl.filter((x) => x.isChecked === "no"
      && x.type.toLowerCase() !== "roomtype");
    if (checkSelectAll.length === 0) {

      lableRoom = "Clear All";
    } else {
      lableRoom = "Select All";
    }




    this.setState({
      itemLists: this.props.parentState.locationControl, isSelectLocationType: lable,
      isSelectRoomType: lableRoom
    });

    // this.setState({
    //   itemLists: this.props.parentState.locationControl
    // });
  }

  discardChanges = () => {

    this.setState({ allLocationList: this.props.parentState.locationControl, itemLists: this.props.parentState.locationControl });
  }



  updateLists = () => {

    let { filterData, itemLists, allPrevLocationList } = this.state;
    //
    if (filterData.length === 0 && this.state.inputValue === "") {
      filterData = allPrevLocationList;//itemLists;
    }
    this.setState({ allLocationList: filterData });

  };

  handleOutsideClickForAddShift = () => {
    this.setState({ opened: false, inputValue: "" });
  };

  handleChange = (e: any) => {

    const updatedList = this.state.allPrevLocationList.filter((item: any) => {
      return (
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });

    this.setState(
      { filterData: updatedList, inputValue: e.target.value },
      () => {
        this.updateLists();
      }
    );
  };


  handleCheckCheckbox1 = (idx: any, e: any) => {
    //debugger;
    let lable: any = "Select All";
    let allLocationList = [...this.state.allLocationList];
    let allPrevLocationList = [...this.state.allPrevLocationList];

    const index = allLocationList.findIndex((xvalue) => xvalue.id === idx);
    const index2 = allPrevLocationList.findIndex((xvalue) => xvalue.id === idx);
    allLocationList[index].isHalfCheck = false;

    if (e.target.checked === true) {
      allLocationList[index].isChecked = "yes";
      allPrevLocationList[index2].isChecked = "yes";

      let roomsId = allLocationList[index].selectID;
      let roomsIdprev = allPrevLocationList[index2].selectID;

      allLocationList.forEach(element => {

        if (element.selectID == roomsId) {
          element.isChecked = "yes";
        }
      });


      allPrevLocationList.forEach(element => {

        if (element.selectID == roomsIdprev) {
          element.isChecked = "yes";
        }
      });

      let checkSelectAll = allLocationList.filter((x) => Boolean(x.isChecked) === false
        && x.type.toLowerCase() === "roomtype");
      if (checkSelectAll.length === 0) {
        lable = "Clear All"
      }

      this.setState({
        allLocationList: allLocationList, allPrevLocationList: allPrevLocationList,
        isSelectRoomType: lable, isSelectLocationType: lable
      }, () => {
        //this.props.locationAdded(this.state.allLocationList, "Add");
        // this.props.locationAdded(allLocationList[index], "Add");

      });

    } else {
      allLocationList[index].isChecked = "no";
      allPrevLocationList[index2].isChecked = "no";
      let roomsId = allLocationList[index].selectID;
      let roomsPrevId = allPrevLocationList[index2].selectID;

      allLocationList.forEach(element => {
        if (element.selectID == roomsId) {
          element.isChecked = "no";
        }
      });

      allPrevLocationList.forEach(element => {
        if (element.selectID == roomsPrevId) {
          element.isChecked = "no";
        }
      });


      let checkSelectAll = allLocationList.filter((x) => Boolean(x.isChecked) === false
        && x.type.toLowerCase() === "roomtype");
      if (checkSelectAll.length > 0) {
        lable = "Select All"
      }


      this.setState({
        allLocationList: allLocationList, allPrevLocationList: allPrevLocationList,
        isSelectRoomType: lable, isSelectLocationType: lable
      }, () => {
        this.props.locationAdded(this.state.allLocationList, "remove");
      });
    }
  };



  handleRoomsCheckCheckbox = (idx: any, e: any) => {
    debugger;
    let lable: any = "";
    let allLocationList = [...this.state.allLocationList];
    let allPrevLocationList = [...this.state.allPrevLocationList];
    const index = allLocationList.findIndex((xvalue) => xvalue.id === idx);
    const index2 = allPrevLocationList.findIndex((xvalue) => xvalue.id === idx);

    if (e.target.checked === true) {
      allLocationList[index].isChecked = "yes";
      allPrevLocationList[index2].isChecked = "yes";
      let roomsId = allLocationList[index].selectID;
      let roomsPrevId = allPrevLocationList[index2].selectID;

      //----half check logic
      let checkhalf = allLocationList.filter((x) => x.isChecked === "no"
        && x.type.toLowerCase() === "location" && x.selectID === roomsId);
      if (checkhalf.length > 0) {
        const halfCheckIndex = allLocationList.findIndex((xvalue) => xvalue.selectID === roomsId
          && xvalue.type.toLowerCase() === "roomtype");
        if (halfCheckIndex != -1) {
          allLocationList[halfCheckIndex].isHalfCheck = true;
          //  this.roomTypeRef.current.checked = false;
          //  this.roomTypeRef.current.indeterminate = true;
          allLocationList[halfCheckIndex].isChecked = "yes";

        }
      } else {
        const halfCheckIndex = allLocationList.findIndex((xvalue) => xvalue.selectID === roomsId
          && xvalue.type.toLowerCase() === "roomtype");
        if (halfCheckIndex != -1) {
           allLocationList[halfCheckIndex].isChecked = "yes";
          allLocationList[halfCheckIndex].isHalfCheck = false;
          // this.roomTypeRef.current.indeterminate = false;
          // this.roomTypeRef.current.checked = true;
        }
      }

      //----half check logic
      let checkhalf2 = allPrevLocationList.filter((x) => x.isChecked === "no"
        && x.type.toLowerCase() === "location" && x.selectID === roomsId);
      if (checkhalf2.length > 0) {
        const halfCheckIndex2 = allPrevLocationList.findIndex((xvalue) => xvalue.selectID === roomsPrevId
          && xvalue.type.toLowerCase() === "roomtype");
          
          if (halfCheckIndex2 != -1) {
          allPrevLocationList[halfCheckIndex2].isChecked = "yes";
          }
      }




      let checkSelectAll = allLocationList.filter((x) => Boolean(x.isChecked) === false
        && x.type.toLowerCase() === "location");
      if (checkSelectAll.length === 0) {
        lable = "Clear All"
      }

      this.setState({
        allLocationList: allLocationList, isSelectLocationType: lable, allPrevLocationList: allPrevLocationList,
        isSelectRoomType: lable
      }, () => {
      //  this.props.locationAdded(this.state.allLocationList, "Add");
        //this.props.locationAdded(allLocationList[index], "Add");
      });

    } else {

      allLocationList[index].isChecked = "no";
      allPrevLocationList[index2].isChecked = "no";
      let roomsId = allLocationList[index].selectID;
      let roomsPrevId = allLocationList[index2].selectID;

      const halfCheckvalueno = allLocationList.filter((xvalue) => xvalue.selectID === roomsId
        && xvalue.type.toLowerCase() !== "roomtype");

      const totalcount = allLocationList.filter((xvalue) => xvalue.selectID === roomsId
        && xvalue.type.toLowerCase() !== "roomtype" && xvalue.isChecked === "yes");

      if (totalcount.length > 0) {

        if (halfCheckvalueno.length !== totalcount.length) {

          const halfCheckIndex = allLocationList.findIndex((xvalue) => xvalue.selectID === roomsId
            && xvalue.type.toLowerCase() === "roomtype");
          if (halfCheckIndex != -1) {
            // allLocationList[halfCheckIndex].isChecked = "no";
            allLocationList[halfCheckIndex].isHalfCheck = true;
          }

        }

        if (halfCheckvalueno.length === totalcount.length) {

          const halfCheckIndex = allLocationList.findIndex((xvalue) => xvalue.selectID === roomsId
            && xvalue.type.toLowerCase() === "roomtype");
          if (halfCheckIndex != -1) {
            // allLocationList[halfCheckIndex].isChecked = "no";
            allLocationList[halfCheckIndex].isHalfCheck = false;
          }

        }
      } else {
        const halfCheckIndex = allLocationList.findIndex((xvalue) => xvalue.selectID === roomsId
          && xvalue.type.toLowerCase() === "roomtype");
        if (halfCheckIndex != -1) {
          // allLocationList[halfCheckIndex].isChecked = "no";
          allLocationList[halfCheckIndex].isHalfCheck = false;
        }
      }

      // this is checkbox conition
      const halfCheckvalue = allLocationList.filter((xvalue) => xvalue.selectID === roomsId
        && xvalue.type.toLowerCase() !== "roomtype" && xvalue.isChecked === "yes");
      if (halfCheckvalue.length > 0) {
        const halfCheckIndex = allLocationList.findIndex((xvalue) => xvalue.selectID === roomsId
          && xvalue.type.toLowerCase() === "roomtype");
        if (halfCheckIndex != -1) {
          allLocationList[halfCheckIndex].isChecked = "yes";        

          //allLocationList[halfCheckIndex].isHalfCheck = true;
        }
      } else {
        const halfCheckvalue = allLocationList.filter((xvalue) => xvalue.selectID === roomsId
          && xvalue.type.toLowerCase() !== "roomtype" && xvalue.isChecked === "yes");
        if (halfCheckvalue.length === 0) {
          const halfCheckIndex = allLocationList.findIndex((xvalue) => xvalue.selectID === roomsId
            && xvalue.type.toLowerCase() === "roomtype");
          if (halfCheckIndex != -1) {
            allLocationList[halfCheckIndex].isChecked = "no";          
            //allLocationList[halfCheckIndex].isHalfCheck = true;
          }
        }


      }

// this is checkbox conition2

const halfCheckvalue2 = allPrevLocationList.filter((xvalue) => xvalue.selectID === roomsPrevId
        && xvalue.type.toLowerCase() !== "roomtype" && xvalue.isChecked === "yes");
      if (halfCheckvalue2.length > 0) {
        const halfCheckIndex = allPrevLocationList.findIndex((xvalue) => xvalue.selectID === roomsPrevId
          && xvalue.type.toLowerCase() === "roomtype");
        if (halfCheckIndex != -1) {
          allPrevLocationList[halfCheckIndex].isChecked = "yes";        

          //allLocationList[halfCheckIndex].isHalfCheck = true;
        }
      } else {
        const halfCheckvalue2 = allPrevLocationList.filter((xvalue) => xvalue.selectID === roomsPrevId
          && xvalue.type.toLowerCase() !== "roomtype" && xvalue.isChecked === "yes");
        if (halfCheckvalue2.length === 0) {
          const halfCheckIndex = allPrevLocationList.findIndex((xvalue) => xvalue.selectID === roomsPrevId
            && xvalue.type.toLowerCase() === "roomtype");
          if (halfCheckIndex != -1) {
            allPrevLocationList[halfCheckIndex].isChecked = "no";          
            //allLocationList[halfCheckIndex].isHalfCheck = true;
          }
        }


      }





      let checkSelectAll = allLocationList.filter((x) => x.isChecked === "no"
        && x.type.toLowerCase() === "location");
      if (checkSelectAll.length > 0) {
        lable = "Select All"
      }


      this.setState({
        allLocationList: allLocationList, isSelectLocationType: lable, allPrevLocationList: allPrevLocationList,
        isSelectRoomType: lable
      }, () => {
        this.props.locationAdded(this.state.allLocationList, "remove");
      });
    }
  };


  selectRoomType = (item) => {
    //debugger;
    if (item === "Select All") {
      let allLocationList = [...this.state.allLocationList];
      allLocationList.forEach(element => {
        element.isChecked = "yes";
        if (element.type.toLowerCase() === "roomtype") {
          element.isHalfCheck = false;
        }

      });

    

      let allPrevLocationList = [...this.state.allPrevLocationList];
      allLocationList.forEach(element => {     
            
        if(element.isChecked==="yes"){
          const index = allPrevLocationList.findIndex((xvalue) => xvalue.id === element.id);
          if(index!==-1){
          allPrevLocationList[index].isChecked = "yes";
          }
        }
      });




      this.setState({ allLocationList: allLocationList,
         isSelectRoomType: "Clear All", isSelectLocationType: "Clear All",
         allPrevLocationList:allPrevLocationList }, () => {
      // this.props.locationAdded(this.state.allLocationList, "Add");
      });
    } else {
      let allLocationList = [...this.state.allLocationList];
      allLocationList.forEach(element => {
        element.isChecked = "no";
      });


      let allPrevLocationList = [...this.state.allPrevLocationList];

      allLocationList.forEach(element => {     
            
        if(element.isChecked==="yes"){
          const index = allPrevLocationList.findIndex((xvalue) => xvalue.id === element.id);
          allPrevLocationList[index].isChecked = "no";
        }
      });



      this.setState({ allLocationList: allLocationList,  allPrevLocationList:allPrevLocationList ,
        isSelectRoomType: "Select All", isSelectLocationType: "Select All" }, () => {
        this.props.locationAdded(this.state.allLocationList, "remove");
      });
    }
  }

  selectLocationType = (item) => {
    //debugger;
    if (item === "Select All") {
      let allLocationList = [...this.state.allLocationList];
      allLocationList.forEach(element => {
        element.isChecked = "yes";
        if (element.type.toLowerCase() === "roomtype") {
          element.isHalfCheck = false;
        }
      });

     

      let allPrevLocationList = [...this.state.allPrevLocationList];
      allLocationList.forEach(element => {     
            
        if(element.isChecked==="yes"){
          const index = allPrevLocationList.findIndex((xvalue) => xvalue.id === element.id);
          if(index!==-1){
          allPrevLocationList[index].isChecked = "yes";
          }
        }
      });




      this.setState({ allLocationList: allLocationList,allPrevLocationList:allPrevLocationList ,
         isSelectRoomType: "Clear All", isSelectLocationType: "Clear All" }, () => {
       // this.props.locationAdded(this.state.allLocationList, "Add");
      });
    } else {
      let allLocationList = [...this.state.allLocationList];
      allLocationList.forEach(element => {
        element.isChecked = "no";
        if (element.type.toLowerCase() === "roomtype") {
          element.isHalfCheck = false;
        }
      });

      let allPrevLocationList = [...this.state.allPrevLocationList];
      allLocationList.forEach(element => {     
            
        if(element.isChecked==="yes"){
          const index = allPrevLocationList.findIndex((xvalue) => xvalue.id === element.id);
          allPrevLocationList[index].isChecked = "no";
        }
      });




      this.setState({ allLocationList: allLocationList, allPrevLocationList:allPrevLocationList ,
        isSelectRoomType: "Select All", isSelectLocationType: "Select All" }, () => {
        this.props.locationAdded(this.state.allLocationList, "remove");
      });
    }
  }

  handleOutsideClickForLocation = () => {
    // this.setState({ inputValue: "" },()=>{
    //   this.updateLists();
    // });
  };



  checkLocationToAdd = () => {
    
    let lable: any = "Select All";
    let lableRoom: any = "Select All";
    let allActualLocationList = _.cloneDeep([...this.state.allActualLocationList]);

    let allActualLocationList_final = _.cloneDeep([...this.state.allActualLocationList]);

    let procdata = _.cloneDeep([...this.state.upatedLocationList]);
    let procdata1 = _.cloneDeep([...this.state.upatedRoomTypeLocationList]);
    //let allProceuresListdata = _.cloneDeep([...this.state.allActualProceuresList]);


    if (procdata.length > 0 && procdata.length > 0) {
      procdata.forEach(element => {

        if (element.type.toLowerCase() === "location") {
          const index = allActualLocationList.findIndex((xvalue) => xvalue.roomID === element.roomID && xvalue.type.toLowerCase() == "location");
          if (index !== -1) {
            allActualLocationList[index].isChecked = "checkyes";

          }
        }

       

      });

      procdata1.forEach(element => {

        if (element.type.toLowerCase() == "roomtype") {
          const index = allActualLocationList.findIndex((xvalue) => xvalue.selectID === element.selectID && xvalue.type.toLowerCase() == "roomtype");
          if (index !== -1) {
            allActualLocationList[index].isChecked = "checkyes";

          }
        }
      
      });




      allActualLocationList.forEach(element => {
        if (element.isChecked === "checkyes" && element.type.toLowerCase() == "location") {

          const index = allActualLocationList_final.findIndex((xvalue) => xvalue.roomID === element.roomID && xvalue.type.toLowerCase() == "location");
          if (index !== -1) {
            allActualLocationList_final[index].isChecked = "yes";

          }
        } else if (element.isChecked === "checkyes" && element.type.toLowerCase() == "roomtype") {

          const index = allActualLocationList_final.findIndex((xvalue) => xvalue.selectID === element.selectID && xvalue.type.toLowerCase() == "roomtype");
          if (index !== -1) {
            allActualLocationList_final[index].isChecked = "yes";

          }
        }
        else {

          if (element.type.toLowerCase() == "location") {
            const index = allActualLocationList_final.findIndex((xvalue) => xvalue.roomID === element.roomID && xvalue.type.toLowerCase() == "location");
            if (index !== -1) {
              allActualLocationList_final[index].isChecked = "no";

            }
          }
          if (element.type.toLowerCase() == "roomtype") {
            const index = allActualLocationList_final.findIndex((xvalue) => xvalue.selectID === element.selectID && xvalue.type.toLowerCase() == "roomtype");
            if (index !== -1) {
              allActualLocationList_final[index].isChecked = "no";

            }
          }
        }




      });


      //// Half check and full check

      let roomTypeMaster = allActualLocationList.filter(x => x.type.toLowerCase() === "roomtype");

      roomTypeMaster.forEach(cntelement => {

        let halfCheckvalueno = allActualLocationList_final.filter((xvalue) => xvalue.selectID === cntelement.selectID
          && xvalue.type.toLowerCase() !== "roomtype");

        let totalcount = allActualLocationList_final.filter((xvalue) => xvalue.selectID === cntelement.selectID
          && xvalue.type.toLowerCase() !== "roomtype" && xvalue.isChecked === "yes");


        if (halfCheckvalueno.length !== totalcount.length) {
          const halfCheckparent = allActualLocationList_final.findIndex((xvalue) => xvalue.selectID === cntelement.selectID
            && xvalue.type.toLowerCase() === "roomtype" && xvalue.isChecked === "yes");
          if (halfCheckparent !== -1) {
            allActualLocationList_final[halfCheckparent].isHalfCheck = true;
          } else {

            const halfCheckparent = allActualLocationList_final.findIndex((xvalue) => xvalue.selectID === cntelement.selectID
              && xvalue.type.toLowerCase() === "roomtype" && xvalue.isChecked === "no");
            if (halfCheckparent !== -1) {
              allActualLocationList_final[halfCheckparent].isHalfCheck = totalcount.length === 0 ? false : true;
            }

          }

        }
        if (halfCheckvalueno.length === totalcount.length) {
          const halfCheckparent = allActualLocationList_final.findIndex((xvalue) => xvalue.selectID === cntelement.selectID
            && xvalue.type.toLowerCase() === "roomtype" && xvalue.isChecked === "yes");
          if (halfCheckparent !== -1) {
            allActualLocationList_final[halfCheckparent].isHalfCheck = false;
          }

        }


      });


      

     this.setState({allLocationList:[]},()=>{
      this.setState({ allLocationList: allActualLocationList_final ,allPrevLocationList:allActualLocationList_final});
     })

    
     let data =allActualLocationList_final ;
     let checkSelectAll = data.filter((x) => x.isChecked === "no"
       && x.type.toLowerCase() === "roomtype");
     if (checkSelectAll.length === 0) {
       lable = "Clear All";
       lableRoom = "Clear All";
     } else {
       lable = "Select All";
       lableRoom = "Select All";
 
     }   
     
     this.setState({
        isSelectLocationType: lable,isSelectRoomType: lableRoom
     });
 


     
    }

   // debugger;

    if (procdata.length === 0 && procdata1.length === 0) {
      allActualLocationList.forEach(element => {
        if (element.type.toLowerCase() === "location") {
          const index = allActualLocationList.findIndex((xvalue) => xvalue.roomID === element.roomID && xvalue.type.toLowerCase() == "location");
          if (index !== -1) {
            allActualLocationList[index].isChecked = "no";
            allActualLocationList[index].isHalfCheck = false;
          }
        } else {
          const index = allActualLocationList.findIndex((xvalue) => xvalue.selectID === element.selectID && xvalue.type.toLowerCase() !== "location");
          if (index !== -1) {
            allActualLocationList[index].isChecked = "no";
            allActualLocationList[index].isHalfCheck = false;

          }
        }
      });



      this.setState({allLocationList:[]},()=>{
        this.setState({ allLocationList: allActualLocationList,allPrevLocationList:allActualLocationList });
      })
      let data =allActualLocationList ;
      let checkSelectAll = data.filter((x) => x.isChecked === "no"
        && x.type.toLowerCase() === "roomtype");
      if (checkSelectAll.length === 0) {
        lable = "Clear All";
        lableRoom = "Clear All";
      } else {
        lable = "Select All";
        lableRoom = "Select All";
  
      }   
      
      this.setState({
         isSelectLocationType: lable,isSelectRoomType: lableRoom
      });
  

      
    }




  }

  upatedLocationList = (locationChieldData: any, roomTypeData: any) => {

    this.setState({ upatedLocationList: locationChieldData, upatedRoomTypeLocationList: roomTypeData, isUpdateList: "Update" });
  }

  handleOutsideClickForProcdure = (e: any) => {
    this.setState({ inputValue: "" }, () => {
      this.checkLocationToAdd();
    });

  };

  handleDropdownChangeLocation = () => {
    this.props.locationAdded(this.state.allPrevLocationList, "Add");
    if (this.skipRefLocation?.current && this.skipRefLocation?.current?.ariaExpanded === "true") {
      this.skipRefLocation.current.click()
    }
  }




  render() {






    return (



      <div className="add-room-dropdown"
      // onClick={this.handleOutsideClickForLocation}
      >
        <Dropdown onToggle={(e) => this.handleOutsideClickForProcdure(e)} className="pl-3">
          <DropdownToggle className="add-link Add-location" ref={this.skipRefLocation} disabled={this.props.disabled}>Add location</DropdownToggle>
          <DropdownMenu>
            <div className="coaSearch">
              <div className="dropDownPanel inner-label">
                <div className="SearchInput">
                  <div className="input">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                      <path d="M5.66634 11.0002C6.84966 10.9999 7.99888 10.6038 8.93101 9.87483L11.8617 12.8055L12.8043 11.8628L9.87368 8.93216C10.603 7.99994 10.9994 6.85045 10.9997 5.66683C10.9997 2.72616 8.60701 0.333496 5.66634 0.333496C2.72567 0.333496 0.333008 2.72616 0.333008 5.66683C0.333008 8.6075 2.72567 11.0002 5.66634 11.0002ZM5.66634 1.66683C7.87234 1.66683 9.66634 3.46083 9.66634 5.66683C9.66634 7.87283 7.87234 9.66683 5.66634 9.66683C3.46034 9.66683 1.66634 7.87283 1.66634 5.66683C1.66634 3.46083 3.46034 1.66683 5.66634 1.66683Z" fill="#84888C"></path>
                    </svg>
                    <input
                      ref={this.inputSearch}
                      key={"text-Filter"}
                      type="text"
                      className="form-control"
                      onChange={this.handleChange}
                      placeholder="Search by location or room type"
                      value={this.state.inputValue}
                    />
                  </div>
                </div>
                <ul>
                  {this.state.allLocationList.length > 0 && (
                    <div className="wrapper">
                      <div className="header">
                        <div className="dropdown-header divider">Room Types</div>
                        {this.state.inputValue==="" &&(
                        <Button
                          onClick={() =>
                            this.selectRoomType(this.state.isSelectRoomType)
                          }
                          className="select-link">{this.state.isSelectRoomType}</Button>
                          )}
                      </div>
                      {this.state.allLocationList.map((item: any, idx: any) => {
                        if (item.type.toLowerCase() === "roomtype")
                          return (
                            <div>

                              <li
                                id={idx}
                                key={idx}
                                className="user-list"
                              // className={
                              //   item.isconflict === "Yes" ? "disabled" : ""
                              // }
                              // onClick={() =>
                              //   this.handleDropdownChange(item)
                              // }
                              >
                                <Form.Check
                                  // custom
                                  type="checkbox"
                                  key={"custom-" + idx}
                                  id={"custom-" + idx}
                                  //className={item.isHalfCheck ? "half-check-checked":""}
                                  //checked={item.isChecked.toLowerCase() !== "no" ? true : false}

                                  ref={(chkRef) => {
                                    if (chkRef) {
                                      this.checkAllRefLocation = chkRef;
                                      // if (
                                      //   item.isHalfCheck === false
                                      // ) {
                                      //   chkRef.checked = true;
                                      // } else {
                                      //   chkRef.checked = false;
                                      // }

                                      if (
                                        item.isHalfCheck
                                      ) {
                                        chkRef.indeterminate = true;
                                        chkRef.checked = false
                                      } else {

                                        chkRef.indeterminate = false;
                                        if (item.isChecked.toLowerCase() === "no") {
                                          chkRef.checked = false;
                                        } else {
                                          chkRef.checked = true;
                                        }

                                      }
                                    }
                                  }}



                                  onChange={(e: any) =>
                                    this.handleCheckCheckbox1(item.id, e)
                                  }
                                //ref={this.checkAllRefLocation}
                                />
                                <div className="user-details">

                                  <div className="name-position mr-auto">
                                    <EllipsisWithTooltip placement="bottom">
                                      <div className="name">
                                        {item.name}
                                      </div>
                                    </EllipsisWithTooltip>
                                    {/* <EllipsisWithTooltip placement="bottom">
                                        <div className="pos-name">
                                          {item.positionName}
                                        </div>
                                      </EllipsisWithTooltip> */}
                                  </div>

                                </div>
                              </li>
                            </div>
                          );
                      })}
                      <div className="header serperator">
                        <div className="dropdown-header divider">Locations</div>
                        {this.state.inputValue==="" &&(
                        <Button
                          onClick={() =>
                            this.selectLocationType(this.state.isSelectLocationType)
                          }
                          className="select-link">{this.state.isSelectLocationType}</Button>
                          )}
                      </div>
                      {this.state.allLocationList.map((item: any, idx: any) => {
                        if (item.type.toLowerCase() === "location")
                          return (
                            <div
                            // data-tip={
                            //   tooltipIncompleteMessage
                            // }
                            >
                              <li id={idx} key={idx} className={"disabled user-list"}
                              // onClick={() =>
                              //   this.handleDropdownChange(item)
                              // }

                              >
                                <Form.Check
                                  // custom
                                  type="checkbox"
                                  key={"custom-" + idx}
                                  id={"custom-" + idx}
                                  className="checkbox-custom"

                                  checked={item.isChecked.toLowerCase() !== "no" ? true : false}




                                  onChange={(e: any) =>
                                    this.handleRoomsCheckCheckbox(item.id, e)
                                  }
                                />
                                <div className="user-details">

                                  <div className="name-position mr-auto">
                                    <EllipsisWithTooltip placement="bottom">
                                      <div className="name">
                                        {item.name}
                                      </div>
                                    </EllipsisWithTooltip>
                                    {/* <EllipsisWithTooltip placement="bottom">
                                        <div className="pos-name">
                                          {item.positionName}
                                        </div>
                                      </EllipsisWithTooltip> */}
                                  </div>
                                </div>
                              </li>
                            </div>
                          );
                      })}
                    </div>
                  )}
                </ul>
                <div className="footer text-right">
                  <Button
                    onClick={() =>
                      this.handleDropdownChangeLocation()
                    }
                    className="select-link">Select</Button>
                </div>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>

    );
  }
}
