import * as React from "react";
import {
  Container,
  Tabs,
  Tab,
  Form,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { LaborSetting } from "../../Common/Services/LaborSetting";
import { UserManagement } from "../../Common/Services/UserManagement";
import { ILaborEnterpriseSetting } from "../../Common/Contracts/ILaborEnterpriseSetting";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../Common/Services/RoleSubPermissionSetting";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";
import Calendar from "react-calendar";
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../../Common/Utilis";
import { EnterpriseWiseLaborSettingsLogHistory } from "./EnterpriseWiseLaborSettingsLogHistory";
import "./EnterpriseWiseLaborSettings.scss";
import { FreshDeskChat } from "../../Common/ThirdPartyServices/FreshDeskChat";


const performanceReviewOptions: any[] = [
  { label: "Date of Hire", value: "1" },
  { label: "Specific Date", value: "2" },
];

let Cta1Link="https://support.inn-flow.net/support/solutions/articles/1000297231-enterprise-wide-labor-settings";
class EnterpriseWiseLaborSettings extends React.Component<any, any> {
  private autoFocusPunchingIn: any;
  private autoFocusHouseKeepingExplaination: any;
  private autoFocusPunchOut: any;
  private autoFocusVariance: any;
  private autoFocusBreakTime: any;
  private autoFocusExceedBreakTime: any;
  private autoFocusActualVariance: any;
  private autoFocusSelectedDate: any;
  private autoFocusOTARAlert: any;
  private autoFocusDailyDepartmentApproval: any;
  private autoFocusDailyGMApproval: any;

  constructor(props: any) {
    super(props);
    this.autoFocusPunchingIn = React.createRef();
    this.autoFocusHouseKeepingExplaination = React.createRef();
    this.autoFocusPunchOut = React.createRef();
    this.autoFocusVariance = React.createRef();
    this.autoFocusBreakTime = React.createRef();
    this.autoFocusExceedBreakTime = React.createRef();
    this.autoFocusActualVariance = React.createRef();
    this.autoFocusSelectedDate = React.createRef();
    this.autoFocusOTARAlert = React.createRef();
    this.autoFocusDailyDepartmentApproval = React.createRef();
    this.autoFocusDailyGMApproval = React.createRef();

    this.state = {
      key: "general",
      selected: [],
      salariedInclude: Boolean,
      contractEmployee: Boolean,
      isPunchingIn: Boolean,
      isHouseKeepingExplainationlimit: Number,
      punchingIn: Number,
      HouseKeepingExplaination: Number,
      actulvariance: Number,
      isAutoPunchOut: Boolean,
      autoPunchOut: Number,
      isVariance: Boolean,
      isVarianceThreshold: Boolean,
      variance: "",
      breakTime: "",
      exceedBreakTime: "",
      isBreakTimeSubracted: Boolean,
      isAutoTrueUp: Boolean,
      enterPriseId: 0,
      dataValidPunchingIn: "",
      dataValidHouseKeepingExplaination: "",
      dataValidAutoPunchOut: "",
      dataValidVariance: "",
      dataValidBreakTime: "",
      dataValidExceedBreakTime: "",
      dataValidActualVariance: "",
      isMPOREnabled: Boolean,
      isDataChange: false,
      options: [],
      selectedDate: "",
      awardFrequency: "Select Frequency",
      awardFrequencyList: [
        { id: "Anniversary Date", name: "Anniversary Date" },
      ],
      isManageSetting: false,
      isPagePermission: false,
      holidaysAttribution: [
        "Day with majority of hours", 
        "Punch-in day"
      ],
      selectedHolidayAttribution: "Day with majority of hours",
      performanceReviewToggle: false,
      performanceReviewType: "0",
      ReviewDate: "",
      calenderShow: false,
      performanceMode: "",
      resetMode: false,
      showChangeLogModal: false,
      isOTARAlert: Boolean,
      OTARAlert: Number,
      dataValidOTARAlert: "",
      isDailyDepartmentApproval: Boolean,
      DailyDepartmentApproval: Number,
      dataValidDailyDepartmentApproval: "",
      isDailyGMApproval: Boolean,
      DailyGMApproval: Number,
      dataValidDailyGMApproval: "",
      onFocus : false,
      applyToshiftFocus : false,
      OTARAlertFF : "",
      GMApproveFF : "",
      HousekeepingFF : "", 
    };
  }



  getMenuPermission = () => {
    let isPermission = false;
    let requestObject = {} as any;
    requestObject.permissionID = "ALL";
    requestObject.Module = "LaborManagement";
    requestObject.Type = "menu";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            if (element.permissionID === 10030) {
              isPermission = true;
              this.setState({ isPagePermission: true });
            }
          });
          if (isPermission) {
            this.getEnterprisePermission();
            LaborSetting.enterPriseSettingDetails()
              .then(async (result: any | null) => {
                if (result != null) {
                  let data = result.result[0] as ILaborEnterpriseSetting;
                  this.setState({
                    salariedInclude:
                      data.salariedInclude === "1" ? true : false,
                    contractEmployee:
                      data.contractEmployee === "Yes" ? true : false,
                    isPunchingIn: data.isPunchingIn,
                    isHouseKeepingExplainationlimit: data.isHouseKeepingExplainationlimit,
                    punchingIn: data.punchingIn,
                    HouseKeepingExplaination: data.houseKeepingExplaination,
                    isAutoPunchOut: data.autoPunchOut,
                    autoPunchOut: data.autoPunchOut,
                    isVariance: data.variance.length > 0 ? true : false,
                    variance: data.variance,
                    breakTime: data.breakTime,
                    exceedBreakTime: data.exceedBreakTime,
                    isBreakTimeSubracted: data.isBreakTimeSubracted,
                    enterPriseId: data.enterPriseID,
                    actulvariance: data.actualVariance,
                    selectedDate:
                      data.calenderDate !== ""
                        ? new Date(data.calenderDate)
                        : "",
                    awardFrequency: data.ptoType,
                    performanceReviewToggle: data.allowPerformanceReview,
                    performanceReviewType: data.reviewType,
                    ReviewDate: data.reviewDate,
                    isOTARAlert: data.isOTARAlert,
                    OTARAlert: data.otarAlert,
                    isDailyDepartmentApproval: data.isDailyDepartmentApproval,
                    DailyDepartmentApproval: data.dailyDepartmentApproval,
                    isDailyGMApproval: data.isDailyGMApproval,
                    DailyGMApproval: data.dailyGMApproval,
                  });
                }
                resolve();
              })
              .catch((error) => {
                reject();
              });

            LaborSetting.GetLaborPayrollUserName()
              .then((result: any | null) => {
                let optionslist: any[] = [];
                if (result != null) {
                  let data = result.result as [];
                  data.map((res, index) => optionslist.push(res));
                }
                this.setState({ options: optionslist });
                resolve();
              })
              .catch((error) => {
                reject();
              });
          } else {
            window.location.href = "/unauthorize-access";
          }
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  };

  componentWillMount() {
    LaborSetting.GetPayrollSavedUser()
      .then(async (result: any | null) => {
        let userData = [];
        if (result != null) {
          let userdata = result.result as [];
          userdata.map((res, index) => userData.push(res));
          this.setState({ selected: userData });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  componentDidMount() {
    this.getEnterprisePermission();
    this.getEnterpriseFeatureFalg()
    LaborSetting.enterPriseSettingDetails()
      .then(async (result: any | null) => {
        if (result != null) {
          let data = result.result[0] as ILaborEnterpriseSetting;
          this.setState({
            salariedInclude: data.salariedInclude === "1" ? true : false,
            contractEmployee: data.contractEmployee === "Yes" ? true : false,
            isPunchingIn: data.isPunchingIn,
            isHouseKeepingExplainationlimit: data.isHouseKeepingExplainationlimit,
            punchingIn: data.punchingIn === 0 ? "" : data.punchingIn,
            HouseKeepingExplaination : data.houseKeepingExplaination === 0 ? "" :data.houseKeepingExplaination,
            isAutoPunchOut: data.autoPunchOut,
            autoPunchOut: data.autoPunchOut === 0 ? "" : data.autoPunchOut,
            isVariance: data.variance.length > 0 ? true : false,
            variance: data.variance === "0" ? "" : data.variance,
            breakTime:
              data.exceedBreakTime === "0" && data.breakTime === "0"
                ? ""
                : data.breakTime,
            exceedBreakTime:
              data.exceedBreakTime === "0" && data.breakTime === "0"
                ? ""
                : data.exceedBreakTime,
            isAutoTrueUp: data.autoTrueUpBreak === 1 ? true : false,
            isBreakTimeSubracted: data.isBreakTimeSubracted,
            enterPriseId: data.enterPriseID,
            actulvariance: data.actualVariance,
            selectedDate:
              data.calenderDate !== "" ? new Date(data.calenderDate) : "",
            awardFrequency: data.ptoType,
            isMPOREnabled: data.isMPOROptimize,
            selectedHolidayAttribution:
              data.holidayAttribute.toLowerCase() === "punchinday"
                // ? "the punch-in day."
                ? "Punch-in day"
                // : "the day with majority of hours.",
                : "Day with majority of hours",
            performanceReviewToggle: data.allowPerformanceReview,
            performanceReviewType: data.reviewType,
            ReviewDate: data.reviewDate,
            isOTARAlert: data.isOTARAlert,
            OTARAlert: data.otarAlert === 0 ? "" : data.otarAlert,
            isDailyDepartmentApproval: data.isDailyDepartmentApproval,
            //DailyDepartmentApproval: data.dailyDepartmentApproval === 0 ? "" : data.dailyDepartmentApproval,
            DailyDepartmentApproval: data.dailyDepartmentApproval ,
             isDailyGMApproval: data.isDailyGMApproval,
            // DailyGMApproval: data.dailyGMApproval === 0 ? "" : data.dailyGMApproval,
            DailyGMApproval: data.dailyGMApproval ,
          });
          if (
            this.state.performanceReviewType == "1" ||
            this.state.performanceReviewType == "2"
          ) {
            this.setState({ performanceMode: "edit" });
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });

    LaborSetting.GetLaborPayrollUserName()
      .then((result: any | null) => {
        let optionslist: any[] = [];
        if (result != null) {
          let data = result.result as [];
          data.map((res, index) => optionslist.push(res));
        }
        this.setState({ options: optionslist });
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  getEnterpriseFeatureFalg = () => {
    LaborSetting.getEnterpriseFeatureFalg()
      .then(async (result: any | null) => {        
        debugger
        if (result.result != null && result.result.length > 0) {
          result.result.forEach((element: any) => {
           
              if(element.featureValue==="GM Approval (Dept and Submit page)" && element.featureStatus==="Yes"){
                this.setState({ GMApproveFF: "Yes" });
              }
              if(element.featureValue==="OTAR Alerts" && element.featureStatus==="Yes"){
                this.setState({ OTARAlertFF: "Yes" });
              }
              if(element.featureValue==="Housekeeping Approval" && element.featureStatus==="Yes"){
                this.setState({ HousekeepingFF: "Yes" });
              }
              
         
          });
          
        } else {
          this.setState({ GMApproveFF: "No",OTARAlertFF: "No",HousekeepingFF:"No" });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  };

  getEnterprisePermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10030;
    requestObject.Module = "LaborManagement";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            switch (element.permissionID) {
              case 10040:
                this.setState({ isManageSetting: true });
            }
          });
        } else {
          Utils.toastError(result.message, {
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
        });
        reject();
      });
  };

  handleSelectDate = (date: any) => {
    this.setState({ selectedDate: date });
    setTimeout(() => {
      this.autoFocusSelectedDate.current.setFocus();
    }, 0);
  };

  onChange(
    e: any,
    type: string,
    isBlur = false,
    isInput = false,
    isChange = false
  ) {
    const { ReviewDate } = this.state;

    if (this.state.isManageSetting) {
      switch (type) {
        case "PerformanceReview":
          this.setState(
            {
              performanceReviewType: "0",
              performanceReviewDate: "",
              ReviewDate: "",
              performanceReviewToggle: e.target.value,
              showReviewConfirmation: false,
            },
            () => {
              if (!this.state.performanceReviewToggle) {
                this.savePerformanceReviewSetting();
              }
            }
          );

          break;
        case "PerformanceReviewType":
          if (e?.value == "1") {
            this.setState({
              performanceReviewType: e.value,
              ReviewDate: "",
              showReviewConfirmation: true,
              resetMode: false,
            });
          } else if (e?.value == "2") {
            this.setState({
              performanceReviewType: e.value,
              ReviewDate: "",
              showReviewConfirmation: false,
              resetMode: false,
            });
          }
          break;
        case "ReviewDate":
          const _formattedDate = Utils.AddSlashINInputDateFormatMMDD(
            e,
            ReviewDate,
            true,
            isBlur
          );
          if (_formattedDate === "invalid") {
            return;
          } else {
            e = _formattedDate;
          }

          if (e?.toString()?.length === 5 && e != ReviewDate) {
            new Date();
            this.setState({ ReviewDate: e }, () => {
              this.setState({ showReviewConfirmation: true });
            });
          } else {
            this.setState({ ReviewDate: e });
          }
          break;
        case "PtoAwardFrequency":
          if (this.state.awardFrequency === "Calendar Date") {
            if (this.state.selectedDate !== "") {
              this.saveEnterPriseSetting(
                "CalendarDate",
                this.state.selectedDate
              );
            } else {
              Utils.toastError("Please select calender date", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else if (this.state.awardFrequency === "Anniversary Date") {
            this.saveEnterPriseSetting("AnniversaryDate", "");
          }

          break;

        case "ActualVariance":
          if (e.target.value > 100 || e.target.value < 1) {
            this.setState({ dataValidActualVariance: "ExceedActualVariance" });
            // Utils.toastError(
              toast.error(
              "Variance Highlighting Threshold value should be between 1 to 100.",
              {
                // // position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            this.autoFocusActualVariance.current.focus();
          } else {
            this.setState({ dataValidActualVariance: "" });
            this.setState({ actulvariance: e.target.value });
            this.saveEnterPriseSetting(
              "ActualVariance",
              Number(e.target.value)
            );
          }
          break;

        case "SalariedInclude":
          this.setState({ salariedInclude: e.target.checked });
          this.saveEnterPriseSetting(
            "SalariedInclude",
            e.target.checked ? "1" : "0"
          );
          break;

        case "ContractEmployee":
          this.setState({ contractEmployee: e.target.checked });
          this.saveEnterPriseSetting(
            "ContractEmployee",
            e.target.checked ? "Yes" : "No"
          );
          break;
        case "isPunchingIn":
          this.setState({ isPunchingIn: e.target.checked }, () => {
            this.autoFocusPunchingIn.current?.focus();
          });
          if (!e.target.checked) {
            this.setState({ punchingIn: "" });
            this.saveEnterPriseSetting("PunchingIn", 0);
          }
          break;
        case "isHouseKeepingExplainationlimit":
            this.setState({ isHouseKeepingExplainationlimit: e.target.checked }, () => {
              this.autoFocusHouseKeepingExplaination?.current?.focus();
            });
            if (!e.target.checked) {
              this.setState({ HouseKeepingExplaination: "" });
              this.saveEnterPriseSetting("HouseKeepingExplaination", 0);
            }
            break;
        case "PunchingIn":
          debugger;
          if (Number(e.target.value) >= 0 && Number(e.target.value) <= 480) {
            if (this.state.isDataChange) {
              this.setState({
                isPunchingIn: Number(e.target.value) > 0 ? true : false,
                dataValidPunchingIn: "",
                punchingIn:
                  Number(e.target.value) === 0 ? "" : Number(e.target.value),
              });
              this.saveEnterPriseSetting("PunchingIn", Number(e.target.value));
            } else {
              this.setState({
                isPunchingIn: Number(e.target.value) > 0 ? true : false,
                dataValidPunchingIn: "",
                // punchingIn: "",
              });
            }
          } else {
            this.setState(
              {
                punchingIn: Number(e.target.value),
                dataValidPunchingIn: "PunchingIn",
              },
              () => {
                this.autoFocusPunchingIn.current.focus();
              }
            );
            // Utils.toastError("Punch-in minutes should be between 0 and 480", {
              toast.error("Punch-in minutes should be between 0 and 480", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          break;

        case "HouseKeepingExplaination":
          if (Number(e.target.value) >= 0 && Number(e.target.value) <= 999) {
            if (this.state.isDataChange) {
              this.setState({
                isHouseKeepingExplainationlimit: Number(e.target.value) > 0 ? true : false,
                dataValidHouseKeepingExplaination: "",
                HouseKeepingExplaination:
                  Number(e.target.value) === 0 ? "" : Number(e.target.value),
              });
              this.saveEnterPriseSetting("HouseKeepingExplaination", Number(e.target.value));
            } else {
              this.setState({
                isHouseKeepingExplainationlimit: Number(e.target.value) > 0 ? true : false,
                dataValidHouseKeepingExplaination: "",
                HouseKeepingExplaination: "",
              });
            }
          } else {
            this.setState(
              {
                HouseKeepingExplaination: Number(e.target.value),
                dataValidHouseKeepingExplaination: "HouseKeepingExplaination",
              },
              () => {
                this.autoFocusHouseKeepingExplaination?.current?.focus();
              }
            );
            Utils.toastError("Houskeeping Limit minutes should be between 0 and 1000", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          break;

        case "isAutoPunchOut":
          this.setState({ isAutoPunchOut: e.target.checked }, () => {
            this.autoFocusPunchOut.current?.focus();
          });
          if (!e.target.checked) {
            this.setState({
              autoPunchOut: "",
            });
            this.saveEnterPriseSetting("AutoPunchOut", 0);
          }

          break;

        case "AutoPunchOut":
          if (Number(e.target.value) >= 0 && Number(e.target.value) <= 24) {
            if (this.state.isDataChange) {
              this.setState({
                isAutoPunchOut: Number(e.target.value) > 0 ? true : false,
                dataValidAutoPunchOut: "",
                autoPunchOut:
                  Number(e.target.value) === 0 ? "" : Number(e.target.value),
              });
              this.saveEnterPriseSetting(
                "AutoPunchOut",
                Number(e.target.value)
              );
            } else {
              this.setState({
                isAutoPunchOut: Number(e.target.value) > 0 ? true : false,
                dataValidAutoPunchOut: "",
                autoPunchOut:
                  Number(e.target.value) === 0 ? "" : Number(e.target.value),
              });
            }
          } else {
            this.setState(
              {
                autoPunchOut: Number(e.target.value),
                dataValidAutoPunchOut: "AutoPunchOut",
              },
              () => {
                this.autoFocusPunchOut.current.focus();
              }
            );
            Utils.toastError("Auto Punch out hours should be between 0 and 24", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          break;

        case "isVariance":
          this.setState({ isVariance: e.target.checked }, () => {
            this.autoFocusVariance.current?.focus();
          });
          if (!e.target.checked) {
            this.setState({ variance: "" });
            this.saveEnterPriseSetting("Variance", "");
          }
          break;

        case "Variance":
          if (
            e.target.value !== "" &&
            e.target.value <= 100 &&
            e.target.value !== "0" && e.target.value !== "0.00"
          ) {
            debugger;
            if (e.target.value.includes(".")) {
              const varianceArray = e.target.value.split(".");
              if (
                isNaN(Number(varianceArray[0])) ||
                isNaN(Number(varianceArray[1]))
              ) {
                this.setState(
                  { variance: e.target.value, dataValidVariance: "Variance" },
                  () => {
                    this.autoFocusVariance.current.focus();
                  }
                );
                Utils.toastError("Please enter valid variance format.", {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                });
              } else if (varianceArray[1].toString().trim().length > 2) {
                this.setState(
                  { variance: e.target.value, dataValidVariance: "Variance" },
                  () => {
                    this.autoFocusVariance.current.focus();
                  }
                );
                Utils.toastError("Please enter maximum two digits after decimal.", {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                });
              } else {
                if (this.state.isDataChange) {
                  this.setState({
                    isVariance: e.target.value != null ? true : false,
                    dataValidVariance: "",
                  });
                  this.saveEnterPriseSetting("Variance", e.target.value);
                }
              }
            } else if (isNaN(Number(e.target.value))) {
              this.setState(
                { variance: e.target.value, dataValidVariance: "Variance" },
                () => {
                  this.autoFocusVariance.current.focus();
                }
              );
              Utils.toastError("Please enter valid variance format.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
              });

              if (e.target.value.toString().trim().length > 2) {
                this.setState(
                  { variance: e.target.value, dataValidVariance: "Variance" },
                  () => {
                    this.autoFocusVariance.current.focus();
                  }
                );
                Utils.toastError("Please enter maximum two digits before decimal.", {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            } else {
              debugger;
              if (this.state.isDataChange) {
                this.setState({
                  isVariance: e.target.value != null ? true : false,
                  dataValidVariance: "",
                });
                this.saveEnterPriseSetting("Variance", e.target.value);
              }
            }
          } else if (e.target.value === "") {
            this.setState({ isVariance: false, dataValidVariance: "" });
            this.saveEnterPriseSetting("Variance", "");
          } else if (e.target.value === "0" || e.target.value === "0.00") {
            this.setState({
              isVariance: false,
              dataValidVariance: "",
              variance: "",
            });
            this.saveEnterPriseSetting("Variance", "");
          } else {
            this.setState(
              { variance: e.target.value, dataValidVariance: "Variance" },
              () => {
                this.autoFocusVariance.current.focus();
              }
            );
            // Utils.toastError("Variance should be less than 100.", {
             toast.error("Variance should be less than or equals to 100.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          break;

        case "isBreakTimeSubracted":
          this.setState({ isBreakTimeSubracted: e.target.checked }, () => {
            this.autoFocusBreakTime.current?.focus();
          });
          if (!e.target.checked) {
            this.setState({
              breakTime: "",
              exceedBreakTime: "",
              dataValidBreakTime: "",
              dataValidExceedBreakTime: "",
              isAutoTrueUp: e.target.checked,
            });
            this.saveEnterPriseSetting("isBreakTime", 0);
          }
          // else if(e.target.checked === false){
          //   this.saveEnterPriseSetting("AutoTrueUpBreak",0);
          // }
          break;

        case "BreakTime":
          if (Number(e.target.value) > 0 && Number(e.target.value) < 60) {
            if (this.state.isDataChange) {
              this.setState(
                {
                  dataValidBreakTime: "",
                  dataValidExceedBreakTime: "",
                },
                () => {
                  this.autoFocusExceedBreakTime.current.focus();
                }
              );
              this.saveEnterPriseSetting("BreakTime", Number(e.target.value));
            }
          } else {
            if (Number(e.target.value) > 59) {
              this.setState(
                {
                  breakTime: e.target.value,
                  dataValidBreakTime: "BreakTime",
                },
                () => {
                  this.autoFocusBreakTime.current.focus();
                }
              );
              Utils.toastError("Please enter Break length between 0 and 59", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else {
              this.setState(
                {
                  breakTime: Number(e.target.value),
                  dataValidBreakTime: "",
                  dataValidExceedBreakTime: "",
                  // isAutoTrueUp:false,
                },
                () => {
                  this.autoFocusExceedBreakTime.current.focus();
                }
              );
              this.saveEnterPriseSetting("BreakTime", 0);
            }
          }
          break;

        case "ExceedBreakTime":
          if (Number(e.target.value) > 0 && Number(e.target.value) < 24) {
            if (this.state.isDataChange) {
              this.setState({
                dataValidExceedBreakTime: "",
                exceedBreakTime: Number(e.target.value),
              });
              this.saveEnterPriseSetting(
                "ExceedBreakTime",
                Number(e.target.value)
              );
            }
          } else {
            if (Number(e.target.value) > 23) {
              this.setState(
                {
                  exceedBreakTime: Number(e.target.value),
                  dataValidExceedBreakTime: "ExceedBreakTime",
                },
                () => {
                  this.autoFocusExceedBreakTime.current.focus();
                }
              );
              Utils.toastError("Please enter Shifts over hours between 0 and 23", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else {
              if (this.state.breakTime > 0) {
                this.setState({
                  exceedBreakTime: Number(e.target.value),
                });
                this.saveEnterPriseSetting(
                  "ExceedBreakTime",
                  Number(e.target.value)
                );
              } else {
                this.setState({
                  isBreakTimeSubracted: false,
                  breakTime: "",
                  exceedBreakTime: "",
                  dataValidExceedBreakTime: "",
                  isAutoTrueUp: false,
                });
                this.saveEnterPriseSetting("isBreakTime", 0);
              }
            }
          }
          break;
        case "OptimizedMPOR":
          this.setState({ isMPOREnabled: e.target.checked });
          this.saveEnterPriseSetting(
            "OptimizedMPOR",
            e.target.checked ? true : false
          );
          break;
        case "OptimizedMPOR":
          this.setState({ isMPOREnabled: e.target.checked });
          this.saveEnterPriseSetting(
            "OptimizedMPOR",
            e.target.checked ? true : false
          );
          break;
        case "isAutoTrueUp":
          this.setState({ isAutoTrueUp: e.target.checked });
          this.saveEnterPriseSetting(
            "AutoTrueUpBreak",
            e.target.checked ? 1 : 0
          );
          break;
        case "HolidayAttribution":
          this.saveEnterPriseSetting(
            "HolidayAttribute",
            this.state.selectedHolidayAttribution.toLowerCase().includes("punch")
              ? "PunchInDay"
              : "MajorityHours"
          );
          break;
        case "isOTARAlert":
          this.setState({ isOTARAlert: e.target.checked }, () => {
            this.autoFocusOTARAlert.current?.focus();
          });
          if (!e.target.checked) {
            this.setState({ OTARAlert: "" });
            this.saveEnterPriseSetting("OTARAlert", 0);
          }
          break;

        case "OTARAlert":
            if (this.state.isDataChange) {
              this.setState({
                isOTARAlert: Number(e.target.value) > 0 ? true : false,
                dataValidOTARAlert: "",
                OTARAlert:
                  Number(e.target.value) === 0 ? "" : Number(e.target.value),
              });
              this.saveEnterPriseSetting("OTARAlert", Number(e.target.value));
            } else {
              this.setState({
                isOTARAlert: Number(e.target.value) > 0 ? true : false,
                dataValidOTARAlert: "",
                // OTARAlert: "",
              });
            }
          break;

        case "isDailyDepartmentApproval":
          debugger;
         
          this.setState({ isDailyDepartmentApproval: e.target.checked }, () => {
            
            this.autoFocusDailyDepartmentApproval.current?.focus();
          });
          if (!e.target.checked) {
            this.setState({ DailyDepartmentApproval: "" });
            this.saveEnterPriseSetting("DailyDepartmentApproval", 0);
          }
          break;

        case "DailyDepartmentApproval":
          debugger;
         
            if (this.state.isDataChange) {
              this.setState({
                isDailyDepartmentApproval: e.target.value===""? false: Number(e.target.value) >= 0 ? true : false,
                dataValidDailyDepartmentApproval: "",
                // DailyDepartmentApproval:
                //   Number(e.target.value) === 0 ? "" : Number(e.target.value),
                //DailyDepartmentApproval:Number(e.target.value) ,
              });
              this.saveEnterPriseSetting("DailyDepartmentApproval", Number(e.target.value));
            } else {
              this.setState({
                // isDailyDepartmentApproval: Number(e.target.value) > 0 ? true : false,
                isDailyDepartmentApproval: e.target.value===""? false: Number(e.target.value) >= 0 ? true : false,
                dataValidDailyDepartmentApproval: "",
                // DailyDepartmentApproval: "",
              });
            }
          break;

        case "isDailyGMApproval":
          this.setState({ isDailyGMApproval: e.target.checked }, () => {
            this.autoFocusDailyGMApproval.current?.focus();
          });
          if (!e.target.checked) {
            this.setState({ DailyGMApproval: "" });
            this.saveEnterPriseSetting("DailyGMApproval", 0);
          }
          break;

        case "DailyGMApproval":
         
            if (this.state.isDataChange) {
              this.setState({
                isDailyGMApproval: e.target.value===""? false: Number(e.target.value) >= 0 ? true : false,
                dataValidDailyGMApproval: "",
                // DailyGMApproval:
                //   Number(e.target.value) === 0 ? "" : Number(e.target.value),
              });
              this.saveEnterPriseSetting("DailyGMApproval", Number(e.target.value));
            } else {
              this.setState({
                // isDailyGMApproval: Number(e.target.value) > 0 ? true : false,
                isDailyGMApproval: e.target.value===""? false: Number(e.target.value) >= 0 ? true : false,
                dataValidDailyGMApproval: "",
                // DailyGMApproval: "",
              });
            }
          break;
        default:
          return type;
      }
    }
  }

  savePerformanceReviewSetting() {
    let ReviewSettingRequest = {} as any;
    ReviewSettingRequest.allowReview = this.state.performanceReviewToggle
      ? 1
      : 0;
    ReviewSettingRequest.reviewType = this.state.performanceReviewType;
    ReviewSettingRequest.reviewDate = this.state.ReviewDate;

    UserManagement.UpdatePerformanceReviewSetting(ReviewSettingRequest)
      .then(async (result: any | null) => {
        if (result.success) {
          this.setState({ showReviewConfirmation: false });
          this.setState({ performanceMode: "edit" });
          toast.success("Setting Updated Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }
  saveEnterPriseSetting(FieldName: string, FieldValue: any, toggle: any = "") {
    this.setState({ isDataChange: false });
    let laborEnterpriseRequest = {} as any;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    laborEnterpriseRequest.TenantID = tenantID;
    laborEnterpriseRequest.EnterPriseId = this.state.enterPriseId;
    laborEnterpriseRequest.FieldName = FieldName;
    laborEnterpriseRequest.FieldValue = FieldValue;
    LaborSetting.saveEnterPriseSetting(laborEnterpriseRequest)
      .then(async (result: any | null) => {
        console.log("laborEnterpriseRequest", laborEnterpriseRequest);

        if (
          FieldName === "AllowReview" ||
          FieldName === "ReviewType" ||
          FieldName === "ReviewDate"
        ) {
          return;
        }

        if (
          result != null &&
          toggle !== "toggleOff" &&
          result.message === "Settings Updated Successfully"
        ) {
          if(laborEnterpriseRequest.FieldName === "AutoTrueUpBreak" && laborEnterpriseRequest.FieldValue === 0 && this.state.isBreakTimeSubracted === false){

          }
          else{
          toast.success(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });}
          if (
            laborEnterpriseRequest.FieldName === "isBreakTime" &&
            laborEnterpriseRequest.FieldValue === 0
          ) {
            this.saveEnterPriseSetting("AutoTrueUpBreak", 0);
          }
        } else if (result.message !== null && toggle !== "toggleOff") {
          Utils.toastError(result.message, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          if (toggle !== "toggleOff") {
            let message = "Invalid Data, settings not saved.";
            Utils.toastError(message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = (): any => {
    this.setState({ calenderShow: true });
  };

  checkPerformanceToggle = () => {
    // debugger
    if (
      this.state.performanceReviewToggle == "1" &&
      this.state.performanceReviewType == "0"
    ) {
      this.setState({ resetMode: true });
      this.getMenuPermission();
    } else if (
      this.state.performanceReviewToggle == "1" &&
      this.state.performanceReviewType == "2" &&
      this.state.ReviewDate == ""
    ) {
      this.getMenuPermission();
      this.setState({ resetMode: true });
    }
  };

  typeheadChange(e: any) {
    if (this.state.isManageSetting) {
      this.setState({ selected: e });
      LaborSetting.SavePayrollUser(e)
        .then(async (result: any | null) => {
          if (result != null) {
            toast.success(result.message, {
            });
          } else {
            toast.success(result.message, {
            });
          }
          resolve();
        })
        .catch((error) => {
          reject();
        });
    }
  }

  formChange = (e, type) => {

    let value = e.target.value;
    if (/^\d+$/.test(value) === false) {
      e.target.value = value.replace(/[^\d]/g, "");
    }

    if (type === "ActualVariance") {
      this.setState({
        actulvariance: Number(e.target.value),
      });
    }
  };

  showHideChangeLogModal = () => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal,
    });
  };

  render() {
    const handleSelect = (eventKey: any) => {
      this.setState({ key: eventKey });
    };

    const handleAwardFrequency = (eventKey: any) => {
      if (eventKey === "Anniversary Date") {
        this.setState({ selectedDate: "" });
        this.setState({ awardFrequency: eventKey }, () => {
          this.onChange("", "PtoAwardFrequency");
        });
      } else {
        this.setState({ awardFrequency: eventKey });
        setTimeout(() => {
          this.autoFocusSelectedDate.current.setFocus();
        }, 0);
      }
    };

    const handleAttributionChange = (eventKey: any) => {
      this.setState({ selectedHolidayAttribution: eventKey }, () => {
        this.onChange("", "HolidayAttribution");
      });
    };

    if (this.state.showReviewConfirmation && this.state.resetMode == false) {
      confirmAlert({
        title: "Are you sure?",
        message:
          this.state.performanceMode == "edit"
            ? "This will update all active annual review due dates to use the new date type selected. Are you sure you would like to proceed?"
            : "This will automatically create an annual review based on the date selected.  Are you sure you want to proceed?",
        buttons: [
          {
            label: "Proceed",
            onClick: () => this.savePerformanceReviewSetting(),
          },
          {
            label: "Cancel",
            onClick: () => {
              this.setState({
                showReviewConfirmation: false,
                ReviewDate: "",
                performanceReviewType: "0",
              });
              reject();
            },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }

    let isDisabled = false;
    if (this.state.isManageSetting) {
      if (this.state.isBreakTimeSubracted) {
        isDisabled = false;
      } else {
        isDisabled = true;
      }
    } else {
      isDisabled = true;
    }

    return (
      <div className="enterprise-wise-labor-settings-main">
        <Container fluid className="body-sec">
          <div className="page-heading d-flex">
            <div className="mr-auto">Enterprise Wide Labor Settings</div>
            <div className="header-popout">
              <a onClick={()=> FreshDeskChat.redirectToFreshDeskURL(Cta1Link)} target="_blank" className="btn-outline-primary btn btn-primary knowledge-btn">
                Knowledge Base 
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M11.5 12.25H2.5C2.0875 12.25 1.75 11.9125 1.75 11.5V2.5C1.75 2.0875 2.0875 1.75 2.5 1.75H6.25C6.6625 1.75 7 1.4125 7 1C7 0.5875 6.6625 0.25 6.25 0.25H1.75C0.9175 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.925 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V7.75C13.75 7.3375 13.4125 7 13 7C12.5875 7 12.25 7.3375 12.25 7.75V11.5C12.25 11.9125 11.9125 12.25 11.5 12.25ZM8.5 1C8.5 1.4125 8.8375 1.75 9.25 1.75H11.1925L4.345 8.5975C4.0525 8.89 4.0525 9.3625 4.345 9.655C4.6375 9.9475 5.11 9.9475 5.4025 9.655L12.25 2.8075V4.75C12.25 5.1625 12.5875 5.5 13 5.5C13.4125 5.5 13.75 5.1625 13.75 4.75V1C13.75 0.5875 13.4125 0.25 13 0.25H9.25C8.8375 0.25 8.5 0.5875 8.5 1Z" fill="#2CAF92"/>
                </svg>
              </a>
              <Dropdown className="more-action" alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey="changeLog"
                    key="changeLog"
                    onClick={() => this.showHideChangeLogModal()}
                  >
                    Change Log
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.key}
            onSelect={handleSelect}
          >
            <Tab eventKey="general" title="General">
              <div className="section">
                {/* <div className="sec-ttl mb-3">
                  Labor Settings
                  <div className="sub-ttl">Labor configuration</div>
                </div> */}
                <div className="controlls">
                    <Form className="entity-row">
                      <div className="label-column">
                        <Form.Label className="entity-label">Include Salaried Employees in the Total Hours</Form.Label>
                        <h6>Enabling this setting will include the hours of Salary Exempt from OT and Salary Non-Exempt from OT employees to the total hour calculation on your Schedule page.</h6>
                      </div>
                      <Form.Check
                        type="switch"
                        id="sw_issalaried"
                        // label="Include salaried employees in the total hours"
                        checked={this.state.salariedInclude}
                        disabled={!this.state.isManageSetting}
                        onChange={(event: any) => {
                          this.onChange(event, "SalariedInclude");
                        }}
                      />
                    </Form>
                </div>
                <div className="controlls">
                  <Form className="entity-row">
                    <div className="label-column">
                      <div className="mb-2"> 
                        <Form.Label className="entity-label d-inline">Excluded from Payroll in the Total Hours</Form.Label>
                        
                      </div>
                      <h6>Enabling this setting will include the hours of Excluded from Payroll employees to the total hour calculation on your Schedule page.</h6>
                    </div>
                    <Form.Check
                        type="switch"
                        id="sw_isexclude"
                        // label={"Employees excluded from payroll are included in total hours"}
                        checked={this.state.contractEmployee}
                        disabled={!this.state.isManageSetting}
                        onChange={(event: any) => {
                          this.onChange(event, "ContractEmployee");
                        }}
                      />
                  </Form>
                </div>

                <OutsideClickHandler
                  onOutsideClick={this.checkPerformanceToggle}
                >
                  <div className="controlls" id="performance-review">
                    <Form>
                      <div className="entity-row">
                        <div className="label-column">
                          <div className="d-flex">
                            <Form.Label className="entity-label">Performance Review</Form.Label>
                           
                          </div>
                          <h6>Enabling this setting will automatically create a performance review for all employees at each hotel.</h6>  
                        </div>
                        <Form.Check
                            type="switch"
                            id="performanceReviewtoggle"
                            // label={"Performance Review"}
                            checked={this.state.performanceReviewToggle}
                            disabled={!this.state.isManageSetting}
                            onChange={(event: any) => {
                              this.onChange(
                                Utils.BuildCustomEventObject(
                                  "PerformanceReview",
                                  event.target.checked
                                ),
                                "PerformanceReview"
                              );
                            }}
                          />
                      </div>
                      <>
                        {this.state.performanceReviewToggle ? (
                          <Form.Group
                            className="custom-input d-flex align-items-center"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="p-0 m-0">Reviews happen on</Form.Label>
                            <DropDownList
                              placeHolder={"Select"}
                              shouldToggle={this.state.performanceReviewToggle}
                              data={performanceReviewOptions}
                              defaultValue={this.state.performanceReviewType}
                              isSearchRequired={false}
                              label={"label"}
                              value={"value"}
                              onDropDownChange={(event: any) => {
                                this.onChange(event, "PerformanceReviewType");
                              }}
                              selectedItem={[
                                ...[{ value: "0", label: "Select" }],
                                ...performanceReviewOptions,
                              ].find(
                                (r: any) =>
                                  r?.value === this.state.performanceReviewType
                              )} 
                            />
                            <>
                              {this.state.performanceReviewType == "2" && (
                                <div className="validate-input d-flex flex-column">
                                  <div className="calender-container">
                                    <div
                                      className={
                                        this.state.calenderShow
                                          ? "calender-display open"
                                          : "calender-display"
                                      }
                                    >
                                      <input
                                        type="text"
                                        onClick={this.calenderShow}
                                        placeholder="MM/DD"
                                        maxLength={5}
                                        onInput={(e) => {
                                          let value = e.currentTarget.value;
                                          value = value
                                            ?.toString()
                                            ?.replace(/[^0-9\/]+/gi, "");
                                          this.onChange(
                                            value,
                                            "ReviewDate",
                                            false,
                                            true
                                          );
                                        }}
                                        onBlur={(e) => {
                                          let value = e.currentTarget.value;
                                          value = value
                                            ?.toString()
                                            ?.replace(/[^0-9\/]+/gi, "");
                                          this.onChange(
                                            value,
                                            "ReviewDate",
                                            false
                                          );
                                        }}
                                        value={this.state.ReviewDate}
                                        className="form-control single-date-pick"
                                        name="ReviewDate"
                                        autoComplete="ReviewDate"
                                      />
                                      <svg
                                        onClick={() => this.calenderShow()}
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                                        <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                                      </svg>
                                    </div>
                                    {this.state.calenderShow && (
                                      <div className="calender">
                                        <OutsideClickHandler
                                          onOutsideClick={this.calenderHide}
                                        >
                                          <Calendar
                                            calendarType={"US"}
                                            onChange={(date) => {
                                              const value =
                                                moment(date).format("MM/DD");

                                              this.onChange(
                                                value,
                                                "ReviewDate",
                                                true,
                                                true
                                              );
                                              this.calenderHide();
                                            }}
                                            value={
                                              this.state.ReviewDate
                                                ? moment(
                                                    this.state.ReviewDate
                                                  )?.format("MM/DD") ===
                                                  "Invalid date"
                                                  ? new Date()
                                                  : new Date(
                                                      this.state.ReviewDate
                                                    )
                                                : new Date()
                                            }
                                          />
                                        </OutsideClickHandler>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          </Form.Group>
                        ) : (
                          ""
                        )}
                      </>
                    </Form>
                  </div>
                  <div className="controlls">
                  <Form>
                    <div className="entity-row">
                      <Form.Group
                        className="custom-input label-column pt-0"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="entity-label">Show Excessive Variance in Red on Labor Reports</Form.Label>
                        <h6>Enabling this setting will display excessive variances on the Labor Flash, Labor Forecast, and Labor Budget reports above the percentage threshold entered below.</h6>
                      </Form.Group>
                      <Form.Check
                        type="switch"
                        id="sw_variance"
                        // label="Show excessive variance in red"
                        checked={this.state.isVariance}
                        disabled={!this.state.isManageSetting}
                        onChange={(event: any) => {
                          this.onChange(event, "isVariance");
                        }}
                      />
                    </div>
                    {this.state.isVariance ?
                        (<div className={`text-box mt-3`}>
                          <Form.Label className="d-flex align-items-center mb-0">Variances in excess of
                          <div className={`text-input mx-2 ${this.state.dataValidVariance} ${document.activeElement == this.autoFocusVariance.current ? "green-border" : ""}`}>
                            <Form.Control
                              ref={this.autoFocusVariance}
                              type="text"
                              id="txtvariance"
                              value={this.state.variance}
                              pattern="[0-9.-]*"
                              maxLength={10}
                              onChange={(e: any) =>
                                this.setState({
                                  variance: e.target.validity.valid
                                    ? e.target.value
                                    : this.state.variance,
                                  isDataChange: true,
                                })
                              }
                              onBlur={(event: any) => {
                                this.onChange(event, "Variance");
                                this.setState({ onFocus : false });
                              }}
                              disabled={
                                this.state.isManageSetting
                                  ? !this.state.isVariance
                                  : !this.state.isManageSetting
                              }
                              // onFocus={(event: { target: { select: () => void } }) =>
                              //   event.target.select()
                              // }
                              onFocus={(event) => {                              
                                event.target.select();
                                this.setState({ onFocus : true });
                              }}
                              autoComplete="off"
                            />
                            % 
                          </div> show in Red.
                          </Form.Label>
                        </div>) :
                      <></>
                    }
                  </Form>
                </div>
                </OutsideClickHandler>

                <div className="controlls">
                  <Form>
                    <div className="entity-row">
                      <Form.Group
                        className="custom-input label-column pt-0"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="entity-label">Variance Highlight Threshold on Performance Page</Form.Label>
                        <h6>This setting will highlight variances on the Performance Page above the threshold provided below.</h6>
                      </Form.Group>
                      {/* <Form.Check
                        type="switch"
                        id="varianceThreshold"
                        checked={this.state.isVarianceThreshold}
                        // disabled={!this.state.isManageSetting}
                        // onChange={(event: any) => {
                        //   this.onChange(event, "isVarianceThreshold");
                        // }}
                      /> */}
                    </div>
                    {
                      this.state.isVarianceThreshold ? (
                        <Form.Group
                            className="custom-input pt-3 text-box"
                            controlId="formBasicEmail"
                          >
                          <Form.Label className="p-0 mb-0 d-flex align-items-center">
                            Highlight variance above
                            <div className={`text-input mx-2 ${this.state.dataValidActualVariance} ${document.activeElement == this.autoFocusActualVariance.current ? "green-border" : ""}`}>
                              <Form.Control
                                ref={this.autoFocusActualVariance} 
                                type="text"
                                id="txtactualvariance"
                                pattern="[0-9]*"
                                maxLength={3}
                                value={this.state.actulvariance}
                                disabled={!this.state.isManageSetting}
                                onChange={(e) => this.formChange(e, "ActualVariance")}
                                onBlur={(event: any) => {
                                  this.onChange(event, "ActualVariance");
                                  this.setState({ onFocus: false })
                                }}
                                // onFocus={(event: { target: { select: () => void } }) =>
                                //   event.target.select()
                                // }
                                onFocus={(event) => {
                                  event.target.select();
                                  this.setState({ onFocus : true });
                                }}
                              />
                                %
                            </div>on Performance Page
                            </Form.Label>
                        </Form.Group>
                        ) :
                        <></>
                    }
                  </Form>
                </div>

                
                <div className="controlls holiday-attribution">
                  <div className="entity-row">
                    <div className="label-column">
                      <label className="entity-label">Holiday Shift Attribution</label>
                      <h6>Holiday Shifts that span 2 days should be attributed to the</h6>
                      {/* <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id={"tooltip-displayName"}>
                          Holiday Shift Attribution
                          </Tooltip>
                      }
                    >
                      <span style={{ marginLeft: 5, marginTop: 10 }}>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          version="1.1"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                          <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                          <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                        </svg>
                      </span>
                    </OverlayTrigger> */}
                    </div>
                    <Form>
                      <div className="d-flex align-items-center">
                        <Dropdown
                          className="slt-frequency holidayAttribution"
                          onSelect={handleAttributionChange}
                        >
                          <Dropdown.Toggle
                            id="dropdown-holidayAttribution"
                            //disabled={!this.state.isManageSetting}
                            // disabled={true}
                            // className={
                            //   this.state.selectedFrequency ===
                            //     "Select Frequency"
                            //     ? "placeholder"
                            //     : ""
                            // }
                          >
                            {this.state.selectedHolidayAttribution}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {this.state.holidaysAttribution.map(
                              (item: any, idx: any) => {
                                if (
                                  item !== this.state.selectedHolidayAttribution
                                ) {
                                  return (
                                    <Dropdown.Item key={idx} eventKey={item}>
                                      {item}
                                    </Dropdown.Item>
                                  );
                                }
                              }
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Form>
                  </div>
                </div>

               
                <div className="controlls">
                  <div className="entity-row">
                      <div className="label-column">
                      <div className="controlls-ttl mb-2">Payroll Administration</div>
                      <h6>Please select the users within your organization who will need to view and manage payroll.</h6>
                      </div>
                      <div className="typeahead">
                        <Form>
                          <Typeahead
                            flip={true}
                            id="PayrollUsers"
                            multiple={true}
                            onChange={(event) => {
                              this.typeheadChange(event);
                            }}
                            disabled={!this.state.isManageSetting}
                            options={this.state.options}
                            placeholder="Choose..."
                            selected={this.state.selected}
                          />
                        </Form>
                      </div>
                  </div> 
                </div>
                {this.state.OTARAlertFF === "Yes" && 
               ( <div className="controlls">
                    <Form className="entity-row">
                      <div className="label-column">
                        <Form.Label className="entity-label">Overtime At Risk (OTAR) Alert Limit</Form.Label>
                        <h6>Enabling this setting will limit OTAR alerts for users to the limit selected below: (this will apply to all hotels).</h6>
                      </div>
                      <Form.Check
                        type="switch"
                        id="OTAR"
                        checked={this.state.isOTARAlert}
                        disabled={!this.state.isManageSetting}
                        onChange={(event: any) => {
                          this.onChange(event, "isOTARAlert");
                        }}
                      />
                    </Form>
                    {this.state.isOTARAlert ? (
                    <Form.Group
                          className="custom-input pt-3 text-box"
                          controlId="formBasicEmail"
                        >
                        <Form.Label className="p-0 mb-0 d-flex align-items-center">
                        OTAR Alerts: Provide an OTAR alert if a user’s OTAR is over:
                          <div className={`text-input mx-2 ${document.activeElement == this.autoFocusOTARAlert.current ? "green-border" : ""}`}>
                          <Form.Control
                                ref={this.autoFocusOTARAlert}
                                className={this.state.dataValidOTARAlert}
                                type="text"
                                id="OTARAlert"
                                pattern="[0-9]*"
                                maxLength={3}
                                value={this.state.OTARAlert}
                                onChange={(e: any) =>
                                  this.setState({
                                    OTARAlert: e.target.validity.valid
                                      ? e.target.value
                                      : this.state.OTARAlert,
                                    isDataChange: true,
                                  })
                                }
                                onBlur={(event: any) => {
                                  this.onChange(event, "OTARAlert");
                                  this.setState({onFocus : false})
                                }}
                                disabled={
                                  this.state.isManageSetting
                                    ? !this.state.isOTARAlert
                                    : !this.state.isManageSetting
                                }
                                // onFocus={(event: { target: { select: () => void } }) =>
                                //   event.target.select()
                                // }
                                onFocus={(event) => {
                                  event.target.select();
                                  this.setState({ onFocus : true });
                              }}
                              />
                          </div>minutes
                          </Form.Label>
                      </Form.Group>)
                      : (<></>) }
                </div>) }

                {this.state.GMApproveFF === "Yes" && 
                (<div className="controlls">
                    <Form className="entity-row">
                      <div className="label-column">
                        <Form.Label className="entity-label">Daily Approval Settings: Department Approval Explanation Limit</Form.Label>
                        <h6>Enabling this setting will remove over-budget approval explanations that are required for Department Approvals as part of the Daily GM Check-In or the Daily Labor Approval workflows: (this will apply to all hotels)</h6>
                      </div>
                      <Form.Check
                        type="switch"
                        id="daily-approval-department-setting"
                        checked={this.state.isDailyDepartmentApproval}
                        disabled={!this.state.isManageSetting}
                        onChange={(event: any) => {
                          this.onChange(event, "isDailyDepartmentApproval");
                        }}
                      />
                    </Form>
                    {this.state.isDailyDepartmentApproval ? (
                    <Form.Group
                          className="custom-input pt-3 text-box"
                          controlId="formBasicEmail"
                        >
                        <Form.Label className="p-0 mb-0 d-flex align-items-center">
                        Department Approvals: Explanation required if total hours are overbudget by:
                          <div className={`text-input mx-2 ${document.activeElement == this.autoFocusDailyDepartmentApproval.current ? "green-border" : ""}`}>
                          <Form.Control
                                ref={this.autoFocusDailyDepartmentApproval}
                                className={this.state.dataValidDailyDepartmentApproval}
                                type="text"
                                id="DepartmentApproval"
                                pattern="[0-9]*"
                                maxLength={4}
                                value={this.state.DailyDepartmentApproval}
                                onChange={(e: any) =>
                                  this.setState({
                                    DailyDepartmentApproval: e.target.validity.valid
                                      ? e.target.value
                                      : this.state.DailyDepartmentApproval,
                                    isDataChange: true,
                                  })
                                }
                                onBlur={(event: any) => {
                                  this.onChange(event, "DailyDepartmentApproval");
                                  this.setState({ onFocus : false });
                                }}
                                disabled={
                                  this.state.isManageSetting
                                    ? !this.state.isDailyDepartmentApproval
                                    : !this.state.isManageSetting
                                }
                                // onFocus={(event: { target: { select: () => void } }) =>
                                //   event.target.select()
                                // }
                                onFocus={(event) => {
                                  event.target.select();
                                  this.setState({ onFocus : true });
                                }}
                              />
                          </div>minutes
                          </Form.Label>
                      </Form.Group>)
                      : (<></>)}
                </div>)}
                {this.state.GMApproveFF === "Yes" && 
                (<div className="controlls">
                    <Form className="entity-row">
                      <div className="label-column">
                        <Form.Label className="entity-label">Daily Approval Settings: GM Approval Explanation Limit</Form.Label>
                        <h6>Enabling this setting will remove over-budget approval explanations that are required to complete the Daily GM Check-In: (this will apply to all hotels)</h6>
                      </div>
                      <Form.Check
                        type="switch"
                        id="daily-approval-setting"
                        checked={this.state.isDailyGMApproval}
                        disabled={!this.state.isManageSetting}
                        onChange={(event: any) => {
                          this.onChange(event, "isDailyGMApproval");
                        }}
                      />
                    </Form>
                    {this.state.isDailyGMApproval ? (
                    <Form.Group
                          className="custom-input pt-3 text-box"
                          controlId="formBasicEmail"
                        >
                        <Form.Label className="p-0 mb-0 d-flex align-items-center">
                        GM Check-In Submission Page: Explanation required if total hours are overbudget by:
                          <div className={`text-input mx-2 ${document.activeElement == this.autoFocusDailyGMApproval.current ? "green-border" : ""}`}>
                          <Form.Control
                                ref={this.autoFocusDailyGMApproval}
                                className={this.state.dataValidDailyGMApproval}
                                type="text"
                                id="DailyGMApproval"
                                pattern="[0-9]*"
                                maxLength={4}
                                value={this.state.DailyGMApproval}
                                onChange={(e: any) =>
                                  this.setState({
                                    DailyGMApproval: e.target.validity.valid
                                      ? e.target.value
                                      : this.state.DailyGMApproval,
                                    isDataChange: true,
                                  })
                                }
                                onBlur={(event: any) => {
                                  this.onChange(event, "DailyGMApproval");
                                  this.setState({ onFocus : false });
                                }}
                                disabled={
                                  this.state.isManageSetting
                                    ? !this.state.isDailyGMApproval
                                    : !this.state.isManageSetting
                                }
                                // onFocus={(event: { target: { select: () => void } }) =>
                                //   event.target.select()
                                // }
                                onFocus={(event) => {
                                  event.target.select();
                                  this.setState({ onFocus : true });
                                }}
                              />
                          </div>minutes
                          </Form.Label>
                      </Form.Group>)
                      : (<></>)}
                </div>)}
               {this.state.HousekeepingFF === "Yes" && 
              ( <div className="controlls">
                  <Form className="entity-row">
                   <div className="label-column">
                        <Form.Label className="entity-label">Housekeeping Approvals: Overage Approval Explanation Limit</Form.Label>
                        <h6>Enabling this setting will remove approval explanations required for overbudget Housekeeping shifts up to the limit selected below:</h6>
                    </div>
                    <Form.Check
                      type="switch"
                      id="sw_Housekeeping_Approvals"
                
                      checked={this.state.isHouseKeepingExplainationlimit}
                      disabled={!this.state.isManageSetting}
                      onChange={(event: any) => {
                        this.onChange(event, "isHouseKeepingExplainationlimit");
                      }}
                    />
                  </Form>
                   {this.state.isHouseKeepingExplainationlimit == true ?
                   <Form.Group
                      className="custom-input  pt-3 text-box"
                      controlId="formBasicEmail"
                    >
                        <Form.Label className="p-0 mb-0 d-flex align-items-center">
                          Housekeeping Approvals: Explanation required if housekeeping shift is overbudgeted by:
                        <div className={`text-input mx-2 ${document.activeElement == this.autoFocusHouseKeepingExplaination.current ? "green-border" : ""}`}>
                          <Form.Control
                            ref={this.autoFocusHouseKeepingExplaination}
                            className={this.state.dataValidHouseKeepingExplaination}
                            type="text"
                            id="txtHousekeepingApprovals"
                            pattern="[0-9]*"
                            maxLength={3}
                            value={this.state.HouseKeepingExplaination}
                            onChange={(e: any) =>
                              this.setState({
                                HouseKeepingExplaination: e.target.validity.valid
                                  ? e.target.value
                                  : this.state.HouseKeepingExplaination,
                                isDataChange: true,
                              })
                            }
                            onBlur={(event: any) => {
                              this.onChange(event, "HouseKeepingExplaination");
                              this.setState({ onFocus : false })
                            }}
                            disabled={
                              this.state.isManageSetting
                                ? !this.state.isHouseKeepingExplainationlimit
                                : !this.state.isManageSetting
                            }
                            // onFocus={(event: { target: { select: () => void } }) =>
                            //   event.target.select()
                            // }
                            onFocus={(event) => {
                              event.target.select();
                              this.setState({ onFocus : true });
                            }}
                          /> 
                        </div>
                     minutes</Form.Label>
                    </Form.Group> : <></>}
                  
               
                </div>)}
              </div>

              {/* <div className="section">
                <div className="sec-ttl">
                  Shift Settings
                  <div className="sub-ttl">
                    Shift and time clock configuration
                  </div>
                </div>
                <div className="controlls">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="sw_autopunchin"
                      label="Allow early punch-in"
                      checked={this.state.isPunchingIn}
                      disabled={!this.state.isManageSetting}
                      onChange={(event: any) => {
                        this.onChange(event, "isPunchingIn");
                      }}
                    />
                    <Form.Group
                      className="custom-input mdy-pad"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        ref={this.autoFocusPunchingIn}
                        className={this.state.dataValidPunchingIn}
                        type="text"
                        id="txtautopunchin"
                        pattern="[0-9]*"
                        maxLength={3}
                        value={this.state.punchingIn}
                        onChange={(e: any) =>
                          this.setState({
                            punchingIn: e.target.validity.valid
                              ? e.target.value
                              : this.state.punchingIn,
                            isDataChange: true,
                          })
                        }
                        onBlur={(event: any) => {
                          this.onChange(event, "PunchingIn");
                        }}
                        disabled={
                          this.state.isManageSetting
                            ? !this.state.isPunchingIn
                            : !this.state.isManageSetting
                        }
                        onFocus={(event: { target: { select: () => void } }) =>
                          event.target.select()
                        }
                      />
                      <Form.Label>minutes before shift starts</Form.Label>
                    </Form.Group>
                  </Form>
                </div>
                <div className="controlls">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="sw_Housekeeping_Approvals"
                      label="Housekeeping Approvals: Overage Approval Explanation Limit"
                      checked={this.state.isHouseKeepingExplainationlimit}
                      disabled={!this.state.isManageSetting}
                      onChange={(event: any) => {
                        this.onChange(event, "isHouseKeepingExplainationlimit");
                      }}
                    />
                    <span>Enabling this setting will remove approval explanations required for overbudget Housekeeping shifts up to the limit selected below: (this will apply to all hotels)</span>
                    <Form.Group
                      className="custom-input mdy-pad"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        ref={this.autoFocusHouseKeepingExplaination}
                        className={this.state.dataValidHouseKeepingExplaination}
                        type="text"
                        id="txtHousekeepingApprovals"
                        pattern="[0-9]*"
                        maxLength={3}
                        value={this.state.HouseKeepingExplaination}
                        onChange={(e: any) =>
                          this.setState({
                            HouseKeepingExplaination: e.target.validity.valid
                              ? e.target.value
                              : this.state.HouseKeepingExplaination,
                            isDataChange: true,
                          })
                        }
                        onBlur={(event: any) => {
                          this.onChange(event, "HouseKeepingExplaination");
                        }}
                        disabled={
                          this.state.isManageSetting
                            ? !this.state.isHouseKeepingExplainationlimit
                            : !this.state.isManageSetting
                        }
                        onFocus={(event: { target: { select: () => void } }) =>
                          event.target.select()
                        }
                      />
                      <Form.Label>minutes</Form.Label>
                    </Form.Group>
                  </Form>
                </div>
                <div className="controlls">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="sw_autopunchout"
                      label="Auto punch-out when forgotten"
                      checked={this.state.isAutoPunchOut}
                      disabled={!this.state.isManageSetting}
                      onChange={(event: any) => {
                        this.onChange(event, "isAutoPunchOut");
                      }}
                    />
                    <Form.Group
                      className="custom-input mdy-pad"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        className={this.state.dataValidAutoPunchOut}
                        ref={this.autoFocusPunchOut}
                        type="text"
                        id="txtautopunchout"
                        value={this.state.autoPunchOut}
                        pattern="[0-9]*"
                        maxLength={2}
                        onChange={(e: any) =>
                          this.setState({
                            autoPunchOut: e.target.validity.valid
                              ? e.target.value
                              : this.state.autoPunchOut,
                            isDataChange: true,
                          })
                        }
                        onBlur={(event: any) => {
                          this.onChange(event, "AutoPunchOut");
                        }}
                        disabled={
                          this.state.isManageSetting
                            ? !this.state.isAutoPunchOut
                            : !this.state.isManageSetting
                        }
                        onFocus={(event: { target: { select: () => void } }) =>
                          event.target.select()
                        }
                        autoComplete="off"
                      />
                      <Form.Label>hours after shift ends</Form.Label>
                    </Form.Group>
                  </Form>
                </div>
                <div className="controlls">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="sw_variance"
                      label="Show excessive variance in red"
                      checked={this.state.isVariance}
                      disabled={!this.state.isManageSetting}
                      onChange={(event: any) => {
                        this.onChange(event, "isVariance");
                      }}
                    />
                    <Form.Group
                      className="custom-input mdy-pad"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        className={this.state.dataValidVariance}
                        ref={this.autoFocusVariance}
                        type="text"
                        id="txtvariance"
                        value={this.state.variance}
                        pattern="[0-9.-]*"
                        maxLength={10}
                        onChange={(e: any) =>
                          this.setState({
                            variance: e.target.validity.valid
                              ? e.target.value
                              : this.state.variance,
                            isDataChange: true,
                          })
                        }
                        onBlur={(event: any) => {
                          this.onChange(event, "Variance");
                        }}
                        disabled={
                          this.state.isManageSetting
                            ? !this.state.isVariance
                            : !this.state.isManageSetting
                        }
                        onFocus={(event: { target: { select: () => void } }) =>
                          event.target.select()
                        }
                        autoComplete="off"
                      />
                      <Form.Label>% considered excessive</Form.Label>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id={"tooltip-displayName"}>
                            This setting will affect the Performance page and
                            the Labor Summary Report. Changing this setting will
                            update the reports that are scheduled.
                          </Tooltip>
                        }
                      >
                        <span style={{ marginLeft: 5, marginTop: 10 }}>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            version="1.1"
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                            <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                            <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                          </svg>
                        </span>
                      </OverlayTrigger>
                    </Form.Group>
                  </Form>
                </div>
                <div className="controlls">
                  <div className="d-flex">
                    <Form>
                      <div className="d-flex">
                        <Form.Check
                          type="switch"
                          id="autobreak"
                          label="Automatic break reduction"
                          checked={this.state.isBreakTimeSubracted}
                          disabled={!this.state.isManageSetting}
                          onChange={(event: any) => {
                            this.onChange(event, "isBreakTimeSubracted");
                          }}
                        />
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id={"tooltip-displayName"}>
                              Automatic Break Reduction hours will only be
                              applied to scheduled hours and punch out is still
                              required.
                            </Tooltip>
                          }
                        >
                          <span style={{ marginLeft: 5, marginTop: 10 }}>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              version="1.1"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                              <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                              <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                            </svg>
                          </span>
                        </OverlayTrigger>
                      </div>
                      <Form.Group
                        className="custom-input mdy-pad"
                        controlId="formBasicEmail"
                      >
                        <div className="inline-row">
                          <div className="controlls-ttl">Break length</div>
                          <Form.Control
                            className={this.state.dataValidBreakTime}
                            ref={this.autoFocusBreakTime}
                            type="text"
                            id="btime"
                            value={this.state.breakTime}
                            pattern="[0-9]*"
                            maxLength={2}
                            onChange={(e: any) =>
                              this.setState({
                                breakTime: e.target.validity.valid
                                  ? e.target.value
                                  : this.state.breakTime,
                                isDataChange: true,
                              })
                            }
                            onBlur={(event: any) => {
                              this.onChange(event, "BreakTime");
                            }}
                            disabled={isDisabled}
                            onFocus={(event: {
                              target: { select: () => void };
                            }) => event.target.select()}
                            autoComplete="off"
                          />
                          <Form.Label>minutes</Form.Label>
                        </div>
                        <div className="inline-row">
                          <div className="controlls-ttl">
                            Apply to shifts over
                          </div>
                          <Form.Control
                            className={this.state.dataValidExceedBreakTime}
                            ref={this.autoFocusExceedBreakTime}
                            type="text"
                            id="ebtime"
                            maxLength={2}
                            value={this.state.exceedBreakTime}
                            pattern="[0-9]*"
                            onChange={(e: any) =>
                              this.setState({
                                exceedBreakTime: e.target.validity.valid
                                  ? e.target.value
                                  : this.state.exceedBreakTime,
                                isDataChange: true,
                              })
                            }
                            onBlur={(event: any) => {
                              this.onChange(event, "ExceedBreakTime");
                            }}
                            disabled={isDisabled}
                            onFocus={(event: {
                              target: { select: () => void };
                            }) => event.target.select()}
                            autoComplete="off"
                          />
                          <Form.Label>hours</Form.Label>
                        </div>
                      </Form.Group>
                    </Form>
                    <Form>
                      <div className="d-flex">
                        <Form.Check
                          type="switch"
                          id="autoTrueUp"
                          label="Auto True-Up"
                          checked={this.state.isAutoTrueUp}
                          disabled={!this.state.isBreakTimeSubracted}
                          onChange={(event: any) => {
                            this.onChange(event, "isAutoTrueUp");
                          }}
                        />
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id={"tooltip-displayName"}>
                              Auto True up for break.
                            </Tooltip>
                          }
                        >
                          <span style={{ marginLeft: 5, marginTop: 10 }}>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              version="1.1"
                              viewBox="0 0 16 16"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                              <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                              <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                            </svg>
                          </span>
                        </OverlayTrigger>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="controlls">
                  <Form>
                    <div className="d-flex">
                      <Form.Check
                        type="switch"
                        id="mporBtn"
                        label="Optimize MPCR Shifts"
                        checked={this.state.isMPOREnabled}
                        disabled={!this.state.isManageSetting}
                        onChange={(event: any) => {
                          this.onChange(event, "OptimizedMPOR");
                        }}
                      />
                    </div>
                  </Form>
                </div>
              </div> */}

             
            </Tab>
              <Tab eventKey="shift" title="Shift">
                  <div className="section shift-content">
                {/* <div className="sec-ttl">
                  Shift Settings
                  <div className="sub-ttl">
                    Shift and time clock configuration
                  </div>
                </div> */}
                <div className="controlls">
                  <Form>
                    <div className="entity-row d-flex">
                      <Form.Group
                        className="custom-input label-column"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="entity-label">Allow Early Punch-In <span>(Recommended)</span></Form.Label>
                        <h6>Enabling this setting will limit how early an employee can punch in using the timeclock.</h6>
                      </Form.Group>
                      <Form.Check
                        type="switch"
                        id="sw_autopunchin"
                        // label="Allow early punch-in"
                        checked={this.state.isPunchingIn}
                        disabled={!this.state.isManageSetting}
                        onChange={(event: any) => {
                          this.onChange(event, "isPunchingIn");
                        }}
                      />
                    </div>
                    {
                      this.state.isPunchingIn ? (
                        <div className={`text-box mt-3`}>
                          <div className={`text-input ${this.state.dataValidPunchingIn} ${document.activeElement == this.autoFocusPunchingIn.current ? "green-border" : ""}`}>
                            <Form.Control
                                ref={this.autoFocusPunchingIn}
                                type="text"
                                id="txtautopunchin"
                                pattern="[0-9]*"
                                maxLength={3}
                                value={this.state.punchingIn}
                                onChange={(e: any) =>
                                  this.setState({
                                    punchingIn: e.target.validity.valid
                                      ? e.target.value
                                      : this.state.punchingIn,
                                    isDataChange: true,
                                  })
                                }
                                onBlur={(event: any) => {
                                  this.onChange(event, "PunchingIn");
                                  this.setState({ onFocus: false })
                                }}
                                disabled={
                                  this.state.isManageSetting
                                    ? !this.state.isPunchingIn
                                    : !this.state.isManageSetting
                                }
                                // onFocus={(event: { target: { select: () => void } }) =>
                                //   event.target.select()
                                // }
                                onFocus={(event) => {
                                  event.target.select();
                                  this.setState({ onFocus : true });
                                }}
                              />
                              <Form.Label>minutes before shift starts</Form.Label>
                          </div>
                        </div>
                      ) : (<></>)
                    }
                  </Form>
                </div>
                <div className="controlls">
                  <Form>
                    <div className="entity-row">
                      <Form.Group
                        className="custom-input label-column"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="entity-label">Auto Punch-Out when Forgotten <span>(Recommended)</span></Form.Label>
                        {/* <h6>Enabling this setting will display excessive variances on the Labor Summary Reports above the percentage threshold entered below.</h6> */}
                         <h6>Enabling this setting will automatically punch out employees after a set amount of time from their projected shift end time.</h6>
                      </Form.Group>
                      <Form.Check
                        type="switch"
                        id="sw_autopunchout"
                        // label="Auto punch-out when forgotten"
                        checked={this.state.isAutoPunchOut}
                        disabled={!this.state.isManageSetting}
                        onChange={(event: any) => {
                          this.onChange(event, "isAutoPunchOut");
                        }}
                      />
                    </div>
                    {this.state.isAutoPunchOut ?
                      (<div className={`text-box mt-3`}>
                          <div className={`text-input ${this.state.dataValidAutoPunchOut} ${document.activeElement == this.autoFocusPunchOut.current ? "green-border" : ""}`}>
                            <Form.Control
                                ref={this.autoFocusPunchOut}
                                type="text"
                                id="txtautopunchout"
                                value={this.state.autoPunchOut}
                                pattern="[0-9]*"
                                maxLength={2}
                                onChange={(e: any) =>
                                  this.setState({
                                    autoPunchOut: e.target.validity.valid
                                      ? e.target.value
                                      : this.state.autoPunchOut,
                                    isDataChange: true,
                                  })
                                }
                                onBlur={(event: any) => {
                                  this.onChange(event, "AutoPunchOut");
                                  this.setState({ onFocus: false })
                                }}
                                disabled={
                                  this.state.isManageSetting
                                    ? !this.state.isAutoPunchOut
                                    : !this.state.isManageSetting
                                }
                                // onFocus={(event: { target: { select: () => void } }) =>
                                //   event.target.select()
                                // }
                                onFocus={(event) => {
                                  event.target.select();
                                  this.setState({ onFocus : true });
                                }}
                                autoComplete="off"
                              />
                              <Form.Label>hours after shift ends</Form.Label>
                          </div>
                      </div>) :
                      <></>
                    }
                  </Form>
                </div>
                <div className="controlls">
                  <Form>
                    <div className="entity-row">
                      <div className="label-column">
                        <Form.Label className="entity-label">Optimize MPCR Shifts <span>(Recommended)</span></Form.Label>
                        <h6>Enabling this setting will automatically optimize your Housekeeping shifts when finalizing Room Assignments and alert affected employees of shift changes.</h6>
                      </div>
                      <Form.Check
                          type="switch"
                          id="mporBtn"
                          // label="Optimize MPCR Shifts"
                          checked={this.state.isMPOREnabled}
                          disabled={!this.state.isManageSetting}
                          onChange={(event: any) => {
                            this.onChange(event, "OptimizedMPOR");
                          }}
                        />
                    </div>
                  </Form>
                </div> 
                <div className="controlls automatic-break-reduction">
                  <Form>
                    <div className="entity-row">
                      <div className="label-column">
                        <div className="d-flex">
                          <Form.Label className="entity-label">Automatic Break Reduction</Form.Label>                          
                        </div>
                        <h6>Enabling this setting will automatically add breaks to your employee's <b>scheduled</b> shifts. Punch out will still be required.</h6>
                      </div>
                      <Form.Check
                        type="switch"
                        id="autobreak"
                        // label="Automatic break reduction"
                        checked={this.state.isBreakTimeSubracted}
                        disabled={!this.state.isManageSetting}
                        onChange={(event: any) => {
                          this.onChange(event, "isBreakTimeSubracted");
                        }}
                      />
                    </div>
                    {this.state.isBreakTimeSubracted ?
                        (<div className={`text-box`}>
                          <div>
                          <Form.Group
                            className="custom-input"
                            controlId="formBasicEmail"
                          >
                            <div className="inline-row">
                              <div className="controlls-ttl">Break length</div>
                              <div className={`text-input ${this.state.dataValidBreakTime} ${document.activeElement == this.autoFocusBreakTime.current ? "green-border" : ""}`}>
                                <Form.Control
                                  ref={this.autoFocusBreakTime}
                                  type="text"
                                  id="btime"
                                  value={this.state.breakTime}
                                  pattern="[0-9]*"
                                  maxLength={2}
                                  onChange={(e: any) =>
                                    this.setState({
                                      breakTime: e.target.validity.valid
                                        ? e.target.value
                                        : this.state.breakTime,
                                      isDataChange: true,
                                    })
                                  }
                                  onBlur={(event: any) => {
                                    this.onChange(event, "BreakTime");
                                    this.setState({ onFocus: false, applyToshiftFocus: true })
                                  }}
                                  disabled={isDisabled}
                                  // onFocus={(event: {
                                  //   target: { select: () => void };
                                  // }) => event.target.select()}
                                  onFocus={(event) => {
                                    event.target.select();
                                    this.setState({ onFocus : true });
                                  }}
                                  autoComplete="off"
                                />
                                <Form.Label>minutes</Form.Label>
                              </div>
                            </div>
                            <div className="inline-row">
                              <div className="controlls-ttl">
                                Apply to shifts over
                              </div>
                              <div className={`text-input ${this.state.dataValidExceedBreakTime} ${this.state.applyToshiftFocus ? "green-border" : ""}`}>
                                <Form.Control
                                  ref={this.autoFocusExceedBreakTime}
                                  type="text"
                                  id="ebtime"
                                  maxLength={2}
                                  value={this.state.exceedBreakTime}
                                  pattern="[0-9]*"
                                  onChange={(e: any) =>
                                    this.setState({
                                      exceedBreakTime: e.target.validity.valid
                                        ? e.target.value
                                        : this.state.exceedBreakTime,
                                      isDataChange: true,
                                    })
                                  }
                                  onBlur={(event: any) => {
                                    this.onChange(event, "ExceedBreakTime");
                                    this.setState({ applyToshiftFocus : false });
                                  }}
                                  disabled={isDisabled}
                                  // onFocus={(event: {
                                  //   target: { select: () => void };
                                  // }) => event.target.select()}
                                  onFocus={(event) => {
                                    event.target.select(); 
                                  }}
                                  autoComplete="off"
                                />
                                <Form.Label>hours</Form.Label>
                              </div>
                            </div>
                          </Form.Group> 
                            <div className="col-lg-6 p-0">
                              <div className="d-flex label-column align-items-center mb-3">
                              <Form.Check
                                type="switch"
                                id="autoTrueUp"
                                label="Auto True Up"
                                checked={this.state.isAutoTrueUp}
                                disabled={!this.state.isBreakTimeSubracted}
                                onChange={(event: any) => {
                                  this.onChange(event, "isAutoTrueUp");
                                }}
                              />
                                {/* <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={ */}
                                    {/* <Tooltip id={"tooltip-displayName"}>
                                      Auto True up for break.
                                    </Tooltip> */}
                                  {/* }
                                >
                                  <span style={{ marginLeft: 5, marginTop: 3 }}>
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      version="1.1"
                                      viewBox="0 0 16 16"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                                      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                                      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                                    </svg>
                                  </span>
                                </OverlayTrigger>  */}
                              </div> 
                              <h6>This setting will automatically reduce <b>actual</b> shift hours for breaks if an employee does not punch out for a break.</h6>
                            </div>
                          </div> 
                        </div>) :
                        <></>
                    }
                    </Form>
                </div> 
                
              </div>
              </Tab>

            {/* <Tab eventKey="positions" title="Positions">
                Positions
              </Tab> */}
          </Tabs>
        </Container>

        {this.state.showChangeLogModal && (
          <EnterpriseWiseLaborSettingsLogHistory
            payPeriodStartDate={""}
            payPeriodEndDate={""}
            accType={"All-J"}
            showChangeLogModal={this.state.showChangeLogModal}
            showHideChangeLogModal={this.showHideChangeLogModal}
            hotelName={""}
            hotelId={0}
            hideCrossAndClearFilters={false}
            shiftId={0}
            shiftDate={""}
            noOfDays={0}
            showPerviousLogBanner={false}
          />
        )}
      </div>
    );
  }
}

export default EnterpriseWiseLaborSettings;
