import React, { useRef } from "react";
import {
  Button,
  // Form,
  //  Col,
  Modal,
  Dropdown,
  //  Row
} from "react-bootstrap";
import { ReportRunnerModal } from "./ReportRunnerModal";
import { toast, ToastContainer } from "react-toastify";
import { ModuleReports } from "../../../Common/Services/ModuleReports";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment";
import { Utils } from "../../../Common/Utilis";
export const ReportRunnerNew: React.FC<any> = (props: any) => {
  // const modelBody = React.createRef();

  const leftPanel: any = useRef();
  let timeId;
  const [reportData, setReportData] = React.useState({} as any);
  const [runReport, setrunReport] = React.useState(false);
  const [isReportLoaded, setIsReportLoaded] = React.useState(false);
  const [allowScheduleReport, setallowScheduleReport] = React.useState(false);
  const [userReportName, setuserReportName] = React.useState("");
  const [showConfirmation, setshowConfirmation] = React.useState(false);
  const [runFrom, setrunFrom] = React.useState(props.runFrom);
  React.useEffect(() => {
    if (Object.keys(props?.reportData).length > 0) {
      setReportData(props.reportData);
      if (userReportName === "" && !+props.reportData.userReportId) {
        setuserReportName(props.reportData.reportName);
      }

      if (userReportName === "" && +props.reportData.userReportId > 0) {
        setuserReportName(props.reportData.userReportName);
      }
      setrunFrom(props.runFrom);
    }
  }, [props, props.reportData, props.runFrom, userReportName]);

  const downloadACACodeFiles = (): void => {
    window.location.href = "../files/ACAStatusCode.zip";
  };

  const showReport = (loadReport: boolean = true) => {
    if (loadReport) {
      setIsReportLoaded(false);
    }
    setrunReport(loadReport);
  };

  const hideModal = () => {
    props.hideModal(props.runFrom);
  };

  // const changeReportName = (e: any) => {
  //   setuserReportName(e.target.value);
  // };

  const exportReport = (exportType = "PDF") => {
    if (runReport) {
      debugger;
      let reportParameters = JSON.parse(
        localStorage.getItem("reportParameters")!
      );
      const ddlmultiObj = reportParameters.find(
        (param) => param.name === "ddlMultiTabls"
      );
      const ddlmultiValue = ddlmultiObj ? ddlmultiObj.value : null;
      if (props.reportData.uniqueReportId === 2 && ddlmultiValue === "0") {
        props.exportReport(userReportName, exportType);
      } else if (
        props.reportData.moduleReportId === 10 ||
        props.reportData.moduleReportId === 12 ||
        props.reportData.uniqueReportId === 2034 ||
        props.reportData.uniqueReportId === 2036 ||
        props.reportData.moduleReportId == 2149 ||
        props.reportData.uniqueReportId == 2149
      ) {
        props.exportReport(userReportName, exportType);
      } else if (
        props.reportData.reportName.toUpperCase() ===
        "POSITIVE PAY FILE".toUpperCase() &&
        exportType === "CSV"
      ) {
        props.exportReport(userReportName, exportType);
      } else {
        let reportviewerObj = $("#reportviewer-container").data(
          "boldReportViewer"
        );
        if (reportviewerObj) {
          reportviewerObj.exportReport(
            exportType === "XLSX" ? "EXCEL" : exportType
          ); //Exports the report into PDF format.
        }
      }
    } else {
      toast.success("Please run report", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "reportRunner",
      });
    }
  };

  const getScheduleReportPermissions = () => {
    let requestObject = {} as any;
    requestObject.permissionID = 2281;
    requestObject.Module = "All";
    requestObject.Type = "page";

    ModuleReports.GetPagePermissionData(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let editExist = result.filter(
            (x) => x.permissionName.toLowerCase() === "edit"
          );
          if (editExist && editExist.length > 0) {
            setallowScheduleReport(true);
          }
        }
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "reportGallerySelection",
        });
      });
  };

  const hideConfirmation = () => {
    setshowConfirmation(false);
  };

  const onscheduleReport = () => {
    ModuleReports.getAutomationReportByReportId(
      props.reportData.moduleReportId
    ).then((result: any) => {
      if (result != null) {
        setshowConfirmation(true);
      } else {
        saveScheduleReport("");
      }
    });
  };

  const openScheduleTab = (scheduleReportData, showrightpanel) => {
    props.openScheduleTab(scheduleReportData, showrightpanel);
  };

  const onReportload = () => {
    if (typeof leftPanel?.current?.enableReportRunner === "function") {
      leftPanel?.current?.enableReportRunner();
      setIsReportLoaded(true);

      getScheduleReportPermissions();
    }
  };

  const saveScheduleReport = async (copytext) => {
    const request: any = {};
    request.reportId = props.reportData.moduleReportId;
    request.ReportGroup = props.reportData.moduleReportId;
    request.description = copytext + " " + userReportName;
    request.version = props.reportData.reportVersion;
    request.reportName = props.reportData.reportName;
    request.parameters = localStorage.getItem("reportParameters");
    props.openScheduleTab(request, true);
  };
  const isRepId = (props?.reportData?.moduleReportId);
  const hideSchedule = (isRepId === 2164 || isRepId === 2166 || isRepId === 2167 || isRepId === 2168);

  return (
    <>
      <Modal
        show={props.showModal}
        keyboard={false}
        onHide={hideModal}
        className="reports-display-modal report-setting-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <EllipsisWithTooltip placement="bottom">{userReportName}</EllipsisWithTooltip>
          </Modal.Title>
          {/* <div className="action-group d-flex">
            {reportData.reportName?.includes("ACA") && (
              <Button
                className="btn-ghost"
                type="button"
                onClick={downloadACACodeFiles}
              >
                ACA Status Codes File
              </Button>
            )}
            <>
              {allowScheduleReport && reportData.allowEmail === true && !hideSchedule && (
                <Button onClick={onscheduleReport}>Schedule</Button>
              )}
              {runReport && showConfirmation && (
                <div className="publishChanges">
                  <OutsideClickHandler
                    onOutsideClick={() => hideConfirmation()}
                  >
                    <div className="publishDropdown">
                      <div className="publishBody">
                        <div className="publishHeading">
                          Confirm Scheduled Report
                        </div>
                        <div className="publishTxt">
                          You already have a scheduled report using this report
                          type: {userReportName} <br></br>
                          <br></br>
                          Are you sure you want to create another schedule
                          report with this type?
                        </div>
                      </div>
                      <div className="publishFooter action-link">
                        <button
                          className="publishCancel btn wht-bg"
                          onClick={() => hideConfirmation()}
                        >
                          Cancel
                        </button>
                        <button
                          className="publishSchedule btn btn-primary"
                          onClick={() => saveScheduleReport("Copy of")}
                        >
                          Add to Schedule Reports
                        </button>
                      </div>
                    </div>
                  </OutsideClickHandler>
                </div>
              )}
            </>
            {isReportLoaded && (
              <Dropdown className="more-action ">
                <Dropdown.Toggle id="dropdown-export">Export</Dropdown.Toggle>
                {props.reportData.reportName.toUpperCase() ===
                  "POSITIVE PAY FILE".toUpperCase() ? (
                  <>
                    <Dropdown.Menu alignRight>
                      <Dropdown.Item onClick={() => exportReport("CSV")}>
                        CSV
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </>
                ) : (
                  <>
                    <Dropdown.Menu alignRight>
                      <Dropdown.Item onClick={() => exportReport("PDF")}>
                        PDF
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => exportReport("XLSX")}>
                        Excel
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </>
                )}
              </Dropdown>
            )}
          </div> */}
        </Modal.Header>
        <Modal.Body>
          {/* <ToastContainer
            enableMultiContainer
            autoClose={3000}
            containerId={"reportRunner"}
          /> */}
          <ReportRunnerModal
            ref={leftPanel}
            // logReportLoadTiming={logReportLoadTiming}
            {...props}
            userReportName={userReportName}
            reportData={reportData}
            showReport={showReport}
            runFrom={runFrom}
            openScheduleTab={openScheduleTab}
            reloadMyReports={() => {
              props.reloadMyReports();
            }}
          />
          {/* {runReport && (
            <ReportRunnerRightPanel onReportload={onReportload} {...props} />
          )} */}
        </Modal.Body>
      </Modal>
    </>
  );
};
