import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Form,
  Row,
  Col,
  Dropdown,
  Tabs,
  Tab,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { LaborPerformance as laborPerformance } from "../../../../Common/Services/LaborPerfomance";
import { LaborDeptPosition as laborDeptPosition } from "../../../../Common/Services/LaborDeptPosition";
import { LaborTimeSheet } from "../../../../Common/Services/LaborTimeSheet";
import { toast } from "react-toastify";
import { resolve, reject } from "q";
import { confirmAlert } from "react-confirm-alert";
import RouteLeavingGuard from "../../../../Common/Components/RouteLeavingGuard";
import { LaborSmileID } from "../../../../Common/Services/LaborSmileId";
import profile from "../../../../Common/Assets/Images/profile.png";
import $ from "jquery";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { MyImage } from "../../../../Common/Components/MyImage";
import OutsideClickHandler from "react-outside-click-handler";
import { Utils } from "../../../../Common/Utilis";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import _ from "lodash";
import { InfoTooltip } from "../../../../Common/Components/InfoTooltip";
import { ConfirmationModal } from "../../../../Common/Components/ConfirmationModal";
import moment from "moment-timezone";
import { AnyARecord } from "dns";

let routeLeavingGuard: any = null;

export default class PerformanceSlideoutChild extends React.Component<
  any,
  any
> {
  private btnSaveNewEntry: any;
  private btnSaveBreak: any;
  private btnEditPunch: any;
  private btnEditBreak: any;
  private btnSplitShift: any;
  private btnChangePostion: any;
  private refAddBreak: any;
  private refIn: any;
  private refOut: any;
  
  constructor(props: any) {
    super(props);

    this.btnSaveNewEntry = React.createRef();
    this.btnSaveBreak = React.createRef();
    this.btnEditPunch = React.createRef();
    this.btnEditBreak = React.createRef();
    this.btnSplitShift = React.createRef();
    this.btnChangePostion = React.createRef();
    this.refAddBreak = React.createRef();
    this.refIn = React.createRef();
    this.refOut = React.createRef();
    this.state = {
      hotelID: props.hotelID,
      positionID: props.positionID,
      date: props.date,
      userName: props.userName,
      departmentID: props.departmentID,
      actualDetails: [],
      departmentlist: [],
      selectedDepartment: "Department",
      positionlist: [],
      selectedPosition: "Position",
      changeDepartmentID: 0,
      changePositionID: 0,
      changeActualID: 0,
      mainrowIndex: this.props.rowIndex,
      isManualBreakHide: false,
      moveShiftNotes: "",
      isDataChange: false,
      currentrow: [],
      currentIndex: 0,
      CurrentEvent: "",
      totalBreak: 0,
      alldepartmentlist: [],
      allpositionlist: [],
      addEntry: {
        newInTime: "00:00",
        newOutTime: "00:00",
        breakHH: "",
        breakMM: "",
        newDepartmentId: 0,
        newDepartment: "Department",
        newPositionId: 0,
        newPosition: "Position",
        newNotes: "",
      },
      pageloader: false,
      smileIdIMG: "",
      autoTrueUpBreakConfirmation:false,
      autoTrueUpBreakValue:"",
      payrollExportPopup : false
    };
    this.ReassignModel = this.ReassignModel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.closeReassignModel = this.closeReassignModel.bind(this);
    this.handleReassignPosition = this.handleReassignPosition.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.handleMoveShiftNotes = this.handleMoveShiftNotes.bind(this);
    this.routeLeavingGuardConfirm = this.routeLeavingGuardConfirm.bind(this);
    this.noteOutChange = this.noteOutChange.bind(this);
    this.updateSmileIdStatus = this.updateSmileIdStatus.bind(this);
    this.addNewEntryNotesChange = this.addNewEntryNotesChange.bind(this);
    this.saveNewEntry = this.saveNewEntry.bind(this);
    this.breakHHChange = this.breakHHChange.bind(this);
    this.breakMMChange = this.breakMMChange.bind(this);
    this.saveBreak = this.saveBreak.bind(this);
    this.handleResetPopup = this.handleResetPopup.bind(this);
    this.changebreakHH = this.changebreakHH.bind(this);
    this.changebreakMM = this.changebreakMM.bind(this);

    this.departmentSplitChange = this.departmentSplitChange.bind(this);
    this.positionSplitChange = this.positionSplitChange.bind(this);
    this.saveSplitEntry = this.saveSplitEntry.bind(this);
    this.saveChangePosition = this.saveChangePosition.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  updateSmileIdStatus(type: string, row: any, mode: number) {
    this.setState({ loaderTrue: true });
    let request = {} as any;
    request.hotelID = this.state.hotelID;
    request.Uniqueno = mode === 1 ? row.inUniqueno : row.outUniqueno;
    request.isFalsePunches = type;
    LaborSmileID.SimeIdAction(request)
      .then(async (result: any | null) => {
        if (result.saveStatus === "Success") {
          this.getUserActualDetails(
            this.state.hotelID,
            this.state.positionID,
            this.state.date,
            this.state.userName
          );
          this.props.setReloadPerformanceData(true);
        } else {
          Utils.toastError(result.message, {
           // position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
        this.setState({ loaderTrue: false });
      })
      .catch((error) => {
        reject();
        this.setState({ loaderTrue: false });
      });
  }

  intimeChange = (e: any, row: any, rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].inTimedup = e.target.value;
    this.setState({ actualDetails: data });
  };

  intimeBlur = (e: any, row: any, rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].inTimedup = e.target.value;
    this.setState({ actualDetails: data }, () => {
      this.changePunchOutTime(rowIndex);
    });
  };

  outtimeChange = (e: any, row: any, rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].outTimedup = e.target.value;
    this.setState({ actualDetails: data });
  };

  outtimeBlur = (e: any, row: any, rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].outTimedup = e.target.value;

    this.setState({ actualDetails: data }, () => {
      this.changePunchOutTime(rowIndex);
    });
  };

  noteChange = (e: any, row: any, rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].lastInNotesdup = e.target.value;
    this.setState({ actualDetails: data });
  };

  noteOutChange = (e: any, row: any, rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].lastOutNotesdup = e.target.value;
    this.setState({ actualDetails: data });
  };

  resetPopupData = (row: any, rowIndex: any, mode: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    if (mode === "In") {
      data[rowIndex].lastInNotesdup = "";
      data[rowIndex].inTimedup = row.inTime;
      data[rowIndex].ddlInDate = row.inDate;
      data[rowIndex].isAutoTrueUpBreakdup = "";
    } else if (mode === "Out") {
      data[rowIndex].lastOutNotesdup = "";
      data[rowIndex].isAutoTrueUpBreakdup = "";
      data[rowIndex].outTimedup = row.outTime;
      data[rowIndex].ddlOutDate = row.outDate;      
    }
    this.setState({ actualDetails: data,autoTrueUpBreakConfirmation:false });
    if (mode === "In" || mode === "Out") {
      this.getSimleIDProfileImage(mode, row);
    }
    $(
      "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex
    ).removeClass("active");
    $(
      "#punch" + mode + "-tab-" + rowIndex + "-tab-edit-punch" + rowIndex
    ).removeClass("active");
    $(
      "#punch" + mode + "-tab-" + rowIndex + "-tab-smile-id" + rowIndex
    ).removeClass("active");
    $(
      "#punch" + mode + "-tab-" + rowIndex + "-tabpane-details" + rowIndex
    ).removeClass("active show");
    $(
      "#punch" + mode + "-tab-" + rowIndex + "-tabpane-edit-punch" + rowIndex
    ).removeClass("active show");
    $(
      "#punch" + mode + "-tab-" + rowIndex + "-tabpane-smile-id" + rowIndex
    ).removeClass("active show");

    if (
      $("#punch" + mode + "-tab-" + rowIndex + "-tabpane-details" + rowIndex)
        .length > 0
    ) {
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tabpane-details" + rowIndex
      ).addClass("active show");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex
      ).addClass("active");
    } else if (
      $("#punch" + mode + "-tab-" + rowIndex + "-tabpane-edit-punch" + rowIndex)
        .length > 0
    ) {
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tabpane-edit-punch" + rowIndex
      ).addClass("active show");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-edit-punch" + rowIndex
      ).addClass("active");
    } else if (
      $("#punch" + mode + "-tab-" + rowIndex + "-tabpane-smile-id" + rowIndex)
        .length > 0
    ) {
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tabpane-smile-id" + rowIndex
      ).addClass("active show");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-smile-id" + rowIndex
      ).addClass("active");
    }
  };

  resetActiveTab = (rowIndex, selectedtab, mode, row) => {
    if (
      $("#punch" + mode + "-tab-" + rowIndex + "-tabpane-details" + rowIndex)
        .length > 0
    ) {
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex
      ).removeClass("active");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tabpane-details" + rowIndex
      ).removeClass("active show");
    } else if (
      $("#punch" + mode + "-tab-" + rowIndex + "-tabpane-edit-punch" + rowIndex)
        .length > 0
    ) {
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-edit-punch" + rowIndex
      ).removeClass("active");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tabpane-edit-punch" + rowIndex
      ).removeClass("active show");
    } else if (
      $("#punch" + mode + "-tab-" + rowIndex + "-tabpane-smile-id" + rowIndex)
        .length > 0
    ) {
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-smile-id" + rowIndex
      ).removeClass("active");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tabpane-smile-id" + rowIndex
      ).removeClass("active show");
    }

    if (selectedtab === "details" + rowIndex) {
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tabpane-details" + rowIndex
      ).addClass("active show");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-details" + rowIndex
      ).addClass("active");
    } else if (selectedtab === "edit-punch" + rowIndex) {
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tabpane-edit-punch" + rowIndex
      ).addClass("active show");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-edit-punch" + rowIndex
      ).addClass("active");
    } else if (selectedtab === "smile-id" + rowIndex) {
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tabpane-smile-id" + rowIndex
      ).addClass("active show");
      $(
        "#punch" + mode + "-tab-" + rowIndex + "-tab-smile-id" + rowIndex
      ).addClass("active");
    }
  };

  getSimleIDProfileImage = (mode: any, row: any) => {
    let request = {
      hotelID: this.state.hotelID,
      uniqueID: mode === "In" ? row.inUniqueno : row.outUniqueno,
    };

    LaborSmileID.GetSimleIDProfile(request).then(async (result: any | null) => {
      let staticPath = "";
      let basePath;
      if (result.success) {
        if (result.result !== "") {
          staticPath = "data:image/jpeg;base64,";
          basePath = result.result;
        } else {
          staticPath = "";
          basePath = profile;
        }
      } else {
        staticPath = "";
        basePath = profile;
      }
      let smileId = mode === "In" ? row.inUniqueno : row.outUniqueno;
      $("#" + smileId).attr("src", staticPath + basePath);
    });
  };

  // checkAutoTrueUpBreak = (e: any, row: any, rowIndex: any, punchtype: string) => {
  //   
  //   if (row.isAutoTrueUpBreak === "AutoTrueUpApply") {
  //           this.setState({ autoTrueUpBreakConfirmation: true }, () => {  
  //       let data = Object.assign(
  //         [],
  //         JSON.parse(JSON.stringify(this.state.actualDetails))
  //       );
  //       data[rowIndex].isAutoTrueUpBreakdup = "check";
  //       this.setState({ actualDetails: data });       
  //     });
  //   } else {
  //    this.handleSave(e, row, rowIndex, punchtype);
  //   }
  // }

  // warningCloseModel = (e: any, row: any, rowIndex: any, punchtype: string) => {
  //   
  //   this.setState({ autoTrueUpBreakConfirmation: false,autoTrueUpBreakValue:"No" },()=>{  
  //     let data = Object.assign(
  //       [],
  //       JSON.parse(JSON.stringify(this.state.actualDetails))
  //     );
  //     data[rowIndex].isAutoTrueUpBreakdup = "CloseModel";
  //     this.setState({ actualDetails: data });       
     
  //     this.handleSave(e, row, rowIndex, punchtype);
  //   });
  // };

  // saveAutoTrueUpBreak = (e: any, row: any, rowIndex: any, punchtype: string) => {
  //   this.setState(
  //     {
  //       autoTrueUpBreakConfirmation: false,
  //       autoTrueUpBreakValue:"Yes"
  //     },
  //     () => {      
  //     let data = Object.assign(
  //       [],
  //       JSON.parse(JSON.stringify(this.state.actualDetails))
  //     );
  //     data[rowIndex].isAutoTrueUpBreakdup = "saveAutoTrueUpBreak";
  //     this.setState({ actualDetails: data });       
  //     this.handleSave(e, row, rowIndex, punchtype);
  //     }
  //   );
  // };



  handleSave = (e: any, row: any, rowIndex: any, punchtype,confirmType:any,type:any) => {
    
    let currentPunchInMins = 0;
    let totalDayMin = 24 * 60;
    if (punchtype === "In") {
      if (row.lastInNotesdup.trim().length === 0) {
        Utils.toastError("Please enter explanation", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
      if (row.lastInNotesdup.trim().length > 200) {
        Utils.toastError("Notes length can not exceed 200 characters");
        return false;
      }
      let punchHrs =
        Number(row.outTime.split(":")[0]) - Number(row.inTimedup.split(":")[0]);
      currentPunchInMins =
        punchHrs * 60 +
        Number(row.outTime.split(":")[1]) -
        Number(row.inTimedup.split(":")[1]);
    } else {
      if (row.lastOutNotesdup.trim().length === 0) {
        Utils.toastError("Please enter explanation", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
      if (row.lastOutNotesdup.trim().length > 200) {
        Utils.toastError("Notes length can not exceed 200 characters", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }

      let punchHrs = 0;
      if (
        Number(row.outTimedup.split(":")[0]) < Number(row.inTime.split(":")[0])
      ) {
        punchHrs =
          24 -
          Number(row.inTime.split(":")[0]) +
          Number(row.outTimedup.split(":")[0]);
      } else {
        punchHrs =
          Number(row.outTimedup.split(":")[0]) -
          Number(row.inTime.split(":")[0]);
      }
      currentPunchInMins =
        punchHrs * 60 +
        Number(row.outTimedup.split(":")[1]) -
        Number(row.inTime.split(":")[1]);
    }

    if (currentPunchInMins < 0) {
      currentPunchInMins = currentPunchInMins + totalDayMin;
    }

    let total = 0;
    this.state.actualDetails.map((arr: any) => {
      if (arr.total.length > 0) {
        let subTotal = 0;
        if (arr.total.includes(":")) {
          const dataArray = arr.total.split(":");
          let HH = Number(dataArray[0]);
          let MM = Number(dataArray[1]);
          if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
            HH = HH < 0 ? HH * -1 : HH;
            MM = MM < 0 ? MM * -1 : MM;
            subTotal = (HH * 60 + MM) * -1;
          } else {
            subTotal = HH * 60 + MM;
          }
          total = total + subTotal;
        }
      }
    });

    let breaktotal = 0;
    this.state.actualDetails.map((arr: any) => {
      if (arr.breakTime.length > 0) {
        let subTotal = 0;
        if (arr.breakTime.includes(":")) {
          const dataArray = arr.breakTime.split(":");
          let HH = Number(dataArray[0]);
          let MM = Number(dataArray[1]);
          if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
            HH = HH < 0 ? HH * -1 : HH;
            MM = MM < 0 ? MM * -1 : MM;
            subTotal = (HH * 60 + MM) * -1;
          } else {
            subTotal = HH * 60 + MM;
          }
          breaktotal = breaktotal + subTotal;
        }
      }
    });

    let currentrowOldTotal = row.total;
    const dataArray = row.total.split(":");
    let HH = Number(dataArray[0]);
    let MM = Number(dataArray[1]);
    if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
      HH = HH < 0 ? HH * -1 : HH;
      MM = MM < 0 ? MM * -1 : MM;
      currentrowOldTotal = (HH * 60 + MM) * -1;
    } else {
      currentrowOldTotal = HH * 60 + MM;
    }

    if (total - currentrowOldTotal + currentPunchInMins < breaktotal) {
      Utils.toastError("Actual hours should be greater than Break duration.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }

    let saveActualDetail: any[] = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let newItem: any = {};
    newItem.tenantID = tenantID;
    newItem.hotelID = this.state.hotelID;
    newItem.positionID = Number(row.positionID);
    newItem.selectDate = this.state.date;
    newItem.employeeName = row.employee;
    newItem.departmentID = Number(row.departmentID);
    newItem.inTime = this.formatDate(row.inTimedup);
    newItem.outTime = this.formatDate(row.outTimedup);
    newItem.actualId = row.actualUniqueno;
    // newItem.inDate = this.state.date;
    newItem.inDate = row.ddlInDate;
    // newItem.outDate = this.state.date;
    newItem.outDate = row.ddlOutDate;
    if (punchtype === "In") {
      newItem.notes = row.lastInNotesdup.trim();
      newItem.ddlSelectedDate = row.ddlInDate;
      newItem.inDate = row.ddlInDate;
    } else {
      newItem.notes = row.lastOutNotesdup.trim();
      newItem.ddlSelectedDate = row.ddlOutDate;
      newItem.outDate = row.ddlOutDate;
    }
    newItem.entryType = row.entryType;
    newItem.flag = row.flag;
    newItem.scheduleId = row.scheduleNo;
    newItem.scheduleBreaktime =
      row.outTime !== ""
        ? "0.00"
        : row.breakTime === ""
        ? "0.00"
        : row.breakTime.toString().replace(":", ".");
    newItem.entryType = row.entryType;
    newItem.inUniqueNo = row.inUniqueno === null ? 0 : row.inUniqueno;
    newItem.outUniqueNo = row.outUniqueno === null ? 0 : row.outUniqueno;
    newItem.actualChildId = row.actualUniqueno;
    newItem.punchtype = punchtype;
    newItem.moduleName = "Performance";
    if (punchtype === "In") {
      newItem.actionType = "EditPunchIn";
    } else {
      newItem.actionType = "EditPunchOut";
    }
    newItem.pageSource = "Performance";
    newItem.IsRemoveDNS = this.state.isRemoveDNS
  //  newItem.autoTrueUpBreakValue = this.state.autoTrueUpBreakValue;
    
    saveActualDetail.push(newItem);
    let laborActualmain: any = {};
    laborActualmain.laborActualDetails = saveActualDetail;
    //this.props.parentState.isPayrollExported = true
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      this.ConfirmPayrollExport(e,row,rowIndex,punchtype,"",confirmType,type,"");
    }
    else{
      this.updateActualDetails(saveActualDetail,row,rowIndex,punchtype);}
  };

  updateActualDetails(saveActualDetail: any[],row: any,rowIndex: any, punchtype: any) {
    this.setState({ loaderTrue: true });
    this.btnEditPunch.current.disabled = true;
    laborPerformance
      .SaveLaborActual(saveActualDetail)
      .then(async (result: any | null) => {
        this.btnEditPunch.current.disabled = false;
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.props.setReloadPerformanceData(true);
            this.setState({ isDataChange: false });
            toast.success("Punch successfully edited.", {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.props.resetList();
            this.getUserActualDetails(
              this.state.hotelID,
              this.state.positionID,
              this.state.date,
              this.state.userName
            );
            this.hideExportedDate();
          } else {
            if(result.result.messageCode === "Scheduleoverlapped" && result.result.entryType === "8"){
              this.DNSConfirmationforUpdate(saveActualDetail[0],row,rowIndex,punchtype)
            }
            else{
              Utils.toastError(result.result.message, {
                // position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
            // Utils.toastError(result.result.message, {
            //  // position: toast.POSITION.BOTTOM_RIGHT,
            // });
          }
        }

        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((err: any) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  }

  IsFormValid(newActualDetails: any[]) {
    let isValid = true;
    for (let x = 0; x < newActualDetails.length; x++) {
      if (newActualDetails[x].notes.trim().length === 0) {
        return false;
      } else {
        isValid = true;
      }
    }
    return isValid;
  }

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.isDataChange &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  resetChangeState() {
    this.setState({ isDataChange: false, actualDetails: [] });
  }

  isDateChanged() {
    if (this.state.isDataChange === true) {
      return true;
    } else {
      return false;
    }
  }

  isChangediscard = () => {
    this.setState({ isDataChange: false });
    switch (this.state.CurrentEvent) {
      case "ReassignModel":
        this.handleDiscard();
        this.openReassignModel(this.state.currentrow, this.state.currentIndex);
        return;
    }
  };

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleDiscard(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  handleDiscard() {
    this.setState({ isDataChange: false });
    this.getUserActualDetails(
      this.state.hotelID,
      this.state.positionID,
      this.state.date,
      this.state.userName
    );
  }

  handleMoveShiftNotes = (event: any, row: any, rowIndex: any) => {
    let notes = event.target.value;
    let data: any[] = [];
    data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].moveShiftNotes = notes;
    this.setState({ moveShiftNotes: notes, actualDetails: data });
  };

  handleReassignPosition = (row: any, index: number) => {
    let newDepartmentlist: any[] = [];
    newDepartmentlist = Object.assign([], this.state.departmentlist);
    let deptartmentName = newDepartmentlist.filter(
      (e: { departmentID: number }) =>
        e.departmentID === Number(this.state.departmentID)
    );
    let positionName = deptartmentName[0].laborPositionlist.filter(
      (e: { positionID: number }) =>
        e.positionID === Number(this.state.positionID)
    );
    if (this.props.isedit.toString().toLowerCase() === "yes") {
      if (row.selectedDepartment === "Department") {
        Utils.toastError("Please select Department.", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (row.selectedPosition === "Position") {
        Utils.toastError("Please select Position.", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (this.state.moveShiftNotes.trim().length <= 0) {
        Utils.toastError("Please enter explanation.", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (this.state.moveShiftNotes.trim().length > 200) {
        Utils.toastError("Explanation length can not exceed 200.", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (
        row.selectedDepartment === deptartmentName[0].departmentName &&
        row.selectedPosition === positionName[0].positionName
      ) {
        Utils.toastError("Reassigning shift to the same position is not allowed.", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.positionID = Number(this.state.changePositionID);
        newItem.selectedDate = this.state.date;
        newItem.employee = this.state.userName;
        newItem.departmentID = Number(this.state.changeDepartmentID);
        newItem.actualUniqueno = Number(row.actualUniqueno);
        newItem.notes = this.state.moveShiftNotes;
        newItem.flagType = "moveshift";
        this.moveShift(newItem, row, index);
      }
    } else {
      Utils.toastError("Entry cannot be added to approved days.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  moveShift(saveActualDetail: any, row: any, index: number) {
    this.setState({ loaderTrue: true });
    laborPerformance
      .MoveActualShift(saveActualDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.props.setReloadPerformanceData(true);
            toast.success(result.result.message, {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState({ isDataChange: false });
            this.props.resetList();
            this.getUserActualDetails(
              this.state.hotelID,
              this.state.positionID,
              this.state.date,
              this.state.userName
            );
            this.closeReassignModel(row, index);
            this.hideExportedDate();
          } else {
            Utils.toastError(result.result.message, {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }

        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  formatDate(time: string) {
    const dataArray = time.split(":");
    let hh = Number(dataArray[0]);
    let m = Number(dataArray[1]);
    let dd = "AM";
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    let min = m < 10 ? "0" + m : m;
    let replacement = h + ":" + min;
    replacement += " " + dd;
    return replacement;
  }

  closeReassignModel = (row: any, rowIndex: number) => {
    let data = this.state.actualDetails;
    let index = data.indexOf(row);
    data[index].actions = "";
    data[rowIndex].moveShiftNotes = "";
    this.setState({ moveShiftNotes: "", actualDetails: data });
  };

  openReassignModel = (row: any, rowIndex: number) => {
    let data = this.state.actualDetails;
    let index = data.indexOf(row);
    data[index].actions = "reassign";
    let actualUniqueno = row.actualUniqueno;
    this.setState(
      { changeActualID: actualUniqueno, actualDetails: data },
      () => {
        let newDepartmentlist: any[] = [];
        newDepartmentlist = Object.assign([], this.state.departmentlist);
        let selectedDepartment = newDepartmentlist.filter(
          (e: { departmentID: number }) =>
            e.departmentID === Number(this.state.departmentID)
        );
        if (selectedDepartment.length > 0) {
          this.setState(
            {
              selectedDepartment: selectedDepartment[0].departmentName,
              positionlist: selectedDepartment[0].laborPositionlist,
            },
            () => {
              let data: any[] = [];
              data = Object.assign(
                [],
                JSON.parse(JSON.stringify(this.state.actualDetails))
              );
              data[rowIndex].selectedDepartment = "Department";
              data[rowIndex].selectedPosition = "Position";
              this.setState({ actualDetails: data });
            }
          );
        }
      }
    );
  };

  ReassignModel = (row: any, rowIndex: number) => {
    this.setState({
      currentrow: row,
      currentIndex: rowIndex,
      CurrentEvent: "ReassignModel",
    });
    if (this.props.isedit.toString().toLowerCase() === "yes") {
      this.openReassignModel(row, rowIndex);
    } else {
      Utils.toastError("Entry cannot be added to approved days.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  decimalHoursToString(numHours: number, numMinutes: number) {
    return `${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
  }

  FormatHoursMinutes(HoursMints: number) {
    if (HoursMints < 0) {
      HoursMints = HoursMints * -1;
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${"-"}${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    } else {
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    }
  }

  FormatHoursMinutesBreak(HoursMints: number) {
    if (HoursMints < 0) {
      HoursMints = HoursMints * -1;
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${"-"}${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    } else {
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    }
  }

  FormatHoursMinutesDuration(HoursMints: number, totalBreak: number) {
    HoursMints = HoursMints - Number(totalBreak);
    if (HoursMints < 0) {
      HoursMints = HoursMints * -1;
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${"-"}${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    } else {
      let numHours = Math.floor(Number(HoursMints) / 60);
      let numMinutes = Math.round(Number(HoursMints) % 60);
      return `${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    }
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
    this.getUserActualDetails(
      this.state.hotelID,
      this.state.positionID,
      this.state.date,
      this.state.userName
    );
    // Punch In out date dropdown list starts
    let punchInDateList: Array<any> = [];
    let currentDate = moment(this.state.date).format("MM/DD/YY");
    let previousDate = moment(this.state.date)
      .subtract(1, "days")
      .format("MM/DD/YY");
    let nextdate = moment(this.state.date).add(1, "days").format("MM/DD/YY");
    punchInDateList.push(previousDate);
    punchInDateList.push(currentDate);
    punchInDateList.push(nextdate);

    this.setState({ punchInDateList });
    // Punch In out date dropdown list ends
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  getUserActualDetails(
    hotelID: Number,
    positionID: Number,
    date: any,
    userName: any
  ) {
    this.setState({ pageloader: true });
    laborPerformance
      .laborPerformanceActualDetail(hotelID, positionID, date, userName)
      .then(async (result: any[] | null) => {
        result =
          result != null
            ? result.length > 0
              ? result[0].timesheet
              : result
            : result;
        if (result != null) {
          if (result.length > 0) {
            result = result.filter(
              (item) => +item?.scheduleNo === +this.props?.scheduleNo
            );
            let newResult: Array<object> = [];
            for (let i = 0; i < result.length; i++) {
              let pushObject = result[i];
              pushObject.ddlInDate = result[i].inDate;
              pushObject.ddlOutDate = result[i].outDate;
              pushObject.assocaiteedDate = moment(result[i].date).format(
                "MM/DD/YY"
              );
              pushObject.assocaiteedDateNotes = "";
              newResult.push(pushObject);
            }
            
            this.setState({ actualDetails: newResult });
          }
        } else {
          this.setState({ actualDetails: [] });
        }
        this.getlaborPositionDeptByemployee();
        this.setState({ pageloader: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ pageloader: false });
        reject();
      });
  }

  getlaborPositionDeptByemployee() {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let userName = storage === null ? 0 : (storage.userName as any);
    laborDeptPosition
      .getlaborPositionDeptByemployee(this.state.hotelID, userName)
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ departmentlist: result });
          this.setState({ alldepartmentlist: result });
        } else {
          this.setState({ departmentlist: [] });
          this.setState({ alldepartmentlist: [] });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error: ${err}`);
        reject();
      });
  }

  breakChange = (e: any, row: any, rowIndex: any) => {
    let data = this.state.actualDetails;
    data[rowIndex].breakTime = e.target.value;
    this.setState({ actualDetails: data, isDataChange: true }, () => {
      let cell = document.getElementById(
        this.state.mainrowIndex + "ActualTable"
      ) as any;
      cell.rows[rowIndex + 1].cells[4].children[0].children[0].autofocus = true;
      cell.rows[rowIndex + 1].cells[4].children[0].children[0].focus();
    });
  };

  handleDepartmentSelect = (row: any, index: number, eventKey: any) => {
    let data: any[] = [];
    data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[index].selectedDepartment = eventKey;
    data[index].selectedDepartment = eventKey;
    data[index].selectedPosition = "Position";
    this.setState({ actualDetails: data });
  };

  handlePositionSelect = (row: any, index: number, eventKey: any) => {
    let data: any[] = [];
    data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[index].selectedPosition = eventKey;
    this.setState({ actualDetails: data });
  };

  changebreakHH = (e: any, row: any, rowIndex: any) => {
    if (Number(e.target.value) < 60) {
      let data: any[] = [];
      data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.actualDetails))
      );
      data[rowIndex].breakHH = e.target.value;
      if (!data[rowIndex].breakMM) {
        data[rowIndex].breakMM = "00";
      }
      this.setState({ actualDetails: data });
    }
  };

  blurChangebreakHH = (e: any, row: any, rowIndex: any) => {
    if (Number(e.target.value) < 60) {
      let data: any[] = [];
      data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.actualDetails))
      );
      data[rowIndex].breakHH = e.target.value?.toString()?.padStart(2, "0");

      this.setState({ actualDetails: data });
    }
  };

  changebreakMM = (e: any, row: any, rowIndex: any) => {
    if (Number(e.target.value) < 60) {
      let data: any[] = [];
      data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.actualDetails))
      );
      data[rowIndex].breakMM = e.target.value;
      if (!data[rowIndex].breakHH) {
        data[rowIndex].breakHH = "00";
      }
      this.setState({ actualDetails: data });
    }
  };

  blurChangebreakMM = (e: any, row: any, rowIndex: any) => {
    if (Number(e.target.value) < 60) {
      let data: any[] = [];
      data = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.actualDetails))
      );
      data[rowIndex].breakMM = e.target.value?.toString()?.padStart(2, "0");
      this.setState({ actualDetails: data });
    }
  };

  getBreak(Time: any, newrowIndex: any, rowIndex: any, mode: any) {
    let data = this.state.actualDetails;
    if (data[rowIndex].scheduleNo === data[newrowIndex].scheduleNo) {
      let outTime = "";
      let inTime = "";
      if (mode === 1) {
        outTime = data[rowIndex].outTime;
        inTime = Time;
        if (outTime === "") {
          return "";
        }
      } else {
        outTime = Time;
        inTime = data[rowIndex].inTime;
        if (inTime === "") {
          return "";
        }
      }
      let duration = 0;
      if (
        this.getMinutesfromHursMinutes(inTime) >=
        this.getMinutesfromHursMinutes(outTime)
      ) {
        duration =
          this.getMinutesfromHursMinutes(outTime) -
          this.getMinutesfromHursMinutes(inTime);
      } else {
        duration =
          this.getMinutesfromHursMinutes("24:00") -
          this.getMinutesfromHursMinutes(outTime) +
          this.getMinutesfromHursMinutes(inTime);
      }
      return this.minutestoHoursMinuts(duration);
    } else {
      return "";
    }
  }

  getDuration(inTime: any, outTime: any) {
    let duration = 0;
    if (
      this.getMinutesfromHursMinutes(inTime) <
      this.getMinutesfromHursMinutes(outTime)
    ) {
      duration =
        this.getMinutesfromHursMinutes(outTime) -
        this.getMinutesfromHursMinutes(inTime);
    } else {
      duration =
        this.getMinutesfromHursMinutes("24:00") -
        this.getMinutesfromHursMinutes(inTime) +
        this.getMinutesfromHursMinutes(outTime);
    }
    return this.minutestoHoursMinuts(duration);
  }

  getMinutesfromHursMinutes(data: any) {
    let total = 0;
    if (data.trim().length > 0) {
      if (data.includes(":")) {
        const dataArray = data.split(":");
        let HH = Number(dataArray[0]);
        let MM = Number(dataArray[1]);
        total = HH * 60 + MM;
      }
    }
    return total;
  }

  minutestoHoursMinuts(totalMinutes: number) {
    if (totalMinutes >= 0) {
      return `${""}${Math.floor(totalMinutes / 60)}:${
        Number(totalMinutes % 60) < 10 ? "0" : ""
      }${Number(totalMinutes % 60)}`;
    } else {
      let hours = String(Math.floor((totalMinutes * -1) / 60));
      let minutes = String(Math.floor((totalMinutes * -1) % 60));
      return `${hours}:${minutes.length === 1 ? "0" : ""}${minutes}`;
    }
  }

  //Add new entry

  addNewEntryNotesChange(e: any) {
    let data = Object.assign(
      {},
      JSON.parse(JSON.stringify(this.state.addEntry))
    );
    data.newNotes = e.target.value;
    this.setState({ addEntry: data });
  }

  newInTimeChange = (e: any) => {
    let data = Object.assign(
      {},
      JSON.parse(JSON.stringify(this.state.addEntry))
    );
    data.newInTime = e.target.value;
    this.setState({ addEntry: data });
  };

  newOutTimeChange = (e: any) => {
    let data = Object.assign(
      {},
      JSON.parse(JSON.stringify(this.state.addEntry))
    );
    data.newOutTime = e.target.value;
    this.setState({ addEntry: data });
  };

  handleResetPopup = (e: any) => {
    this.setState({ addEntry: {}, allpositionlist: [] }, () =>
      this.setState({
        addEntry: {
          newInTime: "00:00",
          newOutTime: "00:00",
          breakHH: "",
          breakMM: "",
          newDepartmentId: 0,
          newDepartment: "Department",
          newPositionId: 0,
          newPosition: "Position",
          newNotes: "",
        },
      })
    );
  };

  saveNewEntry = () => {
    let data = Object.assign(
      {},
      JSON.parse(JSON.stringify(this.state.addEntry))
    );
    if (data.newNotes.trim().length === 0) {
      Utils.toastError("Please enter explanation", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (data.newNotes.trim().length > 200) {
      Utils.toastError("Notes length can not exceed 200 characters", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }

    let saveActualDetail: any[] = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let newItem: any = {};
    newItem.tenantID = tenantID;
    newItem.hotelID = this.state.hotelID;
    newItem.positionID = Number(this.state.positionID);
    newItem.selectDate = this.state.date;
    newItem.employeeName = this.state.userName;
    newItem.departmentID = Number(this.state.departmentID);
    newItem.inTime = this.formatDate(data.newInTime);
    newItem.outTime = this.formatDate(data.newOutTime);
    newItem.actualId = 0;
    newItem.notes = data.newNotes.trim();
    newItem.flag = "";
    newItem.scheduleId = 0;
    newItem.scheduleBreaktime = "";
    newItem.entryType = "Actual";
    newItem.inDate = this.state.date;
    newItem.outDate = this.state.date;
    newItem.inUniqueNo = 0;
    newItem.outUniqueNo = 0;
    newItem.actualChildId = 0;
    newItem.punchtype = "";
    newItem.moduleName = "Performance";
    saveActualDetail.push(newItem);
    let laborActualmain: any = {};
    laborActualmain.laborActualDetails = saveActualDetail;
    this.saveActualDetails(saveActualDetail);
  };

  saveActualDetails(saveActualDetail: any[]) {
    this.setState({ loaderTrue: true });
    this.btnSaveNewEntry.current.disabled = true;
    laborPerformance
      .SaveLaborActual(saveActualDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.props.setReloadPerformanceData(true);
            this.btnSaveNewEntry.current.disabled = false;
            $("#addentrydropdown-more").click();
            this.setState({ isDataChange: false });
            toast.success("Manual entry successfully added.", {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.props.resetList();
            this.getUserActualDetails(
              this.state.hotelID,
              this.state.positionID,
              this.state.date,
              this.state.userName
            );
            this.hideExportedDate();
          } else {
            Utils.toastError(result.result.message, {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.btnSaveNewEntry.current.disabled = false;
          }
        } else {
          this.btnSaveNewEntry.current.disabled = false;
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  breakHHChange(e: any) {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.addEntry);
      data.breakHH = e.target.value;
      if ((+data.breakMM <= 0 || !data.breakMM) && e.target.value > 0) {
        data.breakMM = "00";
      }
      const target = e.currentTarget;
      this.setState({
        addEntry: data,
        actualDetails: this.state.actualDetails,
      });
    }
  }

  breakHHBlur(e: any) {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.addEntry);

      data.breakHH = e.target.value.toString().padStart(2, "0");

      const target = e.currentTarget;
      this.setState({ addEntry: data });
    }
  }

  breakMMChange(e: any) {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.addEntry);
      data.breakMM = e.target.value;
      if ((+data.breakHH <= 0 || !data.breakHH) && e.target.value > 0) {
        data.breakHH = "00";
      }
      if (!data.breakHH) {
        data.breakHH = "00";
      }
      const target = e.currentTarget;
      this.setState({ addEntry: data });
    }
  }

  breakMMBlur(e: any) {
    if (Number(e.target.value) < 60) {
      let data = _.cloneDeep(this.state.addEntry);
      if (!data.breakHH) {
        data.breakHH = "00";
      }
      data.breakMM = e.target.value.toString().padStart(2, "0");
      const target = e.currentTarget;
      this.setState({ addEntry: data });
    }
  }

  saveBreak = (confirmType:any,type:any) => {
  
      let dataArr: any[] = [];
      let data = Object.assign(
        {},
        JSON.parse(JSON.stringify(this.state.addEntry))
      );
      dataArr = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.actualDetails))
      );
      if (data.breakHH !== "" && data.breakMM !== "") {
        if (Number(data.breakHH) <= 0 && Number(data.breakMM) <= 0) {
          Utils.toastError("Please enter Break duration in HH:MM format", {
          // position: toast.POSITION.BOTTOM_RIGHT,
          });
          return false;
        }
        if (data.breakMM.length !== 2) {
          Utils.toastError("Please enter Break duration in HH:MM format", {
          // position: toast.POSITION.BOTTOM_RIGHT,
          });
          return false;
        }
        if (Number(data.breakMM) > 60) {
          Utils.toastError("Minutes should be less than 60", {
          // position: toast.POSITION.BOTTOM_RIGHT,
          });
          return false;
        }
      } else {
        Utils.toastError("Please enter Break duration in HH:MM format", {
        // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
      if (data.newNotes.trim().length === 0) {
        Utils.toastError("Please enter Break details.", {
        // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
      if (data.newNotes.trim().length > 200) {
        Utils.toastError("Break details length can not exceed 200 characters", {
        // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }

      let total = 0;
      // eslint-disable-next-line array-callback-return
      dataArr.map((arr: any) => {
        if (arr.total.length > 0) {
          let subTotal = 0;
          if (arr.total.includes(":")) {
            const dataArray = arr.total.split(":");
            let HH = Number(dataArray[0]);
            let MM = Number(dataArray[1]);
            if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
              HH = HH < 0 ? HH * -1 : HH;
              MM = MM < 0 ? MM * -1 : MM;
              subTotal = (HH * 60 + MM) * -1;
            } else {
              subTotal = HH * 60 + MM;
            }
            total = total + subTotal;
          }
        }
      });

      if (Number(total) <= Number(data.breakHH * 60) + Number(data.breakMM)) {
        Utils.toastError("Break can not be greater or equal to working hours!", {
        // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }

      let breakTime = data.breakHH + "." + data.breakMM;

      let saveActualDetail: any[] = [];
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID = storage === null ? 0 : (storage.tenantID as any);
      let newItem: any = {};
      newItem.tenantID = tenantID;
      newItem.hotelID = this.state.hotelID;
      newItem.positionID = Number(dataArr[0].positionID);
      newItem.selectDate = this.state.date;
      newItem.employeeName = this.state.userName;
      newItem.departmentID = Number(dataArr[0].departmentID);
      newItem.inTime = "";
      newItem.outTime = "";
      newItem.actualId = dataArr[0].actualUniqueno;
      newItem.notes = data.newNotes.trim();
      newItem.flag = "";
      newItem.scheduleId = +this.props?.scheduleNo;
      newItem.scheduleBreaktime = breakTime;
      newItem.entryType = "Break";
      newItem.inDate = "";
      newItem.outDate = "";
      newItem.inUniqueNo = 0;
      newItem.outUniqueNo = 0;
      newItem.actualChildId = 0;
      newItem.punchtype = "Break";
      newItem.moduleName = "Performance";
      newItem.PageSource = "AddBreakPerformance";
      saveActualDetail.push(newItem);
      let laborActualmain: any = {};
      laborActualmain.laborActualDetails = saveActualDetail;

      //this.props.parentState.isPayrollExported = true;
      if(this.props.parentState.exportedDate !== "" && confirmType === "Yes"){
        this.ConfirmPayrollExport("","","","","",confirmType,type,"");
      }
      else{
      this.saveBreakDetails(saveActualDetail);
      }
    
  };

  saveBreakDetails(saveActualDetail: any[]) {
    this.setState({ loaderTrue: true });
    this.btnSaveBreak.current.disabled = true;
    laborPerformance
      .AddManualBreak(saveActualDetail)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.result.messageCode === "Success") {
            //this.btnSaveBreak.current.disabled = false;
            this.props.setReloadPerformanceData(true);
            $("#addbreakdropdown-more").click();
            this.setState({ isDataChange: false });
            toast.success("Manual Break successfully added.", {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.props.resetList();
            this.getUserActualDetails(
              this.state.hotelID,
              this.state.positionID,
              this.state.date,
              this.state.userName
            );
            this.hideExportedDate();
          } else {
            this.btnSaveBreak.current.disabled = false;
            Utils.toastError(result.result.message, {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          this.btnSaveBreak.current.disabled = false;
        }

        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        //this.btnSaveBreak.current.disabled = false;
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  updateBreak = (e: any, row: any, rowIndex: any) => {
    if (row.breakHH !== "" && row.breakMM !== "") {
      if (Number(row.breakHH) <= 0 && Number(row.breakMM) <= 0) {
        Utils.toastError("Please enter Break duration in HH:MM format", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
      if (row.breakMM.length !== 2) {
        Utils.toastError("Please enter Break duration in HH:MM format", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
      if (Number(row.breakMM) > 60) {
        Utils.toastError("Minutes should be less than 60", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
    } else {
      Utils.toastError("Please enter Break duration in HH:MM format", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }

    if (row.lastOutNotesdup.trim().length === 0) {
      Utils.toastError("Please enter Break details.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (row.lastOutNotesdup.trim().length > 200) {
      Utils.toastError("Break details length can not exceed 200 characters", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }

    let dataArr: any[] = [];
    dataArr = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );

    let total = 0;
    // eslint-disable-next-line array-callback-return
    dataArr.map((arr: any) => {
      if (arr.total.length > 0) {
        let subTotal = 0;
        if (arr.total.includes(":")) {
          const dataArray = arr.total.split(":");
          let HH = Number(dataArray[0]);
          let MM = Number(dataArray[1]);
          if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
            HH = HH < 0 ? HH * -1 : HH;
            MM = MM < 0 ? MM * -1 : MM;
            subTotal = (HH * 60 + MM) * -1;
          } else {
            subTotal = HH * 60 + MM;
          }
          total = total + subTotal;
        }
      }
    });
    if (Number(total) <= Number(row.breakHH * 60) + Number(row.breakMM)) {
      Utils.toastError("Break can not be greater or equal to working hours!", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }

    let breakTime = row.breakHH + "." + row.breakMM;
    let saveActualDetail: any[] = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let newItem: any = {};
    newItem.tenantID = tenantID;
    newItem.hotelID = this.state.hotelID;
    newItem.positionID = Number(row.positionID);
    newItem.selectDate = row.date;
    newItem.employeeName = row.employee;
    newItem.departmentID = Number(row.departmentID);
    newItem.inTime = "";
    newItem.outTime = "";
    newItem.actualId = row.actualUniqueno;
    newItem.notes = row.lastOutNotesdup;
    newItem.flag = row.flag;
    newItem.scheduleId = row.scheduleNo;
    newItem.scheduleBreaktime = breakTime;
    newItem.entryType = "Break";
    newItem.inDate = "";
    newItem.outDate = "";
    newItem.inUniqueNo = 0;
    newItem.outUniqueNo = 0;
    newItem.actualChildId = row.actualUniqueno;
    newItem.punchtype = "";
    newItem.moduleName = "TimeSheet";
    newItem.PageSource = "EditBreakPerformance";
    saveActualDetail.push(newItem);
    let laborActualmain: any = {};
    laborActualmain.laborActualDetails = saveActualDetail;
    this.updateBreakDetails(saveActualDetail);
  };

  updateBreakDetails(saveActualDetail: any[]) {
    this.setState({ loaderTrue: true });
    this.btnEditBreak.current.disabled = true;
    laborPerformance
      .AddManualBreak(saveActualDetail)
      .then(async (result: any | null) => {
        this.btnEditBreak.current.disabled = false;
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.props.setReloadPerformanceData(true);
            this.setState({ isDataChange: false });
            toast.success("Manual Break successfully edited.", {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.props.resetList();
            this.getUserActualDetails(
              this.state.hotelID,
              this.state.positionID,
              this.state.date,
              this.state.userName
            );
            this.hideExportedDate();
          } else {
            Utils.toastError(result.result.message, {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }

        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        //this.btnEditBreak.current.disabled = false;
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  handleRootClose(event: any) {
    this.props.onClose(event, { source: "rootClose" });
  }

  //---------------------- Split Entry--------------------------//
  actionButton = (row: any, rowIndex: number, mode: any) => {
    let dataArr = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    dataArr[rowIndex].actions = mode;
    dataArr[rowIndex].splitDepartment = "Select";
    dataArr[rowIndex].splitDepartmentId = 0;
    dataArr[rowIndex].splitNotes = "";
    dataArr[rowIndex].splitPosition = "Select";
    dataArr[rowIndex].splitPositionId = 0;
    dataArr[rowIndex].splitTime = "00:00";
    this.setState({ actualDetails: dataArr });
  };

  splitTimeChange = (e: any, row: any, rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].splitTime = e.target.value;
    this.setState({ actualDetails: data });
  };

  departmentSplitChange(event: any, row: any, item: any, rowIndex: any) {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].splitDepartmentId = item.departmentID;
    data[rowIndex].splitDepartment = item.departmentName;
    data[rowIndex].splitPosition = "Select";
    data[rowIndex].splitPositionId = 0;
    this.setState({
      actualDetails: data,
      allpositionlist: item.laborPositionlist,
    });
  }

  positionSplitChange(event: any, row: any, item: any, rowIndex: any) {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].splitPosition = item.positionName;
    data[rowIndex].splitPositionId = item.positionID;
    this.setState({ actualDetails: data });
  }

  noteSplitChange = (e: any, row: any, rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].splitNotes = e.target.value;
    this.setState({ actualDetails: data });
  };

  hoursMinutesToMinutes(data: any) {
    let total = 0;
    if (data.trim().length > 0) {
      if (data.includes(":")) {
        const dataArray = data.split(":");
        let HH = Number(dataArray[0]);
        let MM = Number(dataArray[1]);
        if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
          HH = HH < 0 ? HH * -1 : HH;
          MM = MM < 0 ? MM * -1 : MM;
          total = (HH * 60 + MM) * -1;
        } else {
          total = HH * 60 + MM;
        }
      } else {
        total = 0;
      }
    } else {
      total = 0;
    }
    return total;
  }

  isSplitTimeValid(inTime: any, splitTime: any, outTime: any) {
    let isNextDayShift = false;
    isNextDayShift =
      this.hoursMinutesToMinutes(inTime) > this.hoursMinutesToMinutes(outTime)
        ? true
        : false;
    if (isNextDayShift) {
      if (
        this.hoursMinutesToMinutes(inTime) <
          this.hoursMinutesToMinutes(splitTime) &&
        this.hoursMinutesToMinutes(splitTime) <=
          this.hoursMinutesToMinutes("24:00")
      ) {
        return true;
      } else if (
        this.hoursMinutesToMinutes("00:00") <=
          this.hoursMinutesToMinutes(splitTime) &&
        this.hoursMinutesToMinutes(splitTime) <
          this.hoursMinutesToMinutes(outTime)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.hoursMinutesToMinutes(inTime) <
          this.hoursMinutesToMinutes(splitTime) &&
        this.hoursMinutesToMinutes(splitTime) <
          this.hoursMinutesToMinutes(outTime)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  saveSplitEntry = (row: any,confirmType : any, type:any) => {
    if (row.splitTime.length === 0) {
      Utils.toastError("Please enter Split Time.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (row.splitDepartmentId === 0) {
      Utils.toastError("Please select department.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (row.splitPositionId === 0) {
      Utils.toastError("Please select position.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (row.splitNotes.trim().length === 0) {
      Utils.toastError("Please enter notes.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (row.splitNotes.trim().length > 200) {
      Utils.toastError("Notes length can not exceed 200 characters.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    let dataArr: any[] = [];
    dataArr = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    let saveActualDetail: any[] = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    for (let i = 0; i < dataArr.length; i++) {
      if (row.actualUniqueno === dataArr[i].actualUniqueno) {
        if (
          this.isSplitTimeValid(
            dataArr[i].inTime,
            dataArr[i].splitTime,
            dataArr[i].outTime
          )
        ) {
          //-Existing Split Row
          let newItem: any = {};
          newItem.actualId = dataArr[i].actualUniqueno;
          newItem.actualChildId = dataArr[i].actualUniqueno;
          newItem.tenantID = tenantID;
          newItem.hotelID = this.state.hotelID;
          newItem.positionID = Number(dataArr[i].positionID);
          newItem.selectDate = dataArr[i].date;
          newItem.employeeName = dataArr[i].employee;
          newItem.departmentID = Number(dataArr[i].departmentID);
          newItem.inTime = this.formatDate(dataArr[i].inTime);
          newItem.outTime = this.formatDate(dataArr[i].splitTime);
          newItem.notes = dataArr[i].notes;
          newItem.flag = dataArr[i].flag;
          newItem.scheduleId = dataArr[i].scheduleNo;
          newItem.scheduleBreaktime = "";
          newItem.entryType = "split"; //dataArr[i].entryType;
          newItem.inDate = dataArr[i].inDate;
          newItem.outDate = dataArr[i].outDate;
          newItem.inUniqueNo = dataArr[i].inUniqueno;
          newItem.outUniqueNo = 0;
          newItem.punchtype = "out";
          newItem.moduleName = "Performance";
          newItem.actionType = "SplitEntry";
          newItem.pageSource = "Performance";
          saveActualDetail.push(newItem);

          //-New Split Row
          let newSplitItem: any = {};
          newSplitItem.actualId = 0;
          newSplitItem.actualChildId = 0;
          newSplitItem.tenantID = tenantID;
          newSplitItem.hotelID = this.state.hotelID;
          newSplitItem.positionID = Number(dataArr[i].splitPositionId);
          newSplitItem.selectDate = dataArr[i].date;
          newSplitItem.employeeName = dataArr[i].employee;
          newSplitItem.departmentID = Number(dataArr[i].splitDepartmentId);
          newSplitItem.inTime = this.formatDate(dataArr[i].splitTime);
          newSplitItem.outTime = this.formatDate(dataArr[i].outTime);
          newSplitItem.notes = dataArr[i].splitNotes;
          newSplitItem.flag = dataArr[i].flag;
          newSplitItem.scheduleId = 0;
          newSplitItem.scheduleBreaktime = "";
          newSplitItem.entryType = "split";
          newSplitItem.inDate = dataArr[i].inDate;
          newSplitItem.outDate = dataArr[i].outDate;
          newSplitItem.inUniqueNo = 0;
          newSplitItem.outUniqueNo = dataArr[i].outUniqueno;
          newSplitItem.punchtype = "";
          newSplitItem.moduleName = "Performance";
          newSplitItem.actionType = "SplitEntry";
          newSplitItem.pageSource = "Performance";
          saveActualDetail.push(newSplitItem);
        } else {
          Utils.toastError(
            "Please enter split time between Punch In and Punch Out time.",
            {
             // position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
          return false;
        }
      }
    }

    let actualArr = this.state.actualDetails.filter(
      (a) => a.entryType != "Break"
    );
    let totalbreakDetail = actualArr;
    let total = 0;
    totalbreakDetail.map((arr: any) => {
      if (arr.total.length > 0) {
        let subTotal = 0;
        if (arr.total.includes(":")) {
          const dataArray = arr.total.split(":");
          let HH = Number(dataArray[0]);
          let MM = Number(dataArray[1]);
          if (dataArray[0].includes("-") || dataArray[1].includes("-")) {
            HH = HH < 0 ? HH * -1 : HH;
            MM = MM < 0 ? MM * -1 : MM;
            subTotal = (HH * 60 + MM) * -1;
          } else {
            subTotal = HH * 60 + MM;
          }
          total = total + subTotal;
        }
      }
    });

    let breakArr = dataArr.filter(
      (x) => x.entryType == "Break" && x.scheduleNo == row.scheduleNo
    );
    if (breakArr?.length > 0) {
      let breakMinutes = this.hoursMinutesToMinutes(breakArr?.[0].breakTime);
      let shift1 = saveActualDetail?.length > 0 ? saveActualDetail[0] : {};
      let shift2 = saveActualDetail?.length > 1 ? saveActualDetail[1] : {};
      if (shift1 == null || shift1 == undefined) return;
      if (shift2 == null || shift2 == undefined) return;
      let shift1InTime = shift1.inDate + " " + shift1.inTime;
      let shift1OutTime = shift1.outDate + " " + shift1.outTime;
      let shift2InTime = shift2.inDate + " " + shift2.inTime;
      let shift2OutTime = shift2.outDate + " " + shift2.outTime;
      let shift1Minutes = Utils.getMinutesBetweenInOutDatetime(
        shift1InTime,
        shift1OutTime
      );
      let shift2Minutes = Utils.getMinutesBetweenInOutDatetime(
        shift2InTime,
        shift2OutTime
      );
      total = total - shift2Minutes;
      if (breakMinutes < total) {
        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.positionID = Number(shift1.positionID);
        newItem.selectDate = row.date;
        newItem.employeeName = row.employee;
        newItem.departmentID = Number(shift1.departmentID);
        newItem.inTime = "";
        newItem.outTime = "";
        newItem.actualId = shift1.actualId;
        newItem.notes = breakArr?.[0].notes;
        newItem.flag = breakArr?.[0].flag;
        newItem.scheduleId = shift1.scheduleId;
        newItem.scheduleBreaktime =
          breakArr?.[0].breakHH + "." + breakArr?.[0].breakMM;
        newItem.entryType = "Break";
        newItem.inDate = "";
        newItem.outDate = "";
        newItem.inUniqueNo = 0;
        newItem.outUniqueNo = 0;
        newItem.actualChildId = shift1.actualId;
        newItem.punchtype = "";
        newItem.moduleName = "Performance";
        saveActualDetail.push(newItem);
      } else if (breakMinutes < shift2Minutes) {
        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.positionID = Number(shift2.positionID);
        newItem.selectDate = row.date;
        newItem.employeeName = row.employee;
        newItem.departmentID = Number(shift2.departmentID);
        newItem.inTime = "";
        newItem.outTime = "";
        newItem.actualId = shift2.actualId;
        newItem.notes = breakArr?.[0].notes;
        newItem.flag = breakArr?.[0].flag;
        newItem.scheduleId = shift2.scheduleId;
        newItem.scheduleBreaktime =
          breakArr?.[0].breakHH + "." + breakArr?.[0].breakMM;
        newItem.entryType = "Break";
        newItem.inDate = "";
        newItem.outDate = "";
        newItem.inUniqueNo = 0;
        newItem.outUniqueNo = 0;
        newItem.actualChildId = shift2.actualId;
        newItem.punchtype = "";
        newItem.moduleName = "Performance";
        saveActualDetail.push(newItem);
      } else {
        Utils.toastError("One shift must be at least as long as the manual break", {
         // position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
    }

    //this.props.parentState.isPayrollExported = true
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      this.ConfirmPayrollExport("",row,"","","",confirmType,type,"");
      this.setState({payrollExportPopup : true});
    }
    else{
    this.splitEntaryDetails(saveActualDetail);
    }
  };

  splitEntaryDetails(saveActualDetail: any[]) {
    this.setState({ loaderTrue: true });
    if(this.btnSplitShift.current !== null){
    this.btnSplitShift.current.disabled = true;}
    laborPerformance
      .SaveLaborActual(saveActualDetail)
      .then(async (result: any | null) => {
        if(this.btnSplitShift.current !== null){
        this.btnSplitShift.current.disabled = false;}
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.props.setReloadPerformanceData(true);
            this.setState({ isDataChange: false });
            toast.success("Shift successfully split.", {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.props.resetList();
            this.getUserActualDetails(
              this.state.hotelID,
              this.state.positionID,
              this.state.date,
              this.state.userName
            );
            this.hideExportedDate();
          } else {
            Utils.toastError(result.result.message, {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }

        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        //this.btnSplitShift.current.disabled = false;
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  saveChangePosition = (row: any, index: any,confirmType:any,type:any) => {
    if (row.splitDepartmentId === 0) {
      Utils.toastError("Please select department.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (row.splitPositionId === 0) {
      Utils.toastError("Please select position.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (row.splitNotes.trim().length === 0) {
      Utils.toastError("Please enter notes.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (row.splitNotes.trim().length > 200) {
      Utils.toastError("Notes length can not exceed 200 characters.", {
       // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let saveActualDetail: any[] = [];
    let dataArr: any[] = [];
    dataArr = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    for (let i = 0; i < dataArr.length; i++) {
      if (row.actualUniqueno === dataArr[i].actualUniqueno) {
        let newItem: any = {};
        newItem.actualId = row.actualUniqueno;
        newItem.actualChildId = row.actualUniqueno;
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.positionID = Number(row.splitPositionId);
        newItem.selectDate = row.date;
        newItem.employeeName = row.employee;
        newItem.departmentID = Number(row.splitDepartmentId);
        newItem.inTime = this.formatDate(row.inTime);
        newItem.outTime = this.formatDate(row.outTime);
        newItem.notes = row.splitNotes;
        newItem.flag = row.flag;
        newItem.scheduleId = row.scheduleNo;
        newItem.scheduleBreaktime = "";
        newItem.entryType = "changeposition";
        newItem.inDate = row.inDate;
        newItem.outDate = row.outDate;
        newItem.inUniqueNo = row.inUniqueNo;
        newItem.outUniqueNo = row.outUniqueNo;
        newItem.punchtype = row.punchtype;
        newItem.moduleName = "TimeSheet";
        newItem.actionType = "ChangePosition";
        newItem.pageSource = "Performance";
        saveActualDetail.push(newItem);
      } else {
        let newItem: any = {};
        newItem.actualId = dataArr[i].actualUniqueno;
        newItem.actualChildId = dataArr[i].actualUniqueno;
        newItem.tenantID = tenantID;
        newItem.hotelID = this.state.hotelID;
        newItem.positionID = Number(dataArr[i].positionID);
        newItem.selectDate = dataArr[i].date;
        newItem.employeeName = dataArr[i].employee;
        newItem.departmentID = Number(dataArr[i].departmentID);
        newItem.inTime = this.formatDate(dataArr[i].inTime);
        newItem.outTime = this.formatDate(dataArr[i].outTime);
        newItem.notes = dataArr[i].notes;
        newItem.flag = dataArr[i].flag;
        newItem.scheduleId = dataArr[i].scheduleNo;
        newItem.scheduleBreaktime = dataArr[i].breakTime
          .toString()
          .replace(":", ".");
        newItem.entryType = dataArr[i].entryType;
        newItem.inDate = dataArr[i].inDate;
        newItem.outDate = dataArr[i].outDate;
        newItem.inUniqueNo = dataArr[i].inUniqueno;
        newItem.outUniqueNo = dataArr[i].outUniqueno;
        newItem.punchtype = dataArr[i].punchtype;
        newItem.moduleName = "Performance";
        saveActualDetail.push(newItem);
      }
    }

    //this.props.parentState.isPayrollExported = true
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
      this.setState({payrollExportPopup : true});
    }
    else{
    this.saveChangePositionDetails(saveActualDetail);
    }
  };

  saveChangePositionDetails(saveActualDetail: any[]) {
    this.setState({ loaderTrue: true });
    if(this.btnChangePostion.current !== null){
    this.btnChangePostion.current.disabled = true;}
    laborPerformance
      .ShiftPositionMove(saveActualDetail)
      .then(async (result: any | null) => {
        if(this.btnChangePostion.current !== null){
        this.btnChangePostion.current.disabled = false;}
        if (result != null) {
          if (result.result.messageCode === "Success") {
            this.props.setReloadPerformanceData(true);
            this.setState({ isDataChange: false });
            toast.success("Shift successfully reassigned.", {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.props.resetList();
            this.getUserActualDetails(
              this.state.hotelID,
              this.state.positionID,
              this.state.date,
              this.state.userName
            );
            this.hideExportedDate();
          } else {
            Utils.toastError(result.result.message, {
             // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }

        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error: any) => {
        //this.btnChangePostion.current.disabled = false;
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  handleOutsideClick() {
    if(this.state.payrollExportPopup === false){
    let actualDetails: any[] = [];
    actualDetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    for (let i = 0; i < actualDetails.length; i++) {
      actualDetails[i].actions = "";
    }
    this.setState({ actualDetails: actualDetails });
  }
  }

  hideExportedDate() {
    // this.props.hideExportedDate();
  }
  deleteBreak = (row: any,confirmType:any,type:any) => {
    debugger;
    //this.props.parentState.isPayrollExported = true
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      this.ConfirmPayrollExport("",row,"","","",confirmType,type,"");
    }
    else
    {
      this.setState({ loaderTrue: true });
      let deleteRequest: any = {};
      deleteRequest.scheduleID = row.scheduleNo;
      deleteRequest.hotelID = this.state.hotelID;
      deleteRequest.date = row.date;
      deleteRequest.username = this.state.userName;
      deleteRequest.PageSource = "DeleteManualBreakPerformance";
      laborPerformance
        .DeleteManualBreak(deleteRequest)
        .then(async (result: any | null) => {
          if (result.success) {
            toast.success("Manual Break deleted successfully", {
            // position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.props.resetList();
            this.getUserActualDetails(
              this.state.hotelID,
              this.state.positionID,
              this.state.date,
              this.state.userName
            );

            this.props.setReloadPerformanceData(true);
            //this.hideExportedDate();
            //this.hideExportedDate();
          } else {
            Utils.toastError(result.message, {
            // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          this.setState({ loaderTrue: false });
          resolve();
        })
        .catch((error) => {
          reject();
          this.setState({ loaderTrue: false });
        });
      }
  };

  getAssociateDateList = (rowData: any) => {
    let newDateList: Array<string> = [];
    
    let previousDate = moment(rowData.inDate)
      .subtract(1, "days")
      .format("MM/DD/YY");
    let currentDate = moment(rowData.inDate).format("MM/DD/YY");
    let NextDate = moment(rowData.inDate).add(1, "days").format("MM/DD/YY");

    let todayActualDate = moment().format("MM/DD/YY");
    if(todayActualDate === currentDate){
       newDateList.push(previousDate);
      newDateList.push(currentDate);
    }else{
      newDateList.push(previousDate);
      newDateList.push(currentDate);
      newDateList.push(NextDate);
    }

    return newDateList;

    // this.setState({ associateDateList: newDateList });
  };

  assocaiteDateChange = (row: any, item: any, index: number) => {
    let data = _.cloneDeep(this.state.actualDetails);
    
    // let childIndex = this.getCurrentChildRow(row, index);

    data[index].assocaiteedDate = item;
    this.setState({
      actualDetails: data,
    });
  };

  assocaitedNotesChange = (e: any, row: any, index: any) => {
    let data = _.cloneDeep(this.state.actualDetails);

    // let childIndex = this.getCurrentChildRow(row, index);

    // data[index].timesheet[childIndex].assocaiteedDateNotes = e.target.value;

    data[index].assocaiteedDateNotes = e.target.value;

    this.setState({
      actualDetails: data,
    });
  };

  saveAssocatedDateChange = (row: any, index: any,confirmType:any,type:any) => {
    let data = _.cloneDeep(this.state.dayDetails);

    // let childIndex = this.getCurrentChildRow(row, index);
    if (row.assocaiteedDateNotes === "") {
      Utils.toastError("Please enter explanation");
      return;
    }

    
    let saveActualDetail: any[] = [];
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let newItem: any = {};
    newItem.tenantID = tenantID;
    newItem.hotelID = this.state.hotelID;
    newItem.positionID = Number(row.positionID);
    newItem.selectDate = row.assocaiteedDate;
    newItem.employeeName = row.employee;
    newItem.departmentID = Number(row.departmentID);
    newItem.inTime = this.formatDate(row.inTimedup);
    newItem.outTime = this.formatDate(row.outTimedup);
    newItem.inDate = row.ddlInDate;
    newItem.outDate = row.ddlOutDate;
    newItem.actualId = row.actualUniqueno;
    
    newItem.notes =row.assocaiteedDateNotes;
    newItem.actionType = "ChangeAssociateDate";
    newItem.entryType = row.entryType;
    newItem.flag = row.flag;
    newItem.scheduleId = row.scheduleNo;
    newItem.scheduleBreaktime =
      row.outTime !== "" 
        ? "0.00"
        : row.breakTime === ""
        ? "0.00"
        : row.breakTime.toString().replace(":", ".");
    newItem.entryType = row.entryType;
    newItem.inUniqueNo = row.inUniqueno === null ? 0 : row.inUniqueno;
    newItem.outUniqueNo = row.outUniqueno === null ? 0 : row.outUniqueno;
    newItem.actualChildId = row.actualUniqueno;
    newItem.moduleName = "Performance";
    newItem.pageSource = "Performance";
    //this.props.parentState.isPayrollExported = true;
    // if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    // {
    //   this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
    // }
    // else 
    // {
      if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
        {
          if((row.assocaiteedDate > this.props.parentState.payPeriodDates[1]) || (row.assocaiteedDate < this.props.parentState.payPeriodDates[0])){
            saveActualDetail.push(newItem);
            laborPerformance
            .SaveLaborAssociateShift(saveActualDetail)
              .then(async (result: any | null) => {
                if (result != null) {
                  this.props.setReloadPerformanceData(true);
                  this.setState({ isDataChange: false });
                  if (result.result.messageCode === "Success") {
                    this.setState({ isDataChange: false });
                //   toast.success("Associate date changed successfully.");
                    toast.success("Associate date changed successfully.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
      
                    this.props.resetList();
                    this.getUserActualDetails(
                      this.state.hotelID,
                      this.state.positionID,
                      this.state.date,
                      this.state.userName
                    );
                    this.hideExportedDate();
                  } else {
                    Utils.toastError(result.result.message);
                  }
                }
                resolve();
              })
              .catch((err) => {
                Utils.toastError(`Server Error: ${err}`);
                reject();
              });
          }
          else{
            this.ConfirmPayrollExport("",row,"","",index,confirmType,type,"");
            this.setState({payrollExportPopup : true})
          }
        }
        else{
      saveActualDetail.push(newItem);
      laborPerformance
      .SaveLaborAssociateShift(saveActualDetail)
        .then(async (result: any | null) => {
          if (result != null) {
            this.props.setReloadPerformanceData(true);
            this.setState({ isDataChange: false });
            if (result.result.messageCode === "Success") {
              this.setState({ isDataChange: false });
          //   toast.success("Associate date changed successfully.");
              toast.success("Associate date changed successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });

              this.props.resetList();
              this.getUserActualDetails(
                this.state.hotelID,
                this.state.positionID,
                this.state.date,
                this.state.userName
              );
              this.hideExportedDate();
            } else {
              Utils.toastError(result.result.message);
            }
          }
          resolve();
        })
        .catch((err) => {
          Utils.toastError(`Server Error: ${err}`);
          reject();
        });
      }
  };

  formatColumnManualDelete = (cell: any, row: any, rowIndex: any) => {
    let confirmModalContent = [
      {
        title: "Delete Shift",
        desc: "Are you sure you want to delete the Manual Shift?",
        cancleAction: "Cancel",
        ActionTrue: "Delete",
      },
    ];

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div className="delete-Confirm">
          <ConfirmationModal
            confirmModalContent={confirmModalContent}
            ishandleDeletePosition={this.IshandleManualDeleteShift.bind(
              this,
              row
            )}
          />
        </div>
      </OutsideClickHandler>
    );
  };

  IshandleManualDeleteShift(row: any, mode: any) {
    this.handleOutsideClick();
    if (mode) {
      this.IshandleManualDeleteEntry(row,"Yes","DeleteManualBreak");
    }
    // else {
    // this.handleOutsideClick();
    // }
  }

  IshandleManualDeleteEntry = (row: any,confirmType:any,type:any) => {
    debugger;
    //this.props.parentState.isPayrollExported = true
    if(this.props.parentState.exportedDate !== "" && confirmType === "Yes")
    {
      this.ConfirmPayrollExport("",row,"","","",confirmType,type,"");
    }
    else
    { 
      this.setState({ loaderTrue: true });
      let deleteManualRequest: any = {} as any;
      deleteManualRequest.actualID = row.actualUniqueno;
      deleteManualRequest.hotelID = this.state.hotelID;
      deleteManualRequest.date = row.date;
      deleteManualRequest.type = "deleteManualShift";
      deleteManualRequest.username = this.state.userName;

      laborPerformance
        .DeleteManualActualShift(deleteManualRequest)
        .then(async (result: any | null) => {
          if (result.success) {
            this.props.setReloadPerformanceData(true);
            toast.success("Shift deleted successfully", {
            // position: toast.POSITION.BOTTOM_RIGHT,
            });

            this.props.resetList();
            this.getUserActualDetails(
              this.state.hotelID,
              this.state.positionID,
              this.state.date,
              this.state.userName
            );
          } else {
            Utils.toastError(result.message, {
            // position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          this.setState({ loaderTrue: false });
          resolve();
        })
        .catch((err) => {
          this.setState({ loaderTrue: false });
          Utils.toastError(`Server Error: ${err}`, {
          // position: toast.POSITION.BOTTOM_RIGHT,
          });
          reject();
        });
      }
  };

  ddlInDateSelection = (item: any, rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    data[rowIndex].ddlInDate = item;

    this.setState(
      {
        actualDetails: data,
      },
      () => {
        this.changePunchOutTime(rowIndex);
      }
    );
  };

  changePunchOutTime = (rowIndex: any) => {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.actualDetails))
    );
    // Change Punch Out Date List on the basis of Punch In Selected Date

    let inMomentTime = moment(
      `${data[rowIndex].ddlInDate} ${data[rowIndex].inTimedup}`
    );
    let outMomentTime = moment(
      `${data[rowIndex].ddlInDate} ${data[rowIndex].outTimedup}`
    );

    let differenceInTime = outMomentTime.diff(inMomentTime);

    let punchOutDate = moment(data[rowIndex].ddlInDate).format("MM/DD/YY");
    if (differenceInTime <= 0) {
      punchOutDate = moment(data[rowIndex].ddlInDate)
        .add(1, "days")
        .format("MM/DD/YY");
    }
    data[rowIndex].ddlOutDate = punchOutDate;

    this.setState({
      actualDetails: data,
    });
  };

  hideDropdown = () => {
    $("#performance-child-table-comments  .dropdown-menu").hide();
    $(".notes-btn").removeClass("show");
  }

  AddManualShiftbyUpdate = (e,row,rowIndex,punchtype) => {
    this.setState({ isRemoveDNS: "Yes" }, () => {
      this.handleSave(e,row,rowIndex,punchtype,"Yes",""); 
    });
  }

  DNSConfirmationforUpdate(e,row,rowIndex,punchtype) {
    confirmAlert({
      message:
        // `Adding a manual shift will remove the DNS shift for ${e.employeeName}. Are you sure you want to continue?`,
        `Adding a manual shift will remove the DNS shift for this employee. Are you sure you want to continue?`,
      buttons: [
        {
          label: "No",
          onClick: () => reject(),
        },
         {
          label: "Yes",
          onClick: () => this.AddManualShiftbyUpdate(e,row,rowIndex,punchtype),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  NoClick(type:any) {
    this.setState({payrollExportPopup: false})
   if(type==="AddBreak"){
    setTimeout(() => {
      this.refAddBreak.current.click();
    }, 0);
  }
  if(type==="In"){
    setTimeout(() => {
      this.refIn.current.click();
    }, 0);
  }
  if(type==="Out"){
    setTimeout(() => {
      this.refOut.current.click();
    }, 0);
  }

  }


  ConfirmPayrollExport(e: any, row: any, rowIndex: any, punchtype: string, index: any,confirmType:any,type:any,callType:any) {
    confirmAlert({
     // title: "Unsaved confirmation",
      message:
        "Payroll for this period has already been exported. Continuing will require payroll to be re-exported to prevent any disruptions. Would you like to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
           type==="Out" ? this.handleSave(e,row,rowIndex,punchtype,"No",type) 
          : type==="In" ? this.handleSave(e,row,rowIndex,punchtype,"No",type) 
          : type === "Split" ? this.saveSplitEntry(row,"No",type) 
          : type === "ChangePosition" ? this.saveChangePosition(row,index,"No",type) 
          : type === "DeleteBreak" ? this.deleteBreak(row,"No",type) 
          : type === "AddBreak" ? this.saveBreak("No",type)
          : type === "Associate" ? this.saveAssocatedDateChange(row,index,"No",type)
          : type === "DeleteManualBreak" ? this.IshandleManualDeleteEntry(row,"No",type) 
          // : type === "AddEntry" ? this.saveNewEntry(index,callType,"No",type)
          // : type === "AddShift" ? this.saveNewEntry(index,callType,"No",type)
          : <></>
        },
        {
          label: "No",
          onClick: () => this.NoClick(type),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }


  render() {
    let isActualRow = false;
    let isBreak = false;
    let isActual = false;
    const isActualExist = () => {
      let dataArr: any[] = [];
      dataArr = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.actualDetails))
      );
      if (dataArr.length > 0) {
        isBreak =
          dataArr.filter((item) => item.entryType === "Break").length > 0
            ? true
            : false;
        isActual =
          dataArr.filter((item) => item.entryType === "Actual").length > 0
            ? true
            : false;
      }

      if (dataArr.length > 0 && isActual === true && isBreak === false) {
        isActualRow = true;
      } else if (dataArr.length > 0 && isBreak === true) {
        isActualRow = false;
      } else {
        isActualRow = false;
      }
      return;
    };

    const columns = [
      {
        dataField: "inTime",
        text: "In",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <>
            {row.entryType.toLowerCase() !== "break" && (
              <div className="d-flex align-items-center">
                {row.maxCountin > 1 && <div className="dot margin-right"></div>}
                {((this.props.isedit.toString().toLowerCase() === "yes" &&
                  row.opens !== "Open") ||
                  row.lastInNotes.length > 0 ||
                  row.inImage.length > 0) && (
                  <Dropdown className="more-action grey" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary  more pos-abs"
                      id="dropdown-more"
                      ref={this.refIn}
                    >
                      <div
                        className="d-flex align-items-center"
                        onClick={() => this.resetPopupData(row, rowIndex, "In")}
                      >
                        {this.formatDate(row.inTime)}
                        {row.inImage.length > 0 && (
                          <div>
                            {row.inimagestatus === "Pending" && (
                              <div className="orange-bg">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                  />
                                  <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                </svg>
                              </div>
                            )}
                            {row.inimagestatus === "Pass" && (
                              <div className="grey-bg">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                  />
                                  <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                </svg>
                              </div>
                            )}
                            {row.inimagestatus === "Fail" && (
                              <div className="red-badges">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                  />
                                  <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                </svg>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="pos-name">
                        <Dropdown.Item className="cross-btn" href="">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                              fill="#84888C"
                            />
                          </svg>
                        </Dropdown.Item>
                      </div>
                      <Tabs
                        id={"punchIn-tab-" + rowIndex}
                        onSelect={(selectedtab) =>
                          this.resetActiveTab(rowIndex, selectedtab, "In", row)
                        }
                      >
                        {row.lastInNotes.length > 0 && (
                          <Tab eventKey={"details" + rowIndex} title="Details">
                            <div className="section">
                              <div className="pading">
                                <div className="d-flex start">
                                  <div className="profile-img">
                                    <img
                                      src={
                                        row.lastInImage === null
                                          ? profile
                                          : row.lastInImage.trim() === ""
                                          ? profile
                                          : row.lastInImage
                                      }
                                      className={
                                        row.lastInImage === null
                                          ? "default"
                                          : row.lastInImage.trim() === ""
                                          ? "default"
                                          : ""
                                      }
                                      width="40"
                                      height="40"
                                      alt={row.lastInUsername}
                                      onError={(e) =>
                                        (e.currentTarget.src = profile)
                                      }
                                    />
                                  </div>
                                  <div className="details">
                                    <div className="name">
                                      {row.lastInUsername}
                                    </div>
                                    <div className="date">{row.lastInDate}</div>
                                  </div>
                                </div>
                                <div className="notes">{row.lastInNotes}</div>
                                <div className="time d-flex start">
                                  <div className="current-status">
                                    <div className="ttl">Original</div>
                                    <div className="time">
                                      {row.firstPunchinTime}
                                    </div>
                                  </div>
                                  <div className="last-status">
                                    <div className="ttl">Updated</div>
                                    <div className="time">
                                      {this.formatDate(row.inTime)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        )}
                        {this.props.isedit.toString().toLowerCase() === "yes" &&
                          row.opens !== "Open" && (
                            <Tab
                              eventKey={"edit-punch" + rowIndex}
                              title="Edit Punch"
                            >
                              <div className="section">
                                <div className="pading">
                                  <div className="pading-wrap d-flex justify-content-between">
                                    <div className="list punch-in">
                                      <div className="ttl">Punch In</div>
                                      <div className="control">
                                        <input
                                          type="time"
                                          id="inTimedupappt"
                                          name="inTimedupappt"
                                          className="html-time-picker"
                                          value={row.inTimedup}
                                          onChange={(e: any) =>
                                            this.intimeChange(e, row, rowIndex)
                                          }
                                          onBlur={(e: any) =>
                                            this.intimeBlur(e, row, rowIndex)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="date-selector">
                                      <div className="ttl">Date</div>
                                      <Form.Group controlId="date-selection">
                                        <Dropdown>
                                          <Dropdown.Toggle id="dropdown-importFrom">
                                            <span className="selected">
                                              {row.ddlInDate}
                                            </span>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <div>
                                              {this.state.punchInDateList.map(
                                                (item: any, index: any) => (
                                                  <Dropdown.Item
                                                    key={index}
                                                    eventKey={item}
                                                    onClick={() =>
                                                      this.ddlInDateSelection(
                                                        item,
                                                        rowIndex
                                                      )
                                                    }
                                                  >
                                                    {item}
                                                  </Dropdown.Item>
                                                )
                                              )}
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Form.Group>
                                    </div>
                                  </div>

                                  <div className="list">
                                    <div className="ttl">Notes</div>
                                    <div className="control">
                                      <Form.Control
                                        className="input-notes"
                                        as="textarea"
                                        value={row.lastInNotesdup}
                                        placeholder="Explanation required..."
                                        onChange={(e: any) =>
                                          this.noteChange(e, row, rowIndex)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="footer d-flex justify-content-end">
                                  <button
                                    type="button"
                                    ref={this.btnEditPunch}
                                    className="btn btn-primary"
                                    onClick={(e: any) =>
                                      this.handleSave(e, row, rowIndex, "In","Yes","In")
                                    }
                                    // onClick={(e: any) =>
                                    //   this.checkAutoTrueUpBreak(e, row, rowIndex, "In")
                                    // }
                                  >
                                    Save
                                  </button>
                                </div>
                                {/* {this.state.autoTrueUpBreakConfirmation && (                                    
                                    <div className="confirmModalNonTable">
                                    <div>
                                    <div className="title">Auto Breaks</div>
                                      <div className="description">Do you want to auto breaks?</div>
                                      <div className="action-link">
                                        <button
                                          type="button"
                                          className="btn wht-bg"
                                          onClick={(e: any) =>
                                            this.warningCloseModel(e, row, rowIndex, "In")
                                          }
                                        >
                                          No
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={(e: any) =>
                                            this.saveAutoTrueUpBreak(e, row, rowIndex, "In")
                                          }
                                        > 
                                        Yes                                        
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                              )} */}
                              </div>
                            </Tab>
                          )}
                        {row.inImage.length > 0 && (
                          <Tab
                            eventKey={"smile-id" + rowIndex}
                            title="Smile iD"
                          >
                            <div className="section">
                              <div className="pading edit-pad">
                                <div className="currentimg">
                                  <MyImage
                                    src={row.inImage}
                                    fallbackSrc={profile}
                                    alt="User SmileId"
                                    id={row.inUniqueno}
                                  ></MyImage>
                                  {/* <img src={row.inImage}  /> */}
                                  {row.inimagestatus === "Pending" && (
                                    <div className="badges red-badges orange-bg">
                                      {row.inimagelevel} %
                                    </div>
                                  )}
                                  {row.inimagestatus === "Pass" && (
                                    <div className="badges red-badges green-bg">
                                      {row.inimagelevel} %
                                    </div>
                                  )}
                                  {row.inimagestatus === "Fail" && (
                                    <div className="badges red-badges red-badges">
                                      {row.inimagelevel} %
                                    </div>
                                  )}
                                </div>
                                <div className="profile-details">
                                  {row.inimagestatus === "Pending" && (
                                    <div className="q-details">
                                      Is this {row.employee}?
                                    </div>
                                  )}
                                  {row.inimagestatus !== "Pending" && (
                                    <div className="q-details">
                                      {row.employee}
                                    </div>
                                  )}
                                  <div className="punch-details">
                                    Punched in at {row?.inImageTime} on{" "}
                                    {row.inDate}
                                  </div>
                                </div>
                              </div>
                              {row.inimagestatus === "Pending" &&
                                this.props.managesmileid
                                  ?.toString()
                                  ?.toLowerCase() === "yes" && (
                                  <div className="footer d-flex justify-content-end">
                                    <button
                                      type="button"
                                      className="btn-danger btn btn-primary"
                                      onClick={(e: any) =>
                                        this.updateSmileIdStatus("No", row, 1)
                                      }
                                    >
                                      No
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-green"
                                      onClick={(e: any) =>
                                        this.updateSmileIdStatus("Yes", row, 1)
                                      }
                                    >
                                      Yes
                                    </button>
                                  </div>
                                )}
                            </div>
                          </Tab>
                        )}
                      </Tabs>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {this.props.isedit.toString().toLowerCase() !== "yes" &&
                  row.lastInNotes.length === 0 &&
                  row.inImage.length === 0 && (
                    <div className="rm-bold">{this.formatDate(row.inTime)}</div>
                  )}
                {this.props.isedit.toString().toLowerCase() === "yes" &&
                  row.opens === "Open" &&
                  row.lastInNotes.length === 0 &&
                  row.inImage.length === 0 && (
                    <div className="rm-bold">{this.formatDate(row.inTime)}</div>
                  )}
              </div>
            )}
            {row.entryType.toLowerCase() === "break" && (
              <div className="d-flex align-items-center">Manual Break</div>
            )}
          </>
        ),
        footer: "Totals",
      },
      {
        dataField: "outTime",
        text: "Out",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <>
            {row.entryType.toLowerCase() !== "break" &&
              row.opens !== "Open" && (
                <div className="d-flex align-items-center">
                  {row.maxCountout > 1 && (
                    <div className="dot margin-right"></div>
                  )}
                  {(this.props.isedit.toString().toLowerCase() === "yes" ||
                    row.lastOutNotes.length > 0 ||
                    row.outImage.length > 0) && (
                    <Dropdown className="more-action grey" alignRight>
                      <Dropdown.Toggle
                        className="btn-outline-primary  more pos-abs"
                        id="dropdown-more"
                        ref={this.refOut}
                      >
                        <div
                          className="d-flex align-items-center"
                          onClick={() =>
                            this.resetPopupData(row, rowIndex, "Out")
                          }
                        >
                          {this.formatDate(row.outTime)}
                          {row.outImage.length > 0 && (
                            <div>
                              {row.outImageStatus === "Pending" && (
                                <div className="orange-bg">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                    />
                                    <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                  </svg>
                                </div>
                              )}
                              {row.outImageStatus === "Pass" && (
                                <div className="grey-bg">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                    />
                                    <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                  </svg>
                                </div>
                              )}
                              {row.outImageStatus === "Fail" && (
                                <div className="red-badges">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.86669 4.00039H4.66669V2.40039H7.86669V4.00039Z"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.2 7.04043H4.8V8.32043C4.8 10.0877 6.23269 11.5204 8 11.5204C9.76731 11.5204 11.2 10.0877 11.2 8.32043V7.04043ZM3.2 5.44043V8.32043C3.2 10.9714 5.34903 13.1204 8 13.1204C10.651 13.1204 12.8 10.9714 12.8 8.32043V5.44043H3.2Z"
                                    />
                                    <path d="M11.0667 3.20039C11.0667 3.64222 10.7085 4.00039 10.2667 4.00039C9.82487 4.00039 9.4667 3.64222 9.4667 3.20039C9.4667 2.75856 9.82487 2.40039 10.2667 2.40039C10.7085 2.40039 11.0667 2.75856 11.0667 3.20039Z" />
                                  </svg>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="pos-name">
                          <Dropdown.Item className="cross-btn" href="">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                fill="#84888C"
                              />
                            </svg>
                          </Dropdown.Item>
                        </div>
                        <Tabs
                          id={"punchOut-tab-" + rowIndex}
                          onSelect={(selectedtab) =>
                            this.resetActiveTab(
                              rowIndex,
                              selectedtab,
                              "Out",
                              row
                            )
                          }
                        >
                          {row.lastOutNotes.length > 0 && (
                            <Tab
                              eventKey={"details" + rowIndex}
                              title="Details"
                            >
                              <div className="section">
                                <div className="pading">
                                  <div className="d-flex start">
                                    <div className="profile-img">
                                      <img
                                        src={
                                          row.lastOutImage === null
                                            ? profile
                                            : row.lastOutImage.trim() === ""
                                            ? profile
                                            : row.lastOutImage
                                        }
                                        className={
                                          row.lastOutImage === null
                                            ? "default"
                                            : row.lastOutImage.trim() === ""
                                            ? "default"
                                            : ""
                                        }
                                        width="40"
                                        height="40"
                                        alt={row.lastOutUsername}
                                        onError={(e) =>
                                          (e.currentTarget.src = profile)
                                        }
                                      />
                                    </div>
                                    <div className="details">
                                      <div className="name">
                                        {row.lastOutUsername}
                                      </div>
                                      <div className="date">
                                        {row.lastOutDate}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="notes">
                                    {row.lastOutNotes}
                                  </div>
                                  <div className="time d-flex start">
                                    <div className="current-status">
                                      <div className="ttl">Original</div>
                                      <div className="time">
                                        {row.firstPunchoutTime}
                                      </div>
                                    </div>
                                    <div className="last-status">
                                      <div className="ttl">Updated</div>
                                      <div className="time">
                                        {this.formatDate(row.outTime)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                          )}
                          {this.props.isedit.toString().toLowerCase() ===
                            "yes" && (
                            <Tab
                              eventKey={"edit-punch" + rowIndex}
                              title="Edit Punch"
                            >
                              <div className="section">
                                <div className="pading">
                                  <div className="pading-wrap d-flex justify-content-between">
                                    <div className="list punch-in">
                                      <div className="ttl">Punch Out</div>
                                      <div className="control">
                                        <input
                                          type="time"
                                          id="outTimedupappt"
                                          name="outTimedupappt"
                                          className="html-time-picker"
                                          value={row.outTimedup}
                                          onChange={(e: any) =>
                                            this.outtimeChange(e, row, rowIndex)
                                          }
                                          onBlur={(e: any) =>
                                            this.outtimeBlur(e, row, rowIndex)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="date-selector">
                                      <div className="ttl">Date</div>
                                      <Form.Group controlId="date-selection">
                                        <Form.Control
                                          disabled
                                          type="text"
                                          value={row.ddlOutDate}
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>

                                  <div className="list">
                                    <div className="ttl">Notes</div>
                                    <div className="control">
                                      <Form.Control
                                        className="input-notes"
                                        as="textarea"
                                        value={row.lastOutNotesdup}
                                        placeholder="Explanation required..."
                                        onChange={(e: any) =>
                                          this.noteOutChange(e, row, rowIndex)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="footer d-flex justify-content-end">
                                  <button
                                    type="button"
                                    ref={this.btnEditPunch}
                                    className="btn btn-primary"
                                    onClick={(e: any) =>
                                      this.handleSave(e, row, rowIndex, "Out","Yes","Out")
                                    }
                                    // onClick={(e: any) =>
                                    //   this.checkAutoTrueUpBreak(e, row, rowIndex, "Out")
                                    // }

                                  >
                                    Save
                                  </button>
                                </div>
                                {/* {this.state.autoTrueUpBreakConfirmation && (                                    
                                    <div className="confirmModalNonTable">
                                    <div>
                                      <div className="title">Auto Breaks</div>
                                      <div className="description">Do you want to auto breaks?</div>
                                      <div className="action-link">
                                        <button
                                          type="button"
                                          className="btn wht-bg"
                                          onClick={(e: any) =>
                                            this.warningCloseModel(e, row, rowIndex, "Out")
                                          }
                                        >
                                          No
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={(e: any) =>
                                            this.saveAutoTrueUpBreak(e, row, rowIndex, "Out")
                                          }
                                        > 
                                        Yes                                        
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                              )} */}
                              </div>
                            </Tab>
                          )}
                          {row.outImage.length > 0 && (
                            <Tab
                              eventKey={"smile-id" + rowIndex}
                              title="Smile iD"
                            >
                              <div className="section">
                                <div className="pading edit-pad">
                                  <div className="currentimg">
                                    <MyImage
                                      src={row.outImage}
                                      fallbackSrc={profile}
                                      alt="User SmileId"
                                      id={row.outUniqueno}
                                    ></MyImage>
                                    {/* <img src={row.outImage} alt="User SmileId" /> */}
                                    {row.outImageStatus === "Pending" && (
                                      <div className="badges red-badges orange-bg">
                                        {row.outImageLevel} %
                                      </div>
                                    )}
                                    {row.outImageStatus === "Pass" && (
                                      <div className="badges red-badges green-bg">
                                        {row.outImageLevel} %
                                      </div>
                                    )}
                                    {row.outImageStatus === "Fail" && (
                                      <div className="badges red-badges red-badges">
                                        {row.outImageLevel} %
                                      </div>
                                    )}
                                  </div>
                                  <div className="profile-details">
                                    {row.outImageStatus === "Pending" && (
                                      <div className="q-details">
                                        Is this {row.employee}?
                                      </div>
                                    )}
                                    {row.outImageStatus !== "Pending" && (
                                      <div className="q-details">
                                        {row.employee}
                                      </div>
                                    )}
                                    <div className="punch-details">
                                      Punched out at {row.outImageTime} on{" "}
                                      {row.outDate}
                                    </div>
                                  </div>
                                </div>
                                {row.outImageStatus === "Pending" &&
                                  this.props.managesmileid
                                    .toString()
                                    .toLowerCase() === "yes" && (
                                    <div className="footer d-flex justify-content-end">
                                      <button
                                        type="button"
                                        className="btn-danger btn btn-primary"
                                        onClick={(e: any) =>
                                          this.updateSmileIdStatus("No", row, 2)
                                        }
                                      >
                                        No
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={(e: any) =>
                                          this.updateSmileIdStatus(
                                            "Yes",
                                            row,
                                            2
                                          )
                                        }
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </Tab>
                          )}
                        </Tabs>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {this.props.isedit.toString().toLowerCase() !== "yes" &&
                    row.lastOutNotes.length === 0 &&
                    row.outImage.length === 0 && (
                      <div className="rm-bold">
                        {this.formatDate(row.outTime)}
                      </div>
                    )}
                </div>
              )}{" "}
          </>
        ),
        footer: "",
      },
      {
        dataField: "breakTime",
        text: "Break",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <>
            {row.entryType.toLowerCase() === "break" && (
              <div className="d-flex align-items-center">
                {row.maxBreakcount > 1 && (
                  <div className="dot margin-right"></div>
                )}
                {(this.props.isedit.toString().toLowerCase() === "yes" ||
                  row.lastBreakNotes.length > 0) && (
                  <Dropdown className="more-action grey" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary  more pos-abs"
                      id="dropdown-more"
                    >
                      <div
                        className="d-flex align-items-center"
                        onClick={() =>
                          this.resetPopupData(row, rowIndex, "Break")
                        }
                      >
                        {row.breakTime}
                        {row.breakTime?.includes("-") && (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={"tooltip-Assign"}>
                                A negative break indicates that time has been
                                added to an employee's payable hours. Entering a
                                positive break deducts time from the total HH:MM
                                paid.
                              </Tooltip>
                            }
                          >
                            <span style={{ paddingLeft: 5 }}>
                              <InfoTooltip />{" "}
                            </span>
                          </OverlayTrigger>
                        )}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="pos-name">
                        <Dropdown.Item className="cross-btn" href="">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                              fill="#84888C"
                            />
                          </svg>
                        </Dropdown.Item>
                      </div>
                      <Tabs
                        id={"punchBreak-tab-" + rowIndex}
                        onSelect={(selectedtab) =>
                          this.resetActiveTab(
                            rowIndex,
                            selectedtab,
                            "Break",
                            row
                          )
                        }
                      >
                        {row.lastBreakNotes.length > 0 && (
                          <Tab eventKey={"details" + rowIndex} title="Details">
                            <div className="section">
                              <div className="pading">
                                <div className="d-flex start">
                                  <div className="profile-img">
                                    <img
                                      src={
                                        row.breakUserImage === null
                                          ? profile
                                          : row.breakUserImage.trim() === ""
                                          ? profile
                                          : row.breakUserImage
                                      }
                                      className={
                                        row.breakUserImage === null
                                          ? "default"
                                          : row.breakUserImage.trim() === ""
                                          ? "default"
                                          : ""
                                      }
                                      width="40"
                                      height="40"
                                      alt={row.lastBreakUser}
                                      onError={(e) =>
                                        (e.currentTarget.src = profile)
                                      }
                                    />
                                  </div>
                                  <div className="details">
                                    <div className="name">
                                      {row.lastBreakUser}
                                    </div>
                                    <div className="date">
                                      {row.lastBreakDate}
                                    </div>
                                  </div>
                                </div>
                                <div className="notes">
                                  {row.lastBreakNotes}
                                </div>
                                <div className="time d-flex start">
                                  <div className="current-status">
                                    <div className="ttl">Original</div>
                                    <div className="time">
                                      {row.firstBreakHrs
                                        .toString()
                                        .replace(".", ":")}
                                    </div>
                                  </div>
                                  <div className="last-status">
                                    <div className="ttl">Updated</div>
                                    <div className="time">
                                      {row.breakTime
                                        .toString()
                                        .replace(".", ":")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        )}
                        {this.props.isedit.toString().toLowerCase() ===
                          "yes" && (
                          <Tab
                            eventKey={"edit-punch" + rowIndex}
                            title="Edit Break"
                          >
                            <div className="section">
                              <div className="pading">
                                <div className="list">
                                  <div className="ttl">Break Duration</div>
                                  <div className="d-flex modf-input align-items-center">
                                    <Form.Control
                                      type="text"
                                      pattern="\d*"
                                      maxLength={2}
                                      placeholder="HH"
                                      id="HH"
                                      value={row.breakHH}
                                      onBlur={(event: any) =>
                                        this.blurChangebreakHH(
                                          event,
                                          row,
                                          rowIndex
                                        )
                                      }
                                      onChange={(event: any) =>
                                        this.changebreakHH(event, row, rowIndex)
                                      }
                                    />
                                    <span>:</span>
                                    <Form.Control
                                      type="text"
                                      pattern="\d*"
                                      maxLength={2}
                                      placeholder="MM"
                                      id="MM"
                                      value={row.breakMM}
                                      onBlur={(event: any) =>
                                        this.blurChangebreakMM(
                                          event,
                                          row,
                                          rowIndex
                                        )
                                      }
                                      onChange={(event: any) =>
                                        this.changebreakMM(event, row, rowIndex)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="list">
                                  <div className="ttl">Break Details</div>
                                  <div className="control">
                                    <Form.Control
                                      className="input-notes"
                                      as="textarea"
                                      value={row.lastOutNotesdup}
                                      placeholder="Explanation required..."
                                      onChange={(e: any) =>
                                        this.noteOutChange(e, row, rowIndex)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="footer d-flex justify-content-end">
                                <button
                                  type="button"
                                  ref={this.btnEditBreak}
                                  className="btn btn-primary"
                                  onClick={(e: any) =>
                                    this.updateBreak(e, row, rowIndex)
                                  }
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </Tab>
                        )}
                      </Tabs>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {this.props.isedit.toString().toLowerCase() !== "yes" &&
                  row.lastBreakNotes.length === 0 && (
                    <div className="rm-bold">{row.breakTime}</div>
                  )}
              </div>
            )}
            {row.entryType.toLowerCase() !== "break" && (
              <div className="rm-bold">{row.breakTime}</div>
            )}
          </>
        ),
        footer: (columnData: any[]) => {
          let total = 0;
          // eslint-disable-next-line array-callback-return
          columnData.map((data: any) => {
            if (data !== null) {
              if (data !== undefined) {
                if (data.trim().length > 0) {
                  if (data.includes(":")) {
                    let subTotal = 0;
                    const dataArray = data.split(":");
                    let HH = Number(dataArray[0]);
                    let MM = Number(dataArray[1]);
                    if (
                      dataArray[0].includes("-") ||
                      dataArray[1].includes("-")
                    ) {
                      HH = HH < 0 ? HH * -1 : HH;
                      MM = MM < 0 ? MM * -1 : MM;
                      subTotal = (HH * 60 + MM) * -1;
                    } else {
                      subTotal = HH * 60 + MM;
                    }
                    total = total + subTotal;
                  }
                }
              }
            }
          });
          return this.FormatHoursMinutesBreak(total);
        },
      },
      {
        dataField: "total",
        text: "Total",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <>{row.entryType.toLowerCase() !== "break" && <>{row.total}</>}</>
        ),
        footer: (columnData: any[]) => {
          let total = 0;
          let dataArr: any[] = [];
          dataArr = Object.assign(
            [],
            JSON.parse(JSON.stringify(this.state.actualDetails))
          );

          // eslint-disable-next-line array-callback-return
          columnData.map((data: any) => {
            if (data !== null) {
              if (data !== undefined) {
                if (data.trim().length > 0) {
                  if (data.includes(":")) {
                    const dataArray = data.split(":");
                    let HH = Number(dataArray[0]);
                    let MM = Number(dataArray[1]);
                    total = total + (HH * 60 + MM);
                  }
                }
              }
            }
          });

          dataArr = dataArr.filter((item) => item.entryType === "Break");
          let breakTime = 0;
          if (dataArr.length > 0) {
            breakTime = dataArr
              .map((item) =>
                Utils.getMinutesFromHHMMWithPositiveNegative(item?.breakTime)
              )
              .reduce((a, b) => a + b);
          }

          return this.FormatHoursMinutesDuration(total, +breakTime);
        },
      },
      {
        dataField: "positionName",
        text: "Position",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip>
            <div className="position-name">{cell}</div>
          </EllipsisWithTooltip>
        ),
      },

      {
        dataField: "",
        text: "",
        footer: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="d-flex flex-wrap">
            {row.apo.length > 0 && (
              <>
                {" "}
                <div className="red-badges orange-bg">{row.apo}</div>{" "}
              </>
            )}
            {row.manuals.length > 0 && (
              <>
                {" "}
                <div className="red-badges blue-bg">{row.manuals}</div>{" "}
              </>
            )}
            {row.opens !== "" && row.opens !== null && (
              <>
                {" "}
                <div className="red-badges">{row.opens}</div>{" "}
              </>
            )}
            {row.otf !== "" && row.otf !== null && (
              <>
                {" "}
                <div className="red-badges blue-bg">{row.otf}</div>{" "}
              </>
            )}
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="d-flex" id="performance-child-table-comments">
            {row.countNotes > 0 && (
              <Dropdown className="more-action notes-btn"
              onMouseLeave={this.hideDropdown}
              >
                <Dropdown.Toggle
                  className="btn-outline-primary  more"
                  id="dropdown-more"
                >
                  <div className="d-flex">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                      <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                    </svg>
                    {/* <span>{row.countNotes}</span> */}
                    <span>{row.laborActualCommentRes.length}</span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu renderOnMount>
                  {/* <div className="pos-name">
                    <Dropdown.Item className="cross-btn" href="">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z" fill="#84888C" />
                      </svg>
                    </Dropdown.Item>
                  </div> */}
                  <div className="notes-sec">
                    {/* <div className="notes-ttl">Edit History</div> */}
                    <div className="notes-list">
                      {row.laborActualCommentRes.map((item: any, idx: any) => (
                        <div className="comment-list">
                          <div className="d-flex profile-details">
                            <div className="profile-img">
                              <img
                                src={
                                  item.imagepath === null
                                    ? profile
                                    : item.imagepath.trim() === ""
                                    ? profile
                                    : item.imagepath
                                }
                                className={
                                  item.imagepath === null
                                    ? "default"
                                    : item.imagepath.trim() === ""
                                    ? "default"
                                    : ""
                                }
                                width="40"
                                height="40"
                                alt={item.username}
                                onError={(e) => (e.currentTarget.src = profile)}
                              />
                            </div>
                            <div>
                              <div className="main-ttl">{item.username}</div>
                              <div className="sub-ttl">
                                {Utils.getDateTimeMddyyFromUtc(
                                  item.commentdate
                                )}
                              </div>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <div className="d-flex">
                                <div className="detail-sec">
                                  <div className="comments">
                                    {item.comments}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        ),
        footer: "",
      },
      {
        dataField: "",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <>
            {row.isActualentry !== "No" && row.opens !== "Open" && (
              <div className="d-flex justify-content-end">
                <div className="custom-split">
                  {row.entryType !== "Break" &&
                    this.props.isedit.toString().toLowerCase() === "yes" && (
                      <Dropdown
                        className={
                          row.actions === "split" || row.actions === "change"
                            ? "more-action show"
                            : "more-action"
                        }
                        alignRight
                      >
                        <Dropdown.Toggle
                          className="btn-outline-primary  more"
                          id="dropdown-more"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <>
                            <Dropdown.Item
                              onClick={() => {
                                this.actionButton(row, rowIndex, "split");
                              }}
                            >
                              Split
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                this.actionButton(row, rowIndex, "change");
                              }}
                            >
                              Change Position
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                this.actionButton(row, rowIndex, "Associate");
                              }}
                            >
                              Associate
                            </Dropdown.Item>
                            {row.shiftStatus === "Manual" && (
                              <Dropdown.Item
                                onClick={() => {
                                  this.actionButton(
                                    row,
                                    rowIndex,
                                    "deleteManualshift"
                                  );
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            )}
                          </>
                          {/* <Dropdown.Item onClick={() => { this.actionButton(row, rowIndex, "delete") }}>Delete</Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  {row.actions === "split" && (
                    <OutsideClickHandler
                      onOutsideClick={this.handleOutsideClick}
                    >
                      <div className="d-flex justify-content-end drop-menu">
                        <div className="add-entry height-425">
                          <div className="re-assign-modal">
                            <div className="">
                              <div className="title height-50 d-flex align-items-center">
                                <div className="mr-auto">Split Shift</div>
                                <Dropdown.Item
                                  href=""
                                  onClick={() => {
                                    this.actionButton(row, rowIndex, "");
                                  }}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                      fill="#84888C"
                                    />
                                  </svg>
                                </Dropdown.Item>
                              </div>
                              <div className="entry-body">
                                <Form.Group as={Row} controlId="time-picker1">
                                  <Col sm="6">
                                    <Form.Label>Split Time</Form.Label>
                                    <input
                                      type="time"
                                      id="appt"
                                      name="appt"
                                      className="html-time-picker"
                                      onChange={(e: any) =>
                                        this.splitTimeChange(e, row, rowIndex)
                                      }
                                    />
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="statistic">
                                  <Col sm="6">
                                    <Form.Label>Department</Form.Label>
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-department">
                                        <EllipsisWithTooltip placement="bottom">
                                          {row.splitDepartment}
                                        </EllipsisWithTooltip>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {this.state.alldepartmentlist.map(
                                          (item: any, idx: any) => (
                                            <Dropdown.Item
                                              key={item.departmentName}
                                              eventKey={item.departmentName}
                                              onClick={(event: any) =>
                                                this.departmentSplitChange(
                                                  event,
                                                  row,
                                                  item,
                                                  rowIndex
                                                )
                                              }
                                            >
                                              {item.departmentName}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                  <Col sm="6">
                                    <Form.Label>Position</Form.Label>
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-position">
                                        <EllipsisWithTooltip placement="bottom">
                                          {row.splitPosition}
                                        </EllipsisWithTooltip>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {this.state.allpositionlist.map(
                                          (item: any, idx: any) => (
                                            <Dropdown.Item
                                              key={item.positionName}
                                              eventKey={item.positionName}
                                              onClick={(event: any) =>
                                                this.positionSplitChange(
                                                  event,
                                                  row,
                                                  item,
                                                  rowIndex
                                                )
                                              }
                                            >
                                              {item.positionName}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>Notes</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Explanation required..."
                                    value={row.splitNotes}
                                    id={"txtsplitnotes" + rowIndex}
                                    onChange={(event: any) =>
                                      this.noteSplitChange(event, row, rowIndex)
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="action-link">
                                <Button
                                  className="btn btn-primary"
                                  ref={this.btnSplitShift}
                                  id={"btnsplit" + rowIndex}
                                  onClick={(e: any) => this.saveSplitEntry(row,"Yes","Split")}
                                >
                                  Split Shift
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </OutsideClickHandler>
                  )}

                  {row.actions === "change" && (
                    <OutsideClickHandler
                      onOutsideClick={this.handleOutsideClick}
                    >
                      <div className="d-flex justify-content-end drop-menu">
                        <div className="add-entry height-346">
                          <div className="re-assign-modal">
                            <div className="">
                              <div className="title height-50 d-flex align-items-center">
                                <div className="mr-auto">Change Position</div>
                                <Dropdown.Item
                                  className="cross-btn"
                                  href=""
                                  onClick={() => {
                                    this.actionButton(row, rowIndex, "");
                                  }}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                      fill="#84888C"
                                    />
                                  </svg>
                                </Dropdown.Item>
                              </div>
                              <div className="entry-body">
                                <Form.Group as={Row} controlId="statistic">
                                  <Col sm="6">
                                    <Form.Label>Department</Form.Label>
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-department">
                                        <EllipsisWithTooltip placement="bottom">
                                          {row.splitDepartment}
                                        </EllipsisWithTooltip>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {this.state.alldepartmentlist.map(
                                          (item: any, idx: any) => (
                                            <Dropdown.Item
                                              key={item.departmentName}
                                              eventKey={item.departmentName}
                                              onClick={(event: any) =>
                                                this.departmentSplitChange(
                                                  event,
                                                  row,
                                                  item,
                                                  rowIndex
                                                )
                                              }
                                            >
                                              {item.departmentName}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                  <Col sm="6">
                                    <Form.Label>Position</Form.Label>
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-position">
                                        <EllipsisWithTooltip placement="bottom">
                                          {row.splitPosition}
                                        </EllipsisWithTooltip>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {this.state.allpositionlist.map(
                                          (item: any, idx: any) => (
                                            <Dropdown.Item
                                              key={item.positionName}
                                              eventKey={item.positionName}
                                              onClick={(event: any) =>
                                                this.positionSplitChange(
                                                  event,
                                                  row,
                                                  item,
                                                  rowIndex
                                                )
                                              }
                                            >
                                              {item.positionName}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>Notes</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Explanation required..."
                                    value={row.splitNotes}
                                    id={"txtchangenotes" + rowIndex}
                                    onChange={(event: any) =>
                                      this.noteSplitChange(event, row, rowIndex)
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="action-link">
                                <Button
                                  className="btn btn-primary"
                                  ref={this.btnChangePostion}
                                  id={"btnchange" + rowIndex}
                                  onClick={(e: any) =>
                                    this.saveChangePosition(row, rowIndex,"Yes","ChangePosition")
                                  }
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </OutsideClickHandler>
                  )}
                  {row.actions === "Associate" && (
                    <OutsideClickHandler
                      onOutsideClick={this.handleOutsideClick}
                    >
                      <div className="d-flex justify-content-end drop-menu">
                        <div className="add-entry ">
                          <div className="re-assign-modal">
                            <div className="">
                              <div className="title height-50 d-flex align-items-center">
                                <div className="mr-auto">Associate Day</div>
                                <Dropdown.Item
                                  className="cross-btn"
                                  href=""
                                  onClick={(e: any) => {
                                    this.actionButton(row, rowIndex, "");
                                  }}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                      fill="#84888C"
                                    />
                                  </svg>
                                </Dropdown.Item>
                              </div>
                              <div className="entry-body">
                                <Form.Group as={Row} controlId="statistic">
                                  <Col sm="6">
                                    {/* <Form.Label>Associate</Form.Label> */}
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-department1">
                                        <EllipsisWithTooltip placement="bottom">
                                          {/* {row.splitDepartment} */}
                                          {row.assocaiteedDate}
                                        </EllipsisWithTooltip>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {this.getAssociateDateList(row).map(
                                          (item: any, idx: any) => (
                                            <Dropdown.Item
                                              key={item}
                                              eventKey={item}
                                              onClick={(e: any) =>
                                                this.assocaiteDateChange(
                                                  row,
                                                  item,
                                                  rowIndex
                                                )
                                              }
                                            >
                                              {item}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>Notes</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Explanation required..."
                                    value={row.assocaiteedDateNotes}
                                    id={"txtchangenotes" + rowIndex}
                                    onChange={(e: any) =>
                                      this.assocaitedNotesChange(
                                        e,
                                        row,
                                        rowIndex
                                      )
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="action-link">
                                <Button
                                  className="btn btn-primary"
                                  //   ref={this.btnChangeShift}
                                  id={"btnchange" + rowIndex}
                                  onClick={() =>
                                    this.saveAssocatedDateChange(row, rowIndex,"Yes","Associate")
                                  }
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </OutsideClickHandler>
                  )}

                  {row.actions === "deleteManualshift" &&
                    this.formatColumnManualDelete(cell, row, rowIndex)}

                  {this.props.isedit.toString().toLowerCase() === "yes" &&
                    row.entryType.toLowerCase() === "break" && (
                      <Dropdown className="more-action" alignRight>
                        <Dropdown.Toggle
                          className="btn-outline-primary  more"
                          id="dropdown-more"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {this.props.isedit.toString().toLowerCase() ===
                            "yes" &&
                            row.entryType === "Break" && (
                              <Dropdown.Item
                                onClick={() => {
                                  this.deleteBreak(row,"Yes","DeleteBreak");
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                </div>
              </div>
            )}
          </>
        ),
        //footerFormatter: getAddBreakFooter
      },
    ];

    return (
      <>
        <RouteLeavingGuard
          when={this.state.isDataChange}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
            if (this.state.isDataChange) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />

        <div className="">{}</div>
        <div>
          {this.props.opens === "Open" && (
            // <div className="OverLayer"></div>
            <div className=""></div>
          )}

          {this.state.pageloader ? (
            <ReactPageLoader useas={"performanceSlideoutActualDetails"} />
          ) : (
            <div>
              <div className="actual-header d-flex align-items-center">
                <div className="actual-details-ttl mr-auto">Punch Details</div>
                {!this.props.isemployeeSlideOut && (
                  <Button
                    className="btn-outline-primary"
                    onClick={(e) =>
                      this.props.loadEmployeeDetails(e, this.props.userName)
                    }
                  >
                    Employee Details
                  </Button>
                )}
              </div>
              <div className="actual-details timesheet performance-slideout-child">
                <BootstrapTable
                  id={this.state.mainrowIndex + "ActualTable"}
                  keyField="rowno"
                  data={this.state.actualDetails}
                  columns={columns}
                  hover
                />
                {isActualExist()}
                <div className="add-break-section d-flex justify-content-end">
                  {isBreak === false &&
                    this.props.noShow === false &&
                    this.props.isedit.toString()?.toLowerCase() === "yes" && (
                      <Dropdown className="more-action add-entry" alignRight>
                        <Dropdown.Toggle
                          className="btn-outline-primary more"
                          id="addbreakdropdown-more"
                          ref={this.refAddBreak}
                        >
                          Add Break
                        </Dropdown.Toggle>
                        <Dropdown.Menu rootCloseEvent="click">
                          <div className="pos-name">
                            <Dropdown.Item className="cross-btn" href="">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                                  fill="#84888C"
                                />
                              </svg>
                            </Dropdown.Item>
                          </div>
                          <div className="re-assign-modal">
                            <div className="">
                              <div className="title">Add Manual Break</div>
                              <div className="entry-body">
                                <Form.Group as={Row} controlId="time-picker">
                                  <Col sm="6">
                                    <Form.Label>Break Duration</Form.Label>
                                    <div className="d-flex modf-input align-items-center">
                                      <Form.Control
                                        type="text"
                                        pattern="\d*"
                                        maxLength={2}
                                        placeholder="HH"
                                        id="HH"
                                        value={this.state.addEntry.breakHH}
                                        onBlur={(event: any) =>
                                          this.breakHHBlur(event)
                                        }
                                        onChange={(event: any) =>
                                          this.breakHHChange(event)
                                        }
                                        autoComplete="off"
                                      />
                                      <span>:</span>
                                      <Form.Control
                                        type="text"
                                        pattern="\d*"
                                        maxLength={2}
                                        placeholder="MM"
                                        id="MM"
                                        value={this.state.addEntry.breakMM}
                                        onBlur={(event: any) =>
                                          this.breakMMBlur(event)
                                        }
                                        onChange={(event: any) =>
                                          this.breakMMChange(event)
                                        }
                                        autoComplete="off"
                                      />
                                    </div>
                                  </Col>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>Break Details</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Explanation required..."
                                    value={this.state.addEntry.newNotes}
                                    onChange={(event: any) =>
                                      this.addNewEntryNotesChange(event)
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="action-link">
                                <Button
                                  className="btn btn-primary"
                                  ref={this.btnSaveBreak}
                                  onClick={(e: any) => this.saveBreak("Yes","AddBreak")}
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                </div>
                {this.state.isDataChange && (
                  <div className="fixed-action">
                    <div className="d-flex align-content-center flex-wrap pos-rel ">
                      <div className="mr-auto message">
                        {" "}
                        <span>You have unsaved changes</span>{" "}
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary btn-discard"
                        onClick={this.handleDiscard}
                      >
                        {" "}
                        Discard{" "}
                      </button>
                      <button type="button" className="btn btn-primary">
                        {" "}
                        Save Changes{" "}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
