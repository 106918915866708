import React from "react";
import { Button, Col, Container, Dropdown, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../Common/StaticArrays";
import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import { FiChevronDown } from "react-icons/fi";
import Calendar from 'react-calendar';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { resolve, reject } from "q";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import {ChieldLocationSlideOut} from "./ChieldLocationSlideOut";
import { LocationAdded } from "./LocationAdded";
import { faBullseye, faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import { FacilityMaintenance } from "../../Common/Services/FacilityMaintenance";
import { IFacilityMaintenanceListRequest,IGetProceureDetailsRequestDto } from "../../Common/Contracts/IMaintenanceSchedule";
import { confirmAlert } from "react-confirm-alert";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";

export class MaintenanceLocationSlideOut extends React.Component<any, any> {
  
  private refLocationAdded: any;
  private checkAllRef: any;
  
    constructor(props: any) {     
        super(props);    
        this.refLocationAdded = React.createRef();   
        this.checkAllRef = React.createRef();   
        this.state = {
           
            roomTypeData:[],
            locationChieldData:[],  
            locationControl:[],                
            tableExpended: [],
            isExpand: false,
            expendedRow: {},
            isBulkSelect: false, 
            tableDataDup: [],            
        };
      }

      componentDidMount() {
        // let filterroomData=this.state.roomTypeData.filter(
        //   (x: any) => x.type.toLowerCase()=="roomtype"
        // );
       

        // this.setState({roomTypeData:filterroomData});
        this.GetMLocationDetails("componentDidMount");
        this.GetAllMLocationDetails("componentDidMount");
        

      }

      GetMLocationDetails(type:any) {
        
        //this.setState({ isworkLoader: true });
        let request = {} as IGetProceureDetailsRequestDto;   
        request.hotelid = Number(this.props.parentState.hidValue);
        request.gblId= this.props.parentState.scheduleData[0]?.id;
        let storage = JSON.parse(localStorage.getItem("storage")!);   
        let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);   
       request.loginUser = Number(userUniqueID);
        
    
        FacilityMaintenance.GetMLocationDetails(request)
          .then(async (result: any | null) => {
            // debugger;
            if (result != null && result.length > 0) {

           result.map((item: any) => {   
                item.action = "Expended";
                item.ddlDeptdetailsRef= React.createRef();
              });


              let filterroomData=result.filter(
                   (x: any) => x.type.toLowerCase()=="roomtype"
                    );
       
             this.setState({roomTypeData:filterroomData,locationChieldData:result,},()=>{
              // debugger;
              this.props.countUpdate(this.state.locationChieldData,"notechange");
             // if(type==="onDiscardChanges"){
                if (this.refLocationAdded.current !== null){               
                  this.refLocationAdded.current.upatedLocationList(this.state.locationChieldData,this.state.roomTypeData);               
                }
               // }
              
             });
            } else {
              this.setState({roomTypeData:[],locationChieldData:[]},()=>{
                this.props.countUpdate(this.state.locationChieldData,"notechange");
                // if(type==="onDiscardChanges"){
                //   if (this.refLocationAdded.current !== null){               
                //     this.refLocationAdded.current.upatedLocationList(this.state.locationChieldData,this.state.roomTypeData);               
                //   }
                //   }
                if (this.refLocationAdded.current !== null){               
                  this.refLocationAdded.current.upatedLocationList(this.state.locationChieldData,this.state.roomTypeData);               
                }


              });
            }   
    
    
            resolve();  
          })
          .catch((err) => {
          
            Utils.toastError(`Server Error, ${err}`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "LocationShudleSldieOut",
            });
    
            reject();
          });
      }

      GetAllMLocationDetails(type:any) {
        this.setState({ isworkLoader: true });
        let request = {} as IGetProceureDetailsRequestDto;   
        request.hotelid = Number(this.props.parentState.hidValue);
        request.type = "AllLocationControll";
        request.gblId= this.props.parentState.scheduleData[0]?.id;
        let storage = JSON.parse(localStorage.getItem("storage")!);   
        let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);   
       request.loginUser = Number(userUniqueID);
        
    
        FacilityMaintenance.GetAllMLocationDetails(request)
          .then(async (result: any | null) => {
          
            if (result != null && result.length > 0) {
              result.map((item: any) => {   
                item.ddlDeptdetailsRef= React.createRef();
              });
              result.forEach(cntelement => { 
              
                const halfCheckvalueno = result.filter((xvalue) => xvalue.selectID === cntelement.selectID
                && xvalue.type.toLowerCase() !== "roomtype" );

                const totalcount = result.filter((xvalue) => xvalue.selectID === cntelement.selectID
                && xvalue.type.toLowerCase() !== "roomtype" && xvalue.isChecked === "yes");


                if(halfCheckvalueno.length!==totalcount.length){
                  const halfCheckparent = result.findIndex((xvalue) => xvalue.selectID === cntelement.selectID
                && xvalue.type.toLowerCase() === "roomtype" && xvalue.isChecked === "yes");
                 if(halfCheckparent !==-1){
                  result[halfCheckparent].isHalfCheck = true;
                 }

                }
                if(halfCheckvalueno.length===totalcount.length){
                  const halfCheckparent = result.findIndex((xvalue) => xvalue.selectID === cntelement.selectID
                && xvalue.type.toLowerCase() === "roomtype" && xvalue.isChecked === "yes");
                 if(halfCheckparent !==-1){
                  result[halfCheckparent].isHalfCheck = false;
                 }

                }


               });

             





             this.setState({locationControl:result},()=>{
              if (this.refLocationAdded.current !== null){               
                this.refLocationAdded.current.upatedLocationList(this.state.locationChieldData,this.state.roomTypeData);               
              }
              if(type==="onDiscardChanges"){
              this.discardChanges();
              this.handleFooterClose();
              }
             });
            } else {
              this.setState({locationControl:[]},()=>{
                if (this.refLocationAdded.current !== null){               
                  this.refLocationAdded.current.upatedLocationList(this.state.locationChieldData,this.state.roomTypeData);               
                }
                if(type==="onDiscardChanges"){
                  this.discardChanges();
                  this.handleFooterClose();
                  }
              });
              
            }   
    
    
            resolve();
          })
          .catch((err) => {
          
            Utils.toastError(`Server Error, ${err}`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "LocationShudleSldieOut",
            });
    
            reject();
          });
      }
    
      handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => { 
        // debugger;         
        if (isExpand) {
        
          row.action = "Expended";
          this.setState({ isExpand: true });        
          this.setState(() => ({
            tableExpended: [...this.state.tableExpended, row.id],
          }));
          this.setState({ expendedRow: row });
          // debugger;
        } else {
          
          row.action = "";
          this.setState({ isExpand: false });
          this.setState(() => ({
            tableExpended: this.state.tableExpended.filter(
              (x: any) => x !== row.id
            ),
          }));
        }
      };

      onOutsideClick = (e: any) => {
       
        if (this.state.isExpand) {
          this.handleOnExpand(this.state.expendedRow, false, "", "");
        }
      };
    
      locationAdded=(item:any,type:any)=>{
       
         let cnt=0;    
        if(type==="Add")
        {
          let roomData:any=[];
          let locationChieldData:any=[];             
          let tbllocationChieldData= _.cloneDeep([...this.state.locationChieldData]); 
          let tblroomTypeData= _.cloneDeep([...this.state.roomTypeData]);

          let tempData:any={};
          let tempRoomData:any={};
          item.forEach(element => {     
            
            if(element.isChecked==="yes" && element.type.toLowerCase()=="roomtype"){
               tempData={};
               tempData.name=element.name;
               tempData.selectID=element.selectID;
               tempData.type=element.type;
               tempData.isChecked=element.isChecked;
               tempData.id=element.id;
               tempData.roomID=element.roomID;
               tempData.roomCount=0;
               //item.ddlDeptdetailsRef= React.createRef();
               
               const index = tblroomTypeData.findIndex((xvalue) => xvalue.selectID === element.selectID && xvalue.type.toLowerCase()=="roomtype");
               if(index==-1){
                tblroomTypeData.push(tempData);
               }
             
               roomData.push(tempData);

            }

            if(element.isChecked==="yes" && element.type.toLowerCase()=="location"){
              tempRoomData={};
              tempRoomData.name=element.name;
              tempRoomData.selectID=element.selectID;
              tempRoomData.type=element.type;
              tempRoomData.isChecked=element.isChecked;
              tempRoomData.id=element.id;
              tempRoomData.roomID=element.roomID;
              tempRoomData.roomCount=0;

              const index = tbllocationChieldData.findIndex((xvalue) => xvalue.roomID === element.roomID && xvalue.type.toLowerCase()=="location");
              if(index ==-1){
                tbllocationChieldData.push(tempRoomData);
              }

              locationChieldData.push(tempRoomData);
             
              
            }

            });

           

           



            tblroomTypeData.forEach(cntelement => {  

              let countRoom= tbllocationChieldData.filter((row) => row.selectID==cntelement.selectID && row.type.toLowerCase()=="location");
               const index = tblroomTypeData.findIndex((row) => row.selectID==cntelement.selectID);
               if(index!=-1){
               
                tblroomTypeData[index].roomCount=countRoom.length;
               }

               });
         
            
               this.setState({locationChieldData:[]},()=>{

                this.setState({locationChieldData:tbllocationChieldData,roomTypeData:tblroomTypeData},()=>{
                  // this.setState({locationChieldData:locationChieldData,roomTypeData:roomData},()=>{
                     this.props.countUpdate(this.state.locationChieldData,"Add");
                     if (this.refLocationAdded.current !== null){
               
                       this.refLocationAdded.current.upatedLocationList(this.state.locationChieldData,this.state.roomTypeData);
                      
                     }
                   });


               })
              
          }else{

            let locationChieldData=_.cloneDeep(this.state.locationChieldData);//[...this.state.locationChieldData];
            let roomData=_.cloneDeep(this.state.roomTypeData);    //[...this.state.roomTypeData];

            item.forEach(element => {

              if(element.isChecked==="no" && element.type.toLowerCase()==="roomtype"){
              const index = roomData.findIndex((xvalue) => xvalue.selectID === element.selectID);
              if(index!=-1){
              roomData.splice(index, 1);  
              }  
              }
              if(element.isChecked==="no" && element.type.toLowerCase()=="location"){
              const index = locationChieldData.findIndex((xvalue) => xvalue.roomID === element.roomID);
              if(index!=-1){
              locationChieldData.splice(index, 1); 
              }
              }            
        
              });


              roomData.forEach(cntelement => {  

                let countRoom= locationChieldData.filter((row) => row.selectID==cntelement.selectID && row.type.toLowerCase()!=="roomtype");
                 const index = roomData.findIndex((row) => row.selectID==cntelement.selectID);
                 if(index!=-1){
                 
                   roomData[index].roomCount=countRoom.length;
                 }
  
                 });
  


              this.setState({locationChieldData:locationChieldData,roomTypeData:roomData},()=>{
                this.props.countUpdate(this.state.locationChieldData,"Add");
                if (this.refLocationAdded.current !== null){
        
                  this.refLocationAdded.current.upatedLocationList(this.state.locationChieldData,this.state.roomTypeData);
                 
                }
              });



           

          }
         
      }

      // handleBulkSelect = () => {
      //   this.setState({isBulkSelect: true});
      // }

      discardChanges=()=>{       
        if (this.refLocationAdded.current !== null){
        
          this.refLocationAdded.current.discardChanges();
         
        }
      }

      handleBulkSelect(e: any, row: any, rowIndex: any) {
       // debugger;
        this.setState(() => ({
          tableExpended: [],
        }));
        this.setState({ isExpand: false });
       // row.action = "";
        // let data = Object.assign(
        //   [],
        //   JSON.parse(JSON.stringify(this.state.roomTypeData))
        // );
        let data =_.cloneDeep([...this.state.roomTypeData]);


        // eslint-disable-next-line array-callback-return
        data.map((item: any) => {
          item.isCheck = false;
        });
        this.setState({ tableDataDup: data });
        this.setState(
          { isBulkSelect: !this.state.isBulkSelect, roomTypeData: [] },
          () => {
            this.setState({
              roomTypeData: this.state.tableDataDup,
              checkCount: 0,
              checkAll: false,
            });
          }
        );
      }

      checkBoxEvent(e: any, row: any, rowIndex: any) {
       // debugger;
        let array = [];
        let arrayTotal = [];
        // let data = Object.assign(
        //   [],
        //   JSON.parse(JSON.stringify(this.state.roomTypeData))
        // );
        let data =_.cloneDeep([...this.state.roomTypeData]);
        data[rowIndex].isCheck = e.target.checked;
        data[rowIndex].action = "";
        array = data.filter((row) => row.isCheck === true);
        arrayTotal = data.filter((row) => row.totalAssign === 0);
        // if (array.length > 0 && array.length !== arrayTotal.length) {
        //   $("#checkAll").prop("checked", false).prop("indeterminate", true);
        // } else {
        //   $("#checkAll").prop("checked", false).prop("indeterminate", false);
        // }

        $("#checkAll").prop("checked", false).prop("indeterminate", true);
        this.setState({
          roomTypeData: data,
          checkCount: array.length,
          checkAll: array.length === arrayTotal.length ? true : false,
        });
        //e.stopPropagation();
      }

      checkAllEvent(e: any) {
        let array = [];
        // let data = Object.assign(
        //   [],
        //   JSON.parse(JSON.stringify(this.state.roomTypeData))
        // );
        let data =_.cloneDeep([...this.state.roomTypeData]);
        // eslint-disable-next-line array-callback-return
        data.map((item: any) => {
          //if (item.totalAssign === 0) {
            item.isCheck = e.target.checked;
         // }
        });
        array = data.filter((row) => row.isCheck === true);
        this.setState(
          { roomTypeData: [], checkCount: array.length, checkAll: e.target.checked },
          () => {
            this.setState({ roomTypeData: data });
          }
        );
      }

      handleFooterClose=()=> {
        // let data = Object.assign(
        //   [],
        //   JSON.parse(JSON.stringify(this.state.roomTypeData))
        // );
        let data =_.cloneDeep([...this.state.roomTypeData]);
        // eslint-disable-next-line array-callback-return
        data.map((item: any) => {
          item.isCheck = false;
        });
        this.setState(
          { roomTypeData: [], checkCount: 0, checkAll: false, isBulkSelect: false },
          () => {
            this.setState({ roomTypeData: data });
          }
        );
      }

      bulkDeleteClick=()=>{
              // debugger;
        let locationChieldData= _.cloneDeep(this.state.locationChieldData);
        let roomData=[...this.state.roomTypeData];
        let locationControllist= _.cloneDeep(this.state.locationControl);
        let roomDataList=_.cloneDeep(this.state.roomTypeData);


        roomDataList.forEach(element => {

        const index = roomData.findIndex((xvalue) => xvalue.selectID === element.selectID
        && element.isCheck===true && element.type.toLowerCase()==="roomtype");
          if(index!=-1){
           roomData.splice(index, 1);  
             // Location deleted
           let listLocation=locationChieldData.filter(x=> x.selectID===element.selectID);
           listLocation.forEach(lo => {
            if(lo.type.toLowerCase()==="roomtype"){
              const loindex = locationChieldData.findIndex((xvalue) => xvalue.selectID === element.selectID);
            if(loindex!=-1){
            locationChieldData.splice(loindex, 1); 
            }
            }else{
            const loindex = locationChieldData.findIndex((xvalue) => xvalue.roomID === lo.roomID);
            if(loindex!=-1){
            locationChieldData.splice(loindex, 1); 
            }
          }
           });

            // Location control deleted
            const locationControlindex = locationControllist.findIndex((xvalue) => xvalue.selectID === element.selectID);  
            locationControllist[locationControlindex].isChecked ="no";
            let roomsId=locationControllist[locationControlindex].selectID;
            locationControllist.forEach(element => {     
              if(element.selectID==roomsId){
                element.isChecked="no";
              }
            });
        


          }                
    
          });

          this.setState({locationChieldData:locationChieldData,roomTypeData:roomData,locationControl:locationControllist},()=>{
            this.props.countUpdate(this.state.locationChieldData,"Add");
            this.handleFooterClose();
           // this.discardChanges();
            if (this.refLocationAdded.current !== null){
               
              this.refLocationAdded.current.upatedLocationList(this.state.locationChieldData,this.state.roomTypeData);
             
            }
          });

      }

      handleSingleDelete=(e: any, row: any, rowIndex: any)=>{
       // debugger;
        let locationChieldData=[...this.state.locationChieldData];
        let roomData=[...this.state.roomTypeData];
        let locationControllist= _.cloneDeep(this.state.locationControl);

      //  roomData.forEach(element => {

        const index = roomData.findIndex((xvalue) => xvalue.selectID === row.selectID
        && row.type.toLowerCase()==="roomtype");
          if(index!=-1){
           roomData.splice(index, 1);  
             // Location deleted
           let listLocation=locationChieldData.filter(x=> x.selectID===row.selectID);
           listLocation.forEach(lo => {
            const loindex = locationChieldData.findIndex((xvalue) => xvalue.roomID === lo.roomID);
            if(loindex!=-1){
            locationChieldData.splice(loindex, 1); 
            }
           });

            // Location control deleted
            const locationControlindex = locationControllist.findIndex((xvalue) => xvalue.selectID === row.selectID);  
            locationControllist[locationControlindex].isChecked ="no";
            let roomsId=locationControllist[locationControlindex].selectID;
            locationControllist.forEach(element => {     
              if(element.selectID==roomsId){
                element.isChecked="no";
              }
            });
        


          }                
    
        //  });

          this.setState({locationChieldData:locationChieldData,roomTypeData:roomData,locationControl:locationControllist},()=>{
            this.props.countUpdate(this.state.locationChieldData,"Add");
            this.handleFooterClose();
           // this.discardChanges();
            if (this.refLocationAdded.current !== null){
               
              this.refLocationAdded.current.upatedLocationList(this.state.locationChieldData,this.state.roomTypeData);
             
            }
          });

      }

      isDeleteConfirmationPoupTab(e: any, row: any, rowIndex: any,stype:any) {
        confirmAlert({
          title: "Confirm to Delete",
          message:
            "Are you sure you want to delete?",
          buttons: [
            {
              label: "Yes",
              onClick: () => stype ==="Delete" ? this.handleSingleDelete(e, row, rowIndex) : this.bulkDeleteClick(),
            },
            {
              label: "No",
              onClick: () => reject(),
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }

      ddlDeptdetails=(ref:any)=> {
 
        // if(ref.current?.ariaExpanded=="true") {
        // ref.current.click();
        // }    
        
      }
    
      render() {  
          const rowClasses = (row: any, rowIndex: any) => {
            if (this.state.isExpand === true && row.action === "Expended") {
              return "row-Expended";
            } else if (this.state.isExpand === true) {
              return "row-Collapsed";
            } else {
              return "row-Action";
            }
          };

        const expandRow = {
            renderer: (row: any) => (
              <div className="rowExpended" 
              // id="laborDayDetails"
              >
               
              
                 <ChieldLocationSlideOut
                //  ref={this.laborDayAppChild}
                    row={row}
                    parentState={this.state}
                />
              </div>
            ),
            expanded: this.state.tableExpended,
            onExpand: this.handleOnExpand,
            // nonExpandable: [1],
            onlyOneExpanding: true,
            // showExpandColumn: true,
            // expandByColumnOnly: true,
            // expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            //   if(isAnyExpands) {
            //     return " "
            //   }
            // },
            // expandColumnRenderer: ({ expanded }) => {
            //   if (expanded) {
            //     return (
            //       <span className="chevronExpended">
            //         <img src={chevronRight} alt="chevron Right" />
            //       </span>
            //     );
            //   }
            //   return (
            //     <span className="chevronCollpsed">
            //       <img src={chevronRight} alt="chevron Right" />
            //     </span>
            //   );
            // },
          };



        const columns = [

          {
            dataField: "",
            text: "",
            formatter: (cell: any, row: any, rowIndex: any) => {
              const stopPropagation = (e: { stopPropagation: () => any }) =>
                e.stopPropagation();
              if (this.state.isBulkSelect) {
                return (
                  <div className="overBudgetDeptsData" onClick={stopPropagation}>
                    <Form.Check
                      custom
                      type="checkbox"
                      key={"custom" + rowIndex}
                      id={"custom" + rowIndex}
                      label=""
                      checked={row.isCheck}
                      onChange={(e: any) => this.checkBoxEvent(e, row, rowIndex)}
                    />
                  </div>
                );
              }
            },
          },

          
          {
            dataField: "action",
            text: "",
            headerClasses:  "expand-collpse",
            classes: "expand-collpse",
            formatter: (cell: any, row: any, rowIndex: any) => {
              if (row.action === "Expended") {
                return (
                  <span className="chevronExpended">
                    <img src={chevronRight} alt="chevron Right" />
                  </span>
                );
              } else {
                return (
                  <span className="chevronCollpsed">
                    <img src={chevronRight} alt="chevron Right" />
                  </span>
                );
              }
            },
          },


            {
              dataField: "name",
              text: "Name",
              formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                  <>
                    <EllipsisWithTooltip placement="bottom">
                      {row.name}
                    </EllipsisWithTooltip>
                  </>
                );
              },
      
            },
            {
                dataField: "type",
                text: "Rooms",
                formatter: (cell: any, row: any, rowIndex: any) => {
                  return (
                    <>
                      <EllipsisWithTooltip placement="bottom">
                        {row.roomCount}
                      </EllipsisWithTooltip>
                    </>
                  );
                },
        
              },
      
           
            // {
            //   dataField: "",
            //   text: "",
            //   formatter: (cell: any, row: any, rowIndex: any) => (
                
            //     <Dropdown
            //       // onClick={(e) => {
            //       //   e.stopPropagation();
            //       // }}
            //       className="more-action position-static text-right"
            //       alignRight
            //     >
            //       <Dropdown.Toggle
            //         className="btn-outline-primary btn btn-primary btn-ghost more"
            //         id="dropdown-more"
            //         //</Dropdown>disabled={!this.state?.createEditPermission || selectedRows?.length > 0
            //         //}
            //       >
            //         <svg
            //           width="20"
            //           height="20"
            //           viewBox="0 0 20 20"
            //           fill="none"
            //           xmlns="http://www.w3.org/2000/svg"
            //         >
            //           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
            //           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
            //           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
            //         </svg>
            //       </Dropdown.Toggle>
            //       <Dropdown.Menu>
                    
            //         <Dropdown.Item 
            //         // onClick={this.handleBulkSelect}
            //         onClick={(e: any) =>
            //           this.handleBulkSelect(e, row, rowIndex)
            //         }
            //         >
            //           Bulk Select</Dropdown.Item>
            //         <Dropdown.Item
            //          onClick={(e: any) =>
            //         //  this.handleSingleDelete(e, row, rowIndex)
            //         this.isDeleteConfirmationPoupTab(e, row, rowIndex,"Delete")
            //         }
            //         >
            //           Delete
            //         </Dropdown.Item>
            //       </Dropdown.Menu>
            //     </Dropdown>
            //   ),
            // },

            {
              dataField: "",
              text: "",
              editable: false,
              formatter: (cell: any, row: any, rowIndex: any) => {
                const stopPropagation = (e: { stopPropagation: () => any }) =>
                e.stopPropagation();
               // if (row.flyOutAction === "") {
                  return (
                    // <OutsideClickHandler onOutsideClick={(e)=>this.ddlDeptdetails() }>
                    <Dropdown
                      className="more-action position-static"
                      alignRight
                      onClick={stopPropagation}
                    >
                      <Dropdown.Toggle
                        className="btn-outline-primary btn btn-primary more"
                        id="dropdown-more"
                        disabled={this.props.disabled}
                        //ref={row?.ddlDeptdetailsRef}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                             <Dropdown.Item 
                             eventKey={"Bulk elect"}
                     // onClick={this.handleBulkSelect}
                    onClick={(e: any) =>
                      this.handleBulkSelect(e, row, rowIndex)
                     }
                     >
                       Bulk Select</Dropdown.Item>
                     <Dropdown.Item
                      onClick={(e: any) =>
                     //  this.handleSingleDelete(e, row, rowIndex)
                    this.isDeleteConfirmationPoupTab(e, row, rowIndex,"Delete")
                     }
                     >
                       Delete
                     </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    // </OutsideClickHandler>
                  );
              
             // }
            },
          }



          ];
  
        
       
        return (
          <>
    
    <div className="laborDayApproval p-0">
           
            <OutsideClickHandler
              onOutsideClick={(e) => {
                 this.onOutsideClick(e);
              }}
            >
              <div className={`maintenance-location-table ${this.state.isBulkSelect ? "set-table-with" : "" }`} id="">
                <BootstrapTable
                  keyField="id"
                  data={this.state.roomTypeData}
                  columns={columns}
                  expandRow={expandRow}
                  rowClasses={rowClasses}
                 // hover
                />
              </div>        
            </OutsideClickHandler>





          </div>

          {this.state.isBulkSelect && (
            <div className="bulk-banner">
              <div className="bulk-banner-wrapper">
                <div>
                  <div className="d-flex">
                    <Form.Check
                      custom
                      type="checkbox"
                      key={"checkAll"}
                      id={"checkAll"}
                      label=""
                      //checked={this.state.checkAll}

                      ref={(chkRef) => {
                        if (chkRef) {
                          this.checkAllRef = chkRef;
                          if (
                            this.state.checkCount ===
                            this.state.roomTypeData.length
                          ) {
                            chkRef.checked = true;
                          } else {
                            chkRef.checked = false;
                          }

                          if (
                            this.state.checkCount > 0 &&
                            !chkRef?.checked
                          ) {
                            chkRef.indeterminate = true;
                          } else {
                            chkRef.indeterminate = false;
                          }
                        }
                      }}


                      onChange={(e: any) => this.checkAllEvent(e)}
                    />
                    <div className="selected-count">
                      {this.state.checkCount}{" "}
                      {this.state.checkCount > 1 } Selected
                    </div>
                  </div>
                </div>
                <div className="mr-auto approve-btn">
                  {this.state.checkCount > 0 && (
                    <button
                      type="button"
                      className="btn-outline-primary btn btn-primary trash-btn"
                     // onClick={this.isDeleteConfirmationPoupTab("","","","bulkdelete") }
                     onClick={(e: any) =>
                      //  this.handleSingleDelete(e, row, rowIndex)
                      this.isDeleteConfirmationPoupTab("","","","bulkdelete")
                      }
                    >
                     Delete
                    </button>
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    className="cross-btn"
                    onClick={this.handleFooterClose}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}


          {this.state.locationControl.length>0 ?(

         
          <div className={`select-users ${this.state.isBulkSelect ? "pb-5" : ""}`}>
              <Form.Group controlId="set-Forecast">                
                <LocationAdded
                  ref={this.refLocationAdded}
                //   conflictCheck="Actual"
                   parentState={this.state}
                   locationAdded={this.locationAdded}
                   disabled={this.props.disabled}
                   
                 // multiSelectErrorValidation={this.multiSelectErrorValidation}
                />
              </Form.Group>
            </div> 
            ):(
              <div  className="add-room-dropdown">
                <Dropdown  className="pl-3">
                  <DropdownToggle className="add-link Add-location"  disabled={this.props.disabled}>Add location</DropdownToggle>
                  <DropdownMenu> 
                    <div className="dropDownPanel inner-label">
                        <div className="SearchInput">
                          <div className="input">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                              <path d="M5.66634 11.0002C6.84966 10.9999 7.99888 10.6038 8.93101 9.87483L11.8617 12.8055L12.8043 11.8628L9.87368 8.93216C10.603 7.99994 10.9994 6.85045 10.9997 5.66683C10.9997 2.72616 8.60701 0.333496 5.66634 0.333496C2.72567 0.333496 0.333008 2.72616 0.333008 5.66683C0.333008 8.6075 2.72567 11.0002 5.66634 11.0002ZM5.66634 1.66683C7.87234 1.66683 9.66634 3.46083 9.66634 5.66683C9.66634 7.87283 7.87234 9.66683 5.66634 9.66683C3.46034 9.66683 1.66634 7.87283 1.66634 5.66683C1.66634 3.46083 3.46034 1.66683 5.66634 1.66683Z" fill="#84888C"></path>
                            </svg>
                            <input
                            // ref={this.inputSearch}
                              key={"text-Filter"}
                              type="text"
                              className="form-control"
                              //onChange={this.handleChange}
                              placeholder="Search by location or room type"
                              value={this.state.inputValue}
                            />
                          </div>
                        </div> 
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
            )}


          
          </>
          );
        }

}