import * as React from 'react';
import { Form, Container, Button, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Register as registersevice } from "../../Common/Services/Register";
import { ToastContainer, toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { Utils } from '../../Common/Utilis';

let exportDataSet: any = [];

export class InvoiceExtraction extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      extractionList: [],
      isLoader: false,
      exportData: [],
    };
  }

  componentDidMount() {
    this.getTableData();
  }

  getTableData = () => {
    registersevice.GetAllExtractionData({})
      .then(async (result: any[] | null) => {
        if (result !== null) {
          this.setState({ extractionList: result, isLoader: false, });
        }
      })
      .catch((error) => {
        this.setState({ extractionList: [], isLoader: false, });
        Utils.toastError("Server Error", {
          // position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }

  onExtractClick = () => {
    this.setState({ isLoader: true });
    registersevice.PDFExtractionSpike({})
      .then(async (result: any | null) => {
        if (result === "Pass") {
          this.getTableData();
        }
      })
      .catch((error) => {
        this.setState({ isLoader: false, });
        Utils.toastError("Server Error", {
          // position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }

  exportData = () => {
    // debugger;
    exportDataSet = [];

    let tableData = Object.assign([], JSON.parse(JSON.stringify(this.state.extractionList)));
    tableData.forEach(item => {
      let row: any = {};
      row["Tenant ID"] = item.tenantID;
      row["HID"] = item.lettercode;
      row["Invoice Number"] = item.invoiceNo;
      row["Azure KVP Matches"] = item.azureHeaderCount;
      row["Azure Line Item Matches"] = item.azureLineCount;
      row["Total Line Items"] = item.totalSplitCount;
      row["AWS KVP Matches"] = item.awsHeaderCount;
      row["AWS Line Item Matches"] = item.awsLineCount;
      row["Azure Amount Matches"] = item.azureAmountCount;
      row["Azure Description Matches"] = item.azureDescriptionCount;
      row["AWS Amount Matches"] = item.awsAmountCount;
      row["AWS Description Matches"] = item.awsDescriptionCount;
      exportDataSet.push(row);
    });
    this.setState({ exportData: exportDataSet }, () => {
      let tempLink = document.getElementById("awsVsAzure") as any;
      tempLink.click();
    })
  }

  downloadInvoicePdf = (fileUrl, invoiceNo) => {
    let BlobbaseUrl = this.state.BlobbaseUrl;
    //"https://innflowfilestoretest.blob.core.windows.net/innflowtemplate/SignatureForm.pdf?X=" + Math.floor(Math.random() * 100),
    fetch(fileUrl,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${invoiceNo.toString().trim()}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
      });
  };

  render() {
    const columns = [
      {
        dataField: "tenantID",
        text: "Tenant ID",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.tenantID}</div>
          )
        }
      }, {
        dataField: "lettercode",
        text: "HID",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.lettercode}</div>
          )
        }
      }, {
        dataField: "invoiceNo",
        text: "Invoice Number",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className='download-link' onClick={() => this.downloadInvoicePdf(row.fileUrl, row.invoiceNo)}>{row.invoiceNo}</div>
          )
        }
      }, {
        dataField: "azureHeaderCount",
        text: "Azure KVP Matches",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.azureHeaderCount}</div>
          )
        }
      }, {
        dataField: "azureLineCount",
        text: "Azure Line Item Matches",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.azureLineCount}</div>
          )
        }
      }, {
        dataField: "totalSplitCount",
        text: "Total Line Items",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.totalSplitCount}</div>
          )
        }
      }, {
        dataField: "awsHeaderCount",
        text: "AWS KVP Matches",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.awsHeaderCount}</div>
          )
        }
      }, {
        dataField: "awsLineCount",
        text: "AWS Line Item Matches",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.awsLineCount}</div>
          )
        }
      }, {
        dataField: "azureAmountCount",
        text: "Azure Amount Matches",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.azureAmountCount}</div>
          )
        }
      }, {
        dataField: "azureDescriptionCount",
        text: "Azure Description Matches",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.azureDescriptionCount}</div>
          )
        }
      }, {
        dataField: "awsAmountCount",
        text: "AWS Amount Matches",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.awsAmountCount}</div>
          )
        }
      }, {
        dataField: "awsDescriptionCount",
        text: "AWS Description Matches",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div>{row.awsDescriptionCount}</div>
          )
        }
      }
    ];
    return (
      <Container fluid className="body-sec invoiceExtraction">
        <div className="page-heading underline d-flex">
          <div className="mr-auto d-flex align-items-center">Invoice Extraction</div>
          <div className="action-group d-flex">
            <Button type="button" onClick={this.onExtractClick}>Extract</Button>
          </div>
        </div>
        <div className="main-Page">
          <div className="bdy-sec">
            <div className='table-responsive table-auto-width'>
              <BootstrapTable
                keyField='id'
                data={this.state.extractionList}
                columns={columns}
                noDataIndication="No matching record found"
              />
            </div>
          </div>
        </div>
        {!this.state.isLoader && this.state.extractionList.length > 0 &&
          <div className="mt-2">
            <Button type="button" onClick={this.exportData}>Export Data</Button>
            <Button className="without-border margin-right-15" variant="primary" style={{ display: "none" }}>
              <CSVLink id="awsVsAzure" data={exportDataSet}
                filename={"AWS v Azure testing" + ".csv"}>Export Data</CSVLink>
            </Button>
          </div>
        }
        {this.state.isLoader && (
          <div className="loader-spinner d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="success" />
          </div>
        )}
      </Container>
    );
  }
}