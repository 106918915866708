import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import moment from "moment";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../../Common/StaticArrays";
import { IUserGarnishment } from "./../../../Common/Contracts/IUserGarnishment";
import _ from "lodash";
export class AddGarnishment extends React.Component<any, any> {
  private garnishmentFormSchema = Joi.object({
    uniqueNo: Joi.number().optional().allow(0),
    hid: Joi.number()
      .required()
      .min(1)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select HID" };
        });
      }),
    fileName: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select File" };
        });
      }),
    status: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Status" };
        });
      }),

    totalAmount: Joi.number()
      .required()
      .min(0)
      .max(99999999)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message: "Please enter valid Amount",
              };
            case "number.min":
              return {
                message: "Amount should greater or equal to 0",
              };
              case "number.max":
                return {
                  message: "Amount should not be greater than 99999999",
                };

          }
        });
        return [_errors[0]];


      }),
    comments: Joi.string()
      .required()
      .max(200)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "any.empty":
              return {
                message: "Please enter Comments",
              };
            case "string.max":
              return {
                message: "Maximun 200 characters are allowed",
              };
          }
        });
        return [_errors[0]];
      }),
    file: Joi.object(),
  });

  state: any = {
    garnishment: {
      uniqueNo: this.props?.garnishmentRowEdit?.uniqueNo || 0,
      hid: this.props?.garnishmentRowEdit?.hid || 0,
      status: this.props?.garnishmentRowEdit?.status || "Active",
      totalAmount: this.props?.garnishmentRowEdit?.totalAmount || "",
      comments: this.props?.garnishmentRowEdit?.comments?.replace(/\s{2,}/g, ' ').trim() || "",
      fileName: "",
      file: {},
    },
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    hotelList: [],
    showDocTypeModal: false,
    typeId: "",
    typeName: "",
    docEntryType: "New",
    isEditButton: false,
  };

  componentDidMount = () => {
    this.getHotels();
  };
  getHotels = () => {
    if(this.props?.userHotels?.length ===1 && (+this.props?.garnishmentRowEdit?.hid===0 || !this.props?.garnishmentRowEdit?.hid) ) {
      const   garnishment = _.cloneDeep(this.state.garnishment);
      garnishment.hid = this.props?.userHotels[0]?.hotelID;
      this.setState({garnishment});
     }
    this.setState({ hotelList: this.props?.userHotels });
  };

  hideModal = (isReload = false) => {
    const garnishment = {
      uniqueNo: this.props?.garnishmentRowEdit?.uniqueNo || 0,
      hid: this.props?.garnishmentRowEdit?.hid || 0,
      status: this.props?.garnishmentRowEdit?.status || "Active",
      totalAmount: this.props?.garnishmentRowEdit?.totalAmount || 0,
      comments: this.props?.garnishmentRowEdit?.comments?.replace(/\s{2,}/g, ' ').trim() || "",
      file: {},
    };
    this.props?.hideGarnishmentModal(isReload);
    this.setState({ garnishment, isFormNotValid: true });
  };

  onFieldChange = (event, inputPosition) => {
    const { garnishment } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
   
    if(fieldName === "comments"){
      value =typeof value === 'string' ? value?.toString()?.trimLeft() :value;
    }
    if (
      (fieldName === "totalAmount") &&
      value?.toString()?.split(".")[1]?.length > 2
    ) {
      return;
    }
    garnishment[fieldName] = value;
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({inputPosition});
    }
    this.setState({ garnishment }, () =>
      this.validategarnishmentFormSchema()
    );
    if (fieldName === "typeID") {
      $("#ddlDocType")?.click();
    }
  };
  componentWillUnmount=()=> {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }
  onFileDrop = (files) => {
    this.onFieldChange(
      Utils.BuildCustomEventObject("file", files[0]),

      5
    );
    this.onFieldChange(
      Utils.BuildCustomEventObject("fileName", files[0]?.name),

      35
    );
  };

  saveGarnishment = () => {
    const {
      garnishment: {
        uniqueNo,
        hid,
        status,
        totalAmount,
        comments,
        fileName,
        file,
      },
    } = this.state;

    const request: IUserGarnishment = {
      uniqueNo: uniqueNo,
      hid: hid,
      status: status,
      totalAmount: totalAmount,
      comments: comments.replace(/\s{2,}/g, ' ').trim(),
      fileName: fileName,
    } as IUserGarnishment;

    request.userUniqueNo = this.props?.userUniqueNo;
    request.statusDate = moment().format("MM/DD/YYYY");
    this.setState({ isDataSaving: true });
    UserManagement.SaveGarnishment(request, file)
      .then((result) => {
        this.setState({ isDataSaving: false });
        if (result?.message === "Success") {
          toast.success("Garnishment Saved Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
          this.hideModal(true);
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      });
  };
  validategarnishmentFormSchema = () => {
    const valid = Joi.validate(
      this.state.garnishment,
      this.garnishmentFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if (this.props?.garnishmentRowEdit?.uniqueNo > 0) {
      if (newErrorObject?.file) {
        delete newErrorObject.file;
      }
      if (newErrorObject?.fileName) {
        delete newErrorObject.fileName;
      }
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  validationOnClick = (inputPosition) => {
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({ inputPosition }, () => this.validategarnishmentFormSchema());
    }

  };

  render() {
    const {
      garnishment: {
        hid,
        status,
        totalAmount,
        comments,
        fileName,
      },
      hotelList,
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,
    } = this.state;
    const { showGarnishmentModal } = this.props;
    return (
      <>
        <div className="">
        
          <Modal
            className="add-garnishment-modal"
            show={showGarnishmentModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props?.garnishmentRowEdit?.uniqueNo > 0
                  ? "Edit Garnishment"
                  : "Add Garnishment"}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Form.Group controlId="hid" className="d-flex">
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.hid && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(1)}
                    >
                      <Form.Label>EHID</Form.Label>
                      {hotelList?.length > 0 && (
                        <DropDownList
                          placeHolder={"Select HID"}
                          data={hotelList}
                          defaultValue={hid}
                          isSearchRequired={false}
                          label={"lettercode"}
                          value={"hotelID"}
                          // disabled={
                          //   !userGeneralInfo?.hasEditContactInfo &&
                          //   !isNewUserAdded
                          // }
                          disabled={
                            this.props?.garnishmentRowEdit?.uniqueNo > 0
                            || hotelList?.length ===1
                          }
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("hid", item.hotelID),
                              1
                            );
                          }}
                          selectedItem={[
                            ...[{ hotelID: 0, lettercode: "Select HID" }],
                            ...hotelList,
                          ].find((r: any) => r?.hotelID === hid)}
                        />
                      )}
                      {this.getFieldError("hid", 1)}
                    </Col>
                    <Col
                      sm={"6"}
                      className={`mb-0 ${
                        errors?.status && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(2)}
                    >
                      <Form.Label>Status</Form.Label>
                      <DropDownList
                        placeHolder={"Select Status"}
                        data={StaticArrays.StatusList}
                        defaultValue={status}
                        isSearchRequired={false}
                        label={"label"}
                        value={"label"}
                        // disabled={
                        //   !userGeneralInfo?.hasEditContactInfo &&
                        //   !isNewUserAdded
                        // }
                        onDropDownChange={(item) => {
                          if (!item) {
                            return;
                          }
                          this.onFieldChange(
                            Utils.BuildCustomEventObject("status", item.value),
                            2
                          );
                        }}
                        selectedItem={[
                          ...[{ label: "Select Status", value: "" }],
                          ...StaticArrays.StatusList,
                        ].find((r: any) => r?.value === status)}
                      />
                      {this.getFieldError("status", 2)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="amount" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.totalAmount && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(3)}
                    >
                      <Form.Label>Goal or Total Amount to be paid</Form.Label>
                      <input
                        type="text"
                        onKeyDown= {Utils.AllowOnlyPositiveNumnberInput}
                        maxLength={10}
                        className="form-control"
                        id="txtAmount"
                        name="totalAmount"
                        autoComplete="totalAmount"
                        value={totalAmount}
                        onChange={(e) => this.onFieldChange(e, 3)}
                      />
                      {this.getFieldError("totalAmount", 3)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="comments" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.comments && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(4)}
                    >
                      <Form.Label>Comments</Form.Label>
                      <textarea
                        className="form-control"
                        id="txtAmount"
                        name="comments"
                        autoComplete="txtAmount"
                        value={comments}
                        onChange={(e) => this.onFieldChange(e, 4)}
                      />
                      {this.getFieldError("comments", 4)}
                    </Col>
                  </Form.Group>
                  {!this.props?.garnishmentRowEdit?.uniqueNo && (
                    <Form.Group
                      as={Row}
                      controlId="fileUpload"
                      onBlur={() => this.validationOnClick(5)}
                      className={
                        errors?.fileName && inputPosition >= 5
                          ? "validation-error-file attachment-file-sec"
                          : "attachment-file-sec"
                      }
                    >
                      <Form.Label>Attachments</Form.Label>
                      <Dropzone onDrop={this.onFileDrop} multiple={false}>
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                        }) => (
                          <section className="upload-area">
                            <aside className="files">
                              <div
                                {...getRootProps({
                                  className: "dropzone",
                                })}
                              >
                                <input {...getInputProps()} />
                                <div>
                                  {fileName ? (
                                    <EllipsisWithTooltip placement="bottom">
                                      <p>{fileName} </p>
                                    </EllipsisWithTooltip>
                                  ) : (
                                    <p>
                                      Drag &amp; drop here or{" "}
                                      <span>browse files</span> to upload
                                    </p>
                                  )}
                                </div>
                              </div>
                            </aside>
                          </section>
                        )}
                      </Dropzone>
                      {this.getFieldError("fileName", 5)}
                    </Form.Group>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-ghost"
                onClick={() => this.hideModal(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.saveGarnishment}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
