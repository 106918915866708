import React from "react";
import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import { BankAccountSlideout } from "./BankAccountSlideout";
import { CreditCardAccountSlideout } from "./CreditCardAccountSlideout";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { Hotel as hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { resolve, reject } from "q";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TableSearch from "../../Common/Components/TableSearch";
import { accountService } from "../../Common/Services/account";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import _ from "lodash";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { LaborPerformance as laborPerformance } from "../../Common/Services/LaborPerfomance";
import "../Register/slideout.scss";
import "./accounts.ui.scss";
import { Register as registersevice } from "../../Common/Services/Register";
import { Utils } from "../../Common/Utilis";
import { RoleSubPermissionSetting, IPageSubPermissionRole, } from "../../Common/Services/RoleSubPermissionSetting";
import * as XLSX from 'xlsx';
import { BankSyncWizard } from "../Accounting/BankSync/BankSyncWizard";
let localStorageUser: any;

let dataAfterSearch: any = [];
let exportDataSet: any =
{
  columns: [
    "Account Number",//pixels width 
    "Bank",
    "COA",
    "Nickname",
    "EHID",
    "Account Type",
    "Balance",
    "Status",//char width
  ],
  data: []
}
  ;

const colCounter: number = 5;
let currentLen: number = -1;

export class accountNew extends React.Component<any, any> {
  private tableSearch: any;
  private pageName = "Accounts";
  constructor(props: any) {
    super(props);
    this.tableSearch = React.createRef();
    this.state = {
      isLimitedsubs: "No",
      isShowSlideout: false,
      isShowCreditCardSlideout: false,
      hidList: [],
      hidFilterList: [],
      hidValue: "Select",
      hotelName: "Select",
      accountsTableData: [],
      accountsExportTableData: [],
      mainAccountsTableData: [],
      tableDataFilter: [],
      search: "",
      accountTypeList: [],
      accountTypeName: 0,
      selectedAcType: "",
      isLoadComplete: false,
      showInactiveAcc: false,
      isRefresh: false,
      defaultSelectOPtion: "All",
      showLoader: true,
      sortingOrderList: ["Bank", "EHID", "Name", "Type"],
      sortingOrder: "",
      tableClass: "accounts-tableWide",
      isAddAccountDisbaled: true,
      isBalLoading: true,

      isForRowBankSinc: false,
      isForRowEdit: false,
      connectBank: {
        accRowoprID: "",
        accRowacctType: "",
        accRowhidValue: "",
        accRowhotelName: "",
        accRowaccountName: "",
        accDisplayName: "",
        CCdisplayName: ""
      },
      showSensitiveInfo: false,
      isBankWizardShow: false,
      hasBankPerm: false,
      isConnectBankPerm: false
    };
  }

  componentDidMount() {
    this.getConnectbankPermission();
    this.advancedAccounting();
    this.getVandorContractMergePermission();
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    const hotelid = JSON.parse(localStorage.getItem("hotelid")!);
    const hotelName = JSON.parse(localStorage.getItem("hotelName")!);
    this.getDefaultView();
    hotel
      .getUserHotelAccess("Accounting")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null && result.length > 0) {
          const allAppendedHotel = {
            ehidName: "All",
            hotelID: "All",
            hotelName: "All",
            hotelType: "0",
            lettercode: "All",
            tenantID: localStorageUser.tenantID,
          };
          this.setState({ hidList: result }, () => {
            if (result.length > 1) {
              this.state.hidList.unshift(allAppendedHotel);
            }
          });
          this.setState({ hidFilterList: result });
          if (hotelName === null) {
            if (result.length > 1) {
              this.setState(
                {
                  hotelName: this.state.hidList[0].lettercode,
                  hidValue: this.state.hidList[0].hotelID,
                },
                () => {
                  let request = {} as any;
                  request.HID = this.state.hidList[0].hotelID;
                  this.getAccountsData(request);
                }
              );
            }
          } else {
            this.setState({ hotelName: hotelName });
          }
          if (hotelid === null) {
            if (result.length === 1) {
              this.setState(
                {
                  hidValue: this.state.hidList[0].hotelID,
                  hotelName: this.state.hidList[0].lettercode,
                },
                () => {
                  let request = {} as any;
                  request.HID = this.state.hidList[0].hotelID;
                  this.getAccountsData(request);
                  this.getSignatureImgPerm();
                }
              );
            }
          } else {
            this.setState({ hidValue: hotelid }, () => {
              let request = {} as any;
              request.HID = this.state.hidList[0].hotelID;
              this.getAccountsData(request);
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "AccountsHome"
        });
        reject();
      });
  }

  getConnectbankPermission = () => {
    let requestObject = {} as any;
    requestObject.permissionID = 1010;
    requestObject.Module = "Accounting";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          this.setState({ isConnectBankPerm: result.some((o) => o.permissionName.toLowerCase() === "connect bank") });
        }
        resolve();
      })
      .catch((err: any) => {
        reject();
      });
  };

  advancedAccounting = () => {
    let isBankSyncFeatureFlag: boolean = false;
    accountService.AdvancedAccounting()
      .then(async (result: any | null) => {
        if (result !== null) {
          isBankSyncFeatureFlag = result.some((o: any) => o?.name?.toString().toLowerCase() === "banksync");
        }

        this.setState({hasBankPerm: isBankSyncFeatureFlag})
        if (!isBankSyncFeatureFlag) {
          window.location.href = "/unauthorize-access";
        }
      });
  }
  getVandorContractMergePermission = () => {
    let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 1500;
    requestObject.Module = "Accounting";
    requestObject.Type = "page";
    requestObject.RoleID = localStorageUser.rolId;
    requestObject.TenantID = localStorageUser.tenantID;
    let { showSensitiveInfo } = this.state;
    showSensitiveInfo = false
    this.setState({ showSensitiveInfo });
    RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          for (let index = 0; index < result.length; index++) {
            if (result[index].displayName.toString().toLowerCase() === "show sensitive information" && result[index].moduleName === "Accounting") {
              showSensitiveInfo = true;
            }
          }
        }
        this.setState({ showSensitiveInfo })
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, { position: toast.POSITION.BOTTOM_RIGHT });
        reject();
      });
  };

  //This method will fetch the default setting for the user on page load
  getDefaultView() {
    this.setState({ pageloader: true });
    laborPerformance
      .getDefaultViewProperties(this.pageName)
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          const result = response;
          const sortingOrder = result.find(
            (item) => item.fieldName === "sort"
          )?.fieldValue;
          const showInactiveAcc = result.find(
            (item) => item.fieldName === "toggle"
          )?.fieldValue;
          this.setState({
            sortingOrder,
            showInactiveAcc: showInactiveAcc === "true" ? true : false,
            tableClass: showInactiveAcc === "true" ? "accountsTable accounts-tableWide" : "accountsTable accounts-tableWide"
          });
        } else {
          this.setDefaultFilterValues();
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "AccountsHome"
        });
        this.setDefaultFilterValues();
      });
  }

  getSignatureImgPerm() {
    let request: any = {};
    request.hotelID = this.state.hidValue;
    accountService.DigitalSignPermission(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result[0].isEditOperating === "Yes" || result[0].isEditMisc === "Yes" || result[0].isEditPayroll === "Yes") {
            this.setState({ isAddAccountDisbaled: false, isLimitedsubs: result[0].isLimitedSubscription });
          } else {
            this.setState({ isAddAccountDisbaled: true, isLimitedsubs: result[0].isLimitedSubscription });
          }
        }
      })
  }
  setDefaultFilterValues = (): void => {
    this.setState({
      sortingOrder: "Name",
      showInactiveAcc: false,
      tableClass: "accountsTable accounts-tableWide",
    });
  };

  searchClear = () => {
    this.setState({ search: "" });
    this.setState({ hidList: this.state.hidFilterList });
  };

  searchItems = (e: any) => {
    const { hidFilterList } = this.state;
    let result = e.target.value;
    this.setState({ search: result });
    if (result !== "") {
      this.setState({
        hidList: hidFilterList.filter((hName: any) =>
          hName.hotelName.toLowerCase().includes(result.toLowerCase().trim())
        ),
      });
    } else {
      this.setState({ hidList: this.state.hidFilterList });
    }
  };

  hotelChange = (hotelDetails: any) => {
    if (sessionStorage.getItem("hotelSelected"))
      sessionStorage.removeItem("hotelSelected");
    sessionStorage.setItem("hotelSelected", JSON.stringify(hotelDetails));

    if (localStorage.getItem("hotelSelected"))
      localStorage.removeItem("hotelSelected");
    localStorage.setItem("hotelSelected", JSON.stringify(hotelDetails));

    this.setState(
      {
        hotelName: hotelDetails.lettercode,
        hidValue: hotelDetails.hotelID,
        selectedAcType: "",
        accountTypeName: 0,
        accountTypeList: [],
        mainAccountsTableData: [],
        accountsTableData: [],
        accountsExportTableData: [],
        isRefresh: true,
        showLoader: true,
        defaultSelectOPtion: "All",
        isBalLoading: true
      },
      () => {
        let request = {} as any;
        request.HID = hotelDetails.hotelID;
        currentLen = -1;
        this.CleanSearch();
        this.getAccountsData(request);
        this.getSignatureImgPerm();
      }
    );
  };

  handleAccountError = (message: any) => {
    this.setState({ accountsTableData: [], accountsExportTableData: [] }, () => {
      setTimeout(() => {
        this.setState({ accountsTableData: [], accountsExportTableData: [], showLoader: false }, () => {
          currentLen = 0;
          this.nextList([]);
        });
      }, 1500);
      Utils.toastError(message, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "AccountsHome" });
    });
  }

  sortTableData = (data: any) => {
    let { sortingOrder } = this.state;
    if (sortingOrder === "Bank") {
      data = _.orderBy(data, [(obj) => obj?.bName?.trim().toUpperCase()], ['dsort']);
    } else if (sortingOrder === "EHID") {
      data = _.orderBy(data, [(obj) => obj?.letterCode?.trim().toUpperCase()], ['dsort']);
    } else if (sortingOrder === "Name") {
      data = _.orderBy(data, [(obj) => obj?.displayName?.trim().toUpperCase()], ['dsort']);
    } else if (sortingOrder === "Type") {
      data = _.sortBy(data, "dsort");
    }
    return data
  };

  getAccountsData = (request: any) => {
    this.getAccountBal([], []);
    accountService.GetAccountsData(request)
      .then(async (result: any) => {
        if (result.message === "Success") {
          if (result.result.length > 0) {
            let data = result.result;
            data = this.sortTableData(data);
            let updatedList: any = [];
            data.forEach((item: any, id: any) => {
              if (item.acctType === "Operating") {
                item.dsort = "a";
              } else if (item.acctType === "Payroll") {
                item.dsort = "b";
              } else if (item.acctType === "Miscellaneous") {
                item.dsort = "c";
              } else if (item.acctType === "Credit Card") {
                item.dsort = "d";
              } else {
                item.dsort = "";
              }
              updatedList.push(item);
            });
            this.setState({ accountTypeList: [], mainAccountsTableData: updatedList });
            if (!this.state.showInactiveAcc) {
              let filterData = updatedList.filter((items) => items.status.toString().toLowerCase() === "active");
              filterData.map((item: any) => item["balanceLoader"] = item.isViewBankGLBalances);
              this.setState({ accountsTableData: [], accountsExportTableData: [] }, () => {
                setTimeout(() => {
                  this.setState({ accountsTableData: filterData, accountsExportTableData: filterData, showLoader: false }, () => {
                    dataAfterSearch = filterData;
                    currentLen = 0;
                    this.nextList(updatedList);
                    this.setState({ defaultSelectOPtion: "All", selectedAcType: "" });
                  });
                }, 1500);
              });
            } else {
              updatedList.map((item: any) => item["balanceLoader"] = true);
              this.setState({ accountsTableData: [], accountsExportTableData: [] }, () => {
                setTimeout(() => {
                  this.setState({ accountsTableData: updatedList, accountsExportTableData: updatedList, showLoader: false }, () => {
                    dataAfterSearch = updatedList;
                    currentLen = 0;
                    this.nextList(updatedList);
                    this.setState({ defaultSelectOPtion: "All", selectedAcType: "" });
                  });
                }, 1500);
              });
            }
            this.setState({
              tableDataFilter: updatedList.filter((items) => items.status.toString().toLowerCase() === "active"),
              isLoadComplete: true,
            });
            let allType: any = { id: -1, name: "All", sort: "a" };
            this.state.accountTypeList.push(allType);
            let newdata: any = _.uniqBy(updatedList, "acctType");
            let { accountTypeList } = this.state;
            newdata.forEach((item: any, id: any) => {
              let data: any = {};
              if (item.acctType === "Operating") {
                data = { id: id, name: item.acctType, sort: "b" }
              } else if (item.acctType === "Payroll") {
                data = { id: id, name: item.acctType, sort: "c" }
              } else if (item.acctType === "Miscellaneous") {
                data = { id: id, name: item.acctType, sort: "d" }
              } else if (item.acctType === "Credit Card") {
                data = { id: id, name: item.acctType, sort: "e" }
              }
              accountTypeList.push(data);
            });
            accountTypeList = _.sortBy(accountTypeList, "sort");
            this.setState({ accountTypeList });
            this.sortChange(this.state.sortingOrder);
          } else {
            this.handleAccountError("No data found.");
          }
        } else {
          this.handleAccountError(`Server Error: ${result.message}`);
        }
        resolve();
      })
      .catch((error: any) => {
        this.handleAccountError(`Server Error: ${error}`)
        reject();
      });
  };

  GetDefaultHotelByAccount = (oprId: any, acctype: any, callType: any) => {
    let defaultrequest: any = {};
    defaultrequest.OprID = oprId;
    defaultrequest.AcctType = acctype === 'Credit Card' ? 2 : 1;
    registersevice.GetDefaultHotelByAccount(defaultrequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          let hotelArray: any = {};
          hotelArray.lettercode = result[0].lettercode;
          hotelArray.hotelID = result[0].hotelID;
          sessionStorage.getItem("hotelSelected") && sessionStorage.removeItem("hotelSelected");
          localStorage.getItem("hotelSelected") && localStorage.removeItem("hotelSelected");
          sessionStorage.setItem("hotelSelected", JSON.stringify(hotelArray));
          localStorage.setItem("hotelSelected", JSON.stringify(hotelArray));
          if (callType === "toRegister") {
            localStorage.setItem("subMenu", "Register");
            this.props.history.push('/register', { oprid: oprId, acctType: acctype, pageName: 'Account' })
          } else {
            localStorage.setItem("subMenu", "Bank Sync");
            this.props.history.push('/BankSync', { oprid: oprId, acctType: acctype, pageName: 'Account' });
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  getNewArr = (data: any) => {
    return data.slice(colCounter * currentLen - colCounter, colCounter * currentLen)
  }

  nextList(data: any) {
    if (data.length > 0) {
      if (currentLen >= 0 && currentLen < data.length / colCounter) {
        currentLen++;
        let newArr = this.getNewArr(data)
        this.getAccountBal(newArr, data);
      }
    }
  }

  getAccountBal = (newArr: any, data: any) => {
    let oprIds: any = [];
    let request: any = {};
    newArr.length > 0 && newArr.forEach(item => {
      oprIds.push(item.oprid)
    })
    request.OPRIDs = newArr.length > 0 ? oprIds.toString() : "";
    registersevice.GetAccountNoBalanceList(request).then(async (result: any[] | null) => {
      if (result !== null) {
        result.forEach(resItem => {
          data.forEach(accItem => {
            if (accItem.oprid === resItem.id) {
              let currentBalance = Number(resItem.result) === 0 ? 0 : resItem.result;
              accItem.balanceLoader = false;
              accItem.opnBal = currentBalance;
            }
          });
        });

        let { showInactiveAcc } = this.state;
        let filteredData: any[] = [];
        if (showInactiveAcc) {
          filteredData = data;
        } else {
          filteredData = data.filter((item: any) => item.status?.toString().toLowerCase() === 'active');
        }
        this.setState({
          mainAccountsTableData: data,
          accountsTableData: filteredData,
          accountsExportTableData: filteredData,
          isBalLoading: (currentLen < data.length / colCounter)
        }, () => {
          this.nextList(data);
          this.readyForExport(this.state.accountsTableData);
        })
      }
      resolve();
    }).catch((error: any) => {
      reject();
    });
  }

  searchResult = (resultAfterSearch: any) => {
    dataAfterSearch = resultAfterSearch;
    this.readyForExport(dataAfterSearch);
  }

  readyForExport = (resultAfterSearch: any) => {

    resultAfterSearch = resultAfterSearch.map((item: any, id: any) => {
      const _item: any = {};
      _item["Account Number"] = item.oBaccno;
      _item["Bank"] = item.bName;
      _item["COA"] = item.coa;
      _item["Nickname"] = item.displayName;
      _item["EHID"] = item.letterCodeToolTip;
      _item["Entity Name"] = item.entityName;
      _item["Account Type"] = item.acctType;
      _item["Inn-Flow Balance"] = Utils.amountFormatted(Number(item.opnBal).toFixed(2));
      _item["Bank Account Balance"] = item.bankConnectionStatus.toString().toLowerCase() === "yes" ? Utils.amountFormatted(Number(item.currentBalance).toFixed(2)) : "-";
      _item["Bank Sync Last Updated"] = item?.balUpdateDatetime;
      _item["Status"] = item.status;
      return _item;
    });
    exportDataSet.data = resultAfterSearch;
  }

  renderLetterCode = (cell: any, row: any, rowIndex) => {
    let isCreditEntry = row.acctType === "Credit Card";
    return (
      <div onClick={() => { isCreditEntry ? this.showCreditCardSlideout(row, "EditRow") : this.showSlideout(row, "EditRow") }}>
        {row.letterCode.toString().toLowerCase().includes("shared (") === true ? (
          <div className="accounts_PageRow">
            <strong className="d-flex">
              <OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{row.letterCodeToolTip}</Tooltip>}>
                <div>{row.letterCode}</div>
              </OverlayTrigger>
            </strong>
            <span></span>
            {this.state.showSensitiveInfo && (<span></span>)}
          </div>
        ) : (
          <EllipsisWithTooltip placement="top">
            <div className="accounts_PageRow">
              <strong>{row.letterCode}</strong>
              <span></span>
              {this.state.showSensitiveInfo && (<span></span>)}
            </div>
          </EllipsisWithTooltip>
        )}
      </div>
    );
  };

  handleSelectedItem = (selected) => {
    this.setState({ isMountDone: false });
    let acntDataFilter: any[] = [];
    if (selected === "fromMethod") {
      let data = this.state.accountTypeList.filter(
        (item) => item.name === this.state.defaultSelectOPtion
      );
      selected = data[0];
    }

    this.setState({
      selectedAcType: selected.name,
      defaultSelectOPtion: selected.name,
    });
    if (selected.id >= 0) {
      if (this.state.showInactiveAcc) {
        acntDataFilter = _.cloneDeep(this.state.mainAccountsTableData);
      } else {
        acntDataFilter = _.cloneDeep(this.state.tableDataFilter);
      }
      acntDataFilter = acntDataFilter.filter(
        (item) => item.acctType === selected.name
      );
      this.setState({ accountsTableData: acntDataFilter, accountsExportTableData: acntDataFilter }, () => {
        this.sortChange(this.state.sortingOrder);
      });
    }
    if (selected.id < 0) {
      if (this.state.showInactiveAcc)
        this.setState(
          { accountsTableData: this.state.mainAccountsTableData, accountsExportTableData: this.state.mainAccountsTableData },
          () => {
            this.sortChange(this.state.sortingOrder);
          }
        );
      else
        this.setState({ accountsTableData: this.state.tableDataFilter, accountsExportTableData: this.state.tableDataFilter }, () => {
          this.sortChange(this.state.sortingOrder);
        });
    }
    this.readyForExport(this.state.accountsTableData);
  };

  showInactiveAccount = (e) => {
    let { checked } = e.target;
    let acntDataFilter: any[] = [];

    this.setState({ showInactiveAcc: checked });
    if (checked) {
      acntDataFilter = _.cloneDeep(this.state.mainAccountsTableData);
    } else {
      acntDataFilter = _.cloneDeep(this.state.tableDataFilter);
    }
    if (
      this.state.selectedAcType !== "" &&
      this.state.selectedAcType !== "All"
    ) {
      acntDataFilter = acntDataFilter.filter(
        (item) => item.acctType === this.state.selectedAcType
      );
    }
    let ifInacFount = acntDataFilter.some(item => item.status.toString().toLowerCase() === "inactive");
    this.setState({ accountsTableData: acntDataFilter, tableClass: ifInacFount === true ? "accountsTable accounts-tableWide" : "accountsTable accounts-tableWide" }, () => {
      this.sortChange(this.state.sortingOrder);
    });
  };

  formatAmmount = (cell: any, row: any, rowIndex) => {
    let showBalance = row.isViewBankGLBalances ? Utils.currencyFormat(Number(row.opnBal).toFixed(2)) : "xxxx";
    let isCreditEntry = row.acctType === "Credit Card";
    return (
      <div className="border-left accounts_PageRow accounts_RowRight" onClick={() => { isCreditEntry ? this.showCreditCardSlideout(row, "EditRow") : this.showSlideout(row, "EditRow") }}>
        <strong>
          {row.balanceLoader ? (<div className="single-loader loadingAnimation balanceLoader"></div>) : (<EllipsisWithTooltip placement="top">{showBalance}</EllipsisWithTooltip>)}
        </strong>
        <span></span>
        {this.state.showSensitiveInfo && (<span></span>)}
      </div>
    )
  };

  CleanSearch = () => {
    if (this.tableSearch.current !== null)
      this.tableSearch.current.CleanSearch();
  };

  sortChange = (item: any) => {
    let tableData = [...this.state.accountsTableData];
    let data = tableData;
    if (item === "Bank") {
      data = _.orderBy(data, [(obj) => obj?.bName?.trim().toUpperCase()], ['dsort']);
    } else if (item === "EHID") {
      data = _.orderBy(data, [(obj) => obj?.letterCode?.trim().toUpperCase()], ['dsort']);
    } else if (item === "Name") {
      data = _.orderBy(data, [(obj) => obj?.displayName?.trim().toUpperCase()], ['dsort']);
    } else if (item === "Type") {
      data = _.sortBy(data, "dsort");
    }
    this.setState({ accountsTableData: data, sortingOrder: item, accountsExportTableData: data, isMountDone: true }, () => {
      let expData = (dataAfterSearch.length < data.length) ? dataAfterSearch : data;
      this.readyForExport(expData);
    });
  };

  saveDefaultView = (): void => {
    const laborSaveAsDefaultdtos: any[] = [];
    const { sortingOrder, showInactiveAcc } = this.state;
    laborSaveAsDefaultdtos.push({
      fieldName: "toggle",
      fieldValue: showInactiveAcc,
      pageName: this.pageName,
    });
    laborSaveAsDefaultdtos.push({
      fieldName: "sort",
      fieldValue: sortingOrder,
      pageName: this.pageName,
    });
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "AccountsHome"
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "AccountsHome"
        });
        this.setState({ showLoader: false });
      });
  };

  resetRowState = () => {
    let { connectBank } = this.state;
    connectBank.accRowoprID = "";
    connectBank.accRowacctType = "";
    connectBank.accRowhidValue = "";
    connectBank.accRowhotelName = "";
    connectBank.accRowaccountName = "";
    connectBank.accDisplayName = "";
    connectBank.CCdisplayName = "";
    this.setState({ connectBank, isForRowBankSinc: false, isForRowEdit: false })
  }

  handleRowEvent = (row: any, type: any) => {
    let { connectBank, isForRowBankSinc, isForRowEdit } = this.state;
    connectBank.accRowoprID = row.oprid;
    connectBank.accRowacctType = row.acctType.charAt(0);
    connectBank.accRowhidValue = row.hid;
    connectBank.accRowhotelName = row.hidLetterCode;
    connectBank.accRowaccountName = row.accountName;
    connectBank.accDisplayName = row.accDisplayName;
    connectBank.CCdisplayName = row?.displayName;
    if (type === "EditRow") {
      isForRowEdit = true
    } else {
      isForRowBankSinc = true
    }
    this.setState({ connectBank, isForRowBankSinc, isForRowEdit })
  }

  showSlideout = (row: any, type: any) => {
    if (row !== "") {
      this.handleRowEvent(row, type);
    }
    this.setState({ isShowSlideout: true });
  }
  showCreditCardSlideout = (row: any, type: any) => {
    if (row !== "") {
      this.handleRowEvent(row, type);
    }
    this.setState({ isShowCreditCardSlideout: true });
  }

  hideSlideOut(mode) {
    if (mode === 1) {
      this.CleanSearch();
      this.setState({ isShowSlideout: false, showLoader: true, }, () => {
        let request = {} as any;
        request.HID = this.state.hidValue;
        this.getAccountsData(request);
      });
    } else {
      this.setState({ isShowSlideout: false })
    }
    this.resetRowState();
  }

  hideCreditCardSlideOut(mode) {
    if (mode === 1) {
      let request = {} as any;
      request.hotelID = this.state.hidValue;
      request.lettercode = this.state.hotelName;
      this.hotelChange(request);
    }
    this.setState({ isShowCreditCardSlideout: false })
    this.resetRowState();
  }

  downloadExcel = () => {
    const fileName = `${"accounts"}-${JSON.parse(localStorage.storage).currentUtcTime.replace(' PM', '').replace(' AM', '').replace(' ', '-').replace(':', '-').replace(':', '-')}`;
    const worksheet = XLSX.utils.json_to_sheet(exportDataSet.data);
    const header = Object.keys(exportDataSet.data[0]);
    let wscols: any = [];
    for (let i = 0; i < header.length; i++) { 
      wscols.push({ wch: header[i].length + 10 })
    }
    worksheet["!cols"] = wscols;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    XLSX.writeFile(workbook, fileName + ".xlsx", {});
  };

  handleTransactions = (row: any) => {
    this.GetDefaultHotelByAccount(row.oprid, row.acctType, "toBankSync");
  }

  rowClickEvents = (row: any) => {
    this.GetDefaultHotelByAccount(row.oprid, row.acctType, "toRegister");
  }

  render() {
    const columns = [{
      dataField: "accDisplayName",
      text: "Name",
      searchable: true,
      headerClasses: "accountName",
      classes: "accountName",
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="accounts_PageRow" onClick={() => { this.rowClickEvents(row) }}>
            <div className="d-flex setHeader">
              <EllipsisWithTooltip placement="top">{row.accDisplayName}</EllipsisWithTooltip>
              {row.status.toString().toLowerCase() === "inactive" && (<span className="ml-1 accCompactBadge badge badge-light">Inactive</span>)}
            </div>
            <span><EllipsisWithTooltip placement="top">{row.bName}</EllipsisWithTooltip></span>
            {this.state.showSensitiveInfo && (<span><EllipsisWithTooltip placement="top">{row.oBaccno}</EllipsisWithTooltip></span>)}
          </div>
        )
      },
    }, {
      dataField: "coa",
      text: "COA",
      searchable: true,
      formatter: (cell: any, row: any, rowIndex: any) => {
        let isCreditEntry = row.acctType === "Credit Card";
        return (
          <div className="accounts_PageRow"
            onClick={() => { isCreditEntry ? this.showCreditCardSlideout(row, "EditRow") : this.showSlideout(row, "EditRow") }}>
            <strong><EllipsisWithTooltip placement="top">{row.coa}</EllipsisWithTooltip></strong>
            <span></span>
            {this.state.showSensitiveInfo && (<span></span>)}
          </div>
        )
      }
    }, {
      dataField: "letterCode",
      text: "EHID",
      searchable: false,
      headerClasses: "accountEhid",
      classes: "accountEhid",
      formatter: (cell: any, row: any, rowIndex: any) => this.renderLetterCode(cell, row, rowIndex),
    }, {
      dataField: "accountName",
      text: "",
      searchable: true,
      hidden: true,
    }, {
      dataField: "bName",
      text: "",
      searchable: true,
      hidden: true,
    }, {
      dataField: "oBaccno",
      text: "",
      searchable: true,
      hidden: true,
    }, {
      dataField: "splitName1",
      text: "",
      searchable: true,
      hidden: true,
    }, {
      dataField: "opnBal",
      text: "Inn-Flow Balance",
      searchable: false,
      headerClasses: "accountBalance text-right",
      classes: "accountBalance text-right",
      formatter: (cell: any, row: any, rowIndex: any) => this.formatAmmount(cell, row, rowIndex)
    }, {
      dataField: "currentBalance",
      text: "Bank Balance",
      searchable: false,
      headerClasses: "accountCurrentBalance text-right",
      classes: "accountCurrentBalance text-right",
      formatter: (cell: any, row: any, rowIndex: any) => {
        let isCreditEntry = row.acctType === "Credit Card";
        let isStatusActive = row.status?.toString().toLowerCase() === "active";
        return (
          <>
            {row.isViewBankGLBalances && isStatusActive ? (
              <div className="accounts_PageRow accounts_RowRight" onClick={() => { isCreditEntry ? this.showCreditCardSlideout(row, "EditRow") : this.showSlideout(row, "EditRow") }}>
                <strong>{row.currentBalance !== "" ? (<EllipsisWithTooltip placement="top">{Utils.currencyFormat(row.currentBalance)}</EllipsisWithTooltip>) : (<>-</>)}</strong>
                <span>{row.balUpdatetime !== "" ? (<>Updated {row.balUpdatetime}</>) : (<>&nbsp;</>)}</span>
                {this.state.showSensitiveInfo && (<span></span>)}
              </div>
            ) : (
              <div className="accounts_PageRow accounts_RowRight" onClick={() => { isCreditEntry ? this.showCreditCardSlideout(row, "EditRow") : this.showSlideout(row, "EditRow") }}>
                <strong>-</strong>
                <span></span>
                {this.state.showSensitiveInfo && (<span></span>)}
              </div>
            )}
          </>
        )
      },
    }, {
      dataField: "",
      text: "Bank Sync",
      searchable: false,
      headerClasses: "accountReview",
      classes: "accountReview",
      formatter: (cell: any, row: any, rowIndex: any) => {
        let isStatusActive = row.status?.toString().toLowerCase() === "active";
        let isConnectBank = row.connectBankPer?.toString().toLowerCase();
        let isConnectStatus = row.bankConnectionStatus.toString().toLowerCase();
        let showTransaction = (isConnectBank === "no" ? true : true);
        return (
          <>
            {isStatusActive && (
              <>
                {isConnectStatus === "yes" && (
                  <>
                    {showTransaction && (
                      <Button className="btn-outline-primary w120 accBtnSmall" onClick={() => this.handleTransactions(row)}>{Number(row.reviewTransCount)} Transactions</Button>
                    )}
                  </>
                )}
                {isConnectStatus === "no" && (
                  <>
                    {isConnectBank === "yes" && (
                      <div className="accounts_PageRow">
                        <strong className="reviewTransCount">-</strong>
                        <span></span>
                        {this.state.showSensitiveInfo && (<span></span>)}
                      </div>
                    )}
                    {isConnectBank === "no" && (
                      <div className="accounts_PageRow">
                        <strong className="reviewTransCount">-</strong>
                        <span></span>
                        {this.state.showSensitiveInfo && (<span></span>)}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      }
    }];

    return (
      <>
        <ToastContainer enableMultiContainer
          autoClose={3000}
          containerId={"AccountsHome"} />
        <div className="accountsPageNew" onClick={this.searchClear}>
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <Dropdown className="hid-select">
                <Dropdown.Toggle
                  id="dropdown-hid"
                  disabled={this.state.showLoader}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    {this.state.hotelName}
                    <div className="drop-arrow d-flex">
                      <FiChevronDown />
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="cusEhidSelector">
                  <div className="EhidSearch">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        name="search"
                        value={this.state.search}
                        autoComplete="off"
                        onChange={this.searchItems}
                        placeholder="Filter EHIDs"
                      />
                    </Form.Group>
                  </div>
                  <div className="dropdownItems">
                    {this.state.hidList.length > 0 ? (
                      this.state.hidList.map(
                        (item: any, idx: number) => (
                          <React.Fragment key={item?.hotelID}>
                            {(idx > 0
                              && item?.hotelType !== this.state.hidList[idx - 1]?.hotelType) && (
                                <Dropdown.Divider />

                              )}
                            <Dropdown.Item
                              eventKey={item.lettercode}
                              onClick={() => this.hotelChange(item)}
                            >
                              <div
                                className={
                                  item.lettercode === this.state.hotelName
                                    ? "dropDownListTitle hasCheck"
                                    : "dropDownListTitle"
                                }
                              >
                                <span className="name">
                                  {item.hotelName.split(" - ")[0]}
                                </span>
                                <span className="title">
                                  {
                                    item.hotelName
                                      .replace(" - ", ".")
                                      .split(".")[1]
                                  }
                                </span>
                                {item.lettercode === this.state.hotelName && (
                                  <span className="itemcheck">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                        fill="#2CAF92"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </Dropdown.Item>
                          </React.Fragment>
                        )
                      )
                    ) : (
                      <Dropdown.Item>
                        <b>No Item Found</b>
                      </Dropdown.Item>
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <div className="mr-auto">Accounts</div>
              <div className="action-group d-flex">
                {this.state.hotelName !== "Select" && !this.state.showLoader && this.state.hasBankPerm && this.state.isConnectBankPerm && (
                  <Button type="button" onClick={() => { this.showBankSyncWizard() }}>
                    Bank Sync Connections
                  </Button>
                )}
                {(this.state.hotelName !== "All" && !this.state.isAddAccountDisbaled) && (
                  <>
                    {this.state.isLimitedsubs === "No" && (
                      <Button className="btn-outline-primary" type="button" onClick={() => { this.showCreditCardSlideout("", "") }}>
                        Add Credit Card Account
                      </Button>
                    )}
                    <Button type="button" onClick={() => { this.showSlideout("", "") }}>
                      Add Account
                    </Button>
                  </>
                )}
              </div>
              {this.state.hotelName !== "Select" && !this.state.showLoader &&
                <>
                  <div className="action-group d-flex">
                    <Dropdown className="more-action" alignRight>
                      <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                          <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                          <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {this.state.accountsExportTableData.length > 0 && (
                          <>
                            {exportDataSet.data.length === 0 || this.state.isBalLoading ? (
                              <Dropdown.Item className="disabled-area">Export</Dropdown.Item>
                            ) : (
                              <Dropdown.Item eventKey="export" key="export" onClick={this.downloadExcel}>Export</Dropdown.Item>
                            )}
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                </>
              }
            </div>

            {this.state.showLoader ? (
              <ReactPageLoader useas={"accounts"} />
            ) : (
              <>
                {this.state.isMountDone && (
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.accountsTableData}
                    columns={columns}
                    search={{
                      afterSearch: (newResult) => this.searchResult(newResult)
                    }}
                    hover
                  >
                    {(props: {
                      searchProps: JSX.IntrinsicAttributes;
                      baseProps: JSX.IntrinsicAttributes;
                    }) => (
                      <React.Fragment>
                        <div className="navigation-menus d-flex">
                          <div className="navigationSort">
                            <Dropdown className="more-action bg-blue" alignRight>
                              <Dropdown.Toggle id="dropdown-more">
                                <div className="d-flex align-items-center">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g>
                                      <path fill="none" d="M0 0h24v24H0z"></path>
                                      <path d="M2 20h20v2H2v-2zm2-8h2v7H4v-7zm5 0h2v7H9v-7zm4 0h2v7h-2v-7zm5 0h2v7h-2v-7zM2 7l10-5 10 5v4H2V7zm10 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                                    </g>
                                  </svg>

                                  {this.state.defaultSelectOPtion}
                                  <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>Account Type</Dropdown.Header>
                                {this.state.accountTypeList.map(
                                  (item: any, idx: any) => (
                                    <Dropdown.Item
                                      key={item?.id}
                                      className={this.state.selectedAcType === item.name ? `${this.state.isBalLoading ? "disabled-area" : "active"}` : `${this.state.isBalLoading ? "disabled-area" : ""}`}
                                      eventKey={item}
                                      onClick={() =>
                                        this.handleSelectedItem(item)
                                      }
                                    >
                                      <div className="d-flex">
                                        <div className="mr-auto">
                                          {" "}
                                          {item.name}
                                        </div>
                                        <span className="itemcheck">
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                              fill="#2CAF92"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <TableSearch
                            id="tableSearch"
                            key="tableSearch"
                            ref={this.tableSearch}
                            {...props.searchProps}
                            CleanSearch={this.CleanSearch.bind(this)}
                            placeholder={"Search"}
                          />

                          <div
                            className="navigationSort ml-auto"
                            style={{ paddingTop: 10, paddingLeft: 10 }}
                          >
                            <Dropdown className="more-action bg-blue" alignRight>
                              <Dropdown.Toggle id="dropdown-more">
                                <div className="d-flex align-items-center">
                                  <svg
                                    width="16"
                                    height="12"
                                    viewBox="0 0 16 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="svgIcon"
                                  >
                                    <path d="M3.5 12L6.5 9H4.25V0H2.75V9H0.5L3.5 12ZM7.25 3H14V4.5H7.25V3ZM7.25 6H12.5V7.5H7.25V6ZM7.25 0H15.5V1.5H7.25V0ZM7.25 9H11V10.5H7.25V9Z" />
                                  </svg>

                                  {this.state.sortingOrder}

                                  <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>SORT</Dropdown.Header>
                                {this.state.sortingOrderList.map(
                                  (item: any, idx: any) => (
                                    <Dropdown.Item
                                      key={item}
                                      className={this.state.sortingOrder === item ? `${this.state.isBalLoading ? "disabled-area" : "active"}` : `${this.state.isBalLoading ? "disabled-area" : ""}`}
                                      eventKey={item}
                                      onClick={() => this.sortChange(item)}
                                    >
                                      <div className="d-flex">
                                        <div className="mr-auto"> {item}</div>
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                            fill="#2CAF92"
                                          />
                                        </svg>
                                      </div>
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <Form.Check disabled={this.state.isBalLoading} id="showInactive" key="showInactive" type="switch" label="Show Inactive" checked={this.state.showInactiveAcc} onChange={this.showInactiveAccount} />
                          <div
                            className="navigationSettings"
                            style={{ paddingTop: 8, paddingLeft: 10 }}
                          >
                            <Dropdown className="more-action bg-blue">
                              <Dropdown.Toggle id="dropdown-ptoType">
                                <div className="d-flex align-items-center">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M1.56286 10.1808L2.89619 12.4875C3.08019 12.8062 3.48819 12.9155 3.80686 12.7308L4.73752 12.1935C5.12419 12.4982 5.55152 12.7482 6.00086 12.9348V14.0002C6.00086 14.3682 6.29886 14.6668 6.66752 14.6668H9.33419C9.70286 14.6668 10.0009 14.3682 10.0009 14.0002V12.9348C10.4502 12.7482 10.8769 12.4982 11.2642 12.1935L12.1949 12.7308C12.5129 12.9142 12.9222 12.8042 13.1055 12.4875L14.4389 10.1808C14.6222 9.8635 14.5122 9.4535 14.1955 9.27016L13.2809 8.7415C13.3155 8.4955 13.3335 8.2475 13.3335 8.00016C13.3335 7.75283 13.3155 7.50483 13.2795 7.25883L14.1942 6.73016C14.5115 6.54683 14.6215 6.13683 14.4375 5.8195L13.1042 3.51283C12.9202 3.19416 12.5122 3.08483 12.1935 3.2695L11.2629 3.80683C10.8762 3.50216 10.4495 3.2515 10.0002 3.0655V2.00016C10.0002 1.63216 9.70219 1.3335 9.33352 1.3335H6.66686C6.29819 1.3335 6.00019 1.63216 6.00019 2.00016V3.0655C5.55086 3.25216 5.12419 3.50216 4.73686 3.80683L3.80686 3.2695C3.48752 3.08483 3.08019 3.19416 2.89619 3.51283L1.56286 5.8195C1.37953 6.13683 1.48953 6.54683 1.80619 6.73016L2.72086 7.25883C2.68486 7.50483 2.66686 7.75283 2.66686 8.00016C2.66686 8.2475 2.68486 8.4955 2.72086 8.7415L1.80619 9.27016C1.48886 9.4535 1.37886 9.8635 1.56286 10.1808ZM8.00019 5.3335C9.47086 5.3335 10.6669 6.5295 10.6669 8.00016C10.6669 9.47083 9.47086 10.6668 8.00019 10.6668C6.52952 10.6668 5.33352 9.47083 5.33352 8.00016C5.33352 6.5295 6.52952 5.3335 8.00019 5.3335Z" />
                                  </svg>
                                  <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => this.saveDefaultView()}
                                >
                                  Save as Default View
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className={this.state.tableClass}>
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="id"
                            // rowEvents={this.rowEvents}
                            hover
                            condensed
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}

              </>
            )}
          </Container>
          {this.state.isShowSlideout && (
            <BankAccountSlideout
              hidValue={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accRowhidValue : this.state.hidValue}
              hotelName={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accRowhotelName : this.state.hotelName}
              actionType={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? "edit" : "new"}
              callFrom={(this.state.isForRowBankSinc) ? "accountsPage" : ""}
              oprID={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accRowoprID : ''}
              accountName={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accRowaccountName : ""}
              acctType={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accRowacctType : ''}
              hideSlideOut={this.hideSlideOut.bind(this)}
              accDisplayName={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accDisplayName : ""}
            />
          )}
          {this.state.isShowCreditCardSlideout && (
            <CreditCardAccountSlideout
              hidValue={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accRowhidValue : this.state.hidValue}
              hotelName={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accRowhotelName : this.state.hotelName}
              actionType={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? "edit" : "new"}
              oprID={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accRowoprID : ''}
              acctType={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accRowacctType : ''}
              hideCreditCardSlideOut={this.hideCreditCardSlideOut.bind(this)}
              accDisplayName={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.accDisplayName : ""}
              accountName={(this.state.isForRowBankSinc || this.state.isForRowEdit) ? this.state.connectBank.CCdisplayName : ""}
              callFrom={(this.state.isForRowBankSinc) ? "accountsPage" : ""}
            ></CreditCardAccountSlideout>
          )}
          {this.state.isBankWizardShow && (
            <BankSyncWizard
              showBankSyncWizard={this.showBankSyncWizard}
              hotelID={this.state.hidList[0].hotelID}
              reloadPage={this.hideSlideOut.bind(this)}
            ></BankSyncWizard>
          )}
        </div>
      </>
    );
  }
 
  showBankSyncWizard = () => {
    this.setState({ isBankWizardShow: !this.state.isBankWizardShow })
  }
}
