import React from "react";
import {
  Form,
  Dropdown,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Spinner,
  ModalFooter,
} from "react-bootstrap";
import _ from "lodash";
import { FiChevronDown } from "react-icons/fi";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "../../../../Modules/Accounting/vendor.scss";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { resolve, reject } from "q";
import { Hotel } from "../../../../Common/Services/Hotel";
import { toast } from "react-toastify";
import { VendorService } from "../../../../Common/Services/vendor";
import OutsideClickHandler from "react-outside-click-handler";
import { DropDownList } from "../../../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../../../Common/StaticArrays";
import { Utils } from "../../../../Common/Utilis";
import { Register as registersevice } from "../../../../Common/Services/Register";
import { InvoiceApprovalS } from "../../../../Common/Services/InvoiceApprovals";

let currentTabIndex: any = 0;
let MaxTabIndex: any = 0;
export class AddVendorModal extends React.Component<any, any> {
  private vendorInputControl: any;
  constructor(props: any) {
    super(props);
    this.vendorInputControl = React.createRef();
    this.state = {
      vendorListShow: "",
      hidList: [],
      vendorList: [],
      mainVendorList: [],
      loader: false,
      AddVendoModalShow: true,
      vendorInput: "",
      taxIdSuffix: "",
      taxIDPrefix: "Tax ID",
      defaultMethod: "Check",
      phone: "",
      Email: "",
      invoicesDuesAfter: "0",
      salesTaxCheck: true,
      vendorCheck: false,
      streetAddress: "",
      city: "",
      states: "",
      zip: "",
      defaultHID: [],
      customerNo: "",
      isVendorReady: false,
      isListOpen: false,
      isAddVendorBtnVisible: false,
      isLoadComplete: false,
      cursor: -1,
      cursorDetails: 1,
      isEmail: "",
      isStreet: "",
      isCity: "",
      isState: "",
      zipCodeError: false,
      isEpayEnable: false,
    };
  }

  componentDidMount() {
    this.GetVendorTranlist();
    this.CheckEpayEnabled();
  }

  CheckEpayEnabled = () => {
    InvoiceApprovalS.CheckEpayEnabled()
      .then(async (result: any) => {
        if (result?.message === "Success") {
          const isCustIDExist = result?.result?.isCustIDExist;
          const isePayEnabled = result?.result.isePayEnabled;

          this.setState({
            isEpayEnable: isCustIDExist && isePayEnabled ? true : false,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT });
        reject();
      });
  };

  //Chnage the state as show/hide for modal
  AddVendoModalShowHide = () => {
    this.setState({ AddVendoModalShow: !this.state.AddVendoModalShow }, () => {
      this.clearStates();
      this.props?.hideAddVendorModal();
    });
  };

  //Fun to get existing vendor list on load
  GetVendorTranlist = () => {
    $("#inputSearch").focus();
    VendorService.ExistingVendorList()
      .then(async (result: any | null) => {
        if (result !== null) {
          this.setState(
            {
              vendorList: [],
              mainVendorList: [],
            },
            () => {
              this.setState(
                {
                  vendorList: result,
                  mainVendorList: result,
                  loader: false,
                  isLoadComplete: true,
                },
                () => {
                  this.clearErrors();
                  this.filterFromVendorList(this.state.vendorInput);
                  //$('#inputSearch').focus();
                }
              );
            }
          );
        }
        resolve();
      })
      .catch((error) => {
        reject();
      })
      .finally(() => {
        this.loadHotelsDetails();
      });
  };

  //Fun to load the HID details as per user role
  loadHotelsDetails() {
    Hotel.getUserHotelAccess("Accounting")
      .then(async (result: any[] | null) => {
        if (result != null) {
          let HIDData = result;
          HIDData = HIDData.map((item: any, id: any) => {
            return {
              id: item.hotelID,
              label: item.lettercode,
            };
          });
          this.setState({ hidList: HIDData });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`);
        reject();
      });
  }

  renderTypeaheadChildren = (option, props, index) => {
    return (
      <div key={option.id}>
        <div className="vendor-name">
          <EllipsisWithTooltip placement="bottom">
            {option.label}
          </EllipsisWithTooltip>
        </div>
      </div>
    );
  };

  //Vendor input change event to search the existing vendor by name
  handleChange = (event) => {
    let inPutText = event.target.value;
    if (inPutText.trim() !== "") {
      inPutText = inPutText.trimLeft();
    }
    this.clearErrors();
    this.filterFromVendorList(inPutText);
  };

  //Clear controls error border
  clearErrors = () => {
    $("#inputSearch").removeClass("alert-danger");
    $("#streetAddress").removeClass("alert-danger");
    $("#MaskStreet").removeClass("alert-danger");
    $("#city").removeClass("alert-danger");
    $("#MaskCity").removeClass("alert-danger");
    $("#state").removeClass("alert-danger");
    $("#MaskState").removeClass("alert-danger");
    $("#zip").removeClass("alert-danger");
    $("#ehid").removeClass("alert-danger");
    $("#mail").removeClass("alert-danger");
  };

  filterFromVendorList = (name: any) => {
    this.setState({
      vendorInput: name.toString(),
      isVendorReady: false,
    });
    let newResult: any = [];
    if (name?.trim().length > 2) {
      let vendorDataFilter: any[] = [];

      vendorDataFilter = _.cloneDeep(this.state.mainVendorList);

      vendorDataFilter = _.reject(vendorDataFilter, (o) =>
        _.includes(o.company?.toLowerCase(), "generic vendor")
      );

      newResult = _.filter(vendorDataFilter, (o) =>
        _.includes(o.company?.toLowerCase(), name?.trim()?.toLowerCase())
      );

      newResult.map((item, index) => (item["id"] = index));

      const isExectMatch = newResult.some(
        (item) => item?.company?.toLowerCase() === name?.trim()?.toLowerCase()
      );

      this.setState({
        vendorList: newResult,
        isListOpen: true,
        isAddVendorBtnVisible: isExectMatch,
      });
    } else {
      this.setState({
        isListOpen: false,
        isAddVendorBtnVisible: false,
      });
    }
  };

  handleOutsideClick = (e) => {
    this.setState({ isListOpen: false, isAddVendorBtnVisible: false });
  };

  handleClick = () => {
    MaxTabIndex = 0;
    if (this.state.vendorInput?.trim().length > 2) {
      const isExectMatch = this.state.vendorList.some(
        (item) =>
          item?.company?.toLowerCase() ===
          this.state.vendorInput?.trim()?.toLowerCase()
      );
      this.setState({
        isListOpen: true,
        isAddVendorBtnVisible: isExectMatch,
        cursor: -1,
        cursorDetails: 1,
      });
    }
  };

  render() {
    let {
      AddVendoModalShow,
      customerNo,
      invoicesDuesAfter,
      isVendorReady,
      vendorInput,
      isAddVendorBtnVisible,
      taxIdSuffix,
      taxIDPrefix,
      defaultMethod,
      phone,
      Email,
      salesTaxCheck,
      vendorCheck,
      streetAddress,
      city,
      states,
      zip,
      defaultHID,
      isLoadComplete,
      isEmail,
      isStreet,
      isCity,
      isState,
      isCustomer,
    } = this.state;
    let isSpclCharMsg =
      "Fields containing only special characters is not allowed. Please update the field.";
    return (
      <Modal
        centered
        show={AddVendoModalShow}
        onHide={this.AddVendoModalShowHide}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="VendorDialog-MPM"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="">Add New Vendor</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="AddVendor-MPM">
            <div className="addVendorForm">
              <div className="borderDiv">
                <div className="Row-MPM">
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <input
                      ref={this.vendorInputControl}
                      id="inputSearch"
                      key={"text-Filter"}
                      type="text"
                      maxLength={50}
                      className="form-control"
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      autoComplete="Off"
                      value={vendorInput}
                      onClick={(e) => {
                        this.handleClick();
                      }}
                      onKeyDown={this.handleKeyDown}
                    />
                  </Form.Group>
                </div>
                {this.state.isListOpen && isLoadComplete && (
                  <React.Fragment>
                    <OutsideClickHandler
                      onOutsideClick={this.handleOutsideClick}
                    >
                      <div className="VendorList-MPM">
                        <div className="pos-rel">
                          <div
                            className="scrollList VendorListScroll"
                            id="VendorListScrol"
                          >
                            <div className="borderDiv" id="borderDiv">
                              <div className="CurrentEHID" id="CurrentEHID">
                                {this.state.vendorList.map((items, index) =>
                                  this.renderVendorList(items, index)
                                )}
                              </div>
                            </div>
                          </div>

                          {!isAddVendorBtnVisible && (
                            <div
                              className={
                                this.state.cursor === MaxTabIndex + 1
                                  ? "activeVendor AddVendorFooter vendorListFocusSet"
                                  : "AddVendorFooter"
                              }
                              tabIndex={MaxTabIndex + 1}
                              onKeyDown={(e) => {
                                this.handleKeyDownArrow(e, "", "AddVendorBtn");
                              }}
                            >
                              <button
                                id="addNewVendor"
                                type="button"
                                className="btn wht-bg txt-green addVendorButton"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({
                                    isVendorReady: true,
                                    isListOpen: false,
                                    isAddVendorBtnVisible: false,
                                  });
                                }}
                              >
                                Add Vendor "{vendorInput?.trim()}"
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </OutsideClickHandler>
                  </React.Fragment>
                )}
                <div className="Row2-MPM">
                  <Row>
                    <Col sm="6">
                      <Form.Group>
                        <Form.Label>Tax Identification Number</Form.Label>
                        <div className="d-flex">
                          <input
                            type="text"
                            id="taxIdSuffix"
                            className="form-control taxID-MPM"
                            placeholder="Optional"
                            value={taxIdSuffix}
                            maxLength={9}
                            disabled={!isVendorReady}
                            onChange={(e) => {
                              this.onChange(e, "taxIdSuffix");
                            }}
                            onBlur={(e) => {
                              this.validateInputsFormat("taxIdSuffix");
                            }}
                            onFocus={(e) => {
                              this.onFocus(e, "taxIdSuffix");
                            }}
                          />
                          <div className="singleSelect">
                            <Dropdown className="more-action bg-blue single-select-image">
                              <Dropdown.Toggle
                                id="Modal-hid"
                                className="filter-btn taxIDDrop-MPM"
                                disabled={!isVendorReady}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="txt-area">{taxIDPrefix}</div>
                                  <div className="drop-arrow ml-0 d-flex">
                                    <FiChevronDown />
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    this.onChange(e, "taxIDPrefix");
                                  }}
                                >
                                  TAX ID
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    this.onChange(e, "taxIDPrefix");
                                  }}
                                >
                                  SSN
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm="3">
                      <Form.Label>Default Method</Form.Label>
                      <div className="singleSelect">
                        <Dropdown className="more-action bg-blue single-select-image">
                          <Dropdown.Toggle
                            id="Modal-hid"
                            className="filter-btn"
                            disabled={!isVendorReady}
                          >
                            <div className="d-flex align-items-center">
                              <div className="txt-area">{defaultMethod}</div>
                              <div className="drop-arrow ml-0 d-flex">
                                <FiChevronDown />
                              </div>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                this.onChange(e, "DefaultMethod");
                              }}
                            >
                              Manual ACH
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                this.onChange(e, "DefaultMethod");
                              }}
                            >
                              Check
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                this.onChange(e, "DefaultMethod");
                              }}
                            >
                              Credit Card
                            </Dropdown.Item>
                            {this.state?.isEpayEnable && (
                              <Dropdown.Item
                                onClick={(e) => {
                                  this.onChange(e, "DefaultMethod");
                                }}
                              >
                                ePay
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                    <Col sm="3">
                      <Form.Group>
                        <Form.Label>Invoices Due After</Form.Label>
                        <Form.Control
                          id="invoices"
                          value={invoicesDuesAfter}
                          autoComplete="Off"
                          placeholder="Optional"
                          maxLength={2}
                          disabled={!isVendorReady}
                          onChange={(e) => {
                            this.onChange(e, "invoicesDuesAfter");
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="Row3-MPM">
                  <Row>
                    <Col sm="6">
                      <Form.Group>
                        <Form.Label>Phone Number </Form.Label>
                        <Form.Control
                          autoComplete="Off"
                          value={phone}
                          id="phone"
                          placeholder="Optional"
                          maxLength={20}
                          disabled={!isVendorReady}
                          onChange={(e) => {
                            this.onChange(e, "phone");
                          }}
                          onBlur={(e) => {
                            this.validateInputsFormat("phone");
                          }}
                          className="VendorModalInput"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          autoComplete="Off"
                          value={Email}
                          id="email"
                          disabled={!isVendorReady}
                          placeholder="Optional"
                          onChange={(e) => {
                            this.onChange(e, "email");
                          }}
                          onBlur={(e) => {
                            this.validateInputsFormat("EmailControl");
                          }}
                          maxLength={50}
                          style={
                            isEmail === "Yes"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        />
                        <button
                          className={Email === "" ? "custom-btn" : "custom-btn"}
                          style={
                            isEmail === "Yes"
                              ? { display: "none", width: "100%" }
                              : { display: "block", width: "100%" }
                          }
                          disabled={!isVendorReady}
                          id="MaskEmail"
                          //  placeholder="Optional"
                          onClick={() => {
                            this.handleDescriptionFocus("email");
                          }}
                          onFocus={() => {
                            this.handleDescriptionFocus("email");
                          }}
                        >
                          {Email !== "" && (
                            <EllipsisWithTooltip placement="bottom">
                              {Email}
                            </EllipsisWithTooltip>
                          )}
                          {Email === "" && (
                            <div className="OptionalEmailPH">{"Optional"}</div>
                          )}
                        </button>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="Row4-MPM">
                  <Row className="section1-MPM">
                    <Col sm="3" className="d-flex">
                      <Form.Check
                        type="checkbox"
                        id="salescheck"
                        className="customeCheck salesCheck form-label"
                        label="Sales Tax"
                        value="Sales Tax"
                        checked={salesTaxCheck}
                        disabled={!isVendorReady}
                        custom
                        onChange={(e) => this.onChange(e, "salescheck")}
                      />
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id="button-tooltip-helpText"
                            style={{ opacity: 1 }}
                          >
                            <span>
                              Enable if sales tax is included on the invoice.
                              Disable if sales tax has not been paid by the
                              vendor, and if use tax is required to be paid on
                              the items purchased.
                            </span>
                          </Tooltip>
                        }
                      >
                        <span className="help-icon salesIcon-MPM">
                          <svg
                            stroke="currentColor"
                            fill="#6a6e73"
                            stroke-width="0"
                            version="1.1"
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                            <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                            <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
                          </svg>{" "}
                        </span>
                      </OverlayTrigger>
                    </Col>
                    <Col sm="9">
                      <Form.Check
                        id="vendorcheck"
                        type="checkbox"
                        className="form-label"
                        label="1099 Vendor"
                        value="1099 Vendor"
                        checked={vendorCheck}
                        custom
                        disabled={!isVendorReady}
                        onChange={(e) => this.onChange(e, "vendorcheck")}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="Row5-MPM">
                  <Row>
                    <Col sm="12">
                      <Form.Group>
                        <Form.Label>Street Address</Form.Label>
                        <Form.Control
                          value={streetAddress}
                          id="streetAddress"
                          autoComplete="Off"
                          maxLength={100}
                          disabled={!isVendorReady}
                          onChange={(e) => this.onChange(e, "streetAddress")}
                          style={
                            isStreet === "Yes"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          onBlur={(e) => {
                            this.validateInputsFormat("streetAddress");
                          }}
                        />
                        <button
                          className={
                            streetAddress === "" ? "custom-btn" : "custom-btn"
                          }
                          style={
                            isStreet === "Yes"
                              ? { display: "none", width: "100%" }
                              : { display: "block", width: "100%" }
                          }
                          disabled={!isVendorReady}
                          id="MaskStreet"
                          onClick={() => {
                            this.handleDescriptionFocus("streetAddress");
                          }}
                          onFocus={() => {
                            this.handleDescriptionFocus("streetAddress");
                          }}
                        >
                          {streetAddress !== "" && (
                            <EllipsisWithTooltip placement="bottom">
                              {streetAddress}
                            </EllipsisWithTooltip>
                          )}
                        </button>

                        {streetAddress.length > 0 &&
                          !this.validateAddress(streetAddress) && (
                            <div className="textError">{isSpclCharMsg}</div>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="Row6-MPM">
                  <Row>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          id="city"
                          autoComplete="Off"
                          value={city}
                          maxLength={50}
                          disabled={!isVendorReady}
                          onChange={(e) => this.onChange(e, "city")}
                          style={
                            isCity === "Yes"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          onBlur={(e) => {
                            this.validateInputsFormat("city");
                          }}
                        />
                        <button
                          className={city === "" ? "custom-btn" : "custom-btn"}
                          style={
                            isCity === "Yes"
                              ? { display: "none", width: "100%" }
                              : { display: "block", width: "100%" }
                          }
                          disabled={!isVendorReady}
                          id="MaskCity"
                          onClick={() => {
                            this.handleDescriptionFocus("city");
                          }}
                          onFocus={() => {
                            this.handleDescriptionFocus("city");
                          }}
                        >
                          {city !== "" && (
                            <EllipsisWithTooltip placement="bottom">
                              {city}
                            </EllipsisWithTooltip>
                          )}
                        </button>
                        {city.length > 0 && !this.validateAddress(city) && (
                          <div className="textError">{isSpclCharMsg}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        {/* <DropDownList
                                                    placeHolder={"Search..."}
                                                    data={_.sortBy(StaticArrays.userStates, 'label')}
                                                    defaultValue={states}
                                                    isSearchRequired={true}
                                                    label={"label"}
                                                    value={"value"}
                                                    disabled={!isVendorReady}
                                                    onDropDownChange={(item) => {
                                                        if (!item) {
                                                            return;
                                                        }
                                                        this.onChange(
                                                            Utils.BuildCustomEventObject(item.value, "states"),
                                                            "states"
                                                        );
                                                    }}
                                                    selectedItem={[
                                                        ...[{ value: "", label: "" }],
                                                        ...StaticArrays.userStates,
                                                    ].find((r: any) => r?.value === states) || {}}
                                                /> */}
                        <Form.Control
                          id="states"
                          value={states}
                          autoComplete="Off"
                          maxLength={50}
                          disabled={!isVendorReady}
                          onChange={(e) => this.onChange(e, "states")}
                          style={
                            isState === "Yes"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          onBlur={(e) => {
                            this.validateInputsFormat("states");
                          }}
                        />
                        <button
                          className={
                            states === "" ? "custom-btn" : "custom-btn"
                          }
                          style={
                            isState === "Yes"
                              ? { display: "none", width: "100%" }
                              : { display: "block", width: "100%" }
                          }
                          disabled={!isVendorReady}
                          id="MaskState"
                          onClick={() => {
                            this.handleDescriptionFocus("states");
                          }}
                          onFocus={() => {
                            this.handleDescriptionFocus("states");
                          }}
                        >
                          {states !== "" && (
                            <EllipsisWithTooltip placement="bottom">
                              {states}
                            </EllipsisWithTooltip>
                          )}
                        </button>
                        {states.length > 0 && !this.validateAddress(states) && (
                          <div className="textError">{isSpclCharMsg}</div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm="4">
                      <Form.Group>
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                          id="zip"
                          value={zip}
                          autoComplete="Off"
                          className={
                            this.state.zipCodeError ? "alert-danger" : ""
                          }
                          maxLength={10}
                          disabled={!isVendorReady}
                          onChange={(e) => this.onChange(e, "zip")}
                          onBlur={(e) => {
                            this.validateInputsFormat("zip");
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="Row7-MPM">
                  <Row>
                    <Col sm="6">
                      <Form.Group>
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          value={customerNo}
                          id="customerno"
                          required
                          maxLength={40}
                          disabled={!isVendorReady}
                          onChange={(e) => this.onChange(e, "customerno")}
                          style={
                            isCustomer === "Yes"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          onBlur={(e) => {
                            this.validateInputsFormat("customerno");
                          }}
                        />
                        <button
                          className={
                            customerNo === "" ? "custom-btn" : "custom-btn"
                          }
                          style={
                            isCustomer === "Yes"
                              ? { display: "none", width: "100%" }
                              : { display: "block", width: "100%" }
                          }
                          disabled={!isVendorReady}
                          id="MaskCustomer"
                          onClick={() => {
                            this.handleDescriptionFocus("customerno");
                          }}
                          onFocus={() => {
                            this.handleDescriptionFocus("customerno");
                          }}
                        >
                          {customerNo !== "" && (
                            <EllipsisWithTooltip placement="bottom">
                              {customerNo}
                            </EllipsisWithTooltip>
                          )}
                        </button>
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group>
                        <Form.Label>EHID</Form.Label>
                        <div className="more-action single-select-image dropdown coa-select jecoaSelect JorTypeheaddropdown">
                          <div>
                            <Typeahead
                              flip={true}
                              options={this.state.hidList}
                              inputProps={{ id: "ehid" }}
                              disabled={
                                !isVendorReady || customerNo.trim() == ""
                              }
                              renderMenu={(results: any, menuProps) => (
                                <Menu {...menuProps}>
                                  {results.map((result: any, index: any) => (
                                    <>
                                      <MenuItem
                                        option={result}
                                        position={index}
                                      >
                                        <EllipsisWithTooltip placement="bottom">
                                          {result.label}
                                        </EllipsisWithTooltip>
                                      </MenuItem>
                                      {result.label == "" && (
                                        <MenuItem
                                          className="load-more"
                                          option={result}
                                          position={index}
                                        >
                                          Load More
                                        </MenuItem>
                                      )}
                                      {this.state.hidList.length > 0 &&
                                        results[index - 1]?.orderno === 1 &&
                                        result?.orderno === 2 && (
                                          <Dropdown.Divider />
                                        )}
                                    </>
                                  ))}
                                </Menu>
                              )}
                              renderMenuItemChildren={
                                this.renderTypeaheadChildren
                              }
                              selected={defaultHID}
                              onChange={(e) => this.onChange(e, "HID")}
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="footerNewVendor">
              <Row>
                <Col sm="12">
                  <Button
                    disabled={
                      !isVendorReady ||
                      this.state.loader ||
                      vendorInput?.trim() === ""
                    }
                    className="addBtn-MPM"
                    id="saveBtn"
                    onClick={() => this.handleSaveRequest()}
                    onKeyDown={(e: any) => this.onTAB("saveBtn", e)}
                  >
                    {this.state.loader && (
                      <>
                        <Spinner size="sm" animation="border" />{" "}
                      </>
                    )}{" "}
                    Add Vendor
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  //Tabbing event to stay on same controls
  onTAB = (control, event) => {
    if (event.key.toLowerCase() === "tab") {
      if (control === "saveBtn") {
        $("#taxIdSuffix").focus();
        setTimeout(() => {
          $("#taxIdSuffix").focus();
        }, 50);
      }
    }
  };

  //Fun to render the vendor list
  renderVendorList = (items: any, index: any) => {
    let rNo = Number(items.id);
    MaxTabIndex = rNo + 1;
    return (
      <div
        className=//"search-vendor-list-item"
        {
          this.state.cursor === items.id
            ? "activeVendor currentHIDRepeat vendorListItem vendorListFocusSet search-vendor-list-item"
            : "vendorListItem currentHIDRepeat search-vendor-list-item"
        }
        tabIndex={rNo}
        onKeyDown={(e) => {
          this.handleKeyDownArrow(e, items, "vendorRow");
        }}
      >
        <div className="d-flex align-items-center">
          <div className="list-item mr-auto">
            <div className="vendorName">{items.company}</div>
            <div className="contracts">
              {items.ctCount > 1
                ? items.ctCount + " Contracts"
                : items.accountNo}
            </div>
          </div>
          <div className="arrow">{this.renderRightArrowBtn(items, index)}</div>
        </div>
      </div>
    );
  };

  handleKeyDownArrow = (e, items, action) => {
    const { cursor, vendorList } = this.state;
    this.handleMouseLeave();
    if (
      e.keyCode === 40 &&
      cursor < vendorList.length - 1 &&
      this.state.isListOpen &&
      action === "vendorRow"
    ) {
      this.setState(
        (prevState) => ({
          cursor: prevState.cursor + 1,
        }),
        () => {
          setTimeout(() => {
            var tabindex: any = $(".vendorListFocusSet").attr("tabindex");
            currentTabIndex = tabindex;
            if (Number(MaxTabIndex) === this.state.cursor) {
              $(".vendorListFocusSet").focus();
            } else {
              $("[tabindex=" + tabindex + "]").focus();
            }
          }, 0);
        }
      );
    } else if (
      e.keyCode === 40 &&
      cursor === vendorList.length - 1 &&
      !this.state.isAddVendorBtnVisible &&
      this.state.isListOpen &&
      action === "vendorRow"
    ) {
      this.setState(
        (prevState) => ({
          cursor: prevState.cursor + 1,
        }),
        () => {
          setTimeout(() => {
            var tabindex: any = $(".AddVendorFooter").attr("tabindex");
            currentTabIndex = tabindex;
            $("[tabindex=" + tabindex + "]").focus();
          }, 0);
        }
      );
    } else if (
      e.keyCode === 38 &&
      cursor > 0 &&
      this.state.isListOpen &&
      action === "vendorRow"
    ) {
      this.setState(
        (prevState) => ({
          cursor: prevState.cursor - 1,
        }),
        () => {
          setTimeout(() => {
            if (Number(currentTabIndex) === 1) {
              $(".vendorListFocusSet").focus();
            } else {
              currentTabIndex = Number(currentTabIndex) - 1;
              $("[tabindex=" + currentTabIndex + "]").focus();
            }
          }, 0);
        }
      );
    } else if (
      e.keyCode === 38 &&
      cursor === 0 &&
      this.state.isListOpen &&
      action === "vendorRow"
    ) {
      // T move the focus on Input search box
      this.setState(
        (prevState) => ({
          cursor: prevState.cursor - 1,
        }),
        () => {
          MaxTabIndex = 0;
          setTimeout(() => {
            this.handleClick();
            $("#SlideBody").scrollTop(0);
            $("#inputSearch").focus();
          }, 0);
        }
      );
    } else if (
      e.keyCode === 38 &&
      cursor === vendorList.length &&
      this.state.isListOpen &&
      action === "AddVendorBtn"
    ) {
      this.setState(
        (prevState) => ({
          cursor: prevState.cursor - 1,
        }),
        () => {
          setTimeout(() => {
            currentTabIndex = Number(MaxTabIndex - 1);
            $("[tabindex=" + currentTabIndex + "]").focus();
          }, 0);
        }
      );
    } else if (
      e.keyCode === 39 &&
      cursor > -1 &&
      this.state.isListOpen &&
      action === "vendorRow"
    ) {
      // for right arrow
      let id = "dropdown-" + items.id;
      this.handleMouseHover(id);
    } else if (e.keyCode === 9) {
      // For disable tab
      e.preventDefault();
    } else if (
      e.keyCode === 13 &&
      (this.state.isListOpen || !this.state.isAddVendorBtnVisible) &&
      action === "AddVendorBtn"
    ) {
      // for Enter Key
      e.stopPropagation();
      this.setState(
        {
          isVendorReady: true,
          isListOpen: false,
          isAddVendorBtnVisible: false,
        },
        () => {
          $("#taxIdSuffix").focus();
        }
      );
    }
  };

  handleKeyDown = (e) => {
    const { cursor, vendorList } = this.state;
    if (
      e.keyCode === 40 &&
      cursor < vendorList.length - 1 &&
      this.state.isListOpen
    ) {
      this.setState(
        (prevState) => ({
          cursor: prevState.cursor + 1,
        }),
        () => {
          let selRow = vendorList.filter((item) => item.id === cursor + 1);
          setTimeout(() => {
            $(".vendorListFocusSet").focus();
          }, 0);
        }
      );
    } else if (e.keyCode === 9) {
      // for Disable Tab Key
      e.preventDefault();
    }
  };

  //Show the contract list on mouse hover on right icon
  handleMouseHover = (id) => {
    this.setState({ vendorListShow: id });
  };

  //Hide the contract list on mouse hover on right icon
  handleMouseLeave = () => {
    this.setState({ vendorListShow: false });
  };

  //Rendering the right arrow with contract list if the vendor having more than 1 contract
  renderRightArrowBtn = (items: any, index) => {
    const addressList = items?.addressList?.split("Ø"); //?.trim()?.replace(/,(?=\s*$)/, '')
    return (
      items?.ctCount > 1 && (
        <>
          <Dropdown
            className="vendor-list-sec"
            id={`dropdown-${index}`}
            show={
              this.state.vendorListShow === `dropdown-${index}` ? true : false
            }
            onMouseEnter={(e) => this.handleMouseHover(`dropdown-${index}`)}
            onMouseLeave={this.handleMouseLeave}
            drop={"right"}
          >
            <Dropdown.Toggle
              id={`dropdown-btn-${index}`}
              className="arrow-icon"
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 320 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="testU">
              <ul>
                {addressList.map((item, index) => (
                  <li>
                    <div className="vendorName">{item?.split("œ")[0]}</div>
                    <div className="contractsDetails">
                      {item?.split("œ")[1]?.split("°")[0]}
                    </div>
                    <div className="contractsDetails">
                      {item?.split("œ")[1]?.split("°")[1]}
                    </div>
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </>
      )
    );
  };

  pCodeValidate = (val: any) => {
    return /^\d{5}(-\d{4})?$/.test(val);
  };

  setZipError = (value) => {
    let zipActLen = value.includes("-") ? 10 : 9;
    let zipLen = value.length;
    let pcodeError: boolean = false;
    if (zipLen < 5) {
      pcodeError = true;
    } else if (zipLen > 5 && zipLen < zipActLen) {
      pcodeError = true;
    } else {
      pcodeError = false;
    }
    return pcodeError;
  };

  validateAddress = (val: any) => {
    return /^(?=.*[a-zA-Z0-9])/.exec(val);
  };

  //Chnage event for setting the states as per control input value
  onChange = (e, controlID) => {
    let { taxIdSuffix } = this.state;
    if (controlID === "taxIdSuffix") {
      let inputValue = Utils.removeSpecialCharcterFromString(
        e.currentTarget.value
      )?.substring(0, 9);
      if (!isNaN(inputValue)) {
        this.setState({ taxIdSuffix: inputValue }, () => {
          $("#taxIdSuffix").removeClass("alert-danger");
        });
      }
    }
    if (controlID === "taxIDPrefix") {
      if (taxIdSuffix?.replaceAll("-", "")?.trim()?.length > 8) {
        taxIdSuffix = taxIdSuffix.replaceAll("-", "");
        if (e.currentTarget.text === "TAX ID")
          taxIdSuffix =
            taxIdSuffix?.substr(0, 2) +
            "-" +
            taxIdSuffix?.substr(2, taxIdSuffix?.length - 2);
        else
          taxIdSuffix =
            taxIdSuffix?.substr(0, 3) +
            "-" +
            taxIdSuffix?.substr(3, 2) +
            "-" +
            taxIdSuffix?.substr(5, taxIdSuffix?.length - 4);
      }
      this.setState({ taxIDPrefix: e.currentTarget.text, taxIdSuffix });
    }
    if (controlID === "DefaultMethod") {
      this.setState({ defaultMethod: e.currentTarget.text });
    }
    if (controlID === "invoicesDuesAfter") {
      let val = Utils.removeSpecialCharcterFromString(
        e.currentTarget.value
      )?.substring(0, 2);
      if (!isNaN(val)) {
        this.setState({ invoicesDuesAfter: val });
      }
    }
    if (controlID === "phone") {
      let val = e.currentTarget.value.replace(/[^0-9]/g, "");
      this.setState({ phone: val }, () => {
        $("#phone").removeClass("alert-danger");
      });
    }
    if (controlID === "email") {
      let inPutValue = e.currentTarget.value;
      if (inPutValue?.trim() !== "") {
        inPutValue = inPutValue?.trimLeft();
      }
      this.setState({ Email: inPutValue }, () => {
        $("#email").removeClass("alert-danger");
        $("#MaskEmail").removeClass("alert-danger");
      });
    }
    if (controlID === "salescheck") {
      this.setState({ salesTaxCheck: e.currentTarget.checked });
    }
    if (controlID === "vendorcheck") {
      this.setState({ vendorCheck: e.currentTarget.checked });
    }
    if (controlID === "streetAddress") {
      let inPutValue = e.currentTarget.value?.trimLeft();
      if (inPutValue?.trim() !== "" && !this.validateAddress(inPutValue)) {
        this.setState({ streetAddress: inPutValue }, () => {
          $("#streetAddress").addClass("alert-danger");
          $("#MaskStreet").addClass("alert-danger");
        });
      } else {
        this.setState({ streetAddress: inPutValue }, () => {
          $("#streetAddress").removeClass("alert-danger");
          $("#MaskStreet").removeClass("alert-danger");
        });
      }
    }
    if (controlID === "city") {
      let inPutValue = e.currentTarget.value?.trimLeft();
      if (inPutValue?.trim() !== "" && !this.validateAddress(inPutValue)) {
        this.setState({ city: inPutValue }, () => {
          $("#city").addClass("alert-danger");
          $("#MaskCity").addClass("alert-danger");
        });
      } else {
        this.setState({ city: inPutValue }, () => {
          $("#city").removeClass("alert-danger");
          $("#MaskCity").removeClass("alert-danger");
        });
      }
    }
    if (controlID === "states") {
      let inPutValue = e.target.value?.trimLeft();
      if (inPutValue?.trim() !== "" && !this.validateAddress(inPutValue)) {
        this.setState({ states: inPutValue }, () => {
          $("#states").addClass("alert-danger");
          $("#MaskState").addClass("alert-danger");
        });
      } else {
        this.setState({ states: inPutValue }, () => {
          $("#states").removeClass("alert-danger");
          $("#MaskState").removeClass("alert-danger");
        });
      }
    }
    if (controlID === "zip") {
      let val = e.currentTarget.value.trim();
      val = val.indexOf("-") === 5 ? val : val.replace(/[^0-9]/g, "");
      val = val.trim().replace(/[^0-9-]/g, "");
      val = val?.substring(0, val.indexOf("-") === 5 ? 10 : 9);
      let { zipCodeError, zip } = this.state;
      zipCodeError = this.setZipError(val);
      zip = val;
      this.setState({ zipCodeError, zip });
    }
    if (controlID === "customerno") {
      let inPutValue = e.currentTarget.value;
      if (inPutValue?.trim() !== "") {
        inPutValue = inPutValue?.trimLeft();
      } else {
        this.setState({ defaultHID: [] });
      }
      this.setState({ customerNo: inPutValue });
    }
    if (controlID === "HID") {
      if (e.length > 0) {
        let defaultHID: any = [];
        let objHID: any = {};
        objHID.id = e[0].id;
        objHID.label = e[0].label;
        defaultHID.push(objHID);
        this.setState({ defaultHID: defaultHID });
      } else {
        this.setState({ defaultHID: [] });
      }
    }
  };

  //Fun to check total length of city, state and zip
  checkTotalLength = () => {
    let cityLength = this.state.city?.substring(0, 50).trim().length;
    let stateLength = this.state.states?.substring(0, 50).trim().length;
    let zipLength = this.state.zip?.substring(0, 50).trim().length;
    let totalLength =
      Number(cityLength) + Number(stateLength) + Number(zipLength);
    if (totalLength > 50) {
      return "Exceed";
    } else {
      return "NotExceed";
    }
  };

  //Fun to validate the input values on input blur

  validateInputsFormat = (controlID: any) => {
    let {
      zip,
      phone,
      Email,
      taxIdSuffix,
      streetAddress,
      city,
      state,
      customerno,
      taxIDPrefix,
    } = this.state;
    if (controlID === "EmailControl" && Email?.trim() !== "") {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!emailRegex.test(Email?.toLowerCase()?.trim())) {
        $("#email").addClass("alert-danger");
        $("#MaskEmail").addClass("alert-danger");
        Utils.toastError("Email format is not correct.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        $("#email").removeClass("alert-danger");
        $("#MaskEmail").removeClass("alert-danger");
      }
      this.setState({ isEmail: "No" });
    }
    if (controlID === "phone" && phone?.trim() !== "") {
      if (phone?.trim().length < 10 || phone?.trim().length > 20) {
        $("#phone").addClass("alert-danger");
        Utils.toastError("Phone number must be between 10 to 20 digits.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        $("#phone").removeClass("alert-danger");
      }
    }
    if (controlID === "taxIdSuffix" && this.state.taxIdSuffix?.trim() !== "") {
      if (taxIdSuffix?.trim().length < 9) {
        Utils.toastError(`${this.state.taxIDPrefix} should be 9 digit.`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        $("#taxIdSuffix").addClass("alert-danger");
        return;
      } else if (
        this.allCharactersSame(
          taxIdSuffix?.trim()?.replaceAll("-", "")?.substr(0, 9)
        )
      ) {
        Utils.toastError(
          this.state.taxIDPrefix + " cannot be the same digit repeating.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        $("#taxIdSuffix").addClass("alert-danger");
        return;
      } else if (taxIdSuffix?.trim()?.length > 8) {
        if (taxIDPrefix?.toLowerCase() === "tax id")
          taxIdSuffix =
            taxIdSuffix?.substr(0, 2) +
            "-" +
            taxIdSuffix?.substr(2, taxIdSuffix?.length - 2);
        else
          taxIdSuffix =
            taxIdSuffix?.substr(0, 3) +
            "-" +
            taxIdSuffix?.substr(3, 2) +
            "-" +
            taxIdSuffix?.substr(5, taxIdSuffix?.length - 4);
        this.setState({ taxIdSuffix });
      }
    }
    if (controlID === "zip" && zip?.trim() !== "") {
      let { zipCodeError, zip } = this.state;
      let pCode = zip.toString().replace(/-/g, "").trim();
      if (pCode?.length > 5 && pCode?.length === 9 && !pCode?.includes("-")) {
        pCode = pCode.substr(0, 5) + "-" + pCode.substr(5, pCode?.length - 5);
      }
      zip = pCode;
      zipCodeError = !this.pCodeValidate(zip);
      if (zipCodeError && pCode.length > 5) {
        Utils.toastError("Please enter 9 digit zip.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (zipCodeError) {
        Utils.toastError("Please enter 5 digit zip.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      this.setState({ zipCodeError, zip });
    }
    if (
      controlID == "streetAddress" &&
      streetAddress?.trim() !== "" //&& !this.validateAddress(streetAddress?.trim())
    ) {
      this.setState({ isStreet: "No" });
    }
    if (
      controlID == "city" &&
      city?.trim() !== "" //&& !this.validateAddress(city?.trim())
    ) {
      this.setState({ isCity: "No" });
    }
    if (
      controlID == "states" &&
      state?.trim() !== "" // && !this.validateAddress(state?.trim())
    ) {
      this.setState({ isState: "No" });
    }
    if (controlID == "customerno" && customerno?.trim() !== "") {
      this.setState({ isCustomer: "No" });
    }
  };

  handleDescriptionFocus = (txtID) => {
    setTimeout(() => {
      if (txtID == "email") this.setState({ isEmail: "Yes" });
      else if (txtID == "streetAddress") this.setState({ isStreet: "Yes" });
      else if (txtID === "city") this.setState({ isCity: "Yes" });
      else if (txtID === "states") this.setState({ isState: "Yes" });
      else if (txtID === "customerno") this.setState({ isCustomer: "Yes" });

      $("#" + txtID).focus();
    }, 100);
  };

  onFocus = (e, controlID) => {
    if (controlID === "taxIdSuffix") {
      this.setState({
        taxIdSuffix: this.state.taxIdSuffix.trim().replaceAll("-", ""),
      });
    }
  };

  //Fun to check whether the tax/SSn having not the same repeating char
  allCharactersSame = (s): boolean => {
    let n = s.length;
    for (let i = 1; i < n; i++) if (s[i] != s[0]) return false;
    return true;
  };

  //Check that all input values are correct in format and in exact length
  checkValidation = () => {
    let {
      vendorInput,
      Email,
      streetAddress,
      city,
      states,
      zip,
      defaultHID,
      customerNo,
      phone,
    } = this.state;
    // ---------------- Validation For Required Fields ----------
    let isValid: any = [];
    let focusSet = false;
    if (vendorInput?.trim() === "") {
      $("#inputSearch").focus();
      $("#inputSearch").addClass("alert-danger");
      focusSet = true;
      isValid.push(false);
    } else {
      $("#inputSearch").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }

    // if (Email?.trim() === "") {
    //     $("#email").focus();
    //     $("#email").addClass("alert-danger");
    //     $('#MaskEmail').addClass("alert-danger");
    //     focusSet = true;
    //     isValid.push(false);
    // } else {
    //     $("#email").blur();
    //     isValid.push(true);
    //     focusSet = focusSet ? true : false;
    // }

    if (streetAddress?.trim() === "" || !this.validateAddress(streetAddress)) {
      isValid.push(false);
      if (!focusSet) {
        $("#streetAddress").focus();
        focusSet = true;
      }
      $("#streetAddress").addClass("alert-danger");
      $("#MaskStreet").addClass("alert-danger");
      focusSet = true;
      isValid.push(false);
    } else {
      $("#streetAddress").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }

    if (city?.trim() === "" || !this.validateAddress(city)) {
      if (!focusSet) {
        $("#city").focus();
        focusSet = true;
      }
      $("#city").addClass("alert-danger");
      $("#MaskCity").addClass("alert-danger");
      isValid.push(false);
    } else {
      $("#city").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if (states === "" || !this.validateAddress(states)) {
      // $("#Modal-ddlundefined").addClass("alert-danger");
      // isValid.push(false);
      // if (!focusSet) {
      //     //$("#Modal-ddlundefined").click();
      //     focusSet = true;
      // }
      isValid.push(false);
      $("#state").addClass("alert-danger");
      $("#MaskState").addClass("alert-danger");
    } else {
      // $("#Modal-ddlundefined").blur();
      // focusSet = focusSet ? true : false;
      // isValid.push(true);
      $("#state").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }
    if (zip?.trim() === "") {
      // $("#zip").addClass("alert-danger");
      let { zipCodeError } = this.state;
      zipCodeError = true;
      this.setState({ zipCodeError });
      isValid.push(false);
      if (!focusSet) {
        $("#zip").focus();
        focusSet = true;
      }
    } else {
      $("#zip").blur();
      focusSet = focusSet ? true : false;
      isValid.push(true);
    }

    if (defaultHID?.length === 0 && customerNo?.trim() !== "") {
      isValid.push(false);
      if (!focusSet) {
        //$("#ehid").focus();
        focusSet = true;
      }
      $("#ehid").addClass("alert-danger");
      isValid.push(false);
    } else {
      $("#ehid").blur();
      isValid.push(true);
      focusSet = focusSet ? true : false;
    }

    const returnValue = isValid.some((item) => item === false);

    // ------------ Validation For If any field contains invalid value ----------
    if (!returnValue) {
      if ($("#inputSearch").hasClass("alert-danger") && vendorInput !== "") {
        return "vendorName";
      } else {
        $("#inputSearch").removeClass("alert-danger");
      }
      if (
        $("#taxIdSuffix").hasClass("alert-danger") &&
        this.state.taxIdSuffix !== ""
      ) {
        return "taxIdSuffix";
      } else {
        $("#taxIdSuffix").removeClass("alert-danger");
      }
      if ($("#phone").hasClass("alert-danger") && phone !== "") {
        return "phone";
      } else {
        $("#phone").removeClass("alert-danger");
      }
      if ($("#email").hasClass("alert-danger") && Email !== "") {
        return "email";
      } else {
        $("#email").removeClass("alert-danger");
      }
      if ($("#zip").hasClass("alert-danger") && zip !== "") {
        return "zip";
      } else {
        $("#zip").removeClass("alert-danger");
      }
    }
    return returnValue ? "Required" : "Succeed";
  };

  //Clear all set values by the user on close or after save the values in the DB
  clearStates = () => {
    this.setState({
      loader: false,
      vendorInput: "",
      taxIdSuffix: "",
      taxIDPrefix: "Tax ID",
      defaultMethod: "Manual ACH",
      phone: "",
      Email: "",
      invoicesDuesAfter: "0",
      salesTaxCheck: false,
      vendorCheck: false,
      streetAddress: "",
      city: "",
      states: "",
      zip: "",
      defaultHID: [],
      customerNo: "",
      isVendorReady: false,
      isListOpen: false,
      isAddVendorBtnVisible: false,
    });
  };

  //Fun to save the details of the form in DB and move the user to the contract tab
  handleSaveRequest = async () => {
    if (
      !this.state.vendorInput?.trim().toUpperCase().includes("GENERIC VENDOR")
    ) {
      this.setState({ loader: true });
      let isValid = ["Succeed"];
      if (!this.state.isGeneric)
        isValid = await Promise.all([this.checkValidation()]);

      if (isValid[0] === "Succeed") {
        let isLengthExceed = this.checkTotalLength();

        if (isLengthExceed === "NotExceed") {
          const vendorName = this.state.vendorInput
            ?.substring(0, 50)
            .trim()
            .toUpperCase();
          let formData: any = {};
          formData.Vid = 0;
          formData.AID = 0;
          formData.Company = vendorName;
          formData.Address1 = this.state.streetAddress
            ?.substring(0, 100)
            .trim();
          formData.City = this.state.city?.substring(0, 43).trim();
          formData.State = this.state.states?.substring(0, 50).trim();
          formData.PCode = this.state.zip.trim(); //?.substring(0, 5).trim();
          formData.HotelId =
            this.state.defaultHID.length == 0
              ? "-1"
              : this.state.defaultHID[0]?.id;
          formData.Phone = this.state.phone?.substring(0, 20).trim();
          formData.PaymentType =
            this.state.defaultMethod.toString().toLowerCase() ===
            "Manual ACH".toLowerCase()
              ? "ACH"
              : this.state.defaultMethod;
          formData.SalesTax = this.state.salesTaxCheck ? "Yes" : "No";
          formData.IsVendor1099 = this.state.vendorCheck ? "Yes" : "No";
          formData.TaxOrSsnId =
            this.state.taxIdSuffix === null
              ? ""
              : this.state.taxIdSuffix?.substring(0, 11).trim();
          formData.IsTaxOrSSN = this.state.taxIDPrefix.replace(" ", "");
          formData.InvoiceDueAfter = this.state.invoicesDuesAfter?.trim();
          formData.CustomerNo = this.state.customerNo?.substring(0, 40).trim();
          formData.Email = this.state.Email?.substring(0, 50).trim();
          formData.CallingOnPage = "Other";
          formData.CallType = "NewVendorMaster";

          registersevice
            .SaveVendorData(formData)
            .then(async (result: any | null) => {
              if (result !== null) {
                if (result.message?.toLowerCase() === "success") {
                  toast.success("Vendor created successfully.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  this.AddVendoModalShowHide();
                  this.clearStates();
                  this.props?.moveToContractTab(
                    result.result.id,
                    vendorName,
                    result?.result?.otherInfo
                  );
                } else if (result.message?.toLowerCase() === "vendorexists") {
                  this.setState({ loader: false });
                  Utils.toastError("Vendor name already exists.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                } else if (
                  result.message?.toLowerCase() === "samecontractexist"
                ) {
                  this.setState({ loader: false });
                  Utils.toastError("Same contract exists for this EHID.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                } else {
                  Utils.toastError(result.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
              }
              this.setState({ loader: false });
              resolve();
            })
            .catch((error) => {
              this.setState({ loader: false });
              reject();
            });
        } else {
          Utils.toastError(
            "Total length of City, State and ZIP cannot be greater than 50.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
          this.setState({ loader: false });
        }
      } else if (isValid[0] === "vendorName") {
        Utils.toastError("Vendor name should be atleast 3 characters.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loader: false });
      } else if (isValid[0] === "Required") {
        Utils.toastError("Please fill the required fields.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loader: false });
      } else if (isValid[0] === "taxIdSuffix") {
        Utils.toastError(
          "Please correct the " + this.state.taxIDPrefix + " format.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        this.setState({ loader: false });
      } else if (isValid[0] === "phone") {
        Utils.toastError("Phone number must be between 10 to 20 digits.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loader: false });
      } else if (isValid[0] === "email") {
        Utils.toastError("Email format is not correct.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.setState({ loader: false });
      } else if (isValid[0] === "zip") {
        // Utils.toastError("Zip must be 5 digits.", {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        // });
        this.setState({ loader: false });
      }
    } else {
      $("#inputSearch").focus();
      $("#inputSearch").addClass("alert-danger");
      Utils.toastError("You can't create a generic vendor.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
  };
}
