import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-toastify/dist/ReactToastify.css";
import { Container, Button, Dropdown } from "react-bootstrap";
import { resolve, reject } from "q";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { accountService } from "./../../../Common/Services/account";
import BootstrapTable from "react-bootstrap-table-next";
import { User } from "../../../Common/Services/User";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Hotel } from "../../../Common/Services/Hotel";
import _ from "lodash";
import moment from "moment-timezone";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReportViewer } from "../../Reports/ReportViewer";
import { ReportExport } from "../../../Common/Services/ReportExport";
import scrollIntoView from "scroll-into-view";
import { OtpModal } from "../../../Common/Components/OtpModal";
import { Link } from "react-router-dom";
import caught from "../../../Common/Assets/Images/icons/all-caught.png";
import { Register as registersevice } from "../../../Common/Services/Register";
import Spinner from "react-bootstrap/Spinner";
import { RoleSubPermissionSetting, IPageSubPermissionRole, } from "../../../Common/Services/RoleSubPermissionSetting";
import { ReconcileService as reconcileService } from "../../../Common/Services/ReconcileService";
import { Utils } from "../../../Common/Utilis";
const noOfApis = 7;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className + " d-flex justify-content-center align-items-center"} onClick={onClick}>
      <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z" fill="#2CAF92" />
      </svg>
    </div>
  );
}
function SampleNextArrowDisabled(props) {
  const { className, style, onClick } = props;
  return <></>;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + " d-flex justify-content-center align-items-center"} onClick={onClick}>
      <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z" fill="#2CAF92" />
      </svg>
    </div>
  );
}

let slickSliderCount: any = 5;
export class AccountingOverview extends React.Component<any, any> {
  private hasPageWidth: any;
  private linkRef: any;
  private reportDivRef: any;
  private cardDivRef: any;
  constructor(props: any) {
    super(props);
    this.hasPageWidth = React.createRef();
    this.linkRef = React.createRef();
    this.reportDivRef = React.createRef();

    this.state = {
      scrollWidth: "auto",
      scrollItemList: 1,
      currentHotelTime: "",
      activeComparision: "",
      accountingOverviewData: [],
      accountingOverviewCounts: [],
      hotelIds: "",
      reportHotelIds:"",
      isDataLoaded: false,
      parameterSet: false,
      isSliderLoading: false,
      Purpose: "Untrusted Vendors",
      hotelAccessDetails: [],
      allAccountingOverviewCounts: [],
      draftInvoiceData: [],
      unapprovedInvoiceData: [],
      unpaidInvoiceData: [],
      unapprovedVendorData: [],
      reconcileAccountsData: [],
      apisCalledCount: 0,
      transtoReviewData: [],
      reconcilePermissionArray: [],
      ePayFeatureflag: "No",
    };
  }

  componentDidMount() {
    // this.getAccountOverviewStat();
    this.getUserHotelAccessAccounting()
    this.getReconcilePermission();
    this.setState({ isSliderLoading: true });
    this.getAccountOverviewTransReview();
    this.getAccountOverviewDraftInvoice();
    this.GetAccountOverviewUnapprovedInvoice();
    this.GetAccountOverviewUnpaidInvoice();
    this.GetAccountOverviewUnapprovedVendor();
    this.GetAccountOverviewFlaggedTransaction();

    this.GetReconcileOverview();
    this.loadHotelsDetails();
    window.addEventListener('resize', this.setslickSliderCount);
    var navToggleBtn: any = document.getElementById('nav-toggle-btn');
    navToggleBtn.addEventListener('click', this.navbuttonClicked);
    const location = this.props.location;
    if (location.state !== undefined) {
      const stateLength = Object.keys(location.state).length;
      if (stateLength > 0) {
        let { tabName } = location.state;
        // if (tabName === "unapprovedVendors" || tabName === "untrustedVendors") {
        if (tabName === "untrustedVendors") {
          this.handleRedirectionBox({}, tabName);
        } else {
          this.optionBoxClick(tabName);
        }
      }
    }
  }

  getReconcilePermission = () => {
    reconcileService.GetReconcilePermission()
      .then(async (result: any | null) => {
        // debugger;
        if (result != null) {
          this.setState({ reconcilePermissionArray: result });
        }
        else {
          // this.setState({ reconsiletData: this.state.blankData });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  handleAlertPreference = () => {
    localStorage.setItem("mainMenu", "Profile");
    localStorage.setItem("subMenu", "Alert Preferences");
  }

  loadHotelsDetails() {
    Hotel.getUserHotelAccess("Register")
      .then(async (result: any[] | null) => {
        if (result != null) {
          this.setState({ hotelAccessDetails: result })
        }
      })
      .catch((error) => {
        reject();
      });
  }

  navbuttonClicked = () => {
    this.setState({ navbtnClicked: true });
    this.setslickSliderCount();
  }

  closeOtpModal = () => {
    if (this.state.isOtpNeed) {
      this.setState({ isOtpNeed: false });
    }
  }

  ValidateOTP = (OTPCode) => {
    this.setState({ otpSuccess: true, isOtpNeed: false }, () => {
      User.GenerateToken()
        .then(async (result: any | null) => {
          let pagePath = "/AccountPayable/UntrustedVendors.aspx?PageName=UntrustedVendors";
          if (result) {
            let mergeURL = result.result.mergeURL;

            let token = result.result.token;
            let tokenID = result.result.tokenID;
            let pageURL,
              queryStringKey,
              queryStringValue,
              functionName = "";
            if (pagePath?.split("?")[0]) {
              pageURL = pagePath?.split("?")[0];
              queryStringKey = pagePath?.split("?")[1]?.split("=")[0];
              queryStringValue = pagePath?.split("?")[1]?.split("=")[1];
              if (pagePath.includes("*")) {
                functionName = pagePath.split("*")[1];
              } else {
                functionName = "None";
              }
            } else {
              queryStringKey = "PageName";
              queryStringValue = "UntrustedVendors";
            }
            let hotelID = '';
            let hCode = '';
            let roleID = JSON.parse(localStorage.getItem("storage")!)?.rolId;
            let storage = JSON.parse(localStorage.getItem("storage")!);
            let tenantID = storage === null ? 0 : (storage.tenantID as any);
            let username = storage === null ? 0 : (storage.userName as any);
            const finalUrl = mergeURL +
              // "http://localhost:50491/login.aspx" +
              "?logInUser=" +
              btoa(username) +
              "&tokenID=" +
              btoa(tokenID) +
              "&module=" +
              btoa("Accounting") +
              "&logInType=2&PageUrl=" +
              pageURL +
              "&Key=" +
              btoa(queryStringKey) +
              "&Value=" +
              btoa(queryStringValue) +
              "&function=" +
              btoa(functionName) +
              (+hotelID ? ("&hid=" +
                hotelID) : '') +
              (hCode ? ("&HCode=" +
                hCode) : ""
              );
            window.open(finalUrl);
          }
          resolve();
        })
        .catch((error) => {
          reject();
        });
    });
  }

  ReSendOTP = () => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let userName = storage === null ? 0 : (storage.userName as any);
    let request: any = {};
    request.userName = userName;
    request.purpose = this.state.Purpose;
    request.otpCode = "";
    request.primaryContact = "Phone";
    accountService.OTPForAuthentication(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.status === "Success") {

            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });

            this.setState({ isOtpNeed: true });
          }
          else {
            toast.success('Something went wrong', {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });
          }

        }
      });
  }

  getDailyFlashV4Report = () => {
    let reportParam = [] as any;
    const storage = JSON.parse(localStorage.getItem("storage")!);
    let username = storage === null ? 0 : (storage.userName as any);
    let tenantId = storage === null ? 0 : (storage.tenantID as any);

    if (this.state?.parameterSet) {
      scrollIntoView(this.cardDivRef?.current, {
        align: {
          top: 0
        },
      });

      this.setState({ parameterSet: false });
      return;
    }




    reportParam =
      reportParam = [{ name: "Status", value: "1" }
        , { name: "coaType", value: "Active" }
        , { name: "Report", value: "115" }
        , { name: "OwnershipID", value: "0" }
        , { name: "isPosition", value: "No" }
        , { name: "IsSalaried", value: "No" }
        , { name: "IsExcludePayroll", value: "No" }
        , { name: "IsVariance", value: "No" }
        , { name: "IsDollars", value: "No" }
        , { name: "ActualCompared", value: "0" }
        , { name: "ReportName", value: 3 }
        , { name: "IsExclude", value: "No" }
        , { name: "ChkSummSec", value: "Yes" }
        , { name: "ChkIsCompSet", value: "No" }
        , { name: "Type", value: "0" }
        , { name: "HyperLink", value: true }
        , { name: "userName", value: username }
        , { name: "IsPageBreak", value: "0" }
        , { name: "ExportType", value: "View" }
        , { name: "IsOCC", value: "1" }
        , { name: "IsADR", value: "1" }
        , { name: "IsRMREV", value: "1" }
        , { name: "IsREVPAR", value: "1" }
        , { name: "IsFromOverview", value: "Yes" }


        , {
        name: "StartDate",
        value: moment().format("MM/DD/YY")
      }
        , { name: "GroupName", value: "" }
        , { name: "HotelBrand", value: "" }
        , { name: "Hotelid", value: this.state?.reportHotelIds  }
        , { name: "description", value: "" }
        , { name: "tenantId", value: tenantId }
        , { name: "reportId", value: 2008 }]

    localStorage.setItem("reportParameters", JSON.stringify(reportParam));
    this.setState({ parameterSet: false }, () => this.setState({ parameterSet: true }, () => {
      scrollIntoView(this.reportDivRef?.current, {
        align: {
          bottom: 0,
          left: 0,
          topOffset: 60,
          leftOffset: 0,
        },
      });
    }));

  }

  getUserHotelAccessAccounting = ()=>{
    Hotel.getUserHotelAccess("Accounting")
    .then(async (data: any[] | null) => {
      const reportHotelIds = data?.filter(x => x?.hotelType == 2)?.map((x) => x.hotelID).join(",");
      this.setState({reportHotelIds:reportHotelIds})
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  getAccountOverviewStat = () => {
    this.setState({ isSliderLoading: true });
    accountService.GetAccountOverviewStat().then((data) => {
      let accountingOverviewCounts: any[] = [];
      let hotelIds = "";

      if (data?.length > 0) {
        hotelIds = _.uniq(data?.filter(x => x?.type === 2)
          ?.map((x) => x.hotelId)).join(",");
        const flags = data[0];

        const draftInvoiceSum: number = data
          ?.map((x) => +x.draftInvoice)
          ?.reduce((a, b) => a + b, 0);
        const draftInvoiceObj: any = {
          label: "Draft Invoices",
          counts: draftInvoiceSum,
          name: "draftInvoice",
          isVisible: flags?.showDraftInvoice
        };
        accountingOverviewCounts.push(draftInvoiceObj);

        const invoiceApprovalWaitingonMeSum: number = data
          ?.map((x) => +x?.invoiceApprovalWaitingonMe)
          ?.reduce((a, b) => a + b, 0);


        const myInvoiceApprovals: any = {
          label: "My Invoice Approvals",
          counts: invoiceApprovalWaitingonMeSum,
          name: "myInvoices",
          isVisible: flags?.showUnapprovedInvoice
        };
        accountingOverviewCounts.push(myInvoiceApprovals);


        const unapprovedInvoiceSum: number = data
          ?.map((x) => +x.unapprovedInvoice)
          ?.reduce((a, b) => a + b, 0);
        const allInvoiceApprovalsObj: any = {
          label: "All Invoice Approvals",
          counts: unapprovedInvoiceSum,
          name: "unapprovedInvoice",
          isVisible: flags?.showUnapprovedInvoice
        };
        accountingOverviewCounts.push(allInvoiceApprovalsObj);

        const unapprovedVendorsSum = data
          ?.map((x) => +x.unapprovedVendors)[0];
        const unapprovedVendorsObj: any = {
          label: "Unapproved Vendors",
          counts: unapprovedVendorsSum,
          name: "unapprovedVendors",
          isVisible: flags?.showUnapprovedVendors
        };


        accountingOverviewCounts.push(unapprovedVendorsObj);

        const unpaidInvoiceSum = data
          ?.map((x) => +x.unpaidInvoiceACH + +x.unpaidInvoiceCC + +x.unpaidInvoiceCheck + +x.unpaidInvoiceDACH + +x.unpaidTransfer)
          ?.reduce((a, b) => a + b, 0);
        const unpaidInvoiceObj: any = {
          label: "Unpaid Invoices",
          counts: unpaidInvoiceSum,
          name: "unpaidInvoice",
          isVisible: flags?.showUnpaidInvoice

        };
        accountingOverviewCounts.push(unpaidInvoiceObj);

        const untrustedVendorsSum = data
          ?.map((x) => +x.untrustedVendors)[0];
        const untrustedVendorsObj: any = {
          label: "Untrusted Vendors",
          counts: untrustedVendorsSum,
          name: "untrustedVendors",
          isVisible: flags?.showUntrustedVendors
        };
        accountingOverviewCounts.push(untrustedVendorsObj);

        const totalHiddenBoxes = Number(flags.totalHideBox);
        const totalHiddenBoxesObj: any = {
          label: "Show " + totalHiddenBoxes + " Hidden",
          counts: totalHiddenBoxes,
          name: "totalHiddenBoxes",
          isVisible: totalHiddenBoxes > 0 ? true : false,
        };
        accountingOverviewCounts.push(totalHiddenBoxesObj);
        this.setState({ allAccountingOverviewCounts: accountingOverviewCounts });
      }
      accountingOverviewCounts = accountingOverviewCounts.filter(x => x?.counts > 0 && x?.isVisible)

      this.setState({
        accountingOverviewData: data || [],
        accountingOverviewCounts,
        hotelIds
      }, () => {
        this.setState({ isDataLoaded: true })
      });
    }).finally(() => {
      this.setState({ isSliderLoading: false });
      this.setslickSliderCount();
    });
  };

  getAccountOverviewTransReview = () => {
    accountService.GetAccountOverviewTransReview().then((data) => {
      let accountingOverviewCounts = [...this.state.accountingOverviewCounts];
      let allAccountingOverviewCounts = [...this.state.allAccountingOverviewCounts];
      if (data.success) {
        let _data = data?.result;
        _data.map(item => item["isLoader"] = false);
        const flags = _data[0];
        if (_data?.length > 0) {
          const TrtoReviewSum: number = _data?.map((x) => +x.toReviewCount)?.reduce((a, b) => a + b, 0);
          const TrtoReview: any = {
            label: "Bank Transactions to Review",
            counts: TrtoReviewSum,
            name: "TranstoReview",
            isVisible: flags?.isHide === 0 ? true : false,
          };
          if (TrtoReview.counts > 0) {
            allAccountingOverviewCounts.push(TrtoReview);
            if (TrtoReview.isVisible) {
              accountingOverviewCounts.push(TrtoReview);
              if (accountingOverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0) {
                const index = accountingOverviewCounts.findIndex((x) => x.name === "totalHiddenBoxes");
                let hiddenObjArr = accountingOverviewCounts.splice(index, 1);
                accountingOverviewCounts.push(hiddenObjArr[0]);
              }
              this.setState({ accountingOverviewCounts });
            }
            else {
              this.getHiddenCount(1);
            }
            this.setState({ allAccountingOverviewCounts, isSliderLoading: false });
          }
          this.setState({ transtoReviewData: _data || [] });
        }
      }
    }).finally(() => {
      this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
      this.setState({ isSliderLoading: false });
      this.setslickSliderCount();
    });
  }

  getAccountOverviewDraftInvoice = () => {
    accountService.GetAccountOverviewDraftInvoice().then((data) => {
      let accountingOverviewCounts = [...this.state.accountingOverviewCounts];
      let allAccountingOverviewCounts = [...this.state.allAccountingOverviewCounts];
      let hotelIds = "";
      const flags = data[0];

      if (flags.permission) {
        if (data?.length > 0) {
          hotelIds = _.uniq(data?.filter(x => x?.type === 2)
            ?.map((x) => x.hotelId)).join(",");

          const draftInvoiceSum: number = data
            ?.map((x) => +x.invoiceCount)
            ?.reduce((a, b) => a + b, 0);
          const draftInvoiceObj: any = {
            label: "Draft Invoices",
            counts: draftInvoiceSum,
            name: "draftInvoice",
            isVisible: flags?.isHide === 0 ? true : false,
          };
          if (draftInvoiceObj.counts > 0) {
            allAccountingOverviewCounts.push(draftInvoiceObj);
            if (draftInvoiceObj.isVisible) {
              accountingOverviewCounts.push(draftInvoiceObj);
              if (accountingOverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0) {
                const index = accountingOverviewCounts.findIndex((x) => x.name === "totalHiddenBoxes");
                let hiddenObjArr = accountingOverviewCounts.splice(index, 1);
                accountingOverviewCounts.push(hiddenObjArr[0]);
              }
              this.setState({ accountingOverviewCounts });
            }
            else {
              this.getHiddenCount(1);
            }
            this.setState({ allAccountingOverviewCounts, isSliderLoading: false });
          }
          this.setState({
            draftInvoiceData: data || [],
            hotelIds
          });
        }
      }
    }).finally(() => {
      this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
      // this.setState({ isSliderLoading: false });
      this.setslickSliderCount();
    });
  };

  GetAccountOverviewUnapprovedInvoice = () => {
    accountService.GetAccountOverviewUnapprovedInvoice().then((data) => {
      let accountingOverviewCounts = [...this.state.accountingOverviewCounts];
      let allAccountingOverviewCounts = [...this.state.allAccountingOverviewCounts];
      let hotelIds = "";
      const flags = data[0];
      let hiddenCount = 0;

      if (flags.permission) {
        if (data?.length > 0) {
          hotelIds = _.uniq(data?.filter(x => x?.type === 2)
            ?.map((x) => x.hotelId)).join(",");

          const invoiceApprovalWaitingonMeSum: number = data
            ?.map((x) => +x?.myInvoiceCount)
            ?.reduce((a, b) => a + b, 0);

          const myInvoiceApprovals: any = {
            label: "My Invoice Approvals",
            counts: invoiceApprovalWaitingonMeSum,
            name: "myInvoices",
            isVisible: flags?.isMyHide === 0 ? true : false,
          };

          if (myInvoiceApprovals.counts > 0) {
            allAccountingOverviewCounts.push(myInvoiceApprovals);
            if (myInvoiceApprovals.isVisible) {
              accountingOverviewCounts.push(myInvoiceApprovals);
              if (accountingOverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0) {
                const index = accountingOverviewCounts.findIndex((x) => x.name === "totalHiddenBoxes");
                let hiddenObjArr = accountingOverviewCounts.splice(index, 1);
                accountingOverviewCounts.push(hiddenObjArr[0]);
              }
              // this.setState({accountingOverviewCounts});
            }
            else {
              // this.getHiddenCount();
              hiddenCount = hiddenCount + 1;
            }
            // this.setState({allAccountingOverviewCounts});
          }

          const allInvoiceApprovalSum: number = data
            ?.map((x) => +x.allInvoiceCount)
            ?.reduce((a, b) => a + b, 0);
          const allInvoiceApprovalsObj: any = {
            label: "All Invoice Approvals",
            counts: allInvoiceApprovalSum,
            name: "unapprovedInvoice",
            isVisible: flags?.isAllHide === 0 ? true : false,
          };

          if (allInvoiceApprovalsObj.counts > 0) {
            allAccountingOverviewCounts.push(allInvoiceApprovalsObj);
            if (allInvoiceApprovalsObj.isVisible) {
              accountingOverviewCounts.push(allInvoiceApprovalsObj);
              if (accountingOverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0) {
                const index = accountingOverviewCounts.findIndex((x) => x.name === "totalHiddenBoxes");
                let hiddenObjArr = accountingOverviewCounts.splice(index, 1);
                accountingOverviewCounts.push(hiddenObjArr[0]);
              }
              // this.setState({accountingOverviewCounts});
            }
            else {
              // this.getHiddenCount();
              hiddenCount = hiddenCount + 1;
            }
            // this.setState({allAccountingOverviewCounts});
          }
          this.setState({ accountingOverviewCounts, allAccountingOverviewCounts, unapprovedInvoiceData: data || [], hotelIds }, () => {
            this.setState({ isSliderLoading: false });
            if (hiddenCount > 0) {
              this.getHiddenCount(hiddenCount);
            }
          })
        }
      }
    }).finally(() => {
      this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
      // this.setState({ isSliderLoading: false });
      this.setslickSliderCount();
    });
  };

  GetAccountOverviewUnpaidInvoice = () => {
    accountService.GetAccountOverviewUnpaidInvoice().then((data) => {
      let accountingOverviewCounts = [...this.state.accountingOverviewCounts];
      let allAccountingOverviewCounts = [...this.state.allAccountingOverviewCounts];
      let hotelIds = "";
      const flags = data[0];
      const featureFlagEpay = flags?.epayFeatureFlag?.toLowerCase() as any;
      if (flags.permission) {
        if (data?.length > 0) {
          hotelIds = _.uniq(data?.filter(x => x?.type === 2)
            ?.map((x) => x.hotelId)).join(",");

          const unpaidInvoiceSum = data
            ?.map((x) => +x.unpaidInvoiceACH + +x.unpaidInvoiceCC + +x.unpaidInvoiceCheck + +x.unpaidInvoiceDACH + +x.unpaidTransfer + (featureFlagEpay === "no" ? +0 : +x.unpaidInvoiceEPAY))
            ?.reduce((a, b) => a + b, 0);
          const unpaidInvoiceObj: any = {
            label: "Unpaid Invoices",
            counts: unpaidInvoiceSum,
            name: "unpaidInvoice",
            isVisible: flags?.isHide === 0 ? true : false,
          };

          if (unpaidInvoiceObj.counts > 0) {
            allAccountingOverviewCounts.push(unpaidInvoiceObj);
            if (unpaidInvoiceObj.isVisible) {
              accountingOverviewCounts.push(unpaidInvoiceObj);
              if (accountingOverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0) {
                const index = accountingOverviewCounts.findIndex((x) => x.name === "totalHiddenBoxes");
                let hiddenObjArr = accountingOverviewCounts.splice(index, 1);
                accountingOverviewCounts.push(hiddenObjArr[0]);
              }
              this.setState({ accountingOverviewCounts });
            }
            else {
              this.getHiddenCount(1);
            }
            this.setState({ allAccountingOverviewCounts, isSliderLoading: false });
          }
          this.setState({
            unpaidInvoiceData: data || [],
            hotelIds,
            ePayFeatureflag: featureFlagEpay
          });
        }
      }
    }).finally(() => {
      this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
      // this.setState({ isSliderLoading: false });
      this.setslickSliderCount();
    });
  };

  GetAccountOverviewFlaggedTransaction= () => {
    accountService.GetAccountOverviewFlaggedTransaction().then((data) => {
      let accountingOverviewCounts = [...this.state.accountingOverviewCounts];
      let allAccountingOverviewCounts = [...this.state.allAccountingOverviewCounts];
      // let hotelIds = "";
      const flags = data[0];
      
      if (flags.canFlagComplete || flags.canReviewPermission) {
        if (data?.length > 0) {
          // hotelIds = _.uniq(data?.filter(x => x?.type === 2)
          //   ?.map((x) => x.hotelId)).join(",");

          const flaggedTransactionSum = data
          ?.map((x) => 
              (flags.canReviewPermission ? (x.needsReview ? x.needsReview : 0) : 0) + 
              (flags.canFlagComplete  ? (x.reviewed ? x.reviewed : 0): 0)
          )
          .reduce((sum, value) => sum + value, 0);
          const flaggedTransactionObj: any = {
            label: "Flagged Transactions",
            counts: flaggedTransactionSum,
            name: "flaggedTransactions",
            isVisible: flags?.isHide === 0 ? true : false,
          };

          if (flaggedTransactionObj.counts > 0) {
            allAccountingOverviewCounts.push(flaggedTransactionObj);
            if (flaggedTransactionObj.isVisible) {
              accountingOverviewCounts.push(flaggedTransactionObj);
              if (accountingOverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0) {
                const index = accountingOverviewCounts.findIndex((x) => x.name === "totalHiddenBoxes");
                let hiddenObjArr = accountingOverviewCounts.splice(index, 1);
                accountingOverviewCounts.push(hiddenObjArr[0]);
              }
              this.setState({ accountingOverviewCounts });
            }
            else {
              this.getHiddenCount(1);
            }
            this.setState({ allAccountingOverviewCounts, isSliderLoading: false });
          }
          this.setState({
            flaggedTransactionsData: data || [],
            // hotelIds,
            canFlagComplete:flags.canFlagComplete,
            canReviewPermission:flags.canReviewPermission
          });
        }
      }
    }).finally(() => {
      this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
      // this.setState({ isSliderLoading: false });
      this.setslickSliderCount();
    });
  };

  GetAccountOverviewUnapprovedVendor = () => {
    accountService.GetAccountOverviewUnapprovedVendor().then((data) => {
      let accountingOverviewCounts = [...this.state.accountingOverviewCounts];
      let allAccountingOverviewCounts = [...this.state.allAccountingOverviewCounts];
      let hotelIds = "";
      const flags = data[0];

      if (flags.permission) {
        if (data?.length > 0) {
          hotelIds = _.uniq(data?.filter(x => x?.type === 2)
            ?.map((x) => x.hotelId)).join(",");

          const unapprovedVendorsSum = data
            ?.map((x) => +x.invoiceCount)[0];
          const unapprovedVendorsObj: any = {
            label: "Unapproved Vendors",
            counts: unapprovedVendorsSum,
            name: "unapprovedVendors",
            isVisible: flags?.isHide === 0 ? true : false,
          };

          if (unapprovedVendorsObj.counts > 0) {
            allAccountingOverviewCounts.push(unapprovedVendorsObj);
            if (unapprovedVendorsObj.isVisible) {
              accountingOverviewCounts.push(unapprovedVendorsObj);
              if (accountingOverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0) {
                const index = accountingOverviewCounts.findIndex((x) => x.name === "totalHiddenBoxes");
                let hiddenObjArr = accountingOverviewCounts.splice(index, 1);
                accountingOverviewCounts.push(hiddenObjArr[0]);
              }
              this.setState({ accountingOverviewCounts });
            }
            else {
              this.getHiddenCount(1);
            }
            this.setState({ allAccountingOverviewCounts, isSliderLoading: false });
          }
          this.setState({
            unapprovedVendorData: data || [],
            hotelIds
          });
        }
      }
    }).finally(() => {
      this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
      // this.setState({ isSliderLoading: false });
      this.setslickSliderCount();
    });
  };

  GetReconcileOverview = () => {
    accountService.GetReconcileOverview().then((data) => {
      let accountingOverviewCounts = [...this.state.accountingOverviewCounts];
      let allAccountingOverviewCounts = [...this.state.allAccountingOverviewCounts];
      // let hotelIds = "";
      const flags = data[0];
      let hiddenCount = 0;
      //NW-31591 hanlde null
      if (flags?.permission) {
        if (data?.length > 0) {
          // hotelIds = _.uniq(data?.filter(x => x?.type === 2)
          //   ?.map((x) => x.hotelId)).join(",");

          const allReconcileCount: number = flags.allCount;

          const allReconcileObj: any = {
            label: "Accounts to Reconcile",
            counts: allReconcileCount,
            name: "allreconcile",
            isVisible: flags?.isAllHide === 0 ? true : false,
          };

          if (allReconcileObj.counts > 0) {
            allAccountingOverviewCounts.push(allReconcileObj);
            if (allReconcileObj.isVisible) {
              accountingOverviewCounts.push(allReconcileObj);
              if (accountingOverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0) {
                const index = accountingOverviewCounts.findIndex((x) => x.name === "totalHiddenBoxes");
                let hiddenObjArr = accountingOverviewCounts.splice(index, 1);
                accountingOverviewCounts.push(hiddenObjArr[0]);
              }
              // this.setState({accountingOverviewCounts});
            }
            else {
              // this.getHiddenCount();
              hiddenCount = hiddenCount + 1;
            }
            // this.setState({allAccountingOverviewCounts});
          }

          const overDueReconcileCount: number = flags.overDueCount;
          const overDueReconcileObj: any = {
            label: "Overdue Reconciliations",
            counts: overDueReconcileCount,
            name: "overduereconcile",
            isVisible: flags?.isDueHide === 0 ? true : false,
          };

          if (overDueReconcileObj.counts > 0) {
            allAccountingOverviewCounts.push(overDueReconcileObj);
            if (overDueReconcileObj.isVisible) {
              accountingOverviewCounts.push(overDueReconcileObj);
              if (accountingOverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0) {
                const index = accountingOverviewCounts.findIndex((x) => x.name === "totalHiddenBoxes");
                let hiddenObjArr = accountingOverviewCounts.splice(index, 1);
                accountingOverviewCounts.push(hiddenObjArr[0]);
              }
              // this.setState({accountingOverviewCounts});
            }
            else {
              // this.getHiddenCount();
              hiddenCount = hiddenCount + 1;
            }
            // this.setState({allAccountingOverviewCounts});
          }
          this.setState({ accountingOverviewCounts, allAccountingOverviewCounts, reconcileAccountsData: data || [], }, () => {
            this.setState({ isSliderLoading: false });
            if (hiddenCount > 0) {
              this.getHiddenCount(hiddenCount);
            }
          })
        }
      }
    }).finally(() => {
      this.setState({ apisCalledCount: this.state.apisCalledCount + 1 });
      // this.setState({ isSliderLoading: false });
      this.setslickSliderCount();
    });
  };

  getHiddenCount = (hiddenCount) => {
    let accountingOverviewCounts = Object.assign([], JSON.parse(JSON.stringify(this.state.accountingOverviewCounts)));
    if (accountingOverviewCounts.filter((x) => x.name === "totalHiddenBoxes").length > 0) {
      const index = accountingOverviewCounts.findIndex((x) => x.name === "totalHiddenBoxes");
      accountingOverviewCounts[index].counts = accountingOverviewCounts[index].counts + hiddenCount;
      accountingOverviewCounts[index].label = "Show " + accountingOverviewCounts[index].counts + " Hidden";
    }
    else {
      let totalHiddenBoxes = hiddenCount;
      const totalHiddenBoxesObj: any = {
        label: "Show " + totalHiddenBoxes + " Hidden",
        counts: totalHiddenBoxes,
        name: "totalHiddenBoxes",
        isVisible: true,
      };
      accountingOverviewCounts.push(totalHiddenBoxesObj);
    }
    this.setState({ accountingOverviewCounts }, () => {
      this.setslickSliderCount();
    });
  }

  optionBoxClick = (name: string) => {
    if (this.state.activeComparision !== "unapprovedVendors" &&
      this.state.activeComparision !== "untrustedVendors") {
      this.setState({ parameterSet: false });
    }

    this.setState({ activeComparision: '' }, () =>
      this.setState({ activeComparision: this.state.hotelAccessDetails.length === 1 && name === 'unpaidInvoice' ? "" : name }, () => {
        if (this.state.hotelAccessDetails.length === 1 && name === 'unpaidInvoice') {
          const location = {
            pathname: "/payments",
            search: "",
          };
          const hotelID = this.state.hotelAccessDetails[0]?.hotelID;
          let url =
            this.props.history.createHref(location) + `?hotelId=${btoa(hotelID)}`;
          url = encodeURI(url);
          window.location.replace(url);
          return;
        }
      })
    );
  };

  handleShowHidden = () => {
    // let allAccountingOverviewCounts = this.state.allAccountingOverviewCounts;

    // for (let i = 0; i < allAccountingOverviewCounts.length; i++) {
    //   if(allAccountingOverviewCounts[i].name === "totalHiddenBoxes") {
    //     allAccountingOverviewCounts[i].isVisible = false;
    //   }
    //   else {
    //     allAccountingOverviewCounts[i].isVisible = true;
    //   }
    // }
    // allAccountingOverviewCounts = allAccountingOverviewCounts.filter(x => x?.counts > 0 && x?.isVisible);
    // this.setState({accountingOverviewCounts: allAccountingOverviewCounts});
    let accountingOverviewCounts = Object.assign([], JSON.parse(JSON.stringify(this.state.allAccountingOverviewCounts)));
    accountingOverviewCounts.sort((a, b) => {
      if (a.isVisible > b.isVisible) { return -1 };
      if (a.isVisible < b.isVisible) { return 1 };
      return 0;
    })

    this.setState({ accountingOverviewCounts });
  }

  exportToPDF = () => {
    let reportParameters = JSON.parse(
      localStorage.getItem("reportParameters")!
    );
    reportParameters.push({ name: "writerFormat", value: "Excel" });
    ReportExport.reportDownload(JSON.stringify(reportParameters)).then(
      (result) => {
        const href = window.URL.createObjectURL(result);
        const a = this.linkRef.current;
        a.download = "DailyFlash.xls";
        a.href = href;
        a.click();
        a.href = "";
      }
    );
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.handleAccountingRedirectionRow(row);
    },
  };

  handleAccountingRedirectionRow = (row: any) => {
    const { activeComparision } = this.state;
    let hotelID = row?.hotelId || '';
    let hCode = row?.lettercode || '';

    if (activeComparision === "draftInvoice") {
      const location = {
        pathname: "/invoiceInbox",
        search: "",
      };
      let url =
        this.props.history.createHref(location) + `?hotelId=${btoa(hotelID)}`;
      url = encodeURI(url);
      window.location.replace(url);
      return;
    }


    if (activeComparision === "unpaidInvoice") {
      const location = {
        pathname: "/payments",
        search: "",
      };
      let url =
        this.props.history.createHref(location) + `?hotelId=${btoa(hotelID)}`;
      url = encodeURI(url);
      window.location.replace(url);
      return;
    }

    if (activeComparision === "myInvoices" || activeComparision === "unapprovedInvoice") {
      //   const location = {
      //     pathname: "/invoiceapproval",
      //     search: "",
      //   };
      //   let url =
      //     this.props.history.createHref(location) + `?hotelId=${btoa(hotelID)}&tabName=${activeComparision}`;
      //   url = encodeURI(url);
      //   window.location.replace(url);

      const location = {
        pathname: "/invoiceapproval",
        search: "",
      };
      let parmvalue = hotelID.toString() + "_" + (activeComparision === "unapprovedInvoice" ? "AllInvoices" : activeComparision)
      let url = this.props.history.createHref(location) + `?parm=${btoa(parmvalue)}`;
      url = encodeURI(url);
      window.location.replace(url);

      return;
    }

    if (activeComparision === "flaggedTransactions") {
      const location = {
        pathname: "/CommunicationsInbox",
        search: "",
      };
      let url =
        this.props.history.createHref(location)+ `?activePageTab=All`;
      url = encodeURI(url);
      window.location.replace(url);
      localStorage.setItem("mainMenu", "Communications");
      localStorage.setItem("subMenu","")
      return;
    }


    // let pagePath = "";

    // switch (activeComparision) {
    //   case "unapprovedInvoice":
    //     pagePath =
    //       "/AccountPayable/SelectNotAppInvoice.aspx?PageName=SelectNotAppInvoice";
    //     break;
    //   default:
    //     break;
    // }
    // if (!pagePath) {
    //   return;
    //}
    // User.GenerateToken()
    //   .then(async (result: any | null) => {

    //     if (result) {
    //       let mergeURL = result.result.mergeURL;
    //       let token = result.result.token;
    //       let tokenID = result.result.tokenID;
    //       let pageURL,
    //         queryStringKey,
    //         queryStringValue,
    //         functionName = "";
    //       pageURL = pagePath?.split("?")[0];
    //       queryStringKey = pagePath?.split("?")[1]?.split("=")[0];
    //       queryStringValue = pagePath?.split("?")[1]?.split("=")[1];
    //       if (pagePath.includes("*")) {
    //         functionName = pagePath.split("*")[1];
    //       } else {
    //         functionName = "None";
    //       }
    //       let roleID = JSON.parse(localStorage.getItem("storage")!)?.rolId;
    //       let storage = JSON.parse(localStorage.getItem("storage")!);
    //       let tenantID = storage === null ? 0 : (storage.tenantID as any);
    //       let username = storage === null ? 0 : (storage.userName as any);
    //       const finalUrl = mergeURL +
    //         // "http://localhost:50491/login.aspx" +
    //         "?logInUser=" +
    //         btoa(username) +
    //         "&tokenID=" +
    //         btoa(tokenID) +
    //         "&module=" +
    //         btoa("Accounting") +
    //         "&logInType=2&PageUrl=" +
    //         pageURL +
    //         "&Key=" +
    //         btoa(queryStringKey) +
    //         "&Value=" +
    //         btoa(queryStringValue) +
    //         "&function=" +
    //         btoa(functionName) +
    //         (+hotelID ? ("&hid=" +
    //           hotelID) : '') +
    //         (hCode ? ("&HCode=" +
    //           hCode) : ""
    //         );

    //       window.open(finalUrl);
    //     }
    //     resolve();
    //   })
    //   .catch((error) => {
    //     reject();
    //   });
  };

  setslickSliderCount = () => {
    if (this.hasPageWidth.current !== null) {
      let navbtnClicked = this.state.navbtnClicked;
      let setTimeVal = 0;
      if (navbtnClicked) {
        setTimeVal = 500
      }
      else {
        setTimeVal = 0
      }
      setTimeout(() => {
        let hasWidth = this.hasPageWidth.current.clientWidth;
        //this is for slidesToShow
        // if (hasWidth < 540) {
        //   this.setState({ scrollItemList: 1 });
        // } else if (hasWidth > 540 && hasWidth < 810) {
        //   this.setState({ scrollItemList: 2 });
        // } else if (hasWidth > 810 && hasWidth < 1080) {
        //   this.setState({ scrollItemList: 3 });
        // } else if (hasWidth > 1080 && hasWidth < 1350) {
        //   this.setState({ scrollItemList: 4 });
        // } else if (hasWidth > 1350 && hasWidth < 1620) {
        //   this.setState({ scrollItemList: 5 });
        // } else {
        //   this.setState({ scrollItemList: 6 });
        // }
        if (hasWidth < 440) {
          this.setState({ scrollItemList: 1 });
        } else if (hasWidth > 440 && hasWidth < 660) {
          this.setState({ scrollItemList: 2 });
        } else if (hasWidth > 660 && hasWidth < 880) {
          this.setState({ scrollItemList: 3 });
        } else if (hasWidth > 880 && hasWidth < 1100) {
          this.setState({ scrollItemList: 4 });
        } else if (hasWidth > 1100 && hasWidth < 1320) {
          this.setState({ scrollItemList: 5 });
        } else if (hasWidth > 1320 && hasWidth < 1540) {
          this.setState({ scrollItemList: 6 });
        } else {
          this.setState({ scrollItemList: 7 });
        }
        this.setState({ scrollWidth: hasWidth });
      }, setTimeVal);
    }
  }

  handleRedirectionBox = (row: any = {}, type = "") => {
    this.optionBoxClick(type);
    if (type === "draftInvoice") {
      const location = {
        pathname: "/invoiceInbox",
        search: "",
      };
      let url = this.props.history.createHref(location);
      url = encodeURI(url);
      window.open(url, "_blank");
      return;
    }
    if (type === "unapprovedInvoice") {
      const location = {
        pathname: "/payments",
        search: "",
      };
      let url = this.props.history.createHref(location);
      url = encodeURI(url);
      window.open(url, "_blank");
      return;
    }

    let pagePath = "";
    switch (type) {
      case "unapprovedVendors":
        pagePath = "/AccountPayable/VendorApproval.aspx?PageName=VendorApproval";
        break;
      case "untrustedVendors":
        pagePath = "/AccountPayable/UntrustedVendors.aspx?PageName=UntrustedVendors";
        break;
      default:
        break;
    }
    if (!pagePath) {
      return;
    }

    if (type === "untrustedVendors") {
      this.ReSendOTP();
      this.setState({ isOtpNeed: true });
    } else {
      User.GenerateToken()
        .then(async (result: any | null) => {

          if (result) {
            let mergeURL = result.result.mergeURL;

            let token = result.result.token;
            let tokenID = result.result.tokenID;
            let pageURL,
              queryStringKey,
              queryStringValue,
              functionName = "";
            if (pagePath?.split("?")[0]) {
              pageURL = pagePath?.split("?")[0];
              queryStringKey = pagePath?.split("?")[1]?.split("=")[0];
              queryStringValue = pagePath?.split("?")[1]?.split("=")[1];
              if (pagePath.includes("*")) {
                functionName = pagePath.split("*")[1];
              } else {
                functionName = "None";
              }
            } else {
              queryStringKey = "PageName";
              queryStringValue =
                type === "untrustedVendors"
                  ? "UntrustedVendors"
                  : "VendorApproval";
            }
            let hotelID = row?.hotelId || '';
            let hCode = row?.lettercode || '';
            let roleID = JSON.parse(localStorage.getItem("storage")!)?.rolId;
            let storage = JSON.parse(localStorage.getItem("storage")!);
            let tenantID = storage === null ? 0 : (storage.tenantID as any);
            let username = storage === null ? 0 : (storage.userName as any);
            const finalUrl = mergeURL +
              // "http://localhost:50491/login.aspx" +
              "?logInUser=" +
              btoa(username) +
              "&tokenID=" +
              btoa(tokenID) +
              "&module=" +
              btoa("Accounting") +
              "&logInType=2&PageUrl=" +
              pageURL +
              "&Key=" +
              btoa(queryStringKey) +
              "&Value=" +
              btoa(queryStringValue) +
              "&function=" +
              btoa(functionName) +
              (+hotelID ? ("&hid=" +
                hotelID) : '') +
              (hCode ? ("&HCode=" +
                hCode) : ""
              );
            window.open(finalUrl);
          }
          resolve();
        })
        .catch((error) => {
          reject();
        });
    }

  };

  toggleTableLoader = (value, row) => {
    let { transtoReviewData } = this.state;
    transtoReviewData = transtoReviewData.map((items) => {
      if (items.id === row.id) {
        return { ...items, isLoader: value };
      } else {
        return items;
      }
    });
    this.setState((curr: any) => ({ ...curr, transtoReviewData }));
  }

  handleAccOverviewTransAlert = (row: any) => {
    this.toggleTableLoader(true, row)
    this.GetDefaultHotelByAccount(row, "toBankSync");
  }

  GetDefaultHotelByAccount = (row: any, callType: any) => {
    let defaultrequest: any = {};
    defaultrequest.OprID = row.oprID;
    defaultrequest.AcctType = row.acctType === 'Credit Card' ? 2 : 1;
    registersevice.GetDefaultHotelByAccount(defaultrequest)
      .then(async (result: any[] | null) => {
        if (result !== null) {
          let hotelArray: any = {};
          hotelArray.lettercode = result[0].lettercode;
          hotelArray.hotelID = result[0].hotelID;
          sessionStorage.getItem("hotelSelected") && sessionStorage.removeItem("hotelSelected");
          localStorage.getItem("hotelSelected") && localStorage.removeItem("hotelSelected");
          sessionStorage.setItem("hotelSelected", JSON.stringify(hotelArray));
          localStorage.setItem("hotelSelected", JSON.stringify(hotelArray));
          if (callType === "toBankSync") {
            localStorage.setItem("subMenu", "Bank Sync");
            this.props.history.push('/BankSync', { oprid: row.oprID, acctType: row.acctType, pageName: 'Account' });
          }
        }
        this.toggleTableLoader(false, row);
        resolve();
      })
      .catch((error) => {
        this.toggleTableLoader(false, row);
        reject();
      });
  };


  handleReconcileBtnClick = (row) => {
    //debugger;
    let reconcilePermissionArray = this.state.reconcilePermissionArray;
    if (reconcilePermissionArray.filter((x) => x.acctType === "CC").length > 0) {
      const index = reconcilePermissionArray.findIndex((x) =>  x.acctType === "CC");
      reconcilePermissionArray[index].acctType = "C";
    }

    if (reconcilePermissionArray.filter(i => i.acctType === row.acctType).length > 0) {
      this.props.history.push('/reconcile', { hotelId: row.hid.toString(), letterCode: row.lettercode, oprID: row.oprID, Accper: row.accPerDisplay, fromPage: "overview", activeAlertBox: this.state.activeComparision, });
    }
    else {
      Utils.toastError("You do not have permission to view this page. Please contact your administrator.", { position: toast.POSITION.BOTTOM_RIGHT });
    }
  }

  render() {
    const {
      accountingOverviewCounts,
      accountingOverviewData,
      activeComparision,
      isDataLoaded,
      parameterSet,
      isSliderLoading,
      apisCalledCount,
      draftInvoiceData,
      unapprovedInvoiceData,
      unpaidInvoiceData,
      flaggedTransactionsData,
      unapprovedVendorData,
      transtoReviewData,
      reconcileAccountsData,
    } = this.state;



    const handleAccountingRedirectionBox = (row: any = {}, type = "") => {
      // /AccountPayable/VendorSelection.aspx?x=Vendor Accounts*VendorMgt
      // /AccountPayable/SelectNotAppInvoice.aspx?PageName=InvoiceApproval*ApprovalMgt
      // /AccountPayable/SelectChkWrtInv.aspx?x=CheckWriterInvoice*CheckWriterMgt
      this.optionBoxClick(type);
      if (type === "draftInvoice") {
        const location = {
          pathname: "/invoiceInbox",
          search: "",
        };
        let url = this.props.history.createHref(location);
        url = encodeURI(url);
        window.open(url, "_blank");
        return;
      }
      else if (type === "unapprovedVendors") {
        const location = {
          pathname: "/invoiceapproval",
          search: "",
        };
        let parmvalue = "-1" + "_VendorApproval"
        let url = this.props.history.createHref(location) + `?parm=${btoa(parmvalue)}`;
        url = encodeURI(url);
        window.location.replace(url);
        return;
      }

      let pagePath = "";
      switch (type) {
        case "unapprovedInvoice":
          pagePath =
            "/AccountPayable/SelectNotAppInvoice.aspx?PageName=SelectNotAppInvoice";
          break;
        case "unapprovedVendors":
          pagePath = "/AccountPayable/VendorApproval.aspx?PageName=VendorApproval";
          break;
        case "untrustedVendors":
          pagePath = "/AccountPayable/UntrustedVendors.aspx?PageName=UntrustedVendors";
          break;
        default:
          break;
      }
      if (!pagePath) {
        return;
      }

      if (type === "untrustedVendors") {
        this.ReSendOTP();
        this.setState({ isOtpNeed: true });
      } else {
        User.GenerateToken()
          .then(async (result: any | null) => {

            if (result) {
              let mergeURL = result.result.mergeURL;

              let token = result.result.token;
              let tokenID = result.result.tokenID;
              let pageURL,
                queryStringKey,
                queryStringValue,
                functionName = "";
              if (pagePath?.split("?")[0]) {
                pageURL = pagePath?.split("?")[0];
                queryStringKey = pagePath?.split("?")[1]?.split("=")[0];
                queryStringValue = pagePath?.split("?")[1]?.split("=")[1];
                if (pagePath.includes("*")) {
                  functionName = pagePath.split("*")[1];
                } else {
                  functionName = "None";
                }
              } else {
                queryStringKey = "PageName";
                queryStringValue =
                  type === "untrustedVendors"
                    ? "UntrustedVendors"
                    : "VendorApproval";
              }
              let hotelID = row?.hotelId || '';
              let hCode = row?.lettercode || '';
              let roleID = JSON.parse(localStorage.getItem("storage")!)?.rolId;
              let storage = JSON.parse(localStorage.getItem("storage")!);
              let tenantID = storage === null ? 0 : (storage.tenantID as any);
              let username = storage === null ? 0 : (storage.userName as any);
              const finalUrl = mergeURL +
                // "http://localhost:50491/login.aspx" +
                "?logInUser=" +
                btoa(username) +
                "&tokenID=" +
                btoa(tokenID) +
                "&module=" +
                btoa("Accounting") +
                "&logInType=2&PageUrl=" +
                pageURL +
                "&Key=" +
                btoa(queryStringKey) +
                "&Value=" +
                btoa(queryStringValue) +
                "&function=" +
                btoa(functionName) +
                (+hotelID ? ("&hid=" +
                  hotelID) : '') +
                (hCode ? ("&HCode=" +
                  hCode) : ""
                );
              window.open(finalUrl);
            }
            resolve();
          })
          .catch((error) => {
            reject();
          });
      }

    };

    const getLabelName = () => {
      let label: any = "Draft Invoices";
      switch (activeComparision) {
        case "draftInvoice":
          label = "Draft Invoices";
          break;
        case "myInvoices":
          label = "Invoice Approvals";
          break;
        case "unapprovedInvoice":
          label = "Invoice Approvals";
          break;
        case "unapprovedVendors":
          label = "Unapproved Vendors";
          break;
        case "unpaidInvoice":
          label = "Unpaid Invoices";
          break;
        case "untrustedVendors":
          label = "Untrusted Vendors";
          break;
        case "TranstoReview":
          label = "Bank Transactions to Review";
          break;
        case "allreconcile":
          label = "Accounts to Reconcile";
          break;
        case "overduereconcile":
          label = "Overdue Reconciliations";
          break;
        case "flaggedTransactions":
            label ="Flagged Transactions";
            break;

        default:
          break;
      }

      return label;
    };

    const getModifiedTableData = () => {
      let tableData: any[] = [];

      switch (this.state.activeComparision) {
        case "draftInvoice":

          tableData = draftInvoiceData
            ?.filter((x) => +x.invoiceCount > 0)
            .map((x) => {
              x.counts = x.invoiceCount;
              return x;
            });
          break;
        case "unapprovedInvoice":
          tableData = unapprovedInvoiceData
            ?.filter((x) => +x.allInvoiceCount > 0)
            .map((x) => {
              x.counts = x.allInvoiceCount;
              return x;
            });
          break;
        case "myInvoices":
          tableData = unapprovedInvoiceData
            ?.filter((x) => +x?.myInvoiceCount > 0)
            .map((x) => {
              x.counts = x?.myInvoiceCount;
              return x;
            });
          break;
        case "flaggedTransactions":
          tableData = flaggedTransactionsData
          ?.filter((x) => 
              (x.canReviewPermission ? x.needsReview : 0) + 
              (x.canFlagComplete ? x.reviewed : 0)
          )
          .map((x) => {
              return x;
          });
          break;
        case "TranstoReview":
          tableData = transtoReviewData?.filter((x) => +x.toReviewCount > 0).map((x) => {
            x.counts = x.toReviewCount;
            return x;
          });
          break;

        case "unapprovedVendors":
          tableData = [];
          break;
        case "unpaidInvoice":
          tableData = unpaidInvoiceData
            ?.filter(
              (x) => +x.unpaidInvoiceCheck > 0 || +x.unpaidInvoiceACH > 0
                || +x.unpaidInvoiceDACH > 0 || +x.unpaidInvoiceCC > 0 || +x.unpaidTransfer > 0 || +x.unpaidInvoiceEPAY > 0
            )
            .map((x) => {
              x.checks = +x.unpaidInvoiceCheck;
              x.mach = +x.unpaidInvoiceACH;
              x.dach = +x.unpaidInvoiceDACH;
              x.cc = +x.unpaidInvoiceCC;
              x.transfer = +x.unpaidTransfer;
              x.unpaidInvoiceEPAY = + x.unpaidInvoiceEPAY;
              return x;
            });
          break;
        case "untrustedVendors":
          tableData = [];
          break;

        case "allreconcile":
          tableData = reconcileAccountsData
            ?.map((x) => {
              let dtSplit = x.accper.split("-");
              let dtMnth = dtSplit[1].toString()[0] === "0" ? dtSplit[1].toString().substring(1) : dtSplit[1].toString();
              let accPerDisplay = dtMnth + "/" + dtSplit[0].toString().substring(2);
              // x.accName = x.account;
              // x.status = x.status1;
              x.accPerDisplay = accPerDisplay;
              return x;
            });
          break;

        case "overduereconcile":
          tableData = reconcileAccountsData
            ?.filter(
              (x) => x.status1 == "Overdue"
            )
            .map((x) => {
              let dtSplit = x.accper.split("-");
              let dtMnth = dtSplit[1].toString()[0] === "0" ? dtSplit[1].toString().substring(1) : dtSplit[1].toString();
              let accPerDisplay = dtMnth + "/" + dtSplit[0].toString().substring(2);
              // x.accName = x.account;
              // x.status = x.status1;
              x.accPerDisplay = accPerDisplay;
              return x;
            });
          break;

        default:
          tableData = [];
          break;
      }

      return tableData;
    };

    let daycountlen = accountingOverviewCounts.length;

    // const settings = {
    //   className: "slider variable-width",
    //   dots: false,
    //   infinite: daycountlen > slickSliderCount ? true : false,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   variableWidth: true,
    //   nextArrow:
    //     daycountlen > slickSliderCount ? (
    //       <SampleNextArrow />
    //     ) : (
    //       <SampleNextArrowDisabled />
    //     ),
    //   prevArrow: <SamplePrevArrow />,
    // };
    const settings = {
      className: "slider variable-width",
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: this.state.scrollItemList,
      slidesToScroll: this.state.scrollItemList,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    const columns = [
      {
        dataField: "letterCode",
        text: "",
        hidden: activeComparision.toLowerCase() === "overduereconcile" || activeComparision.toLowerCase() === "allreconcile",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="hotelDetailsData"

          >
            <span className="hotelLetterAbbr">{row.lettercode}</span>
            <EllipsisWithTooltip placement="bottom">
              <span className="hotelFullName">{row.hotelName}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "counts",
        text: "",
        hidden: activeComparision.toLowerCase() === "unpaidinvoice" || activeComparision.toLowerCase() === "overduereconcile" || activeComparision.toLowerCase() === "allreconcile" ||  activeComparision.toLowerCase() == "flaggedtransactions",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            className="daysToApproveData"
          >
            <span className="daysToApproveCount">{row.counts}</span>
            <span className="daysToApproveLabel">{getLabelName()}</span>
          </div>
        ),
      }, {
        dataField: "checks",
        text: "",
        hidden: activeComparision.toLowerCase() !== "unpaidinvoice",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            className="daysToApproveData"
          >
            <span className="daysToApproveCount">{row.checks}</span>
            <span className="daysToApproveLabel">Unpaid Checks</span>
          </div>
        ),
      }, {
        dataField: "mach",
        text: "",
        hidden: activeComparision.toLowerCase() !== "unpaidinvoice",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            className="daysToApproveData"
          >
            <span className="daysToApproveCount">{row.mach}</span>
            <span className="daysToApproveLabel">Unpaid M-ACH</span>
          </div>
        ),
      }, 
      // {
      //   dataField: "dach",
      //   text: "",
      //   // hidden: activeComparision?.toLowerCase() !== "unpaidinvoice",
      //   hidden: true,
      //   formatter: (cell: any, row: any, rowIndex: any) => (
      //     <div
      //       className="daysToApproveData"
      //     >
      //       <span className="daysToApproveCount">{row.dach}</span>
      //       <span className="daysToApproveLabel">Unpaid D-ACH</span>
      //     </div>
      //   ),
      // }, 
      {
        dataField: "unpaidInvoiceEPAY",
        text: "",
        hidden: activeComparision.toLowerCase() !== "unpaidinvoice" || this.state.ePayFeatureflag === "no",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            className="daysToApproveData"
          >
            <span className="daysToApproveCount">{row.unpaidInvoiceEPAY}</span>
            <span className="daysToApproveLabel">Unpaid ePay</span>
          </div>
        ),
      }, {
        dataField: "cc",
        text: "",
        hidden: activeComparision.toLowerCase() !== "unpaidinvoice",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            className="daysToApproveData"
          >
            <span className="daysToApproveCount">{row.cc}</span>
            <span className="daysToApproveLabel">Unpaid Credit Card</span>
          </div>
        ),
      }, {
        dataField: "transfer",
        text: "",
        hidden: activeComparision.toLowerCase() !== "unpaidinvoice",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            className="daysToApproveData"
          >
            <span className="daysToApproveCount">{row.transfer}</span>
            <span className="daysToApproveLabel">Unpaid Transfers</span>
          </div>
        ),
      }, {
        dataField: "account",
        text: "",
        classes: 'reconcile',
        hidden: activeComparision.toLowerCase() !== "overduereconcile" && activeComparision.toLowerCase() !== "allreconcile",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="reconcile-account">{row.account}</div>
        ),
      }, {
        dataField: "status1",
        text: "",
        classes: 'reconcile',
        hidden: activeComparision.toLowerCase() !== "overduereconcile" && activeComparision.toLowerCase() !== "allreconcile",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className={row.status1 === "Overdue" ? "reconcile-status red" : "reconcile-status blue"}>{row.status1}</div>
        ),
      }, {
        dataField: "accPerDisplay",
        text: "",
        classes: 'reconcile',
        hidden: activeComparision.toLowerCase() !== "overduereconcile" && activeComparision.toLowerCase() !== "allreconcile",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="reconcile-period">
            {row.accPerDisplay}
            <div className="tag">Period</div>
          </div>
        ),
      }, {
        dataField: "",
        text: "",
        classes: 'reconcile right-align',
        hidden: activeComparision.toLowerCase() !== "overduereconcile" && activeComparision.toLowerCase() !== "allreconcile",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Button variant="primary" className="btn-outline-primary" onClick={() => { this.handleReconcileBtnClick(row) }}>Reconcile</Button>
        ),
      },
      {
        dataField: "needsReview",
        text: "",
        hidden: !this.state?.canReviewPermission || activeComparision.toLowerCase() !== "flaggedtransactions",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            className="daysToApproveData"
          >
            <span className="daysToApproveCount">{row.needsReview}</span>
            <span className="daysToApproveLabel">{row.needsReview > 1 ? "Transactions" :"Transaction"} To Review</span>
          </div>
        ),
      }, 

      {
        dataField: "reviewed",
        text: "",
        hidden: !this.state?.canFlagComplete || activeComparision.toLowerCase() !== "flaggedtransactions",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            className="daysToApproveData"
          >
            <span className="daysToApproveCount">{row.reviewed}</span>
            <span className="daysToApproveLabel">Reviewed {row.reviewed > 1 ? "Transactions" :"Transaction"}</span>
          </div>
        ),
      }, 
    ];

    const TranstoReviewcolumns = [
      {
        dataField: "letterCode",
        text: "",
        classes: "transbName",
        formatter: (cell: any, row: any, rowIndex: any) => {
          let accNoLast4Char = (row.oBaccno.length > 4 ? row.oBaccno.substr(row.oBaccno.length - 4) : row.oBaccno);
          console.log(row)
          return (
            <div className="hotelDetailsData">
              <span className="hotelLetterAbbr">
                <EllipsisWithTooltip placement="bottom">
                  {row.bName}
                </EllipsisWithTooltip>
              </span>
              <EllipsisWithTooltip placement="bottom">
                <span className="hotelFullName">
                  {row.displayName} - {accNoLast4Char} - {row.acctType}
                </span>
              </EllipsisWithTooltip>
            </div>
          )
        },
      }, {
        dataField: "counts",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div
            className="daysToApproveData">
            <span className="daysToApproveCount">{row.matchCount}</span>
            <span className="daysToApproveLabel">With Matches</span>
          </div>
        ),
      }, {
        dataField: "counts",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="daysToApproveData">
            <span className="daysToApproveCount">{row.counts}</span>
            <span className="daysToApproveLabel">To Review</span>
          </div>
        ),
      }, {
        dataField: "",
        text: "",
        classes: "text-right",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <>
            <Button disabled={row.isLoader} className="btn-outline-primary" onClick={() => this.handleAccOverviewTransAlert(row)}>{row.isLoader && (<Spinner size="sm" animation="border" />)} Review</Button>
          </>
        ),
      }
    ];
    let transToReview = getLabelName().toString().toLowerCase() === "bank transactions to review";
    return (
      <>
        <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"laborDayOverviewContainer"}
        />

        <div className="accounting-overview">
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">

              <div className="mr-auto">Accounting Overview</div>
              <div className="action-group d-flex">
                {apisCalledCount === noOfApis &&
                  <Button
                    className="add-time-off-shift btn-outline-primary"
                    onClick={() => this.getDailyFlashV4Report()}
                  >
                    {parameterSet ? "Hide Snapshot" : "View Snapshot"}
                  </Button>
                }
                <Dropdown className="more-action three-dot" alignRight>
                  <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="alertPreferences" key="alertPreferences"
                      as={Link}
                      to="/alertPreferences"
                      onClick={() => this.handleAlertPreference()}>
                      Alert Preferences
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {isSliderLoading && daycountlen === 0 && apisCalledCount !== noOfApis && (
              <ReactPageLoader useas={"laborDashboardTopBox"} />
            )}
            {daycountlen > 0 && (
              <div className="slider-option">
                <div
                  className={
                    "optionBoxCont d-flex"
                  }
                >
                  <div className="scroll-area" ref={this.hasPageWidth} style={{ width: `${this.state.scrollWidth}px` }}>
                    <div className="slick-arrow slick-prev slick-disabled d-flex justify-content-center align-items-center shadow-remove">
                      <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z" fill="#2CAF92"></path>
                      </svg>
                    </div>
                    <div className="slick-arrow slick-next slick-disabled d-flex justify-content-center align-items-center shadow-remove">
                      <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.03029 9.28026L6.31054 5.00001L2.03029 0.719757L0.969788 1.78026L4.18954 5.00001L0.969788 8.21976L2.03029 9.28026Z" fill="#2CAF92"></path>
                      </svg>
                    </div>
                    <Slider {...settings}>
                      {accountingOverviewCounts.map((data: any, key: any) => (
                        <div
                          style={{ width: 250 }}
                          // className={
                          //   this.state?.isCheckComponent === ""
                          //     ? "optionBox d-flex align-items-center"
                          //     : this.state.activeComparision === data.name
                          //       ? "optionBox d-flex align-items-center active"
                          //       : "optionBox d-flex align-items-center"
                          // }

                          className={`optionBox d-flex align-items-center ${this.state.activeComparision === data.name ? "active" : ""} ${data.name === "totalHiddenBoxes" ? "hiddenCountBoxes" : ""}`}
                          onClick={(e) =>
                            data.name !== "totalHiddenBoxes"
                              ? data.name === "unapprovedVendors" ||
                                data.name === "untrustedVendors"
                                ? handleAccountingRedirectionBox({}, data.name)
                                : this.optionBoxClick(data.name)
                              : e.stopPropagation()
                          }
                          key={key}
                        >
                          {data.name !== "totalHiddenBoxes" && (
                            <div
                              onClick={() =>
                                data.name === "unapprovedVendors" ||
                                  data.name === "untrustedVendors" ||
                                  data.name === "draftInvoice" ||
                                  data.name === "unapprovedInvoice" ||
                                  data.name === "myInvoices"
                                  ? {}
                                  : handleAccountingRedirectionBox({}, data.name)
                              }
                              className={`circle d-flex align-items-center justify-content-center ${data.name === "overduereconcile" ? "red" : data.name === "flaggedTransactions" ? "flagged-transaction-count":""}`}
                            >
                              {data.counts}
                            </div>
                          )}

                          <div className="label-sec" onClick={(e) => data.name === "totalHiddenBoxes" ? this.handleShowHidden() : ""}>
                            {data.label}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            )}
            <div ref={this.cardDivRef} className="accountingOverviewDetails">
              {getModifiedTableData()?.length > 0 && apisCalledCount === noOfApis && !parameterSet && (
                <>
                  {" "}
                  <h1>{getLabelName()}</h1>
                  <div className="accountingOverviewDetailsTable">
                    {transToReview ? (
                      <BootstrapTable
                        keyField="hotelId"
                        data={getModifiedTableData() || []}
                        columns={TranstoReviewcolumns}
                        hover
                      />
                    ) : (
                      <BootstrapTable
                        keyField="hotelId"
                        data={getModifiedTableData() || []}
                        columns={columns}
                        rowEvents={this.rowEvents}
                        hover
                      />
                    )}
                  </div>
                </>
              )}

              {parameterSet && <div ref={this.reportDivRef} className="body-sec profile-pto pad-btn-24">
                <div className="pto-report">
                  <ReportViewer onReportload={this.props?.onReportload} />
                </div>
              </div>
              }
            </div>
            {(daycountlen <= 0 && apisCalledCount === noOfApis) && (
              <div className="caught d-flex align-items-center justify-content-center">
                <img src={caught} alt="You’re all caught up!" />
                <div className="label-sec"> You’re all caught up!</div>
              </div>
            )}
            {this.state.isOtpNeed && (
              <OtpModal
                closePopup={this.closeOtpModal.bind(this)}
                ValidateOTP={this.ValidateOTP.bind(this)}
                ReSendOTP={this.ReSendOTP.bind(this)}
                purpose={this.state.Purpose}
              ></OtpModal>
            )}
          </Container>
        </div>
      </>
    );
  }
}

