import * as React from "react";
import { Dropdown } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { IHotelResponseDto } from "../../../Common/Contracts/IHotel";
import { Hotel } from "../../../Common/Services/Hotel";
import _ from "lodash";

export class SingleBusinessAccountSelectorGeneralTab extends React.Component<any, any> {
  private inputElement: any;
  private SingleselectChild: any;
  private singleSerch: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      hotelNameModal: "Select EHID", //Dispaly on account
      filterData: [],
      hotelList: [],
      inputValue: "",
    };
    this.handleChangeEhid = this.handleChangeEhid.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);

  }

 

  componentDidMount() {
      let getUserHotelAccess: any;
      if(this.props?.shouldGetAllActiveHotelsOfTenant) {
        getUserHotelAccess = Hotel.getUserHotelAccessUM();
      } else {
        if (this.props?.isAll) {
          if(this.props?.notIncludeEnterpriseAccounts) {
            getUserHotelAccess = Hotel.getUserHotelAccessAll("Labor Management", true);
          } else {
            getUserHotelAccess = Hotel.getUserHotelAccessAll("Labor Management");
          }
        } else {
            if(this.props?.notIncludeEnterpriseAccounts) {
            getUserHotelAccess = Hotel.getUserHotelAccess("Labor Management", true);
          } 
            else {
            getUserHotelAccess = Hotel.getUserHotelAccess("Labor Management");
          }
        }
      }

    
      // Hotel.getUserHotelAccess("Labor Management")
      // .then(async (umResult: IHotelResponseDto[] | null) => {
      getUserHotelAccess
        .then(async (result: IHotelResponseDto[] | null) => {
          if (result != null && result.length > 0) {
            let hotelList: any[] = [];
            result.forEach((element) => {
              let newItem = {} as any;
              newItem.hotelID = element.hotelID;
              newItem.value = element.lettercode;
              newItem.description = element.hotelName;
              newItem.hotelType = element.hotelType;
              newItem.isChecked = false;
              newItem.timeZone = element.timeZone;
              newItem.status = element.status;
              // if(newItem.status?.toLowerCase() === "inactive"){
              //   newItem.enabled = false;
              // }else{
               
                 if(this.props.payrollIntegrationHids?.includes(newItem.hotelID))
                {
                  newItem.enabled = false;
                  newItem.payrollIntegrated =true
                }
                else {
                  newItem.enabled = true;
                }
              // }
              hotelList.push(newItem);
            });
            if(this.props.userAction==="Remove" ) {
              const filterData =  hotelList.filter(x=> this.props.selectedHids.includes(x.value));
               this.setState({filterData});
             }
             else if(this.props.userAction==="Add") {
               const filterData =  hotelList.filter(x=> !this.props.selectedHids.includes(x.value));
               this.setState({filterData});
             }
             else {
               this.setState({filterData:hotelList});
             }

             hotelList = _.sortBy(hotelList, ['status','hotelType', 'value']);
            this.setState({ hotelList: hotelList });
            const { defaultValue } = this.props;
            
            if (+defaultValue) {
              const hotel = hotelList.find((r) => +r.hotelID === +defaultValue);
              this.props.isHotelid(hotel);
              this.hidSelectModal(hotel?.description);
            }
          }
        })
        .catch((error) => {
          // Need to handle this
          console.log(error);
        });
     // });

    }

  handleChangeEhid(e: { target: { value: string } }) {
    const updatedList = this.state.hotelList.filter((item) => {
      return (
        item.description.toLowerCase().indexOf(e.target.value.toLowerCase()) >
        -1
      );
    });

     if(this.props.userAction==="Remove" ) {
      const filterData =  updatedList.filter(x=> this.props.selectedHids.includes(x.value));
       this.setState({filterData});
     }
     else if(this.props.userAction==="Add") {
       const filterData =  updatedList.filter(x=> !this.props.selectedHids.includes(x.value));
       this.setState({filterData});
     }
     else {
       this.setState({filterData:updatedList});
     }
    this.setState({  inputValue: e.target.value });
  }

  handleDropdownChange(e: any) {
    this.props.handleBusinessAccountChange(e);
    this.resetSerchBox();
  }

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    const {hotelList} = this.state;
    if(this.props.userAction==="Remove" ) {
      const filterData =  hotelList.filter(x=> this.props.selectedHids.includes(x.value));
       this.setState({filterData});
     }
     else if(this.props.userAction==="Add") {
       const filterData =  hotelList.filter(x=> !this.props.selectedHids.includes(x.value));
       this.setState({filterData});
     }
     else {
       this.setState({filterData:hotelList});
     }
  };
  componentDidUpdate = (prevProps: any) => {
    const { defaultValue } = this.props;
    if (!defaultValue && prevProps.defaultValue !== defaultValue) {
      this.hidSelectModal("Select EHID");
    }
    if (prevProps?.userAction !== this.props?.userAction) {
      let  hotelList =  _.cloneDeep(this.state.hotelList);
      if(this.props.userAction==="Remove" ) {
       const filterData =  hotelList.filter(x=> this.props.selectedHids.includes(x.value));
        this.setState({filterData});
      }
      else if(this.props.userAction==="Add") {
        const filterData =  hotelList.filter(x=> !this.props.selectedHids.includes(x.value));
        this.setState({filterData});
      }
      else {
        this.setState({filterData:hotelList});
      }
      
    }
    
  };

  hidSelectModal = (eventKey: any) => {
    this.setState({ inputValue: "" });
    this.setState({ hotelNameModal: eventKey });
  };

  render() {
    const onFocusInput = (eventKey: any) => {
      setTimeout(() => {
        this.inputElement?.current?.focus();
      }, 100);
    };

    return (
      <Dropdown
        className="single-select"
        onSelect={this.hidSelectModal}
        onClick={onFocusInput}
      >
        <Dropdown.Toggle id="Modal-hid">
          {this.state.hotelNameModal === "Select Group" && (
            <span className="placeholder">{this.state.hotelNameModal}</span>
          )}
          {this.state.hotelNameModal !== "Select Group" && (
            <EllipsisWithTooltip placement="bottom">
              <span
                style={{
                  width: "95%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                }}
              >
                {this.state.hotelNameModal}
              </span>
            </EllipsisWithTooltip>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="search-sec">
            <input
              type="text"
              className="form-control"
              onChange={this.handleChangeEhid}
              placeholder="Filter Hotel"
              value={this.state.inputValue}
              ref={this.inputElement}
            />
          </div>
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (
             <>
             <div className="list-item">
              
                <Dropdown.Item
                  disabled={!item.enabled}
                  className={
                    this.state.filterData[idx - 1]?.hotelType &&  this.state.filterData[idx - 1]?.hotelType !==
                    item?.hotelType
                      ? "hotel-separator"
                      : ""
                  }
                  eventKey={item.description}
                  onClick={() => this.handleDropdownChange(item)}
                >
                  <EllipsisWithTooltip placement="bottom">{item.description}</EllipsisWithTooltip> {item.payrollIntegrated && <div className="ml-auto badge grey-bg">Payroll Integerated </div>} 
                  {item.status.toLowerCase() == "inactive" && <div className="ml-auto badge grey-bg">Inactive </div>}
                </Dropdown.Item>
              
              
              </div>
              </> 
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
