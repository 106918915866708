import React from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { DwollaService } from '../../../Common/Services/DwollaService';

import { toast, ToastContainer } from "react-toastify";

import _ from "lodash";

export class DwollaSetupModal extends React.Component<any, any> {


  state: any = {
    isdisabled: true

  };

  componentDidMount = () => {

  };


  hideModal = (open) => {
    if (open) {
      DwollaService.AgreeDwollaTerms({ Hid: this.props.hotelId });
    }
    this.props?.hideDwollasetupModal(open);

  };
  CheckAgreebox = (e) => {
    // debugger
    this.setState({ isdisabled: !e.target.checked })
  };



  render() {
    // debugger
    const {

    } = this.state;
    const { showDwollSetUpModal } = this.props;
    return (
      <>
        <div className="">
          <ToastContainer
            containerId={"payrollAddressModal"}
            autoClose={3000}
          />

          <Modal
            className="dwolla-setup-modal"
            show={showDwollSetUpModal}
            onHide={() => this.hideModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Terms of service</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <p>By using the Digital ACH feature, you are agreeing to Inn-Flow and Dwolla's terms of service agreements. There are fees associated with sending Digital ACH transactions. Please review the following terms of service and pricing information.
                  </p>
                  <p>
                    <span className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="chkAgreebox"
                        value="Agree"
                        onClick={(e) => this.CheckAgreebox(e)}
                      />
                    </span>

                    By checking this box you agree to our  <a target="_blank" href="https://www.inn-flow.com/terms-of-service/"><span>Terms of Service</span></a> and  <a target="_blank" href="https://www.inn-flow.com/privacy-policy/"><span>Privacy Policy</span></a>, as well as our partner <a target="_blank" href="https://www.dwolla.com/legal/tos/"><span>Dwolla&#8217;s Terms of Service</span></a> and
                    <br />
                    <a target="_blank" href="https://www.dwolla.com/legal/privacy/"><span>Privacy Policy</span></a>.
                  </p>
                </div>
                <Button className="btn-ghost" onClick={() => this.hideModal(false)}>
                Cancel
              </Button>
              <Button
                disabled={this.state.isdisabled}
                className="btn btn-primary"
                onClick={() => this.hideModal(true)}
              >
                Agree
              </Button>
              </div>
            </Modal.Body>
            <Modal.Footer>
             
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
