import * as React from "react";
import { Container, Tooltip, OverlayTrigger,Dropdown,Tab, Tabs,Form, } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {InvoiceApproval} from "./InvoiceApproval";
import { LaborSetting } from "../../../Common/Services/LaborSetting";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import { Utils } from "../../../Common/Utilis";
import { ILaborEnterpriseSetting } from "../../../Common/Contracts/ILaborEnterpriseSetting";
import "./invoiceapproval.scss";
export class InvoiceApprovalSetting extends React.Component<any, any> {
  private childApproval: any;
  constructor(props: any) {
    super(props);
    this.childApproval = React.createRef();
    this.state = {
      ApprovalList: [],
      AAPermission: "Yes",
      activeTab: "general",
      isACHBypassApproval: false,
      isACHBypassPayment: false,
      isCCBypassApproval: false,
      isCCBypassPayment: false,
      enterPriseId: 0,
    };
  }

  componentDidMount() {
    LaborSetting.enterPriseSettingDetails()
      .then(async (result: any | null) => {
        if (result != null) {
          let data = result.result[0] as ILaborEnterpriseSetting;
          this.setState({
            isACHBypassApproval: data.isACHBypassApproval,
            isACHBypassPayment: data.isACHBypassPayment,
            isCCBypassApproval: data.isCCBypassApproval,
            isCCBypassPayment: data.isCCBypassPayment,
            enterPriseId: data.enterPriseID,
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  handleTabSelect = (eventKey: any) => {
    this.setState({ activeTab: eventKey });
  }

  onChange(
    e: any,
    type: string,
    isBlur = false,
    isInput = false,
    isChange = false
  ) {

    // if (this.state.isManageSetting) {
      switch (type) {
        case "isACHBypassApproval":
          this.setState({ isACHBypassApproval: e.target.checked });
          this.saveEnterPriseSetting("isACHBypassApproval", e.target.checked);
          break;

        case "isACHBypassPayment":
          this.setState({ isACHBypassPayment: e.target.checked });
          this.saveEnterPriseSetting("isACHBypassPayment", e.target.checked);
          break;

        case "isCCBypassApproval":
          this.setState({ isCCBypassApproval: e.target.checked });
          this.saveEnterPriseSetting("isCCBypassApproval", e.target.checked);
          break;

        case "isCCBypassPayment":
          this.setState({ isCCBypassPayment: e.target.checked });
          this.saveEnterPriseSetting("isCCBypassPayment", e.target.checked);
          break;

        default:
          return type;
      }
    // }
  }

  saveEnterPriseSetting(FieldName: string, FieldValue: any, toggle: any = "") {
    let laborEnterpriseRequest = {} as any;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    laborEnterpriseRequest.TenantID = tenantID;
    laborEnterpriseRequest.EnterPriseId = this.state.enterPriseId;
    laborEnterpriseRequest.FieldName = FieldName;
    laborEnterpriseRequest.FieldValue = FieldValue;
    LaborSetting.saveEnterPriseSetting(laborEnterpriseRequest)
      .then(async (result: any | null) => {

        if (result != null && result.message === "Settings Updated Successfully") {
          Utils.toastSuccess(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "inv-app-settings-success",
          });
        } else {
          Utils.toastError(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "inv-app-settings-error",
          });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  updateParent = (ApprovalList) => {
    this.setState({ApprovalList: ApprovalList});
  }

  render() {
    return (
      <Container fluid className="body-sec">
        <div className="page-heading d-flex">
          <div className="mr-auto">Accounts Payable Settings</div>
          {this.state.activeTab === "approvals" && this.state.AAPermission === "Yes" && (
            <div className="action-group d-flex">
              <Dropdown className="more-action" alignRight>
                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.ApprovalList.length > 0 && (
                    <Dropdown.Item
                      onClick={(e) => {
                        this.childApproval?.current?.deleteRuleHeader("multiple")
                      }}
                    >
                      Delete Inactive Rules
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        <Tabs
          defaultActiveKey={this.state.activeTab}
          onSelect={this.handleTabSelect}
          id="approval-setting"
        >
          <Tab eventKey="general" title="General" className="invoice-approval-setting">
            <div className="section">
              <div className="sec-ttl">Paid M-ACH Transactions</div>
              <div className="controlls">
                <Form className="entity-row">
                  <div className="label-column">
                    <Form.Label className="entity-label">Bypass Approvals</Form.Label>
                    <h6>This enables any paid M-ACH to bypass approvals from the AP Inbox.</h6>
                  </div>
                  <Form.Check
                    type="switch"
                    id="sw_ach-bypass-approval"
                    // label="Include salaried employees in the total hours"
                    checked={this.state.isACHBypassApproval}
                    // disabled={!this.state.isManageSetting}
                    onChange={(event: any) => {
                      this.onChange(event, "isACHBypassApproval");
                    }}
                  />
                </Form>
              </div>
              <div className="controlls">
                <Form className="entity-row">
                  <div className="label-column">
                    <Form.Label className="entity-label">Bypass Payment Confirmation</Form.Label>
                    <h6>This enables any paid M-ACH to bypass payment confirmation from the AP Inbox.</h6>
                  </div>
                  <Form.Check
                    type="switch"
                    id="sw_ach-bypass-payment"
                    // label="Include salaried employees in the total hours"
                    checked={this.state.isACHBypassPayment}
                    // disabled={!this.state.isManageSetting}
                    onChange={(event: any) => {
                      this.onChange(event, "isACHBypassPayment");
                    }}
                  />
                </Form>
              </div>
            </div>
            <div className="section">
              <div className="sec-ttl">Paid Credit Card Transactions</div>
              <div className="controlls">
                <Form className="entity-row">
                  <div className="label-column">
                    <Form.Label className="entity-label">Bypass Approvals</Form.Label>
                    <h6>This enables any paid Credit Card Transaction to bypass approvals from the AP Inbox.</h6>
                  </div>
                  <Form.Check
                    type="switch"
                    id="sw_cc-bypass-approval"
                    // label="Include salaried employees in the total hours"
                    checked={this.state.isCCBypassApproval}
                    // disabled={!this.state.isManageSetting}
                    onChange={(event: any) => {
                      this.onChange(event, "isCCBypassApproval");
                    }}
                  />
                </Form>
              </div>
              <div className="controlls">
                <Form className="entity-row">
                  <div className="label-column">
                    <Form.Label className="entity-label">Bypass Payment Confirmation</Form.Label>
                    <h6>This enables any paid Credit Card Transaction to bypass payment confirmation from the AP Inbox.</h6>
                  </div>
                  <Form.Check
                    type="switch"
                    id="sw_cc-bypass-payment"
                    // label="Include salaried employees in the total hours"
                    checked={this.state.isCCBypassPayment}
                    // disabled={!this.state.isManageSetting}
                    onChange={(event: any) => {
                      this.onChange(event, "isCCBypassPayment");
                    }}
                  />
                </Form>
              </div>
            </div>
          </Tab>
          <Tab eventKey="approvals" title="Invoice Approvals" className="invoice-approval-main">
            <InvoiceApproval
              ref={this.childApproval}
              updateParent={this.updateParent}
            />
          </Tab>
        </Tabs>
      </Container>
    );
  }
}
