import * as React from "react";
import { Table, Form, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import InputRange from "react-input-range";
import { toast } from "react-toastify";
import "react-input-range/lib/css/index.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash, faDivide } from "@fortawesome/free-solid-svg-icons";
import { DeleteIcon } from "./delete";
import { SplitIcon } from "./split";
import { Utils } from "../Utilis";

export class SliderComponent extends React.Component<any, any> {
  private input: any;
  constructor(props: any) {
    super(props);

    this.input = React.createRef();
    // this.input = new Map();

    this.state = {
      header: props.header,
      rows: props.rows,
      isContinousSilder: this.props.isContinousSilder ? true : false,
      disableColumn: this.props.disableColumn,
      parentPage: this.props.parentPage,
      oldMinValue: 0,
      oldMaxValue: 0,
      defaultMinVal: 0,
      defaultMaxVal: 0,
      isNew: false,
      validationClassName: "",
      currentRowIndex: 1,
      currentColIndex: 2,
      tableData: this.props.rows,
      htmltable: "",
    };

    this.getHeader = this.getHeader.bind(this);
    this.getRowsData = this.getRowsData.bind(this);
    this.getKeys = this.getKeys.bind(this);
    this.changeInputRange = this.changeInputRange.bind(this);
    this.changeCompleteInputRange = this.changeCompleteInputRange.bind(this);
  }

  componentDidMount() {
    // this.setState({tableData:this.props.rows})
  }
  changeCompleteInputRange = (value: any) => (event: any) => {
    this.setState({ value });
  };

  changeInputRange = (value: any) => (event: any) => {
    event.preventDefault();
    //this.setState({value})
  };

  getKeys() {
    return this.state.tableData?.length > 0
      ? Object.keys(this.state.tableData[0])
      : [];
  }

  getHeader() {
    var keys = this.getKeys();
    return keys.map((key, index) => {
      return <th key={key}>{key.toUpperCase()}</th>;
    });
  }

  setFoucs(event, rowIndex, index) {
    // event.autofocus=true
    if (rowIndex === 0 && index === 2) {
      //this.input.current.focus();
    }
  }

  handleFocus(event) {
    event.target.select();
  }

  handleClick(e, i) {
    const node = this.input.get(i);
    node.focus();
  }

  RenderRow = (props) => {
    let rowIndex = props.rowIndex;
    let length = this.state.tableData.length;
    return props.keys.map((key, index) => {
      //this.state.disableColumn((number) => number * 2);
      let disablecolumn: any = [];
      let disabled = false;
      disablecolumn = this.state.disableColumn;

      if (this.props.PtoSettingPermission)
        disabled =
          disablecolumn.filter((item) => item === index).length > 0
            ? true
            : false;
      else disabled = true;
      if(key == 'if_record'){
        return
      }
      if (key !== "value") {
        return (
          <td key={key + index}>
            <Form.Group>
              <Form.Control
                type="text"
                key={key + rowIndex}
                id={key + rowIndex}
                disabled={disabled}
                value={this.state.tableData[rowIndex][key]}
                onChange={this.textChange(rowIndex, index, key)}
                onBlur={this.onBlurText(rowIndex, index, key)}
                ref={this.input}
              />
            </Form.Group>
          </td>
        );
      } else {
        return (
          <td
            key={key + index}
            className={this.state.isNew ? "hideSlider" : "showSlider"}
          >
            <InputRange
              key={key + rowIndex}
              maxValue={this.state.tableData[length - 1][key].max}
              minValue={this.state.tableData[0][key].min}
              value={this.state.tableData[rowIndex][key]}
              disabled={!this.props.PtoSettingPermission}
              onChange={this.handleChange(rowIndex, props.data[key])}
              onChangeComplete={this.handleChangeComplete(rowIndex)}
            />
          </td>
        );
      }

      // return <td key={props.data[key]}>{props.data[key]}</td>
    });
  };

  sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  async timeSensativeAction() {
    //must be async func
    //do something here
    await this.sleep(5000); //wait 5 seconds
    //continue on...
  }

  getRowsData() {
    var items = this.state.tableData;
    var keys = this.getKeys();
    return items.map((row, index) => {
      return (
        <tr key={index}>
          <this.RenderRow rowIndex={index} data={row} keys={keys} />
          {this.props.PtoSettingPermission && (
            <td>
              <div
                className={
                  row.value.max - row.value.min >= this.props.splitRange
                    ? "valid action-btn"
                    : "invalid action-btn"
                }
              >
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id={"tooltip-rangeSlider"}>
                      Split the existing range into two ranges.
                    </Tooltip>
                  }
                >
                  <span
                    className="fa-divide"
                    onClick={this.handleSplitRow(index, row)}
                  >
                    <SplitIcon />
                  </span>
                </OverlayTrigger>
                <span
                  className="fa-trash"
                  onClick={this.handleRemoveRow(index, row.value)}
                >
                  <DeleteIcon />
                </span>
              </div>
            </td>
          )}
        </tr>
      );
    });
    // }, 500)
  }

  /**Function to recive updated Props from Parent**/
  // componentWillReceiveProps(nextProps: { tableData: any }) {
  //   this.setState({ tableData: nextProps.tableData }, () => {
  //     this.updateRows();
  //   });
  // }

  /**Function to update rows from Parent**/
  updateRows() {
    const rows = [...this.state.tableData];
    const rowsLength = rows.length;
    this.setState({
      defaultMinVal: rows[0].value.min,
      defaultMaxVal: rows[rowsLength - 1].value.max,
    });
  }

  /**For add row of Range of Statistics **/
  handleAddRow = (event: any) => {
    const rows = [...this.state.tableData];
    const rowsLength = rows.length;
    const maxValue = rows[rowsLength - 1].max;
    const minValue = rows[rowsLength - 1].min;
    if (
      Number(maxValue) !== Number(minValue) &&
      maxValue !== "" &&
      maxValue > 0
    ) {
      let newItem = {} as any;
      // newItem =rows[0];
      var keys = this.getKeys();
      keys.map((key, index) => {
        if (key === "value") {
          if (this.state.isContinousSilder) {
            newItem[key] = { min: Number(maxValue), max: Number(maxValue) };
          } else {
            newItem[key] = {
              min: Number(maxValue) + 1,
              max: Number(maxValue) + 2,
            };
          }
        } else {
          newItem[key] = "0";
        }
      });
      newItem.min = newItem.value.min;
      newItem.max = "";

      rows.push(newItem);

      // for (let j = 0; j < rowsLength; j++) {

      //     rows[j].min =rows[j].value.min;
      //     rows[j].max =rows[j].value.max;

      // }
      this.setState({ isNew: true });
      this.UpdateSilderState(rows, "newRow");
    } else {
      Utils.toastError(
        this.state.header[2] +
          " cannot be blank or equal to " +
          this.state.header[0],
        {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    }
  };

  /**For set input data in slider **/
  onBlurText = (idx: any, colIndex: any, Val: any) => (event: any) => {
    if (Val === "min" || Val === "max") {
      let newtableData: any = [];
      newtableData = Object.assign([], this.state.tableData);

      const rows = [...this.state.rows];
      const  oldMinValue  = rows[idx]?.min;
    const  oldMaxValue  = rows[idx]?.max;
      // const rowsLength = [...this.state.rows].length;

      const rowsLength = newtableData.length;

      var currMinValue: any, currMaxValue: any;
      if (
        Number(newtableData[idx].value.min) ===
        Number(newtableData[idx].value.max)
      ) {
      } else if (event.target.value.trim() === "") {
        event.target.value = "";
      } else if (event.target.value === "NaN") {
        event.target.value = "";
      } else if (event.target.value >= "0") {
        let i = 0,
          j = 0;
        var flag = false;
        if (Val === "min") {
          currMaxValue = Number(newtableData[idx].value.max);
          if (
            Number(event.target.value) <=
            Number(newtableData[idx].value.max) - 1
          ) {
            currMinValue = Number(event.target.value);
          } else {
            currMinValue = Number(newtableData[idx].value.max) - 1;
            newtableData[idx].value = {
              min: currMinValue,
              max: newtableData[idx].value.max,
            };
            //   newtableData[idx] = {
            //     value: { min: currMinValue, max: newtableData[idx].value.max }
            //   };
          }
        } else {
          currMinValue = newtableData[idx].value.min;
          if (event.target.value === "") {
            currMaxValue = null;
          } else if (
            Number(event.target.value) >=
            Number(newtableData[idx].value.min) + 1
          ) {
            currMaxValue = Number(event.target.value);
          } else {
            currMaxValue = Number(newtableData[idx].value.min) + 1;
            newtableData[idx].value = {
              min: newtableData[idx].value.min,
              max: currMaxValue,
            };

            //   newtableData[idx] = {
            //     value: { min: newtableData[idx].value.min, max: currMaxValue },
            //     time: newtableData[idx].time,
            //   };
          }
        }
        if (oldMinValue !== currMinValue) {
          for (i = idx - 1; i >= 0; i--) {
            const previousRowMinValue = Number(newtableData[i].value.min);

            if (this.state.isContinousSilder) {
              if (previousRowMinValue <= currMinValue - 1) {
                newtableData[i].value = {
                  min: newtableData[i].value.min,
                  max: Number(currMinValue),
                };
              } else {
                if (currMinValue <= i + i + 1 && flag === false) {
                  newtableData[idx].value = {
                    min: idx + idx,
                    max: currMaxValue,
                  };
                  currMinValue = idx + idx;
                  newtableData[i].value = {
                    min: currMinValue - 1,
                    max: currMinValue - 1,
                  };
                  flag = true;
                } else {
                  newtableData[i].value = {
                    min: currMinValue - 1,
                    max: currMinValue - 1,
                  };
                }
              }
            } else {
              if (previousRowMinValue <= currMinValue - 2) {
                newtableData[i].value = {
                  min: newtableData[i].value.min,
                  max: Number(currMinValue) - 1,
                };
              } else {
                if (currMinValue <= i + i + 2 && flag === false) {
                  newtableData[idx].value = {
                    min: idx + idx,
                    max: currMaxValue,
                  };
                  currMinValue = idx + idx;
                  newtableData[i].value = {
                    min: currMinValue - 2,
                    max: currMinValue - 1,
                  };

                  flag = true;
                } else {
                  newtableData[i].value = {
                    min: currMinValue - 2,
                    max: currMinValue - 1,
                  };
                }
              }
            }

            currMinValue = Number(newtableData[i].value.min);
          }
        } else if (oldMaxValue !== currMaxValue) {
          for (j = idx + 1; j < rowsLength; j++) {
            const MaxValue = Number(newtableData[rowsLength - 1].value.max);
            const nextRowMaxValue = Number(newtableData[j].value.max);
            if (currMaxValue + 1 < nextRowMaxValue) {
              newtableData[j].value = {
                min: Number(currMaxValue) + 1,
                max: newtableData[j].value.max,
              };
              // newtableData[j] = {
              //   value: { min: Number(currMaxValue) + 1, max: newtableData[j].value.max }
              // };
            } else {
              let minimumrows = 0;
              if (this.state.isContinousSilder) {
                minimumrows = MaxValue - 1 * (rowsLength - j);
              } else {
                minimumrows = MaxValue - 2 * (rowsLength - j);
              }

              if (currMaxValue > minimumrows && flag === false) {
                newtableData[idx].value = {
                  min: Number(currMinValue),
                  max: Number(minimumrows),
                };

                if (this.state.isContinousSilder) {
                  currMaxValue = minimumrows;
                  newtableData[j].value = {
                    min: Number(currMaxValue),
                    max: Number(currMaxValue) + 1,
                  };
                } else {
                  currMaxValue = minimumrows;
                  newtableData[j].value = {
                    min: Number(currMaxValue) + 1,
                    max: Number(currMaxValue) + 2,
                  };
                }

                flag = true;
              } else {
                if (this.state.isContinousSilder) {
                  newtableData[j].value = {
                    min: Number(currMaxValue),
                    max: Number(currMaxValue) + 1,
                  };
                } else {
                  newtableData[j].value = {
                    min: Number(currMaxValue) + 1,
                    max: Number(currMaxValue) + 2,
                  };
                }
              }
            }
            currMaxValue = newtableData[j].value.max;
          }
        }
        if (idx === rowsLength - 1) {
          this.setState({
            defaultMaxVal: newtableData[rowsLength - 1].value.max,
          });
        }

        for (j = 0; j < rowsLength; j++) {
          newtableData[j].min = newtableData[j].value.min;
          newtableData[j].max = newtableData[j].value.max;
        }

        // this.setState({
        //     oldMinValue: newtableData[rowsLength-1].value.min,
        //     oldMaxValue: newtableData[rowsLength-1].value.max,
        //   });
        this.UpdateSilderState(newtableData, "blur");
      } else {
        Utils.toastError("Please check the values and try again", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  /**To genetate Header based on recieved Props **/
  generateHeader = () => {
    let res: any[] = [];
    for (var i = 0; i < this.state.header.length; i++) {
      res.push(<th key={this.state.header[i] + i}>{this.state.header[i]}</th>);
    }
    return res;
  };

  /**For change input value **/
  textChange = (idx: any, colindex: any, value: any) => (event: any) => {
    event.preventDefault();

    // const rows = [...this.state.tableData];
    let inputValue = event.target.value.trim();
    let newtableData: any = [];
    newtableData = Object.assign([], this.state.tableData);
    if (inputValue === "" && value === "max") {
      newtableData[idx].value = {
        min: newtableData[idx].value.min,
        max: newtableData[idx].value.min,
      };
      newtableData[idx].max = inputValue;
    } else if (inputValue !== "" && value === "max") {
      newtableData[idx].value = {
        min: newtableData[idx].value.min,
        max: inputValue,
      };
      newtableData[idx].max = inputValue;
    } else {
      newtableData[idx][value] = inputValue;
    }

    // if (value == "min") {
    //   newtableData[idx].value.min = inputValue
    // }
    // if (value == "max") {
    //   newtableData[idx].value.max = inputValue
    // }

    // newtableData[0].max ='12';

    let isnum: any = true;
    if (inputValue.includes("/^[A-Za-z]+$/")) {
      isnum = false;
    } else if (isNaN(inputValue) === true) {
      isnum = false;
    } else if (isNaN(inputValue) === false && inputValue < 0) {
      isnum = false;
    } else {
      isnum = true;
    }

    this.setState({
      oldMinValue: newtableData[idx].value.min,
      oldMaxValue: newtableData[idx].value.max,
    });

    this.setState({ currentRowIndex: idx + 1, currentColIndex: colindex });

    this.UpdateSilderState(newtableData, "textChange");
  };

  /**For remove row of Range of Statistics **/
  handleRemoveRow = (idx: any, Val: any) => (event: any) => {
    event.preventDefault();
    const rows = [...this.state.tableData];
    var currMinValue: any;
    var currMaxValue: any;
    let validationFlag: any;
    // for (let i = 0; i <= rows.length - 1; i++) {
    //   if (
    //     rows[i].value.min === rows[i].value.max ||
    //     rows[i].value.max === 0
    //    // rows[i].value.max === ""
    //   ) {
    //     validationFlag = true;
    //   } else {
    //     validationFlag = false;
    //   }
    // }
    if (Val.min === Val.max) {
      currMinValue = Val.min;
      currMaxValue = rows[idx - 1].value.max;
    } else {
      currMinValue = Val.min;
      currMaxValue = Val.max;
    }

    if (validationFlag === true) {
      Utils.toastError("User cannot delete a row when max value is blank or zero", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (rows.length === 1) {
      Utils.toastError("Single row can not be deleted", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      if (rows.length === idx + 1) {
        if (!this.state.isContinousSilder) {
          rows[idx - 1].value = {
            min: rows[idx - 1].value.min,
            max: currMaxValue,
          };
          rows[idx - 1].min = rows[idx - 1].value.min;
          rows[idx - 1].max = currMaxValue;
        }

        this.setState({ tableData: rows });
      } else {
        rows[idx + 1].value = {
          min: currMinValue,
          max: rows[idx + 1].value.max,
        };
        rows[idx + 1].min = currMinValue;
        rows[idx + 1].max = rows[idx + 1].value.max;

        this.setState({ tableData: rows });
      }

      rows.splice(idx, 1);
      this.UpdateSilderState(rows, "removeRow");
    }
  };

  /**For Split row of Range of Statistics **/
  handleSplitRow = (idx: any, currRow: any) => (event: any) => {
    event.preventDefault();

    let columnName = this.state.header[2];
    let rows: any[] = [];
    rows = Object.assign([], this.state.tableData);
    // rows = [this.state.tableData];
    var currMinValue = parseInt(currRow.value.min);
    var currMaxValue = currRow.value.max;
    var newCurrMaxValue: number;
    var nextCurrMinValue: any;
    let validationFlag: any;
    for (let i = 0; i <= rows.length - 1; i++) {
      if (
        rows[i].value.min === rows[i].value.max ||
        rows[i].value.max === 0 ||
        rows[i].value.max === ""
      ) {
        validationFlag = true;
      } else {
        validationFlag = false;
      }
    }
    if (validationFlag === true) {
      Utils.toastError(
        "User cannot split a row when" + columnName + "value is blank or zero",
        {
          // // position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    } else if (currMaxValue - currMinValue >= 2) {
      // const item = {
      //   value: { min: currMinValue, max: currMaxValue }
      // };

      let newsplitRow: any;
      newsplitRow = Object.assign({}, currRow);
      rows.splice(idx + 1, 0, newsplitRow);

      // this.setState({tableData:rows }, () => {
      //   //this.props.updateRangeStatisticData(rows);
      // });
      var currRange = currMaxValue - currMinValue;
      if (currRange % 2 === 0) {
        newCurrMaxValue = currMinValue + currRange / 2;
        nextCurrMinValue = newCurrMaxValue + 1;
      } else {
        newCurrMaxValue = currMinValue + (currRange - 1) / 2;
        nextCurrMinValue = newCurrMaxValue + 1;
      }

      let newrows: any[] = [];
      newrows = Object.assign([], rows);
      newrows[idx].value = { min: currMinValue, max: newCurrMaxValue };
      // newrows[idx].value = newCurrMaxValue;

      newrows[idx].min = currMinValue;
      newrows[idx].max = newCurrMaxValue;

      newrows[idx + 1].value = { min: nextCurrMinValue, max: currMaxValue };
      newrows[idx + 1].value.max = currMaxValue;

      newrows[idx + 1].min = nextCurrMinValue;
      newrows[idx + 1].max = currMaxValue;

      // rows[idx].value = { min: currMinValue, max: newCurrMaxValue }
      // rows[idx + 1].value = { min: nextCurrMinValue, max: currMaxValue }

      this.UpdateSilderState(newrows, "splitRow");
    } else {
      Utils.toastError("The range is not large enough to split", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  /**For complete change on slider **/
  handleChangeComplete = (idx: any) => (event: any) => {
    const rows = [...this.state.tableData];
    const  oldMaxValue  = rows[idx].max;
    const  oldMinValue  = rows[idx].min;
    const rowsLength = [...this.state.tableData].length;
    var currMinValue = event.min;
    var currMaxValue = event.max;
    var flag = false;
    let i = 0,
      j = 0;
    if (oldMinValue !== currMinValue) {
      for (i = idx - 1; i >= 0; i--) {
        if (this.state.isContinousSilder) {
          const previousRowMinValue = rows[i].value.min;
          if (previousRowMinValue <= currMinValue - 1) {
            rows[i].value = { min: previousRowMinValue, max: currMinValue };
          } else {
            if (currMinValue <= i + i + 2 && flag === false) {
              rows[idx].value = { min: previousRowMinValue, max: currMinValue };

              currMinValue = idx + idx;
              rows[i].value = { min: currMinValue - 1, max: currMinValue };
              flag = true;
            } else {
              rows[i].value = { min: currMinValue - 1, max: currMinValue };
            }
          }
        } else {
          const previousRowMinValue = rows[i].value.min;
          if (previousRowMinValue <= currMinValue - 2) {
            rows[i].value = { min: previousRowMinValue, max: currMinValue - 1 };
          } else {
            if (currMinValue <= i + i + 2 && flag === false) {
              rows[idx].value = {
                min: previousRowMinValue,
                max: currMinValue - 1,
              };

              currMinValue = idx + idx;
              rows[i].value = { min: currMinValue - 2, max: currMinValue - 1 };
              flag = true;
            } else {
              rows[i].value = { min: currMinValue - 2, max: currMinValue - 1 };
            }
          }
        }

        currMinValue = rows[i].value.min;
      }
    } else if (oldMaxValue !== currMaxValue) {
      flag = false;
      for (j = idx + 1; j < rowsLength; j++) {
        const MaxValue = rows[rowsLength - 1].value.max;
        const nextRowMaxValue = rows[j].value.max;
        if (currMaxValue + 1 < nextRowMaxValue) {
          rows[j].value = { min: currMaxValue + 1, max: nextRowMaxValue };
        } else {
          let minimumrows = MaxValue - 2 * (rowsLength - j);
          if (currMaxValue > minimumrows && flag === false) {
            rows[idx].value = { min: currMinValue, max: minimumrows };

            currMaxValue = minimumrows;
            rows[j].value = { min: currMaxValue + 1, max: currMaxValue + 2 };

            flag = true;
          } else {
            rows[j].value = { min: currMaxValue + 1, max: currMaxValue + 2 };
          }
        }
        currMaxValue = rows[j].value.max;
      }
    }

    let x;
    for (x = 0; x < rows.length; x++) {
      rows[x].min = rows[x].value.min;
      rows[x].max = rows[x].value.max;
    }
    this.UpdateSilderState(rows, "sliderComplete");
  };

  handleSliderChangeComplete = (event: any, idx: any) => {
    const rows = [...this.state.tableData];
    const  oldMinValue  = rows[idx].min;
    const  oldMaxValue  = rows[idx].max;
    const rowsLength = [...this.state.tableData].length;
    var currMinValue = event.min;
    var currMaxValue = event.max;
    var flag = false;
    let i = 0,
      j = 0;
    if (oldMinValue !== currMinValue) {
      for (i = idx - 1; i >= 0; i--) {
        const previousRowMinValue = rows[i].value.min;
        if (previousRowMinValue <= currMinValue - 2) {
          rows[i].value = { min: previousRowMinValue, max: currMinValue - 1 };
        } else {
          if (currMinValue <= i + i + 2 && flag === false) {
            rows[idx].value = {
              min: previousRowMinValue,
              max: currMinValue - 1,
            };

            currMinValue = idx + idx;
            rows[i].value = { min: currMinValue - 2, max: currMinValue - 1 };

            flag = true;
          } else {
            rows[i].value = { min: currMinValue - 2, max: currMinValue - 1 };
          }
        }
        currMinValue = rows[i].value.min;
      }
    } else if (oldMaxValue !== currMaxValue) {
      flag = false;
      for (j = idx + 1; j < rowsLength; j++) {
        const MaxValue = rows[rowsLength - 1].value.max;
        const nextRowMaxValue = rows[j].value.max;
        if (this.state.isContinousSilder) {
          if (currMaxValue + 1 < nextRowMaxValue) {
            rows[j].value = { min: currMaxValue, max: nextRowMaxValue };
          } else {
            let minimumrows = MaxValue - 1 * (rowsLength - j);
            if (currMaxValue > minimumrows && flag === false) {
              rows[idx].value = { min: currMinValue, max: minimumrows };
              currMaxValue = minimumrows;
              rows[j].value = { min: currMaxValue, max: currMaxValue + 1 };
              flag = true;
            } else {
              rows[j].value = { min: currMaxValue, max: currMaxValue + 1 };
            }
          }
        } else {
          if (currMaxValue + 1 < nextRowMaxValue) {
            rows[j].value = { min: currMaxValue + 1, max: nextRowMaxValue };
          } else {
            let minimumrows = MaxValue - 2 * (rowsLength - j);
            if (currMaxValue > minimumrows && flag === false) {
              rows[idx].value = { min: currMinValue, max: minimumrows };
              currMaxValue = minimumrows;
              rows[j].value = { min: currMaxValue + 1, max: currMaxValue + 2 };
              flag = true;
            } else {
              rows[j].value = { min: currMaxValue + 1, max: currMaxValue + 2 };
            }
          }
        }

        currMaxValue = rows[j].value.max;
      }
    }

    let x;
    for (x = 0; x < rows.length; x++) {
      rows[x].min = rows[x].value.min;
      rows[x].max = rows[x].value.max;
    }

    this.UpdateSilderState(rows, "splitChange");
  };

  /**For change slider value of Range of Statistics **/
  handleChange = (idx: any, value: any) => (event: any) => {
    // const rows = this.state.tableData;
    const rows = [...this.state.tableData];
    rows[0].min = 0;
    rows[idx].value = event;

    this.setState({
      oldMinValue: rows[idx].value.min,
      oldMaxValue: rows[idx].value.max,
    });

    this.setState({ tableData: rows });
  };

  UpdateSilderState(tabledata: any, step: string) {
    this.setState(
      {
        tableData: tabledata,
      },
      () => {
        if (step === "textChange") {
          let cell = document.getElementById("sildertable") as any;
          cell.rows[this.state.currentRowIndex].cells[
            this.state.currentColIndex
          ].children[0].children[0].focus();

          // const timer = setTimeout(() => {
          //   this.input.current.focus();

          // },

          // 200);
          // return () => clearTimeout(timer);
        }
      }
    );

    this.props.updateSliderData(tabledata);
  }

  updateChildState(tabledata: any) {
    this.setState({
      tableData: tabledata,
    });
  }

  render() {
    var items = this.state.tableData;
    var keys = this.getKeys();

    return (
      <div className="RangeSlider">
        <div className="slider-table">
          <Form>
            {/* <Form.Group>
              <Form.Control
                type="button"
                onClick={this.handleAddRow}
                className="addRange"
                value="Add Row"
              />
            </Form.Group>  */}
            <Table id="sildertable" hover>
              <thead>
                <tr>{this.generateHeader()}</tr>
              </thead>
              <tbody>
                {this.getRowsData()}
                {/* <this.RenderRow  rowIndex={index} data={row} keys={keys}/> */}
              </tbody>
            </Table>
            <Form.Group>
              {this.props.PtoSettingPermission && (
                 <Button
                 className="addRange btn-ghost"
                 onClick={this.handleAddRow}
               >
                 {this.props.buttonName}
               </Button>
              // <Form.Control
              //   type="button"
              //   onClick={this.handleAddRow}
              //   className="addRange"
              //   value={this.props.buttonName}
              // />
              )}

              <span className="infoText">{this.props.description}</span>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }
}
