import Instense from "./Axios-config";
import { ILaborSaveAsDefaultDto } from "./../Contracts/ILaborSaveAsDefaultDto";
import { ISaveForceApprovalDto } from "./../Contracts/ILaborSaveAsDefaultDto";
import { ISmileIDPermission } from "../Contracts/ILaborDayApprove";
import { ILaborPerformaceRequest, ILaborPerformaceBudgetRequest } from './../Contracts/ILaborPerformance';
import { IApprovalChangeLogRequest, IApprovalChangeLogResponse } from './../Contracts/IApprovalChangeLog';

export class LaborPerformance {
  public static laborPerformancePositionDetail = async (
    hotelID: Number,
    positionID: Number,
    date: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.positionID = positionID;
    request.date = date;
    const url = `/LaborActual/LaborPerformancePositionDetail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static laborPerformanceActualDetail = async (
    hotelID: Number,
    positionID: Number,
    date: any,
    userName: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.positionID = positionID;
    request.date = date;
    request.userName = userName;
    const url = `/LaborActual/LaborPerformanceActualDetail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static laborPerformanceScheduleDetail = async (
    hotelID: Number,
    positionID: Number,
    date: any,
    userName: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.positionID = positionID;
    request.date = date;
    request.userName = userName;
    const url = `/LaborActual/LaborPerformanceScheduleDetail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static laborPerformanceEmployee = async (
    hotelID: Number,
    positionID: Number,
    date: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.positionID = positionID;
    request.date = date;
    const url = `/LaborActual/LaborPerformanceEmployee`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static getCalendarMaxMinDate = async (
    hotelID: Number
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    const url = `/LaborActual/PerformanceforecastDate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static SaveLaborActual = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborActual/SaveLaborActual`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static SaveLaborNoShowComments = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborActual/SaveLaborNoShowComment`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

 

  public static CheckNoShowComment = async (
    employeeName:any,
    date: any,
    hotelID: Number,
    
  ): Promise<any | null> => {
    //debugger;
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.selectDate = date;
    request.employeeName = employeeName;
    const url = `/LaborActual/CheckNoShowComment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };




  public static SaveLaborAssociateShift = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborActual/SaveLaborAssociateShift`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };
  public static AddManualBreak = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborActual/AddManualBreak`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static ShiftPositionMove = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborActual/ShiftPositionMove`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static MoveActualShift = async (request: any): Promise<any | null> => {
    const url = `/LaborActual/MoveActualShift`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static LaborPerformanceDeptSummary = async (
    hotelID: Number,
    selectedDate: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.selectedDate = selectedDate;
    const url = `/LaborActual/LaborPerformanceDeptSummary`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborPerformanceDeptPositionSummary = async (
    hotelID: Number,
    departmentID: Number,
    selectedDate: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.selectedDate = selectedDate;
    request.departmentID = departmentID;
    const url = `/LaborActual/LaborDepartmentSummaryDeatils`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static getPayPeriodDates = async (
    hotelID: Number,
    selectedDate: any,
    PayType: string
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.Date = selectedDate;
    request.PayType = PayType;
    const url = `/LaborActual/GetPayPeriodDates`;

    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetLaborEmpSmileIDByPosition = async (
    hotelID: Number,
    positionID: Number,
    date: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.positionID = positionID;
    request.date = date;
    const url = `/LaborActual/GetLaborEmpSmileIDByPosition`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static getDefaultViewProperties = async (
    pageName: string
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantID = storage === null ? 0 : (storage.tenantID as any);
    const userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.UserUniqueID = userUniqueID;
    request.pageName = pageName;
    const url = `/LaborSchedule/GetLaborSaveAsDefaultView`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response?.data?.result as any;
      return result;
    });
  };

  public static saveDefaultViewProperties = async (
    laborSaveAsDefaultdtos: ILaborSaveAsDefaultDto[]
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantID = storage === null ? 0 : (storage.tenantID as any);
    const userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    laborSaveAsDefaultdtos = laborSaveAsDefaultdtos.map((item) => {
      item.tenantID = tenantID;
      item.UserUniqueID = userUniqueID;
      if (JSON.parse(localStorage.storage).currentUtcTime !== "") {
        item.ModifyDate = new Date(JSON.parse(localStorage.storage).currentUtcTime);
      } else {
        item.ModifyDate = new Date();
      }

      return item;
    });
    const url = `/LaborSchedule/LaborSaveAsDefaultView`;
    const response = await Instense.post(url, laborSaveAsDefaultdtos);
    let result = response.data as any;
    return result;
  };

  public static GetLaborApprovalStatusWithDates = async (
    hotelId: Number,
    departmentId: Number,
    startDate: any,
    endDate: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantId = tenantID;
    request.hotelId = hotelId;
    request.departmentId = departmentId;
    request.startDate = startDate;
    request.endDate = endDate;
    const url = `/LaborActual/GetLaborApprovalStatusWithDates`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static hasLaborApprovalStatusPendingForWeek = async (
    hotelId: Number,
    startDate: any,
    endDate: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantId = tenantID;
    request.hotelId = hotelId;
    request.startDate = startDate;
    request.endDate = endDate;
    request.userUniqueno = +storage?.user_UniqueID;
    const url = `/LaborActual/HasLaborApprovalStatusPendingForWeek`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static saveForceApproval = async (
    approvalContext: ISaveForceApprovalDto
  ): Promise<any | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantID = storage === null ? 0 : (storage.tenantID as any);
    //const userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    approvalContext.TenantID = tenantID;

    const url = `/LaborTimeSheet/SaveForceApproval`;
    const response = await Instense.post(url, approvalContext);
    let result = response.data as any;
    return result;
  };

  public static GetSmileIDPermissionData = async (
    request: ISmileIDPermission
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let rolId = storage === null ? 0 : (storage.rolId as any);
    request.TenantID = tenantID;
    request.RoleID = rolId;

    const url = `/RolePermission/GetPageSubPermissionDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;

      return result;
    });
  };

  public static SaveLaborPTOUTO = async (
    request: any[]
  ): Promise<any | null> => {
    const url = `/LaborPTO/SaveAndApproveLaborPTO`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };

  public static DeleteSchedulePtoUto = async (
    req: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    req.tenantID = tenantID;
    const url = `/LaborPTO/DeleteSchedulePtoUto`;
    return Instense.delete(url, { params: req }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static DeleteManualActualShift = async (
    req: any
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    req.tenantID = tenantID;
    const url = `/LaborActual/DeleteManualActualShift`;
    return Instense.delete(url, { params: req }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };


  public static PerformanceSlideOutPermission = async (request: ISmileIDPermission)
    : Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/LaborActual/PerformanceSlideOutPermission`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
  public static DeleteManualBreak = async (
    req: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    req.tenantID = tenantID;
    const url = `/LaborPTO/DeleteManualBreak`;
    return Instense.delete(url, { params: req }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static LaborPerformanceDepartmentViewMain = async (
    hotelID: Number, selectedDate: any, paytype: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.selectedDate = selectedDate;
    request.payType = paytype;
    const url = `/LaborActual/LaborPerformanceDepartmentViewMain`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static LaborPerformanceEmployeeViewMain = async (
    hotelID: Number, selectedDate: any, paytype: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let request = {} as any;
    request.tenantID = tenantID;
    request.hotelID = hotelID;
    request.selectedDate = selectedDate;
    request.payType = paytype;
    const url = `/LaborActual/LaborPerformanceEmployeeViewMain`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static GetLaborPerformance = async (request: ILaborPerformaceRequest): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/LaborActual/GetLaborPerformance`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };


  public static GetLaborPerformanceBudget = async (request: ILaborPerformaceBudgetRequest): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantid = tenantID;
    const url = `/LaborActual/GetDeptPositionBudget`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
  
  public static GetApprovalChangeLogData = async (
    request: IApprovalChangeLogRequest
): Promise<IApprovalChangeLogResponse[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;
    const url = `/LaborActual/GetApprovalChangeLog`;
    return Instense.post(url, request).then((response) => {
        return response.data?.result as IApprovalChangeLogResponse[];
    });
};

public static DownloadChangeLog = async (
    request: IApprovalChangeLogRequest, fileName: string
): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;


    const url = `/LaborActual/DownloadChangeLog`;
    return Instense.post(url, request, { responseType: "blob" }).then(

        (response: any) => {
            
            let csvURL = window.URL.createObjectURL(response.data);
            let tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", fileName + ".xlsx");
            tempLink.click();
        }
    );
};

public static GetCurrentDateTimeByHid = async (
  hotelID: any, 
): Promise<any | null> => {
  // Need to put this and all future URLs into a config js file
  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let request = {} as any;
  request.tenantID = tenantID;
  request.hid = hotelID;  
  const url = `/LaborActual/Get_CurrentDateTimeByHid`;

  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};




}
