import * as React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
export class MultiSelectDocType extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedItem: [],
            documentTypes: [
                { label: "Missing Workmans Comp", value: "Missing Workmans Comp", isChecked: false },
                { label: "Missing General Liability", value: "Missing General Liability", isChecked: false },
                { label: "Missing W9", value: "Missing W9", isChecked: false },
                { label: "Missing Other Tax Form", value: "Missing Other Tax Form", isChecked: false },
                { label: "Missing Other Insurance Certificate", value: "Missing Other Insurance Certificate", isChecked: false },
            ],
            documentTypesCopy: [
                { label: "Missing Workmans Comp", value: "Missing Workmans Comp", isChecked: false },
                { label: "Missing General Liability", value: "Missing General Liability", isChecked: false },
                { label: "Missing W9", value: "Missing W9", isChecked: false },
                { label: "Missing Other Tax Form", value: "Missing Other Tax Form", isChecked: false },
                { label: "Missing Other Insurance Certificate", value: "Missing Other Insurance Certificate", isChecked: false },
            ],
            isbulkSelect: false,
        };
    }

    getDefaultSelectedDocsType = () => {
        // debugger;
        const { defaultValue } = this.props;
        let selectedItem = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedItem)));
        let documentTypes = Object.assign([], JSON.parse(JSON.stringify(this.state.documentTypes)));
        if (defaultValue !== "" && defaultValue !== undefined && defaultValue !== null) {
            let tempArray = defaultValue.split(",");
            for(let i = 0; i < tempArray.length; i++ ) {
                if (documentTypes.filter((x) => x.value === tempArray[i]).length > 0) {
                    const index = documentTypes.findIndex((x) => x.value === tempArray[i]);
                    documentTypes[index].isChecked = true;
                    selectedItem.push(documentTypes[index]);
                }
            }
            this.setState({selectedItem, documentTypes, isbulkSelect: selectedItem.length === this.state.documentTypesCopy.length });
        }
    }

    componentDidUpdate = (prevprops, prevState) => {
        // debugger;
        const { defaultValue } = this.props;
        if((prevprops.defaultValue !== defaultValue) && defaultValue === "") {
            this.setState({ documentTypes: this.state.documentTypesCopy, selectedItem: [], isbulkSelect: false})
        }
    }

    componentDidMount = () => {
        // debugger;
        this.getDefaultSelectedDocsType();
    };

    handleCheckItem = () => {
        // debugger;
        const item = this.state.selectedItem;
        let selecteditem: any = [];
        item.forEach((o: any) => selecteditem.push(o.value));
        this.props?.handleMultiDocType(selecteditem);
    }

    handleDropdownChange = (event: any, item: any) => {
        // debugger;
        let selectedItem = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedItem)));
        let documentTypes = Object.assign([], JSON.parse(JSON.stringify(this.state.documentTypes)));
        let checked = event.target.checked;
        documentTypes.forEach((o: any) => {
            if (o.value === item.value) {
                o.isChecked = checked;
            }
        });
        selectedItem = documentTypes.filter((o: any) => o.isChecked);
        let isEqualLen = selectedItem.length === this.state.documentTypesCopy.length;
        this.setState({ documentTypes, isbulkSelect: isEqualLen, selectedItem }, () => {
            this.handleCheckItem();
        });
    };

    handleSelectAllDocs = () => {
        // debugger;
        let { isbulkSelect } = this.state;
        let selectedItem = Object.assign([], JSON.parse(JSON.stringify(this.state.selectedItem)));
        let documentTypes = Object.assign([], JSON.parse(JSON.stringify(this.state.documentTypes)));
        let totalLength: any[] = [];
        documentTypes.forEach((o: any) => {
            o.isChecked = !isbulkSelect;
            totalLength.push(o);
        })
        selectedItem = documentTypes.filter((o: any) => o.isChecked);
        let isEqualLen = selectedItem.length === this.state.documentTypesCopy.length;
        this.setState({ documentTypes, isbulkSelect: isEqualLen, selectedItem }, () => {
            this.handleCheckItem();
        });
    }

    render() {
        const { selectedItem, documentTypes, isbulkSelect } = this.state;
        return (
            <Dropdown className="single-select">
                <Dropdown.Toggle id="Modal-DocType" className="txtDocTypebtn">
                    {selectedItem.length > 0 ? (<>
                        {selectedItem.length === 1 ? (
                            <>
                                <div className="txtDocTypeItemList">
                                    {selectedItem.map((item: any, index: any) => (
                                        <div className="selectedItems">
                                            <div className="itemLabel"><EllipsisWithTooltip placement="bottom">{item.label}</EllipsisWithTooltip></div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (<div className="txtDocTypeItemList">{selectedItem.length} Document Types Selected</div>)}
                    </>
                    ) : ("Select Document Type")}
                </Dropdown.Toggle>
                <Dropdown.Menu className="txtDocTypeMenu">
                    <div className="list-area">
                        {documentTypes.map((item: any, idx: any) => (
                            <Form.Check
                                type="checkbox"
                                key={"docType" + idx}
                                id={"docType" + idx}
                                label={<EllipsisWithTooltip placement="bottom">{item.label}</EllipsisWithTooltip>}
                                checked={item.isChecked}
                                onChange={(e: any) => this.handleDropdownChange(e, item)}
                            />
                        ))}
                    </div>
                    <div className="footer">
                        <Button className="wht-bg txt-green" onClick={this.handleSelectAllDocs}>{isbulkSelect ? "Clear All" : "Select All"}</Button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}