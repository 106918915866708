import React from "react";
import { Dropdown, Modal, Button, Form } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import Dropzone from 'react-dropzone';
import { Utils } from "../../../Common/Utilis";
import { ReconcileService as reconcileService} from "../../../Common/Services/ReconcileService";
import { Register as registersevice } from "../../../Common/Services/Register";
import { ToastContainer, toast } from "react-toastify";
import { resolve, reject } from "q";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PendingReconcile } from "./PendingReconcile";
import _ from "lodash";
import { faPlus, faMinus, faPaperclip, faFile, faFileImage, faFileExcel, faFilePdf, faFileCsv, faFileWord } from "@fortawesome/free-solid-svg-icons";
import "./reconcile.scss";


export class ReconcileSummary extends React.Component<any, any> {
private prSelector: any;
  constructor(props: any) {
    super(props);
		this.prSelector = React.createRef();
    this.state = {
			PendingReconcileModal: false,
			dintinctAccTypeArr: [],
			dintinctEhidArr: [],
			depositDataArr: [],
			ehidColumnArr: [],
			depositData: [],
			withdrawalData: [],
			reconcileList: [
					{ value: "Visa", eventKey: "Visa", isChecked: false },
					{ value: "Amex", eventKey: "Amex", isChecked: false },
					{ value: "Wire Transfer", eventKey: "Wire Transfer", isChecked: false },
					{ value: "Other Deposits", eventKey: "Other Deposits", isChecked: false },
			],        
			files: [],
			moreActionFileUpload: [
					{ name: "Delete", eventKey: "delete" },
					{ name: "Download", eventKey: "download" }
			],
			pendingDepositBal: 0,
			pendingWithdrawalBal: 0,
			accountBal: 0,
			statementEndBal: 0,
			status: "",
			accountNames: [],
			accountNamelist:[],
			accountName: "",
			selectedOprId: this.props.accDetails.oprID,
			accPerList: [],
			accPerDisplay: this.props?.accPer !== "" ? this.props?.accPer : "",
			accPerReq: "",
    }; 
  }
  componentDidMount() {
		let request: any = {};
		request.hotelId = this.props.hotelId;
    request.OprID = this.state.selectedOprId;
    request.AccPeriod = this.state.accPerReq;

		// let request1: any = {};
		// request1.hotelId = this.props.hotelId;
    // request1.OprID = this.state.selectedOprId;

		// this.getReconcilePeriod(request1);
		this.getRegisterNames(this.props.accDetails);
		// this.getReconcileSummary(request);
		// this.getPendingReconcileDeposit(request);
		// this.getPendingReconcileWithdrawal(request);
		// this.getReconcileAccountBalance(request);
		// this.getDepositDetails(request);
		// this.getWithdrawalDetails(request);
		

		// this.getPendingDeposit();
  }
  onFileChange = (event: any, mode) => {
   
    };
  onDrop = (files) => {
        this.setState({files})
    };

  handleCloseModal() {
    this.props.handleCloseModal(false);
  }

	getRegisterNames = (registerRequest: any) => {
		// let regularlist: any = [];
		// let splitlist: any = [];
		// let selectedaccount = '';
		// let selectedStatus = '';
		let accountName = "";
		registersevice.RegisterAccount(registerRequest)
				.then(async (result: any[] | null) => {
					// debugger;
						if (result !== null) {
								let sharedHIDs: any[] = [];
								let accountNames: any[] = [];

								result.sort((a, b) => a.status !== b.status ? a.status < b.status ? -1 : 1 : 0);//_.sortBy(result, "status");

								result.forEach(element => {

										let hidCode: any = [];
										let acctName: any = {}
										let oprID = element.oprID;
										let name = element.name;
										acctName.id = oprID;
										acctName.sort = element.orderBy
										acctName.name = name;
										acctName.status = element.status;
										accountNames.push(acctName);

										if (Number(this.state.selectedOprId) === Number(oprID)) {
											accountName = name;
										}
								});
								accountNames = _.sortBy(accountNames, "sort");
								this.setState({ accountNamelist: result, accountNames: accountNames, accountName }, () =>{
									let request1: any = {};
									request1.hotelId = this.props.hotelId;
									request1.OprID = this.state.selectedOprId;
									this.getReconcilePeriod(request1);
								})
						}
						resolve();
				})
				.catch((error) => {
						reject();

				});
	};

	getReconcilePeriod = (request) => {
		reconcileService.GetReconcilePeriod(request)
      .then(async (result: any | null) => {
				// debugger;
        if (result != null) {
          this.setState({ accPerList: result }, ()=> {
						// debugger;
						if (this.state.accPerDisplay === "" || this.state.accPerDisplay === undefined) {
							let accPerDisplay = this.state.accPerList[0].defaultAccper;
							let dtArr = accPerDisplay.toString().trim().split("/");
							let accPerReq = dtArr[0]+"/01/"+dtArr[1];
							this.setState({ accPerDisplay, accPerReq },() => {
								this.onLoadData();
							});
						}
						else {
							let accPerReq = this.state.accPerList.filter((accPer) => accPer.displayAccper == this.state.accPerDisplay)[0]?.accper;
        			this.setState({ accPerReq },() => {
								this.onLoadData();
							});
						}
					});
        }
        else {
          // this.setState({ reconsiletData: this.state.blankData });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
	}

	onLoadData = () => {
		let request: any = {};
		request.hotelId = this.props.hotelId;
    request.OprID = this.state.selectedOprId;
    request.AccPeriod = this.state.accPerReq;

		this.getReconcileSummary(request);
		this.getPendingReconcileDeposit(request);
		this.getPendingReconcileWithdrawal(request);
		this.getReconcileAccountBalance(request);
		this.getDepositDetails(request);
		this.getWithdrawalDetails(request);
	}

	getPendingReconcileDeposit = (request) => {
		reconcileService.GetPendingReconcileDeposit(request)
      .then(async (result: any | null) => {
				// debugger;
        if (result != null) {
          this.setState({ pendingDepositBal: result.depositPenndingTotal });
        }
        else {
          // this.setState({ reconsiletData: this.state.blankData });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
	}

	getPendingReconcileWithdrawal = (request) => {
		reconcileService.GetPendingReconcileWithdrawal(request)
      .then(async (result: any | null) => {
				// debugger;
        if (result != null) {
          this.setState({ pendingWithdrawalBal: result.withdrawalTotal });
        }
        else {
          // this.setState({ reconsiletData: this.state.blankData });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
	}

	getReconcileAccountBalance = (request) => {
		reconcileService.GetReconcileAccountBalance(request)
      .then(async (result: any | null) => {
				// debugger;
        if (result != null) {
          this.setState({ accountBal: result.accountBal });
        }
        else {
          // this.setState({ reconsiletData: this.state.blankData });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
	}

	getDepositDetails = (request) => {
		reconcileService.GetDepositDetails(request)
      .then(async (result: any | null) => {
				//debugger;
        if (result != null) {
					let dintinctAccTypeArr = result.map(data => data.type)
																		.filter((value, index, self) => self.indexOf(value) === index);
				
					let dintinctEhidArr = result.filter(x => x.hType !== 1).map(data => data.lettercode)
																	.filter((value, index, self) => self.indexOf(value) === index);
				
					let depositDataArr: any = [];
					dintinctAccTypeArr.map((accType,index) => {
						let depositDataObj = {};
						depositDataObj["id"] = index;
						depositDataObj["accType"] = accType;
						dintinctEhidArr.map((ehid) => {
							depositDataObj["value_" + ehid] = result.filter((data) => data.type === accType && data.lettercode === ehid)[0]?.typeStatus;
						});
						depositDataObj["total"] = result.filter((data) => data.type === accType)[0].totalAmount;
						depositDataObj["overDue"] = result.filter((data) => data.type === accType)[0].overDue;
						depositDataArr.push(depositDataObj);
					});
					
					let ehidColumnArr: any = [];
					dintinctEhidArr.map((ehid) => {
						let ehidColumnObj ={};
							ehidColumnObj["dataField"] = "value_" + ehid;
							ehidColumnObj["text"] = ehid;
							ehidColumnObj["footer"] = '';
							ehidColumnObj["formatter"] = (cell: any, row: any, rowIndex: any) => {
								let rowData = row;
								return (
									row["value_" + ehid] != undefined ?
									<Button variant="primary" type="button" className="btn-link" onClick={(rowData) => this.depositTableRowBtnClick(rowData,cell)} >{row["value_" + ehid]}</Button> : ""
								)
							}
							ehidColumnArr.push(ehidColumnObj);
					});
				
					this.setState({depositDataArr, ehidColumnArr, depositData: result});
        }
        else {
          // this.setState({ reconsiletData: this.state.blankData });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
	}

	getWithdrawalDetails =  (request) => {
		reconcileService.GetWithdrawalDetails(request)
      .then(async (result: any | null) => {
				// debugger;
        if (result != null) {
          this.setState({ withdrawalData: result, });
        }
        else {
          // this.setState({ reconsiletData: this.state.blankData });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
	}

	getReconcileSummary = (request) => {
		reconcileService.GetReconcileSummary(request)
      .then(async (result: any | null) => {
				// debugger;
        if (result != null) {
          this.setState({ statementEndBal: result.amount, status: result.status, });
        }
        else {
          this.setState({ statementEndBal: 0, status: "Unreconciled", });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(`Server Error: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
	}

	// getPendingDeposit = () => {
	// 	let pendingData = [
	// 		{ id: 1, accType: "Visa", ehid: "CMC", total: 100, value: "Start" },
	// 		// { id: 2, accType: "Wire Transfer", ehid: "CMC", total: 100, value: "Start" },
	// 		{ id: 3, accType: "Visa", ehid: "TPWL", total: 100, value: "Start" },
	// 		{ id: 4, accType: "Wire Transfer", ehid: "TPWL", total: 100, value: "Start" },
	// 		{ id: 5, accType: "Visa", ehid: "CMC2", total: 100, value: "Start" },
	// 		{ id: 6, accType: "Wire Transfer", ehid: "CMC2", total: 100, value: "Start" },
	// 		{ id: 7, accType: "Other", ehid: "TPWL", total: 100, value: "Start" },
	// 		{ id: 8, accType: "Other", ehid: "CMC", total: 100, value: "Start" },
	// 		{ id: 9, accType: "Other", ehid: "CMC2", total: 100, value: "Start" },
	// 		// { id: 10, accType: "Wire Transfer", ehid: "TPWL", total: 100, value: "Start" },
	// ];

	// let dintinctAccTypeArr = pendingData.map(data => data.accType)
  // 													.filter((value, index, self) => self.indexOf(value) === index);

	// let dintinctEhidArr = pendingData.map(data => data.ehid)
  // 												.filter((value, index, self) => self.indexOf(value) === index);

	// 	// alert(dintinctAccTypeArr);
	// 	// alert(dintinctEhidArr);

	// let depositDataArr: any = [];
	// dintinctAccTypeArr.map((accType,index) => {
	// 	let depositDataObj = {};
	// 	depositDataObj["id"] = index;
	// 	depositDataObj["accType"] = accType;
	// 	dintinctEhidArr.map((ehid) => {
	// 		depositDataObj["value_" + ehid] = pendingData.filter((data) => data.accType === accType && data.ehid === ehid)[0]?.value;
	// 	});
	// 	depositDataObj["total"] = pendingData.filter((data) => data.accType === accType)[0].total;
	// 	depositDataArr.push(depositDataObj);
	// });
	// //alert(JSON.stringify(depositDataArr));
	
	// let ehidColumnArr: any = [];
	// dintinctEhidArr.map((ehid) => {
	// 	let ehidColumnObj ={};
	// 		ehidColumnObj["dataField"]= "value_" + ehid;
	// 		ehidColumnObj["text"]= ehid;
	// 		ehidColumnObj["footer"]= '';
	// 		ehidColumnObj["formatter"]= (cell: any, row: any, rowIndex: any) => {
	// 			return (
	// 				row["value_" + ehid] != undefined ?
	// 				<Button variant="primary" type="button" className="btn-link">{row["value_" + ehid]}</Button> : ""
	// 			)
	// 		}
	// 		ehidColumnArr.push(ehidColumnObj);
	// });

	// this.setState({depositDataArr, ehidColumnArr});

	// }
    getDropDownHomeIcon() {
        return (
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.466553 4.31579V7.19369H1.22661V11.5087H0.466553V13.6666H1.22661H3.50677H5.02688H7.30704H8.82715H11.1073H12.6274L14.9076 13.6674V13.6666H15.6676V11.5087H14.9076V7.19369H15.6676V4.31579L8.06709 1.90735e-05L0.466553 4.31579ZM3.50677 11.5087V7.19369H5.02688V11.5087H3.50677ZM7.30704 11.5087V7.19369H8.82715V11.5087H7.30704ZM12.6274 11.5087H11.1073V7.19369H12.6274V11.5087ZM9.5872 4.31579C9.5872 5.10989 8.90619 5.75438 8.06709 5.75438C7.228 5.75438 6.54699 5.10989 6.54699 4.31579C6.54699 3.52169 7.228 2.8772 8.06709 2.8772C8.90619 2.8772 9.5872 3.52169 9.5872 4.31579Z" fill="white"/>
        </svg>
        );
    }
    getDropDownArrowIcon() {
        return (
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.28122e-05 1.19715C3.28122e-05 0.889454 0.253262 0.653898 0.543288 0.653898C0.688297 0.653898 0.833313 0.708365 0.923855 0.816593L4.0023 4.02162L7.08074 0.816593C7.2979 0.599435 7.6424 0.599435 7.84116 0.816593C8.04064 1.01608 8.05831 1.34145 7.85955 1.55933L4.40046 5.18103C4.20098 5.39819 3.85721 5.39819 3.64005 5.19943L3.62166 5.18103L0.145009 1.55933C0.054467 1.46879 0 1.34217 0 1.19716L3.28122e-05 1.19715Z" fill="#6A6E73"/>
            </svg>
        );
    }    
    handleEhidSelect = (eventKey: any) => {
			// debugger;
				let accountName = this.state.accountNames.filter((account) => account.id == eventKey)[0].name;
        this.setState({accountName, selectedOprId: eventKey},() => {
					this.onLoadData();
				});
    }
    handlePeriodSelect = (eventKey: any) => {
			// debugger;
			let accPerReq = this.state.accPerList.filter((accPer) => accPer.displayAccper == eventKey)[0].accper;
        this.setState({accPerDisplay: eventKey, accPerReq},() => {
					this.onLoadData();
				});
    }
    handleSelectFileUpload = (eventKey: any, file, index) => {
        
    };

		handlePendingModal = (event) => {
			this.setState({ PendingReconcileModal: event});
		}

		depositTableRowBtnClick = (row,cell) => {
			debugger;

			alert(`OprID:- ${this.state.selectedOprId}, Account:- ${this.state.accountName}, HID:- ${cell}, AccType:- ${row.accType}`);
		}
    
  render() {
		let subTotal = this.state.statementEndBal + this.state.pendingDepositBal;
		let total = subTotal - this.state.pendingWithdrawalBal;
		let variance = total - this.state.accountBal

    const files = this.state.files.map((file, index) => (
        <li key={file.name}>
            <div className="d-flex align-items-center justify-content-start">
                <div className="icon">
                    {(file?.type)?.split('/')[0] === 'image' && (
                        <FontAwesomeIcon icon={faFileImage} />
                    )}
                    {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'pdf' && (
                        <FontAwesomeIcon icon={faFilePdf}/>
                    )}
                    {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'xlsx' && (
                        <FontAwesomeIcon icon={faFileExcel} />
                    )}
                    {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'csv' && (
                        <FontAwesomeIcon icon={faFileCsv} />
                    )}
                    {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'word' && (
                        <FontAwesomeIcon icon={faFileWord}/>
                    )}
                    {(((file?.type)?.split('/')[0] !== 'image') &&
                        ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'pdf') &&
                        ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'xlsx')
                        && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'csv')
                        && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'word'))
                        && (
                            <FontAwesomeIcon icon={faFile} />
                        )}
                </div>
                <div className="file-info d-flex align-items-center">
                    <div className="name-loader d-flex align-items-center">
                        <div className="name">
                            <EllipsisWithTooltip placement="bottom">
                                <div className="full-name" >{file.name}</div>
                            </EllipsisWithTooltip>
                            <div className="details">{file.createDate} - {file.pageno} Page</div>
                        </div>
                    </div>
                    <div className="formate-percentage">
                        {/* <Spinner animation="border" variant="success" size="sm" /> */}
                        <div className="formate d-flex justify-content-center align-items-center">
                            {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length))}
                        </div>
                        {/* <div className="percentage d-flex justify-content-center align-items-center">45%</div> */}
                    </div>
                </div>
                <div className="action">                    
                    <Dropdown className="more-action" alignRight onSelect={(event: any) => this.handleSelectFileUpload(event, file, index)}>
                        <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.state.moreActionFileUpload.map(
                                (item: any, idx: any) => (
                                    <Dropdown.Item                                        
                                        eventKey={this.state.moreActionFileUpload[idx].eventKey}
                                        key={idx}
                                    >
                                        {this.state.moreActionFileUpload[idx].name}
                                    </Dropdown.Item>
                                )
                            )}
                        </Dropdown.Menu>
                    </Dropdown>                    
                </div>
            </div>
        </li>
      ));
    const dropDownHomeIcon = this.getDropDownHomeIcon();
    const dropDownArrowIcon = this.getDropDownArrowIcon();
    const depositsColumns = [
        {
            dataField: "accType",
            text: "Account Type",
            footer: 'Totals',
						formatter : (cell: any, row: any, rowIndex: any) => {
							return (
								<EllipsisWithTooltip placement="bottom">{row.accType}</EllipsisWithTooltip>
							)
						}
        },
				...this.state.ehidColumnArr,
        {
            dataField: "total",
            text: "Total",
            footer: (columnData) => {
							let sum = columnData.reduce((acc, item) => acc + item, 0);
							return (
								<EllipsisWithTooltip placement="bottom">{Utils.currencyFormat(sum)}</EllipsisWithTooltip>
							)
						},
						formatter : (cell: any, row: any, rowIndex: any) => {
							return (
								<EllipsisWithTooltip placement="bottom">{Utils.currencyFormat(row.total)}</EllipsisWithTooltip>
							)
						}
        }
    ];
		const withdrawalColumns = [
			{
					dataField: "type",
					text: "Account Type",
					footer: 'Totals',
					formatter : (cell: any, row: any, rowIndex: any) => {
						return (
							<EllipsisWithTooltip placement="bottom">{row.type}</EllipsisWithTooltip>
						)
					}
			},
			{
			    dataField: "typeStatus",
			    text: "",
			    footer: '',
					formatter : (cell: any, row: any, rowIndex: any) => {
						return (
							<Button variant="primary" type="button" className="btn-link">{row.typeStatus}</Button>
						)
					}
			},
			{
					dataField: "amount",
					text: "Total",
					footer: (columnData) => {
						let sum = columnData.reduce((acc, item) => acc + item, 0);
						return (
							<EllipsisWithTooltip placement="bottom">{Utils.currencyFormat(sum)}</EllipsisWithTooltip>
						)
					},
					formatter : (cell: any, row: any, rowIndex: any) => {
						return (
							<EllipsisWithTooltip placement="bottom">{Utils.currencyFormat(row.amount)}</EllipsisWithTooltip>
						)
					}
			}
	];

    return (
			<>
				<Modal className="reconciliation-modal" show={true} fullscreen={true}>      
            <Modal.Header className="d-flex align-items-center">
                <Modal.Title className="page-title mr-auto">
                    <div className="tag-name">Reconcile Account</div>
                    <div className="ac-name">BBS2 - Branch Banking and Trust Company</div>                    
                </Modal.Title>
                <div className="action">
                    <div className="d-flex justify-content-end">
											{this.state.accountNames.length > 0 &&
                        <Dropdown className="ehid-selector" alignRight onSelect={(event: any) => this.handleEhidSelect(event)}>
                            <Dropdown.Toggle id="dropdown-ehid">
                                <div className="d-flex align-items-center">
                                    <div className="home-icon">{dropDownHomeIcon}</div>
                                    <div className="ehid-name"><EllipsisWithTooltip placement="bottom">{this.state.accountName}</EllipsisWithTooltip></div>
                                    <div className="arrow-icon">{dropDownArrowIcon}</div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.state.accountNames.map(
                                    (item: any, idx: any) => (
																			<Dropdown.Item eventKey={item.id} key={idx}>{item.name}</Dropdown.Item>
																			// <Dropdown.Item eventKey={`${this.state.ehidList[idx].eventKey} - ${this.state.ehidList[idx].name}`} key={idx}>{this.state.ehidList[idx].eventKey} - {this.state.ehidList[idx].name}</Dropdown.Item>
                                    )
                                )}                                
                            </Dropdown.Menu>
                        </Dropdown>
											}
                      {this.state.accPerList.length > 0 &&  
                        <Dropdown className="margin-left-16 period-selector" alignRight onSelect={(event: any) => this.handlePeriodSelect(event)}>
                            <Dropdown.Toggle id="dropdown-period">
                                <div className="d-flex align-items-center">
                                    <div className="period-name mr-auto"><EllipsisWithTooltip placement="bottom">{this.state.accPerDisplay}</EllipsisWithTooltip></div>
                                    <div className="arrow-icon">{dropDownArrowIcon}</div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.state.accPerList.map(
                                    (item: any, idx: any) => (
                                        <Dropdown.Item eventKey={item.displayAccper} key={idx}>{item.displayAccper}</Dropdown.Item>
                                    )
                                )}                                
                            </Dropdown.Menu>
                        </Dropdown>
											}
                        <div className="separator"></div>
                        <Button variant="primary" type="button" className="btn-outline-primary exit-button">Exit</Button>
                    </div>                            
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center">
                    <div className="left-sec">
                        <div className="section">
                            <div className="header-title d-flex">
                                <div className="sec-ttl">Summary</div>
                                <div className="header-status">{this.state.status}</div>
                            </div>
                            <div className="sec-body">
                                <ul>
                                    <li>
                                        <div className="d-flex align-items-center list">
                                            <div className="mr-auto list-ttl">Statement Ending Balance</div>
                                            <div className="list-val"><Form.Control type="text" value={this.state.statementEndBal} /></div>
                                        </div>
                                        <div className="d-flex align-items-center list">
                                            <div className="mr-auto list-ttl">
                                                Pending Deposits
                                                <Button variant="primary" type="button" className="btn-link" onClick={(event:any) => this.handlePendingModal(true)}> (View)</Button>
                                            </div>
                                            <div className="list-val">{Utils.currencyFormat(this.state.pendingDepositBal)}</div>
                                        </div>                                        
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center list">
                                            <div className="mr-auto list-ttl">Subtotal</div>
                                            <div className="list-val">{Utils.currencyFormat(subTotal)}</div>
                                        </div>
                                        <div className="d-flex align-items-center list">
                                            <div className="mr-auto list-ttl">
                                                Pending Withdrawals
                                                <Button variant="primary" type="button" className="btn-link" onClick={(event:any) => this.handlePendingModal(true)}> (View)</Button>
                                            </div>
                                            <div className="list-val">{Utils.currencyFormat(this.state.pendingWithdrawalBal)}</div>
                                        </div>                                        
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center list">
                                            <div className="mr-auto list-ttl">Total</div>
                                            <div className="list-val bold">{Utils.currencyFormat(total)}</div>
                                        </div>
                                        <div className="d-flex align-items-center list">
                                            <div className="mr-auto list-ttl">Account Balance</div>
                                            <div className="list-val bold">{Utils.currencyFormat(this.state.accountBal)}</div>
                                        </div>
                                        <div className="d-flex align-items-center list">
                                            <div className="mr-auto list-ttl">Variance</div>
                                            <div className="list-val bold red">({Utils.currencyFormat(variance)})</div>
                                        </div>                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="section upload-section">
                            <div className="header-title d-flex">
                                <div className="sec-ttl">Attachments</div>                                
                            </div>
                            <div className="sec-body">
                            <Dropzone onDrop={this.onDrop}>
                                {({ getRootProps, getInputProps, isDragActive, isDragReject}) => (
                                    <>
                                        
                                        <section className={isDragActive ? "container upload-area default drag-active " : "container upload-area default"}>
                                            <div {...getRootProps({
                                                className: 'dropzone d-flex align-items-center',
                                                onClick: (this.state.files.length > 0) ?
                                                                event => event.stopPropagation() : event => event
                                            })}>
                                                <input {...getInputProps()} />
                                                {(this.state.files.length <= 0) && (
                                                    <p>Drag and drop invoices or <span>browse files</span> to upload.</p>
                                                )}                                                
                                            </div>                                    
                                            {(this.state.files.length > 0) && (
                                                <aside className="d-flex flex-column">
                                                    <ul>{files}</ul>
                                                </aside>
                                            )}
                                        </section>
                                        {(this.state.files.length < 0) && (                                    
                                            <div className="upload-link mrgn-top">
                                                <button type="button" className="btn wht-bg link-btn mr-auto" >Add Attachment</button>
                                                <Form.Control id="formControlsFile" type="file" multiple onChange={(event: any) => this.onFileChange(event, "1")}
                                                    accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml" />
                                            </div>
                                        )}
                                    </>
                                )}
                            </Dropzone>                                    
                            </div>
                        </div>
                    </div>
                    <div className="right-sec">
                        <div className="section">
                            <div className="header-title d-flex">
                                <div className="sec-ttl mr-auto">Deposits</div>
                                <Dropdown className="margin-left-16 groupReconcile-selector">
                                    <Dropdown.Toggle id="dropdown-groupReconcile">{"Group reconcile"}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="header">Select Accounts to Reconcile</div>
                                        <div className="body">
                                            {this.state.reconcileList.map(
                                                (item: any, idx: any) => (
                                                    <div className="dropdown-item" key={idx}>
                                                        <EllipsisWithTooltip placement="bottom">
                                                            <Form.Check
                                                            custom
                                                            type="checkbox"
                                                            key={"custom-" + idx}
                                                            id={"custom-" + idx}
                                                            label={item.value}
                                                            checked={item.isChecked ? true : false}
                                                            // onChange={(e: any) =>
                                                            //     this.onChangeGroupReconcile(item.value, e)
                                                            // }
                                                            />
                                                        </EllipsisWithTooltip>                                                        
                                                    </div>                                                
                                                )
                                            )}
                                        </div>
                                        <div className="footer d-flex justify-content-end"><Button variant="primary" type="button" className="btn-outline-primary exit-button">Reconcile</Button></div>                                                                        
                                    </Dropdown.Menu>
                                </Dropdown>                                
                            </div>
                            <div className="sec-body">
                                <BootstrapTable
                                    keyField="id"
                                    data={ this.state.depositDataArr }
                                    columns={ depositsColumns }
                                />
                            </div>
                        </div>
                        <div className="section">
                            <div className="header-title d-flex">
                                <div className="sec-ttl mr-auto">Withdrawals</div>                                                               
                            </div>
                            <div className="sec-body">
                                <BootstrapTable
                                    keyField="id"
                                    data={ this.state.withdrawalData }
                                    columns={ withdrawalColumns }
                                />
                            </div>
                        </div>                        
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="unsaved">
                <div className="d-flex justify-content-end">
                    <Button variant="primary" type="button" className="btn-discard" onClick={this.handleCloseModal.bind(this)}>Discard</Button>
                    <Button variant="primary" type="button">Save Changes</Button>
                    <Button variant="primary" type="button" className="btn-outline-primary">Complete Reconciliation</Button>
                </div>                                        
            </Modal.Footer>        
        </Modal>
				{this.state.PendingReconcileModal &&(
                <PendingReconcile
                    headerControlsDisabled ={true}
                    accountName= {this.state.accountName}
                    accPerDisplay= {this.state.accPerDisplay}
                    handlePendingReconcileModal={this.handlePendingModal}>
                </PendingReconcile>
        )}
			</>
        
    );
  }
}
