import * as React from 'react';
import { Container, Dropdown, Tabs, Tab} from "react-bootstrap";
import "./sales.scss";
import {Overview} from "./Overview";
import {Tasks} from "./Tasks";
import {Contacts} from "./Contacts";
import {Documents} from "./Documents";
import { SalesService } from "../../Common/Services/SalesService";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {Bookings} from "./Bookings";
import { Utils } from '../../Common/Utilis';


export class SalesAccountDetails extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeTab: "",
            moreAction: [
                { name: "Change Log", eventKey: "changeLog" },
                { name: "Export", eventKey: "export" },
            ],
            selectedAccount: {},
            redirectFrom: "",
            loadTabs: false,
            accountsPermissionArray: [],
            isViewTaskPerm: false,
            isViewOnlyAccount: false,
        };
    }

    componentDidMount() {
        this.GetSalesPermission();
    }

    GetSalesPermission = () => {       
        SalesService.GetSalesPermission()
            .then(async (result: any | null) => {               
                if (result != null && result.result.length > 0) {
                    // debugger;
                    let perm: any = result.result;
                     this.setState({ 
                        accountsPermissionArray: perm,
                        isViewTaskPerm: perm[0].viewTask.toLowerCase() === "yes" ? true : false,
                    }, () => {
                        const location = this.props.location;
                        if (location.state !== undefined) {
                            const stateLength = Object.keys(location.state).length;
                            if (stateLength > 0) {
                                // pageLoadDate
                                let { selectedAccount, fromPage, activeTab } = location.state;
                                this.setState({
                                    selectedAccount, redirectFrom: fromPage, activeTab,
                                    isViewOnlyAccount: selectedAccount?.viewPermission.toLowerCase() === "view" ? true : false,
                                }, () => {
                                    this.setState({ loadTabs: true, });
                                })
                            }
                        }
                    });
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "sales-acc-det-001",
                });
                reject();
            });
    }

    onBackClick = () => {
        if(this.state.redirectFrom === "salesAccounts") {
            this.props.history.push('/sales/accounts',);
        }
    }

    handleTabSelect = (eventKey) => {
        this.setState({ activeTab: eventKey });
    }

    updateAccNameOrStatus = (accDet) => {
        // if somone updates Acc Name or Status it should reflect in Acc Details Page Header
        let selectedAccount = Object.assign({}, JSON.parse(JSON.stringify(this.state.selectedAccount)));
        selectedAccount.accountName = accDet.accountName;
        selectedAccount.status = accDet.statusName;
        this.setState({ selectedAccount });
    }

    render() {
        return (
            <div className="sales-Details-module position-relative">
                <Container fluid className="body-sec header-sticky">
                    <div className="page-heading set-width d-flex">
                        <div className="d-flex align-items-center ttl-sec">
                            <div className='acc-name'><EllipsisWithTooltip placement="bottom">{this.state.selectedAccount?.accountName}</EllipsisWithTooltip></div>
                            <div className={`status 
                                    ${this.state.selectedAccount?.status == "Lead" ? 'grey' : ''}
                                    ${this.state.selectedAccount?.status == "Active" ? 'green' : ''}
                                    ${this.state.selectedAccount?.status == "Inactive" ? 'orange' : ''}
                                    ${this.state.selectedAccount?.status == "At Risk" ? 'red' : ''}
                                    `}
                            >{this.state.selectedAccount?.status}</div>
                            {/* <div className="tag-name">{this.state.selectedAccount?.status}</div> */}
                        </div>
                        <div className="action-group d-flex ml-auto">
                            <button type="button" className="btn btn-primary btn-outline-primary " onClick={this.onBackClick}>Back</button>
                            <Dropdown className="more-action three-dot" alignRight>
                                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.state.moreAction.map(
                                        (item: any, idx: any) => (
                                            <Dropdown.Item
                                                eventKey={this.state.moreAction[idx].eventKey}
                                                key={idx}
                                            >
                                                {this.state.moreAction[idx].name}
                                            </Dropdown.Item>
                                        )
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="main-Page">
                        <div className="bdy-sec">
                            {this.state.loadTabs && (
                                <Tabs activeKey={this.state.activeTab} id="AccountDetailsTab" onSelect={this.handleTabSelect}>
                                    <Tab eventKey="overview" title="Overview">
                                        {this.state.activeTab === "overview" &&
                                            <Overview
                                                selectedAccount={this.state.selectedAccount}
                                                handleTabSelect={this.handleTabSelect}
                                                accountsPermissionArray={this.state.accountsPermissionArray} //required
                                                updateAccNameOrStatus = {this.updateAccNameOrStatus} // required 
                                            />
                                        }
                                    </Tab>
                                    {this.state.isViewTaskPerm && !this.state.isViewOnlyAccount &&
                                        <Tab eventKey="tasks" title="Tasks">
                                            {this.state.activeTab === "tasks" &&
                                                <Tasks selectedAccount={this.state.selectedAccount}/>
                                            }
                                        </Tab>
                                    }
                                    <Tab eventKey="contacts" title="Contacts">
                                        {this.state.activeTab === "contacts" &&
                                            <Contacts
                                                selectedAccount={this.state.selectedAccount}
                                                handleTabSelect={this.handleTabSelect} 
                                                accountsPermissionArray={this.state.accountsPermissionArray} //required 
                                            />
                                        }
                                    </Tab>
                                    {!this.state.isViewOnlyAccount &&
                                        <Tab eventKey="documents" title="Documents">
                                            {this.state.activeTab === "documents" &&
                                                <Documents />
                                            }
                                        </Tab>
                                    }
                                    {!this.state.isViewOnlyAccount &&
                                        <Tab eventKey="bookings" title="Bookings">
                                            {this.state.activeTab === "bookings" &&
                                                <Bookings />
                                            }
                                        </Tab>
                                    }
                                </Tabs>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}