import React from "react";
import { Button, Container, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { IFacilityMaintenanceListRequest, IGetProceureDetailsRequestDto } from "../../Common/Contracts/IMaintenanceSchedule";
import { FacilityMaintenance } from "../../Common/Services/FacilityMaintenance";
import { toast, ToastContainer } from "react-toastify";
import { resolve, reject } from "q";
import { Utils } from "../../Common/Utilis";
export class ProcedureMaintenanceSlideOut extends React.Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            tableData: [],
            isReload: false,
            isParentState:this.props.parentState.isProcedureRow,
        }
    }
   
    componentDidMount() {
    
     
        this.GetProcedureTaskList();
    }

    closeSlideOut = () => {
      this.props?.closeProcedureSlideout(this.state.isReload);
    }

    GetProcedureTaskList() {
      this.setState({ isworkLoader: true });
      let request = {} as IGetProceureDetailsRequestDto;
      request.hotelid = Number(this.props.parentState.hidValue);
      request.type = "AllProceure";
      request.gblId = this.state.isParentState[0]?.procedureId;
      let storage = JSON.parse(localStorage.getItem("storage")!);   
      let userUniqueID = storage === null ? 0 : (storage.user_UniqueID as any);   
     request.loginUser = Number(userUniqueID);
  
  
      FacilityMaintenance.GetProcedureTaskList(request)
        .then(async (result: any | null) => {
  
          if (result != null && result.length > 0) {
           
            this.setState({ tableData: result });
          } else {
            this.setState({ tableData: [] });
          }
  
  
          resolve();
        })
        .catch((err) => {
  
          Utils.toastError(`Server Error, ${err}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "ProcedureMaintenanceSlideOut",
          });
  
           reject();
        });
    }




    render(){
        
    const columns = [
        {
            dataField: "name",
            text: "",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return(
                    <EllipsisWithTooltip placement="bottom">{row.name}</EllipsisWithTooltip>
                )
            }
        }
    ]

   
        return(
            <div className="procedure-maintenance-slideout"> 
                <div className="breadcrumb-bar">
                    <Button 
                  onClick={this.closeSlideOut}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none">
                    <path d="M25.116 3.1328L22.4208 0.4375L11.6396 11.2187L22.4208 22L25.116 19.3045L17.0302 11.2187L25.116 3.1328Z" fill="#2CAF92"/>
                    <path d="M11.6396 0.4375L14.3349 3.1328L6.24901 11.2187L14.3349 19.3045L11.6396 21.9998L0.858398 11.2187L11.6396 0.4375Z" fill="#2CAF92"/>
                    </svg>
                    <span>Back to {}{this.props.parentState.scheduleData[0]?.name}</span>
                    
                    </Button>
                </div> 
                <Container fluid className="body-sec w-100">
                    <div className="page-heading d-flex">
                        <div className="user-details align-items-center mr-auto">
                            <div className="maintenance-title">
                                <EllipsisWithTooltip placement="bottom">
                               {this.state.isParentState[0]?.procedureName}
                                </EllipsisWithTooltip>
                            </div>
                            <div className="position-name">Procedure</div>
                        </div> 

                      <div className="d-flex">
                          <Dropdown className="more-action" alignRight>
                            <Dropdown.Toggle
                              className="btn-outline-primary btn btn-primary more border-0"
                              id="dropdown-more"
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                              </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item></Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <button
                          type="button"
                          className="btn wht-bg cross pr-0 pl-2" 
                          onClick={this.closeSlideOut}
                        >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#84888C"
                            />
                          </svg>
                        </button>
                      </div>
                        
                    </div> 
                    <div className="table-wrapper">
                        <h4 className="title mb-3">Tasks</h4>
                        <BootstrapTable 
                            data={this.state.tableData}
                            columns={columns}
                            keyField="procedure tasks" 
                        />
                    </div>
                </Container>
            </div>
        )
    }
}