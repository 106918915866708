export class StaticArrays {
  public static yesNoOptions: any[] = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];

  public static genderList: any[] = [
    {
      label: "Male",
      value: "M",
    },
    {
      label: "Female",
      value: "F",
    },
  ];

  public static jobTypes: any[] = [
    {
      label: "Full Time",
      value: "Full Time",
    },
    {
      label: "Part Time",
      value: "Part Time",
    },
  ];

  public static fillingStatusList: any[] = [
    {
      label: "Single or MFS",
      value: "Single or MFS",
    },
    {
      label: "Married Filing Jointly",
      value: "Married Filing Jointly",
    },
    {
      label: "Head of Household",
      value: "Head of Household",
    },
    {
      label: "Exempt",
      value: "Exempt",
    },
  ];

  public static userStates = [
    { label: "AL", value: "AL" },
    { label: "AK", value: "AK" },
    { label: "AZ", value: "AZ" },
    { label: "AR", value: "AR" },
    { label: "CA", value: "CA" },
    { label: "CO", value: "CO" },
    { label: "CT", value: "CT" },
    { label: "DE", value: "DE" },
    { label: "DC", value: "DC" },
    { label: "FL", value: "FL" },
    { label: "GA", value: "GA" },
    { label: "HI", value: "HI" },
    { label: "ID", value: "ID" },
    { label: "IL", value: "IL" },
    { label: "IN", value: "IN" },
    { label: "IA", value: "IA" },
    { label: "KS", value: "KS" },
    { label: "KY", value: "KY" },
    { label: "LA", value: "LA" },
    { label: "ME", value: "ME" },
    { label: "MD", value: "MD" },
    { label: "MA", value: "MA" },
    { label: "MI", value: "MI" },
    { label: "MN", value: "MN" },
    { label: "MS", value: "MS" },
    { label: "MO", value: "MO" },
    { label: "MT", value: "MT" },
    { label: "NE", value: "NE" },
    { label: "NV", value: "NV" },
    { label: "NH", value: "NH" },
    { label: "NJ", value: "NJ" },
    { label: "NM", value: "NM" },
    { label: "NY", value: "NY" },
    { label: "NC", value: "NC" },
    { label: "ND", value: "ND" },
    { label: "OH", value: "OH" },
    { label: "OK", value: "OK" },
    { label: "OR", value: "OR" },
    { label: "PA", value: "PA" },
    { label: "PR", value: "PR" },
    { label: "RI", value: "RI" },
    { label: "SC", value: "SC" },
    { label: "SD", value: "SD" },
    { label: "TN", value: "TN" },
    { label: "TX", value: "TX" },
    { label: "UT", value: "UT" },
    { label: "VT", value: "VT" },
    { label: "VA", value: "VA" },
    { label: "WA", value: "WA" },
    { label: "WV", value: "WV" },
    { label: "WI", value: "WI" },
    { label: "WY", value: "WY" },
  ];

  public static UserAmountTypes = [
    { label: "Entire Paycheck", value: "Total Payroll", depositType: "1" },
    { label: "% of Paycheck", value: "% of Paycheck", depositType: "1" },
    {
      label: "$ Amount of Paycheck",
      value: "$Amount of Paycheck",
      depositType: "1",
    },
    { label: "None", value: "None", depositType: "2" },
    { label: "% of Paycheck", value: "% of Paycheck", depositType: "2" },
    {
      label: "$ Amount of Paycheck",
      value: "$Amount of Paycheck",
      depositType: "2",
    },
  ];

  public static UserAccountTypes = [
    { label: "Checking", value: "Checking" },
    { label: "Savings", value: "Savings" },
  ];

  public static StatusList = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  public static withHoldingUnemploymentStates = [
    { label: "AL", value: "AL" },
    { label: "AK", value: "AK" },
    { label: "AZ", value: "AZ" },
    { label: "AR", value: "AR" },
    { label: "CA", value: "CA" },
    { label: "CO", value: "CO" },
    { label: "CT", value: "CT" },
    { label: "DE", value: "DE" },
    { label: "DC", value: "DC" },
    { label: "FL", value: "FL" },
    { label: "GA", value: "GA" },
    { label: "HI", value: "HI" },
    { label: "ID", value: "ID" },
    { label: "IL", value: "IL" },
    { label: "IN", value: "IN" },
    { label: "IA", value: "IA" },
    { label: "KS", value: "KS" },
    { label: "KY", value: "KY" },
    { label: "LA", value: "LA" },
    { label: "ME", value: "ME" },
    { label: "MD", value: "MD" },
    { label: "MA", value: "MA" },
    { label: "MI", value: "MI" },
    { label: "MN", value: "MN" },
    { label: "MS", value: "MS" },
    { label: "MO", value: "MO" },
    { label: "MT", value: "MT" },
    { label: "NE", value: "NE" },
    { label: "NV", value: "NV" },
    { label: "NH", value: "NH" },
    { label: "NJ", value: "NJ" },
    { label: "NM", value: "NM" },
    { label: "NY", value: "NY" },
    { label: "NC", value: "NC" },
    { label: "ND", value: "ND" },
    { label: "OH", value: "OH" },
    { label: "OK", value: "OK" },
    { label: "OR", value: "OR" },
    { label: "PA", value: "PA" },
    { label: "PR", value: "PR" },
    { label: "RI", value: "RI" },
    { label: "SC", value: "SC" },
    { label: "SD", value: "SD" },
    { label: "TN", value: "TN" },
    { label: "TX", value: "TX" },
    { label: "UT", value: "UT" },
    { label: "VT", value: "VT" },
    { label: "VA", value: "VA" },
    { label: "WA", value: "WA" },
    { label: "WV", value: "WV" },
    { label: "WI", value: "WI" },
    { label: "WY", value: "WY" },
  ];

  public static deductionTypeList: any[] = [
    { label: "Insurance", value: "Insurance" },
    { label: "401K", value: "401K" },
    { label: "401B", value: "401B" },
    { label: "Cafeteria Plan", value: "Cafeteria Plan" },
    { label: "Child Support", value: "Child Support" },
    { label: "401(k)", value: "401(k)" },
    {
      label: "Local 401(k) for Employee 50+",
      value: "Local 401(k) for Employee 50+",
    },
    { label: "401(k) Loan Repayment", value: "401(k) Loan Repayment" },
    { label: "403(b)", value: "403(b)" },
    {
      label: "403(b) for Employee 50+",
      value: "403(b) for Employee 50+",
    },
    { label: "408(a)", value: "408(a)" },
    { label: "457(b)", value: "457(b)" },
    {
      label: "457(b) for Employee 50+",
      value: "457(b) for Employee 50+",
    },
    {
      label: "501c(18) (Regular 501c)",
      value: "501c(18) (Regular 501c)",
    },
    { label: "Bankruptcy", value: "Bankruptcy" },
    { label: "Box 14", value: "Box 14" },
    {
      label: "Charitable Contributions",
      value: "Charitable Contributions",
    },
    { label: "Child/Spousal Support", value: "Child/Spousal Support" },
    {
      label: "College Savings Account (529)",
      value: "College Savings Account (529)",
    },
    { label: "Creditor Debt", value: "Creditor Debt" },
    { label: "Dental Insurance", value: "Dental Insurance" },
    {
      label: "Employer Contributions to Archer Medical Savings Acct.",
      value: "Employer Contributions to Archer Medical Savings Acct.",
    },
    {
      label: "Employer Payments Adoption Assistance",
      value: "Employer Payments Adoption Assistance",
    },
    {
      label: "Excludable moving expense reimbursement",
      value: "Excludable moving expense reimbursement",
    },
    { label: "Federal Agency Debt", value: "Federal Agency Debt" },
    { label: "Federal Tax Levy", value: "Federal Tax Levy" },
    {
      label: "Flexible Spending Account (FSA) Dependent Care Fixed amount",
      value: "Flexible Spending Account (FSA) Dependent Care Fixed amount",
    },
    { label: "FSA Medical", value: "FSA Medical" },
    {
      label: "Garnishment (miscellaneous)",
      value: "Garnishment (miscellaneous)",
    },
    {
      label: "Group Term Life Ins. over 50K",
      value: "Group Term Life Ins. over 50K",
    },
    { label: "Health Savings Account", value: "Health Savings Account" },
    { label: "Loan Repayment", value: "Loan Repayment" },
    { label: "Local Tax Levy", value: "Local Tax Levy" },
    { label: "Medical Insurance", value: "Medical Insurance" },
    {
      label: "Non­Statutory Stock Options",
      value: "Non­Statutory Stock Options",
    },
    {
      label: "Non­Taxable Part of Employee Business Expense",
      value: "Non­Taxable Part of Employee Business Expense",
    },
    { label: "Non­Taxable Sick Pay", value: "Non­Taxable Sick Pay" },
    { label: "Roth 401(k)", value: "Roth 401(k)" },
    {
      label: "Roth 401(k) for Employee 50+",
      value: "Roth 401(k) for Employee 50+",
    },
    { label: "Roth 403(b)", value: "Roth 403(b)" },
    {
      label: "Roth 403(b) for Employee 50+",
      value: "Roth 403(b) for Employee 50+",
    },
    { label: "SARSEP 408(k)", value: "SARSEP 408(k)" },
    {
      label: "SARSEP 408(k) for Employee 50+",
      value: "SARSEP 408(k) for Employee 50+",
    },
    { label: "Savings Bonds", value: "Savings Bonds" },
    {
      label: 'Section 125 Medical (Premium only plan, or "POP")',
      value: 'Section 125 Medical (Premium only plan, or "POP")',
    },
    {
      label: "Section 125 Optical (POP)",
      value: "Section 125 Optical (POP)",
    },
    {
      label: "Section 125 Dental (POP)",
      value: "Section 125 Dental (POP)",
    },
    { label: "Simple 401(k)", value: "Simple 401(k)" },
    {
      label: "Simple 401(k) for Employee 50+",
      value: "Simple 401(k) for Employee 50+",
    },
    { label: "SIMPLE IRA 408(p)", value: "SIMPLE IRA 408(p)" },
    {
      label: "SIMPLE IRA 408(p) for Employee Local 50+",
      value: "SIMPLE IRA 408(p) for Employee Local 50+",
    },
    { label: "State Tax Levy", value: "State Tax Levy" },
    { label: "Student Loan Debt", value: "Student Loan Debt" },
    {
      label: "Tax on Excess Golden Parachutes",
      value: "Tax on Excess Golden Parachutes",
    },
    {
      label: "Uncollected Medicare on GTL",
      value: "Uncollected Medicare on GTL",
    },
    {
      label: "Uncollected Medicare on Tips",
      value: "Uncollected Medicare on Tips",
    },
    {
      label: "Uncollected SocSec or RRTA on GTL",
      value: "Uncollected SocSec or RRTA on GTL",
    },
    {
      label: "Uncollected SocSec or RRTA on Tips",
      value: "Uncollected SocSec or RRTA on Tips",
    },
    { label: "Union Dues", value: "Union Dues" },
    { label: "Vision Insurance", value: "Vision Insurance" },
    { label: "Deduction Type", value: "Deduction Type" },
  ];

  public static deductionCriteriaList: any[] = [
    { label: "Fixed Amount", value: "Fixed Amount" },
    { label: "% of Gross", value: "% of Gross" },
    { label: "% of Net", value: "% of Net" },
    { label: "% of Gross Tax", value: "% of Gross Tax" },
    { label: "Amount ($) Per Hour", value: "Amount ($) Per Hour" },
    { label: "% of Compensation", value: "% of Compensation" },
    { label: "Amount ($) per Month", value: "Amount ($) per Month" },
    { label: "Direct Entry", value: "Direct Entry" },
  ];

  public static exemptList: any[] = [
    { label: "Federal", value: "Federal" },
    { label: "FICA", value: "FICA" },
    { label: "FUTA", value: "FUTA" },
    { label: "State", value: "State" },
    { label: "Local", value: "Local" },
    { label: "SUTA", value: "SUTA" },
  ];

  public static taxTypesList: any[] = [
    { value: "Pre Tax", label: "Pre Tax" },
    { value: "Post Tax", label: "Post Tax" },
  ];

  public static usaStatesWithFullName: any[] = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  public static overtimeRates = [
    { label: "1.0", value: 1.0 },
    { label: "1.5", value: 1.5 },
    { label: "2.0", value: 2.0 },
  ];
  public static doubleOvertimeRates = [
    { label: "2.0", value: 2.0 },
    { label: "3.0", value: 3.0 },
  ];
  public static replacementReservesMethod = [
    { label: "Percentage", value: 1 },
    { label: "Fixed Amount", value: 2 },
  ];

  public static yesNoTrueFalseOptions: any[] = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  public static endpoints = [
    { label: "Default", value: "default" },
    { label: "Red Roof Inn", value: "RedRoofInn" }
  ]
}
