import * as React from "react";
import {
  Form,
  Row,
  Col,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "react-input-range/lib/css/index.css";
import { Slider } from "../../../Common/Components/slider";
import BootstrapTable from "react-bootstrap-table-next"; 
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Autocomplete from "react-autocomplete";
import { resolve, reject } from "q";
import { ILaborPositionDeptResponseDto } from "../../../Common/Contracts/ILaborDeptPosition";
import { LaborDeptPosition as laborDeptPosition } from "../../../Common/Services/LaborDeptPosition";
import { LaborBudgetSetting } from "../../../Common/Services/LaborBudgetSetting";
import {
  IBudgetSettingRequestDto,
  IBudgetSettingDto,
  IBudgeSettingtDeleteRequestDto,
} from "../../../Common/Contracts/ILaborBudgetSettings";
import { Chart as chart } from "../../../Common/Services/Chart";
import Duplicate from "../../../Common/Assets/Images/icons/duplicate.svg";
import Delete from "../../../Common/Assets/Images/icons/delete.svg";
import { InfoTooltip } from "../../../Common/Components/InfoTooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MultiSelectSearch } from "../../../Common/Components/MultiSelectSearch";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageLoader from "../../../Common/Components/PageLoader";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Utils } from "../../../Common/Utilis"; 
const autocompleteData: any[] = [];

export class BudgetSettingsRightPanel extends React.Component<any, any> {
  private multiselectChild: any;
  private autoFocusTextInput: any;
  constructor(props: any) {
    super(props);

    this.multiselectChild = React.createRef();
    this.autoFocusTextInput = React.createRef();
    this.state = {
      isSaved: false,
      tablekey: "",
      budgetTypeValue: "Select Model Type",
      dataFromValue: "Today",
      loaderTrue: false,
      isActionFooter: false,
      isAutocomplete: false,
      isRPanelHide: false,
      isNewBudget: false,
      isDuplicate: false,
      isDuplicatePanel: false,
      isUniqueNo: 0,
      isPositionName: "",
      isdepartmentName: "",
      isRActions: false,

      budgetTypeList: [
        { budgetTypeID: "DOTW", budgetType: "DOTW" },
        { budgetTypeID: "FactorRevenue", budgetType: "Factor of Revenue" },
        { budgetTypeID: "FactorStatistic", budgetType: "Factor of Statistic" },
        { budgetTypeID: "MPOR", budgetType: "MPOR" },
        { budgetTypeID: "RangeStatistic", budgetType: "Range of Statistics" },
        { budgetTypeID: "RangeRevenue", budgetType: "Range of Revenue" },
      ],

      dataFromList: [
        { dataFromID: "Yesterday", dataFrom: "Yesterday" },
        { dataFromID: "Today", dataFrom: "Today" },
      ],

      header: ["Min", "", "Max", "Time", ""],
      rangeStatisticData: [
        {
          value: { min: "0", max: "" },
          hours: "00",
          minutes: "00",
          time: "00:00",
        },
      ],
      tableDate: [],
      DeptData: [],
      positionData: [],
      staticList: [],
      fieldData: {
        uniqueno: 0,
        departmentID: 0,
        departmentName: "",
        positionID: 0,
        positionName: "",
        notes: "",
        revenueType: "",
        subType: "",
        tenantID: 0,
        hotelID: 0,
        budgetTypeID: "",
        budgetType: "",
        itemName: "Select Statistic",
        hours: "00",
        minutes: "00",
        value: 0,
        averageRate: "0.00",
        laborCost: "0.00",
        statisticsNo: "0",
        isRoomAssign: false,
        discartStatisticsNo: "0",
        discartStatisticsItemName: "",
        dataFrom: "",
        budgetSettingList: [
          {
            text: "",
            value: "",
            srNo: 0,
          },
        ],
      },
      coaSelect: [{}],
      coaList: [],
      isSaveDisaled: false,
    };
    // Bind `this` context to functions of the class
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.onAutocompleteSelect = this.onAutocompleteSelect.bind(this);
    this.getItemValue = this.getItemValue.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.dataFromDropdownChange = this.dataFromDropdownChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.onchangeFactorTime = this.onchangeFactorTime.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleBudgetRight = this.handleBudgetRight.bind(this);
    this.handleBudgetLeft = this.handleBudgetLeft.bind(this);
    this.handleLaborCost = this.handleLaborCost.bind(this);
    this.handleLaborAverage = this.handleLaborAverage.bind(this);
    this.changeHH = this.changeHH.bind(this);
    this.changeMM = this.changeMM.bind(this);
  }

  rowEvents = {
    onChange: (e: any, row: any, rowIndex: any) => {
      this.setState({ isActionFooter: true });
    },
  };

  handleRowClick(data: any) {
    this.loadState();
    this.setState({
      isActionFooter: false,
      loaderTrue: true,
      isAutocomplete: false,
      isNewBudget: false,
      isRPanelHide: false,
      isDuplicate: false,
      isRActions: true,
    });
    this.setState({ fieldData: data });
    let request = {} as IBudgetSettingRequestDto;
    request.budgetType = data.budgetTypeID;
    request.departmentID = data.departmentID;
    request.positionID = data.positionID;
    request.hotelID = data.hotelID;
    request.uniqueno = data.uniqueno;
    this.setState({
      isUniqueNo: data.uniqueno,
      isPositionName: data.positionName,
      isdepartmentName: data.departmentName,
      budgetTypeValue: data.budgetTypeID,
    });
    this.getBudgetSettingDetail(request);
  }



  getBudgetSettingDetail(request: IBudgetSettingRequestDto) {
    LaborBudgetSetting.LaborBudgetSettingDetail(request)
      .then(async (result: IBudgetSettingDto[] | null) => {
        if (result != null) {
          let new_state = Object.assign({}, this.state);
          let fieldData = new_state.fieldData;
          if (!this.state.isNewBudget) {
            fieldData.notes = result[0].notes;
          }

          fieldData.isRoomAssign = result[0].isRoomAssign;
          fieldData.dataFrom = result[0].dataFrom;
          this.setState({ dataFromValue: result[0].dataFrom });
          if (result[0].dataFrom === "") {
            if (request.budgetType.toLowerCase() === "MPOR".toLowerCase()) {
              fieldData.dataFrom = "Yesterday";
              this.setState({ dataFromValue: "Yesterday" });
            } else {
              fieldData.dataFrom = "Today";
              this.setState({ dataFromValue: "Today" });
            }
          }

          if (
            request.budgetType.toLowerCase() === "MPOR".toLowerCase() ||
            request.budgetType.toLowerCase() === "DOTW".toLowerCase()
          ) {
            const rows: any = result[0].budgetSettingList;
            if (rows.length > 0) {
              rows.map((item) => {
                let hours = "00";
                let minutes = "00";
                if (item.value.includes(":")) {
                  hours = item.value.split(":")[0];
                  minutes = item.value.split(":")[1];
                }
                item.hours = hours;
                item.minutes = minutes;
                item.itemHHref = React.createRef();
                item.itemMMref = React.createRef();
                item.itemMPORref = React.createRef();
                return item;
              });

              this.setState({ tableDate: rows });
            }
            fieldData.isAlreadyAssigned = result[0].isAlreadyAssigned.toLowerCase() === "yes"?true:false;
            this.setState({ fieldData: fieldData, laborBudgetSetting:result[0] });
          } else if (
            request.budgetType.toLowerCase() === "FactorStatistic".toLowerCase()
          ) {
            fieldData.statisticsNo = result[0].statisticsNo;
            let hours = "00";
            let minutes = "00";
            if (result[0].value.toString().includes(":")) {
              hours = result[0].value.toString().split(":")[0];
              minutes = result[0].value.toString().split(":")[1];
            }
            fieldData.hours = hours;
            fieldData.minutes = minutes;
            fieldData.value = result[0].value;
            this.setState({
              discartStatisticsNo: result[0].statisticsNo,
              discartStatisticsItemName: this.state.fieldData.itemName,
            });
            this.setState({ fieldData: fieldData });
          } else if (
            request.budgetType.toLowerCase() === "FactorRevenue".toLowerCase()
          ) {
            fieldData.averageRate = result[0].averageRate;
            fieldData.laborCost = result[0].laborCost;
            fieldData.subType = result[0].subType;

            let chartItems: any[] = [];
            let savedchartItems = result[0].budgetChartList as any;

            savedchartItems.forEach((element: { chartNo: any }) => {
              let newItem = {} as any;
              newItem.value = element.chartNo;
              newItem.isChecked = true;
              chartItems.push(newItem);
            });
            this.setState({ fieldData: fieldData });
            this.setState({ coaSelect: chartItems });
            this.multiselectChild.current.updateSavedItem(chartItems);
          } else if (
            request.budgetType.toLowerCase() === "RangeStatistic".toLowerCase()
          ) {
            let rangeData: any = [];
            let budgetRageofStatisticsList =
              result[0].budgetRageofStatisticsList;
            this.setState({
              discartStatisticsNo: result[0].statisticsNo,
              discartStatisticsItemName: this.state.fieldData.itemName,
            });
            for (let i = 0; i <= budgetRageofStatisticsList.length - 1; i++) {
              let rangeFrom = budgetRageofStatisticsList[i].rangeFrom;
              let rangeTo = budgetRageofStatisticsList[i].rangeTo;
              let timeValue = budgetRageofStatisticsList[i].value;
              let hourTime = timeValue.split(".");
              let newTimeValue = hourTime[0] + ":" + hourTime[1];
              let value: any = {};
              value.min = rangeFrom;
              value.max = rangeTo;
              let data: any = {};
              data.value = value;
              data.time = this.formatHourValue(newTimeValue);
              data.hours = hourTime[0];
              data.minutes = hourTime[1]?.length === 1 ? "0" + hourTime[1] : hourTime[1];
              rangeData.push(data);
            }
            this.setState({ rangeStatisticData: rangeData });
          } else if (
            request.budgetType.toLowerCase() === "RangeRevenue".toLowerCase()
          ) {
            fieldData.subType = result[0].subType;
            let rangeData: any = [];
            let budgetRageofStatisticsList =
              result[0].budgetRageofStatisticsList;

            for (let i = 0; i <= budgetRageofStatisticsList.length - 1; i++) {
              let rangeFrom = budgetRageofStatisticsList[i].rangeFrom;
              let rangeTo = budgetRageofStatisticsList[i].rangeTo;
              let timeValue = budgetRageofStatisticsList[i].value;
              let hourTime = timeValue.split(".");
              let newTimeValue = hourTime[0] + ":" + hourTime[1];
              let value: any = {};
              value.min = rangeFrom;
              value.max = rangeTo;
              let data: any = {};
              data.value = value;
              data.time = this.formatHourValue(newTimeValue);
              data.hours = hourTime[0];
              data.minutes = hourTime[1]?.length === 1 ? "0" + hourTime[1] : hourTime[1];

              rangeData.push(data);
            }
            this.setState({ rangeStatisticData: rangeData });

            let chartItems: any[] = [];
            let savedchartItems = result[0].budgetChartList as any;

            savedchartItems.forEach((element: { chartNo: any }) => {
              let newItem = {} as any;
              newItem.value = element.chartNo;
              newItem.isChecked = true;
              chartItems.push(newItem);
            });
            this.setState({ fieldData: fieldData });
            this.setState({ coaSelect: chartItems });
            this.multiselectChild.current.updateSavedItem(chartItems);
          }  
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  changeMPOR = (e: any, row: any, rowIndex: any) => {
    if (Number(e.target.value) >= 0) {
      const rows = [...this.state.tableDate];
      rows[rowIndex].value = e.target.value;
      this.setState({ tableDate: rows }, () => {
        if (row.itemMPORref && row.itemMPORref.current)
          row.itemMPORref.current.focus();
      });
    }
  };

  onBlurMPOR = (e: any, row: any, rowIndex: any) => {
    if (e.target.value.trim().length === 0) {
      const rows = [...this.state.tableDate];
      rows[rowIndex].value = "0";
      this.setState({ tableDate: rows });
    }
  };

  changeHH = (e: any, row: any, rowIndex: any) => {
    ;
    if (Number(e.target.value) >= 0) {
      const rows = [...this.state.tableDate];
      rows[rowIndex].hours = e.target.value;
      rows[rowIndex].value = e.target.value + ":" + rows[rowIndex].minutes;
      this.setState({ tableDate: rows }, () => {
        let txtID = rowIndex + "HH"
        $("#tblmpordotw").find("input[id^='" + txtID + "']").focus();
      });
    }
  };

  onBlurHH = (e: any, row: any, rowIndex: any) => {
    if (e.target.value.length === 0) {
      const rows = [...this.state.tableDate];
      rows[rowIndex].hours = "0";
      rows[rowIndex].value = "0:" + rows[rowIndex].minutes;
      this.setState({ tableDate: rows }, () => {
      });
    }
  };

  changeMM = (e: any, row: any, rowIndex: any) => {
    ;
    if (Number(e.target.value) < 60) {
      const rows = [...this.state.tableDate];
      rows[rowIndex].minutes = e.target.value.length === 1 ? Number(e.target.value) > 5 ? "0" + e.target.value : e.target.value : e.target.value;
      rows[rowIndex].value = rows[rowIndex].hours + ":" + (e.target.value.length === 1 ? Number(e.target.value) > 5 ? "0" + e.target.value : e.target.value : e.target.value);
      this.setState({ tableDate: rows }, () => {
        let txtID = rowIndex + "MM"
        $("#tblmpordotw").find("input[id^='" + txtID + "']").focus();
      });
    }
  };

  onBlurMM = (e: any, row: any, rowIndex: any) => {
    if (e.target.value.length === 0) {
      const rows = [...this.state.tableDate];
      rows[rowIndex].minutes = "00";
      rows[rowIndex].value = rows[rowIndex].hours + ":00";
      this.setState({ tableDate: rows }, () => {
      });
    }
  };

  handleBudgetRight = (data: any) => {
    this.BindNewBudget(data);
    let chartItems: any[] = [];
    chartItems = this.state.coaSelect;
    for (let i = 0; i < chartItems.length; i++) {
      if (chartItems[i].value !== undefined) {
        chartItems.forEach((element) => {
          chartItems.splice(element);
        });

        this.setState({ coaSelect: chartItems });
        this.multiselectChild.current.updateSavedItem([]);
      }
    }
  };

  handleBudgetLeft = (data: any) => {
    this.BindNewBudget(data);
  };

  BindNewBudget(data: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.uniqueno = 0;
    fieldData.departmentID = 0;
    fieldData.departmentName = "";
    fieldData.positionID = 0;
    fieldData.positionName = "";
    fieldData.notes = "";
    fieldData.budgetType = "";
    fieldData.revenueType = "";
    fieldData.subType = "";
    fieldData.tenantID = 0;
    fieldData.hotelID = data.hotelID;
    fieldData.budgetTypeID = "";
    fieldData.itemName = "Select Statistic";
    fieldData.statisticsNo = "-1";
    fieldData.hours = "00";
    fieldData.minutes = "00";
    fieldData.value = "00:00";
    fieldData.isRoomAssign = false;
    fieldData.averageRate = "0.00";
    fieldData.laborCost = "0.00";
    fieldData.dataFrom = "";
    this.setState(
      {
        fieldData,
        rangeStatisticData: [
          {
            value: { min: "0", max: "" },
            hours: "00",
            minutes: "00",
            time: "00:00",
          },
        ],
        budgetTypeValue: "Select Model Type",
        dataFromValue: "Yesterday",
        loaderTrue: true,
        isDuplicate: false,
        isActionFooter: false,
        isAutocomplete: true,
        isRPanelHide: false,
        isNewBudget: true,
        isDuplicatePanel: false,
      },
      () => {
        const timer = setTimeout(() => {
          this.autoFocusTextInput?.current?.focus();
        }, 1500);
        return () => clearTimeout(timer);
      }
    );
    this.bindAutocomplete(data.hotelID);
  }

  bindAutocomplete(hotelID: number) {
    laborDeptPosition
      .getlaborPositionDept(hotelID)
      .then(async (result: ILaborPositionDeptResponseDto[] | null) => {
        let positionData: any[] = [];
        if (result != null) {
          autocompleteData.forEach((elements) => {
            autocompleteData.reduce(elements);
          });
          result.forEach((element) => {
            let newItem = {} as any;
            newItem.positionName = element.positionName;
            newItem.positionID = element.positionID;
            positionData.push(newItem);

            let deptItem = {} as any;
            deptItem.positionID = element.positionID;
            deptItem.departmentID = element.departmentID;
            deptItem.departmentName = element.departmentName;
            this.state.DeptData.push(deptItem);
          });
        }

        this.setState({ positionData, loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  handleAction = (event: any, row: any, rowIndex: number) => { };

  onAutocompleteSelect(selectItem: any) {
    let positionArray = selectItem.split("@@@");
    let position_Id = Number(
      positionArray[0].toString().replace(/(^\s+|\s+$)/g, "")
    );
    let position_Name = positionArray[1].toString().replace(/(^\s+|\s+$)/g, "");
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.positionID = position_Id;
    fieldData.positionName = position_Name;
    for (const item of this.state.DeptData) {
      if (item.positionID === position_Id) {
        fieldData.departmentID = item.departmentID;
        fieldData.departmentName = item.departmentName;
      }
    }
    this.setState({ isActionFooter: true, isAutocomplete: false });
    this.setState({ fieldData: fieldData });
    this.props.isDuplicateRow();
  }

  renderItem(item: any, isHighlighted: any) {
    return (
      <div style={{ background: isHighlighted ? "lightgray" : "white" }}>
        {item.positionName}
      </div>
    );
  }

  getItemValue(item: { positionID: any; positionName: any }) {
    return `${item.positionID}@@@${item.positionName}`;
  }

  handleDropdownChange(e: any) {
    this.setState({
      loaderTrue: true,
      isActionFooter: true,
    });
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;

    fieldData.budgetTypeID = e.budgetTypeID;
    fieldData.budgetType = e.budgetType;
    this.setState({ fieldData });
    let request = {} as IBudgetSettingRequestDto;
    request.budgetType = this.state.fieldData.budgetTypeID;
    request.departmentID = this.state.fieldData.departmentID;
    request.positionID = this.state.fieldData.positionID;
    request.hotelID = this.state.fieldData.hotelID;
    this.getBudgetSettingDetail(request);
    if (e.budgetTypeID === "FactorRevenue") {
      if (this.state.isNewBudget) {
        let new_state = Object.assign({}, this.state);
        let fieldData = new_state.fieldData;
        fieldData.averageRate = "0.00";
        fieldData.laborCost = "0.00";
        this.setState({ fieldData: fieldData });
      }
      this.setState({ fieldData: fieldData }, () =>
        this.multiselectChild.current?.updateSavedItem([])
      );
    } else if (e.budgetTypeID === "RangeRevenue") {
      this.setState({ fieldData: fieldData }, () =>
        this.multiselectChild.current?.updateSavedItem([])
      );
      let chartItems: any[] = [];
      chartItems = this.state.coaSelect;
      chartItems.forEach((element) => {
        chartItems.splice(element);
      });

      this.setState({ coaSelect: chartItems });
    }

    if (this.state.isNewBudget) {
      if (e.budgetTypeID === "MPOR") {
        fieldData.dataFrom = "Yesterday";
        this.setState({ dataFromValue: "Yesterday" });
      } else {
        fieldData.dataFrom = "Today";
        this.setState({ dataFromValue: "Today" });
      }
    }
  }

  dataFromDropdownChange(e: any) {
    this.setState({
      isActionFooter: true,
    });
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.dataFrom = e.dataFrom;
    this.setState({ fieldData });
  }

  onAutocompleteChange(e: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.positionName = e.target.value;
    this.setState({ fieldData });
  }

  onchangeNotes(event: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.notes = event.target.value;
    this.setState({ fieldData, isActionFooter: true });
  }

  onchangeDisplayName(event: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;

    let value = event.target.value;
    if (/^[A-Za-z0-9\-_() ]+$/.test(value) === false) {
      event.target.value = value.replace(/[^A-Za-z0-9\-_() ]/g, "");
    }
    if (value.length > 50) {
      let a = value.replace(/[^A-Za-z0-9\-_() ]/g, "");
      event.target.value = a.substring(0, value.length - 1);
    }

    event.target.value = event.target.value.replace(/\s\s+/g, " ");
    event.target.value = event.target.value.replace(/^\s+/g, "");
    fieldData.subType = event.target.value;
    this.setState({ fieldData, isActionFooter: true });
  }

  onchangeFactorTime(event: any, type: string) {
    if (type === "MM") {
      if (Number(event.target.value) < 60) {
        let new_state = Object.assign({}, this.state);
        let fieldData = new_state.fieldData;
        fieldData.minutes = (event.target.value.trim().length === 1 ? Number(event.target.value) > 5 ? "0" + event.target.value : event.target.value : event.target.value);
        fieldData.value = fieldData.hours + ":" + (event.target.value.trim().length === 1 ? Number(event.target.value) > 5 ? "0" + event.target.value : event.target.value : event.target.value);
        this.setState({ fieldData, isActionFooter: true });
      }
    } else {
      if (event.target.value === "0" || event.target.value.trim().length === 0 || Number(event.target.value)) {
        let new_state = Object.assign({}, this.state);
        let fieldData = new_state.fieldData;
        fieldData.hours = event.target.value.trim();
        fieldData.value = event.target.value.trim() + ":0";
        this.setState({ fieldData, isActionFooter: true });
      }
    }
  }

  onBlurFactorTime(event: any, type: string) {
    if (type === "MM") {
      if (event.target.value === "") {
        let new_state = Object.assign({}, this.state);
        let fieldData = new_state.fieldData;
        fieldData.minutes = "00";
        fieldData.value = `${fieldData.hours}:00`;
        this.setState({ fieldData, isActionFooter: true });
      }
    } else {
      if (event.target.value === "") {
        let new_state = Object.assign({}, this.state);
        let fieldData = new_state.fieldData;
        fieldData.hours = "00";
        fieldData.value = "0:0";
        this.setState({ fieldData, isActionFooter: true });
      }
    }
  }

  handleLaborCost(event: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.laborCost = event.target.value;
    this.setState({ fieldData, isActionFooter: true });
  }

  handleLaborAverage(event: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.averageRate = event.target.value;
    this.setState({ fieldData, isActionFooter: true });
  }

  onchangeAssignable(event: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.isRoomAssign = event.target.checked;
    this.setState({ fieldData, isActionFooter: true });
  }

  handleDiscard = (e: any) => {
    if (this.state.isNewBudget === true) {
      this.loadState();
      this.setState({
        budgetTypeValue: "Select Model Type",
        isDuplicate: false,
        isActionFooter: false,
        isAutocomplete: true,
        isRPanelHide: false,
        isNewBudget: true,
        isDuplicatePanel: false,
        rangeStatisticData: [
          {
            value: { min: "0", max: "" },
            hours: "00",
            minutes: "00",
            time: "00:00",
          },
        ],
      });
      let new_state = Object.assign({}, this.state);
      let fieldData = new_state.fieldData;
      fieldData.itemName = "Select Statistic";
      fieldData.statisticsNo = "-1";
    } else {
      let new_state = Object.assign({}, this.state);
      let fieldData = new_state.fieldData;
      let request = {} as IBudgetSettingRequestDto;
      request.budgetType = fieldData.budgetTypeID;
      request.departmentID = fieldData.departmentID;
      request.positionID = fieldData.positionID;
      request.hotelID = fieldData.hotelID;
      if (this.state.isDuplicatePanel) {
        request.uniqueno = this.state.isUniqueNo;
        fieldData.positionName = this.state.isPositionName;
        fieldData.departmentName = this.state.isdepartmentName;
        fieldData.uniqueno = this.state.isUniqueNo;
        this.setState({
          isAutocomplete: false,
          isRPanelHide: false,
        });
      } else {
        request.uniqueno = fieldData.uniqueno;
      }

      fieldData.statisticsNo = this.state.discartStatisticsNo;
      fieldData.itemName = this.state.discartStatisticsItemName;
      this.setState({
        fieldData,
        isActionFooter: false,
        isDuplicate: false,
        isRActions: true,
      });
      this.getBudgetSettingDetail(request);
    }
    this.props.refreshleftpanel();
    this.props.removeBlankRow();
  };

  updateRangeStatisticData = (childState: any) => {
    this.setState({
      rangeStatisticData: childState,
      isActionFooter: true,
    });
  };


  formatHourValue = (value) => {
    if (!value?.trim()) {
      return value;
    }
    const timeArray = value?.split(":");
    if (timeArray?.length < 2) {
      return value;
    }
    return timeArray[0] + ":" + (timeArray[1]?.length === 1 ? "0" + timeArray[1] : timeArray[1]);
  }

  handleSave = (e: any) => {
    this.setState({ isSaveDisaled: false });
    let request = {} as IBudgetSettingDto;
    let tableData = this.state.tableDate;
    if (this.state.fieldData.budgetTypeID === "") {
      Utils.toastError("Please select Model Type", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "BudgetSettingRightPanel",
      });
      return;
    }
    if (this.state.fieldData.positionName === "") {
      Utils.toastError("Please select position name.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "BudgetSettingRightPanel",
      });
      return;
    }

    if (this.state.fieldData.budgetTypeID === "FactorStatistic") {
      if (this.state.fieldData.itemName === "Select Statistic") {
        Utils.toastError("Please select Statistic", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        });
        return;
      }

      if (this.state.fieldData.value.length > 0) {
        if (this.state.fieldData.value.includes(":")) {
          const TimeArry = this.state.fieldData.value.split(":");
          if (
            isNaN(Number(TimeArry[0])) ||
            isNaN(Number(TimeArry[1])) ||
            TimeArry[0].toString().trim().length < 1 ||
            TimeArry[1].toString().trim().length > 2 ||
            TimeArry[1].toString().trim().length < 1
          ) {
            Utils.toastError("Please enter valid time(HH:MM)", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });
            return;
          } else {
            if (+TimeArry[1] > 59) {
              Utils.toastError("Please enter minutes less than 60.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "BudgetSettingRightPanel",
              });
              return;
            }
          }
        } else {
          Utils.toastError("Please enter valid time(HH:MM)", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          return;
        }
      } else {
        Utils.toastError("Please enter time(HH:MM)", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        });
        return;
      }
    }
    request.budgetType = this.state.fieldData.budgetTypeID;
    request.departmentID = this.state.fieldData.departmentID;
    request.hotelID = this.state.fieldData.hotelID;
    request.positionID = this.state.fieldData.positionID;
    request.uniqueno = this.state.fieldData.uniqueno;
    request.value = this.state.fieldData.value !== undefined ?
      this.formatHourValue(this.state.fieldData.value) : "00:00";
    request.statisticsNo = this.state.fieldData.statisticsNo;
    request.isRoomAssign = this.state.fieldData.isRoomAssign;
    request.averageRate = "0";
    request.laborCost = "0";
    if (request.budgetType.toLowerCase() === "DOTW".toLowerCase()) {
      request.dataFrom = "";
    } else {
      request.dataFrom = this.state.fieldData.dataFrom;
    }

    if (this.state.fieldData.notes === undefined) {
      request.notes = "";
    } else {
      request.notes = this.state.fieldData.notes;
    }

    if (
      request.budgetType.toLowerCase() === "MPOR".toLowerCase() ||
      request.budgetType.toLowerCase() === "DOTW".toLowerCase()
    ) {
      if (request.budgetType.toLowerCase() === "DOTW".toLowerCase()) {
        for (let i = 0; i <= tableData.length - 1; i++) {
          if (!tableData[i].value.includes(":")) {
            Utils.toastError("Time format should be in HH:MM", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });
            return;
          }
        }
      }

      if (request.budgetType.toLowerCase() === "MPOR".toLowerCase()) {
        // 
        if(this.state.fieldData.isAlreadyAssigned && request.isRoomAssign){
          if(this.state.isDuplicate){
            Utils.toastError("MPCR Assignable can not be assigned to multiple position, Already assigned to another position", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });
            return; 
          }
          if(request.isRoomAssign !== this.state.laborBudgetSetting.isRoomAssign){
            Utils.toastError("MPCR Assignable can not be assigned to multiple position, Already assigned to another position", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });
            return;
          }
        }
        for (let i = 0; i <= tableData.length - 1; i++) {
          if (tableData[i].value.length === 0) {
            Utils.toastError("Please enter the Service Level value", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });
            return;
          }
        }
      }
      tableData.map((item) => {
        item.itemHHref = "";
        item.itemMMref = "";
        item.itemMPORref = "";
        item.value = this.formatHourValue(item.value);
        item.minutes = item.minutes.trim().length === 1 ? "0" + item.minutes : item.minutes;
        return item;
      });
      request.budgetSettingList = tableData;
      request.value = 0;
      request.statisticsNo = "0";
      request.budgetRageofStatisticsList = {} as any;
      let statisticRange: any = [];
      let data: any = {};
      data.rangeFrom = 0;
      data.rangeTo = 0;
      data.value = 0;
      statisticRange.push(data);

      request.budgetRageofStatisticsList = statisticRange;
    } else if (
      request.budgetType.toLowerCase() === "FactorRevenue".toLowerCase()
    ) {
      if (
        this.state.fieldData.subType === "" ||
        this.state.fieldData.subType == null
      ) {
        Utils.toastError("Please enter Display Name", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        });
        return;
      } else {
        request.subType = this.state.fieldData.subType;
      }
      let chartItems: any = [];
      let selectedItem = this.state.coaSelect;
      if (selectedItem.length > 0) {
        for (let i = 0; i <= selectedItem.length - 1; i++) {
          let data: any = {};
          data.chartNo = selectedItem[i].value;
          chartItems.push(data);
        }
        request.budgetChartList = chartItems;
      } else {
        Utils.toastError("Please select COA(s).", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        });
        return;
      }

      if (this.state.fieldData.laborCost.length > 0) {
        if (Number(this.state.fieldData.laborCost) >= 0) {
          if (isNaN(Number(this.state.fieldData.laborCost))) {
            Utils.toastError("Please enter numeric value for Labor Cost.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });
            return;
          } else {
            if (this.state.fieldData.laborCost.includes(".")) {
              const laborCostArry = this.state.fieldData.laborCost.split(".");
              if (laborCostArry[0].length > 3) {
                Utils.toastError(
                  "Please enter maximum three digits before decimal for Labor Cost.",
                  {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                    // // containerId: "BudgetSettingRightPanel",
                  }
                );
                return;
              }
              if (laborCostArry[1].length > 2) {
                Utils.toastError("Please enter maximum two digits after decimal.", {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                  // // containerId: "BudgetSettingRightPanel",
                });
                return;
              }
            } else {
              if (this.state.fieldData.laborCost.length > 3) {
                Utils.toastError("Please enter maximum three digits", {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                  // // containerId: "BudgetSettingRightPanel",
                });
                return;
              }
            }
          }
        } else {
          Utils.toastError("Please enter positive value for Labor Cost.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          return;
        }
      } else {
        Utils.toastError("Please enter numeric value for Labor Cost.", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        });
        return;
      }

      if (this.state.fieldData.averageRate.length > 0) {
        if (Number(this.state.fieldData.averageRate) >= 0) {
          if (isNaN(Number(this.state.fieldData.averageRate))) {
            Utils.toastError("Please enter numeric value for Average Rate.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });
            return;
          } else {
            if (this.state.fieldData.averageRate.includes(".")) {
              const averageRateArry = this.state.fieldData.averageRate.split(
                "."
              );
              if (averageRateArry[0].length > 3) {
                Utils.toastError(
                  "Please enter maximum three digits before decimal.",
                  {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                    // // containerId: "BudgetSettingRightPanel",
                  }
                );
                return;
              }
              if (averageRateArry[1].length > 2) {
                Utils.toastError("Please enter maximum two digits after decimal.", {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                  // // containerId: "BudgetSettingRightPanel",
                });
                return;
              }
            } else {
              if (this.state.fieldData.averageRate.length > 3) {
                Utils.toastError("Please enter maximum three digits.", {
                  // // position: toast.POSITION.BOTTOM_RIGHT,
                  // // containerId: "BudgetSettingRightPanel",
                });
                return;
              }
            }
          }
        } else {
          Utils.toastError("Please enter positive value for Average Rate.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          return;
        }
      } else {
        Utils.toastError("Please enter numeric value for Average Rate.", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        });
        return;
      }

      request.value = 0;
      request.statisticsNo = "0";
      request.averageRate = this.state.fieldData.averageRate;
      request.laborCost = this.state.fieldData.laborCost;
      request.revenueType = "FactorRevenue";
    }

    if (request.budgetType === "RangeStatistic") {
      if (
        this.state.fieldData.statisticsNo === "" ||
        this.state.fieldData.statisticsNo === "-1"
      ) {
        Utils.toastError("Please select statistic", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        });
        this.setState({ loaderTrue: false });
        return;
      }
      let tabRangeStatisticData = this.state.rangeStatisticData;
      let StatisticData: any = [];
      for (let i = 0; i <= tabRangeStatisticData.length - 1; i++) {
        let rangeFrom = tabRangeStatisticData[i].value.min;
        let time = tabRangeStatisticData[i].time;
        let rangeTo = tabRangeStatisticData[i].value.max;
        time = time.toString().replace("undefined", "0");
        let data: any = {};
        if (
          time === "" ||
          time === ":" ||
          time === "NaN" ||
          time === undefined
        ) {
          Utils.toastError("Time  can't be blank", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          this.setState({ loaderTrue: false });
          return;
        }
        data.rangeFrom = rangeFrom;
        data.rangeTo = rangeTo;
        data.value = time.toString().replace(":", ".");
        if (
          rangeFrom === "" ||
          rangeFrom === "NaN" ||
          rangeFrom === undefined
        ) {
          Utils.toastError("Min range value can not be blank.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          this.setState({ loaderTrue: false });
          return;
        }
        if (
          rangeTo === "" ||
          rangeTo === "NaN" ||
          rangeTo === undefined ||
          rangeFrom === rangeTo ||
          rangeTo === 0
        ) {
          Utils.toastError("Max range value can not be blank, zero or same.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          this.setState({ loaderTrue: false });
          return;
        }
        if (
          rangeTo === "" ||
          rangeTo === "NaN" ||
          rangeTo === undefined ||
          rangeFrom === rangeTo ||
          rangeTo === 0
        ) {
          Utils.toastError("Max range value can not be blank, zero or same.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          this.setState({ loaderTrue: false });
          return;
        }
        if (isNaN(rangeTo)) {
          Utils.toastError("Max range value can not be blank.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          this.setState({ loaderTrue: false });
          return;
        }
        if (isNaN(rangeFrom)) {
          Utils.toastError("Min range value can not be blank.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          this.setState({ loaderTrue: false });
          return;
        }
        if (data.value === "" || data.value === undefined) {
          Utils.toastError("Time  can't be blank", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          this.setState({ loaderTrue: false });
          return;
        }
        StatisticData.push(data);
      }
      request.budgetRageofStatisticsList = StatisticData;
    }

    if (request.budgetType === "RangeRevenue") {
      request.revenueType = "RangeRevenue";
      request.value = 0;
      request.statisticsNo = "0";
      if (
        this.state.fieldData.subType === "" ||
        this.state.fieldData.subType == null
      ) {
        Utils.toastError("Please enter Display Name", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        });
        return;
      } else {
        request.subType = this.state.fieldData.subType;
      }

      let chartItems: any = [];
      let selectedItem = this.state.coaSelect;

      if (selectedItem.length > 0) {
        for (let i = 0; i <= selectedItem.length - 1; i++) {
          let data: any = {};
          data.chartNo = selectedItem[i].value;
          chartItems.push(data);
        }
        request.budgetChartList = chartItems;
      } else {
        Utils.toastError("Please select COA(s).", {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "BudgetSettingRightPanel",
        });
        return;
      }

      let tabRangeStatisticData = this.state.rangeStatisticData;
      let StatisticData: any = [];
      for (let i = 0; i <= tabRangeStatisticData.length - 1; i++) {
        let rangeFrom = tabRangeStatisticData[i].value.min;
        let time = tabRangeStatisticData[i].time;
        let rangeTo = tabRangeStatisticData[i].value.max;
        time = time.toString().replace("undefined", "0");
        let data: any = {};

        if (
          time === "" ||
          time === ":" ||
          time === "NaN" ||
          time === undefined
        ) {
          Utils.toastError("Time  can't be blank", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          return;
        }

        data.rangeFrom = rangeFrom;
        data.rangeTo = rangeTo;
        data.value = time.toString().replace(":", ".");

        if (
          rangeFrom === "" ||
          rangeFrom === "NaN" ||
          rangeFrom === undefined
        ) {
          Utils.toastError("Min range value can not be blank.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          return;
        }

        if (
          rangeTo === "" ||
          rangeTo === "NaN" ||
          rangeTo === undefined ||
          rangeFrom === rangeTo
        ) {
          Utils.toastError("Max range value can not be blank, zero or same.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          return;
        }

        if (isNaN(rangeTo)) {
          Utils.toastError("Max range value can not be blank.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          this.setState({ loaderTrue: false });
          return;
        }
        if (isNaN(rangeFrom)) {
          Utils.toastError("Min range value can not be blank.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          this.setState({ loaderTrue: false });
          return;
        }

        if (data.value === "" || data.value === undefined) {
          Utils.toastError("Time  can't be blank", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "BudgetSettingRightPanel",
          });
          return;
        }

        StatisticData.push(data);
      }

      request.budgetRageofStatisticsList = StatisticData;
    }

    this.setState({ isSaveDisaled: true });
    this.setState({ loaderTrue: true });
    LaborBudgetSetting.SaveLaborBudgetSetting(request)
      .then(async (result: any | null) => {
        if (result != null) {
          let msg = "";
          let data = result.result as any;
          if (data.saveStatus === "Success") {
            this.props.handleCalculatedState();
            let requestUniqueno = request.uniqueno;

            if (requestUniqueno === 0) {
              msg = "Model saved successfully.";
            } else {
              msg = "Model updated successfully";
            }

            let newrequest = {} as IBudgetSettingRequestDto;
            newrequest.budgetType = this.state.fieldData.budgetTypeID;
            newrequest.departmentID = this.state.fieldData.departmentID;
            newrequest.positionID = this.state.fieldData.positionID;
            newrequest.hotelID = this.state.fieldData.hotelID;
            newrequest.uniqueno = this.state.fieldData.uniqueno;
            this.getBudgetSettingDetail(newrequest);

            toast.success(msg, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });

            let new_state = Object.assign({}, this.state);
            let fieldData = new_state.fieldData;
            fieldData.uniqueno = data.uniqueno;


            this.setState({
              fieldData,
              budgetTypeValue: "Select Model Type",
              isAutocomplete: true,
              isNewBudget: true,
              isRPanelHide: true,
              isRActions: true,
              isDuplicate: false,
              discartStatisticsNo: request.statisticsNo,
              discartStatisticsItemName: fieldData.itemName,
              isActionFooter: false,
            });
            this.props.refreshleftpanel();
            this.props.removeBlankRow();
            this.props.isBydefaultSelectedRow(data.uniqueno);
            this.props.isShowAlertBox();
          } else {
            Utils.toastError(data.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });
          }
        }
        this.setState({
          loaderTrue: false,
        });
        this.setState({ isSaveDisaled: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        this.setState({ isSaveDisaled: false });
        reject();
      });
  };

  HideRightPanel() {
    this.setState({ isRPanelHide: true });
  }

  handleDelete = (e: any) => {
    let request = {} as IBudgeSettingtDeleteRequestDto;
    request.budgetType = this.state.fieldData.budgetTypeID;
    request.hotelID = this.state.fieldData.hotelID;
    request.uniqueno = this.state.fieldData.uniqueno;
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this model?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.DeleteBudget(request),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
    });
  };

  DeleteBudget(request: IBudgeSettingtDeleteRequestDto) {
    this.setState({ loaderTrue: true });
    LaborBudgetSetting.DeleteBudgetSetting(request)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.success) {
            if (request.budgetType === "RangeRevenue") {
              let chartItems: any = [];
              chartItems = this.state.coaSelect;
              chartItems.forEach((element) => {
                chartItems.splice(element);
              });
              this.setState({ coaSelect: chartItems });
              this.multiselectChild.current.updateSavedItem([]);
            }

            toast.success(result.result, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });
            this.loadState();
            this.props.refreshleftpanel();
          } else {
            Utils.toastError(result.result, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "BudgetSettingRightPanel",
            });
          }
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  }

  loadState() {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.departmentID = 0;
    fieldData.departmentName = "";
    fieldData.positionID = 0;
    fieldData.positionName = "";
    fieldData.notes = "";
    fieldData.budgetType = "";
    fieldData.budgetTypeID = "";
    fieldData.itemName = "";
    fieldData.statisticsNo = "";
    fieldData.hours = "00";
    fieldData.minutes = "00";
    fieldData.value = 0;
    fieldData.isRoomAssign = false;
    fieldData.averageRate = "0.00";
    fieldData.laborCost = "0.00";
    fieldData.discartStatisticsNo = "";
    fieldData.discartStatisticsNo = "0";
    this.setState({
      fieldData,
      budgetTypeValue: "Select Model Type",
      isAutocomplete: true,
      isNewBudget: true,
      isRPanelHide: true,
    });
  }

  handleDuplicate = (event: any) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.uniqueno = 0;
    fieldData.positionID = 0;
    fieldData.positionName = "";
    let hotelID = this.state.fieldData.hotelID;
    this.props.isDuplicateRow();
    this.setState(
      {
        isDuplicate: true,
        fieldData: fieldData,
        isAutocomplete: true,
        isRPanelHide: false,
        isDuplicatePanel: true,
        isRActions: false,
      },
      () => {
        const timer = setTimeout(() => {
          this.autoFocusTextInput?.current?.focus();
        }, 100);
        return () => clearTimeout(timer);
      }
    );
    this.bindAutocomplete(hotelID);
  };
  componentDidMount() {
    LaborBudgetSetting.getlaborStatistic()
      .then(async (result: any | null) => {
        let staticListData = [];
        if (result != null) {
          let staticData = result as [];
          staticData.map((res, index) => staticListData.push(res));
          this.setState({ staticList: staticListData });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });

    chart
      .getChartDetails("BudgetChart")
      .then(async (result: any | null) => {
        if (result != null) {
          this.setState({ coaList: result });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  staticDropdownChange(e: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.itemName = e.itemName;
    fieldData.statisticsNo = e.itemUniqueNo;
    this.setState({ fieldData, isActionFooter: true });
  }

  updateMultiSelectItem = (coaSelectedItem: any) => {
    this.setState({ coaSelect: coaSelectedItem });
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.setState({ fieldData });
  };
  dataChange = () => {
    this.setState({ isActionFooter: true });
  };

  labelNewBudget = (event: any) => {
    if (this.state.isNewBudget || this.state.isDuplicate) {
      let new_state = Object.assign({}, this.state);
      let fieldData = new_state.fieldData;
      fieldData.positionName = "";
      this.setState({
        isRPanelHide: false,
        isDuplicatePanel: true,
        isRActions: false,
      });
      this.setState({ fieldData: fieldData, isAutocomplete: true }, () => {
        const timer = setTimeout(() => {
          this.autoFocusTextInput.current.focus();
        }, 100);
        return () => clearTimeout(timer);
      });
    }
  };



  handleFocus = (event) => { event.target.focus(); event.target.select(); }
  render() {
    let loadShow: any;
    const budgetTypeSelect = (eventKey: any) => {
      this.setState({ budgetTypeValue: eventKey });
    };
    const dataFromSelect = (eventKey: any) => {
      this.setState({ dataFromValue: eventKey });
    };
    let departmentName: any;
    if (
      this.state.isDuplicatePanel === true &&
      this.state.fieldData.positionName === ""
    ) {
      departmentName = "";
    } else {
      departmentName = this.state.fieldData.departmentName;
    }
    let budgetType = this.state.fieldData.budgetType;
    if(budgetType === "MPOR"){
      budgetType = "MPCR"
    }
    if (this.state.loaderTrue) {
      loadShow = <PageLoader />;
    }

    let columns: any = [];
    if (this.state.fieldData.budgetTypeID === "DOTW") {
      columns = [
        {
          dataField: "text",
          text: "Day",
        },
        {
          dataField: "value",
          text: "Time",
          formatter: (cell: any, row: any, rowIndex: any, colIndex: any) => (
            <div className="d-flex justify-content-center">
              <div className="d-flex time-picker modf-input align-items-center">
                <Form.Control
                  //ref={row.itemHHref}
                  key={rowIndex + "HH" + cell.index}
                  id={rowIndex + "HH"}
                  type="text"
                  pattern="\d*"
                  // onFocus={this.handleFocus}
                  disabled={!this.props.manageBudgets}
                  placeholder="00"
                  value={row.hours}
                  maxLength={3}
                  onChange={(event: any) => this.changeHH(event, row, rowIndex)}
                  onBlur={(event: any) => this.onBlurHH(event, row, rowIndex)}
                />
                <span>:</span>
                <Form.Control
                  // ref={row.itemMMref}
                  key={rowIndex + "MM" + cell.index}
                  id={rowIndex + "MM"}
                  type="text"
                  pattern="\d*"
                  maxLength={2}
                  placeholder="00"
                  disabled={!this.props.manageBudgets}
                  value={row.minutes}
                  // onFocus={this.handleFocus}
                  onChange={(event: any) => this.changeMM(event, row, rowIndex)}
                  onBlur={(event: any) => this.onBlurMM(event, row, rowIndex)}
                />
              </div>
            </div>
          ),
        },
      ];
    } else if (this.state.fieldData.budgetTypeID === "MPOR") {
      columns = [
        {
          dataField: "text",
          text: "Cleaning Service Level",
        },
        {
          dataField: "value",
          text: "MPCR",
          formatter: (cell: any, row: any, rowIndex: any, colIndex: any) => (
            <Form.Control
              ref={row.itemMPORref}
              type="text"
              pattern="\d*"
              placeholder="00"
              maxLength={4}
              disabled={!this.props.manageBudgets}
              value={row.value}
              onChange={(event: any) => this.changeMPOR(event, row, rowIndex)}
              onBlur={(event: any) => this.onBlurMPOR(event, row, rowIndex)}
            />
          )
        },
      ];
    }
    return (
      <>
        {/* <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"BudgetSettingRightPanel"}
        /> */}
        {!this.state.isRPanelHide && (
          <div className="right-panel">
            {loadShow}
            <div className="pos-header d-flex align-content-center flex-wrap">
              {this.state.isAutocomplete && (
                <div className="search-header mr-auto auto-complete">
                  <Autocomplete
                    getItemValue={this.getItemValue}
                    shouldItemRender={(
                      item: { positionName: string },
                      value: string
                    ) =>
                      item.positionName
                        .toLowerCase()
                        .indexOf(value.toLowerCase()) > -1
                    }
                    items={this.state.positionData}
                    renderItem={this.renderItem}
                    value={this.state.fieldData.positionName}
                    onSelect={this.onAutocompleteSelect}
                    onChange={this.onAutocompleteChange}
                    ref={this.autoFocusTextInput}
                    autoFocus
                    inputProps={{ placeholder: "Select Position" }}
                  />
                </div>
              )}
              {!this.state.isAutocomplete && (
                <div className="pos-ttl mr-auto" onClick={this.labelNewBudget}>
                  <EllipsisWithTooltip placement="bottom">
                    {this.state.fieldData.positionName}
                  </EllipsisWithTooltip>
                </div>
              )}
              {this.props.manageBudgets && (
                <>
                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.handleDuplicate}>
                        <img src={Duplicate} alt="Duplicate" /> Duplicate
                    </Dropdown.Item>
                      <Dropdown.Item onClick={this.handleDelete}>
                        <img src={Delete} alt="Delete" /> Delete
                    </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}

            </div>

            {(!this.state.isAutocomplete ||
              this.state.isDuplicatePanel === true) && (
                <div
                  className={
                    this.state.isActionFooter ? "pos-body dataChange" : "pos-body"
                  }
                >
                  <Form>
                    <Form.Group as={Row} controlId="department">
                      <Form.Label column sm="3">
                        {" "}
                      Department{" "}
                      </Form.Label>
                      <div className="col-sm-9">
                        <div className="form-control-plaintext">
                          {" "}
                          {departmentName}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group as={Row} controlId="notes">
                      <Form.Label column sm="3">
                        {" "}
                      Notes{" "}
                      </Form.Label>
                      <Col sm="9">
                        {/* {this.props.manageBudgets? ( */}
                        <Form.Control
                          className="removeBorder"
                          as="textarea"
                          disabled={!this.props.manageBudgets}
                          value={this.state.fieldData.notes}
                          onChange={(e) => this.onchangeNotes(e)}
                          placeholder={this.props.manageBudgets ? "Add notes..." : ""}
                          maxLength={200}
                        />
                        {/* ):this.state.fieldData.notes} */}

                      </Col>
                      <Col sm="12">
                        <div className="border-line"></div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="budget">
                      <Form.Label column sm="3">
                        {" "}
                      Model Type{" "}
                      </Form.Label>

                      {!this.state.isNewBudget && (
                        <div className="col-sm-9">
                          <div className="form-control-plaintext">
                            {budgetType}
                          </div>
                        </div>
                      )}
                      <Col sm="9">
                        {this.state.isNewBudget && (
                          <Dropdown onSelect={budgetTypeSelect}>
                            <Dropdown.Toggle id="dropdown-budgetType">
                              {this.state.budgetTypeValue ===
                                "Select Model Type" && (
                                  <span className="placeholder">
                                    {this.state.budgetTypeValue}
                                  </span>
                                )}
                              {this.state.budgetTypeValue !==
                                "Select Model Type" && (
                                  <span>{this.state.budgetTypeValue === "MPOR" ? "MPCR":this.state.budgetTypeValue }</span>
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {this.state.budgetTypeList.map(
                                (item: any, idx: any) => (
                                  <Dropdown.Item
                                    eventKey={item.budgetType}
                                    onClick={() =>
                                      this.handleDropdownChange(item)
                                    }
                                  >
                                    {item.budgetType === "MPOR" ? "MPCR":item.budgetType }
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </Col>
                    </Form.Group>

                    {this.state.fieldData.budgetTypeID !== "DOTW" &&
                      this.state.budgetTypeValue !== "Select Model Type" && (
                        <Form.Group as={Row} controlId="dataFrom">
                          <Form.Label column sm="3">
                            {" "}
                          Data From{" "}
                          </Form.Label>
                          <Col sm="9">
                            <Dropdown onSelect={dataFromSelect}>
                              <Dropdown.Toggle id="dropdown-budgetType" disabled={!this.props.manageBudgets}>
                                {this.state.dataFromValue}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {this.state.dataFromList.map(
                                  (item: any, idx: any) => (
                                    <Dropdown.Item
                                      eventKey={item.dataFrom}
                                      onClick={() =>
                                        this.dataFromDropdownChange(item)
                                      }
                                    >
                                      {item.dataFrom}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        </Form.Group>
                      )}

                    {this.state.fieldData.budgetTypeID === "MPOR" && (
                      <Form.Group as={Row} controlId="assignable">
                        <Form.Label column sm="3">
                          Assignable
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={"tooltip-Assign"}>
                                If set, the position will be added to the
                                Housekeeping Page.
                            </Tooltip>
                            }
                          >
                            <span>
                              <InfoTooltip />{" "}
                            </span>
                          </OverlayTrigger>
                        </Form.Label>
                        <Col sm="9">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label=""
                            disabled={!this.props.manageBudgets}
                            checked={this.state.fieldData.isRoomAssign}
                            onChange={(e: any) => this.onchangeAssignable(e)}
                          />
                        </Col>
                      </Form.Group>
                    )}
                    {(this.state.fieldData.budgetTypeID === "RangeStatistic" ||
                      this.state.fieldData.budgetTypeID ===
                      "FactorStatistic") && (
                        <Form.Group as={Row} controlId="statistic">
                          <Form.Label column sm="3">
                            Statistic
                      </Form.Label>
                          <Col sm="9">
                            <Dropdown >
                              <Dropdown.Toggle id="dropdown-hid" disabled={!this.props.manageBudgets}>
                                {this.state.fieldData.itemName ===
                                  "Select Statistic" && (
                                    <span className="placeholder">
                                      {this.state.fieldData.itemName}
                                    </span>
                                  )}
                                {this.state.fieldData.itemName !==
                                  "Select Statistic" && (
                                    <span>{this.state.fieldData.itemName}</span>
                                  )}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {this.state.staticList.map(
                                  (item: any, idx: any) => (
                                    <Dropdown.Item
                                      eventKey={item.itemName}
                                      onClick={() =>
                                        this.staticDropdownChange(item)
                                      }
                                    >
                                      {item.itemName}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Col>
                        </Form.Group>
                      )}

                    {(this.state.fieldData.budgetTypeID === "FactorRevenue" ||
                      this.state.fieldData.budgetTypeID === "RangeRevenue") && (
                        <Form.Group as={Row} controlId="coa">
                          <Form.Label column sm="3">
                            {" "}
                        Display Name{" "}
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id={"tooltip-displayName"}>
                                  This is the name for a model that will be shown on the forecast page based on the COA number.
                            </Tooltip>
                              }
                            >
                              <span>
                                <InfoTooltip />{" "}
                              </span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Col sm="9">

                            <Form.Control
                              className="removeBorder"
                              as="textarea"
                              disabled={!this.props.manageBudgets}
                              value={this.state.fieldData.subType}
                              onChange={(e) => this.onchangeDisplayName(e)}
                              placeholder={this.props.manageBudgets ? "Display name..." : ""}
                              maxLength={50}
                            />


                          </Col>

                          <Form.Label column sm="3">
                            COA(s)
                      </Form.Label>
                          <Col sm="9">

                            <MultiSelectSearch
                              ref={this.multiselectChild}
                              itemList={this.state.coaList}
                              updateMultiSelectItem={this.updateMultiSelectItem.bind(
                                this
                              )}
                              managePermission={this.props.manageBudgets}
                              dataChange={this.dataChange.bind(this)}

                              isSelectAll={false}
                              filtertitle={"Filter COA's"}
                            />
                          </Col>
                        </Form.Group>
                      )}

                    {this.state.fieldData.budgetTypeID === "FactorRevenue" && (
                      <Row>
                        <Col className="FactorConfiguration">
                          <div className="title">Factor Configuration</div>
                          <div className="selection">
                            <Form.Group as={Row} controlId="LaborCost">
                              <Form.Label column sm="5">
                                Labor Cost
                              </Form.Label>
                              <Col sm="7">
                                <div className="percentage">%</div>
                                <Form.Control
                                  type="text"
                                  value={this.state.fieldData.laborCost}
                                  onChange={this.handleLaborCost}
                                  maxLength={6}
                                  disabled={!this.props.manageBudgets}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="AverageRate">
                              <Form.Label column sm="5">
                                Average Rate
                              </Form.Label>
                              <Col sm="7">
                                <div className="doller">$</div>
                                <Form.Control
                                  className="modify-pad"
                                  type="text"
                                  disabled={!this.props.manageBudgets}
                                  value={this.state.fieldData.averageRate}
                                  onChange={this.handleLaborAverage}
                                  maxLength={6}
                                />
                              </Col>
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                    )}

                    {this.state.fieldData.budgetTypeID === "FactorStatistic" && (
                      <Form.Group as={Row} controlId="factortime">
                        <Form.Label column sm="3">
                          {" "}
                        Time{" "}
                        </Form.Label>
                        <Col sm="9">
                          <div className="d-flex">
                            {/* <div className="d-flex time-picker modf-input align-items-center">
                              <Form.Control
                                type="text"
                                pattern="\d*"
                                placeholder="00"
                                value={this.state.fieldData.hours}
                                disabled={!this.props.manageBudgets}
                                onFocus={this.handleFocus}
                                onChange={(event: any) =>
                                  this.onchangeFactorTime(event, "HH")
                                }
                                onBlur={(event: any) =>
                                  this.onBlurFactorTime(event, "HH")
                                }
                              />
                              <span>:</span>
                              <Form.Control
                                type="text"
                                pattern="\d*"
                                maxLength={2}
                                placeholder="00"
                                disabled={!this.props.manageBudgets}
                                value={this.state.fieldData.minutes}
                                onFocus={this.handleFocus}
                                onChange={(event: any) =>
                                  this.onchangeFactorTime(event, "MM")
                                }
                                onBlur={(event: any) =>
                                  this.onBlurFactorTime(event, "MM")
                                }
                              />
                            </div> */}
                            <div className="d-flex align-items-center">
                              <Form.Control
                                className="removeBorder min-digits"
                                type="text"
                                pattern="\d*"
                                placeholder="00"
                                maxLength={4}
                                value={this.state.fieldData.hours}
                                disabled={!this.props.manageBudgets}
                                onFocus={this.handleFocus}
                                onChange={(event: any) =>
                                  this.onchangeFactorTime(event, "HH")
                                }
                                onBlur={(event: any) =>
                                  this.onBlurFactorTime(event, "HH")
                                }
                              />
                              <div>{"Minutes"}</div>
                            </div>

                          </div>
                        </Col>
                      </Form.Group>
                    )}
                    {(this.state.fieldData.budgetTypeID === "RangeStatistic" ||
                      this.state.fieldData.budgetTypeID === "RangeRevenue") && (
                        <Slider
                          rows={this.state.rangeStatisticData}
                          header={this.state.header}
                          updateRangeStatisticData={this.updateRangeStatisticData.bind(
                            this
                          )}
                          manageBudgets={this.props.manageBudgets}
                          budgetType={this.state.fieldData.budgetTypeID}
                        />
                      )}
                    {(this.state.fieldData.budgetTypeID === "MPOR" ||
                      this.state.fieldData.budgetTypeID === "DOTW") && (
                        <div className="mpor-dotw">
                          <BootstrapTable
                            id="tblmpordotw"
                            keyField="text"
                            data={this.state.tableDate}
                            columns={columns}
                            rowEvents={this.rowEvents}
                            hover
                          />
                        </div>
                      )}
                  </Form>

                  {this.state.isActionFooter && this.props.manageBudgets && (
                    <div className="fixed-action">
                      <div className="d-flex align-content-center flex-wrap">
                        <div className="mr-auto message">
                          <span>You have unsaved changes</span>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary btn-discard"
                          onClick={this.handleDiscard}
                        >
                          Discard
                      </button>

                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleSave}
                          disabled={this.state.isSaveDisaled}
                        >
                          Save Model
                      </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
      </>
    );
  }
}
