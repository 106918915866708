import React from "react";
import { Button, Col, Form, Modal, Row, OverlayTrigger, Tooltip  } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../../Common/StaticArrays";
import Calendar from "react-calendar";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import { IHotelWisePayRollRequest } from "../../../Common/Contracts/IHotelWisePayRoll";
import { IHotelWisePayrollExludeFPayrollRequest } from "../../../Common/Contracts/IHotelWisePayrollExludeFPayroll";
import _ from "lodash";
import ReactTooltip from "react-tooltip";


export class AddHotelWiseSetting extends React.Component<any, any> {
  private payRateOptions = [
    { label: "Per Hour", value: "Per Hour" },
    { label: "Per Room", value: "Per Room" },
  ];

  private withHoldingRates = [
    { label: "0%", value: "0%" },
    { label: "0.8%", value: "0.8%" },
    { label: "1.3%", value: "1.3%" },
    { label: "1.8%", value: "1.8%" },
    { label: "2.7%", value: "2.7%" },
    { label: "3.6%", value: "3.6%" },
    { label: "4.2%", value: "4.2%" },
    { label: "5.1%", value: "5.1%" },
  ]

  private yesNoOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" }
  ]

  private taxCities = [
    { label: "Kansas City", value: "Kansas City" },
    { label: "St. Louis", value: "St. Louis" }
  ]




  private hotelWiseSttingFormSchema = Joi.object({
    uniqueNo: Joi.string().allow(""),
    hid: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select EHID" };
        });
      }),
    status: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Status" };
        });
      }),
    coa: Joi.number()
      .min(1)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Position" };
        });
      }),
    regularRate: Joi.number()
      .min(0.00)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "number.base":
              return {
                message:
                  "Please enter Regular Rate",
              };
            case "number.min":
              return {
                message:
                  "Regular Rate should not be less than 0",
              };
          }
        });
        return [_errors[0]];
      }),
    holidayProbPeriod: Joi.string().allow("").optional(),
    payRate: Joi.string().allow("").optional(),
    annualSal: Joi.allow("").optional(),
    terDate: Joi.string().optional().allow(""),
    unemployeeState: Joi.string().optional().allow(""),
    withHoldingState: Joi.string().optional().allow(""),
    // stateFilling: Joi.string().optional().allow(""),
    workingState2: Joi.string().optional().allow(""),
    empCode: Joi.string().allow("").optional(),
    totalHrs: Joi.string().optional().allow(""),
    isPrimary_Property: Joi.optional().allow(""),
    payrollid: Joi.string().allow("").optional(),

    // .min(0.01)
    // .required()
    // .error((errors) => {
    //   const _errors = errors.map((error) => {
    //     if (error?.message) {
    //       return;
    //     }
    //     switch (error.type) {
    //       case "number.base":
    //         return {
    //           message:
    //             "Please enter Regular Rate in numeric only",
    //         };
    //       case "number.min":
    //         return {
    //           message:
    //             "Please enter Regular Rate greater than 0",
    //         };
    //     }
    //   });
    //   return [_errors[0]];
    // })
  });

  state: any = {
    hotelWiseSettingFormData: {
      uniqueNo: this.props?.hotelWisePayrollRowEdit?.uniqueNo || "",
      hid: +this.props?.hotelWisePayrollRowEdit?.hid || 0,
      status: this.props?.hotelWisePayrollRowEdit?.status || "Active",
      coa: this.props?.hotelWisePayrollRowEdit?.coa || 0,
      regularRate:
        (+this.props?.hotelWisePayrollRowEdit?.salaried > 0 &&
          +this.props?.hotelWisePayrollRowEdit?.annualSal > 0)
          || (
            this.props?.employeeType !== "Salaried" &&
            this.props?.employeeType !== "SalariedNonExempt"

          )

          ?
          (this.props?.hotelWisePayrollRowEdit?.regularRate || "") : "",
      payRate: this.props?.hotelWisePayrollRowEdit?.payRate || "",
      annualSal: this.props?.hotelWisePayrollRowEdit?.annualSal
        ? Utils.currencyFormat(this.props?.hotelWisePayrollRowEdit?.annualSal)
        : this.props?.hotelWisePayrollRowEdit?.annualSal
      ,
      terDate: this.props?.hotelWisePayrollRowEdit?.terDate,
      unemployeeState:
        this.props?.hotelWisePayrollRowEdit?.unemploymentState || "",
      withHoldingState: this.props?.hotelWisePayrollRowEdit?.withholdingState ||
        this.props?.employeeState || "",
      // stateFilling:
      //   this.props?.hotelWisePayrollRowEdit?.stateFillingStatus || "",
      empCode: this.props?.hotelWisePayrollRowEdit?.empLoyee_Code || "",
      totalHrs: this.props?.hotelWisePayrollRowEdit?.salaried ?

        this.props?.hotelWisePayrollRowEdit?.salaried?.toString()?.replace(".", ":") : "",
      workingState2: this.props?.hotelWisePayrollRowEdit?.workingState2 || "",
      isPrimary_Property: typeof this.props?.hotelWisePayrollRowEdit?.isPrimary_Property === "boolean" ? this.props?.hotelWisePayrollRowEdit?.isPrimary_Property : null,
      holidayProbPeriod:this.props?.hotelWisePayrollRowEdit?.holidayProbPeriod || 0,
      payrollid:this.props?.hotelWisePayrollRowEdit?.mappingId || ""  ,

  
    },
    positionList: [],
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    calenderShow: false,
    inputPosition: 0,
    hotelsLoaded: false,
    positionAssinableData: {},
    hotelList: [],
    showPayRateDDL: false,
    totalPayrollMinutes: 0,
    statusFilingStatusList: [],
    speacialRates: [],
    localAndStateTaxesFormFields: []
  };



  componentDidMount = () => {
    this.getHotels();
   

    if (this.props?.hotelWisePayrollRowEdit?.salaried) {

      this.onHourEntryBlur(
        (this.props?.hotelWisePayrollRowEdit?.salaried?.toString().split(".")[1]?.length === 1 ?

          this.props?.hotelWisePayrollRowEdit?.salaried?.toString()?.replace(".", ":") + "0" :
          this.props?.hotelWisePayrollRowEdit?.salaried?.toString()?.replace(".", ":")), 0)
    }
    if (+this.props?.hotelWisePayrollRowEdit?.uniqueNo > 0 && this.props?.hotelWisePayrollRowEdit?.withholdingState) {
      this.getUserStateFillStatus(
        this.props?.hotelWisePayrollRowEdit?.hid,
        this.props?.hotelWisePayrollRowEdit?.withholdingState
      );
      if (this.props?.employeeType === "ExcludefromPayroll") {
        this.checkIfPositionIsAssignable();
      }
      this.validateHotelWiseFormSchema();


      this.getUserSpecialRates(+this.props?.hotelWisePayrollRowEdit?.hid);






    }
    this.getHotelStateAndLocalTax();
  };

  getHotelStateAndLocalTax = (callback = () => { }) => {
    debugger;
    if (!this.props?.showLocalTaxesFields) {




      return;
    }
    this.setState({ localAndStateTaxesFormFields: [] });
    UserManagement.GetHotelStateAndLocalTax(
      [{ stateCode: this.state.hotelWiseSettingFormData.withHoldingState, hid: +this.props?.hotelWisePayrollRowEdit?.hid }]
      , this.props?.userUniqueNo)
      .then((localAndStateTaxesFormFields: any[]) => {

        let _localAndStateTaxesFormFields = localAndStateTaxesFormFields
          .map(item => {

            if (item.controlType === "checkbox") {
              return { ...item, fieldValue: item.fieldValue === "true" ? true : false }
            } else if (item.isCurrency == 1) {
              return { ...item, fieldValue: !item.fieldValue ? "" : Utils.currencyFormat(item.fieldValue) }
            } else {
              return item
            }
          })

        //     if(item.isLocalTax && 
        //       this.props?.hotelWisePayrollRowEdit?.
        //       showLocalTaxFields?.toString()?.toLowerCase() === "yes"){
        //     if(item.isCurrency == 1) {
        //       return {...item, fieldValue: Utils.currencyFormat(item.fieldValue)}
        //     }else{
        //       return item
        //     }
        //   } else if(!item.isLocalTax) {
        //     if(item.isCurrency == 1) {
        //       return {...item, fieldValue: Utils.currencyFormat(item.fieldValue)}
        //     }else{
        //       return item
        //     }
        //   } {
        //     return  null;
        //   }
        // })?.filter(x=>x);


        this.setState({ localAndStateTaxesFormFields: _localAndStateTaxesFormFields }, () => { callback(); this.validateHotelWiseFormSchema() });

      })

  }
  onHourEntryBlur = (value, inputPosition) => {
    const { hotelWiseSettingFormData } = this.state;
    value = value?.toString()?.replace(".", "");
    if (value?.toString()?.includes(":")) {
      const valueArray = value?.split(":");
      if (
        valueArray[0]?.length === 1 ||
        valueArray[1]?.length === 1 ||
        !valueArray[1]
      ) {
        value =
          (valueArray[0]?.toString()?.padStart(2, "0") || "00") +
          ":" +
          (valueArray[1]?.toString()?.padStart(2, "0") || "00");
      }
    } else {
      if (value) {
        value =
          (value.toString()?.padStart(2, "0") || "00") +
          ":" +
          "00";
      }
    }
    hotelWiseSettingFormData.totalHrs = value;
    if (inputPosition > this.state.inputPosition) {
      this.setState({ inputPosition });
    }
    this.setState({ hotelWiseSettingFormData, }, () =>
      this.validateHotelWiseFormSchema()
    );
  };
  getUserSpecialRates = (hid) => {
    this.setState({ isSpecialRateLoaded: false });
    UserManagement.GetUserSpecialRates(this.props?.userUniqueNo, hid)
      .then((speacialRates) => {
        const positionIds = speacialRates.map((item: any) => +item?.positionId);
        if (+this.props?.hotelWisePayrollRowEdit?.hid) {
          //  this.setState({positionList:[]});
          let filterPosition = this.props.positionList.filter(x => +x.hid === +this.props?.hotelWisePayrollRowEdit?.hid && !positionIds?.includes(+x.empCode))
            ?.map((x: any) => {
              x.empCode = x?.empCode?.toString();
              return x;
            });
          this.setState({ positionList: filterPosition?.length > 0 ? filterPosition : [], speacialRates });
        }
      }).catch(() => this.setState({ isSpecialRateLoaded: true }));
  };



  getUserStateFillStatus = (hid, state) => {
    this.setState({ statusFilingStatusList: [] });
    UserManagement.GetUserStateFillStatus(hid, state).then(
      (statusFilingStatusList: any[]) => {
        if (statusFilingStatusList?.length === 0) {
          const statusFilingStatusList = [{ stateFilling: "Not Applicable" }];
          this.setState({ statusFilingStatusList: [] },
            () => this.setState({ statusFilingStatusList }));
        } else {
          if (+this.props?.hotelWisePayrollRowEdit?.uniqueNo > 0 && this.props?.hotelWisePayrollRowEdit?.withholdingState
          ) {
            statusFilingStatusList.push({ stateFilling: "Not Applicable" })
          }

          let _localAndStateTaxesFormFields = _.cloneDeep(this.state?.localAndStateTaxesFormFields)

          _localAndStateTaxesFormFields = _localAndStateTaxesFormFields.map(x => {
            if (x.fieldName == "Filing Status") {
              if (statusFilingStatusList.filter(y => y.stateFilling === x.fieldValue).length == 0) {
                x.fieldValue = ""
              }
            }
            return x
          })

          this.setState({ statusFilingStatusList: [], localAndStateTaxesFormFields: _localAndStateTaxesFormFields },
            () => {
              this.validateHotelWiseFormSchema();
              this.setState({ statusFilingStatusList })
            });
        }
      }
    );
  };
  calenderHide = () => {
    if (this.state.hotelWiseSettingFormData.terDate.length !== 8) {
      this.setState({ hotelWiseSettingFormData: { ...this.state.hotelWiseSettingFormData, terDate: "" } });

    }
    this.setState({ calenderShow: false });
  };

  calenderShow = (): any => {
    this.setState({ calenderShow: true });
  };

  checkIfPositionIsAssignable = () => {
    this.setState({ showPayRateDDL: false });
    UserManagement.CheckIfPositionIsAssignable(
      +this.state.hotelWiseSettingFormData?.coa,
      +this.state.hotelWiseSettingFormData?.hid
    ).then((response) => {
      if (response?.result?.assignRoomStatus === "Yes") {
        this.setState({ showPayRateDDL: true }, () =>
          this.validateHotelWiseFormSchema()
        );
      } else {
        this.validateHotelWiseFormSchema();
      }
      this.setState({ positionAssinableData: response?.result });
    });
  };

  saveHotelPayrollDetails = () => {
    let request: any = {};
    if (this.props?.employeeType === "ExcludefromPayroll") {
      request = {
        ...this.state.hotelWiseSettingFormData,
      } as IHotelWisePayrollExludeFPayrollRequest;
      request.positionId = this.state.hotelWiseSettingFormData?.coa;
      request.uniqueNo = this.props?.hotelWisePayrollRowEdit?.uniqueNo || 0;
      request.holidayProbPeriod =0;

    } else {
      request = {
        ...this.state.hotelWiseSettingFormData,
      } as IHotelWisePayRollRequest;
      request.uniqueNo = this.props?.hotelWisePayrollRowEdit?.uniqueNo || "";
     
    }
    request.mappingId =request?.payrollid || ""
    delete request.payrollid;
    request.userUniqueNo = this.props?.userUniqueNo;
    request.employeeType = this.props?.employeeType;
    request.totalHrs = request.totalHrs ? request.totalHrs?.toString()?.replace(":", ".") : "";
    request.annualSal = request?.annualSal ? Utils.removecurrencyFormat(request?.annualSal) : 0;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const createdBy = storage === null ? 0 : storage.user_UniqueID ;
    request.stateFieldRequest = (this.state?.localAndStateTaxesFormFields || []).map(item => {

      const _item = {
        // id:,
        userUniqeuno: request?.userUniqueNo,
        hid: request?.hid,
        tenantId: item.tenantId,
        fieldId: item.fieldId,
        fieldValue: item.isCurrency == 1 ? (!item.fieldValue || +item.fieldValue === 0) ?
          "" : Utils.removecurrencyFormat(item.fieldValue) : item.fieldValue,
        createdBy: createdBy,
        createdDate: moment()?.format("MM/DD/YYYY")
      }

      if (this.checkIfChildFieldNeedToDisplay(item)) {
        return _item;
      }
      {
        return { ..._item, fieldValue: item?.controlType === "checkbox" ? (_item?.fieldValue == "" ? false : true) : "" };
      }


    })?.filter(x => x);
    if (this.props?.employeeType === "ExcludefromPayroll") {
      request.regularRate = request.regularRate || "";
    }
    
    request.terDate= request.status === "Active"
      ? ""
      : this.state.hotelWiseSettingFormData.terDate;
    this.setState({ isDataSaving: true });
    (this.props?.employeeType === "ExcludefromPayroll"
      ? UserManagement.SaveHotelWisePayrollForExludeFromPayroll(request)
      : UserManagement.SaveHotelPayrollDetails([request])
    )
      .then((result) => {
        this.setState({ isDataSaving: false });

        if (result?.message === "Success") {
          toast.success(
            this.props?.hotelWisePayrollRowEdit?.uniqueNo > 0
              ? "Entity Wise Payroll Updated Successfully"
              : "Entity Wise Payroll Saved Successfully",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addDeduction",
            }
          );
          this.hideModal(true);
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addDeduction",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addDeduction",
        });
      });
  };



  onSearchChange = (value) => {
    const deductionFilters = this.state?.deductions?.filter((x) =>
      x?.deductionName?.toString()?.toLowerCase()?.includes(value)
    );
    this.setState({ deductionFilters });
  };

  filterBy = () => true;
  componentWillUnmount = () => {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }
  hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };
  onFieldChange = (event, inputPosition) => {
    const { hotelWiseSettingFormData, hotelList } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if (fieldName === "regularRate" && +value > 0) {
      if (value.toString()?.split('.')[0].length > 4) {
        return;
      }
    }

    if (fieldName === "holidayProbPeriod" && +value > 0) {
      if (value.toString()?.length > 10) {
        return;
      }
    }
    if(fieldName === "hid"){
      const showPayrollId=this.props.userPayrollMapping?.length > 0 ? this.props.userPayrollMapping.filter(x => x.hid == value).length > 0  : false
      if(showPayrollId){
        const payrollItem=this.props.userPayrollMapping.find(x => x.hid == value)
        hotelWiseSettingFormData.payrollid = payrollItem?.mappingId || ""
      }
    }

    if (fieldName === "hid" && this.props?.isPayrollTab) {
      
      let state = hotelList?.find(x => x.hid === value && x?.nonUS === "No")?.state || "";
      if (state) {
        state = StaticArrays.usaStatesWithFullName?.find(x => x.name === state)?.abbreviation || "";
        state = StaticArrays.withHoldingUnemploymentStates.find(x => x.value === state)?.value || "";
      }
      hotelWiseSettingFormData.unemployeeState = hotelWiseSettingFormData.unemployeeState || state;

    }
    if (fieldName === "totalHrs") {
      value = value?.toString()?.replace(".", "");

      if (value?.toString()?.length === 2 && value?.toString()?.length > hotelWiseSettingFormData.totalHrs?.toString()?.length) {
        value = value + ":";
      }
      const altValue =
        value !== undefined &&
          hotelWiseSettingFormData.totalHrs
          ? value
          : hotelWiseSettingFormData.totalHrs;
      if (!Utils.GetHHMMValidString(value) && value) {
        return;
      }
      value = Utils.GetHHMMValidString(value) || altValue;

      if (value?.toString()?.includes(":")) {
        const valueArray = value?.split(":");

        if (+valueArray[1] > 59) {
          value =
            valueArray[0]?.toString() +
            ":" +
            valueArray[1]?.toString()?.substr(0, 1);
        }
      }
    }

    if (fieldName === "empCode" || fieldName === "holidayProbPeriod") {
      Utils.AllowOnlyPositiveNumnberInputWithoutDot(event)
      if (value?.toString()?.includes("-"))
        return

    }
    if ((fieldName === "regularRate" || fieldName === "annualSal" || fieldName === "empCode" || fieldName === "holidayProbPeriod")) {
      Utils.AllowOnlyPositiveNumnberInput(event)
      if (value?.toString()?.includes("-"))
        return

      if (value?.toString()?.split('.')[1]?.length > 2)
        return;
    }
    if (fieldName == "annualSal" || fieldName === "regularRate" || fieldName === "holidayProbPeriod"  ) {

      value = Utils.removeInvaildCharFromAmount(value, 10);
    }
    if (fieldName === "terDate") {
      const _formattedDate = Utils.AddSlashINInputDateFormat(
        value,
        hotelWiseSettingFormData.terDate,
        true,
        2,

      );
      if (_formattedDate === "invalid") {
        return;
      } else {
        value = _formattedDate;
      }
      if (this.props?.hireDate && this.props?.hireDate?.toString()?.length === 8 && value?.toString()?.length === 8) {
        console.log({ date: moment(this.props?.hireDate)?.isBefore(value), value, hireDate: this.props?.hireDate })
        if (!moment(this.props?.hireDate)?.isSameOrBefore(value)) {
          return
        }
      }

      // minDate={
      //   this.props?.hireDate
      //     ? new Date(
      //       moment(this.props?.hireDate).format(
      //         "MM/DD/YY"
      //       )
      //     )
      //     : null
      // }
      // maxDate={JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
      //   JSON.parse(localStorage.storage).currentUtcTime
      // ) : new Date()}

    }
    if (fieldName === "payrollid")
    {
      value = value?.trim()
      value= value?.replace(/ /g,"")
    }

    hotelWiseSettingFormData[fieldName] = value;
    if (inputPosition > this.state.inputPosition) {
      this.setState({ inputPosition });
    }
    this.setState({ hotelWiseSettingFormData }, () => {
      if (
        hotelWiseSettingFormData?.hid > 0 &&
        hotelWiseSettingFormData?.coa > 0 &&
        this.props?.employeeType === "ExcludefromPayroll"
      ) {
        if (fieldName === "coa") {
          const { hotelWiseSettingFormData } = this.state;
          hotelWiseSettingFormData.payRate = "";
          this.setState({ hotelWiseSettingFormData });
        }
        if (fieldName === "coa" || fieldName === "hid") {
          this.checkIfPositionIsAssignable();
        }
      }
      if (
        (this.props?.employeeType === "Salaried" ||
          this.props?.employeeType === "SalariedNonExempt") &&
        fieldName === "annualSal" || fieldName === "totalHrs"
      ) {
        if (hotelWiseSettingFormData?.hid > 0) {
          this.updateRegularRateValue();
        } else {
          hotelWiseSettingFormData.regularRate = "";
          this.setState({ hotelWiseSettingFormData });
        }
      }

      if (this.props?.isPayrollTab) {
        if (
          +hotelWiseSettingFormData?.hid > 0 &&
          hotelWiseSettingFormData?.withHoldingState
        ) {


          if (fieldName === "withHoldingState" || fieldName === "hid") {
            if (fieldName === "withHoldingState" && value) {
              this.getHotelStateAndLocalTax(() => {
                this.getUserStateFillStatus(
                  hotelWiseSettingFormData?.hid,
                  hotelWiseSettingFormData?.withHoldingState
                )
              });
            }
            else {
              this.getUserStateFillStatus(
                hotelWiseSettingFormData?.hid,
                hotelWiseSettingFormData?.withHoldingState
              );
            }

            // hotelWiseSettingFormData.stateFilling = "";
            this.setState({ hotelWiseSettingFormData });

          }
        } else {
          this.setState({ statusFilingStatusList: [] });
        }
      }

      const positionIds = (this.state?.speacialRates || []).map((item: any) => +item?.positionId);
      if (+  hotelWiseSettingFormData?.hid) {

        this.setState({ positionList: [] });
        let filterPosition = this.props.positionList.filter(x => +x.hid === + hotelWiseSettingFormData?.hid && !positionIds?.includes(+x.empCode)).map(x => {

          x.empCode = x?.empCode?.toString();
          return x;
        });

        this.setState({ positionList: filterPosition?.length > 0 ? filterPosition : [] });
      } 


      this.validateHotelWiseFormSchema();
    });
  };

  updateRegularRateValue = () => {
    const { hotelWiseSettingFormData } = this.state;

    const totalMinutes = +hotelWiseSettingFormData?.totalHrs?.toString()?.replace(":", ".") > 0 ?
      Utils.getMinutesFromHHMM(hotelWiseSettingFormData?.totalHrs || "0")

      : 0;

    let annualSal = hotelWiseSettingFormData?.annualSal ? Utils.removecurrencyFormat(hotelWiseSettingFormData?.annualSal) : 0;

    hotelWiseSettingFormData.regularRate = (
      annualSal /
      (Utils.getMinutesInHHMMFormatDot(totalMinutes) * 52)
    ).toFixed(2);

    hotelWiseSettingFormData.regularRate = hotelWiseSettingFormData.regularRate === "Infinity" ? "" : hotelWiseSettingFormData.regularRate;
    this.setState({ hotelWiseSettingFormData });
  };

  validateHotelWiseFormSchema = () => {
    const valid = Joi.validate(
      this.state.hotelWiseSettingFormData,
      this.hotelWiseSttingFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }


    if (
      !this.state.hotelWiseSettingFormData?.terDate &&
      this.state.hotelWiseSettingFormData?.status !== "Active"
    ) {
      newErrorObject["terDate"] = "Please select Termination Date";
    } else {
      if (newErrorObject["terDate"]) {
        delete newErrorObject["terDate"];
      }
    }
    if (
      this.props?.employeeType === "Salaried" ||
      this.props?.employeeType === "SalariedNonExempt"
    ) {

      if (
        (+this.state.hotelWiseSettingFormData?.totalHrs?.toString()?.replace(":", ".") <= 0
          || isNaN(this.state.hotelWiseSettingFormData?.totalHrs?.replace(":", "."))

          || !this.state.hotelWiseSettingFormData?.totalHrs?.toString())
        &&
        this.state.hotelWiseSettingFormData?.status === "Active"
      ) {
        newErrorObject["totalHrs"] = "Please enter Total Hours/Week";
      } else {
        if (newErrorObject["totalHrs"]) {
          delete newErrorObject["totalHrs"];
        }
      }

      let annualSal = this.state.hotelWiseSettingFormData?.annualSal ? Utils.removecurrencyFormat(this.state.hotelWiseSettingFormData?.annualSal) : 0;

      if (
        +annualSal <= 0 ||
        isNaN(annualSal)
      ) {
        newErrorObject["annualSal"] = "Please enter Annual Salary";
      } else {
        if (newErrorObject["annualSal"]) {
          delete newErrorObject["annualSal"];
        }

      }


    } else {
      if (newErrorObject["annualSal"]) {
        delete newErrorObject["annualSal"];
      }
      if (newErrorObject["totalHrs"]) {
        delete newErrorObject["totalHrs"];
      }
    }

    if (
      !this.state.hotelWiseSettingFormData?.withHoldingState &&
      this.props?.isPayrollTab && this.state.hotelWiseSettingFormData?.status === "Active"
    ) {
      newErrorObject["withHoldingState"] = "Please enter Working State";
    } else {
      if (newErrorObject["withHoldingState"]) {
        delete newErrorObject["withHoldingState"];
      }
    }


    if (
      (this.state.hotelWiseSettingFormData?.holidayProbPeriod ==="" ||    this.state.hotelWiseSettingFormData?.holidayProbPeriod==undefined || this.state.hotelWiseSettingFormData?.holidayProbPeriod==null)  &&
      this.props?.employeeType  !=="ExcludefromPayroll" 
    ) {
      newErrorObject["holidayProbPeriod"] = "Please enter Holiday Probationary Period";
    } else {
      if (newErrorObject["holidayProbPeriod"]) {
        delete newErrorObject["holidayProbPeriod"];
      }
    }



  







    if (
      !this.state.hotelWiseSettingFormData?.unemployeeState &&
      this.props?.isPayrollTab && this.state.hotelWiseSettingFormData?.status === "Active"
    ) {
      newErrorObject["unemployeeState"] = "Please select Unemployment State";
    } else {
      if (newErrorObject["unemployeeState"]) {
        delete newErrorObject["unemployeeState"];
      }
    }

    // if (
    //   !this.state.hotelWiseSettingFormData?.stateFilling &&
    //   this.props?.isPayrollTab && this.state.hotelWiseSettingFormData?.status === "Active"
    // ) {
    //   newErrorObject["stateFilling"] = "Please select State Filing Status";
    // } else {
    //   if (newErrorObject["stateFilling"]) {
    //     delete newErrorObject["stateFilling"];
    //   }
    // }

    if (
      (this.props?.employeeType === "Salaried" ||
        this.props?.employeeType === "SalariedNonExempt" ||
        this.props?.employeeType === "ExcludefromPayroll")
      ||
      this.state.hotelWiseSettingFormData?.status !== "Active"

    ) {
      if (newErrorObject["regularRate"]) {
        delete newErrorObject["regularRate"];
      }
    }
    if (
      this.state.showPayRateDDL &&
      !this.state.hotelWiseSettingFormData?.payRate
    ) {
      newErrorObject["payRate"] = "Please select Rate Method";
    } else {
      if (newErrorObject["payRate"]) {
        delete newErrorObject["payRate"];
      }
    }

    if (
      this.props?.isEmpCodeRequired &&
      !this.state.hotelWiseSettingFormData?.empCode
    ) {
      newErrorObject["empCode"] = "Please enter Employee Code";
    } else {
      if (newErrorObject["empCode"]) {
        delete newErrorObject["empCode"];
      }
    }

    if (
      this.props?.isEmpCodeRequired &&
      !this.state.hotelWiseSettingFormData?.empCode
    ) {
      newErrorObject["empCode"] = "Please enter Employee Code";
    } else {
      if (newErrorObject["empCode"]) {
        delete newErrorObject["empCode"];
      }
    }



    (this.state?.localAndStateTaxesFormFields || [])?.forEach(item => {
      if (item?.isRequired && this.checkIfChildFieldNeedToDisplay(item)) {
        if (item.controlType === "textbox" && (!item?.fieldValue)) {
          newErrorObject[item.fieldName] = `Please enter ${item?.displayName}`;
        } else
          if (item.controlType === "dropdown" && !item?.fieldValue) {
            newErrorObject[item.fieldName] = `Please select ${item?.displayName}`;
          } else {
            if (newErrorObject[item.fieldName]) {
              delete newErrorObject[item.fieldName];
            }
          }
      } else {
        if (newErrorObject[item.fieldName]) {
          delete newErrorObject[item.fieldName];
        }
      }
    });

    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  validationOnClick = (inputPosition) => {
    if (inputPosition >= this.state.inputPosition) {
      this.setState({ inputPosition }, () => this.validateHotelWiseFormSchema());
    }
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;
    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };




  hideModal = (isReload = false) => {
    const hotelWiseSettingFormData = {
      uniqueNo: this.props?.hotelWisePayrollRowEdit?.uniqueNo || "",
      hid: +this.props?.hotelWisePayrollRowEdit?.hid || 0,
      status: this.props?.hotelWisePayrollRowEdit?.status || "Active",
      coa: +this.props?.hotelWisePayrollRowEdit?.coa || 0,
      regularRate: +this.props?.hotelWisePayrollRowEdit?.regularRate || "",
      payRate: this.props?.hotelWisePayrollRowEdit?.payRate || "",
      annualSal: this.props?.hotelWisePayrollRowEdit?.annualSal,
      terDate: this.props?.hotelWisePayrollRowEdit?.terDate,
      unemployeeState:
        this.props?.hotelWisePayrollRowEdit?.unemploymentState || "",
      withHoldingState:
        this.props?.hotelWisePayrollRowEdit?.withholdingState || "",
      // stateFilling:
      //   this.props?.hotelWisePayrollRowEdit?.stateFillingStatus || "",
      empCode: this.props?.hotelWisePayrollRowEdit?.empLoyee_Code || "",
    };
    this.props?.hideHotelWiseAddEditModalForm(isReload);
    this.setState({ hotelWiseSettingFormData, isFormNotValid: true });
  };

  getHotels = () => {
    const { hotelWiseSettingFormData } = this.state;
    this.setState({ hotelList: _.uniq(this.props?.userHotels) });

    if (_.uniq(this.props?.userHotels)?.length === 1) {
      hotelWiseSettingFormData.hid = this.props?.userHotels[0].hotelID;
      if (this.props?.isPayrollTab) {
        let state = this.props?.userHotels?.find(x => x?.nonUS === "No" && x?.hotelID === hotelWiseSettingFormData?.hid)?.state

        if (state) {
          state = StaticArrays.usaStatesWithFullName?.find(x => x.name === state)?.abbreviation || "";
          state = StaticArrays.withHoldingUnemploymentStates.find(x => x.value === state)?.value || "";
        }

        hotelWiseSettingFormData.unemployeeState = this.props?.hotelWisePayrollRowEdit?.unemploymentState || state;
        if (!this.props?.hotelWisePayrollRowEdit?.withholdingState && hotelWiseSettingFormData?.withHoldingState) {
          this.getUserStateFillStatus(
            hotelWiseSettingFormData.hid,
            hotelWiseSettingFormData?.withHoldingState
          );
        }
      }

      this.setState({ hotelWiseSettingFormData, inputPosition: 0 }, () => {
        const positionIds = (this.state?.speacialRates || []).map((item: any) => +item?.positionId);
        if (+hotelWiseSettingFormData?.hid) {

          this.setState({ positionList: [] });
          let filterPosition = this.props.positionList.filter(x => +x.hid ===
            + hotelWiseSettingFormData?.hid && !positionIds?.includes(+x.empCode)).map(x => {

              x.empCode = x?.empCode?.toString();
              return x;
            });

          let _payrollId = ""
          if(+this.props?.hotelWisePayrollRowEdit?.uniqueNo > 0){
            _payrollId=this.props?.hotelWisePayrollRowEdit?.mappingId || ""
          }
          else{
            const showPayrollId=this.props.userPayrollMapping?.length > 0 ? this.props.userPayrollMapping?.filter(x => x.hid == hotelWiseSettingFormData?.hid).length > 0  : false
            const payrollIntegrationDetails=showPayrollId ? this.props.userPayrollMapping?.find(x => x.hid == hotelWiseSettingFormData?.hid) : null

            _payrollId=payrollIntegrationDetails?.mappingId || ""
          } 

          hotelWiseSettingFormData.payrollid =_payrollId

          this.setState({ positionList: filterPosition?.length > 0 ? filterPosition : [], hotelWiseSettingFormData });
        }




      });
    }


  };

  onAmountFocus = (event: any) => {
    let newval = (!event.target.value || +event.target.value === 0) ?
      "" : Utils.removecurrencyFormat(event.target.value);
    const { hotelWiseSettingFormData } = this.state;
    const _hotelWiseSettingFormData = _.cloneDeep(hotelWiseSettingFormData);
    _hotelWiseSettingFormData.annualSal = newval;
    this.setState({ hotelWiseSettingFormData: _hotelWiseSettingFormData });
  }

  handleAnnualSalaryFormat = (event) => {
    const { hotelWiseSettingFormData } = this.state;
    const _hotelWiseSettingFormData = _.cloneDeep(hotelWiseSettingFormData);
    if (!isNaN(event.target.value)) {
      let annualSal = Utils.currencyFormat(_hotelWiseSettingFormData.annualSal);
      _hotelWiseSettingFormData.annualSal = annualSal;
    }
    this.setState({ hotelWiseSettingFormData: _hotelWiseSettingFormData });
  }

  handleCurrencyOnFocus = (event, index) => {
    let newval = (!event.target.value) ?
      "" : Utils.removecurrencyFormat(event.target.value);
    const { localAndStateTaxesFormFields } = this.state;
    const _localAndStateTaxesFormFields = _.cloneDeep(localAndStateTaxesFormFields);
    _localAndStateTaxesFormFields[index].fieldValue = newval
    this.setState({ localAndStateTaxesFormFields: _localAndStateTaxesFormFields });
  }
  handleCurrencyOnBlur = (event, index) => {
    const { localAndStateTaxesFormFields } = this.state;
    const _localAndStateTaxesFormFields = _.cloneDeep(localAndStateTaxesFormFields);
    if (!isNaN(event.target.value)) {
      let fieldValue = !event.target.value ? "" : Utils.currencyFormat(_localAndStateTaxesFormFields[index].fieldValue);
      _localAndStateTaxesFormFields[index].fieldValue = fieldValue;
    }
    this.setState({ localAndStateTaxesFormFields: _localAndStateTaxesFormFields });
  }

  handleNumberOnBlur = (event, index) => {
    const { localAndStateTaxesFormFields } = this.state;
    const _localAndStateTaxesFormFields = _.cloneDeep(localAndStateTaxesFormFields);
    if (!isNaN(event.target.value)) {
      let fieldValue = !event.target.value ? "" : Utils.convertNumberToTwoPlacesDecimal(_localAndStateTaxesFormFields[index].fieldValue);
      _localAndStateTaxesFormFields[index].fieldValue = fieldValue;
    }
    this.setState({ localAndStateTaxesFormFields: _localAndStateTaxesFormFields });
  }



  handleDynamicFieldChange = (event, item, index, inputPosition) => {
    let value = event.target.value;
    if (item?.controlType === "textbox") {
      if (item.isnumber == 1 && item.isCurrency == 1) {
        Utils.AllowOnlyPositiveNumnberInput(event)

        if (value?.toString()?.includes("-"))
          return

        // if (value.toString()?.split('.')[0]?.length > 10)
        //   return

        // if (value.toString()?.split('.')[1]?.length > 2)
        //   return


        value = Utils.removeInvaildCharFromAmount(value, 10);


      }
      else if (item.isnumber == 1) {
        Utils.AllowOnlyPositiveNumnberInputWithoutDot(event)
        value = value?.toString()?.replace(/[^0-9\-\+]+/gi, "");
        if (value?.toString()?.includes("-") || value?.toString()?.includes("."))
          return


      }
      else {

        value = value?.toString()?.replace(/[^A-Z \-\_]+/gi, "")?.trimLeft();
        if (value) {
          if (value.indexOf("_") === 0 || value.indexOf("-") === 0) {
            value = value?.slice(0, 0);
          }
        }


      }
    }



    const { localAndStateTaxesFormFields } = this.state
    let _localAndStateTaxesFormFields = _.cloneDeep(localAndStateTaxesFormFields)
    _localAndStateTaxesFormFields[index].fieldValue = value
    if (item?.controlType === "checkbox" && !value) {
      _localAndStateTaxesFormFields?.map(x => {
        if (x.parentid === item?.fieldId) {
          x.fieldValue = "";
        }
        return x;
      })
    }

    this.setState({ localAndStateTaxesFormFields: _localAndStateTaxesFormFields, inputPosition }, () => this.validateHotelWiseFormSchema());
  }


  handleDynamicTextInputBlur = (e, item, index) => {
    if (item?.isCurrency == 1 && item?.isnumber == 1) {
      this.handleCurrencyOnBlur(e, index)

    }
  }

  getTextBoxControl = (label, value, item, index, colSize, inputPosition): any => {




    let placeHolder = ""
    if (item.isCurrency == 1) {
      placeHolder = "$0.00"
    }
    else if (item.isnumber) {
      placeHolder = "0"
    }



    return <Col
      sm={colSize}
      className={`form-group ${item?.displayOnParentCheck ? "child-item" : ""} ${this.state.errors[item.fieldName] && this.state.inputPosition >= inputPosition
        ? "validation-error"
        : ""
        }`}
      onBlur={() => this.validationOnClick(inputPosition)}
    >
      <Form.Label className="sec-ttl">
        {label}
      </Form.Label>

      <OverlayTrigger
        placement="bottom"
        defaultShow={false}
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id={`txt${item.fieldName}`} className={((value).length <= 51) ? "tootltip-hide" : ""}>
            {(value)}
          </Tooltip>
        }
      >
        <input
          onKeyDown={(e) => { if (item.isnumber == 1 && item.isCurrency != 1) Utils.AllowOnlyPositiveNumnberInputWithoutDot(e) }}
          maxLength={200}
          type="text"
          className="form-control"
          id={`txt${item.fieldName}`}
          name={`${item.fieldName}`}
          autoComplete={`txt${item.fieldName}`}
          placeholder={placeHolder}
          disabled={this.state.hotelWiseSettingFormData.status !== "Active"}
          value={value}
          onFocus={(e) => { if (item.isCurrency == 1) this.handleCurrencyOnFocus(e, index) }}
          onBlur={(e) => { this.handleDynamicTextInputBlur(e, item, index) }}
          onChange={(e) => this.handleDynamicFieldChange(e, item, index, inputPosition)}
        />
      </OverlayTrigger>
      {this.getFieldError(item.fieldName, inputPosition)}
    </Col>



  };

  getDropDownControl = (controlName, label, value, item, index, colSize, inputPosition): any => {
    let dropDownArray: any[] = [];
    value = value || "";
    const { statusFilingStatusList } = this.state;
    switch (controlName) {
      case "Filing Status":
        dropDownArray = statusFilingStatusList?.map((x) => {
          const item: any = {}
          if (x?.stateFilling === "Not Applicable") {
            item.label = x.stateFilling;
            item.value = "";
          }
          else {
            item.label = x.stateFilling;
            item.value = x.stateFilling;
          }

          return item;
        }

        );
        break;
      case "Withholding Rate":
        dropDownArray = this.withHoldingRates;
        break;
      case "Workers Compensation":
        dropDownArray = this.yesNoOptions;
        break;
      case "PA SUI":
        dropDownArray = this.yesNoOptions;
        break;
      case "2 Earner % Method":
        dropDownArray = this.yesNoOptions;
        break;

      case "City/School Tax #1Resident":
        dropDownArray = this.yesNoOptions;
        break;
      case "City/School Tax #1County":
        dropDownArray = this.yesNoOptions;
        break;
      case "City/School Tax #2Resident":
        dropDownArray = this.yesNoOptions;
        break;
      case "City/School Tax #2County":
        dropDownArray = this.yesNoOptions;
        break;
      case "License Fee #1Resident":
        dropDownArray = this.yesNoOptions;
        break;
      case "License Fee #2CityTown":
        dropDownArray = this.yesNoOptions;
        break;
      case "City/School Tax #1Out-of-State Employee":
        dropDownArray = this.yesNoOptions;
        break;
      case "City/School Tax #2Out-of-State Employee":
        dropDownArray = this.yesNoOptions;
        break;
      case "City Tax #1Resident":
        dropDownArray = this.yesNoOptions;
        break;
      case "City Tax #2Resident":
        dropDownArray = this.yesNoOptions;
        break;
      case "City Earn Tax#City":
        dropDownArray = this.taxCities;
        break;
      case "County Tax #1Resident":
        dropDownArray = this.yesNoOptions;
        break;
      case "County Tax #2Resident":
        dropDownArray = this.yesNoOptions;
        break;


      default:
        break;
    }


    return <Col
      sm={colSize}
      className={`form-group ${item?.displayOnParentCheck ? "child-item" : ""} ${this.state.errors[item.fieldName] && this.state.inputPosition >= inputPosition
        ? "validation-error"
        : ""
        }`}
      onBlur={() => this.validationOnClick(inputPosition)}
    >
      <Form.Label>{label}</Form.Label>
      {dropDownArray?.length > 0 &&
        <DropDownList
          placeHolder={`Select ${label}`}
          data={dropDownArray}
          defaultValue={value}
          isSearchRequired={false}
          label={"label"}
          value={"label"}
          disabled={this.state.hotelWiseSettingFormData.status !== "Active"}
          onDropDownChange={(item) => {
            if (!item) {
              return;
            }
            this.handleDynamicFieldChange(
              Utils.BuildCustomEventObject(
                "unemployeeState",
                item.value
              ),
              item, index, inputPosition
            );
          }}
          selectedItem={[
            ...[
              {
                label: `Select ${label}`,
                value: "",
              },
            ],
            ...dropDownArray,
          ].find((r: any) => r?.value === value)}
        />
      }


      {this.getFieldError(item.fieldName, inputPosition)}
    </Col>

  };

  getCheckBoxControl = (label, value, item, index, colSize, inputPosition): any => {
  
    return <Col
      sm={colSize}
      className={`form-group checkbox`}
    >

      {/* <Form.Label className="sec-ttl">
        {label}
      </Form.Label> */}
      <Form.Check
        type="checkbox"
        id={`chk${item.fieldName}`}
        label={label}
        // value={value == "true" ? true : false}
        checked={value == "true" || value === true ? true : false}
        disabled={this.state.hotelWiseSettingFormData.status !== "Active"}
        onChange={(e) => {

          this.handleDynamicFieldChange(
            Utils.BuildCustomEventObject(
              item.fieldName,
              e.target.checked
            ),
            item, index, inputPosition
          );

        }}
        name={item.fieldName}
      />
      {/* <> { value && (_.cloneDeep(localAndStateTaxesFormFields) || [])?.filter(x=>x?.parentid ===item?.fieldId).map((x, i) => {
      let item = <></>;
      inputPosition =inputPosition +_.cloneDeep(localAndStateTaxesFormFields);
      switch (x.controlType) {
        case "textbox":
if(this.checkIfChildFieldNeedToDisplay(x)) {
          item = this.getTextBoxControl(x.displayName, x.fieldValue, x, (_.cloneDeep(localAndStateTaxesFormFields) || [])?.findIndex(y=>y?.fieldId ===x?.fieldId), colSize, inputPosition);
}
          break;
        case "dropdown":
          if(this.checkIfChildFieldNeedToDisplay(x)) {
          item = this.getDropDownControl(x.fieldName, x.displayName, x.fieldValue, x, (_.cloneDeep(localAndStateTaxesFormFields) || [])?.findIndex(y=>y?.fieldId ===x?.fieldId), colSize, inputPosition);
          }
          break;

        default:
          break;
      }
      return item;

    }
    )

    }</>  */}

    </Col>
  };

  checkIfChildFieldNeedToDisplay = (item): boolean => {

    const { localAndStateTaxesFormFields } = this.state;
    const parentItem = (localAndStateTaxesFormFields || [])?.find(x => x.fieldId === item.parentid);
    if (parentItem) {
      return ((item.displayOnParentCheck && parentItem?.fieldValue?.toString() === "true") ||
        !item.displayOnParentCheck) ? true : false;
    } else {
      return true
    }
  }

  renderDynamicFormFields = (colSize) => {
    const { localAndStateTaxesFormFields } = this.state;
    let inputPosition = 15;
    return <>{(localAndStateTaxesFormFields || [])?.map((x, i) => {
      let item = <></>;
      inputPosition = inputPosition + i;
      switch (x.controlType) {
        case "textbox":
          if (this.checkIfChildFieldNeedToDisplay(x)) {
            item = this.getTextBoxControl(x?.displayName, x?.fieldValue, x, i, colSize, inputPosition);
          }
          break;
        case "checkbox":
          if (this.checkIfChildFieldNeedToDisplay(x)) {
            item = this.getCheckBoxControl(x?.displayName, x?.fieldValue, x, i, colSize, inputPosition);
          }
          break;
        case "dropdown":
          if (this.checkIfChildFieldNeedToDisplay(x)) {
            item = this.getDropDownControl(x?.fieldName, x?.displayName, x?.fieldValue, x, i, colSize, inputPosition);
          }
          break;

        default:
          break;
      }
      return item;

    }
    )

    }</>
  }


  getFormColumnSizeBasedOnFieldsCount = () => {
    //     const {
    //       hotelWiseSettingFormData: {
    //         status,

    //       },

    //       localAndStateTaxesFormFields, positionList, showPayRateDDL } = this.state;


    //       let normalDispalayFieldsLength = (localAndStateTaxesFormFields || [])?.filter(x=>!x?.parentid)?.length;
    //       (localAndStateTaxesFormFields || [])?.forEach(x=> {
    //         if(x?.fieldValue?.toString()==="true" && x.controlType ==="checkbox") {
    //         normalDispalayFieldsLength +=  localAndStateTaxesFormFields?.filter(r=>r?.parentid === x?.fieldId)?.length || 0;
    //         }
    //       }); 


    //     const len = normalDispalayFieldsLength +
    //       2 + (this.props?.isEmpCodeRequired ? 1 : 0) +
    //       ((this.props?.employeeType === "Salaried" ||
    //         this.props?.employeeType === "SalariedNonExempt") ? 2 : 0) +
    //       (positionList?.length > 0 ? 2 : 0)
    //       +
    //       ((this.props?.employeeType === "ExcludefromPayroll" &&
    //         showPayRateDDL) ? 1 : 0)
    //       + (status !== "Active" ? 1 : 0)
    //       + (this.props?.isPayrollTab ? 3 : 0);
    //       let colSize =  0;

    //    if(len>15) {
    //   colSize =3;
    //    }
    //    else if (len > 12) {
    //     colSize =4;
    //    } 
    // else {
    //   colSize =6;
    // }

    //     return colSize;


    return this.props?.isPayrollTab ? 12 : 6

  }

  render() {
    const {
      hotelWiseSettingFormData: {
        
        hid,
        status,
        coa,
        regularRate,
        payRate,
        annualSal,
        terDate,
        withHoldingState,
        unemployeeState,
        holidayProbPeriod,
        empCode,
        totalHrs,
        workingState2,
        isPrimary_Property,
        payrollid
      },
      hotelList,
      
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,
      positionList,
      showPayRateDDL,
     
    } = this.state;
    const { showHotelWiseAddEditModal } = this.props;

    // let mode = "Add";
    // if (this.props?.hotelWisePayrollRowEdit.uniqueNo > 0) {
    //   mode = "Edit"
    // }

    const colSize = this.getFormColumnSizeBasedOnFieldsCount()

    const showPayrollId=this.props.userPayrollMapping?.length > 0 ? this.props.userPayrollMapping?.filter(x => x.hid == hid && x.showPayrollId).length > 0  : false
    const payrollIntegrationDetails=showPayrollId ? this.props.userPayrollMapping?.find(x => x.hid == hid) : null
    // payrollId
    return (
      <>
        <div className="">

          <Modal
            className={`add-hotel-wide-settings-modal ${this.props.isPayrollTab ? "large" : ""}`}
            show={showHotelWiseAddEditModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={this.props?.showHotelWiseAddEditModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props?.hotelWisePayrollRowEdit?.uniqueNo > 0
                  ? "Edit Entity Wide Setting"
                  : "Add Entity Wide Setting"}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Row className="d-flex">
                    <Col
                      sm={colSize}
                      className={`form-group ${errors?.hid && inputPosition >= 1
                        ? "validation-error"
                        : ""
                        }`}
                      onBlur={() => this.validationOnClick(1)}
                    >
                      <Form.Label>EHID</Form.Label>
                      {hotelList?.length > 0 && (
                        <DropDownList
                          placeHolder={"Select HID"}
                          data={hotelList}
                          defaultValue={hid}
                          isSearchRequired={false}
                          label={"lettercode"}
                          value={"hotelID"}
                          disabled={hotelList.length === 1}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("hid", item.hotelID),
                              1
                            );
                          }}
                          selectedItem={[
                            ...[{ hotelID: 0, lettercode: "Select HID" }],
                            ...hotelList,
                          ].find((r: any) => r?.hotelID === hid)}
                        />
                      )}
                      {this.getFieldError("hid", 1)}
                    </Col>
                    <Col
                      sm={colSize}
                      className={`form-group ${errors?.status && inputPosition >= 2
                        ? "validation-error"
                        : ""
                        }`}
                      onBlur={() => this.validationOnClick(2)}
                    >
                      <Form.Label>Status</Form.Label>

                      <DropDownList
                        placeHolder={"Select Status"}
                        data={StaticArrays.StatusList}
                        defaultValue={status}
                        isSearchRequired={false}
                        disabled={!this.props?.hotelWisePayrollRowEdit?.uniqueNo ? true : false}
                        label={"label"}
                        value={"label"}
                        onDropDownChange={(item) => {
                          if (!item) {
                            return;
                          }
                          this.onFieldChange(
                            Utils.BuildCustomEventObject("status", item.value),
                            2
                          );
                        }}
                        selectedItem={[
                          ...[{ label: "Select Status", value: "" }],
                          ...StaticArrays.StatusList,
                        ].find((r: any) => r?.value === status)}
                      />

                      {this.getFieldError("status", 2)}
                    </Col>

                    {this.props?.isEmpCodeRequired && (
                      <Col
                        sm={colSize}
                        className={`form-group ${errors?.empCode && inputPosition >= 3
                          ? "validation-error"
                          : ""
                          }`}
                        onBlur={() => this.validationOnClick(3)}
                      >
                        <Form.Label>Employee Code</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          id="txtempCode"
                          name="empCode"
                          autoComplete="txtempCode"
                          value={empCode}
                          disabled={status !== "Active"}
                          // onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                          onChange={(e) => this.onFieldChange(e, 3)}
                        />
                        {this.getFieldError("empCode", 3)}
                      </Col>
                    )}


                    {(this.props?.employeeType === "Salaried" ||
                      this.props?.employeeType === "SalariedNonExempt") &&
                      (
                        <>
                          <Col
                            sm={colSize}
                            className={`form-group ${errors?.totalHrs && inputPosition >= 4
                              ? "validation-error"
                              : ""
                              }`}
                            onBlur={() => this.validationOnClick(4)}
                          >
                            <Form.Label>Total Hours/Week</Form.Label>
                            <input
                              onBlur={(event: any) =>
                                this.onHourEntryBlur(event.target.value, 4)
                              }
                              className="form-control"
                              id="txtTotalHrs"
                              name="totalHrs"

                              autoComplete="txttotalHrs"
                              onKeyPress={(event) => {
                                Utils.OnlyNumbersWithColon(event);
                              }}
                              disabled={status !== "Active"}
                              maxLength={5}
                              placeholder="HH:MM"
                              type="string"
                              value={totalHrs}
                              onChange={(e) => this.onFieldChange(e, 4)}
                            />

                            {this.getFieldError("totalHrs", 4)}
                          </Col>
                          <Col
                            sm={colSize}
                            className={`form-group ${errors?.annualSal && inputPosition >= 5
                              ? "validation-error"
                              : ""
                              }`}
                            onBlur={() => this.validationOnClick(5)}
                          >
                            <Form.Label>Annual Salary</Form.Label>

                            {
                              annualSal &&
                              <ReactTooltip id="txtAnnualSal" effect="solid" place="bottom">
                                {annualSal}
                              </ReactTooltip>
                            }

                            <input
                              className="form-control"
                              id="txtAnnualSal"
                              data-tip
                              data-for="txtAnnualSal"
                              name="annualSal"
                              autoComplete="txtAnnualSal"
                              value={annualSal}
                              placeholder="$0.00"
                              onBlur={(e) => this.handleAnnualSalaryFormat(e)}
                              onFocus={(e) => this.onAmountFocus(e)}
                              disabled={status !== "Active"}

                              //   pattern="[+-]?\d+(?:[.,]\d+)?"
                              maxLength={20}
                              title=""
                              onChange={(e) => this.onFieldChange(e, 5)}
                            />
                            {this.getFieldError("annualSal", 5)}
                          </Col>
                        </>
                      )}
                    {positionList?.length > 0 &&
                      <>
                        <Col
                          sm={colSize}
                          className={`form-group ${errors?.coa && inputPosition >= 6
                            ? "validation-error"
                            : ""
                            }`}
                          onBlur={() => this.validationOnClick(6)}
                        >
                          <Form.Label>Regular Position</Form.Label>
                          {positionList?.length > 0 && (
                            <DropDownList
                              placeHolder={"Search Position"}
                              data={positionList}
                              defaultValue={coa}
                              disabled={status !== "Active"}
                              isSearchRequired={true}
                              label={"empTitle"}
                              value={"empCode"}

                              onDropDownChange={(item) => {
                                if (!item) {
                                  return;
                                }
                                this.onFieldChange(
                                  Utils.BuildCustomEventObject("coa", item.empCode),
                                  6
                                );
                              }}
                              selectedItem={[
                                ...[{ empCode: 0, empTitle: "Select Position" }],
                                ...positionList,
                              ].find((r: any) => r?.empCode === coa)}
                            />
                          )}

                          {this.getFieldError("coa", 6)}
                        </Col>

                        <Col
                          sm={colSize}
                          className={`form-group ${errors?.regularRate && inputPosition >= 7
                            ? "validation-error"
                            : ""
                            }`}
                          onBlur={() => this.validationOnClick(7)}
                        >
                          <Form.Label>Regular Rate</Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            id="txtRegularRate"
                            name="regularRate"
                            placeholder="0.00"
                            autoComplete="txtRegularRate"
                            disabled={
                              this.props?.employeeType === "Salaried" ||
                              this.props?.employeeType === "SalariedNonExempt"
                              || status !== "Active"

                            }
                            // onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                            value={regularRate}
                            onChange={(e) => this.onFieldChange(e, 7)}
                          />
                          {this.getFieldError("regularRate", 7)}
                        </Col>
                      </>
                    }
                    { this.props?.employeeType  !=="ExcludefromPayroll" &&
                      <Col
                          sm={colSize}
                          className={`form-group ${errors?.holidayProbPeriod && inputPosition >= 8
                            ? "validation-error"
                            : ""
                            }`}
                          onBlur={() => this.validationOnClick(8)}
                        >
                          <Form.Label>Holiday Probationary Period <span>(in Days)</span></Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            id="txtholidayProbPeriod"
                            name="holidayProbPeriod"
                            placeholder="0"
                            maxLength={10}
                            autoComplete="txtholidayProbPeriod"
                            disabled={status !== "Active"}
                             onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                            value={holidayProbPeriod}
                            onChange={(e) => this.onFieldChange(e, 8)}
                          />
                          {this.getFieldError("holidayProbPeriod", 8)}
                        </Col>
  }
                    {this.props?.employeeType === "ExcludefromPayroll" &&
                      showPayRateDDL && (
                        <Col
                          sm={colSize}
                          className={`form-group ${errors?.payRate && inputPosition >= 9
                            ? "validation-error"
                            : ""
                            }`}
                          onBlur={() => this.validationOnClick(9)}
                        >
                          <Form.Label>Rate Method</Form.Label>

                          <DropDownList
                            placeHolder={"Select Status"}
                            data={this.payRateOptions}
                            defaultValue={payRate}
                            disabled={status !== "Active"}
                            isSearchRequired={false}
                            label={"label"}
                            value={"label"}
                            onDropDownChange={(item) => {
                              if (!item) {
                                return;
                              }
                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "payRate",
                                  item.value
                                ),
                                9
                              );
                            }}
                            selectedItem={[
                              ...[{ label: "Select Rate Method", value: "" }],
                              ...this.payRateOptions,
                            ].find((r: any) => r?.value === payRate)}
                          />

                          {this.getFieldError("payRate", 9)}
                        </Col>
                      )}

                    {status !== "Active" && (
                      <Col
                        sm={colSize}
                        className={`form-group ${errors?.terDate && inputPosition >= 10
                          ? "validation-error"
                          : ""
                          }`}
                        onBlur={() => this.validationOnClick(10)}
                      >
                        <Form.Label>Termination Date</Form.Label>
                        <div className="validate-input d-flex flex-column">
                          <div className="calender-container">
                            <div
                              className={
                                this.state.calenderShow
                                  ? "calender-display open"
                                  : "calender-display"
                              }
                              onClick={this.calenderShow}
                              onFocus={this.calenderShow}
                            >
                              <input
                                onClick={this.calenderShow}
                                onFocus={this.calenderShow}
                                type="text"
                                placeholder="MM/DD/YY"
                                value={terDate}
                                maxLength={8}
                                onInput={(e) => {
                                  let value = e.currentTarget.value;

                                  value = value
                                    ?.toString()
                                    ?.replace(/[^0-9\/]+/gi, "");

                                  this.onFieldChange(
                                    Utils.BuildCustomEventObject("terDate", value),
                                    10
                                  );
                                }}
                                onBlur={(e) => {
                                  let value = e.currentTarget.value;

                                  value = value
                                    ?.toString()
                                    ?.replace(/[^0-9\/]+/gi, "");

                                  this.onFieldChange(
                                    Utils.BuildCustomEventObject("terDate", value),
                                    10
                                  );


                                }}
                                className="form-control single-date-pick"
                                name="ptoStartDate"
                                autoComplete="ptoStartDate"
                              />
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                                <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                              </svg>
                            </div>
                            {this.state.calenderShow && (
                              <div className="calender">
                                <OutsideClickHandler
                                  onOutsideClick={this.calenderHide}
                                >
                                  <Calendar
                                    calendarType={"US"}
                                    onChange={(date) => {
                                      const value =
                                        moment(date).format("MM/DD/YY");
                                      this.calenderHide();
                                      this.onFieldChange(
                                        Utils.BuildCustomEventObject(
                                          "terDate",
                                          value
                                        ),

                                        10
                                      );
                                      this.calenderHide();
                                    }}
                                    value={
                                      terDate ? moment(terDate)?.format(
                                        "MM/DD/YY"
                                      ) === "Invalid date"
                                        ? new Date()
                                        : new Date(terDate) : new Date()
                                    }
                                    minDate={
                                      this.props?.hireDate
                                        ? new Date(
                                          moment(this.props?.hireDate).format(
                                            "MM/DD/YY"
                                          )
                                        )
                                        : null
                                    }
                                    maxDate={JSON.parse(localStorage.storage).currentUtcTime !== "" ? new Date(
                                      JSON.parse(localStorage.storage).currentUtcTime
                                    ) : new Date()}
                                  />
                                </OutsideClickHandler>
                              </div>
                            )}
                          </div>
                        </div>
                        {this.getFieldError("terDate", 10)}
                      </Col>
                    )}
                    {this.props?.isPayrollTab && (
                      <>
                        <Col
                          sm={colSize}
                          className={`form-group ${errors?.unemployeeState && inputPosition >= 10
                            ? "validation-error"
                            : ""
                            }`}
                          onBlur={() => this.validationOnClick(10)}
                        >
                          <Form.Label>Unemployment State</Form.Label>
                          <DropDownList
                            placeHolder={"Select Unemployment State"}
                            data={_.sortBy(StaticArrays.withHoldingUnemploymentStates, 'label')}
                            defaultValue={unemployeeState}
                            isSearchRequired={true}
                            label={"label"}
                            value={"label"}
                            disabled={status !== "Active"}

                            onDropDownChange={(item) => {
                              if (!item) {
                                return;
                              }
                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "unemployeeState",
                                  item.value
                                ),
                                10
                              );
                            }}
                            selectedItem={[
                              ...[
                                {
                                  label: "Select Unemployment State",
                                  value: "",
                                },
                              ],
                              ...StaticArrays.withHoldingUnemploymentStates,
                            ].find((r: any) => r?.value === payRate)}
                          />
                          {this.getFieldError("unemployeeState", 10)}
                        </Col>


                        <Col
                          sm={colSize}
                          className={`form-group ${errors?.withHoldingState && inputPosition >= 11
                            ? "validation-error"
                            : ""
                            }`}
                          onBlur={() => this.validationOnClick(11)}
                        >
                          <Form.Label>Working State</Form.Label>
                          <DropDownList
                            placeHolder={"Select Working State"}
                            data={_.sortBy(StaticArrays.withHoldingUnemploymentStates, 'label')}
                            defaultValue={withHoldingState}
                            isSearchRequired={true}
                            label={"label"}
                            value={"label"}
                            disabled={status !== "Active"}

                            onDropDownChange={(item) => {
                              if (!item) {
                                return;
                              }
                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "withHoldingState",
                                  item.value
                                ),
                                11
                              );
                            }}
                            selectedItem={[
                              ...[
                                {
                                  label: "Select Working State",
                                  value: "",
                                },
                              ],
                              ...StaticArrays.withHoldingUnemploymentStates,
                            ].find((r: any) => r?.value === withHoldingState)}
                          />
                          {this.getFieldError("withHoldingState", 11)}
                        </Col>
                        <Col
                          sm={colSize}
                          className={`form-group ${errors?.workingState2 && inputPosition >= 12
                            ? "validation-error"
                            : ""
                            }`}
                          onBlur={() => this.validationOnClick(12)}
                        >
                          <Form.Label>Working State #2</Form.Label>
                          <DropDownList
                            placeHolder={"Select Working State #2"}
                            data={_.sortBy(StaticArrays.withHoldingUnemploymentStates, 'label')}
                            defaultValue={workingState2}
                            isSearchRequired={true}
                            label={"label"}
                            value={"label"}
                            disabled={status !== "Active"}

                            onDropDownChange={(item) => {
                              if (!item) {
                                return;
                              }
                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "workingState2",
                                  item.value
                                ),
                                12
                              );
                            }}
                            selectedItem={[
                              ...[
                                {
                                  label: "Select Working State #2",
                                  value: "",
                                },
                              ],
                              ...StaticArrays.withHoldingUnemploymentStates,
                            ].find((r: any) => r?.value === workingState2)}
                          />
                          {this.getFieldError("workingState2", 12)}
                        </Col>


                        {/* {statusFilingStatusList?.length > 0 && (
                          <Col
                            sm={"12"}
                            className={`mb-0 ${errors?.stateFilling && inputPosition >= 12
                              ? "validation-error"
                              : ""
                              }`}
                            onBlur={() => this.validationOnClick(12)}
                          >
                            <Form.Label>State Filing Status</Form.Label>

                            <DropDownList
                              placeHolder={"Select Unemployment State"}
                              data={statusFilingStatusList?.map((x) => ({
                                label: x.stateFilling,
                                value: x.stateFilling,
                              }))}
                              defaultValue={stateFilling}
                              isSearchRequired={false}
                              label={"label"}
                              value={"label"}
                              disabled={status !== "Active"}

                              onDropDownChange={(item) => {
                                if (!item) {
                                  return;
                                }
                                this.onFieldChange(
                                  Utils.BuildCustomEventObject(
                                    "stateFilling",
                                    item.value
                                  ),
                                  12
                                );
                              }}
                              selectedItem={[
                                ...[
                                  {
                                    label: "Select State Filing Status",
                                    value: "",
                                  },
                                ],
                                ...statusFilingStatusList?.map((x) => ({
                                  label: x.stateFilling,
                                  value: x.stateFilling,
                                })),
                              ].find((r: any) => r?.value === stateFilling)}
                            />

                            {this.getFieldError("stateFilling", 12)}
                          </Col>
                      )} */}


                        {this.renderDynamicFormFields(colSize)}
                       
                      </>

                    )}

                        <Col
                          sm={colSize}
                          className={`form-group primary-property-col ${errors?.isPrimary_Property && inputPosition >= 25
                            ? "validation-error"
                            : ""
                            }`}
                          onBlur={() => this.validationOnClick(25)}
                        >
                          <Form.Label>Employee’s Primary Property?</Form.Label>
                          <DropDownList
                            placeHolder={"Select"}
                            data={StaticArrays.yesNoTrueFalseOptions}
                            defaultValue={isPrimary_Property}
                            isSearchRequired={false}
                            label={"label"}
                            value={"value"}
                            disabled={status !== "Active"}

                            onDropDownChange={(item) => {
                              if (!item) {
                                return;
                              }
                              this.onFieldChange(
                                Utils.BuildCustomEventObject(
                                  "isPrimary_Property",
                                  item.value
                                ),
                                25
                              );
                            }}
                            selectedItem={[
                              ...[
                                {
                                  label: "Select",
                                  value: null,
                                },
                              ],
                              ...StaticArrays.yesNoTrueFalseOptions,
                            ].find((r: any) => r?.value === isPrimary_Property)}
                          />
                          {this.getFieldError("isPrimary_Property", 25)}
                        </Col>
                        {showPayrollId  &&
                      <Col
                          sm={colSize}
                          className={`form-group ${errors?.payrollid && inputPosition >= 26
                            ? "validation-error"
                            : ""
                            }`}
                          onBlur={() => this.validationOnClick(26)}
                        >
                          <Form.Label>Payroll ID</Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            id="txtholidayProbPeriod"
                            name="payrollid"
                            //placeholder="0"
                            maxLength={50}
                            autoComplete="txtholidayProbPeriod"
                            disabled={(status !== "Active") || payrollIntegrationDetails?.payrollId !== 0}
                           
                            value={payrollid}
                            onChange={(e) => this.onFieldChange(e, 26)}
                          />
                          {this.getFieldError("payrollid", 26)}
                        </Col>
  }
                  </Row>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-ghost"
                onClick={() => this.hideModal(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.saveHotelPayrollDetails}
              >
                {this.props?.hotelWisePayrollRowEdit?.uniqueNo > 0
                  ? "Edit Entity Wide Setting"
                  : "Add Entity Wide Setting"}{" "}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
