/* eslint-disable array-callback-return */
import React from 'react'
import { FiChevronDown } from "react-icons/fi";
import { Form, Dropdown, Button, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { resolve, reject } from "q";
import Dropzone from 'react-dropzone';
import { toast } from "react-toastify";
import { Register as registersevice } from "../../../../Common/Services/Register";
import { VendorService } from "../../../../Common/Services/vendor";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import { Utils } from "../../../../Common/Utilis";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import OutsideClickHandler from 'react-outside-click-handler';
import { ddlSearchWithTab as SingleSearchDropdownList } from "../../../../Common/Components/ddlSearchWithTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileImage, faFileExcel, faFilePdf, faFileCsv, faFileWord } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";

let isChangeInput: boolean = false;
let isCrDiffrent: boolean = false;
let isExDiffrent: boolean = false;

export default class ContractExpRow extends React.Component<any, any> {
    private typeaheadRef: any;
    constructor(props) {
        super(props)
        this.typeaheadRef = React.createRef();
        this.state = {
            tableData: props?.row,
            vid: props?.vid,
            changeOrdersData: [],
            originalContractData: [],
            fileSize: 5242880,
            addressList: props?.addressList,
            addNewDetails: {
                pName: "",
                pId: "",
                notes: "",
                coa: "",
                coaname: '',
                defaultCOA: [],
                orderAmount: "",
                notesError: false,
                coaError: false,
                amountEror: false
            },
            coaList: [],
            projectList: [],
            contractExpSubmitLoader: false,
            contractExpPageLoader: false,
            addChangeOrderModal: false,
            addOriginalContractModal: false,
            tabEnable: 0,
            tabDisable: -1,
            contractExpRowLoader: false,
            oldaid: props?.row?.aid
        }
    }

    componentDidMount(): void {
        this.getHotelCOAlist()
        this.getFileSize();
    }

    getFileSize() {
        registersevice.GetFileSize()
            .then(async (result: any | null) => {
                if (result > 0) {
                    this.setState({ fileSize: result })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    getHotelCOAlist = async () => {
        let coaRequest: any = { hotelID: this.props?.row.hid, vid: this.state.vid, ForTransferPage: 0 };
        try {
            this.setState({ contractExpRowLoader: true });
            const coaResponse: any = await Promise.all([registersevice.GetTransactionChartList(coaRequest)]);
            const prjResponse: any = await Promise.all([VendorService.GetVendorProjectList(this.state.vid)]);
            if (coaResponse !== null && prjResponse !== null) {
                let { changeOrdersData, originalContractData } = this.state;
                let coaList: any = coaResponse[0];
                let prjList: any = prjResponse[0]?.filter((item: any) => item.name.toString().toLowerCase() !== ("SELECT PROJECT").toLocaleLowerCase());
                prjList = prjList.filter((item: any) => item.hid === this.props?.row.hid);
                let bothTableList = this.props?.row.originalContracts;
                bothTableList.map(item => {
                    item["pName"] = "";
                    item["defaultCOA"] = [];
                    item["editpId"] = "";
                    item["editpName"] = "";
                    item["editnotes"] = "";
                    item["editcoaName"] = "";
                    item["editorderAmount"] = "";
                    item["notesError"] = false;
                    item["coaError"] = false;
                    item["amountEror"] = false;
                });
                originalContractData = bothTableList.filter((item: any) => item.type.toString().toLocaleLowerCase() === "original");
                if (originalContractData.length > 0) {
                    originalContractData.map(item => {
                        prjList.forEach(pItm => {
                            if (pItm.id === item.pid) {
                                item.pName = pItm.name;
                                item.editpId = pItm.id;
                                item.editpName = pItm.name;
                            }
                        })
                        item["editOriginalContractModal"] = false;
                    })
                } else {
                    originalContractData = [];
                }
                changeOrdersData = bothTableList.filter((item: any) => item.type.toString().toLocaleLowerCase() === "additional");
                if (changeOrdersData.length > 0) {
                    changeOrdersData.map(item => {
                        prjList.forEach(pItm => {
                            if (pItm.id === item.pid) {
                                item.pName = pItm.name;
                                item.editpId = pItm.id;
                                item.editpName = pItm.name;
                            }
                        })
                        item["editChangeOrdersModal"] = false;
                    })
                } else {
                    changeOrdersData = [];
                }
                prjList.unshift({ name: "Select Project", id: 0 });
                this.setState({
                    coaList: coaList,
                    projectList: prjList,
                    changeOrdersData,
                    originalContractData,
                    contractExpRowLoader: false
                });
            }
            resolve();
        } catch (error) {
            Utils.toastError(error?.toString(), { position: toast.POSITION.BOTTOM_RIGHT });
            this.setState({ contractExpRowLoader: false });
            reject();
        } finally {
            this.setState({ contractExpRowLoader: false });
        }
    };

    onAttachmentDropContract = (filelist: any) => {
        let size = this.state.fileSize
        let { tableData } = this.state;
        let newfiles = tableData?.newSelectedfiles;
        let isReturn = true;
        let filestext = "";
        let i = 0;
        let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024))
        filelist.forEach(element => {
            if (element.size > size) {
                i = i + 1;
                filestext = filestext + element.name + ","
                isReturn = false;
            }
        })
        if (!isReturn) {
            let msg = "One or more files are greater than " + filesizeval + "MB.";
            Utils.toastError(msg, { position: toast.POSITION.BOTTOM_RIGHT });
        }
        let isInvalidFile = false;
        filelist.forEach(element => {
            if ((element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'pdf'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'doc'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'docx'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'xls'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'xlsx'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'csv'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'txt'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'bmp'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'tif'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'ppt'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'pptx'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'rtf'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'jpg'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'jpeg'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'png'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'msg'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'eml'
            ) && element.size <= size) {
                newfiles.push(element)
            } else {
                isInvalidFile = true;
            }
        });
        if (isInvalidFile && isReturn) {
            let msg = "";
            if (filelist.length === 1) {
                msg = "This file type is not supported."
            } else {
                msg = "Some files are not supported."
            }
            Utils.toastError(msg, { position: toast.POSITION.BOTTOM_RIGHT });
        }
        newfiles = _.sortBy(newfiles, "name");
        tableData.newSelectedfiles = newfiles;
        this.setState({ tableData }, () => {
            this.SaveVendorContractFile(newfiles);
            this.props.setScrollHeight(this.props.scrollRef.current?.scrollTop);
        })
    }

    SaveVendorContractFile = (newfiles) => {
        let { tableData } = this.state;
        let request: any = {}
        request.HID = this.state.tableData?.hid;
        request.VID = this.props?.vid;
        request.AccouuntNo = this.state.tableData?.accountNo.trim();
        VendorService.SaveVendorContractFile(request, newfiles)
            .then((result: any) => {
                if (result.saveStatus.toString().toLocaleLowerCase() === "success") {
                    toast.success(result.message, { position: toast.POSITION.BOTTOM_RIGHT, });
                    this.props.setContractLoader(false);
                    this.props?.reloadContract(true);
                } else {
                    if (result?.messageCode.toLowerCase() === "vendornotexists") {
                        Utils.toastError("This Vendor is already merged into another vendor.", { position: toast.POSITION.BOTTOM_RIGHT });
                        this.props.setContractLoader(false);
                    } else {
                        Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
                        this.props.setContractLoader(false);
                    }
                }
                tableData.newSelectedfiles = [];
                newfiles = [];
                this.setState({ tableData })
            })
            .catch((error) => {
                reject();
                Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
                this.props.setContractLoader(false);
                tableData.newSelectedfiles = [];
                newfiles = [];
                this.setState({ tableData })
            });
    }

    handleSelectFileUpload = (eventKey: any, file: any, index: any) => {
        if (eventKey === "delete") {
            confirmAlert({
                title: "Delete Attachment",
                message:
                    "Are you sure you want to delete?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => this.deleteVendorContractFile(file),
                    },
                    {
                        label: "No",
                        onClick: () => reject(),
                    },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }
        else if (eventKey === "download") {
            this.downloadVendorContractFile(file);
        }
    }

    downloadVendorContractFile = (file) => {
        let request: any = {
            "UniqueNo": "",
            "Name": file.fileName,
            "size": 0,
            "FileUniqueno": 0,
            "UploadFile": (file.fileCode).toString(),
            "Etype": "",
            "FileCode": (file.fileCode).toString(),
            "Pageno": "",
            "CreateDate": "",
        }
        VendorService.DownloadVendorContractFile(request)
            .then((result: any) => { }).catch((error) => {
                reject();
                Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
            });
    }

    deleteVendorContractFile = (file) => {
        this.props.setContractLoader(true);
        let request: any = {
            "Uniqueno": 0,
            "docType": "",
            "expiryDate": "",
            "stratDate": "",
            "Filecode": file.fileCode,
            "Filename": file.fileName
        }
        VendorService.DeleteVendorContractFile(request)
            .then((result: any) => {
                if (result.saveStatus.toString().toLocaleLowerCase() === "success") {
                    toast.success(result.message, { position: toast.POSITION.BOTTOM_RIGHT, });
                    this.props.setContractLoader(false);
                    this.props?.reloadContract(true);
                } else {
                    Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
                    this.props.setContractLoader(false);
                }
            })
            .catch((error) => {
                reject();
                Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
                this.props.setContractLoader(false);
            });
    }

    isValidDate = (date: any) => {
        let reGoodDate = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][\d]|3[01])[- /.](19|20)?[\d]{2})*$/;
        return reGoodDate.test(date);
    }

    handleKeyEvent = (event: any, inputName) => {
        let { value } = event.target;
        let { tableData } = this.state;
        let validDate = value !== "" ? this.isValidDate(value) : false;
        let setExpiryDate: any;
        let newNextPlusDate: any;
        let newDate: any = new Date(new Date().setHours(0, 0, 0, 0));
        let isStDtLess: any;
        let hasLowerExpDate: any;
        let nextDayDate: any;
        if (event.keyCode === 13) {
            if (inputName === "crDate") {
                newNextPlusDate = validDate ? new Date(new Date(value).setDate(new Date(value).getDate() + 1)) : "";
                isStDtLess = newNextPlusDate >= new Date(value);
                hasLowerExpDate = ((isStDtLess ? (newDate <= new Date(value) ? new Date(value) : new Date()) : new Date(value)) >= new Date(tableData?.expDate));
                nextDayDate = validDate ? newNextPlusDate <= newDate ? newDate : newNextPlusDate : "";
                tableData.crDate = validDate ? value : "";
                tableData.expDate = validDate ? hasLowerExpDate ? nextDayDate : tableData.expDate : tableData.expDate;
                tableData.crDateError = false;
                this.setState(() => ({ tableData }));
                if (tableData.oldcrDate !== value) {
                    this.handleContactDetails(new Date(tableData.crDate), "crDate", "enter");
                }
            } else {
                let stDate = tableData?.crDate === "" ? newDate : new Date(new Date(tableData?.crDate).setDate(new Date(tableData?.crDate).getDate() + 1));
                newNextPlusDate = validDate ? stDate : "";
                isStDtLess = newNextPlusDate > (tableData?.crDate === "" ? new Date(event.target.value) : new Date(tableData?.crDate));
                hasLowerExpDate = ((isStDtLess ? (newDate <= new Date(tableData?.crDate) ? new Date(tableData?.crDate) : new Date()) : new Date(tableData?.crDate)) >= new Date(value));
                nextDayDate = validDate ? newNextPlusDate <= newDate ? newDate : newNextPlusDate : "";
                setExpiryDate = validDate ? hasLowerExpDate ? nextDayDate : value : "";
                tableData.expDate = validDate ? setExpiryDate : "";
                tableData.expDateError = false;
            }
            if (inputName === "expDate") {
                tableData.expDate = validDate ? setExpiryDate : "";
                tableData.expDateError = false;

                this.setState(() => ({ tableData }));
                if (tableData.oldexpDate !== value) {
                    this.handleContactDetails(new Date(tableData.expDate), "expDate", "enter");
                }
            }
        }
    }

    accAddress = () => {
        let { tableData, oldaid } = this.state;
        let addressList = this.props.addressList.filter((item: any) => item.adddressID === Number(oldaid));
        tableData.aid = addressList.length > 0 ? addressList[0].adddressID : "";
        tableData.aName = addressList.length > 0 ? addressList[0].address : "";
        tableData.addressError = addressList.length > 0 ? false : true;
        this.setState(() => ({ tableData }))
    }

    handleContactDetails = (event: any, inputName: any, type: any) => {
        this.props.setScrollHeight(this.props.scrollRef.current?.scrollTop);
        let { tableData } = this.state;
        if (inputName === "letterCode") {
            tableData.letterCode = "";
            this.setState(() => ({ tableData }))
        } else if (inputName === "accountNo") {
            let eVal = event.target.value.trimStart();
            if (eVal === "" || eVal.length < 1) {
                tableData.accountNo = eVal;
                tableData.accountError = true;
            } else {
                tableData.accountNo = eVal;
                tableData.accountError = false;
            }
            isChangeInput = true;
            this.setState(() => ({ tableData }))
        } else if (inputName === "address") {
            let addressList = this.props.addressList.filter((item: any) => item.adddressID === Number(event));
            if (addressList.length > 0) {
                if (addressList[0].adddressID !== tableData.aid) {
                    confirmAlert({
                        title: "Alert",
                        message: "Updating a Vendor Contract will affect all previously generated checks. This new information will appear the next time you view or print the check. Are you sure you want to proceed?",
                        buttons: [
                            {
                                label: "Yes", onClick: () => {
                                    tableData.aid = addressList[0].adddressID;
                                    tableData.aName = addressList[0].address;
                                    tableData.addressError = false;
                                    this.setState(() => ({ tableData }), () => { this.updateVendorContract(inputName) })
                                }
                            },
                            { label: "No", onClick: () => this.accAddress() },
                        ],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                    });
                } else {
                    tableData.aid = addressList[0].adddressID;
                    tableData.aName = addressList[0].address;
                    tableData.addressError = false;
                }
            } else {
                tableData.aid = ''
                tableData.aName = ''
                tableData.addressError = true;
                this.setState(() => ({ tableData }))
            }
        } else if (inputName === "crDate") {
            let newNextPlusDate = event !== null ? new Date(new Date(event).setDate(new Date(event).getDate() + 1)) : "";
            let newDate = new Date(new Date().setHours(0, 0, 0, 0));
            let isStDtLess = newNextPlusDate > new Date(event);
            let hasLowerExpDate = ((isStDtLess ? (newDate <= new Date(event) ? new Date(event) : new Date()) : new Date(event)) >= new Date(tableData?.expDate));
            let nextDayDate = event !== null ? newNextPlusDate <= newDate ? newDate : newNextPlusDate : "";
            tableData.crDate = type === "enter" ? tableData?.crDate : event;
            tableData.expDate = hasLowerExpDate ? nextDayDate : tableData?.expDate;
            tableData.crDateError = false;

            let validCrDate = (tableData.crDate !== "" && tableData.crDate !== null && tableData.crDate !== "Invalid Date") && moment(tableData.crDate).format("MM/DD/YYYY");
            let validOldCrDate = (tableData.oldcrDate !== "" && tableData.oldcrDate !== null && tableData.oldcrDate !== "Invalid Date") && moment(tableData.oldcrDate).format("MM/DD/YYYY");
            isCrDiffrent = validCrDate !== validOldCrDate;

            this.setState(() => ({ tableData }), () => {
                if (isCrDiffrent) {
                    this.updateVendorContract(inputName);
                }
            })
        } else if (inputName === "expDate") {
            tableData.expDate = type === "enter" ? tableData?.expDate : event;
            tableData.expDateError = false;

            let validExDate = (tableData.expDate !== "" && tableData.expDate !== null && tableData.expDate !== "Invalid Date") && moment(tableData.expDate).format("MM/DD/YYYY");
            let validOldExDate = (tableData.oldexpDate !== "" && tableData.oldexpDate !== null && tableData.oldexpDate !== "Invalid Date") && moment(tableData.oldexpDate).format("MM/DD/YYYY");
            isExDiffrent = validExDate !== validOldExDate;
            this.setState(() => ({ tableData }), () => {
                if (isExDiffrent) {
                    this.updateVendorContract(inputName);
                }
            })
        } else if (inputName === "comments") {
            let eVal = event.target.value.trimStart();
            tableData.comments = eVal;
            isChangeInput = true;
            this.setState(() => ({ tableData }))
        }
    }

    accNoReset = () => {
        let { tableData } = this.state;
        tableData.accountNo = tableData?.oldAccountNo;
        tableData.accountError = false;
        this.setState(() => ({ tableData }))
    }

    handleBlurContactDetails = (event: any, inputName) => {
        if (inputName === "accountNo") {
            if (event.target.value !== "" || event.target.value.length > 0) {
                if (isChangeInput) {
                    confirmAlert({
                        title: "Alert",
                        message: "Updating a Vendor Contract will affect all previously generated checks. This new information will appear the next time you view or print the check. Are you sure you want to proceed?",
                        buttons: [
                            { label: "Yes", onClick: () => this.updateVendorContract(inputName) },
                            { label: "No", onClick: () => this.accNoReset() },
                        ],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                    });
                }
            } else {
                this.accNoReset();
            }

        } else if (inputName === "comments") {
            if (isChangeInput) { this.updateVendorContract(inputName) }
        }
    }

    resetContactDetails = () => {
        let { tableData } = this.state;
        tableData.accountNo = tableData?.oldAccountNo;
        tableData.crDate = tableData?.oldcrDate;
        tableData.expDate = tableData?.oldexpDate;
        tableData.comments = tableData?.oldComments;
        this.setState(() => ({ tableData }));
    }

    updateVendorContract = (inputName: any) => {
        this.props.setContractLoader(true);
        let { tableData } = this.state;
        let isDateChange = (inputName === "crDate" || inputName === "expDate")
        let request: any = {
            "VID": Number(this.props?.vid),
            "HID": Number(tableData?.hid),
            "AID": Number(tableData?.aid),
            "OldAccountNo": ((tableData?.oldAccountNo !== "" || tableData?.oldAccountNo.length > 0) ? (tableData?.oldAccountNo) : ""),
            "NewAccountNo": ((tableData?.accountNo !== "" || tableData?.accountNo.length > 0) ? (tableData?.accountNo.trim()) : ""),
            "ExpDate": (isDateChange ? tableData?.expDate === "" ? "" : moment(tableData?.expDate).format("DD-MMM-YYYY") : tableData?.expDate),
            "CrDate": (isDateChange ? tableData?.crDate === "" ? "" : moment(tableData?.crDate).format("DD-MMM-YYYY") : tableData?.crDate),
            "Comments": tableData?.comments
        }
        let message: any = "";
        if (inputName === "accountNo") {
            message = "Account Number updated successfully";
        } else if (inputName === "address") {
            message = "Address updated successfully";
        } else if (inputName === "crDate") {
            message = "Start date updated successfully";
        } else if (inputName === "expDate") {
            message = "Expiration date updated successfully";
        } else if (inputName === "comments") {
            message = "Notes updated successfully";
        }
        VendorService.UpdateVendorContract(request)
            .then((result: any) => {
                if (result.success) {
                    toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT, });
                    this.props?.reloadContract(true);
                } else {
                    if (result.result[0].result.toString().toLocaleLowerCase() === "samecontractexists") {
                        Utils.toastError("Account number already exists.", { position: toast.POSITION.BOTTOM_RIGHT, });
                    } else if (result.result[0].result.toString().toLocaleLowerCase() === "vendornotexists") {
                        Utils.toastError("This Vendor is already merged into another vendor.", { position: toast.POSITION.BOTTOM_RIGHT, });
                    } else if (result.result[0].result.toString().toLocaleLowerCase() === "cardcontractnotchange") {
                        Utils.toastError("Account no. cannot be changed as this vendor is associated with a credit card.", { position: toast.POSITION.BOTTOM_RIGHT, });
                    } else if (result.result[0].result.toString().toLocaleLowerCase() === "cardcontractnotinactive") {
                        Utils.toastError("Account no. cannot be inactivated as this vendor is associated with a credit card.", { position: toast.POSITION.BOTTOM_RIGHT, });
                    } else {
                        Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
                    }
                    this.resetContactDetails();
                }
                this.props.setContractLoader(false);
                isChangeInput = false; isCrDiffrent = false; isExDiffrent = false;
            })
            .catch((error) => {
                reject();
                Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
                this.props.setContractLoader(false);
                this.resetContactDetails();
                isChangeInput = false; isCrDiffrent = false; isExDiffrent = false;
            });
    }

    handleRowEvent = (event: any, row: any, type: any) => {
        this.resetaddNewDetails();
        let { changeOrdersData, originalContractData, coaList, projectList } = this.state;
        if (type === "originalContract") {
            if (event === "editRow") {
                originalContractData = originalContractData.map((items) => {
                    if (items.mainUNiqueno === row.mainUNiqueno) {
                        let hasCoaName: any = [];
                        let filterProject = projectList.filter((item: any) => item.id === row.pid);
                        coaList.forEach(item => item.id === row.coa && hasCoaName.push(item));
                        return {
                            ...items,
                            editOriginalContractModal: true,
                            editpId: filterProject.length > 0 ? filterProject[0].id : 0,
                            editpName: filterProject.length > 0 ? filterProject[0].name : "",
                            editnotes: row.notes,
                            coa: hasCoaName.length > 0 ? hasCoaName[0].id : "",
                            editcoaName: hasCoaName.length > 0 ? hasCoaName[0].name : "",
                            defaultCOA: hasCoaName.length > 0 ? hasCoaName : [],
                            editorderAmount: Utils.currencyFormat(row.orderAmount)
                        };
                    } else {
                        return items;
                    }
                });
                this.setState((curr: any) => ({ ...curr, originalContractData }), () => {
                    this.addEditDetailsContainer(row, "editOriginalContract");
                });
            } else if (event === "deleteRow") {
                this.deleteOrgContract(row, type);
            }
        } else if (type === "changeOrders") {
            if (event === "editRow") {
                changeOrdersData = changeOrdersData.map((items) => {
                    if (items.mainUNiqueno === row.mainUNiqueno) {
                        let hasCoaName: any = [];
                        let filterProject = projectList.filter((item: any) => item.id === row.pid);
                        coaList.forEach(item => item.id === row.coa && hasCoaName.push(item));
                        return {
                            ...items,
                            editChangeOrdersModal: true,
                            editpId: filterProject.length > 0 ? filterProject[0].id : 0,
                            editpName: filterProject.length > 0 ? filterProject[0].name : "",
                            editnotes: row.notes,
                            coa: hasCoaName.length > 0 ? hasCoaName[0].id : "",
                            editcoaName: hasCoaName.length > 0 ? hasCoaName[0].name : "",
                            defaultCOA: hasCoaName.length > 0 ? hasCoaName : [],
                            editorderAmount: Utils.currencyFormat(row.orderAmount)
                        };
                    } else {
                        return items;
                    }
                });
                this.setState((curr: any) => ({ ...curr, changeOrdersData }), () => {
                    this.addEditDetailsContainer(row, "editChangeOrders");
                });
            } else if (event === "deleteRow") {
                this.deleteOrgContract(row, type)
            }
        }
        this.props.setScrollHeight(this.props.scrollRef.current?.scrollTop);
    }

    renderMenuItemChildren = (option, props, index) => {
        return (
            <div key={option.id}>
                <div className="vendor-name">
                    <EllipsisWithTooltip placement="bottom">
                        {option.label}
                    </EllipsisWithTooltip>
                </div>
            </div>
        );
    }

    addEditDetailsContainer = (row, type) => {
        let { addNewDetails } = this.state;
        let isEditForm = type.toString().includes(("edit").toLocaleLowerCase());
        let propId = isEditForm ? row.mainUNiqueno.toString() : "1";
        let headerName = "";
        if (type === "editOriginalContract") {
            headerName = "Edit Original Contract";
        } else if (type === "addOriginalContract") {
            headerName = "Add Original Contract";
        } else if (type === "editChangeOrders") {
            headerName = "Edit Change Order";
        } else if (type === "addChangeOrders") {
            headerName = "Add Change Order";
        } else {
            headerName = "";
        }

        return (
            <div className="generalEditForm">
                <div className="formTitle">
                    <h4 className="mr-auto">{headerName}</h4>
                    <button type="button" className="btn wht-bg cross" onClick={() => this.hideAddEditModal(isEditForm ? row : "", type)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#84888C" />
                        </svg>
                    </button>
                </div>
                <div className="formContainer">
                    <Form.Group controlId="projectSelection">
                        <Form.Label>Project</Form.Label>
                        <div className="isComponentDropdown" onClick={this.hoverOutEllipsis.bind(this)}>
                            <SingleSearchDropdownList
                                tabIndex={this.state.tabEnable}
                                itemList={this.state.projectList}
                                handleSelectedItem={this.handleFormSelect.bind(this, type, isEditForm ? row : addNewDetails)}
                                defaultItem={isEditForm ? row.editpName === "" ? "Select Project" : row.editpName : addNewDetails.pName === "" ? "Select Project" : addNewDetails.pName}
                                defaultText={"Search Project"}
                                defaultName={isEditForm ? row.editpName === "" ? "Select Project" : row.editpName : addNewDetails.pName === "" ? "Select Project" : addNewDetails.pName}
                                controlID="4"
                                id={"ddlProject" + propId}
                                onTAB={this.onTAB.bind(this, "ddlProject" + propId)}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group controlId="descriptionSelection">
                        <Form.Label>Description</Form.Label>
                        <input
                            type="text"
                            maxLength={100}
                            className={`${(row.notesError || addNewDetails.notesError) ? "borderError" : ""} form-control`}
                            value={isEditForm ? row.editnotes : addNewDetails.notes}
                            onChange={(event: any) => this.handleFormChange(event, type, isEditForm ? row : addNewDetails, "description")}
                        />
                        {(row.notesError || addNewDetails.notesError) && (<div className="textError">Please enter description.</div>)}
                    </Form.Group>
                    <Form.Group controlId="coaSelection">
                        <Form.Label>COA</Form.Label>
                        <div className={`${addNewDetails.coaError || row.coaError ? "typeheadCoaError" : ""} more-action single-select-image dropdown coa-select jecoaSelect tabTypeHeadControl`}>
                            <Typeahead
                                ref={this.typeaheadRef}
                                flip={true}
                                inputProps={{ className: "coa" + propId }}
                                id={"coa-" + propId}
                                options={this.state.coaList}
                                placeholder={"Select COA"}
                                renderMenu={(results: any, menuProps) => (
                                    <Menu {...menuProps}>
                                        {results.map((result: any, index: any) => (
                                            <>
                                                {result.label.toString() !== "" ? (
                                                    <MenuItem option={result} position={index}>
                                                        <EllipsisWithTooltip placement="bottom">{result.label}</EllipsisWithTooltip>
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem className="load-more" option={result} position={index}> Load More</MenuItem>
                                                )}
                                                {(this.state.coaList.length > 0 && results[index - 1]?.orderno === 1 && result?.orderno === 2) && (
                                                    <Dropdown.Divider />
                                                )}
                                            </>
                                        ))}
                                    </Menu>
                                )}
                                renderMenuItemChildren={this.renderMenuItemChildren}
                                onChange={(event: any) => this.handleFormChange(event, type, isEditForm ? row : addNewDetails, "coa")}
                                selected={isEditForm ? row.defaultCOA : addNewDetails.defaultCOA}
                            />
                        </div>
                        {(addNewDetails.coaError || row.coaError) && (<div className="textError">Please select COA.</div>)}
                    </Form.Group>

                    <Form.Group controlId="amountSelection">
                        <Form.Label>Amount</Form.Label>
                        <input
                            type="text"
                            maxLength={50}
                            className={`${(row.amountEror || addNewDetails.amountEror) ? "borderError" : ""} form-control`}
                            id="amountInput"
                            value={isEditForm ? row.editorderAmount : addNewDetails.orderAmount}
                            onKeyDown={(e: any) => this.handleTabAmount(e, isEditForm ? row : addNewDetails, type)}
                            onChange={(event: any) => this.handleFormChange(event, type, isEditForm ? row : addNewDetails, "orderAmount")}
                            onBlur={(e: any) => this.handleBlurAmount(e, isEditForm ? row : addNewDetails, type)}
                            onFocus={(e: any) => this.onSplitAmountFocus(e, isEditForm ? row : addNewDetails, type)}
                        />
                        {(row.amountEror || addNewDetails.amountEror) && (<div className="textError">Please enter amount.</div>)}
                    </Form.Group>
                </div>
                <div className="formFooter">
                    <Button onClick={(e: any) => this.handleSaveVendorContract(isEditForm ? row : addNewDetails, type)}>Save</Button>
                </div>
            </div>
        )
    }

    handleFormSelect = (actionType: any, row: any, event: any) => {
        let { originalContractData, changeOrdersData, addNewDetails, projectList } = this.state;
        if (actionType === "editOriginalContract") {
            let currentProj = projectList.filter((item: any) => item.id === Number(event));
            originalContractData = originalContractData.map((items) => {
                if (items.mainUNiqueno === row.mainUNiqueno) {
                    return {
                        ...items,
                        editpId: currentProj[0].id,
                        editpName: currentProj[0].name,
                    };
                } else {
                    return items;
                }
            });
        } else if (actionType === "editChangeOrders") {
            let currentProj = projectList.filter((item: any) => item.id === Number(event));
            changeOrdersData = changeOrdersData.map((items) => {
                if (items.mainUNiqueno === row.mainUNiqueno) {
                    return {
                        ...items,
                        editpId: currentProj[0].id,
                        editpName: currentProj[0].name,
                    };
                } else {
                    return items;
                }
            });
        } else if (actionType === "addOriginalContract" || actionType === "addChangeOrders") {
            let currentProj = projectList.filter((item: any) => item.id === Number(event));
            addNewDetails.pId = currentProj[0].id;
            addNewDetails.pName = currentProj[0].name;
        }
        this.setState((curr: any) => ({ ...curr, changeOrdersData, addNewDetails, originalContractData }));
    }

    handleValidAmount = (orderAmount) => {
        let newVal = Utils.removeInvaildCharFromAmount(orderAmount);
        orderAmount = newVal;
        const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
        let lenbeforePoint = 8;
        if (orderAmount.includes("-")) {
            lenbeforePoint = 9;
        }
        let validAmount: any;
        if (rx_live.test(orderAmount)) {
            if (orderAmount.includes(".")) {
                const timeArray = (orderAmount)?.split(".");
                if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
                    validAmount = orderAmount;
                }
            } else {
                if (orderAmount.length <= lenbeforePoint) {
                    validAmount = orderAmount;
                }
            }
        } else if (orderAmount.includes(".") && orderAmount.length === 1) {
            validAmount = orderAmount;
        }
        return validAmount
    }

    isValidOnBlurAmount = (e) => {
        let afterBlurAmount: any;
        if (!isNaN(e.target.value)) {
            if (e.target.value.includes(".")) {
                const timeArray = (e.target.value)?.split(".");
                if (timeArray[1].length <= 0) {
                    afterBlurAmount = Utils.currencyFormat(timeArray[0]);;
                } else {
                    afterBlurAmount = Utils.currencyFormat(e.target.value);
                }
            } else {
                afterBlurAmount = e.target.value === '' ? '' : !isNaN(e.target.value) ? Utils.currencyFormat(e.target.value) : e.target.value;
            }
        } else {
            afterBlurAmount = "";
        }
        return afterBlurAmount;
    }

    onSplitAmountFocus = (event, row, actionType) => {
        let { originalContractData, changeOrdersData, addNewDetails } = this.state;
        let newval = Utils.removecurrencyFormat(event.target.value);
        let validAmount = newval;
        if (actionType === "editOriginalContract") {
            originalContractData = originalContractData.map((items) => {
                if (items.mainUNiqueno === row.mainUNiqueno) {
                    return {
                        ...items,
                        editorderAmount: validAmount,
                    };
                } else {
                    return items;
                }
            });
        } else if (actionType === "editChangeOrders") {
            changeOrdersData = changeOrdersData.map((items) => {
                if (items.mainUNiqueno === row.mainUNiqueno) {
                    return {
                        ...items,
                        editorderAmount: validAmount,
                    };
                } else {
                    return items;
                }
            });
        } else if (actionType === "addOriginalContract") {
            addNewDetails.orderAmount = validAmount;
        } else if (actionType === "addChangeOrders") {
            addNewDetails.orderAmount = validAmount;
        }
        this.setState((curr: any) => ({ ...curr, originalContractData, changeOrdersData, addNewDetails }), () => {
            $("#amountInput").focus();
        });
    }

    handleBlurAmount = (event, row, actionType) => {
        let { originalContractData, changeOrdersData, addNewDetails } = this.state;
        let validAmount = this.isValidOnBlurAmount(event)
        if (actionType === "editOriginalContract") {
            originalContractData = originalContractData.map((items) => {
                if (items.mainUNiqueno === row.mainUNiqueno) {
                    return {
                        ...items,
                        editorderAmount: validAmount,
                    };
                } else {
                    return items;
                }
            });
        } else if (actionType === "editChangeOrders") {
            changeOrdersData = changeOrdersData.map((items) => {
                if (items.mainUNiqueno === row.mainUNiqueno) {
                    return {
                        ...items,
                        editorderAmount: validAmount,
                    };
                } else {
                    return items;
                }
            });
        } else if (actionType === "addOriginalContract") {
            addNewDetails.orderAmount = validAmount;
        } else if (actionType === "addChangeOrders") {
            addNewDetails.orderAmount = validAmount;
        }
        this.setState((curr: any) => ({ ...curr, originalContractData, changeOrdersData, addNewDetails }));
    }

    handleTabAmount = (event, row, actionType) => {
        let validAmount = event.target.value === '' ? '' : !isNaN(event.target.value) ? Number(event.target.value).toFixed(2) : 0
        let { originalContractData, changeOrdersData, addNewDetails } = this.state;
        if (event.key.toLowerCase() === "tab") {
            if (actionType === "editOriginalContract") {
                originalContractData = originalContractData.map((items) => {
                    if (items.mainUNiqueno === row.mainUNiqueno) {
                        return {
                            ...items,
                            editorderAmount: validAmount,
                        };
                    } else {
                        return items;
                    }
                });
            } else if (actionType === "editChangeOrders") {
                changeOrdersData = changeOrdersData.map((items) => {
                    if (items.mainUNiqueno === row.mainUNiqueno) {
                        return {
                            ...items,
                            editorderAmount: validAmount,
                        };
                    } else {
                        return items;
                    }
                });
            } else if (actionType === "addOriginalContract") {
                addNewDetails.orderAmount = validAmount;
            } else if (actionType === "addChangeOrders") {
                addNewDetails.orderAmount = validAmount;
            }
        }
        this.setState((curr: any) => ({ ...curr, originalContractData, changeOrdersData, addNewDetails }));
    }

    handleFormChange = (event: any, actionType: any, row: any, inputName) => {
        let { originalContractData, changeOrdersData, addNewDetails } = this.state;
        if (actionType === "editOriginalContract") {
            originalContractData = originalContractData.map((items) => {
                if (items.mainUNiqueno === row.mainUNiqueno) {
                    if (inputName === "description") {
                        let value = (event.target.value).trimStart();
                        if (value.length > 0) {
                            return { ...items, editnotes: value, notesError: false };
                        } else {
                            return { ...items, editnotes: value, notesError: true };
                        }
                    } else if (inputName === "coa") {
                        if (event.length > 0) {
                            return { ...items, defaultCOA: event, coa: event[0].id, editcoaName: event[0].name, coaError: false };
                        } else {
                            return { ...items, defaultCOA: [], /*coa: '',*/ editcoaName: '', coaError: true };
                        }
                    } else if (inputName === "orderAmount") {
                        let validAmount = this.handleValidAmount(event.target.value);
                        if (validAmount.length > 0) {
                            return { ...items, editorderAmount: validAmount, amountEror: false };
                        } else {
                            return { ...items, editorderAmount: validAmount, amountEror: true };
                        }
                    }
                } else {
                    return items;
                }
            });
            this.setState((curr: any) => ({ ...curr, originalContractData }));
        } else if (actionType === "editChangeOrders") {
            changeOrdersData = changeOrdersData.map((items) => {
                if (items.mainUNiqueno === row.mainUNiqueno) {
                    if (inputName === "description") {
                        let value = (event.target.value).trimStart();
                        if (value.length > 0) {
                            return { ...items, editnotes: value, notesError: false };
                        } else {
                            return { ...items, editnotes: value, notesError: true };
                        }
                    } else if (inputName === "coa") {
                        if (event.length > 0) {
                            return { ...items, defaultCOA: event, coa: event[0].id, editcoaName: event[0].name, coaError: false };
                        } else {
                            return { ...items, defaultCOA: [], /*coa: '',*/ editcoaName: '', coaError: true };
                        }
                    } else if (inputName === "orderAmount") {
                        let validAmount = this.handleValidAmount(event.target.value);
                        if (validAmount.length > 0) {
                            return { ...items, editorderAmount: validAmount, amountEror: false };
                        } else {
                            return { ...items, editorderAmount: validAmount, amountEror: true };
                        }
                    }
                } else {
                    return items;
                }
            });
        } else if (actionType === "addOriginalContract") {
            if (inputName === "description") {
                let value = (event.target.value).trimStart();
                addNewDetails.notes = value;
                if (value.length > 0) {
                    addNewDetails.notesError = false
                } else {
                    addNewDetails.notesError = true
                }
            } else if (inputName === "coa") {
                addNewDetails.defaultCOA = event.length > 0 ? event : [];
                addNewDetails.coa = event.length > 0 ? event[0].id : '';
                addNewDetails.coaname = event.length > 0 ? event[0].name : '';
                if (addNewDetails.coaname === "") {
                    addNewDetails.coaError = true;
                } else {
                    addNewDetails.coaError = false;
                }
            } else if (inputName === "orderAmount") {
                let validAmount = this.handleValidAmount(event.target.value);
                addNewDetails.orderAmount = validAmount
                if (validAmount.length > 0) {
                    addNewDetails.amountEror = false
                } else {
                    addNewDetails.amountEror = true
                }
            }
        } else if (actionType === "addChangeOrders") {
            if (inputName === "description") {
                addNewDetails.notes = (event.target.value).trimStart();
            } else if (inputName === "coa") {
                addNewDetails.defaultCOA = event.length > 0 ? event : [];
                addNewDetails.coa = event.length > 0 ? event[0].id : '';
                addNewDetails.coaname = event.length > 0 ? event[0].name : '';
            } else if (inputName === "orderAmount") {
                let validAmount = this.handleValidAmount(event.target.value);
                addNewDetails.orderAmount = validAmount
            }
        }
        this.props.setScrollHeight(this.props.scrollRef.current?.scrollTop);
        if (inputName === "coa" && event.length === 0) { this.typeaheadRef?.current.clear(); }
        this.setState((curr: any) => ({ ...curr, changeOrdersData, addNewDetails }));
    }


    resetaddNewDetails = () => {
        const defaultAddObject: any = {
            pName: "",
            pId: "",
            notes: "",
            coa: "",
            coaname: '',
            defaultCOA: [],
            orderAmount: "",
            notesError: false,
            coaError: false,
            amountEror: false
        }
        this.setState(() => ({ addNewDetails: defaultAddObject }));
    }

    showAddEditModal = (row: any, type: any) => {
        if (type === "addChangeOrders") {
            this.setState({ addChangeOrderModal: true, }, () => {
                this.resetaddNewDetails();

            });
        } else if (type === "addOriginalContract") {
            this.setState({ addOriginalContractModal: true }, () => { this.resetaddNewDetails(); });
        }
        this.props.setScrollHeight(this.props.scrollRef.current?.scrollTop);
    }

    hideAddEditModal = (row: any, type: any) => {
        let { originalContractData, changeOrdersData } = this.state;
        if (type === "addChangeOrders") {
            this.setState(() => ({
                addChangeOrderModal: false,
            }), () => { this.resetaddNewDetails(); });
        } else if (type === "addOriginalContract") {
            this.setState(() => ({
                addOriginalContractModal: false,
            }));
        } else if (type === "editOriginalContract") {
            originalContractData = originalContractData.map((items) => {
                if (items.mainUNiqueno === row.mainUNiqueno) {
                    return {
                        ...items,
                        editOriginalContractModal: false,
                        notesError: false,
                        coaError: false,
                        amountEror: false
                    };
                } else {
                    return items;
                }
            });
            this.setState((curr: any) => ({ ...curr, originalContractData }));
        } else if (type === "editChangeOrders") {
            changeOrdersData = changeOrdersData.map((items) => {
                if (items.mainUNiqueno === row.mainUNiqueno) {
                    return {
                        ...items,
                        editChangeOrdersModal: false,
                        notesError: false,
                        coaError: false,
                        amountEror: false
                    };
                } else {
                    return items;
                }
            });
            this.setState((curr: any) => ({ ...curr, changeOrdersData }));
        }
    }

    hoverOutEllipsis = () => {
        $(".tooltip").removeClass("show");
    };

    onTAB = (control, event) => { }

    handleKeyDown = (event, type) => {
        if (event.key.toLowerCase() === "enter") {
            if (type === "droparea") {
                $("div[id^=droparea]").focus();
            }
        }
    }

    deleteOrgContract = (row: any, type: any) => {
        confirmAlert({
            title: "Delete entry",
            message: "Do you want to delete this record?",
            buttons: [{
                label: "Yes", onClick: () => {
                    this.props.setContractLoader(true);
                    let message: any = "";
                    message = type === "originalContract" ? "Original contract" : "Change order";
                    VendorService.DeleteOrgContract(row.mainUNiqueno)
                        .then((result: any) => {
                            if (result.success) {
                                toast.success(`${message} entry deleted successfully`, { position: toast.POSITION.BOTTOM_RIGHT, });
                                this.props.setContractLoader(false);
                                this.props?.reloadContract(true);
                            } else {
                                Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
                                this.props.setContractLoader(false);
                            }
                        })
                        .catch((error) => {
                            reject();
                            Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, });
                            this.props.setContractLoader(false);

                        });
                },
            }, {
                label: "No", onClick: () => reject(),
            }],
        });

    }

    handleSaveVendorContract = (row: any, type: any) => {
        this.props.setScrollHeight(this.props.scrollRef.current?.scrollTop);
        let { tableData, addNewDetails, changeOrdersData, originalContractData } = this.state;
        let isEditForm = type.toString().includes(("edit").toLocaleLowerCase());
        if (isEditForm) {
            if (type === "editOriginalContract") {
                originalContractData.forEach((items) => {
                    if (items.mainUNiqueno === row.mainUNiqueno) {
                        if (items.editnotes === "") {
                            items.notesError = true;
                        } else if (items.editnotes !== "") {
                            items.notesError = false;
                            if (items.editcoaName === "") {
                                items.coaError = true;
                            } else if (items.editcoaName !== "") {
                                items.coaError = false;
                                if (items.editorderAmount === "") {
                                    items.amountEror = true;
                                } else {
                                    items.amountEror = false;

                                    let request: any = {
                                        "VID": this.state.vid,
                                        "Contracts": {
                                            "AHID": tableData?.hid,
                                            "AAccountNo": tableData?.accountNo,
                                            "SrNo": items.srNo,
                                            "COA": items.coa,
                                            "COAName": items.editcoaName,
                                            "OrderAmount": Utils.removecurrencyFormat(items.editorderAmount),
                                            "Notes": items.editnotes.trim(),
                                            "PID": items.editpId === "" ? 0 : items.editpId,
                                            "Type": "original",
                                            "MainUNiqueno": items.mainUNiqueno
                                        }
                                    };
                                    this.saveContractForm(request, type, row);
                                    this.props.setContractLoader(true);
                                }
                            }
                        }
                    }
                });
                this.setState(() => ({ originalContractData }));
            } else {
                changeOrdersData.forEach((items) => {
                    if (items.mainUNiqueno === row.mainUNiqueno) {
                        if (items.editnotes === "") {
                            items.notesError = true;
                        } else if (items.editnotes !== "") {
                            items.notesError = false;
                            if (items.editcoaName === "") {
                                items.coaError = true;
                            } else if (items.editcoaName !== "") {
                                items.coaError = false;
                                if (items.editorderAmount === "") {
                                    items.amountEror = true;
                                } else {
                                    items.amountEror = false;
                                    let request: any = {
                                        "VID": this.state.vid,
                                        "Contracts": {
                                            "AHID": tableData?.hid,
                                            "AAccountNo": tableData?.accountNo,
                                            "SrNo": items.srNo,
                                            "COA": items.coa,
                                            "COAName": items.editcoaName,
                                            "OrderAmount": Utils.removecurrencyFormat(items.editorderAmount),
                                            "Notes": items.editnotes.trim(),
                                            "PID": items.editpId === "" ? 0 : items.editpId,
                                            "Type": "additional",
                                            "MainUNiqueno": items.mainUNiqueno
                                        }
                                    };
                                    this.saveContractForm(request, type, row);
                                    this.props.setContractLoader(true);
                                }
                            }
                        }
                    }
                });
                this.setState(() => ({ changeOrdersData }));
            }
        } else {
            if (type === "addOriginalContract" || type === "addChangeOrders") {
                let ischangeorder = type === "addChangeOrders";
                if (addNewDetails.notes === "") {
                    addNewDetails.notesError = true;
                } else if (addNewDetails.notes !== "") {
                    addNewDetails.notesError = false;
                    if (addNewDetails.coaname === "") {
                        addNewDetails.coaError = true;
                    } else if (addNewDetails.coaname !== "") {
                        addNewDetails.coaError = false;
                        if (addNewDetails.orderAmount === "") {
                            addNewDetails.amountEror = true;
                        } else if (addNewDetails.orderAmount !== "") {
                            addNewDetails.amountEror = false;
                            let tableLen = changeOrdersData.length + originalContractData.length + 1;
                            let request: any = {
                                "VID": this.state.vid,
                                "Contracts": {
                                    "AHID": tableData?.hid,
                                    "AAccountNo": tableData?.accountNo,
                                    "SrNo": tableLen,
                                    "COA": addNewDetails.coa,
                                    "COAName": addNewDetails.coaname,
                                    "OrderAmount": Utils.removecurrencyFormat(addNewDetails.orderAmount),
                                    "Notes": addNewDetails.notes.trim(),
                                    "PID": addNewDetails.pId === "" ? 0 : addNewDetails.pId,
                                    "Type": ischangeorder ? "additional" : "original",
                                    "MainUNiqueno": 0
                                }
                            };
                            this.saveContractForm(request, type, '');
                            this.props.setContractLoader(true);
                        }
                    }
                }
                this.setState(() => ({ addNewDetails }))
            }
        }
    }

    saveContractForm = (request, type, row) => {
        VendorService.SaveVendorOrgContract(request)
            .then((result: any) => {
                if (result.success) {
                    let message: any = ''
                    if (type === "editOriginalContract") {
                        message = "Original Contract updated successfully";
                    } else if (type === "addOriginalContract") {
                        message = "Original Contract added successfully";
                    } else if (type === "editChangeOrders") {
                        message = "change Order updated successfully";
                    } else if (type === "addChangeOrders") {
                        message = "Change Order added successfully";
                    }
                    toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
                    this.props?.reloadContract(true);
                } else {
                    if (result.result[0]?.result.toString().toLocaleLowerCase() === "vendornotexists") {
                        Utils.toastError("This Vendor is already merged into another vendor.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else {
                        Utils.toastError(result.result[0]?.result, { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }
                this.props.setContractLoader(false);
                this.hideAddEditModal(row, type);
                resolve();
            }).catch((error: any) => {
                this.props.setContractLoader(false);
                this.hideAddEditModal(row, type);
                Utils.toastError(error, { position: toast.POSITION.BOTTOM_RIGHT });
                reject();
            });
    }

    render() {
        let { tableData } = this.state;
        let noEditPermission = this.props?.isEditVendor ? false : true;
        const acceptedFiles = `image/*,application/pdf,
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
        application/vnd.ms-excel,
        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
        text/plain,
        .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml`;

        const renderEllipsisWithTooltip = (value: any) => (<EllipsisWithTooltip placement="bottom">{value}</EllipsisWithTooltip>);

        const renderCusAddEditModal = (row: any, type: any) => (
            <div className="generalCusAddEditModal">
                <Dropdown className="more-action" alignRight onSelect={(event: any) => this.handleRowEvent(event, row, type === "original" ? "originalContract" : "changeOrders")}>
                    <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                        </svg>
                    </Dropdown.Toggle>
                    
                    {type === "original" && row.editOriginalContractModal && (
                        <OutsideClickHandler onOutsideClick={() => this.hideAddEditModal(row, "editOriginalContract")}>
                            <div className="generalEditAddressModal">
                                {this.addEditDetailsContainer(row, "editOriginalContract")}
                            </div>
                        </OutsideClickHandler>
                    )}
                    {type === "change" && row.editChangeOrdersModal && (
                        <OutsideClickHandler onOutsideClick={() => this.hideAddEditModal(row, "editChangeOrders")}>
                            <div className="generalEditAddressModal">
                                {this.addEditDetailsContainer(row, "editChangeOrders")}
                            </div>
                        </OutsideClickHandler>
                    )}
                    {noEditPermission ? (<></>) : (
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="editRow">Edit</Dropdown.Item>
                            <Dropdown.Item eventKey="deleteRow">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    )}
                </Dropdown>
            </div>
        )

        const originalContractColumns = [{
            dataField: 'pName',
            headerClasses: "expProjectCol",
            classes: "expProjectCol",
            text: 'Project',
            formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.pName)
        }, {
            dataField: 'notes',
            text: 'Description',
            headerClasses: "expDescriptionCol",
            classes: "expDescriptionCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.notes)
        }, {
            dataField: 'coaName',
            text: 'COA',
            headerClasses: "expCoaCol",
            classes: "expCoaCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.coaName)
        }, {
            dataField: 'orderAmount',
            text: 'Amount',
            headerClasses: "expAmountCol",
            classes: "expAmountCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(Utils.currencyFormatWithOutdoller(row.orderAmount))
        }, {
            dataField: 'action',
            text: '',
            headerClasses: "docIconCol",
            classes: "docIconCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderCusAddEditModal(row, "original")
        }]

        const changeOrdersColumns = [{
            dataField: 'pName',
            headerClasses: "expProjectCol",
            classes: "expProjectCol",
            text: 'Project',
            formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.pName)
        }, {
            dataField: 'notes',
            text: 'Description',
            headerClasses: "expDescriptionCol",
            classes: "expDescriptionCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.notes)
        }, {
            dataField: 'coaName',
            text: 'COA',
            headerClasses: "expCoaCol",
            classes: "expCoaCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.coaName)
        }, {
            dataField: 'orderAmount',
            text: 'Amount',
            headerClasses: "expAmountCol",
            classes: "expAmountCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(Utils.currencyFormatWithOutdoller(row.orderAmount))
        }, {
            dataField: 'action',
            text: '',
            headerClasses: "docIconCol",
            classes: "docIconCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderCusAddEditModal(row, "change")
        }]

        const vendorDetailsLoading = (number) => {
            let newCount: any = [];
            for (let i = 0; i < Number(number); i++) { newCount.push(i) }
            return (
                <div className="loader-responsive register">
                    <div className="performanceList vendorDetails">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    {newCount.map((item) => (
                                        <div key={item} className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        const billingDetailsLoading = (number) => {
            let newCount: any = [];
            for (let i = 0; i < Number(number); i++) { newCount.push(i) }
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    {newCount.map((item) => (
                                        <div key={item} className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        const contractExpRowAnimation = () => {
            return (
                <>
                    <div className="bulkImportList mt-0">
                        <>{vendorDetailsLoading(2)}</>
                        <>{vendorDetailsLoading(2)}</>
                        <>{vendorDetailsLoading(2)}</>
                        <>{vendorDetailsLoading(2)}</>
                        <>{vendorDetailsLoading(2)}</>
                    </div>
                    <div className="separator-line"></div>
                    <div className="bulkImportList mt-0">
                        <div className="bulkImportList1 d-flex">
                            <div className="single-loader loadingAnimation"></div>
                        </div>
                        <>{billingDetailsLoading(8)}</>
                        <>{billingDetailsLoading(8)}</>
                        <>{billingDetailsLoading(8)}</>
                    </div>
                </>
            )
        }

        const files = tableData?.newSelectedfiles.map((file, index) => {
            return (
                <>
                    <li key={file?.name}>
                        <div className="d-flex align-items-center justify-content-start">
                            <div className="icon">
                                {(file?.type)?.split('/')[0] === 'image' && (
                                    <FontAwesomeIcon icon={faFileImage} />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'pdf' && (
                                    <FontAwesomeIcon icon={faFilePdf} />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'xlsx' && (
                                    <FontAwesomeIcon icon={faFileExcel} />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'csv' && (
                                    <FontAwesomeIcon icon={faFileCsv} />
                                )}
                                {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'word' && (
                                    <FontAwesomeIcon icon={faFileWord} />
                                )}
                                {(((file?.type)?.split('/')[0] !== 'image') &&
                                    ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'pdf') &&
                                    ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'xlsx')
                                    && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'csv')
                                    && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'word'))
                                    && (
                                        <FontAwesomeIcon icon={faFile} />
                                    )}
                            </div>
                            <div className="file-info d-flex">
                                <div className="name-loader">
                                    <div className="name d-flex">
                                        <div className="full-name">{file?.name}</div>
                                    </div>
                                </div>
                                <div className="formate-percentage">
                                    <div className="formate d-flex justify-content-center align-items-center">
                                        {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length))}
                                    </div>
                                </div>
                            </div>
                            <div className="action">
                                {tableData?.newSelectedfiles.length > 0 && (<>
                                    <Spinner size="sm" title="Uploading..." animation="border" variant="success" />
                                </>)}
                            </div>
                        </div>
                    </li>
                </>
            )
        });

        const files1 = tableData?.contractsDocuments.map((file, index) => (
            <>
                <li key={file.fileName}>
                    <div className="d-flex align-items-center justify-content-start">
                        <div className="icon">
                            {(file?.type)?.split('/')[0] === 'image' && (
                                <FontAwesomeIcon icon={faFileImage} />
                            )}
                            {(file?.fileName.substring(file?.fileName.lastIndexOf(".") + 1, file?.fileName.length)) === 'pdf' && (
                                <FontAwesomeIcon icon={faFilePdf} />
                            )}
                            {(file?.fileName.substring(file?.fileName.lastIndexOf(".") + 1, file?.fileName.length)) === 'xlsx' && (
                                <FontAwesomeIcon icon={faFileExcel} />
                            )}
                            {(file?.fileName.substring(file?.fileName.lastIndexOf(".") + 1, file?.fileName.length)) === 'csv' && (
                                <FontAwesomeIcon icon={faFileCsv} />
                            )}
                            {(file?.fileName.substring(file?.fileName.lastIndexOf(".") + 1, file?.fileName.length)) === 'word' && (
                                <FontAwesomeIcon icon={faFileWord} />
                            )}
                            {(((file?.type)?.split('/')[0] !== 'image') &&
                                ((file?.fileName.substring(file?.fileName.lastIndexOf(".") + 1, file?.fileName.length)) !== 'pdf') &&
                                ((file?.fileName.substring(file?.fileName.lastIndexOf(".") + 1, file?.fileName.length)) !== 'xlsx')
                                && ((file?.fileName.substring(file?.fileName.lastIndexOf(".") + 1, file?.fileName.length)) !== 'csv')
                                && ((file?.fileName.substring(file?.fileName.lastIndexOf(".") + 1, file?.fileName.length)) !== 'word'))
                                && (
                                    <FontAwesomeIcon icon={faFile} />
                                )}
                        </div>
                        <div className="file-info d-flex">
                            <div className="name-loader">
                                <div className="name d-flex">
                                    <div className="full-name">{file?.fileName}</div>
                                </div>
                            </div>
                            <div className="formate-percentage">
                                <div className="formate d-flex justify-content-center align-items-center">
                                    {(file?.fileName.substring(file?.fileName.lastIndexOf(".") + 1, file?.fileName.length))}
                                </div>
                            </div>
                        </div>
                        <div className="action">
                            <Dropdown className="more-action" alignRight
                                onSelect={(event: any) => this.handleSelectFileUpload(event, file, index)}
                            >
                                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                {noEditPermission ? (<></>) : (
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="delete">Delete</Dropdown.Item>
                                        <Dropdown.Item eventKey="download">Download</Dropdown.Item>
                                    </Dropdown.Menu>
                                )}
                            </Dropdown>
                        </div>
                    </div>
                </li>
            </>
        ));

        return (
            <>
                {this.state.contractExpRowLoader ? (<>
                    {contractExpRowAnimation()}
                </>
                ) : (
                    <>
                        <div className="contractExpRow mb-4">
                            <div className="header">Contract details</div>
                            <div className={`${noEditPermission ? "disabled-area" : ""} vendor-general contractDetails  form-section d-flex flex-column`}>
                                <Form.Group className="d-flex align-item-center" controlId="ehidSelection">
                                    <Form.Label>EHID</Form.Label>
                                    <div className="d-flex flex-column">
                                        <div className="form-control">{tableData?.letterCode}</div>
                                    </div>
                                </Form.Group>
                                <Form.Group className="d-flex align-item-center" controlId="customerNoSelection">
                                    {tableData?.accountError && (
                                        <div className="pips contractPipShow">
                                            <div className="pip light-red"></div>
                                        </div>
                                    )}
                                    <Form.Label className={tableData?.accountError ? "hasTextError" : ""}>Account Number</Form.Label>
                                    <div className="d-flex flex-column">
                                        <OverlayTrigger placement="bottom" overlay={
                                            <Tooltip className={tableData?.accountNo.length === 0 ? "hideToolTip" : ""} id="accountNo">{tableData?.accountNo}</Tooltip>
                                        }>
                                            <input type="text"
                                                className={tableData?.accountError ? "hasError form-control" : "form-control"}
                                                id="idCustomerNo"
                                                maxLength={40}
                                                value={tableData?.accountNo}
                                                onChange={(e: any) => this.handleContactDetails(e, "accountNo", "self")}
                                                onBlur={(e: any) => this.handleBlurContactDetails(e, "accountNo")}
                                            />
                                        </OverlayTrigger>
                                        {tableData?.accountError && (<div className="errorContainer hasTextError">Minumum 1 character required.</div>)}
                                    </div>
                                </Form.Group>
                                <Form.Group className="d-flex align-item-center" controlId="addressSelection">
                                    {tableData?.addressError && (
                                        <div className="pips contractPipShow">
                                            <div className="pip light-red"></div>
                                        </div>
                                    )}
                                    <Form.Label className={tableData?.addressError ? "hasTextError" : ""}>Address</Form.Label>
                                    <div className="generalDropdown">
                                        <Dropdown className="itemDropdown" onSelect={(event: any) => this.handleContactDetails(event, "address", "self")}>
                                            <Dropdown.Toggle id="idAddress">
                                                <div className="pos-rel">
                                                    <div className="d-flex align-items-center">
                                                        <div className="txt-area gTextArea w-330">
                                                            <EllipsisWithTooltip placement="bottom">
                                                                {tableData?.aName === "" ? "Select Address" : (<>{tableData?.aName}</>)}
                                                            </EllipsisWithTooltip>
                                                        </div>
                                                        <div className="drop-arrow ml-0 d-flex">
                                                            <FiChevronDown />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Dropdown.Toggle>
                                            {noEditPermission ? (<></>) : (
                                                <Dropdown.Menu>
                                                    {this.props?.addressList.map((items: any, index: any) => (
                                                        <>
                                                            {items.adddressID !== -1 && (
                                                                <Dropdown.Item eventKey={items.adddressID} key={items.adddressID}>
                                                                    <EllipsisWithTooltip placement="bottom">
                                                                        {items.address}
                                                                    </EllipsisWithTooltip>
                                                                </Dropdown.Item>
                                                            )}
                                                        </>
                                                    ))}
                                                </Dropdown.Menu>
                                            )}
                                        </Dropdown>
                                    </div>
                                </Form.Group>
                                <Form.Group className="d-flex align-item-center" controlId="startdateSelection">
                                    <Form.Label>Start Date</Form.Label>
                                    <div className="date-picker calender-picker row_CustomDateDropdown nonEditableDate ddSetwidth">
                                        <div className="hasOverlay">
                                            <label className="calanderOverlay" htmlFor="idStratDate"></label>
                                            <label htmlFor="idStratDate" className="drop-arrow">
                                                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                                </svg>
                                            </label>
                                        </div>
                                        <DatePicker
                                            id={"idStratDate"}
                                            className="form-control"
                                            disabled={noEditPermission}
                                            placeholderText="Enter start date"
                                            dateFormat="MM/dd/yy"
                                            autoComplete="off"
                                            selected={tableData?.crDate === "" ? "" : new Date(tableData?.crDate).toString() !== "Invalid Date" ? new Date(tableData?.crDate) : ""}
                                            onSelect={(event: any) => this.handleContactDetails(event, 'crDate', "self")}
                                            onKeyDown={(event: any) => this.handleKeyEvent(event, "crDate")}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="d-flex align-item-center" controlId="expirydateSelection">
                                    {tableData?.expDateError && (<div className="pips contractPipShow"><div className="pip light-red"></div></div>)}
                                    <Form.Label className={tableData?.expDateError ? "hasTextError" : ""}>Expiration Date</Form.Label>
                                    <div className="date-picker calender-picker row_CustomDateDropdown nonEditableDate ddSetwidth">
                                        <div className="hasOverlay">
                                            <label className="calanderOverlay" htmlFor="idExpiryDate"></label>
                                            <label htmlFor="idExpiryDate" className="drop-arrow">
                                                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                                </svg>
                                            </label>
                                        </div>
                                        <DatePicker
                                            id={"idExpiryDate"}
                                            className={tableData?.expDateError ? "hasError form-control" : "form-control"}
                                            placeholderText="Enter expiration date"
                                            dateFormat="MM/dd/yy"
                                            autoComplete="off"
                                            disabled={noEditPermission}
                                            minDate={new Date(new Date().setHours(0, 0, 0, 0)) <= new Date(tableData?.crDate) ?
                                                new Date(new Date(tableData?.crDate).setDate(new Date(tableData?.crDate).getDate() + 1)) :
                                                new Date(new Date().setHours(0, 0, 0, 0))
                                            }
                                            selected={tableData?.expDate === "" ? "" : new Date(tableData?.expDate).toString() !== "Invalid Date" ? new Date(tableData?.expDate) : ""}
                                            onSelect={(event: any) => this.handleContactDetails(event, 'expDate', "self")}
                                            onKeyDown={(event: any) => this.handleKeyEvent(event, "expDate")}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="d-flex align-item-center" controlId="notesSelection">
                                    <Form.Label>Notes</Form.Label>
                                    <div className="d-flex flex-column">
                                        <OverlayTrigger placement="bottom" overlay={
                                            <Tooltip className={tableData?.comments.length === 0 ? "hideToolTip" : ""} id="comments">{tableData?.comments}</Tooltip>
                                        }>
                                            <input type="text"
                                                className="form-control"
                                                maxLength={100}
                                                placeholder="Enter notes"
                                                id="idNotes"
                                                value={tableData?.comments}
                                                onChange={(event: any) => this.handleContactDetails(event, 'comments', "self")}
                                                onBlur={(event: any) => this.handleBlurContactDetails(event, 'comments')}
                                            />
                                        </OverlayTrigger>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="contractExpRow mb-4">
                            <div className="header">Original Contract</div>
                            <div className={`${noEditPermission ? "disabled-area" : ""} contractsTabExpTable`}>
                                <BootstrapTable
                                    key={"id"}
                                    keyField='id'
                                    data={this.state.originalContractData}
                                    columns={originalContractColumns}
                                />
                            </div>
                            <div className="generalCusAddEditModal posRelative">
                                {this.state.addOriginalContractModal && (
                                    <OutsideClickHandler onOutsideClick={() => this.hideAddEditModal("", "addOriginalContract")}>
                                        <div className="generalAddAddressModal">
                                            {this.addEditDetailsContainer("", "addOriginalContract")}
                                        </div>
                                    </OutsideClickHandler>
                                )}
                            </div>
                            {noEditPermission ? (<></>) : (
                                <div className="generalCusButton mb-4">
                                    <span className="isbtnspan" onClick={() => this.showAddEditModal("", "addOriginalContract")}>Add Original Contract</span>
                                </div>
                            )}
                        </div>

                        <div className="contractExpRow mb-4">
                            <div className="header">Change Orders</div>
                            <div className={`${noEditPermission ? "disabled-area" : ""} contractsTabExpTable`}>
                                <BootstrapTable
                                    key={"id"}
                                    keyField='id'
                                    data={this.state.changeOrdersData}
                                    columns={changeOrdersColumns}
                                />
                            </div>
                            <div className="generalCusAddEditModal posRelative">
                                {this.state.addChangeOrderModal && (
                                    <OutsideClickHandler onOutsideClick={() => this.hideAddEditModal("", "addChangeOrders")}>
                                        <div className="generalAddAddressModal">
                                            {this.addEditDetailsContainer("", "addChangeOrders")}
                                        </div>
                                    </OutsideClickHandler>
                                )}
                            </div>
                            {noEditPermission ? (<></>) : (
                                <div className="generalCusButton mb-4">
                                    <span className="isbtnspan" onClick={() => this.showAddEditModal("", "addChangeOrders")}>Add Change order</span>
                                </div>
                            )}
                        </div>

                        <div className="contractExpRow mb-4">
                            <div className="header">Documents</div>
                            <div className={`${noEditPermission ? "disabled-area" : ""} cus_fileUploadSectionWithView`}>
                                <Dropzone disabled={noEditPermission} onDrop={(e: any) => this.onAttachmentDropContract(e)} accept={acceptedFiles}>
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                        <section className={isDragActive ? "container upload-area default drag-active" : "container upload-area default"}>
                                            <div id="droparea"
                                                onKeyDown={(event: any) => this.handleKeyDown(event, 'droparea')}
                                                {...getRootProps({
                                                    className: 'dropzone d-flex align-items-center',
                                                    onClick: (tableData?.newSelectedfiles.length > 0 || tableData?.contractsDocuments.length > 0) ?
                                                        event => event.stopPropagation() : event => event
                                                })}>
                                                <input {...getInputProps()} />
                                                {(tableData?.newSelectedfiles.length < 1 && tableData?.contractsDocuments.length < 1) && (
                                                    <p>Drag and drop or <span>browse files</span> to upload</p>
                                                )}
                                                {(tableData?.newSelectedfiles.length > 0 || tableData?.contractsDocuments.length > 0) && isDragActive && (
                                                    <p>Drag and drop Document here to upload.</p>
                                                )}
                                            </div>
                                            {(tableData?.newSelectedfiles.length > 0 || tableData?.contractsDocuments.length > 0) && (
                                                <aside className="d-flex flex-column file-area">
                                                    <ul>{files}</ul>
                                                    <ul>{files1}</ul>
                                                </aside>
                                            )}
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                            <div className={`${noEditPermission ? "disabled-area" : ""} footerAddButton`}>
                                <Dropzone disabled={noEditPermission} onDrop={(e) => this.onAttachmentDropContract(e)} accept={acceptedFiles}>
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                        <>
                                            <input id={`addAttachmentContracts_${tableData?.uniqueno}`} {...getInputProps()} />
                                            <label htmlFor={`addAttachmentContracts_${tableData?.uniqueno}`}>Add Document</label>
                                        </>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }
}
