import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import Joi from "joi-browser";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../../Common/StaticArrays";


export class AddDepositAccount extends React.Component<any, any> {
  private depositFormSchema = Joi.object({
    directDeposit: Joi.string(),
    accountType: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Account Type" };
        });
      }),
    ddAmountType: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Amount Type" };
        });
      }),
    ddAmount: Joi.number().optional().min(0).max(7),
    bankName: Joi.string()
      .required()
      .max(50)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error?.type) {
            case "any.empty":
              return { message: "Please enter Bank Name" };
            case "string.max":
              return {
                message: "Bank Name should not be more than 50 characters",
              };
          }
        });
        return [_errors[0]];
      }),
    routingNumber: Joi.string()
      .min(9)
      .max(9)
      .regex(/^[0-9]+$/, "routingNumber")
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error?.type) {
            case "any.empty":
              return { message: "Please enter Routing Number" };
            case "string.max":
              return { message: "Routing Number must be 9 digit" };
            case "string.min":
              return { message: "Routing Number must be 9 digit" };
            case "string.regex.name":
              return { message: "Routing Number must be 9 digit" };
          }
        });
        return [_errors[0]];
      }),
    accountNumber: Joi.string()
      .max(17)
      .required()
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error?.type) {
            case "any.empty":
              return { message: "Please enter Account Number" };
            case "string.max":
              return {
                message: "Account Number should not be more than 17 digits",
              };
          }
        });
        return [_errors[0]];
      }),
    ddAmountType2: Joi.string().allow(""),
    ddAmount2: Joi.number().allow(0),
  });

  state: any = {
    depositFormData: {
      directDeposit: this.props?.depositRowEdit?.directDeposit || "Yes",
      bankName: this.props?.depositRowEdit?.bankName || "",
      accountType: this.props?.depositRowEdit?.accountType || "",
      routingNumber: this.props?.depositRowEdit?.routingNumber || "",
      accountNumber: this.props?.depositRowEdit?.accountNumber || "",
      ddAmountType:
        this.props?.depositRowEdit?.ddAmountType === "Entire Paycheck"
          ? "Total Payroll"
          : (this.props?.depositRowEdit?.ddAmountType === "$ Amount of Paycheck"
              ? "$Amount of Paycheck"
              : this.props?.depositRowEdit?.ddAmountType) || "",
      ddAmount: this.props?.depositRowEdit?.ddAmount || 0,
      ddAmountType2:
        this.props?.depositRowEdit?.ddAmountType2 === "Entire Paycheck"
          ? "Total Payroll"
          : (this.props?.depositRowEdit?.ddAmountType2 ===
            "$ Amount of Paycheck"
              ? "$Amount of Paycheck"
              : this.props?.depositRowEdit?.ddAmountType2) || "",
      ddAmount2: this.props?.depositRowEdit?.ddAmount2 || 0,
    },

    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    isStatesLoaded: false,
    stateList: [],
    amountTypes2: [],
  };

  componentDidMount = () => {
    this.setState({
      amountTypes2: this.getUserAmountTypes2(
        (this.props?.depositRowEdit?.ddAmountType === "$ Amount of Paycheck"
          ? "$Amount of Paycheck"
          : this.props?.depositRowEdit?.ddAmountType) || ""
      ),
    });
  };

  onFieldChange = (event, inputPosition) => {
    const { depositFormData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if (this.state.depositFormData?.ddAmountType === "% of Paycheck") {
      if (fieldName == "ddAmount" || fieldName == "ddAmount2") {
        value = value
          ?.toString()
          ?.trim()
          ?.replace(/[^0-9\+]+/gi, "");
      }
    } else {
      if (fieldName == "ddAmount" || fieldName == "ddAmount2") {
        value = value
          ?.toString()
          ?.trim()
          ?.replace(/[^0-9\.\+]+/gi, "");
      }
    }

    if (fieldName == "routingNumber" || fieldName == "accountNumber") {
      value = value
        ?.toString()
        ?.trim()
        ?.replace(/[^0-9\+]+/gi, "");
    }

    //maxlength for routingNumber is 9
    if (fieldName == "routingNumber" && value?.toString()?.length > 9) {
      return;
    }

    //maxlength for ddAmount and ddAmount2
    if (
      (fieldName == "ddAmount" || fieldName == "ddAmount2") &&
      !value?.toString()?.includes(".") &&
      value?.toString()?.length > 10
    ) {
      return;
    }

    if (
      (fieldName == "ddAmount" || fieldName == "ddAmount2") &&
      value?.toString()?.split(".")[0]?.length > 10
    ) {
      return;
    }
    if (
      (fieldName == "ddAmount" || fieldName == "ddAmount2") &&
      value?.toString()?.split(".")[1]?.length > 2
    ) {
      return;
    }
    if (
      (fieldName == "ddAmount" || fieldName == "ddAmount2") &&
      value?.toString()?.split(".")[1]?.length > 2 &&
      value
    ) {
      depositFormData[fieldName] =
        +value >= 0 ? Number(value)?.toFixed(2) : value;
    }

    depositFormData[fieldName] = value;
    if (
      !(
        this.state.depositFormData?.ddAmountType === "% of Paycheck" ||
        this.state.depositFormData?.ddAmountType === "$Amount of Paycheck"
      )
    ) {
      depositFormData["ddAmount"] = "";
    }

    if (
      this.props?.depositRowEdit?.ddAmountType2 &&
      +this.props?.depositRowForAddEdit === 1
    ) {
      if (fieldName === "ddAmountType") {
        this.setState(
          {
            amountTypes2: [],
          },
          () => this.setState({ amountTypes2: this.getUserAmountTypes2(value) })
        );

        depositFormData["ddAmountType2"] = "";
        depositFormData["ddAmount2"] = "";
      }
      if (
        this.state.depositFormData?.ddAmountType2 !== "% of Paycheck" &&
        this.state.depositFormData?.ddAmountType2 !== "$Amount of Paycheck"
      ) {
        depositFormData["ddAmount2"] = "";
      }
    }
    if (inputPosition > this.state.inputPosition) {
      this.setState({ inputPosition });
    }

    this.setState({ depositFormData }, () => this.validateDepositFormSchema());
  };

  validateRoutingNumber = (s: string) => {
    let checksum: number = 0;
    let len: number = 0;
    let sum: number = 0;
    let mod: number = 0;
    len = s.length;

    if (len != 9) {
      return false;
    } else {
      let newString: string = s.substring(s.length - 1);
      checksum = +newString;

      sum =
        7 *
          (parseInt(s.charAt(0)) +
            parseInt(s.charAt(3)) +
            parseInt(s.charAt(6))) +
        3 *
          (parseInt(s.charAt(1)) +
            parseInt(s.charAt(4)) +
            parseInt(s.charAt(7))) +
        9 * (parseInt(s.charAt(2)) + parseInt(s.charAt(5)));

      mod = sum % 10;

      if (mod == checksum) return true;
      else return false;
    }
  };

  componentWillUnmount = () => {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  };
  validateDepositFormSchema = () => {
    const valid = Joi.validate(
      this.state.depositFormData,
      this.depositFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if (
      (this.state.depositFormData?.ddAmountType === "% of Paycheck" ||
        this.state.depositFormData?.ddAmountType === "$Amount of Paycheck") &&
      !this.state?.depositFormData?.ddAmount &&
      this.state?.depositFormData?.ddAmount != "0"
    ) {
      newErrorObject["ddAmount"] = "Please enter Amount";
    } else {
      if (newErrorObject["ddAmount"]) {
        delete newErrorObject["ddAmount"];
      }
    }

    if (this.state.depositFormData?.routingNumber) {
      if (
        this.validateRoutingNumber(this.state.depositFormData?.routingNumber)
      ) {
        if (newErrorObject["routingNumber"]) {
          delete newErrorObject["routingNumber"];
        }
      } else {
        newErrorObject["routingNumber"] = "Invalid Routing Number";
      }
    }

    if (
      this.props?.depositRowEdit?.ddAmountType2 &&
      +this.props?.depositRowForAddEdit === 1
    ) {
      if (!this.state.depositFormData?.ddAmountType2) {
        newErrorObject["ddAmount2"] = "Please select Amount 2";
      } else {
        if (newErrorObject["ddAmount2"]) {
          delete newErrorObject["ddAmount2"];
        }

        if (
          this.state.depositFormData?.ddAmountType2 === "% of Paycheck" &&
          !this.state?.depositFormData?.ddAmount2 &&
          this.state?.depositFormData?.ddAmount2 != "0"
        ) {
          newErrorObject["ddAmount2"] = "Please enter Amount 2";
        } else {
          if (newErrorObject["ddAmount2"]) {
            delete newErrorObject["ddAmount2"];
          }
        }
      }
    }

    if (
      newErrorObject["ddAmount2"] &&
      this.state?.depositFormData?.ddAmount2?.toString()?.includes("*")
    ) {
      delete newErrorObject["ddAmount2"];
    }

    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  validationOnClick = (inputPosition) => {
    if (inputPosition > this.state.inputPosition) {
      this.setState({ inputPosition }, () => this.validateDepositFormSchema());
    }
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;
    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };

  hideModal = () => {
    const depositFormData: any = {
      directDeposit: this.props?.depositRowEdit?.directDeposit || "Yes",
      bankName: this.props?.depositRowEdit?.bankName || "",
      accountType: this.props?.depositRowEdit?.accountType || "",
      routingNumber: this.props?.depositRowEdit?.routingNumber || "",
      accountNumber: this.props?.depositRowEdit?.accountNumber || "",
      ddAmountType: this.props?.depositRowEdit?.ddAmountType || "",
      ddAmount: this.props?.depositRowEdit?.ddAmount || 0,
      ddAmountType2: this.props?.depositRowEdit?.ddAmountType2 || "",
      ddAmount2: this.props?.depositRowEdit?.ddAmount2 || 0,
    };
    this.props?.hideDepositModal();
    this.setState({ depositFormData, isFormNotValid: true });
  };

  handleDirectDepositError = () => {
    const newErrorObject = {};

    const errorMessage = "Total percentage cannot be greater than 100%";
    newErrorObject["ddAmount"] = errorMessage;

    if (
      this.props?.depositRowEdit?.ddAmountType2 &&
      +this.props?.depositRowForAddEdit === 1
    ) {
      newErrorObject["ddAmount2"] = errorMessage;
    }
    this.setState({
      isFormNotValid: true,
      errors: newErrorObject,
      inputPosition: 9,
    });
  };

  addDeposit = async () => {
    this.setState({ isDataSaving: true });
    const data = await this.props?.addUpdateDeposit(
      this.state?.depositFormData,
      this.props?.depositRowEdit?.bankName ? true : false,
      false,
      this.handleDirectDepositError
    );
    console.log({ data });
    setTimeout(() => {
      this.setState({ isDataSaving: false });
    }, 2000);
  };

  getUserAmountTypes = (): any[] => {
    let data: any[] = StaticArrays.UserAmountTypes.filter(
      (x) => +x.depositType === +this.props?.depositRowForAddEdit
    );
    if (+this.props?.depositRowForAddEdit === 2) {
      if (
        this.props?.enterPriseWisePayrollData?.ddAmountType === "Total Payroll"
      ) {
        data = data.filter((x) => x.value === "None");
      }
      if (
        this.props?.enterPriseWisePayrollData?.ddAmountType === "% of Paycheck"
      ) {
        data = data.filter((x) => x.value === "% of Paycheck");
      }
      if (
        this.props?.enterPriseWisePayrollData?.ddAmountType ===
        "$Amount of Paycheck"
      ) {
        data = data.filter((x) => x.value === "$Amount of Paycheck");
      }
    }
    return data;
  };

  getUserAmountTypes2 = (ddAmountType): any[] => {
    let data: any[] = StaticArrays.UserAmountTypes.filter(
      (x) => +x.depositType === 2
    );

    if (ddAmountType === "Total Payroll") {
      data = data.filter((x) => x.value === "None");
    }
    if (ddAmountType === "% of Paycheck") {
      data = data.filter((x) => x.value === "% of Paycheck");
    }
    if (ddAmountType === "$Amount of Paycheck") {
      data = data.filter((x) => x.value === "$Amount of Paycheck");
    }

    return data;
  };

  render() {
    const {
      depositFormData: {
        bankName,
        accountType,
        routingNumber,
        accountNumber,
        ddAmountType,
        ddAmount,
        ddAmountType2,
        ddAmount2,
      },
      errors,
      isFormNotValid,
      inputPosition,
      amountTypes2,
    } = this.state;
    const amountTypes = this.getUserAmountTypes();

    const { showDepositModal } = this.props;
    return (
      <>
        <div className="">
          <Modal
            className="add-deposit-account-modal"
            show={showDepositModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props?.depositRowEdit?.bankName
                  ? "Edit Direct Deposit Account"
                  : "Add Direct Deposit Account"}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Form.Group controlId="accountType">
                    <Col
                      sm={"12"}
                      className={`mb-3 ${
                        errors?.accountType && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Account Type</Form.Label>
                      <DropDownList
                        placeHolder={"Search Account Type"}
                        data={StaticArrays.UserAccountTypes}
                        defaultValue={accountType}
                        isSearchRequired={false}
                        label={"label"}
                        value={"value"}
                        // disabled={
                        //   !userGeneralInfo?.hasEditContactInfo &&
                        //   !isNewUserAdded
                        // }
                        onDropDownChange={(item) => {
                          if (!item) {
                            return;
                          }
                          this.onFieldChange(
                            Utils.BuildCustomEventObject(
                              "accountType",
                              item.value
                            ),
                            1
                          );
                        }}
                        selectedItem={[
                          ...[{ value: "", label: "Select Account Type" }],
                          ...StaticArrays.UserAccountTypes,
                        ].find((r: any) => r?.value === accountType)}
                      />
                      {this.getFieldError("accountType", 1)}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="amountType" className="d-flex">
                    <Col
                      sm={
                        ddAmountType === "% of Paycheck" ||
                        ddAmountType === "$Amount of Paycheck"
                          ? "6"
                          : "12"
                      }
                      className={`mb-0 ${
                        errors?.ddAmountType && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                      onBlur={() => this.validationOnClick(2)}
                    >
                      <Form.Label>Amount Type</Form.Label>
                      <DropDownList
                        placeHolder={"Select Amount Type"}
                        data={amountTypes}
                        defaultValue={ddAmountType}
                        isSearchRequired={false}
                        label={"label"}
                        value={"value"}
                        // disabled={
                        //   !userGeneralInfo?.hasEditContactInfo &&
                        //   !isNewUserAdded
                        // }
                        onDropDownChange={(item) => {
                          if (!item) {
                            return;
                          }
                          this.onFieldChange(
                            Utils.BuildCustomEventObject(
                              "ddAmountType",
                              item.value
                            ),
                            2
                          );
                        }}
                        selectedItem={[
                          ...[{ value: "", label: "Select Amount Type" }],
                          ...amountTypes,
                        ].find((r: any) => r?.value === ddAmountType)}
                      />
                      {this.getFieldError("ddAmountType", 2)}
                    </Col>
                    {(ddAmountType === "% of Paycheck" ||
                      ddAmountType === "$Amount of Paycheck") && (
                      <Col
                        sm={"6"}
                        className={`mb-0 ${
                          errors?.ddAmount && inputPosition >= 3
                            ? "validation-error"
                            : ""
                        }`}
                        onBlur={() => this.validationOnClick(3)}
                      >
                        <Form.Label>Amount</Form.Label>
                        <input
                          type="text"
                          // maxLength={13}
                          onKeyDown={
                            this.state.depositFormData?.ddAmountType ===
                            "% of Paycheck"
                              ? Utils.AllowOnlyPositiveNumnberInputWithoutDot
                              : Utils.AllowOnlyPositiveNumnberInput
                          }
                          className="form-control"
                          id="txtAmount"
                          name="ddAmount"
                          autoComplete="off"
                          value={ddAmount}
                          onChange={(e) => this.onFieldChange(e, 3)}
                        />
                        {this.getFieldError("ddAmount", 3)}
                      </Col>
                    )}
                  </Form.Group>
                  <Form.Group controlId="BankName">
                    <Col
                      sm={"12"}
                      onBlur={() => this.validationOnClick(4)}
                      className={
                        errors?.bankName && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Bank Name</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtBankName"
                        name="bankName"
                        autoComplete="off"
                        value={bankName}
                        onChange={(e) => this.onFieldChange(e, 4)}
                      />
                      {this.getFieldError("bankName", 4)}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="routingNumber">
                    <Col
                      sm={"12"}
                      onBlur={() => this.validationOnClick(5)}
                      className={
                        errors?.routingNumber && inputPosition >= 5
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Routing Number</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtroutingNo"
                        name="routingNumber"
                        // maxLength={9}
                        onKeyDown={
                          Utils.AllowOnlyPositiveNumnberInputWithoutDot
                        }
                        autoComplete="off"
                        value={routingNumber}
                        onChange={(e) => this.onFieldChange(e, 5)}
                      />
                      {this.getFieldError("routingNumber", 5)}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="accountNumber">
                    <Col
                      sm={"12"}
                      onBlur={() => this.validationOnClick(6)}
                      className={
                        errors?.accountNumber && inputPosition >= 6
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Account Number</Form.Label>
                      <input
                        type="text"
                        onKeyDown={
                          Utils.AllowOnlyPositiveNumnberInputWithoutDot
                        }
                        className="form-control"
                        id="txtaccountNo"
                        name="accountNumber"
                        autoComplete="off"
                        value={accountNumber}
                        //   disabled={!this.props?.hasViewSensitiveDataPermission}
                        onChange={(e) => this.onFieldChange(e, 6)}
                      />
                      {this.getFieldError("accountNumber", 6)}
                    </Col>
                  </Form.Group>
                  {this.props?.depositRowEdit?.ddAmountType2 &&
                    +this.props?.depositRowForAddEdit === 1 && (
                      <Form.Group controlId="amountType2" className="d-flex">
                        <Col
                          sm={
                            ddAmountType2 === "% of Paycheck" ||
                            ddAmountType2 === "$Amount of Paycheck"
                              ? "6"
                              : "12"
                          }
                          className={`mb-0 ${
                            errors?.ddAmountType2 && inputPosition >= 7
                              ? "validation-error"
                              : ""
                          }`}
                          onBlur={() => this.validationOnClick(7)}
                        >
                          <Form.Label>Amount Type 2</Form.Label>
                          {amountTypes2?.length > 0 && (
                            <DropDownList
                              placeHolder={"Select Amount Type"}
                              data={amountTypes2}
                              defaultValue={ddAmountType2}
                              isSearchRequired={false}
                              label={"label"}
                              value={"value"}
                              // disabled={
                              //   !userGeneralInfo?.hasEditContactInfo &&
                              //   !isNewUserAdded
                              // }
                              onDropDownChange={(item) => {
                                if (!item) {
                                  return;
                                }
                                this.onFieldChange(
                                  Utils.BuildCustomEventObject(
                                    "ddAmountType2",
                                    item.value
                                  ),
                                  7
                                );
                              }}
                              selectedItem={[
                                ...[{ value: "", label: "Select Amount Type" }],
                                ...amountTypes2,
                              ].find((r: any) => r?.value === ddAmountType2)}
                            />
                          )}
                          {this.getFieldError("ddAmountType2", 7)}
                        </Col>
                        {(ddAmountType2 === "% of Paycheck" ||
                          ddAmountType2 === "$Amount of Paycheck") && (
                          <Col
                            sm={"6"}
                            className={`mb-0 ${
                              errors?.ddAmount2 && inputPosition >= 8
                                ? "validation-error"
                                : ""
                            }`}
                            onBlur={() => this.validationOnClick(8)}
                          >
                            <Form.Label>Amount 2</Form.Label>
                            <input
                              type="text"
                              // maxLength={13}
                              onKeyDown={
                                this.state.depositFormData?.ddAmountType ===
                                "% of Paycheck"
                                  ? Utils.AllowOnlyPositiveNumnberInputWithoutDot
                                  : Utils.AllowOnlyPositiveNumnberInput
                              }
                              className="form-control"
                              id="txtAmount2"
                              name="ddAmount2"
                              autoComplete="off"
                              value={ddAmount2}
                              onChange={(e) => this.onFieldChange(e, 8)}
                            />
                            {this.getFieldError("ddAmount2", 8)}
                          </Col>
                        )}
                      </Form.Group>
                    )}{" "}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-ghost" onClick={this.hideModal}>
                Cancel
              </Button>
              <Button
                disabled={isFormNotValid || this.props?.isDataSubmitting}
                className="btn btn-primary"
                onClick={this.addDeposit}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
