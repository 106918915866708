import React from "react";
import { Form, Dropdown, Row, Modal, Col, Button, Spinner, } from "react-bootstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import OutsideClickHandler from 'react-outside-click-handler';
import { VendorService } from "../../../../Common/Services/vendor";
import './vendorTabs.scss';
import _ from "lodash";
import chevronRight from "../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import ContractExpRow from "./ContractExpRow";
import { ddlSearchWithTab as SingleSearchDropdownList } from "../../../../Common/Components/ddlSearchWithTab";
import { FiChevronDown } from "react-icons/fi";
import MergeProcessModal from "./mergeProcessModal";
import moment from "moment";
import $ from "jquery";
import { Utils } from "../../../../Common/Utilis";

export class ContractsTab extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            vid: props?.vid,
            tableData: [],
            tableExpended: [],
            coaList: [],
            contractSubmitLoader: false,
            contractPageLoader: false,
            addContract: {
                ehid: "",
                letterCode: "",
                accountNo: "",
                aid: "",
                aName: "",
                status: "Active",
                ehidError: false,
                accError: false,
                accDupError: false,
                addError: false
            },
            isAddContractModal: false,
            contractTabLoader: false,
            isformPost: true,
            modalMergeProcess: false,
            selectedhid: "",
            selectedId: -1,
            contractList: [],
            addressList: [],
            ehidList: [],
            defehidList: [],
            curScrollLen: 0,
            accNoTooltip: false,
            pendingTrans: [],
            pendingTransShow: false,
        }
    }

    componentDidMount(): void {
        let { ehidList } = this.state;
        let addressList = (this.props?.addressList !== undefined ? this.props?.addressList : []);
        ehidList = (this.props?.ehidList !== undefined ? this.props?.ehidList : [])
        this.setState({ addressList, ehidList, defehidList: ehidList }, () => {
            this.GetVendorContract();
        });
    }

    isValidDate = (date: any) => {
        let reGoodDate = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][\d]|3[01])[- /.](19|20)?[\d]{2})*$/;
        return reGoodDate.test(date);
    }

    GetVendorContract = () => {
        let { contractList } = this.state;
        contractList = [];
        this.setState({ contractPageLoader: true, contractList })
        VendorService.GetVendorContract(this.props?.vid)
            .then((result: any) => {
                if (result.length > 0) {
                    result.map((item, index) => {
                        let validCrDate = (item.crDate === "" || item.crDate === "Invalid date" || item.crDate === null) ? false : this.isValidDate(moment(item.crDate).format("MM/DD/YYYY"));
                        let validExDate = (item.expDate === "" || item.crDate === "Invalid date" || item.expDate === null) ? false : this.isValidDate(moment(item.expDate).format("MM/DD/YYYY"));
                        let isValidCrDate = (validCrDate ? new Date(item.expDate) : false);
                        let isValidExDate = (validExDate ? new Date(item.crDate) : false);
                        let hasExpDateError = validCrDate ? isValidCrDate <= isValidExDate : false;
                        item["aName"] = "";
                        item["uniqueRowNo"] = `${item.hid}_${item.accountNo}`;
                        item["oldAccountNo"] = item.accountNo;
                        item["accountError"] = item.accountNo === "" ? true : false;
                        item["oldComments"] = item.comments;
                        item["addressError"] = ((item.address === "" || item.address === null) ? true : false);
                        item["crDateError"] = ((item.crDate === "" || item.crDate === null) ? true : false);
                        item["oldcrDate"] = item.crDate;
                        item["expDateError"] = hasExpDateError;
                        item["oldexpDate"] = item.expDate;
                        item["newSelectedfiles"] = [];
                        return item;
                    });
                    result.map((item, index) => {
                        let row: any = {};
                        row.id = item.uniqueRowNo;
                        row.label = item.accountNo;
                        row.labelSearch = `${item.letterCode} - ${item.accountNo}`;
                        row.vid = item.vid;
                        row.hid = item.hid;
                        row.letterCode = item.letterCode;
                        row.isContractAgency = "0";
                        row.aid = item.aid;
                        row.status = item.status;
                        row.sortIndex = null;
                        row.isChecked = false;
                        row.isDiffrentEhid = false;
                        row["cardNoExists"] = "notexists";
                        row["vendorAprroval"] = "app";
                        contractList.push(row);
                        return item;
                    })

                    let newResultList: any[] = [];
                    if (this.state.ehidList.length > 0) {
                        this.state.ehidList.forEach((ehid: any) => {
                            result.forEach((item: any) => {
                                if (item.hid === ehid.id) {
                                    newResultList.push(item);
                                }
                            })
                        })
                    }

                    newResultList = _.sortBy(newResultList, "status");
                    newResultList.forEach((item, index) => { item["uniqueno"] = index; item["modalMergeProcess"] = false; item["isToggle"] = false; });
                    this.setState({ tableData: newResultList, contractList, contractPageLoader: false, }, () => {
                        if (this.props?.activeTab === "contracts") {
                            setTimeout(() => { this.props.scrollRef.current?.scrollTo({ top: Number(this.state.curScrollLen), behavior: 'smooth', }); }, 500)
                            setTimeout(() => { this.setState({ curScrollLen: 0 }) }, 550)
                        }
                    });
                    resolve();

                } else {
                    this.setState(() => ({ contractPageLoader: false, }));
                }
            }).finally(() => {
                this.setExpAddress();
            })
            .catch((error) => {
                reject();
                this.setState({ contractPageLoader: false });
            });
    }


    setScrollHeight = (val) => {
        this.setState({ curScrollLen: val });
    }

    setExpAddress = () => {
        let { tableData } = this.state;
        let addressList = [...this.state.addressList]
        tableData.forEach((items) => {
            if (addressList.length > 0) {
                addressList.forEach(addItm => {
                    if (addItm.adddressID !== -1) {
                        if (items.aid === addItm.adddressID) {
                            items.aName = addItm.address;
                        }
                    }
                })
            }

        });
        this.setState({ tableData });
    }

    handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
        let { tableExpended, curScrollLen } = this.state;
        tableExpended = [];
        if (isExpand) {
            tableExpended = [...tableExpended, row.uniqueRowNo];
            curScrollLen = this.props.scrollRef.current?.scrollTop;
        } else {
            tableExpended = tableExpended.filter(x => x !== row.uniqueRowNo);
            curScrollLen = 0;
        }
        this.setState(() => ({ tableExpended, curScrollLen }));
    };

    handleFormChange = (event: any, type: any) => {
        let { addContract } = this.state;
        if (type === "ehid") {
            let hasEhid = this.state.ehidList.filter(item => item.id === event);
            if (hasEhid.length > 0) {
                addContract.ehid = hasEhid[0].id;
                addContract.letterCode = hasEhid[0].name;
                addContract.ehidError = false;
            } else {
                addContract.ehidError = true;
            }
        } else if (type === "accountNo") {
            let value = event.target.value.trimStart();
            addContract[type] = value;
            if (value.length > 0) {
                addContract.accError = false;
            } else {
                addContract.accError = true;
            }
        } else if (type === "address") {
            let addressList = this.props.addressList.filter((item: any) => item.adddressID === Number(event));
            if (addressList.length > 0) {
                addContract.aid = addressList[0].adddressID;
                addContract.aName = addressList[0].address;
                addContract.addError = false;
            } else {
                addContract.aid = ''
                addContract.aName = ''
                addContract.addError = true;
            }
        }
        this.setState(() => ({ addContract }));
    }

    showaccNoTooltip = () => {
        this.setState({ accNoTooltip: true })
    }

    hideaccNoTooltip = () => {
        this.setState({ accNoTooltip: false })
        setTimeout(() => { $("#accountNo2").focus(); }, 10)
    }

    onTAB = (control, event) => { }

    pendingTransTableHideShow = () => {
        this.setState({ pendingTransShow: !this.state.pendingTransShow })
    }

    handleAddAddress = () => {
        let { tableData, addContract, ehidList } = this.state;
        if (addContract.ehid === "") {
            addContract.ehidError = true;
        } else if (addContract.ehid !== "") {
            addContract.ehidError = false;
            if (addContract.accountNo === "") {
                addContract.accError = true;
            } else if (addContract.accountNo !== "") {
                let isSameAccAndEhid = tableData.some(o => (o.accountNo.toString().toLocaleLowerCase() === addContract.accountNo.toString().toLocaleLowerCase() && o.hid === addContract.ehid));
                if (isSameAccAndEhid) {
                    addContract.accDupError = true;
                } else {
                    addContract.accError = false;
                    addContract.accDupError = false;
                    if (addContract.aName === "" || addContract.aid === "") {
                        addContract.addError = true;
                    } else if (addContract.aName !== "" || addContract.aid !== "") {
                        addContract.addError = false;
                        this.setState({ contractSubmitLoader: true });
                        let request: any = {};
                        request.Vid = this.props?.vid;
                        request.AID = addContract.aid;
                        request.Company = "";
                        request.Address1 = "";
                        request.City = "";
                        request.State = "";
                        request.PCode = "";
                        request.HotelId = ehidList.length > 1 ? addContract.ehid : ehidList[0].id;
                        request.Phone = "";
                        request.PaymentType = "";
                        request.SalesTax = "";
                        request.IsVendor1099 = "";
                        request.TaxOrSsnId = "";
                        request.IsTaxOrSSN = "";
                        request.InvoiceDueAfter = "";
                        request.CustomerNo = addContract.accountNo.trim();
                        request.Email = "";
                        request.CallingOnPage = "Other";
                        request.CallType = "ExistsAddrNewContract";
                        VendorService.SaveVendorData(request)
                            .then(async (result: any | null) => {
                                if (result.success) {
                                    if (result?.message.toString().toLocaleLowerCase() === "success") {
                                        toast.success("New contract added successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
                                        this.setState(() => ({ contractSubmitLoader: false }))
                                        this.GetVendorContract();
                                        this.props.isReload(true);
                                    } else if (result?.message.toString().toLocaleLowerCase() === "vendornotexists") {
                                        Utils.toastError("This Vendor is already merged into another vendor.", { position: toast.POSITION.BOTTOM_RIGHT });
                                        this.setState({ contractSubmitLoader: false });
                                    } else {
                                        Utils.toastError(result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                                        this.setState({ contractSubmitLoader: false });
                                    }
                                } else {
                                    Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                                    this.setState({ contractSubmitLoader: false });
                                }
                                this.hideAddContract();
                                resolve();
                            })
                            .catch((error) => {
                                Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                                this.setState({ contractSubmitLoader: false });
                                reject();
                                this.hideAddContract();
                            });
                    }
                }
            }
        }
        this.setState({ addContract });
    }

    setContractLoader = (value) => {
        this.setState({ contractTabLoader: value, isformPost: value })
    }

    handleSelectEvent = (row: any, type) => {
        if (type === "merge") {
            setTimeout(() => {
                let { tableData } = this.state;
                tableData = tableData.map((items) => {
                    if (items.uniqueno === row.uniqueno) {
                        return { ...items, modalMergeProcess: true };
                    } else {
                        return items;
                    }
                });
                this.setState({ tableData, selectedhid: row.hid, selectedId: row.uniqueRowNo });
            }, 10)
        } else if (type === "changeStatus") {
            if (row.status.toString().toLocaleLowerCase() === "inactive") {
                let { addressList } = this.state
                let isActiveAddress: boolean = false;
                if (addressList.length > 0) {
                    addressList.forEach(addItm => {
                        if (addItm.adddressID !== -1) {
                            if (row.aid === addItm.adddressID) {
                                isActiveAddress = true;
                            }
                        }
                    })
                }
                if (isActiveAddress) {
                    this.handleStatusUpdate(row)
                } else {
                    Utils.toastError("Please enter an active address before activating this contract.", { position: toast.POSITION.BOTTOM_RIGHT });
                }
            } else {
                this.handleStatusUpdate(row)
            }
        }
    }

    handleStatusUpdate(row: any) {
        this.setState({ contractTabLoader: true })
        const action = row?.status?.toLowerCase() === "active" ? "Inactive" : "Active";
        let request = {} as any;
        request.VID = row.vid;
        request.HID = row.hid;
        request.Status = action;
        request.Contract = row.accountNo;
        VendorService.ActiveDeactiveVendor(request).then((result: any) => {
            if (result.success) {
                let contractData = result?.result;
                if (contractData.length > 0) {
                    this.setState({
                        pendingTrans: contractData,
                        contractTabLoader: false,
                        pendingTransShow: true
                    });
                } else {
                    this.updateStatus(row)
                }
            }
        }).catch((error) => {
            Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
            this.setState({ contractTabLoader: false });
            reject();
        });
    }

    updateStatus = (row: any) => {
        let status = row.status.toString().toLocaleLowerCase() === "inactive";
        // this.setState({ contractTabLoader: true })
        let request = {} as any;
        request.VID = row.vid;
        request.HID = row.hid;
        request.AID = row.aid;
        request.OldAccountNo = row.oldAccountNo;
        request.NewAccountNo = row.accountNo;
        request.ExpDate = row.expDate;
        request.CrDate = row.crDate;
        request.Comments = row.comments;
        VendorService.UpdateVendorContractStatus(request)
            .then((result: any) => {
                if (result.success) {
                    toast.success(`Contract ${status ? "activated" : "Deactivated"} successfully.`, { position: toast.POSITION.BOTTOM_RIGHT });
                    this.props?.isReload(true);
                    this.GetVendorContract();
                    this.props?.isReloadGeneralInfo();
                } else {
                    if (result.result[0]?.result?.toString().toLowerCase() === "vendornotexists") {
                        Utils.toastError("This Vendor is already merged into another vendor.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (result.result[0]?.result?.toString().toLowerCase() === "contractorexists") {
                        Utils.toastError("There is an active contractor type user assigned to this vendor.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (result.result[0].result?.toString().toLocaleLowerCase() === "cardcontractnotchange") {
                        Utils.toastError("Account no. cannot be changed as this vendor is associated with a credit card.", { position: toast.POSITION.BOTTOM_RIGHT, });
                    } else if (result.result[0].result?.toString().toLocaleLowerCase() === "cardcontractnotinactive") {
                        Utils.toastError("Account no. cannot be inactivated as this vendor is associated with a credit card.", { position: toast.POSITION.BOTTOM_RIGHT, });
                    } else {
                        Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }
                this.setState({ contractTabLoader: false })
                resolve();
            }).catch((error) => {
                Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ contractTabLoader: false });
                reject();
            });
    }

    hideRowModal = () => {
        let { tableData } = this.state;
        tableData = tableData.map((items) => {
            return {
                ...items,
                modalMergeProcess: false
            };
        });
        this.setState({ tableData, selectedhid: -1, selectedId: -1 }, () => {
            $("body").trigger("click");
        });
    }

    mergeContractModalShow = () => {
        this.setState(() => ({ modalMergeProcess: true, selectedhid: -1, selectedId: -1 }))
    }

    mergeContractModalHide = () => {
        this.setState(() => ({ modalMergeProcess: false, selectedhid: -1, selectedId: -1 }))
    }

    hoverOutEllipsis = () => {
        $(".tooltip").removeClass("show");
    };

    resetAddContract = () => {
        const defaultContract: any = {
            ehid: "",
            letterCode: "",
            accountNo: "",
            aid: "",
            aName: "",
            status: "Active",
            ehidError: false,
            accError: false,
            accDupError: false,
            addError: false
        }
        this.setState({ addContract: defaultContract });
    }

    hideAddContract = () => {
        let { ehidList } = this.state;
        if (ehidList.length > 1) {
            this.setState({ ehidList: [] });
        }
        this.resetAddContract();
        this.setState({ isAddContractModal: false, accNoTooltip: false }, () => {
            if (ehidList.length > 1) {
                this.setState({ ehidList: this.state.defehidList });
            }
        });
    }

    handleAddNewContractDrop = (eventVal: any) => {
        if (eventVal === true) {
            let { addContract, ehidList } = this.state;
            let addList = this.state.addressList;
            if (addList.length === 1) {
                addContract.aid = addList[0].adddressID;
                addContract.aName = addList[0].address;
            }
            if (ehidList.length === 1) {
                addContract.ehid = ehidList[0].name;
                addContract.letterCode = ehidList[0].id;
                addContract.ehidError = false;
            }
            this.setState({ isAddContractModal: true, addContract });
        }
    }

    handleToggle = (isVal: any, row: any) => {
        let { tableData } = this.state;
        tableData = tableData.map((o) => {
            if (o.uniqueno === row.uniqueno) {
                return { ...o, isToggle: isVal };
            } else if (o.uniqueno !== row.uniqueno) {
                return { ...o, isToggle: false };
            } else {
                return o;
            }
        });
        this.setState((props) => ({ ...props, tableData }))
    }

    render() {
        let { tableData, addContract } = this.state;
        let noEditPermission = this.props?.isEditVendor ? false : true;

        const renderDocNameCol = (row: any) => (
            <div className="documentColName">
                <strong><EllipsisWithTooltip placement="bottom">{row.letterCode}</EllipsisWithTooltip></strong>
                <span><EllipsisWithTooltip placement="bottom">{row.accountNo}</EllipsisWithTooltip></span>
            </div>
        )
        const renderDocStatusCol = (row: any) => {
            let statusClass = row.status.toString().toLowerCase() === "active" ? "green" : "grey";
            return (
                <>
                    <span className={`badge color-${statusClass}`}>{row.status}</span>
                </>
            )
        }

        const renderDocIconCol = (row) => {
            let isCCPaymentExist = row?.cardNoExists.toString().toLocaleLowerCase() !== "exists";
            let isMergeContracts = this.props?.isMergeContracts;
            let ishideDropDown = ((isMergeContracts && isCCPaymentExist) || !noEditPermission) ? false : true;
            return (
                <div className="generalMergeProcessModal">
                    <Dropdown className="more-action" alignRight
                        onToggle={(e) => this.handleToggle(e, row)}
                        onClick={(e: any) => e.stopPropagation()}
                        show={row.isToggle}
                    >
                        <Dropdown.Toggle className={`${ishideDropDown ? "disabled-area" : ""} btn-outline-primary btn btn-primary more`} id="dropdown-more">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                            </svg>
                        </Dropdown.Toggle>
                        {row.modalMergeProcess && (
                            <div className="generalMergeProcessInside">
                                <OutsideClickHandler onOutsideClick={this.hideRowModal}>
                                    <MergeProcessModal
                                        hideModal={this.hideRowModal}
                                        isHid={this.state.selectedhid}
                                        isId={row.uniqueRowNo}
                                        itemsList={this.state.contractList}
                                        isReload={this.GetVendorContract}
                                        isReloadMain={this.props.isReload}
                                        callType="contracts"
                                        eventType="row"
                                    />
                                </OutsideClickHandler>
                            </div>
                        )}
                        <Dropdown.Menu>
                            {isMergeContracts && isCCPaymentExist && (
                                <Dropdown.Item onClick={() => { this.handleSelectEvent(row, "merge") }}>Merge</Dropdown.Item>
                            )}
                            {noEditPermission ? (<></>) : (
                                <Dropdown.Item onClick={() => { this.handleSelectEvent(row, "changeStatus") }}>
                                    {row.status.toString().toLocaleLowerCase() === "inactive" ? "Activate" : "Deactivate"}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )
        }

        const tableColumns = [{
            dataField: 'name',
            headerClasses: "docNameCol",
            classes: "docNameCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderDocNameCol(row)
        }, {
            dataField: 'status',
            headerClasses: "docStatusCol",
            classes: "docStatusCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderDocStatusCol(row)
        }, {
            dataField: 'action',
            headerClasses: "docIconCol",
            classes: "docIconCol",
            formatter: (cell: any, row: any, rowIndex: any) => renderDocIconCol(row)
        }];

        const expandHeaderColumnRenderer = (isAnyExpands) => {
            if (isAnyExpands) { return <></> }
            return <></>;
        }
        const expandColumnRenderer = (expanded) => (
            <span className={expanded ? "chevronExpended" : "chevronCollpsed"}>
                <img src={chevronRight} alt="chevron Right" />
            </span>
        )

        const expandRow = {
            renderer: (row: any) => (
                <div className="rowExpended contractTabExpendPanel" id="laborDayDetails">
                    <ContractExpRow
                        row={row}
                        ehid={row.hid}
                        vid={row.vid}
                        addressList={this.state.addressList}
                        reloadContract={this.GetVendorContract}
                        setContractLoader={this.setContractLoader}
                        setScrollHeight={this.setScrollHeight}
                        isReload={this.props?.isReload}
                        scrollRef={this.props.scrollRef}
                        isEditVendor={this.props?.isEditVendor}
                    />
                </div>
            ),
            parentClassName: 'parentExpandedRow',
            expanded: this.state.tableExpended,
            onExpand: this.handleOnExpand,
            showExpandColumn: true,
            onlyOneExpanding: true,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => expandHeaderColumnRenderer(isAnyExpands),
            expandColumnRenderer: ({ expanded }) => expandColumnRenderer(expanded),
        };

        const contTabList = (number) => {
            let newCount: any = [];
            for (let i = 0; i < Number(number); i++) { newCount.push(i) }
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    {newCount.map((item: any, index: any) => (
                                        <div key={item} className={index === 2 ? "list d-flex align-self-center ml-auto" : "list d-flex align-self-center"}>
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }
        const contractTabLoader = () => {
            return (
                <>
                    {/* <div style={{ "height": "50px" }}></div> */}
                    <div className="bulkImportList mt-0">
                        <div className="bulkImportList1 d-flex">
                            <div className="single-loader loadingAnimation"></div>
                        </div>
                        <>{contTabList(3)}</>
                        <>{contTabList(3)}</>
                        <>{contTabList(3)}</>
                    </div>
                </>
            )
        }
        const hoverOutEllipsis = () => {
            $(".tooltip").removeClass("show");
        };

        const renderDepartment = (row) => (
            <EllipsisWithTooltip placement="bottom">{row.department}</EllipsisWithTooltip>
        )

        const PendingTransColumns = [
            {
                dataField: "lettercode",
                text: "EHID",
            }, {
                dataField: "department",
                text: "Invoice No",
                formatter: (cell: any, row: any, rowIndex: any) => renderDepartment(row)
            }, {
                dataField: "mDate",
                text: "Date",
            }
        ];

        let isCCPaymentExist = tableData.some((o: any) => o.cardNoExists.toString().toLocaleLowerCase() !== "exists");
        let isSplitCondition = (addContract.ehidError ? "hasEhidError isComponentDropdown" : "isComponentDropdown");
        return (
            <>
                {(this.state.contractTabLoader || this.state.contractSubmitLoader) && (
                    <div className="pageOverlaySpinner">
                        <Spinner animation="border" variant="secondary" />
                    </div>
                )}
                <div className="general-tab posRelative">
                    {/* {noEditPermission ? (<></>) : ()} */}
                    <>
                        {this.props?.isMergeContracts && tableData.length > 0 && isCCPaymentExist && (
                            <div className="header-popout contractModalShow">
                                <Dropdown className="more-action" alignRight>
                                    <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                            <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                            <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={this.mergeContractModalShow}>Merge Contracts</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )}
                    </>

                    <div className="body-section">
                        {this.state.contractPageLoader ? (<>{contractTabLoader()}</>) : (
                            <>
                                <div className="heading">Contracts</div>
                                <div className="contractsTabTable">
                                    <BootstrapTable
                                        key={"uniqueRowNo"}
                                        keyField='uniqueRowNo'
                                        data={tableData}
                                        columns={tableColumns}
                                        expandRow={expandRow}
                                        noDataIndication={'No record found.'}
                                    />
                                </div>

                                {noEditPermission ? (<></>) : (
                                    <div className="cusAddNewContractDrop mt-3">
                                        <Dropdown className="more-action"
                                            onToggle={this.handleAddNewContractDrop}
                                            show={this.state.isAddContractModal}>
                                            <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                                Add Contract
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="generalCusAddEditModal">
                                                <OutsideClickHandler onOutsideClick={() => this.hideAddContract()}>
                                                    <div className="generalAddAddressModal2">
                                                        <div className="generalEditForm">
                                                            <div className="formTitle">
                                                                <h4 className="mr-auto">Add Contract</h4>
                                                                <button type="button" className="btn wht-bg cross" onClick={() => this.hideAddContract()}>
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#84888C" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            <div className="formContainer">
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group controlId="ehidControl"
                                                                            className="generalStateControl">
                                                                            <Form.Label htmlFor="ddlEHID-1">EHID</Form.Label>
                                                                            <div className=
                                                                                {this.state.ehidList.length > 0 ? isSplitCondition : "isComponentDropdown disabled-area"}
                                                                                onClick={hoverOutEllipsis.bind(this)}>
                                                                                {this.state.ehidList.length > 0 && (
                                                                                    <SingleSearchDropdownList
                                                                                        itemList={this.state.ehidList}
                                                                                        handleSelectedItem={(e) => this.handleFormChange(e, "ehid")}
                                                                                        defaultItem={addContract.ehid === "" ? "" : addContract.ehid}
                                                                                        defaultText={"Search EHID"}
                                                                                        defaultName={addContract.ehid === "" ? "" : addContract.ehid}
                                                                                        controlID="3"
                                                                                        id={"ddlEHID-3"}
                                                                                        onTAB={this.onTAB.bind(this, "ddlEHID-3")}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                            {addContract.ehidError && (<div className="textError">Please Select EHID.</div>)}
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        {this.state.accNoTooltip ? (
                                                                            <Form.Group controlId="customerNumber1">
                                                                                <Form.Label htmlFor="accountNo1">Account Number</Form.Label>
                                                                                <Button className={`${(addContract.accError || addContract.accDupError) ? "borderError" : ""} cusBtnFormControl`}
                                                                                    onFocus={this.hideaccNoTooltip}
                                                                                    onClick={this.hideaccNoTooltip}>
                                                                                    <EllipsisWithTooltip placement="bottom">
                                                                                        {addContract.accountNo}
                                                                                    </EllipsisWithTooltip>
                                                                                </Button>
                                                                                {addContract.accError && (<div className="textError">Enter account no.</div>)}
                                                                                {addContract.accDupError && (<div className="textError">Duplicate account no.</div>)}
                                                                            </Form.Group>
                                                                        ) : (<Form.Group controlId="customerNumber2">
                                                                            <Form.Label htmlFor="accountNo2">Account Number</Form.Label>
                                                                            <input
                                                                                id="accountNo2"
                                                                                type="text"
                                                                                maxLength={40}
                                                                                className={(addContract.accError || addContract.accDupError) ? "form-control borderError" : "form-control"}
                                                                                value={addContract.accountNo}
                                                                                onChange={(event: any) => this.handleFormChange(event, "accountNo")}
                                                                                onBlur={this.showaccNoTooltip}
                                                                            />
                                                                            {addContract.accError && (<div className="textError">Enter account no.</div>)}
                                                                            {addContract.accDupError && (<div className="textError">Duplicate account no.</div>)}
                                                                        </Form.Group>
                                                                        )}

                                                                    </Col>
                                                                </Row>
                                                                <Form.Group controlId="addressControl">
                                                                    <Form.Label htmlFor="idaddress">Address</Form.Label>
                                                                    <div className={this.state.addressLoader ? "disabled-area row_CustomDropdown" : "row_CustomDropdown"}>
                                                                        <Dropdown className="itemDropdown" onSelect={(event: any) => this.handleFormChange(event, "address")}>
                                                                            <Dropdown.Toggle id="idaddress" className={addContract.addError ? "borderError" : ""}>
                                                                                <div className="pos-rel">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="txt-area gTextArea">
                                                                                            <EllipsisWithTooltip placement="bottom">
                                                                                                {addContract.aName}
                                                                                            </EllipsisWithTooltip>
                                                                                        </div>
                                                                                        <div className="drop-arrow ml-0 d-flex">
                                                                                            <FiChevronDown />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu className="overFlowContainer" onWheel={() => this.hoverOutEllipsis()}>
                                                                                {this.state.addressList.map((items: any, index: any) => (
                                                                                    <>
                                                                                        {items.adddressID !== -1 && (
                                                                                            <Dropdown.Item eventKey={items.adddressID} key={items?.adddressID}>
                                                                                                <EllipsisWithTooltip placement="bottom">
                                                                                                    {items.address}
                                                                                                </EllipsisWithTooltip>
                                                                                            </Dropdown.Item>
                                                                                        )}
                                                                                    </>
                                                                                ))}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                    {addContract.addError && (<div className="textError">Please Select address.</div>)}
                                                                </Form.Group>
                                                            </div>
                                                            <div className="formFooter">
                                                                <Button
                                                                    //disabled={isNewAddess ? isFormSubmit}
                                                                    onClick={(e: any) => this.handleAddAddress()}
                                                                >Save</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </OutsideClickHandler>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                )}

                                <Modal centered
                                    show={this.state.pendingTransShow}
                                    onHide={this.pendingTransTableHideShow}
                                    backdrop="static"
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter">
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <div className="sub-heading-Pay">Pending Transactions</div>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <div className="notificationBannersPay vendorNotification">
                                        Please clear the following Invoices to inactivate the vendor. (Either Invoices are pending for approval or on the Payment page)
                                    </div>
                                    <Modal.Body>
                                        <ToolkitProvider keyField="Rno"
                                            data={this.state.pendingTrans}
                                            columns={PendingTransColumns}>
                                            {(props: {
                                                searchProps: JSX.IntrinsicAttributes;
                                                baseProps: JSX.IntrinsicAttributes;
                                            }) => (
                                                <div>
                                                    <div className="JournalNavTabs PaymentNavTabs">
                                                        <div className="grid-sec cusGridSec2 cusGridSecPay">
                                                            <div className="scroll-area Vendor-Pending-TranModal table-responsive">
                                                                <BootstrapTable
                                                                    {...props.baseProps}
                                                                    hover
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </Modal.Body>
                                    <Modal.Footer className="Footer-Pay">
                                        <Button variant="primary" type="button" className="space btn btn-primary btn-discard" onClick={this.pendingTransTableHideShow}>Cancel</Button>
                                    </Modal.Footer>
                                </Modal>

                                {this.state.modalMergeProcess && (
                                    <MergeProcessModal
                                        hideModal={this.mergeContractModalHide}
                                        isHid={this.state.selectedhid}
                                        isId={this.state.selectedId}
                                        itemsList={this.state.contractList}
                                        isReload={this.GetVendorContract}
                                        isReloadMain={this.props.isReload}
                                        callType="contracts"
                                        eventType="blank"
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div >
            </>
        )
    }
}
