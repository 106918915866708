import * as React from 'react'
import { Modal } from 'react-bootstrap';
import { Dwollajs } from "../../../Common/ThirdPartyServices/Dwolla";

// const dwollaConfig = {
//   backButton: false,
//   customerToken: "j09JAdAhx7YeKUXfVBSbl8zESOlrVdVV3pPLjxwkBuImpu70iU",
//   environment: 'sandbox',
//   fallbackToMicroDeposits: false,
//   microDeposits: false,
//   stylesheets: [],
//   subscriber: () => {},
// } as any

// debugger

export class CustomDwolla extends React.Component<any, any> {
  state:any ={
    showDwollaModal:true
  }
  componentDidMount(){
    Dwollajs.IntegrateDwolla(this.props?.hotelId,this.props?.documentType);
  }

  hideModal =()=>{
    this.props?.hideModal();
    this.setState({showDwollaModal : false})
  }
  render() {
    const somediv = this.props.ModalName;
   
   // const somediv =  this.props?.custId ? "dwolla-business-vcr customerid=" + this.props?.custId: "dwolla-business-vcr";
    return(
      <Modal
      className="dwolla-setup-modal"
      show={this.state.showDwollaModal}
      onHide={this.hideModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <Modal.Header closeButton>
        <Modal.Title>
        <h5>Create Dwolla Business Customer</h5>          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="dwolladropin">
        {
          React.createElement(somediv, {customerid: this.props?.custId || '',
          terms:'https://www.inn-flow.com/terms-of-service/',privacy:'https://www.inn-flow.com/privacy-policy/'})
        }
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button
          className="btn-ghost"
          onClick={() => this.hideModal(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={isFormNotValid || isDataSaving}
          className="btn btn-primary"
          onClick={this.saveRoomType}
        >
        {this.props?.roomTypeRowEdit?.uniqueno > 0 ? "Update" : "Add"}   
        </Button> */}
      </Modal.Footer>
      </Modal>
    )
      
  }
}
