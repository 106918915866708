/* eslint-disable array-callback-return */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  // Link,
  Redirect,
} from "react-router-dom";
import { Container, Dropdown, Form, Row, Col, Button, Modal ,  Table,Spinner} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import chevronRight from "../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import profile from "../../../../Common/Assets/Images/profile1.png";
import notesIcon from "../../../../Common/Assets/Images/icons/notes-icon.png";

import DatePicker from "react-datepicker";
import OutsideClickHandler from "react-outside-click-handler";
import { resolve, reject } from "q";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { confirmAlert } from "react-confirm-alert";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ForecastLock from "../../../../Common/Assets/Images/icons/forecast-lock.svg";
import moment from "moment";
import { LaborSchedule } from "../../Common/Services/LaborSchedule";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { Utils } from "../../Common/Utilis";
import { EditApprovedPTOModal } from "../LaborManagement/Labor/Schedule/Modals & SlideOut/EditApprovedPTOModal";
import { EditPendingPTOModal } from "../LaborManagement/Labor/Schedule/Modals & SlideOut/EditPendingPTOModal";
import { LaborTimeOffDetails } from "../../Common/Services/LabourTimeOff";
import { LaborPTOCalculation } from "../../Common/Services/LaborPTOCalculation";
import { start } from "repl";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PDFToImg from "../Register/pdfToImg";
import { IMyPtoEditPTORequest } from "../../Common/Contracts/ILaborSchedule";

const { SearchBar } = Search;
export class RequestPendingApproval extends React.Component<any, any> {
  

  constructor(props: any) {
   
    super(props);

    
    this.state = {
        pendingApprovalData:[],
        tableLoader: false,
        editApprovedPTOModal: false,
        editApprovedPTOModalData: {},
        editData : false,
        rowData : [],
        showEditModal: false,
        userName : "",
        tableData : [],
        tableDataLength : 0,
        timeOffRequestDateTime : [],
        formHasError: false,
        timeOffTypes: [],
        timeOffTypesLength : 0,
        selectedTimeOffType: {},
        ptoTypeID : 0,
        ptoTypeName : "",
        ptoType : {},
        availablehrs : "",
        isDnsChecked : false,
        ptoReason : "",
        hotelID : 0,
        ptoRequestID : 0,
        existPtoType : "",
        parentState : this.props.parentState,
        timeValidation : false,
        dateEmpty : false,
        hid : "",
        ChangesDone : false,
        spinnerLoading : false,
        modalShiftOverlaps: false,
        modalShiftOverlapsData: "",
        modalShiftOverlapsType: "",
        discardClicked : false
    };
  
  }

  componentDidMount() {   

    this.GetLaborTimeoffSummaryDetails();
  }

  getPTOBalance = async () => {
    let currentDate: any;
    currentDate =
      new Date().getMonth() +
      1 +
      "/" +
      new Date().getDate() +
      "/" +
      new Date().getFullYear();

    try {
      let hid: any;
      // debugger;
        hid = this.state.pendingApprovalData[0].hotelID;
      

      const response = await Promise.all([
        LaborPTOCalculation.getPTOBalance(
          hid,
          this.state.userName,
          currentDate
        ),
        LaborSchedule.getEmployeeTypeByUserName(
          this.state.userName,
        ),
      ]);
      if (response[0] != null) {
        const timeOffTypes = response[0]?.map(
          (x: {
            hours: string;
            availableHours: string | string[];
            ptoTypeID: string;
          }) => {
            if (!(x.ptoTypeID === "-1" || x.ptoTypeID === "-2")) {
              const hours = x?.hours.replace("hrs", "");
              x.availableHours = (hours.includes(",") || hours.includes(";")
                ? (hours?.split(",").length > 1
                  ? hours?.split(",")[0]
                  : hours?.split(";")[0]
                )?.trim()
                : hours
              )?.trim();
              x.availableHours = !x.availableHours.includes(":")
                ? x.availableHours + ":00"
                : x.availableHours;
            }
            return x;
          }
        );
        this.setState({
          timeOffTypes: [...timeOffTypes],
          timeOffTypesLength : 1
          // typeLoader: false
        });
        // debugger;
        const Type = this.state.timeOffTypes.find(type => type.timeOffType === this.state.existPtoType);
        this.setState({ptoType : Type})
        this.setState({ptoTypeID : Type.ptoTypeID , ptoTypeName : Type.timeOffType, availablehrs : Type.availableHours});
        
      }
    } catch (error: any) {
      Utils.toastError(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
    } finally {
    }
  };


  GetLaborTimeoffSummaryDetails(){   
      let request = {} as any;
      request.hid = 0;    
      this.setState({tableLoader: true});
      request.Useruniqueno = JSON.parse(localStorage.storage).user_UniqueID;   
      request.type = "Pending";
      request.declinedDay = null;
      LaborSchedule.LaborTimeoffSummaryDetails(request)
        .then(async (result: any) => {
          // debugger
        
          if (result.message === "Success") {
            let requestArray: any = [];
           
            for(let i=0; i < result.result.length;i++ ){            
                         
             let request = {} as any;    
             request.requestDate =  result.result[i].requestDate;
             request.startDate =  result.result[i].startdateTime;
             request.endDate= result.result[i].enddateTime;
             request.hrs= result.result[i].hrs;  
             request.ptoType= result.result[i].ptoName; 
              request.reason= result.result[i].reason;  
              request.ptoRequestID = result.result[i].ptoRequestID;
              request.hotelID = result.result[i].hotelID;
              request.hid = result.result[i].letterCode
             requestArray.push(request);
          }
          this.setState({pendingApprovalData:requestArray, tableLoader: false , userName : JSON.parse(localStorage.storage).userName,ptoTypeName : requestArray.ptoType});
           


          }else{
            this.setState({pendingApprovalData:[], tableLoader: false});
          }
        }).catch((err) => {
                  Utils.toastError(`Server Error, ${err}`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                 
                  //reject();
                  this.setState({ tableLoader: false })
                });



  };

  getModalData = () => {
    // debugger;
    let IsPTOID = "1";
    let startDate = this.state.rowData.startDate.split(' ')[0]
    let tapedUser: any = {};
    LaborTimeOffDetails.GetTimeOffRequestDetails(
      this.state.rowData.ptoRequestID,
      startDate,
      IsPTOID
    )
      .then(async (result: any | null) => {
        let data = result.result;
        if (result.success) {
          this.setState({tableData : data , tableDataLength : 1 , timeOffRequestDateTime : data.timeOffRequestDateTime,ptoReason : data.ptoResion});
          let datass = this.state.timeOffRequestDateTime.map(item => ({ ...item, isDnsChecked: false }));
          this.setState({
            timeOffRequestDateTime: [...datass]
        });
        debugger;
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "timeoffRequest",
        });
      });
  };

  outsidehandler = () => {
    
  }


  onEditClick = (row) => {
    this.setState({showEditModal: true, rowData : row , editApprovedPTOModal : false , hotelID : row.hotelID,ptoRequestID : row.ptoRequestID , existPtoType : row.ptoType , formHasError : false , dateEmpty : false , timeValidation : false , hid : row.hid,ChangesDone : false,spinnerLoading : false , discardClicked : false} ,
       () => { 
        this.getPTOBalance();
        this.getModalData()
      } )
       
  }

 

   calculateDuration = (index: string | number) => {
    const timeOffRequestDateTime = this.state.timeOffRequestDateTime;
    const { startDate, endDate, inTime24Hrs, outTime24Hrs } = this.state.timeOffRequestDateTime[index];
    if (startDate && startDate !== "Select" && inTime24Hrs && outTime24Hrs) {
      let startdateTime = new Date(new Date(startDate).toLocaleDateString("en-US") + " " + inTime24Hrs);//new Date(startDate + " " + startTime);
      let endDateTime = new Date(new Date(endDate).toLocaleDateString("en-US") + " " + outTime24Hrs);//new Date(endDate + " " + endTime)
      const totalMinutes = this.timeDiffCalc(startdateTime, endDateTime);
      if (totalMinutes > 0) {
        const totalHours = this.getHoursFromMinutes(totalMinutes);
        timeOffRequestDateTime[index].totalMinutes = totalMinutes;
        timeOffRequestDateTime[index].duration = totalHours;
        // this.setState({ isNewRow: false });
      } else {
        timeOffRequestDateTime[index].totalMinutes = 0;
        timeOffRequestDateTime[index].duration = "";
      }
    } else {
      timeOffRequestDateTime[index].totalMinutes = 0;
      timeOffRequestDateTime[index].duration = "";
    }
    this.setState({ timeOffRequestDateTime });
    if(this.state.timeValidation === true){
      this.setState({formHasError : true});
    }
    
  };

  handleDateValidation = (index) => {
    debugger;
    let { timeOffRequestDateTime } = this.state;
    const { startDate, endDate, inTime24Hrs, outTime24Hrs } = timeOffRequestDateTime[index];
    let formHasError = false;
    if (startDate && startDate !== "" && endDate && endDate !== "" && inTime24Hrs && outTime24Hrs) {      
      formHasError = false;
    } else {
      formHasError = true;
    }
    if(!formHasError){
    this.timeValidations(index);
    }else{
      timeOffRequestDateTime[index].formHasError = formHasError;
    this.setState({ timeOffRequestDateTime, formHasError: formHasError });
    }
  }

  calculateEndDate = (index: number) => {

    let newTableData = [...this.state.timeOffRequestDateTime];
    const { startDate, inTime24Hrs, outTime24Hrs } = newTableData[index];

    if (
      !startDate || startDate === "" ||
      !inTime24Hrs || inTime24Hrs === "" ||
      !outTime24Hrs || outTime24Hrs === ""
    ) {
      return false;
    } else {

      let inMomentTime = moment(
        `${moment(startDate).format("MM/DD/YYYY")} ${inTime24Hrs}`
      );
      let outMomentTime = moment(
        `${moment(startDate).format("MM/DD/YYYY")} ${outTime24Hrs}`
      );

      let differenceInTime = outMomentTime.diff(inMomentTime);

      newTableData[index].endDate = moment(`${moment(startDate).format("MM/DD/YYYY")} ${outTime24Hrs}`).format("MM/DD/YYYY");
      if (differenceInTime <= 0) {
        newTableData[index].endDate = moment(`${moment(startDate).format("MM/DD/YYYY")} ${outTime24Hrs}`)
          .add(1, "days")
          .format("MM/DD/YYYY");
      }
    }

    this.setState({ timeOffRequestDateTime: newTableData });
  }

  getHoursFromMinutes = (totalMinutes: number) => {
    const isNagetive = totalMinutes.toString().includes("-") ? true : false;
    totalMinutes = +totalMinutes.toString().replace("-", "");
    var hours: number = Math.floor(totalMinutes / 60);
    var minutes: number = totalMinutes % 60;
    const hoursWithMinutes =
      hours + ":" + Math.round(minutes).toString().padStart(2, "0");
    return isNagetive
      ? "-" + hoursWithMinutes?.toString().replace("-", "")
      : hoursWithMinutes;
  };

  
  timeDiffCalc = (startDateTime, endDateTime) => {
    let dateTime = endDateTime - startDateTime;
    let min = Math.floor(dateTime / 1000 / 60);
    return min;
  }


  editTimings = (index, event,value) => {  //dhruvi
    this.setState({ChangesDone : true , discardClicked : false});
    const newTime = event.target.value;
    this.setState((prevState) => {
      const updatedTimeOffRequestDateTime = [...prevState.timeOffRequestDateTime];
      value === "inTime" ? 
      updatedTimeOffRequestDateTime[index].inTime24Hrs = newTime : updatedTimeOffRequestDateTime[index].outTime24Hrs = newTime;
      value === "inTime" ?  updatedTimeOffRequestDateTime[index].inTime = newTime : updatedTimeOffRequestDateTime[index].outTime = newTime;
      return { timeOffRequestDateTime: updatedTimeOffRequestDateTime };
    } , () => {
      this.calculateTimeDuration(index)
    });
   
  };

  calculateTimeDuration = (index: string | number) => {  
    // debugger;
    const timeOffRequestDateTime = this.state.timeOffRequestDateTime;
    let { startDate, endDate, inTime24Hrs, outTime24Hrs } = this.state.timeOffRequestDateTime[index];
    if (startDate && startDate !== "Select" && inTime24Hrs && outTime24Hrs) {
      let startdateTime = new Date(new Date(startDate).toLocaleDateString("en-US") + " " + inTime24Hrs);//new Date(startDate + " " + startTime);
      let endDateTime = new Date(new Date(endDate).toLocaleDateString("en-US") + " " + outTime24Hrs);//new Date(endDate + " " + endTime)

      if (endDateTime <= startdateTime) {
        endDateTime.setDate(endDateTime.getDate() + 1);
      }
      const totalMinutes = this.timeDiffCalc(startdateTime, endDateTime);
      if(totalMinutes > 0)
      {
      const totalHours = this.getHoursFromMinutes(totalMinutes);
      this.setState((prevState) => {
        const updatedDuration = [...prevState.timeOffRequestDateTime];
        updatedDuration[index].dayDuration = totalHours;
        return { timeOffRequestDateTime: updatedDuration };
      });

      // debugger;
      let isOverlap=  this.cheackOVerlapWithRedError();
      if (isOverlap === false) {
        Utils.toastError("Time Off request is overlapping.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
      }
    }
    }
  };

  
  timeValidations = (index: any): void => {
    let { timeOffRequestDateTime } = this.state;
    const { startDate, endDate, inTime24Hrs, outTime24Hrs } = timeOffRequestDateTime[index];
    let formHasError = true;
    let timeValidation = true;
    let startdateTime = new Date(new Date(startDate).toLocaleDateString("en-US") + " " + inTime24Hrs);
    let endDateTime = new Date(new Date(endDate).toLocaleDateString("en-US") + " " + outTime24Hrs);
    if (endDateTime <= startdateTime) {
      endDateTime.setDate(endDateTime.getDate() + 1);
    }
    if (startDate && startDate !== "" && endDate && endDate !== "" && inTime24Hrs && outTime24Hrs) {

   // let isOverlap=  this.cheackOverlap(startdateTime, endDateTime,index);
   let isOverlap=  this.cheackOVerlapWithRedError();
    if (isOverlap === false) {
      Utils.toastError("Time Off request is overlapping.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });
        formHasError = true;
        timeValidation = true;
      // tableData[index].formHasError = formHasError;    
      this.setState({ timeOffRequestDateTime, formHasError: formHasError , timeValidation : timeValidation });
      // if(this.isSaveRequest.current!=null){
      //   this.isSaveRequest.current.disabled=true;
      // }
      return;
    }else {
      
      formHasError = false;
      timeValidation = false;
      // if(this.isSaveRequest.current!=null){
      //   this.isSaveRequest.current.disabled=false;
      // }
    }


      const totalMinutes = this.timeDiffCalc(startdateTime, endDateTime);
      if (totalMinutes < 0) {
        Utils.toastError("Start Time can't be greater than End Time.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        formHasError = true;
        timeValidation = true;
        timeOffRequestDateTime[index].formHasError = formHasError;
        this.setState({ timeOffRequestDateTime, formHasError: formHasError , timeValidation : timeValidation });
        // if(this.isSaveRequest.current!=null){
        //   this.isSaveRequest.current.disabled=true;
        // }
        return;
      } else {
        formHasError = false;
        timeValidation = false;
      }
      if (totalMinutes === 0) {
        Utils.toastError("Time Off Shift Start Time and End Time cannot be same.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
        formHasError = true;
        timeValidation = true;
        timeOffRequestDateTime[index].formHasError = formHasError;
        this.setState({ timeOffRequestDateTime, formHasError: formHasError , timeValidation : timeValidation });
        // if(this.isSaveRequest.current!=null){
        //   this.isSaveRequest.current.disabled=true;
        // }
        return;
      } else {
        formHasError = false;
        timeValidation = false;
      }
     
      if (+this.state.ptoTypeID !== -1) {
        if (totalMinutes > 480) {
          Utils.toastError("PTO/UTO duration can not be more than 8 hours for a day.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
          formHasError = true;
          timeValidation = true;
          timeOffRequestDateTime[index].formHasError = formHasError;
          this.setState({ timeOffRequestDateTime, formHasError: formHasError , timeValidation : timeValidation });
          // if(this.isSaveRequest.current!=null){
          //   this.isSaveRequest.current.disabled=true;
          // }
          return;
        } else {
          formHasError = false;
          timeValidation = false;
        }
       
        if (this.getPostEntryBalance()?.startsWith("-")) {
        setTimeout(() => {
           
          Utils.toastError("Post Entry Balance should not be less than 0.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
          
        }, 400);
        formHasError = true;
        timeValidation = true;
        timeOffRequestDateTime[index].formHasError = formHasError;
        this.setState({ timeOffRequestDateTime, formHasError: formHasError , timeValidation : timeValidation });
        // if(this.isSaveRequest.current!=null){
        //   this.isSaveRequest.current.disabled=true;
        // }
        return;
          
        } else {
          formHasError = false;
          timeValidation = false;
          timeOffRequestDateTime[index].formHasError = formHasError;           
          if(!formHasError)
          {
            timeOffRequestDateTime.map((x,index) => {
              timeOffRequestDateTime[index].formHasError = formHasError;
            });
          }
          this.setState({ timeOffRequestDateTime, formHasError: formHasError , timeValidation : timeValidation});
          return;
        }
      } else {
        if (this.getPostEntryBalance()?.startsWith("-")) {
          setTimeout(() => {
            Utils.toastError("Post Entry Balance should not be less than 0.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "scheduleMain",
            });
          }, 400);
          formHasError = true;
          timeValidation = true;
          timeOffRequestDateTime[index].formHasError = formHasError;
          this.setState({ timeOffRequestDateTime, formHasError: formHasError , timeValidation : timeValidation });
          return;
        } else {
          formHasError = false;
          timeValidation = false;
        }
      }

      if(isOverlap){
        timeOffRequestDateTime[index].isDuplicateEntry = false;
      }
    } else {
      formHasError = true;
      timeValidation = true;
    }
    timeOffRequestDateTime[index].formHasError = formHasError;
    
    if(!formHasError)
    {
      timeOffRequestDateTime.map((x,index) => {
        timeOffRequestDateTime[index].formHasError = formHasError;
      });
    }
    
    
    this.setState({ timeOffRequestDateTime, formHasError: formHasError , timeValidation : timeValidation } , () => {
    });
  };
  

  addNewEntry = () => {
    // debugger;
    const item = {
      startDate: "",
      inTime: "",
      endDate: "",
      outTime: "",
      dayDuration: "",
      inTime24Hrs : "",
      outTime24Hrs : "",
      requestID : "0",
      isDayApprove : "No",
      isDeptApprove : "No",
      // totalMinutes: 0,
      // weekStartDate: "",
      // weekEndDate: "",
      // isDuplicateEntry: false,
      isDnsChecked: false,
    };
    const timeOffRequestDateTime = [...this.state.timeOffRequestDateTime, ...[item]];
    this.setState({ timeOffRequestDateTime,
      //  isNewRow: true 
      });
  };

  removeEntry = (index: any) => {
    // this.setState({ChangesDone : true ,  discardClicked : false});
    this.isRequestDeleteConfirmPop(index);
  };

  NodeleteSingleEntry = () => {
    if(this.state.ChangesDone === false){
      this.setState({discardClicked : true});
    }
  }

  isRequestDeleteConfirmPop(index:any) {
    confirmAlert({
     // title: "Unsaved confirmation",
      message:
        "Are you sure that you want to delete this entry?",
      buttons: [
        {
          label: "Yes",
          onClick:() => this.deleteSingleEntry(index),
        },
        {
          label: "No",
          onClick: () => this.NodeleteSingleEntry(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  deleteSingleEntry= (index: any) => {
      let formHasError=false;
    
        const timeOffRequestDateTime = this.state.timeOffRequestDateTime;   
        timeOffRequestDateTime.splice(index, 1);
        this.setState({ timeOffRequestDateTime});
        if (this.getPostEntryBalance()?.startsWith("-")) { 
  
        formHasError=true;
        this.setState({ timeOffRequestDateTime, formHasError: formHasError });    
          Utils.toastError("Post Entry Balance should not be less than 0.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
          
        return;
  
      } 
  
      let isOverlap=  this.cheackOVerlapWithRedError();
  
      if (isOverlap === false) {
        Utils.toastError("Time Off request is overlapping.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "scheduleMain",
        });
          formHasError = true;   
        this.setState({ timeOffRequestDateTime, formHasError: formHasError });
        return;
      }
  
      if (!this.getPostEntryBalance()?.startsWith("-") && isOverlap) {
        timeOffRequestDateTime.map((x,index) => {
          timeOffRequestDateTime[index].formHasError = formHasError;
        });
        this.setState({ timeOffRequestDateTime});
       }

       if(formHasError === false){
        this.setState({formHasError : false , timeValidation : false , ChangesDone : true , discardClicked : false});
       }

    }

  handleDateSelect = (index, selected,value,type) => {  
    const newDate = selected;
    let startdate2 = moment(newDate).format("MM/DD/YYYY");
    this.setState((prevState) => {
      const updatedDate = [...prevState.timeOffRequestDateTime];
      updatedDate[index].startDate = startdate2;
      updatedDate[index].endDate = startdate2;
      return { timeOffRequestDateTime: updatedDate };
    });

  }

  handleSelectDate = (index, event, dateType,eventType) => {
    this.setState({ChangesDone : true , discardClicked : false});
     let { timeOffRequestDateTime } = this.state;
     const { startDate, endDate, inTime24Hrs, outTime24Hrs } = timeOffRequestDateTime[index];
     let isDuplicateDate = false;
     if (dateType === "startDate") {
      let startdateTime = new Date(new Date(startDate).toLocaleDateString("en-US") + " " + inTime24Hrs);
      let endDateTime = new Date(new Date(endDate).toLocaleDateString("en-US") + " " + outTime24Hrs);
      if (startDate && startDate !== "" && endDate && endDate !== "" && inTime24Hrs && outTime24Hrs) {
     
     const timeOffRequestDateTime = this.state.timeOffRequestDateTime;
     timeOffRequestDateTime[index]["startDate"] = event;
     let startdate1 = this.state.timeOffRequestDateTime[index].startDate;
     let startTime1 = this.state.timeOffRequestDateTime[index].startTime;
     let endTime1 = this.state.timeOffRequestDateTime[index].endTime;
 
     let startdate2 = moment(startdate1).format("MM/DD/YYYY");
     let inMomentTime = moment(
       `${startdate2} ${startTime1}`
     );
     let outMomentTime = moment(
       `${startdate2} ${endTime1}`
     );
 
     let differenceInTime = outMomentTime.diff(inMomentTime);
 
     let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
     if (differenceInTime <= 0) {
       punchOutDate = moment(startdate2)
         .add(1, "days")
         .format("MM/DD/YYYY");
     }
 
     timeOffRequestDateTime[index]['endDate'] = punchOutDate;
 
     }
 
 
     this.setState({ timeOffRequestDateTime }
       , () => this.calculateDuration(index)
     );
 
 
 
 
 
      }
     if (timeOffRequestDateTime[index]["endTime"] === '') {
       this.handleDateValidation(index);
     }
     timeOffRequestDateTime[index][dateType] = event
    //  timeOffRequestDateTime[index].isDuplicateEntry = isDuplicateDate;
     this.setState({ timeOffRequestDateTime },()=>{
       //if (tableData[index]["endDate"] === '') {
       this.calculateEndDate(index);
       if(eventType==="onChange"){
       this.timeValidations(index);
       }

       debugger;
       let dateEmpty = false;
      for (let i = 0; i < timeOffRequestDateTime.length; i++) {
        if (timeOffRequestDateTime[i].startDate === null) {
          dateEmpty = true;
          break;
        }
    }
  this.setState({ dateEmpty : dateEmpty});
      // }
     });


   }

  getPostEntryBalance = (): string => {
    const availableMinutes = this.state.availablehrs
      ? +this.state.availablehrs.split(":")[0] * 60 + +this.state.availablehrs.split(":")[1]
      : 0;
    const totalDurationMinutes = this.getTotalDurationMinutes();
    return +this.state.ptoTypeID > 0
      ? this.getHoursFromMinutes(availableMinutes - totalDurationMinutes)
      : "0";
  };

  getTotalDurationMinutes = () => {
   
    const minutesArray = this.state.timeOffRequestDateTime
      .filter(
        (x: any) => x.startDate && x.startDate !== "Select" && x.inTime24Hrs && x.outTime24Hrs
      )
      ?.map((x: any) => {
        const data = x.dayDuration.split(":");
        return +data[0] * 60 + +data[1];
      })
      .filter((r: any) => r);
    return minutesArray.length > 0
      ? minutesArray.reduce((a: any, b: any) => a + b)
      : 0;
  };

  onTypeChange = (item: any) => {  
   this.setState({ptoTypeName : item.timeOffType , ptoTypeID : item.ptoTypeID, availablehrs : item.availableHours , ChangesDone : true , discardClicked: false})
   if(item.ptoTypeID !== "-1"){
    const { timeOffRequestDateTime } = this.state;
    let isValid = true;
    for (let i = 0; i < timeOffRequestDateTime.length; i++) {
      const entry = timeOffRequestDateTime[i];
      const duration = parseFloat(entry.dayDuration);
      if (isNaN(duration) || duration > 8) {
        isValid = false;
        timeOffRequestDateTime[i].formHasError=true;
        break;
      }else{

        if (isNaN(duration) || duration < 8) {

          isValid = true;
          timeOffRequestDateTime[i].formHasError=false;
        }

      }
    }
    if(isValid === false){
      Utils.toastError("PTO/UTO duration can not be more than 8 hours for a day.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "scheduleMain",
      });

      this.setState({ timeOffRequestDateTime, formHasError: true ,timeValidation:true});
    }
    else
    {
      // if(this.state.timeValidation === false){
      // this.setState({formHasError: false });
      // }

      this.setState({ timeOffRequestDateTime, formHasError: false ,timeValidation:false});

    }
    
  }
  else{

    if(item.ptoTypeID=="-1")
    {
    const { timeOffRequestDateTime } = this.state;
    for (let i = 0; i < timeOffRequestDateTime.length; i++) {
        timeOffRequestDateTime[i].isDnsChecked=false;
        timeOffRequestDateTime[i].formHasError=false;
      }
      this.setState({ timeOffRequestDateTime, formHasError: false ,timeValidation:false});

    }
    
  //   if(this.state.timeValidation === false){
  //     this.setState({formHasError: false });
  //     }
  }
  };

  ModalClose = () => {
    this.setState({showEditModal : false , tableData : [], timeOffRequestDateTime : []});
  }

  cheackOVerlapWithRedError = () => {
    // debugger;
       //let { tableData } = this.state;
       let isValidate = true;
       let timeOffRequestDateTime = [...this.state.timeOffRequestDateTime];
      
   
       for (let i = 0; i < timeOffRequestDateTime.length; i++) {
         let srartdateA: any = new Date(new Date(timeOffRequestDateTime[i].startDate).toLocaleDateString("en-US") + " " + timeOffRequestDateTime[i].inTime24Hrs);
         let endDateA: any = new Date(new Date(timeOffRequestDateTime[i].endDate).toLocaleDateString("en-US") + " " + timeOffRequestDateTime[i].outTime24Hrs);
   
         for (let x = i+1; x < timeOffRequestDateTime.length; x++) {
           let startDateTime: any = new Date(new Date(timeOffRequestDateTime[x].startDate).toLocaleDateString("en-US") + " " + timeOffRequestDateTime[x].inTime24Hrs);
           let endDateTime: any = new Date(new Date(timeOffRequestDateTime[x].endDate).toLocaleDateString("en-US") + " " + timeOffRequestDateTime[x].outTime24Hrs);
   
         let startDateTimeB: any = new Date(startDateTime);
         let endDateTimeB: any = new Date(endDateTime);
         if (srartdateA < endDateTimeB && endDateA > startDateTimeB) {
          timeOffRequestDateTime[x].formHasError = true;  
           isValidate = false;
         
         } 
       }
     }
    //  if(isValidate===false){
    //    this.setState({ tableData});
    //    if(this.isSaveRequest.current!=null){
    //      this.isSaveRequest.current.disabled=true;
    //    }
    //  }
   
   
       return isValidate;
   
   
     }

  getTableRowsWithData = () => {
    debugger;
    return (
      <tbody>
      {this.state.timeOffRequestDateTime.map((data: any,i: any) => {
        let _minDate = new Date(new Date().getTime() - 360 * 60 * 60 * 1000);
        let _maxDate = "" ;
        const [hours, minutes] = data.dayDuration.split(":").map(Number);
        const totalHours = hours + minutes / 60;
        return (
        <tr>
            <td>
              <div className="date-picker tofDatePicker">
                <DatePicker
                  value={data?.startDate ? new Date(data.startDate) : null}
                  name="startDate"
                  className={`form-control scheduleInput ${data.isDuplicateEntry ? "alert-danger" : ''}`}
                  id="shiftStartDate"
                  minDate={_minDate ? _minDate : null}
                  maxDate={_maxDate ? _maxDate : null}
                  selected={data?.startDate? new Date(data.startDate) : null}
                  onSelect={(e: any) => this.handleSelectDate(i, e, "startDate","")}
                  onChange={(e: any) => this.handleSelectDate(i, e, "startDate","onChange")}
                  // onSelect = {(selected) => this.handleDateSelect(i,selected,"StartDate","")}
                  // onChange = {(selected) => this.handleDateSelect(i,selected,"StartDate","onChange")}
                  onBlur={(e) => this.handleDateValidation(i)}
                  placeholderText="mm/dd/yyyy"
                  required
                  autoComplete="off"
                />
              </div>
              {this.state.ptoTypeID==="-1" &&(
                 <Form.Check className="checkbox-ui"  onChange={(e) => this.dnsCheckBox(i,e)}  type={"checkbox"} 
                 checked={data.isDnsChecked}
                  label="Request DNS for Full Day(s)" />
                   )}
            </td>
          <td>
              <div>
                <Form.Control
                  type="time"
                  name="inTime"
                  value={data.inTime24Hrs}
                  className={`scheduleInput ${data.formHasError ? "alert-danger" : ''}`}
                  onChange={(e) => this.editTimings(i, e,"inTime")}
                  onBlur={() => this.timeValidations(i)}
                  disabled={
                    (!data.startDate || data.startDate === "")
                    || (this.state.ptoTypeID === "-1" ? data.isDnsChecked : "")
                  }
                  required
                />
              </div>
          </td>
          <td>
            <div>
                <Form.Control
                  type="time"
                  name="inTime"
                  value={data.outTime24Hrs}
                  className={`scheduleInput ${data.formHasError ? "alert-danger" : ''}`}
                  onChange={(e) => this.editTimings(i, e,"outTime")}
                  onBlur={() => this.timeValidations(i)}
                  disabled={
                    (!data.startDate || data.startDate === "")
                    || (this.state.ptoTypeID === "-1" ? data.isDnsChecked : "")
                  }
                  required
                />
              </div>
          </td>
          <td className="duration-col">
            <div className={((this.state.ptoTypeID !== "-1") && (totalHours > 8)) ? "show-red" : ""}>{data.dayDuration}</div> 
          </td>
          <td>
            <div className="add-remove-entry-buttons">
              <Button className="btn-outline-primary" 
               disabled={(!data.inTime24Hrs || data.inTime24Hrs === "")
                || (!data.startDate || data.startDate === "")
                 || (!data.endDate || data.endDate === "")
                 || (! data.outTime24Hrs ||  data.outTime24Hrs === "" 
                  || this.state.timeValidation || 
                  this.state.formHasError || 
                  this.state.dateEmpty)}
              onClick={() => this.AddRowEntry(i)}
             >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              {this.state.timeOffRequestDateTime.length > 1 ? 
              <Button className="btn-outline-primary" 
              disabled={(!data.inTime24Hrs || data.inTime24Hrs === "")
                || (!data.startDate || data.startDate === "")
                 || (!data.endDate || data.endDate === "")
                 || (! data.outTime24Hrs ||  data.outTime24Hrs === "")
                 //||(formHasError)
              }
              onClick={() => this.removeEntry(i)} 
             >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
              : <></>}
            </div>
          </td>
        </tr>
      )})}

        {(this.state.timeOffRequestDateTime.length > 0 && this.getTotalDurationMinutes() > 0) && (
          <tr key={this.state.timeOffRequestDateTime.length + 1}>
            <td colSpan={4}>
              <span className="totals-title p-0">Duration</span>
              :  <span className={`totals-value ${+this.state.ptoTypeID !== -1 && this.getTotalDurationMinutes() > 2400 ? "text-danger" : ""}`}>
                {this.getHoursFromMinutes(this.getTotalDurationMinutes())}
              </span>
            </td>
            <td>
              {/* <span className={`totals-value ${+selectedTimeOffType?.ptoTypeID !== -1 && this.getTotalDurationMinutes() > 2400 ? "text-danger" : ""}`}>
                {this.getHoursFromMinutes(this.getTotalDurationMinutes())}
              </span> */}
            </td>
            <td className="text-right"> </td>
          </tr>
        )}
      </tbody>
    )
  }

  onDelete = (row) => {
    // debugger;
    let request: any[] = [];
    let newItem: any = {
      HotelID: row.hotelID,
      PTORequestID: row.ptoRequestID,
      ActionType: "delete",
      pageSource: ''
    }
    request.push(newItem);
    confirmAlert({
      message: `You are selecting to delete this pending ${row.ptoType} request. Would you like to continue?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            LaborSchedule.MyPtoRaiseTimeOff(request)
              .then(async (result: any | null) => {
                let data = result;
                if (data.success && data !== null) {
                  toast.success("Pending request deleted successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "timeoffRequest",
                  });
                  
                  this.props.GetLaborTimeoffSummaryCount();
                  this.GetLaborTimeoffSummaryDetails();
                  
                 
                }
                
              })
              .catch((error) => {
                Utils.toastError(error.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "timeoffRequest",
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
    });
  }

  dnsCheckBox=(index:any,e:any)=>{  
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.timeOffRequestDateTime))
    );
    // this.setState({isDNSChecked : e.currentTarget.checked});
    data[index].isDnsChecked=e.currentTarget.checked;
    data[index].inTime24Hrs='00:00';
    data[index].inTime='00:00';
    data[index].outTime24Hrs='23:59';
    data[index].outTime='23:59';
    // data[index].isDNSRows=e.currentTarget.checked;
    data[index].dayDuration= '23:59';
    // data[index].totalMinutes=  1439;
   this.setState({timeOffRequestDateTime:data},()=>{
    if (this.state.timeOffRequestDateTime[index]["startDate"] !== '') {

      this.calculateEndDate(index);
      this.timeValidations(index);
      }
   }); 
  }

  onEditPTO = (e : any) => {
    this.setState({spinnerLoading : true});
    e.preventDefault();
    
    if (this.getPostEntryBalance()?.startsWith("-")) {     
        setTimeout(() => {         
          Utils.toastError("Post Entry Balance should not be less than 0.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "scheduleMain",
          });
          
        }, 400);       
        return;     
       }


    let isOverlap=true;
    isOverlap=this.cheackDuplicateEntry();
    if(!isOverlap){
     this.isCheckDuplicatePop();
    }else{
      let hour=this.getHoursFromMinutes(this.getTotalDurationMinutes());
      this.EditSave()
    }
  }

  convertTo12HourFormat = (time) => {
    if (time.includes('AM') || time.includes('PM')) {
      return time;
    }
    const [hour, minute] = time.split(':');
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; 
    return `${hour12}:${minute} ${ampm}`;
  }

  EditSave = () => {
    let tableData = this.state.tableData;
    let requestTableData = this.state.timeOffRequestDateTime;
    let request: IMyPtoEditPTORequest[];
    request = this.state.timeOffRequestDateTime.map((item: {
      startDate: any;
      inTime: any;
      endDate: any;
      outTime: any;
      requestID : any;
    }) => {
      const newItem: IMyPtoEditPTORequest = {
        HotelID: this.state.hotelID,
        UserName: this.state.userName,
        UserUniqueno : JSON.parse(localStorage.storage).user_UniqueID,
        PTOUniqueno: Number(this.state.ptoTypeID),
        StartDate: this.setCalcFormat(item.startDate),
        EndDate: this.setCalcFormat(item.endDate),
        WeekStartDate: this.state.parentState?.navigationCalenderDates[0],
        WeekEndDate: this.state.parentState?.navigationCalenderDates[1],
        Memo: this.state.ptoReason,
        InTime: this.convertTo12HourFormat(item.inTime),
        OutTime: this.convertTo12HourFormat(item.outTime),
        PageSource: "RequestedPTOMyTimeOff",
        PTORequestID : this.state.ptoRequestID,
        RequestID : Number(item.requestID),
        ActionType : "edit"
      };
      return newItem;
    },

    
    );

    LaborSchedule.MyPtoRaiseTimeOff(request)
    .then(async (result: any | null) => {
      debugger;
      let data = result;
      let hasTable;
      let hasString;
      let hasString2;
      if (data.success && data !== null) {
        toast.success("Pending request edited successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "timeoffRequest",
        });
        this.setState({showEditModal : false})
        this.props.GetLaborTimeoffSummaryCount();
        this.GetLaborTimeoffSummaryDetails();
        
       
      }
      else {
        if (data !== null) {
          if (data.result.toString().toLowerCase() === "actual") {
            this.setState({ IsRequestEntry: "yes" });
            hasTable = result?.message.includes("<table>");
            hasString = result?.message.includes(
              "Time Off shift overlaps with this one"
            );
            hasString2 = result?.message.includes(
              "appropriate permissions and will need"
            );

            if (hasTable) {
              this.setState({
                modalShiftOverlaps: true,
                modalShiftOverlapsData: result?.message,
                modalShiftOverlapsType: "actual",
              });
            } else if (hasString) {
              this.setState({
                modalShiftOverlaps: true,
                modalShiftOverlapsData: result?.message,
                modalShiftOverlapsType: "actual",
              });
            } else if (hasString2) {
              this.setState({
                modalShiftOverlaps: true,
                modalShiftOverlapsData: result?.message,
                modalShiftOverlapsType: "actual",
              });
            }
          } else if (data.result.toString().toLowerCase() === "schedule") {
            this.setState({ IsForceApprove: "yes" });
            hasTable = result?.message.includes("<table>");
            hasString = result?.message.includes(
              "approved as an already approved Time Off shift"
            );
            if (hasTable) {
              this.setState({
                modalShiftOverlaps: true,
                modalShiftOverlapsData: result?.message,
                modalShiftOverlapsType: "schedule",
              });
            } else if (hasString) {
              this.setState({
                modalShiftOverlaps: true,
                modalShiftOverlapsData: result?.message,
                modalShiftOverlapsType: "schedule",
              });
            }
          } else {
            this.setState({ChangesDone : true, spinnerLoading : false , discardClicked : false} , () => {
              Utils.toastError(result?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "scheduleMain",
              });
            })
           
          }
        } 
      else{
        this.setState({ChangesDone : true, spinnerLoading : false , discardClicked : false} , () => {
          toast.error(result.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeoffRequest",
          });
        });
       
       
      }
    }
    })
    
    .catch((error) => {
      this.setState({ChangesDone : true, spinnerLoading : false , discardClicked : false} , () => {
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "timeoffRequest",
        });
      });
      })
  }

  setCalcFormat = date => {
    let CDate = new Date(date).toLocaleDateString("en-US");
    let calenderMonth = parseInt(CDate.split('/')[0]) < 10 ? '0' + CDate.split('/')[0] : CDate.split('/')[0];
    let calenderDate = parseInt(CDate.split('/')[1]) < 10 ? '0' + CDate.split('/')[1] : CDate.split('/')[1];
    let calenderYear = CDate.split('/')[2];
    let calenderDateString = `${calenderMonth}/${calenderDate}/${calenderYear}`;
    return calenderDateString;
  };

  cheackDuplicateEntry = () => {
 
    let isValidate = true;
    let timeOffRequestDateTime = [...this.state.timeOffRequestDateTime];
   

    for (let i = 0; i < timeOffRequestDateTime.length; i++) {


      let srartdateA: any = new Date(new Date(timeOffRequestDateTime[i].startDate).toLocaleDateString("en-US") + " " + timeOffRequestDateTime[i].inTime24Hrs);
      let endDateA: any = new Date(new Date(timeOffRequestDateTime[i].endDate).toLocaleDateString("en-US") + " " + timeOffRequestDateTime[i].outTime24Hrs);

      for (let x = i+1; x < timeOffRequestDateTime.length; x++) {
        let startDateTime: any = new Date(new Date(timeOffRequestDateTime[x].startDate).toLocaleDateString("en-US") + " " + timeOffRequestDateTime[x].inTime24Hrs);
        let endDateTime: any = new Date(new Date(timeOffRequestDateTime[x].endDate).toLocaleDateString("en-US") + " " + timeOffRequestDateTime[x].outTime24Hrs);

      let startDateTimeB: any = new Date(startDateTime);
      let endDateTimeB: any = new Date(endDateTime);
      if (srartdateA < endDateTimeB && endDateA > startDateTimeB) {
        isValidate = false;
        break;
      } 
    }

   if (!isValidate){
    break;
   }
    }
    return isValidate;
  }

  onOKClick = () => {
    this.setState({spinnerLoading : false});
  }

  isCheckDuplicatePop() {
    confirmAlert({
      message:
        "You have made multiple requests with the same date or time. Please update and submit again.",
      buttons: [
        {
          label: "OK",
          onClick: () => this.onOKClick(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "duplicate-entry-alert"
    });
  }

  discardChanges = (e) => {
    this.setState({timeValidation : false ,formHasError : false ,discardClicked : true})
    this.getModalData();
    this.getPTOBalance();
  }

  onReasonChange =(e) => {
    this.setState({ptoReason : e.target.value ,ChangesDone : true , discardClicked: false});
  }

  AddRowEntry = (index: any) => {
    this.setState({ChangesDone : true, discardClicked : false});
    
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.timeOffRequestDateTime))
    );
   // let dates = this.state.headerFullDates;
    let rowNo = data.length;
    let lastIndex = data.length - 1;

    let newTableData: any = [];
    //let Start_Date=slicedData[lastsliceIndex].startDate
    let End_date=this.prepareEndDate(index);    

    if (index !== lastIndex) {
      let slicedData = data.slice(0, index + 1);
      let restData = data.slice(index + 1);
      if (slicedData.length > 0) {
        slicedData.map((item: any) => newTableData.push(item));
      }
      let lastsliceIndex = slicedData.length - 1;

      let newRow: any = {};
      newRow.startDate= moment(slicedData[lastsliceIndex].startDate).add(1, "days");
      newRow.inTime24Hrs=  slicedData[lastsliceIndex].inTime24Hrs;
      newRow.inTime=  slicedData[lastsliceIndex].inTime;
      newRow.endDate=End_date;//slicedData[lastsliceIndex].endDate;
      newRow.outTime24Hrs=  slicedData[lastsliceIndex].outTime24Hrs;
      newRow.outTime=  slicedData[lastsliceIndex].outTime;
      newRow.dayDuration=  slicedData[lastsliceIndex].dayDuration;
      newRow.isDnsChecked= slicedData[lastsliceIndex].isDnsChecked;
      newRow.requestID = "0"
      newRow.isDuplicateEntry= false;   

      //newRow.isDNSRows= ptotype === "-1" ? true : false;  

      newTableData.push(newRow);
      if (restData.length > 0) {
        restData.map((item: any) =>{
         // item.rowNo = item.rowNo +1;
         return newTableData.push(item)
        });
      }    
      this.setState({ timeOffRequestDateTime:newTableData },()=>{
        this.timeValidations(index+1);
      });


    }else{
      let slicedData = data.slice(0, index + 1);
      let restData = data.slice(index + 1);
      if (slicedData.length > 0) {
        slicedData.map((item: any) => newTableData.push(item));
      }
      let lastsliceIndex = slicedData.length - 1;

      let newRow: any = {};
      //newRow.startDate= slicedData[lastsliceIndex].startDate;
      newRow.startDate= moment(slicedData[lastsliceIndex].startDate).add(1, "days");
      newRow.inTime= slicedData[lastsliceIndex].inTime;
      newRow.inTime24Hrs= slicedData[lastsliceIndex].inTime24Hrs;
      newRow.endDate=End_date;//slicedData[lastsliceIndex].endDate;
      newRow.outTime= slicedData[lastsliceIndex].outTime;
      newRow.outTime24Hrs= slicedData[lastsliceIndex].outTime24Hrs;
      newRow.dayDuration=  slicedData[lastsliceIndex].dayDuration;
      newRow.isDnsChecked= slicedData[lastsliceIndex].isDnsChecked;
      newRow.requestID = "0"
      
      newRow.isDuplicateEntry= false;   
      //newRow.isDNSRows= ptotype === "-1" ? true : false;  
  

      newTableData.push(newRow);
      if (restData.length > 0) {
        restData.map((item: any) =>{
         // item.rowNo = item.rowNo +1;
         return newTableData.push(item)
        });
      }    
      this.setState({ timeOffRequestDateTime:newTableData },()=>{
        this.timeValidations(index+1);
      });

    }

  };

  prepareEndDate = (index:  number, ) => {
    const tableData = this.state.timeOffRequestDateTime;
    let startdate1 = this.state.timeOffRequestDateTime[index].startDate;
    let startTime1 = this.state.timeOffRequestDateTime[index].inTime24Hrs;
    let endTime1 = this.state.timeOffRequestDateTime[index].outTime24Hrs;

    let startdate2 = moment(startdate1).add(1, "days").format("MM/DD/YYYY");
    let inMomentTime = moment(
      `${startdate2} ${startTime1}`
    );
    let outMomentTime = moment(
      `${startdate2} ${endTime1}`
    );

    let differenceInTime = outMomentTime.diff(inMomentTime);

    let punchOutDate = moment(startdate2).format("MM/DD/YYYY");
    if (differenceInTime <= 0) {
      punchOutDate = moment(startdate2)
        .add(1, "days")
        .format("MM/DD/YYYY");
    }

    return punchOutDate;
  };

  handleModalShiftClose = () => {
    this.setState({
      modalShiftOverlaps: false,
      ChangesDone : true,
      spinnerLoading : false,
      discardClicked : false
    });
  };

  render() {
    const {
     timeOffTypes
    } = this.state;

    const PendingApprovalcolumns = [
      
        {
          dataField: "",
          text: "Request Date",
          editable: false,
          formatter: (cell: any, row: any, rowIndex: any) => (
           
              <span className="">
               <span>{row.entryType}  </span> 
               <span className="autoPunch-status badge">{row.requestDate}</span>
              </span>
          ),
        },
        {
            dataField: "",
            text: "Start Date/Time",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
             
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.startDate}</span>
                </span>
            ),
          },
          {
            dataField: "",
            text: "End Date/Time",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
             
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.endDate}</span>
                </span>
            ),
          },
          {
            dataField: "",
            text: "Hours",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
             
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.hrs}</span>
                </span>
            ),
          },
          {
            dataField: "",
            text: "Type",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.ptoType}</span>
                </span>
            ),
          },
          {
            dataField: "",
            text: "Reason",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
             
                // <span className="">
                //  <span>{row.entryType}  </span> 
                //  <span className="autoPunch-status badge">{row.reason}</span>
                // </span>
                row.reason !== "" ? 
                <Dropdown className="more-action notes-btn"  alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary  more"
                  id="dropdown-more"
                >
                  <div className="d-flex">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                      <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                    </svg>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="content">
                    <h5 className="comments">{row.reason}</h5>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              : <></>
            ),
          },
          {
            dataField: "",
            text: "",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                  <OutsideClickHandler onOutsideClick={(e) => this.outsidehandler()}>
                  <Dropdown
                    className="more-action autoPunchOutDetailsDropdown"
                    alignRight
                  >
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more border-0"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        eventKey={"Details"}
                        onClick={() => this.onEditClick(row)}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={"Details"}
                        onClick={() => this.onDelete(row)}
                      >
                        Delete
                      </Dropdown.Item>
     
                    </Dropdown.Menu>
                  </Dropdown>
                  </OutsideClickHandler>
                );
            
           // }
          },
        }
  
  
      ];
  return(<>
          {
            this.state.tableLoader ? <ReactPageLoader useas={"myPTOLoader"} /> :
          
          <div className={this.state.pendingApprovalData.length > 5 ? "add-scrollabr" : ""}>
              <BootstrapTable
              keyField="rowNo"
              data={this.state.pendingApprovalData}
              columns={PendingApprovalcolumns}
              hover
            />
             {this.state.pendingApprovalData.length === 0 && <div className="data-alert-caption">No record found</div>}
          </div>

          
          }
        
          {this.state.tableDataLength === 1 && this.state.timeOffTypesLength === 1 && this.state.timeOffRequestDateTime.length > 0 &&
          <Modal show={this.state.showEditModal}
            centered
            keyboard={false}
            size="lg"
            className="edit-pending-request-modal"
            onHide={this.ModalClose}
          >
            <Modal.Header closeButton>
            <Modal.Title>Edit Time Off Request</Modal.Title>
          </Modal.Header>
            <Modal.Body>
              <div className="user-type">
                <Form>
             
                <>
                  <div className="d-flex justify-content-between">
                  <Form.Group className="mb-3 hasEhidSelector" controlId="HID">
                      <Form.Label>HID <b>*</b></Form.Label>
                      <Form.Control type="text" disabled placeholder="HID" value={this.state.hid} />  
                    </Form.Group> 
                    <Form.Group className="mb-3 col-w-42" controlId="userName">
                      <Form.Label>User <b>*</b></Form.Label>
                      <Form.Control type="text" disabled placeholder="Enter user name" value={this.state.userName} />  
                    </Form.Group> 
                   
                      <Form.Group controlId="select-type" className="col-w-42 type-dropdown">
                <Form.Label>
                  Type <b>*</b>
                </Form.Label>
                <Dropdown
                >
                  <Dropdown.Toggle id="dropdown-importFrom">
                    <EllipsisWithTooltip placement="top">
                        <span className="selected">
                            {this.state.ptoTypeName}
                          {+this.state.ptoTypeID > 0 &&
                            `, ${this.state.availablehrs} Available`} 
                        </span>
                    </EllipsisWithTooltip>
                  </Dropdown.Toggle>
                  {
                    this.state.typeLoader && <ReactPageLoader useas={"field-loader"} />
                  }
                  
                  <Dropdown.Menu>
                    <div>
                      {timeOffTypes.map((item: any, idx: any) => {
                        if (Number(item.ptoTypeID) === -1) {
                            return (
                              <EllipsisWithTooltip placement="bottom">
                                <Dropdown.Item
                                  key={idx}
                                  eventKey={item.ptoTypeID}
                                  onClick={() => this.onTypeChange(item)}
                                >
                                  <div className="dropdwn-timeoff-type">
                                    {item.timeOffType}
                                  </div>
                                  <div className="dropdwn-timeoff-value">
                                    {item.availableHours}{" "}
                                    {+item.ptoTypeID > 0 && "Balance"}{" "}
                                  </div>
                                </Dropdown.Item>
                              </EllipsisWithTooltip>
                            );
                          } else {
                              return (
                                <EllipsisWithTooltip placement="bottom">
                                  <Dropdown.Item
                                    key={idx}
                                    eventKey={item.ptoTypeID}
                                    onClick={() => this.onTypeChange(item)}
                                  >
                                    <div className="dropdwn-timeoff-type">
                                      {item.timeOffType}
                                    </div>
                                    <div className="dropdwn-timeoff-value">
                                      {item.availableHours}{" "}
                                      {+item.ptoTypeID > 0 && "Balance"}{" "}
                                    </div>
                                  </Dropdown.Item>
                                </EllipsisWithTooltip>
                              );
                            }
                        
                      })}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
                  </div>
                  <div>
                  <div className="mb-3">
                    <Form.Label>Reason</Form.Label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      value={this.state.ptoReason}
                      maxLength={200}
                      onChange={(e: any) => this.onReasonChange(e)}
                    />
                  </div> 
                  </div>
                  <hr />
                  <div className="mb-2">
                    <label className="form-label labelheader">
                      Request Entries
                    </label>
                    <div className={`table-wrapper ${this.state.ptoTypeID==="-1" ? "table-DNS-view" : ""}`}>
                      <Table>
                        <thead>
                          <tr className="bg-light">
                            <th>Start Date</th>
                            <th>Start Time</th>
                            {/* <th>End Date</th> */}
                            <th>End Time</th>
                            <th>Duration</th> 
                            <th></th> 
                          </tr>
                        </thead>
                        {this.getTableRowsWithData()}
                      </Table>
                    </div>
                  </div>
                </>
                
                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer className={`justify-content-end ${+this.state.ptoTypeID > 0 ? "justify-content-between" : ""}`}>
            {+this.state.ptoTypeID > 0 && (
              <div className="post-entry-balance">
                Post Entry Balance:{" "}
                <span
                  className={
                    this.getPostEntryBalance()?.startsWith("-")
                      ? "badge color-orange"
                      : "badge color-green"
                  }
                >
                  {/* {this.state.tableDataLength > 0 ? this.state.tableData.postBalance : ""} */}
                  {this.getPostEntryBalance()}
                </span>
              </div>
             )}

              <div>
                <Button variant="primary" 
                className="mr-3 btn-discard"
                onClick={(e: any) => this. discardChanges(e)}
                disabled={
                  this.state.ChangesDone === false || this.state.discardClicked === true
                }
                >
                  Discard
                </Button>
                <Button variant="primary" 
                disabled={
                  
                  // this.state.loaderTrue 
                  // ||
                  this.getPostEntryBalance()?.startsWith("-") || 
                  this.getTotalDurationMinutes() === 0 ||
                  //isBothEqual ||
                  this.state.formHasError && this.state.timeValidation 
                  || this.state.dateEmpty || this.state.ChangesDone === false
                   || this.state.spinnerLoading
                }
                onClick={(e: any) => this. onEditPTO(e)}
                >
                  {this.state.spinnerLoading ? <Spinner animation="border" size="sm" /> : <></>} Edit Time Off 
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
           }

    <Modal
      show={this.state.modalShiftOverlaps}
      onHide={this.handleModalShiftClose}
      backdrop="static"
      keyboard={false}
      centered
      className="modaloverlapOverlay"
    >
      <Modal.Header>
        <Modal.Title>
          {this.state.modalShiftOverlapsType.toLowerCase() === "actual" &&
            this.state.modalShiftOverlapsData.includes(
              "appropriate permissions and will need"
            ) ? (
            "Insufficient Permission"
          ) : (
            <>Shift overlap</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="shiftTimeModal">
          {this.state.modalShiftOverlapsType.toLowerCase() ===
            "schedule" ? (
            <>
              {this.state.modalShiftOverlapsData.includes("<table>") ? (
                <>
                  <p>
                    <strong>
                      This time off request overlaps with the following
                      shifts:
                    </strong>
                  </p>
                  <div className="table-responsive">
                    <div className="shiftTimeModalTable">
                      <table>
                        <tr>
                          <th>Position</th>
                          <th>Date</th>
                          <th>In Time</th>
                          <th>Out Time</th>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="shiftTimeModalTable shiftTimebodyTable"
                      dangerouslySetInnerHTML={{
                        __html: this.state.modalShiftOverlapsData,
                      }}
                    ></div>
                  </div>
                  <p>
                    Continuing will move these shifts to unassigned. Do you
                    want to continue?
                  </p>
                </>
              ) : (
                <p>{this.state.modalShiftOverlapsData}</p>
              )}
            </>
          ) : (
                /* Actual */ <>
              {this.state.modalShiftOverlapsData.includes("<table>") && (
                <>
                  <p>
                    <strong>
                      This time off request overlaps with the following
                      shifts:
                    </strong>
                  </p>
                  <div className="table-responsive">
                    <div className="shiftTimeModalTable">
                      <table>
                        <tr>
                          <th>Position</th>
                          <th>Date</th>
                          <th>In Time</th>
                          <th>Out Time</th>
                        </tr>
                      </table>
                    </div>
                    <div
                      className="shiftTimeModalTable shiftTimebodyTable"
                      dangerouslySetInnerHTML={{
                        __html: this.state.modalShiftOverlapsData,
                      }}
                    ></div>
                  </div>
                  <p>
                    The time off cannot be added as it overlaps with an
                    existing shift.
                  </p>
                </>
              )}
              {this.state.modalShiftOverlapsData.includes(
                "Time Off shift overlaps with this one"
              ) && (
                  <>
                    <p>{this.state.modalShiftOverlapsData}</p>
                  </>
                )}
              {this.state.modalShiftOverlapsData.includes(
                "appropriate permissions and will need"
              ) && (
                  <>
                    <p>{this.state.modalShiftOverlapsData}</p>
                  </>
                )}
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center">
        {this.state.modalShiftOverlapsType.toLowerCase() === "schedule" ? (
          <>
            {this.state.modalShiftOverlapsData.includes("<table>") ? (
              <>
                <Button
                  id="yes"
                  className="btn-success"
                onClick={(e: any) => this.onEditPTO(e)}
                >
                  Yes
                </Button>
                <Button
                  variant="danger"
                  onClick={this.handleModalShiftClose}
                >
                  No
                </Button>
              </>
            ) : (
              <Button onClick={this.handleModalShiftClose}>Ok</Button>
            )}
          </>
        ) : (
          <>
            {this.state.modalShiftOverlapsData.includes("<table>") && (
              <Button onClick={this.handleModalShiftClose}>Ok</Button>
            )}
            {this.state.modalShiftOverlapsData.includes(
              "Time Off shift overlaps with this one"
            ) && (
                <>
                  <Button onClick={this.handleModalShiftClose}>Ok</Button>
                </>
              )}
            {this.state.modalShiftOverlapsData.includes(
              "appropriate permissions and will need"
            ) && (
                <>
                  <Button
                    className="btn-success"
                  onClick={(e: any) => this.onEditPTO(e)}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="danger"
                    onClick={this.handleModalShiftClose}
                  >
                    No
                  </Button>
                </>
              )}
          </>
        )}
      </Modal.Footer>
    </Modal>
   </>
    );
  }
}

