import * as React from "react";
import { Form } from "react-bootstrap";
import { ModuleReports } from "../../Common/Services/ModuleReports";
import { DropDownMultiList } from "./common-components/dropdown-multi-list";

export class DataSource2Periods extends React.Component<any, any> {
  private checkBoxRef: any;
  constructor(props: any) {
    super(props);
    this.checkBoxRef = React.createRef();

    this.state = {
      dataSourceList: [
        { label: "$", value: "$", isChecked: true },
        { label: "%", value: "%", isChecked: false }, 
        { label: "POR", value: "POR", isChecked: false }]
    };
  }
  
  componentDidMount = () => {
    const { defaultValue } = this.props;
    let dataSourceList = [...this.state.dataSourceList];
    if (defaultValue === undefined) {
      this.props?.onDataSourceChange2(this.state.dataSourceList);
    } else {
      let groupNames = defaultValue.split(",")?.map(r => r);
      const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
      groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
      dataSourceList = dataSourceList?.map(item => {
        if (groupNames?.includes(item?.label)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      })
      this.setState({ dataSourceList, defaultValue },()=>{
        this.props?.onDataSourceChange2(dataSourceList);
      });
    }
  };

  updateMultiSelectItem = (selectedItem: any) => {
    this.props?.onDataSourceChange2(selectedItem);
  };

  dataChange = () => {
  };

  handleDropdownChange = (e: any) => {
  };

  componentDidUpdate(prevProps, prevState) {
    const { defaultValue } = this.props;
    let dataSourceList = [...this.state.dataSourceList];
    if (prevState.defaultValue !== defaultValue) {
      let groupNames = defaultValue.split(",")?.map(r => r);
      const selectedGroups = dataSourceList?.filter(r => groupNames?.includes(r.label))
      groupNames = groupNames.filter(r => selectedGroups?.map(r => r?.label)?.includes(r));
      dataSourceList = dataSourceList?.map(item => {
        if (groupNames?.includes(item?.label)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
        return item;
      })
      this.setState({ dataSourceList, defaultValue });
    }
  }

  render() {
    return (
      <div>
        {/* <Form.Group controlId={"ACACODES"} > */}
          {/* <Form.Label className="sub-ttl">Data Source 2</Form.Label> */}
          <DropDownMultiList
            defaultValue={this.state?.defaultValue}
            label={"label"}
            value={"value"}
            data={[...this.state.dataSourceList]}
            updateMultiSelectItem={this.updateMultiSelectItem}
            dataChange={this.dataChange.bind(this)}
            isSelectAll={true}
            placeHolder={"Select Data Source 2"}
            filtertitle={"Filter Data Source 2"}
          />
        {/* </Form.Group> */}
      </div>
    );
  }
}
