import React from "react";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { Button, Col, Dropdown, Form, Modal } from "react-bootstrap";

export class AddSalesGoal extends React.Component<any, any> {
  toggleRef: any  = React.createRef();
  private salesGoalFormSchema = Joi.object({
    taskTypeId: Joi.number()
      .required()
      .min(1)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please Select Task Type" };
        });
      }),
    dayNo: Joi.number()
      .min(0)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Days count should be greater than or equal to 0" };
        });
      }),
    weekNo: Joi.number()
      .required()
      .min(0)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Week count should be greater than or equal to 0" };
        });
      }),
  });

  state: any = {
    salesGoal: {
      taskTypeId: (+this.props?.salesRow?.uniqueNo || 0),
      dayNo: (this.props?.salesRow?.dayNo==="0" ? 0: +this.props?.salesRow?.dayNo  || null ),
      weekNo: (this.props?.salesRow?.weekNo === "0" ? 0 : +this.props?.salesRow?.weekNo || null)
    },
    calenderShow: false,
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    showSalesGoalModal: false,
    isSalesAgent: false,
    salesTaskTypes: [],
    salesTaskTypesMain: this.props.salesTaskTypesMain,
  };

  componentDidMount(){
    let  _salesTaskTypesMain = this.props?.salesTaskTypesMain;
    let salesGoalUnique=this.props.salesGoals.map(item => +item.uniqueNo)

    if ((_salesTaskTypesMain)?.length > 0) {
      if(Object.keys(this.props?.salesRow)?.length > 0) {
        _salesTaskTypesMain = (_salesTaskTypesMain).filter(
          (item) => (+item.uniqueno === +this.props?.salesRow?.uniqueNo || !salesGoalUnique.includes(+item.uniqueno))
        );
      }
      else {
        _salesTaskTypesMain = (_salesTaskTypesMain).filter(
            (item) =>
              !this.props.salesGoals
                .map((x) => x?.uniqueNo)
                .includes(item?.uniqueno)
          );
        }
      }
        this.setState({ salesTaskTypes: _salesTaskTypesMain || [] });


  };

  decimalValueChange =(event)=>{
    const {name,value}=event.target
    const newValue=Utils.decimalValue(value)
    const { salesGoal } = this.state;
    salesGoal[name]=newValue
    this.setState({ salesGoal }, () =>
    this.validateDocumentFormSchema()
  );
  }

  onFieldChange = (event, inputPosition) => {
    const { salesGoal } = this.state;
    let value = event.target.value;
    value = value?.toString()?.replace(/[^0-9\.\+]+/gi, "");

    const fieldName = event.target.name;
    if((fieldName === "dayNo" || fieldName === "weekNo") && (value?.toString()?.split(".")[0]?.length > 10 || value?.toString()?.split(".")[1]?.length > 2))
    {
      return
    }
    salesGoal[fieldName] = value;
    if (fieldName === "dayNo" || fieldName === "weekNo") {

      value = value ? +value : value;
      
      if (fieldName === "dayNo") {
        if (value) {
          const weekNo = value * 5;
          salesGoal.weekNo = Number.isInteger(weekNo) ? weekNo : weekNo?.toFixed(2);
        } else {
          salesGoal.weekNo = value == "0"? 0:"";
        }
      }
      if (fieldName === "weekNo") {
        if (value) {
          const dayNo = value / 5;
          salesGoal.dayNo = Number.isInteger(dayNo) ? dayNo: dayNo?.toFixed(2);
        } else {
          salesGoal.dayNo =  value == "0"? 0:"";
        }
      }
    }
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({ inputPosition });
    }

    this.setState({ salesGoal }, () =>
      this.validateDocumentFormSchema()
    );
   
  };

 

  reset = () => {
    const { salesTaskTypesMain, salesTaskTypes } = this.state;
    let _salesTaskTypes = [...salesTaskTypes];
    if (salesTaskTypesMain.length > 0  && Object.keys(this.props?.salesRow)?.length === 0) {
      _salesTaskTypes = salesTaskTypesMain.filter(
        (item) =>
          !this.props.salesGoals
            .map((x) => x?.uniqueNo)
            .includes(item?.uniqueno)
      );
    } 

    this.setState({
      salesGoal: {
        taskTypeId: (+this.props?.salesRow?.uniqueNo || 0),
        dayNo: (+this.props?.salesRow?.dayNo || 0),
        weekNo: (+this.props?.salesRow?.weekNo || 0)
      },
      calenderShow: false,
      errors: {},
      isDataSaving: false,
      isFormNotValid: true,
      inputPosition: 0,
      showSalesGoalModal: false,
      salesTaskTypes: _salesTaskTypes,
    });
  };

  saveUserGoal = () => {
    const {
      salesGoal: { taskTypeId, dayNo, weekNo },
    } = this.state;

    const rowNumber=this.props?.salesRow?.uniqueNo>0 ? this.props?.salesRow?.rowNumber || 0 : 0
    this.setState({ isDataSaving: true });
    UserManagement.SaveUserGoal(this.props?.userName, taskTypeId, dayNo, weekNo,rowNumber )
      .then((result) => {
        this.setState({ isDataSaving: false });
        if (result?.message === "Success") {
          toast.success("Goal Saved Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "ddlSales",
          });
       
          this.reset();
            this.hideModal(true);

        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "ddlSales",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "ddlSales",
        });
      })
      .finally(() => this.reset());
  };
  validateDocumentFormSchema = () => {
    const valid = Joi.validate(this.state.salesGoal, this.salesGoalFormSchema, {
      abortEarly: false,
    });

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };
  componentWillUnmount=()=> {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }
  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };


  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  validationOnClick = (inputPosition) => {
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({ inputPosition }, () => this.validateDocumentFormSchema());
    }
    
  };

  openModal = () => {
    this.reset();
  };

  hideModal =(isReloadRequired = false)=> {
    this.reset();
this.props.hideSalesGoal(isReloadRequired);
  }
  onTabKeyDown = (e) => {
    e.preventDefault();
    if (this.toggleRef.current.ariaExpanded === "false" && e?.keyCode === 9) {
      this.toggleRef.current.click();
     
    }
  };

  render() {
    const {
      salesGoal: { taskTypeId, dayNo, weekNo },
      errors,
      salesTaskTypes,
      inputPosition,
      isFormNotValid,
      isDataSaving,
    } = this.state;

    const {showAddSalesModal} = this.props;

    return (
      <>
        <div className="">
          <Modal
            className="add-sales-goal-modal"
            show={showAddSalesModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{
                this.props?.salesRow?.uniqueNo>0 ?"Edit Sales Goal" : "Add Sales Goal"
                } </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  
                    <Form.Group controlId="description">
                      <Col
                        sm={"12"}
                        className={`mb-3 ${
                          errors?.taskTypeId && inputPosition >= 1
                            ? "validation-error"
                            : ""
                        }`}
                        onBlur={() => this.validationOnClick(1)}
                      >
                        <Form.Label>Task Type</Form.Label>
                        <div className="">
                          <Dropdown 
                          onKeyUp={(e)=>this.onTabKeyDown(e)}
                          className="import-from-dropdown">
                            <Dropdown.Toggle ref={this.toggleRef} id="ddlTaskType" disabled={ this.props?.salesRow?.uniqueNo>0 && salesTaskTypes.length == 1 ? true : false } >
                              {!taskTypeId ? (
                                <span className="placeholder">
                                  Select Task Type
                                </span>
                              ) : (
                                <EllipsisWithTooltip placement="bottom">
                                  <span className="selected-item">
                                    {
                                      (
                                        salesTaskTypes.find(
                                          (item: any) =>
                                            +item?.uniqueno === +taskTypeId
                                        )
                                      )?.typeName
                                    }
                                  </span>
                                </EllipsisWithTooltip>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                              {salesTaskTypes?.length > 0 && (
                                <>
                                {salesTaskTypes.map((item: any) => {
                                    return (
                                   
                                        <Dropdown.Item
                                          key={item.uniqueno}
                                          className="dropdown-item"
                                          onClick={() =>
                                            this.onFieldChange(
                                              Utils.BuildCustomEventObject(
                                                "taskTypeId",
                                                item.uniqueno
                                              ),
                                              1
                                            )
                                          }
                                        >
                                             <EllipsisWithTooltip placement="bottom">
                                             {item.typeName}
                                             </EllipsisWithTooltip>
                                          
                                        </Dropdown.Item>
                                    );
                                  })
                                }
                                  </>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        {this.getFieldError("taskTypeId", 1)}
                      </Col>
                    </Form.Group>
                    <Form.Group className="d-flex" controlId="time-picker">
                      <Col
                        sm="6"
                        onBlur={() => this.validationOnClick(2)}
                        className={
                          errors?.dayNo && inputPosition >= 2
                            ? "validation-error"
                            : ""
                        }
                      >
                        <Form.Label>Day</Form.Label>
                        <input
                          type="text"
                          onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                          step="1"
                          className="form-control"
                          id="txtDayNo"
                          name="dayNo"
                          autoComplete="txtDayNo"
                          value={dayNo}
                          onChange={(e) => this.onFieldChange(e, 0)}
                          onBlur={e=>this.decimalValueChange(e)}
                        />
                        {this.getFieldError("dayNo", 2)}
                      </Col>

                      <Col
                        sm={"6"}
                        onBlur={() => this.validationOnClick(3)}
                        className={
                          errors?.weekNo && inputPosition >= 3
                            ? "validation-error"
                            : ""
                        }
                      >
                        <Form.Label>Week</Form.Label>
                        <input
                          type="text"
                          onKeyDown={Utils.AllowOnlyPositiveNumnberInput}
                          step="1"
                          className="form-control"
                          id="txtWeekNo"
                          name="weekNo"
                          autoComplete="txtWeekNo"
                          value={weekNo}
                          onChange={(e) => this.onFieldChange(e, 0)}
                          onBlur={e=>this.decimalValueChange(e)}
                        />
                        {this.getFieldError("weekNo", 3)}
                      </Col>
                    </Form.Group>
                  
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.saveUserGoal}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
