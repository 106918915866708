import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import scrollIntoView from "scroll-into-view";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IAutoPunchOutDaysDto,
  IDeptLaborApproveRequestDto,
} from "../../../../../Common/Contracts/ILaborDayApprove";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import { resolve, reject } from "q";
import { ApproveDepartmentDetailsNew } from "../../../../../Common/Components/ApproveDepartmentDetailsNew";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import moment from "moment";
import "./AutoPunchOutTab.scss";
import { Utils } from "../../../../../Common/Utilis";

const approveDepartmentDetailsContent = [
  {
    title:"Approve Auto Punch Out",
    cancelButtonName: "Cancel",
    actionButtonName: "Approve",
    table: "autoPunchOut",
  },
];
let localStorageUser: any;

export default class AutoPunchOutTab extends React.Component<any, any> {
  private autoPunchDivRef: any;
  private approveDepartment: any;
  constructor(props: any) {
    super(props);
    this.autoPunchDivRef = React.createRef();
    this.approveDepartment = React.createRef();
    this.state = {
      parentRow: props.row,
      autoPunchOutData: [],
      multipleRowSelected: false,
      selectedRows: [],
      preSelectedRows: [],
      loaderAutoPunch: false,
      flyOutMenuApprove: false,
      punchOutTime :{}
    };
  }
  componentDidMount() {
    this.getAutoPunchApprovalData();
  }

  getAutoPunchApprovalData() { 
    localStorageUser = JSON.parse(localStorage.getItem("storage")!);
    this.setState({ loaderAutoPunch: true });
    let parentRow = this.state.parentRow;
    let request = {} as IAutoPunchOutDaysDto;
    request.hotelID = Number(parentRow.hotelID);
    request.user_uniqueno = localStorageUser.user_UniqueID;
    request.date = parentRow.date;
    request.entryType =  parentRow.isGM;;
    // GetLaborOverViewDayApproveAutoPunchDetails
    LaborDayApprove.GetLaborOverViewDayApproveAutoPunchDetails(request)
      .then(async (result: any | null) => {
        let DayApproveData: any = [];
        if (result != null && result.length > 0) {
          let Data = result as [];
          let punchOutTime = {...this.state.punchOutTime};
          Data.map((res, index) => DayApproveData.push(res));
          for (let i = 0; i < DayApproveData.length; i++) {
            DayApproveData[i].aOutTime_old = DayApproveData[i].aOutTime;
            const [time, modifier] = DayApproveData[i].aOutTime.split(" ");
            let [hours, minutes] = time.split(":");
            if (hours === "12") {
              hours = "00";
            }
            if (modifier === "PM") {
              hours = parseInt(hours, 10) + 12;
            }
            if (Number(hours) < 10 && hours.length <= 1) {
              hours = "0" + hours;
            }
            let newTimeInFormat = hours + ":" + minutes;
            punchOutTime[i] = newTimeInFormat
            DayApproveData[i].aOutTime = newTimeInFormat;
            DayApproveData[i].rowId = i;
            DayApproveData[i].flyOutAction = "";
            DayApproveData[i].hoverOutAction = "";
          }

          this.setState({
            autoPunchOutData: DayApproveData,
            loaderAutoPunch: false,
            punchOutTime:punchOutTime
          });
        } else {
          this.setState({ loaderAutoPunch: false });
        }
        
        if(!this.props.tableExpended){

          scrollIntoView(this.autoPunchDivRef.current, {
            align: {
              top: 0,
              left: 0,
              topOffset: 259,
              leftOffset: 0,
            },
          });
          this.props.changeExpandedState(true)
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        this.setState({ loaderAutoPunch: false });
        reject();
      });
  }

  inTimeChange = (e: any, index: any,row:any) => {

    let newPO = {...this.state.punchOutTime}
    newPO[row.rowId] = e.target.value; 
    this.setState(
      {
        punchOutTime: newPO,
      }
    );
  };

  onBlurChange = (e: any, index: any,row:any) => {
    if( e.target.value === ""){
      // row.aOutTime_old.replace("AM)
      let time = row.aOutTime_old.split(" ");
      let value = time[0].split(":");
      let hours = value[0];
      let appendHour =Number(hours) > 9 ? hours : `0${hours}`;
      let timeString = appendHour + ":" + value[1]
      let newPO = {...this.state.punchOutTime}
      newPO[row.rowId] = timeString; 
      this.setState(
        {
          punchOutTime: newPO,
        }
        );
      }
  };

  onRowSelect = (
    selectedRow: any,
    isSelect: boolean,
    rowIndex: any,
    e: any
  ) => {
    let data = this.state.autoPunchOutData;
    let selectedRows = this.state.selectedRows;
    let preSelectedRows = this.state.preSelectedRows;
    if (isSelect === true) {
      // if (
      //   selectedRow.isAutoPunch === "No" &&
      //   selectedRow.isNotPunchOut === "No"
      // ) {
      //   selectedRows.push(selectedRow);
      //   preSelectedRows.push(selectedRow.rowNo);
      // }

      selectedRows.push(selectedRow);
      preSelectedRows.push(selectedRow.rowNo);

      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });

        let index = data.indexOf(data[rowIndex]);
        data[index].hoverOutAction = "selected";
        data[index].flyOutAction = "rowSeclected";

        for (let i = 0; i < data.length; i++) {
          let index = data.indexOf(data[i]);
          data[index].hoverOutAction = "selected"; 
        }

        this.setState({ autoPunchOutData: data });
      } else {
        this.setState({
          multipleRowSelected: false,
        });
      }
      this.setState(
        {
          selectedRows: selectedRows,
          preSelectedRows: preSelectedRows,
        },
        () => {
          let pandingData = data.filter((item: any) => {
            return (item.approveStatus ==="")
          });
          if (pandingData.length === selectedRows.length) {
            this.props.AproveAll(
              "Approve all",
              this.state.selectedRows,
              "autopunchtab"
            );
          } else {
            this.props.AproveAll(
              "Approve All Selected",
              this.state.selectedRows,
              "autopunchtab"
            );
          }
        }
      );
    } else {
      let indexOf = selectedRows.indexOf(selectedRow);
      selectedRows.splice(indexOf, 1);
      preSelectedRows.splice(indexOf, 1);
      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });
      } else {
        this.setState({
          multipleRowSelected: false,
        });

        for (let i = 0; i < data.length; i++) {
          let index = data.indexOf(data[i]);
          data[index].hoverOutAction = "";
          data[index].flyOutAction = "";
        }
        this.setState({ autoPunchOutData: data });
      }
      this.setState(
        {
          selectedRows: selectedRows,
          preSelectedRows: preSelectedRows,
        },
        () => {
          let pandingData = data.filter((item: any) => {
            return (item.approveStatus ==="")
          });
          if (pandingData.length === selectedRows.length) {
            this.props.AproveAll(
              "Approve all",
              this.state.selectedRows,
              "autopunchtab"
            );
          } else {
            if (selectedRows.length === 0) {
              this.props.AproveAll("", this.state.selectedRows, "autopunchtab");
            }
            if (selectedRows.length > 0) {
              this.props.AproveAll(
                "Approve All Selected",
                this.state.selectedRows,
                "autopunchtab"
              );
            }
          }
        }
      ); 
    }
  };

  onRowSelectAll = (isSelect: any, rows: any, e: any) => { 
    let data = this.state.autoPunchOutData;
    if (isSelect === true) {
      let tempSelectedRows: any = [];
      let selectedRows = this.state.selectedRows;
      let preSelectedRows = this.state.preSelectedRows;
      selectedRows = tempSelectedRows;
      for (let i = 0; i < rows.length; i++) {
        //if (rows[i].isAutoPunch === "No" && rows[i].isNotPunchOut === "No") {
          if (rows[i].approveStatus === "" ) {
        selectedRows.push(rows[i]);
        preSelectedRows.push(rows[i].rowNo);
        // }
        let index = data.indexOf(rows[i]);
        data[index].hoverOutAction = "selected";
        data[index].flyOutAction = "rowSeclected";
          }
      }

      this.setState({ autoPunchOutData: data });

      if (selectedRows.length > 0) {
        this.setState({
          multipleRowSelected: true,
        });
      } else {
        this.setState({ multipleRowSelected: false });
      }
      this.setState(
        {
          selectedRows: selectedRows,
          preSelectedRows: preSelectedRows,
        },
        () => {
         
          let pandingData = data.filter((item: any) => {
            return (item.approveStatus ==="")
          });
          if (pandingData.length === selectedRows.length) {
            this.props.AproveAll(
              "Approve all",
              this.state.selectedRows,
              "autopunchtab"
            );
          } else {
            this.props.AproveAll(
              "Approve All Selected",
              this.state.selectedRows,
              "autopunchtab"
            );
          }
        }
      );
    } else {
      this.setState(
        {
          multipleRowSelected: false,
          selectedRows: [],
          preSelectedRows: [],
        },
        () => {
          this.props.AproveAll("", this.state.selectedRows, "autopunchtab");
        }
      );

      for (let i = 0; i < data.length; i++) {
        let index = data.indexOf(data[i]);
        data[index].hoverOutAction = "";
        data[index].flyOutAction = "";
      }
      this.setState({ autoPunchOutData: data });
    }
  };

  approveModalAction = (
    row: any,
    actionItem: any,
    flag: any,
    childState: { approvalReason: string }
  ) => {
    if (flag) {
      if (childState.approvalReason === "") {
        Utils.toastError(`Please enter Approval Reason`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
      } else {
        if (childState.approvalReason.length > 200) {
          Utils.toastError(`Explanation should not exceed 200 characters`, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "laborDayOverviewContainer",
          });
        } else {
          this.rowApprove(row, actionItem, childState);
        }
      }
    } else {
      if (actionItem === "singleApprove") {
        let data = this.state.autoPunchOutData;
        let index = data.indexOf(row);
        if (index >= 0) {
          data[index].flyOutAction = "";
          this.setState({ autoPunchOutData: data });
        }
      }
    }
  };

  rowApprove = (row: any, actionItem: any, childState: any) => {
    
    this.setState({ loaderAutoPunch: true });
    let data = this.state.autoPunchOutData;
    let punchOutTime = this.state.punchOutTime;
    let indexOf = data.indexOf(row);
   
    let parentRow = this.state.parentRow;
    let tempRequestArray: any = [];
    if (actionItem === "singleApprove") {
      row = data[indexOf];
      let request = {} as IDeptLaborApproveRequestDto;
      request.hotelID = Number(parentRow.hotelID);
      request.user_uniqueno = localStorageUser.user_UniqueID;
      request.username = row.userName;
      request.date = parentRow.date;
      request.explanation = childState.approvalReason;
      request.tenantID = localStorageUser.tenantID;
      request.deptID = Number(row.deptID);
      request.actUniqueno = Number(row.mainUniqueno);
      request.entryType = "autopunch";
      request.if_recordid = Number(row.if_RecordID);
      request.intime = row.aInTime; 
      let value = punchOutTime[row.rowId].split(":");
      let hours = value[0];
      let appendHour = hours % 12 || 12;
      let ampm = hours < 12 || hours === 24 ? "AM" : "PM";
      let minutes = value[1];
      let timeString = appendHour + ":" + minutes + " " + ampm;
      let oldoutDate = row.outdate.split("T")[0];
      let totalSeconds = 0;
      totalSeconds = +hours * 60 * 60 + minutes * 60;
      let newoutDate = moment(oldoutDate + " " + timeString).format(
        "MM/DD/YYYY hh:mm:ss A"
      );
      request.outTime = timeString;
      request.hrs = row.hrs;
      request.outDate = newoutDate;
      request.old_outTime = row.aOutTime_old;
      tempRequestArray.push(request);
    } else {
      for (let i = 0; i < row.length; i++) {
        let request = {} as IDeptLaborApproveRequestDto;
        request.hotelID = Number(parentRow.hotelID);
        request.user_uniqueno = localStorageUser.user_UniqueID;
        request.username = row[i].userName;
        request.date = parentRow.date;
        request.explanation = childState.approvalReason;
        request.tenantID = localStorageUser.tenantID;
        request.deptID = Number(row[i].deptID);
        request.actUniqueno = Number(row[i].mainUniqueno);
        request.entryType = "autopunch";
        request.if_recordid = Number(row[i].if_RecordID);
        request.intime = row[i].aInTime;
        let value =punchOutTime[row[i].rowId].split(":");
        let hours = value[0];
        let appendHour = hours % 12 || 12;
        let ampm = hours < 12 || hours === 24 ? "AM" : "PM";
        let minutes = value[1];
        let timeString = appendHour + ":" + minutes + " " + ampm;
        let oldoutDate = row[i].outdate.split("T")[0];
        let newoutDate = moment(oldoutDate + " " + timeString).format(
          "MM/DD/YYYY hh:mm:ss A"
        );
        request.outTime = timeString;
        request.hrs = row[i].hrs;
        request.outDate = newoutDate; //row[i].outdate;
        request.old_outTime = row[i].aOutTime_old;
        tempRequestArray.push(request);
      }
    }

    LaborDayApprove.ApproveLaborWithDept(tempRequestArray)
      .then(async (result: any | null) => {
        if (result != null) {
        
          let resultData = result.result as any;
          if (resultData.saveStatus === "Success") {
            if (actionItem === "singleApprove") {
              let indexOf = data.indexOf(row);
              data.splice(indexOf, 1);
              this.props.autoPunchoutApprove("Approved", "autoPunchOut");
            } else if (actionItem === "bulkApprove") {
              for (let i = 0; i < row.length; i++) {
                let indexOf = data.indexOf(row[i]);
                data.splice(indexOf, 1);
                this.props.autoPunchoutApprove("Approved", "autoPunchOut");
              }
            }
            this.setState({
              autoPunchOutData: data,
              selectedRows: [],
              preSelectedRows: [],
              multipleRowSelected: false,
            });

            for (let i = 0; i < data.length; i++) {
              let index = data.indexOf(data[i]);
              data[index].hoverOutAction = "";
              data[index].flyOutAction = "";
            }
            this.setState({ autoPunchOutData: data });
            toast.success("Auto Punch Out is approved successfully.", {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
          } else {
            Utils.toastError(resultData.message, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "laborDayOverviewContainer",
            });
          }
        }
        this.setState({
          loaderAutoPunch: false,
        });
        this.getAutoPunchApprovalData();
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderAutoPunch: false });
        this.getAutoPunchApprovalData();
        Utils.toastError(`Server Error, ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  };

  autoPunchOutDetails = (row: any) => {
    row.parentName = "autoPunchOut";
    this.props.positionDetail(row);
  };
  refreshAPOChild=()=> {   
    this.getAutoPunchApprovalData();
  }
  render() {
    const autoPunchOutSelectRow = {
      mode: "checkbox",
      classes: "selection-row",
      selected: this.state.preSelectedRows,
      clickToExpand: true,
      onSelect: (
        selectedRow: any,
        isSelect: boolean,
        rowIndex: any,
        e: any
      ) => {
        this.onRowSelect(selectedRow, isSelect, rowIndex, e);
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        this.onRowSelectAll(isSelect, rows, e);
      },
    };
    const rowClasses = (row: any, rowIndex: any) => {
      // debugger
      if (row.approveStatus !== "") {
        return "row-disabled"; 
      }  
      if (row.isDepartMentAccess.toLowerCase() === "no" ) {
        return "row-disabled"; 
      }            
    };

    const autoPunchOutcolumns = [
      {
        dataField: "userName",
        text: "Username",
        editable: false,
        // headerFormatter: this.headerFormat,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <span className="autoPunchOutUsername">{row.userName}</span>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "positionName",
        text: "Position",
        editable: false,
        headerClasses: "text-left",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <EllipsisWithTooltip placement="bottom">
            <span className="autoPunchOutposition">{row.positionName}</span>
          </EllipsisWithTooltip>
        ),
      },
      {
        dataField: "sInTime",
        text: "Scheduled In",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <span className="autoPunchOutscheduledIn">{row.sInTime}</span>
        ),
      },
      {
        dataField: "sOutTime",
        text: "Scheduled Out",
        headerClasses: "scheduleOutCol",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <span className="autoPunchOutscheduledOut">{row.sOutTime}</span>
        ),
      },
      {
        dataField: "aInTime",
        text: "Actual In",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <span className="autoPunchOutactualIn">{row.aInTime}</span>
        ),
      },
      {
        dataField: "aOutTime",
        text: "Actual Out",
        editable: false,
        headerClasses: "w-12",
        formatExtraData : this.state.punchOutTime,
        formatter: (cell: any, row: any, rowIndex: any,extraData:any) => {          
          return (
            <span className="autoPunchOutactualIn">
             <input
              type="time"
              id={`startTime${rowIndex}`}
              value={extraData[row.rowId]}
              onChange={(e: any) => this.inTimeChange(e, rowIndex,row)}
              onBlur={(e: any) => this.onBlurChange(e, rowIndex,row)}
              />
          </span>
              )
        },
      },

      {
        dataField: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Button
            className="btn-details autoPunchOutDetails"
            onClick={() => this.autoPunchOutDetails(row)}
          >
            Details
          </Button>
        ),
      },
      {
        dataField: "hoverOutAction",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.hoverOutAction !== "selected") {
            return (
              <div className="autoPunchOutAction actualOutDropdown">
                <Dropdown
                  className="more-action"
                  alignRight
                  // onClick={this.ApproveSetFocus}
                >
                  <Dropdown.Toggle
                    className="btn-outline-primary autoPunchOutApprove more"
                    id="dropdown-more"
                  >
                    Approve
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ApproveDepartmentDetailsNew
                      approveDepartmentDetailsContent={
                        approveDepartmentDetailsContent
                      }
                      outTime={row.aOutTime}
                      showOutTime={true}
                      // showPlaceHolder={"Explain the numbers..."}
                      showPlaceHolder={"Please provide explanation here."}
                      table="autoPunchOut"
                      ref={this.approveDepartment}
                      approveModalAction={this.approveModalAction.bind(
                        this,
                        row,
                        "singleApprove"
                      )}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          } else {
            return <div className="autoPunchOutAction"></div>;
          }
        },
      },
      {
        dataField: "flyOutAction",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.flyOutAction === "") {
            return (
              <Dropdown
                className="more-action autoPunchOutDetailsDropdown"
                alignRight
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"Details"}
                    onClick={() => this.autoPunchOutDetails(row)}
                  >
                    Details
                  </Dropdown.Item>

                  {/* <Dropdown.Item
                        eventKey={"Approve"}
                       // onClick={() => this.showCommentComponent(row)}
                      >
                        Approve
                      </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            );
          } else if (row.flyOutAction === "rowSeclected") {
            return (
              <Dropdown className="more-action" alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item 
                  eventKey={"Details"}
                  onClick={() => this.autoPunchOutDetails(row)}
                  
                  
                  >Details</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          } else {
            return (
              <OutsideClickHandler
                onOutsideClick={() => {
                  // this.onOutsideClick(row);
                }}
              >
                <div className="flayoutApproveMenu">
                  <Dropdown className="more-action show" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={true}>
                      <ApproveDepartmentDetailsNew
                        approveDepartmentDetailsContent={
                          approveDepartmentDetailsContent
                        }
                        table="autoPunchOut"
                        outTime={row.aOutTime}
                        showOutTime={true}
                        // showPlaceHolder={"Explain the numbers..."}
                        showPlaceHolder={"Please provide explanation here."}
                        ref={this.approveDepartment}
                        approveModalAction={this.approveModalAction.bind(
                          this,
                          row,
                          "singleApprove"
                        )}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </OutsideClickHandler>
            );
          }
        },
      },
    ];

    return (
      <>
        {this.state.loaderAutoPunch ? (
          <ReactPageLoader useas={"daysToApproveExpand"} />
        ) : (
          <div
            className="autoPunchOutsTable"
            id="autoPunchOutTab"
            ref={this.autoPunchDivRef}
          >
            <div className="pb-2">
              <h6 className="tableHeading">Auto Punch Outs</h6>
            </div>
            <BootstrapTable
              keyField="rowNo"
              data={this.state.autoPunchOutData}
              columns={autoPunchOutcolumns}
              selectRow={autoPunchOutSelectRow}
              rowClasses={rowClasses}
              hover
            />
          </div>
        )}
      </>
    );
  }
}
