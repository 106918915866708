import React from "react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip"; 
import TableSearch from "../../../../Common/Components/TableSearch";

const { SearchBar } = Search;
let newSearchResult: any = [];
export class SmileIdLeftPanel extends React.Component<any, any> {
  private searchBar: any;
  constructor(props: any) {
    super(props);
    this.searchBar = React.createRef();

    this.state = {
      // loaderTrue: false,
      tableData: [],
      selectedFilter: "Match %",
      filterList: [
        { id: "employee", value: "Employees" },
        { id: "confidencelevel", value: "Match %" },
      ],
      activeClassRow: "",
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    return {
      tableData: props.parentState.tableData,
    };
  }

  componentDidMount() {
     
    if (this.state.tableData.length > 0) {
      this.setState({ activeClassRow: this.state.tableData[0].uniqueno });
    }
    
  }

  undoButtonClickRerender = (row: any) => {
    let data = this.state.tableData;
    for (let i = 0; i < data.length; i++) {
      if (data[i].uniqueno === row.uniqueno) {
        this.setState(
          {
            activeClassRow: row.uniqueno,
          },
          () => {
            this.props.leftSelectedRow(this.state.tableData[i]);
          }
        );
        break;
      }
    }
  };

  undoButtonClick = (e: any, row: any) => {
    e.stopPropagation();
    this.props.leftUndoButton(row);
  };

  handleFilterChange = (value: string) => {
    if (value === "Employees") {
      this.props.sortLeftPanel("employee");
    } else {
      this.props.sortLeftPanel("match%");
    }

    this.setState({
      selectedFilter: value,
      // , activeClassRow: ""
    });
  };

  searchedData = (newResult: any) => {
    if (newResult.length > 0) {
      let newRowIndex = 0;
      this.setState({ isFirstRowSelected: true });
      for (let i = 0; i < newResult.length; i++) {
        if (newResult[i].action === false) {
          newRowIndex = i;
          break;
        }
      }
      ;
      this.setState({ activeClassRow: newResult[newRowIndex].uniqueno });
      this.props.leftSelectedRow(newResult[newRowIndex]);
    } else {
      let blankRow: any = {};
      blankRow.name = "";
      this.setState({ activeClassRow: "" });
      this.props.leftSelectedRow(blankRow);
    }

    newSearchResult = newResult;
  };

  nextSelectedRow = (row: any) => {
    ;
    debugger;
    let newTableData: any = this.state.tableData;

    if (this.searchBar.current !== null) {
      if (this.searchBar.current.state?.value !== "") {
        newTableData = newSearchResult;
      }
    }

    for (let i = 0; i < newTableData.length; i++) {
      let rowIndex: number;
      if (newTableData[i].uniqueno === row.uniqueno) {
        if (newTableData.length - 1 > i) {
          rowIndex = i + 1;
          if (newTableData[rowIndex].action === false) {
            this.setState(
              {
                activeClassRow: newTableData[rowIndex].uniqueno,
              },
              () => {
                this.props.leftSelectedRow(newTableData[rowIndex]);
              }
            );
          } else {
            for (let y = 0; y < newTableData.length; y++) {
              if (newTableData[y].action === false) {
                this.setState(
                  {
                    activeClassRow: newTableData[y].uniqueno,
                  },
                  () => {
                    this.props.leftSelectedRow(newTableData[y]);
                  }
                );
                break;
              } else {
                let blankRow: any = {};
                blankRow.name = "";
                this.setState({ activeClassRow: "" }, () => {
                  this.props.leftSelectedRow(blankRow);
                });
              }
            }
          }
        } else {
          for (let x = 0; x < newTableData.length; x++) {
            if (newTableData[x].action === false) {
              this.setState(
                {
                  activeClassRow: newTableData[x].uniqueno,
                },
                () => {
                  this.props.leftSelectedRow(newTableData[x]);
                }
              );
              break;
            } else {
              let blankRow: any = {};
              blankRow.name = "";
              this.setState({ activeClassRow: "" }, () => {
                this.props.leftSelectedRow(blankRow);
              });
            }
          }
        }
      }
    }
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      if (!row.action) {
        this.setState({ activeClassRow: row.uniqueno }, () => {
          this.props.leftSelectedRow(row);
        });
      }
    },
  };

  render() {
    const rowClasses = (row: any, rowIndex: number) => {
      if (row.uniqueno === Number(this.state.activeClassRow)) {
        return "active-row";
      } else if (row.action) {
        return "faded-row";
      } else {
        return;
      }
    };

    const columns = [
      {
        dataField: "employee",
        text: "",
        sort: true,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdEmpDetails">
            <EllipsisWithTooltip placement="bottom">
              <span className="smileIdEmpName">{row.employee}</span>
            </EllipsisWithTooltip>
            <EllipsisWithTooltip placement="bottom">
              <span className="smileIdEmpDesignation">{row.position}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "confidencelevel",
        text: "",
        sort: true,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="smileIdMatchPerDetails">
            <span className="smileIdMatchPerNum">{row.confidencelevel}</span>
            <span className="smileIdMatchPerLabel">Match %</span>
          </div>
        ),
      },
      {
        dataField: "action",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => {
          if (row.action) {
            
            return (
              <div className="smile-page-undo-button">
                <Button
                  className={
                    row.undoButtonSpinner
                      ? "row-UndoButton btn-outline-primary float-right p-none"
                      : "row-UndoButton btn-outline-primary float-right"
                  }
                  // className="row-UndoButton btn-outline-primary float-right"
                  onClick={(e: any) => this.undoButtonClick(e, row)}
                >
                  {row.undoButtonSpinner && (
                    <Spinner
                      className="mr-2"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Undo
                </Button>
              </div>
            );
          } else {
            return <div className="smile-page-undo-button"></div>;
          }
        },
      },
      {
        dataField: "position",
        text: "",
        hidden: true,
      },
    ];

    return (
      <div className="smileid-left-panel">
        <ToolkitProvider
          keyField="uniqueno"
          data={this.state.tableData}
          columns={columns}
          rowEvents={this.rowEvents}
          search={{
            afterSearch: (newResult: any) => {
              this.searchedData(newResult);
            },
          }}
        >
          {(props: {
            searchProps: JSX.IntrinsicAttributes;
            baseProps: JSX.IntrinsicAttributes;
          }) => (
            <div>
              <div className="search-header d-flex">
                {/* <SearchBar
                  {...props.searchProps}
                  placeholder={"Searchhhh..."}
                  ref={this.searchBar}
                /> */}
                <TableSearch id="tableSearch" key="tableSearch"
                  {...props.searchProps}
                  placeholder={"Search..."}
                  ref={this.searchBar}
                />

                <Dropdown className="more-action bg-blue">
                  <Dropdown.Toggle id="dropdown-ptoType">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
                    </svg>
                    {this.state.selectedFilter}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Header>SORT</Dropdown.Header>
                    {this.state.filterList.map((item: any, idx: any) => (
                      <Dropdown.Item
                        key={idx}
                        className={
                          this.state.selectedFilter === item.value
                            ? "active"
                            : ""
                        }
                        eventKey={item.value}
                        onClick={() => this.handleFilterChange(item.value)}
                      >
                        <div className="d-flex">
                          <div className="mr-auto"> {item.value}</div>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                              fill="#2CAF92"
                            />
                          </svg>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="left-body">
                <BootstrapTable
                  {...props.baseProps}
                  keyField="uniqueno"
                  rowEvents={this.rowEvents}
                  hover
                  condensed
                  rowClasses={rowClasses}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
