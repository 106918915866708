import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const LaborManagement: React.FC = () => {
    return (
        <div className="wht-bg shadow-bg">
            <Container fluid>
                <Row className="screen-action">
                    <Col>
                        <div className="screen-heading flt-left">Labor Management Default Page</div>
                        <div className="flt-left"><Link to="/labor-management/page-two">Click Here For Page Two</Link></div>
                    </Col>
                </Row>
            </Container> 
        </div>         
    );
}