
import axios from "axios";
import { IFacilityMaintenanceListRequest,IGetProceureDetailsRequestDto } from "../Contracts/IMaintenanceSchedule";
import Instense from "./Axios-config";

export class FacilityMaintenance {

  private static  ajaxRequestMaintenance: any = null; 
  public static GetAllMaintenanceSchedule = async (request:IFacilityMaintenanceListRequest ): Promise<any[] | null> => {
    if (FacilityMaintenance.ajaxRequestMaintenance ) {
      FacilityMaintenance.ajaxRequestMaintenance.cancel("Cancel"); 
  }
  FacilityMaintenance.ajaxRequestMaintenance = axios.CancelToken.source();
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
     request.tenantId =tenantID;
    const url = `/FacilityMaintenance/GetAllMaintenanceSchedule`;
    return Instense.post(url,  request, {
      cancelToken: FacilityMaintenance.ajaxRequestMaintenance.token
     }).then((response) => {
      let result = response?.data?.result as any[];
      return result;
    });
  };

  
  public static GetFrequency = async (): Promise<any[] | null> => {
    const url = `/FacilityMaintenance/GetFrequency`;
    return Instense.get(url  ).then((response) => {
      let result = response?.data?.result as any[] || [];
      return result;
    });
  };

  public static GetPriority = async (): Promise<any[] | null> => {
    const url = `/FacilityMaintenance/GetPriority`;
    return Instense.get(url  ).then((response) => {
      let result = response?.data?.result as any[] || [];
      return result;
    });
  };

  
  public static GetStatusFacilityMaintenanceSchedule = async (): Promise<any[] | null> => {
    const url = `/FacilityMaintenance/GetStatusFacilityMaintenanceSchedule`;
    return Instense.get(url  ).then((response) => {
      let result = response?.data?.result as any[] || [];
      return result;
    });
  };
  
  
    
  public static DeleteMaintenanceSchedule = async (id:number): Promise<any[] | null> => {
    const url = `/FacilityMaintenance/DeleteMaintenanceSchedule`;
    return Instense.delete(url,{params: {id}}).then((response) => {
      let result = response?.data as any[];
      return result;
    });
  };
  
  public static UpdateScheduleStatus = async (scheduleIds:number[], status: string): Promise<any[] | null> => {
    const url = `/FacilityMaintenance/UpdateScheduleStatus`;
    return Instense.post(url,scheduleIds,{params: {status}}).then((response) => {
      let result = response?.data as any[];
      return result;
    });
  };

   
  public static ArchiveMaintenanceSchedules = async (scheduleIds:number[]): Promise<any[] | null> => {
    const url = `/FacilityMaintenance/ArchiveMaintenanceSchedules`;
    return Instense.post(url,scheduleIds).then((response) => {
      let result = response?.data as any[];
      return result;
    });
  };

  public static GetMSProceureDetails = async (
    request: IGetProceureDetailsRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/FacilityMaintenance/GetMSProceureDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };

  public static GetMSAllProceure = async (
    request: IGetProceureDetailsRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/FacilityMaintenance/GetMSAllProceure`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };

  public static GetAllMLocationDetails = async (
    request: IGetProceureDetailsRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/FacilityMaintenance/GetMSAllLocation`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };

  public static GetMLocationDetails = async (
    request: IGetProceureDetailsRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/FacilityMaintenance/GetMSLocationDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };

  public static GetPMAllProceureList = async (
    request: IGetProceureDetailsRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/FacilityMaintenance/GetPMAllProceureList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };
  
  public static SaveMaintenance = async (
    request: any
  ): Promise<any | null> => {
    // let storage = JSON.parse(localStorage.getItem("storage")!);
    // let tenantID = storage === null ? 0 : storage.tenantID as any;
    // request.tenantID = tenantID;
      const url = `/FacilityMaintenance/SaveMaintenanceSchedule`;
      return Instense.post(url, request).then((response) => {
        let result = response.data.result as any;
        return result;
      });
    
  };

  public static GetProcedureTaskList = async (
    request: IGetProceureDetailsRequestDto
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const url = `/FacilityMaintenance/GetProcedureTaskList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any[];
      return result;
    });
  };

  public static GetPreventativeMaintenanceById = async (CycleId): Promise<any | null> => {
    const url = `/FacilityMaintenance/GetPreventativeMaintenanceById`;
    return Instense.get(url, { params: {CycleId} }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
}
