import { Dashboard } from "./Components/Dashboard";
import { Home } from "../Modules/Home/Home";
import { HomeConfiguration } from "../Modules/Home/HomeConfiguration";
import { Archive } from "../Modules/Home/Archive";
import { LaborManagement } from "../Modules/LaborManagement/LaborManagement";
import AccountManagement from "../Modules/LaborManagement/AcctManagement";
import UserManagement from "../Modules/LaborManagement/UserManagement";
import { TimeClockSetting } from "../Modules/LaborManagement/Administration/TimeClockSetting";
import { ResetPassword } from "../Modules/MySetting/ResetPassword";
import { BudgetSettings } from "../Modules/Settings/BudgetSetting/BudgetSettings";
import { PayrollProcessorIndex } from "../Modules/Settings/PayrollProcessor/PayrollProcessorIndex";
import { Forecast } from "../Modules/LaborManagement/Forecast";
import EnterpriseWiseLaborSettings from "../Modules/LaborManagement/EnterpriseWiseLaborSettings";
import { PTOSetting } from "../Modules/LaborManagement/PTOSetting";
import { DepartmentPosition } from "../Modules/LaborManagement/DepartmentPosition";
import { Holidays } from "../Modules/LaborManagement/Holidays";
import { ReportSelection } from "../Modules/Reports/ReportSelection";
import { ReportParameterSelection } from "../Modules/Reports/ReportParameterSelection";
import { ReportViewer } from "../Modules/Reports/ReportViewer";
import { LaborDayOverviews } from "../Modules/LaborManagement/Labor/Overview/LaborDayOverviews";
import { TipEntry } from "../Modules/LaborManagement/Labor/TipEntry/TipEntry";
import { ScheduleMain } from "../Modules/LaborManagement/Labor/Schedule/ScheduleMain";
import { PayrollExportIndex } from "../Modules/LaborManagement/Labor/Payroll Export/PayrollExportIndex";
import { PerformancePositionDetail } from "../Modules/LaborManagement/Labor/Performance/PerformancePositionDetail";
import { Performance } from "../Modules/LaborManagement/Labor/Performance/Performance";
import { LaborTimesheet } from "../Modules/LaborManagement/TimeSheet/LaborTimesheet";
import { LaborTimesheetTest } from "../Modules/LaborManagement/TimeSheet/LaborTimesheetTest";
import { SmileIDPunchEvaluates } from "../Modules/LaborManagement/Labor/SmileId/SmileIdPunchEvaluate";
import { SmileIDPunches } from "../Modules/LaborManagement/Labor/SmileId/SmileIDPunches";
import { TimeOffRequests } from "../Modules/LaborManagement/TimeOffRequest";
import { RolePermission } from "../Modules/LaborManagement/RolePermission/RolePermission";
import { ReportViewerRolePermission } from "../Modules/LaborManagement/RolePermission/RolePermissionReportViewer";
import { ReportViewerPayRegister } from "../Modules/Accounting/Payments/ReportViewerPayRegister";
import { LaborTimesheetDetailUserView } from "../Modules/LaborManagement/TimeSheetUserView/LaborTimesheetDetailUserView";
import { MySchedule } from "../Modules/MySetting/MySchedule";
import { DepartmentandPosition } from "../Modules/LaborManagement/DepartmentAndPositions";
import { LaborDepartmentPosition } from "../Modules/LaborManagement/LaborDepartmentPosition";
import { ReportRunner } from "../Modules/Reports/ReportRunner";
import { UnauthorizeAccess } from "../Common/Components/UnauthorizeAccess";
import { MyPTO } from "../Modules/MySetting/MyPTO";
import { UnApprove } from "../Modules/LaborManagement/Labor/unapprove";
import { HousekeepingList } from "../Modules/LaborManagement/Labor/HouseKeeping/HouseKeepingList";
import { DayApproveOverviewReport } from "../Modules/LaborManagement/Labor/Overview/DayApproveOverview/DayApproveOverviewReport";
import { PerformanceMain } from "../Modules/LaborManagement/Labor/PerformanceViews/PerformanceMain";
import { DailyActivityIndex } from "../Modules/LaborManagement/Labor/DailyActivity/DailyActivityIndex";
import { Register } from "../Modules/Register/Register";
import { DraftTransaction } from "../Modules/Register/DraftTransaction";
import { Journal } from "../Modules/Accounting/JournalEntry/Journal";
import { account } from "../Modules/Accounting/Accounts";
import { accountNew } from "../Modules/Accounting/AccountsNew";
import { ReconcileStatus } from "../Modules/Accounting/Reconcile/ReconcileStatus";
import { TransactionSlideout } from "../Modules/Register/TransactionSlideout";
import { InvoiceEntry } from "../Modules/Register/InvoiceEntry";
import { InvoiceInbox } from "../Modules/Register/InvoiceInbox";
import { DataImportRule } from "../Modules/Accounting/ConditionalRuleSetting/DataImportRule";
import { AccountingOverview } from "../Modules/Accounting/Overview/AccountingOverView";
import { payrollImport } from "../Modules/Accounting/PayrollImport/payrollImport";
import { Repeating } from "../Modules/Accounting/Repeating Entries/Repeating";
import { EntityGroupMaster } from "../Modules/Settings/EntityManagement/EntityGroupMaster";
import { Users } from "./../Modules/UserManagement/Users";
import { Component } from "react";
import { UnbudgetedHoursReportViewer } from "../Modules/Analytics/UnbudgetedHoursReportViewer";
import { InvoiceApprovalSetting } from "../Modules/Accounting/InvoiceApprovalSetting/InvoiceApprovalSetting";
import { InvoiceApproval } from "../Modules/Accounting/InvoiceApproval/invoiceapproval";
import { MediaViewer } from "../Modules/Register/MediaViewer";
import { DetailsReportViwer } from "../Modules/Reports/DetailsReportViwer";
import { Payments } from "../Modules/Accounting/Payments/Payments";
import { Search } from "../Modules/Accounting/Search";
import { AdditionalPayrollImports } from "../Modules/Accounting/PayrollImport/AdditionalPayrollImports";
import { Vendor } from "../Modules/Accounting/Vendor/Vendor";
import { AlertPreferences } from "../Modules/AlertPreferences/AlertPreferences";
import { PlaidTransactionLog } from "../Modules/Accounting/PlaidTransactionLog";
import { BankSync } from "../Modules/Accounting/BankSync/BankSync";
import { ReconcileSummaryPage } from "../Modules/Accounting/Reconcile/ReconcileSummaryPage";
import ImportUser from "../Modules/UserManagement/components/ImportUser";
import { documentViewer } from "../Modules/Register/documentViewer";
import { LaborDashboard } from "../Modules/Analytics/LaborDashboard";
import PayrollDeductionSettingsList from "../Modules/LaborManagement/PayrollDeductionSettings/PayrollDeductionSettingsList";
import { EpayOnboarding } from "../Modules/UserManagement/EpayOnboarding";
import { PmsMonitoring } from "../Modules/Settings/EntityManagement/PmsMonitoring";
import { InvoiceExtraction } from "../Modules/Register/InvoiceExtraction";
import { FilePmsMonitoring } from "../Modules/Settings/EntityManagement/FilePmsMonitoring";
import { HotelKeyHistorical } from "../Modules/Settings/EntityManagement/HotelKeyHistorical";
import { Assets } from "../Modules/Facilities/Assets";
import { workOrderDetails } from "../Modules/Workorder/workOrderDetails";
import { AdvancedAccounting } from "../Modules/Accounting/AA/AdvancedAccounting";
import { Procedures } from "../Modules/Facilities/Procedures";
import { Locations } from "../Modules/Facilities/Locations";
import { MaintenanceSchedule } from "../Modules/Facilities/MaintenanceSchedule";
import { SalesAccounts } from "../Modules/Sales/SalesAccounts";
import { PreventativeMaintenance } from "../Modules/Facilities/PreventativeMaintenance";
import { AccountingDashboard } from "../Modules/Analytics/AccountingDashboard";
import { HousekeepingDashboard } from "../Modules/Analytics/HouseKeepingDashboard";
import { DetailsReportDrilldown } from "../Modules/Reports/DetailsReportDrilldown";
import { DetailsReportDrilldownMultiCOA } from "../Modules/Reports/DetailsReportDrilldownMultiCOA";
import { DailyTaskCreation } from "../Modules/Facilities/DailyTaskCreation";
import { DailyTaskManagement } from "../Modules/Facilities/DailyTaskManagement";
import { SalesAccountDetails } from "../Modules/Sales/SalesAccountDetails";
import { FacilityOverview } from "../Modules/Facilities/Overview/FacilityOverview";
import { PnLReport } from "../Modules/Analytics/InteractiveReports/PnLReport";
import { Reports } from "../Modules/Reports/ConsolidatedReports/Reports";
import { IframeSpike } from "../Modules/Reports/ConsolidatedReports/IframeSpike";
import { PayrollIntegrationSetup } from "../Modules/LaborManagement/Administration/PayrollIntegrationSetup";
import {CommunicationsInbox} from "../Modules/Communications/CommunicationsIndox";
import { APIIntegration } from "../Modules/LaborManagement/Administration/APIIntegration";
import { AzureExtraction } from "../Modules/Spike/AzureExtraction";
import { APIIntegrationNew } from "../Modules/LaborManagement/Administration/APIIntegrationNew";

export const protectedRoutes = [
  {
    path: "/dashboard/accounting",
    name: "Dashboard",
    Component: AccountingDashboard,
  },
  {
    path: "/dashboard/labor",
    name: "LaborDashboard",
    Component: LaborDashboard,
  },
  {
    path: "/dashboard/housekeeping",
    name: "HousekeepingDashboard",
    Component: HousekeepingDashboard,
  },
  {
    path: "/dashboard/Performance",
    name: "PerformanceDashboard",
    Component: PerformanceMain,
  },
  {
    path: "/labor/payroll-deduction-settings",
    name: "PayrollDeductionSettings",
    Component: PayrollDeductionSettingsList,
  },
  {
    path: "/labordashboard/UnbudgetedHours",
    name: "UnbudgetedHours",
    Component: UnbudgetedHoursReportViewer,
  },

  {
    path: "/home",
    name: "Home",
    Component: Home,
  },
  {
    path: "/entities",
    name: "Entity",
    Component: EntityGroupMaster,
  },
  {
    path: "/archive",
    name: "Archive",
    Component: Archive,
  },
  {
    path: "/home-configuration",
    name: "HomeConfiguration",
    Component: HomeConfiguration,
  },
  {
    path: "/labor-management/Reports",
    name: "Reports",
    Component: ReportSelection,
  },
  {
    path: "/Account-management/Overview",
    name: "Overview",
    Component: AccountingOverview,
  },
  {
    path: "/Account-management/Reports",
    name: "Reports",
    Component: ReportSelection,
  },
  {
    path: "/Account-management/DetailsReport",
    name: "DetailsReport",
    Component: DetailsReportViwer,
  },
  {
    path: "/reports/parameter-selection",
    name: "ReportParameterSelection",
    Component: ReportParameterSelection,
  },
  { path: "/reports/viewer", name: "ReportViewer", Component: ReportViewer },
  {
    path: "/reports/run-report",
    name: "Report Runner",
    Component: ReportRunner,
  },
  {
    path: "/labor-management",
    name: "Labor Management",
    Component: LaborManagement,
  },
  {
    path: "/labor-management/forecast",
    name: "Forecast",
    Component: Forecast,
  },
  {
    path: "/labor-management/daily-activity",
    name: "DailyActivity",
    Component: DailyActivityIndex,
  },
  {
    path: "/labor-settings/budget-settings",
    name: "BudgetSettings",
    Component: BudgetSettings,
  },
  {
    path: "/labor-settings/enterprise-wise-labor-settings",
    name: "EnterpriseWiseLaborSettings",
    Component: EnterpriseWiseLaborSettings,
  },
  {
    path: "/labor-settings/time-off",
    name: "Time Off",
    Component: PTOSetting,
  },
  {
    path: "/Account-Management",
    name: "Account Management",
    Component: AccountManagement,
  },
  {
    path: "/User-Management",
    name: "User Management",
    Component: UserManagement,
  },
  {
    path: "/users",
    name: "Users",
    Component: Users,
  },
  {
    path: "/labor-settings/TimeClockSetting",
    name: "TimeClock IpSetting",
    Component: TimeClockSetting,
  },
  {
    path: "/labor-settings/holidays",
    name: "Holidays",
    Component: Holidays,
  },
  {
    path: "/labor-management/unapprove",
    name: "UnApprove",
    Component: UnApprove,
  },
  {
    path: "/labor-settings/payroll-processor",
    name: "PayrollProcessor",
    Component: PayrollProcessorIndex,
  },
  {
    path: "/change-password",
    name: "ResetPassword",
    Component: ResetPassword,
  },
  {
    path: "/labor-management/approval",
    name: "Approval",
    Component: LaborDayOverviews,
  },
  {
    path: "/labor-management/tip-entry",
    name: "Tip Entry",
    Component: TipEntry,
  },
  {
    path: "/labor-management/schedule",
    name: "Schedule",
    Component: ScheduleMain,
  },
  {
    path: "/labor-management/PerformancePositionDetail",
    name: "PerformancePositionDetail",
    Component: PerformancePositionDetail,
  },
  // {
  //   path: "/labor-management/Performance",
  //   name: "Performance",
  //   Component: Performance,
  // },
  {
    path: "/labor-management/Timesheet",
    name: "Timesheet",
    Component: LaborTimesheet,
  },
  {
    path: "/labor-management/TimesheetTest",
    name: "TimesheetTest",
    Component: LaborTimesheetTest,
  },
  {
    path: "/labor-management/TimesheetUserView",
    name: "MyTimesheet",
    Component: LaborTimesheetDetailUserView,
    //Component: LaborTimesheetUserView,
  },
  {
    path: "/labor-management/SmileID",
    name: "SmileID",
    Component: SmileIDPunchEvaluates,
  },
  {
    path: "/labor-management/SmileIDPunches",
    name: "SmileID",
    Component: SmileIDPunches,
  },
  {
    path: "/labor-management/timeoffrequest",
    name: "timeoffrequest",
    Component: TimeOffRequests,
  },
  {
    path: "/labor-management/payroll-export",
    name: "PayrollExport",
    Component: PayrollExportIndex,
  },
  {
    path: "/labor-settings/role-permission",
    name: "RolePermission",
    Component: RolePermission,
  },
  {
    path: "/labor-settings/viewer",
    name: "viewer",
    Component: ReportViewerRolePermission,
  },
  {
    path: "/labor-management/MySchedule",
    name: "MySchedule",
    Component: MySchedule,
  },
  {
    path: "/labor-management/MyPTO",
    name: "MyPTO",
    Component: MyPTO,
  },
  {
    path: "/labor-management/LaborDepartmentPosition",
    name: "LaborDepartmentPosition",
    Component: LaborDepartmentPosition,
  },
  {
    path: "/labor-management/Housekeeping",
    name: "Housekeeping",
    Component: HousekeepingList,
  },
  {
    path: "/unauthorize-access",
    name: "UnauthorizeAccess",
    Component: UnauthorizeAccess,
  },
  {
    path: "/labor-management/viewer",
    name: "viewer",
    Component: DayApproveOverviewReport,
  },
  //Performance New Design
  {
    path: "/labor-management/Performance",
    name: "PerformanceMain",
    Component: PerformanceMain,
  },
  {
    path: "/register",
    name: "Register",
    Component: Register,
  },
  {
    path: "/BankSync",
    name: "BankSync",
    Component: BankSync,
  },
  {
    path: "/Journal",
    name: "Journal",
    Component: Journal,
  },
  {
    path: "/transactionSlideout",
    name: "TransactionSlideout",
    Component: TransactionSlideout,
  },
  {
    path: "/invoiceEntry",
    name: "InvoiceEntry",
    Component: InvoiceEntry,
  },
  {
    path: "/invoiceInbox",
    name: "InvoiceInbox",
    Component: InvoiceInbox,
  },
  {
    path: "/accounting/accounts",
    name: "Accounts",
    Component: account,
  },
  {
    path: "/accounting/accountsNew",
    name: "Accounts",
    Component: accountNew,
  },
  {
    path: "/accounting/reconcileStatus",
    name: "ReconcileStatus",
    Component: ReconcileStatus,
  },
  {
    path: "/dataimportrule",
    name: "DataImportRule",
    Component: DataImportRule,
  },
  {
    path: "/accounting/payrollImport",
    name: "payrollImport",
    Component: payrollImport,
  },
  {
    path: "/repeating",
    name: "repeating",
    Component: Repeating,
  },
  {
    path: "/invoiceapprovals",
    name: "invoiceapprovals",
    Component: InvoiceApprovalSetting,
  },
  {
    path: "/invoiceapproval",
    name: "invoiceapproval",
    Component: InvoiceApproval,
  },
  {
    path: "/MediaViewer",
    name: "MediaViewer",
    Component: MediaViewer,
  },
  {
    path: "/payments",
    name: "payments",
    Component: Payments,
  },
  {
    path: "/search",
    name: "search",
    Component: Search,
  },
  {
    path: "/additionalPayrollImports",
    name: "AdditionalPayrollImports",
    Component: AdditionalPayrollImports,
  },
  {
    path: "/vendors",
    name: "vendors",
    Component: Vendor,
  },
  {
    path: "/alertPreferences",
    name: "alertPreferences",
    Component: AlertPreferences,
  },
  {
    path: "/account-settings/viewer",
    name: "viewer",
    Component: ReportViewerPayRegister,
  },
  {
    path: "/PlaidTransactionLog",
    name: "PlaidTransactionLog",
    Component: PlaidTransactionLog,
  },
  {
    path: "/reconcile",
    name: "reconcile",
    Component: ReconcileSummaryPage,
  },
  {
    path: "/importUser",
    name: "ImportUser",
    Component: ImportUser,
  },
  {
    path: "/documentViewer",
    name: "Document Viewer",
    Component: documentViewer,

  },
  {

    path: "/epay-onboarding",
    name: "epay Onboarding",
    Component: EpayOnboarding,
  },
  {
    path: "/pmsMonitoring",
    name: "PMS Import API Monitoring",
    Component: PmsMonitoring,
  },
  {
    path: "/invoiceExtraction",
    name: "Invoice extraction",
    Component: InvoiceExtraction,
  },
  {
    path: "/fileMonitoring",
    name: "File Based PMS Monitoring",
    Component: FilePmsMonitoring,
  },
  {
    path: "/facilities-management/location",
    name: "Locations",
    Component: Locations,
  },
  {
    path: "/facilities-management/assets",
    name: "Assets",
    Component: Assets,

  },
  {

    path: "/advanced-accounting",
    name: "Advanced Accounting",
    Component: AdvancedAccounting,
  },
  {
    path: "/facilities-management/workOrder",
    name: "workOrderDetails",
    Component: workOrderDetails,
  },
  {
    path: "/hotelKeyHistorical",
    name: "Hotel Key Historical Data",
    Component: HotelKeyHistorical,
  },
  {
    path: "/facilities-management/procedures",
    name: "Procedures",
    Component: Procedures,
  },
  {
    path: "/facilities-management/schedule",
    name: "schedule",
    Component: MaintenanceSchedule,
  },
  {
    path: "/facilities-management/preventative",
    name: "PreventativeMaintenance",
    Component: PreventativeMaintenance,
  },
  {

    path: "/facilities-management/dailytasksettings",
    name: "DailyTaskCreation",
    Component: DailyTaskCreation,
  },
  {
    path: "/facilities-management/dailytask",
    name: "DailyTaskManagement",
    Component: DailyTaskManagement,
  },
  {
    path: "/sales/accounts",
    name: "SalesAccounts",
    Component: SalesAccounts,

  },
  {
    path: "/Report/ReportDrilldown",
    name: "Report Drilldown",
    Component: DetailsReportDrilldown,
  },
  {
    path: "/sales/accountDetails",
    name: "SalesAccountDetails",
    Component: SalesAccountDetails,
  },
  {
    path: "/Report/ReportDrilldownMultiCOA",
    name: "Report Drilldown",
    Component: DetailsReportDrilldownMultiCOA,

  },
  {
    path: "/facilities-management/overview",
    name: "Facility Overview",
    Component: FacilityOverview,

  },
  {
    path: "/analytics/pnl-interactive-report",
    name: "PnL Interactive Report",
    Component: PnLReport,
  },
  {
    path: "/Reports",
    name: "Reports",
    Component: Reports,
  },
  {
    path: "/spike/iframe",
    name: "iframe",
    Component: IframeSpike,
  },
  {
    path: "/PayrollIntegrationSetup",
    name: "PayrollIntegrationSetup",
    Component: PayrollIntegrationSetup,
  },
  {
    path: "/CommunicationsInbox",
    name: "CommunicationsInbox",
    Component: CommunicationsInbox,
  },
  {
    path: "/integrationaccess",
    name: "APIIntegrationNew",
    Component: APIIntegrationNew,
  },
  {
    path: "/spike/azure-extraction",
    name: "iframe",
    Component: AzureExtraction,
  },
  {
    path: "/draft-transaction",
    name: "DraftTransaction",
    Component: DraftTransaction,
  }
];

