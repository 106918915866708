import { AnyObject } from "chart.js/dist/types/basic";
import Instense from "./Axios-config";

export class DataImportRules {
  public static GetDataImportRule = async (RuleID: String): Promise<any | null> => {
    let request = {} as any
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    request.RuleID = RuleID;
    const url = `/Transfer/GetDataImportRule`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetMappingRule = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/Transfer/GetMappingRule`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };
  public static GetMappingRuleField = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/Transfer/GetMappingRuleField`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };
  public static GetCOAList = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/Transfer/GetCOAListDataRule`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };
  public static GetVendorList = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/Transfer/GetVendorList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static DeleteUpdateDataImportRule = async (
    request:any
  ): Promise<any | null> => {

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.TenantID = tenantID;
    const url = `/Transfer/DeleteUpdateDataImportRule`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };
  public static SaveDataImportRule = async (
    request:any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.TenantID = tenantID;
    const url = `/Transfer/SaveDataImportRule`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result;
  };
  public static UpdateDataImportRule = async (
    request:any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;
    request.TenantID = tenantID;
    const url = `/Transfer/UpdateDataImportRule`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };
}
