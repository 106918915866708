import React from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { ISavePTOUserDetailRequest } from "../../../Common/Contracts/ISavePTOUserDetailRequest";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import Calendar from "react-calendar";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { IPTOUserDetails } from "../../../Common/Contracts/IPTOUserDetails";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../../Common/StaticArrays";
import _ from "lodash";

export class AddGlobalSettingsAddress extends React.Component<any, any> {
  private payrollAddressFormSchema = Joi.object({
    nonUS: Joi.boolean().default(false),
    astreet: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Street" };
        });
      }),
    city: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter City" };
        });
      }),
    state: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select State" };
        });
      }),
      province: Joi.string()
      .optional().allow("")
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Province/Region" };
        });
      }),
      

      postalCode: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Zip" };
        });
      }),
    country: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Country" };
        });
      }),
  });

  state: any = {
    prevPayrollAddress: {
      nonUS: this.props?.globalSettings?.nonUS || false,
      astreet: this.props?.globalSettings?.astreet || "",
      city:  this.props?.globalSettings?.city || "",
      state: this.props?.globalSettings?.state || "" ,
      province:  this.props?.globalSettings?.province || "" ,
      postalCode: this.props?.globalSettings?.postalCode || "",
      country: this.props?.globalSettings?.country || "",
    },
    payrollAddress: {
      nonUS: this.props?.globalSettings?.nonUS || false,
      astreet: this.props?.globalSettings?.astreet || "",
      city:  this.props?.globalSettings?.city || "",
      state: this.props?.globalSettings?.state || "" ,
      province:  this.props?.globalSettings?.province || "" ,
      postalCode: this.props?.globalSettings?.postalCode || "",
      country: this.props?.globalSettings?.country || "",
    },

    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    isFormValuesModified: false,
    inputPosition: 0,
  };

  componentDidMount = () => {};

  onFieldChange = (event, inputPosition) => {
    const { payrollAddress } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if(fieldName === "city" || fieldName === "country" || fieldName === "province") {
      value = Utils.AllowOnlyAlphabetsWithRightSpace(value);
    }
    if(fieldName === "nonUS" &&  value) {
      payrollAddress.state = '';
    } else {
      payrollAddress.province ='';
    }
    payrollAddress[fieldName] = typeof value === 'string' ? value?.toString()?.trimLeft() :value;
    this.setState({ payrollAddress, inputPosition }, () => {
      this.validatepayrollAddressFormSchema();
   this.setState({ isFormValuesModified :!_.isEqual(this.state.prevPayrollAddress,this.state.payrollAddress)});
    });
  };

  validatepayrollAddressFormSchema = () => {
    const valid = Joi.validate(
      this.state.payrollAddress,
      this.payrollAddressFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
 

    if(this.state.payrollAddress?.nonUS) {
      if(!this.state.payrollAddress?.province) {
        newErrorObject.province = "Please enter Province/Region";
      } else {
        if(newErrorObject?.province) {
        delete  newErrorObject.province;
        }

      }
      if(newErrorObject?.state) {
      delete  newErrorObject.state;
      }
    } else {
      if(newErrorObject?.province) {
        delete  newErrorObject.province;
        }
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () =>
      this.validatepayrollAddressFormSchema()
    );
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };

  hideModal = () => {
    const payrollAddress: any = {
      nonUS: this.props?.globalSettings?.nonUS || false,
      astreet: this.props?.globalSettings?.astreet || "",
      city:  this.props?.globalSettings?.city || "",
      state: this.props?.globalSettings?.state || "" ,
      province:  this.props?.globalSettings?.province || "" ,
      postalCode: this.props?.globalSettings?.postalCode || "",
      country: this.props?.globalSettings?.country || ""
    };
    this.props?.hidePayrollAddressModal();
    this.setState({ payrollAddress, isFormNotValid: true });
  };

  addAddress = () => {
    var abbreviation = StaticArrays.usaStatesWithFullName.find(x=>x.name === this.state?.payrollAddress?.state)?.abbreviation || "";
     this.props?.reAttachHotelAddressToMainObject(this.state?.payrollAddress, abbreviation);
    this.hideModal();
  };

  render() {
    const {
      payrollAddress: { nonUS, astreet, city, state,province, postalCode, country },
      errors,
      isDataSaving,
      isFormNotValid,
      isFormValuesModified,
      inputPosition,
    } = this.state;
    const { showPayrollAddressModal } = this.props;
    return (
      <>
        <div className="">
          <ToastContainer
            containerId={"payrollAddressModal"}
            autoClose={3000}
          />

          <Modal
            className="add-address-modal"
            show={showPayrollAddressModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Form.Group controlId="nonUS">
                    <Col
                      sm={"12"}
                      className={`mb-3 ${
                        errors?.nonUS && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      onClick={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Non US</Form.Label>
                      <Form.Check
                        type="switch"
                        id="nonUS"
                        label=""
                        tabIndex = {1000}
                        disabled={false}
                        name="nonUS"
                        checked={nonUS}
                        onChange={(event: any) => {
                          const value = event.target.checked;
                          this.onFieldChange(
                            Utils.BuildCustomEventObject("nonUS", value),
                            1
                          );
                        }}
                      />
                      {this.getFieldError("nonUS", 1)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="street">
                    <Col
                      sm={"12"}
                      className={`mb-3 ${
                        errors?.astreet && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      onClick={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Street</Form.Label>
                      <input
                        tabIndex = {1041}
                        type="text"
                        className="form-control"
                        id="txtStreet"
                        name="astreet"
                        autoComplete="txtStreet"
                        value={astreet}
                        onChange={(e) => this.onFieldChange(e, 1)}
                      />
                      {this.getFieldError("astreet", 1)}
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="city">
                    <Col
                      sm={"12"}
                      className={`mb-3 ${
                        errors?.city && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}
                      onClick={() => this.validationOnClick(2)}
                    >
                      <Form.Label>City</Form.Label>
                      <input
                        tabIndex = {1042}
                        type="text"
                        className="form-control"
                        id="txtCity"
                        name="city"
                        autoComplete="txtCity"
                        value={city}
                        onChange={(e) => this.onFieldChange(e, 2)}
                      />
                      {this.getFieldError("city", 2)}
                    </Col>
                  </Form.Group>
                  {nonUS && (
                    <Form.Group controlId="province">
                      <Col
                        sm={"12"}
                        className={`mb-3 ${
                          errors?.province && inputPosition >= 3
                            ? "validation-error"
                            : ""
                        }`}
                        onClick={() => this.validationOnClick(3)}
                      >
                        <Form.Label>Province/Region</Form.Label>
                        <input
                        tabIndex = {1043}
                          type="text"
                          className="form-control"
                          id="province"
                          name="province"
                          autoComplete="province"
                          maxLength={100}
                          value={province}
                          onChange={(e) => this.onFieldChange(e, 3)}
                        />
                        {this.getFieldError("province", 3)}
                      </Col>
                    </Form.Group>
                  )}

                  {!nonUS && (
                    <Form.Group controlId="state">
                      <Col
                        sm={"12"}
                        className={`mb-3 ${
                          errors?.state && inputPosition >= 3
                            ? "validation-error"
                            : ""
                        }`}
                        onClick={() => this.validationOnClick(3)}
                      >
                        <Form.Label>State</Form.Label>
                        <DropDownList
                        tabIndex = {1044}
                          placeHolder={"Select State"}
                          data={StaticArrays.usaStatesWithFullName}
                          defaultValue={state}
                          isSearchRequired={true}
                          label={"name"}
                          value={"name"}
                          // disabled={
                          //   !userGeneralInfo?.hasEditContactInfo &&
                          //   !isNewUserAdded
                          // }
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("state", item.name),
                              3
                            );
                          }}
                          selectedItem={
                            [
                              ...[{ value: "", label: "Select State" }],
                              ...StaticArrays.usaStatesWithFullName,
                            ].find((r: any) => r?.name === state) || {
                              value: "",
                              label: "Select State",
                            }
                          }
                        />
                        {this.getFieldError("state", 3)}
                      </Col>
                    </Form.Group>
                  )}
                  <Form.Group controlId="country">
                    <Col
                      sm={"12"}
                      onBlur={() => this.validationOnClick(4)}
                      className={
                        errors?.country && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Country</Form.Label>
                      <input
                        tabIndex = {1045}
                        type="text"
                        className="form-control"
                        id="txtCountry"
                        name="country"
                        autoComplete="txtCountry"
                        value={country}
                        onChange={(e) => this.onFieldChange(e, 4)}
                      />
                      {this.getFieldError("country", 4)}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="postalCode">
                    <Col
                      sm={"12"}
                      onBlur={() => this.validationOnClick(6)}
                      className={
                        errors?.postalCode && inputPosition >= 6
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Zip/Postal Code</Form.Label>
                      <input
                        tabIndex = {1046}
                        type="text"
                        className="form-control"
                        id="postalCode"
                        name="postalCode"
                        autoComplete="postalCode"
                        value={postalCode}
                        onChange={(e) => this.onFieldChange(e, 6)}
                      />
                      {this.getFieldError("postalCode", 6)}
                    </Col>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-ghost" onClick={this.hideModal}>
                Cancel
              </Button>
              <Button
                tabIndex={1047}
                disabled={ !isFormValuesModified || (isFormNotValid || isDataSaving)}
                className="btn btn-primary"
                onClick={this.addAddress}
              >
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
