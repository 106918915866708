import * as React from "react";
import "@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min";
import "@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.min.css";
import "@boldreports/react-reporting-components/Scripts/bold.reports.react.min";
import { IRolePermissionReport } from "../../../../../Common/Contracts/IRolePermission";
import { API_ROOT } from "../../../../../Common/Services/Api-config";
import { RoleSubPermissionSetting } from "../../../../../Common/Services/RoleSubPermissionSetting";
import { Redirect } from "react-router-dom";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
declare let ej: any;
declare let BoldReportViewerComponent: any;

export const DayApproveOverviewReport: React.FC = (props: any) => {
  const [reportParameters, setReportParameters] = React.useState<
    IRolePermissionReport[]
  >([]);

  const [isReportViewPermission, isSetReportViewPermission] = React.useState(
    false
  );
  const [isPagePermission, isSetPagePermission] = React.useState(false);

  let parameterSettings = {
    hideParameterBlock: true,
  };

  function onReportPrint(event) {
    event.isStyleLoad = false;
  }

 // eslint-disable-next-line react-hooks/exhaustive-deps
 function getURLParamsValues () {
   ;
    const paramObject: any = {
      date: getParamValueByParamName("date"),
      hotelId: getParamValueByParamName("hotelId"),
     // departmentId: getParamValueByParamName("departmentId"),
     // positionId: getParamValueByParamName("positionId")
    };
    
    return paramObject;
    
  }

function  getParamValueByParamName(paramName) {
    ;
    const valuesArray = decodeURIComponent(props.history.location.search)?.replace('?', '')?.split('&');
    const item = valuesArray?.find(r => r?.includes(paramName));
    return item?.split('=')[1];
  }


  // function getReportPermission() {
  ;
    
    
  //   let requestObject = {} as any;
  //   requestObject.permissionID = 28;
  //   requestObject.Module = "Accounting";
  //   requestObject.Type = "page";
  //   LaborDayApprove.GetDaliyPunchReport(requestObject)

  //     .then(async (result: any | null) => {
  //       if (result != null && result.length > 0) {
  //         let isValid=0;
  //         for (let index = 0; index < result.length; index++) {
  //           if(result[index].permissionID===2164){
               
  //             isSetReportViewPermission(true);
  //             isSetPagePermission(true);
  //             break;
  //           }
  //           else{
  //             isSetPagePermission(false);
  //             isSetReportViewPermission(false);
  //           }
  //           isValid++;
  //         }
  //         if(result.length===isValid){
  //           isSetPagePermission(true);
  //         }

  //         // result.forEach((element: any) => {
  //         //   switch (element.permissionID) {
  //         //     case 2164:
  //         //       isSetReportViewPermission(false);
  //         //       break;
  //         //   }
  //         // });
  //       }
  //     })
  //     .catch((err: any) => {});
  // }

  var exportSettings = {
    exportOptions:
      ej.ReportViewer.ExportOptions.All &
      ~ej.ReportViewer.ExportOptions.CSV &
      ~ej.ReportViewer.ExportOptions.Word &
      ~ej.ReportViewer.ExportOptions.XML &
      ~ej.ReportViewer.ExportOptions.Html &
      ~ej.ReportViewer.ExportOptions.PPT 
  };

  var toolbarSettings = {
    showToolbar: true,
    items:
      ~ej.ReportViewer.ToolbarItems.FittoPage &
      ~ej.ReportViewer.ToolbarItems.Parameters &
      ~ej.ReportViewer.ToolbarItems.Refresh &
      ~ej.ReportViewer.ToolbarItems.PageSetup
  };
  React.useEffect(() => {
    ;
    const paramObject: any = getURLParamsValues ();
    let Date=paramObject.date;
    let Hotleid=paramObject.hotelId;

    if (!reportParameters || reportParameters.length === 0) {
      let reportData = {
        name: "tenantId",
        value: JSON.parse(localStorage.storage).tenantID,
      };
      let reportData2 = {
        name: "reportId",
        value: "54",
      };
      
      let reportData3 = {
        name: "Hotelid",
        value: Hotleid,
      };

      let reportData4 = {
        name: "StartDate",
        value: Date,
      };
      
     // getReportPermission();
      setReportParameters([reportData, reportData2,reportData3,reportData4]);
    }
  }, [getURLParamsValues, reportParameters]);

  if (reportParameters && reportParameters.length > 0) {
    return (
      <div id="reportviewer">
        <BoldReportViewerComponent
          id="reportviewer-container"
           reportServiceUrl={
          `${API_ROOT.reportingHost}/reportviewer`
          }
          reportPath={JSON.stringify(reportParameters)}
          isResponsive={true}
          parameterSettings={parameterSettings}
          reportPrint={onReportPrint}
          exportSettings={exportSettings}
          toolbarSettings={toolbarSettings}
          // exportProgressChanged = {onExportProgressChanged}
          // exportItemClick ={onexportItemClick}
          // ajaxSuccess = {onAjaxSuccess}
          // ajaxError = { onAjaxFailed}
          // reportError = { onAjaxFailed}
          // toolBarItemClick = {ontoolBarItemClick}
        ></BoldReportViewerComponent>
      </div>
    );
  } else {
    return <div id="reportviewer"></div>;
  }
//   //if (isPagePermission) {
//     if (
//       reportParameters &&
//       reportParameters.length > 0 //&&
//      // isReportViewPermission
//     ) {
//       return (
//         <div id="rolepermissionreportviewer">
//           {/* <BoldReportViewerComponent
//             id="reportviewer-container"
//             reportServiceUrl={`${API_ROOT.reportingHost}/reportviewer`}
//             reportPath={JSON.stringify(reportParameters)}
//             isResponsive={true}
//             parameterSettings={parameterSettings}
//             reportPrint={onReportPrint}
//             exportSettings={exportSettings}
//           ></BoldReportViewerComponent> */}

// <BoldReportViewerComponent
//           id="reportviewer-container"
//            reportServiceUrl={
//           `${API_ROOT.reportingHost}/reportviewer`
//           }
//           reportPath={JSON.stringify(reportParameters)}
//           isResponsive={true}
//           parameterSettings={parameterSettings}
//           reportPrint={onReportPrint}
//           exportSettings={exportSettings}
//           toolbarSettings={toolbarSettings}
         
//         ></BoldReportViewerComponent>
//         </div>
//       );
    // } else {
    //   return <Redirect to="/unauthorize-access" />;
    // }
  // } else {
  //   return <div></div>;
  // }
};
