import React from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import Calendar from "react-calendar";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
//import { IUserDocTypeResponse } from "../../../Common/Contracts/IUserDocTypeResponse";
// import { IUserDocumentRequest } from "../../../Common/Contracts/IUserDocumentRequest";
// import { AdDocumentType } from "./AdDocumentType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
export class AddGroup extends React.Component<any, any> {
  state: any = {
    groupName: "",
    calenderShow: false,
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    isDocTypesLoaded: false,
    docTypes: [],
    showDocTypeModal: false,
    typeId: "",
    typeName: "",
    docEntryType: "New",
    isEditButton: false,
  };

  componentDidMount = () => { };

  checkIfGroupNameAlreadyExsit = (value) => {
    const groupNames =
      [].concat
        .apply(
          [],
          (this.props?.entityGroupsData || [])?.map(
            (x) => x?.groupWithHotels || []
          )
        )
        ?.map((l: any) => l?.groupName) || [];
    return (
      groupNames?.filter(
        (x) => x?.toString()?.toLowerCase() === value?.toString()?.toLowerCase()
      )?.length > 0
    );
  };

  addGroup = () => {
    if (this.checkIfGroupNameAlreadyExsit(this.state.groupName)) {
      Utils.toastError("Please enter different group name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "addDocument",
      });
      return;
    }

    this.props.addGroup(this.state.groupName);
    this.setState({ groupName: "" }, () => {
      $("#ddlAddPTOType").click();
    });
  };

  onGroupNameChange = (e) => {
    let value = e?.target?.value?.toString()?.trimStart();

    if (value?.toString().length >= 100) {
      return;
    }

    value = Utils.AllowOnlyAlphaNumricWithRightSpace(value);
    value =typeof value === 'string' ? value?.toString()?.trimLeft() :value;
    if (value) {
      value = Utils.CapitalizeFirstLetter(value);
    }

    this.setState({ groupName: value });
  };

  reset = () => {
    this.setState({
      groupName: "",
      calenderShow: false,
      errors: {},
      isDataSaving: false,
      isFormNotValid: true,
      inputPosition: 0,
      isDocTypesLoaded: false,
      docTypes: [],
      showDocTypeModal: false,
      typeId: "",
      typeName: "",
      docEntryType: "New",
      isEditButton: false,
    });

    $('#addGroupBtn').click();
  };
  componentWillUnmount = () => {
    // setTimeout(() => {
    //   toast.dismiss();
    // }, 1000);
  };

  openModal = () => {
    this.reset();
  };
  render() {
    const {
      errors,
      docTypes,
      inputPosition,
      isFormNotValid,
      isDataSaving,
      showDocTypeModal,
      docEntryType,
      typeId,
      typeName,
      isEditButton,
      groupName,
    } = this.state;
    const { heading } = this.props;

    return (
      <div className="add-group-wrapper">
        <ToastContainer containerId={"addPTOType"} autoClose={3000} />

        <Dropdown className="more-action add-group-drp">
          <Dropdown.Toggle
            className="btn-outline-primary inner-sec-btn more"
            id="ddlAddPTOType"
          >
            <div id="addGroupBtn" onClick={(e: any) => this.openModal()}>Add Group</div>
          </Dropdown.Toggle>
          <Dropdown.Menu>

            <div className="cross-btn" onClick={() => this.openModal()} >
              <div className="pos-name" onClick={() => this.openModal()}  >
                <svg
                  onClick={() => this.openModal()}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z"
                    fill="#84888C"
                  />
                </svg>
              </div>
            </div>

            <div className="re-assign-modal">
              <div className="">
                {/* <div className="title">Add</div> */}
                <div className="entry-body">
                  <Form.Group as={Row} controlId="time-picker">
                    <Col
                      sm="12"
                      className={
                        errors?.ptoStartDate && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Name</Form.Label>
                      <div className="validate-input d-flex flex-column">
                        <input
                          type="text"
                          placeholder=""
                          value={groupName}
                          className="form-control "
                          name="groupName"
                          maxLength={100}
                          autoComplete="groupName"
                          onChange={(e) => this.onGroupNameChange(e)}
                        />
                      </div>
                    </Col>
                  </Form.Group>
                </div>
                <div className="action-link">
                  <Button
                    disabled={!groupName}
                    onClick={this.addGroup}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
