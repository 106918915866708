import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Tab,
  Tabs,
  Row,
  Nav,
  FormGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button
} from "react-bootstrap";
import { Subscriptions } from "./Subscriptions";
import { Rooms } from "./Rooms";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { GlobalSettings } from "./GlobalSettings";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { InfoTooltip } from "../../../Common/Components/InfoTooltip";
import { DigitalAchTab } from "./DigitalAchTab";
import { confirmAlert } from "react-confirm-alert";
import { FaPencilAlt, FaCopy } from "react-icons/fa";
import { RoomsOld } from "./RoomsOld";
import { Utils } from "../../../Common/Utilis";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../../Common/StaticArrays";

export class EntityManagementSlideOut extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: this.props.activeTab ? this.props.activeTab : "globalsetting",
      isHotelDetailsLoaded: false,
      hotelDetails: {},
      isReload: false,
      isCopied: false,
      hasFullSub: false,
      isSpecialPermissionUser: false,
      hasEditPermission: true,
      hasEditRoomType: true,
      hasEditRooms: true,
      isRoomsTabChangesNotSaved: false,
      isGeneralTabChangesNotSaved: false,
      controlPosition: 0,
      dualPmsMails: [],
      showAddUserModal: false,
      propertycodeError_1: '',
      userNameError_1: '',
      passwordError_1: '',
      propertycodeError_0: '',
      userNameError_0: '',
      passwordError_0: '',
      password: '',
      confirmPassword: '',
      passwordError: '',
      confirmPasswordError: '',
      hotelKeyAPIDetails: [],
      hotelKeyApiKey: "",
      saveButtonDisabled: true,
      selectedEndpoint: "default",
      selectedPencilIndex: 0
    };
  }

  componentDidMount() {
    this.getHotelModules();
    this.getHotelKeyApiSetup();
    const { fullSubHotelsList, specialUserList } = this.props;
    const hotelId = this.props?.entityRowData?.hotelId;
    let hasFullSub = false;
    let isSpecialPermissionUser = false;


    if (fullSubHotelsList.length > 0) {
      let hasHotel = fullSubHotelsList.find((item) => {
        return Number(item.hotelId) === Number(hotelId);
      });
      if (hasHotel !== undefined) {
        hasFullSub = true;
      }
    }
    const { userName } = JSON.parse(localStorage.getItem("storage")!);
    if (specialUserList.length > 0) {
      let hasManagePermission = specialUserList.find((item) => {
        return item.result.toLowerCase() === userName.toLowerCase();
      });
      debugger;
      if (hasManagePermission !== undefined) {
        isSpecialPermissionUser = true;
      }
    }

    this.setState({ hasFullSub, isSpecialPermissionUser });
  }

  getHotelModules = () => {
    this.setState({ isHotelDetailsLoaded: false });
    EntityManagementService.GetHotelModules(this.props?.entityRowData?.hotelId)
      .then((hotelDetails: any) => {
        debugger;
        let hasEditPermission = hotelDetails?.hasEditPermission || false;
        let hasEditRoomType = hotelDetails?.hasEditRoomType || false;
        let hasEditRooms = hotelDetails?.hasEditRooms || false;

        const hotelModules = [...hotelDetails?.hotelModules];
        //hasEditPermission= hotelDetails?.


        if (hotelDetails?.dualbrandsetup?.pmsName !== null) {
          let emails = hotelDetails.pmsMailBox.split(",");
          this.setState({ dualPmsMails: emails });
        }


        if (hotelModules?.length > 0) {
          hotelDetails.hotelModules = hotelModules.map((item) => {
            item.startDate = item.startDate
              ? moment(item.startDate).format("MM/DD/YY")
              : item.startDate;
            item.terminationDate = item.terminationDate
              ? moment(item.terminationDate).format("MM/DD/YY")
              : item.terminationDate;
            return item;
          });
        }
        this.setState({ hotelDetails, hasEditPermission, hasEditRoomType, hasEditRooms }, () =>
          this.setState({ isHotelDetailsLoaded: true })
        );
      })
      .catch(() => {
        this.setState({ isHotelDetailsLoaded: true });
      });
  };

  getHotelKeyApiSetup = () => {
    EntityManagementService.GetHotelkeyAPISetup(this.props?.entityRowData?.hotelId)
      .then((result: any) => {

        // Check if hotelKeyAPIDetails has non-null or non-empty propertycode values
        result.map((items) => {
          //storing copy of old property code for reference
          items["oldPropertyCode"] = items.propertycode;
          items["oldUserName"] = items.userName;
          if (items.password !== null && items.password !== "") {
            items["isUpdate"] = true;
          } else {
            items["isUpdate"] = false;
          }
        });
        let endpoint = result[0]?.endPoint === null ? "default" : result[0]?.endPoint;
        this.setState({ hotelKeyAPIDetails: result, selectedEndpoint: endpoint }, () => this.getHotelAPIKey());
      })
      .catch((error) => {
        this.setState({ hotelKeyAPIDetails: [] }, () => {
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        });
      });
  }

  getHotelAPIKey = () => {
    EntityManagementService.GetHotelAPI(this.props?.entityRowData?.hotelId)
      .then((result: any) => {
        this.setState({ hotelKeyApiKey: result.message });
      })
      .catch((error) => {
        this.setState({ hotelKeyApiKey: "" }, () => {
          Utils.toastError(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        });
      });
  }

  saveHotelkeyAPISetupDetails = () => {
    const { hotelKeyApiKey, hotelKeyAPIDetails, password, selectedPencilIndex, selectedEndpoint } = this.state;
    const request: any = {};
    request.IF_Recordid = 0;
    request.Hid = this.props?.entityRowData?.hotelId;
    request.APIKey = hotelKeyApiKey;
    request.UserName = hotelKeyAPIDetails[selectedPencilIndex].userName;
    request.Password = password;
    request.property_code = hotelKeyAPIDetails[selectedPencilIndex].propertycode;
    request.isdual = hotelKeyAPIDetails[selectedPencilIndex].isdual;
    request.EndPoint = selectedEndpoint === "default" ? null : selectedEndpoint;

    EntityManagementService.SaveHotelkeyAPISetup(request)
      .then((result) => {
        if (result?.success) {
          toast.success("Property Details saved successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });

          //storing copy of old property code for reference         
          hotelKeyAPIDetails[selectedPencilIndex] = {
            ...hotelKeyAPIDetails[selectedPencilIndex],
            oldPropertyCode: hotelKeyAPIDetails[selectedPencilIndex].propertycode,
            oldUserName: hotelKeyAPIDetails[selectedPencilIndex].userName
          };

        } else {
          Utils.toastError(result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
        this.hideModal();
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
        this.hideModal();
      });
  }

  closeSlideOut = () => {
    this.props?.handleHideSlideout(this.state.isReload);
  };

  getGeneralTabModificationStatus = (isGeneralTabChangesNotSaved = false) => {
    this.setState({ isGeneralTabChangesNotSaved });
  };

  getRoomsTabModificationStatus = (isRoomsTabChangesNotSaved = false) => {
    this.setState({ isRoomsTabChangesNotSaved });
  };

  closeSlideOutWithConfirmation = () => {
    const { isRoomsTabChangesNotSaved, isGeneralTabChangesNotSaved } =
      this.state;

    if (isRoomsTabChangesNotSaved || isGeneralTabChangesNotSaved) {
      this.confirmationOnSlideoutClose();
      return;
    }
    this.closeSlideOut();
  };

  confirmationOnSlideoutClose = () => {
    confirmAlert({
      title: "Unsaved confirmation?",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.closeSlideOut(),
        },
        {
          label: "No",
          onClick: () => null
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  isReloadRequired = (isReload) => {
    this.setState({ isReload });
  };

  getStatusBadgeclass = (status) => {
    let className = "";
    switch (status) {
      case "Active":
        className = "color-green";
        break;
      case "Inactive":
        className = "color-grey";
        break;
      default:
        className = "color-orange";
        break;
    }
    return className;
  };

  setActiveTab = (selectedTab) => {
    const { isRoomsTabChangesNotSaved, isGeneralTabChangesNotSaved } = this.state;

    if (isRoomsTabChangesNotSaved || isGeneralTabChangesNotSaved) {
      this.confirmationOnTabChange(selectedTab);
      return;

    }


    this.setState({ activeTab: selectedTab });
  };

  setTabSelection = (selectedTab) => {
    this.getHotelModules();
    this.setState({
      activeTab: selectedTab,
      isRoomsTabChangesNotSaved: false,
      isGeneralTabChangesNotSaved: false
    });
  };


  confirmationOnTabChange = (selectedTab) => {
    confirmAlert({
      title: "Unsaved confirmation?",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.setTabSelection(selectedTab),
        },
        {
          label: "No",
          onClick: () => null
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  copyToClipboard = (text, type) => {
    this.setState({ isCopied: true }, () => {
      setTimeout(() => {
        this.setState({ isCopied: false });
      }, 1000);
    });
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    let message = "";
    if (type === "Email Address") {
      message = "Email Address Copied to Your Clipboard!";
    } else {
      message = "API Key Copied to Your Clipboard";
    }
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      containerId: "addGarnishment",
    });
  };

  renderVendorSearchBox = () => {
    if ((this.state.selectedVendor.length + this.state.selectedContract.length) > 20
      && !this.state.isListOpen && !this.state.addVendorFooterVisible)
      return (<OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 200 }}
        overlay={
          <Tooltip id={"ddd"}>
            {this.state.selectedVendor + this.state.selectedContract}
          </Tooltip>
        }>
        <input
          // ref={this.inputSearch}
          id="inputSearch"
          key={"text-Filter"}
          type="text"
          className="form-control searchVendor VendorDisabled"
          onChange={(e) => {
            //  this.handleChange(e, "dropdown");
          }}
          // onClick={this.handleClick}
          disabled={this.state.disabled ? true : this.state.isNewRequest}
          placeholder="Enter 3 characters to search."
          //title={this.state.selectedVendor.length > 20 ? this.state.selectedVendor + this.state.selectedContract : ""}
          autoComplete="Off"
          value={
            this.state.selectedVendor + this.state.selectedContract
          }
        //onKeyDown={this.handleKeyDown}
        />
      </OverlayTrigger>)
    else
      return (<input
        //ref={this.inputSearch}
        id="inputSearch"
        key={"text-Filter"}
        type="text"
        className="form-control searchVendor VendorDisabled"
        onChange={(e) => {
          //this.handleChange(e, "dropdown");
        }}
        //onClick={this.handleClick}
        disabled={this.state.disabled ? true : this.state.isNewRequest}
        placeholder="Enter 3 characters to search."
        autoComplete="Off"
        value={
          this.state.selectedVendor + this.state.selectedContract
        }
      //onKeyDown={this.handleKeyDown}
      />)
  }

  openPasswordModal = (index) => {
    if (this.state.showAddUserModal) {
      this.setState({ showAddUserModal: false });
    }
    setTimeout(() => {
      this.setState({ showAddUserModal: true, selectedPencilIndex: index });
    });
  }

  hideModal = () => {
    this.setState({
      showAddUserModal: false,
      password: '',  // Reset password field
      passwordError: '',  // Reset password error message
      confirmPassword: '',  // Reset confirmPassword field
      confirmPasswordError: '',  // Reset confirmPassword error message
      saveButtonDisabled: true
    });
  }

  handleChange = (e, index) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    const updatedHotelKeyAPIDetails = [...this.state.hotelKeyAPIDetails];
    updatedHotelKeyAPIDetails[index] = {
      ...updatedHotelKeyAPIDetails[index],
      [name]: trimmedValue,
    };

    this.setState({ hotelKeyAPIDetails: updatedHotelKeyAPIDetails }, () => {
      // Check if passwords match and update the error message
      const errorState = { ...this.state };
      if (name === 'propertycode') {
        if (
          !updatedHotelKeyAPIDetails[index].isUpdate &&
          (updatedHotelKeyAPIDetails[index].userName !== null && updatedHotelKeyAPIDetails[index].userName.trim() !== '') &&
          (updatedHotelKeyAPIDetails[index].password === null || updatedHotelKeyAPIDetails[index].password.trim() === '')
        ) {
          errorState[`passwordError_${index}`] = 'Password is required';
        }

        if (
          (updatedHotelKeyAPIDetails[index].userName === null || updatedHotelKeyAPIDetails[index].userName.trim() === '') &&
          (updatedHotelKeyAPIDetails[index].password === null || updatedHotelKeyAPIDetails[index].password.trim() === '')
        ) {
          errorState[`passwordError_${index}`] = 'Password is required';
          errorState[`userNameError_${index}`] = 'Username is required';
        }
      } else if (name === 'userName' && trimmedValue !== '') {
        if (!updatedHotelKeyAPIDetails[index].isUpdate) {
          errorState[`passwordError_${index}`] = 'Password is required';
        } else {
          errorState[`userNameError_${index}`] = '';
          errorState[`passwordError_${index}`] = '';
        }
      }

      this.setState(errorState);
    });

    this.setState({ [name]: trimmedValue, [`${name}Error`]: '' });
  }

  handleBlurValidation = (fieldName, index) => (e) => {
    const value = e.target.value.trim();
    const errors = {
      propertycode: 'Property Code is required',
      userName: 'Username is required',
      password: 'Password is required',
      confirmPassword: 'Confirm Password is required',
    };

    let error = '';
    if (value === '') {
      error = errors[fieldName] || '';
    } else if (fieldName === 'confirmPassword' && this.state.password !== value) {
      error = 'Passwords do not match';
    } else if (fieldName === 'propertycode' && !/^\d{4}$/.test(value) && this.state.selectedEndpoint !== "RedRoofInn") {
      error = 'Property Code must be numeric and exactly 4 digits';
    } else if (fieldName === 'propertycode' && !/^[a-zA-Z0-9]{1,10}$/.test(value) && this.state.selectedEndpoint === "RedRoofInn") {
      error = 'Property Code must be alphanumeric and not have more than 10 characters.';
    } else if (fieldName === 'userName' && value.length > 50) {
      error = 'Username cannot exceed 50 characters';
    } else {
      // Clear the error message for the specific index
      const errorState = { ...this.state };
      errorState[`propertycodeError_${index}`] = '';
      errorState[`userNameError_${index}`] = '';

      this.setState(errorState);
    }

    // Set the error message for the specific index
    const errorState = { ...this.state };
    errorState[`${fieldName}Error_${index}`] = error;
    this.setState(errorState, () => {
      // Check if both fields have valid values for the specific index
      const { password, confirmPassword } = this.state;
      const isSaveButtonEnabled = password.length > 0 && confirmPassword.length > 0 && password === confirmPassword;

      // Update the state to enable/disable the "Save" button
      this.setState({ saveButtonDisabled: !isSaveButtonEnabled });

      if (this.state[`propertycodeError_${index}`] === '' && this.state[`userNameError_${index}`] === '') {
        if (this.state.hotelKeyAPIDetails[index].oldPropertyCode !== value && this.state.hotelKeyAPIDetails[index].oldUserName !== value
          && this.state.hotelKeyAPIDetails[index].isUpdate) {
          this.setState({ selectedPencilIndex: index }, () => {
            this.saveHotelkeyAPISetupDetails();
          })
        }
      }
    });
  }

  handlePasswordChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();

    this.setState({ [name]: trimmedValue }, () => {
      // Check if both fields have valid values
      const { password, confirmPassword, selectedPencilIndex } = this.state;
      const isSaveButtonEnabled =
        password.length > 0 &&
        confirmPassword.length > 0 &&
        password === confirmPassword;

      // Update the state to enable/disable the "Save" button
      this.setState({ saveButtonDisabled: !isSaveButtonEnabled });

      // Check if passwords match and update the error message
      if (password !== confirmPassword && confirmPassword !== '') {
        this.setState({ confirmPasswordError: 'Passwords do not match' });
      } else if (name === 'password' && trimmedValue.length > 50) {
        this.setState({ passwordError: 'Password cannot exceed 50 characters' });
      } else {
        this.setState({
          confirmPasswordError: '',
          [`passwordError_${selectedPencilIndex}`]: ''
        });
      }
    });

    if (trimmedValue === '') {
      this.setState({ [`${name}Error`]: 'Password is required', [name]: '' });
    } else {
      this.setState({ [name]: trimmedValue, [`${name}Error`]: '' });
    }
  }

  isSaveDisabled = () => {
    // Check if either the password or confirmPassword fields have errors
    if (this.state.passwordError || this.state.confirmPasswordError) {
      return true;
    }

    // Check if either the password or confirmPassword fields are empty
    if (!this.state.password || !this.state.confirmPassword) {
      return true;
    }

    // If none of the above conditions are met, enable the "Save" button
    return false;
  }

  onFieldChange = (event, inputPosition) => {
    let value = event.target.value;
    this.setState({ selectedEndpoint: value, inputPosition }, () =>
      this.saveHotelkeyAPISetupDetails()
    );
  };

  render() {
    const {
      isHotelDetailsLoaded,
      hotelDetails,
      hasFullSub,
      isSpecialPermissionUser,
      hasEditPermission,
      hasEditRooms,
      dualPmsMails, hotelKeyAPIDetails, hotelKeyApiKey, saveButtonDisabled
    } = this.state;
    const { entityRowData, digitalACHEnabled } = this.props;
    return (
      <div id="entitySlideOut" className="pos-fxd index-1000 entity-slideout-ui">
        {/* <ToastContainer containerId={"userSlideOut"} autoClose={3000} /> */}
        <div className="d-flex">
          <div className="back-drop" onClick={this.closeSlideOutWithConfirmation}></div>

          <Container
            fluid
            className="body-sec performance-position entity-slideout-container entity-management-detail-slideout justify-content-end"
          >
            <div className="page-heading d-flex">
              {hotelDetails && (
                <div className="user-details align-items-center mr-auto">
                  <div className="user-name d-flex align-items-center">
                    {hotelDetails?.hotelDetail?.pname}
                    <span
                      className={`badge ${this.getStatusBadgeclass(
                        hotelDetails?.hotelDetail?.status
                      )}`}
                    >
                      {hotelDetails?.hotelDetail?.status}
                    </span>
                  </div>
                  <div className="position-name">
                    {entityRowData?.lettercode}
                  </div>
                </div>
              )}

              {/* <div className="header-popout ml-auto">
                <Dropdown className="more-action" alignRight>
                  <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary more"
                    id="dropdown-more"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                 <Dropdown.Menu>
                    <Dropdown.Item>Link</Dropdown.Item>
                  </Dropdown.Menu> 
                </Dropdown>
              </div> */}

              <button
                type="button"
                className="btn wht-bg cross"
                onClick={this.closeSlideOutWithConfirmation}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                    fill="#84888C"
                  />
                </svg>
              </button>
            </div>

            <Tab.Container
              activeKey={this.state.activeTab}
              onSelect={(selectedtab) => this.setActiveTab(selectedtab)}
              id="left-tabs-example"
              defaultActiveKey="subscriptions"
            >
              <div>
                <Nav className="nav-tabs flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey="globalsetting">General </Nav.Link>
                  </Nav.Item>

                  {entityRowData?.hotelType === "Hotel" && (
                    <Nav.Item>
                      <Nav.Link eventKey="rooms">Rooms</Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link eventKey="subscriptions">Subscriptions </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="importemail">
                      Import Email Addresses{" "}
                    </Nav.Link>
                  </Nav.Item>
                  {digitalACHEnabled && hasFullSub && (
                    <Nav.Item>
                      <Nav.Link eventKey="DigitalACH">Digital ACH</Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </div>
              <div>
                <Tab.Content>
                  <Tab.Pane eventKey="subscriptions">
                    {isHotelDetailsLoaded &&
                      <Subscriptions hasEditPermission={hasEditPermission} entityRowData={entityRowData} />
                    }
                  </Tab.Pane>
                  {entityRowData?.hotelType === "Hotel" && (
                    hotelDetails?.facilityFeatureFlag === "Yes" ?
                      <Tab.Pane eventKey="rooms">
                      {isHotelDetailsLoaded && (
                        <Rooms
                          hasEditPermission={hasEditPermission}
                          hasEditRooms={hasEditRooms}
                          entityRowData={entityRowData}
                          isReloadRequired={this.isReloadRequired}
                          getRoomsTabModificationStatus={this.getRoomsTabModificationStatus}
                          facilityFeatureFlag = { hotelDetails?.facilityFeatureFlag}
                        />
                      )}
                    </Tab.Pane> :
                    <Tab.Pane eventKey="rooms">
                      {isHotelDetailsLoaded && (
                        <RoomsOld
                          hasEditPermission={hasEditPermission}
                          hasEditRooms={hasEditRooms}
                          entityRowData={entityRowData}
                          isReloadRequired={this.isReloadRequired}
                          getRoomsTabModificationStatus={this.getRoomsTabModificationStatus}
                          facilityFeatureFlag = { hotelDetails?.facilityFeatureFlag}
                        />
                      )}
                    </Tab.Pane> 
                  
                  )}
                  <Tab.Pane eventKey="globalsetting">
                    {isHotelDetailsLoaded && (
                      <GlobalSettings
                        getGeneralTabModificationStatus={this.getGeneralTabModificationStatus}
                        isReloadRequired={this.isReloadRequired}
                        hotelId={this.props?.entityRowData?.hotelId}
                        globalSettings={hotelDetails?.hotelDetail}
                        taxLabels={hotelDetails?.nightAudit}
                        dwollaCustomersDetail={
                          hotelDetails?.dwolla_customersDetail
                        }
                        dwollaCustomerDocumentDetail={
                          hotelDetails?.dwollaCustomer_documentDetail
                        }
                        docstatus={hotelDetails?.docstatus}
                        boverificationStatus={
                          hotelDetails?.boverificationStatus
                        }
                        digitalACHEnabled={hotelDetails.digitalACHEnabled}
                        hasAddDigitalACH={hotelDetails.hasAddDigitalACH}
                        hasEditPermission={hasEditPermission}

                        getHotelModules={this.getHotelModules}
                      />
                    )}
                  </Tab.Pane>

                  <Tab.Pane eventKey="importemail">
                    <div className="general-tab general-tab-content">
                      <div className="body-section">
                        <div className="heading">Import Email Addresses</div>
                        <div className="form-section d-flex flex-column">
                          <div>
                            <FormGroup className="d-flex flex-row email-list">
                              <Form.Label>Daily Activity</Form.Label>
                              <div className="d-flex flex-column">
                                <div
                                  onClick={() =>
                                    this.copyToClipboard(dualPmsMails.length > 1 ? dualPmsMails[0] : hotelDetails?.pmsMailBox, "Email Address")
                                  }
                                  className="d-flex flex-column daily-activity-email"
                                >
                                  <span>{dualPmsMails.length > 1 ? dualPmsMails[0] : hotelDetails?.pmsMailBox}</span>{" "}
                                </div>
                                {dualPmsMails.length > 1 &&
                                  <div
                                    onClick={() =>
                                      this.copyToClipboard(dualPmsMails[1], "Email Address")
                                    }
                                    className="d-flex flex-column daily-activity-email"
                                  >
                                    <span>{dualPmsMails[1]}</span>{" "}
                                  </div>
                                }
                              </div>
                            </FormGroup>
                            <FormGroup className="d-flex flex-row">
                              <Form.Label>Invoice</Form.Label>
                              <div
                                onClick={() =>
                                  this.copyToClipboard(hotelDetails?.acMailBox, "Email Address")
                                }
                                className="d-flex flex-column invoice-email"
                              >
                                <span>{hotelDetails?.acMailBox}</span>
                              </div>
                            </FormGroup>
                            {hotelDetails?.anyActiveCC && (
                            <FormGroup className="d-flex flex-row">
                              <Form.Label>Credit Card Receipts</Form.Label>
                              <div
                                onClick={() =>
                                  this.copyToClipboard(hotelDetails?.ccReceiptMailbox, "Email Address")
                                }
                                className="d-flex flex-column invoice-email"
                              >
                                <span>{hotelDetails?.ccReceiptMailbox}</span>
                              </div>
                            </FormGroup>)}
                          </div>
                        </div>
                        {/* <div>
                        Daily Activity <span>{hotelDetails?.hotelDetail?.displayHotelid}-PMS@inn-flow.net</span> (Opera)
                      </div>
                      <div>
                        Invoice <span>{hotelDetails?.hotelDetail?.displayHotelid}-PMS@inn-flow.net</span>
                      </div> */}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="DigitalACH" unmountOnExit>
                    <DigitalAchTab
                      isReloadRequired={this.isReloadRequired}
                      hotelId={this.props?.entityRowData?.hotelId}
                      hotelDetails={hotelDetails}
                      getHotelModules={this.getHotelModules}
                      isSpecialPermissionUser={isSpecialPermissionUser}
                    />
                  </Tab.Pane>
                  {hotelKeyAPIDetails.length > 0 &&
                    <Tab.Pane eventKey="importemail">
                      <div className="general-tab general-tab-content">
                        <div className="body-section">
                          <div className="heading">Hotel Key Details</div>
                          {hotelKeyAPIDetails.length > 0 &&
                            <div className="form-section d-flex flex-column">
                              <div>
                                <Form.Group
                                  controlId="endpoint-selection"
                                  className= "d-flex flex-row">
                                  <Form.Label>Endpoint</Form.Label>
                                  <div className="validate-input d-flex flex-column">
                                    <DropDownList
                                      // tabIndex={12}
                                      placeHolder={"Select Endpoint"}
                                      data={StaticArrays.endpoints}
                                      defaultValue={this.state.selectedEndpoint}
                                      isSearchRequired={false}
                                      label={"label"}
                                      value={"value"}
                                      id="endpoint"
                                      name="endpoint"
                                      onDropDownChange={(item) => {
                                        if (!item) {
                                          return;
                                        }
                                        this.onFieldChange(
                                          Utils.BuildCustomEventObject(
                                            "endpoint",
                                            item.value
                                          ),
                                          12
                                        );
                                      }}
                                      selectedItem={[
                                        ...[{ value: "", label: "Select Endpoint" }],
                                        ...StaticArrays.endpoints,
                                      ].find(
                                        (r: any) =>
                                          r?.value === this.state.selectedEndpoint
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                                <Form.Group controlId="date-selection" className="d-flex flex-row mrgn-btm">
                                  <Form.Label>Hotel key API key</Form.Label>
                                  <div className="validate-input d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="hotelKeyApiKey"
                                      name="hotelKeyApiKey"
                                      value={hotelKeyApiKey}
                                      disabled={true}
                                    />
                                    <span className="icon">
                                      <FaCopy onClick={() =>
                                        this.copyToClipboard(hotelKeyApiKey, "API Key")
                                      } />
                                    </span>
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          }
                          {this.state.hotelKeyAPIDetails.map((hotelKeyApi, index) => (
                            <div className="form-section d-flex flex-column dual-brand-sec" key={index}>
                              {index > 0 && <div className="heading">Dual Brand Property Code</div>}
                              <div>
                                <Form.Group
                                  onBlur={this.handleBlurValidation('propertycode', index)}
                                  controlId="propertycode-selection"
                                  className={
                                    this.state[`propertycodeError_${index}`] !== ""
                                      ? "validation-error d-flex flex-row"
                                      : "d-flex flex-row"
                                  }
                                >
                                  <Form.Label>Property Code<span className="validation-asterisk">*</span></Form.Label>
                                  <div className="validate-input d-flex flex-column">
                                    <input
                                      type="text"
                                      placeholder="Please Enter Property Code"
                                      className="form-control"
                                      id="txtfax"
                                      name="propertycode"
                                      autoComplete="fax"
                                      value={hotelKeyApi.propertycode}
                                      onChange={(e) => this.handleChange(e, index)}
                                    />
                                    <span className="validation-message">
                                      {this.state[`propertycodeError_${index}`]}
                                    </span>
                                  </div>
                                </Form.Group>
                                <Form.Group
                                  onBlur={this.handleBlurValidation('userName', index)}
                                  controlId="date-selection"
                                  className={
                                    this.state[`userNameError_${index}`] !== ""
                                      ? "validation-error d-flex flex-row"
                                      : "d-flex flex-row"
                                  }
                                >
                                  <Form.Label>Username<span className="validation-asterisk">*</span></Form.Label>
                                  <div className="validate-input d-flex flex-column">
                                    <input
                                      type="text"
                                      placeholder="Please Enter Username"
                                      className="form-control"
                                      id="txtfax"
                                      name="userName"
                                      autoComplete="off"
                                      value={hotelKeyApi.userName}
                                      onChange={(e) => this.handleChange(e, index)}
                                    />
                                    <span className="validation-message">
                                      {this.state[`userNameError_${index}`]}
                                    </span>
                                  </div>
                                </Form.Group>
                                <Form.Group
                                  controlId="date-selection"
                                  className={
                                    this.state[`passwordError_${index}`] !== ""
                                      ? "validation-error d-flex flex-row"
                                      : "d-flex flex-row"
                                  }
                                >
                                  <Form.Label>Password<span className="validation-asterisk">*</span></Form.Label>
                                  <div className="validate-input">
                                    <div className="d-flex align-items-center">
                                      <input
                                        tabIndex={22}
                                        type="password"
                                        placeholder="Password"
                                        className="form-control"
                                        id="txtfax"
                                        name="fax"
                                        autoComplete="off"
                                        value="00000000"
                                        disabled={true}
                                      />
                                      <span className="icon">
                                        <FaPencilAlt
                                          className={
                                            (hotelKeyApi.userName === null || hotelKeyApi.userName === "" || hotelKeyApi.propertycode === "") ||
                                              (this.state[`propertycodeError_${index}`] !== "" || this.state[`userNameError_${index}`] !== "")
                                              ? "disabled"
                                              : ""
                                          }
                                          onClick={() => this.openPasswordModal(index)} />
                                      </span>
                                    </div>

                                    <div className="validation-message">
                                      {this.state[`passwordError_${index}`]}
                                    </div>
                                  </div>
                                </Form.Group>
                                {/* Add other fields and validation as needed */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Tab.Pane>
                  }
                </Tab.Content>
              </div>
            </Tab.Container>
            <Modal
              show={this.state.showAddUserModal}
              onHide={this.hideModal}
              backdrop="static"
              keyboard={false}
              centered
              className="ccsImportModal"
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-section d-flex flex-column">
                  <div>
                    <Form.Group
                      controlId="date-selection"
                      className={
                        this.state.passwordError !== ""
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Password</Form.Label>
                      <input
                        type="password"
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        autoComplete="new-password"
                        id="password"
                        name="password"
                        placeholder="Enter new password"
                        className="form-control"
                      />
                      <span className="validation-message">{this.state.passwordError}</span>
                    </Form.Group>
                    <Form.Group
                      controlId="date-selection"
                      className={
                        this.state.confirmPasswordError !== ""
                          ? "validation-error"
                          : ""
                      }
                    >
                      <Form.Label>Confirm Password</Form.Label>
                      <input
                        type="password"
                        onChange={this.handlePasswordChange}
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        id="confirmPassword"
                        placeholder="Enter confirm password"
                        className="form-control"
                        autoComplete="off"
                      />
                      <span className="validation-message">{this.state.confirmPasswordError}</span>
                    </Form.Group>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button className="btnCancel btn-ghost"
                  onClick={this.hideModal}
                >Discard</Button>
                <Button variant="primary"
                  onClick={this.saveHotelkeyAPISetupDetails}
                  disabled={saveButtonDisabled}
                >Save</Button>
              </Modal.Footer>
            </Modal>
          </Container >
        </div >
      </div >
    );
  }
}
