import React from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row
} from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { ISavePTOUserDetailRequest } from "../../../Common/Contracts/ISavePTOUserDetailRequest";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import Calendar from "react-calendar";
import moment from "moment";
import {  reject } from "q";
import OutsideClickHandler from "react-outside-click-handler";
import { IPTOUserDetails } from "../../../Common/Contracts/IPTOUserDetails";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { confirmAlert } from "react-confirm-alert";

export class AddPTOType extends React.Component<any, any> {
  private ptoTypeFormSchema = Joi.object({
    userName: Joi.string(),
    ptoName: Joi.number()
      .required()
      .min(1)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select PTO name" };
        });
      }),

    ptoStartDate: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select PTO Start Date" };
        });
      }),
    ptoStatus: Joi.number()
      .required()
      .min(0)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select PTO Status" };
        });
      }),

    uniqueNo: Joi.number(),
  });

  state: any = {
    ptoType: {
      userName: this.props.userName,
      ptoName: this.props?.ptoTypeEditRow?.ptoUniqueNo || 0,
      ptoStartDate: this.props?.ptoTypeEditRow?.ptoStartDate ? this.props?.ptoTypeEditRow?.ptoStartDate : (this.props?.hireDate ? moment(this.props?.hireDate).format("MM/DD/YY") : ""),
      ptoStatus: this.props?.ptoTypeEditRow?.status === "0" ? 0  : 1,
      uniqueNo: +this.props?.ptoTypeEditRow?.uniqueNo || 0,
    },
    calenderShow: false,
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    isPTONamesLoaded: false,
    ptoNames: [],
    totalPTOHours: this.props?.ptoTypeEditRow?.ptoStartingBalance || "00:00",
    statusList: [
    
    ],
  };

  componentDidMount = () => {
    this.getPTOName();
    if(this.props.isPTOTypeEdit) {
      
      this.setState({statusList:
        [{ id: 1, name: "Active" },
      { id: 0, name: "Inactive" }]});
    } else {

      this.setState({statusList:
        [{ id: 1, name: "Active" },
      { id: 0, name: "Inactive", disabled: true }]});
    }

  };

  onFieldChange = (event, inputPosition) => {
    const { ptoType } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if (fieldName === "ptoName") {
      this.getPTOUserList(+event.target.value);
    }
    ptoType[fieldName] = value;
    if(inputPosition> this.state.inputPosition) 
    {
      this.setState({ inputPosition });
    }
    this.setState({ ptoType }, () =>
      this.validatePTOTypeFormSchema()
    );
    if (fieldName === "ptoName") {
      $('#ddlptoname')?.click();
    }
    if (fieldName === "ptoStatus") {
      $('#ddlStatus')?.click();

    }
  };


  getPTOUserList = (ptoType: any) => {

    UserManagement.GetPTOUserList(this.props.userName).then((data: IPTOUserDetails[]) => {
      const laborTimeOffData = data
        ?.map((item) => {
          item.ptoStartDate = item.ptoStartDate
            ? moment(item?.ptoStartDate).format("MM/DD/YYYY")
            : "";
          item.ptoStartingBalance = Utils.getMinutesInHHMMFormat(
            Utils.getDecimalHHMMToMinutes(item.ptoStartingBalance)
          );
          return item;
        }).find(x => x.ptoUniqueNo === ptoType);
      this.setState({ totalPTOHours: laborTimeOffData?.ptoStartingBalance });
    });
  };

  componentWillUnmount = () => {
    // setTimeout(()=> {
    //   toast.dismiss();
    // },1000)
  }

  getPTOName = () => {
    const { userName } = this.props;
    UserManagement.GetPTOName("Active", userName).then((ptoNames) => {

      const existingPTONames = this.props.laborTimeOffData.map(item => item.ptoName);
      if (this.props?.isPTOTypeEdit) {
        ptoNames = ptoNames.filter(item => +item.uniqueNo === +this.props?.ptoTypeEditRow?.ptoUniqueNo)
      } else {
        if (existingPTONames?.length > 0) {
          ptoNames = ptoNames.filter(item => !existingPTONames.includes(item.ptoName))
        }
      }
      this.setState({ ptoNames });
    }).finally(() => this.setState({ isPTONamesLoaded: true }));
  }


  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps?.laborTimeOffData?.length !== this.props?.laborTimeOffData?.length) {

      let ptoNames = [...this.state.ptoNames];
      const existingPTONames = this.props.laborTimeOffData.map(item => item.ptoName);

      if (existingPTONames?.length > 0) {
        ptoNames = ptoNames.filter(item => !existingPTONames.includes(item.ptoName))
      }
      this.setState({ ptoNames });
    }
  }



  confirmSavePTOUserDetails = () => {
    if (  +this.state.ptoStatus!==1 && (+this.state.ptoStatus!== +this.props?.ptoTypeEditRow?.status) && +this.props?.ptoTypeEditRow?.status ===1)  {
      confirmAlert({
        title: "Inactive confirmation",
        message:"User has pending PTO requests that will be removed. Are you sure you would like to continue?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.savePTOUserDetails(),
          },
          {
            label: "Cancel",
            onClick: () => reject(),
          },
         
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      this.savePTOUserDetails();
    }


  };


  savePTOUserDetails = () => {
    const request: ISavePTOUserDetailRequest = {
      ...this.state.ptoType,
    } as ISavePTOUserDetailRequest;

    request.isNewUserAdded = this.props?.isNewUserAdded;
    this.setState({ isDataSaving: true });
    UserManagement.SavePTOUserDetails(request)
      .then((result) => {
        debugger
        this.setState({ isDataSaving: false });
        if (result?.message === "Success") {
          toast.success(result?.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addPTOType"
          });
          this.props.getPTOUserList(true);
          this.props.hidePTOTypeModal(true);
        } else {
          Utils.toastError(result?.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addPTOType"
          });
        }

      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addPTOType"
        });
      });
  };
  validatePTOTypeFormSchema = () => {
    const valid = Joi.validate(this.state.ptoType, this.ptoTypeFormSchema, {
      abortEarly: false,
    });

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  showPTOErrorMessage = () => {
    const message = this.props.laborTimeOffData?.length > 0 ? "User EHID's have no more associated PTO's to add" :
      "User EHID's have no associated PTO's to add";

    Utils.toastError(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      containerId: "addPTOType"
    });
  }


  render() {
    const {
      ptoType,
      errors,
      totalPTOHours,
      ptoNames,
      inputPosition,
      statusList,
      isFormNotValid,
      isDataSaving
    } = this.state;

    const { showPTOTypeModal, hidePTOTypeModal }
      = this.props;

    return (
      <>
        {this.state.isPTONamesLoaded &&




          <Modal
            className="add-pto-type-modal"
            show={showPTOTypeModal}
            onHide={hidePTOTypeModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >

            <Modal.Header closeButton>
              <Modal.Title>
                {+this.props?.ptoTypeEditRow?.uniqueNo > 0
                  ? "Edit PTO Type"
                  : "Add PTO Type"}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="form-section">
              <div className="body-section">
              
                <Form.Group as={Row} controlId="time-picker">
                  <Col
                    sm="6"
                    className={
                      errors?.ptoName && inputPosition >= 1
                        ? "validation-error"
                        : ""
                    }
                  >
                    <Form.Label>PTO Name</Form.Label>
                    {ptoNames.length > 0 && <DropDownList placeHolder={"Search PTO Name"}
                          data={ptoNames}
                          isSearchRequired={false}
                          label={"ptoName"}
                          value={"uniqueNo"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "ptoName",
                                item.uniqueNo
                              ),
                              1
                            )
                           
                          }}
                          selectedItem={[
                            ...[{ uniqueNo: 0, ptoName: "Select PTO Name" }],
                            ...ptoNames,
                          ].find((item) => +item?.uniqueNo === +ptoType?.ptoName)}
                          />}
                   
                    {this.getFieldError("ptoName", 1)}
                  </Col>
                  <Col
                    sm="6"
                    className={
                      errors?.ptoStartDate && inputPosition >= 2
                        ? "validation-error"
                        : ""
                    }
                  >
                    <Form.Label>PTO Start Date</Form.Label>
                    <div className="validate-input d-flex flex-column">
                      <div className="calender-container">
                        <div
                          className={
                            this.state.calenderShow
                              ? "calender-display open"
                              : "calender-display"
                          }
                          onClick={this.calenderShow}
                        >
                          <input
                            onFocus={this.calenderShow}
                            type="text"
                            placeholder="MM/DD/YY"
                            value={ptoType.ptoStartDate}
                            className="form-control single-date-pick"
                            name="ptoStartDate"
                            autoComplete="ptoStartDate"
                          />
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                            <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                          </svg>
                        </div>
                        {this.state.calenderShow && (
                          <div className="calender">
                            <OutsideClickHandler onOutsideClick={this.calenderHide}>
                              <Calendar
                                calendarType={"US"}
                                onChange={(date) => {
                                  const value = moment(date).format("MM/DD/YY");
                                  this.calenderHide();
                                  this.onFieldChange(
                                    Utils.BuildCustomEventObject(
                                      "ptoStartDate",
                                      value
                                    ),

                                    2
                                  );
                                  this.calenderHide();
                                }}
                                value={ptoType.ptoStartDate ? new Date(ptoType.ptoStartDate) : (this.props?.hireDate ? new Date(moment(this.props?.hireDate).format("MM/DD/YY")) : new Date())}
                                minDate={this.props?.hireDate ? new Date(moment(this.props?.hireDate).format("MM/DD/YY")) : undefined}
                              />
                            </OutsideClickHandler>
                          </div>
                        )}
                      </div>
                    </div>
                    {this.getFieldError("ptoStartDate", 2)}
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  controlId="time-picker"
                  className={
                    errors?.ptoStatus && inputPosition >= 3
                      ? "validation-error"
                      : ""
                  }
                >
                  <Col sm="6">
                    <Form.Label>Status</Form.Label>
                    {statusList.length > 0 && (
                        <DropDownList
                          isSearchRequired={false}
                          placeHolder={"Search PTO status"}
                          data={statusList}
                          label={"name"}
                          value={"id"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject(
                                "ptoStatus",
                                item.id
                              ),
                              3
                            )
                            
                          }}
                         
                          selectedItem={[
                            ...[{ id: 0, name: "Select Status" }],
                            ...statusList,
                          ].find((item) => item?.id === ptoType?.ptoStatus)}
                        />
                      )}
                    {this.getFieldError("ptoStatus", 3)}
                  </Col>
                  <Col sm="6">
                    <Form.Label>Current PTO Balance</Form.Label>
                    <Form.Control
                      as="input"
                      type="text"
                      value={totalPTOHours}
                      readOnly

                      id=""
                    ></Form.Control>
                  </Col>
                </Form.Group>
              
              </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={() => ptoNames?.length === 0 ? this.showPTOErrorMessage() : this.confirmSavePTOUserDetails()}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        }
      </>
    );
  }
}

