import * as React from "react";
import { Dropdown, Card, Button, Form } from "react-bootstrap";
import {
  Roles,
  RolesforTreeView,
  RoleMatrixClass,
} from "../../../Common/Services/RolePermissionSetting";
import { reject, resolve } from "q";
import { ISaveRoleMatrixDto } from "../../../Common/Contracts/IRolePermission";

import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import PageLoader from "../../../Common/Components/PageLoader";
import OutsideClickHandler from "react-outside-click-handler";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Spinner from "react-bootstrap/Spinner";
import scrollIntoView from "scroll-into-view";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { Utils } from "../../../Common/Utilis";

let removePermissionID: any[] = [];
let addPermissionID: any[] = [];
let selectedRolesItem: any = [];
let isMatchedNodeParentID: any[] = [];
let removePIDforSave: any[] = [];
let addPIDforDave: any[] = [];

export default class RoleMatrix extends React.Component<any, any> {
  //public scrollRef;
  public moduleNameRef;
  constructor(props) {
    super(props);
    // this.scrollRef = React.createRef();
    this.moduleNameRef = React.createRef();
    this.state = {
      clickCount: [],
      loader: false,
      userRoleList: [],
      oldUserRoleList: [],
      userRoleFilterList: [],
      userRoleforTreeView: [],
      tNodes: [],
      roleforTreeView0: [],
      roleforTreeView1: [],
      roleforTreeView2: [],
      roleforTreeView3: [],
      roleforTreeView4: [],
      roleforTreeView5: [],
      tNodes0: [],
      tNodes1: [],
      tNodes2: [],
      tNodes3: [],
      tNodes4: [],
      tNodes5: [],
      oldTreeData0: [],
      oldTreeData1: [],
      oldTreeData2: [],
      oldTreeData3: [],
      oldTreeData4: [],
      oldTreeData5: [],
      selectedRoles: [],
      expandcollapse: "+ Expand All",
      arrowIcon: chevronRight,
      roleFilter: "",
      filterPermissions: "",
      accordionToggle: false,
      isRotatedArrow: false,
      accordionTitle: true,
      selectedRoleLength: "",
      toggleZero: false,
      toggleOne: false,
      toggleTwo: false,
      toggleThree: false,
      toggleFour: false,
      toggleFive: false,
      filterText: "",
      //scrollTop: 0,
      ///scrollToFix: false,
    };
  }
  componentDidMount() {
    selectedRolesItem = [];
    this.generateTreeView("0");
    this.roleMatrixUserRole();
  }
  roleMatrixUserRole = () => {
    Roles.GetUserRoleList().then(async (result) => {
      if (result != null) {
        result.map((items) => (items["isChecked"] = false));
        this.setState({
          //userRoleList: this.sortByKey(result, "orderNo"),
          //oldUserRoleList: this.sortByKey(result, "orderNo"),
          //userRoleFilterList: this.sortByKey(result, "orderNo"),
          userRoleList: this.sortByName(result),
          oldUserRoleList: this.sortByName(result),
          userRoleFilterList: this.sortByName(result),
        });
      }
    });
  };

  sortByName = (arrayResult) => {
    arrayResult.sort(function (a: any, b: any) {
      if (a.roleName.toLowerCase() < b.roleName.toLowerCase()) {
        return -1;
      }
      if (a.roleName.toLowerCase() > b.roleName.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return arrayResult;
  };

  sortByKey = (array: any, key: any) => {
    return array.sort((a, b) => {
      let x = parseInt(a[key]);
      let y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };
  generateTreeView = (roleid: any) => {
    this.setState({ loader: true });
    RolesforTreeView.GetMatrixUserRoleforTreeView(roleid).then(
      async (result) => {
        if (result != null) {
          result = result.map((item, id) => {
            return {
              id: id,
              isExpend: false,
              helpText: item.helpText,

              menuName: item.menuName,
              isSensetive: item.isSensetive,

              selectAll: "Select All",
              isRotate: "",
              backColorClass: "roleMatrixMenuName",
              pMenuName: item.pMenuName,
              parentID: item.parentID,
              childID: item.childID,
              isChecked: item.isChecked,
              isShowIcon: true,
              modulePermisisonCount: item.modulePermisisonCount,
              permisionCount: item.permisionCount,
              children: [],
              isSpecial: item.isSpecial
            };
          });
          result.forEach((item) => {
            if (item.parentID !== "0") {
              try {
                let parent = result.find((a) => item.parentID === a.childID);
                //parent.children = parent.children || [];
                parent.children = typeof parent.children !== "undefined" && (parent.children || []);
                parent.children.push(item);
              } catch { }
            }
          });
          result = result.filter(({ parentID }) => parentID === "0");
          this.setState({ userRoleforTreeView: result, tNodes: result });
          this.setState({ loader: false });
        }
      }
    );
  };

  removeHighlightedColor = (obj, textToSearch) => {
    if (obj.children.length > 0) {
      for (let i = 0; i < obj.children.length; i++) {
        this.removeHighlightedColor(obj.children[i], textToSearch);
        obj.backColorClass = "";
      }
    } else {
      obj.backColorClass = "";
    }
  };
  changeRole = (event, itm) => {
    let id = itm.roleID;
    let isChecked = event.target.checked;
    let { userRoleList, filterText } = this.state;
    let checkData = [...this.state.tNodes];
    for (let index = 0; index < checkData.length; index++) {
      this.removeHighlightedColor(checkData[index], "");
    }
    if (isChecked) {
      userRoleList.forEach((items) => {
        ;
        if (items.roleID === id) {
          if (selectedRolesItem.length < 6) {
            selectedRolesItem.push(items);
            items.isChecked = isChecked;
            this.checkLength(selectedRolesItem, selectedRolesItem.length, id);
          }
        }
      });
    } else {
      userRoleList.forEach((items) => items.roleID === id && (items.isChecked = isChecked));
      selectedRolesItem = selectedRolesItem.filter((item) => item.roleID !== id);
      if (selectedRolesItem.isLength > 0) {
        this.sortSelectedArray(selectedRolesItem);
      }
      // this.checkLength(selectedRolesItem, selectedRolesItem.length, id);
    }
    this.setState({ userRoleList, selectedRoles: selectedRolesItem });
    if (filterText !== "") {
      this.setState({
        filterText: "",
        userRoleforTreeView: this.state.tNodes,
        roleforTreeView0: this.state.tNodes0,
        roleforTreeView1: this.state.tNodes1,
        roleforTreeView2: this.state.tNodes2,
        roleforTreeView3: this.state.tNodes3,
        roleforTreeView4: this.state.tNodes4,
        roleforTreeView5: this.state.tNodes5,
      });
    }
  };

  sortSelectedArray = (myArray) => {
    myArray.sort(function (a, b) {
      if (a.roleName.toLowerCase() < b.roleName.toLowerCase()) return -1;
      if (a.roleName.toLowerCase() > b.roleName.toLowerCase()) return 1;
      return 0;
    });
  };

  checkLength = (selectedRoles, isLength, id) => {
    if (isLength > 0) {
      this.sortSelectedArray(selectedRoles);
    }
    let rollID = isLength === 1 ? selectedRoles[0]?.roleID : selectedRoles.filter((o: any) => o.roleID === id)[0]?.roleID;
    if (isLength === 1) {
      this.generateTreeView0(rollID);
    } else if (isLength === 2) {
      this.generateTreeView1(rollID);
    } else if (isLength === 3) {
      this.generateTreeView2(rollID);
    } else if (isLength === 4) {
      this.generateTreeView3(rollID);
    } else if (isLength === 5) {
      this.generateTreeView4(rollID);
    } else if (isLength === 6) {
      this.generateTreeView5(rollID);
    }
  };
  generateTreeView0 = (rollID: any) => {
    this.setState({ loader: true });
    RolesforTreeView.GetMatrixUserRoleforTreeView(rollID)
      .then(async (result) => {
        debugger
        if (result != null) {
          this.setState({ tableData0: result });
          result = result.map((item, id) => {
            return {
              id: id,
              isExpend: false,
              helpText: item.helpText,

              menuName: item.menuName,
              isSensetive: item.isSensetive,

              selectAll: "Select All",
              isRotate: "",
              pMenuName: item.pMenuName,
              parentID: item.parentID,
              childID: item.childID,

              treeID: "1",
              isChecked: item.isChecked,
              modulePermisisonCount: item.modulePermisisonCount,
              permisionCount: item.permisionCount,
              children: [],
              isSpecial: item.isSpecial
            };
          });

          this.setState({ oldTreeData0: result });

          result.forEach((item) => {
            if (item.parentID !== "0") {
              let parent = result.find((a) => item.parentID === a.childID);
              parent.children = parent.children || [];
              parent.children.push(item);
            }
          });
          result = result.filter(({ parentID }) => parentID === "0");
          this.setState({ roleforTreeView0: result, tNodes0: result });
          //console.log(this.state.roleforTreeView0);
          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        reject();
        this.setState({ loader: false });
      });
  };
  generateTreeView1 = (rollID: any) => {
    this.setState({ loader: true });
    RolesforTreeView.GetMatrixUserRoleforTreeView(rollID)
      .then(async (result) => {
        if (result != null) {
          result = result.map((item, id) => {
            return {
              id: id,
              isExpend: false,
              helpText: item.helpText,

              menuName: item.menuName,
              isSensetive: item.isSensetive,

              selectAll: "Select All",
              isRotate: "",
              pMenuName: item.pMenuName,
              parentID: item.parentID,
              childID: item.childID,
              isChecked: item.isChecked,
              treeID: "2",
              modulePermisisonCount: item.modulePermisisonCount,
              permisionCount: item.permisionCount,
              children: [],
              isSpecial: item.isSpecial
            };
          });
          this.setState({ oldTreeData1: result });
          result.forEach((item) => {
            if (item.parentID !== "0") {
              let parent = result.find((a) => item.parentID === a.childID);
              parent.children = parent.children || [];
              parent.children.push(item);
            }
          });
          result = result.filter(({ parentID }) => parentID === "0");
          this.setState({ roleforTreeView1: result, tNodes1: result });
          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        reject();
        this.setState({ loader: false });
      });
  };
  generateTreeView2 = (rollID: any) => {
    this.setState({ loader: true });
    RolesforTreeView.GetMatrixUserRoleforTreeView(rollID)
      .then(async (result) => {
        if (result != null) {
          result = result.map((item, id) => {
            return {
              id: id,
              isExpend: false,
              helpText: item.helpText,

              menuName: item.menuName,
              isSensetive: item.isSensetive,

              selectAll: "Select All",
              isRotate: "",
              pMenuName: item.pMenuName,
              parentID: item.parentID,
              childID: item.childID,
              isChecked: item.isChecked,
              treeID: "3",
              modulePermisisonCount: item.modulePermisisonCount,
              permisionCount: item.permisionCount,
              children: [],
              isSpecial: item.isSpecial
            };
          });
          this.setState({ oldTreeData2: result });

          result.forEach((item) => {
            if (item.parentID !== "0") {
              let parent = result.find((a) => item.parentID === a.childID);
              parent.children = parent.children || [];
              parent.children.push(item);
            }
          });
          result = result.filter(({ parentID }) => parentID === "0");
          this.setState({ roleforTreeView2: result, tNodes2: result });
          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        reject();
        this.setState({ loader: false });
      });
  };
  generateTreeView3 = (rollID: any) => {
    this.setState({ loader: true });
    RolesforTreeView.GetMatrixUserRoleforTreeView(rollID)
      .then(async (result) => {
        if (result != null) {
          result = result.map((item, id) => {
            return {
              id: id,
              isExpend: false,
              helpText: item.helpText,

              menuName: item.menuName,
              isSensetive: item.isSensetive,

              selectAll: "Select All",
              isRotate: "",
              pMenuName: item.pMenuName,
              parentID: item.parentID,
              childID: item.childID,
              isChecked: item.isChecked,
              treeID: "4",
              modulePermisisonCount: item.modulePermisisonCount,
              permisionCount: item.permisionCount,
              children: [],
              isSpecial: item.isSpecial
            };
          });
          this.setState({ oldTreeData3: result });
          result.forEach((item) => {
            if (item.parentID !== "0") {
              let parent = result.find((a) => item.parentID === a.childID);
              parent.children = parent.children || [];
              parent.children.push(item);
            }
          });
          result = result.filter(({ parentID }) => parentID === "0");
          this.setState({ roleforTreeView3: result, tNodes3: result });
          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        reject();
        this.setState({ loader: false });
      });
  };
  generateTreeView4 = (rollID: any) => {
    this.setState({ loader: true });
    RolesforTreeView.GetMatrixUserRoleforTreeView(rollID)
      .then(async (result) => {
        if (result != null) {
          result = result.map((item, id) => {
            return {
              id: id,
              isExpend: false,
              helpText: item.helpText,

              menuName: item.menuName,
              isSensetive: item.isSensetive,

              selectAll: "Select All",
              isRotate: "",
              pMenuName: item.pMenuName,
              parentID: item.parentID,
              childID: item.childID,
              isChecked: item.isChecked,
              treeID: "5",
              modulePermisisonCount: item.modulePermisisonCount,
              permisionCount: item.permisionCount,
              children: [],
              isSpecial: item.isSpecial
            };
          });
          this.setState({ oldTreeData4: result });

          result.forEach((item) => {
            if (item.parentID !== "0") {
              let parent = result.find((a) => item.parentID === a.childID);
              parent.children = parent.children || [];
              parent.children.push(item);
            }
          });
          result = result.filter(({ parentID }) => parentID === "0");
          this.setState({ roleforTreeView4: result, tNodes4: result });
          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        reject();
        this.setState({ loader: false });
      });
  };
  generateTreeView5 = (rollID: any) => {
    this.setState({ loader: true });
    RolesforTreeView.GetMatrixUserRoleforTreeView(rollID)
      .then(async (result) => {
        if (result != null) {
          result = result.map((item, id) => {
            return {
              id: id,
              isExpend: false,
              helpText: item.helpText,

              menuName: item.menuName,
              isSensetive: item.isSensetive,

              selectAll: "Select All",
              isRotate: "",
              pMenuName: item.pMenuName,
              parentID: item.parentID,
              childID: item.childID,
              isChecked: item.isChecked,
              treeID: "6",
              modulePermisisonCount: item.modulePermisisonCount,
              permisionCount: item.permisionCount,
              children: [],
              isSpecial: item.isSpecial
            };
          });
          this.setState({ oldTreeData5: result });

          result.forEach((item) => {
            if (item.parentID !== "0") {
              let parent = result.find((a) => item.parentID === a.childID);
              parent.children = parent.children || [];
              parent.children.push(item);
            }
          });
          result = result.filter(({ parentID }) => parentID === "0");
          this.setState({ roleforTreeView5: result, tNodes5: result });
          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        reject();
        this.setState({ loader: false });
      });
  };
  badgeSensitive = () => {
    return <label className="red-badges orange-bg">Sensitive</label>;
  };
  helperText = (title) => {
    return (
      <span title={title}>
        <svg
          stroke="currentColor"
          fill="#d7d7d7d"
          strokeWidth="0"
          version="1.1"
          viewBox="0 0 16 16"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
          <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
          <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
        </svg>
      </span>
    );
  };
  /*Filter Permissions by Name*/
  onFilterChange(e) {
    this.setState({ filterText: e.target.value });
    let checkData = [...this.state.tNodes];
    for (let index = 0; index < checkData.length; index++) {
      isMatchedNodeParentID = [];
      this.findAllMatchedNode(checkData[index], e.target.value);
    }
  }

  findAllMatchedNode(obj, textToSearch) {
    if (obj.children.length > 0) {
      for (let i = 0; i < obj.children.length; i++) {
        this.findAllMatchedNode(obj.children[i], textToSearch);
        if (textToSearch !== "") {
          // this.setState({ expandcollapse: "- Collapse All" });
          if (obj.menuName.toLowerCase().includes(textToSearch.toLowerCase())) {
            obj.isExpend = true;
            obj.isRotate = "isRotated";
            obj.backColorClass = "roleMatrixsearched";
            isMatchedNodeParentID.push(obj.parentID);
          } else if (isMatchedNodeParentID.includes(obj.childID)) {
            obj.isExpend = true;
            obj.isRotate = "isRotated";
            obj.backColorClass = "roleMatrixsearched";
            isMatchedNodeParentID.push(obj.parentID);
          } else {
            obj.backColorClass = "";
            obj.isExpend = false;
            obj.isRotate = "";
          }
        } else {
          //this.setState({ expandcollapse: "+ Expand All" });
          //obj.isExpend = false;
          //obj.isRotate = "";
          obj.backColorClass = "";
        }
      }
    } else {
      if (textToSearch !== "") {
        //this.setState({ expandcollapse: "- Collapse All" });
        if (obj.menuName.toLowerCase().includes(textToSearch.toLowerCase())) {
          obj.isExpend = true;
          obj.isRotate = "isRotated";
          obj.backColorClass = "roleMatrixsearched";
          isMatchedNodeParentID.push(obj.parentID);
        } else if (isMatchedNodeParentID.includes(obj.childID)) {
          obj.isExpend = true;
          obj.isRotate = "isRotated";
          obj.backColorClass = "roleMatrixsearched";
          isMatchedNodeParentID.push(obj.parentID);
        } else {
          obj.isExpend = false;
          obj.isRotate = "";
          obj.backColorClass = "";
        }
      } else {
        //this.setState({ expandcollapse: "+ Expand All" });
        //obj.isExpend = false;
        //obj.isRotate = "";
        obj.backColorClass = "";
      }
    }
  }

  collapseexpand() {
    let checkData = [...this.state.tNodes];
    for (let index = 0; index < checkData.length; index++) {
      this.findAllExpandandCollapse(checkData[index], 0);
    }
  }

  findAllExpandandCollapse(obj, id) {
    if (obj.children) {
      for (let i = 0; i < obj.children.length; i++) {
        if (this.state.expandcollapse === "+ Expand All") {
          obj.isExpend = true;
          obj.isRotate = "isRotated";
          this.setState({
            expandcollapse: "- Collapse All",
            expandallclass: "plus-minus-toggle",
            selectUnselctAll: "Select All",
            isRotate: "isRotated",
          });
        } else {
          obj.isExpend = false;
          obj.isRotate = "";
          this.setState({
            expandcollapse: "+ Expand All",
            expandallclass: "plus-minus-toggle collapsed",
            selectUnselctAll: "Select All",
            isRotate: "",
          });
        }
        let x = this.findAllExpandandCollapse(obj.children[i], id);
        if (x) return { ...x, pos: i };
      }
    }
  }

  searchRoleItems = (event: any) => {
    const { userRoleFilterList } = this.state;
    let result = event.target.value;
    this.setState({ roleFilter: result });
    if (result !== "") {
      this.setState({
        userRoleList: userRoleFilterList.filter((items) =>
          items.roleName.toLowerCase().includes(result.toLowerCase().trim())
        ),
      });
    } else {
      this.setState({ userRoleList: this.state.userRoleFilterList });
    }
  };

  toggleAccordion = () => {
    this.setState({
      accordionToggle: !this.state.accordionToggle,
      accordionTitle: !this.state.accordionTitle,
    });
  };

  /* Check uncheck list */
  findAllCheckedUnchecked(obj, id) {
    if (obj.children.length > 0) {
      for (let i = 0; i < obj.children.length; i++) {
        if (obj.isChecked.toLowerCase() === "yes") {
          addPermissionID.push(obj.childID);
        } else {
          removePermissionID.push(obj.childID);
        }
        let x = this.findAllCheckedUnchecked(obj.children[i], id);
        if (x) return { ...x, pos: i };
      }
    }
    if (obj.isChecked.toLowerCase() === "yes") {
      addPermissionID.push(obj.childID);
    } else {
      removePermissionID.push(obj.childID);
    }
    return obj.id === id ? obj : false;
  }

  findChildsCheckbox(data, index) {
    let result;
    let foundResult;
    const i = (data.children || []).findIndex((child) => {
      if (child.isChecked === "Yes") {
        foundResult = true;
      }
      return (result = this.findChildsCheckbox(child, index));
    });
    if (result) return [i, foundResult];
  }
  findParentCheckforCheckbox(data, index) {
    let foundResult = false;
    const i = (data.children || []).findIndex((child) => {
      if (child.isChecked === "Yes") {
        foundResult = true;
        return [foundResult];
      }
      this.findParentCheckforCheckbox(child, index);
    });
    return [foundResult];
  }
  checkParentforCheckbox(data, index, chkType, treeID) {
    if (data.id === index)
      if (chkType === "Yes") {
        data.isChecked = "Yes";
        addPIDforDave.push(data.childID);
      } else {
        // data.isChecked = "No";
        // removePIDforSave.push(data.childID);
      }

    if (data.parentID === "0") {
      data.permisionCount = "0";
      addPermissionID = [];

      this.findAllCheckedUnchecked(data, 0);
      let addPermissionIDs = Array.from(new Set(addPermissionID));
      data.permisionCount = String(addPermissionIDs.length);
    }
    let resultParent;
    if (treeID === "1") {
      resultParent = this.state.oldTreeData0.filter(
        ({ childID }) => childID === data.parentID
      );
    }
    if (treeID === "2") {
      resultParent = this.state.oldTreeData1.filter(
        ({ childID }) => childID === data.parentID
      );
    }
    if (treeID === "3") {
      resultParent = this.state.oldTreeData2.filter(
        ({ childID }) => childID === data.parentID
      );
    }
    if (treeID === "4") {
      resultParent = this.state.oldTreeData3.filter(
        ({ childID }) => childID === data.parentID
      );
    }
    if (treeID === "5") {
      resultParent = this.state.oldTreeData4.filter(
        ({ childID }) => childID === data.parentID
      );
    }
    if (treeID === "6") {
      resultParent = this.state.oldTreeData5.filter(
        ({ childID }) => childID === data.parentID
      );
    }

    if (resultParent.length > 0) {
      let ifFound = this.findParentCheckforCheckbox(
        resultParent[0],
        resultParent[0].id
      );
      if (ifFound[0]) {
        chkType = "Yes";
      } else {
        //chkType = "No";
      }
      this.checkParentforCheckbox(
        resultParent[0],
        resultParent[0].id,
        chkType,
        treeID
      );
    }
  }
  checkChildsforCheckbox = (data, index, chkType, treeID) => {
    //
    if (data.id === index)
      if (chkType === "Yes") {
        data.isChecked = "Yes";
        addPIDforDave.push(data.childID);
      } else {
        data.isChecked = "No";
        removePIDforSave.push(data.childID);
      }
    let resultChild;
    if (treeID === "1") {
      resultChild = this.state.oldTreeData0.filter(
        ({ parentID }) => parentID === data.childID
      );
    }
    if (treeID === "2") {
      resultChild = this.state.oldTreeData1.filter(
        ({ parentID }) => parentID === data.childID
      );
    }
    if (treeID === "3") {
      resultChild = this.state.oldTreeData2.filter(
        ({ parentID }) => parentID === data.childID
      );
    }
    if (treeID === "4") {
      resultChild = this.state.oldTreeData3.filter(
        ({ parentID }) => parentID === data.childID
      );
    }
    if (treeID === "5") {
      resultChild = this.state.oldTreeData4.filter(
        ({ parentID }) => parentID === data.childID
      );
    }
    if (treeID === "6") {
      resultChild = this.state.oldTreeData5.filter(
        ({ parentID }) => parentID === data.childID
      );
    }

    if (resultChild.length > 0) {
      for (let index = 0; index < resultChild.length; index++) {
        this.checkChildsforCheckbox(
          resultChild[index],
          resultChild[index].id,
          chkType,
          treeID
        );
      }
    }
  };
  checkChange(targetNode: any, event, treeID) {
    ;
    this.setState({ loader: true });
    addPIDforDave = [];
    removePIDforSave = [];

    const targetNodeId = targetNode.id;
    let chkType;
    if (targetNode.isChecked === "Yes") chkType = "No";
    else chkType = "Yes";
    this.checkChildsforCheckbox(targetNode, targetNodeId, chkType, treeID);
    this.checkParentforCheckbox(targetNode, targetNodeId, chkType, treeID);
    let { roleforTreeView0 } = this.state;
    this.setState({ roleforTreeView0 });
    let roleID = event.target.value.split(":")[0];
    this.handleSave(chkType, roleID);
  }
  handleSave = (chkType, roleID) => {
    let request = {} as ISaveRoleMatrixDto;
    request.PasswordStateChange = "No";
    request.RoleID = Number(roleID);

    let addPermissionIDs = Array.from(new Set(addPIDforDave));
    let removePermissionIDs = Array.from(new Set(removePIDforSave));

    let allRemoveIds = "";
    let allAddIDs = "";
    if (chkType === "Yes") {
      for (let i = 0; i < addPermissionIDs.length; i++) {
        allAddIDs += addPermissionIDs[i].trim() + ",";
      }
    } else {
      for (let index = 0; index < removePermissionIDs.length; index++) {
        allRemoveIds += removePermissionIDs[index].trim() + ",";
      }
    }

    request.AddPermissionIDList = allAddIDs;
    request.RemovePermissionIDList = allRemoveIds;
    request.RoleTag = "";
    request.UpdateRoleName = "";
    ;
    RoleMatrixClass.saveRoleMatrixData(request).then(async (result: any | null) => {
      ;
      console.log(result);
      if (result !== null) {
        if (result.success) {
          let msg = "Permission updated successfully.";
          toast.success(msg, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "RoleMatrix",
          });
        } else {
          Utils.toastError("Error " + result.result, {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "RoleMatrix",
          });
        }
      }

      this.setState({ loader: false });
      resolve();
    })
      .catch((error) => {
        this.setState({ loader: false });
        reject();
      });
  };
  showchilds = (e: any, targetNode: any) => {
    const targetNodeId = targetNode.id;

    if (targetNode.id === targetNodeId)
      if (targetNode.isExpend === false) {
        targetNode.isExpend = true;
        targetNode.isRotate = "isRotated";
      } else {
        targetNode.isExpend = false;
        targetNode.isRotate = "";
      }

    let { userRoleforTreeView } = this.state;
    let checkData = [...this.state.tNodes];
    for (let index = 0; index < checkData.length; index++) {
      this.checkAllExpandorCollapse(checkData[index]);

    }
    this.checkAllExpandorCollapse2(targetNode);
    this.setState({ userRoleforTreeView });
    scrollIntoView(e.currentTarget, { align: { top: 0, topOffset: 115 } });
  };

  checkAllExpandorCollapse(obj) {
    if (obj.children.length > 0) {
      for (let i = 0; i < obj.children.length; i++) {
        // if (obj.isExpend === true) {
        //   this.setState({ expandcollapse: "- Collapse All" });
        //   return;
        // } else {
        //   this.setState({ expandcollapse: "+ Expand All" });
        // }
        this.checkAllExpandorCollapse(obj.children[i]);
      }
    }
  }

  checkAllExpandorCollapse2 = (targetNode) => {
    let id;
    let checkData = [...this.state.tNodes];
    for (let index = 0; index < checkData.length; index++) {
      id = checkData[index].id;
      if (id === targetNode.id) {
        if (checkData[index].isExpend === true) {
          this.setState({ expandcollapse: "- Collapse All" });
          return;
        } else {
          this.setState({ expandcollapse: "+ Expand All" });
        }
      }
    }
  }

  handleFilterRoleOutSide = () => {
    this.setState({ roleFilter: "", userRoleList: this.state.oldUserRoleList });
    // console.log(this.state.userRoleList);
  };

  render() {
    let {
      userRoleforTreeView,
      roleforTreeView0,
      roleforTreeView1,
      roleforTreeView2,
      roleforTreeView3,
      roleforTreeView4,
      roleforTreeView5,
      userRoleList,
      selectedRoles,
      arrowIcon,
      accordionToggle,
      //scrollToFix,
    } = this.state;

    let { scrollToFix } = this.props;

    return (
      <>
        <div
        //className="RollMatrixScroll"
        //ref={this.scrollRef}
        >
          <PageLoader />

          {/* <ToastContainer autoClose={3000} containerId={"RoleMatrix"} /> */}
          {this.state.loader && (
            <div className="matrixSpinnerCenter">
              <Spinner animation="border" variant="secondary" />
            </div>
          )}
          <div className="main-Page">
            {/* {!scrollToFix && ()} */}

            <div className="d-flex">
              <div className="position-filter">
                <div className="rolePermissionSearch">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33317 15.0001C9.8715 15.0001 11.2857 14.4709 12.414 13.5934L16.0773 17.2567L17.2557 16.0784L13.5923 12.4151C14.4707 11.2859 14.9998 9.87175 14.9998 8.33342C14.9998 4.65758 12.009 1.66675 8.33317 1.66675C4.65734 1.66675 1.6665 4.65758 1.6665 8.33342C1.6665 12.0092 4.65734 15.0001 8.33317 15.0001ZM8.33317 3.33341C11.0907 3.33341 13.3332 5.57591 13.3332 8.33342C13.3332 11.0909 11.0907 13.3334 8.33317 13.3334C5.57567 13.3334 3.33317 11.0909 3.33317 8.33342C3.33317 5.57591 5.57567 3.33341 8.33317 3.33341Z"
                      fill="#84888C"
                    ></path>
                  </svg>

                  <input
                    className="form-control"
                    placeholder="Filter Permissions by Name"
                    type="text"
                    onChange={this.onFilterChange.bind(this)}
                    value={this.state.filterText}
                  />
                </div>
              </div>
              <div className="mr-auto department-filter ">
                <Dropdown className="more-action bg-blue dropdown rolePermissiondropdown">
                  <Dropdown.Toggle id="rolesList">
                    Roles
                    <span className="cusBadge badgePrimary">
                      {selectedRoles.length}
                    </span>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </Dropdown.Toggle>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      this.handleFilterRoleOutSide();
                    }}
                  >
                    <Dropdown.Menu className="rolePermissionSelector">
                      <div className="roleFilter">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            name="searchRoles"
                            value={this.state.roleFilter}
                            autoComplete="off"
                            onChange={this.searchRoleItems}
                            placeholder="Filter Roles"
                          />
                        </Form.Group>
                      </div>
                      <div className="dropdownItems">
                        {userRoleList.length > 0 &&
                          userRoleList.map((items, index) => (
                            <div
                              //className="roleListItems"
                              className="dropdown-item"
                              key={index}
                            >
                              <div className={selectedRoles.length === 6 && !items.isChecked ? "roleListDisabled" : ''}>
                                <EllipsisWithTooltip placement="bottom">
                                  <Form.Check
                                    type="checkbox"
                                    id={`list_${items.roleID}`}
                                    name={items.roleName.toLowerCase()}
                                    label={items.roleName}
                                    value={items.roleID}
                                    onChange={(event) =>
                                      this.changeRole(event, items)
                                    }
                                    checked={items.isChecked}
                                  />
                                </EllipsisWithTooltip>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Dropdown.Menu>
                  </OutsideClickHandler>
                </Dropdown>
              </div>
              <div className="navigationSort ml-auto">
                <div className="expendCollapse">
                  <Button
                    disabled={userRoleforTreeView.length > 0 ? false : true}
                    onClick={() => this.collapseexpand()}
                  >
                    {this.state.expandcollapse}
                  </Button>
                </div>
              </div>
            </div>

            <div className="rollMatrixPanel">
              <div className="rollMatrixPanelScroll">
                <div
                  className={scrollToFix ? "cusRow cusRowFullWidth" : "cusRow"}
                >
                  <div className="column6">
                    <div
                      className={`${scrollToFix
                        ? "rollMatrixAccordionHeader fullWidthHeader hasSearchRoles"
                        : "rollMatrixAccordionHeader"
                        }`}
                    >
                      <div className="colPadd">
                        {scrollToFix && (
                          <div className="d-flex">
                            <div className="position-filter">
                              <div className="rolePermissionSearch fullWidthSearchBar">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.33317 15.0001C9.8715 15.0001 11.2857 14.4709 12.414 13.5934L16.0773 17.2567L17.2557 16.0784L13.5923 12.4151C14.4707 11.2859 14.9998 9.87175 14.9998 8.33342C14.9998 4.65758 12.009 1.66675 8.33317 1.66675C4.65734 1.66675 1.6665 4.65758 1.6665 8.33342C1.6665 12.0092 4.65734 15.0001 8.33317 15.0001ZM8.33317 3.33341C11.0907 3.33341 13.3332 5.57591 13.3332 8.33342C13.3332 11.0909 11.0907 13.3334 8.33317 13.3334C5.57567 13.3334 3.33317 11.0909 3.33317 8.33342C3.33317 5.57591 5.57567 3.33341 8.33317 3.33341Z"
                                    fill="#84888C"
                                  ></path>
                                </svg>

                                <input
                                  className="form-control"
                                  placeholder="Filter Permissions by Name"
                                  type="text"
                                  onChange={this.onFilterChange.bind(this)}
                                  value={this.state.filterText}
                                />
                              </div>
                            </div>
                            <div className="mr-auto department-filter ">
                              <Dropdown className="more-action bg-blue dropdown rolePermissiondropdown">
                                <Dropdown.Toggle id="rolesList">
                                  Roles{" "}
                                  <span className="cusBadge badgePrimary">
                                    {selectedRoles.length}
                                  </span>
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="rolePermissionSelector">
                                  <div className="roleFilter">
                                    <Form.Group controlId="formBasicEmail">
                                      <Form.Control
                                        type="text"
                                        name="searchRoles"
                                        value={this.state.roleFilter}
                                        autoComplete="off"
                                        onChange={this.searchRoleItems}
                                        placeholder="Filter Roles"
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="dropdownItems">
                                    {userRoleList.length > 0 &&
                                      userRoleList.map((items, index) => (
                                        <div
                                          className="dropdown-item"
                                          key={index}
                                        >
                                          <div className={selectedRoles.length === 6 && !items.isChecked ? "roleListDisabled" : ''}>
                                            <EllipsisWithTooltip placement="bottom">
                                              <Form.Check
                                                type="checkbox"
                                                id={`list_${items.roleID}`}
                                                name={items.roleName}
                                                label={items.roleName}
                                                value={items.roleID}
                                                onChange={(event) =>
                                                  this.changeRole(event, items)
                                                }
                                                checked={items.isChecked}
                                              />
                                            </EllipsisWithTooltip>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <ul className="rollAccordion mainAccordion">
                        {userRoleforTreeView === 0
                          ? "Loading"
                          : userRoleforTreeView.map((items, index) => (
                            <li
                              key={index}
                              className={`${items.isExpend | accordionToggle
                                ? "matrixBottomShaddow"
                                : ""
                                }`}
                            >
                              <Card.Header
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.showchilds(e, items);
                                }}
                              // className={
                              //   scrollToFix === true
                              //     ? "fullWidthHeader"
                              //     : ""
                              // }
                              >
                                <div
                                  className={
                                    scrollToFix === true
                                      ? "cardMainHeader cardMainHeaderFullWidth"
                                      : "cardMainHeader"
                                  }
                                >
                                  {items.isShowIcon && (
                                    <div className="hasArrow">
                                      <img
                                        id={items.menuName}
                                        className={`${items.isExpend | accordionToggle
                                          ? "isRotated"
                                          : ""
                                          }`}
                                        src={arrowIcon}
                                        alt="arrowIcon"
                                      />
                                    </div>
                                  )}

                                  <div className="hasContent">
                                    <div
                                      //ref= {items.menuName}  //{this.moduleNameRef}

                                      key={items.menuName}
                                      //defaultValue={items.menuName}
                                      className={items.backColorClass}
                                    >
                                      {items.menuName}
                                    </div>
                                    <span>
                                      {items.modulePermisisonCount - 1}{" "}
                                      Permissions
                                    </span>
                                  </div>
                                </div>
                              </Card.Header>
                              <ul
                                className={
                                  items.isExpend || accordionToggle
                                    ? "collapse show"
                                    : "collapse"
                                }
                              >
                                {items.children.map((child, idx1) => (
                                  <li key={idx1}>
                                    {child.children.length > 0 ? (
                                      <>
                                        <Card.Header
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            this.showchilds(e, child);
                                          }}
                                        >
                                          <div className="cardHeader">
                                            <div className="hasArrow">
                                              <img
                                                className={`${child.isExpend ||
                                                  accordionToggle
                                                  ? "isRotated"
                                                  : ""
                                                  }`}
                                                src={arrowIcon}
                                                alt="arrowIcon"
                                              />
                                            </div>
                                            <div className="hasContent hasSensitive">
                                              <span
                                                className={
                                                  child.backColorClass
                                                }
                                              >
                                                {child.menuName}
                                              </span>
                                              {child.isSensetive !== "0"
                                                ? this.badgeSensitive()
                                                : ""}{" "}
                                              {child.helpText === ""
                                                ? null
                                                : this.helperText(
                                                  child.helpText
                                                )}
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <>
                                          <ul
                                            id={child.isExpend}
                                            className={
                                              child.isExpend ||
                                                accordionToggle
                                                ? "collapse show"
                                                : "collapse"
                                            }
                                          >
                                            {child.children.map(
                                              (child, idx2) => (
                                                <li key={idx2}>
                                                  {child.children.length >
                                                    0 ? (
                                                    <>
                                                      <Card.Header
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          this.showchilds(
                                                            e,
                                                            child
                                                          );
                                                        }}
                                                      >
                                                        <div className="cardHeader">
                                                          <div className="hasArrow">
                                                            <img
                                                              className={`${child.isExpend ||
                                                                accordionToggle
                                                                ? "isRotated"
                                                                : ""
                                                                }`}
                                                              src={arrowIcon}
                                                              alt="arrowIcon"
                                                            />
                                                          </div>
                                                          <div className="hasContent hasSensitive">
                                                            <span
                                                              className={
                                                                child.backColorClass
                                                              }
                                                            >
                                                              {child.menuName}
                                                            </span>
                                                            {child.isSensetive !==
                                                              "0"
                                                              ? this.badgeSensitive()
                                                              : ""}{" "}
                                                            {child.helpText ===
                                                              ""
                                                              ? null
                                                              : this.helperText(
                                                                child.helpText
                                                              )}
                                                          </div>
                                                        </div>
                                                      </Card.Header>

                                                      <ul
                                                        id={child.isExpend}
                                                        className={
                                                          child.isExpend ||
                                                            accordionToggle
                                                            ? "collapse show"
                                                            : "collapse"
                                                        }
                                                      >
                                                        {child.children.map(
                                                          (child, idx3) => (
                                                            <li key={idx3}>
                                                              {child.children
                                                                .length >
                                                                0 ? (
                                                                <>
                                                                  <Card.Header
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      this.showchilds(
                                                                        e,
                                                                        child
                                                                      );
                                                                    }}
                                                                  >
                                                                    <div className="cardHeader">
                                                                      <div className="hasArrow">
                                                                        <img
                                                                          className={`${child.isExpend ||
                                                                            accordionToggle
                                                                            ? "isRotated"
                                                                            : ""
                                                                            }`}
                                                                          src={
                                                                            arrowIcon
                                                                          }
                                                                          alt="arrowIcon"
                                                                        />
                                                                      </div>
                                                                      <div className="hasContent hasSensitive">
                                                                        <span
                                                                          className={
                                                                            child.backColorClass
                                                                          }
                                                                        >
                                                                          {
                                                                            child.menuName
                                                                          }
                                                                        </span>
                                                                        {child.isSensetive !==
                                                                          "0"
                                                                          ? this.badgeSensitive()
                                                                          : ""}{" "}
                                                                        {child.helpText ===
                                                                          ""
                                                                          ? null
                                                                          : this.helperText(
                                                                            child.helpText
                                                                          )}
                                                                      </div>
                                                                    </div>
                                                                  </Card.Header>
                                                                  <ul
                                                                    id={
                                                                      child.isExpend
                                                                    }
                                                                    className={
                                                                      child.isExpend ||
                                                                        accordionToggle
                                                                        ? "collapse show"
                                                                        : "collapse"
                                                                    }
                                                                  >
                                                                    {child.children.map(
                                                                      (
                                                                        child,
                                                                        idx4
                                                                      ) => (
                                                                        <li
                                                                          key={
                                                                            idx4
                                                                          }
                                                                        >
                                                                          {child
                                                                            .children
                                                                            .length >
                                                                            0 ? (
                                                                            <>
                                                                              <Card.Header
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  e.stopPropagation();
                                                                                  this.showchilds(
                                                                                    e,
                                                                                    child
                                                                                  );
                                                                                }}
                                                                              >
                                                                                <div className="cardHeader">
                                                                                  <div className="hasArrow">
                                                                                    <img
                                                                                      className={`${child.isExpend ||
                                                                                        accordionToggle
                                                                                        ? "isRotated"
                                                                                        : ""
                                                                                        }`}
                                                                                      src={
                                                                                        arrowIcon
                                                                                      }
                                                                                      alt="arrowIcon"
                                                                                    />
                                                                                  </div>
                                                                                  <div className="hasContent hasSensitive">
                                                                                    <span
                                                                                      className={
                                                                                        child.backColorClass
                                                                                      }
                                                                                    >
                                                                                      {
                                                                                        child.menuName
                                                                                      }
                                                                                    </span>
                                                                                    {child.isSensetive !==
                                                                                      "0"
                                                                                      ? this.badgeSensitive()
                                                                                      : ""}{" "}
                                                                                    {child.helpText ===
                                                                                      ""
                                                                                      ? null
                                                                                      : this.helperText(
                                                                                        child.helpText
                                                                                      )}
                                                                                  </div>
                                                                                </div>
                                                                              </Card.Header>
                                                                              <ul
                                                                                id={
                                                                                  child.isExpend
                                                                                }
                                                                                className={
                                                                                  child.isExpend ||
                                                                                    accordionToggle
                                                                                    ? "collapse show"
                                                                                    : "collapse"
                                                                                }
                                                                              >
                                                                                {child.children.map(
                                                                                  (
                                                                                    child,
                                                                                    idx5
                                                                                  ) => (
                                                                                    <li
                                                                                      key={
                                                                                        idx5
                                                                                      }
                                                                                    >
                                                                                      {child
                                                                                        .children
                                                                                        .length >
                                                                                        0 ? (
                                                                                        <>
                                                                                          <Card.Header
                                                                                            onClick={(
                                                                                              e
                                                                                            ) => {
                                                                                              e.stopPropagation();
                                                                                              this.showchilds(
                                                                                                e,
                                                                                                child
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            <div className="cardHeader">
                                                                                              <div className="hasArrow">
                                                                                                <img
                                                                                                  className={`${child.isExpend ||
                                                                                                    accordionToggle
                                                                                                    ? "isRotated"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                  src={
                                                                                                    arrowIcon
                                                                                                  }
                                                                                                  alt="arrowIcon"
                                                                                                />
                                                                                              </div>
                                                                                              <div className="hasContent hasSensitive">
                                                                                                <span
                                                                                                  className={
                                                                                                    child.backColorClass
                                                                                                  }
                                                                                                >
                                                                                                  {
                                                                                                    child.menuName
                                                                                                  }
                                                                                                </span>
                                                                                                {child.isSensetive !==
                                                                                                  "0"
                                                                                                  ? this.badgeSensitive()
                                                                                                  : ""}{" "}
                                                                                                {child.helpText ===
                                                                                                  ""
                                                                                                  ? null
                                                                                                  : this.helperText(
                                                                                                    child.helpText
                                                                                                  )}
                                                                                              </div>
                                                                                            </div>
                                                                                          </Card.Header>
                                                                                          <ul
                                                                                            id={
                                                                                              child.isExpend
                                                                                            }
                                                                                            className={
                                                                                              child.isExpend ||
                                                                                                accordionToggle
                                                                                                ? "collapse show"
                                                                                                : "collapse"
                                                                                            }
                                                                                          >
                                                                                            {child.children.map(
                                                                                              (
                                                                                                child,
                                                                                                idx6
                                                                                              ) => (
                                                                                                <li
                                                                                                  key={
                                                                                                    idx6
                                                                                                  }
                                                                                                >
                                                                                                  {child
                                                                                                    .children
                                                                                                    .length >
                                                                                                    0 ? (
                                                                                                    <>
                                                                                                      <Card.Header
                                                                                                        onClick={(
                                                                                                          e
                                                                                                        ) => {
                                                                                                          e.stopPropagation();
                                                                                                          this.showchilds(
                                                                                                            e,
                                                                                                            child
                                                                                                          );
                                                                                                        }}
                                                                                                      >
                                                                                                        <div className="cardHeader">
                                                                                                          <div className="hasArrow">
                                                                                                            <img
                                                                                                              className={`${child.isExpend ||
                                                                                                                accordionToggle
                                                                                                                ? "isRotated"
                                                                                                                : ""
                                                                                                                }`}
                                                                                                              src={
                                                                                                                arrowIcon
                                                                                                              }
                                                                                                              alt="arrowIcon"
                                                                                                            />
                                                                                                          </div>
                                                                                                          <div className="hasContent hasSensitive">
                                                                                                            <span
                                                                                                              className={
                                                                                                                child.backColorClass
                                                                                                              }
                                                                                                            >
                                                                                                              {
                                                                                                                child.menuName
                                                                                                              }
                                                                                                            </span>
                                                                                                            {child.isSensetive !==
                                                                                                              "0"
                                                                                                              ? this.badgeSensitive()
                                                                                                              : ""}{" "}
                                                                                                            {child.helpText ===
                                                                                                              ""
                                                                                                              ? null
                                                                                                              : this.helperText(
                                                                                                                child.helpText
                                                                                                              )}
                                                                                                          </div>
                                                                                                        </div>
                                                                                                      </Card.Header>
                                                                                                      <ul
                                                                                                        id={
                                                                                                          child.isExpend
                                                                                                        }
                                                                                                        className={
                                                                                                          child.isExpend ||
                                                                                                            accordionToggle
                                                                                                            ? "collapse show"
                                                                                                            : "collapse"
                                                                                                        }
                                                                                                      >
                                                                                                        {child.children.map(
                                                                                                          (
                                                                                                            child,
                                                                                                            idx7
                                                                                                          ) => (
                                                                                                            <li
                                                                                                              key={
                                                                                                                idx7
                                                                                                              }
                                                                                                            >
                                                                                                              {child
                                                                                                                .children
                                                                                                                .length >
                                                                                                                0 ? (
                                                                                                                <>
                                                                                                                  <Card.Header>
                                                                                                                    <div className="cardHeader">
                                                                                                                      <div className="hasArrow">
                                                                                                                        <img
                                                                                                                          className={`${child.isExpend ||
                                                                                                                            accordionToggle
                                                                                                                            ? "isRotated"
                                                                                                                            : ""
                                                                                                                            }`}
                                                                                                                          src={
                                                                                                                            arrowIcon
                                                                                                                          }
                                                                                                                          alt="arrowIcon"
                                                                                                                        />
                                                                                                                      </div>
                                                                                                                      <div className="hasContent hasSensitive">
                                                                                                                        <span
                                                                                                                          className={
                                                                                                                            child.backColorClass
                                                                                                                          }
                                                                                                                        >
                                                                                                                          {
                                                                                                                            child.menuName
                                                                                                                          }
                                                                                                                        </span>
                                                                                                                        {child.isSensetive !==
                                                                                                                          "0"
                                                                                                                          ? this.badgeSensitive()
                                                                                                                          : ""}{" "}
                                                                                                                        {child.helpText ===
                                                                                                                          ""
                                                                                                                          ? null
                                                                                                                          : this.helperText(
                                                                                                                            child.helpText
                                                                                                                          )}
                                                                                                                      </div>
                                                                                                                    </div>
                                                                                                                  </Card.Header>
                                                                                                                  <div
                                                                                                                    className={
                                                                                                                      child.isExpend ||
                                                                                                                        accordionToggle
                                                                                                                        ? "collapse show"
                                                                                                                        : "collapse"
                                                                                                                    }
                                                                                                                  >
                                                                                                                    <>

                                                                                                                    </>
                                                                                                                  </div>
                                                                                                                </>
                                                                                                              ) : (
                                                                                                                <Card.Header>
                                                                                                                  <div className="cardHeader">
                                                                                                                    <div className="hasContent hasSensitive">
                                                                                                                      <span
                                                                                                                        className={
                                                                                                                          child.backColorClass
                                                                                                                        }
                                                                                                                      >
                                                                                                                        {
                                                                                                                          child.menuName
                                                                                                                        }
                                                                                                                      </span>
                                                                                                                      {child.isSensetive !==
                                                                                                                        "0"
                                                                                                                        ? this.badgeSensitive()
                                                                                                                        : ""}{" "}
                                                                                                                      {child.helpText ===
                                                                                                                        ""
                                                                                                                        ? null
                                                                                                                        : this.helperText(
                                                                                                                          child.helpText
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                  </div>
                                                                                                                </Card.Header>
                                                                                                              )}
                                                                                                            </li>
                                                                                                          )
                                                                                                        )}
                                                                                                      </ul>
                                                                                                    </>
                                                                                                  ) : (
                                                                                                    <Card.Header>
                                                                                                      <div className="cardHeader singleNode">
                                                                                                        <div className="hasContent hasSensitive">
                                                                                                          <span
                                                                                                            className={
                                                                                                              child.backColorClass
                                                                                                            }
                                                                                                          >
                                                                                                            {
                                                                                                              child.menuName
                                                                                                            }
                                                                                                          </span>
                                                                                                          {child.isSensetive !==
                                                                                                            "0"
                                                                                                            ? this.badgeSensitive()
                                                                                                            : ""}{" "}
                                                                                                          {child.helpText ===
                                                                                                            ""
                                                                                                            ? null
                                                                                                            : this.helperText(
                                                                                                              child.helpText
                                                                                                            )}
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    </Card.Header>
                                                                                                  )}
                                                                                                </li>
                                                                                              )
                                                                                            )}
                                                                                          </ul>
                                                                                        </>
                                                                                      ) : (
                                                                                        <Card.Header>
                                                                                          <div className="cardHeader singleNode">
                                                                                            <div className="hasContent hasSensitive">
                                                                                              <span
                                                                                                className={
                                                                                                  child.backColorClass
                                                                                                }
                                                                                              >
                                                                                                {
                                                                                                  child.menuName
                                                                                                }
                                                                                              </span>
                                                                                              {child.isSensetive !==
                                                                                                "0"
                                                                                                ? this.badgeSensitive()
                                                                                                : ""}{" "}
                                                                                              {child.helpText ===
                                                                                                ""
                                                                                                ? null
                                                                                                : this.helperText(
                                                                                                  child.helpText
                                                                                                )}
                                                                                            </div>
                                                                                          </div>
                                                                                        </Card.Header>
                                                                                      )}
                                                                                    </li>
                                                                                  )
                                                                                )}
                                                                              </ul>
                                                                            </>
                                                                          ) : (
                                                                            <Card.Header>
                                                                              <div className="cardHeader singleNode">
                                                                                <div className="hasContent hasSensitive">
                                                                                  <span
                                                                                    className={
                                                                                      child.backColorClass
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      child.menuName
                                                                                    }
                                                                                  </span>
                                                                                  {child.isSensetive !==
                                                                                    "0"
                                                                                    ? this.badgeSensitive()
                                                                                    : ""}{" "}
                                                                                  {child.helpText ===
                                                                                    ""
                                                                                    ? null
                                                                                    : this.helperText(
                                                                                      child.helpText
                                                                                    )}
                                                                                </div>
                                                                              </div>
                                                                            </Card.Header>
                                                                          )}
                                                                        </li>
                                                                      )
                                                                    )}
                                                                  </ul>
                                                                </>
                                                              ) : (
                                                                <Card.Header>
                                                                  <div className="cardHeader singleNode">
                                                                    <div className="hasContent hasSensitive">
                                                                      <span
                                                                        className={
                                                                          child.backColorClass
                                                                        }
                                                                      >
                                                                        {
                                                                          child.menuName
                                                                        }
                                                                      </span>
                                                                      {child.isSensetive !==
                                                                        "0"
                                                                        ? this.badgeSensitive()
                                                                        : ""}{" "}
                                                                      {child.helpText ===
                                                                        ""
                                                                        ? null
                                                                        : this.helperText(
                                                                          child.helpText
                                                                        )}
                                                                    </div>
                                                                  </div>
                                                                </Card.Header>
                                                              )}
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    </>
                                                  ) : (
                                                    <Card.Header>
                                                      <div className="cardHeader singleNode">
                                                        <div className="hasContent hasSensitive">
                                                          <span
                                                            className={
                                                              child.backColorClass
                                                            }
                                                          >
                                                            {child.menuName}
                                                          </span>
                                                          {child.isSensetive !==
                                                            "0"
                                                            ? this.badgeSensitive()
                                                            : ""}{" "}
                                                          {child.helpText ===
                                                            ""
                                                            ? null
                                                            : this.helperText(
                                                              child.helpText
                                                            )}
                                                        </div>
                                                      </div>
                                                    </Card.Header>
                                                  )}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </>
                                      </>
                                    ) : (
                                      <Card.Header className="hasNoChild singleNode">
                                        <div className="cardHeader">
                                          <div className="hasContent hasSensitive">
                                            <span
                                              className={child.backColorClass}
                                            >
                                              {child.menuName}
                                            </span>
                                            {child.isSensetive !== "0"
                                              ? this.badgeSensitive()
                                              : ""}{" "}
                                            {child.helpText === ""
                                              ? null
                                              : this.helperText(
                                                child.helpText
                                              )}
                                          </div>
                                        </div>
                                      </Card.Header>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  {selectedRoles.length > 0 && (
                    <div className="column1">
                      <div className="rollMatrixAccordionHeader">
                        <div className="colPadd text-center">
                          <div className="heading">
                            <EllipsisWithTooltip placement="bottom">
                              {selectedRoles.length > 0 ? (
                                selectedRoles[0].roleName
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </EllipsisWithTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <ul className="rollAccordion listAccordion">
                          {roleforTreeView0.map((items, index) => (
                            <li
                              key={index}
                              className={`${userRoleforTreeView[index].isExpend |
                                accordionToggle
                                ? "matrixBottomShaddow"
                                : ""
                                }`}
                            >
                              <Card.Header>
                                <div
                                  className={`${selectedRoles.length > 0 &&
                                    (selectedRoles[0].roleName ===
                                      "Administrator"
                                      ? "matrixAssignedPanel matrixhasAdmin"
                                      : "matrixAssignedPanel")
                                    }`}
                                >
                                  <div className="content border_l">
                                    <div className="content-heading">
                                      {items.permisionCount === "0"
                                        ? items.permisionCount
                                        : items.permisionCount - 1}{" "}
                                      / {items.modulePermisisonCount - 1}
                                    </div>
                                    <span className="content-subhead">
                                      Assigned
                                    </span>
                                  </div>
                                </div>
                              </Card.Header>
                              <ul
                                className={
                                  userRoleforTreeView[index].isExpend ||
                                    accordionToggle
                                    ? "collapse show border_l border_r"
                                    : "collapse"
                                }
                              >
                                {items.children.map((child, idx1) => (
                                  <li>
                                    {child.children.length > 0 ? (
                                      <>
                                        <Card.Header>
                                          <div className="hasCheckBox cardHeader">
                                            <label className="form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={
                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                    ? true
                                                    : false
                                                }
                                                value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                disabled={
                                                  selectedRoles.length >
                                                  0 &&
                                                  (selectedRoles[0]
                                                    .roleName ===
                                                    "Administrator" || child.isSpecial === "1")
                                                }
                                                onChange={(e: any) => {
                                                  this.checkChange(
                                                    child,
                                                    e,
                                                    child.treeID
                                                  );
                                                }}
                                              ></input>
                                              <span></span>
                                            </label>
                                          </div>
                                        </Card.Header>
                                        <ul
                                          className={
                                            userRoleforTreeView[index].children[
                                              idx1
                                            ].isExpend || accordionToggle
                                              ? "collapse show"
                                              : "collapse"
                                          }
                                        >
                                          {child.children.map((child, idx2) => (
                                            <li key={idx2}>
                                              {child.children.length > 0 ? (
                                                <>
                                                  <Card.Header>
                                                    <div className="hasCheckBox cardHeader">
                                                      <label className="form-check">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          checked={
                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                              ? true
                                                              : false
                                                          }
                                                          value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                          disabled={
                                                            selectedRoles.length >
                                                            0 &&
                                                            (selectedRoles[0]
                                                              .roleName ===
                                                              "Administrator" || child.isSpecial === "1")
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            e.stopPropagation();
                                                            this.checkChange(
                                                              child,
                                                              e,
                                                              child.treeID
                                                            );
                                                          }}
                                                        ></input>
                                                        <span></span>
                                                        <span></span>
                                                      </label>
                                                    </div>
                                                  </Card.Header>
                                                  <ul
                                                    className={
                                                      userRoleforTreeView[index]
                                                        .children[idx1]
                                                        .children[idx2]
                                                        .isExpend ||
                                                        accordionToggle
                                                        ? "collapse show"
                                                        : "collapse"
                                                    }
                                                  >
                                                    {child.children.map(
                                                      (child, idx3) => (
                                                        <li key={idx3}>
                                                          {child.children
                                                            .length > 0 ? (
                                                            <>
                                                              <Card.Header>
                                                                <div className="hasCheckBox cardHeader">
                                                                  <label className="form-check">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="form-check-input"
                                                                      checked={
                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                                      disabled={
                                                                        selectedRoles.length >
                                                                        0 &&
                                                                        (selectedRoles[0]
                                                                          .roleName ===
                                                                          "Administrator" || child.isSpecial === "1")
                                                                      }
                                                                      onChange={(
                                                                        e: any
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                        this.checkChange(
                                                                          child,
                                                                          e,
                                                                          child.treeID
                                                                        );
                                                                      }}
                                                                    ></input>
                                                                    <span></span>
                                                                    <span></span>
                                                                  </label>
                                                                </div>
                                                              </Card.Header>
                                                              <ul
                                                                className={
                                                                  userRoleforTreeView[
                                                                    index
                                                                  ].children[
                                                                    idx1
                                                                  ].children[
                                                                    idx2
                                                                  ].children[
                                                                    idx3
                                                                  ].isExpend ||
                                                                    accordionToggle
                                                                    ? "collapse show"
                                                                    : "collapse"
                                                                }
                                                              >
                                                                {child.children.map(
                                                                  (
                                                                    child,
                                                                    idx4
                                                                  ) => (
                                                                    <li
                                                                      key={idx4}
                                                                    >
                                                                      {child
                                                                        .children
                                                                        .length >
                                                                        0 ? (
                                                                        <>
                                                                          <Card.Header>
                                                                            <div className="hasCheckBox cardHeader">
                                                                              <label className="form-check">
                                                                                <input
                                                                                  type="checkbox"
                                                                                  className="form-check-input"
                                                                                  checked={
                                                                                    child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                      ? true
                                                                                      : false
                                                                                  }
                                                                                  value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                                                  disabled={
                                                                                    selectedRoles.length >
                                                                                    0 &&
                                                                                    (selectedRoles[0]
                                                                                      .roleName ===
                                                                                      "Administrator" || child.isSpecial === "1")
                                                                                  }
                                                                                  onChange={(
                                                                                    e: any
                                                                                  ) => {
                                                                                    e.stopPropagation();
                                                                                    this.checkChange(
                                                                                      child,
                                                                                      e,
                                                                                      child.treeID
                                                                                    );
                                                                                  }}
                                                                                ></input>
                                                                                <span></span>
                                                                                <span></span>
                                                                              </label>
                                                                            </div>
                                                                          </Card.Header>
                                                                          <ul
                                                                            className={
                                                                              userRoleforTreeView[
                                                                                index
                                                                              ]
                                                                                .children[
                                                                                idx1
                                                                              ]
                                                                                .children[
                                                                                idx2
                                                                              ]
                                                                                .children[
                                                                                idx3
                                                                              ]
                                                                                .children[
                                                                                idx4
                                                                              ]
                                                                                .isExpend ||
                                                                                accordionToggle
                                                                                ? "collapse show"
                                                                                : "collapse"
                                                                            }
                                                                          >
                                                                            {child.children.map(
                                                                              (
                                                                                child,
                                                                                idx5
                                                                              ) => (
                                                                                <li
                                                                                  key={
                                                                                    idx5
                                                                                  }
                                                                                >
                                                                                  {child
                                                                                    .children
                                                                                    .length >
                                                                                    0 ? (
                                                                                    <>
                                                                                      <Card.Header>
                                                                                        <div className="hasCheckBox cardHeader">
                                                                                          <label className="form-check">
                                                                                            <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              checked={
                                                                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                  ? true
                                                                                                  : false
                                                                                              }
                                                                                              value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                                                              disabled={
                                                                                                selectedRoles.length >
                                                                                                0 &&
                                                                                                (selectedRoles[0]
                                                                                                  .roleName ===
                                                                                                  "Administrator" || child.isSpecial === "1")
                                                                                              }
                                                                                              onChange={(
                                                                                                e: any
                                                                                              ) => {
                                                                                                e.stopPropagation();
                                                                                                this.checkChange(
                                                                                                  child,
                                                                                                  e,
                                                                                                  child.treeID
                                                                                                );
                                                                                              }}
                                                                                            ></input>
                                                                                            <span></span>
                                                                                          </label>
                                                                                        </div>
                                                                                      </Card.Header>
                                                                                      <ul
                                                                                        className={
                                                                                          userRoleforTreeView[
                                                                                            index
                                                                                          ]
                                                                                            .children[
                                                                                            idx1
                                                                                          ]
                                                                                            .children[
                                                                                            idx2
                                                                                          ]
                                                                                            .children[
                                                                                            idx3
                                                                                          ]
                                                                                            .children[
                                                                                            idx4
                                                                                          ]
                                                                                            .children[
                                                                                            idx5
                                                                                          ]
                                                                                            .isExpend ||
                                                                                            accordionToggle
                                                                                            ? "collapse show"
                                                                                            : "collapse"
                                                                                        }
                                                                                      >
                                                                                        {child.children.map(
                                                                                          (
                                                                                            child,
                                                                                            idx6
                                                                                          ) => (
                                                                                            <li
                                                                                              key={
                                                                                                idx6
                                                                                              }
                                                                                            >
                                                                                              {child
                                                                                                .children
                                                                                                .length >
                                                                                                0 ? (
                                                                                                <>
                                                                                                  <Card.Header>
                                                                                                    <div className="hasCheckBox cardHeader">
                                                                                                      <label className="form-check">
                                                                                                        <input
                                                                                                          type="checkbox"
                                                                                                          className="form-check-input"
                                                                                                          checked={
                                                                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                              ? true
                                                                                                              : false
                                                                                                          }
                                                                                                          value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                                                                          disabled={
                                                                                                            selectedRoles.length >
                                                                                                            0 &&
                                                                                                            (selectedRoles[0]
                                                                                                              .roleName ===
                                                                                                              "Administrator" || child.isSpecial === "1")
                                                                                                          }
                                                                                                          onChange={(
                                                                                                            e: any
                                                                                                          ) => {
                                                                                                            e.stopPropagation();
                                                                                                            this.checkChange(
                                                                                                              child,
                                                                                                              e,
                                                                                                              child.treeID
                                                                                                            );
                                                                                                          }}
                                                                                                        ></input>
                                                                                                        <span></span>
                                                                                                        <span></span>
                                                                                                      </label>
                                                                                                    </div>
                                                                                                  </Card.Header>
                                                                                                  <ul
                                                                                                    className={
                                                                                                      userRoleforTreeView[
                                                                                                        index
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx1
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx2
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx3
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx4
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx5
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx6
                                                                                                      ]
                                                                                                        .isExpend ||
                                                                                                        accordionToggle
                                                                                                        ? "collapse show"
                                                                                                        : "collapse"
                                                                                                    }
                                                                                                  >
                                                                                                    {child.children.map(
                                                                                                      (
                                                                                                        child,
                                                                                                        idx7
                                                                                                      ) => (
                                                                                                        <li
                                                                                                          key={
                                                                                                            idx7
                                                                                                          }
                                                                                                        >
                                                                                                          {child
                                                                                                            .children
                                                                                                            .length >
                                                                                                            0 ? (
                                                                                                            <>
                                                                                                              <Card.Header>
                                                                                                                <div className="hasCheckBox cardHeader">
                                                                                                                  <label className="form-check">
                                                                                                                    <input
                                                                                                                      type="checkbox"
                                                                                                                      className="form-check-input"
                                                                                                                      checked={
                                                                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                          ? true
                                                                                                                          : false
                                                                                                                      }
                                                                                                                      value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                                                                                      disabled={
                                                                                                                        selectedRoles.length >
                                                                                                                        0 &&
                                                                                                                        (selectedRoles[0]
                                                                                                                          .roleName ===
                                                                                                                          "Administrator" || child.isSpecial === "1")
                                                                                                                      }
                                                                                                                      onChange={(
                                                                                                                        e: any
                                                                                                                      ) => {
                                                                                                                        e.stopPropagation();
                                                                                                                        this.checkChange(
                                                                                                                          child,
                                                                                                                          e,
                                                                                                                          child.treeID
                                                                                                                        );
                                                                                                                      }}
                                                                                                                    ></input>
                                                                                                                    <span></span>
                                                                                                                    <span></span>
                                                                                                                  </label>
                                                                                                                </div>
                                                                                                              </Card.Header>
                                                                                                              <div
                                                                                                                className={
                                                                                                                  userRoleforTreeView[
                                                                                                                    index
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx1
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx2
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx3
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx4
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx5
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx6
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx7
                                                                                                                  ]
                                                                                                                    .isExpend ||
                                                                                                                    accordionToggle
                                                                                                                    ? "collapse show"
                                                                                                                    : "collapse"
                                                                                                                }
                                                                                                              >
                                                                                                                <>

                                                                                                                </>
                                                                                                              </div>
                                                                                                            </>
                                                                                                          ) : (
                                                                                                            <Card.Header>
                                                                                                              <div className="hasCheckBox cardHeader">
                                                                                                                <label className="form-check">
                                                                                                                  <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    checked={
                                                                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                        ? true
                                                                                                                        : false
                                                                                                                    }
                                                                                                                    value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                                                                                    disabled={
                                                                                                                      selectedRoles.length >
                                                                                                                      0 &&
                                                                                                                      (selectedRoles[0]
                                                                                                                        .roleName ===
                                                                                                                        "Administrator" || child.isSpecial === "1")
                                                                                                                    }
                                                                                                                    onChange={(
                                                                                                                      e: any
                                                                                                                    ) => {
                                                                                                                      e.stopPropagation();
                                                                                                                      this.checkChange(
                                                                                                                        child,
                                                                                                                        e,
                                                                                                                        child.treeID
                                                                                                                      );
                                                                                                                    }}
                                                                                                                  ></input>
                                                                                                                  <span></span>
                                                                                                                  <span></span>
                                                                                                                </label>
                                                                                                              </div>
                                                                                                            </Card.Header>
                                                                                                          )}
                                                                                                        </li>
                                                                                                      )
                                                                                                    )}
                                                                                                  </ul>
                                                                                                </>
                                                                                              ) : (
                                                                                                <Card.Header>
                                                                                                  <div className="hasCheckBox cardHeader">
                                                                                                    <label className="form-check">
                                                                                                      <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        checked={
                                                                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                            ? true
                                                                                                            : false
                                                                                                        }
                                                                                                        value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                                                                        disabled={
                                                                                                          selectedRoles.length >
                                                                                                          0 &&
                                                                                                          (selectedRoles[0]
                                                                                                            .roleName ===
                                                                                                            "Administrator" || child.isSpecial === "1")
                                                                                                        }
                                                                                                        onChange={(
                                                                                                          e: any
                                                                                                        ) => {
                                                                                                          e.stopPropagation();
                                                                                                          this.checkChange(
                                                                                                            child,
                                                                                                            e,
                                                                                                            child.treeID
                                                                                                          );
                                                                                                        }}
                                                                                                      ></input>
                                                                                                      <span></span>
                                                                                                      <span></span>
                                                                                                    </label>
                                                                                                  </div>
                                                                                                </Card.Header>
                                                                                              )}
                                                                                            </li>
                                                                                          )
                                                                                        )}
                                                                                      </ul>
                                                                                    </>
                                                                                  ) : (
                                                                                    <Card.Header>
                                                                                      <div className="hasCheckBox cardHeader">
                                                                                        <label className="form-check">
                                                                                          <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            checked={
                                                                                              child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                ? true
                                                                                                : false
                                                                                            }
                                                                                            value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                                                            disabled={
                                                                                              selectedRoles.length >
                                                                                              0 &&
                                                                                              (selectedRoles[0]
                                                                                                .roleName ===
                                                                                                "Administrator" || child.isSpecial === "1")
                                                                                            }
                                                                                            onChange={(
                                                                                              e: any
                                                                                            ) => {
                                                                                              e.stopPropagation();
                                                                                              this.checkChange(
                                                                                                child,
                                                                                                e,
                                                                                                child.treeID
                                                                                              );
                                                                                            }}
                                                                                          ></input>
                                                                                          <span></span>
                                                                                          <span></span>
                                                                                        </label>
                                                                                      </div>
                                                                                    </Card.Header>
                                                                                  )}
                                                                                </li>
                                                                              )
                                                                            )}
                                                                          </ul>
                                                                        </>
                                                                      ) : (
                                                                        <Card.Header>
                                                                          <div className="hasCheckBox cardHeader">
                                                                            <label className="form-check">
                                                                              <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={
                                                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                    ? true
                                                                                    : false
                                                                                }
                                                                                value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                                                disabled={
                                                                                  selectedRoles.length >
                                                                                  0 &&
                                                                                  (selectedRoles[0]
                                                                                    .roleName ===
                                                                                    "Administrator" || child.isSpecial === "1")
                                                                                }
                                                                                onChange={(
                                                                                  e: any
                                                                                ) => {
                                                                                  e.stopPropagation();
                                                                                  this.checkChange(
                                                                                    child,
                                                                                    e,
                                                                                    child.treeID
                                                                                  );
                                                                                }}
                                                                              ></input>
                                                                              <span></span>
                                                                              <span></span>
                                                                            </label>
                                                                          </div>
                                                                        </Card.Header>
                                                                      )}
                                                                    </li>
                                                                  )
                                                                )}
                                                              </ul>
                                                            </>
                                                          ) : (
                                                            <Card.Header>
                                                              <div className="hasCheckBox cardHeader">
                                                                <label className="form-check">
                                                                  <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={
                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                                    disabled={
                                                                      selectedRoles.length >
                                                                      0 &&
                                                                      (selectedRoles[0]
                                                                        .roleName ===
                                                                        "Administrator" || child.isSpecial === "1")
                                                                    }
                                                                    onChange={(
                                                                      e: any
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      this.checkChange(
                                                                        child,
                                                                        e,
                                                                        child.treeID
                                                                      );
                                                                    }}
                                                                  ></input>
                                                                  <span></span>
                                                                  <span></span>
                                                                </label>
                                                              </div>
                                                            </Card.Header>
                                                          )}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </>
                                              ) : (
                                                <Card.Header>
                                                  <div className="hasCheckBox cardHeader">
                                                    <label className="form-check">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={
                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                            ? true
                                                            : false
                                                        }
                                                        value={`${selectedRoles[0].roleID}:${child.childID}`}
                                                        disabled={
                                                          selectedRoles.length >
                                                          0 &&
                                                          (selectedRoles[0]
                                                            .roleName ===
                                                            "Administrator" || child.isSpecial === "1")
                                                        }
                                                        onChange={(e: any) => {
                                                          e.stopPropagation();
                                                          this.checkChange(
                                                            child,
                                                            e,
                                                            child.treeID
                                                          );
                                                        }}
                                                      ></input>
                                                      <span></span>
                                                      <span></span>
                                                    </label>
                                                  </div>
                                                </Card.Header>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    ) : (
                                      <Card.Header className="hasNoChild">
                                        <div className="hasCheckBox cardHeader">
                                          <label className="form-check">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                  ? true
                                                  : false
                                              }
                                              value={`${selectedRoles[0].roleID}:${child.childID}`}
                                              disabled={
                                                selectedRoles.length >
                                                0 &&
                                                (selectedRoles[0]
                                                  .roleName ===
                                                  "Administrator" || child.isSpecial === "1")
                                              }
                                              onChange={(e: any) => {
                                                e.stopPropagation();
                                                this.checkChange(
                                                  child,
                                                  e,
                                                  child.treeID
                                                );
                                              }}
                                            ></input>
                                            <span></span>
                                            <span></span>
                                          </label>
                                        </div>
                                      </Card.Header>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                  {selectedRoles.length > 1 && (
                    <div className="column1">
                      <div className="rollMatrixAccordionHeader">
                        <div className="colPadd text-center">
                          <div className="heading">
                            <EllipsisWithTooltip placement="bottom">
                              {selectedRoles.length > 1 ? (
                                selectedRoles[1].roleName
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </EllipsisWithTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <ul className="rollAccordion listAccordion">
                          {roleforTreeView1.map((items, index) => (
                            <li
                              key={index}
                              className={`${userRoleforTreeView[index].isExpend |
                                accordionToggle
                                ? "matrixBottomShaddow"
                                : ""
                                }`}
                            >
                              <Card.Header>
                                <div
                                  className={`${selectedRoles.length > 1 &&
                                    (selectedRoles[1].roleName ===
                                      "Administrator"
                                      ? "matrixAssignedPanel matrixhasAdmin"
                                      : "matrixAssignedPanel")
                                    }`}
                                >
                                  <div className="content border_l">
                                    <div className="content-heading">
                                      {items.permisionCount === "0"
                                        ? items.permisionCount
                                        : items.permisionCount - 1}{" "}
                                      / {items.modulePermisisonCount - 1}
                                    </div>
                                    <span className="content-subhead">
                                      Assigned
                                    </span>
                                  </div>
                                </div>
                              </Card.Header>
                              <ul
                                className={
                                  userRoleforTreeView[index].isExpend ||
                                    accordionToggle
                                    ? "collapse show border_r"
                                    : "collapse"
                                }
                              >
                                {items.children.map((child, idx1) => (
                                  <li key={idx1}>
                                    {child.children.length > 0 ? (
                                      <>
                                        <Card.Header>
                                          <div className="hasCheckBox cardHeader">
                                            <label className="form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={
                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                    ? true
                                                    : false
                                                }
                                                value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                disabled={
                                                  selectedRoles.length > 1 &&
                                                  (selectedRoles[1].roleName ===
                                                    "Administrator" || child.isSpecial === "1")
                                                }
                                                onChange={(e: any) => {
                                                  e.stopPropagation();
                                                  this.checkChange(
                                                    child,
                                                    e,
                                                    child.treeID
                                                  );
                                                }}
                                              ></input>
                                              <span></span>
                                            </label>
                                          </div>
                                        </Card.Header>
                                        <ul
                                          className={
                                            userRoleforTreeView[index].children[
                                              idx1
                                            ].isExpend || accordionToggle
                                              ? "collapse show"
                                              : "collapse"
                                          }
                                        >
                                          {child.children.map((child, idx2) => (
                                            <li key={idx2}>
                                              {child.children.length > 0 ? (
                                                <>
                                                  <Card.Header>
                                                    <div className="hasCheckBox cardHeader">
                                                      <label className="form-check">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          checked={
                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                              ? true
                                                              : false
                                                          }
                                                          value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                          disabled={
                                                            selectedRoles.length > 1 &&
                                                            (selectedRoles[1].roleName ===
                                                              "Administrator" || child.isSpecial === "1")
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            e.stopPropagation();
                                                            this.checkChange(
                                                              child,
                                                              e,
                                                              child.treeID
                                                            );
                                                          }}
                                                        ></input>
                                                        <span></span>
                                                      </label>
                                                    </div>
                                                  </Card.Header>
                                                  <ul
                                                    className={
                                                      userRoleforTreeView[index]
                                                        .children[idx1]
                                                        .children[idx2]
                                                        .isExpend ||
                                                        accordionToggle
                                                        ? "collapse show"
                                                        : "collapse"
                                                    }
                                                  >
                                                    {child.children.map(
                                                      (child, idx3) => (
                                                        <li key={idx3}>
                                                          {child.children
                                                            .length > 0 ? (
                                                            <>
                                                              <Card.Header>
                                                                <div className="hasCheckBox cardHeader">
                                                                  <label className="form-check">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="form-check-input"
                                                                      checked={
                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                                      disabled={
                                                                        selectedRoles.length > 1 &&
                                                                        (selectedRoles[1].roleName ===
                                                                          "Administrator" || child.isSpecial === "1")
                                                                      }
                                                                      onChange={(
                                                                        e: any
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                        this.checkChange(
                                                                          child,
                                                                          e,
                                                                          child.treeID
                                                                        );
                                                                      }}
                                                                    ></input>
                                                                    <span></span>
                                                                  </label>
                                                                </div>
                                                              </Card.Header>
                                                              <ul
                                                                className={
                                                                  userRoleforTreeView[
                                                                    index
                                                                  ].children[
                                                                    idx1
                                                                  ].children[
                                                                    idx2
                                                                  ].children[
                                                                    idx3
                                                                  ].isExpend ||
                                                                    accordionToggle
                                                                    ? "collapse show"
                                                                    : "collapse"
                                                                }
                                                              >
                                                                {child.children.map(
                                                                  (
                                                                    child,
                                                                    idx4
                                                                  ) => (
                                                                    <li
                                                                      key={idx4}
                                                                    >
                                                                      {child
                                                                        .children
                                                                        .length >
                                                                        0 ? (
                                                                        <>
                                                                          <Card.Header>
                                                                            <div className="hasCheckBox cardHeader">
                                                                              <label className="form-check">
                                                                                <input
                                                                                  type="checkbox"
                                                                                  className="form-check-input"
                                                                                  checked={
                                                                                    child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                      ? true
                                                                                      : false
                                                                                  }
                                                                                  value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                                                  disabled={
                                                                                    selectedRoles.length > 1 &&
                                                                                    (selectedRoles[1].roleName ===
                                                                                      "Administrator" || child.isSpecial === "1")
                                                                                  }
                                                                                  onChange={(
                                                                                    e: any
                                                                                  ) => {
                                                                                    e.stopPropagation();
                                                                                    this.checkChange(
                                                                                      child,
                                                                                      e,
                                                                                      child.treeID
                                                                                    );
                                                                                  }}
                                                                                ></input>
                                                                                <span></span>
                                                                              </label>
                                                                            </div>
                                                                          </Card.Header>
                                                                          <ul
                                                                            className={
                                                                              userRoleforTreeView[
                                                                                index
                                                                              ]
                                                                                .children[
                                                                                idx1
                                                                              ]
                                                                                .children[
                                                                                idx2
                                                                              ]
                                                                                .children[
                                                                                idx3
                                                                              ]
                                                                                .children[
                                                                                idx4
                                                                              ]
                                                                                .isExpend ||
                                                                                accordionToggle
                                                                                ? "collapse show"
                                                                                : "collapse"
                                                                            }
                                                                          >
                                                                            {child.children.map(
                                                                              (
                                                                                child,
                                                                                idx5
                                                                              ) => (
                                                                                <li
                                                                                  key={
                                                                                    idx5
                                                                                  }
                                                                                >
                                                                                  {child
                                                                                    .children
                                                                                    .length >
                                                                                    0 ? (
                                                                                    <>
                                                                                      <Card.Header>
                                                                                        <div className="hasCheckBox cardHeader">
                                                                                          <label className="form-check">
                                                                                            <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              checked={
                                                                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                  ? true
                                                                                                  : false
                                                                                              }
                                                                                              value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                                                              disabled={
                                                                                                selectedRoles.length > 1 &&
                                                                                                (selectedRoles[1].roleName ===
                                                                                                  "Administrator" || child.isSpecial === "1")
                                                                                              }
                                                                                              onChange={(
                                                                                                e: any
                                                                                              ) => {
                                                                                                e.stopPropagation();
                                                                                                this.checkChange(
                                                                                                  child,
                                                                                                  e,
                                                                                                  child.treeID
                                                                                                );
                                                                                              }}
                                                                                            ></input>
                                                                                            <span></span>
                                                                                          </label>
                                                                                        </div>
                                                                                      </Card.Header>
                                                                                      <ul
                                                                                        className={
                                                                                          userRoleforTreeView[
                                                                                            index
                                                                                          ]
                                                                                            .children[
                                                                                            idx1
                                                                                          ]
                                                                                            .children[
                                                                                            idx2
                                                                                          ]
                                                                                            .children[
                                                                                            idx3
                                                                                          ]
                                                                                            .children[
                                                                                            idx4
                                                                                          ]
                                                                                            .children[
                                                                                            idx5
                                                                                          ]
                                                                                            .isExpend ||
                                                                                            accordionToggle
                                                                                            ? "collapse show"
                                                                                            : "collapse"
                                                                                        }
                                                                                      >
                                                                                        {child.children.map(
                                                                                          (
                                                                                            child,
                                                                                            idx6
                                                                                          ) => (
                                                                                            <li
                                                                                              key={
                                                                                                idx6
                                                                                              }
                                                                                            >
                                                                                              {child
                                                                                                .children
                                                                                                .length >
                                                                                                0 ? (
                                                                                                <>
                                                                                                  <Card.Header>
                                                                                                    <div className="hasCheckBox cardHeader">
                                                                                                      <label className="form-check">
                                                                                                        <input
                                                                                                          type="checkbox"
                                                                                                          className="form-check-input"
                                                                                                          checked={
                                                                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                              ? true
                                                                                                              : false
                                                                                                          }
                                                                                                          value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                                                                          disabled={
                                                                                                            selectedRoles.length > 1 &&
                                                                                                            (selectedRoles[1].roleName ===
                                                                                                              "Administrator" || child.isSpecial === "1")
                                                                                                          }
                                                                                                          onChange={(
                                                                                                            e: any
                                                                                                          ) => {
                                                                                                            e.stopPropagation();
                                                                                                            this.checkChange(
                                                                                                              child,
                                                                                                              e,
                                                                                                              child.treeID
                                                                                                            );
                                                                                                          }}
                                                                                                        ></input>
                                                                                                        <span></span>
                                                                                                      </label>
                                                                                                    </div>
                                                                                                  </Card.Header>
                                                                                                  <ul
                                                                                                    className={
                                                                                                      userRoleforTreeView[
                                                                                                        index
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx1
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx2
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx3
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx4
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx5
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx6
                                                                                                      ]
                                                                                                        .isExpend ||
                                                                                                        accordionToggle
                                                                                                        ? "collapse show"
                                                                                                        : "collapse"
                                                                                                    }
                                                                                                  >
                                                                                                    {child.children.map(
                                                                                                      (
                                                                                                        child,
                                                                                                        idx7
                                                                                                      ) => (
                                                                                                        <li
                                                                                                          key={
                                                                                                            idx7
                                                                                                          }
                                                                                                        >
                                                                                                          {child
                                                                                                            .children
                                                                                                            .length >
                                                                                                            0 ? (
                                                                                                            <>
                                                                                                              <Card.Header>
                                                                                                                <div className="hasCheckBox cardHeader">
                                                                                                                  <label className="form-check">
                                                                                                                    <input
                                                                                                                      type="checkbox"
                                                                                                                      className="form-check-input"
                                                                                                                      checked={
                                                                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                          ? true
                                                                                                                          : false
                                                                                                                      }
                                                                                                                      value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                                                                                      disabled={
                                                                                                                        selectedRoles.length > 1 &&
                                                                                                                        (selectedRoles[1].roleName ===
                                                                                                                          "Administrator" || child.isSpecial === "1")
                                                                                                                      }
                                                                                                                      onChange={(
                                                                                                                        e: any
                                                                                                                      ) => {
                                                                                                                        e.stopPropagation();
                                                                                                                        this.checkChange(
                                                                                                                          child,
                                                                                                                          e,
                                                                                                                          child.treeID
                                                                                                                        );
                                                                                                                      }}
                                                                                                                    ></input>
                                                                                                                    <span></span>
                                                                                                                  </label>
                                                                                                                </div>
                                                                                                              </Card.Header>
                                                                                                              <div
                                                                                                                className={
                                                                                                                  userRoleforTreeView[
                                                                                                                    index
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx1
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx2
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx3
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx4
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx5
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx6
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx7
                                                                                                                  ]
                                                                                                                    .isExpend ||
                                                                                                                    accordionToggle
                                                                                                                    ? "collapse show"
                                                                                                                    : "collapse"
                                                                                                                }
                                                                                                              >
                                                                                                                <>

                                                                                                                </>
                                                                                                              </div>
                                                                                                            </>
                                                                                                          ) : (
                                                                                                            <Card.Header>
                                                                                                              <div className="hasCheckBox cardHeader">
                                                                                                                <label className="form-check">
                                                                                                                  <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    checked={
                                                                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                        ? true
                                                                                                                        : false
                                                                                                                    }
                                                                                                                    value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                                                                                    disabled={
                                                                                                                      selectedRoles.length > 1 &&
                                                                                                                      (selectedRoles[1].roleName ===
                                                                                                                        "Administrator" || child.isSpecial === "1")
                                                                                                                    }
                                                                                                                    onChange={(
                                                                                                                      e: any
                                                                                                                    ) => {
                                                                                                                      e.stopPropagation();
                                                                                                                      this.checkChange(
                                                                                                                        child,
                                                                                                                        e,
                                                                                                                        child.treeID
                                                                                                                      );
                                                                                                                    }}
                                                                                                                  ></input>
                                                                                                                  <span></span>
                                                                                                                </label>
                                                                                                              </div>
                                                                                                            </Card.Header>
                                                                                                          )}
                                                                                                        </li>
                                                                                                      )
                                                                                                    )}
                                                                                                  </ul>
                                                                                                </>
                                                                                              ) : (
                                                                                                <Card.Header>
                                                                                                  <div className="hasCheckBox cardHeader">
                                                                                                    <label className="form-check">
                                                                                                      <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        checked={
                                                                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                            ? true
                                                                                                            : false
                                                                                                        }
                                                                                                        value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                                                                        disabled={
                                                                                                          selectedRoles.length > 1 &&
                                                                                                          (selectedRoles[1].roleName ===
                                                                                                            "Administrator" || child.isSpecial === "1")
                                                                                                        }
                                                                                                        onChange={(
                                                                                                          e: any
                                                                                                        ) => {
                                                                                                          e.stopPropagation();
                                                                                                          this.checkChange(
                                                                                                            child,
                                                                                                            e,
                                                                                                            child.treeID
                                                                                                          );
                                                                                                        }}
                                                                                                      ></input>
                                                                                                      <span></span>
                                                                                                    </label>
                                                                                                  </div>
                                                                                                </Card.Header>
                                                                                              )}
                                                                                            </li>
                                                                                          )
                                                                                        )}
                                                                                      </ul>
                                                                                    </>
                                                                                  ) : (
                                                                                    <Card.Header>
                                                                                      <div className="hasCheckBox cardHeader">
                                                                                        <label className="form-check">
                                                                                          <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            checked={
                                                                                              child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                ? true
                                                                                                : false
                                                                                            }
                                                                                            value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                                                            disabled={
                                                                                              selectedRoles.length > 1 &&
                                                                                              (selectedRoles[1].roleName ===
                                                                                                "Administrator" || child.isSpecial === "1")
                                                                                            }
                                                                                            onChange={(
                                                                                              e: any
                                                                                            ) => {
                                                                                              e.stopPropagation();
                                                                                              this.checkChange(
                                                                                                child,
                                                                                                e,
                                                                                                child.treeID
                                                                                              );
                                                                                            }}
                                                                                          ></input>
                                                                                          <span></span>
                                                                                        </label>
                                                                                      </div>
                                                                                    </Card.Header>
                                                                                  )}
                                                                                </li>
                                                                              )
                                                                            )}
                                                                          </ul>
                                                                        </>
                                                                      ) : (
                                                                        <Card.Header>
                                                                          <div className="hasCheckBox cardHeader">
                                                                            <label className="form-check">
                                                                              <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={
                                                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                    ? true
                                                                                    : false
                                                                                }
                                                                                value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                                                disabled={
                                                                                  selectedRoles.length > 1 &&
                                                                                  (selectedRoles[1].roleName ===
                                                                                    "Administrator" || child.isSpecial === "1")
                                                                                }
                                                                                onChange={(
                                                                                  e: any
                                                                                ) => {
                                                                                  e.stopPropagation();
                                                                                  this.checkChange(
                                                                                    child,
                                                                                    e,
                                                                                    child.treeID
                                                                                  );
                                                                                }}
                                                                              ></input>
                                                                              <span></span>
                                                                            </label>
                                                                          </div>
                                                                        </Card.Header>
                                                                      )}
                                                                    </li>
                                                                  )
                                                                )}
                                                              </ul>
                                                            </>
                                                          ) : (
                                                            <Card.Header>
                                                              <div className="hasCheckBox cardHeader">
                                                                <label className="form-check">
                                                                  <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={
                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                                    disabled={
                                                                      selectedRoles.length > 1 &&
                                                                      (selectedRoles[1].roleName ===
                                                                        "Administrator" || child.isSpecial === "1")
                                                                    }
                                                                    onChange={(
                                                                      e: any
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      this.checkChange(
                                                                        child,
                                                                        e,
                                                                        child.treeID
                                                                      );
                                                                    }}
                                                                  ></input>
                                                                  <span></span>
                                                                </label>
                                                              </div>
                                                            </Card.Header>
                                                          )}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </>
                                              ) : (
                                                <Card.Header>
                                                  <div className="hasCheckBox cardHeader">
                                                    <label className="form-check">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={
                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                            ? true
                                                            : false
                                                        }
                                                        value={`${selectedRoles[1].roleID}:${child.childID}`}
                                                        disabled={
                                                          selectedRoles.length > 1 &&
                                                          (selectedRoles[1].roleName ===
                                                            "Administrator" || child.isSpecial === "1")
                                                        }
                                                        onChange={(e: any) => {
                                                          e.stopPropagation();
                                                          this.checkChange(
                                                            child,
                                                            e,
                                                            child.treeID
                                                          );
                                                        }}
                                                      ></input>
                                                      <span></span>
                                                    </label>
                                                  </div>
                                                </Card.Header>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    ) : (
                                      <Card.Header className="hasNoChild">
                                        <div className="hasCheckBox cardHeader">
                                          <label className="form-check">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                  ? true
                                                  : false
                                              }
                                              value={`${selectedRoles[1].roleID}:${child.childID}`}
                                              disabled={
                                                selectedRoles.length > 1 &&
                                                (selectedRoles[1].roleName ===
                                                  "Administrator" || child.isSpecial === "1")
                                              }
                                              onChange={(e: any) => {
                                                e.stopPropagation();
                                                this.checkChange(
                                                  child,
                                                  e,
                                                  child.treeID
                                                );
                                              }}
                                            ></input>
                                            <span></span>
                                          </label>
                                        </div>
                                      </Card.Header>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                  {selectedRoles.length > 2 && (
                    <div className="column1">
                      <div className="rollMatrixAccordionHeader">
                        <div className="colPadd text-center">
                          <div className="heading">
                            <EllipsisWithTooltip placement="bottom">
                              {selectedRoles.length > 2 ? (
                                selectedRoles[2].roleName
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </EllipsisWithTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <ul className="rollAccordion listAccordion">
                          {roleforTreeView2.map((items, index) => (
                            <li
                              key={index}
                              className={`${userRoleforTreeView[index].isExpend |
                                accordionToggle
                                ? "matrixBottomShaddow"
                                : ""
                                }`}
                            >
                              <Card.Header>
                                <div
                                  className={`${selectedRoles.length > 2 &&
                                    (selectedRoles[2].roleName ===
                                      "Administrator"
                                      ? "matrixAssignedPanel matrixhasAdmin"
                                      : "matrixAssignedPanel")
                                    }`}
                                >
                                  <div className="content border_l">
                                    <div className="content-heading">
                                      {items.permisionCount === "0"
                                        ? items.permisionCount
                                        : items.permisionCount - 1}{" "}
                                      / {items.modulePermisisonCount - 1}
                                    </div>
                                    <span className="content-subhead">
                                      Assigned
                                    </span>
                                  </div>
                                </div>
                              </Card.Header>
                              <ul
                                className={
                                  userRoleforTreeView[index].isExpend ||
                                    accordionToggle
                                    ? "collapse show border_r"
                                    : "collapse"
                                }
                              >
                                {items.children.map((child, idx1) => (
                                  <li key={idx1}>
                                    {child.children.length > 0 ? (
                                      <>
                                        <Card.Header>
                                          <div className="hasCheckBox cardHeader">
                                            <label className="form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={
                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                    ? true
                                                    : false
                                                }
                                                value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                disabled={
                                                  selectedRoles.length > 1 &&
                                                  (selectedRoles[2].roleName ===
                                                    "Administrator" || child.isSpecial === "1")
                                                }
                                                onChange={(e: any) => {
                                                  e.stopPropagation();
                                                  this.checkChange(
                                                    child,
                                                    e,
                                                    child.treeID
                                                  );
                                                }}
                                              ></input>
                                              <span></span>
                                            </label>
                                          </div>
                                        </Card.Header>
                                        <ul
                                          className={
                                            userRoleforTreeView[index].children[
                                              idx1
                                            ].isExpend || accordionToggle
                                              ? "collapse show"
                                              : "collapse"
                                          }
                                        >
                                          {child.children.map((child, idx2) => (
                                            <li key={idx2}>
                                              {child.children.length > 0 ? (
                                                <>
                                                  <Card.Header>
                                                    <div className="hasCheckBox cardHeader">
                                                      <label className="form-check">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          checked={
                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                              ? true
                                                              : false
                                                          }
                                                          value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                          disabled={
                                                            selectedRoles.length > 1 &&
                                                            (selectedRoles[2].roleName ===
                                                              "Administrator" || child.isSpecial === "1")
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            e.stopPropagation();
                                                            this.checkChange(
                                                              child,
                                                              e,
                                                              child.treeID
                                                            );
                                                          }}
                                                        ></input>
                                                        <span></span>
                                                      </label>
                                                    </div>
                                                  </Card.Header>
                                                  <ul
                                                    className={
                                                      userRoleforTreeView[index]
                                                        .children[idx1]
                                                        .children[idx2]
                                                        .isExpend ||
                                                        accordionToggle
                                                        ? "collapse show"
                                                        : "collapse"
                                                    }
                                                  >
                                                    {child.children.map(
                                                      (child, idx3) => (
                                                        <li key={idx3}>
                                                          {child.children
                                                            .length > 0 ? (
                                                            <>
                                                              <Card.Header>
                                                                <div className="hasCheckBox cardHeader">
                                                                  <label className="form-check">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="form-check-input"
                                                                      checked={
                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                                      disabled={
                                                                        selectedRoles.length > 1 &&
                                                                        (selectedRoles[2].roleName ===
                                                                          "Administrator" || child.isSpecial === "1")
                                                                      }
                                                                      onChange={(
                                                                        e: any
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                        this.checkChange(
                                                                          child,
                                                                          e,
                                                                          child.treeID
                                                                        );
                                                                      }}
                                                                    ></input>
                                                                    <span></span>
                                                                  </label>
                                                                </div>
                                                              </Card.Header>
                                                              <ul
                                                                className={
                                                                  userRoleforTreeView[
                                                                    index
                                                                  ].children[
                                                                    idx1
                                                                  ].children[
                                                                    idx2
                                                                  ].children[
                                                                    idx3
                                                                  ].isExpend ||
                                                                    accordionToggle
                                                                    ? "collapse show"
                                                                    : "collapse"
                                                                }
                                                              >
                                                                {child.children.map(
                                                                  (
                                                                    child,
                                                                    idx4
                                                                  ) => (
                                                                    <li
                                                                      key={idx4}
                                                                    >
                                                                      {child
                                                                        .children
                                                                        .length >
                                                                        0 ? (
                                                                        <>
                                                                          <Card.Header>
                                                                            <div className="hasCheckBox cardHeader">
                                                                              <label className="form-check">
                                                                                <input
                                                                                  type="checkbox"
                                                                                  className="form-check-input"
                                                                                  checked={
                                                                                    child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                      ? true
                                                                                      : false
                                                                                  }
                                                                                  value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                                                  disabled={
                                                                                    selectedRoles.length > 1 &&
                                                                                    (selectedRoles[2].roleName ===
                                                                                      "Administrator" || child.isSpecial === "1")
                                                                                  }
                                                                                  onChange={(
                                                                                    e: any
                                                                                  ) => {
                                                                                    e.stopPropagation();
                                                                                    this.checkChange(
                                                                                      child,
                                                                                      e,
                                                                                      child.treeID
                                                                                    );
                                                                                  }}
                                                                                ></input>
                                                                                <span></span>
                                                                              </label>
                                                                            </div>
                                                                          </Card.Header>
                                                                          <ul
                                                                            className={
                                                                              userRoleforTreeView[
                                                                                index
                                                                              ]
                                                                                .children[
                                                                                idx1
                                                                              ]
                                                                                .children[
                                                                                idx2
                                                                              ]
                                                                                .children[
                                                                                idx3
                                                                              ]
                                                                                .children[
                                                                                idx4
                                                                              ]
                                                                                .isExpend ||
                                                                                accordionToggle
                                                                                ? "collapse show"
                                                                                : "collapse"
                                                                            }
                                                                          >
                                                                            {child.children.map(
                                                                              (
                                                                                child,
                                                                                idx5
                                                                              ) => (
                                                                                <li
                                                                                  key={
                                                                                    idx5
                                                                                  }
                                                                                >
                                                                                  {child
                                                                                    .children
                                                                                    .length >
                                                                                    0 ? (
                                                                                    <>
                                                                                      <Card.Header>
                                                                                        <div className="hasCheckBox cardHeader">
                                                                                          <label className="form-check">
                                                                                            <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              checked={
                                                                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                  ? true
                                                                                                  : false
                                                                                              }
                                                                                              value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                                                              disabled={
                                                                                                selectedRoles.length > 1 &&
                                                                                                (selectedRoles[2].roleName ===
                                                                                                  "Administrator" || child.isSpecial === "1")
                                                                                              }
                                                                                              onChange={(
                                                                                                e: any
                                                                                              ) => {
                                                                                                e.stopPropagation();
                                                                                                this.checkChange(
                                                                                                  child,
                                                                                                  e,
                                                                                                  child.treeID
                                                                                                );
                                                                                              }}
                                                                                            ></input>
                                                                                            <span></span>
                                                                                          </label>
                                                                                        </div>
                                                                                      </Card.Header>
                                                                                      <ul
                                                                                        className={
                                                                                          userRoleforTreeView[
                                                                                            index
                                                                                          ]
                                                                                            .children[
                                                                                            idx1
                                                                                          ]
                                                                                            .children[
                                                                                            idx2
                                                                                          ]
                                                                                            .children[
                                                                                            idx3
                                                                                          ]
                                                                                            .children[
                                                                                            idx4
                                                                                          ]
                                                                                            .children[
                                                                                            idx5
                                                                                          ]
                                                                                            .isExpend ||
                                                                                            accordionToggle
                                                                                            ? "collapse show"
                                                                                            : "collapse"
                                                                                        }
                                                                                      >
                                                                                        {child.children.map(
                                                                                          (
                                                                                            child,
                                                                                            idx6
                                                                                          ) => (
                                                                                            <li
                                                                                              key={
                                                                                                idx6
                                                                                              }
                                                                                            >
                                                                                              {child
                                                                                                .children
                                                                                                .length >
                                                                                                0 ? (
                                                                                                <>
                                                                                                  <Card.Header>
                                                                                                    <div className="hasCheckBox cardHeader">
                                                                                                      <label className="form-check">
                                                                                                        <input
                                                                                                          type="checkbox"
                                                                                                          className="form-check-input"
                                                                                                          checked={
                                                                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                              ? true
                                                                                                              : false
                                                                                                          }
                                                                                                          value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                                                                          disabled={
                                                                                                            selectedRoles.length > 1 &&
                                                                                                            (selectedRoles[2].roleName ===
                                                                                                              "Administrator" || child.isSpecial === "1")
                                                                                                          }
                                                                                                          onChange={(
                                                                                                            e: any
                                                                                                          ) => {
                                                                                                            e.stopPropagation();
                                                                                                            this.checkChange(
                                                                                                              child,
                                                                                                              e,
                                                                                                              child.treeID
                                                                                                            );
                                                                                                          }}
                                                                                                        ></input>
                                                                                                        <span></span>
                                                                                                      </label>
                                                                                                    </div>
                                                                                                  </Card.Header>
                                                                                                  <ul
                                                                                                    className={
                                                                                                      userRoleforTreeView[
                                                                                                        index
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx1
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx2
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx3
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx4
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx5
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx6
                                                                                                      ]
                                                                                                        .isExpend ||
                                                                                                        accordionToggle
                                                                                                        ? "collapse show"
                                                                                                        : "collapse"
                                                                                                    }
                                                                                                  >
                                                                                                    {child.children.map(
                                                                                                      (
                                                                                                        child,
                                                                                                        idx7
                                                                                                      ) => (
                                                                                                        <li
                                                                                                          key={
                                                                                                            idx7
                                                                                                          }
                                                                                                        >
                                                                                                          {child
                                                                                                            .children
                                                                                                            .length >
                                                                                                            0 ? (
                                                                                                            <>
                                                                                                              <Card.Header>
                                                                                                                <div className="hasCheckBox cardHeader">
                                                                                                                  <label className="form-check">
                                                                                                                    <input
                                                                                                                      type="checkbox"
                                                                                                                      className="form-check-input"
                                                                                                                      checked={
                                                                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                          ? true
                                                                                                                          : false
                                                                                                                      }
                                                                                                                      value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                                                                                      disabled={
                                                                                                                        selectedRoles.length > 1 &&
                                                                                                                        (selectedRoles[2].roleName ===
                                                                                                                          "Administrator" || child.isSpecial === "1")
                                                                                                                      }
                                                                                                                      onChange={(
                                                                                                                        e: any
                                                                                                                      ) => {
                                                                                                                        e.stopPropagation();
                                                                                                                        this.checkChange(
                                                                                                                          child,
                                                                                                                          e,
                                                                                                                          child.treeID
                                                                                                                        );
                                                                                                                      }}
                                                                                                                    ></input>
                                                                                                                    <span></span>
                                                                                                                  </label>
                                                                                                                </div>
                                                                                                              </Card.Header>
                                                                                                              <div
                                                                                                                className={
                                                                                                                  userRoleforTreeView[
                                                                                                                    index
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx1
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx2
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx3
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx4
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx5
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx6
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx7
                                                                                                                  ]
                                                                                                                    .isExpend ||
                                                                                                                    accordionToggle
                                                                                                                    ? "collapse show"
                                                                                                                    : "collapse"
                                                                                                                }
                                                                                                              >
                                                                                                                <>

                                                                                                                </>
                                                                                                              </div>
                                                                                                            </>
                                                                                                          ) : (
                                                                                                            <Card.Header>
                                                                                                              <div className="hasCheckBox cardHeader">
                                                                                                                <label className="form-check">
                                                                                                                  <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    checked={
                                                                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                        ? true
                                                                                                                        : false
                                                                                                                    }
                                                                                                                    value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                                                                                    disabled={
                                                                                                                      selectedRoles.length > 1 &&
                                                                                                                      (selectedRoles[2].roleName ===
                                                                                                                        "Administrator" || child.isSpecial === "1")
                                                                                                                    }
                                                                                                                    onChange={(
                                                                                                                      e: any
                                                                                                                    ) => {
                                                                                                                      e.stopPropagation();
                                                                                                                      this.checkChange(
                                                                                                                        child,
                                                                                                                        e,
                                                                                                                        child.treeID
                                                                                                                      );
                                                                                                                    }}
                                                                                                                  ></input>
                                                                                                                  <span></span>
                                                                                                                </label>
                                                                                                              </div>
                                                                                                            </Card.Header>
                                                                                                          )}
                                                                                                        </li>
                                                                                                      )
                                                                                                    )}
                                                                                                  </ul>
                                                                                                </>
                                                                                              ) : (
                                                                                                <Card.Header>
                                                                                                  <div className="hasCheckBox cardHeader">
                                                                                                    <label className="form-check">
                                                                                                      <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        checked={
                                                                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                            ? true
                                                                                                            : false
                                                                                                        }
                                                                                                        value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                                                                        disabled={
                                                                                                          selectedRoles.length > 1 &&
                                                                                                          (selectedRoles[2].roleName ===
                                                                                                            "Administrator" || child.isSpecial === "1")
                                                                                                        }
                                                                                                        onChange={(
                                                                                                          e: any
                                                                                                        ) => {
                                                                                                          e.stopPropagation();
                                                                                                          this.checkChange(
                                                                                                            child,
                                                                                                            e,
                                                                                                            child.treeID
                                                                                                          );
                                                                                                        }}
                                                                                                      ></input>
                                                                                                      <span></span>
                                                                                                    </label>
                                                                                                  </div>
                                                                                                </Card.Header>
                                                                                              )}
                                                                                            </li>
                                                                                          )
                                                                                        )}
                                                                                      </ul>
                                                                                    </>
                                                                                  ) : (
                                                                                    <Card.Header>
                                                                                      <div className="hasCheckBox cardHeader">
                                                                                        <label className="form-check">
                                                                                          <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            checked={
                                                                                              child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                ? true
                                                                                                : false
                                                                                            }
                                                                                            value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                                                            disabled={
                                                                                              selectedRoles.length > 1 &&
                                                                                              (selectedRoles[2].roleName ===
                                                                                                "Administrator" || child.isSpecial === "1")
                                                                                            }
                                                                                            onChange={(
                                                                                              e: any
                                                                                            ) => {
                                                                                              e.stopPropagation();
                                                                                              this.checkChange(
                                                                                                child,
                                                                                                e,
                                                                                                child.treeID
                                                                                              );
                                                                                            }}
                                                                                          ></input>
                                                                                          <span></span>
                                                                                        </label>
                                                                                      </div>
                                                                                    </Card.Header>
                                                                                  )}
                                                                                </li>
                                                                              )
                                                                            )}
                                                                          </ul>
                                                                        </>
                                                                      ) : (
                                                                        <Card.Header>
                                                                          <div className="hasCheckBox cardHeader">
                                                                            <label className="form-check">
                                                                              <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={
                                                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                    ? true
                                                                                    : false
                                                                                }
                                                                                value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                                                disabled={
                                                                                  selectedRoles.length > 1 &&
                                                                                  (selectedRoles[2].roleName ===
                                                                                    "Administrator" || child.isSpecial === "1")
                                                                                }
                                                                                onChange={(
                                                                                  e: any
                                                                                ) => {
                                                                                  e.stopPropagation();
                                                                                  this.checkChange(
                                                                                    child,
                                                                                    e,
                                                                                    child.treeID
                                                                                  );
                                                                                }}
                                                                              ></input>
                                                                              <span></span>
                                                                            </label>
                                                                          </div>
                                                                        </Card.Header>
                                                                      )}
                                                                    </li>
                                                                  )
                                                                )}
                                                              </ul>
                                                            </>
                                                          ) : (
                                                            <Card.Header>
                                                              <div className="hasCheckBox cardHeader">
                                                                <label className="form-check">
                                                                  <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={
                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                                    disabled={
                                                                      selectedRoles.length > 1 &&
                                                                      (selectedRoles[2].roleName ===
                                                                        "Administrator" || child.isSpecial === "1")
                                                                    }
                                                                    onChange={(
                                                                      e: any
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      this.checkChange(
                                                                        child,
                                                                        e,
                                                                        child.treeID
                                                                      );
                                                                    }}
                                                                  ></input>
                                                                  <span></span>
                                                                </label>
                                                              </div>
                                                            </Card.Header>
                                                          )}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </>
                                              ) : (
                                                <Card.Header>
                                                  <div className="hasCheckBox cardHeader">
                                                    <label className="form-check">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={
                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                            ? true
                                                            : false
                                                        }
                                                        value={`${selectedRoles[2].roleID}:${child.childID}`}
                                                        disabled={
                                                          selectedRoles.length > 1 &&
                                                          (selectedRoles[2].roleName ===
                                                            "Administrator" || child.isSpecial === "1")
                                                        }
                                                        onChange={(e: any) => {
                                                          e.stopPropagation();
                                                          this.checkChange(
                                                            child,
                                                            e,
                                                            child.treeID
                                                          );
                                                        }}
                                                      ></input>
                                                      <span></span>
                                                    </label>
                                                  </div>
                                                </Card.Header>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    ) : (
                                      <Card.Header className="hasNoChild">
                                        <div className="hasCheckBox cardHeader">
                                          <label className="form-check">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                  ? true
                                                  : false
                                              }
                                              value={`${selectedRoles[2].roleID}:${child.childID}`}
                                              disabled={
                                                selectedRoles.length > 1 &&
                                                (selectedRoles[2].roleName ===
                                                  "Administrator" || child.isSpecial === "1")
                                              }
                                              onChange={(e: any) => {
                                                e.stopPropagation();
                                                this.checkChange(
                                                  child,
                                                  e,
                                                  child.treeID
                                                );
                                              }}
                                            ></input>
                                            <span></span>
                                          </label>
                                        </div>
                                      </Card.Header>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                  {selectedRoles.length > 3 && (
                    <div className="column1">
                      <div className="rollMatrixAccordionHeader">
                        <div className="colPadd text-center">
                          <div className="heading">
                            <EllipsisWithTooltip placement="bottom">
                              {selectedRoles.length > 3 ? (
                                selectedRoles[3].roleName
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </EllipsisWithTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <ul className="rollAccordion listAccordion">
                          {roleforTreeView3.map((items, index) => (
                            <li
                              key={index}
                              className={`${userRoleforTreeView[index].isExpend |
                                accordionToggle
                                ? "matrixBottomShaddow"
                                : ""
                                }`}
                            >
                              <Card.Header>
                                <div
                                  className={`${selectedRoles.length > 3 &&
                                    (selectedRoles[3].roleName ===
                                      "Administrator"
                                      ? "matrixAssignedPanel matrixhasAdmin"
                                      : "matrixAssignedPanel")
                                    }`}
                                >
                                  <div className="content border_l">
                                    <div className="content-heading">
                                      {items.permisionCount === "0"
                                        ? items.permisionCount
                                        : items.permisionCount - 1}{" "}
                                      / {items.modulePermisisonCount - 1}
                                    </div>
                                    <span className="content-subhead">
                                      Assigned
                                    </span>
                                  </div>
                                </div>
                              </Card.Header>
                              <ul
                                className={
                                  userRoleforTreeView[index].isExpend ||
                                    accordionToggle
                                    ? "collapse show border_r"
                                    : "collapse"
                                }
                              >
                                {items.children.map((child, idx1) => (
                                  <li key={idx1}>
                                    {child.children.length > 0 ? (
                                      <>
                                        <Card.Header>
                                          <div className="hasCheckBox cardHeader">
                                            <label className="form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={
                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                    ? true
                                                    : false
                                                }
                                                value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                disabled={
                                                  selectedRoles.length > 1 &&
                                                  (selectedRoles[3].roleName ===
                                                    "Administrator" || child.isSpecial === "1")
                                                }
                                                onChange={(e: any) => {
                                                  e.stopPropagation();
                                                  this.checkChange(
                                                    child,
                                                    e,
                                                    child.treeID
                                                  );
                                                }}
                                              ></input>
                                              <span></span>
                                            </label>
                                          </div>
                                        </Card.Header>
                                        <ul
                                          className={
                                            userRoleforTreeView[index].children[
                                              idx1
                                            ].isExpend || accordionToggle
                                              ? "collapse show"
                                              : "collapse"
                                          }
                                        >
                                          {child.children.map((child, idx2) => (
                                            <li key={idx2}>
                                              {child.children.length > 0 ? (
                                                <>
                                                  <Card.Header>
                                                    <div className="hasCheckBox cardHeader">
                                                      <label className="form-check">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          checked={
                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                              ? true
                                                              : false
                                                          }
                                                          value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                          disabled={
                                                            selectedRoles.length > 1 &&
                                                            (selectedRoles[3].roleName ===
                                                              "Administrator" || child.isSpecial === "1")
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            e.stopPropagation();
                                                            this.checkChange(
                                                              child,
                                                              e,
                                                              child.treeID
                                                            );
                                                          }}
                                                        ></input>
                                                        <span></span>
                                                      </label>
                                                    </div>
                                                  </Card.Header>
                                                  <ul
                                                    className={
                                                      userRoleforTreeView[index]
                                                        .children[idx1]
                                                        .children[idx2]
                                                        .isExpend ||
                                                        accordionToggle
                                                        ? "collapse show"
                                                        : "collapse"
                                                    }
                                                  >
                                                    {child.children.map(
                                                      (child, idx3) => (
                                                        <li key={idx3}>
                                                          {child.children
                                                            .length > 0 ? (
                                                            <>
                                                              <Card.Header>
                                                                <div className="hasCheckBox cardHeader">
                                                                  <label className="form-check">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="form-check-input"
                                                                      checked={
                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                                      disabled={
                                                                        selectedRoles.length > 1 &&
                                                                        (selectedRoles[3].roleName ===
                                                                          "Administrator" || child.isSpecial === "1")
                                                                      }
                                                                      onChange={(
                                                                        e: any
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                        this.checkChange(
                                                                          child,
                                                                          e,
                                                                          child.treeID
                                                                        );
                                                                      }}
                                                                    ></input>
                                                                    <span></span>
                                                                  </label>
                                                                </div>
                                                              </Card.Header>
                                                              <ul
                                                                className={
                                                                  userRoleforTreeView[
                                                                    index
                                                                  ].children[
                                                                    idx1
                                                                  ].children[
                                                                    idx2
                                                                  ].children[
                                                                    idx3
                                                                  ].isExpend ||
                                                                    accordionToggle
                                                                    ? "collapse show"
                                                                    : "collapse"
                                                                }
                                                              >
                                                                {child.children.map(
                                                                  (
                                                                    child,
                                                                    idx4
                                                                  ) => (
                                                                    <li
                                                                      key={idx4}
                                                                    >
                                                                      {child
                                                                        .children
                                                                        .length >
                                                                        0 ? (
                                                                        <>
                                                                          <Card.Header>
                                                                            <div className="hasCheckBox cardHeader">
                                                                              <label className="form-check">
                                                                                <input
                                                                                  type="checkbox"
                                                                                  className="form-check-input"
                                                                                  checked={
                                                                                    child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                      ? true
                                                                                      : false
                                                                                  }
                                                                                  value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                                                  disabled={
                                                                                    selectedRoles.length > 1 &&
                                                                                    (selectedRoles[3].roleName ===
                                                                                      "Administrator" || child.isSpecial === "1")
                                                                                  }
                                                                                  onChange={(
                                                                                    e: any
                                                                                  ) => {
                                                                                    e.stopPropagation();
                                                                                    this.checkChange(
                                                                                      child,
                                                                                      e,
                                                                                      child.treeID
                                                                                    );
                                                                                  }}
                                                                                ></input>
                                                                                <span></span>
                                                                              </label>
                                                                            </div>
                                                                          </Card.Header>
                                                                          <ul
                                                                            className={
                                                                              userRoleforTreeView[
                                                                                index
                                                                              ]
                                                                                .children[
                                                                                idx1
                                                                              ]
                                                                                .children[
                                                                                idx2
                                                                              ]
                                                                                .children[
                                                                                idx3
                                                                              ]
                                                                                .children[
                                                                                idx4
                                                                              ]
                                                                                .isExpend ||
                                                                                accordionToggle
                                                                                ? "collapse show"
                                                                                : "collapse"
                                                                            }
                                                                          >
                                                                            {child.children.map(
                                                                              (
                                                                                child,
                                                                                idx5
                                                                              ) => (
                                                                                <li
                                                                                  key={
                                                                                    idx5
                                                                                  }
                                                                                >
                                                                                  {child
                                                                                    .children
                                                                                    .length >
                                                                                    0 ? (
                                                                                    <>
                                                                                      <Card.Header>
                                                                                        <div className="hasCheckBox cardHeader">
                                                                                          <label className="form-check">
                                                                                            <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              checked={
                                                                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                  ? true
                                                                                                  : false
                                                                                              }
                                                                                              value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                                                              disabled={
                                                                                                selectedRoles.length > 1 &&
                                                                                                (selectedRoles[3].roleName ===
                                                                                                  "Administrator" || child.isSpecial === "1")
                                                                                              }
                                                                                              onChange={(
                                                                                                e: any
                                                                                              ) => {
                                                                                                e.stopPropagation();
                                                                                                this.checkChange(
                                                                                                  child,
                                                                                                  e,
                                                                                                  child.treeID
                                                                                                );
                                                                                              }}
                                                                                            ></input>
                                                                                            <span></span>
                                                                                          </label>
                                                                                        </div>
                                                                                      </Card.Header>
                                                                                      <ul
                                                                                        className={
                                                                                          userRoleforTreeView[
                                                                                            index
                                                                                          ]
                                                                                            .children[
                                                                                            idx1
                                                                                          ]
                                                                                            .children[
                                                                                            idx2
                                                                                          ]
                                                                                            .children[
                                                                                            idx3
                                                                                          ]
                                                                                            .children[
                                                                                            idx4
                                                                                          ]
                                                                                            .children[
                                                                                            idx5
                                                                                          ]
                                                                                            .isExpend ||
                                                                                            accordionToggle
                                                                                            ? "collapse show"
                                                                                            : "collapse"
                                                                                        }
                                                                                      >
                                                                                        {child.children.map(
                                                                                          (
                                                                                            child,
                                                                                            idx6
                                                                                          ) => (
                                                                                            <li
                                                                                              key={
                                                                                                idx6
                                                                                              }
                                                                                            >
                                                                                              {child
                                                                                                .children
                                                                                                .length >
                                                                                                0 ? (
                                                                                                <>
                                                                                                  <Card.Header>
                                                                                                    <div className="hasCheckBox cardHeader">
                                                                                                      <label className="form-check">
                                                                                                        <input
                                                                                                          type="checkbox"
                                                                                                          className="form-check-input"
                                                                                                          checked={
                                                                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                              ? true
                                                                                                              : false
                                                                                                          }
                                                                                                          value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                                                                          disabled={
                                                                                                            selectedRoles.length > 1 &&
                                                                                                            (selectedRoles[3].roleName ===
                                                                                                              "Administrator" || child.isSpecial === "1")
                                                                                                          }
                                                                                                          onChange={(
                                                                                                            e: any
                                                                                                          ) => {
                                                                                                            e.stopPropagation();
                                                                                                            this.checkChange(
                                                                                                              child,
                                                                                                              e,
                                                                                                              child.treeID
                                                                                                            );
                                                                                                          }}
                                                                                                        ></input>
                                                                                                        <span></span>
                                                                                                      </label>
                                                                                                    </div>
                                                                                                  </Card.Header>
                                                                                                  <ul
                                                                                                    className={
                                                                                                      userRoleforTreeView[
                                                                                                        index
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx1
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx2
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx3
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx4
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx5
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx6
                                                                                                      ]
                                                                                                        .isExpend ||
                                                                                                        accordionToggle
                                                                                                        ? "collapse show"
                                                                                                        : "collapse"
                                                                                                    }
                                                                                                  >
                                                                                                    {child.children.map(
                                                                                                      (
                                                                                                        child,
                                                                                                        idx7
                                                                                                      ) => (
                                                                                                        <li
                                                                                                          key={
                                                                                                            idx7
                                                                                                          }
                                                                                                        >
                                                                                                          {child
                                                                                                            .children
                                                                                                            .length >
                                                                                                            0 ? (
                                                                                                            <>
                                                                                                              <Card.Header>
                                                                                                                <div className="hasCheckBox cardHeader">
                                                                                                                  <label className="form-check">
                                                                                                                    <input
                                                                                                                      type="checkbox"
                                                                                                                      className="form-check-input"
                                                                                                                      checked={
                                                                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                          ? true
                                                                                                                          : false
                                                                                                                      }
                                                                                                                      value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                                                                                      disabled={
                                                                                                                        selectedRoles.length > 1 &&
                                                                                                                        (selectedRoles[3].roleName ===
                                                                                                                          "Administrator" || child.isSpecial === "1")
                                                                                                                      }
                                                                                                                      onChange={(
                                                                                                                        e: any
                                                                                                                      ) => {
                                                                                                                        e.stopPropagation();
                                                                                                                        this.checkChange(
                                                                                                                          child,
                                                                                                                          e,
                                                                                                                          child.treeID
                                                                                                                        );
                                                                                                                      }}
                                                                                                                    ></input>
                                                                                                                    <span></span>
                                                                                                                  </label>
                                                                                                                </div>
                                                                                                              </Card.Header>
                                                                                                              <div
                                                                                                                className={
                                                                                                                  userRoleforTreeView[
                                                                                                                    index
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx1
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx2
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx3
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx4
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx5
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx6
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx7
                                                                                                                  ]
                                                                                                                    .isExpend ||
                                                                                                                    accordionToggle
                                                                                                                    ? "collapse show"
                                                                                                                    : "collapse"
                                                                                                                }
                                                                                                              >
                                                                                                                <>

                                                                                                                </>
                                                                                                              </div>
                                                                                                            </>
                                                                                                          ) : (
                                                                                                            <Card.Header>
                                                                                                              <div className="hasCheckBox cardHeader">
                                                                                                                <label className="form-check">
                                                                                                                  <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    checked={
                                                                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                        ? true
                                                                                                                        : false
                                                                                                                    }
                                                                                                                    value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                                                                                    disabled={
                                                                                                                      selectedRoles.length > 1 &&
                                                                                                                      (selectedRoles[3].roleName ===
                                                                                                                        "Administrator" || child.isSpecial === "1")
                                                                                                                    }
                                                                                                                    onChange={(
                                                                                                                      e: any
                                                                                                                    ) => {
                                                                                                                      e.stopPropagation();
                                                                                                                      this.checkChange(
                                                                                                                        child,
                                                                                                                        e,
                                                                                                                        child.treeID
                                                                                                                      );
                                                                                                                    }}
                                                                                                                  ></input>
                                                                                                                  <span></span>
                                                                                                                </label>
                                                                                                              </div>
                                                                                                            </Card.Header>
                                                                                                          )}
                                                                                                        </li>
                                                                                                      )
                                                                                                    )}
                                                                                                  </ul>
                                                                                                </>
                                                                                              ) : (
                                                                                                <Card.Header>
                                                                                                  <div className="hasCheckBox cardHeader">
                                                                                                    <label className="form-check">
                                                                                                      <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        checked={
                                                                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                            ? true
                                                                                                            : false
                                                                                                        }
                                                                                                        value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                                                                        disabled={
                                                                                                          selectedRoles.length > 1 &&
                                                                                                          (selectedRoles[3].roleName ===
                                                                                                            "Administrator" || child.isSpecial === "1")
                                                                                                        }
                                                                                                        onChange={(
                                                                                                          e: any
                                                                                                        ) => {
                                                                                                          e.stopPropagation();
                                                                                                          this.checkChange(
                                                                                                            child,
                                                                                                            e,
                                                                                                            child.treeID
                                                                                                          );
                                                                                                        }}
                                                                                                      ></input>
                                                                                                      <span></span>
                                                                                                    </label>
                                                                                                  </div>
                                                                                                </Card.Header>
                                                                                              )}
                                                                                            </li>
                                                                                          )
                                                                                        )}
                                                                                      </ul>
                                                                                    </>
                                                                                  ) : (
                                                                                    <Card.Header>
                                                                                      <div className="hasCheckBox cardHeader">
                                                                                        <label className="form-check">
                                                                                          <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            checked={
                                                                                              child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                ? true
                                                                                                : false
                                                                                            }
                                                                                            value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                                                            disabled={
                                                                                              selectedRoles.length > 1 &&
                                                                                              (selectedRoles[3].roleName ===
                                                                                                "Administrator" || child.isSpecial === "1")
                                                                                            }
                                                                                            onChange={(
                                                                                              e: any
                                                                                            ) => {
                                                                                              e.stopPropagation();
                                                                                              this.checkChange(
                                                                                                child,
                                                                                                e,
                                                                                                child.treeID
                                                                                              );
                                                                                            }}
                                                                                          ></input>
                                                                                          <span></span>
                                                                                        </label>
                                                                                      </div>
                                                                                    </Card.Header>
                                                                                  )}
                                                                                </li>
                                                                              )
                                                                            )}
                                                                          </ul>
                                                                        </>
                                                                      ) : (
                                                                        <Card.Header>
                                                                          <div className="hasCheckBox cardHeader">
                                                                            <label className="form-check">
                                                                              <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={
                                                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                    ? true
                                                                                    : false
                                                                                }
                                                                                value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                                                disabled={
                                                                                  selectedRoles.length > 1 &&
                                                                                  (selectedRoles[3].roleName ===
                                                                                    "Administrator" || child.isSpecial === "1")
                                                                                }
                                                                                onChange={(
                                                                                  e: any
                                                                                ) => {
                                                                                  e.stopPropagation();
                                                                                  this.checkChange(
                                                                                    child,
                                                                                    e,
                                                                                    child.treeID
                                                                                  );
                                                                                }}
                                                                              ></input>
                                                                              <span></span>
                                                                            </label>
                                                                          </div>
                                                                        </Card.Header>
                                                                      )}
                                                                    </li>
                                                                  )
                                                                )}
                                                              </ul>
                                                            </>
                                                          ) : (
                                                            <Card.Header>
                                                              <div className="hasCheckBox cardHeader">
                                                                <label className="form-check">
                                                                  <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={
                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                                    disabled={
                                                                      selectedRoles.length > 1 &&
                                                                      (selectedRoles[3].roleName ===
                                                                        "Administrator" || child.isSpecial === "1")
                                                                    }
                                                                    onChange={(
                                                                      e: any
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      this.checkChange(
                                                                        child,
                                                                        e,
                                                                        child.treeID
                                                                      );
                                                                    }}
                                                                  ></input>
                                                                  <span></span>
                                                                </label>
                                                              </div>
                                                            </Card.Header>
                                                          )}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </>
                                              ) : (
                                                <Card.Header>
                                                  <div className="hasCheckBox cardHeader">
                                                    <label className="form-check">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={
                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                            ? true
                                                            : false
                                                        }
                                                        value={`${selectedRoles[3].roleID}:${child.childID}`}
                                                        disabled={
                                                          selectedRoles.length > 1 &&
                                                          (selectedRoles[3].roleName ===
                                                            "Administrator" || child.isSpecial === "1")
                                                        }
                                                        onChange={(e: any) => {
                                                          e.stopPropagation();
                                                          this.checkChange(
                                                            child,
                                                            e,
                                                            child.treeID
                                                          );
                                                        }}
                                                      ></input>
                                                      <span></span>
                                                    </label>
                                                  </div>
                                                </Card.Header>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    ) : (
                                      <Card.Header className="hasNoChild">
                                        <div className="hasCheckBox cardHeader">
                                          <label className="form-check">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                  ? true
                                                  : false
                                              }
                                              value={`${selectedRoles[3].roleID}:${child.childID}`}
                                              disabled={
                                                selectedRoles.length > 1 &&
                                                (selectedRoles[3].roleName ===
                                                  "Administrator" || child.isSpecial === "1")
                                              }
                                              onChange={(e: any) => {
                                                e.stopPropagation();
                                                this.checkChange(
                                                  child,
                                                  e,
                                                  child.treeID
                                                );
                                              }}
                                            ></input>
                                            <span></span>
                                          </label>
                                        </div>
                                      </Card.Header>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                  {selectedRoles.length > 4 && (
                    <div className="column1">
                      <div className="rollMatrixAccordionHeader">
                        <div className="colPadd text-center">
                          <div className="heading">
                            <EllipsisWithTooltip placement="bottom">
                              {selectedRoles.length > 4 ? (
                                selectedRoles[4].roleName
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </EllipsisWithTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <ul className="rollAccordion listAccordion">
                          {roleforTreeView4.map((items, index) => (
                            <li
                              key={index}
                              className={`${userRoleforTreeView[index].isExpend |
                                accordionToggle
                                ? "matrixBottomShaddow"
                                : ""
                                }`}
                            >
                              <Card.Header>
                                <div
                                  className={`${selectedRoles.length > 4 &&
                                    (selectedRoles[4].roleName ===
                                      "Administrator"
                                      ? "matrixAssignedPanel matrixhasAdmin"
                                      : "matrixAssignedPanel")
                                    }`}
                                >
                                  <div className="content border_l">
                                    <div className="content-heading">
                                      {items.permisionCount === "0"
                                        ? items.permisionCount
                                        : items.permisionCount - 1}{" "}
                                      / {items.modulePermisisonCount - 1}
                                    </div>
                                    <span className="content-subhead">
                                      Assigned
                                    </span>
                                  </div>
                                </div>
                              </Card.Header>
                              <ul
                                className={
                                  userRoleforTreeView[index].isExpend ||
                                    accordionToggle
                                    ? "collapse show border_r"
                                    : "collapse"
                                }
                              >
                                {items.children.map((child, idx1) => (
                                  <li key={idx1}>
                                    {child.children.length > 0 ? (
                                      <>
                                        <Card.Header>
                                          <div className="hasCheckBox cardHeader">
                                            <label className="form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={
                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                    ? true
                                                    : false
                                                }
                                                value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                disabled={
                                                  selectedRoles.length > 1 &&
                                                  (selectedRoles[4].roleName ===
                                                    "Administrator" || child.isSpecial === "1")
                                                }
                                                onChange={(e: any) => {
                                                  e.stopPropagation();
                                                  this.checkChange(
                                                    child,
                                                    e,
                                                    child.treeID
                                                  );
                                                }}
                                              ></input>
                                              <span></span>
                                            </label>
                                          </div>
                                        </Card.Header>
                                        <ul
                                          className={
                                            userRoleforTreeView[index].children[
                                              idx1
                                            ].isExpend || accordionToggle
                                              ? "collapse show"
                                              : "collapse"
                                          }
                                        >
                                          {child.children.map((child, idx2) => (
                                            <li key={idx2}>
                                              {child.children.length > 0 ? (
                                                <>
                                                  <Card.Header>
                                                    <div className="hasCheckBox cardHeader">
                                                      <label className="form-check">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          checked={
                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                              ? true
                                                              : false
                                                          }
                                                          value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                          disabled={
                                                            selectedRoles.length > 1 &&
                                                            (selectedRoles[4].roleName ===
                                                              "Administrator" || child.isSpecial === "1")
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            e.stopPropagation();
                                                            this.checkChange(
                                                              child,
                                                              e,
                                                              child.treeID
                                                            );
                                                          }}
                                                        ></input>
                                                        <span></span>
                                                      </label>
                                                    </div>
                                                  </Card.Header>
                                                  <ul
                                                    className={
                                                      userRoleforTreeView[index]
                                                        .children[idx1]
                                                        .children[idx2]
                                                        .isExpend ||
                                                        accordionToggle
                                                        ? "collapse show"
                                                        : "collapse"
                                                    }
                                                  >
                                                    {child.children.map(
                                                      (child, idx3) => (
                                                        <li key={idx3}>
                                                          {child.children
                                                            .length > 0 ? (
                                                            <>
                                                              <Card.Header>
                                                                <div className="hasCheckBox cardHeader">
                                                                  <label className="form-check">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="form-check-input"
                                                                      checked={
                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                                      disabled={
                                                                        selectedRoles.length > 1 &&
                                                                        (selectedRoles[4].roleName ===
                                                                          "Administrator" || child.isSpecial === "1")
                                                                      }
                                                                      onChange={(
                                                                        e: any
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                        this.checkChange(
                                                                          child,
                                                                          e,
                                                                          child.treeID
                                                                        );
                                                                      }}
                                                                    ></input>
                                                                    <span></span>
                                                                  </label>
                                                                </div>
                                                              </Card.Header>
                                                              <ul
                                                                className={
                                                                  userRoleforTreeView[
                                                                    index
                                                                  ].children[
                                                                    idx1
                                                                  ].children[
                                                                    idx2
                                                                  ].children[
                                                                    idx3
                                                                  ].isExpend ||
                                                                    accordionToggle
                                                                    ? "collapse show"
                                                                    : "collapse"
                                                                }
                                                              >
                                                                {child.children.map(
                                                                  (
                                                                    child,
                                                                    idx4
                                                                  ) => (
                                                                    <li
                                                                      key={idx4}
                                                                    >
                                                                      {child
                                                                        .children
                                                                        .length >
                                                                        0 ? (
                                                                        <>
                                                                          <Card.Header>
                                                                            <div className="hasCheckBox cardHeader">
                                                                              <label className="form-check">
                                                                                <input
                                                                                  type="checkbox"
                                                                                  className="form-check-input"
                                                                                  checked={
                                                                                    child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                      ? true
                                                                                      : false
                                                                                  }
                                                                                  value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                                                  disabled={
                                                                                    selectedRoles.length > 1 &&
                                                                                    (selectedRoles[4].roleName ===
                                                                                      "Administrator" || child.isSpecial === "1")
                                                                                  }
                                                                                  onChange={(
                                                                                    e: any
                                                                                  ) => {
                                                                                    e.stopPropagation();
                                                                                    this.checkChange(
                                                                                      child,
                                                                                      e,
                                                                                      child.treeID
                                                                                    );
                                                                                  }}
                                                                                ></input>
                                                                                <span></span>
                                                                              </label>
                                                                            </div>
                                                                          </Card.Header>
                                                                          <ul
                                                                            className={
                                                                              userRoleforTreeView[
                                                                                index
                                                                              ]
                                                                                .children[
                                                                                idx1
                                                                              ]
                                                                                .children[
                                                                                idx2
                                                                              ]
                                                                                .children[
                                                                                idx3
                                                                              ]
                                                                                .children[
                                                                                idx4
                                                                              ]
                                                                                .isExpend ||
                                                                                accordionToggle
                                                                                ? "collapse show"
                                                                                : "collapse"
                                                                            }
                                                                          >
                                                                            {child.children.map(
                                                                              (
                                                                                child,
                                                                                idx5
                                                                              ) => (
                                                                                <li
                                                                                  key={
                                                                                    idx5
                                                                                  }
                                                                                >
                                                                                  {child
                                                                                    .children
                                                                                    .length >
                                                                                    0 ? (
                                                                                    <>
                                                                                      <Card.Header>
                                                                                        <div className="hasCheckBox cardHeader">
                                                                                          <label className="form-check">
                                                                                            <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              checked={
                                                                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                  ? true
                                                                                                  : false
                                                                                              }
                                                                                              value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                                                              disabled={
                                                                                                selectedRoles.length > 1 &&
                                                                                                (selectedRoles[4].roleName ===
                                                                                                  "Administrator" || child.isSpecial === "1")
                                                                                              }
                                                                                              onChange={(
                                                                                                e: any
                                                                                              ) => {
                                                                                                e.stopPropagation();
                                                                                                this.checkChange(
                                                                                                  child,
                                                                                                  e,
                                                                                                  child.treeID
                                                                                                );
                                                                                              }}
                                                                                            ></input>
                                                                                            <span></span>
                                                                                          </label>
                                                                                        </div>
                                                                                      </Card.Header>
                                                                                      <ul
                                                                                        className={
                                                                                          userRoleforTreeView[
                                                                                            index
                                                                                          ]
                                                                                            .children[
                                                                                            idx1
                                                                                          ]
                                                                                            .children[
                                                                                            idx2
                                                                                          ]
                                                                                            .children[
                                                                                            idx3
                                                                                          ]
                                                                                            .children[
                                                                                            idx4
                                                                                          ]
                                                                                            .children[
                                                                                            idx5
                                                                                          ]
                                                                                            .isExpend ||
                                                                                            accordionToggle
                                                                                            ? "collapse show"
                                                                                            : "collapse"
                                                                                        }
                                                                                      >
                                                                                        {child.children.map(
                                                                                          (
                                                                                            child,
                                                                                            idx6
                                                                                          ) => (
                                                                                            <li
                                                                                              key={
                                                                                                idx6
                                                                                              }
                                                                                            >
                                                                                              {child
                                                                                                .children
                                                                                                .length >
                                                                                                0 ? (
                                                                                                <>
                                                                                                  <Card.Header>
                                                                                                    <div className="hasCheckBox cardHeader">
                                                                                                      <label className="form-check">
                                                                                                        <input
                                                                                                          type="checkbox"
                                                                                                          className="form-check-input"
                                                                                                          checked={
                                                                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                              ? true
                                                                                                              : false
                                                                                                          }
                                                                                                          value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                                                                          disabled={
                                                                                                            selectedRoles.length > 1 &&
                                                                                                            (selectedRoles[4].roleName ===
                                                                                                              "Administrator" || child.isSpecial === "1")
                                                                                                          }
                                                                                                          onChange={(
                                                                                                            e: any
                                                                                                          ) => {
                                                                                                            e.stopPropagation();
                                                                                                            this.checkChange(
                                                                                                              child,
                                                                                                              e,
                                                                                                              child.treeID
                                                                                                            );
                                                                                                          }}
                                                                                                        ></input>
                                                                                                        <span></span>
                                                                                                      </label>
                                                                                                    </div>
                                                                                                  </Card.Header>
                                                                                                  <ul
                                                                                                    className={
                                                                                                      userRoleforTreeView[
                                                                                                        index
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx1
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx2
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx3
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx4
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx5
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx6
                                                                                                      ]
                                                                                                        .isExpend ||
                                                                                                        accordionToggle
                                                                                                        ? "collapse show"
                                                                                                        : "collapse"
                                                                                                    }
                                                                                                  >
                                                                                                    {child.children.map(
                                                                                                      (
                                                                                                        child,
                                                                                                        idx7
                                                                                                      ) => (
                                                                                                        <li
                                                                                                          key={
                                                                                                            idx7
                                                                                                          }
                                                                                                        >
                                                                                                          {child
                                                                                                            .children
                                                                                                            .length >
                                                                                                            0 ? (
                                                                                                            <>
                                                                                                              <Card.Header>
                                                                                                                <div className="hasCheckBox cardHeader">
                                                                                                                  <label className="form-check">
                                                                                                                    <input
                                                                                                                      type="checkbox"
                                                                                                                      className="form-check-input"
                                                                                                                      checked={
                                                                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                          ? true
                                                                                                                          : false
                                                                                                                      }
                                                                                                                      value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                                                                                      disabled={
                                                                                                                        selectedRoles.length > 1 &&
                                                                                                                        (selectedRoles[4].roleName ===
                                                                                                                          "Administrator" || child.isSpecial === "1")
                                                                                                                      }
                                                                                                                      onChange={(
                                                                                                                        e: any
                                                                                                                      ) => {
                                                                                                                        e.stopPropagation();
                                                                                                                        this.checkChange(
                                                                                                                          child,
                                                                                                                          e,
                                                                                                                          child.treeID
                                                                                                                        );
                                                                                                                      }}
                                                                                                                    ></input>
                                                                                                                    <span></span>
                                                                                                                  </label>
                                                                                                                </div>
                                                                                                              </Card.Header>
                                                                                                              <div
                                                                                                                className={
                                                                                                                  userRoleforTreeView[
                                                                                                                    index
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx1
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx2
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx3
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx4
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx5
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx6
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx7
                                                                                                                  ]
                                                                                                                    .isExpend ||
                                                                                                                    accordionToggle
                                                                                                                    ? "collapse show"
                                                                                                                    : "collapse"
                                                                                                                }
                                                                                                              >
                                                                                                                <>

                                                                                                                </>
                                                                                                              </div>
                                                                                                            </>
                                                                                                          ) : (
                                                                                                            <Card.Header>
                                                                                                              <div className="hasCheckBox cardHeader">
                                                                                                                <label className="form-check">
                                                                                                                  <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    checked={
                                                                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                        ? true
                                                                                                                        : false
                                                                                                                    }
                                                                                                                    value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                                                                                    disabled={
                                                                                                                      selectedRoles.length > 1 &&
                                                                                                                      (selectedRoles[4].roleName ===
                                                                                                                        "Administrator" || child.isSpecial === "1")
                                                                                                                    }
                                                                                                                    onChange={(
                                                                                                                      e: any
                                                                                                                    ) => {
                                                                                                                      e.stopPropagation();
                                                                                                                      this.checkChange(
                                                                                                                        child,
                                                                                                                        e,
                                                                                                                        child.treeID
                                                                                                                      );
                                                                                                                    }}
                                                                                                                  ></input>
                                                                                                                  <span></span>
                                                                                                                </label>
                                                                                                              </div>
                                                                                                            </Card.Header>
                                                                                                          )}
                                                                                                        </li>
                                                                                                      )
                                                                                                    )}
                                                                                                  </ul>
                                                                                                </>
                                                                                              ) : (
                                                                                                <Card.Header>
                                                                                                  <div className="hasCheckBox cardHeader">
                                                                                                    <label className="form-check">
                                                                                                      <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        checked={
                                                                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                            ? true
                                                                                                            : false
                                                                                                        }
                                                                                                        value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                                                                        disabled={
                                                                                                          selectedRoles.length > 1 &&
                                                                                                          (selectedRoles[4].roleName ===
                                                                                                            "Administrator" || child.isSpecial === "1")
                                                                                                        }
                                                                                                        onChange={(
                                                                                                          e: any
                                                                                                        ) => {
                                                                                                          e.stopPropagation();
                                                                                                          this.checkChange(
                                                                                                            child,
                                                                                                            e,
                                                                                                            child.treeID
                                                                                                          );
                                                                                                        }}
                                                                                                      ></input>
                                                                                                      <span></span>
                                                                                                    </label>
                                                                                                  </div>
                                                                                                </Card.Header>
                                                                                              )}
                                                                                            </li>
                                                                                          )
                                                                                        )}
                                                                                      </ul>
                                                                                    </>
                                                                                  ) : (
                                                                                    <Card.Header>
                                                                                      <div className="hasCheckBox cardHeader">
                                                                                        <label className="form-check">
                                                                                          <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            checked={
                                                                                              child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                ? true
                                                                                                : false
                                                                                            }
                                                                                            value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                                                            disabled={
                                                                                              selectedRoles.length > 1 &&
                                                                                              (selectedRoles[4].roleName ===
                                                                                                "Administrator" || child.isSpecial === "1")
                                                                                            }
                                                                                            onChange={(
                                                                                              e: any
                                                                                            ) => {
                                                                                              e.stopPropagation();
                                                                                              this.checkChange(
                                                                                                child,
                                                                                                e,
                                                                                                child.treeID
                                                                                              );
                                                                                            }}
                                                                                          ></input>
                                                                                          <span></span>
                                                                                        </label>
                                                                                      </div>
                                                                                    </Card.Header>
                                                                                  )}
                                                                                </li>
                                                                              )
                                                                            )}
                                                                          </ul>
                                                                        </>
                                                                      ) : (
                                                                        <Card.Header>
                                                                          <div className="hasCheckBox cardHeader">
                                                                            <label className="form-check">
                                                                              <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={
                                                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                    ? true
                                                                                    : false
                                                                                }
                                                                                value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                                                disabled={
                                                                                  selectedRoles.length > 1 &&
                                                                                  (selectedRoles[4].roleName ===
                                                                                    "Administrator" || child.isSpecial === "1")
                                                                                }
                                                                                onChange={(
                                                                                  e: any
                                                                                ) => {
                                                                                  e.stopPropagation();
                                                                                  this.checkChange(
                                                                                    child,
                                                                                    e,
                                                                                    child.treeID
                                                                                  );
                                                                                }}
                                                                              ></input>
                                                                              <span></span>
                                                                            </label>
                                                                          </div>
                                                                        </Card.Header>
                                                                      )}
                                                                    </li>
                                                                  )
                                                                )}
                                                              </ul>
                                                            </>
                                                          ) : (
                                                            <Card.Header>
                                                              <div className="hasCheckBox cardHeader">
                                                                <label className="form-check">
                                                                  <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={
                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                                    disabled={
                                                                      selectedRoles.length > 1 &&
                                                                      (selectedRoles[4].roleName ===
                                                                        "Administrator" || child.isSpecial === "1")
                                                                    }
                                                                    onChange={(
                                                                      e: any
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      this.checkChange(
                                                                        child,
                                                                        e,
                                                                        child.treeID
                                                                      );
                                                                    }}
                                                                  ></input>
                                                                  <span></span>
                                                                </label>
                                                              </div>
                                                            </Card.Header>
                                                          )}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </>
                                              ) : (
                                                <Card.Header>
                                                  <div className="hasCheckBox cardHeader">
                                                    <label className="form-check">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={
                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                            ? true
                                                            : false
                                                        }
                                                        value={`${selectedRoles[4].roleID}:${child.childID}`}
                                                        disabled={
                                                          selectedRoles.length > 1 &&
                                                          (selectedRoles[4].roleName ===
                                                            "Administrator" || child.isSpecial === "1")
                                                        }
                                                        onChange={(e: any) => {
                                                          e.stopPropagation();
                                                          this.checkChange(
                                                            child,
                                                            e,
                                                            child.treeID
                                                          );
                                                        }}
                                                      ></input>
                                                      <span></span>
                                                    </label>
                                                  </div>
                                                </Card.Header>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    ) : (
                                      <Card.Header className="hasNoChild">
                                        <div className="hasCheckBox cardHeader">
                                          <label className="form-check">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                  ? true
                                                  : false
                                              }
                                              value={`${selectedRoles[4].roleID}:${child.childID}`}
                                              disabled={
                                                selectedRoles.length > 1 &&
                                                (selectedRoles[4].roleName ===
                                                  "Administrator" || child.isSpecial === "1")
                                              }
                                              onChange={(e: any) => {
                                                e.stopPropagation();
                                                this.checkChange(
                                                  child,
                                                  e,
                                                  child.treeID
                                                );
                                              }}
                                            ></input>
                                            <span></span>
                                          </label>
                                        </div>
                                      </Card.Header>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                  {selectedRoles.length > 5 && (
                    <div className="column1">
                      <div className="rollMatrixAccordionHeader">
                        <div className="colPadd text-center">
                          <div className="heading">
                            <EllipsisWithTooltip placement="bottom">
                              {selectedRoles.length > 5 ? (
                                selectedRoles[5].roleName
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </EllipsisWithTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <ul className="rollAccordion listAccordion">
                          {roleforTreeView5.map((items, index) => (
                            <li
                              key={index}
                              className={`${userRoleforTreeView[index].isExpend |
                                accordionToggle
                                ? "matrixBottomShaddow"
                                : ""
                                }`}
                            >
                              <Card.Header>
                                <div
                                  className={`${selectedRoles.length > 5 &&
                                    (selectedRoles[5].roleName ===
                                      "Administrator"
                                      ? "matrixAssignedPanel matrixhasAdmin"
                                      : "matrixAssignedPanel")
                                    }`}
                                >
                                  <div className="content border_l">
                                    <div className="content-heading">
                                      {items.permisionCount === "0"
                                        ? items.permisionCount
                                        : items.permisionCount - 1}{" "}
                                      / {items.modulePermisisonCount - 1}
                                    </div>
                                    <span className="content-subhead">
                                      Assigned
                                    </span>
                                  </div>
                                </div>
                              </Card.Header>
                              <ul
                                className={
                                  userRoleforTreeView[index].isExpend ||
                                    accordionToggle
                                    ? "collapse show border_r"
                                    : "collapse"
                                }
                              >
                                {items.children.map((child, idx1) => (
                                  <li key={idx1}>
                                    {child.children.length > 0 ? (
                                      <>
                                        <Card.Header>
                                          <div className="hasCheckBox cardHeader">
                                            <label className="form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={
                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                    ? true
                                                    : false
                                                }
                                                value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                disabled={
                                                  selectedRoles.length > 1 &&
                                                  (selectedRoles[5].roleName ===
                                                    "Administrator" || child.isSpecial === "1")
                                                }
                                                onChange={(e: any) => {
                                                  e.stopPropagation();
                                                  this.checkChange(
                                                    child,
                                                    e,
                                                    child.treeID
                                                  );
                                                }}
                                              ></input>
                                              <span></span>
                                            </label>
                                          </div>
                                        </Card.Header>
                                        <ul
                                          className={
                                            userRoleforTreeView[index].children[
                                              idx1
                                            ].isExpend || accordionToggle
                                              ? "collapse show"
                                              : "collapse"
                                          }
                                        >
                                          {child.children.map((child, idx2) => (
                                            <li key={idx2}>
                                              {child.children.length > 0 ? (
                                                <>
                                                  <Card.Header>
                                                    <div className="hasCheckBox cardHeader">
                                                      <label className="form-check">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          checked={
                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                              ? true
                                                              : false
                                                          }
                                                          value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                          disabled={
                                                            selectedRoles.length > 1 &&
                                                            (selectedRoles[5].roleName ===
                                                              "Administrator" || child.isSpecial === "1")
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            e.stopPropagation();
                                                            this.checkChange(
                                                              child,
                                                              e,
                                                              child.treeID
                                                            );
                                                          }}
                                                        ></input>
                                                        <span></span>
                                                      </label>
                                                    </div>
                                                  </Card.Header>
                                                  <ul
                                                    className={
                                                      userRoleforTreeView[index]
                                                        .children[idx1]
                                                        .children[idx2]
                                                        .isExpend ||
                                                        accordionToggle
                                                        ? "collapse show"
                                                        : "collapse"
                                                    }
                                                  >
                                                    {child.children.map(
                                                      (child, idx3) => (
                                                        <li key={idx3}>
                                                          {child.children
                                                            .length > 0 ? (
                                                            <>
                                                              <Card.Header>
                                                                <div className="hasCheckBox cardHeader">
                                                                  <label className="form-check">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="form-check-input"
                                                                      checked={
                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                                      disabled={
                                                                        selectedRoles.length > 1 &&
                                                                        (selectedRoles[5].roleName ===
                                                                          "Administrator" || child.isSpecial === "1")
                                                                      }
                                                                      onChange={(
                                                                        e: any
                                                                      ) => {
                                                                        e.stopPropagation();
                                                                        this.checkChange(
                                                                          child,
                                                                          e,
                                                                          child.treeID
                                                                        );
                                                                      }}
                                                                    ></input>
                                                                    <span></span>
                                                                  </label>
                                                                </div>
                                                              </Card.Header>
                                                              <ul
                                                                className={
                                                                  userRoleforTreeView[
                                                                    index
                                                                  ].children[
                                                                    idx1
                                                                  ].children[
                                                                    idx2
                                                                  ].children[
                                                                    idx3
                                                                  ].isExpend ||
                                                                    accordionToggle
                                                                    ? "collapse show"
                                                                    : "collapse"
                                                                }
                                                              >
                                                                {child.children.map(
                                                                  (
                                                                    child,
                                                                    idx4
                                                                  ) => (
                                                                    <li
                                                                      key={idx4}
                                                                    >
                                                                      {child
                                                                        .children
                                                                        .length >
                                                                        0 ? (
                                                                        <>
                                                                          <Card.Header>
                                                                            <div className="hasCheckBox cardHeader">
                                                                              <label className="form-check">
                                                                                <input
                                                                                  type="checkbox"
                                                                                  className="form-check-input"
                                                                                  checked={
                                                                                    child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                      ? true
                                                                                      : false
                                                                                  }
                                                                                  value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                                                  disabled={
                                                                                    selectedRoles.length > 1 &&
                                                                                    (selectedRoles[5].roleName ===
                                                                                      "Administrator" || child.isSpecial === "1")
                                                                                  }
                                                                                  onChange={(
                                                                                    e: any
                                                                                  ) => {
                                                                                    e.stopPropagation();
                                                                                    this.checkChange(
                                                                                      child,
                                                                                      e,
                                                                                      child.treeID
                                                                                    );
                                                                                  }}
                                                                                ></input>
                                                                                <span></span>
                                                                              </label>
                                                                            </div>
                                                                          </Card.Header>
                                                                          <ul
                                                                            className={
                                                                              userRoleforTreeView[
                                                                                index
                                                                              ]
                                                                                .children[
                                                                                idx1
                                                                              ]
                                                                                .children[
                                                                                idx2
                                                                              ]
                                                                                .children[
                                                                                idx3
                                                                              ]
                                                                                .children[
                                                                                idx4
                                                                              ]
                                                                                .isExpend ||
                                                                                accordionToggle
                                                                                ? "collapse show"
                                                                                : "collapse"
                                                                            }
                                                                          >
                                                                            {child.children.map(
                                                                              (
                                                                                child,
                                                                                idx5
                                                                              ) => (
                                                                                <li
                                                                                  key={
                                                                                    idx5
                                                                                  }
                                                                                >
                                                                                  {child
                                                                                    .children
                                                                                    .length >
                                                                                    0 ? (
                                                                                    <>
                                                                                      <Card.Header>
                                                                                        <div className="hasCheckBox cardHeader">
                                                                                          <label className="form-check">
                                                                                            <input
                                                                                              type="checkbox"
                                                                                              className="form-check-input"
                                                                                              checked={
                                                                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                  ? true
                                                                                                  : false
                                                                                              }
                                                                                              value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                                                              disabled={
                                                                                                selectedRoles.length > 1 &&
                                                                                                (selectedRoles[5].roleName ===
                                                                                                  "Administrator" || child.isSpecial === "1")
                                                                                              }
                                                                                              onChange={(
                                                                                                e: any
                                                                                              ) => {
                                                                                                e.stopPropagation();
                                                                                                this.checkChange(
                                                                                                  child,
                                                                                                  e,
                                                                                                  child.treeID
                                                                                                );
                                                                                              }}
                                                                                            ></input>
                                                                                            <span></span>
                                                                                          </label>
                                                                                        </div>
                                                                                      </Card.Header>
                                                                                      <ul
                                                                                        className={
                                                                                          userRoleforTreeView[
                                                                                            index
                                                                                          ]
                                                                                            .children[
                                                                                            idx1
                                                                                          ]
                                                                                            .children[
                                                                                            idx2
                                                                                          ]
                                                                                            .children[
                                                                                            idx3
                                                                                          ]
                                                                                            .children[
                                                                                            idx4
                                                                                          ]
                                                                                            .children[
                                                                                            idx5
                                                                                          ]
                                                                                            .isExpend ||
                                                                                            accordionToggle
                                                                                            ? "collapse show"
                                                                                            : "collapse"
                                                                                        }
                                                                                      >
                                                                                        {child.children.map(
                                                                                          (
                                                                                            child,
                                                                                            idx6
                                                                                          ) => (
                                                                                            <li
                                                                                              key={
                                                                                                idx6
                                                                                              }
                                                                                            >
                                                                                              {child
                                                                                                .children
                                                                                                .length >
                                                                                                0 ? (
                                                                                                <>
                                                                                                  <Card.Header>
                                                                                                    <div className="hasCheckBox cardHeader">
                                                                                                      <label className="form-check">
                                                                                                        <input
                                                                                                          type="checkbox"
                                                                                                          className="form-check-input"
                                                                                                          checked={
                                                                                                            child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                              ? true
                                                                                                              : false
                                                                                                          }
                                                                                                          value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                                                                          disabled={
                                                                                                            selectedRoles.length > 1 &&
                                                                                                            (selectedRoles[5].roleName ===
                                                                                                              "Administrator" || child.isSpecial === "1")
                                                                                                          }
                                                                                                          onChange={(
                                                                                                            e: any
                                                                                                          ) => {
                                                                                                            e.stopPropagation();
                                                                                                            this.checkChange(
                                                                                                              child,
                                                                                                              e,
                                                                                                              child.treeID
                                                                                                            );
                                                                                                          }}
                                                                                                        ></input>
                                                                                                        <span></span>
                                                                                                      </label>
                                                                                                    </div>
                                                                                                  </Card.Header>
                                                                                                  <ul
                                                                                                    className={
                                                                                                      userRoleforTreeView[
                                                                                                        index
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx1
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx2
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx3
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx4
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx5
                                                                                                      ]
                                                                                                        .children[
                                                                                                        idx6
                                                                                                      ]
                                                                                                        .isExpend ||
                                                                                                        accordionToggle
                                                                                                        ? "collapse show"
                                                                                                        : "collapse"
                                                                                                    }
                                                                                                  >
                                                                                                    {child.children.map(
                                                                                                      (
                                                                                                        child,
                                                                                                        idx7
                                                                                                      ) => (
                                                                                                        <li
                                                                                                          key={
                                                                                                            idx7
                                                                                                          }
                                                                                                        >
                                                                                                          {child
                                                                                                            .children
                                                                                                            .length >
                                                                                                            0 ? (
                                                                                                            <>
                                                                                                              <Card.Header>
                                                                                                                <div className="hasCheckBox cardHeader">
                                                                                                                  <label className="form-check">
                                                                                                                    <input
                                                                                                                      type="checkbox"
                                                                                                                      className="form-check-input"
                                                                                                                      checked={
                                                                                                                        child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                          ? true
                                                                                                                          : false
                                                                                                                      }
                                                                                                                      value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                                                                                      disabled={
                                                                                                                        selectedRoles.length > 1 &&
                                                                                                                        (selectedRoles[5].roleName ===
                                                                                                                          "Administrator" || child.isSpecial === "1")
                                                                                                                      }
                                                                                                                      onChange={(
                                                                                                                        e: any
                                                                                                                      ) => {
                                                                                                                        e.stopPropagation();
                                                                                                                        this.checkChange(
                                                                                                                          child,
                                                                                                                          e,
                                                                                                                          child.treeID
                                                                                                                        );
                                                                                                                      }}
                                                                                                                    ></input>
                                                                                                                    <span></span>
                                                                                                                  </label>
                                                                                                                </div>
                                                                                                              </Card.Header>
                                                                                                              <div
                                                                                                                className={
                                                                                                                  userRoleforTreeView[
                                                                                                                    index
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx1
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx2
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx3
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx4
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx5
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx6
                                                                                                                  ]
                                                                                                                    .children[
                                                                                                                    idx7
                                                                                                                  ]
                                                                                                                    .isExpend ||
                                                                                                                    accordionToggle
                                                                                                                    ? "collapse show"
                                                                                                                    : "collapse"
                                                                                                                }
                                                                                                              >
                                                                                                                <>

                                                                                                                </>
                                                                                                              </div>
                                                                                                            </>
                                                                                                          ) : (
                                                                                                            <Card.Header>
                                                                                                              <div className="hasCheckBox cardHeader">
                                                                                                                <label className="form-check">
                                                                                                                  <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    checked={
                                                                                                                      child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                                        ? true
                                                                                                                        : false
                                                                                                                    }
                                                                                                                    value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                                                                                    disabled={
                                                                                                                      selectedRoles.length > 1 &&
                                                                                                                      (selectedRoles[5].roleName ===
                                                                                                                        "Administrator" || child.isSpecial === "1")
                                                                                                                    }
                                                                                                                    onChange={(
                                                                                                                      e: any
                                                                                                                    ) => {
                                                                                                                      e.stopPropagation();
                                                                                                                      this.checkChange(
                                                                                                                        child,
                                                                                                                        e,
                                                                                                                        child.treeID
                                                                                                                      );
                                                                                                                    }}
                                                                                                                  ></input>
                                                                                                                  <span></span>
                                                                                                                </label>
                                                                                                              </div>
                                                                                                            </Card.Header>
                                                                                                          )}
                                                                                                        </li>
                                                                                                      )
                                                                                                    )}
                                                                                                  </ul>
                                                                                                </>
                                                                                              ) : (
                                                                                                <Card.Header>
                                                                                                  <div className="hasCheckBox cardHeader">
                                                                                                    <label className="form-check">
                                                                                                      <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        checked={
                                                                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                            ? true
                                                                                                            : false
                                                                                                        }
                                                                                                        value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                                                                        disabled={
                                                                                                          selectedRoles.length > 1 &&
                                                                                                          (selectedRoles[5].roleName ===
                                                                                                            "Administrator" || child.isSpecial === "1")
                                                                                                        }
                                                                                                        onChange={(
                                                                                                          e: any
                                                                                                        ) => {
                                                                                                          e.stopPropagation();
                                                                                                          this.checkChange(
                                                                                                            child,
                                                                                                            e,
                                                                                                            child.treeID
                                                                                                          );
                                                                                                        }}
                                                                                                      ></input>
                                                                                                      <span></span>
                                                                                                    </label>
                                                                                                  </div>
                                                                                                </Card.Header>
                                                                                              )}
                                                                                            </li>
                                                                                          )
                                                                                        )}
                                                                                      </ul>
                                                                                    </>
                                                                                  ) : (
                                                                                    <Card.Header>
                                                                                      <div className="hasCheckBox cardHeader">
                                                                                        <label className="form-check">
                                                                                          <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            checked={
                                                                                              child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                                ? true
                                                                                                : false
                                                                                            }
                                                                                            value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                                                            disabled={
                                                                                              selectedRoles.length > 1 &&
                                                                                              (selectedRoles[5].roleName ===
                                                                                                "Administrator" || child.isSpecial === "1")
                                                                                            }
                                                                                            onChange={(
                                                                                              e: any
                                                                                            ) => {
                                                                                              e.stopPropagation();
                                                                                              this.checkChange(
                                                                                                child,
                                                                                                e,
                                                                                                child.treeID
                                                                                              );
                                                                                            }}
                                                                                          ></input>
                                                                                          <span></span>
                                                                                        </label>
                                                                                      </div>
                                                                                    </Card.Header>
                                                                                  )}
                                                                                </li>
                                                                              )
                                                                            )}
                                                                          </ul>
                                                                        </>
                                                                      ) : (
                                                                        <Card.Header>
                                                                          <div className="hasCheckBox cardHeader">
                                                                            <label className="form-check">
                                                                              <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={
                                                                                  child.isChecked === "Yes" && child.isSpecial !== "1"
                                                                                    ? true
                                                                                    : false
                                                                                }
                                                                                value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                                                disabled={
                                                                                  selectedRoles.length > 1 &&
                                                                                  (selectedRoles[5].roleName ===
                                                                                    "Administrator" || child.isSpecial === "1")
                                                                                }
                                                                                onChange={(
                                                                                  e: any
                                                                                ) => {
                                                                                  e.stopPropagation();
                                                                                  this.checkChange(
                                                                                    child,
                                                                                    e,
                                                                                    child.treeID
                                                                                  );
                                                                                }}
                                                                              ></input>
                                                                              <span></span>
                                                                            </label>
                                                                          </div>
                                                                        </Card.Header>
                                                                      )}
                                                                    </li>
                                                                  )
                                                                )}
                                                              </ul>
                                                            </>
                                                          ) : (
                                                            <Card.Header>
                                                              <div className="hasCheckBox cardHeader">
                                                                <label className="form-check">
                                                                  <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={
                                                                      child.isChecked ===
                                                                        "Yes"
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                                    disabled={
                                                                      selectedRoles.length > 1 &&
                                                                      (selectedRoles[5].roleName ===
                                                                        "Administrator" || child.isSpecial === "1")
                                                                    }
                                                                    onChange={(
                                                                      e: any
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      this.checkChange(
                                                                        child,
                                                                        e,
                                                                        child.treeID
                                                                      );
                                                                    }}
                                                                  ></input>
                                                                  <span></span>
                                                                </label>
                                                              </div>
                                                            </Card.Header>
                                                          )}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </>
                                              ) : (
                                                <Card.Header>
                                                  <div className="hasCheckBox cardHeader">
                                                    <label className="form-check">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={
                                                          child.isChecked === "Yes" && child.isSpecial !== "1"
                                                            ? true
                                                            : false
                                                        }
                                                        value={`${selectedRoles[5].roleID}:${child.childID}`}
                                                        disabled={
                                                          selectedRoles.length > 1 &&
                                                          (selectedRoles[5].roleName ===
                                                            "Administrator" || child.isSpecial === "1")
                                                        }
                                                        onChange={(e: any) => {
                                                          e.stopPropagation();
                                                          this.checkChange(
                                                            child,
                                                            e,
                                                            child.treeID
                                                          );
                                                        }}
                                                      ></input>
                                                      <span></span>
                                                    </label>
                                                  </div>
                                                </Card.Header>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    ) : (
                                      <Card.Header className="hasNoChild">
                                        <div className="hasCheckBox cardHeader">
                                          <label className="form-check">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={
                                                child.isChecked === "Yes" && child.isSpecial !== "1"
                                                  ? true
                                                  : false
                                              }
                                              value={`${selectedRoles[5].roleID}:${child.childID}`}
                                              disabled={
                                                selectedRoles.length > 1 &&
                                                (selectedRoles[5].roleName ===
                                                  "Administrator" || child.isSpecial === "1")
                                              }
                                              onChange={(e: any) => {
                                                e.stopPropagation();
                                                this.checkChange(
                                                  child,
                                                  e,
                                                  child.treeID
                                                );
                                              }}
                                            ></input>
                                            <span></span>
                                          </label>
                                        </div>
                                      </Card.Header>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
