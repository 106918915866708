import Instense from "./Axios-config";


export class PassCodeService {

    public static SendPassCode = async (
        request: any
    ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);

        request.tenantID = tenantID;

        const url = `/OTP/SendPassCode`;

        return Instense.post(url, request).then((response) => {
            let result = response.data.result as any;
            return result;
        });
    };

    public static ValidatePassCode = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
        request.tenantID = tenantID;
    
        const url = `/OTP/ValidatePassCode`;
        return Instense.post(url, request).then((response) => {
          let result = response.data.result as any;
          return result;
        });
      };

    public static GenerateGoogleAuthKey = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
        request.tenantID = tenantID;
    
        const url = `/OTP/GenerateTwoFACode`;
        return Instense.post(url, request).then((response) => {
          let result = response.data.result as any;
          return result;
        });
      };
    
    public static ValidateQRCode = async (request: any): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
    
        request.tenantID = tenantID;
    
        const url = `/OTP/VerifyTwoFACode`;
        return Instense.post(url, request).then((response) => {
          let result = response.data.result as any;
          return result;
        });
      };
      public static GoogleAuthFeatureFlag = async (): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request :any ={};
        request.TenantID = tenantID;
        const url = `/OTP/GoogleAuthFeatureFlag`;
        return Instense.get(url, { params: request }).then((response) => {
            let result = response.data as any;
            return result;
        });
    };
}