import * as React from "react";
import { Dropdown, 
  // OverlayTrigger, Tooltip 
} from "react-bootstrap";

export class TotalFormat extends React.Component<any, any> {
  private inputElement: any;

  private formatTypes = [
    {label: 'HH:MM', value:'1' },
    {label: 'HRS', value:'2' }
  ];



  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      selectedItem:  {label: 'HH:MM', value:'1' },
      formatTypes: this.formatTypes,
      inputValue: "",
    };
  }


  componentDidMount = () => {
   
    const { defaultValue} = this.props;
    if(defaultValue) {
   const item =   this.formatTypes.find(r=> +r.value === +defaultValue);
      this.handleDropdownChange(item);
    } else {
      this.handleDropdownChange( this.state.selectedItem);
    }
  };

  componentDidUpdate = (prevProps: any) => {
    const { defaultValue} = this.props;
    if(!defaultValue && prevProps.defaultValue !== defaultValue ) {
      this.handleDropdownChange( {label: 'HH:MM', value:'1' });
    } 
  }

    handleformatTypessearch = (e: { target: { value: string } }) => {
    const updatedList = this.state.formatTypes.filter((item) => {
      return (
        item?.label?.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) >
        -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

    handleDropdownChange = (item: any) =>  {
      this.setState({ selectedItem: item });
     this.props?.onFormatTypeChange(item.value);
     this.resetSerchBox();
  }

   resetSerchBox = () => {
    this.setState({ filterData: this.state.formatTypes, inputValue: "" });
  };

 
  
  render() {

    

    return (
      <Dropdown
        className="single-select"
      
      >
        <Dropdown.Toggle id="Modal-formatType">
            
              <span
                style={{
                  width: " 95%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inline-block",
                }}
              >
                {this.state.selectedItem.label}
              </span>
          
        </Dropdown.Toggle>
        <Dropdown.Menu>
         
          <div className="list-area">
            {this.state.formatTypes.map((item: any, idx: any) => (
            
                <Dropdown.Item
                   onClick={() => this.handleDropdownChange(item)}
                >
                  {item.label}
                </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
