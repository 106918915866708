import { AccountingHubActions } from "./AccountingHubActions";
import { GeneralHubActions } from "./GeneralHubActions";
import { LaborManagementHubActions } from "./LaborManagementHubActions ";
import { UserManagementHubActions } from "./UserManagementHubActions";

export class Actions {
  static AccountingHubActions: AccountingHubActions =
    new AccountingHubActions();
  static LaborManagementHubActions: LaborManagementHubActions =
    new LaborManagementHubActions();
  static UserManagementHubActions: UserManagementHubActions =
    new UserManagementHubActions();
  static GeneralHubActions: GeneralHubActions = new GeneralHubActions();
}
