/* eslint-disable array-callback-return */
import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import BootstrapTable from "react-bootstrap-table-next";
import { SingleSearchDropdownList } from "../../../Common/Components/SingleSearchDropdownList";
import { GridOptions } from "ag-grid-community";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { VendorService } from "../../../Common/Services/vendor";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { IVendorList } from "../../../Common/Contracts/IVendor";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { Hotel } from "../../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../../Common/Contracts/IHotel";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import OutsideClickHandler from "react-outside-click-handler";
import _ from "lodash";
import { toast } from "react-toastify";
import "./vendor-ui.scss";
import { VendorDetailSlideOut } from "./components/VendorDetails";
import Spinner from "react-bootstrap/Spinner";
import { AddVendorModal } from "./components/AddVendorModal";
import { resolve, reject } from "q";
import MergeProcessModal from "./components/mergeProcessModal";
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";
import { TransactionLogHistory } from "../../Register/TransactionLogHistory";

import DropdownItem from "react-bootstrap/DropdownItem";
import { Register as registersevice } from "../../../Common/Services/Register";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../../../Common/Services/RoleSubPermissionSetting";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import { Utils } from "../../../Common/Utilis";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let filterStart: boolean = false;
let pasteSearch: boolean = false;
let columnCounter = 20;
let columnFilterCount = 20;
let pastedText: any = "";
export class Vendor extends React.Component<any, any> {
  private gridArea: any;
  private addVendorModal: any;
  private agVendorGrid: any;
  private searchTextRef: any;
  columnDefs: any[] = [
    /*{
            headerName: "order",
            field: "order",
            cellRenderer: "indexCellRenderer",
            lockPosition: true,
            pinned: true,
        },*/ {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      lockPosition: true,
      pinned: true,
      maxWidth: 35,
      minWidth: 35,
      cellClass: "bulkSelect-cells",
      headerClass: "bulkSelect-cells-header",
      hide: true,
    },
    {
      headerName: "Name",
      field: "company",
      cellRenderer: "VendorNameCellRenderer",
      lockPosition: true,
      pinned: true,
      tooltip: (params) => params?.data?.userName,
      // maxWidth: 200,
      // minWidth: 200,
      width: 270,
      cellClass: "company-cells",
      hide: false,
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: "statusCellRenderer",
      tooltip: (params) => params?.data?.userStatus,
      lockPosition: true,
      headerClass: "statusCol",
      pinned: true,
      // maxWidth: 80,
      // minWidth: 80,
      width: 100,
      cellClass: "status-cells",
      hide: false,
    },
    {
      headerName: "EHID",
      field: "ehidList",
      cellRenderer: "hotelIdsCellRenderer",
      cellStyle: { overflow: "visible" },
      cellClass: "ehid-cells",
      headerClass: "eHidCol",
      // maxWidth: 160,
      // minWidth: 160,
      width: 150,
      lockPosition: true,
      pinned: true,
      hide: false,
    },
    {
      headerName: "Billing Address",
      field: "street",
      cellRenderer: "addressCellRenderer",
      headerClass: "billingAdress ",
      lockPosition: true,
      pinned: true,
      // maxWidth: 250,
      // minWidth: 150,
      width: 325,
      cellClass: "billing-cells",
      hide: false,
    },
    {
      headerName: "Payment Method",
      field: "paymentType",
      cellRenderer: "paymentCellRenderer",
      cellStyle: { overflow: "visible" },
      cellClass: "paymentType-cells",
      // maxWidth: 115,
      // minWidth: 115,
      width: 180,
      lockPosition: true,
      pinned: true,
      hide: true,
    },
    {
      headerName: "",
      field: "",
      lockPinned: true,
      width: 100,
      pinned: "right",
      cellRenderer: "tripleDotCellRenderer",
      cellStyle: { overflow: "visible", position: "reset" },
      cellClass: "triple-dot",
      lockPosition: true,
    },
  ];

  gridApi: any;

  constructor(props: any) {
    super(props);
    this.gridArea = React.createRef();
    this.addVendorModal = React.createRef();
    this.agVendorGrid = React.createRef();
    this.searchTextRef = React.createRef();
    this.state = {
      hotelId: 0,
      hotelName: "All",
      searchText: "",
      Status: -1,
      isDataLoading: false,
      gridColumns: this.columnDefs.map((item, index) => {
        item.order = index;
        return item;
      }),
      totalDataLength: 0,
      statusList: [
        { id: -1, name: "All" },
        { id: 1, name: "Active" },
        { id: 2, name: "Inactive" },
      ],
      activeStatus: "Status",
      isInitialDataDataLoading: true,
      sortingFields: [
        "Name",
        "Status",
        "EHID",
        "Billing Address",
        "Payment Method",
      ],
      sortFieldName: "Name",
      vendors: [],
      hotels: [],
      pageSize: 20,
      currentPageNo: 1,
      isPageCreateNewUser: false,
      isChangeStatus: false,
      tableActions: [
        { action: "Deactivate", eventKey: "inactive" },
        { action: "Activate", eventKey: "active" },
      ],
      isBulkAction: false,
      isNewVendorAdded: false,
      showSlideout: false,
      isSearchDisable: false,
      pendingTrans: [],
      pendingInventoryTrans: [],
      pendingTransShow: false,
      isDachSetupEnterPrise: false,
      modalMergeProcess: false,
      selectedVID: "",
      selectedhid: -1,
      vendorlist: [],
      isMergeContracts: false,
      isMergeVendor: false,
      vendorListAll: [],
      vendorsDef: [],
      vendorListCount: [],
      currentsearch: "",
      showChangeLogModal: false,
      showChangeHistoryLogModal: false,
      isEditVendor: false,
      isSameVendorState: false,
      isReloadRequired: false,

      columnDropdownToggle: false,
      toggleColumnData: [],
      currentColumnSelected: 0,
      rowSelection: "multiple",
      vendorSelectedRows: [],
      isVendorBulkSelect: false,
      isPaymentMethodModal: false,
      vendorPaymentMethod: "Select Payment Method",
      vendorBulkSelectAll: false,
      isPaymentDisabled: false,
      isReloadColumns: false,
      isDeactivateVendor: false,

      tableColumnList: [
        { id: "company", name: "Name", isChecked: true, disabled: true },
        { id: "status", name: "Status", isChecked: true, disabled: true },
        { id: "ehidList", name: "EHID", isChecked: true, disabled: true },
        {
          id: "street",
          name: "Billing Address",
          isChecked: true,
          disabled: true,
        },
        {
          id: "paymentType",
          name: "Payment Method",
          isChecked: false,
          disabled: false,
        },
      ],
    };
  }

  componentDidMount() {
    this.loadDefaultData();
    //window.addEventListener("resize", this.getSizes, false);//NW-31990
    this.GetVendorList();
    this.CheckEpayEnabled();
    this.getDefaultColumn();
  }

  GetVendorList = () => {
    let request: any = {};
    request.HID = 0;
    InvoiceApprovalS.GetVendorlist(request)
      .then(async (result: any) => {
        if (result.length > 0) {
          result.map((items) => {
            items["sortIndex"] = null;
            items["id"] = items.vid;
            items["label"] = items.vendor;
            items["labelSearch"] = items.vendor;
            items["letterCode"] = "";
            items["isChecked"] = false;
            items["isDiffrentEhid"] = false;
          });
          this.setState(
            () => ({ vendorlist: [] }),
            () => {
              this.setState({ vendorlist: result });
            }
          );
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT });
        reject();
      });
  };

  getSizes = () => {
    this.gridApi?.sizeColumnsToFit();
  };

  loadDefaultData = () => {
    this.getVendorList();
    this.loadDropDownsData();
  };

  handleShowSlideout = (event: any) => {
    let { vendors } = this.state;
    const data = vendors.find((it: any) => it.vid === event.data.vid);
    this.setState({
      showSlideout: true,
      selectedTab: "general",
      userRowData: data,
    });
  };

  footerBulkClose = () => {
    this.setState(
      {
        vendorSelectedRows: [],
        isVendorBulkSelect: false,
        checkboxSelection: false,
        vendorBulkSelectAll: false,
      },
      () => {
        this.agVendorGrid?.current?.api?.deselectAll();
      }
    );
  };

  CheckEpayEnabled = () => {
    InvoiceApprovalS.CheckEpayEnabled()
      .then(async (result: any) => {
        if (result?.message === "Success") {
          const isCustIDExist = result?.result?.isCustIDExist;
          const isePayEnabled = result?.result.isePayEnabled;

          this.setState({
            isEpayEnable: isCustIDExist && isePayEnabled ? true : false,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT });
        reject();
      });
  };

  footerBulkSelectAll = (event) => {
    let isChecked = event.target.checked;
    if (isChecked) {
      this.agVendorGrid?.current?.api?.selectAll();
    } else {
      this.footerBulkClose();
    }
    this.setState({ vendorBulkSelectAll: isChecked });
  };

  handleChangePaymentMethod = (value: any) => {
    this.setState({
      isPaymentMethodModal: value,
      vendorPaymentMethod: "Select Payment Method",
    });
  };

  handlePaymentSelect = (eventKey: any) => {
    this.setState({ vendorPaymentMethod: eventKey });
  };

  UpdatePaymentMethod = () => {
    this.setState({ isPaymentDisabled: true });
    let { vendorSelectedRows } = this.state;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID: any = (storage === null ? 0 : storage.tenantID);
    let vendorList: any[] = [];
    vendorSelectedRows.forEach((o: any) => {
      let row: any = {};
      row.vid = o.vid;
      row.PaymentType = this.state.vendorPaymentMethod;
      row.TenantID = tenantID;
      vendorList.push(row);
    });
    registersevice
      .BulkUpdateVendorDefaultPaymentMethod(vendorList)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.success) {
            toast.success(result.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState(
              {
                isPaymentDisabled: false,
                isVendorBulkSelect: false,
                isPaymentMethodModal: false,
                isReloadColumns: true,
                vendors: [],
                vendorListAll: [],
                vendorsDef: [],
                vendorListCount: [],
                totalDataLength: 0,
                currentPageNo: 1,
              },
              () => {
                this.getVendorList();
              }
            );
          } else {
            Utils.toastError("Something went wrong.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.setState({ isPaymentDisabled: false });
          }
          resolve();
        } else {
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.setState({ isPaymentDisabled: false });
        }
      })
      .catch((error) => {
        this.setState({ isPaymentDisabled: false });
        reject();
      });
  };

  onRowSelected = (event) => {
    let { vendorSelectedRows, vendors } = this.state;
    if (event.node.selected) {
      vendorSelectedRows.push(event.data);
      vendorSelectedRows = _.uniqBy(vendorSelectedRows, "vid");
    } else {
      vendorSelectedRows = vendorSelectedRows.filter(
        (o: any) => o.vid !== event.data.vid
      );
    }
    let isVendorBulkSelect = vendorSelectedRows.length > 0;
    if (vendors.length !== vendorSelectedRows.length) {
      $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
    } else {
      $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
    }
    this.setState({
      vendorSelectedRows,
      isVendorBulkSelect: isVendorBulkSelect,
      vendorBulkSelectAll: vendors.length === vendorSelectedRows.length,
    });
  };

  loadDropDownsData = () => {
    this.getHotels();
  };

  toggleDataLoading = () => {
    this.setState({ isDataLoading: !this.state.isDataLoading });
  };

  onPaginationchange = () => {
    this.setState({ isSameVendorState: true });
    if (this.state.searchText !== "") {
      let { vendorListAll, hotelName, Status, vendorListCount, vendors } =
        this.state;
      let isVenData: any[] = [];
      let filterdData: any[] = [];
      let currentStatus = Status === 1 ? "Active" : "Inactive";
      vendorListAll.filter((item: any, index: any) => {
        if (hotelName !== "All") {
          if (item.ehidList !== null) {
            let splitEhidList = item.ehidList.split(",");
            splitEhidList.filter((splitm) => {
              if (splitm === hotelName) {
                if (index > columnCounter) {
                  filterdData.push(item);
                }
              }
            });
          }
        } else {
          if (
            item.status.toString().toLocaleLowerCase() ===
            currentStatus.toString().toLocaleLowerCase()
          ) {
            if (index > columnCounter) {
              filterdData.push(item);
            }
          }
        }
      });

      if (hotelName === "All" && Status === -1) {
        columnCounter += 20;
        if (columnCounter - 20 < vendorListAll.length) {
          for (let i = columnCounter - 20; i < vendorListAll.length; i++) {
            if (i < columnCounter) {
              isVenData = isVenData.concat(vendorListAll[i]);
              vendors = [...vendors, vendorListAll[i]];
              vendorListCount = vendorListCount.concat(vendorListAll[i]);
            }
          }
          setTimeout(() => {
            this.gridArea.current?.scrollTo({
              top: this.gridArea.current?.scrollHeight,
              behavior: "smooth",
            });
          }, 100);
        } else {
          Utils.toastError("No more records found.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          vendorListCount = vendorListAll;
        }
      } else {
        columnCounter += 20;
        columnFilterCount += 20;
        if (columnCounter - 20 < vendorListAll.length) {
          for (let i = columnCounter - 20; i < vendorListAll.length; i++) {
            if (i < columnCounter) {
              vendorListCount = vendorListCount.concat(vendorListAll[i]);
            }
          }
        }
        if (columnFilterCount - 20 < filterdData.length) {
          for (let i = columnFilterCount - 20; i < filterdData.length; i++) {
            if (i < columnFilterCount) {
              isVenData = isVenData.concat(filterdData[i]);
              vendors = [...vendors, vendorListAll[i]];
            }
          }
          setTimeout(() => {
            this.gridArea.current?.scrollTo({
              top: this.gridArea.current?.scrollHeight,
              behavior: "smooth",
            });
          }, 100);
        } else {
          Utils.toastError("No more records found.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          vendorListCount = vendorListAll;
        }
      }

      let { vendorSelectedRows } = this.state;
      vendors = _.uniqBy(vendors, "vid");
      this.setState({ vendors, vendorsDef: vendors, vendorListCount }, () => {
        this.agVendorGrid?.current?.api?.forEachNode((node: any) => {
          vendorSelectedRows.forEach((item: any) => {
            if (item.vid === node.data.vid) {
              node.setSelected(true);
            }
          });
        });
        setTimeout(() => {
          this.onSortingOrderChange(this.state.sortFieldName);
        }, 50);
      });
    } else {
      this.gridApi.showLoadingOverlay();
      const currentPageNo = this.state.currentPageNo + 1;
      setTimeout(() => {
        this.gridArea.current?.scrollTo({
          top: this.gridArea.current?.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
      this.setState({ currentPageNo }, () => {
        this.getVendorList(false, true);
      });
    }
  };

  onSortingOrderChange = (sortName: string) => {
    let { sortFieldName, vendors } = this.state;
    if (this.state.searchText === "") {
      sortFieldName = sortName;
      this.setState({ sortFieldName, currentPageNo: 1 }, () => {
        this.getVendorList();
      });
    } else {
      let sName: any = "";
      if (sortName === "Name") {
        sName = "companySort";
      } else if (sortName === "EHID") {
        sName = "ehidSort";
      } else if (sortName === "Billing Address") {
        sName = "addressSort";
      } else if (sortName === "Payment Method") {
        sName = "paymentSort";
      } else {
        sName = sortName.toString().toLocaleLowerCase();
      }
      vendors = _.sortBy(vendors, sName);
      this.setState({ vendors, sortFieldName: sortName });
    }
  };

  getHotels = () => {
    Hotel.getUserHotelAccess("Accounting").then(
      async (response: IHotelResponseDto[] | null) => {
        const hotels = [
          { id: -1, name: "All", lattercode: "All" },
          ..._.sortBy(
            response?.map((item) => ({
              id: item.hotelID,
              name: item.hotelName,
              lattercode: item.lettercode,
            })),
            "name"
          ),
        ];

        this.setState({ hotels });
      }
    );
  };

  toggleColumnDropdownToggle = (value: boolean) => {
    this.setState({ columnDropdownToggle: value });
  };

  saveDefaultColumn = (): void => {
    let tableColumnList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableColumnList))
    );
    let selectedColumns: any = [];
    tableColumnList.map((item) => {
      if (item.isChecked) {
        selectedColumns.push(item.id);
      }
    });

    const laborSaveAsDefaultdtos: any[] = [];
    laborSaveAsDefaultdtos.push({
      fieldName: "column",
      fieldValue: selectedColumns.toString(),
      pageName: "VendorColumn",
    });
    laborPerformance
      .saveDefaultViewProperties(laborSaveAsDefaultdtos)
      .then(async (result: any | null) => {
        if (result != null) {
          if (result.message === "Success") {
            this.toggleColumnDropdownToggle(false);
            toast.success(result?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      })
      .catch((error) => {
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({ showLoader: false });
      });
  };

  getDefaultColumn = () => {
    let { tableColumnList } = this.state;
    let colListdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(tableColumnList))
    );
    let gridColumns = _.cloneDeep(this.state.gridColumns);
    laborPerformance
      .getDefaultViewProperties("VendorColumn")
      .then(async (response: any | null) => {
        if (response?.length > 0) {
          let resData = response[0].fieldValue.split(",");
          tableColumnList.map((item) => {
            item.id === "paymentType" && (item.isChecked = false);
          });
          gridColumns.map(
            (item: any) => item.field === "paymentType" && (item.hide = true)
          );
          resData.forEach((ritm) => {
            tableColumnList.map((item: any, index: any) => {
              if (item.id === ritm) {
                item.isChecked = true;
              }
            });
            for (let i of gridColumns) {
              if (i.field === ritm) {
                i.hide = false;
              }
            }
            gridColumns.map(
              (item: any) =>
                item.headerCheckboxSelection &&
                (item.hide = ritm === "paymentType" ? false : true)
            );
          });
          if (
            !resData.some((o: any) => o === "paymentType") &&
            this.state.sortFieldName === "Payment Method"
          ) {
            this.onSortingOrderChange("Name");
          }
          this.setState(() => ({ gridColumns, tableColumnList }));
        } else {
          let checked = { checked: true };
          let event = { target: checked };
          let data = tableColumnList[4];
          this.toggleColumnCheck(event, data, 4);
          tableColumnList.map(
            (item: any) => item.id === "paymentType" && (item.isChecked = true)
          );
          this.setState({ tableColumnList });
        }
      })
      .catch((error) => {
        this.setState({ tableColumnList: colListdata });
        Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
      });
  };

  toggleColumnCheck = (e: any, data: any, idx: any) => {
    let checked = e.target.checked;
    let gridColumns = _.cloneDeep(this.state.gridColumns);
    let colListdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.tableColumnList))
    );
    colListdata[idx].isChecked = checked;
    for (let i of gridColumns) {
      if (i.field === data.id) {
        i.hide = !checked;
        break;
      }
    }
    gridColumns.map(
      (item: any) =>
        item.headerCheckboxSelection &&
        (item.hide = checked && data.id === "paymentType" ? false : true)
    );
    if (!checked && data.id === "paymentType") {
      this.setState({ sortFieldName: "Name" }, () => {
        let { vendors } = this.state;
        vendors = _.sortBy(vendors, "companySort");
        this.setState({ vendors });
      });
    }
    this.setState(
      {
        gridColumns,
        tableColumnList: colListdata,
      },
      () => {
        this.footerBulkClose();
        this.columnWidthSizeToFit();
      }
    );
  };

  onGridReady = (params: any) => {
    this.gridApi = params.api;
    this.columnWidthSizeToFit();
  };

  columnWidthSizeToFit = () => {
    this.gridApi?.sizeColumnsToFit();
  }

  tableOutsideClick = () => {
    setTimeout(() => {
      this.columnWidthSizeToFit();
    }, 800);
  };

  onDDlFiltersChange = (controlID: any, id: any) => {
    let { hotelId, Status, hotels, hotelName, vendors } = this.state;
    if (controlID === "ddlStatus") {
      Status = id;
    } else {
      hotelId = id;
    }
    let currentHotel: any[] = [];
    let filteredVendor: any[] = [];
    if (controlID === "ddlHotel") {
      let currentStatus = Status === 1 ? "Active" : "Inactive";
      vendors = [];
      currentHotel = hotels.filter((item) => item.id === id);
      if (currentHotel.length > 0) {
        hotelName = currentHotel[0].lattercode;
      } else {
        hotelName = "All";
      }
      filteredVendor = this.searchFilter(
        this.state.searchText,
        hotelName,
        Status
      );
      filteredVendor.forEach((item: any, index: any) => {
        if (hotelName === "All") {
          if (Status === -1) {
            if (index < columnCounter) {
              vendors.push(item);
            }
          } else {
            if (
              item.status.toString().toLocaleLowerCase() ===
              currentStatus.toString().toLocaleLowerCase()
            ) {
              if (this.state.searchText.length >= 3) {
                vendors.push(item);
              } else {
                if (index < columnCounter) {
                  vendors.push(item);
                }
              }
            }
          }
        } else {
          if (Status === -1) {
            if (item.ehidList !== null) {
              let splitEhidList = item.ehidList.split(",");
              splitEhidList.filter((splitm) => {
                if (splitm === hotelName) {
                  if (this.state.searchText.length >= 3) {
                    vendors.push(item);
                  } else {
                    if (index < columnCounter) {
                      vendors.push(item);
                    }
                  }
                }
              });
            }
          } else {
            if (item.ehidList !== null) {
              let splitEhidList = item.ehidList.split(",");
              splitEhidList.filter((splitm) => {
                if (splitm === hotelName) {
                  if (
                    item.status.toString().toLocaleLowerCase() ===
                    currentStatus.toString().toLocaleLowerCase()
                  ) {
                    if (this.state.searchText.length >= 3) {
                      vendors.push(item);
                    } else {
                      if (index < columnCounter) {
                        vendors.push(item);
                      }
                    }
                  }
                }
              });
            }
          }
        }
      });
    } else if (controlID === "ddlStatus") {
      vendors = [];
      let currentStatus = Status === 1 ? "Active" : "Inactive";
      filteredVendor = this.searchFilter(
        this.state.searchText,
        this.state.hotelName,
        Status
      );
      filteredVendor.forEach((item: any, index: any) => {
        if (Status === -1) {
          if (hotelName !== "All") {
            if (item.ehidList !== null) {
              let splitEhidList = item.ehidList.split(",");
              splitEhidList.filter((splitm) => {
                if (splitm === hotelName) {
                  if (this.state.searchText.length >= 3) {
                    vendors.push(item);
                  } else {
                    if (index < columnCounter) {
                      vendors.push(item);
                    }
                  }
                }
              });
            }
          } else {
            if (index < columnCounter) {
              vendors.push(item);
            }
          }
        } else {
          if (hotelName !== "All") {
            if (item.ehidList !== null) {
              let splitEhidList = item.ehidList.split(",");
              splitEhidList.filter((splitm) => {
                if (splitm === hotelName) {
                  if (
                    item.status.toString().toLocaleLowerCase() ===
                    currentStatus.toString().toLocaleLowerCase()
                  ) {
                    if (this.state.searchText.length >= 3) {
                      vendors.push(item);
                    } else {
                      if (index < columnCounter) {
                        vendors.push(item);
                      }
                    }
                  }
                }
              });
            }
          } else {
            if (
              item.status.toString().toLocaleLowerCase() ===
              currentStatus.toString().toLocaleLowerCase()
            ) {
              if (this.state.searchText.length >= 3) {
                vendors.push(item);
              } else {
                if (index < columnCounter) {
                  vendors.push(item);
                }
              }
            }
          }
        }
      });
    }
    this.setState(
      { hotelId, hotelName, Status, currentPageNo: 1, vendors },
      () => {
        if (this.state.searchText === "") {
          this.getVendorList();
        } else {
          this.onSortingOrderChange(this.state.sortFieldName);
        }
      }
    );
  };

  onPasteSearch = () => {
    pasteSearch = true;
  };

  searchFilter = (values: any, hotelName: any, Status: any) => {
    const vendorData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.vendorsDef))
    );
    let { vendorListAll } = this.state;
    let filterStatus: any[] = [];
    let currentStatus = Status === 1 ? "Active" : "Inactive";
    vendorListAll.filter((item) => {
      if (Status === -1) {
        filterStatus.push(item);
      } else {
        if (
          item.status.toString().toLocaleLowerCase() ===
          currentStatus.toString().toLocaleLowerCase()
        ) {
          filterStatus.push(item);
        }
      }
    });
    let filterByEhid: any[] = [];
    filterStatus.forEach((item) => {
      if (hotelName === "All") {
        filterByEhid.push(item);
      } else {
        if (item.ehidList !== null) {
          let splitEhidList = item.ehidList.split(",");
          splitEhidList.filter((splitm) => {
            if (splitm === hotelName) {
              filterByEhid.push(item);
            }
          });
        }
      }
    });
    let filterVendor: any[] = [];
    if (values !== "") {
      let iVal = values.toString().toLowerCase().trim();
      filterVendor = filterByEhid.filter(
        (o: any) =>
          o.company !== null &&
          o.company.toString().toLocaleLowerCase().includes(iVal)
      );
    } else {
      vendorData.forEach((o: any) => {
        filterVendor.push(o);
      });
    }
    return filterVendor;
  };

  onSearchChange = (event: any) => {
    let { currentsearch, vendorListCount } = this.state;
    let { value } = event.target;
    this.setState({ searchText: value });
    if (value.length > 2 && value.length === 3) {
      if (
        value.toString().toLocaleLowerCase() ===
        currentsearch.toString().toLocaleLowerCase()
      ) {
        if (currentsearch.length > 0) {
          let filterVendor = this.searchFilter(
            value,
            this.state.hotelName,
            this.state.Status
          );
          let max20Result: any[] = [];
          filterVendor.forEach((item: any, index: any) => {
            if (vendorListCount.length < filterVendor.length) {
              if (index < 20) {
                max20Result.push(item);
              }
            } else {
              max20Result.push(item);
            }
          });
          this.setState({ vendors: [] }, () => {
            this.setState({ vendors: max20Result });
          });
          filterStart = true;
        } else {
          this.setState(
            { currentPageNo: 1, isSearchDisable: true, currentsearch: "" },
            () => {
              this.getVendorList();
            }
          );
        }
      } else {
        this.setState(
          { currentPageNo: 1, isSearchDisable: true, currentsearch: "" },
          () => {
            this.getVendorList();
          }
        );
      }
    } else if (value.length >= 3) {
      if (pasteSearch) {
        pastedText = value;
        this.setState(
          {
            searchText: pastedText,
            currentPageNo: 1,
            isSearchDisable: true,
            vendors: [],
          },
          () => {
            this.getVendorList();
          }
        );
      } else {
        let filterVendor = this.searchFilter(
          value,
          this.state.hotelName,
          this.state.Status
        );
        this.setState({ vendors: [] }, () => {
          this.setState({ vendors: filterVendor });
        });
        filterStart = true;
      }
    } else if (value.length === 0) {
      this.setState(
        () => ({
          currentPageNo: 1,
          // hotelId:0,
          // Status:-1,
          sortFieldName: "Name",
          activeStatus: "Status",
          isSearchDisable: true,
          currentsearch: "",
        }),
        () => {
          this.getVendorList();
        }
      );
    }
  };

  filterAllData = (vendorList: any, searchText) => {
    let allFilterData: any[] = [];
    if (searchText !== "") {
      let iVal = searchText.toString().toLowerCase().trim();
      allFilterData = vendorList.filter(
        (o: any) =>
          o.company !== null &&
          o.company.toString().toLocaleLowerCase().includes(iVal)
      );
    } else {
      allFilterData = vendorList;
    }
    return allFilterData;
  };

  getVendorList = (isDefaultDataLoad = true, isPagination = false) => {
    if (!isPagination) {
      this.setState({
        vendors: [],
        vendorListAll: [],
        vendorsDef: [],
        vendorListCount: [],
        totalDataLength: 0,
        vendorSelectedRows: [],
      });
    } else {
      const hollowRows = [];
      this.setState({
        vendors: [...this.state.vendors, ...(hollowRows as IVendorList[])],
      });
    }
    let {
      hotelId,
      searchText,
      Status,
      pageSize,
      currentPageNo,
      sortFieldName,
    } = this.state;
    hotelId = hotelId === -1 ? 0 : hotelId;
    const request: any = {
      searchText,
      Status: this.state.searchText.length >= 3 ? -1 : Status,
      HID: this.state.searchText.length >= 3 ? 0 : hotelId,
      pageIndex: currentPageNo,
      totalRecords: pageSize,
      sortBy: sortFieldName,
      sortDirection: "ASC",
    };
    this.toggleDataLoading();
    if (isDefaultDataLoad) {
      this.setState({ isInitialDataDataLoading: true });
    }
    VendorService.getVendorList(request)
      .then((vendors: IVendorList[]) => {
        if (vendors?.length > 0) {
          if (!this.state.isSameVendorState || this.state.isReloadRequired) {
            this.setState({
              vendors: [],
              vendorListAll: [],
              vendorsDef: [],
              vendorListCount: [],
              vendorSelectedRows: [],
            });
          }
          let _vendors: any[] = [...this.state.vendors?.filter((item) => item?.company), ...(vendors)];
          this.setState({ currentsearch: searchText }, () => {
            setTimeout(() => {
              this.setFilterVendorBySearch(
                _vendors,
                isPagination,
                isDefaultDataLoad
              );
            }, 50);
          });
        } else {
          this.setState({
            isInitialDataDataLoading: false,
            /*currentsearch: searchText*/
          });
        }
      })
      .finally(() => {
        this.toggleDataLoading();
        if (isDefaultDataLoad) {
          this.setState({ isSearchDisable: false });
        }
        filterStart = false;
        pasteSearch = false;
        columnCounter = 20;
      });
  };

  sortAfterLoad = (sortFieldName: any) => {
    let sName: any = "";
    if (sortFieldName === "Name") {
      sName = "companySort";
    } else if (sortFieldName === "EHID") {
      sName = "ehidSort";
    } else if (sortFieldName === "Billing Address") {
      sName = "addressSort";
    } else if (sortFieldName === "Payment Method") {
      sName = "paymentSort";
    } else {
      sName = sortFieldName.toString().toLocaleLowerCase();
    }
    return sName;
  };

  setFilterVendorBySearch = (
    _vendors: any,
    isPagination: any,
    isDefaultDataLoad: any
  ) => {
    let { Status, searchText, hotelName, sortFieldName } = this.state;
    _vendors.map((item) => {
      item["companySort"] =
        item?.company === null
          ? ""
          : item?.company.trim().charAt(0).toLocaleLowerCase();
      item["ehidSort"] =
        item?.ehidList === null
          ? ""
          : item?.ehidList.trim().charAt(0).toLocaleLowerCase();
      item["addressSort"] =
        item?.addressList === null
          ? ""
          : item?.addressList.trim().charAt(0).toLocaleLowerCase();
      item["paymentSort"] =
        item?.paymentType === null ? "" : item?.paymentType.trim();
    });
    _vendors = _.sortBy(_vendors, this.sortAfterLoad(sortFieldName));

    if (searchText !== "") {
      _vendors = this.filterAllData(_vendors, searchText);
    }
    const totalDataLength = _vendors?.length ? _vendors[0]?.totalResult : 0;
    const DachSetupEnterPrise = _vendors[0]?.dachSetUpforEnterPrise;
    const DachSetupPerm = _vendors[0]?.dachSetupPerm;
    let currentStatus = Status === 1 ? "Active" : "Inactive";
    let filterStatus: any[] = [];
    _vendors.filter((item) => {
      if (Status === -1) {
        filterStatus.push(item);
      } else {
        if (
          item.status.toString().toLocaleLowerCase() ===
          currentStatus.toString().toLocaleLowerCase()
        ) {
          filterStatus.push(item);
        }
      }
    });
    let filterByEhid: any[] = [];
    filterStatus.forEach((item) => {
      if (hotelName === "All") {
        filterByEhid.push(item);
      } else {
        if (item.ehidList !== null) {
          let splitEhidList = item.ehidList.split(",");
          splitEhidList.filter((splitm) => {
            if (splitm === hotelName) {
              filterByEhid.push(item);
            }
          });
        }
      }
    });
    let max20Result: any[] = [];
    filterByEhid.forEach((item: any, index: any) => {
      if (index < 20) {
        max20Result.push(item);
      }
    });
    let { vendors, vendorSelectedRows } = this.state;
    let insertRecord: any[] = [];
    insertRecord = this.state.searchText === "" ? filterByEhid : max20Result;
    let _isfinalVendor = sortFieldName === "Status" ? _.sortBy(insertRecord, ["status"]) : insertRecord;
    let isfinalVendor: any = (isPagination ? insertRecord : _isfinalVendor);
    vendors = [...vendors, ...isfinalVendor];
    vendors = _.uniqBy(vendors, "vid");
    this.setState(
      {
        vendorListAll: _vendors,
        vendorListCount: insertRecord,
        vendors,
        vendorsDef: isfinalVendor,
        totalDataLength,
        isDachSetupEnterPrise: DachSetupEnterPrise,
        isSearchDisable: false,
        dachSetupPerm: DachSetupPerm,
        isEditVendor: _vendors[0]?.isEditVendorInfoPerm,
        isDeactivateVendor: _vendors[0]?.isDeactivateVendorsPerm,
        isMergeVendor: _vendors[0]?.isMergeVendorsPerm,
        isSameVendorState: false,
        // isVendorBulkSelect: false,
      },
      () => {
        this.agVendorGrid?.current?.api?.forEachNode((node: any) => {
          vendorSelectedRows.forEach((item: any) => {
            if (item.vid === node.data.vid) {
              node.setSelected(true);
            }
          });
        });
      }
    );
    if (!isDefaultDataLoad) {
      this.gridArea.current?.scrollTo({
        top: this.gridArea.current?.scrollHeight,
        behavior: "smooth",
      });
    }
    if (this.state.isSearchDisable) {
      this.setState({ isSearchDisable: false });
    }
    this.searchTextRef.current?.focus();
    if (this.state.searchText.length >= 3) {
      this.searchFilter(
        this.state.searchText,
        this.state.hotelName,
        this.state.Status
      );
    }
    if (this.state.searchText.length > 3 || this.state.isReloadRequired) {
      this.onDDlFiltersChange("ddlHotel", this.state.hotelId);
      this.onDDlFiltersChange("ddlStatus", this.state.Status);
    } else {
      setTimeout(() => {
        this.setState({ isInitialDataDataLoading: false });
      }, 500);
    }
    if (this.state.searchText.length > 3 && !this.state.isReloadRequired) {
      setTimeout(() => {
        this.setState({ isInitialDataDataLoading: false });
      }, 500);
    }
    this.setState({ isReloadRequired: false });
  };

  getAgGridOptions = () => {
    const gridOptions: GridOptions = {
      rowData: [],
      rowClass: "user-table-row",
      getRowHeight: (params) => {
        const dataLength = 70;
        return dataLength;
      },
      headerHeight: 48,
      frameworkComponents: {
        indexCellRenderer: this.indexCellRenderer,
        VendorNameCellRenderer: this.VendorNameCellRenderer,
        statusCellRenderer: this.statusCellRenderer,
        hotelIdsCellRenderer: this.hotelIdsCellRenderer,
        addressCellRenderer: this.addressCellRenderer,
        paymentCellRenderer: this.paymentCellRenderer,
        tripleDotCellRenderer: this.tripleDotCellRenderer,
      },
      onColumnMoved: (params: any) => {
        const columnCount = params.columnApi
          .getAllColumns()
          .filter((c) => c.isVisible()).length;
        const maxIndex = columnCount - 2;
        if (params.toIndex > maxIndex) {
          params.columnApi.moveColumnByIndex(params.toIndex, maxIndex);
        }
      },

      // rowSelection: "multiple",
    };
    return gridOptions;
  };

  indexCellRenderer(params: any) {
    return (
      <div className="title1">
        <EllipsisWithTooltip placement="top">
          {params?.rowIndex}
        </EllipsisWithTooltip>
      </div>
    );
  }

  VendorNameCellRenderer(params: any) {
    return (
      <div className="ag-react-container">
        <div className="containerCall vendorInfo">
          <strong>
            <EllipsisWithTooltip placement="top">
              {params?.data?.company}
            </EllipsisWithTooltip>
          </strong>
          <div className="secondaryInfo"></div>
        </div>
      </div>
    );
  }

  statusCellRenderer(params: any) {
    let className = "";
    if (params?.data?.status?.toLowerCase() === "active") {
      className = "color-green";
    } else if (params?.data?.status?.toLowerCase() === "inactive") {
      className = "color-grey";
    }
    return (
      <div className="ag-react-container">
        <div>
          {params?.data?.status ? (
            <span className={`badge ${className}`}>
              {params?.data?.status?.toLowerCase() === "active"
                ? "Active"
                : "Inactive"}
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  hotelIdsCellRenderer(params: any): any {
    const hids = params?.data?.ehidList?.toString()?.split(",");
    if (hids?.length > 2) {
      hids?.shift();
      const hidsToolTipData = hids?.join(",  ");
      return (
        <div className="ag-react-container">
          <div className="ehid-tag rm-bg d-flex align-items-center">
            <div className="hids">
              {params?.data?.ehidList?.toString()?.split(",")[0]}
            </div>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="data-ehids" id="hidTool">
                  {hidsToolTipData}
                </Tooltip>
              }
            >
              <button type="button" className="showalltags d-flex">
                <div className="more-hids" style={{ paddingRight: "2px" }}>
                  <span>
                    +{params?.data?.ehidList?.toString()?.split(",").length - 1}{" "}
                    More
                  </span>
                </div>
              </button>
            </OverlayTrigger>
          </div>
        </div>
      );
    } else {
      return (
        <div className="ag-react-container">
          <div className="ehid-tag rm-bg d-flex align-items-center">
            <span className="hids">{hids?.join(",  ")}</span>
          </div>
        </div>
      );
    }
  }

  addressCellRenderer(params): any {
    const adrsList = params?.data?.addressList?.toString()?.split("Ø");
    if (adrsList?.length > 1) {
      return (
        <div className="ag-react-container">
          <div className="containerCall col-lg-11 pl-0">
            <EllipsisWithTooltip placement="top">
              {adrsList[0].split("°")[0]}
              <br />
              {adrsList[0].split("°")[1]}
            </EllipsisWithTooltip>

            <button type="button" className="showalltags d-flex">
              <div className="more-hids" style={{ paddingRight: "2px" }}>
                <EllipsisWithTooltip placement="top">
                  <span className="data-ehids">
                    +
                    {params?.data?.addressList?.toString()?.split("Ø").length -
                      1}{" "}
                    More
                    {""}
                  </span>
                </EllipsisWithTooltip>
              </div>
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="ag-react-container">
          <div className="containerCall col-lg-11 pl-0">
            <EllipsisWithTooltip placement="top">
              {params?.data?.addressList?.split("°")[0]} <br />
              {params?.data?.addressList?.split("°")[1]}
            </EllipsisWithTooltip>
          </div>
        </div>
      );
    }
  }

  paymentCellRenderer(params): any {
    return (
      <div className="ag-react-container">
        <div>{params?.data?.paymentType}</div>
      </div>
    );
  }

  handleTripleDotMenu(item: any, rowData: any) {
    if (item.eventKey === "inactive" || item.eventKey === "active") {
      this.setState({ loaderTrue: true });
      let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
      let requestObject = {} as IPageSubPermissionRole;
      requestObject.permissionID = 4;
      requestObject.Module = "Accounting";
      requestObject.Type = "page";
      requestObject.RoleID = localStorageUser.rolId;
      requestObject.TenantID = localStorageUser.tenantID;
      let isVendorDeactivatePermission: boolean = false;
      RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)
        .then(async (result: any | null) => {
          if (result != null && result.length > 0) {
            for (let index of result) {
              if (index?.permissionName.toString().toLowerCase() === "deactivate vendors" && index?.moduleName === "Accounting") {
                isVendorDeactivatePermission = true;
              }
            }
          }
          if (isVendorDeactivatePermission) {
            const action = item.action === "Activate" ? "Active" : "Inactive";
            let request = {} as any;
            request.VID = rowData.vid;
            request.HID = 0;
            request.Status = action;
            VendorService.ActiveDeactiveVendor(request).then(
              (response: any) => {
                if (response?.message === "Update") {
                  if (response?.result === "success") {
                    toast.success(
                      `Vendor ${item.action?.toLowerCase()} successfully.`,
                      {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      }
                    );
                    this.setState({ currentPageNo: 1 }, () => {
                      this.getVendorList();
                    });
                  } else if (response?.result === "ccexist") {
                    Utils.toastError(
                      `Vendor cannot be inactivated as this vendor is associated with a credit card.`,
                      {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      }
                    );
                  } else if (response?.result === "checkexist") {
                    Utils.toastError(
                      `Please clear the checks that have been generated but not printed for this vendor on the check writer page.`,
                      {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      }
                    );
                  } else if (response?.result === "activecontractuser") {
                    Utils.toastError(
                      `There is an active contractor type user assigned to this vendor.`,
                      {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      }
                    );
                  }
                } else if (response?.message === "Table") {
                  const pendingTrans = response?.result.filter(
                    (o) => o.entryName === "Invoice"
                  );
                  const pendingInventoryTrans = response?.result.filter(
                    (o) => o.entryName === "Inventory"
                  );
                  this.setState({
                    pendingTrans: pendingTrans,
                    pendingInventoryTrans: pendingInventoryTrans,
                    pendingTransShow: true,
                  });
                } else {
                  toast.success(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
                this.setState({ loaderTrue: false });
              }
            );
          } else {
            this.setState({ loaderTrue: false });
            Utils.toastError(
              `You do not have permission to Activate/Deactivate Vendors`,
              { position: toast.POSITION.BOTTOM_RIGHT }
            );
            setTimeout(() => {
              this.getVendorList(true, false);
            }, 1000);
          }
          this.setState({ loaderTrue: false });
          resolve();
        })
        .catch((err: any) => {
          this.setState({ loaderTrue: false });
          Utils.toastError(`Server Error, ${err}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reject();
        });
    }
  }

  handleMerge = (row) => {
    this.setState({
      selectedVID: row.vid,
      selectedhid: -1,
      modalMergeProcess: true,
    });
  };

  mergeVendorModalShow = () => {
    this.setState({ modalMergeProcess: true, selectedhid: -1 });
  };

  showHideChangeLogModal = (value: any) => {
    this.setState({ showChangeLogModal: !this.state.showChangeLogModal });
    if (value === "View Past Changes") {
      this.setState({
        showChangeLogModal: true,
        showChangeHistoryLogModal: true,
      });
    }
  };

  showHideChangeHistoryLogModal = () => {
    this.setState({
      showChangeHistoryLogModal: !this.state.showChangeHistoryLogModal,
    });
  };

  mergeVendorModalHide = () => {
    this.setState({
      modalMergeProcess: false,
      selectedVID: "",
      selectedhid: -1,
    });
  };

  tripleDotCellRenderer = (params: any): any => {
    let _buttonAction = params.data?.status?.toLowerCase().trim() === "inactive" ? this.state.tableActions.filter((x) => x.eventKey !== "inactive") : []
    let buttonAction = params.data?.status?.toLowerCase().trim() === "active" ? this.state.tableActions.filter((x) => x.eventKey !== "active") : _buttonAction;
    let isMergeShow: boolean = false;
    isMergeShow = this.state.vendorlist.length > 0 && this.state.isMergeVendor && params.data?.cardNoExists.toString().toLocaleLowerCase() === "notexists" &&
      params.data?.isContractAgency.toString().toLocaleLowerCase() === "0" &&
      params.data?.vendorAprroval.toString().toLocaleLowerCase() === "app";

    return (
      <>
        <div className="ag-react-container">
          {(isMergeShow ||
            this.state.isDeactivateVendor) /* && this.state.isEditVendor*/ && (
              <Dropdown
                className="more-action"
                alignRight
                onToggle={this.handleToggleTrippleDots}
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {isMergeShow && (
                    <DropdownItem
                      eventKey="merge"
                      onClick={() => this.handleMerge(params.data)}
                    >
                      Merge{" "}
                    </DropdownItem>
                  )}
                  {this.state.isDeactivateVendor /* && this.state.isEditVendor*/ && (
                    <>
                      {buttonAction?.map((item) => (
                        <Dropdown.Item key={item.action}
                          onClick={() =>
                            this.handleTripleDotMenu(item, params.data)
                          }
                          eventKey={item?.eventKey}
                        >
                          {item?.action}
                        </Dropdown.Item>
                      ))}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
        </div>
      </>
    );
  };

  handleToggleTrippleDots = () => {
    this.footerBulkClose();
  };

  closeSlideOut = (shouldReload = false) => {
    if (shouldReload) {
      this.setState({ isReloadRequired: true }, () => {
        this.getVendorList(true, false);
      });
    }
    this.setState({ showSlideout: false });
  };

  render() {
    const {
      vendors,
      statusList,
      searchText,
      hotels,
      pageSize,
      currentPageNo,
      totalDataLength,
      isDataLoading,
      sortingFields,
      sortFieldName,
      gridColumns,
      isInitialDataDataLoading,
      hotelId,
      showSlideout,
      userRowData,
      pendingTransShow,
      pendingInventoryTrans,
      pendingTrans,
      loaderTrue,
    } = this.state;

    let hideLoadMore: any = false;
    let isStatusHidNotSelected =
      this.state.Status === -1 &&
      (this.state.hotelId === 0 || this.state.hotelId === -1);
    let isCountEqualstoAllVendors =
      this.state.vendorListAll.length !== this.state.vendorListCount.length;
    let isvendorsEqualstoAllVendors =
      vendors.length === this.state.vendorListAll.length;
    if (this.state.searchText !== "") {
      let _hideLdMr = isStatusHidNotSelected ? isCountEqualstoAllVendors : false;
      let _hideLoadMore = isvendorsEqualstoAllVendors ? false : _hideLdMr;
      hideLoadMore = (vendors.length >= 20 ? _hideLoadMore : false);
    } else {
      hideLoadMore = (totalDataLength > pageSize * currentPageNo && !isDataLoading && !this.state.isPagination && this.state.vendors.length >= 20);
    }

    const renderEllipsisWithTooltip=(value:any)=>{
      return(
        <EllipsisWithTooltip placement="bottom">
              {value}
            </EllipsisWithTooltip>
      )
    }

    const PendingTransColumns = [
      {
        dataField: "lettercode",
        text: "EHID",
      },
      {
        dataField: "department",
        text: "Invoice No",
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.department)
      },
      {
        dataField: "mDate",
        text: "Date",
      },
    ];

    const PendingInventoryTransColumns = [
      {
        dataField: "lettercode",
        text: "EHID",
      },
      {
        dataField: "department",
        text: "Department",
        formatter: (cell: any, row: any, rowIndex: any) => renderEllipsisWithTooltip(row.department)
      },
      {
        dataField: "mDate",
        text: "Date",
      },
      {
        dataField: "poNumber",
        text: "PO Number",
      },
    ];
    let { vendorPaymentMethod, isPaymentDisabled, isEditVendor } = this.state;
    let isDisablePaymentMethodSave =
      vendorPaymentMethod === "Select Payment Method" ||
      isPaymentDisabled ||
      !isEditVendor;
    let { vendorSelectedRows } = this.state;
    let isShowMenual: boolean = true;
    let isShowCheck: boolean = true;
    let isShowCreditCard: boolean = true;
    let isShowEpay: boolean = true;
    if (vendorSelectedRows.length === 1) {
      isShowMenual = vendorSelectedRows[0].paymentType !== "Manual ACH";
      isShowCheck = vendorSelectedRows[0].paymentType !== "Check";
      isShowCreditCard = vendorSelectedRows[0].paymentType !== "Credit Card";
      isShowEpay = vendorSelectedRows[0].paymentType !== "ePay";
    }

    return (
      <div className="vendor-ui">
        {this.state.showChangeLogModal && (
          <TransactionLogHistory
            payPeriodStartDate={""}
            payPeriodEndDate={""}
            accType={"vendorlog"}
            showChangeLogModal={this.state.showChangeLogModal}
            showHideChangeLogModal={this.showHideChangeLogModal}
            hotelName={""}
            hotelId={this.state.hotelId}
            hideCrossAndClearFilters={false}
            shiftId={0}
            oprID={""}
            shiftDate={""}
            noOfDays={30}
          />
        )}

        {this.state.showChangeHistoryLogModal && (
          <TransactionLogHistory
            payPeriodStartDate={""}
            payPeriodEndDate={""}
            showChangeLogModal={this.state.showChangeHistoryLogModal}
            showHideChangeLogModal={this.showHideChangeHistoryLogModal}
            hotelName={""}
            hotelId={this.state.hotelId}
            hideCrossAndClearFilters={false}
            shiftId={0}
            shiftDate={""}
            accType={"VendorHistorical"}
          />
        )}

        <Container fluid className="body-sec pos-rel">
          {loaderTrue && (
            <div className="matrixSpinnerCenter">
              <Spinner animation="border" variant="secondary" />
            </div>
          )}
          <div className="page-heading underline d-flex">
            <div className="mr-auto">Vendors</div>
            <div className="action-group d-flex flex-row ml-auto">
              {/* <div className="small">
                                <code>Count{JSON.stringify(this.state.vendorListCount.length)}</code> , <code>all{JSON.stringify(this.state.vendorListAll.length)}</code>                                
                            </div> */}
              <Button
                disabled={this.state.isSearchDisable}
                onClick={this.showAddVendorModal}
              >
                Add Vendor
              </Button>
              <div className="header-popout">
                <Dropdown className="more-action" alignRight>
                  <Dropdown.Toggle
                    className={
                      this.state.vendorListAll.length > 0
                        ? "btn-outline-primary btn btn-primary more"
                        : "disabled-area btn-outline-primary btn btn-primary more"
                    }
                    id="dropdown-more"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <>
                      {this.state.vendors.length > 0 &&
                        this.state.isEditVendor && (
                          <Dropdown.Item onClick={this.exportVendorSheet}>
                            Export
                          </Dropdown.Item>
                        )}
                      {this.state.vendorlist.length > 0 &&
                        this.state.isMergeVendor && (
                          <Dropdown.Item onClick={this.mergeVendorModalShow}>
                            Merge Vendor
                          </Dropdown.Item>
                        )}
                    </>
                    <Dropdown.Item onClick={this.showHideChangeLogModal}>
                      Change Log
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="main-page" id="vendor-ui-table">
            <div className="th-cover isThCover"></div>
            <div className="navigation-menus d-flex">
              <input
                ref={this.searchTextRef}
                type="text"
                value={searchText}
                id="searchText"
                // disabled={this.state.isSearchDisable}
                onChange={(e: any) => this.onSearchChange(e)}
                onPaste={this.onPasteSearch}
                className="search-box form-control"
                placeholder="Search..."
                autoComplete="OFF"
              />
              <div
                className={`${this.state.isSearchDisable ? "disabled-area" : ""
                  } status-filter vendorStatusFilter`}
              >
                <SingleSearchDropdownList
                  allowSearch={false}
                  itemList={statusList}
                  handleSelectedItem={this.onDDlFiltersChange.bind(
                    this,
                    "ddlStatus"
                  )}
                  defaultText={"Search Status"}
                  defaultName={this.state.activeStatus}
                  controlID="ddlStatus"
                />
              </div>
              <div
                className={`${this.state.isSearchDisable ? "disabled-area" : ""
                  } ehid-filter vendorStatusFilter`}
              >
                {hotels?.length > 0 && (
                  <SingleSearchDropdownList
                    itemList={hotels}
                    allowSearch={true}
                    handleSelectedItem={this.onDDlFiltersChange.bind(
                      this,
                      "ddlHotel"
                    )}
                    defaultText={"Search EHID"}
                    defaultName={
                      +hotelId === 0
                        ? "EHID"
                        : hotels.find((item) => +item?.id === +hotelId)?.name
                    }
                    controlID="ddlHotel"
                  />
                )}
              </div>
              <div className="navigationSort ml-auto">
                <Dropdown
                  className={`${this.state.isSearchDisable ? "disabled-area" : ""
                    } more-action bg-blue`}
                  alignRight
                >
                  <Dropdown.Toggle className="" id="dropdown-more">
                    <div className="d-flex align-items-center">
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M3.5 12L6.5 9H4.25V0H2.75V9H0.5L3.5 12ZM7.25 3H14V4.5H7.25V3ZM7.25 6H12.5V7.5H7.25V6ZM7.25 0H15.5V1.5H7.25V0ZM7.25 9H11V10.5H7.25V9Z" />
                      </svg>
                      {sortFieldName}
                      <div className="drop-arrow d-flex">
                        <FiChevronDown />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Header>SORT</Dropdown.Header>
                    {sortingFields.map((item: any, idx: any) => {
                      let isPaymentCheck =
                        this.state.tableColumnList[4].isChecked;
                      return (
                        <Dropdown.Item
                          key={item}
                          className={`${!isPaymentCheck && item === "Payment Method" ? "d-none" : ""} ${sortFieldName === item ? "active" : ""}`}
                          eventKey={item}
                          onClick={() => this.onSortingOrderChange(item)}
                        >
                          <div className="d-flex">
                            <div className="mr-auto"> {item}</div>
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                fill="#2CAF92"
                              />
                            </svg>
                          </div>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="vendor-ui-table" id="vendorTable">
              {isInitialDataDataLoading ? (
                <ReactPageLoader useas={"usersTable"} />
              ) : (
                <OutsideClickHandler
                  onOutsideClick={() => this.tableOutsideClick()}
                >
                  <div
                    id="myGrid"
                    className={`${this.state.isVendorBulkSelect
                      ? "hasBulkFooter scroll-relative"
                      : "isNormalView scroll-relative"
                      } myGridContainer ag-theme-balham static-header`}
                    ref={this.gridArea}
                  >
                    <div className={"customToggleDropdown static-toggle"}>
                      <div
                        className={
                          this.state.columnDropdownToggle
                            ? "ddnicon d-flex show"
                            : "ddnicon d-flex"
                        }
                        onClick={() => this.toggleColumnDropdownToggle(true)}
                      >
                        <span className="dropdown-icon mr-1">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M16.3914 2H3.60861C2.72146 2 2 2.79733 2 3.77778V16.2222C2 17.2027 2.72146 18 3.60861 18H16.3914C17.2785 18 18 17.2027 18 16.2222V6.44356V5.55467V4.66578V3.77778V3.77689C17.9992 2.79644 17.2769 2 16.3914 2ZM6.82582 16.2222H3.60861V6.44356H6.82582V16.2222ZM11.6516 16.2222H8.43442V6.44356H11.6516V16.2222ZM13.2602 16.2222V6.44356H16.3914L16.3922 16.2222H13.2602Z" />
                          </svg>
                        </span>
                        <span>
                          <FiChevronDown />
                        </span>
                      </div>
                      {this.state.columnDropdownToggle && (
                        <OutsideClickHandler
                          onOutsideClick={() =>
                            this.toggleColumnDropdownToggle(false)
                          }
                        >
                          <div className="toggle-drowpdown-wrapper forAccModule">
                            <div className="dropdown-header d-flex">
                              <span className="header-name">Columns</span>
                            </div>
                            <div className="dropdown-body">
                              <div className="show-hide-column">
                                {this.state.tableColumnList.map((data: any, idx: any) => {
                                  let _dataIsChecked = data.isChecked ? "form-check-label active" : "form-check-label";
                                  return (
                                    <div className="dropdown-col-items d-flex form-check" key={data?.id}>
                                      <input
                                        id={`isCheckID_${idx}`}
                                        className="form-check-input"
                                        key={data?.id}
                                        type="checkbox"
                                        checked={data.isChecked}
                                        disabled={data.disabled}
                                        onChange={(e: any) =>
                                          this.toggleColumnCheck(e, data, idx)
                                        }
                                      />
                                      <label
                                        htmlFor={`isCheckID_${idx}`}
                                        className={data.disabled ? "form-check-label disabled" : _dataIsChecked}
                                      >
                                        {data.name}
                                      </label>
                                    </div>
                                  )
                                }
                                )}
                              </div>
                            </div>
                            <div className="dropdown-footer d-flex">
                              <span
                                className="saveDefaultBtn"
                                onClick={() => this.saveDefaultColumn()}
                              >
                                Save as Default Columns
                              </span>
                            </div>
                          </div>
                        </OutsideClickHandler>
                      )}
                    </div>
                    <AgGridReact
                      ref={this.agVendorGrid}
                      onGridReady={this.onGridReady}
                      domLayout={"autoHeight"}
                      animateRows={false}
                      rowData={vendors}
                      columnDefs={gridColumns}
                      suppressDragLeaveHidesColumns={true}
                      onCellClicked={(e: any) => {
                        if (
                          e.column &&
                          (e.column.colDef.cellClass === "triple-dot" ||
                            e.column.colDef.cellClass === "bulkSelect-cells" ||
                            e.event.target.type === "checkbox")
                        ) {
                          e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection =
                            true;
                        } else {
                          e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection =
                            true;
                          this.handleShowSlideout(e);
                        }
                      }}
                      suppressRowDrag={true}
                      suppressScrollOnNewData={true}
                      gridOptions={this.getAgGridOptions()}
                      rowSelection={this.state.rowSelection}
                      suppressRowClickSelection={true}
                      onRowSelected={this.onRowSelected.bind(this)}
                      getRowNodeId={(data) => data.vid}
                    />
                  </div>
                </OutsideClickHandler>
              )}
            </div>

            {showSlideout && userRowData && (
              <VendorDetailSlideOut
                vendorData={userRowData}
                selectedTab={this.state.selectedTab}
                closeSlideOut={this.closeSlideOut}
                isDachSetupEnterPrise={this.state.isDachSetupEnterPrise}
                dachSetupPerm={this.state.dachSetupPerm}
              ></VendorDetailSlideOut>
            )}
            {pendingTransShow && (
              <Modal
                centered
                show={pendingTransShow}
                onHide={this.pendingTransTableHideShow}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <div className="sub-heading-Pay">Pending Transactions</div>
                  </Modal.Title>
                </Modal.Header>
                {pendingTrans.length > 0 && (
                  <>
                    <div className="notificationBannersPay vendorNotification">
                      Please clear the following Invoices to inactivate the
                      vendor. (Either Invoices are pending for approval or on
                      the Payment page)
                    </div>
                    <Modal.Body>
                      <ToolkitProvider
                        keyField="Rno"
                        data={pendingTrans}
                        columns={PendingTransColumns}
                      >
                        {(props: {
                          searchProps: JSX.IntrinsicAttributes;
                          baseProps: JSX.IntrinsicAttributes;
                        }) => (
                          <div>
                            <div className="JournalNavTabs PaymentNavTabs">
                              <div className="grid-sec cusGridSec2 cusGridSecPay">
                                <div className="scroll-area Vendor-Pending-TranModal table-responsive">
                                  <BootstrapTable {...props.baseProps} hover />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </ToolkitProvider>
                    </Modal.Body>
                  </>
                )}
                {pendingInventoryTrans.length > 0 && (
                  <>
                    <div className="notificationBannersPay vendorNotification">
                      Please clear all transactions containing the items related
                      to the selected vendor from Inventory, Approval or
                      Purchase pages to inactivate the vendor.
                    </div>
                    <Modal.Body>
                      <ToolkitProvider
                        keyField="Rno"
                        data={pendingInventoryTrans}
                        columns={PendingInventoryTransColumns}
                      >
                        {(props: {
                          searchProps: JSX.IntrinsicAttributes;
                          baseProps: JSX.IntrinsicAttributes;
                        }) => (
                          <div>
                            <div className="JournalNavTabs PaymentNavTabs">
                              <div className="grid-sec cusGridSec2 cusGridSecPay">
                                <div className="scroll-area Vendor-Pending-InvModal table-responsive">
                                  <BootstrapTable {...props.baseProps} hover />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </ToolkitProvider>
                    </Modal.Body>
                  </>
                )}
                <Modal.Footer className="Footer-Pay">
                  <Button
                    variant="primary"
                    type="button"
                    className="space btn btn-primary btn-discard"
                    onClick={this.pendingTransTableHideShow}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            {this.state.isNewVendorAdded && (
              <AddVendorModal
                ref={this.addVendorModal}
                hideAddVendorModal={this.hideAddVendorModal.bind(this)}
                moveToContractTab={this.moveToContractTab.bind(this)}
                isDachSetupEnterPrise={this.state.isDachSetupEnterPrise}
                dachSetupPerm={this.state.dachSetupPerm}
              ></AddVendorModal>
            )}
            {this.state.modalMergeProcess && (
              <>
                {this.state.vendorlist.length > 0 && (
                  <MergeProcessModal
                    hideModal={this.mergeVendorModalHide}
                    isHid={this.state.selectedhid}
                    isId={this.state.selectedVID}
                    itemsList={this.state.vendorlist}
                    isReload={this.closeSlideOut}
                    isReloadMain={this.moveToGeneralTab.bind(this)}
                    callType="vendor"
                    eventType="row"
                  />
                )}
              </>
            )}
          </div>

          <div
            className={
              this.state.isVendorBulkSelect
                ? "wom_TabFooter"
                : "d-none wom_TabFooter"
            }
          >
            <div className="bulkSelectFooter">
              <Form.Check
                type="checkbox"
                label=""
                id="footerBulkSelect"
                checked={this.state.vendorBulkSelectAll}
                onClick={(e: any) => this.footerBulkSelectAll(e)}
              />
              <span className="shift-selected-count">
                {this.state.vendorSelectedRows.length} Vendor
                {this.state.vendorSelectedRows.length > 1 && "s"} Selected
              </span>
              <div className="footer-vertical-line"></div>
              <div className="action-group d-flex flex-row mr-auto">
                <Button
                  disabled={!this.state.isEditVendor}
                  onClick={() => this.handleChangePaymentMethod(true)}
                >
                  {" "}
                  Change Payment Method
                </Button>
              </div>
              <Button
                className="closeButton btn-ghost-light"
                onClick={() => this.footerBulkClose()}
              >
                &times;
              </Button>
            </div>
          </div>
          {this.state.isPaymentMethodModal && (
            <Modal
              className="confirm-modal isVendorModal"
              onHide={() => this.handleChangePaymentMethod(false)}
              show={this.state.isPaymentMethodModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Change Payment Method</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="cusProcessDropdownWithSearch">
                  <Dropdown
                    className="itemDropdown"
                    onSelect={this.handlePaymentSelect}
                  >
                    <Dropdown.Toggle id="idChangePaymentMethod">
                      <div className="pos-rel">
                        <div className="d-flex align-items-center">
                          <div className="txt-area gTextArea mr-auto">
                            <EllipsisWithTooltip placement="bottom">
                              {this.state.vendorPaymentMethod}
                            </EllipsisWithTooltip>
                          </div>
                          <div className="drop-arrow ml-0 d-flex">
                            <FiChevronDown />
                          </div>
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    {this.state.isEditVendor ? (
                      <Dropdown.Menu>
                        {isShowMenual && (
                          <Dropdown.Item eventKey="Manual ACH">
                            Manual ACH
                          </Dropdown.Item>
                        )}
                        {isShowCheck && (
                          <Dropdown.Item eventKey="Check">Check</Dropdown.Item>
                        )}
                        {isShowCreditCard && (
                          <Dropdown.Item eventKey="Credit Card">
                            Credit Card
                          </Dropdown.Item>
                        )}
                        {this.state.isEpayEnable && (
                          <>
                            {isShowEpay && (
                              <Dropdown.Item eventKey="ePay">
                                ePay
                              </Dropdown.Item>
                            )}
                          </>
                        )}
                      </Dropdown.Menu>
                    ) : (
                      <></>
                    )}
                  </Dropdown>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-ghost"
                  onClick={() => this.handleChangePaymentMethod(false)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isDisablePaymentMethodSave}
                  variant="primary"
                  onClick={this.UpdatePaymentMethod}
                >
                  {isPaymentDisabled && (
                    <Spinner
                      size="sm"
                      className="disabled-area"
                      animation="border"
                    />
                  )}{" "}
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          {/* {(totalDataLength > pageSize * currentPageNo) && !isDataLoading && (!this.state.isPagination && this.state.vendors.length >= 20) &&} */}

          {hideLoadMore && (
            <div
              className={`${this.state.isVendorBulkSelect ? "setToView" : "setToBottom"
                } loadMoreBtn`}
            >
              <Button
                className="btn btn-ghost"
                onClick={() => this.onPaginationchange()}
              >
                Load More...
              </Button>
            </div>
          )}
        </Container>
      </div>
    );
  }

  pendingTransTableHideShow = () => {
    this.setState({ pendingTransShow: !this.state.pendingTransShow });
  };

  exportVendorSheet = () => {
    const {
      hotelId: hid,
      searchText,
      Status,
      pageSize,
      currentPageNo,
      sortFieldName,
    } = this.state;

    const request: any = {
      searchText,
      Status,
      hid,
      pageIndex: currentPageNo,
      totalRecords: pageSize,
      tenantId: 0,
      sortBy: sortFieldName,
      sortDirection: "ASC",
      IsPaymentMethodSelected: this.state.tableColumnList[4].isChecked
        ? "Yes"
        : "No",
    };
    this.setState({ loaderTrue: true });
    VendorService.exportVendorSheet(request, "").then((users: any[] | null) => {
      this.setState({ loaderTrue: false });
    });
  };

  showAddVendorModal = () => {
    this.setState({ isNewVendorAdded: true });
  };
  hideAddVendorModal = () => {
    this.setState({ isNewVendorAdded: false });
  };
  moveToContractTab = (VID, company, vendorAprroval) => {
    let newVendorArray: any = {};
    newVendorArray.vid = VID;
    newVendorArray.company = company;
    newVendorArray.vendorAprroval = vendorAprroval;
    this.setState(
      {
        showSlideout: true,
        userRowData: newVendorArray,
        selectedTab: "contracts",
        vendorData: newVendorArray,
      },
      () => this.GetVendorList()
    );
  };

  moveToGeneralTab = (VID, company, vendorAprroval) => {
    let newVendorArray: any = {};
    newVendorArray.vid = VID;
    newVendorArray.company = company;
    newVendorArray.vendorAprroval = vendorAprroval;
    this.setState(
      {
        showSlideout: true,
        userRowData: newVendorArray,
        selectedTab: "general",
        vendorData: newVendorArray,
      },
      () => {
        this.GetVendorList();
      }
    );
  };
}
