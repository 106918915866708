import * as React from "react";
import { SingleSelectReportGroupSearchForChart } from "../../../Common/Components/SingleSelectReportGroupSearchForChart";
// import { Form } from "react-bootstrap";
// import { ReportGroup } from "../../Common/Services/ReportGroup";
// import {
//   IReportGroupResponseDto,
//   IReportGroupSubGroupWithHotelCountDto,
// } from "../../Common/Contracts/IReportParameter";

export class ReportGroupSelectorForChart extends React.Component<any, any> {
  // private SingleselectChild: any;
  private singleSerch: any;
  constructor(props: any) {
    super(props);
    this.singleSerch = React.createRef();
  }

  onDDLChange = (selectedItem: any) => {
    this.props.handleReportGroupChange(selectedItem);
  };

  resetValue = () => {
    this.singleSerch.current.resetSerchBox();
  };

  render() {
    return (
      <SingleSelectReportGroupSearchForChart
        isDashboard={this.props.isDashboard}
        defaultValue={this.props.defaultValue}
        notIncludeEnterpriseAccounts={this.props?.notIncludeEnterpriseAccounts}
        showOnlyHotelAccounts={this.props?.showOnlyHotelAccounts}
        ref={this.singleSerch}
        onDDLChange={this.onDDLChange}
        isAll={this.props?.isAll}
        showBlueBg={this.props?.showBlueBg}
        moduleName={this.props?.moduleName}
      ></SingleSelectReportGroupSearchForChart>
    );
  }
}
