import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { resolve, reject } from "q";
import {
  Container,
  Dropdown,
  Form,
  Tab,
  Tabs,
  Row,
  Nav,
  Button,
  Modal,
  Col,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import { DropDownList } from "../Reports/common-components/dropdown-list";
import { StaticArrays } from "../../Common/StaticArrays";
import { Utils } from "../../Common/Utilis";
import { supportServices } from "../../Common/Services/Support";
import { accountService } from "../../Common/Services/account";

export class FundingSourceModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isSpinner: false,
      isDisabled:false,
      formData: {
        bankName: this.props.bankName,
        accountType: "",
        routingNumber: "",
        accountNo: this.props.accountNo,
        accountName: this.props.accountName,
        hotelID: this.props?.hotelID,
        bankRefID: this.props?.bankRefID,
      },
      errors: {
        bankName: "",
        accountType: "",
        routingNumber: "",
        accountNo: "",
        accountName: "",
      },
    };
  }

  componentDidMount() {}

  isFormValid() {
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    let isForm = true;
    if (formData.bankName.trim() === "") {
      errors.bankName = "Bank name is required.";
      isForm = false;
    }
    if (formData.accountType === "") {
      errors.accountType = "Please select account type.";
      isForm = false;
    }
    if (formData.routingNumber.trim() === "") {
      errors.routingNumber = "Routing number is required.";
      isForm = false;
    }
    if (!this.validateRoutingNumber(formData.routingNumber.trim())) {
      errors.routingNumber = "Routing number is invalid.";
      isForm = false;
    }
    if (formData.accountNo.trim().length === 0) {
      errors.accountNo = "Account number is required.";
      isForm = false;
    }
    if (formData.accountName === 0) {
      errors.accountName = "Account name(business name) is required.";
      isForm = false;
    }
    this.setState({ errors });
    if (isForm === true) {
      return true;
    } else {
      return false;
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    switch (name) {
      case "routingNumber":
        errors.routingNumber =
          value.trim().length === 0 ? "Please enter routing number." : "";
        formData.routingNumber = value;
        break;
      case "accountType":
        errors.accountType = value.trim() === "" ? "" : "";
        formData.accountType = value;
        break;
      default:
        break;
    }

    this.setState({ errors, formData });
  };

  validateRoutingNumber = (num: string): boolean => {
    // Run through each digit and calculate the total.
    let n = 0;
    for (let i = 0; i < num.length; i += 3) {
      n +=
        parseInt(num.charAt(i), 10) * 3 +
        parseInt(num.charAt(i + 1), 10) * 7 +
        parseInt(num.charAt(i + 2), 10);
    }
    // If the resulting sum is an even multiple of ten (but not zero),
    // the aba routing number is good.
    if (n !== 0 && n % 10 === 0) {
      return true;
    } else {
      return false;
    }
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () => this.validateDepositFormSchema());
  };

  validateDepositFormSchema = () => {
    let errors = this.state.errors;
    let formData = { ...this.state.formData };
    let isForm = true;

    if (formData.accountType && errors.accountType) {
      errors.accountType = "";
    }
    this.setState({ errors });
  };

  addFundingSource = () => {
    //debugger;
    if (this.isFormValid()) {
      this.setState({ isDisabled: true });
    this.setState({ isSpinner: true });
    accountService
      .SaveFundingSourceDetails(this.state.formData)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.status === "Success") {
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "bankDetailsSlideout",
            });
            setTimeout(() => {
              this.closePopupFinal();
              this.refreshPage();
            }, 1000);
          } else {
            this.setState({ isSpinner: false });
            this.setState({ isDisabled: false });
            Utils.toastError(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "bankDetailsSlideout",
            });
          }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        this.setState({ isDisabled: false });
        Utils.toastError(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "bankDetailsSlideout",
        });
      });
    }
  };

  onFieldChange = (event, inputPosition) => {
    const { formData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    formData[fieldName] = value;

    this.setState({ formData, inputPosition }, () =>
      this.validateDepositFormSchema()
    );
  };

  closePopup() {
    this.props?.handleSourceModal();
  }

  closePopupFinal = () => {
    this.props.handleShowHideChoiceModal();
  };

  refreshPage = () => {
    this.props?.PlaiddSetupStatus(this.props?.hotelID, this.props?.bankRefID);
    this.refreshBankSlidOut();
  };

  refreshBankSlidOut() {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let selectedExistAcc = { ...this.state.selectedExistAcc };
    selectedExistAcc.oprID = this.props.oprID;
    selectedExistAcc.hotelID = this.props?.hotelID;
    selectedExistAcc.tenantID = tenantID;
    selectedExistAcc.obaccno = this.props.accountNo;
    this.props?.bindFormData(selectedExistAcc);
  }

  render() {
    const { errors, formData } = this.state;
    return (
      <div id="" className="entity-management">
        <ToastContainer containerId={"bankDetailsSlideout"} autoClose={3000} />
        <Container fluid className="">
          <Modal
            className="add-deposit-account-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.props.showSourceModal}
            onHide={() => this.props?.handleSourceModal()}
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Account Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
                  <Form.Group controlId="BankName">
                    <Col sm={"12"} onBlur={() => this.validationOnClick(1)}>
                      <Form.Label>Bank Name</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtBankName"
                        name="bankName"
                        autoComplete="txtBankName"
                        value={this.props.bankName}
                        disabled={true}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="accountType">
                    <Col
                      className={
                        errors.accountType.length > 0 ? "mb-3 validation-error" : "mb-3"
                      }
                    >
                      <Form.Label>Account Type</Form.Label>
                      <DropDownList
                        placeHolder={"Search Account Type"}
                        data={StaticArrays.UserAccountTypes}
                        isSearchRequired={false}
                        defaultValue={this.state.formData.accountType}
                        label={"label"}
                        value={"value"}
                        id="accountType"
                        name="accountType"
                        onDropDownChange={(item) => {
                          if (!item) {
                            return;
                          }

                          this.onFieldChange(
                            Utils.BuildCustomEventObject(
                              "accountType",
                              item.value
                            ),
                            3
                          );
                        }}
                        selectedItem={[
                          ...[{ value: "", label: "Select Account Type" }],
                          ...StaticArrays.UserAccountTypes,
                        ].find(
                          (r: any) =>
                            r?.value === this.state.formData.accountType
                        )}
                      />
                      {errors.accountType.length > 0 && (
                        <span className="validation-message">{errors.accountType}</span>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="routingNumber">
                    <Col
                      sm={"12"}
                      className={
                        errors.routingNumber.length > 0 ? "mb-3 validation-error" : "mb-3"
                      }
                      onBlur={() => this.validationOnClick(2)}
                    >
                      <Form.Label>Routing Number</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="routingNumber"
                        name="routingNumber"
                        onKeyDown={
                          Utils.AllowOnlyPositiveNumnberInputWithoutDot
                        }
                        autoComplete="routingNumber"
                        onChange={this.handleChange}
                      />
                      {errors.routingNumber.length > 0 && (
                        <span className="validation-message">{errors.routingNumber}</span>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="accountNumber">
                    <Col sm={"12"} onBlur={() => this.validationOnClick(3)}>
                      <Form.Label>Account Number</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtaccountNumber"
                        name="accountNumber"
                        onKeyDown={
                          Utils.AllowOnlyPositiveNumnberInputWithoutDot
                        }
                        autoComplete="txtaccountNumber"
                        value={this.props.accountNo}
                        disabled={true}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="businessName">
                    <Col sm={"12"} onBlur={() => this.validationOnClick(4)}>
                      <Form.Label>Business Name</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtbusinessName"
                        name="businessName"
                        autoComplete="txtbusinessName"
                        value={this.props.accountName}
                        disabled={true}
                      />
                    </Col>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-ghost"
                onClick={() => {
                  this.closePopup();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.isDisabled ? true : false}
                className="btn btn-primary"
                onClick={this.addFundingSource}
              >
                Send
              </Button>
            </Modal.Footer>
            {this.state.isSpinner && (
              <div className="loader-spinner d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="success" />
              </div>
            )}
          </Modal>
        </Container>
      </div>
    );
  }
}
