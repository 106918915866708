import React, {useState, useRef, useEffect} from "react";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { idText } from "typescript";


const AutoComplete = ({id, selected, data, rowId, onBlurDesc,disabled,handleDescFocus }) => {
  //const inputRef = useRef<HTMLInputElement>(null);
  //const inputRef = useRef(null);
  const suggestionRef = useRef<any>(null);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [value, setValue] = useState(selected);
  const [ellipsisVal, setEllipsisVal] = useState(true);
  
  useEffect(() => {
    // const inputeRef = inputRef.current;
    // debugger;
  });

  const handleChange = (e) => {
    //debugger;
    const query = e.target.value;
    setValue(query);
    if (query.length > 0) {
      const filterSuggestions = data.filter(
        (suggestion) =>
          suggestion.label.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
      setSuggestions(filterSuggestions);
      setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };

  const handleBlur = (e) => {
    setSuggestionsActive(false);
    onBlurDesc(value,rowId);
  }

  const handleClick = (e) => {
    
    setSuggestions([]);
    setValue(e.target.innerText);
    setSuggestionsActive(false);
    handleDescFocus(e.target.innerText,id,rowId);
    onBlurDesc(e.target.innerText,rowId);
    //setEllipsisVal(true);
  };

  const handleKeyDown = (e) => {
    // UP ARROW
    if (e.keyCode === 38) {
      if (suggestionIndex === 0) {
        return;
      }
      setValue(suggestions[suggestionIndex - 1]?.label);
      setSuggestionIndex(suggestionIndex - 1);
      if(suggestionsActive){
        setTimeout(() => {
          let currentScrollPoint = suggestionRef.current.childNodes[1];
          let elementTop = currentScrollPoint.childNodes[suggestionIndex].offsetTop;
          let divTop = currentScrollPoint.childNodes[1].offsetTop;
          let elementRelativeTop = elementTop - divTop;
          currentScrollPoint.scrollTop = elementRelativeTop;
          
        }, 10);
      }
      
    }
    // DOWN ARROW
    else if (e.keyCode === 40) {
      if (suggestionIndex + 1 === suggestions.length) {
        return;
      }
      setValue(suggestions[suggestionIndex + 1]?.label);
      setSuggestionIndex(suggestionIndex + 1);
      
      if(suggestionsActive){
        setTimeout(() => {
          let currentScrollPoint = suggestionRef.current.childNodes[1];
          let elementTop = currentScrollPoint.childNodes[suggestionIndex].offsetTop;
          let divTop = currentScrollPoint.childNodes[1].offsetTop;
          let elementRelativeTop = elementTop - divTop;
          currentScrollPoint.scrollTop = elementRelativeTop;

          // let currentScrollPoint = suggestionRef.current.childNodes[1];
          // let activeTopPos = currentScrollPoint.childNodes[suggestionIndex].getBoundingClientRect().top;
          // console.log(currentScrollPoint.scrollTop + ":::::" + activeTopPos)
          // currentScrollPoint.scrollTop = activeTopPos
          // console.log(currentScrollPoint.scrollTop + ":::::::::::" + activeTopPos)
        }, 10);
      }
    }
    // ENTER
    else if (e.keyCode === 13 && suggestions.length > 0) {
      setValue(suggestions[suggestionIndex]?.label);
      setSuggestionIndex(0);
      setSuggestionsActive(false);
      handleDescFocus(suggestions[suggestionIndex]?.label,id,rowId);
      onBlurDesc(suggestions[suggestionIndex]?.label,rowId);
      //setEllipsisVal(true);
        // setTimeout(() => {
        //     let divRef = inputRef;
        //     if (divRef.current?.clientWidth < divRef.current?.scrollWidth) {
        //         setEllipsisVal(true);
        //     }
        // }, 50)
    }
  };

  const Suggestions = () => {
    return (
      <ul className="suggestions">
        {suggestions.map((suggestion, index) => {
          return (
            <li
              className={index === suggestionIndex ? "active" : ""}
              key={index}
              onMouseDown={handleClick}
            >
                <EllipsisWithTooltip placement="bottom">{suggestion.label}</EllipsisWithTooltip>
              
            </li>
          );
        })}
      </ul>
    );
  };
  const renderTooltip = (props) => (
    <>
          {ellipsisVal && (
              <Tooltip id={`Description-Feild${rowId}`} {...props}>
                  {value}
              </Tooltip>
          )}
    </>
  );

  return (
    <div className="custom-autocomplete" ref={suggestionRef}>
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}

        >
            <input
                //ref={inputRef}
                id={id}
                className="form-control"
                type="text"
                placeholder="Enter Description"
                value={value}
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
                onKeyDown={handleKeyDown}
                disabled ={disabled}
                autoComplete="off"
                maxLength={99}
               // onFocus={handleFocus}
            />
        </OverlayTrigger>
      {suggestionsActive && <Suggestions />}
    </div>
  );
  
};

export default AutoComplete;