import React, { useState, useEffect } from "react";
import { Form, Dropdown, Row, Container, Card, Modal, Button,OverlayTrigger,Spinner,
  Tooltip } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { UserManagement } from "../../../../Common/Services/UserManagement";
import { IGeneralInfo } from "../../../../Common/Contracts/IGeneralInfo";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { Utils } from "../../../../Common/Utilis";
import { DropDownListWithDescription } from "../../../../Common/Components/dropDownListWithDescription";
import { accountService } from "../../../../Common/Services/account";
import { GoogleAuthentication } from "../../../../Common/Services/GoogleAuthentication";
import _ from "lodash";
import Joi from "joi-browser";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { User } from "../../../../Common/Services/User";
import { PassCodeService as OTPService } from "../../../../Common/Services/PassCodeService";
import { resolve, reject } from "q";
import { faHourglassEmpty } from "@fortawesome/free-solid-svg-icons";

const styles = (theme) => ({
  overrideMe: {
    maxWidth: "auto",
  },
});

export interface IGeneralProps {
 // userData: any;
  userGeneralInfo: any;
  // onEnterpriseAccessChange: Function;
  getGeneralTabModificationStatus: Function;
  getUserGeneralInfo: Function;
  user: any;
  enterpriseAccess: any;
  enableEnterpriseAccess: any;
  statusList: any[];
  uniqueno: string;
  isNewUserAdded: boolean;

}

let primaryContacts: any[] = [
  {
    label: "Text Message",
    value: "TextMsg",
    disabled: true,
    description : "Enable method above to select"
  },
  { label: "Google Authenticator", value: "GoogleFA",disabled: true,description : "Enable method above to select" },
];


const contactFormSchema = Joi.object({
  user_UniqueId: Joi.number(),
  tenantID: Joi.number(),
  createdBy: Joi.number(),
  primaryContact: Joi.string().allow(""),
  terminationDate: Joi.string().allow(""),
  terminationReason: Joi.string().allow(""),
  userUniqueID: Joi.number(),
  accountStatus: Joi.number(),
  firstName: Joi.string()
    .required()
    .max(48)
    .error((errors) => {
      const _errors = errors.map((error) => {
        if (error?.message) {
          return;
        }
        switch (error?.type) {
          case "any.empty":
            return { message: "First Name is required" };
          case "string.max":
            return {
              message: "First Name should not be more than 48 characters",
            };
        }
      });
      return [_errors[0]];
    }),
  lastName: Joi.string()
    .required()
    .max(48)
    .error((errors) => {
      const _errors = errors.map((error) => {
        if (error?.message) {
          return;
        }
        switch (error?.type) {
          case "any.empty":
            return { message: "Last Name is required" };
          case "string.max":
            return {
              message: "Last Name should not be more than 48 characters",
            };
        }
      });
      return [_errors[0]];
    }),
    email: Joi.string()
    .regex(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "email"
    )
    .required()
    .max(200)
    .error((errors) => {
      const _errors = errors.map((error) => {
        if (error?.message) {
          return;
        }
        switch (error.type) {
          case "any.empty":
            return { message: "Email Address is required" };
          case "string.regex.name":
            return { message: "Email Address is not valid" };
          case "string.max":
            return { message: "Email Address is not valid" };
        }
      });
      return [_errors[0]];
    }),

  phone1: Joi.string()
    .optional()
    .allow("")
    .regex(/^(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, "phone1")
    .error((errors) => {
      const _errors = errors.map((error) => {
            return { message: "Mobile Phone Number is not valid" };
      });
      return [_errors[0]];
    }),
  hireDate: Joi.string()
    .required()
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Hire Date is required" };
      });
    }),
  status: Joi.string(),
  primaryMethod: Joi.string(),	
        isTextMessage: Joi.boolean(),	
        isGoogleFA: Joi.boolean(),	
        allow2FA: Joi.boolean()
});

export class ProfileGeneral extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      contactInfo: {
    firstName: props.userGeneralInfo.firstName,
    lastName: props.userGeneralInfo.lastName,
    email: props.userGeneralInfo.email,
    userUniqueID: props.userGeneralInfo.user_UniqueId,
    phone1: props.userGeneralInfo.phoneStatus === "blocked" ? "" : props.userGeneralInfo.phone1,
    status: props.userGeneralInfo?.status || "Incomplete",
    hireDate: props.userGeneralInfo.hireDate  &&
    props.userGeneralInfo.hireDate  != "0001-01-01T00:00:00"
        ? moment(props.userGeneralInfo.hireDate).format("MM/DD/YY")
        : "",
    terminationDate:  props.userGeneralInfo.terminationDate &&
    props.userGeneralInfo.terminationDate != "0001-01-01T00:00:00"
        ? moment(props.userGeneralInfo.terminationDate).format("MM/DD/YY")
        : "",
    terminationReason: props.userGeneralInfo.terminationReason || "",
    primaryContact: props.userGeneralInfo?.primaryContact || "Email",
    accountStatus: props.userGeneralInfo?.accountStatus || 0,
    primaryMethod: props.userGeneralInfo?.primaryMethod || "0",	
        isTextMessage: props?.userGeneralInfo?.isTextMessage > 0 ? true : false,	
        isGoogleFA: this.props?.userGeneralInfo?.isGoogleFA > 0 ? true : false,	
        allow2FA: this.props?.userGeneralInfo?.allow2FA > 0 ? true : false
       },
      prevContactInfo: {
    firstName: props.userGeneralInfo.firstName,
    lastName: props.userGeneralInfo.lastName,
    email: props.userGeneralInfo.email,
    userUniqueID: props.userGeneralInfo.user_UniqueId,
    phone1: props.userGeneralInfo.phoneStatus === "blocked" ? "" : props.userGeneralInfo.phone1,
    status: props.userGeneralInfo?.status || "Incomplete",
    hireDate: props.userGeneralInfo.hireDate  &&
    props.userGeneralInfo.hireDate  != "0001-01-01T00:00:00"
        ? moment(props.userGeneralInfo.hireDate).format("MM/DD/YY")
        : "",
    terminationDate:  props.userGeneralInfo.terminationDate &&
    props.userGeneralInfo.terminationDate != "0001-01-01T00:00:00"
        ? moment(props.userGeneralInfo.terminationDate).format("MM/DD/YY")
        : "",
    terminationReason: props.userGeneralInfo.terminationReason || "",
    primaryContact: props.userGeneralInfo?.primaryContact || "Email",
    accountStatus: props.userGeneralInfo?.accountStatus || 0,
    primaryMethod: props.userGeneralInfo?.primaryMethod || "0",	
    isTextMessage: props?.userGeneralInfo?.isTextMessage > 0 ? true : false,	
    isGoogleFA: this.props?.userGeneralInfo?.isGoogleFA > 0 ? true : false,	
    allow2FA: this.props?.userGeneralInfo?.allow2FA > 0 ? true : false
  },
      accessControlInfo: {
        role: props.userGeneralInfo.role || 0,
        hasEnterpriseAccess: props.userGeneralInfo.hasEnterpriseAccess,
        hids: props.userGeneralInfo.hids,
      },
      contactFormError: {},
      inputPosition: 20,
      isContactFormValid: true,
      isContactFormUpdated: false,
      userName: props.userGeneralInfo?.userName,
      isDataSubmitting: false,
      hotelWiseSetPayHours: [],
      hasLogiUserEnterpriseAccess: false,
      userRoleList: [],
      mode: "",
      primaryContact: "Phone",
      timeOutFunc: {},
      isTextMessage: this.props?.userGeneralInfo?.isTextMessage > 0 ? true : false,
      isGoogleFA: this.props?.userGeneralInfo?.isGoogleFA > 0 ? true : false,
      isSendOTP: false,
      otp: "",
      otpCount: 0,
      reSendOtp: false,
      errorMessage: "",
      errorMessageForGoogle: "",
      isValidOTP: false,
      isSendAuthKey: false,
      authKeyData: {},
      code: "",
      isValidCode: false,
      isClosePanel: false,
      isOpenOTPModal: false,
      isOpenCodeModal: false,
      primaryMethod: props.userGeneralInfo?.primaryMethod,
      isTextMessageDisabled: false,
      isGoogleDisabled: false,
      allow2FA:this.props?.userGeneralInfo?.allow2FA > 0 ? true : false,
      isSendOTPSubmitted : false,	
      otpID :0,
      isgoogleAuth: false,
      isSpinner:false
    };
    this.resendPasscode = this.resendPasscode.bind(this);

    if(this.state.contactInfo["isGoogleFA"]){
      primaryContacts[1].disabled=false;
    }
    if(this.state.contactInfo["isTextMessage"]){
      primaryContacts[0].disabled=false;
    }
  }

  renderInfoIcon = () => {
    return (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        version="1.1"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
      </svg>
    );
  };
  renderTextMessageTooltip = (e) => (
    <Tooltip id="button-tooltipPwd" {...e}>
      To allow Inn-Flow to send OTP to your phone number for 2 Factor Authentication.
    </Tooltip>
  );

  renderValidationTooltip = (e) => (
    
    <Tooltip id="button-tooltipPwd" {...e}>
      
      Due to your role, you can not disable 2-Factor Authentication.
      <br/>
      Setup secondary...
    </Tooltip>
 
  );
  
  renderGoogleTooltip = (e) => (
    <Tooltip id="button-tooltipPwd" {...e}>
      To allow Inn-Flow to use your Google authentication app for 2 Factor Authentication.
    </Tooltip>
  );

  componentDidMount = () => {
   
    this.GoogleAuthFeatureFlag();
  }

  onTextMessageChange = (event) => {
    if (event.target.checked) {
      const { contactInfo,contactFormError } = this.state;
      if (contactInfo.phone1 && !contactFormError?.phone1) {
        this.setState({ isTextMessage: true, isSendOTP: false, isOpenOTPModal: true, isClosePanel: true,isSendOTPSubmitted :false,isValidOTP:false,isTextMessageDisabled:false });
      }
      else{
        this.validationOnContactClick(4, "phone1")
      }

      
    }
    else {
      const { contactInfo } = this.state;
      if(this.props?.userGeneralInfo?.hasSpecialPermissionUsers
        && contactInfo.allow2FA && this.state.isgoogleAuth) {

      if(contactInfo.isGoogleFA === false ){
        this.setState({isTextMessageDisabled : true });
        this.setState({ isTextMessage: true});
        
      
      }
      else{
        if(contactInfo.isGoogleFA){
          contactInfo.primaryMethod = "GoogleFA";      
          this.setState({ contactInfo });
          this.setState({isTextMessageDisabled : false });
        
        }
       
          primaryContacts[0].disabled = true;
          contactInfo.isTextMessage = false;	
          this.setState({contactInfo});
          this.setState({ isTextMessage: false, isSendOTP: false, isOpenOTPModal: false,isValidOTP:false,otp:"" });
        
      }

      }
      else{
        this.setState({isTextMessageDisabled : true });
        this.setState({ isTextMessage: true});
        
      }

    }
  }

  onGoogleChange = (event) => {
    if (event.target.checked) {
      this.setState({ isGoogleFA: true, isOpenCodeModal: true, isClosePanel: true,isValidCode:false,code:"",isGoogleDisabled : false });
      this.GenerateGoogleAuthKey();
      
    }
    else {
      const { contactInfo } = this.state;
     

      if(contactInfo.isTextMessage === false){
        this.setState({isGoogleDisabled : true });
        this.setState({ isGoogleFA: true});
     
      }
      else {
        if(contactInfo.isTextMessage){
          contactInfo.primaryMethod = "TextMsg";      
          this.setState({ contactInfo ,isGoogleDisabled : false});
        }

        primaryContacts[1].disabled = true;
        contactInfo.isGoogleFA =false;	
        this.setState({contactInfo});
        this.setState({ isGoogleFA: false, isOpenCodeModal: false, isSendAuthKey: false,isValidCode:false,code:"" });
    }
    }
  }

  GenerateGoogleAuthKey() {
   

    this.setState({ isSendAuthKey: false,isSpinner:true });
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let request = {} as  any;
        request.tenantId = tenantID;
        request.userName = this.state.userName;
        OTPService.GenerateGoogleAuthKey(request)
      .then(async (result: any | null) => {
        if (result != null) {
          let resobj = {} as any;
          resobj.userName = result.userName;
          resobj.ImageUrl = result.imageUrl;
          resobj.Key = result.key;
          this.setState({ authKeyData: resobj });
          this.setState({ isSpinner: false });
        }
        // resolve();
      })
      .catch((err) => {
        this.setState({ isSendAuthKey: false,isSpinner: false })
        Utils.toastError(`Server Error, ${err}`);
        //   reject();
      });

  };

  validateCode = () => {
    
    this.setState({ isEnabled: false });
    let errorMessageForGoogle = "";
    if (this.state.code === 0 || this.state.code === "" ) {
      errorMessageForGoogle = "Please enter the confirmation code.";
      this.setState({ errorMessageForGoogle: errorMessageForGoogle });
    } 
    else if(this.state.code.length > 6)
    {
      errorMessageForGoogle = "Incorrect code entered. Please try again";
      this.setState({ errorMessageForGoogle: errorMessageForGoogle });
    }
    else
    {

     

    let request: any = {};
      request.otpID = this.state.otpID;
      request.userName = this.state.userName;
      request.userEmail = "";
      request.userPhone = "";
      request.purpose = "Google Verification - Edit Profile";
      request.otpCode = this.state.code;
      request.primaryContact = "";
      request.source = "";
      request.HID = -1;
      request.otpMode = "";

      OTPService.ValidateQRCode(request)
      .then((result) => {

        if (result != null) {

          if (result?.message === "ValidPassCode") {
            this.setState({ isValidCode: true });

            this.setState({ isSendAuthKey: true });
            const { contactInfo } = this.state;
            contactInfo.primaryMethod = "GoogleFA";
            contactInfo.isGoogleFA=true;
            this.setState({isTextMessageDisabled : false });
            this.setState({ contactInfo });
            
            this.updateGeneralInfo(true);
            // toast.success("2-Factor Authentication Successfully Enabled", {
            //   position: toast.POSITION.BOTTOM_RIGHT,
            // });
            primaryContacts[1].disabled = false;
          } else if (result?.message === "InvalidPassCode") {
            errorMessageForGoogle = "Incorrect code entered. Please try again";
            this.setState({ isValidCode: false});

            this.setState({ isSendAuthKey: true });
           // this.updateGeneralInfo();

          //  Utils.toastError("Please enter the confirmation code", {
          //   position: toast.POSITION.BOTTOM_RIGHT,
          // });
          }
          

          

          this.setState({ errorMessageForGoogle: errorMessageForGoogle });
        } else {
          this.setState({ isValidCode: false });

          this.setState({ isSendAuthKey: true });
          Utils.toastError(result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        this.setState({ isValidCode: false });

        this.setState({ isSendAuthKey: true });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    }
  }

  cancelGoogle = () => {
    this.setState({ isGoogleFA: false, isSendAuthKey: false ,isOpenCodeModal: false});
    this.setState({ errorMessageForGoogle: "", code:"" ,isGoogleDisabled : false});
  };

  handleContinue() {
    this.setState({ isSendAuthKey: true,isOpenCodeModal: true,isValidCode:false,errorMessageForGoogle: "", code:"" });
  };

  validationOnContactClick = (inputPosition, fieldName = "") => {
    this.setState({ inputPosition });
    if (
      ((fieldName === "phone1" && !this.state?.contactInfo?.phone1) ||
        fieldName !== "phone1") || (fieldName === "phone1" && this.state?.contactInfo?.phone1?.toString()?.includes("+") &&
          this.state?.contactInfo?.phone1?.toString()?.substr(1, 1) !== "1")
    ) {
      this.validateContactForm();
    }
  };
  


   validateContactForm = (isFromPhoneValidator = false) => {
    
    const {contactInfo,contactFormError} = this.state;
    const {userGeneralInfo} =this.props
    const valid = Joi.validate(contactInfo, contactFormSchema, {
      abortEarly: false,
    });

    let newErrorObject: any =
      contactFormError?.phone1 && contactInfo.phone1 &&
      !isFromPhoneValidator
        ? {phone1: contactFormError?.phone1}
        : {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if(
      newErrorObject["phone1"] == "Mobile Phone Number is not valid"&&
      contactInfo?.phone1?.toString()?.length >= 9 &&
      (contactInfo?.phone1?.toString()?.startsWith("+1242") || 
      contactInfo?.phone1?.toString()?.startsWith("1242") || 
      contactInfo?.phone1?.toString()?.startsWith("242"))
    ){
       delete newErrorObject["phone1"];
    }

    if (contactInfo.hireDate) {
  
      var splittedDate = contactInfo.hireDate?.toString()?.split("/");
      if (
        moment(contactInfo.hireDate)?.format("MM/DD/YY") === "Invalid date" ||
        contactInfo.hireDate?.toString()?.length !== 8 ||
        splittedDate[0]?.toString()?.length !== 2 ||
        splittedDate[1]?.toString()?.length !== 2 ||
        splittedDate[2]?.toString()?.length !== 2
      ) {
        newErrorObject["hireDate"] = "Hire Date should be in MM/DD/YY format";
      } else {
        if (newErrorObject["hireDate"]) {
          delete newErrorObject["hireDate"];
        }
      }
    }

    if (contactInfo.status === "Inactive" && !contactInfo.terminationDate) {
      newErrorObject["terminationDate"] =
        "Please select valid Termination Date";
    } else {
      if (newErrorObject["terminationDate"]) {
        delete newErrorObject["terminationDate"];
      }
    }
    if (contactInfo.status === "Inactive" && !contactInfo.terminationReason) {
      newErrorObject["terminationReason"] = "Please enter Termination Reason";
      
    } else {
      if (newErrorObject["terminationReason"]) {
        delete newErrorObject["terminationReason"];
      }
    }

    if ((contactInfo?.primaryContact === "Phone" ||
    this.props?.userGeneralInfo?.hasSpecialPermissionUsers) && !contactInfo.phone1) {
      newErrorObject["phone1"] = "Please enter Mobile Phone Number";
    }
    else {
      if (((newErrorObject?.phone1 && contactInfo.phone1?.toString()?.length) > 8 || 
      (contactInfo?.primaryContact === "Email" && !contactInfo.phone1)) && contactInfo.phone1?.toString()?.includes("+")
      && contactInfo.phone1?.toString()?.substr(1, 1) !== "1") {
        delete newErrorObject.phone1;
      }
    }

    
    if(userGeneralInfo?.isLowSecurityUser ===1)
    { 
      if(newErrorObject.phone1 != "Mobile Phone Number is not valid")
      {
        delete newErrorObject.phone1;
      }
      if(newErrorObject.email !="Email Address is not valid")
      {
        delete newErrorObject.email;
      }
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isContactFormValid: true });
    } else {
      this.setState({ isContactFormValid: false });
    }
    this.setState({ contactFormError: newErrorObject });
    return newErrorObject;
  };
  onContactFieldChange = (event, inputPosition, isBlur = false) => {
    
    const { timeOutFunc, contactInfo } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;

    // if(this.state.isTextMessage){
    //   event.target.disabled=false;
    // }


    //this.state.contactInfo.primaryMethod=value;
    if(fieldName === "phone1") {
      let validValue = value
        ?.toString()
        ?.replace(/[^0-9\+]+/gi, "")
      if (validValue) {
        if (validValue.indexOf("-") >= 0) {
          validValue = validValue?.replaceAll("-", "");
        }

        if (validValue?.toString()?.length > 1) {
          validValue = validValue?.toString().substr(0, 1) + validValue?.toString()?.substr(1)?.replaceAll("+", "");
        }
      }
      value = validValue
    }
    if (fieldName === "phone1" && value) {
      if (timeOutFunc) {
        clearTimeout(timeOutFunc);
      }
      this.setState({ isContactFormValid: false });
      const timeoutId = setTimeout(() => this.validatePhoneNo(value, 6), 500);
      this.setState({ timeOutFunc: timeoutId });
    }

    if (fieldName == "email") {
    const  validValue = value?.toString()?.trim();
    contactInfo[fieldName] = validValue;
     

    }
    else {
     
    contactInfo[fieldName] = value;
    }


    this.setState({ contactInfo },()=> this.validationOnContactClick(inputPosition,fieldName));
    
  };


  CapitalizeFirstLetter =(string) =>{
    if(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
    } else {
     return string; 
    }
  }

  validatePhoneNo = (phone, _inputPosition): any => {
  
    const { contactInfo, contactFormError,inputPosition,prevContactInfo } = this.state;
    this.setState({ isContactFormValid: false });
    contactInfo.phone1 = phone;
    const errorObj: any = contactFormError;
    if (!phone) {
      if (
        errorObj?.phone1 &&
        contactInfo.primaryContact === "Email"
      ) {
        delete errorObj.phone1;
        this.setState({ contactFormError: errorObj });
      }
      return;
    }

    if (
      phone?.toString()?.includes("+") &&
      phone?.toString()?.substr(1, 1) !== "1"
     

    ) {
      if(phone?.toString()?.length <9) {
        errorObj["phone1"] ="Mobile Phone Number is not valid";
        this.setState({contactFormError:errorObj}, ()=> this.validateContactForm());
      }
      else if (errorObj.phone1   ) {
        delete errorObj.phone1;
        this.setState({ contactFormError:errorObj, inputPosition:_inputPosition }, () => this.validateContactForm());
      } else {
        this.validateContactForm();
      }
      
      

      return;
    }
    this.setState({ isDataSubmitting: true });
    User.ValidatePhoneNumber(phone)
    .then((response: any) => {
      if (!contactInfo.phone1) {
        this.validateContactForm(true);
        const _isContactFormUpdated = !_.isEqual(contactInfo, prevContactInfo);
        this.setState({ isContactFormUpdated: _isContactFormUpdated});
        return;
      }
      if (
        response?.fakeNumber === "YES"
        // &&
       // response?.lineType === "CELL PHONE"
      ) {
        if(_inputPosition>inputPosition) { 
        this.setState({inputPosition:_inputPosition });
          }
        errorObj["phone1"] = "Mobile Phone Number is not valid";
        this.setState({ contactFormError:errorObj, inputPosition:_inputPosition });
      } else if (response?.lineType !== "CELL PHONE") {
        if(_inputPosition>inputPosition) { 
          this.setState({inputPosition:_inputPosition });
          }
        errorObj["phone1"] = response?.lineType ? `${this.CapitalizeFirstLetter(response?.lineType)} Phone Numbers are not supported` : `Mobile Phone Number is not valid` ;
        this.setState({ contactFormError:errorObj});
       
      } else {
        this.validateContactForm(true);
        const _isContactFormUpdated = !_.isEqual(contactInfo, prevContactInfo);
        this.setState({ isContactFormUpdated: _isContactFormUpdated});
        if (errorObj?.phone1) {
          delete errorObj.phone1;
          this.setState({ contactFormError:errorObj});
        }
      }

      if (Object.keys(errorObj).length === 0) {
        this.setState({ isContactFormValid: true });
      } else {
        this.setState({ isContactFormValid: false });
      }
    })
    .finally(() =>    this.setState({ isDataSubmitting: false }));
  };

  getContactFieldError = (fieldName: string, fieldPosition: number) => {
   
    const { contactFormError, inputPosition } = this.state;
    return (
      <>
        {Object.keys(contactFormError).length > 0 &&
          fieldPosition <= inputPosition && (
            <span className="validation-message">
              {contactFormError[fieldName]}
            </span>
          )}
      </>
    );
  };

  onInputChange = (value: number) => {
    var numbers = /^[0-9]+$/;
    let x= value.toString();
    if (isNaN(value) || (value && !x.match(numbers))) {
      let errorMessage = "Invalid input, accept only numeric value";
      this.setState({ errorMessage: errorMessage });
    } else {
      if(value.toString().length <= 6){
      this.setState({ otp: value });
      this.setState({ errorMessage: "" });
      }
    }
  };

  onInputChangeForGoogle = (value: number) => {
   
    var numbers = /^[0-9]+$/;
    let x= value ? value.toString() : "";
    
    if (isNaN(value) ||  (value && !x.match(numbers))) {
      let errorMessageForGoogle = "Invalid input, accept only numeric value";
      this.setState({ errorMessageForGoogle: errorMessageForGoogle });
      
      
    } else {
      if(value.toString().length <= 6){
      this.setState({ code: value });
      this.setState({ errorMessageForGoogle: "" });
      }
    }
  };

  SendOTP(mode = "") {

    const { contactInfo } = this.state;
    this.setState({isSendOTPSubmitted :true});

    let request: any = {};
    request.otpID = 0;
    request.userName = this.props.userGeneralInfo?.userName;;
    request.userEmail = contactInfo?.email;
    request.userPhone = contactInfo?.phone1;
    request.purpose = "Text Message Verification - Edit Profile";;
    request.otpCode = "";
    request.primaryContact = "phone";
    request.source = "3";
    request.HID = -1;
    request.otpMode = "sms";

    OTPService.SendPassCode(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.status === "Success") {
            this.setState({ isSendOTP: true });
            this.setState({ otpID: result.otpID });
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut1",
            });

          } else {
            this.setState({ isSendOTP: false,isSendOTPSubmitted : false })
            toast.success("Something went wrong", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut2",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ isSendOTP: false,isSendOTPSubmitted :false })
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      });



  };


  resendPasscode(event: any) {
    this.setState({ reSendOtp: true });
    const { contactInfo } = this.state;
    
    let request: any = {};
    request.otpID = 0;
    request.userName = this.props.userGeneralInfo?.userName;;
    request.userEmail = contactInfo?.email;
    request.userPhone = contactInfo?.phone1;
    request.purpose = "Text Message Verification - Edit Profile";;
    request.otpCode = "";
    request.primaryContact = "phone";
    request.source = "3";
    request.HID = -1;
    request.otpMode = "";

    OTPService.SendPassCode(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          if (result.status === "Success") {
            this.setState({ isSendOTP: true });
            this.setState({ otpID: result.otpID });
            toast.success(result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });

          } else {
            this.setState({ isSendOTP: false })
            toast.success("Something went wrong", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "userSlideOut",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ isSendOTP: false })
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
      });

      setTimeout(() => {
        this.setState({ reSendOtp: false });
      }, 60000);
  }

  validateOTP = () => {
   
    const { contactInfo } = this.state;
    let errorMessage = "";

    if (this.state.otp === 0 || this.state.otp === "") {
      errorMessage = "Please enter validation code";
      this.setState({ errorMessage: errorMessage });
    }
    else if (this.state.otp.length > 6) {
      errorMessage = "Please enter valid validation code";
      this.setState({ errorMessage: errorMessage });
    }
    else {
      this.setState({ loaderTrue: true });

      let request: any = {};
      request.otpID = this.state.otpID;
      request.userName = this.props.userGeneralInfo?.userName;
      request.userEmail = contactInfo?.email;
      request.userPhone = contactInfo?.phone1;
      request.purpose = "Text Message Verification - Edit Profile";
      request.otpCode = this.state.otp;
      request.primaryContact = "phone";
      request.source = "";
      request.HID = -1;
      request.otpMode = "sms";
      OTPService.ValidatePassCode(request)
        .then(async (result: any | null) => {

          if (result.status !== "") {
            if (result.status === "InvalidPassCode") {
              errorMessage = "Incorrect passcode entered. Please try again";
              // Utils.toastError(errorMessage, {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              //   containerId: "userSlideOut",
              // });
            } else if (result.status === "ExpiredPassCode") {
              errorMessage =
                "Passcode expired. Please click 'Resend Code' to send a new passcode";
              // Utils.toastError(errorMessage, {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              //   containerId: "userSlideOut",
              // });

             
            } else if (result.status === "ValidPassCode") {
              this.setState({ isValidOTP: true });
              this.setState({ isSendOTP: true });
              this.setState({ isTextMessageVerified: true });
              const { contactInfo } = this.state;
              this.setState({isGoogleDisabled : false });
              if(contactInfo.isGoogleFA){
                contactInfo.primaryMethod = "GoogleFA";                
              }
              else {
                contactInfo.primaryMethod = "TextMsg"; 
              }
              contactInfo.isTextMessage=true;
              this.setState({ contactInfo });
              this.updateGeneralInfo(true);
              // toast.success("2-Factor Authentication Successfully Enabled", {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              //   containerId: "userSlideOut",
              // });

              
              primaryContacts[0].disabled = false;
             
            }
          }
          this.setState({ errorMessage: errorMessage });


        })
        .catch((error) => {
          this.setState({ isValidOTP: false });
          this.setState({ isSendOTP: true });
          Utils.toastError(error?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userSlideOut",
          });

        });
    }



  }

  cancel = () => {
    this.setState({ isTextMessage: false, isSendOTP: false,isSendOTPSubmitted :faHourglassEmpty,isTextMessageDisabled:false });
    this.setState({ errorMessage: "" ,otp:""});
  };


  handleDiscard = () => {
    this.props.getGeneralTabModificationStatus(false);
    this.props.getUserGeneralInfo();
    this.setState({ isClosePanel: true });
    this.setState({ errorMessage: "" ,errorMessageForGoogle:"",isSendOTPSubmitted :false});
  };

   updateGeneralInfo = (otp) => {
 
    const request: IGeneralInfo = {} as IGeneralInfo;
   const {isContactFormValid,contactInfo,accessControlInfo} = this.state;
    // group1
    if (isContactFormValid ) {
      request.firstName = contactInfo.firstName;
      request.lastName = contactInfo.lastName;
      request.email = contactInfo.email;
      request.user_UniqueId = contactInfo.userUniqueID;
      request.phone1 = contactInfo.phone1;
      request.status = contactInfo.status ? contactInfo.status : "";
      request.hireDate = contactInfo.hireDate;
      request.terminationDate = contactInfo.terminationDate;
      request.terminationReason = contactInfo.terminationReason;
      request.primaryContact = contactInfo.primaryContact;
      request.accountStatus = contactInfo.accountStatus;
      // group 2
      request.role = accessControlInfo.role;
      request.hids = accessControlInfo.hids;
      request.modifyAccessInfo = false;
      request.modifyContactInfo = true;

      request.primaryMethod = contactInfo.primaryMethod === "0" ? "" :contactInfo.primaryMethod;
      request.isTextMessage = contactInfo.isTextMessage ? 1 : 0;
      request.isGoogleFA = contactInfo.isGoogleFA ? 1 : 0;

    }

  if(this.props?.userGeneralInfo?.hasSpecialPermissionUsers && this.state.isgoogleAuth
      && contactInfo.allow2FA &&  contactInfo.isTextMessage == false && contactInfo.isGoogleFA == false) {
      Utils.toastError("Please setup 2-Factor Authentication method", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "generaltab1",
      });
      this.setState({ isDataSubmitting: false });
      return;
    
  }
  else if(this.props?.userGeneralInfo?.hasSpecialPermissionUsers && ( !this.state.isgoogleAuth
    || !contactInfo.allow2FA) &&  contactInfo.isTextMessage == false ) {
    Utils.toastError("Please setup 2-Factor Authentication method", {
      position: toast.POSITION.BOTTOM_RIGHT,
      containerId: "generaltab1",
    });
    this.setState({ isDataSubmitting: false });
    return;
  
} 
  else{
    request.isNewUserAdded = false;
   this.setState({isDataSubmitting:true});

      UserManagement.updateGeneralInfo(request)
      .then((result) => {
        this.setState({isDataSubmitting:false});
        if (result?.result?.success) {
         // const NewprimaryContacts = primaryContacts;
          this.setState({prevContactInfo:_.cloneDeep(contactInfo)});

          toast.success(result?.result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "generaltab",
          });
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "generaltab",
          });
        }
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "generaltab",
        });
      });
    }
  };




   showHideSavebutton = (): boolean => {
    
    const {isContactFormValid,contactInfo,prevContactInfo,isTextMessage, isGoogleFA}=this.state;
    const isContactFormUpdated = !_.isEqual(contactInfo, prevContactInfo);
    const _isPrimaryMethodUpdated = !_.isEqual(contactInfo.primaryMethod, prevContactInfo.primaryMethod);
    const _isTextMsgUpdated = !_.isEqual(isTextMessage, prevContactInfo.isTextMessage);
    const _isGoogleAuthUpdated = !_.isEqual(isGoogleFA, prevContactInfo.isGoogleFA);

      if (isContactFormValid ) {
        if (isContactFormUpdated && isContactFormValid ) {
          //_isPrimaryMethodUpdated || _isTextMsgUpdated || _isGoogleAuthUpdated
          return true;
        }  else {
          return false;
        }
      } else {
        return false;
      }
   
  };

  GoogleAuthFeatureFlag = () => {


    OTPService.GoogleAuthFeatureFlag()
      .then(async (result: any | null) => {

        if (result?.result.length > 0) {
          let googleAuth = result?.result[0].googleAuth;
          if(googleAuth === "No"){
            primaryContacts.splice(1);
          }

          this.setState({
           
            isgoogleAuth: (googleAuth === "Yes" ? true : false)
          })
        }

        resolve();
      })
      .catch((error) => {

        reject();
      });
  }

  render() {
    const { userGeneralInfo } = this.props;
    const {

      contactInfo,
      userName,
      otp,
      code,
      authKeyData,
      isDataSubmitting,
      isGoogleDisabled,
      isTextMessageDisabled,
      contactFormError,
      inputPosition,
      isSendOTPSubmitted,	
     
    } = this.state;
    // let primarymethodlength = primaryContacts.filter(item => item.value == contactInfo.primaryMethod) ;
    // debugger;
    //     if(primarymethodlength.length == 0){
    //       contactInfo.primaryMethod = primaryContacts[0].value;
    //     }
    const isEnabled = otp.length === 6;
    return (
      <>
        <>
          <div className="general-tab">

            <>
              <div className="body-section">
                <div className="heading">User Information</div>
                <div className="form-section d-flex flex-column">
                  <Form.Group
                    controlId="date-selection"
                    className="d-flex flex-row"
                  >
                    <Form.Label
                    //  onBlur={() => this.validationOnContactClick(1)}
                    >
                      First Name
                    </Form.Label>
                    {/* <div className="validate-input d-flex flex-column">
                      <Form.Label>
                        {Utils.CapitalizeFirstLetter(
                          contactInfo?.firstName
                        )}
                      </Form.Label>
                    </div> */}
                    <div className="readonly-value">
                      <EllipsisWithTooltip placement="bottom">
                      {Utils.CapitalizeFirstLetter(
                            contactInfo?.firstName
                          )}
                      </EllipsisWithTooltip>
                      </div>
                  </Form.Group>
                  <Form.Group
                    controlId="date-selection"
                    className="d-flex flex-row"

                  >
                    <Form.Label>Last Name</Form.Label>
                    {/* <div className="validate-input d-flex flex-column">
                      <Form.Label>{Utils.CapitalizeFirstLetter(
                        contactInfo?.lastName
                      )}</Form.Label>
                    </div> */}
                    <div className="readonly-value">  
                    <EllipsisWithTooltip placement="bottom">
                      {Utils.CapitalizeFirstLetter(
                        contactInfo?.lastName
                      )}
                      </EllipsisWithTooltip></div>
                  </Form.Group>
                  <Form.Group
                    className="d-flex flex-row"
                    controlId="date-selection"
                  >
                    <Form.Label>Username</Form.Label>
                    {/* <div className="validate-input">
                      <Form.Label>{userName}</Form.Label>
                    </div> */}
                    <div className="readonly-value">  <EllipsisWithTooltip placement="bottom">{userName} </EllipsisWithTooltip> </div>
                  </Form.Group>
                  <Form.Group
                   
                   controlId="date-selection"
                   className={
                     contactFormError?.email && inputPosition >= 3
                       ? "validation-error d-flex flex-row"
                       : "d-flex flex-row"
                   }
                 >
                  
                   <Form.Label>
                     <div className="d-flex align-items-center">
                         <div className={contactFormError?.email ? "pips" : ""}><div className="pip light-red"></div></div>
                         <div>Email</div>
                     </div>
                     </Form.Label>
                   <div className="validate-input d-flex flex-column">
                     {
                       contactInfo?.email != "" &&
                       <ReactTooltip id="txtEmail" effect="solid" place="bottom">
                         {contactInfo?.email}
                       </ReactTooltip>
                     }
                     
                     <input
                       type="text"
                       className="form-control"
                       id="txtEmail"
                       data-tip
                       data-for="txtEmail"
                       placeholder="Add Email Address"
                    
                       name="email"
                       autoComplete="email"
                       value={contactInfo?.email}
                       onChange={(e: any) => this.onContactFieldChange(e, 3)}
                       onBlur={() => this.validationOnContactClick(3)}
                     />

                   
                     {this.getContactFieldError("email", 3)}
                   </div>
                 </Form.Group>
                  <Form.Group
                    controlId="date-selection"
                    className="d-flex flex-row"
                  >
                    <Form.Label>User ID</Form.Label>
                    {/* <Form.Label>{this.props.uniqueno}</Form.Label> */}
                    <div className="readonly-value">{this.props.uniqueno}</div>
                  </Form.Group>
                  <Form.Group
               
                    controlId="date-selection"
                    className={
                      contactFormError?.phone1 && inputPosition >= 4
                        ? "validation-error d-flex flex-row"
                        : "d-flex flex-row"
                    }
                  >                    
                    <Form.Label>
                      <div className="d-flex align-items-center">
                          <div className={contactFormError?.phone1 ? "pips" : ""}><div className="pip light-red"></div></div>
                          <div>Mobile Phone</div>
                      </div>
                      
                      </Form.Label>
                    <div className="validate-input d-flex flex-column">
                      <input
                        type="text"
                        className="form-control"
                        id="txtPhoneNo"
                        name="phone1"
                        maxLength={16}
                        autoComplete="phone1"
                        placeholder="Add Mobile Phone Number"
                      
                        value={contactInfo?.phone1}
                        onChange={(e: any) => {
                          this.onContactFieldChange(e, 4);
                        }}
                        onBlur={() => this.validationOnContactClick(4, "phone1")}
                      />

                   
                      {this.getContactFieldError("phone1", 4)}
                    </div>
                 
                  </Form.Group>
                </div>
              </div>
              <div className="separator-line"></div>
            </>
            <>
            {this.state.allow2FA && 
              <div className="body-section">
                <div className="heading">
                <div className={ this.props?.userGeneralInfo?.hasSpecialPermissionUsers && !contactInfo.isTextMessage && !contactInfo.isGoogleFA ? "pips" : ""}>
                  <div className="pip light-red"></div></div>
                  2-Factor Authentication 
               
                </div>
                <div className="form-section d-flex flex-column mb-3">
                  <>
                    <Form.Group
                      controlId="date-selection"
                      className="d-flex flex-row"
                    >
                      <Form.Label
                      //  onBlur={() => this.validationOnContactClick(1)}
                      >
                        Text Message {""} 
                        <OverlayTrigger
                            placement="bottom"
                            overlay={this.renderTextMessageTooltip}
                            
                          >
                            {this.renderInfoIcon()}
                          </OverlayTrigger>
                      </Form.Label>
                      <div className="validate-input d-flex flex-column">
                      {isTextMessageDisabled && ( 
                          <ReactTooltip
                            className={"overrideMe"}
                            data-html={true}
                            id="ChkIsTextMessage"
                            effect="solid"
                            place="bottom"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            multiline={true}
                          >
                           <span>  Due to your role, you can not disable 2-Factor Authentication.
                            <br/>
                            Setup secondary...</span>
                          </ReactTooltip>
                        )} 
                      <Form.Check
                        type="switch"
                        id="ChkIsTextMessage"
                        label=""
                        name="ChkTextMessage"
                        checked={this.state.isTextMessage}
                       // disabled={isTextMessageDisabled}
                       data-tip
                          data-for="ChkIsTextMessage"
                        onChange={(event: any) => {
                          this.onTextMessageChange(event);
                        }}
                      />
                         
                      </div>
                    </Form.Group>

                    {this.state.isTextMessage && !this.state.isSendOTP && this.state.isOpenOTPModal && (
                      <>
                        <div className="setup-text-message">
                          <div className="section-header">
                            <div className="title">Setup Text Message</div>
                            <span className="description">
                              In order to enable text messages for 2-factor authentication, we need to send a code to your phone number for verification.
                            </span>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-footer">
                            <Button
                              variant="primary"
                              type="button"
                              className="btn-ghost"
                              onClick={() => this.cancel()}
                            >
                              Cancel
                            </Button>
                            <Button disabled={isSendOTPSubmitted} onClick={() => this.SendOTP()} variant="primary" type="button">
                              Send Code
                            </Button>
                          </div>
                        </div>
                      </>
                    )}

                    {this.state.isTextMessage && this.state.isSendOTP && !this.state.isValidOTP && (
                      <>
                        <div className="setup-text-message">
                          <div className="section-header">
                            <div className="title">Setup Text Message</div>
                            <span className="description">Enter the code you received below:</span>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-body">

                            <Form.Group
                              controlId="date-selection"
                            className={
                              (this.state.errorMessage !== "" && (!this.state.isValidOTP || this.state.otp.length < 6))
                                ? "validation-error d-flex flex-column"
                                : "d-flex flex-column"
                            }
                           // invalid entry class apply condition to add/remove this class   
                            >
                              <Form.Label>Validation Code</Form.Label>
                              <br/>
                              <div className="validate-input">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtOtp"
                                  name="otp"
                                  autoComplete="otp"
                                  placeholder="Enter the Code you received"
                                  value={this.state.otp}

                                  //  onBlur={(e: any) => this.validate(e, "phone")}
                                  onChange={(e: any) => this.onInputChange(e.target.value)}
                                />
                                <span className="validation-message">
                                  {this.state.errorMessage}
                                </span>
                              </div>
                              <br/>
                              <div className="receive-textcode">
                                Didn't receive the code ?{" "}
                                <span
                                  className={!this.state.reSendOtp ? "enableOtp" : "disabledOtp"}
                                  onClick={this.resendPasscode}
                                >Resend Code</span>
                              </div>
                            </Form.Group>

                          </div>
                          <div className="separator-line"></div>
                          <div className="section-footer">
                            <Button
                              variant="primary"
                              type="button"
                              className="btn-ghost"
                              onClick={() => this.cancel()}
                            >
                              Cancel
                            </Button>
                            {
                              this.state.isSendOTP == true &&
                              <Button onClick={() => this.validateOTP()} variant="primary" type="button" >
                                Submit
                              </Button>
                            }
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  <>
              {this.state.isgoogleAuth   && (             
                    <Form.Group
                      controlId="date-selection"
                      className="d-flex flex-row"
                    >
                      <Form.Label>Google {""}
                      <OverlayTrigger
                            placement="bottom"
                            overlay={this.renderGoogleTooltip}
                          >
                            {this.renderInfoIcon()}
                          </OverlayTrigger>

                      </Form.Label>
                      <div className="validate-input d-flex flex-column">
                    
                       {isGoogleDisabled && ( 
                          <ReactTooltip
                            className={"overrideMe"}
                            data-html={true}
                            id="ChkIsGoogle"
                            effect="solid"
                            place="bottom"
                            event="mouseover mouseenter"
                            eventOff="mouseleave mouseout scroll mousewheel blur"
                            multiline={true}
                          >
                           <span>  Due to your role, you can not disable 2-Factor Authentication.
                            <br/>
                            Setup secondary...</span>
                          </ReactTooltip>
                        )} 
                      <Form.Check
                          type="switch"
                          id="ChkIsGoogle"
                          label=""
                          name="ChkGoogle"
                          checked={this.state.isGoogleFA}
                          data-tip
                          data-for="ChkIsGoogle"
                          //   disabled={!(this.props?.isEditSalesInfo || this.props?.isNewUserAdded)}
                          onChange={(event: any) => {
                            this.onGoogleChange(event);
                          }}
                      />
                      {/* )
                      } */}
                      </div>
                    </Form.Group>
    )}
                    {this.state.isGoogleFA && !this.state.isSendAuthKey && this.state.isOpenCodeModal && (
                      <>
                        <div className="setup-google-auth-section">
                          <div className="section-header">
                            <div className="title">Setup Google Authenticator</div>

                            <div className="description">
                              In order to enable Google 2-Factor authentication, use your Google authentication app to scan this QR code :
                            </div>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-body d-flex flex-row align-items-center">
                         
                            <div className="bar-code">
                              <img
                                src={this.state.authKeyData.ImageUrl}
                                className={"default"}
                                width="230"
                                height="230"
                              //onError={(e) => (e.currentTarget.src = profile)}
                              />
                            </div>
                            <div className="optional-code">
                              <div className="text"> Or enter this code into your authentication app:</div>
                              <div className="authentication-code">{this.state.authKeyData.Key}</div>
                              {/* <div className="authentication-code">XNCG FPRO REP3 KV5H</div> */}
                            </div>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-footer">
                            <Button
                              variant="primary"
                              type="button"
                              className="btn-ghost"
                              onClick={() => this.cancelGoogle()}
                            >
                              Cancel
                            </Button>

                            <Button onClick={() => this.handleContinue()} variant="primary" type="button">
                              Continue
                            </Button>


                          </div>
                          {this.state.isSpinner && (
                              <div className="loader-spinner d-flex justify-content-center align-items-center">
                                <Spinner animation="border" variant="success" />
                              </div>
                            )}
                        </div>
                      </>
                    )}

                    {this.state.isGoogleFA && this.state.isSendAuthKey && !this.state.isValidCode && (
                      <>
                        <div className="setup-google-auth-section">
                          <div className="section-header">
                            <div className="title">Setup Google Authenticator</div>
                            <div className="description">Enter the confirmation code you see on your Google Authenticator app:</div>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-body">
                            <Form.Group
                              controlId="date-selection"
                              className={
                                (this.state.errorMessageForGoogle !== "" && (!this.state.isValidCode || this.state.code.length < 6))
                                  ? "validation-error d-flex flex-column"
                                  : "d-flex flex-column"
                              }
                            //className="validation-error" // invalid entry class apply condition to add/remove this class
                            >
                              <Form.Label>Confirmation Code</Form.Label>
                              <div className="validate-input">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtcode"
                                  name="code"
                                  autoComplete="code"
                                  placeholder="Enter the Code you received"
                                  value={this.state.code}

                                  //  onBlur={(e: any) => this.validate(e, "phone")}
                                  onChange={(e: any) => this.onInputChangeForGoogle(e.target.value)}
                                />
                                <span className="validation-message">
                                  {this.state.errorMessageForGoogle}
                                </span>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="separator-line"></div>
                          <div className="section-footer">
                            <Button
                              variant="primary"
                              type="button"
                              className="btn-ghost"
                              onClick={() => this.cancelGoogle()}
                            >
                              Cancel
                            </Button>
                            <Button  onClick={() => this.validateCode()} variant="primary" type="button" >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  <>

                    <Form.Group
                      controlId="date-selection"
                      className="d-flex flex-row"
                    >
                      <Form.Label>Primary Method </Form.Label>
                      {primaryContacts?.length > 0 && (       
                      <DropDownListWithDescription
                        placeHolder={"Select Primary Method"}
                        data={primaryContacts}
                        defaultValue={contactInfo.primaryMethod}
                        isSearchRequired={false}
                        label={"label"}
                        value={"value"}
                        
                       
                        onDropDownChange={(item) => {
                          if (!item) {
                            return;
                          }
                          this.onContactFieldChange(
                            Utils.BuildCustomEventObject(
                              "primaryMethod",
                              item.value
                            ),
                            5
                          );
                        }}
                       
                        selectedItem= {[
                          ...[{ value: "0", label: "Select Primary Method" }],
                          ...primaryContacts,
                        ].find(
                          (r: any) => r?.value === contactInfo.primaryMethod
                        )}
                      />

                            
                      )}
                    </Form.Group>

                  </>
                </div>
              </div>
            }
            </>

          </div>
          <>
            {this.showHideSavebutton() &&
              <div className="fixed-action ml-auto">
                <div className="d-flex align-content-center flex-wrap justify-content-end">

                  <div className="mr-auto message">
                    <span>You have unsaved changes</span>
                  </div>
                  <button
                    disabled={isDataSubmitting}
                    type="button"
                    className="btn btn-primary btn-discard"
                    onClick={this.handleDiscard}
                  >
                    Discard
                  </button>

                  <button
                    disabled={isDataSubmitting}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.updateGeneralInfo("")}
                  >
                    Save
                  </button>
                </div>
              </div>
            }
          </>
        </>
      </>
    );
  }
}
