import React from "react";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolve, reject } from "q";
import $ from "jquery";
// import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
// import { PayrollProcessor } from "../../../Common/Services/PayrollProcessor";
// import {
//   IGetPayrollExportReciepentData,
//   ISaveCodeMappingData,
// } from "../../../Common/Contracts/IPayrollProcessor";
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../../Common/Utilis";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import "./PayrollPositionMapping.scss";
import RouteLeavingGuard from "../../Common/Components/RouteLeavingGuard";
import { LaborDeptPosition as laborDeptPosition } from "../../Common/Services/LaborDeptPosition";

let routeLeavingGuard: any = null;
export class PayrollPositionMapping extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      loaderTrue: false,
      oldTableData: [],     
      tableData: [],    
      payRollType:[],
     showSaveBanner: false,
     payrollID:0,
     showSaveloader: false,
    };
  }

  componentDidMount() {
  
    //this.getPayrollIntegrationType();
   // this.getExportReciepentData("componentMount");
  }

  getPayrollIntegrationType = () => {
    
    this.setState({ loaderTrue: true });
    laborDeptPosition
      .GetPayrollIntegrationType()
      .then(async (result: any[] | null) => {
      
        if (result != null && result.length > 0) {
          
          let activeExportName = result[0].displayName;
          let activeExportType = result[0].name;    
          let PayrollID = result[0].payrollID; 
          this.setState({payRollType:result},()=>{
           this.setState({activeExportType, activeExportName,payrollID:PayrollID});
           this.getPayrollPositionMappingDetails(PayrollID);
          });       

        }

        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };


  getPayrollPositionMappingDetails = (payRollTypeID) => {
  
    //this.setState({ loaderTrue: true });
   let request: any = {};
   request.payRollType=payRollTypeID;
    laborDeptPosition
      .GetPayrollPositionMappingDetails(request)
      .then(async (result: any[] | null) => {
     
        if (result != null && result.length > 0) {

          
          let listArray: any = [];
          // for (let i = 0; i < result.length; i++) {
          //   let dynamicObject: any = {};
          //   dynamicObject.id=result[i].id;
          //   dynamicObject.departmentID=result[i].departmentID;
          //   dynamicObject.departmentName=result[i].departmentName;
          //   dynamicObject.payrollPosition=result[i].payrollPosition;
          //   dynamicObject.positionID=result[i].positionID;
          //   dynamicObject.postionName=result[i].postionName;
          //   dynamicObject.payrollID=result[i].payrollID;
          //   dynamicObject.positionsMapValidation=false;    
          //   dynamicObject.isEditPermission=result[i].isEditPermission;   
          //   listArray.push(dynamicObject)
          // }    
          for (const item of result) {
            let dynamicObject: any = {};
            dynamicObject.id = item.id;
            dynamicObject.departmentID = item.departmentID;
            dynamicObject.departmentName = item.departmentName;
            dynamicObject.payrollPosition = item.payrollPosition;
            dynamicObject.positionID = item.positionID;
            dynamicObject.postionName = item.postionName;
            dynamicObject.payrollID = item.payrollID;
            dynamicObject.positionsMapValidation = false;    
            dynamicObject.isEditPermission = item.isEditPermission;   
            listArray.push(dynamicObject);
          }
         this.setState({tableData:listArray,oldTableData:listArray,loaderTrue: false });
        }else{
          this.setState({ loaderTrue: false });
        }

        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        reject();
      });
  };



  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.showSaveBanner &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }



  positionFormatter = (cell: any, row: any, rowIndex: any) => {
    return <div className="ehid-formatter">{row.postionName}</div>;
  };

  deptFormatter = (cell: any, row: any, rowIndex: any) => {
    return <div className="dept-formatter">{row.departmentName}</div>;
  };
  onDescriptionChange = (e: any, row: any, rowIndex: any) => {
    let inputId = `${rowIndex}payrollcompanypositions`;

    let value = e.target.value;
    let cursorIndex = e.target.selectionStart;

    // let descriptionRegex = /^[A-Za-z0-9\-_() ]+$/;

    // if (descriptionRegex.test(value) === false) {
    //   value = value.replace(/[^A-Za-z0-9\-_() ]/g, "");
    // }
    // if (value.length > 100) {
    //   let a = value.replace(/[^A-Za-z0-9\-_() ]/g, "");
    //   value = a.substring(0, value.length - 1);
    // }

    this.setState({ showSaveBanner: true },()=>{
     this.props.togglePayrollPositionMapping(true);
    })
    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
      if (d.id === row.id) {
        return {
          ...d,
          payrollPosition: value?.toString()?.trimLeft(),
          positionsMapValidation: false, 
        };
      } else {
        return d;
      }
    });
    this.setState(
      (curr: any) => ({
        ...curr,
        tableData: newTableData,
      }),
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
    );
  };

  

  

  payrollcompanypositions = (cell: any, row: any, rowIndex: any) => {
   
    return (
      <div className="description-formatter">
        <Form.Group>
          <Form.Control   autoComplete="Off"        
            className={row.positionsMapValidation ? "validation-error" : ""}
            id={`${rowIndex}payrollcompanypositions`}
            maxLength={200}
            value={row.payrollPosition}
            onChange={(e: any) => this.onDescriptionChange(e, row, rowIndex)}
           // disabled={row.isEditPermission=="Yes" ? false : true}
          
          
          //  disabled={row.isEditPermission=="Yes" ? false : true} 
          />
           {row.positionsMapValidation ? <div className="message">Position mapping required</div> :"" }
        </Form.Group>
      </div>
    );
  };

  payrollExportSelection = (item: any) => {    
    if(this.state.showSaveBanner){
      this.isValidateDiscard("payrollExportSelection",item);
      return;
    }else{
      let activeExportName = item.displayName;
      let activeExportType = item.name;    
      this.setState({ activeExportType, activeExportName,payrollID:item.payrollID }, () => {       
        this.getPayrollPositionMappingDetails(item.payrollID);
      });
    }

    
  };

  discardDdlExportSelection = (item: any) => {  
    let activeExportName = item.displayName;
    let activeExportType = item.name;    
    this.setState({ activeExportType, activeExportName,payrollID:item.payrollID }, () => {     
      this.getPayrollPositionMappingDetails(item.payrollID);
    });
  };


  handleSave = () => {
    debugger;
      let validateResult: boolean = false;
      let validateMessage: string = "";
      let validatior: string = "";
      this.setState({showSaveloader: true})
        let newTableData = [...this.state.tableData];
        newTableData.map((d,index) => {
          let regx=/^\s*$/;

          if (regx.test(d.payrollPosition)) {        
            d.positionsMapValidation= true ;  
            validateResult = true;              
          } 
        });
      
       if(validateResult){

        this.setState({tableData:[] },()=>{
          this.setState({ tableData: newTableData, showSaveloader: false});
         });

        validateMessage = "Payroll company positions can not be blank";     
        Utils.toastError(validateMessage, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "payrollProcessorIndex",
        });
       }else{
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID;
       
         let request=[...this.state.tableData];
         let listArrayRequest: any = [];
        for (let i = 0; i < request.length; i++) {
          let requestObject : any = {};
          requestObject.ifPosition=request[i].positionID;
          requestObject.payrollID=this.state.payrollID;
          requestObject.tenantID=tenantID;
          requestObject.payrollPosition=request[i].payrollPosition;
          requestObject.saveType="";         
          listArrayRequest.push(requestObject)
          
         }    
       

         laborDeptPosition.SavePayrollPositionMappingDetails(listArrayRequest)
          .then(async (result: any | null) => {
            if (result === "Success") {

              this.setState({showSaveBanner: false, showSaveloader: false},()=>{
                this.props.togglePayrollPositionMapping(false);
              }); 

              toast.success("Payroll company positions mapped Successfully", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "payrollProcessorIndex",
              });
            } else {
              Utils.toastError("Save Error, please try again", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
                // // containerId: "payrollProcessorIndex",
              });
            }
            resolve();
          })
          .catch((err: any) => {
            Utils.toastError(`Server Error, ${err}`, {
              // // position: toast.POSITION.BOTTOM_RIGHT,
              // // containerId: "payrollProcessorIndex",
            });
            reject();
          });
      }   
  };

  handleDiscardChanges = (type:any,item:any) => {  
    if(type==="disccardbutton"){
    let oldTable=[...this.state.oldTableData];
    this.setState({ tableData:oldTable, showSaveBanner: false},()=>{
      this.props.togglePayrollPositionMapping(false);
    })   
    } 
    if(type==="payrollExportSelection"){
      let oldTable=[...this.state.oldTableData];
      this.setState({ tableData:oldTable, showSaveBanner: false},()=>{
        this.discardDdlExportSelection(item);
        this.props.togglePayrollPositionMapping(false);
      })   
      } 


   };

  disccardChanges = () => {
    this.isValidateDiscard("disccardbutton","");
   
 }

  isValidateDiscard(type:any,item:any) {
    confirmAlert({
      title: "Discard Changes",
      message:
        "Are you sure you want to discard the changes ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleDiscardChanges(type,item),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };



  render() {
    const columns = [
      {
        dataField: "position",
        text: "Position",
        editable: false,
        formatter: this.positionFormatter,
      },
      {
        dataField: "department",
        text: "Department",
        editable: false,
        formatter: this.deptFormatter,
      },
      {
        dataField: "payrollcompanypositions",
        text: "Payroll Company Positions",
        editable: false,
        formatter: this.payrollcompanypositions,
      },
    
    ];

    return (
      <>

<RouteLeavingGuard
          when={this.state.showSaveBanner}
          routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
          shouldBlockNavigation={(location: { pathname: any }) => {
           // debugger;
            if (this.state.showSaveBanner) {
              if (location.pathname) {
                return true;
              }
            }
            return false;
          }}
        />




        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"payroll-position-mapping"} />
        ) : (
          <div className="payroll-position-mapping-wrapper">
            <div className="formatSelector d-flex align-items-center">
              <span className="format-label">Select Payroll Type</span>
              <Dropdown className="format-select">
                <Dropdown.Toggle id="dropdown-hid">
                  {this.state.activeExportName}
                </Dropdown.Toggle>
                <Dropdown.Menu className="format-selector-menu">
                  {this.state.payRollType.map((item: any, idx: any) => (
                    <Dropdown.Item
                      key={item.payrollID}
                      onClick={() => {
                        this.payrollExportSelection(item);
                      }}
                    >
                      {item.displayName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className={`payroll-position-mapping-table ${this.state.showSaveBanner ? "set-table-height" : ""}`}>
              <BootstrapTable
                id="payroll-position-mapping"
                keyField="id"
                data={this.state.tableData}
                columns={columns}
                hover
              />
            </div>
            {
              this.state.showSaveBanner && 
                <div className="fixed-action px-5 pb-2 h-auto">
                  <div className="d-flex align-items-center flex-wrap forecast-action-strip mr-4">
                    <div className="mr-auto message">
                      <span>
                        You have made changes to this Payroll company positions. Do
                        you want to save and continue ?{" "}
                      </span>
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary border-0 btn-discard btn-changes-discard"
                      onClick={() => this.disccardChanges()}
                    >
                      Discard
                    </button>

                    <button
                      // disabled={isDataSaving}
                      type="button"
                      className="save-btn btn btn-primary mr-0"
                       onClick={this.handleSave}
                      // onClick={() =>this.handleSave}
                    >
                      Save {this.state.showSaveloader && <Spinner animation="border" size="sm" />} 
                    </button>
                  </div>
                </div> 
            }
            
          </div>
        )}
      </>
    );
  }
}