import React from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { resolve, reject } from "q";
import { toast } from "react-toastify";
import { IUser } from "../Common/Contracts/IUser";
import { User } from "../Common/Services/User";
import { GetUser } from "../ForgotPassword/GetUser";
import { ResetMethod } from "../ForgotPassword/ResetMethod";
import { EnterCode } from "../ForgotPassword/EnterCode";
import { ChangePassword } from "../ForgotPassword/ChangePassword";
import { ResetPasswordComplete } from "../ForgotPassword/ResetPasswordComplete";
import {
  RoleSubPermissionSetting,
  IPageSubPermissionRole,
} from "../Common/Services/RoleSubPermissionSetting";
import { Utils } from "../Common/Utilis";

export class ManagerLoginModal extends React.Component<any, any> {
  private timerID: any | undefined;
  constructor(props: any) {
    super(props);
    this.state = {
      isManager: false,
      buttonSpinner: false,     
      resetpassTrigger: 0,
      closeTrigger: 0,
      errorMessage: "",
      errorMessageUserName: "",
      userName: "",
      password: "",
      mode: -1,
      passCode: "",
      resetMode: "",
    };
    this.cancelResetPassword = this.cancelResetPassword.bind(this);
  }

  componentDidMount() {
    this.timerID = setInterval(this.trigger, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  sleep(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  trigger = () => {
    this.setState((prevState: any) => ({
      closeTrigger: prevState.closeTrigger + 1,
      resetpassTrigger: prevState.resetpassTrigger + 1,
    }));
    // close on 5 minutes of inactivity 5*60=300
    if (
      this.state.mode === 0 ||
      this.state.mode === 1 ||
      this.state.mode === 4
    ) {
      if (this.state.resetpassTrigger >= 300) {
        this.setState(
          {
            mode: -1,
            resetpassTrigger: 0,
          },
          () => {
            this.managerLoginClose();
          }
        );
      }
    }
    if (this.state.mode === 2 || this.state.mode === 3) {
      if (this.state.resetpassTrigger >= 300) {
        this.setState(
          {
            mode: -1,
            resetpassTrigger: 0,
          },
          () => {
            this.managerLoginClose();
          }
        );
      }
    }
    if (this.state.mode === -1 && this.state.closeTrigger >= 300) {
      this.managerLoginClose();
    }
  };

  managerLoginClose = () => {
    this.props.managerLoginClose();
  };

  handleSubmit = (e: any) => {
    debugger;
    e.preventDefault();
    if (this.state.userName === "") {
      this.setState({ errorMessageUserName: "Username is required" });
      return false;
    }
    if (this.state.password === "") {
      this.setState({ errorMessage: "Password is required" });
      return false;
    }
    this.setState({ buttonSpinner: true });
    let cookieHID = JSON.parse(localStorage.getItem("WebClockDetail")!)
      .defaultHid;
    User.login(this.state.userName, this.state.password, cookieHID)
      .then(async (result: IUser | null) => {
        if (result !== null) {
          if (result.message.toLowerCase() === "success") {
            let requestObject = {} as IPageSubPermissionRole;
            requestObject.permissionID = 10035;
            requestObject.Module = "LaborManagement";
            requestObject.Type = "page";
            requestObject.RoleID = parseInt(result.rolId);
            requestObject.TenantID = result.tenantID;
            let isPermission = false;
            RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(
              requestObject
            )
              .then(async (resultPermission: any | null) => {
                if (resultPermission != null && resultPermission.length > 0) {
                  for (
                    let index = 0;
                    index < resultPermission.length;
                    index++
                  ) {
                    if (resultPermission[index].permissionID === 10048) {
                      isPermission = true;
                    }
                  }
                }
                if (isPermission) {
                  if (result.user_UniqueID === 0) {
                    let errorMessage =
                      "Please enter the valid username/password.";
                    this.setState({
                      errorMessage: errorMessage,
                      userName: "",
                      password: "",
                    });
                  } else {
                    if (result.message === "success") {
                      let roleObj: any = {};
                      roleObj.RoleID = result.rolId;
                      roleObj.TenantID = result.tenantID;
                      localStorage.setItem("ML", JSON.stringify(roleObj));
                      localStorage.setItem("token", result.mergeToken);
                      User.ValidateUserStatus(
                        result.userName,
                        result.tenantID,
                        "managerLoginModal"
                      ).then(async (userRes: any | null) => {
                        let apiResult = userRes.result;

                        if (apiResult.rVal === "0") {
                          let userObject: any = {};
                          // userObject.userName = this.props.parentState.userPucnModalData.userName;
                          userObject.userName = result.userName;
                          userObject.password = this.state.password;
                          userObject.email = apiResult.email;
                          userObject.phone = apiResult.phone;
                          userObject.primaryContact = apiResult.primaryContact;
                          userObject.rVal = "0";
                          userObject.isPhoneBlock = apiResult.isPhoneBlock;
                          userObject.isEmailBlock = apiResult.isEmailBlock;
                          userObject.displayEmail = apiResult.displayEmail;
                          userObject.displayPhone = apiResult.displayPhone;
                          userObject.isSplPermission = apiResult.isSplPermission;
                          userObject.phoneStatus = apiResult.phoneStatus;
                          userObject.modalOpen = "managerLoginModal";
                          userObject.user_UniqueID =result.user_UniqueID;
                          this.props.userValidated(userObject);
                        } else {
                          this.props.managerLoginSucsess(result.userName, result.user_UniqueID);
                        }
                      });
                    } else {
                      let errorMessage = "Please click on Reset Password link.";
                      this.setState({
                        errorMessage: errorMessage,
                        userName: "",
                        password: "",
                      });
                    }
                  }
                } else {
                  Utils.toastError("Unauthorized access.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "timeClockIndex",
                  });
                }
                this.setState({ buttonSpinner: false });
                resolve();
              })
              .catch((err: any) => {
                this.setState({ buttonSpinner: false });
                Utils.toastError(`Server Error, ${err}`, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "timeClockIndex",
                });
                reject();
              });
          } else if (result.message.toLowerCase() === "invalid hid access") {
            Utils.toastError("Unauthorized access.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "timeClockIndex",
            });
          } else if (result.message.toLowerCase() === "wrong password") {
            Utils.toastError("Wrong Password.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "timeClockIndex",
            });
          }
        }else{
         
          Utils.toastError("Please enter the valid username/password.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "timeClockIndex",
          });
        
        }
        this.setState({ buttonSpinner: false });
        resolve();
      })
      .catch((err) => {
        this.setState({ buttonSpinner: false });
        Utils.toastError(`Server Error, ${err}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "timeClockIndex",
        });
        reject();
      });
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
    }
    if (this.state.errorMessageUserName !== "") {
      this.setState({ errorMessageUserName: "" });
    }
    
  };

  changeModule(module: any) {
    this.setState({
      mode: module.mode,
      userName: module.userName,
      passCode: module.passCode,
      resetMode: module.resetMode,
      resetpassTrigger: 0
    });
  }

  resetPassword = () => {
    this.setState({
      mode: 0,
    });
  };

  cancelResetPassword = () => {
    this.setState({
      mode: -1,
    });
  };

  render() {
    var moduleShow: any;
    if (this.state.mode === 0) {
      moduleShow = (
        <GetUser
          changeModule={this.changeModule.bind(this)}
          page="timeclock"
          platform="timeclockmanager"
          cancelResetPassword={() => this.cancelResetPassword()}
        />
      );
    } else if (this.state.mode === 1) {
      moduleShow = (
        <ResetMethod
          changeModule={[this.changeModule.bind(this), this.state.userName]}
          page="timeclock"
          platform="timeclockmanager"
          cancelResetPassword={() => this.cancelResetPassword()}
        />
      );
    } else if (this.state.mode === 2) {
      moduleShow = (
        <EnterCode
          changeModule={[this.changeModule.bind(this), this.state]}
          page="timeclock"
          platform="managertimeclock"
          cancelResetPassword={() => this.cancelResetPassword()}
        />
      );
    } else if (this.state.mode === 3) {
      moduleShow = (
        <ChangePassword
          changeModule={[this.changeModule.bind(this), this.state]}
          errorMessage=""
          page="timeclock"
          cancelResetPassword={() => this.cancelResetPassword()}
        />
      );
    } else if (this.state.mode === 4) {
      moduleShow = (
        <ResetPasswordComplete
          changeModule={[this.changeModule.bind(this), this.state]}
          page="timeclock"
          cancelResetPassword={() => this.cancelResetPassword()}
        />
      );
    }
    return (
      <div>
        <Modal
          className="manager-login-time-clock-modal webtime-clock-modal"
          show={this.props.parentState.managerLoginModal}
          onHide={() => this.managerLoginClose()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          {this.state.mode === -1 && (
            <Modal.Header closeButton>
              <Modal.Title>Sign In as Manager</Modal.Title>
            </Modal.Header>
          )}
          <Modal.Body>
            {moduleShow}
            {this.state.mode === -1 && (
              <div className="body-sec">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    name="userName"
                    className={
                      this.state.errorMessageUserName ? "alert-danger" : "valid"
                    }
                    value={this.state.userName}
                    placeholder="Enter username"
                    onChange={(e) => this.handleChange(e)}
                    autoComplete="off"
                  />
                  {this.state.errorMessageUserName !== "" && (
                    <span className="error-message">
                      {this.state.errorMessageUserName}
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    className={
                      this.state.errorMessage ? "alert-danger" : "valid"
                    }
                    value={this.state.password}
                    placeholder="Enter Password"
                    onChange={(e) => this.handleChange(e)}
                    autoComplete="off"
                  />
                  {this.state.errorMessage !== "" && (
                    <span className="error-message">
                      {this.state.errorMessage}
                    </span>
                  )}
                </Form.Group>
                <div className="login-button">
                  <Button
                    variant="primary"
                    className={
                      this.state.buttonSpinner
                        ? "lg-btn action p-none"
                        : "lg-btn action"
                    }
                    type="submit"
                    onClick={(e: any) => this.handleSubmit(e)}
                  >
                    {this.state.buttonSpinner && (
                      <Spinner
                        className="mr-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    Login
                  </Button>
                </div>
              </div>
            )}
          </Modal.Body>
          {this.state.mode === -1 && (
            <Modal.Footer>
              <Button
                className="reset-password-btn btn-ghost mr-auto"
                onClick={() => this.resetPassword()}
              >
                Reset Password
              </Button>
              <Button
                className="cancel-btn btn-ghost"
                onClick={() => this.managerLoginClose()}
              >
                Cancel
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    );
  }
}
