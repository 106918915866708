import * as React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { Hotel as hotel } from "../../Common/Services/Hotel";


export class SingleSelectSearch extends React.Component<any, any> {
  private inputElement: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      hotelNameModal: "Select EHID",
      filterData: [],
      hotelList: [],
      inputValue: "",
      
    };
    this.handleChangeEhid = this.handleChangeEhid.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);

  }
  componentDidMount() {

    hotel
      .getUserHotelAccess("Labor Management")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          let hotelid = JSON.parse(localStorage.getItem("hotelid")!);
          let hList: any[] = [];
          let hFilterList: any[] = [];
          hList = result;
          hFilterList = hList.filter(el => el.hotelID !== Number(hotelid));
         
          this.setState({ hotelList: hFilterList });

          this.setState({
            filterData: hFilterList,
          });

        }
      })
      .catch((error) => {

      });
  }
  handleChangeEhid(e: { target: { value: string; }; }) {

    const updatedList = this.state.hotelList.filter(item => {
      return (
        item.hotelName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  handleDropdownChange(e: any) {

    this.props.isCopyBudgetId(e.hotelID);
    this.setState({ inputValue: "" });    
  }

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.hotelList }); 


  };




  render() {



    const hidSelectModal = (eventKey: any) => {      
      this.setState({ inputValue: "" });
      this.setState({ hotelNameModal: eventKey });
    };

    const onFocusInput = (eventKey: any) => {
      setTimeout(() => {
        this.inputElement.current.focus();
      }, 100)
    };



    return (
      <Dropdown className="single-select" onSelect={hidSelectModal} onClick={onFocusInput}  >
        <Dropdown.Toggle id="Modal-hid">
          {this.state.hotelNameModal ===
            "Select EHID" && (
              <span className="placeholder">
                {this.state.hotelNameModal}
              </span>
            )}
          {this.state.hotelNameModal !==
            "Select EHID" && (
              <OverlayTrigger
                placement="bottom-start"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={"hoteldata" + this.state.hotelNameModal}>
                    {this.state.hotelNameModal}
                  </Tooltip>
                }
              >
                <span style={{ width: " 95%", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block" }} >{this.state.hotelNameModal}</span>
              </OverlayTrigger>
            )}

        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="search-sec" >
            <input
              type="text"
              className="form-control"
              onChange={this.handleChangeEhid}
              placeholder="Filter EHIDs"
              value={this.state.inputValue}
              ref={this.inputElement}
            />
          </div>
          <div className="list-area">
            {this.state.filterData.map((item: any, idx: any) => (

              <OverlayTrigger
                placement="bottom-start"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={"hoteldata" + idx}>
                    {item.hotelName}
                  </Tooltip>
                }
                key={idx}
              >

                <Dropdown.Item
                  eventKey={item.hotelName}
                  onClick={() =>
                    this.handleDropdownChange(item)
                  }
                 
                  >


                  {item.hotelName}

                </Dropdown.Item>
              </OverlayTrigger>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
