import Instense from "./Axios-config";
import { IChartDto, IChartRequestDto } from "../Contracts/IChart";

export class Chart {
  public static getChartDetails = async (
    chartType: string
  ): Promise<IChartDto[] | null> => {
    // Need to put this and all future URLs into a config js file    
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID as any;   
    
    let request = {} as IChartRequestDto;
    request.tenantID = tenantID;
    request.chartType = chartType;
    const url = `/Chart/ChartDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as IChartDto[];
      return result;
    });
  };
}
