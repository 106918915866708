import Instense from "./Axios-config";
// import ReduxStore from "../../Redux/Store/IndexStore";

export class Journal {
  public static GetJournalList = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    const url = "/Journal/Journallist";
    return Instense.get(url, { params: request }).then((response) => {
      let result:any = response.data.result;
      return result;
    });
  };

  public static SaveJournalEntry = async (
    request: any,
    files: any[]
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    if (files.length === 0) {
      const url = `/Journal/SaveJournalEntry`;
      return Instense.post(url, request).then((response) => {
        let result:any = response.data.result;
        return result;
      });
    } else {
      const url = `/Journal/SaveJournalEntryWithFile`;
      const formData: FormData = new FormData();
      files.forEach((element) => {
        formData.append("file", element);
      });
      formData.append("formField", JSON.stringify(request));
      return Instense.post(url, formData).then((response) => {
        let result:any = response.data.result;
        return result;
      });
    }
  };

  public static GetPreviousTransactionList = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    const url = "/Journal/GetPreviousTransactionList";
    return Instense.get(url, { params: request }).then((response) => {
      let result:any = response.data.result;
      return result;
    });
  };

  public static GetJournalByUniQueNo = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    const url = "/Journal/GetJournalByUniQueNo";
    return Instense.get(url, { params: request }).then((response) => {
      let result:any = response.data.result;
      return result;
    });
  };
  public static JournalRepeatlistByUniQueNo = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    const url = "/Repeating/JournalRepeatlistByUniQueNo";
    return Instense.get(url, { params: request }).then((response) => {
      let result:any = response.data.result;
      return result;
    });
  };

  public static GetJournalEntryFiles = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    const url = "/Journal/GetJournalEntryFiles";
    return Instense.get(url, { params: request }).then((response) => {
      let result:any = response.data.result;
      return result;
    });
  };

  public static DeleteUploadedFile = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.tenantID = tenantID;
    const url = "/Journal/DeleteUploadedFile";
    return Instense.post(url, request).then((response) => {
      let result:any = response.data.result;
      return result;
    });
  };

  public static CheckForDelete = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    const url = "/Journal/CheckForDelete";
    return Instense.get(url, { params: request }).then((response) => {
      let result:any = response.data.result;
      return result;
    });
  };

  public static DeleteJournalEntry = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    const url = "/Journal/DeleteJournalEntry";
    return Instense.post(url, request).then((response) => {
      let result:any = response.data.result;
      return result;
    });
  };

  public static ExportJournal = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    const url = "/Journal/ExportJournal";
    const fileName = `Journal-${JSON.parse(localStorage.storage).currentUtcTime.replace(' PM','').replace(' AM','').replace(' ','-').replace(':','-')}`;
    return Instense.post(url, request,  { responseType: "blob" }).then(
           
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };
  public static JournalExcelImport = async (
    files: any[]
  ): Promise<any | null> => {
   const url = `/Journal/JournalExcelImport`;
      let request = {} as any;
      let storage = JSON.parse(localStorage.getItem("storage")!);
      let tenantID:any = storage === null ? 0 : (storage.tenantID);
      request.TenantID = tenantID;
      const formData: FormData = new FormData();
      files.forEach((element) => {
        formData.append("file", element);
      });
      formData.append("TenantID", JSON.stringify(request));
      return Instense.post(url, formData).then((response) => {
        let result:any = response.data;
        return result;
      });
  };
  // public static SaveJournalImport = async (
  //   request: any
  // ): Promise<any[] | null> => {
  //   const url = `/Journal/SaveJournalImport`;
  //   return Instense.post(url, request).then((response) => {
  //     let result:any = response.data.result;
  //     return result;
  //   });
  // };
  public static SaveJournalImport = async (
    request: any
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID:any = storage === null ? 0 : (storage.tenantID);
    request.TenantID = tenantID;
    let files: any = [];
    request.forEach((items) => { // 
      if (items.attachmentMain.length > 0) {
        items.attachmentMain.forEach((item, index) => {
          files.push(item);
        })
      }
    });
    if (files.length === 0) {
      request.filter((items) => delete items["attachmentMain"]);
      const url = `/Journal/SaveJournalImport`;
      return Instense.post(url, request).then((response) => {
        let result:any = response.data.result;
        return result;
      });
    } else {
      const url = `/Journal/SaveJournalImportWithFile`;
      const formData: FormData = new FormData();
      request.forEach((items) => { // 
        if (items.attachmentMain.length > 0) {
          items.attachmentMain.forEach((item, index) => {
            formData.append(items.id, item);
          })
        }
      });
      
      formData.append("formField", JSON.stringify(request));
      request.filter((items) => delete items["attachmentMain"]);
      
      return Instense.post(url, formData).then((response) => {
        let result:any = response.data.result;
        return result;
      });
    }
  };

}
