import React, { Component } from 'react'
import { Button, Form } from 'react-bootstrap';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { InvoiceApprovalS } from "../../Common/Services/InvoiceApprovals";
import { toast } from "react-toastify";
import { resolve, reject } from "q";
import { Utils } from '../Utilis';

export class UnApproveModal extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            isloder: false,
            UnApprovalArray: [],
            modalUniqueNos: props.modalUniqueNos,
        };
    }

    componentDidMount() {
        this.handleInvoiceRuleforUnApprove();
    }

    handleInvoiceRuleforUnApprove = () => {
        let request: any = {};
        request.InvoiceNo = this.state.modalUniqueNos
        InvoiceApprovalS.GetInvoiceRuleforUnApprove(request)
            .then(async (result: any) => {
                if (result.success) {
                    let mainResult = result?.result;
                    if (mainResult === null || mainResult === "") {
                        this.props.modalClose(true);
                        Utils.toastError("This invoice is already paid and changes are no longer allowed in this session. Please reopen the invoice and make any necessary changes.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (mainResult === "InvoiceUnapproved") {
                        this.props.modalClose(true);
                        Utils.toastError("This invoice is already unapproved by some another user.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else if (mainResult === "APClosed") {
                        this.props.modalClose(true);
                        Utils.toastError("Invoice cannot be unapproved as Accounting period has been closed for this invoice.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else {
                        let { UnApprovalArray } = this.state;
                        let pList1: any = [];
                        pList1.push(mainResult !== null ? mainResult : "");
                        pList1.forEach(item => {
                            if (item !== "") {
                                let splitComma = item.split("INN-FLOW-INN-FLOW-INN-FLOW-INN-FLOW-INN-FLOW-INN-FLOW");
                                splitComma.forEach(item => {
                                    let row: any = {};
                                    row.key = "primary";
                                    row.id = item.split("^")[0];
                                    row.isCheck = false;
                                    row.ruleName = item.split("^")[1] !== undefined ? item.split("^")[1] : "";
                                    UnApprovalArray.push(row);
                                })
                            }
                        });
                        this.setState({ UnApprovalArray }, () => {
                            if (UnApprovalArray.length === 1) {
                                this.submitUnApproveInvoice(UnApprovalArray[0].id);
                            }
                        });
                    }

                } else {
                    this.setState({ isloder: false })
                    Utils.toastError("Something went wrong.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isloder: false })
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    hideUnApprovalModal = () => {
        this.unCheckAll();
        this.props.modalClose(false);
    }

    unCheckAll = () => {
        let { UnApprovalArray } = this.state;
        UnApprovalArray = UnApprovalArray.map((items) => {
            return { ...items, isCheck: false };
        });
        this.setState(({ UnApprovalArray }))
    }
    approvalcheckChange = (event, id) => {
        let { UnApprovalArray } = this.state;
        let checked = event.target.checked;
        UnApprovalArray = UnApprovalArray.map((items) => {
            if (items.id === id) {
                return { ...items, isCheck: checked };
            } else {
                return items;
            }
        });
        this.setState(({ UnApprovalArray }))
    }

    UnApproveInvoice = () => {
        let roleIds: any = "";
        this.state.UnApprovalArray.forEach((items) => {
            if (items.isCheck) {
                roleIds += items.id + ",";
            }
        });
        roleIds = roleIds.replace(/,(?=\s*$)/, '');
        this.setState({ isloder: true }, () => {
            this.submitUnApproveInvoice(roleIds)
        })
    }

    submitUnApproveInvoice = (roleIds) => {
        let request: any = {};
        request.InvoiceUniqueNo = this.state.modalUniqueNos;
        request.RuleIds = roleIds;
        InvoiceApprovalS.UnApproveInvoice(request)
            .then(async (result: any) => {
                if (result.success) {
                    if (result.result.result === "ValidationInvoice") {
                        Utils.toastError("The current invoice is no longer present in the system as someone else has taken another action.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isloder: false }, () => {
                            this.props.modalClose(true);
                        })
                    } else if (result.result.result === "ValidationPaid") {
                        Utils.toastError("This invoice is already paid and changes are no longer allowed in this session. Please reopen the invoice and make any necessary changes.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isloder: false }, () => {
                            this.props.modalClose(true);
                        })
                    } else if(result.result.result === "APClosed"){
                        Utils.toastError("Invoice cannot be unapproved as Accounting period has been closed for this invoice.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isloder: false }, () => {
                            this.props.modalClose(true);
                        })
                    } else if (result.result?.message.toString().toLocaleLowerCase() === "closed") {
                        Utils.toastError("Invoice cannot be unapproved as Accounting period has been closed for this invoice.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isloder: false }, () => {
                            this.props.modalClose(false);
                        })
                    } else {
                        toast.success(`Invoice unapproved successfully.`, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isloder: false }, () => {
                            this.props.modalClose(true);
                        })
                    }
                } else {
                    if (result.result === "Validation") {
                        Utils.toastError("The current invoice is no longer present in the system as someone else has taken another action.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.setState({ isloder: false }, () => {
                            this.props.modalClose(true);
                        })
                    }
                    else {
                        this.setState({ isloder: false })
                        Utils.toastError("Something went wrong.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isloder: false })
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    render() {
        const isDisableApproveBtn = this.state.UnApprovalArray.some(o => o.isCheck) ? false : true;
        let { isloder } = this.state;
        const checkruleName = (item) => {
            return (
                <EllipsisWithTooltip placement='bottom'>{item.ruleName}</EllipsisWithTooltip>
            )
        }
        return (
            <>
                {this.state.UnApprovalArray.length > 1 && (
                    <div className="confirmModal wom_confirmModal forPaymentTab">
                        <div className="title">Which of these would you like to un-approve?</div>
                        <div className="description wom_description">
                            <div className="wom_ApprovalsList">
                                <ul>
                                    {this.state.UnApprovalArray.map((item: any, index: any) => {
                                        let id = "idal_" + index;
                                        return (
                                            <li key={index}>
                                                <Form.Check
                                                    className={item.key === "primary" ? "wom_formCheck isPrimary" : "wom_formCheck"}
                                                    type="checkbox"
                                                    label={checkruleName(item)}
                                                    style={{ "userSelect": "none" }}
                                                    id={id}
                                                    checked={item.isCheck}
                                                    onClick={(e: any) => this.approvalcheckChange(e, item.id)}
                                                />
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="action-link">
                            <Button className="btn btnCancel" onClick={this.hideUnApprovalModal}>Cancel</Button>
                            <Button
                                variant="primary"
                                className="btn btnApprove"
                                disabled={isDisableApproveBtn || isloder}
                                onClick={(e: any) => this.UnApproveInvoice()}>
                                Un-approve
                            </Button>
                        </div>
                    </div>
                )}
            </>
        )
    }
}
