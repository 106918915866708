import React from "react";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Utils } from "../../../Common/Utilis";
import moment from "moment";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast, ToastContainer } from "react-toastify";
import Joi from "joi-browser";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { Hotel } from "../../../Common/Services/Hotel";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { IRoom, ISaveRoom } from "../../../Common/Contracts/IRoom";


export class AddRoom extends React.Component<any, any> {
  private roomTypeFormSchema = Joi.object({
    roomName: Joi.string()
    .required()
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Enter Room Name" };
      });
    }),
    roomTypeId: Joi.number().required().error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Room Type" };
        });
      }),

    roomType: Joi.string().required().error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Room Type" };
        });
      }),

      // category: Joi.string().allow('').optional(),
      // size: Joi.number().allow(0).max(9999).allow('').error((errors) => {
      //   return errors.map((error) => {
      //     return { message: "Size should not be more than 4 digits" };
      //   });
      // }),
      //   seatingCapacity: Joi.number().allow(0).max(9999).allow('').error((errors) => {
      //     return errors.map((error) => {
      //       return { message: "Seating Capacity should not be more than 4 digits" };
      //     });
      //   }),
     
    // accountingDate: Joi.string().optional().allow(''),
    roomNotes: Joi.string().max(500)
    .error((errors) => {
      return errors.map((error) => {
        return { message: "Maximun 500 characters are allowed" };
      });
    })
    .optional().allow('')
  });  

  state: any = {
    roomTypeFormData: {
        roomName: this.props?.roomRowEdit?.roomName || '',
        roomTypeId: this.props?.roomRowEdit?.roomTypeId || '',
        roomType: this.props?.roomRowEdit?.roomType || '',
        // category: this.props?.roomRowEdit?.category || '',
        // size:this.props?.roomRowEdit?.size || 0,
        // seatingCapacity:this.props?.roomRowEdit?.seatingCapacity || 0,
    
      // accountingDate: this.props?.roomRowEdit?.accountingDate ? moment(this.props?.roomRowEdit?.accountingDate).format("MM/DD/YYYY") : '' || '',
      roomNotes: this.props?.roomRowEdit?.roomNotes || ''
    },
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0,
    dateList: [{label: "11/11/21", value: "11/11/21"}, {label: "11/11/21", value: "11/11/21"}],
        isEditButton: false,
  };

  componentDidMount = () => {
    this.getHotels();
  };
  getHotels = () => {
    this.setState({ hotelList: this.props?.userHotels });
  };

  hideModal = (isReload = false) => {
    const    roomTypeFormData = {
      roomName: this.props?.roomRowEdit?.roomName || '',
      roomTypeId: this.props?.roomRowEdit?.roomTypeId || '',
      roomType: this.props?.roomRowEdit?.roomType || '',
      // category: this.props?.roomRowEdit?.category || '',
      // size:this.props?.roomRowEdit?.size || 0,
      // seatingCapacity:this.props?.roomRowEdit?.seatingCapacity || 0,
      roomNotes: this.props?.roomRowEdit?.roomNotes || ''
    };
    this.props?.handleHideRoomModal(isReload);

    this.setState({ roomTypeFormData, isFormNotValid: true,isDataSaving:false });
  };

  onFieldChange = (event, inputPosition) => {
    const { roomTypeFormData } = this.state;
    let value = event.target.value;
    const fieldName = event.target.name;
    if(fieldName === "roomName" || fieldName === "roomNotes"){
      value = value?.toString()?.trimLeft();
    }
   else if(fieldName === "size" || fieldName === "seatingCapacity"){
      value = value?.toString()?.trim()?.replace(/[^0-9\+]+/gi, "");
      value=value.slice(0,4)
    }
    
  
    
    roomTypeFormData[fieldName] = value;
    this.setState({roomTypeFormData},()=> {
      this.validationOnClick(inputPosition);
    });
  };


  saveRoomType = () => {
    const request: IRoom = {...this.state?.roomTypeFormData } as IRoom;
    request.hid =this.props?.hotelId;
    request.roomID = this.props?.roomRowEdit?.roomID || 0;
    request.accountingDate = this.props?.date;
    request.category = request.roomTypeId == -1 ? "Conference" : request.roomTypeId == -2 ? "Other":"Customer";
    request.roomStatus = this.props?.roomRowEdit?.roomStatus ||  "Online"
    this.setState({isDataSaving:true})


    if(this.props.changeRoomType)
    {
      // Call Change Room type API
      this.props?.updateRoomList(request,()=>{
        this.hideModal(true);
      });
     
    }
    else{
    
      const validateRequest:ISaveRoom={
        RoomID: request.roomID,
        RoomName: request.roomName,
        Notes: request.roomNotes,
        RoomStatus: request.roomStatus,
        HID: request.hid,
        TenantID: request.tenantID,
        AccountingDate: request.accountingDate ? request.accountingDate :  moment().format("MM/DD/YYYY"),
        RoomTypeId: request.roomTypeId,
      }
       //Check if Room Name Already Exist
    EntityManagementService.ValidateRoom(validateRequest)
    .then((result: any) => {
      
      if(result?.message === "Fail")
      {
        this.setState({isDataSaving:false})
        Utils.toastError(result?.result, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      }
      else{
        this.props?.updateRoomList(request);
        this.hideModal(true);
      }
     
    }).catch((error)=>{
      
      this.props?.updateRoomList(request);
      this.hideModal(true);
    })

      // Call Add / Edit API  with Name Validation
      // this.props?.updateRoomList(request);
      // this.hideModal(true);
    }

    //Check if Room Name Already Exist
    // EntityManagementService.ValidateRoomType([request],null,request.hid)
    // .then((result: any) => {
      
    //   if(result?.result)
    //   {
    //     this.setState({isDataSaving:false})
    //     Utils.toastError(result?.result, {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       containerId: "addGarnishment",
    //     });
    //   }
    //   else{
    //     this.props?.updateRoomList(request);
    //     this.hideModal(true);
    //   }
     
    // }).catch((error)=>{
      
    //   this.props?.updateRoomList(request);
    //   this.hideModal(true);
    // })

   
  
  };
  validateRoomConfigurationFormSchema = () => {
    const valid = Joi.validate(
      this.state.roomTypeFormData,
      this.roomTypeFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if (this.props?.changeRoomType) {
      if (newErrorObject?.roomName) {
        delete newErrorObject.roomName;
      }
    }

    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };
  calenderHide = () => {
    this.setState({ calenderShow: false });
  };

  calenderShow = () => {
    this.setState({ calenderShow: true });
  };

  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () =>
      this.validateRoomConfigurationFormSchema()
    );
  };

  render() {
    const {
      roomTypeFormData: {
        roomName,
        roomTypeId,
        roomType,
        category,
        // size,
        // seatingCapacity,
        roomNotes
      },
      errors,
      isDataSaving,
      isFormNotValid,
      inputPosition,
     
    } = this.state;

    console.log({RoomTypesList:this.props.RoomTypesList})
    const { showAddRoomModal } = this.props;
    return (
      <>
        <div className="">
          <ToastContainer containerId={"addDeduction"} autoClose={3000} />

          <Modal
            className="add-room-type-modal"
            show={showAddRoomModal}
            onHide={this.hideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
           // keyboard={this.props?.showDeductionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props.changeRoomType ? "Change Room Type" :  this.props?.roomRowEdit?.roomID > 0
                  ? "Edit Room"
                  : "Add Room"}
             
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-section">
                <div className="body-section">
               {!this.props.changeRoomType && <Form.Group 
                      onBlur={() => this.validationOnClick(1)}
                      controlId="name" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.roomName && inputPosition >= 1
                          ? "validation-error"
                          : ""
                      }`}
                      // onClick={() => this.validationOnClick(1)}
                    >
                      <Form.Label>Room Name</Form.Label>
                      <OverlayTrigger
                        placement="bottom"
                        defaultShow={false}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"roomName"} className={(roomName.length <= 47) ? "tootltip-hide": ""}>
                                {roomName}
                            </Tooltip> 
                        }
                        >
                      <Form.Control
                        type="text"
                        className="form-control"
                        // tabIndex={1}
                        maxLength={100}
                        id="txtroomName"
                        name="roomName"
                        autoComplete="roomName"
                        value={roomName}
                        onChange={(e) => this.onFieldChange(e, 1)}
                        onBlur={()=>{this.setState({roomTypeFormData:{...this.state.roomTypeFormData,roomName:this.state.roomTypeFormData.roomName.trimRight()}})}}
                      />
                        </OverlayTrigger>
                      {this.getFieldError("roomName", 1)}
                    </Col>
                  </Form.Group>}

                  <Form.Group onBlur={() => this.validationOnClick(2)} controlId="roomTypeId" className="d-flex">
                    <Col  sm={"12"}
                      className={`mb-0 ${
                        errors?.roomTypeId && inputPosition >= 2
                          ? "validation-error"
                          : ""
                      }`}>
                    <Form.Label>Room Type</Form.Label>

                      <DropDownList
                        //   tabIndex={2}
                          placeHolder={"Select Room Type"}
                          data={this.props.RoomTypesList}
                          defaultValue={roomTypeId}
                          isSearchRequired={true}
                          label={"description"}
                          value={"value"}
                          onDropDownChange={(item) => {
                            if (!item) {
                              return;
                            }
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("roomTypeId", item.value),
                              2
                            );
                            this.onFieldChange(
                              Utils.BuildCustomEventObject("roomType", item.description),
                              2
                            );
                          }}
                          selectedItem={[
                            ...[{ value: "", description: "Select Room Type" }],
                            ...this.props.RoomTypesList,
                          ].find((r: any) => r?.value === roomTypeId)}
                        />
                     
                      {this.getFieldError("roomTypeId", 2)}
                    </Col>
                  </Form.Group>

                  {/* {!this.props?.roomRowEdit?.roomID &&  ( roomTypeId == -1 || roomTypeId == -2 )  && <Form.Group 
                      onBlur={() => this.validationOnClick(3)}
                  
                  controlId="size" className="d-flex">
                    <Col
                      sm={ "12"}
                      className={`mb-0 ${
                        errors?.size && inputPosition >= 3
                          ? "validation-error"
                          : ""
                      }`}
                    >
                      <Form.Label>Size</Form.Label>
                      <input
                        // maxLength={4}
                        // tabIndex={3}
                        type="text"
                        min="0"
                        onKeyDown={Utils.AllowOnlyPositiveNumnberInput
                          }
                        className="form-control"
                        id="txtSize"
                        name="size"
                        autoComplete="size"
                        value={size}
                        onChange={(e) => this.onFieldChange(e, 3)}
                      />
                      {this.getFieldError("size", 3)}
                    </Col>
                  </Form.Group>}
                 {!this.props?.roomRowEdit?.roomID && roomTypeId == -1 && <Form.Group
                      onBlur={() => this.validationOnClick(4)}
                      controlId="seatingCapacity" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.seatingCapacity && inputPosition >= 4
                          ? "validation-error"
                          : ""
                      }`}
                    >
                      <Form.Label>Seating Capacity</Form.Label>
                      <input
                        // maxLength={4}
                        // tabIndex={4}
                        type="text"
                        // min="0"
                        onKeyDown={Utils.AllowOnlyPositiveNumnberInputWithoutDot}
                        className="form-control"
                        id="txtSeatingCapacity"
                        name="seatingCapacity"
                        autoComplete="seatingCapacity"
                        value={seatingCapacity}
                        onChange={(e) => this.onFieldChange(e, 4)}
                      />
                      {this.getFieldError("seatingCapacity", 4)}
                    </Col>
                  </Form.Group>} */}
             
                  <Form.Group  onBlur={() => this.validationOnClick(5)} controlId="roomNotes" className="d-flex">
                    <Col
                      sm={"12"}
                      className={`mb-0 ${
                        errors?.roomNotes && inputPosition >= 5
                          ? "validation-error"
                          : ""
                      }`}
                      // onClick={() => this.validationOnClick(4)}
                    >
                      <Form.Label>Notes</Form.Label>
                      <Form.Control as="textarea"
                      
                        className="form-control"
                        id="txtnotes"
                        rows={1}
                        // tabIndex={3}
                        name="roomNotes"
                        autoComplete="roomNotes"
                        value={roomNotes}
                        maxLength={500}
                        onChange={(e) => this.onFieldChange(e, 5)}
                        onBlur={()=>{this.setState({roomTypeFormData:{...this.state.roomTypeFormData,roomNotes:this.state.roomTypeFormData.roomNotes.trimRight()}})}}

                      />
                      {this.getFieldError("roomNotes", 5)}
                    </Col>
                  </Form.Group>
                 
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
           {!this.props.changeRoomType && <Button
            //   tabIndex={8}
                className="btn-ghost"
                onClick={() => this.hideModal(false)}
              >
                Cancel
              </Button> }
              <Button
            //    tabIndex={9}
                disabled={isFormNotValid || isDataSaving}
                className="btn btn-primary"
                onClick={this.saveRoomType}
              >
             Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
