import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { LaborTimeSheet } from "../../../Common/Services/LaborTimeSheet";
import { Dropdown, Tabs, Tab, Form, Row, Col, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { resolve, reject } from "q";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import $ from 'jquery';
import { Utils } from '../../../Common/Utilis';

export class LaborTimesheetResolvedDispute extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            hotelID: this.props.hotelID,
            userName: this.props.userName,
            date: this.props.date,
            correctedIn: "",
            correctedOut: "",
            disputedDetails: [],
            allpositionlist: [],
            pageloader: true
        }
        this.handleSave = this.handleSave.bind(this);
        this.handleResolveDispute = this.handleResolveDispute.bind(this);
        this.departmentDropdownChange = this.departmentDropdownChange.bind(this);
        this.positionDropdownChange = this.positionDropdownChange.bind(this);
        this.reSetDepartment = this.reSetDepartment.bind(this);
        this.refreshResolveNotes = this.refreshResolveNotes.bind(this);
    }

    formatDate(time: string) {
        const dataArray = time.split(":");
        let hh = Number(dataArray[0]);
        let m = Number(dataArray[1]);
        let dd = "AM";
        let h = hh;
        if (h >= 12) {
            h = hh - 12;
            dd = "PM";
        }
        if (h === 0) {
            h = 12;
        }
        let min = m < 10 ? "0" + m : m;
        let replacement = h + ":" + min;
        replacement += " " + dd;
        return replacement;
    }

    componentDidMount() {
        this.loadDisputeDetails(this.props.hotelID, this.props.date, this.props.userName);
    }

    reSetDepartment(index: any) {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.disputedDetails)));
        data[index].correctedPunche[0].newDepartmentId = 0;
        data[index].correctedPunche[0].newDepartment = "Select";
        data[index].correctedPunche[0].newPositionId = 0; data[index].correctedPunche[0].newPosition = "Select";
        this.setState({ disputedDetails: data, allpositionlist: [] });
    }

    departmentDropdownChange(event: any, item: any, index: any) {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.disputedDetails)));
        data[index].correctedPunche[0].newDepartmentId = item.departmentID;
        data[index].correctedPunche[0].newDepartment = item.departmentName;
        data[index].correctedPunche[0].newPositionId = 0;
        data[index].correctedPunche[0].newPosition = "Select";
        this.setState({ disputedDetails: data, allpositionlist: item.laborPositionlist });
        $('#ddlcorrectedpunchdept' + index).click();
    }

    positionDropdownChange(event: any, item: any, index: any) {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.disputedDetails)));
        data[index].correctedPunche[0].newPositionId = item.positionID;
        data[index].correctedPunche[0].newPosition = item.positionName;
        this.setState({ disputedDetails: [] });
        this.setState({ disputedDetails: data });
        $('#ddlcorrectedpunchposition' + index).click();
    }

    loadDisputeDetails(hotelID: any, selectedDate: any, userName: any) {
        this.setState({ pageloader: true })
        LaborTimeSheet.LaborDisputePunchDetails(hotelID, selectedDate, userName, "TimeSheet").then(async (result: any | null) => {
            if (result.disputedDetails != null) {
                this.setState({ disputedDetails: [] });
                let data = Object.assign([], JSON.parse(JSON.stringify(result.disputedDetails)));
                data = data.filter(item => item.status.toLowerCase() !== "open");
                // eslint-disable-next-line array-callback-return
                data.map((item) => {
                    item.correctedPunche[0].newDepartmentId = 0;
                    item.correctedPunche[0].newDepartment = "Select";
                    item.correctedPunche[0].newPositionId = 0;
                    item.correctedPunche[0].newPosition = "Select";
                });
                this.setState({ disputedDetails: data }, () => {
                    this.setState({ pageloader: false })
                })
            }
        })
    }

    setCorrectedIn = (correctedIn, index) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.disputedDetails)));
        data[index].correctedPunche[0].correctedTempIn = correctedIn;
        this.setState({ disputedDetails: data });
    }

    setCorrectedOut = (correctedOut, index) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.disputedDetails)));
        data[index].correctedPunche[0].correctedTempOut = correctedOut;
        this.setState({ disputedDetails: data });
    }

    intimeChange = (e: any, row: any, index: any) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.disputedDetails)));
        data[index].correctedPunche[0].correctedTempIn = e.target.value;
        this.setState({ disputedDetails: data });
    };

    outtimeChange = (e: any, row: any, index: any) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.disputedDetails)));
        data[index].correctedPunche[0].correctedTempOut = e.target.value;
        this.setState({ disputedDetails: data });
    };

    handleSave = (e: any, row: any, index: any, punchtype: string) => {
        if (punchtype === "Position") {
            if (row.newDepartmentId === 0) {
                Utils.toastError("Please select department.", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false
            }
            if (row.newPositionId === 0) {
                Utils.toastError("Please select position.", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false
            }
        }
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.actualID = this.state.disputedDetails[index].actualId;
        if (punchtype === "In") {
            newItem.correctedIn = this.formatDate(row.correctedTempIn);
            newItem.correctedOut = this.formatDate(row.correctedOutOrg);
        } else if (punchtype === "Out") {
            newItem.correctedOut = this.formatDate(row.correctedTempOut);
            newItem.correctedIn = this.formatDate(row.correctedInOrg);
        } else {
            newItem.correctedIn = this.formatDate(row.correctedInOrg);
            newItem.correctedOut = this.formatDate(row.correctedOutOrg);
        }
        newItem.disputeID = this.state.disputedDetails[index].disputeID;
        newItem.correctedPosition = row.newPositionId;
        newItem.roleType = "Manager";
        newItem.entryType = punchtype;
        this.updateDisputDetails(newItem, index);
    }

    updateDisputDetails(updateDispute: any, index: any) {
        this.setState({ loaderTrue: true })
        LaborTimeSheet.UpdateLaborDisputePunch(updateDispute).then(async (result: any | null) => {
            if (result != null) {
                if (result.result.messageCode === "Success") {
                    if (updateDispute.entryType === "In") {
                        $('#ddlcorrectedpunchIn' + index).click();
                    }
                    if (updateDispute.entryType === "Out") {
                        $('#ddlcorrectedpunchOut' + index).click();
                    }
                    if (updateDispute.entryType === "Position") {
                        $('#ddlcorrectedpunchPosition' + index).click();
                    }

                    toast.success("Corrected punch successfully edited.", {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.loadDisputeDetails(this.state.hotelID, this.state.date, this.state.userName);
                }
                else {
                    Utils.toastError(result.result.message, {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            }
            this.setState({ loaderTrue: false })
            resolve();
        })
            .catch((error: any) => {
                this.setState({ loaderTrue: false })
                reject();
            });
    }

    refreshResolveNotes = (index: any) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.disputedDetails)));
        data[index].resolutionNote = "";
        this.setState({ disputedDetails: data });
    }

    notesChange = (e: any, index: any) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.disputedDetails)));
        data[index].resolutionNote = e.target.value;
        this.setState({ disputedDetails: data });
    }

    handleResolveDispute(index: any, status: any) {

        if (this.state.disputedDetails[index].resolutionNote.trim().length === 0) {
            Utils.toastError("Please enter resolution notes.", {
                // // position: toast.POSITION.BOTTOM_RIGHT,
            });
            return false
        }

        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let newItem: any = {};
        newItem.tenantID = tenantID;

        newItem.employeeName = this.state.userName;
        newItem.actualID = this.state.disputedDetails[index].actualId;
        newItem.correctedIn = this.formatDate(this.state.disputedDetails[index].correctedPunche[0].correctedInOrg);
        newItem.correctedOut = this.formatDate(this.state.disputedDetails[index].correctedPunche[0].correctedOutOrg);
        newItem.correctedPosition = this.state.disputedDetails[index].correctedPunche[0].correctedPositionIDOrg;
        newItem.roleType = "Manager";
        newItem.entryType = this.state.disputedDetails[index].disputType;
        newItem.comments = this.state.disputedDetails[index].resolutionNote;
        newItem.status = status;
        newItem.hotelID = this.state.hotelID;
        newItem.disputeID = this.state.disputedDetails[index].disputeID;
        this.saveDisputDetails(newItem);
    }

    saveDisputDetails(saveDispute: any) {
        this.setState({ loaderTrue: true })
        LaborTimeSheet.LaborResolveRejectAdjustment(saveDispute).then(async (result: any | null) => {
            if (result != null) {
                if (result.result.messageCode === "Success") {
                    if (saveDispute.status.toLowerCase() === "resolve") {
                        toast.success("Dispute successfully resolved.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else {
                        toast.success("Dispute successfully rejected.", {
                            // // position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    this.loadDisputeDetails(this.state.hotelID, this.state.date, this.state.userName);
                    this.props.refreshTimesheetDetails();
                }
                else {
                    Utils.toastError(result.result.message, {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }

            }
            this.setState({ loaderTrue: false })
            resolve();
        })
            .catch((error: any) => {
                this.setState({ loaderTrue: false })
                reject();
            });
    }

    keyPress(e: any, index: any) {
        if (e.keyCode === 13) {
            if (this.state.disputedDetails[index].comment.trim().length > 0) {
                this.postComment(index);
            }
        }
    }

    blurComment(e: any, index: any) {
        if (this.state.disputedDetails[index].comment.trim().length > 0) {
            this.postComment(index);
        }
    }
    postComment(index: any) {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
        let newItem: any = {};
        newItem.tenantID = tenantID;
        newItem.disputeID = this.state.disputedDetails[index].disputeID;
        newItem.comments = this.state.disputedDetails[index].comment;
        newItem.roleType = "Manager";
        newItem.status = this.state.disputedDetails[index].disputType;

        this.setState({ loaderTrue: true })
        LaborTimeSheet.CommentHistory(newItem).then(async (result: any | null) => {
            if (result != null) {
                if (result.result.messageCode === "Success") {
                    toast.success("Comment successfully added.", {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.loadDisputeDetails(this.state.hotelID, this.state.date, this.state.userName);
                }
                else {
                    Utils.toastError(result.result.message, {
                        // // position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            }
            this.setState({ loaderTrue: false })
            resolve();
        })
            .catch((error: any) => {
                this.setState({ loaderTrue: false })
                reject();
            });
    }

    commentChange = (e: any, index: any) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.disputedDetails)));
        data[index].comment = e.target.value;
        this.setState({ disputedDetails: data });
    }

    render() {

        const disputedColumns = (index: any) => [
            {
                dataField: "disputedIn",
                text: "In",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "in" && (
                            <div className="badge color-orange statics">{this.formatDate(row.disputedIn)}</div>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "in" && (
                            <div className="rm-bold">{this.formatDate(row.disputedIn)}</div>
                        )}
                    </>
                )
            },
            {
                dataField: "disputedOut",
                text: "Out",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "out" && (
                            <div className="badge color-orange statics">{this.formatDate(row.disputedOut)}</div>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "out" && (
                            <div className="rm-bold">{this.formatDate(row.disputedOut)}</div>
                        )}
                    </>
                )
            },
            {
                dataField: "disputedPosition",
                text: "Position",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "position" && (
                            <div className="badge color-orange statics width-150">
                                <EllipsisWithTooltip placement="bottom">
                                    {row.disputedPosition}
                                </EllipsisWithTooltip>
                            </div>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "position" && (
                            <div>
                                <EllipsisWithTooltip placement="bottom">
                                    {row.disputedPosition}
                                </EllipsisWithTooltip>
                            </div>
                        )}

                    </>
                )
            },
            {
                dataField: "",
                text: "",
            }
        ]
        const correctedColumns = (index: any) => [
            {
                dataField: "correctedIn",
                text: "In",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "in" && (
                            <Dropdown className="more-action grey" alignRight>
                                {this.state.disputedDetails[index].status.toLowerCase() === "open" && (
                                    <Dropdown.Toggle className="btn-outline-primary inner-sec-btn more" id={"ddlcorrectedpunchIn" + rowIndex} >
                                        <div onClick={() => this.setCorrectedIn(row.correctedInOrg, index)} className="d-flex align-items-center badge color-green">
                                            {this.formatDate(row.correctedInOrg)}
                                        </div>
                                    </Dropdown.Toggle>
                                )}

                                {this.state.disputedDetails[index].status.toLowerCase() !== "open" && (
                                    <div onClick={() => this.setCorrectedIn(row.correctedInOrg, index)} className="badge color-green statics">
                                        {this.formatDate(row.correctedInOrg)}
                                    </div>
                                )}
                                <Dropdown.Menu>
                                    <div className="add-entry">
                                        <div className="re-assign-modal">
                                            <div className="title height-50 d-flex align-items-center">
                                                <div className="mr-auto">Edit Corrected Punch</div>
                                                <Dropdown.Item className="cross-btn" href="">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z" fill="#84888C" />
                                                    </svg>
                                                </Dropdown.Item>
                                            </div>
                                            <div className="entry-body">
                                                <Form.Group as={Row} controlId="time-picker">
                                                    <Col sm="12">
                                                        <Form.Label>Punch In</Form.Label>
                                                        <input type="time" id={"inTimedupappt" + index} name="inTimedupappt" className="html-time-picker"
                                                            value={row.correctedTempIn} onChange={(e: any) => this.intimeChange(e, row, index)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </div>
                                            <div className="action-link">
                                                <button type="button" id={"btnsavein" + index} className="btn btn-primary" onClick={(e: any) => (this.handleSave(e, row, index, "In"))}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "in" && (
                            <div className="rm-bold">{this.formatDate(row.correctedInOrg)}</div>
                        )}
                    </>
                )
            },
            {
                dataField: "correctedOut",
                text: "Out",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "out" && (

                            <Dropdown className="more-action grey" alignRight>
                                {this.state.disputedDetails[index].status.toLowerCase() === "open" && (
                                    <Dropdown.Toggle className="btn-outline-primary inner-sec-btn more" id={"ddlcorrectedpunchOut" + rowIndex} >
                                        <div onClick={() => this.setCorrectedOut(row.correctedOutOrg, index)} className="d-flex align-items-center badge color-green">
                                            {this.formatDate(row.correctedOutOrg)}
                                        </div>
                                    </Dropdown.Toggle>
                                )}
                                {this.state.disputedDetails[index].status.toLowerCase() !== "open" && (
                                    <div onClick={() => this.setCorrectedOut(row.correctedOutOrg, index)} className="badge color-green statics">
                                        {this.formatDate(row.correctedOutOrg)}
                                    </div>
                                )}
                                <Dropdown.Menu>
                                    <div className="add-entry">
                                        <div className="re-assign-modal">
                                            <div className="title height-50 d-flex align-items-center">
                                                <div className="mr-auto">Edit Corrected Punch</div>
                                                <Dropdown.Item className="cross-btn" href="">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z" fill="#84888C" />
                                                    </svg>
                                                </Dropdown.Item>
                                            </div>
                                            <div className="entry-body">
                                                <Form.Group as={Row} controlId="time-picker">
                                                    <Col sm="12">
                                                        <Form.Label>Punch Out</Form.Label>
                                                        <input type="time" id={"outTimedupappt" + index} name="outTimedupappt" className="html-time-picker"
                                                            value={row.correctedTempOut} onChange={(e: any) => this.outtimeChange(e, row, index)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </div>
                                            <div className="action-link">
                                                <button type="button" id={"btnsaveout" + index} className="btn btn-primary" onClick={(e: any) => (this.handleSave(e, row, index, "Out"))}>Save</button>
                                            </div>
                                        </div>
                                    </div>

                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "out" && (
                            <div className="rm-bold">{this.formatDate(row.correctedOutOrg)}</div>
                        )}

                    </>
                )
            },
            {
                dataField: "correctedPosition",
                text: "Position",
                formatter: (cell: any, row: any, rowIndex: any) => (
                    <>
                        {this.state.disputedDetails[index].disputType.toLowerCase() === "position" && (
                            <Dropdown className="more-action grey" alignRight>
                                {this.state.disputedDetails[index].status.toLowerCase() === "open" && (
                                    <Dropdown.Toggle className="btn-outline-primary inner-sec-btn more" id={"ddlcorrectedpunchPosition" + rowIndex} >
                                        <div className="d-flex align-items-center badge width-150 color-green" onClick={() => this.reSetDepartment(index)} >
                                            <EllipsisWithTooltip placement="bottom">
                                                {row.correctedPositionOrg}
                                            </EllipsisWithTooltip>
                                        </div>
                                    </Dropdown.Toggle>
                                )}
                                {this.state.disputedDetails[index].status.toLowerCase() !== "open" && (
                                    <div className="badge color-green statics width-150">
                                        <EllipsisWithTooltip placement="bottom">
                                            {row.correctedPositionOrg}
                                        </EllipsisWithTooltip>
                                    </div>
                                )}
                                <Dropdown.Menu>
                                    <div className="add-entry">
                                        <div className="re-assign-modal">
                                            <div className="title height-50 d-flex align-items-center">
                                                <div className="mr-auto">Edit Disputed Position</div>
                                                <Dropdown.Item className="cross-btn" href="">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z" fill="#84888C" />
                                                    </svg>
                                                </Dropdown.Item>
                                            </div>
                                            <div className="entry-body">
                                                <Form.Group as={Row}>
                                                    <Col sm="12">
                                                        <Form.Label>Department</Form.Label>
                                                        <Dropdown>
                                                            <Dropdown.Toggle id={"ddlcorrectedpunchdept" + index}>
                                                                <EllipsisWithTooltip placement="bottom">
                                                                    {row.newDepartment}
                                                                </EllipsisWithTooltip>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <ul>
                                                                    {this.props.alldepartmentlist.map(
                                                                        (item: any, idx: any) => (
                                                                            <li
                                                                                key={item.departmentName}
                                                                                onClick={(event: any) => this.departmentDropdownChange(event, item, index)}
                                                                            >
                                                                                {item.departmentName}
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <Col sm="12">
                                                        <Form.Label>Position</Form.Label>
                                                        <Dropdown>
                                                            <Dropdown.Toggle id={"ddlcorrectedpunchposition" + index}>
                                                                <EllipsisWithTooltip placement="bottom">
                                                                    {row.newPosition}
                                                                </EllipsisWithTooltip>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <ul>
                                                                    {this.state.allpositionlist.map(
                                                                        (item: any, idx: any) => (
                                                                            <li
                                                                                key={item.positionName}
                                                                                onClick={(event: any) => this.positionDropdownChange(event, item, index)}
                                                                            >
                                                                                {item.positionName}
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Col>
                                                </Form.Group>
                                            </div>
                                            <div className="action-link">
                                                <button type="button" id={"btnPositionSave" + index} className="btn btn-primary" onClick={(e: any) => (this.handleSave(e, row, index, "Position"))}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        {this.state.disputedDetails[index].disputType.toLowerCase() !== "position" && (
                            <div className="rm-bold">
                                <EllipsisWithTooltip placement="bottom">
                                    {row.correctedPositionOrg}
                                </EllipsisWithTooltip>
                            </div>
                        )}
                    </>
                )
            },
            {
                dataField: "",
                text: "",
            }
        ]

        return (
            <>
                {/* <ToastContainer autoClose={3000} /> */}
               
                    <>
                        {(this.state.pageloader &&
                            <div className="loader-responsive dispute-details">
                                <div className="ttl">Resolved Disputes</div>
                                <div className="detail-view">
                                    <div className="header-sec d-flex align-items-center">
                                        <div className="tick-mark loadingAnimation"></div>
                                        <div className="forgot-ttl mr-auto">
                                            <div className="main-ttl loadingAnimation"></div>
                                            <div className="sub-ttl d-flex loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="d-flex dispute-detail">
                                        <div className="list">
                                            <div className="sec-ttl loadingAnimation"></div>
                                            <div className="day-list">
                                                <div className="ttl loadingAnimation"></div>
                                                <div className="timesheetSlideout common-table">
                                                    <div className="table-header loadingAnimation"></div>
                                                    <div className="table-body">
                                                        <div className="d-flex align-items-center row-table">
                                                            <div className="flex-fill loadingAnimation"></div>
                                                            <div className="flex-fill loadingAnimation"></div>
                                                            <div className="flex-fill loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list">
                                            <div className="sec-ttl loadingAnimation"></div>
                                            <div className="day-list">
                                                <div className="ttl loadingAnimation"></div>
                                                <div className="timesheetSlideout common-table">
                                                    <div className="table-header loadingAnimation"></div>
                                                    <div className="table-body">
                                                        <div className="d-flex align-items-center row-table">
                                                            <div className="flex-fill loadingAnimation"></div>
                                                            <div className="flex-fill loadingAnimation"></div>
                                                            <div className="flex-fill loadingAnimation"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="view-shift loadingAnimation"></div>
                                    <div className="comment-sec">
                                        <Tabs defaultActiveKey="Comments" id="uncontrolled-tab-example">
                                            <Tab eventKey="Comments" title="Comments">
                                                <ul>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="profile loadingAnimation"></div>
                                                            <div className="details">
                                                                <div className="userName loadingAnimation"></div>
                                                                <div className="comment-text loadingAnimation "></div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="profile loadingAnimation"></div>
                                                            <div className="details">
                                                                <div className="userName loadingAnimation"></div>
                                                                <div className="comment-text loadingAnimation "></div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Tab>
                                            <Tab eventKey="History" title="History">
                                                <ul>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="profile loadingAnimation"></div>
                                                            <div className="details">
                                                                <div className="userName loadingAnimation"></div>
                                                                <div className="comment-text loadingAnimation "></div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="profile loadingAnimation"></div>
                                                            <div className="details">
                                                                <div className="userName loadingAnimation"></div>
                                                                <div className="comment-text loadingAnimation "></div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(!this.state.pageloader &&
                         this.state.disputedDetails.length > 0 && (
                            <div>
                                <div className="ttl">Resolved Disputes</div>
                                {this.state.disputedDetails.map((item: any, index: any) => (
                                    <div className="detail-view" key={item.disputeID}>
                                        <div className="header-sec d-flex align-items-center">
                                            {item.status.toLowerCase() === "resolve" && (
                                                <div className="tick-mark">
                                                    <svg width="43" height="44" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="21.5" cy="22" r="21.5" fill="#DEFFF8" />
                                                        <path d="M18.2505 26.0347L14.5458 22.3301L12.9551 23.9208L18.2505 29.2162L29.1708 18.2958L27.5801 16.7051L18.2505 26.0347Z" fill="#2CAF92" />
                                                    </svg>
                                                </div>
                                            )}
                                            {item.status.toLowerCase() === "reject" && (
                                                <div className="tick-mark">
                                                    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="21.5" cy="21.5" r="21.5" fill="#FFEFED" />
                                                        <rect x="14.9287" y="27.3848" width="18" height="2" transform="rotate(-45 14.9287 27.3848)" fill="#B12A18" />
                                                        <rect x="16.3428" y="14.6562" width="18" height="2" transform="rotate(45 16.3428 14.6562)" fill="#B12A18" />
                                                    </svg>
                                                </div>
                                            )}
                                            <div className="forgot-ttl mr-auto">
                                                <div className="main-ttl">{item.reason}</div>
                                                <div className="sub-ttl d-flex">
                                                    <div>Punch Dispute</div>
                                                    <div className="separator">.</div>
                                                    <div>Submitted {Utils.getLocalDateUtc(item.submitted1)}</div>
                                                </div>
                                            </div>
                                            {item.status.toLowerCase() === "open" && (
                                                <div className="action-group d-flex flex-row">
                                                    <Dropdown className="more-action add-entry resolve-dispute" alignRight>
                                                        <Dropdown.Toggle className="btn btn-primary btn-outline-primary rm-pad main-btn" id="dropdown-more">
                                                            <div onClick={() => this.refreshResolveNotes(index)} >
                                                                Reject
                                                        </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <div className="pos-name">
                                                                <Dropdown.Item className="cross-btn" href="">
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z" fill="#84888C" />
                                                                    </svg>
                                                                </Dropdown.Item>
                                                            </div>
                                                            <div className="re-assign-modal">
                                                                <div className="">
                                                                    <div className="title">Reject Dispute</div>
                                                                    <div className="entry-body">
                                                                        <Form.Group as={Row}>
                                                                            <Col sm="12">
                                                                                <Form.Label>Summary of Changes</Form.Label>
                                                                                <div className="summery-details">
                                                                                    <div className="date">{item.submitted.split(',')[0]}</div>
                                                                                    <ul>
                                                                                        {item.disputType.toLowerCase() === "in" && (
                                                                                            <li>Punch in will be changed from {this.formatDate(item.disputedPunche[0].disputedIn)} to {this.formatDate(item.correctedPunche[0].correctedIn)} </li>
                                                                                        )}
                                                                                        {item.disputType.toLowerCase() === "out" && (
                                                                                            <li>Punch out will be changed from {this.formatDate(item.disputedPunche[0].disputedOut)} to {this.formatDate(item.correctedPunche[0].correctedOut)} </li>
                                                                                        )}
                                                                                        {item.disputType.toLowerCase() === "position" && (
                                                                                            <li>Position will be changed from {item.disputedPunche[0].disputedPosition} to {item.correctedPunche[0].correctedPosition} </li>
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </Col>
                                                                        </Form.Group>
                                                                        <Form.Group as={Row}>
                                                                            <Col sm="12">
                                                                                <Form.Label>Reject Notes</Form.Label>
                                                                                <Form.Control as="textarea" rows={2} placeholder="Explanation required..."
                                                                                    value={item.resolutionNote} onChange={(e: any) => this.notesChange(e, index)} />
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="action-link">
                                                                        <Button className="btn btn-primary" onClick={(e: any) => (this.handleResolveDispute(index, "Reject"))}>Submit</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <Dropdown className="more-action add-entry resolve-dispute" alignRight>
                                                        <Dropdown.Toggle className="btn btn-primary rm-pad main-btn" id="dropdown-more">
                                                            <div onClick={() => this.refreshResolveNotes(index)} >
                                                                Resolve Dispute
                                                        </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <div className="pos-name">
                                                                <Dropdown.Item className="cross-btn" href="">
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M13.4933 5.28662L9.95747 8.82162L6.42247 5.28662L5.24414 6.46495L8.77914 9.99995L5.24414 13.535L6.42247 14.7133L9.95747 11.1783L13.4933 14.7133L14.6716 13.535L11.1366 9.99995L14.6716 6.46495L13.4933 5.28662Z" fill="#84888C" />
                                                                    </svg>
                                                                </Dropdown.Item>
                                                            </div>
                                                            <div className="re-assign-modal">
                                                                <div className="">
                                                                    <div className="title">Resolve Dispute</div>
                                                                    <div className="entry-body">
                                                                        <Form.Group as={Row}>
                                                                            <Col sm="12">
                                                                                <Form.Label>Summary of Changes</Form.Label>
                                                                                <div className="summery-details">
                                                                                    <div className="date">{item.submitted.split(',')[0]}</div>
                                                                                    <ul>
                                                                                        {item.disputType.toLowerCase() === "in" && (
                                                                                            <li>Punch in will be changed from {this.formatDate(item.disputedPunche[0].disputedIn)} to {this.formatDate(item.correctedPunche[0].correctedIn)} </li>
                                                                                        )}
                                                                                        {item.disputType.toLowerCase() === "out" && (
                                                                                            <li>Punch out will be changed from {this.formatDate(item.disputedPunche[0].disputedOut)} to {this.formatDate(item.correctedPunche[0].correctedOut)} </li>
                                                                                        )}
                                                                                        {item.disputType.toLowerCase() === "position" && (
                                                                                            <li>Position will be changed from {item.disputedPunche[0].disputedPosition} to {item.correctedPunche[0].correctedPosition} </li>
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </Col>
                                                                        </Form.Group>
                                                                        <Form.Group as={Row}>
                                                                            <Col sm="12">
                                                                                <Form.Label>Resolution Notes</Form.Label>
                                                                                <Form.Control as="textarea" rows={2} placeholder="Explanation required..."
                                                                                    value={item.resolutionNote} onChange={(e: any) => this.notesChange(e, index)} />
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="action-link">
                                                                        <Button className="btn btn-primary" onClick={(e: any) => (this.handleResolveDispute(index, "Resolve"))}>Submit</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex dispute-detail">
                                            <div>
                                                <div className="sec-ttl">Disputed Punch</div>
                                                <div className="day-list">
                                                    <div className="ttl">{Utils.getLocalDateFromUtc(item.disputedPunche[0].disputedDate1)}</div>
                                                    <div className="dropdown-static">
                                                        <BootstrapTable
                                                            id={"disputed"}
                                                            keyField="1"
                                                            data={item.disputedPunche}
                                                            columns={disputedColumns(index)}
                                                            hover
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="sec-ttl">Corrected Punch</div>
                                                <div className="day-list">
                                                    <div className="ttl"> {Utils.getLocalDateFromUtc(item.correctedPunche[0].correctedDate1)}</div>
                                                    <div className="dropdown-static">
                                                        <BootstrapTable
                                                            id={"corrected"}
                                                            keyField="2"
                                                            data={item.correctedPunche}
                                                            columns={correctedColumns(index)}
                                                            hover
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="view-shift">
                                            <button type="button" onClick={() => this.props.handleComparisonClick(-1, "All")} className="btn green-text-btn-blue">View Shift </button>
                                        </div>
                                        <div className="comment-sec">
                                            <Tabs defaultActiveKey="Comments" id="uncontrolled-tab-example">
                                                <Tab eventKey="Comments" title="Comments">
                                                    <ul>
                                                        {item.disputeComment.length > 0 && (
                                                            <>
                                                                {item.disputeComment.map((item: any) => (
                                                                    <li>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="profile">
                                                                                <img src={item.imagePath} width="40" height="40" alt={item.userName} />
                                                                            </div>
                                                                            <div className="details">
                                                                                <div className="userName">{item.userName}<span> {Utils.getLocalDateTimeFromUtc(item.commentDate1)}</span></div>
                                                                                <div className="comment-text">{item.comment}</div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                                )}
                                                            </>
                                                        )}
                                                        {item.status.toLowerCase() === "open" && (
                                                            <li>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="profile">
                                                                        <img src={item.logInUserImage} width="40" height="40" alt={item.logInUser} />
                                                                    </div>
                                                                    <div className="details">
                                                                        <Form.Control type="text" id="newcomment" placeholder="Write a comment..."
                                                                            value={item.comment}
                                                                            onChange={(e: any) => this.commentChange(e, index)}
                                                                            onKeyDown={(e: any) => (this.keyPress(e, index))}
                                                                            onBlur={(e: any) => (this.blurComment(e, index))} />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </Tab>
                                                <Tab eventKey="History" title="History">
                                                    <ul>
                                                        {item.disputeComment.length > 0 && (
                                                            <>
                                                                <li>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="profile">
                                                                            <img src={item.disputeComment[0].imagePath} width="40" height="40" alt={item.disputeComment[0].userName} />
                                                                        </div>
                                                                        <div className="details">
                                                                            <div className="userName">{item.disputeComment[0].userName + " created the dispute"}<span> {Utils.getLocalDateTimeFromUtc(item.disputeComment[0].commentDate1)}</span></div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                {item.disputeComment.map((item: any) => (
                                                                    <li>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="profile">
                                                                                <img src={item.imagePath} width="40" height="40" alt={item.userName} />
                                                                            </div>
                                                                            <div className="details">
                                                                                <div className="userName">{item.userName}<span> {Utils.getLocalDateTimeFromUtc(item.commentDate1)} </span></div>
                                                                                <div className="comment-text">{item.comment}</div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                                )}
                                                            </>
                                                        )}
                                                        {item.status.toLowerCase() !== "open" && (
                                                            <li>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="profile">
                                                                        <img src={item.resolvedUserImage} width="40" height="40" alt={item.resolvedUser} />
                                                                    </div>
                                                                    <div className="details">
                                                                        <div className="userName">{item.resolvedUser}<span> {Utils.getLocalDateTimeFromUtc(item.resolvedDate1)}</span></div>
                                                                        <div className="d-flex">
                                                                            <div className="badge">Open</div>
                                                                            <div className="arrow">
                                                                                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M9.16667 4L4.5 0V3.33333H0.5V4.66667H4.5V8L9.16667 4Z" fill="#6A6E73" />
                                                                                </svg>
                                                                            </div>
                                                                            <div className={item.status.toLowerCase() === "resolve" ? "badge color-green" : "badge color-orange"}>{item.status.toLowerCase() === "resolve" ? "Resolved" : "Rejected"}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                        {item.status.toLowerCase() !== "open" && (
                                                            <li>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="profile">
                                                                        <img src={item.resolvedUserImage} width="40" height="40" alt={item.resolvedUser} />
                                                                    </div>
                                                                    <div className="details">
                                                                        <div className="userName">{item.resolvedUser + " edited punch data "}<span> {Utils.getLocalDateTimeFromUtc(item.resolvedDate1)} </span></div>
                                                                        <div className="action-link">
                                                                            <a href="javascript:void(0);" onClick={() => this.props.handleComparisonClick(-1, "All")}>View Punch</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                        {item.status.toLowerCase() === "open" && (
                                                            <li>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="profile">
                                                                        <img src={item.logInUserImage} width="40" height="40" alt={item.logInUser} />
                                                                    </div>
                                                                    <div className="details">
                                                                        <Form.Control type="text" id="newcomment" placeholder="Write a comment..."
                                                                            value={item.comment}
                                                                            onChange={(e: any) => this.commentChange(e, index)}
                                                                            onKeyDown={(e: any) => (this.keyPress(e, index))}
                                                                            onBlur={(e: any) => (this.blurComment(e, index))} />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                )
                                )}
                            </div>
                            )
                        )}
                    </>
                
            </>
        );
    }
}