/* eslint-disable array-callback-return */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  // Link,
  Redirect,
} from "react-router-dom";
import { Container, Dropdown, Form, Row, Col, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import chevronRight from "../../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import profile from "../../../../Common/Assets/Images/profile1.png";
import notesIcon from "../../../../Common/Assets/Images/icons/notes-icon.png";

import DatePicker from "react-datepicker";
import OutsideClickHandler from "react-outside-click-handler";
import { resolve, reject } from "q";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { confirmAlert } from "react-confirm-alert";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ForecastLock from "../../../../Common/Assets/Images/icons/forecast-lock.svg";
import moment from "moment";
import { LaborSchedule } from "../../Common/Services/LaborSchedule";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { Utils } from "../../Common/Utilis";

const { SearchBar } = Search;
export class DeniedRequest extends React.Component<any, any> {
  

  constructor(props: any) {
    debugger;
    super(props);

    
    this.state = {
        deniedRequestData:[],
        tableLoader: false,
    };
  
  }

  componentDidMount() {   

    // let requestArray: any = [];
    // let request = {} as any;    
    // request.requestDate = "09/11/2023";
    // request.startDate = "09/11/2023";
    // request.endDate="09/11/2023";
    // request.hrs="8.0";  
    // request.ptoType="pto"; 
    //  request.reason="Denied Request Reason";  
    // requestArray.push(request);
    // this.setState({deniedRequestData:requestArray}); 
    this.GetLaborTimeoffSummaryDetails();
  }

  GetLaborTimeoffSummaryDetails(){   
    let request = {} as any;
    request.hid = 0;    
    
    request.Useruniqueno = JSON.parse(localStorage.storage).user_UniqueID; 
   // request.Useruniqueno = 39776;    
    request.type = "Declined";
    request.declinedDay = null;
    this.setState({ tableLoader: true });
    LaborSchedule.LaborTimeoffSummaryDetails(request)
      .then(async (result: any) => {
        debugger
      
        if (result.message === "Success") {
          let requestArray: any = [];
         
          for(let i=0; i < result.result.length;i++ ){            
                       
           let request = {} as any;    
           request.requestDate =  result.result[i].requestDate;
           request.startDate =  result.result[i].startdateTime;
           request.endDate= result.result[i].enddateTime;
           request.hrs= result.result[i].hrs;  
           request.ptoType= result.result[i].ptoName; 
            request.reason= result.result[i].reason;  
           requestArray.push(request);
        }
        this.setState({ deniedRequestData:requestArray, tableLoader: false });
         


        }else{
          this.setState({ deniedRequestData:[], tableLoader: false });
        }
      }).catch((err) => {
                Utils.toastError(`Server Error, ${err}`, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
               
                this.setState({ tableLoader: false })
                //reject();
              });



};

  render() {

    const DeniedRequestcolumns = [
      
        {
          dataField: "",
          text: "Request Date",
          editable: false,
          formatter: (cell: any, row: any, rowIndex: any) => (
           
              <span className="">
               <span>{row.entryType}  </span> 
               <span className="autoPunch-status badge">{row.requestDate}</span>
              </span>
          ),
        },
        {
            dataField: "",
            text: "Start Date",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.startDate}</span>
                </span>
            ),
          },
          {
            dataField: "",
            text: "End Date",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.endDate}</span>
                </span>
            ),
          },
          {
            dataField: "",
            text: "Hours",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
             
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.hrs}</span>
                </span>
            ),
          },
          {
            dataField: "",
            text: "Type",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
             
                <span className="">
                 <span>{row.entryType}  </span> 
                 <span className="autoPunch-status badge">{row.ptoType}</span>
                </span>
            ),
          },
          {
            dataField: "",
            text: "Reason For Denial",
            editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => (
             
                // <span className="">
                //  <span>{row.entryType}  </span> 
                //  <span className="autoPunch-status badge">{row.reason}</span>
                // </span>
                row.reason !== "" ? 
                <Dropdown className="more-action notes-btn"  alignRight>
                <Dropdown.Toggle
                  className="btn-outline-primary  more"
                  id="dropdown-more"
                >
                  <div className="d-flex">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.5556 2.4H11.3889V1H9.94444V2.4H7.05556V1H5.61111V2.4H3.44444C2.64783 2.4 2 3.0279 2 3.8V13.6C2 14.3721 2.64783 15 3.44444 15H13.5556C14.3522 15 15 14.3721 15 13.6V3.8C15 3.0279 14.3522 2.4 13.5556 2.4ZM3.44444 13.6V4.5H5.61111H7.05556H9.94444H11.3889H13.5556V3.8L13.557 13.6H3.44444Z" />
                      <path d="M4.6665 6H11.3332V7.33333H4.6665V6ZM4.6665 8.66667H7.99984V10H4.6665V8.66667Z" />
                    </svg>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="content">
                    <h5 className="comments">{row.reason}</h5>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              : <></>
            ),
          },
      
  
  
      ];
  return(<>
        {
            this.state.tableLoader ? <ReactPageLoader useas={"myPTOLoader"} /> : 
          
          <div className={this.state.deniedRequestData.length > 5 ? "add-scrollabr" : ""}>
              <BootstrapTable
              keyField="rowNo"
              data={this.state.deniedRequestData}
              columns={DeniedRequestcolumns}
              hover
            />
            {this.state.deniedRequestData.length === 0 && <div className="data-alert-caption">No record found</div>}
            
          </div>
        }
   </>
    );
  }
}

