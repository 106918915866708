import { Hub } from "./Hub";
import { HubNames } from "../Constants/HubNames";
import { HubEndPointNames } from "../Constants/HubEndPointNames";
import { Slide, toast } from "react-toastify";
import EventEmitter from "reactjs-eventemitter";
export class GeneralHub {
  static connect() {
    const hubConnection = Hub.connect(HubNames.GENERAL);
    return hubConnection;
  }

  static connectAndOperateHub = () => {
    const hubConnection = GeneralHub.connect();
    hubConnection
      .start()
      .then(() => {
        console.log("Connection started!");
        hubConnection.on(
          HubEndPointNames.GeneralChannelNotification,
          GeneralHub.recieveGeneralChannelNotificationData
        );
      })
      .catch((err) => console.log("Error while establishing connection :("));
  };

  private static recieveGeneralChannelNotificationData = (response) => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantId = storage === null ? 0 : (storage.tenantID as any);
    if (
      Number(response?.tenantId) === Number(tenantId) ||
      Number(response?.tenantId) <= 0
    ) {
      EventEmitter.emit(response.action, response);
    }
  };
}
