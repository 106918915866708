import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { LaborPerformance as laborPerformance } from "../../../../Common/Services/LaborPerfomance";
import { resolve, reject } from "q";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
export default class PerformanceScheduleDetails extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            hotelID: props.hotelID,
            positionID: props.positionID,
            date: props.date,
            userName: props.userName,
            scheduleDetails: [],
            pageloader:false
        };
    }

    componentDidMount() {
        this.getUserScheduleDetails(this.state.hotelID, this.state.positionID, this.state.date, this.state.userName);
    }

    resetChangeState() {
        this.setState({ scheduleDetails: [] });

        //this.getUserActualDetails(this.state.hotelID, this.state.positionID, this.state.date, this.state.userName);
    }

    getUserScheduleDetails(hotelID: Number, positionID: Number, date: any, userName: any) {
        this.setState({ pageloader: false })
        laborPerformance.laborPerformanceScheduleDetail(hotelID, positionID, date, userName).then(async (result: any[] | null) => {
            if (result != null) {
                
                this.setState({ scheduleDetails: result });
            }
            this.setState({ pageloader: true })
            resolve();
        })
            .catch((error: any) => {
                this.setState({ pageloader: false })
                reject();
            });
    }

    render() {
        const columns = [
            {
                dataField: "inTime",
                text: "In",
            },
            {
                dataField: "outTime",
                text: "Out",
            },
            {
                dataField: "breakTime",
                text: "Break",
            },
            {
                dataField: "duration",
                text: "Duration",
            },          
            {
                dataField: "",
                text: "",   
                formatter: (cell: any, row: any, rowIndex: any) => (
                   
                    <div className="d-flex flex-wrap">
                        {row.pto.length > 0 && (
                            <>
                                <div className="red-badges blue-bg">{row.pto}</div>
                            </>
                        )}
                        {row.uto.length > 0 && (
                            <>
                                <div className="red-badges blue-bg">{row.uto}</div>
                            </>
                        )}
                        {row.noShow.length > 0 && (
                            <>
                                <div className="red-badges">{row.noShow}</div>
                            </>
                        )}
                    </div>
                ),
            },
            {
                dataField: "",
                text: "",
            },
            {
                dataField: "",
                text: "",
            },

        ];
        return (
            <div className="">
                {(!this.state.pageloader &&
                <ReactPageLoader useas={"performanceSlideoutScheduleDetails"} />
                )}
                {(this.state.pageloader &&
                <div>
                    <div className="sub-sec-ttl">Scheduled</div>
                    <div className="actual-details schedule timesheet">
                        <BootstrapTable
                            keyField="scheduleNo"
                            data={this.state.scheduleDetails}
                            columns={columns}
                            hover
                        />
                    </div>
                   
                </div>
                  )}
            </div>
        );
    }
}
