import React from "react";
import { Dropdown, Button, Form, Table, Modal, Spinner } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "react-calendar/dist/Calendar.css";
import { resolve, reject } from "q";
import _ from "lodash";
import "../ConditionalRuleSetting/conditionalRule.scss";
import { Register as registersevice } from "../../../Common/Services/Register";
import $, { event } from "jquery";
import { Utils } from "../../../Common/Utilis";

export class ColumnMapingControl extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      AddlessAmt1: { value: "", isDisable: false, isMatch: false },
      AddlessAmt2: { value: "", isDisable: false, isMatch: false },
      Amount1: { value: "", isDisable: false, isMatch: false },
      Amount2: { value: "", isDisable: false, isMatch: false },
      Date: { value: "", isDisable: false, isMatch: false },
      Company: { value: "", isDisable: false, isMatch: false },
      Category: { value: "", isDisable: false, isMatch: false },
      Description1: { value: "", isDisable: false, isMatch: false },
      Description2: { value: "", isDisable: false, isMatch: false },
      Payment: { value: "", isDisable: false, isMatch: false },
      Deposit: { value: "", isDisable: false, isMatch: false },
      PaymentDeposit: { value: "", isDisable: false, isMatch: false },
      maxLength: 100,
      isDuplicateMessage: "Duplicate",
      isDuplicateColumn: false,
      isSaveColumnMapping: false,
      isAddNewRole: false,
      newRuleName: "",
      newRuleError: false,
      newRuleErrorSet: false,
      newRuleErrorName: "",
      selectedRule: { "ruleID": 0, "ruleName": "Select mapping rule" },
      defaultSelectedRule: { "ruleID": 0, "ruleName": "Select mapping rule" },
      mappingRuleArray: [],
      isSelectedRoleHasValue: false,
      columnMappingLoader: false,
      isAmountColumnExist: false,
      tabEnable: 0,
      tabDisable: -1,

    };
  }

  componentDidMount = () => {
    //this.GetMappingRule();
    this.setState({ mappingRuleArray: this.props.mappingRuleArray })
  };

  GetMappingRule = () => {
    let request: any = {};
    request.Type = "CashImportStatement";
    registersevice.GetMappingRule(request)
      .then(async (result: any) => {
        let data = result.result;
        data.map(item => (item["ruleName"] = item.ruleName.trim(), item["ruleNameNew"] = item.ruleName.toLowerCase().trim()))
        data = _.sortBy(data, "ruleNameNew");
        if (result.success) {
          this.setState({ mappingRuleArray: data })
        } else {
          Utils.toastError("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  updatechildList = (updatedList) => {
    this.setState({ mappingRuleArray: updatedList })
  }

  handleSelectRule = (eventKey: any) => {
    if (eventKey === "addNewRule") {
      this.setState({ isAddNewRole: true });
    } else {
      this.resetColumnMapping();
      this.setState({
        fieldMappedWithRule: [],
        isSelectedRoleHasValue: false,
        selectedRule: JSON.parse(eventKey),
        columnMappingLoader: true,
      }, () => {
        this.GetFieldMappedWithRule(this.state.selectedRule)
        this.props?.updateNewReqState(true);
      });
    }
  };


  GetFieldMappedWithRule = (data) => {
    let request: any = {};
    let { isAmountColumnExist, AddlessAmt1, AddlessAmt2, Amount1, Amount2, Date, Company, Category, Description1, Description2, Payment, Deposit, PaymentDeposit } = this.state;
    request.RuleID = data.ruleID;
    registersevice.GetFieldMappedWithRule(request)
      .then(async (result: any) => {
        let data = result.result;
        if (result.success) {
          if (data.length > 0) {
            if (data[0].amount1 !== null || data[0].amount2 !== null) { isAmountColumnExist = true; }
            else { isAmountColumnExist = false; }
            AddlessAmt1.value = data[0].amount1 !== null ? data[0]?.addlessAmt1 !== null ? data[0]?.addlessAmt1 : "" : "";
            AddlessAmt2.value = data[0].amount2 !== null ? data[0]?.addlessAmt2 !== null ? data[0]?.addlessAmt2 : "" : "";
            Amount1.value = data[0]?.amount1 !== null ? data[0]?.amount1 : "";
            Amount2.value = data[0]?.amount2 !== null ? data[0]?.amount2 : "";
            Category.value = data[0]?.category !== null ? data[0]?.category : "";
            Company.value = data[0]?.company !== null ? data[0]?.company : "";
            Date.value = data[0]?.date !== null ? data[0]?.date : "";
            Deposit.value = data[0]?.debit !== null && data[0]?.debit !== undefined ? data[0]?.debit : "";
            Description1.value = data[0]?.description1 !== null ? data[0]?.description1 : "";
            Description2.value = data[0]?.description2 !== null ? data[0]?.description2 : "";
            Payment.value = data[0]?.credit !== null && data[0]?.credit !== undefined ? data[0]?.credit : "";
            PaymentDeposit.value = data[0]?.payment_Deposit !== null ? data[0]?.payment_Deposit : "";
            this.setState({
              columnMappingLoader: false,
              isAmountColumnExist,
              isSelectedRoleHasValue: true,
              AddlessAmt1, AddlessAmt2, Amount1, Amount2, Date, Company, Category, Description1, Description2, Payment, Deposit, PaymentDeposit
            }, () => {
              this.setcolumnMapingVisibilty();
            });
          } else {
            this.setState({
              columnMappingLoader: false,
              isSelectedRoleHasValue: true
            })
          }
        } else {
          Utils.toastError(result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        resolve();
      })
      .catch((error) => {
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  addNewRoleClose = () => {
    this.setState({ isAddNewRole: false, newRuleName: "", newRuleError: false, newRuleErrorName: "" })
  };

  handleNewRuleName = (event) => {
    let { value } = event.target;
    this.setState({ newRuleName: value })
  }

  NewRuleNameValidation = () => {
    let { newRuleName, mappingRuleArray, newRuleError, newRuleErrorName } = this.state;
    if (newRuleName === "") {
      newRuleError = true;
      newRuleErrorName = "Please enter rule name.";
      Utils.toastError(newRuleErrorName, { position: toast.POSITION.BOTTOM_RIGHT });
      this.setState({ newRuleError, newRuleErrorName });
      return newRuleError;
    } else if (newRuleName.length > 50) {
      newRuleError = true;
      newRuleErrorName = "Maximum 50 characters allowed.";
      Utils.toastError(newRuleErrorName, { position: toast.POSITION.BOTTOM_RIGHT });
      this.setState({ newRuleError, newRuleErrorName });
      return newRuleError;
    } else {
      let hasVal = mappingRuleArray.filter(item => item?.ruleName.toString().toLocaleLowerCase() === newRuleName.toLocaleLowerCase())
      if (hasVal.length > 0) {
        newRuleErrorName = "Rule name already exists";
        newRuleError = true;
        Utils.toastError(newRuleErrorName, { position: toast.POSITION.BOTTOM_RIGHT });
        this.setState({ newRuleError, newRuleErrorName });
        return newRuleError;
      } else {
        newRuleError = false;
        this.setState({ newRuleError });
        return newRuleError;
      }
    }
  }

  addNewRoleName = () => {
    let { newRuleName, mappingRuleArray } = this.state;
    if (!this.NewRuleNameValidation()) {
      this.setState({ newRuleErrorSet: true })
      let request: any = {};
      request.RuleName = newRuleName;
      request.Type = "CashImportStatement";
      let newRule = {};
      registersevice.SaveCreditCardRuleName(request)
        .then(async (result: any) => {
          if (result.success) {
            toast.success("New rule added successfully", { position: toast.POSITION.BOTTOM_RIGHT });
            let data = result?.result;
            newRule = { "ruleID": data.id, "ruleName": data.result, ruleNameNew: data.result.toString().toLowerCase().trim() }
            mappingRuleArray.push(newRule);
            mappingRuleArray = _.sortBy(mappingRuleArray, "ruleNameNew");
            this.setState({ selectedRule: newRule, mappingRuleArray }, () => {
              setTimeout(() => {
                this.handleSelectRule(JSON.stringify(newRule));
                this.addNewRoleClose();
                this.setState({ newRuleErrorSet: false });
              }, 1000)
            })
          } else {
            this.setState({ newRuleErrorSet: false });
            Utils.toastError(result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ newRuleErrorSet: false })
          Utils.toastError(error?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reject();
        });
    }
  }

  resetColumnMapping = () => {
    this.setState({
      AddlessAmt1: { value: "", isDisable: false, isMatch: false },
      AddlessAmt2: { value: "", isDisable: false, isMatch: false },
      Amount1: { value: "", isDisable: false, isMatch: false },
      Amount2: { value: "", isDisable: false, isMatch: false },
      Date: { value: "", isDisable: false, isMatch: false },
      Company: { value: "", isDisable: false, isMatch: false },
      Category: { value: "", isDisable: false, isMatch: false },
      Description1: { value: "", isDisable: false, isMatch: false },
      Description2: { value: "", isDisable: false, isMatch: false },
      Payment: { value: "", isDisable: false, isMatch: false },
      Deposit: { value: "", isDisable: false, isMatch: false },
      PaymentDeposit: { value: "", isDisable: false, isMatch: false },
      isSaveColumnMapping: false, selectedRule: this.state.defaultSelectedRule, isSelectedRoleHasValue: false, isAmountColumnExist: false,
    }, () => {
      this.props.updateNewReqState(false)
    })
  }

  handleSelectControl = (event: any, type: any) => {
    if (type === "AddlessAmt1") {
      let { AddlessAmt1 } = this.state;
      AddlessAmt1.value = event;
      this.setState({ AddlessAmt1 });
    } else if (type === "AddlessAmt2") {
      let { AddlessAmt2 } = this.state;
      AddlessAmt2.value = event;
      this.setState({ AddlessAmt2 });
    }
  }

  isValidInput(value) {
    let val = value.split(" ").join("").toString();
    return val = Number(val.charAt(0)) > 0 ? val.substring(1) : val;
  }

  handleInputControl = (event: any, type: any) => {
    let value = event.target.value.length <= 1 ? event.target.value.replace(/[^a-zA-Z]/g, '') : event.target.value.replace(/[^a-zA-Z0-9]/g, '');
    let { AddlessAmt1, AddlessAmt2, Amount1, Amount2, Date, Company, Category, Description1, Description2, Payment, Deposit, PaymentDeposit } = this.state;
    if (type === "Amount1" || type === "Amount2") {
      if (type === "Amount1") {
        Amount1.value = this.isValidInput(value);
      } else if (type === "Amount2") {
        Amount2.value = this.isValidInput(value);
      }
    } else if (type === "Date") {
      Date.value = this.isValidInput(value);
    } else if (type === "Company") {
      Company.value = this.isValidInput(value);
    } else if (type === "Category") {
      Category.value = this.isValidInput(value);
    } else if (type === "Description1") {
      Description1.value = this.isValidInput(value);
    } else if (type === "Description2") {
      Description2.value = this.isValidInput(value);
    } else if (type === "Payment" || type === "Deposit") {
      if (type === "Payment") {
        Payment.value = this.isValidInput(value);
      } else if (type === "Deposit") {
        Deposit.value = this.isValidInput(value);
      }
    } else if (type === "PaymentDeposit") {
      PaymentDeposit.value = this.isValidInput(value);
    }
    this.setState({ AddlessAmt1, AddlessAmt2, Amount1, Amount2, Date, Company, Category, Description1, Description2, Payment, Deposit, PaymentDeposit }, () => {
      this.setcolumnMapingVisibilty();
      //this.setcolumnMapingMatch();
    });
  }

  setcolumnMapingVisibilty = () => {
    
    let { AddlessAmt1, AddlessAmt2, Amount1, Amount2, Date, Company, Category, Description1, Description2, Payment, Deposit, PaymentDeposit } = this.state;
    // if (Amount1.value !== "" || Amount2.value !== "") {
    //   Payment.value = ""; Payment.isDisable = true;
    //   Deposit.value = ""; Deposit.isDisable = true;
    //   PaymentDeposit.value = ""; PaymentDeposit.isDisable = true;
    // } else 
    if (Payment.value !== "" || Deposit.value !== "") {
      AddlessAmt1.value = ""; AddlessAmt1.isDisable = true;
      AddlessAmt2.value = ""; AddlessAmt2.isDisable = true;
      Amount1.value = ""; Amount1.isDisable = true;
      Amount2.value = ""; Amount2.isDisable = true;
      PaymentDeposit.value = ""; PaymentDeposit.isDisable = true;
    } else if (PaymentDeposit.value !== "") {
      Amount1.value = ""; Amount1.isDisable = true;
      Amount2.value = ""; Amount2.isDisable = true;
      Payment.value = ""; Payment.isDisable = true;
      Deposit.value = ""; Deposit.isDisable = true;
      AddlessAmt1.value = ""; AddlessAmt1.isDisable = true;
      AddlessAmt2.value = ""; AddlessAmt2.isDisable = true;
    } else {
      AddlessAmt1.isDisable = false;
      AddlessAmt2.isDisable = false;
      Amount1.isDisable = false;
      Amount2.isDisable = false;
      Payment.isDisable = false;
      Deposit.isDisable = false;
      PaymentDeposit.isDisable = false;
    }
    let isAmountType = "";
    if (Amount1.value !== "") {
      isAmountType = Amount1.value;
      if (Amount1.value.toString().toLowerCase() === Amount2.value.toLowerCase() ||
        Amount1.value.toString().toLowerCase() === Date.value.toLowerCase() ||
        Amount1.value.toString().toLowerCase() === Company.value.toLowerCase() ||
        Amount1.value.toString().toLowerCase() === Category.value.toLowerCase() ||
        Amount1.value.toString().toLowerCase() === Description1.value.toLowerCase() ||
        Amount1.value.toString().toLowerCase() === Description2.value.toLowerCase()) {
        Amount1.isMatch = true;
      } else {
        Amount1.isMatch = false;
      }
    } else {
      Amount1.isMatch = false;
    }
    if (Amount2.value !== "") {
      isAmountType = Amount2.value;
      if (Amount2.value.toString().toLowerCase() === Amount1.value.toLowerCase() ||
        Amount2.value.toString().toLowerCase() === Date.value.toLowerCase() ||
        Amount2.value.toString().toLowerCase() === Company.value.toLowerCase() ||
        Amount2.value.toString().toLowerCase() === Category.value.toLowerCase() ||
        Amount2.value.toString().toLowerCase() === Description1.value.toLowerCase() ||
        Amount2.value.toString().toLowerCase() === Description2.value.toLowerCase()) {
        Amount2.isMatch = true;
      } else {
        Amount2.isMatch = false;
      }
    } else {
      Amount2.isMatch = false;
    }
    if (PaymentDeposit.value !== "") {
      isAmountType = PaymentDeposit.value;
      if (PaymentDeposit.value.toString().toLowerCase() === Date.value.toLowerCase() ||
        PaymentDeposit.value.toString().toLowerCase() === Company.value.toLowerCase() ||
        PaymentDeposit.value.toString().toLowerCase() === Category.value.toLowerCase() ||
        PaymentDeposit.value.toString().toLowerCase() === Description1.value.toLowerCase() ||
        PaymentDeposit.value.toString().toLowerCase() === Description2.value.toLowerCase()) {
        PaymentDeposit.isMatch = true;
      } else {
        PaymentDeposit.isMatch = false;
      }
    } else {
      PaymentDeposit.isMatch = false;
    }
    if (Payment.value !== "") {
      isAmountType = Payment.value;
      if (Payment.value.toString().toLowerCase() === Deposit.value.toLowerCase() ||
        Payment.value.toString().toLowerCase() === Date.value.toLowerCase() ||
        Payment.value.toString().toLowerCase() === Company.value.toLowerCase() ||
        Payment.value.toString().toLowerCase() === Category.value.toLowerCase() ||
        Payment.value.toString().toLowerCase() === Description1.value.toLowerCase() ||
        Payment.value.toString().toLowerCase() === Description2.value.toLowerCase()) {
        Payment.isMatch = true;
      } else {
        Payment.isMatch = false;
      }
    } else {
      Payment.isMatch = false;
    }
    if (Deposit.value !== "") {
      isAmountType = Deposit.value;
      if (Deposit.value.toString().toLowerCase() === Payment.value.toLowerCase() ||
        Deposit.value.toString().toLowerCase() === Date.value.toLowerCase() ||
        Deposit.value.toString().toLowerCase() === Company.value.toLowerCase() ||
        Deposit.value.toString().toLowerCase() === Category.value.toLowerCase() ||
        Deposit.value.toString().toLowerCase() === Description1.value.toLowerCase() ||
        Deposit.value.toString().toLowerCase() === Description2.value.toLowerCase()) {
        Deposit.isMatch = true;
      } else {
        Deposit.isMatch = false;
      }
    } else {
      Deposit.isMatch = false;
    }
    if (Date.value !== "") {
      if (
        Date.value.toString().toLowerCase() === Company.value.toLowerCase() ||
        Date.value.toString().toLowerCase() === Category.value.toLowerCase() ||
        Date.value.toString().toLowerCase() === Description1.value.toLowerCase() ||
        Date.value.toString().toLowerCase() === Description2.value.toLowerCase() ||
        Date.value.toString().toLowerCase() === isAmountType.toLowerCase()) {
        Date.isMatch = true;
      } else {
        Date.isMatch = false;
      }
    } else {
      Date.isMatch = false;
    }
    if (Company.value !== "") {
      if (
        Company.value.toString().toLowerCase() === Date.value.toLowerCase() ||
        Company.value.toString().toLowerCase() === Category.value.toLowerCase() ||
        Company.value.toString().toLowerCase() === Description1.value.toLowerCase() ||
        Company.value.toString().toLowerCase() === Description2.value.toLowerCase() ||
        Company.value.toString().toLowerCase() === isAmountType.toLowerCase()) {
        Company.isMatch = true;
      } else {
        Company.isMatch = false;
      }
    } else {
      Company.isMatch = false;
    }

    if (Category.value !== "") {
      if (
        Category.value.toString().toLowerCase() === Date.value.toLowerCase() ||
        Category.value.toString().toLowerCase() === Company.value.toLowerCase() ||
        Category.value.toString().toLowerCase() === Description1.value.toLowerCase() ||
        Category.value.toString().toLowerCase() === Description2.value.toLowerCase() ||
        Category.value.toString().toLowerCase() === isAmountType.toLowerCase()) {
        Category.isMatch = true;
      } else {
        Category.isMatch = false;
      }
    } else {
      Category.isMatch = false;
    }

    if (Description1.value !== "") {
      if (
        Description1.value.toString().toLowerCase() === Date.value.toLowerCase() ||
        Description1.value.toString().toLowerCase() === Company.value.toLowerCase() ||
        Description1.value.toString().toLowerCase() === Category.value.toLowerCase() ||
        Description1.value.toString().toLowerCase() === Description2.value.toLowerCase() ||
        Description1.value.toString().toLowerCase() === isAmountType.toLowerCase()
      ) {
        Description1.isMatch = true;
      } else {
        Description1.isMatch = false;
      }
    } else {
      Description1.isMatch = false;
    }

    if (Description2.value !== "") {
      if (Description2.value.toString().toLowerCase() === Date.value.toLowerCase() ||
        Description2.value.toString().toLowerCase() === Company.value.toLowerCase() ||
        Description2.value.toString().toLowerCase() === Category.value.toLowerCase() ||
        Description2.value.toString().toLowerCase() === Description1.value.toLowerCase() ||
        Description2.value.toString().toLowerCase() === isAmountType.toLowerCase()
      ) {
        Description2.isMatch = true;
      } else {
        Description2.isMatch = false;
      }
    } else {
      Description2.isMatch = false;
    }
    this.setState({ Amount1, Amount2, Date, Company, Category, Description1, Description2, Payment, Deposit, PaymentDeposit }, () => {
      let isDuplicateColumn = false;
      if (Amount1.isMatch || Amount2.isMatch || Date.isMatch || Company.isMatch || Category.isMatch || Description1.isMatch ||
        Description2.isMatch || Payment.isMatch || Deposit.isMatch || PaymentDeposit.isMatch) {
        isDuplicateColumn = true;
      } else {
        isDuplicateColumn = false;
      }
      this.setState({ isDuplicateColumn });
    })
  }

  validateSaveRule = () => {
    let { Amount1, Amount2, Date, Company, Category, Description1, Description2, Payment, Deposit, PaymentDeposit } = this.state;
    let isValid = false;
    if (Amount1.value !== "" || Amount2.value !== "" || Date.value !== "" || Company.value !== "" || Category.value !== "" || Description1.value !== "" ||
      Description2.value !== "" || Payment.value !== "" || Deposit.value !== "" || PaymentDeposit.value !== "") {
      isValid = true;
    } else {
      isValid = false;
    }
    // let isOther = Date.value === "" || Company.value === "" || Category.value === "" || Description1.value === "" || Description2.value === "";
    // let isValid = false;
    // if (Amount1.value !== "" || Amount2.value !== "") {
    //   if (Amount1.value === "" || Amount2.value === "" || AddlessAmt1.value === "" || AddlessAmt2.value === "" || isOther) {
    //     isValid = false;
    //   } else {
    //     isValid = true;
    //   }
    // }
    // if (Payment.value !== "" || Deposit.value !== "") {
    //   if (Payment.value === "" || Deposit.value === "" || isOther) {
    //     isValid = false;
    //   } else {
    //     isValid = true;
    //   }
    // }
    // if (PaymentDeposit.value !== "") {
    //   if (PaymentDeposit.value === "" || isOther) {
    //     isValid = false;
    //   } else {
    //     isValid = true;
    //   }
    // }
    return isValid;
  }

  handleSaveRule = () => {
    this.setState({ isSaveColumnMapping: true });
    let request: any = {};
    request.Date = this.state.Date.value;
    request.Company = this.state.Company.value;
    request.Category = this.state.Category.value;
    request.Description1 = this.state.Description1.value;
    request.Description2 = this.state.Description2.value;
    request.Description3 = "";
    request.Amount1 = this.state.Amount1.value;  // Payment
    request.Amount2 = this.state.Amount2.value;  // Deposit
    request.AddlessAmt1 = this.state.AddlessAmt1.value;
    request.AddlessAmt2 = this.state.AddlessAmt2.value;
    request.Status = "";
    request.Payment = this.state.Payment.value;
    request.Deposit = this.state.Deposit.value;
    request.Payment_Deposit = this.state.PaymentDeposit.value;
    request.AcPeriod = "";
    request.InvoiceNo = "";
    request.Type = "CashImportStatement";
    request.OprId = -1;
    request.RuleID = this.state.selectedRule.ruleID;
    request.Flag = "CreditCardAcct";
    registersevice.SaveImportStatementDataMapping(request)
      .then(async (result: any) => {
        if (result.success) {
          toast.success("Mapping Rules updated.", { position: toast.POSITION.BOTTOM_RIGHT });
          setTimeout(() => {
            this.resetColumnMapping();
            this.setState({ isSaveColumnMapping: false, selectedRule: this.state.defaultSelectedRule, isSelectedRoleHasValue: false });
          }, 1000);
        } else {
          Utils.toastError(result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.setState({ isProcessing: false });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isProcessing: false, isProcessStart: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reject();
      });
  }

  onTAB = (control, event) => {
    if (event.key.toLowerCase() === "tab") {
      if (control === 'toDiscard' && !event.shiftKey) {
        if (this.state.PaymentDeposit.value !== "") {
          setTimeout(() => { $("#btnDiscard").focus() }, 50);
        }
      }
    }
  }

  render() {

    return (
      <>
        <div className="main-page">
          {this.props.parentState?.dataRuleFrom !== "Import" && (
            <>
              <div className="navigation-menus d-flex">

                <div className="columnMappingSingleSelect">
                  <Dropdown onSelect={(e) => this.handleSelectRule(e)}>
                    <Dropdown.Toggle id="Modal-hid" className="filter-btn white-bg">
                      <div className="d-flex align-items-center">
                        <div className="txt-area">
                          {this.state.selectedRule.ruleName === "Select" ?
                            (<span className="dropdownPlaceholder">Select mapping rule</span>) :
                            (<EllipsisWithTooltip placement="bottom">{this.state.selectedRule.ruleName}</EllipsisWithTooltip>)}
                        </div>
                        <div className="drop-arrow ml-0 d-flex">
                          <FiChevronDown />
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="scrollableArea">
                        {this.state.mappingRuleArray.map(
                          (item: any, idx: any) => (
                            <Dropdown.Item eventKey={JSON.stringify(item)} key={idx}>
                              <EllipsisWithTooltip placement="bottom">{item.ruleName}</EllipsisWithTooltip>
                            </Dropdown.Item>
                          )
                        )}
                      </div>
                      <Dropdown.Divider className="m-0" />
                      <Dropdown.Item className="dropdownFooter" eventKey={"addNewRule"}>
                        <strong>Add New Rule</strong>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </>
          )}
          {this.state.isSelectedRoleHasValue && (
            <div className={this.state.isSelectedRoleHasValue ? "" : "disabled-area"}>
              <div className={this.validateSaveRule() ? "tableColumnMapping cmEx" : "tableColumnMapping"}>
                <Table className="react-bootstrap-table">
                  <thead>
                    <tr>
                      <th className="cmColumn1">Columns</th>
                      <th className="cmColumn2">Mapping</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>Date</strong></td>
                      <td>
                        {this.state.columnMappingLoader ? (
                          <div className="columnMappingLoader single-loader loadingAnimation"></div>
                        ) : (
                          <Form.Control
                            type="text"
                            id="idDate"
                            value={this.state.Date.value}
                            onChange={(event: any) => this.handleInputControl(event, 'Date')}
                            placeholder="Date"
                            className={this.state.Date.isMatch ? "columnMappedControl hasError" : "columnMappedControl"}
                            autoComplete="off"
                            maxLength={this.state.maxLength}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Company</strong></td>
                      <td>
                        {this.state.columnMappingLoader ? (
                          <div className="columnMappingLoader single-loader loadingAnimation"></div>
                        ) : (
                          <Form.Control
                            type="text"
                            id="idCompany"
                            value={this.state.Company.value}
                            onChange={(event: any) => this.handleInputControl(event, 'Company')}
                            placeholder="Company"
                            className={this.state.Company.isMatch ? "columnMappedControl hasError" : "columnMappedControl"}
                            autoComplete="off"
                            maxLength={this.state.maxLength}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Category</strong></td>
                      <td>
                        {this.state.columnMappingLoader ? (
                          <div className="columnMappingLoader single-loader loadingAnimation"></div>
                        ) : (
                          <Form.Control
                            type="text"
                            id="idCategory"
                            value={this.state.Category.value}
                            onChange={(event: any) => this.handleInputControl(event, 'Category')}
                            placeholder="Category"
                            className={this.state.Category.isMatch ? "columnMappedControl hasError" : "columnMappedControl"}
                            autoComplete="off"
                            maxLength={this.state.maxLength}
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Description1</strong></td>
                      <td>
                        {this.state.columnMappingLoader ? (
                          <div className="columnMappingLoader single-loader loadingAnimation"></div>
                        ) : (
                          <Form.Control
                            type="text"
                            id="idDescription1"
                            value={this.state.Description1.value}
                            onChange={(event: any) => this.handleInputControl(event, 'Description1')}
                            placeholder="Description1"
                            className={this.state.Description1.isMatch ? "columnMappedControl hasError" : "columnMappedControl"}
                            autoComplete="off"
                            maxLength={this.state.maxLength}
                          />
                        )}
                      </td>
                    </tr>
                    <tr><td><strong>Description2</strong></td>
                      <td>
                        {this.state.columnMappingLoader ? (
                          <div className="columnMappingLoader single-loader loadingAnimation"></div>
                        ) : (
                          <Form.Control
                            type="text"
                            id="idDescription2"
                            value={this.state.Description2.value}
                            onChange={(event: any) => this.handleInputControl(event, 'Description2')}
                            placeholder="Description2"
                            className={this.state.Description2.isMatch ? "columnMappedControl hasError" : "columnMappedControl"}
                            autoComplete="off"
                            maxLength={this.state.maxLength}
                          />
                        )}
                      </td>
                    </tr>


                    {this.state.isAmountColumnExist && (<>
                      <tr>
                        <td><strong>Amount1</strong></td>
                        <td>
                          {this.state.columnMappingLoader ? (
                            <div className="columnMappingLoader single-loader loadingAnimation"></div>
                          ) : (
                            <Form.Control type="text" id="idAmount1"
                              value={this.state.Amount1.value}
                              disabled={true}
                              placeholder=""
                              className={this.state.Amount1.isMatch ? "columnMappedControl hasError" : "columnMappedControl"}
                              autoComplete="off"
                              maxLength={this.state.maxLength}
                              onChange={(event: any) => this.handleInputControl(event, 'Amount1')}
                            />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>AddlessAmt1</strong></td>
                        <td>
                          {this.state.columnMappingLoader ? (
                            <div className="columnMappingLoader single-loader loadingAnimation"></div>
                          ) : (
                            <Form.Control type="text" id="idAddlessAmt1" disabled={true} value={this.state.AddlessAmt1.value} className="columnMappedControl" />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Amount2</strong></td>
                        <td>
                          {this.state.columnMappingLoader ? (
                            <div className="columnMappingLoader single-loader loadingAnimation"></div>
                          ) : (
                            <Form.Control
                              type="text"
                              id="idAmount2"
                              disabled={true}
                              value={this.state.Amount2.value}
                              onChange={(event: any) => this.handleInputControl(event, 'Amount2')}
                              placeholder=""
                              className={this.state.Amount2.isMatch ? "columnMappedControl hasError" : "columnMappedControl"}
                              autoComplete="off"
                              maxLength={this.state.maxLength}
                            />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>AddlessAmt2</strong></td>
                        <td>
                          {this.state.columnMappingLoader ? (
                            <div className="columnMappingLoader single-loader loadingAnimation"></div>
                          ) : (
                            <Form.Control type="text" id="idAddlessAmt2" disabled={true} value={this.state.AddlessAmt2.value} className="columnMappedControl" />
                          )}
                        </td>
                      </tr>
                    </>
                    )}

                    <tr><td><strong>Debit</strong></td>{/* Payment */}
                      <td>
                        {this.state.columnMappingLoader ? (
                          <div className="columnMappingLoader single-loader loadingAnimation"></div>
                        ) : (
                          <Form.Control
                            type="text"
                            id="idPayment"
                            value={this.state.Payment.value}
                            disabled={this.state.Payment.isDisable}
                            onChange={(event: any) => this.handleInputControl(event, 'Payment')}
                            placeholder={this.state.Payment.isDisable ? "" : "Debit"}
                            className={this.state.Payment.isMatch ? "columnMappedControl hasError" : "columnMappedControl"}
                            autoComplete="off"
                            maxLength={this.state.maxLength}
                          />
                        )}
                      </td>
                    </tr>
                    <tr><td><strong>Credit</strong></td>{/* Deposit */}
                      <td>
                        {this.state.columnMappingLoader ? (
                          <div className="columnMappingLoader single-loader loadingAnimation"></div>
                        ) : (
                          <Form.Control
                            type="text"
                            id="idDeposit"
                            disabled={this.state.Deposit.isDisable}
                            value={this.state.Deposit.value}
                            onChange={(event: any) => this.handleInputControl(event, 'Deposit')}
                            placeholder={this.state.Deposit.isDisable ? "" : "Credit"}
                            className={this.state.Deposit.isMatch ? "columnMappedControl hasError" : "columnMappedControl"}
                            autoComplete="off"
                            maxLength={this.state.maxLength}
                            onKeyDown={this.onTAB.bind(this, "toDiscard")}
                          />
                        )}
                      </td>
                    </tr>
                    <tr><td><strong>Debit/Credit</strong></td>
                      <td>
                        {this.state.columnMappingLoader ? (
                          <div className="columnMappingLoader single-loader loadingAnimation"></div>
                        ) : (
                          <Form.Control
                            type="text"
                            id="idPaymentDeposit"
                            value={this.state.PaymentDeposit.value}
                            disabled={this.state.PaymentDeposit.isDisable}
                            onChange={(event: any) => this.handleInputControl(event, 'PaymentDeposit')}
                            placeholder={this.state.PaymentDeposit.isDisable ? "" : "Debit/Credit"}
                            className={this.state.PaymentDeposit.isMatch ? "columnMappedControl hasError" : "columnMappedControl"}
                            autoComplete="off"
                            maxLength={this.state.maxLength}
                          //onKeyDown={this.onTAB.bind(this, "toDiscard")}
                          />
                        )}
                      </td>
                    </tr>

                  </tbody>
                </Table>
                {this.validateSaveRule() && (
                  <div className="columnMappingFooter">
                    <div className="d-flex align-items-center justify-content-end height-72">
                      <Button type="button"
                        id="btnDiscard"
                        onClick={(e) => this.resetColumnMapping()}
                        className="space btnDiscardColumnMaping btn-discard">Discard</Button>
                      <Button className="btnSaveColumnMaping" id="btnSaveColumnMaping"
                        disabled={this.state.isSaveColumnMapping || this.state.isDuplicateColumn}
                        type="button" onClick={this.handleSaveRule}>
                        {this.state.isSaveColumnMapping && <><Spinner size="sm" animation="border" />{" "}</>}Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <Modal
          show={this.state.isAddNewRole}
          onHide={this.addNewRoleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="ccsImportModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Rule Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Enter rule name</Form.Label>
            <Form.Control
              className={this.state.newRuleError ? "border-danger" : ""}
              value={this.state.newRuleName}
              type="text"
              onChange={this.handleNewRuleName}
              //onBlur={this.NewRuleNameValidation}
              placeholder="Enter rule name" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary"
              disabled={this.state.newRuleErrorSet}
              onClick={this.addNewRoleName}>
              Add
            </Button>
            <Button variant="secondary" className="btnCancel" onClick={this.addNewRoleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
