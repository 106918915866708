import * as React from "react";
// import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DropDownList } from "./common-components/dropdown-list";
export class EmployementTypeSelector extends React.Component<any, any> {
  private inputElement: any;
  private employementTypes = [
    { label: 'All', value: 'All' },
    { label: 'Salaried', value: 'Salaried' },
    { label: 'Hourly', value: 'HourlyWage' },
    { label: 'Contractor', value: 'Contractor' },
    { label: 'Exclude From Payroll', value: 'ExcludefromPayroll' }
  ];
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      placeHolder: "Select Employement Type",
      selectedItem: { label: 'All', value: 'All' },
      filterData: this.employementTypes,
      employementTypes: this.employementTypes,
      inputValue: "",
    };
  }


  componentDidMount = () => {
    if (!this.props.defaultValue) {
      this.handleDropdownChange(this.state.selectedItem);
    }
  };

  componentDidUpdate = (prevProps) => {

    const { defaultValue } = this.props;
    if (!defaultValue && this.state.selectedItem.label !== 'All') {
      this.handleDropdownChange({ label: 'All', value: 'All' });
    }
  };


  handleEmpTypeSearch = (e: { target: { value: string } }) => {
    const updatedList = this.state.employementTypes.filter((item) => {
      return (
        item?.label?.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) >
        -1
      );
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }

  handleDropdownChange = (item: any) => {
    this.setState({ selectedItem: item });
    this.props.onEmpTypeChange(item.value);
    this.resetSerchBox();
  }

  resetSerchBox = () => {
    this.setState({ filterData: this.state.employementTypes, inputValue: "" });
  };


  render() {
    // const empTypeSelectModal = (eventKey: any) => {
    //   this.setState({ placeHolder: eventKey });
    // };

    // const onFocusInput = (eventKey: any) => {
    //   this.resetSerchBox();
    //   setTimeout(() => {
    //     this.inputElement.current.focus();
    //   }, 100);
    // };

    return (
      <DropDownList placeholder={"Select Employement Type"}
        defaultValue={this.props.defaultValue}
        data={this.employementTypes}
        label={"label"} value={"value"}
        onDropDownChange={this.handleDropdownChange}
        selectedItem={this.state.selectedItem} />
    )
  }
}
