import React from "react";
import { Spinner } from "react-bootstrap";

class PageLoader extends React.Component<any, any> {
  render() {
    return (
      <div className="pageLoader">
        <Spinner animation="border" />
      </div>
    );
  }
}

export default PageLoader;
