import * as React from 'react';
// import { Form } from 'react-bootstrap'
// import { Hotel } from '../../Common/Services/Hotel';
// import { IHotelResponseDto } from '../../Common/Contracts/IHotel';
import { COAAccountSelector } from "../Reports/COAAccountSelector"
import { AccountingPeriodStart } from './AccountingPeriodStart';


export class AccotingPeriodSector extends React.Component<any, any> {

  private SingleselectChild: any;
  private singleSerch: any;
  constructor(props: any) {
    super(props);
    this.SingleselectChild = React.createRef();
    this.singleSerch = React.createRef();
    this.state = {
      hidValue: "Select",
      hotelName: "Select",
      copyFromHid: 0,
      hotelList: [],
      defaultValue: this.props?.defaultValue
    };
    this.isHotelid = this.isHotelid.bind(this);
  }

  isHotelid = (hotelList: any) => {
    this.setState({ copyFromHid: hotelList });
    this.props?.handleAccotingSelector(hotelList);
  };

  resetValue = () => {
    this.singleSerch.current.resetSerchBox();
  };

  componentDidUpdate() {
    this.singleSerch.current.hidSelectModal(this.props?.defaultValue);
  }

  render() {
    return (
      <AccountingPeriodStart
        ddlId={this.props?.ddlId}
        isAll={this.props?.isAll}
        rptid={this.props?.reportid}
        ddlValue={this.props?.startvalue}
        notIncludeEnterpriseAccounts={this.props?.notIncludeEnterpriseAccounts}
        defaultValue={this.state?.defaultValue}
        ref={this.singleSerch}
        isHotelid={this.isHotelid.bind(this)}
      />
    );
  }
}
