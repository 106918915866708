import * as React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { Container, Dropdown, Form, Button, OverlayTrigger, Tooltip, Modal, Spinner } from "react-bootstrap";
import { resolve, reject } from "q";
import { Utils } from "../../Common/Utilis";
import "./comment.scss";
import profile from "../Assets/Images/profile.png";
import { Register as registersevice } from "../../Common/Services/Register";
import { toast } from "react-toastify";
import moment from 'moment';

export class CommentWithoutList extends React.Component<any, any> {
    private scrollToBottomlist: any;
    constructor(props: any) {
        super(props);
        this.scrollToBottomlist = React.createRef();
        this.state = {
            hotelid: this.props.hotelid,                      
            value: '',
            mentionData: null,           
            users: [],          
            logInUser: "",
            logInUserImage: "",
            isCommentAdd: false,
            addCommentDisabled : this.props.addCommentDisabled,
        };
    }

    onTAB = (event) => {
        if (this.state.value.trim().length > 0) {
            if (event.key.toLowerCase() === "tab") {
                let txtID = "btncomment";

                setTimeout(() => {
                    $("button[id^='" + txtID + "']").focus();
                }, 50);
            }
            if (event.key.toLowerCase() === "enter") {
                // this.addComment();
            }
        }
    }

    handleChange = (event, newValue, newPlainTextValue, mentions) => {
        let text = newPlainTextValue;
        this.setState({
            value: newValue,
            mentionData: { newValue, newPlainTextValue: text, mentions: mentions }
        })
        // setTimeout(() => {
        //     this.props.parentScrollMethod();
        // }, 50)
    }
   


    componentDidMount() {
        this.usernameList();

    }

   
   

    

    isCommentAdd = () => {
        return this.state.value.trim().length > 0 ? true : false;
    }

    retsetComment = () => {
        this.setState({ userComments: [] })
    }


    addReviewComment = () => {

      // let comment = this.state.mentionData.newPlainTextValue.trim(); 
      let mentionData : any []  =[]; 
        mentionData = this.state.mentionData;
       this.props.addReviewComment(mentionData);       
    }



    usernameList = () => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let userName = storage === null ? 0 : (storage.userName as any);
        let userRequest: any = {};
        userRequest.hotelid = this.state.hotelid;
        registersevice.usernameList(userRequest)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.length > 0) {
                        let logInUser = result.filter(x => x.username === userName);
                        let logInUserimage = logInUser[0].image
                        this.setState({ users: result, logInUserImage: logInUserimage }, () => {
                            
                        })
                    }

                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };


    

    render() {
        const userMentionData = this.state.users.map(myUser => ({
            id: myUser.username,
            username: myUser.username,
            name: myUser.name,
            image: myUser.image,
            email: myUser.email,
            position: myUser.position,
            display: `@${myUser.username + ' '}`,
        }))
        return (
            <Modal className="comment-box withoutList" show={true} centered>
                <Modal.Header>
                    <Modal.Title>
                        <div className='d-flex'>
                            <div className='ttl mr-auto'>{this.props?.title}</div>
                            <button type="button" className="close" onClick={() => this.props.closeCommentModal(false)}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.9497 5.05033C14.5933 4.69391 14.0149 4.69391 13.6585 5.05033L10 8.70884L6.34149 5.05033C5.98507 4.69391 5.40668 4.69391 5.05025 5.05033C4.69383 5.40676 4.69383 5.98515 5.05025 6.34157L8.70876 10.0001L5.05025 13.6586C4.69383 14.015 4.69383 14.5934 5.05025 14.9498C5.40668 15.3063 5.98507 15.3063 6.34149 14.9498L10 11.2913L13.6585 14.9498C14.0149 15.3063 14.5933 15.3063 14.9497 14.9498C15.3062 14.5934 15.3062 14.015 14.9497 13.6586L11.2912 10.0001L14.9497 6.34157C15.3062 5.98515 15.3062 5.40676 14.9497 5.05033Z" fill="#3F4347" />
                                </svg>
                            </button>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='comment-area'>
                        <div className='inpute-ttl'>Enter Comment<span>*</span></div>
                        <div className="inpute-field">
                            <MentionsInput
                                id="txtcomment"
                                className="mentions__mention"
                                markup="@{{__type__||__id__||__display__}}"
                                value={this.state.value}
                                onChange={this.handleChange}
                                // onClick={this.handleFocus}
                                placeholder="Type @ to notify another user."
                                onKeyDown={(e: any) => this.onTAB(e)}
                            //singleLine

                            >
                                <Mention
                                    type="user"
                                    trigger="@"
                                    data={userMentionData}
                                    className="mentions__mention"
                                    //displayTransform={(id, display) => `@${display}`}
                                    renderSuggestion={(entry, search, highlightedDisplay, index, focused) => (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <div className="profile">
                                                <img className={entry.image === "" ? 'default' : ''} src={entry.image === "" ? profile : entry.image} width="36" height="36" alt={entry.username} />
                                            </div>
                                            <div>
                                                <div className="user-name">{entry.username}</div>
                                                <div className="user-pos">{entry.position}</div>
                                            </div>
                                        </div>
                                    )}
                                />
                            </MentionsInput>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn wht-bg" onClick={() => this.props.closeCommentModal(false)}>Cancel</button>
                    <button type="button" disabled={this.state.value.trim().length === 0} className="btn btn-primary" onClick={this.addReviewComment}>Submit</button>
                </Modal.Footer>
            </Modal>
        );
    }
}