import * as React from "react";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { AddRoomConfiguration } from "./AddRoomConfiguration";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AddRoomType } from "./AddRoomType";
import { AddConferenceRoom } from "./AddConferenceRoom";
import { FiChevronDown } from "react-icons/fi";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment";
import Calendar from "react-calendar";
import OutsideClickHandler from "react-outside-click-handler";
import { EntityManagementService } from "../../../Common/Services/EntityManagementService";
import { toast } from "react-toastify";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { confirmAlert } from "react-confirm-alert";
import "./Rooms.scss";
import { Utils } from "../../../Common/Utilis";
import _ from "lodash";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import DateWithYearDropdown from "./DateWithYearDropdown";
import TableSearch from "../../../Common/Components/TableSearch";
import { MultiSelectDropdown } from "../../LaborManagement/PayrollDeductionSettings/MultiSelectDropdown";
import { AddRoom } from "./AddRoom";
import { AddRoom as ChangeRoomType} from "./AddRoom";
import { TakeRoomOffline } from "./TakeRoomOffline";
import SaveRoomDateModal from "./SaveRoomDateModal";
import RoomsListTable from "./RoomsListTable";
import { IRoom } from "../../../Common/Contracts/IRoom";
import { EditRoom } from "./EditRoom";
import { SingleTakeRoomOffline } from "./SingleTakeRoomOffline";

const { SearchBar } = Search;

const SortByList = [
  { label: "Name", value: "roomType" },
  { label: "Count", value: "capacity" },
];

const statusList =[
  { label: "All", value: "All" },
  { label: "Active", value: "Online" },
  { label: "Inactive", value: "Offline" },
]


let RoomCategoryList =[
  { label: "Customer", value: "Customer" },
  { label: "Conference", value: "Conference" },
  { label: "Other", value: "Other" },
]

const conferenceOtherRoomsFormatted =(data)=>{
  let conferenceOtherRooms= data?.find(x => x.category == "conference" || x.category == "other"  )

  if(conferenceOtherRooms)
  {
    const conferenceOtherRoomsList=conferenceOtherRooms?.roomList || []
   
    const result=data.map( x=> ({
      ...x,
      roomList:[...x?.roomList,...conferenceOtherRoomsList.map(item => ({...item,accountingDate:x.accountingDate}))]
    })).filter(x => !(x.category == "conference" || x.category == "other") )

    return result
  }
  else{

    return data
  }
}

const getFormattedRoomTypeData =(data:any[]=[])=>{

   
    const result = _.chain(data)
    .groupBy('accountingDate')
    .map((value, key) => ({
      accountingDate: key,
      value,
      roomTypeNew: _.map(_.countBy(_.flatMap(value, 'roomList'), 'roomTypeId'), (capacity, roomTypeId) => ({
        // roomTypeId:parseInt(roomTypeId),
        uniqueno:parseInt(roomTypeId),
        capacity:  _.find(value[0].roomList,{roomTypeId:parseInt(roomTypeId)}).roomID ? capacity : 0  ,
        hid: _.find(value[0].roomList,{roomTypeId:parseInt(roomTypeId)}).hid,
        roomType:parseInt(roomTypeId) == -1 ? "Conference Rooms": parseInt(roomTypeId) == -2 ? "Other Rooms" : _.find(value[0].roomList,{roomTypeId:parseInt(roomTypeId)}).roomType,
        notes:_.find(value[0].roomList,{roomTypeId:parseInt(roomTypeId)}).roomTypeNotes,
      })) || [],
    }))
    .value();

    return result
}


const AddConferenceOtherRoomsTypes =(data:any[]=[])=>{
  const typesLits=[...data]

  const index=typesLits.findIndex(obj => obj.uniqueno == -2)
  

  if(!typesLits.find(x => x.uniqueno == -1))
  {
    if(index !== -1){
      typesLits.splice(index, 0, {
        uniqueno:-1,
        roomType:"Conference Rooms",
        capacity:0,
        notes:""
      })
    }
    else{
      typesLits.push({
        uniqueno:-1,
        roomType:"Conference Rooms",
        capacity:0,
        notes:""
      })
    }
   
  }

  if(!typesLits.find(x => x.uniqueno == -2))
  {
    typesLits.push({
      uniqueno:-2,
      roomType:"Other Rooms",
      capacity:0,
      notes:""
    })
  }

  return typesLits
}

const AddConferenceOtherROomsDropdownList =(data:any[]=[])=>{
  let dropdownList=[...data]
  if(!dropdownList.find(x=> x.value == -1))
  {
    dropdownList?.push({
      uniqueno:-1,
      value:-1,
      description:"Conference Rooms",
      isChecked:false
    })
  }

  if(!dropdownList.find(x=> x.value == -2))
  {
    dropdownList?.push({
      uniqueno:-2,
      value:-2,
      description:"Other Rooms",
      isChecked:false
    })
  }

  dropdownList=_.uniqBy(dropdownList,"uniqueno")
  return dropdownList
}

RoomCategoryList=RoomCategoryList.map(x=>({...x, value:x.value, description:x.label, isChecked:false}))


const mostRecentDate = (dateArray) => {
  let moments = dateArray.map((d) => moment(d.date));
  let maxDate = moment.max(moments);

  return maxDate.format("MM/DD/YYYY");
};

const ExpanedDropdown =({cell,row,rowIndex,hasEditPermission,currentSelectedDate,hotelId,updateRoomList,RoomTypesList,isBulkAction})=>{
   const rowRef=React.useRef<any>() 
   const [isShowModal,setIsShowModal]=React.useState(false)
   const [isTakeOfflineRoom,setIsTakeOfflineRoom]=React.useState(false)

  

   const hideDropdown =()=>{
    if(rowRef?.current && rowRef?.current?.ariaExpanded === "true")
    {
      rowRef.current.click()
    }
   }

   const showModal =()=>{
    // hideDropdown()
    setIsShowModal(true)
   }

   const hideMoal =()=>{
    hideDropdown()
    setIsShowModal(false)
   }

   const showTakeOfflineModal =()=>{
    // hideDropdown()
    setIsTakeOfflineRoom(true)
   }

   const hideTakeOfflineMoal =()=>{
    hideDropdown()
    setIsTakeOfflineRoom(false)
   }
  return <div className="d-flex justify-content-end dropdowns-wrapper">
      <OutsideClickHandler onOutsideClick={hideDropdown}>
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className={`position-static room-dropdowns ${isShowModal || isTakeOfflineRoom ? "more-action show"
            : "more-action"}`}
            // style={{ position: "static" }}
            alignRight
          >
            <Dropdown.Toggle
              ref={rowRef}
              disabled={!hasEditPermission || +row?.roomID <= 0 || isBulkAction}
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu> 

              {+row?.roomID > 0 && (
                <div className="dropdown-item"
                  // key={"edit"}
                  // eventKey={"edit"}
                  onClick={showModal}
                >
                  Edit
                </div>
              )}

              {+row?.roomID > 0 && (
                <div className="dropdown-item"
                  // key={"edit"}
                  // eventKey={"edit"}
                  onClick={showTakeOfflineModal}
                >
                  Take Offline
                </div>
              )}

              {/* {+row?.roomID > 0 && (
                <Dropdown.Item
                  key={"edit"}
                  eventKey={"edit"}
                  onClick={showModal}
                >
                  Edit
                </Dropdown.Item>
              )}

              {+row?.roomID > 0 && (
                <Dropdown.Item
                  key={"edit"}
                  eventKey={"edit"}
                  onClick={showTakeOfflineModal}
                >
                  Take Offline
                </Dropdown.Item>
              )} */}
              {/* <Dropdown.Item Dropdown.Item
                key={"delete"}
                eventKey={"delete"}
                onClick={() => this.deleteRowData(row)}
              >
                Delete
              </Dropdown.Item> */}

              {isShowModal && (
                
                <EditRoom
                  date={currentSelectedDate}
                  roomRowEdit={row}
                  hotelId={hotelId}
                  showAddRoomModal={isShowModal}
                  handleHideRoomModal={hideMoal}
                  updateRoomList={updateRoomList}
                  RoomTypesList={RoomTypesList}
      
                />
              
              )}

            {/* Take offline dropdown */}

              {isTakeOfflineRoom && (
                <SingleTakeRoomOffline
                  date={currentSelectedDate}
                  roomRowEdit={row}
                  hotelId={hotelId}
                  showAddRoomModal={isTakeOfflineRoom}
                  handleHideRoomModal={hideTakeOfflineMoal}
                  updateRoomTypesList={()=>{}}
                  RoomTypesList={RoomTypesList}

                />
              )}  

            </Dropdown.Menu>
          </Dropdown>
        </OutsideClickHandler>
 
        
          </div>
}

export class Rooms extends React.Component<any, any> {
  private tableSearch: any;
  private toolkit: any;
  private roomTypeRef: any;
  private roomCategoryRef: any;
  constructor(props: any) {
    super(props);
    this.tableSearch = React.createRef();
    this.toolkit = React.createRef();
    this.roomTypeRef = React.createRef();
    this.roomCategoryRef = React.createRef();
    this.state = {
      leftPanelLoader: false,
      rightPanelLoader: false,
      showAddRoomTypeModal: false,
      showRoomConfigurationModal: false,
      currentSelectedDate: "",
      leftPanelDates: [],
      isSelected: false,
      selected: [],
      roomTypeData: [],
      roomTypeRowEdit: {},
      rightTableData: [],
      calenderStartDateShow: false,
      isDataSubmitting: false,
      conferenceRoomRowEdit: {},
      otherRoomRowEdit: {},
      conferenceRoomData: [],
      otherRoomData: [],
      deletedRoomIds: [],
      showAddCoferenceRoomModal: false,
      showAddOtherRoomModal: false,
      showSaveModal: false,
      isRoomTypesArrayModified: false,
      hideConferenceSection: true,
      isRoomTypeLoading: false,
      // toggleLoading:false,
      isConferenceLoading: false,
      conferenceRooms: [],
      otherRooms: [],
      calendarSelectedDate: "",

      roomData:[],
      allRoomsTableData:[],
      filteredRoomsTableData:[],
      tableExpended: [],
      isExpand: false,
      selectedRow: null,
      searchText: "",
      SortBy: "Name",
      currentToggle: "Types",
      status:"Active",
      RoomTypeDropdownList:[],
      roomRowEdit:{},
      showAddRoomModal:false,
      selectedRoomCategory:[],
      filteredRoomById:[],
      isMountDone:true,
      showTakeRoomOffline:false,
      showChangeRoomTypeModal:false,
      isBulkAction:false,
      selectedIds:[],
      isRoomsArrayModified:false,
      isExpandedRoomsArrayModified:false

    };
  }

  componentDidMount() {
    this.GetAllRooms();
   
  }

  calenderDateHide = () => {
    this.setState({ calenderStartDateShow: false });
  };

  calenderDateDisplay = () => {
    this.setState({ calenderStartDateShow: true });
  };

  discard = () => {
    //  this.setState({selected:[],currentSelectedDate: ""});
    this.GetAllRooms();
  };
  GetAllRooms = (isUpdating = false) => {
    this.setState({ isRoomTypeLoading: true , isBulkAction: false, selectedIds:[], tableExpended:[],isExpandedRoomsArrayModified:false,isRoomTypesArrayModified:false, isRoomsArrayModified:false});
    EntityManagementService.GetAllRooms(
      this.props?.entityRowData?.hotelId
    ).then((roomData: any) => {
       
      roomData=conferenceOtherRoomsFormatted(roomData)
      const roomTypeData=getFormattedRoomTypeData(roomData)
      // const roomTypeData=roomData

      if (roomData?.length > 0) {
        const leftPanelDates = roomData?.map((item) => ({
          date: moment(item?.accountingDate).format("MM/DD/YYYY"),
        }))

      

        const recentDate = mostRecentDate(leftPanelDates);
        // if(recentDate){
        //   this.setState({currentSelectedDate:recentDate})
        // }
      
        let currenDate;
        if (isUpdating) {
          if (
            leftPanelDates.find(
              (x) => x.date == this.state.calendarSelectedDate
            )
          ) {
            currenDate = this.state.calendarSelectedDate;
          } else {
            currenDate = recentDate || "";
          }
        } else {
          if (this.state?.currentSelectedDate) {
            currenDate = this.state?.currentSelectedDate;
          } else {
            currenDate = recentDate || "";
          }
        }

      
        if (currenDate) {

          //Room Types
          const rightTableData = roomTypeData.find((r) => {
            return moment(r.accountingDate).format("MM/DD/YYYY") === currenDate;
          })?.roomTypeNew;

          
          const _roomTypeData = [...roomTypeData]?.map((item) => {
            item.isDataModified = false;
            if (
              moment(item.accountingDate).format("MM/DD/YYYY") === currenDate
            ) {
              item.roomTypeNew = rightTableData;
            }
            return item;
          });

          //All Rooms 
          const allRoomsTableData = roomData.find((r) => {
            return moment(r.accountingDate).format("MM/DD/YYYY") === currenDate;
          })?.roomList?.filter(item => item.roomID) || [];

          //Room Type Dropdown 
          let RoomTypeDropdownList = currenDate ?  rightTableData?.map(x=> ({...x, value:x.uniqueno, description:x.roomType,  isChecked:false})) : [];

         
          RoomTypeDropdownList=AddConferenceOtherROomsDropdownList(RoomTypeDropdownList)
          RoomTypeDropdownList= _.orderBy(RoomTypeDropdownList, ["description"])
      

          this.setState({ rightTableData: [] }, () =>
            this.setState({
              roomData,
              allRoomsTableData,
              filteredRoomsTableData:allRoomsTableData,
              rightTableData,
              leftPanelDates,
              roomTypeData: _roomTypeData,
              currentSelectedDate: currenDate,
              RoomTypeDropdownList:RoomTypeDropdownList,
              isRoomTypeLoading: false,
            },()=>{
              this.getConferenceRooms();
              this.filterRooms()
            })
          );
        } else {
        }
        if (!isUpdating) {
          if (
            leftPanelDates?.filter((x) => x?.date === currenDate)?.length === 0
          ) {
            this.setState({ selected: [], currentSelectedDate: "" });
          }

          this.setState({ leftPanelDates });
        }
      } else {

        let RoomTypeDropdownList:any[] = [];
        RoomTypeDropdownList=AddConferenceOtherROomsDropdownList(RoomTypeDropdownList)
        RoomTypeDropdownList= _.orderBy(RoomTypeDropdownList, ["description"])
    

        this.setState({
          selected: [],
          currentSelectedDate: "",
          rightTableData: [],
          roomData:[],
          allRoomsTableData:[],
          filteredRoomsTableData:[],
          leftPanelDates: [],
          RoomTypeDropdownList:RoomTypeDropdownList,
          isRoomTypeLoading: false,
        },()=>{
          this.getConferenceRooms();
        });
      }
      if (!isUpdating) {
        this.props.getRoomsTabModificationStatus(false);

        this.setState(
          {
            roomTypeData,
            isRoomTypesArrayModified: false,
            isRoomsArrayModified:false,
            isExpandedRoomsArrayModified:false,
            isRoomTypeLoading: false,
          },
          () => {
            // this.handleDateChange();
          }
        );
      }
    })
  };

  getConferenceRooms = () => {
    let _rightTableData=[...this.state.rightTableData]
    _rightTableData=AddConferenceOtherRoomsTypes(_rightTableData)
   
    if(this.state?.tableExpended.length > 0)
    {
      const id= this.state?.tableExpended[0]
      this.getRoomsById(id)
    }
    
    this.setState({rightTableData:_rightTableData})
  };

  handleShowRoomTypeModal = () => {
    this.setState({ roomTypeRowEdit: {} }, () => {
      this.setState({ showAddRoomTypeModal: true });
    });
  };

 
  handleHideRoomTypeModal = (isReload: boolean = false) => {


    this.setState({ showAddRoomTypeModal: false, roomTypeRowEdit: {} });
  };

  handleRoomModal = () => {
    this.setState({ roomRowEdit: {} }, () => {
      this.setState({ showAddRoomModal: true });
    });
  };

  handleHideRoomModal = (isReload: boolean = false) => {

    this.setState({ showAddRoomModal: false, roomRowEdit: {} });
  };

  handleShowTakeRoomOfflineModal = (row) => {
    this.setState({ roomRowEdit: row }, () => {
      this.setState({ showTakeRoomOffline: true });
    });
  };

  handleHideTakeRoomOfflineModal = (isReload: boolean = false) => {

    this.setState({ showTakeRoomOffline: false, roomRowEdit: {} });
  };

  BulkShowTakeRoomOfflineModal =(selectedIds)=>{
    this.setState({ selectedIds: selectedIds }, () => {
      this.setState({ showTakeRoomOffline: true });
    });
  }

  BulkHideTakeRoomOfflineModal = (isReload: boolean = false) => {

    this.setState({ showTakeRoomOffline: false, selectedIds: [] });
  };


  BulkShowChangeRoomTypeModal =(selectedIds)=>{
    this.setState({ selectedIds: selectedIds }, () => {
      this.setState({ showChangeRoomTypeModal: true });
    });
  }

  BulkHideChangeRoomTypeModal =(selectedIds)=>{
    this.setState({ showChangeRoomTypeModal: false, selectedIds: [] });

  }

  editRoom = (row) => {
    this.setState({ roomRowEdit: row }, () => {
      this.setState({ showAddRoomModal: true });
    });
  };

  handleShowConferenceRoomModal = () => {
    this.setState({ conferenceRoomRowEdit: {} }, () => {
      this.setState({ showAddCoferenceRoomModal: true });
    });
  };

  handleShowOtherRoomModal = () => {
    this.setState({ otherRoomRowEdit: {} }, () => {
      this.setState({ showAddOtherRoomModal: true });
    });
  };

 



  getRoomsById = (id) => {
    const _filteredRoomById=this.state.allRoomsTableData.filter(item => item.roomTypeId == id)
    this.setState({filteredRoomById:_filteredRoomById},()=>{
      if(this.state.filteredRoomById.length == 0){
       
       this.setState(
        {
          selectedRow: {},
          isExpand: false,
          isExpandedRoomsArrayModified: false,
          tableExpended: [],
        })
      }
    })
  };

  handleOnExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {
  

    if(isExpand && row.uniqueno != 0){
      row.action = "Expended";
      this.setState({
        isExpand: true,
        selectedRow: row,
        tableExpended: [row.uniqueno],
      });
      this.getRoomsById(row.uniqueno);
    }
    else{
      row.action = "";
      const allRoomsTableData = this.state.roomData.find((r) => {
        return moment(r.accountingDate).format("MM/DD/YYYY") === this.state?.currentSelectedDate;
      })?.roomList?.filter(item => item.roomID) || [];
      this.setState(
        {
          selectedRow: {},
          isExpand: false,
          tableExpended: [],
          selectedIds:[],
          isExpandedRoomsArrayModified:false,
          allRoomsTableData
        })
    }
 
    //  if (this.state.tableExpended.length > 0) {
    //   this.setState(
    //     {
    //       selectedRow: {},
    //       isExpand: false,
    //       tableExpended: [],
    //     },
    //     () => {
    //       if (isExpand && row.uniqueno != 0) {
    //         row.action = "Expended";
    //         this.setState({
    //           isExpand: true,
    //           selectedRow: row,
    //           tableExpended: [row.uniqueno],
    //         });
    //         this.getRoomsById(row.uniqueno);
    //       }
    //     }
    //   );
    // } else {
    //   if(row.uniqueno != 0)
    //   { 
    //     row.action = "Expended";
    //     this.setState({
    //       isExpand: true,
    //       selectedRow: row,
    //       tableExpended: [row.uniqueno],
    //     });
    //     this.getRoomsById(row.uniqueno);
    //   }
      
    // }
  };

  updateRightTableData=(data)=>{
    
    // Use lodash to group and count rooms
    const groupedRooms = _.groupBy(data, 'roomTypeId');

    // Create a new array of objects with Roomtypeid, NumberOfRooms, and Hid
    const _rightTableData = _.map(groupedRooms, (rooms, roomTypeId) => ({
      uniqueno: Number(roomTypeId),
      capacity: rooms?.filter(x=> x.roomID)?.length,
      hid: rooms[0].hid,
      roomType:rooms[0].roomType,
      notes:rooms[0].roomTypeNotes,
    }));


    this.setState({rightTableData:_rightTableData})

  }

  updateRoomTypesList = (data) => {
    data.isDataModified = true;
    let roomTypeData = [...this.state.roomTypeData];
    const roomData = this.state.roomTypeData.filter(
      (r) =>
        moment(r.accountingDate).format("MM/DD/YYYY") ===
        this.state?.currentSelectedDate
    );

    if (roomData.length === 0) {
      roomTypeData.push({
        accountingDate: this.state?.currentSelectedDate,
        roomTypeNew: [],
        isDataModified: true,
      });
    }

    const rightTableData =
      roomTypeData.find(
        (r) =>
          moment(r.accountingDate).format("MM/DD/YYYY") ===
          this.state?.currentSelectedDate
      )?.roomTypeNew || [];
    var index = rightTableData.findIndex(
      (x) => +x.uniqueno === +data?.uniqueno
    );
    if (index < 0 || +data?.uniqueno === 0) {
      rightTableData.push(data);
    } else {
      rightTableData[index] = data;
    }
    roomTypeData = roomTypeData.map((item) => {
      if (
        moment(item.accountingDate).format("MM/DD/YYYY") ===
        this.state?.currentSelectedDate
      ) {
        item.roomTypeNew = rightTableData;
      }
      return item;
    });
    this.props.getRoomsTabModificationStatus(true);

    this.setState({
      roomTypeData,
      rightTableData,
      isRoomTypesArrayModified: true,
    },()=>{
      this.getConferenceRooms()
    });
  };

  updateRoomList =(data) =>{
    data.isDataModified = true;
    let filteredRoomsTableData = [...this.state.filteredRoomsTableData];

    var index = filteredRoomsTableData.findIndex(
      (x) => +x.roomID === +data?.roomID
    );
    if (index < 0 || +data?.roomID === 0) {
      filteredRoomsTableData.push(data);
    } else {
      filteredRoomsTableData[index] = data;
    }
   
    this.props.getRoomsTabModificationStatus(true);

    this.setState({
      filteredRoomsTableData,
      isRoomsArrayModified: true,
    },()=>{
      this.getConferenceRooms()
    });
  }

  updateRoomListExpanded =(data)=>{

    // on save 
    // 1. Update Name of The Room
    // 2. If user changes roomType then 
    //   -- 1. we have to remove that Room from expanded Table
    //   -- 2. we need to add that room in changed Room Type 
    //3. Update count according by + and - 

    data.isDataModified = true;
    // let allRoomsTableData = [...this.state.allRoomsTableData];

    let allRoomsTableData = this.state.roomData.find((r) => {
      return moment(r.accountingDate).format("MM/DD/YYYY") === this.state.currentSelectedDate;
    })?.roomList || [];

    var index = allRoomsTableData.findIndex(
      (x) => +x?.roomID === +data?.roomID
    );
    let prevData:any={}
    if (index < 0 || +data?.roomID === 0) {
      // No Add
    } else {
      prevData=allRoomsTableData[index]
      allRoomsTableData[index] = data;
    }
   

    const currentExpandedId = this.state.tableExpended[0]

    let _filteredRoomById=allRoomsTableData.filter(item => item.roomTypeId == currentExpandedId).filter(x =>x?.roomID)
    _filteredRoomById = _.orderBy(_filteredRoomById, ["roomName"]);
    if(_filteredRoomById?.length === 0)
    {
      allRoomsTableData=[...allRoomsTableData,{...prevData, roomID:null}]
    }

    this.updateRightTableData(allRoomsTableData)
    this.props.getRoomsTabModificationStatus(true);

    this.setState({
      allRoomsTableData:allRoomsTableData.filter(x =>x?.roomID),
      filteredRoomById:_filteredRoomById,
      isExpandedRoomsArrayModified: true,
      tableExpended:_filteredRoomById?.length  == 0 ?[data?.roomTypeId]:[currentExpandedId]
    },()=>{
      this.getConferenceRooms()
    });
  }

  updateBulkChangeRoomType =(data,afterAPICall)=>{
   const _selectedIds=this.state.selectedIds
  //  let RoomsTableData = this.state.currentToggle === "Rooms"  ?[...this.state.filteredRoomsTableData] :[...this.state.filteredRoomById];

   let RoomsTableData = this.state.roomData.find((r) => {
    return moment(r.accountingDate).format("MM/DD/YYYY") === this.state.currentSelectedDate;
  })?.roomList || [];

   RoomsTableData=RoomsTableData.map(item =>{
     if(_selectedIds.includes(item.roomID))
     {
      return {
        ...item,
        isDataModified:true,
        roomTypeId:data.roomTypeId,
        roomType:data.roomType,
        roomNotes:data.roomNotes
        
      }
     }
     return item
   })


   const _filteredRoomsTableData =this.state.currentToggle === "Rooms" ? RoomsTableData: this.state.filteredRoomsTableData
   const _filteredRoomById =this.state.currentToggle === "Types" ? RoomsTableData: this.state.filteredRoomById
   this.setState({allRoomsTableData:RoomsTableData , filteredRoomsTableData: this.state.currentToggle === "Types" ? _filteredRoomById :  _filteredRoomsTableData, filteredRoomById:_filteredRoomById},()=>{
    this.saveRoomTypeBulkData(true,afterAPICall)
   })

   

  }

  saveRoomTypeBulkData = (isFromSaveRoom=false, afterAPICall=()=>{}) => {
    this.setState({ isDataSubmitting: true });
 
    const _rightTableData=this.state.rightTableData.map(x =>({...x,PrevRoomTypeId:x?.uniqueno == -1 || x?.uniqueno == -2 ? x.uniqueno :x?.PrevRoomTypeId ? x?.PrevRoomTypeId : x?.uniqueno}))
    const roomsTableData= this.state.currentToggle == "Types" ? this.state.allRoomsTableData: this.state.filteredRoomsTableData 
    const _filteredRoomsTableData= roomsTableData.map((item:IRoom) =>({
      RoomID: item.roomID,
      RoomName: item.roomName,
      Notes: item?.roomNotes || "",
      RoomStatus: item?.roomStatus || "Online",
      HID: item.hid,
      TenantID: item?.tenantID,
      AccountingDate: this.state?.calendarSelectedDate ? this.state.calendarSelectedDate : item.accountingDate ? item.accountingDate : moment().format("MM/DD/YYYY"),
      RoomTypeId: item?.roomTypeId 
    }))
    EntityManagementService.SaveRoomType(
      _rightTableData,
      this.state.deletedRoomIds,
      this.props.entityRowData?.hotelId,
      this.state.calendarSelectedDate,
      this.state.currentSelectedDate || moment().format("MM/DD/YYYY"),
      _filteredRoomsTableData
    )
      .then((result) => {
        this.setState({ isDataSubmitting: false, deletedRoomIds: [] });
        afterAPICall()
        if (result?.message === "Success") {
          this.props?.isReloadRequired(true);
          this.GetAllRooms(true);
          this.props.getRoomsTabModificationStatus(false);
          this.setState({ isRoomTypesArrayModified: false });
          if(isFromSaveRoom)
          {
            toast.success("Rooms saved successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addGarnishment",
            });
          }
          else { toast.success("Room types saved successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });}
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSubmitting: false, deletedRoomIds: [] });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      });
  };

  saveRoomBulkData = (afterAPICall=()=>{}, isSaveRoomType=false) => {
    this.setState({ isDataSubmitting: true });

    const {isExpandedRoomsArrayModified,isRoomsArrayModified,currentSelectedDate,calendarSelectedDate}=this.state

    const _calendarSelectedDate= moment(calendarSelectedDate).format("MM/DD/YYYY")
    const _currentSelectedDate= moment(currentSelectedDate).format("MM/DD/YYYY")


    const roomsTableData= this.state.currentToggle == "Types" ? this.state.allRoomsTableData: this.state.filteredRoomsTableData 
    const _filteredRoomsTableData= roomsTableData.filter(x=>x.roomID >=0 && x.isDataModified).map((item:IRoom) =>({
      RoomID: item.roomID,
      RoomName: item.roomName,
      Notes: item?.roomNotes || "",
      RoomStatus: item?.roomStatus || "Online",
      HID: item.hid,
      TenantID: item?.tenantID,
      AccountingDate: this.state?.calendarSelectedDate ? this.state.calendarSelectedDate : item.accountingDate ? item.accountingDate : moment().format("MM/DD/YYYY"),
      
    }))
   
    EntityManagementService.SaveBulkRoom(
      _filteredRoomsTableData
    )
      .then((result) => {
        this.setState({ isDataSubmitting: false });
        afterAPICall()
        if (result?.message === "Success") {
          if(isSaveRoomType){
            this.saveRoomTypeBulkData(true)
          }
          else{
            this.props?.isReloadRequired(true);
            this.GetAllRooms(true);
            this.props.getRoomsTabModificationStatus(false);
            toast.success("Rooms saved successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addGarnishment",
            });
          }
          this.setState({ isRoomsArrayModified: false, isExpandedRoomsArrayModified:false });
         
        } else {
          Utils.toastError(result?.result, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addGarnishment",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSubmitting: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        });
      });
  };

  showSaveModalClick = () => {
    this.setState({
      showSaveModal: true,
      calendarSelectedDate:
        this.state.currentSelectedDate || moment().format("MM/DD/YYYY"),
    }, ()=>{
     
    });
  };

  hideSaveModal = () => {
    this.setState({ showSaveModal: false });
  };

  handleShowRoomConfigurationModal = () => {
    this.setState({ showRoomConfigurationModal: true });
  };

  handleHideRoomConfigurationModal = (isReload: boolean = false) => {
    this.setState({ showRoomConfigurationModal: false });
  };

  filterRooms =()=>{
    const {selectedRoomTypes,selectedRoomCategory,status,allRoomsTableData}=this.state
  

    let _allRoomsTableData=_.cloneDeep(allRoomsTableData)
    if(selectedRoomTypes?.length > 0)
    {
      _allRoomsTableData=_allRoomsTableData.filter(item => selectedRoomTypes.map(x =>x.value).includes(item.roomTypeId))
    }

    if(selectedRoomCategory?.length > 0)
    {
      _allRoomsTableData=_allRoomsTableData.filter(item => selectedRoomCategory.map(x =>x.value.toLowerCase()).includes(item.category.toLowerCase()))
    }

    if(status)
    { 
      if(status == "All")
      {
        _allRoomsTableData=_allRoomsTableData.filter(item => item.roomStatus === "Online" ||  item.roomStatus === "Offline" )
      }
      else{
        _allRoomsTableData=_allRoomsTableData.filter(item =>  status == item.roomStatus)
      }
    }

    if (this.state.searchText != "") {
      if(this.state.currentToggle === "Rooms")
      {
        _allRoomsTableData = _allRoomsTableData.filter(
          (x) =>
            x.roomName
              ?.toLowerCase()
              .includes(this.state.searchText.toLowerCase())
        );
      }
    }

    this.setState({filteredRoomsTableData:_allRoomsTableData})
  }

 

  CleanSearch = () => {
    if (this.tableSearch.current !== null)
      this.tableSearch.current.CleanSearch();
  };

  editRoomType = (row) => {
    this.setState({ roomTypeRowEdit: row }, () => {
      this.setState({ showAddRoomTypeModal: true });
    });
  };

  searchData =()=>{
   
      if (this.state.searchText != "") {
        if(this.state.currentToggle === "Rooms")
        {
          this.filterRooms()
        }
        else{
        //Room Types
        let _rightTableData = this.state.roomTypeData.find((r) => {
          return moment(r.accountingDate).format("MM/DD/YYYY") === this.state.currentSelectedDate;
        })?.roomTypeNew;


        _rightTableData=AddConferenceOtherRoomsTypes(_rightTableData)

        _rightTableData = _rightTableData.filter(
          (x) =>
            x.roomType
              ?.toLowerCase()
              .includes(this.state.searchText.toLowerCase())
        );

        this.setState({rightTableData:_rightTableData})

        }
      }
      else{
        let _rightTableData = this.state.roomTypeData.find((r) => {
          return moment(r.accountingDate).format("MM/DD/YYYY") === this.state.currentSelectedDate;
        })?.roomTypeNew;

       
        _rightTableData=AddConferenceOtherRoomsTypes(_rightTableData)
        let _allRoomsTableData=_.cloneDeep(this.state.allRoomsTableData)

        this.setState({rightTableData:_rightTableData,filteredRoomsTableData:_allRoomsTableData})
      }
   
  }

  handleSearch =(e)=>{
    const searchText= e.target.value 
    this.setState({ searchText: searchText },()=>{
      this.searchData()
    });
  }
  

  handleOnSave =()=>{

    const value = moment().format("MM/DD/YYYY");
    
    let CalendarDate
     const {leftPanelDates,calendarSelectedDate,isExpandedRoomsArrayModified,isRoomsArrayModified}=this.state
     if(calendarSelectedDate === ""){
      CalendarDate=value
     }
     else{
      CalendarDate=calendarSelectedDate
     }

     this.setState({calendarSelectedDate:CalendarDate},()=>{
      if (
        leftPanelDates.find((x) => x.date === calendarSelectedDate)
      ) {
        this.hideSaveModal();
        this.overWriteChanges();
      } else {
        const _rightTableData = this.state.rightTableData.map(
          (x) => ({
            ...x,
            PrevRoomTypeId:x.uniqueno,
            uniqueno: x.uniqueno  == -1  || x.uniqueno == -2  ? x.uniqueno: 0,
            accountingDate: calendarSelectedDate,
          })
        );

        const _filteredRoomsTableData = this.state.filteredRoomsTableData.map(
          (x) => ({
            ...x,
            // roomID: 0,
            // isDataModified:true,
            accountingDate: calendarSelectedDate,
          })
        );
        this.setState({ rightTableData: _rightTableData, filteredRoomsTableData:_filteredRoomsTableData },async () => {
          if(isExpandedRoomsArrayModified || isRoomsArrayModified)
          {
            // await this.saveRoomBulkData(()=>{}, true)
            await this.saveRoomTypeBulkData(true)
           
          }
          else{
            await this.saveRoomTypeBulkData();
          }
        
          this.hideSaveModal();
        });
      }
     })


     
 
  }



  handleBulkAction =(isBulkAction)=>{
    this.setState({isBulkAction})
  }

  handleSelectedIds =(ids)=>{
    this.setState({selectedIds:ids})
  }

  handleCalendarDateChange = (value) => {
    const _formattedDate = Utils.AddSlashINInputDateFormat(
      value,
      this.state.calendarSelectedDate,
      false,
      4,
      false
    );
    if (_formattedDate === "invalid") {
      return;
    } else {
      value = _formattedDate;
    }

    this.setState({ calendarSelectedDate: value });
  };

  handleDateChange = () => {
    const { currentSelectedDate ,conferenceRoomData,otherRoomData} = this.state;
    // let selectedRow: Array<any> = [];

    console.log(this.state.roomTypeData);
    const rightTableData = this.state.roomTypeData.find(
      (r) =>
        moment(r.accountingDate).format("MM/DD/YYYY") === currentSelectedDate
    )?.roomTypeNew;

    const allRoomsTableData = this.state.roomData.find((r) => {
      return moment(r.accountingDate).format("MM/DD/YYYY") ===  this.state?.currentSelectedDate;
    })?.roomList?.filter(item => item.roomID);

    if(!rightTableData.find(x => x.uniqueno ==  -1))
    {
      rightTableData.push({
        uniqueno:-1,
        roomType:"Conference Rooms",
        capacity:conferenceRoomData.length,
        notes:""
      })
    }
    
    if(!rightTableData.find(x => x.uniqueno ==  -2))
    {
      rightTableData.push({
        uniqueno:-2,
        roomType:"Other Rooms",
        capacity:otherRoomData.length,
        notes:""
      })
    }
    
   


    let RoomTypeDropdownList = currentSelectedDate ?  this.state.roomTypeData.find(
      (r) =>
        moment(r.accountingDate).format("MM/DD/YYYY") === currentSelectedDate
    )?.roomTypeNew?.map(x=> ({...x, value:x.uniqueno, description:x.roomType,  isChecked:false})) : [];

    RoomTypeDropdownList=AddConferenceOtherROomsDropdownList(RoomTypeDropdownList)

    RoomTypeDropdownList= _.orderBy(RoomTypeDropdownList, ["description"])

    let isDataModified =
      rightTableData?.filter((x) => x?.isDataModified)?.length > 0;

    //  isDataModified = this.state.roomTypeData.filter(
    //   (r) => moment(r.accountingDate).format("MM/DD/YYYY") === currentSelectedDate && r?.isDataModified
    // )?.length > 0

    // selectedRow.push(currentSelectedDate);
    this.props.getRoomsTabModificationStatus(isDataModified);

    this.setState({
      rightTableData,
      allRoomsTableData,
      filteredRoomsTableData:allRoomsTableData,
      RoomTypeDropdownList:RoomTypeDropdownList,
      isRoomTypesArrayModified: isDataModified ? true : false,
    },()=> this.filterRooms());
  };

 

  sortData = (sortBy) => {
    let rightTableData = _.orderBy(this.state.rightTableData, [sortBy]);
    if (sortBy === "roomType") {
      this.setState({ SortBy: "Name" });
    }
    

    this.setState({ rightTableData: rightTableData });
  };

  overWriteChanges = () => {
    const {isExpandedRoomsArrayModified,isRoomsArrayModified,currentSelectedDate,calendarSelectedDate}=this.state

    const _calendarSelectedDate= moment(calendarSelectedDate).format("MM/DD/YYYY")
    const _currentSelectedDate= moment(currentSelectedDate).format("MM/DD/YYYY")


    const rightTableData = this.state.roomTypeData.find(
      (r) =>
        moment(r.accountingDate).format("MM/DD/YYYY") === calendarSelectedDate
    )?.roomTypeNew;


    if(_calendarSelectedDate !== _currentSelectedDate)
    {
      this.setState({deletedRoomIds:rightTableData?.length > 0 ?  rightTableData.map(x =>x?.uniqueno) : []})
    }
    
    
    confirmAlert({
      title:
        "There is an existing room configuration set for this date. Saving will overwrite changes to the existing room configuration. Are you sure you want to continue?",
      message: "",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const _rightTableData = this.state.rightTableData.map((x) => ({
              ...x,
              PrevRoomTypeId:x.uniqueno,
              uniqueno: x.uniqueno  == -1  || x.uniqueno == -2  ? x.uniqueno:  _calendarSelectedDate == _currentSelectedDate ? x.uniqueno : 0,
              accountingDate: this.state.calendarSelectedDate,
            }));
            this.setState({ rightTableData: _rightTableData }, () => {
              (isExpandedRoomsArrayModified || isRoomsArrayModified) ?  this.saveRoomTypeBulkData(true):this.saveRoomTypeBulkData();
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            this.setState({ showSaveModal: true });
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "rooms-alert",
    });
  };

  discardChanges = () => {
    confirmAlert({
      title:
        "Are you sure you want to discard? You will lose any unsaved progress.",
      message: "",
      buttons: [
        {
          label: "No",
          onClick: () => {},
        },
        {
          label: "Yes",
          onClick: () => {
            this.discard();
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "rooms-alert",
    });
  };

  onCalendarDateSelect = (date) => {
    
    const leftPanelDates = [...this.state?.leftPanelDates];
    if (
      !leftPanelDates.find((x) => x.date == date) &&
      leftPanelDates?.filter(
        (item) =>
          moment(item.date).format("MM/YYYY") === moment(date).format("MM/YYYY")
      )?.length >= 2
    ) {
      Utils.toastError(
        "Maximum two accounting dates are allowed for the hotel room type.",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "addGarnishment",
        }
      );
      return;
    }
    this.setState({ calendarSelectedDate: date });
  };

  resetFilters=()=>{
    this.setState({isMountDone:true, searchText:""}, ()=>{
      this.searchData()
    })
   
    setTimeout(()=>{
      if(this?.roomCategoryRef)
      {
        this.roomCategoryRef?.resetMultiSelectDropDown()
      }
  
      if(this.roomTypeRef){
        this.roomTypeRef?.resetMultiSelectDropDown()
      }
  
      if(this.tableSearch)
      {
        this.CleanSearch()
        
      }
    },0)


    this.setState({
      status:"Active",
      SortBy: "Name",
      selectedRoomCategory:[],
      selectedRoomTypes:[],
      // toggleLoading:false,
      selectedRow: {},
      isExpand: false,
      tableExpended: [],
      selectedIds:[]
    }, ()=>this.filterRooms())
  
  }

  handleToggleClick = (toggle) => {
    this.setState({ currentToggle: toggle , isMountDone:false},()=>{
      this.resetFilters()
    });
  };

  render() {
    const rowClasses = (row: any, rowIndex: any) => {
      if(row.capacity <= 0 || (this.state.selectedIds.length > 0 || this.state.isExpandedRoomsArrayModified) && (this.state.tableExpended?.length > 0 && this.state?.tableExpended[0] !== row?.uniqueno) ){
        return "row-not-expandable row-Action"
      }
      if (this.state.isExpand === true && this.state.tableExpended?.length > 0 && this.state?.tableExpended[0] == row.uniqueno) {
        return "row-Expended";
      } else if (row.approveButtonSpinner) {
        return "p-none";
      } else if (this.state.isExpand === true) {
        return "row-Collapsed";
      } else {
        return "row-Action";
      }
    };

    const {
      leftPanelLoader,
      roomTypeData,
      rightTableData,
      leftPanelDates,
      currentSelectedDate,
      showRoomConfigurationModal,
      showAddRoomTypeModal,
      calenderStartDateShow,
      conferenceRoomRowEdit,
      conferenceRoomData,
      isDataSubmitting,
      showAddCoferenceRoomModal,
      showAddOtherRoomModal,
      otherRoomData,
      otherRoomRowEdit,
      isRoomTypesArrayModified,
      hideConferenceSection,
      otherRooms,
      isConferenceLoading,
      isRoomTypeLoading,
      toggleLoading,
      showSaveModal,
      calendarSelectedDate,
      currentToggle,
      status,
      RoomTypeDropdownList,
      roomRowEdit,
      showAddRoomModal,
      allRoomsTableData,
      filteredRoomsTableData,
      isMountDone,
      showTakeRoomOffline,
      isBulkAction,
      showChangeRoomTypeModal,
      isRoomsArrayModified,
      isExpandedRoomsArrayModified
    } = this.state;

    const roomTypeGridColumns: any[] = [
      {
        dataField: "roomType",
        text: "Name",
     
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-name">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
        footer: () => {
          return <div>Total Room Count</div>;
        },
      },
      {
        dataField: "capacity",
        text: "Count",
        searchable:false,
        footer: (columnData: any[]) => {
          const total = columnData.reduce((a: number, b: number) => +a + +b, 0);
          return <div>{total}</div>;
        },
      },

      {
        dataField: "notes",
        text: "Notes",
        searchable:false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-notes">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
        footer: () => {
          return <div>{""}</div>;
        },
      },
      {
        dataField: "",
        text: "",
        classes: "text-right",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <Dropdown
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            className="more-action"
            style={{ position: "static" }}
            alignRight
          >
            <Dropdown.Toggle
              disabled={!this.props?.hasEditPermission || +row?.uniqueno <= 0}
              className="btn-outline-primary btn btn-primary btn-ghost more"
              id="dropdown-more"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {+row?.uniqueno > 0 && (
                <Dropdown.Item
                  key={"edit"}
                  eventKey={"edit"}
                  onClick={() => this.editRoomType(row)}
                >
                  Edit
                </Dropdown.Item>
              )}

              {/* <Dropdown.Item Dropdown.Item
                key={"delete"}
                eventKey={"delete"}
                onClick={() => this.deleteRowData(row)}
              >
                Delete
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        ),
        footer: () => {
          return <div>{""}</div>;
        },
      },
    ];



    const roomGridColumns: any []=[
      {
        dataField: "roomName",
        text: "Room Name",
     
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-name">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
            {currentToggle === "Rooms"? row.roomStatus !== "Online" && <div className="badge">{row.roomStatus}</div>:<></>}
          </div>
        ),
       
      },
      {
        dataField: "roomStatus",
        text: "Status",
        searchable:false,
        hidden: currentToggle === "Rooms" ? true:false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-notes">
            <span className={`badge ${row.roomStatus == "Online" ? "color-green":"color-grey"}`}>
              {row.roomStatus == "Online" ? "Active":"Inactive"}
            </span>
           
          </div>
        ),
      },
      {
        dataField: "roomType",
        text: "Room Type",
        searchable:false,
        hidden: currentToggle === "Rooms" ? false:true,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-notes ">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
      },

      {
        dataField: "category",
        text: "Category",
        searchable:false,
        hidden: currentToggle === "Rooms" ? false:true,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-notes text-capitalize">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
        
      },
      {
        dataField: "roomNotes",
        text: "Notes",
        searchable:false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-notes">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
      
      },
      {
        dataField: "",
        text: "",
        classes: "text-right",
        formatter: (cell: any, row: any, rowIndex: any, extraData) => {
       return <ExpanedDropdown 
        cell={cell}  
        row={row} 
        rowIndex={rowIndex}
        hasEditPermission={this.props?.hasEditPermission}
        currentSelectedDate={currentSelectedDate}
        hotelId={this.props?.entityRowData?.hotelId}
        isBulkAction={extraData.isBulkAction}
        // updateRoomList={this.updateRoomListExpanded}
        updateRoomList={(data)=> extraData.currentToggle === "Rooms" ? this.updateRoomList(data) :  this.updateRoomListExpanded(data)}
        RoomTypesList={RoomTypeDropdownList}

        />
        //   <div>
        //   <Dropdown
        //     // onClick={(e) => {
        //     //   e.stopPropagation();
        //     // }}
        //     className="more-action"
        //     style={{ position: "static" }}
        //     alignRight
        //   >
        //     <Dropdown.Toggle
        //       disabled={!this.props?.hasEditPermission || +row?.roomID <= 0}
        //       className="btn-outline-primary btn btn-primary btn-ghost more"
        //       id="dropdown-more"
        //     >
        //       <svg
        //         width="20"
        //         height="20"
        //         viewBox="0 0 20 20"
        //         fill="none"
        //         xmlns="http://www.w3.org/2000/svg"
        //       >
        //         <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
        //         <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
        //         <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
        //       </svg>
        //     </Dropdown.Toggle>
        //     <Dropdown.Menu>
        //       {+row?.roomID > 0 && (
        //         <Dropdown.Item
        //           key={"edit"}
        //           eventKey={"edit"}
        //           onClick={() => this.editRoom(row)}
        //         >
        //           Edit
        //         </Dropdown.Item>
        //       )}

        //       {+row?.roomID > 0 && (
        //         <Dropdown.Item
        //           key={"edit"}
        //           eventKey={"edit"}
        //           onClick={() => this.handleShowTakeRoomOfflineModal(row)}
        //         >
        //           Take Offline
        //         </Dropdown.Item>
        //       )}
        //       {/* <Dropdown.Item Dropdown.Item
        //         key={"delete"}
        //         eventKey={"delete"}
        //         onClick={() => this.deleteRowData(row)}
        //       >
        //         Delete
        //       </Dropdown.Item> */}
        //     </Dropdown.Menu>
        //   </Dropdown>


          
        // {currentToggle !== "Rooms"  && showAddRoomModal && (
        //   <AddRoom
        //     date={currentSelectedDate}
        //     roomRowEdit={this.state.roomRowEdit}
        //     hotelId={this.props?.entityRowData?.hotelId}
        //     showAddRoomModal={showAddRoomModal}
        //     handleHideRoomModal={this.handleHideRoomModal}
        //     updateRoomList={(data)=> currentToggle === "Rooms" ? this.updateRoomList(data) :  this.updateRoomListExpanded(data)}
        //     RoomTypesList={RoomTypeDropdownList}
        //   />
        // )} 
        
        //   </div>
          
        },
        formatExtraData: {isBulkAction,currentToggle}
      
      },
    ]

    
    const expandedRoomGridColumns: any []=[
      {
        dataField: "roomName",
        text: "Name",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-name">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
            {currentToggle === "Rooms"? row.roomStatus !== "Online" && <div>{row.roomStatus}</div>:<></>}
          </div>
        ),
       
      },
      {
        dataField: "roomNotes",
        text: "Notes",
        searchable:false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-notes">
            <EllipsisWithTooltip placement="bottom">{cell}</EllipsisWithTooltip>
          </div>
        ),
      
      },
      {
        dataField: "roomStatus",
        text: "Status",
        searchable:false,
        hidden: currentToggle === "Rooms" ? true:false,
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="room-type-notes">
            <span className={`badge ${row.roomStatus == "Online" ? "color-green":"color-grey"}`}>
              {row.roomStatus == "Online" ? "Active":"Inactive"}
            </span>
           
          </div>
        ),
      },
      {
        dataField: "",
        text: "",
        classes: "text-right",
        formatter: (cell: any, row: any, rowIndex: any, extraData) => {
       return <ExpanedDropdown 
        cell={cell}  
        row={row} 
        rowIndex={rowIndex}
        hasEditPermission={this.props?.hasEditPermission}
        currentSelectedDate={currentSelectedDate}
        hotelId={this.props?.entityRowData?.hotelId}
        isBulkAction={extraData.isBulkAction}
        // updateRoomList={this.updateRoomListExpanded}
        updateRoomList={(data)=> extraData.currentToggle === "Rooms" ? this.updateRoomList(data) :  this.updateRoomListExpanded(data)}
        RoomTypesList={RoomTypeDropdownList}
        />
        },
        formatExtraData: {isBulkAction,currentToggle}
      
      },
    ]

    const expandRow = {
     
      renderer: (row: any) => (
        <div className={`rowExpended position-relative ${(isExpandedRoomsArrayModified) && this.props?.hasEditPermission ? "save-baner-open" : ""}`} id="laborDayDetails">
           {this.state.filteredRoomById?.length > 0 && (<RoomsListTable 
                  isRoomsArrayModified={isExpandedRoomsArrayModified}
                  handleBulkAction={this.handleBulkAction}
                  handleSelectedIds={this.handleSelectedIds}
                  showChangeRoomTypeModal={this.BulkShowChangeRoomTypeModal}
                  showTakeRoomOfflineModal={this.BulkShowTakeRoomOfflineModal}
                  columns={expandedRoomGridColumns}
                  data={this.state.filteredRoomById}
                  hasEditPermission={this.props?.hasEditPermission}
              />)}


            {(isExpandedRoomsArrayModified) && this.props?.hasEditPermission && (
                              <div className="fixed-action ml-auto w-100">
                                <div className="d-flex align-content-center flex-wrap">
                                  <div className="mr-auto message">
                                    {rightTableData?.length > 0 && (
                                      <span>You have made changes to the room configuration.</span>
                                    )}
                                  </div>
                                  <button
                                    disabled={isDataSubmitting}
                                    type="button"
                                    className="btn btn-primary btn-discard"
                                    onClick={() => this.discardChanges()}
                                  >
                                    Discard
                                  </button>

                                  <button
                                    disabled={isDataSubmitting}
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.showSaveModalClick()}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            )}
        </div>
      ),
      expanded: this.state.tableExpended,
      onExpand: this.handleOnExpand,
      showExpandColumn: true,
      onlyOneExpanding: true,
      expandByColumnOnly: true,
      nonExpandable:[0, ...(this.state.rightTableData.filter(x => x.capacity <= 0).map(x => x.uniqueno)), ...((this.state.selectedIds.length > 0 || this.state.isExpandedRoomsArrayModified) ? this.state.rightTableData.filter(x => x.uniqueno !== this.state.tableExpended[0]).map(x => x.uniqueno) : [])],
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if(isAnyExpands) {
          return " "
        }
      },
      expandColumnRenderer: ({expanded}) => {
       
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

  

  
   

    const leftPanelDatesSorted = _.orderBy(
      leftPanelDates,
      (item) => new Date(item.date)
    );
    


     const isDisabled= isRoomTypesArrayModified || isRoomsArrayModified || isBulkAction

  
  
   
    return (
      // <div
      //   className={`body-section pt-0 room-table-wrapper ${
      //     isRoomTypesArrayModified &&
      //     this.props?.hasEditPermission &&
      //     "pb-5 mb-3"
      //   }`}
      // >
      <div className="body-section pt-0 room-table-wrapper">
        <div>
          <div className="main-Page">
            <div className="bdy-sec mt-0">
              
             {
             (isMountDone && <ToolkitProvider
                keyField="roomType"
                data={currentToggle === "Rooms" ? filteredRoomsTableData:rightTableData}
                columns={currentToggle === "Rooms"  ?  roomGridColumns:roomTypeGridColumns}
                ref={this.toolkit}
                search
                hover
              >
                {(props: {
                  searchProps: JSX.IntrinsicAttributes;
                  baseProps: any;
                }) => (
                  <div>
                    <div className="search-header d-flex mt-0"></div>
                    
                      <div className={` ${
                                currentToggle === "Rooms" ? "navigation-wrapper" : ""
                              }`}>
                        
                        <div className="navigation-menus d-flex align-items-center mt-0">
                          <div className="search-bar">
                            {/* <TableSearch
                              id="tableSearch"
                              key="tableSearch"
                              ref={this.tableSearch}
                              {...props.searchProps}
                              CleanSearch={this.CleanSearch.bind(this)}
                              placeholder={"Search"}
                              disabled={isDisabled}
                            /> */}
                              <input
                                type="text"
                                className="form-control "
                                placeholder="Search..."
                                value={this.state.searchText}
                                onChange={(e) => this.handleSearch(e)}
                              />
                          </div>
                          <div>
                            <Form.Group
                              controlId="date-selection"
                              className={"d-block ml-1 mb-0"}
                            >
                              {/* <Form.Label>OverTime Rate</Form.Label> */}
                              <div
                                className="validate-input date-selector"
                                id="date-selector-dropdown"
                              >
                                {leftPanelDatesSorted.length > 0 && (
                                  <DateWithYearDropdown
                                    disabled={isDisabled}
                                    data={leftPanelDatesSorted}
                                    defaultValue={currentSelectedDate}
                                    isSearchRequired={true}
                                    label={"date"}
                                    value={"date"}
                                    dependent={currentSelectedDate}
                                    onDropDownChange={(item) => {
                                      if (!item) {
                                        return;
                                      }

                                      this.setState(
                                        { currentSelectedDate: item.date },
                                        () => {
                                          this.handleDateChange();
                                        }
                                      );
                                    }}
                                    selectedItem={
                                      [...leftPanelDatesSorted].find(
                                        (r: any) => r?.date == currentSelectedDate
                                      ) || {
                                        date: "Select date",
                                      }
                                    }
                                  />
                                )}

                                {/* {leftPanelDatesSorted.length > 0 &&<DropDownList
                                        placeHolder={"Search Date"}
                                        data={leftPanelDatesSorted}
                                        defaultValue={currentSelectedDate}
                                        isSearchRequired={true}
                                        label={"date"}
                                        value={"date"}
                                        dependent={currentSelectedDate}
                                        onDropDownChange={(item) => {
                                          if(!item){
                                            return
                                          }

                                          this.setState({currentSelectedDate:item.date},()=>{
                                            this.handleDateChange()
                                          })
                                        }
                                        }
                                        selectedItem={
                                          [
                                          
                                            ...leftPanelDatesSorted
                                          ].find((r: any) => r?.date  == currentSelectedDate) || {
                                            date: "Select date",
                                          
                                          }
                                        }
                                      />} */}
                              </div>
                            </Form.Group>
                          </div>

                        
                          { currentToggle === "Rooms" &&<>

                          {RoomTypeDropdownList?.length > 0 &&   <div className="dropdown-design room-type-dropdown">
                              <MultiSelectDropdown
                                  ref={val => this.roomTypeRef =val}
                                  key="roomTypeDropdown"
                                  dataChange={()=>{}}
                                  showLength={1}
                                  // disabled={isBulkAction}
                                  itemList={RoomTypeDropdownList?.length > 0 ? RoomTypeDropdownList : []}
                                  updateMultiSelectItem={(item) => {
                                    if (!item) {
                                      return;
                                    }
                                    this.setState({selectedRoomTypes:item}, ()=>this.filterRooms())
                                    // setSelectedDeductionType(item)
                                    // getUpdatedItemListDeduction(item)
                                    
                                  }}
                                  disabled={isDisabled}
                                  isSelectAll={true}
                                  removeItem
                                  placeholder={"Room Type"}
                                  filtertitle={"Select Room Type"}

                                  />
                          </div> }


                          {RoomCategoryList?.length > 0 &&   <div className="dropdown-design room-category-dropdown">
                            <MultiSelectDropdown
                                  ref={val => this.roomCategoryRef =val}
                                  key="roomCategory"
                                  dataChange={()=>{}}
                                  showLength={1}
                                  // disabled={isBulkAction}
                                  disabled={isDisabled}
                                  itemList={RoomCategoryList?.length > 0 ? RoomCategoryList : []}
                                  updateMultiSelectItem={(item) => {
                                    if (!item) {
                                      return;
                                    }
                                    this.setState({selectedRoomCategory:item},()=>this.filterRooms())
                                    // setSelectedDeductionType(item)
                                    // getUpdatedItemListDeduction(item)
                                    
                                  }}
                                  isSelectAll={true}
                                  removeItem
                                  placeholder={"Room Category"}
                                  filtertitle={"Select Room Category"}

                                  />
                          </div> }


                          <div className={`status-dropdown ${isDisabled && "disabled"}`}>
                              <DropDownList
                                disabled={isDisabled}
                                  placeHolder={"Status"}
                                  data={statusList}
                                  defaultValue={status}
                                  isSearchRequired={false}
                                  label={"label"}
                                  value={"value"}
                                  onDropDownChange={(item) => {
                                    if (!item) {
                                      return;
                                    }
                                    this.setState({status:item.value},()=>this.filterRooms())
                                  }}
                                  selectedItem={[
                                    ...[{ label: "Status" , value: ''}],
                                    ...statusList,
                                  ].find((r: any) => r?.value === status) || { label: "Status" , value: ''}}
                                />
                          </div>

                          </>}
                          {/* <div className="navigationSort ml-auto">
                            <Dropdown className="more-action bg-blue">
                              <Dropdown.Toggle id="dropdown-ptoType"  disabled={isRoomTypesArrayModified}>
                                <div className="d-flex align-items-center">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
                                  </svg>
                                  {this.state.SortBy}
                                  <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {SortByList.map((item) => (
                                  <Dropdown.Item
                                    key={item.label}
                                    eventKey={item.label}
                                    className={
                                      this.state.SortBy === item.label
                                        ? "active"
                                        : ""
                                    }
                                    onClick={(e: any) => this.sortData(item.value)}
                                  >
                                    <div className="d-flex">
                                      <div className="mr-auto">{item.label}</div>
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                          fill="#2CAF92"
                                        />
                                      </svg>
                                    </div>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu> 
                            </Dropdown>

                          
                          </div> */}

                          

                          <div className="types-tabs ml-auto">
                            <button
                              disabled={isDisabled}
                              onClick={() => this.handleToggleClick("Types")}
                              className={` ${
                                currentToggle === "Types" ? "Active" : ""
                              } btn `}
                            >
                              Types
                            </button>
                          {leftPanelDatesSorted.length == 0 ? <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={"rooms-btn"}>Please Add Room Type</Tooltip>
                              }
                            >
                              <button
                            disabled={isDisabled || leftPanelDatesSorted.length == 0}
                              onClick={() => this.handleToggleClick("Rooms")}
                              className={` ${
                                currentToggle === "Rooms" ? "Active" : ""
                              } btn `}
                            >
                              Rooms
                            </button>

                            </OverlayTrigger> :
                            <button
                            disabled={isDisabled || leftPanelDatesSorted.length == 0}
                              onClick={() => this.handleToggleClick("Rooms")}
                              className={` ${
                                currentToggle === "Rooms" ? "Active" : ""
                              } btn `}
                            >
                              Rooms
                            </button>
                            }
                          
                            {/* <p>Please Add Room Type</p> */}
                          </div>
                          <div className="navigationSettings">
                            <Dropdown className="more-action bg-blue">
                              <Dropdown.Toggle id="dropdown-ptoType"  disabled={isDisabled}>
                                <div className="d-flex align-items-center">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M1.56286 10.1808L2.89619 12.4875C3.08019 12.8062 3.48819 12.9155 3.80686 12.7308L4.73752 12.1935C5.12419 12.4982 5.55152 12.7482 6.00086 12.9348V14.0002C6.00086 14.3682 6.29886 14.6668 6.66752 14.6668H9.33419C9.70286 14.6668 10.0009 14.3682 10.0009 14.0002V12.9348C10.4502 12.7482 10.8769 12.4982 11.2642 12.1935L12.1949 12.7308C12.5129 12.9142 12.9222 12.8042 13.1055 12.4875L14.4389 10.1808C14.6222 9.8635 14.5122 9.4535 14.1955 9.27016L13.2809 8.7415C13.3155 8.4955 13.3335 8.2475 13.3335 8.00016C13.3335 7.75283 13.3155 7.50483 13.2795 7.25883L14.1942 6.73016C14.5115 6.54683 14.6215 6.13683 14.4375 5.8195L13.1042 3.51283C12.9202 3.19416 12.5122 3.08483 12.1935 3.2695L11.2629 3.80683C10.8762 3.50216 10.4495 3.2515 10.0002 3.0655V2.00016C10.0002 1.63216 9.70219 1.3335 9.33352 1.3335H6.66686C6.29819 1.3335 6.00019 1.63216 6.00019 2.00016V3.0655C5.55086 3.25216 5.12419 3.50216 4.73686 3.80683L3.80686 3.2695C3.48752 3.08483 3.08019 3.19416 2.89619 3.51283L1.56286 5.8195C1.37953 6.13683 1.48953 6.54683 1.80619 6.73016L2.72086 7.25883C2.68486 7.50483 2.66686 7.75283 2.66686 8.00016C2.66686 8.2475 2.68486 8.4955 2.72086 8.7415L1.80619 9.27016C1.48886 9.4535 1.37886 9.8635 1.56286 10.1808ZM8.00019 5.3335C9.47086 5.3335 10.6669 6.5295 10.6669 8.00016C10.6669 9.47083 9.47086 10.6668 8.00019 10.6668C6.52952 10.6668 5.33352 9.47083 5.33352 8.00016C5.33352 6.5295 6.52952 5.3335 8.00019 5.3335Z" />
                                  </svg>
                                  <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="heading mb-0">Customer Rooms</div>

                    {
                      
                   
                      this.props?.hasEditPermission && (
                        <Button
                          disabled={isBulkAction || (currentToggle === "Rooms" && RoomTypeDropdownList.length == 2)}
                          className={`add-room-type mt-2 ml-3 h-auto ${isBulkAction || (currentToggle === "Rooms" && RoomTypeDropdownList.length == 2) && "disabled"} ${isBulkAction || (currentToggle === "Rooms" && RoomTypeDropdownList.length == 2) ? "disabled" : ""}`}
                          onClick={() => currentToggle === "Types"? this.handleShowRoomTypeModal(): this.handleRoomModal()}
                        >
                          {currentToggle === "Types" ? "Add Room Type" : "Create New Room"}
                        </Button>


                      )
                    }
                        
                    </div>
                    { <>
                    {currentToggle === "Types" ? (
                      <>
                        {isRoomTypeLoading ? (
                          <ReactPageLoader useas={"entity-rooms"} />
                        ) : (
                       (this.state.searchText?.length > 0 && rightTableData.length > 0) || rightTableData.filter(x=>x.uniqueno != -2 && x.uniqueno != -1).length > 0  ?
                          <>
                          {/* <div id="room-table">
                            {props.baseProps?.data.length > 0 ? (
                              <div className={`hasCursorPointer types-table hide-header-scrollbar ${rightTableData?.length >= 7 ? "add-scrollbar overflow-auto" : ""}`}>
                                <BootstrapTable
                                  id={"uniqueno"}
                                  {...props.baseProps}
                                  keyField="uniqueno"
                                  expandRow={expandRow}
                                  rowClasses={rowClasses}
                                  hover
                                />
                              </div> 
                            ) : <h6 className="pt-3">No Data Found</h6>}
                          
                          </div> */}
                          <div id="room-table">
                            {props.baseProps?.data.filter(x=>x.uniqueno != -2 && x.uniqueno != -1).length > 0 ? (
                              <div className={`hasCursorPointer types-table hide-header-scrollbar ${rightTableData?.length >= 7 ? "add-scrollbar overflow-auto" : ""}`}>
                                <BootstrapTable
                                  id={"uniqueno"}
                                  {...props.baseProps}
                                  data={props.baseProps?.data.filter(x=>x.uniqueno != -2 && x.uniqueno != -1)}
                                  keyField="uniqueno"
                                  expandRow={expandRow}
                                  rowClasses={rowClasses}
                                  hover
                                />
                              </div> 
                            ) : <h6 className="pt-3">No Data Found</h6>}
                          
                          </div>


                          <div className="table-devider"></div>
                          <div className="heading">Conference Rooms</div>
                          <div id="room-table" className="conference-rooms">
                            {props.baseProps?.data.filter(x=>x.uniqueno == -1)?.length > 0 ? (
                              <div className={`hasCursorPointer types-table hide-header-scrollbar ${rightTableData?.length >= 7 ? "add-scrollbar overflow-auto" : ""}`}>
                                <BootstrapTable
                                  id={"uniqueno"}
                                  {...props.baseProps}
                                  data={props.baseProps?.data.filter(x=>x.uniqueno == -1)}
                                  keyField="uniqueno"
                                  expandRow={expandRow}
                                  rowClasses={rowClasses}
                                  hover
                                />
                              </div> 
                            ) : <h6 className="pt-3">No Data Found</h6>}
                          
                          </div>


                          <div className="table-devider"></div>
                          <div className="heading">Other Rooms</div>
                          <div id="room-table" className="other-rooms pb-4">
                            {props.baseProps?.data.filter(x=>x.uniqueno == -2)?.length > 0 ? (
                              <div className={`hasCursorPointer types-table hide-header-scrollbar ${rightTableData?.length >= 7 ? "add-scrollbar overflow-auto" : ""}`}>
                                <BootstrapTable
                                  id={"uniqueno"}
                                  {...props.baseProps}
                                  data={props.baseProps?.data.filter(x=>x.uniqueno == -2)}
                                  keyField="uniqueno"
                                  expandRow={expandRow}
                                  rowClasses={rowClasses}
                                  hover
                                />
                              </div> 
                            ) : <h6 className="pt-3">No Data Found</h6>}
                          
                          </div>
                          </> 
                          :this.state.searchText.length > 0 &&  <h6 className="pt-3">No Data Found</h6>
                        )}
                      </>
                    ) : (
                      <>
                       {isRoomTypeLoading ? (
                          <ReactPageLoader useas={"entity-rooms"} />
                        ) : (
                          <div id="customer-room-table" className={`position-relative ${(isRoomTypesArrayModified || isRoomsArrayModified) && this.props?.hasEditPermission ? "save-baner-open" : "" }`}>
                          {filteredRoomsTableData?.length > 0 ? (<RoomsListTable 
                             id="RoomsList"
                             isRoomsArrayModified={isRoomsArrayModified}
                             handleBulkAction={this.handleBulkAction}
                             handleSelectedIds={this.handleSelectedIds}
                             showChangeRoomTypeModal={this.BulkShowChangeRoomTypeModal}
                             showTakeRoomOfflineModal={this.BulkShowTakeRoomOfflineModal}
                             hasEditPermission={this.props?.hasEditPermission}
                             {...props.baseProps}
                          />): <h6 className="pt-3">No Data Found</h6>}
                          </div>
                            )
                          }
                      </>
                      
                    )}
                   </>}
                    </div>
                  </div>
                )}
              </ToolkitProvider>)}

              {(isRoomTypesArrayModified || isRoomsArrayModified ) && this.props?.hasEditPermission && (
                              <div className="fixed-action ml-auto">
                                <div className="d-flex align-content-center flex-wrap">
                                  <div className="mr-auto message">
                                    {rightTableData?.length > 0 && (
                                      <span>You have made changes to the room configuration.</span>
                                    )}
                                  </div>
                                  <button
                                    disabled={isDataSubmitting}
                                    type="button"
                                    className="btn btn-primary btn-discard"
                                    onClick={() => this.discardChanges()}
                                  >
                                    Discard
                                  </button>

                                  <button
                                    disabled={isDataSubmitting}
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.showSaveModalClick()}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            )}
            </div>
          </div>
        </div>

        
        {(isRoomTypesArrayModified || isRoomsArrayModified || isExpandedRoomsArrayModified) && this.props?.hasEditPermission && (
          <SaveRoomDateModal 
          showSaveModal={showSaveModal}
          currentSelectedDate={currentSelectedDate}
          calendarSelectedDate={calendarSelectedDate}
          hideSaveModal={this.hideSaveModal}
          handleCalendarDateChange={this.handleCalendarDateChange}
          onChange={(date) => {
            const value = moment(date).format("MM/DD/YYYY");
            this.onCalendarDateSelect(value);
          }}
          isDataSubmitting={isDataSubmitting}
          handleOnSave={this.handleOnSave}

          />
        )}

        {showAddRoomTypeModal && (
            <AddRoomType
              date={currentSelectedDate}
              roomTypeRowEdit={this.state.roomTypeRowEdit}
              hotelId={this.props?.entityRowData?.hotelId}
              showAddRoomTypeModal={showAddRoomTypeModal}
              handleHideRoomTypeModal={this.handleHideRoomTypeModal}
              facilityFeatureFlag = {this.props?.facilityFeatureFlag}
              updateRoomTypesList={this.updateRoomTypesList}
            />
        )}


         {showChangeRoomTypeModal && (
          <ChangeRoomType
            changeRoomType={true}
            date={currentSelectedDate}
            roomRowEdit={this.state.roomRowEdit}
            hotelId={this.props?.entityRowData?.hotelId}
            showAddRoomModal={showChangeRoomTypeModal}
            handleHideRoomModal={this.BulkHideChangeRoomTypeModal}
            updateRoomList={this.updateBulkChangeRoomType}
            RoomTypesList={RoomTypeDropdownList}

          />
        )}  

        {currentToggle === "Rooms" && showAddRoomModal && (
          <AddRoom
            date={currentSelectedDate}
            roomRowEdit={this.state.roomRowEdit}
            hotelId={this.props?.entityRowData?.hotelId}
            showAddRoomModal={showAddRoomModal}
            handleHideRoomModal={this.handleHideRoomModal}
            updateRoomList={(data)=> currentToggle === "Rooms" ? this.updateRoomList(data) :  this.updateRoomListExpanded(data)}
            RoomTypesList={RoomTypeDropdownList}

          />
        )} 
        
         

        {showTakeRoomOffline && (
          <TakeRoomOffline
            date={currentSelectedDate}
            roomRowEdit={this.state.roomRowEdit}
            hotelId={this.props?.entityRowData?.hotelId}
            showAddRoomModal={showTakeRoomOffline}
            handleHideRoomModal={this.handleHideTakeRoomOfflineModal}
            updateRoomTypesList={()=>{}}
            RoomTypesList={RoomTypeDropdownList}
          />
        )} 
      </div>
    );
  }
}
