import React from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import { InvoiceApprovalS } from "../../Common/Services/InvoiceApprovals";
import { toast } from "react-toastify";
import { resolve, reject } from "q";
import "../../Modules/Accounting/InvoiceApproval/invoiceApproval.scss";
import { Utils } from "../../Common/Utilis";

export class PartialPayment extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            isloder: false,
            amount: "",
        };
    }
    componentDidMount(): void {
        $('#amount').focus();
    }

    savePartialPayment = () => {
        let { amount } = this.state;
        this.setState({ isloder: true });
        let request: any = {};
        request.Uniqueno = this.props?.uniqueNo;
        request.PartAmt = Utils.removecurrencyFormat(amount)?.replace(',', '')?.trim();
        request.HotelID = this.props?.HotelID;
        InvoiceApprovalS.savePartialPayment(request)
            .then(async (result: any) => {
                if (result?.result?.saveStatus?.toLowerCase() ==="success") {
                    toast.success(`Partial payment created successfully.`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.setState({ isloder: false }, () => {
                        this.props.modalClose(true);
                    })
                } else {
                    this.setState({ isloder: false })
                    Utils.toastError(result?.result?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isloder: false })
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    render() {
        let { isloder, amount } = this.state;
        const amountWithoutDollar = Utils.removecurrencyFormat(amount);
        const isDisabled = amountWithoutDollar === "." || amountWithoutDollar === "" || parseFloat(amountWithoutDollar) === 0.00 || isloder || parseFloat(amountWithoutDollar) >= parseFloat(this.props?.amount);

        return (
            <div className="confirmModal wom_confirmModal forPartialPayment">
                <div className="title titlePP">Partial Payment</div>
                <div className="description wom_description">
                    <div className="PPPayment">
                        <Form.Group>
                            <Form.Label>
                                Amount
                            </Form.Label>
                            <input
                                id="amount"
                                type="text"
                                className="form-control PPInvoice"
                                autoComplete="Off"
                                value={amount}
                                onChange={(e) => { this.handleChange(e) }}
                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                onBlur={(e: any) => this.amountBlur(e)}
                                onFocus={this.onAmountFocus}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className="action-link actionPP">
                    <Button
                        variant="primary"
                        type="button"
                        className="space btn btn-primary btn-discard-PP"
                        onClick={this.hidePPModal}
                        disabled={isloder}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        className="btn btnApprove"
                        disabled={isDisabled}
                        onClick={(e: any) => this.savePayment(this.state.amount, this.props?.uniqueNo)}
                    >
                        {isloder && (
                            <>
                                <Spinner size="sm" animation="border" />{" "}
                            </>
                        )}{" "}
                        Confirm
                    </Button>
                </div>
            </div>
        )
    }

    savePayment=(amount:any, uniqueNo:any)=>{
        this.setState({isloder:true},()=>{
            this.props.savePartialPayment(amount, uniqueNo)
        })
    }


    handleChange = (e) => {
        $('#amount').removeClass("alert-danger");
        let newVal = Utils.removeInvaildCharFromAmount(e.target.value?.replace('-', '')?.trimLeft());
        e.target.value = newVal;
        const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
        let lenbeforePoint = 8;
        if (e.target.value.includes("-")) {
            lenbeforePoint = 9;
        }

        if (rx_live.test(e.target.value)) {
            if (e.target.value.includes(".")) {
                const timeArray = (e.target.value)?.split(".");
                if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
                    newVal = e.target.value;
                }
            } else {
                if (e.target.value.length <= lenbeforePoint) {
                    newVal = e.target.value;
                }
            }
        } else if (e.target.value.includes(".") && e.target.value.length === 1) {
            newVal = e.target.value;
        }
        this.setState({ amount: newVal });
    }

    amountBlur = (e) => {
        let newAmount = "";
        if (e.target.value.trim() !== "") {

            if (e.target.value.includes(".")) {
                const timeArray = (e.target.value)?.split(".");
                if (timeArray[1].length <= 0) {
                    newAmount = timeArray[0] === ""?"0":timeArray[0];
                }
                else {
                    newAmount = e.target.value;
                }
            } else {                
                newAmount = e.target.value === '' ? '' : !isNaN(e.target.value) ? e.target.value : "0";
            }
            this.setState({ amount: Utils.currencyFormat(parseFloat(newAmount)) });
            if (e.target.value.trim() >= this.props?.amount) {
                $('#amount').addClass("alert-danger");
                
            }
        }else{
            this.setState({ amount: "" });
        }
        
    }

    onAmountFocus = (event: any) => {
        let newval = Utils.removecurrencyFormat(event.target.value);
        this.setState({ amount: newval });
    }

    hidePPModal = () => {
        this.props.modalClose(false);
    }
}
