import axios from "axios";
import { API_ROOT } from "./Api-config";
import Instense from "./Axios-config";
export class ForgotPassword {
  public static generatePassCode = async (
    UserName: string,
    Type: string,
    IsResend: number,
    Platform:string,
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    const url = `${API_ROOT.backendHost}/User/GeneratePassCode`;
    const response = await axios.post(url, { UserName, Type, IsResend,Platform });
    let loginResponse = response.data.result as any;
    return loginResponse;
  };

  public static GeneratePassCodeByPage = async (
    UserName: string,
    PassCode: number,
    Type: string,
    Platform: string,
    IsResend: number,
    PageName: string,
    TransType: string
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    const url = `${API_ROOT.backendHost}/User/GeneratePassCodeByPage`;
    const response = await axios.post(url, { UserName, PassCode, Type, Platform, IsResend, PageName, TransType });
    let loginResponse = response.data.result as any;
    return loginResponse;
  };

  public static ResetPasswordWithPassCode = async (
    userName: string,
    password: string,
    passcode: number,
    IsFromWeb:string
  ): Promise<string | null> => {
    // Need to put this and all future URLs into a config js file
    const url = `${API_ROOT.backendHost}/User/ResetPasswordWithPassCode`;
    const response = await axios.post(url, { userName, password, passcode,IsFromWeb });
    return response.data.message;
  };

  public static ValidatePassCode = async (
    UserName: string,
    PassCode: number
  ): Promise<string | null> => {
    // Need to put this and all future URLs into a config js file
    let Type = "";
    const url = `${API_ROOT.backendHost}/User/ValidatePassCode`;
    const response = await axios.post(url, { UserName, PassCode, Type });
    return response.data.message;
  };

  public static ValidatePassCodePayment = async (
    UserName: string,
    PassCode: number,
    Type: string,
  ): Promise<string | null> => {
    // Need to put this and all future URLs into a config js file
    const url = `${API_ROOT.backendHost}/User/ValidatePassCode`;
    const response = await axios.post(url, { UserName, PassCode, Type });
    return response.data.message;
  };

  public static ResetPassword = async (
    user_UniqueID: number,
    OldPassword: string,
    newPassword: string
  ): Promise<any | null> => {
    // Need to put this and all future URLs into a config js file
    const url = `/User/ResetPassword`;
    const response = await Instense.post(url, {
      user_UniqueID,
      OldPassword,
      newPassword
    });
    return response.data;
  };
}
