import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { Container, Tab, Nav } from "react-bootstrap";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { confirmAlert } from "react-confirm-alert";
import { VendorService } from "../../../../Common/Services/vendor";
import { GeneralTab } from "./GeneralTab";
import { ContractsTab } from "./ContractsTab";
import { DocumentsTab } from "./DocumentsTab";
import { resolve, reject } from "q";
import { Register as registersevice } from "../../../../Common/Services/Register";
import { Hotel } from "../../../../Common/Services/Hotel";
import { RoleSubPermissionSetting, IPageSubPermissionRole, } from "../../../../Common/Services/RoleSubPermissionSetting";
import _ from "lodash";
import { Utils } from "../../../../Common/Utilis";

let isDACHChange = false;
export class VendorDetailSlideOut extends React.Component<any, any> {
    private scrollvdRef: any;
    constructor(props: any) {
        super(props);
        this.scrollvdRef = React.createRef();
        this.state = {
            activeTab: this.props?.selectedTab || "general",
            isGeneralTabChangesNotSaved: false,
            shouldReloadMainPage: false,
            genVenData: [],
            genBillData: [],
            isStatusChange: false,
            venAddressList: [],
            ehidList: [],
            isContractLoader: false,
            pageHeaer: "",
            isPermissionLoading: false,
        };
    }

    componentDidMount() {
        this.getVandorContractMergePermission();
    }

    getVandorContractMergePermission = () => {
        this.setState({ isPermissionLoading: true });
        let localStorageUser = JSON.parse(localStorage.getItem("storage")!);
        let requestObject = {} as IPageSubPermissionRole;
        requestObject.permissionID = 4;
        requestObject.Module = "Accounting";
        requestObject.Type = "page";
        requestObject.RoleID = localStorageUser.rolId;
        requestObject.TenantID = localStorageUser.tenantID;
        let { isMergeContracts, isEditVendor } = this.state;
        isMergeContracts = false; isEditVendor = false
        this.setState({ isMergeContracts, isEditVendor });
        RoleSubPermissionSetting.GetPageSubPermissionWebClockDetails(requestObject)
            .then(async (result: any | null) => {
                if (result != null && result.length > 0) {
                    for (let item of result) {
                        if (item?.permissionName.toString().toLowerCase() === "merge contracts" && item?.moduleName === "Accounting") {
                            isMergeContracts = true;
                        } else if (item?.permissionName.toString().toLowerCase() === "edit vendor information" && item?.moduleName === "Accounting") {
                            isEditVendor = true;
                        }
                    }
                }
                this.setState({ isMergeContracts, isEditVendor, isPermissionLoading: false }, () => {
                    this.getVendorGeneralInfo();
                })
                resolve();
            })
            .catch((err: any) => {
                this.setState({ isPermissionLoading: false });
                Utils.toastError(`Server Error, ${err}`, { position: toast.POSITION.BOTTOM_RIGHT });
                this.getVendorGeneralInfo();
                reject();
            });
    };

    changeReloadStatus = (value) => {
        this.setState({ shouldReloadMainPage: value }, () => {
            this.getVendorAddress();
            this.getVendorGeneralInfo();
        });
    }

    changesStatus = (value) => {
        this.setState({ isStatusChange: value })
    }

    closeSlideOutWithConfirmation = () => {
        if (this.state.isStatusChange || isDACHChange) {
            this.confirmationOnTabChange()
        } else {
            this.props.closeSlideOut(this.state?.shouldReloadMainPage);
            isDACHChange = false;
        }
    };

    stayOnSameTab = () => {
        console.log("");
    };

    confirmationOnTabChange = () => {
        confirmAlert({
            title: "Unsaved confirmation?",
            message: "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
            buttons: [{
                label: "Yes",
                onClick: () => { this.props.closeSlideOut(this.state?.shouldReloadMainPage); isDACHChange = false; },
            }, {
                label: "No",
                onClick: () => { this.stayOnSameTab() },
            }],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    };

    setActiveTab = (selectedTab) => {
        const { isGeneralTabChangesNotSaved, activeTab } = this.state;
        if (activeTab === selectedTab) {
            return;
        }
        switch (activeTab) {
            case "general":
                if (isGeneralTabChangesNotSaved) {
                    this.confirmationOnTabChange();
                    return;
                }
                break;
            case "dach":
                if (isDACHChange) {
                    this.unsavedChangesPopUp(selectedTab);
                    return;
                }
                break;
            case "contracts":
                this.getVendorGeneralInfo();
                break;
            default:
                break;
        }

        this.setState({ activeTab: selectedTab, billingEmail: this.state.genVenData[0]?.billingEmail, taxID: this.state.genVenData[0]?.taxOrSsnId });
    };

    getVendorGeneralInfo = () => {
        this.setState({ genVenData: [], genBillData: [] });
        VendorService.GetVendorGeneralInfo(this.props.vendorData?.vid)
            .then((result: any) => {
                if (result?.vendorDetails.length > 0) {
                    let billData = result?.billingDetails;
                    let vendorData = result?.vendorDetails;
                    let pageHeaer = vendorData[0].company;
                    billData.map(item => {
                        item['isEditModal'] = false;
                        item['isFormSubmit'] = false;
                        item['rowNo1'] = '';
                        item['aid1'] = '';
                        item['department1'] = '';
                        item['city1'] = '';
                        item['pcode1'] = '';
                        item['status1'] = '';
                        item['street1'] = '';
                        item['state1'] = '';
                    });
                    vendorData.map(item => {
                        item["selectedCOAList"] = [];
                        item["coaList"] = item.coaList === null ? "" : item.coaList;
                        item["company"] = item.company === null ? "" : item.company;
                        item["oldcompany"] = item.company === null ? "" : item.company;
                        item["billingEmail"] = item.billingEmail === null ? "" : item.billingEmail;
                        item["services"] = item.services === null ? "" : item.services;
                        item['phone'] = item.phone === null ? "" : item.phone;
                        item['taxType'] = item.taxType === null ? "" : item.taxType;
                        item['taxOrSsnId'] = item.taxOrSsnId === null ? "" : item.taxOrSsnId;
                    });

                    billData = _.sortBy(billData, "status");
                    this.getVendorAddress();
                    this.setState({
                        genVenData: vendorData,
                        genBillData: billData,
                        pageHeaer
                    })
                    resolve();
                }
            }).catch((error) => {
                reject();
            });
    }

    getVendorAddress = () => {
        let request: any = {};
        request.hotelID = 0;
        request.vendorType = "Current";
        request.VendorId = this.props.vendorData?.vid;
        this.setState({ isContractLoader: true })
        registersevice.GetVendorAddress(request).then(async (result: any | null) => {
            if (result !== null) {
                this.setState(() => ({
                    addressList: result
                }), () => {
                    this.GetImportList();
                });
            }
            resolve();
        }).catch((error) => {
            reject();
            this.GetImportList();
        });
    }

    GetImportList = () => {
        let moduleName = "Register";
        Hotel.getUserHotelAccess(moduleName)
            .then(async (result: any | null) => {
                if (result !== null) {
                    result.map((item: any) => {
                        item['id'] = item.hotelID;
                        item['name'] = item.lettercode
                    })
                    this.setState({ ehidList: result, isContractLoader: false });
                }
                // else {
                //     Utils.toastError("Ehid list not loadad.", { position: toast.POSITION.BOTTOM_RIGHT, });
                // }
                resolve();
            })
            .catch((error) => {
                this.setState({ isContractLoader: false })
                reject();
            });
    };


    getStatusBadgeclass = (status) => {
        let className = "";
        switch (status) {
            case "Active":
                className = "color-green";
                break;
            case "Inactive":
                className = "color-grey";
                break;
            default:
                className = "color-orange";
                break;
        }
        return className;
    };

    render() {
        let { genVenData } = this.state;
        let isValidVendor = genVenData.length > 0;

        const vendorDetailsLoading = (number) => {
            let newCount: any = [];
            for (let i = 0; i < Number(number); i++) { newCount.push(i) }
            return (
                <div className="loader-responsive register">
                    <div className="performanceList vendorDetails">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    {newCount.map((item) => (
                                        <div key={item} className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        const billingDetailsLoading = (number) => {
            let newCount: any = [];
            for (let i = 0; i < Number(number); i++) { newCount.push(i) }
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    {newCount.map((item) => (
                                        <div key={item} className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        const generalLoader = () => {
            return (
                <>
                    <div className="bulkImportList mt-0">
                        <>{vendorDetailsLoading(2)}</>
                        <>{vendorDetailsLoading(2)}</>
                        <>{vendorDetailsLoading(2)}</>
                        <>{vendorDetailsLoading(2)}</>
                        <>{vendorDetailsLoading(2)}</>
                    </div>
                    <div className="separator-line"></div>
                    <div className="bulkImportList mt-0">
                        <div className="bulkImportList1 d-flex">
                            <div className="single-loader loadingAnimation"></div>
                        </div>
                        <>{billingDetailsLoading(8)}</>
                        <>{billingDetailsLoading(8)}</>
                        <>{billingDetailsLoading(8)}</>
                    </div>
                </>
            )
        }

        const contTabList = (number) => {
            let newCount: any = [];
            for (let i = 0; i < Number(number); i++) { newCount.push(i) }
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    {newCount.map((item: any, index: any) => (
                                        <div key={item} className={index === 2 ? "list d-flex align-self-center ml-auto" : "list d-flex align-self-center"}>
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }
        const contractTabLoader = () => {
            return (
                <>
                    {/* <div style={{ "height": "50px" }}></div> */}
                    <div className="bulkImportList mt-0">
                        <div className="bulkImportList1 d-flex">
                            <div className="single-loader loadingAnimation"></div>
                        </div>
                        <>{contTabList(3)}</>
                        <>{contTabList(3)}</>
                        <>{contTabList(3)}</>
                    </div>
                </>
            )
        }

        const documentTabLoading = (number: number) => {
            let newCount: any = [];
            for (let i = 0; i < Number(number); i++) { newCount.push(i) }
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    {newCount.map((item) => (
                                        <div key={item} className="list d-flex align-self-center">
                                            <div className="group">
                                                <div className="loadingAnimation"></div>
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        const tabLoader = () => {
            return (
                <>
                    <div style={{ "height": "50px" }}></div>
                    <div className="bulkImportList mt-0">
                        <div className="bulkImportList1 d-flex">
                            <div className="single-loader loadingAnimation"></div>
                        </div>
                        <>
                            {documentTabLoading(6)}
                            {documentTabLoading(6)}
                            {documentTabLoading(6)}
                        </>
                    </div>
                </>
            )
        }

        return (
            <div id="performanceSlideOut" className="pos-fxd index-1000">
                <ToastContainer containerId={"userDetailSlideoutcontainer"} autoClose={3000} />
                <div className="back-drop" onClick={this.closeSlideOutWithConfirmation}></div>
                <div className="d-flex">
                    <Container fluid ref={this.scrollvdRef} className="body-sec performance-position user-management-detail-slideout justify-content-end pos-rel">
                        <div className="page-heading d-flex">
                            <div className="user-details align-items-center">
                                <div className="user-name d-flex align-items-center">
                                    {isValidVendor ? this.state.pageHeaer === "" ? (<div style={{ "width": "100px" }}></div>) : (
                                        <div className="w-520">
                                            <EllipsisWithTooltip placement="bottom">
                                                {this.state.pageHeaer}
                                            </EllipsisWithTooltip>
                                        </div>
                                    ) : (
                                        <div className="w-150 h-25 single-loader loadingAnimation"></div>
                                    )}
                                    <span className={`badge ${this.getStatusBadgeclass(isValidVendor ? genVenData[0]?.status : "")}`}>
                                        {isValidVendor ? genVenData[0]?.status : <> &nbsp; &nbsp; </>}
                                    </span>
                                </div>
                                <div className="position-name">Vendor</div>
                            </div>
                            <div className="header-popout ml-auto"></div>
                            <button type="button" className="btn wht-bg cross" onClick={this.closeSlideOutWithConfirmation}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#84888C" />
                                </svg>
                            </button>
                        </div>
                        <Tab.Container activeKey={this.state.activeTab} onSelect={(selectedtab) => this.setActiveTab(selectedtab)} id="left-tabs-example" defaultActiveKey="general">
                            <Nav className="nav-tabs flex-row">
                                <Nav.Item><Nav.Link eventKey="general">General</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="contracts">Contracts</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="documents">Documents</Nav.Link></Nav.Item>
                            </Nav>
                            <div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="general">
                                        {isValidVendor ? (
                                            <GeneralTab
                                                VendorDetails={this.state.genVenData}
                                                billingDetails={this.state.genBillData}
                                                vid={this.props.vendorData?.vid}
                                                isEditVendor={this.state?.isEditVendor}
                                                isReload={this.changeReloadStatus}
                                                isEpayEnable={this.props.isEpayEnable}
                                            />
                                        ) : (
                                            <>{generalLoader()}</>
                                        )}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="contracts">
                                        {this.state.isContractLoader ? (<>{contractTabLoader()}</>) : (
                                            <ContractsTab
                                                vid={this.props.vendorData?.vid}
                                                ehidList={this.state?.ehidList}
                                                addressList={this.state.addressList}
                                                isReload={this.changeReloadStatus}
                                                closeSlide={this.closeSlideOutWithConfirmation}
                                                changesStatus={this.changesStatus}
                                                isMergeContracts={this.state?.isMergeContracts}
                                                isEditVendor={this.state?.isEditVendor}
                                                isReloadGeneralInfo={this.getVendorGeneralInfo}
                                                scrollRef={this.scrollvdRef}
                                                activeTab={this.state.activeTab}
                                            />
                                        )}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="documents">
                                        {this.state.isPermissionLoading ? (<>{tabLoader()}</>) : (
                                            <DocumentsTab
                                                vid={this.props.vendorData?.vid}
                                                isReload={this.changeReloadStatus}
                                                isEditVendor={this.state?.isEditVendor}
                                                closeSlide={this.closeSlideOutWithConfirmation}
                                                changesStatus={this.changesStatus}
                                            />
                                        )}
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </Container>
                </div>
            </div>
        );
    }

    unsavedChangesPopUp(selectedTab) {
        confirmAlert({
            title: "Unsaved confirmation",
            message: "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
            buttons: [{ label: "Yes", onClick: () => this.acceptRedirect(selectedTab) }, { label: "No", onClick: () => reject() }],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }

    acceptRedirect = (selectedTab) => {
        this.setState({ activeTab: selectedTab }, () => {
            isDACHChange = false;
        });
    }
}
