import React from "react";
import { Modal, ProgressBar, Button, Dropdown, Spinner, Tabs, Tab, Form, Tooltip, OverlayTrigger, Row } from "react-bootstrap";
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from "react-toastify";
import "./bulkImportInvoiceSlideout.scss";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { FiChevronDown, FiTrash, FiPaperclip } from "react-icons/fi";
import { resolve, reject } from "q";
import { confirmAlert } from "react-confirm-alert";
import TableAutocomplete from "../../../Common/Components/TableAutocomplete";
import cellEditFactory from 'react-bootstrap-table2-editor';
import DatePicker from "react-datepicker";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { Register as registersevice } from "../../../Common/Services/Register";
import { ddlSearchWithTab as SingleSearchDropdownList } from "../../../Common/Components/ddlSearchWithTab";
import { VendorSearch } from "../../../Common/Components/VendorSearch";
import { CommonService } from "../../../Common/Services/CommonService";
import { RoleSubPermissionSetting } from "../../../Common/Services/RoleSubPermissionSetting";
import { Utils } from "../../../Common/Utilis";
const { SearchBar } = Search;
let expandedRows: any = [];
export class BulkImportInvoiceSlideout extends React.Component<any, any> {
    private vendorSearch: any;
    constructor(props: any) {
        super(props);
        this.vendorSearch = React.createRef();
        this.state = {
            BlobbaseUrl:"",
            hidValue: props.hidValue,
            hotelName: props.hotelName,
            oprId: props.oprId,
            acctType: props.acctType,
            modalWidth: "modal-60w",
            isProgress: Math.floor(Math.random() * 30),
            uploadFiles: [],
            coaList: [],
            vendorListMain: [],
            accPeriodList: [],
            isSpinner: false,
            isImportData: false,
            ehidList: [],
            ehidListMain: [],
            isFileImported: false,
            isCloseAlert: false,
            tableData: [],
            isSlideUpdated: false,
            isSlideSave: false,
            isExpand: [],
            isBulkUpdated: false,
            isVandorSpinnerPanel: false,
            isAddContractPerm: true,
            editedRow: [],
            isPostID: 0,
            fileSize: 5242880,
        };
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount() {
        this.getBlobbaseUrl();
          }
        
          getBlobbaseUrl = () => {
           
            let keyName ="BlobbaseUrl";
            let BlobbaseUrl="";
             CommonService.GetInnflowConfigKeyValue(keyName)
               .then(async (result: any | null) => {
                 console.log(result);        
                 if (result !== null) {
                   BlobbaseUrl = result;
                   this.setState({ BlobbaseUrl:BlobbaseUrl });
                 }
                 
               });
           }

    getFileSize() {
        registersevice.GetFileSize()
            .then(async (result: any | null) => {
                if (result > 0) {
                    this.setState({ fileSize: result })
                }

            });
    }

    onDrop = (uploadFiles: any) => {
        let size = 5242880
        if (uploadFiles.length === 1) {
            if (uploadFiles[0].size > size) {
                Utils.toastError("File is greater than 5 MB.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                this.setState({ uploadFiles }, () => {
                    this.BulkExcelImport();
                    this.getFileSize();
                });
            }
        } else if (uploadFiles.length > 1) {
            Utils.toastError("Multiple files are not allowed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } else {
            Utils.toastError("Only .xlsx, and .csv file is acceptable.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    BulkExcelImport = () => {
        let request: any = {};
        request.TransType = "";
        request.Hid = this.state.hidValue;
        request.OprId = this.props.oprId;
        registersevice.BulkImportExcelFile("importInvoice", request, this.state.uploadFiles)
            .then(async (result: any) => {
                this.setState({ isProgress: 100 })
                let resResult = result?.message.toString().toLowerCase();
                let resMessage = "";
                if (result.success) {
                    if (resResult === "Success".toLocaleLowerCase()) {
                        let data = result?.result;
                        data.map(element => {
                            let isChild = element.rowNo !== 1;
                            element["defaultCOA"] = [];
                            element["defaultVendor"] = [];
                            element["defaultSubVendor"] = [];
                            element["duedate"] = (element.duedate === "01/01/00" || element.duedate === null) ? '' : element.duedate;
                            element["billdate"] = (element.billdate === "01/01/00" || element.billdate === null) ? '' : element.billdate;
                            element['isActivePeriod'] = element.accper === "01/01/00" ? "Yes" : element.isActivePeriod;
                            element["accper"] = element.accper === "01/01/00" ? "" : element.accper;
                            element["attachmentMain"] = [];
                            element["isException"] = false;
                            element["accPeriodForEHID"] = false;
                            element["amount"] = Utils.removeInvaildCharFromAmountNonMinus(element.amount);
                            element["VendorList"] = [];
                            element["splitHidList"] = [];
                            element["subVendorList"] = [];
                            element["customerList"] = [];
                            element["etypeList"] = ["Manual ACH", "Check", "Credit Card", "ePay"];
                            element["ehidHasDach"] = false;
                            element["venderHasDach"] = false;
                            element["cusNoHasDach"] = false;
                            element["vid"] = null;
                            if (isChild) {
                                element["isVendor"] = "Right";
                                element["isInactiveVendor"] = "Active";
                                element["isActivePeriod"] = "Yes";
                                //element["isVendorToHId"] = "Right";
                                element["isSplitPeriod"] = "Active";
                            }
                        })

                        setTimeout(() => {
                            this.setState({ isFileImported: true, isSpinner: true, isCloseAlert: true }, () => {
                                this.GetAccountingPeriod(data);
                                //this.getVendorPermission();
                            });
                        }, 1000)

                    } else {
                        if (resResult === "ColumnNameNotMatch".toLocaleLowerCase() || resResult === "InvalidCallName".toLocaleLowerCase()) {
                            resMessage = "The columns in the selected file do not match the template.";
                        } else if (resResult === "LengthExceed".toLocaleLowerCase()) {
                            resMessage = "Import sheet should not have more than 100 characters in a cell.";
                        } else if (resResult === "EHIDShouldbeSame".toLocaleLowerCase()) {
                            resMessage = "EHID Should be Same.";
                        } else if (resResult === "fail".toLocaleLowerCase()) {
                            resMessage = "System keyword does not allowed please rename the column name.";
                        } else if (resResult === "Worng".toLocaleLowerCase()) {
                            resMessage = "Please enter valid column value.";
                        } else if (resResult === "NoData".toLocaleLowerCase()) {
                            resMessage = "No data found.";
                        } else {
                            resMessage = resResult;
                        }
                        setTimeout(() => { Utils.toastError(resMessage, { position: toast.POSITION.BOTTOM_RIGHT, }); }, 1000);
                        setTimeout(() => { this.setState({ isSpinner: false, uploadFiles: [], isProgress: Math.floor(Math.random() * 30) }); }, 1500);
                    }
                } else {
                    resMessage = resResult;
                    setTimeout(() => { Utils.toastError(resMessage, { position: toast.POSITION.BOTTOM_RIGHT, }); }, 1000);
                    setTimeout(() => { this.setState({ isSpinner: false, uploadFiles: [], isProgress: Math.floor(Math.random() * 30) }); }, 1500);
                }
                resolve();
            })
            .catch((error) => {
                let resMessage = "No Records are available to import.";
                setTimeout(() => { Utils.toastError(resMessage, { position: toast.POSITION.BOTTOM_RIGHT, }); }, 1000);
                setTimeout(() => { this.setState({ isSpinner: false, uploadFiles: [], isProgress: Math.floor(Math.random() * 30) }); }, 1500);
                reject();
            });
    }

    GetAccountingPeriod = (data) => {
        let accPeriod: any = [];
        accPeriod = Object.assign([], JSON.parse(JSON.stringify(this.state.accPeriodList)));
        let request: any = {};
        registersevice.GetAccountingPeriodAllEHID(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    result.forEach(element => {
                        element.name = element.accperiod;
                        accPeriod.push(element);
                    });
                    this.setState({ accPeriodList: accPeriod }, () => {
                        this.GetHotelCOAlist(data);
                    })
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };

    GetHotelCOAlist = (data) => {
        let request: any = {};
        let tableData: any = [];
        tableData = Object.assign([], JSON.parse(JSON.stringify(data)));
        let isExpanded: any = [];
        let accPeriodList: any = [];
        registersevice.GetImportList(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    let coaData = result.transactionChartList;
                    let vendorList: any = result.approvedVendorList;
                    let subVendorList: any = result.vendorBYHIDList;
                    vendorList = vendorList.filter(item => item.name.toString().toLowerCase() !== "SELECT SUB VENDOR".toLocaleLowerCase());
                    vendorList.map((element: any) => (element["label"] = element?.name, element["vendor"] = element?.name));
                    subVendorList = subVendorList.filter(item => item.name.toString().toLowerCase() !== "SELECT SUB VENDOR".toLocaleLowerCase());
                    subVendorList.map((element: any) => (element["label"] = element?.name, element["vendor"] = element?.name));
                    let vendorData = vendorList;
                    let subVendorData = subVendorList;

                    // let userHotelAccess: any = result.userHotelAccess;
                    // let selectedHotelList: any = result.selectedHotelList;

                    tableData.map((element: any, idx: any) => {
                        let parentNode = element.rowNo === 1;
                        let childNode = element.rowNo !== 1;
                        let isbackHID = element.hid;

                        if (parentNode) {
                            let contractList = element.contractList !== null && element.contractList.split("~");
                            let cusList: any = [];
                            if (contractList.length > 0) {
                                contractList.forEach((item, index) => {
                                    let cRow: any = {};
                                    cRow.accountNo = item;
                                    cRow.rowID = index;
                                    element.customerList.push(cRow);
                                    if (element.customerNo.toString().toLocaleLowerCase() === item.toLocaleLowerCase()) {
                                        cusList.push(item);
                                    }
                                })
                            }
                            element.customerNo = cusList.length > 0 ? cusList[0] : '';

                            if (result.selectedHotelList.filter(x => x.id === this.state.hidValue).length > 0) {
                                element.isSplitPeriod = "Active";
                            }
                            element.VendorList = vendorData;//.filter(x => x.hid === isbackHID);
                            element.VendorList.forEach((vendor: any) => {
                                if (element.vendor.toString().toLowerCase() === vendor.name.toString().toLowerCase()) {
                                    let defaultVendor: any = [];
                                    let objVEN: any = {};
                                    objVEN.label = vendor?.name;
                                    objVEN.vendor = vendor?.name;
                                    objVEN.isDigitalACH = vendor?.isDigitalACH;
                                    defaultVendor.push(objVEN);
                                    element.defaultVendor = defaultVendor;
                                    element.vid = vendor?.id;
                                }
                            })
                            if (element.defaultVendor.length > 0) {
                                element.vendor = element.defaultVendor[0].vendor;
                                let isDigitalACH = element.defaultVendor[0].isDigitalACH.toString().toLocaleLowerCase() === "yes" ? true : false;
                                element.venderHasDach = isDigitalACH;
                            } else {
                                element.vendor = "";
                                //element.isVendorToHId = "Right";
                                element.isInactiveVendor = "Active";
                            }
                        }
                        let currentEhidName = result.selectedHotelList.filter(item => item.id === isbackHID);
                        if (currentEhidName.length > 0) {
                            let isCreditCard = currentEhidName[0].isCreditCard.toString().toLocaleLowerCase() === "yes" ? true : false;
                            let isDigitalACH = currentEhidName[0].isDigitalACH.toString().toLocaleLowerCase() === "yes" ? true : false;
                            let isePay = currentEhidName[0].isePay.toString().toLocaleLowerCase() === "yes" ? true : false;
                            element.ehidHasDach = isDigitalACH;
                            let isPtypeDach = element.ehidHasDach && element.venderHasDach;
                            let newEtypeList: any = [];
                            if (isPtypeDach) {
                                newEtypeList = isCreditCard ? isePay ? ["Digital ACH", "Manual ACH", "Check", "Credit Card", "ePay"] : ["Digital ACH", "Manual ACH", "Check", "Credit Card"] : isePay ? ["Digital ACH", "Manual ACH", "Check", "ePay"] : ["Digital ACH", "Manual ACH", "Check"];
                            } else {
                                newEtypeList = isCreditCard ? isePay ? ["Manual ACH", "Check", "Credit Card", "ePay"] : ["Manual ACH", "Check", "Credit Card"] : isePay ? ["Manual ACH", "Check", "ePay"] : ["Manual ACH", "Check"];
                            }
                            element.etypeList = newEtypeList;
                            let currentAccType = element.etypeList.filter(item => item.toString().toLocaleLowerCase() === element.etype.toString().toLocaleLowerCase());
                            if (currentAccType.length > 0) {
                                element.etype = currentAccType[0];
                            } else {
                                element.etype = ""
                            }
                        } else {
                            element.etype = ""
                        }


                        accPeriodList = this.state.accPeriodList.filter(x => x.hid === isbackHID && x.apClosed.toString().toLowerCase() === "Active".toLocaleLowerCase());
                        if (accPeriodList.length > 0) {
                            element.accPeriodForEHID = accPeriodList;
                            // element.accper = accPeriodList[0].defaultPeriod;
                            // element.isActivePeriod = "Yes";
                            // element.isSplitPeriod = "Active";
                        }
                        coaData.forEach((coa: any) => {
                            if (element.coa === coa.id) {
                                let defaultCOA: any = [];
                                let objCOA: any = {};
                                objCOA.id = coa?.id;
                                objCOA.label = coa?.label;
                                objCOA.coAname = coa?.coAname;
                                defaultCOA.push(objCOA);
                                element.defaultCOA = defaultCOA;
                            }
                        })
                        if (element.defaultCOA.length > 0) {
                            element.coa = element.defaultCOA[0].id;
                        } else {
                            element.coa = "";
                        }
                        let isSplitHid = result.userHotelAccess !== null && result.userHotelAccess.filter(x => x.id === element.hid);
                        if (isSplitHid.length > 0) {
                            if (isSplitHid[0].type === 1) {
                                element.splitHidList = result.selectedHotelList;
                                element.subVendorList = subVendorData.filter(x => x.hid === element.splitID);
                                //element.customerNo = "";
                                if (element.subVendorList.length > 0) {
                                    element.subVendorList.forEach((vendor: any) => {
                                        if (element.subvendor.toString().toLowerCase() === vendor.name.toString().toLowerCase()) {
                                            let defaultSubVendor: any = [];
                                            let objVEN: any = {};
                                            objVEN.id = vendor?.id;
                                            objVEN.label = vendor?.name;
                                            objVEN.vendor = vendor?.name;
                                            defaultSubVendor.push(objVEN);
                                            element.defaultSubVendor = defaultSubVendor;
                                        }
                                    })
                                }
                                if (element.defaultSubVendor.length > 0) {
                                    element.subvendor = element.defaultSubVendor[0].vendor;
                                } else {
                                    element.subvendor = "";
                                }
                            } else {
                                element.subVendorList = subVendorData.filter(x => x.hid === isSplitHid[0].id);
                                //element.customerNo = "";
                                if (element.subVendorList.length > 0) {
                                    element.subVendorList.forEach((vendor: any) => {
                                        if (element.subvendor.toString().toLowerCase() === vendor.name.toString().toLowerCase()) {
                                            let defaultSubVendor: any = [];
                                            let objVEN: any = {};
                                            objVEN.id = vendor?.id;
                                            objVEN.label = vendor?.name;
                                            objVEN.vendor = vendor?.name;
                                            defaultSubVendor.push(objVEN);
                                            element.defaultSubVendor = defaultSubVendor;
                                        }
                                    })
                                }
                                if (element.defaultSubVendor.length > 0) {
                                    element.subvendor = element.defaultSubVendor[0].vendor;
                                } else {
                                    element.subvendor = "";
                                }
                                element.splitHidList = isSplitHid;
                                element.splitHid = isSplitHid[0].name;
                                element.splitID = isSplitHid[0].id;
                                element.checkEhid = "Right";
                                element.isEHID = "Right";
                            }

                        } else {
                            element.subVendorList = subVendorData.filter(x => x.hid === element.splitID);
                            //element.customerNo = "";
                            if (element.subVendorList.length > 0) {
                                element.subVendorList.forEach((vendor: any) => {
                                    if (element.subvendor.toString().toLowerCase() === vendor.name.toString().toLowerCase()) {
                                        let defaultSubVendor: any = [];
                                        let objVEN: any = {};
                                        objVEN.id = vendor?.id;
                                        objVEN.label = vendor?.name;
                                        objVEN.vendor = vendor?.name;
                                        defaultSubVendor.push(objVEN);
                                        element.defaultSubVendor = defaultSubVendor;
                                    }
                                })
                            }
                            if (element.defaultSubVendor.length > 0) {
                                element.subvendor = element.defaultSubVendor[0].vendor;
                            } else {
                                element.subvendor = "";
                            }
                        }

                        let headerEhid = result.userHotelAccess !== null && result.userHotelAccess.filter(x => x.id === element.hid);
                        if (headerEhid.length > 0) {
                            element.checkEhid = "Right";
                        } else {
                            element.checkEhid = "NotRight";
                            element.accountName = ""
                        }
                        let hasValidEhid = element.splitHidList.filter(item => item.id === element.splitID).length > 0;
                        let isActiveCOA = element.isActiveCOA.toString().toLowerCase() !== "Right".toLocaleLowerCase();
                        // let isSubVendor = element.isSubVendor.toString().toLowerCase() !== "Right".toLocaleLowerCase();
                        let isInactiveVendor = element.isInactiveVendor.toString().toLowerCase() !== "Active".toLocaleLowerCase();
                        let isActivePeriod = element.isActivePeriod.toString().toLowerCase() !== "Yes".toLocaleLowerCase();
                        //let isVendorToHId = element.isVendorToHId.toString().toLowerCase() !== "Right".toLocaleLowerCase();
                        let isSplitPeriod = element.isSplitPeriod.toString().toLowerCase() !== "Active".toLocaleLowerCase();
                        let isEHID = element.isEHID.toString().toLowerCase() !== "Right".toLocaleLowerCase();
                        let checkEhid = element.checkEhid.toString().toLowerCase() !== "Right".toLocaleLowerCase();
                        let isEtype = element.isEtype.toString().toLowerCase() !== "Right".toLocaleLowerCase();

                        if (isActiveCOA || checkEhid ||
                            (parentNode && (element.splitHid === "" || element.coa === "" || element.amount === "")) ||
                            (childNode && (element.splitHid === "" || element.coa === "" || !hasValidEhid || element.amount === ""))
                        ) {
                            let parentNode = tableData.filter(item => (item.uniqueno === element.uniqueno && item.rowNo === 1));
                            isExpanded.push(parentNode[0].id);
                        }

                        if (isActiveCOA || isInactiveVendor || isActivePeriod /*|| isVendorToHId*/ || (isSplitPeriod) || isActiveCOA || isEHID || checkEhid ||
                            (parentNode && element.vendor === "" || element.etype === "" || isEtype ||
                                element.coa === "" || element.amount === "") ||
                            (childNode && element.coa === "" || element.amount === "")
                        ) {
                            element.isException = true;
                        }

                    });
                    this.setState({
                        coaList: result.transactionChartList,
                        ehidList: result.userHotelAccess !== null ? result.userHotelAccess : [],
                        ehidListMain: result.selectedHotelList,
                        vendorListMain: subVendorData,
                        tableData,
                        isSpinner: false,
                        isExpand: isExpanded,
                    }, () => {
                        this.isDuplicateInvoice();
                    });
                }
                resolve();
            })
            .catch((error) => {
                Utils.toastError(error.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.setState({ isSpinner: false })
                reject();
            });
    };

    downloadTemplate = () => {
        let BlobbaseUrl =this.state.BlobbaseUrl;
        //'https://innflowfilestoretest.blob.core.windows.net/innflowtemplate/ImportInvoiceForMultiHID.xlsx'
        fetch(BlobbaseUrl+'innflowtemplate/ImportInvoiceForMultiHID.xlsx', {
            method: 'GET', headers: { 'Content-Type': 'application/vnd.ms-excel' }
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ImportInvoiceForMultiHID.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
    }

    bulkInvoiceSlideoutClose = () => {
        if (this.state.isCloseAlert) {
            confirmAlert({
                title: "Exit",
                message: "You will lose all the data in setup during the import process. Are you sure you want to exit?",
                buttons: [
                    { label: "Yes", onClick: () => { this.props.hideBulkInvoiceSlideout(this.state.isBulkUpdated); }, },
                    { label: "No", onClick: () => reject() }
                ],
            });
        } else {
            this.props.hideBulkInvoiceSlideout(this.state.isBulkUpdated);
        }
    }

    deleteCurrentRow = (row) => {
        let { tableData } = this.state;
        if (tableData.filter(item => item.rowNo === 1).length > 1) {
            confirmAlert({
                title: "Delete entry",
                message: "Are you sure you want to delete this Entry?",
                buttons: [{
                    label: "Yes", onClick: () => {
                        tableData = tableData.filter(
                            (item) => item.uniqueno !== row.uniqueno
                        );
                        this.setState({ tableData, isSlideUpdated: true }, () => {
                            this.isDuplicateInvoice();
                        });
                    },
                }, {
                    label: "No", onClick: () => reject(),
                }],
            });
        }
    }

    onAttachmentDrop = (uploadAttachments: any, uniqueid: any) => {
        let size = this.state.fileSize//5242880//5MB
        let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024))
        let { tableData } = this.state;
        if (uploadAttachments.length > 0) {
            if (uploadAttachments[0].size > size) {
                Utils.toastError("File is greater than " + filesizeval + "MB.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                let hasAttachmentMain: any = [];
                uploadAttachments.forEach((items, index) => {
                    hasAttachmentMain.push(items);
                });
                tableData = tableData.map((items) => {
                    if (items.uniqueno === uniqueid) {
                        return {
                            ...items,
                            attachmentMain: items.attachmentMain.concat(hasAttachmentMain)
                        };
                    } else {
                        return items;
                    }
                });
                $("#addAttachment").val("");
                this.setState((curr: any) => ({ ...curr, tableData: tableData, uploadAttachments, isSlideUpdated: true }));
            }
        } else {
            Utils.toastError("Please select a valid file.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    deleteCurrentAttachment = (rowIndex, row) => {
        let { tableData } = this.state;
        confirmAlert({
            title: "Delete entry",
            message: "Are you sure you want to delete this Entry?",
            buttons: [{
                label: "Yes", onClick: () => {
                    tableData = tableData.map((items) => {
                        if (items.uniqueno === row.uniqueno) {
                            return {
                                ...items,
                                attachmentMain: items.attachmentMain.filter((item, index) => rowIndex !== index)
                            };
                        } else {
                            return items;
                        }
                    });

                    setTimeout(() => {
                        this.setState((curr: any) => ({ ...curr, tableData: tableData }));
                        tableData.forEach((items) => {
                            if (items.uniqueno === row.uniqueno) {
                                if (items.attachmentMain.length === 0) {
                                    this.setState({ uploadAttachments: [] });
                                };
                            }
                        });
                    }, 1000)
                },
            }, {
                label: "No", onClick: () => reject(),
            }],
        });
    }

    handleSelectedItem = (event: any, row: any, type: any) => {
        let { tableData } = this.state;
        if (type === "coa") {
            if (event.length > 0) {
                let defaultCOA: any = [];
                let objCOA: any = {};
                objCOA.id = event[0].id;
                objCOA.label = event[0].label;
                objCOA.coAname = event[0].coAname;
                defaultCOA.push(objCOA);
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return { ...items, defaultCOA: defaultCOA, coa: event[0].id, isActiveCOA: "Right" };
                    } else {
                        return items;
                    }
                });
            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return { ...items, defaultCOA: [], coa: '', isException: true };
                    } else {
                        return items;
                    }
                });
            }
        } else if (type === "vendor") {
            if (event.length > 0) {
                let defaultVendor: any = [];
                let objVEN: any = {};
                objVEN.id = event[0].id;
                objVEN.label = event[0].vendor;
                objVEN.vendor = event[0].vendor;
                defaultVendor.push(objVEN);
                let isDigitalACH = event[0].isDigitalACH.toString().toLocaleLowerCase() === "yes" ? true : false;
                tableData = tableData.map((items) => {
                    let parentVendor = items.rowNo == 1 && items.vendor;
                    tableData.forEach((items2) => {
                        if (items2.uniqueno === items.uniqueno) {
                            if (items2.subvendor !== parentVendor) {
                                tableData.map(it => it.uniqueno === row.uniqueno && (it.isSubVendor = "Right", it.isException = false));
                            }
                        }
                    })
                    if (items.id === row.id) {
                        row.customerList = [];
                        row.customerNo = '';
                        return {
                            ...items,
                            defaultVendor: defaultVendor,
                            vendor: event[0].vendor,
                            venderHasDach: isDigitalACH,
                            vid: event[0].id,
                            customerList: [],
                            customerNo: '',
                            isVendor: "Right",
                            //isVendorToHId: "Right",
                            isInactiveVendor: "Active",
                        }
                    } else {
                        return items;
                    }
                });


            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return { ...items, defaultVendor: [], vendor: '', venderHasDach: false, etype: '' };
                    } else {
                        return items;
                    }
                });
            }
        } else if (type === "subVendor") {
            if (event.length > 0) {
                let defaultSubVendor: any = [];
                let objVEN: any = {};
                objVEN.id = event[0].id;
                objVEN.label = event[0].vendor;
                objVEN.vendor = event[0].vendor
                defaultSubVendor.push(objVEN);
                tableData = tableData.map((items) => {
                    let parentVendor = items.rowNo == 1 && items.vendor;
                    tableData.forEach((items2) => {
                        if (items2.uniqueno === items.uniqueno) {
                            if (items2.subvendor !== parentVendor) {
                                tableData.map(it => it.uniqueno === row.uniqueno && (it.isSubVendor = "Right", it.isException = false));
                            }
                        }
                    })

                    if (items.id === row.id) {
                        if (items.rowNo === row.rowNo) {
                            if (items.subvendor !== row.subvendor) {
                                return {
                                    ...items,
                                    defaultSubVendor: defaultSubVendor,
                                    subvendor: event[0].vendor,
                                }
                            } else {
                                return {
                                    ...items,
                                    defaultSubVendor: defaultSubVendor,
                                    subvendor: event[0].vendor,
                                    isSubVendor: "Right",
                                }
                            }
                        } else {
                            return items;
                        }
                    } else {
                        return items;
                    }
                });
            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return { ...items, defaultSubVendor: [], subvendor: '', venderHasDach: false, etype: "" };
                    } else {
                        return items;
                    }
                });
            }
        } else if (type === "ehidHeaderSelector") {
            // row.VendorList = [];
            // row.defaultVendor = [];
            // row.vendor = "";
            const etypeName = row.etype;
            row.etypeList = [];
            row.etype = "";
            row.customerList = [];
            row.customerNo = '';
            let { ehidListMain } = this.state;
            let currentEhidName = ehidListMain.filter(item => item.id === event);
            let subVendorList = this.state.vendorListMain.filter(x => x.hid === currentEhidName[0].id);
            if (currentEhidName.length > 0) {
                //let VendorList = this.state.vendorListMain.filter(x => x.hid === currentEhidName[0].id);
                let accPeriodList = this.state.accPeriodList.filter(x => x.hid === currentEhidName[0].id && x.apClosed.toString().toLowerCase() === "Active".toLocaleLowerCase());

                let isEtype = "";
                let isCreditCard = currentEhidName[0].isCreditCard.toString().toLocaleLowerCase() === "yes" ? true : false;
                let isePay = currentEhidName[0].isePay.toString().toLocaleLowerCase() === "yes" ? true : false;
                let newEtypeList = isCreditCard ? isePay ? ["Digital ACH", "Manual ACH", "Check", "Credit Card", "ePay"] : ["Digital ACH", "Manual ACH", "Check", "Credit Card"] : isePay ? ["Digital ACH", "Manual ACH", "Check", "ePay"] : ["Digital ACH", "Manual ACH", "Check"];
                let currentAccType = newEtypeList.filter(item => item.toString().toLocaleLowerCase() === etypeName.toString().toLocaleLowerCase())
                let setExixtingName = "";
                if (currentAccType.length > 0) {
                    setExixtingName = currentAccType[0];
                    isEtype = "Right";
                } else {
                    setExixtingName = "";
                    isEtype = "NotRight";
                }
                let isDigitalACH = currentEhidName[0].isDigitalACH.toString().toLocaleLowerCase() === "yes" ? true : false;
                if (currentEhidName[0].type === 1) {
                    tableData = tableData.map((items) => {
                        if (items.uniqueno === row.uniqueno) {
                            return {
                                ...items,
                                backHid: currentEhidName[0].name,
                                hid: currentEhidName[0].id,
                                splitHidList: ehidListMain,
                                oprId: currentEhidName[0].splitOprID,
                                //VendorList: VendorList,
                                customerList: [],
                                cusNoHasDach: false,
                                customerNo: '',
                                accPeriodForEHID: accPeriodList,
                                accper: accPeriodList.length > 0 ? accPeriodList[0].defaultPeriod : "",
                                isActivePeriod: "Yes",
                                isSplitPeriod: "Active",
                                checkEhid: "Right",
                                isEHID: "Right",
                                ehidHasDach: isDigitalACH,
                                etypeList: newEtypeList,
                                etype: setExixtingName,
                                isEtype: isEtype
                            };
                        } else {
                            return items;
                        }
                    });
                } else {
                    let idSplitEhidSame = tableData.filter((items) => items.uniqueno === row.uniqueno && items.splitID);
                    if (idSplitEhidSame.length > 0) {
                        idSplitEhidSame.forEach(items => {
                            if (items.splitID === event) {
                                tableData = tableData.map((items) => {
                                    if (items.uniqueno === row.uniqueno) {
                                        return {
                                            ...items,
                                            backHid: currentEhidName[0].name,
                                            hid: currentEhidName[0].id,
                                            splitHidList: currentEhidName,
                                            splitHid: currentEhidName[0].name,
                                            splitID: currentEhidName[0].id,
                                            oprId: currentEhidName[0].splitOprID,
                                            //VendorList: VendorList,
                                            customerList: [],
                                            cusNoHasDach: false,
                                            customerNo: '',
                                            accPeriodForEHID: accPeriodList,
                                            accper: accPeriodList.length > 0 ? accPeriodList[0].defaultPeriod : "",
                                            isActivePeriod: "Yes",
                                            isSplitPeriod: "Active",
                                            checkEhid: "Right",
                                            isEHID: "Right",
                                            ehidHasDach: isDigitalACH,
                                            subVendorList: subVendorList,
                                            etypeList: newEtypeList,
                                            etype: setExixtingName
                                        };
                                    } else {
                                        return items;
                                    }
                                });
                            } else {
                                tableData = tableData.map((items) => {
                                    if (items.uniqueno === row.uniqueno) {
                                        return {
                                            ...items,
                                            backHid: currentEhidName[0].name,
                                            hid: currentEhidName[0].id,
                                            splitHidList: currentEhidName,
                                            splitHid: currentEhidName[0].name,
                                            splitID: currentEhidName[0].id,
                                            oprId: currentEhidName[0].splitOprID,
                                            //VendorList: VendorList,
                                            customerList: [],
                                            cusNoHasDach: false,
                                            customerNo: '',
                                            accPeriodForEHID: accPeriodList,
                                            accper: accPeriodList.length > 0 ? accPeriodList[0].defaultPeriod : "",
                                            isActivePeriod: "Yes",
                                            isSplitPeriod: "Active",
                                            checkEhid: "Right",
                                            isEHID: "Right",
                                            subVendorList: subVendorList,
                                            defaultSubVendor: [],
                                            subvendor: "",
                                            ehidHasDach: isDigitalACH,
                                            etypeList: newEtypeList,
                                            etype: setExixtingName
                                        };
                                    } else {
                                        return items;
                                    }
                                });
                            }
                        });
                    } else {
                        tableData = tableData.map((items) => {
                            if (items.uniqueno === row.uniqueno) {
                                return {
                                    ...items,
                                    backHid: currentEhidName[0].name,
                                    hid: currentEhidName[0].id,
                                    splitHidList: currentEhidName,
                                    splitHid: currentEhidName[0].name,
                                    splitID: currentEhidName[0].id,
                                    oprId: currentEhidName[0].splitOprID,
                                    //VendorList: VendorList,
                                    customerList: [],
                                    customerNo: '',
                                    accPeriodForEHID: accPeriodList,
                                    accper: accPeriodList.length > 0 ? accPeriodList[0].defaultPeriod : "",
                                    isActivePeriod: "Yes",
                                    isSplitPeriod: "Active",
                                    checkEhid: "Right",
                                    isEHID: "Right",
                                    subVendorList: subVendorList,
                                    ehidHasDach: isDigitalACH,
                                    defaultSubVendor: [],
                                    subvendor: "",
                                    etypeList: newEtypeList,
                                    etype: setExixtingName
                                };
                            } else {
                                return items;
                            }
                        });
                    }
                }
            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return {
                            ...items,
                            backHid: '',
                            hid: -1,
                            VendorList: [],
                            vendor: "",
                            venderHasDach: false,
                            cusNoHasDach: false,
                            customerList: [],
                            customerNo: '',
                            ehidHasDach: false,
                            etypeList: [],
                            etype: ""

                        };
                    } else {
                        return items;
                    }
                });
            }
        } else if (type === "ehidSplitSelector") {
            let { ehidListMain } = this.state;
            let currentEhidName = ehidListMain.filter(item => item.id === event);
            if (currentEhidName.length > 0) {
                let subVendorList = this.state.vendorListMain.filter(x => x.hid === currentEhidName[0].id);
                tableData = tableData.map((items) => {
                    if (items.uniqueno === row.uniqueno) {
                        return { ...items, isSplitPeriod: "Active" };
                    } else {
                        return items;
                    }
                });
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        if (items.splitID === event) {
                            return {
                                ...items,
                                splitHid: currentEhidName[0].name,
                                splitID: currentEhidName[0].id,
                            };
                        } else {
                            return {
                                ...items,
                                splitHid: currentEhidName[0].name,
                                splitID: currentEhidName[0].id, subvendor: "",
                                defaultSubVendor: [],
                                subVendorList: subVendorList,
                                isEHID: "Right"
                            };
                        }
                    } else {
                        return items;
                    }
                });
            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return {
                            ...items,
                            splitHid: "",
                            subVendorList: [],
                            subvendor: "",
                            isException: true
                        };
                    } else {
                        return items;
                    }
                });
            }
        }
        this.setState((curr: any) => ({ ...curr, tableData, isSlideUpdated: true }), () => {
            if (type === "vendor" && event.length > 0) { this.GetVendorContract(event[0].id, row, "vendor"); }
            if (type === "vendor" && event.length > 0) {
                this.GetVendorContract(event[0].id, row, "vendor");
            } else {
                this.isSubVendorMatch(row);
                this.isValidForm();
                this.isDuplicateInvoice();
            }
        });
    }


    // getVendorPermission = async () => {
    //     let requestObject = {} as any;
    //     requestObject.permissionID = 3;
    //     requestObject.Module = "Accounting";
    //     requestObject.Type = "page";
    //     try {
    //         const response = await Promise.all([RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)]);
    //         if (response !== null) {
    //             let finalResponse = response[0];
    //             if (finalResponse.length > 0) {
    //                 for (let i = 0; i < finalResponse.length; i++) {
    //                     if (finalResponse[i].displayName === "Add Contract") {
    //                         this.setState({ isAddContractPerm: true });
    //                     }
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         Utils.toastError("Something went wrong in roll permission.", {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //         });
    //     }
    // };

    GetVendorContract = (vid, row, type) => {
        if (row.customerList.length === 0) {
            const etypeName = row.etype;
            let { tableData, ehidListMain } = this.state;
            tableData = tableData.map((items) => {
                if (items.uniqueno === row.uniqueno) {
                    return { ...items, isVandorSpinner: true, };
                } else {
                    return items;
                }
            });
            this.setState((curr: any) => ({ ...curr, tableData, isVandorSpinnerPanel: true }));
            let request: any = {};
            request.hotelID = row.hid; //this.state.hidValue;
            request.vendorType = "Current";
            request.VendorId = vid;
            registersevice.GetVendorContract(request)
                .then(async (result: any) => {
                    if (result.length > 0) {
                        if (result.length === 1) {
                            let isEtype = "";
                            let isCreditCard = result[0].paymentType !== undefined ? result[0].paymentType.toString().toLocaleLowerCase() === "credit card" ? true : false : false;
                            let isDigitalACH = result[0].paymentType !== undefined ? result[0].paymentType.toString().toLocaleLowerCase() === "digital ach" ? true : false : false;
                            let newEtypeList = isCreditCard ? ["Digital ACH", "Manual ACH", "Check", "Credit Card", "ePay"] : ["Digital ACH", "Manual ACH", "Check", "ePay"];
                            let currentAccType = newEtypeList.filter(item => item.toString().toLocaleLowerCase() === etypeName.toString().toLocaleLowerCase())
                            let setExixtingName = "";
                            // if (isDigitalACH) {
                            //     setExixtingName = "Digital ACH";
                            //     isEtype = "Right";
                            // } else {
                            if (currentAccType.length > 0) {
                                setExixtingName = currentAccType[0];
                                isEtype = "Right";
                                if (currentAccType[0].toString().toLocaleLowerCase() === "epay") {
                                    let request: any = {};
                                    request.VID = row.vid;
                                    request.HID = row.hid;
                                    request.Contract = result[0].accountNo;
                                    this.vendorCheckEpayAddress(row, request);
                                }
                            } else {
                                setExixtingName = "";
                                isEtype = "NotRight"
                            }
                            // }

                            tableData = tableData.map((items) => {
                                if (items.id === row.id) {
                                    return {
                                        ...items,
                                        customerList: result,
                                        customerNo: result[0].accountNo,
                                        isVandorSpinner: false,
                                        cusNoHasDach: isDigitalACH,
                                        etype: setExixtingName,
                                        isEtype: isEtype,
                                    };
                                } else {
                                    return items;
                                }
                            });
                        } else {
                            tableData = tableData.map((items) => {
                                if (items.id === row.id) {
                                    return {
                                        ...items,
                                        customerList: result,
                                        customerNo: '',
                                        etype: '',
                                        isEtype: "notRight",
                                        isVandorSpinner: false
                                    };
                                } else {
                                    return items;
                                }
                            });
                        }
                    } else {
                        tableData = tableData.map((items) => {
                            if (items.id === row.id) {
                                return { ...items, customerNo: "", customerList: [], isVandorSpinner: false };
                            } else {
                                return items;
                            }
                        });
                    }
                    this.setState((curr: any) => ({ ...curr, tableData, isVandorSpinnerPanel: false }), () => {
                        if (type === "custNo" && result.length === 0) {
                            this.addNewCustomerNo(row);
                        } else {
                            this.isValidForm();
                        }
                        this.isDuplicateInvoice();
                    });
                    resolve();
                })
                .catch((error) => {
                    Utils.toastError(error?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    reject();
                });
        }
    }

    addNewCustomerNo = (row: any) => {
        this.setState({ editedRow: row }, () => {
            if (this.vendorSearch?.current !== null) {
                this.setState(
                    prevState => ({ currentRowHid: row.hid }),
                    () => {
                        setTimeout(() => { this.vendorSearch?.current.importFromJournal(row.vendor, row.vid, "current"); }, 500);
                    }
                );
            }
        })
    }

    updateVendorList = (result: any) => { }
    handleVendorChange = (vendor: any) => { }
    getCustomerNo = (result: any) => { }
    updateCustomerList = (newCustList) => {
        this.updateNewCustomerList(newCustList);
    }

    updateNewCustomerList = (newCustList) => {
        let { tableData, editedRow, ehidListMain } = this.state;
        const etypeName = editedRow.etype;
        let request: any = {};
        request.hotelID = editedRow.hid;
        request.vendorType = "Current";
        request.VendorId = editedRow.vid;
        registersevice.GetVendorContract(request)
            .then(async (result: any) => {
                if (result.length > 0) {
                    let isEtype = "";
                    let isCreditCard = newCustList.paymentType !== undefined ? newCustList.paymentType.toString().toLocaleLowerCase() === "yes" ? true : false : false;
                    let isDigitalACH = result.paymentType !== undefined ? result.paymentType.toString().toLocaleLowerCase() === "digital ach" ? true : false : false;
                    let newEtypeList = isCreditCard ? ["Digital ACH", "Manual ACH", "Check", "Credit Card", "ePay"] : ["Digital ACH", "Manual ACH", "Check", "ePay"];
                    let currentAccType = newEtypeList.filter(item => item.toString().toLocaleLowerCase() === etypeName.toString().toLocaleLowerCase())
                    let setExixtingName = "";
                    // if (isDigitalACH) {
                    //     setExixtingName = "Digital ACH";
                    //     isEtype = "Right";
                    // } else {
                    if (currentAccType.length > 0) {
                        setExixtingName = currentAccType[0];
                        isEtype = "Right";
                    } else {
                        setExixtingName = "";
                        isEtype = "NotRight"
                    }
                    // }

                    tableData = tableData.map((items) => {
                        if (items.id === editedRow.id) {
                            return {
                                ...items, customerList: result,
                                customerNo: newCustList.accountNo,
                                etype: setExixtingName,
                                cusNoHasDach: isDigitalACH,
                                isEtype: isEtype,
                            };
                        } else {
                            return items;
                        }
                    });
                    this.setState((curr: any) => ({ ...curr, tableData }), () => this.isValidForm());
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    isSubVendorMatch = (row) => {
        let tableData = [...this.state.tableData];
        let parentNode = tableData.filter(item => (item.uniqueno === row.uniqueno && item.rowNo === 1));
        let parentVendor = parentNode.length > 0 && parentNode[0]?.vendor;
        let currentid: any = [];
        let hasUniqueRows: any = [];
        let isMatch = false;
        tableData.filter(item => {
            if (item.uniqueno === row.uniqueno) {
                hasUniqueRows.push(item);
            }
        })
        if (hasUniqueRows.length > 0) {
            hasUniqueRows.filter((item: any) => {
                if ((item.subvendor !== "" && item.subvendor !== null) && item.subvendor === parentVendor) {
                    currentid.push(item.id);
                } else {

                }
            })
        }
        if (currentid.length > 0) {
            currentid.filter(item => {
                if (item === row.id) {
                    isMatch = true;
                }
            })
        }
        return isMatch;
    }

    isSubVendorVelidate = () => {
        let tableData = [...this.state.tableData];
        let hasError = false;
        tableData.forEach(items => {
            let header: any = [];
            if (items.rowNo === 1) {
                let headerRow: any = {};
                headerRow.uniqueno = items.uniqueno;
                headerRow.vendor = items.vendor;
                headerRow.splitData = [];
                header.push(headerRow);
            }
            tableData.forEach(items2 => {
                if (items2.uniqueno === items.uniqueno) {
                    if (header.length > 0) {
                        let HeaderData = header.find(a => a.uniqueno === items2.uniqueno)
                        let splitRow: any = {};
                        splitRow.subvendor = items2.subvendor !== undefined && items2.subvendor !== null ? items2.subvendor : "";
                        HeaderData.splitData.push(splitRow)
                    }
                }
            });
            if (header.length > 0) {
                if (hasError !== true) {
                    header.forEach(header => {
                        hasError = header.splitData.filter(item => item.subvendor === header.vendor).length > 0;
                    })
                }
            }
        });
        return hasError;
    }

    isDuplicateInvoice = () => {
        let tableData = [...this.state.tableData];
        let match = 0;
        let i = 0;
        let k = 0;
        for (i = 0; i < tableData.length; i++) {
            match = 0;
            for (k = 0; k < tableData.length; k++) {
                if (tableData[k].invoiceNo === tableData[i].invoiceNo && tableData[k].backHid === tableData[i].backHid && tableData[k].vendor === tableData[i].vendor) {
                    match++;
                    tableData = tableData.map((items) => {
                        if (items.id === tableData[k].id) {
                            return { ...items, isDuplicate: match > 1 ? "Duplicate invoice No." : "Right" };
                        } else {
                            return items;
                        }
                    });
                }
            }
        }
        this.setState((curr: any) => ({ ...curr, tableData }), () => {
            this.isValidForm();
            this.isDueDateGreater();
        });
    }

    isDueDateGreater = () => {
        let tableData = [...this.state.tableData];
        tableData = tableData.map((items) => {
            if (items.srno === 1) {
                let isDueDate = new Date(items.duedate);
                let isBillDate = new Date(items.billdate);
                if (isBillDate > isDueDate) {
                    return { ...items, duedate: "" };
                } else {
                    return items;
                }
            } else {
                return items;
            }
        });
        this.setState((curr: any) => ({ ...curr, tableData }), () => {
            this.isValidForm()
        });
    }

    isValidForm = () => {
        let { tableData } = this.state;
        let isSubVendorVelidate = this.isSubVendorVelidate();
        tableData.map((element) => {
            let parentNode = element.rowNo === 1;
            let childNode = element.rowNo !== 1;
            let isActiveCOA = element.isActiveCOA.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            let isSubVendor = element.isSubVendor.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            let isInactiveVendor = element.isInactiveVendor.toString().toLowerCase() !== "Active".toLocaleLowerCase();
            let isActivePeriod = element.isActivePeriod.toString().toLowerCase() !== "Yes".toLocaleLowerCase();
            // let isVendorToHId = element.isVendorToHId.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            let isSplitPeriod = element.isSplitPeriod.toString().toLowerCase() !== "Active".toLocaleLowerCase();
            let isDuplicate = element.isDuplicate.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            let isEHID = element.isEHID.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            let checkEhid = element.checkEhid.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            let isGenericVendor = parentNode && element.vendor.toString().toLowerCase() !== "GENERIC VENDOR".toLocaleLowerCase();
            let isEtype = element.isEtype.toString().toLowerCase() !== "Right".toLocaleLowerCase();
            if (isActiveCOA || isInactiveVendor || isActivePeriod || /*isVendorToHId ||*/ (isSubVendor && isSplitPeriod) || isSubVendorVelidate || isEHID || checkEhid ||
                (parentNode && (element.splitHid === "" || element.amount === "" || isEtype ||
                    isDuplicate || element.vendor === "" || element.etype === "" || element.coa === "" || element.invoiceNo.toString().trim() === "" || element.accper === "" || element.billdate === "" || element.duedate === "" ||
                    (isGenericVendor && element.customerNo === "")
                )) ||
                (childNode && element.splitHid === "" || element.coa === "" || element.amount === "")
            ) {
                element.isException = true;
            } else {
                element.isException = false;
            }
        });
        this.setState({ tableData });
    }

    handleChange = (event: any, row: any, FieldType: string) => {
        let { tableData } = this.state;
        if (FieldType === "billdate") {
            let isDueDate = new Date(row.duedate);
            let isBillDate = event === null ? new Date() : event;
            let today = new Date(isBillDate);
            let month = (today.getMonth() + 1) <= 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
            let day = today.getDate() <= 9 ? "0" + today.getDate() : today.getDate();
            let year = today.getFullYear().toString().substr(-2);
            let dateNew = `${month}/${day}/${year}`;
            tableData = tableData.map((items) => {
                if (items.id === row.id) {
                    if (isBillDate > isDueDate) {
                        return { ...items, billdate: dateNew, duedate: '' };
                    } else {
                        return { ...items, billdate: dateNew };
                    }

                } else {
                    return items;
                }
            });
        } else if (FieldType === "duedate") {
            let isDueDate = event === null ? new Date() : event;
            let today = new Date(isDueDate);
            let month = (today.getMonth() + 1) <= 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
            let day = today.getDate() <= 9 ? "0" + today.getDate() : today.getDate();
            let year = today.getFullYear().toString().substr(-2);
            let dateNew = `${month}/${day}/${year}`;
            tableData = tableData.map((items) => {
                if (items.id === row.id) {
                    return { ...items, duedate: dateNew };
                } else {
                    return items;
                }
            });
        }
        this.setState((curr: any) => ({ ...curr, tableData, isSlideUpdated: true }), () => this.isValidForm());
    }

    cellParentEdit = cellEditFactory({
        mode: "click",
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            let { tableData } = this.state;
            let dataField = column.dataField;
            let index = tableData.indexOf(row);
            if (oldValue !== newValue) {
                if (dataField === "internalnotes") {
                    if (newValue.length > 100) {
                        row.internalnotes = newValue.substring(0, 100);
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isValidForm());
                    } else {
                        row.internalnotes = newValue;
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isValidForm());
                    }
                } else if (dataField === "invoiceNo") {
                    row.invoiceNo = newValue.toString().trim().substring(0, 100);
                    tableData[index] = row;
                    this.setState({ tableData }, () => this.isDuplicateInvoice());
                }
                this.setState({ tableData }, () => {
                    if (dataField === "invoiceNo") {
                        this.isDuplicateInvoice();
                    } else {
                        this.isValidForm()
                    }
                });
            }
        }
    })

    formatDollar(num) {
        return (Number(num).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    cellChildEdit = cellEditFactory({
        mode: "click",
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            let { tableData } = this.state;
            let dataField = column.dataField;
            let index = tableData.indexOf(row);
            if (oldValue !== newValue) {
                if (dataField === "description") {
                    if (newValue.length > 100) {
                        row.description = newValue.substring(0, 100);
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isValidForm());
                    } else {
                        row.description = newValue;
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isValidForm());
                    }
                } else if (dataField === "amount") {

                    newValue = Utils.removeInvaildCharFromAmount(newValue);
                    if (!isNaN(newValue)) {
                        if (newValue === "") {
                            row.amount = ''
                            tableData[index] = row;
                            this.setState({ tableData }, () => this.isValidForm());
                        } else {
                            row.amount = newValue !== "" ? newValue : newValue
                            tableData[index] = row;
                            this.setState({ tableData }, () => this.isValidForm());
                        }
                    } else {
                        row.amount = ""
                        tableData[index] = row;
                        this.setState({ tableData }, () => this.isValidForm());
                    }
                }
                this.setState({ tableData }, () => this.isValidForm());
            }
        }
    })

    clearVendorControl = (row) => {
        let { tableData } = this.state;
        tableData = tableData.map((items) => {
            if (items.id === row.id) {
                return { ...items, etype: "", isEtype: "notRight" };
            } else {
                return items;
            }
        });
        this.setState((curr: any) => ({ ...curr, tableData, isSlideUpdated: true }), () => this.isValidForm());
    }

    hasEpayValidAddress = (row) => {
        let { tableData } = this.state;
        tableData = tableData.map((items) => {
            if (items.id === row.id) {
                return { ...items, isVendor: "Right" };
            } else {
                return items;
            }
        });
        this.setState(() => ({ tableData, isSlideUpdated: true }), () => this.isValidForm());
    }

    vendorCheckEpayAddress = (row: any, request: any) => {
        registersevice.VendorCheckEpayAddress(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    let data = result.result.result;
                    if (data.toString().toLocaleLowerCase() === "invalid") {
                        confirmAlert({
                            title: "Alert",
                            message: "This vendor has an invalid address for making ePay payments.  Having only special characters in the State/Zip field or blank values in Street/City fields is not accepted. Please correct this and then proceed with selecting ePay as the payment method.",
                            buttons: [
                                { label: "OK", onClick: () => { reject(); this.clearVendorControl(row); } },
                                // { label: "No", onClick: () => reject(), },
                            ],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                        });
                    } else {
                        this.hasEpayValidAddress(row);
                    }
                } else {
                    this.hasEpayValidAddress(row);
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }

    handleRowEvent = (event: any, row: any, type: any) => {
        let { tableData } = this.state;
        if (type === "cusNo") {
            let currentAccType = "";
            let isDigitalACH;
            if (event.paymentType !== undefined) {
                isDigitalACH = event.paymentType.toString().toLocaleLowerCase() === "digital ach" ? true : false;
                if (event.paymentType.toString().toLocaleLowerCase() === "ach") {
                    currentAccType = "Manual ACH"
                } else if (event.paymentType.toString().toLocaleLowerCase() === "epay") {
                    currentAccType = event.paymentType;
                    let request: any = {};
                    request.VID = row.vid;
                    request.HID = row.hid;
                    request.Contract = event.accountNo;
                    row.vendor !== "" && this.vendorCheckEpayAddress(row, request);
                } else {
                    currentAccType = event.paymentType;
                    this.hasEpayValidAddress(row);
                }
            } else {
                if (row.etype.toString().toLocaleLowerCase() === "epay") {
                    currentAccType = row.etype;
                    let request: any = {};
                    request.VID = row.vid;
                    request.HID = row.hid;
                    request.Contract = event.accountNo;
                    row.vendor !== "" && this.vendorCheckEpayAddress(row, request);
                } else {
                    currentAccType = row.etype;
                    this.hasEpayValidAddress(row);
                }
            }
            tableData = tableData.map((items) => {
                if (items.id === row.id) {
                    return { ...items, etype: currentAccType, isEtype: "Right", cusNoHasDach: isDigitalACH, customerNo: event.accountNo };
                } else {
                    return items;
                }
            });
        } else if (type === "actPer") {
            tableData = tableData.map((items) => {
                if (items.id === row.id) {
                    return { ...items, accper: event, isActivePeriod: "Yes", isSplitPeriod: "Active" };
                } else {
                    return items;
                }
            });
        } else if (type === "etype") {
            //let currentAccType = row.etypeList.filter(item => item.toString().toLocaleLowerCase() === event.toString().toLocaleLowerCase())
            tableData = tableData.map((items) => {
                if (items.id === row.id) {
                    return { ...items, etype: event, isEtype: "Right" };
                } else {
                    return items;
                }
            });
            if (event.toString().toLowerCase() === "epay") {
                let request: any = {};
                request.VID = row.vid;
                request.HID = row.hid;
                request.Contract = row.customerNo;
                row.vendor !== "" && this.vendorCheckEpayAddress(row, request);
            } else {
                tableData = tableData.map((items) => {
                    if (items.id === row.id) {
                        return { ...items, etype: event, isVendor: "Right" };
                    } else {
                        return items;
                    }
                });
                this.hasEpayValidAddress(row);
                this.isDuplicateInvoice();
            }
        } else {

        }
        this.setState((curr: any) => ({ ...curr, tableData, isSlideUpdated: true }), () => this.isValidForm());
    }

    handleBackScreen = () => {
        confirmAlert({
            title: "Alert!",
            message: "All data will remove which you set to current step, Are you sure you want to redirect previous step?",
            buttons: [{
                label: "Yes", onClick: () => {
                    this.setState({
                        uploadFiles: [],
                        coaList: [],
                        vendorListMain: [],
                        accPeriodList: [],
                        ehidList: [],
                        tableData: [],
                        isExpand: [],
                        isFileImported: false,
                        isSlideUpdated: false,
                        //isAddContractPerm: false,
                        isVandorSpinnerPanel: false,
                        isCloseAlert: false,
                        editedRow: [],
                        isProgress: Math.floor(Math.random() * 30),
                    })
                },
            }, {
                label: "No", onClick: () => reject(),
            }],
        });
    }

    importData = () => {
        $("#btnImportData").prop('disabled', true);
        this.setState({ isImportData: true });
        let tableData = [...this.state.tableData];
        const storage = JSON.parse(localStorage.getItem("storage")!);
        const username = storage === null ? 0 : (storage.userName as any);
        const tenantId = storage === null ? 0 : (storage.tenantID as any);
        let InvoiceNo = tableData[0].invoiceno;
        let request: any = {}
        request.FileNo = tableData[0].fileNo;
        let importInfo: any = {};
        importInfo.TransType = "";
        importInfo.TenantID = tenantId;
        importInfo.Hid = this.state.hidValue;
        importInfo.OprId = tableData[0].oprId;
        importInfo.LoginUserName = username;
        request.ImportInfo = importInfo;
        let headerData: any = [];
        tableData.forEach(items => {
            let header: any = [];
            if (items.rowNo === 1) {
                let headerRow: any = {};
                headerRow.FileUniqueNo = items.uniqueno;
                headerRow.EHID = items.hid;
                headerRow.OprId = items.oprId;
                headerRow.VendorName = items.vendor;
                headerRow.InvoiceNo = items.invoiceNo;
                headerRow.InvoiceDate = items.billdate;
                headerRow.AccPer = items.accper;
                headerRow.DueDate = items.duedate;
                headerRow.InternalNotes = items.internalnotes;
                headerRow.CustomerNo = items.customerNo !== null && items.customerNo !== undefined && items.customerNo !== "" ? items.customerNo : items.hid;
                headerRow.Etype = items.etype;
                headerRow.splitData = [];
                header.push(headerRow);
            }
            tableData.forEach(items2 => {
                if (items2.uniqueno === items.uniqueno) {
                    if (header.length > 0) {
                        let HeaderData = header.find(a => a.FileUniqueNo === items2.uniqueno)
                        let splitRow: any = {};
                        splitRow.EHID = items2.splitHid !== undefined && items2.splitHid !== null ? items2.splitHid : "";
                        splitRow.COA = items2.coa !== undefined && items2.coa !== null ? items2.coa : "";
                        splitRow.SubVendor = items2.subvendor !== undefined && items2.subvendor !== null ? items2.subvendor : "";
                        splitRow.Description = items2.description !== undefined && items2.description !== null ? items2.description : "";
                        splitRow.Amount = items2.amount;
                        HeaderData.splitData.push(splitRow)
                    }
                }
            });
            if (header.length > 0) {
                header.forEach(header => {
                    let totalAmount = 0;
                    header.splitData.forEach(item => totalAmount += Number(item.Amount))
                    header.TotalAmt = totalAmount.toString();
                    headerData.push(header);
                })
            }
        });
        request.HeaderData = headerData;
        registersevice.SaveBulkTemplateData("importInvoice", request, tableData)
            .then(async (result: any) => {
                //messageCode
                if (result?.saveStatus.toString().toLowerCase() === "success".toLocaleLowerCase()) {
                    toast.success("Data imported successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({ isBulkUpdated: true, isImportData: false, isCloseAlert: false })
                    setTimeout(() => {
                        this.bulkInvoiceSlideoutClose();
                        $("#btnImportData").prop('disabled', false);
                    }, 1000);
                } else if (result?.saveStatus.toString().toLowerCase() === "Fail".toLocaleLowerCase()) {
                    let failResponse = result?.message.toString().toLowerCase();
                    if (failResponse === "PeriodNotActive".toLocaleLowerCase() || failResponse === "NotActive".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError("Please set active accounting period.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "Vendor does not exist.".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError("Please set active ", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "EHIDShouldbeSame".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError("EHID should be the same.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "SubVendorNotExist".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError("Sub Vendor does not exist..", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "FixedCOA".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError("Entry can not be made in Fixed COA.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "ConfidentialCOA".toLocaleLowerCase() || failResponse === "Confidential COA".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError("Transactions with Confidential COAs are not permitted.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "COANotExist".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError("COA does not exists.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "EHIDNotExist".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError("EHID does not exists.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "DuplicateInvoice".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError("Duplicate Invoice.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (failResponse === "customerNoNotexist".toLocaleLowerCase()) {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError("Account Number not exist.", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else {
                        $("#btnImportData").prop('disabled', false);
                        this.setState({ isImportData: false });
                        Utils.toastError(result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }
                $("#btnImportData").prop('disabled', false);
                this.setState({ isImportData: false })
                resolve();
            })
            .catch((error) => {
                $("#btnImportData").prop('disabled', false);
                this.setState({ isImportData: false })
                Utils.toastError(error?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }
    onTAB = (control, event) => { }
    render() {
        let tableData = [...this.state.tableData];
        let isException = tableData.filter(item => item.isException).length > 0;
        let isisSubVendor = tableData.filter(item => item.isException).length > 0;
        const files = this.state.uploadFiles.map(file => (
            <li key={file.name}>
                <div className="d-flex align-items-center">
                    <div className="file-name">{file.name}</div>
                    <div className="file-loader mr-auto"><ProgressBar variant="success" now={this.state.isProgress} /></div>
                    <div className="file-percentage">{this.state.isProgress} %</div>
                </div>
            </li>
        ));

        const notesIcon = (file) => {
            return (
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"></path>
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"></path>
                </svg>
            )
        }

        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                {props}
            </Tooltip>
        );

        const hoverOutEllipsis = () => {
            $(".tooltip").removeClass("show");
        };

        const renderMenuItemChildren = (option, props, index) => {
            return (
                <>
                    <div key={option.id}>
                        <div className="vendor-name">
                            <EllipsisWithTooltip placement="bottom">
                                {option.label}
                            </EllipsisWithTooltip>
                        </div>
                    </div>
                </>
            );
        }

        const hasCOAControl = (row: any, rowIndex: any) => {
            return (
                <div onWheel={hoverOutEllipsis.bind(this)}>
                    {this.state.coaList.length > 0 && (
                        <div className="more-action single-select-image dropdown coa-select jecoaSelect bulkInvoiceTypeheaddropdown">
                            <Typeahead
                                flip={true}
                                inputProps={{ className: "coa" + rowIndex.toString() }}
                                id={"coa-" + rowIndex.toString()}
                                options={this.state.coaList}
                                placeholder={"Enter COA"}
                                renderMenu={(results: any, menuProps) => (
                                    <Menu {...menuProps}>
                                        {results.map((result: any, index: any) => (
                                            <>
                                                <MenuItem option={result} position={index}>
                                                    <EllipsisWithTooltip placement="bottom">{result.label}</EllipsisWithTooltip>
                                                </MenuItem>
                                                {result.label == "" && (
                                                    <MenuItem className="load-more" option={result} position={index}>
                                                        Load More
                                                    </MenuItem>
                                                )}
                                                {(this.state.coaList.length > 0
                                                    && results[index - 1]?.orderno === 1 && result?.orderno === 2) && (
                                                        <Dropdown.Divider />
                                                    )}
                                            </>
                                        ))}
                                    </Menu>
                                )}
                                renderMenuItemChildren={renderMenuItemChildren}
                                onChange={(e) => this.handleSelectedItem(e, row, "coa")}
                                selected={row.defaultCOA}
                            />
                        </div>
                    )}
                </div>
            )
        }


        const vendorControl = (row: any, rowIndex: any,) => {
            return (
                <div onWheel={hoverOutEllipsis.bind(this)}>
                    <div className="">
                        {row.VendorList.length > 0 ? (
                            <div className="more-action single-select-image dropdown coa-select jecoaSelect bulkInvoiceTypeheaddropdown">
                                <Typeahead
                                    flip={true}
                                    inputProps={{ className: "vendor" + rowIndex.toString() }}
                                    id={"vendor-" + rowIndex.toString()}
                                    options={row.VendorList}
                                    placeholder={"Enter Vendor"}
                                    renderMenu={(results: any, menuProps) => (
                                        <Menu {...menuProps}>
                                            {results.map((result: any, index: any) => (
                                                <>
                                                    <MenuItem option={result} position={index}>
                                                        <EllipsisWithTooltip placement="bottom">{result.vendor}</EllipsisWithTooltip>
                                                    </MenuItem>
                                                    {result.label == "" && (
                                                        <MenuItem className="load-more" option={result} position={index}>
                                                            Load More
                                                        </MenuItem>
                                                    )}
                                                    {(row.VendorList.length > 0
                                                        && results[index - 1]?.orderno === 1 && result?.orderno === 2) && (
                                                            <Dropdown.Divider />
                                                        )}
                                                </>
                                            ))}
                                        </Menu>
                                    )}
                                    renderMenuItemChildren={renderMenuItemChildren}
                                    onChange={(e) => this.handleSelectedItem(e, row, "vendor")}
                                    selected={row.defaultVendor}
                                />
                            </div>
                        ) : (
                            <Dropdown className="more-action disabled-area bg-blue single-select-image">
                                <Dropdown.Toggle id="Modal-hid" className="filter-btn border-danger">
                                    <div className="d-flex align-items-center">
                                        <div className="txt-area">
                                            No vendor exist.
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        )}
                    </div>
                </div>
            )
        }

        const hasVendorControl = (row: any, rowIndex: any, isMessage: any) => {
            if (row.vendor === null || row.vendor === undefined || row.vendor === "" || row.isVendor !== "Right") {
                return (
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id={"VendorName"}>{isMessage}</Tooltip>
                        }>
                        {vendorControl(row, rowIndex)}
                    </OverlayTrigger>
                )
            } else {
                return vendorControl(row, rowIndex)
            }
        }

        const hasSubVendorControl = (row: any, rowIndex: any) => {
            return (
                <div onWheel={hoverOutEllipsis.bind(this)}>
                    <div className="">
                        {row.subVendorList.length > 0 && row.splitHid !== "" ? (
                            <div className="more-action single-select-image dropdown coa-select jecoaSelect bulkInvoiceTypeheaddropdown">
                                <Typeahead
                                    flip={true}
                                    inputProps={{ className: "subvendor" + rowIndex.toString() }}
                                    id={"vendor-" + rowIndex.toString()}
                                    options={row.subVendorList}
                                    placeholder={"Enter Sub Vendor"}
                                    renderMenu={(results: any, menuProps) => (
                                        <Menu {...menuProps}>
                                            {results.map((result: any, index: any) => (
                                                <>
                                                    <MenuItem option={result} position={index}>
                                                        <EllipsisWithTooltip placement="bottom">{result.vendor}</EllipsisWithTooltip>
                                                    </MenuItem>
                                                    {result.label == "" && (
                                                        <MenuItem className="load-more" option={result} position={index}>
                                                            Load More
                                                        </MenuItem>
                                                    )}
                                                    {(row.subVendorList.length > 0
                                                        && results[index - 1]?.orderno === 1 && result?.orderno === 2) && (
                                                            <Dropdown.Divider />
                                                        )}
                                                </>
                                            ))}
                                        </Menu>
                                    )}
                                    renderMenuItemChildren={renderMenuItemChildren}
                                    onChange={(e) => this.handleSelectedItem(e, row, "subVendor")}
                                    selected={row.defaultSubVendor}
                                />
                            </div>
                        ) : (
                            <Dropdown className="more-action disabled-area bg-blue single-select-image">
                                <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                    <div className="d-flex align-items-center">
                                        <div className="txt-area">
                                            No sub vendor exist.
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        )}
                    </div>
                </div>
            )
        }

        const hasAccPerControl = (row: any, rowIndex: any) => {
            return (
                <div className="singleSelect bulkInvoiceimportDropdown">
                    {row.accPeriodForEHID.length > 0 ? (
                        <Dropdown onSelect={(e) => this.handleRowEvent(e, row, "actPer")} className="more-action single-select-image">
                            <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                                <div className="d-flex align-items-center">
                                    <div className="txt-area">
                                        <EllipsisWithTooltip placement="bottom">{row.accper === "" ? "Select" : row.accper}</EllipsisWithTooltip>
                                    </div>
                                    <div className="drop-arrow ml-0 d-flex">
                                        <FiChevronDown />
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {row.accPeriodForEHID.map(
                                    (item: any, idx: any) => (
                                        <Dropdown.Item eventKey={item.name} key={idx}>
                                            <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                                        </Dropdown.Item>
                                    )
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <Dropdown className="more-action disabled-area bg-blue single-select-image">
                            <Dropdown.Toggle id="Modal-hid" className="filter-btn border-danger">
                                <div className="d-flex align-items-center">
                                    <div className="txt-area">
                                        No Accounting Period exist.
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                        </Dropdown>
                    )}
                </div>
            )
        }

        const hasAccTypeControl = (row: any, rowIndex: any) => {
            return (
                <div className="singleSelect bulkInvoiceimportDropdown">
                    <Dropdown onSelect={(e) => this.handleRowEvent(e, row, "etype")} className="more-action single-select-image">
                        <Dropdown.Toggle id="Modal-hid" className="filter-btn">
                            <div className="d-flex align-items-center">
                                <div className="txt-area">
                                    <EllipsisWithTooltip placement="bottom">{row.etype === "" ? "Select Type" : row.etype}</EllipsisWithTooltip>
                                </div>
                                <div className="drop-arrow ml-0 d-flex">
                                    <FiChevronDown />
                                </div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {row.ehidHasDach && row.venderHasDach && (
                                <Dropdown.Item eventKey={"Digital ACH"}>
                                    <EllipsisWithTooltip placement="bottom">Digital ACH</EllipsisWithTooltip>
                                </Dropdown.Item>
                            )}
                            {row.etypeList.map(
                                (item: any, idx: any) => (
                                    <Dropdown.Item eventKey={item} key={idx} className={item === "Digital ACH" ? "d-none" : ""}>
                                        <EllipsisWithTooltip placement="bottom">{item}</EllipsisWithTooltip>
                                    </Dropdown.Item>
                                )
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )
        }

        const hasDateControl = (row: any, rowIndex: any, type: any) => {
            if (type === "billdate") {
                return (
                    <div className="date-picker calender-picker bulkInvoiceDropdownSmall">
                        <div className="posRelative">
                            <label className="calanderOverlay" htmlFor={"billdate_" + rowIndex}></label>
                            <label htmlFor={"billdate_" + rowIndex} className="drop-arrow">
                                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                </svg>
                            </label>
                        </div>
                        <DatePicker
                            id={"billdate_" + rowIndex}
                            className={row.billdate === "" ? "border-danger form-control" : "form-control"}
                            placeholderText={"MM/DD/YY"}
                            dateFormat="MM/dd/yy"
                            autoComplete="off"
                            selected={row.billdate === "" ? "" : new Date(row.billdate)}
                            onSelect={(event: any) => this.handleChange(event, row, 'billdate')}
                            onChange={(event: any) => this.handleChange(event, row, 'billdate')}
                        />
                    </div>
                )
            } else {
                return (
                    <div className="date-picker calender-picker bulkInvoiceDropdownSmall">
                        <div className="posRelative">
                            <label className="calanderOverlay" htmlFor={"duedate_" + rowIndex}></label>
                            <label htmlFor={"duedate_" + rowIndex} className="drop-arrow">
                                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                </svg>
                            </label>
                        </div>
                        <DatePicker
                            id={"duedate_" + rowIndex}
                            className={row.duedate === "" ? "border-danger form-control" : "form-control"}
                            placeholderText={"MM/DD/YY"}
                            minDate={new Date(row.billdate)}
                            dateFormat="MM/dd/yy"
                            autoComplete="off"
                            selected={row.duedate === "" ? "" : new Date(row.duedate)}
                            onSelect={(event: any) => this.handleChange(event, row, 'duedate')}
                            onChange={(event: any) => this.handleChange(event, row, 'duedate')}
                        />
                    </div>
                )
            }
        }

        const hasEHIDControl = (row: any, rowIndex: any, type: any) => {
            if (type === "header") {
                return (
                    <div className="bulkInvoiceimportDropdown cusDDARight" onClick={hoverOutEllipsis.bind(this)}>
                        <SingleSearchDropdownList
                            itemList={this.state.ehidList}
                            handleSelectedItem={(e) => this.handleSelectedItem(e, row, "ehidHeaderSelector")}
                            defaultItem={row.backHid === "" || row.backHid === null ? "EHID" : row.backHid}
                            defaultText={"Search EHID"}
                            defaultName={row.backHid === "" || row.backHid === null ? "EHID" : row.backHid}
                            controlID="1"
                            id={"ddlEHID-" + rowIndex.toString()}
                            onTAB={this.onTAB.bind(this, "ddlEHID-" + rowIndex.toString())}
                        />
                    </div>
                )
            } else {
                return (
                    <div className="bulkInvoiceimportDropdown cusDDARight">
                        <SingleSearchDropdownList
                            itemList={row.splitHidList}
                            handleSelectedItem={(e) => this.handleSelectedItem(e, row, "ehidSplitSelector")}
                            defaultItem={row.splitHid === "" ? "EHID" : row.splitHid}
                            defaultText={"Search EHID"}
                            defaultName={row.splitHid === "" ? "EHID" : row.splitHid}
                            controlID="1"
                            id={"ddlEHID-" + rowIndex.toString()}
                            onTAB={this.onTAB.bind(this, "ddlEHID-" + rowIndex.toString())}
                        />
                    </div>
                )
            }
        }

        const importParentColumns = [
            {
                dataField: 'backHid', text: 'EHID', headerClasses: "parentCol1", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.backHid === "" || row.backHid === null) {
                        isMessage = "Please enter a valid EHID.";
                        isInvalidControl = true;
                    } else if (row.checkEhid.toString().toLowerCase() !== "Right".toLocaleLowerCase()) {
                        isMessage = "EHID does not exist!";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                <div className="hasErrorInControl">{hasEHIDControl(row, rowIndex, "header")}</div>
                            </div>
                        )
                    } else {
                        return (<div className="noErrorInControl">{hasEHIDControl(row, rowIndex, "header")}</div>)
                    }
                }
            },
            {
                dataField: 'vendor', text: 'Vendor', headerClasses: "parentCol2", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    // if (row.isVendorToHId !== undefined && row.isVendorToHId.toString().toLowerCase() !== "Right".toLocaleLowerCase()) {
                    //     isMessage = row.isVendorToHId.split("Please")[0].trim();
                    //     isInvalidControl = true;
                    // } else 
                    if (row.isInactiveVendor !== undefined && row.isInactiveVendor.toString().toLowerCase() !== "Active".toLocaleLowerCase()) {
                        isMessage = "Inactive contract!";
                        isInvalidControl = true;
                    } else if (row.defaultVendor.length === 0 || row.vendor === "" || row.vendor === null) {
                        isMessage = "Please enter a valid Vendor.";
                        isInvalidControl = true;
                    }
                    else if (row.isVendor !== "Right") {
                        isMessage = row.isVendor;
                        isInvalidControl = true;
                    }
                    else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl">
                                {/* <div className="toolTipIn">{isMessage}</div> */}
                                <div className="hasErrorInControl">{hasVendorControl(row, rowIndex, isMessage)}</div>
                            </div>
                        )
                    } else {
                        return (
                            <div className="noErrorInControl">{hasVendorControl(row, rowIndex, isMessage)}</div>
                        )
                    }
                }
            }, {
                dataField: 'customerNo', text: 'Customer No.', headerClasses: "parentCol8", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isGenericVendor = row.vendor.toString().toLowerCase() !== "GENERIC VENDOR".toLocaleLowerCase();
                    let hasValidVendor = row.VendorList.some((item: any) => item.vendor === row.vendor);
                    let isMessage = "Account No. does not exist!";
                    if (hasValidVendor && row.ehid !== "" && row.vendor.toString().toLowerCase() !== "GENERIC VENDOR".toLocaleLowerCase()) {
                        return (
                            <div className="isCusNoField hasVendorSearch posStatic">
                                {row.customerNo === "" && row.customerList.length == 0 && (
                                    <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                        <div onClick={(e) => this.GetVendorContract(row.vid, row, "custNo")}
                                            className={this.state.isAddContractPerm ?
                                                "ddBorderDanger singleSelect importDropdown importDropdownSmall hasCustomerList" :
                                                "disabled-area ddBorderDanger singleSelect importDropdown importDropdownSmall hasCustomerList"}>
                                            <Dropdown className="more-action single-select-image posUnset">
                                                <Dropdown.Toggle id="Modal-hid" className="filter-btn posRelative">
                                                    <div className="d-flex align-items-center">
                                                        <div className="txt-area">
                                                            <EllipsisWithTooltip placement="bottom">
                                                                {row.customerNo === ""
                                                                    ? "Add" : row.customerNo}
                                                            </EllipsisWithTooltip>
                                                        </div>
                                                        <div className="add-icon ml-0 d-flex">
                                                            {row.isVandorSpinner ? (
                                                                <Spinner size="sm" variant="success" animation="border" />) : (
                                                                <svg stroke="currentColor" fill="#2caf92" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </div>
                                    </div>
                                )}
                                {(row.customerNo !== "" || row.customerList.length > 0) &&
                                    (<>
                                        <div className={row.customerNo === "" ? "customTooltipControl" : ""}><div className="toolTipIn">{row.customerNo === "" && "Please Select a valid account No."}</div>
                                            <div className={row.customerNo === "" ?
                                                "ddBorderDanger singleSelect importDropdown importDropdownSmall hasCustomerList hasFixButton" :
                                                "singleSelect importDropdown importDropdownSmall hasCustomerList hasFixButton"}
                                            >

                                                <Dropdown
                                                    onClick={(e) => this.GetVendorContract(row.vid, row, "custNo")}
                                                    className="more-action single-select-image posUnset">
                                                    <Dropdown.Toggle id="Modal-hid" className="filter-btn posRelative">
                                                        <div className="d-flex align-items-center">
                                                            <div className="txt-area">
                                                                <EllipsisWithTooltip placement="bottom">
                                                                    {row.customerNo === "" ? "Select" : row.customerNo}</EllipsisWithTooltip>
                                                            </div>
                                                            <div className="drop-arrow ml-0 d-flex">
                                                                <FiChevronDown />
                                                            </div>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="scrollableArea">
                                                            {row.customerList !== undefined && row.customerList.map((item: any, idx: any) => (
                                                                <Dropdown.Item eventKey={JSON.stringify(item)} key={idx} onSelect={(e) => this.handleRowEvent(item, row, "cusNo")}>
                                                                    <EllipsisWithTooltip placement="bottom">{item.accountNo}</EllipsisWithTooltip>
                                                                </Dropdown.Item>
                                                            ))}
                                                        </div>
                                                        {this.state.isAddContractPerm && (
                                                            <Dropdown.Item onClick={(e) => this.addNewCustomerNo(row)} style={{ borderTop: "1px solid #e0e3e6" }}>
                                                                <EllipsisWithTooltip placement="bottom"
                                                                ><strong style={{ color: "#2caf92" }}>Add New Contract</strong> </EllipsisWithTooltip>
                                                            </Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </>
                                    )}
                            </div>
                        )
                    }
                    else {
                        return (
                            <Form.Control disabled={true} className={isGenericVendor ? "border-danger" : ""} type="text" placeholder={isGenericVendor ? "Cust. No" : ""} />
                        )
                    }
                }
            }, {
                dataField: 'etype', text: 'Type', headerClasses: "parentColType", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.etype === "" || row.isEtype.toString().toLowerCase() !== "Right".toLocaleLowerCase()) {
                        isMessage = "Please select Type.";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                <div className="hasErrorInControl">{hasAccTypeControl(row, rowIndex)}</div></div>
                        )
                    } else {
                        return (<div className="noErrorInControl">{hasAccTypeControl(row, rowIndex)}</div>)
                    }
                }
            }, {
                dataField: 'invoiceNo', text: 'Invoice No.', headerClasses: "parentCol3",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.invoiceNo.toString().trim() !== "" && row.isDuplicate.toString().toLowerCase() !== "Right".toLocaleLowerCase()) {
                        isMessage = "Invoice No is duplicate!";
                        isInvalidControl = true;
                    } else if (row.invoiceNo.toString().trim() === "") {
                        isMessage = "Please enter invoice No.";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                <div className="hasRowError"><EllipsisWithTooltip placement="bottom">{row.invoiceNo.toString().trim()}</EllipsisWithTooltip></div>
                            </div>
                        )
                    } else {
                        return (
                            <div className="validEntry"><EllipsisWithTooltip placement="bottom">{row.invoiceNo.toString().trim()}</EllipsisWithTooltip></div>
                        )
                    }
                }
            }, {
                dataField: 'billdate', text: 'Invoice Date', headerClasses: "parentCol4", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.billdate === "") {
                        isMessage = "Please enter a valid invoice date.";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                <div className="hasErrorInControl">{hasDateControl(row, rowIndex, "billdate")}</div></div>
                        )
                    } else {
                        return (<div className="noErrorInControl">{hasDateControl(row, rowIndex, "billdate")}</div>)
                    }
                }
            }, {
                dataField: 'accper', text: 'A/C PERIOD', headerClasses: "parentCol5", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.accper === "") {
                        isMessage = "Please select accounting period.";
                        isInvalidControl = true;
                    } else {
                        if (row.isActivePeriod.toString().toLowerCase() !== "Yes".toLocaleLowerCase()) {
                            isMessage = "Inactive accounting period";
                            isInvalidControl = true;
                        } else if (row.isSplitPeriod.toString().toLowerCase() !== "Active".toLocaleLowerCase()) {
                            isMessage = row.isSplitPeriod.split("Please")[0].trim();
                            isInvalidControl = true;
                        } else {
                            isMessage = "";
                            isInvalidControl = false;
                        }
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                <div className="hasErrorInControl">{hasAccPerControl(row, rowIndex)}</div></div>
                        )
                    } else {
                        return (<div className="noErrorInControl">{hasAccPerControl(row, rowIndex)}</div>)
                    }
                }
            }, {
                dataField: 'duedate', text: 'Due Date', headerClasses: "parentCol6", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    let isMessage = "";
                    let isInvalidControl = false;
                    if (row.duedate === "") {
                        isMessage = "Please enter a valid due date.";
                        isInvalidControl = true;
                    } else {
                        isMessage = "";
                        isInvalidControl = false;
                    }
                    if (isInvalidControl) {
                        return (
                            <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                                {hasDateControl(row, rowIndex, "duedate")}
                            </div>
                        )
                    } else {
                        return (<div className="noErrorInControl">{hasDateControl(row, rowIndex, "duedate")}</div>)
                    }


                }
            }, {
                dataField: 'internalnotes', text: 'Notes', headerClasses: "parentCol7",
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <div className="validEntry">
                            <EllipsisWithTooltip placement="bottom">{row.internalnotes}</EllipsisWithTooltip>
                        </div>
                    )
                }
            },
            { dataField: 'coa', text: '', hidden: true },
            { dataField: 'subvendor', text: '', hidden: true },
            { dataField: 'description', text: '', hidden: true },
            { dataField: 'amount', text: '', hidden: true },
            {
                dataField: "upload", text: <span className="IconPaperclip"><FiPaperclip /></span>, headerClasses: "iconCol", classes: "iconCol", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <button type="button" className="btn btn-primary fa-trash bulkInvoicebtnDropzone">
                            <div className="bulkInvoicebtnDropzone">
                                <Dropzone key={row.uniqueno} onDrop={(e) => this.onAttachmentDrop(e, row.uniqueno)}
                                    accept="image/*,application/pdf,
                                            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                            application/vnd.ms-excel,
                                            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                            text/plain,
                                            .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                >
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                        <input id="addAttachment" {...getInputProps()} title={row.attachmentMain.length > 99 ? (row.attachmentMain.length) : ""} />
                                    )}
                                </Dropzone>
                                <div className="bulkInvoicebtnDropzoneIn">
                                    <span className="IconPaperclip"><FiPaperclip /></span>
                                    {row.attachmentMain.length > 0 && (
                                        <span className="fileCounter">
                                            {row.attachmentMain.length > 99 ? 99 + "+" : row.attachmentMain.length}
                                        </span>)}
                                </div>
                            </div>
                        </button>
                    )
                }
            }, {
                dataField: "action", text: "", headerClasses: "iconCol", classes: "iconCol", editable: false,
                formatter: (cell: any, row: any, rowIndex: any) => {
                    return (
                        <OverlayTrigger placement="top" overlay={<Tooltip id="addContract">Delete Row</Tooltip>}>
                            <button type="button" disabled={tableData.filter(item => item.rowNo === 1).length < 2} onClick={(e) => { this.deleteCurrentRow(row) }} className="btn btn-primary fa-trash">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="trashSVGIcon">
                                    <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path><path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                                </svg>
                            </button>
                        </OverlayTrigger>
                    )
                }
            }];

        const importChildColumns = [{
            dataField: 'splitHid', text: 'Split Hid', headerClasses: "childCol1", editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => {
                let hasValidEhid = row.splitHidList.filter(item => item.id === row.splitID).length > 0;
                let isMessage = "";
                let isInvalidControl = false;
                if (row.splitHid === "" || !hasValidEhid) {
                    isMessage = "Please enter a valid Split EHID.";
                    isInvalidControl = true;
                } else if (row.isEHID.toString().toLowerCase() !== "Right".toLocaleLowerCase()) {
                    isMessage = "Split EHID does not exist!";
                    isInvalidControl = true;
                } else {
                    isMessage = "";
                    isInvalidControl = false;
                }
                if (isInvalidControl) {
                    return (
                        <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                            <div className="hasErrorInControl">{hasEHIDControl(row, rowIndex, "split")}</div>
                        </div>
                    )
                } else {
                    return (<div className="noErrorInControl">{hasEHIDControl(row, rowIndex, "split")}</div>)
                }
            }
        }, {
            dataField: 'coa', text: 'COA', headerClasses: "childCol2", editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => {
                let isMessage = "";
                let isInvalidControl = false;
                if (row.defaultCOA.length === 0) {
                    isMessage = "Please enter a valid COA";
                    isInvalidControl = true;
                } else {
                    isMessage = "";
                    isInvalidControl = false;
                }
                if (isInvalidControl) {
                    return (
                        <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                            <div className="hasErrorInControl">{hasCOAControl(row, rowIndex)}</div>
                        </div>
                    )
                } else {
                    return (<div className="noErrorInControl">{hasCOAControl(row, rowIndex)}</div>)
                }
            }
        }, {
            dataField: 'subvendor', text: 'Sub Vendor', headerClasses: "childCol3", editable: false,
            formatter: (cell: any, row: any, rowIndex: any) => {
                let isMessage = "";
                let isInvalidControl = false;
                // if (row.defaultSubVendor.length === 0 || row.subvendor === "") {
                //     isMessage = "Please enter a valid Sub Vendor.";
                //     isInvalidControl = true;
                // } else 
                if (this.isSubVendorMatch(row)) {
                    isMessage = "Vendor and sub vendor can not be same.";
                    isInvalidControl = true;
                } else if (row.subvendor !== null && row.subvendor !== "") {
                    isMessage = row.subvendor;
                    isInvalidControl = false;
                } else {
                    isMessage = "";
                    isInvalidControl = false;
                }
                if (isInvalidControl) {
                    return (
                        <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                            <div className="hasErrorInControl">{hasSubVendorControl(row, rowIndex)}</div>
                        </div>
                    )
                } else {
                    return (<div className="noErrorInControl customTooltipControl">
                        {(row.subvendor !== null && row.subvendor !== "") && (<div className="toolTipIn">{isMessage}</div>)}
                        {hasSubVendorControl(row, rowIndex)}</div>)
                }
            }
        }, {
            dataField: 'description', text: 'Description', headerClasses: "childCol4",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <div className="validEntry">
                        <EllipsisWithTooltip placement="bottom">{row.description}</EllipsisWithTooltip>
                    </div>
                )
            }
        }, {
            dataField: 'amount', text: 'Amount', headerClasses: "amountCol", classes: "amountCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                let isInvalidControl = false;
                let isMessage = "";
                if (row.amount === "") {
                    isMessage = "Invalid Amount.";
                    isInvalidControl = true;
                } else {
                    isMessage = "";
                    isInvalidControl = false;
                }
                if (isInvalidControl) {
                    return (
                        <div className="customTooltipControl"><div className="toolTipIn">{isMessage}</div>
                            <div className="hasRowError"><EllipsisWithTooltip placement="bottom">{row.amount}</EllipsisWithTooltip></div>
                        </div>
                    )
                } else {
                    return (<div className="validEntry"><EllipsisWithTooltip placement="bottom">{Utils.currencyFormatWithOutdoller(row.amount)}</EllipsisWithTooltip></div>)
                }
            }
        }]

        const handleOnExpand = (row, isExpand, rowIndex, e) => {
            if (isExpand) {
                this.setState(() => ({
                    isExpand: [...this.state.isExpand, row.id]
                }));
            } else {
                this.setState(() => ({
                    isExpand: this.state.isExpand.filter(x => x !== row.id)
                }));
            }
        }

        const expandBulkImportRow = {
            parentClassName: "parent-expand-foo",
            renderer: (row: any, rowIndex: any) => renderChild(rowIndex, row),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expanded: this.state.isExpand,
            onExpand: handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <div></div>;
                }
                return <div></div>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="chevronExpended">
                            <img src={chevronRight} alt="chevron Right" />
                        </span>
                    );
                }
                return (
                    <span className="chevronCollpsed">
                        <img src={chevronRight} alt="chevron Right" />
                    </span>
                );
            },
        };

        const columnAttachments = (mainRow) => [{
            dataField: 'attachment',
            headerClasses: "attachmentCol",
            classes: "attachmentCol",
            text: '',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        {notesIcon(row)}
                    </>
                )
            }
        }, {
            dataField: 'name',
            text: 'Name',
            headerClasses: "fileNameCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <>
                        <EllipsisWithTooltip placement="bottom">{row.name.split(/\.(?=[^\.]+$)/)[0]}</EllipsisWithTooltip>
                        <div className="dateSection"><EllipsisWithTooltip placement="bottom">{new Date(row.lastModified).toLocaleDateString()}</EllipsisWithTooltip></div>
                    </>
                )
            }
        }, {
            dataField: 'type',
            headerClasses: "fileTypeCol",
            text: 'File Type',
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom"><span className="badge badgeOrange">{row.path.split(/\.(?=[^\.]+$)/)[1]}</span></EllipsisWithTooltip>
                )
            }
        }, {
            dataField: 'action',
            text: '',
            headerClasses: "iconCol",
            classes: "iconCol",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="addContract">Delete Row</Tooltip>}>
                        <button type="button"
                            onClick={(e) => { this.deleteCurrentAttachment(rowIndex, mainRow) }}
                            className="btn btn-primary fa-trash">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="trashSVGIcon">
                                <path d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"></path><path d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"></path>
                            </svg>
                        </button>
                    </OverlayTrigger>
                )
            }
        }]

        const renderChild = (rowIndex, row) => {
            let { tableData } = this.state;
            let uniqueID = Math.floor(Math.random() * 90 + 10);
            let uniqueID123 = Math.floor(Math.random() * 90 + 10);
            return (
                <div className="bulkInvoiceImportChildTable">
                    <div className="childHeader"><strong>Splits</strong></div>
                    <BootstrapTable
                        key={uniqueID}
                        id={uniqueID}
                        keyField='id'
                        data={tableData.filter(item => item.uniqueno === row.uniqueno)}
                        columns={importChildColumns}
                        cellEdit={this.cellChildEdit}
                    />
                    {row.attachmentMain.length > 0 ? (
                        <>
                            <div className="mt-3 mb-3 childHeader"><strong>Attachments</strong></div>
                            <div className="bulkInvoiceattachmentTable">
                                <BootstrapTable
                                    key={uniqueID123}
                                    id={uniqueID123}
                                    keyField='id'
                                    data={row.attachmentMain}
                                    columns={columnAttachments(row)}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="mt-3 mb-3 childHeader"><strong>Attachments</strong></div>
                            <div className="bulkInvoiceUploadSection">
                                <Dropzone key={row.uniqueno} onDrop={(e) => this.onAttachmentDrop(e, row.uniqueno)}
                                    //accept=".xlsx,.xls,.csv"
                                    accept="image/*,application/pdf,
                                                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                                application/vnd.ms-excel,
                                                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                text/plain,
                                                .doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                >
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                        <>
                                            <section className={isDragActive ? "upload-area default drag-active " : "upload-area default"}>
                                                <div {...getRootProps({ className: 'dropzone d-flex align-items-center' })}>
                                                    <input {...getInputProps()} />
                                                    <p>Drag and drop or <span>browse files</span> to upload</p>
                                                </div>
                                            </section>
                                        </>
                                    )}
                                </Dropzone>
                            </div>
                        </>
                    )}
                </div>
            );
        }

        const bulkResponsiveList = () => {
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        {/* <div className="tableHeader height-40 loadingAnimation"></div> */}
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <>
                <ToastContainer autoClose={3000} enableMultiContainer />
                <Modal className={this.state.isFileImported ? "import-credit-card-result-modal importCreditCardModal" : "import-credit-card-modal"}
                    centered
                    show={true}
                    onHide={this.bulkInvoiceSlideoutClose}
                    backdrop="static"
                    dialogClassName={this.state.isFileImported ? "modal-100w" : "modal-60w"}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.isFileImported ?
                                (<>Bulk Import Invoice<div className="sub-heading">{this.state.uploadFiles[0]?.path}</div></>) :
                                (<>Bulk Import Invoice</>
                                )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={this.state.key === "import-data" ? "" : "NoScroll"}>
                        {!this.state.isFileImported ? (
                            <>
                                <Dropzone onDrop={this.onDrop} accept=".xlsx,.csv" multiple={false}>
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                        <>
                                            {this.state.uploadFiles.length == 0 && (
                                                <section className={isDragActive ? "container upload-area default drag-active " : "container upload-area default"}>
                                                    <div {...getRootProps({ className: 'dropzone d-flex align-items-center' })}>
                                                        <input {...getInputProps()} />
                                                        <p>Drag and drop here to start <br /><strong className="acceptedFiles">.xlsx or .csv</strong></p>
                                                    </div>
                                                </section>
                                            )}
                                            {this.state.uploadFiles.length > 0 && (
                                                <aside className="d-flex flex-column file-list">
                                                    <ul>{files}</ul>
                                                </aside>
                                            )}
                                        </>
                                    )}
                                </Dropzone>
                                {this.state.uploadFiles.length === 0 && (
                                    <div className="bulkInvoiceImportPanel">
                                        <Link to="#" className="templateLink" onClick={this.downloadTemplate}>Download Template</Link>
                                    </div>
                                )}
                            </>
                        ) : (<>
                            <ToolkitProvider
                                keyField="rNo"
                                data={this.state.tableData}
                                columns={importParentColumns}
                                search
                            >
                                {(props: {
                                    searchProps: JSX.IntrinsicAttributes;
                                    baseProps: JSX.IntrinsicAttributes;
                                }) => (
                                    <>
                                        <div className="bulkInvoiceImportSearchPanel">
                                            <SearchBar {...props.searchProps} placeholder={"Search..."} />
                                        </div>
                                        {(this.state.isSpinner) ? (
                                            <div className="bulkInvoiceImportList">
                                                <div className="bulkInvoiceImportList1 d-flex">
                                                    <div className="single-loader loadingAnimation"></div>
                                                </div>
                                                <>{bulkResponsiveList()}</>
                                                <>{bulkResponsiveList()}</>
                                                <>{bulkResponsiveList()}</>
                                            </div>
                                        ) : (
                                            <div className="grid-sec cusGridSec">
                                                <div className="bulkInvoiceImportedNavTabs">
                                                    <div className="scroll-area bulkInvoiceImportedTable">
                                                        <BootstrapTable
                                                            {...props.baseProps}
                                                            keyField="id"
                                                            data={this.state.tableData.filter(item => item.rowNo === 1)}
                                                            hover
                                                            noDataIndication={'No record found.'}
                                                            expandRow={expandBulkImportRow}
                                                            cellEdit={this.cellParentEdit}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>)}
                            </ToolkitProvider>
                        </>)}
                    </Modal.Body>
                    {this.state.isFileImported && (
                        <Modal.Footer>
                            <button type="button"
                                onClick={this.handleBackScreen}
                                className="btn-outline-primary btn btn-primary mr-auto">Back</button>
                            <Button
                                id="btnImportData"
                                onClick={this.importData}
                                disabled={this.state.isSpinner || this.state.isImportData || isException}
                                variant="primary">
                                {this.state.isSpinner || this.state.isImportData && <><Spinner size="sm" animation="border" />{" "}</>} Import Data
                            </Button>
                        </Modal.Footer>
                    )}


                    <div className="isCusNoField hasVendorSearch">
                        <VendorSearch
                            ref={this.vendorSearch}
                            updateVendorList={this.updateVendorList}
                            handleVendorChange={this.handleVendorChange}
                            getCustomerNo={this.getCustomerNo}
                            HID={this.state.currentRowHid}
                            callFromPage="CreditCardStatementImport"
                            updateCustomerList={this.updateCustomerList}
                        />
                    </div>
                </Modal>
            </>
        );
    }
}