import React, { useEffect, useRef, useState } from 'react';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoTooltip } from '../../Common/Components/InfoTooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUpLong, faCheck, faClose } from "@fortawesome/free-solid-svg-icons";

export default (props) => {
  const [ascSort, setAscSort] = useState('active');
  const [descSort, setDescSort] = useState('inactive');
  const [noSort, setNoSort] = useState('inactive');
  const refButton = useRef(null);

  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current);
  };

  const onSortChanged = () => {
    setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');
    setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');
    setNoSort(
      !props.column.isSortAscending() && !props.column.isSortDescending()
        ? 'active'
        : 'inactive'
    );
  };

  const onSortRequested = (order, event) => {
    if(props.enableSorting && typeof props["getLastSortedColumnDataFromHeader"]=== "function") {
      props.getLastSortedColumnDataFromHeader({order,event,props});
    }
    props.setSort(order, event.shiftKey);
  };

  useEffect(() => {
    props.column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();
  }, []);

  let menu: any = null;
  if (props.enableMenu) {
    menu = (
      <div
        ref={refButton}
        className="customHeaderMenuButton"
        onClick={() => onMenuClicked()}
      >
        <i className={`fa ${props.menuIcon}`}></i>
      </div>
    );
  }

  let sort: any = null;
  if (props.enableSorting) {
    sort = (
      <div style={{ display: 'inline-block' }} className="sort-control">
        <div
          onClick={(event) => onSortRequested('desc', event)}
          onTouchEnd={(event) => onSortRequested('desc', event)}
          className={`customSortDownLabel ${ascSort}`}
        >
          {/* <i className="fa fa-long-arrow-alt-down">down {ascSort}</i> */}
          <FontAwesomeIcon icon={faArrowUpLong} />
        
        </div>
        <div
          onClick={(event) => onSortRequested('', event)}
          onTouchEnd={(event) => onSortRequested('', event)}
          className={`customSortUpLabel ${descSort}`}
        >
          {/* <i className="fa fa-long-arrow-alt-up">up {descSort}</i> */}
          <FontAwesomeIcon icon={faArrowDown} />
        </div>
        
      </div>
    );
  }

  const handleSorting=(event)=>{
    if(!props.enableSorting)
     return
    // console.log(JSON.stringify(props.column.colDef.sortingOrder)  == JSON.stringify(['asc', 'desc',null]))
    if(JSON.stringify(props?.column?.colDef?.sortingOrder)  == JSON.stringify(['asc', 'desc',null]))
    {
      if(noSort === "active"){
        onSortRequested('asc', event)
      }
      else if (ascSort === "active"){
        onSortRequested('desc', event)
      }
      else if (descSort === "active"){
        onSortRequested('', event)
      }
    }
    else{
      if(noSort === "active"){
        onSortRequested('desc', event)
      }
      else if (descSort === "active"){
        onSortRequested('asc', event)
      }
      else if (ascSort === "active"){
        onSortRequested('desc', event)
      }
    }
     
  }

  return (
    <div className='d-flex align-items-center' onClick={handleSorting}>

      <div className={`customHeaderLabel ${props.tooltip ? "": "visualize-chart-icon"}`}>
        {props.isIcon ? <div className="visualize-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M10 11.414C10.0928 11.5069 10.203 11.5807 10.3243 11.631C10.4456 11.6813 10.5757 11.7072 10.707 11.7072C10.8383 11.7072 10.9684 11.6813 11.0897 11.631C11.211 11.5807 11.3212 11.5069 11.414 11.414L16.414 6.414L15 5L10.707 9.293L8.414 7C8.32121 6.90705 8.21101 6.83332 8.08969 6.78301C7.96838 6.73269 7.83833 6.7068 7.707 6.7068C7.57567 6.7068 7.44562 6.73269 7.32431 6.78301C7.20299 6.83332 7.09279 6.90705 7 7L2 12L3.414 13.414L7.707 9.121L10 11.414Z" fill="#3F4347" />
          </svg>
        </div> : props.displayName}
      </div>


      {/* {props.tooltip} */}
      {props.tooltip && <OverlayTrigger
        key={props.displayName}
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id={props.displayName} key={props.displayName}>
            {props.tooltip}
          </Tooltip>
        }
      >
        <span className='info-icon'>
          <InfoTooltip />
        </span>
      </OverlayTrigger>}
      
      {sort}


    </div>
  );
};