import moment from 'moment';
import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import Calendar from "react-calendar";
import OutsideClickHandler from 'react-outside-click-handler';

function SaveRoomDateModal({showSaveModal,hideSaveModal,calendarSelectedDate,handleCalendarDateChange,currentSelectedDate,onChange,handleOnSave,isDataSubmitting}) {
    const [calenderStartDateShow,setCalenderStartDateShow] =useState(false)
    const calenderDateHide = () => {
        setCalenderStartDateShow(false)
      };
    
      const calenderDateDisplay = () => {
        setCalenderStartDateShow(true)
      };
  
    return (
    <div>
         <Modal
            className="add-room-type-modal"
            show={showSaveModal}
            onHide={hideSaveModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // keyboard={this.props?.showDeductionModal}
          >
            <Modal.Body>
              <Form.Group
                controlId="date-selection"
                className={
                  !calendarSelectedDate
                    ? "validation-error d-flex flex-row date-picker"
                    : "d-flex flex-row date-picker"
                }
              >
                <Form.Label>Select Date</Form.Label>
                <div className="calender">
                  <div
                    className={`calender-container ${
                      !calendarSelectedDate && "validation-error"
                    }`}
                  >
                    <div
                      className={
                        calenderStartDateShow
                          ? "calender-display open"
                          : "calender-display"
                      }
                    >
                      <svg
                        onClick={calenderDateDisplay}
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z" />
                        <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z" />
                      </svg>
                      <input
                        // onFocus={this.calenderDateDisplay}
                        onClick={calenderDateDisplay}
                        onInput={(e) => {
                          let value = e.currentTarget.value;

                          value = value?.toString()?.replace(/[^0-9\/]+/gi, "");

                          handleCalendarDateChange(value);
                        }}
                        type="text"
                        placeholder="MM/DD/YYYY"
                        maxLength={8}
                        name="hireDate"
                        autoComplete="hireDate"
                        value={
                          calendarSelectedDate &&
                          currentSelectedDate != "Select date"
                            ? calendarSelectedDate
                            : moment()?.format("MM/DD/YYYY")
                        }
                        className="form-control single-date-pick"
                      />
                      {!calendarSelectedDate && (
                        <span className="validation-message">
                          Please enter Date.
                        </span>
                      )}
                    </div>

                    {calenderStartDateShow && (
                      <div className="calender">
                        <OutsideClickHandler
                          onOutsideClick={calenderDateHide}
                        >
                          <Calendar
                            calendarType={"US"}
                            onChange={(date)=> {
                              onChange(date);  
                              calenderDateHide();}}
                            value={
                              calendarSelectedDate
                                ? moment(calendarSelectedDate)?.format(
                                    "MM/DD/YYYY"
                                  ) === "Invalid date"
                                  ? currentSelectedDate &&
                                    currentSelectedDate != "Select date"
                                    ? new Date(currentSelectedDate)
                                    : new Date()
                                  : new Date(calendarSelectedDate)
                                : currentSelectedDate &&
                                  currentSelectedDate != "Select date"
                                ? new Date(currentSelectedDate)
                                : new Date()
                            }
                          />
                        </OutsideClickHandler>
                      </div>
                    )}
                  </div>
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-primary btn-discard"
                onClick={hideSaveModal}
              >
                Cancel
              </button>
              <button
                disabled={!calendarSelectedDate || isDataSubmitting}
                className="btn btn-primary"
                onClick={handleOnSave}
              >
                Save
              </button>
            </Modal.Footer>
          </Modal>
    </div>
  )
}

export default SaveRoomDateModal
