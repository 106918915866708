import * as React from "react";
import {
  Form,
  Tabs,
  Tab,
  Row,
  Col,
  Dropdown,
  Button,
  OverlayTrigger,
  Tooltip,
  Overlay
} from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import {
  Link,
  // Redirect
} from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import "react-input-range/lib/css/index.css";
import {
  IRolePermissionResponseTreeViewDto,
  INewRolePermissionDto,
  IDeleteRolePermissionDto,
} from "../../../Common/Contracts/IRolePermission";
import {
  Roles,
  RolesforTreeView,
  RoleMatrixClass
} from "../../../Common/Services/RolePermissionSetting";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { resolve, reject } from "q";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import PageLoader from "../../../Common/Components/PageLoader";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import scrollIntoView from "scroll-into-view";
import MasterRoleLock from "../../../Common/Assets/Images/icons/forecast-lock.svg";
import Spinner from "react-bootstrap/Spinner";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../RolePermission/LowSecurity.scss";
import { confirmAlert } from "react-confirm-alert";
import { Utils } from "../../../Common/Utilis";

let isMatchedNodeParentID: any[] = [];
let removePermissionID: any[] = [];
let addPermissionID: any[] = [];
let selectedLowRoleID: any;

export class RolePermissionRightPanel extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordReset: "No",
      passwordResetoldState: "",
      selectAllCall: false,
      rightPanelColor: "right-panel",
      key: "Permissions",
      roleTitle: "",
      tableData: [],
      oldData: [],
      filterText: "",
      removePermissionID: [],
      addPermissionID: [],
      duplicateRoleName: "",
      buttonText: "Save Changes",
      isSaveButtonDisable: false,
      totalRoleCount: 0,
      oldStateBeforeCheck: [],
      totalAssignCount: 0,
      roleSelected: "Select",
      roleTagSelected: "Select a new tag...",
      roleTagValue: "",
      newRoleIDAssign: 0,
      oldRoleIDtoDelete: 0,
      noOfAssignedUsertoRole: 0,
      deleteReassignCssClass: "btn-default btn btn",
      deleteReassignDisable: true,
      departmentDangerWell: "newassignrole",
      roleTagDangerWell: "",
      isRoleTagSelected: false,
      show: false,
      iconCollapse: "chevronCollpsed",
      checked: "checked",
      selectUnselctAll: "Select All",
      newRoleTextBox: "form-control",
      expandAll: true,
      collapseAll: false,
      expandcollapse: "Expand All",
      expandallclass: "plus-minus-toggle collapsed",
      classCaret: "caret",
      loader: false,
      isActionFooter: false,
      isMoreDivShow: "No",
      isAutocomplete: false,
      isRPanelHide: false,
      isDuplicate: false,
      isMoreItemShow: false,
      isDuplicatePanel: false,
      panelDelete: false,
      roleListforDelete: [],
      isTextBoxShow: false,
      backColor: "",
      fieldData: {
        roleID: 0,
        roleName: "",
        roletype: "",
        orderNo: 0,
        assignedUserCount: 0,
        tenantID: 0,
        hotelID: 0,
        resetPwd: "",
        departmentCount: 0,
        isMasterRole: false,
        roleTag: "",
        isLowSecurityRole: ""
      },
      userAssignedDDN: false,
      userAssignedArray: [],
      isLowRoleSelected: false,
      selectedLowRole: [],
      lowRoleOptions: [],
      totalHotelCount: "0",
      totalEntityCount: "0",
      defaultData: [],
      hideLSToggle: true,
      isFromLowSecurity: false
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleRolesRight = this.handleRolesRight.bind(this);
    this.handleRolesLeft = this.handleRolesLeft.bind(this);
    this.cancelDeleteRole = this.cancelDeleteRole.bind(this);
    this.handleDeleteRole = this.handleDeleteRole.bind(this);
  }

  onFilterChange(e) {
    this.setState({ filterText: e.target.value });
    let checkData = [...this.state.tableData];
    for (let index = 0; index < checkData.length; index++) {
      isMatchedNodeParentID = [];
      this.findAllMatchedNode(checkData[index], e.target.value);
    }
  }

  findAllMatchedNode(obj, textToSearch) {
    if (obj.children.length > 0) {
      for (let i = 0; i < obj.children.length; i++) {
        this.findAllMatchedNode(obj.children[i], textToSearch);
        if (textToSearch !== "") {
          if (obj.menuName.toLowerCase().includes(textToSearch.toLowerCase())) {
            obj.isExpend = true;
            obj.isRotate = "isRotated";
            obj.backColorClass = "rolePermissonMenuName searched";
            isMatchedNodeParentID.push(obj.parentID);
          } else if (isMatchedNodeParentID.includes(obj.childID)) {
            obj.isExpend = true;
            obj.isRotate = "isRotated";
            obj.backColorClass = "rolePermissonMenuName searched";
            isMatchedNodeParentID.push(obj.parentID);
          } else {
            obj.backColorClass = "rolePermissonMenuName";
            obj.isExpend = false;
            obj.isRotate = "";
          }
        } else {
          obj.isExpend = false;
          obj.isRotate = "";
          obj.backColorClass = "rolePermissonMenuName";
        }
      }
    } else {
      if (textToSearch !== "") {
        if (obj.menuName.toLowerCase().includes(textToSearch.toLowerCase())) {
          obj.isExpend = true;
          obj.isRotate = "isRotated";
          obj.backColorClass = "rolePermissonMenuName searched";
          isMatchedNodeParentID.push(obj.parentID);
        } else if (isMatchedNodeParentID.includes(obj.childID)) {
          obj.isExpend = true;
          obj.isRotate = "isRotated";
          obj.backColorClass = "rolePermissonMenuName searched";
          isMatchedNodeParentID.push(obj.parentID);
        } else {
          obj.isExpend = false;
          obj.isRotate = "";
          obj.backColorClass = "rolePermissonMenuName";
        }
      } else {
        obj.isExpend = false;
        obj.isRotate = "";
        obj.backColorClass = "rolePermissonMenuName";
      }
    }
  }

  changeTreeNodeKey = (result: any) => {
    result = result.map((item: any, id: any) => {
      return {
        id: id,
        isExpend: this.state.expandcollapse === "Expand All" ? false : true,
        helpText: item.helpText,
        shortNo: item.shortNo,
        backColorClass: "rolePermissonMenuName",
        menuName: item.menuName,
        isSensetive: item.isSensetive,
        isSpecial: item.isSpecial,
        selectAll: "Select All",
        isRotate: "",
        parentID: item.parentID,
        childID: item.childID,
        isChecked: item.isChecked,
        modulePermisisonCount: item.modulePermisisonCount,
        permisionCount: item.permisionCount,
        children: [],
      };
    });
    this.setState({ oldData: result });
    result.forEach((item: any) => {
      if (item.parentID !== "0") {
        try {
          let parent = result.find((a) => item.parentID === a.childID);
          //parent.children = parent.children || [];
          parent.children =
            typeof parent.children !== "undefined" && (parent.children || []);
          if (parent.permisionCount === parent.modulePermisisonCount) {
            parent.selectAll = "Unselect All";
          }
          parent.children.push(item);
        } catch { console.log(item) }
      }
    });

    result = result.filter(({ parentID }) => parentID === "0");

    this.sortNestedRolePermissionArray(result);

    this.setState({
      tableData: result,
      nodes: result,
      loader: false,
    });
  };
  sortNestedRolePermissionArray = (resultArray) => {
    resultArray.sort(function (a, b) {
      if (a.shortNo !== "0") {
        let aa = a.shortNo || 0,
          bb = b.shortNo || 0;
        return bb < aa;
      } else {
        if (a.menuName < b.menuName) {
          return -1;
        }
        if (a.menuName > b.menuName) {
          return 1;
        }
        return 0;
      }
    });
  };

  showChilds(e: any, targetNode: any) {
    //if(e.target.checked===true || e.target.checked===undefined){
    //if(e.target.type!=="checkbox"){
    const targetNodeId = targetNode.id;
    if (targetNode.id === targetNodeId)
      if (targetNode.isExpend === false) {
        targetNode.isExpend = true;
        targetNode.isRotate = "isRotated";
      } else {
        targetNode.isExpend = false;
        targetNode.isRotate = "";
      }
    let newTableData = [...this.state.tableData];
    this.setState({ tableData: newTableData });
    scrollIntoView(e.currentTarget, { align: { top: 0, topOffset: 70 } });
    //}
    //}
  }

  findChildsCheckbox(data, index) {
    let result;
    let foundResult;
    const i = (data.children || []).findIndex((child) => {
      if (child.isChecked === "Yes") foundResult = true;
      return (result = this.findChildsCheckbox(child, index));
    });
    if (result) return [i, foundResult];
  }
  findParentCheckforCheckbox(data, index) {
    let foundResult = false;
    const i = (data.children || []).findIndex((child) => {
      if (child.isChecked === "Yes") {
        foundResult = true;
        return [foundResult];
      }
      this.findParentCheckforCheckbox(child, index);
    });
    return [foundResult];
  }

  checkParentforCheckbox(data, index, chkType) {
    if (data.id === index) if (chkType === "Yes") data.isChecked = "Yes";
    //else data.isChecked = "No";
    if (data.parentID === "0") {
      data.permisionCount = "0";
      addPermissionID = [];
      this.findAllCheckedUnchecked(data, 0);
      let addPermissionIDs = Array.from(new Set(addPermissionID));
      data.permisionCount = String(addPermissionIDs.length);
    }
    let resultParent;
    resultParent = this.state.oldData.filter(
      ({ childID }) => childID === data.parentID
    );

    if (resultParent.length > 0) {
      let ifFound = this.findParentCheckforCheckbox(
        resultParent[0],
        resultParent[0].id
      );
      if (ifFound[0]) chkType = "Yes";
      // else chkType = "No";

      this.checkParentforCheckbox(resultParent[0], resultParent[0].id, chkType);
    }
  }

  checkChildsforCheckbox = (data, index, chkType) => {
    if (data.id === index)
      if (chkType === "Yes") data.isChecked = "Yes";
      else data.isChecked = "No";

    let resultChild;

    resultChild = this.state.oldData.filter(
      ({ parentID }) => parentID === data.childID
    );
    if (resultChild.length > 0) {
      for (let index = 0; index < resultChild.length; index++) {
        this.checkChildsforCheckbox(
          resultChild[index],
          resultChild[index].id,
          chkType
        );
      }
    }
  };

  checkChange(targetNode: any, event) {
    if (this.state.fieldData.roleName.toLowerCase() !== "administrator") {
      const targetNodeId = targetNode.id;
      let chkType;
      if (targetNode.isChecked === "Yes") chkType = "No";
      else chkType = "Yes";
      this.checkChildsforCheckbox(targetNode, targetNodeId, chkType);
      this.checkParentforCheckbox(targetNode, targetNodeId, chkType);
      this.setState({ isActionFooter: true }, () => {
        this.props.changeValueFuntion(true);
      });
    }
  }

  findAllChilds(obj, id, selectType) {
    if (selectType.toLocaleLowerCase() === "select all") {
      obj.isChecked = "Yes";
      obj.isExpend = true;
      obj.isRotate = "isRotated";
      obj.selectAll = "Unselect All";
    } else {
      obj.isExpend = true;
      obj.isChecked = "No";
      obj.isRotate = "isRotated";
      obj.selectAll = "Select All";
    }
    if (obj.children) {
      for (let i = 0; i < obj.children.length; i++) {
        let x = this.findAllChilds(obj.children[i], id, selectType);
        if (x) return { ...x, pos: i };
      }
    }
    return obj.id === id ? obj : false;
  }

  selectAll = (targetNode: any) => {
    if (this.state.fieldData.roleName.toLowerCase() !== "administrator") {
      const targetNodeId = targetNode.id;
      let selectType = targetNode.selectAll;
      let data2 = this.findAllChilds(targetNode, targetNodeId, selectType);
      let newTableData = [...this.state.tableData];
      if (targetNodeId > -1) {
        newTableData.splice(targetNodeId, 1, data2);
      }
      if (targetNode.parentID === "0") {
        targetNode.permisionCount = "0";
        addPermissionID = [];
        this.findAllCheckedUnchecked(targetNode, 0);
        let addPermissionIDs = Array.from(new Set(addPermissionID));
        targetNode.permisionCount = String(addPermissionIDs.length);
      }
      this.setState({ selectAllCall: true });
      this.setState({ isActionFooter: true }, () => {
        this.props.changeValueFuntion(true);
      });
      localStorage.setItem("ClickEventType", JSON.stringify("SelectALL"));
      this.props.changeValueFuntion(true);
    }
  };

  setShow = (currentstate: any, childID: any) => {

    this.setState({ showToolTip: !currentstate });
    if ($("#" + childID).css("display") == "none") {
      $("#" + childID).css("display", "block");
    } else {
      $("#" + childID).css("display", "none");
    }
  };
  redirectToSpecialTab = () => {
    this.props.handleSelect("SpecialPermission");
  };

  showSpan = (spanID: any) => {
    if ($("#" + spanID).css("display") == "none") {
      $("#" + spanID).css("display", "block");
    } else {
      $("#" + spanID).css("display", "none");
    }
  };

  renderTree = (node) => {
    let hasChild = node.children.length > 0 ? "" : "rotateIconDisplayRole";
    let display = node.parentID === "0" ? "none" : "inline";
    let cursor = this.state.fieldData.roleName.toLowerCase() === "administrator" ? "default" : "cursor";
    let blur = this.state.fieldData.isLowSecurityRole.toString().toLocaleLowerCase() === "yes" ? ".5" : node.isSpecial === "0" ? "1" : ".5";
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let loginRole = storage === null ? 0 : (storage.roleName as any);
    return (
      <div
        id={`parentid_${node.childID}`}
        onClick={(e) => {
          e.stopPropagation();
          this.showChilds(e, node);
        }}
        className={node.parentID === "0" ? "row-Action" : ""}
        style={{
          paddingTop: 16,
          paddingBottom: 8,
          borderTop: "1px solid #e0e3e6",
          //opacity:blur
        }}
      >
        {node.parentID === "0" ? <div className="border-line"></div> : null}
        <span className="subHeading" style={{ opacity: blur }}>
          {/* <img
            className={[node.isRotate, hasChild].join(" ")}
            src={chevronRight}
            alt="chevron Right"
          ></img> */}
          <svg className={[node.isRotate, hasChild].join(" ")} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.92247 14.7558L13.6783 9.99997L8.92247 5.24414L7.74414 6.42247L11.3216 9.99997L7.74414 13.5775L8.92247 14.7558Z" fill="#84888C" />
          </svg>
        </span>

        <label className="form-check" style={{ display: display, opacity: blur }}>
          <input
            type="checkbox"
            className="form-check-input"
            checked={
              node.isChecked === "Yes" && node.isSpecial === "0" ? true : false
            }
            value={node.childID}
            style={{ cursor: cursor }}
            disabled={this.state.fieldData.roleName.toLowerCase() === "administrator" ? true : node.isSpecial !== "0" ? true : this.state.fieldData.isLowSecurityRole.toString().toLocaleLowerCase() === "yes" ? true : false}
            onChange={(e: any) => {
              e.stopPropagation();
              this.checkChange(node, e);
            }}
          ></input>
        </label>
        <span
          className={
            node.parentID === "0"
              ? "rolePermissonModuleName"
              : node.backColorClass
          }
          style={{ opacity: blur }}
        >
          {node.menuName}
        </span>
        {node.isSensetive === "0" ? null : (
          <span className="badge orange-bg">Sensitive</span>
        )}
        {node.helpText === "" ? null : (
          <OverlayTrigger
            placement="bottom"
            overlay={this.renderHelpTextTooltip(node.helpText)}
          >
            <span className="help-icon">
              <svg
                stroke="currentColor"
                fill="#6a6e73"
                stroke-width="0"
                version="1.1"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
              </svg>{" "}
            </span>
          </OverlayTrigger>
        )}
        {node.isSpecial === "0" ? null : (
          <OverlayTrigger
            placement="bottom"
            trigger="click"
            rootClose={true}
            overlay={
              <Popover id="specialPermission" style={{ background: "#000", borderRadius: ".25rem" }}>
                <Popover.Content style={{ color: "#fff" }}>
                  Please view{" "}
                  {loginRole?.toLowerCase() === "administrator" ?
                    <span
                      style={{ cursor: "pointer", color: "#2caf92" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        this.redirectToSpecialTab();
                        document.body.click()
                      }}
                    >
                      Special Permissions
                    </span> : <span>Special Permissions</span>
                  }
                  {" "} to manage this permission.
                </Popover.Content>
              </Popover>
            }
          >
            <span className="help-icon">
              <svg
                stroke="currentColor"
                fill="#6a6e73"
                stroke-width="0"
                version="1.1"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
                <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
                <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
              </svg>{" "}
            </span>
          </OverlayTrigger>
        )}
        <Form.Group as={Row} controlId="node" className="totalPermissionCount">
          {node.parentID === "0" ? (
            <Col sm="9">
              <span className="subHeading">
                {/* <img
                  className="totalpermissioncountimg"
                  src={chevronRight}
                  alt="chevron Right"
                ></img> */}
                <svg className="totalpermissioncountimg" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.92247 14.7558L13.6783 9.99997L8.92247 5.24414L7.74414 6.42247L11.3216 9.99997L7.74414 13.5775L8.92247 14.7558Z" fill="#84888C" />
                </svg>
              </span>
              <label>
                {node.permisionCount === "0"
                  ? node.permisionCount
                  : node.permisionCount - 1}{" "}
                / {node.modulePermisisonCount - 1}
              </label>
            </Col>
          ) : null}
          {node.parentID === "0" ? (
            <Col sm="3" className="colSelectAllRolePermission">
              <Link
                to="#"
                className={
                  (this.state.fieldData.roleName.toLowerCase() === "administrator" || this.state.fieldData.isLowSecurityRole.toString().toLocaleLowerCase() === "yes")
                    ? "isDisabledSelectAllRolePermissionAnchor"
                    : "isEnabledSelectAllRolePermissionAnchor"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  this.selectAll(node);
                }}
              >
                {node.selectAll}
              </Link>
            </Col>
          ) : null}
        </Form.Group>
        {node.parentID === "0" ? (
          <div
            id={node.parentID}
            className={
              node.isExpend
                ? "rolePermissionTreeView collapse show"
                : "collapse"
            }
          >
            <div className="permissionNameHeading">Permission Name</div>

            {node.children.length > 0 &&
              node.children.map((childNode: any, id: any) => {
                if (id >= 0) {
                  return (
                    <div
                      key={id}
                      className={
                        node.isExpend
                          ? "childDivTreeRolePermission collapse show"
                          : "collapse"
                      }
                      id={`childid_${node.childID}`}
                    >
                      <div className="border-line"></div>
                      <div>{this.renderTree(childNode)}</div>
                    </div>
                  );
                } else {
                  return false;
                }
              })}
          </div>
        ) : (
          node.children.length > 0 &&
          node.children.map((childNode: any, id: any) => {
            if (id >= 0) {
              return (
                <div
                  key={id}
                  className={
                    node.isExpend
                      ? "childDivTreeRolePermission collapse show"
                      : "collapse"
                  }
                  id={`childid_${node.childID}`}
                >
                  <div className="border-line"></div>

                  <div>{this.renderTree(childNode)}</div>
                </div>
              );
            } else {
              return false;
            }
          })
        )}
      </div>
    );
  };

  findAllExpandandCollapse(obj, id) {
    if (obj.children) {
      for (let i = 0; i < obj.children.length; i++) {
        if (this.state.expandcollapse === "Expand All") {
          obj.isExpend = true;
          obj.isRotate = "isRotated";
          this.setState({
            expandcollapse: "Collapse All",
            expandallclass: "plus-minus-toggle",
            selectUnselctAll: "Select All",
            isRotate: "isRotated",
          });
        } else {
          obj.isExpend = false;
          obj.isRotate = "";
          this.setState({
            expandcollapse: "Expand All",
            expandallclass: "plus-minus-toggle collapsed",
            selectUnselctAll: "Select All",
            isRotate: "",
          });
        }
        let x = this.findAllExpandandCollapse(obj.children[i], id);
        if (x) return { ...x, pos: i };
      }
    }
  }

  collapseexpand() {
    let checkData = [...this.state.tableData];
    for (let index = 0; index < checkData.length; index++) {
      this.findAllExpandandCollapse(checkData[index], 0);
    }
    localStorage.setItem("ClickEventType", JSON.stringify("SelectALL"));
  }
  handleRowClick(data: any) {
    this.setState({ userAssignedArray: [], loader: true, });
    this.getRoleAssignedUsers(data);
    this.setState({
      roleListforDelete: this.props.roleListforDelete,
      hideLSToggle: data?.isLowSecurityRole.toString().toLocaleLowerCase() === "yes" ? false : true,
      fieldData: data,
      deleteReassignDisable: true,
      isActionFooter: false,
      newRoleTextBox: "",
      isAutocomplete: false,
      isRPanelHide: false,
      isDuplicate: false,
      isRActions: true,
      show: false,
      panelDelete: false,
      isTextBoxShow: false,
      filterText: "",
      isMoreItemShow: false,
      backColor: "",
      isMoreDivShow: "No",
      departmentDangerWell: "newassignrole",
      roleSelected: "Select a new role...",
      roleTagDangerWell: "",
      noOfAssignedUsertoRole: data.assignedUserCount,
      oldRoleIDtoDelete: data.roleid,
      passwordResetoldState: data.resetPwd,
      roleTagValue: data.roleTag,
      isNewRole: false,
      roleTitle: "",
      // isLowRoleSelected: data.roleName?.toLowerCase() === "low security" ? true : false,
      lowRoleOptions: this.props.roleListforDelete,
    });

    // localStorage.setItem("roleid", data.roleid);
    localStorage.setItem("roleid", data.roleid);
    // if (data.roleName?.toLowerCase() === "low security") {
    //   setTimeout(() => {
    //     let { lowRoleOptions } = this.state;
    //     this.props.roleListforDelete.map(
    //       (item, index) => (
    //         (item["label"] = item.roleName)
    //       )
    //     );
    //     let filterResult = lowRoleOptions.filter(
    //       (item) => item.roleName.toLowerCase() !== 'low security'
    //     );
    //     this.setState({ lowRoleOptions: filterResult });
    //   }, 0);

    //   RolesforTreeView.GetPasswordAssistanceRole()
    //     .then(async (result: any | null) => {
    //       if (result != null) {
    //         let roleData = result.result[0]?.result?.split(',');
    //         let selectedList = [] as any;
    //         if (roleData[0] !== "0") {
    //           for (let i = 0; i < roleData.length; i++) {
    //             const selectedName = this.props.roleListforDelete.filter((item) => item.roleID === roleData[i])
    //             let lst = {} as any;
    //             lst.label = selectedName[0].roleName;
    //             lst.roleName = selectedName[0].roleName;
    //             lst.roleID = selectedName[0].roleID;
    //             lst.lowSecurityStatus = selectedName[0].lowSecurityStatus;
    //             lst.assignedDept = selectedName[0].assignedDept;
    //             lst.assignedUsers = selectedName[0].assignedUsers;
    //             lst.isMasterRole = selectedName[0].isMasterRole;
    //             lst.orderNo = selectedName[0].orderNo;
    //             lst.resetPwd = selectedName[0].resetPwd;
    //             lst.roleTag = selectedName[0].roleTag;
    //             lst.rowNo = selectedName[0].rowNo;
    //             lst.type = selectedName[0].type;
    //             selectedList.push(lst)
    //           }
    //           this.setState({ selectedLowRole: selectedList }, () => {
    //             let request = {} as any;
    //             request.RoleID = result.result[0]?.result
    //             this.getLowSecurityCountHCovered(request, 'load')
    //           });
    //         } else {
    //           this.setState({ selectedLowRole: [] }, () => {
    //             let request = {} as any;
    //             request.RoleID = 0
    //             this.getLowSecurityCountHCovered(request, 'load')
    //           })
    //         }
    //       }
    //       resolve();
    //     })
    //     .catch((error) => {
    //       this.setState({ loader: false });
    //       reject();
    //     });

    // } else {

      RolesforTreeView.GetUserRoleforTreeView(data.roleid)
        .then(async (result: IRolePermissionResponseTreeViewDto[] | null) => {
          if (result != null) {
            if (data.roleTag === "") {
              this.setState({
                roleTagSelected: "Select a new tag...",
                isRoleTagSelected: false,
              });
            } else {
              this.bindRolesTagsList(data.roleTag, "rowClick");
            }
            this.setState({
              expandcollapse: "Expand All",
              expandallclass: "plus-minus-toggle collapsed",
              selectUnselctAll: "Select All",
              tableData: [],
              defaultData: result,
            });

            this.changeTreeNodeKey(result);
          }

          this.setState({
            fieldData: data,
            isNewRole: false,
            key: "Permissions",
          });
          let tabType = JSON.parse(localStorage.getItem("tabType")!);
          if (tabType !== "") {
            this.setState({
              key: tabType,
            });
            localStorage.setItem("tabType", JSON.stringify(""));
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loader: false });
          reject();
        });
    // }
  }

  getLowSecurityCountHCovered = (request: any, callType) => {
    RolesforTreeView.GetCountHCovered(request)
      .then(async (result: any | null) => {
        if (result != null) {
          if (Number(result.result[0].result.split(':')[1]) === Number(result.result[0].result.split(':')[0]) && callType === 'Change') {
            this.setState({ isActionFooter: true }, () => {
              this.props.changeValueFuntion(true);
            });
          } else {
            this.setState({ isActionFooter: false }, () => {
              this.props.changeValueFuntion(false);
            })
          }
          this.setState({
            loader: false,
            totalHotelCount: result.result[0].result.split(':')[1],
            totalEntityCount: result.result[0].result.split(':')[0]
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ loader: false });
        reject();
      });
  }

  lowRoleSelectorChange(e: any) {
    this.setState({ selectedLowRole: e, loader: true });
    selectedLowRoleID = "";
    let request = {} as any;
    let lowRoles = "";
    e.map(
      (item, index) => (
        lowRoles += item.roleID + ","
      )
    );
    let roleIDs = e.length > 0 ? String(lowRoles.replace(/,\s*$/, '')) : '0';
    request.RoleID = roleIDs;
    selectedLowRoleID = roleIDs;
    this.getLowSecurityCountHCovered(request, 'Change');
  }

  getRoleAssignedUsers = (data) => {
    let requestObject: any = {};
    requestObject.rolId = Number(data.roleid);

    Roles.GetUserAssingedList(requestObject)
      .then((data) => {
        this.setState({ userAssignedArray: data });
        resolve();
      })
      .catch((err) => {
        reject();
      });
  };

  sortPermissionName = (result: any) => {
    result.sort(function (a: any, b: any) {
      if (a.parentID !== "0") {
        if (a.shortNo === "0") {
          if (a.menuName < b.menuName) {
            return -1;
          }
          if (a.menuName > b.menuName) {
            return 1;
          }
        } else {
          if (a.shortNo < b.shortNo) {
            return -1;
          }
          if (a.shortNo > b.shortNo) {
            return 1;
          }
        }
      }
      return 0;
    });
  };

  handlePasswordReset(event: any) {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    if (event.target.checked) {
      fieldData.resetPwd = "Yes";
    } else {
      fieldData.resetPwd = "No";
    }
    if (!this.state.isNewRole && !this.state.isDuplicate) {
      if (
        this.state.passwordResetoldState === "Yes" &&
        fieldData.resetPwd === "No"
      ) {
        this.setState({ isActionFooter: true }, () => {
          this.props.changeValueFuntion(true);
        });
        this.setState({ isPasswordReset: "Yes" });
      } else if (
        this.state.passwordResetoldState === "No" &&
        fieldData.resetPwd === "Yes"
      ) {
        this.setState({ isActionFooter: true }, () => {
          this.props.changeValueFuntion(true);
        });
        this.setState({ isPasswordReset: "Yes" });
      } else {
        this.setState({ isActionFooter: false }, () => {
          this.props.changeValueFuntion(false);
        });
        this.setState({ isPasswordReset: "No" });
      }
    } else {
      this.setState({ isActionFooter: true }, () => {
        this.props.changeValueFuntion(true);
      });
    }

    // this.setState({ isActionFooter: true }, () => {
    //   this.props.changeValue(true);
    // });
  }

  handleLowSecurityRole = (event: any) => {
    let { fieldData, defaultData } = this.state;
    let isChecked = event.target.checked;
    fieldData.isLowSecurityRole = isChecked ? "Yes" : "No";
    this.setState({ fieldData, loader: true });
    if (this.state.isNewRole || this.state.isDuplicate) {
      this.setState({ isFromLowSecurity: true });
      if (isChecked) {
        confirmAlert({
          title: "Warning",
          message: 'This setting is only intended for users who need password reset assistance and maintain low levels of access to the application. Enabling this will remove all permissions from this role, which cannot be undone.  Are you sure you would like to proceed?',
          buttons: [
            {
              label: "Confirm",
              className: "confirmAlert btnSuccess",
              onClick: () => {
                defaultData.forEach(item => item.isChecked = "No");
                let sendidString: any = [];
                defaultData.forEach(item => item.parentID !== 0 && sendidString.push(item.childID));
                this.setState({ defaultData }, () => {
                  this.changeTreeNodeKey(this.state.defaultData);
                  this.handleRolesRight();
                  toast.success("Permission updated successfully.", { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                });
              },
            },
            {
              label: "Cancel",
              className: "confirmAlert btnDiscard",
              onClick: () => {
                fieldData.isLowSecurityRole = "No";
                this.setState({ fieldData, loader: false });
                reject()
              },
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      } else {
        defaultData.forEach(item => item.isChecked = "No");
        let sendidString: any = [];
        defaultData.forEach(item => item.parentID !== 0 && sendidString.push(item.childID));
        this.setState({ defaultData }, () => {
          this.changeTreeNodeKey(this.state.defaultData);
          this.handleRolesRight();
        });
      }
      this.setState({ loader: false });
    } else {
      if (isChecked) {
        confirmAlert({
          title: "Warning",
          message: 'This setting is only intended for users who need password reset assistance and maintain low levels of access to the application. Enabling this will remove all permissions from this role, which cannot be undone.  Are you sure you would like to proceed?',
          buttons: [
            {
              label: "Confirm",
              className: "confirmAlert btnSuccess",
              onClick: () => {
                RolesforTreeView.RoleApplicableForLowSecurity(Number(this.state.fieldData.roleid))
                  .then(async (result: any | null) => {
                    if (result.toString().toLocaleLowerCase() === "success") {
                      defaultData.forEach(item => item.isChecked = "No");
                      let sendidString: any = [];
                      defaultData.forEach(item => item.parentID !== 0 && sendidString.push(item.childID));
                      this.setState({ defaultData }, () => {
                        this.changeTreeNodeKey(this.state.defaultData);
                        this.UpdateRolePermissionTreeViewData(sendidString);
                      });
                    } else if (result.toString().toLocaleLowerCase() === "specialpermissionusers") {
                      fieldData.isLowSecurityRole = "No";
                      Utils.toastError("There are one or more users within this role with special permissions. To proceed, please remove the special permissions for those users.",
                        { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                    } else if (result.toString().toLocaleLowerCase() === "invoiceapplavel") {
                      fieldData.isLowSecurityRole = "No";
                      Utils.toastError("User role exists for Invoice Approval Rule.", { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                    } else if (result.toString().toLocaleLowerCase() === "passwordassistancerole") {
                      fieldData.isLowSecurityRole = "No";
                      Utils.toastError("User role exists in Password Assistance Role.", { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                    } else if (result.toString().toLocaleLowerCase() === "lowsecurityrole") {
                      fieldData.isLowSecurityRole = "No";
                      Utils.toastError("The user should not be able to mark any role as Low Security that is higher in Hierarchy than any Password Assistance role to ensure that all users can receive password assistance.",
                        { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                    } else {
                      fieldData.isLowSecurityRole = "No";
                      Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                    }
                    this.setState({ fieldData, loader: false });
                    resolve();
                  }).catch((error) => {
                    Utils.toastError("Error " + error, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
                    this.setState({ loader: false });
                    reject();
                  });
              },
            },
            {
              label: "Cancel",
              className: "confirmAlert btnDiscard",
              onClick: () => {
                fieldData.isLowSecurityRole = "No";
                this.setState({ fieldData, loader: false });
                reject()
              },
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      } else {
        defaultData.forEach(item => item.isChecked = "No");
        let sendidString: any = [];
        defaultData.forEach(item => item.parentID !== 0 && sendidString.push(item.childID));
        this.setState({ defaultData }, () => {
          this.changeTreeNodeKey(this.state.defaultData);
          this.UpdateRolePermissionTreeViewData(sendidString);
        });
      }
    }
  }

  UpdateRolePermissionTreeViewData = (sendidString: any) => {
    this.setState({ loader: true });
    let request: any = {};
    request.RoleID = this.state.fieldData.roleid;
    request.PasswordStateChange = this.state.isPasswordReset;
    request.RemovePermissionIDList = sendidString.toString();
    request.AddPermissionIDList = "";
    request.RoleTag = this.state.roleTagValue.trim().toLowerCase() === "nn" ? "" : this.state.roleTagValue.trim();
    request.LowSecurityStateChange = "Yes";
    request.UpdateRoleName = this.state.fieldData.roleName;
    RoleMatrixClass.saveRoleMatrixData(request).then(async (result: any | null) => {
      if (result !== null) {
        if (result.success) {
          if (this.state.isNewRole || this.state.isDuplicate) {
            this.handleRolesRight();
            this.setState({ loader: false, isFromLowSecurity: true });
          } else {
            this.setState({
              isNewRole: false,
              isRActions: true,
              isDuplicate: false,
              isRPanelHide: true,
              isSaveButtonDisable: false,
              isActionFooter: false,
            });
            let new_state = Object.assign({}, this.state);
            let fieldData = new_state.fieldData;
            this.props.getRoleData();
            this.props.refreshleftpanelforNewRole();
            this.setState({ fieldData, hideLSToggle: true }, () => {
              setTimeout(() => {
                this.handleRowClick(this.state.fieldData);
                this.setState({ loader: false });
              }, 100);
            });
          }
          toast.success("Permission updated successfully.", { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
        } else {
          this.setState({ loader: false });
          Utils.toastError("Error " + result.result, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
        }
      }

      resolve();
    }).catch((error) => {
      Utils.toastError("Error " + error, { position: toast.POSITION.BOTTOM_RIGHT, containerId: "RolePermissionRightPanel" });
      this.setState({ loader: false });
      reject();
    });
  };

  handleRolesRight = () => {
    this.setState({
      newRoleTextBox: "",
      isActionFooter: true,
      userAssignedArray: [],
      isLowRoleSelected: false
    });
    //localStorage.setItem("roleid", "0");
    RolesforTreeView.GetUserRoleforTreeView(0)
      .then(async (result: IRolePermissionResponseTreeViewDto[] | null) => {
        if (result != null) {
          this.props.childHandleChange("NewRolePermission");
          this.setState({
            filterText: "",
            oldRoleIDtoDelete: "",
            expandcollapse: "Expand All",
            expandallclass: "plus-minus-toggle collapsed",
            selectUnselctAll: "Select All",
            roleTagSelected: "Select a new tag...",
            isRoleTagSelected: false,
          });

          this.changeTreeNodeKey(result);

          this.setState({
            newRoleTextBox: "form-control",
            key: "Permissions",
          });
          if (!this.state.isFromLowSecurity) {
             
          }
        }
        this.setState({ isActionFooter: true, isTextBoxShow: true, isFromLowSecurity: false }, () => {
          this.props.changeValueFuntion(true);
        });

        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false, isFromLowSecurity: false });
        reject();
      });

    this.setState({
      isNewRole: true,
      isRPanelHide: false,
      isDuplicate: false,
    });
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.resetPwd = "Yes";
    fieldData.assignedDept = 0;
  };

  handleRolesLeft = (data: any) => {
    this.handleRolesRight();
  };

  handleDiscard = (e: any) => {
    localStorage.setItem("ClickEventType", JSON.stringify("SelectALL"));
    this.setState({
      isSaveButtonDisable: false,
      isMoreItemShow: false,
      isMoreDivShow: "No",
      roleTitle: "",
    });
    this.props.changeValueFuntion(false);
    let eventType = JSON.parse(localStorage.getItem("eventType")!);
    if (eventType !== "rightPanelTab") {
      if (this.state.isNewRole === true && this.state.isDuplicate) {
        // this.props.defaultSelectedRow(this.state.fieldData);
        this.handleRowClick(this.state.fieldData);
        //this.props.refreshleftpanel();
        // this.props.removeBlankRow();
      } else if (!this.state.isNewRole && !this.state.isDuplicate) {
        let new_state = Object.assign({}, this.state);
        let fieldData = new_state.fieldData;
        fieldData.resetPwd = this.state.passwordResetoldState;
        //this.loadState();
        //this.props.refreshleftpanel();
        this.handleRowClick(this.state.fieldData);
        this.setState({ isActionFooter: false });
      } else {
        ///this.loadState();
        //this.handleRowClick(this.state.fieldData);
        this.setState({ isRPanelHide: true });
        this.props.refreshleftpanel();
        this.props.removeBlankRow();
      }
    }
    if (eventType === "rightPanelTab") {
      let tabType = JSON.parse(localStorage.getItem("tabType")!);
      this.setState({ isActionFooter: false });
      let new_state = Object.assign({}, this.state);
      let fieldData = new_state.fieldData;
      fieldData.resetPwd = this.state.passwordResetoldState;
      this.handleRowClick(this.state.fieldData);
      this.handleRightTabSelect(tabType);
    }
    if (this.state.isNewRole) {
      let { fieldData } = this.state;
      fieldData.isLowSecurityRole = "No";
      this.setState({ fieldData });
    }
  };

  findAllCheckedUnchecked(obj: any, id: any) {
    if (obj.children.length > 0) {
      for (let i = 0; i < obj.children.length; i++) {
        if (obj.isChecked.toLocaleLowerCase() === "yes") {
          addPermissionID.push(obj.childID);
        } else {
          removePermissionID.push(obj.childID);
        }
        this.findAllCheckedUnchecked(obj.children[i], id);
      }
    }
    if (obj.isChecked.toLocaleLowerCase() === "yes") {
      addPermissionID.push(obj.childID);
    } else {
      removePermissionID.push(obj.childID);
    }
    return obj.id === id ? obj : false;
  }

  handleSave = (e: any) => {
    if (!this.state.isLowRoleSelected) {
      let request = {} as INewRolePermissionDto;
      let duplicateFound;
      addPermissionID = [];
      removePermissionID = [];
      this.setState({ isSaveButtonDisable: true });
      if (
        (this.state.isNewRole ||
          this.state.isDuplicate ||
          this.state.isTextBoxShow) &&
        this.state.roleTitle.trim() === ""
      ) {
        this.setState({
          newRoleTextBox: "form-control newassignroledanger",
          isSaveButtonDisable: false,
        });
        Utils.toastError("Please Enter Role Name", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "RolePermissionRightPanel",
        });

        return;
      }
      request.RoleName = this.state.roleTitle.trim();

      if (
        this.state.fieldData.roleName.trim().toLowerCase() !==
        this.state.roleTitle.trim().toLowerCase() ||
        this.state.isNewRole ||
        this.state.isDuplicate
      ) {
        if (
          (this.state.isNewRole ||
            this.state.isDuplicate ||
            this.state.isTextBoxShow) &&
          this.state.roleTitle !== ""
        ) {
          duplicateFound = this.props.roleListforDelete.filter(
            (it) =>
              it.roleName.trim().toLowerCase() ===
              request.RoleName.trim().toLowerCase()
          );
          if (duplicateFound.length > 0) {
            Utils.toastError("Role name already exists.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "RolePermissionRightPanel",
            });
            this.setState({
              newRoleTextBox: "form-control newassignroledanger",
              isSaveButtonDisable: false,
            });
            return;
          }
        }
      }

      // if (this.state.roleTagSelected === "Select a new tag...") {
      //   this.setState({
      //    // roleTagDangerWell: "dangerRoleTagSelect",
      //    // isSaveButtonDisable: false,
      //    roleTagSelected:""
      //   });
      //   // Utils.toastError("Please select a role tag", {
      //   //   position: toast.POSITION.BOTTOM_RIGHT,
      //   //   containerId: "RolePermissionRightPanel",
      //   // });
      //   // return;
      // }

      this.setState({ newRoleTextBox: "form-control" });

      let checkData = [...this.state.tableData];
      for (let index = 0; index < checkData.length; index++) {
        this.findAllCheckedUnchecked(checkData[index], 0);
      }

      let removePermissionIDs = Array.from(new Set(removePermissionID));
      let addPermissionIDs = Array.from(new Set(addPermissionID));

      let allRemoveIds = "";
      let allAddIDs = "";
      for (let index = 0; index < removePermissionIDs.length; index++) {
        allRemoveIds += removePermissionIDs[index].trim() + ",";
      }
      for (let i = 0; i < addPermissionIDs.length; i++) {
        allAddIDs += addPermissionIDs[i].trim() + ",";
      }

      request.ResetPwd = this.state.fieldData.resetPwd;
      request.AddPermissionIDList = String(allAddIDs.replace(/,\s*$/, ""));
      request.RemovePermissionIDList = String(allRemoveIds.replace(/,\s*$/, ""));
      request.LowSecurity = this.state.fieldData.isLowSecurityRole;
      request.RoleTag = this.state.roleTagValue.trim().toLowerCase() === "nn" ? "" : this.state.roleTagValue.trim();

      this.setState({ loader: true });
      if (this.state.isNewRole) {
        RolesforTreeView.saveNewRolePermissioin(request)
          .then(async (result: any | null) => {
            if (result != null) {
              if (result.message === "Success") {
                let msg = "";
                this.props.changeValueFuntion(false);
                if (this.state.isDuplicate) {
                  msg = "Duplicate Role created successfully.";
                } else {
                  msg = "New Role created successfully.";
                }
                this.setState({ loader: false }, () => {
                  toast.success(msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "RolePermissionRightPanel",
                  });
                });
                let new_state = Object.assign({}, this.state);
                let fieldData = new_state.fieldData;
                fieldData.roleID = result.result;
                fieldData.roleName = this.state.roleTitle;

                this.setState({
                  isRActions: true,
                  isDuplicate: false,
                  isRPanelHide: false,
                  isSaveButtonDisable: false,
                  isActionFooter: false,
                  isMoreItemShow: false,
                  isMoreDivShow: "No",
                  roleTitle: "",
                });

                this.props.getRoleData();
                this.props.removeBlankRow();
                this.props.isBydefaultSelectedRow(result.result);
                this.handleRowClick(this.state.fieldData);
              }
            }
            resolve();
          })
          .catch((error) => {
            this.setState({ loader: false });
            reject();
          });
      } else {
        if (this.state.isPasswordReset === "Yes")
          request.PasswordStateChange = "Yes";
        else request.PasswordStateChange = "No";

        request.RoleID = this.state.fieldData.roleid;
        request.LowSecurityStateChange = this.state.fieldData.isLowSecurityRole;

        request.updateRoleName =
          this.state.roleTitle.trim() === ""
            ? this.state.fieldData.roleName
            : this.state.roleTitle.trim();

        RolesforTreeView.updateExistingRolePermissioin(request)
          .then(async (result: any | null) => {
            if (result != null) {
              this.setState({ loader: false }, () => {
                toast.success("Role updated successfully.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "RolePermissionRightPanel",
                });
              });


              this.setState({
                isNewRole: false,
                isRActions: true,
                isDuplicate: false,
                isRPanelHide: true,
                isSaveButtonDisable: false,
                isActionFooter: false,
              });

              let new_state = Object.assign({}, this.state);
              let fieldData = new_state.fieldData;
              fieldData.roleName = this.state.roleTitle !== "" ? this.state.roleTitle.trim() : fieldData.roleName;
              fieldData.roleTag = this.state.roleTagValue !== "" ? this.state.roleTagValue : fieldData.roleTag;

              this.props.changeValueFuntion(false);
              this.props.refreshleftpanelforNewRole();
              this.handleRowClick(this.state.fieldData);
            }
            this.setState({
              roleTitle: "",
            });
            resolve();
          })
          .catch((error) => {
            this.setState({ loader: false });
            reject();
          });
      }
    } else if (this.state.isLowRoleSelected) {
      this.setState({ loader: true });
      let request = {} as any;
      request.RoleIDs = String(selectedLowRoleID.replace(/,\s*$/, ""));
      RolesforTreeView.SavePasswordAssistanceRole(request)
        .then(async (result: any | null) => {
          if (result != null) {
            if (result.message === "Success") {
              this.props.changeValueFuntion(false);

              this.setState({ loader: false }, () => {
                toast.success("Role updated successfully.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  containerId: "RolePermissionRightPanel",
                });
              });

              let new_state = Object.assign({}, this.state);
              let fieldData = new_state.fieldData;
              //fieldData.roleName = this.state.roleTitle.trim();
              //fieldData.roleTag = this.state.roleTagValue;
              fieldData.roleName = this.state.roleTitle !== "" ? this.state.roleTitle.trim() : fieldData.roleName;
              fieldData.roleTag = this.state.roleTagValue !== "" ? this.state.roleTagValue : fieldData.roleTag;

              this.props.changeValueFuntion(false);
              this.props.refreshleftpanelforNewRole();
              this.handleRowClick(this.state.fieldData);
            }
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loader: false });
          reject();
        });
    }
  };

  HideRightPanel() {
    this.setState({ isRPanelHide: true });
  }

  handleDeleteRole() {
    let request = {} as IDeleteRolePermissionDto;
    if (Number(this.state.oldRoleIDtoDelete) !== 1) {
      if (Number(this.state.noOfAssignedUsertoRole) > 0) {
        if (this.state.roleSelected !== "Select a new role...") {
          request.NewRoleID = Number(this.state.newRoleIDAssign);
          request.OldRoleID = Number(this.state.oldRoleIDtoDelete);

          RolesforTreeView.deleteWithNewAssignRolePermissioin(request)
            .then(async (result: any | null) => {
              if (result != null) {
                if (result.message.toLowerCase() === "success") {
                  toast.success("Role Deleted successfully.", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                    // // containerId: "RolePermissionRightPanel",
                  });
                  this.setState({ rightPanelColor: "right-panel" });
                } else {
                  Utils.toastError("OOPS! Some error occured.", {
                    // // position: toast.POSITION.BOTTOM_RIGHT,
                    // // containerId: "RolePermissionRightPanel",
                  });
                }

                this.props.refreshleftpanel();
                this.props.removeSelectedRow();
              }
            })
            .catch((error) => {
              this.setState({ loaderTrue: false });
              reject();
            });
        } else {
          this.setState({ departmentDangerWell: "newassignroledanger" });
          Utils.toastError("Please Choose a new Role to Reassign.", {
            // // position: toast.POSITION.BOTTOM_RIGHT,
            // // containerId: "RolePermissionRightPanel",
          });
        }
      } else {
        this.setState({ loader: true });
        request.RoleID = Number(this.state.oldRoleIDtoDelete);
        RolesforTreeView.deleteRolePermissioin(request)
          .then(async (result: any | null) => {
            if (result != null) {
              if (result.message.toLowerCase() === "success") {
                this.setState({ loader: false }, () => {
                  toast.success("Role deleted successfully.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "RolePermissionRightPanel",
                  });
                });
              } else {
                this.setState({ loader: false }, () => {
                  Utils.toastError("OOPS! Some error occured.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    containerId: "RolePermissionRightPanel",
                  });
                });
              }
              this.props.refreshleftpanel();
              this.props.removeSelectedRow();
            }
          })
          .catch((error) => {
            this.setState({ loader: false });
            reject();
          });
      }
    } else {
      Utils.toastError("Administrator role cant be deleted.", {
        // // position: toast.POSITION.BOTTOM_RIGHT,
        // // containerId: "RolePermissionRightPanel",
      });
    }
  }

  handleDuplicate = () => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    this.props.childHandleChange("NewRolePermission");
    if (this.state.fieldData.isLowSecurityRole.toString().toLocaleLowerCase() === "yes") {
      fieldData.isLowSecurityRole = "No";
    }
    this.setState(
      {
        roleTagValue: fieldData.roleTag,
        isActionFooter: true,
        isDuplicate: true,
        fieldData: fieldData,
        isAutocomplete: true,
        isRPanelHide: false,
        isDuplicatePanel: true,
        isRActions: false,
        isNewRole: true,
        roleTitle: "Copy of " + fieldData.roleName,
      },
      () => {
        this.props.changeValueFuntion(true);
      }
    );
    //alert(this.state.roleTagValue);
  };

  bindRolesList = (item: any) => {
    this.setState({
      departmentDangerWell: "newassignrole",
      deleteReassignDisable: false,
      roleSelected: item.roleName,
      newRoleIDAssign: item.roleID,
    });
  };

  bindRolesTagsList = (item: any, callType) => {
    if (callType === "rowClick" && item !== "") {
      let selectedValue = this.props.roleTagName.filter(
        (list) => list.result?.split(";")[0] === item
      );
      this.setState({
        roleTagSelected: selectedValue[0]?.result?.split(";")[1],
        isRoleTagSelected: true,
      });
    } else {
      this.setState({
        roleTagDangerWell: "",
        roleTagSelected: item.result?.split(";")[1],
        roleTagValue: item.result?.split(";")[0],
      });
      if (this.state.isNewRole !== true) {
        this.setState({ isActionFooter: true }, () => {
          this.props.changeValueFuntion(true);
        });
      }
    }
  };

  handleRoleInput = (e: any) => {
    const { value } = e.target;
    this.setState({
      roleTitle: value,
      newRoleTextBox: "form-control",
      [e.target.name]: { value: e.target.value, isValid: !!e.target.value },
    });
  };

  openDeletePanel = () => {
    this.setState({ panelDelete: true, show: false });
  };

  toggleShow = () => {
    if (this.state.isMoreDivShow === "Yes") {
      this.setState({
        isMoreItemShow: false,
        deleteReassignDisable: true,
        roleSelected: "Select a new role...",
        backColor: "",
        panelDelete: false,
        isMoreDivShow: "No",
      });
    } else {
      this.setState({
        isMoreItemShow: true,
        backColor: "#364760",
        isMoreDivShow: "Yes",
      });
    }
  };

  handleAssignedDepClick = () => {
    localStorage.setItem("mainMenu", "Settings");
    localStorage.setItem("subMenu", "Department & Position");

    // window.open(
    //   "https://inn-flow-v2-dev.azurewebsites.net/labor-management/departments&positions",
    //   "_blank"
    // );

    //return <Redirect to="/labor-management/LaborDepartmentPosition" />
  };

  cancelDeleteRole() {
    this.setState({
      isMoreItemShow: false,
      isMoreDivShow: "No",
      show: false,
      panelDelete: false,
      backColor: "",
      deleteReassignDisable: true,
      roleSelected: "Select a new role...",
    });
  }

  handleRightTabSelect = (eventKey: any) => {
    // localStorage.setItem("eventType", JSON.stringify("rightPanelTab"));
    // localStorage.setItem("tabType", JSON.stringify(eventKey));
    // if (this.props.changeValue) {
    //   this.props.isValidateModelPoupTab();
    // } else {
    this.setState({ key: eventKey });

    // }
    // if(eventKey==="Permissions"){
    // //  localStorage.setItem("tabType","");
    //   //this.handleRolesRight();
    // }
  };

  renderHelpTextTooltip = (e) => (
    <Tooltip id="button-tooltip-helpText" {...e}>
      <span>{e}</span>
    </Tooltip>
  );

  renderTooltip = (e) => (
    <Tooltip id="button-tooltip" {...e}>
      <span>
        Labor management department access for each role. This will not affect
        any other modules besides labor management.
      </span>
    </Tooltip>
  );

  renderLowSecurityRoleTooltip = (e) => (
    <Tooltip id="button-tooltip" {...e}>
      <span>
        Marking this as a low security user role will allow for password assistance from the designated user roles.  This will also remove all permissions within this role.
      </span>
    </Tooltip>
  );

  renderPwdResetTooltip = (e) => (
    <Tooltip id="button-tooltipPwd" {...e}>
      <span>
        Selecting the reset password would require users of this role permission
        to reset their passwords every 90 days.
      </span>
    </Tooltip>
  );
  renderToolTipMasterRole = (e) => (
    <Tooltip id="button-tooltip" {...e}>
      <span>System Role.</span>
    </Tooltip>
  );
  renderRoleTagTooltip = (e) => (
    <Tooltip id="button-tooltipPwd" {...e}>
      To allow Inn-Flow to send notifications based on role, a role tagging
      functionality has been added. Since users can name roles anything they
      want, the tag allows Inn-Flow to find a certain role and send
      notifications.
    </Tooltip>
  );
  showTextBox = (roleName) => {
    if (roleName.trim().toLowerCase() !== "administrator" && !this.state.isLowRoleSelected) {
      this.setState(
        { isActionFooter: true, isTextBoxShow: true, roleTitle: roleName },
        () => {
          this.props.changeValueFuntion(true);
        }
      );
    }
  };

  renderTextBox = () => {
    if (this.state.isNewRole || this.state.isTextBoxShow) {
      return (
        <div className="search-header mr-auto auto-complete">
          <Form.Control
            type="text"
            name="newRole"
            value={this.state.roleTitle}
            placeholder="Enter Role Name"
            onChange={this.handleRoleInput}
            className={this.state.newRoleTextBox}
          />
        </div>
      );
    }
  };

  hideTextBox = () => {
    this.setState(
      { isActionFooter: false, isTextBoxShow: false, roleTitle: "" },
      () => {
        this.props.changeValueFuntion(false);
      }
    );
  };

  renderInfoIcon = () => {
    return (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        version="1.1"
        viewBox="0 0 16 16"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path>
        <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path>
        <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path>
      </svg>
    );
  };

  userAssignedShow = () => {
    if (this.state.userAssignedArray.length <= 0) {
      Utils.toastError("No users are assigned to this role", {
        // position: toast.POSITION.BOTTOM_RIGHT,
        // containerId: "RolePermissionRightPanel",
      });
    } else {
      this.setState({ userAssignedDDN: true });
    }
  };

  userAssignedHide = () => {
    this.setState({ userAssignedDDN: false });
  };

  render() {
    return (
      <>
        <ToastContainer
          enableMultiContainer
          autoClose={3000}
          containerId={"RolePermissionRightPanel"}
        />
        {this.state.loader && (
          <div className="matrixSpinnerCenter">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}

        {!this.state.isRPanelHide && (
          <div className={this.state.rightPanelColor}>
            <div className="pos-header d-flex align-content-center flex-wrap">
              {!this.state.isLowRoleSelected && (
                <>{this.renderTextBox()}</>
              )}

              {!this.state.isNewRole &&
                !this.state.isDuplicate &&
                !this.state.isTextBoxShow && (
                  <div className="pos-ttl mr-auto">
                    <EllipsisWithTooltip placement="bottom">
                      <div
                        onClick={() => {
                          this.showTextBox(this.state.fieldData.roleName);
                        }}
                      >
                        {this.state.fieldData.roleName}
                      </div>
                    </EllipsisWithTooltip>
                  </div>
                )}

              {!this.state.isNewRole &&
                this.state.isRActions &&
                !this.state.isTextBoxShow &&
                !this.state.isLowRoleSelected && (
                  <div className="more-action">
                    <div
                      className="moreActionRolePermission btn-outline-primary btn btn-primary more"
                      style={{
                        backgroundColor: this.state.backColor,
                      }}
                      onClick={this.toggleShow}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </div>
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        this.setState({
                          isMoreItemShow: false,

                          show: false,
                          panelDelete: false,
                          backColor: "",
                          deleteReassignDisable: true,
                          roleSelected: "Select a new role...",
                        });
                      }}
                    >
                      {this.state.isMoreItemShow && (
                        <div
                          className={
                            !this.state.fieldData.isMasterRole &&
                              this.state.fieldData.roleName.toLowerCase() !==
                              "administrator"
                              ? "dropdown-menu dropdown-menu-right rolePermissionMoreFilter"
                              : "dropdown-menu dropdown-menu-right rolePermissionMoreFilterforAdmin"
                          }
                        >
                          <Form.Group controlId="rActions">
                            <Form.Label
                              column
                              sm="12"
                              onClick={this.handleDuplicate}
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15 1.5H7.5C6.67275 1.5 6 2.17275 6 3V6H3C2.17275 6 1.5 6.67275 1.5 7.5V15C1.5 15.8273 2.17275 16.5 3 16.5H10.5C11.3273 16.5 12 15.8273 12 15V12H15C15.8273 12 16.5 11.3273 16.5 10.5V3C16.5 2.17275 15.8273 1.5 15 1.5ZM3 15V7.5H10.5L10.5015 15H3ZM15 10.5H12V7.5C12 6.67275 11.3273 6 10.5 6H7.5V3H15V10.5Z"
                                  fill="#84888C"
                                />
                              </svg>
                              Duplicate
                            </Form.Label>
                            {!this.state.fieldData.isMasterRole &&
                              this.state.fieldData.roleName.toLowerCase() !==
                              "administrator" && (
                                <div>
                                  <div className="border-line"></div>
                                  <Form.Label
                                    column
                                    sm="12"
                                    onClick={this.openDeletePanel.bind(this)}
                                  >
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="deleteIconRP"
                                    >
                                      <path
                                        d="M3.75226 15C3.75226 15.8273 4.42501 16.5 5.25226 16.5H12.7523C13.5795 16.5 14.2523 15.8273 14.2523 15V6H15.7523V4.5H13.5023H12.7523V3C12.7523 2.17275 12.0795 1.5 11.2523 1.5H6.75226C5.92501 1.5 5.25226 2.17275 5.25226 3V4.5H4.50226H2.25226V6H3.75226V15ZM6.75226 3H11.2523V4.5H6.75226V3ZM6.00226 6H12.0023H12.7523L12.753 15H5.25226V6H6.00226Z"
                                        fill="#84888C"
                                      />
                                      <path
                                        d="M6.75226 7.5H8.25226V13.5H6.75226V7.5ZM9.75226 7.5H11.2523V13.5H9.75226V7.5Z"
                                        fill="#84888C"
                                      />
                                    </svg>{" "}
                                    Delete
                                  </Form.Label>
                                </div>
                              )}
                          </Form.Group>
                        </div>
                      )}
                      {this.state.panelDelete && (
                        <Form
                          noValidate
                          className="moreDropDownRolePermission dropdown-menu dropdown-menu-right"
                        >
                          <Form.Group
                            controlId="exampleForm.ControlTextarea1"
                            className="deleteFormGroupRP"
                          >
                            <div>
                              <Form.Label>
                                <div>
                                  <div className="deleteRoleTitle">
                                    <p>
                                      {" "}
                                      Delete {
                                        this.state.fieldData.roleName
                                      }{" "}
                                      Role
                                    </p>
                                  </div>

                                  <div className="deleteRoleMsg">
                                    <p>
                                      {" "}
                                      Are you sure you want to delete the role "
                                      {this.state.fieldData.roleName}"?
                                    </p>
                                    {this.state.noOfAssignedUsertoRole !==
                                      "0" && (
                                        <p>
                                          There are currently{" "}
                                          {this.state.fieldData.assignedUserCount}{" "}
                                          users assigned to the{" "}
                                          {this.state.fieldData.roleName} role.
                                          Select a new role for these users to
                                          continue:
                                        </p>
                                      )}
                                  </div>
                                </div>
                              </Form.Label>
                            </div>
                            {this.state.noOfAssignedUsertoRole !== "0" && (
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="dropdown-roles"
                                  className={this.state.departmentDangerWell}
                                >
                                  {this.state.roleSelected === "Select" ? (
                                    <span className="placeholder rolePermissionPlaceholder">
                                      Select a new role...
                                    </span>
                                  ) : (
                                    <span className="selected selectedRole">
                                      {this.state.roleSelected}
                                    </span>
                                  )}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="newassigndroplist">
                                  <div>
                                    {this.state.roleListforDelete
                                      .filter(
                                        (item) =>
                                          item.roleID !==
                                          this.state.oldRoleIDtoDelete
                                      )
                                      .map((item: any, idx: any) => (
                                        <Dropdown.Item
                                          key={idx}
                                          eventKey={item.id}
                                          onClick={() =>
                                            this.bindRolesList(item)
                                          }
                                        >
                                          {item.roleName}
                                        </Dropdown.Item>
                                      ))}
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </Form.Group>

                          <Form.Group className="formGroupDeleteRP d-flex">
                            <Form.Label className="ml-auto">
                              <button
                                className="btn btn-ghost"
                                type="button"
                                onClick={this.cancelDeleteRole}
                              >
                                Cancel
                              </button>
                            </Form.Label>
                            <Form.Label>
                              {this.state.noOfAssignedUsertoRole !== "0" && (
                                <Button
                                  className="btn-danger"
                                  onClick={this.handleDeleteRole}
                                  disabled={this.state.deleteReassignDisable}
                                >
                                  Reassign &amp; Delete Role
                                </Button>
                              )}
                              {this.state.noOfAssignedUsertoRole === "0" && (
                                <Button
                                  className="btn-danger"
                                  onClick={this.handleDeleteRole}
                                >
                                  Delete Role
                                </Button>
                              )}
                            </Form.Label>
                          </Form.Group>
                        </Form>
                      )}
                    </OutsideClickHandler>
                  </div>
                )}
            </div>

            {(!this.state.isAutocomplete ||
              this.state.isDuplicatePanel === true) && (
                <div
                  className={
                    this.state.isActionFooter ? "pos-body dataChange" : "pos-body"
                  }
                >
                  <div className="rolePerRPContent">
                    <Form className="mainForm">
                      {!this.state.isLowRoleSelected && (
                        <>
                          <Form.Group as={Row} controlId="notes">
                            <Form.Label
                              column
                              xl="4"
                              lg="4"
                              md="4"
                              sm="4"
                              className="d-flex"
                            >
                              <span className="passwordLabel">Password Reset</span>{" "}
                              {/* <OverlayTrigger
                          placement="bottom"
                          overlay={this.renderPwdResetTooltip}
                        >
                          {this.renderInfoIcon()}
                        </OverlayTrigger> */}
                            </Form.Label>
                            <Col xl="8" lg="8" md="8" sm="8">
                              <Form.Check
                                type="switch"
                                className="sm-controll toggleButtonRP"
                                id="custom-switch1"
                                style={{ marginTop: 1 }}
                                label=""
                                disabled={
                                  this.state.fieldData.roleName.toLowerCase() ===
                                    "administrator"
                                    ? true
                                    : false
                                }
                                checked={
                                  this.state.fieldData.resetPwd === "Yes"
                                    ? true
                                    : false
                                }
                                onChange={(event: any) =>
                                  this.handlePasswordReset(event)
                                }
                              />
                            </Col>
                          </Form.Group>
                          {this.state.fieldData.roleName.toLowerCase() !== "administrator" &&
                            this.props?.roleDataList.length > 0 &&
                            this.props?.lowSecurityCheck &&
                            // !this.state.isNewRole &&
                            // this.state.hideLSToggle &&
                            this.props?.isLowSecurityPer && (
                              <Form.Group as={Row} controlId="LowSecurityRoleControl">
                                <Form.Label column xl="4" lg="4" md="4" sm="4" className="d-flex">
                                  <span className="passwordLabel d-flex align-items-center">Low Security Role
                                    <OverlayTrigger placement="bottom" overlay={this.renderLowSecurityRoleTooltip}>
                                      <span className="info-icn">{this.renderInfoIcon()}</span>
                                    </OverlayTrigger></span>{" "}
                                </Form.Label>
                                <Col xl="8" lg="8" md="8" sm="8">
                                  <Form.Check type="switch" className="sm-controll toggleButtonRP" id="LowSecurityRole"
                                    label=""
                                    // disabled={this.state.hideLSToggle ? false : true}
                                    checked={this.state.fieldData.isLowSecurityRole.toString().toLocaleLowerCase() === "yes" ? true : false}
                                    onChange={(event: any) => this.handleLowSecurityRole(event)}
                                  />
                                </Col>
                              </Form.Group>
                            )}
                        </>
                      )}
                      <Form.Group as={Row} controlId="notes">
                        <Form.Label
                          column
                          xl="4"
                          lg="4"
                          md="4"
                          sm="4"
                          className="d-flex align-items-center"
                        >
                          <span className="deptCountLabel">Assigned</span>
                        </Form.Label>
                        <Col
                          xl="4"
                          lg="4"
                          md="4"
                          sm="4"
                          //style={{ marginTop: 10 }}
                          className="d-flex align-items-center"
                        >
                          <span className="departMentAssigned deptAssignedWidthRP">
                            <Link
                              to={{
                                pathname:
                                  "/labor-management/LaborDepartmentPosition",
                                state: {
                                  roleID: this.state.oldRoleIDtoDelete,
                                  pageName: "RolePermission",
                                },
                              }}
                              onClick={() => this.handleAssignedDepClick()}
                            >
                              <OverlayTrigger
                                placement="bottom"
                                overlay={this.renderTooltip}
                              >
                                <span className="info-icn">
                                  {this.renderInfoIcon()}
                                </span>
                              </OverlayTrigger>
                              {this.state.fieldData.assignedDept} Departments
                              Assigned
                            </Link>
                          </span>
                        </Col>

                        <Col
                          xl="4"
                          lg="4"
                          md="4"
                          sm="4"
                          className="d-flex align-items-center"
                        >
                          {!this.state.isNewRole && (
                            <OutsideClickHandler
                              onOutsideClick={() => this.userAssignedHide()}
                            >
                              <div className="assigned-user-dropdown">
                                <Button
                                  className={
                                    this.state.userAssignedDDN
                                      ? "btn-ghost assigned-user-toggle active"
                                      : "btn-ghost assigned-user-toggle"
                                  }
                                  onClick={() => {
                                    this.userAssignedShow();
                                  }}
                                >
                                  Users Assigned
                                </Button>
                                {this.state.userAssignedArray.length > 0 &&
                                  this.state.userAssignedDDN && (
                                    <div className="user-assign-list">
                                      <ul className="list-wrapper">
                                        {this.state.userAssignedArray.map(
                                          (data: any, idx: number) => (
                                            <li key={idx} className="p-none">
                                              <div className="user-assigned-item">
                                                <div className="user-assigned-name">
                                                  {`${data.lastName}, ${data.firstName}`}
                                                </div>
                                                <div className="user-assinged-posi">
                                                  {data.userName}
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </OutsideClickHandler>
                          )}
                        </Col>
                      </Form.Group>
                      {!this.state.isLowRoleSelected && (
                        <>
                          {this.state.fieldData.isMasterRole ? (
                            <div>
                              {this.state.fieldData.roleTag !== "" && (
                                <Form.Group as={Row} controlId="roleTag">
                                  <Form.Label className="d-flex form-label col-form-label col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                    <span className="passwordLabel">Role Tag</span>{" "}
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={this.renderRoleTagTooltip}
                                    >
                                      {this.renderInfoIcon()}
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Col className="col-xl-7 col-lg-7 col-md-7 col-sm-7">
                                    {this.state.fieldData.isMasterRole === true &&
                                      this.state.isDuplicate === false ? (
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          id="dropdown-roles-tags-master"
                                          className={this.state.roleTagDangerWell}
                                          disabled={this.state.isRoleTagSelected}
                                        >
                                          {this.state.roleTagSelected ===
                                            "Select a role tag..." ? (
                                            <span className="placeholder roleTagPlaceholder">
                                              Select a role tag...
                                            </span>
                                          ) : (
                                            <span className="selected selectedTag">
                                              <EllipsisWithTooltip placement="bottom">
                                                {this.state.roleTagSelected}
                                              </EllipsisWithTooltip>
                                            </span>
                                          )}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="newassigndroplist">
                                          <div>
                                            {this.props.roleTagName.map(
                                              (item: any, idx: any) => (
                                                <Dropdown.Item
                                                  key={idx}
                                                  eventKey={item.id}
                                                  onClick={() =>
                                                    this.bindRolesTagsList(
                                                      item,
                                                      "dropCall"
                                                    )
                                                  }
                                                >
                                                  <EllipsisWithTooltip placement="bottom">
                                                    {item.result.split(";")[1]}
                                                  </EllipsisWithTooltip>
                                                </Dropdown.Item>
                                              )
                                            )}
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          id="dropdown-roles-tags"
                                          className={this.state.roleTagDangerWell}
                                        >
                                          {this.state.roleTagSelected ===
                                            "Select a role tag..." ? (
                                            <span className="placeholder roleTagPlaceholder">
                                              Select a role tag...
                                            </span>
                                          ) : (
                                            <span className="selected selectedTag">
                                              {this.state.roleTagSelected}
                                            </span>
                                          )}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="newassigndroplist">
                                          <div>
                                            {this.props.roleTagName.map(
                                              (item: any, idx: any) => (
                                                <Dropdown.Item
                                                  key={idx}
                                                  eventKey={item.id}
                                                  onClick={() =>
                                                    this.bindRolesTagsList(
                                                      item,
                                                      "dropCall"
                                                    )
                                                  }
                                                >
                                                  {item.result.split(";")[1]}
                                                </Dropdown.Item>
                                              )
                                            )}
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    )}
                                  </Col>
                                  <Col className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                                    {this.state.fieldData.isMasterRole === true && (
                                      <div>
                                        {this.state.isRoleTagSelected === true && (
                                          <span>
                                            <OverlayTrigger
                                              placement="bottom"
                                              overlay={this.renderToolTipMasterRole}
                                            >
                                              <img
                                                src={MasterRoleLock}
                                                alt="MasterRoleLock"
                                                style={{ paddingTop: 15 }}
                                              />
                                            </OverlayTrigger>
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Col>
                                </Form.Group>
                              )}
                            </div>
                          ) : (
                            <Form.Group as={Row} controlId="roleTag">
                              <Form.Label className="d-flex form-label col-form-label col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <span className="passwordLabel">Role Tag</span>{" "}
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={this.renderRoleTagTooltip}
                                >
                                  {this.renderInfoIcon()}
                                </OverlayTrigger>
                              </Form.Label>
                              <Col className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-roles-tags"
                                    className={this.state.roleTagDangerWell}
                                  >
                                    {this.state.roleTagSelected ===
                                      "Select a role tag..." ? (
                                      <span className="placeholder roleTagPlaceholder">
                                        Select a role tag...
                                      </span>
                                    ) : (
                                      <span className="selected selectedTag">
                                        <EllipsisWithTooltip placement="bottom">
                                          {this.state.roleTagSelected}
                                        </EllipsisWithTooltip>
                                      </span>
                                    )}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="newassigndroplist">
                                    <div>
                                      {this.props.roleTagName.map(
                                        (item: any, idx: any) => (
                                          <Dropdown.Item
                                            key={idx}
                                            eventKey={item.id}
                                            onClick={() =>
                                              this.bindRolesTagsList(item, "dropCall")
                                            }
                                          >
                                            <EllipsisWithTooltip placement="bottom">
                                              {item.result.split(";")[1]}
                                            </EllipsisWithTooltip>
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Form.Group>
                          )}
                        </>
                      )}
                      <div className="border-line-main"></div>
                      {!this.state.isLowRoleSelected && (
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={this.state.key}
                          onSelect={this.handleRightTabSelect}
                        >
                          <Tab eventKey="Permissions" title="Permissions">
                            <Form className="search-form">
                              <Form.Group controlId="tree" className="d-flex">
                                <div className="form-group has-search">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.33317 15.0001C9.8715 15.0001 11.2857 14.4709 12.414 13.5934L16.0773 17.2567L17.2557 16.0784L13.5923 12.4151C14.4707 11.2859 14.9998 9.87175 14.9998 8.33342C14.9998 4.65758 12.009 1.66675 8.33317 1.66675C4.65734 1.66675 1.6665 4.65758 1.6665 8.33342C1.6665 12.0092 4.65734 15.0001 8.33317 15.0001ZM8.33317 3.33341C11.0907 3.33341 13.3332 5.57591 13.3332 8.33342C13.3332 11.0909 11.0907 13.3334 8.33317 13.3334C5.57567 13.3334 3.33317 11.0909 3.33317 8.33342C3.33317 5.57591 5.57567 3.33341 8.33317 3.33341Z"
                                      fill="#84888C"
                                    />
                                  </svg>

                                  <input
                                    className="filter-text form-control permissionFilter"
                                    placeholder="Filter Permissions by Name"
                                    type="text"
                                    onKeyPress={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    onChange={this.onFilterChange.bind(this)}
                                    value={this.state.filterText}
                                  />
                                </div>

                                <div className="colExpandAllRP">
                                  <Link
                                    to="#"
                                    onClick={() => this.collapseexpand()}
                                    className={[
                                      this.state.expandallclass,
                                      "expandAllAnchorLinkRP",
                                    ].join(" ")}
                                  >
                                    {this.state.expandcollapse}
                                  </Link>
                                </div>
                              </Form.Group>
                            </Form>
                            <div className="allPermissionsCollapse">
                              {this.state.tableData.map((node: any) =>
                                this.renderTree(node)
                              )}
                            </div>

                            <div className="border-line"></div>
                          </Tab>
                          <Tab eventKey="notifications" title="Notifications"></Tab>
                        </Tabs>
                      )}
                      {/* {this.state.isLowRoleSelected && (
                        <React.Fragment>
                          <div className="userInfoLowSec">
                            <div className="WarningMsgLow LowLine">
                              <strong>WARNING:</strong><span className="desc grey"> Low Security users should be
                                used sparingly and only in instances where
                                a user has neither an email address nor a
                                phone number.</span> <br></br>
                            </div>

                            <div className="desc grey WarningMsgLowTwo">Low Security Users:</div>
                            <ol className="LowLine">
                              <li>Cannot access the Inn-Flow web platform</li>
                              <li>Can <strong>ONLY</strong> access iPad Time Clocks</li>
                              <li>Cannot receive any notifications or alerts as they have no contact information available</li>
                              <li>Cannot reset their password on their own, they require a manager's assistance</li>
                            </ol>
                            <div className="WarningMsgLow LowLine">
                              <span className="desc grey"><div className="WarningMsgLow LowLine">
                                <span className="desc grey">By creating Low Security users you
                                  are accepting the security risk they present.
                                  Inn-Flow recommends creating standard users
                                  rather than low security users, and enabling 2
                                  Factor Authentication (2FA) whenever possible.</span>
                              </div></span>
                            </div>
                          </div>
                          <div className="userInfoLowSec">
                            <strong>{this.state.totalEntityCount} of {this.state.totalHotelCount} Entities Covered by Password Assistance</strong>
                          </div>
                          <div className="d-flex">
                            <Form.Label className="d-flex form-label col-form-label col-xl-4 col-lg-4 col-md-4 col-sm-4">
                              <span className="passwordLabel">Password Assistance Roles</span>{" "}

                            </Form.Label>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                              <div className="LowSecuritySection">
                                <div className="LowSecuritycontrolls">
                                  <div className="LowSecuritytypeahead">
                                    <Form>
                                      <Typeahead
                                        flip={true}
                                        id="roles"
                                        multiple={true}
                                        inputProps={{
                                          className: "lowSecurityMultiSelect"
                                        }}
                                        onChange={(event) => {
                                          this.lowRoleSelectorChange(event);
                                        }}
                                        options={this.state.lowRoleOptions}
                                        placeholder="Choose..."
                                        selected={this.state.selectedLowRole}
                                      />
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )} */}
                    </Form>
                  </div>

                  {this.state.isActionFooter && (
                    <div className="fixed-action">
                      <div className="d-flex align-content-center flex-wrap">
                        <div className="mr-auto message">
                          <span>You have unsaved changes</span>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary btn-discard"
                          onClick={this.handleDiscard}
                        >
                          Discard
                        </button>

                        <button
                          type="button"
                          disabled={this.state.isSaveButtonDisable}
                          className="btn btn-primary"
                          onClick={this.handleSave}
                        >
                          {this.state.isNewRole === true
                            ? "Save New Role"
                            : "Save Changes"}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
      </>
    );
  }
}
