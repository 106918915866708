import * as React from "react";
import "react-input-range/lib/css/index.css";
import BootstrapTable from "react-bootstrap-table-next";
// import { resolve, reject } from "q";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const { SearchBar } = Search;

export class RolePermissionLeftPanel extends React.Component<any, any> {
  private isNewRoleScroll: any;

  constructor(props) {
    super(props);
    this.isNewRoleScroll = React.createRef();
    this.state = {
      Roles: props.Roles,
      columns: props.columns,
      resetPwdOldState: "",
      changeValue: props.changeValue,
      fieldData: {
        roleID: 0,
        roleName: "",
        assignedUserCount: 0,
        resetPwd: "",
        assignedDept: 0,
        orderNo: 0,
        tenantID: 0,
        hotelID: 0,
        ishide: false,
        selected: [],
        isselected: false,
        isMasterRole: false,
        roleTag: "",
        roleListforDelete: [],
        isLowSecurityRole: ''
      },
      selectedRow: true,
    };
    this.handleLeftNewRole = this.handleLeftNewRole.bind(this);
    this.removeSelectedRow = this.removeSelectedRow.bind(this);
    this.removeNewBlankRow = this.removeNewBlankRow.bind(this);
    this.defaultSelectedRow = this.defaultSelectedRow.bind(this);
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      Roles: nextProps.Roles,
      changeValue: nextProps.changeValue,
    });
  }

  handleLeftNewRole = (event: any) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.ishide = true;
    this.props.updateStateNewRole(fieldData);
    this.setState({ selectedRow: false }, () => {
      this.isNewRoleScroll.current.scrollIntoView();
    });
    this.setState({ isActionFooter: true }, () => {
      this.props.changeValueFuntion(true);
    });
  };

  removeSelectedRow = (data: any) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.ishide = true;
    this.setState({ selectedRow: false }, () => {
      this.isNewRoleScroll.current.scrollIntoView();
    });
  };

  removeNewBlankRow = (data: any) => {
    let new_state = Object.assign({}, this.state);
    let fieldData = new_state.fieldData;
    fieldData.ishide = false;
    this.setState({ selectedRow: false });
  };

  defaultSelectedRow = (data: any) => {
    if (data !== "") {
      localStorage.setItem("dataUniqueRoleID", JSON.stringify(data));
    }

    let uniqueValueFeild: any;
    for (let i = 0; i <= this.props.Roles.length - 1; i++) {
      if (Number(this.props.Roles[i].roleID) === Number(data)) {
        uniqueValueFeild = i;
        this.setState({
          selected: [this.props.Roles[uniqueValueFeild].roleID],
          isselected: true,
        });
        localStorage.setItem("dataUniqueRoleID", JSON.stringify(""));
      } else {
      }
    }

    this.setState({ selectedRow: true });
    let row: any = {};
    let roles = this.props.Roles;

    let rowIndex: any = -1;
    for (let i = 0; i < roles.length; i++) {
      if (Number(roles[i].roleID) === Number(data)) {
        rowIndex = i;
        row = roles[i];
        break;
      }
    }

    if (rowIndex > -1) {
      this.rowEvents.onClick("e", row, rowIndex);
    }
  };

  rowEvents = {
    onClick: (
      e: any,
      row: {
        hotelID: number;
        tenantID: number;
        assignedUsers: number;
        roleID: number;
        type: string;
        roleName: any;
        resetPwd: string;
        assignedDept: number;
        isMasterRole: boolean;
        roleTag: string;
        roleListforDelete: [];
        isLowSecurityRole: string
      },
      rowIndex: any
    ) => {
      if (this.state.changeValue) {
        this.props.childHandleChange("rowClick");
        this.props.rowChildValue(row);
        this.setState({ selectedRow: false });
        this.props.isValidateModelPoupTab();
      } else {
        let new_state = Object.assign({}, this.state);
        let fieldData1 = new_state.fieldData;
        fieldData1.ishide = false;
        const fieldData = {
          hotelID: row.hotelID,
          tenantID: row.tenantID,
          roleid: row.roleID,
          roletype: row.type,
          roleName: row.roleName,
          assignedUserCount: row.assignedUsers,
          ishide: false,
          resetPwd: row.resetPwd,
          assignedDept: row.assignedDept,
          resetPwdOldState: row.resetPwd,
          roleListforDelete: row.roleListforDelete,
          roleTag: row.roleTag,
          isMasterRole: row.isMasterRole,
          isLowSecurityRole: row.isLowSecurityRole
        };
        this.props.updateState(fieldData);
        this.setState({ selectedRow: true });
        this.setState({ isselected: false });
      }
      this.props?.getLeftRowClick();
    },
  };

  render() {
    const selectRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      classes: "active-row",
      selected: this.state.isselected ? this.state.selected : "",
    };
    const hideRow = {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      classes: "",
    };

    return (
      <div className="left-panel">
        <ToolkitProvider
          keyField="roleName"
          data={this.props.Roles}
          columns={this.state.columns}
          rowEvents={this.rowEvents}
          search
        >
          {(props: {
            searchProps: JSX.IntrinsicAttributes;
            baseProps: JSX.IntrinsicAttributes;
          }) => (
            <div>
              <div className="search-header">
                <div className="leftFilterDiv">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33317 15.0001C9.8715 15.0001 11.2857 14.4709 12.414 13.5934L16.0773 17.2567L17.2557 16.0784L13.5923 12.4151C14.4707 11.2859 14.9998 9.87175 14.9998 8.33342C14.9998 4.65758 12.009 1.66675 8.33317 1.66675C4.65734 1.66675 1.6665 4.65758 1.6665 8.33342C1.6665 12.0092 4.65734 15.0001 8.33317 15.0001ZM8.33317 3.33341C11.0907 3.33341 13.3332 5.57591 13.3332 8.33342C13.3332 11.0909 11.0907 13.3334 8.33317 13.3334C5.57567 13.3334 3.33317 11.0909 3.33317 8.33342C3.33317 5.57591 5.57567 3.33341 8.33317 3.33341Z"
                      fill="#84888C"
                    />
                  </svg>

                  <SearchBar
                    autocomplete="off"
                    {...props.searchProps}
                    placeholder={"Filter Roles by Name"}
                  />
                </div>
              </div>
              <div className="left-body">
                <BootstrapTable
                  {...props.baseProps}
                  keyField="roleID"
                  rowEvents={this.rowEvents}
                  selectRow={this.state.selectedRow ? selectRow : hideRow}
                  hover
                  condensed
                />
                {this.state.fieldData.ishide && (
                  <div className="scrollDiv" style={{ background: "transparent", border: "none" }} ref={this.isNewRoleScroll}>
                    <div className="add-Budget"></div>
                  </div>
                )}
                <div className="add-Budget" onClick={this.handleLeftNewRole}>
                  New Role
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
