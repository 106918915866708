import Instense from "./Axios-config";

export class ReconcileService {


  public static GetReconcileStatus = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetReconcileStatus`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetReconcileStatusYear = async (
  ): Promise<any[] | null> => {
    const url = `/Reconcile/GetReconcileStatusYear?type=''`;
    return Instense.get(url).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetPendingReconcileDeposit = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetPendingReconcileDeposit`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetPendingReconcileWithdrawal = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetPendingReconcileWithdrawal`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetReconcileAccountBalance = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetReconcileAccountBalance`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetReconcileSummary = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetReconcileSummary`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetReconcilePeriod = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetReconcilePeriod`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetDepositDetails = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetDepositDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetWithdrawalDetails = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetWithdrawalDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static SaveReconcileSummary = async (
    request: any
  ): Promise<any[] | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantId = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantId;
    const url = `/Reconcile/SaveReconcileSummary`;
    return Instense.post(url, request).then((response) => {
      let result = response.data;
      return result;
    });
  };

  public static GetUnconcileDetail = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetUnconcileDetail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static exportUnconcileDetail = async (
    request: any
  ): Promise<any | null> => {
    const hoteldateTime = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    let date = hoteldateTime.getDate();
    let year = hoteldateTime.getFullYear();
    let month = (hoteldateTime.getMonth() + 1);
    let hour = hoteldateTime.getHours();
    let min = hoteldateTime.getMinutes();
    let sec = (hoteldateTime.getSeconds());

    let fileName = "";
    if (request.Type == "deposit") {
      fileName = `ViewPendingDeposit-${month}-${date}-${year}-${hour}-${min}-${sec}`;
    }
    else {
      fileName = `ViewPendingWithdrawal-${month}-${date}-${year}-${hour}-${min}-${sec}`;
    }

    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    request.FileName = fileName;

    const url = `/Reconcile/GetUnconcileDetailForExport`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".csv");
        tempLink.click();
      }
    );
  };
  public static GetReconcilefeatureflag = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetReconcilefeatureflag`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetReconcileData = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetReconcileData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetAmexReconcileData = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetAmexReconcileData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetGroupReconcileData = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetGroupReconcileData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static SaveReconcileOtherWithdrawal = async (request: any[]): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.forEach((items) => ((items["TenantId"] = tenantID)));
    const url = `/Reconcile/SaveOtherWithdrawal`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static SaveReconcileAmex = async (request: any[]): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.forEach((items) => ((items["TenantID"] = tenantID)));
    const url = `/Reconcile/SaveReconcileAmex`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetReconcileStatusOtherWithdrawal = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetReconcileStatusOtherWithdrawal`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static SaveCompleteOtherWithdrawal = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/SaveCompleteOtherWithdrawal`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };


  public static GetHotelsForAddAdjustment = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetHotelsForAddAdjustment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetGroupReconcileList = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetGroupReconcileList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };


  public static GetCOAListForAddAdjustment = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetCOAListForAddAdjustment`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetSelectedCOAData = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetSelectedCOAData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static AddAdjustmentsWithdrawal = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/AddAdjustmentsWithdrawal`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
  public static AddAdjustmentsBankSync = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/AddAdjustmentsBankSync`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetReconcileGenDetails = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetReconcileGenDetails`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetReconcilePermission = async (): Promise<any | null> => {
    let request: any = {};
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetReconcilePermission`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  }

  public static getReconcileTypeList = async (request: any): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetAllReconcileType`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  }

  public static GetReconcileAllData = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetReconcileAllData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetHotelStatusAccPer = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantID;
    const url = `/Reconcile/GetHotelStatusAccPer`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static ReconcileAccount = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;

    request.tenantID = tenantID;

    const url = `/Reconcile/ReconcileAccount`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };


  public static AddReconcileVoid = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/AddReconcileVoid`;
    return Instense.post(url, request).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static reconcileExportData = async (request: any): Promise<any | null> => {
    const hoteldateTime = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    let date = hoteldateTime.getDate();
    let year = hoteldateTime.getFullYear();
    let month = (hoteldateTime.getMonth() + 1);

    let fileName = "";
    if (request.type.toLowerCase() === "vendordeposit" || request.type.toLowerCase() === "deposits") {
      fileName = `OtherDeposit-${month}-${date}-${year}`;
    }
    else {
      fileName = request.type + "ExportReconcileData";
    }



    const url = `/Reconcile/GetReconcileDataExport`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  }

  public static GetSplitLettercode = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    //debugger
    const url = `/Reconcile/GetSplitLettercode`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetReconcileDepositGroup = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    //debugger
    const url = `/Reconcile/GetReconcileDepositGroup`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static GetReconcileExistingDepositGroup = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    //debugger
    const url = `/Reconcile/GetReconcileExistingDepositGroup`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static SaveReconcilDepositGroup = async (
    request: any
  ): Promise<any[] | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantId = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantId;
    const url = `/Reconcile/SaveReconcilDepositGroup`;
    return Instense.post(url, request).then((response) => {
      let result = response.data;
      return result;
    });
  };

  public static GetReconcileDepositGroupToggleDetail = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    //debugger
    const url = `/Reconcile/GetReconcileDepositGroupToggleDetail`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };

  public static SaveReconcileDepositGroupToggle = async (
    request: any
  ): Promise<any[] | null> => {
    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantId = storage === null ? 0 : storage.tenantID;
    request.tenantID = tenantId;
    const url = `/Reconcile/SaveReconcileDepositGroupToggle`;
    return Instense.post(url, request).then((response) => {
      let result = response.data;
      return result;
    });
  };
  public static depositReconcileExportData = async (request: any): Promise<any | null> => {
    const hoteldateTime = new Date(JSON.parse(localStorage.storage).currentUtcTime);
    let date = hoteldateTime.getDate();
    let year = hoteldateTime.getFullYear();
    let month = (hoteldateTime.getMonth() + 1);

    let fileName = `Deposit-${month}-${date}-${year}`;
    const url = `/Reconcile/GetDepositReconcileDataExport`;
    return Instense.post(url, request, { responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  }

  public static SaveReconcileStatus = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/SaveReconcileStatus`;
    return Instense.post(url, request).then((response) => {
      let result = response.data;
      return result;
    });
  };

  public static GetGroupReconcileStatus = async (request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    request.TenantID = tenantID;
    const url = `/Reconcile/GetGroupReconcileStatus`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result;
      return result;
    });
  };
}