import Instense from "./Axios-config";
import {
  IGetPayrollExportData,
  IExportPayrollData,
  IGetPayrollExportSlideOutData,
  ISaveUserCheckStatus,
  IMarkASUnexport,
  IGetPtoBalance,
  IGetLaborPtoPayout,
} from "../Contracts/IPayrollExport";

export class PayrollExportService {
  public static GetPayrollData = async (
    request: IGetPayrollExportData
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.TenantID = tenantID;
    request.UserName = `${user_UniqueID}`;
    const url = `/LaborSchedule/Get_LaborExportPayrollData`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static ExportPayroll = async (
    request: IExportPayrollData
  ): Promise<any | null> => {
    
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.TenantID = storage === null ? 0 : (storage.tenantID as any);
    request.UserName = storage === null ? `0` : `${storage.user_UniqueID}`;
    const url = `/LaborSchedule/Get_LaborExportPayrollDataProcess`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static DownloadPayrolExportContractor = async (
    request: IExportPayrollData
  ): Promise<any | null> => {
    
    let storage = JSON.parse(localStorage.getItem("storage")!);
    request.TenantID = storage === null ? 0 : (storage.tenantID as any);
    request.UserName = storage === null ? `0` : `${storage.user_UniqueID}`;
    const url = `/LaborSchedule/DownloadPayrolExportContractor`;
    const response = await Instense.post(url, request,{responseType:"blob"});
    let result = response as any;
    return result;
  };

  public static GetPayrollSlideOutData = async (
    request: IGetPayrollExportSlideOutData
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/LaborSchedule/Get_ExportedPAyrollColumn`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static SavePayrollFieldData = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/SavePayrollFildData`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static SaveAsDefaultView = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/LaborSaveAsDefaultView`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static SaveLaborScheduleFromPayroll = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborSchedule/SaveLaborScheduleFromPayroll`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static SaveUserCheckStatus = async (
    request: ISaveUserCheckStatus
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/LaborSchedule/SaveLaborNewHireorTerminate`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static downloadPayrollSheet = async (
    request: any,
    fileName: string
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    request.TenantID = tenantID;
    request.UserName = `${user_UniqueID}`;

    const url = `/LaborSchedule/DownloadPayrollSheet`;
    return Instense.get(url, { params: request, responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };

  public static DownloadPayrollSheetContractor = async (
    request: any,
    fileName: string
  ): Promise<any | null> => {
    // let storage = JSON.parse(localStorage.getItem("storage")!);
    // let tenantID = storage === null ? 0 : (storage.tenantID as any);
    // let user_UniqueID = storage === null ? 0 : (storage.user_UniqueID as any);
    // request.TenantID = tenantID;
    // request.UserName = `${user_UniqueID}`;

    const url = `/LaborSchedule/DownloadPayrollSheetContractor`;
    return Instense.post(url, request,{ responseType: "blob" }).then(
      (response: any) => {
        let csvURL = window.URL.createObjectURL(response.data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName + ".xlsx");
        tempLink.click();
      }
    );
  };

  public static MarkAsUnexport = async (
    request: IMarkASUnexport
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : storage.tenantID;
    let user_UniqueID = storage === null ? 0 : storage.user_UniqueID;
    request.TenantID = tenantID;
    request.UserName = `${user_UniqueID}`;

    const url = `/LaborSchedule/UnApproveExportToPayroll`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static GetPayRollDate = async (request): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;
    const url = `/LaborActual/GetPayRollDate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };

  public static getPTOBalance = async (
    request: IGetPtoBalance
  ): Promise<any | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;

    const url = `/LaborPTO/GetPTOBalance`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static GetLaborPtoPayout = async (
    request: IGetLaborPtoPayout
  ): Promise<any> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantid = tenantID;

    const url = `/LaborPTOCalculation/GetLaborPtoPayout`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data as any;
      return result;
    });
  };

  public static saveLaborPtoPayout = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborPTOCalculation/SaveLaborPtoPayout`;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };

  public static DeleteLaborPtoPayout = async (
    request: any
  ): Promise<any | null> => {
    const url = `/LaborPTOCalculation/DeleteLaborPtoPayout`;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantID = tenantID;
    const response = await Instense.post(url, request);
    let result = response.data as any;
    return result.result;
  };
}
