import Instense from "./Axios-config";

export class CCPaymentService {

  public static GetCCVendorName = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;

    const url = `/Transfer/GetCCVendorName`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
  public static GetCCBankList = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;

    const url = `/Transfer/GetCCBankList`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
  public static SaveCCPayment = async (
    request: any
  ): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenamtID = tenantID;
    request.userName = storage?.userName;

    console.log(request);
    const url = "/Transfer/SaveCCPayment";
    return Instense.post(url, request).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
  public static CheckExistInvoice = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.TenantID = tenantID;

    const url = `/Transfer/CheckExistInvoice`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
  public static GetAccountOpeningDate = async (request: any): Promise<any[] | null> => {
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    request.tenantId = tenantID;

    const url = `/Transfer/GetAccountOpeningDate`;
    return Instense.get(url, { params: request }).then((response) => {
      let result = response.data.result as any;
      return result;
    });
  };
}
