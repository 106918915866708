import { schConversions } from "./conversions";

// constant interface for a schedule Object
interface IScheduleObject {
  shiftDate: string;
  shiftDay: string;
  shiftInTime: string;
  shiftOutTime: string;
  displayTime: string;
  deptId: string;
  positionId: string;
  optionsItems: string;
  shiftPosition: string;
  shiftType: string;
  shiftIsMultiple: string;
  shiftAction: string;
  shiftbulkSelect: any;
  typeMaster: string;
  actualPunches: string;
  uniqueno: string;
}

// const schedule object
const universalScheduleObject = {} as IScheduleObject;

universalScheduleObject.shiftDate = "";
universalScheduleObject.shiftDay = "";
universalScheduleObject.shiftInTime = "";
universalScheduleObject.shiftOutTime = "";
universalScheduleObject.displayTime = "";
universalScheduleObject.deptId = "";
universalScheduleObject.positionId = "";
universalScheduleObject.optionsItems = "showOptions";
universalScheduleObject.shiftPosition = "";
universalScheduleObject.shiftType = "blankShift";
universalScheduleObject.shiftIsMultiple = "";
universalScheduleObject.shiftAction = "";
universalScheduleObject.shiftbulkSelect = false;
universalScheduleObject.typeMaster = "";
universalScheduleObject.actualPunches = "";
universalScheduleObject.uniqueno = "";

export class dragDrop {
  // To validate overlap and actual in Drag Drop
  public static validateDragDrop = (parentState: any, type: any) => {
    let isValidate: boolean = true;
    let errorMessage: string = "";

    let data = parentState.tableData;
    let {
      dragStartRow,
      dragStartDay,
      dragStartScrollIndex,
      dragStartScrollDay,
      dragStartSchedule,
      dragEndRow,
      dragEndDay,
      dragType,
    } = parentState;
        
    // Condition  for actual shifts needed to be chek here
    if (dragStartSchedule.actualPunches === "Yes") {
      isValidate = false;
      errorMessage =
        "Actual punches added for the employee shift, Schedule can not be moved.";
      return { isValidate, errorMessage };
    }

    // OverlapCondition Check ony on table drop
    if (type !== "scrollHeaderDrop" && dragEndRow.shiftType !== "UnAssigned") {
      let employeeShifts: any = [];
      let rowdata: any;
      if (dragType === "scrollHeader") {
        rowdata = data[dragStartScrollIndex][dragStartScrollDay];
      } else {
        rowdata = dragStartRow[dragStartDay];
      }
      let _strIntime = rowdata[0].shiftInTime;
      let _strOutTime = rowdata[0].shiftOutTime;
      let dayToEndEmployee = dragEndRow.employeeName;
      let _dragenddate = dragEndRow[dragEndDay][0].shiftDate;

      data
        .filter((person: any) => person.employeeName === dayToEndEmployee)
        .map(
          (filteredPerson: any) => (
            employeeShifts.push(filteredPerson.day1),
            employeeShifts.push(filteredPerson.day2),
            employeeShifts.push(filteredPerson.day3),
            employeeShifts.push(filteredPerson.day4),
            employeeShifts.push(filteredPerson.day5),
            employeeShifts.push(filteredPerson.day6),
            employeeShifts.push(filteredPerson.day7)
          )
        );

      for (var i = 0; i < employeeShifts.length; i++) {
        let objVal = employeeShifts[i];
        if (!isValidate) {
          break;
        }

        if (objVal.length > 0) {
          
          for (var j = 0; j < objVal.length; j++) {
            let objValsplit = objVal[j];
            let _shiftIndate = objValsplit.indate;
            let _shiftOutdate = objValsplit.outdate;
            if (objValsplit.shiftDate !== "") {
              let typeMaster=objValsplit.typeMaster;
              let srartdateA: any = new Date(_shiftIndate);
              let endDateA: any = new Date(_shiftOutdate);
              let dragSrartdate: any = new Date(
                _dragenddate + " " + _strIntime
              );
              let dragEndDate: any = new Date(_dragenddate + " " + _strOutTime);
             
              if (srartdateA < dragEndDate && endDateA > dragSrartdate && typeMaster!=="10") {
                isValidate = false;
                errorMessage =
                  "The shift cannot be moved to the selected user as the shift times are overlapping.";
                break;
              } else {
                isValidate = true;
              }
            }
          }
        }
      }
      return { isValidate, errorMessage };
    }
    return { isValidate, errorMessage };
  };

  // Drag Drop Before API Result in Normal Table (Non-Scroll Header)
  public static dragDropTableCompletion = (parentState: any) => {
    //Getting all the varabiles from parent State
    let {
      dragType,
      dragStartScrollDay,
      dragStartScrollIndex,
      dragStartDay,
      dragStartRowIndex,
      dragStartSchedule,
      dragEndRow,
      dragEndDay,
      dragEndRowIndex,
    } = parentState;

    // Deep Copying Dragged Schedule and making changes
    let dragStartScheduleCopy = { ...dragStartSchedule };
    dragStartScheduleCopy.shiftAction = "wfres";

    if (dragEndRow.shiftType === "UnAssigned") {
      dragStartScheduleCopy.shiftType = "unassigned";
    } else {
      dragStartScheduleCopy.shiftType = "unpublished";
    }

    if (
      dragStartSchedule.shiftType === "hideShowPublished" ||
      dragStartSchedule.shiftType === "hideShowDeptUnpublished"
    ) {
      dragStartScheduleCopy.shiftType = "hideShowDeptUnpublished";
    }

    // Declearing deep copeied data varaible based on parentState;
    let data: any = JSON.parse(JSON.stringify(parentState.tableData));

    // Declearing Start and End Days
    let dayToEnd: any;
    let dayToStart: any;

    if (dragType === "scrollHeader") {
      dayToEnd = data[dragEndRowIndex][dragEndDay];
      dayToStart = data[dragStartScrollIndex][dragStartScrollDay];
    } else {
      dayToEnd = data[dragEndRowIndex][dragEndDay];
      dayToStart = data[dragStartRowIndex][dragStartDay];
    }

    // Drag Drop Functionality Starts
    // Drag Start Functionaly Here
    if (dragType === "scrollHeader") {
      //Removing current shift and entering blank shift from start day
      data[dragStartScrollIndex][dragStartScrollDay].splice(0, 1);
      dayToStart = data[dragStartScrollIndex][dragStartScrollDay];
      // To identify schedule after the callback
      let blankObject: any = { ...universalScheduleObject };
      blankObject.uniqueno = dragStartSchedule.uniqueno.toString() + "wfres";
      // wait for response set to blank again, for managing state
      blankObject.shiftAction = "wfres";
      dayToStart.push(blankObject);
      data[dragStartScrollIndex][dragStartScrollDay] = dayToStart;
    } else {
      // Condition for Multiple Shifts
      if (dayToStart.length >= 3) {
        let rowIndex: any;
        for (let i = dayToStart.length - 1; i >= 0; i--) {
          let compareItem: any;
          compareItem = data[dragStartRowIndex][dragStartDay][i];
          if (
            JSON.stringify(compareItem) === JSON.stringify(dragStartSchedule)
          ) {
            rowIndex = i;
            break;
          }
        }

        data[dragStartRowIndex][dragStartDay].splice(rowIndex, 1);
        dayToStart = data[dragStartRowIndex][dragStartDay];
        let blankObject: any = { ...universalScheduleObject };
        // To identify schedule after the callback
        blankObject.uniqueno = dragStartSchedule.uniqueno.toString() + "wfres";
        // wait for response set to blank again, for managing state
        blankObject.shiftAction = "wfres";

        // Inserting the blank object at the desired place;
        dayToStart.splice(rowIndex, 0, blankObject);
        data[dragStartRowIndex][dragStartDay] = dayToStart;
      } else {
        data[dragStartRowIndex][dragStartDay].splice(0, 1);
        dayToStart = data[dragStartRowIndex][dragStartDay];

        let blankObject: any = { ...universalScheduleObject };
        // To identify schedule after the callback
        blankObject.uniqueno = dragStartSchedule.uniqueno.toString() + "wfres";
        // wait for response set to blank again, for managing state
        blankObject.shiftAction = "wfres";

        dayToStart.push(blankObject);
        data[dragStartRowIndex][dragStartDay] = dayToStart;
      }
    }

    // Drag End Functionaly Here
    // checking for HideShow Dept Shifts Dragged in Unassigend Section
    if (
      (dragStartSchedule.shiftType === "hideShowPublished" ||
        dragStartSchedule.shiftType === "hideShowDeptUnpublished") &&
      dragEndRow.shiftType === "UnAssigned"
    ) {
      // To do if the following condition exists -- not recieved requirements yet
    } else {
      // Assigning a variale for multiple conditin checks
      let blankUnassignedIndex: any = -1;

      // Removing the current having shedule to replace with the new schedule
      if (dragEndRow.shiftType === "UnAssigned") {
        for (let i = 0; i < data.length; i++) {
          if (data[i].shiftType === "UnAssigned") {
            let row = data[i][dragEndDay];
            if (row[0].shiftPosition === "") {
              blankUnassignedIndex = i;
              data[blankUnassignedIndex][dragEndDay].splice(0, 1);
              break;
            }
          }
        }
      } else {
        if (
          (dayToEnd.length === 1 && dayToEnd[0].shiftPosition === "+") ||
          (dayToEnd.length === 1 && dayToEnd[0].shiftPosition === "")
        ) {
          data[dragEndRowIndex][dragEndDay].splice(0, 1);
        }
      }

      // Inserting the new scheeule here
      //Checking if the shift has been moved to unassinged and there is space to adjust a shift
      if (blankUnassignedIndex >= 0 && dragEndRow.shiftType === "UnAssigned") {
        dayToEnd = data[blankUnassignedIndex][dragEndDay];
        dayToEnd.push(dragStartScheduleCopy);
        data[blankUnassignedIndex][dragEndDay] = dayToEnd;
      }
      //Checking if the shift has been moved to unassinged and there is no - space to adjust a shift
      else if (
        blankUnassignedIndex === -1 &&
        dragEndRow.shiftType === "UnAssigned"
      ) {
        let unassignedData = parentState.tableData.filter(
          (data: any) => data.shiftType === "UnAssigned"
        );
        // Genarating Blank Row For Insertion to adjust the space
        let rowIndex = unassignedData.length - 1;
        let blankRow: any = {};
        blankRow.actualHour = 0;
        blankRow.bulkSelect = false;
        blankRow.employeeImage = "";
        blankRow.employeeName = "Unassigned Shifts";
        blankRow.rowNo = rowIndex;
        blankRow.shiftType = "UnAssigned";
        blankRow.showUserList = "showOptions";
        blankRow.multipleShiftVisibility = "hideDropdown";
        blankRow.timeDetails = "";
        blankRow.timeDetailsSummary = "";
        blankRow.employeePayType = "";
        for (let i = 0; i < 7; i++) {
          let dayValue = `day${i + 1}`;
          let scheduleObject: any = { ...universalScheduleObject };
          blankRow[dayValue] = [];
          blankRow[dayValue].push(scheduleObject);
        }
        data.splice(rowIndex, 0, blankRow);
        dragStartRowIndex = dragStartRowIndex + 1; // Increasing Start Row Index as a row has been added
        // Inserting the dragged schedule
        data[rowIndex][dragEndDay].splice(0, 1);
        let dataCopy = data[rowIndex][dragEndDay];
        dataCopy.push(dragStartScheduleCopy);
        data[rowIndex][dragEndDay] = dataCopy;
      } else {
        dayToEnd = data[dragEndRowIndex][dragEndDay];
        let lengthOfDay = dayToEnd.length - 1;
        if (dayToEnd.length === 1) {
          let blankObject: any = { ...universalScheduleObject };
          blankObject.shiftPosition = "+";
          dayToEnd.push(blankObject);
        }
        dayToEnd.splice(lengthOfDay, 0, dragStartScheduleCopy);
        data[dragEndRowIndex][dragEndDay] = dayToEnd;
      }
    }

    return data;
  };

  // Drag Drop Before API Result in Scroll Header (Non-Table)
  public static dragDropScrollHeaderCompletion = (parentState: any) => {
    //Getting all the varabiles from parent State
    let {
      dragStartScrollIndex,
      dragStartScrollDay,
      dragEndScrollDay,
      dragType,
      dragStartDay,
      dragStartRowIndex,
      dragStartSchedule,
    } = parentState;

    // Deep Copying Dragged Schedule and making changes
    let dragStartScheduleCopy = { ...dragStartSchedule };
    dragStartScheduleCopy.shiftAction = "wfres";

    dragStartScheduleCopy.shiftType = "unassigned";

    if (
      dragStartSchedule.shiftType === "hideShowPublished" ||
      dragStartSchedule.shiftType === "hideShowDeptUnpublished"
    ) {
      dragStartScheduleCopy.shiftType = "hideShowDeptUnpublished";
    }

    // Declearing deep copeied data varaible based on parentState;
    let data: any = JSON.parse(JSON.stringify(parentState.tableData));

    // Declearing Start Days
    let dayToStart: any;
    if (dragType === "table") {
      dayToStart = data[dragStartRowIndex][dragStartDay];
    } else {
      dayToStart = data[dragStartScrollIndex][dragStartScrollDay];
    }

    // Drag Drop Functionality Starts
    // Drag Start Functionaly Here
    if (dragType === "table") {
      // Condition for Multiple Shifts
      if (dayToStart.length >= 3) {
        let rowIndex: any;
        for (let i = dayToStart.length - 1; i >= 0; i--) {
          let compareItem: any;
          compareItem = data[dragStartRowIndex][dragStartDay][i];
          if (
            JSON.stringify(compareItem) === JSON.stringify(dragStartSchedule)
          ) {
            rowIndex = i;
            break;
          }
        }

        data[dragStartRowIndex][dragStartDay].splice(rowIndex, 1);
        dayToStart = data[dragStartRowIndex][dragStartDay];
        // To identify schedule after the callback
        let blankObject: any = { ...universalScheduleObject };
        blankObject.uniqueno = dragStartSchedule.uniqueno.toString() + "wfres";
        // wait for response set to blank again, for managing state
        blankObject.shiftAction = "wfres";
        // Inserting the blank object at the desired place;
        dayToStart.splice(rowIndex, 0, blankObject);
        data[dragStartRowIndex][dragStartDay] = dayToStart;
      } else {
        data[dragStartRowIndex][dragStartDay].splice(0, 1);
        dayToStart = data[dragStartRowIndex][dragStartDay];
        let blankObject: any = { ...universalScheduleObject };
        dayToStart.push(blankObject);
        data[dragStartRowIndex][dragStartDay] = dayToStart;
      }
    } else {
      data[dragStartScrollIndex][dragStartScrollDay].splice(0, 1);
      dayToStart = data[dragStartScrollIndex][dragStartScrollDay];
      // To identify schedule after the callback
      let blankObject: any = { ...universalScheduleObject };
      blankObject.uniqueno = dragStartSchedule.uniqueno.toString() + "wfres";
      // wait for response set to blank again, for managing state
      blankObject.shiftAction = "wfres";
      dayToStart.push(blankObject);
      data[dragStartScrollIndex][dragStartScrollDay] = dayToStart;
    }

    // Drag End Functionaly Here
    // checking for HideShow Dept Shifts Dragged in Unassigend Section
    if (
      dragStartSchedule.shiftType === "hideShowPublished" ||
      dragStartSchedule.shiftType === "hideShowDeptUnpublished"
    ) {
      // To do if the following condition exists -- not recieved requirements yet
    } else {
      // Assigning a variale for multiple conditin checks
      let status: any = "newRow";
      let currentRowIndex: any = -1;
      for (let i = 0; i < data.length - 1; i++) {
        if (data[i].shiftType === "UnAssigned") {
          let day = data[i][dragEndScrollDay];
          if (day[0].shiftPosition === "") {
            currentRowIndex = i;
            status = "current";
            break;
          }
        }
      }

      // Removing the current having shedule to replace with the new schedule
      // Inserting the new scheeule here
      // Checking if the shift has been moved to unassinged and there is space to adjust a shift
      if (status === "current" && currentRowIndex >= 0) {
        data[currentRowIndex][dragEndScrollDay].splice(0, 1);
        let test = data[currentRowIndex][dragEndScrollDay];
        test.push(dragStartScheduleCopy);
        data[currentRowIndex][dragEndScrollDay] = test;
      }
      // Checking if the shift has been moved to unassinged and there is no - space to adjust a shift
      else {
        let unassignedData = parentState.tableData.filter(
          (data: any) => data.shiftType === "UnAssigned"
        );
        let rowIndex = unassignedData.length - 1;
        let blankRow: any = {};
        blankRow.employeeImage = "";
        blankRow.employeeName = "Unassigned Shifts";
        blankRow.rowNo = rowIndex;
        blankRow.shiftType = "UnAssigned";
        blankRow.timeDetails = "";
        blankRow.showUserList = "showOptions";
        blankRow.multipleShiftVisibility = "hideDropdown";
        blankRow.timeDetailsSummary = "";
        blankRow.employeePayType = "";
        for (let i = 0; i < 7; i++) {
          let dayValue = `day${i + 1}`;

          let scheduleObject: any = { ...universalScheduleObject };
          blankRow[dayValue] = [];
          blankRow[dayValue].push(scheduleObject);
        }

        data.splice(rowIndex, 0, blankRow);
        dragStartRowIndex = dragStartRowIndex + 1; // Increasing Start Row Index as a row has been added
        // Inserting the dragged schedule
        data[rowIndex][dragEndScrollDay].splice(0, 1);
        let test = data[rowIndex][dragEndScrollDay];
        test.push(dragStartScheduleCopy);
        data[rowIndex][dragEndScrollDay] = test;
      }
    }

    return data;
  };

  // After return from API func
  public static afterDragDropResult = (
    parentState: any,
    dragDropArray: any,
    result: any,
    viewType: string
  ) => {
    let newDragDropArray = [...dragDropArray];
    let dragDropIndex = -1;

    if (dragDropArray.length > 0) {
      for (let i = 0; i <= dragDropArray.length; i++) {
        if (Number(dragDropArray[i].uniqueno) === Number(result.old_uniqueno)) {
          dragDropIndex = i;
          break;
        }
      }

      if (dragDropIndex > -1) {
        newDragDropArray.splice(dragDropIndex, 1);
      }

      // Drag Drop Functionality Starts
      // Getiing the Drag Drop Variables
      let { endDay, endRow, startDay, startRow, uniqueno } = dragDropArray[
        dragDropIndex
      ];
      // Declearing deep copeied data varaible based on parentState;
      let data: any = JSON.parse(JSON.stringify(parentState.tableData));
      let footerDayData: any;
      let footerDayTotal: any;
      if (viewType === "userView") {
        footerDayData = JSON.parse(JSON.stringify(parentState.footerDayData));
        footerDayTotal = JSON.parse(JSON.stringify(parentState.footerDayTotal));
      }

      // Managing from Drag Start Here
      // Checking if the drag was from unassigned
      if (startRow.employeeName === "Unassigned Shifts") {
        let startUnassignedIndex = -1;
        for (let i = 0; i < data.length; i++) {
          if (data[i][startDay][0].uniqueno === uniqueno.toString() + "wfres") {
            startUnassignedIndex = i;
            break;
          }
        }
        if (startUnassignedIndex > -1) {
          let dayToStart = data[startUnassignedIndex][startDay];
          dayToStart[0].uniqueno = "";
          dayToStart[0].shiftAction = "";

          data[startUnassignedIndex][startDay] = dayToStart;
        }
      } else {
        let startUnassignedIndex = -1;
        for (let i = 0; i < data.length; i++) {
          if (data[i].employeeName === startRow.employeeName) {
            startUnassignedIndex = i;
            break;
          }
        }
        if (startUnassignedIndex > -1) {
          let dayToStart = data[startUnassignedIndex][startDay];

          if (dayToStart.length >= 3) {
            let rowIndex: any = -1;
            for (let i = dayToStart.length - 1; i >= 0; i--) {
              let compareItem: any;
              compareItem = data[startUnassignedIndex][startDay][i];
              if (compareItem.uniqueno === uniqueno.toString() + "wfres") {
                rowIndex = i;
                break;
              }
            }
            if (rowIndex > -1) {
              // Removing the last add shift ( + ) in case of multiple shift turing to single shift
              if (dayToStart.length === 3) {
                dayToStart.pop();
              }
              dayToStart.splice(rowIndex, 1);
              // dayToStart = data[startUnassignedIndex][startDay];
              data[startUnassignedIndex][startDay] = dayToStart;
            }
          } else {
            dayToStart[0].uniqueno = "";
            dayToStart[0].shiftAction = "";
            dayToStart[0].shiftPosition = "+";

            data[startUnassignedIndex][startDay] = dayToStart;
          }

          // Substarcting employee time only if shift is not for unassigned
          let startUserTime = data[startUnassignedIndex].timeDetails;
          let tempStartTime = startUserTime.split("/");
          let startRowTimeInMInutes: any = schConversions.convertHourToMInute(
            tempStartTime[0]
          );
          let resultRowTimesInHHMM = result.oldHours.replace(".", ":");
          let resultRowTimeInMInutes = schConversions.convertHourToMInute(
            resultRowTimesInHHMM
          );
          let newTimeinMinutes = startRowTimeInMInutes - resultRowTimeInMInutes;
          let startToCalculate = schConversions.convertMinuteToHour(
            newTimeinMinutes
          );
          if (
            Number(startToCalculate.trim().replace(":", ".")) >
              Number(tempStartTime[1].trim().replace(":", ".")) &&
            data[startUnassignedIndex].employeePayType !== "Salaried"
          ) {
            data[startUnassignedIndex].timeDetailsSummary = "overtime";
          } else {
            data[startUnassignedIndex].timeDetailsSummary = "undertime";
          }

          data[
            startUnassignedIndex
          ].timeDetails = `${startToCalculate} /${tempStartTime[1]}`;
        }
      }

      // Managing from Drag End Here
      // Checking if Drag End was in
      if (endRow.employeeName === "Unassigned Shifts") {
        let endUnassignedIndex = -1;
        for (let i = 0; i < data.length; i++) {
          if (data[i][endDay][0].uniqueno === uniqueno) {
            endUnassignedIndex = i;
            break;
          }
        }
        if (endUnassignedIndex > -1) {
          let dayToEnd = data[endUnassignedIndex][endDay];

          dayToEnd[0].shiftAction = "";
          dayToEnd[0].indate = result.indate;
          dayToEnd[0].outdate = result.outdate;
          dayToEnd[0].uniqueno = result.scheduleID;
          dayToEnd[0].shiftDate = result.shiftDate;
          dayToEnd[0].shiftDay = result.shiftDay;

          data[endUnassignedIndex][endDay] = dayToEnd;
        }
      } else {
        let endUnassignedIndex = -1;
        for (let i = 0; i < data.length; i++) {
          if (data[i].employeeName === endRow.employeeName) {
            endUnassignedIndex = i;
            break;
          }
        }

        if (endUnassignedIndex > -1) {
          let dayToEnd = data[endUnassignedIndex][endDay];

          if (dayToEnd.length >= 3) {
            let rowIndex: any = -1;
            for (let i = dayToEnd.length - 1; i >= 0; i--) {
              let compareItem: any;
              compareItem = data[endUnassignedIndex][endDay][i];
              if (compareItem.uniqueno === uniqueno) {
                rowIndex = i;
                break;
              }
            }
            if (rowIndex > -1) {
              dayToEnd[rowIndex].shiftAction = "";
              dayToEnd[0].indate = result.indate;
              dayToEnd[0].outdate = result.outdate;
              dayToEnd[0].uniqueno = result.scheduleID;
              dayToEnd[0].shiftDate = result.shiftDate;
              dayToEnd[0].shiftDay = result.shiftDay;
            }
            data[endUnassignedIndex][endDay] = dayToEnd;
          } else {
            dayToEnd[0].shiftAction = "";
            dayToEnd[0].indate = result.indate;
            dayToEnd[0].outdate = result.outdate;
            dayToEnd[0].uniqueno = result.scheduleID;
            dayToEnd[0].shiftDate = result.shiftDate;
            dayToEnd[0].shiftDay = result.shiftDay;

            data[endUnassignedIndex][endDay] = dayToEnd;
          }

          // Adding employee time only if shift is not for unassigned
          let endUserTime = data[endUnassignedIndex].timeDetails;
          let tempEndTime = endUserTime.split("/");
          let endRowTimeInMInutes: any = schConversions.convertHourToMInute(
            tempEndTime[0]
          );
          let resultRowTimesInHHMM = result.newHours.replace(".", ":");

          let resultRowTimeInMInutes = schConversions.convertHourToMInute(
            resultRowTimesInHHMM
          );
          let newTimeinMinutes = endRowTimeInMInutes + resultRowTimeInMInutes;
          let endToCalculate = schConversions.convertMinuteToHour(
            newTimeinMinutes
          );
          if (
            Number(endToCalculate.trim().replace(":", ".")) >
              Number(tempEndTime[1].trim().replace(":", ".")) &&
            data[endUnassignedIndex].employeePayType !== "Salaried"
          ) {
            data[endUnassignedIndex].timeDetailsSummary = "overtime";
          } else {
            data[endUnassignedIndex].timeDetailsSummary = "undertime";
          }

          data[
            endUnassignedIndex
          ].timeDetails = `${endToCalculate} /${tempEndTime[1]}`;
        }
      }

      // Sorting the Unassigned Section in order of incerasing shift times
      if (endRow.shiftType.toLowerCase() === "unassigned") {
        let unassignedData: any = parentState.tableData.filter(
          (data: any) => data.shiftType.toLowerCase() === "unassigned"
        );

        if (unassignedData.length > 1) {
          let length = unassignedData.length - 1;
          for (let i = 0; i < length; i++) {
            for (let j = 0; j < length - i - 1; j++) {
              let currentRow = data[j];
              let nextRow = data[j + 1];
              let currnetRowDisplay = currentRow[endDay][0].displayTime;
              let nextRowDisplay = nextRow[endDay][0].displayTime;
              let currentTime: any;
              let nextTime: any;
              if (currnetRowDisplay !== "") {
                let tempCurrentTime = currnetRowDisplay.split("-");
                let tempTime = tempCurrentTime[0].trim();

                currentTime = schConversions.tweleveToTwentyFourConvert(
                  tempTime
                );
              }

              if (nextRowDisplay !== "") {
                let tempNextTime = nextRowDisplay.split("-");
                let tempTime = tempNextTime[0].trim();
                nextTime = schConversions.tweleveToTwentyFourConvert(tempTime);
              }
              if (currentTime > nextTime) {
                let tmp = data[j][endDay];
                data[j][endDay] = data[j + 1][endDay];
                data[j + 1][endDay] = tmp;
              }
            }
          }
        }
      }

      // To move the shifts up if anything has been removed from top
      if (startRow.shiftType.toLowerCase() === "unassigned") {
        let unassignedData: any = parentState.tableData.filter(
          (data: any) => data.shiftType.toLowerCase() === "unassigned"
        );

        if (unassignedData.length > 1) {
          let length = unassignedData.length - 1;
          for (let i = 0; i < length; i++) {
            for (let j = 0; j < length - i - 1; j++) {
              let currentRow = data[j];
              let nextRow = data[j + 1];
              let currnetRowPosition = currentRow[startDay][0].shiftPosition;
              let nextRowPosition = nextRow[startDay][0].shiftPosition;

              if (
                currnetRowPosition.trim() === "" &&
                nextRowPosition.trim() !== "+"
              ) {
                let tmp = data[j][startDay];
                data[j][startDay] = data[j + 1][startDay];
                data[j + 1][startDay] = tmp;
              }
            }
          }
        }
      }

      //Removeing any Row from unassinged section if blank
      let unassignedData = parentState.tableData.filter(
        (data: any) => data.shiftType.toLowerCase() === "unassigned"
      );
      for (let i = unassignedData.length - 2; i >= 0; i--) {
        let current = data[i];
        if (
          current.day1[0].shiftPosition.trim() === "" &&
          current.day2[0].shiftPosition.trim() === "" &&
          current.day3[0].shiftPosition.trim() === "" &&
          current.day4[0].shiftPosition.trim() === "" &&
          current.day5[0].shiftPosition.trim() === "" &&
          current.day6[0].shiftPosition.trim() === "" &&
          current.day7[0].shiftPosition.trim() === ""
        ) {
          data.splice(i, 1);
        }
      }

      // Footer Data Calculations in UserView only
      if (viewType === "userView") {
        // Managing The Stat Time  only when it is not started in unassigned
        // Start Time Variables
        if (startRow.shiftType.toLowerCase() !== "unassigned") {
          let indexOfStart =
            Number(startDay.slice(startDay.length - 1, startDay.length)) - 1;
          let newOldHrsInHHMM = result.oldHours.replace(".", ":");
          let appendStartTimeInMInutes = schConversions.convertHourToMInute(
            newOldHrsInHHMM
          );
          let footerStartTimeInMInutes = schConversions.convertHourToMInute(
            footerDayData[indexOfStart].total
          );

          // Deductiong the start time
          let deductedStartTime =
            footerStartTimeInMInutes - appendStartTimeInMInutes;
          let newFooterStartTime = schConversions.convertMinuteToHour(
            deductedStartTime
          );
          footerDayData[indexOfStart].total = newFooterStartTime;
        }

        // Managing End Time only when it is not drooped in unassigned
        // End Time Variables
        if (endRow.shiftType.toLowerCase() !== "unassigned") {
          let indexOfEnd =
            Number(endDay.slice(endDay.length - 1, endDay.length)) - 1;
          let newNewHrsInHHMM = result.newHours.replace(".", ":");
          let appendEndTimeInMInutes = schConversions.convertHourToMInute(
            newNewHrsInHHMM
          );
          let footerEndTimeInMInutes = schConversions.convertHourToMInute(
            footerDayData[indexOfEnd].total
          );

          // Adding the end time
          let deductedEndTime = footerEndTimeInMInutes + appendEndTimeInMInutes;
          let newFooterEndTime = schConversions.convertMinuteToHour(
            deductedEndTime
          );
          footerDayData[indexOfEnd].total = newFooterEndTime;
        }

        // Footer Day Total management
        let totalTime: any = 0;
        for (let i = 0; i < footerDayData.length; i++) {
          totalTime += schConversions.convertHourToMInute(
            footerDayData[i].total
          );
        }
        footerDayTotal[0] = schConversions.convertMinuteToHour(totalTime);
      }

      // Returning Final Result confditional basis

      if (viewType === "userView") {
        return { newDragDropArray, data, footerDayData, footerDayTotal };
      } else {
        return { newDragDropArray, data };
      }
    } else {
      // returning the input value if the drag drop array is blank
      let tableData = parentState.tableData;
      let footerDayData: any = parentState.footerDayData;
      let footerDayTotal: any = parentState.footerDayTotal;

      if (viewType === "userView") {
        return { newDragDropArray, tableData, footerDayData, footerDayTotal };
      } else {
        return { newDragDropArray, tableData };
      }
    }
  };
}
