import React from "react";
import { Button,  Form, Modal } from "react-bootstrap";
import { Utils } from "../../../Common/Utilis";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import moment from "moment";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { ISaveUserReviewRequest } from "../../../Common/Contracts/ISaveUserReviewRequest";

export class AddPerformanceReviewApproval extends React.Component<any, any> {
  private statusList: any[] = [
    { label: "Complete", value: "Complete" },
    { label: "Incomplete", value: "Incomplete" }
  ];
  private performanceReviewApprovalFormSchema = Joi.object({
    fileUniqueNo: Joi.number(),
      userUnique:Joi.number(),
      description: Joi.string().required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please enter Comment" };
        });
      }),
      date: Joi.string(),
      notes: Joi.string().allow(''),
      status: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Status" };
        });
      }),

      reviewType: Joi.string().allow(''),
    prUniqueno: Joi.number().min(-1).allow(-1),
    scheduleId: Joi.number(),
  });
  constructor(props: any) {
    super(props);

  this.state = {
    performanceReviewAprroval: {
      fileUniqueNo: this.props?.performanceReviewAprrovalRow?.noOfDays, // annual case : year 
      userUnique: this.props?.performanceReviewAprrovalRow?.userUniqueno, 
      description: '',
      date: this.props.performanceReviewAprrovalRow?.nextDueDate ? moment(this.props.performanceReviewAprrovalRow?.nextDueDate).format("MM/DD/YY") : '',
      notes:  this.props?.performanceReviewAprrovalRow?.reviewType,
      status:  'Incomplete',
      reviewType: this.props?.performanceReviewAprrovalRow?.reviewType,
      prUniqueno: this.props?.performanceReviewAprrovalRow?.prUniqueno ?  this.props?.performanceReviewAprrovalRow?.prUniqueno: -1, // -1 for new : primary key
      scheduleId: this.props?.performanceReviewAprrovalRow?.scheduleId
    },
    errors: {},
    isDataSaving: false,
    isFormNotValid: true,
    inputPosition: 0
  };
}

  componentDidMount = () => {
  };

  
  componentWillUnmount = () => {
    // setTimeout(() => {
    //   toast.dismiss();
    // }, 1000)
  }
  onFieldChange = (event, inputPosition) => {
    const { performanceReviewAprroval } = this.state;
    let value = event.target.value;
    
    const fieldName = event.target.name;
    if(fieldName == "description"){
      value =typeof value === 'string' ? value?.toString()?.trimLeft() :value;
    }
    performanceReviewAprroval[fieldName] = value;
    this.setState({ performanceReviewAprroval, inputPosition }, () => {
      this.validatePerformanceReviewFormSchema();
    });

  };


  savePerformanceReviewApproval = () => {
    const request: ISaveUserReviewRequest = {
      ...this.state.performanceReviewAprroval,
    } as ISaveUserReviewRequest;
    request.fileUniqueNo = request.reviewType === "Annual Review" ? new Date(request.date).getFullYear() : request.fileUniqueNo;
    this.setState({ isDataSaving: true });
    UserManagement.SaveUserPerformance(request)
      .then((result) => {
        this.setState({ isDataSaving: false });
        if (result?.message === "success") {
          toast.success("Performance review comments have been added successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "somePerformanceReview",
          });
          this.hideModal(true);
        } else {
          Utils.toastError(result?.result?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "somePerformanceReview",
          });
        }
      })
      .catch((error) => {
        this.setState({ isDataSaving: false });
        Utils.toastError(error?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "somePerformanceReview",
        });
      });
  };
  validatePerformanceReviewFormSchema = () => {
    const valid = Joi.validate(
      this.state.performanceReviewAprroval,
      this.performanceReviewApprovalFormSchema,
      {
        abortEarly: false,
      }
    );

    const newErrorObject = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }
    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isFormNotValid: false });
    } else {
      this.setState({ isFormNotValid: true });
    }
    this.setState({ errors: newErrorObject });
    return newErrorObject;
  };

  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { errors, inputPosition } = this.state;

    return (
      <>
        {Object.keys(errors).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{errors[fieldName]}</span>
        )}
      </>
    );
  };


  validationOnClick = (inputPosition) => {
    this.setState({ inputPosition }, () =>
      this.validatePerformanceReviewFormSchema()
    );
  };


  
  hideModal = (isReload = false) => {
    this.props?.hidePerformanceReviewApprovalModal(isReload);
  }

  render() {
    const {
      performanceReviewAprroval: {
        description,
        date,
        status,
        reviewType,
      },
      errors,
      inputPosition,
      isFormNotValid,
      isDataSaving
    } = this.state;
    const { addPerformanceReviewApproval } = this.props;

    return (
      <>
        <Modal
          className="add-performance-review-modal"
          show={addPerformanceReviewApproval}
          onHide={this.hideModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Performance Review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-section">
              <div className="body-section">
                <Form.Group
                  controlId="reviewtype"
                  className="d-flex"
                >
                  <Form.Label style={{width: "45%", paddingTop: "8px"}}>Review Type</Form.Label>
                  <input
                      type="text"
                      className="form-control"
                      value= {reviewType}
                      disabled={true}
                    />
                </Form.Group>
                <Form.Group
                  controlId="ehid"
                  className="d-flex"
                >
                  <Form.Label style={{width: "45%", paddingTop: "8px"}}>Date</Form.Label>
                  <input
                      type="text"
                      className="form-control"
                      value={date}
                      disabled={true}
                    />
                </Form.Group>
                <Form.Group
                  controlId="description"
                  className={`mb-3 ${
                    errors?.description && inputPosition >= 1
                      ? "validation-error"
                      : ""
                  }`}
                  onBlur={() => this.validationOnClick(1)}
                >
                  <Form.Label>Add Comments</Form.Label>
                  <textarea
                    rows={2}
                    placeholder=""
                    onChange={(e)=>this.onFieldChange(e,1)}
                    value={description}
                    className="form-control"
                    name="description"
                    autoComplete="description"
                  />
                  {this.getFieldError("description", 1)}
                </Form.Group>
                <Form.Group
                  controlId="status"
                  onClick={() => this.validationOnClick(2)}
                >
                  <Form.Label>Status</Form.Label>
                  <DropDownList
                    defaultValue={status}
                    data={ this.props?.performanceReviewAprrovalRow?.status === "Complete" ? this.statusList?.filter(x=>x?.value !== "Complete") : this.statusList  }
                    isSearchRequired={false}
                    label={"label"}
                    value={"value"}
                    onDropDownChange={(item) => {
                      if (!item) {
                        return;
                      }
                      this.onFieldChange(
                        Utils.BuildCustomEventObject(
                          "status",
                          item.value
                        ),
                        2
                      );
                    }}
                    selectedItem={this.statusList?.find(
                      (r) => r.value === status
                    )
                    }
                  />

                  {this.getFieldError("status", 2)}
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isFormNotValid || isDataSaving}
              className="btn btn-primary"
              onClick={this.savePerformanceReviewApproval}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
