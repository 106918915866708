import React from "react";
import { Container, 
  // Row, Col, 
  Card } from "react-bootstrap";
import { Link, 
  // useParams
 } from "react-router-dom";
import logo from "../Common/Assets/Images/logo-brand.png";
import { GetUser } from "./GetUser";
import { ResetMethod } from "./ResetMethod";
import { EnterCode } from "./EnterCode";
import { ChangePassword } from "./ChangePassword";
import { ResetPasswordComplete } from "./ResetPasswordComplete";
import { ForgotPassword as PwdService } from "../Common/Services/ForgotPasswordService";
import "../Login/login.scss";

export class Forgot extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      mode: -1,
      userName: "",
      passCode: "",
      resetMode: "",
      type:"",
      errorMessage:"",
      isloading:true
    };
  }
  changeModule(module: any) {
    this.setState({
      mode: module.mode,
      userName: module.userName,
      passCode: module.passCode,
      resetMode: module.resetMode
    });
  }

  componentDidMount() {
    if (this.props.match.params.userName !== "0") {
      let userName = window.atob(this.props.match.params.userName);
      let passCode = window.atob(this.props.match.params.passCode);
      let type = this.props.match.params.type ? window.atob(this.props.match.params.type) : "";
      this.setState({isloading:true});
      PwdService.ValidatePassCode(userName, parseInt(passCode))
      .then(async (resturnMessage: string | null) => {
        this.setState({isloading:false});
        if (resturnMessage === "PasscodeExpired") {
          if(type == "welcomeEmail"){
            this.setState({ errorMessage: "User setup link has expired. Please contact your manager to resend this invitation.",isloading:false });
          }else{
            this.setState({ errorMessage: "Password reset link has been expired.",isloading:false });
          }
          
          this.setState({
            mode: 3,
            userName: userName,
            passCode: passCode,
            type:type,
            resetMode: "mail"
          });
        }else{
          this.setState({
            mode: 3,
            userName: userName,
            passCode: passCode,
            type:type,
            resetMode: "mail"
          });
        }
      })
      .catch((error) => {
        
      });
    } else {
      this.setState({
        mode: 0,
        isloading:false
      });
    }
  }

  render() {
    var moduleShow;
    if (this.state.mode === 0) {
      moduleShow = <GetUser changeModule={this.changeModule.bind(this)} />;
    } else if (this.state.mode === 1 && !this.state.isloading) {
      moduleShow = (
        <ResetMethod
        platform="web"
          changeModule={[this.changeModule.bind(this), this.state.userName]}
        />
      );
    } else if (this.state.mode === 2 && !this.state.isloading) {
      moduleShow = (
        <EnterCode platform="web" changeModule={[this.changeModule.bind(this), this.state]} />
      );
    }  else if (this.state.mode === 3 && !this.state.isloading) {
        moduleShow = (
          <ChangePassword
            errorMessage={this.state.errorMessage}
            changeModule={[this.changeModule.bind(this), this.state]}
          />
        );
      
    } else if (this.state.mode === 4 && !this.state.isloading) {
      moduleShow = (
        <ResetPasswordComplete type={this.state.type}
          changeModule={[this.changeModule.bind(this), this.state]}
        />
      );
    }
    return (
      <>
        <div className="login">
          <div className="lgn-form d-flex flex-column">
            <div className="header d-flex align-items-center">
              <div className="logo">
                <a href="https://www.inn-flow.com" target="_blank">
                  <svg width="121" height="32" viewBox="0 0 121 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.0732 4.37285H13.4629V0.840933C13.4629 0.509232 13.5738 0.270967 13.7941 0.130812C13.9285 0.0436039 14.1019 0 14.2847 0C14.4503 0 14.6238 0.0373748 14.7816 0.10901L24.0732 4.37285Z" fill="#101828" />
                    <path d="M23.8873 4.99585L14.4051 9.34846C14.0364 9.51664 13.7176 9.70196 13.463 9.94178C13.3442 10.0508 13.2395 10.1723 13.1505 10.3077C13.0083 10.5226 12.9036 10.7734 12.838 11.077C12.7864 11.3122 12.7599 11.5785 12.7599 11.8837V27.0735H8.39609C7.48052 27.0735 6.73682 26.3306 6.73682 25.4196V6.64968C6.73682 5.73712 7.48052 4.99585 8.39609 4.99585H23.8873Z" fill="#065986" />
                    <path d="M25.1074 5.29321V27.1357L25.0293 27.1715L24.6558 27.3428L24.5652 27.3848L23.8825 27.6978L15.033 31.7592C14.4909 32.0131 14.0409 32.0691 13.7956 31.9134C13.5753 31.7748 13.5425 31.4696 13.5425 31.3014V11.8836C13.5425 10.7857 13.9096 10.4322 14.733 10.0554L25.0839 5.30411L25.1089 5.29321H25.1074Z" fill="#2ED3B7" />
                    <path d="M75.5064 11.547V14.2244H66.9909V11.547H75.5064ZM69.0919 25.8264V10.2641C69.0919 9.28489 69.2902 8.473 69.6868 7.82844C70.0897 7.18389 70.632 6.70047 71.3137 6.37819C71.9954 6.05591 72.7547 5.89478 73.5913 5.89478C74.1801 5.89478 74.7038 5.94126 75.1624 6.03422C75.6273 6.12719 75.9743 6.21086 76.2036 6.28523L75.5157 8.96261C75.3732 8.91923 75.1934 8.87584 74.9765 8.83246C74.7596 8.78288 74.521 8.75809 74.2607 8.75809C73.6409 8.75809 73.204 8.90683 72.9499 9.20432C72.702 9.49561 72.578 9.91395 72.578 10.4593V25.8264H69.0919Z" fill="#293056" />
                    <path d="M81.5956 6.78724V25.8264H78.1094V6.78724H81.5956Z" fill="#293056" />
                    <path d="M91.2616 26.1053C89.8547 26.1053 88.6369 25.7985 87.6081 25.1849C86.5793 24.5714 85.7829 23.713 85.2189 22.6098C84.6611 21.5004 84.3822 20.2113 84.3822 18.7425C84.3822 17.2675 84.6611 15.9752 85.2189 14.8659C85.7829 13.7565 86.5793 12.895 87.6081 12.2815C88.6369 11.6679 89.8547 11.3611 91.2616 11.3611C92.6622 11.3611 93.877 11.6679 94.9058 12.2815C95.9346 12.895 96.731 13.7565 97.295 14.8659C97.859 15.9752 98.141 17.2675 98.141 18.7425C98.141 20.2113 97.859 21.5004 97.295 22.6098C96.731 23.713 95.9346 24.5714 94.9058 25.1849C93.877 25.7985 92.6622 26.1053 91.2616 26.1053ZM91.2709 23.335C92.0146 23.335 92.6313 23.1304 93.1209 22.7214C93.6167 22.3123 93.9854 21.7607 94.2271 21.0666C94.475 20.3725 94.599 19.5916 94.599 18.7239C94.599 17.8624 94.475 17.0846 94.2271 16.3905C93.9854 15.6902 93.6167 15.1355 93.1209 14.7264C92.6313 14.3112 92.0146 14.1036 91.2709 14.1036C90.521 14.1036 89.895 14.3112 89.393 14.7264C88.8972 15.1355 88.5253 15.6902 88.2774 16.3905C88.0357 17.0846 87.9148 17.8624 87.9148 18.7239C87.9148 19.5916 88.0357 20.3725 88.2774 21.0666C88.5253 21.7607 88.8972 22.3123 89.393 22.7214C89.895 23.1304 90.521 23.335 91.2709 23.335Z" fill="#293056" />
                    <path d="M103.475 25.8264L99.468 11.547H103.019L105.464 21.4943H105.594L108.104 11.547H111.609L114.138 21.4478H114.268L116.685 11.547H120.227L116.22 25.8264H112.576L109.945 16.3161H109.75L107.119 25.8264H103.475Z" fill="#293056" />
                    <path d="M32.659 25.8264V11.547H34.4254V25.8264H32.659ZM33.5608 9.11136C33.2013 9.11136 32.8915 8.9905 32.6312 8.74879C32.3771 8.50089 32.25 8.2034 32.25 7.85633C32.25 7.51546 32.3771 7.22417 32.6312 6.98246C32.8915 6.73456 33.2013 6.6106 33.5608 6.6106C33.9141 6.6106 34.2177 6.73456 34.4719 6.98246C34.7322 7.22417 34.8623 7.51546 34.8623 7.85633C34.8623 8.2034 34.7322 8.50089 34.4719 8.74879C34.2177 8.9905 33.9141 9.11136 33.5608 9.11136Z" fill="#293056" />
                    <path d="M40.2551 16.9669V25.8264H38.4888V11.547H40.1993V13.7875H40.3573C40.692 13.0562 41.2095 12.4705 41.9099 12.0304C42.6164 11.5842 43.4964 11.3611 44.55 11.3611C45.5169 11.3611 46.3629 11.5625 47.088 11.9654C47.8131 12.362 48.3771 12.9508 48.7799 13.7317C49.189 14.5064 49.3935 15.467 49.3935 16.6136V25.8264H47.6272V16.7252C47.6272 15.5662 47.308 14.6521 46.6696 13.9827C46.0313 13.3072 45.1698 12.9694 44.0852 12.9694C43.3477 12.9694 42.6908 13.1305 42.1144 13.4528C41.538 13.7689 41.0825 14.2275 40.7478 14.8287C40.4193 15.4237 40.2551 16.1364 40.2551 16.9669Z" fill="#293056" />
                    <path d="M55.2046 16.9669V25.8264H53.4383V11.547H55.1488V13.7875H55.3069C55.6416 13.0562 56.1591 12.4705 56.8594 12.0304C57.5659 11.5842 58.446 11.3611 59.4996 11.3611C60.4664 11.3611 61.3124 11.5625 62.0375 11.9654C62.7626 12.362 63.3266 12.9508 63.7295 13.7317C64.1385 14.5064 64.343 15.467 64.343 16.6136V25.8264H62.5767V16.7252C62.5767 15.5662 62.2575 14.6521 61.6192 13.9827C60.9808 13.3072 60.1193 12.9694 59.0348 12.9694C58.2972 12.9694 57.6403 13.1305 57.0639 13.4528C56.4875 13.7689 56.032 14.2275 55.6973 14.8287C55.3689 15.4237 55.2046 16.1364 55.2046 16.9669Z" fill="#293056" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="body d-flex align-items-center justify-content-center">
              <div className="frm-sec forgot-password-sec">{moduleShow}</div>
            </div>
            <div className="footer">
              © 2020 Inn-Flow. All Rights Reserved  |  <a
                href="https://www.inn-flow.com"
                target="_blank"
                className="action-link"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      {/* <Container fluid className="login">
        <div className="d-flex justify-content-center login-container flex-column forgot-password">
          <div className="brand-img align-self-center">
            <img src={logo} width="180" height="" className="" alt="Inn-Flow" />
          </div>
          <Card className="align-self-center">{moduleShow}</Card>
        </div>
        <div className="d-flex justify-content-center">
          © 2020 Inn-Flow. All rights reserved.{" "}
          <span className="divider">|</span> <a
                            href="https://www.inn-flow.com"
                            target="_blank"
                            className="action txt-center action-link"
                          >Learn more</a>
        </div>
      </Container> */}
      </>
    );
  }
}
