import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { LaborDayApprove } from "../../../../../Common/Services/LaborDayApprove";
import { ILaborDayApproveRequestDto } from "../../../../../Common/Contracts/ILaborDayApprove";
import { ReactPageLoader } from "../../../../../Common/Components/ReactPageLoader";
import { resolve, reject } from "q";
import { Utils } from "../../../../../Common/Utilis";

export default class LaborDayOverviewDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      laborDayOverviewData: [],
      loaderTrue: false,
    };
  }

  dayToApproveAccounts(request: ILaborDayApproveRequestDto) {
    this.setState({ loaderTrue: true });
    LaborDayApprove.GetLaborDayOverViewetails(request)
      .then(async (result: any | null) => {
        if (result !== null && result.length > 0) {
          this.setState({ laborDayOverviewData: result });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ loaderTrue: false });
        Utils.toastError(`Server Error: ${error}`, {
          // // position: toast.POSITION.BOTTOM_RIGHT,
          // // containerId: "laborDayOverviewContainer",
        });
        reject();
      });
  }

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.props.laborOverviewChecked(row.letterCode, row.hotelID,"");
    },
  };

  render() {
    const columns = [
      {
        dataField: "letterCode",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="hotelDetailsData">
            <span className="hotelLetterAbbr">{row.letterCode}</span>
            <EllipsisWithTooltip placement="bottom">
              <span className="hotelFullName">{row.hotelName}</span>
            </EllipsisWithTooltip>
          </div>
        ),
      },
      {
        dataField: "dayToApprove",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="daysToApproveData">
            <span className="daysToApproveCount">{row.dayToApprove}</span>
            <span className="daysToApproveLabel">Days to Approve</span>
          </div>
        ),
      },
      {
        dataField: "autoPunchOut",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="autoPunchOutsData">
            <span className="autoPunchOutsCount">{row.autoPunchOut}</span>
            <span className="autoPunchOutsLabel">Auto Punch Outs</span>
          </div>
        ),
      },
      {
        dataField: "overBudgetDept",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => (
          <div className="overBudgetDeptsData">
            <span className="overBudgetDeptsCount">{row.overBudgetDept}</span>
            <span className="overBudgetDeptsLabel">Over Budget Depts</span>
          </div>
        ),
      },
      {
        dataField: "action",
        text: "",
        formatter: (cell: any, row: any, rowIndex: any) => <div></div>,
      },
    ];
    return (
      <>
        {this.state.loaderTrue ? (
          <ReactPageLoader useas={"daysToApprove"} />
        ) : (
          <div className="LaborDayOverviewDetails">
            <h1>Properties with days to approve</h1>
            <div className="LaborDayOverviewDetailsTable">
              <BootstrapTable
                keyField="hotelID"
                data={this.state.laborDayOverviewData}
                columns={columns}
                rowEvents={this.rowEvents}
                hover
              />
            </div>
          </div>
        )}
      </>
    );
  }
}
