import Instense from './Axios-config'
export class DeductionCriteria {

    public static GetAllDeductionCriteria = async (): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
      
        const url = `/DeductionCriteria/GetAllDeductionCriteria`;
        return Instense.get(url, {params: { tenantID } }).then(response => {
          return response.data as any;
        });
      }

      public static SaveDeductionCriteria = async ( request: any ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
       
        let deductionRequest = {...request} as any;
        deductionRequest.tenantId = tenantID;
        const url = `/DeductionCriteria/SaveDeductionCriteria`;
        return Instense.post(url,deductionRequest).then(response => {
          return response.data as any;
        });
      };

      public static DeleteDeductionCriteria = async ( Uniqueno: any ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
       
        let deductionRequest = {} as any;
        deductionRequest.TenantId = tenantID;
        deductionRequest.Uniqueno = Uniqueno;
        const url = `/DeductionCriteria/DeleteDeductionCriteria`;
        return Instense.delete(url,{params:{...deductionRequest}}).then(response => {
          return response.data as any;
        });
      };


      public static MultiDeleteDeductionCriteria = async ( UniquenoList: any ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID as any;
       
        let deductionRequest = {} as any;
        deductionRequest.TenantId = tenantID;
        deductionRequest.UniquenoList = UniquenoList;
        const url = `/DeductionCriteria/MultipleDeleteDeductionCriteria`;
        return Instense.delete(url,{data:{...deductionRequest}}).then(response => {
          return response.data as any;
        });
      };
      
      
      public static GetDeductionCriteriaChangeLog = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantId = tenantID;
        const url = `/DeductionCriteria/GetDeductionCriteriaLog` ;
        return Instense.get(url, { params: request }).then((response) => {
          let result = response.data.result?.result as any;
          return result;
        });
      };


      public static GetOldDeductionCriteriaChangeLog = async (request: any): Promise<any[] | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantId = tenantID;
        const url = `/DeductionCriteria/GetOldDeductionCriteriaLog` ;
        return Instense.get(url, { params: request }).then((response) => {
          let result = response.data.result?.result as any;
          return result;
        });
      };

      public static DownloadChangeLog = async (
        request: any, fileName: string
      ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantId = tenantID;
        const url =  `/DeductionCriteria/DownloadDeductionCriteriaChangeLog`;
        return Instense.post(url, request, { responseType: "blob" }).then(
          (response: any) => {
            let csvURL = window.URL.createObjectURL(response.data);
            let tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", fileName + ".xlsx");
            tempLink.click();
          }
        );
      };

      public static DownloadOldChangeLog = async (
        request: any, fileName: string
      ): Promise<any | null> => {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        request.tenantId = tenantID;
        const url =  `/DeductionCriteria/GetOldDeductionCriteriaChangeLogExcelData`;
        return Instense.post(url, request, { responseType: "blob" }).then(
          (response: any) => {
           // debugger;
            let csvURL = window.URL.createObjectURL(response.data);
            let tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", fileName + ".xlsx");
            tempLink.click();
          }
        );
      };
    
}